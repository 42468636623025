const result = {
    total: 3,
    data: [
        {
            created: '2022-09-27T04:51:19.000Z',
            id: 3,
            path: '/home/ubuntu/s9s_tmp/error-report-2022-09-27_045117-cluster16.tar.gz',
            size: '7.29 MiB',
            www: false,
        },
        {
            created: '2022-09-27T06:17:14.000Z',
            id: 4,
            path: '/folder/error-report-2022-09-27_061712-cluster16.tar.gz',
            size: '7.34 MiB',
            www: false,
        },
        {
            created: '2022-09-27T08:35:46.000Z',
            id: 6,
            path: '/home/ubuntu/s9s_tmp/error-report-2022-09-27_083543-cluster16.tar.gz',
            size: '7.41 MiB',
            www: false,
        },
    ],
};

export default result;
