import React from 'react';
import { Form } from 'antd';
import { Col, Row, Space, Input } from 'antd';

export const keepalivedHostValidation = [
    ['keepalivedHost', 'virtualIP'],
    ['keepalivedHost', 'networkInterface'],
];
export const keepalivedHostInitial = {
    keepalivedHost: {
        networkInterface: 'eth0',
    },
};

export default CreateKeepalivedHost;

function CreateKeepalivedHost() {
    return (
        <div style={{ minHeight: 450 }}>
            <Row gutter={[24, 0]}>
                <Col span={24}>
                    <h3>Host configuration</h3>
                </Col>
            </Row>
            <Row gutter={[24, 0]}>
                <Col span={12}>
                    <Form.Item
                        name={['keepalivedHost', 'virtualIP']}
                        label={<Space>Virtual IP</Space>}
                        rules={[
                            {
                                required: true,
                                message: 'Enter Virtual IP.',
                            },
                        ]}
                    >
                        <Input placeholder="Enter Virtual IP."></Input>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        name={['keepalivedHost', 'networkInterface']}
                        label={<Space>Network interface</Space>}
                        rules={[
                            {
                                required: true,
                                message: 'Enter Network interface.',
                            },
                        ]}
                    >
                        <Input placeholder="Enter Network interface."></Input>
                    </Form.Item>
                </Col>
            </Row>
        </div>
    );
}
