import React, { Fragment, useState } from 'react';
import { Form, Col, Row, Input, RadioChangeEvent, Space } from 'antd';
import { FormInstance } from 'antd/lib/form';
import InfoIcon from '@severalnines/bar-frontend-components/build/lib/General/InfoIcon';
import AppRadio from '../../../../../../common/DataEntry/AppRadio';

export default ProxySQLRulesType;

type ProxySQLRulesTypeProps = {
    form: FormInstance;
};

export const RULES_TYPE_INITIAL = {
    rulesType: {
        ruleType: 'digestType',
    },
};

function ProxySQLRulesType({ form }: ProxySQLRulesTypeProps) {
    const [ruleType, setRuleType] = useState<string>(
        form.getFieldValue(['rulesType', 'ruleType'])
    );
    const onTypeChange = (e: RadioChangeEvent) => {
        const type = e.target.value;
        setRuleType(type);
    };
    return (
        <div style={{ minHeight: 450 }}>
            <Row gutter={[24, 0]}>
                <Col span={24}>
                    <h3>Rule type</h3>
                </Col>
            </Row>
            <Row gutter={[24, 0]}>
                <Col span={24}>
                    <Form.Item name={['rulesType', 'ruleType']} label="Type">
                        <AppRadio.Group onChange={onTypeChange}>
                            <AppRadio.Button value="digestType">
                                Digest
                            </AppRadio.Button>

                            <AppRadio.Button value="matchDigestType">
                                Match digest
                            </AppRadio.Button>
                            <AppRadio.Button value="matchPatternType">
                                Match pattern
                            </AppRadio.Button>
                        </AppRadio.Group>
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={[24, 0]}>
                {ruleType === 'digestType' && (
                    <Col span={12}>
                        <Form.Item
                            name={['rulesType', 'digest']}
                            label={
                                <Space>
                                    Digest
                                    <InfoIcon
                                        info={
                                            'Match queries with a specific digest hash, as returned by stats_mysql_query_digest.digest.'
                                        }
                                    />
                                </Space>
                            }
                        >
                            <Input placeholder="Enter digest."></Input>
                        </Form.Item>
                    </Col>
                )}
                {ruleType === 'matchDigestType' && (
                    <Col span={12}>
                        <Form.Item
                            name={['rulesType', 'matchDigest']}
                            label={
                                <Space>
                                    Match Digest
                                    <InfoIcon
                                        info={
                                            'Regular expression that matches the parameterized query digest.'
                                        }
                                    />
                                </Space>
                            }
                        >
                            <Input placeholder="Enter match digest."></Input>
                        </Form.Item>
                    </Col>
                )}
                {ruleType === 'matchPatternType' && (
                    <Fragment>
                        <Col span={12}>
                            <Form.Item
                                name={['rulesType', 'matchPattern']}
                                label={
                                    <Space>
                                        Match pattern
                                        <InfoIcon
                                            info={
                                                'Regular expression that matches the non-parameterized query text.'
                                            }
                                        />
                                    </Space>
                                }
                            >
                                <Input placeholder="Enter match pattern."></Input>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name={['rulesType', 'replacePattern']}
                                label={
                                    <Space>
                                        Replace pattern
                                        <InfoIcon
                                            info={
                                                'This is the pattern with which to replace the matched pattern. It is done using RE2::Replace. Note that this is optional, and when this is missing, the query processor will only cache, route, or set other parameters without rewriting.'
                                            }
                                        />
                                    </Space>
                                }
                            >
                                <Input placeholder="Enter replace pattern."></Input>
                            </Form.Item>
                        </Col>
                    </Fragment>
                )}
                <Col span={12}>
                    <Form.Item
                        name={['rulesType', 'comments']}
                        label={
                            <Space>
                                Comments
                                <InfoIcon
                                    info={
                                        'Free form text field, usable for a descriptive comment of the query rule.'
                                    }
                                />
                            </Space>
                        }
                    >
                        <Input placeholder="Enter comments."></Input>
                    </Form.Item>
                </Col>
            </Row>
        </div>
    );
}
