import CcStatsBase, { CcStatsBaseAttentionLevel } from './CcStatsBase';
import { CcLogEntrySeverity } from './CcLogEntry';

export type LogsCountProps = {
    [key in CcLogEntrySeverity]?: number;
};

export type CcLogStatsProps = {
    newLogsCount: LogsCountProps;
};

export default class CcLogStats extends CcStatsBase {
    constructor(props: CcLogStatsProps) {
        super({
            statsCount: props.newLogsCount,
            attentionErrorStats: [
                CcLogEntrySeverity.LOG_EMERG,
                CcLogEntrySeverity.LOG_ALERT,
                CcLogEntrySeverity.LOG_CRIT,
                CcLogEntrySeverity.LOG_ERR,
            ],
            attentionWarnStats: [CcLogEntrySeverity.LOG_WARNING],
        });
    }

    public getFirstAttentionStat(
        level?: CcStatsBaseAttentionLevel
    ): [CcLogEntrySeverity, number] {
        return super.getFirstAttentionStat(level) as [
            CcLogEntrySeverity,
            number
        ];
    }

    public getAttentionStatsValues(
        level?: CcStatsBaseAttentionLevel
    ): CcLogEntrySeverity[] {
        return super.getAttentionStatsValues(level) as CcLogEntrySeverity[];
    }
}
