import React from 'react';
import { Radio, RadioGroupProps, RadioProps } from 'antd';
import { RadioButtonProps } from 'antd/es/radio/radioButton';
import './AppRadio.less';
import classNames from 'classnames';

export default AppRadio;

export type AppRadioProps = RadioProps & {};

function AppRadio({ className, ...rest }: AppRadioProps) {
    return <Radio className={classNames('AppRadio', className)} {...rest} />;
}

export type AppRadioGroupProps = RadioGroupProps & {};

function AppRadioGroup({ className, ...rest }: AppRadioGroupProps) {
    return (
        <Radio.Group
            className={classNames('AppRadioGroup', className)}
            {...rest}
        />
    );
}

export type AppRadioButtonProps = RadioButtonProps & {};

function AppRadioButton({ className, ...rest }: AppRadioButtonProps) {
    return (
        <Radio.Button
            className={classNames('AppRadioButton', className)}
            {...rest}
        />
    );
}

AppRadio.Group = AppRadioGroup;
AppRadio.Button = AppRadioButton;
