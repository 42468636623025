import React, { useMemo, useState } from 'react';
import { Form } from 'antd';
import WizardForm from '@severalnines/bar-frontend-components/build/lib/Navigation/WizardForm';
import WizardFormConfiguration from '@severalnines/bar-frontend-components/build/lib/Navigation/Wizard/WizardFormConfiguration';
import CcCluster from '../../../../services/models/CcCluster';

import useCreateJob from '../../../Jobs/useCreateJob';
import { CmonJobInstanceCommand } from '../../../../services/cmon/models/CmonJobInstance';

import ImportProxySQLConfiguration, {
    proxySQLConfigurationInitialValues,
    getImportProxySQLConfigurationValidation,
} from './ImportProxySQLWizardFromSteps/ImportProxySQLConfiguration';

import ImportProxySQLCredentials, {
    PROXYSQL_CREDENTIALS_VALIDATION,
} from './ImportProxySQLWizardFromSteps/ImportProxySQLCredentials';

import ImportProxySQLPreview from './ImportProxySQLWizardFromSteps/ImportProxySQLPreview';
import { formatFields } from './importProxySQLWizardHelper';

export enum ImportProxySQLFormSteps {
    CONFIGURATION = 'Configuration',
    CREDENTIALS = 'Credentials',
    PREVIEW = 'preview',
}

export type ImportProxySQLWizardFromProps = {
    onCancel?: () => void;
    onSuccess?: () => void;
    onError?: (err: Error) => void;
    onStepErrorInsist?: (err: Error) => void;
    onTouchedChange?: (touched: boolean) => void;
    cluster?: CcCluster;
};

export default ImportProxySQLWizardFrom;

function ImportProxySQLWizardFrom({
    onCancel,
    onSuccess,
    onStepErrorInsist,
    onTouchedChange,
    onError,
    cluster,
}: ImportProxySQLWizardFromProps) {
    const [form] = Form.useForm();
    const [isImportConfig, setIsImportConfig] = useState<boolean>(false);

    const { loading, send } = useCreateJob({
        clusterId: cluster?.clusterId,
        title: 'Register ProxySql',
        command: CmonJobInstanceCommand.PROXYSQL.toLowerCase() as CmonJobInstanceCommand,
        onSuccess,
    });

    const handleImportConfig = (importConfig: boolean) => {
        setIsImportConfig(importConfig);
    };

    const handleSubmit = async () => {
        try {
            const { proxySQLConfiguration } = form.getFieldsValue(true);
            const [address] = proxySQLConfiguration.address;
            await send(
                {
                    clusterId: cluster?.clusterId,
                    ...formatFields(form),
                },
                {
                    job: {
                        title: `Register ProxySql ${address?.extraData.hostname}`,
                    },
                }
            );
        } catch (err: any) {
            if (onError) {
                onError(err);
            }
        }
    };

    const steps = useMemo(
        () =>
            isImportConfig
                ? [
                      <WizardForm.Step
                          key={ImportProxySQLFormSteps.CONFIGURATION}
                          title="Configuration"
                          subTitle=" "
                          validate={getImportProxySQLConfigurationValidation(
                              form
                          )}
                      >
                          <ImportProxySQLConfiguration
                              selectedCluster={cluster}
                              isImportConfig={isImportConfig}
                              form={form}
                          />
                      </WizardForm.Step>,
                      <WizardForm.Step
                          key={ImportProxySQLFormSteps.PREVIEW}
                          title="Preview"
                          subTitle=" "
                      >
                          <ImportProxySQLPreview
                              isImportConfig={isImportConfig}
                              form={form}
                          />
                      </WizardForm.Step>,
                  ]
                : [
                      <WizardForm.Step
                          key={ImportProxySQLFormSteps.CONFIGURATION}
                          title="Configuration"
                          subTitle=" "
                          validate={getImportProxySQLConfigurationValidation(
                              form
                          )}
                      >
                          <ImportProxySQLConfiguration
                              selectedCluster={cluster}
                              form={form}
                          />
                      </WizardForm.Step>,
                      <WizardForm.Step
                          key={ImportProxySQLFormSteps.CREDENTIALS}
                          title="Credentials"
                          subTitle=" "
                          validate={[...PROXYSQL_CREDENTIALS_VALIDATION]}
                      >
                          <ImportProxySQLCredentials />
                      </WizardForm.Step>,

                      <WizardForm.Step
                          key={ImportProxySQLFormSteps.PREVIEW}
                          title="Preview"
                          subTitle=" "
                      >
                          <ImportProxySQLPreview
                              isImportConfig={isImportConfig}
                              form={form}
                          />
                      </WizardForm.Step>,
                  ],
        [isImportConfig]
    );

    return (
        <WizardFormConfiguration
            form={form}
            onValuesChange={(value) => {
                if (value?.proxySQLConfiguration?.importConfig !== undefined) {
                    handleImportConfig(
                        value?.proxySQLConfiguration?.importConfig
                    );
                }
            }}
            onSubmit={handleSubmit}
            loading={loading}
            onCancel={onCancel}
            initialValues={{
                ...proxySQLConfigurationInitialValues(),
            }}
            onStepErrorInsist={onStepErrorInsist}
            onTouchedChange={onTouchedChange}
            steps={steps}
        />
    );
}
