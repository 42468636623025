
import CcNode, { CcNodeProps } from '../../models/CcNode';


export interface CmonContainerServerProps  extends CcNodeProps {
    containers?: string;
    disk_devices?: string;
    images?: string;
    last_container_collect?: number;
    last_hw_collect?: number;
    max_containers?: number;
    max_containers_running?: number;
    memory?: string;
    model?: string;
    network_interfaces?: string;
    processors?: string;
    protocol?: string;
    regions?: string;
    regions_active?: string;
    regions_default?: string;
    regions_supported?: string;
    subnets?: string;
    templates?: string;

}



export default class CmonContainerServer extends CcNode {
    public containers?: string;
    public diskDevices?: string;
    public images?: string;
    public lastContainerCollect?: number;
    public lastHwCollect?: number;
    public maxContainers?: number;
    public maxContainersRunning?: number;
    public memory?: string;
    public model?: string;
    public networkInterfaces?: string;
    public processors?: string;
    public protocol?: string;
    public regions?: string;
    public regionsActive?: string;
    public regionsDefault?: string;
    public regionsSupported?: string;
    public subnets?: string;
    public templates?: string;


    constructor(props: CmonContainerServerProps) {
        super(props);
        this.containers = props.containers;
        this.diskDevices = props.disk_devices;
        this.images = props.images;
        this.lastContainerCollect = props.last_container_collect;
        this.lastHwCollect = props.last_hw_collect;
        this.maxContainers = props.max_containers;
        this.maxContainersRunning = props.max_containers_running;
        this.memory = props.memory;
        this.model = props.model;
        this.networkInterfaces = props.network_interfaces;
        this.processors = props.processors;
        this.protocol = props.protocol;
        this.regions = props.regions;
        this.regionsActive = props.regions_active;
        this.regionsDefault = props.regions_default;
        this.regionsSupported = props.regions_supported;
        this.subnets = props.subnets;
        this.templates = props.templates;

    }

}
