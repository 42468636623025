import React from 'react';
import { FormInstance } from 'antd/lib/form';
import SpaceDescriptions from '../../../../../../../common/Layout/SpaceDescriptions';
import AppDivider from '../../../../../../../common/AppDivider';

export type RulesCacheQueryPreviewProps = {
    form: FormInstance;
};

export default RulesCacheQueryPreview;

function RulesCacheQueryPreview({ form }: RulesCacheQueryPreviewProps) {
    const { cacheConfiguration, rulesType } = form.getFieldsValue(true);
    return (
        <div>
            <SpaceDescriptions
                direction="vertical"
                title="Configuration"
                size="small"
                alignItems="right"
            >
                <SpaceDescriptions.Item label="Rule ID" labelStrong>
                    {cacheConfiguration.ruleId}
                </SpaceDescriptions.Item>

                {cacheConfiguration.username && (
                    <SpaceDescriptions.Item label="Username" labelStrong>
                        {cacheConfiguration.username}
                    </SpaceDescriptions.Item>
                )}
                <SpaceDescriptions.Item
                    label="Destination hostgroup"
                    labelStrong
                >
                    {`${cacheConfiguration.hostgroup}`}
                </SpaceDescriptions.Item>
                {cacheConfiguration.schemaName && (
                    <SpaceDescriptions.Item label="Schema name" labelStrong>
                        {cacheConfiguration.schemaName}
                    </SpaceDescriptions.Item>
                )}
                {cacheConfiguration.cacheTTL && (
                    <SpaceDescriptions.Item label="Cache TTL" labelStrong>
                        {cacheConfiguration.cacheTTL}
                    </SpaceDescriptions.Item>
                )}
            </SpaceDescriptions>
            <AppDivider />
            <SpaceDescriptions
                direction="vertical"
                title="Rule Type"
                size="small"
                alignItems="right"
            >
                {rulesType.ruleType && (
                    <SpaceDescriptions.Item label="Rule Type" labelStrong>
                        {rulesType.ruleType}
                    </SpaceDescriptions.Item>
                )}
                {rulesType.ruleType === 'digestType' && rulesType.digest && (
                    <SpaceDescriptions.Item label="Digest" labelStrong>
                        {rulesType.digest}
                    </SpaceDescriptions.Item>
                )}
                {rulesType.ruleType === 'matchDigestType' &&
                    rulesType.matchDigest && (
                        <SpaceDescriptions.Item
                            label="Match Digest"
                            labelStrong
                        >
                            {rulesType.matchDigest}
                        </SpaceDescriptions.Item>
                    )}
                {rulesType.ruleType === 'matchPatternType' &&
                    rulesType.matchPattern && (
                        <SpaceDescriptions.Item
                            label="Match pattern"
                            labelStrong
                        >
                            {rulesType.matchPattern}
                        </SpaceDescriptions.Item>
                    )}
                {rulesType.ruleType === 'matchPatternType' &&
                    rulesType.replacePattern && (
                        <SpaceDescriptions.Item
                            label="Replace pattern"
                            labelStrong
                        >
                            {rulesType.replacePattern}
                        </SpaceDescriptions.Item>
                    )}
                {rulesType.comments && (
                    <SpaceDescriptions.Item label="Comments" labelStrong>
                        {`${rulesType.comments}`}
                    </SpaceDescriptions.Item>
                )}
            </SpaceDescriptions>
        </div>
    );
}
