import CcBackup from '../../../services/models/CcBackup';
import React, { useEffect, useMemo } from 'react';
import MultiSelect, {
    MULTI_SELECT_ALL_OPTION_VALUE,
} from '../../../common/DataEntry/MultiSelect';

export default BackupDatabaseSelect;
type BackupDatabaseSelectProps = {
    backup: CcBackup;
    value?: string[];
    onChange?: (value: string[]) => void;
};

function BackupDatabaseSelect({
    backup,
    value,
    onChange,
}: BackupDatabaseSelectProps) {
    const databases = useMemo(() => {
        return backup.metadata?.backup
            ?.map((item) => item?.database_names)
            .flat();
    }, [backup]);

    const options = useMemo(() => {
        return databases.map((item) => ({ label: item, value: item }));
    }, [databases]);

    const handleChange = (values: string[]) => {
        if (values?.includes(MULTI_SELECT_ALL_OPTION_VALUE)) {
            onChange?.(databases);
        } else {
            onChange?.(values);
        }
    };

    // covert all values to multiple value
    // @todo consider to have this logic in MultiSelect
    const currentValue = useMemo(() => {
        if (
            value &&
            Array.isArray(value) &&
            value.sort().join(',') === databases.sort().join(',')
        ) {
            return [MULTI_SELECT_ALL_OPTION_VALUE];
        } else {
            return value;
        }
    }, [value, databases]);

    return (
        <MultiSelect
            allOptionName="All schemas"
            options={options}
            value={currentValue}
            onChange={handleChange}
        />
    );
}
