import './PgBouncerDetailsModal.less';
import React from 'react';
import { Row, Space } from 'antd';
import ModalDefault from '../../../../common/ModalDefault';
import CcPostgreSqlNode from '../../../../services/models/CcPostgreSqlNode';
import SpaceDescriptions from '../../../../common/Layout/SpaceDescriptions';
import NodeFormat from '../../NodeFormat';
import CcCluster from '../../../../services/models/CcCluster';
import PgBouncerDetailsContent from './PgBouncerDetailsContent';
import AppPageWrapper from '../../../../common/Layout/AppPageWrapper';

export default PgBouncerDetailsModal;

export type PgBouncerDetailsModalProps = {
    node: CcPostgreSqlNode;
    cluster: CcCluster;
    onCancel?: () => void;
};

function PgBouncerDetailsModal({
    cluster,
    node,
    onCancel,
}: PgBouncerDetailsModalProps) {
    return (
        <ModalDefault
            title="Node details"
            visible={true}
            width={'90%'}
            onCancel={onCancel}
        >
            <AppPageWrapper
                contentClassName="PgBouncerDetailsModal"
                transparent={true}
                contentProps={{ style: { border: 0 } }}
            >
                <Row justify="space-between">
                    <Space direction="vertical" align="start" size={0}>
                        <SpaceDescriptions direction={'vertical'}>
                            <NodeFormat
                                key={node?.hostname}
                                node={node}
                                size="large"
                            />
                        </SpaceDescriptions>
                    </Space>
                </Row>
                <PgBouncerDetailsContent cluster={cluster} node={node} />
            </AppPageWrapper>
        </ModalDefault>
    );
}
