


export interface CmonVerbatimProps  {

}



export default class CmonVerbatim {


    constructor(props: CmonVerbatimProps) {

    }

}
