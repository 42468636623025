import React from 'react';
import JobActionButton from '../../Jobs/JobActionButton';
import { CmonJobInstanceCommand } from '../../../services/cmon/models/CmonJobInstance';
import ButtonWithForm from '../../../common/General/ButtonWithForm';
import CcCluster from '../../../services/models/CcCluster';
import { ButtonProps } from 'antd/lib/button';
import ClusterConvertToShardModal from './ClusterConvertToShard/ClusterConvertToShardModal';

export default ClusterConvertToShard;

export type ClusterConvertToShardProps = ButtonProps & {
    cluster: CcCluster;
    children?: React.ReactNode;
    onSuccess?: () => void;
};

function ClusterConvertToShard({
    cluster,
    onSuccess,
    children,
    ...rest
}: ClusterConvertToShardProps) {
    return (
        <ButtonWithForm
            button={
                <JobActionButton
                    command={CmonJobInstanceCommand.CONVERT_TO_SHARDED_CLUSTER}
                    clusterId={cluster.clusterId}
                    clusterType={cluster.clusterType}
                    {...rest}
                >
                    {children || 'Convert to shard'}
                </JobActionButton>
            }
            form={<ClusterConvertToShardModal cluster={cluster} />}
        />
    );
}
