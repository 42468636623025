
import CcStats, { CcStatsProps } from '../../models/CcStats';


export interface CmonCpuStatsProps  extends CcStatsProps {
    busy?: number;
    cpuid?: number;
    cpumhz?: number;
    cpumodelname?: string;
    cpuphysicalid?: number;
    cputemp?: number;
    guest?: number;
    idle?: number;
    iowait?: number;
    irq?: number;
    loadavg1?: number;
    loadavg15?: number;
    loadavg5?: number;
    steal?: number;
    sys?: number;
    uptime?: number;
    user?: number;

}



export default class CmonCpuStats extends CcStats {
    public busy?: number;
    public cpuid?: number;
    public cpumhz?: number;
    public cpumodelname?: string;
    public cpuphysicalid?: number;
    public cputemp?: number;
    public guest?: number;
    public idle?: number;
    public iowait?: number;
    public irq?: number;
    public loadavg1?: number;
    public loadavg15?: number;
    public loadavg5?: number;
    public steal?: number;
    public sys?: number;
    public uptime?: number;
    public user?: number;


    constructor(props: CmonCpuStatsProps) {
        super(props);
        this.busy = props.busy;
        this.cpuid = props.cpuid;
        this.cpumhz = props.cpumhz;
        this.cpumodelname = props.cpumodelname;
        this.cpuphysicalid = props.cpuphysicalid;
        this.cputemp = props.cputemp;
        this.guest = props.guest;
        this.idle = props.idle;
        this.iowait = props.iowait;
        this.irq = props.irq;
        this.loadavg1 = props.loadavg1;
        this.loadavg15 = props.loadavg15;
        this.loadavg5 = props.loadavg5;
        this.steal = props.steal;
        this.sys = props.sys;
        this.uptime = props.uptime;
        this.user = props.user;

    }

}
