import React, { useEffect, useState } from 'react';
import CcDbStats from '../../services/models/CcDbStats';
import useFetch from '../../common/useFetch';
import CmonStatService from '../../services/cmon/CmonStatService';
import DbGrowthTopDbTablesTable from './DbGrowthTopDbTablesTable';
import CcDbStatsDbTable from '../../services/models/CcDbStatsDbTable';
import SpaceWide from '../../common/SpaceWide';
import AppColumnChart from '../../common/DataDisplay/AppColumnChart';
import {
    formatMonitorValue,
    MonitorValueFormatType,
} from '../Monitor/MonitorValueFormat';
import AppSpin from '../../common/General/AppSpin';

export default DbGrowthDbDataPage;

export type DbGrowthDbTableChartDataPoint = {
    category: string;
    type: string;
    value: number;
};

export type DbGrowthDbDataPageProps = {
    clusterId: number;
    dayData?: CcDbStats;
    dbName?: string;
};

function DbGrowthDbDataPage({
    clusterId,
    dayData,
    dbName,
}: DbGrowthDbDataPageProps) {
    const [chartData, setChartData] = useState<DbGrowthDbTableChartDataPoint[]>(
        []
    );
    const { refresh, data, loading } = useFetch<CcDbStatsDbTable[]>({
        fetchFn: async () => {
            const response = await CmonStatService.getDbGrowth({
                cluster_id: clusterId,
                dayofyear: dayData?.yearday,
                db: dbName,
                include_tables: true,
                nostartdate: true,
            });
            return response.data[0].dbs[0].tables;
        },
    });

    useEffect(() => {
        (async () => {
            if (dayData && dbName) {
                await refresh({});
            }
        })();
    }, [dayData, dbName]);

    useEffect(() => {
        setChartData(
            (data || []).reduce(
                (
                    acc: DbGrowthDbTableChartDataPoint[],
                    curr: CcDbStatsDbTable
                ) => {
                    acc.push(
                        {
                            category: curr.tableName,
                            type: 'Index size',
                            value: curr.indexSize,
                        },
                        {
                            category: curr.tableName,
                            type: 'Data size',
                            value: curr.dataSize,
                        }
                    );
                    return acc;
                },
                []
            )
        );
    }, [data]);
    return (
        <AppSpin spinning={loading}>
            <SpaceWide
                className="DbGrowthDataPage"
                direction="vertical"
                size={32}
            >
                <AppColumnChart
                    data={chartData}
                    valueFormatter={(v) =>
                        formatMonitorValue?.(
                            v as number,
                            MonitorValueFormatType.SIZE
                        )
                    }
                />
                {data ? <DbGrowthTopDbTablesTable tables={data} /> : null}
            </SpaceWide>
        </AppSpin>
    );
}
