import React, { useMemo, useState } from 'react';
import WizardFormConfiguration, {
    WizardFormConfigurationProps,
} from '@severalnines/bar-frontend-components/build/lib/Navigation/Wizard/WizardFormConfiguration';
import { FormInstance } from 'antd/es';
import { Alert, Col, Form, Input, Row, Select, Space } from 'antd';
import ClusterSelectDropdown from '../../../Clusters/ClusterSelectDropdown';
import CcCluster, {
    CcClusterType,
} from '../../../../services/models/CcCluster';
import CcNode from '../../../../services/models/CcNode';
import NodeRoleFormat from '../../../Nodes/NodeRoleFormat';
import { CcBackupMethod } from '../../../../services/models/CcBackup';
import InfoIcon from '@severalnines/bar-frontend-components/build/lib/General/InfoIcon';
import FormItemInlineSwitch from '../../../../common/DataEntry/FormItemInlineSwitch';
import SpaceDescriptions from '../../../../common/Layout/SpaceDescriptions';
import YesNoFormat from '../../../../common/Format/YesNoFormat';
import {
    notifyError,
    notifyOperationSuccess,
} from '../../../Notifications/uiNotification';
import { getBackupFormRestoreJobData } from '../../Config/BackupJobConfig';
import CmonJobsService from '../../../../services/cmon/CmonJobsService';

export type BackupRestoreExternalWizardFormProps = Omit<
    WizardFormConfigurationProps,
    'steps' | 'initialValues' | 'form' | 'onSubmit'
> & {
    cluster: CcCluster;
    initialValues?: BackupRestoreExternalWizardFormValues;
    onSuccess?: () => void;
    onError?: (err: any) => void;
    form?: FormInstance;
};

enum BackupRestoreExternalWizardFormSteps {
    CONFIGURATION = 'CONFIGURATION',
    SUMMARY = 'SUMMARY',
}

export const EXTERNAL_BACKUP_RESTORE_CLUSTER_TYPES = [
    CcClusterType.TYPE_REPLICATION,
    CcClusterType.TYPE_GALERA,
];

const MARIADB_RESTORE_SUPPORTED_METHOD = [
    CcBackupMethod.MYSQLDUMP,
    CcBackupMethod.MARIABACKUP,
];
const PERCONA_RESTORE_SUPPORTED_METHOD = [
    CcBackupMethod.MYSQLDUMP,
    CcBackupMethod.XTRABACKUP,
];

const MYSQL_DUMP_SUPPORTED_EXTENSIONS = [
    'sql.gz',
    'sql.bz2',
    'aes',
    'aes256',
    'aes128',
];

const XTRABACKUP_SUPPORTED_EXTENSIONS = [
    'xbstream',
    'xbstream.gz',
    'xbstream.bz2',
    'tar.gz',
    'tar.bz2',
    'aes',
    'aes256',
    'aes128',
];

export interface BackupRestoreExternalWizardFormValues {
    clusterId?: number;
    serverAddress?: string;
    method?: string;
    sourceAddress?: string;
    backupPath?: string;
    tmpDir?: string;
    dumpSetsDatabase?: boolean;
    pitrCompatible?: boolean;
    bootstrap?: boolean;
    database?: string;
    backupDataDirBeforeRestore?: boolean;
}

function BackupRestoreExternalWizardForm({
    cluster,
    onSuccess,
    onError,
    onCancel,
    form: parentForm,
    ...rest
}: BackupRestoreExternalWizardFormProps) {
    const [localForm] = Form.useForm<BackupRestoreExternalWizardFormValues>();
    const [loading, setLoading] = useState<boolean>(false);
    const form = parentForm || localForm;
    const [currentValues, setCurrentValues] = useState<
        BackupRestoreExternalWizardFormValues
    >();

    const handleValuesChange = () => {
        setCurrentValues(form.getFieldsValue(true));
    };

    const handleSubmit = async () => {
        try {
            setLoading(true);
            const formFields = form.getFieldsValue(true);
            if (isMethodMySQLDump()) {
                formFields.bootstrap = undefined;
                formFields.backupDataDirBeforeRestore = undefined;
            } else {
                formFields.pitrCompatible = undefined;
                formFields.database = undefined;
            }
            await CmonJobsService.createRestoreBackupJobInstance(
                cluster.clusterId as number,
                {
                    job_data: getBackupFormRestoreJobData(formFields),
                },
                {
                    job: {
                        title: 'Restore Backup',
                    },
                }
            );
            notifyOperationSuccess({
                size: 'large',
                title: 'Restore backup started successfully!',
                content: (
                    <>
                        Your restore backup has started and will take some time
                        to finish up.
                    </>
                ),
                okUri: '/backup/list',
                okText: 'All Backups',
                cancelText: 'Close',
            });
            setLoading(false);
            onSuccess?.();
        } catch (err: any) {
            setLoading(false);
            if (onError) {
                onError(err);
            }
            notifyError({ content: err.message });
        }
    };

    const handleCancel = () => {
        onCancel?.();
    };

    const isMethodMySQLDump = () =>
        form.getFieldValue('method')
            ? form.getFieldValue('method') === CcBackupMethod.MYSQLDUMP
            : true;

    const isMethodXtrabackup = () =>
        form.getFieldValue('method') === CcBackupMethod.XTRABACKUP;

    const isMethodMariabackup = () =>
        form.getFieldValue('method') === CcBackupMethod.MARIABACKUP;

    const isDumpSetDatabase = () => form.getFieldValue('dumpSetsDatabase');

    const getPattern = () =>
        isMethodMySQLDump()
            ? new RegExp(`\/.+(${MYSQL_DUMP_SUPPORTED_EXTENSIONS.join('|')})$`)
            : new RegExp(`\/.+(${XTRABACKUP_SUPPORTED_EXTENSIONS.join('|')})$`);

    const steps = useMemo(() => {
        return [
            <WizardFormConfiguration.Step
                key={BackupRestoreExternalWizardFormSteps.CONFIGURATION}
                title="Configuration"
                subTitle=" "
                validate={[
                    'serverAddress',
                    'method',
                    'sourceAddress',
                    'backupPath',
                    'tmpDir',
                ]}
                hasRequiredFields={true}
            >
                <Row gutter={[24, 0]}>
                    <Col xs={24} sm={24} md={12} key="cluster">
                        <Form.Item
                            name="clusterId"
                            label={<Space>Cluster</Space>}
                            rules={[
                                {
                                    required: true,
                                    message: 'Please select cluster.',
                                },
                            ]}
                        >
                            <ClusterSelectDropdown
                                disabled={true}
                                showId={true}
                                useGlobalState={true}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} key="serverAddress">
                        <Form.Item
                            name="serverAddress"
                            label="Restore backup on"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please select host',
                                },
                            ]}
                        >
                            <Select data-testid="BackupRestoreExternalForm-serverAddress">
                                {cluster
                                    ?.getPrimaryNodes()
                                    .map((node: CcNode) => (
                                        <Select.Option
                                            value={node.getHostWithPort()}
                                            key={node.hostname}
                                        >
                                            {node.getHostWithPort()} -{' '}
                                            <NodeRoleFormat
                                                node={node}
                                            ></NodeRoleFormat>
                                        </Select.Option>
                                    ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} key="method">
                        <Form.Item
                            name="method"
                            label="Backup Method"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please select backp method',
                                },
                            ]}
                        >
                            <Select data-testid="BackupRestoreExternalForm-method">
                                {cluster?.isVendorMariaDb()
                                    ? MARIADB_RESTORE_SUPPORTED_METHOD.map(
                                          (method: CcBackupMethod) => (
                                              <Select.Option
                                                  value={method}
                                                  key={method}
                                              >
                                                  {method}
                                              </Select.Option>
                                          )
                                      )
                                    : PERCONA_RESTORE_SUPPORTED_METHOD.map(
                                          (method: CcBackupMethod) => (
                                              <Select.Option
                                                  value={method}
                                                  key={method}
                                              >
                                                  {method}
                                              </Select.Option>
                                          )
                                      )}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} key="sourceAddress">
                        <Form.Item
                            name="sourceAddress"
                            label={
                                <Space>
                                    Storage Host
                                    <InfoIcon
                                        info={
                                            <span>
                                                Select the host where the
                                                backuup file is stored
                                            </span>
                                        }
                                    />
                                </Space>
                            }
                            rules={[
                                {
                                    required: true,
                                    message: 'Please select a storage host',
                                },
                            ]}
                        >
                            <Select data-testid="BackupRestoreExternalForm-sourceAddress">
                                {[
                                    [cluster?.getControllerNode()],
                                    ...[cluster?.getDatabaseNodes()],
                                ]
                                    ?.flat()
                                    .map((node: CcNode | undefined) => (
                                        <Select.Option
                                            value={node?.hostname}
                                            key={node?.hostname}
                                        >
                                            {node?.hostname} {' - '}
                                            {node ? (
                                                <NodeRoleFormat
                                                    node={node}
                                                ></NodeRoleFormat>
                                            ) : null}
                                        </Select.Option>
                                    ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} key="backupPath">
                        <Form.Item
                            name="backupPath"
                            label={<Space>Backup Path (absolute path)</Space>}
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter backup path',
                                },
                                {
                                    pattern: getPattern(),
                                    message:
                                        'Path should be absolute and file should match supported extensions.',
                                },
                            ]}
                        >
                            <Input data-testid="BackupRestoreExternalForm-backupPath" />
                        </Form.Item>
                        Supported extensions:{' '}
                        {isMethodMySQLDump()
                            ? MYSQL_DUMP_SUPPORTED_EXTENSIONS.join(', ')
                            : XTRABACKUP_SUPPORTED_EXTENSIONS.join(', ')}
                    </Col>
                    <Col xs={24} sm={24} md={12} key="tmpDir">
                        <Form.Item
                            name="tmpDir"
                            label={
                                <Space>
                                    Temporary Directory
                                    <InfoIcon
                                        info={
                                            <span>
                                                The tmpdir is used as temporary
                                                storage area for the backup
                                                files during restore. It must be
                                                as big as the datadir
                                            </span>
                                        }
                                    />
                                </Space>
                            }
                        >
                            <Input data-testid="BackupRestoreExternalForm-tmpDir" />
                        </Form.Item>
                    </Col>

                    {isMethodMySQLDump() ? (
                        <Col xs={24} sm={24} md={24} key="dumpSetsDatabase">
                            <Form.Item>
                                <FormItemInlineSwitch
                                    justify
                                    noMargin
                                    name="dumpSetsDatabase"
                                    label={
                                        <Space>
                                            Does the dump file sets the database
                                            to restore the data into?
                                            <InfoIcon
                                                info={
                                                    <>
                                                        <div>
                                                            Dump includes
                                                            database
                                                        </div>
                                                        <span>
                                                            If the dump does NOT
                                                            include databases
                                                            then set this off.
                                                        </span>
                                                    </>
                                                }
                                            />
                                        </Space>
                                    }
                                    valuePropName="checked"
                                />
                            </Form.Item>
                        </Col>
                    ) : null}

                    {isMethodMySQLDump() && !isDumpSetDatabase() ? (
                        <Col xs={24} sm={24} md={12} key="database">
                            <Form.Item name="database" label="Database">
                                <Input data-testid="BackupRestoreExternalForm-database" />
                            </Form.Item>
                        </Col>
                    ) : null}

                    {isMethodMySQLDump() && isDumpSetDatabase() ? (
                        <Col xs={24} sm={24} md={24} key="pitrCompatible">
                            <Form.Item>
                                <FormItemInlineSwitch
                                    justify
                                    noMargin
                                    name="pitrCompatible"
                                    label={
                                        <Space>
                                            Reset master before restore?
                                            <InfoIcon
                                                info={
                                                    <>
                                                        <div>Reset Master</div>
                                                        <span>
                                                            This maybe needed if
                                                            the dumpfile
                                                            contains GTID
                                                            information.
                                                        </span>
                                                    </>
                                                }
                                            />
                                        </Space>
                                    }
                                    valuePropName="checked"
                                />
                            </Form.Item>
                        </Col>
                    ) : null}

                    {isMethodXtrabackup() || isMethodMariabackup() ? (
                        <Col xs={24} sm={24} md={12} key="bootstrap">
                            <Form.Item>
                                <FormItemInlineSwitch
                                    justify
                                    noMargin
                                    name="bootstrap"
                                    label="Bootstrap cluster from the restored node?"
                                    valuePropName="checked"
                                />
                            </Form.Item>
                        </Col>
                    ) : null}

                    {isMethodXtrabackup() || isMethodMariabackup() ? (
                        <Col
                            xs={24}
                            sm={24}
                            md={12}
                            key="backupDataDirBeforeRestore"
                        >
                            <Form.Item>
                                <FormItemInlineSwitch
                                    justify
                                    noMargin
                                    name="backupDataDirBeforeRestore"
                                    label="Make a copy of the datadir before restoringthe backup"
                                    valuePropName="checked"
                                />
                            </Form.Item>
                        </Col>
                    ) : null}
                </Row>
            </WizardFormConfiguration.Step>,
            <WizardFormConfiguration.Step
                key={BackupRestoreExternalWizardFormSteps.SUMMARY}
                title="Summary"
                subTitle=" "
                hasRequiredFields={false}
            >
                <SpaceDescriptions
                    direction="vertical"
                    title="Configuration"
                    size="small"
                    alignItems="right"
                >
                    <SpaceDescriptions.Item
                        label="Restore backup on"
                        labelStrong
                    >
                        {currentValues?.serverAddress}
                    </SpaceDescriptions.Item>

                    <SpaceDescriptions.Item label="Backup method" labelStrong>
                        {currentValues?.method}
                    </SpaceDescriptions.Item>

                    <SpaceDescriptions.Item label="Storage Host" labelStrong>
                        {currentValues?.sourceAddress}
                    </SpaceDescriptions.Item>

                    <SpaceDescriptions.Item label="Backup Path" labelStrong>
                        {currentValues?.backupPath}
                    </SpaceDescriptions.Item>

                    <SpaceDescriptions.Item
                        label="Temporary Directory"
                        labelStrong
                    >
                        {currentValues?.tmpDir}
                    </SpaceDescriptions.Item>

                    {isMethodMariabackup() ? (
                        <SpaceDescriptions.Item
                            label="Bootstrap cluster from the restored node"
                            labelStrong
                        >
                            <YesNoFormat
                                booleanVar={currentValues?.bootstrap}
                            />
                        </SpaceDescriptions.Item>
                    ) : null}

                    {isMethodMySQLDump() ? (
                        <SpaceDescriptions.Item
                            label="Does the dump file sets the database to
                        restore the data into"
                            labelStrong
                        >
                            <YesNoFormat
                                booleanVar={currentValues?.dumpSetsDatabase}
                            />
                        </SpaceDescriptions.Item>
                    ) : null}

                    {isMethodMySQLDump() && !currentValues?.dumpSetsDatabase ? (
                        <SpaceDescriptions.Item label="Database" labelStrong>
                            {currentValues?.database}
                        </SpaceDescriptions.Item>
                    ) : null}

                    {isMethodMySQLDump() && currentValues?.dumpSetsDatabase ? (
                        <SpaceDescriptions.Item
                            label="Reset master before restore"
                            labelStrong
                        >
                            <YesNoFormat
                                booleanVar={currentValues?.pitrCompatible}
                            />
                        </SpaceDescriptions.Item>
                    ) : null}

                    {isMethodXtrabackup() || isMethodMariabackup() ? (
                        <SpaceDescriptions.Item
                            label="Make a copy of the datadir before restoring the backup"
                            labelStrong
                        >
                            <YesNoFormat
                                booleanVar={
                                    currentValues?.backupDataDirBeforeRestore
                                }
                            />
                        </SpaceDescriptions.Item>
                    ) : null}
                </SpaceDescriptions>
                <Space direction="vertical">
                    {isMethodXtrabackup() || isMethodMariabackup() ? (
                        <Alert
                            message={
                                <>
                                    Cluster will be stopped when performing this
                                    action. The datadir must have enough space
                                    to accomodate the restored backup.
                                </>
                            }
                            type="warning"
                            showIcon
                        />
                    ) : null}

                    {isMethodMariabackup() ? (
                        <Alert
                            message={
                                <>
                                    <div>
                                        The following steps will be performed
                                    </div>
                                    <ol>
                                        <li>Stop all nodes in the cluster.</li>
                                        <li>
                                            Copy backup files to selected
                                            server.
                                        </li>
                                        <li>Restore the backup.</li>
                                        <li>Start the cluster.</li>
                                        <li>
                                            <b>Note:</b> An external backup must
                                            contain privileges allowing the
                                            database user 'cmon' to connect to
                                            the mysql server/all Galera nodes,
                                            or else cmon may not be able to
                                            connect and monitor/manage the
                                            database nodes.
                                        </li>
                                    </ol>
                                    <div>
                                        <b>Note:</b> Restoring PARTIAL external
                                        backups is not supported!
                                    </div>
                                </>
                            }
                            type="info"
                            showIcon
                        />
                    ) : null}

                    {isMethodMySQLDump() ? (
                        <Alert
                            message={
                                <>
                                    <div>
                                        The following steps will be performed
                                    </div>
                                    <ol>
                                        <li>
                                            The mysqldump files will be copied
                                            to the node.
                                        </li>
                                        <li>
                                            {' '}
                                            <b>WARNING!</b> If the dump file
                                            contains the mysql database, then it
                                            is <b>required</b> that the dumpfile
                                            contains the 'cmon' account and the
                                            same privileges. Else the controller
                                            cannot connect after the restore due
                                            to changed accounts/privileges.
                                        </li>
                                    </ol>
                                </>
                            }
                            type="info"
                            showIcon
                        />
                    ) : null}

                    <Alert
                        message="Restoring a NON-VERIFIED backup may cause server failures and unexpected results."
                        type="warning"
                        showIcon
                    />
                </Space>
            </WizardFormConfiguration.Step>,
        ];
    }, [form, cluster, currentValues]);

    return (
        <>
            <WizardFormConfiguration
                form={form}
                loading={loading}
                onValuesChange={handleValuesChange}
                steps={steps}
                onSubmit={handleSubmit}
                showCancelButton
                cancelButtonText="Cancel"
                onCancel={handleCancel}
                initialValues={{
                    clusterId: cluster?.clusterId,
                    serverAddress: cluster?.primaryNode?.getHostWithPort(),
                    sourceAddress: cluster?.primaryNode?.hostname,
                    method: CcBackupMethod.MYSQLDUMP,
                    backupPath: '/',
                    dumpSetsDatabase: true,
                    pitrCompatible: false,
                    bootstrap: true,
                    backupDataDirBeforeRestore: false,
                }}
                {...rest}
            />
        </>
    );
}

export default BackupRestoreExternalWizardForm;
