import React from 'react';
import TopologySummary, { TopologySummaryProps } from '../../TopologySummary';
import AppDivider from '../../../../../common/AppDivider';

export default MongoShardsDeploymentShardsSummary;

export type MongoShardsDeploymentShardsSummaryProps = TopologySummaryProps & {};

function MongoShardsDeploymentShardsSummary({
    form,
    ...props
}: MongoShardsDeploymentShardsSummaryProps) {
    const shards = form.getFieldValue('shards');
    const shardKeys: string[] = Object.keys(shards).filter(
        (k) => shards[k] !== undefined && shards[k].replicaSetName
    );
    shardKeys.sort();
    return (
        <div className="MongoShardsDeploymentShardsSummary">
            {shardKeys.map((shardKey: string) => {
                const shard = shards[shardKey];
                const perNode = shard.perNode;
                return (
                    <div key={shardKey}>
                        <TopologySummary
                            key={shardKey}
                            title={`Replica set: ${shard.replicaSetName}`}
                            topologyFieldPath={['shards', shardKey, 'topology']}
                            renderNode={(item) => {
                                const nodeProps = [];
                                const {
                                    dataIp,
                                    slaveDelay,
                                    priority,
                                    arbiter,
                                } = perNode[item.extraData.hostname] || {};
                                if (dataIp) {
                                    nodeProps.push(`data: ${dataIp}`);
                                }
                                if (item.type === 'secondary') {
                                    if (slaveDelay !== undefined && !arbiter) {
                                        nodeProps.push(
                                            `slave delay: ${slaveDelay}`
                                        );
                                    }
                                    if (
                                        priority !== undefined &&
                                        slaveDelay === 0 &&
                                        !arbiter
                                    ) {
                                        nodeProps.push(`priority: ${priority}`);
                                    }
                                    if ((item.index || 0) >= 1 && arbiter) {
                                        nodeProps.push(`act as arbiter: yes`);
                                    }
                                }

                                return (
                                    <span key={item.extraData.hostname}>
                                        {item.title}
                                        {nodeProps.length > 0
                                            ? ` (${nodeProps.join(', ')})`
                                            : ''}
                                    </span>
                                );
                            }}
                            form={form}
                            {...props}
                        />

                        <AppDivider />
                    </div>
                );
            })}
        </div>
    );
}
