import React, { useContext } from 'react';
import { ResponsiveContext } from '@severalnines/bar-frontend-components/build/lib/Layout/Responsive';
import AppEmpty from '../../../common/Feedback/AppEmpty';
import { ClusterConfigValue } from '../useClusterConfig';
import ClusterConfigValueInput from './ClusterConfigValueInput';
import { getSortAlphabeticFn } from '../../../common/sorting';
import useClusterConfigGroupedList from './useClusterConfigGroupedList';
import AppTable from '../../../common/DataDisplay/AppTable';
import AppWordHighlighter from '../../../common/Text/AppWordHighlighter';
import AppTooltip from '../../../common/Feedback/AppTooltip';
import { ConfigContext } from './ConfigContext';
import { TableColumnsType } from 'antd';

export default ClusterConfigGroupTable;
export type ClusterConfigGroupTableProps = {
    categories?: string[];
    noDescription?: boolean;
};

function ClusterConfigGroupTable({
    categories = [],
    noDescription = false,
    ...rest
}: ClusterConfigGroupTableProps) {
    const { responsive } = useContext(ResponsiveContext);

    const { searchString, changeLoading } = useContext(ConfigContext);
    const {
        filter,
        items,
        configGroupedLoading,
    } = useClusterConfigGroupedList({ categories });

    const handleTableChange = (
        pagination: any,
        currentFilter: any,
        sorters: any
    ) => {
        let sorterFn: any = null;
        if (sorters.order) {
            switch (sorters.columnKey) {
                case 'parameter':
                    sorterFn = getSortAlphabeticFn(
                        sorters.order,
                        (x: ClusterConfigValue) => x.name
                    );
                    break;
                case 'value':
                    sorterFn = getSortAlphabeticFn(
                        sorters.order,
                        (x: ClusterConfigValue) => `${x.current_value}`
                    );
                    break;
            }
        }
        filter({ order: sorterFn });
    };

    const columns: TableColumnsType = [
        {
            title: 'Parameter',
            key: 'parameter',
            width: 250,
            sorter: true,
            defaultSortOrder: 'ascend',
            sortDirections: ['ascend', 'descend', 'ascend'],
            render: (record: ClusterConfigValue) => (
                <AppWordHighlighter
                    searchWords={[searchString || '']}
                    textToHighlight={record.name}
                />
            ),
        },
        {
            title: 'Value',
            key: 'value',
            width: 250,
            sorter: true,
            ellipsis: { showTitle: true },
            render: (record: ClusterConfigValue) => (
                <ClusterConfigValueInput value={record} />
            ),
        },
    ];
    if (!noDescription) {
        columns.push({
            title: 'Description',
            key: 'description',
            ellipsis: true,
            render: (record: ClusterConfigValue) => (
                <AppTooltip size="large" title={record.description}>
                    <AppWordHighlighter
                        searchWords={[searchString || '']}
                        textToHighlight={record.description}
                    />
                </AppTooltip>
            ),
        });
    }

    return (
        <AppTable
            rowKey={(record: ClusterConfigValue) => record.name}
            loading={configGroupedLoading && !changeLoading}
            dataSource={items}
            columns={columns}
            responsive={responsive}
            pagination={false}
            onRow={() => ({})}
            onChange={handleTableChange}
            renderEmpty={
                <AppEmpty
                    loading={configGroupedLoading && !changeLoading}
                    description="You haven’t received alarms yet. When you do, it'll show up here."
                />
            }
        />
    );
}
