import React from 'react';
import './AppTable.less';
import classNames from 'classnames';
import ResponsiveTable, {
    ResponsiveTableProps,
} from '@severalnines/bar-frontend-components/build/lib/DataDisplay/ResponsiveTable';
import { AppConfig } from '../../AppConfig';
import AppSpin from '../General/AppSpin';
import { Skeleton } from 'antd';
import AppEmpty from '../Feedback/AppEmpty';

export default AppTable;

export type AppTableProps = Omit<
    ResponsiveTableProps,
    'dataSource' | 'onRow'
> & {
    renderEmpty?: React.ReactNode;
    loaded?: boolean;
    dataSource?: ResponsiveTableProps['dataSource'] | null;
    onRow?: ResponsiveTableProps['onRow'];
    // onChange?: ResponsiveTableProps['onChange'];
};

function AppTable({
    className,
    dataSource,
    renderEmpty = <AppEmpty />,
    loading,
    pagination,
    loaded = true,
    locale,
    onRow = () => ({}),
    ...rest
}: AppTableProps) {
    const data = dataSource || undefined;
    const content = (
        <ResponsiveTable
            dataSource={data}
            className={classNames('AppTable', className)}
            pagination={{
                ...AppConfig.defaultPaginationConfig,
                ...pagination,
            }}
            locale={{ emptyText: renderEmpty, ...locale }}
            onRow={onRow}
            {...rest}
            /*{ if total is 0 then we hide footer }*/
            {...(pagination?.total === 0 ? { footer: undefined } : {})}
        />
    );
    return (
        <AppSpin spinning={!!loading}>
            {loaded && data !== undefined ? (
                content
            ) : data === undefined || loading || !loaded ? (
                <Skeleton />
            ) : (
                renderEmpty
            )}
        </AppSpin>
    );
}
