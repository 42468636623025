import React from 'react';
import SshCheckableNodesInput, {
    SshCheckableNodesInputProps,
} from './SshCheckeableNodesInput';

export default HostCheckInput;

export type HostCheckInputProps = SshCheckableNodesInputProps & {
    placeholder?: string;
};

function HostCheckInput({ placeholder, ...rest }: HostCheckInputProps) {
    return (
        <SshCheckableNodesInput
            singleNode={true}
            emptyState={null}
            direction="vertical"
            hideFormOnAdded={true}
            formProps={{
                primaryPlaceholder: placeholder,
                primaryTitle: null,
                primaryFormItemProps: {
                    withLessMarginBottom: true,
                },
            }}
            {...rest}
        />
    );
}
