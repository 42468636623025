
import CcNode, { CcNodeProps } from '../../models/CcNode';


export interface CmonPBMAgentHostProps  extends CcNodeProps {
    backup_dir?: string;
    mongo_hostname?: string;
    mongo_port?: number;
    os_user?: string;
    service_name?: string;

}



export default class CmonPBMAgentHost extends CcNode {
    public backupDir?: string;
    public mongoHostname?: string;
    public mongoPort?: number;
    public osUser?: string;
    public serviceName?: string;


    constructor(props: CmonPBMAgentHostProps) {
        super(props);
        this.backupDir = props.backup_dir;
        this.mongoHostname = props.mongo_hostname;
        this.mongoPort = props.mongo_port;
        this.osUser = props.os_user;
        this.serviceName = props.service_name;

    }

}
