import React from 'react';
import { Form, Space } from 'antd';
import InfoIcon from '@severalnines/bar-frontend-components/build/lib/General/InfoIcon';
import FormItemInlineSwitch, {
    FormItemInlineSwitchProps,
} from '../../DataEntry/FormItemInlineSwitch';
import InputNumberWide from '../../DataEntry/InputNumberWide';

export default DelayReplicaField;

export type DelayReplicaFieldProps = FormItemInlineSwitchProps & {};

function DelayReplicaField({ ...rest }: DelayReplicaFieldProps) {
    return (
        <div className="DelayReplicaField">
            <FormItemInlineSwitch
                justify={true}
                name="delayReplica"
                label={
                    <Space>
                        Delay the replica node
                        <InfoIcon
                            info={
                                <span>
                                    Only for MySQL 5.6 and later. A delayed
                                    slave can be useful as a Disaster Recovery
                                    slave in case an accidental DML/DDL is
                                    executed on the master.
                                </span>
                            }
                        />
                    </Space>
                }
                valuePropName="checked"
                extraOnSwitch={
                    <Form.Item
                        name="delayReplicaSeconds"
                        label="Delay in seconds"
                    >
                        <InputNumberWide placeholder="Enter delay in seconds" />
                    </Form.Item>
                }
                {...rest}
            />
        </div>
    );
}
