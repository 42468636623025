import React, { useMemo } from 'react';
import { ButtonProps } from 'antd/lib/button';
import { CmonJobInstanceCommand } from '../../../services/cmon/models/CmonJobInstance';
import CcNode from '../../../services/models/CcNode';
import JobConfirmActionButton from '../../Jobs/JobConfirmActionButton';

export default NodeRebootHostButton;

export type NodeRebootHostButtonProps = ButtonProps & {
    node: CcNode;
    children?: React.ReactNode;
    onSuccess?: () => void;
};

function NodeRebootHostButton({
    node,
    children,
    onSuccess,
    ...rest
}: NodeRebootHostButtonProps) {
    const jobData = useMemo(
        () => ({
            hostname: node.hostname,
        }),
        []
    );
    return (
        <JobConfirmActionButton
            clusterId={node.clusterid}
            command={CmonJobInstanceCommand.REBOOT}
            confirmTitle={`Reboot host: ${node.getName()}?`}
            title={`Reboot host`}
            jobData={jobData}
            onSuccess={onSuccess}
            extraKey={[node.hostname, node.port]}
            {...rest}
        />
    );
}
