import React, { useEffect, useState } from 'react';
import StatisticBadge, {
    StatisticBadgeProps,
} from '../../common/StatisticBadge';
import { AppState } from '../../appReducer';
import { useSelector } from 'react-redux';
import { getLogEntrySeverityFormatType } from './LogEntrySeverityFormat';
import useLogStats from './useLogStats';
import { CcLogEntrySeverity } from '../../services/models/CcLogEntry';

export default CmonLogBadge;

export type CmonLogBadgeProps = StatisticBadgeProps & {
    clusterId?: number;
};

function CmonLogBadge({ clusterId, ...rest }: CmonLogBadgeProps) {
    const [lastSeenLog]: [Date] = useSelector(({ userUIConfig }: AppState) => [
        userUIConfig.getIn(['notifications', 'lastSeenLog']),
    ]);
    const [logWarnings, setLogWarnings] = useState<number>();
    const [logErrors, setLogErrors] = useState<number>();

    const { record: statsRecord, refresh } = useLogStats({
        useGlobalState: !clusterId,
    });
    useEffect(() => {
        (async () => {
            if (clusterId) {
                setLogErrors(0);
                setLogWarnings(0);
                await refresh({ cluster_id: clusterId });
            }
        })();
    }, [clusterId]);

    useEffect(() => {
        if (statsRecord) {
            // if last seen log is from 30 secs ago, then we show 0 count and we expect the next request
            // of logs to update the real number of new logs
            setLogWarnings(
                new Date().getTime() - lastSeenLog.getTime() > 30000
                    ? statsRecord.getWarnings()
                    : 0
            );
            setLogErrors(
                new Date().getTime() - lastSeenLog.getTime() > 30000
                    ? statsRecord.getErrors()
                    : 0
            );
        }
    }, [statsRecord, lastSeenLog]);

    return (
        <StatisticBadge
            count={logErrors || logWarnings || 0}
            dot={false}
            status={
                logErrors
                    ? getLogEntrySeverityFormatType(CcLogEntrySeverity.LOG_ERR)
                    : logWarnings
                    ? getLogEntrySeverityFormatType(
                          CcLogEntrySeverity.LOG_WARNING
                      )
                    : getLogEntrySeverityFormatType(CcLogEntrySeverity.LOG_INFO)
            }
            title={`${
                logErrors || logWarnings || 0
            } new error or warning logs}`}
            {...rest}
        />
    );
}
