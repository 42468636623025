import React from 'react';
import { Space } from 'antd';
import CurrentUserMenu from '../components/User/CurrentUserMenu';
import CcUser from '../services/models/CcUser';

export default DebugCurrentUserMenu;
export type DebugCurrentUserMenuProps = {};

function DebugCurrentUserMenu({ ...rest }: DebugCurrentUserMenuProps) {
    return (
        <div className="DebugCurrentUserMenu">
            <Space direction="vertical">
                <CurrentUserMenu
                    user={
                        new CcUser({
                            first_name: 'First',
                            last_name: 'Last',
                            email_address: 'first@last.club',
                        })
                    }
                    country="th"
                />
                <CurrentUserMenu
                    user={
                        new CcUser({
                            first_name: 'Wilson',
                            last_name: 'Ball',
                            email_address: 'willson@island.ocean',
                        })
                    }
                    country="th"
                />
            </Space>
        </div>
    );
}
