import React, { useEffect } from 'react';
import useSnapshotRepositoryList from '../../components/SnapshotRepository/useSnapshotRepositoryList';
import { Alert, SelectProps, Space } from 'antd';
import { getSnapshotRepositoryIcon } from '../../components/SnapshotRepository/SnapshotRepositoryType';
import SpaceWide from '../SpaceWide';
import SelectWrap from './SelectWrap';
import TypographyText from '../TypographyText';
import SnapshotRepositoryCreateModal from '../../components/Backups/Repository/SnapshotRepositoryCreateModal';
import { ElasticSnapshotRepositoryFormValues } from '../../components/Backups/Repository/ElasticSnapshotRepositoryFormWizard';
import useCurrentCluster from '../../components/Clusters/useCurrentCluster';
import AppSpin from '../General/AppSpin';

export default SnapshotRepositorySelect;
export type SnapshotRepositorySelectProps = SelectProps & {
    clusterId: number;
};

function SnapshotRepositorySelect({
    clusterId,
    onChange,
    value,
    ...rest
}: SnapshotRepositorySelectProps) {
    const { data, refresh, loading, loaded } = useSnapshotRepositoryList({
        clusterId,
    });
    const cluster = useCurrentCluster({ clusterId });
    useEffect(() => {
        (async () => {
            await refresh({});
        })();
    }, [clusterId]);

    const handleRepositoryCreated = async ({
        name,
    }: ElasticSnapshotRepositoryFormValues) => {
        await refresh({});
        if (name) {
            onChange?.(name, { value: name, label: name });
        }
    };
    return (
        <AppSpin spinning={loading}>
            <SpaceWide size={10} direction="vertical">
                <SelectWrap
                    selectFirst={loaded}
                    value={(loaded && value) || undefined}
                    loading={loading}
                    onChange={onChange}
                    addOptionForm={
                        <SnapshotRepositoryCreateModal
                            onSuccess={handleRepositoryCreated}
                            cluster={cluster}
                        />
                    }
                    addOptionLabel={
                        <TypographyText type="secondary">
                            Create new repository
                        </TypographyText>
                    }
                    options={
                        data?.map((repository) => ({
                            label: (
                                <Space>
                                    {getSnapshotRepositoryIcon(repository.type)}
                                    <span>{repository.name}</span>
                                </Space>
                            ),
                            value: repository.name,
                        })) || []
                    }
                    {...rest}
                />
                {loaded && data && data.length < 1 && (
                    <Alert
                        type="warning"
                        message={
                            <span>
                                No snapshot repositories found please create one
                                first
                            </span>
                        }
                    />
                )}{' '}
            </SpaceWide>
        </AppSpin>
    );
}
