
export enum CmonFeature {
    AUTO_RECOVER_CLUSTER = 'AUTO_RECOVER_CLUSTER',
    AUTO_RECOVER_NODE = 'AUTO_RECOVER_NODE',
    PROMOTE_SLAVE = 'PROMOTE_SLAVE',
    DEMOTE_NODE = 'DEMOTE_NODE',
    START_FAILED_NODE = 'START_FAILED_NODE',
    FAILOVER_REPLICATION_SLAVE = 'FAILOVER_REPLICATION_SLAVE',
    RESLAVE_OLD_MASTER_JOB = 'RESLAVE_OLD_MASTER_JOB',
    FAILOVER_JOB = 'FAILOVER_JOB',
    RECOVER_SINGLE_NODE_JOB = 'RECOVER_SINGLE_NODE_JOB',
    RECOVER_SLAVES_JOB = 'RECOVER_SLAVES_JOB',
    RECOVER_MASTER_JOB = 'RECOVER_MASTER_JOB',
    ALARM_INIT_SNMP_TRAP_EXECUTOR = 'ALARM_INIT_SNMP_TRAP_EXECUTOR',
    ADD_NODE = 'ADD_NODE',
    CONVERT_TO_SHARDS = 'CONVERT_TO_SHARDS',
    ADD_SHARD = 'ADD_SHARD',
    REMOVE_SHARD = 'REMOVE_SHARD',
    REMOVE_NODE = 'REMOVE_NODE',
    /**
     *  \param closeJob argument: CmonWorkflow::restoreBackup may also used by
     *    CmonCommandHandlerWorker in createCluster code, in case of create cluster
     *    from backup, job must not be closed then.
     * 
     *  This function will perform the restore backup job. Well, actually the
     *  restoring will be done by the cluster class and that code will also decide if
     *  the cluster should be stopped or not. Previously we had a job argument about
     *  that, but now we have none of those.
     */
    RESTORE_BACKUP = 'RESTORE_BACKUP',
    START_BACKUP = 'START_BACKUP',
    /**
     *  \param report The requested report, an object with some of the properties
     *    pre-set to represent what kind of report should be created (like report
     *    type or owner user).
     *  \param errorString A place to return error messages.
     *  \param clusterIds Some reports are about multiple clusters...
     *  \returns True if the report was successfully created.
     * 
     *  This method will create and save a report, then it will send it to the
     *  recipients by email (if recipients were provided). This is the new variant of
     *  the function that is called from RPC v2.
     * 
     *  NOTE: This is the new way of making a report: the reporter is kind of static,
     *  can not hold information about any specific report, so we create a report
     *  object in advance, pass it, and the reporter fills in the data. The report
     *  passed here hold information about what the caller want and at the end it
     *  contains the full report.
     */
    GENERATE_REPORT = 'GENERATE_REPORT',
    LDAP = 'LDAP',
    ADD_REPLICATION_SLAVE = 'ADD_REPLICATION_SLAVE',
    /**
     *  The command handler worker calls this function to perform the "verify_backup"
     *  job. Currently not called from any other places.
     */
    VERIFY_BACKUP = 'VERIFY_BACKUP',
    /**
     *  \param status This is 0 if the backup was created, non-zero on error.
     * 
     *  Sends an email about a backup creation ended.
     */
    SEND_EMAIL_ON_BACKUP_CREATED = 'SEND_EMAIL_ON_BACKUP_CREATED',
    /**
     *  Sends an email about a restore and verification.
     */
    SEND_EMAIL_ON_BACKUP_RESTORED = 'SEND_EMAIL_ON_BACKUP_RESTORED',
    /**
     *  Executes the encrypt_replication job that will either enable or disable the
     *  encryption in replication.
     */
    ENCRYPT_REPLICATION = 'ENCRYPT_REPLICATION',
    SETUP_SSL = 'SETUP_SSL',
    PURGE_QUERY_MONITOR = 'PURGE_QUERY_MONITOR',
    SETUP_AUDIT_LOGGING = 'SETUP_AUDIT_LOGGING',
    ENABLE_DISABLE_CLUSTER_READONLY = 'ENABLE_DISABLE_CLUSTER_READONLY',
    CLUSTER_RECOVERY = 'CLUSTER_RECOVERY',
    CHECK_PKG_UPGRADES = 'CHECK_PKG_UPGRADES',
    /**
     *  This private method is called from autoRecover() to recover a cluster.
     */
    GALERA_AUTO_RECOVER_CLUSTER = 'GALERA/AUTO_RECOVER_CLUSTER',
    GALERA_AUTO_RECOVER_NODE = 'GALERA/AUTO_RECOVER_NODE',
    REPLICATION_PROMOTE_SLAVE = 'REPLICATION/PROMOTE_SLAVE',
    /**
     *  This methods demotes a M/M to a M/S
     *  Thus the node being demoted must have:
     *  - => slaves connected
     *  - it must also be a slave.
     *  And it is the slave part of itself that will be cleared.
     */
    REPLICATION_DEMOTE_NODE = 'REPLICATION/DEMOTE_NODE',
    /**
     *  This function is used to start (restart) the mysql daemon on a failed node.
     */
    REPLICATION_START_FAILED_NODE = 'REPLICATION/START_FAILED_NODE',
    /**
     *  Only works for GTID replication.
     *  Mariadb has its own inferior GTID design: 
     *      only way to get it stable was to down in changeMaster(..):
     *           - lock the master
     *           - get the binlog file, pos from the master
     *           - unlock, 
     *           - calculate the mariadb gtid, 
     *           - set it,
     *           - change master, and start the replication again
     *          
     *  For Oracle MySQL and Percona there are no known issues.
     */
    MYSQL_FAILOVER_REPLICATION_SLAVE = 'MYSQL/FAILOVER_REPLICATION_SLAVE',
    /**
     *  This function will execute a 'failover_replication_slave' job, a job that
     *  makes a slave to take over the roles of a master node that failed. This
     *  method is similar to the failoverJob(), but there are differences.
     * 
     *  - master_address: The new master, a slave that will take over. 
     *  - hostname/node: This is ignored.
     */
    POSTGRESQL_FAILOVER_REPLICATION_SLAVE = 'POSTGRESQL/FAILOVER_REPLICATION_SLAVE',
    POSTGRESQL_RESLAVE_OLD_MASTER_JOB = 'POSTGRESQL/RESLAVE_OLD_MASTER_JOB',
    /**
     *  This method will open, execute and close a job to take over the master status
     *  from a failed master node. Initiating a failover process is as simple as
     *  creating a file that we use as trigger.
     */
    POSTGRESQL_FAILOVER_JOB = 'POSTGRESQL/FAILOVER_JOB',
    /**
     *  Single node recovery, if we can't connect to the single node
     *  and the postgres process is died here, this method
     *  will "recovers" the node by restarting it.
     * 
     *  Change replication primary
     */
    POSTGRESQL_RECOVER_SINGLE_NODE_JOB = 'POSTGRESQL/RECOVER_SINGLE_NODE_JOB',
    /**
     *  Slave recovery, if we can't connect to a slave, and the postgres process is
     *  died here, this method will "recovers" the slaves by restarting them.
     */
    POSTGRESQL_RECOVER_SLAVES_JOB = 'POSTGRESQL/RECOVER_SLAVES_JOB',
    /**
     *  FIX by csjpeter: Lets promote the specified server even if
     *                   we can not found any master for the cluster.
     *                   Specially in such situation, when all nodes
     *                   are slave we need a way to fix up the cluster
     *                   to have at least a working master.
     */
    POSTGRESQL_PROMOTE_SLAVE = 'POSTGRESQL/PROMOTE_SLAVE',
    /**
     *  When cluster recovery is enabled, and cmon sees all hosts down (and there is
     *  only one master), this method is going to start the master.
     */
    POSTGRESQL_RECOVER_MASTER_JOB = 'POSTGRESQL/RECOVER_MASTER_JOB',
    POSTGRESQL_ALARM_INIT_SNMP_TRAP_EXECUTOR = 'POSTGRESQL/ALARM_INIT_SNMP_TRAP_EXECUTOR',
    /**
     *  @param job with the node information to be added on the cluster
     *  @return true if nodes were successfully added to the cluster
     *  Note: this method should remain similar to createCluster except
     *  for the specific actions for adding the node
     */
    ELASTIC_ADD_NODE = 'ELASTIC/ADD_NODE',
    MONGODB_CONVERT_TO_SHARDS = 'MONGODB/CONVERT_TO_SHARDS',
    MONGODB_ADD_SHARD = 'MONGODB/ADD_SHARD',
    /**
     *  https://docs.mongodb.com/manual/tutorial/expand-replica-set/
     */
    MONGODB_ADD_NODE = 'MONGODB/ADD_NODE',
    /**
     *  https://docs.mongodb.com/manual/tutorial/remove-shards-from-cluster/
     */
    MONGODB_REMOVE_SHARD = 'MONGODB/REMOVE_SHARD',
    MONGODB_REMOVE_NODE = 'MONGODB/REMOVE_NODE',
    /**
     *  https://docs.mongodb.com/manual/tutorial/restore-sharded-cluster/#restore-sh-cl-dmp
     *  https://github.com/Percona-Lab/mongodb_consistent_backup
     */
    MONGODB_RESTORE_BACKUP = 'MONGODB/RESTORE_BACKUP',
    MONGODB_START_BACKUP = 'MONGODB/START_BACKUP',
    /**
     *  failoverCheck
     *  If current master has failed, this method will
     *  elect a new one and does failover to it if
     *  cluster auto-recovery is enabled.
     */
    MSSQL_AO_ASYNC_FAILOVER_JOB = 'MSSQL_AO_ASYNC/FAILOVER_JOB',
    MSSQL_AO_ASYNC_PROMOTE_SLAVE = 'MSSQL_AO_ASYNC/PROMOTE_SLAVE',
    PROXY_SQL_START_BACKUP = 'PROXY_SQL/START_BACKUP',
    PROXY_SQL_RESTORE_BACKUP = 'PROXY_SQL/RESTORE_BACKUP',
    /**
     *  License check, add-replication-slave in case of Galera cluster
     *      requires a license [http://52.58.107.236/bugzilla/show_bug.cgi?id=1167]
     */
    GALERA_ADD_REPLICATION_SLAVE = 'GALERA/ADD_REPLICATION_SLAVE',
}
