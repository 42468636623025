import React, { useMemo } from 'react';
import './PermissionLevelSelect.less';
import { Select, SelectProps } from 'antd';
import {
    CcTreeItemAccess,
    getLevelsBelow,
} from '../../services/models/CcTreeItem';
import { getTreeItemAccessDescription, getTreeItemAccessText } from './useTree';
import { DefaultOptionType } from 'rc-select/lib/Select';
import TypographyText from '../../common/TypographyText';

export default PermissionLevelSelect;

export type PermissionLevelType = CcTreeItemAccess | 'custom';

export type PermissionLevelSelectProps = SelectProps & {
    value?: string;
    onChange?: (
        value: PermissionLevelType | PermissionLevelType[] | undefined
    ) => void;
    noCustom?: boolean;
    maxLevel?: CcTreeItemAccess;
    showDescription?: boolean;
};

function PermissionLevelSelect({
    value,
    onChange,
    loading,
    disabled,
    noCustom,
    maxLevel,
    showDescription,
    ...rest
}: PermissionLevelSelectProps) {
    const permissionLevelOptions: DefaultOptionType[] = useMemo(
        () => [
            ...getLevelsBelow(maxLevel).map((l) => ({
                title: getTreeItemAccessText(l),
                label: showDescription ? (
                    <div>
                        <span>{getTreeItemAccessText(l)}</span>
                        <br />
                        <small>
                            <TypographyText muted={true}>
                                {getTreeItemAccessDescription(l)}
                            </TypographyText>
                        </small>
                    </div>
                ) : (
                    getTreeItemAccessText(l)
                ),
                value: l,
            })),
            ...(noCustom
                ? []
                : [
                      {
                          title: 'Custom',
                          label: showDescription ? (
                              <div>
                                  <div>Custom</div>
                                  <small>
                                      <TypographyText muted={true}>
                                          Apply specific permission level for
                                          each cluster.
                                      </TypographyText>
                                  </small>
                              </div>
                          ) : (
                              'Custom'
                          ),
                          value: 'custom',
                      },
                  ]),
        ],
        []
    );

    const handleOnChange = (
        value: PermissionLevelType | PermissionLevelType[] | undefined
    ) => {
        onChange?.(value);
    };

    return (
        <Select
            className="PermissionLevelSelect"
            style={{ width: '100%' }}
            onChange={handleOnChange}
            value={value}
            loading={loading}
            disabled={disabled}
            options={permissionLevelOptions}
            optionLabelProp="title"
            dropdownClassName="PermissionLevelSelect_dropdown"
            listHeight={400}
            {...rest}
        />
    );
}
