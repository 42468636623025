import React, { useEffect } from 'react';
import useServiceMock from './serviceMock/useServiceMock';
import { ServiceMethod } from './serviceMock/ServiceMockContext';
import LdapMapGroupButton from '../components/UserManagement/Ldap/LdapMapGroupButton';
import useGroupList from '../components/UserManagement/useGroupList';

export default DebugLdapMapGroupButton;

export type DebugLdapMapGroupButtonProps = {};

function DebugLdapMapGroupButton({}: DebugLdapMapGroupButtonProps) {
    useServiceMock({
        mockId: 'ldap-map-group',
        methods: [
            ServiceMethod.GET_LDAP_CONFIG,
            ServiceMethod.SET_LDAP_CONFIG,
            ServiceMethod.USER_GET_GROUPS,
        ],
    });
    const { refresh } = useGroupList({
        useCache: false,
    });
    useEffect(() => {
        (async () => {
            await refresh();
        })();
    }, []);
    return (
        <div className="DebugLdapMapGroupButton">
            <LdapMapGroupButton />
        </div>
    );
}
