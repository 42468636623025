import React from 'react';
import ClusterNodeSelect, {
    ClusterNodeSelectProps,
} from '../../common/DataEntry/ClusterNodeSelect';

export default QueryMonitorNodeSelect;

export type QueryMonitorNodeSelectProps = ClusterNodeSelectProps & {};

function QueryMonitorNodeSelect({
    cluster,
    onChange,
    value,
    showAllOption = true,
    primaryCandidatesOnly = false,
    ...rest
}: QueryMonitorNodeSelectProps) {
    return (
        <ClusterNodeSelect
            cluster={cluster}
            databaseNodes={true}
            onChange={onChange}
            extractNodeValue={(node) =>
                `${node.hostId}:${node.getHostWithPort()}`
            }
            value={value}
            showAllOption={showAllOption}
            {...rest}
            primaryCandidatesOnly={primaryCandidatesOnly}
        />
    );
}
