import MysqlConfigurator, { MysqlFormValues } from '../Mysql/MysqlConfigurator';
import { TopologyItem } from '../../../Topology/TopologyItem';
import { ClusterConfiguratorVendor } from '../ClusterConfigurator';

export interface MysqlReplicationFormValues extends MysqlFormValues {
    useMultiPrimary?: boolean;
    topologyMultiPrimary?: TopologyItem[];
}
export default class MysqlReplicationConfigurator extends MysqlConfigurator {
    public static getVendors(): ClusterConfiguratorVendor[] {
        return [
            ...MysqlConfigurator.getVendors(),
            {
                name: 'Oracle',
                value: 'oracle',
                versions: [
                    { name: '8.0', value: '8.0' },
                    { name: '5.7', value: '5.7' },
                ],
            },
        ];
    }
}
