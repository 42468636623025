import React from 'react';
import AppConfirmActionButton from '../../common/General/AppConfirmActionButton';
import {
    notifyError,
    notifyOperationSuccess,
    NotifyType,
} from '../Notifications/uiNotification';
import { useDebugContext } from '../../common/Debug';
import CmonReportsService from '../../services/cmon/CmonReportsService';
import { ConfirmActionButtonProps } from '@severalnines/bar-frontend-components/build/lib/General/ConfirmActionButton';
import CcScheduleReport from '../../services/models/CcScheduleReport';

export default OperationalReportScheduleDeleteButton;

export type OperationalReportScheduleDeleteButtonProps = ConfirmActionButtonProps & {
    schedule: CcScheduleReport;
    onSuccess?: () => void;
};

function OperationalReportScheduleDeleteButton({
    schedule,
    onSuccess,
    children,
    ...rest
}: OperationalReportScheduleDeleteButtonProps) {
    const { log } = useDebugContext();

    const handleConfirm = async () => {
        try {
            await CmonReportsService.removeSchedule({
                id: schedule.id,
            });
            notifyOperationSuccess({
                type: NotifyType.TOAST,
                title: 'Success!',
                content: 'Schedule successfully deleted',
            });
            onSuccess?.();
        } catch (e) {
            notifyError({ content: e.message });
            log.error(e);
        }
    };

    return (
        <AppConfirmActionButton
            critical={true}
            confirmTitle="You want to remove this schedule?"
            onConfirm={handleConfirm}
            {...rest}
        >
            {'Delete'}
        </AppConfirmActionButton>
    );
}
