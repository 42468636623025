import React, { useState } from 'react';
import AuthWrapper from './AuthWrapper';
import { Alert, Button, Checkbox, Form, Input, Space } from 'antd';
import { Link } from 'react-router-dom';
import useForm from 'antd/lib/form/hooks/useForm';
import './RegistrationShort.less';
import CmonUsersService from '../../services/cmon/CmonUsersService';
import CcUser from '../../services/models/CcUser';
import { AppConfig } from '../../AppConfig';
import CmonTreeService from '../../services/cmon/CmonTreeService';
import { useDebugContext } from '../../common/Debug';
import CryptoJS from 'crypto-js';
import RegistrationLicenseFailed from './RegistrationLicenseFailed';
import { requestCMONVersion } from './Registration';

type RegistrationShortFieldsType = {
    username: string;
    password: string;
    email: string;
    confirmPassword: string;
    terms: boolean;
    trial: boolean;
};

export type RegistrationShortProps = {
    user: CcUser;
};

function RegistrationShort({ user }: RegistrationShortProps) {
    const [loading, setLoading] = useState<boolean>(false);
    const [form] = useForm<RegistrationShortFieldsType>();
    const [error, setError] = useState<any | null>(null);
    const [showLicenseError, setShowLicenseError] = useState<boolean>(false);
    const { log } = useDebugContext();
    const handleSubmit = async (values: RegistrationShortFieldsType) => {
        setLoading(true);

        try {
            await CmonUsersService.updateUser({
                new_password: values.confirmPassword,
                user: {
                    class_name: 'CmonUser',
                    user_name: values.username,
                    user_id: user?.userId,
                    email_address: values.email,
                    groups: user.groups,
                    timezone: user.timezone,
                },
            });
        } catch (err) {
            setLoading(false);
            setError(err);
            log.error(err);
        }

        try {
            if (values.trial) {
                const cmonVersion = await requestCMONVersion();
                const response = await fetch(
                    `/cc-license?name=${
                        values.username
                    }&uuid=${CryptoJS.MD5(values.email)}&email=${
                        values.email
                    }&extra=,,${cmonVersion}`
                );

                const data = await response.json();

                await CmonTreeService.setcontent({
                    content: data.lickey,
                    path: '/.runtime/cmon_license',
                });
            }
            window.location.assign(`/dashboard`);
        } catch (err) {
            setLoading(false);
            log.error(err);
            setShowLicenseError(true);
        }
    };

    const handleCancel = () => {
        window.location.assign(`/logout`);
    };

    async function passwordMatchValidator(rule: any, value: string) {
        if (form.getFieldValue('password') !== value) {
            throw new Error("Password doesn't match!");
        }
        return true;
    }

    async function termsValidator(rule: any, value: string) {
        if (!value) {
            throw new Error(
                'Please read and agree to the terms and conditions, and privacy policy!'
            );
        }
        return true;
    }

    async function userNameValidator(rule: any, value: string) {
        if (value === AppConfig.INITIAL_USER) {
            throw new Error('Username not allowed');
        }
        return true;
    }

    return (
        <>
            {showLicenseError ? (
                <div className="RegistrationLicenseFailed">
                    <AuthWrapper>
                        <RegistrationLicenseFailed />
                    </AuthWrapper>
                </div>
            ) : (
                <div className="RegistrationShortPageContainer">
                    <AuthWrapper>
                        <div className="RegistrationPage">
                            <p className="RegistrationPageTitle">Create User</p>
                            <Form
                                className="RegistrationPageForm"
                                form={form}
                                layout="vertical"
                                onFinish={handleSubmit}
                                initialValues={{
                                    email: user?.emailAddress,
                                    trial: true,
                                    terms: false,
                                }}
                            >
                                <Form.Item
                                    data-testid="userNameField"
                                    label="Username"
                                    name="username"
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                'Please enter your user name!',
                                        },
                                        {
                                            validator: userNameValidator,
                                        },
                                    ]}
                                >
                                    <Input
                                        maxLength={100}
                                        placeholder="Enter username"
                                    />
                                </Form.Item>
                                <Form.Item
                                    data-testid="emailField"
                                    label="Email"
                                    name="email"
                                    rules={[
                                        {
                                            type: 'email',
                                            required: true,
                                            message:
                                                'Please input your email address!',
                                        },
                                    ]}
                                >
                                    <Input
                                        maxLength={100}
                                        disabled={!!user?.emailAddress}
                                        placeholder="Enter an email address"
                                    />
                                </Form.Item>
                                <Form.Item
                                    data-testid="passwordField"
                                    label="New password"
                                    name="password"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Enter the new password!',
                                        },
                                    ]}
                                >
                                    <Input.Password
                                        maxLength={100}
                                        placeholder="Enter a new admin password"
                                    />
                                </Form.Item>

                                <Form.Item
                                    data-testid="reenterPasswordField"
                                    label="Confirm new password"
                                    name="confirmPassword"
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                'Re-enter the new password!',
                                        },
                                        {
                                            validator: passwordMatchValidator,
                                        },
                                    ]}
                                >
                                    <Input.Password
                                        maxLength={100}
                                        placeholder="Reenter the admin password"
                                    />
                                </Form.Item>
                                <Form.Item
                                    data-testid="trial"
                                    id="trial"
                                    name="trial"
                                    valuePropName="checked"
                                >
                                    <Checkbox data-testid="trial">
                                        Enable 30 days free Enterprise trial
                                    </Checkbox>
                                </Form.Item>
                                <Form.Item
                                    data-testid="terms"
                                    id="terms"
                                    name="terms"
                                    valuePropName="checked"
                                    rules={[
                                        {
                                            validator: termsValidator,
                                        },
                                    ]}
                                >
                                    <Checkbox data-testid="privacy">
                                        I've read and agree to the&nbsp;
                                        <Link to="/agreement" target="_blank">
                                            Terms and Conditions
                                        </Link>
                                        , and&nbsp;
                                        <Link to="/agreement" target="_blank">
                                            Privacy Policy
                                        </Link>
                                    </Checkbox>
                                </Form.Item>
                                <Form.Item
                                    style={{
                                        marginTop: '10px',
                                        textAlign: 'right',
                                    }}
                                >
                                    <Space>
                                        <Button
                                            loading={loading}
                                            type="default"
                                            htmlType="button"
                                            onClick={handleCancel}
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            loading={loading}
                                            type="primary"
                                            htmlType="submit"
                                        >
                                            Create
                                        </Button>
                                    </Space>
                                </Form.Item>
                            </Form>

                            {error ? (
                                <>
                                    <Alert
                                        showIcon
                                        message={error.message}
                                        type="error"
                                    />
                                    <br />
                                </>
                            ) : null}
                        </div>
                    </AuthWrapper>
                </div>
            )}
        </>
    );
}

export default RegistrationShort;
