import React from 'react';
import CcCluster from '../../services/models/CcCluster';
import { PlusOutlined } from '@ant-design/icons';
import ButtonBarrier from '../../common/Barrier/ButtonBarrier';
import { CmonFeature } from '../../services/cmon/CmonFeature';
import ButtonWithForm from '../../common/General/ButtonWithForm';
import { Button } from 'antd';
import BackupWizardModal from './Wizard/BackupWizardModal';
import { ButtonProps } from 'antd/lib/button';
import {
    BackupWizardStep,
    BackupWizardStepActionType,
} from './Wizard/BackupWizard';

export type BackupCreateWizardButtonProps = ButtonProps & {
    cluster?: CcCluster;
    onSuccess?: () => void;
    wizardAction?: BackupWizardStepActionType;
    activeStep?: BackupWizardStep;
};
const BackupCreateWizardButton = ({
    cluster,
    children,
    onSuccess,
    wizardAction,
    activeStep,
    ...rest
}: BackupCreateWizardButtonProps) => {
    const button = (
        <Button type="primary" icon={<PlusOutlined />} {...rest}>
            {children || <span>Create backup</span>}
        </Button>
    );
    return (
        <ButtonBarrier
            licenseFeature={CmonFeature.START_BACKUP}
            buttonProps={{ icon: <PlusOutlined /> }}
            button={button}
        >
            <ButtonWithForm
                button={button}
                form={
                    <BackupWizardModal
                        cluster={cluster}
                        onSuccess={onSuccess}
                        wizardAction={wizardAction}
                        activeStep={activeStep}
                    />
                }
            />
        </ButtonBarrier>
    );
};

export default BackupCreateWizardButton;
