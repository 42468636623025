import CmonMongoHost, {
    CmonMongoHostProps,
} from '../cmon/models/CmonMongoHost';
import { CcNodeRole } from './CcNode';

export enum CcMongoNodeMemberRole {
    PRIMARY = 'Primary',
    SECONDARY = 'Secondary',
    NON_PRIMARY = 'non-Primary',
}

export interface CcMongoNodeProps extends CmonMongoHostProps {}

export default class CcMongoNode extends CmonMongoHost {
    public readonly memberRole?: CcMongoNodeMemberRole;
    constructor(props: CcMongoNodeProps) {
        super(props);
        this.memberRole = props.member_role as CcMongoNodeMemberRole;
    }

    public getRole() {
        return this.isRole(CcNodeRole.MONGO_DATA_NODE)
            ? this.memberRole
            : this.role;
    }
}
