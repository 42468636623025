


export interface CmonJobStatisticsProps  {
    by_state?: {[key:string]:any};
    cluster_id?: number;

}



export default class CmonJobStatistics {
    public byState?: {[key:string]:any};
    public clusterId?: number;


    constructor(props: CmonJobStatisticsProps) {
        this.byState = props.by_state;
        this.clusterId = props.cluster_id;

    }

}
