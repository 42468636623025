import './LoadBalancerWizard.less';
import WizardConfiguration from '@severalnines/bar-frontend-components/build/lib/Navigation/Wizard/WizardConfiguration';
import React, { useState, useEffect, useMemo } from 'react';
import WizardSelectCardStep from '@severalnines/bar-frontend-components/build/lib/Navigation/Wizard/WizardSelectCardStep';
import WizardSelectTypeStep, {
    WizardSelectTypeStepItem,
} from '@severalnines/bar-frontend-components/build/lib/Navigation/Wizard/WizardSelectTypeStep';
import CcCluster, { CcClusterType } from '../../services/models/CcCluster';
import SpaceWide from '../../common/SpaceWide';
import { Button } from 'antd';
import { DatabaseOutlined } from '@ant-design/icons';
import {
    notifyJobCreationSuccess,
    notifyJobCreationError,
} from '../Notifications/uiNotification';
import LoadBalancerFormsCreate from './LoadBalancerFormsCreate';
import LoadBalancerFormsImport from './LoadBalancerFormsImport';

export enum LoadBalancerType {
    PGBOUNCER = 'PgBouncer',
    PGPOOL_II = 'Pgpool-II',
    HAPROXY = 'HAProxy',
    KEEPALIVED = 'Keepalived',
    PROXYSQL = 'ProxySQL',
    GARBD = 'Garbd',
    MAXSCALE = 'MaxScale',
}

interface ILoadBalancerItem {
    name: string;
    key: string;
    icon: string;
    description: string;
    disabled: boolean;
}

const loadBalancerItems: ILoadBalancerItem[] = [
    {
        name: LoadBalancerType.PROXYSQL,
        key: LoadBalancerType.PROXYSQL,
        icon: 'img-service-deploy.svg',
        description:
            'ProxySQL is database aware load-balancer which understands the MySQL protocol and can provide advanced traffic control for database transactions. Use sticky queries, set priorities for important queries, or even prevent queries from being executed.',
        disabled: false,
        documentationLink: 'https://proxysql.com/documentation/',
    },
    {
        name: LoadBalancerType.HAPROXY,
        key: LoadBalancerType.HAPROXY,
        icon: 'img-service-deploy.svg',
        description:
            'HAProxy is very fast and reliable reverse-proxy offering high availability, load balancing, and proxying for TCP and HTTP-based applications.',
        disabled: false,
        documentationLink: 'http://docs.haproxy.org/',
    },

    {
        name: LoadBalancerType.PGPOOL_II,
        key: LoadBalancerType.PGPOOL_II,
        icon: 'img-service-deploy.svg',
        description:
            "Pgpool-II's load balancing allows READ queries for a particular database or application connection to be sent to a particular PostgreSQL server.",
        disabled: true,
        documentationLink: 'https://pgpool.net/mediawiki/index.php/Main_Page',
    },
    {
        name: LoadBalancerType.KEEPALIVED,
        key: LoadBalancerType.KEEPALIVED,
        icon: 'img-service-deploy.svg',
        description:
            'Keepalived uses the IP Virtual Server (IPVS) kernel module to provide transport layer (Layer 4) load balancing, redirecting requests for network-based services to individual members of a server cluster.',
        disabled: false,
        documentationLink: 'https://www.keepalived.org/manpage.html',
    },

    {
        name: 'MariaDB MaxScale',
        key: LoadBalancerType.MAXSCALE,
        icon: 'img-service-deploy.svg',
        description:
            'MariaDB MaxScale is a database proxy that extends the high availability, scalability, and security of MariaDB Server while at the same time simplifying application development by decoupling it from underlying database infrastructure.',
        disabled: false,
        documentationLink: 'https://mariadb.com/kb/en/maxscale/',
    },

    {
        name: LoadBalancerType.GARBD,
        key: LoadBalancerType.GARBD,
        icon: 'img-service-deploy.svg',
        description:
            'Galera garbd (galera arbitrator) is a small program that serves as an arbitrator for Galera Cluster, it resolve split-brain situation which happen when there is an even number of nodes in the cluster, by determining which nodes should be considered primary and which should be considered secondary.',
        disabled: false,
        documentationLink:
            'https://galeracluster.com/library/documentation/arbitrator.html',
    },

    {
        name: LoadBalancerType.PGBOUNCER,
        key: LoadBalancerType.PGBOUNCER,
        icon: 'img-service-deploy.svg',
        description:
            'PgBouncer is an open-source, lightweight, single-binary connection pooler for PostgreSQL. It can pool connections to one or more databases (on possibly different servers) and serve clients over TCP and Unix domain sockets. PgBouncer maintains a pool of connections for each unique user, database pair.',
        disabled: false,
        documentationLink: 'https://www.pgbouncer.org/usage.html#description',
    },
];

export enum LoadBalancerWizardStepActionType {
    CREATE = 'Create',
    IMPORT = 'Import',
}

export enum LoadBalancerWizardStep {
    ACTION = 'action',
    TYPE = 'type',
    FORM_CREATE = 'form_create',
    FORM_IMPORT = 'form_import',
}

export type LoadBalancerWizardProps = {
    activeStep?: LoadBalancerWizardStep;
    cluster?: CcCluster;
    onChangeTitle?: (modetitle: String) => void;
    onCancel?: () => void;
    onFormStepErrorInsist?: (err: Error) => void;
    onFormTouchedChange?: (touched: boolean) => void;
};
export default function LoadBalancerWizard({
    cluster,
    onChangeTitle,
    onCancel,
    onFormStepErrorInsist,
    onFormTouchedChange,
}: LoadBalancerWizardProps) {
    const [step, setStep] = useState<LoadBalancerWizardStep>(
        LoadBalancerWizardStep.ACTION
    );
    const [action, setAction] = useState<LoadBalancerWizardStepActionType>();
    const [loadBalancerTypes, setLoadBalancerTypes] = useState<
        LoadBalancerType
    >(LoadBalancerType.PGPOOL_II);

    useEffect(() => {
        if (step === LoadBalancerWizardStep.ACTION) {
            onChangeTitle?.('');
        }
    }, [step]);

    const handleActionSelect = (value: LoadBalancerWizardStepActionType) => {
        onChangeTitle?.(`${value} a load balancer`);
        setAction(value);
        setStep(LoadBalancerWizardStep.TYPE);
    };

    const handleCloudProviderSelect = ({ key }: WizardSelectTypeStepItem) => {
        onChangeTitle?.(`${action}  ${key} service`);
    };
    const handleCloudProviderConfirm = ({ key }: any) => {
        setLoadBalancerTypes(key);
        if (action === LoadBalancerWizardStepActionType.CREATE) {
            setStep(LoadBalancerWizardStep.FORM_CREATE);
        } else {
            setStep(LoadBalancerWizardStep.FORM_IMPORT);
        }
    };

    const handleBackClick = () => {
        setStep(LoadBalancerWizardStep.ACTION);
    };

    const handleFormCancel = () => {
        setStep(LoadBalancerWizardStep.TYPE);
    };

    const handleSuccess = () => {
        if (onCancel) {
            onCancel();
        }
        notifyJobCreationSuccess({
            size: 'large',
            content: 'Creating the service could takes some time, please wait.',
        });
    };
    const handleError = () => {
        if (onCancel) {
            onCancel();
        }
        notifyJobCreationError({
            size: 'large',
            content: 'Creating the service failded.',
        });
    };

    const handleFormTouchedChange = (touched: boolean) => {
        if (onFormTouchedChange) {
            onFormTouchedChange(touched);
        }
    };

    const handleFormStepErrorInsist = (err: Error) => {
        if (onFormStepErrorInsist) {
            onFormStepErrorInsist(err);
        }
    };

    const items = getLoadBalancerType(
        cluster?.clusterType,
        loadBalancerItems
    ).map((item) => ({
        ...item,
        value: item.key,
    }));
    const supportedLoadBalancerTypes = items.map(({ name }) => name).join(', ');

    return (
        <WizardConfiguration
            activeStep={step}
            steps={[
                {
                    step: LoadBalancerWizardStep.ACTION,
                    content: (
                        <WizardSelectCardStep
                            title="Add load balancer"
                            description=""
                            onSelect={(value) =>
                                handleActionSelect(
                                    value as LoadBalancerWizardStepActionType
                                )
                            }
                        >
                            <WizardSelectCardStep.Item
                                icon={
                                    <img
                                        src={require('./img-service-deploy.svg')}
                                        alt="Create balancer"
                                    />
                                }
                                backgroundType="squares"
                                title="Create balancer"
                                action={LoadBalancerWizardStepActionType.CREATE}
                                description="Choose a load balancer technology, configure and create balancing service within a few minutes."
                                descriptionSecondary={`Supported load balancers: ${supportedLoadBalancerTypes}`}
                                buttonTitle={getCreateBackupActionTypeTitle(
                                    LoadBalancerWizardStepActionType.CREATE
                                )}
                            />
                            <WizardSelectCardStep.Item
                                icon={
                                    <img
                                        src={require('./img-service-import.svg')}
                                        alt="Import balancer"
                                    />
                                }
                                backgroundType="circles"
                                title="Import balancer"
                                action={LoadBalancerWizardStepActionType.IMPORT}
                                description="Choose a load balancer technology, configure and create balancing service within a few minutes."
                                descriptionSecondary={`Supported load balancers: ${supportedLoadBalancerTypes}`}
                                buttonTitle={getCreateBackupActionTypeTitle(
                                    LoadBalancerWizardStepActionType.IMPORT
                                )}
                            />
                        </WizardSelectCardStep>
                    ),
                },
                {
                    step: LoadBalancerWizardStep.TYPE,
                    className: 'LoadBalancer-select-type',
                    content: (
                        <WizardSelectTypeStep
                            onSelect={handleCloudProviderSelect}
                            onConfirm={handleCloudProviderConfirm}
                            footer={
                                <SpaceWide justify="flex-end">
                                    <Button onClick={handleBackClick}>
                                        Back
                                    </Button>
                                </SpaceWide>
                            }
                            items={items}
                            emptyState={
                                <WizardSelectTypeStep.DetailEmptyState
                                    src={require('./img-cloud-service-zero-state.svg')}
                                    description="Choose a service on your left
                                that you want to create"
                                />
                            }
                            defaultIconComponent={DatabaseOutlined}
                        />
                    ),
                },
                {
                    step: LoadBalancerWizardStep.FORM_CREATE,
                    content: (
                        <LoadBalancerFormsCreate
                            onCancel={handleFormCancel}
                            cluster={cluster}
                            loadBalancerTypes={loadBalancerTypes}
                            onSuccess={handleSuccess}
                            onError={handleError}
                            onTouchedChange={handleFormTouchedChange}
                            onStepErrorInsist={handleFormStepErrorInsist}
                        />
                    ),
                },
                {
                    step: LoadBalancerWizardStep.FORM_IMPORT,
                    content: (
                        <LoadBalancerFormsImport
                            onCancel={handleFormCancel}
                            cluster={cluster}
                            loadBalancerTypes={loadBalancerTypes}
                            onSuccess={handleSuccess}
                            onError={handleError}
                            onTouchedChange={handleFormTouchedChange}
                            onStepErrorInsist={handleFormStepErrorInsist}
                        />
                    ),
                },
            ]}
        />
    );
}

export function getCreateBackupActionTypeTitle(
    type: LoadBalancerWizardStepActionType
) {
    switch (type) {
        case LoadBalancerWizardStepActionType.CREATE:
            return 'Create';
        case LoadBalancerWizardStepActionType.IMPORT:
            return 'Import';
    }
}

function getLoadBalancerType(
    clusterType: any,
    loadBalancerItems: ILoadBalancerItem[]
) {
    const replication = ['ProxySQL', 'HAProxy', 'Keepalived', 'MaxScale'];
    const galera = ['ProxySQL', 'HAProxy', 'Keepalived', 'Garbd', 'MaxScale'];
    const postgreSql = ['HAProxy', 'Keepalived', 'PgBouncer'];
    const timeScaleDB = ['HAProxy', 'Keepalived', 'PgBouncer'];
    switch (clusterType) {
        case CcClusterType.TYPE_REPLICATION:
            return loadBalancerItems.filter((item) =>
                replication.includes(item.key)
            );
        case CcClusterType.TYPE_GALERA:
            return loadBalancerItems.filter((item) =>
                galera.includes(item.key)
            );
        case CcClusterType.TYPE_POSTGRESQL:
            return loadBalancerItems.filter((item) =>
                postgreSql.includes(item.key)
            );
        case CcClusterType.TYPE_TIMESCALEDB:
            return loadBalancerItems.filter((item) =>
                timeScaleDB.includes(item.key)
            );
        default:
            return loadBalancerItems;
    }
}
