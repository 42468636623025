import './ContentWrapper.less';
import React from 'react';
import classNames from 'classnames';

export default ContentWrapper;
type ContentWrapperProps = {
    children: React.ReactNode;
    size?: 'small' | 'medium';
};

function ContentWrapper({ size, children }: ContentWrapperProps) {
    return (
        <div
            className={classNames(
                'ContentWrapper',
                `ContentWrapper--size-${size}`
            )}
        >
            {children}
        </div>
    );
}
