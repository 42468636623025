import React from 'react';
import { ButtonProps } from 'antd/lib/button';
import CcCluster from '../../../services/models/CcCluster';
import { CmonJobInstanceCommand } from '../../../services/cmon/models/CmonJobInstance';
import JobConfirmActionButton from '../../Jobs/JobConfirmActionButton';

export default ClusterToggleNodeAutoRecoveryButton;

export type ClusterToggleNodeAutoRecoveryButtonProps = ButtonProps & {
    cluster: CcCluster;
    children?: React.ReactNode;
    onSuccess?: () => void;
};

function ClusterToggleNodeAutoRecoveryButton({
    cluster,
    children,
    onSuccess,
    ...rest
}: ClusterToggleNodeAutoRecoveryButtonProps) {
    return (
        <JobConfirmActionButton
            clusterId={cluster.clusterId}
            command={
                cluster.nodeAutoRecovery
                    ? CmonJobInstanceCommand.DISABLE_NODE_RECOVERY
                    : CmonJobInstanceCommand.ENABLE_NODE_RECOVERY
            }
            confirmTitle={
                cluster.nodeAutoRecovery
                    ? 'Disable node auto recovery?'
                    : 'Enable node auto recovery?'
            }
            title={
                cluster.nodeAutoRecovery
                    ? 'Disable node recovery'
                    : 'Enable node recovery'
            }
            onSuccess={onSuccess}
            {...rest}
        />
    );
}
