import React from 'react';
import { Form, Input } from 'antd';

export default IntegrationComment;

export type IntegrationCommentProps = {};

function IntegrationComment({}: IntegrationCommentProps) {
    return (
        <Form.Item name="comment" label="Comment">
            <Input placeholder="Enter a comment" />
        </Form.Item>
    );
}
