
import CcNode, { CcNodeProps } from '../../models/CcNode';


export interface CmonControllerHostProps  extends CcNodeProps {
    controller_keys?: string;

}



export default class CmonControllerHost extends CcNode {
    public controllerKeys?: string;


    constructor(props: CmonControllerHostProps) {
        super(props);
        this.controllerKeys = props.controller_keys;

    }

}
