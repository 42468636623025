import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Input, Row, Select, Space } from 'antd';
import Alert from '@severalnines/bar-frontend-components/build/lib/Feedback/Alert';
import FormFooter from '../../../common/FormFooter';
import ModalDefaultForm from '../../../common/ModalDefaultForm';
import CcNode, {
    CcNodeBase,
    CcNodeType,
    CcNodeStatus,
} from '../../../services/models/CcNode';
import NodeFormat, { getNodeHostWithDesc } from '../NodeFormat';
import SpaceWide from '../../../common/SpaceWide';
import useCreateJob from '../../Jobs/useCreateJob';
import { CmonJobInstanceCommand } from '../../../services/cmon/models/CmonJobInstance';
import CcCluster from '../../../services/models/CcCluster';
import useClusterConfig from '../../Clusters/useClusterConfig';
import useBackups from '../../Backups/useBackups';
import FormItemInlineSwitch from '../../../common/DataEntry/FormItemInlineSwitch';
import CcBackup, { CcBackupStatus } from '../../../services/models/CcBackup';
import BackupFormat from '../../Backups/BackupFormat';
import CcPostgreSqlNode from '../../../services/models/CcPostgreSqlNode';
import AppRadio from '../../../common/DataEntry/AppRadio';
import AppSpin from '../../../common/General/AppSpin';
import { RuleObject } from 'rc-field-form/lib/interface';
import { backupRebuildLocationValidator } from '../../Clusters/Actions/AddNode/FormParts/RebuildFromBackupSelectField';
import StorageLocationFormat from '../../Backups/BackupStorageFormat';

export default NodeRebuildReplicationSlaveForm;

export type NodeRebuildReplicationSlaveFormProps = {
    node: CcNode;
    cluster: CcCluster;
    onSuccess?: () => void;
    onCancel?: () => void;
};
export type NodeRebuildReplicationSlaveFormValues = {
    primaryCandidate: string;
    netcatPort?: string;
    backupId?: number;
    backupDataDir?: boolean;
    synchronous?: boolean;
};

function NodeRebuildReplicationSlaveForm({
    node,
    cluster,
    onSuccess,
    onCancel,
}: NodeRebuildReplicationSlaveFormProps) {
    const [primaryCandidates] = useState(() => {
        return (cluster.isReplica()
            ? cluster
                  .getReplicationPrimary()
                  ?.getPrimaryCandidates(node, true) || []
            : cluster.getPrimaryCandidates(node, true)
        ).filter(
            (master) => master.getHostWithPort() !== node.getHostWithPort()
        );
    });
    const [availableBackups, setAvailableBackups] = useState<CcBackup[]>([]);
    const [form] = Form.useForm<NodeRebuildReplicationSlaveFormValues>();
    const { loading, send } = useCreateJob({
        clusterId: node.clusterid,
        title: 'Rebuild replica',
        command: CmonJobInstanceCommand.STAGE_REPLICATION_SLAVE,
        onSuccess,
    });
    const {
        config,
        loading: loadingConfig,
        refresh: refreshConfig,
    } = useClusterConfig({
        clusterId: cluster.clusterId as number,
        useCache: false,
    });
    const {
        list: backups,
        loading: loadingBackups,
        refresh: refreshBackups,
    } = useBackups({ pageSize: 0 });

    useEffect(() => {
        (async () => {
            await refreshConfig();
        })();
        if (node.isType(CcNodeType.MYSQL)) {
            (async () => {
                await refreshBackups({
                    cluster_id: cluster.clusterId,
                    parent_id: -1,
                });
            })();
        }
    }, []);

    useEffect(() => {
        form.setFieldsValue({
            netcatPort: config?.netcat_port?.default_value as string,
        });
    }, [config]);

    useEffect(() => {
        if (backups) {
            setAvailableBackups(
                backups.filter(
                    (b: CcBackup) =>
                        b.canUseForPitr() &&
                        b.getParentId() === 0 &&
                        b.getStatus() === CcBackupStatus.STATUS_COMPLETED
                )
            );
        }
    }, [backups]);

    const handleSubmit = async (
        values: NodeRebuildReplicationSlaveFormValues
    ) => {
        await send({
            remote_cluster_id: cluster.isReplica()
                ? cluster.getReplicationPrimary()?.clusterId
                : null,
            master_address: values.primaryCandidate,
            slave_address: node.getHostWithPort(),
            backupid: values.backupId || undefined,
            backup_datadir_before_restore: values.backupDataDir,
            synchronous: values.synchronous,
        });
    };
    return (
        <ModalDefaultForm
            title="Rebuild replication replica"
            form={form}
            footer={[]}
            onCancel={onCancel}
            width={900}
            defaultVisible={true}
        >
            <AppSpin spinning={loadingConfig || loadingBackups}>
                <Form
                    className="NodeRebuildReplicationSlaveForm"
                    form={form}
                    layout="vertical"
                    onFinish={handleSubmit}
                    initialValues={{
                        primaryCandidate:
                            primaryCandidates.length === 1 &&
                            primaryCandidates[0]?.hoststatus ===
                                CcNodeStatus.CmonHostOnline
                                ? primaryCandidates[0]?.getHostWithPort()
                                : undefined,
                    }}
                >
                    <Row gutter={24}>
                        <Col span={12}>
                            <SpaceWide direction="vertical">
                                <NodeFormat
                                    node={node}
                                    size="large"
                                    contentProps={{ style: { width: '100%' } }}
                                />
                                {node.isType(CcNodeType.MYSQL) ? (
                                    <FormItemInlineSwitch
                                        justify
                                        noMargin
                                        name="rebuildFromBackup"
                                        disabled={availableBackups?.length < 1}
                                        label={
                                            <span>
                                                <span>
                                                    Rebuild from a backup
                                                </span>{' '}
                                                ({availableBackups?.length || 0}{' '}
                                                PITR compatible backups)
                                            </span>
                                        }
                                        valuePropName="checked"
                                        extraOnSwitch={
                                            <SpaceWide direction="vertical">
                                                <FormItemInlineSwitch
                                                    justify
                                                    noMargin
                                                    name="backupDataDir"
                                                    label={
                                                        <Space>
                                                            Make a copy of the
                                                            datadir before
                                                            restoring the backup
                                                        </Space>
                                                    }
                                                    valuePropName="checked"
                                                />
                                                <Form.Item
                                                    name="backupId"
                                                    label="Backup"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message:
                                                                'Please select a backup.',
                                                        },
                                                        {
                                                            validator: async (
                                                                rule: RuleObject,
                                                                value
                                                            ) => {
                                                                const backup = availableBackups?.find(
                                                                    (backup) =>
                                                                        backup.getId() ===
                                                                        value
                                                                );
                                                                if (backup) {
                                                                    await backupRebuildLocationValidator(
                                                                        rule,
                                                                        backup
                                                                    );
                                                                }
                                                            },
                                                        },
                                                    ]}
                                                >
                                                    <Select
                                                        data-testid="nodes-actions-nodeRebuildReplicationSlaveForm-select-backup"
                                                        id="select-backup"
                                                        placeholder="Select a backup"
                                                        style={{
                                                            width: '100%',
                                                        }}
                                                    >
                                                        {availableBackups?.map(
                                                            (
                                                                backup: CcBackup
                                                            ) => (
                                                                <Select.Option
                                                                    value={backup.getId()}
                                                                    key={backup.getId()}
                                                                >
                                                                    <SpaceWide
                                                                        justify={
                                                                            'space-between'
                                                                        }
                                                                    >
                                                                        <BackupFormat
                                                                            backup={
                                                                                backup
                                                                            }
                                                                            shortBackupName={
                                                                                true
                                                                            }
                                                                        />
                                                                        <StorageLocationFormat
                                                                            backup={
                                                                                backup
                                                                            }
                                                                        />
                                                                    </SpaceWide>
                                                                </Select.Option>
                                                            )
                                                        )}
                                                    </Select>
                                                </Form.Item>
                                            </SpaceWide>
                                        }
                                    />
                                ) : null}

                                <div>
                                    <Form.Item
                                        name="primaryCandidate"
                                        label="Primary node candidate"
                                        rules={[
                                            {
                                                required: true,
                                                message:
                                                    'Please select a primary candidate.',
                                            },
                                        ]}
                                    >
                                        <Select
                                            data-testid="nodes-actions-nodeRebuildReplicationSlaveForm-select-primaryCandidate"
                                            id="select-primaryCandidate"
                                            placeholder="Select primary candidate"
                                            style={{ width: '100%' }}
                                        >
                                            {primaryCandidates.map((node) => (
                                                <Select.Option
                                                    value={node.getHostWithPort()}
                                                    key={node.getKey()}
                                                    disabled={
                                                        node.hoststatus !==
                                                        CcNodeStatus.CmonHostOnline
                                                    }
                                                >
                                                    {getNodeHostWithDesc(node)}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>

                                    {node.isType(CcNodeType.MYSQL) ? (
                                        <Form.Item
                                            name="netcatPort"
                                            label="Netcat port"
                                        >
                                            <Input disabled={true} />
                                        </Form.Item>
                                    ) : null}

                                    {node.isBase(CcNodeBase.BASE_POSTGRESQL) ? (
                                        <Form.Item
                                            name="synchronous"
                                            label="Synchronous replication"
                                        >
                                            <AppRadio.Group>
                                                <AppRadio.Button
                                                    key="disable-synchronous-replication-keep"
                                                    value={undefined}
                                                >
                                                    Keep it{' '}
                                                    {(node as CcPostgreSqlNode).isSyncStateSynchronous()
                                                        ? 'enabled'
                                                        : 'disabled'}
                                                </AppRadio.Button>
                                                {(node as CcPostgreSqlNode).isSyncStateSynchronous() ? (
                                                    <AppRadio.Button
                                                        key="disable-synchronous-replication"
                                                        value={false}
                                                    >
                                                        Disable
                                                    </AppRadio.Button>
                                                ) : null}
                                                {!(node as CcPostgreSqlNode).isSyncStateSynchronous() ? (
                                                    <AppRadio.Button
                                                        key="disable-synchronous-replication"
                                                        value={true}
                                                    >
                                                        Enable
                                                    </AppRadio.Button>
                                                ) : null}
                                            </AppRadio.Group>
                                        </Form.Item>
                                    ) : null}
                                </div>
                            </SpaceWide>
                        </Col>
                        <Col span={12}>
                            <SpaceWide direction="vertical">
                                {node.isType(CcNodeType.MYSQL) ? (
                                    <Alert
                                        message={`Data will be streamed using a port in this range: ${
                                            config?.netcat_port
                                                ?.default_value as string
                                        }`}
                                    />
                                ) : null}

                                <Form.Item noStyle={false} shouldUpdate={true}>
                                    {() => (
                                        <Alert
                                            message={
                                                <span>
                                                    The following actions will
                                                    happen on the replica:
                                                    {node.isType(
                                                        CcNodeType.MYSQL
                                                    ) ? (
                                                        <ol>
                                                            <li>
                                                                Stop MySQL
                                                                server
                                                            </li>
                                                            <li>
                                                                Remove content
                                                                from its datadir
                                                            </li>
                                                            <li>
                                                                Stream a backup
                                                                from the node to
                                                                the replica
                                                                using xtrabackup
                                                            </li>
                                                            <li>
                                                                Start MySQL
                                                                server
                                                            </li>
                                                        </ol>
                                                    ) : null}
                                                    {node.isBase(
                                                        CcNodeBase.BASE_POSTGRESQL
                                                    ) ? (
                                                        <ol>
                                                            <li>
                                                                Stop PostgreSQL
                                                                Server
                                                            </li>
                                                            <li>
                                                                Remove content
                                                                from its datadir
                                                            </li>
                                                            <li>
                                                                Stream a backup
                                                                from the Primary
                                                                to the Replica
                                                                using
                                                                pg_basebackup
                                                            </li>
                                                            <li>
                                                                Start the
                                                                Replica
                                                            </li>
                                                        </ol>
                                                    ) : null}
                                                    {node.isType(
                                                        CcNodeType.MSSQL
                                                    ) ? (
                                                        <ol>
                                                            <li>
                                                                Restart the SQL
                                                                Server
                                                            </li>
                                                            <li>
                                                                Resume HADR on
                                                                each Database in
                                                                each
                                                                Availability
                                                                Group
                                                            </li>
                                                        </ol>
                                                    ) : null}
                                                </span>
                                            }
                                            showIcon={true}
                                        />
                                    )}
                                </Form.Item>
                            </SpaceWide>
                        </Col>
                    </Row>

                    <FormFooter>
                        <Button
                            key="done"
                            type="primary"
                            htmlType="submit"
                            loading={loading}
                        >
                            Rebuild
                        </Button>
                    </FormFooter>
                </Form>
            </AppSpin>
        </ModalDefaultForm>
    );
}
