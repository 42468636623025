import React from 'react';
import JobActionButton from '../../Jobs/JobActionButton';
import { CmonJobInstanceCommand } from '../../../services/cmon/models/CmonJobInstance';
import ButtonWithForm from '../../../common/General/ButtonWithForm';
import CcCluster from '../../../services/models/CcCluster';
import CcBackup from '../../../services/models/CcBackup';
import BackupCreateClusterModal from '../BackupCreateClusterModal';

export default BackupClusterCreateActionButton;
type BackupClusterCreateActionButtonProps = {
    cluster: CcCluster;
    backup: CcBackup;
};

function BackupClusterCreateActionButton({
    cluster,
    backup,
}: BackupClusterCreateActionButtonProps) {
    return (
        <ButtonWithForm
            button={
                <JobActionButton
                    command={
                        CmonJobInstanceCommand.CREATE_CLUSTER
                    }
                    clusterId={cluster?.clusterId}
                    extraKey={[backup.getId()]}
                >
                    Create cluster
                </JobActionButton>
            }
            form={
                <BackupCreateClusterModal
                    cluster={cluster}
                    backup={backup}
                />
            }
        />
    );
}
