import TypographyText from '../../../common/TypographyText';
import { Button, Space } from 'antd';
import PromoCard, { PromoCardProps } from '../../../common/Layout/PromoCard';
import React, { ReactNode } from 'react';
import { CmonJobInstanceCommand } from '../../../services/cmon/models/CmonJobInstance';

export default BackupToolPromoCard;
type BackupToolPromoCardProps = Omit<PromoCardProps, 'extra'> & {
    extra?: ReactNode;
    intoCommand?: CmonJobInstanceCommand;
    onContinueClick?: () => void;
    onInstallClick?: () => void;
    onCancelClick?: () => void;
};

function BackupToolPromoCard({
    children,
    intoCommand,
    onContinueClick,
    onInstallClick,
    onCancelClick,
    extra,
    ...rest
}: BackupToolPromoCardProps) {
    const localExtra =
        extra || intoCommand ? (
            <Button type="primary" size="middle" onClick={onContinueClick}>
                Continue
            </Button>
        ) : (
            <Space>
                <Button type="primary" size="middle" onClick={onInstallClick}>
                    Install
                </Button>
                <Button size="middle" onClick={onCancelClick}>
                    Cancel
                </Button>
            </Space>
        );

    const content =
        children ||
        (intoCommand ? (
            <div style={{ paddingBottom: 50 }}>
                {getBackupToolPromoIntro(intoCommand)}
            </div>
        ) : null);

    return (
        <PromoCard extra={localExtra} {...rest}>
            {content}
        </PromoCard>
    );
}

function getBackupToolPromoIntro(
    command?: CmonJobInstanceCommand
): React.ReactNode {
    switch (command) {
        case CmonJobInstanceCommand.PGBACKREST:
            return (
                <TypographyText>
                    <a
                        href="https://pgbackrest.org/"
                        target="_blank"
                        rel="noreferrer"
                    >
                        PgBackRest{' '}
                    </a>
                    - Reliable PostgreSQL Backup & Restore.
                </TypographyText>
            );
        case CmonJobInstanceCommand.PBMAGENT:
            return (
                <TypographyText>
                    <a
                        href="https://www.percona.com/software/mongodb/percona-backup-for-mongodb"
                        target="_blank"
                        rel="noreferrer"
                    >
                        PBM{' '}
                    </a>
                    - Percona Backup for MongoDB is available for MongoDB 4.0 or
                    later versions.
                </TypographyText>
            );
    }
}
