import {
    AddClusterNodeConfigFormValues,
    ClusterConfigurator,
    ClusterConfiguratorFormValues,
} from '../ClusterConfigurator';
import merge from 'deepmerge';
import React from 'react';
import { CcClusterType } from '../../../../services/models/CcCluster';
import PostgreSqlNodeConfiguration from './PostgreSqlNodeConfiguration';

export interface PostgreSqlFormValues extends ClusterConfiguratorFormValues {
    nodeConfig: AddClusterNodeConfigFormValues & {
        serverPort?: number;
        adminUser?: string;
        adminPassword?: string;
        serverDataDirectory?: string;
    };
    synchronousReplication?: {
        [key: string]: boolean;
    };
    topologyServerPort?: {
        [key: string]: number;
    };
    topologyLogFile?: {
        [key: string]: string;
    };
}

export default class PostgreSqlConfigurator extends ClusterConfigurator {
    public static getDefaults(): PostgreSqlFormValues {
        return merge(ClusterConfigurator.getDefaults(), {
            nodeConfig: {
                serverPort: 5432,
            },
        });
    }

    public static getJobData(formValues: PostgreSqlFormValues): any {
        const { nodeConfig, details } = formValues;
        return merge(ClusterConfigurator.getJobData(formValues), {
            cluster_type: CcClusterType.TYPE_POSTGRESQL.toLowerCase(),
            db_user: nodeConfig.adminUser,
            db_password: nodeConfig.adminPassword,
            datadir: nodeConfig.serverDataDirectory,
            port: nodeConfig.serverPort,
            enterprise_token: details.enterpriseToken,
        });
    }

    public static getVendors() {
        return [
            {
                name: 'PostgreSQL',
                value: 'default',
                versions: [
                    { name: '15', value: '15' },
                    { name: '14', value: '14' },
                    { name: '13', value: '13' },
                    { name: '12', value: '12' },
                    { name: '11', value: '11' },
                ],
            },
            {
                name: 'PostgreSQL Enterprise DB',
                value: 'enterprisedb',
                versions: [
                    { name: '15', value: '15' },
                    { name: '14', value: '14' },
                    { name: '13', value: '13' },
                    { name: '12', value: '12' },
                    { name: '11', value: '11' },
                ],
            },
        ];
    }

    public static getNodeConfigurationValidate(): string[][] {
        return [
            ['nodeConfig', 'serverPort'],
            ['nodeConfig', 'adminUser'],
            ['nodeConfig', 'adminPassword'],
        ];
    }

    public static getNodeConfigurationStep(props: any): React.ReactNode {
        return <PostgreSqlNodeConfiguration {...props} />;
    }

}
