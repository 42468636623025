
import CmonRequestService, {CmonRequestServiceResponseData} from './CmonRequestService';


export interface TopqueriesRequestData {

    [key: string]: any;
}

export interface ProcesslistRequestData {

    [key: string]: any;
}

export interface QueryproxysqlclusterRequestData {

    [key: string]: any;
}

export interface QueryrulesRequestData {

    [key: string]: any;
}

export interface QueryhostgroupsRequestData {

    [key: string]: any;
}

export interface QuerygalerahostgroupsRequestData {

    [key: string]: any;
}

export interface QueryserversRequestData {

    [key: string]: any;
}

export interface QueryproxysqlserversRequestData {

    [key: string]: any;
}

export interface QueryusersRequestData {

    [key: string]: any;
}

export interface QueryvariablesRequestData {

    [key: string]: any;
}

export interface QueryschedulesRequestData {

    [key: string]: any;
}

export interface InsertqueryruleRequestData {

    [key: string]: any;
}

export interface DeletequeryruleRequestData {

    [key: string]: any;
}

export interface UpdatequeryruleRequestData {

    [key: string]: any;
}

export interface InsertmysqlserverRequestData {

    [key: string]: any;
}

export interface DeletemysqlserverRequestData {

    [key: string]: any;
}

export interface UpdatemysqlserverRequestData {

    [key: string]: any;
}

export interface InsertproxysqlserverRequestData {

    [key: string]: any;
}

export interface DeleteproxysqlserverRequestData {

    [key: string]: any;
}

export interface UpdateproxysqlserverRequestData {

    [key: string]: any;
}

export interface InsertscheduleRequestData {

    [key: string]: any;
}

export interface DeletescheduleRequestData {

    [key: string]: any;
}

export interface UpdatescheduleRequestData {

    [key: string]: any;
}

export interface InsertmysqluserRequestData {

    [key: string]: any;
}

export interface DeletemysqluserRequestData {

    [key: string]: any;
}

export interface UpdatemysqluserRequestData {

    [key: string]: any;
}

export interface UpdatevariableRequestData {

    [key: string]: any;
}

export interface UpdateadmincredentialsincmonRequestData {

    [key: string]: any;
}

export interface UpdatemonitorcredentialsincmonRequestData {

    [key: string]: any;
}

export interface ImportmysqlusersRequestData {

    [key: string]: any;
}

export interface ResetqueryrulestatsRequestData {

    [key: string]: any;
}


export default class CmonProxysqlService extends CmonRequestService {
    
    public static module(): string {
        return 'proxysql';
    }
    
    public static async topqueries(
        data: TopqueriesRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('topqueries', data, opts);
    }

    public static async processlist(
        data: ProcesslistRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('processlist', data, opts);
    }

    public static async queryproxysqlcluster(
        data: QueryproxysqlclusterRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('queryproxysqlcluster', data, opts);
    }

    public static async queryrules(
        data: QueryrulesRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('queryrules', data, opts);
    }

    public static async queryhostgroups(
        data: QueryhostgroupsRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('queryhostgroups', data, opts);
    }

    public static async querygalerahostgroups(
        data: QuerygalerahostgroupsRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('querygalerahostgroups', data, opts);
    }

    public static async queryservers(
        data: QueryserversRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('queryservers', data, opts);
    }

    public static async queryproxysqlservers(
        data: QueryproxysqlserversRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('queryproxysqlservers', data, opts);
    }

    public static async queryusers(
        data: QueryusersRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('queryusers', data, opts);
    }

    public static async queryvariables(
        data: QueryvariablesRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('queryvariables', data, opts);
    }

    public static async queryschedules(
        data: QueryschedulesRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('queryschedules', data, opts);
    }

    public static async insertqueryrule(
        data: InsertqueryruleRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('insertqueryrule', data, opts);
    }

    public static async deletequeryrule(
        data: DeletequeryruleRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('deletequeryrule', data, opts);
    }

    public static async updatequeryrule(
        data: UpdatequeryruleRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('updatequeryrule', data, opts);
    }

    public static async insertmysqlserver(
        data: InsertmysqlserverRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('insertmysqlserver', data, opts);
    }

    public static async deletemysqlserver(
        data: DeletemysqlserverRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('deletemysqlserver', data, opts);
    }

    public static async updatemysqlserver(
        data: UpdatemysqlserverRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('updatemysqlserver', data, opts);
    }

    public static async insertproxysqlserver(
        data: InsertproxysqlserverRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('insertproxysqlserver', data, opts);
    }

    public static async deleteproxysqlserver(
        data: DeleteproxysqlserverRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('deleteproxysqlserver', data, opts);
    }

    public static async updateproxysqlserver(
        data: UpdateproxysqlserverRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('updateproxysqlserver', data, opts);
    }

    public static async insertschedule(
        data: InsertscheduleRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('insertschedule', data, opts);
    }

    public static async deleteschedule(
        data: DeletescheduleRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('deleteschedule', data, opts);
    }

    public static async updateschedule(
        data: UpdatescheduleRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('updateschedule', data, opts);
    }

    public static async insertmysqluser(
        data: InsertmysqluserRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('insertmysqluser', data, opts);
    }

    public static async deletemysqluser(
        data: DeletemysqluserRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('deletemysqluser', data, opts);
    }

    public static async updatemysqluser(
        data: UpdatemysqluserRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('updatemysqluser', data, opts);
    }

    public static async updatevariable(
        data: UpdatevariableRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('updatevariable', data, opts);
    }

    public static async updateadmincredentialsincmon(
        data: UpdateadmincredentialsincmonRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('updateadmincredentialsincmon', data, opts);
    }

    public static async updatemonitorcredentialsincmon(
        data: UpdatemonitorcredentialsincmonRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('updatemonitorcredentialsincmon', data, opts);
    }

    public static async importmysqlusers(
        data: ImportmysqlusersRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('importmysqlusers', data, opts);
    }

    public static async resetqueryrulestats(
        data: ResetqueryrulestatsRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('resetqueryrulestats', data, opts);
    }

}
