import CmonPostgreSqlHost, {
    CmonPostgreSqlHostProps,
} from '../cmon/models/CmonPostgreSqlHost';
import { CcNodeType } from './CcNode';

export enum CcPostgreSqlNodePitrMethod {
    NONE = 'none',
    LOCAL_WAL_ARCHIVE = 'local_wal_archive',
    PGBACKREST = 'pgbackrest',
}

export enum CcPostgreSqlNodeArchiveMode {
    ON = 'on',
    OFF = 'off',
    ALWAYS = 'always',
}

export interface CcPostgresqlNodeProps extends CmonPostgreSqlHostProps {
    pitrMethod?: CcPostgreSqlNodePitrMethod;
}

export default class CcPostgreSqlNode extends CmonPostgreSqlHost {
    public readonly pitrMethod?: CcPostgreSqlNodePitrMethod;

    constructor(props: CcPostgresqlNodeProps) {
        if (isTimescaleDb(props.extensions)) {
            props.nodetype = CcNodeType.TIMESCALEDB;
        }
        super(props);
        this.pitrMethod = props.pitr_method as CcPostgreSqlNodePitrMethod;
    }

    isReplicationRunning() {
        return this.replicationState === 'streaming';
    }

    isPitrMethod(pitrMethod: CcPostgreSqlNodePitrMethod) {
        return this.pitrMethod === pitrMethod;
    }

    isPitrMethodPGBackRest() {
        return this.isPitrMethod(CcPostgreSqlNodePitrMethod.PGBACKREST);
    }

    isPitrMethodLocalWalArchive() {
        return this.isPitrMethod(CcPostgreSqlNodePitrMethod.LOCAL_WAL_ARCHIVE);
    }

    isWALArchivingModeEnabled() {
        return (
            this.archiveMode &&
            [
                CcPostgreSqlNodeArchiveMode.ON,
                CcPostgreSqlNodeArchiveMode.ALWAYS,
            ].includes(this.archiveMode as CcPostgreSqlNodeArchiveMode)
        );
    }

    isTimescaledb() {
        return isTimescaleDb(this.extensions);
    }

    isSyncStateSynchronous() {
        return this.syncState && this.syncState === 'sync';
    }

    isPgStatStatementsEnabled() {
        return isPgStatStatementsEnabled(this.extensions);
    }

    public getMessage() {
        return `${super.getMessage()}${
            this.replicationState ? ` (${this.replicationState})` : ''
        }`;
    }
}

function isTimescaleDb(extensions?: { [key: string]: any }) {
    return !!extensions?.find((ext: any) => ext.name === 'timescaledb');
}
function isPgStatStatementsEnabled(extensions?: { [key: string]: any }) {
    return !!extensions?.find((ext: any) => ext.name === 'pg_stat_statements');
}
