import React, { useEffect, useState } from 'react';
import { Select, Form } from 'antd';
import { DataSourceType } from './ProxySQLImportUsers';
import { FormInstance } from 'antd/lib/form';

export type ProxySQLHostGroupProps = {
    hostGroupList: { value: string; label: string }[] | undefined;
    globalHostGroupValue?: string;
    record: DataSourceType;
    usersList: DataSourceType[];
    form: FormInstance;
    setUserHostGroup: (record: DataSourceType, value: string) => void;
};

// @todo : enhance incoming  props

function ProxySQLHostGroup({
    hostGroupList,
    record,
    globalHostGroupValue,
    usersList,
    form,
    setUserHostGroup,
}: ProxySQLHostGroupProps) {
    const [inputValue, setInputValue] = useState<string>();

    useEffect(() => {
        if (globalHostGroupValue) {
            const obj: any = {};
            obj[`hostgroup_${record.key}`] = globalHostGroupValue;
            form.setFieldsValue(obj);
            setInputValue(globalHostGroupValue);
        }
    }, [globalHostGroupValue]);

    const onChangeHandler = (value: string) => {
        setInputValue(value);
        setUserHostGroup(record, value);
    };

    return (
        <Form.Item
            label={' '}
            name={`hostgroup_${record.key}`}
            rules={[
                {
                    required: usersList.some((user) => user.key === record.key),
                    message: 'Select hostgroup.',
                },
            ]}
        >
            <Select
                value={inputValue}
                onChange={onChangeHandler}
                placeholder={'Hostgroup'}
                options={hostGroupList}
            />
        </Form.Item>
    );
}

export default ProxySQLHostGroup;
