import React from 'react';
import LicenseStateFormat from './LicenseStateFormat';
import { Space } from 'antd';
import { AppState, AppStateLicenseInfo } from '../../appReducer';
import { useSelector } from 'react-redux';
import { SpaceProps } from 'antd/lib/space';
import { CcLicenseState } from '../../services/models/CcLicense';

export default LicenseStateList;
export type LicenseStateListProps = SpaceProps & {
    stateFilter?: (state: CcLicenseState) => boolean;
    size?: 'large' | 'small';
};

function LicenseStateList({
    size = 'small',
    stateFilter = () => true,
    ...rest
}: LicenseStateListProps) {
    const [licenseInfo]: [
        AppStateLicenseInfo
    ] = useSelector(({ licenseInfo }: AppState) => [licenseInfo]);
    return (
        <Space
            {...rest}
            direction={size === 'large' ? 'vertical' : 'horizontal'}
        >
            {licenseInfo?.license?.state?.filter(stateFilter).map((state) => (
                <LicenseStateFormat
                    key={state}
                    state={state}
                    license={licenseInfo?.license}
                    size={size}
                />
            ))}
        </Space>
    );
}
