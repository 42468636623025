import React, { useMemo, useState } from 'react';
import JobProgress from '../Jobs/JobProgress';
import { CmonJobInstanceCommand } from '../../services/cmon/models/CmonJobInstance';
import { Button, Form, Input, Radio, Space } from 'antd';
import TypographyText from '../../common/TypographyText';
import InfoIcon from '@severalnines/bar-frontend-components/build/lib/General/InfoIcon';
import CcCluster from '../../services/models/CcCluster';
import useCreateJob from '../Jobs/useCreateJob';
import { CcNodeRole } from '../../services/models/CcNode';
import { FormInstance } from 'antd/lib/form';
import PromoCard from '../../common/Layout/PromoCard';
import { notifyError } from '../Notifications/uiNotification';
import CcJob from '../../services/models/CcJob';
import HostCheckInput from '../../common/DataEntry/HostCheckInput';
import { BackupFormFieldsType } from './Config/BackupFormConfigurator';
import ContentWrapper from '../../common/Layout/ContentWrapper';
import AppSpin from '../../common/General/AppSpin';

export default InstallBackupTool;

export type InstallBackupToolProps = {
    form: FormInstance<BackupFormFieldsType>;
    command: CmonJobInstanceCommand;
    cluster: CcCluster;
    onlyForm?: boolean;
    reinstall?: boolean;
    reinstallMessage?: React.ReactNode;
    onFormOpen?: () => void;
    onJobStart?: () => void;
    onFinish?: (job: CcJob) => void;
    onCancel?: () => void;
    onReset?: () => void;
};

function InstallBackupTool({
    form,
    command,
    cluster,
    onlyForm = false,
    reinstall = false,
    reinstallMessage,
    onFormOpen,
    onJobStart,
    onFinish,
    onCancel,
    onReset,
}: InstallBackupToolProps) {
    const [install, setInstall] = useState(onlyForm);
    const { loading, send } = useCreateJob({
        clusterId: cluster?.clusterId,
        title: `${reinstall ? 'Reconfigure' : 'Install'} ${getBackupToolJobName(
            command
        )}`,
        command: command.toLowerCase() as CmonJobInstanceCommand,
    });

    const handleContinueClick = async () => {
        onFormOpen?.();
        if (reinstall) {
            await handleInstallClick();
        } else {
            setInstall(true);
        }
    };

    const handleCancelClick = () => {
        if (!onlyForm) {
            setInstall(false);
        }
        onCancel?.();
    };

    const handleInstallClick = async () => {
        try {
            switch (command) {
                case CmonJobInstanceCommand.PBMAGENT:
                    await form.validateFields(['__backup_tool_directory']);
                    await send({
                        action: reinstall ? 'reconfigure' : 'setup',
                        nodes: [
                            {
                                class_name: 'CmonPBMAgentHost',
                                backup_dir: form.getFieldValue(
                                    '__backup_tool_directory'
                                ),
                                hostname: '*',
                            },
                        ],
                        clusterId: cluster?.clusterId,
                    });
                    onJobStart?.();
                    break;
                case CmonJobInstanceCommand.PGBACKREST:
                    if (cluster) {
                        await form.validateFields([
                            '__backup_tool_repository_host',
                        ]);
                        const repositoryHost = form.getFieldValue(
                            '__backup_tool_repository_host'
                        );

                        const method = form.getFieldValue(
                            '__backup_tool_config_method'
                        );
                        const hostName =
                            !reinstall && method === 'primary'
                                ? cluster?.getNodesByRoles([
                                      CcNodeRole.PRIMARY,
                                      CcNodeRole.MASTER,
                                  ])?.[0]?.hostname
                                : '*';
                        if (!hostName) {
                            const message =
                                'Could not find primary node hostname';
                            notifyError({ content: message });
                            throw new Error(message);
                        }
                        await send({
                            action: reinstall ? 'reconfigure' : 'setup',
                            nodes: [
                                {
                                    class_name: 'CmonPgBackRestHost',
                                    hostname: hostName,
                                },
                                ...(!reinstall && method === 'all_host'
                                    ? [
                                          {
                                              class_name: 'CmonPgBackRestHost',
                                              hostname: repositoryHost,
                                          },
                                      ]
                                    : []),
                            ],
                            clusterId: cluster?.clusterId,
                        });
                        onJobStart?.();
                    }
                    break;
            }
        } catch (e: any) {
            console.error(`Install backup tool error: ${e.message}`, e);
        }
    };

    const installForm = useMemo(() => {
        switch (command) {
            case CmonJobInstanceCommand.PGBACKREST:
                return (
                    <Form.Item shouldUpdate noStyle>
                        {() => {
                            return (
                                <div>
                                    <Form.Item
                                        name="__backup_tool_config_method"
                                        label={
                                            <Space>Configuration method</Space>
                                        }
                                        initialValue="primary"
                                    >
                                        <Radio.Group>
                                            <Space direction="vertical">
                                                <Radio value="primary">
                                                    Primary{' '}
                                                    <InfoIcon
                                                        info={
                                                            <span>
                                                                Install on the
                                                                current master
                                                                but not on
                                                                slaves. The
                                                                backup
                                                                repository
                                                                (storing backup
                                                                data) will be
                                                                configured to be
                                                                on the master.
                                                                There will be no
                                                                ssh
                                                                configuration
                                                                for PgBackRest.
                                                            </span>
                                                        }
                                                    />
                                                </Radio>
                                                <Radio value="all">
                                                    All DB nodes{' '}
                                                    <InfoIcon
                                                        info={
                                                            <span>
                                                                Install on all
                                                                database hosts.
                                                                The backup
                                                                repository will
                                                                be created on
                                                                the current
                                                                master. Backup
                                                                will be made by
                                                                using standby
                                                                node. PgBackRest
                                                                will use ssh for
                                                                communicating
                                                                between hosts.
                                                            </span>
                                                        }
                                                    />
                                                </Radio>
                                                <Radio value="all_host">
                                                    All DB nodes and dedicated
                                                    repository host{' '}
                                                    <InfoIcon
                                                        info={
                                                            <span>
                                                                Install on all
                                                                database hosts.
                                                                The backup
                                                                repository will
                                                                be made on a
                                                                specified host.
                                                                Backup will be
                                                                made by using
                                                                standby node.
                                                                PgBackRest will
                                                                use ssh for
                                                                communicating
                                                                between hosts.
                                                            </span>
                                                        }
                                                    />
                                                </Radio>
                                            </Space>
                                        </Radio.Group>
                                    </Form.Item>
                                    {form.getFieldValue(
                                        '__backup_tool_config_method'
                                    ) === 'all_host' ? (
                                        <Form.Item
                                            name="__backup_tool_repository_host"
                                            label={
                                                <Space>
                                                    Dedicated repository host
                                                </Space>
                                            }
                                            rules={[
                                                {
                                                    required: true,
                                                    message:
                                                        'Please enter repository host.',
                                                },
                                            ]}
                                        >
                                            <HostCheckInput
                                                clusterId={cluster.clusterId}
                                                placeholder="Enter FQDN or IP-address"
                                            />
                                        </Form.Item>
                                    ) : null}
                                </div>
                            );
                        }}
                    </Form.Item>
                );
            case CmonJobInstanceCommand.PBMAGENT:
                return (
                    <Form.Item
                        name="__backup_tool_directory"
                        label={<Space>Backup directory</Space>}
                        rules={[
                            {
                                required: true,
                                message: 'Please enter backup directory host.',
                            },
                        ]}
                    >
                        <Input placeholder="Enter backup directory" />
                    </Form.Item>
                );
            default:
                return null;
        }
    }, [form, command]);

    const intro = useMemo(() => {
        if (reinstall) {
            return (
                <div style={{ paddingBottom: 50 }}>
                    <TypographyText>
                        {reinstallMessage ||
                            'It is recommended to reconfigure backup tool'}
                    </TypographyText>
                </div>
            );
        }
        switch (command) {
            case CmonJobInstanceCommand.PGBACKREST:
                return (
                    <div style={{ paddingBottom: 50 }}>
                        <TypographyText>
                            <a
                                href="https://pgbackrest.org/"
                                target="_blank"
                                rel="noreferrer"
                            >
                                PgBackRest{' '}
                            </a>
                            - Reliable PostgreSQL Backup & Restore.
                        </TypographyText>
                    </div>
                );
            case CmonJobInstanceCommand.PBMAGENT:
                return (
                    <div style={{ paddingBottom: 50 }}>
                        <TypographyText>
                            <a
                                href="https://www.percona.com/software/mongodb/percona-backup-for-mongodb"
                                target="_blank"
                                rel="noreferrer"
                            >
                                PBM{' '}
                            </a>
                            - Percona Backup for MongoDB is available for
                            MongoDB 4.0 or later versions.
                        </TypographyText>
                    </div>
                );
            default:
                return null;
        }
    }, [command]);
    const jobProgressProps = useMemo(() => {
        const action = reinstall ? 'Reconfiguring' : 'Installing';
        switch (command) {
            case CmonJobInstanceCommand.PGBACKREST:
                return {
                    title: `${action} PgBackRest Backup`,
                    failedMessage: `${action} PgBackRest has failed`,
                    successMessage: `${action} PgBackRest has finished successfully`,
                };
            case CmonJobInstanceCommand.PBMAGENT:
                return {
                    title: `${action} MongoDB Percona Backup`,
                    failedMessage: `${action} MongoDB Percona Backup has failed`,
                    successMessage: `${action} MongoDB Percona Backup has finished successfully`,
                };
            default:
                return {};
        }
    }, [command]);

    const buttons = install ? (
        <Space>
            <Button type="primary" size="middle" onClick={handleInstallClick}>
                {reinstall ? 'Reconfigure' : 'Install'}
            </Button>
            <Button size="middle" onClick={handleCancelClick}>
                Cancel
            </Button>
        </Space>
    ) : (
        <Button type="primary" size="middle" onClick={handleContinueClick}>
            {reinstall ? 'Reconfigure' : 'Continue'}
        </Button>
    );
    return (
        <div className="InstallBackupTool">
            <AppSpin spinning={loading}>
                <JobProgress
                    command={command.toLowerCase() as CmonJobInstanceCommand}
                    cluster={cluster}
                    showReset
                    onFinish={onFinish}
                    onReset={onReset}
                    {...jobProgressProps}
                    jobId={form.getFieldValue('backupToolJobId')}
                    fallback={
                        onlyForm ? (
                            <ContentWrapper>
                                {installForm}
                                {buttons}
                            </ContentWrapper>
                        ) : (
                            <PromoCard
                                title={
                                    reinstall ? (
                                        <TypographyText
                                            type={
                                                install ? undefined : 'danger'
                                            }
                                        >
                                            Reconfigure backup tool
                                        </TypographyText>
                                    ) : (
                                        <TypographyText primary={!install}>
                                            Install new backup tool
                                        </TypographyText>
                                    )
                                }
                                extra={buttons}
                                iconType={install ? undefined : 'install'}
                                bg={
                                    install ? undefined : 'circles-bottom-right'
                                }
                            >
                                {install ? installForm : intro}
                            </PromoCard>
                        )
                    }
                />
            </AppSpin>
        </div>
    );
}

function getBackupToolJobName(command: CmonJobInstanceCommand) {
    switch (command) {
        case CmonJobInstanceCommand.PGBACKREST:
            return 'PgBackRest Backup';
        case CmonJobInstanceCommand.PBMAGENT:
            return 'Percona Backup MongoDB';
        default:
            return '';
    }
}
