const dashboard = {
    title: 'Cluster Overview',
    meta: {
        slug: 'cluster-overview',
        supportClusterTypes: ['all'],
    },
    panels: [
        /*        {
            "title": "Cluster Status",
            "type": "singlestat",
            "gridPos": {
                "h": 4,
                "w": 4,
                "x": 0,
                "y": 0
            },
            "options": {
                "decimals": 0,
                "format": "none",
                "type": "line",
                "legend": {},
                "chart": {
                    "full": false,
                    "show": false
                },
                "valueMaps": [
                    {
                        "op": "=",
                        "value": "Primary"
                    },
                    {
                        "op": "=",
                        "value": "Disconnected",
                        "color": "#DE3C4B"
                    }
                ]
            },
            "datasource": "clustercontrol",
            "targets": [
                {
                    "expr": "node:$node(galera.galeraStatus)",
                    "legendFormat": "{{ galera.galeraStatus }}",
                    "legendAsValue": true
                }
            ]
        },*/
        {
            title: 'Load average 1m',
            type: 'chart',
            gridPos: {
                h: 8,
                w: 8,
                x: 0,
                y: 0,
            },
            options: {
                decimals: 2,
                format: 'short',
                type: 'line',
                legend: {
                    alignAsTable: true,
                },
                yaxis: [
                    {
                        format: 'short',
                        min: 0,
                    },
                ],
                stack: false,
            },
            targets: [
                {
                    expr: 'node_load1{instance=~"$instance"}',
                    legendFormat: '{{ instance }}',
                },
            ],
        },
        {
            title: 'Load average 5m',
            type: 'chart',
            gridPos: {
                h: 8,
                w: 8,
                x: 0,
                y: 0,
            },
            options: {
                decimals: 2,
                format: 'short',
                type: 'line',
                legend: {
                    alignAsTable: true,
                },
                yaxis: [
                    {
                        format: 'short',
                        min: 0,
                    },
                ],
                stack: false,
            },
            targets: [
                {
                    expr: 'node_load5{instance=~"$instance"}',
                    legendFormat: '{{ instance }}',
                },
            ],
        },
        {
            title: 'Load average 15m',
            type: 'chart',
            gridPos: {
                h: 8,
                w: 8,
                x: 0,
                y: 0,
            },
            options: {
                decimals: 2,
                format: 'short',
                type: 'line',
                legend: {
                    alignAsTable: true,
                },
                yaxis: [
                    {
                        format: 'short',
                        min: 0,
                    },
                ],
                stack: false,
            },
            targets: [
                {
                    expr: 'node_load15{instance=~"$instance"}',
                    legendFormat: '{{ instance }}',
                },
            ],
        },
        {
            title: 'Memory available for applications ',
            type: 'chart',
            gridPos: {
                h: 8,
                w: 8,
                x: 0,
                y: 0,
            },
            options: {
                decimals: 2,
                format: 'short',
                type: 'line',
                legend: {
                    alignAsTable: true,
                },
                yaxis: [
                    {
                        format: 'percent',
                        min: 0,
                        max: 100,
                    },
                ],
                stack: false,
            },
            targets: [
                {
                    expr:
                        '100*node_memory_MemAvailable_bytes/node_memory_MemTotal_bytes{instance=~"$instance"}',
                    legendFormat: '{{ instance }}',
                },
            ],
        },
        {
            title: 'Network TX',
            type: 'chart',
            gridPos: {
                h: 8,
                w: 8,
                x: 0,
                y: 0,
            },
            options: {
                decimals: 2,
                format: 'sizeps',
                type: 'line',
                legend: {
                    alignAsTable: true,
                },
                yaxis: [
                    {
                        max: null,
                        min: 0,
                    },
                ],
                stack: false,
            },
            targets: [
                {
                    expr:
                        'sum(rate(node_network_transmit_bytes_total{instance=~"$instance",device!~"lo|bond[0-9]|cbr[0-9]|veth.*"}[5m])) by (instance)',
                    legendFormat: '{{ instance }}',
                },
            ],
        },
        {
            title: 'Network RX',
            type: 'chart',
            gridPos: {
                h: 8,
                w: 8,
                x: 0,
                y: 0,
            },
            options: {
                decimals: 2,
                format: 'sizeps',
                type: 'line',
                legend: {
                    alignAsTable: true,
                },
                yaxis: [
                    {
                        max: null,
                        min: 0,
                    },
                ],
                stack: false,
            },
            targets: [
                {
                    expr:
                        'sum(rate(node_network_receive_bytes_total{instance=~"$instance",device!~"lo|bond[0-9]|cbr[0-9]|veth.*"}[5m])) by (instance)',
                    legendFormat: '{{ instance }}',
                },
            ],
        },
        {
            title: 'Disk read IOPS',
            type: 'chart',
            gridPos: {
                h: 8,
                w: 8,
                x: 0,
                y: 0,
            },
            options: {
                decimals: 1,
                format: 'short',
                type: 'line',
                legend: {
                    alignAsTable: true,
                },
                yaxis: [
                    {
                        max: null,
                        min: 0,
                    },
                ],
                stack: false,
            },
            targets: [
                {
                    expr:
                        'sum(rate(node_disk_reads_completed_total{instance=~"$instance"}[5m])) by (instance)',
                    legendFormat: '{{ instance }}',
                },
            ],
        },
        {
            title: 'Disk write IOPS',
            type: 'chart',
            gridPos: {
                h: 8,
                w: 8,
                x: 0,
                y: 0,
            },
            options: {
                decimals: 1,
                format: 'short',
                type: 'line',
                legend: {
                    alignAsTable: true,
                },
                yaxis: [
                    {
                        max: null,
                        min: 0,
                    },
                ],
                stack: false,
            },
            targets: [
                {
                    expr:
                        'sum(rate(node_disk_writes_completed_total{instance=~"$instance"}[5m])) by (instance)',
                    legendFormat: '{{ instance }}',
                },
            ],
        },
        {
            title: 'Disk write + read IOPS',
            type: 'chart',
            gridPos: {
                h: 8,
                w: 8,
                x: 0,
                y: 0,
            },
            options: {
                decimals: 1,
                format: 'short',
                type: 'line',
                legend: {
                    alignAsTable: true,
                },
                yaxis: [
                    {
                        max: null,
                        min: 0,
                    },
                ],
                stack: false,
            },
            targets: [
                {
                    expr:
                        'sum(rate(node_disk_reads_completed_total{instance=~"$instance"}[5m])) by (instance) + sum(rate(node_disk_writes_completed_total[5m])) by (instance)',
                    legendFormat: '{{ instance }}',
                },
            ],
        },
        {
            title: 'DB queries',
            type: 'chart',
            gridPos: {
                h: 8,
                w: 8,
                x: 0,
                y: 0,
            },
            options: {
                decimals: 0,
                format: 'short',
                type: 'line',
                legend: {
                    alignAsTable: true,
                },
                yaxis: [
                    {
                        format: 'short',
                        max: null,
                        min: 0,
                    },
                ],
                stack: false,
                postfix: '/s',
            },
            targets: [
                {
                    expr:
                        'rate(mysql_global_status_queries{instance=~"$instance"}[5m])',
                    legendFormat: '{{ instance }}',
                },
            ],
        },
        {
            title: 'DB connections used (%)',
            type: 'chart',
            gridPos: {
                h: 8,
                w: 8,
                x: 0,
                y: 0,
            },
            options: {
                decimals: 0,
                format: 'percentage',
                type: 'area',
                legend: {
                    alignAsTable: true,
                },
                yaxis: [
                    {
                        format: 'short',
                        max: 100,
                        min: 0,
                    },
                ],
                stack: false,
            },
            targets: [
                {
                    expr:
                        '100* mysql_global_status_threads_connected{instance=~"$instance"} / mysql_global_variables_max_connections{instance=~"$instance"}',
                    legendFormat: '{{ instance }}',
                },
            ],
        },
        {
            title: 'InnoDB bufferpool hit ratio',
            type: 'chart',
            gridPos: {
                h: 8,
                w: 8,
                x: 0,
                y: 0,
            },
            options: {
                decimals: 2,
                format: 'short',
                type: 'line',
                legend: {
                    alignAsTable: true,
                },
                yaxis: [
                    {
                        format: 'short',
                        max: 1000,
                        min: 0,
                    },
                ],
                stack: false,
            },
            targets: [
                {
                    expr:
                        '1000*mysql_global_status_innodb_buffer_pool_read_requests{instance=~"$instance"} / (mysql_global_status_innodb_buffer_pool_reads{instance=~"$instance"} + mysql_global_status_innodb_buffer_pool_read_requests{instance=~"$instance"})',
                    legendFormat: '{{ instance }}',
                },
            ],
        },
        {
            title: 'MySQL network - TX',
            type: 'chart',
            gridPos: {
                h: 8,
                w: 8,
                x: 0,
                y: 0,
            },
            options: {
                decimals: 2,
                format: 'short',
                type: 'line',
                legend: {
                    alignAsTable: true,
                },
                yaxis: [
                    {
                        format: 'sizeps',
                        max: null,
                        min: 0,
                    },
                ],
                stack: false,
            },
            targets: [
                {
                    expr:
                        'rate(mysql_global_status_bytes_sent{instance=~"$instance"}[5m])',
                    legendFormat: '{{ instance }}',
                },
            ],
        },
        {
            title: 'MySQL network - RX',
            type: 'chart',
            gridPos: {
                h: 8,
                w: 8,
                x: 0,
                y: 0,
            },
            options: {
                decimals: 2,
                format: 'short',
                type: 'line',
                legend: {
                    alignAsTable: true,
                },
                yaxis: [
                    {
                        format: 'sizeps',
                        max: null,
                        min: 0,
                    },
                ],
                stack: false,
            },
            targets: [
                {
                    expr:
                        'rate(mysql_global_status_bytes_received{instance=~"$instance"}[5m])',
                    legendFormat: '{{ instance }}',
                },
            ],
        },
    ],
    templating: {
        list: [
            {
                allFormat: 'pipe',
                allValue: null,
                datasource: 'clustercontrol',
                hide: 0,
                multi: true,
                multiFormat: 'pipe',
                includeAll: true,
                label: 'Host',
                name: 'instance',
                query: 'host:$',
                optionValue: 'hostname',
                optionText: '{{hostname}} ({{types}})',
                sort: 1,
                isInstancePicker: true,
            },
        ],
    },
    schemaVersion: 1,
};
export default dashboard;
