import React, { useState, useRef } from 'react';
import ModalDefault from '../../common/ModalDefault';
import { notifyConfirmCloseWindow } from '../Notifications/uiNotification';
import MotionShake, { MotionApi } from '../../common/motion/MotionShake';

import CcCluster from '../../services/models/CcCluster';
import LoadBalancerWizard from './LoadBalancerWizard';

export default LoadBalancerModal;

export type LoadBalancerModalProps = {
    cluster?: CcCluster;
    showModalHandler?: () => void;
    onSuccess?: () => void;
    onCancel?: () => void;
    changeVisible?: (value: boolean) => void;
    visible?: boolean;
};

function LoadBalancerModal({ cluster, onCancel }: LoadBalancerModalProps) {
    const animationRef = useRef<MotionApi | undefined>();
    const [title, setTitle] = useState<String>('');

    const [confirmClose, setConfirmClose] = useState(false);

    const stepChangeHandler = (modetitle: String) => {
        setTitle(modetitle);
    };

    const handleFormTouchedChange = (touched: boolean) => {
        setConfirmClose(touched);
    };

    const handleCancel = () => {
        if (confirmClose) {
            notifyConfirmCloseWindow({
                onOk: () => {
                    onCancel?.();
                },
            });
        } else {
            onCancel?.();
        }
    };

    const handleFormStepErrorInsist = (err: Error) => {
        if (animationRef.current) {
            animationRef.current.animate();
        }
    };
    return (
        <ModalDefault
            title={title}
            animateHeight={true}
            visible={true}
            onCancel={handleCancel}
            modalRender={(content) => (
                <MotionShake ref={animationRef}>{content}</MotionShake>
            )}
        >
            <LoadBalancerWizard
                cluster={cluster}
                onChangeTitle={stepChangeHandler}
                onCancel={onCancel}
                onFormStepErrorInsist={handleFormStepErrorInsist}
                onFormTouchedChange={handleFormTouchedChange}
            />
        </ModalDefault>
    );
}
