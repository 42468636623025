import React from 'react';
import { AutoComplete, Form } from 'antd';
import { TopologyItem } from '../../../components/Topology/TopologyItem';
import { FormItemProps } from 'antd/lib/form';

export default TopologyNodeDataIpField;

export type TopologyNodeDataIpFieldProps = FormItemProps & {
    item: TopologyItem;
};

function TopologyNodeDataIpField({
    item,
    ...rest
}: TopologyNodeDataIpFieldProps) {
    const interfaces =
        item.extraData?.checkedHost?.hardware?.network_interfaces || [];
    return (
        <Form.Item
            label="Data IP (optional)"
            name="nodeDataIp"
            style={{ marginBottom: '5px' }}
            {...rest}
        >
            <AutoComplete
                placeholder="Enter data IP"
                options={Object.keys(interfaces).map((key: string) => ({
                    value: interfaces[key],
                    label: `${key} - ${interfaces[key]}`,
                }))}
            />
        </Form.Item>
    );
}
