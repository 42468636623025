import FormItemInlineSwitch from '../../../../common/DataEntry/FormItemInlineSwitch';
import { Space } from 'antd';
import React, { useMemo } from 'react';
import { CcClusterType } from '../../../../services/models/CcCluster';
import InfoIcon from '@severalnines/bar-frontend-components/build/lib/General/InfoIcon';

export default SslEncryptionField;
type SslEncryptionFieldProps = {
    clusterType?: CcClusterType;
};

function SslEncryptionField({ clusterType }: SslEncryptionFieldProps) {
    const info = useMemo(() => {
        switch (clusterType) {
            case CcClusterType.TYPE_GALERA:
                return (
                    <InfoIcon
                        info={
                            <span>
                                Enabling SSL encryption configures Galera
                                Replication SSL encryption and SST encryption.
                                This option is ignored with certain vendors.
                                Percona XtraDB 8.0 enable SSL by default
                                regardless of this setting.
                            </span>
                        }
                    />
                );
        }
        return null;
    }, [clusterType]);

    return (
        <FormItemInlineSwitch
            justify
            name={['nodeConfig', 'sslEncryption']}
            label={
                <Space>
                    <span>Enable SSL encryption</span>
                    {info}
                </Space>
            }
            valuePropName="checked"
        />
    );
}
