import React from 'react';
import CcCluster, {
    CcClusterBase,
    CcClusterTechnology,
    CcClusterType,
} from '../../../services/models/CcCluster';
import ActionsMenu, {
    ActionsMenuProps,
} from '../../../common/Navigation/ActionsMenu';
import ClusterToggleAutoRecoveryButton from './ClusterToggleAutoRecoveryButton';
import ClusterToggleNodeAutoRecoveryButton from './ClusterToggleNodeAutoRecoveryButton';
import ClusterEnableReadonlyButton from './ClusterEnableReadonlyButton';
import ClusterRollingRestartButton from './ClusterRollingRestartButton';
import ClusterDeleteButton from './ClusterDeleteButton';
import AddMaintenanceButton from '../../Maintenance/AddMaintenanceButton';
import AddRpcButton from './RpcToken/AddRpcButton';
import DisableMaintenanceButton from '../../Maintenance/DisableMaintenanceButton';
import ClusterConfigureWalButton from './ClusterConfigureWalButton';
import useMaintenanceWatcher from '../../Maintenance/useMaintenanceWatcher';
import ManageMaintenanceButton from '../../Maintenance/ManageMaintenanceButton';
import ClusterAddReplicationSecondary from './ClusterAddReplicationSecondary';
import ClusterAddNode from './ClusterAddNode';
import CcPostgreSqlNode from '../../../services/models/CcPostgreSqlNode';
import ClusterEnableTimescaledbButton from './ClusterEnableTimescaledbButton';
import ClusterBootstrapButton from './ClusterBootstrapButton';
import ClusterCloneButton from './ClusterCloneButton';
import ClusterFindMostAdvancedNodeButton from './ClusterFindMostAdvancedNodeButton';
import ClusterCreateReplicationButton from './ClusterCreateReplicationButton';
import LoadBalancerWizardButton from '../../LoadBalancer/LoadBalancerWizardButton';
import ClusterDisableReadonlyButton from './ClusterDisableReadonlyButton';
import ClusterStopButton from './ClusterStopButton';
import { ActionMenuItem } from '@severalnines/bar-frontend-components/build/lib/Navigation/ActionMenu';
import ClusterAddShardButton from './ClusterAddShardButton';
import ClusterConvertToShardButton from './ClusterConvertToShardButton';
import ClusterRemoveShardButton from './ClusterRemoveShard/ClusterRemoveShardButton';
import ClusterNodesUpgrade from './ClusterNodesUpgrade';
import ClusterDisableSSLButton from './ClusterDisableSSLButton';
import ClusterEnableSSLButton from '../../Security/ClusterEnableSSLButton';
import GaleraDisableSSLButton from './GaleraDisableSSLButton';
import DisableAuditLogButton from '../../Security/DisableAuditLogButton';
import EnableAuditLogButton from '../../Security/EnableAuditLogButton';
import AppDivider from '../../../common/AppDivider';
import ClusterEditDetailsButton from './ClusterEditDetails/ClusterEditDetailsButton';

export default ClusterActionsMenu;

export type ClusterActionsMenuProps = ActionsMenuProps & {
    cluster: CcCluster;
    onActionPerformed?: () => void;
};

function ClusterActionsMenu({
    cluster,
    onActionPerformed,
    ...rest
}: ClusterActionsMenuProps) {
    const {
        active: activeMaintenance,
        next: nextMaintenance,
    } = useMaintenanceWatcher({
        clusterId: cluster.clusterId,
    });

    const handleActionSuccess = () => {
        onActionPerformed?.();
    };

    const enableAuditLog =
        cluster.isAuditLogSupported() && !cluster.isAuditLogEnabled()
            ? {
                  key: 'enable-audit-log',
                  label: <EnableAuditLogButton cluster={cluster} />,
              }
            : undefined;

    const disableAuditLog =
        cluster.isAuditLogSupported() && cluster.isAuditLogEnabled()
            ? {
                  key: 'disable-audit-log',
                  label: <DisableAuditLogButton cluster={cluster} />,
                  waitForConfirm: true,
              }
            : undefined;

    const regularEnableSSL = cluster.isRegularSSLSupported()
        ? {
              key: 'cluster-enable-ssl',
              label: <ClusterEnableSSLButton cluster={cluster} />,
          }
        : undefined;

    const regularDisableSSL =
        cluster.isRegularSSLSupported() &&
        cluster.isDisablingRegularSSLSupported() &&
        cluster.isRegularSSLEnabled()
            ? {
                  key: 'cluster-disable-ssl',
                  label: <ClusterDisableSSLButton cluster={cluster} />,
                  waitForConfirm: true,
              }
            : undefined;

    const galeraEnableSSL = cluster.isReplicationSSLSupported()
        ? {
              key: 'galera-cluster-enable-ssl',
              label: <ClusterEnableSSLButton galera={true} cluster={cluster} />,
          }
        : undefined;

    const galeraDisableSSL =
        cluster.isReplicationSSLSupported() && cluster.isReplicationSSLEnabled()
            ? {
                  key: 'galera-cluster-disable-ssl',
                  label: <GaleraDisableSSLButton cluster={cluster} />,
                  waitForConfirm: true,
              }
            : undefined;

    const addLoadBalancer = [
        CcClusterType.TYPE_REPLICATION,
        CcClusterType.TYPE_GALERA,
        CcClusterType.TYPE_POSTGRESQL,
        CcClusterType.TYPE_TIMESCALEDB,
    ].includes(cluster.clusterType)
        ? {
              key: 'cluster-load-balancer',
              label: <LoadBalancerWizardButton cluster={cluster} />,
          }
        : undefined;
    const clusterChangeRPCAPItoken = {
        key: 'cluster-change-rpc-token',
        label: <AddRpcButton cluster={cluster} />,
    };

    const clusterEnableMaintenanceModeButton = {
        key: 'cluster-enable-maintenance-mode',
        label: <AddMaintenanceButton cluster={cluster} />,
    };

    const clusterManageMaintenanceModeButton =
        nextMaintenance || activeMaintenance
            ? {
                  key: 'cluster-manage-maintenance-mode',
                  label: (
                      <ManageMaintenanceButton
                          cluster={cluster}
                          onSuccess={handleActionSuccess}
                      />
                  ),
              }
            : undefined;

    const clusterDisableMaintenanceModeButton = cluster.isMaintenanceModeEnabled()
        ? {
              key: 'cluster-disable-maintenance-mode',
              label: (
                  <DisableMaintenanceButton
                      cluster={cluster}
                      onSuccess={handleActionSuccess}
                  />
              ),
              waitForConfir: true,
          }
        : undefined;

    const clusterConfigureWalButton = cluster.isBase(
        CcClusterBase.BASE_POSTGRESQL
    )
        ? {
              key: 'cluster-configure-wal',
              label: <ClusterConfigureWalButton cluster={cluster} />,
          }
        : undefined;

    const clusterAddReplicationSecondary = [
        CcClusterType.TYPE_REPLICATION,
        CcClusterType.TYPE_GALERA,
        CcClusterType.TYPE_POSTGRESQL,
        CcClusterType.TYPE_TIMESCALEDB,
    ].includes(cluster.clusterType)
        ? {
              key: 'cluster-add-replication-secondary',
              label: <ClusterAddReplicationSecondary cluster={cluster} />,
          }
        : undefined;

    const clusterAddNode = [
        CcClusterType.TYPE_GALERA,
        CcClusterType.TYPE_MONGODB,
        CcClusterType.TYPE_MONGODB_SHARDS,
        CcClusterType.TYPE_ELASTIC,
        CcClusterType.TYPE_REDIS,
        CcClusterType.TYPE_MSSQL_AO_ASYNC,
    ].includes(cluster.clusterType)
        ? {
              key: 'cluster-add-node',
              label: <ClusterAddNode cluster={cluster} />,
          }
        : undefined;

    const clusterAddShard = [CcClusterType.TYPE_MONGODB_SHARDS].includes(
        cluster.clusterType
    )
        ? {
              key: 'cluster-add-shard',
              label: <ClusterAddShardButton cluster={cluster} />,
          }
        : undefined;

    const clusterConvertToShard = [CcClusterType.TYPE_MONGODB].includes(
        cluster.clusterType
    )
        ? {
              key: 'cluster-convert-to-shard',
              label: <ClusterConvertToShardButton cluster={cluster} />,
          }
        : undefined;

    const clusterRemoveShard = [CcClusterType.TYPE_MONGODB_SHARDS].includes(
        cluster.clusterType
    )
        ? {
              key: 'cluster-remove-shard',
              label: <ClusterRemoveShardButton cluster={cluster} />,
          }
        : undefined;

    const clusterEnableTimescaledb =
        [CcClusterType.TYPE_POSTGRESQL].includes(cluster.clusterType) &&
        !cluster
            .getDatabaseNodes()
            .find((n) => (n as CcPostgreSqlNode).isTimescaledb())
            ? {
                  key: 'cluster-enable-timescaledb',
                  label: <ClusterEnableTimescaledbButton cluster={cluster} />,
                  waitForConfirm: true,
              }
            : undefined;

    const items: ActionMenuItem[] = [
        clusterEnableMaintenanceModeButton,
        clusterManageMaintenanceModeButton,
        clusterDisableMaintenanceModeButton,
        clusterAddNode,
        clusterAddShard,
        clusterConvertToShard,
        clusterRemoveShard,
        clusterAddReplicationSecondary,
        addLoadBalancer,
        enableAuditLog,
        disableAuditLog,
        regularEnableSSL,
        regularDisableSSL,
        galeraEnableSSL,
        galeraDisableSSL,
        clusterChangeRPCAPItoken,
        {
            key: 'toggle-autorecovery',
            label: <ClusterToggleAutoRecoveryButton cluster={cluster} />,
            waitForConfirm: true,
        },
        {
            key: 'toggle-node-autorecovery',
            label: <ClusterToggleNodeAutoRecoveryButton cluster={cluster} />,
            waitForConfirm: true,
        },
        clusterConfigureWalButton,
        cluster.isTechnology(CcClusterTechnology.TECHNOLOGY_MYSQL) &&
        !cluster.isReadonly()
            ? {
                  key: 'enable-readonly',
                  label: <ClusterEnableReadonlyButton cluster={cluster} />,
                  waitForConfirm: true,
              }
            : undefined,
        cluster.isTechnology(CcClusterTechnology.TECHNOLOGY_MYSQL) &&
        cluster.isReadonly()
            ? {
                  key: 'disable-readonly',
                  waitForConfirm: true,
                  label: <ClusterDisableReadonlyButton cluster={cluster} />,
              }
            : undefined,
        cluster.isType(CcClusterType.TYPE_GALERA)
            ? {
                  key: 'rolling-restart',
                  label: <ClusterRollingRestartButton cluster={cluster} />,
              }
            : undefined,
        cluster.isType(CcClusterType.TYPE_GALERA)
            ? {
                  key: 'clone-cluster',
                  label: <ClusterCloneButton cluster={cluster} />,
              }
            : undefined,
        cluster.isType(CcClusterType.TYPE_GALERA)
            ? {
                  key: 'find-most-advanced-node',
                  waitForConfirm: true,
                  label: (
                      <ClusterFindMostAdvancedNodeButton cluster={cluster} />
                  ),
              }
            : undefined,
        cluster.isType(CcClusterType.TYPE_GALERA) ||
        cluster.isType(CcClusterType.TYPE_POSTGRESQL) ||
        cluster.isType(CcClusterType.TYPE_TIMESCALEDB)
            ? {
                  key: 'create-replica-cluster',
                  label: <ClusterCreateReplicationButton cluster={cluster} />,
              }
            : undefined,
        cluster.isTechnology(CcClusterTechnology.TECHNOLOGY_MYSQL) ||
        cluster.isTechnology(CcClusterTechnology.TECHNOLOGY_POSTGRESQL)
            ? {
                  key: 'upgrade-nodes',
                  label: <ClusterNodesUpgrade cluster={cluster} />,
              }
            : undefined,
        clusterEnableTimescaledb,
        cluster.isType(CcClusterType.TYPE_GALERA)
            ? {
                  key: 'bootstrap-cluster',
                  label: <ClusterBootstrapButton cluster={cluster} />,
              }
            : undefined,
        cluster.isType(CcClusterType.TYPE_GALERA)
            ? {
                  key: 'stop-cluster',
                  label: <ClusterStopButton cluster={cluster} />,
              }
            : undefined,
        { type: 'divider', label: <AppDivider /> },
        {
            key: 'edit',
            label: (
                <ClusterEditDetailsButton
                    cluster={cluster}
                    onSuccess={handleActionSuccess}
                />
            ),
        },
        {
            key: 'delete',
            label: <ClusterDeleteButton cluster={cluster} />,
        },
    ] as ActionMenuItem[];
    return <ActionsMenu items={items} {...rest} />;
}
