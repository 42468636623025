import useListFetch, { UseListFetchProps } from '../../common/useListFetch';
import { AppConfig } from '../../AppConfig';
import CmonStatService from '../../services/cmon/CmonStatService';
export type UseQMProcessesListProps = Omit<UseListFetchProps, 'fetchFn'> & {
    pageSize?: number;
    clusterId: number;
};

export default function useQMProcessesList({
    pageSize = AppConfig.defaultPaginationConfig.pageSize,
    useCache,
    filters,
    name,
    clusterId,
}: UseQMProcessesListProps) {
    const {
        list: topQueries,
        loading,
        refresh,
        page,
        pageSize: listPageSize,
        total,
    } = useListFetch({
        name: 'qm-process-list',
        pageSize,
        fetchFn: async ({ pageSize, page, ...rest }, opts) => {
            const { data, total } = await CmonStatService.qm_processes(
                {
                    cluster_id: clusterId,
                    limit: pageSize,
                    offset: ((page || 1) - 1) * pageSize,
                    ...rest,
                },
                opts
            );

            return {
                list: data,
                total,
            };
        },
        cancelFn: async ({ requestId }) => {
            await CmonStatService.cancelRequest(requestId);
        },
    });

    const list: any[] = topQueries;
    return {
        list,
        loading,
        refresh,
        page,
        pageSize: listPageSize,
        total,
    };
}
