import { ButtonProps } from 'antd/lib/button';
import React, { useState } from 'react';
import CmonJobService from '../../services/cmon/CmonJobsService';
import CcBackupSchedule from '../../services/models/CcBackupSchedule';
import { notifyError } from '../Notifications/uiNotification';
import AppConfirmActionButton from '../../common/General/AppConfirmActionButton';

export type BackupScheduleDeleteActionButtonProps = ButtonProps & {
    schedule: CcBackupSchedule;
    onSuccess?: () => void;
};
const BackupScheduleDeleteActionButton = ({
    children,
    schedule,
    onSuccess,
    ...rest
}: BackupScheduleDeleteActionButtonProps) => {
    const [loading, setLoading] = useState(false);
    const handleConfirm = async () => {
        try {
            setLoading(true);
            await CmonJobService.deleteJobInstance({
                job_id: schedule.jobId,
            });
            if (onSuccess) {
                onSuccess();
            }
        } catch (err) {
            notifyError({ size: 'large', content: err.message });
        } finally {
            setLoading(false);
        }
    };
    return (
        <AppConfirmActionButton
            critical={true}
            confirmTitle={
                <span>
                    Delete schedule <b>#{schedule.jobId}</b>?
                </span>
            }
            onConfirm={handleConfirm}
            loading={loading}
            {...rest}
        >
            {children || 'Delete'}
        </AppConfirmActionButton>
    );
};
export default BackupScheduleDeleteActionButton;
