const dashboard = {
    title: 'MongoDB Cluster Overview',
    meta: {
        slug: 'mongodb-cluster-overview',
        supportClusterTypes: ['mongodb', 'mongodb_shards'],
    },
    panels: [
        {
            title: 'Op counters (all mongos)',
            type: 'chart',
            options: {
                decimals: null,
                format: 'short',
                type: 'area',
                legend: {
                    alignAsTable: true,
                },
                yaxis: [
                    {
                        format: 'short',
                        max: null,
                        min: 0,
                    },
                ],
                stack: false,
            },
            targets: [
                {
                    expr:
                        'sum(irate(mongodb_mongos_op_counters_total{type="command"}[5m]))',
                    legendFormat: 'command',
                },
                {
                    expr:
                        'sum(irate(mongodb_mongos_op_counters_total{type="delete"}[5m]))',
                    legendFormat: 'delete',
                },
                {
                    expr:
                        'sum(irate(mongodb_mongos_op_counters_total{type="insert"}[5m]))',
                    legendFormat: 'insert',
                },
                {
                    expr:
                        'sum(irate(mongodb_mongos_op_counters_total{type="getmore"}[5m]))',
                    legendFormat: 'getmore',
                },
                {
                    expr:
                        'sum(irate(mongodb_mongos_op_counters_total{type="update"}[5m]))',
                    legendFormat: 'update',
                },
                {
                    expr:
                        'sum(irate(mongodb_mongos_op_counters_total{type="query"}[5m]))',
                    legendFormat: 'query',
                },
            ],
            gridPos: {
                h: 8,
                w: 12,
                x: 0,
                y: 0,
            },
        },
        {
            title: 'Connections (all mongos) ',
            type: 'chart',
            options: {
                decimals: null,
                format: 'short',
                type: 'area',
                legend: {
                    alignAsTable: true,
                },
                yaxis: [
                    {
                        format: 'short',
                        max: null,
                        min: 0,
                    },
                    {
                        format: 'short',
                        max: 100,
                        min: 0,
                    },
                ],
                stack: false,
            },
            targets: [
                {
                    expr: 'sum(mongodb_mongos_connections{state="current"})',
                    legendFormat: 'Total current',
                },
                {
                    expr: 'sum(mongodb_mongos_connections{state="available"})',
                    legendFormat: 'Total available',
                },
                {
                    expr:
                        '100 * (sum(mongodb_mongos_connections{state="current"}) / sum(mongodb_mongos_connections))',
                    legendFormat: 'used %',
                    yaxis: 1,
                },
            ],
            gridPos: {
                h: 8,
                w: 12,
                x: 0,
                y: 0,
            },
        },
        {
            title: 'Asserts (all mongos)',
            type: 'chart',
            options: {
                decimals: null,
                format: 'short',
                type: 'line',
                legend: {
                    alignAsTable: true,
                },
                yaxis: [
                    {
                        format: 'short',
                        max: null,
                        min: 0,
                    },
                    {
                        format: 'short',
                        max: null,
                        min: null,
                    },
                ],
                stack: false,
            },
            targets: [
                {
                    expr:
                        'sum(irate(mongodb_mongos_asserts_total{type="msg"}[5m]))',
                    legendFormat: 'msg',
                },
                {
                    expr:
                        'sum(irate(mongodb_mongos_asserts_total{type="regular"}[5m]))',
                    legendFormat: 'regular',
                },
                {
                    expr:
                        'sum(irate(mongodb_mongos_asserts_total{type="rollovers"}[5m]))',
                    legendFormat: 'rollovers',
                },
                {
                    expr:
                        'sum(irate(mongodb_mongos_asserts_total{type="user"}[5m]))',
                    legendFormat: 'user',
                },
                {
                    expr:
                        'sum(irate(mongodb_mongos_asserts_total{type="warning"}[5m]))',
                    legendFormat: 'warning',
                },
            ],
            gridPos: {
                h: 7,
                w: 12,
                x: 0,
                y: 0,
            },
        },
        {
            title: 'Open cursors (all mongos)',
            type: 'chart',
            gridPos: {
                h: 8,
                w: 12,
                x: 0,
                y: 0,
            },
            options: {
                decimals: 0,
                format: 'short',
                type: 'line',
                legend: {
                    alignAsTable: true,
                },
                yaxis: [
                    {
                        max: null,
                        min: 0,
                    },
                ],
                stack: false,
            },
            targets: [
                {
                    expr:
                        'sum(mongodb_mongos_metrics_cursor_open{state="total"})',
                    legendFormat: 'Total',
                },
                {
                    expr:
                        'sum(mongodb_mongos_metrics_cursor_open{state="pinned"})',
                    legendFormat: 'Pinned ',
                },
                {
                    expr:
                        'sum(mongodb_mongos_metrics_cursor_open{state="noTimeout"})',
                    legendFormat: 'Timed-out',
                },
            ],
        },
        {
            title: 'Timed-out cursors (all mongos)',
            type: 'chart',
            gridPos: {
                h: 8,
                w: 12,
                x: 0,
                y: 0,
            },
            options: {
                decimals: 0,
                format: 'short',
                type: 'line',
                legend: {
                    alignAsTable: true,
                },
                yaxis: [
                    {
                        max: null,
                        min: 0,
                    },
                ],
                stack: false,
            },
            targets: [
                {
                    expr: 'sum(mongodb_mongos_metrics_cursor_timed_out_total)',
                    legendFormat: 'Timed-out',
                },
            ],
        },
    ],
    templating: {
        list: [
            {
                allFormat: 'pipe',
                allValue: null,
                datasource: 'clustercontrol',
                hide: 0,
                multi: true,
                multiFormat: 'pipe',
                includeAll: true,
                label: 'Host',
                name: 'instance',
                query: 'host:$[nodes[nodetype="mongo"]]',
                optionValue: 'hostname',
                optionText: 'hostname',
                sort: 1,
                isInstancePicker: true,
            },
        ],
    },
};
export default dashboard;
