import React from 'react';
import { Button, Form, Space } from 'antd';
import ModalDefaultForm from '../../../common/ModalDefaultForm';
import CcCluster from '../../../services/models/CcCluster';
import ContentWrapper from '../../../common/Layout/ContentWrapper';
import usePBMSetup from './usePBMSetup';
import BackupToolPBMSetupForm from './BackupToolPBMSetupForm';

export default BackupToolPBMSetupModal;

export type BackupToolPBMSetupModalProps = {
    cluster: CcCluster;
    onSuccess?: () => void;
    onCancel?: () => void;
    reinstall?: boolean;
};

function BackupToolPBMSetupModal({
    cluster,
    onSuccess,
    onCancel,
    reinstall = true,
}: BackupToolPBMSetupModalProps) {
    const [form] = Form.useForm();

    const { loading, setup } = usePBMSetup({
        cluster,
        form,
        onSuccess,
        reinstall,
    });

    const handleInstallClick = async () => {
        await setup();
    };

    const action = reinstall ? 'Reinstall' : 'Install';

    return (
        <ModalDefaultForm
            title={`${action} Percona Backup MongoDB`}
            form={form}
            footer={[]}
            onCancel={onCancel}
            width={400}
            defaultVisible={true}
            bodyStyle={{ padding: 0 }}
            loading={loading}
        >
            <Form form={form} layout="vertical">
                <ContentWrapper>
                    <BackupToolPBMSetupForm />
                    <Space>
                        <Button
                            type="primary"
                            size="middle"
                            onClick={handleInstallClick}
                        >
                            {action}
                        </Button>
                        <Button size="middle" onClick={onCancel}>
                            Cancel
                        </Button>
                    </Space>
                </ContentWrapper>
            </Form>
        </ModalDefaultForm>
    );
}
