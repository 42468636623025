export type ObjectPropertyPath = (string | number)[];

export function getObjectValueByPath(path: ObjectPropertyPath, fields: any) {
    return path.reduce((a: any, i) => a?.[i], fields);
}

/**
 * Converts object to array of path:
 * {parent: {field: any}, field: any} => [['parent', 'field'], ['field']]
 * @param object
 * @param levels - restricts how depp function dives in objects
 */
export function objectToPathArray(
    object: any,
    levels: number | null = null
): ObjectPropertyPath[] {
    if (null !== levels) {
        if (levels < 1) {
            return [];
        }
        levels--;
    }

    return Object.keys(object)
        .map((key) =>
            (null === levels || levels > 0) &&
            object[key] &&
            !Array.isArray(object[key]) &&
            typeof object[key] === 'object' &&
            Object.keys(object[key])?.length > 0
                ? objectToPathArray(object[key], levels).map((field) => [
                      key,
                      ...field,
                  ])
                : [[key]]
        )
        .flat();
}

export function getObjectIntersectionValues(
    object1: any,
    object2: any,
    levels?: number
) {
    return objectToPathArray(object1, levels)
        .map((field) => getObjectValueByPath(field, object2) as any)
        .filter((value) => value !== undefined);
}

/**
 * Removes properties which didn't pass filter
 * @param object
 * @param filter
 * @param levels
 */
export function removeObjectProperties(
    object: any,
    filter: (field: ObjectPropertyPath) => boolean,
    levels: number | null = null
) {
    return objectToPathArray(object, levels).reduce((a, field) => {
        const currentField = [...field];
        const fieldName = currentField.pop();
        const currentObject = getObjectValueByPath(currentField, a);
        if (currentObject && fieldName && filter(field)) {
            delete currentObject[fieldName];
        }
        return a;
        // @todo if we need work with complex object that contains e.g. Date, Map, etc,
        // @todo then need to find proper deep clone solution
    }, JSON.parse(JSON.stringify(object)));
}

/**
 * Receivers data and mapping form object to this data fields
 * Mapping contains handlers: {field: (data) => any}
 * Or data field path: {field: ['parent', 'value']}
 * @param data
 * @param propertiesMap
 */
export function convertObjectByRules(data: any, propertiesMap: any): any {
    let fields: any = {};
    Object.keys(propertiesMap).forEach((key) => {
        let value: any = undefined;
        if (Array.isArray(propertiesMap[key])) {
            value = propertiesMap[key].reduce(
                (a: any, i: string) => a?.[i],
                data
            );
        } else if (
            propertiesMap[key] &&
            {}.toString.call(propertiesMap[key]) === '[object Function]'
        ) {
            value = propertiesMap[key](data);
        } else if (typeof propertiesMap[key] === 'object') {
            value = convertObjectByRules(data, propertiesMap[key]);
        }
        if (undefined !== value) {
            fields[key] = value;
        }
    });
    return fields;
}

export function checkObjectPropRules(
    path: ObjectPropertyPath,
    object: any,
    rules: any
) {
    const condition: (fields: any) => boolean =
        getObjectValueByPath(path, rules) || undefined;
    return (
        condition === undefined ||
        typeof condition !== 'function' ||
        condition(object)
    );
}
