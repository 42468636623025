import { useEffect, useState } from 'react';
import { CcMaintenancePeriod } from '../../services/models/CcMaintenanceInfo';
import { AppState, AppStateScheduledMaintenances } from '../../appReducer';
import { useSelector } from 'react-redux';

export type UseMaintenanceWatcherProps = {
    clusterId?: number;
    hostname?: string;
};
export default function useMaintenanceWatcher({
    clusterId,
    hostname,
}: UseMaintenanceWatcherProps): {
    active: CcMaintenancePeriod | null;
    next: CcMaintenancePeriod | null;
} {
    const [active, setActive] = useState<CcMaintenancePeriod | null>(null);
    const [next, setNext] = useState<CcMaintenancePeriod | null>(null);

    const [storedScheduledMaintenances]: [
        AppStateScheduledMaintenances
    ] = useSelector(({ scheduledMaintenances }: AppState) => [
        scheduledMaintenances,
    ]);

    useEffect(() => {
        if (storedScheduledMaintenances) {
            const activeOrNext = storedScheduledMaintenances.get(
                `${clusterId || hostname}`
            );
            if (activeOrNext) {
                const periods = activeOrNext.getMaintenancePeriods();
                if (periods.length > 0) {
                    if (periods[0]?.isMaintenanceActive()) {
                        setActive(periods[0]);
                        setNext(null);
                    } else {
                        setActive(null);
                        setNext(periods[0]);
                    }
                }
            } else {
                setActive(null);
                setNext(null);
            }
        }
    }, [
        storedScheduledMaintenances,
        clusterId,
        hostname,
        next?.isMaintenanceActive(),
        active?.isMaintenanceActive(),
    ]);

    return {
        active,
        next,
    };
}
