import { Form, Alert, Input } from 'antd';
import useForm from 'antd/lib/form/hooks/useForm';
import ModalDefaultForm from '../../common/ModalDefaultForm';
import FormFooter from '../../common/FormFooter';

export default KeyManagementCreateFolderModal;

type KeyManagementCreateFolderFields = {
    folderName: string;
};

export type KeyManagementCreateFolderModalProps = {
    onCancel?: () => void;
    onSuccess?: (folderName: string) => void;
};

function KeyManagementCreateFolderModal({
    onCancel,
    onSuccess,
}: KeyManagementCreateFolderModalProps) {
    const [form] = useForm<KeyManagementCreateFolderFields>();
    const handleSubmit = ({ folderName }: KeyManagementCreateFolderFields) => {
        onSuccess?.(folderName);
    };
    return (
        <ModalDefaultForm
            title={`Create new folder`}
            width={450}
            form={form}
            onCancel={onCancel}
            defaultVisible={true}
            bodyStyle={{ padding: '20px' }}
            loading={false}
        >
            <Form form={form} onFinish={handleSubmit} layout="vertical">
                <Alert
                    showIcon
                    style={{ marginBottom: '10px' }}
                    message="
                    The actual path of the folder will not be created until the
                    certificate is created on the server"
                ></Alert>
                <Form.Item
                    name="folderName"
                    label="Folder name"
                    rules={[
                        {
                            required: true,
                            message: 'Please enter the folder name!',
                        },
                    ]}
                >
                    <Input placeholder="Enter folder name" />
                </Form.Item>
                <FormFooter
                    loading={false}
                    submitButtonText="Create"
                    showCancelButton
                    showSubmitButton
                    onCancel={onCancel}
                />
            </Form>
        </ModalDefaultForm>
    );
}
