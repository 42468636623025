
import CcNode, { CcNodeProps } from '../../models/CcNode';


export interface CmonPrometheusHostProps  extends CcNodeProps {
    configuration?: {[key:string]:any};
    data_retention?: string;
    data_retention_size?: string;
    exporters?: {[key:string]:any};
    managed_by_sd?: boolean;
    scrape_interval?: string;
    user_managed?: boolean;

}



export default class CmonPrometheusHost extends CcNode {
    public configuration?: {[key:string]:any};
    public dataRetention?: string;
    public dataRetentionSize?: string;
    public exporters?: {[key:string]:any};
    public managedBySd?: boolean;
    public scrapeInterval?: string;
    public userManaged?: boolean;


    constructor(props: CmonPrometheusHostProps) {
        super(props);
        this.configuration = props.configuration;
        this.dataRetention = props.data_retention;
        this.dataRetentionSize = props.data_retention_size;
        this.exporters = props.exporters;
        this.managedBySd = props.managed_by_sd;
        this.scrapeInterval = props.scrape_interval;
        this.userManaged = props.user_managed;

    }

}
