import React, { useState } from 'react';

type BreadcrumbVariables = {
    [key: string]: { value: string; title: React.ReactNode };
};

interface BreadcrumbContextInterface {
    variables: BreadcrumbVariables;
    setVariables: (variables: BreadcrumbVariables) => void;
}
export const BreadcrumbContext = React.createContext<
    BreadcrumbContextInterface
>({
    variables: {},
    setVariables: () => {},
});

export type BreadcrumbProviderProps = {
    children: React.ReactNode;
};
export const BreadcrumbProvider = ({ children }: BreadcrumbProviderProps) => {
    const [variables, setVariables] = useState<BreadcrumbVariables>({});

    const breadcrumbContext = {
        variables,
        setVariables,
    };
    return (
        <BreadcrumbContext.Provider value={breadcrumbContext}>
            {children}
        </BreadcrumbContext.Provider>
    );
};
