import TimescaleDbConfigurator from '../../TimescaleDb/TimescaleDbConfigurator';
import PostgreSqlDeploymentConfigurator from '../PostgreSql/PostgreSqlDeploymentConfigurator';
import merge from 'deepmerge';
import { PostgreSqlFormValues } from '../../PostgreSql/PostgreSqlConfigurator';

export default class TimescaleDbDeploymentConfigurator extends PostgreSqlDeploymentConfigurator {
    public static getVendors() {
        return TimescaleDbConfigurator.getVendors();
    }
    public static getJobData(formValues: PostgreSqlFormValues): any {
        return {
            ...PostgreSqlDeploymentConfigurator.getJobData(formValues),
            install_timescaledb: true,
        };
    }

    public static getJobOptions(formValues: PostgreSqlFormValues): any {
        return merge(
            PostgreSqlDeploymentConfigurator.getJobOptions(formValues),
            {
                enable_uninstall: true,
                job: {
                    title: 'Create TimescaleDB Cluster',
                },
            }
        );
    }
}
