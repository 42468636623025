import React from 'react';
import LdapSettingsButton from '../components/UserManagement/Ldap/LdapSettingsButton';
import useServiceMock from './serviceMock/useServiceMock';
import { ServiceMethod } from './serviceMock/ServiceMockContext';

export default DebugLdapSettingsButton;

export type DebugLdapSettingsButtonProps = {};

function DebugLdapSettingsButton({}: DebugLdapSettingsButtonProps) {
    useServiceMock({
        mockId: 'ldap-config',
        methods: [ServiceMethod.GET_LDAP_CONFIG, ServiceMethod.SET_LDAP_CONFIG],
    });
    return (
        <div className="DebugLdapSettingsButton">
            <LdapSettingsButton />
        </div>
    );
}
