import React from 'react';
import AppConfirmActionButton from '../../../common/General/AppConfirmActionButton';
import { useDebugContext } from '../../../common/Debug';
import { ConfirmActionButtonProps } from '@severalnines/bar-frontend-components/build/lib/General/ConfirmActionButton';
import CmonConfigService from '../../../services/cmon/CmonConfigService';
import {
    notifyOperationSuccess,
    NotifyType,
    notifyError,
} from '../../Notifications/uiNotification';

export default NotificationServicesDeleteButton;

export type NotificationServicesDeleteButtonProps = ConfirmActionButtonProps & {
    onSuccess?: () => void;
    record: any;
};

function NotificationServicesDeleteButton({
    onSuccess,
    children,
    record,
    ...rest
}: NotificationServicesDeleteButtonProps) {
    const { log } = useDebugContext();

    const handleConfirm = async () => {
        try {
            await CmonConfigService.removeintegration({
                id: record?.id,
            });
            notifyOperationSuccess({
                type: NotifyType.TOAST,
                title: 'Success!',
                content: 'Successfully deleted',
            });
            onSuccess?.();
        } catch (e) {
            notifyError({ content: e.message });
            log.error(e);
        }
    };

    return (
        <AppConfirmActionButton
            critical={true}
            confirmTitle="You want to delete this record?"
            onConfirm={handleConfirm}
            {...rest}
        >
            {'Delete'}
        </AppConfirmActionButton>
    );
}
