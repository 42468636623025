import React, { Fragment, useEffect } from 'react';
import useServiceMock from './serviceMock/useServiceMock';
import { ServiceMethod } from './serviceMock/ServiceMockContext';
import useTree from '../components/UserManagement/useTree';
import { Card, Space, Tag, Tree } from 'antd';
import CcTreeItem from '../services/models/CcTreeItem';
import { DataNode } from 'antd/lib/tree';
import Diagram, {
    IDiagramItem,
} from '@severalnines/bar-frontend-components/build/lib/DataDisplay/Diagram';

export default DebugTmp;

export type DebugTmpProps = {};

function DebugTmp() {
    useServiceMock({
        mockId: 'tmp',
        methods: [
            ServiceMethod.USER_GET_GROUPS,
            ServiceMethod.USER_CREATE,
            ServiceMethod.GROUP_CREATE,
            ServiceMethod.GET_TREE,
            ServiceMethod.USER_GET_USERS,
            ServiceMethod.ADD_ACL,
            ServiceMethod.ADD_TO_GROUP,
        ],
    });
    const { data: tree, refresh, loaded } = useTree({});
    useEffect(() => {
        (async () => {
            await refresh();
        })();
    }, [loaded]);
    function getTreeData(data: CcTreeItem[]): DataNode[] {
        return data.map((i) => ({
            key: i.getKey(),
            title: (
                <Space>
                    <span>{i.itemName}</span>
                    <Tag>{i.itemType}</Tag>
                    <strong>{i.itemAcl}</strong>
                    <Tag>
                        Owners: <Tag>{i.ownerUserName}</Tag>
                        <Tag>{i.ownerGroupName}</Tag>
                    </Tag>
                </Space>
            ),
            children: getTreeData(i.subItems || []),
        }));
    }

    function getTopologyItems(data, fromKey = null): IDiagramItem[] {
        if (!data && data.length === 0) {
            return [];
        }
        return data.reduce((acc, current) => {
            acc = [
                ...acc,
                {
                    key: current.getKey(),
                    title: current.getKey(),
                    from: fromKey,
                },
                ...getTopologyItems(current.subItems, current.getKey()),
            ];
            return acc;
        }, []);
    }
    return (
        <Fragment>
            {tree && tree.length && (
                <Tree
                    treeData={getTreeData(tree || [])}
                    defaultExpandAll={true}
                ></Tree>
            )}
            {tree && tree.length && (
                <Diagram
                    items={getTopologyItems(tree || [])}
                    renderItem={(item, children) => {
                        return (
                            <Card size="small" title={item.key}>
                                {children}
                            </Card>
                        );
                    }}
                />
            )}
        </Fragment>
    );
}
