import React, { useState } from 'react';
import { Form, Input, Space } from 'antd';
import InfoIcon from '@severalnines/bar-frontend-components/build/lib/General/InfoIcon';
import FormFooter from '../../../../common/FormFooter';
import ModalDefaultForm from '../../../../common/ModalDefaultForm';
import SpaceWide from '../../../../common/SpaceWide';
import CmonClustersService from '../../../../services/cmon/CmonClustersService';
import Alert from '@severalnines/bar-frontend-components/build/lib/Feedback/Alert';
import {
    notifyError,
    notifyOperationSuccess,
    NotifyType,
} from '../../../Notifications/uiNotification';

import CcCluster from '../../../../services/models/CcCluster';

export default AddRpcForm;

export type AddRpcFormProps = {
    cluster: CcCluster;
    onSuccess?: () => void;
    onCancel?: () => void;
    onError?: (err: Error) => void;
};
export type AddRpcFormValues = {
    rpcToken?: string;
};

function AddRpcForm({
    cluster,
    onSuccess,
    onCancel,
    onError,
}: AddRpcFormProps) {
    const [form] = Form.useForm<AddRpcFormValues>();
    const [loading, setLoading] = useState<boolean>(false);

    const handleSubmit = async (values: AddRpcFormValues) => {
        try {
            setLoading(true);
            await CmonClustersService.setConfig({
                configuration: [
                    {
                        name: 'rpc_key',
                        value: values.rpcToken,
                    },
                ],
                cluster_id: cluster.clusterId,
            });

            notifyOperationSuccess({
                type: NotifyType.TOAST,
                title: <span>RPC API token has been changed successfully</span>,
            });
            setLoading(false);
            onSuccess?.();
        } catch (error: any) {
            setLoading(false);
            notifyError({
                content: error.message,
            });
            onError?.(error);
        }
    };

    return (
        <ModalDefaultForm
            title="Change RPC API token"
            form={form}
            footer={[]}
            onCancel={onCancel}
            width={450}
            defaultVisible={true}
        >
            <Form
                className="AddRpcForm"
                form={form}
                layout="vertical"
                onFinish={handleSubmit}
            >
                <SpaceWide direction="vertical">
                    <Alert
                        message={`Authenticate controller RPC API access with a unique token.`}
                        type="info"
                        showIcon
                    />

                    <Form.Item
                        name="rpcToken"
                        label={
                            <Space>
                                Controller RPC API token
                                <InfoIcon
                                    info={
                                        <span>
                                            RPC API token needs to be identical
                                            with rpc_key in your cluster’s
                                            cmon_N.cnf file.
                                        </span>
                                    }
                                />
                            </Space>
                        }
                        rules={[
                            {
                                required: true,
                                message: 'Please enter RPC token.',
                            },
                        ]}
                    >
                        <Input.Password placeholder="Enter controller RPC API access" />
                    </Form.Item>
                </SpaceWide>
                <FormFooter
                    loading={loading}
                    showCancelButton
                    onCancel={onCancel}
                    submitButtonText="Save"
                    showSubmitButton
                />
            </Form>
        </ModalDefaultForm>
    );
}
