import React from 'react';

import CcJob, { CcJobStatus } from '../../services/models/CcJob';
import StatusFormat, {
    StatusFormatProps,
    StatusFormatStatus,
    StatusFormatType,
} from '@severalnines/bar-frontend-components/build/lib/Format/StatusFormat';

export default JobStatusFormat;

export function getJobStatusFormatType(status?: CcJobStatus) {
    if (!status) {
        return StatusFormatStatus.info;
    }
    if (isJobStatusFailed(status)) {
        return StatusFormatStatus.error;
    } else if (isJobStatusFinished(status)) {
        return StatusFormatStatus.success;
    } else if (isJobStatusPaused(status)) {
        return StatusFormatStatus.default;
    } else {
        return StatusFormatStatus.info;
    }
}

export function getJobStatusFormatText(status?: CcJobStatus) {
    if (!status) {
        return 'Unknown';
    }
    if (isJobStatusFailed(status)) {
        return 'Failed';
    } else if (isJobStatusWaiting(status)) {
        return 'Waiting';
    } else if (isJobStatusRunning(status)) {
        return 'Running';
    } else if (isJobStatusFinished(status)) {
        return 'Finished';
    } else if (isJobStatusScheduled(status)) {
        return 'Scheduled';
    } else if (isJobStatusPaused(status)) {
        return 'Paused';
    }
}
export function getJobStatusGroup(status?: CcJobStatus) {
    switch (status) {
        case CcJobStatus.FAILED:
        case CcJobStatus.ABORTED:
            return [CcJobStatus.FAILED, CcJobStatus.ABORTED];
        case CcJobStatus.FINISHED:
            return [CcJobStatus.FINISHED];
        case CcJobStatus.DEFINED:
        case CcJobStatus.DEQUEUED:
            return [CcJobStatus.DEFINED, CcJobStatus.DEQUEUED];
        case CcJobStatus.RUNNING:
        case CcJobStatus.RUNNING2:
        case CcJobStatus.RUNNING3:
        case CcJobStatus.RUNNINGEXT:
            return [
                CcJobStatus.RUNNING,
                CcJobStatus.RUNNING2,
                CcJobStatus.RUNNING3,
                CcJobStatus.RUNNINGEXT,
            ];
        case CcJobStatus.SCHEDULED:
            return [CcJobStatus.SCHEDULED];
        default:
            return [];
    }
}

export function isJobStatusFailed(status: CcJobStatus) {
    return [CcJobStatus.FAILED, CcJobStatus.ABORTED].includes(status);
}
export function isJobStatusWaiting(status: CcJobStatus) {
    return [CcJobStatus.DEFINED, CcJobStatus.DEQUEUED].includes(status);
}
export function isJobStatusRunning(status: CcJobStatus) {
    return [
        CcJobStatus.RUNNING,
        CcJobStatus.RUNNING2,
        CcJobStatus.RUNNING3,
        CcJobStatus.RUNNINGEXT,
    ].includes(status);
}
export function isJobStatusFinished(status: CcJobStatus) {
    return [CcJobStatus.FINISHED].includes(status);
}
export function isJobStatusScheduled(status: CcJobStatus) {
    return [CcJobStatus.SCHEDULED].includes(status);
}
export function isJobStatusPaused(status: CcJobStatus) {
    return [CcJobStatus.PAUSED].includes(status);
}

export type JobStatusFormatProps = StatusFormatProps & {
    job: CcJob;
    type?: StatusFormatType;
};

function JobStatusFormat({ job, ...rest }: JobStatusFormatProps) {
    return (
        <StatusFormat
            className="JobStatusFormat"
            status={getJobStatusFormatType(job.status)}
            text={getJobStatusFormatText(job.status)}
            loading={job.isRunning()}
            {...rest}
        />
    );
}
