import React, { useRef } from 'react';
import { CmonJobInstanceCommand } from '../../../../services/cmon/models/CmonJobInstance';
import CcCluster from '../../../../services/models/CcCluster';
import { CcNodeType } from '../../../../services/models/CcNode';
import useJobWatcher from '../../../Jobs/useJobWatcher';
import useCreateJob from '../../../Jobs/useCreateJob';
import AppConfirmActionButton from '../../../../common/General/AppConfirmActionButton';
import { ButtonProps } from 'antd/lib/button';

export default BackupToolUninstallActionButton;

export type ClusterBackupToolUninstallButtonProps = ButtonProps & {
    cluster: CcCluster;
    toolType: CcNodeType.PGBACKREST | CcNodeType.PBM_AGENT;
    children?: React.ReactNode;
    onSuccess?: () => void;
};

function BackupToolUninstallActionButton({
    cluster,
    toolType,
    onSuccess,
    ...rest
}: ClusterBackupToolUninstallButtonProps) {
    const title = useRef(
        `Uninstall ${
            toolType === CcNodeType.PGBACKREST ? 'PgBackRest' : 'Percona backup'
        }`
    );
    const { loading: loadingJob } = useJobWatcher({
        command: CmonJobInstanceCommand.REMOVENODE,
        clusterId: cluster.clusterId,
    });
    const { loading, send } = useCreateJob({
        clusterId: cluster.clusterId,
        title: title.current,
        command: CmonJobInstanceCommand.REMOVENODE,
        onSuccess,
    });

    const handleConfirm = async () => {
        // can't delete multiple node in one job
        await Promise.all(
            cluster
                .getNodesByType(toolType)
                .map(({ hostname, port, nodetype }) =>
                    send({
                        enable_uninstall: true,
                        clusterId: cluster.clusterId,
                        nodes: [
                            {
                                hostname,
                                port,
                                nodetype,
                            },
                        ],
                    })
                )
        );
    };
    return (
        <AppConfirmActionButton
            confirmTitle={'Uninstall backup tool?'}
            onConfirm={handleConfirm}
            critical={true}
            loading={loading || loadingJob}
            {...rest}
        >
            {title.current}
        </AppConfirmActionButton>
    );
}
