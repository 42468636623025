import React, { useEffect, useState } from 'react';
import { CcNodeType } from '../../services/models/CcNode';
import { Select, SelectProps } from 'antd';
import CcCluster from '../../services/models/CcCluster';
import CcMongoNode from '../../services/models/CcMongoNode';

export default MongoReplicaSetSelect;

export type MongoReplicaSetSelectProps = SelectProps & {
    cluster: CcCluster;
    nodeFilter?: (node: CcMongoNode) => boolean;
};

function MongoReplicaSetSelect({
    cluster,
    nodeFilter = () => true,
    ...rest
}: MongoReplicaSetSelectProps) {
    const [items, setItems] = useState<string[]>();

    useEffect(() => {
        const replicaSetList: string[] = [];
        cluster.nodes.filter(nodeFilter).forEach((node: CcMongoNode) => {
            if (
                node.isType(CcNodeType.MONGO) &&
                node.rs &&
                !replicaSetList.includes(node.rs)
            ) {
                replicaSetList.push(node.rs);
            }
        });
        setItems(replicaSetList);
    }, [cluster]);

    return (
        <div className="MongoReplicaSetSelect">
            <Select
                data-testid="mongo-replica-select"
                placeholder="Choose a replica set"
                disabled={!items}
                {...rest}
                options={[
                    ...(items?.map((item) => ({
                        value: item,
                        label: item,
                    })) || []),
                ]}
            />
        </div>
    );
}
