export enum CcPrometheusExporterJob {
    NODE = 'node',
    MYSQLD = 'mysqld',
    PROXYSQL = 'proxysql',
    POSTGRES = 'postgres',
    MONGO = 'mongo',
    HAPROXY = 'haproxy',
    MSSQL = 'mssql',
    REDIS = 'redis',
    PGBOUNCER = 'pgbouncer',
}

export interface CcPrometheusExporterProps {
    address: string;
    monitored_host: string;
    port: number;
    job: string;
    up: boolean;
    version: string;
}

export default class CcPrometheusExporter {
    public readonly address: string;
    public readonly monitoredHost: string;
    public readonly port: number;
    public readonly job: string;
    public readonly up: boolean;
    public readonly version: string;
    constructor(props: CcPrometheusExporterProps) {
        this.address = props.address;
        this.monitoredHost = props.monitored_host;
        this.port = props.port;
        this.job = props.job;
        this.up = props.up;
        this.version = props.version;
    }

    getHostname() {
        return this.address?.split(':')?.[0] || '';
    }

    getDescription() {
        return `${this.job} job @ ${this.address}`;
    }
}
