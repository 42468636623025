import React from 'react';
import ButtonWithForm from '../../common/General/ButtonWithForm';
import ActionButton from '@severalnines/bar-frontend-components/build/lib/General/ActionButton';
import CcCluster from '../../services/models/CcCluster';
import LoadBalancerModal from './LoadBalancerWizardModal';

export default LoadBalancerWizardButton;

export type LoadBalancerButton = {
    cluster?: CcCluster;
};

function LoadBalancerWizardButton({ cluster }: LoadBalancerButton) {
    return (
        <ButtonWithForm
            button={<ActionButton>Add load balancer</ActionButton>}
            form={<LoadBalancerModal cluster={cluster} />}
        />
    );
}
