
import CcNode, { CcNodeProps } from '../../models/CcNode';


export interface CmonNFSServerHostProps  extends CcNodeProps {
    os_user?: string;
    service_name?: string;

}



export default class CmonNFSServerHost extends CcNode {
    public osUser?: string;
    public serviceName?: string;


    constructor(props: CmonNFSServerHostProps) {
        super(props);
        this.osUser = props.os_user;
        this.serviceName = props.service_name;

    }

}
