


export interface CmonClusterInfoProps  {
    acl?: string;
    alarm_statistics?: string;
    cdt_path?: string;
    class_name?: string;
    cluster_auto_recovery?: boolean;
    cluster_id?: number;
    cluster_name?: string;
    cluster_type?: string;
    configuration_file?: string;
    effective_privileges?: string;
    group_owner?: string;
    job_statistics?: string;
    log_file?: string;
    maintenance_mode_active?: boolean;
    managed?: boolean;
    node_auto_recovery?: boolean;
    owner?: string;
    rpc_version?: string;
    state?: string;
    status_text?: string;
    tags?: string;
    vendor?: string;
    version?: string;

}



export default class CmonClusterInfo {
    public acl?: string;
    public alarmStatistics?: string;
    public cdtPath?: string;
    public className?: string;
    public clusterAutoRecovery?: boolean;
    public clusterId?: number;
    public clusterName?: string;
    public clusterType?: string;
    public configurationFile?: string;
    public effectivePrivileges?: string;
    public groupOwner?: string;
    public jobStatistics?: string;
    public logFile?: string;
    public maintenanceModeActive?: boolean;
    public managed?: boolean;
    public nodeAutoRecovery?: boolean;
    public owner?: string;
    public rpcVersion?: string;
    public state?: string;
    public statusText?: string;
    public tags?: string;
    public vendor?: string;
    public version?: string;


    constructor(props: CmonClusterInfoProps) {
        this.acl = props.acl;
        this.alarmStatistics = props.alarm_statistics;
        this.cdtPath = props.cdt_path;
        this.className = props.class_name;
        this.clusterAutoRecovery = props.cluster_auto_recovery;
        this.clusterId = props.cluster_id;
        this.clusterName = props.cluster_name;
        this.clusterType = props.cluster_type;
        this.configurationFile = props.configuration_file;
        this.effectivePrivileges = props.effective_privileges;
        this.groupOwner = props.group_owner;
        this.jobStatistics = props.job_statistics;
        this.logFile = props.log_file;
        this.maintenanceModeActive = props.maintenance_mode_active;
        this.managed = props.managed;
        this.nodeAutoRecovery = props.node_auto_recovery;
        this.owner = props.owner;
        this.rpcVersion = props.rpc_version;
        this.state = props.state;
        this.statusText = props.status_text;
        this.tags = props.tags;
        this.vendor = props.vendor;
        this.version = props.version;

    }

}
