import React, { useState } from 'react';
import ModalDefaultForm from '../../common/ModalDefaultForm';
import { Alert, Form } from 'antd';
import FormFooter from '../../common/FormFooter';
import useForm from 'antd/lib/form/hooks/useForm';
import CcLicense from '../../services/models/CcLicense';
import TextArea from 'antd/lib/input/TextArea';
import SpaceWide from '../../common/SpaceWide';
import CmonTreeService from '../../services/cmon/CmonTreeService';
import { useDebugContext } from '../../common/Debug';
import {
    notifyOperationSuccess,
    NotifyType,
} from '../Notifications/uiNotification';
import useClusterList from '../Clusters/useClusterList';
import TypographyText from '../../common/TypographyText';
import AppDateFormat from '../../common/AppDateFormat';

export default LicenseKeyModal;
type LicenseKeyFormType = {
    licenseKey?: string;
};

export type LicenseKeyModalProps = {
    license?: CcLicense;
    onSuccess?: () => void;
    onCancel?: () => void;
};

function LicenseKeyModal({
    license,
    onCancel,
    onSuccess,
    ...rest
}: LicenseKeyModalProps) {
    const [errorMessage, setErrorMessage] = useState<string>();
    const [loading, setLoading] = useState(false);
    const [form] = useForm<LicenseKeyFormType>();
    const { log } = useDebugContext();
    const { refresh: refreshClusters } = useClusterList({
        useCache: false,
    });

    const handleCancel = () => {
        onCancel?.();
    };
    const handleSubmit = async (fields: LicenseKeyFormType) => {
        try {
            setLoading(true);
            await CmonTreeService.setcontent({
                content: fields.licenseKey,
                path: '/.runtime/cmon_license',
            });
            await refreshClusters();
            notifyOperationSuccess({
                type: NotifyType.TOAST,
                title: 'License key has updated successfully!',
                content: 'Thank you for choosing ClusterControl!',
            });
            onSuccess?.();
        } catch (e: any) {
            log.error(e);
            setErrorMessage(e.message);
            try {
                await form.validateFields();
            } catch (err: any) {}
        } finally {
            setLoading(false);
        }
    };

    const handelKeyChange = () => {
        setErrorMessage(undefined);
    };

    return (
        <ModalDefaultForm
            title="Enter a license key"
            width={700}
            form={form}
            onCancel={handleCancel}
            defaultVisible={true}
            bodyStyle={{ padding: '20px' }}
            loading={loading}
        >
            <Form form={form} onFinish={handleSubmit} layout="vertical">
                <SpaceWide direction="vertical" size={20}>
                    {license?.validDate && license?.expirationDate && (
                        <Alert
                            type="success"
                            message={
                                <span>
                                    Your current license is valid until{' '}
                                    <TypographyText strong={true}>
                                        <AppDateFormat includeTimezone={false}>
                                            {new Date(license?.expirationDate)}
                                        </AppDateFormat>
                                    </TypographyText>
                                </span>
                            }
                        />
                    )}
                    <Form.Item
                        name="licenseKey"
                        label="License key"
                        validateStatus={(errorMessage && 'error') || ''}
                        rules={[
                            {
                                required: true,
                                validator: async (rule: any, value: string) => {
                                    if (!value) {
                                        throw new Error(
                                            'Please enter license key'
                                        );
                                    }
                                    if (errorMessage) {
                                        throw new Error(errorMessage);
                                    }
                                },
                            },
                        ]}
                    >
                        <TextArea
                            onChange={handelKeyChange}
                            rows={7}
                            placeholder="Enter license key here"
                        />
                    </Form.Item>
                </SpaceWide>
                <FormFooter
                    loading={loading}
                    submitButtonText="Apply license key"
                    showCancelButton
                    showSubmitButton
                    onCancel={handleCancel}
                />
            </Form>
        </ModalDefaultForm>
    );
}
