import React from 'react';
import StatusFormat, {
    StatusFormatStatus,
    StatusFormatType,
} from '@severalnines/bar-frontend-components/build/lib/Format/StatusFormat';
import { Divider } from 'antd';

export default DebugStatusFormat;

export type DebugStatusFormatProps = {};

function DebugStatusFormat() {
    const samples: any[] = [
        { text: 'default' },
        { text: 'loading', loading: true },
        { text: 'info', status: StatusFormatStatus.info },
        { text: 'success', status: StatusFormatStatus.success },
        { text: 'warning', status: StatusFormatStatus.warning },
        { text: 'error', status: StatusFormatStatus.error },
        { text: 'unknown', status: StatusFormatStatus.unknown },
        {
            text: 'unknown',
            type: StatusFormatType.icon,
        },
        { text: 'loading', loading: true, type: StatusFormatType.icon },
        {
            text: 'info',
            status: StatusFormatStatus.info,
            type: StatusFormatType.icon,
        },
        {
            text: 'success',
            status: StatusFormatStatus.success,
            type: StatusFormatType.icon,
        },
        {
            text: 'warning',
            status: StatusFormatStatus.warning,
            type: StatusFormatType.icon,
        },
        {
            text: 'error',
            status: StatusFormatStatus.error,
            type: StatusFormatType.icon,
        },
        {
            text: 'unknown',
            status: StatusFormatStatus.unknown,
            type: StatusFormatType.icon,
        },
        { text: 'loading', loading: true, type: StatusFormatType.iconCircled },
        {
            text: 'info',
            status: StatusFormatStatus.info,
            type: StatusFormatType.iconCircled,
        },
        {
            text: 'success',
            status: StatusFormatStatus.success,
            type: StatusFormatType.iconCircled,
        },
        {
            text: 'warning',
            status: StatusFormatStatus.warning,
            type: StatusFormatType.iconCircled,
        },
        {
            text: 'error',
            status: StatusFormatStatus.error,
            type: StatusFormatType.iconCircled,
        },
        {
            text: 'unknown',
            status: StatusFormatStatus.unknown,
            type: StatusFormatType.iconCircled,
        },
        { text: 'loading', loading: true, type: StatusFormatType.iconFilled },
        {
            text: 'info',
            status: StatusFormatStatus.info,
            type: StatusFormatType.iconFilled,
        },
        {
            text: 'success',
            status: StatusFormatStatus.success,
            type: StatusFormatType.iconFilled,
        },
        {
            text: 'warning',
            status: StatusFormatStatus.warning,
            type: StatusFormatType.iconFilled,
        },
        {
            text: 'error',
            status: StatusFormatStatus.error,
            type: StatusFormatType.iconFilled,
        },
        {
            text: 'unknown',
            status: StatusFormatStatus.unknown,
            type: StatusFormatType.iconFilled,
        },

        { icon: 'customIcon', type: StatusFormatType.icon },
    ];
    return (
        <div className="DebugStatusFormat">
            <h1>StatusFormat</h1>
            {samples.map((sample, index) => (
                <div key={index}>
                    <Divider
                        plain={true}
                        orientation="left"
                        style={{ marginBottom: '0px', marginTop: '15px' }}
                    >
                        {JSON.stringify(sample)}
                    </Divider>
                    <StatusFormat {...sample} />
                </div>
            ))}
        </div>
    );
}
