import getAllClusterInfo1 from './getAllClusterInfo1';

const result = {
    ...getAllClusterInfo1,
    clusters: getAllClusterInfo1.clusters.filter(
        (record) => record.vendor !== 'elasticsearch'
    ),
};

export default result;
