import { Modal, ModalProps } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import AnimateHeight from 'react-animate-height';
import './ModalDefault.less';
import classNames from 'classnames';
import AppSpin from './General/AppSpin';

export type ModalDefaultProps = {
    children: React.ReactNode;
    animateHeight?: boolean;
    loading?: boolean;
} & ModalProps;
const ModalDefault = ({
    title,
    children,
    animateHeight = false,
    visible,
    className,
    wrapClassName,
    loading = false,
    ...rest
}: ModalDefaultProps) => {
    const [height, setHeight] = useState<number>(0);
    const container = useRef<any>(null);

    useEffect(() => {
        if (!animateHeight) {
            return;
        }
        if (visible) {
            let id = setInterval(() => {
                const newHeight =
                    container.current?.contentElement.clientHeight;
                setHeight(newHeight > height / 10 ? newHeight : 0);
            }, 100);
            return () => {
                id && clearInterval(id);
                setHeight(0);
            };
        } else {
            setHeight(0);
        }
    }, [visible]);
    return (
        <Modal
            centered
            width={900}
            footer={null}
            className={classNames('ModalDefault', className)}
            wrapClassName={classNames('ModalDefault_wrap', wrapClassName)}
            bodyStyle={{
                padding: '0',
            }}
            destroyOnClose={true}
            maskClosable={false}
            visible={visible}
            {...rest}
            title={<div>{title ? title : <span>&nbsp;</span>}</div>}
        >
            {animateHeight ? (
                <AnimateHeight
                    ref={container}
                    height={height > 0 ? height : 'auto'}
                    duration={100}
                >
                    <AppSpin spinning={loading}>{children}</AppSpin>
                </AnimateHeight>
            ) : (
                <AppSpin spinning={loading}>{children}</AppSpin>
            )}
        </Modal>
    );
};
export default ModalDefault;
