import { Form, Button } from 'antd';
import ModalDefaultForm from '../../../../common/ModalDefaultForm';
import CcCluster from '../../../../services/models/CcCluster';
import SpaceWide from '../../../../common/SpaceWide';
import ClusterFormat from '../../../Clusters/ClusterFormat';
import MongoReplicaSetSelectField from '../../../../common/Form/Fields/MongoReplicaSetSelectField';
import FormFooter from '../../../../common/FormFooter';
import CcMongoNode from '../../../../services/models/CcMongoNode';
import { CcNodeRole } from '../../../../services/models/CcNode';
import useCreateJob from '../../../Jobs/useCreateJob';
import { CmonJobInstanceCommand } from '../../../../services/cmon/models/CmonJobInstance';

export default ClusterRemoveShardModal;

export type ClusterRemoveShardModalProps = {
    cluster: CcCluster;
    onSuccess?: () => void;
    onCancel?: () => void;
};

export type ClusterRemoveShardFormValues = {
    clusterId: number;
    replicatSet?: string;
    targetReplicaset?: string;
};

function ClusterRemoveShardModal({
    cluster,
    onSuccess,
    onCancel,
}: ClusterRemoveShardModalProps) {
    const [form] = Form.useForm<ClusterRemoveShardFormValues>();
    const { loading, send } = useCreateJob({
        clusterId: cluster.clusterId,
        title: 'Remove Shard',
        command: CmonJobInstanceCommand.REMOVE_SHARD,
        onSuccess,
    });

    const nodeFilter = (node: CcMongoNode) =>
        node.role !== CcNodeRole.MONGO_CONFIG_SERVER;

    const handleSubmit = async (fields: ClusterRemoveShardFormValues) => {
        await send({
            replicaset: fields.replicatSet,
            target_replicaset: fields.targetReplicaset,
            enable_uninstall: false,
        });
    };

    return (
        <ModalDefaultForm
            title="Remove Shard"
            form={form}
            footer={[]}
            onCancel={onCancel}
            width={400}
            defaultVisible={true}
        >
            <Form
                className="RemoveShardForm"
                form={form}
                layout="vertical"
                onFinish={handleSubmit}
            >
                <SpaceWide direction="vertical">
                    <ClusterFormat
                        cluster={cluster}
                        size="large"
                        contentProps={{ style: { width: '100%' } }}
                        showId={true}
                    />
                    <Form.Item name="replicatSet">
                        <MongoReplicaSetSelectField
                            label="Replica Set you want to remove"
                            nodeFilter={nodeFilter}
                            name="replicatSet"
                            cluster={cluster}
                            required={true}
                        />
                    </Form.Item>
                    <Form.Item name="targetReplicaset">
                        <MongoReplicaSetSelectField
                            label="Move to Replica Set"
                            nodeFilter={nodeFilter}
                            name="targetReplicaset"
                            cluster={cluster}
                            required={false}
                        />
                    </Form.Item>
                </SpaceWide>
                <FormFooter>
                    <Button
                        key="done"
                        type="primary"
                        htmlType="submit"
                        loading={loading}
                    >
                        Remove Shard
                    </Button>
                </FormFooter>
            </Form>
        </ModalDefaultForm>
    );
}
