
import CcStats, { CcStatsProps } from '../../models/CcStats';


export interface CmonHaProxyStatsProps  extends CcStatsProps {
    act?: number;
    addr?: string;
    agent_code?: number;
    agent_desc?: string;
    agent_duration?: number;
    agent_fall?: number;
    agent_health?: number;
    agent_rise?: number;
    agent_status?: string;
    algo?: string;
    bck?: number;
    bin?: number;
    bout?: number;
    check_code?: number;
    check_desc?: string;
    check_duration?: number;
    check_fall?: number;
    check_health?: number;
    check_rise?: number;
    check_status?: string;
    chkdown?: number;
    chkfail?: number;
    cli_abrt?: number;
    comp_byp?: number;
    comp_in?: number;
    comp_out?: number;
    comp_rsp?: number;
    conn_rate?: number;
    conn_rate_max?: number;
    conn_tot?: number;
    cookie?: number;
    ctime?: number;
    dcon?: number;
    downtime?: number;
    dreq?: number;
    dresp?: number;
    dses?: number;
    econ?: number;
    ereq?: number;
    eresp?: number;
    hanafail?: number;
    hrsp_1xx?: number;
    hrsp_2xx?: number;
    hrsp_3xx?: number;
    hrsp_4xx?: number;
    hrsp_5xx?: number;
    hrsp_other?: number;
    iid?: number;
    intercepted?: number;
    last_agt?: string;
    last_chk?: string;
    lastchg?: number;
    lastsess?: number;
    lbtot?: number;
    mode?: string;
    pid?: number;
    pxname?: string;
    qcur?: number;
    qlimit?: number;
    qmax?: number;
    qtime?: number;
    rate?: number;
    rate_lim?: number;
    rate_max?: number;
    req_rate?: number;
    req_rate_max?: number;
    req_tot?: number;
    rtime?: number;
    scur?: number;
    sid?: number;
    slim?: number;
    smax?: number;
    srv_abrt?: number;
    status?: string;
    stot?: number;
    svname?: string;
    throttle?: number;
    tracked?: number;
    ttime?: number;
    type?: number;
    weight?: number;
    wredis?: number;
    wretr?: number;

}



export default class CmonHaProxyStats extends CcStats {
    public act?: number;
    public addr?: string;
    public agentCode?: number;
    public agentDesc?: string;
    public agentDuration?: number;
    public agentFall?: number;
    public agentHealth?: number;
    public agentRise?: number;
    public agentStatus?: string;
    public algo?: string;
    public bck?: number;
    public bin?: number;
    public bout?: number;
    public checkCode?: number;
    public checkDesc?: string;
    public checkDuration?: number;
    public checkFall?: number;
    public checkHealth?: number;
    public checkRise?: number;
    public checkStatus?: string;
    public chkdown?: number;
    public chkfail?: number;
    public cliAbrt?: number;
    public compByp?: number;
    public compIn?: number;
    public compOut?: number;
    public compRsp?: number;
    public connRate?: number;
    public connRateMax?: number;
    public connTot?: number;
    public cookie?: number;
    public ctime?: number;
    public dcon?: number;
    public downtime?: number;
    public dreq?: number;
    public dresp?: number;
    public dses?: number;
    public econ?: number;
    public ereq?: number;
    public eresp?: number;
    public hanafail?: number;
    public hrsp_1xx?: number;
    public hrsp_2xx?: number;
    public hrsp_3xx?: number;
    public hrsp_4xx?: number;
    public hrsp_5xx?: number;
    public hrspOther?: number;
    public iid?: number;
    public intercepted?: number;
    public lastAgt?: string;
    public lastChk?: string;
    public lastchg?: number;
    public lastsess?: number;
    public lbtot?: number;
    public mode?: string;
    public pid?: number;
    public pxname?: string;
    public qcur?: number;
    public qlimit?: number;
    public qmax?: number;
    public qtime?: number;
    public rate?: number;
    public rateLim?: number;
    public rateMax?: number;
    public reqRate?: number;
    public reqRateMax?: number;
    public reqTot?: number;
    public rtime?: number;
    public scur?: number;
    public sid?: number;
    public slim?: number;
    public smax?: number;
    public srvAbrt?: number;
    public status?: string;
    public stot?: number;
    public svname?: string;
    public throttle?: number;
    public tracked?: number;
    public ttime?: number;
    public type?: number;
    public weight?: number;
    public wredis?: number;
    public wretr?: number;


    constructor(props: CmonHaProxyStatsProps) {
        super(props);
        this.act = props.act;
        this.addr = props.addr;
        this.agentCode = props.agent_code;
        this.agentDesc = props.agent_desc;
        this.agentDuration = props.agent_duration;
        this.agentFall = props.agent_fall;
        this.agentHealth = props.agent_health;
        this.agentRise = props.agent_rise;
        this.agentStatus = props.agent_status;
        this.algo = props.algo;
        this.bck = props.bck;
        this.bin = props.bin;
        this.bout = props.bout;
        this.checkCode = props.check_code;
        this.checkDesc = props.check_desc;
        this.checkDuration = props.check_duration;
        this.checkFall = props.check_fall;
        this.checkHealth = props.check_health;
        this.checkRise = props.check_rise;
        this.checkStatus = props.check_status;
        this.chkdown = props.chkdown;
        this.chkfail = props.chkfail;
        this.cliAbrt = props.cli_abrt;
        this.compByp = props.comp_byp;
        this.compIn = props.comp_in;
        this.compOut = props.comp_out;
        this.compRsp = props.comp_rsp;
        this.connRate = props.conn_rate;
        this.connRateMax = props.conn_rate_max;
        this.connTot = props.conn_tot;
        this.cookie = props.cookie;
        this.ctime = props.ctime;
        this.dcon = props.dcon;
        this.downtime = props.downtime;
        this.dreq = props.dreq;
        this.dresp = props.dresp;
        this.dses = props.dses;
        this.econ = props.econ;
        this.ereq = props.ereq;
        this.eresp = props.eresp;
        this.hanafail = props.hanafail;
        this.hrsp_1xx = props.hrsp_1xx;
        this.hrsp_2xx = props.hrsp_2xx;
        this.hrsp_3xx = props.hrsp_3xx;
        this.hrsp_4xx = props.hrsp_4xx;
        this.hrsp_5xx = props.hrsp_5xx;
        this.hrspOther = props.hrsp_other;
        this.iid = props.iid;
        this.intercepted = props.intercepted;
        this.lastAgt = props.last_agt;
        this.lastChk = props.last_chk;
        this.lastchg = props.lastchg;
        this.lastsess = props.lastsess;
        this.lbtot = props.lbtot;
        this.mode = props.mode;
        this.pid = props.pid;
        this.pxname = props.pxname;
        this.qcur = props.qcur;
        this.qlimit = props.qlimit;
        this.qmax = props.qmax;
        this.qtime = props.qtime;
        this.rate = props.rate;
        this.rateLim = props.rate_lim;
        this.rateMax = props.rate_max;
        this.reqRate = props.req_rate;
        this.reqRateMax = props.req_rate_max;
        this.reqTot = props.req_tot;
        this.rtime = props.rtime;
        this.scur = props.scur;
        this.sid = props.sid;
        this.slim = props.slim;
        this.smax = props.smax;
        this.srvAbrt = props.srv_abrt;
        this.status = props.status;
        this.stot = props.stot;
        this.svname = props.svname;
        this.throttle = props.throttle;
        this.tracked = props.tracked;
        this.ttime = props.ttime;
        this.type = props.type;
        this.weight = props.weight;
        this.wredis = props.wredis;
        this.wretr = props.wretr;

    }

}
