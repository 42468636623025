import React from 'react';
import FormItemInlineSwitch, {
    FormItemInlineSwitchProps,
} from '../../DataEntry/FormItemInlineSwitch';

export default DisableSelinuxSwitch;

export type DisableSelinuxSwitchProps = FormItemInlineSwitchProps & {};

function DisableSelinuxSwitch({ ...rest }: DisableSelinuxSwitchProps) {
    return (
        <div className="DisableSelinuxSwitch">
            <FormItemInlineSwitch
                justify={true}
                name="disableSeLinux"
                label="Disable SELinux/AppArmor"
                valuePropName="checked"
                {...rest}
            />
        </div>
    );
}
