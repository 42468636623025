
import CcStats, { CcStatsProps } from '../../models/CcStats';


export interface CmonDiskStatsProps  extends CcStatsProps {
    blocksize?: number;
    device?: string;
    filesystem?: string;
    free?: number;
    mount_option?: string;
    mountpoint?: string;
    reads?: number;
    readspersec?: number;
    sectorsread?: number;
    sectorswritten?: number;
    total?: number;
    utilization?: number;
    writes?: number;
    writespersec?: number;

}



export default class CmonDiskStats extends CcStats {
    public blocksize?: number;
    public device?: string;
    public filesystem?: string;
    public free?: number;
    public mountOption?: string;
    public mountpoint?: string;
    public reads?: number;
    public readspersec?: number;
    public sectorsread?: number;
    public sectorswritten?: number;
    public total?: number;
    public utilization?: number;
    public writes?: number;
    public writespersec?: number;


    constructor(props: CmonDiskStatsProps) {
        super(props);
        this.blocksize = props.blocksize;
        this.device = props.device;
        this.filesystem = props.filesystem;
        this.free = props.free;
        this.mountOption = props.mount_option;
        this.mountpoint = props.mountpoint;
        this.reads = props.reads;
        this.readspersec = props.readspersec;
        this.sectorsread = props.sectorsread;
        this.sectorswritten = props.sectorswritten;
        this.total = props.total;
        this.utilization = props.utilization;
        this.writes = props.writes;
        this.writespersec = props.writespersec;

    }

}
