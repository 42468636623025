import React, { useEffect, useState } from 'react';
import WizardFormConfiguration from '@severalnines/bar-frontend-components/build/lib/Navigation/Wizard/WizardFormConfiguration';
import WizardForm from '@severalnines/bar-frontend-components/build/lib/Navigation/WizardForm';
import PrometheusSettingsForm, {
    PrometheusSettingsFormValues,
} from './PrometheusSettingsForm';
import CcCluster, {
    CcClusterBase,
    CcClusterTechnology,
    CcClusterType,
} from '../../services/models/CcCluster';
import { CcNodeType } from '../../services/models/CcNode';
import PrometheusEnableSummary from './PrometheusEnableSummary';
import PrometheusExporterForm, {
    PrometheusExporterFromItem,
} from './PrometheusExporterForm';
import { Store } from 'antd/es/form/interface';
import { CcPrometheusExporterJob } from '../../services/models/CcPrometheusExporter';
import { FormInstance } from 'antd/lib/form';
import CcPrometheusNode from '../../services/models/CcPrometheusNode';

export default PrometheusEnableWizard;
type PrometheusFormExporterValues = {
    [key in CcPrometheusExporterJob]?: PrometheusExporterFromItem;
};
export type PrometheusFormValues = PrometheusSettingsFormValues &
    PrometheusFormExporterValues & {};

export type ExporterType = {
    type: CcPrometheusExporterJob;
    availableArguments: string[];
    title: string;
};

export enum PrometheusEnableWizardStep {
    SETTINGS = 'settings',
    SUMMARY = 'summary',
}
export type PrometheusEnableWizardProps = {
    form: FormInstance;
    cluster: CcCluster;
    defaultInitialValues?: PrometheusFormValues;
    saveMode?: boolean;
    activeStep?: string;
    loading?: boolean;
    onCancel?: () => void;
    onSubmit?: () => void;
    onTouchedChange?: (touched: boolean) => void;
};
function PrometheusEnableWizard({
    form,
    cluster,
    defaultInitialValues,
    saveMode,
    activeStep,
    loading = false,
    onSubmit,
    onCancel,
    onTouchedChange,
}: PrometheusEnableWizardProps) {
    const [exporterTypes, setExporterTypes] = useState<ExporterType[]>([]);
    const [initialValues, setInitialValues] = useState<Store | undefined>(
        defaultInitialValues
    );

    useEffect(() => {
        const types = getPrometheusExporterTypes(cluster);
        setExporterTypes(types);

        if (!defaultInitialValues) {
            const currentInitialValues: Store = {
                scrapeInterval: 10,
                dataRetention: 15,
                dataRetentionSize: 0,
            };
            types.forEach(({ type }) => {
                currentInitialValues[type] = {
                    scrapeInterval: 10,
                    arguments: getPrometheusExporterDefaultArguments(type),
                };
            });
            setInitialValues(currentInitialValues);
        }
    }, [cluster]);

    return initialValues ? (
        <WizardFormConfiguration
            form={form}
            onSubmit={() => {
                onSubmit?.();
            }}
            activeKey={activeStep}
            initialValues={initialValues}
            loading={loading}
            footerExtra={null}
            saveMode={saveMode}
            showCancelButton
            cancelButtonText="Cancel"
            onCancel={onCancel}
            onTouchedChange={onTouchedChange}
            steps={[
                <WizardForm.Step
                    key={PrometheusEnableWizardStep.SETTINGS}
                    title="Configuration"
                    subTitle=" "
                    validate={['dataDirectory']}
                >
                    <PrometheusSettingsForm
                        cluster={cluster}
                        form={form}
                        hideHost={saveMode}
                    />
                </WizardForm.Step>,
                ...exporterTypes.map(({ type, availableArguments, title }) => (
                    <WizardForm.Step
                        key={type}
                        title={
                            <span style={{ whiteSpace: 'nowrap' }}>
                                {title}
                            </span>
                        }
                        subTitle=" "
                    >
                        <PrometheusExporterForm
                            type={type}
                            title={title}
                            availableArguments={availableArguments}
                        />
                    </WizardForm.Step>
                )),
                <WizardForm.Step
                    key={PrometheusEnableWizardStep.SUMMARY}
                    title="Preview"
                    subTitle=" "
                >
                    <PrometheusEnableSummary
                        form={form}
                        exporterTypes={exporterTypes}
                    />
                </WizardForm.Step>,
            ]}
        />
    ) : null;
}

export function getPrometheusExporterTypes(cluster: CcCluster): ExporterType[] {
    const exporters: CcPrometheusExporterJob[] = [];
    exporters.push(CcPrometheusExporterJob.NODE);

    if (cluster.isTechnology(CcClusterTechnology.TECHNOLOGY_MYSQL)) {
        exporters.push(CcPrometheusExporterJob.MYSQLD);
        if (cluster.containsNodeType(CcNodeType.PROXYSQL)) {
            exporters.push(CcPrometheusExporterJob.PROXYSQL);
        }
    } else if (cluster.isBase(CcClusterBase.BASE_POSTGRESQL)) {
        exporters.push(CcPrometheusExporterJob.POSTGRES);
        if (cluster.containsNodeType(CcNodeType.PGBOUNCER)) {
            exporters.push(CcPrometheusExporterJob.PGBOUNCER);
        }
    } else if (cluster.isBase(CcClusterBase.BASE_MONGODB)) {
        exporters.push(CcPrometheusExporterJob.MONGO);
    } else if (cluster.isType(CcClusterType.TYPE_MSSQL_SINGLE)) {
        exporters.push(CcPrometheusExporterJob.MSSQL);
    } else if (cluster.isType(CcClusterType.TYPE_REDIS)) {
        exporters.push(CcPrometheusExporterJob.REDIS);
    }

    if (
        (cluster.isTechnology(CcClusterTechnology.TECHNOLOGY_MYSQL) ||
            cluster.isTechnology(CcClusterTechnology.TECHNOLOGY_POSTGRESQL)) &&
        cluster.containsNodeType(CcNodeType.HAPROXY)
    ) {
        exporters.push(CcPrometheusExporterJob.HAPROXY);
    }

    return exporters.map((type) => ({
        type,
        title: getPrometheusExporterTitle(type),
        availableArguments: getPrometheusExporterAvailableArguments(type),
    }));
}

export function getPrometheusExporterAvailableArguments(
    type: CcPrometheusExporterJob
) {
    switch (type) {
        case 'mysqld':
            return [
                '--collect.perf_schema.eventswaits',
                '--collect.perf_schema.file_events',
                '--collect.perf_schema.file_instances',
                '--collect.perf_schema.indexiowaits',
                '--collect.perf_schema.tableiowaits',
                '--collect.perf_schema.tablelocks',
                '--collect.info_schema.tablestats',
                '--collect.info_schema.processlist',
                '--collect.binlog_size',
                '--collect.global_status',
                '--collect.global_variables',
                '--collect.info_schema.innodb_metrics',
                '--collect.slave_status',
                '--collect.info_schema.userstats',
            ];
        default:
            return [];
    }
}

export function getPrometheusExporterTitle(type: CcPrometheusExporterJob) {
    switch (type) {
        case CcPrometheusExporterJob.NODE:
            return 'Node exporter';
        case CcPrometheusExporterJob.MYSQLD:
            return 'MySQL exporter';
        case CcPrometheusExporterJob.MONGO:
            return 'MongoDB exporter';
        case CcPrometheusExporterJob.POSTGRES:
            return 'Postgres exporter';
        case CcPrometheusExporterJob.MSSQL:
            return 'MS SQL exporter';
        case CcPrometheusExporterJob.REDIS:
            return 'Redis exporter';
        case CcPrometheusExporterJob.PROXYSQL:
            return 'ProxySQL exporter';
        case CcPrometheusExporterJob.HAPROXY:
            return 'HaProxy exporter';
        default:
            return `${type} exporter`;
    }
}

export function getPrometheusExporterDefaultArguments(
    type: CcPrometheusExporterJob
) {
    // @todo available arguments might be not default
    return getPrometheusExporterAvailableArguments(type);
}

export function getPrometheusNodeFormValues(
    node: CcPrometheusNode
): PrometheusFormValues {
    return {
        host: node.hostname || '',
        scrapeInterval: parseInt(node.scrapeInterval || '0'),
        dataRetention: parseInt(node.dataRetention || '0'),
        dataRetentionSize: parseInt(node.dataRetentionSize || '0'),
        ...(node.configuration?.reduce((a: any, c: any) => {
            return {
                ...a,
                [c.job]: {
                    scrapeInterval: parseInt(c.scrape_interval),
                    arguments:
                        c.arguments?.split(' ').filter((i: string) => !!i) ||
                        [],
                },
            };
        }, {}) || {}),
    };
}
