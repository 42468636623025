import React from 'react';
import { Result } from 'antd';
import { Link } from 'react-router-dom';

export default Error403;

function Error403() {
    return (
        <Result
            className="Error403"
            title="Access denied"
            subTitle={
                <span>
                    You don’t have permission to access the requested page.
                    <br />
                    To get access to this page, please, contact your local
                    administrator.
                </span>
            }
            icon={<img alt="404" src="/error403.svg" width={200} />}
            extra={<Link to="/dashboard">Back Home</Link>}
        />
    );
}
