import React from 'react';
import { Col, Radio, Row } from 'antd';
import ClusterNameAndTags from '../../FormParts/ClusterNameAndTags';
import FormItemInline from '../../../../../common/FormItemInline';
import { FormInstance } from 'antd/lib/form';
import { ClusterConfigurator } from '../../ClusterConfigurator';

export default MongoImportClusterDetails;

export type MongoImportClusterDetailsProps = {
    form: FormInstance;
    configurator: typeof ClusterConfigurator;
};

function MongoImportClusterDetails({
    form,
    configurator,
}: MongoImportClusterDetailsProps) {
    return (
        <div className="ImportClusterDetails">
            <Col span={24}>
                <h3>Vendor and version</h3>
            </Col>
            <Col xs={24} sm={24} md={16}>
                <FormItemInline
                    name={['details', 'vendor']}
                    label="Vendor"
                    rules={[
                        {
                            required: true,
                            message: 'Please select a vendor.',
                        },
                    ]}
                >
                    <Radio.Group>
                        {configurator.getVendors().map((v) => (
                            <Radio.Button key={v.value} value={v.value}>
                                {v.name}
                            </Radio.Button>
                        ))}
                    </Radio.Group>
                </FormItemInline>
            </Col>
            <Row gutter={[24, 0]}>
                <ClusterNameAndTags />
            </Row>
        </div>
    );
}
