import React from 'react';
import { Form, Col, Row, Input, Space } from 'antd';
import AppSelect from '../../../../../../../common/DataEntry/AppSelect';
import InputNumberWide from '../../../../../../../common/DataEntry/InputNumberWide';
import InfoIcon from '@severalnines/bar-frontend-components/build/lib/General/InfoIcon';

export const CACHE_CONFIGURATION_VALIDATION = [
    ['cacheConfiguration', 'ruleId'],
    ['cacheConfiguration', 'hostgroup'],
];

export default RulesCacheQueryConfig;
type RulesCacheQueryConfigProps = {
    hostGroupList: { value: string; label: string }[] | undefined;
};

function RulesCacheQueryConfig({ hostGroupList }: RulesCacheQueryConfigProps) {
    return (
        <div style={{ minHeight: 450 }}>
            <Row gutter={[24, 0]}>
                <Col span={24}>
                    <h3>Configuration</h3>
                </Col>
            </Row>
            <Row gutter={[24, 0]}>
                <Col span={12}>
                    <Form.Item
                        name={['cacheConfiguration', 'ruleId']}
                        label={
                            <Space>
                                Rule ID
                                <InfoIcon
                                    info={
                                        'The unique id of the rule. Rules are processed in Rule ID order.'
                                    }
                                />
                            </Space>
                        }
                        rules={[
                            {
                                required: true,
                                message: 'Please enter rule id.',
                            },
                        ]}
                    >
                        <InputNumberWide
                            min={0}
                            placeholder="Enter rule id."
                        ></InputNumberWide>
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={[24, 0]}>
                <Col span={12}>
                    <Form.Item
                        name={['cacheConfiguration', 'username']}
                        label={
                            <Space>
                                Username
                                <InfoIcon
                                    info={
                                        'Filtering criteria matching username. If is not empty, a query will match only if the connection is made with the correct username.'
                                    }
                                />
                            </Space>
                        }
                    >
                        <Input placeholder="Enter username."></Input>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        name={['cacheConfiguration', 'hostgroup']}
                        label={
                            <Space>
                                Destination hostgroup
                                <InfoIcon
                                    info={
                                        'Route matched queries to this hostgroup. This happens unless there is a started transaction and the logged in user has the transaction_persistent flag set to 1.'
                                    }
                                />
                            </Space>
                        }
                        rules={[
                            {
                                required: true,
                                message: 'Please enter destination hostgroup.',
                            },
                        ]}
                    >
                        <AppSelect
                            placeholder={'Enter destination hostgroup.'}
                            options={hostGroupList}
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        name={['cacheConfiguration', 'schemaName']}
                        label={
                            <Space>
                                Schema name
                                <InfoIcon
                                    info={
                                        'Filtering criteria matching schema name. If is not empty, a query will match only if the connection uses this schema name as default schema.'
                                    }
                                />
                            </Space>
                        }
                    >
                        <Input placeholder="Enter schema name."></Input>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        name={['cacheConfiguration', 'cacheTTL']}
                        label={
                            <Space>
                                Cache TTL
                                <InfoIcon
                                    info={
                                        'The number of milliseconds for which to cache the result of the query. Note: in ProxySQL 1.1 cache_ttl was in seconds.'
                                    }
                                />
                            </Space>
                        }
                    >
                        <InputNumberWide
                            min={0}
                            placeholder="Click here to enter Cache TTL."
                        ></InputNumberWide>
                    </Form.Item>
                </Col>
            </Row>
        </div>
    );
}
