const dashboard = {
    title: 'Galera Overview',
    meta: {
        slug: 'galera-overview',
        supportClusterTypes: ['galera'],
    },
    panels: [
        {
            title: 'Cluster size',
            type: 'singlestat',
            gridPos: {
                h: 6,
                w: 3,
                x: 0,
                y: 0,
            },
            options: {
                decimals: 0,
                format: 'number',
                legend: {},
                chart: {
                    full: false,
                    show: true,
                },
            },
            timeFrom: '24h',
            targets: [
                {
                    expr:
                        'max(mysql_global_status_wsrep_cluster_size * on (instance) group_left mysql_galera_variables_info{wsrep_cluster_name="$cluster"})',
                    legendFormat: '',
                },
            ],
        },
        {
            title: 'Flow control paused',
            type: 'chart',
            gridPos: {
                h: 6,
                w: 7,
                x: 0,
                y: 0,
            },
            options: {
                decimals: 2,
                format: 'short',
                type: 'area',
                legend: {
                    alignAsTable: true,
                },
                yaxis: [
                    {
                        format: 'short',
                        max: 100,
                        min: 0,
                    },
                ],
                stack: false,
            },
            targets: [
                {
                    expr:
                        '100*mysql_global_status_wsrep_flow_control_paused{instance=~"$instance"}',
                    legendFormat: '{{ instance }}',
                    yaxis: 0,
                },
            ],
        },
        {
            title: 'Flow control sent',
            type: 'chart',
            gridPos: {
                h: 6,
                w: 7,
                x: 0,
                y: 0,
            },
            options: {
                decimals: 2,
                format: 'short',
                type: 'area',
                legend: {
                    alignAsTable: true,
                },
                yaxis: [
                    {
                        format: 'short',
                        max: null,
                        min: 0,
                    },
                ],
                stack: false,
            },
            targets: [
                {
                    expr:
                        'irate(mysql_global_status_wsrep_flow_control_sent{instance=~"$instance"}[5m])',
                    legendFormat: '{{ instance }}',
                    yaxis: 0,
                },
            ],
        },
        {
            title: 'Flow control received',
            type: 'chart',
            gridPos: {
                h: 6,
                w: 7,
                x: 0,
                y: 0,
            },
            options: {
                decimals: 2,
                format: 'short',
                type: 'area',
                legend: {
                    alignAsTable: true,
                },
                yaxis: [
                    {
                        format: 'short',
                        max: null,
                        min: 0,
                    },
                ],
                stack: false,
            },
            targets: [
                {
                    expr:
                        'irate(mysql_global_status_wsrep_flow_control_recv{instance=~"$instance"}[5m])',
                    legendFormat: '{{ instance }}',
                    yaxis: 0,
                },
            ],
        },
        {
            title: 'BF aborts',
            type: 'chart',
            gridPos: {
                h: 6,
                w: 8,
                x: 0,
                y: 0,
            },
            options: {
                decimals: 2,
                format: 'short',
                type: 'area',
                legend: {
                    alignAsTable: true,
                },
                yaxis: [
                    {
                        format: 'short',
                        max: null,
                        min: 0,
                    },
                ],
                stack: false,
            },
            targets: [
                {
                    expr:
                        'rate(mysql_global_status_wsrep_local_bf_aborts{instance=~"$instance"}[1m])',
                    legendFormat: '{{ instance }}',
                    yaxis: 0,
                },
            ],
        },
        {
            title: 'Replicated bytes',
            type: 'chart',
            gridPos: {
                h: 6,
                w: 8,
                x: 0,
                y: 0,
            },
            options: {
                decimals: 2,
                format: 'short',
                type: 'area',
                legend: {
                    alignAsTable: true,
                },
                yaxis: [
                    {
                        format: 'short',
                        max: null,
                        min: 0,
                    },
                ],
                stack: false,
            },
            targets: [
                {
                    expr:
                        'irate(mysql_global_status_wsrep_replicated_bytes{instance=~"$instance"}[1m])',
                    legendFormat: '{{ instance }}',
                    yaxis: 0,
                },
            ],
        },
        {
            title: 'Received bytes',
            type: 'chart',
            gridPos: {
                h: 6,
                w: 8,
                x: 0,
                y: 0,
            },
            options: {
                decimals: 2,
                format: 'short',
                type: 'area',
                legend: {
                    alignAsTable: true,
                },
                yaxis: [
                    {
                        format: 'short',
                        max: null,
                        min: 0,
                    },
                ],
                stack: false,
            },
            targets: [
                {
                    expr:
                        'irate(mysql_global_status_wsrep_received_bytes{instance=~"$instance"}[1m])',
                    legendFormat: '{{ instance }}',
                    yaxis: 0,
                },
            ],
        },
        {
            title: 'Desync count',
            type: 'chart',
            gridPos: {
                h: 6,
                w: 8,
                x: 0,
                y: 0,
            },
            options: {
                decimals: 2,
                format: 'short',
                type: 'area',
                legend: {
                    alignAsTable: true,
                },
                yaxis: [
                    {
                        format: 'short',
                        max: null,
                        min: 0,
                    },
                ],
                stack: false,
            },
            targets: [
                {
                    expr:
                        'mysql_global_status_wsrep_desync_count{instance=~"$instance"}',
                    legendFormat: '{{ instance }}',
                    yaxis: 0,
                },
            ],
        },
        {
            title: 'Apply OOOE (out of order execution)',
            type: 'chart',
            gridPos: {
                h: 6,
                w: 8,
                x: 0,
                y: 0,
            },
            options: {
                decimals: 2,
                format: 'short',
                type: 'area',
                legend: {
                    alignAsTable: true,
                },
                yaxis: [
                    {
                        format: 'short',
                        max: null,
                        min: 0,
                    },
                ],
                stack: false,
            },
            targets: [
                {
                    expr:
                        'mysql_global_status_wsrep_apply_oooe{instance=~"$instance"}',
                    legendFormat: '{{ instance }}',
                    yaxis: 0,
                },
            ],
        },
        {
            title: 'Apply OOOL',
            type: 'chart',
            gridPos: {
                h: 6,
                w: 8,
                x: 0,
                y: 0,
            },
            options: {
                decimals: 2,
                format: 'short',
                type: 'area',
                legend: {
                    alignAsTable: true,
                },
                yaxis: [
                    {
                        format: 'short',
                        max: null,
                        min: 0,
                    },
                ],
                stack: false,
            },
            targets: [
                {
                    expr:
                        'mysql_global_status_wsrep_apply_oool{instance=~"$instance"}',
                    legendFormat: '{{ instance }}',
                    yaxis: 0,
                },
            ],
        },
        {
            title: 'Apply window',
            type: 'chart',
            gridPos: {
                h: 6,
                w: 8,
                x: 0,
                y: 0,
            },
            options: {
                decimals: 2,
                format: 'short',
                type: 'area',
                legend: {
                    alignAsTable: true,
                },
                yaxis: [
                    {
                        format: 'short',
                        max: null,
                        min: 0,
                    },
                ],
                stack: false,
            },
            targets: [
                {
                    expr:
                        'mysql_global_status_wsrep_apply_window{instance=~"$instance"}',
                    legendFormat: '{{ instance }}',
                    yaxis: 0,
                },
            ],
        },
        {
            title: 'Certification deps. distance',
            type: 'chart',
            gridPos: {
                h: 6,
                w: 8,
                x: 0,
                y: 0,
            },
            options: {
                decimals: 2,
                format: 'short',
                type: 'area',
                legend: {
                    alignAsTable: true,
                },
                yaxis: [
                    {
                        format: 'short',
                        max: null,
                        min: 0,
                    },
                ],
                stack: false,
            },
            targets: [
                {
                    expr:
                        'mysql_global_status_wsrep_cert_deps_distance{instance=~"$instance"}',
                    legendFormat: '{{ instance }}',
                    yaxis: 0,
                },
            ],
        },
        {
            title: 'Certification interval',
            type: 'chart',
            gridPos: {
                h: 6,
                w: 8,
                x: 0,
                y: 0,
            },
            options: {
                decimals: 2,
                format: 'short',
                type: 'area',
                legend: {
                    alignAsTable: true,
                },
                yaxis: [
                    {
                        format: 'short',
                        max: null,
                        min: 0,
                    },
                ],
                stack: false,
            },
            targets: [
                {
                    expr:
                        'mysql_global_status_wsrep_cert_interval{instance=~"$instance"}',
                    legendFormat: '{{ instance }}',
                    yaxis: 0,
                },
            ],
        },
    ],
    templating: {
        list: [
            {
                datasource: 'clustercontrol',
                hide: 1,
                multi: false,
                label: 'Cluster',
                name: 'cluster',
                query:
                    "node:$[nodetype='galera'].{'galeraClusterName': galera.clustername}",
                optionValue: 'galeraClusterName',
                optionText: 'galeraClusterName',
                sort: 1,
            },
            {
                allFormat: 'pipe',
                allValue: null,
                datasource: 'clustercontrol',
                hide: 0,
                multi: true,
                multiFormat: 'pipe',
                includeAll: true,
                label: 'Host',
                name: 'instance',
                query: "host:$[nodes[nodetype='galera']]",
                optionValue: 'hostname',
                optionText: 'hostname',
                sort: 1,
                isInstancePicker: true,
            },
        ],
    },
    schemaVersion: 1,
};
export default dashboard;
