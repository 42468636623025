import React, { useMemo } from 'react';
import { ButtonProps } from 'antd/lib/button';
import { CmonJobInstanceCommand } from '../../../services/cmon/models/CmonJobInstance';
import JobConfirmActionButton from '../../Jobs/JobConfirmActionButton';
import CcPostgreSqlNode from '../../../services/models/CcPostgreSqlNode';
import Alert from '@severalnines/bar-frontend-components/build/lib/Feedback/Alert';
import { Space } from 'antd';

export default NodeToggleSynchronousReplicationButton;

export type NodeToggleSynchronousReplicationButtonProps = ButtonProps & {
    node: CcPostgreSqlNode;
    children?: React.ReactNode;
    onSuccess?: () => void;
};

function NodeToggleSynchronousReplicationButton({
    node,
    children,
    onSuccess,
    ...rest
}: NodeToggleSynchronousReplicationButtonProps) {
    const jobData = useMemo(
        () => ({
            node: { hostname: node.hostname, port: node.port },
            synchronous: !node.isSyncStateSynchronous(),
        }),
        [node]
    );
    return (
        <JobConfirmActionButton
            clusterId={node.clusterid}
            command={CmonJobInstanceCommand.TOGGLE_REPLICATION_SYNC}
            confirmTitle={
                <Space direction="vertical" style={{ width: '400px' }}>
                    <span>
                        {node.isSyncStateSynchronous()
                            ? 'Disable synchronous replication?'
                            : 'Enable synchronous replication?'}
                    </span>
                    <Alert
                        showIcon
                        message="The master will be re-configured (but will not be restarted), then the slave is going to be restarted to apply the new replication settings."
                    />
                </Space>
            }
            title={
                node.isSyncStateSynchronous()
                    ? 'Disable synchronous replication'
                    : 'Enable synchronous replication'
            }
            onSuccess={onSuccess}
            jobData={jobData}
            {...rest}
        />
    );
}
