import React from 'react';

import StatusFormat, {
    StatusFormatStatus,
    StatusFormatType,
} from '@severalnines/bar-frontend-components/build/lib/Format/StatusFormat';
import CcBackup, { CcBackupStatus } from '../../services/models/CcBackup';
import CcJob from '../../services/models/CcJob';
import InfoIcon from '@severalnines/bar-frontend-components/build/lib/General/InfoIcon';

import { CmonJobInstanceCommand } from '../../services/cmon/models/CmonJobInstance';
import { Space } from 'antd';

export default BackupStatusFormat;

export function getBackupStatusFormatType(status: CcBackupStatus) {
    switch (status) {
        case CcBackupStatus.STATUS_COMPLETED:
            return StatusFormatStatus.success;
        case CcBackupStatus.STATUS_FAILED_MISSING_DATA:
        case CcBackupStatus.STATUS_FAILED:
            return StatusFormatStatus.error;
        case CcBackupStatus.STATUS_PENDING:
            return StatusFormatStatus.warning;

        case CcBackupStatus.STATUS_RUNNING:
            return StatusFormatStatus.info;
        case CcBackupStatus.STATUS_COMPLETED_MISSING_DATA:
        default:
            return StatusFormatStatus.unknown;
    }
}

export function getBackupStatusFormatText(status?: CcBackupStatus) {
    switch (status) {
        case CcBackupStatus.STATUS_COMPLETED:
            return 'Backup completed';
        case CcBackupStatus.STATUS_FAILED:
            return 'Backup failed';
        case CcBackupStatus.STATUS_PENDING:
            return 'Backup pending';
        case CcBackupStatus.STATUS_RUNNING:
            return 'Backup running';
        case CcBackupStatus.STATUS_COMPLETED_MISSING_DATA:
            // assuming status tells that backup was done successfully but at some point data got missing
            return 'Missing data';
        case CcBackupStatus.STATUS_FAILED_MISSING_DATA:
            // Not sure in which case this status can appear, so returning status name as is
            return CcBackupStatus.STATUS_FAILED_MISSING_DATA;
        default:
            return 'Status unknown';
    }
}

export function getBackupJobTypeFormatText(job: CcJob) {
    switch (job.getCommand()) {
        case CmonJobInstanceCommand.DELETE_BACKUP:
            return 'Deleting backup';
        case CmonJobInstanceCommand.RESTORE_BACKUP:
            return 'Restoring backup';
        case CmonJobInstanceCommand.UPLOAD_BACKUP_DATA_TO_CLOUD_STORAGE:
            return 'Uploading backup';
        case CmonJobInstanceCommand.DOWNLOAD_BACKUP_DATA_FROM_CLOUD_STORAGE:
            return 'Downloading backup';
    }
}

export function getBackupJobTypeStatusFormatText(job: CcJob) {
    switch (job.getCommand()) {
        case CmonJobInstanceCommand.DELETE_BACKUP:
            return StatusFormatStatus.error;
        case CmonJobInstanceCommand.RESTORE_BACKUP:
        case CmonJobInstanceCommand.UPLOAD_BACKUP_DATA_TO_CLOUD_STORAGE:
        case CmonJobInstanceCommand.DOWNLOAD_BACKUP_DATA_FROM_CLOUD_STORAGE:
            return StatusFormatStatus.info;
    }
}

export function getBackupInfoIcon(backup: CcBackup) {
    if (backup.getStatus() === CcBackupStatus.STATUS_COMPLETED_MISSING_DATA) {
        return (
            <InfoIcon info="The backup was completed successfully but data appears to be missing" />
        );
    }
    return undefined;
}

export type BackupStatusFormatProps = {
    backup: CcBackup;
    runningJob?: CcJob;
    type?: StatusFormatType;
};

function BackupStatusFormat({
    backup,
    runningJob,
    ...rest
}: BackupStatusFormatProps) {
    return (
        <StatusFormat
            className="BackupStatusFormat"
            status={
                (runningJob && getBackupJobTypeStatusFormatText(runningJob)) ||
                getBackupStatusFormatType(backup.getStatus())
            }
            nowrap={true}
            {...rest}
        >
            <Space>
                <span>
                    {(runningJob && getBackupJobTypeFormatText(runningJob)) ||
                        getBackupStatusFormatText(backup.getStatus())}
                </span>
                {getBackupInfoIcon(backup)}
            </Space>
        </StatusFormat>
    );
}
