import React from 'react';
import StatisticBadge, {
    StatisticBadgeProps,
} from '../../common/StatisticBadge';
import CcAlarm, { CcAlarmSeverity } from '../../services/models/CcAlarm';
import {
    getAlarmSeverityFormatText,
    getAlarmSeverityFormatType,
} from './AlarmSeverityFormat';
import { useStateAlarmsStat } from './useCurrentAlarmsList';

export default AlarmsBadge;

export type AlarmsBadgeProps = StatisticBadgeProps & {
    severity?: CcAlarmSeverity;
    clusterId?: number;
};

function AlarmsBadge({ severity, clusterId, ...rest }: AlarmsBadgeProps) {
    const state = useStateAlarmsStat({
        filters: [
            (alarm: CcAlarm) => !clusterId || alarm.clusterId === clusterId,
        ],
    });

    const alarmCount = state?.getStatsCount(severity) || 0;

    return (
        <StatisticBadge
            count={alarmCount}
            dot={false}
            title={`${alarmCount}${
                severity
                    ? ` ${getAlarmSeverityFormatText(
                          severity
                      ).toLocaleLowerCase()}`
                    : ''
            } alarms`}
            status={getAlarmSeverityFormatType(
                severity || CcAlarmSeverity.ALARM_CRITICAL
            )}
            {...rest}
        />
    );
}
