import { FormInstance } from 'antd';
import { digestTextToMatchPattern } from '../ProxySQLTopQueries/ProxySQLTopQueriesHelper';
import { RULES_ADV_SETTING_INITIAL } from './ProxySQLRulesAdvSetting';
import { RULES_SETTING_INITIAL } from './ProxySQLRulesSetting';
import { RULES_TYPE_INITIAL } from './ProxySQLRulesType';

export type ProxySqlEndPointRuleData = {
    [key: string]: any;
};

export type TopQueryDataType = {
    digestText?: string;
    hostgroup?: string;
    digest?: string;
    schemaName?: string;
};

export function prepareTableRules(rules: ProxySqlEndPointRuleData[]) {
    return rules.map((rule: ProxySqlEndPointRuleData, index: any) => {
        return {
            key: `${index + 1}`,
            rule: `Rule ${rule.ruleId}`,
            status: `${rule.active === '1' ? 'Active' : 'Inactive'}`,
            apply: `${rule.apply === '1' ? 'Yes' : 'No'}`,
            hits: `${rule.hits}`,
            query: `${rule.matchPattern}`,
            hostGroup: `${rule.destinationHostgroup}`,
            pattern: `${rule.negateMatchPattern}`,
            userName: `${rule.username}`,
            schemaName: `${rule.schemaname}`,
            actions: {
                queryRule: rule,
            },
        };
    });
}

export function prepareInitialValue(
    edit?: boolean,
    rule?: ProxySqlEndPointRuleData,
    nextQueryRuleId?: number,
    topQueryCreate?: boolean,
    topQueryCache?: boolean,
    topQueryData?: TopQueryDataType
) {
    if (edit && rule) {
        return { ...prepareInitialEditValue(rule) };
    } else if (topQueryCreate) {
        return {
            ...RULES_TYPE_INITIAL,
            ...RULES_SETTING_INITIAL,
            ...RULES_ADV_SETTING_INITIAL,
            rulesConfiguration: {
                ruleId: nextQueryRuleId ? nextQueryRuleId : 100,
                schemaName: topQueryData?.schemaName
                    ? topQueryData.schemaName
                    : '',
            },
            rulesType: {
                ...RULES_TYPE_INITIAL,
                ruleType: topQueryData?.digest
                    ? 'digestType'
                    : digestTextToMatchPattern(topQueryData?.digestText)
                    ? 'matchDigestType'
                    : 'matchPatternType',
                digest: topQueryData?.digest ? topQueryData.digest : '',
                comments: topQueryData?.digestText
                    ? topQueryData.digestText
                    : '',
                matchDigest: digestTextToMatchPattern(topQueryData?.digestText),
                matchPattern: digestTextToMatchPattern(
                    topQueryData?.digestText
                ),
            },
        };
    } else if (topQueryCache) {
        return {
            ...RULES_TYPE_INITIAL,
            ...RULES_SETTING_INITIAL,
            ...RULES_ADV_SETTING_INITIAL,
            cacheConfiguration: {
                cacheTTL: 1000,
                ruleId: nextQueryRuleId ? nextQueryRuleId : 100,
                schemaName: topQueryData?.schemaName
                    ? topQueryData.schemaName
                    : '',
            },
            rulesType: {
                ...RULES_TYPE_INITIAL,
                ruleType: topQueryData?.digest
                    ? 'digestType'
                    : digestTextToMatchPattern(topQueryData?.digestText)
                    ? 'matchDigestType'
                    : 'matchPatternType',
                digest: topQueryData?.digest ? topQueryData.digest : '',
                comments: topQueryData?.digestText
                    ? topQueryData.digestText
                    : '',
                matchDigest: digestTextToMatchPattern(topQueryData?.digestText),
                matchPattern: digestTextToMatchPattern(
                    topQueryData?.digestText
                ),
            },
        };
    } else {
        return {
            ...RULES_TYPE_INITIAL,
            ...RULES_SETTING_INITIAL,
            ...RULES_ADV_SETTING_INITIAL,
            rulesConfiguration: {
                ruleId: nextQueryRuleId ? nextQueryRuleId : 100,
            },
        };
    }
}

export function prepareCacheQueryData(
    form: FormInstance,
    topQueryData?: TopQueryDataType
) {
    const { cacheConfiguration, rulesType } = form.getFieldsValue(true);
    return {
        class_name: 'CmonProxySqlQueryRule',

        // cacheConfiguration
        rule_id: cacheConfiguration.ruleId,
        destination_hostgroup: +cacheConfiguration.hostgroup,

        ...(cacheConfiguration.username && {
            username: cacheConfiguration.username,
        }),
        ...(cacheConfiguration.schemaName && {
            schemaname: cacheConfiguration.schemaName,
        }),
        ...(cacheConfiguration.cacheTTL !== undefined && {
            cache_ttl: +cacheConfiguration.cacheTTL,
        }),

        ...(rulesType.ruleType === 'digestType' &&
            rulesType.digest && {
                digest: rulesType.digest,
            }),
        ...(rulesType.comments && { comment: rulesType.comments }),
        match_digest: rulesType.matchDigest ? rulesType.matchDigest : null,
        match_pattern: rulesType.matchPattern ? rulesType.matchPattern : null,
        replace_pattern: rulesType.replacePattern
            ? rulesType.replacePattern
            : null,
        active: 1,
        apply: 1,
        cache_ttl: 1000,
        log: null,
        negate_match_pattern: 0,
        reconnect: null,
        sticky_conn: null,
        multiplex: null,
        comment: topQueryData?.digestText ? topQueryData.digestText : '',
        digest: topQueryData?.digest ? topQueryData.digest : '',
        schemaname: topQueryData?.schemaName ? topQueryData.schemaName : '',
    };
}

export function prepareCreateRuleFormData(form: FormInstance) {
    const {
        rulesConfiguration,
        rulesType,
        rulesSetting,
        rulesAdvSetting,
    } = form.getFieldsValue(true);

    return {
        class_name: 'CmonProxySqlQueryRule',

        // rulesConfiguration
        rule_id: rulesConfiguration.ruleId,
        destination_hostgroup: +rulesConfiguration.hostgroup,
        ...(rulesConfiguration.proxyAddress && {
            proxy_addr: rulesConfiguration.proxyAddress,
        }),
        ...(rulesConfiguration.proxyPort && {
            proxy_port: rulesConfiguration.proxyPort,
        }),
        ...(rulesConfiguration.username && {
            username: rulesConfiguration.username,
        }),
        ...(rulesConfiguration.schemaName && {
            schemaname: rulesConfiguration.schemaName,
        }),
        ...(rulesConfiguration.clientAddress && {
            client_addr: rulesConfiguration.clientAddress,
        }),

        // rulesSetting
        active: rulesSetting.active ? 1 : 0,
        apply: rulesSetting.apply ? 1 : 0,
        ...(rulesSetting.timeout !== undefined && {
            timeout: +rulesSetting.timeout,
        }),
        ...(rulesSetting.cacheTTL !== undefined && {
            cache_ttl: +rulesSetting.cacheTTL,
        }),
        ...(rulesSetting.retries !== undefined && {
            retries: +rulesSetting.retries,
        }),

        ...(rulesType.ruleType === 'digestType' &&
            rulesType.digest && {
                digest: rulesType.digest,
            }),
        ...(rulesType.comments && { comment: rulesType.comments }),
        match_digest: rulesType.matchDigest ? rulesType.matchDigest : null,
        match_pattern: rulesType.matchPattern ? rulesType.matchPattern : null,
        replace_pattern: rulesType.replacePattern
            ? rulesType.replacePattern
            : null,

        // rulesAdvSetting
        log:
            rulesAdvSetting.log === 'enabled'
                ? 1
                : rulesAdvSetting.log === 'disabled'
                ? 0
                : null,
        reconnect:
            rulesAdvSetting.reconnect === 'enabled'
                ? 1
                : rulesAdvSetting.reconnect === 'disabled'
                ? 0
                : null,
        negate_match_pattern: rulesAdvSetting.negativePattern ? 1 : 0,
        sticky_conn:
            rulesAdvSetting.stickyConn !== undefined
                ? +rulesAdvSetting.stickyConn
                : null,
        multiplex:
            rulesAdvSetting.multiplex !== undefined
                ? +rulesAdvSetting.multiplex
                : null,

        ...(rulesAdvSetting.delay !== undefined && {
            delay: +rulesAdvSetting.delay,
        }),
        ...(rulesAdvSetting.flagIN !== undefined && {
            flagIN: +rulesAdvSetting.flagIN,
        }),
        ...(rulesAdvSetting.flagOut !== undefined && {
            flagOUT: +rulesAdvSetting.flagOut,
        }),
        ...(rulesAdvSetting.mirrorFlagOut !== undefined && {
            mirror_flagOUT: +rulesAdvSetting.mirrorFlagOut,
        }),
        ...(rulesAdvSetting.mirrorHostGroup !== undefined && {
            mirror_hostgroup: +rulesAdvSetting.mirrorHostGroup,
        }),
        ...(rulesAdvSetting.okMessage && {
            OK_msg: rulesAdvSetting.okMessage,
        }),
        ...(rulesAdvSetting.errorMessage && {
            error_msg: rulesAdvSetting.errorMessage,
        }),
    };
}

export function prepareEditRuleFormData(form: FormInstance) {
    const {
        rulesConfiguration,
        rulesType,
        rulesSetting,
        rulesAdvSetting,
    } = form.getFieldsValue(true);
    return {
        class_name: 'CmonProxySqlQueryRule',
        active: rulesSetting.active ? 1 : 0,
        apply: rulesSetting.apply ? 1 : 0,
        cache_ttl: rulesSetting.cacheTTL ? +rulesSetting.cacheTTL : null,
        client_addr: rulesConfiguration.clientAddress
            ? rulesConfiguration.clientAddress
            : null,
        comment: rulesType.comments ? rulesType.comments : null,
        delay:
            rulesAdvSetting.delay !== undefined ? +rulesAdvSetting.delay : null,
        destination_hostgroup: +rulesConfiguration.hostgroup,

        ...(rulesType.ruleType === 'digestType' && rulesType.digest
            ? {
                  digest: rulesType.digest,
              }
            : { digest: null }),

        flagIN:
            rulesAdvSetting.flagIN !== undefined
                ? +rulesAdvSetting.flagIN
                : null,
        flagOUT:
            rulesAdvSetting.flagOut !== undefined
                ? rulesAdvSetting.flagOut
                : null,
        log:
            rulesAdvSetting.log === 'enabled'
                ? 1
                : rulesAdvSetting.log === 'disabled'
                ? 0
                : null,

        ...(rulesType.ruleType === 'matchDigestType' && rulesType.matchDigest
            ? {
                  match_digest: rulesType.matchDigest,
              }
            : { match_digest: null }),

        ...(rulesType.ruleType === 'matchPatternType' && rulesType.matchPattern
            ? {
                  match_pattern: rulesType.matchPattern,
              }
            : { match_pattern: null }),

        mirror_flagOUT:
            rulesAdvSetting.mirrorFlagOut !== undefined
                ? +rulesAdvSetting.mirrorFlagOut
                : null,
        mirror_hostgroup:
            rulesAdvSetting.mirrorHostGroup !== undefined
                ? +rulesAdvSetting.mirrorHostGroup
                : null,
        negate_match_pattern: rulesAdvSetting.negativePattern ? 1 : 0,
        proxy_addr: rulesConfiguration.proxyAddress
            ? rulesConfiguration.proxyAddress
            : null,
        proxy_port: rulesConfiguration.proxyPort
            ? rulesConfiguration.proxyPort
            : null,
        reconnect:
            rulesAdvSetting.reconnect === 'enabled'
                ? 1
                : rulesAdvSetting.reconnect === 'disabled'
                ? 0
                : null,
        ...(rulesType.ruleType === 'matchPatternType' &&
        rulesType.replacePattern
            ? {
                  replace_pattern: rulesType.replacePattern,
              }
            : { replace_pattern: null }),

        retries:
            rulesSetting.retries !== undefined ? +rulesSetting.retries : null,
        schemaname: rulesConfiguration.schemaName
            ? rulesConfiguration.schemaName
            : null,
        timeout:
            rulesSetting.timeout !== undefined ? +rulesSetting.timeout : null,
        username: rulesConfiguration.username
            ? rulesConfiguration.username
            : null,
        OK_msg: rulesAdvSetting.okMessage ? rulesAdvSetting.okMessage : null,
        error_msg: rulesAdvSetting.errorMessage
            ? rulesAdvSetting.errorMessage
            : null,
        sticky_conn:
            rulesAdvSetting.stickyConn !== undefined
                ? +rulesAdvSetting.stickyConn
                : null,
        multiplex:
            rulesAdvSetting.multiplex !== undefined
                ? +rulesAdvSetting.multiplex
                : null,
    };
}

export function prepareInitialEditValue(data: ProxySqlEndPointRuleData) {
    return {
        rulesType: {
            ruleType: data.digest
                ? 'digestType'
                : data.matchDigest
                ? 'matchDigestType'
                : 'matchPatternType',
            digest: data.digest ? data.digest : '',
            comments: data.comment ? data.comment : '',
            matchDigest: data.matchDigest ? data.matchDigest : '',
            matchPattern: data.matchPattern ? data.matchPattern : '',
            replacePattern: data.replacePattern ? data.replacePattern : '',
        },
        rulesSetting: {
            active: data.active === '1' ? true : false,
            apply: data.apply === '1' ? true : false,
            cacheTTL: data.cacheTtl ? +data.cacheTtl : '',
            retries: data.retries ? +data.retries : '',
            timeout: data.timeout !== null ? +data.timeout : '',
        },
        rulesAdvSetting: {
            delay: data.delay !== null ? +data.delay : '',
            flagOut: data.flagOUT !== null ? +data.flagOUT : '',
            flagIN: data.flagIN !== null ? +data.flagIN : '',
            stickyConn: data.stickyConn !== null ? +data.stickyConn : '',
            multiplex: data.multiplex !== null ? +data.multiplex : '',
            mirrorHostGroup:
                data.mirrorHostgroup !== null ? +data.mirrorHostgroup : '',
            mirrorFlagOut:
                data.mirrorFlagOUT !== null ? +data.mirrorFlagOUT : '',
            errorMessage: data.errorMsg ? data.errorMsg : '',
            negativePattern: data.negateMatchPattern === '1' ? true : false,
            reconnect:
                data.reconnect === '1'
                    ? 'enabled'
                    : data.reconnect === '0'
                    ? 'disabled'
                    : 'default',
            log:
                data.log === '1'
                    ? 'enabled'
                    : data.log === '0'
                    ? 'disabled'
                    : 'default',
            okMessage: data.OKMsg ? data.OKMsg : '',
        },
        rulesConfiguration: {
            ruleId: +data.ruleId,
            hostgroup: data.destinationHostgroup,
            proxyAddress: data.proxyAddr ? data.proxyAddr : '',
            proxyPort: data.proxyPort ? data.proxyPort : '',
            username: data.username ? data.username : '',
            clientAddress: data.clientAddr ? data.clientAddr : '',
            schemaName: data.schemaname ? data.schemaname : '',
        },
    };
}

export function prepareDeleteRuleData(data: any) {
    return {
        class_name: 'CmonProxySqlQueryRule',
        rule_id: +data.ruleId,
        active: data.active === '1' ? true : false,
        apply: data.apply === '1' ? true : false,
        cache_ttl: data.cacheTtl,
        client_addr: data.clientAddr,
        comment: data.comment,
        delay: data.delay,
        destination_hostgroup: +data.destinationHostgroup,
        digest: data.digest,
        flagIN: +data.flagIN,
        flagOUT: +data.flagOUT,
        log: data.log,
        match_digest: data.matchDigest,
        match_pattern: data.matchPattern,
        mirror_flagOUT: data.mirrorFlagOUT,
        mirror_hostgroup: data.mirrorHostgroup,
        negate_match_pattern: data.negateMatchPattern === '1' ? true : false,
        proxy_addr: data.proxyAddr,
        proxy_port: data.proxyPort,
        reconnect: data.reconnect,
        replace_pattern: data.replacePattern,
        retries: +data.retries,
        schemaname: data.schemaname,
        timeout: data.timeout,
        username: data.username,
    };
}
