import React from 'react';
import ActionsMenu, {
    ActionsMenuProps,
} from '../../common/Navigation/ActionsMenu';
import CcScheduleReport from '../../services/models/CcScheduleReport';
import OperationalReportDeleteButton from './OperationalReportDeleteButton';
import OperationalReportViewButton from './OperationalReportViewButton';

export default OperationalReportActionMenu;

export type OperationalReportActionMenuProps = ActionsMenuProps & {
    report: CcScheduleReport;
    onActionPerformed?: Function;
};

function OperationalReportActionMenu({
    report,
    onActionPerformed,
}: OperationalReportActionMenuProps) {
    return (
        <ActionsMenu
            items={[
                {
                    key: 'view',
                    label: <OperationalReportViewButton report={report} />,
                },
                {
                    key: 'delete',
                    label: (
                        <OperationalReportDeleteButton
                            report={report}
                            onSuccess={onActionPerformed}
                        />
                    ),
                },
            ]}
        />
    );
}
