import React from 'react';
import { Col, Form, Input, Row, Space } from 'antd';
import InfoIcon from '@severalnines/bar-frontend-components/build/lib/General/InfoIcon';
import PasswordInput from '../../../../common/DataEntry/PasswordInput';
import { FormInstance } from 'antd/lib/form';
import { CcClusterType } from '../../../../services/models/CcCluster';
import NodeDataDirectoryField from '../FormParts/NodeDataDirectoryField';
import VendorRepositoryField from '../../../../common/Form/Fields/VendorRepositoryField';
import NodeConfigurationTemplateField from '../../../../common/Form/Fields/NodeConfigurationTemplateField';
import MongoNodeConfigurationTemplateField from '../../../../common/Form/Fields/MongoNodeConfigurationTemplateField';
import SslEncryptionField from '../FormParts/SslEncryptionField';

export default MongoNodeConfiguration;

export type MongoNodeConfigurationProps = {
    form: FormInstance;
    hasDataDir?: boolean;
    hasRepository?: boolean;
    hasReplicaSetName?: boolean;
    hasTemplate?: boolean;
    hasMongoAuthDb?: boolean;
    hasRouterConfigurationTemplate?: boolean;
    hasSslEncryption?: boolean;
};

function MongoNodeConfiguration({
    form,
    hasDataDir = true,
    hasRepository = true,
    hasReplicaSetName = true,
    hasTemplate = true,
    hasMongoAuthDb = false,
    hasRouterConfigurationTemplate = false,
    hasSslEncryption = false,
}: MongoNodeConfigurationProps) {
    const { details } = form.getFieldsValue(true);

    return (
        <div className="MongoNodeConfiguration">
            <Row gutter={[24, 0]}>
                <Col span={24}>
                    <h3>Node configuration</h3>
                </Col>
                <Col xs={24} sm={24} md={12}>
                    <Form.Item
                        name={['nodeConfig', 'serverPort']}
                        label={
                            <Space>
                                Server port
                                <InfoIcon info="The MongoDB server port." />
                            </Space>
                        }
                        rules={[
                            {
                                required: true,
                                message:
                                    'Please enter the MongoDB service port.',
                            },
                        ]}
                    >
                        <Input placeholder="Enter server port" />
                    </Form.Item>
                </Col>
                {hasDataDir ? (
                    <Col xs={24} sm={24} md={12}>
                        <NodeDataDirectoryField />
                    </Col>
                ) : null}

                <Col xs={24} sm={24} md={12}>
                    <Form.Item
                        name={['nodeConfig', 'adminUser']}
                        label={
                            <Space>
                                User
                                <InfoIcon info="Admin user for MongoDB server" />
                            </Space>
                        }
                        rules={[
                            {
                                required: true,
                                message: 'Please enter MongoDB admin user',
                            },
                        ]}
                    >
                        <Input placeholder="Enter MongoDB admin user" />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12}>
                    <Form.Item
                        name={['nodeConfig', 'adminPassword']}
                        label={
                            <Space>
                                Password
                                <InfoIcon info="Password for MongoDB admin user." />
                            </Space>
                        }
                        rules={[
                            {
                                required: true,
                                message:
                                    'Please enter password for MongoDB admin user.',
                            },
                        ]}
                    >
                        <PasswordInput
                            autoComplete="new-password"
                            placeholder="Enter password for MongoDB admin user"
                        />
                    </Form.Item>
                </Col>
                {hasReplicaSetName ? (
                    <Col xs={24} sm={24} md={12}>
                        <Form.Item
                            name={['nodeConfig', 'replicaSetName']}
                            label="ReplicaSet name"
                            rules={[
                                {
                                    required: true,
                                    message:
                                        'Please enter MongoDB ReplicaSet name',
                                },
                            ]}
                        >
                            <Input placeholder="Enter ReplicaSet name" />
                        </Form.Item>
                    </Col>
                ) : null}
                {hasMongoAuthDb ? (
                    <Col xs={24} sm={24} md={12}>
                        <Form.Item
                            name={['nodeConfig', 'mongoAuthDb']}
                            label="MongoDB Auth DB"
                        >
                            <Input placeholder="Enter MongoDB authentication database" />
                        </Form.Item>
                    </Col>
                ) : null}
                {hasTemplate ? (
                    <Col xs={24} sm={24} md={12}>
                        <NodeConfigurationTemplateField
                            name={['nodeConfig', 'configurationTemplate']}
                            vendor={details.vendor}
                            version={details.version}
                            clusterType={CcClusterType.TYPE_MONGODB}
                        />
                    </Col>
                ) : null}
                {hasRouterConfigurationTemplate ? (
                    <Col xs={24} sm={24} md={12}>
                        <MongoNodeConfigurationTemplateField
                            name={['nodeConfig', 'routerConfigurationTemplate']}
                            vendor={details.vendor}
                            version={details.version}
                            clusterType={CcClusterType.TYPE_MONGODB}
                        />
                    </Col>
                ) : null}
                {hasRepository ? (
                    <Col xs={24} sm={24} md={12}>
                        <VendorRepositoryField
                            name={['nodeConfig', 'repository']}
                            form={form}
                        />
                    </Col>
                ) : null}
            </Row>
            {hasSslEncryption ? (
                <Row gutter={[24, 0]}>
                    <Col xs={24} sm={24} md={12}>
                        <SslEncryptionField />
                    </Col>
                </Row>
            ) : null}
        </div>
    );
}
