import React from 'react';
import { Col, Form, Input, Row, Space } from 'antd';
import IntegrationName from './IntegrationName';
import PasswordInput from '../../../../common/DataEntry/PasswordInput';
import InfoIcon from '@severalnines/bar-frontend-components/build/lib/General/InfoIcon';
import IntegrationComment from './IntegrationComment';
import { getCloudCredentialsFieldLabel } from '../CloudCredentialsAuthenticationForm';
import { CcCloudProviderType } from '../../../../services/models/CcCloudCredentials';
import FormItemInlineSwitch from '../../../../common/DataEntry/FormItemInlineSwitch';

export default CloudS3Authentication;

export type CloudS3AuthenticationProps = {};

function CloudS3Authentication({}: CloudS3AuthenticationProps) {
    return (
        <div className="CloudS3Authentication">
            <Row gutter={[24, 0]}>
                <Col span={12}>
                    <IntegrationName />
                </Col>
                <Col span={12}>
                    <Form.Item
                        name={[CcCloudProviderType.S3, 'endpoint']}
                        label={getCloudCredentialsFieldLabel(
                            CcCloudProviderType.S3,
                            'endpoint'
                        )}
                        rules={[
                            {
                                required: true,
                                validator: async (rule, value) => {
                                    if (!value) {
                                        throw new Error(
                                            'Please enter endpoint.'
                                        );
                                    }
                                    if (
                                        value.indexOf('http://') === 0 ||
                                        value.indexOf('https://') === 0
                                    ) {
                                        throw new Error(
                                            'Protocol is not allowed in endpoint url'
                                        );
                                    }
                                    return true;
                                },
                            },
                        ]}
                    >
                        <Input
                            addonBefore="http(s)://"
                            autoComplete="off"
                            placeholder="Name an endpoint address"
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        name={[CcCloudProviderType.S3, 'access_key_id']}
                        label={getCloudCredentialsFieldLabel(
                            CcCloudProviderType.S3,
                            'access_key_id'
                        )}
                        rules={[
                            {
                                required: true,
                                message: 'Please enter access key.',
                            },
                        ]}
                    >
                        <PasswordInput
                            autoComplete="new-password"
                            placeholder="Enter an access key"
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        name={[CcCloudProviderType.S3, 'access_key_secret']}
                        label={getCloudCredentialsFieldLabel(
                            CcCloudProviderType.S3,
                            'access_key_secret'
                        )}
                        rules={[
                            {
                                required: true,
                                message: 'Please enter secret key.',
                            },
                        ]}
                    >
                        <PasswordInput
                            autoComplete="new-password"
                            placeholder="Enter an secret key"
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        name={[CcCloudProviderType.S3, 'access_key_region']}
                        label={
                            <Space>
                                {getCloudCredentialsFieldLabel(
                                    CcCloudProviderType.S3,
                                    'access_key_region'
                                )}
                                <InfoIcon
                                    info={
                                        <span>
                                            Type the region name as it is
                                            provided by you cloud provider.
                                        </span>
                                    }
                                />
                            </Space>
                        }
                    >
                        <Input placeholder="Name your region name" />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <IntegrationComment />
                </Col>
                <Col span={12}>
                    <FormItemInlineSwitch
                        justify
                        noMargin
                        name={[CcCloudProviderType.S3, 'use_ssl']}
                        label={getCloudCredentialsFieldLabel(
                            CcCloudProviderType.S3,
                            'use_ssl'
                        )}
                        valuePropName="checked"
                    />
                </Col>
            </Row>
        </div>
    );
}

export function getCloudS3AuthenticationValidate() {
    return [
        [CcCloudProviderType.S3, 'endpoint'],
        [CcCloudProviderType.S3, 'access_key_id'],
        [CcCloudProviderType.S3, 'access_key_secret'],
    ];
}
