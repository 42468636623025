import React from 'react';
import { Col, Row, Form, Space, Input } from 'antd';

export const PROXYSQL_CREDENTIALS_VALIDATION = [
    ['proxySQLCredentials', 'administrationUser'],
    ['proxySQLCredentials', 'administrationPassword'],
];

export default ImportProxySQLCredentials;

function ImportProxySQLCredentials() {
    return (
        <div style={{ minHeight: 450 }}>
            <Row gutter={[24, 0]}>
                <Col span={24}>
                    <h3>Credentials</h3>
                </Col>
            </Row>
            <Row gutter={[24, 0]}>
                <Col span={12}>
                    <Form.Item
                        name={['proxySQLCredentials', 'administrationUser']}
                        label={<Space>Administration user</Space>}
                        rules={[
                            {
                                required: true,
                                message: 'Enter Administration User.',
                            },
                        ]}
                    >
                        <Input placeholder="Enter Administration User."></Input>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        name={['proxySQLCredentials', 'administrationPassword']}
                        label={<Space>Administration password</Space>}
                        rules={[
                            {
                                required: true,
                                message: 'Enter Administration Password.',
                            },
                        ]}
                    >
                        <Input.Password placeholder="Enter Administration Password." />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={[24, 0]}>
                <Col span={12}>
                    <Form.Item
                        name={['proxySQLCredentials', 'monitorUser']}
                        label={<Space>Monitor user</Space>}
                        rules={[
                            {
                                required: true,
                                message: 'Enter Monitor User.',
                            },
                        ]}
                    >
                        <Input placeholder="Enter Monitor User."></Input>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        name={['proxySQLCredentials', 'monitorPassword']}
                        label={<Space>Monitor password</Space>}
                        rules={[
                            {
                                required: true,
                                message: 'Enter Monitor Password.',
                            },
                        ]}
                    >
                        <Input.Password placeholder="Enter Monitor Password." />
                    </Form.Item>
                </Col>
            </Row>
        </div>
    );
}
