import React from 'react';
import './WrapFormat.less';
import { PopoverProps, Space, TooltipProps } from 'antd';
import { StatusFormatStatus } from '@severalnines/bar-frontend-components/build/lib/Format/StatusFormat';
import classNames from 'classnames';
import { SpaceProps } from 'antd/lib/space';
import AppPopover from '../Feedback/AppPopover';
import AppTooltip from '../Feedback/AppTooltip';

export default WrapFormat;

export type WrapFormatProps = {
    className?: string;
    children?: React.ReactNode;
    popoverProps?: PopoverProps;
    tooltipProps?: TooltipProps;
    popoverContent?: React.ReactNode;
    title?: React.ReactNode;
    popoverStatus?: StatusFormatStatus;
    contentProps?: SpaceProps;
    size?: 'default' | 'large' | 'xlarge';
    showPopover?: boolean;
};

function WrapFormat({
    className,
    children,
    popoverProps,
    tooltipProps,
    popoverContent,
    showPopover = true,
    title,
    popoverStatus,
    contentProps = {},
    size = 'default',
}: WrapFormatProps) {
    const {
        className: contentPropsClassname,
        style: contentPropsStyle,
        ...restContentProps
    } = contentProps;
    const content = (
        <Space
            className={classNames(
                'WrapFormat_content',
                `WrapFormat_content--size-${size}`,
                contentPropsClassname
            )}
            style={{ ...contentPropsStyle }}
            {...restContentProps}
        >
            {children}
        </Space>
    );
    const { title: popoverTitle, ...restPopoverProps } = popoverProps || {};
    return popoverContent && showPopover ? (
        <AppPopover
            content={popoverContent}
            overlayClassName={classNames(
                'WrapFormat_popover-overlay',
                `WrapFormat_popover-overlay--status-${popoverStatus}`
            )}
            mouseEnterDelay={0.4}
            {...{
                title:
                    popoverTitle && popoverStatus ? (
                        <div
                            className={classNames(
                                'WrapFormat_title',
                                `WrapFormat_title--status-${popoverStatus}`
                            )}
                        >
                            {popoverProps?.title}
                        </div>
                    ) : (
                        title
                    ),
                ...restPopoverProps,
            }}
        >
            {content}
        </AppPopover>
    ) : title ? (
        <AppTooltip title={title} {...tooltipProps}>
            {content}
        </AppTooltip>
    ) : (
        content
    );
}
