import CcSnapshotRepository from './CcSnapshotRepository';
import { SnapshotRepositoriesClustersData } from '../CcBackupService';
import CcBackupSchedule from './CcBackupSchedule';

export type CcSnapshotRepositoryCollectionProps = {
    data: SnapshotRepositoriesClustersData;
};
export class CcSnapshotRepositoryCollection {
    public readonly list: CcSnapshotRepository[];
    public readonly data: SnapshotRepositoriesClustersData;
    constructor(props: CcSnapshotRepositoryCollectionProps) {
        this.data = props.data;
        this.list = Object.entries(this.data).reduce(
            (a: CcSnapshotRepository[], [, list]) => {
                a.push(
                    ...Object.entries(list).reduce(
                        (a: CcSnapshotRepository[], [, repository]) => {
                            a.push(repository);
                            return a;
                        },
                        []
                    )
                );
                return a;
            },
            []
        );
    }

    get(name: string, clusterId = 0): CcSnapshotRepository | undefined {
        return this.data[clusterId]?.[name];
    }

    getBySchedule(schedule: CcBackupSchedule) {
        return this.get(
            schedule.spec?.job_data.snapshot_repository,
            schedule.clusterId
        );
    }
}
