import React, { useRef } from 'react';
import { Divider, Input } from 'antd';
import Form from 'antd/lib/form';
import AppFormItem from '../common/Form/AppFormItem';
import SpaceDescriptions from '../common/Layout/SpaceDescriptions';

export default DebugAppFormItem;
export type DebugAppFormItemProps = {};

function DebugAppFormItem({ ...rest }: DebugAppFormItemProps) {
    const refs = {
        updateCounter1: useRef(0),
        updateCounter2: useRef(0),
        updateCounter3: useRef(0),
        updateCounter4: useRef(0),
    };
    return (
        <div className="DebugAppFormItem">
            <Divider plain={true} orientation="left">
                <strong>updateOnFields and onUpdate</strong>
            </Divider>
            <Form layout="vertical">
                <AppFormItem
                    label="updateOnFields={['field1', 'field2']}"
                    updateOnFields={['field1', 'field2']}
                    onUpdate={() => ++refs.updateCounter1.current}
                >
                    {() => (
                        <SpaceDescriptions title="content">
                            <SpaceDescriptions.Item label="onUpdate">
                                {refs.updateCounter1.current}
                            </SpaceDescriptions.Item>
                            <SpaceDescriptions.Item label="contentUpdate">
                                {++refs.updateCounter2.current}
                            </SpaceDescriptions.Item>
                        </SpaceDescriptions>
                    )}
                </AppFormItem>
                <AppFormItem
                    label="updateOnFields={['field3']}"
                    updateOnFields={['field3']}
                    onUpdate={() => ++refs.updateCounter3.current}
                >
                    {() => (
                        <SpaceDescriptions title="content">
                            <SpaceDescriptions.Item label="onUpdate">
                                {refs.updateCounter3.current}
                            </SpaceDescriptions.Item>
                            <SpaceDescriptions.Item label="contentUpdate">
                                {++refs.updateCounter4.current}
                            </SpaceDescriptions.Item>
                        </SpaceDescriptions>
                    )}
                </AppFormItem>
                <Form.Item name="field1" label="field1">
                    <Input />
                </Form.Item>
                <Form.Item name="field2" label="field2">
                    <Input />
                </Form.Item>
                <Form.Item name="field3" label="field3">
                    <Input />
                </Form.Item>
                <Form.Item name="field4" label="field4">
                    <Input />
                </Form.Item>
            </Form>
        </div>
    );
}
