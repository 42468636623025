import React from 'react';
import ActionButton from '@severalnines/bar-frontend-components/build/lib/General/ActionButton';
import ButtonWithForm from '../../../common/General/ButtonWithForm';
import { ButtonProps } from 'antd/lib/button';
import CcUser from '../../../services/models/CcUser';
import UserEditModal from './UserEditModal';
import ButtonWithTooltip from '../../../common/Control/ButtonWithTooltip';
import { Space } from 'antd';
import TypographyText from '../../../common/TypographyText';
import { InfoCircleOutlined } from '@ant-design/icons';

export default UserEditButton;

export type UserEditButtonProps = ButtonProps & {
    user: CcUser;
    onSuccess?: () => void;
};

function UserEditButton({ user, onSuccess, ...rest }: UserEditButtonProps) {
    const isUserForbidden = user.isForbiddenUser();
    return isUserForbidden ? (
        <ButtonWithTooltip
            type="link"
            size="small"
            {...rest}
            disabled={true}
            tooltipProps={{
                title: <span>System users cannot be edited.</span>,
            }}
        >
            <Space>
                <TypographyText muted={true}>Edit</TypographyText>
                <TypographyText muted={true}>
                    <InfoCircleOutlined />
                </TypographyText>
            </Space>
        </ButtonWithTooltip>
    ) : (
        <ButtonWithForm
            button={<ActionButton {...rest}>Edit</ActionButton>}
            form={<UserEditModal user={user} onSuccess={onSuccess} />}
        />
    );
}
