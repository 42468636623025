import React from 'react';
import CcCluster, {
    CcClusterTechnology,
} from '../../../services/models/CcCluster';
import AppPageWrapper from '../../../common/Layout/AppPageWrapper';
import { useNavigate, useParams } from 'react-router-dom';
import ConfigManagement from '../../ConfigManage/ConfigManagement';
import DatabaseAccounts from '../../DatabaseManage/DatabaseAccounts';
import AdvisorList from '../Advisors/AdvisorList';
import ClusterScriptsEditor from '../Scripts/ClusterScriptsEditor';
import Tabs from '../../../common/DataDisplay/Tabs';

export default ClusterManagePage;
export type ClusterManagePageProps = {
    cluster: CcCluster;
};

function ClusterManagePage({ cluster, ...rest }: ClusterManagePageProps) {
    const navigate = useNavigate();
    const { managePage = 'db-users' } = useParams<{
        managePage: string;
    }>();

    const tall = ['configuration', 'scripts'].includes(managePage);

    return (
        <AppPageWrapper
            className="ClusterManagePage"
            transparentBackground={true}
            noPadding={true}
            tall={tall}
        >
            <Tabs
                onTabClick={(key) => {
                    navigate(`/clusters/${cluster.clusterId}/manage/${key}`);
                }}
                activeKey={managePage}
                tall={tall}
            >
                <Tabs.TabPane tab="Configuration" key="configuration">
                    <ConfigManagement cluster={cluster} />
                </Tabs.TabPane>
                {[
                    CcClusterTechnology.TECHNOLOGY_POSTGRESQL,
                    CcClusterTechnology.TECHNOLOGY_MYSQL,
                ].includes(cluster?.getTechnology()) && (
                    <Tabs.TabPane tab="DB Users" key="db-users">
                        <DatabaseAccounts cluster={cluster} />
                    </Tabs.TabPane>
                )}
                <Tabs.TabPane tab="Advisors" key="advisors">
                    <AdvisorList cluster={cluster} />
                </Tabs.TabPane>
                <Tabs.TabPane tab="Scripts" key="scripts">
                    <ClusterScriptsEditor cluster={cluster} />
                </Tabs.TabPane>
            </Tabs>
        </AppPageWrapper>
    );
}
