import {
    CmonHostDistribution,
    CmonHostDistributionProps,
} from '../cmon/models/CmonHost';

export type CcHostDistributionProps = CmonHostDistributionProps & {};
export default class CcHostDistribution extends CmonHostDistribution {
    constructor(props: CcHostDistributionProps) {
        super(props);
    }

    getFullName() {
        return `${this.name} ${this.release} [${this.codename}]`;
    }
}
