import React from 'react';
import CcCluster from '../../../services/models/CcCluster';
import PerformancePage from '../../Performance/PerformancePage';

export default ClusterPerformancePage;

export type ClusterPerformancePageProps = {
    cluster: CcCluster;
};

function ClusterPerformancePage({ cluster }: ClusterPerformancePageProps) {
    return (
        <PerformancePage className="ClusterPerformancePage" cluster={cluster} />
    );
}
