import React from 'react';
import { ButtonProps } from 'antd/lib/button';
import CcCluster from '../../services/models/CcCluster';
import { CmonJobInstanceCommand } from '../../services/cmon/models/CmonJobInstance';
import JobConfirmActionButton from '../Jobs/JobConfirmActionButton';

export default DisableAuditLogButton;

export type DisableAuditLogButtonProps = ButtonProps & {
    cluster: CcCluster;
    onSuccess?: () => void;
};

function DisableAuditLogButton({
    cluster,
    onSuccess,
    ...rest
}: DisableAuditLogButtonProps) {
    return (
        <JobConfirmActionButton
            clusterId={cluster.clusterId}
            command={
                CmonJobInstanceCommand.SETUP_AUDIT_LOGGING.toLowerCase() as CmonJobInstanceCommand
            }
            jobData={{
                action: 'disable',
                clusterId: `${cluster.clusterId}`,
            }}
            confirmTitle={'Are you sure you want to disable audit logging?'}
            title={'Disable Audit Log'}
            onSuccess={onSuccess}
            {...rest}
        />
    );
}
