import React from 'react';
import useMaintenanceWatcher from './useMaintenanceWatcher';
import CcNode from '../../services/models/CcNode';
import CcCluster from '../../services/models/CcCluster';
import { Space, Tag } from 'antd';
import BooleanFormat from '@severalnines/bar-frontend-components/build/lib/Format/BooleanFormat';
import DurationFormat from '@severalnines/bar-frontend-components/build/lib/Format/DurationFormat';
import { MaintenanceIcon } from '../../common/icons/icons';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { AppIconSize } from '../../common/icons/AppIcon';
import InfoIcon from '@severalnines/bar-frontend-components/build/lib/General/InfoIcon';
import SpaceWide from '../../common/SpaceWide';
import AppDateFormat from '../../common/AppDateFormat';

export default MaintenanceFormat;

export type MaintenanceFormatProps = {
    node?: CcNode;
    cluster?: CcCluster;
    size?: SizeType;
    asTag?: boolean;
    asText?: boolean;
    emptyText?: React.ReactNode;
    showTooltip?: boolean;
};

function MaintenanceFormat({
    node,
    cluster,
    size,
    asTag,
    asText,
    emptyText,
    showTooltip = true,
}: MaintenanceFormatProps) {
    const {
        active: activeMaintenance,
        next: nextMaintenance,
    } = useMaintenanceWatcher({
        hostname: node?.hostname,
        clusterId: cluster?.clusterId,
    });

    const text = !!activeMaintenance ? (
        <span>
            Under maintenance until{' '}
            <AppDateFormat>
                {activeMaintenance.deadline ?? undefined}
            </AppDateFormat>
            {activeMaintenance?.reason
                ? ` because: ${activeMaintenance?.reason}`
                : null}
        </span>
    ) : (
        <span>
            Maintenance for{' '}
            <DurationFormat>{nextMaintenance?.getDuration()}</DurationFormat> is
            scheduled to start{' '}
            {nextMaintenance?.isStartingSoon() ? (
                <AppDateFormat fromNow>
                    {nextMaintenance?.initiate ?? undefined}
                </AppDateFormat>
            ) : (
                <span>
                    {' '}
                    on{' '}
                    <AppDateFormat>
                        {nextMaintenance?.initiate ?? undefined}
                    </AppDateFormat>
                </span>
            )}
        </span>
    );
    const content = (
        <BooleanFormat
            booleanVar={!!activeMaintenance}
            content={<MaintenanceIcon size={size as AppIconSize} />}
            //@fixme update bar-frontend-components BooleanFormat typings
            {...(showTooltip ? { tooltip: text as any } : {})}
        />
    );
    return (
        <Space className="MaintenanceFormat">
            {activeMaintenance || nextMaintenance ? (
                asText ? (
                    <span>
                        {text}{' '}
                        <InfoIcon
                            info={
                                <SpaceWide direction="vertical">
                                    <span>
                                        Who:{' '}
                                        {
                                            (
                                                activeMaintenance ||
                                                nextMaintenance
                                            )?.username
                                        }
                                    </span>
                                    <span>
                                        Reason:{' '}
                                        {
                                            (
                                                activeMaintenance ||
                                                nextMaintenance
                                            )?.reason
                                        }
                                    </span>
                                </SpaceWide>
                            }
                        />
                    </span>
                ) : asTag ? (
                    <Tag
                        style={{
                            padding: 2,
                            lineHeight: '14px',
                            fontSize: '14px',
                            borderRadius: '50%',
                        }}
                    >
                        {content}
                    </Tag>
                ) : (
                    content
                )
            ) : (
                emptyText || null
            )}
        </Space>
    );
}
