import React, { Fragment, useState } from 'react';
import './CreateCertificateForm.less';
import { Col, Form, Row } from 'antd';
import FormFooter from '../../common/FormFooter';
import SpaceWide from '../../common/SpaceWide';
import FormItemInlineSwitch from '../../common/DataEntry/FormItemInlineSwitch';
import Alert from '@severalnines/bar-frontend-components/build/lib/Feedback/Alert';
import CcCluster from '../../services/models/CcCluster';
import InputNumberWide from '../../common/DataEntry/InputNumberWide';
import useCreateJob from '../Jobs/useCreateJob';
import { CmonJobInstanceCommand } from '../../services/cmon/models/CmonJobInstance';
import { notifyJobCreationError } from '../Notifications/uiNotification';

export default CreateCertificateForm;

export type CreateCertificateFormProps = {
    cluster?: CcCluster;
    galera?: boolean;
    onSuccess?: () => void;
    onCancel?: () => void;
};

export type CreateCertificateFormValues = {
    time: number;
    restart?: boolean;
};

function CreateCertificateForm({
    cluster,
    galera,
    onSuccess,
    onCancel,
}: CreateCertificateFormProps) {
    const [form] = Form.useForm<CreateCertificateFormValues>();
    const [isRestart, setRestart] = useState<boolean>();

    const { loading, send } = useCreateJob({
        clusterId: cluster?.clusterId,
        title: galera ? 'Encrypting Replication' : 'Setup SSL',
        command: galera
            ? (CmonJobInstanceCommand.ENCRYPT_REPLICATION.toLowerCase() as CmonJobInstanceCommand)
            : (CmonJobInstanceCommand.SETUP_SSL.toLowerCase() as CmonJobInstanceCommand),
        onSuccess,
    });

    const handleSubmit = async (values: CreateCertificateFormValues) => {
        if (galera) {
            try {
                await send({
                    action: 'enable',
                    certificateId: null,
                    clusterId: `${cluster?.clusterId}`,
                    expire_days: values?.time,
                });
            } catch (err: any) {
                notifyJobCreationError({
                    size: 'large',
                    content: 'Job failded.',
                });
            }
        } else {
            try {
                await send({
                    action: 'enable',
                    certificateId: null,
                    clusterId: `${cluster?.clusterId}`,
                    expire_days: values?.time,
                    rolling_restart: values?.restart,
                });
            } catch (err: any) {
                notifyJobCreationError({
                    size: 'large',
                    content: 'Job failded.',
                });
            }
        }
    };

    const handleRestartChange = (value: boolean) => {
        setRestart(value);
    };

    return (
        <Form
            className="CreateCertificateForm"
            form={form}
            layout="vertical"
            onFinish={handleSubmit}
            initialValues={{
                time: 365,
                restart: false,
            }}
        >
            <Row>
                <Col span={24}>
                    <Form.Item
                        name={'time'}
                        label={'Certificate expiration time (in days)'}
                        rules={[
                            {
                                required: true,
                                message: 'Enter Certificate expiration time.',
                            },
                        ]}
                    >
                        <InputNumberWide
                            min={0}
                            placeholder="Click here to enter expiration time."
                        ></InputNumberWide>
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <SpaceWide direction="vertical">
                        <Alert
                            showIcon
                            type="info"
                            message={
                                'Setup encrypted SSL client-server connections for the node(s). The same certificates will be used on all nodes (the existing ones might be overwritten).'
                            }
                        />
                    </SpaceWide>
                </Col>
                {!galera && (
                    <Fragment>
                        <Col span={24}>
                            <FormItemInlineSwitch
                                justify
                                onChange={handleRestartChange}
                                name="restart"
                                label={'Restart nodes'}
                                valuePropName="checked"
                                style={{ margin: '1rem 0' }}
                            />
                        </Col>
                        <Col span={24}>
                            <SpaceWide direction="vertical">
                                {!isRestart ? (
                                    <Alert
                                        showIcon
                                        type="info"
                                        message={
                                            'To setup SSL encryption, the nodes must be restarted. Switch ON "Restart Nodes" to perform a rolling start of the nodes.'
                                        }
                                    />
                                ) : (
                                    <Alert
                                        showIcon
                                        type="warning"
                                        message={
                                            'The nodes will be restarted with a rolling restart. Applications may be affected during this restart.'
                                        }
                                    />
                                )}
                            </SpaceWide>
                        </Col>
                    </Fragment>
                )}
            </Row>
            <FormFooter
                loading={loading}
                showSubmitButton={true}
                showCancelButton={true}
                submitButtonText="Create"
                onCancel={onCancel}
            />
        </Form>
    );
}
