


export interface CmonProxySqlUserProps  {
    active?: number;
    backend?: number;
    class_name?: string;
    default_hostgroup?: number;
    default_schema?: string;
    fast_forward?: number;
    frontend?: number;
    max_connections?: number;
    password?: string;
    schema_locked?: number;
    transaction_persistent?: number;
    use_ssl?: number;
    username?: string;

}



export default class CmonProxySqlUser {
    public active?: number;
    public backend?: number;
    public className?: string;
    public defaultHostgroup?: number;
    public defaultSchema?: string;
    public fastForward?: number;
    public frontend?: number;
    public maxConnections?: number;
    public password?: string;
    public schemaLocked?: number;
    public transactionPersistent?: number;
    public useSsl?: number;
    public username?: string;


    constructor(props: CmonProxySqlUserProps) {
        this.active = props.active;
        this.backend = props.backend;
        this.className = props.class_name;
        this.defaultHostgroup = props.default_hostgroup;
        this.defaultSchema = props.default_schema;
        this.fastForward = props.fast_forward;
        this.frontend = props.frontend;
        this.maxConnections = props.max_connections;
        this.password = props.password;
        this.schemaLocked = props.schema_locked;
        this.transactionPersistent = props.transaction_persistent;
        this.useSsl = props.use_ssl;
        this.username = props.username;

    }

}
