const dashboard = {
    title: 'PgBouncer Overview',
    meta: {
        slug: 'pgbouncer-overview',
        supportClusterTypes: ['postgresql_single'],
    },
    panels: [
        {
            title: 'Pool saturation',
            type: 'chart',
            gridPos: {
                h: 10,
                w: 12,
                x: 0,
                y: 0,
            },
            options: {
                decimals: 0,
                format: 'short',
                type: 'line',
                legend: {
                    alignAsTable: true,
                },
                yaxis: [
                    {
                        format: 'short',
                        max: null,
                        min: 0,
                    },
                ],
                stack: false,
            },
            targets: [
                {
                    //
                    expr:
                        '100*sum by (database) (pgbouncer_pools_server_active_connections{instance=~"$instance"}) / sum by (database) (pgbouncer_databases_pool_size{instance=~"$instance"})',
                    legendFormat: ' {{ database }} ',
                },
            ],
        },
        {
            title: 'PostgreSQL connections',
            type: 'chart',
            gridPos: {
                h: 10,
                w: 12,
                x: 0,
                y: 0,
            },
            options: {
                decimals: 0,
                format: 'short',
                type: 'line',
                legend: {
                    alignAsTable: true,
                },
                yaxis: [
                    {
                        format: 'short',
                        max: null,
                        min: 0,
                    },
                ],
                stack: false,
            },
            targets: [
                {
                    //
                    expr:
                        'sum(pg_stat_activity_count) BY (environment, instance)',
                    legendFormat: 'Active Connections',
                },
                {
                    //
                    expr:
                        '0.75* pg_settings_max_connections{instance=~"$instance"}',
                    legendFormat: '75% of Max Connections - Threshold',
                },
                {
                    //
                    expr: 'pg_settings_max_connections{instance=~"$instance"}',
                    legendFormat: '100% of Max Connections - Threshold',
                },
            ],
        },
        {
            title: 'Pool size',
            type: 'chart',
            gridPos: {
                h: 10,
                w: 12,
                x: 0,
                y: 0,
            },
            options: {
                decimals: 0,
                format: 'short',
                type: 'line',
                legend: {
                    alignAsTable: true,
                },
                yaxis: [
                    {
                        format: 'short',
                        max: null,
                        min: 0,
                    },
                ],
                stack: false,
            },
            targets: [
                {
                    //
                    expr:
                        'pgbouncer_databases_pool_size{instance=~"$instance"}',
                    legendFormat:
                        'Db: {{ database }} Poolname: {{name}} Port: {{port}} ',
                },
            ],
        },

        {
            title: 'Total query time',
            type: 'chart',
            gridPos: {
                h: 10,
                w: 12,
                x: 0,
                y: 0,
            },
            options: {
                decimals: 0,
                format: 'short',
                type: 'area',
                legend: {
                    alignAsTable: true,
                },
                yaxis: [
                    {
                        format: 'short',
                        max: null,
                        min: 0,
                    },
                ],
                stack: true,
            },
            targets: [
                {
                    //
                    expr:
                        'sum by (database) (irate(pgbouncer_stats_queries_duration_seconds_total{instance=~"$instance"}[1m]))',
                    legendFormat: '{{ database }}',
                },
            ],
        },
        {
            title: 'Server connections - active',
            type: 'chart',
            gridPos: {
                h: 10,
                w: 12,
                x: 0,
                y: 0,
            },
            options: {
                decimals: 0,
                format: 'short',
                type: 'area',
                legend: {
                    alignAsTable: true,
                },
                yaxis: [
                    {
                        format: 'short',
                        max: null,
                        min: 0,
                    },
                ],
                stack: false,
            },
            targets: [
                {
                    //
                    expr:
                        'sum by (database) (pgbouncer_pools_server_active_connections{instance=~"$instance"})',
                    legendFormat: '{{ database }}',
                },
                /*
                    {
                      //
                      "expr": "sum by (instance) (pgbouncer_pools_server_idle_connections{instance=~\"$instance\"})",
                      "legendFormat": "Idle"
                    },
                    {
                      //
                      "expr": "sum by (instance) (pgbouncer_pools_server_login_connections{instance=~\"$instance\"})",
                      "legendFormat": "Login"
                    },
                    {
                      //
                      "expr": "sum by (instance) (pgbouncer_pools_server_used_connections{instance=~\"$instance\"})",
                      "legendFormat": "Used"
                    },
                    {
                      //
                      "expr": "sum by (instance) (pgbouncer_pools_server_testing_connections{instance=~\"$instance\"})",
                      "legendFormat": "Used"
                    },
                    {
                      "expr": "sum by (instance) (pgbouncer_databases_pool_size{instance=~\"$instance\"})",
                      "legendFormat": "Pool Size",
                    }*/
            ],
        },
        {
            title: 'Server connections - used',
            type: 'chart',
            gridPos: {
                h: 10,
                w: 12,
                x: 0,
                y: 0,
            },
            options: {
                decimals: 0,
                format: 'short',
                type: 'area',
                legend: {
                    alignAsTable: true,
                },
                yaxis: [
                    {
                        format: 'short',
                        max: null,
                        min: 0,
                    },
                ],
                stack: false,
            },
            targets: [
                /*
                    {
                      //
                      "expr": "sum by (database) (pgbouncer_pools_server_active_connections{instance=~\"$instance\"})",
                      "legendFormat": "Active"
                    },
                    */
                {
                    //
                    expr:
                        'sum by (database) (pgbouncer_pools_server_used_connections{instance=~"$instance"})',
                    legendFormat: '{{ database }}',
                },
                /*
                    {
                      //
                      "expr": "sum by (instance) (pgbouncer_pools_server_login_connections{instance=~\"$instance\"})",
                      "legendFormat": "Login"
                    },
                    {
                      //
                      "expr": "sum by (instance) (pgbouncer_pools_server_used_connections{instance=~\"$instance\"})",
                      "legendFormat": "Used"
                    },
                    {
                      //
                      "expr": "sum by (instance) (pgbouncer_pools_server_testing_connections{instance=~\"$instance\"})",
                      "legendFormat": "Used"
                    },
                    {
                      "expr": "sum by (instance) (pgbouncer_databases_pool_size{instance=~\"$instance\"})",
                      "legendFormat": "Pool Size",
                    }*/
            ],
        },
        {
            title: 'Client connections',
            type: 'chart',
            gridPos: {
                h: 10,
                w: 12,
                x: 0,
                y: 0,
            },
            options: {
                decimals: 0,
                format: 'short',
                type: 'area',
                legend: {
                    alignAsTable: true,
                },
                yaxis: [
                    {
                        format: 'short',
                        max: null,
                        min: 0,
                    },
                ],
                stack: false,
            },
            targets: [
                {
                    //
                    expr:
                        'sum by (database) (pgbouncer_pools_client_active_connections{instance=~"$instance"})',
                    legendFormat: 'Active - {{ database }}',
                },
                {
                    //
                    expr:
                        'sum by (database) (pgbouncer_pools_client_waiting_connections{instance=~"$instance"})',
                    legendFormat: 'Waiting - {{ database }}',
                },
            ],
        },
        {
            title: 'Server connections linked to a client connection',
            type: 'chart',
            gridPos: {
                h: 10,
                w: 12,
                x: 0,
                y: 0,
            },
            options: {
                decimals: 0,
                format: 'short',
                type: 'line',
                legend: {
                    alignAsTable: true,
                },
                yaxis: [
                    {
                        format: 'short',
                        max: null,
                        min: 0,
                    },
                ],
                stack: false,
            },
            targets: [
                {
                    //
                    expr:
                        'sum by (database) (pgbouncer_pools_server_active_connections{instance=~"$instance"})',
                    legendFormat: 'Db: {{ database }}',
                },
            ],
        },

        {
            title:
                'Client connections linked to server connection and able to process queries, shown as connection',
            type: 'chart',
            gridPos: {
                h: 10,
                w: 12,
                x: 0,
                y: 0,
            },
            options: {
                decimals: 0,
                format: 'short',
                type: 'line',
                legend: {
                    alignAsTable: true,
                },
                yaxis: [
                    {
                        format: 'short',
                        max: null,
                        min: 0,
                    },
                ],
                stack: false,
            },
            targets: [
                {
                    //
                    expr:
                        'sum by (database) (pgbouncer_pools_client_active_connections{instance=~"$instance"})',
                    legendFormat: 'Db: {{ database }}',
                },
            ],
        },
        {
            title: 'Age of oldest unserved client connection',
            type: 'chart',
            gridPos: {
                h: 10,
                w: 12,
                x: 0,
                y: 0,
            },
            options: {
                decimals: 0,
                format: 'short',
                type: 'line',
                legend: {
                    alignAsTable: true,
                },
                yaxis: [
                    {
                        format: 'short',
                        max: null,
                        min: 0,
                    },
                ],
                stack: false,
            },
            targets: [
                {
                    //
                    expr:
                        'sum by (database) (pgbouncer_pools_client_maxwait_seconds{instance=~"$instance"})',
                    legendFormat: 'Db: {{ database }}',
                },
            ],
        },
        {
            title: 'Network traffic sent and received',
            type: 'chart',
            gridPos: {
                h: 10,
                w: 12,
                x: 0,
                y: 0,
            },
            options: {
                decimals: 1,
                format: 'short',
                type: 'area',
                legend: {
                    alignAsTable: true,
                },
                yaxis: [
                    {
                        format: 'size',
                        max: null,
                        min: null,
                    },
                ],
                stack: false,
            },
            targets: [
                {
                    //
                    expr:
                        'sum by (instance) (irate(pgbouncer_stats_sent_bytes_total{instance=~"$instance"}[5m]))',
                    legendFormat: 'Sent',
                },
                {
                    //
                    expr:
                        'sum by (instance) (irate(pgbouncer_stats_received_bytes_total{instance=~"$instance"}[5m]))',
                    legendFormat: 'Received',
                },
            ],
        },
    ],
    templating: {
        list: [
            {
                datasource: 'clustercontrol',
                multi: false,
                label: 'Host',
                name: 'instance',
                query: "host:$[nodes[nodetype='pgbouncer']]",
                optionValue: 'hostname',
                optionText: 'hostname',
                sort: 1,
                isInstancePicker: true,
            },
            {
                datasource: 'clustercontrol',
                multi: false,
                label: 'Node',
                name: 'monitors',
                query: "node:$[nodetype='pgbouncer' and hostname='$instance']",
                optionValue: '{{hostname}}:{{port}}',
                optionText: '{{hostname}}:{{port}}',
                sort: 2,
            },
        ],
    },
    schemaVersion: 1,
};
export default dashboard;
