import React from 'react';
import { Col, Form, Input, Row, Select, Space } from 'antd';
import IntegrationName from './IntegrationName';
import InfoIcon from '@severalnines/bar-frontend-components/build/lib/General/InfoIcon';
import IntegrationComment from './IntegrationComment';
import { getCloudCredentialsFieldLabel } from '../CloudCredentialsAuthenticationForm';
import { CcCloudProviderType } from '../../../../services/models/CcCloudCredentials';

const AWS_REGIONS = [
    {
        id: 'us-east-2',
        label: '[us-east-2] US East (Ohio)',
    },
    {
        id: 'us-east-1',
        label: '[us-east-1] US East (N. Virginia)',
    },
    {
        id: 'us-west-1',
        label: '[us-west-1] US West (N. California)',
    },
    {
        id: 'us-west-2',
        label: '[us-west-2] US West (Oregon)',
    },
    {
        id: 'ap-east-1',
        label: '[ap-east-1] Asia Pacific (Hong Kong)',
    },
    {
        id: 'ap-south-1',
        label: '[ap-south-1] Asia Pacific (Mumbai)',
    },
    {
        id: 'ap-northeast-3',
        label: '[ap-northeast-3] Asia Pacific (Osaka-Local)',
    },
    {
        id: 'ap-northeast-2',
        label: '[ap-northeast-2] Asia Pacific (Seoul)',
    },
    {
        id: 'ap-southeast-1',
        label: '[ap-southeast-1] Asia Pacific (Singapore)',
    },
    {
        id: 'ap-southeast-2',
        label: '[ap-southeast-2] Asia Pacific (Sydney)',
    },
    {
        id: 'ap-northeast-1',
        label: '[ap-northeast-1] Asia Pacific (Tokyo)',
    },
    {
        id: 'ca-central-1',
        label: '[ca-central-1] Canada (Central)',
    },
    {
        id: 'eu-central-1',
        label: '[eu-central-1] EU (Frankfurt)',
    },
    {
        id: 'eu-west-1',
        label: '[eu-west-1] EU (Ireland)',
    },
    {
        id: 'eu-west-2',
        label: '[eu-west-2] EU (London)',
    },
    {
        id: 'eu-west-3',
        label: '[eu-west-3] EU (Paris)',
    },
    {
        id: 'eu-north-1',
        label: '[eu-north-1] EU (Stockholm)',
    },
    {
        id: 'me-south-1',
        label: '[me-south-1] Middle East (Bahrain)',
    },
    {
        id: 'sa-east-1',
        label: '[sa-east-1] South America (São Paulo)',
    },
];

export default CloudAwsAuthentication;

export type CloudAwsAuthenticationProps = {};

function CloudAwsAuthentication({}: CloudAwsAuthenticationProps) {
    return (
        <div className="CloudAwsAuthentication">
            <Row gutter={[24, 0]}>
                <Col span={12}>
                    <IntegrationName />
                </Col>
                <Col span={12}>
                    <Form.Item
                        name={[CcCloudProviderType.AWS, 'access_key_id']}
                        label={
                            <Space>
                                {getCloudCredentialsFieldLabel(
                                    CcCloudProviderType.AWS,
                                    'access_key_id'
                                )}
                                <InfoIcon
                                    info={
                                        <span>
                                            Your AWS Access Key ID as described
                                            on this page. You can get this from
                                            the AWS IAM Management console.
                                        </span>
                                    }
                                />
                            </Space>
                        }
                        rules={[
                            {
                                required: true,
                                message: 'Please enter AWS key ID.',
                            },
                        ]}
                    >
                        <Input placeholder="Enter AWS key ID" />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        name={[CcCloudProviderType.AWS, 'access_key_secret']}
                        label={
                            <Space>
                                {getCloudCredentialsFieldLabel(
                                    CcCloudProviderType.AWS,
                                    'access_key_secret'
                                )}
                                <InfoIcon
                                    info={
                                        <span>
                                            Your AWS Secret Access Key as
                                            described on this page. You can get
                                            this from the AWS IAM Management
                                            console.
                                        </span>
                                    }
                                />
                            </Space>
                        }
                        rules={[
                            {
                                required: true,
                                message: 'Please enter AWS key secret.',
                            },
                        ]}
                    >
                        <Input placeholder="Enter AWS key secret" />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        name={[CcCloudProviderType.AWS, 'access_key_region']}
                        label={
                            <Space>
                                {getCloudCredentialsFieldLabel(
                                    CcCloudProviderType.AWS,
                                    'access_key_region'
                                )}
                                <InfoIcon
                                    info={
                                        <span>
                                            Choose the default AWS region for
                                            this credential.
                                        </span>
                                    }
                                />
                            </Space>
                        }
                        rules={[
                            {
                                required: true,
                                message: 'Please choose region.',
                            },
                        ]}
                    >
                        <Select
                            options={AWS_REGIONS.map(({ id, label }) => ({
                                label,
                                value: id,
                            }))}
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <IntegrationComment />
                </Col>
            </Row>
        </div>
    );
}

export function getCloudAwsAuthenticationValidate() {
    return [
        [CcCloudProviderType.AWS, 'access_key_id'],
        [CcCloudProviderType.AWS, 'access_key_secret'],
        [CcCloudProviderType.AWS, 'access_key_region'],
    ];
}
