/**
 * Applies a an array of filters to a source array
 * Returns array filtered
 */
export function arrayFilter({ filters, arr }: { filters?: any[]; arr: any[] }) {
    let nList = arr;
    if (filters && filters.length && arr) {
        let i = 0;
        while (i < filters.length) {
            nList = nList.filter(filters[i]);
            i++;
        }
    }
    return nList;
}

/**
 * Splits array in chunks/pages
 */
export function arrayPages({
    page,
    pageSize,
    order,
    arr,
}: {
    page?: number;
    pageSize?: number;
    order?: (a: any, b: any) => number;
    arr: any[];
}) {
    let nList = arr;
    //sorting
    if (order) {
        nList.sort(order);
    }
    //paginating
    if (page && pageSize) {
        nList = nList.slice((page - 1) * pageSize, page * pageSize);
    }
    return nList;
}

/**
 * Merges two collections and replacing object by key
 * f('a', [{a: 1}, {b:2}], [{a: 10}, {c:30}]) => [{a: 10},{b:2},{c:30}]
 */
export function collectionMergeByKey(
    key: string,
    collection1: any[],
    collection2: any[]
) {
    const collection2Keys = collection2.map((f) => f[key]);
    const collection1Filtered = collection1.filter(
        (f) => !collection2Keys.includes(f[key])
    );
    return [...collection1Filtered, ...collection2];
}

/**
 * Returns intersection of arrays
 */
export function arrayIntersection(array1: any[], array2: any[]) {
    return array1.filter((n) => array2.indexOf(n) !== -1);
}

/**
 * Returns difference of arrays (array1 - array2)
 */
export function arrayDifference(
    array1: any[],
    array2: any[],
    get?: (item: any) => any,
    getB?: (item: any) => any
) {
    return get
        ? array1.filter(
              (n) => !array2.map((i) => (getB || get)(i)).includes(get(n))
          )
        : array1.filter((n) => !array2.includes(n));
}

/**
 * Returns true if array1 is subset of array2
 */
export function arrayIsSubset(array1: any[], array2: any[]) {
    return array1.every((val) => array2.includes(val));
}

/**
 * Returns unique array
 */
export function arrayUnique(
    array: any[],
    predicate?: (itemA: any, itemB: any) => any
) {
    if (predicate) {
        return array.filter(
            (t: any, i: number) => array.findIndex((j) => predicate(j, t)) === i
        );
    } else {
        return array.filter((t: string, i: number) => array.indexOf(t) === i);
    }
}

/**
 * Returns unique collection by key
 */
export function collectionUniqueBy(collection: any[], key: string) {
    return collection.filter(
        (t: any, i: number) =>
            collection.findIndex((j) => j[key] === t[key]) === i
    );
}

//https://stackoverflow.com/questions/6237537/finding-matching-objects-in-an-array-of-objects
export function collectionFilterProps(
    collection: any[],
    props: { [key: string]: any }
) {
    return collection.filter((entry) =>
        matchCollectionEntryProps(entry, props)
    );
}

export function matchCollectionEntryProps(
    entry: any,
    props: { [key: string]: any }
) {
    return Object.keys(props).every((key) => entry[key] === props[key]);
}
