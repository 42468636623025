import React, { useMemo } from 'react';
import { TopologyItem } from '../../../components/Topology/TopologyItem';
import { PostgreSqlFormValues } from '../../../components/Services/Cluster/PostgreSql/PostgreSqlConfigurator';
import SpaceDescriptions, {
    SpaceDescriptionsItemProps,
} from '../../Layout/SpaceDescriptions';
import YesNoFormat from '../../Format/YesNoFormat';
import { CcClusterType } from '../../../services/models/CcCluster';
import { getNodeRoleText } from '../../../components/Nodes/NodeTypeFormat';
import { ElasticFormValues } from '../../../components/Services/Cluster/Elastic/ElasticConfigurator';
import { ClusterConfiguratorFormValues } from '../../../components/Services/Cluster/ClusterConfigurator';

export default TopologyItemSummary;
type TopologyItemNodeProps = {
    label?: string;
    nodeDataIp?: string;
    nodeRole?: string;
    nodePort?: string;
    useAsDataNode?: boolean;
    synchronousReplication?: boolean;
    topologyLogFile?: string;
    topologyServerPort?: number;
};
export type TopologyItemSummaryProps = SpaceDescriptionsItemProps & {
    item: TopologyItem;
    nodeProps?: any;
    clusterType?: CcClusterType;
};

function TopologyItemSummary({
    item,
    nodeProps,
    clusterType,
    ...rest
}: TopologyItemSummaryProps) {
    const props = useMemo(() => {
        const props = [];
        if (nodeProps.synchronousReplication !== undefined) {
            props.push(
                <SpaceDescriptions.Item
                    key="synchronousReplication"
                    align="end"
                    label="Synchronous"
                >
                    <YesNoFormat
                        booleanVar={nodeProps.synchronousReplication}
                    />
                </SpaceDescriptions.Item>
            );
        }
        if (nodeProps.useAsDataNode !== undefined) {
            props.push(
                <SpaceDescriptions.Item
                    key="useAsDataNode"
                    align="end"
                    label="As data node"
                >
                    <YesNoFormat booleanVar={nodeProps.useAsDataNode} />
                </SpaceDescriptions.Item>
            );
        }
        if (nodeProps.nodeRole !== undefined) {
            props.push(
                <SpaceDescriptions.Item
                    key="nodeRole"
                    align="end"
                    label="Node role"
                >
                    {getNodeRoleText(
                        nodeProps.nodeRole,
                        undefined,
                        clusterType
                    )}
                </SpaceDescriptions.Item>
            );
        }
        if (nodeProps.topologyLogFile !== undefined) {
            props.push(
                <SpaceDescriptions.Item
                    key="topologyLogFile"
                    align="end"
                    label="Logfile"
                >
                    {nodeProps.topologyLogFile}
                </SpaceDescriptions.Item>
            );
        }
        return props;
    }, [item, nodeProps]);
    return (
        <SpaceDescriptions.Item
            key={item.extraData.hostname}
            label={getTopologyItemTitle(item, nodeProps)}
            align="end"
            direction="vertical"
            labelStrong={false}
            labelMuted={false}
            colon={false}
            {...rest}
        >
            {props}
        </SpaceDescriptions.Item>
    );
}

function getTopologyItemTitle(item: TopologyItem, nodeProps?: any) {
    let port;
    if (nodeProps.topologyServerPort) {
        port = nodeProps.topologyServerPort;
    }
    if (!port && nodeProps.nodePort) {
        port = nodeProps.nodePort;
    }
    return `${item.extraData.hostname}${
        port ? `:${port}` : ''
    } ${getTopologyItemPropsString(nodeProps)}`;
}

function getTopologyItemPropsString(nodeProps?: TopologyItemNodeProps) {
    if (!nodeProps) {
        return '';
    }
    const props: string[] = [];
    if (nodeProps.label !== undefined) {
        props.push(nodeProps.label);
    }
    if (nodeProps.nodeDataIp !== undefined) {
        props.push(`data:${nodeProps.nodeDataIp}`);
    }
    return props.length > 0 ? `(${props.join(', ')})` : '';
}

export function extractFormTopologyItemNodeProps(
    item: TopologyItem,
    formFields: PostgreSqlFormValues &
        ElasticFormValues &
        ClusterConfiguratorFormValues
) {
    const props: TopologyItemNodeProps = {};

    if (formFields.topologyDataIps?.[item.extraData.hostname] !== undefined) {
        props.nodeDataIp = formFields.topologyDataIps[item.extraData.hostname];
    }

    if (formFields.useAsDataNode !== undefined) {
        props.useAsDataNode = formFields.useAsDataNode[item.extraData.hostname];
    }

    if (formFields.synchronousReplication !== undefined) {
        props.synchronousReplication =
            formFields.synchronousReplication[item.extraData.hostname] || false;
    }

    if (formFields.topologyServerPort !== undefined) {
        props.topologyServerPort =
            formFields.topologyServerPort[item.extraData.hostname] ||
            formFields.nodeConfig?.serverPort;
    }

    if (formFields.topologyLogFile !== undefined) {
        props.topologyLogFile =
            formFields.topologyLogFile[item.extraData.hostname] || 'empty';
    }

    return props;
}
