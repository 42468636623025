import './AppIcon.less';
import React, { useCallback } from 'react';
import classNames from 'classnames/index';
import Icon from '@ant-design/icons';

export default AppIcon;

export enum AppIconSize {
    auto = 'auto',
    xsmall = 'xsmall',
    small = 'small',
    medium = 'medium',
    large = 'large',
    xlarge = 'xlarge',
}

export type AppIconProps = {
    icon: React.ReactNode | string;
    className?: string;
    size?: AppIconSize | number;
    width?: string | number;
    height?: string | number;
    disable?: boolean;
};

function AppIcon({
    icon,
    className,
    width,
    height,
    size,
    disable,
}: AppIconProps) {
    const iconSize = size ? getSize(size) : null;

    const iconComponent = useCallback(() => {
        return (
            <span
                className={classNames('AppIcon-wrap', {
                    'AppIcon-wrap--autosize': size === AppIconSize.auto,
                })}
            >
                {typeof icon === 'string' ? (
                    <img
                        src={require(`./img-logo-${icon}.svg`)}
                        alt={icon}
                        width={width || iconSize || getSize(AppIconSize.small)}
                        height={
                            height || iconSize || getSize(AppIconSize.small)
                        }
                    />
                ) : (
                    React.cloneElement(icon as React.ReactElement, {
                        className: 'AppIcon_ant-icon',
                        style: {
                            width: width || iconSize,
                            height: height || iconSize,
                            fontSize: width || iconSize || 'inherit',
                        },
                    })
                )}
                {disable ? (
                    <svg
                        style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            fontSize: width || iconSize || 'inherit',
                        }}
                        width={width || iconSize || 'auto'}
                        height={height || iconSize || 'auto'}
                        viewBox={`0 0 33 32`}
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M31.7494 31L30.1583 32.5911C30.0298 32.7156 29.8289 32.7156 29.7043 32.5911L0.0933752 2.98014C0.0335565 2.91973 0 2.83815 0 2.75313C0 2.66811 0.0335565 2.58653 0.0933752 2.52612L1.68445 0.93505C1.74486 0.875231 1.82644 0.841675 1.91146 0.841675C1.99647 0.841675 2.07805 0.875231 2.13846 0.93505L31.7494 30.546C31.874 30.6705 31.874 30.8754 31.7494 31Z"
                            fill="currentColor"
                            fillOpacity="0.85"
                        />
                    </svg>
                ) : null}
            </span>
        );
    }, [disable, icon]);
    return (
        <Icon
            className={classNames(
                'AppIcon',
                {
                    'AppIcon--autosize': size === AppIconSize.auto,
                },
                className
            )}
            component={iconComponent}
        />
    );
}

function getSize(size: AppIconSize | number) {
    let resultSize = !Number.isNaN(Number(size)) ? size : null;
    if (!resultSize) {
        switch (size) {
            case AppIconSize.auto:
                resultSize = 0;
                break;
            case AppIconSize.xsmall:
                resultSize = 16;
                break;
            case AppIconSize.small:
                resultSize = 24;
                break;
            case AppIconSize.medium:
                resultSize = 32;
                break;
            case AppIconSize.large:
                resultSize = 64;
                break;
            case AppIconSize.xlarge:
            default:
                resultSize = 128;
                break;
        }
    }
    return resultSize;
}
