import React from 'react';
import CcCluster from '../services/models/CcCluster';
import SpaceDescriptions from '../common/Layout/SpaceDescriptions';

import NodeFormat from '../components/Nodes/NodeFormat';
import { baseClustersList } from './dataMocks/clusters';
import { Col, Row } from 'antd';

const clusters = baseClustersList.map((c) => new CcCluster(c as any));

const node = clusters[0].nodes[0];
export default DebugNodeFormat;

export type DebugNodeFormatProps = {};

function DebugNodeFormat({}: DebugNodeFormatProps) {
    return (
        <Row className="DebugNodeFormat" gutter={[24, 24]}>
            <Col span={12}>
                <SpaceDescriptions title="showId=true" direction={'vertical'}>
                    <NodeFormat key={node.hostname} node={node} />
                </SpaceDescriptions>
                <SpaceDescriptions
                    title="showStatus=true"
                    direction={'vertical'}
                >
                    <NodeFormat
                        key={node.hostname}
                        node={node}
                        showStatus={true}
                    />
                </SpaceDescriptions>
                <SpaceDescriptions
                    title="showStatus=true, showType=false"
                    direction={'vertical'}
                >
                    <NodeFormat
                        key={node.hostname}
                        node={node}
                        showStatus={true}
                        showType={false}
                    />
                </SpaceDescriptions>
                <SpaceDescriptions title="size=large" direction={'vertical'}>
                    <NodeFormat key={node.hostname} node={node} size="large" />
                </SpaceDescriptions>
                <SpaceDescriptions
                    title="size=large, showStatus: true"
                    direction={'vertical'}
                >
                    <NodeFormat
                        key={node.hostname}
                        node={node}
                        size="large"
                        showStatus={true}
                    />
                </SpaceDescriptions>
                <SpaceDescriptions
                    title="size=large, showStatus: true, showPopover: true"
                    direction={'vertical'}
                >
                    <NodeFormat
                        key={node.hostname}
                        node={node}
                        size="large"
                        showStatus={true}
                        showPopover={true}
                    />
                </SpaceDescriptions>
            </Col>
            <Col span={12}>
                <SpaceDescriptions title="default" direction={'vertical'}>
                    {clusters[0].nodes.map((n) => {
                        return <NodeFormat key={n.hostname} node={n} />;
                    })}
                </SpaceDescriptions>
            </Col>

            <Col span={12}>
                <SpaceDescriptions title="With popover" direction={'vertical'}>
                    {clusters[0].nodes.map((n) => {
                        return (
                            <NodeFormat
                                key={n.hostname}
                                node={n}
                                showPopover={true}
                            />
                        );
                    })}
                </SpaceDescriptions>
            </Col>
        </Row>
    );
}
