import { Button, Space } from 'antd';
import {
    ClockCircleOutlined,
    LeftOutlined,
    RightOutlined,
    ZoomInOutlined,
    ZoomOutOutlined,
} from '@ant-design/icons';
import WrapFormat from '../Format/WrapFormat';
import React, {
    forwardRef,
    useEffect,
    useImperativeHandle,
    useMemo,
    useState,
} from 'react';
import {
    timeDurationRound,
    timeRangeZoomIn,
    timeRangeZoomOut,
} from '../dateTime';
import TimeRangeForm, { TimeRangeFormProps } from './TimeRangeForm';
import DurationFormat from '@severalnines/bar-frontend-components/build/lib/Format/DurationFormat';
import TypographyText from '../TypographyText';
import moment from 'moment-timezone';

export default forwardRef(TimeRange);

export interface TimeRangeApi {
    refresh: () => void;
    getTimeRange: () => [number, number, number];
    setTimeRange: (timeRange: [number, number]) => void;
}

export type TimeRangeProps = {
    range?: number;
    dateFrom?: string;
    dateTo?: string;
    disabled?: boolean;
    quickRanges?: number[];
    onChange?: (from: number, to: number, quickRange: number) => void;
    onQuickRangeChange?: (range: number) => void;
    onReset?: () => void;
    setDefaults?: boolean;
    timeRangeFormProps?: Omit<
        TimeRangeFormProps,
        'dateFrom' | 'dateTo' | 'quickRange'
    >;
    minimalUnit?: 'day' | 'minute' | 'second';
    enableCustomTimerange?: boolean;
    timezone?: string;
};
const DATE_FORMAT = 'YYYY-MM-DD HH:mm';
const DEFAULT_TIME_INTERVALS = [
    60 * 15,
    60 * 30,
    60 * 45,
    3600,
    86400,
    86400 * 7,
    86400 * 30,
    86400 * 365,
];
function TimeRange(
    {
        range = DEFAULT_TIME_INTERVALS[0],
        dateFrom,
        dateTo,
        disabled,
        onChange,
        onQuickRangeChange,
        onReset,
        setDefaults = true,
        timeRangeFormProps,
        minimalUnit = 'second',
        enableCustomTimerange = true,
        quickRanges,
        timezone = 'UTC',
    }: TimeRangeProps,
    ref: any
) {
    useImperativeHandle(
        ref,
        (): TimeRangeApi => ({
            async refresh() {
                if (quickRange > 0) {
                    setNow(quickRange);
                }
            },
            getTimeRange() {
                if (quickRange > 0) {
                    // recalculates and returns start, end, and quickRange > 0
                    return [getNow() - quickRange, getNow(), quickRange];
                } else {
                    // returns custom start, end, and quickRange === 0
                    return [
                        timeRange?.[0] || 0,
                        timeRange?.[1] || 0,
                        quickRange,
                    ];
                }
            },
            setTimeRange(timeRange: [number, number]) {
                setQuickRange(0);
                setTimeRange(timeRange);
            },
        })
    );
    const [popoverVisible, setPopoverVisible] = useState(false);

    const defaultTimeRage = [
        dateFrom
            ? moment(dateFrom).startOf(minimalUnit).unix()
            : getNow() - range,
        dateTo ? moment(dateTo).endOf(minimalUnit).unix() : getNow(),
    ];
    const [loaded, setLoaded] = useState(false);
    const [quickRange, setQuickRange] = useState(setDefaults ? range : 0);
    const [timeRange, setTimeRange] = useState(
        setDefaults ? defaultTimeRage : undefined
    );

    const handleZoomOut = () => {
        if (timeRange) {
            const [from, to] = timeRangeZoomOut(timeRange[0], timeRange[1]);
            const currentTo = to || getNow();
            setTimeRange([from, currentTo]);
            setQuickRange(to > 0 ? 0 : currentTo - from);
        }
    };

    // @todo support zoom in without quickRange set
    const handleZoomIn = () => {
        if (timeRange) {
            const [from, to] = timeRangeZoomIn(timeRange[0], timeRange[1]);
            setTimeRange([from, to]);
            setQuickRange(to - from);
        }
    };

    const handleQuickRangeChange = (value: number) => {
        setQuickRange(value);
        setNow(value);
        hidePopover();
        onQuickRangeChange?.(value);
    };
    const handleTimeRangeChange = (from: moment.Moment, to: moment.Moment) => {
        const now = getNow();
        if (to.endOf(minimalUnit).unix() > now) {
            const currentRange = timeDurationRound(
                to.endOf(minimalUnit).unix() - from.startOf(minimalUnit).unix()
            );
            setQuickRange(currentRange);
            setNow(currentRange);
        } else {
            setQuickRange(0);
            setTimeRange([
                from.startOf(minimalUnit).unix(),
                to.endOf(minimalUnit).unix(),
            ]);
        }
        hidePopover();
    };

    const handleShiftLeft = () => {
        if (timeRange) {
            const diff = timeRange[1] - timeRange[0];
            setQuickRange(0);
            setTimeRange([timeRange[0] - diff, timeRange[0]]);
        }
    };

    const handleShiftRight = () => {
        if (timeRange) {
            const now = getNow();
            const diff = timeRange[1] - timeRange[0];
            let newTimeTo = timeRange[1] + diff;
            // round current time to 1 minute
            if (newTimeTo > now - 60) {
                newTimeTo = now;
                setQuickRange(diff);
            }
            setTimeRange([newTimeTo - diff, newTimeTo]);
        }
    };

    useEffect(() => {
        setLoaded(true);
    }, []);

    useEffect(() => {
        if (loaded && timeRange) {
            onChange?.(timeRange[0], timeRange[1], quickRange);
        }
    }, [timeRange?.[0] || 0, timeRange?.[1] || 0]);

    const hidePopover = () => {
        setPopoverVisible(false);
    };
    const handlePopoverVisibleChange = (visible: boolean) => {
        setPopoverVisible(visible);
    };
    const handleReset = () => {
        setTimeRange(undefined);
        hidePopover();
        onReset?.();
    };

    const formTimeRange = useMemo(() => timeRange || defaultTimeRage, [
        timeRange,
    ]);

    return (
        <Space className="TimeRange">
            <Button
                disabled={disabled || !timeRange}
                onClick={handleShiftLeft}
                icon={<LeftOutlined />}
                data-testid="time-range-shift-left"
            />
            <WrapFormat
                popoverProps={{
                    destroyTooltipOnHide: true,
                    overlayInnerStyle: {
                        minWidth: enableCustomTimerange ? '590px' : '315px',
                        padding: 20,
                    },
                    trigger: 'click',
                    visible: popoverVisible,
                    onVisibleChange: handlePopoverVisibleChange,
                }}
                popoverContent={
                    <TimeRangeForm
                        onQuickRangeChange={handleQuickRangeChange}
                        onTimeRangeChange={handleTimeRangeChange}
                        dateFrom={moment.unix(formTimeRange[0]).format()}
                        dateTo={moment.unix(formTimeRange[1]).format()}
                        quickRange={quickRange}
                        extraButtons={
                            setDefaults === false ? (
                                <Button onClick={handleReset}>Reset</Button>
                            ) : undefined
                        }
                        enableCustomTimerange={enableCustomTimerange}
                        quickRanges={quickRanges}
                        timezone={timezone}
                        {...timeRangeFormProps}
                    />
                }
                showPopover={true}
            >
                <Button
                    disabled={disabled}
                    data-testid="time-range-form-button"
                >
                    <Space>
                        <ClockCircleOutlined />
                        <span>
                            {!timeRange ? (
                                <span>Date time range</span>
                            ) : quickRange ? (
                                <span>
                                    last{' '}
                                    <TypographyText strong muted={disabled}>
                                        <DurationFormat
                                            precision={1}
                                            units={['d', 'h', 'm']}
                                        >
                                            {quickRange * 1000}
                                        </DurationFormat>
                                    </TypographyText>
                                </span>
                            ) : (
                                getDateFromToName()
                            )}
                        </span>
                    </Space>
                </Button>
            </WrapFormat>
            <Button
                onClick={handleShiftRight}
                disabled={quickRange > 0 || disabled || !timeRange}
                icon={<RightOutlined />}
                data-testid="time-range-shift-right"
            />
            <WrapFormat title="Zoom out time range">
                <Button
                    disabled={disabled || !timeRange}
                    onClick={handleZoomOut}
                    icon={<ZoomOutOutlined />}
                    data-testid="time-range-zoom-out"
                />
            </WrapFormat>
            <WrapFormat title="Zoom in time range">
                <Button
                    disabled={
                        disabled ||
                        !quickRange ||
                        quickRange <= DEFAULT_TIME_INTERVALS[0] ||
                        !timeRange
                    }
                    onClick={handleZoomIn}
                    icon={<ZoomInOutlined />}
                    data-testid="time-range-zoom-in"
                />
            </WrapFormat>
        </Space>
    );

    function setNow(quickRange: number) {
        setTimeRange([getNow() - quickRange, getNow()]);
    }

    function getDateFromToName() {
        return (
            <span>
                From{' '}
                <TypographyText strong>
                    {moment
                        .unix(formTimeRange[0])
                        .tz(timezone)
                        .format(DATE_FORMAT)}
                </TypographyText>{' '}
                To{' '}
                <TypographyText strong>
                    {moment
                        .unix(formTimeRange[1])
                        .tz(timezone)
                        .format(DATE_FORMAT)}
                </TypographyText>
            </span>
        );
    }

    function getNow() {
        return moment().endOf(minimalUnit).unix();
    }
}
