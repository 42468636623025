import React, { useState } from 'react';
import { ButtonProps } from 'antd/lib/button';
import ConfirmActionButton from '@severalnines/bar-frontend-components/build/lib/General/ConfirmActionButton';
import { useDebugContext } from '../../../common/Debug';
import {
    notifyError,
    notifyOperationSuccess,
    NotifyType,
} from '../../Notifications/uiNotification';
import { LdapConfigGroupMappingsItem } from './LdapTypes';
import useLdapSettings from './useLdapSettings';

export default LdapGroupMapDeleteButton;

export type LdapGroupMapDeleteButtonProps = ButtonProps & {
    item: LdapConfigGroupMappingsItem;
    onSuccess?: () => void;
    onError?: (error: any) => void;
};

function LdapGroupMapDeleteButton({
    item,
    children,
    onSuccess,
    onError,
    ...rest
}: LdapGroupMapDeleteButtonProps) {
    const { log } = useDebugContext();
    const [loading, setLoading] = useState(false);

    const { refresh: refreshSettings, deleteGroupMap } = useLdapSettings();
    const handleClick = async () => {
        try {
            setLoading(true);
            await refreshSettings();
            await deleteGroupMap(item);
            notifyOperationSuccess({
                type: NotifyType.TOAST,
                title: <span>LDAP group map deleted succesfully.</span>,
            });
            onSuccess?.();
        } catch (err) {
            log.error(err);
            notifyError({ content: err.message });
            onError?.(err);
        }
        setLoading(false);
    };

    return (
        <ConfirmActionButton
            confirmTitle={'Delete LDAP group map?'}
            onConfirm={handleClick}
            loading={loading}
            critical={true}
            {...rest}
        >
            {children || 'Delete'}
        </ConfirmActionButton>
    );
}
