import React, { useEffect, useState } from 'react';
import { CcLicenseFeatureType } from '../../services/models/CcLicense';
import { getClusterFeature } from '../../services/models/CcLicenseInfo';
import { CcClusterType } from '../../services/models/CcCluster';
import ButtonBarrier from './ButtonBarrier';
import { CmonJobInstanceCommand } from '../../services/cmon/models/CmonJobInstance';
import { getCmonLicenseJobFeature } from '../../services/cmon/CmonLicenseFeature';
import { ButtonProps } from 'antd/lib/button';

export default JobButtonBarrier;
export type JobButtonBarrierProps = {
    command: CmonJobInstanceCommand;
    children: React.ReactElement<ButtonProps>;
    clusterType?: CcClusterType;
};

function JobButtonBarrier({
    command,
    children,
    clusterType,
    ...rest
}: JobButtonBarrierProps) {
    const [licensedFeature, setLicensedFeature] = useState<
        CcLicenseFeatureType
    >();

    useEffect(() => {
        const commandFeature = getCmonLicenseJobFeature(command);
        if (commandFeature) {
            setLicensedFeature(getClusterFeature(commandFeature, clusterType));
        }
    }, [command, clusterType]);

    return (
        <ButtonBarrier actionButton={true} licenseFeature={licensedFeature}>
            {children}
        </ButtonBarrier>
    );
}
