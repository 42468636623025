import ActionsMenu from '../../../common/Navigation/ActionsMenu';
import React from 'react';
import CcCluster, {
    CcClusterBase,
    CcClusterType
} from '../../../services/models/CcCluster';
import { ActionMenuItem } from '@severalnines/bar-frontend-components/build/lib/Navigation/ActionMenu';
import { CcNodeType } from '../../../services/models/CcNode';
import { CmonJobInstanceCommand } from '../../../services/cmon/models/CmonJobInstance';
import BackupToolReconfigureActionButton from '../../Backups/BackupTool/ActionButtons/BackupToolReconfigureActionButton';
import BackupToolUninstallActionButton from '../../Backups/BackupTool/ActionButtons/BackupToolUninstallActionButton';
import BackupSettingsButton from '../Actions/BackupSettingsButton';
import BackupToolPBMSetupActionButton from '../../Backups/BackupTool/ActionButtons/BackupToolPBMSetupActionButton';
import BackupToolPbBackrestSetupActionButton from '../../Backups/BackupTool/ActionButtons/BackupToolPbBackrestSetupActionButton';
import ClusterBackupRestoreActionButton from '../../Backups/Wizard/BackupRestore/ClusterBackupRestoreActionButton';

export default ClusterBackupsMenu;
type ClusterBackupsMenuProps = {
    cluster: CcCluster;
};

function ClusterBackupsMenu({ cluster, ...rest }: ClusterBackupsMenuProps) {
    const restoreBackup = {
        key: 'restore-backup',
        label: <ClusterBackupRestoreActionButton cluster={cluster} />,
    };

    const clusterPBMAgentInstall =
        cluster.isType(CcClusterType.TYPE_MONGODB_SHARDS) &&
        cluster.getDatabaseNodes().length >
            cluster.getNodesByType(CcNodeType.PBM_AGENT).length
            ? {
                  key: 'cluster-pbm-install',
                  label: <BackupToolPBMSetupActionButton cluster={cluster} />,
              }
            : undefined;

    const clusterPBMAgentReinstall =
        cluster.isType(CcClusterType.TYPE_MONGODB_SHARDS) &&
        cluster.getNodesByType(CcNodeType.PBM_AGENT).length > 0
            ? {
                  key: 'cluster-pbm-reinstall',
                  label: (
                      <BackupToolPBMSetupActionButton
                          cluster={cluster}
                          reinstall={true}
                      />
                  ),
              }
            : undefined;

    const clusterPBMAgentReconfigure =
        cluster.isType(CcClusterType.TYPE_MONGODB_SHARDS) &&
        cluster.getNodesByType(CcNodeType.PBM_AGENT).length > 0
            ? {
                  key: 'cluster-pbm-reconfigure',
                  label: (
                      <BackupToolReconfigureActionButton
                          command={CmonJobInstanceCommand.PBMAGENT}
                          cluster={cluster}
                      />
                  ),
              }
            : undefined;

    const clusterPBMAgentUninstall =
        cluster.isType(CcClusterType.TYPE_MONGODB_SHARDS) &&
        cluster.getNodesByType(CcNodeType.PBM_AGENT).length > 0
            ? {
                  key: 'cluster-pbm-uninstall',
                  label: (
                      <BackupToolUninstallActionButton
                          toolType={CcNodeType.PBM_AGENT}
                          cluster={cluster}
                      />
                  ),
              }
            : undefined;

    const clusterPgBackRestInstall = cluster.isBase(
        CcClusterBase.BASE_POSTGRESQL
    )
        ? {
              key: 'cluster-pgbackrest-install',
              label: (
                  <BackupToolPbBackrestSetupActionButton cluster={cluster} />
              ),
          }
        : undefined;

    const clusterPgBackRestReconfigure =
        cluster.isBase(CcClusterBase.BASE_POSTGRESQL) &&
        cluster.getNodesByType(CcNodeType.PGBACKREST).length > 0
            ? {
                  key: 'cluster-pgbackrest-reconfigure',
                  label: (
                      <BackupToolReconfigureActionButton
                          command={CmonJobInstanceCommand.PGBACKREST}
                          cluster={cluster}
                      />
                  ),
              }
            : undefined;

    const clusterPgBackRestUninstall =
        cluster.isBase(CcClusterBase.BASE_POSTGRESQL) &&
        cluster.getNodesByType(CcNodeType.PGBACKREST).length > 0
            ? {
                  key: 'cluster-pgbackrest-uninstall',
                  label: (
                      <BackupToolUninstallActionButton
                          toolType={CcNodeType.PGBACKREST}
                          cluster={cluster}
                      />
                  ),
              }
            : undefined;

    const items: ActionMenuItem[] = [
        restoreBackup,

        clusterPBMAgentInstall,
        clusterPBMAgentReinstall,
        clusterPBMAgentReconfigure,
        clusterPBMAgentUninstall,

        clusterPgBackRestInstall,
        clusterPgBackRestReconfigure,
        clusterPgBackRestUninstall,
        {
            key: 'backup-settings',
            label: <BackupSettingsButton cluster={cluster} />,
        },
    ].filter(Boolean) as ActionMenuItem[];

    return <ActionsMenu items={items} {...rest} />;
}
