import React, { useMemo } from 'react';
import ButtonWithForm from '../../../common/General/ButtonWithForm';
import ActionButton from '@severalnines/bar-frontend-components/build/lib/General/ActionButton';
import CcNode from '../../../services/models/CcNode';
import CcCluster from '../../../services/models/CcCluster';
import { CcNodeType } from '../../../services/models/CcNode';
import { ButtonProps } from 'antd/es';
import KeepalivedDetailsModal from '../NodeDetails/KeepalivedPage/KeepalivedDetailsModal';
import MaxScaleDetailsModal from '../NodeDetails/MaxScalePage/MaxScaleDetailsModal';
import ProxySQLDetailsModal from '../NodeDetails/ProxySQLPage/ProxySQLDetailsModal';
import PgBouncerDetailsModal from '../NodeDetails/PgBouncerPage/PgBouncerDetailsModal';
import HAProxyDetailsModal from '../NodeDetails/HAProxyPage/HAProxyDetailsModal';

export default NodeDetailsButton;

export type NodeDetailsButtonProps = ButtonProps & {
    node: CcNode;
    cluster: CcCluster;
};

function NodeDetailsButton({
    node,
    cluster,
    children,
    ...rest
}: NodeDetailsButtonProps) {
    const detailsModal = useMemo(() => {
        switch (node.getType()) {
            case CcNodeType.HAPROXY:
                return <HAProxyDetailsModal node={node} cluster={cluster} />;
            case CcNodeType.KEEPALIVED:
                return <KeepalivedDetailsModal node={node} />;
            case CcNodeType.MAXSCALE:
                return <MaxScaleDetailsModal cluster={cluster} node={node} />;
            case CcNodeType.PROXYSQL:
                return <ProxySQLDetailsModal cluster={cluster} node={node} />;
            case CcNodeType.PGBOUNCER:
                return <PgBouncerDetailsModal node={node} cluster={cluster} />;
        }
    }, [node, cluster]);

    return (
        <ButtonWithForm
            button={
                <ActionButton {...rest}>
                    {children || 'Node details'}
                </ActionButton>
            }
            form={detailsModal}
        />
    );
}
