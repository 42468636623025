import React, { useMemo } from 'react';
import { Carousel, Space } from 'antd';
import './LoadingPage.less';
import {
    ActivityIcon,
    AlarmsIcon,
    BackupsIcon,
    ClustersIcon,
    JobsIcon,
    NodesIcon,
    VerifyBackupIcon,
} from './icons/icons';

type LoadingPageProps = {
    spinning?: boolean;
};

export default LoadingPage;

function LoadingPage({ spinning = true }: LoadingPageProps) {
    const items = [
        <ClustersIcon />,
        <BackupsIcon />,
        <NodesIcon />,
        <ActivityIcon />,
        <AlarmsIcon />,
        <JobsIcon />,
        <VerifyBackupIcon />,
    ];

    const elementRef = React.useRef<any>(
        <div className="LoadingPage">
            <div className="LoadingPage_loader">
                <Space direction="horizontal">
                    <Carousel
                        className="LoadingPage_carousel"
                        autoplay={spinning}
                        autoplaySpeed={600}
                        dots={false}
                        pauseOnHover={false}
                    >
                        {items.map((item, index) => {
                            return <div key={index}>{item}</div>;
                        })}
                    </Carousel>
                    <div className="LoadingPage_text">Loading...</div>
                </Space>
            </div>
        </div>
    );

    return elementRef.current;
}
