import useFetch from '../../common/useFetch';
import { useEffect, useState } from 'react';
import CcImperativeService from '../../services/CcImperativeService';

export type UseClusterDatabasesProps = {
    name?: string | null;
    host?: string;
    clusterId: number;
    useCache?: boolean;
};
export type UserClusterDatabaseSchema = {
    name: string;
    text: string;
    hostname?: string;
    port?: number;
};
const useClusterDatabases = ({
    name = 'use-cluster-databases',
    host = '',
    clusterId,
    useCache = false,
}: UseClusterDatabasesProps) => {
    const [schemas, setSchemas] = useState<UserClusterDatabaseSchema[]>([]);
    const {
        loading,
        data,
        refresh: refreshFetch,
    } = useFetch({
        name,
        useCache,
        fetchFn: async (params, opts) => {
            const {
                results: {
                    exitStatus: { schemas },
                },
            } = await CcImperativeService.executeScript(
                {
                    ...params,
                    filename: '/s9s/mysql/widgets/schema/show_dbs.js',
                    arguments: host,
                    cluster_id: clusterId,
                },
                opts
            );
            return schemas;
        },
        cancelFn: async ({ requestId }) => {
            await CcImperativeService.cancelRequest(requestId);
        },
    });

    useEffect(() => {
        if (data) {
            setSchemas(
                data?.map((item: any) => ({
                    ...item,
                    text: item.name,
                })) || []
            );
        }
    }, [data]);

    return {
        loading,
        schemas,
        refresh: refreshFetch,
    };
};

export default useClusterDatabases;
