import React, { useEffect } from 'react';
import './NodesHoneycomb.less';

import Honeycomb from '@severalnines/bar-frontend-components/build/lib/DataDisplay/Honeycomb';
import CcCluster from '../../services/models/CcCluster';
import { CcNodeStatus } from '../../services/models/CcNode';
import { getNodeStatusFormatType } from './NodeStatusFormat';
import { getNodeTypeFormatIcon } from './NodeTypeFormat';
import useNodeList from './useNodeList';
import NodeFormat from './NodeFormat';
import { getSortNumberFn } from '../../common/sorting';
import MaintenanceFormat from '../Maintenance/MaintenanceFormat';
import classNames from 'classnames';
import AppSpin from '../../common/General/AppSpin';
import AppLink from '../../common/AppLink';
import NodeRunningJobsFormat from './NodeRunningJobsFormat';

export default NodesHoneycomb;

export type NodesHoneycombProps = {
    filters?: ((c: CcCluster) => boolean)[];
    style?: any;
    className?: string;
    excludeGlobalFilters?: boolean;
};

function NodesHoneycomb({
    style,
    excludeGlobalFilters,
    ...rest
}: NodesHoneycombProps) {
    const { list: nodes, loading, refresh: refreshNodes } = useNodeList({
        name: 'node-list',
        pageSize: 0,
        order: getSortNumberFn('ascend', (a) => a.clusterid),
    });
    useEffect(() => {
        (async () => {
            await refreshNodes();
        })();
    }, []);

    return (
        <AppSpin spinning={loading}>
            <Honeycomb
                className="NodesHoneycomb"
                style={style}
                highlightable={true}
                hexagons={
                    nodes?.map((n) => ({
                        contentWrapper: (children) => {
                            return (
                                <AppLink
                                    to={`/clusters/${n.clusterid}/dashboard?slug=system-overview&vars=instance-${n.hostname}`}
                                >
                                    <div
                                        className={classNames(
                                            'NodesHoneycomb_Hexagon_content'
                                        )}
                                    >
                                        {children}
                                        <div className="NodesHoneycomb_Hexagon_content-extra">
                                            <MaintenanceFormat
                                                node={n}
                                                asTag={true}
                                            />
                                        </div>
                                    </div>
                                </AppLink>
                            );
                        },
                        content: (
                            <NodeFormat
                                node={n}
                                contentProps={{
                                    direction: 'vertical',
                                    size: 0,
                                    style: {
                                        flex: 1,
                                    },
                                }}
                                showPopover={true}
                            >
                                {getNodeTypeFormatIcon(n.nodetype, n.vendor, {
                                    width: '60%',
                                    height: '60%',
                                    size: 'auto',
                                })}
                                <div className="NodesHoneycomb_Hexagon_extra-center">
                                    <NodeRunningJobsFormat node={n} />
                                </div>
                            </NodeFormat>
                        ),
                        hoverContent:
                            n.hoststatus === CcNodeStatus.CmonHostOnline,
                        cell: {
                            hover: n.hoststatus === CcNodeStatus.CmonHostOnline,
                            show: n.hoststatus !== CcNodeStatus.CmonHostOnline,
                        },
                        type: getNodeStatusFormatType(n.hoststatus),
                        multiplier: 1,
                        highlightable: true,
                    })) || []
                }
                {...rest}
            />
        </AppSpin>
    );
}
