import React from 'react';
import {Col, Form, Input} from "antd";
import PasswordInput from "../../../common/DataEntry/PasswordInput";
import ClusterNodeSelect from "../../../common/DataEntry/ClusterNodeSelect";
import CcCluster from "../../../services/models/CcCluster";

export default DatabaseUserCommonFields;
export type DatabaseUserCommonFieldsProps = {
    cluster: CcCluster;
};

function DatabaseUserCommonFields({cluster, ...rest}: DatabaseUserCommonFieldsProps) {
    return <Col span={24}>
        <Form.Item
            name="username"
            label="Username"
            rules={[
                {
                    required: true,
                    message: 'Please enter a username',
                },
            ]}
        >
            <Input placeholder="Enter username" />
        </Form.Item>
        <Form.Item
            name="password"
            label="Password"
            rules={[
                {
                    required: true,
                    message: 'Please enter password',
                },
            ]}
        >
            <PasswordInput placeholder="Enter password" />
        </Form.Item>
        <Form.Item
            name="hostname"
            label="Hostname"
            rules={[
                {
                    required: true,
                    message:
                        'Please select or enter hostname',
                },
            ]}
        >
            <ClusterNodeSelect
                databaseNodes={true}
                cluster={cluster}
                autoComplete={true}
                extractNodeValue={(node) => node.hostname}
            />
        </Form.Item>
    </Col>
}
