import React, { useEffect } from 'react';
import { Select, SelectProps } from 'antd';
import useGroupList from '../../components/UserManagement/useGroupList';
import CcGroup from '../../services/models/CcGroup';

export default GroupSelect;

export type GroupSelectProps = SelectProps & {
    value?: string;
    onChange?: (value: CcGroup | CcGroup[] | undefined) => void;
};

function GroupSelect({
    value,
    onChange,
    loading,
    disabled,
    ...rest
}: GroupSelectProps) {
    const { list: groups, loading: loadingGroups, refresh } = useGroupList({
        pageSize: 0,
    });

    useEffect(() => {
        (async () => {
            await refresh();
        })();
    }, []);

    const handleOnChange = (value: string | string[]) => {
        onChange?.(
            Array.isArray(value)
                ? value.map(
                      (v) => groups?.find((g) => g.getKey() === v) as CcGroup
                  )
                : groups?.find((g) => g.getKey() === value)
        );
    };

    return (
        <Select
            className="GroupSelect"
            style={{ width: '100%' }}
            onChange={handleOnChange}
            value={
                Array.isArray(value)
                    ? value.map((v) => v.getKey())
                    : value?.getKey()
            }
            loading={loadingGroups || loading}
            disabled={loadingGroups || disabled}
            options={(groups || []).map((group) => ({
                value: group.getKey(),
                label: group.groupName,
            }))}
            optionFilterProp="label"
            {...rest}
        />
    );
}
