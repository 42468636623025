const mock = {
    class_name: 'CmonClusterInfo',
    cdt_path: '/',
    acl: 'user::rwx,group::rwx,other::---',
    tags: [],
    cluster_auto_recovery: true,
    cluster_id: 5,
    cluster_name: 'mongodb1',
    cluster_type: 'MONGODB',
    configuration_file: '/etc/cmon.d/cmon_5.cnf',
    effective_privileges: 'rwx',
    log_file: '/var/log/cmon_5.log',
    maintenance_mode_active: false,
    managed: true,
    node_auto_recovery: true,
    rpc_version: '2.0',
    state: 'FAILURE',
    status_text: 'Cluster failure.',
    vendor: 'redis',
    version: '',
    alarm_statistics: {
        class_name: 'CmonAlarmStatistics',
        cluster_id: 5,
        created_after: '1970-01-01T00:00:00.000Z',
        critical: 0,
        reported_after: '1970-01-01T00:00:00.000Z',
        warning: 1,
    },
    group_owner: {
        class_name: 'CmonGroup',
        cdt_path: '/groups',
        owner_user_id: 1,
        owner_user_name: 'system',
        owner_group_id: 1,
        owner_group_name: 'admins',
        acl: 'user::rwx,group::rwx,other::---',
        created: '2021-04-28T22:57:55.328Z',
        group_id: 1,
        group_name: 'admins',
    },
    hosts: [
        {
            cdt_path: '/cluster-3',
            class_name: 'CmonMongoHost',
            clusterid: 3,
            cmdline: '',
            configfile: '/etc/mongod.conf',
            connected: true,
            connections_available: 838857,
            connections_current: 3,
            container: true,
            datadir: '',
            dbpath: '',
            distribution: {
                codename: 'precise',
                name: 'ubuntu',
                release: '12.04',
                type: 'debian',
            },
            global_lock_queue: 0,
            global_lock_ratio: 0,
            hidden: false,
            hostId: 1,
            hostname: '10.0.3.10',
            hoststatus: 'CmonHostOnline',
            ip: '10.0.3.10',
            lastseen: 1516105724,
            logfile: '/opt/log/mongodb/mongod-rs1.log',
            logpath: '',
            maintenance_mode_active: false,
            member_role: 'Secondary',
            message: 'Up and Running',
            nodetype: 'mongo',
            pid: 13983,
            pidfile: '/var/run/mongodb/mongod-rs1.pid',
            pingstatus: 1,
            pingtime: 81,
            port: 27017,
            priority: 1,
            replication_lag: 0,
            role: 'shardsvr',
            roleid: 0,
            rs: 'my_rs',
            slaveDelay: 0,
            sshfailcount: 0,
            timestamp: 1516105724,
            unique_id: 1,
            uptime: 818977,
            version: '3.2.7',
            wallclock: 1516105690,
            wallclocktimestamp: 1516105690,
        },
        {
            cdt_path: '/cluster-3',
            class_name: 'CmonMongoHost',
            clusterid: 3,
            cmdline: '',
            configfile: '/etc/mongod.conf',
            connected: true,
            connections_available: 838857,
            connections_current: 3,
            container: true,
            datadir: '',
            dbpath: '',
            distribution: {
                codename: 'precise',
                name: 'ubuntu',
                release: '12.04',
                type: 'debian',
            },
            global_lock_queue: 0,
            global_lock_ratio: 0,
            hidden: false,
            hostId: 2,
            hostname: '10.0.3.20',
            hoststatus: 'CmonHostOnline',
            ip: '10.0.3.20',
            lastseen: 1516105724,
            logfile: '/opt/log/mongodb/mongod-rs1.log',
            logpath: '',
            maintenance_mode_active: false,
            member_role: 'Primary',
            message: 'Up and Running',
            nodetype: 'mongo',
            pid: 8335,
            pidfile: '/var/run/mongodb/mongod-rs1.pid',
            pingstatus: 1,
            pingtime: 66,
            port: 27017,
            priority: 1,
            replication_lag: 0,
            role: 'shardsvr',
            roleid: 0,
            rs: 'my_rs',
            slaveDelay: 0,
            sshfailcount: 0,
            timestamp: 1516105724,
            unique_id: 2,
            uptime: 818977,
            version: '3.2.7',
            wallclock: 1516105690,
            wallclocktimestamp: 1516105690,
        },
        {
            cdt_path: '/cluster-3',
            class_name: 'CmonMongoHost',
            clusterid: 3,
            cmdline: '',
            configfile: '/etc/mongod.conf',
            connected: true,
            connections_available: 838857,
            connections_current: 3,
            container: true,
            datadir: '',
            dbpath: '',
            distribution: {
                codename: 'precise',
                name: 'ubuntu',
                release: '12.04',
                type: 'debian',
            },
            global_lock_queue: 0,
            global_lock_ratio: 0,
            hidden: false,
            hostId: 3,
            hostname: '10.0.3.30',
            hoststatus: 'CmonHostOnline',
            ip: '10.0.3.30',
            lastseen: 1516105724,
            logfile: '/opt/log/mongodb/mongod-rs1.log',
            logpath: '',
            maintenance_mode_active: false,
            member_role: 'Secondary',
            message: 'Up and Running',
            nodetype: 'mongo',
            pid: 4976,
            pidfile: '/var/run/mongodb/mongod-rs1.pid',
            pingstatus: 1,
            pingtime: 87,
            port: 27017,
            priority: 1,
            replication_lag: 0,
            role: 'shardsvr',
            roleid: 0,
            rs: 'my_rs',
            slaveDelay: 0,
            sshfailcount: 0,
            timestamp: 1516105724,
            unique_id: 3,
            uptime: 818977,
            version: '3.2.7',
            wallclock: 1516105690,
            wallclocktimestamp: 1516105690,
        },
        {
            cdt_path: '/cluster-3',
            class_name: 'CmonHost',
            clusterid: 3,
            configfile: '/etc/cmon.d/cmon_3.cnf',
            connected: true,
            container: true,
            distribution: {
                codename: 'precise',
                name: 'ubuntu',
                release: '12.04',
                type: 'debian',
            },
            hostId: 25,
            hostname: '10.0.3.168',
            hoststatus: 'CmonHostOnline',
            ip: '10.0.3.168',
            lastseen: 1516105688,
            logfile: '/var/log/cmon_3.log',
            maintenance_mode_active: false,
            message: 'Up and running',
            nodetype: 'controller',
            pid: 19853,
            pingstatus: 1,
            pingtime: 55,
            port: 9500,
            role: 'controller',
            timestamp: 1516105690,
            unique_id: 23,
            uptime: 38533,
            version: '1.5.2.2321',
            wallclock: 1516105690,
            wallclocktimestamp: 1516105690,
        },
    ],
    job_statistics: {
        class_name: 'CmonJobStatistics',
        cluster_id: 5,
        by_state: {
            ABORTED: 0,
            DEFINED: 0,
            DEQUEUED: 0,
            FAILED: 0,
            FINISHED: 0,
            PAUSED: 2,
            RUNNING: 0,
        },
    },
    owner: {
        class_name: 'CmonUser',
        cdt_path: '/',
        acl: 'user::rwx,group::r--,other::r--',
        disabled: false,
        suspended: false,
        groups: [],
        timezone: {
            class_name: 'CmonTimeZone',
            name: 'Coordinated Universal Time',
            abbreviation: 'UTC',
            offset: 0,
            use_dst: false,
        },
    },
};
export default mock;
