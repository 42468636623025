import './LicensePage.less';
import React from 'react';
import LicenseInfo from './LicenseInfo';
import SpaceWide from '../../common/SpaceWide';
import { Space } from 'antd';
import LicenseStateList from './LicenseStateList';
import { getLicenseStatusStatus } from './LicenseStateFormat';
import { StatusFormatStatus } from '@severalnines/bar-frontend-components/build/lib/Format/StatusFormat';

export default LicensePage;
export type LicensePageProps = {};

function LicensePage({ ...rest }: LicensePageProps) {
    return (
        <SpaceWide className="LicensePage" direction="vertical" align="center">
            <Space className="LicensePage_LicenseState">
                <LicenseStateList
                    size="large"
                    stateFilter={(state) =>
                        getLicenseStatusStatus(state) !==
                        StatusFormatStatus.info
                    }
                />
            </Space>
            <LicenseInfo />
        </SpaceWide>
    );
}
