const result = {
    controller_id: 'adc0fa9f-6dcf-473d-9652-87ac9a59e2d0',
    request_processed: '2022-08-04T04:54:21.122Z',
    request_status: 'Ok',
    request_user_id: 5,
    debug_messages: ["RPC V2 authenticated user is 'alex'."],
    results: {
        fileName: '/s9s/mysql/widgets/schema/show_dbs.js',
        status: 'Ended',
        exitStatus: {
            schemas: [
                {
                    name: 'mysql',
                    hostname: '10.0.8.13',
                    port: 3306,
                },
            ],
        },
    },
};

export default result;
