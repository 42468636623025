import React, { useMemo, useCallback } from 'react';
import { Col, Row, Space, InputNumber } from 'antd';
import { Form } from 'antd';
import { FormInstance } from 'antd/lib/form';
import CcCluster from '../../../../../services/models/CcCluster';
import {
    getHostWithDesc,
    getNodeHostWithDesc,
} from '../../../../Nodes/NodeFormat';
import FormItemInlineSwitch from '../../../../../common/DataEntry/FormItemInlineSwitch';
import FormItemInline from '../../../../../common/FormItemInline';
import { CcNodeType } from '../../../../../services/models/CcNode';
import InfoIcon from '@severalnines/bar-frontend-components/build/lib/General/InfoIcon';
import { DefaultOptionType } from 'rc-select/lib/Select';
import SshCheckableNodesAutocomplete from '../../../../../common/DataEntry/SshCheckeableNodesAutocomplete';
import AppRadio from '../../../../../common/DataEntry/AppRadio';
import { getTopologyValidator } from '../../../loadBalancerHelpers';

type CreateProxySQLWhereProps = {
    selectedCluster?: CcCluster;
    isImportConfig?: boolean;
    form: FormInstance;
};

export const proxySQLWhereInitialValues = (selectedCluster?: CcCluster) => {
    return {
        proxySQLWhere: {
            version: '2',
            adminPort: 6032,
            listenPort: 6033,
            importConfig: false,
            firewall: true,
            appArmor: true,
            nativeClustering: false,
        },
    };
};

export default CreateProxySQLWhere;

function CreateProxySQLWhere({
    selectedCluster,
    isImportConfig,
    form,
}: CreateProxySQLWhereProps) {
    const getAddresses = useCallback(
        (type?: CcNodeType): DefaultOptionType[] | undefined => {
            if (type) {
                const arrayOfNodes = selectedCluster?.getNodesByType(type);
                return arrayOfNodes?.map((node) => {
                    return {
                        value: `${node.hostname}`,
                        label: getNodeHostWithDesc(node),
                    };
                });
            } else {
                return selectedCluster
                    ?.getHosts()
                    .filter((h) => !h.types.includes(CcNodeType.PROXYSQL))
                    .map((host) => {
                        return {
                            value: `${host.hostname}`,
                            label: getHostWithDesc(host),
                        };
                    });
            }
        },
        [selectedCluster]
    );

    // all options
    const optionsAddress = useMemo(() => getAddresses(), [selectedCluster]);

    // SQLInstance options
    const optionsSQLInstance = useMemo(
        () => getAddresses(CcNodeType.PROXYSQL),
        [selectedCluster]
    );

    return (
        <div style={{ minHeight: 450 }}>
            <Row>
                <Col span={24}>
                    <h3>Where to install</h3>
                </Col>
            </Row>
            <Row gutter={[24, 0]}>
                <Col span={24}>
                    <FormItemInline
                        name={['proxySQLWhere', 'version']}
                        label="Version :"
                    >
                        <AppRadio.Group>
                            <AppRadio.Button value="2">2.x</AppRadio.Button>
                        </AppRadio.Group>
                    </FormItemInline>
                </Col>
                <Col span={24}>
                    <Form.Item
                        name={['proxySQLWhere', 'address']}
                        label={<Space>Server Address</Space>}
                        rules={[
                            {
                                required: true,
                                message: 'Please select server address.',
                            },
                        ]}
                    >
                        <SshCheckableNodesAutocomplete
                            clusterId={selectedCluster?.clusterId}
                            emptyState={null}
                            singleNode={true}
                            formProps={{
                                primaryTitle: null,
                                primaryFormItemProps: {
                                    withLessMarginBottom: true,
                                },
                            }}
                            autocompleteOptions={optionsAddress}
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Row>
                        <Col span={24}>
                            <Form.Item
                                name={['proxySQLWhere', 'adminPort']}
                                label={<Space>Admin Port</Space>}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Enter admin port number.',
                                    },
                                ]}
                            >
                                <InputNumber
                                    min={0}
                                    style={{ width: '100%' }}
                                    placeholder="Click here to enter admin port."
                                ></InputNumber>
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                name={['proxySQLWhere', 'listenPort']}
                                label={<Space>Listening Port</Space>}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Enter port number.',
                                    },
                                ]}
                            >
                                <InputNumber
                                    min={0}
                                    style={{ width: '100%' }}
                                    placeholder="Click here to enter listening port."
                                ></InputNumber>
                            </Form.Item>
                        </Col>
                    </Row>
                </Col>
                {isImportConfig && (
                    <Col span={24}>
                        <Form.Item
                            name={['proxySQLWhere', 'proxySQLInstance']}
                            label={<Space>Source ProxySQL Instance</Space>}
                            rules={[
                                {
                                    required: true,
                                    message:
                                        'Please select server ProxySQL Instance.',
                                },
                            ]}
                        >
                            <SshCheckableNodesAutocomplete
                                clusterId={selectedCluster?.clusterId}
                                emptyState={null}
                                singleNode={true}
                                formProps={{
                                    primaryTitle: null,
                                    primaryFormItemProps: {
                                        withLessMarginBottom: true,
                                    },
                                }}
                                autocompleteOptions={optionsSQLInstance}
                            />
                        </Form.Item>
                    </Col>
                )}
            </Row>
            <Row gutter={[24, 0]}>
                <Col span={24}>
                    <h3>ProxySQL Configuration</h3>
                </Col>
                <Col span={12}>
                    <FormItemInlineSwitch
                        justify={true}
                        label="Firewall"
                        name={['proxySQLWhere', 'firewall']}
                        labelStrong={false}
                        valuePropName="checked"
                    />
                </Col>
                <Col span={12}>
                    <Form.Item noStyle={true} shouldUpdate={true}>
                        {() => (
                            <FormItemInlineSwitch
                                justify={true}
                                label={
                                    <Space>
                                        <span>Import Configuration</span>
                                        <InfoIcon
                                            info={
                                                'Import and apply the ProxySQL configuration from an existing instance or from a backup.'
                                            }
                                        />
                                    </Space>
                                }
                                name={['proxySQLWhere', 'importConfig']}
                                labelStrong={false}
                                valuePropName="checked"
                                disabled={form.getFieldValue([
                                    'proxySQLWhere',
                                    'nativeClustering',
                                ])}
                                onChange={(flag: boolean) => {
                                    if (flag) {
                                        // just in case, if disabling will fail
                                        form.setFieldsValue({
                                            proxySQLWhere: {
                                                nativeClustering: false,
                                            },
                                        });
                                    }
                                }}
                            />
                        )}
                    </Form.Item>
                </Col>

                <Col span={12}>
                    <FormItemInlineSwitch
                        justify={true}
                        label="AppArmor/SELinux"
                        name={['proxySQLWhere', 'appArmor']}
                        labelStrong={false}
                        valuePropName="checked"
                    />
                </Col>
                <Col span={12}>
                    <Form.Item noStyle={true} shouldUpdate={true}>
                        {() => (
                            <FormItemInlineSwitch
                                justify={true}
                                label={
                                    <Space>
                                        <span>Native Clustering</span>
                                        <InfoIcon
                                            info={
                                                'The ProxySQL Server will be created using native clustering and an entry will be created in the proxysql_server tab.'
                                            }
                                        />
                                    </Space>
                                }
                                name={['proxySQLWhere', 'nativeClustering']}
                                labelStrong={false}
                                disabled={form.getFieldValue([
                                    'proxySQLWhere',
                                    'importConfig',
                                ])}
                                onChange={(flag: boolean) => {
                                    if (flag) {
                                        // just in case, if disabling will fail
                                        form.setFieldsValue({
                                            proxySQLWhere: {
                                                importConfig: false,
                                            },
                                        });
                                    }
                                }}
                            />
                        )}
                    </Form.Item>
                </Col>
            </Row>
        </div>
    );
}

export function getCreateProxySQLWhereValidation(form: FormInstance) {
    return [
        // @todo make field naming in loadbalancers flat, so we can reuse names and types in different form components
        ['proxySQLWhere', 'address'],
        ['proxySQLWhere', 'adminPort'],
        ['proxySQLWhere', 'listenPort'],
        ['proxySQLWhere', 'proxySQLInstance'],
        getTopologyValidator(form, ['proxySQLWhere', 'address']),
    ];
}
