import React from 'react';
import ButtonWithForm from '../../common/General/ButtonWithForm';
import OperationalReportScheduleModal from './OperationalReportScheduleModal';
import ActionButton from '@severalnines/bar-frontend-components/build/lib/General/ActionButton';
import CcScheduleReport from '../../services/models/CcScheduleReport';
import CcReport from '../../services/models/CcReport';

export default OperationalReportCreateScheduleReportButton;

export type OperationalReportCreateScheduleReportButtonProps = {
    schedule?: CcScheduleReport;
    templates?: CcReport[];
    onSuccess?: () => void;
};

function OperationalReportCreateScheduleReportButton({
    schedule,
    templates,
    onSuccess,
}: OperationalReportCreateScheduleReportButtonProps) {
    return (
        <ButtonWithForm
            button={<ActionButton>{schedule ? 'Edit' : 'Create'}</ActionButton>}
            form={
                <OperationalReportScheduleModal
                    schedule={schedule}
                    templates={templates}
                    onSuccess={onSuccess}
                />
            }
        />
    );
}
