import React, { useMemo } from 'react';
import moment from 'moment';
import { Select, SelectProps, Space } from 'antd';

export default TimezoneSelect;
export type TimezoneSelectProps = SelectProps<string> & {};

function TimezoneSelect({ ...rest }: TimezoneSelectProps) {
    const timezones = useMemo(() => moment.tz.names(), []);
    return (
        <div className="TimezoneSelect">
            <Select
                showSearch
                placeholder="Choose timezone"
                {...rest}
                options={timezones.map((zone) => ({
                    value: zone,
                    label: (
                        <Space>
                            <span>{zone}</span>
                        </Space>
                    ),
                }))}
            />
        </div>
    );
}
