import React, { useEffect, useMemo, useState } from 'react';
import { Form } from 'antd';

import WizardSummary from './../WizardSummary';
import merge from 'deepmerge';
import {
    ClusterConfiguratorFormValues,
    ClusterConfigurator,
} from '../ClusterConfigurator';
import CcCluster, {
    CcClusterType,
} from '../../../../services/models/CcCluster';
import CmonJobsService from '../../../../services/cmon/CmonJobsService';
import { notifyError } from '../../../Notifications/uiNotification';
import WizardFormConfiguration from '@severalnines/bar-frontend-components/build/lib/Navigation/Wizard/WizardFormConfiguration';
import DeploymentSshConfiguration from './DeploymentSshConfiguration';
import { getSshCredentialsValidate } from '../FormParts/SshCredentials';
import RedisDeploymentConfigurator from './Redis/RedisDeploymentConfigurator';
import MysqlReplicationDeploymentConfigurator from './MysqlReplication/MysqlReplicationDeploymentConfigurator';
import DeploymentClusterDetails, {
    getDeploymentClusterDetailsValidate,
} from './DeploymentClusterDetails';
import MssqlDeploymentConfigurator from './Mssql/MssqlDeploymentConfigurator';
import MysqlGaleraDeploymentConfigurator from './MysqlGalera/MysqlGaleraDeploymentConfigurator';
import PostgreSqlDeploymentConfigurator from './PostgreSql/PostgreSqlDeploymentConfigurator';
import TimescaleDbDeploymentConfigurator from './TimescaleDb/TimescaleDbDeploymentConfigurator';
import MongoDeploymentConfigurator from './Mongo/MongoDeploymentConfigurator';
import ElasticDeploymentConfigurator from './Elastic/ElasticDeploymentConfigurator';
import { FormInstance } from 'antd/lib/form';
import { ServiceClusterWizardStep } from '../ServiceClusterWizardStep';
import SnapshotRepositoryForm from '../SnapshotRepositoryForm';
import MongoShardsDeploymentConfigurator from './MongoShards/MongoShardsDeploymentConfigurator';
import { getShardKeys } from './MongoShards/MongoShardsReplicaSetFormWrapper';
import { useDispatch } from 'react-redux';
import { addNewRunningJob } from '../../../../appReducer';
 import CcBackup from '../../../../services/models/CcBackup';

export default DeploymentWizard;

export type DeploymentWizardProps = {
    initialValues?: ClusterConfiguratorFormValues;
    defaultActiveStep?: ServiceClusterWizardStep;
    onSuccess?: (values?: any) => void;
    onError?: (err: Error) => void;
    onCancel?: () => void;
    onTouchedChange?: (touched: boolean) => void;
    onValuesChange?: (changedValues: any, values: any) => void;
    onStepErrorInsist?: (err: Error) => void;
    clusterType: CcClusterType;
    cluster?: CcCluster;
    primaryCluster?: CcCluster;
    fromBackup?: boolean;
    backup?: CcBackup;
    vendor?: string;
    version?: string;
    form?: FormInstance;
    cancelButtonText?: string;
};

let valuesCacheMap: { [key: string]: any } = {};

function DeploymentWizard({
    initialValues,
    defaultActiveStep,
    onSuccess,
    onError,
    onCancel,
    onTouchedChange,
    onValuesChange,
    onStepErrorInsist,
    clusterType,
    vendor,
    version,
    cluster,
    primaryCluster,
    fromBackup,
    backup,
    form: parentForm,
    cancelButtonText,
}: DeploymentWizardProps) {
    const [localForm] = Form.useForm();
    const form = parentForm || localForm;

    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const configurator = getDeploymentConfigurator(clusterType);
    const [currentValues, setCurrentValues] = useState<
        ClusterConfiguratorFormValues
    >(initialValues as ClusterConfiguratorFormValues);
    const [activeStep, setActiveStep] = useState<
        ServiceClusterWizardStep | string | undefined
    >(defaultActiveStep);

    useEffect(() => {
        return () => {
            valuesCacheMap = {};
        };
    }, []);

    useEffect(() => {
        form.resetFields();
        form.setFieldsValue(
            merge(
                valuesCacheMap[clusterType],
                merge(configurator.getDefaults(), initialValues || {})
            )
        );
    }, [clusterType]);

    useEffect(() => {
        if (primaryCluster) {
            // if primaryCluster is present, then we fix vendor and version
            // and do not allow the user to change it
            // this is used in create replica cluster action
            form.setFieldsValue({
                details: {
                    vendor: primaryCluster.vendor,
                    version: primaryCluster.version,
                },
            });
        } else {
            if (vendor) {
                form.setFieldsValue({
                    details: {
                        vendor,
                    },
                });
            }
            if (version) {
                form.setFieldsValue({
                    details: {
                        version,
                    },
                });
            }
        }
    }, [primaryCluster, vendor, version]);

    const handleSubmit = async () => {
        const formValues = form.getFieldsValue(true);
        try {
            setLoading(true);
            const {
                job,
            } = await CmonJobsService.createCreateClusterJobInstance(
                0,
                {
                    job_data: configurator.getJobData(formValues),
                },
                configurator.getJobOptions(formValues)
            );
            setLoading(false);
            if (onSuccess) {
                onSuccess();
            }
            dispatch(addNewRunningJob(job));
        } catch (err: any) {
            setLoading(false);
            if (onError) {
                onError(err);
            }
            notifyError({ size: 'large', content: err.message });
        }
    };
    const handleValuesChange = (values: any) => {
        valuesCacheMap[clusterType] = values;
        setCurrentValues(form.getFieldsValue(true));
        onValuesChange?.(values, form.getFieldsValue(true));
    };

    const handleCancel = () => {
        if (onCancel) {
            onCancel();
        }
    };

    const steps: React.ReactElement[] = useMemo(() => {
        return (
            form &&
            configurator &&
            (configurator
                .getDeploymentSteps(
                    form,
                    currentValues as ClusterConfiguratorFormValues
                )
                .map((step) => {
                    let stepKey;
                    let stepProps = {};
                    if (Array.isArray(step)) {
                        [stepKey, stepProps] = step;
                    } else {
                        stepKey = step;
                    }
                    return getDeploymentWizardStep(
                        stepKey,
                        configurator,
                        form,
                        clusterType,
                        cluster,
                        primaryCluster,
                        fromBackup,
                        backup,
                        stepProps
                    );
                })
                .filter((step) => !!step) as React.ReactElement[])
        );
    }, [
        configurator,
        form,
        clusterType,
        cluster,
        primaryCluster,
        fromBackup,
        backup,
        currentValues,
    ]);

    return (
        <div className="DeploymentWizard">
            <WizardFormConfiguration
                form={form}
                activeKey={activeStep}
                onValuesChange={handleValuesChange}
                onSubmit={handleSubmit}
                onCancel={handleCancel}
                cancelButtonText={cancelButtonText}
                initialValues={{
                    ...{
                        fromBackup: {
                            backup: backup,
                        },
                    },
                    ...merge(
                        merge(configurator.getDefaults(), {
                            nodeConfig: {
                                readonly: primaryCluster ? true : undefined,
                            },
                        }),
                        initialValues || {}
                    ),
                }}
                loading={loading}
                onStepErrorInsist={onStepErrorInsist}
                onTouchedChange={onTouchedChange}
                steps={steps}
                onActiveKeyChange={(key) => setActiveStep(key)}
                // using stepsExtra as a hack to set currentValues state with updated values
                stepsExtra={
                    clusterType === CcClusterType.TYPE_MONGODB_SHARDS
                        ? () => (
                              <Form.Item
                                  shouldUpdate={(previous, current) => {
                                      const previousShards =
                                          previous.shards &&
                                          getShardKeys(previous.shards);
                                      const currentShards =
                                          current.shards &&
                                          getShardKeys(current.shards);
                                      if (
                                          previousShards.length !== 0 &&
                                          previousShards.length !==
                                              currentShards.length
                                      ) {
                                          // setting the last shard
                                          setActiveStep(
                                              `${
                                                  currentShards[
                                                      currentShards.length - 1
                                                  ]
                                              }`
                                          );
                                      }
                                      return (
                                          previousShards.length !==
                                          currentShards.length
                                      );
                                  }}
                              >
                                  {() => {
                                      // hack to update form values
                                      setCurrentValues(
                                          form.getFieldsValue(true)
                                      );
                                      return null;
                                  }}
                              </Form.Item>
                          )
                        : undefined
                }
            />
        </div>
    );
}

function getDeploymentWizardStep(
    step: ServiceClusterWizardStep | string,
    configurator: typeof ClusterConfigurator,
    form: FormInstance,
    clusterType: CcClusterType,
    cluster?: CcCluster,
    primaryCluster?: CcCluster,
    fromBackup?: boolean,
    backup?: CcBackup,
    props?: any //@todo: fix components to expose type of WizardFormConfiguration.Step props
) {
    switch (step) {
        case ServiceClusterWizardStep.DETAILS:
            return (
                <WizardFormConfiguration.Step
                    key={ServiceClusterWizardStep.DETAILS}
                    title="Cluster details"
                    subTitle=" "
                    validate={getDeploymentClusterDetailsValidate()}
                    hasRequiredFields={true}
                >
                    <DeploymentClusterDetails
                        configurator={
                            configurator as typeof ClusterConfigurator
                        }
                        form={form}
                        cluster={cluster}
                        primaryCluster={primaryCluster}
                        fromBackup={fromBackup}
                        backup={backup}
                    />
                </WizardFormConfiguration.Step>
            );
        case ServiceClusterWizardStep.SSH_CONFIG:
            return (
                <WizardFormConfiguration.Step
                    key={ServiceClusterWizardStep.SSH_CONFIG}
                    title="SSH configuration"
                    subTitle=" "
                    validate={getSshCredentialsValidate()}
                    hasRequiredFields={true}
                >
                    <DeploymentSshConfiguration form={form} />
                </WizardFormConfiguration.Step>
            );
        case ServiceClusterWizardStep.NODE_CONFIG:
            return (
                <WizardFormConfiguration.Step
                    key={ServiceClusterWizardStep.NODE_CONFIG}
                    title={
                        <span style={{ whiteSpace: 'nowrap' }}>
                            Node configuration
                        </span>
                    }
                    subTitle=" "
                    validate={getDeploymentConfigurator(
                        clusterType
                    ).getNodeConfigurationValidate(form)}
                    hasRequiredFields={true}
                >
                    {getDeploymentConfigurator(
                        clusterType
                    ).getNodeConfigurationStep({
                        form,
                        hasPrimaryCluster: primaryCluster ? true : undefined,
                    } as any)}
                </WizardFormConfiguration.Step>
            );
        case ServiceClusterWizardStep.TOPOLOGY:
            return (
                <WizardFormConfiguration.Step
                    key={ServiceClusterWizardStep.TOPOLOGY}
                    title="Add nodes"
                    subTitle=" "
                    validate={() =>
                        getDeploymentConfigurator(
                            clusterType
                        ).getTopologyValidate(form)
                    }
                    hasRequiredFields={true}
                    {...props}
                >
                    {configurator.getTopologyStep(form)}
                </WizardFormConfiguration.Step>
            );
        case ServiceClusterWizardStep.SNAPSHOT_REPOSITORY:
            return (
                <WizardFormConfiguration.Step
                    key={ServiceClusterWizardStep.SNAPSHOT_REPOSITORY}
                    title="Snapshot storage configuration"
                    subTitle=" "
                    validate={[['repository', 'storageHost']]}
                    hasRequiredFields={true}
                >
                    <SnapshotRepositoryForm form={form} />
                </WizardFormConfiguration.Step>
            );
        case ServiceClusterWizardStep.PREVIEW:
            return (
                <WizardFormConfiguration.Step
                    key={ServiceClusterWizardStep.PREVIEW}
                    title="Preview"
                    subTitle=" "
                >
                    <Form.Item noStyle shouldUpdate={true}>
                        {() => (
                            <WizardSummary
                                configurator={configurator}
                                clusterType={clusterType}
                                form={form}
                            />
                        )}
                    </Form.Item>
                </WizardFormConfiguration.Step>
            );
        default:
            return (
                <WizardFormConfiguration.Step
                    key={step}
                    {...props}
                ></WizardFormConfiguration.Step>
            );
    }
}

export function getDeploymentConfigurator(
    clusterType?: CcClusterType
): typeof ClusterConfigurator {
    switch (clusterType) {
        case CcClusterType.TYPE_REPLICATION:
            return MysqlReplicationDeploymentConfigurator;
        case CcClusterType.TYPE_GALERA:
            return MysqlGaleraDeploymentConfigurator;
        case CcClusterType.TYPE_POSTGRESQL:
            return PostgreSqlDeploymentConfigurator;
        case CcClusterType.TYPE_TIMESCALEDB:
            return TimescaleDbDeploymentConfigurator;
        case CcClusterType.TYPE_MONGODB:
            return MongoDeploymentConfigurator;
        case CcClusterType.TYPE_MONGODB_SHARDS:
            return MongoShardsDeploymentConfigurator;
        case CcClusterType.TYPE_REDIS:
            return RedisDeploymentConfigurator;
        case CcClusterType.TYPE_MSSQL_SINGLE:
            return MssqlDeploymentConfigurator;
        case CcClusterType.TYPE_ELASTIC:
            return ElasticDeploymentConfigurator;
        default:
            return ClusterConfigurator;
    }
}
