import React, {
    forwardRef,
    useContext,
    useImperativeHandle,
    useMemo,
    useRef,
} from 'react';
import { Col, Row } from 'antd';
import { getDashboardPanelsForCluster } from '../Clusters/Pages/ClusterDashboardSelect';
import { MonitorDashboardConfig, MonitorDashboardPanelConfig } from './Monitor';
import MonitorBox, { MonitorBoxApi, MonitorType } from './MonitorBox';
import SpaceWide from '../../common/SpaceWide';
import CcCluster from '../../services/models/CcCluster';
import { ResponsiveContext } from '@severalnines/bar-frontend-components/build/lib/Layout/Responsive';
import { MonitorVariableItem } from './MonitorVariable';
import moment from 'moment';

export default forwardRef(MonitorDashboard);

export interface MonitorDashboardApi {
    reloadAll: () => void;
    updateAll: () => void;
    toggleTooltipSync: () => void;
}

export type MonitorDashboardProps = {
    dashboard: MonitorDashboardConfig;
    cluster: CcCluster;
    vars?: MonitorVariableItem[];
    startTs?: number;
    endTs?: number;
    onRangeSelect?: (from: number, to: number) => void;
};

function MonitorDashboard(
    {
        dashboard,
        cluster,
        vars = [],
        startTs = moment().unix() - 60 * 15,
        endTs = moment().unix(),
        onRangeSelect,
    }: MonitorDashboardProps,
    ref: any
) {
    const { responsive } = useContext(ResponsiveContext);
    const monitorRefs = useRef<MonitorBoxApi[]>([]);
    // ref is needed so the handler will get the right value
    const tooltipSyncEnabledRef = useRef<boolean>(false);

    useImperativeHandle(
        ref,
        (): MonitorDashboardApi => ({
            reloadAll() {
                setTimeout(() => {
                    monitorRefs.current.forEach((r) => r?.reload());
                });
            },
            updateAll() {
                monitorRefs.current.forEach((m) => {
                    if (m?.isLoaded()) {
                        setTimeout(() => {
                            m?.update();
                        });
                    }
                });
            },
            toggleTooltipSync() {
                tooltipSyncEnabledRef.current = !tooltipSyncEnabledRef.current;
            },
        })
    );

    const handleTooltipShow = ({ x, y }: { x: number; y: number }) => {
        if (tooltipSyncEnabledRef.current) {
            monitorRefs.current.forEach((m) => m?.showTooltip({ x, y }));
        }
    };

    const handleTooltipHide = () => {
        monitorRefs.current.forEach((m) => m?.hideTooltip());
    };

    const handleRangeSelect = (evt: CustomEvent) => {
        const { from, to } = evt.detail;

        if (to !== from) {
            onRangeSelect?.(from, to);
        }
    };

    const dashboardPanels = useMemo(() => {
        return (
            dashboard.templating.list.length === vars?.length &&
            getDashboardPanelsForCluster(dashboard, cluster).map(
                (panel: MonitorDashboardPanelConfig, idx: number) => {
                    return (
                        <Col
                            span={
                                responsive
                                    ? panel.type === MonitorType.SINGLESTAT
                                        ? 12
                                        : 24
                                    : panel.gridPos?.w || 12
                            }
                            key={`${panel.title}`}
                        >
                            <MonitorBox
                                ref={(element: MonitorBoxApi) =>
                                    (monitorRefs.current[idx] = element)
                                }
                                datasource={panel.datasource}
                                type={panel.type}
                                cluster={cluster}
                                title={panel.title}
                                startTs={startTs}
                                endTs={endTs}
                                options={panel.options}
                                targets={panel.targets}
                                vars={vars}
                                onTooltipShow={handleTooltipShow}
                                onTooltipHide={handleTooltipHide}
                                onRangeSelect={handleRangeSelect}
                            />
                        </Col>
                    );
                }
            )
        );
    }, [dashboard, cluster, vars, startTs, endTs, responsive]);

    return (
        <SpaceWide
            key="dashboards-wrap"
            size={25}
            direction="vertical"
            className="MonitorDashboard"
        >
            <Row gutter={[24, 24]}>
                <Col span={24}>
                    <Row gutter={[20, 20]}>{dashboardPanels}</Row>
                </Col>
            </Row>
        </SpaceWide>
    );
}
