import React from 'react';
import FormItemInline, { FormItemInlineProps } from '../../FormItemInline';
import AppRadio from '../../DataEntry/AppRadio';

export default ClusterVendorSelect;

export type ClusterVendorSelectProps = FormItemInlineProps & {
    vendors: { value: string; name: string }[];
    disabledVendors?: string[];
};

function ClusterVendorSelect({
    vendors,
    disabledVendors,
    ...rest
}: ClusterVendorSelectProps) {
    return (
        <FormItemInline
            name="clusterVendors"
            label="Vendor"
            rules={[
                {
                    required: true,
                    message: 'Please select a vendor.',
                },
            ]}
            {...rest}
        >
            <AppRadio.Group>
                {vendors.map((v) => (
                    <AppRadio.Button
                        key={v.value}
                        value={v.value}
                        disabled={disabledVendors?.includes(v.value)}
                    >
                        {v.name}
                    </AppRadio.Button>
                ))}
            </AppRadio.Group>
        </FormItemInline>
    );
}
