import React from 'react';
import ButtonWithForm from '../../common/General/ButtonWithForm';
import { PlusOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import UserGroupCreateForm, {
    UserGroupCreateFormProps,
} from './UserGroupCreateForm';
import { ButtonProps } from 'antd/lib/button';
import { WizardConfigurationModalOption } from '../../common/Navigation/WizardConfigurationModal';

export default UserGroupCreateButton;

export type UserGroupCreateButtonProps = ButtonProps & {
    formContainerProps?: UserGroupCreateFormProps;
    onSuccess?: (option: WizardConfigurationModalOption | undefined) => void;
};

function UserGroupCreateButton({
    formContainerProps,
    onSuccess,
    children,
    ...rest
}: UserGroupCreateButtonProps) {
    return (
        <ButtonWithForm
            button={
                <Button
                    className="UserGroupCreateButton"
                    type="primary"
                    icon={<PlusOutlined />}
                    {...rest}
                >
                    {children || 'Create user or team'}
                </Button>
            }
            form={
                <UserGroupCreateForm
                    onSuccess={onSuccess}
                    {...formContainerProps}
                />
            }
        ></ButtonWithForm>
    );
}
