import React from 'react';
import ActionButton from '@severalnines/bar-frontend-components/build/lib/General/ActionButton';
import ButtonWithForm from '../../common/General/ButtonWithForm';
import { ButtonProps } from 'antd/lib/button';
import CcUser from '../../services/models/CcUser';
import UserChangePasswordModal, {
    UserChangePasswordModalProps,
} from './UserChangePasswordModal';

export default UserChangePasswordButton;
export type UserChangePasswordButtonProps = ButtonProps & {
    user: CcUser;
    onSuccess?: () => void;
    formProps?: Omit<UserChangePasswordModalProps, 'user'>;
};

function UserChangePasswordButton({
    children,
    user,
    onSuccess,
    formProps,
    ...rest
}: UserChangePasswordButtonProps) {
    return (
        <ButtonWithForm
            button={
                <ActionButton {...rest}>
                    {children || 'Change password'}
                </ActionButton>
            }
            form={
                <UserChangePasswordModal
                    user={user}
                    onSuccess={onSuccess}
                    {...formProps}
                />
            }
        />
    );
}
