
import CcStats, { CcStatsProps } from '../../models/CcStats';


export interface CmonMemoryStatsProps  extends CcStatsProps {
    memoryutilization?: number;
    pgpgin?: number;
    pgpgout?: number;
    pswpin?: number;
    pswpout?: number;
    rambuffers?: number;
    ramcached?: number;
    ramfree?: number;
    ramfreemin?: number;
    ramtotal?: number;
    swapfree?: number;
    swaptotal?: number;
    swaputilization?: number;

}



export default class CmonMemoryStats extends CcStats {
    public memoryutilization?: number;
    public pgpgin?: number;
    public pgpgout?: number;
    public pswpin?: number;
    public pswpout?: number;
    public rambuffers?: number;
    public ramcached?: number;
    public ramfree?: number;
    public ramfreemin?: number;
    public ramtotal?: number;
    public swapfree?: number;
    public swaptotal?: number;
    public swaputilization?: number;


    constructor(props: CmonMemoryStatsProps) {
        super(props);
        this.memoryutilization = props.memoryutilization;
        this.pgpgin = props.pgpgin;
        this.pgpgout = props.pgpgout;
        this.pswpin = props.pswpin;
        this.pswpout = props.pswpout;
        this.rambuffers = props.rambuffers;
        this.ramcached = props.ramcached;
        this.ramfree = props.ramfree;
        this.ramfreemin = props.ramfreemin;
        this.ramtotal = props.ramtotal;
        this.swapfree = props.swapfree;
        this.swaptotal = props.swaptotal;
        this.swaputilization = props.swaputilization;

    }

}
