import { Tag } from 'antd';
import {
    EVENT_TYPES,
    EVENT_TRIGGERS,
    ALL_EVENT_TYPES,
} from '../DataEntry/EventSelect';

export default NotificationEventTag;

export type NotificationEventTagProps = {
    trigger: string;
    type: string;
    separate: boolean;
};

function NotificationEventTag({
    type,
    trigger,
    separate = false,
}: NotificationEventTagProps) {
    return (
        <>
            {separate ? EVENT_TYPES[type] || ALL_EVENT_TYPES[type] : ''}{' '}
            <Tag
                color={
                    EVENT_TRIGGERS[trigger] === EVENT_TRIGGERS.ALL
                        ? '#141414'
                        : EVENT_TRIGGERS[trigger] ===
                          EVENT_TRIGGERS.ALARM_WARNING
                        ? 'orange'
                        : 'red'
                }
            >
                {!separate
                    ? EVENT_TYPES[type] || ALL_EVENT_TYPES[type]
                    : EVENT_TRIGGERS[trigger]}
            </Tag>
        </>
    );
}
