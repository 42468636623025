import React, { useCallback, useContext, useRef, useState } from 'react';

export enum LogEntryLevel {
    DEBUG,
    INFO,
    WARN,
    ERROR,
}

export type DebugContextProps = {
    debug: boolean;
    setDebug: (debug: boolean) => void;
    log: {
        debug: (...args: any[]) => void;
        info: (...args: any[]) => void;
        warn: (...args: any[]) => void;
        error: (...args: any[]) => void;
    };
    getLogs: (print: boolean) => string[];
};

export const useDebugContext = (): DebugContextProps =>
    useContext(DebugContext) as DebugContextProps;

export const DebugContext = React.createContext({});

export interface LogEntry {
    level: LogEntryLevel;
    message: string;
    fullMessage: string;
    timestamp: number;
}

export type DebugProviderProps = {
    children?: React.ReactNode;
    initialDebug?: boolean;
    initialDebugLevel?: LogEntryLevel;
    retentionCount?: number;
};

export const DebugProvider = ({
    children,
    initialDebug = false,
    initialDebugLevel = LogEntryLevel.INFO,
    retentionCount = 500,
}: DebugProviderProps) => {
    const [debug, setDebug] = useState(initialDebug);
    const [debugLevel, setDebugLevel] = useState(initialDebugLevel);
    const logEntries = useRef<LogEntry[]>([]);

    const logEntry = useCallback(
        (level, ...args) => {
            const now = new Date();
            const pr = `[${now.toISOString()}] [${
                Object.values(LogEntryLevel)[
                    level !== undefined ? level : LogEntryLevel.INFO
                ]
            }]`;
            const message = Array.from(args).join(' ');
            const fullMessage = `${pr} ${message}`;
            logEntries.current.push({
                level,
                message,
                fullMessage,
                timestamp: Math.floor(now.getTime() / 1000),
            });
            if (logEntries.current.length > retentionCount) {
                // cleaning older logs
                logEntries.current.shift();
            }
            if (debug && level >= debugLevel) {
                console.log(pr, ...args);
            }
        },
        [debug]
    );
    const logDebug = useCallback(
        (...args) => {
            logEntry(LogEntryLevel.DEBUG, ...args);
        },
        [logEntry]
    );
    const logInfo = useCallback(
        (...args) => {
            logEntry(LogEntryLevel.INFO, ...args);
        },
        [logEntry]
    );
    const logWarn = useCallback(
        (...args) => {
            logEntry(LogEntryLevel.WARN, ...args);
        },
        [logEntry]
    );
    const logError = useCallback(
        (message, ...args) => {
            logEntry(
                LogEntryLevel.ERROR,
                module,
                message.message || message,
                ...args
            );
        },
        [logEntry]
    );
    const getLogs = useCallback((print) => {
        if (print) {
            return logEntries.current
                .map(
                    ({ timestamp, level, message }) =>
                        `[${new Date(timestamp * 1000).toISOString()}] ${
                            Object.values(LogEntryLevel)[level]
                        }: ${message}`
                )
                .join('\n');
        } else {
            return logEntries.current;
        }
    }, []);

    const debugContext = {
        debug,
        setDebug,
        debugLevel,
        setDebugLevel,
        log: {
            debug: logDebug,
            info: logInfo,
            warn: logWarn,
            error: logError,
        },
        getLogs,
    };
    return (
        <DebugContext.Provider value={debugContext}>
            {children}
        </DebugContext.Provider>
    );
};
