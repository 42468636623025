import useCreateJob from '../../Jobs/useCreateJob';
import { CmonJobInstanceCommand } from '../../../services/cmon/models/CmonJobInstance';
import CcCluster from '../../../services/models/CcCluster';
import { CcNodeRole } from '../../../services/models/CcNode';
import { notifyError } from '../../Notifications/uiNotification';
import { FormInstance } from 'antd/lib/form';

export default useBackRestSetup;
type UseBackRestSetupProps = {
    cluster: CcCluster;
    form: FormInstance;
    onSuccess?: () => void;
    reinstall?: boolean;
};

function useBackRestSetup({
    cluster,
    form,
    onSuccess,
    reinstall = false,
}: UseBackRestSetupProps) {
    const { loading, send } = useCreateJob({
        clusterId: cluster?.clusterId,
        title: `${reinstall ? 'Reinstall' : 'Install'} PgBackRest Backup`,
        command: `${CmonJobInstanceCommand.PGBACKREST.toLowerCase()}` as CmonJobInstanceCommand,
    });

    const setup = async () => {
        try {
            if (cluster) {
                await form.validateFields(['pg_backrest_repository_host']);
                const repositoryHost = form.getFieldValue(
                    'pg_backrest_repository_host'
                );

                const method = form.getFieldValue('pg_backrest_config_method');
                const hostName =
                    method === 'primary'
                        ? cluster?.getNodesByRoles([
                              CcNodeRole.PRIMARY,
                              CcNodeRole.MASTER,
                          ])?.[0]?.hostname
                        : '*';
                if (!hostName) {
                    const message = 'Could not find primary node hostname';
                    notifyError({ content: message });
                    throw new Error(message);
                }
                await send({
                    action: reinstall ? 'reinstall' : 'setup',
                    nodes: [
                        {
                            class_name: 'CmonPgBackRestHost',
                            hostname: hostName,
                        },
                        ...(method === 'all_host'
                            ? [
                                  {
                                      class_name: 'CmonPgBackRestHost',
                                      hostname: repositoryHost,
                                  },
                              ]
                            : []),
                    ],
                    clusterId: cluster?.clusterId,
                });
                onSuccess?.();
            }
        } catch (e: any) {
            console.error(`Install backup tool error: ${e.message}`, e);
        }
    };

    const reconfigure = async () => {
        try {
            if (cluster) {
                await send({
                    action: 'reconfigure',
                    nodes: [
                        {
                            class_name: 'CmonPgBackRestHost',
                            hostname: '*',
                        },
                    ],
                    clusterId: cluster?.clusterId,
                });
                onSuccess?.();
            }
        } catch (e: any) {
            console.error(`Reconfigure backup tool error: ${e.message}`, e);
        }
    };

    return {
        loading,
        setup,
        reconfigure,
    };
}
