import React from 'react';
import { ACLManageContent } from './useCurrentUser';
import CcCluster from '../../services/models/CcCluster';
import { CcTreeItemHandledAclPath } from '../../services/models/CcTreeItem';

export default UserAclManageUsers;

export type UserAclManageUsersProps = {
    cluster?: CcCluster;
    children?: React.ReactNode;
};

function UserAclManageUsers({ cluster, ...rest }: UserAclManageUsersProps) {
    return (
        <ACLManageContent
            path={CcTreeItemHandledAclPath.ACL_USER_MANAGER}
            {...rest}
        />
    );
}
