import useRunningJobList from './components/Jobs/useRunningJobList';
import useCurrentAlarmsList from './components/Alarms/useCurrentAlarmsList';
import useScheduledMaintenancesList from './components/Maintenance/useScheduledMaintenancesList';
import useLogStats from './components/Logs/useLogStats';
import useTree from './components/UserManagement/useTree';
import useJobWatcher from './components/Jobs/useJobWatcher';
import useClusterList from './components/Clusters/useClusterList';
import { useEffect } from 'react';
import { CmonJobInstanceCommand } from './services/cmon/models/CmonJobInstance';
import { getNodeRelatedJobs } from './services/models/CcJob';

export default BackgroundPolling;

export type BackgroundPollingProps = {};

/**
 * This component is in charge of making background requests
 */
function BackgroundPolling({}: BackgroundPollingProps) {
    const { refresh: refreshClusters } = useClusterList({
        name: 'cluster-list-from-background-polling',
    });
    const { refresh: refreshRunningJobs } = useRunningJobList({
        name: 'running-jobs-list-from-background-polling',
    });
    const { refresh: refreshCurrentAlarms } = useCurrentAlarmsList({
        name: 'current-alarms-list-from-background-polling',
    });
    const {
        refresh: refreshScheduledMaintenances,
    } = useScheduledMaintenancesList({
        name: 'scheduled-maintenances-list-from-background-polling',
    });
    const { refresh: refreshLogStats } = useLogStats({
        name: 'log-stats-from-background-polling',
    });
    const { refresh: refreshTree } = useTree({
        name: 'tree-from-background-polling',
    });
    const { stoppedJobs } = useJobWatcher({
        command: [
            CmonJobInstanceCommand.REMOVE_CLUSTER,
            CmonJobInstanceCommand.CREATE_CLUSTER,
            CmonJobInstanceCommand.ADD_CLUSTER,
            ...getNodeRelatedJobs(),
        ],
    });

    useEffect(() => {
        (async () => {
            await refreshRunningJobs({
                autoRefresh: 10000,
            });
        })();
        (async () => {
            await refreshCurrentAlarms({
                autoRefresh: 5000,
            });
        })();
        (async () => {
            await refreshScheduledMaintenances({
                autoRefresh: 60000,
            });
        })();
        (async () => {
            await refreshLogStats({
                autoRefresh: 60000,
            });
        })();
        (async () => {
            await refreshTree({
                autoRefresh: 30000,
            });
        })();
    }, []);

    useEffect(() => {
        if (
            stoppedJobs?.find((job) =>
                [
                    CmonJobInstanceCommand.CREATE_CLUSTER,
                    CmonJobInstanceCommand.REMOVE_CLUSTER,
                    CmonJobInstanceCommand.ADD_CLUSTER,

                    ...getNodeRelatedJobs(),
                ].includes(job.getCommand())
            )
        ) {
            (async () => {
                await refreshClusters();
            })();
        }
    }, [stoppedJobs]);
    return null;
}
