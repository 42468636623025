import React from 'react';
import CoverView, { CoverViewProps } from './CoverView';
import {
    ClusterConfiguratorClusterType,
    ClusterConfiguratorVendor,
    ClusterConfiguratorVendorVersion,
} from '../../components/Services/Cluster/ClusterConfigurator';
import { FileOutlined } from '@ant-design/icons';
import SpaceDescriptions from '../Layout/SpaceDescriptions';
import SpaceWide from '../SpaceWide';
import TypographyText from '../TypographyText';

export default ClusterTypeCoverView;

export type ClusterTypeCoverViewProps = CoverViewProps & {
    clusterTypeConfig?: ClusterConfiguratorClusterType;
    vendor?: ClusterConfiguratorVendor;
    version?: ClusterConfiguratorVendorVersion;
};

function ClusterTypeCoverView({
    clusterTypeConfig,
    vendor,
    version,
    ...rest
}: ClusterTypeCoverViewProps) {
    return (
        <CoverView
            title={
                <span>
                    {clusterTypeConfig?.name}{' '}
                    {clusterTypeConfig?.disabled ? (
                        <TypographyText type="warning">
                            <small>Coming soon...</small>
                        </TypographyText>
                    ) : null}
                </span>
            }
            icon={vendor?.icon || clusterTypeConfig?.icon}
            isEmpty={!clusterTypeConfig}
            description={
                clusterTypeConfig ? (
                    <SpaceWide size={20} direction="vertical">
                        <SpaceDescriptions direction="horizontal" size={20}>
                            <SpaceDescriptions.Item
                                direction="vertical"
                                label="Database"
                            >
                                {clusterTypeConfig.name}
                            </SpaceDescriptions.Item>
                            {vendor ? (
                                <SpaceDescriptions.Item
                                    direction="vertical"
                                    label="Vendor"
                                >
                                    {vendor?.name}
                                </SpaceDescriptions.Item>
                            ) : null}

                            {version ? (
                                <SpaceDescriptions.Item
                                    direction="vertical"
                                    label="Version"
                                >
                                    {version?.name}
                                </SpaceDescriptions.Item>
                            ) : null}
                        </SpaceDescriptions>
                        <SpaceDescriptions direction="horizontal" size={20}>
                            <SpaceDescriptions.Item
                                direction="vertical"
                                label="Description"
                            >
                                {clusterTypeConfig.description}
                            </SpaceDescriptions.Item>
                        </SpaceDescriptions>
                        <a
                            href={clusterTypeConfig.documentationLink}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <FileOutlined /> Learn more
                        </a>
                    </SpaceWide>
                ) : null
            }
            {...rest}
        />
    );
}
