import { useState } from 'react';

export default useAutoIncrement;

export interface UseAutoIncrementProps {}

function useAutoIncrement(): { getNextAutoIncrement: () => number } {
    const [counter, setCounter] = useState<number>(0);
    const getNextAutoIncrement = () => {
        const newCounter = counter + 1;
        setCounter(newCounter);
        return newCounter;
    };
    return {
        getNextAutoIncrement,
    };
}
