const dashboard = {
    title: 'PostgreSQL Overview',
    meta: {
        slug: 'postgresql-overview',
        supportClusterTypes: ['postgresql_single', 'postgresql'],
    },
    panels: [
        {
            title: 'Cache hit',
            type: 'chart',
            gridPos: {
                h: 7,
                w: 16,
                x: 0,
                y: 0,
            },
            options: {
                type: 'line',
                format: 'short',
                decimals: 0,
                legend: {
                    alignAsTable: true,
                },
            },
            targets: [
                {
                    expr:
                        'sum(irate(pg_stat_database_blks_read{datname!~"template0|template1", monitors="$monitors"}[5m]))',
                    legendFormat: 'blocks-read',
                },
                {
                    expr:
                        'sum(irate(pg_stat_database_blks_hit{datname!~"template0|template1", monitors="$monitors"}[5m]))',
                    legendFormat: 'blocks-hit',
                },
                {
                    expr:
                        'irate(pg_stat_activity_count{datname!~"template0|template1", monitors="$monitors", state="active"}[5m])',
                    legendFormat: 'connections',
                },
                {
                    expr:
                        'sum(irate(pg_stat_database_xact_commit{datname!~"template0|template1", monitors="$monitors"}[5m]))',
                    legendFormat: 'commits',
                },
            ],
        },
        {
            title: 'Cache hit ratio',
            type: 'chart',
            gridPos: {
                h: 7,
                w: 8,
                x: 0,
                y: 0,
            },
            options: {
                type: 'line',
                format: 'short',
                decimals: 0,
                legend: {
                    alignAsTable: true,
                },
            },
            targets: [
                {
                    expr:
                        '100*sum(irate(pg_stat_database_blks_hit{monitors="$monitors"}[5m])) / (sum(irate(pg_stat_database_blks_hit{monitors="$monitors"}[5m])) + sum(irate(pg_stat_database_blks_read{monitors="$monitors"}[5m])))',
                    legendFormat: 'hit-ratio',
                },
            ],
        },
        {
            title: 'SELECT (fetched)',
            type: 'chart',
            gridPos: {
                h: 7,
                w: 8,
                x: 0,
                y: 0,
            },
            options: {
                type: 'line',
                format: 'short',
                decimals: 0,
                legend: {
                    alignAsTable: true,
                },
            },
            targets: [
                {
                    expr:
                        'sum(irate(pg_stat_database_tup_fetched{datname!~"template0|template1|", monitors="$monitors"}[5m]))',
                    legendFormat: 'Total SELECT (fetched)',
                },
            ],
        },
        {
            title: 'INSERT',
            type: 'chart',
            gridPos: {
                h: 7,
                w: 8,
                x: 0,
                y: 0,
            },
            options: {
                type: 'line',
                format: 'short',
                decimals: 0,
                legend: {
                    alignAsTable: true,
                },
            },
            targets: [
                {
                    expr:
                        'sum(irate(pg_stat_database_tup_inserted{datname!~"template0|template1|", monitors="$monitors"}[5m]))',
                    legendFormat: 'Total INSERT',
                },
            ],
        },
        {
            title: 'UPDATE',
            type: 'chart',
            gridPos: {
                h: 7,
                w: 8,
                x: 0,
                y: 0,
            },
            options: {
                type: 'line',
                format: 'short',
                decimals: 0,
                legend: {
                    alignAsTable: true,
                },
            },
            targets: [
                {
                    expr:
                        'sum(irate(pg_stat_database_tup_updated{datname!~"template0|template1|", monitors="$monitors"}[5m]))',
                    legendFormat: 'Total UPDATE',
                },
            ],
        },
        {
            title: 'DELETE',
            type: 'chart',
            gridPos: {
                h: 7,
                w: 8,
                x: 0,
                y: 0,
            },
            options: {
                type: 'line',
                format: 'short',
                decimals: 0,
                legend: {
                    alignAsTable: true,
                },
            },
            targets: [
                {
                    expr:
                        'sum(irate(pg_stat_database_tup_deleted{datname!~"template0|template1|", monitors="$monitors"}[5m]))',
                    legendFormat: 'Total DELETE',
                },
            ],
        },
        {
            title: 'SELECT (returned)',
            type: 'chart',
            gridPos: {
                h: 7,
                w: 8,
                x: 0,
                y: 0,
            },
            options: {
                type: 'line',
                format: 'short',
                decimals: 0,
                legend: {
                    alignAsTable: true,
                },
            },
            targets: [
                {
                    expr:
                        'sum(irate(pg_stat_database_tup_returned{datname!~"template0|template1|", monitors="$monitors"}[5m]))',
                    legendFormat: 'Total SELECT (returned)',
                },
            ],
        },
        {
            title: 'Active sessions',
            type: 'chart',
            gridPos: {
                h: 7,
                w: 8,
                x: 0,
                y: 0,
            },
            options: {
                type: 'line',
                format: 'short',
                decimals: 0,
                legend: {
                    alignAsTable: true,
                },
            },
            targets: [
                {
                    expr:
                        'sum(pg_stat_activity_count{datname!~"template0|template1|", monitors="$monitors", state="active"})',
                    legendFormat: 'Total active sessions',
                },
            ],
        },
        {
            title: 'Idle sessions',
            type: 'chart',
            gridPos: {
                h: 7,
                w: 8,
                x: 0,
                y: 0,
            },
            options: {
                type: 'line',
                format: 'short',
                decimals: 0,
                legend: {
                    alignAsTable: true,
                },
            },
            targets: [
                {
                    expr:
                        'sum(pg_stat_activity_count{datname!~"template0|template1|", monitors="$monitors", state=~"idle"})',
                    legendFormat: 'Total idle sessions',
                },
            ],
        },
        {
            title: 'Idle sessions in transaction',
            type: 'chart',
            gridPos: {
                h: 7,
                w: 8,
                x: 0,
                y: 0,
            },
            options: {
                type: 'line',
                format: 'short',
                decimals: 0,
                legend: {
                    alignAsTable: true,
                },
            },
            targets: [
                {
                    expr:
                        'sum(pg_stat_activity_count{datname!~"template0|template1|", monitors="$monitors", state="idle in transaction"})',
                    legendFormat: 'Total idle sessions in transaction',
                },
            ],
        },
        {
            title: 'Idle sessions in transaction (aborted)',
            type: 'chart',
            gridPos: {
                h: 7,
                w: 8,
                x: 0,
                y: 0,
            },
            options: {
                type: 'line',
                format: 'short',
                decimals: 0,
                legend: {
                    alignAsTable: true,
                },
            },
            targets: [
                {
                    expr:
                        'sum(pg_stat_activity_count{datname!~"template0|template1|", monitors="$monitors", state="idle in transaction (aborted)"})',
                    legendFormat:
                        'Total idle sessions in transaction (aborted)',
                },
            ],
        },
        {
            title: 'Checkpoints requested and timed',
            type: 'chart',
            gridPos: {
                h: 7,
                w: 24,
                x: 0,
                y: 0,
            },
            options: {
                type: 'area',
                format: 'short',
                decimals: 0,
                legend: {
                    alignAsTable: true,
                },
            },
            targets: [
                {
                    expr:
                        'rate(pg_stat_bgwriter_checkpoints_req{monitors="$monitors"}[5m])',
                    legendFormat: 'Requested: Number of requested checkpoints',
                },
                {
                    expr:
                        'rate(pg_stat_bgwriter_checkpoints_timed{monitors="$monitors"}[5m])',
                    legendFormat: 'Timed: Number of scheduled checkpoints',
                },
            ],
        },
        {
            title: 'Lock tables',
            type: 'chart',
            gridPos: {
                h: 7,
                w: 8,
                x: 0,
                y: 0,
            },
            options: {
                type: 'line',
                decimals: 0,
                format: 'short',
                legend: {
                    alignAsTable: true,
                },
            },
            targets: [
                {
                    expr:
                        'sum without (datname) (pg_locks_count{datname!~"template0|template1|", monitors="$monitors"})',
                    legendFormat: 'Total {{ mode }}',
                },
            ],
        },
        {
            title: 'Checkpoint sync time',
            type: 'chart',
            gridPos: {
                h: 7,
                w: 8,
                x: 0,
                y: 0,
            },
            options: {
                type: 'area',
                format: 'short',
                decimals: 0,
                legend: {
                    alignAsTable: true,
                },
            },
            targets: [
                {
                    expr:
                        'rate(pg_stat_bgwriter_checkpoint_sync_time{monitors="$monitors"}[5m])',
                    legendFormat: 'Sync Time',
                },
            ],
        },
        {
            title: 'Checkpoint write time',
            type: 'chart',
            gridPos: {
                h: 7,
                w: 8,
                x: 0,
                y: 0,
            },
            options: {
                type: 'area',
                format: 'short',
                decimals: 0,
                legend: {
                    alignAsTable: true,
                },
            },
            targets: [
                {
                    expr:
                        'rate(pg_stat_bgwriter_checkpoint_write_time{monitors="$monitors"}[5m])',
                    legendFormat: 'Write Time',
                },
            ],
        },
        {
            title: 'Disk IO utilization',
            type: 'chart',
            gridPos: {
                h: 7,
                w: 8,
                x: 0,
                y: 0,
            },
            options: {
                type: 'line',
                decimals: 1,
                legend: {
                    alignAsTable: true,
                },
                yaxis: [
                    {
                        format: 'percent',
                        max: 100,
                    },
                ],
            },
            targets: [
                {
                    expr:
                        'irate(node_disk_io_time_seconds_total{instance=~"$instance"}[5m])*100',
                    legendFormat: '{{ device }}',
                },
            ],
        },
        {
            title: 'Disk usage',
            type: 'chart',
            gridPos: {
                h: 7,
                w: 8,
                x: 0,
                y: 0,
            },
            options: {
                type: 'line',
                decimals: 1,
                legend: {
                    alignAsTable: true,
                },
                yaxis: [
                    {
                        format: 'percentage',
                        max: 1,
                    },
                ],
            },
            targets: [
                {
                    expr:
                        '1 - node_filesystem_free_bytes{instance=~"$instance", fstype!~"rootfs|selinuxfs|autofs|rpc_pipefs|tmpfs"} / node_filesystem_size_bytes{fstype!~"rootfs|selinuxfs|autofs|rpc_pipefs|tmpfs"}',
                    legendFormat: '{{ instance }},  {{ mountpoint }}',
                },
            ],
        },
        {
            title: 'Disk latency',
            type: 'chart',
            gridPos: {
                h: 7,
                w: 8,
                x: 0,
                y: 0,
            },
            options: {
                type: 'line',
                decimals: 2,
                format: 'short',
                postfix: 'ms',
                legend: {
                    alignAsTable: true,
                },
            },
            targets: [
                {
                    expr:
                        '(irate(node_disk_read_time_seconds_total{device=~".*", instance=~"$instance"}[5m]) / irate(node_disk_reads_completed_total{device=~".*", instance=~"$instance"}[5m]))*1000',
                    legendFormat: 'Read: {{ device }}',
                },
                {
                    expr:
                        '(irate(node_disk_write_time_seconds_total{device=~".*", instance=~"$instance"}[5m]) / irate(node_disk_writes_completed_total{device=~".*", instance=~"$instance"}[5m]))*1000',
                    legendFormat: 'Write: {{ device }}',
                },
            ],
        },
    ],
    templating: {
        list: [
            {
                datasource: 'clustercontrol',
                multi: false,
                label: 'Host',
                name: 'instance',
                query: "host:$[nodes[nodetype='postgres']]",
                optionValue: 'hostname',
                optionText: 'hostname',
                sort: 1,
                isInstancePicker: true,
            },
            {
                datasource: 'clustercontrol',
                multi: false,
                label: 'Node',
                name: 'monitors',
                query: "node:$[nodetype='postgres' and hostname='$instance']",
                optionValue: '{{hostname}}:{{port}}',
                optionText: '{{hostname}}:{{port}}',
                sort: 2,
            },
        ],
    },
};
export default dashboard;
