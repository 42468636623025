export type CcPkgInfoProps = {
    class_name?: string;
    available_version?: string;
    host_class_name?: string;
    host_name?: string;
    installed_version?: string;
    last_updated?: string;
    package_name?: string;
};

export default class CcPkgInfo {
    public readonly className?: string;
    public readonly availableVersion?: string;
    public readonly hostClassName?: string;
    public readonly hostName?: string;
    public readonly installedVersion?: string;
    public readonly lastUpdated?: string;
    public readonly packageName?: string;

    constructor(props: CcPkgInfoProps) {
        this.className = props.class_name;
        this.availableVersion = props.available_version;
        this.hostClassName = props.host_class_name;
        this.hostName = props.host_name;
        this.installedVersion = props.installed_version;
        this.lastUpdated = props.last_updated;
        this.packageName = props.package_name;
    }
}
