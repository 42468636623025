import SpaceDescriptions from '../../../common/Layout/SpaceDescriptions';
import { TopologyItem } from '../../Topology/TopologyItem';
import { Col, Row } from 'antd';
import AppDivider from '../../../common/AppDivider';
import React, { useEffect, useRef } from 'react';
import { FormInstance } from 'antd/lib/form';
import { TopologyItemList } from '../../Topology/TopologyItemList';
import TopologyItemSummary, {
    extractFormTopologyItemNodeProps,
    TopologyItemSummaryProps,
} from '../../../common/Form/Summary/TopologyItemSummary';
export default TopologySummary;
export type TopologySummaryProps = {
    form: FormInstance;
    title?: React.ReactNode;
    singleNode?: boolean;
    primaryTitle?: string;
    secondaryTitle?: string;
    topologyFieldPath?: string[];
    renderNode?: (item: TopologyItem, form: FormInstance) => React.ReactNode;
    topologyItemSummaryProps?: TopologyItemSummaryProps;
};
function TopologySummary({
    form,
    title,
    singleNode = false,
    primaryTitle,
    secondaryTitle = 'Replicas',
    topologyFieldPath = ['topology'],
    renderNode,
    topologyItemSummaryProps,
}: TopologySummaryProps) {
    const topology = form.getFieldValue(topologyFieldPath);

    const itemList = useRef<TopologyItemList>(
        new TopologyItemList({ items: topology })
    );
    useEffect(() => {
        itemList.current = new TopologyItemList({ items: topology });
    }, [topology]);
    const nodeParents = itemList.current.getParents();

    const renderNodeFn =
        renderNode ||
        ((item: TopologyItem, form) => (
            <TopologyItemSummary
                item={item}
                nodeProps={extractFormTopologyItemNodeProps(
                    item,
                    form.getFieldsValue(true)
                )}
                {...topologyItemSummaryProps}
            />
        ));

    return (
        <>
            <SpaceDescriptions
                direction="vertical"
                title={title !== undefined ? title : `Nodes`}
                alignItems="right"
            >
                {' '}
            </SpaceDescriptions>
            {nodeParents.map((p: TopologyItem, index: number) =>
                singleNode ? (
                    <Row key={index}>
                        <Col flex="auto"></Col>
                        <Col flex="400px">
                            <div style={{ textAlign: 'right', width: 400 }}>
                                {index !== 0 ? <AppDivider /> : null}
                                {renderNodeFn(p, form)}
                            </div>
                        </Col>
                    </Row>
                ) : (
                    <div key={index}>
                        {index !== 0 ? (
                            <Row>
                                <Col flex="auto"></Col>
                                <Col flex="400px">
                                    <AppDivider />
                                </Col>
                            </Row>
                        ) : null}

                        <Row style={{ textAlign: 'right' }}>
                            <Col flex="auto"></Col>
                            <Col flex="200px">
                                <SpaceDescriptions
                                    title={primaryTitle}
                                    direction="vertical"
                                >
                                    {renderNodeFn(p, form)}
                                </SpaceDescriptions>
                            </Col>
                            <Col flex="200px">
                                <SpaceDescriptions
                                    title={secondaryTitle || 'Replicas'}
                                    direction="vertical"
                                >
                                    {itemList.current.count()
                                        ? itemList.current
                                              .getItemChildren(p)
                                              .map((c: TopologyItem, index) => (
                                                  <div
                                                      style={{
                                                          paddingLeft: 10,
                                                      }}
                                                      key={index}
                                                  >
                                                      {index !== 0 ? (
                                                          <AppDivider />
                                                      ) : null}
                                                      {renderNodeFn(c, form)}
                                                  </div>
                                              ))
                                        : 'No hosts'}
                                </SpaceDescriptions>
                            </Col>
                        </Row>
                    </div>
                )
            )}
        </>
    );
}
