import React from 'react';
import { CmonJobInstanceCommand } from '../../services/cmon/models/CmonJobInstance';
import JobButtonBarrier from '../../common/Barrier/JobButtonBarrier';
import { CcClusterType } from '../../services/models/CcCluster';
import JobWatcherButton from './JobWatcherButton';
import Button, { ButtonProps } from 'antd/lib/button';
import AppButton, { AppButtonProps } from '../../common/Control/AppButton';

export default JobButton;

export type JobButtonProps = AppButtonProps & {
    clusterId?: number;
    clusterType?: CcClusterType;
    command: CmonJobInstanceCommand;
    children?: React.ReactNode;
    extraKey?: (string | number | undefined)[];
    onFinish?: () => void;
};

function JobButton({
    clusterId,
    clusterType,
    command,
    children,
    extraKey,
    onFinish,
    ...rest
}: JobButtonProps) {
    return (
        <JobButtonBarrier command={command} clusterType={clusterType}>
            <JobWatcherButton
                command={command}
                clusterId={clusterId}
                extraKey={extraKey}
                onFinish={onFinish}
                button={<AppButton {...rest}>{children}</AppButton>}
            />
        </JobButtonBarrier>
    );
}
