const dashboard = {
    /* sources: fromdual (What can mysql perf mon graphs tell you, munin */
    title: 'MySQL InnoDB Metrics',
    meta: {
        slug: 'mysql-innodb-metrics',
        supportClusterTypes: [
            'replication',
            'galera',
            'mysql_single',
            'mysqlcluster',
            'group_replication',
        ],
    },
    panels: [
        {
            title: 'Innodb bufferpool hit ratio',
            type: 'singlestat',
            gridPos: {
                h: 4,
                w: 5,
                x: 0,
                y: 0,
            },
            options: {
                decimals: 0,
                format: 'number',
                type: 'line',
                legend: {},
                chart: {
                    full: false,
                    show: false,
                },
                postfix: ' / 1,000',
                valueMaps: [],
            },
            targets: [
                {
                    expr:
                        '1000*mysql_global_status_innodb_buffer_pool_read_requests{instance=~"$instance"} / (mysql_global_status_innodb_buffer_pool_reads{instance=~"$instance"} + mysql_global_status_innodb_buffer_pool_read_requests{instance=~"$instance"})',
                    legendFormat: '',
                },
            ],
        },
        {
            title: 'Innodb bufferpool size',
            type: 'singlestat',
            gridPos: {
                h: 4,
                w: 5,
                x: 0,
                y: 0,
            },
            options: {
                decimals: 1,
                format: 'size',
                type: 'line',
                legend: {},
                chart: {
                    full: false,
                    show: false,
                },
                valueMaps: [],
            },
            targets: [
                {
                    expr:
                        'mysql_global_variables_innodb_buffer_pool_size{instance=~"$instance"}',
                    legendFormat: '',
                },
            ],
        },
        {
            title: 'Innodb bufferpool instances',
            type: 'singlestat',
            gridPos: {
                h: 4,
                w: 5,
                x: 0,
                y: 0,
            },
            options: {
                decimals: 1,
                format: 'short',
                type: 'line',
                legend: {},
                chart: {
                    full: false,
                    show: false,
                },
                valueMaps: [],
            },
            targets: [
                {
                    expr:
                        'mysql_global_variables_innodb_buffer_pool_instances{instance=~"$instance"}',
                    legendFormat: '',
                },
            ],
        },
        {
            title: 'Innodb log file size',
            type: 'singlestat',
            gridPos: {
                h: 4,
                w: 4,
                x: 0,
                y: 0,
            },
            options: {
                decimals: 1,
                format: 'size',
                type: 'line',
                legend: {},
                chart: {
                    full: false,
                    show: false,
                },
                valueMaps: [],
            },
            targets: [
                {
                    expr:
                        'mysql_global_variables_innodb_log_file_size{instance=~"$instance"}',
                    legendFormat: '',
                },
            ],
        },
        {
            title: 'Innodb log files in group',
            type: 'singlestat',
            gridPos: {
                h: 4,
                w: 4,
                x: 0,
                y: 0,
            },
            options: {
                decimals: 1,
                format: 'short',
                type: 'line',
                legend: {},
                chart: {
                    full: false,
                    show: false,
                },
                valueMaps: [],
            },
            targets: [
                {
                    expr:
                        'mysql_global_variables_innodb_log_files_in_group{instance=~"$instance"}',
                    legendFormat: '',
                },
            ],
        },
        {
            title: 'InnoDB bufferpool hit ratio',
            type: 'chart',
            gridPos: {
                h: 8,
                w: 12,
                x: 0,
                y: 0,
            },
            options: {
                decimals: 2,
                format: 'short',
                type: 'area',
                legend: {
                    alignAsTable: true,
                },
                yaxis: [
                    {
                        format: 'short',
                        max: null,
                        min: 0,
                    },
                ],
                stack: false,
            },
            targets: [
                {
                    expr:
                        '1000*mysql_global_status_innodb_buffer_pool_read_requests{instance=~"$instance"} / (mysql_global_status_innodb_buffer_pool_reads{instance=~"$instance"} + mysql_global_status_innodb_buffer_pool_read_requests{instance=~"$instance"})',
                    legendFormat: 'Bufferpool Hit Ratio',
                },
            ],
        },
        {
            title: 'InnoDB bufferpool usage',
            type: 'chart',
            gridPos: {
                h: 8,
                w: 12,
                x: 0,
                y: 0,
            },
            options: {
                decimals: 2,
                format: 'short',
                type: 'area',
                legend: {
                    alignAsTable: true,
                },
                yaxis: [
                    {
                        format: 'short',
                        max: null,
                        min: 0,
                    },
                    {
                        format: 'short',
                        max: null,
                        min: 0,
                    },
                ],
                stack: false,
            },
            targets: [
                {
                    expr:
                        'mysql_global_variables_innodb_buffer_pool_size{instance=~"$instance"}',
                    legendFormat: 'Bufferpool Size',
                    yaxis: 1,
                },
                {
                    expr:
                        'mysql_global_status_innodb_buffer_pool_bytes_data{instance=~"$instance"}',
                    legendFormat: 'Used Bytes (including dirty)',
                },
                /*                {
                                    "expr": "mysql_global_status_innodb_buffer_pool_bytes_free{instance=~\"$instance\"}",
                                    "legendFormat": "Free Bytes"
                                },*/
                {
                    expr:
                        'mysql_global_status_innodb_buffer_pool_bytes_dirty{instance=~"$instance"}',
                    legendFormat: 'Dirty Bytes',
                },
                {
                    expr:
                        'mysql_global_status_innodb_data_reads{instance=~"$instance"}',
                    legendFormat: 'Reads from Disk',
                },
            ],
        },
        {
            title: 'InnoDB bufferpool activity',
            type: 'chart',
            gridPos: {
                h: 8,
                w: 12,
                x: 0,
                y: 0,
            },
            options: {
                decimals: 2,
                format: 'short',
                type: 'area',
                legend: {
                    alignAsTable: true,
                },
                yaxis: [
                    {
                        format: 'short',
                        max: null,
                        min: 0,
                    },
                ],
                stack: false,
            },
            targets: [
                {
                    expr:
                        'irate(mysql_global_status_innodb_pages_created{instance=~"$instance"}[$interval])',
                    legendFormat: 'Created (pages)',
                },
                {
                    expr:
                        'irate(mysql_global_status_innodb_pages_read{instance=~"$instance"}[$interval])',
                    legendFormat: 'Read (pages)',
                },
                {
                    expr:
                        'irate(mysql_global_status_innodb_pages_written{instance=~"$instance"}[$interval])',
                    legendFormat: 'Written (pages)',
                },
            ],
        },
        {
            title: 'InnoDB Row Operations',
            type: 'chart',
            gridPos: {
                h: 8,
                w: 12,
                x: 0,
                y: 0,
            },
            options: {
                decimals: 2,
                format: 'short',
                type: 'area',
                legend: {
                    alignAsTable: true,
                },
                yaxis: [
                    {
                        format: 'short',
                        max: null,
                        min: 0,
                    },
                ],
                stack: false,
            },
            targets: [
                {
                    expr:
                        'irate(mysql_global_status_innodb_row_ops_total{instance=~"$instance",operation="read"}[$interval])',
                    legendFormat: 'InnoDb Rows Read',
                },
                {
                    expr:
                        'irate(mysql_global_status_innodb_row_ops_total{instance=~"$instance",operation="deleted"}[$interval])',
                    legendFormat: 'InnoDb Rows Deleted',
                },
                {
                    expr:
                        'irate(mysql_global_status_innodb_row_ops_total{instance=~"$instance",operation="updated"}[$interval])',
                    legendFormat: 'InnoDb Rows Updated',
                },
                {
                    expr:
                        'irate(mysql_global_status_innodb_row_ops_total{instance=~"$instance",operation="inserted"}[$interval])',
                    legendFormat: 'InnoDb Rows Inserted',
                },
            ],
        },
        {
            title: 'InnoDB data reads/writes',
            type: 'chart',
            gridPos: {
                h: 8,
                w: 12,
                x: 0,
                y: 0,
            },
            options: {
                decimals: 2,
                format: 'short',
                type: 'area',
                legend: {
                    alignAsTable: true,
                },
                yaxis: [
                    {
                        format: 'short',
                        max: null,
                        min: 0,
                    },
                    {
                        format: 'short',
                        max: null,
                        min: 0,
                    },
                ],
                stack: false,
            },
            targets: [
                {
                    expr:
                        'irate(mysql_global_status_innodb_data_read{instance=~"$instance"}[1m])',
                    legendFormat: 'Innodb_data_read (bytes/sec)',
                    yaxis: 1,
                },
                {
                    expr:
                        'irate(mysql_global_status_innodb_data_written{instance=~"$instance"}[1m])',
                    legendFormat: 'Innodb_data_written (bytes/sec)',
                    yaxis: 1,
                },
            ],
        },
        {
            title: 'InnoDB OS reads/writes',
            type: 'chart',
            gridPos: {
                h: 8,
                w: 12,
                x: 0,
                y: 0,
            },
            options: {
                decimals: 2,
                format: 'short',
                type: 'area',
                legend: {
                    alignAsTable: true,
                },
                yaxis: [
                    {
                        format: 'short',
                        max: null,
                        min: 0,
                    },
                    {
                        format: 'short',
                        max: null,
                        min: 0,
                    },
                ],
                stack: false,
            },
            targets: [
                {
                    expr:
                        'irate(mysql_global_status_innodb_data_reads{instance=~"$instance"}[1m])',
                    legendFormat: 'Innodb_data_reads (per sec)',
                    yaxis: 1,
                },
                {
                    expr:
                        'irate(mysql_global_status_innodb_data_writes{instance=~"$instance"}[1m])',
                    legendFormat: 'Innodb_data_writes (per sec)',
                    yaxis: 1,
                },
            ],
        },
        {
            title: 'InnoDB fsyncs',
            type: 'chart',
            gridPos: {
                h: 8,
                w: 12,
                x: 0,
                y: 0,
            },
            options: {
                decimals: 2,
                format: 'short',
                type: 'area',
                legend: {
                    alignAsTable: true,
                },
                yaxis: [
                    {
                        format: 'short',
                        max: null,
                        min: 0,
                    },
                    {
                        format: 'short',
                        max: null,
                        min: 0,
                    },
                ],
                stack: false,
            },
            targets: [
                {
                    expr:
                        'irate(mysql_global_status_innodb_data_fsyncs{instance=~"$instance"}[1m])',
                    legendFormat: 'Innodb_data_fsyncs (fsyncs/sec)',
                    yaxis: 1,
                },
                {
                    expr:
                        'irate(mysql_global_status_innodb_os_log_fsyncs{instance=~"$instance"}[1m])',
                    legendFormat: 'Innodb_os_log_fsyncs (redo log fsyncs/sec)',
                    yaxis: 1,
                },
            ],
        },
        {
            title: 'InnoDB locking',
            type: 'chart',
            gridPos: {
                h: 8,
                w: 12,
                x: 0,
                y: 0,
            },
            options: {
                decimals: 2,
                format: 'short',
                type: 'area',
                legend: {
                    alignAsTable: true,
                },
                yaxis: [
                    {
                        format: 'short',
                        max: null,
                        min: 0,
                    },
                ],
                stack: false,
            },
            targets: [
                {
                    expr:
                        'irate(mysql_global_status_innodb_row_lock_time{instance=~"$instance"}[1m])',
                    legendFormat: 'Innodb Row Lock Time (total)',
                },
                {
                    expr:
                        'irate(mysql_global_status_innodb_row_lock_waits{instance=~"$instance"}[1m])',
                    legendFormat: 'Innodb Row Lock Waits (total)',
                },
                {
                    expr:
                        'mysql_global_status_innodb_row_lock_current_waits{instance=~"$instance"}',
                    legendFormat: 'Innodb Row Lock Current Waits',
                },
                {
                    expr:
                        'mysql_global_status_innodb_row_lock_time_avg{instance=~"$instance"}',
                    legendFormat: 'Innodb Row Lock Time Avg',
                },
                {
                    expr:
                        'mysql_global_status_innodb_row_lock_time_max{instance=~"$instance"}',
                    legendFormat: 'Innodb Row Lock Time Wait',
                },
            ],
        },
        {
            title: 'InnoDB read ahead',
            type: 'chart',
            gridPos: {
                h: 8,
                w: 12,
                x: 0,
                y: 0,
            },
            options: {
                decimals: 2,
                format: 'short',
                type: 'area',
                legend: {
                    alignAsTable: true,
                },
                yaxis: [
                    {
                        format: 'short',
                        max: null,
                        min: 0,
                    },
                ],
                stack: false,
            },
            targets: [
                {
                    expr:
                        'irate(mysql_global_status_innodb_buffer_pool_read_ahead_rnd{instance=~"$instance"}[1m])',
                    legendFormat: 'Innodb_buffer_pool_read_ahead_rnd',
                },
                {
                    expr:
                        'irate(mysql_global_status_innodb_buffer_pool_read_ahead_evicted{instance=~"$instance"}[1m])',
                    legendFormat: 'Innodb_buffer_pool_read_ahead_evicted',
                },
                {
                    expr:
                        'irate(mysql_global_status_innodb_buffer_pool_read_ahead{instance=~"$instance"}[1m])',
                    legendFormat: 'Innodb_buffer_pool_read_ahead',
                },
            ],
        },
        {
            title: 'InnoDB buffer pool read/write requests',
            type: 'chart',
            gridPos: {
                h: 8,
                w: 12,
                x: 0,
                y: 0,
            },
            options: {
                decimals: 2,
                format: 'short',
                type: 'area',
                legend: {
                    alignAsTable: true,
                },
                yaxis: [
                    {
                        format: 'short',
                        max: null,
                        min: 0,
                    },
                ],
                stack: false,
            },
            targets: [
                {
                    expr:
                        'irate(mysql_global_status_innodb_buffer_pool_reads{instance=~"$instance"}[1m])',
                    legendFormat: 'Innodb_buffer_pool_reads',
                },
                {
                    expr:
                        'irate(mysql_global_status_innodb_buffer_pool_read_requests{instance=~"$instance"}[1m])',
                    legendFormat: 'Innodb_buffer_pool_read_requests',
                },
                {
                    expr:
                        'irate(mysql_global_status_innodb_buffer_pool_write_requests{instance=~"$instance"}[1m])',
                    legendFormat: 'Innodb_buffer_pool_write_requests',
                },
            ],
        },
    ],
    templating: {
        list: [
            {
                datasource: 'clustercontrol',
                multi: false,
                label: 'Host',
                name: 'instance',
                query: "host:$[nodes[nodetype='mysql' or nodetype='galera']]",
                optionValue: 'hostname',
                optionText: 'hostname',
                sort: 1,
                isInstancePicker: true,
            },
        ],
    },
    schemaVersion: 1,
};
export default dashboard;
