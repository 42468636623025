import React, {
    useContext,
    useCallback,
    ReactNode,
    useState,
    useEffect,
} from 'react';
import { ResponsiveContext } from '@severalnines/bar-frontend-components/build/lib/Layout/Responsive';
import ActionsMenu, {
    ActionsMenuProps,
} from '../../../../common/Navigation/ActionsMenu';
import { Operation } from './haProxyDetailsHelper';
import useCreateJob from '../../../Jobs/useCreateJob';
import { CmonJobInstanceCommand } from '../../../../services/cmon/models/CmonJobInstance';
import useJobWatcher from '../../../Jobs/useJobWatcher';
import StatusFormat, {
    StatusFormatStatus,
} from '@severalnines/bar-frontend-components/build/lib/Format/StatusFormat';
import { Button } from 'antd';
import AppTable from '../../../../common/DataDisplay/AppTable';

export type HAProxyDetailsTableProps = ActionsMenuProps & {
    clusterId?: number;
    haProxyTableData: undefined | any[];
    loading: boolean;
    onSuccess?: () => void;
    onError?: (err: Error) => void;
    handleRefresh: () => Promise<void>;
};

export default HAProxyDetailsTable;

function HAProxyDetailsTable({
    clusterId,
    haProxyTableData,
    loading: tableLoading,
    onSuccess,
    onError,
    handleRefresh,
    ...rest
}: HAProxyDetailsTableProps) {
    const { responsive } = useContext(ResponsiveContext);
    const [keys, setKeys] = useState<string[]>(['0', '1', '2']);

    useEffect(() => {
        if (haProxyTableData) {
            setKeys(haProxyTableData?.map((tr) => tr.key));
        }
    }, [haProxyTableData]);

    const { loading: loadingJob } = useJobWatcher({
        command: CmonJobInstanceCommand.HAPROXY.toLowerCase() as CmonJobInstanceCommand,
        clusterId: clusterId,
        onFinish: handleRefresh,
    });
    const { send } = useCreateJob({
        clusterId: clusterId,
        title: `HAProxy`,
        command: CmonJobInstanceCommand.HAPROXY.toLowerCase() as CmonJobInstanceCommand,
        onSuccess,
    });

    const handleNodeStatus = useCallback(
        async (operationData: Operation) => {
            if (typeof operationData === 'object') {
                try {
                    const { name, data } = operationData;
                    await send(
                        {
                            clusterId: clusterId,
                            ...data,
                        },
                        { job: { title: `${name} on HAProxy` } }
                    );
                } catch (err: any) {
                    if (onError) {
                        onError(err);
                    }
                }
            }
        },
        [haProxyTableData]
    );
    const columns = [
        {
            title: '',
            key: 'rowName',
            dataIndex: 'rowName',
        },
        {
            title: 'Server',
            children: [
                {
                    title: 'Status',
                    dataIndex: 'status',
                    key: 'status',
                    render: (record: string) =>
                        record !== undefined ? getHAProxyStatus(record) : <></>,
                },
                {
                    title: 'Role',
                    dataIndex: 'role',
                    key: 'role',
                },
            ],
        },
        {
            title: 'Queue',
            children: [
                {
                    title: 'Cur',
                    dataIndex: 'curQueue',
                    key: 'curQueue',
                },
                {
                    title: 'Max',
                    dataIndex: 'maxQueue',
                    key: 'maxQueue',
                },
                {
                    title: 'Limit',
                    dataIndex: 'limitQueue',
                    key: 'limitQueue',
                },
            ],
        },
        {
            title: 'Session rate',
            children: [
                {
                    title: 'Cur',
                    dataIndex: 'curRate',
                    key: 'curRate',
                },
                {
                    title: 'Max',
                    dataIndex: 'maxRate',
                    key: 'maxRate',
                },
                {
                    title: 'Limit',
                    dataIndex: 'limitRate',
                    key: 'limitRate',
                },
            ],
        },
        {
            title: 'Session',
            children: [
                {
                    title: 'Cur',
                    dataIndex: 'curSession',
                    key: 'curSession',
                },
                {
                    title: 'Max',
                    dataIndex: 'maxSession',
                    key: 'maxSession',
                },
                {
                    title: 'Limit',
                    dataIndex: 'limitSession',
                    key: 'limitSession',
                },
            ],
        },
        {
            title: 'Bytes',
            children: [
                {
                    title: 'In',
                    dataIndex: 'inBytes',
                    key: 'inBytes',
                },
                {
                    title: 'Out',
                    dataIndex: 'outBytes',
                    key: 'outBytes',
                },
            ],
        },
        {
            title: 'Actions',
            key: 'operation',
            render: (record: any) =>
                typeof record.operation === 'object' ? (
                    <ActionsMenu
                        items={[
                            {
                                key: 'node-status',
                                label: (
                                    <Button
                                        type="link"
                                        onClick={() =>
                                            handleNodeStatus(record.operation)
                                        }
                                    >
                                        {record.operation.name}
                                    </Button>
                                ),
                            },
                        ]}
                        {...rest}
                    />
                ) : (
                    record.operation
                ),
        },
    ];

    return (
        <AppTable
            className="HAProxyDetailsTable"
            loading={tableLoading || loadingJob}
            size="small"
            defaultExpandAllRows={true}
            defaultExpandedRowKeys={keys}
            dataSource={haProxyTableData}
            columns={columns}
            pagination={false}
            responsive={responsive}
        />
    );
}

function getHAProxyStatus(type: string): ReactNode {
    switch (type) {
        case 'OPEN':
            return (
                <StatusFormat status={StatusFormatStatus.success} text={type} />
            );
        case 'UP':
            return (
                <StatusFormat status={StatusFormatStatus.success} text={type} />
            );
        case 'UP1/2':
            return (
                <StatusFormat status={StatusFormatStatus.info} text="Loading" />
            );
        case 'MAINT':
            return (
                <StatusFormat status={StatusFormatStatus.warning} text={type} />
            );

        default:
            return (
                <StatusFormat status={StatusFormatStatus.error} text={type} />
            );
    }
}
