import React from 'react';
import { ButtonProps } from 'antd/lib/button';
import CcCluster from '../../../services/models/CcCluster';
import { CmonJobInstanceCommand } from '../../../services/cmon/models/CmonJobInstance';
import JobConfirmActionButton from '../../Jobs/JobConfirmActionButton';

export default GaleraDisableSSLButton;

export type GaleraDisableSSLButtonProps = ButtonProps & {
    cluster: CcCluster;
    onSuccess?: () => void;
};

function GaleraDisableSSLButton({
    cluster,
    onSuccess,
    ...rest
}: GaleraDisableSSLButtonProps) {
    return (
        <JobConfirmActionButton
            clusterId={cluster.clusterId}
            command={
                CmonJobInstanceCommand.ENCRYPT_REPLICATION.toLowerCase() as CmonJobInstanceCommand
            }
            jobData={{
                action: 'disable',
                clusterId: `${cluster.clusterId}`,
            }}
            confirmTitle={
                'Are you sure you want to disable Galera SSL encryption?'
            }
            title={'Disable Galera SSL encryption'}
            onSuccess={onSuccess}
            {...rest}
        />
    );
}
