


export interface CmonRawElementProps  {

}



export default class CmonRawElement {


    constructor(props: CmonRawElementProps) {

    }

}
