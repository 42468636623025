import React, { useMemo } from 'react';
import { ButtonProps } from 'antd/lib/button';
import { CmonJobInstanceCommand } from '../../services/cmon/models/CmonJobInstance';
import useJobWatcher from './useJobWatcher';

export default JobWatcherButton;

export type JobWatcherButtonProps = ButtonProps & {
    command: CmonJobInstanceCommand;
    button?: React.ReactElement<ButtonProps>;
    clusterId?: number;
    extraKey?: (string | number | undefined)[];
    onFinish?: () => void;
};

function JobWatcherButton({
    command,
    button,
    clusterId,
    extraKey,
    onFinish,
    ...rest
}: JobWatcherButtonProps) {
    const { loading: loadingJob } = useJobWatcher({
        command,
        clusterId,
        extraKey,
        onFinish,
    });

    return useMemo(() => {
        const props = (button as React.ReactElement).props;
        return React.cloneElement(button as React.ReactElement, {
            ...props,
            ...rest,
            loading: loadingJob || props.loading,
        });
    }, [button, loadingJob]);
}
