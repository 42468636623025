import useListFetch from '../../../common/useListFetch';
import { AppConfig } from '../../../AppConfig';
import { useCallback, useEffect, useReducer, useState } from 'react';
import {
    initialListState,
    listReducer,
    setListAll,
} from '../../../common/listReducer';
import { getSortAlphabeticFn } from '../../../common/sorting';
import { arrayFilter, arrayPages } from '../../../common/filtering';
import { FetchRefreshFunctionParams } from '../../../common/useFetch';
import CmonConfigService from '../../../services/cmon/CmonConfigService';

export interface LdapConfigGroupMappingItem {
    cmonGroupName: string;
    ldapGroupId: string;
    sectionName: string;
}

export type UseLdapGroupMapListProps = {
    pageSize?: number;
    useCache?: boolean;
};

// @todo refactor this to useListFetch file
type ListParams = {
    page?: number;
    pageSize?: number;
    order?: (a: any, b: any) => any;
    filters?: Function[];
};

// @todo refactor this to useListFetch file
type FilterFunctionParams = ListParams & { arr?: any[] };
type RefreshFunctionParams = FetchRefreshFunctionParams;

export default function useLdapGroupMapList({
    pageSize = AppConfig.defaultPaginationConfig.pageSize,
    useCache,
}: UseLdapGroupMapListProps) {
    const {
        list: groupMapList,
        loading,
        loaded,
        refresh: groupMapRefresh,
        cancel,
    } = useListFetch({
        name: 'group-map-list',
        useCache,
        fetchFn: async ({ filters, order, page, pageSize, ...rest }, opts) => {
            const {
                ldap_configuration,
            } = await CmonConfigService.getLdapConfig(
                {
                    ...rest,
                },
                opts
            );
            return {
                list: ldap_configuration?.groupMappings || [],
                total,
            };
        },
        cancelFn: async ({ requestId }) => {
            await CmonConfigService.cancelRequest(requestId);
        },
    });
    const [list, setList] = useState<LdapConfigGroupMappingItem[]>();
    const [total, setTotal] = useState<number>(0);

    const [
        {
            page: listPage,
            pageSize: listPageSize,
            order: listOrder,
            filters: listFilters,
        },
        listDispatch,
    ] = useReducer(listReducer, {
        ...initialListState,
        pageSize: pageSize || 0,
        order: getSortAlphabeticFn('ascend', (x) => x.ldapGroupId),
    });
    const filter = useCallback<(p?: FilterFunctionParams) => void>(
        ({
            page = listPage,
            pageSize = listPageSize,
            order = listOrder,
            filters = listFilters,
            arr = groupMapList || [],
        } = {}) => {
            listDispatch(setListAll({ page, pageSize, order, filters }));
            const filteredArr = arrayFilter({
                filters: [...(filters || [])],
                arr,
            });

            setList([
                ...arrayPages({
                    page,
                    pageSize,
                    order: order,
                    arr: filteredArr,
                }),
            ]);
            setTotal(filteredArr.length);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [listPage, listPageSize, listOrder, listFilters, groupMapList]
    );
    const refresh = useCallback<(p?: RefreshFunctionParams) => Promise<void>>(
        async ({
            page = listPage,
            pageSize = listPageSize,
            order = listOrder,
            filters = listFilters,
            ...rest
        } = {}) => {
            listDispatch(setListAll({ page, pageSize, order, filters }));
            await groupMapRefresh({
                ...rest,
            });
        },
        [listPage, listPageSize, listOrder, listFilters]
    );

    useEffect(() => {
        if (groupMapList) {
            filter({
                arr: groupMapList,
            });
        }
    }, [groupMapList]);

    return {
        list,
        loading,
        loaded,
        refresh,
        filter,
        page: listPage,
        pageSize: listPageSize,
        total,
        cancel,
    };
}
