import React from 'react';
import ActionButton from '@severalnines/bar-frontend-components/build/lib/General/ActionButton';
import ButtonWithForm from '../../../common/General/ButtonWithForm';
import { ButtonProps } from 'antd/lib/button';
import CcCluster from '../../../services/models/CcCluster';
import PrometheusDisableModal from '../../Prometheus/PrometheusDisableModal';

export default ClusterDisablePrometheusButton;

export type ClusterDisablePrometheusButtonProps = ButtonProps & {
    cluster: CcCluster;
};

function ClusterDisablePrometheusButton({
    cluster,
    ...rest
}: ClusterDisablePrometheusButtonProps) {
    return (
        <ButtonWithForm
            button={
                <ActionButton critical={true} {...rest}>
                    Disable agent based monitoring
                </ActionButton>
            }
            form={<PrometheusDisableModal cluster={cluster} />}
        />
    );
}
