import './ClusterConfigPage.less';
import React from 'react';
import CcCluster from '../../../services/models/CcCluster';
import { Alert } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import AppPageWrapper from '../../../common/Layout/AppPageWrapper';
import { CLUSTER_CONFIG_READONLY_CATEGORIES } from '../useClusterConfigGrouped';
import ClusterConfigParams from '../Config/ClusterConfigParams';
import { ConfigClusterGroupedProvider } from '../Config/ConfigContext/ConfigClusterGroupedProvider';

export default ClusterConfigPage;

export type ClusterConfigPageProps = { cluster: CcCluster };

function ClusterConfigPage({ cluster, ...rest }: ClusterConfigPageProps) {
    const navigate = useNavigate();

    const { configPage } = useParams<{
        configPage: string;
    }>();

    return (
        <ConfigClusterGroupedProvider cluster={cluster}>
            <ClusterConfigParams
                className="ClusterConfigPage_content"
                tabChange={(key) => {
                    navigate(
                        `/clusters/${cluster.clusterId}/settings/system-settings/${key}`
                    );
                }}
                activeCategory={configPage}
                readonlyCategories={CLUSTER_CONFIG_READONLY_CATEGORIES}
                readonlyMessage={
                    <Alert
                        message={
                            <span>
                                This category contains CMON settings and can't
                                be changed for the specific cluster, to change
                                these settings please check /etc/cmon.cnf file
                            </span>
                        }
                    />
                }
            />
        </ConfigClusterGroupedProvider>
    );
}
