import React from 'react';
import ButtonWithForm from '../../common/General/ButtonWithForm';
import KeyManagementImportCertificateModal from './KeyManagementImportCertificateModal';
import ActionButton from '@severalnines/bar-frontend-components/build/lib/General/ActionButton';

export default KeyManagementImportCertificateButton;

export type KeyManagementImportCertificateButtonProps = {
    list: any;
    onSuccess?: () => void;
};

function KeyManagementImportCertificateButton({
    list,
    onSuccess,
}: KeyManagementImportCertificateButtonProps) {
    return (
        <ButtonWithForm
            button={<ActionButton>Import certificate</ActionButton>}
            form={
                <KeyManagementImportCertificateModal
                    list={list}
                    onSuccess={onSuccess}
                />
            }
        />
    );
}
