import React from 'react';
import './AuthWrapper.less';
import logo from './auth-logo.svg';
import smallLogo from './logo-white.svg';

export default AuthWrapper;

export type AuthWrapperProps = {
    children: any;
};

function AuthWrapper({ children }: AuthWrapperProps) {
    return (
        <div className="AuthWrapper">
            <div className="AuthWrapper_welcome">
                <div className="AuthWrapper_welcome_wrapper">
                    <div>
                        Welcome to
                        <img
                            className="AuthWrapper_logo"
                            alt="Cluster Control"
                            src={logo}
                        />
                    </div>
                </div>
                <div className="AuthWrapper_copyright">
                    <div>
                        <img
                            className="AuthWrapper_copyright_logo"
                            alt="Cluster Control"
                            src={smallLogo}
                        />
                        <br />© Copyright Severalnines{' '}
                        {new Date().getFullYear()} All Rights Reserved.
                    </div>
                </div>
            </div>
            <div className="AuthWrapper_body">
                <div className="AuthWrapper_content">
                    <div className="AuthWrapper_content_body">{children}</div>
                    <p className="AuthWrapper_copyright_right-side">
                        © Copyright Severalnines {new Date().getFullYear()} All
                        Rights Reserved.
                    </p>
                </div>
            </div>
        </div>
    );
}
