import React from 'react';
import { Form, Input } from 'antd';
import { FormItemProps } from 'antd/lib/form';

export default NetcatPortField;

export type NetcatPortFieldProps = FormItemProps & {
    required?: boolean;
    disabled?: boolean;
};

function NetcatPortField({
    required = false,
    disabled = false,
    ...rest
}: NetcatPortFieldProps) {
    return (
        <div className="NetcatPortField">
            <Form.Item
                name="netcatPorts"
                label="Netcat port"
                rules={[
                    {
                        required,
                        message: 'Please enter netcat ports ranges',
                    },
                ]}
                {...rest}
            >
                <Input
                    disabled={disabled}
                    placeholder="Enter netcat ports ranges"
                />
            </Form.Item>
        </div>
    );
}
