import React from 'react';
import { Alert, Button, Form, Input, Result, Space } from 'antd';
import FormFooter from '../../../common/FormFooter';
import CcCluster, { CcClusterType } from '../../../services/models/CcCluster';
import ModalDefaultForm from '../../../common/ModalDefaultForm';
import useCreateJob from '../../Jobs/useCreateJob';
import { CmonJobInstanceCommand } from '../../../services/cmon/models/CmonJobInstance';
import SpaceWide from '../../../common/SpaceWide';
import { StatusFormatStatus } from '@severalnines/bar-frontend-components/build/lib/Format/StatusFormat';
import { getLargeIconUrl } from '../../Notifications/uiNotification';
import ClusterFormat from '../ClusterFormat';
import AppDivider from '../../../common/AppDivider';
import SpaceDescriptions from '../../../common/Layout/SpaceDescriptions';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import FormItemInlineSwitch from '../../../common/DataEntry/FormItemInlineSwitch';

export default ClusterDeleteForm;

export type ClusterDeleteFormProps = {
    cluster: CcCluster;
    onSuccess?: () => void;
    onCancel?: () => void;
};
export type ClusterDeleteFormValues = {
    removeBackups: boolean;
};

function ClusterDeleteForm({
    cluster,
    onSuccess,
    onCancel,
}: ClusterDeleteFormProps) {
    const navigate = useNavigate();
    const { loading, send } = useCreateJob({
        clusterId: 0,
        title: 'Remove cluster',
        command: CmonJobInstanceCommand.REMOVE_CLUSTER,
        onSuccess,
    });
    const { clusterId: urlClusterId } = useParams<{
        clusterId: string;
    }>();
    const [form] = Form.useForm<ClusterDeleteFormValues>();

    const handleSubmit = async (values: ClusterDeleteFormValues) => {
        if (
            !!(await send({
                clusterid: cluster.clusterId,
                remove_backups: values.removeBackups,
            }))
        ) {
            if (urlClusterId) {
                navigate('/clusters/');
            }
        }
    };
    return (
        <ModalDefaultForm
            title="Remove cluster"
            form={form}
            footer={[]}
            onCancel={onCancel}
            defaultVisible={true}
            width={450}
        >
            <Form
                className="ClusterDeleteForm"
                form={form}
                layout="vertical"
                onFinish={handleSubmit}
                initialValues={{}}
            >
                <SpaceWide direction="vertical">
                    <Result
                        style={{
                            background: 'white',
                            minWidth: '300px',
                            padding: 0,
                        }}
                        status="success"
                        icon={
                            <div
                                className="UiNotification_icon"
                                style={{
                                    backgroundImage: `url("${getLargeIconUrl(
                                        StatusFormatStatus.warning
                                    )}")`,
                                }}
                            ></div>
                        }
                        title={'You are going to remove this cluster'}
                        subTitle={
                            <Space direction="vertical" size={20}>
                                <div>
                                    Removing the cluster will delete all
                                    metadata stored on it. The database nodes
                                    will not be affected and will continue to
                                    operate as normal
                                </div>
                            </Space>
                        }
                    ></Result>
                    <SpaceDescriptions
                        title="You are going to remove:"
                        direction="vertical"
                        wide
                    >
                        <SpaceDescriptions.Item style={{ width: '100%' }}>
                            <ClusterFormat
                                cluster={cluster}
                                size="large"
                                showId={true}
                                contentProps={{ style: { width: '100%' } }}
                            />
                        </SpaceDescriptions.Item>
                    </SpaceDescriptions>
                    <Form.Item shouldUpdate={true}>
                        {() =>
                            form.getFieldValue('removeBackups') ? null : (
                                <Alert
                                    message={
                                        cluster.isType(
                                            CcClusterType.TYPE_ELASTIC
                                        ) ? (
                                            <span>
                                                You have to delete all snapshots
                                                files in your cloud repository
                                                or you won’t be able to use the
                                                same bucket for future snapshots
                                            </span>
                                        ) : (
                                            <span>
                                                All related backup files will
                                                not be affected and will not be
                                                deleted after the retention
                                                period is over
                                            </span>
                                        )
                                    }
                                />
                            )
                        }
                    </Form.Item>

                    <FormItemInlineSwitch
                        justify={true}
                        name="removeBackups"
                        label="Delete all backup"
                        valuePropName="checked"
                    />

                    <AppDivider />
                    <Form.Item
                        name="typeRemove"
                        label="Type “REMOVE” into the field to confirm"
                        rules={[
                            {
                                required: true,
                                message: 'Please type "REMOVE".',
                            },
                            {
                                pattern: /^REMOVE$/,
                                message: 'Please type "REMOVE".',
                                validateTrigger: 'onBlur',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                </SpaceWide>
                <FormFooter>
                    <Button
                        key="done"
                        type="primary"
                        htmlType="submit"
                        loading={loading}
                    >
                        Remove
                    </Button>
                </FormFooter>
            </Form>
        </ModalDefaultForm>
    );
}
