import React from 'react';
import { Col, Form, InputNumber, Row, Select, Space } from 'antd';
import InfoIcon from '@severalnines/bar-frontend-components/build/lib/General/InfoIcon';

export default PrometheusExporterForm;

export type PrometheusExporterFromItem = {
    scrapeInterval: number;
    arguments: string[];
};

export type PrometheusExporterFormProps = {
    type: string;
    title: string;
    availableArguments?: string[];
};

function PrometheusExporterForm({
    type,
    title,
    availableArguments = [],
}: PrometheusExporterFormProps) {
    return (
        <div className="PrometheusExporterForm">
            <Row gutter={[24, 0]}>
                <Col span={24}>
                    <h3>{title}</h3>
                </Col>
            </Row>
            <Row gutter={[24, 0]}>
                <Col span={12}>
                    <Form.Item
                        name={[type, 'scrapeInterval']}
                        label={
                            <Space>
                                Scrape Interval
                                <InfoIcon
                                    info={
                                        <span>
                                            Set how frequently the nodes are
                                            scraped for metrics.
                                        </span>
                                    }
                                />
                            </Space>
                        }
                    >
                        <InputNumber min={1} addonBefore="seconds" />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={[24, 0]}>
                <Col span={24}>
                    <Form.Item
                        name={[type, 'arguments']}
                        label={<Space>Arguments</Space>}
                    >
                        <Select
                            mode="tags"
                            allowClear
                            placeholder="Type to add argument"
                        >
                            {availableArguments &&
                                availableArguments.map((o) => (
                                    <Select.Option key={o} value={o}>
                                        {o}
                                    </Select.Option>
                                ))}
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
        </div>
    );
}
