import React, { useMemo } from 'react';
import { Select, SelectProps } from 'antd';
import { CcClusterTechnology } from '../../services/models/CcCluster';

export const DB_PRIVILEGES = [
    'ALL PRIVILEGES',
    'ALTER',
    'SHOW DATABASES',
    'ALTER ROUTINE',
    'CREATE',
    'SHOW VIEW',
    'CREATE ROUTINE',
    'CREATE TEMPORARY TABLES',
    'SHUTDOWN',
    'CREATE USER',
    'CREATE VIEW',
    'SUPER',
    'DELETE',
    'DROP',
    'TRIGGER',
    'EVENT',
    'EXECUTE',
    'UPDATE',
    'FILE',
    'INDEX',
    'USAGE',
    'INSERT',
    'LOCK TABLES',
    'SELECT',
    'PROCESS',
    'REFERENCES',
    'REPLICATION SLAVE',
    'RELOAD',
    'REPLICATION CLIENT',
];

const ALL = 'ALL';
const ALL_PRIVILEGES = 'ALL PRIVILEGES';
const NO_PRIVILEGES = 'NO PRIVILEGES';
const USAGE = 'USAGE';
export const POSTGRESQL_DATA_PRIVILEGES = [
    'SELECT',
    'INSERT',
    'UPDATE',
    'DELETE',
    'TRUNCATE',
    'REFERENCES',
    'TRIGGER',
];
export const POSTGRESQL_DATABASE_PRIVILEGES = [
    'CREATE',
    'CONNECT',
    'TEMPORARY',
];
export const POSTGRESQL_USER_PRIVILEGES = [
    'SUPERUSER',
    'CREATEDB',
    'CREATEROLE',
    'INHERIT',
    'LOGIN',
    'REPLICATION',
    'BYPASSRLS',
];

export const MYSQL_ADMIN_PRIVILEGES = [
    'CREATE TABLESPACE',
    'CREATE USER',
    'FILE',
    'GRANT OPTION',
    'LOCK TABLES',
    'PROCESS',
    'PROXY',
    'REFERENCES',
    'RELOAD',
    'REPLICATION CLIENT',
    'REPLICATION SLAVE',
    'SHOW DATABASES',
    'SHUTDOWN',
    'SUPER',
    'USAGE',
];
export const MYSQL_DATA_PRIVILEGES = [
    'ALTER',
    'ALTER ROUTINE',
    'CREATE',
    'CREATE ROUTINE',
    'CREATE TEMPORARY TABLES',
    'CREATE VIEW',
    'DROP',
    'EVENT',
    'EXECUTE',
    'INDEX',
    'SHOW VIEW',
    'TRIGGER',
];
export const MYSQL_STRUCTURE_PRIVILEGES = [
    'DELETE',
    'INSERT',
    'SELECT',
    'UPDATE',
];

const MYSQL_SPECIAL_PRIVILEGES = [ALL, ALL_PRIVILEGES, NO_PRIVILEGES, USAGE];

export default DBPrivilegesSelect;
export type DBPrivilegesSelectProps = SelectProps<
    typeof DB_PRIVILEGES[number]
> & {
    technology: CcClusterTechnology;
};

function DBPrivilegesSelect({
    value,
    technology,
    ...rest
}: DBPrivilegesSelectProps) {
    const options = useMemo(() => {
        let privileges = getDatabasePrivileges(technology);

        if (technology === CcClusterTechnology.TECHNOLOGY_MYSQL) {
            if (
                MYSQL_SPECIAL_PRIVILEGES.some((privilege) =>
                    value?.includes(privilege)
                )
            ) {
                return [];
            }
            if (value && value.length > 0) {
                privileges = privileges.filter(
                    (privilege) => !MYSQL_SPECIAL_PRIVILEGES.includes(privilege)
                );
            }
        } else if (technology === CcClusterTechnology.TECHNOLOGY_POSTGRESQL) {
            if (value?.includes(ALL_PRIVILEGES)) {
                return [];
            }
            if (value && value.length > 0) {
                const firstPrivilege = value[0];
                if (POSTGRESQL_DATA_PRIVILEGES.includes(firstPrivilege)) {
                    privileges = POSTGRESQL_DATA_PRIVILEGES;
                } else if (
                    POSTGRESQL_DATABASE_PRIVILEGES.includes(firstPrivilege)
                ) {
                    privileges = POSTGRESQL_DATABASE_PRIVILEGES;
                } else if (
                    POSTGRESQL_USER_PRIVILEGES.includes(firstPrivilege)
                ) {
                    privileges = POSTGRESQL_USER_PRIVILEGES;
                }
                privileges = privileges.filter(
                    (privilege) => privilege !== ALL_PRIVILEGES
                );
            }
        }

        return privileges.map((privilege) => ({
            label: privilege,
            value: privilege,
        }));
    }, [value, technology]);

    return (
        <div className="DBPrivilegesSelect">
            <Select
                style={{ minWidth: 200 }}
                placeholder="privileges"
                options={value?.includes('ALL PRIVILEGES') ? [] : options}
                mode="tags"
                filterOption={(inputValue, option) => {
                    if (!option?.value) {
                        return false;
                    }
                    return (
                        `${option.value}`
                            ?.toUpperCase()
                            .indexOf(inputValue.toUpperCase()) === 0 || false
                    );
                }}
                {...rest}
                value={[] as any}
            />
        </div>
    );
}

function getDatabasePrivileges(technology: CcClusterTechnology) {
    switch (technology) {
        case CcClusterTechnology.TECHNOLOGY_MYSQL:
            return [
                ...MYSQL_ADMIN_PRIVILEGES,
                ...MYSQL_DATA_PRIVILEGES,
                ...MYSQL_STRUCTURE_PRIVILEGES,
                ALL_PRIVILEGES,
                NO_PRIVILEGES,
            ];
        case CcClusterTechnology.TECHNOLOGY_POSTGRESQL:
            return [
                ...POSTGRESQL_DATA_PRIVILEGES,
                ...POSTGRESQL_DATABASE_PRIVILEGES,
                ...POSTGRESQL_USER_PRIVILEGES,
                ALL_PRIVILEGES,
            ];
        default:
            return [];
    }
}
