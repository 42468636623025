const mock = {
    class_name: 'CmonClusterInfo',
    cdt_path: '/',
    acl: 'user::rwx,group::rwx,other::---',
    tags: ['mysql', 'aws'],
    cluster_auto_recovery: false,
    cluster_id: 2,
    cluster_name: 'MySQL Replication',
    cluster_type: 'REPLICATION',
    configuration_file: '/etc/cmon.d/cmon_2.cnf',
    effective_privileges: 'rwx',
    log_file: '/var/log/cmon_2.log',
    maintenance_mode_active: false,
    managed: true,
    node_auto_recovery: false,
    rpc_version: '2.0',
    state: 'STARTED',
    status_text: 'All nodes are operational.',
    vendor: 'percona',
    version: '8.0',
    alarm_statistics: {
        class_name: 'CmonAlarmStatistics',
        cluster_id: 2,
        created_after: '1970-01-01T00:00:00.000Z',
        critical: 0,
        reported_after: '1970-01-01T00:00:00.000Z',
        warning: 0,
    },
    group_owner: {
        class_name: 'CmonGroup',
        cdt_path: '/groups',
        owner_user_id: 1,
        owner_user_name: 'system',
        owner_group_id: 1,
        owner_group_name: 'admins',
        acl: 'user::rwx,group::rwx,other::---',
        created: '2021-04-28T22:57:55.328Z',
        group_id: 1,
        group_name: 'admins',
    },
    hosts: [
        {
            class_name: 'CmonMySqlHost',
            cdt_path: '/MySQL Replication',
            owner_user_id: 1,
            owner_user_name: 'system',
            owner_group_id: 1,
            owner_group_name: 'admins',
            acl: 'user::rwx,group::rwx,other::---',
            clusterid: 2,
            configfile: ['/etc/mysql/my.cnf'],
            connected: true,
            container: true,
            datadir: '/var/lib/mysql/',
            description: ' -------/usr/bin/socat\r\n',
            errorcode: 0,
            errormsg: 'Up and running.',
            hostId: 9,
            hostname: 'c5',
            hoststatus: 'CmonHostOnline',
            ip: '10.0.8.15',
            isgalera: false,
            lastseen: 1628603020,
            log_bin: 'ON',
            log_bin_basename: '/var/lib/mysql/binlog',
            logfile: '/var/log/mysql/mysqld.log',
            maintenance_mode: 'none',
            maintenance_mode_active: false,
            message: 'Up and running.',
            mysqlstatus: 0,
            nodeid: 9,
            nodetype: 'mysql',
            performance_schema: false,
            pid: 510,
            pidfile: '/var/lib/mysql/mysql.pid',
            pingstatus: 1,
            pingtime: 73,
            port: 3306,
            readonly: true,
            role: 'slave',
            serverid: 2001,
            skip_name_resolve: false,
            slaves: [],
            socket: '/var/lib/mysql/mysql.sock',
            sshfailcount: 0,
            statereason: 'The server is working fine.',
            status: 0,
            stopped_at: '2021-06-14T10:15:43.233Z',
            super_read_only: true,
            timestamp: 1628603020,
            unique_id: 9,
            uptime: 951142,
            version: '8.0.22-13',
            version_comment:
                "Percona Server (GPL), Release '13', Revision '6f7822f'",
            wallclock: 1628603010,
            wallclocktimestamp: 1628603010,
            audit_log: {
                plugins: [
                    {
                        name: 'sha2_cache_cleaner',
                        author: 'Oracle Corporation',
                        status: 'ACTIVE',
                        version: '1.0',
                    },
                    {
                        name: 'mysqlx_cache_cleaner',
                        author: 'Oracle Corporation',
                        status: 'ACTIVE',
                        version: '1.0',
                    },
                ],
            },
            distribution: {
                name: 'ubuntu',
                codename: 'bionic',
                release: '18.04',
                type: 'debian',
            },
            replication_master: {
                binlog_do_db: '',
                binlog_ignore_db: '',
                exec_gtid: '55df3df0-a876-11eb-9d7d-00163e6306e9:1-10',
                file: 'binlog.000005',
                position: '156',
                semisync_status: 'OFF',
            },
            replication_slave: {
                exec_master_log_pos: '156',
                executed_gtid_set: '55df3df0-a876-11eb-9d7d-00163e6306e9:1-10',
                gtid_binlog_pos: '',
                gtid_io_pos: '',
                last_io_errno: '0',
                last_io_error: '',
                last_io_error_timestamp: '',
                last_sql_errno: '0',
                last_sql_error: '',
                last_sql_error_timestamp: '',
                linkstatus: 1,
                master_cluster_id: 2,
                master_host: 'c7',
                master_log_file: 'binlog.000016',
                master_port: '3306',
                master_server_id: '2003',
                master_uuid: 'dce1a2db-ccfc-11eb-8e79-00163e34596e',
                read_master_log_pos: '156',
                relay_master_log_file: 'binlog.000016',
                retrieved_gtid_set: '',
                seconds_behind_master: '0',
                semisync_status: 'OFF',
                slave_io_running: 'Yes',
                slave_io_state: 'Waiting for master to send event',
                slave_sql_running: 'Yes',
                slave_sql_state:
                    'Slave has read all relay log; waiting for more updates',
                sqldelay: 0,
                status:
                    'Waiting for master to send event<br/>Slave has read all relay log; waiting for more updates',
                using_gtid: 'ON',
            },
            slow_query: {
                log_not_using_idxs: 'OFF',
                long_query_time: '0.500000',
                slow_query_log: 'ON',
                slow_query_log_file: '/var/log/mysql/mysql-slow.log',
            },
            ssl_certs: {
                replication: {
                    ca: '',
                    id: 0,
                    key: '',
                    path: '',
                },
                server: {
                    ca: '/etc/mysql/certs/server_ca.crt',
                    id: 8,
                    key: '/etc/mysql/certs/server.key',
                    path: '/etc/mysql/certs/server.crt',
                    ssl_enabled: true,
                },
            },
        },
        {
            class_name: 'CmonMySqlHost',
            cdt_path: '/MySQL Replication',
            owner_user_id: 1,
            owner_user_name: 'system',
            owner_group_id: 1,
            owner_group_name: 'admins',
            acl: 'user::rwx,group::rwx,other::---',
            clusterid: 2,
            configfile: ['/etc/mysql/my.cnf'],
            connected: true,
            container: true,
            datadir: '/var/lib/mysql/',
            errorcode: 0,
            errormsg: 'Up and running.',
            hostId: 10,
            hostname: 'c6',
            hoststatus: 'CmonHostOnline',
            ip: '10.0.8.16',
            isgalera: false,
            lastseen: 1628603020,
            log_bin: 'ON',
            log_bin_basename: '/var/lib/mysql/binlog',
            logfile: '/var/log/mysql/mysqld.log',
            maintenance_mode: 'none',
            maintenance_mode_active: false,
            message: 'Up and running.',
            mysqlstatus: 0,
            nodeid: 10,
            nodetype: 'mysql',
            performance_schema: false,
            pid: 511,
            pidfile: '/var/lib/mysql/mysql.pid',
            pingstatus: 1,
            pingtime: 72,
            port: 3306,
            readonly: true,
            role: 'slave',
            serverid: 2002,
            skip_name_resolve: false,
            slaves: [],
            socket: '/var/lib/mysql/mysql.sock',
            sshfailcount: 0,
            statereason: 'The server is working fine.',
            status: 0,
            super_read_only: true,
            timestamp: 1628603020,
            unique_id: 10,
            uptime: 951140,
            version: '8.0.22-13',
            version_comment:
                "Percona Server (GPL), Release '13', Revision '6f7822f'",
            wallclock: 1628603010,
            wallclocktimestamp: 1628603010,
            audit_log: {
                plugins: [
                    {
                        name: 'sha2_cache_cleaner',
                        author: 'Oracle Corporation',
                        status: 'ACTIVE',
                        version: '1.0',
                    },
                    {
                        name: 'mysqlx_cache_cleaner',
                        author: 'Oracle Corporation',
                        status: 'ACTIVE',
                        version: '1.0',
                    },
                ],
            },
            distribution: {
                name: 'ubuntu',
                codename: 'bionic',
                release: '18.04',
                type: 'debian',
            },
            replication_master: {
                binlog_do_db: '',
                binlog_ignore_db: '',
                exec_gtid: '55df3df0-a876-11eb-9d7d-00163e6306e9:1-10',
                file: 'binlog.000003',
                position: '156',
                semisync_status: 'OFF',
            },
            replication_slave: {
                exec_master_log_pos: '156',
                executed_gtid_set: '55df3df0-a876-11eb-9d7d-00163e6306e9:1-10',
                gtid_binlog_pos: '',
                gtid_io_pos: '',
                last_io_errno: '0',
                last_io_error: '',
                last_io_error_timestamp: '',
                last_sql_errno: '0',
                last_sql_error: '',
                last_sql_error_timestamp: '',
                linkstatus: 1,
                master_cluster_id: 2,
                master_host: 'c7',
                master_log_file: 'binlog.000016',
                master_port: '3306',
                master_server_id: '2003',
                master_uuid: 'dce1a2db-ccfc-11eb-8e79-00163e34596e',
                read_master_log_pos: '156',
                relay_master_log_file: 'binlog.000016',
                retrieved_gtid_set: '',
                seconds_behind_master: '0',
                semisync_status: 'OFF',
                slave_io_running: 'Yes',
                slave_io_state: 'Waiting for master to send event',
                slave_sql_running: 'Yes',
                slave_sql_state:
                    'Slave has read all relay log; waiting for more updates',
                sqldelay: 0,
                status:
                    'Waiting for master to send event<br/>Slave has read all relay log; waiting for more updates',
                using_gtid: 'ON',
            },
            slow_query: {
                log_not_using_idxs: 'OFF',
                long_query_time: '0.500000',
                slow_query_log: 'ON',
                slow_query_log_file: '/var/log/mysql/mysql-slow.log',
            },
            ssl_certs: {
                replication: {
                    ca: '',
                    id: 0,
                    key: '',
                    path: '',
                },
                server: {
                    ca: '/etc/mysql/certs/server_ca.crt',
                    id: 8,
                    key: '/etc/mysql/certs/server.key',
                    path: '/etc/mysql/certs/server.crt',
                    ssl_enabled: true,
                },
            },
        },
        {
            class_name: 'CmonMySqlHost',
            cdt_path: '/MySQL Replication',
            owner_user_id: 1,
            owner_user_name: 'system',
            owner_group_id: 1,
            owner_group_name: 'admins',
            acl: 'user::rwx,group::rwx,other::---',
            clusterid: 2,
            configfile: ['/etc/mysql/my.cnf'],
            connected: true,
            container: true,
            datadir: '/var/lib/mysql/',
            description: ' -------/usr/bin/socat\r\n',
            errorcode: 0,
            errormsg: 'Up and running.',
            hostId: 11,
            hostname: 'c7',
            hoststatus: 'CmonHostOnline',
            ip: '10.0.8.17',
            isgalera: false,
            lastseen: 1628603020,
            log_bin: 'ON',
            log_bin_basename: '/var/lib/mysql/binlog',
            logfile: '/var/log/mysql/mysqld.log',
            maintenance_mode: 'none',
            maintenance_mode_active: false,
            message: 'Up and running.',
            mysqlstatus: 0,
            nodeid: 11,
            nodetype: 'mysql',
            performance_schema: false,
            pid: 514,
            pidfile: '/var/lib/mysql/mysql.pid',
            pingstatus: 1,
            pingtime: 56,
            port: 3306,
            readonly: false,
            role: 'master',
            serverid: 2003,
            skip_name_resolve: false,
            slaves: ['c6:3306', 'c5:3306'],
            socket: '/var/lib/mysql/mysql.sock',
            sshfailcount: 0,
            statereason: 'Master is working fine.',
            status: 0,
            stopped_at: '2021-06-01T23:12:21.002Z',
            super_read_only: false,
            timestamp: 1628603020,
            unique_id: 11,
            uptime: 951141,
            version: '8.0.22-13',
            version_comment:
                "Percona Server (GPL), Release '13', Revision '6f7822f'",
            wallclock: 1628603010,
            wallclocktimestamp: 1628603010,
            audit_log: {
                plugins: [
                    {
                        name: 'sha2_cache_cleaner',
                        author: 'Oracle Corporation',
                        status: 'ACTIVE',
                        version: '1.0',
                    },
                    {
                        name: 'mysqlx_cache_cleaner',
                        author: 'Oracle Corporation',
                        status: 'ACTIVE',
                        version: '1.0',
                    },
                ],
            },
            distribution: {
                name: 'ubuntu',
                codename: 'bionic',
                release: '18.04',
                type: 'debian',
            },
            replication_master: {
                binlog_do_db: '',
                binlog_ignore_db: '',
                exec_gtid: '55df3df0-a876-11eb-9d7d-00163e6306e9:1-10',
                file: 'binlog.000016',
                position: '156',
                semisync_status: 'OFF',
            },
            replication_slave: {
                exec_master_log_pos: 20792392,
                executed_gtid_set: '',
                gtid_binlog_pos: '',
                gtid_io_pos: '',
                last_io_errno: '',
                last_io_error: '',
                last_io_error_timestamp: '',
                last_sql_errno: '0',
                last_sql_error: '',
                last_sql_error_timestamp: '',
                linkstatus: 7,
                master_cluster_id: '-1',
                master_host: '',
                master_log_file: '',
                master_port: '',
                master_server_id: '2002',
                master_uuid: '051d59d8-a877-11eb-968c-00163ea6328b',
                read_master_log_pos: '0',
                relay_master_log_file: '',
                retrieved_gtid_set: '',
                seconds_behind_master: '',
                semisync_status: '',
                slave_io_running: '',
                slave_io_state: '',
                slave_sql_running: '',
                slave_sql_state: '',
                sqldelay: 0,
                status: '',
                using_gtid: '',
            },
            slow_query: {
                log_not_using_idxs: 'OFF',
                long_query_time: '0.500000',
                slow_query_log: 'ON',
                slow_query_log_file: '/var/log/mysql/mysql-slow.log',
            },
            ssl_certs: {
                replication: {
                    ca: '',
                    id: 0,
                    key: '',
                    path: '',
                },
                server: {
                    ca: '/etc/mysql/certs/server_ca.crt',
                    id: 8,
                    key: '/etc/mysql/certs/server.key',
                    path: '/etc/mysql/certs/server.crt',
                    ssl_enabled: true,
                },
            },
        },
        {
            class_name: 'CmonHost',
            cdt_path: '/MySQL Replication',
            owner_user_id: 1,
            owner_user_name: 'system',
            owner_group_id: 1,
            owner_group_name: 'admins',
            acl: 'user::rwx,group::rwx,other::---',
            clusterid: 2,
            configfile: '/etc/cmon.d/cmon_2.cnf',
            connected: true,
            container: false,
            hostId: 12,
            hostname: '143.198.228.20',
            hoststatus: 'CmonHostOnline',
            ip: '143.198.228.20',
            lastseen: 1628603009,
            logfile: '/var/log/cmon_2.log',
            maintenance_mode: 'none',
            maintenance_mode_active: false,
            message: 'Up and running.',
            nodetype: 'controller',
            pid: 15943,
            pingstatus: -1,
            pingtime: -1,
            port: 9500,
            role: 'controller',
            status: 10,
            timestamp: 1628603010,
            unique_id: 12,
            uptime: 5946,
            version: '1.9.1.4712',
            wallclock: 1628603010,
            wallclocktimestamp: 1628603010,
            distribution: {
                name: 'ubuntu',
                codename: 'bionic',
                release: '18.04',
                type: 'debian',
            },
        },
    ],
    job_statistics: {
        class_name: 'CmonJobStatistics',
        cluster_id: 2,
        by_state: {
            ABORTED: 1,
            DEFINED: 0,
            DEQUEUED: 0,
            FAILED: 0,
            FINISHED: 6,
            PAUSED: 1,
            RUNNING: 0,
            SCHEDULED: 2,
        },
    },
    owner: {
        class_name: 'CmonUser',
        cdt_path: '/',
        acl: 'user::rwx,group::r--,other::r--',
        disabled: false,
        suspended: false,
        groups: [],
        timezone: {
            class_name: 'CmonTimeZone',
            name: 'Coordinated Universal Time',
            abbreviation: 'UTC',
            offset: 0,
            use_dst: false,
        },
    },
};
export default mock;
