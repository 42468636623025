import React from 'react';
import { Button, Divider, Space } from 'antd';
import ButtonWithForm from '../common/General/ButtonWithForm';
import ClusterCloneModal from '../components/Clusters/Actions/ClusterCloneModal';
import CcCluster from '../services/models/CcCluster';
import galera from './dataMocks/clusters/galera';
import CmonDiscoveryService from '../services/cmon/CmonDiscoveryService';

export default DebugClusterCloneModal;

export type DebugClusterCloneModalProps = {};

const galeraCluster = new CcCluster(galera as any);
function DebugClusterCloneModal({}: DebugClusterCloneModalProps) {
    CmonDiscoveryService.checkHosts = async () => ({
        checked_hosts: [
            {
                status: {
                    error_code: 'HostIsOk',
                },
            },
        ] as any,
    });
    return (
        <Space direction="vertical" size={20}>
            <Space>
                <Divider
                    plain={true}
                    orientation="left"
                    style={{ marginBottom: '0px', marginTop: '15px' }}
                >
                    Default
                </Divider>
                <ButtonWithForm
                    button={<Button>Clone Cluster</Button>}
                    form={<ClusterCloneModal cluster={galeraCluster} />}
                />
            </Space>
        </Space>
    );
}
