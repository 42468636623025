import React from 'react';
import { Divider } from 'antd';
import RepositoryInput from '../common/DataEntry/RepositoryInput';

export default DebugRepositoryInput;

export type DebugRepositoryInputProps = {};

function DebugRepositoryInput() {
    const samples: any[] = [{ text: 'default' }];
    return (
        <div className="DebugRepositoryInput">
            <h1>RepositoryInput</h1>
            {samples.map((sample, index) => (
                <div key={index}>
                    <Divider
                        plain={true}
                        orientation="left"
                        style={{ marginBottom: '0px', marginTop: '15px' }}
                    >
                        {JSON.stringify(sample)}
                    </Divider>
                    <RepositoryInput {...sample} />
                </div>
            ))}
        </div>
    );
}
