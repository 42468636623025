import React from 'react';
import { Card, CardProps } from 'antd';
import './AppCard.less';
import classNames from 'classnames';

export default AppCard;

export type AppCardProps = CardProps & {};

function AppCard({ className, ...rest }: AppCardProps) {
    return <Card className={classNames('AppCard', className)} {...rest} />;
}
