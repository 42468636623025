import React from 'react';
import { Input, InputProps } from 'antd';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';

export default PasswordInput;

export type PasswordInputProps = InputProps & {
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

function PasswordInput({ onChange, ...rest }: PasswordInputProps) {
    const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        onChange?.(e);
    };
    return (
        <Input.Password
            iconRender={(visible) =>
                visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
            }
            onChange={handleOnChange}
            {...rest}
        />
    );
}
