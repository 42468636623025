import React from 'react';
import { Link, LinkProps, To } from 'react-router-dom';

export default ActivityCenterAlarmsLink;

export type ActivityCenterAlarmsLinkProps = Omit<LinkProps, 'to'> & {
    to?: To;
};

function ActivityCenterAlarmsLink({
    to = '/activity-center/alarms',
    ...rest
}: ActivityCenterAlarmsLinkProps) {
    return <Link to={to} {...rest} />;
}
