import './CloudCredentialsWizard.less';
import React, { useEffect, useState } from 'react';
import { CloudOutlined } from '@ant-design/icons';
import { FormInstance } from 'antd/lib/form';
import CloudCredentialsWizardForm from './CloudCredentialsWizardForm';
import WizardSelectTypeStep, {
    WizardSelectTypeStepItem,
} from '@severalnines/bar-frontend-components/build/lib/Navigation/Wizard/WizardSelectTypeStep';
import WizardConfiguration from '@severalnines/bar-frontend-components/build/lib/Navigation/Wizard/WizardConfiguration';
import CcCloudCredentials, {
    CcCloudCredentialsDTO,
    CcCloudProviderType,
} from '../../../services/models/CcCloudCredentials';
import SpaceWide from '../../../common/SpaceWide';
import { Button } from 'antd';

const CLOUD_SERVICES = [
    {
        name: 'Amazon Web Services',
        key: CcCloudProviderType.AWS,
        icon: 'img-logo-cloud-aws.svg',
        description:
            'Amazon Web Services provides a highly reliable, scalable, low-cost infrastructure platform in the cloud with data center locations in the U.S., Europe, Brazil, Singapore, Japan, and Australia.',
        documentationLink: getCloudCredentialsDocumentationLink(
            CcCloudProviderType.AWS
        ),
    },
    {
        name: 'Google Cloud',
        key: CcCloudProviderType.GOOGLE,
        icon: 'img-logo-cloud-google.svg',
        description:
            'Google Cloud is a suite of public cloud computing services offered by Google. The platform includes a range of hosted services for compute, storage and application development that run on Google hardware.',
        documentationLink: getCloudCredentialsDocumentationLink(
            CcCloudProviderType.GOOGLE
        ),
    },
    {
        name: 'Microsoft Azure',
        key: CcCloudProviderType.AZURE,
        icon: 'img-logo-cloud-azure.svg',
        description:
            'Microsoft Azure is a public cloud computing platform—with solutions including Infrastructure as a Service (IaaS), Platform as a Service (PaaS), and Software as a Service (SaaS) that can be used for services such as analytics, virtual computing, storage, networking, and much more.',
        documentationLink: getCloudCredentialsDocumentationLink(
            CcCloudProviderType.AZURE
        ),
    },
    {
        name: 'S3 Compatible storage provider',
        key: CcCloudProviderType.S3,
        description:
            'Upload backups to any AWS S3 compatible cloud storage provider by creating a custom S3 cloud storage credential.',
        documentationLink: getCloudCredentialsDocumentationLink(
            CcCloudProviderType.S3
        ),
    },
];

export default CloudCredentialsWizard;

export enum CloudCredentialsWizardStep {
    SERVICE = 'service',
    WIZARD = 'wizard',
}
export type CloudCredentialsWizardProps = {
    form: FormInstance;
    activeStep?: CloudCredentialsWizardStep;
    credentials?: CcCloudCredentials;
    onServiceTypeChange?: (type: CcCloudProviderType) => void;
    onSubmit?: (dto: CcCloudCredentialsDTO) => void;
    onCancel?: () => void;
    onTouchedChange?: (touched: boolean) => void;
    loading?: boolean;
};

function CloudCredentialsWizard({
    form,
    activeStep = CloudCredentialsWizardStep.SERVICE,
    credentials,
    onServiceTypeChange,
    onSubmit,
    onCancel,
    onTouchedChange,
    loading = false,
}: CloudCredentialsWizardProps) {
    const [step, setStep] = useState<CloudCredentialsWizardStep>(activeStep);
    const [serviceType, setServiceType] = useState<
        CcCloudProviderType | undefined
    >(credentials?.provider);

    const handleCloudProviderSelect = ({ key }: WizardSelectTypeStepItem) => {
        setServiceType(key as CcCloudProviderType);
        onServiceTypeChange?.(key as CcCloudProviderType);
    };
    const handleCloudProviderConfirm = ({ key }: WizardSelectTypeStepItem) => {
        setServiceType(key as CcCloudProviderType);
        setStep(CloudCredentialsWizardStep.WIZARD);
    };
    const handleFormCancel = () => {
        if (credentials) {
            onCancel?.();
        } else {
            setStep(CloudCredentialsWizardStep.SERVICE);
        }
    };

    const handleWizardCancel = () => {
        onCancel?.();
    };

    useEffect(() => {
        if (credentials?.provider) {
            setServiceType(credentials.provider);
            setStep(CloudCredentialsWizardStep.WIZARD);
        }
    }, [credentials]);

    return (
        <WizardConfiguration
            activeStep={step}
            steps={[
                {
                    step: CloudCredentialsWizardStep.SERVICE,
                    className: 'CloudCredentialsWizard_select-service',
                    content: (
                        <WizardSelectTypeStep
                            onSelect={handleCloudProviderSelect}
                            onConfirm={handleCloudProviderConfirm}
                            footer={
                                <SpaceWide justify="flex-end">
                                    <Button onClick={handleWizardCancel}>
                                        Cancel
                                    </Button>
                                </SpaceWide>
                            }
                            items={CLOUD_SERVICES.map((item) => ({
                                ...item,
                                value: item.key,
                                disabled: false,
                            }))}
                            emptyState={
                                <WizardSelectTypeStep.DetailEmptyState
                                    src={require('./img-cloud-service-zero-state.svg')}
                                    description="Choose a cloud service provider on your left"
                                />
                            }
                            defaultIconComponent={CloudOutlined}
                        />
                    ),
                },
                {
                    step: CloudCredentialsWizardStep.WIZARD,
                    content: (
                        <CloudCredentialsWizardForm
                            form={form}
                            onCancel={handleFormCancel}
                            defaultInitialValues={{}}
                            serviceType={serviceType as any}
                            credentials={credentials}
                            saveMode={!!credentials}
                            onSubmit={onSubmit}
                            loading={loading}
                            onTouchedChange={onTouchedChange}
                        />
                    ),
                },
            ]}
        />
    );
}

export function getCloudServiceName(type: CcCloudProviderType) {
    return CLOUD_SERVICES.find(({ key }) => key === type)?.name;
}

export function getCloudCredentialsDocumentationLink(
    type: CcCloudProviderType
) {
    switch (type) {
        case CcCloudProviderType.AWS:
        case CcCloudProviderType.S3:
            return 'https://docs.severalnines.com/docs/clustercontrol/user-guide-gui/sidebar-2/integrations/#amazon-web-services-credential';
        case CcCloudProviderType.GOOGLE:
            return 'https://docs.severalnines.com/docs/clustercontrol/user-guide-gui/sidebar-2/integrations/#google-cloud-platform-credentials';
        case CcCloudProviderType.AZURE:
            return 'https://docs.severalnines.com/docs/clustercontrol/user-guide-gui/sidebar-2/integrations/#microsoft-azure-credentials';
    }
}
