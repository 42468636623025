import CcNode from './CcNode';

export interface CcTransactionLogEntryProps {
    blocked_by_trx_id?: string;
    db?: string;
    duration?: string;
    host?: string;
    hostId?: number;
    info?: string;
    innodb_trx_id?: string;
    instance?: string;
    internal_trx_id?: string;
    lastseen?: number;
    message?: string;
    mysql_trx_id?: string;
    port?: number;
    sql?: string;
    state?: string;
    user?: string;
    node?: CcNode;
}

export default class CcTransactionLogEntry {
    blockedByTrxId?: string;
    db?: string;
    duration?: string;
    host?: string;
    hostId?: number;
    info?: string;
    innodbTrxId?: string;
    instance?: string;
    internalTrxId?: string;
    lastseen?: number;
    message?: string;
    mysqlTrxId?: string;
    port?: number;
    sql?: string;
    state?: string;
    user?: string;
    node?: CcNode;

    constructor(props: CcTransactionLogEntryProps) {
        this.blockedByTrxId = props.blocked_by_trx_id;
        this.db = props.db;
        this.duration = props.duration;
        this.host = props.host;
        this.hostId = props.hostId;
        this.info = props.info;
        this.innodbTrxId = props.innodb_trx_id;
        this.instance = props.instance;
        this.internalTrxId = props.internal_trx_id;
        this.lastseen = props.lastseen;
        this.message = props.message;
        this.mysqlTrxId = props.mysql_trx_id;
        this.port = props.port;
        this.sql = props.sql;
        this.state = props.state;
        this.user = props.user;
        this.node = props.node;
    }
}
