import CcNode, {
    CcNodeRole,
    CcNodeType,
    getNodeDistributionString,
} from '../../../services/models/CcNode';
import CcCluster from '../../../services/models/CcCluster';
import { getNodeStatusFormatType } from '../../Nodes/NodeStatusFormat';
import { IDiagramItem } from '@severalnines/bar-frontend-components/build/lib/DataDisplay/Diagram';
import { formatDuration } from '@severalnines/bar-frontend-components/build/lib/Format/DurationFormat';
import AppDateFormat from '../../../common/AppDateFormat';
import TruncateText from '@severalnines/bar-frontend-components/build/lib/Format/TruncateText';

export interface ClusterTopologyCardField {
    label?: string;
    value?: React.ReactNode | string | number | null;
}
export type ClusterTopologyCardFieldsRow = ClusterTopologyCardField[];
export type ClusterTopologyCard = ClusterTopologyCardFieldsRow[];
export default class ClusterTopologyConfigurator {
    public items: IDiagramItem[];
    public groups: { [key: string]: IDiagramItem };
    public hasDbNodes: boolean;
    public hasLoadBalancers: boolean;
    public clusters: CcCluster[];

    constructor(clusters: CcCluster[]) {
        this.items = [];
        this.groups = {};
        this.hasDbNodes = false;
        this.hasLoadBalancers = false;
        this.clusters = clusters;
    }

    filterNodes(nodes: CcNode[]) {
        return nodes.filter(
            (n) =>
                ![
                    CcNodeType.CONTROLLER,
                    CcNodeType.PROMETHEUS,
                    CcNodeType.REDIS_SENTINEL,
                    CcNodeType.PGBACKREST,
                    CcNodeType.PBM_AGENT,
                    CcNodeType.CMON_AGENT,
                ].includes(n.nodetype as CcNodeType)
        );
    }

    getDiagramItems(cluster: CcCluster): IDiagramItem[] {
        return this.filterNodes(cluster.nodes)
            .map((n) =>
                this.nodeToItem(n, cluster, this.getDefaultItem(n, cluster))
            )
            .concat(this.getFixedGroups(cluster));
    }

    nodeToItem(node: CcNode, cluster: CcCluster, item: any) {
        let result = item;
        if (node.isLoadBalancer() || node.isType(CcNodeType.KEEPALIVED)) {
            this.hasLoadBalancers = true;
            result = this.enhanceItemForLoadBalancers(result);
        } else if (node.isRole(CcNodeRole.BVS)) {
            result.from = this.getCustomGroup('bvs', 'Backup Verification');
        } else {
            result.rack = node.nodetype;
        }
        return result;
    }

    prepareGroups(cluster?: CcCluster) {
        if (this.hasDbNodes) {
            this.groups.db = {
                key: 'db',
                type: 'group',
                placement: 'out',
                title: 'DB Nodes',
            };
        }
        if (this.hasLoadBalancers) {
            this.groups.lb = {
                key: 'lb',
                type: 'group',
                title: 'Load Balancers',
            };
            this.groups.db && (this.groups.db.from = 'lb');
        }

        // Cluster 2 Cluster replication check secondary and create primary cluster group
        if (cluster && cluster.replicationPrimaries.length > 0) {
            // we get only first replication info for now
            if (cluster.replicationSecondaries.length === 0) {
                // we show cluster as secondary group only if the cluster does not have secondaries, for now
                const primaryCluster = cluster.replicationPrimaries[0];
                const isBidirectional = cluster.isReplicationBidirectional(
                    primaryCluster
                );
                this.groups[`primary-${primaryCluster.clusterId}`] = {
                    key: `primary-${primaryCluster.clusterId}`,
                    status: 'success',
                    data: {
                        cluster: primaryCluster,
                    },
                    title: primaryCluster.clusterName,
                    arrow: isBidirectional ? 'both' : 'right',
                    type: 'group',
                };
                if (this.hasLoadBalancers) {
                    this.groups.clusterGroup = {
                        key: 'clusterGroup',
                        type: 'group',
                        title: cluster.clusterName,
                        from: `primary-${primaryCluster.clusterId}`,
                        placement: 'bottom',
                        arrow: isBidirectional ? 'both' : 'right',
                    };
                    this.groups.lb.from = `clusterGroup`;
                } else {
                    this.groups.db.from = `primary-${primaryCluster.clusterId}`;
                    this.groups.db.placement = 'bottom';
                    this.groups.db.arrow = isBidirectional ? 'both' : 'right';
                }
            }
        }

        // Cluster 2 Cluster replication check secondaries and create secondaries groups
        if (cluster && cluster.replicationSecondaries.length > 0) {
            cluster.replicationSecondaries.forEach((secondaryCluster) => {
                const isBidirectional = cluster.isReplicationBidirectional(
                    secondaryCluster
                );
                this.groups[`secondary-${secondaryCluster.clusterId}`] = {
                    key: `secondary-${secondaryCluster.clusterId}`,
                    status: 'success',
                    data: {
                        cluster: secondaryCluster,
                    },
                    title: secondaryCluster.clusterName,
                    from: 'db',
                    placement: 'bottom',
                    arrow: isBidirectional ? 'both' : 'right',
                    type: 'group',
                };
            });
        }
    }

    getFixedGroups(cluster?: CcCluster) {
        this.prepareGroups(cluster);
        return Object.values(this.groups);
    }

    getDefaultItem(node: CcNode, cluster?: CcCluster) {
        return {
            key: node.getKey(),
            status: getNodeStatusFormatType(node.hoststatus),
            data: {
                node,
                cluster,
                // nodeLink: getNodeHref(node)
            },
            from: node.isDatabaseNode() ? 'db' : null,
        };
    }

    getCustomGroup(key: string, title: string) {
        if (!this.groups[key]) {
            this.groups[key] = { key, type: 'group', title };
        }
        return key;
    }

    enhanceItemForPrimaries(item: IDiagramItem) {
        item.from = 'db';
        item.rack = 'primaries';
        return item;
    }

    enhanceItemForLoadBalancers(item: IDiagramItem, node?: CcNode) {
        if (node) {
            const hostnameGroupKey = node.hostname?.replace(
                /\./g,
                '_'
            ) as string;
            if (!this.groups[hostnameGroupKey]) {
                this.groups[hostnameGroupKey] = {
                    key: hostnameGroupKey,
                    type: 'group',
                    from: 'lb',
                };
            }
            item.from = hostnameGroupKey;
        } else {
            item.from = 'lb';
        }
        return item;
    }
    getNodeCoverCardConfig(node: CcNode): ClusterTopologyCard {
        return [[{ label: 'Version', value: `${node.version}` }]];
    }
    getNodeExtraCardsConfig(node: CcNode): ClusterTopologyCard[] {
        return [
            [
                [
                    {
                        label: 'Version',
                        value: (
                            <TruncateText
                                text={node.version as string}
                                maxLength={33}
                            />
                        ),
                    },
                ],
                [
                    {
                        label: 'Uptime',
                        value:
                            node.uptime &&
                            formatDuration(node.uptime * 1000, {}),
                    },
                ],
                [
                    {
                        label: 'OS',
                        value: (
                            <TruncateText
                                text={getNodeDistributionString(node) as string}
                                maxLength={33}
                            />
                        ),
                    },
                ],
                [
                    {
                        label: 'Last seen',
                        value: node.lastseen ? (
                            <AppDateFormat fromNow>
                                {new Date(node.lastseen * 1000)}
                            </AppDateFormat>
                        ) : null,
                    },
                    {
                        label: 'Ping',
                        value: ![undefined, null, '', 0, -1].includes(
                            node.pingtime
                        ) ? (
                            <span>{node.pingtime} &#181;s</span>
                        ) : null,
                    },
                ],
            ],
        ];
    }
}
