import { MongoShardsFormValues } from '../../MongoShards/MongoShardsConfigurator';
import merge from 'deepmerge';

import React from 'react';
import MongoImportConfigurator from '../Mongo/MongoImportConfigurator';
import MongoConfigurator, {
    MongoFormValues,
} from '../../Mongo/MongoConfigurator';
import { TopologyItem } from '../../../../Topology/TopologyItem';
import { FormInstance } from 'antd/lib/form';
import ClusterNodesForm from '../../ClusterNodesForm';
import { Alert } from 'antd';

export default class MongoShardsImportConfigurator extends MongoImportConfigurator {
    public static getJobData(formValues: MongoFormValues): any {
        const { topology, nodeConfig, topologyDataIps } = formValues;
        return merge(MongoConfigurator.getJobData(formValues), {
            replica_sets: undefined,
            server_address: undefined,
            mongos_servers: topology.map((currentNode: TopologyItem) => ({
                port: nodeConfig.serverPort,
                hostname: currentNode.extraData.hostname,
                hostname_data: currentNode.extraData.hostname,
                hostname_internal:
                    topologyDataIps?.[currentNode.extraData.hostname] || '',
            })),
        });
    }

    public static getJobOptions(formValues: MongoShardsFormValues): any {
        return merge(MongoImportConfigurator.getJobOptions(formValues), {
            job: {
                title: 'Import Mongo Shards Cluster',
            },
        });
    }

    public static getTopologyStep(form: FormInstance): React.ReactNode {
        return (
            <div>
                <ClusterNodesForm
                    form={form}
                    title="Router/Mongos servers"
                    nodesInputProps={{
                        onlyPrimaries: true,
                        formProps: {
                            primaryTitle: 'Node router server',
                            primaryInfo:
                                'Router/Mongos are responsible for routing queries and write operations to shards in a sharded cluster',
                        },
                        mutateItem: (item) => ({
                            ...item,
                            description: 'Router/Mongos',
                        }),
                    }}
                />
                <Alert
                    message="Specify only the MongoDB router servers (mongos) and ClusterControl will discover the rest of the config and shards members."
                    type="info"
                    showIcon={true}
                />
            </div>
        );
    }
}
