


export interface CmonParagraphProps  {

}



export default class CmonParagraph {


    constructor(props: CmonParagraphProps) {

    }

}
