import React, { useMemo } from 'react';
import WizardSelectCardStep from '@severalnines/bar-frontend-components/build/lib/Navigation/Wizard/WizardSelectCardStep';
import { UserAddOutlined, UsergroupAddOutlined } from '@ant-design/icons';
import WizardConfigurationModal, {
    WizardConfigurationModalOption,
    WizardConfigurationModalProps,
} from '../../common/Navigation/WizardConfigurationModal';
import UserCreateWizardForm from './UserCreateWizardForm';
import GroupCreateWizardForm from './GroupCreateWizardForm';

export default UserGroupCreateForm;

export enum UserGroupCreateOptionType {
    USER = 'user',
    GROUP = 'group',
}

export type UserGroupCreateFormProps = {
    wizardProps?: WizardConfigurationModalProps;
    onSuccess?: (option: WizardConfigurationModalOption | undefined) => void;
    onCancel?: () => void;
};

/**
 * @code-example usage of WizardConfigurationModal
 */
function UserGroupCreateForm({
    onSuccess,
    onCancel,
    wizardProps,
}: UserGroupCreateFormProps) {
    const options: WizardConfigurationModalOption<
        UserGroupCreateOptionType
    >[] = useMemo(() => {
        const items: WizardConfigurationModalOption<
            UserGroupCreateOptionType
        >[] = [
            {
                key: UserGroupCreateOptionType.USER,
                item: (
                    <WizardSelectCardStep.Item
                        key={UserGroupCreateOptionType.USER}
                        icon={<UserAddOutlined />}
                        title={getUserGroupCreateOptionTypeTitle(
                            UserGroupCreateOptionType.USER
                        )}
                        action={UserGroupCreateOptionType.USER}
                        description={getUserGroupCreateOptionTypeDescription(
                            UserGroupCreateOptionType.USER
                        )}
                        buttonTitle="Create"
                    />
                ),
                step: {
                    step: UserGroupCreateOptionType.USER,
                    title: getUserGroupCreateOptionTypeTitle(
                        UserGroupCreateOptionType.USER
                    ),
                    content: <UserCreateWizardForm />,
                },
            },
            {
                key: UserGroupCreateOptionType.GROUP,
                item: (
                    <WizardSelectCardStep.Item
                        key={UserGroupCreateOptionType.GROUP}
                        icon={<UsergroupAddOutlined />}
                        title={getUserGroupCreateOptionTypeTitle(
                            UserGroupCreateOptionType.GROUP
                        )}
                        action={UserGroupCreateOptionType.GROUP}
                        description={getUserGroupCreateOptionTypeDescription(
                            UserGroupCreateOptionType.GROUP
                        )}
                        buttonTitle="Create"
                    />
                ),
                step: {
                    step: UserGroupCreateOptionType.GROUP,
                    title: getUserGroupCreateOptionTypeTitle(
                        UserGroupCreateOptionType.GROUP
                    ),
                    content: <GroupCreateWizardForm />,
                },
            },
        ];

        return items;
    }, []);

    return (
        <WizardConfigurationModal
            title="Create user or team"
            description="Create new users and group them together for better management."
            options={options}
            onSuccess={onSuccess}
            onCancel={onCancel}
            {...wizardProps}
        />
    );
}

export function getUserGroupCreateOptionTypeTitle(
    type: UserGroupCreateOptionType
) {
    switch (type) {
        case UserGroupCreateOptionType.USER:
            return 'Create user';
        case UserGroupCreateOptionType.GROUP:
            return 'Create team';
        default:
            return '';
    }
}
export function getUserGroupCreateOptionTypeDescription(
    type: UserGroupCreateOptionType
) {
    switch (type) {
        case UserGroupCreateOptionType.USER:
            return (
                <ul>
                    <li>Create a new user</li>
                    <li>Assign it to a team</li>
                </ul>
            );
        case UserGroupCreateOptionType.GROUP:
            return (
                <ul>
                    <li>Manage large amount of users at ones</li>
                    <li>Easily assign permissions for all users in the team</li>
                </ul>
            );
        default:
            return '';
    }
}
