import { Col, Form, Input, Select, Space } from 'antd';
import InfoIcon from '@severalnines/bar-frontend-components/build/lib/General/InfoIcon';
import TypographyText from '../../../../common/TypographyText';
import React from 'react';
import { AppState, AppStateTags } from '../../../../appReducer';
import { useSelector } from 'react-redux';

export default ClusterNameAndTags;

export type ClusterNameAndTagsProps = {
    fullWidth?: boolean;
    optionalName?: boolean;
};

export type ClusterNameAndTagsValues = {
    details: {
        name: string;
        tags: string[];
    };
};
function ClusterNameAndTags({
    fullWidth,
    optionalName = true,
}: ClusterNameAndTagsProps) {
    const [tags]: [AppStateTags] = useSelector(({ tags }: AppState) => [tags]);

    const md = fullWidth ? 24 : 16;
    return (
        <>
            <Col span={24}>
                <h3>Name your cluster</h3>
            </Col>
            <Col xs={24} sm={24} md={md}>
                <Form.Item
                    name={['details', 'name']}
                    label={
                        <Space>
                            Name
                            <InfoIcon info="Valid characters are A-Z a-z 0-9 . - _ <space>" />
                            {optionalName && (
                                <TypographyText muted>
                                    (optional)
                                </TypographyText>
                            )}
                        </Space>
                    }
                    {...(optionalName
                        ? {
                              extra:
                                  'Leave empty and we will generate one for you.',
                          }
                        : {
                              rules: [
                                  {
                                      required: true,
                                      message:
                                          'Please enter a name for the cluster',
                                  },
                              ],
                          })}
                >
                    <Input placeholder="Enter a name" />
                </Form.Item>
            </Col>

            <Col xs={24} sm={24} md={md}>
                <Form.Item
                    name={['details', 'tags']}
                    label="Tags"
                    extra="Add tags to search or group your database clusters"
                >
                    <Select
                        mode="tags"
                        allowClear
                        placeholder="Type to add tags"
                    >
                        {tags &&
                            tags.map((o) => (
                                <Select.Option key={o} value={o}>
                                    {o}
                                </Select.Option>
                            ))}
                    </Select>
                </Form.Item>
            </Col>
        </>
    );
}
