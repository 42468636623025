const result = {
    controller_id: 'adc0fa9f-6dcf-473d-9652-87ac9a59e2d0',
    request_processed: '2022-08-04T04:54:02.577Z',
    request_status: 'Ok',
    request_user_id: 5,
    debug_messages: ["RPC V2 authenticated user is 'alex'."],
    results: {
        fileName: '/s9s/mysql/widgets/schema/show_tables.js',
        status: 'Ended',
        exitStatus: {
            tables: [
                {
                    name: 'columns_priv',
                    hostname: '10.0.8.13',
                    port: 3306,
                    schema: 'mysql',
                },
                {
                    name: 'component',
                    hostname: '10.0.8.13',
                    port: 3306,
                    schema: 'mysql',
                },
                {
                    name: 'db',
                    hostname: '10.0.8.13',
                    port: 3306,
                    schema: 'mysql',
                },
                {
                    name: 'default_roles',
                    hostname: '10.0.8.13',
                    port: 3306,
                    schema: 'mysql',
                },
                {
                    name: 'engine_cost',
                    hostname: '10.0.8.13',
                    port: 3306,
                    schema: 'mysql',
                },
                {
                    name: 'func',
                    hostname: '10.0.8.13',
                    port: 3306,
                    schema: 'mysql',
                },
                {
                    name: 'general_log',
                    hostname: '10.0.8.13',
                    port: 3306,
                    schema: 'mysql',
                },
                {
                    name: 'global_grants',
                    hostname: '10.0.8.13',
                    port: 3306,
                    schema: 'mysql',
                },
                {
                    name: 'gtid_executed',
                    hostname: '10.0.8.13',
                    port: 3306,
                    schema: 'mysql',
                },
                {
                    name: 'help_category',
                    hostname: '10.0.8.13',
                    port: 3306,
                    schema: 'mysql',
                },
                {
                    name: 'help_keyword',
                    hostname: '10.0.8.13',
                    port: 3306,
                    schema: 'mysql',
                },
                {
                    name: 'help_relation',
                    hostname: '10.0.8.13',
                    port: 3306,
                    schema: 'mysql',
                },
                {
                    name: 'help_topic',
                    hostname: '10.0.8.13',
                    port: 3306,
                    schema: 'mysql',
                },
                {
                    name: 'innodb_index_stats',
                    hostname: '10.0.8.13',
                    port: 3306,
                    schema: 'mysql',
                },
                {
                    name: 'innodb_table_stats',
                    hostname: '10.0.8.13',
                    port: 3306,
                    schema: 'mysql',
                },
                {
                    name: 'password_history',
                    hostname: '10.0.8.13',
                    port: 3306,
                    schema: 'mysql',
                },
                {
                    name: 'plugin',
                    hostname: '10.0.8.13',
                    port: 3306,
                    schema: 'mysql',
                },
                {
                    name: 'procs_priv',
                    hostname: '10.0.8.13',
                    port: 3306,
                    schema: 'mysql',
                },
                {
                    name: 'proxies_priv',
                    hostname: '10.0.8.13',
                    port: 3306,
                    schema: 'mysql',
                },
                {
                    name: 'replication_asynchronous_connection_failover',
                    hostname: '10.0.8.13',
                    port: 3306,
                    schema: 'mysql',
                },
                {
                    name: 'replication_asynchronous_connection_failover_managed',
                    hostname: '10.0.8.13',
                    port: 3306,
                    schema: 'mysql',
                },
                {
                    name: 'replication_group_configuration_version',
                    hostname: '10.0.8.13',
                    port: 3306,
                    schema: 'mysql',
                },
                {
                    name: 'replication_group_member_actions',
                    hostname: '10.0.8.13',
                    port: 3306,
                    schema: 'mysql',
                },
                {
                    name: 'role_edges',
                    hostname: '10.0.8.13',
                    port: 3306,
                    schema: 'mysql',
                },
                {
                    name: 'server_cost',
                    hostname: '10.0.8.13',
                    port: 3306,
                    schema: 'mysql',
                },
                {
                    name: 'servers',
                    hostname: '10.0.8.13',
                    port: 3306,
                    schema: 'mysql',
                },
                {
                    name: 'slave_master_info',
                    hostname: '10.0.8.13',
                    port: 3306,
                    schema: 'mysql',
                },
                {
                    name: 'slave_relay_log_info',
                    hostname: '10.0.8.13',
                    port: 3306,
                    schema: 'mysql',
                },
                {
                    name: 'slave_worker_info',
                    hostname: '10.0.8.13',
                    port: 3306,
                    schema: 'mysql',
                },
                {
                    name: 'slow_log',
                    hostname: '10.0.8.13',
                    port: 3306,
                    schema: 'mysql',
                },
                {
                    name: 'tables_priv',
                    hostname: '10.0.8.13',
                    port: 3306,
                    schema: 'mysql',
                },
                {
                    name: 'time_zone',
                    hostname: '10.0.8.13',
                    port: 3306,
                    schema: 'mysql',
                },
                {
                    name: 'time_zone_leap_second',
                    hostname: '10.0.8.13',
                    port: 3306,
                    schema: 'mysql',
                },
                {
                    name: 'time_zone_name',
                    hostname: '10.0.8.13',
                    port: 3306,
                    schema: 'mysql',
                },
                {
                    name: 'time_zone_transition',
                    hostname: '10.0.8.13',
                    port: 3306,
                    schema: 'mysql',
                },
                {
                    name: 'time_zone_transition_type',
                    hostname: '10.0.8.13',
                    port: 3306,
                    schema: 'mysql',
                },
                {
                    name: 'user',
                    hostname: '10.0.8.13',
                    port: 3306,
                    schema: 'mysql',
                },
                {
                    name: 'wsrep_cluster',
                    hostname: '10.0.8.13',
                    port: 3306,
                    schema: 'mysql',
                },
                {
                    name: 'wsrep_cluster_members',
                    hostname: '10.0.8.13',
                    port: 3306,
                    schema: 'mysql',
                },
                {
                    name: 'wsrep_streaming_log',
                    hostname: '10.0.8.13',
                    port: 3306,
                    schema: 'mysql',
                },
            ],
        },
        messages: [
            {
                fileName: '/s9s/mysql/widgets/schema/show_tables.js',
                lineNumber: 67,
                message: '<tr><td>columns_priv&nbsp;&nbsp;</td></td></tr>',
            },
            {
                fileName: '/s9s/mysql/widgets/schema/show_tables.js',
                lineNumber: 67,
                message: '<tr><td>component&nbsp;&nbsp;</td></td></tr>',
            },
            {
                fileName: '/s9s/mysql/widgets/schema/show_tables.js',
                lineNumber: 67,
                message: '<tr><td>db&nbsp;&nbsp;</td></td></tr>',
            },
            {
                fileName: '/s9s/mysql/widgets/schema/show_tables.js',
                lineNumber: 67,
                message: '<tr><td>default_roles&nbsp;&nbsp;</td></td></tr>',
            },
            {
                fileName: '/s9s/mysql/widgets/schema/show_tables.js',
                lineNumber: 67,
                message: '<tr><td>engine_cost&nbsp;&nbsp;</td></td></tr>',
            },
            {
                fileName: '/s9s/mysql/widgets/schema/show_tables.js',
                lineNumber: 67,
                message: '<tr><td>func&nbsp;&nbsp;</td></td></tr>',
            },
            {
                fileName: '/s9s/mysql/widgets/schema/show_tables.js',
                lineNumber: 67,
                message: '<tr><td>general_log&nbsp;&nbsp;</td></td></tr>',
            },
            {
                fileName: '/s9s/mysql/widgets/schema/show_tables.js',
                lineNumber: 67,
                message: '<tr><td>global_grants&nbsp;&nbsp;</td></td></tr>',
            },
            {
                fileName: '/s9s/mysql/widgets/schema/show_tables.js',
                lineNumber: 67,
                message: '<tr><td>gtid_executed&nbsp;&nbsp;</td></td></tr>',
            },
            {
                fileName: '/s9s/mysql/widgets/schema/show_tables.js',
                lineNumber: 67,
                message: '<tr><td>help_category&nbsp;&nbsp;</td></td></tr>',
            },
            {
                fileName: '/s9s/mysql/widgets/schema/show_tables.js',
                lineNumber: 67,
                message: '<tr><td>help_keyword&nbsp;&nbsp;</td></td></tr>',
            },
            {
                fileName: '/s9s/mysql/widgets/schema/show_tables.js',
                lineNumber: 67,
                message: '<tr><td>help_relation&nbsp;&nbsp;</td></td></tr>',
            },
            {
                fileName: '/s9s/mysql/widgets/schema/show_tables.js',
                lineNumber: 67,
                message: '<tr><td>help_topic&nbsp;&nbsp;</td></td></tr>',
            },
            {
                fileName: '/s9s/mysql/widgets/schema/show_tables.js',
                lineNumber: 67,
                message:
                    '<tr><td>innodb_index_stats&nbsp;&nbsp;</td></td></tr>',
            },
            {
                fileName: '/s9s/mysql/widgets/schema/show_tables.js',
                lineNumber: 67,
                message:
                    '<tr><td>innodb_table_stats&nbsp;&nbsp;</td></td></tr>',
            },
            {
                fileName: '/s9s/mysql/widgets/schema/show_tables.js',
                lineNumber: 67,
                message: '<tr><td>password_history&nbsp;&nbsp;</td></td></tr>',
            },
            {
                fileName: '/s9s/mysql/widgets/schema/show_tables.js',
                lineNumber: 67,
                message: '<tr><td>plugin&nbsp;&nbsp;</td></td></tr>',
            },
            {
                fileName: '/s9s/mysql/widgets/schema/show_tables.js',
                lineNumber: 67,
                message: '<tr><td>procs_priv&nbsp;&nbsp;</td></td></tr>',
            },
            {
                fileName: '/s9s/mysql/widgets/schema/show_tables.js',
                lineNumber: 67,
                message: '<tr><td>proxies_priv&nbsp;&nbsp;</td></td></tr>',
            },
            {
                fileName: '/s9s/mysql/widgets/schema/show_tables.js',
                lineNumber: 67,
                message:
                    '<tr><td>replication_asynchronous_connection_failover&nbsp;&nbsp;</td></td></tr>',
            },
            {
                fileName: '/s9s/mysql/widgets/schema/show_tables.js',
                lineNumber: 67,
                message:
                    '<tr><td>replication_asynchronous_connection_failover_managed&nbsp;&nbsp;</td></td></tr>',
            },
            {
                fileName: '/s9s/mysql/widgets/schema/show_tables.js',
                lineNumber: 67,
                message:
                    '<tr><td>replication_group_configuration_version&nbsp;&nbsp;</td></td></tr>',
            },
            {
                fileName: '/s9s/mysql/widgets/schema/show_tables.js',
                lineNumber: 67,
                message:
                    '<tr><td>replication_group_member_actions&nbsp;&nbsp;</td></td></tr>',
            },
            {
                fileName: '/s9s/mysql/widgets/schema/show_tables.js',
                lineNumber: 67,
                message: '<tr><td>role_edges&nbsp;&nbsp;</td></td></tr>',
            },
            {
                fileName: '/s9s/mysql/widgets/schema/show_tables.js',
                lineNumber: 67,
                message: '<tr><td>server_cost&nbsp;&nbsp;</td></td></tr>',
            },
            {
                fileName: '/s9s/mysql/widgets/schema/show_tables.js',
                lineNumber: 67,
                message: '<tr><td>servers&nbsp;&nbsp;</td></td></tr>',
            },
            {
                fileName: '/s9s/mysql/widgets/schema/show_tables.js',
                lineNumber: 67,
                message: '<tr><td>slave_master_info&nbsp;&nbsp;</td></td></tr>',
            },
            {
                fileName: '/s9s/mysql/widgets/schema/show_tables.js',
                lineNumber: 67,
                message:
                    '<tr><td>slave_relay_log_info&nbsp;&nbsp;</td></td></tr>',
            },
            {
                fileName: '/s9s/mysql/widgets/schema/show_tables.js',
                lineNumber: 67,
                message: '<tr><td>slave_worker_info&nbsp;&nbsp;</td></td></tr>',
            },
            {
                fileName: '/s9s/mysql/widgets/schema/show_tables.js',
                lineNumber: 67,
                message: '<tr><td>slow_log&nbsp;&nbsp;</td></td></tr>',
            },
            {
                fileName: '/s9s/mysql/widgets/schema/show_tables.js',
                lineNumber: 67,
                message: '<tr><td>tables_priv&nbsp;&nbsp;</td></td></tr>',
            },
            {
                fileName: '/s9s/mysql/widgets/schema/show_tables.js',
                lineNumber: 67,
                message: '<tr><td>time_zone&nbsp;&nbsp;</td></td></tr>',
            },
            {
                fileName: '/s9s/mysql/widgets/schema/show_tables.js',
                lineNumber: 67,
                message:
                    '<tr><td>time_zone_leap_second&nbsp;&nbsp;</td></td></tr>',
            },
            {
                fileName: '/s9s/mysql/widgets/schema/show_tables.js',
                lineNumber: 67,
                message: '<tr><td>time_zone_name&nbsp;&nbsp;</td></td></tr>',
            },
            {
                fileName: '/s9s/mysql/widgets/schema/show_tables.js',
                lineNumber: 67,
                message:
                    '<tr><td>time_zone_transition&nbsp;&nbsp;</td></td></tr>',
            },
            {
                fileName: '/s9s/mysql/widgets/schema/show_tables.js',
                lineNumber: 67,
                message:
                    '<tr><td>time_zone_transition_type&nbsp;&nbsp;</td></td></tr>',
            },
            {
                fileName: '/s9s/mysql/widgets/schema/show_tables.js',
                lineNumber: 67,
                message: '<tr><td>user&nbsp;&nbsp;</td></td></tr>',
            },
            {
                fileName: '/s9s/mysql/widgets/schema/show_tables.js',
                lineNumber: 67,
                message: '<tr><td>wsrep_cluster&nbsp;&nbsp;</td></td></tr>',
            },
            {
                fileName: '/s9s/mysql/widgets/schema/show_tables.js',
                lineNumber: 67,
                message:
                    '<tr><td>wsrep_cluster_members&nbsp;&nbsp;</td></td></tr>',
            },
            {
                fileName: '/s9s/mysql/widgets/schema/show_tables.js',
                lineNumber: 67,
                message:
                    '<tr><td>wsrep_streaming_log&nbsp;&nbsp;</td></td></tr>',
            },
        ],
    },
};

export default result;
