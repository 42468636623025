const dashboard = {
    title: 'MySQL Server - Caches',
    meta: {
        slug: 'mysql-server-caches',
        supportClusterTypes: [
            'replication',
            'galera',
            'mysql_single',
            'mysqlcluster',
            'group_replication',
        ],
    },
    panels: [
        {
            title: 'Innodb bufferpool hit ratio',
            type: 'singlestat',
            gridPos: {
                h: 4,
                w: 8,
                x: 0,
                y: 0,
            },
            options: {
                decimals: 2,
                format: 'short',
                type: 'line',
                legend: {},
                chart: {
                    full: false,
                    show: false,
                },
                postfix: ' / 1,000',
                valueMaps: [],
            },
            targets: [
                {
                    expr:
                        '1000*mysql_global_status_innodb_buffer_pool_read_requests{instance=~"$instance"} / (mysql_global_status_innodb_buffer_pool_reads{instance=~"$instance"} + mysql_global_status_innodb_buffer_pool_read_requests{instance=~"$instance"})',
                    legendFormat: '',
                },
            ],
        },
        {
            title: 'InnoDB bufferpool size',
            type: 'singlestat',
            gridPos: {
                h: 4,
                w: 8,
                x: 0,
                y: 0,
            },
            options: {
                decimals: 0,
                format: 'size',
                type: 'line',
                legend: {},
                chart: {
                    full: false,
                    show: false,
                },
                valueMaps: [],
            },
            targets: [
                {
                    expr:
                        'mysql_global_variables_innodb_buffer_pool_size{instance=~"$instance"}',
                    legendFormat: '',
                },
            ],
        },
        {
            title: 'Innodb bufferpool instances',
            type: 'singlestat',
            gridPos: {
                h: 4,
                w: 8,
                x: 0,
                y: 0,
            },
            options: {
                decimals: 1,
                format: 'short',
                type: 'line',
                legend: {},
                chart: {
                    full: false,
                    show: false,
                },
                valueMaps: [],
            },
            targets: [
                {
                    expr:
                        'mysql_global_variables_innodb_buffer_pool_instances{instance=~"$instance"}',
                    legendFormat: '',
                },
            ],
        },
        {
            title: 'InnoDB bufferpool hit ratio',
            type: 'chart',
            gridPos: {
                h: 7,
                w: 24,
                x: 0,
                y: 0,
            },
            options: {
                decimals: 2,
                format: 'short',
                type: 'area',
                legend: {
                    alignAsTable: true,
                },
                yaxis: [
                    {
                        format: 'short',
                        max: null,
                        min: 0,
                    },
                    {
                        format: 'short',
                        max: null,
                        min: 0,
                    },
                ],
                stack: false,
            },
            targets: [
                {
                    expr:
                        '1000*mysql_global_status_innodb_buffer_pool_read_requests{instance=~"$instance"} / (mysql_global_status_innodb_buffer_pool_reads{instance=~"$instance"} + mysql_global_status_innodb_buffer_pool_read_requests{instance=~"$instance"})',
                    legendFormat: 'Bufferpool Hit Ratio',
                    yaxis: 1,
                },
            ],
        },
        {
            title: 'Table open cache size per table open cache instance',
            type: 'singlestat',
            gridPos: {
                h: 4,
                w: 8,
                x: 0,
                y: 0,
            },
            options: {
                decimals: 0,
                format: 'long',
                type: 'line',
                legend: {},
                chart: {
                    full: false,
                    show: false,
                },
                valueMaps: [],
            },
            targets: [
                {
                    expr:
                        'mysql_global_variables_table_open_cache{instance=~"$instance"}/mysql_global_variables_table_open_cache_instances{instance=~"$instance"}',
                    legendFormat: '',
                },
            ],
        },
        {
            title: 'Table open cache configured instances',
            type: 'singlestat',
            gridPos: {
                h: 4,
                w: 8,
                x: 0,
                y: 0,
            },
            options: {
                decimals: 0,
                format: 'short',
                type: 'line',
                legend: {},
                chart: {
                    full: false,
                    show: false,
                },
                valueMaps: [],
            },
            targets: [
                {
                    expr:
                        'mysql_global_variables_table_open_cache_instances{instance=~"$instance"}',
                    legendFormat: '',
                },
            ],
        },
        {
            title: 'Table open cache active instances',
            type: 'singlestat',
            gridPos: {
                h: 4,
                w: 8,
                x: 0,
                y: 0,
            },
            options: {
                decimals: 0,
                format: 'short',
                type: 'line',
                legend: {},
                chart: {
                    full: false,
                    show: false,
                },
                valueMaps: [],
            },
            targets: [
                {
                    expr:
                        'mysql_global_status_table_open_cache_active_instances{instance=~"$instance"}',
                    legendFormat: '',
                },
            ],
        },
        {
            title: 'Table open cache usage',
            type: 'chart',
            gridPos: {
                h: 8,
                w: 8,
                x: 0,
                y: 0,
            },
            options: {
                decimals: 2,
                format: 'short',
                type: 'area',
                legend: {
                    alignAsTable: true,
                },
                yaxis: [
                    {
                        format: 'short',
                        max: null,
                        min: 0,
                    },
                    {
                        format: 'percentage',
                        max: null,
                        min: 0,
                    },
                ],
                stack: false,
            },
            targets: [
                {
                    expr:
                        'mysql_global_variables_table_open_cache{instance=~"$instance"}',
                    legendFormat: 'Table Open Cache Size',
                },
                {
                    expr:
                        'mysql_global_status_open_tables{instance=~"$instance"}',
                    legendFormat: 'Open Tables',
                },
            ],
        },
        {
            title: 'Table open cache hit ratio',
            type: 'chart',
            gridPos: {
                h: 8,
                w: 8,
                x: 0,
                y: 0,
            },
            options: {
                decimals: 2,
                format: 'short',
                type: 'area',
                legend: {
                    alignAsTable: true,
                },
                yaxis: [
                    {
                        format: 'percentage',
                        max: null,
                        min: 0,
                    },
                ],
                stack: false,
            },
            targets: [
                {
                    expr:
                        'mysql_global_status_table_open_cache_hits{instance=~"$instance"} / (mysql_global_status_table_open_cache_misses{instance=~"$instance"} + mysql_global_status_table_open_cache_hits{instance=~"$instance"})',
                    legendFormat: 'Table Open Cache Hit Ratio',
                },
            ],
        },
        {
            title: 'Table open cache hits and misses',
            type: 'chart',
            gridPos: {
                h: 8,
                w: 8,
                x: 0,
                y: 0,
            },
            options: {
                decimals: 2,
                format: 'short',
                type: 'line',
                legend: {
                    alignAsTable: true,
                },
                yaxis: [
                    {
                        format: 'short',
                        max: null,
                        min: 0,
                    },
                    {
                        format: 'short',
                        max: null,
                        min: 0,
                    },
                ],
                stack: false,
            },
            targets: [
                {
                    expr:
                        'mysql_global_status_table_open_cache_hits{instance=~"$instance"}',
                    legendFormat: 'Table Open Cache Hits',
                    yaxis: 0,
                },
                {
                    expr:
                        'mysql_global_status_table_open_cache_misses{instance=~"$instance"}',
                    legendFormat: 'Table Open Cache Misses',
                    yaxis: 1,
                },
            ],
        },
        /*        {
                    "title": "Table Definition Cache",
                    "type": "singlestat",
                    "gridPos": {
                        "h": 8,
                        "w": 4,
                        "x": 0,
                        "y": 0
                    },
                    "options": {
                        "decimals": 0,
                        "format": "long",
                        "type": "line",
                        "legend": {},
                        "chart": {
                            "full": false,
                            "show": false
                        },
                        "valueMaps": []
                    },
                    "targets": [
                        {
                            "expr": "mysql_global_variables_table_definition_cache{instance=~\"$instance\"}",
                            "legendFormat": ""
                        }
                    ]
                },        */
        {
            title: 'Table definition cache usage',
            type: 'chart',
            gridPos: {
                h: 8,
                w: 8,
                x: 0,
                y: 0,
            },
            options: {
                decimals: 2,
                format: 'short',
                type: 'area',
                legend: {
                    alignAsTable: true,
                },
                yaxis: [
                    {
                        format: 'short',
                        max: null,
                        min: 0,
                    },
                ],
                stack: false,
            },
            targets: [
                {
                    expr:
                        'mysql_global_variables_table_definition_cache{instance=~"$instance"}',
                    legendFormat: 'Open Table Definition Cache Size',
                },
                {
                    expr:
                        'mysql_global_status_open_table_definitions{instance=~"$instance"}',
                    legendFormat: 'Cached Table Definitions',
                },
            ],
        },
        {
            title: 'Table definition cache hit ratio',
            type: 'chart',
            gridPos: {
                h: 8,
                w: 8,
                x: 0,
                y: 0,
            },
            options: {
                decimals: 2,
                format: 'short',
                type: 'area',
                legend: {
                    alignAsTable: true,
                },
                yaxis: [
                    {
                        format: 'percent',
                        max: null,
                        min: 0,
                    },
                ],
                stack: false,
            },
            targets: [
                {
                    expr:
                        '100*mysql_global_status_open_table_definitions{instance=~"$instance"} / (mysql_global_status_opened_table_definitions{instance=~"$instance"})',
                    legendFormat: 'Open Table Definition Cache Hit Ratio',
                },
            ],
        },
        {
            title: 'Open tables',
            type: 'chart',
            gridPos: {
                h: 8,
                w: 8,
                x: 0,
                y: 0,
            },
            options: {
                decimals: 2,
                format: 'short',
                type: 'area',
                legend: {
                    axlignAsTable: true,
                },
                yaxis: [
                    {
                        format: 'short',
                        max: null,
                        min: 0,
                    },
                ],
                stack: false,
            },
            targets: [
                {
                    expr:
                        'mysql_global_status_open_files{instance=~"$instance"}',
                    legendFormat: 'Open_files',
                },
                {
                    expr:
                        'mysql_global_variables_open_files_limit{instance=~"$instance"}',
                    legendFormat: 'Open_files_limit',
                },
            ],
        },
        {
            title: 'Thread cache size',
            type: 'singlestat',
            gridPos: {
                h: 4,
                w: 8,
                x: 0,
                y: 0,
            },
            options: {
                decimals: 0,
                format: 'short',
                type: 'line',
                legend: {},
                chart: {
                    full: false,
                    show: false,
                },
                valueMaps: [],
            },
            targets: [
                {
                    expr:
                        'mysql_global_variables_thread_cache_size{instance=~"$instance"}',
                    legendFormat: '',
                },
            ],
        },
        {
            title: 'Max used connections',
            type: 'singlestat',
            gridPos: {
                h: 4,
                w: 8,
                x: 0,
                y: 0,
            },
            options: {
                decimals: 0,
                format: 'short',
                type: 'line',
                legend: {},
                chart: {
                    full: false,
                    show: false,
                },
                valueMaps: [],
            },
            targets: [
                {
                    expr:
                        'mysql_global_status_max_used_connections{instance=~"$instance"}',
                    legendFormat: '',
                },
            ],
        },
        {
            title: 'Thread cache - usage',
            type: 'chart',
            gridPos: {
                h: 8,
                w: 12,
                x: 0,
                y: 0,
            },
            options: {
                decimals: 2,
                format: 'short',
                type: 'area',
                legend: {
                    alignAsTable: true,
                },
                yaxis: [
                    {
                        format: 'short',
                        max: null,
                        min: 0,
                    },
                ],
                stack: false,
            },
            targets: [
                {
                    expr:
                        'mysql_global_variables_thread_cache_size{instance=~"$instance"}',
                    legendFormat: 'Thread Cache Size',
                },
                {
                    expr:
                        'mysql_global_status_max_used_connections{instance=~"$instance"}',
                    legendFormat: 'Max Used Connections',
                },
                {
                    expr:
                        'mysql_global_status_threads_running{instance=~"$instance"}',
                    legendFormat: 'Threads Running',
                },
                {
                    expr:
                        'mysql_global_status_threads_connected{instance=~"$instance"}',
                    legendFormat: 'Threads Connected',
                },
                {
                    expr:
                        'mysql_global_status_threads_cached{instance=~"$instance"}',
                    legendFormat: 'Threads Cached',
                },
            ],
        },
        {
            title: 'Thread cache - creation ratio',
            type: 'chart',
            gridPos: {
                h: 8,
                w: 12,
                x: 0,
                y: 0,
            },
            options: {
                decimals: 1,
                format: 'percent',
                type: 'area',
                legend: {
                    alignAsTable: true,
                },
                yaxis: [
                    {
                        format: 'percent',
                        max: 100,
                        min: 0,
                    },
                ],
                stack: false,
            },
            targets: [
                {
                    expr:
                        '100*mysql_global_status_threads_created{instance=~"$instance"}/ mysql_global_status_connections{instance=~"$instance"}',
                    legendFormat: 'Connections to Thread Creation Ratio',
                },
            ],
        },
    ],
    templating: {
        list: [
            {
                datasource: 'clustercontrol',
                multi: false,
                label: 'Host',
                name: 'instance',
                query: "host:$[nodes[nodetype='mysql' or nodetype='galera']]",
                optionValue: 'hostname',
                optionText: 'hostname',
                sort: 1,
                isInstancePicker: true,
            },
        ],
    },
    schemaVersion: 2,
};
export default dashboard;
