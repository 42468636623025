import ClusterTopologyConfigurator, {
    ClusterTopologyCard,
} from './ClusterTopologyConfigurator';
import CcNode, {
    CcNodeBase,
    CcNodeRole,
    CcNodeType,
} from '../../../services/models/CcNode';
import CcCluster from '../../../services/models/CcCluster';
import CcPostgreSqlNode from '../../../services/models/CcPostgreSqlNode';
import UnavailableFormat from '@severalnines/bar-frontend-components/build/lib/Format/UnavailableFormat';

export default class ClusterTopologyPostgresqlConfigurator extends ClusterTopologyConfigurator {
    public hasPgBouncers: boolean;
    constructor(clusters: CcCluster[]) {
        super(clusters);
        this.hasPgBouncers = false;
    }

    nodeToItem(node: CcNode, cluster: CcCluster, item: any): any {
        let result = item;
        this.hasDbNodes = true;
        let master;
        if (
            node.isBase(CcNodeBase.BASE_POSTGRESQL) &&
            !node.isRole(CcNodeRole.BVS)
        ) {
            if (node.isRole(CcNodeRole.MASTER)) {
                result.from = 'db';
                result.arrow = 'both';
            }

            if (node.isRole(CcNodeRole.SLAVE)) {
                master = cluster.getPrimaryOf(node);

                if (master) {
                    result.from = master.getKey();
                    result.arrow = 'right';
                    if (!(node as CcPostgreSqlNode).isReplicationRunning()) {
                        result.connectionStatus = 'error';
                    }
                }
            }
        } else if (node.isType(CcNodeType.PGBOUNCER)) {
            this.hasPgBouncers = true;
            result.from = this.getCustomGroup('pgb', 'PgBouncers');
        } else {
            result = super.nodeToItem(node, cluster, item);
        }
        return result;
    }

    prepareGroups(cluster: CcCluster) {
        super.prepareGroups(cluster);
        if (this.hasPgBouncers) {
            this.getCustomGroup('pgb', 'PgBouncers');
            this.groups.db && (this.groups.db.from = 'pgb');
            if (this.hasLoadBalancers) {
                this.groups.pgb.from = 'lb';
                this.groups.pgb.placement = 'out';
            }
        }
        // @TODO: fix this..
        if (cluster.masterCluster && !cluster.biDirectional) {
            if (this.hasPgBouncers && this.groups.clusterGroup) {
                this.groups.pgb.from = `clusterGroup`;
            }
        }
    }

    getNodeCoverCardConfig(node: CcNode): ClusterTopologyCard {
        if (node.isType(CcNodeType.POSTGRESQL)) {
            const postgresNode = node as CcPostgreSqlNode;
            return [
                [
                    {
                        label: 'Received location',
                        value: postgresNode.receivedLocation,
                    },
                ],
                [
                    {
                        label: 'Sync state',
                        value: postgresNode.syncState,
                    },
                ],
                ...([CcNodeRole.SLAVE].includes(postgresNode.role as CcNodeRole)
                    ? [
                          [
                              {
                                  label: 'Replay location',
                                  value:
                                      postgresNode.replicationSlave
                                          ?.replayLocation,
                              },
                          ],
                          [
                              {
                                  label: 'Replication state',
                                  value: postgresNode.replicationState,
                              },
                              {
                                  label: 'Lag',
                                  value: (
                                      <UnavailableFormat
                                          val={
                                              postgresNode.replicationSlave
                                                  ?.secondsBehindMaster
                                          }
                                      >
                                          {
                                              postgresNode.replicationSlave
                                                  ?.secondsBehindMaster
                                          }
                                          s
                                      </UnavailableFormat>
                                  ),
                              },
                          ],
                      ]
                    : []),
            ];
        }

        return super.getNodeCoverCardConfig(node);
    }
}
