import React from 'react';
import FormValueListener from '../../../../common/DataEntry/FormValueListener';
import { getShardKeys } from '../Deploy/MongoShards/MongoShardsReplicaSetFormWrapper';
import { MongoShardsFormShardsMap } from './MongoShardsConfigurator';

export default MongoShardsFormValueListener;

export type MongoShardsFormValueListenerProps = {
    onShardAdded?: (shards: MongoShardsFormShardsMap) => void;
    onShardRemoved?: (shards: MongoShardsFormShardsMap) => void;
};

function MongoShardsFormValueListener({
    onShardAdded,
    onShardRemoved,
}: MongoShardsFormValueListenerProps) {
    const handleChange = (
        shards: MongoShardsFormShardsMap,
        prevShards: MongoShardsFormShardsMap
    ) => {
        const shardsKeys = getShardKeys(shards);
        const prevShardsKeys = getShardKeys(prevShards);
        if (shardsKeys.length < prevShardsKeys.length) {
            onShardRemoved?.(shards);
        } else {
            onShardAdded?.(shards);
        }
    };
    return (
        <FormValueListener
            name={['shards']}
            onChange={handleChange}
            getValue={(shards) => getShardKeys(shards).length}
        />
    );
}
