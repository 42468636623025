const dashboard = {
    title: 'System Overview',
    meta: {
        slug: 'system-overview',
        supportClusterTypes: ['all'],
    },
    panels: [
        {
            title: 'Operating System',
            type: 'singlestat',
            datasource: 'clustercontrol',
            gridPos: {
                h: 4,
                w: 6,
                x: 0,
                y: 0,
            },
            options: {
                format: 'none',
            },
            targets: [
                {
                    expr:
                        "host:$[hostname='$instance'].{'distributionName': distribution.name, 'distributionRelease': distribution.release}",
                    valueFormat:
                        '{{ distributionName }} {{ distributionRelease }}',
                },
            ],
        },
        {
            title: 'CPU cores',
            type: 'singlestat',
            gridPos: {
                h: 4,
                w: 6,
                x: 0,
                y: 0,
            },
            options: {
                decimals: 0,
                format: 'number',
            },
            targets: [
                {
                    expr:
                        'count(count(node_cpu_seconds_total{instance=~"$instance"}) by (cpu)) ',
                },
            ],
        },
        {
            title: 'Total RAM',
            type: 'singlestat',
            gridPos: {
                h: 4,
                w: 6,
                x: 0,
                y: 0,
            },
            options: {
                decimals: 0,
                format: 'size',
            },
            targets: [
                {
                    expr: 'node_memory_MemTotal_bytes{instance=~"$instance"}',
                },
            ],
        },
        {
            title: 'Server uptime',
            type: 'singlestat',
            gridPos: {
                h: 4,
                w: 6,
                x: 0,
                y: 0,
            },
            options: {
                decimals: 2,
                format: 'duration',
            },
            targets: [
                {
                    expr:
                        'node_time_seconds{instance=~"$instance"} - node_boot_time_seconds{instance=~"$instance"}',
                },
            ],
        },
        {
            title: 'Load average',
            type: 'chart',
            gridPos: {
                h: 8,
                w: 8,
                x: 0,
                y: 0,
            },
            options: {
                decimals: 2,
                format: 'short',
                type: 'line',
                legend: {
                    alignAsTable: true,
                },
                yaxis: [
                    {
                        format: 'short',
                        min: 0,
                    },
                ],
                stack: false,
            },
            targets: [
                {
                    expr: 'node_load1{instance=~"$instance"}',
                    legendFormat: 'LoadAvg 1m',
                },
                {
                    expr: 'node_load5{instance=~"$instance"}',
                    legendFormat: 'LoadAvg 5m',
                },
                {
                    expr: 'node_load15{instance=~"$instance"}',
                    legendFormat: 'LoadAvg 15m',
                },
            ],
        },
        {
            title: 'CPU usage',
            type: 'chart',
            gridPos: {
                h: 8,
                w: 8,
                x: 0,
                y: 0,
            },
            options: {
                decimals: 2,
                format: 'percent',
                type: 'area',
                legend: {
                    alignAsTable: true,
                },
                yaxis: [
                    {
                        max: 100,
                        min: 0,
                    },
                ],
                stack: true,
            },
            targets: [
                {
                    expr:
                        '100-100*sum((avg by (mode, instance) (irate(node_cpu_seconds_total{instance=~"$instance",mode!="idle"}[5m]))))',
                    legendFormat: 'Idle',
                },
                {
                    expr:
                        '100*(avg by (mode, instance) (irate(node_cpu_seconds_total{instance=~"$instance",mode="user"}[5m])))',
                    legendFormat: 'User',
                },
                {
                    expr:
                        '100*(avg by (mode, instance) (irate(node_cpu_seconds_total{instance=~"$instance",mode="system"}[5m])))',
                    legendFormat: 'System',
                },
                {
                    expr:
                        '100*(avg by (mode, instance) (irate(node_cpu_seconds_total{instance=~"$instance",mode="iowait"}[5m])))',
                    legendFormat: 'Iowait',
                },
                {
                    expr:
                        '100*(avg by (mode, instance) (irate(node_cpu_seconds_total{instance=~"$instance",mode="softirq"}[5m])))',
                    legendFormat: 'SoftIrq',
                },
                {
                    expr:
                        '100*(avg by (mode, instance) (irate(node_cpu_seconds_total{instance=~"$instance",mode="irq"}[5m])))',
                    legendFormat: 'Irq',
                },
                {
                    expr:
                        '100*(avg by (mode, instance) (irate(node_cpu_seconds_total{instance=~"$instance",mode="steal"}[5m])))',
                    legendFormat: 'Steal',
                },
                {
                    expr:
                        '100*(avg by (mode, instance) (irate(node_cpu_seconds_total{instance=~"$instance",mode="nice"}[5m])))',
                    legendFormat: 'Nice',
                },
            ],
        },
        {
            title: 'RAM usage',
            type: 'chart',
            gridPos: {
                h: 8,
                w: 8,
                x: 0,
                y: 0,
            },
            options: {
                decimals: 2,
                type: 'area',
                legend: {
                    alignAsTable: true,
                },
                yaxis: [
                    {
                        format: 'size',
                        max: null,
                        min: 0,
                    },
                ],
                stack: true,
            },
            targets: [
                {
                    expr:
                        'node_memory_MemTotal_bytes{instance=~"$instance"} - node_memory_MemFree_bytes{instance=~"$instance"}',
                    legendFormat: 'Used',
                    color: '#4363d8' /*blue*/,
                },
                {
                    expr: 'node_memory_MemFree_bytes{instance=~"$instance"}',
                    legendFormat: 'Free',
                    color: '#911eb4' /*purple*/,
                },
            ],
        },
        {
            title: 'Disk space usage',
            type: 'chart',
            gridPos: {
                h: 8,
                w: 8,
                x: 0,
                y: 0,
            },
            options: {
                decimals: 2,
                type: 'area',
                legend: {
                    alignAsTable: true,
                },
                yaxis: [
                    {
                        format: 'size',
                        max: null,
                        min: 0,
                    },
                ],
                stack: true,
            },
            targets: [
                {
                    expr:
                        'node_filesystem_avail_bytes{instance=~"$instance",fstype=~"ext4|xfs"}',
                    legendFormat: 'Free: {{ device }}',
                },
                {
                    expr:
                        'node_filesystem_size_bytes{instance=~"$instance",fstype=~"ext4|xfs"}- node_filesystem_avail_bytes{instance=~"$instance",fstype=~"ext4|xfs"} ',
                    legendFormat: 'Used: {{ device }}',
                },
            ],
        },
        {
            title: 'Network usage',
            type: 'chart',
            gridPos: {
                h: 8,
                w: 8,
                x: 0,
                y: 0,
            },
            options: {
                decimals: 2,
                format: 'sizeps',
                type: 'line',
                legend: {
                    alignAsTable: true,
                },
                yaxis: [
                    {
                        max: null,
                        min: 0,
                    },
                ],
                stack: false,
            },
            targets: [
                {
                    expr:
                        'irate(node_network_transmit_bytes_total{instance=~"$instance", device!~"lo|bond[0-9]|cbr[0-9]|veth.*"}[5m]) > 0',
                    legendFormat: '{{ device }} Sent',
                    color: '#4363d8' /*blue*/,
                },
                {
                    expr:
                        'irate(node_network_receive_bytes_total{instance=~"$instance", device!~"lo|bond[0-9]|cbr[0-9]|veth.*"}[5m]) > 0',
                    legendFormat: '{{ device }} Received',
                    color: '#3cb44b' /*green*/,
                },
            ],
        },
        {
            title: 'Disk IOPS',
            type: 'chart',
            gridPos: {
                h: 8,
                w: 8,
                x: 0,
                y: 0,
            },
            options: {
                decimals: 1,
                format: 'short',
                type: 'line',
                legend: {
                    alignAsTable: true,
                },
                yaxis: [
                    {
                        max: null,
                        min: 0,
                    },
                ],
                stack: false,
            },
            targets: [
                {
                    expr:
                        'irate(node_disk_reads_completed_total{instance=~"$instance"}[5m]) or irate(node_disk_reads_completed{instance=~"$instance"}[5m])',
                    legendFormat: '{{device}} reads',
                },
                {
                    expr:
                        'irate(node_disk_writes_completed_total{instance=~"$instance"}[5m]) or irate(node_disk_writes_completed{instance=~"$instance"}[5m])',
                    legendFormat: '{{device}} writes',
                },
            ],
        },
        {
            title: 'Disk IO util %',
            type: 'chart',
            gridPos: {
                h: 8,
                w: 8,
                x: 0,
                y: 0,
            },
            options: {
                decimals: 2,
                format: 'short',
                type: 'line',
                legend: {
                    alignAsTable: true,
                },
                yaxis: [
                    {
                        max: 100,
                        min: 0,
                    },
                ],
                stack: false,
            },
            targets: [
                {
                    expr:
                        'irate(node_disk_io_time_seconds_total{instance=~"$instance"}[5m]) * 100',
                    legendFormat: '{{device}} util (%)',
                },
            ],
        },
        {
            title: 'Disk throughput',
            type: 'chart',
            gridPos: {
                h: 8,
                w: 8,
                x: 0,
                y: 0,
            },
            options: {
                decimals: 2,
                format: 'sizeps',
                type: 'line',
                legend: {
                    alignAsTable: true,
                },
                yaxis: [
                    {
                        max: null,
                        min: 0,
                    },
                ],
                stack: false,
            },
            targets: [
                {
                    expr:
                        'irate(node_disk_read_bytes_total{instance=~"$instance"}[5m]) or irate(node_disk_sectors_read{instance=~"$instance"}[5m]) * 512',
                    legendFormat: '{{device}} read',
                },
                {
                    expr:
                        'irate(node_disk_written_bytes_total{instance=~"$instance"}[5m]) or irate(node_disk_sectors_written{instance=~"$instance"}[5m]) * 512',
                    legendFormat: '{{device}} write',
                },
            ],
        },
    ],
    templating: {
        list: [
            {
                datasource: 'clustercontrol',
                multi: false,
                label: 'Host',
                name: 'instance',
                query: "host:$.{'hostname': hostname, 'types': types}",
                optionValue: 'hostname',
                optionText: '{{hostname}} ({{types}})',
                sort: 1,
                isInstancePicker: true,
            },
        ],
    },
    schemaVersion: 2,
};
export default dashboard;
