
import CmonContainerServer, { CmonContainerServerProps } from './CmonContainerServer';


export interface CmonLxcServerProps  extends CmonContainerServerProps {

}



export default class CmonLxcServer extends CmonContainerServer {


    constructor(props: CmonLxcServerProps) {
        super(props);

    }

}
