import { SelectProps } from 'antd';
import React, { useEffect, useState } from 'react';
import useClusterDatabasesTables from './useClusterDatabaseTables';
import MultiSelect, {
    MultiSelectOptionType,
} from '../../common/DataEntry/MultiSelect';

export type ClusterHostDatabaseTablesSelectProps = SelectProps<string[]> & {
    clusterId: number;
    host: string;
    schemas: string[];
    disabled?: boolean;
    onTableListLoad?: Function;
};
export const CLUSTER_ALL_TABLES_NAME = 'All Tables';
const ClusterHostDatabaseTablesSelect = ({
    clusterId,
    host,
    schemas,
    disabled = false,
    onChange,
    value,
    onTableListLoad,
    ...rest
}: ClusterHostDatabaseTablesSelectProps) => {
    const [options, setOptions] = useState<MultiSelectOptionType[]>([]);
    const {
        tables,
        loading,
        refresh: refreshTables,
    } = useClusterDatabasesTables({
        clusterId,
        host,
        schemas,
        useCache: true,
    });

    useEffect(() => {
        if (!disabled) {
            (async () => {
                await refreshTables({ schemas });
            })();
        }
    }, [disabled, schemas]);

    useEffect(() => {
        if (tables?.length > 0) {
            setOptions(
                tables.map((table) => ({
                    label: `${table.schema}.${table.text}`,
                    value: `${table.schema}.${table.text}`,
                }))
            );
            onTableListLoad?.(tables.map((table) => table.text));
        }
    }, [tables]);

    return (
        <MultiSelect
            options={options}
            value={value}
            disabled={disabled || loading}
            onChange={onChange}
            loading={loading}
            allOptionName={CLUSTER_ALL_TABLES_NAME}
            {...rest}
        />
    );
};

export default ClusterHostDatabaseTablesSelect;
