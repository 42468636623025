import merge from 'deepmerge';

export function collectionToMap(arr: any[], key: string = 'key') {
    return arr.reduce((result, item) => {
        result[item[key]] = { ...item };
        return result;
    }, {});
}

export function mapToCollection(map: any[]) {
    return Object.values(map);
}

export function collectionToMapDeep(
    arr: any[],
    key: string = 'key',
    subArrayKey: string = 'children'
) {
    let map = collectionToMap(arr, key);
    for (let prop in map) {
        if (map[prop][subArrayKey] && map[prop][subArrayKey].length) {
            map[prop][subArrayKey] = collectionToMapDeep(
                map[prop][subArrayKey],
                key,
                subArrayKey
            );
        } else {
            map[prop][subArrayKey] = {};
        }
    }
    return map;
}

export function mapToCollectionDeep(
    map: any,
    subArrayKey: string = 'children'
) {
    let arr = mapToCollection(map);
    for (let i = 0; i < arr.length; i++) {
        for (let subprop in arr[i]) {
            if (subprop === subArrayKey) {
                arr[i][subprop] = mapToCollectionDeep(
                    arr[i][subprop],
                    subArrayKey
                );
            }
        }
    }
    return arr;
}

export function mergeCollectionDeepByKey(
    a: any[],
    b: any[],
    key: string = 'key',
    deepKey: string = 'children'
) {
    const collectionA = collectionToMapDeep(a, key, deepKey);
    const collectionB = collectionToMapDeep(b, key, deepKey);
    const merged = merge(collectionA, collectionB);
    return mapToCollectionDeep(merged, deepKey);
}
