import ActionButton from '@severalnines/bar-frontend-components/build/lib/General/ActionButton';
import ButtonWithForm from '../../common/General/ButtonWithForm';
import KeyManagementMoveLocationModal from './KeyManagementMoveLocationModal';

export default KeyManagementActionMenuMoveButton;

export type KeyManagementActionMenuMoveButtonProps = {
    list: any;
    selectedRow: any;
    onSuccess?: () => void;
};

function KeyManagementActionMenuMoveButton({
    list,
    selectedRow,
    onSuccess,
}: KeyManagementActionMenuMoveButtonProps) {
    return (
        <ButtonWithForm
            button={<ActionButton>Move to a new location</ActionButton>}
            form={
                <KeyManagementMoveLocationModal
                    list={list}
                    onSuccess={onSuccess}
                    selectedRow={selectedRow}
                />
            }
        />
    );
}
