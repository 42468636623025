import React, { useContext } from 'react';
import { ResponsiveContext } from '@severalnines/bar-frontend-components/build/lib/Layout/Responsive';
import CcKeepalivedNode from '../../../../services/models/CcKeepalivedNode';
import AppTable from '../../../../common/DataDisplay/AppTable';

export type KeepalivedDetailsTableProps = {
    node: CcKeepalivedNode;
};

export default KeepalivedDetailsTable;

function KeepalivedDetailsTable({ node }: KeepalivedDetailsTableProps) {
    const { responsive } = useContext(ResponsiveContext);

    const columns = [
        {
            title: '',
            key: 'rowName',
            dataIndex: 'rowName',
        },
        {
            title: 'Value',
            key: 'value',
            dataIndex: 'value',
        },
    ];

    return (
        <AppTable
            className="KeepalivedDetailsTable"
            size="small"
            defaultExpandAllRows={true}
            dataSource={prepareKeepalivedTableData(node)}
            columns={columns}
            pagination={false}
            responsive={responsive}
        />
    );
}

function prepareKeepalivedTableData(node: CcKeepalivedNode) {
    const {
        name,
        version,
        role,
        cmdline,
        configfile,
        port,
        pid,
        ip,
        nic,
        virtualip,
        message,
        backendInfo,
    } = node;
    return [
        {
            key: '1',
            rowName: 'Name',
            value: name,
        },
        {
            key: '2',
            rowName: 'Version',
            value: version,
        },
        {
            key: '3',
            rowName: 'Role',
            value: role,
        },
        {
            key: '4',
            rowName: 'Cmd',
            value: cmdline,
        },
        {
            key: '5',
            rowName: 'Config',
            value: configfile,
        },
        {
            key: '6',
            rowName: 'Port',
            value: port,
        },
        {
            key: '7',
            rowName: 'PID',
            value: pid,
        },
        {
            key: '8',
            rowName: 'Keepalived address',
            value: ip,
        },
        {
            key: '9',
            rowName: 'Interface',
            value: nic,
        },
        {
            key: '10',
            rowName: 'Virtual Ip',
            value: virtualip,
        },
        {
            key: '11',
            rowName: 'Status',
            value: message,
        },
        {
            key: '12',
            rowName: 'Loadbalancer addresses',
            value: backendInfo?.backend_servers
                .map((bs: any) => bs.hostname + ':' + bs.port)
                .join(', '),
        },
    ];
}
