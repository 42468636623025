import React from 'react';
import { Space } from 'antd';
import InfoIcon from '@severalnines/bar-frontend-components/build/lib/General/InfoIcon';
import FormItemInlineSwitch, {
    FormItemInlineSwitchProps,
} from '../../DataEntry/FormItemInlineSwitch';

export default TopologyNodeUseAsDataField;
export type TopologyNodeUseAsDataFieldProps = FormItemInlineSwitchProps & {};

function TopologyNodeUseAsDataField({
    ...rest
}: TopologyNodeUseAsDataFieldProps) {
    return (
        <FormItemInlineSwitch
            justify
            noMargin
            label={
                <Space>
                    <span>Use as Data node</span>
                    <InfoIcon info="By default, the node has both roles ON - Master and Data" />
                </Space>
            }
            name="useAsDataNode"
            {...rest}
        />
    );
}
