
import CcNode, { CcNodeProps } from '../../models/CcNode';


export interface CmonPgBouncerHostProps  extends CcNodeProps {
    auth_file?: string;
    os_user?: string;
    pg_hostname?: string;
    pg_port?: number;
    pool_mode?: string;
    service_name?: string;

}



export default class CmonPgBouncerHost extends CcNode {
    public authFile?: string;
    public osUser?: string;
    public pgHostname?: string;
    public pgPort?: number;
    public poolMode?: string;
    public serviceName?: string;


    constructor(props: CmonPgBouncerHostProps) {
        super(props);
        this.authFile = props.auth_file;
        this.osUser = props.os_user;
        this.pgHostname = props.pg_hostname;
        this.pgPort = props.pg_port;
        this.poolMode = props.pool_mode;
        this.serviceName = props.service_name;

    }

}
