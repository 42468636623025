import { getSortDateFn } from '../../common/sorting';

const DAYMS = 24 * 60 * 60 * 1000;
export type CcMaintenancePeriodProps = {
    UUID?: string;
    deadline?: string;
    groupid?: number;
    groupname?: string;
    initiate?: string;
    is_active?: boolean;
    reason?: string;
    userid?: number;
    username?: string;
};

export class CcMaintenancePeriod {
    public readonly uuid?: string;
    public readonly deadline?: Date | null;
    public readonly groupid?: number;
    public readonly groupname?: string;
    public readonly initiate?: Date | null;
    public readonly isActive?: boolean;
    public readonly reason?: string;
    public readonly userid?: number;
    public readonly username?: string;

    constructor(props: CcMaintenancePeriodProps) {
        this.uuid = props.UUID;
        this.deadline = props.deadline ? new Date(props.deadline) : null;
        this.groupid = props.groupid;
        this.groupname = props.groupname;
        this.initiate = props.initiate ? new Date(props.initiate) : null;
        this.isActive = props.is_active;
        this.reason = props.reason;
        this.userid = props.userid;
        this.username = props.username;
    }

    getDuration() {
        return (
            (this.deadline?.getTime() || 0) - (this.initiate?.getTime() || 0)
        );
    }
    isStartingSoon() {
        return (
            !this.isMaintenanceActive() &&
            this.initiate &&
            this.initiate.getTime() - new Date().getTime() < DAYMS
        );
    }
    isMaintenanceActive() {
        // for performance reasons we need to check if maintenance is active also by
        // checking if NOW is between the dates. Otherwise we will need to refresh maintenance
        // list every second to update data
        return (
            this.isActive ||
            (this.initiate &&
                this.initiate.getTime() < new Date().getTime() &&
                this.deadline &&
                new Date().getTime() < this.deadline?.getTime())
        );
    }
}

export type CcMaintenanceInfoProps = {
    cluster_id?: number;
    hostname?: string;
    is_active?: boolean;
    maintenance_periods?: CcMaintenancePeriodProps[];
};

export default class CcMaintenanceInfo {
    public readonly clusterId?: number;
    public readonly hostname?: string;
    public readonly isActive?: boolean;
    public readonly maintenancePeriods: CcMaintenancePeriod[];
    constructor(props: CcMaintenanceInfoProps) {
        this.clusterId = props.cluster_id;
        this.hostname = props.hostname;
        this.isActive = props.is_active;
        this.maintenancePeriods = props.maintenance_periods
            ? props.maintenance_periods.map((m) => new CcMaintenancePeriod(m))
            : [];
    }

    public getKey() {
        return `${this.clusterId ? this.clusterId : this.hostname}`;
    }

    public getMaintenancePeriods() {
        const periods = this.maintenancePeriods;
        periods.sort(getSortDateFn('ascend', (a) => a.initiate));
        return periods;
    }
}
