import React from 'react';
import { Form, Input } from 'antd';
import { FormInstance, FormItemProps } from 'antd/lib/form';
import { getObjectValueByPath } from '../../objectHelpers';

export default SnapshotRepositoryNameField;
export type SnapshotRepositoryNameFieldProps = FormItemProps & {
    form: FormInstance;
};

function SnapshotRepositoryNameField({
    form,
    name = 'name',
    ...rest
}: SnapshotRepositoryNameFieldProps) {
    return (
        <div className="SnapshotRepositoryNameField">
            <Form.Item
                noStyle={true}
                shouldUpdate={(prev, current) => {
                    const path = Array.isArray(name) ? name : [name];
                    const currentValue = getObjectValueByPath(path, current);
                    if (currentValue && currentValue?.indexOf(' ') > -1) {
                        form.setFields([
                            {
                                name: path,
                                value: currentValue?.replaceAll(' ', '-'),
                            },
                        ]);
                        return true;
                    }
                    return false;
                }}
            >
                {() => {
                    return null;
                }}
            </Form.Item>
            <Form.Item
                name={name}
                label="Repository name"
                rules={[
                    {
                        required: true,
                        message: 'Please enter repository name',
                    },
                ]}
                {...rest}
            >
                <Input placeholder="Name your repository" />
            </Form.Item>
        </div>
    );
}
