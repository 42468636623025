

export interface CmonFileContentProps {
    as_string?: string;
    end_index?: number;
    start_index?: number;
}


export interface CmonFileProps  {
    access?: number;
    changed?: string;
    content?: CmonFileContentProps;
    exists?: boolean;
    full_path?: string;
    group?: string;
    hard_links?: number;
    host_name?: string;
    modified?: string;
    size?: number;
    used?: string;
    user?: string;

}

export class CmonFileContent {
    public asString?: string;
    public endIndex?: number;
    public startIndex?: number;

    constructor(props: CmonFileContentProps) {
        this.asString = props.as_string;
        this.endIndex = props.end_index;
        this.startIndex = props.start_index;

    }}



export default class CmonFile {
    public access?: number;
    public changed?: string;
    public content?: CmonFileContent;
    public exists?: boolean;
    public fullPath?: string;
    public group?: string;
    public hardLinks?: number;
    public hostName?: string;
    public modified?: string;
    public size?: number;
    public used?: string;
    public user?: string;


    constructor(props: CmonFileProps) {
        this.access = props.access;
        this.changed = props.changed;
        this.content = props.content && new CmonFileContent(props.content);
        this.exists = props.exists;
        this.fullPath = props.full_path;
        this.group = props.group;
        this.hardLinks = props.hard_links;
        this.hostName = props.host_name;
        this.modified = props.modified;
        this.size = props.size;
        this.used = props.used;
        this.user = props.user;

    }

}
