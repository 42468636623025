import React, { useEffect, useMemo, useRef, useState } from 'react';
import MonitorToolbar, { MonitorToolbarApi } from '../Monitor/MonitorToolbar';
import SpaceWide from '../../common/SpaceWide';
import moment from 'moment';
import CcCluster from '../../services/models/CcCluster';
import CcDbStats from '../../services/models/CcDbStats';
import { Button, Form, Select } from 'antd';
import { DefaultOptionType } from 'rc-select/lib/Select';
import FormItem from '../../common/DataEntry/FormItem';
import { getSortDateFn } from '../../common/sorting';
import useFetch from '../../common/useFetch';
import CmonStatService from '../../services/cmon/CmonStatService';
import AppColumnChart, {
    AppColumnChartDataPoint,
} from '../../common/DataDisplay/AppColumnChart';
import CcDbStatsDb from '../../services/models/CcDbStatsDb';
import { CmonJobInstanceCommand } from '../../services/cmon/models/CmonJobInstance';
import {
    formatMonitorValue,
    MonitorValueFormatType,
} from '../Monitor/MonitorValueFormat';
import DbGrowthTopDbsTable from './DbGrowthTopDbsTable';
import DbGrowthDbDataPage from './DbGrowthDbDataPage';
import JobCreateButton from '../Jobs/JobCreateButton';
import { LeftOutlined } from '@ant-design/icons';
import AppSpin from '../../common/General/AppSpin';

export default DbGrowthPage;

export type DbGrowthPageProps = {
    cluster: CcCluster;
};

function DbGrowthPage({ cluster }: DbGrowthPageProps) {
    const monitorToolbarRef = useRef<MonitorToolbarApi>(null);
    const range = 60 * 60 * 24 * 31; // 1 month
    const [startTs, setStartTs] = useState<number>(
        moment().endOf('day').unix() - range
    );
    const [endTs, setEndTs] = useState<number>(moment().endOf('day').unix());
    const [selectedDayData, setSelectedDayData] = useState<CcDbStats>();
    const [daysOptions, setDaysOptions] = useState<DefaultOptionType[]>([]);
    const [loadingDay, setLoadingDay] = useState<boolean>(false);
    const [selectedOption, setSelectedOption] = useState<
        DefaultOptionType | undefined
    >({
        label: 'DB Growth',
        value: 'dbgrowth',
    });
    const [selectedDbOption, setSelectedDbOption] = useState<
        DefaultOptionType
    >();
    const { refresh, data, loading } = useFetch<CcDbStats[]>({
        fetchFn: async ({ startTs: sTs, endTs: eTs }) => {
            const response = await CmonStatService.getDbGrowth({
                cluster_id: cluster.clusterId,
                startdate: sTs || startTs,
                enddate: eTs || endTs,
            });
            return response.data;
        },
    });
    const [chartData, setChartData] = useState<
        AppColumnChartDataPoint<CcDbStats>[]
    >([]);

    useEffect(() => {
        (async () => {
            await refresh({});
        })();
    }, []);
    useEffect(() => {
        if (data) {
            const lastDataPoint = data.slice(-1)[0] as CcDbStats;
            setSelectedDayData(lastDataPoint);
            const daysOpts = data
                .filter((stat) => stat && stat.dbs)
                .map((stat) => ({
                    label: `${moment(stat.createdDate).format(
                        'dddd, MMMM Do YYYY'
                    )}`,
                    value: stat?.created as string,
                    extra: stat,
                }));
            daysOpts.sort(getSortDateFn('descend', (a) => a.extra.createdDate));
            setDaysOptions(daysOpts);

            setChartData(
                (data || []).reduce(
                    (
                        acc: AppColumnChartDataPoint<CcDbStats>[],
                        curr: CcDbStats
                    ) => {
                        acc.push(
                            {
                                category: moment(curr.createdDate).format(
                                    'MMMM Do YYYY'
                                ),
                                type: 'Index size',
                                value: curr.indexSize as number,
                                extra: curr,
                            },
                            {
                                category: moment(curr.createdDate).format(
                                    'MMMM Do YYYY'
                                ),
                                type: 'Data size',
                                value: curr.dataSize as number,
                                extra: curr,
                            }
                        );
                        return acc;
                    },
                    []
                )
            );
        } else {
        }
    }, [data]);

    const handleTimeRangeChange = async (from: number, to: number) => {
        setStartTs(from);
        setEndTs(to);
        refresh({ startTs: from, endTs: to });
    };

    const handlePlotClick = (dataPoint: any) => {
        // dbs info are already in memory and the user may may not notice the change
        // when he/she clicks the colum in chart and the table below is loaded
        // fake loading will be a better UX
        setLoadingDay(true);
        setTimeout(() => {
            setLoadingDay(false);
            setSelectedDayData(dataPoint[0].data.extra);
        }, 500);
    };

    const selectOptions = useMemo(
        () => [
            {
                label: 'DB Growth',
                value: 'dbgrowth',
            },
            {
                label: 'Top 10 largest tables',
                value: 'top10tables',
            },
        ],
        []
    );
    const dbSelectOptions = useMemo(() => {
        const optionList = selectedDayData?.dbs?.map((db) => ({
            label: db.dbName,
            value: db.dbName,
            extra: db,
        }));
        setSelectedDbOption(optionList?.[0]);
        return optionList;
    }, [selectedDayData]);

    const handleSelectDbChange = (
        value: string,
        option: DefaultOptionType | DefaultOptionType[]
    ) => {
        setSelectedDbOption(option as DefaultOptionType);
    };
    const handleSelectDayChange = (
        value: string,
        option: DefaultOptionType | DefaultOptionType[]
    ) => {
        setSelectedDayData((option as DefaultOptionType).extra);
    };

    const handleRecordView = (db: CcDbStatsDb) => {
        setSelectedOption(
            selectOptions.find((opt) => opt.value === 'top10tables')
        );
        setSelectedDbOption(
            dbSelectOptions?.find((opt) => opt.value === db.dbName)
        );
    };

    const handleBackClick = () => {
        setSelectedOption(
            selectOptions.find((opt) => opt.value === 'dbgrowth')
        );
    };
    const handleTopTablesClick = () => {
        setSelectedOption(
            selectOptions.find((opt) => opt.value === 'top10tables')
        );
    };
    return (
        <AppSpin spinning={loading}>
            <SpaceWide direction="vertical" size={0} className="DbGrowthPage">
                <SpaceWide justify="space-between">
                    <Form layout="vertical">
                        <SpaceWide>
                            {selectedOption?.value === 'top10tables' ? (
                                <FormItem>
                                    <SpaceWide>
                                        <Button
                                            onClick={handleBackClick}
                                            icon={<LeftOutlined />}
                                        >
                                            Db growth
                                        </Button>
                                        <h3 style={{ display: 'inline' }}>
                                            Top largest tables in
                                        </h3>
                                    </SpaceWide>
                                </FormItem>
                            ) : (
                                <FormItem>
                                    <Button onClick={handleTopTablesClick}>
                                        Top largest tables
                                    </Button>
                                </FormItem>
                            )}

                            {selectedOption?.value === 'top10tables' ? (
                                <FormItem>
                                    <Select
                                        style={{ minWidth: 200 }}
                                        value={
                                            selectedDbOption?.value as string
                                        }
                                        options={dbSelectOptions}
                                        onChange={handleSelectDbChange}
                                    />
                                </FormItem>
                            ) : null}
                        </SpaceWide>
                    </Form>

                    {selectedOption?.value === 'dbgrowth' ? (
                        <FormItem>
                            <MonitorToolbar
                                ref={monitorToolbarRef}
                                timeRange={endTs - startTs}
                                onTimeRangeChange={handleTimeRangeChange}
                                // onTimeRangeShift={handleTimeRangeShift}
                                selectors={[]}
                                autoRefresh={false}
                                minimalUnit="day"
                                timeRangeProps={{
                                    timeRangeFormProps: {
                                        minRange: 86400 /* DAY */,
                                    },
                                }}
                                extra={
                                    <JobCreateButton
                                        title="Update db growth data"
                                        command={
                                            CmonJobInstanceCommand.CALCULATE_DB_GROWTH
                                        }
                                        clusterId={cluster.clusterId}
                                    >
                                        Update data in controller
                                    </JobCreateButton>
                                }
                            />
                        </FormItem>
                    ) : (
                        <FormItem>
                            <Select
                                value={selectedDayData?.created}
                                options={daysOptions}
                                onChange={handleSelectDayChange}
                            ></Select>
                        </FormItem>
                    )}
                </SpaceWide>
                {selectedOption?.value === 'dbgrowth' ? (
                    <SpaceWide direction="vertical" size={32}>
                        <AppColumnChart
                            data={chartData}
                            valueFormatter={(v) =>
                                formatMonitorValue?.(
                                    v as number,
                                    MonitorValueFormatType.SIZE
                                )
                            }
                            onClick={handlePlotClick}
                        />
                        <AppSpin spinning={loadingDay}>
                            {selectedDayData ? (
                                <DbGrowthTopDbsTable
                                    data={selectedDayData}
                                    onRecordView={handleRecordView}
                                />
                            ) : null}
                        </AppSpin>
                    </SpaceWide>
                ) : (
                    <DbGrowthDbDataPage
                        clusterId={cluster.clusterId as number}
                        dayData={selectedDayData}
                        dbName={selectedDbOption?.value as string}
                    />
                )}
            </SpaceWide>
        </AppSpin>
    );
}
