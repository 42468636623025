import React from 'react';
import { Col, Form, Input, Row, Space } from 'antd';
import InfoIcon from '@severalnines/bar-frontend-components/build/lib/General/InfoIcon';
import { FormInstance } from 'antd/lib/form';
import PasswordInput from '../../../../common/DataEntry/PasswordInput';
import NodeDataDirectoryField from '../FormParts/NodeDataDirectoryField';
import VendorRepositoryField from '../../../../common/Form/Fields/VendorRepositoryField';
import SslEncryptionField from '../FormParts/SslEncryptionField';
import { CcClusterType } from '../../../../services/models/CcCluster';

export default PostgreSqlNodeConfiguration;

export type PostgreSqlNodeConfigurationProps = {
    form: FormInstance;
    hasRepository?: boolean;
    hasDataDir?: boolean;
    hasSslEncryption?: boolean;
};

function PostgreSqlNodeConfiguration({
    form,
    hasRepository = true,
    hasDataDir = true,
    hasSslEncryption = false,
}: PostgreSqlNodeConfigurationProps) {
    return (
        <div className="PostgreSqlNodeConfiguration">
            <Row gutter={[24, 0]}>
                <Col span={24}>
                    <h3>Node configuration</h3>
                </Col>
                <Col xs={24} sm={24} md={12}>
                    <Form.Item
                        name={['nodeConfig', 'serverPort']}
                        label={
                            <Space>
                                Server port
                                <InfoIcon info="The PostgreSQL server port." />
                            </Space>
                        }
                        rules={[
                            {
                                required: true,
                                message:
                                    'Please enter the PostgreSQL service port.',
                            },
                        ]}
                    >
                        <Input placeholder="Enter server port" />
                    </Form.Item>
                </Col>
                {hasDataDir ? (
                    <Col xs={24} sm={24} md={12}>
                        <NodeDataDirectoryField
                            label={
                                <Space>
                                    Server data directory
                                    <InfoIcon info="When not specified package default directory will be used." />
                                </Space>
                            }
                        />
                    </Col>
                ) : null}

                <Col xs={24} sm={24} md={12}>
                    <Form.Item
                        name={['nodeConfig', 'adminUser']}
                        label={
                            <Space>
                                User
                                <InfoIcon info="User that will be used for monitoring" />
                            </Space>
                        }
                        rules={[
                            {
                                required: true,
                                message: 'Please enter monitoring username.',
                            },
                        ]}
                    >
                        <Input placeholder="Enter monitoring username" />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12}>
                    <Form.Item
                        name={['nodeConfig', 'adminPassword']}
                        label={
                            <Space>
                                Password
                                <InfoIcon info="Password for monitoring user." />
                            </Space>
                        }
                        rules={[
                            {
                                required: true,
                                message:
                                    'Please enter monitoring user password.',
                            },
                        ]}
                    >
                        <PasswordInput
                            autoComplete="new-password"
                            placeholder="Enter monitoring user password"
                        />
                    </Form.Item>
                </Col>
                {hasRepository ? (
                    <Col xs={24} sm={24} md={12}>
                        <VendorRepositoryField
                            name={['nodeConfig', 'repository']}
                            form={form}
                        />
                    </Col>
                ) : null}
            </Row>
            {hasSslEncryption ? (
                <Row gutter={[24, 0]}>
                    <Col xs={24} sm={24} md={12}>
                        <SslEncryptionField />
                    </Col>
                </Row>
            ) : null}
        </div>
    );
}
