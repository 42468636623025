import CmonProxySqlUser, {
    CmonProxySqlUserProps,
} from '../cmon/models/CmonProxySqlUser';

export interface CcProxySqlUserProps extends CmonProxySqlUserProps {}

export default class CcProxySqlUser extends CmonProxySqlUser {
    constructor(props: CcProxySqlUserProps) {
        super(props);
    }

    public getKey() {
        return `${this.username || ''}-${this.backend}-${this.frontend}`;
    }
}
