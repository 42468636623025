import React from 'react';
import './AppSubsectionWrapper.less';
import { Tabs } from 'antd';

export default AppSubsectionWrapper;

export type AppSubsectionWrapperProps = {
    items: any[];
    activeSection?: string;
    base?: string;
    querySearch?: string;
    onNavigate?: (key: string) => void;
};

function AppSubsectionWrapper({
    items,
    activeSection,
    onNavigate,
}: AppSubsectionWrapperProps) {
    return (
        <Tabs
            className="AppSubsectionWrapper"
            onTabClick={(key) => {
                onNavigate?.(key);
            }}
            tabPosition="left"
            activeKey={activeSection}
            destroyInactiveTabPane={true}
        >
            {items.map((item) => (
                <Tabs.TabPane key={item.key} tab={item.label}>
                    {item.children}
                </Tabs.TabPane>
            ))}
        </Tabs>
    );
}
