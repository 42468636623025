import React, { useState, useMemo, useCallback } from 'react';
import { Button, Menu, Dropdown } from 'antd';
import { MenuInfo } from 'rc-menu/lib/interface';
import { ReloadIcon } from '../icons/icons';
import useCountdownTimer from './useCountdownTimer';
import DurationFormat from '@severalnines/bar-frontend-components/build/lib/Format/DurationFormat';

type RefreshButtonProps = {
    timeOptionsMenu?: number[]; // time in millisecond
    onRefresh: () => void;
    isNeverRefresh?: boolean;
    isMenu?: boolean;
};

export default RefreshButton;

function RefreshButton({
    timeOptionsMenu = [10000, 20000, 30000, 60000, 300000],
    isNeverRefresh,
    isMenu,
    onRefresh,
}: RefreshButtonProps) {
    const { secondsRemaining, stop, autoRefresh } = useCountdownTimer();
    const [isRefresh, setIsRefresh] = useState<boolean>(false);

    const handleMenuChange = useCallback((e: MenuInfo) => {
        if (e.key !== 'Never') {
            const time: number = +e.key;
            autoRefresh({
                time,
                repeated: true,
                onRefresh: onRefresh,
            });
            setIsRefresh(true);
        } else {
            setIsRefresh(false);
            stop();
        }
    }, []);

    const menu = useMemo(
        () => (
            <Menu
                onClick={handleMenuChange}
                items={[
                    ...timeOptionsMenu?.map((time) => ({
                        key: time,
                        label: (
                            <DurationFormat
                                precision={1}
                                languages={{
                                    shortEn: {
                                        s: () => 'sec',
                                    },
                                }}
                            >
                                {time}
                            </DurationFormat>
                        ),
                    })),
                    ...(isNeverRefresh
                        ? [{ key: 'Never', label: 'Never refresh' }]
                        : []),
                ]}
            />
        ),
        [timeOptionsMenu, isNeverRefresh, isMenu]
    );
    return isMenu ? (
        <Dropdown overlay={menu} trigger={['click']}>
            <Button>
                <ReloadIcon />{' '}
                {isRefresh ? (
                    <DurationFormat
                        precision={1}
                        languages={{
                            shortEn: {
                                s: () => 'sec',
                            },
                        }}
                        short
                        noSpace={false}
                        units={['s']}
                    >
                        {secondsRemaining * 1000}
                    </DurationFormat>
                ) : (
                    'Refresh'
                )}
            </Button>
        </Dropdown>
    ) : (
        <Button onClick={() => onRefresh()}>
            <ReloadIcon />
        </Button>
    );
}
