import {
    ArrayParam,
    NumberParam,
    QueryParamConfigMap,
    StringParam,
    useQueryParams,
} from 'use-query-params';
import { useMemo, useState } from 'react';

const DEFAULT_URL_PARAM_TYPES = {
    sort: StringParam,
    order: StringParam,
    page: NumberParam,
};

export type UseTableFilterProps = {
    params: QueryParamConfigMap;
    useUrl?: boolean;
    defaultParams?: any;
};

// @todo adjust types to access types easier
export default function useTableFilter({
    params,
    useUrl = true,
    defaultParams = {},
}: UseTableFilterProps) {
    const localParams = useMemo(
        () => ({
            ...DEFAULT_URL_PARAM_TYPES,
            ...params,
        }),
        [params]
    );

    const [urlParams, setQuery]: any = useQueryParams(localParams);

    const [stateParams, setStateParams] = useState({});

    const filterParams = useMemo(() => {
        let queryParams: any;
        if (useUrl) {
            queryParams = { ...urlParams };
        } else {
            queryParams = { ...stateParams };
        }
        // convert StringParam to ArrayParam in tableParams
        Object.keys(queryParams).forEach((key) => {
            if (
                queryParams[key] &&
                typeof queryParams[key] === 'string' &&
                params[key] === ArrayParam
            ) {
                queryParams[key] = queryParams[key].split(',');
            }
        });
        Object.keys(defaultParams).forEach((key) => {
            if (!queryParams[key]) {
                queryParams[key] = defaultParams[key];
            }
        });
        return queryParams;
    }, [urlParams, stateParams]);

    const prepareParams = (params: any) => {
        const modifiedParams = { ...params };
        Object.keys(modifiedParams).forEach((key) => {
            if (
                modifiedParams[key] === null ||
                JSON.stringify(modifiedParams[key]) ===
                    JSON.stringify(defaultParams[key]) ||
                !localParams.hasOwnProperty(key)
            ) {
                modifiedParams[key] = undefined;
            }
        });
        return modifiedParams;
    };

    const handleTableChange = (pagination: any, filter: any, sorters: any) => {
        const tableParams = {
            ...filterParams,
            sort: sorters.order ? sorters.columnKey : undefined,
            order: sorters.order,
            page: pagination.current > 0 ? pagination.current : undefined,
            ...filter,
        };

        if (useUrl) {
            setQuery(prepareParams(tableParams));
        } else {
            setStateParams(tableParams);
        }
    };

    const addFilterParams = (params: any) => {
        if (useUrl) {
            setQuery(prepareParams({ ...filterParams, ...params }));
        } else {
            setStateParams(prepareParams({ ...filterParams, ...params }));
        }
    };

    const resetFilters = () => {
        if (useUrl) {
            const resetParams = { ...filterParams };
            Object.keys(resetParams).forEach((key) => {
                if (resetParams[key]) {
                    resetParams[key] = undefined;
                }
            });
            setQuery(resetParams);
        } else {
            setStateParams({});
        }
    };

    return {
        filterParams,
        urlParams,
        handleTableChange,
        resetFilters,
        addFilterParams,
    };
}
