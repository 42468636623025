import React, { useMemo } from 'react';
import { ButtonProps } from 'antd/lib/button';
import { CmonJobInstanceCommand } from '../../services/cmon/models/CmonJobInstance';
import JobConfirmActionButton from '../Jobs/JobConfirmActionButton';
import CcCluster from '../../services/models/CcCluster';

export default InstallCmonAgentsButton;

export type EnableQueryMonitorButtonProps = ButtonProps & {
    cluster: CcCluster;
    children?: React.ReactNode;
    onSuccess?: () => void;
};

function InstallCmonAgentsButton({
    cluster,
    children,
    onSuccess,
    ...rest
}: EnableQueryMonitorButtonProps) {
    const jobData = useMemo(() => ({}), []);
    return (
        <JobConfirmActionButton
            clusterId={cluster.clusterId}
            command={CmonJobInstanceCommand.DEPLOY_CMONAGENTS}
            confirmTitle={`ClusterControl will install the agent in all the nodes in a default path. Is it ok?`}
            title={`Install monitoring agent`}
            jobData={jobData}
            onSuccess={onSuccess}
            {...rest}
        />
    );
}
