
import CmonRequestService, {CmonRequestServiceResponseData} from './CmonRequestService';
import {CmonReportProps} from './models/CmonReport'


export interface GenerateReportRequestData {
    cluster_id?: number;
    report?: CmonReportProps;
    request_created?: string;
    request_id?: number;
    [key: string]: any;
}

export interface GetReportRequestData {
    report?: CmonReportProps;
    request_created?: string;
    request_id?: number;
    [key: string]: any;
}

export interface GetReportTemplatesRequestData {
    request_created?: string;
    request_id?: number;
    [key: string]: any;
}

export interface DeleteReportRequestData {
    cluster_id?: number;
    report?: CmonReportProps;
    request_created?: string;
    request_id?: number;
    [key: string]: any;
}

export interface GetReportsRequestData {
    cluster_id?: number;
    request_created?: string;
    request_id?: number;
    [key: string]: any;
}

export interface ListErrorReportsRequestData {
    cluster_id?: number;
    [key: string]: any;
}

export interface RemoveErrorReportRequestData {
    cluster_id?: number;
    id?: number;
    [key: string]: any;
}

export interface DownloadErrorReportRequestData {
    cluster_id?: number;
    id?: number;
    [key: string]: any;
}

export interface AddScheduleRequestData {
    cluster_id?: number;
    name?: string;
    schedule?: string;
    recipients?: string;
    [key: string]: any;
}

export interface RemoveScheduleRequestData {
    id?: number;
    [key: string]: any;
}

export interface ListSchedulesRequestData {

    [key: string]: any;
}


export default class CmonReportsService extends CmonRequestService {
    
    public static module(): string {
        return 'reports';
    }
    
    public static async generateReport(
        data: GenerateReportRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('generateReport', data, opts);
    }

    public static async getReport(
        data: GetReportRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('getReport', data, opts);
    }

    public static async getReportTemplates(
        data: GetReportTemplatesRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('getReportTemplates', data, opts);
    }

    public static async deleteReport(
        data: DeleteReportRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('deleteReport', data, opts);
    }

    public static async getReports(
        data: GetReportsRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('getReports', data, opts);
    }

    public static async listErrorReports(
        data: ListErrorReportsRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('listErrorReports', data, opts);
    }

    public static async removeErrorReport(
        data: RemoveErrorReportRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('removeErrorReport', data, opts);
    }

    public static async downloadErrorReport(
        data: DownloadErrorReportRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('downloadErrorReport', data, opts);
    }

    public static async addSchedule(
        data: AddScheduleRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('addSchedule', data, opts);
    }

    public static async removeSchedule(
        data: RemoveScheduleRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('removeSchedule', data, opts);
    }

    public static async listSchedules(
        data: ListSchedulesRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('listSchedules', data, opts);
    }

}
