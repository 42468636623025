import React from 'react';
import { TopologySummaryProps } from '../../TopologySummary';
import AppDivider from '../../../../../common/AppDivider';
import MongoShardsDeploymentShardsSummary from './MongoShardsDeploymentShardsSummary';
import MongoShardsConfigurationServerTopologySummary from '../../MongoShards/MongoShardsConfigurationServerTopologySummary';
import MongoShardsRouterTopologySummary from '../../MongoShards/MongoShardsRouterTopologySummary';

export default MongoShardsDeploymentTopologySummary;

export type MongoShardsDeploymentTopologySummaryProps = TopologySummaryProps & {};

function MongoShardsDeploymentTopologySummary({
    form,
    ...props
}: MongoShardsDeploymentTopologySummaryProps) {
    const shards = form.getFieldValue('shards');
    const shardKeys: string[] = Object.keys(shards).filter(
        (k) => shards[k] !== undefined && shards[k].replicaSetName
    );
    shardKeys.sort();
    return (
        <div className="MongoShardsDeploymentTopologySummary">
            <MongoShardsConfigurationServerTopologySummary form={form} />
            <AppDivider />
            <MongoShardsRouterTopologySummary form={form} />
            <AppDivider />
            <MongoShardsDeploymentShardsSummary form={form} {...props} />
        </div>
    );
}
