import React from 'react';
import { ACLManageClusterContent, ACLManageContent } from './useCurrentUser';
import CcCluster from '../../services/models/CcCluster';

export default UserAclManageCluster;

export type UserAclManageClusterProps = {
    cluster?: CcCluster;
    children?: React.ReactNode;
};

function UserAclManageCluster({ cluster, ...rest }: UserAclManageClusterProps) {
    return cluster ? (
        <ACLManageClusterContent cluster={cluster} {...rest} />
    ) : (
        <ACLManageContent path={'/*'} {...rest} />
    );
}
