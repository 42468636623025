import React from 'react';

import SshCheckableNodesInput, {
    SshCheckableNodesInputProps,
} from './SshCheckeableNodesInput';
import { DefaultOptionType } from 'rc-select/lib/Select';

export default SshCheckableNodesAutocomplete;

export type SshCheckableNodesAutocompleteProps = SshCheckableNodesInputProps & {
    autocompleteOptions?: DefaultOptionType[];
};

function SshCheckableNodesAutocomplete({
    autocompleteOptions,
    ...rest
}: SshCheckableNodesAutocompleteProps) {
    const { formProps, ...moreRest } = rest;
    return (
        <SshCheckableNodesInput
            formProps={{
                primaryInputProps: {
                    enableAutocomplete: true,
                    autocompleteOptions,
                },
                primaryPlaceholder: 'Select or type hostname',
                ...formProps,
            }}
            checkSelectedHost={false}
            {...moreRest}
        />
    );
}
