import React, { useMemo } from 'react';
import { ButtonProps } from 'antd/lib/button';
import { CmonJobInstanceCommand } from '../../../services/cmon/models/CmonJobInstance';
import CcNode from '../../../services/models/CcNode';
import JobConfirmActionButton from '../../Jobs/JobConfirmActionButton';

export default NodeToggleReadonlyButton;

export type NodeToggleReadonlyButtonProps = ButtonProps & {
    node: CcNode;
    children?: React.ReactNode;
    onSuccess?: () => void;
};

function NodeToggleReadonlyButton({
    node,
    children,
    onSuccess,
    ...rest
}: NodeToggleReadonlyButtonProps) {
    const jobData = useMemo(
        () => ({
            node: { hostname: node.hostname, port: node.port },
        }),
        []
    );

    return (
        <JobConfirmActionButton
            clusterId={node.clusterid}
            command={
                node.readonly
                    ? CmonJobInstanceCommand.DISABLE_DB_READONLY
                    : CmonJobInstanceCommand.ENABLE_DB_READONLY
            }
            confirmTitle={
                node.readonly
                    ? `Disable readonly: ${node.getName()}?`
                    : `Enable readonly: ${node.getName()}?`
            }
            title={node.readonly ? `Disable readonly` : `Enable readonly`}
            jobData={jobData}
            onSuccess={onSuccess}
            extraKey={[node.hostname, node.port]}
            {...rest}
        />
    );
}
