
import CmonRequestService, {CmonRequestServiceResponseData} from './CmonRequestService';


export interface GetEntriesRequestData {
    ascending?: boolean;
    cluster_ids?: [];
    request_created?: string;
    request_id?: number;
    [key: string]: any;
}


export default class CmonAuditService extends CmonRequestService {
    
    public static module(): string {
        return 'audit';
    }
    
    public static async getEntries(
        data: GetEntriesRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('getEntries', data, opts);
    }

}
