import AppTable from '../../../common/DataDisplay/AppTable';
import React, { useContext, useMemo } from 'react';
import CcAdvisorSchedule, {
    AdvisorExitStatus,
    getAdvisorSeverityPriority,
} from '../../../services/models/CcAdvisorSchedule';
import AdvisorStatusFormat from './AdvisorStatusFormat';
import { ResponsiveContext } from '@severalnines/bar-frontend-components/build/lib/Layout/Responsive';
import useTableFilterColumns from '../../../common/hooks/useTableFilterColumns';
import TagsList from '../../../common/DataDisplay/TagsList';
import AppEmpty from '../../../common/Feedback/AppEmpty';
import NodeFormat from '../../Nodes/NodeFormat';
import CcNode from '../../../services/models/CcNode';
import AppTooltip from '../../../common/Feedback/AppTooltip';
import useAdvisorStatuses from './useAdvisorStatuses';
import useAdvisorTags from './useAdvisorTags';
import { TableProps } from 'antd';
import AdvisorFormat from './AdvisorFormat';
import { clearTags } from '../../../common/utils/string';
import { getSortAlphabeticFn, getSortNumberFn } from '../../../common/sorting';

export default AdvisorResultsTable;

type AdvisorResultsTableRow = AdvisorExitStatus & {
    schedule: CcAdvisorSchedule;
};

type AdvisorResultsTableProps = {
    schedules: CcAdvisorSchedule[];
    loading?: boolean;
    loaded?: boolean;
    onChange?: TableProps<any>['onChange'];
    filterParams?: any;
    disableFilters?: boolean;
};

function AdvisorResultsTable({
    schedules,
    loading,
    loaded,
    onChange,
    filterParams,
    disableFilters,
}: AdvisorResultsTableProps) {
    const { responsive } = useContext(ResponsiveContext);

    const list: AdvisorResultsTableRow[] = useMemo(() => {
        return schedules
            .map((item) =>
                Object.values(item.exitStatus).map((status) => ({
                    ...status,
                    advice: clearTags(status.advice || ''),
                    justification: clearTags(status.justification || ''),
                    schedule: item,
                    title: status.title || item.file?.filename,
                }))
            )
            .flat();
    }, [schedules]);

    const nodes: CcNode[] = useMemo(() => {
        const nodes = list?.reduce((acc, item) => {
            if (item.host) {
                acc[item.host.getHostWithPort()] = item.host;
            }
            return acc;
        }, {} as any);
        return Object.values(nodes);
    }, [list]);

    const tags = useAdvisorTags({ schedules });
    const statues = useAdvisorStatuses({ schedules });

    const { columns } = useTableFilterColumns({
        disableFilters,
        columns: useMemo(
            () => [
                {
                    title: 'Advisor',
                    key: 'advisor',
                    sorter: (
                        a: AdvisorResultsTableRow,
                        b: AdvisorResultsTableRow
                    ) => {
                        return getSortAlphabeticFn(
                            'ascend',
                            (x: AdvisorResultsTableRow) => {
                                return x.schedule?.getTitle() || '';
                            }
                        )?.(a, b);
                    },
                    ellipsis: { showTitle: true },
                    filters: schedules
                        .sort((a: CcAdvisorSchedule, b: CcAdvisorSchedule) => {
                            return getSortAlphabeticFn(
                                'ascend',
                                (x: CcAdvisorSchedule) => {
                                    return x?.getTitle() || '';
                                }
                            )?.(a, b);
                        })
                        .map((item) => ({
                            value: item.scheduleid,
                            text:
                                item.getCurrentStatus()?.title ||
                                item.file?.filename,
                        })),
                    width: 220,
                    onFilter: (
                        value: number,
                        record: AdvisorResultsTableRow
                    ) => {
                        return record.schedule.scheduleid === value;
                    },
                    render: (record: AdvisorResultsTableRow) => (
                        <AdvisorFormat advisorSchedule={record.schedule} />
                    ),
                },
                {
                    title: 'Tags',
                    key: 'tags',
                    width: 140,
                    filters: tags.map((tag) => ({
                        value: tag,
                        text: tag,
                    })),
                    onFilter: (
                        value: string,
                        record: AdvisorResultsTableRow
                    ) => {
                        return record.schedule?.tags?.includes(value);
                    },
                    render: (record: AdvisorResultsTableRow) =>
                        (record.schedule.tags?.length || 0) > 0 && (
                            <TagsList tags={record.schedule.tags || []} />
                        ),
                },
                {
                    title: 'Instance',
                    key: 'instance',
                    width: 'auto',
                    ellipsis: { showTitle: true },
                    filters: nodes.map((node) => ({
                        value: node.getHostWithPort(),
                        text: <NodeFormat node={node} showRole={false} />,
                    })),
                    onFilter: (
                        value: string,
                        record: AdvisorResultsTableRow
                    ) => {
                        return record.host?.getHostWithPort() === value;
                    },
                    render: (record: AdvisorResultsTableRow) =>
                        record.host && (
                            <NodeFormat node={record.host} showRole={false} />
                        ),
                },
                {
                    title: 'Status',
                    key: 'status',
                    width: 100,
                    sorter: (
                        a: AdvisorResultsTableRow,
                        b: AdvisorResultsTableRow
                    ) => {
                        return getSortNumberFn(
                            'ascend',
                            (x: AdvisorResultsTableRow) => {
                                return getAdvisorSeverityPriority(x.severity);
                            }
                        )?.(a, b);
                    },
                    filters: statues.map((status) => ({
                        value: status,
                        text: (
                            <AdvisorStatusFormat
                                status={status}
                                nowrap={true}
                            />
                        ),
                    })),
                    onFilter: (
                        value: string,
                        record: AdvisorResultsTableRow
                    ) => {
                        return record.schedule.statusTitle === value;
                    },
                    render: (record: AdvisorResultsTableRow) => (
                        <AdvisorStatusFormat
                            status={record.schedule.statusTitle}
                            nowrap={true}
                        />
                    ),
                },
                {
                    title: 'Justification',
                    key: 'justification',
                    ellipsis: { showTitle: false },
                    render: (record: AdvisorResultsTableRow) => (
                        <AppTooltip title={record.justification}>
                            {record.justification}
                        </AppTooltip>
                    ),
                },
                {
                    title: 'Advice',
                    key: 'advice',
                    ellipsis: { showTitle: false },
                    render: (record: AdvisorResultsTableRow) => (
                        <AppTooltip title={record.advice}>
                            {record.advice}
                        </AppTooltip>
                    ),
                },
            ],
            [schedules, filterParams]
        ),
        filterParams,
    });

    return (
        <AppTable
            loading={loading}
            loaded={loaded}
            onChange={onChange}
            size={'middle'}
            className="AdvisorResultsTable"
            rowKey={(record) => record.filename}
            dataSource={list}
            columns={columns}
            pagination={{ pageSize: 40 }}
            responsive={responsive}
            onRow={() => {}}
            renderEmpty={
                <AppEmpty loading={loading} description="Nothing to show yet" />
            }
        />
    );
}
