import React, { useContext, useEffect } from 'react';
import './ProxySQLTopQueries.less';
import { ResponsiveContext } from '@severalnines/bar-frontend-components/build/lib/Layout/Responsive';
import { Button } from 'antd';
import { TablePaginationConfig } from 'antd/es';
import ActionsMenu, {
    ActionsMenuProps,
} from '../../../../../../common/Navigation/ActionsMenu';
import CmonProxysqlService from '../../../../../../services/cmon/CmonProxysqlService';
import CcCluster from '../../../../../../services/models/CcCluster';
import CcProxySqlNode from '../../../../../../services/models/CcProxySqlNode';
import useListFetch from '../../../../../../common/useListFetch';
import AppEmpty from '../../../../../../common/Feedback/AppEmpty';
import CcProxySqlTopQueries from '../../../../../../services/models/CcProxySqlTopQueries';
import DurationFormat from '@severalnines/bar-frontend-components/build/lib/Format/DurationFormat';
import ButtonWithForm from '../../../../../../common/General/ButtonWithForm';
import TopQueryFullDigestModal from './TopQueryFullDigestModal';
import ProxySQLRulesWizardModal from '../ProxySQLRules/ProxySQLRulesWizardModal';
import { RuleIdType } from '../ProxySQLRules/ProxySQLRules';
import AppTable from '../../../../../../common/DataDisplay/AppTable';

export default ProxySQLTopQueries;

export type ProxySQLTopQueriesProps = ActionsMenuProps & {
    cluster: CcCluster;
    node: CcProxySqlNode;
    queryPageSize?: number;
    hostGroupList: { value: string; label: string }[] | undefined;
    onQueriesLoad: () => Promise<void>;
    queryRuleId?: RuleIdType;
};

function ProxySQLTopQueries({
    cluster,
    node,
    queryPageSize = 15,
    hostGroupList,
    onQueriesLoad,
    queryRuleId,
    ...rest
}: ProxySQLTopQueriesProps) {
    const { responsive }: any = useContext(ResponsiveContext);

    const {
        loading,
        list: queryResponse,
        refresh: refreshQueries,
        page,
        pageSize,
        total,
    } = useListFetch({
        name: 'topqueries',
        pageSize: queryPageSize,
        fetchFn: async ({ pageSize, page, ...rest }, opts) => {
            const response = await CmonProxysqlService.topqueries(
                {
                    cluster_id: cluster.clusterId,
                    hostName: node.hostname,
                    port: node.port,
                    orderByColumn: 'sum_time',
                    sortOrder: 'desc',
                    ...rest,
                },
                opts
            );

            return {
                list: (response.queryResults as CcProxySqlTopQueries[]).map(
                    (res, index) => {
                        return { key: `${res.firstSeen} ${index}`, ...res };
                    }
                ),
                total: response.queryResultTotalCount,
            };
        },
        cancelFn: async ({ requestId }) => {
            await CmonProxysqlService.cancelRequest(requestId);
        },
    });

    useEffect(() => {
        refreshQueries();
        onQueriesLoad();
    }, []);

    const handleTableChange = async (pagination: any) => {
        await refreshQueries({
            page: pagination.current,
            pageSize: pagination.pageSize,
        });
    };

    const columns = [
        {
            title: 'Digest text',
            key: 'digestText',
            dataIndex: 'digestText',
        },
        {
            title: 'Hostgroup',
            key: 'hostgroup',
            dataIndex: 'hostgroup',
        },
        {
            title: 'Schema',
            key: 'schemaname',
            dataIndex: 'schemaname',
        },
        {
            title: 'Count star',
            key: 'countStar',
            dataIndex: 'countStar',
        },
        {
            title: 'Sum time',
            key: 'sumTime',
            render: (record: CcProxySqlTopQueries) => {
                return (
                    <DurationFormat short={true}>
                        {new Date(Number(record.sumTime)).getTime()}
                    </DurationFormat>
                );
            },
        },
        {
            title: 'Min time',
            key: 'minTime',
            render: (record: CcProxySqlTopQueries) => {
                return (
                    <DurationFormat short={true}>
                        {new Date(Number(record.minTime)).getTime()}
                    </DurationFormat>
                );
            },
        },
        {
            title: 'Max time',
            key: 'maxTime',
            render: (record: CcProxySqlTopQueries) => {
                return (
                    <DurationFormat short={true}>
                        {new Date(Number(record.maxTime)).getTime()}
                    </DurationFormat>
                );
            },
        },
        {
            title: 'First seen',
            key: 'firstSeen',
            render: (record: CcProxySqlTopQueries) => {
                return (
                    <DurationFormat short={true}>
                        {new Date(Number(record.firstSeen)).getTime()}
                    </DurationFormat>
                );
            },
        },
        {
            title: 'Last seen',
            key: 'lastSeen',
            render: (record: CcProxySqlTopQueries) => {
                return (
                    <DurationFormat short={true}>
                        {new Date(Number(record.lastSeen)).getTime()}
                    </DurationFormat>
                );
            },
        },
        {
            title: 'Actions',
            key: '',
            dataIndex: '',
            render: (record: CcProxySqlTopQueries) => (
                <ActionsMenu
                    items={[
                        {
                            key: 'create-rule',
                            label: (
                                <ButtonWithForm
                                    button={
                                        <Button type="link">Create rule</Button>
                                    }
                                    form={
                                        <ProxySQLRulesWizardModal
                                            title="Create new rule"
                                            onSuccess={onQueriesLoad}
                                            nextQueryRuleId={
                                                queryRuleId?.nextRuleId
                                            }
                                            cluster={cluster}
                                            node={node}
                                            hostGroupList={hostGroupList}
                                            topQueryCreate
                                            topQueryData={{
                                                digest: record?.digest,
                                                digestText: record?.digestText,
                                                hostgroup: `${record?.hostgroup}`,
                                                schemaName: record?.schemaname,
                                            }}
                                        />
                                    }
                                />
                            ),
                        },
                        {
                            key: 'cache-query',
                            label: (
                                <ButtonWithForm
                                    button={
                                        <Button type="link">Cache query</Button>
                                    }
                                    form={
                                        <ProxySQLRulesWizardModal
                                            title="Cache query"
                                            onSuccess={onQueriesLoad}
                                            nextQueryRuleId={
                                                queryRuleId?.nextCacheId
                                            }
                                            cluster={cluster}
                                            node={node}
                                            hostGroupList={hostGroupList}
                                            topQueryCache
                                            topQueryData={{
                                                digest: record?.digest,
                                                digestText: record?.digestText,
                                                hostgroup: `${record?.hostgroup}`,
                                                schemaName: record?.schemaname,
                                            }}
                                        />
                                    }
                                />
                            ),
                        },
                        {
                            key: 'full-digest',
                            label: (
                                <TopQueryFullDigestModal
                                    digestText={record.digestText}
                                />
                            ),
                        },
                    ]}
                    {...rest}
                />
            ),
        },
    ];

    const pagination: TablePaginationConfig = {
        size: 'default',
        pageSize,
        current: page,
        total,
        hideOnSinglePage: true,
        showQuickJumper: true,
        showSizeChanger: true,
        position: ['bottomCenter'],
    };
    return (
        <div className="ProxySQLTopQueries">
            <AppTable
                className="ProxySQLTopQueriesTable"
                size="small"
                dataSource={queryResponse}
                columns={columns}
                responsive={responsive}
                onChange={handleTableChange}
                pagination={pagination}
                loading={loading}
                renderEmpty={
                    <AppEmpty
                        loading={loading}
                        description="You don't have queries yet."
                    />
                }
            />
        </div>
    );
}
