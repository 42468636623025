import React, { Fragment } from 'react';
import { FormInstance } from 'antd/lib/form';
import SpaceDescriptions from '../../../../../common/Layout/SpaceDescriptions';
import AppDivider from '../../../../../common/AppDivider';
import { serverInstanceNodeAddress } from '../createProxySQLWizardHelper';

export type CreateProxySQLPreviewProps = {
    form: FormInstance;
    isImportConfig: boolean;
};

export default CreateProxySQLPreview;

function CreateProxySQLPreview({
    form,
    isImportConfig,
}: CreateProxySQLPreviewProps) {
    const {
        proxySQLWhere,
        proxySQLServerInstances,
        proxySQLConfiguration,
    } = form.getFieldsValue(true);
    const [address] = proxySQLWhere.address;

    return (
        <div>
            <SpaceDescriptions
                direction="vertical"
                title="Where to install"
                size="small"
                alignItems="right"
            >
                <SpaceDescriptions.Item label="Version" labelStrong>
                    {proxySQLWhere.version ?? ''}
                </SpaceDescriptions.Item>
                <SpaceDescriptions.Item label="Server Address" labelStrong>
                    {address?.extraData?.hostname ?? ''}
                </SpaceDescriptions.Item>
                {proxySQLWhere?.proxySQLInstance !== undefined &&
                    proxySQLWhere?.importConfig && (
                        <SpaceDescriptions.Item
                            label="ProxySQL Instance"
                            labelStrong
                        >
                            {proxySQLWhere?.proxySQLInstance[0]?.extraData
                                ?.hostname ?? ''}
                        </SpaceDescriptions.Item>
                    )}

                <SpaceDescriptions.Item label="Admin Port" labelStrong>
                    {proxySQLWhere.adminPort ?? ''}
                </SpaceDescriptions.Item>
                <SpaceDescriptions.Item label="Listening Port" labelStrong>
                    {proxySQLWhere.listenPort ?? ''}
                </SpaceDescriptions.Item>
                <SpaceDescriptions.Item label="Firewall" labelStrong>
                    {`${proxySQLWhere.firewall ?? ''}`}
                </SpaceDescriptions.Item>
                <SpaceDescriptions.Item label="AppArmor/SELinux" labelStrong>
                    {`${proxySQLWhere.appArmor ?? ''}`}
                </SpaceDescriptions.Item>
                <SpaceDescriptions.Item label="Native Clustering" labelStrong>
                    {`${proxySQLWhere.nativeClustering ?? ''}`}
                </SpaceDescriptions.Item>
            </SpaceDescriptions>
            <AppDivider />

            {!isImportConfig && (
                <Fragment>
                    <SpaceDescriptions
                        direction="vertical"
                        title="Configuration"
                        size="small"
                        alignItems="right"
                    >
                        <SpaceDescriptions.Item
                            label="Administration user"
                            labelStrong
                        >
                            {proxySQLConfiguration.administrationUser ?? ''}
                        </SpaceDescriptions.Item>
                        <SpaceDescriptions.Item
                            label="Monitor user"
                            labelStrong
                        >
                            {proxySQLConfiguration.monitorUser ?? ''}
                        </SpaceDescriptions.Item>
                        <SpaceDescriptions.Item
                            label="Database username"
                            labelStrong
                        >
                            {proxySQLConfiguration.dbUsername ?? ''}
                        </SpaceDescriptions.Item>
                        <SpaceDescriptions.Item
                            label="Database name"
                            labelStrong
                        >
                            {proxySQLConfiguration.dbName ?? ''}
                        </SpaceDescriptions.Item>
                        <SpaceDescriptions.Item
                            label="MySQL privilege(s)"
                            labelStrong
                        >
                            {Array.isArray(proxySQLConfiguration.sqlPrivileges)
                                ? proxySQLConfiguration.sqlPrivileges.join(', ')
                                : ''}
                        </SpaceDescriptions.Item>
                    </SpaceDescriptions>
                    <AppDivider />
                    <SpaceDescriptions
                        direction="vertical"
                        title="Server instances "
                        size="small"
                        alignItems="right"
                    >
                        {serverInstanceNodeAddress(proxySQLServerInstances).map(
                            (obj: any, index) => {
                                return (
                                    <SpaceDescriptions.Item key={index}>
                                        {`hostname: ${obj.hostname} / max_replication: ${obj.max_replication_lag} / max_connection: ${obj.max_connection} / weight: ${obj.weight} / port: ${obj.port}`}
                                    </SpaceDescriptions.Item>
                                );
                            }
                        )}
                    </SpaceDescriptions>
                </Fragment>
            )}
        </div>
    );
}
