import React, { useEffect, useState } from 'react';
import { Form } from 'antd';
import FormFooter from '../../../../common/FormFooter';
import ModalDefaultForm from '../../../../common/ModalDefaultForm';
import {
    notifyError,
    notifyOperationSuccess,
    NotifyType,
} from '../../../Notifications/uiNotification';

import CcCluster from '../../../../services/models/CcCluster';
import ClusterNameAndTags, {
    ClusterNameAndTagsValues,
} from '../../../Services/Cluster/FormParts/ClusterNameAndTags';
import AppSpin from '../../../../common/General/AppSpin';
import useClusterConfig, { ClusterConfig } from '../../useClusterConfig';
import CmonClustersService from '../../../../services/cmon/CmonClustersService';
import ClusterFormat from '../../ClusterFormat';
import SpaceWide from '../../../../common/SpaceWide';

export default ClusterEditDetailsModal;

export type ClusterEditDetailsModalProps = {
    cluster: CcCluster;
    onSuccess?: () => void;
    onCancel?: () => void;
    onError?: (err: Error) => void;
};
export type ClusterEditDetailsModalValues = ClusterNameAndTagsValues & {
    rpcToken?: string;
};

function ClusterEditDetailsModal({
    cluster,
    onSuccess,
    onCancel,
    onError,
}: ClusterEditDetailsModalProps) {
    const [form] = Form.useForm<ClusterEditDetailsModalValues>();
    const [loading, setLoading] = useState<boolean>(false);

    const {
        config,
        refresh: refreshConfig,
        loading: loadingConfig,
    } = useClusterConfig({
        clusterId: cluster.clusterId,
    });

    useEffect(() => {
        (async () => await refreshConfig({}))();
    }, []);
    const handleSubmit = async (values: ClusterEditDetailsModalValues) => {
        try {
            setLoading(true);
            await CmonClustersService.setConfig({
                configuration: [
                    {
                        name: 'name',
                        value: values.details.name,
                    },
                    {
                        name: 'tags',
                        value: values.details.tags.join(';'),
                    },
                ],
                cluster_id: cluster.clusterId,
            });

            notifyOperationSuccess({
                type: NotifyType.TOAST,
                title: <span>Cluster details edited succesfully</span>,
            });
            setLoading(false);
            onSuccess?.();
        } catch (error: any) {
            setLoading(false);
            notifyError({
                content: error.message,
            });
            onError?.(error);
        }
    };

    return (
        <ModalDefaultForm
            title="Edit cluster details"
            form={form}
            footer={[]}
            onCancel={onCancel}
            width={450}
            defaultVisible={true}
        >
            <AppSpin spinning={loadingConfig}>
                {config ? (
                    <Form
                        className="ClusterEditDetailsModal"
                        form={form}
                        layout="vertical"
                        initialValues={getClusterDetailsConfigFields(config)}
                        onFinish={handleSubmit}
                    >
                        <SpaceWide direction={'vertical'}>
                            <ClusterFormat
                                size={'large'}
                                cluster={cluster}
                                wide={true}
                            />
                            <ClusterNameAndTags
                                fullWidth={true}
                                optionalName={false}
                            />
                        </SpaceWide>

                        <FormFooter
                            loading={loading}
                            showCancelButton
                            onCancel={onCancel}
                            submitButtonText="Save"
                            showSubmitButton
                        />
                    </Form>
                ) : null}
            </AppSpin>
        </ModalDefaultForm>
    );
}

export function getClusterDetailsConfigFields(
    config: ClusterConfig
): ClusterEditDetailsModalValues {
    return {
        details: {
            name: config.name.current_value as string,
            tags: config.tags.current_value
                ? (config.tags.current_value as string).split(';')
                : [],
        },
    };
}
