


export interface CmonProxySqlServerProps  {
    Bytes_data_recv?: string;
    Bytes_data_sent?: string;
    ConnERR?: string;
    ConnFree?: string;
    ConnUsed?: string;
    Latency?: string;
    Queries?: string;
    class_name?: string;
    comment?: string;
    compression?: string;
    hostgroup_id?: string;
    hostgroup_name?: string;
    hostname?: string;
    max_connections?: string;
    max_latency_ms?: string;
    max_replication_lag?: string;
    port?: string;
    status?: string;
    use_ssl?: string;
    weight?: string;

}



export default class CmonProxySqlServer {
    public BytesDataRecv?: string;
    public BytesDataSent?: string;
    public ConnERR?: string;
    public ConnFree?: string;
    public ConnUsed?: string;
    public Latency?: string;
    public Queries?: string;
    public className?: string;
    public comment?: string;
    public compression?: string;
    public hostgroupId?: string;
    public hostgroupName?: string;
    public hostname?: string;
    public maxConnections?: string;
    public maxLatencyMs?: string;
    public maxReplicationLag?: string;
    public port?: string;
    public status?: string;
    public useSsl?: string;
    public weight?: string;


    constructor(props: CmonProxySqlServerProps) {
        this.BytesDataRecv = props.Bytes_data_recv;
        this.BytesDataSent = props.Bytes_data_sent;
        this.ConnERR = props.ConnERR;
        this.ConnFree = props.ConnFree;
        this.ConnUsed = props.ConnUsed;
        this.Latency = props.Latency;
        this.Queries = props.Queries;
        this.className = props.class_name;
        this.comment = props.comment;
        this.compression = props.compression;
        this.hostgroupId = props.hostgroup_id;
        this.hostgroupName = props.hostgroup_name;
        this.hostname = props.hostname;
        this.maxConnections = props.max_connections;
        this.maxLatencyMs = props.max_latency_ms;
        this.maxReplicationLag = props.max_replication_lag;
        this.port = props.port;
        this.status = props.status;
        this.useSsl = props.use_ssl;
        this.weight = props.weight;

    }

}
