import './ConfigManagement.less';
import React, { useMemo } from 'react';
import CmonConfigService from '../../services/cmon/CmonConfigService';
import CcCluster from '../../services/models/CcCluster';
import AppEditor, {
    EditorTreeDataNode,
    getAppEditorConfigSyntax,
} from '../../common/AppEditor/AppEditor';
import {
    notifyError,
    notifyOperationMessages,
} from '../Notifications/uiNotification';
import RefreshClusterConfigButton from './RefreshClusterConfigButton';
import { Button, Space } from 'antd';
import ButtonWithForm from '../../common/General/ButtonWithForm';
import ConfigEditParamsModal from './ConfigEditParamsModal';
import useClusterNodesConfigs from './useClusterNodesConfigs';
import AppTooltip from '../../common/Feedback/AppTooltip';
import { InfoCircleOutlined } from '@ant-design/icons';
import AppEmpty from '../../common/Feedback/AppEmpty';
import SpaceWide from "../../common/SpaceWide";

export default ConfigManagement;
export type ConfigManagementProps = {
    cluster: CcCluster;
};

function ConfigManagement({ cluster, ...rest }: ConfigManagementProps) {
    const {
        data: configData,
        loading: configLoading,
        refresh: configRefresh,
    } = useClusterNodesConfigs({ cluster });

    const treeData = useMemo(() => {
        return (
            configData?.map((item: any) => {
                const node = item.node;
                const key = node.getHostWithPort();
                const files = item.files;
                return {
                    selectable: false,
                    name: key,
                    type: 'dir',
                    key,
                    children: files.map((file: any) => ({
                        name: file.path,
                        type: 'file',
                        filename: file.filename,
                        hostname: key,
                        content: file.content,
                        key: `${key}-${file.filename}`,
                        language: getAppEditorConfigSyntax(file.syntax),
                        isLeaf: true,
                        hasValues: file?.values && file.values.length > 0,
                    })),
                };
            }) || []
        );
    }, [configData]);

    const handleNodeSaveClick = async (node: EditorTreeDataNode) => {
        const [host, port] = node.hostname?.split(':') || [];
        if (!host || !port || !node.filename) {
            notifyError({ content: `Invalid key: ${node.key}` });
            return;
        }
        try {
            const { messages } = await CmonConfigService.setContent({
                cluster_id: cluster.clusterId,
                hostname: host,
                port: port ? +port : undefined,
                filename: node.filename,
                content: node.value,
            });
            notifyOperationMessages({
                messages: [
                    `Config file ${node.filename} saved successfully!`,
                    ...(messages || []),
                ],
            });
            await configRefresh({});
        } catch (e: any) {
            notifyError({ content: e.message });
        }
    };

    const handleRefreshJobFinish = async () => {
        await configRefresh({});
    };

    const handleParamsModalCanceled = async (changed: boolean) => {
        if (changed) {
            await configRefresh({});
        }
    };

    return (
        <div className="ClusterConfigManagePage">
            <AppEditor
                treeData={treeData}
                loading={configLoading}
                onNodeSaveClick={handleNodeSaveClick}
                emptyState={
                    <SpaceWide direction="vertical" align="center">
                        <AppEmpty description="No files found" />
                        <RefreshClusterConfigButton
                            cluster={cluster}
                            onFinish={handleRefreshJobFinish}
                        />
                    </SpaceWide>
                }
                footerLeftExtra={(node: any) => (
                    <Space>
                        <RefreshClusterConfigButton
                            cluster={cluster}
                            onFinish={handleRefreshJobFinish}
                        />
                        {node && node.hasValues ? (
                            <ButtonWithForm
                                button={<Button>Edit params</Button>}
                                form={
                                    <ConfigEditParamsModal
                                        cluster={cluster}
                                        hostname={
                                            node.hostname?.split(':')?.[0] || ''
                                        }
                                        port={
                                            +(
                                                node.hostname
                                                    ?.split(':')
                                                    .pop() || 0
                                            )
                                        }
                                        filename={node.filename}
                                        title={
                                            <span>{node.filename} params</span>
                                        }
                                        onCancel={handleParamsModalCanceled}
                                    />
                                }
                            />
                        ) : (
                            <AppTooltip
                                title={
                                    <span>
                                        There are no params available to edit
                                    </span>
                                }
                            >
                                <Button
                                    disabled={true}
                                    icon={<InfoCircleOutlined />}
                                >
                                    Edit params
                                </Button>
                            </AppTooltip>
                        )}
                    </Space>
                )}
            />
        </div>
    );
}
