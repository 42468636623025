import React, { useState } from 'react';
import { useDebugContext } from '../../common/Debug';
import CmonReportsService from '../../services/cmon/CmonReportsService';
import { notifyError } from '../Notifications/uiNotification';
import { ReportItem } from './ReportsTable';
import { ActionButtonProps } from '@severalnines/bar-frontend-components/src/lib/General/ActionButton';
import ActionButton from '@severalnines/bar-frontend-components/build/lib/General/ActionButton';

export default DownloadReportActionButton;

export type DownloadReportActionButtonProps = ActionButtonProps & {
    report: ReportItem;
};

function DownloadReportActionButton({
    report,
    children,
    ...rest
}: DownloadReportActionButtonProps) {
    const [loading, setLoading] = useState<boolean>(false);
    const { log } = useDebugContext();

    const handleClick = async () => {
        try {
            setLoading(true);
            await CmonReportsService.downloadFile({
                body: {
                    cluster_id: report.clusterId,
                    id: report.id,
                    operation: 'downloadErrorReport',
                },
            });
        } catch (e) {
            notifyError({ content: e.message });
            log.error(e);
        } finally {
            setLoading(false);
        }
    };
    return (
        <ActionButton onClick={handleClick} loading={loading} {...rest}>
            {children || 'Download'}
        </ActionButton>
    );
}
