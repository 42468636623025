import { ClusterConfiguratorFormValues } from '../../ClusterConfigurator';
import merge from 'deepmerge';
import ElasticConfigurator, {
    ElasticFormValues,
} from '../../Elastic/ElasticConfigurator';
import { TopologyItem } from '../../../../Topology/TopologyItem';
import { StatusFormatStatus } from '@severalnines/bar-frontend-components/build/lib/Format/StatusFormat';
import React from 'react';
import { Alert } from 'antd';
import Form, { FormInstance } from 'antd/lib/form';
import TopologySummary from '../../TopologySummary';
import { ServiceClusterWizardStep } from '../../ServiceClusterWizardStep';
import { ELASTIC_REPOSITORY_DEFAULT_NAME } from '../../../../../common/Form/Fields/ElasticRepositorySelectField';
import SpaceDescriptions from '../../../../../common/Layout/SpaceDescriptions';
import { WizardFormConfigurationStepProps } from '@severalnines/bar-frontend-components/build/lib/Navigation/Wizard/WizardFormConfiguration';
import { RepositoryItemKey } from '../../../../../common/DataEntry/RepositoryInput';
import TopologyNodeUseAsDataField from '../../../../../common/Form/Fields/TopologyNodeUseAsDataField';
import { CcElasticRole } from '../../../../../services/models/CcElasticNode';
import YesNoFormat from '../../../../../common/Format/YesNoFormat';
import AppDivider from '../../../../../common/AppDivider';
import ClusterNodesForm from '../../ClusterNodesForm';
import NodeConfigurationSummary, {
    NodeConfigurationSummaryProps,
} from '../../NodeConfigurationSummary';

export default class ElasticDeploymentConfigurator extends ElasticConfigurator {
    public static getDefaults(): ElasticFormValues {
        return merge(ElasticConfigurator.getDefaults(), {
            details: {
                vendor: 'elasticsearch',
                version: '8.3.1',
            },
            nodeConfig: {
                repository: RepositoryItemKey.USE_VENDOR,
                sslEncryption: true,
            },
            repository: {
                configureSharedFs: true,
            },
        });
    }

    public static getJobData(formValues: ElasticFormValues): any {
        const {
            eligibleMasterTopology,
            dataNodeTopology,
            useAsDataNode,
            topologyDataIps,
            repository,
        } = formValues;
        return merge(ElasticConfigurator.getJobData(formValues), {
            snapshot_location: repository?.snapshotLocation,
            snapshot_repository:
                repository?.name || ELASTIC_REPOSITORY_DEFAULT_NAME,
            storage_host: repository?.storageHost,
            nodes: [
                ...eligibleMasterTopology.map((currentNode: TopologyItem) => ({
                    class_name: 'CmonElasticHost',
                    hostname: currentNode.extraData.hostname,
                    hostname_data: currentNode.extraData.hostname,
                    hostname_internal:
                        topologyDataIps?.[currentNode.extraData.hostname] || '',
                    protocol: 'elastic',
                    roles: !!useAsDataNode?.[currentNode.extraData.hostname]
                        ? CcElasticRole.MASTER_DATA
                        : CcElasticRole.MASTER,
                })),
                ...dataNodeTopology.map((currentNode: TopologyItem) => ({
                    class_name: 'CmonElasticHost',
                    hostname: currentNode.extraData.hostname,
                    hostname_data: currentNode.extraData.hostname,
                    hostname_internal:
                        topologyDataIps?.[currentNode.extraData.hostname] || '',
                    protocol: 'elastic',
                    roles: CcElasticRole.DATA,
                })),
            ],
            snapshot_repository_type: repository?.configureSharedFs
                ? 'fs-nfs'
                : 'fs',
        });
    }

    public static getJobOptions(
        formValues: ClusterConfiguratorFormValues
    ): any {
        const { details } = formValues;
        return merge(ElasticConfigurator.getJobOptions(formValues), {
            enable_uninstall: true,
            job: {
                title: `Deploy Elasticsearch ${details?.version || ''} Cluster`,
            },
        });
    }

    public static getExtraConfigurationSummary({
        form,
    }: {
        form: FormInstance;
    }): React.ReactNode {
        const { repository } = form.getFieldsValue(true);
        return (
            <SpaceDescriptions
                direction="vertical"
                title="Snapshot repository configuration"
                alignItems="right"
            >
                <SpaceDescriptions.Item label="Storage host" labelStrong>
                    {repository?.storageHost}
                </SpaceDescriptions.Item>
                <SpaceDescriptions.Item label="Repository name" labelStrong>
                    {repository?.name}
                </SpaceDescriptions.Item>

                <Form.Item noStyle={true} shouldUpdate={true}>
                    {() => (
                        <SpaceDescriptions.Item
                            label="Snapshot location"
                            labelStrong
                        >
                            {repository?.snapshotLocation}
                        </SpaceDescriptions.Item>
                    )}
                </Form.Item>
                <SpaceDescriptions.Item
                    label="Configure shared filesystem"
                    labelStrong
                >
                    <YesNoFormat booleanVar={!!repository?.configureSharedFs} />
                </SpaceDescriptions.Item>
            </SpaceDescriptions>
        );
    }

    public static getTopologyStep(form: FormInstance): React.ReactNode {
        return (
            <div>
                <ClusterNodesForm
                    form={form}
                    title="Eligible masters"
                    formItemProps={{ name: 'eligibleMasterTopology' }}
                    nodesInputProps={{
                        formProps: {
                            primaryTitle: 'Eligible master',
                            primaryExtra: 'The eligible master node hostname',
                        },
                        onlyPrimaries: true,
                        mutateItem: (item) => {
                            return {
                                ...item,
                                description: 'Eligible master',
                                footer:
                                    item.status ===
                                    StatusFormatStatus.success ? (
                                        <div>
                                            <TopologyNodeUseAsDataField
                                                name={[
                                                    'useAsDataNode',
                                                    item.extraData.hostname,
                                                ]}
                                            />
                                        </div>
                                    ) : null,
                            };
                        },
                        onItemAdd: (item) => {
                            form.setFieldsValue({
                                useAsDataNode: {
                                    [item.extraData.hostname]: true,
                                },
                            });
                        },
                    }}
                />
                <ClusterNodesForm
                    form={form}
                    title="Data nodes"
                    formItemProps={{ name: 'dataNodeTopology' }}
                    nodesInputProps={{
                        formProps: {
                            primaryTitle: 'Data node',
                            primaryExtra: 'The data node hostname',
                            primaryRequired: false,
                        },
                        onlyPrimaries: true,
                        mutateItem: (item) => {
                            return {
                                ...item,
                                description: 'Data node',
                            };
                        },
                    }}
                />

                <Alert
                    style={{ marginTop: 20 }}
                    message={
                        <span>
                            For deployment available single node cluster (1
                            master node) or high availability cluster (3 master
                            nodes and 2+ data nodes).
                        </span>
                    }
                />
            </div>
        );
    }

    public static getTopologySummary(form: FormInstance): React.ReactNode {
        return (
            <div>
                <TopologySummary
                    form={form}
                    title="Eligible masters"
                    singleNode={true}
                    topologyFieldPath={['eligibleMasterTopology']}
                />
                {form.getFieldValue('dataNodeTopology').length > 0 ? (
                    <div>
                        <AppDivider />
                        <TopologySummary
                            form={form}
                            title="Data nodes"
                            singleNode={true}
                            topologyFieldPath={['dataNodeTopology']}
                        />
                    </div>
                ) : null}
            </div>
        );
    }

    public static getNodeConfigurationSummary(
        props: NodeConfigurationSummaryProps
    ): React.ReactNode {
        return <NodeConfigurationSummary hasSslEncryption={true} {...props} />;
    }

    public static getTopologyValidate(form: FormInstance) {
        const eligibleMasterTopology: TopologyItem[] = form.getFieldValue(
            'eligibleMasterTopology'
        );
        const dataNodeTopology: TopologyItem[] = form.getFieldValue(
            'dataNodeTopology'
        );
        const useAsDataNode = form?.getFieldValue('useAsDataNode');
        return [
            () => {
                if (
                    eligibleMasterTopology.find(
                        (item: TopologyItem) =>
                            item.status !== StatusFormatStatus.success
                    ) ||
                    dataNodeTopology.find(
                        (item: TopologyItem) =>
                            item.status !== StatusFormatStatus.success
                    )
                ) {
                    throw new Error('All nodes should be reachable');
                }
                const eligibleMasterCount = eligibleMasterTopology.length;
                const dataNodeCount =
                    dataNodeTopology.length +
                    eligibleMasterTopology.filter(
                        (item) => !!useAsDataNode?.[item.extraData.hostname]
                    ).length;
                if (eligibleMasterTopology.length > 1) {
                    if (eligibleMasterCount !== 3) {
                        throw new Error(
                            'For high availability cluster need to be added 3 eligible master nodes'
                        );
                    }
                    if (dataNodeCount < 2) {
                        throw new Error(
                            'For high availability cluster need to be added at least 2 data nodes'
                        );
                    }
                } else if (
                    eligibleMasterTopology.length === 1 &&
                    dataNodeCount !== 1
                ) {
                    throw new Error(
                        'Single node cluster has to have eligible master used as data node'
                    );
                } else if (eligibleMasterTopology.length < 1) {
                    throw new Error(
                        'Please specify at least one eligible master node'
                    );
                }
            },
        ];
    }

    public static getDeploymentSteps(
        form: FormInstance,
        formValues?: ElasticFormValues
    ): (
        | ServiceClusterWizardStep
        | [ServiceClusterWizardStep | string, WizardFormConfigurationStepProps]
    )[] {
        return [
            ServiceClusterWizardStep.DETAILS,
            ServiceClusterWizardStep.SSH_CONFIG,
            ServiceClusterWizardStep.NODE_CONFIG,
            ServiceClusterWizardStep.TOPOLOGY,
            ServiceClusterWizardStep.SNAPSHOT_REPOSITORY,
            ServiceClusterWizardStep.PREVIEW,
        ];
    }
}
