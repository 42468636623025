import { SelectProps } from 'antd';
import useClusterDatabases from './useClusterDatabases';
import React, { useEffect, useState } from 'react';
import MultiSelect, {
    MultiSelectOptionType,
} from '../../common/DataEntry/MultiSelect';
import { UserClusterDatabaseSchema } from '../Clusters/useClusterDatabases';

export type ClusterHostDatabasesSelectProps = SelectProps<string[]> & {
    clusterId: number;
    host: string;
    filterDatabases?: (schema: UserClusterDatabaseSchema) => boolean;
};
export const CLUSTER_ALL_DATABASES_NAME = 'All Databases';

const ClusterHostDatabasesSelect = ({
    clusterId,
    host,
    value,
    filterDatabases,
    ...rest
}: ClusterHostDatabasesSelectProps) => {
    const [options, setOptions] = useState<MultiSelectOptionType[]>([]);
    const { schemas, loading, refresh: refreshDatabases } = useClusterDatabases(
        {
            clusterId,
            host,
            useCache: true,
        }
    );

    useEffect(() => {
        (async () => {
            await refreshDatabases();
        })();
    }, []);
    useEffect(() => {
        if (schemas?.length > 0) {
            let filteredSchemas = schemas;
            if (filterDatabases) {
                filteredSchemas = schemas.filter(filterDatabases);
            }
            setOptions(
                filteredSchemas.map(({ name: value, text: label }) => ({
                    label,
                    value,
                }))
            );
        }
    }, [schemas]);
    return (
        <MultiSelect
            options={options}
            value={value}
            loading={loading}
            disabled={loading}
            allOptionName={CLUSTER_ALL_DATABASES_NAME}
            {...rest}
        />
    );
};

export default ClusterHostDatabasesSelect;
