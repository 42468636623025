import React from 'react';
import './StatisticBadge.less';
import { Badge, BadgeProps } from 'antd';
import classNames from 'classnames';
import { PresetStatusColorType } from 'antd/lib/_util/colors';

export default StatisticBadge;

export type StatisticBadgeProps = Omit<BadgeProps, 'status'> & {
    status?: PresetStatusColorType | 'info';
    className?: string;
    size?: 'default' | 'small';
    children?: any;
    offsetX?: number;
    offsetY?: number;
    inline?: boolean;
};

function StatisticBadge({
    status,
    count,
    className,
    size,
    children,
    dot = true,
    offsetX,
    offsetY,
    inline,
    showZero,
    text,
    ...rest
}: StatisticBadgeProps) {
    return (
        <Badge
            count={!dot ? count : null}
            showZero={showZero || inline}
            offset={[offsetX || 0, offsetY || 0]}
            dot={dot ? (count ? count > 0 : false) : false}
            size={size || (!dot ? 'small' : 'default')}
            className={classNames(
                'StatisticBadge',
                `StatisticBadge--status-${status}`,
                className
            )}
            text={inline && !text ? '' : text}
            {...rest}
        >
            {children}
        </Badge>
    );
}
