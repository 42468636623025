import React from 'react';
import Immutable from 'immutable';
import './App.less';
import { ResponsiveProvider } from '@severalnines/bar-frontend-components/build/lib/Layout/Responsive';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { createAppStore, initialState } from './appReducer';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';
import AppRoutes from './AppRoutes';
import useLocalStorage from '@severalnines/bar-frontend-components/build/lib/hooks/useLocalStorage';

import './services/servicesConfig';
// import { GoogleAnalytics as GA } from '@severalnines/bar-frontend-components/build/lib/tracking';
import { DebugProvider } from './common/Debug';
const { DEBUG_ENABLED, DEBUG_LEVEL } = window.FEAS_ENV;

// GA.init();
function App() {
    const [selectedTechnologies] = useLocalStorage(
        'global-filters-technologies'
    );
    const [selectedTags] = useLocalStorage('global-filters-tags');

    const store = createAppStore({
        ...initialState,
        globalFilters: Immutable.Map({
            technologies: selectedTechnologies,
            tags: selectedTags,
        }),
    });
    return (
        <div className="App">
            <DebugProvider
                initialDebug={DEBUG_ENABLED}
                initialDebugLevel={DEBUG_LEVEL}
            >
                <Provider store={store}>
                    <BrowserRouter>
                        {/*<GA.RouteTracker />*/}
                        <QueryParamProvider adapter={ReactRouter6Adapter}>
                            <ResponsiveProvider>
                                <AppRoutes />
                            </ResponsiveProvider>
                        </QueryParamProvider>
                    </BrowserRouter>
                </Provider>
            </DebugProvider>
        </div>
    );
}

export default App;
