import React, { useCallback, useRef, useState } from 'react';
import './NodesInput.less';
import { Col } from 'antd';
import TopologyNodeTree, {
    NodeTreeTopologyNode,
} from '../../components/Topology/TopologyNodeTree';
import { TopologyItemList } from '../../components/Topology/TopologyItemList';
import {
    TopologyItem,
    TopologyItemProps,
} from '../../components/Topology/TopologyItem';
import { StatusFormatStatus } from '@severalnines/bar-frontend-components/build/lib/Format/StatusFormat';
import NodesInputForm, { NodesInputFormProps } from './NodesInputForm';
import useAutoIncrement from '../hooks/useAutoIncrement';
import { DataSourceItemType } from 'antd/lib/auto-complete';
import AppRow from '../AppRow';

export default NodesInput;

const HOSTNAME_REGEX = /^(([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9\-]*[a-zA-Z0-9])\.)*([A-Za-z0-9]|[A-Za-z0-9][A-Za-z0-9\-]*[A-Za-z0-9])$/;

export enum NodesInputNodeType {
    PRIMARY = 'primary',
    SECONDARY = 'secondary',
}

export type NodesInputProps = {
    direction?: 'vertical' | 'horizontal';
    emptyState?: React.ReactNode;
    formProps?: NodesInputFormProps;
    mutateItem?: (item: TopologyItem) => TopologyItem;
    onChange?: (items: TopologyItem[]) => void;
    onItemAdd?: (item: TopologyItem) => void;
    onItemDeleting?: (item: TopologyItem) => void;
    onItemDelete?: (item: TopologyItem) => void;
    onlyPrimaries?: boolean;
    singleNode?: boolean;
    validateItem?: (item: TopologyItem) => TopologyItem | Promise<TopologyItem>;
    value?: TopologyItem[];
    enableAutocomplete?: boolean;
    autocompleteOptions?: DataSourceItemType[];
    hideFormOnAdded?: boolean;
    validateItemHostname?: true;
};

function NodesInput({
    direction = 'horizontal',
    value,
    onChange,
    formProps,
    emptyState,
    singleNode,
    onlyPrimaries,
    validateItem,
    mutateItem,
    onItemAdd,
    onItemDeleting,
    onItemDelete,
    hideFormOnAdded,
    validateItemHostname = true,
}: NodesInputProps) {
    const { getNextAutoIncrement: getNextSecodaryIndex } = useAutoIncrement();
    const itemList = useRef<TopologyItemList>(
        new TopologyItemList({ items: value })
    );

    const [nodes, setNodes] = useState<TopologyItem[]>(
        itemList.current.getItems()
    );

    const triggerOnChange = (nodes: TopologyItem[]) => {
        onChange?.(nodes);
    };

    const updateNode = (node: TopologyItem) => {
        const newNodes = itemList.current.setItem(node).getItems();
        setNodes(newNodes);
        triggerOnChange(newNodes);
    };

    const closeNodeItems = useCallback(() => {
        itemList.current = new TopologyItemList({
            items: itemList.current
                .getItems()
                .map((item) => ({ ...item, footerExpanded: false })),
        });
    }, [itemList]);

    const addItem = async (
        index: number,
        type: NodesInputNodeType,
        primaryNode?: TopologyItem,
        custom?: Partial<TopologyItemProps>
    ) => {
        let item = new TopologyItem({
            key: `${primaryNode ? primaryNode.key : ''}${type}${index}`,
            type,
            index,
            fromKey: primaryNode ? primaryNode.key : undefined,
            status: StatusFormatStatus.info,
            ...custom,
        });
        updateNode(item);
        if (validateItem) {
            try {
                item.loading = true;
                updateNode(item);
                if (validateItemHostname) {
                    validateNodeItemHostname(item.extraData?.hostname);
                }
                // perform custom validation
                item = await validateItem(item);
                item.status = StatusFormatStatus.success;
            } catch (e: any) {
                if (e.isCanceled && e.message === 'Request cancelled') {
                    return;
                }
                item.status = StatusFormatStatus.error;
                item.message = e.message;
            }
            item.loading = false;
            updateNode(item);
        } else {
            item.status = StatusFormatStatus.success;
            updateNode(item);
        }
        if (mutateItem) {
            item = mutateItem(item);
        }
        updateNode(item);
        onItemAdd?.(item);
    };

    const handlePrimaryAdd = async (host: string, extraData: any = {}) => {
        closeNodeItems();
        const primaryIndex = onlyPrimaries ? itemList.current.count() : 0;

        await addItem(primaryIndex, NodesInputNodeType.PRIMARY, undefined, {
            title: host,
            description: singleNode ? 'Node' : 'Primary',
            extraData: { ...extraData, hostname: host },
        });
    };

    const handleSecondaryAdd = async (host: string, extraData: any = {}) => {
        closeNodeItems();
        const masterNode = itemList.current.items.get(
            `${NodesInputNodeType.PRIMARY}${0}`
        );
        const secondaryIndex = masterNode ? getNextSecodaryIndex() : 0;

        await addItem(
            secondaryIndex,
            NodesInputNodeType.SECONDARY,
            masterNode,
            { title: host, extraData: { ...extraData, hostname: host } }
        );
    };

    const handleItemDelete = async (dataNode: NodeTreeTopologyNode) => {
        if (dataNode.item) {
            await onItemDeleting?.(dataNode.item);
            // we need to move the deletion to next tick because validate item
            // can be happening when the user deletes the item.
            setTimeout(() => {
                if (dataNode.item) {
                    const newNodes = itemList.current
                        .removeItem(dataNode.item.key)
                        .getItems();
                    setNodes(newNodes);
                    triggerOnChange(newNodes);
                    onItemDelete?.(dataNode.item);
                }
            });
        }
    };

    return (
        <div className="NodesInput">
            <AppRow gutter={[24, 0]}>
                {!hideFormOnAdded ||
                (hideFormOnAdded && itemList.current.count() === 0) ? (
                    <Col span={direction === 'horizontal' ? 12 : 24}>
                        <NodesInputForm
                            switchFocus={!singleNode && !onlyPrimaries}
                            hasSecondary={!singleNode && !onlyPrimaries}
                            onPrimaryAdd={handlePrimaryAdd}
                            onSecondaryAdd={handleSecondaryAdd}
                            primaryTitle={singleNode ? 'Node' : 'Primary node'}
                            secondaryTitle={'Replica nodes'}
                            secondaryDisabled={!nodes[0]}
                            {...formProps}
                        />
                    </Col>
                ) : null}

                <Col span={direction === 'horizontal' ? 12 : 24}>
                    {nodes.length > 0 ? (
                        <TopologyNodeTree
                            items={nodes}
                            fullWidth={direction === 'vertical'}
                            itemProps={{
                                onDelete: handleItemDelete,
                            }}
                        />
                    ) : emptyState !== undefined ? (
                        emptyState
                    ) : (
                        <div className="NodesInput_default-empty-state">
                            <img
                                src={require(singleNode
                                    ? './topology-empty-state-single.svg'
                                    : './topology-empty-state.svg')}
                                alt="Add your first node"
                                title="Add your first node"
                            />
                        </div>
                    )}
                </Col>
            </AppRow>
        </div>
    );
}

function validateNodeItemHostname(hostname: string) {
    if (hostname && !HOSTNAME_REGEX.test(hostname)) {
        throw new Error(
            'Hostname is not valid, please enter FQDN or ipv4 address'
        );
    }
    return undefined;
}
