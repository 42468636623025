import React from 'react';
import {
    Input,
    AutoComplete,
    AutoCompleteProps,
    InputProps,
    Select,
    SelectProps,
    Button,
} from 'antd';
import { ButtonProps } from 'antd/es';

type AllDataEntryProps = (AutoCompleteProps & InputProps) | SelectProps;

export type AppSelectProps = AllDataEntryProps & {
    value?: string;
    placeholder?: string;
    editable?: boolean;
    clickable?: boolean;
    allowClear?: boolean;
    buttonProps?: ButtonProps;
    onClick?: React.MouseEventHandler<HTMLElement>;
    onKeyUp?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
    onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
    onChange?: (value: string | React.ChangeEvent<HTMLInputElement>) => void;
    onSelect?: (value: string) => void;
};

export default AppSelect;

function AppSelect({
    value,
    placeholder,
    editable,
    clickable,
    allowClear,
    buttonProps,
    onClick,
    onKeyUp,
    onKeyDown,
    onChange,
    onSelect,
    ...rest
}: AppSelectProps) {
    if (editable) {
        return (
            <AutoComplete<AutoCompleteProps>
                onSelect={onSelect}
                value={value}
                {...rest}
            >
                <Input
                    placeholder={placeholder}
                    value={value}
                    onChange={onChange}
                    onKeyUp={onKeyUp}
                    onKeyDown={onKeyDown}
                    allowClear={allowClear}
                    addonAfter={
                        clickable && (
                            <Button
                                type="link"
                                size="small"
                                onClick={onClick}
                                children={'+'}
                                {...buttonProps}
                                disabled={!value}
                            />
                        )
                    }
                    {...rest}
                />
            </AutoComplete>
        );
    } else {
        return (
            <Select<SelectProps>
                onSelect={onSelect}
                onChange={onChange}
                value={value}
                allowClear={allowClear}
                placeholder={placeholder}
                {...rest}
            />
        );
    }
}
