import React from 'react';
import { Result } from 'antd';
import { Link } from 'react-router-dom';

export default Error404;

function Error404() {
    return (
        <Result
            className="Error404"
            title="404"
            subTitle="Sorry, the page you visited does not exist."
            icon={<img alt="404" src="/clustercontrol-small.svg" width={200} />}
            extra={<Link to="/dashboard">Back Home</Link>}
        />
    );
}
