import React from 'react';
import { Button, Form, Space } from 'antd';
import ModalDefaultForm from '../../../common/ModalDefaultForm';
import CcCluster from '../../../services/models/CcCluster';
import BackupToolPgBackrestSetupForm from './BackupToolPgBackrestSetupForm';
import ContentWrapper from '../../../common/Layout/ContentWrapper';
import useBackRestSetup from './useBackRestSetup';

export default BackupToolPgBackrestSetupModal;

export type BackupTookPgBackrestInstallModalProps = {
    cluster: CcCluster;
    onSuccess?: () => void;
    onCancel?: () => void;
    reinstall?: boolean;
};

function BackupToolPgBackrestSetupModal({
    cluster,
    onSuccess,
    onCancel,
    reinstall = true,
}: BackupTookPgBackrestInstallModalProps) {
    const [form] = Form.useForm();

    const { loading, setup } = useBackRestSetup({
        cluster,
        form,
        onSuccess,
        reinstall,
    });

    const handleInstallClick = async () => {
        await setup();
    };

    const action = reinstall ? 'Reinstall' : 'Install';

    return (
        <ModalDefaultForm
            title={`${action} PgBackrest`}
            form={form}
            footer={[]}
            onCancel={onCancel}
            width={400}
            defaultVisible={true}
            bodyStyle={{ padding: 0 }}
            loading={loading}
        >
            <Form form={form} layout="vertical" onFinish={handleInstallClick}>
                <ContentWrapper>
                    <BackupToolPgBackrestSetupForm
                        cluster={cluster}
                        form={form}
                    />
                    <Space>
                        <Button type="primary" size="middle" htmlType="submit">
                            {action}
                        </Button>
                        <Button size="middle" onClick={onCancel}>
                            Cancel
                        </Button>
                    </Space>
                </ContentWrapper>
            </Form>
        </ModalDefaultForm>
    );
}
