import React from 'react';

import { CcLogEntrySeverity } from '../../services/models/CcLogEntry';
import StatusFormat, {
    StatusFormatStatus,
    StatusFormatType,
} from '@severalnines/bar-frontend-components/build/lib/Format/StatusFormat';
import CcLogEntry from '../../services/models/CcLogEntry';

export default LogEntrySeverityFormat;

export function getLogEntrySeverityFormatType(
    severityName?: CcLogEntrySeverity
) {
    switch (severityName) {
        case CcLogEntrySeverity.LOG_DEBUG:
            return StatusFormatStatus.success;
        case CcLogEntrySeverity.LOG_ERR:
        case CcLogEntrySeverity.LOG_ALERT:
        case CcLogEntrySeverity.LOG_CRIT:
        case CcLogEntrySeverity.LOG_EMERG:
            return StatusFormatStatus.error;
        case CcLogEntrySeverity.LOG_WARNING:
            return StatusFormatStatus.warning;
        case CcLogEntrySeverity.LOG_INFO:
        case CcLogEntrySeverity.LOG_NOTICE:
        default:
            return StatusFormatStatus.default;
    }
}

export function getLogEntrySeverityFormatText(
    severityName?: CcLogEntrySeverity
) {
    switch (severityName) {
        case CcLogEntrySeverity.LOG_DEBUG:
            return 'Debug';
        case CcLogEntrySeverity.LOG_ERR:
            return 'Error';
        case CcLogEntrySeverity.LOG_ALERT:
            return 'Alert';
        case CcLogEntrySeverity.LOG_CRIT:
            return 'Critical';
        case CcLogEntrySeverity.LOG_EMERG:
            return 'Emergency';
        case CcLogEntrySeverity.LOG_WARNING:
            return 'Warning';
        case CcLogEntrySeverity.LOG_INFO:
            return 'Info';
        case CcLogEntrySeverity.LOG_NOTICE:
            return 'Notice';
        default:
            return 'Unknown';
    }
}

export type LogEntrySeverityFormatProps = {
    logEntry: CcLogEntry;
    type?: StatusFormatType;
};

function LogEntrySeverityFormat({
    logEntry,
    type,
    ...rest
}: LogEntrySeverityFormatProps) {
    return (
        <StatusFormat
            className="LogEntrySeverityFormat"
            status={getLogEntrySeverityFormatType(logEntry.severity)}
            text={getLogEntrySeverityFormatText(logEntry.severity)}
            type={type}
            {...rest}
        />
    );
}
