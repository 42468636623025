import React from 'react';
import AppConfirmActionButton from '../../common/General/AppConfirmActionButton';
import {
    notifyError,
    notifyOperationSuccess,
    NotifyType,
} from '../Notifications/uiNotification';
import { useDebugContext } from '../../common/Debug';
import CmonReportsService from '../../services/cmon/CmonReportsService';
import { ConfirmActionButtonProps } from '@severalnines/bar-frontend-components/build/lib/General/ConfirmActionButton';
import CcReport from '../../services/models/CcReport';

export default OperationalReportDeleteButton;

export type OperationalReportDeleteButtonProps = ConfirmActionButtonProps & {
    report: CcReport;
    onSuccess?: () => void;
};

function OperationalReportDeleteButton({
    report,
    onSuccess,
    children,
    ...rest
}: OperationalReportDeleteButtonProps) {
    const { log } = useDebugContext();

    const handleConfirm = async () => {
        try {
            await CmonReportsService.deleteReport({
                cluster_id: report.clusterId,
                report: {
                    report_id: report.reportId,
                },
            });
            notifyOperationSuccess({
                type: NotifyType.TOAST,
                title: 'Success!',
                content: 'Report successfully deleted',
            });
            onSuccess?.();
        } catch (e) {
            notifyError({ content: e.message });
            log.error(e);
        }
    };

    return (
        <AppConfirmActionButton
            critical={true}
            confirmTitle="You want to delete this report?"
            onConfirm={handleConfirm}
            {...rest}
        >
            {'Delete'}
        </AppConfirmActionButton>
    );
}
