import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    AppState,
    AppStateGlobalFilters,
    AppStateClusterStatsRecord,
    setClusterStats,
    AppStateClustersMap,
} from '../../appReducer';
import { FetchRefreshFunctionParams } from '../../common/useFetch';
import { arrayFilter } from '../../common/filtering';
import CcClusterStats from '../../services/models/CcClusterStats';
import useListFetch from '../../common/useListFetch';
import CmonClustersService from '../../services/cmon/CmonClustersService';
import { getFiltersForClient } from './useClusterList';
import CcAlarmStats, {
    AlarmsCountProps,
} from '../../services/models/CcAlarmStats';
import { CcAlarmSeverity } from '../../services/models/CcAlarm';

type RefreshFunctionParams = FetchRefreshFunctionParams & { filters?: any };

type UseClustersOverviewProps = {
    name?: string | null;
};

type FilterFunctionParams = { arr?: any[] };

export default function useClustersOverview({
    name,
}: UseClustersOverviewProps = {}) {
    const {
        error,
        loading,
        loaded,
        list: clusters,
        refresh: refreshFetch,
        cancel,
    } = useListFetch({
        name,
        fetchFn: async (params, opts) => {
            // await new Promise((resolve) => setTimeout(resolve, 5000));
            const {
                clusters,
                total,
            } = await CmonClustersService.getAllClusterInfo(
                {
                    ...params,
                    with_hosts: true,
                },
                opts
            );
            return {
                list: clusters,
                total: total,
            };
        },
        cancelFn: async ({ requestId }) => {
            await CmonClustersService.cancelRequest(requestId);
        },
    });
    const [record, setRecord] = useState<CcClusterStats | null>(null);
    const [storedMap, storedRecord, globalFilters]: [
        AppStateClustersMap,
        AppStateClusterStatsRecord,
        AppStateGlobalFilters
    ] = useSelector(({ clusters, clusterStats, globalFilters }: AppState) => [
        clusters,
        clusterStats,
        globalFilters,
    ]);
    const dispatch = useDispatch();
    const gFilters = useRef<any>(getFiltersForClient(globalFilters));

    const filter = useCallback<(p?: FilterFunctionParams) => void>(
        ({ arr = (storedMap && storedMap.toList().toArray()) || [] } = {}) => {
            const filteredArr = arrayFilter({
                filters: [...gFilters.current],
                arr,
            });
            const alarms: [number, number] = filteredArr.reduce(
                ([critical, warning], cluster) => [
                    critical + cluster.alarmStatistics.critical,
                    warning + cluster.alarmStatistics.warning,
                ],
                [0, 0]
            );
            setRecord(
                new CcClusterStats({
                    statsCount: filteredArr.reduce((accumulator, current) => {
                        return {
                            ...accumulator,
                            [current.state]:
                                1 + (accumulator[current.state] || 0),
                        };
                    }, {}),
                    totalClusters: filteredArr.length,
                    maintenanceCount: filteredArr.reduce(
                        (count, cluster) =>
                            cluster.maintenanceModeActive ? ++count : count,
                        0
                    ),
                    alarmStatistics: new CcAlarmStats({
                        alarmsCount: {
                            [CcAlarmSeverity.ALARM_CRITICAL]: alarms[0],
                            [CcAlarmSeverity.ALARM_WARNING]: alarms[1],
                        } as AlarmsCountProps,
                    }),
                })
            );
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [gFilters.current]
    );

    const refresh = useCallback<(p?: RefreshFunctionParams) => Promise<void>>(
        async (params = {}) => {
            await refreshFetch({
                filters: gFilters.current,
                ...params,
            });
        },
        [gFilters.current]
    );

    useEffect(() => {
        if (clusters) {
            filter({
                arr: clusters,
            });
        }
    }, [clusters]);

    useEffect(() => {
        if (record) {
            dispatch(setClusterStats(record));
        }
    }, [record]);

    useEffect(() => {
        if (storedRecord) {
            setRecord(storedRecord);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [storedRecord]);

    useEffect(() => {
        gFilters.current = getFiltersForClient(globalFilters);
        if (loaded && globalFilters) {
            filter();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [globalFilters]);

    return {
        error,
        loading,
        loaded,
        record,
        refresh,
        filter,
        cancel,
    };
}
