import React from 'react';
import { Space } from 'antd';
import {
    formatMonitorValue,
    MonitorValueFormatType,
} from './MonitorValueFormat';
import StatusFormat from '@severalnines/bar-frontend-components/build/lib/Format/StatusFormat';

export default MonitorValueMapsFormat;

export type MonitorValueMapsFormatProps = {
    value: string;
    maps?: any[];
    formatType?: MonitorValueFormatType;
    decimals?: number;
    postfix?: string;
};

function MonitorValueMapsFormat({
    value,
    maps,
    formatType,
    decimals,
}: MonitorValueMapsFormatProps) {
    const [result, opts] = formatMonitorValueMaps(
        value,
        maps,
        formatType,
        decimals
    );
    return (
        <Space className="MonitorValueMapsFormat">
            {opts.status ? (
                <StatusFormat status={opts.status}>{result}</StatusFormat>
            ) : (
                <span style={{ color: opts?.color }}>{result}</span>
            )}
        </Space>
    );
}

/**
 * Returns formatted value in different types
 */
export function formatMonitorValueMaps(
    input: string,
    maps?: any[],
    formatType?: MonitorValueFormatType,
    decimals = 2,
    postfix = ''
): [string, any?] {
    let result = input;
    let resultColor;
    let resultStatus;
    if (maps) {
        const inputNum = parseFloat(input);
        const isNumeric = !Number.isNaN(inputNum);
        maps.forEach((m) => {
            const valueNum = parseFloat(m.value);
            switch (m.op) {
                case '=':
                    if (
                        input === m.value ||
                        (isNumeric && inputNum === valueNum)
                    ) {
                        result = m.text || input;
                        resultColor = m.color;
                        resultStatus = m.status;
                    }
                    break;
                case '>':
                    if (input > m.value || (isNumeric && inputNum > valueNum)) {
                        result = m.text || input;
                        resultColor = m.color;
                        resultStatus = m.status;
                    }
                    break;
                case '<':
                    if (input < m.value || (isNumeric && inputNum < valueNum)) {
                        result = m.text || input;
                        resultColor = m.color;
                        resultStatus = m.status;
                    }
                    break;
                case '>=':
                    if (
                        input >= m.value ||
                        (isNumeric && inputNum >= valueNum)
                    ) {
                        result = m.text || input;
                        resultColor = m.color;
                        resultStatus = m.status;
                    }
                    break;
                case '<=':
                    if (
                        input <= m.value ||
                        (isNumeric && inputNum <= valueNum)
                    ) {
                        result = m.text || input;
                        resultColor = m.color;
                        resultStatus = m.status;
                    }
                    break;
                default:
            }
        });
    }

    if (result === undefined) {
        result = '-';
    }

    if (!Number.isNaN(Number(result))) {
        result = formatMonitorValue(
            Number.parseFloat(result),
            formatType,
            decimals,
            postfix
        );
    }
    return [result, { color: resultColor, status: resultStatus }];
}
