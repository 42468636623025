import React, {
    forwardRef,
    useCallback,
    useContext,
    useEffect,
    useImperativeHandle,
} from 'react';
import { ResponsiveContext } from '@severalnines/bar-frontend-components/build/lib/Layout/Responsive';
import useLdapGroupMapList, {
    LdapConfigGroupMappingItem,
} from './useLdapGroupMapList';
import AppTable from '../../../common/DataDisplay/AppTable';
import { TablePaginationConfig } from 'antd/es';
import { getSortAlphabeticFn } from '../../../common/sorting';
import LdapGroupMapActionMenu from './LdapGroupMapActionMenu';
import { GroupListApi } from '../GroupList';
import ButtonBarrier from '../../../common/Barrier/ButtonBarrier';
import { CmonFeature } from '../../../services/cmon/CmonFeature';

export default forwardRef(LdapGroupMapList);

export interface LdapGroupMapListApi {
    refresh: () => void;
}

export type LdapGroupMapListProps = {};

function LdapGroupMapList({}: LdapGroupMapListProps, ref: any) {
    const { responsive } = useContext(ResponsiveContext);

    const {
        list: groupMapItems,
        loading: loadingGroupMapItems,
        refresh: refreshGroupMapItems,
        filter: filterGroupMapItems,
        page,
        pageSize,
        total,
    } = useLdapGroupMapList({
        useCache: false,
    });

    useImperativeHandle(
        ref,
        (): GroupListApi => ({
            async refresh() {
                await refreshGroupMapItems();
            },
        })
    );

    useEffect(() => {
        (async () => {
            await refreshGroupMapItems();
        })();
    }, []);

    const handleActionPerformed = async () => {
        refreshGroupMapItems();
    };
    const columns = [
        {
            title: 'LDAP Group',
            key: 'ldap_group',
            render: (record: LdapConfigGroupMappingItem) => (
                <>{record.ldapGroupId}</>
            ),
            sorter: true,
        },
        {
            title: 'ClusterControl Team',
            key: 'clustercontrol_group',
            render: (record: LdapConfigGroupMappingItem) => (
                <>{record.cmonGroupName}</>
            ),
            sorter: true,
        },

        {
            key: 'actions',
            title: 'Actions',
            align: 'center',
            render: (record: LdapConfigGroupMappingItem) => (
                <ButtonBarrier
                    licenseFeature={CmonFeature.LDAP}
                    actualButton={true}
                >
                    <LdapGroupMapActionMenu
                        item={record}
                        onActionPerformed={handleActionPerformed}
                    />
                </ButtonBarrier>
            ),
            width: 20,
            onCell: () => ({ style: { padding: '2px 10px' } }),
        },
    ];

    const handleTableChange = (pagination: any, filter: any, sorters: any) => {
        let sorterFn;
        switch (sorters.columnKey) {
            case 'ldap_group':
                sorterFn = getSortAlphabeticFn(
                    sorters.order,
                    (x) => x.ldapGroupId
                );
                break;
            case 'clustercontrol_group':
                sorterFn = getSortAlphabeticFn(
                    sorters.order,
                    (x) => x.cmonGroupName || ''
                );
                break;
        }

        filterGroupMapItems({
            page: pagination.current,
            order: sorterFn || undefined,
        });
    };
    const pag: TablePaginationConfig = {
        pageSize,
        current: page,
        total,
    };
    const rowKey = useCallback(
        (record: LdapConfigGroupMappingItem) => record.sectionName,
        []
    );
    return (
        <div className="GroupMapList">
            <AppTable
                className="LdapGroupMapTable"
                loading={loadingGroupMapItems}
                rowKey={rowKey}
                dataSource={groupMapItems}
                columns={columns}
                pagination={pag}
                size="middle"
                responsive={responsive}
                onChange={handleTableChange}
            />
        </div>
    );
}
