import './ClusterDashboards.less';
import ActionButton from '@severalnines/bar-frontend-components/build/lib/General/ActionButton';
import React, {
    Fragment,
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react';
import CcCluster from '../../../services/models/CcCluster';
import { Space } from 'antd';
import MonitorToolbar, {
    MonitorToolbarApi,
} from '../../Monitor/MonitorToolbar';
import moment from 'moment';
import ClusterDashboardsVariables from './ClusterDashboardsVariables';
import ClusterDashboardSelect, {
    getDefaultDashboard,
} from './ClusterDashboardSelect';
import { MonitorDashboardConfig } from '../../Monitor/Monitor';
import usePreviousValue from '@severalnines/bar-frontend-components/build/lib/hooks/usePreviousValue';
import { MonitorVariableItem } from '../../Monitor/MonitorVariable';
import { StringParam, useQueryParam } from 'use-query-params';
import AppEmpty from '../../../common/Feedback/AppEmpty';
import TypographyText from '../../../common/TypographyText';
import MonitorSettingsMenu from '../../Monitor/MonitorSettingsMenu';
import { CmonJobInstanceCommand } from '../../../services/cmon/models/CmonJobInstance';
import JobProgress from '../../Jobs/JobProgress';
import { FileTextOutlined } from '@ant-design/icons';
import useClusterList from '../useClusterList';
import CcJob, { CcJobStatus } from '../../../services/models/CcJob';
import CcPrometheusNode from '../../../services/models/CcPrometheusNode';
import ClusterEnablePrometheusButton from '../Actions/ClusterEnablePrometheusButton';
import AppPageWrapper from '../../../common/Layout/AppPageWrapper';
import UserAclManageCluster from '../../User/UserAclManageCluster';
import { AppConfig } from '../../../AppConfig';
import { AppState, AppStateUser } from '../../../appReducer';
import { useSelector } from 'react-redux';
import MonitorDashboard, {
    MonitorDashboardApi,
} from '../../Monitor/MonitorDashboard';

export default ClusterDashboards;

export type ClusterDashboardsProps = {
    cluster: CcCluster;
};

function ClusterDashboards({ cluster }: ClusterDashboardsProps) {
    const { refresh: refreshClusters } = useClusterList({
        name: 'cluster-list-from-ClusterDashboards',
    });
    const [user]: [AppStateUser] = useSelector(({ user }: AppState) => [user]);
    const [dashboardSlug, setDashboardSlug] = useQueryParam<any, any>(
        'slug',
        StringParam
    );
    const prevCluster: CcCluster = usePreviousValue(cluster);
    const [selectedDashboard, setSelectedDashboard] = useState<
        MonitorDashboardConfig | undefined
    >(getDefaultDashboard(cluster, dashboardSlug));

    const monitorToolbarRef = useRef<MonitorToolbarApi>(null);
    const monitorDashboardRef = useRef<MonitorDashboardApi>(null);

    // vars to interpolate with targets
    const [vars, setVars] = useState<MonitorVariableItem[]>();

    const range = 60 * 15; // 30 min
    const [startTs, setStartTs] = useState<number>(moment().unix() - range);
    const [endTs, setEndTs] = useState<number>(moment().unix());

    const [prometheusNode, setPrometheusNode] = useState<
        CcPrometheusNode | undefined
    >(cluster.getPrometheusNode());

    useEffect(() => {
        if (prevCluster?.clusterId !== cluster.clusterId) {
            setSelectedDashboard(getDefaultDashboard(cluster, dashboardSlug));
        }
        setPrometheusNode(cluster.getPrometheusNode());
    }, [cluster]);

    useEffect(() => {
        setDashboardSlug(selectedDashboard?.meta?.slug);
    }, [selectedDashboard]);

    const reloadAll = useCallback(() => {
        monitorDashboardRef.current?.reloadAll();
    }, []);

    const handleTimeRangeChange = (from: number, to: number) => {
        setStartTs(from);
        setEndTs(to);
        reloadAll();
    };

    const handleTimeRangeShift = (from: number, to: number) => {
        setStartTs(from);
        setEndTs(to);
        monitorDashboardRef.current?.updateAll();
    };

    const handleVariablesFilterChange = (vars: MonitorVariableItem[]) => {
        setVars(vars);
    };

    const handleVariablesFilterLoaded = (vars: MonitorVariableItem[]) => {
        setVars(vars);
    };

    const handleRangeSelect = (from: number, to: number) => {
        monitorToolbarRef.current?.setTimeRange([from, to]);
        reloadAll();
    };

    const handleToggleTooltipSync = () => {
        monitorDashboardRef.current?.toggleTooltipSync();
    };

    const handleInstallationFinish = (job: CcJob) => {
        if (job.status === CcJobStatus.FINISHED) {
            refreshClusters();
        }
    };

    return (
        <AppPageWrapper
            className="ClusterDashboards"
            title={
                <Space size={'large'}>
                    <ClusterDashboardSelect
                        disabled={!prometheusNode}
                        cluster={cluster}
                        value={selectedDashboard?.meta?.slug}
                        onChange={(value) => {
                            setSelectedDashboard(
                                getDefaultDashboard(cluster, value)
                            );
                        }}
                        style={{ width: '300px' }}
                    />
                </Space>
            }
            headerMenu={
                <UserAclManageCluster cluster={cluster}>
                    <MonitorSettingsMenu
                        cluster={cluster}
                        extraItems={
                            prometheusNode
                                ? [
                                      {
                                          key: 'toggle-tooltip-sync',
                                          label: (
                                              <ActionButton
                                                  onClick={
                                                      handleToggleTooltipSync
                                                  }
                                              >
                                                  Toggle tooltip sync
                                              </ActionButton>
                                          ),
                                      },
                                  ]
                                : []
                        }
                    />
                </UserAclManageCluster>
            }
            transparentBackground={true}
            noPadding={true}
            contentClassName="ClusterDashboards_content"
        >
            <div>
                {prometheusNode ? (
                    <Fragment>
                        <div className="ClusterDashboards_header">
                            <MonitorToolbar
                                ref={monitorToolbarRef}
                                timeRange={range}
                                onTimeRangeChange={handleTimeRangeChange}
                                onTimeRangeShift={handleTimeRangeShift}
                                selectors={[
                                    <ClusterDashboardsVariables
                                        key={'dashboard-variables'}
                                        cluster={cluster}
                                        dashboard={selectedDashboard}
                                        onFilterChange={
                                            handleVariablesFilterChange
                                        }
                                        onFilterLoaded={
                                            handleVariablesFilterLoaded
                                        }
                                    />,
                                ]}
                                defaultRefreshTimeout={
                                    AppConfig.MONITORING_DEFAULT_REFRESH_RATE_SECONDS
                                }
                                timezone={user?.timezone?.name}
                            />
                        </div>
                        {selectedDashboard ? (
                            <div className="ClusterDashboards_wrap">
                                <MonitorDashboard
                                    ref={monitorDashboardRef}
                                    cluster={cluster}
                                    dashboard={selectedDashboard}
                                    vars={vars}
                                    startTs={startTs}
                                    endTs={endTs}
                                    onRangeSelect={handleRangeSelect}
                                />
                            </div>
                        ) : null}
                    </Fragment>
                ) : (
                    <div className="ClusterDashboards_wrap">
                        <JobProgress
                            command={CmonJobInstanceCommand.DEPLOY_AGENTS}
                            cluster={cluster}
                            title="Enabling agent based monitoring"
                            failedMessage="Enabling agent based monitoring has failed"
                            successMessage="Enabling agent based monitoring has finished successfully"
                            onFinish={handleInstallationFinish}
                            extra={
                                <a
                                    target="_blank"
                                    rel="noreferrer"
                                    href="https://prometheus.io/docs/"
                                >
                                    <FileTextOutlined /> Prometheus
                                    documentation
                                </a>
                            }
                            fallback={
                                <AppEmpty
                                    loading={false}
                                    title={'Dashboards are disabled'}
                                    description={
                                        <div>
                                            <TypographyText muted>
                                                Enable the agent based
                                                monitoring in order to use the
                                                dashboards. A Prometheus server
                                                will be installed and used to
                                                store monitoring metrics.
                                                Special-purpose exporters agents
                                                will also be installed on each
                                                of the cluster nodes and will
                                                expose metrics that Prometheus
                                                scrapes/polls (using http) at a
                                                custom interval. for further
                                                information.
                                            </TypographyText>
                                            <br />
                                            <br />
                                            <a
                                                target="_blank"
                                                rel="noreferrer"
                                                href="https://prometheus.io/docs/"
                                            >
                                                <FileTextOutlined /> Prometheus
                                                documentation
                                            </a>
                                        </div>
                                    }
                                    extra={
                                        <ClusterEnablePrometheusButton
                                            cluster={cluster}
                                            type="primary"
                                            size="middle"
                                        >
                                            Enable agent based monitoring
                                        </ClusterEnablePrometheusButton>
                                    }
                                />
                            }
                        />
                    </div>
                )}
            </div>
        </AppPageWrapper>
    );
}
