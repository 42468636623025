import React from 'react';
import { Form, Input } from 'antd';

export default IntegrationName;

export type IntegrationNameProps = {};

function IntegrationName({}: IntegrationNameProps) {
    return (
        <Form.Item
            name="name"
            label="Name"
            rules={[
                {
                    required: true,
                    message: 'Please enter integration name.',
                },
            ]}
        >
            <Input placeholder="Name your integration" />
        </Form.Item>
    );
}
