import React, { useState } from 'react';
import WizardFormConfiguration, {
    WizardFormConfigurationProps,
} from '@severalnines/bar-frontend-components/build/lib/Navigation/Wizard/WizardFormConfiguration';
import { Col, Form, Row } from 'antd';
import SpaceDescriptions from '../../common/Layout/SpaceDescriptions';
import FormItem from '../../common/DataEntry/FormItem';
import CcGroup from '../../services/models/CcGroup';
import AppDivider from '../../common/AppDivider';
import {
    notifyError,
    notifyOperationSuccess,
    NotifyType,
} from '../Notifications/uiNotification';
import CmonUsersService from '../../services/cmon/CmonUsersService';
import { FormInstance } from 'antd/es';
import GroupPermissions from './GroupPermissions';
import UserDetailsForm from './UserDetailsForm';
import GroupField from './GroupField';

export default UserCreateWizardForm;

enum UserCreateWizardFormStep {
    DETAILS = 'DETAILS',
    GROUP = 'GROUP',
    SUMMARY = 'SUMMARY',
}

export interface UserCreateWizardFormValues {
    firstName?: string;
    lastName?: string;
    username?: string;
    password?: string;
    email?: string;
    timezone?: string;
    group?: CcGroup;
}

export type UserCreateWizardFormProps = Omit<
    WizardFormConfigurationProps,
    'steps' | 'initialValues' | 'form' | 'onSubmit'
> & {
    initialValues?: UserCreateWizardFormValues;
    onSuccess?: () => void;
    onError?: (err: any) => void;
    form?: FormInstance;
};

function UserCreateWizardForm({
    onSuccess,
    onError,
    onCancel,
    form: parentForm,
    ...rest
}: UserCreateWizardFormProps) {
    const [localForm] = Form.useForm<UserCreateWizardFormValues>();
    const [loading, setLoading] = useState<boolean>(false);

    const form = parentForm || localForm;

    const handleSubmit = async () => {
        const {
            firstName,
            lastName,
            username,
            email,
            password,
            timezone,
            group,
        } = form.getFieldsValue(true);
        try {
            setLoading(true);
            await CmonUsersService.createUser({
                create_group: false,
                new_password: password,
                user: {
                    class_name: 'CmonUser',
                    public_keys: [],
                    user_name: username,
                    email_address: email,
                    first_name: firstName,
                    last_name: lastName,
                    timezone: {
                        class_name: 'CmonTimeZone',
                        name: timezone,
                    },
                    groups: [
                        {
                            class_name: 'CmonGroup',
                            group_name: group.groupName,
                            group_id: group.groupId,
                        },
                    ],
                },
            });
            notifyOperationSuccess({
                type: NotifyType.TOAST,
                title: <span>The user was created successfully.</span>,
            });
            setLoading(false);
            if (onSuccess) {
                onSuccess();
            }
        } catch (err: any) {
            setLoading(false);
            if (onError) {
                onError(err);
            }
            notifyError({ content: err.message });
        }
    };
    const handleCancel = () => {
        onCancel?.();
    };
    return (
        <WizardFormConfiguration
            form={form}
            loading={loading}
            steps={[
                <WizardFormConfiguration.Step
                    key={UserCreateWizardFormStep.DETAILS}
                    title="Details"
                    subTitle=" "
                    validate={[
                        'firstName',
                        'lastName',
                        'username',
                        'password',
                        'email',
                    ]}
                    hasRequiredFields={true}
                >
                    <Row gutter={[24, 0]}>
                        <Col span={24}>
                            <h3>Details</h3>
                        </Col>
                    </Row>
                    <UserDetailsForm showPassword={true} showUsername={true} />
                </WizardFormConfiguration.Step>,
                <WizardFormConfiguration.Step
                    key={UserCreateWizardFormStep.GROUP}
                    title="Team"
                    subTitle=" "
                    validate={['group']}
                    hasRequiredFields={true}
                >
                    <Row gutter={[24, 0]}>
                        <Col span={24}>
                            <h3>Groups</h3>
                        </Col>
                    </Row>
                    <Row gutter={[24, 0]}>
                        <Col xs={24} sm={24} md={12}>
                            <GroupField />
                        </Col>
                    </Row>
                </WizardFormConfiguration.Step>,
                <WizardFormConfiguration.Step
                    key={UserCreateWizardFormStep.SUMMARY}
                    title="Preview"
                    subTitle=" "
                    hasRequiredFields={false}
                >
                    <FormItem shouldUpdate={true} noStyle={true}>
                        {() => {
                            const formFields = form.getFieldsValue(true);

                            return (
                                <Row gutter={[24, 0]}>
                                    <Col span={24}>
                                        <SpaceDescriptions
                                            direction="vertical"
                                            title="Details"
                                            size="small"
                                            alignItems="right"
                                        >
                                            <SpaceDescriptions.Item
                                                label="First name"
                                                labelStrong
                                            >
                                                {formFields.firstName}
                                            </SpaceDescriptions.Item>
                                            <SpaceDescriptions.Item
                                                label="Last name"
                                                labelStrong
                                            >
                                                {formFields.lastName}
                                            </SpaceDescriptions.Item>
                                            <SpaceDescriptions.Item
                                                label="Username"
                                                labelStrong
                                            >
                                                {formFields.username}
                                            </SpaceDescriptions.Item>
                                            <SpaceDescriptions.Item
                                                label="Email"
                                                labelStrong
                                            >
                                                {formFields.email}
                                            </SpaceDescriptions.Item>
                                            <SpaceDescriptions.Item
                                                label="Timezone"
                                                labelStrong
                                            >
                                                {formFields.timezone || '-'}
                                            </SpaceDescriptions.Item>
                                        </SpaceDescriptions>
                                        <AppDivider />
                                        <SpaceDescriptions
                                            direction="vertical"
                                            title="Group"
                                            size="small"
                                            alignItems="right"
                                        >
                                            <SpaceDescriptions.Item labelStrong>
                                                {formFields.group
                                                    ? formFields.group.groupName
                                                    : 'No groups'}
                                            </SpaceDescriptions.Item>
                                        </SpaceDescriptions>
                                        <AppDivider />
                                        <GroupPermissions
                                            groupName={
                                                formFields.group.groupName
                                            }
                                        />
                                    </Col>
                                </Row>
                            );
                        }}
                    </FormItem>
                </WizardFormConfiguration.Step>,
            ]}
            onSubmit={handleSubmit}
            showCancelButton
            cancelButtonText="Cancel"
            onCancel={handleCancel}
            {...rest}
        />
    );
}
