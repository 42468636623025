const dashboard = {
    title: 'Redis Overview',
    meta: {
        slug: 'redis-overview',
        supportClusterTypes: ['redis'],
    },
    panels: [
        {
            title: 'Commands per second',
            type: 'chart',
            gridPos: {
                h: 7,
                w: 8,
                x: 0,
                y: 0,
            },
            options: {
                type: 'line',
                legend: {
                    alignAsTable: true,
                },
                postfix: '/s',
            },
            targets: [
                {
                    expr:
                        'irate(redis_commands_processed_total{instance=~"$instance"}[5m])',
                    legendFormat: 'Commands per second',
                },
            ],
        },
        {
            title: 'Total keys',
            type: 'chart',
            gridPos: {
                h: 7,
                w: 8,
                x: 0,
                y: 0,
            },
            options: {
                type: 'line',
                decimals: 0,
                legend: {
                    alignAsTable: true,
                },
            },
            targets: [
                {
                    expr: 'sum(redis_db_keys{instance=~"$instance"})',
                    legendFormat: 'Total keys',
                },
            ],
        },
        {
            title: 'Connected clients',
            type: 'chart',
            gridPos: {
                h: 7,
                w: 8,
                x: 0,
                y: 0,
            },
            options: {
                type: 'line',
                decimals: 0,
                legend: {
                    alignAsTable: true,
                },
            },
            targets: [
                {
                    expr: 'redis_connected_clients{instance=~"$instance"}',
                    legendFormat: 'Connected clients',
                },
            ],
        },
        {
            title: 'Blocked clients',
            type: 'chart',
            gridPos: {
                h: 7,
                w: 8,
                x: 0,
                y: 0,
            },
            options: {
                type: 'line',
                decimals: 0,
                legend: {
                    alignAsTable: true,
                },
            },
            targets: [
                {
                    expr: 'redis_blocked_clients{instance=~"$instance"}',
                    legendFormat: 'Blocked clients',
                },
            ],
        },
        {
            title: 'Memory used',
            type: 'chart',
            gridPos: {
                h: 7,
                w: 8,
                x: 0,
                y: 0,
            },
            options: {
                type: 'area',
                format: 'size',
                legend: {
                    alignAsTable: true,
                },
            },
            targets: [
                {
                    expr: 'redis_memory_used_bytes{instance=~"$instance"}',
                    legendFormat: 'Memory used',
                },
            ],
        },
        {
            title: 'Slave lag',
            type: 'chart',
            gridPos: {
                h: 7,
                w: 8,
                x: 0,
                y: 0,
            },
            options: {
                type: 'line',
                legend: {
                    alignAsTable: true,
                },
                postfix: '/s',
            },
            targets: [
                {
                    expr:
                        'redis_connected_slave_lag_seconds{instance=~"$instance"}',
                    legendFormat: 'Slave lag',
                },
            ],
        },
    ],
    templating: {
        list: [
            {
                datasource: 'clustercontrol',
                multi: false,
                label: 'Host',
                name: 'instance',
                query: "host:$[nodes[nodetype='redis']]",
                optionValue: 'hostname',
                optionText: '{{hostname}} ({{types}})',
                sort: 1,
                isInstancePicker: true,
            },
        ],
    },
};
export default dashboard;
