import React, { useState } from 'react';
import { Form, Alert, Input, Row, Col, Layout, Select } from 'antd';
import useForm from 'antd/lib/form/hooks/useForm';
import ModalDefaultForm from '../../common/ModalDefaultForm';
import FormFooter from '../../common/FormFooter';
import KeyManagementTree from './KeyManagementTree';
import CmonCaService from '../../services/cmon/CmonCaService';
import {
    notifyError,
    notifyOperationSuccess,
    NotifyType,
} from '../Notifications/uiNotification';
import { useDebugContext } from '../../common/Debug';
import { CertificateTypes } from '../Security/UseExistingCertificate';
import CountrySelect from '../../common/DataEntry/CountrySelect';

const { Sider, Content } = Layout;

export default KeyManagementGenerateCertificateModal;

type KeyManagementGenerateCertificateModalFields = {
    type: string;
    certificateAuthKey: string;
    description: string;
    country: string;
    state: string;
    location: string;
    organization: string;
    organizationUnit: string;
    commonName: string;
    emailAddress: string;
    keyLength: number;
    expiration: number;
};

export type KeyManagementGenerateCertificateModalProps = {
    list: any;
    clientServer: boolean;
    onCancel?: () => void;
    onSuccess?: () => void;
};

const keybits = [
    {
        value: 1024,
        label: 1024,
    },
    {
        value: 2048,
        label: 2048,
    },
    {
        value: 4096,
        label: 4096,
    },
];

const typeOptions = [
    {
        value: 'server',
        label: 'Server',
    },
    {
        value: 'client',
        label: 'Client',
    },
];

function KeyManagementGenerateCertificateModal({
    list,
    clientServer = false,
    onCancel,
    onSuccess,
}: KeyManagementGenerateCertificateModalProps) {
    const [form] = useForm<KeyManagementGenerateCertificateModalFields>();

    const [destinationPath, setDestinationPath] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const { log } = useDebugContext();
    const [selectedNode, setSelectedNode] = useState<any>(null);

    const handleCancel = () => {
        onCancel?.();
    };

    const handleTreeItemSelect = (node: any) => {
        if (
            node.type === CertificateTypes.CERTIFICATE_AUTHORITY ||
            node.type === CertificateTypes.CERTIFICATE
        ) {
            const cert = node?.data.certfile
                .replace(/^.*[\\\/]/, '')
                .replace(/\.[^/.]+$/, '');
            form.setFieldsValue({
                certificateAuthKey: cert,
                description: node.data.subjectName.description,
                country: node.data.subjectName.C,
                state: node.data.subjectName.ST,
                location: node.data.subjectName.L,
                organization: node.data.subjectName.O,
                organizationUnit: node.data.subjectName.OU,
                commonName: node.data.subjectName.CN,
                emailAddress: node.data.subjectName.emailAddress,
                keyLength: node.data.keybits,
            });
            setSelectedNode(node);
        }
        setDestinationPath(node.path ? `${node.path}/` : '');
    };

    const handleSubmit = async ({
        type,
        certificateAuthKey,
        description,
        country,
        state,
        location,
        organization,
        organizationUnit,
        commonName,
        emailAddress,
        keyLength,
        expiration,
    }: KeyManagementGenerateCertificateModalFields) => {
        try {
            setLoading(true);
            if (selectedNode?.data.id) {
                await CmonCaService.delete({
                    id: selectedNode.data.id,
                });
            }
            await CmonCaService.create({
                type: type ?? 'ca',
                name: destinationPath
                    ? `${destinationPath}${certificateAuthKey}`
                    : certificateAuthKey,
                validity: expiration,
                data: {
                    keybits: keyLength,
                    CN: commonName,
                    description,
                    emailAddress,
                    C: country,
                    ST: state,
                    L: location,
                    O: organization,
                    OU: organizationUnit,
                },
            });
            setLoading(false);
            onSuccess?.();
            notifyOperationSuccess({
                type: NotifyType.TOAST,
                title: 'Success!',
                content: 'Changes are saved successfully!',
            });
        } catch (err) {
            setLoading(false);
            notifyError({ content: err.message });
            log.error(err);
        }
    };

    return (
        <ModalDefaultForm
            title={
                clientServer
                    ? 'Generate client/server certificate'
                    : 'Certificate authority and key'
            }
            width={944}
            form={form}
            onCancel={handleCancel}
            defaultVisible={true}
            bodyStyle={{ padding: '20px' }}
            loading={false}
        >
            <Form
                form={form}
                onFinish={handleSubmit}
                layout="vertical"
                initialValues={{
                    keyLength: 2048,
                    expiration: 3650,
                    type: clientServer ? typeOptions[0].value : undefined,
                }}
            >
                <Layout>
                    <Sider
                        width={260}
                        style={{
                            background: '#fff',
                            borderRight: '1px solid #ccc',
                            paddingRight: '5px',
                        }}
                    >
                        <KeyManagementTree
                            list={list}
                            onSelect={handleTreeItemSelect}
                        />
                    </Sider>
                    <Content
                        style={{
                            padding: '0 24px',
                            minHeight: 280,
                            background: '#fff',
                        }}
                    >
                        <Alert
                            showIcon
                            style={{
                                marginBottom: '10px',
                            }}
                            message={
                                <>
                                    {clientServer ? (
                                        <ol>
                                            <li>
                                                Sign with an existing CA or
                                                generate a selfsigned
                                                certificate
                                            </li>
                                            <li>
                                                Generate server certificate and
                                                key
                                            </li>
                                            <li>
                                                Generate client certificate and
                                                key
                                            </li>
                                        </ol>
                                    ) : (
                                        <ol>
                                            <li>
                                                Generate a selfsigned
                                                certificate authority and key
                                            </li>
                                            <li>
                                                You can use this CA to sign your
                                                client and server certificates
                                            </li>
                                        </ol>
                                    )}
                                </>
                            }
                        ></Alert>
                        <Alert
                            type="success"
                            style={{
                                marginBottom: '10px',
                                backgroundColor: '#F5F5F5',
                                borderColor: '#F5F5F5',
                            }}
                            message={
                                <>
                                    <b>Destination path: </b>{' '}
                                    <span>
                                        /var/lib/cmon/ca/{destinationPath}
                                    </span>
                                </>
                            }
                        ></Alert>
                        {clientServer ? (
                            <Row gutter={[24, 0]}>
                                <Col xs={12} sm={12} md={12}>
                                    <Form.Item
                                        name="type"
                                        label="Type"
                                        rules={[
                                            {
                                                required: true,
                                                message:
                                                    'Enter the description of the certificate!',
                                            },
                                        ]}
                                    >
                                        <Select options={typeOptions} />
                                    </Form.Item>
                                </Col>
                            </Row>
                        ) : (
                            ''
                        )}
                        <Row gutter={[24, 0]}>
                            <Col xs={12} sm={12} md={12}>
                                <Form.Item
                                    name="certificateAuthKey"
                                    label="Certificate authority and key name"
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                'Enter name without extensions!',
                                        },
                                    ]}
                                >
                                    <Input placeholder="Enter name without extensions" />
                                </Form.Item>
                            </Col>
                            <Col xs={12} sm={12} md={12}>
                                <Form.Item
                                    name="description"
                                    label="Description"
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                'Enter the description of the certificate!',
                                        },
                                    ]}
                                >
                                    <Input placeholder="Name certificate description" />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={[24, 0]}>
                            <Col xs={12} sm={12} md={12}>
                                <Form.Item
                                    name="country"
                                    label="Country"
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                'Select a country from the list',
                                        },
                                    ]}
                                >
                                    <CountrySelect data-testid="key-management-country-select" />
                                </Form.Item>
                            </Col>
                            <Col xs={12} sm={12} md={12}>
                                <Form.Item name="state" label="State">
                                    <Input placeholder="Enter a state name" />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={[24, 0]}>
                            <Col xs={12} sm={12} md={12}>
                                <Form.Item
                                    name="location"
                                    label="Location"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Enter location name!',
                                        },
                                    ]}
                                >
                                    <Input placeholder="Enter location name (city or town)" />
                                </Form.Item>
                            </Col>
                            <Col xs={12} sm={12} md={12}>
                                <Form.Item
                                    name="organization"
                                    label="Organization"
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                'Enter name of organization!',
                                        },
                                    ]}
                                >
                                    <Input placeholder="Enter name of organization" />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={[24, 0]}>
                            <Col xs={12} sm={12} md={12}>
                                <Form.Item
                                    name="organizationUnit"
                                    label="Organization unit"
                                >
                                    <Input placeholder="Enter team or department name" />
                                </Form.Item>
                            </Col>
                            <Col xs={12} sm={12} md={12}>
                                <Form.Item
                                    name="commonName"
                                    label="Common name"
                                >
                                    <Input placeholder="Enter common name" />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={[24, 0]}>
                            <Col xs={12} sm={12} md={12}>
                                <Form.Item
                                    name="emailAddress"
                                    label="Email"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Enter owner's email!",
                                        },
                                    ]}
                                >
                                    <Input placeholder="Enter owner's email" />
                                </Form.Item>
                            </Col>
                            <Col xs={12} sm={12} md={12}>
                                <Form.Item
                                    name="keyLength"
                                    label="Key length"
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                'Enter the absolute path of certificate file!',
                                        },
                                    ]}
                                >
                                    <Select options={keybits} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={[24, 0]}>
                            <Col xs={12} sm={12} md={12}>
                                <Form.Item
                                    name="expiration"
                                    label="Certificate expiration time (in days)"
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                'Enter certification expiration in days',
                                        },
                                    ]}
                                >
                                    <Input type="number" />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Content>
                </Layout>
                <FormFooter
                    loading={false}
                    submitButtonText="Create"
                    showCancelButton
                    showSubmitButton
                    onCancel={handleCancel}
                />
            </Form>
        </ModalDefaultForm>
    );
}
