
import CcNode, { CcNodeProps } from '../../models/CcNode';


export interface CmonMongoHostProps  extends CcNodeProps {
    configtemplate?: string;
    connections_available?: string;
    connections_current?: string;
    dbpath?: string;
    global_lock_queue?: string;
    global_lock_ratio?: string;
    hidden?: string;
    install?: string;
    logpath?: string;
    member_role?: string;
    os_user?: string;
    page_faults?: string;
    pf_op_ratio?: string;
    pidfile?: string;
    priority?: string;
    replication_lag?: string;
    roleid?: string;
    rs?: string;
    slaveDelay?: number;
    softwarepackage?: string;
    ssl_allowinvalidcert?: boolean;
    ssl_mode?: string;

}



export default class CmonMongoHost extends CcNode {
    public configtemplate?: string;
    public connectionsAvailable?: string;
    public connectionsCurrent?: string;
    public dbpath?: string;
    public globalLockQueue?: string;
    public globalLockRatio?: string;
    public hidden?: string;
    public install?: string;
    public logpath?: string;
    public memberRole?: string;
    public osUser?: string;
    public pageFaults?: string;
    public pfOpRatio?: string;
    public pidfile?: string;
    public priority?: string;
    public replicationLag?: string;
    public roleid?: string;
    public rs?: string;
    public slaveDelay?: number;
    public softwarepackage?: string;
    public sslAllowinvalidcert?: boolean;
    public sslMode?: string;


    constructor(props: CmonMongoHostProps) {
        super(props);
        this.configtemplate = props.configtemplate;
        this.connectionsAvailable = props.connections_available;
        this.connectionsCurrent = props.connections_current;
        this.dbpath = props.dbpath;
        this.globalLockQueue = props.global_lock_queue;
        this.globalLockRatio = props.global_lock_ratio;
        this.hidden = props.hidden;
        this.install = props.install;
        this.logpath = props.logpath;
        this.memberRole = props.member_role;
        this.osUser = props.os_user;
        this.pageFaults = props.page_faults;
        this.pfOpRatio = props.pf_op_ratio;
        this.pidfile = props.pidfile;
        this.priority = props.priority;
        this.replicationLag = props.replication_lag;
        this.roleid = props.roleid;
        this.rs = props.rs;
        this.slaveDelay = props.slaveDelay;
        this.softwarepackage = props.softwarepackage;
        this.sslAllowinvalidcert = props.ssl_allowinvalidcert;
        this.sslMode = props.ssl_mode;

    }

}
