import React from 'react';
import ButtonWithForm from '../../common/General/ButtonWithForm';
import AddMaintenanceForm from './AddMaintenanceForm';
import CcNode from '../../services/models/CcNode';
import ActionButton from '@severalnines/bar-frontend-components/build/lib/General/ActionButton';
import CcCluster from '../../services/models/CcCluster';

export default AddMaintenanceButton;

export type AddMaintenanceButtonProps = {
    node?: CcNode;
    cluster?: CcCluster;
};

function AddMaintenanceButton({ node, cluster }: AddMaintenanceButtonProps) {
    return (
        <ButtonWithForm
            button={<ActionButton>Schedule maintenance</ActionButton>}
            form={<AddMaintenanceForm node={node} cluster={cluster} />}
        />
    );
}
