import { Col, Row, Space } from 'antd';
import React from 'react';
import Switch from '../../../../common/DataEntry/Switch';
import FormItemInline from '../../../../common/FormItemInline';
import NodeAutoRecovery from '../FormParts/NodeAutoRecovery';
import InfoIcon from '@severalnines/bar-frontend-components/build/lib/General/InfoIcon';

export default MysqlAdvancedNodeConfiguration;
export type MysqlAdvancedNodeConfigurationProps = {
    hasNodeAutodetect?: boolean;
    nodeInfo?: React.ReactNode;
};
function MysqlAdvancedNodeConfiguration({
    hasNodeAutodetect = false,
}: MysqlAdvancedNodeConfigurationProps) {
    return (
        <div className="MysqlAdvancedNodeConfiguration">
            <Row gutter={[24, 0]}>
                <Col span={24}>
                    <h3>Advanced configuration</h3>
                </Col>
                <Col xs={24} sm={24} md={12}>
                    <FormItemInline
                        justify={true}
                        name={['nodeConfig', 'informationSchemaQueries']}
                        label="information_schema queries"
                        valuePropName="checked"
                        noMargin
                    >
                        <Switch />
                    </FormItemInline>
                    <NodeAutoRecovery />
                    {hasNodeAutodetect ? (
                        <FormItemInline
                            justify={true}
                            name={['nodeConfig', 'nodeAutoDetect']}
                            label={
                                <Space>
                                    Automatic node discovery
                                    <InfoIcon info="If the ClusterControl node uses different IPs/hostnames to connect to Galera Cluster than are used for cluster inter-node connectivity, disable Automatic Node Discovery and pass IPs/hostnames that ClusterControl can use to connect to Galera nodes." />
                                </Space>
                            }
                            valuePropName="checked"
                            noMargin
                        >
                            <Switch />
                        </FormItemInline>
                    ) : null}
                </Col>
            </Row>
        </div>
    );
}
