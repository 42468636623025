import React, { useState } from 'react';
import { Col, Form, Input, Row, Select, Space } from 'antd';
import FormFooter from '../../../../../../common/FormFooter';
import ModalDefaultForm from '../../../../../../common/ModalDefaultForm';
import {
    notifyError,
    notifyOperationSuccess,
    NotifyType,
} from '../../../../../Notifications/uiNotification';
import FormItemInlineSwitch from '../../../../../../common/DataEntry/FormItemInlineSwitch';
import InputNumberWide from '../../../../../../common/DataEntry/InputNumberWide';
import CmonProxysqlService from '../../../../../../services/cmon/CmonProxysqlService';
import CcCluster from '../../../../../../services/models/CcCluster';
import CcProxySqlNode from '../../../../../../services/models/CcProxySqlNode';
import {
    prepareAddScript,
    prepareEditScript,
    prepareScriptInitialValue,
} from './proxySQLSchedulerHelper';
import InfoIcon from '@severalnines/bar-frontend-components/build/lib/General/InfoIcon';
import CcProxySqlSchedule from '../../../../../../services/models/CcProxySqlSchedule';

export default ProxySQLSchedulerScriptModal;

export type ProxySQLSchedulerScriptModalProps = {
    cluster: CcCluster;
    node: CcProxySqlNode;
    onSuccess?: () => void;
    onCancel?: () => void;
    onError?: (err: Error) => void;
    script?: CcProxySqlSchedule;
    edit?: boolean;
    title: string;
};
export type ProxySQLSchedulerFormValues = {
    filename: string;
    arguments: string[];
    interval: string;
    comment: string;
    active: boolean;
};

function ProxySQLSchedulerScriptModal({
    onSuccess,
    onCancel,
    onError,
    cluster,
    node,
    edit,
    title,
    script,
}: ProxySQLSchedulerScriptModalProps) {
    const [form] = Form.useForm<ProxySQLSchedulerFormValues>();
    const [loading, setLoading] = useState<boolean>(false);

    const [argValues, setArgValues] = useState<string[] | undefined>();

    const handleSubmit = async (values: ProxySQLSchedulerFormValues) => {
        try {
            setLoading(true);
            if (edit) {
                await CmonProxysqlService.updateschedule({
                    clusterid: cluster?.clusterId,
                    hostName: node?.hostname,
                    port: node?.port,
                    schedule: {
                        ...prepareEditScript(values),
                        id: Number(script?.id),
                    },
                });
            } else {
                await CmonProxysqlService.insertschedule({
                    clusterid: cluster?.clusterId,
                    hostName: node?.hostname,
                    port: node?.port,
                    schedule: prepareAddScript(values),
                });
            }
            notifyOperationSuccess({
                type: NotifyType.TOAST,
                title: edit ? (
                    <span>Script saved successfully</span>
                ) : (
                    <span>Script added successfully</span>
                ),
            });
            setLoading(false);
            onSuccess?.();
        } catch (error: any) {
            setLoading(false);
            notifyError({
                content: error.message,
            });
            onError?.(error);
        }
    };
    const onArgumentChange = (value: string[]) => {
        setArgValues(value);
    };

    async function argumentsValidator(_: any, value: string[]) {
        if (value?.length === 6) {
            throw new Error('Maximum 5 arguments supported.');
        }
        return true;
    }
    async function scriptPathValidator(_: any, value: string) {
        if (!value?.startsWith('/')) {
            throw new Error('Script path should start with /.');
        }
        return true;
    }

    return (
        <ModalDefaultForm
            title={title}
            form={form}
            footer={[]}
            onCancel={onCancel}
            width={900}
            defaultVisible={true}
        >
            <Form
                className="ProxySQLSchedulerScriptModal"
                form={form}
                layout="vertical"
                onFinish={handleSubmit}
                initialValues={
                    edit && script
                        ? prepareScriptInitialValue(script)
                        : {
                              active: true,
                          }
                }
            >
                <Row gutter={[24, 0]}>
                    <Col span={12}>
                        <Form.Item
                            name={'filename'}
                            label={<Space>Script Path</Space>}
                            rules={[
                                {
                                    validator: scriptPathValidator,
                                },
                                {
                                    required: true,
                                    message: 'Enter script path.',
                                },
                            ]}
                        >
                            <Input placeholder="Script Path."></Input>
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item
                            name={'arguments'}
                            label={<Space>Arguments</Space>}
                            rules={[
                                {
                                    validator: argumentsValidator,
                                },
                            ]}
                        >
                            <Select
                                onChange={onArgumentChange}
                                value={argValues}
                                mode="tags"
                                placeholder="Type to add arguments"
                            ></Select>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name={'interval'}
                            label={
                                <Space>
                                    Interval (ms)
                                    <InfoIcon
                                        info={
                                            'How often (in millisecond) the script will be executed.'
                                        }
                                    />
                                </Space>
                            }
                            rules={[
                                {
                                    required: true,
                                    message: 'Enter interval.',
                                },
                            ]}
                        >
                            <InputNumberWide
                                min={0}
                                placeholder="Interval (ms)."
                            ></InputNumberWide>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name={'comment'}
                            label={<Space>Comment</Space>}
                        >
                            <Input placeholder="Comment." />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={[24, 0]}>
                    <Col span={12}>
                        <FormItemInlineSwitch
                            justify={true}
                            label="Active"
                            name={'active'}
                            labelStrong={false}
                            valuePropName="checked"
                        />
                    </Col>
                </Row>

                <FormFooter
                    loading={loading}
                    showCancelButton
                    onCancel={onCancel}
                    submitButtonText="Submit"
                    showSubmitButton
                />
            </Form>
        </ModalDefaultForm>
    );
}
