import WizardForm from '@severalnines/bar-frontend-components/build/lib/Navigation/WizardForm';
import { ProxySQLRulesFormSteps } from '../ProxySQLRulesWizardModal';
import ProxySQLRulesType from '../ProxySQLRulesType';
import { FormInstance } from 'antd/lib/form';
import RulesCacheQueryConfig, {
    CACHE_CONFIGURATION_VALIDATION,
} from './RulesCacheQueryConfig';
import RulesCacheQueryPreview from './RulesCacheQueryPreview';

function rulesCacheQuerySteps(
    hostGroupList: { value: string; label: string }[] | undefined,
    form: FormInstance
) {
    return [
        <WizardForm.Step
            key={ProxySQLRulesFormSteps.CONFIGURATION}
            title="Configuration"
            subTitle=" "
            validate={CACHE_CONFIGURATION_VALIDATION}
        >
            <RulesCacheQueryConfig hostGroupList={hostGroupList} />
        </WizardForm.Step>,

        <WizardForm.Step
            key={ProxySQLRulesFormSteps.TYPE}
            title="Type"
            subTitle=" "
        >
            <ProxySQLRulesType form={form} />
        </WizardForm.Step>,
        <WizardForm.Step
            key={ProxySQLRulesFormSteps.PREVIEW}
            title="Preview"
            subTitle=" "
        >
            <RulesCacheQueryPreview form={form} />
        </WizardForm.Step>,
    ];
}

export default rulesCacheQuerySteps;
