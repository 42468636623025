import React, { useMemo } from 'react';
import { Form, Space } from 'antd';
import SshCheckableNodesAutocomplete from '../../../common/DataEntry/SshCheckeableNodesAutocomplete';
import { FormInstance, FormItemProps } from 'antd/lib/form';
import CcCluster from '../../../services/models/CcCluster';
import { CcNodeType } from '../../../services/models/CcNode';
import { TopologyItem } from '../../Topology/TopologyItem';
import { getHostWithDesc, getNodeHostWithDesc } from '../../Nodes/NodeFormat';

export default KeepAlivedHostnameField;
export type KeepAlivedHostnameFieldProps = FormItemProps & {
    form: FormInstance;
    cluster: CcCluster;
    nodeTypes?: CcNodeType[];
    selectHosts?: boolean;
};

// @todo this can be transformed to a common component, e.g. SshCheckableClusterHostSelect
function KeepAlivedHostnameField({
    name,
    cluster,
    form,
    nodeTypes,
    selectHosts = false,
    ...rest
}: KeepAlivedHostnameFieldProps) {
    const hostnames: TopologyItem[] = name
        ? form.getFieldValue(name) || []
        : [];

    const hostnameSnapshot = hostnames
        .map((item) => item.extraData.hostname)
        .filter((hostname: string) => !!hostname)
        .join(',');
    const nodeTypesSnapshot = nodeTypes?.join(',') || null;

    const options = useMemo(
        () =>
            selectHosts
                ? cluster
                      .getHosts()
                      .filter((host) => {
                          if (
                              nodeTypes &&
                              !host.nodes.some((node) =>
                                  nodeTypes.includes(
                                      node.nodetype as CcNodeType
                                  )
                              )
                          ) {
                              return false;
                          }

                          if (
                              hostnames &&
                              hostnames.some(
                                  (hostname) =>
                                      hostname.extraData.hostname ===
                                      host.hostname
                              )
                          ) {
                              return false;
                          }

                          return true;
                      })
                      .map((host) => ({
                          value: `${host.hostname}`,
                          label: getHostWithDesc(host),
                      }))
                : cluster.nodes
                      .filter((node) => {
                          if (
                              nodeTypes &&
                              !nodeTypes.includes(node.nodetype as CcNodeType)
                          ) {
                              return false;
                          }
                          if (
                              hostnames &&
                              hostnames.some(
                                  (hostname) =>
                                      hostname.extraData.hostname ===
                                      node.hostname
                              )
                          ) {
                              return false;
                          }
                          return true;
                      })
                      .map((node) => ({
                          value: `${node.hostname}`,
                          label: getNodeHostWithDesc(node),
                          port: `${node.port}`,
                      })),
        [nodeTypesSnapshot, hostnameSnapshot, selectHosts]
    );

    return (
        <Form.Item
            name={name}
            label={<Space>Keepalived</Space>}
            rules={[
                {
                    required: true,
                    message: 'Please select server hostname.',
                },
            ]}
            {...rest}
        >
            <SshCheckableNodesAutocomplete
                clusterId={cluster?.clusterId}
                emptyState={null}
                onlyPrimaries={true}
                formProps={{
                    primaryTitle: null,
                    primaryFormItemProps: {
                        withLessMarginBottom: true,
                    },
                }}
                autocompleteOptions={options}
            />
        </Form.Item>
    );
}
