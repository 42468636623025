import React from 'react';
import CreateServiceButton from '../Services/CreateServiceButton';
import AppEmpty, { AppEmptyProps } from '../../common/Feedback/AppEmpty';
import UserAclExecuteClusters from '../User/UserAclExecuteClusters';

export default ClustersEmpty;

export type ClustersEmptyProps = AppEmptyProps & {};

function ClustersEmpty({ ...rest }: ClustersEmptyProps) {
    return (
        <AppEmpty
            className="ClustersEmpty"
            description="You haven’t created any clusters. When you do, it'll show up here."
            extra={
                <UserAclExecuteClusters>
                    <CreateServiceButton type="primary" />
                </UserAclExecuteClusters>
            }
            {...rest}
        />
    );
}
