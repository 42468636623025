import { useEffect, useState } from 'react';
import useFetch from '../../common/useFetch';
import CmonJobService from '../../services/cmon/CmonJobsService';
import { useSelector } from 'react-redux';
import { AppState } from '../../appReducer';
import CmonJobMessage from '../../services/cmon/models/CmonJobMessage';

export default useJobMessages;
export type UseJobMessagesProps = {
    jobId: number;
};

function useJobMessages({ jobId }: UseJobMessagesProps) {
    const [running, setRunning] = useState<boolean>(false);
    const { data: messages, refresh: logRefresh, loaded, error } = useFetch<
        CmonJobMessage[]
    >({
        name: 'job-log-modal',
        fetchFn: async (params, opts) => {
            const { messages } = await CmonJobService.getJobLog(
                { job_id: jobId, ...params },
                opts
            );

            return messages;
        },
        cancelFn: async ({ requestId }) => {
            await CmonJobService.cancelRequest(requestId);
        },
    });
    const [storedRunningJobs] = useSelector(({ runningJobs }: AppState) => [
        runningJobs,
    ]);
    useEffect(() => {
        (async () => {
            await logRefresh({});
        })();
    }, []);

    useEffect(() => {
        const runningJob = storedRunningJobs
            .toList()
            .toArray()
            .find((j) => j.jobId === jobId);
        // refreshing whenever running jobs are updated and the job is still running
        if (runningJob) {
            setRunning(true);
            (async () => {
                await logRefresh({});
            })();
        } else {
            if (running === true) {
                // current value of running is true but is going to be false
                // we do the last refresh
                (async () => {
                    await logRefresh({});
                })();
            }
            setRunning(false);
        }
    }, [storedRunningJobs]);

    return { messages, loaded, error };
}
