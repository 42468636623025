import React from 'react';
import SpaceDescriptions from '../../../common/Layout/SpaceDescriptions';
import { getRepositoryText } from '../../../common/DataEntry/RepositoryInput';
import SpaceWide from '../../../common/SpaceWide';
import { FormInstance } from 'antd/lib/form';
import YesNoFormat from '../../../common/Format/YesNoFormat';

export default NodeConfigurationSummary;

export type NodeConfigurationSummaryProps = {
    form: FormInstance;
    hasAdvancedNodeConfiguration?: boolean;
    hasNodeAutodetect?: boolean;
    hasSslEncryption?: boolean;
    hasSemiSynchronous?: boolean;
    hasPrimaryCluster?: boolean;
    extra?: React.ReactNode;
};

function NodeConfigurationSummary({
    form,
    hasAdvancedNodeConfiguration = false,
    hasNodeAutodetect = false,
    hasSslEncryption = false,
    hasSemiSynchronous = false,
    hasPrimaryCluster,
    extra,
}: NodeConfigurationSummaryProps) {
    const nodeConfig = form.getFieldValue('nodeConfig');
    return (
        <SpaceDescriptions
            direction="vertical"
            title="Node configuration"
            className="NodeConfigurationSummary"
            alignItems="right"
        >
            <SpaceDescriptions.Item label="Server port" labelStrong>
                {nodeConfig?.serverPort}
            </SpaceDescriptions.Item>
            <SpaceDescriptions.Item label="Server data directory" labelStrong>
                {nodeConfig?.serverDataDirectory}
            </SpaceDescriptions.Item>
            {nodeConfig?.configurationTemplate ? (
                <SpaceDescriptions.Item
                    label="Configuration template"
                    labelStrong
                >
                    {nodeConfig?.configurationTemplate}
                </SpaceDescriptions.Item>
            ) : null}
            <SpaceDescriptions.Item label="Admin/Root user" labelStrong>
                {nodeConfig?.adminUser}
            </SpaceDescriptions.Item>
            <SpaceDescriptions.Item label="Admin/Root password" labelStrong>
                {nodeConfig?.adminPassword
                    ? nodeConfig.adminPassword.replace(/./g, '*')
                    : null}
            </SpaceDescriptions.Item>
            {nodeConfig?.repository ? (
                <SpaceDescriptions.Item label="Repository" labelStrong>
                    {getRepositoryText(nodeConfig?.repository)}
                </SpaceDescriptions.Item>
            ) : null}

            {hasAdvancedNodeConfiguration ? (
                <SpaceWide direction="vertical">
                    <SpaceDescriptions.Item
                        label="information_schema"
                        labelStrong
                    >
                        <YesNoFormat
                            booleanVar={nodeConfig.informationSchemaQueries}
                        />
                    </SpaceDescriptions.Item>
                    <SpaceDescriptions.Item
                        label="Cluster auto-recovery"
                        labelStrong
                    >
                        <YesNoFormat
                            booleanVar={nodeConfig.clusterAutoRecovery}
                        />
                    </SpaceDescriptions.Item>
                    <SpaceDescriptions.Item
                        label="Node auto-recovery"
                        labelStrong
                    >
                        <YesNoFormat booleanVar={nodeConfig.nodeAutoRecovery} />
                    </SpaceDescriptions.Item>
                    {hasNodeAutodetect ? (
                        <SpaceDescriptions.Item
                            label="Automatic node discovery"
                            labelStrong
                        >
                            <YesNoFormat
                                booleanVar={nodeConfig.nodeAutoDetect}
                            />
                        </SpaceDescriptions.Item>
                    ) : null}
                </SpaceWide>
            ) : null}
            {hasSslEncryption ? (
                <SpaceDescriptions.Item
                    label="Enable SSL encryption"
                    labelStrong
                >
                    <YesNoFormat booleanVar={nodeConfig.sslEncryption} />
                </SpaceDescriptions.Item>
            ) : null}
            {hasSemiSynchronous ? (
                <SpaceDescriptions.Item
                    label="Semi-synchronous replication"
                    labelStrong
                >
                    <YesNoFormat booleanVar={nodeConfig.semiSynchronous} />
                </SpaceDescriptions.Item>
            ) : null}
            {hasPrimaryCluster ? (
                <SpaceDescriptions.Item label="Readonly cluster" labelStrong>
                    <YesNoFormat booleanVar={nodeConfig.readonly} />
                </SpaceDescriptions.Item>
            ) : null}
            {extra}
        </SpaceDescriptions>
    );
}
