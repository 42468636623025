import React from 'react';
import ModalDefaultForm from '../../common/ModalDefaultForm';
import CcBackup from '../../services/models/CcBackup';
import useForm from 'antd/lib/form/hooks/useForm';
import { Alert, Form, Select } from 'antd';
import FormFooter from '../../common/FormFooter';
import useCreateJob from '../Jobs/useCreateJob';
import { CmonJobInstanceCommand } from '../../services/cmon/models/CmonJobInstance';
import StorageLocationFormat from './StorageLocations/StorageLocationFormat';
import BackupFormat from './BackupFormat';

export default BackupDeleteModal;

export type BackupDeleteModalProps = {
    backup: CcBackup;
    onCancel?: () => void;
    onSuccess?: () => void;
};

type DownloadFieldsType = {
    uuid: string;
};

function BackupDeleteModal({
    backup,
    onCancel,
    onSuccess,
}: BackupDeleteModalProps) {
    const [form] = useForm<DownloadFieldsType>();

    const { loading, send } = useCreateJob({
        clusterId: backup.getCid(),
        title: 'Delete Backup / Storage Location',
        command: CmonJobInstanceCommand.DELETE_BACKUP,
        onSuccess,
    });

    const handleCancel = () => {
        onCancel?.();
    };

    const handleSubmit = async ({ uuid }: DownloadFieldsType) => {
        const storageLocation = backup
            .getStorageLocations()
            .find((s) => s.getUuid() === uuid);

        await send({
            backupid: backup.getId(),
            clusterId: backup.getCid(),
            cloud_location_uuid: storageLocation?.isTypeCloud()
                ? uuid
                : undefined,
            host_location_uuid: storageLocation?.isTypeHost()
                ? uuid
                : undefined,
        });
    };
    return (
        <ModalDefaultForm
            title={`Delete backup or storage location`}
            width={450}
            form={form}
            onCancel={handleCancel}
            defaultVisible={true}
            bodyStyle={{ padding: '20px' }}
            loading={loading}
        >
            <Form
                form={form}
                onFinish={handleSubmit}
                layout="vertical"
                initialValues={{ uuid: 'all' }}
            >
                <Form.Item>
                    <BackupFormat
                        backup={backup}
                        size="large"
                        contentProps={{ style: { width: '100%' } }}
                    />
                </Form.Item>
                <Form.Item
                    name="uuid"
                    label="Delete all or single storage location"
                >
                    <Select data-testid="BackupDeleteModal-Form-uuid">
                        <Select.Option value="all" key="all">
                            All
                        </Select.Option>
                        {backup.getStorageLocations().map((storageLocation) => (
                            <Select.Option
                                value={storageLocation.getUuid()}
                                key={storageLocation.getUuid()}
                            >
                                <StorageLocationFormat
                                    storageLocation={storageLocation}
                                >
                                    {storageLocation.getPath()}
                                </StorageLocationFormat>
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item shouldUpdate>
                    {() => {
                        return form.getFieldValue('uuid') === 'all' ? (
                            <Alert
                                message={
                                    "The backup, including all its' storage locations, will be deleted forever. You will not be able to restore it afterwards."
                                }
                            />
                        ) : null;
                    }}
                </Form.Item>
                <FormFooter
                    loading={loading}
                    submitButtonText="Delete"
                    showCancelButton
                    showSubmitButton
                    onCancel={handleCancel}
                />
            </Form>
        </ModalDefaultForm>
    );
}
