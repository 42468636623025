import React from 'react';
import './LogSeverityFilterDropdown.less';
import AppSlider from '../../common/DataEntry/AppSlider';
import { getLogSeverities } from '../../services/models/CcLogEntry';
import {
    ColumnFilterItem,
    FilterDropdownProps,
} from 'antd/lib/table/interface';

export default LogSeverityFilterDropdown;

export type LogSeverityFilterDropdownProps = {
    filterDropdownProps: FilterDropdownProps;
};

function LogSeverityFilterDropdown({
    filterDropdownProps,
}: LogSeverityFilterDropdownProps) {
    const severities = filterDropdownProps.filters || [];
    const selectedValue = getLogSeverities().findIndex(
        (s) => s === filterDropdownProps.selectedKeys[0]
    );

    const marks = severities.reduce(
        (acc: { [key: number]: any }, currItem: ColumnFilterItem, index) => {
            acc[index] = {
                label: currItem.text,
            };
            return acc;
        },
        {}
    );
    return (
        <div className="LogSeverityFilterDropdown">
            <AppSlider
                vertical={true}
                value={selectedValue}
                marks={marks}
                step={1}
                min={0}
                max={severities.length - 1}
                reverse={true}
                onChange={(value) => {
                    filterDropdownProps.setSelectedKeys([
                        severities[value].value as string,
                    ]);
                    filterDropdownProps.confirm();
                }}
                tooltipVisible={false}
                handleStyle={{
                    marginTop: '0px',
                }}
                dots={false}
            />
        </div>
    );
}
