import ActionButton from '@severalnines/bar-frontend-components/build/lib/General/ActionButton';
import React from 'react';
import CcAdvisorSchedule from '../../../services/models/CcAdvisorSchedule';
import CmonImperativeService from '../../../services/cmon/CmonImperativeService';
import CcCluster from '../../../services/models/CcCluster';

export default AdvisorActivityToggleActionButton;

type AdvisorActivityToggleActionButtonProps = {
    cluster: CcCluster;

    advisorSchedule?: CcAdvisorSchedule;
    onSuccess?: () => void;
};

function AdvisorActivityToggleActionButton({
    cluster,
    advisorSchedule,
    onSuccess,
}: AdvisorActivityToggleActionButtonProps) {
    const enableAdvisor = async (enabled: boolean) => {
        try {
            await CmonImperativeService.changeSchedule({
                cluster_id: cluster?.clusterId,
                schedule_id: advisorSchedule?.scheduleid,
                enabled: enabled,
            });
        } catch (e) {
            console.error(e);
        }
    };

    const handleEnableAdvisor = async () => {
        await enableAdvisor(true);
        onSuccess?.();
    };

    const handleDisableAdvisor = async () => {
        await enableAdvisor(false);
        onSuccess?.();
    };
    return advisorSchedule?.enabled ? (
        <ActionButton critical={true} onClick={handleDisableAdvisor}>
            Disable
        </ActionButton>
    ) : (
        <ActionButton onClick={handleEnableAdvisor}>Enable</ActionButton>
    );
}
