export enum CcSnapshotRepositoryType {
    FS = 'fs',
    S3 = 's3',
}

export type CcSnapshotRepositoryProps = {
    name?: string;
    cluster_id?: number;
    settings?: {
        bucket?: string;
        client?: string;
        region?: string;
        location?: string;
    };
    storage_host?: string;
    type?: CcSnapshotRepositoryType;
};

export default class CcSnapshotRepository {
    public readonly name?: string;
    public readonly clusterId?: number;
    public readonly settings?: {
        bucket?: string;
        client?: string;
        region?: string;
        location?: string;
    };
    public readonly type?: CcSnapshotRepositoryType;
    public readonly storageHost?: string;
    constructor(props: CcSnapshotRepositoryProps) {
        this.name = props.name;
        this.clusterId = props.cluster_id;
        this.type = props.type;
        this.settings = props.settings;
        this.storageHost = props.storage_host;
    }

    getLocation() {
        switch (this.type) {
            case CcSnapshotRepositoryType.FS:
                return this.settings?.location;
            case CcSnapshotRepositoryType.S3:
                return `${this.settings?.region}:${this.settings?.client}/${this.settings?.bucket}`;
            default:
                return '';
        }
    }
}
