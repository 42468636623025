import React from 'react';
import CcNode, { CcNodeStatus } from '../../services/models/CcNode';
import Alert, {
    AlertProps,
} from '@severalnines/bar-frontend-components/build/lib/Feedback/Alert';
import TypographyText from '@severalnines/bar-frontend-components/build/lib/General/TypographyText';

export default NodeAlert;

export type NodeAlertProps = AlertProps & {
    node: CcNode;
};

function NodeAlert({ node, ...rest }: NodeAlertProps) {
    return node.hoststatus !== CcNodeStatus.CmonHostOnline ? (
        <Alert
            type="warning"
            showIcon={true}
            message={
                <TypographyText
                    style={{ whiteSpace: 'normal', wordBreak: 'break-word' }}
                >
                    {node.getMessage()}
                </TypographyText>
            }
            {...rest}
        />
    ) : null;
}
