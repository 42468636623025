import CmonJobInstance, {
    CmonJobInstanceCommand,
    CmonJobInstanceProps,
} from '../cmon/models/CmonJobInstance';

export enum CcJobStatus {
    DEFINED = 'DEFINED',
    DEQUEUED = 'DEQUEUED',
    SCHEDULED = 'SCHEDULED',
    RUNNING = 'RUNNING',
    RUNNING2 = 'RUNNING2',
    RUNNING3 = 'RUNNING3',
    RUNNINGEXT = 'RUNNINGEXT',
    FINISHED = 'FINISHED',
    ABORTED = 'ABORTED',
    FAILED = 'FAILED',
    PAUSED = 'PAUSED',
}

export function getJobKey({
    clusterId,
    jobId,
    command,
    spec,
}: {
    clusterId?: number;
    command?: string;
    jobId?: number;
    spec?: {
        job_data?: any;
    };
}) {
    let parts = [`${clusterId}/${command}`];
    switch (command?.toUpperCase()) {
        case CmonJobInstanceCommand.CREATE_CLUSTER:
        case CmonJobInstanceCommand.BACKUP:
            if (jobId) {
                parts.push(`${jobId}`);
            }
            break;
        case CmonJobInstanceCommand.RESTORE_BACKUP:
        case CmonJobInstanceCommand.DELETE_BACKUP:
        case CmonJobInstanceCommand.UPLOAD_BACKUP_DATA_TO_CLOUD_STORAGE:
        case CmonJobInstanceCommand.DOWNLOAD_BACKUP_DATA_FROM_CLOUD_STORAGE:
            if (spec) {
                parts.push(`${spec.job_data.backupid}`);
            }
            break;
        case CmonJobInstanceCommand.DISABLE_BINARY_LOGGING:
        case CmonJobInstanceCommand.RESTART:
        case CmonJobInstanceCommand.STOP:
        case CmonJobInstanceCommand.START:
            if (spec) {
                parts.push(`${spec.job_data.hostname}/${spec.job_data.port}`);
            }
            break;
        case CmonJobInstanceCommand.REBOOT:
            if (spec) {
                parts.push(`${spec.job_data.hostname}`);
            }
            break;
        case CmonJobInstanceCommand.ENABLE_DB_READONLY:
        case CmonJobInstanceCommand.DISABLE_DB_READONLY:
        case CmonJobInstanceCommand.STOP_REPLICATION_SLAVE:
        case CmonJobInstanceCommand.START_REPLICATION_SLAVE:
        case CmonJobInstanceCommand.REMOVENODE:
        case CmonJobInstanceCommand.TOGGLE_REPLICATION_SYNC:
            if (spec) {
                parts.push(
                    `${spec.job_data.node?.hostname}/${spec.job_data.node?.port}`
                );
            }
            break;
        case CmonJobInstanceCommand.PROMOTE_REPLICATION_SLAVE:
        case CmonJobInstanceCommand.STAGE_REPLICATION_SLAVE:
        case CmonJobInstanceCommand.FAILOVER_REPLICATION_SLAVE:
            if (spec) {
                parts.push(`${spec.job_data.slave_address}`);
            }
            break;
        case CmonJobInstanceCommand.RESET_REPLICATION_SLAVE:
            if (spec) {
                parts.push(
                    `${spec.job_data.replication_slave?.hostname}/${spec.job_data.replication_slave?.port}`
                );
            }
            break;
        default:
    }
    return parts.join('/');
}

export function getNodeRelatedJobs() {
    return [
        CmonJobInstanceCommand.DISABLE_BINARY_LOGGING,
        CmonJobInstanceCommand.RESTART,
        CmonJobInstanceCommand.STOP,
        CmonJobInstanceCommand.START,
        CmonJobInstanceCommand.REBOOT,
        CmonJobInstanceCommand.ENABLE_DB_READONLY,
        CmonJobInstanceCommand.DISABLE_DB_READONLY,
        CmonJobInstanceCommand.STOP_REPLICATION_SLAVE,
        CmonJobInstanceCommand.START_REPLICATION_SLAVE,
        CmonJobInstanceCommand.RESET_REPLICATION_SLAVE,
        CmonJobInstanceCommand.REMOVENODE,
        CmonJobInstanceCommand.PROMOTE_REPLICATION_SLAVE,
        CmonJobInstanceCommand.STAGE_REPLICATION_SLAVE,
        CmonJobInstanceCommand.FAILOVER_REPLICATION_SLAVE,
        CmonJobInstanceCommand.TOGGLE_REPLICATION_SYNC,
    ];
}
export function getHostRelatedJobs() {
    return [CmonJobInstanceCommand.REBOOT];
}

export type CcJobProps = CmonJobInstanceProps & {
    status: CcJobStatus;
    parent_job_id?: number;
    new_job?: boolean;
};
export default class CcJob extends CmonJobInstance {
    public readonly status: CcJobStatus;
    public readonly spec: any;
    public readonly parentId: number;
    public readonly newJob?: boolean;

    constructor(props: CcJobProps) {
        super(props);
        this.status = props.status;
        //@TODO:  investigate why there are some jobs with string as job_spec and some with object
        this.spec = null;
        try {
            if (typeof props.job_spec === 'string') {
                this.spec = JSON.parse(props.job_spec);
            } else {
                this.spec = props.job_spec;
            }
        } catch (err) {
            this.spec = { title: props.job_spec };
        }
        this.parentId = props.parent_job_id || 0;
        this.newJob = props.new_job;
    }

    public getDuration(): number {
        let duration = 0;
        if (this.started) {
            if (
                [
                    CcJobStatus.FINISHED,
                    CcJobStatus.ABORTED,
                    CcJobStatus.FAILED,
                ].includes(this.status) &&
                this.ended
            ) {
                duration =
                    new Date(this.ended).getTime() -
                    new Date(this.started).getTime();
            } else {
                duration =
                    new Date().getTime() - new Date(this.started).getTime();
            }
        }

        return duration;
    }

    public getClusterKey(): string {
        return `${this.clusterId}`;
    }

    public getKey(): string {
        return `${this.jobId}`;
    }

    public getCommand() {
        return this.spec?.command.toUpperCase();
    }

    public getCommandClusterKey(): string {
        return getJobKey({ ...this, command: this.spec?.command });
    }

    public isCommand(command: CmonJobInstanceCommand): boolean {
        return command === this.getCommand();
    }

    public isRunning() {
        return [
            CcJobStatus.DEFINED,
            CcJobStatus.RUNNING,
            CcJobStatus.RUNNING2,
            CcJobStatus.RUNNING3,
            CcJobStatus.RUNNINGEXT,
        ].includes(this.status);
    }
}
