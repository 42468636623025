import CmonAccount, { CmonAccountProps } from '../cmon/models/CmonAccount';

export enum CcDatabaseAccountStats {
    ACTIVE = 'active',
    INACTIVE = 'inactive',
    UNKNOWN = 'unknown',
}
type CcDatabaseAccountProps = CmonAccountProps & {
    status?: CcDatabaseAccountStats;
    privileges?: any[];
};
export default class CcDatabaseAccount extends CmonAccount {
    public status?: CcDatabaseAccountStats;
    public privileges?: { db: string; privileges: string[] }[];

    constructor(props: CcDatabaseAccountProps) {
        super(props);
        this.status = props.status;
        if (props.privileges) {
            this.privileges = props.privileges;
        } else if (props.grants) {
            this.privileges = props.grants.split(';').map((privilege) => {
                if (privilege.indexOf(':') === -1) {
                    return {
                        db: '*.*',
                        privileges: Array.from(
                            new Set(privilege.split(','))
                        ).sort(),
                    };
                }
                const [database, privileges] = privilege.split(':');
                return {
                    db: database,
                    privileges: Array.from(
                        new Set(privileges.split(','))
                    ).sort(),
                };
            });
        }
    }
}
