import { AppState, AppStateLicenseInfo } from '../../appReducer';
import { useSelector } from 'react-redux';
import { ReactNode, useCallback } from 'react';
import { CcLicenseFeatureType } from '../../services/models/CcLicense';
import Alert from '@severalnines/bar-frontend-components/build/lib/Feedback/Alert';
import { AlertProps, Button } from 'antd';

export default function useFeatureCheck() {
    const [licenseInfo]: [AppStateLicenseInfo] = useSelector(
        ({ licenseInfo }: AppState) => [licenseInfo]
    );

    const isFeatureEnabled = useCallback(
        (
            feature: CcLicenseFeatureType,
            callback?: (result: boolean) => boolean
        ): boolean => {
            const result: boolean = !!licenseInfo?.isFeatureEnabled(feature);
            if (callback) {
                return callback(result);
            }
            return result;
        },
        [licenseInfo]
    );

    return { isFeatureEnabled, licenseInfo };
}

export type FeatureDisabledContentProps = {
    feature: CcLicenseFeatureType;
    children?: ReactNode;
};

export function FeatureDisabledContent({
    feature,
    children,
}: FeatureDisabledContentProps) {
    const { isFeatureEnabled } = useFeatureCheck();

    return (!isFeatureEnabled(feature) ? children : null) as JSX.Element;
}

export type FeatureDisabledAlertProps = AlertProps & {
    feature: CcLicenseFeatureType;
    showUpgrade?: boolean;
};

export function FeatureDisabledAlert({
    feature,
    message,
    showUpgrade = true,
    ...rest
}: FeatureDisabledAlertProps) {
    return (
        <FeatureDisabledContent feature={feature}>
            <Alert
                message={
                    <span>
                        {message || (
                            <span>
                                This feature is not enabled in your active
                                subscription.
                            </span>
                        )}
                    </span>
                }
                type="warning"
                showIcon={true}
                extra={
                    showUpgrade ? (
                        <Button size="small" type="link">
                            Contact Sales
                        </Button>
                    ) : null
                }
                {...rest}
            />
        </FeatureDisabledContent>
    );
}
