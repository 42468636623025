import useListFetch from '../../../common/useListFetch';
import CmonCloudService from '../../../services/cmon/CmonCloudService';
import CcCloudCredentials, {
    CcCloudProviderType,
} from '../../../services/models/CcCloudCredentials';
import { useCallback, useEffect, useReducer, useState } from 'react';
import {
    initialListState,
    listReducer,
    setListAll,
} from '../../../common/listReducer';
import { arrayFilter, arrayPages } from '../../../common/filtering';
type ListParams = {
    page?: number;
    pageSize?: number;
    order?: (a: any, b: any) => number;
    filters?: Function[];
};
export type UseCloudCredentialsFilterParams = ListParams & { arr?: any[] };

export type UseCloudCredentialsProps = ListParams & { name?: string | null };
export default function useCloudCredentials({
    pageSize,
}: UseCloudCredentialsProps = {}) {
    const [list, setList] = useState<CcCloudCredentials[]>();
    const [total, setTotal] = useState<number>(0);
    const [
        {
            page: listPage,
            pageSize: listPageSize,
            order: listOrder,
            filters: listFilters,
        },
        listDispatch,
    ] = useReducer(listReducer, {
        ...initialListState,
        pageSize: pageSize || 0,
    });

    const {
        list: recordList,
        loading,
        loaded,
        refresh,
    } = useListFetch({
        name: 'cloud-credentials-list',
        fetchFn: async ({ pageSize, page, ...rest }, opts) => {
            const { result } = await CmonCloudService.listCredentials(
                {
                    ...rest,
                },
                opts
            );
            return {
                list: getCloudCredentialsModelList(result),
            };
        },
        cancelFn: async ({ requestId }) => {
            await CmonCloudService.cancelRequest(requestId);
        },
    });

    const filter = useCallback<(p?: UseCloudCredentialsFilterParams) => void>(
        ({
            page = listPage,
            pageSize = listPageSize,
            order = listOrder,
            filters = listFilters,
            arr = recordList,
        } = {}) => {
            listDispatch(setListAll({ page, pageSize, order, filters }));

            const filteredArr = arrayFilter({
                filters: filters || [],
                arr,
            });
            setList(
                arrayPages({
                    page,
                    pageSize,
                    order,
                    arr: filteredArr,
                })
            );
            setTotal(filteredArr.length);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [recordList, listPage, listPageSize, listOrder, listFilters]
    );

    useEffect(() => {
        if (recordList) {
            filter({
                arr: recordList,
            });
        }
    }, [recordList]);

    return {
        list,
        loading,
        loaded,
        refresh,
        filter,
        page: listPage,
        pageSize: listPageSize,
        total,
    };
}

export function getCloudCredentialsModelList(result: any) {
    return Object.entries(result)
        .map(([key, item]: any) =>
            item.map(
                (record: any) =>
                    new CcCloudCredentials({
                        ...record,
                        provider: key,
                    })
            )
        )
        .flat();
}

export async function createCloudCredentialsBucket(
    bucketName: string,
    credentialsId: number,
    provider: CcCloudProviderType
) {
    const {
        response: { json },
    } = await CmonCloudService.proxy(
        {
            uri: `/${provider}/storage`,
            credentials_id: credentialsId,
            method: 'POST',
            provider: provider,
            body: { bucket: bucketName },
        },
        {}
    );

    return json;
}
