import React, { useState } from 'react';
import { Col, Form, Input, Row, Space } from 'antd';
import FormFooter from '../../../../../../common/FormFooter';
import ModalDefaultForm from '../../../../../../common/ModalDefaultForm';
import {
    notifyError,
    notifyOperationSuccess,
    NotifyType,
} from '../../../../../Notifications/uiNotification';
import FormItemInlineSwitch from '../../../../../../common/DataEntry/FormItemInlineSwitch';
import AppSelect from '../../../../../../common/DataEntry/AppSelect';
import {
    prepareEditUserInitialValue,
    prepareUserDataEndPoint,
    privilegeOptions,
} from './proxySQLUserHelper';
import InputNumberWide from '../../../../../../common/DataEntry/InputNumberWide';
import CmonProxysqlService from '../../../../../../services/cmon/CmonProxysqlService';
import CcCluster from '../../../../../../services/models/CcCluster';
import CcProxySqlNode from '../../../../../../services/models/CcProxySqlNode';
import CcProxySqlUser from '../../../../../../services/models/CcProxySqlUser';
import { dbNameValidator } from '../../../../../LoadBalancer/CreateLoadBalancerWizard/CreateProxySQLWizardFrom/CreateProxySQLWizradFromSteps/CreateProxySQLConfiguration';

export default ProxySQLUsersModal;

export type ProxySQLUsersModalProps = {
    cluster: CcCluster;
    node: CcProxySqlNode;
    onSuccess?: () => void;
    onCancel?: () => void;
    onError?: (err: Error) => void;
    hostGroupList: { value: string; label: string }[] | undefined;
    userData?: CcProxySqlUser;
    edit?: boolean;
    title: string;
};
export type ProxySQLUsersFormValues = {
    userName: string;
    password: string;
    sqlPrivileges: string;
    hostGroup: string;
    schema: string;
    dbName: string;
    maxConnections: string;
    active: boolean;
    schemaLocked: boolean;
    useSSL: boolean;
    transaction: boolean;
    fastForward: boolean;
    backendReadOnly: boolean;
    frontendReadOnly: boolean;
};

function ProxySQLUsersModal({
    onSuccess,
    onCancel,
    onError,
    hostGroupList,
    cluster,
    node,
    edit,
    userData,
    title,
}: ProxySQLUsersModalProps) {
    const [form] = Form.useForm<ProxySQLUsersFormValues>();
    const [loading, setLoading] = useState<boolean>(false);

    const handleSubmit = async (values: ProxySQLUsersFormValues) => {
        try {
            setLoading(true);
            if (edit) {
                await CmonProxysqlService.updatemysqluser({
                    clusterid: cluster?.clusterId,
                    hostName: node?.hostname,
                    port: node?.port,
                    mysqlUser: prepareUserDataEndPoint(values),
                });
            } else {
                await CmonProxysqlService.insertmysqluser({
                    clusterid: cluster?.clusterId,
                    hostName: node?.hostname,
                    port: node?.port,
                    mysqlUser: prepareUserDataEndPoint(values),
                });
            }
            notifyOperationSuccess({
                type: NotifyType.TOAST,
                title: <span>Done</span>,
            });
            setLoading(false);
            onSuccess?.();
        } catch (error: any) {
            setLoading(false);
            notifyError({
                content: error.message,
            });
            onError?.(error);
        }
    };

    return (
        <ModalDefaultForm
            title={title}
            form={form}
            footer={[]}
            onCancel={onCancel}
            width={900}
            defaultVisible={true}
        >
            <Form
                className="ProxySQLUsersModal"
                form={form}
                layout="vertical"
                onFinish={handleSubmit}
                initialValues={
                    edit && userData
                        ? prepareEditUserInitialValue(userData)
                        : {
                              dbName: '*.*',
                              maxConnections: 10000,
                              active: true,
                              backendReadOnly: true,
                              frontendReadOnly: true,
                          }
                }
            >
                <Row gutter={[24, 0]}>
                    <Col span={12}>
                        <Form.Item
                            name={'userName'}
                            label={<Space>Username</Space>}
                            rules={[
                                {
                                    required: true,
                                    message: 'Enter username.',
                                },
                            ]}
                        >
                            <Input
                                disabled={edit}
                                placeholder="Click here to enter username."
                            ></Input>
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item
                            name={'password'}
                            label={<Space>Password</Space>}
                            rules={[
                                {
                                    required: true,
                                    message: 'Enter password number.',
                                },
                            ]}
                        >
                            <Input.Password placeholder="Enter Password." />
                        </Form.Item>
                    </Col>
                    {!edit && (
                        <Col span={12}>
                            <Form.Item
                                name={'sqlPrivileges'}
                                label={<Space>MySQL privilege(s)</Space>}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Select Privileges',
                                    },
                                ]}
                            >
                                <AppSelect
                                    mode="multiple"
                                    allowClear
                                    placeholder="Please Select Privilege(s)."
                                    options={privilegeOptions}
                                    placement="topLeft"
                                />
                            </Form.Item>
                        </Col>
                    )}
                    <Col span={12}>
                        <Form.Item
                            name={'hostGroup'}
                            label={<Space>Default hostgroup</Space>}
                            rules={[
                                {
                                    required: true,
                                    message: 'Enter Default hostgroup.',
                                },
                            ]}
                        >
                            <AppSelect
                                placeholder={'Enter default hostgroup.'}
                                options={hostGroupList}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name={'schema'}
                            label={<Space>Default schema</Space>}
                        >
                            <Input placeholder="Click here to enter default schema."></Input>
                        </Form.Item>
                    </Col>
                    {!edit && (
                        <Col span={12}>
                            <Form.Item
                                name={'dbName'}
                                label={<Space>DB Name</Space>}
                                rules={[
                                    {
                                        required: true,
                                        validator: dbNameValidator,
                                    },
                                ]}
                            >
                                <Input placeholder="enter DB Name."></Input>
                            </Form.Item>
                        </Col>
                    )}
                    <Col span={12}>
                        <Form.Item
                            name={'maxConnections'}
                            label={<Space>Max connection</Space>}
                            rules={[
                                {
                                    required: true,
                                    message: 'Enter max connections.',
                                },
                            ]}
                        >
                            <InputNumberWide
                                min={0}
                                placeholder="Click here to enter max connections."
                            ></InputNumberWide>
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={[24, 0]}>
                    <Col span={12}>
                        <FormItemInlineSwitch
                            justify={true}
                            label="Active"
                            name={'active'}
                            labelStrong={false}
                            valuePropName="checked"
                        />
                    </Col>
                    <Col span={12}>
                        <FormItemInlineSwitch
                            justify={true}
                            label="Schema locked"
                            name={'schemaLocked'}
                            labelStrong={false}
                            valuePropName="checked"
                        />
                    </Col>
                    <Col span={12}>
                        <FormItemInlineSwitch
                            justify={true}
                            label="Use SSL"
                            name={'useSSL'}
                            labelStrong={false}
                            valuePropName="checked"
                        />
                    </Col>
                    <Col span={12}>
                        <FormItemInlineSwitch
                            justify={true}
                            label="Transaction persistent"
                            name={'transaction'}
                            labelStrong={false}
                            valuePropName="checked"
                        />
                    </Col>
                    <Col span={12}>
                        <FormItemInlineSwitch
                            justify={true}
                            label="Fast forward"
                            name={'fastForward'}
                            labelStrong={false}
                            valuePropName="checked"
                        />
                    </Col>
                    <Col span={12}>
                        <FormItemInlineSwitch
                            justify={true}
                            label="Backend (read only)"
                            name={'backendReadOnly'}
                            labelStrong={false}
                            valuePropName="checked"
                            disabled
                        />
                    </Col>
                    <Col span={12}>
                        <FormItemInlineSwitch
                            justify={true}
                            label="Frontend (read only)"
                            name={'frontendReadOnly'}
                            labelStrong={false}
                            valuePropName="checked"
                            disabled
                        />
                    </Col>
                </Row>

                <FormFooter
                    loading={loading}
                    showCancelButton
                    onCancel={onCancel}
                    submitButtonText="Add"
                    showSubmitButton
                />
            </Form>
        </ModalDefaultForm>
    );
}
