import './LicenseStateFormat.less';
import React from 'react';
import CcLicense, {
    CcLicenseState,
    CcLicenseType,
} from '../../services/models/CcLicense';
import { Alert } from 'antd';
import { StatusFormatStatus } from '@severalnines/bar-frontend-components/build/lib/Format/StatusFormat';
import classNames from 'classnames';
import AppDateFormat from '../../common/AppDateFormat';
import AppTooltip from '../../common/Feedback/AppTooltip';

export default LicenseStateFormat;
export type LicenseStateFormatProps = {
    state: CcLicenseState;
    license: CcLicense;
    size?: 'large' | 'small';
};

function LicenseStateFormat({
    state,
    license,
    size = 'small',
    ...rest
}: LicenseStateFormatProps) {
    const alert = (
        <Alert
            message={getLicenseStatusName(state, license)}
            type={getLicenseStatusStatus(state)}
            description={
                size === 'large' && getLicenseStatusText(state, license)
            }
            showIcon={true}
            className={classNames(
                'LicenseStateFormat',
                `LicenseStateFormat--size-${size}`
            )}
        />
    );
    if (size === 'small') {
        return (
            <AppTooltip title={getLicenseStatusText(state, license)}>
                {alert}
            </AppTooltip>
        );
    }
    return alert;
}

export function getLicenseFeatureLimitedState(license: CcLicense) {
    if (license.state?.includes(CcLicenseState.EXPIRED)) {
        return CcLicenseState.EXPIRED;
    }
    if (license.type === CcLicenseType.Community) {
        return CcLicenseState.COMMUNITY;
    }
    return undefined;
}

export function getLicenseStatusStatus(status: CcLicenseState) {
    switch (status) {
        case CcLicenseState.TRIAL:
            return StatusFormatStatus.info;
        case CcLicenseState.COMMUNITY:
            return StatusFormatStatus.info;
        case CcLicenseState.NODE_LIMIT_CLOSE:
            return StatusFormatStatus.warning;
        case CcLicenseState.EXPIRE_SOON:
            return StatusFormatStatus.warning;
        case CcLicenseState.NODE_LIMIT_EXCEED:
            return StatusFormatStatus.error;
        case CcLicenseState.EXPIRED:
            return StatusFormatStatus.error;
    }
}
export function getLicenseStatusName(
    status: CcLicenseState,
    license: CcLicense
) {
    switch (status) {
        case CcLicenseState.TRIAL:
            return `${license.daysLeft} days Enterprise trial`;
        case CcLicenseState.COMMUNITY:
            return 'Community edition';
        case CcLicenseState.NODE_LIMIT_CLOSE:
            return 'Nodes limit will be reached soon';
        case CcLicenseState.EXPIRE_SOON:
            return 'License expiring soon';
        case CcLicenseState.NODE_LIMIT_EXCEED:
            return 'Nodes limit is reached';
        case CcLicenseState.EXPIRED:
            return 'License has expired';
    }
}

export function getLicenseStatusText(
    status: CcLicenseState,
    license?: CcLicense
) {
    switch (status) {
        case CcLicenseState.TRIAL:
            return (
                <span>
                    The ClusterControl Enterprise trial provides you with free
                    access to our full suite of features for {license?.daysLeft}{' '}
                    days. It also grants you access to our support team who can
                    help make sure you have the best experience as possible
                    during this trial period.
                </span>
            );
        case CcLicenseState.COMMUNITY:
            return (
                <span>
                    The ClusterControl Community Edition is free to use.
                    Limitations on some features will apply. To extend your
                    license and to unlock the full potential of Cluster Control,
                    please contact our sales team.
                </span>
            );
        case CcLicenseState.NODE_LIMIT_CLOSE:
            return (
                <span>
                    You have used {license?.usedNodes}/{license?.licensedNodes}{' '}
                    nodes and will reach your nodes limit soon. Once you reach
                    the maximum amount of nodes, you will not be allowed to
                    create new nodes. Contact the license owner for more
                    information.
                </span>
            );
        case CcLicenseState.EXPIRE_SOON:
            return (
                <span>
                    Your license is going to expire soon. If you not renew your
                    license before{' '}
                    <AppDateFormat includeTimezone={false}>
                        {license?.expirationDate}
                    </AppDateFormat>
                    , some features of the product may not be accessible after
                    that time. Contact the license owner for more information.
                </span>
            );
        case CcLicenseState.NODE_LIMIT_EXCEED:
            return (
                <span>
                    Your have reached your nodes limit. To create new nodes,
                    delete some of your existing ones or contact your license
                    owner for more information.
                </span>
            );
        case CcLicenseState.EXPIRED:
            return (
                <span>
                    Your license has expired. Some features of the product may
                    not be accessible. Contact the license owner for more
                    information.
                </span>
            );
    }
}
