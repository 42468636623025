
import CmonMetaClass, { CmonMetaClassProps } from './CmonMetaClass';


export interface CmonJobProps  extends CmonMetaClassProps {

}



export default class CmonJob extends CmonMetaClass {


    constructor(props: CmonJobProps) {
        super(props);

    }

}
