import React from 'react';
import AppPageWrapper from '../common/Layout/AppPageWrapper';
import ReportsPage from '../components/Report/ReportsPage';
import CcCluster, { CcClusterType } from '../services/models/CcCluster';

export default DebugNodeList;
export type DebugNodeListProps = {};

function DebugNodeList({ ...rest }: DebugNodeListProps) {
    return (
        <AppPageWrapper>
            <ReportsPage
                cluster={
                    new CcCluster({
                        cluster_id: 1,
                        cluster_type: CcClusterType.TYPE_REPLICATION,
                    })
                }
            />
        </AppPageWrapper>
    );
}
