import React from 'react';
import { AclPermissionItem, getAclItemDescription } from './useTree';
import FormItemInlineSwitch from '../../common/DataEntry/FormItemInlineSwitch';
import { CcTreeItemHandledAclPath } from '../../services/models/CcTreeItem';
import { InternalNamePath } from 'rc-field-form/lib/interface';
import AppRow from '../../common/AppRow';
import { Col } from 'antd';

export default PermissionsForm;

export type PermissionsFormProps = {
    permissions: AclPermissionItem[];
    fieldNamespace?: InternalNamePath;
};

function PermissionsForm({
    permissions,
    fieldNamespace = ['permissions'],
}: PermissionsFormProps) {
    return (
        <AppRow className="PermissionsForm" gutter={[24, 0]}>
            {permissions
                .filter((i) => !i.disabled)
                .map((i) => (
                    <Col key={i.key} xs={24} sm={24} md={12}>
                        <FormItemInlineSwitch
                            name={[...fieldNamespace, i.key]}
                            justify={true}
                            disabled={
                                i.key ===
                                CcTreeItemHandledAclPath.ACL_USER_MANAGER
                            }
                            label={
                                <span>
                                    {getAclItemDescription(
                                        i.key as CcTreeItemHandledAclPath
                                    )}
                                </span>
                            }
                        />
                    </Col>
                ))}
        </AppRow>
    );
}
