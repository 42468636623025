import './SendMailForm.less';
import React, { useEffect, useMemo, useState } from 'react';
import { Col, Form, Input, Row, Space } from 'antd';
import InfoIcon from '@severalnines/bar-frontend-components/build/lib/General/InfoIcon';
import FormFooter from '../../../common/FormFooter';
import SendTestEmailButton from './SendTestEmailButton';
import useClusterList from '../../Clusters/useClusterList';
import CmonClustersService from '../../../services/cmon/CmonClustersService';
import {
    notifyError,
    notifyOperationSuccess,
    NotifyType,
} from '../../Notifications/uiNotification';
import { useDebugContext } from '../../../common/Debug';
import AppSpin from '../../../common/General/AppSpin';
import CcCluster from '../../../services/models/CcCluster';
import ContentWrapper from '../../../common/Layout/ContentWrapper';
import SpaceWide from '../../../common/SpaceWide';
import TypographyText from '../../../common/TypographyText';

export default SendMailForm;

export const regex = /^(([^<>()[\].,;:\s@"]+(.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+.)+[^<>()[\].,;:\s@"]{2,})$/i;

export type SendMailFormProps = {
    onCancel?: () => void;
    onSuccess?: () => void;
};

function SendMailForm({ onCancel, onSuccess }: SendMailFormProps) {
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm<any>();
    const { list: clusters, map: clustersMap } = useClusterList({
        fromStore: true,
    });
    const { log } = useDebugContext();

    const clusterIds = useMemo(
        () =>
            clusters
                ?.map((cluster) => cluster.clusterId)
                .sort()
                .join(','),
        [clusters]
    );

    useEffect(() => {
        if (clusterIds) {
            (async () => {
                setLoading(true);
                try {
                    const responses = (
                        await Promise.all(
                            clusterIds.split(',').map(async (clusterId) => {
                                // if(clusterId == '3') {
                                //     await new Promise(resolve => setTimeout(resolve, 1000));
                                // }
                                if (!clusterId) {
                                    return undefined;
                                }
                                const response = await CmonClustersService.getConfig(
                                    {
                                        cluster_id: +clusterId,
                                    }
                                );
                                return {
                                    clusterId: clusterId,
                                    response,
                                };
                            })
                        )
                    ).filter(Boolean);
                    form.setFieldsValue({
                        clusters: responses.map(
                            (data?: { clusterId: string; response: any }) => {
                                const { clusterId, response } = data || {};
                                return {
                                    emailAddress: response?.configuration.values.find(
                                        (value: any) =>
                                            value.name === 'cmon_mail_sender'
                                    ).current_value,
                                    clusterId,
                                };
                            }
                        ),
                    });
                } catch (err) {
                    notifyError({ content: err.message });
                    log.error(err);
                } finally {
                    setLoading(false);
                }
            })();
        }
    }, [clusterIds]);

    const handleSubmit = async () => {
        try {
            if (clusters) {
                const clustersFields = form.getFieldValue('clusters');
                setLoading(true);
                await Promise.all(
                    clusters.map(async (cluster, index) => {
                        const value = clustersFields?.find(
                            (field: any) =>
                                `${field.clusterId}` === `${cluster.clusterId}`
                        )?.emailAddress;

                        await CmonClustersService.setConfig({
                            cluster_id: cluster.clusterId,
                            configuration: [
                                {
                                    name: 'cmon_mail_sender',
                                    value: value,
                                },
                                {
                                    name: 'cmon_use_mail',
                                    value: true,
                                },
                            ] as any,
                        });

                        notifyOperationSuccess({
                            type: NotifyType.TOAST,
                            title: `Email address ${value} associated with cluster ${cluster.clusterName}`,
                        });
                    })
                );
                notifyOperationSuccess({
                    type: NotifyType.TOAST,
                    title: 'Sendmail configured saved successfully!',
                });
                onSuccess?.();
            }
        } catch (err) {
            notifyError({ content: err.message });
            log.error(err);
        } finally {
            setLoading(false);
        }
    };

    return (
        <ContentWrapper>
            <AppSpin spinning={loading}>
                <Form
                    form={form}
                    layout="vertical"
                    autoComplete="off"
                    onFinish={handleSubmit}
                >
                    <Row gutter={[24, 0]}>
                        <Form.List name={'clusters'}>
                            {(fields) => {
                                return fields.map((field) => {
                                    const clusterId = form.getFieldValue([
                                        'clusters',
                                        field.name,
                                        'clusterId',
                                    ]);
                                    return (
                                        <Col
                                            key={field?.key}
                                            xs={24}
                                            sm={24}
                                            md={12}
                                        >
                                            <Form.Item
                                                name={[
                                                    field.key,
                                                    'emailAddress',
                                                ]}
                                                rules={[
                                                    {
                                                        type: 'email',
                                                        message:
                                                            'Invalid email address',
                                                    },
                                                ]}
                                                label={
                                                    <Space>
                                                        Reply to/from:
                                                        {
                                                            (clustersMap?.get(
                                                                clusterId
                                                            ) as CcCluster)
                                                                ?.clusterName
                                                        }
                                                        <InfoIcon
                                                            info={
                                                                <span>
                                                                    Specify the
                                                                    sender of
                                                                    the email.
                                                                    This will
                                                                    appear in
                                                                    the 'From'
                                                                    field of the
                                                                    mail header.
                                                                </span>
                                                            }
                                                        />
                                                    </Space>
                                                }
                                            >
                                                <Input type="email" />
                                            </Form.Item>
                                        </Col>
                                    );
                                });
                            }}
                        </Form.List>
                    </Row>
                    <Form.Item shouldUpdate={true}>
                        {() => {
                            let recipients = form.getFieldValue('clusters');
                            recipients = recipients?.filter(
                                (item: any) => !!item?.emailAddress
                            );
                            recipients = recipients?.[0] ? [recipients[0]] : [];

                            const disabled = recipients.length < 1;

                            return (
                                <SpaceWide
                                    direction="vertical"
                                    className="SendMailForm_TestEmailsWrapper"
                                    align="center"
                                >
                                    <SendTestEmailButton
                                        disabled={disabled}
                                        recipients={recipients}
                                    />
                                    <Space direction="vertical" size={0}>
                                        <TypographyText muted={true}>
                                            Make sure the local MTA is installed
                                            and verified using the test email
                                            button.
                                        </TypographyText>
                                        <TypographyText muted={true}>
                                            {recipients.length < 1 ? (
                                                <span>
                                                    Test email will be sent to
                                                    first provided email above.
                                                </span>
                                            ) : (
                                                <span>
                                                    Test email will be sent to{' '}
                                                    <TypographyText
                                                        strong={true}
                                                    >
                                                        {
                                                            recipients[0]
                                                                ?.emailAddress
                                                        }
                                                    </TypographyText>
                                                    .
                                                </span>
                                            )}
                                        </TypographyText>
                                    </Space>
                                </SpaceWide>
                            );
                        }}
                    </Form.Item>
                    <FormFooter
                        submitButtonText="Save"
                        showCancelButton
                        showSubmitButton
                        onCancel={onCancel}
                    />
                </Form>
            </AppSpin>
        </ContentWrapper>
    );
}
