
import CcNode, { CcNodeProps } from '../../models/CcNode';


export interface CmonMaxScaleHostProps  extends CcNodeProps {
    admin_password?: string;
    admin_user?: string;
    cli_port?: string;
    debug_port?: string;
    monitor_interval?: string;
    monitor_password?: string;
    monitor_user?: string;
    rr_port?: string;
    rw_port?: string;
    server_info?: string;

}



export default class CmonMaxScaleHost extends CcNode {
    public adminPassword?: string;
    public adminUser?: string;
    public cliPort?: string;
    public debugPort?: string;
    public monitorInterval?: string;
    public monitorPassword?: string;
    public monitorUser?: string;
    public rrPort?: string;
    public rwPort?: string;
    public serverInfo?: string;


    constructor(props: CmonMaxScaleHostProps) {
        super(props);
        this.adminPassword = props.admin_password;
        this.adminUser = props.admin_user;
        this.cliPort = props.cli_port;
        this.debugPort = props.debug_port;
        this.monitorInterval = props.monitor_interval;
        this.monitorPassword = props.monitor_password;
        this.monitorUser = props.monitor_user;
        this.rrPort = props.rr_port;
        this.rwPort = props.rw_port;
        this.serverInfo = props.server_info;

    }

}
