import CcCluster from '../../../services/models/CcCluster';
import ActionsMenu, {
    ActionsMenuProps,
} from '../../../common/Navigation/ActionsMenu';
import React from 'react';
import ActionButton from '@severalnines/bar-frontend-components/build/lib/General/ActionButton';
import ButtonWithForm from '../../../common/General/ButtonWithForm';
import AdvisorInfoModal from './AdvisorInfoModal';
import AdvisorEditScriptModal from './AdvisorEditScriptModal';
import AdvisorEditParamsModal from './AdvisorEditParamsModal';
import CcAdvisorSchedule from '../../../services/models/CcAdvisorSchedule';
import AdvisorActivityToggleActionButton from './AdvisorActivityToggleActionButton';

export default AdvisorActionsMenu;
type AdvisorActionsMenuProps = ActionsMenuProps & {
    cluster: CcCluster;
    advisorSchedule?: CcAdvisorSchedule;
};

function AdvisorActionsMenu({
    cluster,
    advisorSchedule,
    onActionPerformed,
    ...rest
}: AdvisorActionsMenuProps) {
    const items = [
        {
            key: 'results',
            label: (
                <ButtonWithForm
                    button={<ActionButton>Results</ActionButton>}
                    form={
                        <AdvisorInfoModal advisorSchedule={advisorSchedule} />
                    }
                />
            ),
        },
        {
            key: 'Edit script',
            label: (
                <ButtonWithForm
                    button={<ActionButton>Edit script</ActionButton>}
                    form={
                        advisorSchedule && (
                            <AdvisorEditScriptModal
                                cluster={cluster}
                                advisorSchedule={advisorSchedule}
                                onUpdate={onActionPerformed}
                            />
                        )
                    }
                />
            ),
        },
        {
            key: 'Edit params',
            label: (
                <ButtonWithForm
                    button={<ActionButton>Edit params</ActionButton>}
                    form={
                        advisorSchedule && (
                            <AdvisorEditParamsModal
                                cluster={cluster}
                                advisorSchedule={advisorSchedule}
                                onSuccess={onActionPerformed}
                            />
                        )
                    }
                />
            ),
        },
        {
            key: 'enable',
            label: (
                <AdvisorActivityToggleActionButton
                    cluster={cluster}
                    advisorSchedule={advisorSchedule}
                    onSuccess={onActionPerformed}
                />
            ),
        },
    ];
    return <ActionsMenu items={items} {...rest} />;
}
