import React from 'react';
import { Space } from 'antd';
import { formatNumber } from '@severalnines/bar-frontend-components/build/lib/Format/NumberFormat';
import { formatPercentage } from '@severalnines/bar-frontend-components/build/lib/Format/PercentageFormat';
import { formatBytes } from '@severalnines/bar-frontend-components/build/lib/Format/ByteFormat';
import { formatDuration } from '@severalnines/bar-frontend-components/build/lib/Format/DurationFormat';

export default MonitorValueFormat;

export type MonitorValueFormatProps = {
    value: number;
    formatType?: MonitorValueFormatType;
    decimals?: number;
    postfix?: string;
};

function MonitorValueFormat({
    value,
    formatType,
    decimals,
    postfix,
    ...rest
}: MonitorValueFormatProps) {
    return (
        <Space className="MonitorValueFormat">
            {formatMonitorValue(value, formatType, decimals, postfix, rest)}
        </Space>
    );
}

export enum MonitorValueFormatType {
    NUMBER = 'number',
    PERCENTAGE = 'percentage',
    PERCENT = 'percent',
    SIZE = 'size',
    SIZEPS = 'sizeps',
    DURATION = 'duration',
    NONE = 'none',
}

/**
 * Returns formatted value in different types
 */
export function formatMonitorValue(
    value: number,
    formatType?: MonitorValueFormatType,
    decimals?: number,
    postfix?: string,
    extra?: any
): string {
    let formatted;
    const deci = decimals ?? 2;
    switch (formatType) {
        case MonitorValueFormatType.PERCENTAGE:
            formatted = formatPercentage(value, deci);
            break;
        case MonitorValueFormatType.PERCENT:
            formatted =
                value === undefined || Number.isNaN(value)
                    ? '-'
                    : `${formatNumber(value, deci)} %`;
            break;
        case MonitorValueFormatType.SIZE:
            formatted = formatBytes(value, deci);
            break;
        case MonitorValueFormatType.SIZEPS:
            formatted =
                value === undefined || Number.isNaN(value)
                    ? '-'
                    : `${formatBytes(value, deci)}/s`;
            break;
        case MonitorValueFormatType.DURATION:
            formatted = formatDuration(value, {
                precision: decimals ?? 3,
                short: true,
                noSpace: true,
                seconds: true,
                ...extra,
            });
            break;
        case MonitorValueFormatType.NONE:
            formatted = value !== undefined ? `${value}` : '-';
            break;
        case MonitorValueFormatType.NUMBER:
        default:
            formatted = formatNumber(value, deci);
    }
    return postfix ? `${formatted}${postfix}` : formatted;
}
