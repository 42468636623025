import React from 'react';
import ConfirmActionButton, {
    ConfirmActionButtonProps,
} from '@severalnines/bar-frontend-components/build/lib/General/ConfirmActionButton';
import './AppConfirmActionButton.less';

export default AppConfirmActionButton;

export type AppConfirmActionButtonProps = ConfirmActionButtonProps & {};

function AppConfirmActionButton({ ...rest }: AppConfirmActionButtonProps) {
    return <ConfirmActionButton className="AppConfirmActionButton" {...rest} />;
}
