


export interface CmonContainerProps  {
    acl?: string;
    alias?: string;
    architecture?: string;
    autostart?: boolean;
    block_io_weight?: number;
    cdt_path?: string;
    cluster_id?: number;
    configfile?: string;
    effective_privileges?: string;
    firewalls?: string;
    hostname?: string;
    image?: string;
    image_os_user?: string;
    is_claimed?: boolean;
    memory_limit?: number;
    network?: string;
    os_version?: string;
    owner_group_id?: number;
    owner_user_id?: number;
    parent_server?: string;
    provider?: string;
    region?: string;
    root_fs_path?: string;
    status?: string;
    subnet?: string;
    tags?: string;
    template?: string;
    type?: string;
    use_private_network?: boolean;
    version?: number;
    volumes?: string;

}



export default class CmonContainer {
    public acl?: string;
    public alias?: string;
    public architecture?: string;
    public autostart?: boolean;
    public blockIoWeight?: number;
    public cdtPath?: string;
    public clusterId?: number;
    public configfile?: string;
    public effectivePrivileges?: string;
    public firewalls?: string;
    public hostname?: string;
    public image?: string;
    public imageOsUser?: string;
    public isClaimed?: boolean;
    public memoryLimit?: number;
    public network?: string;
    public osVersion?: string;
    public ownerGroupId?: number;
    public ownerUserId?: number;
    public parentServer?: string;
    public provider?: string;
    public region?: string;
    public rootFsPath?: string;
    public status?: string;
    public subnet?: string;
    public tags?: string;
    public template?: string;
    public type?: string;
    public usePrivateNetwork?: boolean;
    public version?: number;
    public volumes?: string;


    constructor(props: CmonContainerProps) {
        this.acl = props.acl;
        this.alias = props.alias;
        this.architecture = props.architecture;
        this.autostart = props.autostart;
        this.blockIoWeight = props.block_io_weight;
        this.cdtPath = props.cdt_path;
        this.clusterId = props.cluster_id;
        this.configfile = props.configfile;
        this.effectivePrivileges = props.effective_privileges;
        this.firewalls = props.firewalls;
        this.hostname = props.hostname;
        this.image = props.image;
        this.imageOsUser = props.image_os_user;
        this.isClaimed = props.is_claimed;
        this.memoryLimit = props.memory_limit;
        this.network = props.network;
        this.osVersion = props.os_version;
        this.ownerGroupId = props.owner_group_id;
        this.ownerUserId = props.owner_user_id;
        this.parentServer = props.parent_server;
        this.provider = props.provider;
        this.region = props.region;
        this.rootFsPath = props.root_fs_path;
        this.status = props.status;
        this.subnet = props.subnet;
        this.tags = props.tags;
        this.template = props.template;
        this.type = props.type;
        this.usePrivateNetwork = props.use_private_network;
        this.version = props.version;
        this.volumes = props.volumes;

    }

}
