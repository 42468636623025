const dashboard = {
    title: 'ProxySQL Overview',
    meta: {
        slug: 'proxysql-overview',
        supportClusterTypes: ['replication', 'galera', 'mysql_single'],
    },
    panels: [
        {
            title: 'Questions',
            type: 'chart',
            gridPos: {
                h: 8,
                w: 12,
                x: 0,
                y: 0,
            },
            options: {
                decimals: 0,
                type: 'area',
                legend: {
                    alignAsTable: true,
                },
                yaxis: [
                    {
                        format: 'number',
                        decimals: 0,
                        max: null,
                        min: 0,
                    },
                ],
                stack: false,
            },
            targets: [
                {
                    expr:
                        'irate(proxysql_mysql_status_questions{instance=~"$instance"}[1m])',
                    legendFormat: 'questions',
                },
            ],
        },
        {
            title: 'Slow queries',
            type: 'chart',
            gridPos: {
                h: 8,
                w: 12,
                x: 0,
                y: 0,
            },
            options: {
                decimals: 0,
                type: 'area',
                legend: {
                    alignAsTable: true,
                },
                yaxis: [
                    {
                        format: 'number',
                        max: null,
                        min: 0,
                    },
                ],
                stack: false,
            },
            targets: [
                {
                    expr:
                        'irate(proxysql_mysql_status_slow_queries{instance=~"$instance"}[1m])',
                    legendFormat: 'slow_queries',
                },
            ],
        },
        {
            title: 'Queries / hostgroup distribution',
            type: 'chart',
            gridPos: {
                h: 8,
                w: 12,
                x: 0,
                y: 0,
            },
            options: {
                decimals: 0,
                type: 'area',
                legend: {
                    alignAsTable: true,
                },
                yaxis: [
                    {
                        format: 'number',
                        decimals: 0,
                        max: null,
                        min: 0,
                    },
                ],
                stack: false,
            },
            targets: [
                {
                    expr:
                        'sum(irate(proxysql_connection_pool_queries{instance=~"$instance"}[1m])) by (hostgroup)',
                    legendFormat: 'queries (hostgroup {{ hostgroup }})',
                },
            ],
        },
        {
            title: 'Latency / hostgroup distribution',
            type: 'chart',
            gridPos: {
                h: 8,
                w: 12,
                x: 0,
                y: 0,
            },
            options: {
                decimals: 0,
                type: 'area',
                legend: {
                    alignAsTable: true,
                },
                yaxis: [
                    {
                        format: 'number',
                        decimals: 0,
                        max: null,
                        min: 0,
                    },
                ],
                stack: false,
            },
            targets: [
                {
                    expr:
                        'sum(proxysql_connection_pool_latency_us{instance=~"$instance"}) by (hostgroup)',
                    legendFormat: 'latency_us (hostgroup {{ hostgroup }})',
                },
            ],
        },
        {
            title: 'Data sent / hostgroup distribution',
            type: 'chart',
            gridPos: {
                h: 8,
                w: 12,
                x: 0,
                y: 0,
            },
            options: {
                decimals: 0,
                type: 'area',
                legend: {
                    alignAsTable: true,
                },
                yaxis: [
                    {
                        format: 'sizeps',
                        decimals: 0,
                        max: null,
                        min: 0,
                    },
                ],
                stack: false,
            },
            targets: [
                {
                    expr:
                        'sum(irate(proxysql_connection_pool_bytes_data_sent{instance=~"$instance"}[1m])) by (hostgroup)',
                    legendFormat: 'sent (hostgroup {{ hostgroup }})',
                },
            ],
        },
        {
            title: 'Data received / hostgroup distribution',
            type: 'chart',
            gridPos: {
                h: 8,
                w: 12,
                x: 0,
                y: 0,
            },
            options: {
                decimals: 0,
                type: 'area',
                legend: {
                    alignAsTable: true,
                },
                yaxis: [
                    {
                        format: 'number',
                        decimals: 0,
                        max: null,
                        min: 0,
                    },
                ],
                stack: false,
            },
            targets: [
                {
                    expr:
                        'sum(irate(proxysql_connection_pool_bytes_data_recv{instance=~"$instance"}[1m])) by (hostgroup)',
                    legendFormat: 'recv (hostgroup {{ hostgroup }})',
                },
            ],
        },
        {
            title: 'Active transactions',
            type: 'chart',
            gridPos: {
                h: 8,
                w: 12,
                x: 0,
                y: 0,
            },
            options: {
                decimals: 0,
                type: 'area',
                legend: {
                    alignAsTable: true,
                },
                yaxis: [
                    {
                        format: 'number',
                        max: null,
                        min: 0,
                    },
                ],
                stack: false,
            },
            targets: [
                {
                    expr:
                        'proxysql_mysql_status_active_transactions{instance=~"$instance"}',
                    legendFormat: 'active_transactions',
                },
            ],
        },
        {
            title: 'Queries backend',
            type: 'chart',
            gridPos: {
                h: 8,
                w: 12,
                x: 0,
                y: 0,
            },
            options: {
                decimals: 0,
                type: 'area',
                legend: {
                    alignAsTable: true,
                },
                yaxis: [
                    {
                        format: 'sizeps',
                        max: null,
                        min: 0,
                    },
                ],
                stack: false,
            },
            targets: [
                {
                    expr:
                        'irate(proxysql_mysql_status_queries_backends_bytes_sent{instance=~"$instance"}[1m])',
                    legendFormat: 'queries_backends_bytes_sent',
                },
                {
                    expr:
                        'irate(proxysql_mysql_status_queries_backends_bytes_recv{instance=~"$instance"}[1m])',
                    legendFormat: 'queries_backends_bytes_recv',
                },
            ],
        },
        {
            title: 'Client connections',
            type: 'chart',
            gridPos: {
                h: 8,
                w: 12,
                x: 0,
                y: 0,
            },
            options: {
                decimals: 0,
                type: 'area',
                legend: {
                    alignAsTable: true,
                },
                yaxis: [
                    {
                        format: 'number',
                        max: null,
                        min: 0,
                    },
                ],
                stack: false,
            },
            targets: [
                {
                    expr:
                        'irate(proxysql_mysql_status_client_connections_aborted{instance=~"$instance"}[1m])',
                    legendFormat: 'client_connections_aborted',
                },
                {
                    expr:
                        'proxysql_mysql_status_client_connections_connected{instance=~"$instance"}',
                    legendFormat: 'client_connections_connected',
                },
                {
                    expr:
                        'proxysql_mysql_status_client_connections_created{instance=~"$instance"}',
                    legendFormat: 'client_connections_created',
                },
                {
                    expr:
                        'proxysql_mysql_status_client_connections_non_idle{instance=~"$instance"}',
                    legendFormat: 'client_connections_non_idle',
                },
            ],
        },
        {
            title: 'Server connections',
            type: 'chart',
            gridPos: {
                h: 8,
                w: 12,
                x: 0,
                y: 0,
            },
            options: {
                decimals: 0,
                type: 'area',
                legend: {
                    alignAsTable: true,
                },
                yaxis: [
                    {
                        format: 'number',
                        max: null,
                        min: 0,
                    },
                ],
                stack: false,
            },
            targets: [
                {
                    expr:
                        'irate(proxysql_mysql_status_server_connections_aborted{instance=~"$instance"}[1m])',
                    legendFormat: 'server_connections_aborted',
                },
                {
                    expr:
                        'proxysql_mysql_status_server_connections_connected{instance=~"$instance"}',
                    legendFormat: 'server_connections_connected',
                },
                {
                    expr:
                        'proxysql_mysql_status_server_connections_created{instance=~"$instance"}',
                    legendFormat: 'server_connections_created',
                },
                {
                    expr:
                        'proxysql_mysql_status_server_connections_delayed{instance=~"$instance"}',
                    legendFormat: 'server_connections_delayed',
                },
            ],
        },
        {
            title: 'Query cache - memory used by result sets',
            type: 'chart',
            gridPos: {
                h: 8,
                w: 12,
                x: 0,
                y: 0,
            },
            options: {
                decimals: 0,
                type: 'area',
                legend: {
                    alignAsTable: true,
                },
                yaxis: [
                    {
                        format: 'number',
                        max: null,
                        min: 0,
                    },
                ],
                stack: false,
            },
            targets: [
                {
                    expr:
                        'proxysql_mysql_status_query_cache_memory_bytes{instance=~"$instance"}',
                    legendFormat: 'query_cache_memory_bytes',
                },
            ],
        },
        {
            title: 'Query cache - get/set',
            type: 'chart',
            gridPos: {
                h: 8,
                w: 12,
                x: 0,
                y: 0,
            },
            options: {
                decimals: 0,
                type: 'area',
                legend: {
                    alignAsTable: true,
                },
                yaxis: [
                    {
                        format: 'number',
                        max: null,
                        min: 0,
                    },
                ],
                stack: false,
            },
            targets: [
                {
                    expr:
                        'proxysql_mysql_status_query_cache_count_get_ok{instance=~"$instance"}',
                    legendFormat: 'query_cache_count_get_ok',
                },
                {
                    expr:
                        'proxysql_mysql_status_query_cache_count_get{instance=~"$instance"}',
                    legendFormat: 'query_cache_count_get',
                },
                {
                    expr:
                        'proxysql_mysql_status_query_cache_count_set{instance=~"$instance"}',
                    legendFormat: 'query_cache_count_set',
                },
            ],
        },
        {
            title: 'Query cache - in/out bytes',
            type: 'chart',
            gridPos: {
                h: 8,
                w: 12,
                x: 0,
                y: 0,
            },
            options: {
                decimals: 0,
                type: 'area',
                legend: {
                    alignAsTable: true,
                },
                yaxis: [
                    {
                        format: 'number',
                        max: null,
                        min: 0,
                    },
                ],
                stack: false,
            },
            targets: [
                {
                    expr:
                        'proxysql_mysql_status_query_cache_bytes_in{instance=~"$instance"}',
                    legendFormat: 'query_cache_bytes_in',
                },
                {
                    expr:
                        'proxysql_mysql_status_query_cache_bytes_out{instance=~"$instance"}',
                    legendFormat: 'query_cache_bytes_out',
                },
            ],
        },
        {
            title: 'Memory utilization',
            type: 'chart',
            gridPos: {
                h: 8,
                w: 12,
                x: 0,
                y: 0,
            },
            options: {
                decimals: 0,
                type: 'area',
                legend: {
                    alignAsTable: true,
                },
                yaxis: [
                    {
                        format: 'size',
                        max: null,
                        min: 0,
                    },
                ],
                stack: true,
            },
            targets: [
                {
                    expr:
                        'proxysql_mysql_status_sqlite3_memory_bytes{instance=~"$instance"}',
                    legendFormat: 'sqlite3_memory_bytes',
                },
                {
                    expr:
                        'proxysql_mysql_status_connpool_memory_bytes{instance=~"$instance"}',
                    legendFormat: 'connpool_memory_bytes{',
                },
                {
                    expr:
                        'proxysql_mysql_status_mysql_backend_buffers_bytes{instance=~"$instance"}',
                    legendFormat: 'mysql_backend_buffers_bytes',
                },
                {
                    expr:
                        'proxysql_mysql_status_mysql_frontend_buffers_bytes{instance=~"$instance"}',
                    legendFormat: 'mysql_frontend_buffers_bytes',
                },
                {
                    expr:
                        'proxysql_mysql_status_mysql_session_internal_bytes{instance=~"$instance"}',
                    legendFormat: 'mysql_session_internal_bytes',
                },
            ],
        },
    ],
    templating: {
        list: [
            {
                datasource: 'clustercontrol',
                multi: false,
                label: 'Host',
                name: 'instance',
                query: "host:$[nodes[nodetype='proxysql']]",
                optionValue: 'hostname',
                optionText: 'hostname',
                sort: 0,
                isInstancePicker: true,
            },
        ],
    },
    schemaVersion: 1,
};
export default dashboard;
