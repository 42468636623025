import React, { useContext, useEffect, useMemo } from 'react';
import CcCluster from '../../services/models/CcCluster';
import CcNode, { CcNodeType } from '../../services/models/CcNode';
import AppTable from '../../common/DataDisplay/AppTable';
import { ResponsiveContext } from '@severalnines/bar-frontend-components/build/lib/Layout/Responsive';
import NodeStatusFormat, {
    getNodeStatusFormatText,
} from '../Nodes/NodeStatusFormat';
import AppDateFormat from '../../common/AppDateFormat';
import UserAclManageCluster from '../User/UserAclManageCluster';
import CcAgentNode from '../../services/models/CcAgentNode';
import CmonAgentActionsMenu from './CmonAgentActionsMenu';
import SpaceWide from '../../common/SpaceWide';
import CmonAgentsSettingsMenu from './CmonAgentsSettingsMenu';
import useNodeList from '../Nodes/useNodeList';
import { getSortAlphabeticFn, getSortDateFn } from '../../common/sorting';
import { AppConfig } from '../../AppConfig';
import AgentQueryMonitorEnabledWrapper from './AgentQueryMonitorEnabledWrapper';

export default CmonAgentsPage;

export type CmonAgentsPageProps = { cluster: CcCluster };

function CmonAgentsPage({ cluster }: CmonAgentsPageProps) {
    const { responsive } = useContext(ResponsiveContext);
    const {
        list: agents,
        loading: loadingAgents,
        filter: filterAgents,
        refresh: refreshAgents,
        page,
        pageSize,
        total,
    } = useNodeList({
        name: 'agents-list',
        pageSize: AppConfig.defaultPaginationConfig.pageSize,
        useCache: true,
        filters: [
            (node: CcNode) =>
                node.clusterid === cluster.clusterId &&
                node.isType(CcNodeType.CMON_AGENT),
        ],
    });

    useEffect(() => {
        (async () => {
            await refreshAgents();
        })();
    }, []);
    const handleActionPerformed = async () => {
        // await refreshJobs({ useCache: false });
    };
    const columns = useMemo(
        () => [
            {
                title: 'Hostname',
                key: 'hostname',
                render: (record: CcAgentNode) => record.hostname,
                sorter: true,
            },
            {
                title: 'Host status',
                key: 'hostname',
                render: (record: CcAgentNode) => (
                    <NodeStatusFormat node={record} showMaintenance={true} />
                ),
                sorter: true,
            },
            {
                title: 'Last seen',
                key: 'lastseen',
                render: (record: CcAgentNode) => (
                    <AppDateFormat fromNow>
                        {record.lastseen
                            ? new Date(record.lastseen * 1000)
                            : undefined}
                    </AppDateFormat>
                ),
                sorter: true,
                hideWithJob: true,
            },
            {
                key: 'actions',
                title: 'Actions',
                align: 'center',
                width: 20,
                render: (record: CcAgentNode) => {
                    return (
                        cluster && (
                            <UserAclManageCluster cluster={cluster}>
                                <CmonAgentActionsMenu
                                    node={record}
                                    cluster={cluster}
                                    onActionPerformed={handleActionPerformed}
                                />
                            </UserAclManageCluster>
                        )
                    );
                },
                actions: true,
                onCell: () => ({
                    style: {
                        padding: '2px 10px',
                    },
                }),
            },
        ],
        []
    );
    const handleTableChange = (pagination: any, filter: any, sorters: any) => {
        let sorterFn;
        switch (sorters.columnKey) {
            case 'hostname':
                sorterFn = getSortAlphabeticFn(
                    sorters.order,
                    (x) => x.hostname
                );
                break;
            case 'status':
                sorterFn = getSortAlphabeticFn(sorters.order, (x) =>
                    getNodeStatusFormatText(x.hoststatus)
                );
                break;
            case 'lastseen':
                sorterFn = getSortDateFn(
                    sorters.order,
                    (x) => new Date(x.lastseen * 1000)
                );
                break;
        }
        filterAgents({
            page: pagination.current,
            order: sorterFn || undefined,
        });
    };

    return (
        <AgentQueryMonitorEnabledWrapper cluster={cluster}>
            <SpaceWide
                direction="vertical"
                size={24}
                className="QueryMonitorOverviewPage"
            >
                <SpaceWide justify="right">
                    <CmonAgentsSettingsMenu cluster={cluster} />
                </SpaceWide>
                <AppTable
                    size="small"
                    loading={loadingAgents}
                    rowKey={(record: CcNode) => record.getKey()}
                    dataSource={agents}
                    columns={columns}
                    responsive={responsive}
                    onRow={(record: CcNode, index: any) => ({
                        'data-testid': `query-monitor-agents-row-${index}`,
                    })}
                    pagination={{ current: page, pageSize, total }}
                    onChange={handleTableChange}
                />
            </SpaceWide>
        </AgentQueryMonitorEnabledWrapper>
    );
}
