import React, { useEffect, useRef, useState } from 'react';
import './StatusPieDiagram.less';
import { Pie } from '@ant-design/charts';
import StatusFormat, {
    getStatusFormatColor,
    StatusFormatStatus,
} from '@severalnines/bar-frontend-components/build/lib/Format/StatusFormat';
import { PieConfig } from '@ant-design/charts';
import { getSortNumberFn } from './sorting';
import AppSpin from './General/AppSpin';
import SpaceWide from './SpaceWide';

export type StatusPieDiagramItem = {
    name: string;
    value: number;
    type: StatusFormatStatus;
};

export type StatusPieDiagramProps = {
    loading?: boolean;
    pieData: StatusPieDiagramItem[];
    total: number;
    pieSize: number;
    onElementClick?: (data: any) => void;
};

export function getStatusFormatSortIndex(status: StatusFormatStatus): number {
    const index = [
        StatusFormatStatus.success,
        StatusFormatStatus.error,
        StatusFormatStatus.warning,
        StatusFormatStatus.info,
        StatusFormatStatus.default,
        StatusFormatStatus.unknown,
    ].indexOf(status);
    return index < 0 ? status.length : index;
}

export default function StatusPieDiagram({
    loading = false,
    pieData,
    total,
    pieSize,
    onElementClick,
}: StatusPieDiagramProps) {
    const [data, setData] = useState<Record<string, any>[]>([]);
    const pieRef = useRef<any>();
    useEffect(() => {
        setData(
            pieData.sort(
                getSortNumberFn('ascend', ({ type }) =>
                    getStatusFormatSortIndex(type)
                )
            )
        );
    }, [pieData]);
    useEffect(() => {
        if (pieRef.current) {
            const chart = pieRef.current.chart;
            chart.on('element:click', (evt: any) => {
                onElementClick?.(evt.data);
            });
        }
    }, []);

    const getDiagramData = (): Record<string, any>[] =>
        total > 0
            ? data
            : [
                  {
                      name: '',
                      value: 0,
                      type: StatusFormatStatus.unknown,
                  },
              ];

    const config: PieConfig = {
        data: getDiagramData(),
        height: pieSize,
        width: pieSize,
        autoFit: false,
        appendPadding: 10,
        angleField: 'value',
        colorField: 'name',
        color: getDiagramData().map(({ type }) => getStatusFormatColor(type)),
        tooltip: {
            enterable: true,
            customContent: (title) => {
                return `<span style="line-height: 2em">${title}</span>`;
            },
        },
        radius: 1,
        animation: false,
        innerRadius: 0.75,
        label: {
            type: 'inner',
            content: '',
        },
        state: {
            active: {
                style: {
                    lineWidth: 0,
                },
            },
        },
        interactions: [{ type: 'element-highlight' }],
        legend: false,
        statistic: {
            title: false,
            content: {
                style: {
                    fontSize: '2.5em',
                },
                formatter: function formatter() {
                    return total > 0 ? '' : '0/0';
                },
            },
        },
    };

    return (
        <AppSpin spinning={loading} size="small">
            <div className="StatusPieDiagram">
                <div className="StatusPieDiagram_diagram">
                    <div
                        className="StatusPieDiagram_diagram_wrap"
                        style={{
                            width: pieSize,
                            height: pieSize,
                        }}
                    >
                        {config && <Pie {...config} chartRef={pieRef} />}
                    </div>
                    <SpaceWide className="StatusPieDiagram_legend">
                        {data.map(({ name, type }) => (
                            <div
                                key={name}
                                className="StatusPieDiagram_legend_item"
                            >
                                <StatusFormat
                                    status={type}
                                    text={name}
                                    colorText={true}
                                    nowrap={true}
                                />
                            </div>
                        ))}
                    </SpaceWide>
                </div>
            </div>
        </AppSpin>
    );
}
