
import CcNode, { CcNodeProps } from '../../models/CcNode';


export interface CmonNFSClientHostProps  extends CcNodeProps {
    client_dir?: string;
    os_user?: string;
    server_dir?: string;
    server_hostname?: string;
    service_name?: string;

}



export default class CmonNFSClientHost extends CcNode {
    public clientDir?: string;
    public osUser?: string;
    public serverDir?: string;
    public serverHostname?: string;
    public serviceName?: string;


    constructor(props: CmonNFSClientHostProps) {
        super(props);
        this.clientDir = props.client_dir;
        this.osUser = props.os_user;
        this.serverDir = props.server_dir;
        this.serverHostname = props.server_hostname;
        this.serviceName = props.service_name;

    }

}
