import React from 'react';
import { Button, Col, Divider, Form, Row, Space } from 'antd';
import FormItemInlineSwitch from '../../../common/DataEntry/FormItemInlineSwitch';
import FormFooter from '../../../common/FormFooter';
import CcCluster from '../../../services/models/CcCluster';
import ModalDefaultForm from '../../../common/ModalDefaultForm';
import useCreateJob from '../../Jobs/useCreateJob';
import { CmonJobInstanceCommand } from '../../../services/cmon/models/CmonJobInstance';
import InfoIcon from '@severalnines/bar-frontend-components/build/lib/General/InfoIcon';
import ClusterFormat from '../ClusterFormat';
import SpaceWide from '../../../common/SpaceWide';

export default ClusterRollingRestartForm;

export type ClusterRollingRestartFormProps = {
    cluster: CcCluster;
    onSuccess?: () => void;
    onCancel?: () => void;
};
export type ClusterRollingRestartFormValues = {
    performSST?: boolean;
    rebootHosts?: boolean;
};

function ClusterRollingRestartForm({
    cluster,
    onSuccess,
    onCancel,
}: ClusterRollingRestartFormProps) {
    const { loading, send } = useCreateJob({
        clusterId: cluster.clusterId,
        title: 'Rolling restart',
        command: CmonJobInstanceCommand.ROLLING_RESTART,
        onSuccess,
    });
    const [form] = Form.useForm<ClusterRollingRestartFormValues>();

    const handleSubmit = async (values: ClusterRollingRestartFormValues) => {
        await send({ initial: values.performSST, reboot: values.rebootHosts });
    };
    return (
        <ModalDefaultForm
            title="Rolling restart"
            form={form}
            footer={[]}
            onCancel={onCancel}
            defaultVisible={true}
            width={450}
        >
            <Form
                className="ClusterRollingRestartForm"
                form={form}
                layout="vertical"
                onFinish={handleSubmit}
                initialValues={{ performSST: false, rebootHosts: false }}
            >
                <Row gutter={24}>
                    <Col span={24}>
                        The nodes will be restarted one by one in a rolling
                        fashion. The rolling restart will be aborted if a node
                        fails to be restarted.
                    </Col>
                </Row>
                <Divider />
                <Row gutter={24}>
                    <Col span={24}>
                        <SpaceWide direction="vertical">
                            <ClusterFormat
                                cluster={cluster}
                                size="large"
                                wide={true}
                            />
                            <div>
                                <FormItemInlineSwitch
                                    justify
                                    name="performSST"
                                    label={
                                        <Space>
                                            <span>Perform SST</span>
                                            <InfoIcon info="The data directory will be removed from the data node and the node will be completely resynced from another node." />
                                        </Space>
                                    }
                                    valuePropName="checked"
                                />
                                <FormItemInlineSwitch
                                    justify
                                    noMargin
                                    name="rebootHosts"
                                    label={
                                        <Space>
                                            <span>Reboot hosts</span>
                                            <InfoIcon info="The host will be rebooted during the restart." />
                                        </Space>
                                    }
                                    valuePropName="checked"
                                />
                            </div>
                        </SpaceWide>
                    </Col>
                </Row>
                <FormFooter>
                    <Button
                        key="done"
                        type="primary"
                        htmlType="submit"
                        loading={loading}
                    >
                        Restart
                    </Button>
                </FormFooter>
            </Form>
        </ModalDefaultForm>
    );
}
