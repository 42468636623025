import React from 'react';
import { Col, Row } from 'antd';
import FormItemInlineSwitch from '../../../../../common/DataEntry/FormItemInlineSwitch';
import CcCluster, {
    CcClusterType,
} from '../../../../../services/models/CcCluster';

export const haProxyInstallationInitial = {
    haProxyInstallation: {
        overwrite: true,
    },
};

type CreateHAProxyInstallations = {
    selectedCluster?: CcCluster;
};

export default CreateHAProxyInstallation;

function CreateHAProxyInstallation({
    selectedCluster,
}: CreateHAProxyInstallations) {
    return (
        <div style={{ minHeight: 450 }}>
            <Row gutter={[24, 0]}>
                <Col span={24}>
                    <h3>Installation settings</h3>
                </Col>
            </Row>
            <Row gutter={[24, 0]}>
                <Col span={12}>
                    <FormItemInlineSwitch
                        justify={true}
                        name={['haProxyInstallation', 'overwrite']}
                        labelStrong={false}
                        style={{
                            alignItems: 'start',
                        }}
                        label={`Overwrite existing
                        /usr/local/sbin/${
                            selectedCluster?.clusterType ===
                            CcClusterType.TYPE_POSTGRESQL
                                ? 'postgreschk_rw_split on targets'
                                : 'mysqlchk on target'
                        }`}
                        labelCol={{ span: 20 }}
                        valuePropName="checked"
                    />
                </Col>
            </Row>
        </div>
    );
}
