import IMonitorDataProcessor from './IMonitorDataProcessor';
import { MonitorDataPoint } from './MonitorBox';
import jsonata from 'jsonata';

export default class ClustercontrolDataProcessor
    implements IMonitorDataProcessor {
    public static processData(
        data: { [key: string]: any }[][],
        options?: any
    ): MonitorDataPoint[][] {
        const newData = data.map((dataRow: any, dataRowIdx: number) => {
            try {
                const expression = jsonata(options.jsonQueries[dataRowIdx]);
                // for some reason the expression returns one level more of arrays
                // when collection length is more than 1
                return dataRow.length === 0
                    ? []
                    : dataRow.length === 1
                    ? expression.evaluate(dataRow).map((r: any) => [r])
                    : expression.evaluate(dataRow);
            } catch (error) {
                console.error(
                    new Error(
                        `There is an error in jsonata query "${options.jsonQueries[dataRowIdx]}": ${error.message}`
                    )
                );
                return [];
            }
        });

        return newData;
    }
}
