import React from 'react';
import { Switch as AntSwitch, SwitchProps as AntSwitchProps } from 'antd';

export default Switch;

export type SwitchProps = AntSwitchProps & {};

function Switch({
    checkedChildren = 'On',
    unCheckedChildren = 'Off',
    ...rest
}: SwitchProps) {
    return (
        <AntSwitch
            checkedChildren={checkedChildren}
            unCheckedChildren={unCheckedChildren}
            {...rest}
        />
    );
}
