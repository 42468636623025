export type CcDbStatsDbTableProps = {
    count: number;
    data_size: number;
    db_name: string;
    engine: string;
    index_size: number;
    row_count: number;
    table_name: string;
};

export default class CcDbStatsDbTable {
    public count: number;
    public dataSize: number;
    public dbName: string;
    public engine: string;
    public indexSize: number;
    public rowCount: number;
    public tableName: string;
    constructor(props: CcDbStatsDbTableProps) {
        this.count = props.count;
        this.dataSize = props.data_size;
        this.dbName = props.db_name;
        this.engine = props.engine;
        this.indexSize = props.index_size;
        this.rowCount = props.row_count;
        this.tableName = props.table_name;
    }
}
