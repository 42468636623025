import {
    NotificationPagerDutyIcon,
    NotificationSlackIcon,
    NotificationServiceNowIcon,
    NotificationTelegramIcon,
    NotificationVictorOpsIcon,
    NotificationOpsGenieIcon,
    NotificationWebhookIcon,
} from '../../../common/icons/icons';

export default NotificationServicesProviderFormat;

export enum IntegrationType {
    PAGER_DUTY = 'pagerduty',
    VICTOR_OPS = 'victorops',
    TELEGRAM = 'telegram',
    OPS_GENIE = 'opsgenie',
    SLACK = 'slack',
    WEB_HOOK = 'webhook',
    SERVICE_NOW = 'servicenow',
}

function NotificationServicesProviderFormat({
    children,
    provider,
}: CloudProviderTypeProps) {
    return (
        <span className="NotificationServicesProviderFormat">
            {getNotificationProviderIcon(provider)}{' '}
            <span>{children || getNotificationProviderName(provider)}</span>
        </span>
    );
}

export function getNotificationProviderIcon(provider: IntegrationType) {
    switch (provider) {
        case IntegrationType.SLACK:
            return <NotificationSlackIcon />;
        case IntegrationType.PAGER_DUTY:
            return <NotificationPagerDutyIcon />;
        case IntegrationType.TELEGRAM:
            return <NotificationTelegramIcon />;
        case IntegrationType.OPS_GENIE:
            return <NotificationOpsGenieIcon />;
        case IntegrationType.VICTOR_OPS:
            return <NotificationVictorOpsIcon />;
        case IntegrationType.SERVICE_NOW:
            return <NotificationServiceNowIcon />;
        case IntegrationType.WEB_HOOK:
            return <NotificationWebhookIcon />;
    }
}

export function getNotificationProviderName(provider: IntegrationType) {
    switch (provider) {
        case IntegrationType.SLACK:
            return 'Slack';
        case IntegrationType.PAGER_DUTY:
            return 'Pager Duty';
        case IntegrationType.TELEGRAM:
            return 'Telegram';
        case IntegrationType.OPS_GENIE:
            return 'Ops Genie';
        case IntegrationType.VICTOR_OPS:
            return 'Victor Ops';
        case IntegrationType.SERVICE_NOW:
            return 'Service Now';
        case IntegrationType.WEB_HOOK:
            return 'WebHook';
    }
}
