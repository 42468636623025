export const OPTIONS = [
    { label: 'add user', value: 'add user' },
    { label: 'remove user', value: 'remove user' },
    { label: 'clear server', value: 'clear server' },
    { label: 'help', value: 'help' },
    { label: 'list clients', value: 'list clients' },
    { label: 'list dcbs', value: 'list dcbs' },
    { label: 'list filters', value: 'list filters' },
    { label: 'list listeners', value: 'list listeners' },
    { label: 'list modules', value: 'list modules' },
    { label: 'list monitors', value: 'list monitors' },
    { label: 'list services', value: 'list services' },
    { label: 'list servers', value: 'list servers' },
    { label: 'list sessions', value: 'list sessions' },
    { label: 'list threads', value: 'list threads' },
    { label: 'reload config', value: 'reload config' },
    { label: 'reload dbusers', value: 'reload dbusers' },
    { label: 'restart monitor', value: 'restart monitor' },
    { label: 'restart service', value: 'restart service' },
    { label: 'show dcbs', value: 'show dcbs' },
    { label: 'show dcb', value: 'show dcb' },
    { label: 'show dbusers', value: 'show dbusers' },
    { label: 'show epoll', value: 'show epoll' },
    { label: 'show event', value: 'show event' },
    { label: 'show eventstats', value: 'show eventstats' },
    { label: 'show feedbackreport', value: 'show feedbackreport' },
    { label: 'show filter', value: 'show filter' },
    { label: 'show filters', value: 'show filters' },
    { label: 'show modules', value: 'show modules' },
    { label: 'show monitor', value: 'show monitor' },
    { label: 'show monitors', value: 'show monitors' },
    { label: 'show server', value: 'show server' },
    { label: 'show servers', value: 'show servers' },
    { label: 'show serversjson', value: 'show serversjson' },
    { label: 'show services', value: 'show services' },
    { label: 'show service', value: 'show service' },
    { label: 'show session', value: 'show session' },
    { label: 'show sessions', value: 'show sessions' },
    { label: 'show tasks', value: 'show tasks' },
    { label: 'show threads', value: 'show threads' },
    { label: 'show users', value: 'show users' },
];
