import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { ResponsiveContext } from '@severalnines/bar-frontend-components/build/lib/Layout/Responsive';
import useListFetch from '../../common/useListFetch';
import {
    AppState,
    AppStateClustersMap,
    AppStateRunningJobs,
} from '../../appReducer';
import { useSelector } from 'react-redux';
import ClusterFormat from '../Clusters/ClusterFormat';
import DurationFormat from '@severalnines/bar-frontend-components/build/lib/Format/DurationFormat';

import CcJob from '../../services/models/CcJob';
import JobStatusFormat, {
    isJobStatusFailed,
    isJobStatusFinished,
    isJobStatusRunning,
} from '../Jobs/JobStatusFormat';
import CmonJobsService from '../../services/cmon/CmonJobsService';
import CmonJobInstance from '../../services/cmon/models/CmonJobInstance';
import JobActionsMenu from './JobActionsMenu';
import { TablePaginationConfig } from 'antd/es';
import AppEmpty from '../../common/Feedback/AppEmpty';
import AppDateFormat from '../../common/AppDateFormat';
import AppTable from '../../common/DataDisplay/AppTable';
import useTableFilter from '../../common/hooks/useTableFilter';
import { ArrayParam } from 'use-query-params';
import useTableFilterColumns, {
    TableFilterType,
} from '../../common/hooks/useTableFilterColumns';
import JobDetailsButton from './JobDetailsButton';

export default JobsTable;

export type JobsTableProps = {
    enablePagination?: boolean;
    pageLimit?: number;
    footerRender?: (total?: number) => React.ReactNode;
    clusterId?: number;
    showRelativeTime?: boolean;
};

function JobsTable({
    enablePagination = true,
    pageLimit = 20,
    footerRender,
    clusterId,
    showRelativeTime,
}: JobsTableProps) {
    const { filterParams, handleTableChange } = useTableFilter({
        params: {
            cluster: ArrayParam,
        },
    });

    const { responsive } = useContext(ResponsiveContext);
    const {
        list: jobs,
        loaded: loadedJobs,
        loading: loadingJobs,
        refresh: refreshJobs,
        page,
        pageSize,
        total,
    } = useListFetch({
        name: 'jobs-list',
        pageSize: pageLimit,
        order: 'created DESC',
        fetchFn: async ({ pageSize, page, ...rest }, opts) => {
            const response = await CmonJobsService.getJobInstances(
                {
                    limit: pageSize,
                    offset: (page - 1) * pageSize,
                    ...rest,
                },
                opts
            );
            return {
                list: response.jobs || [],
                // defaulting to empty list bc of this https://severalnines.atlassian.net/browse/CLUS-2596
                total: response.total,
            };
        },
        cancelFn: async ({ requestId }) => {
            CmonJobsService.cancelRequest(requestId);
        },
    });

    const [clustersMap, runningJobs]: [
        AppStateClustersMap,
        AppStateRunningJobs
    ] = useSelector(({ clusters, runningJobs }: AppState) => [
        clusters,
        runningJobs,
    ]);

    // @todo come up with reusable logic handling these filters
    const refresh = async (params: any = {}) => {
        let refreshParams: any = {
            cluster_ids: undefined,
            page: filterParams.page || 1,
        };
        if (clusterId) {
            refreshParams = {
                ...refreshParams,
                cluster_id: clusterId,
            };
        } else if (filterParams.cluster) {
            refreshParams = {
                ...refreshParams,
                cluster_ids: filterParams.cluster,
            };
        }
        await refreshJobs({ ...refreshParams, ...params });
    };

    useEffect(() => {
        (async () => {
            await refresh();
        })();
    }, [filterParams, clusterId]);

    useEffect(() => {
        (async () => {
            if (loadedJobs) {
                // refreshing list when something happens in running jobs map
                await refresh({ showLoading: false });
            }
        })();
    }, [runningJobs]);

    const handleActionPerfomed = async () => {
        await refresh({ useCache: false });
    };

    const { columns } = useTableFilterColumns({
        columns: useMemo(
            () => [
                {
                    title: 'Title',
                    key: 'title',
                    render: (record: CcJob) => (
                        <JobDetailsButton job={record}>
                            {record.title}
                        </JobDetailsButton>
                    ),
                },
                {
                    title: 'Status',
                    key: 'status',
                    render: (record: CcJob) => (
                        <JobStatusFormat job={record} nowrap={true} />
                    ),
                },
                {
                    title: 'Cluster',
                    key: 'cluster',
                    render: (record: CcJob) => (
                        <ClusterFormat
                            clusterLink={true}
                            linkDestination={'jobs'}
                            cluster={clustersMap.get(record.getClusterKey())}
                            showPopover={true}
                        />
                    ),
                    filterType: TableFilterType.CLUSTER,
                },
                {
                    title: 'Started by',
                    key: 'started_by',
                    render: (record: CcJob) => record.userName,
                },
                {
                    title: 'When',
                    key: 'created',
                    render: (record: CcJob) => {
                        let date = record.created;
                        if (
                            isJobStatusFailed(record.status) ||
                            isJobStatusFinished(record.status)
                        ) {
                            date = record.ended;
                        } else if (isJobStatusRunning(record.status)) {
                            date = record.started;
                        }
                        return (
                            <>
                                <AppDateFormat fromNow={showRelativeTime}>
                                    {date ? new Date(date) : undefined}
                                </AppDateFormat>
                            </>
                        );
                    },
                },
                {
                    title: 'Duration',
                    key: 'duration',
                    render: (record: CcJob) => {
                        return (
                            <DurationFormat short={true}>
                                {record.getDuration()}
                            </DurationFormat>
                        );
                    },
                },
                {
                    key: 'actions',
                    title: 'Actions',
                    align: 'center',
                    render: (record: CcJob) => (
                        <JobActionsMenu
                            cluster={clustersMap.get(record.getClusterKey())}
                            job={record}
                            onActionPerformed={handleActionPerfomed}
                        />
                    ),
                    onCell: () => ({ style: { padding: '2px 10px' } }),
                },
            ],
            [clustersMap, showRelativeTime]
        ),
        filterParams,
        state: {
            clusters: clustersMap,
        },
    });

    const pagination: TablePaginationConfig = {
        size: 'default',
        pageSize,
        current: page,
        total,
        hideOnSinglePage: true,
        showQuickJumper: true,
        showSizeChanger: true,
        position: ['bottomCenter'],
    };

    const rowKey = useCallback(
        (record: CmonJobInstance) => `${record.jobId}`,
        []
    );

    const handleOnRow = useCallback(
        (record: CmonJobInstance, index: number) => ({
            'data-testid': `job-list-row-${index}`,
        }),
        []
    );

    const extraProps = useCallback(() => {
        let props: any = {};
        if (footerRender) {
            props.footer = () => footerRender(total);
        }
        return props;
    }, [footerRender, total]);

    return (
        <AppTable
            className="JobsTable"
            loading={loadingJobs}
            rowKey={rowKey}
            dataSource={jobs}
            columns={columns}
            pagination={enablePagination ? pagination : false}
            onChange={handleTableChange}
            responsive={responsive}
            onRow={handleOnRow}
            renderEmpty={
                filterParams.cluster ? (
                    false
                ) : (
                    <AppEmpty
                        loading={loadingJobs}
                        description="You haven’t created jobs yet. When you do, it'll show up here."
                    />
                )
            }
            {...extraProps()}
        />
    );
}
