import React from 'react';
import { MomentProps } from 'react-moment';
import DateFormat from '@severalnines/bar-frontend-components/build/lib/Format/DateFormat';
import { AppState, AppStateUser } from '../appReducer';
import { useSelector } from 'react-redux';

export default AppDateFormat;

// @todo use types from bar-frontend-components
export type AppDateFormatProps = MomentProps & {
    className?: string;
    format?: string;
    includeTimezone?: boolean;
    renderTooltip?: (dateStr: string) => React.ReactNode;
    outdated?: number;
    type?: string;
    fromNow?: boolean;
    toNow?: boolean;
    ago?: boolean;
};

function AppDateFormat({ children, ...rest }: AppDateFormatProps) {
    const [user]: [AppStateUser] = useSelector(({ user }: AppState) => [user]);
    return (
        <DateFormat tz={user?.timezone?.name} {...rest}>
            {children}
        </DateFormat>
    );
}
