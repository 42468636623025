import useLocalStorage from '@severalnines/bar-frontend-components/build/lib/hooks/useLocalStorage';

const FAKE_INCREASE_MIN_PERCENT = 20;
const FAKE_INCREASE_MAX_PERCENT = 30;
const FAKE_INCREASE_MAX = 40;
const FAKE_INCREASE_SECONDS_PERIOD = 10;
const FAKE_INCREASE_MAX_TIMES = 10;
const FAKE_PROGRESS_TIMEOUT = 3600 * 1000;

type StoredFakeProgressItem = {
    percent?: number;
    time?: number;
};
export type UseFakeProgressProps = {
    id?: string | number;
    increasePeriod?: number;
};
export default function useFakeProgress({
    id,
    increasePeriod = FAKE_INCREASE_SECONDS_PERIOD,
}: UseFakeProgressProps) {
    const [items, setItems] = useLocalStorage({
        key: `fake-progress`,
    });

    const prepareItem = () => {
        const currentItems: { [key: string]: StoredFakeProgressItem } = items
            ? { ...items }
            : {};
        const now = Date.now();
        // delete items that are too old
        Object.keys(currentItems).forEach((key) => {
            const item = currentItems[key];
            if (item.time && item.time < now - FAKE_PROGRESS_TIMEOUT) {
                delete currentItems[key];
            }
        });

        return currentItems;
    };

    const increaseProgress = (lastPercent: number) => {
        const last = 100 - lastPercent;
        const increase =
            last *
            ((Math.random() *
                (FAKE_INCREASE_MAX_PERCENT - FAKE_INCREASE_MIN_PERCENT + 1) +
                FAKE_INCREASE_MIN_PERCENT) /
                100);
        return Math.floor(
            lastPercent +
                (increase > FAKE_INCREASE_MAX ? increase / 2 : increase)
        );
    };

    const update = () => {
        if (!id) {
            return;
        }
        const currentItems = prepareItem();
        const { percent: lastPercent, time: lastUpdate } =
            currentItems[id] || {};

        const now = Date.now();
        let percent = lastPercent || 0;
        if (lastPercent) {
            const timeDiff = now - (lastUpdate || 0);
            let times = timeDiff / (increasePeriod * 1000);

            // increase progress one time for 'increasePeriod' seconds
            // maximum FAKE_INCREASE_MAX_TIMES times
            if (times < 1) {
                const newPercent = increaseProgress(percent);
                percent = percent + (newPercent - percent) * times;
            } else {
                times = Math.floor(times);
                if (times > FAKE_INCREASE_MAX_TIMES) {
                    times = FAKE_INCREASE_MAX_TIMES;
                }
                for (let i = 0; i < times; i++) {
                    percent = increaseProgress(percent);
                }
            }
        } else {
            percent = increaseProgress(percent);
        }

        currentItems[id] = {
            percent,
            time: now,
        };
        setItems(currentItems);
    };

    // clear current progress
    const clear = () => {
        if (!id) {
            return;
        }
        const currentItems = prepareItem();
        delete currentItems[id];
        setItems(currentItems);
    };

    const percent = (items && id && items[id]?.percent) || 0;
    return { percent, update, clear };
}
