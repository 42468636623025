import './Avatar.less';
import React from 'react';
import { getArrayItemByString, hexToRgbA } from '../common/helpers';
import { abbreviateString } from '../common/utils/string';
import { Space } from 'antd';
import classNames from 'classnames/index';
import { UserOutlined } from '@ant-design/icons';

export default Avatar;

const COLORS = [
    '#1890FF',
    '#F5222D',
    '#A0D911',
    '#722ED1',
    '#13C2C2',
    '#FA8C16',
];

export type AvatarProps = {
    name: string;
    showAvatar?: boolean;
    size?: 'small' | 'large';
    colorGenerateKey?: string;
};

function Avatar({
    name,
    showAvatar,
    size = 'small',
    colorGenerateKey,
    ...rest
}: AvatarProps) {
    const color = getArrayItemByString(colorGenerateKey || name, COLORS);
    return (
        <Space
            direction="vertical"
            align="center"
            size={1}
            className={classNames('Avatar', `Avatar--size-${size}`, {
                'Avatar--avatar-icon': showAvatar,
            })}
            style={{ backgroundColor: hexToRgbA(color, 0.1) }}
            {...rest}
        >
            {showAvatar ? (
                <span
                    style={{
                        color,
                        fontSize: size === 'large' ? '3em' : '1.3em',
                    }}
                >
                    <UserOutlined />
                </span>
            ) : (
                <span
                    style={{
                        color,
                        fontSize: size === 'large' ? '2.5em' : '1em',
                    }}
                >
                    {abbreviateString(name)}
                </span>
            )}
        </Space>
    );
}
