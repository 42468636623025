


export interface CmonDbStatProps  {
    class_name?: string;
    count?: number;
    data_size?: number;
    db_name?: string;
    index_size?: number;
    row_count?: number;
    table_count?: number;
    table_name?: string;

}



export default class CmonDbStat {
    public className?: string;
    public count?: number;
    public dataSize?: number;
    public dbName?: string;
    public indexSize?: number;
    public rowCount?: number;
    public tableCount?: number;
    public tableName?: string;


    constructor(props: CmonDbStatProps) {
        this.className = props.class_name;
        this.count = props.count;
        this.dataSize = props.data_size;
        this.dbName = props.db_name;
        this.indexSize = props.index_size;
        this.rowCount = props.row_count;
        this.tableCount = props.table_count;
        this.tableName = props.table_name;

    }

}
