import React, {
    useImperativeHandle,
    forwardRef,
    useEffect,
    useMemo,
} from 'react';
import { Tag, Space } from 'antd';
import { useSelector } from 'react-redux';
import BooleanFormat from '@severalnines/bar-frontend-components/build/lib/Format/BooleanFormat';
import AppTable from '../../../common/DataDisplay/AppTable';
import AppEmpty from '../../../common/Feedback/AppEmpty';
import ClusterFormat from '../../Clusters/ClusterFormat';
import useNotificationServices from './useNotificationServices';
import NotificationServicesActionMenu from './NotificationServicesActionMenu';
import NotificationServicesProviderFormat from './NotificationServicesProviderFormat';
import NotificationEventTag from '../../../common/DataDisplay/NotificationEventTag';
import { SELECT_ALL_CLUSTER_OPTION } from '../../../components/Clusters/ClusterSelectDropdown';
import { useDebugContext } from '../../../common/Debug';
import { AppState, AppStateClustersMap } from '../../../appReducer';
import TagsList from '../../../common/DataDisplay/TagsList';

export type NotificationServicesTableProps = {};

export default forwardRef(NotificationServicesTable);

export interface NotificationServicesTableApi {
    refresh: () => void;
}

function NotificationServicesTable(
    {}: NotificationServicesTableProps,
    ref: any
) {
    const { list, refresh, loading } = useNotificationServices({});
    const [clustersMap]: [
        AppStateClustersMap
    ] = useSelector(({ clusters }: AppState) => [clusters]);
    const { log } = useDebugContext();
    useImperativeHandle(
        ref,
        (): NotificationServicesTableApi => ({
            async refresh() {
                await refresh({});
            },
        })
    );

    const columns = useMemo(
        () => [
            {
                title: 'Name',
                key: 'name',
                width: 210,
                render: (record: any) => record.name,
            },
            {
                title: 'Service',
                key: 'service_id',
                render: (record: any) => (
                    <NotificationServicesProviderFormat
                        provider={record.service_id}
                    />
                ),
            },
            {
                title: 'Active',
                key: 'is_active',
                render: (record: any) => (
                    <BooleanFormat
                        booleanVar={record.is_active}
                        contentOn="Enabled"
                        contentOff="Disabled"
                        statusOn="success"
                    />
                ),
            },
            {
                title: 'Send Alarms From',
                key: 'config',
                render: (record: any) => {
                    try {
                        // @todo find better way to show clusters in table cell
                        const config = record.config;
                        if (
                            config.clusters.length &&
                            config.clusters[0] === SELECT_ALL_CLUSTER_OPTION
                        ) {
                            return 'All Clusters';
                        } else {
                            const clusters: number[] = config.clusters.slice(
                                0,
                                2
                            );
                            return (
                                <Space size={[10, 10]} wrap>
                                    {clusters.map((cluster_id) => {
                                        const cluster = clustersMap.get(
                                            cluster_id?.toString()
                                        );
                                        if (cluster) {
                                            return (
                                                <ClusterFormat
                                                    clusterLink={true}
                                                    linkDestination={
                                                        'dashboard'
                                                    }
                                                    cluster={cluster}
                                                    showPopover={true}
                                                />
                                            );
                                        }
                                        return '';
                                    })}
                                    {config.clusters.length > 2 && (
                                        <Tag>
                                            + {config.clusters.length - 2} ...
                                        </Tag>
                                    )}
                                </Space>
                            );
                        }
                    } catch (e) {
                        log.error(e);
                    }
                },
            },
            {
                title: 'Trigger events',
                key: 'events',
                render: (record: any) => {
                    const config = record.config;
                    const triggers: any[] = config.triggers;
                    const tags = triggers.map(
                        (trigger) => `${trigger.trigger},${trigger.severity}`
                    );
                    return (
                        <TagsList
                            title={tags.map((tag) => `[${tag}]`).join(',\n')}
                            tags={tags}
                            tagRender={({ value }) => {
                                const [trigger, severity] = value.split(',');
                                return (
                                    <NotificationEventTag
                                        trigger={severity}
                                        type={trigger}
                                        separate={false}
                                    />
                                );
                            }}
                        />
                    );
                },
            },
            {
                key: 'Actions',
                width: 30,
                render: (record: any) => {
                    return (
                        <NotificationServicesActionMenu
                            onSuccess={refreshTable}
                            record={record}
                        />
                    );
                },
            },
        ],
        []
    );

    const refreshTable = () => {
        refresh({});
    };

    useEffect(() => {
        refreshTable();
    }, []);

    return (
        <AppTable
            loading={loading}
            dataSource={list || []}
            renderEmpty={
                <AppEmpty
                    loading={loading}
                    description="You haven’t created integration"
                />
            }
            onRow={() => {}}
            columns={columns}
            size="middle"
            footer={() => <Space size="large" />}
        />
    );
}
