import useListFetch from '../../../common/useListFetch';
import CcAdvisorSchedule, {
    AdvisorScheduleResultsType,
    ClusterDirTreeFileType,
} from '../../../services/models/CcAdvisorSchedule';
import CmonImperativeService from '../../../services/cmon/CmonImperativeService';

type UseAdvisorSchedulesProps = {
    clusterId?: number;
};
export default function useAdvisorSchedules({
    clusterId,
}: UseAdvisorSchedulesProps) {
    const { loading, list, refresh, loaded } = useListFetch<CcAdvisorSchedule>({
        name: 'cluster-advisors-list',
        pageSize: 0,
        fetchFn: async (params, opts) => {
            const { data } = await CmonImperativeService.dirTree(
                {
                    ...params,
                    cluster_id: clusterId,
                },
                opts
            );
            const files: ClusterDirTreeFileType[] = extractFilesWithSchedule(
                data.contents
            );
            type FilesObject = { [key: string]: ClusterDirTreeFileType };
            // convert array files with object with the key of filename
            const filesObj: FilesObject = files.reduce((acc, file) => {
                acc[`${file.path}${file.filename}`] = file;
                return acc;
            }, {} as FilesObject);

            const {
                data: scheduleResults,
            } = await CmonImperativeService.scheduleResults(
                {
                    cluster_id: clusterId,
                    filenames: Object.keys(filesObj),
                },
                opts
            );
            return {
                list:
                    scheduleResults.map((item: AdvisorScheduleResultsType) => {
                        return new CcAdvisorSchedule(
                            item,
                            filesObj[`${item.filename}`]
                        );
                    }) || [],
            };
        },
        cancelFn: async ({ requestId }) => {
            await CmonImperativeService.cancelRequest(requestId);
        },
    });

    return {
        loading,
        loaded,
        list,
        refresh,
    };
}

function extractFilesWithSchedule(files: any[]): any[] {
    const result = [];

    for (const file of files) {
        if (file.type === 'directory') {
            result.push(...extractFilesWithSchedule(file.contents));
        } else if (file.settings && file.schedule) {
            result.push(file);
        }
    }

    return result;
}
