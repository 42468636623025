import React from 'react';
import { Button, Col, Form, Row } from 'antd';
import FormFooter from '../../../common/FormFooter';
import ModalDefaultForm from '../../../common/ModalDefaultForm';
import CcNode, { CcNodeType } from '../../../services/models/CcNode';
import NodeFormat from '../NodeFormat';
import SpaceWide from '../../../common/SpaceWide';
import useCreateJob from '../../Jobs/useCreateJob';
import { CmonJobInstanceCommand } from '../../../services/cmon/models/CmonJobInstance';
import NodeStopForm from './NodeStopForm';
import NodeStartForm from './NodeStartForm';

export default NodeRestartForm;

export type NodeRestartFormProps = {
    node: CcNode;
    onSuccess?: () => void;
    onCancel?: () => void;
};
export type NodeRestartFormValues = {
    initialStart?: boolean;
    stopTimeout?: number;
    forceStop?: boolean;
};

function NodeRestartForm({ node, onSuccess, onCancel }: NodeRestartFormProps) {
    const [form] = Form.useForm<NodeRestartFormValues>();
    const { loading, send } = useCreateJob({
        clusterId: node.clusterid,
        title: 'Restart node',
        command: CmonJobInstanceCommand.RESTART,
        onSuccess,
    });

    const handleSubmit = async (values: NodeRestartFormValues) => {
        await send({
            initial: values.initialStart,
            hostname: node.hostname,
            port: node.port,
            stop_timeout: values.stopTimeout,
            force_stop: values.forceStop,
        });
    };
    return (
        <ModalDefaultForm
            title="Restart node"
            form={form}
            footer={[]}
            onCancel={onCancel}
            width={450}
            defaultVisible={true}
        >
            <Form
                className="NodeRestartForm"
                form={form}
                layout="vertical"
                onFinish={handleSubmit}
                initialValues={{
                    initialStart: false,
                    stopTimeout: 1800,
                    forceStop: false,
                }}
            >
                <Row gutter={24}>
                    <Col span={24}>
                        <SpaceWide direction="vertical">
                            <NodeFormat
                                node={node}
                                size="large"
                                contentProps={{ style: { width: '100%' } }}
                            />
                            {node.isType(CcNodeType.GALERA) ? (
                                <NodeStartForm.InitialStartInput />
                            ) : null}
                            <NodeStopForm.StopTimeoutInput />
                            <NodeStopForm.ForceStopInput />
                            <NodeStopForm.Notice />
                        </SpaceWide>
                    </Col>
                </Row>
                <FormFooter>
                    <Button
                        key="done"
                        type="primary"
                        htmlType="submit"
                        loading={loading}
                    >
                        Restart
                    </Button>
                </FormFooter>
            </Form>
        </ModalDefaultForm>
    );
}
