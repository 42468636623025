import { useState, useEffect } from 'react';
import { Button, Space } from 'antd';
import { RedoOutlined } from '@ant-design/icons';
import { DirectoryTreeProps } from 'antd/lib/tree';
import { loadCertificatesTree } from '../Security/UseExistingCertificate';
import AppDirectoryTree from '../../common/General/AppDirectoryTree';
import KeyManagementCreateFolderButton from './KeyManagementCreateFolderButton';
import KeyManagementDeleteButton from './KeyManagementDeleteButton';
import './KeyManagement.less';

export default KeyManagementTree;

export type KeyManagementTreeProps = {
    list: any;
    hideFiles: boolean;
    onSelect?: (node: any) => void;
    onRefresh?: () => void;
};

function KeyManagementTree({
    list,
    hideFiles = false,
    onSelect,
    onRefresh,
}: KeyManagementTreeProps) {
    const [expandedKeys, setExpandedKeys] = useState<string[]>([]);
    const [treeData, setTreeData] = useState([]);
    const [selectedNode, setSelectedNode] = useState<any>(null);

    const handleExpand: DirectoryTreeProps['onExpand'] = (keys) => {
        setExpandedKeys(keys as string[]);
    };

    const handleCreateFolder = (folderName: string) => {
        list?.push({
            certfile: selectedNode
                ? `${selectedNode.path}/${folderName}/`
                : `${folderName}/`,
        });
        setTreeData(loadCertificatesTree(list, hideFiles));
    };

    const handleTreeItemSelect = (item: any, { node }: any) => {
        setSelectedNode(node);
        onSelect?.(node);
    };

    useEffect(() => {
        if (Array.isArray(list)) {
            setTreeData(loadCertificatesTree(list, hideFiles));
        }
    }, [list]);

    return (
        <div className="KeyManagementTree">
            <Space direction="horizontal" style={{ paddingBottom: '10px' }}>
                <KeyManagementCreateFolderButton
                    onSuccess={handleCreateFolder}
                />
                <Button
                    size={'small'}
                    icon={<RedoOutlined />}
                    onClick={onRefresh}
                ></Button>
            </Space>

            <AppDirectoryTree
                onExpand={handleExpand}
                expandedKeys={expandedKeys}
                onSelect={handleTreeItemSelect}
                treeData={treeData}
            />
        </div>
    );
}
