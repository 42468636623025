import React, { useEffect } from 'react';
import useServiceMock from './serviceMock/useServiceMock';
import { ServiceMethod } from './serviceMock/ServiceMockContext';
import UserGroupCreateButton from '../components/UserManagement/UserGroupCreateButton';
import { Form } from 'antd';
import CcGroup from '../services/models/CcGroup';

export default DebugUserGroupCreateButton;

export type DebugUserGroupCreateButtonProps = {};

function DebugUserGroupCreateButton() {
    useServiceMock({
        mockId: 'user-group-create',
        methods: [
            ServiceMethod.USER_GET_GROUPS,
            ServiceMethod.USER_CREATE,
            ServiceMethod.GROUP_CREATE,
            ServiceMethod.GET_TREE,
            ServiceMethod.USER_GET_USERS,
            ServiceMethod.ADD_ACL,
            ServiceMethod.ADD_TO_GROUP,
        ],
    });
    const [form] = Form.useForm();
    useEffect(() => {
        form.setFieldsValue({
            firstName: 'First',
            lastName: 'Last',
            username: 'Username',
            email: 'some@email.com',
            password: 'passwd',
            groups: [
                new CcGroup({
                    class_name: 'CmonGroup',
                    cdt_path: '/groups',
                    owner_user_id: 1,
                    owner_user_name: 'system',
                    owner_group_id: 1,
                    owner_group_name: 'admins',
                    acl: 'user::rwx,group::rwx,other::---',
                    created: '2022-02-02T13:11:15.734Z',
                    effective_privileges: 'rwx',
                    group_id: 1,
                    group_name: 'admins',
                }),
            ],
        });
    }, []);
    return <UserGroupCreateButton />;
}
