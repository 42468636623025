
import CmonRequestService, {CmonRequestServiceResponseData} from './CmonRequestService';
import {CmonBackupScheduleProps} from './models/CmonBackupSchedule'


export interface GetBackupsRequestData {
    ascending?: boolean;
    cluster_id?: number;
    backup_record_version?: number;
    request_created?: string;
    request_id?: number;
    [key: string]: any;
}

export interface GetBackupSetRequestData {
    cluster_id?: number;
    backup_record_version?: number;
    request_created?: string;
    request_id?: number;
    [key: string]: any;
}

export interface GetBackupSchedulesRequestData {
    request_created?: string;
    request_id?: number;
    [key: string]: any;
}

export interface ScheduleBackupRequestData {
    cluster_id?: number;
    request_created?: string;
    request_id?: number;
    schedule?: CmonBackupScheduleProps;
    [key: string]: any;
}

export interface DeleteBackupRecordRequestData {
    backup_record?: {[key:string]:any};
    request_created?: string;
    request_id?: number;
    [key: string]: any;
}

export interface SnapshotRepositoryRequestData {
    class_name?: string;
    cluster_id?: number;
    request_created?: string;
    request_id?: number;
    repository?: {};
    [key: string]: any;
}

export interface GetAllSnapshotRepositoriesRequestData {
    request_created?: string;
    request_id?: number;
    [key: string]: any;
}

export interface GetSnapshotRepositoriesRequestData {
    cluster_id?: number;
    request_created?: string;
    request_id?: number;
    [key: string]: any;
}

export interface DeleteSnapshotRepositoryRequestData {
    class_name?: string;
    cluster_id?: number;
    request_created?: string;
    request_id?: number;
    snapshot_repository?: string;
    [key: string]: any;
}


export default class CmonBackupService extends CmonRequestService {
    
    public static module(): string {
        return 'backup';
    }
    
    public static async getBackups(
        data: GetBackupsRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('getBackups', data, opts);
    }

    public static async getBackupSet(
        data: GetBackupSetRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('getBackupSet', data, opts);
    }

    public static async getBackupSchedules(
        data: GetBackupSchedulesRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('getBackupSchedules', data, opts);
    }

    public static async scheduleBackup(
        data: ScheduleBackupRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('scheduleBackup', data, opts);
    }

    public static async deleteBackupRecord(
        data: DeleteBackupRecordRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('deleteBackupRecord', data, opts);
    }

    public static async snapshotRepository(
        data: SnapshotRepositoryRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('snapshotRepository', data, opts);
    }

    public static async getAllSnapshotRepositories(
        data: GetAllSnapshotRepositoriesRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('getAllSnapshotRepositories', data, opts);
    }

    public static async getSnapshotRepositories(
        data: GetSnapshotRepositoriesRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('getSnapshotRepositories', data, opts);
    }

    public static async deleteSnapshotRepository(
        data: DeleteSnapshotRepositoryRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('deleteSnapshotRepository', data, opts);
    }

}
