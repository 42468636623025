import './AppSpin.less';
import React from 'react';
import { Skeleton, Spin, SpinProps } from 'antd';
import classNames from 'classnames';
import { Loading3QuartersOutlined } from '@ant-design/icons';
import { StatusFormatStatus } from '@severalnines/bar-frontend-components/build/lib/Format/StatusFormat';

export default AppSpin;
export type AppSpinProps = SpinProps & {
    tall?: boolean;
    status?: StatusFormatStatus;
    showFallback?: boolean;
};

function AppSpin({
    tall,
    size = 'large',
    spinning,
    wrapperClassName,
    status,
    children,
    showFallback,
    ...rest
}: AppSpinProps) {
    return (
        <Spin
            className={classNames('AppSpin', `AppSpin--status-${status}`)}
            size={size}
            spinning={spinning}
            indicator={<Loading3QuartersOutlined spin={true} />}
            wrapperClassName={classNames(wrapperClassName, {
                'AppSpin--tall': tall,
            })}
            children={showFallback && spinning ? <Skeleton /> : children}
            {...rest}
        />
    );
}
