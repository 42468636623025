import { Col, Input, Row, Select } from 'antd';
import FormItem from '../../../../common/DataEntry/FormItem';
import React from 'react';

export default ClusterScriptParamsFields;

type ClusterScriptParamsFieldsProps = {
    tags: string[];
};

function ClusterScriptParamsFields({ tags }: ClusterScriptParamsFieldsProps) {
    return (
        <Row gutter={[24, 0]}>
            <Col span={12}>
                <FormItem withLessMarginBottom={true} name="tags" label="Tags">
                    <Select
                        mode="tags"
                        allowClear
                        placeholder="Type to add tags"
                    >
                        {tags.map((o) => (
                            <Select.Option key={o} value={o}>
                                {o}
                            </Select.Option>
                        ))}
                    </Select>
                </FormItem>
            </Col>
            <Col span={12}>
                <FormItem
                    withLessMarginBottom={true}
                    name="arguments"
                    label="Arguments"
                >
                    <Input />
                </FormItem>
            </Col>
        </Row>
    );
}
