import CmonProxySqlHost, {
    CmonProxySqlHostProps,
} from '../cmon/models/CmonProxySqlHost';

export interface CcProxySqlNodeProps extends CmonProxySqlHostProps {}

export default class CcProxySqlNode extends CmonProxySqlHost {
    constructor(props: CcProxySqlNodeProps) {
        super(props);
    }
}
