import { useEffect, useReducer, useState } from 'react';
import { initialListState, listReducer, setListAll } from '../listReducer';
import { arrayFilter, arrayPages } from '../filtering';
import { AppState, AppStateGlobalFilters } from '../../appReducer';
import { useSelector } from 'react-redux';

type FilterFunctionParams = ListParams & {
    arr?: any[];
    order?: null | ((a: any, b: any) => number);
};
type ListParams = {
    page?: number;
    pageSize?: number;
    filters?: Function[];
    // null can be used to reset the order and cancel sorting
    order?: (a: any, b: any) => number;
};
type UseClientListFilterProps<T> = ListParams & {
    dataList?: T[];
    globalFilterConfig?: (filters: AppStateGlobalFilters) => Function[];
};
export default function useClientListFilter<T extends any>({
    pageSize,
    order,
    dataList,
    globalFilterConfig,
}: UseClientListFilterProps<T>) {
    const [globalFilters]: [AppStateGlobalFilters] = useSelector(
        ({ globalFilters }: AppState) => [globalFilters]
    );

    const [
        {
            page: listPage,
            pageSize: listPageSize,
            order: listOrder,
            filters: listFilters,
        },
        listDispatch,
    ] = useReducer(listReducer, {
        ...initialListState,
        pageSize: pageSize || 0,
        order: order,
    });

    const filterList = ({
        page = listPage,
        pageSize = listPageSize,
        order = listOrder,
        filters = listFilters,
        arr = dataList || [],
    }: FilterFunctionParams = {}): [T[], number] => {
        const filteredArr = arrayFilter({
            filters:
                [
                    ...(filters || []),
                    ...(globalFilterConfig?.(globalFilters) || []),
                ] || [],
            arr,
        });
        return [
            arrayPages({
                page,
                pageSize,
                order: order === null ? undefined : order,
                arr: filteredArr,
            }),
            filteredArr.length,
        ];
    };

    const [list, setList] = useState<T[]>([]);
    const [total, setTotal] = useState<number>(list.length);

    const filter = ({
        page = listPage,
        pageSize = listPageSize,
        order = listOrder,
        filters = listFilters,
        arr = dataList || [],
    }: FilterFunctionParams = {}) => {
        listDispatch(
            setListAll({
                page,
                pageSize,
                order: order === null ? undefined : order,
                filters: filters,
            })
        );
        const [list, total] = filterList({
            page,
            pageSize,
            order,
            filters,
            arr,
        });
        setList(list);
        setTotal(total);
    };

    useEffect(() => {
        if (dataList) {
            filter();
        }
    }, [dataList]);

    return {
        filter,
        list,
        total,
    };
}
