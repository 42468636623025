const mock = {
    class_name: 'CmonClusterInfo',
    cdt_path: '/',
    acl: 'user::rwx,group::rwx,other::---',
    tags: [],
    cluster_auto_recovery: true,
    cluster_id: 1,
    cluster_name: 'Mongo Replica Set',
    cluster_type: 'MONGODB',
    configuration_file: '/etc/cmon.d/cmon_1.cnf',
    effective_privileges: 'rwx',
    log_file: '/var/log/cmon_1.log',
    maintenance_mode_active: false,
    managed: true,
    node_auto_recovery: true,
    rpc_version: '2.0',
    state: 'STARTED',
    status_text: 'All nodes are operational.',
    vendor: 'percona',
    version: '5.0',
    alarm_statistics: {
        class_name: 'CmonAlarmStatistics',
        cluster_id: 1,
        created_after: null,
        critical: 0,
        reported_after: null,
        warning: 0,
    },
    group_owner: {
        class_name: 'CmonGroup',
        cdt_path: '/groups',
        owner_user_id: 1,
        owner_user_name: 'system',
        owner_group_id: 1,
        owner_group_name: 'admins',
        acl: 'user::rwx,group::rwx,other::---',
        created: '2022-02-02T13:11:15.734Z',
        group_id: 1,
        group_name: 'admins',
    },
    hosts: [
        {
            class_name: 'CmonMongoHost',
            cdt_path: '/cluster_1',
            owner_user_id: 1,
            owner_user_name: 'system',
            owner_group_id: 1,
            owner_group_name: 'admins',
            acl: 'user::rwx,group::rwx,other::---',
            clusterid: 1,
            cmdline: '/usr/bin/mongod --fork -f /etc/mongod.conf',
            configfile: '/etc/mongod.conf',
            connected: true,
            connections_available: 811,
            connections_current: 8,
            container: true,
            datadir: '/var/lib/mongodb',
            dbpath: '/var/lib/mongodb',
            global_lock_queue: 0,
            global_lock_ratio: 0,
            hidden: false,
            hostId: 2,
            hostname: '10.0.8.11',
            hoststatus: 'CmonHostOnline',
            ip: '10.0.8.11',
            lastseen: 1656086081,
            logfile: '/var/log/mongodb/mongod.log',
            maintenance_mode: 'none',
            maintenance_mode_active: false,
            member_role: 'Primary',
            message: 'Up and running',
            nodetype: 'mongo',
            os_user: 'mongod',
            pid: 4245,
            pidfile: '/var/run/mongod.pid',
            pingstatus: 1,
            pingtime: 50,
            port: 27017,
            priority: 1,
            replication_lag: 0,
            role: 'shardsvr',
            roleid: 0,
            rs: 'my_mongodb_0',
            slaveDelay: 0,
            sshfailcount: 0,
            ssl_allowinvalidcert: false,
            ssl_mode: '',
            statereason: 'Primary',
            status: 1,
            timestamp: 1656086081,
            unique_id: 2,
            uptime: 3086,
            version: '5.0.9-8',
            wallclock: 1656086020,
            wallclocktimestamp: 1656086020,
            distribution: {
                name: 'ubuntu',
                codename: 'focal',
                release: '20.04',
                type: 'debian',
            },
            replication_slave: {
                seconds_behind_master: 0,
            },
            ssl_certs: {
                server: {
                    ca: '',
                    id: 0,
                    key: '',
                    path: '',
                    ssl_enabled: false,
                },
            },
        },
        {
            class_name: 'CmonMongoHost',
            cdt_path: '/cluster_1',
            owner_user_id: 1,
            owner_user_name: 'system',
            owner_group_id: 1,
            owner_group_name: 'admins',
            acl: 'user::rwx,group::rwx,other::---',
            clusterid: 1,
            cmdline: '/usr/bin/mongod --fork -f /etc/mongod.conf',
            configfile: '/etc/mongod.conf',
            connected: true,
            connections_available: 815,
            connections_current: 4,
            container: true,
            datadir: '/var/lib/mongodb',
            dbpath: '/var/lib/mongodb',
            global_lock_queue: 0,
            global_lock_ratio: 0,
            hidden: false,
            hostId: 3,
            hostname: '10.0.8.12',
            hoststatus: 'CmonHostOnline',
            ip: '10.0.8.12',
            lastseen: 1656086081,
            logfile: '/var/log/mongodb/mongod.log',
            maintenance_mode: 'none',
            maintenance_mode_active: false,
            member_role: 'Secondary',
            message: 'Up and running',
            nodetype: 'mongo',
            os_user: 'mongod',
            pid: 4247,
            pidfile: '/var/run/mongod.pid',
            pingstatus: 1,
            pingtime: 47,
            port: 27017,
            priority: 1,
            replication_lag: 0,
            role: 'shardsvr',
            roleid: 0,
            rs: 'my_mongodb_0',
            slaveDelay: 0,
            sshfailcount: 0,
            ssl_allowinvalidcert: false,
            ssl_mode: '',
            statereason: 'Secondary',
            status: 2,
            timestamp: 1656086081,
            unique_id: 3,
            uptime: 3088,
            version: '5.0.9-8',
            wallclock: 1656086020,
            wallclocktimestamp: 1656086020,
            distribution: {
                name: 'ubuntu',
                codename: 'focal',
                release: '20.04',
                type: 'debian',
            },
            replication_slave: {
                seconds_behind_master: 0,
            },
            ssl_certs: {
                server: {
                    ca: '',
                    id: 0,
                    key: '',
                    path: '',
                    ssl_enabled: false,
                },
            },
        },
        {
            class_name: 'CmonMongoHost',
            cdt_path: '/cluster_1',
            owner_user_id: 1,
            owner_user_name: 'system',
            owner_group_id: 1,
            owner_group_name: 'admins',
            acl: 'user::rwx,group::rwx,other::---',
            clusterid: 1,
            cmdline: '/usr/bin/mongod --fork -f /etc/mongod.conf',
            configfile: '/etc/mongod.conf',
            connected: true,
            connections_available: 815,
            connections_current: 4,
            container: true,
            datadir: '/var/lib/mongodb',
            dbpath: '/var/lib/mongodb',
            global_lock_queue: 0,
            global_lock_ratio: 0,
            hidden: false,
            hostId: 4,
            hostname: '10.0.8.13',
            hoststatus: 'CmonHostOnline',
            ip: '10.0.8.13',
            lastseen: 1656086081,
            logfile: '/var/log/mongodb/mongod.log',
            maintenance_mode: 'none',
            maintenance_mode_active: false,
            member_role: 'Secondary',
            message: 'Up and running',
            nodetype: 'mongo',
            os_user: 'mongod',
            pid: 4242,
            pidfile: '/var/run/mongod.pid',
            pingstatus: 1,
            pingtime: 44,
            port: 27017,
            priority: 1,
            replication_lag: 0,
            role: 'shardsvr',
            roleid: 0,
            rs: 'my_mongodb_0',
            slaveDelay: 0,
            sshfailcount: 0,
            ssl_allowinvalidcert: false,
            ssl_mode: '',
            statereason: 'Secondary',
            status: 2,
            timestamp: 1656086081,
            unique_id: 4,
            uptime: 3091,
            version: '5.0.9-8',
            wallclock: 1656086020,
            wallclocktimestamp: 1656086020,
            distribution: {
                name: 'ubuntu',
                codename: 'focal',
                release: '20.04',
                type: 'debian',
            },
            replication_slave: {
                seconds_behind_master: 0,
            },
            ssl_certs: {
                server: {
                    ca: '',
                    id: 0,
                    key: '',
                    path: '',
                    ssl_enabled: false,
                },
            },
        },
        {
            class_name: 'CmonHost',
            cdt_path: '/cluster_1',
            owner_user_id: 5,
            owner_user_name: 'pablo',
            owner_group_id: 1,
            owner_group_name: 'admins',
            acl: 'user::rwx,group::rwx,other::---',
            clusterid: 1,
            configfile: '/etc/cmon.d/cmon_1.cnf',
            connected: true,
            container: false,
            hostId: 5,
            hostname: '134.122.121.181',
            hoststatus: 'CmonHostOnline',
            ip: '134.122.121.181',
            lastseen: 1656086078,
            logfile: '/var/log/cmon_1.log',
            maintenance_mode: 'none',
            maintenance_mode_active: false,
            message: 'Up and running.',
            nodetype: 'controller',
            pid: 1152,
            pingstatus: -1,
            pingtime: -1,
            port: 9500,
            role: 'controller',
            status: 10,
            timestamp: 1656086078,
            unique_id: 5,
            uptime: 4902,
            version: '1.9.4.5459',
            wallclock: 1656086020,
            wallclocktimestamp: 1656086020,
            distribution: {
                name: 'ubuntu',
                codename: 'focal',
                release: '20.04',
                type: 'debian',
            },
        },
    ],
    job_statistics: {
        class_name: 'CmonJobStatistics',
        cluster_id: 1,
        by_state: {
            ABORTED: 0,
            DEFINED: 0,
            DEQUEUED: 0,
            FAILED: 0,
            FINISHED: 0,
            RUNNING: 0,
        },
    },
    owner: {
        class_name: 'CmonUser',
        cdt_path: '/',
        owner_user_id: 5,
        owner_user_name: 'pablo',
        owner_group_id: 1,
        owner_group_name: 'admins',
        acl: 'user::rwx,group::r--,other::r--',
        created: '2022-03-24T13:24:02.244Z',
        disabled: false,
        email_address: 'pablo@localhost.xyz',
        first_name: 'pablo',
        last_failed_login: '',
        last_login: '2022-06-24T14:42:56.393Z',
        last_name: '',
        n_failed_logins: 0,
        origin: 'CmonDb',
        suspended: false,
        user_id: 5,
        user_name: 'pablo',
        groups: [
            {
                class_name: 'CmonGroup',
                cdt_path: '/groups',
                owner_user_id: 1,
                owner_user_name: 'system',
                owner_group_id: 1,
                owner_group_name: 'admins',
                acl: 'user::rwx,group::rwx,other::---',
                created: '2022-02-02T13:11:15.734Z',
                group_id: 1,
                group_name: 'admins',
            },
        ],
        timezone: {
            class_name: 'CmonTimeZone',
            name: 'Coordinated Universal Time',
            abbreviation: 'UTC',
            offset: 0,
            use_dst: false,
        },
    },
};
export default mock;
