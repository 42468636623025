import { Col, Row } from 'antd';
import React, { useMemo } from 'react';
import CcCluster, { CcClusterType } from '../../../services/models/CcCluster';
import { CcBackupMethod } from '../../../services/models/CcBackup';
import FormItemInlineSwitch from '../../../common/DataEntry/FormItemInlineSwitch';
import { InstallBackupToolStatus } from '../InstallBackupToolInput';
import { FormInstance } from 'antd/lib/form';
import BackupConfigurationFields from '../FormParts/BackupConfigurationFields';
import BackupConfigurationMongoForm from './BackupConfigurationForm/BackupConfigurationMongoForm';
import BackupConfigurationPostgresForm from './BackupConfigurationForm/BackupConfigurationPostgresForm';
import BackupFormConfigurator from '../Config/BackupFormConfigurator';

export type BackupConfigurationFormProps = {
    cluster?: CcCluster;
    clusterSelect?: boolean;
    onClusterSelect?: (cluster?: CcCluster) => void;
    form: FormInstance;
    configurator: BackupFormConfigurator;
    clusterPreselected?: boolean;
};
const BackupConfigurationForm = ({
    cluster,
    onClusterSelect,
    form,
    configurator,
    clusterPreselected,
}: BackupConfigurationFormProps) => {
    const configurationForm = useMemo(() => {
        switch (cluster?.clusterType) {
            case CcClusterType.TYPE_MONGODB:
            case CcClusterType.TYPE_MONGODB_SHARDS:
                return (
                    <BackupConfigurationMongoForm
                        form={form}
                        configurator={configurator}
                        cluster={cluster}
                        onClusterSelect={onClusterSelect}
                        clusterPreselected={clusterPreselected}
                    />
                );
            case CcClusterType.TYPE_TIMESCALEDB:
            case CcClusterType.TYPE_POSTGRESQL:
                return (
                    <BackupConfigurationPostgresForm
                        form={form}
                        configurator={configurator}
                        cluster={cluster}
                        onClusterSelect={onClusterSelect}
                        clusterPreselected={clusterPreselected}
                    />
                );
            default:
                return (
                    <div>
                        <BackupConfigurationFields
                            configurator={configurator}
                            form={form}
                            cluster={cluster}
                            onClusterSelect={onClusterSelect}
                            clusterPreselected={clusterPreselected}
                            size="small"
                        />
                        {configurator.available('cloudUpload') && (
                            <Row gutter={[24, 0]}>
                                <Col xs={24} sm={24} md={12}>
                                    <FormItemInlineSwitch
                                        justify
                                        noMargin
                                        name="cloudUpload"
                                        label="Upload backup to cloud"
                                        valuePropName="checked"
                                    />
                                </Col>
                            </Row>
                        )}
                    </div>
                );
        }
    }, [cluster]);

    return (
        <div className="BackupConfigurationForm">
            <Row gutter={[24, 0]}>
                <Col span={24} key="title">
                    <h3>Configuration</h3>
                </Col>
            </Row>
            {configurationForm}
        </div>
    );
};

export function getBackupConfigurationValidate(form: FormInstance) {
    return [
        'method',
        'host',
        'clusterId',
        'elasticRepository',
        'scheduleName',
        () => {
            const method: CcBackupMethod = form.getFieldValue('method');
            if (
                ![
                    CcBackupMethod.MONGODB_PERCONA,
                    CcBackupMethod.PGBACKREST_FULL,
                    CcBackupMethod.PGBACKREST_INC,
                    CcBackupMethod.PGBACKREST_DIFF,
                ].includes(method)
            ) {
                return;
            }
            const toolStatus: InstallBackupToolStatus = form.getFieldValue(
                'toolStatus'
            );

            if (toolStatus === InstallBackupToolStatus.FAILED) {
                throw new Error(
                    'Installing backup tool has failed, please try again or reset job progress!'
                );
            }
            if (toolStatus === InstallBackupToolStatus.PROGRESS) {
                throw new Error(
                    'You have initiated installing backup tool, please finish or cancel the flow!'
                );
            }
            if (toolStatus === InstallBackupToolStatus.REINSTALL) {
                throw new Error(
                    'Reconfigure backup tool is required for this backup method!'
                );
            }
            if (toolStatus === InstallBackupToolStatus.UNINSTALLED) {
                throw new Error(
                    'Installing backup tool is required for this backup method!'
                );
            }
        },
    ];
}

export default BackupConfigurationForm;
