import React from 'react';
import './ActionsMenu.less';
import ActionMenu from '@severalnines/bar-frontend-components/build/lib/Navigation/ActionMenu';
import classNames from 'classnames';
import {
    ActionMenuItem,
    ActionMenuProps,
} from '@severalnines/bar-frontend-components/build/lib/Navigation/ActionMenu';
import { SizeType } from 'antd/es/config-provider/SizeContext';
import Button, { ButtonProps } from 'antd/lib/button';
import TypographyText from '../TypographyText';

export default ActionsMenu;

export type ActionsMenuProps = ActionMenuProps & {
    size?: SizeType;
    onActionPerformed?: (action?: any) => void;
    items?: ActionMenuItem[];
    excludeKeys?: string[];
    bordered?: boolean;
    disabled?: boolean;
};

function ActionsMenu({
    children,
    className,
    size = 'middle',
    items,
    excludeKeys,
    moreButtonProps = {},
    bordered = true,
    disabled,
    ...rest
}: ActionsMenuProps) {
    const menuItems = (items || []).filter(
        (i: any) =>
            !!i &&
            (!excludeKeys || (excludeKeys && !excludeKeys.includes(i.key)))
    );

    const {
        className: moreButtonClassName,
        ...restMoreButtonProps
    } = moreButtonProps;

    const buttonProps: ButtonProps = {
        className: classNames(
            'ActionsMenu_more-button',
            { 'ActionsMenu_more-button--bordered': bordered },
            moreButtonClassName
        ),
        type: 'link',
        size: size,
        ...restMoreButtonProps,
    };

    return disabled ? (
        <div
            className={classNames(
                'ActionsMenu',
                className,
                `ActionsMenu--size-${size}`,
                'ActionsMenu--disabled'
            )}
        >
            <Button disabled={true} {...buttonProps}>
                <TypographyText muted={true}>
                    {children || '...'}
                </TypographyText>
            </Button>
        </div>
    ) : (
        <ActionMenu
            moreItems={menuItems as ActionMenuItem[]}
            className={classNames(
                'ActionsMenu',
                className,
                `ActionsMenu--size-${size}`
            )}
            dropdownProps={{
                overlayClassName: classNames('ActionsMenu_overlay'),
            }}
            moreButtonProps={buttonProps}
            {...rest}
        >
            {children || '...'}
        </ActionMenu>
    );
}
