import { ButtonProps } from 'antd/lib/button';
import { Button, Tooltip } from 'antd';
import React from 'react';
import { TooltipProps } from 'antd/lib/tooltip';

export default ButtonWithTooltip;
type ButtonWithTooltipProps = ButtonProps & {
    tooltipProps?: TooltipProps;
};

function ButtonWithTooltip({
    tooltipProps,
    disabled,
    ...rest
}: ButtonWithTooltipProps) {
    const localTooltipProps: any = {
        ...tooltipProps,
        onClick: (e: any) => {
            if (disabled) {
                e.stopPropagation();
            }
        },
    };
    return tooltipProps ? (
        <Tooltip {...localTooltipProps}>
            <Button disabled={disabled} {...rest} />
        </Tooltip>
    ) : (
        <Button disabled={disabled} {...rest} />
    );
}
