import React from 'react';
import { ButtonProps } from 'antd/lib/button';
import CcCluster from '../../../services/models/CcCluster';
import { CmonJobInstanceCommand } from '../../../services/cmon/models/CmonJobInstance';
import ButtonWithForm from '../../../common/General/ButtonWithForm';
import JobActionButton from '../../Jobs/JobActionButton';
import ProxySqlSynchronizeInstancesFrom from './ProxySqlSynchronizeInstancesFrom';

export default ProxySqlSynchronizeInstancesButton;

export type ProxySqlSynchronizeInstancesButtonProps = ButtonProps & {
    cluster: CcCluster;
    children?: React.ReactNode;
    onSuccess?: () => void;
};

function ProxySqlSynchronizeInstancesButton({
    cluster,
    children,
    onSuccess,
    ...rest
}: ProxySqlSynchronizeInstancesButtonProps) {
    return (
        <ButtonWithForm
            button={
                <JobActionButton
                    command={
                        CmonJobInstanceCommand.PROXYSQL.toLowerCase() as CmonJobInstanceCommand
                    }
                    clusterId={cluster?.clusterId}
                    {...rest}
                >
                    {children || 'Synchronize Instances'}
                </JobActionButton>
            }
            form={
                <ProxySqlSynchronizeInstancesFrom
                    cluster={cluster}
                    onSuccess={onSuccess}
                />
            }
        />
    );
}
