import React, { useContext } from 'react';
import { ClusterConfigContext } from '../Clusters/Config/ClusterConfigContext';
import AppEmpty from '../../common/Feedback/AppEmpty';
import CcCluster from '../../services/models/CcCluster';
import QueryMonitorSettingsButton from './QueryMonitorSettingsButton';
import AppSpin from '../../common/General/AppSpin';

export default QueryMonitorEnabledWrapper;

export type QueryMonitorEnabledWrapperProps = {
    loading?: boolean;
    cluster: CcCluster;
    children?: React.ReactNode;
};

function QueryMonitorEnabledWrapper({
    loading,
    cluster,
    children,
}: QueryMonitorEnabledWrapperProps) {
    const { configGroupedLoading, configGrouped } = useContext(
        ClusterConfigContext
    );

    return (
        <AppSpin spinning={loading || configGroupedLoading}>
            {!configGrouped?.sampling?.enable_query_monitor?.current_value ? (
                <AppEmpty
                    description="Query monitor is not enabled. Enable it from query monitor settings."
                    loading={loading || configGroupedLoading}
                    extra={<QueryMonitorSettingsButton cluster={cluster} />}
                />
            ) : null}

            {!!configGrouped?.sampling?.enable_query_monitor?.current_value
                ? children
                : null}
        </AppSpin>
    );
}
