const mock = {
    class_name: 'CmonClusterInfo',
    cdt_path: '/',
    acl: 'user::rwx,group::rwx,other::---',
    tags: ['redis6', 'sentinel', 'dev'],
    cluster_auto_recovery: true,
    cluster_id: 8,
    cluster_name: 'redis6sentinel',
    cluster_type: 'REDIS',
    configuration_file: '/etc/cmon.d/cmon_4.cnf',
    effective_privileges: 'rwx',
    log_file: '/var/log/cmon_4.log',
    maintenance_mode_active: false,
    managed: true,
    node_auto_recovery: true,
    rpc_version: '2.0',
    state: 'STARTED',
    status_text: 'All nodes are operational.',
    vendor: 'redis',
    version: '',
    alarm_statistics: {
        class_name: 'CmonAlarmStatistics',
        cluster_id: 4,
        created_after: null,
        critical: 4,
        reported_after: null,
        warning: 1,
    },
    group_owner: {
        class_name: 'CmonGroup',
        cdt_path: '/groups',
        owner_user_id: 1,
        owner_user_name: 'system',
        owner_group_id: 1,
        owner_group_name: 'admins',
        acl: 'user::rwx,group::rwx,other::---',
        created: '2021-04-28T22:57:55.328Z',
        group_id: 1,
        group_name: 'admins',
    },
    hosts: [
        {
            class_name: 'CmonRedisHost',
            cdt_path: '/redis6sentinel',
            owner_user_id: 5,
            owner_user_name: 'ccadmin',
            owner_group_id: 1,
            owner_group_name: 'admins',
            acl: 'user::rwx,group::rwx,other::---',
            clusterid: 4,
            configfile: '/etc/redis/redis.conf',
            connected: true,
            container: true,
            datadir: '/var/lib/redis',
            description: 'All is fine.',
            hostId: 20,
            hostname: '10.0.8.30',
            hoststatus: 'CmonHostOnline',
            ip: '10.0.8.30',
            lastseen: 1639665211,
            logfile: '/var/log/redis/redis-server.log',
            maintenance_mode: 'none',
            maintenance_mode_active: false,
            message: 'Up and running.',
            nodetype: 'redis',
            pidfile: '/var/run/redis/redis-server.pid',
            pingstatus: 1,
            pingtime: 95,
            port: 6379,
            role: 'master',
            sshfailcount: 6,
            statereason: 'Up and running.',
            status: 10,
            timestamp: 1639665472,
            unique_id: 19,
            uptime: 3203743,
            version: '6.0.6',
            wallclock: 1639664963,
            wallclocktimestamp: 1639664963,
            distribution: {
                name: 'ubuntu',
                codename: 'bionic',
                release: '18.04',
                type: 'debian',
            },
            redis: {
                appendlogname: 'appendonly.aof',
                appendonly: true,
                dbfilename: 'dump.rdb',
            },
            replication_master: {
                slave_info: {
                    slaves: [
                        {
                            ip: '10.0.8.31',
                            lag: 83,
                            offset: 634815502,
                            port: 6379,
                            state: 'online',
                        },
                    ],
                },
            },
        },
        {
            class_name: 'CmonRedisHost',
            cdt_path: '/redis6sentinel',
            owner_user_id: 5,
            owner_user_name: 'ccadmin',
            owner_group_id: 1,
            owner_group_name: 'admins',
            acl: 'user::rwx,group::rwx,other::---',
            clusterid: 4,
            configfile: '/etc/redis/redis.conf',
            connected: true,
            container: true,
            datadir: '/var/lib/redis',
            description: '',
            hostId: 21,
            hostname: '10.0.8.31',
            hoststatus: 'CmonHostOnline',
            ip: '10.0.8.31',
            lastseen: 1639665211,
            logfile: '/var/log/redis/redis-server.log',
            maintenance_mode: 'none',
            maintenance_mode_active: false,
            message: 'Up and running.',
            nodetype: 'redis',
            pidfile: '/var/run/redis/redis-server.pid',
            pingstatus: 1,
            pingtime: 54,
            port: 6379,
            readonly: true,
            role: 'slave',
            sshfailcount: 6,
            statereason: 'Up and running.',
            status: 10,
            timestamp: 1639665473,
            unique_id: 20,
            uptime: 3203742,
            version: '6.0.6',
            wallclock: 1639664963,
            wallclocktimestamp: 1639664963,
            distribution: {
                name: 'ubuntu',
                codename: 'bionic',
                release: '18.04',
                type: 'debian',
            },
            redis: {
                appendlogname: 'appendonly.aof',
                appendonly: true,
                dbfilename: 'dump.rdb',
            },
            replication_slave: {
                linkstatus: 1,
                master_host: '10.0.8.30',
                master_port: '6379',
                seconds_behind_master: 83,
                status: 'up',
            },
        },
        {
            class_name: 'CmonRedisHost',
            cdt_path: '/redis6sentinel',
            owner_user_id: 5,
            owner_user_name: 'ccadmin',
            owner_group_id: 1,
            owner_group_name: 'admins',
            acl: 'user::rwx,group::rwx,other::---',
            clusterid: 4,
            configfile: '/etc/redis/redis.conf',
            connected: true,
            container: true,
            datadir: '/var/lib/redis',
            description: '',
            hostId: 22,
            hostname: '10.0.8.32',
            hoststatus: 'CmonHostOnline',
            ip: '10.0.8.32',
            lastseen: 1639665106,
            logfile: '/var/log/redis/redis-server.log',
            maintenance_mode: 'none',
            maintenance_mode_active: false,
            message: 'Up and running.',
            nodetype: 'redis',
            pidfile: '/var/run/redis/redis-server.pid',
            pingstatus: 1,
            pingtime: 52,
            port: 6379,
            readonly: true,
            role: 'slave',
            sshfailcount: 6,
            statereason: 'Up and running.',
            status: 10,
            timestamp: 1639665473,
            unique_id: 21,
            uptime: 3203636,
            version: '6.0.6',
            wallclock: 1639664963,
            wallclocktimestamp: 1639664963,
            distribution: {
                name: 'ubuntu',
                codename: 'bionic',
                release: '18.04',
                type: 'debian',
            },
            redis: {
                appendlogname: 'appendonly.aof',
                appendonly: true,
                dbfilename: 'dump.rdb',
            },
            replication_slave: {
                linkstatus: 1,
                master_host: '10.0.8.30',
                master_port: '6379',
                seconds_behind_master: 0,
                status: 'up',
            },
        },
        {
            class_name: 'CmonRedisSentinelHost',
            cdt_path: '/redis6sentinel',
            owner_user_id: 5,
            owner_user_name: 'ccadmin',
            owner_group_id: 1,
            owner_group_name: 'admins',
            acl: 'user::rwx,group::rwx,other::---',
            clusterid: 4,
            configfile: '/etc/redis/sentinel.conf',
            connected: true,
            container: true,
            datadir: '/var/lib/redis',
            description: 'All is fine.',
            hostId: 20,
            hostname: '10.0.8.30',
            hoststatus: 'CmonHostOnline',
            ip: '10.0.8.30',
            lastseen: 1639665210,
            maintenance_mode: 'none',
            maintenance_mode_active: false,
            message: 'Up and running.',
            nodetype: 'sentinel',
            pingstatus: 1,
            pingtime: 95,
            port: 26379,
            sshfailcount: 6,
            statereason: 'Up and running.',
            status: 10,
            timestamp: 1639665472,
            unique_id: 22,
            uptime: 3203742,
            version: '6.0.6',
            wallclock: 1639664963,
            wallclocktimestamp: 1639664963,
            distribution: {
                name: 'ubuntu',
                codename: 'bionic',
                release: '18.04',
                type: 'debian',
            },
            sentinel: {
                master_info: [
                    {
                        name: 'cluster_4',
                        address: '10.0.8.30:6379',
                        id: 'primary0',
                        sentinels: 3,
                        slaves: 2,
                        status: 'ok',
                    },
                ],
            },
        },
        {
            class_name: 'CmonRedisSentinelHost',
            cdt_path: '/redis6sentinel',
            owner_user_id: 5,
            owner_user_name: 'ccadmin',
            owner_group_id: 1,
            owner_group_name: 'admins',
            acl: 'user::rwx,group::rwx,other::---',
            clusterid: 4,
            configfile: '/etc/redis/sentinel.conf',
            connected: true,
            container: true,
            datadir: '/var/lib/redis',
            description: 'All is fine.',
            hostId: 21,
            hostname: '10.0.8.31',
            hoststatus: 'CmonHostOnline',
            ip: '10.0.8.31',
            lastseen: 1639665211,
            maintenance_mode: 'none',
            maintenance_mode_active: false,
            message: 'Up and running.',
            nodetype: 'sentinel',
            pingstatus: 1,
            pingtime: 54,
            port: 26379,
            sshfailcount: 6,
            statereason: 'Up and running.',
            status: 10,
            timestamp: 1639665473,
            unique_id: 23,
            uptime: 3203742,
            version: '6.0.6',
            wallclock: 1639664963,
            wallclocktimestamp: 1639664963,
            distribution: {
                name: 'ubuntu',
                codename: 'bionic',
                release: '18.04',
                type: 'debian',
            },
            sentinel: {
                master_info: [
                    {
                        name: 'cluster_4',
                        address: '10.0.8.30:6379',
                        id: 'primary0',
                        sentinels: 3,
                        slaves: 2,
                        status: 'ok',
                    },
                ],
            },
        },
        {
            class_name: 'CmonRedisSentinelHost',
            cdt_path: '/redis6sentinel',
            owner_user_id: 5,
            owner_user_name: 'ccadmin',
            owner_group_id: 1,
            owner_group_name: 'admins',
            acl: 'user::rwx,group::rwx,other::---',
            clusterid: 4,
            configfile: '/etc/redis/sentinel.conf',
            connected: true,
            container: true,
            datadir: '/var/lib/redis',
            description: 'All is fine.',
            hostId: 22,
            hostname: '10.0.8.32',
            hoststatus: 'CmonHostOnline',
            ip: '10.0.8.32',
            lastseen: 1639665211,
            maintenance_mode: 'none',
            maintenance_mode_active: false,
            message: 'Up and running.',
            nodetype: 'sentinel',
            pingstatus: 1,
            pingtime: 52,
            port: 26379,
            sshfailcount: 6,
            statereason: 'Up and running.',
            status: 10,
            timestamp: 1639665473,
            unique_id: 24,
            uptime: 3203741,
            version: '6.0.6',
            wallclock: 1639664963,
            wallclocktimestamp: 1639664963,
            distribution: {
                name: 'ubuntu',
                codename: 'bionic',
                release: '18.04',
                type: 'debian',
            },
            sentinel: {
                master_info: [
                    {
                        name: 'cluster_4',
                        address: '10.0.8.30:6379',
                        id: 'primary0',
                        sentinels: 3,
                        slaves: 2,
                        status: 'sdown',
                    },
                ],
            },
        },
        {
            class_name: 'CmonHost',
            cdt_path: '/redis6sentinel',
            owner_user_id: 5,
            owner_user_name: 'ccadmin',
            owner_group_id: 1,
            owner_group_name: 'admins',
            acl: 'user::rwx,group::rwx,other::---',
            clusterid: 4,
            configfile: '/etc/cmon.d/cmon_4.cnf',
            connected: true,
            container: false,
            hostId: 26,
            hostname: '143.198.228.20',
            hoststatus: 'CmonHostOnline',
            ip: '143.198.228.20',
            lastseen: 1639665473,
            logfile: '/var/log/cmon_4.log',
            maintenance_mode: 'none',
            maintenance_mode_active: false,
            message: 'Up and running.',
            nodetype: 'controller',
            pid: 31827,
            pingstatus: -1,
            pingtime: -1,
            port: 9500,
            role: 'controller',
            status: 10,
            timestamp: 1639665473,
            unique_id: 25,
            uptime: 162496,
            version: '1.9.2.5009',
            wallclock: 1639665471,
            wallclocktimestamp: 1639665471,
            distribution: {
                name: 'ubuntu',
                codename: 'bionic',
                release: '18.04',
                type: 'debian',
            },
        },
        {
            class_name: 'CmonPrometheusHost',
            cdt_path: '/redis6sentinel',
            owner_user_id: 5,
            owner_user_name: 'ccadmin',
            owner_group_id: 1,
            owner_group_name: 'admins',
            acl: 'user::rwx,group::rwx,other::---',
            clusterid: 4,
            configfile: '/etc/prometheus/prometheus.yml',
            container: false,
            data_retention: '15d',
            data_retention_size: '0',
            datadir: '/var/lib/prometheus',
            hostId: 26,
            hostname: '143.198.228.20',
            hoststatus: 'CmonHostOnline',
            ip: '143.198.228.20',
            lastseen: 1639665473,
            logfile: '/var/log/prometheus/prometheus.log',
            maintenance_mode: 'none',
            maintenance_mode_active: false,
            message: "Process 'prometheus' is running.",
            nodetype: 'prometheus',
            pid: 16460,
            pingstatus: -1,
            pingtime: -1,
            port: 9090,
            scrape_interval: '10s',
            statereason: 'CmonProcessManager::prometheusFound()',
            status: 10,
            timestamp: 1639665473,
            unique_id: 63,
            version: '2.29.2',
            wallclock: 1639665471,
            wallclocktimestamp: 1639665471,
            configuration: [
                {
                    arguments: '',
                    job: 'prometheus',
                    scrape_interval: '2s',
                },
                {
                    arguments: '',
                    job: 'node',
                    scrape_interval: '10s',
                },
                {
                    arguments: '',
                    job: 'process',
                    scrape_interval: '10s',
                },
                {
                    arguments:
                        ' --collect.perf_schema.eventswaits  --collect.perf_schema.file_events  --collect.perf_schema.file_instances  --collect.perf_schema.indexiowaits  --collect.perf_schema.tableiowaits  --collect.perf_schema.tablelocks  --collect.info_schema.tablestats  --collect.info_schema.processlist  --collect.info_schema.userstats  --collect.binlog_size  --collect.global_status  --collect.global_variables  --collect.info_schema.innodb_metrics  --collect.slave_status ',
                    job: 'mysqld',
                    scrape_interval: '10s',
                },
                {
                    arguments: '',
                    job: 'proxysql',
                    scrape_interval: '10s',
                },
                {
                    arguments: '',
                    job: 'haproxy',
                    scrape_interval: '10s',
                },
                {
                    arguments: '',
                    job: 'redis',
                    scrape_interval: '10s',
                },
                {
                    arguments: '',
                    job: 'mssql',
                    scrape_interval: '10s',
                },
            ],
            distribution: {
                name: 'ubuntu',
                codename: 'bionic',
                release: '18.04',
                type: 'debian',
            },
            exporters: [
                {
                    address: '10.0.8.30:9011',
                    job: 'process',
                    monitored_host: '',
                    up: true,
                    version: '0.10.10',
                },
                {
                    address: '10.0.8.30:9100',
                    job: 'node',
                    monitored_host: '',
                    up: false,
                    version: '1.0.1',
                },
                {
                    address: '10.0.8.31:9011',
                    job: 'process',
                    monitored_host: '',
                    up: true,
                    version: '0.10.10',
                },
                {
                    address: '10.0.8.31:9100',
                    job: 'node',
                    monitored_host: '',
                    up: false,
                    version: '1.0.1',
                },
                {
                    address: '10.0.8.32:9011',
                    job: 'process',
                    monitored_host: '',
                    up: false,
                    version: '0.10.10',
                },
                {
                    address: '10.0.8.32:9100',
                    job: 'node',
                    monitored_host: '',
                    up: false,
                    version: '1.0.1',
                },
                {
                    address: '143.198.228.20:9011',
                    job: 'process',
                    monitored_host: '',
                    up: true,
                    version: '0.10.10',
                },
                {
                    address: '143.198.228.20:9100',
                    job: 'node',
                    monitored_host: '',
                    up: true,
                    version: '1.0.1',
                },
            ],
        },
    ],
    job_statistics: {
        class_name: 'CmonJobStatistics',
        cluster_id: 4,
        by_state: {
            ABORTED: 0,
            DEFINED: 0,
            DEQUEUED: 0,
            FAILED: 0,
            FINISHED: 0,
            RUNNING: 0,
        },
    },
    owner: {
        class_name: 'CmonUser',
        cdt_path: '/',
        owner_user_id: 5,
        owner_user_name: 'ccadmin',
        owner_group_id: 1,
        owner_group_name: 'admins',
        acl: 'user::rwx,group::r--,other::r--',
        created: '2021-04-28T23:00:42.821Z',
        disabled: false,
        email_address: 'ccadmin@localhost.xyz',
        first_name: '',
        last_failed_login: '',
        last_login: '2021-12-16T13:32:48.781Z',
        last_name: '',
        n_failed_logins: 0,
        origin: 'CmonDb',
        suspended: false,
        user_id: 5,
        user_name: 'ccadmin',
        groups: [
            {
                class_name: 'CmonGroup',
                cdt_path: '/groups',
                owner_user_id: 1,
                owner_user_name: 'system',
                owner_group_id: 1,
                owner_group_name: 'admins',
                acl: 'user::rwx,group::rwx,other::---',
                created: '2021-04-28T22:57:55.328Z',
                group_id: 1,
                group_name: 'admins',
            },
        ],
        timezone: {
            class_name: 'CmonTimeZone',
            name: 'Coordinated Universal Time',
            abbreviation: 'UTC',
            offset: 0,
            use_dst: false,
        },
    },
};
export default mock;
