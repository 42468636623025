import React from 'react';
import TimeRange from '../common/DataEntry/TimeRange';
import { Divider } from 'antd';

export default DebugTimeRange;

function DebugTimeRange() {
    return (
        <div>
            <div>
                <TimeRange />
            </div>
            <div>
                <Divider
                    plain={true}
                    orientation="left"
                    style={{ marginBottom: '0px', marginTop: '15px' }}
                >
                    with 24 hours selected
                </Divider>
                <TimeRange range={86400} />
            </div>
            <div>
                <Divider
                    plain={true}
                    orientation="left"
                    style={{ marginBottom: '0px', marginTop: '15px' }}
                >
                    with date time
                </Divider>
                <TimeRange
                    range={0}
                    dateFrom="2021-10-01 22:22:22"
                    dateTo="2021-10-02 10:00:00"
                />
            </div>
        </div>
    );
}
