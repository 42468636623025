import React from 'react';
import { FormInstance } from 'antd/lib/form';
import CloudCredentialsAuthenticationForm, {
    filterProviderCredentialsFields,
    getCloudCredentialsAuthenticateValidate,
} from './CloudCredentialsAuthenticationForm';
import CloudCredentialsWizardSummary from './CloudCredentialsWizardSummary';
import WizardForm from '@severalnines/bar-frontend-components/build/lib/Navigation/WizardForm';
import WizardFormConfiguration from '@severalnines/bar-frontend-components/build/lib/Navigation/Wizard/WizardFormConfiguration';
import CcCloudCredentials, {
    CcCloudCredentialsDTO,
    CcCloudCredentialsFields,
    CcCloudProviderType,
} from '../../../services/models/CcCloudCredentials';

export default CloudCredentialsWizardForm;

type CloudCredentialsProviderFormFields = {
    [key in keyof CcCloudProviderType]?: CcCloudCredentialsFields;
};
export type CloudCredentialsFormFields = CloudCredentialsProviderFormFields & {
    id?: number;
    name?: string;
    comment?: string;
};

export enum CloudCredentialsWizardFormStep {
    AUTH = 'auth',
    SUMMARY = 'summary',
}

export type CloudCredentialsWizardFormProps = {
    form: FormInstance<CloudCredentialsFormFields>;
    serviceType: CcCloudProviderType;
    defaultInitialValues: CloudCredentialsFormFields;
    credentials?: CcCloudCredentials;
    saveMode?: boolean;
    loading?: boolean;
    activeStep?: string;
    onCancel?: () => void;
    onSubmit?: (dto: CcCloudCredentialsDTO) => void;
    onTouchedChange?: (touched: boolean) => void;
};

function CloudCredentialsWizardForm({
    form,
    serviceType,
    defaultInitialValues,
    saveMode,
    loading = false,
    activeStep,
    onCancel,
    onSubmit,
    onTouchedChange,
}: CloudCredentialsWizardFormProps) {
    const handleSubmit = () => {
        onSubmit?.(
            getFormFieldsCredentialsDTO(form.getFieldsValue(true), serviceType)
        );
    };
    return (
        <WizardFormConfiguration
            form={form}
            onSubmit={handleSubmit}
            activeKey={activeStep}
            initialValues={defaultInitialValues}
            loading={loading}
            footerExtra={null}
            saveMode={saveMode}
            showCancelButton
            cancelButtonText={saveMode ? 'Cancel' : 'Back'}
            onCancel={onCancel}
            onTouchedChange={onTouchedChange}
            steps={[
                <WizardForm.Step
                    key={CloudCredentialsWizardFormStep.AUTH}
                    title="Authentication"
                    subTitle=" "
                    validate={getCloudCredentialsAuthenticateValidate(
                        serviceType,
                        form
                    )}
                >
                    <CloudCredentialsAuthenticationForm
                        form={form}
                        type={serviceType}
                    />
                </WizardForm.Step>,
                <WizardForm.Step
                    key={CloudCredentialsWizardFormStep.SUMMARY}
                    title="Preview"
                    subTitle=" "
                >
                    <CloudCredentialsWizardSummary
                        form={form}
                        type={serviceType}
                    />
                </WizardForm.Step>,
            ]}
        />
    );
}

export function getCredentialsItemFormValues(
    item: CcCloudCredentials
): CloudCredentialsFormFields {
    return {
        id: item.id,
        name: item.name,
        comment: item.comment,
        [item.provider]: item.credentials,
    } as CloudCredentialsFormFields;
}

export function getFormFieldsCredentialsDTO(
    fields: CloudCredentialsFormFields,
    provider: CcCloudProviderType
): CcCloudCredentialsDTO {
    return {
        id: fields.id,
        name: fields.name || '',
        comment: fields.comment || '',
        provider: provider,
        credentials: filterProviderCredentialsFields(
            provider,
            fields[provider as any] || {}
        ),
    };
}
