import React from 'react';
import {
    AlertOutlined,
    DatabaseOutlined,
    ClusterOutlined,
    ControlOutlined,
    RobotOutlined,
    UsbOutlined,
    FundViewOutlined,
    ExperimentOutlined,
    ProfileOutlined,
    MessageOutlined,
    SettingOutlined,
    ScheduleOutlined,
    HddOutlined,
    AppstoreOutlined,
    CalendarOutlined,
    SyncOutlined,
    DeploymentUnitOutlined,
    CloudOutlined,
    LineChartOutlined,
    ToolOutlined,
    ReloadOutlined,
    SearchOutlined,
    UserOutlined,
    LinkOutlined,
} from '@ant-design/icons';
import AppIcon, { AppIconProps } from './AppIcon';

export function ControllersIcon(props: Omit<AppIconProps, 'icon'>) {
    return <AppIcon icon={<ControlOutlined />} {...props} />;
}

export function DashboardIcon(props: Omit<AppIconProps, 'icon'>) {
    return <AppIcon icon={<AppstoreOutlined />} {...props} />;
}

export function ClustersIcon(props: Omit<AppIconProps, 'icon'>) {
    return <AppIcon icon={<ClusterOutlined />} {...props} />;
}

export function ActivityIcon(props: Omit<AppIconProps, 'icon'>) {
    return <AppIcon icon={<FundViewOutlined />} {...props} />;
}

export function AlarmsIcon(props: Omit<AppIconProps, 'icon'>) {
    return <AppIcon icon={<AlertOutlined />} {...props} />;
}

export function SyncIcon(props: Omit<AppIconProps, 'icon'>) {
    return <AppIcon icon={<SyncOutlined />} {...props} />;
}

export function BackupsIcon(props: Omit<AppIconProps, 'icon'>) {
    return <AppIcon icon={<UsbOutlined />} {...props} />;
}
export function UserManagementIcon(props: Omit<AppIconProps, 'icon'>) {
    return <AppIcon icon={<UserOutlined />} {...props} />;
}

export function NodesIcon(props: Omit<AppIconProps, 'icon'>) {
    return <AppIcon icon={<DatabaseOutlined />} {...props} />;
}
export function JobsIcon(props: Omit<AppIconProps, 'icon'>) {
    return <AppIcon icon={<ExperimentOutlined />} {...props} />;
}
export function LogsIcon(props: Omit<AppIconProps, 'icon'>) {
    return <AppIcon icon={<ProfileOutlined />} {...props} />;
}
export function AutoRecoveryIcon(props: Omit<AppIconProps, 'icon'>) {
    return <AppIcon icon={<RobotOutlined />} {...props} />;
}
export function DashboardsIcon(props: Omit<AppIconProps, 'icon'>) {
    return <AppIcon icon={<LineChartOutlined />} {...props} />;
}
export function FeedbackIcon(props: Omit<AppIconProps, 'icon'>) {
    return <AppIcon icon={<MessageOutlined />} {...props} />;
}

export function SettingsIcon(props: Omit<AppIconProps, 'icon'>) {
    return <AppIcon icon={<SettingOutlined />} {...props} />;
}
export function LdapSettingsIcon(props: Omit<AppIconProps, 'icon'>) {
    return <AppIcon icon={<SettingOutlined />} {...props} />;
}
export function LdapGroupMapIcon(props: Omit<AppIconProps, 'icon'>) {
    return <AppIcon icon={<LinkOutlined />} {...props} />;
}
export function ReloadIcon(props: Omit<AppIconProps, 'icon'>) {
    return <AppIcon icon={<ReloadOutlined />} {...props} />;
}

export function StorageIcon(props: Omit<AppIconProps, 'icon'>) {
    return <AppIcon icon={<HddOutlined />} {...props} />;
}

export function VerifyBackupIcon(props: Omit<AppIconProps, 'icon'>) {
    return <AppIcon icon={<ScheduleOutlined />} {...props} />;
}

export function ScheduleBackupIcon(props: Omit<AppIconProps, 'icon'>) {
    return <AppIcon icon={<CalendarOutlined />} {...props} />;
}

export function AdvancedBackupSettingsIcon(props: Omit<AppIconProps, 'icon'>) {
    return <AppIcon icon={<ControlOutlined />} {...props} />;
}

export function SearchIcon(props: Omit<AppIconProps, 'icon'>) {
    return <AppIcon icon={<SearchOutlined />} {...props} />;
}

export function ControllerIcon(props: Omit<AppIconProps, 'icon'>) {
    return <AppIcon icon="controller" {...props} />;
}
export function RedisIcon(props: Omit<AppIconProps, 'icon'>) {
    return <AppIcon icon="redis" {...props} />;
}
export function MysqlIcon(props: Omit<AppIconProps, 'icon'>) {
    return <AppIcon icon="mysql" {...props} />;
}
export function MariadbIcon(props: Omit<AppIconProps, 'icon'>) {
    return <AppIcon icon="mariadb" {...props} />;
}
export function PerconaIcon(props: Omit<AppIconProps, 'icon'>) {
    return <AppIcon icon="percona" {...props} />;
}
export function PostgresqlIcon(props: Omit<AppIconProps, 'icon'>) {
    return <AppIcon icon="postgresql" {...props} />;
}
export function TimescaledbIcon(props: Omit<AppIconProps, 'icon'>) {
    return <AppIcon icon="timescaledb" {...props} />;
}
export function MongodbIcon(props: Omit<AppIconProps, 'icon'>) {
    return <AppIcon icon="mongodb" {...props} />;
}
export function CodershipIcon(props: Omit<AppIconProps, 'icon'>) {
    return <AppIcon icon="codership" {...props} />;
}
export function MsSQLIcon(props: Omit<AppIconProps, 'icon'>) {
    return <AppIcon icon="mssql" {...props} />;
}
export function PrometheusIcon(props: Omit<AppIconProps, 'icon'>) {
    return <AppIcon icon="prometheus" {...props} />;
}
export function HaProxyIcon(props: Omit<AppIconProps, 'icon'>) {
    return <AppIcon icon="haproxy" {...props} />;
}
export function ReplicationIcon(props: Omit<AppIconProps, 'icon'>) {
    return <AppIcon icon={<DeploymentUnitOutlined />} {...props} />;
}

export function ElasticsearchIcon(props: Omit<AppIconProps, 'icon'>) {
    return <AppIcon icon="elastic" {...props} />;
}

export function MaintenanceIcon(props: Omit<AppIconProps, 'icon'>) {
    return <AppIcon icon={<ToolOutlined />} {...props} />;
}

export function CloudIcon(props: Omit<AppIconProps, 'icon'>) {
    return <AppIcon icon={<CloudOutlined />} {...props} />;
}

export function CloudAwsIcon(props: Omit<AppIconProps, 'icon'>) {
    return <AppIcon icon="cloud-aws" {...props} />;
}

export function CloudAzureIcon(props: Omit<AppIconProps, 'icon'>) {
    return <AppIcon icon="cloud-azure" {...props} />;
}
export function CloudGoogleIcon(props: Omit<AppIconProps, 'icon'>) {
    return <AppIcon icon="cloud-google" {...props} />;
}

export function NotificationPagerDutyIcon(props: Omit<AppIconProps, 'icon'>) {
    return <AppIcon icon="pagerduty" {...props} />;
}

export function NotificationSlackIcon(props: Omit<AppIconProps, 'icon'>) {
    return <AppIcon icon="slack" {...props} />;
}

export function NotificationServiceNowIcon(props: Omit<AppIconProps, 'icon'>) {
    return <AppIcon icon="now" {...props} />;
}

export function NotificationTelegramIcon(props: Omit<AppIconProps, 'icon'>) {
    return <AppIcon icon="telegram" {...props} />;
}

export function NotificationVictorOpsIcon(props: Omit<AppIconProps, 'icon'>) {
    return <AppIcon icon="victoropts" {...props} />;
}

export function NotificationOpsGenieIcon(props: Omit<AppIconProps, 'icon'>) {
    return <AppIcon icon="opsgenie" {...props} />;
}

export function NotificationWebhookIcon(props: Omit<AppIconProps, 'icon'>) {
    return <AppIcon icon="webhooks" {...props} />;
}
