import useListFetch from '../../../common/useListFetch';
import CmonCloudService from '../../../services/cmon/CmonCloudService';
import {
    CcCloudBucket,
    CcCloudProviderType,
} from '../../../services/models/CcCloudCredentials';

export type UseCloudBucketsProps = {
    credentialsId: number;
    provider: CcCloudProviderType;
};
export default function useCloudBuckets({
    credentialsId,
    provider,
}: UseCloudBucketsProps) {
    const {
        error,
        list: bucketList,
        loading: bucketListLoading,
        refresh,
    } = useListFetch({
        name: 'cloud-bucket-list',
        fetchFn: async ({ pageSize, page, ...rest }, opts) => {
            const {
                response: { json },
            } = await CmonCloudService.proxy(
                {
                    uri: `/${provider}/storage`,
                    credentials_id: credentialsId,
                    method: 'GET',
                    provider,
                    ...rest,
                },
                opts
            );
            if (json.error) {
                throw new Error(json.error);
            }
            return {
                list: json,
            };
        },
        cancelFn: async ({ requestId }) => {
            await CmonCloudService.cancelRequest(requestId);
        },
    });

    const list = bucketList as CcCloudBucket[] | undefined;

    return {
        error,
        list,
        refresh,
        loading: bucketListLoading,
    };
}
