


export interface CmonProxySqlHostgroupProps  {
    class_name?: string;
    comment?: string;
    reaader_hostgroup?: number;
    writer_hostgroup?: number;

}



export default class CmonProxySqlHostgroup {
    public className?: string;
    public comment?: string;
    public reaaderHostgroup?: number;
    public writerHostgroup?: number;


    constructor(props: CmonProxySqlHostgroupProps) {
        this.className = props.class_name;
        this.comment = props.comment;
        this.reaaderHostgroup = props.reaader_hostgroup;
        this.writerHostgroup = props.writer_hostgroup;

    }

}
