import React from 'react';
import ActionsMenu, {
    ActionsMenuProps,
} from '../../common/Navigation/ActionsMenu';
import CcCluster from '../../services/models/CcCluster';
import useCurrentUser from '../User/useCurrentUser';
import UninstallCmonAgentButton from './UninstallCmonAgentButton';
import CcAgentNode from '../../services/models/CcAgentNode';

export default CmonAgentActionsMenu;

export type CmonAgentActionsMenuProps = ActionsMenuProps & {
    cluster: CcCluster;
    node: CcAgentNode;
};

function CmonAgentActionsMenu({
    cluster,
    node,
    onActionPerformed,
    ...rest
}: CmonAgentActionsMenuProps) {
    const { canManageCluster } = useCurrentUser();
    const handleAlarmIgnoreSuccess = () => {
        if (onActionPerformed) {
            onActionPerformed();
        }
    };
    return (
        <ActionsMenu
            items={[
                ...(canManageCluster(cluster?.clusterId as number)
                    ? [
                          {
                              key: 'unistall',
                              waitForConfirm: true,
                              label: (
                                  <UninstallCmonAgentButton
                                      node={node}
                                      onSuccess={handleAlarmIgnoreSuccess}
                                  >
                                      Uninstall agent
                                  </UninstallCmonAgentButton>
                              ),
                          },
                      ]
                    : []),
            ]}
            {...rest}
        />
    );
}
