import React from 'react';
import { Tag } from 'antd';
import { FormInstance } from 'antd/lib/form';
import ClusterTypeFormat from '../../Clusters/ClusterTypeFormat';
import { CcClusterType } from '../../../services/models/CcCluster';
import AppDivider from '../../../common/AppDivider';
import SpaceDescriptions from '../../../common/Layout/SpaceDescriptions';
import { ClusterConfigurator } from './ClusterConfigurator';
import YesNoFormat from '../../../common/Format/YesNoFormat';
import BackupFormat from '../../Backups/BackupFormat';

export default WizardSummary;

export type DeploymentSummaryProps = {
    clusterType: CcClusterType;
    form: FormInstance;
    configurator: typeof ClusterConfigurator;
};

function WizardSummary({
    clusterType,
    form,
    configurator,
}: DeploymentSummaryProps) {
    const {
        primaryCluster,
        fromBackup,
        details,
        sshConfig,
    } = form.getFieldsValue(true);

    const extraConfingurationSummary = configurator.getExtraConfigurationSummary(
        {
            form: form,
        }
    );

    return (
        <div className="DeploymentSummary">
            {primaryCluster ? (
                <>
                    <SpaceDescriptions
                        direction="vertical"
                        title={<span>Replication</span>}
                        alignItems="right"
                    >
                        {fromBackup?.backup ? (
                            <SpaceDescriptions.Item label="Stage from">
                                <BackupFormat backup={fromBackup.backup} />
                            </SpaceDescriptions.Item>
                        ) : (
                            <SpaceDescriptions.Item label="Streaming from">
                                {primaryCluster.address}
                            </SpaceDescriptions.Item>
                        )}

                        <SpaceDescriptions.Item label="Bi-directional replication">
                            <YesNoFormat
                                booleanVar={
                                    primaryCluster.bidirectionalReplication
                                }
                            />
                        </SpaceDescriptions.Item>
                    </SpaceDescriptions>
                    <AppDivider />
                </>
            ) : null}
            <SpaceDescriptions
                direction="vertical"
                title="Names and tags"
                size="small"
                alignItems="right"
            >
                <SpaceDescriptions.Item>{details.name}</SpaceDescriptions.Item>
                <SpaceDescriptions.Item>
                    {details.tags
                        ? details.tags.map((tag: string) => (
                              <Tag key={tag}>{tag}</Tag>
                          ))
                        : 'No tags'}
                </SpaceDescriptions.Item>
            </SpaceDescriptions>
            {details.vendor && details.version ? (
                <>
                    <AppDivider />
                    <SpaceDescriptions
                        direction="vertical"
                        title="Vendor and version"
                        alignItems="right"
                    >
                        <SpaceDescriptions.Item>
                            <ClusterTypeFormat
                                type={clusterType}
                                vendor={details.vendor}
                                showIcon={true}
                                iconProps={{ size: 'medium' }}
                            />
                            &nbsp;-&nbsp;
                            {details.version}
                        </SpaceDescriptions.Item>
                    </SpaceDescriptions>
                </>
            ) : null}

            <AppDivider />
            <SpaceDescriptions
                direction="vertical"
                title="SSH configuration"
                alignItems="right"
            >
                <SpaceDescriptions.Item label="SSH user" labelStrong>
                    {sshConfig.sshUser}
                </SpaceDescriptions.Item>
                <SpaceDescriptions.Item label="SSH key path" labelStrong>
                    {sshConfig.sshKeyPath}
                </SpaceDescriptions.Item>
                <SpaceDescriptions.Item label="SSH port" labelStrong>
                    {sshConfig.sshPort}
                </SpaceDescriptions.Item>
                {sshConfig.sshSudoPassword ? (
                    <SpaceDescriptions.Item
                        label="SSH Sudo Password"
                        labelStrong
                    >
                        {sshConfig.sshSudoPassword
                            ? sshConfig.sshSudoPassword.replace(/./g, '*')
                            : null}
                    </SpaceDescriptions.Item>
                ) : null}
            </SpaceDescriptions>
            <AppDivider />
            {configurator.getNodeConfigurationSummary({
                form: form,
                hasPrimaryCluster: primaryCluster?.clusterId ? true : undefined,
            })}
            {extraConfingurationSummary ? (
                <div>
                    <AppDivider />
                    {extraConfingurationSummary}
                </div>
            ) : null}
            <AppDivider />
            {configurator.getTopologySummary(form)}
        </div>
    );
}
