import React, { useState } from 'react';
import { Col, Form, Input, Row, AutoComplete } from 'antd';
import FormFooter from '../../../../../../common/FormFooter';
import ModalDefaultForm from '../../../../../../common/ModalDefaultForm';
import {
    notifyError,
    notifyOperationSuccess,
    NotifyType,
} from '../../../../../Notifications/uiNotification';
import FormItemInlineSwitch from '../../../../../../common/DataEntry/FormItemInlineSwitch';
import InputNumberWide from '../../../../../../common/DataEntry/InputNumberWide';
import CcCluster from '../../../../../../services/models/CcCluster';
import CcProxySqlNode from '../../../../../../services/models/CcProxySqlNode';
import {
    prepareServerDataEndPoint,
    prepareInitialFormServerData,
} from './proxySQLServersHelper';
import { FetchRefreshFunctionParams } from '../../../../../../common/useFetch';
import CmonProxysqlService from '../../../../../../services/cmon/CmonProxysqlService';
import CcProxySqlServer from '../../../../../../services/models/CcProxySqlServer';

export default ProxySQLServersModal;

export type ProxySQLServersModalProps = {
    onSuccess?: () => void;
    onCancel?: () => void;
    onError?: (err: Error) => void;
    title?: string;
    cluster: CcCluster;
    node: CcProxySqlNode;
    edit?: boolean;
    serverData?: CcProxySqlServer;
    refreshServers: (
        p?: FetchRefreshFunctionParams | undefined
    ) => Promise<void>;
    hostIdList: { value: string }[] | undefined;
};
export type ProxySQLServersFormValues = {
    ProxySQLServers: {
        hostName: string;
        port: number;
        hostGroupId: string;
        weight: number;
        comment: string;
        replicationLag: number;
        maxConnections: number;
        latency: number;
    };
};

function ProxySQLServersModal({
    onSuccess,
    onCancel,
    onError,
    title = '',
    cluster,
    node,
    edit,
    serverData,
    refreshServers,
    hostIdList,
}: ProxySQLServersModalProps) {
    const [form] = Form.useForm<ProxySQLServersFormValues>();
    const [loading, setLoading] = useState<boolean>(false);

    const handleSubmit = async (values: ProxySQLServersFormValues) => {
        try {
            setLoading(true);
            if (edit) {
                await CmonProxysqlService.updatemysqlserver({
                    clusterid: cluster?.clusterId,
                    hostName: node?.hostname,
                    port: node?.port,
                    mysqlServer: prepareServerDataEndPoint(
                        values.ProxySQLServers
                    ),
                });
            } else {
                await CmonProxysqlService.insertmysqlserver({
                    clusterid: cluster?.clusterId,
                    hostName: node?.hostname,
                    port: node?.port,
                    mysqlServer: prepareServerDataEndPoint(
                        values.ProxySQLServers
                    ),
                });
            }
            refreshServers();
            notifyOperationSuccess({
                type: NotifyType.TOAST,
                title: (
                    <span>Server {edit ? 'edited' : 'added'} successfully</span>
                ),
            });
            setLoading(false);
            onSuccess?.();
        } catch (error: any) {
            setLoading(false);
            notifyError({
                content: error.message,
            });
            onError?.(error);
        }
    };

    return (
        <ModalDefaultForm
            title={title}
            form={form}
            onCancel={onCancel}
            width={900}
            defaultVisible={true}
        >
            <Form
                className="ProxySQLServersModal"
                form={form}
                layout="vertical"
                onFinish={handleSubmit}
                initialValues={
                    edit && serverData
                        ? {
                              ProxySQLServers: prepareInitialFormServerData(
                                  serverData
                              ),
                          }
                        : {
                              ProxySQLServers: {
                                  port: 3306,
                                  weight: 1,
                                  replicationLag: 10,
                                  maxConnections: 100,
                                  latency: 0,
                                  useSSL: false,
                                  compression: false,
                              },
                          }
                }
            >
                <Row gutter={[24, 0]}>
                    <Col span={12}>
                        <Form.Item
                            name={['ProxySQLServers', 'hostName']}
                            label={'Host name'}
                            rules={[
                                {
                                    required: true,
                                    message: 'Enter hostname number.',
                                },
                            ]}
                        >
                            <Input
                                disabled={edit}
                                placeholder="Click here to enter hostname."
                            ></Input>
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item
                            name={['ProxySQLServers', 'port']}
                            label={'Host port'}
                            rules={[
                                {
                                    required: true,
                                    message: 'Enter port number.',
                                },
                            ]}
                        >
                            <InputNumberWide
                                min={0}
                                disabled={edit}
                                placeholder="Click here to enter host port."
                            ></InputNumberWide>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name={['ProxySQLServers', 'hostGroupId']}
                            label={'Hostgroup Id'}
                            rules={[
                                {
                                    required: true,
                                    pattern: /^[0-9]*$/,
                                    message: 'Hostgroup Id must be number.',
                                },
                            ]}
                        >
                            <AutoComplete disabled={edit} options={hostIdList}>
                                <Input placeholder="Click here to enter Hostgroup Id."></Input>
                            </AutoComplete>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name={['ProxySQLServers', 'weight']}
                            label={'Weight'}
                        >
                            <InputNumberWide
                                min={0}
                                placeholder="Click here to enter weight."
                            ></InputNumberWide>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name={['ProxySQLServers', 'comment']}
                            label={'Comment'}
                        >
                            <Input placeholder="Click here to enter comment."></Input>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name={['ProxySQLServers', 'replicationLag']}
                            label={'Max replication lag'}
                        >
                            <InputNumberWide
                                min={0}
                                placeholder="Click here to enter max replication lag."
                            ></InputNumberWide>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name={['ProxySQLServers', 'maxConnections']}
                            label={'Max connection'}
                        >
                            <InputNumberWide
                                min={0}
                                placeholder="Click here to enter max connections."
                            ></InputNumberWide>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name={['ProxySQLServers', 'latency']}
                            label={'Max latency (ms)'}
                        >
                            <InputNumberWide
                                min={0}
                                placeholder="Click here to enter max latency."
                            ></InputNumberWide>
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={[24, 0]}>
                    <Col span={12}>
                        <FormItemInlineSwitch
                            justify={true}
                            label="Use SSL"
                            name={['ProxySQLServers', 'useSSL']}
                            labelStrong={false}
                            valuePropName="checked"
                        />
                    </Col>
                    <Col span={12}>
                        <FormItemInlineSwitch
                            justify={true}
                            label="Compression"
                            name={['ProxySQLServers', 'compression']}
                            labelStrong={false}
                            valuePropName="checked"
                        />
                    </Col>
                </Row>

                <FormFooter
                    loading={loading}
                    showCancelButton
                    onCancel={onCancel}
                    submitButtonText="Submit"
                    showSubmitButton
                />
            </Form>
        </ModalDefaultForm>
    );
}
