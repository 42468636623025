import React, { useState } from 'react';
import { Alert, Checkbox, Form, Input, Select } from 'antd';

import { Link } from 'react-router-dom';
import { useForm } from 'antd/lib/form/Form';
import AuthWrapper from './AuthWrapper';
import WizardFormConfiguration from '@severalnines/bar-frontend-components/build/lib/Navigation/Wizard/WizardFormConfiguration';
import CmonUsersService from '../../services/cmon/CmonUsersService';
import CcUser from '../../services/models/CcUser';
import './RegistrationFull.less';
import { AppConfig } from '../../AppConfig';
import CmonTreeService from '../../services/cmon/CmonTreeService';
import { useDebugContext } from '../../common/Debug';
import CryptoJS from 'crypto-js';
import RegistrationLicenseFailed from './RegistrationLicenseFailed';
import { requestCMONVersion } from './Registration';

type RegistrationFullFieldsType = {
    username: string;
    password: string;
    email: string;
    confirmPassword: string;
    firstname: string;
    lastname: string;
    terms: boolean;
    company: string;
    phone: string;
    interest: string;
};

export type RegistrationFullProps = {
    user: CcUser;
};

function RegistrationFull({ user }: RegistrationFullProps) {
    const [loading, setLoading] = useState<boolean>(false);
    const [form] = useForm<RegistrationFullFieldsType>();
    const [error, setError] = useState<any | null>(null);
    const [showLicenseError, setShowLicenseError] = useState<boolean>(false);
    const { log } = useDebugContext();

    const handleSubmit = async () => {
        const formFields = form.getFieldsValue(true);
        setLoading(true);
        try {
            await CmonUsersService.updateUser({
                new_password: formFields.confirmPassword,
                user: {
                    class_name: 'CmonUser',
                    user_id: user?.userId,
                    user_name: formFields.username,
                    email_address: formFields.email,
                    first_name: formFields.firstname,
                    last_name: formFields.lastname,
                    groups: user.groups,
                    timezone: user.timezone,
                },
            });
        } catch (err) {
            setError(err);
            log.error(err);
        }

        try {
            const cmonVersion = await requestCMONVersion();
            const response = await fetch(
                `/cc-license?name=${formFields.username}&uuid=${CryptoJS.MD5(
                    formFields.email
                )}&email=${formFields.email}&extra=${formFields.company},${
                    formFields.phone
                },${cmonVersion}&intent=${formFields.interest}`
            );
            const data = await response.json();
            await CmonTreeService.setcontent({
                content: data.lickey,
                path: '/.runtime/cmon_license',
            });
            window.location.assign(`/dashboard`);
        } catch (err) {
            setLoading(false);
            log.error(err);
            setShowLicenseError(true);
        }
    };

    const onStepChange = () => {
        setError(null);
    };

    const handleCancel = () => {
        window.location.assign(`/registration`);
    };

    async function passwordMatchValidator(rule: any, value: string) {
        if (form.getFieldValue('password') !== value) {
            throw new Error("Password doesn't match!");
        }
        return true;
    }

    async function termsValidator(rule: any, value: string) {
        if (!value) {
            throw new Error(
                'Please read and agree to the terms and conditions, and privacy policy!'
            );
        }
        return true;
    }

    async function userNameValidator(rule: any, value: string) {
        if (value === AppConfig.INITIAL_USER) {
            throw new Error('Username not allowed');
        }
        return true;
    }

    return (
        <>
            {showLicenseError ? (
                <div className="RegistrationLicenseFailed">
                    <AuthWrapper>
                        <RegistrationLicenseFailed />
                    </AuthWrapper>
                </div>
            ) : (
                <div className="RegistrationFullPageContainer">
                    <AuthWrapper>
                        <div className="RegistrationFullPage">
                            <div className="RegistrationFullPage_title">
                                Create User
                            </div>
                            <WizardFormConfiguration
                                form={form}
                                onSubmit={handleSubmit}
                                onStepChange={onStepChange}
                                onCancel={handleCancel}
                                loading={loading}
                                doneButtonText={'Create'}
                                stepsProps={{
                                    direction: 'horizontal',
                                }}
                                initialValues={{
                                    username:
                                        user?.userName !==
                                        AppConfig.INITIAL_USER
                                            ? AppConfig.INITIAL_USER
                                            : '',
                                    email: user?.emailAddress,
                                    firstname: user?.firstName,
                                    lastname: user?.lastName,
                                }}
                                headerSize={0}
                                steps={[
                                    <WizardFormConfiguration.Step
                                        key="registration-step-1"
                                        title="Admin Info"
                                        subTitle=""
                                        validate={[
                                            'username',
                                            'email',
                                            'password',
                                            'confirmPassword',
                                        ]}
                                        hasRequiredFields={true}
                                    >
                                        <Form.Item
                                            data-testid="userNameField"
                                            label="Username"
                                            name="username"
                                            rules={[
                                                {
                                                    validator: userNameValidator,
                                                },
                                                {
                                                    required: true,
                                                    message:
                                                        'Please enter your user name!',
                                                },
                                            ]}
                                        >
                                            <Input
                                                maxLength={100}
                                                placeholder="Enter username"
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            data-testid="emailField"
                                            label="Email"
                                            name="email"
                                            rules={[
                                                {
                                                    type: 'email',
                                                    required: true,
                                                    message:
                                                        'Please input your email address!',
                                                },
                                            ]}
                                        >
                                            <Input
                                                maxLength={100}
                                                placeholder="Enter an email address"
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            data-testid="passwordField"
                                            label="New password"
                                            name="password"
                                            rules={[
                                                {
                                                    required: true,
                                                    message:
                                                        'Enter the new password!',
                                                },
                                            ]}
                                        >
                                            <Input.Password
                                                maxLength={100}
                                                placeholder="Enter a new password"
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            data-testid="reenterPasswordField"
                                            label="Confirm new password"
                                            name="confirmPassword"
                                            rules={[
                                                {
                                                    required: true,
                                                    message:
                                                        'Re-enter the new password!',
                                                },
                                                {
                                                    validator: passwordMatchValidator,
                                                },
                                            ]}
                                        >
                                            <Input.Password
                                                maxLength={100}
                                                placeholder="Re-enter the new password"
                                            />
                                        </Form.Item>
                                    </WizardFormConfiguration.Step>,
                                    <WizardFormConfiguration.Step
                                        key="registration-step-2"
                                        title="Registration Info"
                                        subTitle=""
                                        validate={[
                                            'firstname',
                                            'lastname',
                                            'terms',
                                            'company',
                                            'phone',
                                            'interest',
                                        ]}
                                        hasRequiredFields={true}
                                    >
                                        <Form.Item
                                            data-testid="firstNameField"
                                            label="First name"
                                            name="firstname"
                                            rules={[
                                                {
                                                    required: true,
                                                    message:
                                                        'Please enter your first name!',
                                                },
                                            ]}
                                        >
                                            <Input
                                                maxLength={100}
                                                placeholder="Enter your first name"
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            data-testid="lastNameField"
                                            label="Last name"
                                            name="lastname"
                                            rules={[
                                                {
                                                    required: true,
                                                    message:
                                                        'Please enter your last name!',
                                                },
                                            ]}
                                        >
                                            <Input
                                                maxLength={100}
                                                placeholder="Enter your last name"
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            data-testid="companyNameField"
                                            label="Company name"
                                            name="company"
                                            rules={[
                                                {
                                                    required: true,
                                                    message:
                                                        'Please enter your company name!',
                                                },
                                            ]}
                                        >
                                            <Input
                                                maxLength={100}
                                                placeholder="Enter your company name"
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            data-testid="phoneNumberField"
                                            label="Phone number"
                                            name="phone"
                                            rules={[
                                                {
                                                    required: true,
                                                    message:
                                                        'Please enter your phone number!',
                                                },
                                            ]}
                                        >
                                            <Input
                                                maxLength={100}
                                                placeholder="Enter your phone number"
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            data-testid="interestField"
                                            label="Plan of interest"
                                            name="interest"
                                            rules={[
                                                {
                                                    required: true,
                                                    message:
                                                        'Please select plan of interest!',
                                                },
                                            ]}
                                        >
                                            <Select
                                                placeholder="Select plan of interest"
                                                options={[
                                                    {
                                                        value: 'Community',
                                                    },
                                                    {
                                                        value: 'Advanced',
                                                    },
                                                    {
                                                        value: 'Enterprise',
                                                    },
                                                ]}
                                            ></Select>
                                        </Form.Item>
                                        <Form.Item
                                            name="terms"
                                            valuePropName="checked"
                                            rules={[
                                                {
                                                    validator: termsValidator,
                                                },
                                            ]}
                                        >
                                            <Checkbox>
                                                I've read and agree to the&nbsp;
                                                <Link
                                                    to="/agreement"
                                                    target="_blank"
                                                >
                                                    Terms and Conditions
                                                </Link>
                                                , and&nbsp;
                                                <Link
                                                    to="/agreement"
                                                    target="_blank"
                                                >
                                                    Privacy Policy
                                                </Link>
                                            </Checkbox>
                                        </Form.Item>
                                    </WizardFormConfiguration.Step>,
                                ]}
                            />
                            {error ? (
                                <>
                                    <Alert
                                        showIcon
                                        message={error.message}
                                        type="error"
                                    />
                                    <br />
                                </>
                            ) : null}
                        </div>
                    </AuthWrapper>
                </div>
            )}
        </>
    );
}

export default RegistrationFull;
