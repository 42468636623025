import React, { useMemo } from 'react';
import AppTable, { AppTableProps } from '../../common/DataDisplay/AppTable';
import CmonAccount from '../../services/cmon/models/CmonAccount';
import ActionsMenu from '../../common/Navigation/ActionsMenu';
import ButtonWithForm from '../../common/General/ButtonWithForm';
import AppConfirmActionButton from '../../common/General/AppConfirmActionButton';
import ActionButton from '@severalnines/bar-frontend-components/build/lib/General/ActionButton';
import CcDatabaseAccount, {
    CcDatabaseAccountStats,
} from '../../services/models/CcDatabaseAccount';
import { Space, TableProps } from 'antd';
import TypographyText from '../../common/TypographyText';
import SegmentedData from '../../common/DataDisplay/SegmentedData';
import CcCluster, {
    CcClusterTechnology,
} from '../../services/models/CcCluster';
import DatabaseUserFormModal from './DatabaseUserFormModal';
import StatusFormat, {
    StatusFormatStatus,
} from '@severalnines/bar-frontend-components/build/lib/Format/StatusFormat';
import useTableFilterColumns from '../../common/hooks/useTableFilterColumns';
import InfoIcon from '@severalnines/bar-frontend-components/build/lib/General/InfoIcon';

export default DatabaseUsersTable;
export type DatabaseUsersTableProps = Omit<AppTableProps, 'onRow'> & {
    cluster: CcCluster;
    onDelete?: (user: CcDatabaseAccount) => void;
    onSave?: (user: CcDatabaseAccount) => void;
    activeFilterDisabledMessage?: boolean;
    filterParams?: any;
    statusFilterAvailable?: boolean;
};

function DatabaseUsersTable({
    cluster,
    onDelete,
    onSave,
    activeFilterDisabledMessage,
    filterParams,
    statusFilterAvailable = true,
    ...rest
}: DatabaseUsersTableProps) {
    const { columns }: TableProps<CcDatabaseAccount> = useTableFilterColumns({
        columns: useMemo(() => {
            const activeColumn = {
                title: 'Active',
                key: 'active',
                width: 130,
                sorter: statusFilterAvailable,
                // do not allow disabling sorting
                sortDirections: ['descend', 'ascend', 'descend'],
                filterMultiple: false,
                filters: statusFilterAvailable
                    ? [
                          {
                              text: 'Active users',
                              value: 'active',
                          },
                          {
                              text: 'Inactive users',
                              value: 'inactive',
                          },
                      ]
                    : undefined,
                render: (record: CcDatabaseAccount) => {
                    switch (record.status) {
                        case CcDatabaseAccountStats.ACTIVE:
                            return (
                                <StatusFormat
                                    status={StatusFormatStatus.success}
                                >
                                    Active
                                </StatusFormat>
                            );
                        case CcDatabaseAccountStats.INACTIVE:
                            return (
                                <StatusFormat
                                    status={StatusFormatStatus.unknown}
                                >
                                    Unused
                                </StatusFormat>
                            );
                        default:
                            return (
                                <Space size={5}>
                                    <StatusFormat
                                        status={StatusFormatStatus.unknown}
                                    >
                                        Unknown
                                    </StatusFormat>
                                    <InfoIcon
                                        info={
                                            <span>
                                                Server must have been running
                                                for 1 hours to check unused
                                                accounts since last server
                                                restart
                                            </span>
                                        }
                                    />
                                </Space>
                            );
                    }
                },
            };
            return [
                {
                    title: 'User',
                    key: 'userName',
                    width: 150,
                    render: (record: CcDatabaseAccount) =>
                        `${record.userName}${
                            record.hostAllow ? `@${record.hostAllow}` : ''
                        }`,
                },
                ...(cluster?.isTechnology(CcClusterTechnology.TECHNOLOGY_MYSQL)
                    ? [activeColumn]
                    : []),
                {
                    title: 'Privileges',
                    key: 'privileges',
                    render: (record: CcDatabaseAccount) => {
                        return (
                            <Space wrap={true} size={10}>
                                {record.privileges?.map((privilege, index) => {
                                    return (
                                        <SegmentedData
                                            extraBefore={
                                                privilege.db === '*.*' &&
                                                cluster.isTechnology(
                                                    CcClusterTechnology.TECHNOLOGY_POSTGRESQL
                                                ) ? undefined : (
                                                    <TypographyText
                                                        strong={true}
                                                        nowrap={true}
                                                    >
                                                        {privilege.db}
                                                    </TypographyText>
                                                )
                                            }
                                        >
                                            <TypographyText
                                                style={{ maxWidth: 200 }}
                                                ellipsis={{
                                                    tooltip:
                                                        privilege.privileges.join(
                                                            ','
                                                        ),
                                                }}
                                            >
                                                {privilege.privileges.join(',')}
                                            </TypographyText>
                                        </SegmentedData>
                                    );
                                })}
                            </Space>
                        );
                    },
                },

                {
                    key: 'Actions',
                    align: 'actions',
                    width: 30,
                    render: (record: CmonAccount) => (
                        <ActionsMenu
                            items={[
                                {
                                    key: 'edit',
                                    label: (
                                        <ButtonWithForm
                                            button={
                                                <ActionButton>
                                                    Edit
                                                </ActionButton>
                                            }
                                            form={
                                                <DatabaseUserFormModal
                                                    cluster={cluster}
                                                    editAccount={record}
                                                    onSuccess={() => {
                                                        onSave?.(record);
                                                    }}
                                                />
                                            }
                                        />
                                    ),
                                },

                                {
                                    key: 'delete',
                                    label: (
                                        <AppConfirmActionButton
                                            onConfirm={() => {
                                                onDelete?.(record);
                                            }}
                                            confirmTitle="Are you sure you want to delete this user?"
                                        >
                                            Delete
                                        </AppConfirmActionButton>
                                    ),
                                },
                            ]}
                        />
                    ),
                },
            ];
        }, [filterParams, statusFilterAvailable]),
        filterParams,
    });
    return (
        <AppTable
            rowKey={(record: CmonAccount) =>
                `${record.userName}@${record.hostAllow}`
            }
            onRow={(record: CmonAccount, index: number) => {
                return {
                    'data-testid': `database-users-table-row-${index}`,
                };
            }}
            columns={columns}
            {...rest}
        ></AppTable>
    );
}
