import React, { useEffect } from 'react';
import { FormInstance } from 'antd/lib/form';
import { Alert, Col, Form, Input, Row, Select, Space } from 'antd';
import InfoIcon from '@severalnines/bar-frontend-components/build/lib/General/InfoIcon';
import { TopologyItem } from '../../Topology/TopologyItem';
import FormItemInlineSwitch from '../../../common/DataEntry/FormItemInlineSwitch';
import SnapshotRepositoryNameField from '../../../common/Form/Fields/SnapshotRepositoryNameField';

export default SnapshotRepositoryForm;
export type SnapshotRepositoryFormProps = {
    form: FormInstance;
};

function SnapshotRepositoryForm({ form }: SnapshotRepositoryFormProps) {
    function setDefaultDirectory() {
        if (!form.getFieldValue(['repository', '_locationChanged'])) {
            form.setFieldsValue({
                repository: {
                    snapshotLocation: getDefaultDirectory(),
                },
            });
        }
    }
    function getDefaultDirectory() {
        return `/mnt/data/backups/es-snapshot-repositories`;
    }

    const handleLocationChange = () => {
        form.setFieldsValue({
            repository: {
                _locationChanged: true,
            },
        });
    };

    const availableHostsOptions = [
        ...form.getFieldValue('eligibleMasterTopology'),
        ...form.getFieldValue('dataNodeTopology'),
    ].map((item: TopologyItem) => ({
        label: item.extraData?.hostname,
        value: item.extraData?.hostname,
    }));

    useEffect(() => {
        setDefaultDirectory();
        if (availableHostsOptions.length === 1) {
            // select the unique option by default
            form.setFieldsValue({
                repository: { storageHost: availableHostsOptions[0].value },
            });
        }
    }, []);

    return (
        <div className="SnapshotRepositoryField">
            <Row gutter={[24, 0]}>
                <Col span={24}>
                    <h3>Snapshot storage configuration</h3>
                </Col>
            </Row>
            <Row gutter={[24, 0]}>
                <Col span={12}>
                    <SnapshotRepositoryNameField
                        name={['repository', 'name']}
                        form={form}
                    />
                </Col>
                <Col span={12}>
                    <Form.Item
                        name={['repository', 'storageHost']}
                        label={
                            <Space>
                                <span>Storage host</span>
                                <InfoIcon
                                    info={
                                        <span>
                                            Host with physical file system
                                            storing snapshots and sharing with
                                            other cluster's nodes
                                        </span>
                                    }
                                />
                            </Space>
                        }
                        rules={[
                            {
                                required: true,
                                message: 'Please select a storage host',
                            },
                        ]}
                    >
                        <Select options={availableHostsOptions} />
                    </Form.Item>
                </Col>

                <Col span={16}>
                    <Form.Item
                        name={['repository', 'snapshotLocation']}
                        label={
                            <Space>
                                <span>Default snapshot location</span>
                                <InfoIcon
                                    info={
                                        <span>
                                            Location of the shared filesystem
                                            used to store and retrieve
                                            snapshots. This location will be
                                            registered in the path.repo setting
                                            on all master and data nodes in the
                                            cluster.
                                        </span>
                                    }
                                />
                            </Space>
                        }
                    >
                        <Input
                            onChange={handleLocationChange}
                            placeholder="Enter default snapshot location"
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <FormItemInlineSwitch
                        justify={true}
                        name={['repository', 'configureSharedFs']}
                        label={
                            <Space>
                                Configure shared filesystem
                                <InfoIcon
                                    info={
                                        <span>
                                            ClusterControl will configure shared
                                            filesystem with nfs on specified
                                            storage host and location as the
                                            default cluster's snapshot
                                            repository
                                        </span>
                                    }
                                />
                            </Space>
                        }
                        valuePropName="checked"
                    />
                </Col>
                <Col span={24}>
                    <Form.Item noStyle={true} shouldUpdate={true}>
                        {() =>
                            !form.getFieldValue([
                                'repository',
                                'configureSharedFs',
                            ]) && (
                                <Space size={10} direction="vertical">
                                    <Alert
                                        showIcon={true}
                                        message={
                                            <span>
                                                If 'Configure shared filesystem'
                                                is off, then a shared filesystem
                                                has to be configured manually
                                                before deployment. The
                                                deployment job will fail if a
                                                shared filesystem is not
                                                configured.
                                            </span>
                                        }
                                    />
                                    <Alert
                                        showIcon={true}
                                        message={
                                            <span>
                                                A shared filesystem needs to be
                                                accessible from all cluster
                                                nodes in order for backup and
                                                restore to function properly
                                                with a clustered setup.
                                            </span>
                                        }
                                    />
                                </Space>
                            )
                        }
                    </Form.Item>
                </Col>
            </Row>
        </div>
    );
}
