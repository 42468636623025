import React from 'react';
import { ButtonProps } from 'antd/lib/button';
import CcCluster from '../../../services/models/CcCluster';
import { CmonJobInstanceCommand } from '../../../services/cmon/models/CmonJobInstance';
import JobConfirmActionButton from '../../Jobs/JobConfirmActionButton';

export default ClusterEnableReadonlyButton;

export type ClusterEnableReadonlyButtonProps = ButtonProps & {
    cluster: CcCluster;
    children?: React.ReactNode;
    onSuccess?: () => void;
};

function ClusterEnableReadonlyButton({
    cluster,
    children,
    onSuccess,
    ...rest
}: ClusterEnableReadonlyButtonProps) {
    return (
        <JobConfirmActionButton
            clusterId={cluster.clusterId}
            command={CmonJobInstanceCommand.ENABLE_CLUSTER_READONLY}
            confirmTitle={'Enable cluster readonly?'}
            title={`Enable readonly`}
            onSuccess={onSuccess}
            {...rest}
        />
    );
}
