import useListFetch from '../../common/useListFetch';
import CcBackupService from '../../services/CcBackupService';
import CcBackup from '../../services/models/CcBackup';
export type UseBackupsListProps = {
    pageSize?: number;
};
export default function useBackups({ pageSize = 10 }: UseBackupsListProps) {
    const {
        list: backupList,
        loading,
        loaded,
        refresh,
        page,
        pageSize: listPageSize,
        total,
    } = useListFetch<CcBackup>({
        name: 'backups-list',
        pageSize,
        order: 'created DESC',
        fetchFn: async ({ pageSize, page, ...rest }, opts) => {
            const { backup_records, total } = await CcBackupService.getBackups(
                {
                    limit: pageSize,
                    offset: ((page || 1) - 1) * pageSize,
                    ascending: rest.ascending || false,
                    backup_record_version: 2,
                    ...rest,
                },
                opts
            );
            return {
                list: backup_records,
                total,
            };
        },
        cancelFn: async ({ requestId }) => {
            await CcBackupService.cancelRequest(requestId);
        },
    });

    return {
        list: backupList,
        loading,
        loaded,
        refresh,
        page,
        pageSize: listPageSize,
        total,
    };
}
