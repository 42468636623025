import React from 'react';
import { Button, Form, Radio, Space } from 'antd';
import FormFooter from '../../../common/FormFooter';
import ModalDefaultForm from '../../../common/ModalDefaultForm';
import CcNode, { CcNodeType } from '../../../services/models/CcNode';
import NodeFormat from '../NodeFormat';
import SpaceWide from '../../../common/SpaceWide';
import useCreateJob from '../../Jobs/useCreateJob';
import { CmonJobInstanceCommand } from '../../../services/cmon/models/CmonJobInstance';
import Alert from '@severalnines/bar-frontend-components/build/lib/Feedback/Alert';
import CcElasticNode from '../../../services/models/CcElasticNode';

export default NodeUnregsiterForm;

enum UnregisterOption {
    UnregisterOnly,
    Stop,
    Uninstall,
}

export type NodeUnregisterFormProps = {
    node: CcNode;
    onSuccess?: () => void;
    onCancel?: () => void;
};
export type NodeUnregisterFormValues = {
    unregisterOption?: UnregisterOption;
};

function NodeUnregsiterForm({
    node,
    onSuccess,
    onCancel,
}: NodeUnregisterFormProps) {
    const [form] = Form.useForm<NodeUnregisterFormValues>();
    const { loading, send } = useCreateJob({
        clusterId: node.clusterid,
        title: 'Remove node',
        command: CmonJobInstanceCommand.REMOVENODE,
        onSuccess,
    });

    const handleSubmit = async (values: NodeUnregisterFormValues) => {
        await send({
            node: {
                hostname: node.hostname,
                port: node.port,
            },
            unregister_only:
                values.unregisterOption === UnregisterOption.UnregisterOnly,
            enable_uninstall:
                values.unregisterOption === UnregisterOption.Uninstall,
        });
    };
    return (
        <ModalDefaultForm
            title="Remove node"
            form={form}
            footer={[]}
            onCancel={onCancel}
            width={450}
            defaultVisible={true}
        >
            <Form
                className="NodeUnregsiterForm"
                form={form}
                layout="vertical"
                onFinish={handleSubmit}
                initialValues={{
                    unregisterOption: UnregisterOption.UnregisterOnly,
                }}
            >
                <SpaceWide direction="vertical">
                    <NodeFormat
                        node={node}
                        size="large"
                        contentProps={{ style: { width: '100%' } }}
                    />
                    <Form.Item name="unregisterOption" label={<Space></Space>}>
                        <Radio.Group>
                            <Space direction="vertical">
                                <Radio value={UnregisterOption.UnregisterOnly}>
                                    Keep service running
                                </Radio>
                                <Radio value={UnregisterOption.Stop}>
                                    Stop service and keep files untouched
                                </Radio>
                                <Radio value={UnregisterOption.Uninstall}>
                                    Stop and uninstall service
                                </Radio>
                            </Space>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item noStyle={true} shouldUpdate={true}>
                        {() => (
                            <Alert
                                message={
                                    <span>
                                        The following actions will be performed:
                                        <ol>
                                            {form.getFieldValue(
                                                'unregisterOption'
                                            ) ===
                                            UnregisterOption.UnregisterOnly ? (
                                                <li>
                                                    Node will be unregistered
                                                    from ClusterControl but the
                                                    service will be kept running
                                                </li>
                                            ) : form.getFieldValue(
                                                  'unregisterOption'
                                              ) === UnregisterOption.Stop ? (
                                                <li>
                                                    Node will be unregistered
                                                    from ClusterControl and
                                                    service will be stopped
                                                </li>
                                            ) : (
                                                <li>
                                                    Node will be unregistered,
                                                    stopped and all software
                                                    will be uninstalled
                                                </li>
                                            )}
                                        </ol>
                                    </span>
                                }
                                showIcon={true}
                            />
                        )}
                    </Form.Item>
                    {node.isType(CcNodeType.ELASTIC) &&
                        (node as CcElasticNode).electedMaster && (
                            <Alert
                                type="warning"
                                message={
                                    <span>
                                        This node is elected master, if node is
                                        removed, a few cluster operations may
                                        fail until new node with master role is
                                        elected
                                    </span>
                                }
                                showIcon={true}
                            />
                        )}
                </SpaceWide>
                <FormFooter>
                    <Button
                        key="done"
                        type="primary"
                        htmlType="submit"
                        loading={loading}
                    >
                        Remove
                    </Button>
                </FormFooter>
            </Form>
        </ModalDefaultForm>
    );
}
