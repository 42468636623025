
import CcNode, { CcNodeProps } from '../../models/CcNode';


export interface CmonPgBackRestHostProps  extends CcNodeProps {
    os_user?: string;
    repo_hostname?: string;
    repo_path?: string;
    stanza_name?: string;

}



export default class CmonPgBackRestHost extends CcNode {
    public osUser?: string;
    public repoHostname?: string;
    public repoPath?: string;
    public stanzaName?: string;


    constructor(props: CmonPgBackRestHostProps) {
        super(props);
        this.osUser = props.os_user;
        this.repoHostname = props.repo_hostname;
        this.repoPath = props.repo_path;
        this.stanzaName = props.stanza_name;

    }

}
