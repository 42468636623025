import ButtonWithForm from '../../../../common/General/ButtonWithForm';
import ActionButton from '@severalnines/bar-frontend-components/build/lib/General/ActionButton';
import BackupRestoreWizardModal from './BackupRestoreWizardModal';
import React from 'react';
import { ButtonProps } from 'antd/lib/button';
import CcCluster from '../../../../services/models/CcCluster';

export default ClusterBackupRestoreActionButton;
type ClusterBackupRestoreActionButtonProps = ButtonProps & {
    cluster: CcCluster;
    onSuccess?: () => void;
};

function ClusterBackupRestoreActionButton({
    cluster,
    onSuccess,
    children,
    ...rest
}: ClusterBackupRestoreActionButtonProps) {
    return (
        <ButtonWithForm
            button={
                <ActionButton className="RestoreBackupButton" {...rest}>
                    {children || 'Restore backup'}
                </ActionButton>
            }
            form={
                <BackupRestoreWizardModal
                    cluster={cluster}
                    onSuccess={onSuccess}
                />
            }
        ></ButtonWithForm>
    );
}
