import React, { useEffect, useRef } from 'react';
import ModalDefaultForm from '../../../common/ModalDefaultForm';
import useForm from 'antd/lib/form/hooks/useForm';
import { Alert, Col, Form, Input, Row, Space } from 'antd';
import WizardFormConfiguration from '@severalnines/bar-frontend-components/build/lib/Navigation/Wizard/WizardFormConfiguration';
import {
    getRepositoryText,
    RepositoryItemKey,
} from '../../../common/DataEntry/RepositoryInput';
import TypographyText from '../../../common/TypographyText';
import SpaceDescriptions from '../../../common/Layout/SpaceDescriptions';
import YesNoFormat from '../../../common/Format/YesNoFormat';
import useCreateJob from '../../Jobs/useCreateJob';
import { CmonJobInstanceCommand } from '../../../services/cmon/models/CmonJobInstance';
import CcCluster from '../../../services/models/CcCluster';
import { TopologyItem } from '../../Topology/TopologyItem';
import { TopologyItemList } from '../../Topology/TopologyItemList';
import { FormInstance } from 'antd/lib/form';
import VendorRepositoryField from '../../../common/Form/Fields/VendorRepositoryField';
import DisableFirewallSwitch from '../../../common/Form/Fields/DisableFirewallSwitch';
import DisableSelinuxSwitch from '../../../common/Form/Fields/DisableSelinuxSwitch';
import { StatusFormatStatus } from '@severalnines/bar-frontend-components/build/lib/Format/StatusFormat';
import ClusterNodesForm from '../../Services/Cluster/ClusterNodesForm';

export default ClusterCloneModal;

enum ClusterCloneWizardStep {
    CONFIG = 'CONFIG',
    HOST = 'HOST',
    SUMMARY = 'SUMMARY',
}

export type ClusterCloneWizardProps = {
    cluster: CcCluster;
    onCancel?: () => void;
    onSuccess?: () => void;
};

export type ClusterCloneWizardFormValues = {
    name: string;
    repository: string;
    disableFirewall: boolean;
    disableSeLinux: boolean;
    topology: TopologyItem[];
    topologyDataIps?: {
        [key: string]: string;
    };
};

function ClusterCloneModal({
    cluster,
    onCancel,
    onSuccess,
}: ClusterCloneWizardProps) {
    const { loading, send } = useCreateJob({
        clusterId: cluster.clusterId,
        title: `Clone cluster ${cluster.clusterName || ''}`,
        command: CmonJobInstanceCommand.CLONE_CLUSTER,
        onSuccess,
    });
    const [form] = useForm<ClusterCloneWizardFormValues>();

    const handleCancel = () => {
        onCancel?.();
    };

    const handleSubmit = async () => {
        const values: ClusterCloneWizardFormValues = form.getFieldsValue(true);
        await send({
            clusterId: cluster.clusterId,
            cluster_name: values.name,
            disable_firewall: values.disableFirewall,
            disable_selinux: values.disableSeLinux,
            generate_token: true,
            user_id: 5,
            nodes: values.topology?.map((item) => ({
                hostname: item.extraData?.hostname,
                hostname_data: item.extraData?.hostname,
                hostname_internal:
                    values.topologyDataIps?.[item.extraData.hostname] || '',
            })),
        });
    };

    return (
        <ModalDefaultForm
            title="Clone cluster"
            form={form}
            footer={[]}
            bodyStyle={{ padding: 0 }}
            onCancel={handleCancel}
            confirmClose={true}
            loading={loading}
            defaultVisible={true}
        >
            <WizardFormConfiguration
                form={form}
                onSubmit={handleSubmit}
                initialValues={{
                    name: `Clone of (${cluster.clusterName})`,
                    repository: RepositoryItemKey.USE_VENDOR,
                }}
                showCancelButton
                cancelButtonText="Cancel"
                onCancel={handleCancel}
                steps={[
                    <WizardFormConfiguration.Step
                        key={ClusterCloneWizardStep.CONFIG}
                        title="Configuration"
                        subTitle=" "
                        validate={['name', 'repository']}
                        hasRequiredFields={true}
                    >
                        <Row gutter={[24, 0]}>
                            <Col span={24}>
                                <h3>Configuration</h3>
                            </Col>
                        </Row>
                        <Row gutter={[24, 0]}>
                            <Col xs={24} sm={24} md={12}>
                                <Form.Item
                                    name="name"
                                    label="Cluster name"
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                'Please enter cluster name',
                                        },
                                    ]}
                                >
                                    <Input placeholder="Enter cluster name" />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12}>
                                <VendorRepositoryField
                                    form={form}
                                    name="repository"
                                />
                            </Col>
                        </Row>
                        <Row gutter={[24, 0]}>
                            <Col span={24}>
                                <h3>Security configuration</h3>
                            </Col>
                            <Col xs={24} sm={24} md={12}>
                                <DisableFirewallSwitch />
                            </Col>
                            <Col xs={24} sm={24} md={12}>
                                <DisableSelinuxSwitch />
                            </Col>
                        </Row>
                        <Row>
                            <Alert
                                message={
                                    <Space direction="vertical">
                                        <TypographyText strong>
                                            The following procedures applies
                                        </TypographyText>
                                        <ul
                                            style={{
                                                margin: 0,
                                                listStyle: 'decimal',
                                            }}
                                        >
                                            <li>
                                                Create a new cluster consisting
                                                of one node;
                                            </li>
                                            <li>
                                                Stage the new cluster with SST
                                                (it is now cloned);
                                            </li>
                                            <li>
                                                Nodes will be added to the
                                                cloned cluster until cloned
                                                cluster size is reached;
                                            </li>
                                            <li>
                                                Query monitor settings and
                                                settings for Cluster recovery
                                                and Node recovery options are
                                                not cloned;
                                            </li>
                                            <li>
                                                The my.cnf file may not be
                                                identical on the cloned cluster;
                                            </li>
                                        </ul>
                                    </Space>
                                }
                                showIcon
                                type="info"
                            />
                        </Row>
                    </WizardFormConfiguration.Step>,
                    <WizardFormConfiguration.Step
                        key={ClusterCloneWizardStep.HOST}
                        title="Add host"
                        subTitle=" "
                        validate={() => {
                            // @todo refactor ClusterConfigurator.getTopologyValidate
                            const topology: TopologyItem[] =
                                form.getFieldValue('topology') || [];
                            return [
                                () => {
                                    if (topology.length === 0) {
                                        throw new Error(
                                            'At least 1 node is required'
                                        );
                                    }
                                    if (
                                        topology.find(
                                            (item) =>
                                                item.status !==
                                                StatusFormatStatus.success
                                        )
                                    ) {
                                        throw new Error(
                                            'All nodes should be reachable'
                                        );
                                    }
                                },
                            ];
                        }}
                        hasRequiredFields={true}
                    >
                        <Row gutter={[24, 0]}>
                            <Col span={24}>
                                <h3>Add host</h3>
                            </Col>
                        </Row>
                        <Row gutter={[24, 0]}>
                            <Col xs={24} sm={24} md={24}>
                                <ClusterNodesForm
                                    form={form}
                                    nodesInputProps={{
                                        clusterId: cluster.clusterId,
                                        singleNode: true,
                                        onlyPrimaries: true,
                                    }}
                                />
                            </Col>
                        </Row>
                    </WizardFormConfiguration.Step>,
                    <WizardFormConfiguration.Step
                        key={ClusterCloneWizardStep.SUMMARY}
                        title="Preview"
                        subTitle=" "
                        hasRequiredFields={true}
                    >
                        <Form.Item noStyle shouldUpdate={true}>
                            {() => (
                                <>
                                    <SpaceDescriptions
                                        direction="vertical"
                                        title="Configuration"
                                        size="small"
                                        alignItems="right"
                                    >
                                        <SpaceDescriptions.Item
                                            label="Cluster name"
                                            labelStrong
                                        >
                                            {form.getFieldValue('name')}
                                        </SpaceDescriptions.Item>
                                        <SpaceDescriptions.Item
                                            label="Repository"
                                            labelStrong
                                        >
                                            {getRepositoryText(
                                                form.getFieldValue('repository')
                                            )}
                                        </SpaceDescriptions.Item>
                                    </SpaceDescriptions>
                                    <SpaceDescriptions
                                        direction="vertical"
                                        title="Security configuration"
                                        size="small"
                                        alignItems="right"
                                    >
                                        <SpaceDescriptions.Item
                                            label="Disable firewall"
                                            labelStrong
                                        >
                                            <YesNoFormat
                                                booleanVar={form.getFieldValue(
                                                    'disableFirewall'
                                                )}
                                            />
                                        </SpaceDescriptions.Item>
                                        <SpaceDescriptions.Item
                                            label="Disable SELinux/AppArmor"
                                            labelStrong
                                        >
                                            <YesNoFormat
                                                booleanVar={form.getFieldValue(
                                                    'disableSeLinux'
                                                )}
                                            />
                                        </SpaceDescriptions.Item>
                                    </SpaceDescriptions>
                                    <ClusterCloneTopologySummary form={form} />
                                </>
                            )}
                        </Form.Item>
                    </WizardFormConfiguration.Step>,
                ]}
            />
        </ModalDefaultForm>
    );
}

type ClusterCloneTopologySummaryProps = {
    form: FormInstance;
};
// @todo refactor TopologySummary in deployment
function ClusterCloneTopologySummary({
    form,
}: ClusterCloneTopologySummaryProps) {
    const { topology } = form.getFieldsValue(true);

    const itemList = useRef<TopologyItemList>(
        new TopologyItemList({ items: topology })
    );
    useEffect(() => {
        itemList.current = new TopologyItemList({ items: topology });
    }, [topology]);
    const nodeParents = itemList.current.getParents();
    return (
        <>
            <SpaceDescriptions
                direction="vertical"
                title="Hosts"
                alignItems="right"
            >
                {' '}
            </SpaceDescriptions>
            {nodeParents.map((item: TopologyItem, index: number) => (
                <div key={index} style={{ textAlign: 'right' }}>
                    <span key={item.key}>{item.title}</span>
                </div>
            ))}
        </>
    );
}
