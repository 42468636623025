import React, { useState } from 'react';

import postgres from './dataMocks/clusters/postgresWithLb';
import CcCluster from '../services/models/CcCluster';
import ClusterDashboardsVariables from '../components/Clusters/Pages/ClusterDashboardsVariables';
import SystemOverview from '../components/Monitor/data/system-overview';
export default DebugMonitorVariable;

function DebugMonitorVariable() {
    const [dashboard] = useState(SystemOverview);

    return (
        <div>
            <ClusterDashboardsVariables
                cluster={new CcCluster(postgres as any)}
                dashboard={dashboard}
                onFilterLoaded={(vars) => {
                    console.log('vars loaded', vars);
                }}
                onFilterChange={(vars) => {
                    console.log('vars changed', vars);
                }}
            />
        </div>
    );
}
