import React, { useRef, useState } from 'react';
import WizardFormConfiguration from '@severalnines/bar-frontend-components/build/lib/Navigation/Wizard/WizardFormConfiguration';
import { useForm } from 'antd/lib/form/Form';
import { Alert, Col, Form, Input, Row, Space } from 'antd';
import InfoIcon from '@severalnines/bar-frontend-components/build/lib/General/InfoIcon';
import ElasticRepositoryTypeSelect from './ElasticRepositoryTypeSelect';
import FormItemInlineSwitch from '../../../common/DataEntry/FormItemInlineSwitch';
import TypographyText from '../../../common/TypographyText';
import { FileTextOutlined } from '@ant-design/icons';
import BytesInputField from '../../../common/Form/Fields/BytesInputField';
import StorageHostField from '../../../common/Form/Fields/StorageHostField';
import ClusterSelectDropdown from '../../Clusters/ClusterSelectDropdown';
import CcCluster, { CcClusterType } from '../../../services/models/CcCluster';
import SpaceDescriptions from '../../../common/Layout/SpaceDescriptions';
import AppDivider from '../../../common/AppDivider';
import ClusterFormat from '../../Clusters/ClusterFormat';
import YesNoFormat from '../../../common/Format/YesNoFormat';
import {
    notifyError,
    notifyOperationSuccess,
} from '../../Notifications/uiNotification';
import { useNavigate } from 'react-router-dom';
import CmonBackupService from '../../../services/cmon/CmonBackupService';
import CcCloudCredentials from '../../../services/models/CcCloudCredentials';
import CloudProviderTypeFormat from '../../Integrations/CloudProviderTypeFormat';
import BackupCloudCredentialsForm from '../Wizard/BackupCloudCredentialsForm';
import { CcSnapshotRepositoryType } from '../../../services/models/CcSnapshotRepository';
import SnapshotRepositoryNameField from '../../../common/Form/Fields/SnapshotRepositoryNameField';
import FormItem from '../../../common/DataEntry/FormItem';

export default ElasticSnapshotRepositoryFormWizard;

export enum ElasticSnapshotRepositoryFormWizardStep {
    DETAILS = 'details',
    CONFIG_URL = 'config_url',
    CONFIG_FILE_SYSTEM = 'config_file_system',
    HOST = 'host',
    CLOUD = 'cloud',
    PREVIEW = 'preview',
}

export type ElasticSnapshotRepositoryFormValues = {
    clusterId?: number;
    name?: string;
    type?: CcSnapshotRepositoryType;
    sourceOnly?: boolean;
    url?: string;
    cloud?: {
        credentials?: number;
        bucket?: string;
        region?: string;
    };
};

export type ElasticSnapshotRepositoryFormWizardProps = {
    cluster?: CcCluster;
    onSuccess?: (fields: ElasticSnapshotRepositoryFormValues) => void;
    onTouchedChange?: (touched: boolean) => void;
    onCancel?: () => void;
    onStepErrorInsist?: (err: Error) => void;
    loading?: boolean;
};

function ElasticSnapshotRepositoryFormWizard({
    cluster,
    onSuccess,
    onCancel,
    onStepErrorInsist,
    onTouchedChange,
}: ElasticSnapshotRepositoryFormWizardProps) {
    const selectedCluster = useRef<CcCluster | undefined>(cluster);
    const selectedCredentials = useRef<CcCloudCredentials>();
    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(false);
    const [availableSteps, setAvailableSteps] = useState<string[]>(
        getElasticSnapshotFormWizardSteps(CcSnapshotRepositoryType.S3)
    );
    const [form] = useForm<ElasticSnapshotRepositoryFormValues>();

    const handleCredentialsSelect = (credentials?: CcCloudCredentials) => {
        selectedCredentials.current = credentials;
    };
    const handleSubmit = async () => {
        const formFields = form.getFieldsValue(true);
        try {
            if (!formFields.clusterId) {
                throw new Error('Cluster is not set');
            }

            setLoading(true);

            await CmonBackupService.snapshotRepository({
                cluster_id: formFields.clusterId,
                repository: {
                    class_name: 'CmonBackupSnapshotRepository',
                    job: {
                        command: 'backup',
                        description: 'Snapshot repository creating',
                        job_data: {
                            description: 'Backup creating.',
                            credential_id: formFields?.cloud?.credentials,
                            s3_bucket: formFields?.cloud?.bucket,
                            s3_region:
                                selectedCredentials?.current?.credentials
                                    ?.access_key_region,
                            snapshot_repository: formFields?.name,
                            snapshot_repository_type: formFields?.type,
                        },
                    },
                },
            });
            notifyOperationSuccess({
                size: 'large',
                navigate,
                title: 'Repository created successfully!',
                okUri: '/backup/repositories',
                okText: 'All repositories',
                cancelText: 'Close',
            });
            onSuccess?.(formFields);
        } catch (err) {
            notifyError({ size: 'large', content: err.message });
        } finally {
            setLoading(false);
        }
    };
    const handleCancel = () => {
        onCancel?.();
    };

    const handleValuesChange = ({
        type,
    }: ElasticSnapshotRepositoryFormValues) => {
        if (type) {
            setAvailableSteps(getElasticSnapshotFormWizardSteps(type));
        }
    };

    const handleClusterSelect = (cluster?: CcCluster) => {
        selectedCluster.current = cluster;
        form.setFieldsValue({ clusterId: cluster?.clusterId });
    };
    return (
        <WizardFormConfiguration
            form={form}
            onSubmit={handleSubmit}
            onCancel={handleCancel}
            onTouchedChange={onTouchedChange}
            onStepErrorInsist={onStepErrorInsist}
            onValuesChange={handleValuesChange}
            loading={loading}
            initialValues={{
                type: 's3',
                clusterId: cluster?.clusterId,
            }}
            availableSteps={availableSteps}
            steps={[
                <WizardFormConfiguration.Step
                    key={ElasticSnapshotRepositoryFormWizardStep.DETAILS}
                    title="Details"
                    subTitle=" "
                    validate={['name']}
                    hasRequiredFields={true}
                >
                    <Row gutter={[24, 0]}>
                        <Col span={24}>
                            <h3>Details</h3>
                        </Col>
                    </Row>
                    <Form.Item shouldUpdate noStyle>
                        {() => {
                            return form.getFieldValue('type') === 'shared' ? (
                                <Form.Item>
                                    <Alert
                                        type="warning"
                                        message={
                                            <span>
                                                Creating a new repository using
                                                shared file system on a new
                                                location will require
                                                Elasticsearch services restart
                                                on the nodes. Cluster will be
                                                available during that process
                                                but it will loose performance
                                                and fault tolerance.{' '}
                                            </span>
                                        }
                                    />
                                </Form.Item>
                            ) : null;
                        }}
                    </Form.Item>

                    <Row gutter={[24, 0]}>
                        <Col span={12}>
                            <SnapshotRepositoryNameField form={form} />
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="type"
                                label="Repository type"
                                rules={[
                                    {
                                        required: true,
                                        message:
                                            'Please select repository type',
                                    },
                                ]}
                            >
                                <ElasticRepositoryTypeSelect placeholder="Select repository type" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="clusterId"
                                label={<Space>Cluster</Space>}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please select cluster.',
                                    },
                                ]}
                            >
                                <ClusterSelectDropdown
                                    selectFirst={true}
                                    useGlobalState={true}
                                    showId={true}
                                    disabled={!!cluster}
                                    filterClusters={(cluster) =>
                                        cluster.clusterType ===
                                        CcClusterType.TYPE_ELASTIC
                                    }
                                    onClusterSelect={handleClusterSelect}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </WizardFormConfiguration.Step>,
                <WizardFormConfiguration.Step
                    key={
                        ElasticSnapshotRepositoryFormWizardStep.CONFIG_FILE_SYSTEM
                    }
                    title="Configuration"
                    subTitle=" "
                    validate={[
                        'storageLocation',
                        'chunkSize',
                        'restoreRate',
                        'snapshotRate',
                    ]}
                    hasRequiredFields={true}
                >
                    <Row gutter={[24, 0]}>
                        <Col xs={24} sm={24} md={12}>
                            <h3>Configuration</h3>
                        </Col>
                        <Col xs={24} sm={24} md={12}>
                            <a
                                href="https://www.elastic.co/guide/en/elasticsearch/reference/7.17/snapshots-register-repository.html#snapshots-filesystem-repository"
                                target="_blank"
                                title="Learn more about shared file system repositories"
                                rel="noreferrer"
                            >
                                <FileTextOutlined /> Learn more about read only
                                url repositories
                            </a>
                        </Col>
                    </Row>
                    <Row gutter={[24, 0]}>
                        <Col span={12}>
                            <BytesInputField
                                name="storageLocation"
                                label="File system location"
                                info={
                                    <span>
                                        The location must be registered in the
                                        path.repo setting on all master and data
                                        nodes.
                                    </span>
                                }
                                required
                                requiredMessage="Please enter location path"
                                placeholder="Enter here the location path"
                            />
                        </Col>
                        <Col span={12}>
                            <BytesInputField
                                name="chunkSize"
                                label="Chunk size"
                                info={
                                    <span>
                                        Breaks files into smaller units when
                                        taking snapshots.
                                    </span>
                                }
                                required
                                requiredMessage="Please enter the size of chunk"
                                placeholder="Enter the size of chunk"
                            />
                        </Col>
                        <Col span={12}>
                            <BytesInputField
                                name="restoreRate"
                                label="Max restore bytes per second"
                                info={
                                    <span>
                                        Maximum snapshot restore rate for each
                                        node.
                                    </span>
                                }
                                required
                                requiredMessage="Please enter restore rate"
                                placeholder="Enter restore rate here"
                            />
                        </Col>
                        <Col span={12}>
                            <BytesInputField
                                name="snapshotRate"
                                label="Max snapshot bytes per second"
                                info={
                                    <span>
                                        Maximum rate for creating snapshots for
                                        each node.
                                    </span>
                                }
                                required
                                requiredMessage="Please enter snapshot rate"
                                placeholder="Enter snapshot rate here"
                            />
                        </Col>
                    </Row>
                    <Row gutter={[24, 0]}>
                        <Col span={12}>
                            <FormItemInlineSwitch
                                justify
                                noMargin
                                name="compression"
                                label={
                                    <Space>
                                        <span>Snapshots compression</span>
                                        <InfoIcon
                                            info={
                                                <span>
                                                    Compresses the index mapping
                                                    and setting files for
                                                    snapshots. Data files are
                                                    not compressed.
                                                </span>
                                            }
                                        />
                                    </Space>
                                }
                                valuePropName="checked"
                            />
                        </Col>
                        <Col span={12}>
                            <FormItemInlineSwitch
                                justify
                                noMargin
                                name="readOnly"
                                label={
                                    <Space>
                                        <span>Read-only</span>
                                        <InfoIcon
                                            info={
                                                <span>
                                                    Only one cluster should have
                                                    write access to this
                                                    repository. All other
                                                    clusters should be
                                                    read-only.
                                                </span>
                                            }
                                        />
                                    </Space>
                                }
                                valuePropName="checked"
                            />
                        </Col>
                    </Row>
                </WizardFormConfiguration.Step>,
                <WizardFormConfiguration.Step
                    key={ElasticSnapshotRepositoryFormWizardStep.CONFIG_URL}
                    title="Configuration"
                    subTitle=" "
                    validate={['url']}
                    hasRequiredFields={true}
                >
                    <Row gutter={[24, 0]}>
                        <Col xs={24} sm={24} md={12}>
                            <h3>Configuration</h3>
                        </Col>
                        <Col xs={24} sm={24} md={12}>
                            <a
                                href="https://www.elastic.co/guide/en/elasticsearch/reference/7.17/snapshots-register-repository.html#snapshots-read-only-repository"
                                target="_blank"
                                title="Learn more about read only url repositories"
                                rel="noreferrer"
                            >
                                <FileTextOutlined /> Learn more about read only
                                url repositories
                            </a>
                        </Col>
                    </Row>
                    <Row gutter={[24, 0]}>
                        <Col span={24}>
                            <Form.Item
                                name="url"
                                label={
                                    <Space>
                                        <span>URL</span>
                                        <InfoIcon
                                            info={
                                                <span>
                                                    The location of the
                                                    snapshots.
                                                </span>
                                            }
                                        />
                                    </Space>
                                }
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter URL',
                                    },
                                ]}
                                extra={
                                    <TypographyText muted>
                                        This URL must be registered in the
                                        repositories.url.allowed_urls setting.
                                    </TypographyText>
                                }
                            >
                                <Input
                                    addonBefore={<span>https://</span>}
                                    placeholder="Enter URL here"
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </WizardFormConfiguration.Step>,
                <WizardFormConfiguration.Step
                    key={ElasticSnapshotRepositoryFormWizardStep.HOST}
                    title="Storage host"
                    subTitle=" "
                    validate={[]}
                    hasRequiredFields={true}
                >
                    <Row gutter={[24, 0]}>
                        <Col xs={24} sm={24} md={12}>
                            <h3>Storage host</h3>
                        </Col>
                    </Row>

                    <Row gutter={[24, 0]}>
                        <Col span={24}>
                            <FormItem noStyle={true} shouldUpdate={true}>
                                {() => {
                                    return (
                                        <StorageHostField
                                            inputProps={{
                                                clusterId: form.getFieldValue(
                                                    'clusterId'
                                                ),
                                            }}
                                        />
                                    );
                                }}
                            </FormItem>
                        </Col>
                    </Row>
                </WizardFormConfiguration.Step>,
                <WizardFormConfiguration.Step
                    key={ElasticSnapshotRepositoryFormWizardStep.CLOUD}
                    title="Cloud storage"
                    subTitle=" "
                    validate={['cloudCredentials', 'cloudBucket']}
                    ignoreOutOfDateError={true}
                    hasRequiredFields={true}
                >
                    <BackupCloudCredentialsForm
                        form={form}
                        showLocationPath={false}
                        onCredentialsSelect={handleCredentialsSelect}
                        onLoadingChange={(loading) => {
                            setLoading(loading);
                        }}
                    />
                </WizardFormConfiguration.Step>,
                <WizardFormConfiguration.Step
                    key={ElasticSnapshotRepositoryFormWizardStep.PREVIEW}
                    title="Preview"
                    subTitle=" "
                    validate={[]}
                    hasRequiredFields={true}
                >
                    <Form.Item shouldUpdate>
                        {() => (
                            <div>
                                {' '}
                                <SpaceDescriptions
                                    direction="vertical"
                                    title="Repository details"
                                    size="small"
                                    alignItems="right"
                                >
                                    {selectedCluster.current ? (
                                        <SpaceDescriptions.Item
                                            label="Cluster"
                                            labelStrong
                                        >
                                            <ClusterFormat
                                                cluster={
                                                    selectedCluster.current
                                                }
                                            />
                                        </SpaceDescriptions.Item>
                                    ) : null}
                                    <SpaceDescriptions.Item
                                        label="Name"
                                        labelStrong
                                    >
                                        {form.getFieldValue('name')}
                                    </SpaceDescriptions.Item>
                                    <SpaceDescriptions.Item
                                        label="Type"
                                        labelStrong
                                    >
                                        {form.getFieldValue('type')}
                                    </SpaceDescriptions.Item>
                                </SpaceDescriptions>
                                <AppDivider />
                                {form.getFieldValue('type') === 'url' ? (
                                    <SpaceDescriptions
                                        direction="vertical"
                                        title="Configuration"
                                        size="small"
                                        alignItems="right"
                                    >
                                        <SpaceDescriptions.Item
                                            label="URL"
                                            labelStrong
                                        >
                                            {form.getFieldValue('url')}
                                        </SpaceDescriptions.Item>
                                    </SpaceDescriptions>
                                ) : form.getFieldValue('type') === 's3' ? (
                                    <SpaceDescriptions
                                        direction="vertical"
                                        title="Cloud storage"
                                        size="small"
                                        alignItems="right"
                                    >
                                        {selectedCredentials.current ? (
                                            <SpaceDescriptions.Item
                                                label="Credentials"
                                                labelStrong
                                            >
                                                <CloudProviderTypeFormat
                                                    provider={
                                                        selectedCredentials
                                                            .current?.provider
                                                    }
                                                >
                                                    {
                                                        selectedCredentials
                                                            .current?.name
                                                    }
                                                </CloudProviderTypeFormat>
                                            </SpaceDescriptions.Item>
                                        ) : null}
                                        <SpaceDescriptions.Item
                                            label="Bucket"
                                            labelStrong
                                        >
                                            {form.getFieldValue('cloudBucket')}
                                        </SpaceDescriptions.Item>
                                    </SpaceDescriptions>
                                ) : form.getFieldValue('type') === 'shared' ? (
                                    <div>
                                        <SpaceDescriptions
                                            direction="vertical"
                                            title="Configuration"
                                            size="small"
                                            alignItems="right"
                                        >
                                            <SpaceDescriptions.Item
                                                label="File system location"
                                                labelStrong
                                            >
                                                {form.getFieldValue(
                                                    'storageLocation'
                                                )}
                                            </SpaceDescriptions.Item>
                                            <SpaceDescriptions.Item
                                                label="chunkSize"
                                                labelStrong
                                            >
                                                {form.getFieldValue(
                                                    'chunkSize'
                                                )}
                                            </SpaceDescriptions.Item>
                                            <SpaceDescriptions.Item
                                                label="Max restore bytes per second"
                                                labelStrong
                                            >
                                                {form.getFieldValue(
                                                    'restoreRate'
                                                )}
                                            </SpaceDescriptions.Item>
                                            <SpaceDescriptions.Item
                                                label="Max snapshot bytes per second"
                                                labelStrong
                                            >
                                                {form.getFieldValue(
                                                    'snapshotRate'
                                                )}
                                            </SpaceDescriptions.Item>
                                            <SpaceDescriptions.Item
                                                label="Snapshots compression"
                                                labelStrong
                                            >
                                                <YesNoFormat
                                                    booleanVar={form.getFieldValue(
                                                        'compression'
                                                    )}
                                                />
                                            </SpaceDescriptions.Item>
                                            <SpaceDescriptions.Item
                                                label="Read-only"
                                                labelStrong
                                            >
                                                <YesNoFormat
                                                    booleanVar={form.getFieldValue(
                                                        'readOnly'
                                                    )}
                                                />
                                            </SpaceDescriptions.Item>
                                        </SpaceDescriptions>
                                        <AppDivider />
                                        <SpaceDescriptions
                                            direction="vertical"
                                            title="Storage host"
                                            size="small"
                                            alignItems="right"
                                        >
                                            <SpaceDescriptions.Item
                                                label="host"
                                                labelStrong
                                            >
                                                {
                                                    form.getFieldValue(
                                                        'storageHost'
                                                    )?.[0].title
                                                }
                                            </SpaceDescriptions.Item>
                                        </SpaceDescriptions>
                                    </div>
                                ) : null}
                            </div>
                        )}
                    </Form.Item>
                </WizardFormConfiguration.Step>,
            ]}
        />
    );
}

function getElasticSnapshotFormWizardSteps(type: CcSnapshotRepositoryType) {
    switch (type) {
        case CcSnapshotRepositoryType.S3:
            return [
                ElasticSnapshotRepositoryFormWizardStep.DETAILS,
                // ElasticSnapshotRepositoryFormWizardStep.HOST,
                ElasticSnapshotRepositoryFormWizardStep.CLOUD,
                ElasticSnapshotRepositoryFormWizardStep.PREVIEW,
            ];
    }
    return [];
}
