import React, { useState } from 'react';
import { Button } from 'antd';
import { ButtonProps } from 'antd/lib/button';
import CmonJobsService from '../../../services/cmon/CmonJobsService';
import {
    notifyOperationSuccess,
    NotifyType,
} from '../../Notifications/uiNotification';
import { useDebugContext } from '../../../common/Debug';

export type SendTestEmailProps = {
    emailAddress?: string;
    clusterId?: number;
};

export type SendTestEmailButtonProps = {
    recipients?: SendTestEmailProps[];
} & ButtonProps;

export default SendTestEmailButton;

function SendTestEmailButton({
    recipients,
    ...rest
}: SendTestEmailButtonProps) {
    const [loading, setLoading] = useState<boolean>(false);
    const { log } = useDebugContext();
    const handleSubmit = async () => {
        try {
            if (recipients) {
                setLoading(true);
                await Promise.all(
                    recipients.map((recipient) => {
                        if (recipient.emailAddress) {
                            const result = CmonJobsService.createEmailJobInstance(
                                recipient.clusterId as number,
                                {
                                    job_data: {
                                        recipient: recipient.emailAddress,
                                    },
                                },
                                {
                                    job: {
                                        title: 'Send test email',
                                    },
                                }
                            );
                            notifyOperationSuccess({
                                type: NotifyType.TOAST,
                                title: 'Info!',
                                content:
                                    'Job successfully created to test mail server!',
                                okText: 'Ok',
                                cancelText: 'Close',
                            });
                            return result;
                        } else {
                            return Promise.resolve({});
                        }
                    })
                );
            }
        } catch (err: any) {
            log.error(err);
        } finally {
            setLoading(false);
        }
    };
    return (
        <Button
            type="primary"
            loading={loading}
            onClick={handleSubmit}
            {...rest}
        >
            Send test email
        </Button>
    );
}
