import React from 'react';
import { useDispatch } from 'react-redux';
import { closeActivityQuickView } from '../../appReducer';
import { Button, Space } from 'antd';

type ActivityQuickViewFooterProps = {
    children?: React.ReactNode;
};

export default ActivityQuickViewFooter;

function ActivityQuickViewFooter({ children }: ActivityQuickViewFooterProps) {
    const dispatch = useDispatch();
    const handleCloseClick = () => {
        dispatch(closeActivityQuickView());
    };
    return (
        <Space style={{ justifyContent: 'space-between', width: '100%' }}>
            <span>{children}</span>
            <Button onClick={handleCloseClick}>Close</Button>
        </Space>
    );
}
