export function camelCasePropToUnderscore(obj: {
    [key: string]: any;
}): {
    [key: string]: any;
} {
    const result: { [key: string]: any } = {};
    for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
            result[
                key.replace(/\.?([A-Z])/g, function (x, y) {
                    return '_' + y.toLowerCase();
                })
            ] = obj[key];
        }
    }
    return result;
}

/**
 * @name versionDelta
 * @desc Compares the first versionA to versionB and returns 1 if its newer, 0 if its equal and -1 if it is lower.
 * It works for versions just like '1', '1.2', '1.4.66' for now.
 * @param {String} versionA
 * @param {String} versionB
 * @return {Number}
 **/
export function versionDelta(versionA: string, versionB: string) {
    if ((versionA !== '0' && !versionA) || (versionB !== '0' && !versionB)) {
        return 1;
    }
    if (versionA === versionB) {
        return 0;
    }
    const partsA = versionA.split('.');
    const partsB = versionB.split('.');
    const numParts =
        partsA.length > partsB.length ? partsA.length : partsB.length;

    for (let i = 0; i < numParts; i++) {
        if ((parseInt(partsB[i], 10) || 0) !== (parseInt(partsA[i], 10) || 0)) {
            return (
                ((parseInt(partsB[i], 10) || 0) >
                    (parseInt(partsA[i], 10) || 0) &&
                    -1) ||
                1
            );
        }
    }
    return 1;
}

/**
 * @name versionCompare
 * @desc Returns true if version matches the rule
 * @param {String} version
 * @param {String} rule
 * @return {Number}
 **/
export function versionCompare(version: string, rule: string) {
    let result;
    const firstChar = rule[0];
    switch (firstChar) {
        case '<':
            if (rule[1] === '=') {
                result = versionDelta(version, rule.substring(2)) <= 0;
            } else {
                result = versionDelta(version, rule.substring(1)) < 0;
            }
            break;
        case '>':
            if (rule[1] === '=') {
                result = versionDelta(version, rule.substring(2)) >= 0;
            } else {
                result = versionDelta(version, rule.substring(1)) > 0;
            }
            break;
        case '=':
            result = versionDelta(version, rule.substring(1)) === 0;
            break;
        default:
            result = versionDelta(version, rule) === 0;
    }
    return result;
}

const LOWERCASE_LETTERS_RULE = /[a-z]/;
const UPPERCASE_LETTERS_RULE = /[A-Z]/;
const NUMBER_RULE = /[0-9]/;
const SYMBOLS_RULE = /[!@#$%^&*()+_\-=}{[\]|:;"/?.><,`~]/;
export function validatePasswordStrength(
    password: string,
    {
        length = 8,
        numbers = true,
        lowercase = true,
        uppercase = true,
        symbols = true,
    }: {
        length?: number;
        numbers?: boolean;
        lowercase?: boolean;
        uppercase?: boolean;
        symbols?: boolean | string;
    } = {}
): boolean {
    if (length > 0 && password.length < length) return false;
    if (numbers && !password.match(NUMBER_RULE)) return false;
    if (lowercase && !password.match(LOWERCASE_LETTERS_RULE)) return false;
    if (uppercase && !password.match(UPPERCASE_LETTERS_RULE)) return false;
    return !(
        symbols &&
        !password.match(
            typeof symbols === 'string'
                ? new RegExp(
                      `[${symbols.replace(/[-\]]/g, (m) => {
                          return `\\${m}`;
                      })}]`
                  )
                : SYMBOLS_RULE
        )
    );
}

export function groupArrayBy(items: any[], grouper: (item: any) => any) {
    return items.reduce((result, current) => {
        const key = grouper(current);
        (result[key] = result[key] || []).push(current);
        return result;
    }, {});
}

export function pluralize(count: number, noun: string, suffix = 's') {
    return `${count} ${noun}${count !== 1 ? suffix : ''}`;
}

// CSV formate to array of object
export function csvToArray(csvString: string, delimiter = ',') {
    // slice from start of text to the first \n index
    // use split to create an array from string by delimiter
    const str = csvString
        .replace(/#/g, '')
        .replace(/;/g, '')
        .replace(/ /g, '')
        .replace(/\r/g, '');
    const headers = str.slice(0, str.indexOf('\n')).split(delimiter);

    // slice from \n index + 1 to the end of the text
    // use split to create an array of each csv value row
    const rows = str.slice(str.indexOf('\n') + 1).split('\n');

    const arr = rows.map(function (row) {
        const values = row.split(delimiter);
        const el = headers.reduce(function (object: any, header, index) {
            if (values[index] !== undefined) {
                object[header] = values[index];
                return object;
            }
            return undefined;
        }, {});
        return el;
    });

    // return the array
    return arr.filter((vd) => vd !== undefined);
}

/**
 * Returns the array content represented in an object, based on a given key
 *
 * Example:
 * arrayToMap([{field1: 'a', field2: true}, {field1: 'b', field2: false}], (item) => item.field1)
 * => {
 *  a: {field1: 'a', field2: true},
 *  b: {field1: 'b', field2: false}
 * }
 */
export function arrayToMap(
    array: any[],
    getKey: (item: any) => string | number
) {
    return array.reduce(
        (accumulator: { [key: string | number]: any }, item: any) => {
            accumulator[getKey(item)] = item;
            return accumulator;
        },
        {}
    );
}

/**
 * Converts string to integer hash
 * @link https://werxltd.com/wp/2010/05/13/javascript-implementation-of-javas-string-hashcode-method/
 * @param str
 * @param module
 */
export function getStringHash(str: string, module?: number): number {
    let hash = 0,
        i,
        chr;
    if (str.length === 0) return hash;
    for (i = 0; i < str.length; i++) {
        chr = str.charCodeAt(i);
        hash = (hash << 5) - hash + chr;
        hash |= 0; // Convert to 32bit integer
    }
    return module === undefined ? hash : hash % module;
}

export function getArrayItemByString(str: string, arr: any[]) {
    return arr[Math.abs(getStringHash(str, arr.length))];
}

/**
 * @link https://stackoverflow.com/questions/21646738/convert-hex-to-rgba
 * @param hex
 * @param opacity
 */
export function hexToRgbA(hex: string, opacity: number = 1) {
    if (/^#([A-Fa-f\d]{3}){1,2}$/.test(hex)) {
        let c: any = hex.substring(1).split('');
        if (c.length === 3) {
            c = [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c = '0x' + c.join('');
        return `rgba(${[(c >> 16) & 255, (c >> 8) & 255, c & 255].join(
            ','
        )},${opacity})`;
    }
}

export function debounce(callback: Function, delay: number) {
    let timeout: any;
    return function (...args: any[]) {
        clearTimeout(timeout);
        timeout = setTimeout(() => {
            callback(...args);
        }, delay);
    };
}
