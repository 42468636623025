import React, { useMemo, useCallback } from 'react';
import { Col, Row, InputNumber } from 'antd';
import { Form } from 'antd';
import { FormInstance } from 'antd/lib/form';
import CcCluster from '../../../../../services/models/CcCluster';
import { getHostWithDesc } from '../../../../Nodes/NodeFormat';
import FormItemInlineSwitch from '../../../../../common/DataEntry/FormItemInlineSwitch';
import { CcNodeType } from '../../../../../services/models/CcNode';
import SshCheckableNodesAutocomplete from '../../../../../common/DataEntry/SshCheckeableNodesAutocomplete';
import { DefaultOptionType } from 'rc-select/lib/Select';
import { getTopologyValidator } from '../../../loadBalancerHelpers';

type CreateMaxScaleWhereProps = {
    selectedCluster?: CcCluster;
    form: FormInstance;
};

export const MAX_SCALE_WHERE_INITIAL = {
    maxScaleWhere: {
        portRW: 4008,
        portRR: 4006,
        threads: 4,
        firewall: true,
        appArmor: true,
    },
};

export default CreateMaxScaleWhere;

function CreateMaxScaleWhere({
    selectedCluster,
    form,
}: CreateMaxScaleWhereProps) {
    const getOptions = useCallback((): DefaultOptionType[] | undefined => {
        return selectedCluster
            ?.getHosts()
            .filter((h) => !h.types.includes(CcNodeType.MAXSCALE))
            .map((host) => {
                return {
                    value: `${host.hostname}`,
                    label: getHostWithDesc(host),
                };
            });
    }, [selectedCluster]);

    const options: DefaultOptionType[] | undefined = useMemo(
        () => getOptions(),
        [selectedCluster]
    );

    return (
        <div style={{ minHeight: 450 }}>
            <Row>
                <Col span={24}>
                    <h3>Where to install</h3>
                </Col>
            </Row>
            <Row gutter={[24, 0]}>
                <Col span={24}>
                    <Form.Item
                        name={['maxScaleWhere', 'address']}
                        label={'Server Address'}
                        rules={[
                            {
                                required: true,
                                message: 'Please select server address.',
                            },
                        ]}
                    >
                        <SshCheckableNodesAutocomplete
                            clusterId={selectedCluster?.clusterId}
                            emptyState={null}
                            singleNode={true}
                            formProps={{
                                primaryTitle: null,
                                primaryFormItemProps: {
                                    withLessMarginBottom: true,
                                },
                            }}
                            autocompleteOptions={options}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={[24, 0]}>
                <Col span={24}>
                    <h3>Configure MaxScale</h3>
                </Col>
                <Col span={12}>
                    <Form.Item
                        name={['maxScaleWhere', 'threads']}
                        label={'Threads'}
                        rules={[
                            {
                                required: true,
                                message: 'Enter threads number.',
                            },
                        ]}
                    >
                        <InputNumber
                            min={0}
                            style={{ width: '100%' }}
                            placeholder="Enter threads number."
                        ></InputNumber>
                    </Form.Item>
                </Col>

                <Col span={12}>
                    <Form.Item
                        name={['maxScaleWhere', 'portRR']}
                        label={' RR Port (Port for Round Robin Listener)'}
                        rules={[
                            {
                                required: true,
                                message: 'Enter port number.',
                            },
                        ]}
                    >
                        <InputNumber
                            min={0}
                            style={{ width: '100%' }}
                            placeholder="Enter port number."
                        ></InputNumber>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        name={['maxScaleWhere', 'portRW']}
                        label={' RW Port (Port for Read/Write split Listener)'}
                        rules={[
                            {
                                required: true,
                                message: 'Enter port number.',
                            },
                        ]}
                    >
                        <InputNumber
                            min={0}
                            style={{ width: '100%' }}
                            placeholder="Enter port number."
                        ></InputNumber>
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={[24, 0]}>
                <Col span={12}>
                    <Row justify="space-between" align="middle">
                        <Col span={24}>
                            <FormItemInlineSwitch
                                justify={true}
                                label="Firewall"
                                name={['maxScaleWhere', 'firewall']}
                                labelStrong={false}
                                valuePropName="checked"
                            />
                        </Col>
                    </Row>
                    <Row justify="space-between" align="middle">
                        <Col span={24}>
                            <FormItemInlineSwitch
                                justify={true}
                                label="AppArmor/SELinux"
                                name={['maxScaleWhere', 'appArmor']}
                                labelStrong={false}
                                valuePropName="checked"
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    );
}

export function getCreateMaxScaleWhereValidation(form: FormInstance) {
    return [
        ['maxScaleWhere', 'address'],
        ['maxScaleWhere', 'portRW'],
        ['maxScaleWhere', 'portRR'],
        ['maxScaleWhere', 'threads'],
        getTopologyValidator(form, ['maxScaleWhere', 'address']),
    ];
}
