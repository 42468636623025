
import CmonRequestService, {CmonRequestServiceResponseData} from './CmonRequestService';
import {CmonHostProps} from './models/CmonHost'
import {CmonJobInstanceProps} from './models/CmonJobInstance'


export interface CheckClusterNameRequestData {
    new_cluster_name?: string;
    request_created?: string;
    request_id?: number;
    [key: string]: any;
}

export interface GetSupportedClusterTypesRequestData {
    request_created?: string;
    request_id?: number;
    [key: string]: any;
}

export interface GetSshCredentialsRequestData {
    cluster_id?: number;
    request_created?: string;
    request_id?: number;
    [key: string]: any;
}

export interface CheckHostsRequestData {
    check_if_already_registered?: boolean;
    check_job?: boolean;
    check_ssh_sudo?: boolean;
    job?: CmonJobInstanceProps | {job_spec: string;};
    nodes?: CmonHostProps[];
    request_created?: string;
    request_id?: number;
    [key: string]: any;
}


export default class CmonDiscoveryService extends CmonRequestService {
    
    public static module(): string {
        return 'discovery';
    }
    
    public static async checkClusterName(
        data: CheckClusterNameRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('checkClusterName', data, opts);
    }

    public static async getSupportedClusterTypes(
        data: GetSupportedClusterTypesRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('getSupportedClusterTypes', data, opts);
    }

    public static async getSshCredentials(
        data: GetSshCredentialsRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('getSshCredentials', data, opts);
    }

    public static async checkHosts(
        data: CheckHostsRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('checkHosts', data, opts);
    }

}
