import React from 'react';
import { Button, Space } from 'antd';
import ButtonWithForm from '../../../common/General/ButtonWithForm';
import MailServerForm from './MailServerForm';
import CmonConfigService from '../../../services/cmon/CmonConfigService';
import useFetch from '../../../common/useFetch';

export type SMTPButtonProps = {};

export default MailServerButton;

function MailServerButton({}: SMTPButtonProps) {
    const { data, loading, loaded, error } = useFetch<any>({
        name: 'main-server',
        autoFetch: true,
        fetchFn: async () => {
            return await CmonConfigService.getmailserver({});
        },
        cancelFn: async ({ requestId }) => {
            await CmonConfigService.cancelRequest(requestId);
        },
    });

    const smtpConfigured =
        data?.smtp_server &&
        data.smtp_server.hostname &&
        data.smtp_server.hostname;

    const isLoaded = loaded || error;
    return (
        <Space>
            {isLoaded && !smtpConfigured ? (
                <>You don't have a mail server configured.</>
            ) : null}
            <ButtonWithForm
                button={
                    <Button type="link" disabled={!isLoaded} loading={loading}>
                        {isLoaded && !smtpConfigured ? (
                            <>Configure now</>
                        ) : (
                            <>Configure mail server</>
                        )}
                    </Button>
                }
                form={<MailServerForm />}
            ></ButtonWithForm>
        </Space>
    );
}
