
import CmonRequestService, {CmonRequestServiceResponseData} from './CmonRequestService';


export interface GetLogEntriesRequestData {
    ascending?: boolean;
    cluster_name?: string;
    limit?: number;
    request_created?: string;
    request_id?: number;
    [key: string]: any;
}

export interface GetLogEntryRequestData {
    cluster_id?: number;
    message_id?: number;
    request_created?: string;
    request_id?: number;
    [key: string]: any;
}

export interface GetLogStatisticsRequestData {
    request_created?: string;
    request_id?: number;
    [key: string]: any;
}

export interface ListCollectedLogsRequestData {
    cluster_id?: number;
    [key: string]: any;
}

export interface GetCollectedLogRequestData {
    cluster_id?: number;
    filename?: string;
    hostname?: string;
    [key: string]: any;
}


export default class CmonLogService extends CmonRequestService {
    
    public static module(): string {
        return 'log';
    }
    
    public static async getLogEntries(
        data: GetLogEntriesRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('getLogEntries', data, opts);
    }

    public static async getLogEntry(
        data: GetLogEntryRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('getLogEntry', data, opts);
    }

    public static async getLogStatistics(
        data: GetLogStatisticsRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('getLogStatistics', data, opts);
    }

    public static async listCollectedLogs(
        data: ListCollectedLogsRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('listCollectedLogs', data, opts);
    }

    public static async getCollectedLog(
        data: GetCollectedLogRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('getCollectedLog', data, opts);
    }

}
