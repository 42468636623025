import { Button } from 'antd';

function RegistrationLicenseFailed() {
    const handleContinue = () => {
        window.location.assign(`/dashboard`);
    };

    return (
        <>
            <div>
                <h3>Trial License Failed</h3>
                The trial license failed to be activated! You will be using the
                Community Edition with limited features. Please contact{' '}
                <a href="mailto:sales@severalnines.com">
                    sales@severalnines.com
                </a>{' '}
                to request a trial license which will unlock all features to
                evaluate for a period of time
            </div>
            <br />
            <Button type="primary" onClick={handleContinue}>
                Continue
            </Button>
        </>
    );
}

export default RegistrationLicenseFailed;
