import React from 'react';
import BackupToolPBMSetupModal from '../BackupToolPBMSetupModal';
import JobActionButton, {
    JobActionButtonProps,
} from '../../../Jobs/JobActionButton';
import ButtonWithForm from '../../../../common/General/ButtonWithForm';
import CcCluster from '../../../../services/models/CcCluster';
import { CmonJobInstanceCommand } from '../../../../services/cmon/models/CmonJobInstance';

export default BackupToolPBMSetupActionButton;

export type BackupToolPBMSetupActionButtonProps = Omit<
    JobActionButtonProps,
    'command'
> & {
    cluster: CcCluster;
    children?: React.ReactNode;
    onSuccess?: () => void;
    reinstall?: boolean;
};

function BackupToolPBMSetupActionButton({
    cluster,
    children,
    onSuccess,
    reinstall = false,
    ...rest
}: BackupToolPBMSetupActionButtonProps) {
    return (
        <ButtonWithForm
            button={
                <JobActionButton
                    command={
                        CmonJobInstanceCommand.PBMAGENT.toLowerCase() as CmonJobInstanceCommand
                    }
                    clusterId={cluster.clusterId}
                    {...rest}
                >
                    {`${reinstall ? 'Reinstall' : 'Install'} Percona Backup`}
                </JobActionButton>
            }
            form={
                <BackupToolPBMSetupModal
                    cluster={cluster}
                    reinstall={reinstall}
                    onSuccess={onSuccess}
                />
            }
        />
    );
}
