import CcDbStatsDbTable, { CcDbStatsDbTableProps } from './CcDbStatsDbTable';

export type CcDbStatsDbProps = {
    data_size?: number;
    db_name?: string;
    index_size?: number;
    row_count?: number;
    table_count?: number;
    tables?: CcDbStatsDbTableProps[];
};

export default class CcDbStatsDb {
    public dataSize?: number;
    public dbName?: string;
    public indexSize?: number;
    public rowCount?: number;
    public tableCount?: number;
    public tables?: CcDbStatsDbTable[];

    constructor(props: CcDbStatsDbProps) {
        this.dataSize = props.data_size;
        this.dbName = props.db_name;
        this.indexSize = props.index_size;
        this.rowCount = props.row_count;
        this.tableCount = props.table_count;
        this.tables = props.tables?.map((t) => new CcDbStatsDbTable(t));
    }
}
