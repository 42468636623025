import React from 'react';
import CcCluster from '../../../../services/models/CcCluster';
import { CmonJobInstanceCommand } from '../../../../services/cmon/models/CmonJobInstance';
import TypographyText from '../../../../common/TypographyText';
import {
    CcBackupMethod,
    isBackupMethodPgBackRest,
} from '../../../../services/models/CcBackup';
import CcPostgreSqlNode, {
    CcPostgreSqlNodeArchiveMode,
} from '../../../../services/models/CcPostgreSqlNode';
import FormItemInlineSwitch from '../../../../common/DataEntry/FormItemInlineSwitch';
import BackupConfigurationFields from '../../FormParts/BackupConfigurationFields';
import { Alert, Col, Form, Row, Space } from 'antd';
import { FormInstance } from 'antd/lib/form';
import BackupConfigurationFieldsWithTool from '../../FormParts/BackupConfigurationFieldsWithTool';
import BackupFormConfigurator from '../../Config/BackupFormConfigurator';

export default BackupConfigurationPostgresForm;
export type BackupConfigurationPostgresFormProps = {
    form: FormInstance;
    configurator: BackupFormConfigurator;
    cluster?: CcCluster;
    clusterPreselected?: boolean;
    onClusterSelect?: (cluster?: CcCluster) => void;
};

function BackupConfigurationPostgresForm({
    form,
    configurator,
    cluster,
    clusterPreselected,
    onClusterSelect,
    ...rest
}: BackupConfigurationPostgresFormProps) {
    const getReconfigureMessage = () => {
        const node = getPostgreSqlPrimaryNode();
        if (
            node &&
            cluster?.hasBackupTool() &&
            node.isPitrMethodLocalWalArchive()
        ) {
            return (
                <span>
                    PgBackRest tool is installed, but WAL archiving method is
                    set to 'local WAL archiving'
                    <br />{' '}
                    <TypographyText strong>
                        To use PgBackRest again reconfgure is required
                    </TypographyText>
                </span>
            );
        }
        return null;
    };

    const isMethodPgBase = () => {
        return form.getFieldValue('method') === CcBackupMethod.PGBASEBACKUP;
    };

    const isMethodPgBackrest = () => {
        return isBackupMethodPgBackRest(form.getFieldValue('method'));
    };

    const getPostgreSqlPrimaryNode = () => {
        return cluster?.primaryNode as CcPostgreSqlNode | undefined;
    };
    const isArchiveModeEnabled = () => {
        const node = getPostgreSqlPrimaryNode();
        return [
            CcPostgreSqlNodeArchiveMode.ON,
            CcPostgreSqlNodeArchiveMode.ALWAYS,
        ].includes(node?.archiveMode as CcPostgreSqlNodeArchiveMode);
    };

    const showPitrAvailableSwitch = () => {
        const node = getPostgreSqlPrimaryNode();
        if (!node) {
            return false;
        }
        const method = form.getFieldValue('method');
        if (method === CcBackupMethod.PGDUMP) {
            return false;
        }

        return true;
    };

    const getPitrAvailableSwitch = () => {
        const node = getPostgreSqlPrimaryNode();
        let pitrEnabled =
            form.getFieldValue('method') !== CcBackupMethod.PGDUMP;
        if (pitrEnabled) {
            if (!isArchiveModeEnabled()) {
                pitrEnabled = false;
            } else if (isMethodPgBackrest()) {
                if (
                    !cluster?.hasBackupTool() &&
                    !node?.isPitrMethodPGBackRest()
                ) {
                    pitrEnabled = false;
                }
            } else if (!node?.isPitrMethodLocalWalArchive()) {
                pitrEnabled = false;
            }
        }
        return (
            <FormItemInlineSwitch
                label="PITR enabled"
                justify
                noMargin
                checked={pitrEnabled}
                disabled
                extraOffSwitch={
                    <TypographyText muted>
                        You can enable point in time recovery in the Backup
                        Settings
                    </TypographyText>
                }
            />
        );
    };

    const renderFields = (size: 'small' | 'large' = 'small') => {
        const md = size === 'large' ? 24 : 12;
        return (
            <div>
                <BackupConfigurationFields
                    configurator={configurator}
                    form={form}
                    cluster={cluster}
                    onClusterSelect={onClusterSelect}
                    clusterPreselected={clusterPreselected}
                    size={size}
                />
                {showPitrAvailableSwitch() && (
                    <Row gutter={[24, 0]}>
                        <Col xs={24} sm={24} md={md}>
                            {getPitrAvailableSwitch()}
                        </Col>
                    </Row>
                )}
                {configurator.available('cloudUpload') && (
                    <Row gutter={[24, 0]}>
                        <Col xs={24} sm={24} md={md}>
                            <FormItemInlineSwitch
                                justify
                                noMargin
                                name="cloudUpload"
                                label="Upload backup to cloud"
                                valuePropName="checked"
                            />
                        </Col>
                    </Row>
                )}
            </div>
        );
    };

    return (
        <div className="BackupConfigurationPostgresForm">
            <Form.Item shouldUpdate noStyle>
                {() => {
                    return (
                        <div>
                            <BackupConfigurationFieldsWithTool
                                form={form}
                                configurator={configurator}
                                renderFields={renderFields}
                                toolCommand={CmonJobInstanceCommand.PGBACKREST}
                                cluster={cluster}
                                reconfigureMessage={getReconfigureMessage()}
                            />
                            <Space direction="vertical">
                                <span></span>
                                {isMethodPgBase() &&
                                getPostgreSqlPrimaryNode()?.isPitrMethodPGBackRest() ? (
                                    <Alert
                                        type="warning"
                                        message="pgBackRest will be deconfigured if switching the WAL archiving method from 'pgBackRest' to 'local WAL archiving'."
                                    />
                                ) : null}
                                {isMethodPgBackrest() &&
                                getPostgreSqlPrimaryNode()?.isPitrMethodLocalWalArchive() ? (
                                    <Alert
                                        type="warning"
                                        message="PITR using pg_basebackup will no longer be possible if switching the WAL archiving method from 'local WAL archiving' to 'pgBackRest'."
                                    />
                                ) : null}
                            </Space>
                        </div>
                    );
                }}
            </Form.Item>
        </div>
    );
}
