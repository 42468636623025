import React, { useMemo, useRef, useState } from 'react';
import { ModalDefaultFormProps } from '../ModalDefaultForm';
import MotionShake, { MotionApi } from '../motion/MotionShake';
import { notifyConfirmCloseWindow } from '../../components/Notifications/uiNotification';
import { Form } from 'antd';
import { FormInstance } from 'antd/es';
import WizardConfiguration, {
    WizardConfigurationStep,
} from '@severalnines/bar-frontend-components/build/lib/Navigation/Wizard/WizardConfiguration';
import { WizardConfigurationProps } from '@severalnines/bar-frontend-components/build/lib/Navigation/Wizard/WizardConfiguration';
import WizardSelectCardStep, {
    WizardSelectCardStepItem,
} from '@severalnines/bar-frontend-components/build/lib/Navigation/Wizard/WizardSelectCardStep';
import { WizardFormConfigurationProps } from '@severalnines/bar-frontend-components/build/lib/Navigation/Wizard/WizardFormConfiguration';
import ModalDefault from '../../common/ModalDefault';

export default WizardConfigurationModal;

export type WizardConfigurationModalOption<T = string> = {
    key: T;
    item: React.ReactElement<WizardSelectCardStepItem>;
    step: WizardConfigurationStep;
};

export type WizardConfigurationModalProps = {
    modalProps?: ModalDefaultFormProps;
    wizardConfigurationProps?: Omit<WizardConfigurationProps, 'steps'>;
    form?: FormInstance;
    onSuccess?: (option: WizardConfigurationModalOption | undefined) => void;
    onCancel?: () => void;
    options?: WizardConfigurationModalOption[];
    title?: React.ReactNode;
    description?: React.ReactNode;
    defaultActiveKey?: string;
    closeOnCancel?: boolean;
};

export type FormResultProps = {
    onSuccess?: () => void;
    onError?: () => void;
    onCancel?: () => void;
};

function WizardConfigurationModal({
    modalProps,
    onSuccess,
    onCancel,
    form: parentForm,
    options,
    title,
    description,
    wizardConfigurationProps,
    defaultActiveKey,
    closeOnCancel = false,
}: WizardConfigurationModalProps) {
    const [confirmClose, setConfirmClose] = useState(false);
    const [selectedOption, setSelectedOption] = useState<
        WizardConfigurationModalOption | undefined
    >(() =>
        defaultActiveKey && options
            ? options.find((option) => option.key === defaultActiveKey)
            : undefined
    );
    const animationRef = useRef<MotionApi | undefined>();
    const [localForm] = Form.useForm();
    const form = parentForm || localForm;

    const handleFormTouchedChange = (touched: boolean) => {
        setConfirmClose(touched);
    };
    const handleFormStepErrorInsist = (err: Error) => {
        if (animationRef.current) {
            animationRef.current.animate();
        }
    };

    const handleCancel = () => {
        if (confirmClose) {
            notifyConfirmCloseWindow({
                onOk: () => {
                    onCancel?.();
                },
            });
        } else {
            onCancel?.();
        }
    };

    const handleSuccess = () => {
        onSuccess?.(selectedOption);
    };
    const handleOptionSelect = (value: string) => {
        setSelectedOption(
            options ? options.find((option) => option.key === value) : undefined
        );
    };

    const optionItems = useMemo(() => {
        return options ? options.map((option) => option.item) : [];
    }, []);

    const optionSteps = useMemo(() => {
        return options
            ? options.map((option) => ({
                  ...option.step,
                  content: React.cloneElement<
                      Partial<WizardFormConfigurationProps> & FormResultProps
                  >(option.step.content, {
                      form,
                      onTouchedChange: handleFormTouchedChange,
                      onStepErrorInsist: handleFormStepErrorInsist,
                      onSuccess: handleSuccess,
                      onCancel: () => {
                          setSelectedOption(undefined);
                          if (closeOnCancel) {
                              handleCancel();
                          }
                      },
                      cancelButtonText: 'Back',
                  }),
              }))
            : [];
    }, [options, handleSuccess]);

    return (
        <ModalDefault
            title={selectedOption?.step.title}
            form={form}
            animateHeight={true}
            visible={true}
            onCancel={handleCancel}
            modalRender={(content) => (
                <MotionShake ref={animationRef}>{content}</MotionShake>
            )}
            {...modalProps}
        >
            <WizardConfiguration
                activeStep={selectedOption?.key || 'options'}
                steps={[
                    {
                        step: 'options',
                        content: (
                            <WizardSelectCardStep
                                title={title as string}
                                description={description as string}
                                onSelect={handleOptionSelect}
                            >
                                {optionItems}
                            </WizardSelectCardStep>
                        ),
                    },
                    ...optionSteps,
                ]}
                {...wizardConfigurationProps}
            />
        </ModalDefault>
    );
}
