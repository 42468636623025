
import CcNode, { CcNodeProps } from '../../models/CcNode';


export interface CmonRedisSentinelHostProps  extends CcNodeProps {
    sentinel?: string;

}



export default class CmonRedisSentinelHost extends CcNode {
    public sentinel?: string;


    constructor(props: CmonRedisSentinelHostProps) {
        super(props);
        this.sentinel = props.sentinel;

    }

}
