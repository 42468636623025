const result = {
    configuration: {
        extended_info: [
            {
                backup: [
                    {
                        name: 'disable_backup_email',
                        category: 8,
                        current_value: false,
                        default_value: false,
                        description:
                            'This setting controls if emails are sent or not if a backup finished or failed. This feature is disabled by default, meaning backup emails are sent.',
                        keys: ['disable_backup_email', 'disable_backup_emails'],
                        sql_keys: ['DISABLE_BACKUP_EMAIL'],
                        value_type: 'Bool',
                    },
                    {
                        name: 'backup_create_checksum',
                        category: 8,
                        current_value: 'true',
                        default_value: true,
                        description:
                            'Configures cmon if it has to calculate checksum (md5sum) on the created backup files and verify them.',
                        keys: ['backup_create_checksum', 'backup_create_hash'],
                        sql_keys: ['BACKUP_CREATE_HASH'],
                        value_type: 'String',
                    },
                    {
                        name: 'backup_encryption_key',
                        category: 8,
                        current_value: '<hidden>',
                        default_value: null,
                        description:
                            'The AES encryption key to encrypt backups. The format of the string is base64 encoded.',
                        keys: ['backup_encryption_key'],
                        sql_keys: [],
                        value_type: 'String',
                    },
                    {
                        name: 'pitr_retention_hours',
                        category: 8,
                        current_value: 0,
                        default_value: 0,
                        description:
                            'Retention hours (to erase old WAL archive logs) for PITR.',
                        keys: ['pitr_retention_hours', 'wal_retention_hours'],
                        sql_keys: ['PITR_RETENTION_HOURS'],
                        value_type: 'Int',
                    },
                    {
                        name: 'pgbackrest_stanza_name',
                        category: 8,
                        current_value: null,
                        default_value: null,
                        description:
                            'The name of the stanza to be used to save and restore backups of the cluster.',
                        keys: ['pgbackrest_stanza_name'],
                        sql_keys: ['PGBACKREST_STANZA_NAME'],
                        value_type: 'String',
                    },
                    {
                        name: 'pgbackrest_repo_hostname',
                        category: 8,
                        current_value: null,
                        default_value: null,
                        description:
                            'The name of the repository host where to save backup data of PgBackRest.',
                        keys: ['pgbackrest_repo_hostname'],
                        sql_keys: ['PGBACKREST_REPO_HOSTNAME'],
                        value_type: 'String',
                    },
                    {
                        name: 'pgbackrest_repo_path',
                        category: 8,
                        current_value: null,
                        default_value: null,
                        description:
                            'The path of the repository directory where to save backup data of PgBackRest.',
                        keys: ['pgbackrest_repo_path'],
                        sql_keys: ['PGBACKREST_REPO_PATH'],
                        value_type: 'String',
                    },
                    {
                        name: 'pgbackrest_cipher_type',
                        category: 8,
                        current_value: null,
                        default_value: null,
                        description:
                            'Chiper to be used to encrypt backup repository of PgBackRest.',
                        keys: ['pgbackrest_cipher_type'],
                        sql_keys: ['PGBACKREST_CIPHER_TYPE'],
                        value_type: 'String',
                    },
                    {
                        name: 'pgbackrest_cipher_pass',
                        category: 8,
                        current_value: '<hidden>',
                        default_value: null,
                        description:
                            'The AES key to be used to encrypt backup repository of PgBackRest.',
                        keys: ['pgbackrest_cipher_pass'],
                        sql_keys: [],
                        value_type: 'String',
                    },
                    {
                        name: 'netcat_port',
                        category: 8,
                        current_value: '9999,9990-9998',
                        default_value: '9999,9990-9998',
                        description:
                            "List of netcat ports and port ranges used to stream backups. Defaults to '9999,9990-9998' and port 9999 will be preferred if available.",
                        keys: ['netcat_port'],
                        sql_keys: ['NETCAT_PORT'],
                        value_type: 'String',
                    },
                    {
                        name: 'backupdir',
                        category: 8,
                        current_value: '/root/backups',
                        default_value: null,
                        description:
                            'The default backup directory, to be pre-filled in Frontend.',
                        keys: ['backupdir'],
                        sql_keys: ['BACKUPDIR'],
                        value_type: 'String',
                    },
                    {
                        name: 'datadir_backup_path',
                        category: 8,
                        current_value: null,
                        default_value: null,
                        description:
                            'During restore/rebuild operations a backup (filesystem copy) of the existing datadir maybe performed (user decides). Unless specified, the default datadir backup location is DATADIR_bak, e.g /var/lib/mysql_bak if the datadir is /var/lib/mysql.',
                        keys: ['datadir_backup_path'],
                        sql_keys: [],
                        value_type: 'String',
                    },
                    {
                        name: 'backup_subdir',
                        category: 8,
                        current_value: 'BACKUP-%I',
                        default_value: null,
                        description:
                            'Set the name of the backup subdirectory. This string may hold standard "%X" field separators, the "%06I" for example will be replaced by the numerical ID of the backup in 6 field wide format that uses \'0\' as leading fill characters.\nDefault value: "BACKUP-%I"\nHere is the list of fields the backend currently supports:\n- B The date and time when the backup creation was beginning.\n- H The name of the backup host, the host that created the backup.\n- i The numerical ID of the cluster.\n- I The numerical ID of the backup.\n- J The numerical ID of the job that created the backup.\n- M The backup method (e.g. "mysqldump").\n- O The name of the user who initiated the backup job.\n- S The name of the storage host, the host that stores the backup files.\n- % The percent sign itself. Use two percent signs, "%%" the same way the standard printf() function interprets it as one percent sign.',
                        keys: ['backup_subdir'],
                        sql_keys: ['BACKUP_SUBDIR'],
                        value_type: 'String',
                    },
                    {
                        name: 'backup_retention',
                        category: 8,
                        current_value: 31,
                        default_value: 31,
                        description:
                            'Setting of how many days to keep the backups. Backups matching retention period are removed.',
                        keys: ['backup_retention'],
                        sql_keys: ['BACKUP_RETENTION'],
                        value_type: 'Int',
                    },
                    {
                        name: 'backup_cloud_retention',
                        category: 8,
                        current_value: 180,
                        default_value: 180,
                        description:
                            'Setting of how many days to keep the backups uploaded to a cloud. Backups matching retention period are removed.',
                        keys: ['backup_cloud_retention'],
                        sql_keys: ['BACKUP_CLOUD_RETENTION'],
                        value_type: 'Int',
                    },
                    {
                        name: 'backup_n_safety_copies',
                        category: 8,
                        current_value: 1,
                        default_value: 1,
                        description:
                            'Setting of how many completed full backups will be kept regardless of their retention status.',
                        keys: ['backup_n_safety_copies'],
                        sql_keys: ['BACKUP_N_SAFETY_COPIES'],
                        value_type: 'Int',
                    },
                    {
                        name: 'backup_pre_script',
                        category: 8,
                        current_value: null,
                        default_value: null,
                        description:
                            "This script is executed before the backup happens, but after a candidate has been elected and it is possible to continue the backup process.\n If the script returns non-zero the backup will be aborted.\n If the script specified script does not exists the backup will be aborted.\n Six arguments are supplied to the script and set if they are known, else empty: arg1='backup id'  arg2='backup method' arg3='backup type', arg4='backup hostname' arg5='storage hostname' arg6='storage dir'and passed like this: 'scripname arg1 arg2 arg3 arg4 arg5 arg6'\n The script must be accessible on the controller and executable.",
                        keys: ['backup_pre_script'],
                        sql_keys: ['BACKUP_PRE_SCRIPT'],
                        value_type: 'String',
                    },
                    {
                        name: 'backup_post_script',
                        category: 8,
                        current_value: null,
                        default_value: null,
                        description:
                            "This script is executed after the backup happens, but after a candidate has been elected and it is possible to continue the backup process.\n If the script returns non-zero the backup will be aborted.\n If the script specified script does not exists the backup will be aborted.\n Seven arguments are supplied to the script and set if they are known, else empty: arg1='backup id'  arg2='backup method' arg3='backup type', arg4='backup hostname' arg5='storage hostname' arg6='storage dir' arg7='backup status' and passed like this: 'scripname arg1 arg2 arg3 arg4 arg5 arg6 arg7'\n The script must be accessible on the controller and executable.",
                        keys: ['backup_post_script'],
                        sql_keys: ['BACKUP_POST_SCRIPT'],
                        value_type: 'String',
                    },
                ],
            },
            {
                cluster: [
                    {
                        name: 'cluster_type',
                        category: 10,
                        current_value: 'postgresql_single',
                        default_value: null,
                        description: 'The type of the cluster.',
                        keys: ['cluster_type', 'type'],
                        sql_keys: [],
                        value_type: 'String',
                    },
                    {
                        name: 'name',
                        category: 10,
                        current_value: 'LXD Postgresql',
                        default_value: null,
                        description:
                            'The name of the cluster for easy identification.',
                        keys: ['name', 'cluster_name'],
                        sql_keys: ['CLUSTER_NAME'],
                        value_type: 'String',
                    },
                    {
                        name: 'enable_node_autorecovery',
                        category: 10,
                        current_value: true,
                        default_value: true,
                        description: 'Node auto-recovery setting.',
                        keys: ['enable_node_autorecovery'],
                        sql_keys: ['ENABLE_NODE_AUTORECOVERY'],
                        value_type: 'Bool',
                    },
                    {
                        name: 'enable_cluster_autorecovery',
                        category: 10,
                        current_value: true,
                        default_value: true,
                        description:
                            'If true the Cmon Controller will perform cluster auto-recovery, if false no cluster recovery will be done automatically.',
                        keys: ['enable_cluster_autorecovery'],
                        sql_keys: ['ENABLE_CLUSTER_AUTORECOVERY'],
                        value_type: 'Bool',
                    },
                    {
                        name: 'cluster_id',
                        category: 10,
                        current_value: 10,
                        default_value: null,
                        description: 'The cluster ID.',
                        keys: ['cluster_id'],
                        sql_keys: [],
                        value_type: 'Int',
                    },
                    {
                        name: 'db_configdir',
                        category: 10,
                        current_value: '/etc/mysql/',
                        default_value: null,
                        description: 'The database server config directory.',
                        keys: ['db_configdir', 'configdir'],
                        sql_keys: ['CONFIGDIR'],
                        value_type: 'String',
                    },
                    {
                        name: 'created_by_job',
                        category: 10,
                        current_value: 26991,
                        default_value: null,
                        description: 'The ID of the job created this cluster.',
                        keys: ['created_by_job'],
                        sql_keys: ['CREATED_BY_JOB'],
                        value_type: 'Int',
                    },
                    {
                        name: 'ssh_keypath',
                        category: 10,
                        current_value: '/root/.ssh/id_rsa',
                        default_value: null,
                        description:
                            'The SSH key file used for connection to nodes.',
                        keys: ['ssh_keypath', 'ssh_identity', 'identity_file'],
                        sql_keys: ['SSH_KEYPATH', 'SSH_IDENTITY'],
                        value_type: 'String',
                    },
                    {
                        name: 'ssh_keydata',
                        category: 10,
                        current_value: '<hidden>',
                        default_value: null,
                        description: 'base64 encoded SSH key',
                        keys: ['ssh_keydata'],
                        sql_keys: [],
                        value_type: 'String',
                    },
                    {
                        name: 'owner',
                        category: 10,
                        current_value: 5,
                        default_value: null,
                        description:
                            'The Cmon user ID of the owner of the cluster object.',
                        keys: ['owner'],
                        sql_keys: ['OWNER'],
                        value_type: 'Int',
                    },
                    {
                        name: 'group_owner',
                        category: 10,
                        current_value: 1,
                        default_value: null,
                        description:
                            'The Cmon group ID of the group that owns the cluster object.',
                        keys: ['group_owner'],
                        sql_keys: ['GROUP_OWNER'],
                        value_type: 'Int',
                    },
                    {
                        name: 'cdt_path',
                        category: 10,
                        current_value: '/',
                        default_value: null,
                        description:
                            'The location of the cluster object in the Cmon Drirectory Tree.',
                        keys: ['cdt_path'],
                        sql_keys: ['CDT_PATH'],
                        value_type: 'String',
                    },
                    {
                        name: 'tags',
                        category: 10,
                        current_value: null,
                        default_value: null,
                        description: 'A set of strings the user can specify.',
                        keys: ['tags'],
                        sql_keys: ['TAGS'],
                        value_type: 'String',
                    },
                    {
                        name: 'acl',
                        category: 10,
                        current_value: null,
                        default_value: null,
                        description:
                            'The Access Control List as a string controlling the access to the cluster object.',
                        keys: ['acl'],
                        sql_keys: ['ACL'],
                        value_type: 'String',
                    },
                    {
                        name: 'basedir',
                        category: 10,
                        current_value: '/usr/local/mysql/',
                        default_value: null,
                        description: 'The basedir for MySQL installation.',
                        keys: ['basedir', 'mysql_basedir'],
                        sql_keys: ['MYSQL_BASEDIR'],
                        value_type: 'String',
                    },
                    {
                        name: 'mysql_scriptdir',
                        category: 10,
                        current_value: '/usr/local/mysql/scripts/',
                        default_value: null,
                        description: 'The scripts dir of MySQL installation.',
                        keys: ['mysql_scriptdir', 'scriptdir'],
                        sql_keys: ['SCRIPTDIR'],
                        value_type: 'String',
                    },
                    {
                        name: 'mysql_socket',
                        category: 10,
                        current_value: '',
                        default_value: null,
                        description:
                            'The local unix socket file to be used for MySQL connection (if set TCP will not be used).',
                        keys: ['mysql_socket'],
                        sql_keys: [],
                        value_type: 'String',
                    },
                    {
                        name: 'enable_legacy_sql_stats',
                        category: 10,
                        current_value: true,
                        default_value: true,
                        description:
                            'Enabling the legacy stats collection. When it is false, the Prometheus shall be used instead.',
                        keys: ['enable_legacy_sql_stats'],
                        sql_keys: [],
                        value_type: 'Bool',
                    },
                    {
                        name: 'stagingdir',
                        category: 10,
                        current_value: '/root/s9s_tmp',
                        default_value: null,
                        description: 'A staging path for temporary files.',
                        keys: ['stagingdir', 'staging_dir'],
                        sql_keys: ['STAGING_DIR'],
                        value_type: 'String',
                    },
                    {
                        name: 'ndb_connectstring',
                        category: 10,
                        current_value: '127.0.0.1:1186',
                        default_value: '127.0.0.1:1186',
                        description:
                            'The NDB connect string setting for MySQL Cluster.',
                        keys: ['ndb_connectstring'],
                        sql_keys: ['NDB_CONNECTSTRING'],
                        value_type: 'String',
                    },
                    {
                        name: 'ndbd_datadir',
                        category: 10,
                        current_value: null,
                        default_value: null,
                        description: 'The datadir of the NDBD nodes.',
                        keys: ['ndbd_datadir'],
                        sql_keys: ['NDBD_DATADIR'],
                        value_type: 'String',
                    },
                    {
                        name: 'mgmd_datadir',
                        category: 10,
                        current_value: null,
                        default_value: null,
                        description: 'The datadir of the NDB MGMD nodes.',
                        keys: ['mgmd_datadir'],
                        sql_keys: ['MGMD_DATADIR'],
                        value_type: 'String',
                    },
                    {
                        name: 'add_node_timeout',
                        category: 10,
                        current_value: 28800,
                        default_value: 28800,
                        description:
                            'A timeout value for waiting the new node to reach the synchronized state.',
                        keys: ['add_node_timeout', 'node_recovery_timeout'],
                        sql_keys: [],
                        value_type: 'Int',
                    },
                    {
                        name: 'add_cluster_timeout',
                        category: 10,
                        current_value: 28800,
                        default_value: 28800,
                        description:
                            'Timeout value for cluster start during deployment (currently NDB only).',
                        keys: ['add_cluster_timeout'],
                        sql_keys: [],
                        value_type: 'Int',
                    },
                    {
                        name: 'osuser',
                        category: 10,
                        current_value: 'root',
                        default_value: null,
                        description:
                            'The SSH username used for accessing nodes.',
                        keys: ['osuser', 'os_user', 'ssh_user'],
                        sql_keys: ['OS_USER', 'SSH_USER'],
                        value_type: 'String',
                    },
                    {
                        name: 'repl_user',
                        category: 10,
                        current_value: 'cmon_replication',
                        default_value: null,
                        description: 'The replication username.',
                        keys: ['repl_user'],
                        sql_keys: ['REPL_USER'],
                        value_type: 'String',
                    },
                    {
                        name: 'repl_password',
                        category: 10,
                        current_value: '<hidden>',
                        default_value: null,
                        description: 'The replication user password.',
                        keys: ['repl_password'],
                        sql_keys: [],
                        value_type: 'String',
                    },
                    {
                        name: 'mgmnode_addresses',
                        category: 10,
                        current_value: null,
                        default_value: null,
                        description: 'The NDB MGMD node addresses.',
                        keys: ['mgmnode_addresses'],
                        sql_keys: [],
                        value_type: 'StringList',
                    },
                    {
                        name: 'datanode_addresses',
                        category: 10,
                        current_value: null,
                        default_value: null,
                        description: 'The NDBD node addresses.',
                        keys: ['datanode_addresses'],
                        sql_keys: [],
                        value_type: 'StringList',
                    },
                    {
                        name: 'vendor',
                        category: 10,
                        current_value: 'postgres',
                        default_value: null,
                        description:
                            'The database vendor name used for deployments.',
                        keys: ['vendor', 'galera_vendor', 'mongodb_vendor'],
                        sql_keys: ['VENDOR'],
                        value_type: 'String',
                    },
                    {
                        name: 'galera_version',
                        category: 10,
                        current_value: null,
                        default_value: null,
                        description: 'The used Galera version number.',
                        keys: ['galera_version'],
                        sql_keys: ['GALERA_VERSION'],
                        value_type: 'String',
                    },
                    {
                        name: 'mysql_version',
                        category: 10,
                        current_value: '14',
                        default_value: null,
                        description:
                            'The used database server version for deployments.',
                        keys: ['mysql_version', 'server_version'],
                        sql_keys: ['SERVER_VERSION'],
                        value_type: 'String',
                    },
                    {
                        name: 'postgresql_server_addresses',
                        category: 10,
                        current_value: ['10.0.8.11:5432'],
                        default_value: null,
                        description: 'The PostgreSQL node instances.',
                        keys: [
                            'postgresql_server_addresses',
                            'postgre_server_addresses',
                        ],
                        sql_keys: [],
                        value_type: 'StringList',
                    },
                    {
                        name: 'postgresql_user',
                        category: 10,
                        current_value: 'root',
                        default_value: null,
                        description: 'The PostgreSQL user name.',
                        keys: ['postgresql_user', 'postgre_user'],
                        sql_keys: ['POSTGRESQL_USER'],
                        value_type: 'String',
                    },
                    {
                        name: 'postgresql_password',
                        category: 10,
                        current_value: '<hidden>',
                        default_value: null,
                        description: 'The password used for PostgreSQL user.',
                        keys: ['postgresql_password', 'postgre_password'],
                        sql_keys: [],
                        value_type: 'String',
                    },
                    {
                        name: 'postgresql_start_by_pgctl',
                        category: 10,
                        current_value: false,
                        default_value: false,
                        description:
                            'Whether to use pg_ctl binary to start, stop or restart postgresql service instead of systemctl.',
                        keys: ['postgresql_start_by_pgctl'],
                        sql_keys: ['POSTGRESQL_START_BY_PGCTL'],
                        value_type: 'Bool',
                    },
                    {
                        name: 'pgbouncer_admin',
                        category: 10,
                        current_value: null,
                        default_value: null,
                        description: 'The PgBouncer admin user name.',
                        keys: ['pgbouncer_admin'],
                        sql_keys: [],
                        value_type: 'String',
                    },
                    {
                        name: 'pgbouncer_admin_password',
                        category: 10,
                        current_value: '<hidden>',
                        default_value: null,
                        description:
                            'The password used for PgBouncer admin user.',
                        keys: ['pgbouncer_admin_password'],
                        sql_keys: [],
                        value_type: 'String',
                    },
                    {
                        name: 'cluster_ssl_key',
                        category: 10,
                        current_value: null,
                        default_value: null,
                        description: 'SSL private key for connection to nodes.',
                        keys: ['cluster_ssl_key'],
                        sql_keys: [],
                        value_type: 'String',
                    },
                    {
                        name: 'cluster_ssl_cert',
                        category: 10,
                        current_value: null,
                        default_value: null,
                        description: 'SSL certificate for connection to nodes.',
                        keys: ['cluster_ssl_cert'],
                        sql_keys: [],
                        value_type: 'String',
                    },
                    {
                        name: 'cluster_ssl_ca',
                        category: 10,
                        current_value: null,
                        default_value: null,
                        description:
                            'SSL CA certificate for connection to nodes.',
                        keys: ['cluster_ssl_ca'],
                        sql_keys: [],
                        value_type: 'String',
                    },
                    {
                        name: 'cluster_ssl_enforce',
                        category: 10,
                        current_value: null,
                        default_value: null,
                        description:
                            'If set to true, then SSL is required. Default false.',
                        keys: ['cluster_ssl_enforce'],
                        sql_keys: [],
                        value_type: 'Bool',
                    },
                    {
                        name: 'galera_port',
                        category: 10,
                        current_value: 4567,
                        default_value: 4567,
                        description:
                            'The galera port to be used when adding nodes/garbd, and constructing wsrep_cluster_address. Do not change at runtime.',
                        keys: ['galera_port'],
                        sql_keys: ['GALERA_PORT'],
                        value_type: 'Int',
                    },
                    {
                        name: 'auto_manage_readonly',
                        category: 10,
                        current_value: true,
                        default_value: true,
                        description:
                            'Allow cmon to manage the read-only flag of the managed mysql servers.',
                        keys: ['auto_manage_readonly'],
                        sql_keys: ['AUTO_MANAGE_READONLY'],
                        value_type: 'Bool',
                    },
                    {
                        name: 'node_recovery_lock_file',
                        category: 10,
                        current_value: null,
                        default_value: null,
                        description:
                            'Specify a lock file and if present on a node,  the node will not recover. It is the responsibilty of the administrator to create/remove the file.',
                        keys: ['node_recovery_lock_file'],
                        sql_keys: ['NODE_RECOVERY_LOCK_FILE'],
                        value_type: 'String',
                    },
                    {
                        name: 'enable_readonly_on_disk_free_pct',
                        category: 10,
                        current_value: 0,
                        default_value: 0,
                        description:
                            'Set the cluster in read-only when the free disk space goes below this percentage value.',
                        keys: ['enable_readonly_on_disk_free_pct'],
                        sql_keys: ['ENABLE_READONLY_ON_DISK_FREE_PCT'],
                        value_type: 'Int',
                    },
                    {
                        name: 'backup_diskspace_min_free_pct',
                        category: 10,
                        current_value: 0,
                        default_value: 0,
                        description:
                            'Sets the lower bound of free diskspace that must be available for the backup to start. If there is not enough disk space the backup job will be failed.',
                        keys: ['backup_diskspace_min_free_pct'],
                        sql_keys: ['BACKUP_DISKSPACE_MIN_FREE_PCT'],
                        value_type: 'Int',
                    },
                    {
                        name: 'snmp_trap_target_host',
                        category: 10,
                        current_value: null,
                        default_value: null,
                        description:
                            'The host to be sent SNMP trap when an alarm occurs.',
                        keys: ['snmp_trap_target_host'],
                        sql_keys: [],
                        value_type: 'String',
                    },
                    {
                        name: 'snmp_trap_target_port',
                        category: 10,
                        current_value: null,
                        default_value: null,
                        description:
                            'The target port to be sent SNMP trap when an alarm occurs.',
                        keys: ['snmp_trap_target_port'],
                        sql_keys: [],
                        value_type: 'String',
                    },
                    {
                        name: 'snmp_protocol',
                        category: 10,
                        current_value: null,
                        default_value: null,
                        description: 'The SNMP protocol to be used.',
                        keys: ['snmp_protocol'],
                        sql_keys: [],
                        value_type: 'String',
                    },
                    {
                        name: 'snmp_community_str',
                        category: 10,
                        current_value: null,
                        default_value: null,
                        description:
                            'The community string to be used on sent SNMP traps.',
                        keys: ['snmp_community_str'],
                        sql_keys: [],
                        value_type: 'String',
                    },
                    {
                        name: 'ccx_mode',
                        category: 10,
                        current_value: null,
                        default_value: null,
                        description: 'If this cluster is configured from CCX.',
                        keys: ['ccx_mode'],
                        sql_keys: [],
                        value_type: 'Bool',
                    },
                ],
            },
            {
                cmondb: [
                    {
                        name: 'cmon_db',
                        category: 9,
                        current_value: 'cmon',
                        default_value: 'cmon',
                        description: 'The cmon database name.',
                        keys: ['cmon_db', 'cmondb_database'],
                        sql_keys: ['CMON_DB'],
                        value_type: 'String',
                    },
                    {
                        name: 'mysql_hostname',
                        category: 9,
                        current_value: '127.0.0.1',
                        default_value: '127.0.0.1',
                        description: 'The cmon database MySQL server hostname.',
                        keys: [
                            'mysql_hostname',
                            'cmon_mysql_hostname',
                            'cmondb_hostname',
                            'local_mysql_hostname',
                            'cmon_local_mysql_hostname',
                        ],
                        sql_keys: ['CMONDB_HOSTNAME'],
                        value_type: 'String',
                    },
                    {
                        name: 'mysql_port',
                        category: 9,
                        current_value: 3306,
                        default_value: 3306,
                        description: 'The cmon database MySQL server port.',
                        keys: ['mysql_port', 'cmon_mysql_port', 'cmondb_port'],
                        sql_keys: ['MYSQL_PORT'],
                        value_type: 'Int',
                    },
                    {
                        name: 'mysql_password',
                        category: 9,
                        current_value: '<hidden>',
                        default_value: null,
                        description: 'The cmon database password.',
                        keys: [
                            'mysql_password',
                            'cmon_mysql_password',
                            'cmondb_password',
                        ],
                        sql_keys: [],
                        value_type: 'String',
                    },
                    {
                        name: 'cmon_user',
                        category: 9,
                        current_value: 'cmon',
                        default_value: 'cmon',
                        description:
                            'The account name for accessing the cmon database.',
                        keys: ['cmon_user', 'cmondb_user'],
                        sql_keys: ['CMON_USER'],
                        value_type: 'String',
                    },
                    {
                        name: 'cmon_pool_size',
                        category: 9,
                        current_value: '64',
                        default_value: 64,
                        description:
                            'The connection pool size to the cmon database.',
                        keys: ['cmon_pool_size'],
                        sql_keys: [],
                        value_type: 'String',
                    },
                    {
                        name: 'cmondb_ssl_key',
                        category: 9,
                        current_value: null,
                        default_value: null,
                        description:
                            'SSL private key used for cmon database connection.',
                        keys: ['cmondb_ssl_key'],
                        sql_keys: [],
                        value_type: 'String',
                    },
                    {
                        name: 'cmondb_ssl_cert',
                        category: 9,
                        current_value: null,
                        default_value: null,
                        description:
                            'SSL certificate used for cmon database connection.',
                        keys: ['cmondb_ssl_cert'],
                        sql_keys: [],
                        value_type: 'String',
                    },
                    {
                        name: 'cmondb_ssl_ca',
                        category: 9,
                        current_value: null,
                        default_value: null,
                        description:
                            'SSL CA certificate for cmon database connection.',
                        keys: ['cmondb_ssl_ca'],
                        sql_keys: [],
                        value_type: 'String',
                    },
                ],
            },
            {
                controller: [
                    {
                        name: 'controller_id',
                        category: 11,
                        current_value: 'adc0fa9f-6dcf-473d-9652-87ac9a59e2d0',
                        default_value: null,
                        description:
                            'An arbitrary identifier string of this controller instance.',
                        keys: ['controller_id'],
                        sql_keys: ['CONTROLLER_ID'],
                        value_type: 'String',
                    },
                    {
                        name: 'hostname',
                        category: 11,
                        current_value: '178.128.85.233',
                        default_value: null,
                        description: 'The controller hostname.',
                        keys: ['hostname', 'cmon_hostname'],
                        sql_keys: ['CMON_HOSTNAME'],
                        value_type: 'String',
                    },
                    {
                        name: 'error_report_dir',
                        category: 11,
                        current_value: '/root/s9s_tmp',
                        default_value: null,
                        description: 'Storage location of error reports.',
                        keys: ['error_report_dir'],
                        sql_keys: ['ERROR_REPORT_DIR'],
                        value_type: 'String',
                    },
                    {
                        name: 'vault_addr',
                        category: 11,
                        current_value: 'https://127.0.0.1:8200',
                        default_value: 'https://127.0.0.1:8200',
                        description: 'Address of the Vault server.',
                        keys: ['vault_addr'],
                        sql_keys: [],
                        value_type: 'String',
                    },
                    {
                        name: 'vault_token',
                        category: 11,
                        current_value: null,
                        default_value: null,
                        description:
                            'The authentication token to the Vault server.',
                        keys: ['vault_token'],
                        sql_keys: [],
                        value_type: 'String',
                    },
                    {
                        name: 'vault_path',
                        category: 11,
                        current_value: 'clustercontrol',
                        default_value: 'clustercontrol',
                        description:
                            'The secrets path prefix for clustercontrol credentials.',
                        keys: ['vault_path'],
                        sql_keys: [],
                        value_type: 'String',
                    },
                    {
                        name: 'vault_auto_migrate',
                        category: 11,
                        current_value: false,
                        default_value: false,
                        description:
                            'Enable this to auto-move all the secrets to the configured Vault instance.',
                        keys: ['vault_auto_migrate'],
                        sql_keys: [],
                        value_type: 'Bool',
                    },
                    {
                        name: 'audit_logfile',
                        category: 11,
                        current_value: '/var/log/cmon_audit.log',
                        default_value: '/var/log/cmon_audit.log',
                        description: "The controller's audit log file.",
                        keys: ['audit_logfile'],
                        sql_keys: [],
                        value_type: 'String',
                    },
                    {
                        name: 'audit_ignore_users',
                        category: 11,
                        current_value: ['system', 'ccrpc'],
                        default_value: 'system,ccrpc',
                        description:
                            'Skip certain users from audit logging (comma separated list).',
                        keys: ['audit_ignore_users'],
                        sql_keys: [],
                        value_type: 'StringList',
                    },
                ],
            },
            {
                long_query: [
                    {
                        name: 'long_query_time',
                        category: 6,
                        current_value: '0.5',
                        default_value: '0.5',
                        description: 'Threshold value for slow query checking.',
                        keys: ['long_query_time'],
                        sql_keys: ['LONG_QUERY_TIME'],
                        value_type: 'String',
                    },
                ],
            },
            {
                replication: [
                    {
                        name: 'max_replication_lag',
                        category: 5,
                        current_value: 10,
                        default_value: 10,
                        description:
                            'Max allowed replication lag in seconds before sending an Alarm.',
                        keys: ['max_replication_lag'],
                        max_value: 86400,
                        min_value: 0,
                        sql_keys: ['MAX_REPLICATION_LAG'],
                        value_type: 'Int',
                    },
                    {
                        name: 'replication_stop_on_error',
                        category: 5,
                        current_value: true,
                        default_value: true,
                        description:
                            'Controls if the failover/switchover procedures should fail if errors are encountered that may cause data loss.',
                        keys: ['replication_stop_on_error'],
                        sql_keys: ['REPLICATION_STOP_ON_ERROR'],
                        value_type: 'Bool',
                    },
                    {
                        name: 'replication_auto_rebuild_slave',
                        category: 5,
                        current_value: false,
                        default_value: false,
                        description:
                            'MySQL/MariaDb: If the server is running and the SQL THREAD is stopped and error code is non-zero then the slave will be automatically rebuilt.  Thus, the slave will be rebuilt if there is a  data inconsistency error on the slave.PostgreSQL: . 1 means enable, 0 means disable (default).',
                        keys: ['replication_auto_rebuild_slave'],
                        sql_keys: ['REPLICATION_AUTO_REBUILD_SLAVE'],
                        value_type: 'Bool',
                    },
                    {
                        name: 'replication_failover_blacklist',
                        category: 5,
                        current_value: null,
                        default_value: null,
                        description:
                            'Comma separated list of hostname:port pairs. Blacklisted servers will not be considered as a candidate during failover. replication_failover_blacklist is ignored if replication_failover_whitelist is set.',
                        keys: ['replication_failover_blacklist'],
                        sql_keys: ['REPLICATION_FAILOVER_BLACKLIST'],
                        value_type: 'String',
                    },
                    {
                        name: 'replication_failover_whitelist',
                        category: 5,
                        current_value: null,
                        default_value: null,
                        description:
                            'Comma separated list of hostname:port pairs. Only whitelisted servers will be considered as a candidate during failover. If no server on the whitelist is available (up/connected) the failover will fail. replication_failover_blacklist is ignored if replication_failover_whitelist is set.',
                        keys: ['replication_failover_whitelist'],
                        sql_keys: ['REPLICATION_FAILOVER_WHITELIST'],
                        value_type: 'String',
                    },
                    {
                        name: 'replication_failover_wait_extra_sampling_rounds',
                        category: 5,
                        current_value: 0,
                        default_value: 0,
                        description:
                            'This item configures how much additional sampling rounds (db_stats_collection_interval) the controller needs to wait before actualy proceeding with the failover.',
                        keys: [
                            'replication_failover_wait_extra_sampling_rounds',
                        ],
                        sql_keys: [
                            'REPLICATION_FAILOVER_WAIT_EXTRA_SAMPLING_ROUNDS',
                        ],
                        value_type: 'Int',
                    },
                    {
                        name: 'replication_onfail_failover_script',
                        category: 5,
                        current_value: null,
                        default_value: null,
                        description:
                            "This script executed as soon as it has been discovered that a failover is needed. If the script returns non-zero it will force the failover to abort.\n If the script returns non-zero the failover will be aborted.\n If the script specified script does not exists the failover will be aborted.\n Four arguments are supplied to the script and set if they are known, else empty: arg1='all servers'  arg2='failed master' arg3='selected candidate', arg4='slaves of oldmaster (the candidates)' and passed like this: 'scripname arg1 arg2 arg3 arg4'\n The script must be accessible on the controller and executable.",
                        keys: ['replication_onfail_failover_script'],
                        sql_keys: ['REPLICATION_ONFAIL_FAILOVER_SCRIPT'],
                        value_type: 'String',
                    },
                    {
                        name: 'replication_pre_failover_script',
                        category: 5,
                        current_value: null,
                        default_value: null,
                        description:
                            "This script executed before the failover happens, but after a candidate has been elected and it is possible to continue the failover process.\n If the script returns non-zero the failover will be aborted.\n If the script specified script does not exists the failover will be aborted.\n Four arguments are supplied to the script and set if they are known, else empty: arg1='all servers'  arg2='failed master' arg3='selected candidate', arg4='slaves of oldmaster (the candidates)' and passed like this: 'scripname arg1 arg2 arg3 arg4'\n The script must be accessible on the controller and executable.",
                        keys: ['replication_pre_failover_script'],
                        sql_keys: ['REPLICATION_PRE_FAILOVER_SCRIPT'],
                        value_type: 'String',
                    },
                    {
                        name: 'replication_post_failover_script',
                        category: 5,
                        current_value: null,
                        default_value: null,
                        description:
                            "This script executed after the failover happened ( a new master is elected and up and running).\n If the script returns non-zero the failover will be aborted.\n If the script specified script does not exists the failover will be aborted.\n Four arguments are supplied to the script and set if they are known, else empty.: arg1='all servers'  arg2='failed master' arg3='selected candidate', arg4='slaves of oldmaster (the candidates)' and passed like this: 'scripname arg1 arg2 arg3 arg4'\n The script must be accessible on the controller and executable.",
                        keys: ['replication_post_failover_script'],
                        sql_keys: ['REPLICATION_POST_FAILOVER_SCRIPT'],
                        value_type: 'String',
                    },
                    {
                        name: 'replication_post_unsuccessful_failover_script',
                        category: 5,
                        current_value: null,
                        default_value: null,
                        description:
                            "This script executed if the failover attempt failed.  If the script returns non-zero the failover will be aborted.\n If the script specified script does not exists the failover will be aborted.\n Four arguments are supplied to the script and set if they are known, else empty.: arg1='all servers'  arg2='failed master' arg3='selected candidate', arg4='slaves of oldmaster (the candidates)' and passed like this: 'scripname arg1 arg2 arg3 arg4'\n The script must be accessible on the controller and executable.",
                        keys: ['replication_post_unsuccessful_failover_script'],
                        sql_keys: [
                            'REPLICATION_POST_UNSUCCESSFUL_FAILOVER_SCRIPT',
                        ],
                        value_type: 'String',
                    },
                ],
            },
            {
                retention: [
                    {
                        name: 'ops_report_retention',
                        category: 4,
                        current_value: 31,
                        default_value: 31,
                        description:
                            'Setting of how many days to keep operational reports. Operational Reports matching retention period are removed.',
                        keys: ['ops_report_retention'],
                        sql_keys: ['OPS_REPORT_RETENTION'],
                        value_type: 'Int',
                    },
                ],
            },
            {
                sampling: [
                    {
                        name: 'db_exporter_user',
                        category: 3,
                        current_value: 'cmonexporter',
                        default_value: 'cmonexporter',
                        description:
                            'The username for the database statistics collector exporter (agent).',
                        keys: ['db_exporter_user', 'db_exporter_username'],
                        sql_keys: [],
                        value_type: 'String',
                    },
                    {
                        name: 'db_exporter_password',
                        category: 3,
                        current_value: '<hidden>',
                        default_value: null,
                        description:
                            'The password for the database statistics collector exporter (agent).',
                        keys: ['db_exporter_password'],
                        sql_keys: [],
                        value_type: 'String',
                    },
                    {
                        name: 'db_exporter_use_nonlocal_address',
                        category: 3,
                        current_value: null,
                        default_value: null,
                        description:
                            'Specifies if exporter should connect to the non-local address of the DB services.',
                        keys: ['db_exporter_use_nonlocal_address'],
                        sql_keys: [],
                        value_type: 'Bool',
                    },
                    {
                        name: 'enable_icmp_ping',
                        category: 3,
                        current_value: true,
                        default_value: true,
                        description:
                            'Toggles if controller shall measure the ICMP ping times to the host.',
                        keys: ['enable_icmp_ping'],
                        sql_keys: ['ENABLE_ICMP_PING'],
                        value_type: 'Bool',
                    },
                    {
                        name: 'host_stats_collection_interval',
                        category: 3,
                        current_value: 30,
                        default_value: 30,
                        description:
                            'Setting for host (CPU, memory..) collection interval.',
                        keys: ['host_stats_collection_interval'],
                        sql_keys: ['HOST_STATS_COLLECTION_INTERVAL'],
                        value_type: 'Int',
                    },
                    {
                        name: 'host_stats_window_size',
                        category: 3,
                        current_value: 180,
                        default_value: 180,
                        description:
                            'Setting the window size (in seconds) to examine of (CPU, memory..) stats to raise/clear host stats alarms.',
                        keys: ['host_stats_window_size'],
                        sql_keys: ['HOST_STATS_WINDOW_SIZE'],
                        value_type: 'Int',
                    },
                    {
                        name: 'db_stats_collection_interval',
                        category: 3,
                        current_value: 30,
                        default_value: 30,
                        description:
                            'Setting for database stats collection interval.',
                        keys: ['db_stats_collection_interval'],
                        sql_keys: ['DB_STATS_COLLECTION_INTERVAL'],
                        value_type: 'Int',
                    },
                    {
                        name: 'db_proc_stats_collection_interval',
                        category: 3,
                        current_value: 5,
                        default_value: 5,
                        description:
                            'Setting for database process stats collection interval.Min allowed value is  1 second. Requires a cmon restart.',
                        keys: ['db_proc_stats_collection_interval'],
                        sql_keys: ['DB_PROC_STATS_COLLECTION_INTERVAL'],
                        value_type: 'Int',
                    },
                    {
                        name: 'lb_stats_collection_interval',
                        category: 3,
                        current_value: 15,
                        default_value: 15,
                        description:
                            'Setting for loadbalancer stats collection interval.',
                        keys: ['lb_stats_collection_interval'],
                        sql_keys: ['LB_STATS_COLLECTION_INTERVAL'],
                        value_type: 'Int',
                    },
                    {
                        name: 'db_schema_stats_collection_interval',
                        category: 3,
                        current_value: 108000,
                        default_value: 108000,
                        description:
                            'Setting for schema stats monitoring interval.',
                        keys: ['db_schema_stats_collection_interval'],
                        sql_keys: ['DB_SCHEMA_STATS_COLLECTION_INTERVAL'],
                        value_type: 'Int',
                    },
                    {
                        name: 'db_log_collection_interval',
                        category: 3,
                        current_value: 600,
                        default_value: 600,
                        description:
                            'Controls the interval between logfile collections.',
                        keys: [
                            'db_log_collection_interval',
                            'log_collection_interval',
                        ],
                        sql_keys: ['LOG_COLLECTION_INTERVAL'],
                        value_type: 'Int',
                    },
                    {
                        name: 'db_hourly_stats_collection_interval',
                        category: 3,
                        current_value: 5,
                        default_value: 5,
                        description:
                            'Controls how many seconds are between every individual samples in the hourly range statistics.',
                        keys: ['db_hourly_stats_collection_interval'],
                        sql_keys: ['DB_HOURLY_STATS_COLLECTION_INTERVAL'],
                        value_type: 'Int',
                    },
                    {
                        name: 'monitored_mountpoints',
                        category: 3,
                        current_value: ['/var/lib/postgresql/14/main'],
                        default_value: null,
                        description:
                            'The list of mount points to be monitored.',
                        keys: [
                            'monitored_mountpoints',
                            'monitored_mount_points',
                        ],
                        sql_keys: ['MONITORED_MOUNTPOINTS'],
                        value_type: 'StringList',
                    },
                    {
                        name: 'monitored_nics',
                        category: 3,
                        current_value: null,
                        default_value: null,
                        description:
                            'The list of network interfaces to be monitored.',
                        keys: ['monitored_nics'],
                        sql_keys: [],
                        value_type: 'StringList',
                    },
                    {
                        name: 'monitor_cpu_temperature',
                        category: 3,
                        current_value: false,
                        default_value: false,
                        description: 'Whether to monitor CPU temperature.',
                        keys: ['monitor_cpu_temperature'],
                        sql_keys: ['MONITOR_CPU_TEMPERATURE'],
                        value_type: 'Bool',
                    },
                    {
                        name: 'log_queries_not_using_indexes',
                        category: 3,
                        current_value: false,
                        default_value: false,
                        description:
                            'Set query monitor to detect queries not using indexes.',
                        keys: ['log_queries_not_using_indexes'],
                        sql_keys: ['LOG_QUERIES_NOT_USING_INDEXES'],
                        value_type: 'Bool',
                    },
                    {
                        name: 'enable_query_monitor',
                        category: 3,
                        current_value: 1,
                        default_value: 1,
                        description:
                            'Controls the query monitor interval in seconds, -1 means no query monitoring.',
                        keys: [
                            'enable_query_monitor',
                            'query_monitor_enabled',
                            'query_sample_interval',
                        ],
                        sql_keys: ['QUERY_SAMPLE_INTERVAL'],
                        value_type: 'Int',
                    },
                    {
                        name: 'enable_query_monitor_auto_purge_ps',
                        category: 3,
                        current_value: false,
                        default_value: false,
                        description:
                            "If enabled the P_S table events_statements_summary_by_digest will be auto-purged (TRUNCATE TABLE) every hour. By default this is disabled ('false'). Enable by setting it to 'true'.",
                        keys: [
                            'enable_query_monitor_auto_purge_ps',
                            'query_monitor_auto_purge_ps',
                        ],
                        sql_keys: ['QUERY_MONITOR_AUTO_PURGE_PS'],
                        value_type: 'Bool',
                    },
                    {
                        name: 'schema_change_detection_address',
                        category: 3,
                        current_value: null,
                        default_value: null,
                        description:
                            'Checks will be executed (using SHOW TABLES/SHOW CREATE TABLE) to determine if the schema has changed. The checks are executed on the address specified and is of the format HOSTNAME:PORT. The schema_change_detection_databases must also be set. A diff of a changed table is created.',
                        keys: ['schema_change_detection_address'],
                        sql_keys: ['schema_change_detection_address'],
                        value_type: 'String',
                    },
                    {
                        name: 'schema_change_detection_databases',
                        category: 3,
                        current_value: null,
                        default_value: null,
                        description:
                            'Comma separated list of databases to monitor for schema changes. If empty, no checks are made.',
                        keys: ['schema_change_detection_databases'],
                        sql_keys: ['schema_change_detection_databases'],
                        value_type: 'String',
                    },
                    {
                        name: 'schema_change_detection_pause_time_ms',
                        category: 3,
                        current_value: 0,
                        default_value: 0,
                        description:
                            'Pause time in ms between each SHOW CREATE TABLE. The pause time will affect the duration of the detection process. Default is 0, no pause.',
                        keys: ['schema_change_detection_pause_time_ms'],
                        sql_keys: ['schema_change_detection_pause_time_ms'],
                        value_type: 'Int',
                    },
                    {
                        name: 'lb_pre_install_script',
                        category: 3,
                        current_value: null,
                        default_value: null,
                        description:
                            'Setting for load balancer pre install/setup script',
                        keys: ['lb_pre_install_script'],
                        sql_keys: ['LB_PRE_INSTALL_SCRIPT'],
                        value_type: 'String',
                    },
                    {
                        name: 'lb_post_install_script',
                        category: 3,
                        current_value: null,
                        default_value: null,
                        description:
                            'Setting for load balancer post install/setup script',
                        keys: ['lb_post_install_script'],
                        sql_keys: ['LB_POST_INSTALL_SCRIPT'],
                        value_type: 'String',
                    },
                    {
                        name: 'lb_pre_start_script',
                        category: 3,
                        current_value: null,
                        default_value: null,
                        description:
                            'Setting for load balancer pre node start script',
                        keys: ['lb_pre_start_script'],
                        sql_keys: ['LB_PRE_START_SCRIPT'],
                        value_type: 'String',
                    },
                    {
                        name: 'lb_post_start_script',
                        category: 3,
                        current_value: null,
                        default_value: null,
                        description:
                            'Setting for load balancer post node start script',
                        keys: ['lb_post_start_script'],
                        sql_keys: ['LB_POST_START_SCRIPT'],
                        value_type: 'String',
                    },
                ],
            },
            {
                swapping: [
                    {
                        name: 'swap_warning',
                        category: 2,
                        current_value: 20,
                        default_value: 20,
                        description: 'Warning alarm threshold for swap usage.',
                        keys: ['swap_warning'],
                        max_value: 100,
                        min_value: 0,
                        sql_keys: ['SWAP_WARNING'],
                        value_type: 'Int',
                    },
                    {
                        name: 'swap_critical',
                        category: 2,
                        current_value: 90,
                        default_value: 90,
                        description: 'Critical alarm threshold for swap usage.',
                        keys: ['swap_critical'],
                        max_value: 100,
                        min_value: 0,
                        sql_keys: ['SWAP_CRITICAL'],
                        value_type: 'Int',
                    },
                    {
                        name: 'swap_inout_period',
                        category: 2,
                        current_value: 0,
                        default_value: 0,
                        description:
                            'The interval for swap I/O alarms (<= 0 disables).',
                        keys: ['swap_inout_period'],
                        max_value: 28800,
                        min_value: 0,
                        sql_keys: ['SWAP_INOUT_PERIOD'],
                        value_type: 'Int',
                    },
                    {
                        name: 'swap_inout_warning',
                        category: 2,
                        current_value: 10240,
                        default_value: 10240,
                        description:
                            'The number of pages swapped I/O in the specified interval (swap_inout_period, by default 10 minutes) for warning.',
                        keys: ['swap_inout_warning'],
                        max_value: 2147483647,
                        min_value: 0,
                        sql_keys: ['SWAP_INOUT_WARNING'],
                        value_type: 'Int',
                    },
                    {
                        name: 'swap_inout_critical',
                        category: 2,
                        current_value: 102400,
                        default_value: 102400,
                        description:
                            'The number of pages swapped I/O in the specified interval (swap_inout_period, by default 10 minutes) for critical.',
                        keys: ['swap_inout_critical'],
                        max_value: 2147483647,
                        min_value: 0,
                        sql_keys: ['SWAP_INOUT_CRITICAL'],
                        value_type: 'Int',
                    },
                ],
            },
            {
                system: [
                    {
                        name: 'config_file_path',
                        category: 7,
                        current_value: '/etc/cmon.d/cmon_10.cnf',
                        default_value: null,
                        description:
                            'The config file path. This configuration value is read-only.',
                        keys: ['config_file_path', 'cmon_config_path'],
                        sql_keys: ['CMON_CONFIG_PATH'],
                        value_type: 'String',
                    },
                    {
                        name: 'logfile',
                        category: 7,
                        current_value: '/var/log/cmon_10.log',
                        default_value: null,
                        description: 'The log file absolute path.',
                        keys: ['logfile'],
                        sql_keys: [],
                        value_type: 'String',
                    },
                    {
                        name: 'os',
                        category: 7,
                        current_value: 'debian',
                        default_value: null,
                        description:
                            "The OS type. Possible values are 'debian' or 'redhat'.",
                        keys: ['os'],
                        sql_keys: ['OS'],
                        value_type: 'String',
                    },
                    {
                        name: 'libssh_timeout',
                        category: 7,
                        current_value: 30,
                        default_value: 30,
                        description:
                            'Network timeout value for SSH connections.',
                        keys: ['libssh_timeout', 'ssh_timeout'],
                        sql_keys: ['LIBSSH_TIMEOUT'],
                        value_type: 'Int',
                    },
                    {
                        name: 'libssh_loglevel',
                        category: 7,
                        current_value: null,
                        default_value: null,
                        description:
                            'Setting for libssh logging verbosity to stdout.',
                        keys: ['libssh_loglevel'],
                        sql_keys: [],
                        value_type: 'Int',
                    },
                    {
                        name: 'ssh_acquire_tty',
                        category: 7,
                        current_value: true,
                        default_value: true,
                        description:
                            'Setting for libssh: should it acquire a remote tty.',
                        keys: ['ssh_acquire_tty'],
                        sql_keys: [],
                        value_type: 'Bool',
                    },
                    {
                        name: 'ssh_password',
                        category: 7,
                        current_value: '<hidden>',
                        default_value: null,
                        description:
                            'The SSH password used for connection to nodes.',
                        keys: ['ssh_password'],
                        sql_keys: [],
                        value_type: 'String',
                    },
                    {
                        name: 'net_read_timeout',
                        category: 7,
                        current_value: 10,
                        default_value: 10,
                        description:
                            'Network read timeout value in seconds (DB connections).',
                        keys: ['net_read_timeout'],
                        sql_keys: [],
                        value_type: 'Int',
                    },
                    {
                        name: 'net_write_timeout',
                        category: 7,
                        current_value: 10,
                        default_value: 10,
                        description:
                            'Network write timeout value in seconds (DB connections).',
                        keys: ['net_write_timeout'],
                        sql_keys: [],
                        value_type: 'Int',
                    },
                    {
                        name: 'connect_timeout',
                        category: 7,
                        current_value: 10,
                        default_value: 10,
                        description:
                            'Network connect timeout value in seconds (DB connections).',
                        keys: ['connect_timeout'],
                        sql_keys: [],
                        value_type: 'Int',
                    },
                    {
                        name: 'connect_heartbeat_when_faulty',
                        category: 7,
                        current_value: 30,
                        default_value: 30,
                        description:
                            'Connections blocking period when faulty and resource consuming situation is detected.',
                        keys: ['connect_heartbeat_when_faulty'],
                        sql_keys: [],
                        value_type: 'Int',
                    },
                    {
                        name: 'disable_numa',
                        category: 7,
                        current_value: null,
                        default_value: null,
                        description:
                            'Not to use NUMA support dependent features.',
                        keys: ['disable_numa'],
                        sql_keys: [],
                        value_type: 'Bool',
                    },
                    {
                        name: 'skip_name_resolve',
                        category: 7,
                        current_value: null,
                        default_value: null,
                        description: 'Setting to disable name resolution.',
                        keys: ['skip_name_resolve'],
                        sql_keys: [],
                        value_type: 'Bool',
                    },
                    {
                        name: 'sudo_opts',
                        category: 7,
                        current_value: 'sudo -n 2>/dev/null',
                        default_value: null,
                        description:
                            'The command used to obtain superuser privileges.',
                        keys: ['sudo_opts', 'sudo'],
                        sql_keys: ['SUDO'],
                        value_type: 'String',
                    },
                    {
                        name: 'ssh_port',
                        category: 7,
                        current_value: 22,
                        default_value: 22,
                        description:
                            'The port for SSH connections to the nodes.',
                        keys: ['ssh_port'],
                        sql_keys: ['SSH_PORT'],
                        value_type: 'Int',
                    },
                    {
                        name: 'prometheus_ssh_port',
                        category: 7,
                        current_value: 22,
                        default_value: 22,
                        description:
                            'Custom SSH port for SSH connection to the Prometheus node.',
                        keys: ['prometheus_ssh_port'],
                        sql_keys: [],
                        value_type: 'Int',
                    },
                    {
                        name: 'prometheus_ssh_user',
                        category: 7,
                        current_value: null,
                        default_value: null,
                        description:
                            'The SSH username used for accessing the Prometheus node.',
                        keys: ['prometheus_ssh_user'],
                        sql_keys: [],
                        value_type: 'String',
                    },
                    {
                        name: 'prometheus_ssh_keypath',
                        category: 7,
                        current_value: null,
                        default_value: null,
                        description:
                            'The SSH key file used for connection to the Prometheus node.',
                        keys: ['prometheus_ssh_keypath'],
                        sql_keys: [],
                        value_type: 'String',
                    },
                    {
                        name: 'prometheus_ssh_keydata',
                        category: 7,
                        current_value: '<hidden>',
                        default_value: null,
                        description:
                            'A base64 encoded SSH key for connecting to Prometheus node.',
                        keys: ['prometheus_ssh_keydata'],
                        sql_keys: [],
                        value_type: 'String',
                    },
                    {
                        name: 'prometheus_ssh_password',
                        category: 7,
                        current_value: '<hidden>',
                        default_value: null,
                        description:
                            'The SSH password used for connection to the Prometheus node.',
                        keys: ['prometheus_ssh_password'],
                        sql_keys: [],
                        value_type: 'String',
                    },
                    {
                        name: 'prometheus_hostname',
                        category: 7,
                        current_value: '<hidden>',
                        default_value: null,
                        description:
                            'A Prometheus node hostname for which cmon shall use custom SSH credentials.',
                        keys: ['prometheus_hostname'],
                        sql_keys: [],
                        value_type: 'String',
                    },
                    {
                        name: 'local_repo_name',
                        category: 7,
                        current_value: null,
                        default_value: null,
                        description:
                            'The used local repository names for cluster deployment.',
                        keys: ['local_repo_name'],
                        sql_keys: ['LOCAL_REPO_NAME'],
                        value_type: 'String',
                    },
                    {
                        name: 'frontend_url',
                        category: 7,
                        current_value: null,
                        default_value: null,
                        description:
                            'The URL sent in the emails to direct the recipient to the Cmon Contoller web interface.',
                        keys: ['frontend_url'],
                        sql_keys: ['FRONTEND_URL'],
                        value_type: 'String',
                    },
                    {
                        name: 'save_history_days',
                        category: 7,
                        current_value: 7,
                        default_value: 7,
                        description:
                            'A setting how long controller shall keep data. Measured in days, jobs, job messages, alarms, collected logs, operational reports, database growth information older than this will be deleted.',
                        keys: ['save_history_days', 'purge'],
                        sql_keys: ['PURGE'],
                        value_type: 'Int',
                    },
                    {
                        name: 'osuser_home',
                        category: 7,
                        current_value: '/root',
                        default_value: null,
                        description:
                            'The HOME directory of the user used on nodes.',
                        keys: ['osuser_home', 'os_user_home', 'ssh_user_home'],
                        sql_keys: ['OS_USER_HOME'],
                        value_type: 'String',
                    },
                    {
                        name: 'cmon_mail_sender',
                        category: 7,
                        current_value: null,
                        default_value: null,
                        description: 'The used e-mail sender for sent mails.',
                        keys: ['cmon_mail_sender'],
                        sql_keys: ['CMON_MAIL_SENDER'],
                        value_type: 'String',
                    },
                    {
                        name: 'rpc_key',
                        category: 7,
                        current_value: '<hidden>',
                        default_value: null,
                        description: 'The authentication token/key.',
                        keys: ['rpc_key', 'rpc_api_key'],
                        sql_keys: [],
                        value_type: 'String',
                    },
                    {
                        name: 'rpc_version',
                        category: 7,
                        current_value: '2.0',
                        default_value: null,
                        description: 'The RPC version created the cluster.',
                        keys: ['rpc_version'],
                        sql_keys: [],
                        value_type: 'String',
                    },
                    {
                        name: 'plugin_dir',
                        category: 7,
                        current_value: null,
                        default_value: null,
                        description: 'The path of plugins directory.',
                        keys: ['plugin_dir', 'plugin_path'],
                        sql_keys: ['PLUGIN_DIR'],
                        value_type: 'String',
                    },
                    {
                        name: 'use_internal_repos',
                        category: 7,
                        current_value: false,
                        default_value: false,
                        description:
                            'Setting which disabled the 3rd party repository to be set up.',
                        keys: ['use_internal_repos'],
                        sql_keys: ['USE_INTERNAL_REPOS'],
                        value_type: 'Bool',
                    },
                    {
                        name: 'cmon_use_mail',
                        category: 7,
                        current_value: false,
                        default_value: false,
                        description:
                            "Setting to use the 'mail' command for e-mailing.",
                        keys: ['cmon_use_mail'],
                        sql_keys: ['CMON_USE_MAIL'],
                        value_type: 'Bool',
                    },
                    {
                        name: 'email_subject_prefix',
                        category: 7,
                        current_value: null,
                        default_value: null,
                        description: 'Custom e-mail subject prefix.',
                        keys: ['email_subject_prefix'],
                        sql_keys: ['email_subject_prefix'],
                        value_type: 'Bool',
                    },
                    {
                        name: 'enable_html_emails',
                        category: 7,
                        current_value: true,
                        default_value: true,
                        description: 'Enables sending of HTML e-mails.',
                        keys: ['enable_html_emails'],
                        sql_keys: ['ENABLE_HTML_EMAILS'],
                        value_type: 'Bool',
                    },
                    {
                        name: 'send_clear_cluster_failure',
                        category: 7,
                        current_value: true,
                        default_value: true,
                        description:
                            'Toggles the email sending in case of cluster alarms being cleared.',
                        keys: [
                            'send_clear_cluster_failure',
                            'send_clear_alarm',
                        ],
                        sql_keys: ['SEND_CLEAR_ALARM'],
                        value_type: 'Bool',
                    },
                ],
            },
            {
                threshold: [
                    {
                        name: 'ram_warning',
                        category: 1,
                        current_value: 80,
                        default_value: 80,
                        description: 'Warning alarm threshold for RAM usage.',
                        keys: ['ram_warning'],
                        max_value: 100,
                        min_value: 0,
                        sql_keys: ['RAM_WARNING'],
                        value_type: 'Int',
                    },
                    {
                        name: 'ram_critical',
                        category: 1,
                        current_value: 90,
                        default_value: 90,
                        description: 'Critical alarm threshold for RAM usage.',
                        keys: ['ram_critical'],
                        max_value: 100,
                        min_value: 0,
                        sql_keys: ['RAM_CRITICAL'],
                        value_type: 'Int',
                    },
                    {
                        name: 'diskspace_warning',
                        category: 1,
                        current_value: 80,
                        default_value: 80,
                        description: 'Warning alarm treshold for disk usage.',
                        keys: ['diskspace_warning'],
                        max_value: 100,
                        min_value: 0,
                        sql_keys: ['DISKSPACE_WARNING'],
                        value_type: 'Int',
                    },
                    {
                        name: 'diskspace_critical',
                        category: 1,
                        current_value: 90,
                        default_value: 90,
                        description: 'Critical alarm threshold for disk usage.',
                        keys: ['diskspace_critical'],
                        max_value: 100,
                        min_value: 0,
                        sql_keys: ['DISKSPACE_CRITICAL'],
                        value_type: 'Int',
                    },
                    {
                        name: 'cpu_warning',
                        category: 1,
                        current_value: 80,
                        default_value: 80,
                        description: 'Warning alarm threshold for CPU usage.',
                        keys: ['cpu_warning'],
                        max_value: 100,
                        min_value: 0,
                        sql_keys: ['CPU_WARNING'],
                        value_type: 'Int',
                    },
                    {
                        name: 'cpu_critical',
                        category: 1,
                        current_value: 90,
                        default_value: 90,
                        description: 'Critical alarm threshold for CPU usage.',
                        keys: ['cpu_critical'],
                        max_value: 100,
                        min_value: 0,
                        sql_keys: ['CPU_CRITICAL'],
                        value_type: 'Int',
                    },
                    {
                        name: 'cpu_steal_warning',
                        category: 1,
                        current_value: 10,
                        default_value: 10,
                        description: 'Warning alarm threshold for CPU steal.',
                        keys: ['cpu_steal_warning'],
                        max_value: 100,
                        min_value: 0,
                        sql_keys: ['CPU_STEAL_WARNING'],
                        value_type: 'Int',
                    },
                    {
                        name: 'cpu_steal_critical',
                        category: 1,
                        current_value: 20,
                        default_value: 20,
                        description: 'Critical alarm threshold for CPU steal.',
                        keys: ['cpu_steal_critical'],
                        max_value: 100,
                        min_value: 0,
                        sql_keys: ['CPU_STEAL_CRITICAL'],
                        value_type: 'Int',
                    },
                    {
                        name: 'cpu_iowait_warning',
                        category: 1,
                        current_value: 50,
                        default_value: 50,
                        description: 'Warning alarm threshold for CPU IO Wait.',
                        keys: ['cpu_iowait_warning'],
                        max_value: 100,
                        min_value: 0,
                        sql_keys: ['CPU_IOWAIT_WARNING'],
                        value_type: 'Int',
                    },
                    {
                        name: 'cpu_iowait_critical',
                        category: 1,
                        current_value: 60,
                        default_value: 60,
                        description:
                            'Critical alarm threshold for CPU IO Wait.',
                        keys: ['cpu_iowait_critical'],
                        max_value: 100,
                        min_value: 0,
                        sql_keys: ['CPU_IOWAIT_CRITICAL'],
                        value_type: 'Int',
                    },
                    {
                        name: 'slow_ssh_warning',
                        category: 1,
                        current_value: 6,
                        default_value: 6,
                        description:
                            'An Warning alarm will be raised if it takes longer than the specified time to setup an SSH connection (secs).',
                        keys: ['slow_ssh_warning'],
                        sql_keys: ['SLOW_SSH_WARNING'],
                        value_type: 'Int',
                    },
                    {
                        name: 'slow_ssh_critical',
                        category: 1,
                        current_value: 12,
                        default_value: 12,
                        description:
                            'An Critical alarm will be raised if it takes longer than the specified time to setup an SSH connection (secs).',
                        keys: ['slow_ssh_critical'],
                        sql_keys: ['SLOW_SSH_CRITICAL'],
                        value_type: 'Int',
                    },
                ],
            },
        ],
    },
};

export default result;
