import React, { useEffect, useMemo, useState } from 'react';
import CmonClustersService from '../../../services/cmon/CmonClustersService';
import useClusterConfigGrouped, {
    ClusterConfigCategory,
    ClusterConfigGrouped,
} from '../useClusterConfigGrouped';
import { notifyError, NotifyType } from '../../Notifications/uiNotification';
import useClusterConfig, {
    ClusterConfig,
    ClusterConfigValue,
} from '../useClusterConfig';
import { debounce } from '../../../common/helpers';

export type ThresholdItem = {
    warning?: ClusterConfigValue;
    critical?: ClusterConfigValue;
    name: string;
    warningValue?: ClusterConfigValue;
    criticalValue?: ClusterConfigValue;
};
interface ClusterConfigContextInterface {
    search: (searchString: string) => void;
    searchString?: string;
    changeValue: (name: string, value: any) => void;
    changeLoading: boolean;
    availableCategories: ClusterConfigCategory[];
    configGroupedLoading: boolean;
    configGrouped?: ClusterConfigGrouped;
    configLoading: boolean;
    config?: ClusterConfig;
    thresholds: ThresholdItem[];
    setClusterId: (clusterId?: number) => void;
    refresh: (params: any) => void;
}
export const ClusterConfigContext = React.createContext<
    ClusterConfigContextInterface
>({
    search: () => {},
    changeValue: () => {},
    changeLoading: false,
    availableCategories: [],
    configGroupedLoading: false,
    configGrouped: {},
    configLoading: false,
    config: {},
    thresholds: [],
    setClusterId: () => {},
    refresh: () => {},
});

export type ClusterConfigProviderProps = {
    children: React.ReactNode;
    clusterId?: number;
    loadConfigGrouped?: boolean;
    loadConfig?: boolean;
};
export const ClusterConfigProvider = ({
    clusterId: defaultClusterId,
    children,
    loadConfigGrouped = true,
    loadConfig = false,
}: ClusterConfigProviderProps) => {
    const [clusterId, setClusterId] = useState<number | undefined>(
        defaultClusterId
    );
    const [changeLoading, setChangeLoading] = useState(false);
    const [searchString, setSearchString] = useState<string>();

    const {
        refresh: refreshConfigGrouped,
        config: configGrouped,
        loading: configGroupedLoading,
    } = useClusterConfigGrouped({
        clusterId,
    });
    const {
        refresh: refreshConfig,
        config,
        loading: configLoading,
    } = useClusterConfig({
        clusterId,
    });

    const changeValue = async (name: string, value: any) => {
        try {
            setChangeLoading(true);
            await CmonClustersService.setConfig({
                cluster_id: clusterId,
                configuration: [
                    {
                        name: name,
                        value: value,
                    },
                ],
            });
            // @todo for performance optimization change value in configGrouped directly
            await refreshConfigGrouped({});
        } catch (err: any) {
            notifyError({ type: NotifyType.TOAST, content: err.message });
            throw err; // rethrow error to restore previous value in input
        } finally {
            setChangeLoading(false);
        }
    };

    const search = debounce((searchString: string) => {
        setSearchString(searchString || undefined);
    }, 300);

    /**
     * Match items with searchString
     * @param current_value
     * @param name
     * @param description
     */
    const filterItem = ({
        current_value,
        name,
        description,
    }: ClusterConfigValue) => {
        const searchStringLower = searchString?.toLowerCase() || '';
        return (
            (name && name.toLowerCase().indexOf(searchStringLower) !== -1) ||
            (current_value &&
                `${current_value}`.toLowerCase().indexOf(searchStringLower) !==
                    -1) ||
            (description &&
                description.toLowerCase().indexOf(searchStringLower) !== -1)
        );
    };

    // search result based on searchString
    const searchResultGrouped = useMemo(() => {
        if (searchString && configGrouped) {
            return Object.entries(configGrouped).reduce(
                (acc, [key, values]) => {
                    const filteredValues = Object.values(values).filter(
                        filterItem
                    );

                    if (filteredValues.length > 0) {
                        acc[
                            key as ClusterConfigCategory
                        ] = filteredValues.reduce((a, item) => {
                            a[item.name] = item;
                            return a;
                        }, {} as ClusterConfig);
                    }
                    return acc;
                },
                {} as ClusterConfigGrouped
            );
        }
        return configGrouped;
    }, [searchString, configGrouped]);

    // all threshold items
    const thresholdItems = useMemo(() => {
        const listItems =
            [ClusterConfigCategory.THRESHOLD, ClusterConfigCategory.SWAPPING]
                ?.map((category) =>
                    Object.values(configGrouped?.[category] || {})
                )
                .flat() || [];
        const items: ThresholdItem[] = Object.values(
            listItems.reduce((a, c) => {
                let name: string[] | string = c.name?.split('_') || [];
                const type = name.pop();
                if (!type || !['warning', 'critical'].includes(type)) {
                    return a;
                }
                name = name.join('_');
                if (!a[name]) {
                    a[name] = {
                        name: name,
                        waning: 0,
                        critical: 0,
                    };
                }
                a[name][type] = c;
                a[name][`${type}Value`] = c;
                return a;
            }, {} as any)
        );

        return items;
    }, [configGrouped]);

    // filtered by searchString thresholds
    const thresholds: ThresholdItem[] = useMemo(() => {
        return thresholdItems.filter(
            ({ warning, critical }) =>
                (warning && filterItem(warning)) ||
                (critical && filterItem(critical))
        );
    }, [searchString, thresholdItems]);

    const availableCategories = useMemo(() => {
        const categories =
            (searchResultGrouped &&
                (Object.keys(
                    searchResultGrouped
                ) as ClusterConfigCategory[])) ||
            [];
        // thresholds must be handled separately
        if (
            thresholds.length > 0 &&
            !categories.includes(ClusterConfigCategory.THRESHOLD)
        ) {
            categories.push(ClusterConfigCategory.THRESHOLD);
        }
        return categories;
    }, [searchResultGrouped, thresholds]);

    useEffect(() => {
        (async () => {
            if (clusterId) {
                if (loadConfigGrouped) {
                    await refreshConfigGrouped({});
                }
                if (loadConfig) {
                    await refreshConfig({});
                }
            }
        })();
    }, [clusterId]);

    const clusterConfigContext = {
        search,
        searchString,
        changeValue,
        changeLoading,
        availableCategories,
        configGroupedLoading,
        configGrouped: searchResultGrouped,
        configLoading,
        config,
        thresholds,
        setClusterId,
        refresh: refreshConfigGrouped,
    };
    return (
        <ClusterConfigContext.Provider value={clusterConfigContext}>
            {children}
        </ClusterConfigContext.Provider>
    );
};
