import React from 'react';
import { StatisticBadgeProps } from '../../common/StatisticBadge';
import AlarmsBadge from '../Alarms/AlarmsBadge';

export default ActivityBadge;

export type ActivityBadgeProps = StatisticBadgeProps & {
    children?: React.ReactNode;
};

function ActivityBadge({ ...rest }: ActivityBadgeProps) {
    return <AlarmsBadge {...rest} />;
}
