
import CcNode, { CcNodeProps } from '../../models/CcNode';

export interface CmonNdbHostNdbProps {
    backupdir?: string;
    datamemory_total_bytes?: number;
    datamemory_used_bytes?: number;
    datamemory_used_pct?: number;
    diskpagebuffer_hitratio?: number;
    indexmemory_total_bytes?: number;
    indexmemory_used_bytes?: number;
    indexmemory_used_pct?: number;
    longmemory_total_bytes?: number;
    longmemory_used_bytes?: number;
    longmemory_used_pct?: number;
    redobuffer_used_pct?: number;
    redolog_used_pct?: number;
}


export interface CmonNdbHostProps  extends CcNodeProps {
    disconnects?: number;
    executable_name?: string;
    failedrestarts?: number;
    lastdisconnect?: number;
    mgm_port?: number;
    ndb?: CmonNdbHostNdbProps;
    ndb_port?: number;
    ndbstatus?: string;
    nodegroup?: number;
    nodeid?: number;
    startmode?: string;
    startok?: number;
    startphase?: number;

}

export class CmonNdbHostNdb {
    public backupdir?: string;
    public datamemoryTotalBytes?: number;
    public datamemoryUsedBytes?: number;
    public datamemoryUsedPct?: number;
    public diskpagebufferHitratio?: number;
    public indexmemoryTotalBytes?: number;
    public indexmemoryUsedBytes?: number;
    public indexmemoryUsedPct?: number;
    public longmemoryTotalBytes?: number;
    public longmemoryUsedBytes?: number;
    public longmemoryUsedPct?: number;
    public redobufferUsedPct?: number;
    public redologUsedPct?: number;

    constructor(props: CmonNdbHostNdbProps) {
        this.backupdir = props.backupdir;
        this.datamemoryTotalBytes = props.datamemory_total_bytes;
        this.datamemoryUsedBytes = props.datamemory_used_bytes;
        this.datamemoryUsedPct = props.datamemory_used_pct;
        this.diskpagebufferHitratio = props.diskpagebuffer_hitratio;
        this.indexmemoryTotalBytes = props.indexmemory_total_bytes;
        this.indexmemoryUsedBytes = props.indexmemory_used_bytes;
        this.indexmemoryUsedPct = props.indexmemory_used_pct;
        this.longmemoryTotalBytes = props.longmemory_total_bytes;
        this.longmemoryUsedBytes = props.longmemory_used_bytes;
        this.longmemoryUsedPct = props.longmemory_used_pct;
        this.redobufferUsedPct = props.redobuffer_used_pct;
        this.redologUsedPct = props.redolog_used_pct;

    }}



export default class CmonNdbHost extends CcNode {
    public disconnects?: number;
    public executableName?: string;
    public failedrestarts?: number;
    public lastdisconnect?: number;
    public mgmPort?: number;
    public ndb?: CmonNdbHostNdb;
    public ndbPort?: number;
    public ndbstatus?: string;
    public nodegroup?: number;
    public nodeid?: number;
    public startmode?: string;
    public startok?: number;
    public startphase?: number;


    constructor(props: CmonNdbHostProps) {
        super(props);
        this.disconnects = props.disconnects;
        this.executableName = props.executable_name;
        this.failedrestarts = props.failedrestarts;
        this.lastdisconnect = props.lastdisconnect;
        this.mgmPort = props.mgm_port;
        this.ndb = props.ndb && new CmonNdbHostNdb(props.ndb);
        this.ndbPort = props.ndb_port;
        this.ndbstatus = props.ndbstatus;
        this.nodegroup = props.nodegroup;
        this.nodeid = props.nodeid;
        this.startmode = props.startmode;
        this.startok = props.startok;
        this.startphase = props.startphase;

    }

}
