import React from 'react';
import { Col, Divider, Row, Space } from 'antd';
import NodeTree, { NodeTreeProps } from '../common/NodeTree';
import { StatusFormatStatus } from '@severalnines/bar-frontend-components/build/lib/Format/StatusFormat';
import CmonHost from '../services/cmon/models/CmonHost';
import TypographyText from '../common/TypographyText';

export default DebugNodeTree;

export type DebugNodeTreeProps = {};

function DebugNodeTree() {
    const samples: { message: string; props: NodeTreeProps }[] = [
        {
            message: 'placeholder',
            props: {
                treeData: [],
                placeholderTree: [
                    {
                        key: 'primary0',
                        title: (
                            <span>
                                ---.---.---.---
                                <br />
                                <small>Primary</small>
                            </span>
                        ),
                        children: [
                            {
                                key: 'secondary0',
                                title: (
                                    <span>
                                        ---.---.---.---
                                        <br />
                                        <small>Replica</small>
                                    </span>
                                ),
                            },
                            {
                                key: 'secondary1',
                                title: (
                                    <span>
                                        ---.---.---.---
                                        <br />
                                        <small>Replica</small>
                                    </span>
                                ),
                            },
                        ],
                    },
                ],
            },
        },
        {
            message: 'placeholder overrided with partial tree data',
            props: {
                treeData: [
                    {
                        key: 'primary0',
                        status: StatusFormatStatus.error,
                        title: (
                            <span>
                                master
                                <br />
                                <small>Primary</small>
                            </span>
                        ),
                        extraData: {
                            node: new CmonHost({
                                hostname: '10.10.10.12',
                            }),
                        },
                        children: [
                            {
                                key: 'secondary0',
                                status: StatusFormatStatus.success,
                                title: (
                                    <span>
                                        secondary0
                                        <br />
                                        <small>Replica</small>
                                    </span>
                                ),
                                extraData: {
                                    node: new CmonHost({
                                        hostname: '10.10.10.12',
                                    }),
                                },
                            },
                        ],
                    },
                ],
                placeholderTree: [
                    {
                        key: 'primary0',
                        title: (
                            <span>
                                ---.---.---.---
                                <br />
                                <small>Primary</small>
                            </span>
                        ),
                        children: [
                            {
                                key: 'secondary0',
                                title: (
                                    <span>
                                        ---.---.---.---
                                        <br />
                                        <small>Replica</small>
                                    </span>
                                ),
                            },
                            {
                                key: 'secondary1',
                                title: (
                                    <span>
                                        ---.---.---.---
                                        <br />
                                        <small>Replica</small>
                                    </span>
                                ),
                            },
                        ],
                    },
                ],
            },
        },
        {
            message: 'placeholder overrided with tree data',
            props: {
                treeData: [
                    {
                        key: 'primary0',
                        status: StatusFormatStatus.error,
                        title: (
                            <span>
                                master
                                <br />
                                <small>Primary</small>
                            </span>
                        ),
                        extraData: {
                            node: new CmonHost({
                                hostname: '10.10.10.12',
                            }),
                        },
                        children: [
                            {
                                key: 'secondary0',
                                status: StatusFormatStatus.success,
                                title: (
                                    <span>
                                        secondary0
                                        <br />
                                        <small>Replica</small>
                                    </span>
                                ),
                                extraData: {
                                    node: new CmonHost({
                                        hostname: '10.10.10.12',
                                    }),
                                },
                            },
                            {
                                key: 'secondary1',
                                loading: true,
                                title: (
                                    <span>
                                        secondary1
                                        <br />
                                        <small>Replica</small>
                                    </span>
                                ),
                                extraData: {
                                    node: new CmonHost({
                                        hostname: '10.10.10.13',
                                    }),
                                },
                            },
                        ],
                    },
                ],
                placeholderTree: [
                    {
                        key: 'primary0',
                        title: (
                            <span>
                                ---.---.---.---
                                <br />
                                <small>Primary</small>
                            </span>
                        ),
                        children: [
                            {
                                key: 'secondary0',
                                title: (
                                    <span>
                                        ---.---.---.---
                                        <br />
                                        <small>Replica</small>
                                    </span>
                                ),
                            },
                            {
                                key: 'secondary1',
                                title: (
                                    <span>
                                        ---.---.---.---
                                        <br />
                                        <small>Replica</small>
                                    </span>
                                ),
                            },
                        ],
                    },
                ],
            },
        },
        {
            message: 'only primaries, placeholder overrided with tree data',
            props: {
                treeData: [
                    {
                        key: 'primary0',
                        status: StatusFormatStatus.error,
                        title: (
                            <span>
                                primary0
                                <br />
                                <small>Primary</small>
                            </span>
                        ),
                        extraData: {
                            node: new CmonHost({
                                hostname: '10.10.10.12',
                            }),
                        },
                    },
                    {
                        key: 'primary1',
                        status: StatusFormatStatus.success,
                        title: (
                            <span>
                                primary1
                                <br />
                                <small>Primary 2</small>
                            </span>
                        ),
                        extraData: {
                            node: new CmonHost({
                                hostname: '10.10.10.12',
                            }),
                        },
                    },
                    {
                        key: 'primary2',
                        loading: true,
                        title: (
                            <span>
                                primary2
                                <br />
                                <small>Primary 2</small>
                            </span>
                        ),
                        extraData: {
                            node: new CmonHost({
                                hostname: '10.10.10.13',
                            }),
                        },
                    },
                ],
                placeholderTree: [
                    {
                        key: 'primary0',
                        title: (
                            <span>
                                ---.---.---.---
                                <br />
                                <small>Primary</small>
                            </span>
                        ),
                    },
                    {
                        key: 'primary1',
                        title: (
                            <span>
                                ---.---.---.---
                                <br />
                                <small>Primary</small>
                            </span>
                        ),
                    },
                    {
                        key: 'primary2',
                        title: (
                            <span>
                                ---.---.---.---
                                <br />
                                <small>Primary</small>
                            </span>
                        ),
                    },
                ],
            },
        },
    ];

    const longText = 'withlongtexttotestoverlowingofthisfieldsinthebox';
    const ipv6 = '1050:0000:0000:0000:0005:0600:300c:326';
    const wideProps = {
        treeData: [
            {
                key: 'primary0',
                status: StatusFormatStatus.error,
                title: (
                    <span>
                        <Space>
                            <TypographyText
                                ellipsis={{
                                    tooltip: `${ipv6}a`,
                                }}
                            >
                                {`${ipv6}a`}
                            </TypographyText>
                        </Space>
                        <br />
                        <small>
                            <TypographyText
                                ellipsis={{
                                    tooltip: longText,
                                }}
                            >
                                Primary {longText}
                            </TypographyText>
                        </small>
                    </span>
                ),
                extraData: {
                    node: new CmonHost({
                        hostname: '1050:0000:0000:0000:0005:0600:300c:326a',
                    }),
                },
                children: [
                    {
                        key: 'secondary0',
                        status: StatusFormatStatus.success,
                        title: (
                            <span>
                                <TypographyText
                                    ellipsis={{
                                        tooltip: `${ipv6}b`,
                                    }}
                                >
                                    {`${ipv6}b`}
                                </TypographyText>
                                <br />
                                <small>
                                    <TypographyText
                                        ellipsis={{
                                            tooltip: longText,
                                        }}
                                    >
                                        Replica {longText}
                                    </TypographyText>
                                </small>
                            </span>
                        ),
                        extraData: {
                            node: new CmonHost({
                                hostname:
                                    '1050:0000:0000:0000:0005:0600:300c:326b',
                            }),
                        },
                    },
                ],
            },
        ],
        placeholderTree: [
            {
                key: 'primary0',
                title: (
                    <span>
                        ---.---.---.---
                        <br />
                        <small>Primary</small>
                    </span>
                ),
                children: [
                    {
                        key: 'secondary0',
                        title: (
                            <span>
                                ---.---.---.---
                                <br />
                                <small>Replica</small>
                            </span>
                        ),
                    },
                    {
                        key: 'secondary1',
                        title: (
                            <span>
                                ---.---.---.---
                                <br />
                                <small>Replica 1 {longText} no ellipsis</small>
                            </span>
                        ),
                    },
                    {
                        key: 'secondary2',
                        title: (
                            <span>
                                ---.---.---.---
                                <br />
                                <small>
                                    <TypographyText
                                        ellipsis={{
                                            tooltip: `Replica 2 ${longText} with ellipsis`,
                                        }}
                                    >
                                        Replica 2 {longText} with ellipsis
                                    </TypographyText>
                                </small>
                            </span>
                        ),
                    },
                ],
            },
        ],
    };
    return (
        <div className="DebugNodeTree">
            <h1>NodeTree</h1>
            <Row gutter={16}>
                {samples.map((sample, index) => (
                    <Col span={12} key={index}>
                        <Divider
                            plain={true}
                            orientation="left"
                            style={{ marginBottom: '0px', marginTop: '15px' }}
                        >
                            {sample.message}
                        </Divider>
                        <NodeTree {...sample.props}></NodeTree>
                    </Col>
                ))}
            </Row>
            <h2>fit content in limited width</h2>
            <div
                style={{
                    width: '250px',
                    border: '1px solid lightgrey',
                    padding: 20,
                }}
            >
                <NodeTree {...wideProps} fitContent={true}></NodeTree>
            </div>
        </div>
    );
}
