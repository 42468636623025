import CmonAgentHost, {
    CmonAgentHostProps,
} from '../cmon/models/CmonAgentHost';

export interface CcAgentNodeProps extends CmonAgentHostProps {
    elastic_roles?: string;
}

export default class CcAgentNode extends CmonAgentHost {
    constructor(props: CcAgentNodeProps) {
        super(props);
    }
}
