import React from 'react';
import { Form, Space } from 'antd';
import InfoIcon from '@severalnines/bar-frontend-components/build/lib/General/InfoIcon';
import { FormItemProps } from 'antd/lib/form';
import CcCloudCredentials from '../../../services/models/CcCloudCredentials';
import CloudCredentialsSelect from '../../DataEntry/CloudCredentialsSelect';

export default CloudCredentialsSelectField;
export type CloudCredentialsSelectFieldProps = FormItemProps & {
    onCredentialsSelect?: (credentials?: CcCloudCredentials) => void;
};

function CloudCredentialsSelectField({
    onCredentialsSelect,
    ...rest
}: CloudCredentialsSelectFieldProps) {
    return (
        <div className="CloudCredentialsSelectField">
            <Form.Item
                name="credentials"
                label={
                    <Space>
                        Credentials
                        <InfoIcon
                            info={
                                <span>
                                    Select your named cloud credentials profile
                                    to use for authentication.
                                </span>
                            }
                        />
                    </Space>
                }
                rules={[
                    {
                        required: true,
                        message: 'Please select cloud credentials.',
                    },
                ]}
                {...rest}
            >
                <CloudCredentialsSelect
                    onCredentialsSelect={onCredentialsSelect}
                />
            </Form.Item>
        </div>
    );
}
