


export interface CmonTemplateElementProps  {

}



export default class CmonTemplateElement {


    constructor(props: CmonTemplateElementProps) {

    }

}
