import CcProxySqlUser from '../../../../../../services/models/CcProxySqlUser';
import { ProxySQLUsersFormValues } from './ProxySQLUsersModal';

export const BLACKLISTED_DB_USERS = [
    'cmon',
    'backupuser',
    'cmonexporter',
    'debian-sys-maint',
    'root',
    's9smysqlchk',
    'mysql.session',
    'mysql.infoschema',
    'mysql.sys',
    'mariadb.sys',
    'proxydemo',
    'rpl_user',
    'proxysql-monitor',
    'proxysql-admin',
];

export type TableData = CcProxySqlUser & {
    key: string;
};

export function prepareUserDataEndPoint(data: ProxySQLUsersFormValues) {
    return {
        class_name: 'CmonProxySqlUser',
        active: data.active ? '1' : '0',
        backend: data.backendReadOnly ? '1' : '0',
        default_hostgroup: Number(data.hostGroup),
        default_schema: data.schema ? data.schema : null,
        fast_forward: data.fastForward ? '1' : '0',
        frontend: data.frontendReadOnly ? '1' : '0',
        max_connections: Number(data.maxConnections),
        password: data.password,
        schema_locked: data.schemaLocked ? '1' : '0',
        transaction_persistent: data.transaction ? '1' : '0',
        use_ssl: data.useSSL ? '1' : '0',
        username: data.userName,
        db_database: data.dbName ? data.dbName : null,
        db_privs: Array.isArray(data.sqlPrivileges)
            ? data.sqlPrivileges.join(',')
            : '',
    };
}

export function prepareDeleteUserData(user: CcProxySqlUser) {
    return {
        default_hostgroup: Number(user.defaultHostgroup),
        max_connections: Number(user.maxConnections),
        class_name: user.className,
        active: user.active,
        backend: user.backend,
        default_schema: user.defaultSchema,
        fast_forward: user.fastForward,
        frontend: user.frontend,
        password: user.password,
        schema_locked: user.schemaLocked,
        transaction_persistent: user.transactionPersistent,
        use_ssl: user.useSsl,
        username: user.username,
    };
}
export function prepareEditUserInitialValue(userData: CcProxySqlUser) {
    return {
        userName: userData.username,
        password: userData.password,
        hostGroup: Number(userData.defaultHostgroup),
        schema: userData.defaultSchema,
        maxConnections: Number(userData.maxConnections),
        active: (userData.active as any) === '1' ? true : false,
        schemaLocked: (userData.schemaLocked as any) === '1' ? true : false,
        useSSL: (userData.useSsl as any) === '1' ? true : false,
        transaction:
            (userData.transactionPersistent as any) === '1' ? true : false,
        fastForward: (userData.fastForward as any) === '1' ? true : false,
        backendReadOnly: (userData.backend as any) === '1' ? true : false,
        frontendReadOnly: (userData.frontend as any) === '1' ? true : false,
    };
}

export const privilegeOptions = [
    { label: 'CREATE', value: 'CREATE' },
    { label: 'DROP', value: 'DROP' },
    { label: 'GRANT OPTION', value: 'GRANT OPTION' },
    { label: 'LOCK TABLES', value: 'LOCK TABLES' },
    { label: 'REFERENCES', value: 'REFERENCES' },
    { label: 'EVENT', value: 'EVENT' },
    { label: 'ALTER', value: 'ALTER' },
    { label: 'DELETE', value: 'DELETE' },
    { label: 'INDEX', value: 'INDEX' },
    { label: 'INSERT', value: 'INSERT' },
    { label: 'SELECT', value: 'SELECT' },
    { label: 'UPDATE', value: 'UPDATE' },
    { label: 'CREATE TEMPORARY TABLES', value: 'CREATE TEMPORARY TABLES' },
    { label: 'TRIGGER', value: 'TRIGGER' },
    { label: 'CREATE VIEW', value: 'CREATE VIEW' },
    { label: 'SHOW VIEW', value: 'SHOW VIEW' },
    { label: 'ALTER ROUTINE', value: 'ALTER ROUTINE' },
    { label: 'CREATE ROUTINE', value: 'CREATE ROUTINE' },
    { label: 'EXECUTE', value: 'EXECUTE' },
    { label: 'FILE', value: 'FILE' },
    { label: 'CREATE TABLESPACE', value: 'CREATE TABLESPACE' },
    { label: 'CREATE USER', value: 'CREATE USER' },
    { label: 'PROCESS', value: 'PROCESS' },
    { label: 'PROXY', value: 'PROXY' },
    { label: 'RELOAD', value: 'RELOAD' },
    { label: 'REPLICATION CLIENT', value: 'REPLICATION CLIENT' },
    { label: 'REPLICATION SLAVE', value: 'REPLICATION SLAVE' },
    { label: 'SHOW DATABASES', value: 'SHOW DATABASES' },
    { label: 'SHUTDOWN', value: 'SHUTDOWN' },
    { label: 'SUPER', value: 'SUPER' },
    { label: 'ALL', value: 'ALL' },
    { label: 'ALL PRIVILEGES', value: 'ALL PRIVILEGES' },
    { label: 'USAGE', value: 'USAGE' },
];
