import Search from 'antd/lib/input/Search';
import { Tabs } from 'antd';
import SpaceWide from '../../../common/SpaceWide';
import { ClusterConfigCategory } from '../useClusterConfigGrouped';
import AppEmpty from '../../../common/Feedback/AppEmpty';
import React, { HTMLAttributes, Key, useContext, useEffect } from 'react';
import ClusterConfigGroupTable from './ClusterConfigGroupTable';
import ClusterConfigThresholdsTable from './ClusterConfigThresholdsTable';
import {
    ConfigActionContext,
    ConfigContext,
    ConfigGroupedContext,
} from './ConfigContext';
import AppSpin from '../../../common/General/AppSpin';

export default ClusterConfigParams;
export type ClusterConfigParamsProps = HTMLAttributes<HTMLDivElement> & {
    readonlyCategories?: string[];
    readonlyMessage?: React.ReactNode;
    tabChange?: (key: Key) => void;
    activeCategory?: string;
    noDescription?: boolean;
};

function ClusterConfigParams({
    readonlyCategories,
    readonlyMessage,
    tabChange,
    activeCategory,
    noDescription = false,
    ...rest
}: ClusterConfigParamsProps) {
    const [activeKey, setActiveKey] = React.useState<string>(
        activeCategory || ''
    );
    const {
        availableCategories,
        configGroupedLoading,
        categoryTotal,
    } = useContext(ConfigGroupedContext);

    const { search } = useContext(ConfigActionContext);
    const { searchString, changeLoading } = useContext(ConfigContext);

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        search(e.target.value);
    };

    const handleTabClick = (key: Key) => {
        tabChange?.(key);
        setActiveKey(key as string);
    };

    useEffect(() => {
        if (availableCategories.length > 0) {
            if (
                !availableCategories.includes(
                    activeCategory as ClusterConfigCategory
                )
            ) {
                setActiveKey(availableCategories[0]);
            }
        }
    }, [availableCategories]);

    return (
        <div {...rest}>
            <Search
                placeholder="Search by parameter, value, description"
                onChange={handleSearchChange}
            />
            <div style={{ padding: '30px 0' }}>
                <AppSpin
                    spinning={configGroupedLoading && !changeLoading}
                    showFallback={true}
                >
                    {categoryTotal === 1 ? (
                        <SpaceWide direction="vertical" size={15}>
                            {readonlyCategories?.includes(
                                availableCategories[0]
                            )
                                ? readonlyMessage
                                : undefined}
                            {getClusterConfigCategoryTab(
                                availableCategories[0]
                            )}
                        </SpaceWide>
                    ) : availableCategories.length > 0 ? (
                        <Tabs
                            tabPosition="left"
                            onTabClick={handleTabClick}
                            activeKey={activeKey}
                        >
                            {availableCategories.map((category) => (
                                <Tabs.TabPane
                                    tab={getClusterConfigCategoryName(category)}
                                    key={category}
                                >
                                    <SpaceWide direction="vertical" size={15}>
                                        {readonlyCategories?.includes(category)
                                            ? readonlyMessage
                                            : undefined}
                                        {getClusterConfigCategoryTab(category)}
                                    </SpaceWide>
                                </Tabs.TabPane>
                            ))}
                        </Tabs>
                    ) : searchString ? (
                        <AppEmpty
                            loading={false}
                            description={`Nothing was found for your search "${searchString}"`}
                        />
                    ) : undefined}
                </AppSpin>
            </div>
        </div>
    );

    function getClusterConfigCategoryTab(category: ClusterConfigCategory) {
        switch (category) {
            case ClusterConfigCategory.THRESHOLD:
                return <ClusterConfigThresholdsTable />;
            default:
                return (
                    <ClusterConfigGroupTable
                        noDescription={noDescription}
                        categories={[category]}
                    />
                );
        }
    }
}

// @todo maybe move these out the component, so category names won't be messed up
function getClusterConfigCategoryName(category: ClusterConfigCategory) {
    switch (category) {
        case ClusterConfigCategory.BACKUP:
            return 'Backup';
        case ClusterConfigCategory.CLUSTER:
            return 'Cluster';
        case ClusterConfigCategory.CMONDB:
            return 'CmonDB';
        case ClusterConfigCategory.CONTROLLER:
            return 'Controller';
        case ClusterConfigCategory.LONG_QUERY:
            return 'Long Query';
        case ClusterConfigCategory.REPLICATION:
            return 'Replication';
        case ClusterConfigCategory.RETENTION:
            return 'Retention';
        case ClusterConfigCategory.SAMPLING:
            return 'Sampling';
        case ClusterConfigCategory.SWAPPING:
            return 'Swapping';
        case ClusterConfigCategory.SYSTEM:
            return 'System';
        case ClusterConfigCategory.THRESHOLD:
            return 'Threshold';
        default:
            return category;
    }
}
