import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import CcCluster from '../../services/models/CcCluster';
import MonitorToolbar, { MonitorToolbarApi } from '../Monitor/MonitorToolbar';
import moment from 'moment';
import useTopQueriesList from './useTopQueriesList';
import AppTable from '../../common/DataDisplay/AppTable';
import { ResponsiveContext } from '@severalnines/bar-frontend-components/build/lib/Layout/Responsive';
import SpaceWide from '../../common/SpaceWide';
import TypographyText from '../../common/TypographyText';
import MonitorDashboard, {
    MonitorDashboardApi,
} from '../Monitor/MonitorDashboard';
import { MonitorVariableItem } from '../Monitor/MonitorVariable';
import AgentQueryMonitorEnabledWrapper from './AgentQueryMonitorEnabledWrapper';
import QueryMonitorNodeSelect from './QueryMonitorNodeSelect';
import useTableFilter from '../../common/hooks/useTableFilter';
import { BooleanParam, NumberParam, StringParam } from 'use-query-params';
import { picoToSec } from '../../common/dateTime';
import { formatDuration } from '@severalnines/bar-frontend-components/build/lib/Format/DurationFormat';
import { formatNumber } from '@severalnines/bar-frontend-components/build/lib/Format/NumberFormat';

export default QueryMonitorOverviewPage;

export type QueryMonitorOverviewPageProps = {
    cluster: CcCluster;
};

function QueryMonitorOverviewPage({ cluster }: QueryMonitorOverviewPageProps) {
    const { responsive } = useContext(ResponsiveContext);
    const monitorToolbarRef = useRef<MonitorToolbarApi>(null);
    const monitorDashboardRef = useRef<MonitorDashboardApi>(null);
    const range = 60 * 15; // 15 min

    const {
        list,
        refresh,
        filter: filterList,
        loading,
        page,
        pageSize,
        total,
    } = useTopQueriesList({
        clusterId: cluster.clusterId,
    });

    const {
        filterParams,
        handleTableChange: onTableChange,
        addFilterParams,
    } = useTableFilter({
        params: {
            host: StringParam,
            from: NumberParam,
            to: NumberParam,
            shift: BooleanParam,
        },
        defaultParams: {},
    });
    const [startTs, setStartTs] = useState<number>(
        filterParams.from || moment().unix() - range
    );
    const [endTs, setEndTs] = useState<number>(
        filterParams.to || moment().unix()
    );
    const dbNodes = cluster.getDatabaseNodes();
    const [selectedHostname, setSelectedHostname] = useState<string>(
        !filterParams.host || filterParams.host === 'all'
            ? `${dbNodes[0]?.hostId}:${dbNodes[0]?.getHostWithPort()}`
            : filterParams.host
    );

    const handleNodeChange = async (value: string) => {
        monitorToolbarRef.current?.resetTimeout();
        setSelectedHostname(value);
        addFilterParams({ host: value, page: 1, shift: undefined });
    };

    const handleTimeRangeChange = async (from: number, to: number) => {
        monitorToolbarRef.current?.resetTimeout();
        setStartTs(from);
        setEndTs(to);
        monitorDashboardRef.current?.reloadAll();
        addFilterParams({ page: 1, from, to, shift: undefined });
    };

    const handleTimeRangeShift = async (from: number, to: number) => {
        setStartTs(from);
        setEndTs(to);
        monitorDashboardRef.current?.updateAll();
        addFilterParams({ page: 1, from, to, shift: true });
    };

    useEffect(() => {
        const { host, shift, ...restFilter } = filterParams;
        const [, hostname] = (host || selectedHostname)?.split(':');
        (async () => {
            await refresh({
                ...restFilter,
                begin: new Date(startTs * 1000).toISOString(),
                end: new Date(endTs * 1000).toISOString(),
                hostname,
                showLoading: !shift,
            });
        })();
    }, [filterParams, selectedHostname]);

    const columns = [
        {
            title: 'Digest',
            key: 'digest',
            render: (record: any) => (
                <TypographyText
                    ellipsis={{ tooltip: record.digestText }}
                    style={{ width: '300px' }}
                >
                    {record.digestText}
                </TypographyText>
            ),
        },
        {
            title: 'Schema',
            key: 'schema',
            render: (record: any) => <>{record.schema}</>,
        },
        {
            title: 'Count',
            key: 'count',
            render: (record: any) => <>{record.count}</>,
            align: 'right',
        },
        {
            title: 'Rows',
            children: [
                {
                    title: 'Sent',
                    key: 'rowsSent',
                    render: (record: any) => <>{record.rowsSent}</>,
                    align: 'right',
                },
                {
                    title: 'Affectted',
                    key: 'rowsAffected',
                    render: (record: any) => <>{record.rowsAffected}</>,
                    align: 'right',
                },
                {
                    title: 'Examined',
                    key: 'rowsExamined',
                    render: (record: any) => <>{record.rowsExamined}</>,
                    align: 'right',
                },
            ],
        },
        {
            title: 'Execution time',
            children: [
                {
                    title: 'Avg',
                    key: 'sumTimeAvg',
                    render: (record: any) =>
                        formatDuration(
                            formatNumber(
                                picoToSec(record.sumTime / record.count),
                                1
                            ),
                            {
                                round: false,
                                short: true,
                                seconds: true,
                            }
                        ),
                    align: 'right',
                },
                {
                    title: 'Total',
                    key: 'sumTimeTot',
                    render: (record: any) =>
                        formatDuration(
                            formatNumber(picoToSec(record.sumTime), 1),
                            {
                                round: false,
                                short: true,
                                seconds: true,
                            }
                        ),
                    align: 'right',
                },
            ],
        },
    ];
    const handleTableChange = (pagination: any, filter: any, sorters: any) => {
        filterList({
            page: pagination.current,
        });
    };
    const dashboardPanels = useMemo(
        () => [
            {
                datasource: 'cmonagent',
                title: 'Throughput',
                type: 'chart',
                gridPos: {
                    h: 8,
                    w: 12,
                    x: 0,
                    y: 0,
                },
                options: {
                    decimals: 0,
                    format: 'short',
                    type: 'line',
                    legend: {
                        alignAsTable: true,
                    },
                    yaxis: [
                        {
                            format: 'short',
                            min: 0,
                        },
                    ],
                    stack: false,
                },
                targets: [
                    {
                        expr:
                            "$map($, function($v) {[$toMillis($v[0]) / 1000,$v[3], 'Query count']})(origin=timeline&hostname=$instance)",
                        legendFormat: 'Query count',
                    },
                ],
            },
            {
                datasource: 'cmonagent',
                title: 'Average latency',
                type: 'chart',
                gridPos: {
                    h: 8,
                    w: 12,
                    x: 0,
                    y: 0,
                },
                options: {
                    decimals: 2,
                    format: 'short',
                    type: 'line',
                    postfix: 's',
                    legend: {
                        alignAsTable: true,
                    },
                    yaxis: [
                        {
                            min: 0,
                        },
                    ],
                    stack: false,
                },
                targets: [
                    {
                        expr:
                            "$map($, function($v) {[$toMillis($v[0]) / 1000,$v[5]/$v[3] /1000000000000, 'Average latency']})(origin=timeline&hostname=$instance)",
                        legendFormat: 'Average lantency',
                    },
                ],
            },
            {
                datasource: 'clustercontrol',
                title: 'Concurrency',
                type: 'chart',
                gridPos: {
                    h: 8,
                    w: 12,
                    x: 0,
                    y: 0,
                },
                options: {
                    decimals: 2,
                    format: 'short',
                    type: 'line',
                    postfix: 's',
                    legend: {
                        alignAsTable: true,
                    },
                    yaxis: [
                        {
                            min: 0,
                        },
                    ],
                    stack: false,
                },
                targets: [
                    {
                        expr:
                            '$.[created, INNODB_ROW_LOCK_TIME, "Lock time"](name=sqlstat&calculate_per_sec=true&fields=INNODB_ROW_LOCK_TIME,ABORTED_CONNECTS,ABORTED_CLIENTS,created&host_port=$instance:$port)',
                        legendFormat: 'Lock time',
                    },
                ],
            },
            {
                datasource: 'clustercontrol',
                title: 'Errors',
                type: 'chart',
                gridPos: {
                    h: 8,
                    w: 12,
                    x: 0,
                    y: 0,
                },
                options: {
                    decimals: 2,
                    format: 'short',
                    type: 'line',
                    legend: {
                        alignAsTable: true,
                    },
                    yaxis: [
                        {
                            min: 0,
                        },
                    ],
                    stack: true,
                },
                targets: [
                    {
                        expr:
                            '$.[created, ABORTED_CONNECTS, "Aborted connects"](name=sqlstat&calculate_per_sec=true&fields=INNODB_ROW_LOCK_TIME,ABORTED_CONNECTS,ABORTED_CLIENTS,created&host_port=$instance:$port)',
                        legendFormat: 'Aborted connects',
                    },
                    {
                        expr:
                            '$.[created, ABORTED_CLIENTS, "Aborted clients"](name=sqlstat&calculate_per_sec=true&fields=INNODB_ROW_LOCK_TIME,ABORTED_CONNECTS,ABORTED_CLIENTS,created&host_port=$instance:$port)',
                        legendFormat: 'Aborted clients',
                    },
                ],
            },
        ],
        []
    );

    const dashboardVars = useMemo(() => {
        const [, hostname, port] = (selectedHostname || '')?.split(':');
        return [
            {
                key: 'instance',
                loaded: true,
                ready: true,
                value: hostname,
                variable: {
                    name: 'instance',
                },
                sort: 1,
            } as MonitorVariableItem,
            {
                key: 'port',
                loaded: true,
                ready: true,
                value: port,
                variable: {
                    name: 'port',
                },
                sort: 1,
            } as MonitorVariableItem,
        ];
    }, [selectedHostname]);
    const rowKey = (record: any) => record.digest;
    return (
        <AgentQueryMonitorEnabledWrapper cluster={cluster}>
            <SpaceWide
                direction="vertical"
                size={24}
                className="QueryMonitorOverviewPage"
            >
                <MonitorToolbar
                    ref={monitorToolbarRef}
                    timeRange={range}
                    onTimeRangeChange={handleTimeRangeChange}
                    onTimeRangeShift={handleTimeRangeShift}
                    selectors={[
                        <QueryMonitorNodeSelect
                            key="query-mointor-node-select"
                            cluster={cluster}
                            onChange={handleNodeChange}
                            value={selectedHostname}
                            showAllOption={false}
                        />,
                    ]}
                    timeRangeProps={{
                        enableCustomTimerange: false,
                        quickRanges: [
                            60 * 15, // 15 mins
                            60 * 30, // 30 mins
                            60 * 45, // 45 mins
                            3600, // hour
                            3600 * 2, //  2 hours
                        ],
                    }}
                />
                <MonitorDashboard
                    ref={monitorDashboardRef}
                    cluster={cluster}
                    dashboard={{
                        title: 'Query monitor',
                        meta: {
                            slug: 'query-monitor',
                            supportClusterTypes: [
                                'replication',
                                'galera',
                                'mysql_single',
                                'mysql',
                                'postgresql_single',
                                'postgresql',
                            ],
                        },
                        panels: dashboardPanels,
                        templating: {
                            list: [],
                        },
                    }}
                    vars={dashboardVars}
                    startTs={startTs}
                    endTs={endTs}
                />
                <AppTable
                    className="QueryMonitorOverview"
                    loading={loading}
                    rowKey={rowKey}
                    dataSource={list}
                    columns={columns}
                    pagination={{ current: page, pageSize, total }}
                    size="small"
                    responsive={responsive}
                    onChange={handleTableChange}
                />
            </SpaceWide>
        </AgentQueryMonitorEnabledWrapper>
    );
}
