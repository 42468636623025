import useListFetch, {
    ListFetchRefreshFunctionParams,
} from '../../common/useListFetch';
import CmonJobsService from '../../services/cmon/CmonJobsService';
import CcBackupSchedule from '../../services/models/CcBackupSchedule';
import CcJob from '../../services/models/CcJob';
import useSnapshotRepositoryList from '../SnapshotRepository/useSnapshotRepositoryList';
import { useEffect, useState } from 'react';
type ScheduleListRefreshFunctionParams = ListFetchRefreshFunctionParams & {};
export type UseSchedulesListProps = {};
export default function useSchedulesList({}: UseSchedulesListProps) {
    const [loading, setLoading] = useState(false);
    const {
        refresh: refreshRepositories,
        collection: repositoryCollection,
        loading: repositoryLoading,
    } = useSnapshotRepositoryList();
    const {
        list,
        loading: scheduleLoading,
        refresh: refreshSchedules,
        page,
        pageSize: listPageSize,
        total,
    } = useListFetch({
        name: 'schedules-list',
        order: 'created DESC',
        fetchFn: async ({ clusterId, pageSize, page, ...rest }, opts) => {
            const { jobs, total } = await CmonJobsService.getJobInstances(
                {
                    cluster_id: clusterId,
                    show_defined: false,
                    show_running: false,
                    show_scheduled: true,
                    show_aborted: false,
                    show_finished: false,
                    show_failed: false,
                    limit: pageSize,
                    offset: (page - 1) * pageSize,
                    ...rest,
                },
                opts
            );

            return {
                list: jobs
                    ? jobs.map((j: CcJob) => CcBackupSchedule.createFromJob(j))
                    : [],
                total,
            };
        },
        cancelFn: async ({ requestId }) => {
            await CmonJobsService.cancelRequest(requestId);
        },
    });

    const refresh = async (props: ScheduleListRefreshFunctionParams = {}) => {
        await Promise.all([refreshSchedules(props), refreshRepositories()]);
    };

    useEffect(() => {
        setLoading(scheduleLoading || repositoryLoading);
    }, [scheduleLoading, repositoryLoading]);

    return {
        list,
        loading,
        refresh,
        page,
        pageSize: listPageSize,
        total,
        repositoryCollection,
    };
}
