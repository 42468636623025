import React, { useState } from 'react';
import { Button, Form, Select } from 'antd';
import Alert from '@severalnines/bar-frontend-components/build/lib/Feedback/Alert';
import FormFooter from '../../../common/FormFooter';
import ModalDefaultForm from '../../../common/ModalDefaultForm';
import CcNode from '../../../services/models/CcNode';
import NodeFormat, { getNodeHostWithDesc } from '../NodeFormat';
import SpaceWide from '../../../common/SpaceWide';
import useCreateJob from '../../Jobs/useCreateJob';
import { CmonJobInstanceCommand } from '../../../services/cmon/models/CmonJobInstance';
import CcCluster from '../../../services/models/CcCluster';

export default NodeChangeReplicationMasterForm;

export type NodeChangeReplicationMasterFormProps = {
    node: CcNode;
    cluster: CcCluster;
    onSuccess?: () => void;
    onCancel?: () => void;
};
export type NodeChangeReplicationMasterFormValues = {
    primaryCandidate?: string;
};

function NodeChangeReplicationMasterForm({
    node,
    cluster,
    onSuccess,
    onCancel,
}: NodeChangeReplicationMasterFormProps) {
    const [masterCandidates] = useState(() => {
        return cluster.isReplica()
            ? cluster.getReplicationPrimary()?.getPrimaryCandidates() || []
            : cluster.getPrimaryCandidates(node);
    });
    const [form] = Form.useForm<NodeChangeReplicationMasterFormValues>();
    const { loading, send } = useCreateJob({
        clusterId: node.clusterid,
        title: 'Change replication primary',
        command: CmonJobInstanceCommand.FAILOVER_REPLICATION_SLAVE,
        onSuccess,
    });

    const handleSubmit = async (
        values: NodeChangeReplicationMasterFormValues
    ) => {
        await send({
            remote_cluster_id: cluster.isReplica()
                ? cluster.getReplicationPrimary()?.clusterId
                : null,
            master_address: values.primaryCandidate,
            slave_address: node.getHostWithPort(),
        });
    };
    return (
        <ModalDefaultForm
            title="Change replication primary"
            form={form}
            footer={[]}
            onCancel={onCancel}
            width={450}
            defaultVisible={true}
        >
            <Form
                className="NodeChangeReplicationMasterForm"
                form={form}
                layout="vertical"
                onFinish={handleSubmit}
                initialValues={{}}
            >
                <SpaceWide direction="vertical">
                    <NodeFormat
                        node={node}
                        size="large"
                        contentProps={{ style: { width: '100%' } }}
                    />
                    <Form.Item
                        name="primaryCandidate"
                        label="Primary node candidate"
                        rules={[
                            {
                                required: true,
                                message: 'Please select a primary candidate.',
                            },
                        ]}
                    >
                        <Select
                            data-testid="nodes-actions-nodeChangeReplicationMasterForm-select-primaryCandidate"
                            id="select-primaryCandidate"
                            placeholder="Select primary candidate"
                            style={{ width: '100%' }}
                        >
                            {masterCandidates.map((node) => (
                                <Select.Option
                                    value={node.getHostWithPort()}
                                    key={node.getKey()}
                                >
                                    {getNodeHostWithDesc(node)}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item noStyle={false} shouldUpdate={true}>
                        {() => (
                            <Alert
                                message={
                                    <span>
                                        This procedure is only applicable to
                                        GTID replication. The following actions
                                        will happen on the replica:
                                        <ol>
                                            <li>STOP SLAVE</li>
                                            <li>
                                                CHANGE MASTER TO MASTER_HOST=
                                                {form.getFieldValue(
                                                    'primaryCandidate'
                                                ) || ''}
                                            </li>
                                            <li>START SLAVE</li>
                                        </ol>
                                    </span>
                                }
                                showIcon={true}
                            />
                        )}
                    </Form.Item>
                </SpaceWide>
                <FormFooter>
                    <Button
                        key="done"
                        type="primary"
                        htmlType="submit"
                        loading={loading}
                    >
                        Change
                    </Button>
                </FormFooter>
            </Form>
        </ModalDefaultForm>
    );
}
