import React, { useCallback, useContext } from 'react';
import AppTable from '../../common/DataDisplay/AppTable';
import { ResponsiveContext } from '@severalnines/bar-frontend-components/build/lib/Layout/Responsive';
import {
    formatMonitorValue,
    MonitorValueFormatType,
} from '../Monitor/MonitorValueFormat';
import CcDbStatsDbTable from '../../services/models/CcDbStatsDbTable';

export default DbGrowthTopDbTablesTable;

export type DbGrowthTopDbTablesTableProps = {
    tables: CcDbStatsDbTable[];
};

function DbGrowthTopDbTablesTable({ tables }: DbGrowthTopDbTablesTableProps) {
    const { responsive } = useContext(ResponsiveContext);
    const columns = [
        {
            title: 'name',
            key: 'name',
            render: (record: CcDbStatsDbTable) => <>{record.tableName}</>,
        },
        {
            title: 'Row count',
            key: 'row_count',
            align: 'right',
            render: (record: CcDbStatsDbTable) => <>{record.rowCount}</>,
        },
        {
            title: 'Data size',
            key: 'data_size',
            align: 'right',
            render: (record: CcDbStatsDbTable) => (
                <>
                    {formatMonitorValue(
                        record.dataSize as number,
                        MonitorValueFormatType.SIZE,
                        2
                    )}
                </>
            ),
        },
        {
            title: 'Index size',
            key: 'index_size',
            align: 'right',
            render: (record: CcDbStatsDbTable) => (
                <>
                    {formatMonitorValue(
                        record.indexSize as number,
                        MonitorValueFormatType.SIZE,
                        2
                    )}
                </>
            ),
        },
        {
            title: 'Table size',
            key: 'table_size',
            align: 'right',
            render: (record: CcDbStatsDbTable) => (
                <>
                    {formatMonitorValue(
                        record.dataSize ?? 0 + (record.indexSize ?? 0),
                        MonitorValueFormatType.SIZE,
                        2
                    )}
                </>
            ),
        },
    ];

    const rowKey = useCallback(
        (record: CcDbStatsDbTable) => record.tableName as string,
        []
    );
    return (
        <AppTable
            className="DbGrowthPageTop25Dbs"
            rowKey={rowKey}
            dataSource={tables}
            columns={columns}
            size="middle"
            responsive={responsive}
        />
    );
}
