import ClusterTopologyConfigurator, {
    ClusterTopologyCard,
} from './ClusterTopologyConfigurator';
import CcNode, {
    CcNodeRole,
    CcNodeType,
} from '../../../services/models/CcNode';
import CcCluster from '../../../services/models/CcCluster';
import CcMongoNode from '../../../services/models/CcMongoNode';
import { formatPercentage } from '@severalnines/bar-frontend-components/build/lib/Format/PercentageFormat';
import UnavailableFormat from '@severalnines/bar-frontend-components/build/lib/Format/UnavailableFormat';
import { IDiagramItem } from '@severalnines/bar-frontend-components/build/lib/DataDisplay/Diagram';

export default class ClusterTopologyMongodbConfigurator extends ClusterTopologyConfigurator {
    public hasRouters: boolean;
    constructor(clusters: CcCluster[]) {
        super(clusters);
        this.hasRouters = false;
    }

    nodeToItem(node: CcNode, cluster: CcCluster, item: IDiagramItem): any {
        let result = item;
        this.hasDbNodes = true;
        if (node.isType(CcNodeType.MONGO)) {
            const mongoNode = node as CcMongoNode;
            if (
                [CcNodeRole.MONGO_DATA_NODE, CcNodeRole.MONGO_ARBITER].includes(
                    mongoNode.role as CcNodeRole
                )
            ) {
                let master = cluster.getPrimaryOf(mongoNode);

                if (master) {
                    result.from = master.getKey();
                    result.arrow = 'right';
                } else {
                    if (!this.groups[mongoNode.rs as string]) {
                        this.groups[mongoNode.rs as string] = {
                            key: mongoNode.rs || '',
                            type: 'group',
                            title: mongoNode.rs,
                            from: 'db',
                            placement: 'in',
                        };
                    }
                    result.from = mongoNode.rs;
                }
            } else if (node.isRole(CcNodeRole.MONGO_MONGOS)) {
                this.hasRouters = true;
                result.from = this.getCustomGroup('routers', 'Routers');
            } else if (node.isRole(CcNodeRole.MONGO_CONFIG_SERVER)) {
                result.from = this.getCustomGroup('configs', 'Config Servers');
            }
        } else {
            result = super.nodeToItem(node, cluster, item);
        }
        return result;
    }
    prepareGroups(cluster: CcCluster) {
        super.prepareGroups(cluster);
        if (this.hasRouters) {
            this.groups.db.from = 'routers';
        }
    }
    getNodeCoverCardConfig(node: CcNode): ClusterTopologyCard {
        if (node.isType(CcNodeType.MONGO)) {
            const mongoNode = node as CcMongoNode;
            return [
                [
                    {
                        label: 'Global lock ratio',
                        value: `${
                            mongoNode?.globalLockRatio &&
                            !Number.isNaN(Number(mongoNode.globalLockRatio)) &&
                            formatPercentage(Number(mongoNode.globalLockRatio))
                        } %`,
                    },
                ],
                [
                    {
                        label: 'Global lock queue',
                        value: `${mongoNode.globalLockQueue}`,
                    },
                ],
                [
                    {
                        label: 'Connections',
                        value: `${mongoNode.connectionsCurrent}/${mongoNode.connectionsAvailable}`,
                    },
                    {
                        label: 'Lag',

                        value: (
                            <UnavailableFormat val={mongoNode.replicationLag}>
                                {mongoNode.replicationLag}s
                            </UnavailableFormat>
                        ),
                    },
                ],
            ];
        }

        return super.getNodeCoverCardConfig(node);
    }
    getNodeExtraCardsConfig(node: CcNode): ClusterTopologyCard[] {
        if (node.isType(CcNodeType.MONGO)) {
            const mongoNode = node as CcMongoNode;
            return [
                [
                    [
                        {
                            label: 'Priority',
                            value: mongoNode.priority,
                        },
                    ],
                    [
                        {
                            label: 'Hidden',
                            value: `${mongoNode.hidden ? 'Yes' : 'No'}`,
                        },
                    ],
                    ...(mongoNode.slaveDelay !== 0
                        ? [
                              [
                                  {
                                      label: 'Slave delay',
                                      value: mongoNode.slaveDelay,
                                  },
                              ],
                          ]
                        : []),
                ],
                ...super.getNodeExtraCardsConfig(node),
            ];
        } else {
            return super.getNodeExtraCardsConfig(node);
        }
    }
}
