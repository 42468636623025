import CcCluster from '../../../../../services/models/CcCluster';
import { FormInstance } from 'antd/lib/form';
import AppRadio from '../../../../../common/DataEntry/AppRadio';
import { Form, Space, Tag } from 'antd';
import React from 'react';
import { BackupRestoreType } from '../BackupRestoreWizardForm';
import TypographyText from '../../../../../common/TypographyText';

export default BackupRestoreTypeForm;
type BackupRestoreTypeFormProps = {
    cluster: CcCluster;
    form: FormInstance;
};

function BackupRestoreTypeForm({ cluster, form }: BackupRestoreTypeFormProps) {
    return (
        <Form.Item name="restoreType">
            <AppRadio.Group>
                <Space direction="vertical">
                    <AppRadio value={BackupRestoreType.NODE}>
                        {getBackupRestoreTypeFormItemName(
                            BackupRestoreType.NODE
                        )}
                        <TypographyText muted={true}>
                            <ul>
                                <li>
                                    Restore the backup on an existing database
                                    node.
                                </li>
                            </ul>
                        </TypographyText>
                    </AppRadio>
                    <AppRadio value={BackupRestoreType.STANDALONE_HOST}>
                        {getBackupRestoreTypeFormItemName(
                            BackupRestoreType.STANDALONE_HOST
                        )}
                        <TypographyText muted={true}>
                            <ul>
                                <li>
                                    Verify the backup by restoring it to a new
                                    standalone database host.
                                </li>
                                <li>
                                    This requires a dedicated host not part of
                                    the current cluster.
                                </li>
                            </ul>
                        </TypographyText>
                    </AppRadio>
                </Space>
            </AppRadio.Group>
        </Form.Item>
    );
}

export function getBackupRestoreTypeFormItemName(type: BackupRestoreType) {
    switch (type) {
        case BackupRestoreType.NODE:
            return 'Restore on node';
        case BackupRestoreType.STANDALONE_HOST:
            return 'Restore and verify on standalone host';
        case BackupRestoreType.CLUSTER:
            return 'Create a cluster from the backup';
    }
}
