
import CmonMetaClass, { CmonMetaClassProps } from './CmonMetaClass';


export interface CmonRegExpProps  extends CmonMetaClassProps {
    source?: string;

}



export default class CmonRegExp extends CmonMetaClass {
    public source?: string;


    constructor(props: CmonRegExpProps) {
        super(props);
        this.source = props.source;

    }

}
