import React, { useState } from 'react';
import { ButtonProps } from 'antd/lib/button';
import CcBackup from '../../../services/models/CcBackup';
import ActionButton from '@severalnines/bar-frontend-components/build/lib/General/ActionButton';
import ModalDefault from '../../../common/ModalDefault';
import { Button } from 'antd';
import BackupsTable from '../BackupsTable';
import SpaceDescriptions from '../../../common/Layout/SpaceDescriptions';
import SpaceWide from '../../../common/SpaceWide';
import BackupsList from '../BackupsList';
import { BackupAction } from '../BackupActionsMenu';

export type BackupSetDetailsButtonProps = ButtonProps & {
    backup: CcBackup;
    onSuccess?: () => void;
};
const BackupSetDetailsButton = ({
    children,
    backup,
    onSuccess,
    ...rest
}: BackupSetDetailsButtonProps) => {
    const [visible, setVisible] = useState(false);
    const handleClick = () => {
        setVisible(true);
        if (onSuccess) {
            onSuccess();
        }
    };
    const handleCancel = () => {
        setVisible(false);
    };

    const handleActionPerformed = (action: BackupAction) => {
        if (action === BackupAction.DELETE) {
            // deleting full backup so better we close the Modal,
            // we dont want to see what happens if we don't
            // probably nothing
            setVisible(false);
        }
    };
    return (
        <>
            <ActionButton onClick={handleClick} {...rest}>
                {children || 'View set'}
            </ActionButton>
            <ModalDefault
                title={<div>Backup Set {backup.getId()}</div>}
                visible={visible}
                onCancel={handleCancel}
                footer={[
                    <Button key="done" type="primary" onClick={handleCancel}>
                        Done
                    </Button>,
                ]}
                bodyStyle={{ padding: '20px' }}
                width={1100}
            >
                <SpaceWide direction="vertical" size={40}>
                    <SpaceDescriptions
                        title="Full backups"
                        direction="vertical"
                    >
                        <BackupsTable
                            backups={[backup]}
                            showBackupSetIndicator={false}
                            excludeActions={[BackupAction.VIEW_SET]}
                            onActionPerformed={handleActionPerformed}
                            footer={false}
                        />
                    </SpaceDescriptions>
                    <SpaceDescriptions
                        title="Incremental backups"
                        direction="vertical"
                    >
                        <BackupsList
                            parentId={backup.getId()}
                            defaultPageSize={10}
                            tableProps={{
                                showBackupSetIndicator: false,
                                excludeActions: [BackupAction.VIEW_SET],
                                footer: false,
                            }}
                        />
                    </SpaceDescriptions>
                </SpaceWide>
            </ModalDefault>
        </>
    );
};

export default BackupSetDetailsButton;
