import React from 'react';
import { SpaceProps } from 'antd/lib/space';
import LicenseStateFormat, {
    getLicenseFeatureLimitedState,
} from './LicenseStateFormat';
import { AppState, AppStateLicenseInfo } from '../../appReducer';
import { useSelector } from 'react-redux';
import SpaceWide from '../../common/SpaceWide';

export default LicenseSpaceWrapper;
export type LicenseSpaceWrapperProps = SpaceProps;

function LicenseSpaceWrapper({ children, ...rest }: LicenseSpaceWrapperProps) {
    const [licenseInfo]: [
        AppStateLicenseInfo
    ] = useSelector(({ licenseInfo }: AppState) => [licenseInfo]);
    const state =
        licenseInfo?.license &&
        getLicenseFeatureLimitedState(licenseInfo?.license);
    return (
        <SpaceWide
            className="LicenseSpaceWrapper"
            direction="vertical"
            {...rest}
        >
            {state && (
                <LicenseStateFormat
                    state={state}
                    license={licenseInfo?.license}
                    size="large"
                />
            )}
            {children}
        </SpaceWide>
    );
}
