const mock = {
    class_name: 'CmonClusterInfo',
    cdt_path: '/',
    acl: 'user::rwx,group::rwx,other::---',
    tags: ['postgresql', 'development'],
    cluster_auto_recovery: true,
    cluster_id: 3,
    cluster_name: 'PostgreSQL Streaming Replication',
    cluster_type: 'POSTGRESQL_SINGLE',
    configuration_file: '/etc/cmon.d/cmon_3.cnf',
    effective_privileges: 'rwx',
    log_file: '/var/log/cmon_3.log',
    maintenance_mode_active: false,
    managed: true,
    node_auto_recovery: true,
    rpc_version: '2.0',
    state: 'STARTED',
    status_text: 'All nodes are operational.',
    vendor: 'postgres',
    version: '12',
    alarm_statistics: {
        class_name: 'CmonAlarmStatistics',
        cluster_id: 3,
        created_after: null,
        critical: 0,
        reported_after: null,
        warning: 1,
    },
    group_owner: {
        class_name: 'CmonGroup',
        cdt_path: '/groups',
        owner_user_id: 1,
        owner_user_name: 'system',
        owner_group_id: 1,
        owner_group_name: 'admins',
        acl: 'user::rwx,group::rwx,other::---',
        created: '2021-04-28T22:57:55.328Z',
        group_id: 1,
        group_name: 'admins',
    },
    hosts: [
        {
            class_name: 'CmonPostgreSqlHost',
            cdt_path: '/PostgreSQL Streaming Replication',
            owner_user_id: 1,
            owner_user_name: 'system',
            owner_group_id: 1,
            owner_group_name: 'admins',
            acl: 'user::rwx,group::rwx,other::---',
            additionalconfigfiles: [
                '/etc/postgresql/12/main/pg_hba.conf',
                '/var/lib/postgresql/12/main/postgresql.auto.conf',
            ],
            application_name: 'pgsql_3_node_2',
            archive_command:
                'test ! -f /var/lib/postgresql/12/main/../wal_archive/%f.gz && gzip -c < %p > /var/lib/postgresql/12/main/../wal_archive/%f.gz',
            archive_mode: 'always',
            clusterid: 3,
            configfile: ['/etc/postgresql/12/main/postgresql.conf'],
            connected: true,
            connected_slaves: 0,
            container: true,
            data_directory: '/var/lib/postgresql/12/main',
            datadir: '/var/lib/postgresql/12/main',
            description: '',
            failedmaster: false,
            hostId: 4,
            hostname: 'c8',
            hoststatus: 'CmonHostOnline',
            hot_standby: true,
            ip: '10.0.8.18',
            lastseen: 1641917575,
            logfile:
                '/var/lib/postgresql/12/main/log/postgresql-2021-12-20_071251.log',
            maintenance_mode: 'none',
            maintenance_mode_active: false,
            message: 'Up and running.',
            nodetype: 'postgres',
            pgname: 'main',
            pid: 451,
            pidfile: '/var/run/postgresql/12-main.pid',
            pingstatus: 1,
            pingtime: 38,
            pitr_method: 'local_wal_archive',
            port: 5432,
            readonly: true,
            received_location: '0/15003030',
            replication_state: 'streaming',
            restore_command: '',
            role: 'slave',
            service_started: 1639984371,
            socket: '/var/run/postgresql/.s.PGSQL.5432',
            sshfailcount: 0,
            statereason: 'SQL Ping succeeded',
            status: 10,
            sync_priority: 0,
            sync_state: 'async',
            synchronous: false,
            timestamp: 1641917576,
            trigger_file: '/tmp/failover_5432.trigger',
            unique_id: 4,
            uptime: 1933204,
            version: '12.6 (Ubuntu 12.6-1.pgdg18.04+1)',
            wal_level: 'replica',
            wallclock: 1641917517,
            wallclocktimestamp: 1641917517,
            distribution: {
                name: 'ubuntu',
                codename: 'bionic',
                release: '18.04',
                type: 'debian',
            },
            extensions: [
                {
                    name: 'plpgsql',
                    version: '1.0',
                },
                {
                    name: 'pg_stat_statements',
                    version: '1.7',
                },
            ],
            replication_slave: {
                master_cluster_id: 3,
                master_host: 'c9',
                master_port: '5432',
                replay_location: '0/15003030',
                replaylog_paused: false,
                seconds_behind_master: 0,
            },
            ssl_certs: {
                server: {
                    ca: '/etc/ssl/postgresql_single/cluster_3/server_ca.crt',
                    id: 2,
                    key: '/etc/ssl/postgresql_single/cluster_3/server.key',
                    path: '/etc/ssl/postgresql_single/cluster_3/server.crt',
                    ssl_enabled: true,
                },
            },
        },
        {
            class_name: 'CmonPostgreSqlHost',
            cdt_path: '/PostgreSQL Streaming Replication',
            owner_user_id: 1,
            owner_user_name: 'system',
            owner_group_id: 1,
            owner_group_name: 'admins',
            acl: 'user::rwx,group::rwx,other::---',
            additionalconfigfiles: [
                '/etc/postgresql/12/main/pg_hba.conf',
                '/var/lib/postgresql/12/main/postgresql.auto.conf',
            ],
            application_name: 'pgsql_0_node_0',
            archive_command: '',
            archive_mode: 'off',
            clusterid: 3,
            configfile: ['/etc/postgresql/12/main/postgresql.conf'],
            connected: true,
            connected_slaves: 2,
            container: true,
            data_directory: '/var/lib/postgresql/12/main',
            datadir: '/var/lib/postgresql/12/main',
            description: '',
            failedmaster: false,
            hostId: 5,
            hostname: 'c9',
            hoststatus: 'CmonHostOnline',
            hot_standby: true,
            ip: '10.0.8.19',
            lastseen: 1641917600,
            logfile:
                '/var/lib/postgresql/12/main/log/postgresql-2021-12-20_071050.log',
            maintenance_mode: 'none',
            maintenance_mode_active: false,
            message: 'Up and running.',
            nodetype: 'postgres',
            pgname: 'main',
            pid: 503,
            pidfile: '/var/run/postgresql/12-main.pid',
            pingstatus: 1,
            pingtime: 40,
            pitr_method: 'none',
            port: 5432,
            readonly: false,
            received_location: '0/15003030',
            replication_state: '',
            restore_command: '',
            role: 'master',
            service_started: 1639984250,
            socket: '/var/run/postgresql/.s.PGSQL.5432',
            sshfailcount: 0,
            statereason: 'SQL Ping succeeded',
            status: 10,
            sync_priority: 0,
            sync_state: 'async',
            synchronous: false,
            timestamp: 1641917600,
            trigger_file: '/tmp/failover_5432.trigger',
            unique_id: 5,
            uptime: 1933325,
            version: '12.6 (Ubuntu 12.6-1.pgdg18.04+1)',
            wal_level: 'replica',
            wallclock: 1641917517,
            wallclocktimestamp: 1641917517,
            distribution: {
                name: 'ubuntu',
                codename: 'bionic',
                release: '18.04',
                type: 'debian',
            },
            extensions: [
                {
                    name: 'plpgsql',
                    version: '1.0',
                },
                {
                    name: 'pg_stat_statements',
                    version: '1.7',
                },
            ],
            replication_slave: {
                master_cluster_id: 3,
                master_host: '',
                master_port: '',
                replay_location: '0/15003030',
                replaylog_paused: false,
                seconds_behind_master: '-1',
            },
            ssl_certs: {
                server: {
                    ca: '/etc/ssl/postgresql_single/cluster_3/server_ca.crt',
                    id: 2,
                    key: '/etc/ssl/postgresql_single/cluster_3/server.key',
                    path: '/etc/ssl/postgresql_single/cluster_3/server.crt',
                    ssl_enabled: true,
                },
            },
        },
        {
            class_name: 'CmonPostgreSqlHost',
            cdt_path: '/PostgreSQL Streaming Replication',
            owner_user_id: 1,
            owner_user_name: 'system',
            owner_group_id: 1,
            owner_group_name: 'admins',
            acl: 'user::rwx,group::rwx,other::---',
            additionalconfigfiles: [
                '/etc/postgresql/12/main/pg_hba.conf',
                '/var/lib/postgresql/12/main/postgresql.auto.conf',
            ],
            application_name: 'pgsql_0_node_1',
            archive_command: '',
            archive_mode: 'off',
            clusterid: 3,
            configfile: ['/etc/postgresql/12/main/postgresql.conf'],
            connected: true,
            connected_slaves: 0,
            container: true,
            data_directory: '/var/lib/postgresql/12/main',
            datadir: '/var/lib/postgresql/12/main',
            description: '',
            failedmaster: false,
            hostId: 6,
            hostname: 'c10',
            hoststatus: 'CmonHostOnline',
            hot_standby: true,
            ip: '10.0.8.20',
            lastseen: 1641917575,
            logfile:
                '/var/lib/postgresql/12/main/log/postgresql-2021-12-20_071044.log',
            maintenance_mode: 'none',
            maintenance_mode_active: false,
            message: 'Up and running.',
            nodetype: 'postgres',
            pgname: 'main',
            pid: 424,
            pidfile: '/var/run/postgresql/12-main.pid',
            pingstatus: 1,
            pingtime: 46,
            pitr_method: 'none',
            port: 5432,
            readonly: true,
            received_location: '0/15003030',
            replication_state: 'streaming',
            restore_command: '',
            role: 'slave',
            service_started: 1639984244,
            socket: '/var/run/postgresql/.s.PGSQL.5432',
            sshfailcount: 0,
            statereason: 'SQL Ping succeeded',
            status: 10,
            sync_priority: 0,
            sync_state: 'async',
            synchronous: false,
            timestamp: 1641917575,
            trigger_file: '/tmp/failover_5432.trigger',
            unique_id: 6,
            uptime: 1933331,
            version: '12.6 (Ubuntu 12.6-1.pgdg18.04+1)',
            wal_level: 'replica',
            wallclock: 1641917517,
            wallclocktimestamp: 1641917517,
            distribution: {
                name: 'ubuntu',
                codename: 'bionic',
                release: '18.04',
                type: 'debian',
            },
            extensions: [
                {
                    name: 'plpgsql',
                    version: '1.0',
                },
                {
                    name: 'pg_stat_statements',
                    version: '1.7',
                },
            ],
            replication_slave: {
                master_cluster_id: 3,
                master_host: 'c9',
                master_port: '5432',
                replay_location: '0/15003030',
                replaylog_paused: false,
                seconds_behind_master: 0,
            },
            ssl_certs: {
                server: {
                    ca: '/etc/ssl/postgresql_single/cluster_3/server_ca.crt',
                    id: 2,
                    key: '/etc/ssl/postgresql_single/cluster_3/server.key',
                    path: '/etc/ssl/postgresql_single/cluster_3/server.crt',
                    ssl_enabled: true,
                },
            },
        },
        {
            class_name: 'CmonHost',
            cdt_path: '/PostgreSQL Streaming Replication',
            owner_user_id: 1,
            owner_user_name: 'system',
            owner_group_id: 1,
            owner_group_name: 'admins',
            acl: 'user::rwx,group::rwx,other::---',
            clusterid: 3,
            configfile: '/etc/cmon.d/cmon_3.cnf',
            connected: true,
            container: false,
            hostId: 7,
            hostname: '143.198.228.20',
            hoststatus: 'CmonHostOnline',
            ip: '143.198.228.20',
            lastseen: 1641917575,
            logfile: '/var/log/cmon_3.log',
            maintenance_mode: 'none',
            maintenance_mode_active: false,
            message: 'Up and running.',
            nodetype: 'controller',
            pid: 28846,
            pingstatus: -1,
            pingtime: -1,
            port: 9500,
            role: 'controller',
            status: 10,
            timestamp: 1641917575,
            unique_id: 7,
            uptime: 538913,
            version: '1.9.2.5044',
            wallclock: 1641917517,
            wallclocktimestamp: 1641917517,
            distribution: {
                name: 'ubuntu',
                codename: 'bionic',
                release: '18.04',
                type: 'debian',
            },
        },
        {
            class_name: 'CmonPrometheusHost',
            cdt_path: '/PostgreSQL Streaming Replication',
            owner_user_id: 1,
            owner_user_name: 'system',
            owner_group_id: 1,
            owner_group_name: 'admins',
            acl: 'user::rwx,group::rwx,other::---',
            clusterid: 3,
            configfile: '/etc/prometheus/prometheus.yml',
            container: false,
            data_retention: '15d',
            datadir: '/var/lib/prometheus',
            hostId: 7,
            hostname: '143.198.228.20',
            hoststatus: 'CmonHostOnline',
            ip: '143.198.228.20',
            lastseen: 1641917575,
            logfile: '/var/log/prometheus/prometheus.log',
            maintenance_mode: 'none',
            maintenance_mode_active: false,
            message: "Process 'prometheus' is running.",
            nodetype: 'prometheus',
            pid: 30593,
            pingstatus: -1,
            pingtime: -1,
            port: 9090,
            scrape_interval: '10s',
            statereason: 'Prometheus is available.',
            status: 10,
            timestamp: 1641917575,
            unique_id: 15,
            version: '2.29.2',
            wallclock: 1641917517,
            wallclocktimestamp: 1641917517,
            configuration: [
                {
                    arguments: '',
                    job: 'prometheus',
                    scrape_interval: '2s',
                },
                {
                    arguments: '',
                    job: 'node',
                    scrape_interval: '10s',
                },
                {
                    arguments: '',
                    job: 'process',
                    scrape_interval: '10s',
                },
                {
                    arguments: '',
                    job: 'postgres',
                    scrape_interval: '10s',
                },
                {
                    arguments: '',
                    job: 'proxysql',
                    scrape_interval: '10s',
                },
                {
                    arguments: '',
                    job: 'haproxy',
                    scrape_interval: '10s',
                },
                {
                    arguments: '',
                    job: 'redis',
                    scrape_interval: '10s',
                },
                {
                    arguments: '',
                    job: 'mssql',
                    scrape_interval: '10s',
                },
            ],
            distribution: {
                name: 'ubuntu',
                codename: 'bionic',
                release: '18.04',
                type: 'debian',
            },
            exporters: [
                {
                    address: '143.198.228.20:9011',
                    job: 'process',
                    monitored_host: '',
                    up: true,
                    version: '0.10.10',
                },
                {
                    address: '143.198.228.20:9100',
                    job: 'node',
                    monitored_host: '',
                    up: true,
                    version: '1.0.1',
                },
                {
                    address: 'c10:9011',
                    job: 'process',
                    monitored_host: '',
                    up: true,
                    version: '0.10.10',
                },
                {
                    address: 'c10:9100',
                    job: 'node',
                    monitored_host: '',
                    up: true,
                    version: '1.0.1',
                },
                {
                    address: 'c10:9187',
                    job: 'postgres',
                    monitored_host: 'c10:5432',
                    up: true,
                    version: '0.4.7',
                },
                {
                    address: 'c12:9011',
                    job: 'process',
                    monitored_host: '',
                    up: true,
                    version: '0.10.10',
                },
                {
                    address: 'c12:9100',
                    job: 'node',
                    monitored_host: '',
                    up: true,
                    version: '1.0.1',
                },
                {
                    address: 'c12:9101',
                    job: 'haproxy',
                    monitored_host: 'c12:9600',
                    up: true,
                    version: '0.9.0',
                },
                {
                    address: 'c8:9011',
                    job: 'process',
                    monitored_host: '',
                    up: true,
                    version: '0.10.10',
                },
                {
                    address: 'c8:9100',
                    job: 'node',
                    monitored_host: '',
                    up: true,
                    version: '1.0.1',
                },
                {
                    address: 'c8:9187',
                    job: 'postgres',
                    monitored_host: 'c8:5432',
                    up: true,
                    version: '0.4.7',
                },
                {
                    address: 'c9:9011',
                    job: 'process',
                    monitored_host: '',
                    up: true,
                    version: '0.10.10',
                },
                {
                    address: 'c9:9100',
                    job: 'node',
                    monitored_host: '',
                    up: true,
                    version: '1.0.1',
                },
                {
                    address: 'c9:9187',
                    job: 'postgres',
                    monitored_host: 'c9:5432',
                    up: true,
                    version: '0.4.7',
                },
            ],
        },
        {
            class_name: 'CmonHaProxyHost',
            cdt_path: '/PostgreSQL Streaming Replication',
            acl: 'user::rwx,group::rwx,other::---',
            backend_name_ro: 'haproxy_c12_5434_ro',
            backend_name_rw: 'haproxy_c12_5433_rw_rw',
            clusterid: 3,
            cmdline: 'systemctl restart haproxy',
            configfile: '/etc/haproxy/haproxy.cfg',
            connectstring: 'c12:5433',
            container: true,
            hostId: 16,
            hostname: 'c12',
            hoststatus: 'CmonHostOnline',
            ip: '10.0.8.22',
            lastseen: 1641917575,
            lb_admin: 'admin',
            lb_name: 'haproxy_c12_5434_ro',
            lb_password: 'admin',
            lb_policy: 'leastconn',
            maintenance_mode: 'none',
            maintenance_mode_active: false,
            max_connections_be: 64,
            max_connections_fe: 8192,
            message: "Process 'haproxy' is running.",
            monitor_user: 's9spostgresqlchk',
            nodetype: 'haproxy',
            pid: 459,
            pingstatus: 1,
            pingtime: 45,
            port: 9600,
            ro_port: 5434,
            rw_port: 5433,
            socket: '/var/run/haproxy.socket',
            sshfailcount: 0,
            statereason: 'CmonProcessManager::haProxyDaemonFound()',
            stats_socket: '/var/run/haproxy.socket',
            status: 10,
            svc_check_script: 'postgreschk',
            timeout_client: 10800,
            timeout_server: 10800,
            timestamp: 1641917600,
            unique_id: 18,
            version: '1.8.8',
            wallclock: 1641917517,
            wallclocktimestamp: 1641917517,
            xinetd_allow_from: '0.0.0.0/0',
            xinetd_port: 9201,
            backend_info: {
                backend_servers: [
                    {
                        name: 'admin_page',
                        last_check_state: '',
                        service: 'FRONTEND',
                        status: 'OPEN',
                    },
                    {
                        name: 'admin_page',
                        last_check_state: '',
                        service: 'BACKEND',
                        status: 'UP',
                    },
                    {
                        name: 'haproxy_c12_5433_rw_rw',
                        last_check_state: '',
                        service: 'FRONTEND',
                        status: 'OPEN',
                    },
                    {
                        name: 'haproxy_c12_5433_rw_rw',
                        last_check_state:
                            "TCPCHK did not match content 'master is running' at step 2",
                        service: 'c8',
                        status: 'DOWN',
                    },
                    {
                        name: 'haproxy_c12_5433_rw_rw',
                        last_check_state: '(tcp-check)',
                        service: 'c9',
                        status: 'UP',
                    },
                    {
                        name: 'haproxy_c12_5433_rw_rw',
                        last_check_state:
                            "TCPCHK did not match content 'master is running' at step 2",
                        service: 'c10',
                        status: 'DOWN',
                    },
                    {
                        name: 'haproxy_c12_5433_rw_rw',
                        last_check_state: '',
                        service: 'BACKEND',
                        status: 'UP',
                    },
                    {
                        name: 'haproxy_c12_5434_ro',
                        last_check_state: '',
                        service: 'FRONTEND',
                        status: 'OPEN',
                    },
                    {
                        name: 'haproxy_c12_5434_ro',
                        last_check_state: '(tcp-check)',
                        service: 'c8',
                        status: 'UP',
                    },
                    {
                        name: 'haproxy_c12_5434_ro',
                        last_check_state: '(tcp-check)',
                        service: 'c9',
                        status: 'UP',
                    },
                    {
                        name: 'haproxy_c12_5434_ro',
                        last_check_state: '',
                        service: 'c10',
                        status: 'UP',
                    },
                    {
                        name: 'haproxy_c12_5434_ro',
                        last_check_state: '',
                        service: 'BACKEND',
                        status: 'UP',
                    },
                ],
            },
            bind_node_address: [],
            distribution: {
                name: 'ubuntu',
                codename: 'bionic',
                release: '18.04',
                type: 'debian',
            },
            server_info: {
                nodes: [
                    {
                        hostname: 'c8',
                        port: 5432,
                        role: 'active',
                    },
                    {
                        hostname: 'c9',
                        port: 5432,
                        role: 'backup',
                    },
                    {
                        hostname: 'c10',
                        port: 5432,
                        role: 'backup',
                    },
                ],
            },
        },
    ],
    job_statistics: {
        class_name: 'CmonJobStatistics',
        cluster_id: 3,
        by_state: {
            ABORTED: 0,
            DEFINED: 0,
            DEQUEUED: 0,
            FAILED: 1,
            FINISHED: 1,
            PAUSED: 1,
            RUNNING: 0,
            SCHEDULED: 1,
        },
    },
    owner: {
        class_name: 'CmonUser',
        cdt_path: '/',
        acl: 'user::rwx,group::r--,other::r--',
        disabled: false,
        suspended: false,
        groups: [],
        timezone: {
            class_name: 'CmonTimeZone',
            name: 'Coordinated Universal Time',
            abbreviation: 'UTC',
            offset: 0,
            use_dst: false,
        },
    },
};

export default mock;
