import { useContext, useEffect } from 'react';
import { ServiceMethod, ServiceMockContext } from './ServiceMockContext';

export type UserServiceMockProps = {
    methods: ServiceMethod[];
    mockId: string;
    mock?: any | any[];
};
export default function useServiceMock({
    methods,
    mockId,
    mock,
}: UserServiceMockProps) {
    const { getActual, saveActual, restoreActual, mockMethod } = useContext(
        ServiceMockContext
    );

    if (!getActual(mockId)) {
        saveActual(mockId, methods);
        methods.forEach((method, i) => {
            const currentMock = Array.isArray(mock) ? mock[i] : mock;
            mockMethod(method, currentMock);
        });
    }
    useEffect(() => {
        return () => {
            restoreActual(mockId);
        };
    }, []);
}
