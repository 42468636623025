import React from 'react';
import { Button, Form, Space } from 'antd';
import Alert from '@severalnines/bar-frontend-components/build/lib/Feedback/Alert';
import FormFooter from '../../../common/FormFooter';
import ModalDefaultForm from '../../../common/ModalDefaultForm';
import CcNode from '../../../services/models/CcNode';
import NodeFormat from '../NodeFormat';
import SpaceWide from '../../../common/SpaceWide';
import InfoIcon from '@severalnines/bar-frontend-components/build/lib/General/InfoIcon';
import useCreateJob from '../../Jobs/useCreateJob';
import { CmonJobInstanceCommand } from '../../../services/cmon/models/CmonJobInstance';
import InputNumberWide from '../../../common/DataEntry/InputNumberWide';

export default NodeFreezeForm;

export type NodeFreezeFormProps = {
    node: CcNode;
    onSuccess?: () => void;
    onCancel?: () => void;
};

export type NodeFreezeFormValues = {
    freezeTimeSecs?: number;
};

function NodeFreezeForm({ node, onSuccess, onCancel }: NodeFreezeFormProps) {
    const [form] = Form.useForm<NodeFreezeFormValues>();
    const { loading, send } = useCreateJob({
        clusterId: node.clusterid,
        title: 'Freeze node',
        command: CmonJobInstanceCommand.REPLSET_FREEZE,
        onSuccess,
    });

    const handleSubmit = async (values: NodeFreezeFormValues) => {
        await send({
            hostname: node.hostname,
            port: node.port,
            freeze_time_secs: values.freezeTimeSecs,
        });
    };
    return (
        <ModalDefaultForm
            title="Freeze node"
            form={form}
            footer={[]}
            onCancel={onCancel}
            width={450}
            defaultVisible={true}
        >
            <Form
                className="NodeFreezeForm"
                form={form}
                layout="vertical"
                onFinish={handleSubmit}
                initialValues={{ freezeTimeSecs: 60 }}
            >
                <SpaceWide direction="vertical">
                    <NodeFormat
                        node={node}
                        size="large"
                        contentProps={{ style: { width: '100%' } }}
                    />
                    <Form.Item
                        name="freezeTimeSecs"
                        label={
                            <Space>
                                Set freeze time (in seconds)
                                <InfoIcon
                                    info={
                                        <span>
                                            Prevents a replica set member from
                                            seeking election for the specified
                                            number of seconds
                                        </span>
                                    }
                                />
                            </Space>
                        }
                        rules={[
                            {
                                required: true,
                                message: 'Please enter freeze time.',
                            },
                            {
                                pattern: /^[0-9][0-9]*$/,
                                message:
                                    'Please enter a number greater or equal than 0',
                            },
                        ]}
                    >
                        <InputNumberWide placeholder="Enter enter freeze time" />
                    </Form.Item>
                    <Alert
                        message="If you want to unfreeze a replica set member before the specified number of seconds has elapsed, you can issue the command with a seconds value of 0."
                        showIcon={true}
                    />
                </SpaceWide>
                <FormFooter>
                    <Button
                        key="done"
                        type="primary"
                        htmlType="submit"
                        loading={loading}
                    >
                        Freeze
                    </Button>
                </FormFooter>
            </Form>
        </ModalDefaultForm>
    );
}
