import React from 'react';

import AppRoute from './AppRoute';
import Dashboard from './components/Dashboard/Dashboard';
import Error404 from './common/Error404';
import PageWithJsError from './PageWithJsError';
import LoginPage from './components/Auth/LoginPage';
import ResetPasswordPage from './components/Auth/ResetPasswordPage';
import LogoutPage from './components/Auth/LogoutPage';
import ActivityCenterPage from './components/ActivityCenter/ActivityCenterPage';
import ClusterList from './components/Clusters/ClusterList';
import BackupsPage from './components/Backups/BackupsPage';
import ClusterPage from './components/Clusters/ClusterPage';
import NodesPage from './components/Nodes/NodesPage';
import SettingsPage from './components/Settings/SettingsPage';
import OperationReportPage from './components/OperationalReports/OperationalReportPage';
import DebugComponentsPage from './debug/DebugComponentsPage';
import UserManagementPage from './components/UserManagement/UserManagementPage';
import Registration from './components/Auth/Registration';
import Agreement from './components/Auth/Agreement';
import { Navigate, Routes, Route, useParams } from 'react-router-dom';

const CLUSTERS_BREADCRUMB_CONFIG = {
    '/': 'Home',
    '/clusters': 'Clusters',
    '/clusters/:clusterId': '',
};

export default AppRoutes;

function NavigateToCluster() {
    const { clusterId } = useParams();
    return <Navigate to={`/clusters/${clusterId}/dashboard`} />;
}

function AppRoutes() {
    return (
        <Routes>
            <Route
                path="/"
                element={<Navigate to="/dashboard" replace={true} />}
            />
            <Route
                path="/clusters/:clusterId"
                element={<NavigateToCluster />}
            />
            <Route
                path="/dashboard"
                element={
                    <AppRoute transparentContent={true}>
                        <Dashboard />
                    </AppRoute>
                }
            />
            <Route
                path="/clusters"
                element={
                    <AppRoute
                        breadcrumb={{
                            '/': 'Home',
                            '/clusters': 'Clusters',
                        }}
                        transparentContent={true}
                    >
                        <ClusterList />
                    </AppRoute>
                }
            />
            <Route
                path="/clusters/:clusterId/:page"
                element={
                    <AppRoute
                        contentSize={['large', 'tall']}
                        transparentContent={true}
                        breadcrumb={CLUSTERS_BREADCRUMB_CONFIG}
                        layout={null}
                    >
                        <ClusterPage />
                    </AppRoute>
                }
            />
            <Route
                path="/clusters/:clusterId/nodes/:section?"
                element={
                    <AppRoute
                        transparentContent={true}
                        breadcrumb={CLUSTERS_BREADCRUMB_CONFIG}
                        layout={null}
                    >
                        <ClusterPage page="nodes" />
                    </AppRoute>
                }
            />
            <Route
                path="/clusters/:clusterId/backups/:listType"
                element={
                    <AppRoute
                        transparentContent={true}
                        breadcrumb={CLUSTERS_BREADCRUMB_CONFIG}
                        layout={null}
                    >
                        <ClusterPage page="backups" />
                    </AppRoute>
                }
            />

            <Route
                path="/clusters/:clusterId/settings/:settingsPage?"
                element={
                    <AppRoute
                        transparentContent={true}
                        breadcrumb={CLUSTERS_BREADCRUMB_CONFIG}
                        layout={null}
                    >
                        <ClusterPage page="settings" />
                    </AppRoute>
                }
            />

            <Route
                path="/clusters/:clusterId/settings/:settingsPage/:configPage?"
                element={
                    <AppRoute
                        transparentContent={true}
                        breadcrumb={CLUSTERS_BREADCRUMB_CONFIG}
                        layout={null}
                    >
                        <ClusterPage page="settings" />
                    </AppRoute>
                }
            />

            <Route
                path="/clusters/:clusterId/performance/:section/:subSection?"
                element={
                    <AppRoute
                        transparentContent={true}
                        breadcrumb={CLUSTERS_BREADCRUMB_CONFIG}
                        layout={null}
                    >
                        <ClusterPage page="performance" />
                    </AppRoute>
                }
            />
            <Route
                path="/clusters/:clusterId/logs/:section"
                element={
                    <AppRoute
                        contentSize={['tall']}
                        transparentContent={true}
                        breadcrumb={CLUSTERS_BREADCRUMB_CONFIG}
                        layout={null}
                    >
                        <ClusterPage page="logs" />
                    </AppRoute>
                }
            />
            <Route
                path="/clusters/:clusterId/manage/:managePage"
                element={
                    <AppRoute
                        contentSize={['tall']}
                        transparentContent={true}
                        breadcrumb={CLUSTERS_BREADCRUMB_CONFIG}
                        layout={null}
                    >
                        <ClusterPage page="manage" />
                    </AppRoute>
                }
            />

            <Route
                path="/nodes"
                element={
                    <AppRoute transparentContent={true}>
                        <NodesPage />
                    </AppRoute>
                }
            />

            <Route
                path="/backup/:listType"
                element={
                    <AppRoute transparentContent={true}>
                        <BackupsPage />
                    </AppRoute>
                }
            />

            <Route
                path="/activity-center/:activityType?"
                element={
                    <AppRoute transparentContent={true}>
                        <ActivityCenterPage />
                    </AppRoute>
                }
            />

            <Route
                path="/operational-reports/:page"
                element={
                    <AppRoute transparentContent={true}>
                        <OperationReportPage />
                    </AppRoute>
                }
            />

            <Route
                path="/settings/profile"
                element={
                    <AppRoute transparentContent={true}>
                        <SettingsPage />
                    </AppRoute>
                }
            />

            <Route
                path="/reset-password"
                element={
                    <AppRoute
                        contentSize={['middle']}
                        transparentContent={true}
                        withoutLayout={true}
                    >
                        <ResetPasswordPage />{' '}
                    </AppRoute>
                }
            />

            <Route
                path="/settings/:page"
                element={
                    <AppRoute transparentContent={true}>
                        <SettingsPage />
                    </AppRoute>
                }
            />
            <Route
                path="/user-management/ldap"
                element={
                    <AppRoute transparentContent={true}>
                        <UserManagementPage defaultSection={'ldap'} />
                    </AppRoute>
                }
            />
            <Route
                path="/user-management/:section"
                element={
                    <AppRoute transparentContent={true}>
                        <UserManagementPage />
                    </AppRoute>
                }
            />
            <Route path="/debugComponents/" element={<DebugComponentsPage />} />
            <Route
                path="/debugComponents/:componentName"
                element={<DebugComponentsPage />}
            />
            <Route
                path="/pageWithError"
                element={
                    <AppRoute
                        contentSize={['middle']}
                        transparentContent={true}
                    >
                        <PageWithJsError />
                    </AppRoute>
                }
            />
            <Route
                path="/login"
                element={
                    <AppRoute
                        contentSize={['middle']}
                        transparentContent={true}
                        withoutLayout={true}
                    >
                        <LoginPage />
                    </AppRoute>
                }
            />
            <Route
                path="/registration"
                element={
                    <AppRoute
                        contentSize={['middle']}
                        transparentContent={true}
                        withoutLayout={true}
                    >
                        <Registration />
                    </AppRoute>
                }
            />
            <Route
                path="/agreement"
                element={
                    <AppRoute
                        contentSize={['middle']}
                        transparentContent={true}
                        withoutLayout={true}
                    >
                        <Agreement />
                    </AppRoute>
                }
            />
            <Route
                path="/logout"
                element={
                    <AppRoute
                        contentSize={['middle']}
                        transparentContent={true}
                        withoutLayout={true}
                    >
                        <LogoutPage />
                    </AppRoute>
                }
            />
            <Route path="*" element={<Error404 />} />
        </Routes>
    );
}
