import React, { useEffect } from 'react';
import { Divider, Space } from 'antd';
import BackupCreateWizardButton from '../components/Backups/BackupCreateWizardButton';
import useServiceMock from './serviceMock/useServiceMock';
import CcLicense from '../services/models/CcLicense';
import CcLicenseCheck from '../services/models/CcLicenseCheck';
import CcCluster from '../services/models/CcCluster';
import useClusterList from '../components/Clusters/useClusterList';
import getAllClusterInfo from './dataMocks/rpc/clusters/getAllClusterInfo2_no_elastic';
import { ServiceMethod } from './serviceMock/ServiceMockContext';

export default DebugBackupWizardButtonNoElastic;
export type DebugBackupWizardButtonNoElasticProps = {};

function DebugBackupWizardButtonNoElastic({
    ...rest
}: DebugBackupWizardButtonNoElasticProps) {
    useServiceMock({
        mockId: 'no-elastic',
        methods: [ServiceMethod.GET_ALL_CLUSTER_INFO],
        mock: async () => ({
            license: new CcLicense(getAllClusterInfo.license as any),
            license_check: new CcLicenseCheck(
                getAllClusterInfo.license_check as any
            ),
            clusters: [...getAllClusterInfo.clusters].map(
                (props) => new CcCluster(props as any)
            ),
        }),
    });
    const { refresh } = useClusterList();
    useEffect(() => {
        (async () => {
            await refresh();
        })();
    }, []);

    return (
        <Space direction="vertical" size={20}>
            <Space>
                <Divider
                    plain={true}
                    orientation="left"
                    style={{ marginBottom: '0px', marginTop: '15px' }}
                >
                    Create Backup Wizard
                </Divider>
                <BackupCreateWizardButton>Button</BackupCreateWizardButton>
            </Space>
        </Space>
    );
}
