import { useEffect, useState } from 'react';
import CcJob, { getJobKey } from '../../services/models/CcJob';
import { CmonJobInstanceCommand } from '../../services/cmon/models/CmonJobInstance';
import { useSelector } from 'react-redux';
import { AppState } from '../../appReducer';

export type UseJobWatcherProps = {
    command: CmonJobInstanceCommand | CmonJobInstanceCommand[];
    clusterId?: number;
    extraKey?: (string | number | undefined)[];
    onFinish?: () => void;
    filterJobs?: (job: CcJob) => boolean;
};
export default function useJobWatcher({
    command,
    clusterId,
    extraKey,
    onFinish,
    filterJobs,
}: UseJobWatcherProps): {
    loading: boolean;
    runningJobs: CcJob[];
    stoppedJobs: CcJob[];
} {
    const [runningJobs, setRunningJobs] = useState<CcJob[]>([]);
    const [stoppedJobs, setStoppedJobs] = useState<CcJob[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    const cmds: CmonJobInstanceCommand[] =
        typeof command === 'string' ? [command] : command;
    const [storedRunningJobs] = useSelector(({ runningJobs }: AppState) => [
        runningJobs,
    ]);

    useEffect(() => {
        if (storedRunningJobs) {
            let allJobs = storedRunningJobs.toList().toArray();
            if (filterJobs) {
                allJobs = allJobs.filter(filterJobs);
            }
            const commandKeys = cmds?.map(
                (cmd) =>
                    `${getJobKey({
                        clusterId,
                        command: cmd,
                    })}${extraKey ? `/${extraKey.join('/')}` : ''}`
            );
            const running = allJobs.filter(
                (job) =>
                    (cmds?.includes(job.spec?.command?.toUpperCase()) ||
                        cmds?.includes(job.spec?.command?.toLowerCase())) &&
                    (!clusterId ||
                        (clusterId && job.clusterId === clusterId)) &&
                    (!extraKey ||
                        (extraKey &&
                            commandKeys.includes(
                                `${getJobKey({
                                    clusterId: job.clusterId,
                                    command: job.spec?.command,
                                })}${extraKey ? `/${extraKey.join('/')}` : ''}`
                            )))
            );
            const stopped = runningJobs.filter(
                (job) => !running.find((newJob) => newJob.jobId === job.jobId)
            );
            setRunningJobs(running);
            setStoppedJobs(stopped);
        }
    }, [storedRunningJobs, clusterId]);

    useEffect(() => {
        if (storedRunningJobs) {
            const isLoading = cmds
                .map(
                    (cmd) =>
                        !!storedRunningJobs.has(
                            `${getJobKey({
                                clusterId,
                                command: cmd,
                            })}${extraKey ? `/${extraKey.join('/')}` : ''}`
                        )
                )
                .some((state) => state === true);
            if (onFinish && loading === true && isLoading === false) {
                onFinish();
            }
            setLoading(isLoading);
        }
    }, [storedRunningJobs, command, onFinish, clusterId, extraKey]);

    return {
        runningJobs,
        stoppedJobs,
        loading,
    };
}
