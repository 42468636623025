import React from 'react';
import { Button, Divider, Space } from 'antd';
import ButtonWithForm from '../common/General/ButtonWithForm';
import BackupCloudUploadModal from '../components/Backups/BackupCloudUploadModal';
import CcBackup, { CcBackupProps } from '../services/models/CcBackup';
import CmonCloudService from '../services/cmon/CmonCloudService';
import listCredentials1 from './dataMocks/rpc/cloud/listCredentials1';
import proxyBucketList1 from './dataMocks/rpc/cloud/proxyBucketList1';

export default DebugBackupCloudUploadModal;

export type DebugBackupCloudUploadModalProps = {};

const backup = new CcBackup({ metadata: { id: 1, cid: 2 } } as CcBackupProps);

function DebugBackupCloudUploadModal({}: DebugBackupCloudUploadModalProps) {
    CmonCloudService.listCredentials = () => Promise.resolve(listCredentials1);
    CmonCloudService.proxy = ({ method, body }) => {
        if (method === 'POST') {
            if (body?.bucket === 'testbucket') {
                return Promise.resolve({
                    error: 'testbucket is not valid name',
                });
            }
            return Promise.resolve({ created: 1 });
        }
        return Promise.resolve(proxyBucketList1);
    };

    return (
        <Space direction="vertical" size={20}>
            <Space>
                <Divider
                    plain={true}
                    orientation="left"
                    style={{ marginBottom: '0px', marginTop: '15px' }}
                >
                    Backup To Cloud
                </Divider>
                <ButtonWithForm
                    button={<Button>Button</Button>}
                    form={<BackupCloudUploadModal backup={backup} />}
                />
            </Space>
        </Space>
    );
}
