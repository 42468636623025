
import CmonRequestService, {CmonRequestServiceResponseData} from './CmonRequestService';


export interface GetTreeRequestData {
    path?: string;
    filters?: [];
    request_created?: string;
    request_id?: number;
    [key: string]: any;
}

export interface MoveRequestData {
    request_created?: string;
    request_id?: number;
    source_path?: string;
    target_path?: string;
    [key: string]: any;
}

export interface RenameRequestData {
    request_created?: string;
    request_id?: number;
    source_path?: string;
    target_name?: string;
    [key: string]: any;
}

export interface GetAclRequestData {
    path?: string;
    request_created?: string;
    request_id?: number;
    [key: string]: any;
}

export interface CatRequestData {
    path?: string;
    request_created?: string;
    request_id?: number;
    [key: string]: any;
}

export interface GetObjectRequestData {
    path?: string;
    request_created?: string;
    request_id?: number;
    [key: string]: any;
}

export interface AppendTagRequestData {
    path?: string;
    request_created?: string;
    request_id?: number;
    tag?: string;
    [key: string]: any;
}

export interface RemoveTagRequestData {
    path?: string;
    request_created?: string;
    request_id?: number;
    tag?: string;
    [key: string]: any;
}

export interface AddAclRequestData {
    acl?: string;
    path?: string;
    request_created?: string;
    request_id?: number;
    [key: string]: any;
}

export interface RemoveAclRequestData {
    acl?: string;
    path?: string;
    request_created?: string;
    request_id?: number;
    [key: string]: any;
}

export interface CheckAccessRequestData {
    path?: string;
    privileges?: string;
    request_created?: string;
    request_id?: number;
    [key: string]: any;
}

export interface ChownRequestData {
    owner_group_name?: string;
    owner_user_name?: string;
    path?: string;
    recursive?: boolean;
    request_created?: string;
    request_id?: number;
    [key: string]: any;
}

export interface MkdirRequestData {
    path?: string;
    request_created?: string;
    request_id?: number;
    [key: string]: any;
}

export interface RmdirRequestData {
    path?: string;
    request_created?: string;
    request_id?: number;
    [key: string]: any;
}

export interface MkfileRequestData {
    path?: string;
    request_created?: string;
    request_id?: number;
    [key: string]: any;
}

export interface SetcontentRequestData {
    content?: string;
    path?: string;
    request_created?: string;
    request_id?: number;
    [key: string]: any;
}

export interface DeleteRequestData {
    path?: string;
    request_created?: string;
    request_id?: number;
    [key: string]: any;
}


export default class CmonTreeService extends CmonRequestService {
    
    public static module(): string {
        return 'tree';
    }
    
    public static async getTree(
        data: GetTreeRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('getTree', data, opts);
    }

    public static async move(
        data: MoveRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('move', data, opts);
    }

    public static async rename(
        data: RenameRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('rename', data, opts);
    }

    public static async getAcl(
        data: GetAclRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('getAcl', data, opts);
    }

    public static async cat(
        data: CatRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('cat', data, opts);
    }

    public static async getObject(
        data: GetObjectRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('getObject', data, opts);
    }

    public static async appendTag(
        data: AppendTagRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('appendTag', data, opts);
    }

    public static async removeTag(
        data: RemoveTagRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('removeTag', data, opts);
    }

    public static async addAcl(
        data: AddAclRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('addAcl', data, opts);
    }

    public static async removeAcl(
        data: RemoveAclRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('removeAcl', data, opts);
    }

    public static async checkAccess(
        data: CheckAccessRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('checkAccess', data, opts);
    }

    public static async chown(
        data: ChownRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('chown', data, opts);
    }

    public static async mkdir(
        data: MkdirRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('mkdir', data, opts);
    }

    public static async rmdir(
        data: RmdirRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('rmdir', data, opts);
    }

    public static async mkfile(
        data: MkfileRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('mkfile', data, opts);
    }

    public static async setcontent(
        data: SetcontentRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('setcontent', data, opts);
    }

    public static async delete(
        data: DeleteRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('delete', data, opts);
    }

}
