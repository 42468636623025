import React from 'react';
import CcCluster from '../../../services/models/CcCluster';
import JobsTable from '../../Jobs/JobsTable';
import AppPageWrapper from '../../../common/Layout/AppPageWrapper';

export default ClusterJobs;

export type ClusterJobsProps = { cluster: CcCluster };

function ClusterJobs({ cluster }: ClusterJobsProps) {
    return (
        <AppPageWrapper
            className="ClusterJobs"
            transparentBackground={true}
            noPadding={true}
        >
            <JobsTable clusterId={cluster.clusterId} />
        </AppPageWrapper>
    );
}
