const result = {
    request_status: 'Ok',
    total: 3,
    debug_messages: ["RPC V2 authenticated user is 'alex'."],
    jobs: [
        {
            class_name: 'CmonJobInstance',
            can_be_aborted: false,
            can_be_deleted: true,
            cluster_id: 5,
            created: '2022-09-04T10:10:49.000Z',
            exit_code: 0,
            group_id: 1,
            group_name: 'admins',
            ip_address: '58.11.93.97',
            job_id: 27084,
            job_spec:
                '{"command":"BACKUP","job_data":{"backup_method":"mongodump","backupdir":"/home/ubuntu/backups","backupsubdir":"BACKUP-%I","encrypt_backup":true,"hostname":"","cc_storage":false,"wsrep_desync":false,"backup_retention":0}}',
            recurrence: 'TZ=UTC 0 0 5 * *',
            rpc_version: '2.0',
            status: 'SCHEDULED',
            status_text: '',
            title: 'Mongo backup schedule',
            user_id: 5,
            user_name: 'alex',
        },
        {
            class_name: 'CmonJobInstance',
            can_be_aborted: false,
            can_be_deleted: true,
            cluster_id: 3,
            created: '2022-09-04T10:09:34.000Z',
            exit_code: 0,
            group_id: 1,
            group_name: 'admins',
            ip_address: '58.11.93.97',
            job_id: 27082,
            job_spec:
                '{"command":"BACKUP","job_data":{"backup_method":"pgdump","backupdir":"/root/backups","backupsubdir":"BACKUP-%I","encrypt_backup":true,"hostname":"10.0.8.11","port":5432,"cc_storage":false,"compression":true,"compression_level":6,"include_databases":"postgres","backup_retention":0}}',
            recurrence: 'TZ=UTC 6 3 12 3 *',
            rpc_version: '2.0',
            status: 'SCHEDULED',
            status_text: '',
            title: 'PostgreSQL schedule',
            user_id: 5,
            user_name: 'alex',
        },
        {
            class_name: 'CmonJobInstance',
            can_be_aborted: false,
            can_be_deleted: true,
            cluster_id: 14,
            created: '2022-08-18T10:47:10.000Z',
            exit_code: 0,
            group_id: 1,
            group_name: 'admins',
            ip_address: '58.11.86.197',
            job_id: 27065,
            job_spec:
                '{"command":"BACKUP","job_data":{"snapshot_repository":"cc_snapshots","backup_retention":0}}',
            recurrence: 'TZ=UTC 0 0 1 1 *',
            rpc_version: '2.0',
            status: 'SCHEDULED',
            status_text: '',
            title: 'Elastic backup shcedule',
            user_id: 5,
            user_name: 'alex',
        },
    ],
};

export default result;
