import React from 'react';
import './ClusterTopologySmallNodeItem.less';
import { IDiagramItem } from '@severalnines/bar-frontend-components/build/lib/DataDisplay/Diagram';
import classNames from 'classnames';
import MaintenanceFormat from '../../Maintenance/MaintenanceFormat';
import TypographyText from '../../../common/TypographyText';
import { getNodeTypeInitial } from '../../Nodes/NodeTypeFormat';
import { getNodeStatusFormatType } from '../../Nodes/NodeStatusFormat';
import Honeycomb from '@severalnines/bar-frontend-components/build/lib/DataDisplay/Honeycomb';

export default ClusterTopologySmallNodeItem;

export type ClusterTopologySmallNodeItemProps = {
    item: IDiagramItem;
    className?: string;
};

function ClusterTopologySmallNodeItem({
    item,
    className,
}: ClusterTopologySmallNodeItemProps) {
    return (
        <Honeycomb
            style={{ width: '40px', height: '40px' }}
            hexagons={[
                {
                    contentWrapper: (children) => {
                        return (
                            <div
                                className={classNames(
                                    'ClusterTopologySmallNodeItem',
                                    className
                                )}
                            >
                                {children}
                                <div className="ClusterTopologySmallNodeItem_content-extra">
                                    <MaintenanceFormat
                                        node={item.data.node}
                                        asTag={true}
                                    />
                                </div>
                            </div>
                        );
                    },
                    content: (
                        <TypographyText strong={true} white>
                            {getNodeTypeInitial(
                                item.data.node.nodetype,
                                item.data.node.getRole()
                            )}
                        </TypographyText>
                    ),
                    type: getNodeStatusFormatType(item.data.node.hoststatus),
                },
            ]}
        ></Honeycomb>
    );
}
