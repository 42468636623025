import { Col, Form, Row } from 'antd';
import BackupSelect, { BackupSelectProps } from '../DataEntry/BackupSelect';
import BackupStorageLocationSelect from '../../components/Backups/BackupStorageLocationSelect';
import BackupFormat from '../../components/Backups/BackupFormat';
import React from 'react';
import CcBackup from '../../services/models/CcBackup';
import CcBackupStorageLocation from '../../services/models/CcBackupStorageLocation';
import { FormInstance } from 'antd/lib/form';
import CcCluster from '../../services/models/CcCluster';

export default BackupLocationSelectForm;
type BackupLocationSelectFormProps = {
    form: FormInstance;
    cluster: CcCluster;
    backup?: CcBackup;
    onBackupSelect?: (backup: CcBackup) => void;
    backupSelectProps?: Omit<BackupSelectProps, 'clusterId'>;
    backupFieldName?: string[] | string;
    locationFieldName?: string[] | string;
    showLocation?: boolean;
    disableLocation?: boolean;
};

function BackupLocationSelectForm({
    form,
    cluster,
    backup,
    onBackupSelect,
    backupSelectProps = {},
    backupFieldName = 'backup',
    locationFieldName = 'backupLocation',
    showLocation = true,
    disableLocation = false,
}: BackupLocationSelectFormProps) {
    const handleBackupSelect = (backup: CcBackup) => {
        const backupLocation = form.getFieldValue(locationFieldName);
        const storageLocation: CcBackupStorageLocation = (backupLocation &&
            backup?.getLocation(backupLocation)) as CcBackupStorageLocation;

        /**
         * if there is no location found that means that another backup was selected
         * the reset the location to the first one
         */
        if (!storageLocation) {
            const locationUuid = backup.getStorageLocations()?.[0]?.getUuid();
            if (locationUuid) {
                form.setFieldsValue({
                    backupLocation: locationUuid,
                });
            }
        }

        onBackupSelect?.(backup);
    };
    return (
        <Row gutter={[24, 0]}>
            <Col xs={24} sm={24} md={12}>
                <Form.Item
                    name={backupFieldName}
                    label={<span>Backup</span>}
                    rules={[
                        { required: true, message: 'Please select backup' },
                    ]}
                >
                    <BackupSelect
                        backupLabel={''}
                        selectFirst={true}
                        disabled={!!backup}
                        disableIncremental={!!backup?.isIncremental()}
                        onChange={handleBackupSelect}
                        clusterId={cluster.clusterId as number}
                        {...backupSelectProps}
                    />
                </Form.Item>
                {showLocation && (
                    <Form.Item noStyle={true} shouldUpdate={true}>
                        {() => {
                            const backup = form.getFieldValue(backupFieldName);
                            return (
                                backup && (
                                    <Form.Item
                                        name={locationFieldName}
                                        label={
                                            <span>
                                                Restore this backup from
                                            </span>
                                        }
                                    >
                                        <BackupStorageLocationSelect
                                            selectFirst={true}
                                            backup={backup}
                                            disabled={disableLocation}
                                        />
                                    </Form.Item>
                                )
                            );
                        }}
                    </Form.Item>
                )}
            </Col>
            <Form.Item shouldUpdate={true} noStyle={true}>
                {() => {
                    const backup = form.getFieldValue(backupFieldName);
                    const content = (backup && (
                        <Form.Item label={<span></span>}>
                            <BackupFormat
                                backup={backup}
                                size="xlarge"
                                contentProps={{ style: { width: '100%' } }}
                            />
                        </Form.Item>
                    )) || <></>;
                    return (
                        <Col xs={24} sm={24} md={12}>
                            {content}
                        </Col>
                    );
                }}
            </Form.Item>
        </Row>
    );
}
