import { MonitorVariableItem } from './MonitorVariable';
import {
    MonitorDashboardTemplatingItemConfig,
    MonitorDatasource,
    MonitorTarget,
} from './Monitor';
import CcCluster from '../../services/models/CcCluster';
import jsonata from 'jsonata';
import CcHost from '../../services/models/CcHost';
import CcNode from '../../services/models/CcNode';
import { arrayUnique } from '../../common/filtering';
import { getObjectValueByPath } from '../../common/objectHelpers';

export const INTERPOLATE_PATTERN = /\{\{\s?([\w|.|[|\]]+)\s?\}\}/g;
export const VAR_PATTERN = /\$(\w+)/g;

/**
 * Interpolates a string with data, placeholders in string follows the INTERPOLATE_PATTERN
 * by default
 * f('hello {{ something }}', {something: 'world'}) => 'hello world'
 */
export function strInterpolate(
    input: string,
    data: { [key: string]: any },
    pattern?: RegExp
): string {
    return input.replace(
        pattern || INTERPOLATE_PATTERN,
        ($1, $2) => data && getObjectValueByPath($2.split('.'), data)
    );
}

/**
 * Returns true if string requires replacement of variable matching VAR_PATTERN
 * by default
 * f('hello $name') => true
 * f('hello world') => false
 */
export function queryNeedsReplacements(
    query: string,
    pattern?: RegExp
): boolean {
    return !!query.match(pattern || VAR_PATTERN);
}

/**
 * Interpolates monitor queries with monitor variable items
 * f('my expression $something $another',
 * [{key: 'something', value: 'world'}, {key: 'another', value: '!'}]
 * ) => 'hello world !'
 */
export function exprReplace(
    expression: string,
    vars: MonitorVariableItem[],
    datasource: MonitorDatasource,
    prometheusHostname: string
): string {
    let result = expression;

    vars.forEach((r) => {
        let value = r.value;
        // the following patch is really nasty, we need to add port pattern to selected instances so the query can work
        // we need the label rewrite in prometheus config but it is kind of difficult to achieve without breaking current setups
        if (
            datasource !== MonitorDatasource.CLUSTERCONTROL &&
            r.isInstancePicker
        ) {
            const splittedValue =
                r.variable.includeAll && r.variable.allFormat
                    ? value.split(getVariableAllSeparator(r.variable))
                    : [value, ...(r.extraValues || [])];
            const newValue = splittedValue.map((sv) => {
                // for backwards compatibility we need to use prometheus real hostname also with localhost in the query
                // in the first version of scumm stats were saved with 127.0.0.1 ip for prometheus host
                // now we fixed that in backend but we need to query both, real ip and localhost.
                if (sv === prometheusHostname) {
                    sv += '(:[0-9]+)?|127.0.0.1';
                }

                sv += '(:[0-9]+)?';
                return sv;
            });
            value =
                r.variable.includeAll && r.variable.allFormat
                    ? newValue.join(getVariableAllSeparator(r.variable))
                    : newValue.join('|');
        }
        result = result.replace(new RegExp(`\\$${r.key}`, 'g'), value);
    });

    return result;
}

/**
 * Maps variable separator name to char and returns char
 */
export function getVariableAllSeparator(
    variable: MonitorDashboardTemplatingItemConfig
): string {
    switch (variable.allFormat) {
        case 'pipe':
        default:
            return '|';
    }
}

/**
 * Returns true if query is for cluster, otherwise returns false
 */
export function isQueryForCluster(query: string): boolean {
    return query.indexOf('host:') + query.indexOf('node:') === -1;
    // one of those ^ is 0 then is true
}

/**
 * Returns different array sources depending on the query
 */
export function getClusterQueryDataSource(
    cluster: CcCluster,
    query: string
): CcNode[] | CcHost[] {
    const clusterDatasource = query.substr(0, 4);
    if (clusterDatasource === 'node') {
        return cluster.nodes;
    } else if (clusterDatasource === 'host') {
        return cluster.getHosts();
    } else {
        return [];
    }
}

/**
 * Evaluates cluster sources using jsonata tool to query JSON structures.
 * Returns new structure
 */
export function queryClusterObjects(cluster: CcCluster, query: string): any[] {
    let result: any = getClusterQueryDataSource(cluster, query);
    let jsonQuery = query.substr(5);
    try {
        result = jsonata(`${jsonQuery}`).evaluate(result);
        if (typeof result !== 'object') {
            result = [];
        }
        // jsonata result returns non array for single result
        if (!Array.isArray(result)) {
            result = [result];
        }
    } catch (e) {
        result = [];
    }

    return result;
}

/**
 * Identifies and returns exporters array by analyzing the query
 */
export function queryToExporters(input: string): string[] {
    const exporters = [];
    if (input.match(/node_/g)) {
        exporters.push('node');
    }
    if (input.match(/mysql_/g)) {
        exporters.push('mysqld');
    }
    if (input.match(/proxysql_/g)) {
        exporters.push('proxysql');
    }
    if (input.match(/pg_/g) || input.match(/pgbouncer_/g)) {
        exporters.push('postgres');
    }
    if (input.match(/haproxy_/g)) {
        exporters.push('haproxy');
    }
    if (input.match(/mongodb_/g)) {
        exporters.push('mongo');
    }
    if (input.match(/redis_/g)) {
        exporters.push('redis');
    }
    if (input.match(/mssql_/g)) {
        exporters.push('mssql');
    }
    return arrayUnique(exporters);
}

/**
 * Returns all exporters needed by the array of targets
 */
export function targetsToExporters(targets: MonitorTarget[]): string[] {
    let exporters: string[] = [];
    targets.forEach(({ expr }) => {
        exporters = exporters.concat(queryToExporters(expr));
    });
    return arrayUnique(exporters);
}

/**
 * Returns preferred step depending on the range
 */
// This function takes a range of values, and returns an appropriate step size
// based on the size of the range and the desired interval between data points.

export function getStepByRange(
    from: number,
    to: number,
    scrapeInterval?: number
): number {
    // Define constants for common time intervals.
    const oneSecond = 1;
    const oneMinute = 60 * oneSecond;
    const oneHour = oneMinute * 60;
    const oneDay = oneHour * 24;
    const sevenDays = oneDay * 7;
    const oneMonth = oneDay * 31;

    // Calculate the difference between the start and end of the range.
    const diff = to - from;

    let resultStep;

    // Determine the appropriate step size based on the size of the range.
    if (diff <= oneHour) {
        resultStep = 10;
    } else if (diff < oneHour * 6) {
        resultStep = 20;
    } else if (diff <= oneDay) {
        resultStep = oneMinute;
    } else if (diff <= sevenDays) {
        resultStep = oneHour * 2;
    } else if (diff <= oneMonth) {
        resultStep = oneDay;
    } else {
        resultStep = 10 * oneDay;
    }

    // If the result step is smaller than the scrape interval, use the scrape interval instead.
    if (scrapeInterval && resultStep < scrapeInterval) {
        return scrapeInterval + 1;
    }
    // Otherwise, return the result step.
    return resultStep;
}
