import React from 'react';
import JobActionButton from '../../Jobs/JobActionButton';
import { CmonJobInstanceCommand } from '../../../services/cmon/models/CmonJobInstance';
import ButtonWithForm from '../../../common/General/ButtonWithForm';
import CcCluster from '../../../services/models/CcCluster';
import { ButtonProps } from 'antd/lib/button';
import ClusterAddShardModal from './ClusterAddShard/ClusterAddShardModal';

export default ClusterAddShard;

export type ClusterAddShardProps = ButtonProps & {
    cluster: CcCluster;
    children?: React.ReactNode;
    onSuccess?: () => void;
};

function ClusterAddShard({
    cluster,
    onSuccess,
    children,
    ...rest
}: ClusterAddShardProps) {
    return (
        <ButtonWithForm
            button={
                <JobActionButton
                    command={CmonJobInstanceCommand.ADD_SHARD}
                    clusterId={cluster.clusterId}
                    clusterType={cluster.clusterType}
                    {...rest}
                >
                    {children || 'Add shards'}
                </JobActionButton>
            }
            form={<ClusterAddShardModal cluster={cluster} />}
        />
    );
}
