import useFetch from '../../common/useFetch';
import CmonClustersService from '../../services/cmon/CmonClustersService';
import { useEffect, useState } from 'react';
export type ClusterConfig = {
    [key: string]: ClusterConfigValue;
};
export type ClusterConfigValue = {
    name: string;
    current_value: any;
    default_value: string | number | boolean | null;
    description: string;
    keys: string[];
    sql_keys: string[];
    readonly?: boolean;
    group?: string;
};
export type UseClusterConfigProps = {
    clusterId?: number;
    name?: string;
    useCache?: boolean;
};
export default function useClusterConfig({
    clusterId,
    name = 'use-cluster-config',
    useCache = false,
}: UseClusterConfigProps) {
    const [config, setConfig] = useState<ClusterConfig | undefined>();
    const { loading, data, refresh: refreshFetch } = useFetch<
        ClusterConfigValue[]
    >({
        name,
        useCache,
        fetchFn: async (params, opts) => {
            const {
                configuration: { values },
            } = await CmonClustersService.getConfig(
                {
                    ...params,
                    cluster_id: clusterId,
                },
                opts
            );
            return values as ClusterConfigValue[];
        },
        cancelFn: async ({ requestId }) => {
            await CmonClustersService.cancelRequest(requestId);
        },
    });
    useEffect(() => {
        if (data) {
            setConfig(
                data.reduce(
                    (a: ClusterConfig, c: ClusterConfigValue) => ({
                        ...a,
                        [c.name]: c,
                    }),
                    {}
                )
            );
        }
    }, [data]);
    return {
        config,
        loading,
        refresh: refreshFetch,
    };
}
