import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { BreadcrumbContext } from './BreadcrumbContext';

const VARIABLE_PATTERN = /:([a-zA-Z]+)/g;

type CrumbVariable = {
    name: string;
    value: string;
    title: React.ReactNode;
};

export type BreadcrumbConfig = {
    /**
     * When value is false after type casting then it uses BreadcrumbContext variables
     * to get dynamically set title
     */
    [key: string]: React.ReactNode;
};

type BreadcrumbItem = {
    path: string;
    name: React.ReactNode;
};

export type UseBreadcrumbProps = { config?: BreadcrumbConfig };
export default function useBreadcrumb({ config }: UseBreadcrumbProps = {}) {
    const [items, setItems] = useState<BreadcrumbItem[]>([]);
    const [paramsConfig, setParamsConfig] = useState<BreadcrumbConfig>();
    const location = useLocation();
    const { variables, setVariables } = useContext(BreadcrumbContext);
    const [crumbVariable, setCrumbVariable] = useState<CrumbVariable>();

    useEffect(() => {
        if (crumbVariable) {
            setVariables({
                ...variables,
                [crumbVariable.name]: {
                    value: crumbVariable.value,
                    title: crumbVariable.title,
                },
            });
            setCrumbVariable(undefined);
        }
    }, [crumbVariable]);

    useEffect(() => {
        /**
         * Replacing breadcrumb config params by variables from context
         * e.g. /clusters/:clusterId: '' => /clusters/1: 'Cluster Name'
         */
        setParamsConfig(
            config &&
                Object.entries(config).reduce((a, [key, title]) => {
                    const vars = Array.from(key.matchAll(VARIABLE_PATTERN)).map(
                        ([, paramName]) => paramName
                    );
                    if (vars.length > 0) {
                        key = vars.reduce(
                            (a, c) =>
                                variables.hasOwnProperty(c)
                                    ? a.replace(
                                          `:${c}`,
                                          variables[c]?.value || ''
                                      )
                                    : a,
                            key
                        );
                        const last = vars[vars.length - 1];
                        if (!title && variables.hasOwnProperty(last)) {
                            title = variables[last]?.title || '';
                        }
                    }
                    return { ...a, [key]: title };
                }, {})
        );
    }, [variables, config]);

    useEffect(() => {
        if (location && paramsConfig) {
            let path = '';
            /**
             * Parsing pathname to retrieve crumbs according paramsConfig
             */
            setItems(
                location.pathname
                    .split('/')
                    .map((item) => {
                        path = `${path === '/' ? '' : path}/${item}`;
                        if (paramsConfig[path]) {
                            return { path, name: paramsConfig[path] };
                        }
                        return null;
                    })
                    .filter((item) => !!item) as BreadcrumbItem[]
            );
        }
    }, [location, paramsConfig]);

    const setVariable = useCallback(
        (name, value, title) => {
            setCrumbVariable({
                name,
                value,
                title,
            });
        },
        [setCrumbVariable]
    );

    return {
        items,
        setVariable,
    };
}
