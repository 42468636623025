import JobActionButton from '../../Jobs/JobActionButton';
import { CmonJobInstanceCommand } from '../../../services/cmon/models/CmonJobInstance';
import BackupRestoreWizardModal from '../Wizard/BackupRestore/BackupRestoreWizardModal';
import ButtonWithForm from '../../../common/General/ButtonWithForm';
import React from 'react';
import { BackupAction, getBackupActionFormatText } from '../BackupActionsMenu';
import CcCluster from '../../../services/models/CcCluster';
import CcBackup from '../../../services/models/CcBackup';

export default BackupRestoreActionButton;

type BackupRestoreButtonProps = {
    cluster: CcCluster;
    backup: CcBackup;
    onSuccess?: () => void;
};

function BackupRestoreActionButton({
    cluster,
    backup,
    onSuccess,
}: BackupRestoreButtonProps) {
    return (
        <ButtonWithForm
            button={
                <JobActionButton
                    command={CmonJobInstanceCommand.RESTORE_BACKUP}
                    clusterId={cluster?.clusterId}
                    extraKey={[backup.getId()]}
                >
                    {getBackupActionFormatText(BackupAction.RESTORE)}
                </JobActionButton>
            }
            form={
                <BackupRestoreWizardModal
                    cluster={cluster}
                    backup={backup}
                    onSuccess={onSuccess}
                />
            }
        />
    );
}
