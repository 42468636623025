
import CcNode, { CcNodeProps } from '../../models/CcNode';


export interface CmonElasticHostProps  extends CcNodeProps {
    elastic_roles?: string;
    elected_master?: string;
    on_voting_config?: string;

}



export default class CmonElasticHost extends CcNode {
    public elasticRoles?: string;
    public electedMaster?: string;
    public onVotingConfig?: string;


    constructor(props: CmonElasticHostProps) {
        super(props);
        this.elasticRoles = props.elastic_roles;
        this.electedMaster = props.elected_master;
        this.onVotingConfig = props.on_voting_config;

    }

}
