import React from 'react';
import { FormInstance } from 'antd/es';
import MongoShardsConfigurationServerClusterNodesForm from '../../../Services/Cluster/MongoShards/MongoShardsConfigurationServerClusterNodesForm';
import MongoShardsRouterClusterNodesForm from '../../../Services/Cluster/MongoShards/MongoShardsRouterClusterNodesForm';

export default ClusterConvertToShardTopologyForm;

export type ClusterConvertToShardTopologyFormProps = {
    form: FormInstance;
    clusterId?: number;
};

function ClusterConvertToShardTopologyForm({
    clusterId,
    form,
}: ClusterConvertToShardTopologyFormProps) {
    return (
        <div className="ClusterConvertToShardTopologyForm">
            <MongoShardsConfigurationServerClusterNodesForm
                form={form}
                clusterId={clusterId}
            />
            <MongoShardsRouterClusterNodesForm
                form={form}
                clusterId={clusterId}
            />
        </div>
    );
}
