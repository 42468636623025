import './AppWordHighlighter.less';
import React from 'react';
import Highlighter, { HighlighterProps } from 'react-highlight-words';

export default AppWordHighlighter;
export type AppWordHighlighterProps = HighlighterProps & {};

function AppWordHighlighter({ ...rest }: AppWordHighlighterProps) {
    return (
        <Highlighter
            highlightClassName="AppWordHighlighter--highlighted"
            {...rest}
        />
    );
}
