import React from 'react';
import { SettingsIcon } from '../../common/icons/icons';
import { Tabs, Space } from 'antd';
import UserProfilePage from '../User/UserProfilePage';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import CloudCredentialsPage from '../Integrations/CloudCredentials/CloudCredentialsPage';
import LicensePage from '../License/LicensePage';
import AppPageWrapper from '../../common/Layout/AppPageWrapper';
import useCurrentUser from '../User/useCurrentUser';
import MailServerButton from '../Settings/MailServer/MailServerButton';
import UserAclManageUsers from '../User/UserAclManageUsers';
import KeyManagementManagePage from '../KeyManagement/KeyManagementManagePage';
import NotificationServicesPage from '../Integrations/NotificationServices/NotificationServicesPage';

export default SettingsPage;

export enum SettingsPageType {
    CLOUD_CREDENTIALS = 'cloud-credentials',
    USER_PROFILE = 'profile',
    LICENSE = 'license',
    CERTIFICATES = 'certificates',
    NOTIFICATIONS = 'notifications',
}
export type IntegrationsPageProps = {};

function SettingsPage({}: IntegrationsPageProps) {
    const navigate = useNavigate();
    const { page = SettingsPageType.USER_PROFILE } = useParams<{
        page: SettingsPageType;
    }>();

    const { canManageConfiguration } = useCurrentUser();
    return (
        <AppPageWrapper
            className="SettingsPage"
            title={
                <span>
                    <SettingsIcon /> Settings
                </span>
            }
            headerMenu={
                <Space size={'large'}>
                    <UserAclManageUsers>
                        <MailServerButton />
                    </UserAclManageUsers>
                </Space>
            }
            transparentBackground={true}
            noPadding={true}
        >
            <Tabs
                onTabClick={(key) => {
                    navigate(`/settings/${key}`);
                }}
                activeKey={page}
            >
                <Tabs.TabPane tab="Profile" key={SettingsPageType.USER_PROFILE}>
                    <UserProfilePage />
                </Tabs.TabPane>
                {canManageConfiguration() ? (
                    <Tabs.TabPane
                        tab="Cloud storage credentials"
                        key={SettingsPageType.CLOUD_CREDENTIALS}
                    >
                        <CloudCredentialsPage />
                    </Tabs.TabPane>
                ) : null}

                {canManageConfiguration() ? (
                    <Tabs.TabPane
                        tab="Notification services"
                        key={SettingsPageType.NOTIFICATIONS}
                    >
                        <NotificationServicesPage />
                    </Tabs.TabPane>
                ) : null}

                {canManageConfiguration() ? (
                    <Tabs.TabPane
                        tab="Certificate management"
                        key={SettingsPageType.CERTIFICATES}
                    >
                        <KeyManagementManagePage />
                    </Tabs.TabPane>
                ) : null}

                {canManageConfiguration() ? (
                    <Tabs.TabPane tab="License" key={SettingsPageType.LICENSE}>
                        <LicensePage />
                    </Tabs.TabPane>
                ) : null}
            </Tabs>
        </AppPageWrapper>
    );
}
