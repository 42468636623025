import React, { useRef } from 'react';
import LdapGroupMapList, { LdapGroupMapListApi } from './LdapGroupMapList';
import SpaceWide from '../../../common/SpaceWide';
import { Space } from 'antd';
import LdapSettingsButton from './LdapSettingsButton';
import LdapMapGroupButton from './LdapMapGroupButton';
import { CmonFeature } from '../../../services/cmon/CmonFeature';
import ButtonBarrier from '../../../common/Barrier/ButtonBarrier';

export default LdapPage;

export type LdapPageProps = {};

function LdapPage({}: LdapPageProps) {
    const ldapGroupMapListRef = useRef<LdapGroupMapListApi>();
    const handleMapGroupSuccess = () => {
        ldapGroupMapListRef.current?.refresh();
    };
    return (
        <SpaceWide className="LdapPage" direction="vertical">
            <Space>
                <ButtonBarrier
                    licenseFeature={CmonFeature.LDAP}
                    actualButton={true}
                >
                    <LdapMapGroupButton onSuccess={handleMapGroupSuccess} />
                </ButtonBarrier>
                <ButtonBarrier
                    licenseFeature={CmonFeature.LDAP}
                    actualButton={true}
                >
                    <LdapSettingsButton />
                </ButtonBarrier>
            </Space>
            <LdapGroupMapList ref={ldapGroupMapListRef} />
        </SpaceWide>
    );
}
