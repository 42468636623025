import React from 'react';
import CcUser from '../../services/models/CcUser';
import WrapFormat from '../../common/Format/WrapFormat';
import UserInfo from '../User/UserInfo';

export default UserFormat;
export type UserFormatProps = {
    user: CcUser;
    country?: string;
    showName?: boolean;
};

function UserFormat({ user, showName = true, ...rest }: UserFormatProps) {
    return (
        <WrapFormat
            popoverProps={{
                destroyTooltipOnHide: true,
            }}
            popoverContent={<UserInfo user={user} />}
        >
            <UserInfo user={user} size="xsmall" />
        </WrapFormat>
    );
}

export function getOriginText(user: CcUser) {
    return user.origin === 'CmonDb' ? 'cmon' : user.origin;
}
