import CmonRedisSentinelHost, {
    CmonRedisSentinelHostProps,
} from '../cmon/models/CmonRedisSentinelHost';

export interface CcRedisSentinelNodeProps extends CmonRedisSentinelHostProps {}

export default class CcRedisSentinelNode extends CmonRedisSentinelHost {
    constructor(props: CcRedisSentinelNodeProps) {
        super(props);
    }
}
