import React, { useEffect } from 'react';
import ClusterList from '../components/Clusters/ClusterList';
import AppPageWrapper from '../common/Layout/AppPageWrapper';
import useServiceMock from './serviceMock/useServiceMock';
import { ServiceMethod } from './serviceMock/ServiceMockContext';
import getAllClusterInfo from './dataMocks/rpc/clusters/getAllClusterInfo3_c2c_readonly';
import CcCluster from '../services/models/CcCluster';
import useClusterList from '../components/Clusters/useClusterList';
import {Divider} from "antd";
import SpaceDescriptions from "../common/Layout/SpaceDescriptions";
import ClusterFormat from "../components/Clusters/ClusterFormat";
import ClusterTopology from "../components/Clusters/ClusterTopology";
import AppDivider from "../common/AppDivider";

export default DebugClusterListReadonly;

// this is related to ticket https://severalnines.atlassian.net/browse/CLUS-1881
function DebugClusterListReadonly({}) {
    useServiceMock({
        mockId: 'cluster-c2c-readonly-CLUS-1881',
        methods: [ServiceMethod.GET_ALL_CLUSTER_INFO],
        mock: async () => ({
            clusters: [...getAllClusterInfo.clusters].map(
                (props) => new CcCluster(props as any)
            ),
        }),
    });

    const { refresh, list } = useClusterList();
    useEffect(() => {
        (async () => {
            await refresh();
        })();
    }, []);

    return (
        <AppPageWrapper>
            <ClusterList />
            <Divider plain={true} orientation="left">
                CLUS-1881 c2c replication
            </Divider>
            {list?.map((c) => {
                return (
                    <div key={c.clusterName}>
                        <SpaceDescriptions
                            title={<ClusterFormat cluster={c} />}
                        >
                            <ClusterTopology cluster={c} />
                        </SpaceDescriptions>
                        <AppDivider />
                    </div>
                );
            })}
        </AppPageWrapper>

    );
}
