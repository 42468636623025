import React from 'react';
import CcCluster from '../../../services/models/CcCluster';
import AuditLogTable from '../../AuditLog/AuditLogTable';

export default ClusterAuditLog;

export type ClusterAuditLogProps = { cluster: CcCluster };

function ClusterAuditLog({ cluster }: ClusterAuditLogProps) {
    return <AuditLogTable clusterId={cluster.clusterId} />;
}
