import React from 'react';
import { Col, Row, Space, InputNumber } from 'antd';
import { Form } from 'antd';
import InfoIcon from '@severalnines/bar-frontend-components/build/lib/General/InfoIcon';
import CcCluster from '../../../../../services/models/CcCluster';
import { getNodeHostWithDesc } from '../../../../Nodes/NodeFormat';
import FormItemInlineSwitch from '../../../../../common/DataEntry/FormItemInlineSwitch';

import { FormInstance } from 'antd/lib/form';

type CreateProxySQLServerInstancesProps = {
    selectedCluster?: CcCluster;
    form: FormInstance;
};

export const proxySQLServerInstancesValues = (
    form: FormInstance,
    selectedCluster?: CcCluster
): void => {
    let proxySQLServerInstances: any = {};
    if (selectedCluster?.getDatabaseNodes())
        for (let i = 0; i < selectedCluster?.getDatabaseNodes().length; i++) {
            proxySQLServerInstances[
                `node:${selectedCluster?.getDatabaseNodes()[i].getKey()}`
            ] = true;
            proxySQLServerInstances[
                `maxReplicationLag:${selectedCluster
                    ?.getDatabaseNodes()
                    [i].getKey()}`
            ] = '10';
            proxySQLServerInstances[
                `maxConnection:${selectedCluster
                    ?.getDatabaseNodes()
                    [i].getKey()}`
            ] = '100';
            proxySQLServerInstances[
                `weight:${selectedCluster?.getDatabaseNodes()[i].getKey()}`
            ] = '1';
            proxySQLServerInstances[
                `hostname:${selectedCluster?.getDatabaseNodes()[i].getKey()}`
            ] = `${selectedCluster?.getDatabaseNodes()[i].hostname}`;
            proxySQLServerInstances[
                `port:${selectedCluster?.getDatabaseNodes()[i].getKey()}`
            ] = `${selectedCluster?.getDatabaseNodes()[i].port}`;
        }
    form.setFieldsValue({
        ...{ proxySQLServerInstances, implicitTransactions: false },
    });
};

export default CreateProxySQLServerInstances;

function CreateProxySQLServerInstances({
    selectedCluster,
}: CreateProxySQLServerInstancesProps) {
    return (
        <div style={{ minHeight: 450 }}>
            <Row>
                <Col span={24}>
                    <h3>
                        Server instances in the load balancer{' '}
                        <InfoIcon
                            info={
                                <span>
                                    Switch on the instances you want to be
                                    included in the load balancer.
                                </span>
                            }
                        />
                    </h3>
                </Col>
            </Row>

            {selectedCluster?.getDatabaseNodes().map((node) => {
                return (
                    <Row key={node.hostname}>
                        <Col span={24}>
                            <FormItemInlineSwitch
                                justify={true}
                                label={`${getNodeHostWithDesc(node)}`}
                                name={[
                                    'proxySQLServerInstances',
                                    `node:${node.getKey()}`,
                                ]}
                                style={{ width: '50%' }}
                                labelStrong={false}
                                valuePropName="checked"
                                extraOnSwitch={
                                    <Row gutter={[24, 0]}>
                                        <Col span={12}>
                                            <Form.Item
                                                name={[
                                                    'proxySQLServerInstances',
                                                    `maxReplicationLag:${node.getKey()}`,
                                                ]}
                                                label={
                                                    <Space>
                                                        Max replication lag
                                                    </Space>
                                                }
                                            >
                                                <InputNumber
                                                    min={0}
                                                    style={{ width: '100%' }}
                                                    placeholder="Enter Max Replication Lag."
                                                ></InputNumber>
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item
                                                name={[
                                                    'proxySQLServerInstances',
                                                    `maxConnection:${node.getKey()}`,
                                                ]}
                                                label={
                                                    <Space>
                                                        Max connection
                                                    </Space>
                                                }
                                            >
                                                <InputNumber
                                                    min={0}
                                                    style={{ width: '100%' }}
                                                    placeholder="Enter Max Connection."
                                                ></InputNumber>
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item
                                                name={[
                                                    'proxySQLServerInstances',
                                                    `weight:${node.getKey()}`,
                                                ]}
                                                label={<Space>Weight</Space>}
                                            >
                                                <InputNumber
                                                    min={0}
                                                    style={{ width: '100%' }}
                                                    placeholder="Enter Weight."
                                                ></InputNumber>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                }
                            />
                        </Col>
                    </Row>
                );
            })}
            <Row>
                <Col span={24}>
                    <Col span={12}>
                        <FormItemInlineSwitch
                            justify={true}
                            label={
                                <span>
                                    Are you using implicit transactions?{' '}
                                    <InfoIcon
                                        info={
                                            <span>
                                                Select "Yes" if you rely on SET
                                                autocommit=0 to create a
                                                transaction for you. Select "No"
                                                if you use BEGIN or START
                                                TRANSACTION to create a
                                                transaction for you.
                                            </span>
                                        }
                                    />
                                </span>
                            }
                            name={[
                                'proxySQLServerInstances',
                                'implicitTransactions',
                            ]}
                            labelStrong={false}
                        />
                    </Col>
                </Col>
            </Row>
        </div>
    );
}
