import { Form, Radio, Space } from 'antd';
import InfoIcon from '@severalnines/bar-frontend-components/build/lib/General/InfoIcon';
import React, { useMemo } from 'react';
import CcCluster from '../../../services/models/CcCluster';
import { FormInstance } from 'antd/lib/form';
import { CcNodeType } from '../../../services/models/CcNode';
import HostCheckInputField from '../../../common/DataEntry/HostCheckInputField';

export default BackupToolPgBackrestSetupForm;
type BackupToolPgBackrestInstallFormProps = {
    cluster: CcCluster;
    form: FormInstance;
};

function BackupToolPgBackrestSetupForm({
    cluster,
    form,
}: BackupToolPgBackrestInstallFormProps) {
    const installed = useMemo(() => {
        return cluster.getNodesByType(CcNodeType.PGBACKREST).length > 0;
    }, [cluster]);

    const isOnAllNodes = useMemo(() => {
        return (
            installed &&
            cluster.getNodesByType(CcNodeType.PGBACKREST).length >=
                cluster.getDatabaseNodes().length
        );
    }, [cluster, installed]);

    const isOnPrimary = useMemo(() => {
        return (
            installed &&
            (isOnAllNodes ||
                cluster.getNodesByType(CcNodeType.PGBACKREST).some((node) => {
                    return cluster.getPrimaryNodes().some((primary) => {
                        return primary.hostname === node.hostname;
                    });
                }))
        );
    }, [cluster, isOnAllNodes, installed]);

    const initialValue = isOnAllNodes
        ? 'all_host'
        : isOnPrimary
        ? 'all'
        : 'primary';

    return (
        <Form.Item shouldUpdate noStyle>
            {() => {
                return (
                    <div>
                        <Form.Item
                            name="pg_backrest_config_method"
                            label={<Space>Configuration method</Space>}
                            initialValue={initialValue}
                        >
                            <Radio.Group>
                                <Space direction="vertical">
                                    <Radio
                                        value="primary"
                                        disabled={isOnPrimary}
                                    >
                                        Primary{' '}
                                        <InfoIcon
                                            info={
                                                <span>
                                                    Install on the current
                                                    master but not on slaves.
                                                    The backup repository
                                                    (storing backup data) will
                                                    be configured to be on the
                                                    master. There will be no ssh
                                                    configuration for
                                                    PgBackRest.
                                                </span>
                                            }
                                        />
                                    </Radio>
                                    <Radio value="all" disabled={isOnAllNodes}>
                                        All DB nodes{' '}
                                        <InfoIcon
                                            info={
                                                <span>
                                                    Install on all database
                                                    hosts. The backup repository
                                                    will be created on the
                                                    current master. Backup will
                                                    be made by using standby
                                                    node. PgBackRest will use
                                                    ssh for communicating
                                                    between hosts.
                                                </span>
                                            }
                                        />
                                    </Radio>
                                    <Radio value="all_host">
                                        All DB nodes and dedicated repository
                                        host{' '}
                                        <InfoIcon
                                            info={
                                                <span>
                                                    Install on all database
                                                    hosts. The backup repository
                                                    will be made on a specified
                                                    host. Backup will be made by
                                                    using standby node.
                                                    PgBackRest will use ssh for
                                                    communicating between hosts.
                                                </span>
                                            }
                                        />
                                    </Radio>
                                </Space>
                            </Radio.Group>
                        </Form.Item>
                        {form.getFieldValue('pg_backrest_config_method') ===
                        'all_host' ? (
                            <HostCheckInputField
                                validateTrigger={false}
                                label={<span>Dedicated repository host</span>}
                                form={form}
                                cluster={cluster}
                                name="pg_backrest_repository_host"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter repository host',
                                    },
                                ]}
                            />
                        ) : null}
                    </div>
                );
            }}
        </Form.Item>
    );
}
