import CmonProxySqlSchedule, {
    CmonProxySqlScheduleProps,
} from '../cmon/models/CmonProxySqlSchedule';

export interface CcProxySqlScheduleProps extends CmonProxySqlScheduleProps {}

export default class CcProxySqlSchedule extends CmonProxySqlSchedule {
    constructor(props: CcProxySqlScheduleProps) {
        super(props);
    }

    public getKey(): string {
        return `${this.id}`;
    }
}
