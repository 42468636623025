import React, { useEffect } from 'react';
import { SelectProps } from 'antd';
import CloudCredentialsModalAdd, {
    CloudAddCredentialsResponse,
} from '../../components/Integrations/CloudCredentials/CloudCredentialsModalAdd';
import useCloudCredentials from '../../components/Integrations/CloudCredentials/useCloudCredentials';
import CcCloudCredentials from '../../services/models/CcCloudCredentials';
import SelectWrap from './SelectWrap';
import TypographyText from '../TypographyText';

export default CloudCredentialsSelect;
export type CloudCredentialsSelectProps = SelectProps & {
    onCredentialsSelect?: (credentials?: CcCloudCredentials) => void;
};

function CloudCredentialsSelect({
    onCredentialsSelect,
    onChange,
    value,
    ...rest
}: CloudCredentialsSelectProps) {
    const {
        list,
        refresh: refreshCredentials,
        loading,
        loaded,
    } = useCloudCredentials();
    useEffect(() => {
        (async () => {
            await refreshCredentials();
        })();
    }, []);

    const handleCredentialsSelect = (credentialId: number) => {
        const credentials = list?.find((item) => item.id === credentialId);
        if (credentials) {
            onCredentialsSelect?.(credentials);
        }
    };

    const handleCredentialsCreated = async ({
        added_id,
    }: CloudAddCredentialsResponse = {}) => {
        await refreshCredentials();
        if (added_id) {
            handleCredentialsSelect(added_id);
            onChange?.(added_id, { value: added_id, label: added_id });
        }
    };
    const handleCredentialsCancel = () => {
        onCredentialsSelect?.(undefined);
    };

    useEffect(() => {
        if (value && list) {
            handleCredentialsSelect(value);
        }
    }, [value, list]);

    return (
        <div className="CloudCredentialsSelect">
            <SelectWrap
                data-testid="cloud-credentials-select"
                selectFirst={true}
                onChange={onChange}
                onSelect={handleCredentialsSelect}
                value={(loaded && value) || undefined}
                loading={loading}
                addOptionForm={
                    <CloudCredentialsModalAdd
                        onSuccess={handleCredentialsCreated}
                        onCancel={handleCredentialsCancel}
                    />
                }
                addOptionLabel={
                    <TypographyText type="secondary">
                        Create new credentials
                    </TypographyText>
                }
                {...rest}
                options={
                    list?.map((o) => ({
                        value: o.id,
                        label: o.name,
                    })) || []
                }
            />
        </div>
    );
}
