import React, { useMemo, useState } from 'react';
import {
    ClusterConfigCategory,
    ClusterConfigGrouped,
} from '../useClusterConfigGrouped';
import { ThresholdItem } from './ClusterConfigContext';
import { ClusterConfig, ClusterConfigValue } from '../useClusterConfig';

interface ConfigContextInterface {
    changeLoading: boolean;
    // @todo separate searchString to another context so it won't rerender when just loaded is needed
    searchString?: string;
}

export const ConfigContext = React.createContext<ConfigContextInterface>({
    changeLoading: false,
});

interface ConfigClusterContextInterface {
    clusterId?: number;
    setClusterId: (clusterId?: number) => void;
}

export const ConfigClusterContext = React.createContext<
    ConfigClusterContextInterface
>({
    clusterId: undefined,
    setClusterId: () => {},
});

interface ConfigClusterThresholdsContextInterface {
    thresholds: ThresholdItem[];
}

export const ConfigClusterThresholdsContext = React.createContext<
    ConfigClusterThresholdsContextInterface
>({
    thresholds: [],
});

interface ConfigGroupedContextInterface {
    availableCategories: ClusterConfigCategory[];
    configGroupedLoading: boolean;
    configGrouped?: ClusterConfigGrouped;
    categoryTotal: number;
}

export const ConfigGroupedContext = React.createContext<
    ConfigGroupedContextInterface
>({
    availableCategories: [],
    configGroupedLoading: false,
    configGrouped: {},
    categoryTotal: 0,
});

interface ConfigActionContextInterface {
    search: (searchString: string) => void;

    changeValue: (item: ClusterConfigValue, value: any) => void;

    refresh: (params: any) => void;
}

export const ConfigActionContext = React.createContext<
    ConfigActionContextInterface
>({
    search: () => {},
    changeValue: () => {},
    refresh: () => {},
});

type ConfigGroupedProviderProps = Omit<
    ConfigActionContextInterface,
    'search'
> & {
    configGroupedLoading: boolean;
    configGrouped?: ClusterConfigGrouped;
    children: React.ReactNode;
    changeLoading: boolean;
};

export function ConfigGroupedProvider({
    children,
    changeValue,
    refresh,
    configGroupedLoading,
    configGrouped,
    changeLoading,
}: ConfigGroupedProviderProps) {
    const [searchString, setSearchString] = useState<string>();

    const search = useMemo(() => {
        return (searchString: string) => {
            setSearchString(searchString || undefined);
        };
    }, []);

    const categoryTotal = useMemo(() => {
        return (configGrouped && Object.keys(configGrouped).length) || 0;
    }, [configGrouped]);

    // search result based on searchString
    const searchResultGrouped = useMemo(() => {
        if (searchString && configGrouped) {
            return Object.entries(configGrouped).reduce(
                (acc, [key, values]) => {
                    const filteredValues = Object.values(
                        values
                    ).filter((item) => filterConfigItem(searchString, item));

                    if (filteredValues.length > 0) {
                        acc[
                            key as ClusterConfigCategory
                        ] = filteredValues.reduce((a, item) => {
                            a[item.name] = item;
                            return a;
                        }, {} as ClusterConfig);
                    }
                    return acc;
                },
                {} as ClusterConfigGrouped
            );
        }
        return configGrouped;
    }, [searchString, configGrouped]);

    const availableCategories = useMemo(() => {
        return (
            (searchResultGrouped &&
                (Object.keys(
                    searchResultGrouped
                ) as ClusterConfigCategory[])) ||
            []
        );
    }, [searchResultGrouped]);

    const configContext = useMemo(() => {
        return {
            searchString,
            changeLoading,
        };
    }, [searchString, changeLoading]);
    const actionConfigContext = useMemo(() => {
        return {
            search,
            changeValue,
            refresh,
        };
    }, [search, changeValue, refresh]);

    const memoizedCategories = useMemo(() => {
        return availableCategories;
    }, [availableCategories.sort().join(',')]);

    const configGroupedContext = useMemo(() => {
        return {
            configGroupedLoading,
            availableCategories: memoizedCategories,
            configGrouped: searchResultGrouped,
            categoryTotal
        };
    }, [configGroupedLoading, memoizedCategories, searchResultGrouped]);

    return (
        <ConfigContext.Provider value={configContext}>
            <ConfigActionContext.Provider value={actionConfigContext}>
                <ConfigGroupedContext.Provider value={configGroupedContext}>
                    {children}
                </ConfigGroupedContext.Provider>
            </ConfigActionContext.Provider>
        </ConfigContext.Provider>
    );
}

/**
 *
 * @param searchString
 * @param current_value
 * @param name
 * @param description
 */
export function filterConfigItem(
    searchString?: string,
    {
        current_value,
        name,
        description,
    }: ClusterConfigValue = {} as ClusterConfigValue
) {
    const searchStringLower = searchString?.toLowerCase() || '';
    return (
        (name && name.toLowerCase().indexOf(searchStringLower) !== -1) ||
        (current_value &&
            `${current_value}`.toLowerCase().indexOf(searchStringLower) !==
                -1) ||
        (description &&
            description.toLowerCase().indexOf(searchStringLower) !== -1)
    );
}
