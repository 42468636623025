import React from 'react';
import { FormInstance } from 'antd/lib/form';
import SpaceDescriptions from '../../../../common/Layout/SpaceDescriptions';
import YesNoFormat from '../../../../common/Format/YesNoFormat';

export default RedisNodeSummary;

export type RedisNodeSummaryProps = {
    form: FormInstance;
};

function RedisNodeSummary({ form }: RedisNodeSummaryProps) {
    const nodeConfig = form.getFieldValue('nodeConfig');
    return (
        <SpaceDescriptions
            direction="vertical"
            title="Node configuration"
            className="RedisDeploymentNodeSummary"
            alignItems="right"
        >
            <SpaceDescriptions.Item label="Redis port" labelStrong>
                {nodeConfig?.redisPort}
            </SpaceDescriptions.Item>
            <SpaceDescriptions.Item label="Redis sentinel port" labelStrong>
                {nodeConfig?.redisSentinelPort}
            </SpaceDescriptions.Item>
            <SpaceDescriptions.Item label="Password" labelStrong>
                {nodeConfig?.dbPassword?.replace(/./g, '*') || ''}
            </SpaceDescriptions.Item>
            {nodeConfig?.replicationUser && (
                <SpaceDescriptions.Item label="Replication user" labelStrong>
                    {nodeConfig?.replicationUser}
                </SpaceDescriptions.Item>
            )}
            {nodeConfig?.replicationPassword && (
                <SpaceDescriptions.Item
                    label="Replication password"
                    labelStrong
                >
                    {nodeConfig?.replicationPassword?.replace(/./g, '*') || ''}
                </SpaceDescriptions.Item>
            )}
            {nodeConfig?.sentinelPassword && (
                <SpaceDescriptions.Item label="Sentinel password" labelStrong>
                    {nodeConfig?.sentinelPassword?.replace(/./g, '*') || ''}
                </SpaceDescriptions.Item>
            )}

            {/** @todo dunno maybe here would better to decompose component or delete this comment */}
            {nodeConfig?.clusterAutoRecovery !== undefined ? (
                <SpaceDescriptions.Item
                    label="Cluster auto-recovery"
                    labelStrong
                >
                    <YesNoFormat booleanVar={nodeConfig.clusterAutoRecovery} />
                </SpaceDescriptions.Item>
            ) : null}
            {nodeConfig?.nodeAutoRecovery !== undefined ? (
                <SpaceDescriptions.Item label="Node auto-recovery" labelStrong>
                    <YesNoFormat booleanVar={nodeConfig.nodeAutoRecovery} />
                </SpaceDescriptions.Item>
            ) : null}
        </SpaceDescriptions>
    );
}
