import { useEffect } from 'react';
import { Space, Tabs } from 'antd';
import { AuditOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import AppPageWrapper from '../../common/Layout/AppPageWrapper';
import OperationalReportSchedulesTable from './OperationalReportSchedulesTable';
import OperationalReportTable from './OperationalReportTable';
import CmonReportsService from '../../services/cmon/CmonReportsService';
import CcReport from '../../services/models/CcReport';
import useFetch from '../../common/useFetch';

export default OperationalReportPage;

export enum OperationalReportType {
    REPORTS = 'reports',
    SCHEDULES = 'schedules',
}

export type OperationalReportPageProps = {};

function OperationalReportPage({}: OperationalReportPageProps) {
    const navigate = useNavigate();
    const { page = OperationalReportType.REPORTS } = useParams<{
        page: OperationalReportType;
    }>();

    const { data: templates, refresh } = useFetch<CcReport[]>({
        useCache: true,
        fetchFn: async () => {
            const response = await CmonReportsService.getReportTemplates({});
            return response?.reports.map(
                (template: CcReport) => template
            ) as CcReport[];
        },
        cancelFn: async ({ requestId }) => {
            await CmonReportsService.cancelRequest(requestId);
        },
    });

    useEffect(() => {
        (async () => {
            await refresh({});
        })();
    }, []);

    return (
        <AppPageWrapper
            title={
                <Space>
                    <AuditOutlined />
                    Operational reports
                </Space>
            }
            transparentBackground={true}
            noPadding={true}
        >
            <Tabs
                onTabClick={(key) => {
                    navigate(`/operational-reports/${key}`);
                }}
                activeKey={page}
            >
                <Tabs.TabPane tab="Reports" key={OperationalReportType.REPORTS}>
                    <OperationalReportTable templates={templates} />
                </Tabs.TabPane>

                <Tabs.TabPane
                    tab="Schedules"
                    key={OperationalReportType.SCHEDULES}
                >
                    <OperationalReportSchedulesTable templates={templates} />
                </Tabs.TabPane>
            </Tabs>
        </AppPageWrapper>
    );
}
