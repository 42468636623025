const result = {
    total: 4,
    clusters: [
        {
            class_name: 'CmonClusterInfo',
            cdt_path: '/',
            acl: 'user::rwx,group::rwx,other::---',
            tags: [],
            cluster_auto_recovery: true,
            cluster_id: 10,
            cluster_name: 'CHQ-MYSQL1-PROD',
            cluster_type: 'GALERA',
            configuration_file: '/etc/cmon.d/cmon_10.cnf',
            effective_privileges: '',
            log_file: '/var/log/cmon_10.log',
            maintenance_mode_active: false,
            managed: true,
            node_auto_recovery: true,
            state: 'STARTED',
            status_text: 'All nodes are operational.',
            vendor: 'percona',
            version: '5.7',
            alarm_statistics: {
                class_name: 'CmonAlarmStatistics',
                cluster_id: 10,
                created_after: null,
                critical: 0,
                reported_after: null,
                warning: 1,
            },
            group_owner: {
                class_name: 'CmonGroup',
                cdt_path: '/groups',
                owner_user_id: 1,
                owner_user_name: 'system',
                owner_group_id: 1,
                owner_group_name: 'admins',
                acl: 'user::rwx,group::rwx,other::---',
                group_id: 1,
                group_name: 'admins',
            },
            hosts: [
                {
                    class_name: 'CmonHost',
                    cdt_path: '/CHQ-MYSQL1-PROD',
                    owner_user_id: 1,
                    owner_user_name: 'system',
                    owner_group_id: 1,
                    owner_group_name: '',
                    acl: 'user::rwx,group::rw-,other::---',
                    clusterid: 10,
                    configfile: '/etc/cmon.d/cmon_10.cnf',
                    connected: true,
                    container: false,
                    hostId: 19,
                    hostname: 'CHQMYSQLMGMT',
                    hoststatus: 'CmonHostOnline',
                    ip: '10.200.6.171',
                    lastseen: 1679940592,
                    logfile: '/var/log/cmon_10.log',
                    maintenance_mode: 'none',
                    maintenance_mode_active: false,
                    message: 'Up and running.',
                    nodetype: 'controller',
                    pid: 1774,
                    pingstatus: -1,
                    pingtime: -1,
                    port: 9500,
                    role: 'controller',
                    status: 10,
                    timestamp: 1679940595,
                    unique_id: 15,
                    uptime: 300957,
                    version: '1.9.5.6225',
                    wallclock: 1679940595,
                    wallclocktimestamp: 1679940595,
                    distribution: {
                        name: 'ubuntu',
                        codename: 'bionic',
                        release: '18.04',
                        type: 'debian',
                    },
                },
                {
                    class_name: 'CmonHaProxyHost',
                    cdt_path: '/CHQ-MYSQL1-PROD',
                    acl: 'user::rwx,group::rw-,other::---',
                    backend_name_ro: 'haproxy_CHQMYSQL1HALBPROD1_3308_ro',
                    backend_name_rw: 'haproxy_CHQMYSQL1HALBPROD1_3307',
                    clusterid: 10,
                    cmdline:
                        '/usr/sbin/haproxy -D -f /etc/haproxy/haproxy.cfg -sf $(cat /var/run/haproxy.pid)',
                    configfile: '/etc/haproxy/haproxy.cfg',
                    connectstring: 'CHQMYSQL1HALBPROD1:3307',
                    container: false,
                    hostId: 22,
                    hostname: 'CHQMYSQL1HALBPROD1',
                    hoststatus: 'CmonHostOnline',
                    ip: '10.200.6.174',
                    lastseen: 1679940592,
                    lb_admin: 'admin',
                    lb_name: 'haproxy_CHQMYSQL1HALBPROD1_3307',
                    lb_password: 'admin',
                    lb_policy: 'leastconn',
                    maintenance_mode: 'none',
                    maintenance_mode_active: false,
                    max_connections_be: 64,
                    max_connections_fe: 8192,
                    message: "Process 'haproxy' is running.",
                    monitor_user: '',
                    nodetype: 'haproxy',
                    pid: 1441,
                    pingstatus: 1,
                    pingtime: 158,
                    port: 9600,
                    ro_port: 3308,
                    rw_port: 3307,
                    socket: '/var/run/haproxy.socket',
                    sshfailcount: 0,
                    statereason: 'CmonProcessManager::haProxyDaemonFound()',
                    stats_socket: '/var/run/haproxy.socket',
                    status: 10,
                    svc_check_script: 'mysqlchk',
                    timeout_client: 10800,
                    timeout_server: 10800,
                    timestamp: 1679940595,
                    unique_id: 18,
                    version: '1.8.8',
                    wallclock: 1679940595,
                    wallclocktimestamp: 1679940595,
                    xinetd_allow_from: '0.0.0.0/0',
                    xinetd_port: 9200,
                    backend_info: {
                        backend_servers: [
                            {
                                name: 'admin_page',
                                last_check_state: '',
                                service: 'FRONTEND',
                                status: 'OPEN',
                            },
                            {
                                name: 'admin_page',
                                last_check_state: '',
                                service: 'BACKEND',
                                status: 'UP',
                            },
                            {
                                name: 'haproxy_CHQMYSQL1HALBPROD1_3307',
                                last_check_state: '',
                                service: 'FRONTEND',
                                status: 'OPEN',
                            },
                            {
                                name: 'haproxy_CHQMYSQL1HALBPROD1_3307',
                                last_check_state: 'OK',
                                service: 'chqmysql2prod1',
                                status: 'UP',
                            },
                            {
                                name: 'haproxy_CHQMYSQL1HALBPROD1_3307',
                                last_check_state: 'OK',
                                service: 'chqmysql2prod2',
                                status: 'UP',
                            },
                            {
                                name: 'haproxy_CHQMYSQL1HALBPROD1_3307',
                                last_check_state: 'OK',
                                service: 'chqmysql2prod3',
                                status: 'UP',
                            },
                            {
                                name: 'haproxy_CHQMYSQL1HALBPROD1_3307',
                                last_check_state: '',
                                service: 'BACKEND',
                                status: 'UP',
                            },
                        ],
                    },
                    bind_node_address: [
                        'CHQMYSQL1PROD2',
                        'CHQMYSQL1PROD3',
                        'RGEMYSQL1PROD1',
                    ],
                    distribution: {
                        name: 'ubuntu',
                        codename: 'bionic',
                        release: '18.04',
                        type: 'debian',
                    },
                    server_info: {
                        nodes: [
                            {
                                hostname: 'CHQMYSQL1PROD2',
                                port: 3306,
                                role: 'ACTIVE',
                            },
                            {
                                hostname: 'CHQMYSQL1PROD3',
                                port: 3306,
                                role: 'ACTIVE',
                            },
                            {
                                hostname: 'RGEMYSQL1PROD1',
                                port: 3306,
                                role: 'ACTIVE',
                            },
                        ],
                    },
                },
                {
                    class_name: 'CmonHaProxyHost',
                    cdt_path: '/CHQ-MYSQL1-PROD',
                    acl: 'user::rwx,group::rw-,other::---',
                    backend_name_ro: 'haproxy_CHQMYSQL1HALBPROD2_3308_ro',
                    backend_name_rw: 'haproxy_CHQMYSQL1HALBPROD2_3307',
                    clusterid: 10,
                    cmdline:
                        '/usr/sbin/haproxy -D -f /etc/haproxy/haproxy.cfg -sf $(cat /var/run/haproxy.pid)',
                    configfile: '/etc/haproxy/haproxy.cfg',
                    connectstring: 'CHQMYSQL1HALBPROD2:3307',
                    container: false,
                    hostId: 23,
                    hostname: 'CHQMYSQL1HALBPROD2',
                    hoststatus: 'CmonHostOnline',
                    ip: '10.200.6.175',
                    lastseen: 1679940592,
                    lb_admin: 'admin',
                    lb_name: 'haproxy_CHQMYSQL1HALBPROD2_3307',
                    lb_password: 'admin',
                    lb_policy: 'leastconn',
                    maintenance_mode: 'none',
                    maintenance_mode_active: false,
                    max_connections_be: 64,
                    max_connections_fe: 8192,
                    message: "Process 'haproxy' is running.",
                    monitor_user: '',
                    nodetype: 'haproxy',
                    pid: 1401,
                    pingstatus: 1,
                    pingtime: 182,
                    port: 9600,
                    ro_port: 3308,
                    rw_port: 3307,
                    socket: '/var/run/haproxy.socket',
                    sshfailcount: 0,
                    statereason: 'CmonProcessManager::haProxyDaemonFound()',
                    stats_socket: '/var/run/haproxy.socket',
                    status: 10,
                    svc_check_script: 'mysqlchk',
                    timeout_client: 10800,
                    timeout_server: 10800,
                    timestamp: 1679940603,
                    unique_id: 19,
                    version: '1.8.8',
                    wallclock: 1679940595,
                    wallclocktimestamp: 1679940595,
                    xinetd_allow_from: '0.0.0.0/0',
                    xinetd_port: 9200,
                    backend_info: {
                        backend_servers: [
                            {
                                name: 'admin_page',
                                last_check_state: '',
                                service: 'FRONTEND',
                                status: 'OPEN',
                            },
                            {
                                name: 'admin_page',
                                last_check_state: '',
                                service: 'BACKEND',
                                status: 'UP',
                            },
                            {
                                name: 'haproxy_CHQMYSQL1HALBPROD2_3307',
                                last_check_state: '',
                                service: 'FRONTEND',
                                status: 'OPEN',
                            },
                            {
                                name: 'haproxy_CHQMYSQL1HALBPROD2_3307',
                                last_check_state: 'OK',
                                service: 'chqmysql2prod1',
                                status: 'UP',
                            },
                            {
                                name: 'haproxy_CHQMYSQL1HALBPROD2_3307',
                                last_check_state: 'OK',
                                service: 'chqmysql2prod2',
                                status: 'UP',
                            },
                            {
                                name: 'haproxy_CHQMYSQL1HALBPROD2_3307',
                                last_check_state: 'OK',
                                service: 'chqmysql2prod3',
                                status: 'UP',
                            },
                            {
                                name: 'haproxy_CHQMYSQL1HALBPROD2_3307',
                                last_check_state: '',
                                service: 'BACKEND',
                                status: 'UP',
                            },
                        ],
                    },
                    bind_node_address: [
                        'CHQMYSQL1PROD2',
                        'CHQMYSQL1PROD3',
                        'RGEMYSQL1PROD1',
                    ],
                    distribution: {
                        name: 'ubuntu',
                        codename: 'bionic',
                        release: '18.04',
                        type: 'debian',
                    },
                    server_info: {
                        nodes: [
                            {
                                hostname: 'CHQMYSQL1PROD2',
                                port: 3306,
                                role: 'ACTIVE',
                            },
                            {
                                hostname: 'CHQMYSQL1PROD3',
                                port: 3306,
                                role: 'ACTIVE',
                            },
                            {
                                hostname: 'RGEMYSQL1PROD1',
                                port: 3306,
                                role: 'ACTIVE',
                            },
                        ],
                    },
                },
                {
                    class_name: 'CmonKeepalivedHost',
                    name: 'VI_HAPROXY',
                    cdt_path: '/CHQ-MYSQL1-PROD',
                    owner_user_id: 1,
                    owner_user_name: 'system',
                    owner_group_id: 1,
                    owner_group_name: 'admins',
                    acl: 'user::rwx,group::rw-,other::---',
                    clusterid: 10,
                    cmdline: '/usr/sbin/keepalived',
                    configfile: '/etc/keepalived/keepalived.conf',
                    container: false,
                    hostId: 22,
                    hostname: 'CHQMYSQL1HALBPROD1',
                    hoststatus: 'CmonHostOnline',
                    ip: '10.200.6.174',
                    lastseen: 1679940592,
                    maintenance_mode: 'none',
                    maintenance_mode_active: false,
                    message: "Process 'keepalived' is running.",
                    nic: 'ens160',
                    nodetype: 'keepalived',
                    pid: 1397,
                    pingstatus: 1,
                    pingtime: 158,
                    port: 112,
                    role: 'master',
                    sshfailcount: 0,
                    statereason: 'The keepalived process found.',
                    status: 10,
                    timestamp: 1679940595,
                    unique_id: 20,
                    version: '1.3',
                    virtualip: '10.200.6.176',
                    wallclock: 1679940595,
                    wallclocktimestamp: 1679940595,
                    backend_info: {
                        backend_servers: [
                            {
                                hostname: 'CHQMYSQL1HALBPROD1',
                                hoststatus: 'CmonHostOnline',
                                nodetype: 'haproxy',
                                port: 9600,
                            },
                            {
                                hostname: 'CHQMYSQL1HALBPROD2',
                                hoststatus: 'CmonHostOnline',
                                nodetype: 'haproxy',
                                port: 9600,
                            },
                        ],
                    },
                    distribution: {
                        name: 'ubuntu',
                        codename: 'bionic',
                        release: '18.04',
                        type: 'debian',
                    },
                },
                {
                    class_name: 'CmonKeepalivedHost',
                    name: 'VI_HAPROXY',
                    cdt_path: '/CHQ-MYSQL1-PROD',
                    owner_user_id: 1,
                    owner_user_name: 'system',
                    owner_group_id: 1,
                    owner_group_name: 'admins',
                    acl: 'user::rwx,group::rw-,other::---',
                    clusterid: 10,
                    cmdline: '/usr/sbin/keepalived',
                    configfile: '/etc/keepalived/keepalived.conf',
                    container: false,
                    hostId: 23,
                    hostname: 'CHQMYSQL1HALBPROD2',
                    hoststatus: 'CmonHostOnline',
                    ip: '10.200.6.175',
                    lastseen: 1679940592,
                    maintenance_mode: 'none',
                    maintenance_mode_active: false,
                    message: "Process 'keepalived' is running.",
                    nic: 'ens160',
                    nodetype: 'keepalived',
                    pid: 1367,
                    pingstatus: 1,
                    pingtime: 182,
                    port: 112,
                    role: 'backup',
                    sshfailcount: 0,
                    statereason: 'The keepalived process found.',
                    status: 10,
                    timestamp: 1679940595,
                    unique_id: 21,
                    version: '1.3',
                    virtualip: '10.200.6.176',
                    wallclock: 1679940595,
                    wallclocktimestamp: 1679940595,
                    backend_info: {
                        backend_servers: [
                            {
                                hostname: 'CHQMYSQL1HALBPROD1',
                                hoststatus: 'CmonHostOnline',
                                nodetype: 'haproxy',
                                port: 9600,
                            },
                            {
                                hostname: 'CHQMYSQL1HALBPROD2',
                                hoststatus: 'CmonHostOnline',
                                nodetype: 'haproxy',
                                port: 9600,
                            },
                        ],
                    },
                    distribution: {
                        name: 'ubuntu',
                        codename: 'bionic',
                        release: '18.04',
                        type: 'debian',
                    },
                },
                {
                    class_name: 'CmonGaleraHost',
                    cdt_path: '/CHQ-MYSQL1-PROD',
                    owner_user_id: 1,
                    owner_user_name: 'system',
                    owner_group_id: 1,
                    owner_group_name: 'admins',
                    acl: 'user::rwx,group::rwx,other::---',
                    clusterid: 10,
                    configfile: ['/etc/mysql/my.cnf'],
                    connected: true,
                    container: false,
                    datadir: '/var/lib/mysql/',
                    description: '',
                    errorcode: 0,
                    errormsg: 'Up and running.',
                    hostId: 94,
                    hostname: 'chqmysql2prod1',
                    hostname_internal: '10.202.42.21',
                    hoststatus: 'CmonHostOnline',
                    ip: '10.202.42.21',
                    isgalera: true,
                    lastseen: 1679940613,
                    log_bin: 'ON',
                    log_bin_basename: '/var/lib/mysql/binlog',
                    logfile: '/var/log/mysql/mysqld.log',
                    maintenance_mode: 'none',
                    maintenance_mode_active: false,
                    message: 'Up and running.',
                    mysqlstatus: 0,
                    nodeid: 94,
                    nodetype: 'galera',
                    performance_schema: true,
                    pid: 876862,
                    pidfile: '/var/lib/mysql/mysql.pid',
                    pingstatus: 1,
                    pingtime: 571,
                    port: 3306,
                    readonly: false,
                    role: 'slave',
                    serverid: 10000,
                    skip_name_resolve: false,
                    slaves: [],
                    socket: '/var/lib/mysql/mysql.sock',
                    sshfailcount: 0,
                    statereason:
                        'Setting to MYSQL_OK because the node is Synced',
                    status: 0,
                    stopped_at: '2022-10-04T04:04:57.931Z',
                    super_read_only: false,
                    timestamp: 1679940613,
                    unique_id: 94,
                    uptime: 15082341,
                    version: '5.7.37-40-57-log',
                    version_comment:
                        'Percona XtraDB Cluster (GPL), Release rel40, Revision ce3b993, WSREP version 31.57, wsrep_31.57',
                    wallclock: 1679940595,
                    wallclocktimestamp: 1679940595,
                    audit_log: {
                        plugins: [],
                    },
                    distribution: {
                        name: 'ubuntu',
                        codename: 'focal',
                        release: '20.04',
                        type: 'debian',
                    },
                    galera: {
                        certsdepsdistance: 48.0014,
                        cluster_address:
                            'gcomm://10.202.42.21,10.202.42.22,10.202.42.23',
                        clustername: 'CHQ-MYSQL1-PROD',
                        clustersize: 3,
                        connected: true,
                        flowctrlpaused: 0,
                        flowctrlrecv: 0,
                        flowctrlsent: 0,
                        galerastatus: 'Primary',
                        gcache_recover: false,
                        lastcommitted: 247472049,
                        localrecvqueue: 0,
                        localrecvqueueavg: 0,
                        localsendqueue: 0,
                        localsendqueueavg: 0,
                        localstatus: 4,
                        localstatusstr: 'Synced',
                        provider: '/usr/lib/libgalera_smm.so',
                        ready: 'ON',
                        segmentid: 0,
                        socketssl: false,
                        sst_method: 'xtrabackup-v2',
                    },
                    replication_master: {
                        binlog_do_db: '',
                        binlog_ignore_db: '',
                        exec_gtid:
                            '2f70a3a1-ed92-ee13-5337-cd9fb2cbd8a0:1-113,833aceaa-fce5-ee16-5c99-55a3814928da:1-81296659,bf245890-0bc5-ee13-7bdb-651cff5f7712:1-13955,edeed2da-bb94-ee12-7358-112b4d9bfcb2:1',
                        file: 'binlog.006845',
                        position: '15059826',
                        semisync_status: 'OFF',
                    },
                    replication_slave: {
                        exec_master_log_pos: '780610675',
                        executed_gtid_set:
                            '2f70a3a1-ed92-ee13-5337-cd9fb2cbd8a0:1-113,\n833aceaa-fce5-ee16-5c99-55a3814928da:1-81296659,\nbf245890-0bc5-ee13-7bdb-651cff5f7712:1-13955,\nedeed2da-bb94-ee12-7358-112b4d9bfcb2:1',
                        gtid_binlog_pos: '',
                        gtid_io_pos: '',
                        last_io_errno: '0',
                        last_io_error: '',
                        last_io_error_timestamp: '',
                        last_sql_errno: '0',
                        last_sql_error: '',
                        last_sql_error_timestamp: '',
                        linkstatus: 1,
                        master_cluster_id: 18,
                        master_host: '10.102.42.21',
                        master_log_file: 'binlog.000026',
                        master_port: '3306',
                        master_server_id: '18000',
                        master_uuid: '12714bef-446b-11ed-b33b-0050568ad942',
                        read_master_log_pos: '780610675',
                        relay_master_log_file: 'binlog.000026',
                        retrieved_gtid_set:
                            'edeed2da-bb94-ee12-7358-112b4d9bfcb2:1',
                        seconds_behind_master: '0',
                        semisync_status: 'OFF',
                        slave_io_running: 'Yes',
                        slave_io_state: 'Waiting for master to send event',
                        slave_sql_running: 'Yes',
                        slave_sql_state:
                            'Slave has read all relay log; waiting for more updates',
                        sqldelay: 0,
                        status:
                            'Waiting for master to send event<br/>Slave has read all relay log; waiting for more updates',
                        using_gtid: 'ON',
                    },
                    slow_query: {
                        log_not_using_idxs: 'ON',
                        long_query_time: '0.500000',
                        slow_query_log: 'OFF',
                        slow_query_log_file: '/var/log/mysql/mysql-slow.log',
                    },
                    ssl_certs: {
                        replication: {
                            ca: '',
                            id: 0,
                            key: '',
                            path: '',
                        },
                        server: {
                            ca: '/var/lib/mysql/ca.pem',
                            id: 0,
                            key: '/var/lib/mysql/server-key.pem',
                            path: '/var/lib/mysql/server-cert.pem',
                            ssl_enabled: false,
                        },
                    },
                },
                {
                    class_name: 'CmonGaleraHost',
                    cdt_path: '/CHQ-MYSQL1-PROD',
                    owner_user_id: 1,
                    owner_user_name: 'system',
                    owner_group_id: 1,
                    owner_group_name: 'admins',
                    acl: 'user::rwx,group::rwx,other::---',
                    clusterid: 10,
                    configfile: ['/etc/mysql/my.cnf'],
                    connected: true,
                    container: false,
                    datadir: '/var/lib/mysql/',
                    description: '',
                    errorcode: 0,
                    errormsg: 'Up and running.',
                    hostId: 95,
                    hostname: 'chqmysql2prod2',
                    hostname_internal: '10.202.42.22',
                    hoststatus: 'CmonHostOnline',
                    ip: '10.202.42.22',
                    isgalera: true,
                    lastseen: 1679940613,
                    log_bin: 'ON',
                    log_bin_basename: '/var/lib/mysql/binlog',
                    logfile: '/var/log/mysql/mysqld.log',
                    maintenance_mode: 'none',
                    maintenance_mode_active: false,
                    message: 'Up and running.',
                    mysqlstatus: 0,
                    nodeid: 95,
                    nodetype: 'galera',
                    performance_schema: true,
                    pid: 2830216,
                    pidfile: '/var/lib/mysql/mysql.pid',
                    pingstatus: 1,
                    pingtime: 544,
                    port: 3306,
                    readonly: false,
                    role: 'master',
                    serverid: 10000,
                    skip_name_resolve: false,
                    slaves: [],
                    socket: '/var/lib/mysql/mysql.sock',
                    sshfailcount: 0,
                    statereason:
                        'Setting to MYSQL_OK because the node is Synced',
                    status: 0,
                    stopped_at: '2022-10-04T04:11:52.762Z',
                    super_read_only: false,
                    timestamp: 1679940613,
                    unique_id: 95,
                    uptime: 15083848,
                    version: '5.7.37-40-57-log',
                    version_comment:
                        'Percona XtraDB Cluster (GPL), Release rel40, Revision ce3b993, WSREP version 31.57, wsrep_31.57',
                    wallclock: 1679940595,
                    wallclocktimestamp: 1679940595,
                    audit_log: {
                        plugins: [],
                    },
                    distribution: {
                        name: 'ubuntu',
                        codename: 'focal',
                        release: '20.04',
                        type: 'debian',
                    },
                    galera: {
                        certsdepsdistance: 48.0014,
                        cluster_address:
                            'gcomm://10.202.42.21,10.202.42.22,10.202.42.23',
                        clustername: 'CHQ-MYSQL1-PROD',
                        clustersize: 3,
                        connected: true,
                        flowctrlpaused: 0,
                        flowctrlrecv: 0,
                        flowctrlsent: 0,
                        galerastatus: 'Primary',
                        gcache_recover: false,
                        lastcommitted: 247472049,
                        localrecvqueue: 0,
                        localrecvqueueavg: 0.009804,
                        localsendqueue: 0,
                        localsendqueueavg: 0,
                        localstatus: 4,
                        localstatusstr: 'Synced',
                        provider: '/usr/lib/libgalera_smm.so',
                        ready: 'ON',
                        segmentid: 0,
                        socketssl: false,
                        sst_method: 'xtrabackup-v2',
                    },
                    replication_master: {
                        binlog_do_db: '',
                        binlog_ignore_db: '',
                        exec_gtid:
                            '2f70a3a1-ed92-ee13-5337-cd9fb2cbd8a0:1-113,833aceaa-fce5-ee16-5c99-55a3814928da:1-81296660,bf245890-0bc5-ee13-7bdb-651cff5f7712:1-13955,edeed2da-bb94-ee12-7358-112b4d9bfcb2:1',
                        file: 'binlog.000077',
                        position: '1029933929',
                        semisync_status: 'OFF',
                    },
                    replication_slave: {
                        exec_master_log_pos: 22504783,
                        executed_gtid_set: '',
                        gtid_binlog_pos: '',
                        gtid_io_pos: '',
                        last_io_errno: '',
                        last_io_error: '',
                        linkstatus: 7,
                        master_cluster_id: '-1',
                        master_host: '',
                        master_log_file: '',
                        master_port: '',
                        read_master_log_pos: '0',
                        relay_master_log_file: '',
                        retrieved_gtid_set: '',
                        seconds_behind_master: '',
                        semisync_status: 'OFF',
                        slave_io_running: '',
                        slave_io_state: '',
                        slave_sql_running: '',
                        slave_sql_state: '',
                        status: '',
                        using_gtid: '',
                    },
                    slow_query: {
                        log_not_using_idxs: 'ON',
                        long_query_time: '0.500000',
                        slow_query_log: 'OFF',
                        slow_query_log_file: '/var/log/mysql/mysql-slow.log',
                    },
                    ssl_certs: {
                        replication: {
                            ca: '',
                            id: 0,
                            key: '',
                            path: '',
                        },
                        server: {
                            ca: '/var/lib/mysql/ca.pem',
                            id: 0,
                            key: '/var/lib/mysql/server-key.pem',
                            path: '/var/lib/mysql/server-cert.pem',
                            ssl_enabled: false,
                        },
                    },
                },
                {
                    class_name: 'CmonGaleraHost',
                    cdt_path: '/CHQ-MYSQL1-PROD',
                    owner_user_id: 1,
                    owner_user_name: 'system',
                    owner_group_id: 1,
                    owner_group_name: 'admins',
                    acl: 'user::rwx,group::rwx,other::---',
                    clusterid: 10,
                    configfile: ['/etc/mysql/my.cnf'],
                    connected: true,
                    container: false,
                    datadir: '/var/lib/mysql/',
                    description: '',
                    errorcode: 0,
                    errormsg: 'Up and running.',
                    hostId: 97,
                    hostname: 'chqmysql2prod3',
                    hostname_internal: '10.202.42.23',
                    hoststatus: 'CmonHostOnline',
                    ip: '10.202.42.23',
                    isgalera: true,
                    lastseen: 1679940613,
                    log_bin: 'ON',
                    log_bin_basename: '/var/lib/mysql/binlog',
                    logfile: '/var/log/mysql/mysqld.log',
                    maintenance_mode: 'none',
                    maintenance_mode_active: false,
                    message: 'Up and running.',
                    mysqlstatus: 0,
                    nodeid: 97,
                    nodetype: 'galera',
                    performance_schema: true,
                    pid: 2697265,
                    pidfile: '/var/lib/mysql/mysql.pid',
                    pingstatus: 1,
                    pingtime: 538,
                    port: 3306,
                    readonly: false,
                    role: 'master',
                    serverid: 10000,
                    skip_name_resolve: false,
                    slaves: [],
                    socket: '/var/lib/mysql/mysql.sock',
                    sshfailcount: 0,
                    statereason:
                        'Setting to MYSQL_OK because the node is Synced',
                    status: 0,
                    stopped_at: '2022-10-04T04:26:35.547Z',
                    super_read_only: false,
                    timestamp: 1679940613,
                    unique_id: 97,
                    uptime: 15082967,
                    version: '5.7.37-40-57-log',
                    version_comment:
                        'Percona XtraDB Cluster (GPL), Release rel40, Revision ce3b993, WSREP version 31.57, wsrep_31.57',
                    wallclock: 1679940595,
                    wallclocktimestamp: 1679940595,
                    audit_log: {
                        plugins: [],
                    },
                    distribution: {
                        name: 'ubuntu',
                        codename: 'focal',
                        release: '20.04',
                        type: 'debian',
                    },
                    galera: {
                        certsdepsdistance: 48.0014,
                        cluster_address:
                            'gcomm://10.202.42.21,10.202.42.22,10.202.42.23',
                        clustername: 'CHQ-MYSQL1-PROD',
                        clustersize: 3,
                        connected: true,
                        flowctrlpaused: 0,
                        flowctrlrecv: 0,
                        flowctrlsent: 0,
                        galerastatus: 'Primary',
                        gcache_recover: false,
                        lastcommitted: 247472049,
                        localrecvqueue: 0,
                        localrecvqueueavg: 0.009615,
                        localsendqueue: 0,
                        localsendqueueavg: 0,
                        localstatus: 4,
                        localstatusstr: 'Synced',
                        provider: '/usr/lib/libgalera_smm.so',
                        ready: 'ON',
                        segmentid: 0,
                        socketssl: false,
                        sst_method: 'xtrabackup-v2',
                    },
                    replication_master: {
                        binlog_do_db: '',
                        binlog_ignore_db: '',
                        exec_gtid:
                            '2f70a3a1-ed92-ee13-5337-cd9fb2cbd8a0:1-113,833aceaa-fce5-ee16-5c99-55a3814928da:1-81296662,bf245890-0bc5-ee13-7bdb-651cff5f7712:1-13955,edeed2da-bb94-ee12-7358-112b4d9bfcb2:1',
                        file: 'binlog.000078',
                        position: '1022168800',
                        semisync_status: 'OFF',
                    },
                    replication_slave: {
                        exec_master_log_pos: 22504783,
                        executed_gtid_set: '',
                        gtid_binlog_pos: '',
                        gtid_io_pos: '',
                        last_io_errno: '',
                        last_io_error: '',
                        linkstatus: 7,
                        master_cluster_id: '-1',
                        master_host: '',
                        master_log_file: '',
                        master_port: '',
                        read_master_log_pos: '0',
                        relay_master_log_file: '',
                        retrieved_gtid_set: '',
                        seconds_behind_master: '',
                        semisync_status: 'OFF',
                        slave_io_running: '',
                        slave_io_state: '',
                        slave_sql_running: '',
                        slave_sql_state: '',
                        status: '',
                        using_gtid: '',
                    },
                    slow_query: {
                        log_not_using_idxs: 'ON',
                        long_query_time: '0.500000',
                        slow_query_log: 'OFF',
                        slow_query_log_file: '/var/log/mysql/mysql-slow.log',
                    },
                    ssl_certs: {
                        replication: {
                            ca: '',
                            id: 0,
                            key: '',
                            path: '',
                        },
                        server: {
                            ca: '/var/lib/mysql/ca.pem',
                            id: 0,
                            key: '/var/lib/mysql/server-key.pem',
                            path: '/var/lib/mysql/server-cert.pem',
                            ssl_enabled: false,
                        },
                    },
                },
            ],
            job_statistics: {
                class_name: 'CmonJobStatistics',
                cluster_id: 10,
                by_state: {
                    ABORTED: 1,
                    DEFINED: 0,
                    DEQUEUED: 0,
                    FAILED: 0,
                    FINISHED: 175,
                    RUNNING: 0,
                    SCHEDULED: 2,
                },
            },
            owner: {
                class_name: 'CmonUser',
                cdt_path: '/',
                owner_user_id: 1,
                owner_user_name: 'system',
                owner_group_id: 1,
                owner_group_name: 'admins',
                acl: 'user::rwx,group::r--,other::r--',
                created: '2018-12-13T23:26:18.826Z',
                disabled: false,
                first_name: 'System',
                last_failed_login: '',
                last_login: '2023-03-27T18:10:11.706Z',
                last_name: 'User',
                n_failed_logins: 0,
                origin: 'CmonDb',
                password_encrypted:
                    '43030e3067de2de956046fcc97ec13337b5ec2f51efbc66ebfe88f913d4339c4',
                password_format: 'sha256',
                password_salt: '75b76a66-f0ee-4cf6-8f28-480ed0d4809a',
                suspended: false,
                user_id: 1,
                user_name: 'system',
                groups: [
                    {
                        class_name: 'CmonGroup',
                        cdt_path: '/groups',
                        owner_user_id: 1,
                        owner_user_name: 'system',
                        owner_group_id: 1,
                        owner_group_name: 'admins',
                        group_id: 1,
                        group_name: 'admins',
                    },
                ],
                timezone: {
                    class_name: 'CmonTimeZone',
                    name: 'Pacific Standard Time',
                    abbreviation: 'PST',
                    offset: 28800,
                    use_dst: false,
                },
            },
        },
        {
            class_name: 'CmonClusterInfo',
            cdt_path: '/',
            acl: 'user::rwx,group::rwx,other::---',
            tags: ['PROD', 'RGE', 'REBUILD2'],
            cluster_auto_recovery: true,
            cluster_id: 18,
            cluster_name: 'RGE-MYSQL1-PROD',
            cluster_type: 'GALERA',
            configuration_file: '/etc/cmon.d/cmon_18.cnf',
            effective_privileges: '',
            log_file: '/var/log/cmon_18.log',
            maintenance_mode_active: false,
            managed: true,
            node_auto_recovery: true,
            rpc_version: '1.0',
            state: 'STARTED',
            status_text: 'All nodes are operational.',
            vendor: 'percona',
            version: '5.7',
            alarm_statistics: {
                class_name: 'CmonAlarmStatistics',
                cluster_id: 18,
                created_after: null,
                critical: 0,
                reported_after: null,
                warning: 1,
            },
            group_owner: {
                class_name: 'CmonGroup',
                cdt_path: '/groups',
                owner_user_id: 1,
                owner_user_name: 'system',
                owner_group_id: 1,
                owner_group_name: 'admins',
                acl: 'user::rwx,group::rwx,other::---',
                group_id: 1,
                group_name: 'admins',
            },
            hosts: [
                {
                    class_name: 'CmonGaleraHost',
                    cdt_path: '/RGE-MYSQL1-PROD',
                    owner_user_id: 1,
                    owner_user_name: 'system',
                    owner_group_id: 1,
                    owner_group_name: 'admins',
                    acl: 'user::rwx,group::rwx,other::---',
                    clusterid: 18,
                    configfile: ['/etc/mysql/my.cnf'],
                    connected: true,
                    container: false,
                    datadir: '/var/lib/mysql/',
                    description: '',
                    errorcode: 0,
                    errormsg: 'Up and running.',
                    hostId: 121,
                    hostname: 'RGEMYSQL1PROD2',
                    hostname_data: 'RGEMYSQL1PROD2',
                    hostname_internal: '10.102.42.21',
                    hoststatus: 'CmonHostOnline',
                    ip: '10.102.42.21',
                    isgalera: true,
                    lastseen: 1679940613,
                    log_bin: 'ON',
                    log_bin_basename: '/var/lib/mysql/binlog',
                    logfile: '/var/log/mysql/mysqld.log',
                    maintenance_mode: 'none',
                    maintenance_mode_active: false,
                    message: 'Up and running.',
                    mysqlstatus: 0,
                    nodeid: 121,
                    nodetype: 'galera',
                    performance_schema: true,
                    pid: 1943512,
                    pidfile: '/var/lib/mysql/mysql.pid',
                    pingstatus: 1,
                    pingtime: 7480,
                    port: '3306',
                    readonly: true,
                    role: 'slave',
                    serverid: 18000,
                    skip_name_resolve: false,
                    slaves: [],
                    socket: '/var/lib/mysql/mysql.sock',
                    sshfailcount: 0,
                    statereason:
                        'Setting to MYSQL_OK because the node is Synced',
                    status: 0,
                    stopped_at: '2022-10-05T05:03:51.489Z',
                    super_read_only: true,
                    timestamp: 1679940613,
                    unique_id: 114,
                    uptime: 14994397,
                    version: '5.7.37-40-57-log',
                    version_comment:
                        'Percona XtraDB Cluster (GPL), Release rel40, Revision ce3b993, WSREP version 31.57, wsrep_31.57',
                    wallclock: 1679940542,
                    wallclocktimestamp: 1679940542,
                    audit_log: {
                        plugins: [],
                    },
                    distribution: {
                        name: 'ubuntu',
                        codename: 'focal',
                        release: '20.04',
                        type: 'debian',
                    },
                    galera: {
                        certsdepsdistance: 42.6707,
                        cluster_address:
                            'gcomm://10.102.42.21,10.102.42.22,RGEMYSQL1PROD4',
                        clustername: 'RGE-MYSQL1-PROD',
                        clustersize: 3,
                        connected: true,
                        flowctrlpaused: 0,
                        flowctrlrecv: 0,
                        flowctrlsent: 0,
                        galerastatus: 'Primary',
                        gcache_recover: false,
                        lastcommitted: 26646390,
                        localrecvqueue: 0,
                        localrecvqueueavg: 0,
                        localsendqueue: 0,
                        localsendqueueavg: 0,
                        localstatus: 4,
                        localstatusstr: 'Synced',
                        provider: '/usr/lib/libgalera_smm.so',
                        ready: 'ON',
                        segmentid: 0,
                        socketssl: true,
                        sst_method: 'xtrabackup-v2',
                    },
                    replication_master: {
                        binlog_do_db: '',
                        binlog_ignore_db: '',
                        exec_gtid:
                            '2f70a3a1-ed92-ee13-5337-cd9fb2cbd8a0:1-113,833aceaa-fce5-ee16-5c99-55a3814928da:1-54705382:54705638-81296657,bf245890-0bc5-ee13-7bdb-651cff5f7712:1-13955,edeed2da-bb94-ee12-7358-112b4d9bfcb2:1',
                        file: 'binlog.000026',
                        position: '780609943',
                        semisync_status: 'OFF',
                    },
                    replication_slave: {
                        exec_master_log_pos: '15059094',
                        executed_gtid_set:
                            '2f70a3a1-ed92-ee13-5337-cd9fb2cbd8a0:1-113,\n833aceaa-fce5-ee16-5c99-55a3814928da:1-54705382:54705638-81296657,\nbf245890-0bc5-ee13-7bdb-651cff5f7712:1-13955,\nedeed2da-bb94-ee12-7358-112b4d9bfcb2:1',
                        gtid_binlog_pos: '',
                        gtid_io_pos: '',
                        last_io_errno: '0',
                        last_io_error: '',
                        last_io_error_timestamp: '',
                        last_sql_errno: '0',
                        last_sql_error: '',
                        last_sql_error_timestamp: '',
                        linkstatus: 1,
                        master_cluster_id: 10,
                        master_host: '10.202.42.21',
                        master_log_file: 'binlog.006845',
                        master_port: '3306',
                        master_server_id: '10000',
                        master_uuid: '74eccc3f-e614-11ec-9f99-0050568a57f7',
                        read_master_log_pos: '15059094',
                        relay_master_log_file: 'binlog.006845',
                        retrieved_gtid_set:
                            '833aceaa-fce5-ee16-5c99-55a3814928da:54705383-81296657',
                        seconds_behind_master: '0',
                        semisync_status: 'OFF',
                        slave_io_running: 'Yes',
                        slave_io_state: 'Waiting for master to send event',
                        slave_sql_running: 'Yes',
                        slave_sql_state:
                            'Slave has read all relay log; waiting for more updates',
                        sqldelay: 0,
                        status:
                            'Waiting for master to send event<br/>Slave has read all relay log; waiting for more updates',
                        using_gtid: 'ON',
                    },
                    slow_query: {
                        log_not_using_idxs: 'OFF',
                        long_query_time: '0.500000',
                        slow_query_log: 'OFF',
                        slow_query_log_file: '/var/log/mysql/mysql-slow.log',
                    },
                    ssl_certs: {
                        replication: {
                            ca: '',
                            id: 20,
                            key: '/etc/mysql/certs/galera_rep.key',
                            path: '/etc/mysql/certs/galera_rep.crt',
                        },
                        server: {
                            ca: '/etc/mysql/certs/server_ca.crt',
                            id: 18,
                            key: '/etc/mysql/certs/server.key',
                            path: '/etc/mysql/certs/server.crt',
                            ssl_enabled: true,
                        },
                    },
                },
                {
                    class_name: 'CmonHost',
                    cdt_path: '/RGE-MYSQL1-PROD',
                    owner_user_id: 1,
                    owner_user_name: 'system',
                    owner_group_id: 1,
                    owner_group_name: 'admins',
                    acl: 'user::rwx,group::rwx,other::---',
                    clusterid: 18,
                    configfile: '/etc/cmon.d/cmon_18.cnf',
                    connected: true,
                    container: false,
                    hostId: 122,
                    hostname: 'CHQMYSQLMGMT',
                    hoststatus: 'CmonHostOnline',
                    ip: '10.200.6.171',
                    lastseen: 1679940587,
                    logfile: '/var/log/cmon_18.log',
                    maintenance_mode: 'none',
                    maintenance_mode_active: false,
                    message: 'Up and running.',
                    nodetype: 'controller',
                    pid: 1774,
                    pingstatus: -1,
                    pingtime: -1,
                    port: 9500,
                    role: 'controller',
                    status: 10,
                    timestamp: 1679940587,
                    unique_id: 115,
                    uptime: 300952,
                    version: '1.9.5.6225',
                    wallclock: 1679940542,
                    wallclocktimestamp: 1679940542,
                    distribution: {
                        name: 'ubuntu',
                        codename: 'bionic',
                        release: '18.04',
                        type: 'debian',
                    },
                },
                {
                    class_name: 'CmonGaleraHost',
                    cdt_path: '/RGE-MYSQL1-PROD',
                    owner_user_id: 1,
                    owner_user_name: 'system',
                    owner_group_id: 1,
                    owner_group_name: 'admins',
                    acl: 'user::rwx,group::rwx,other::---',
                    clusterid: 18,
                    configfile: ['/etc/mysql/my.cnf'],
                    connected: true,
                    container: false,
                    datadir: '/var/lib/mysql/',
                    description: '',
                    errorcode: 0,
                    errormsg: 'Up and running.',
                    hostId: 123,
                    hostname: 'RGEMYSQL1PROD3',
                    hostname_internal: '10.102.42.22',
                    hoststatus: 'CmonHostOnline',
                    ip: '10.102.42.22',
                    isgalera: true,
                    lastseen: 1679940613,
                    log_bin: 'ON',
                    log_bin_basename: '/var/lib/mysql/binlog',
                    logfile: '/var/log/mysql/mysqld.log',
                    maintenance_mode: 'none',
                    maintenance_mode_active: false,
                    message: 'Up and running.',
                    mysqlstatus: 0,
                    nodeid: 123,
                    nodetype: 'galera',
                    performance_schema: true,
                    pid: 2358810,
                    pidfile: '/var/lib/mysql/mysql.pid',
                    pingstatus: 1,
                    pingtime: 6914,
                    port: 3306,
                    readonly: true,
                    role: 'master',
                    serverid: 18000,
                    skip_name_resolve: false,
                    slaves: [],
                    socket: '/var/lib/mysql/mysql.sock',
                    sshfailcount: 0,
                    statereason:
                        'Setting to MYSQL_OK because the node is Synced',
                    status: 0,
                    stopped_at: '2022-10-05T05:15:29.606Z',
                    super_read_only: true,
                    timestamp: 1679940613,
                    unique_id: 116,
                    uptime: 14994010,
                    version: '5.7.37-40-57-log',
                    version_comment:
                        'Percona XtraDB Cluster (GPL), Release rel40, Revision ce3b993, WSREP version 31.57, wsrep_31.57',
                    wallclock: 1679940542,
                    wallclocktimestamp: 1679940542,
                    audit_log: {
                        plugins: [],
                    },
                    distribution: {
                        name: 'ubuntu',
                        codename: 'focal',
                        release: '20.04',
                        type: 'debian',
                    },
                    galera: {
                        certsdepsdistance: 42.7275,
                        cluster_address:
                            'gcomm://10.102.42.21,10.102.42.22,RGEMYSQL1PROD4',
                        clustername: 'RGE-MYSQL1-PROD',
                        clustersize: 3,
                        connected: true,
                        flowctrlpaused: 0,
                        flowctrlrecv: 0,
                        flowctrlsent: 0,
                        galerastatus: 'Primary',
                        gcache_recover: false,
                        lastcommitted: 26646390,
                        localrecvqueue: 0,
                        localrecvqueueavg: 0,
                        localsendqueue: 0,
                        localsendqueueavg: 0,
                        localstatus: 4,
                        localstatusstr: 'Synced',
                        provider: '/usr/lib/libgalera_smm.so',
                        ready: 'ON',
                        segmentid: 0,
                        socketssl: true,
                        sst_method: 'xtrabackup-v2',
                    },
                    replication_master: {
                        binlog_do_db: '',
                        binlog_ignore_db: '',
                        exec_gtid:
                            '2f70a3a1-ed92-ee13-5337-cd9fb2cbd8a0:1-113,833aceaa-fce5-ee16-5c99-55a3814928da:1-54695031:54705383-81296657,bf245890-0bc5-ee13-7bdb-651cff5f7712:1-13955,edeed2da-bb94-ee12-7358-112b4d9bfcb2:1',
                        file: 'binlog.000030',
                        position: '780609577',
                        semisync_status: 'OFF',
                    },
                    replication_slave: {
                        exec_master_log_pos: 22504783,
                        executed_gtid_set: '',
                        gtid_binlog_pos: '',
                        gtid_io_pos: '',
                        last_io_errno: '',
                        last_io_error: '',
                        linkstatus: 7,
                        master_cluster_id: '-1',
                        master_host: '',
                        master_log_file: '',
                        master_port: '',
                        read_master_log_pos: '0',
                        relay_master_log_file: '',
                        retrieved_gtid_set: '',
                        seconds_behind_master: '',
                        semisync_status: 'OFF',
                        slave_io_running: '',
                        slave_io_state: '',
                        slave_sql_running: '',
                        slave_sql_state: '',
                        status: '',
                        using_gtid: '',
                    },
                    slow_query: {
                        log_not_using_idxs: 'OFF',
                        long_query_time: '0.500000',
                        slow_query_log: 'OFF',
                        slow_query_log_file: '/var/log/mysql/mysql-slow.log',
                    },
                    ssl_certs: {
                        replication: {
                            ca: '',
                            id: 20,
                            key: '/etc/mysql/certs/galera_rep.key',
                            path: '/etc/mysql/certs/galera_rep.crt',
                        },
                        server: {
                            ca: '/etc/mysql/certs/server_ca.crt',
                            id: 18,
                            key: '/etc/mysql/certs/server.key',
                            path: '/etc/mysql/certs/server.crt',
                            ssl_enabled: true,
                        },
                    },
                },
                {
                    class_name: 'CmonGaleraHost',
                    cdt_path: '/RGE-MYSQL1-PROD',
                    owner_user_id: 1,
                    owner_user_name: 'system',
                    owner_group_id: 1,
                    owner_group_name: 'admins',
                    acl: 'user::rwx,group::rwx,other::---',
                    clusterid: 18,
                    configfile: ['/etc/mysql/my.cnf'],
                    connected: true,
                    container: false,
                    datadir: '/var/lib/mysql/',
                    description: '',
                    errorcode: 0,
                    errormsg: 'Up and running.',
                    hostId: 124,
                    hostname: 'RGEMYSQL1PROD4',
                    hostname_internal: '10.102.42.23',
                    hoststatus: 'CmonHostOnline',
                    ip: '10.102.42.23',
                    isgalera: true,
                    lastseen: 1679940613,
                    log_bin: 'ON',
                    log_bin_basename: '/var/lib/mysql/binlog',
                    logfile: '/var/log/mysql/mysqld.log',
                    maintenance_mode: 'none',
                    maintenance_mode_active: false,
                    message: 'Up and running.',
                    mysqlstatus: 0,
                    nodeid: 124,
                    nodetype: 'galera',
                    performance_schema: true,
                    pid: 2430551,
                    pidfile: '/var/lib/mysql/mysql.pid',
                    pingstatus: 1,
                    pingtime: 7416,
                    port: 3306,
                    readonly: true,
                    role: 'master',
                    serverid: 18000,
                    skip_name_resolve: false,
                    slaves: [],
                    socket: '/var/lib/mysql/mysql.sock',
                    sshfailcount: 0,
                    statereason:
                        'Setting to MYSQL_OK because the node is Synced',
                    status: 0,
                    stopped_at: '2022-10-05T05:21:43.676Z',
                    super_read_only: true,
                    timestamp: 1679940613,
                    unique_id: 117,
                    uptime: 14993627,
                    version: '5.7.37-40-57-log',
                    version_comment:
                        'Percona XtraDB Cluster (GPL), Release rel40, Revision ce3b993, WSREP version 31.57, wsrep_31.57',
                    wallclock: 1679940542,
                    wallclocktimestamp: 1679940542,
                    audit_log: {
                        plugins: [],
                    },
                    distribution: {
                        name: 'ubuntu',
                        codename: 'focal',
                        release: '20.04',
                        type: 'debian',
                    },
                    galera: {
                        certsdepsdistance: 42.6707,
                        cluster_address:
                            'gcomm://10.102.42.21,10.102.42.22,RGEMYSQL1PROD4',
                        clustername: 'RGE-MYSQL1-PROD',
                        clustersize: 3,
                        connected: true,
                        flowctrlpaused: 0,
                        flowctrlrecv: 0,
                        flowctrlsent: 0,
                        galerastatus: 'Primary',
                        gcache_recover: false,
                        lastcommitted: 26646390,
                        localrecvqueue: 0,
                        localrecvqueueavg: 0.002656,
                        localsendqueue: 0,
                        localsendqueueavg: 0,
                        localstatus: 4,
                        localstatusstr: 'Synced',
                        provider: '/usr/lib/libgalera_smm.so',
                        ready: 'ON',
                        segmentid: 0,
                        socketssl: true,
                        sst_method: 'xtrabackup-v2',
                    },
                    replication_master: {
                        binlog_do_db: '',
                        binlog_ignore_db: '',
                        exec_gtid:
                            '2f70a3a1-ed92-ee13-5337-cd9fb2cbd8a0:1-113,833aceaa-fce5-ee16-5c99-55a3814928da:1-54695031:54705383-81296657,bf245890-0bc5-ee13-7bdb-651cff5f7712:1-13955,edeed2da-bb94-ee12-7358-112b4d9bfcb2:1',
                        file: 'binlog.000031',
                        position: '780609943',
                        semisync_status: 'OFF',
                    },
                    replication_slave: {
                        exec_master_log_pos: 22504783,
                        executed_gtid_set: '',
                        gtid_binlog_pos: '',
                        gtid_io_pos: '',
                        last_io_errno: '',
                        last_io_error: '',
                        linkstatus: 7,
                        master_cluster_id: '-1',
                        master_host: '',
                        master_log_file: '',
                        master_port: '',
                        read_master_log_pos: '0',
                        relay_master_log_file: '',
                        retrieved_gtid_set: '',
                        seconds_behind_master: '',
                        semisync_status: 'OFF',
                        slave_io_running: '',
                        slave_io_state: '',
                        slave_sql_running: '',
                        slave_sql_state: '',
                        status: '',
                        using_gtid: '',
                    },
                    slow_query: {
                        log_not_using_idxs: 'OFF',
                        long_query_time: '0.500000',
                        slow_query_log: 'OFF',
                        slow_query_log_file: '/var/log/mysql/mysql-slow.log',
                    },
                    ssl_certs: {
                        replication: {
                            ca: '',
                            id: 20,
                            key: '/etc/mysql/certs/galera_rep.key',
                            path: '/etc/mysql/certs/galera_rep.crt',
                        },
                        server: {
                            ca: '/etc/mysql/certs/server_ca.crt',
                            id: 18,
                            key: '/etc/mysql/certs/server.key',
                            path: '/etc/mysql/certs/server.crt',
                            ssl_enabled: true,
                        },
                    },
                },
                {
                    class_name: 'CmonHaProxyHost',
                    cdt_path: '/RGE-MYSQL1-PROD',
                    acl: 'user::rwx,group::rwx,other::---',
                    backend_name_ro: 'haproxy_rgemysql1halbprod1_3308_ro',
                    backend_name_rw: 'haproxy_rgemysql1halbprod1_3307_rw',
                    clusterid: 18,
                    cmdline:
                        '/usr/sbin/haproxy -D -f /etc/haproxy/haproxy.cfg -sf $(cat /var/run/haproxy.pid)',
                    configfile: '/etc/haproxy/haproxy.cfg',
                    connectstring: 'rgemysql1halbprod1:3307',
                    container: false,
                    hostId: 131,
                    hostname: 'rgemysql1halbprod1',
                    hoststatus: 'CmonHostOnline',
                    ip: '10.102.42.31',
                    lastseen: 1679940588,
                    lb_admin: 'admin',
                    lb_name: 'haproxy_rgemysql1halbprod1_3307_rw',
                    lb_password: 'admin',
                    lb_policy: 'leastconn',
                    maintenance_mode: 'none',
                    maintenance_mode_active: false,
                    max_connections_be: 64,
                    max_connections_fe: 8192,
                    message: "Process 'haproxy' is running.",
                    monitor_user: 's9smysqlchk',
                    nodetype: 'haproxy',
                    pid: 3509,
                    pingstatus: 1,
                    pingtime: 7426,
                    port: 9600,
                    ro_port: 3308,
                    rw_port: 3307,
                    socket: '/var/run/haproxy.socket',
                    sshfailcount: 0,
                    statereason: 'CmonProcessManager::haProxyDaemonFound()',
                    stats_socket: '/var/run/haproxy.socket',
                    status: 10,
                    svc_check_script: 'mysqlchk',
                    timeout_client: 10800,
                    timeout_server: 10800,
                    timestamp: 1679940588,
                    unique_id: 128,
                    version: '1.8.9',
                    wallclock: 1679940542,
                    wallclocktimestamp: 1679940542,
                    xinetd_allow_from: '0.0.0.0/0',
                    xinetd_port: 9200,
                    backend_info: {
                        backend_servers: [
                            {
                                name: 'admin_page',
                                last_check_state: '',
                                service: 'FRONTEND',
                                status: 'OPEN',
                            },
                            {
                                name: 'admin_page',
                                last_check_state: '',
                                service: 'BACKEND',
                                status: 'UP',
                            },
                            {
                                name: 'haproxy_rgemysql1halbprod1_3307_rw',
                                last_check_state: '',
                                service: 'FRONTEND',
                                status: 'OPEN',
                            },
                            {
                                name: 'haproxy_rgemysql1halbprod1_3307_rw',
                                last_check_state: 'Service Unavailable',
                                service: 'RGEMYSQL1PROD2',
                                status: 'DOWN',
                            },
                            {
                                name: 'haproxy_rgemysql1halbprod1_3307_rw',
                                last_check_state: 'Service Unavailable',
                                service: 'RGEMYSQL1PROD3',
                                status: 'DOWN',
                            },
                            {
                                name: 'haproxy_rgemysql1halbprod1_3307_rw',
                                last_check_state: 'Service Unavailable',
                                service: 'RGEMYSQL1PROD4',
                                status: 'DOWN',
                            },
                            {
                                name: 'haproxy_rgemysql1halbprod1_3307_rw',
                                last_check_state: '',
                                service: 'BACKEND',
                                status: 'DOWN',
                            },
                        ],
                    },
                    bind_node_address: [],
                    distribution: {
                        name: 'ubuntu',
                        codename: 'bionic',
                        release: '18.04',
                        type: 'debian',
                    },
                    server_info: {
                        nodes: [
                            {
                                hostname: 'RGEMYSQL1PROD2',
                                port: 3306,
                                role: 'active',
                            },
                            {
                                hostname: 'RGEMYSQL1PROD3',
                                port: 3306,
                                role: 'active',
                            },
                            {
                                hostname: 'RGEMYSQL1PROD4',
                                port: 3306,
                                role: 'active',
                            },
                        ],
                    },
                },
                {
                    class_name: 'CmonHaProxyHost',
                    cdt_path: '/RGE-MYSQL1-PROD',
                    acl: 'user::rwx,group::rwx,other::---',
                    backend_name_ro: 'haproxy_rgemysql1halbprod2_3308_ro',
                    backend_name_rw: 'haproxy_rgemysql1halbprod2_3307_rw',
                    clusterid: 18,
                    cmdline:
                        '/usr/sbin/haproxy -D -f /etc/haproxy/haproxy.cfg -sf $(cat /var/run/haproxy.pid)',
                    configfile: '/etc/haproxy/haproxy.cfg',
                    connectstring: 'rgemysql1halbprod2:3307',
                    container: false,
                    hostId: 132,
                    hostname: 'rgemysql1halbprod2',
                    hoststatus: 'CmonHostOnline',
                    ip: '10.102.42.32',
                    lastseen: 1679940588,
                    lb_admin: 'admin',
                    lb_name: 'haproxy_rgemysql1halbprod2_3307_rw',
                    lb_password: 'admin',
                    lb_policy: 'leastconn',
                    maintenance_mode: 'none',
                    maintenance_mode_active: false,
                    max_connections_be: 64,
                    max_connections_fe: 8192,
                    message: "Process 'haproxy' is running.",
                    monitor_user: 's9smysqlchk',
                    nodetype: 'haproxy',
                    pid: 3596,
                    pingstatus: 1,
                    pingtime: 7417,
                    port: 9600,
                    ro_port: 3308,
                    rw_port: 3307,
                    socket: '/var/run/haproxy.socket',
                    sshfailcount: 0,
                    statereason: 'CmonProcessManager::haProxyDaemonFound()',
                    stats_socket: '/var/run/haproxy.socket',
                    status: 10,
                    svc_check_script: 'mysqlchk',
                    timeout_client: 10800,
                    timeout_server: 10800,
                    timestamp: 1679940588,
                    unique_id: 129,
                    version: '1.8.9',
                    wallclock: 1679940542,
                    wallclocktimestamp: 1679940542,
                    xinetd_allow_from: '0.0.0.0/0',
                    xinetd_port: 9200,
                    backend_info: {
                        backend_servers: [
                            {
                                name: 'admin_page',
                                last_check_state: '',
                                service: 'FRONTEND',
                                status: 'OPEN',
                            },
                            {
                                name: 'admin_page',
                                last_check_state: '',
                                service: 'BACKEND',
                                status: 'UP',
                            },
                            {
                                name: 'haproxy_rgemysql1halbprod2_3307_rw',
                                last_check_state: '',
                                service: 'FRONTEND',
                                status: 'OPEN',
                            },
                            {
                                name: 'haproxy_rgemysql1halbprod2_3307_rw',
                                last_check_state: 'Service Unavailable',
                                service: 'RGEMYSQL1PROD2',
                                status: 'DOWN',
                            },
                            {
                                name: 'haproxy_rgemysql1halbprod2_3307_rw',
                                last_check_state: 'Service Unavailable',
                                service: 'RGEMYSQL1PROD3',
                                status: 'DOWN',
                            },
                            {
                                name: 'haproxy_rgemysql1halbprod2_3307_rw',
                                last_check_state: 'Service Unavailable',
                                service: 'RGEMYSQL1PROD4',
                                status: 'DOWN',
                            },
                            {
                                name: 'haproxy_rgemysql1halbprod2_3307_rw',
                                last_check_state: '',
                                service: 'BACKEND',
                                status: 'DOWN',
                            },
                        ],
                    },
                    bind_node_address: [],
                    distribution: {
                        name: 'ubuntu',
                        codename: 'bionic',
                        release: '18.04',
                        type: 'debian',
                    },
                    server_info: {
                        nodes: [
                            {
                                hostname: 'RGEMYSQL1PROD2',
                                port: 3306,
                                role: 'active',
                            },
                            {
                                hostname: 'RGEMYSQL1PROD3',
                                port: 3306,
                                role: 'active',
                            },
                            {
                                hostname: 'RGEMYSQL1PROD4',
                                port: 3306,
                                role: 'active',
                            },
                        ],
                    },
                },
                {
                    class_name: 'CmonKeepalivedHost',
                    name: 'VI_HAPROXY',
                    cdt_path: '/RGE-MYSQL1-PROD',
                    owner_user_id: 1,
                    owner_user_name: 'system',
                    owner_group_id: 1,
                    owner_group_name: 'admins',
                    acl: 'user::rwx,group::rwx,other::---',
                    clusterid: 18,
                    cmdline: '/usr/sbin/keepalived',
                    configfile: '/etc/keepalived/keepalived.conf',
                    container: false,
                    hostId: 131,
                    hostname: 'rgemysql1halbprod1',
                    hoststatus: 'CmonHostOnline',
                    ip: '10.102.42.31',
                    lastseen: 1679940588,
                    maintenance_mode: 'none',
                    maintenance_mode_active: false,
                    message: "Process 'keepalived' is running.",
                    nic: 'ens160',
                    nodetype: 'keepalived',
                    pid: 1324,
                    pingstatus: 1,
                    pingtime: 7426,
                    port: 112,
                    role: 'master',
                    sshfailcount: 0,
                    statereason: 'The keepalived process found.',
                    status: 10,
                    timestamp: 1679940588,
                    unique_id: 130,
                    version: '1.3',
                    virtualip: '10.102.42.30',
                    wallclock: 1679940542,
                    wallclocktimestamp: 1679940542,
                    backend_info: {
                        backend_servers: [
                            {
                                hostname: 'rgemysql1halbprod1',
                                hoststatus: 'CmonHostOnline',
                                nodetype: 'haproxy',
                                port: 9600,
                            },
                            {
                                hostname: 'rgemysql1halbprod2',
                                hoststatus: 'CmonHostOnline',
                                nodetype: 'haproxy',
                                port: 9600,
                            },
                        ],
                    },
                    distribution: {
                        name: 'ubuntu',
                        codename: 'bionic',
                        release: '18.04',
                        type: 'debian',
                    },
                },
                {
                    class_name: 'CmonKeepalivedHost',
                    name: 'VI_HAPROXY',
                    cdt_path: '/RGE-MYSQL1-PROD',
                    owner_user_id: 1,
                    owner_user_name: 'system',
                    owner_group_id: 1,
                    owner_group_name: 'admins',
                    acl: 'user::rwx,group::rwx,other::---',
                    clusterid: 18,
                    cmdline: '/usr/sbin/keepalived',
                    configfile: '/etc/keepalived/keepalived.conf',
                    container: false,
                    hostId: 132,
                    hostname: 'rgemysql1halbprod2',
                    hoststatus: 'CmonHostOnline',
                    ip: '10.102.42.32',
                    lastseen: 1679940588,
                    maintenance_mode: 'none',
                    maintenance_mode_active: false,
                    message: "Process 'keepalived' is running.",
                    nic: 'ens160',
                    nodetype: 'keepalived',
                    pid: 1340,
                    pingstatus: 1,
                    pingtime: 7417,
                    port: 112,
                    role: 'backup',
                    sshfailcount: 0,
                    statereason: 'The keepalived process found.',
                    status: 10,
                    timestamp: 1679940588,
                    unique_id: 131,
                    version: '1.3',
                    virtualip: '10.102.42.30',
                    wallclock: 1679940542,
                    wallclocktimestamp: 1679940542,
                    backend_info: {
                        backend_servers: [
                            {
                                hostname: 'rgemysql1halbprod1',
                                hoststatus: 'CmonHostOnline',
                                nodetype: 'haproxy',
                                port: 9600,
                            },
                            {
                                hostname: 'rgemysql1halbprod2',
                                hoststatus: 'CmonHostOnline',
                                nodetype: 'haproxy',
                                port: 9600,
                            },
                        ],
                    },
                    distribution: {
                        name: 'ubuntu',
                        codename: 'bionic',
                        release: '18.04',
                        type: 'debian',
                    },
                },
            ],
            job_statistics: {
                class_name: 'CmonJobStatistics',
                cluster_id: 18,
                by_state: {
                    ABORTED: 0,
                    DEFINED: 0,
                    DEQUEUED: 0,
                    FAILED: 0,
                    FINISHED: 9,
                    RUNNING: 0,
                },
            },
            owner: {
                class_name: 'CmonUser',
                cdt_path: '/',
                owner_user_id: 1,
                owner_user_name: 'system',
                owner_group_id: 1,
                owner_group_name: 'admins',
                acl: 'user::rwx,group::r--,other::r--',
                created: '2018-12-13T23:26:18.826Z',
                disabled: false,
                first_name: 'System',
                last_failed_login: '',
                last_login: '2023-03-27T18:10:11.706Z',
                last_name: 'User',
                n_failed_logins: 0,
                origin: 'CmonDb',
                password_encrypted:
                    '43030e3067de2de956046fcc97ec13337b5ec2f51efbc66ebfe88f913d4339c4',
                password_format: 'sha256',
                password_salt: '75b76a66-f0ee-4cf6-8f28-480ed0d4809a',
                suspended: false,
                user_id: 1,
                user_name: 'system',
                groups: [
                    {
                        class_name: 'CmonGroup',
                        cdt_path: '/groups',
                        owner_user_id: 1,
                        owner_user_name: 'system',
                        owner_group_id: 1,
                        owner_group_name: 'admins',
                        group_id: 1,
                        group_name: 'admins',
                    },
                ],
                timezone: {
                    class_name: 'CmonTimeZone',
                    name: 'Pacific Standard Time',
                    abbreviation: 'PST',
                    offset: 28800,
                    use_dst: false,
                },
            },
        },
        {
            class_name: 'CmonClusterInfo',
            cdt_path: '/',
            acl: 'user::rwx,group::rwx,other::---',
            tags: ['RGE', 'QA', 'REBUILT', 'REPLICA'],
            cluster_auto_recovery: true,
            cluster_id: 24,
            cluster_name: 'RGE-MYSQL1-QA',
            cluster_type: 'GALERA',
            configuration_file: '/etc/cmon.d/cmon_24.cnf',
            effective_privileges: '',
            log_file: '/var/log/cmon_24.log',
            maintenance_mode_active: false,
            managed: true,
            node_auto_recovery: true,
            rpc_version: '1.0',
            state: 'STARTED',
            status_text: 'All nodes are operational.',
            vendor: 'percona',
            version: '5.7',
            alarm_statistics: {
                class_name: 'CmonAlarmStatistics',
                cluster_id: 24,
                created_after: null,
                critical: 0,
                reported_after: null,
                warning: 1,
            },
            group_owner: {
                class_name: 'CmonGroup',
                cdt_path: '/groups',
                owner_user_id: 1,
                owner_user_name: 'system',
                owner_group_id: 1,
                owner_group_name: 'admins',
                acl: 'user::rwx,group::rwx,other::---',
                group_id: 1,
                group_name: 'admins',
            },
            hosts: [
                {
                    class_name: 'CmonGaleraHost',
                    cdt_path: '/RGE-MYSQL1-QA',
                    owner_user_id: 1,
                    owner_user_name: 'system',
                    owner_group_id: 1,
                    owner_group_name: 'admins',
                    acl: 'user::rwx,group::rwx,other::---',
                    clusterid: 24,
                    configfile: ['/etc/mysql/my.cnf'],
                    connected: true,
                    container: false,
                    datadir: '/var/lib/mysql/',
                    description: '',
                    errorcode: 0,
                    errormsg: 'Up and running.',
                    hostId: 148,
                    hostname: 'RGEMYSQL1QA1',
                    hostname_data: 'RGEMYSQL1QA1',
                    hostname_internal: '10.102.42.41',
                    hoststatus: 'CmonHostOnline',
                    ip: '10.102.42.41',
                    isgalera: true,
                    lastseen: 1679940613,
                    log_bin: 'ON',
                    log_bin_basename: '/var/lib/mysql/binlog',
                    logfile: '/var/log/mysql/mysqld.log',
                    maintenance_mode: 'none',
                    maintenance_mode_active: false,
                    message: 'Up and running.',
                    mysqlstatus: 0,
                    nodeid: 148,
                    nodetype: 'galera',
                    performance_schema: true,
                    pid: 2538,
                    pidfile: '/var/lib/mysql/mysql.pid',
                    pingstatus: 1,
                    pingtime: 7400,
                    port: '3306',
                    readonly: true,
                    role: 'slave',
                    serverid: 24000,
                    skip_name_resolve: false,
                    slaves: [],
                    socket: '/var/lib/mysql/mysql.sock',
                    sshfailcount: 0,
                    statereason:
                        'Setting to MYSQL_OK because the node is Synced',
                    status: 0,
                    stopped_at: '2023-01-08T04:20:49.058Z',
                    super_read_only: true,
                    timestamp: 1679940613,
                    unique_id: 142,
                    uptime: 1497931,
                    version: '5.7.40-43-57-log',
                    version_comment:
                        'Percona XtraDB Cluster (GPL), Release rel43, Revision ab4d0bd, WSREP version 31.63, wsrep_31.63',
                    wallclock: 1679940592,
                    wallclocktimestamp: 1679940592,
                    audit_log: {
                        plugins: [],
                    },
                    distribution: {
                        name: 'ubuntu',
                        codename: 'focal',
                        release: '20.04',
                        type: 'debian',
                    },
                    galera: {
                        certsdepsdistance: 0,
                        cluster_address:
                            'gcomm://10.102.42.41,10.102.42.42,RGEMYSQL1QA3',
                        clustername: 'RGE-MYSQL1-QA',
                        clustersize: 3,
                        connected: true,
                        flowctrlpaused: 0,
                        flowctrlrecv: 0,
                        flowctrlsent: 0,
                        galerastatus: 'Primary',
                        gcache_recover: false,
                        lastcommitted: 37444,
                        localrecvqueue: 0,
                        localrecvqueueavg: 0,
                        localsendqueue: 0,
                        localsendqueueavg: 0,
                        localstatus: 4,
                        localstatusstr: 'Synced',
                        provider: '/usr/lib/libgalera_smm.so',
                        ready: 'ON',
                        segmentid: 0,
                        socketssl: false,
                        sst_method: 'xtrabackup-v2',
                    },
                    replication_master: {
                        binlog_do_db: '',
                        binlog_ignore_db: '',
                        exec_gtid:
                            '33e7fe75-70f4-ee12-6a4f-9cf72ef12316:1-964,93474419-5dff-ee13-6239-1570910ada2a:1-82106,b0c54df4-78fb-ee12-4c66-48f0684bbc3e:1-206,cec224fc-8c46-ee12-66bf-b8d36628ae64:1-16662,dc671b22-8e34-ee12-5362-29e279c97227:1-1957,e6170f58-9aeb-ee12-426a-f15e9b96af2a:1-53618:53760-55072',
                        file: 'binlog.000005',
                        position: '828750',
                        semisync_status: 'OFF',
                    },
                    replication_slave: {
                        exec_master_log_pos: '664545',
                        executed_gtid_set:
                            '33e7fe75-70f4-ee12-6a4f-9cf72ef12316:1-964,\n93474419-5dff-ee13-6239-1570910ada2a:1-82106,\nb0c54df4-78fb-ee12-4c66-48f0684bbc3e:1-206,\ncec224fc-8c46-ee12-66bf-b8d36628ae64:1-16662,\ndc671b22-8e34-ee12-5362-29e279c97227:1-1957,\ne6170f58-9aeb-ee12-426a-f15e9b96af2a:1-53618:53760-55072',
                        gtid_binlog_pos: '',
                        gtid_io_pos: '',
                        last_io_errno: '0',
                        last_io_error: '',
                        last_io_error_timestamp: '',
                        last_sql_errno: '0',
                        last_sql_error: '',
                        last_sql_error_timestamp: '',
                        linkstatus: 1,
                        master_cluster_id: 25,
                        master_host: '10.202.42.41',
                        master_log_file: 'binlog.000022',
                        master_port: '3306',
                        master_server_id: '25000',
                        master_uuid: 'df1edc04-753c-11ed-8e11-0050568af5d5',
                        read_master_log_pos: '664545',
                        relay_master_log_file: 'binlog.000022',
                        retrieved_gtid_set:
                            'cec224fc-8c46-ee12-66bf-b8d36628ae64:14575-16662',
                        seconds_behind_master: '0',
                        semisync_status: 'OFF',
                        slave_io_running: 'Yes',
                        slave_io_state: 'Waiting for master to send event',
                        slave_sql_running: 'Yes',
                        slave_sql_state:
                            'Slave has read all relay log; waiting for more updates',
                        sqldelay: 0,
                        status:
                            'Waiting for master to send event<br/>Slave has read all relay log; waiting for more updates',
                        using_gtid: 'ON',
                    },
                    slow_query: {
                        log_not_using_idxs: 'OFF',
                        long_query_time: '0.500000',
                        slow_query_log: 'OFF',
                        slow_query_log_file: '/var/log/mysql/mysql-slow.log',
                    },
                    ssl_certs: {
                        replication: {
                            ca: '',
                            id: 0,
                            key: '',
                            path: '',
                        },
                        server: {
                            ca: '/var/lib/mysql/ca.pem',
                            id: 0,
                            key: '/var/lib/mysql/server-key.pem',
                            path: '/var/lib/mysql/server-cert.pem',
                            ssl_enabled: true,
                        },
                    },
                },
                {
                    class_name: 'CmonHost',
                    cdt_path: '/RGE-MYSQL1-QA',
                    owner_user_id: 1,
                    owner_user_name: 'system',
                    owner_group_id: 1,
                    owner_group_name: 'admins',
                    acl: 'user::rwx,group::rwx,other::---',
                    clusterid: 24,
                    configfile: '/etc/cmon.d/cmon_24.cnf',
                    connected: true,
                    container: false,
                    hostId: 149,
                    hostname: 'CHQMYSQLMGMT',
                    hoststatus: 'CmonHostOnline',
                    ip: '10.200.6.171',
                    lastseen: 1679940587,
                    logfile: '/var/log/cmon_24.log',
                    maintenance_mode: 'none',
                    maintenance_mode_active: false,
                    message: 'Up and running.',
                    nodetype: 'controller',
                    pid: 1774,
                    pingstatus: -1,
                    pingtime: -1,
                    port: 9500,
                    role: 'controller',
                    status: 10,
                    timestamp: 1679940592,
                    unique_id: 143,
                    uptime: 300952,
                    version: '1.9.5.6225',
                    wallclock: 1679940592,
                    wallclocktimestamp: 1679940592,
                    distribution: {
                        name: 'ubuntu',
                        codename: 'bionic',
                        release: '18.04',
                        type: 'debian',
                    },
                },
                {
                    class_name: 'CmonGaleraHost',
                    cdt_path: '/RGE-MYSQL1-QA',
                    owner_user_id: 1,
                    owner_user_name: 'system',
                    owner_group_id: 1,
                    owner_group_name: 'admins',
                    acl: 'user::rwx,group::rwx,other::---',
                    clusterid: 24,
                    configfile: ['/etc/mysql/my.cnf'],
                    connected: true,
                    container: false,
                    datadir: '/var/lib/mysql/',
                    description: '',
                    errorcode: 0,
                    errormsg: 'Up and running.',
                    hostId: 150,
                    hostname: 'RGEMYSQL1QA2',
                    hostname_internal: '10.102.42.42',
                    hoststatus: 'CmonHostOnline',
                    ip: '10.102.42.42',
                    isgalera: true,
                    lastseen: 1679940613,
                    log_bin: 'ON',
                    log_bin_basename: '/var/lib/mysql/binlog',
                    logfile: '/var/log/mysql/mysqld.log',
                    maintenance_mode: 'none',
                    maintenance_mode_active: false,
                    message: 'Up and running.',
                    mysqlstatus: 0,
                    nodeid: 150,
                    nodetype: 'galera',
                    performance_schema: true,
                    pid: 2840,
                    pidfile: '/var/lib/mysql/mysql.pid',
                    pingstatus: 1,
                    pingtime: 7535,
                    port: 3306,
                    readonly: true,
                    role: 'master',
                    serverid: 24000,
                    skip_name_resolve: false,
                    slaves: [],
                    socket: '/var/lib/mysql/mysql.sock',
                    sshfailcount: 0,
                    statereason:
                        'Setting to MYSQL_OK because the node is Synced',
                    status: 0,
                    stopped_at: '2023-01-08T04:23:25.517Z',
                    super_read_only: true,
                    timestamp: 1679940613,
                    unique_id: 144,
                    uptime: 1498060,
                    version: '5.7.40-43-57-log',
                    version_comment:
                        'Percona XtraDB Cluster (GPL), Release rel43, Revision ab4d0bd, WSREP version 31.63, wsrep_31.63',
                    wallclock: 1679940592,
                    wallclocktimestamp: 1679940592,
                    audit_log: {
                        plugins: [],
                    },
                    distribution: {
                        name: 'ubuntu',
                        codename: 'focal',
                        release: '20.04',
                        type: 'debian',
                    },
                    galera: {
                        certsdepsdistance: 0,
                        cluster_address:
                            'gcomm://10.102.42.41,10.102.42.42,RGEMYSQL1QA3',
                        clustername: 'RGE-MYSQL1-QA',
                        clustersize: 3,
                        connected: true,
                        flowctrlpaused: 0,
                        flowctrlrecv: 0,
                        flowctrlsent: 0,
                        galerastatus: 'Primary',
                        gcache_recover: false,
                        lastcommitted: 37444,
                        localrecvqueue: 0,
                        localrecvqueueavg: 0,
                        localsendqueue: 0,
                        localsendqueueavg: 0,
                        localstatus: 4,
                        localstatusstr: 'Synced',
                        provider: '/usr/lib/libgalera_smm.so',
                        ready: 'ON',
                        segmentid: 0,
                        socketssl: false,
                        sst_method: 'xtrabackup-v2',
                    },
                    replication_master: {
                        binlog_do_db: '',
                        binlog_ignore_db: '',
                        exec_gtid:
                            '33e7fe75-70f4-ee12-6a4f-9cf72ef12316:1-965,93474419-5dff-ee13-6239-1570910ada2a:1-82106,b0c54df4-78fb-ee12-4c66-48f0684bbc3e:1-199,cec224fc-8c46-ee12-66bf-b8d36628ae64:1-5552:5561-16662,dc671b22-8e34-ee12-5362-29e279c97227:1-1957,e6170f58-9aeb-ee12-426a-f15e9b96af2a:1-53618:53760-55072',
                        file: 'binlog.000007',
                        position: '828750',
                        semisync_status: 'OFF',
                    },
                    replication_slave: {
                        exec_master_log_pos: 22504783,
                        executed_gtid_set: '',
                        gtid_binlog_pos: '',
                        gtid_io_pos: '',
                        last_io_errno: '',
                        last_io_error: '',
                        last_io_error_timestamp: '221216 01:17:44',
                        last_sql_errno: '0',
                        last_sql_error: '',
                        last_sql_error_timestamp: '',
                        linkstatus: 7,
                        master_cluster_id: '-1',
                        master_host: '',
                        master_log_file: '',
                        master_port: '',
                        master_server_id: '0',
                        master_uuid: '',
                        read_master_log_pos: '0',
                        relay_master_log_file: '',
                        retrieved_gtid_set: '',
                        seconds_behind_master: '',
                        semisync_status: 'OFF',
                        slave_io_running: '',
                        slave_io_state: '',
                        slave_sql_running: '',
                        slave_sql_state: '',
                        sqldelay: 0,
                        status: '',
                        using_gtid: '',
                    },
                    slow_query: {
                        log_not_using_idxs: 'OFF',
                        long_query_time: '0.500000',
                        slow_query_log: 'OFF',
                        slow_query_log_file: '/var/log/mysql/mysql-slow.log',
                    },
                    ssl_certs: {
                        replication: {
                            ca: '',
                            id: 0,
                            key: '',
                            path: '',
                        },
                        server: {
                            ca: '/var/lib/mysql/ca.pem',
                            id: 0,
                            key: '/var/lib/mysql/server-key.pem',
                            path: '/var/lib/mysql/server-cert.pem',
                            ssl_enabled: false,
                        },
                    },
                },
                {
                    class_name: 'CmonGaleraHost',
                    cdt_path: '/RGE-MYSQL1-QA',
                    owner_user_id: 1,
                    owner_user_name: 'system',
                    owner_group_id: 1,
                    owner_group_name: 'admins',
                    acl: 'user::rwx,group::rwx,other::---',
                    clusterid: 24,
                    configfile: ['/etc/mysql/my.cnf'],
                    connected: true,
                    container: false,
                    datadir: '/var/lib/mysql/',
                    description: '',
                    errorcode: 0,
                    errormsg: 'Up and running.',
                    hostId: 151,
                    hostname: 'RGEMYSQL1QA3',
                    hostname_internal: '10.102.42.43',
                    hoststatus: 'CmonHostOnline',
                    ip: '10.102.42.43',
                    isgalera: true,
                    lastseen: 1679940613,
                    log_bin: 'ON',
                    log_bin_basename: '/var/lib/mysql/binlog',
                    logfile: '/var/log/mysql/mysqld.log',
                    maintenance_mode: 'none',
                    maintenance_mode_active: false,
                    message: 'Up and running.',
                    mysqlstatus: 0,
                    nodeid: 151,
                    nodetype: 'galera',
                    performance_schema: true,
                    pid: 2462,
                    pidfile: '/var/lib/mysql/mysql.pid',
                    pingstatus: 1,
                    pingtime: 7622,
                    port: 3306,
                    readonly: true,
                    role: 'master',
                    serverid: 24000,
                    skip_name_resolve: false,
                    slaves: [],
                    socket: '/var/lib/mysql/mysql.sock',
                    sshfailcount: 0,
                    statereason:
                        'Setting to MYSQL_OK because the node is Synced',
                    status: 0,
                    stopped_at: '2023-01-08T04:26:30.734Z',
                    super_read_only: true,
                    timestamp: 1679940613,
                    unique_id: 145,
                    uptime: 1498195,
                    version: '5.7.40-43-57-log',
                    version_comment:
                        'Percona XtraDB Cluster (GPL), Release rel43, Revision ab4d0bd, WSREP version 31.63, wsrep_31.63',
                    wallclock: 1679940592,
                    wallclocktimestamp: 1679940592,
                    audit_log: {
                        plugins: [],
                    },
                    distribution: {
                        name: 'ubuntu',
                        codename: 'focal',
                        release: '20.04',
                        type: 'debian',
                    },
                    galera: {
                        certsdepsdistance: 0,
                        cluster_address:
                            'gcomm://10.102.42.41,10.102.42.42,RGEMYSQL1QA3',
                        clustername: 'RGE-MYSQL1-QA',
                        clustersize: 3,
                        connected: true,
                        flowctrlpaused: 0,
                        flowctrlrecv: 0,
                        flowctrlsent: 0,
                        galerastatus: 'Primary',
                        gcache_recover: false,
                        lastcommitted: 37444,
                        localrecvqueue: 0,
                        localrecvqueueavg: 0,
                        localsendqueue: 0,
                        localsendqueueavg: 0,
                        localstatus: 4,
                        localstatusstr: 'Synced',
                        provider: '/usr/lib/libgalera_smm.so',
                        ready: 'ON',
                        segmentid: 0,
                        socketssl: false,
                        sst_method: 'xtrabackup-v2',
                    },
                    replication_master: {
                        binlog_do_db: '',
                        binlog_ignore_db: '',
                        exec_gtid:
                            '33e7fe75-70f4-ee12-6a4f-9cf72ef12316:1-87,93474419-5dff-ee13-6239-1570910ada2a:1-82106,b0c54df4-78fb-ee12-4c66-48f0684bbc3e:1-199,cec224fc-8c46-ee12-66bf-b8d36628ae64:1-5552:5561-16662,dc671b22-8e34-ee12-5362-29e279c97227:1-1957,e6170f58-9aeb-ee12-426a-f15e9b96af2a:1-53618:53760-55072',
                        file: 'binlog.000009',
                        position: '828750',
                        semisync_status: 'OFF',
                    },
                    replication_slave: {
                        exec_master_log_pos: 22504783,
                        executed_gtid_set: '',
                        gtid_binlog_pos: '',
                        gtid_io_pos: '',
                        last_io_errno: '',
                        last_io_error: '',
                        linkstatus: 7,
                        master_cluster_id: '-1',
                        master_host: '',
                        master_log_file: '',
                        master_port: '',
                        read_master_log_pos: '0',
                        relay_master_log_file: '',
                        retrieved_gtid_set: '',
                        seconds_behind_master: '',
                        semisync_status: 'OFF',
                        slave_io_running: '',
                        slave_io_state: '',
                        slave_sql_running: '',
                        slave_sql_state: '',
                        status: '',
                        using_gtid: '',
                    },
                    slow_query: {
                        log_not_using_idxs: 'OFF',
                        long_query_time: '0.500000',
                        slow_query_log: 'OFF',
                        slow_query_log_file: '/var/log/mysql/mysql-slow.log',
                    },
                    ssl_certs: {
                        replication: {
                            ca: '',
                            id: 0,
                            key: '',
                            path: '',
                        },
                        server: {
                            ca: '/var/lib/mysql/ca.pem',
                            id: 0,
                            key: '/var/lib/mysql/server-key.pem',
                            path: '/var/lib/mysql/server-cert.pem',
                            ssl_enabled: false,
                        },
                    },
                },
                {
                    class_name: 'CmonHaProxyHost',
                    cdt_path: '/RGE-MYSQL1-QA',
                    acl: 'user::rwx,group::rwx,other::---',
                    backend_name_ro: '',
                    backend_name_rw: '',
                    clusterid: 24,
                    cmdline:
                        '/usr/sbin/haproxy -D -f /etc/haproxy/haproxy.cfg -sf $(cat /var/run/haproxy.pid)',
                    configfile: '/etc/haproxy/haproxy.cfg',
                    connectstring: 'rgemysql1halbqa1:3307',
                    container: false,
                    hostId: 152,
                    hostname: 'rgemysql1halbqa1',
                    hoststatus: 'CmonHostOnline',
                    ip: '10.102.42.51',
                    lastseen: 1679940587,
                    lb_admin: 'admin',
                    lb_name: 'lb_group1',
                    lb_password: 'admin',
                    lb_policy: 'leastconn',
                    maintenance_mode: 'none',
                    maintenance_mode_active: false,
                    message: "Process 'haproxy' is running.",
                    nodetype: 'haproxy',
                    pid: 3650,
                    pingstatus: 1,
                    pingtime: 7393,
                    port: 9600,
                    ro_port: 3308,
                    rw_port: 3307,
                    socket: '/var/run/haproxy.socket',
                    sshfailcount: 0,
                    statereason: 'CmonProcessManager::haProxyDaemonFound()',
                    stats_socket: '/var/run/haproxy.socket',
                    status: 10,
                    timeout_client: 10800,
                    timeout_server: 10800,
                    timestamp: 1679940592,
                    unique_id: 146,
                    version: '1.8.9',
                    wallclock: 1679940592,
                    wallclocktimestamp: 1679940592,
                    xinetd_allow_from: '0.0.0.0/0',
                    backend_info: {
                        backend_servers: [
                            {
                                name: 'admin_page',
                                last_check_state: '',
                                service: 'FRONTEND',
                                status: 'OPEN',
                            },
                            {
                                name: 'admin_page',
                                last_check_state: '',
                                service: 'BACKEND',
                                status: 'UP',
                            },
                            {
                                name: 'haproxy_rgemysql1halbqa1_3307_rw',
                                last_check_state: '',
                                service: 'FRONTEND',
                                status: 'OPEN',
                            },
                            {
                                name: 'haproxy_rgemysql1halbqa1_3307_rw',
                                last_check_state: '(tcp-check)',
                                service: 'RGEMYSQL1QA1',
                                status: 'UP',
                            },
                            {
                                name: 'haproxy_rgemysql1halbqa1_3307_rw',
                                last_check_state: '(tcp-check)',
                                service: 'RGEMYSQL1QA2',
                                status: 'UP',
                            },
                            {
                                name: 'haproxy_rgemysql1halbqa1_3307_rw',
                                last_check_state: '(tcp-check)',
                                service: 'RGEMYSQL1QA3',
                                status: 'UP',
                            },
                            {
                                name: 'haproxy_rgemysql1halbqa1_3307_rw',
                                last_check_state: '',
                                service: 'BACKEND',
                                status: 'UP',
                            },
                        ],
                    },
                    distribution: {
                        name: 'ubuntu',
                        codename: 'bionic',
                        release: '18.04',
                        type: 'debian',
                    },
                },
                {
                    class_name: 'CmonHaProxyHost',
                    cdt_path: '/RGE-MYSQL1-QA',
                    acl: 'user::rwx,group::rwx,other::---',
                    backend_name_ro: '',
                    backend_name_rw: '',
                    clusterid: 24,
                    cmdline:
                        '/usr/sbin/haproxy -D -f /etc/haproxy/haproxy.cfg -sf $(cat /var/run/haproxy.pid)',
                    configfile: '/etc/haproxy/haproxy.cfg',
                    connectstring: 'rgemysql1halbqa2:3307',
                    container: false,
                    hostId: 153,
                    hostname: 'rgemysql1halbqa2',
                    hoststatus: 'CmonHostOnline',
                    ip: '10.102.42.52',
                    lastseen: 1679940587,
                    lb_admin: 'admin',
                    lb_name: 'lb_group1',
                    lb_password: 'admin',
                    lb_policy: 'leastconn',
                    maintenance_mode: 'none',
                    maintenance_mode_active: false,
                    message: "Process 'haproxy' is running.",
                    nodetype: 'haproxy',
                    pid: 3506,
                    pingstatus: 1,
                    pingtime: 7384,
                    port: 9600,
                    ro_port: 3308,
                    rw_port: 3307,
                    socket: '/var/run/haproxy.socket',
                    sshfailcount: 0,
                    statereason: 'CmonProcessManager::haProxyDaemonFound()',
                    stats_socket: '/var/run/haproxy.socket',
                    status: 10,
                    timeout_client: 10800,
                    timeout_server: 10800,
                    timestamp: 1679940592,
                    unique_id: 147,
                    version: '1.8.9',
                    wallclock: 1679940592,
                    wallclocktimestamp: 1679940592,
                    xinetd_allow_from: '0.0.0.0/0',
                    backend_info: {
                        backend_servers: [
                            {
                                name: 'admin_page',
                                last_check_state: '',
                                service: 'FRONTEND',
                                status: 'OPEN',
                            },
                            {
                                name: 'admin_page',
                                last_check_state: '',
                                service: 'BACKEND',
                                status: 'UP',
                            },
                            {
                                name: 'haproxy_rgemysql1halbqa2_3307_rw',
                                last_check_state: '',
                                service: 'FRONTEND',
                                status: 'OPEN',
                            },
                            {
                                name: 'haproxy_rgemysql1halbqa2_3307_rw',
                                last_check_state: '(tcp-check)',
                                service: 'RGEMYSQL1QA1',
                                status: 'UP',
                            },
                            {
                                name: 'haproxy_rgemysql1halbqa2_3307_rw',
                                last_check_state: '(tcp-check)',
                                service: 'RGEMYSQL1QA2',
                                status: 'UP',
                            },
                            {
                                name: 'haproxy_rgemysql1halbqa2_3307_rw',
                                last_check_state: '(tcp-check)',
                                service: 'RGEMYSQL1QA3',
                                status: 'UP',
                            },
                            {
                                name: 'haproxy_rgemysql1halbqa2_3307_rw',
                                last_check_state: '',
                                service: 'BACKEND',
                                status: 'UP',
                            },
                        ],
                    },
                    distribution: {
                        name: 'ubuntu',
                        codename: 'bionic',
                        release: '18.04',
                        type: 'debian',
                    },
                },
                {
                    class_name: 'CmonKeepalivedHost',
                    name: 'VI_s9s_keepalived_import',
                    cdt_path: '/RGE-MYSQL1-QA',
                    owner_user_id: 1,
                    owner_user_name: 'system',
                    owner_group_id: 1,
                    owner_group_name: 'admins',
                    acl: 'user::rwx,group::rwx,other::---',
                    clusterid: 24,
                    cmdline: 'service keepalived restart',
                    configfile: '/etc/keepalived/keepalived.conf',
                    container: false,
                    hostId: 152,
                    hostname: 'rgemysql1halbqa1',
                    hoststatus: 'CmonHostOnline',
                    ip: '10.102.42.51',
                    lastseen: 1679940587,
                    maintenance_mode: 'none',
                    maintenance_mode_active: false,
                    message: "Process 'keepalived' is running.",
                    nic: 'ens160',
                    nodetype: 'keepalived',
                    pid: 1119,
                    pingstatus: 1,
                    pingtime: 7393,
                    port: 112,
                    role: 'master',
                    sshfailcount: 0,
                    statereason: 'The keepalived process found.',
                    status: 10,
                    timestamp: 1679940592,
                    unique_id: 148,
                    version: '1.3',
                    virtualip: '10.102.42.50',
                    wallclock: 1679940592,
                    wallclocktimestamp: 1679940592,
                    distribution: {
                        name: 'ubuntu',
                        codename: 'bionic',
                        release: '18.04',
                        type: 'debian',
                    },
                },
                {
                    class_name: 'CmonKeepalivedHost',
                    name: 'VI_s9s_keepalived_import',
                    cdt_path: '/RGE-MYSQL1-QA',
                    owner_user_id: 1,
                    owner_user_name: 'system',
                    owner_group_id: 1,
                    owner_group_name: 'admins',
                    acl: 'user::rwx,group::rwx,other::---',
                    clusterid: 24,
                    cmdline: 'service keepalived restart',
                    configfile: '/etc/keepalived/keepalived.conf',
                    container: false,
                    hostId: 153,
                    hostname: 'rgemysql1halbqa2',
                    hoststatus: 'CmonHostOnline',
                    ip: '10.102.42.52',
                    lastseen: 1679940587,
                    maintenance_mode: 'none',
                    maintenance_mode_active: false,
                    message: "Process 'keepalived' is running.",
                    nic: 'ens160',
                    nodetype: 'keepalived',
                    pid: 1229,
                    pingstatus: 1,
                    pingtime: 7384,
                    port: 112,
                    role: 'backup',
                    sshfailcount: 0,
                    statereason: 'The keepalived process found.',
                    status: 10,
                    timestamp: 1679940592,
                    unique_id: 149,
                    version: '1.3',
                    virtualip: '10.102.42.50',
                    wallclock: 1679940592,
                    wallclocktimestamp: 1679940592,
                    distribution: {
                        name: 'ubuntu',
                        codename: 'bionic',
                        release: '18.04',
                        type: 'debian',
                    },
                },
            ],
            job_statistics: {
                class_name: 'CmonJobStatistics',
                cluster_id: 24,
                by_state: {
                    ABORTED: 0,
                    DEFINED: 0,
                    DEQUEUED: 0,
                    FAILED: 0,
                    FINISHED: 0,
                    RUNNING: 1,
                },
            },
            owner: {
                class_name: 'CmonUser',
                cdt_path: '/',
                owner_user_id: 1,
                owner_user_name: 'system',
                owner_group_id: 1,
                owner_group_name: 'admins',
                acl: 'user::rwx,group::r--,other::r--',
                created: '2018-12-13T23:26:18.826Z',
                disabled: false,
                first_name: 'System',
                last_failed_login: '',
                last_login: '2023-03-27T18:10:11.706Z',
                last_name: 'User',
                n_failed_logins: 0,
                origin: 'CmonDb',
                password_encrypted:
                    '43030e3067de2de956046fcc97ec13337b5ec2f51efbc66ebfe88f913d4339c4',
                password_format: 'sha256',
                password_salt: '75b76a66-f0ee-4cf6-8f28-480ed0d4809a',
                suspended: false,
                user_id: 1,
                user_name: 'system',
                groups: [
                    {
                        class_name: 'CmonGroup',
                        cdt_path: '/groups',
                        owner_user_id: 1,
                        owner_user_name: 'system',
                        owner_group_id: 1,
                        owner_group_name: 'admins',
                        group_id: 1,
                        group_name: 'admins',
                    },
                ],
                timezone: {
                    class_name: 'CmonTimeZone',
                    name: 'Pacific Standard Time',
                    abbreviation: 'PST',
                    offset: 28800,
                    use_dst: false,
                },
            },
        },
        {
            class_name: 'CmonClusterInfo',
            cdt_path: '/',
            acl: 'user::rwx,group::rwx,other::---',
            tags: ['REBUILT', 'FROM_RGE_QA', 'QA'],
            cluster_auto_recovery: true,
            cluster_id: 25,
            cluster_name: 'CHQ-MYSQL2-QA',
            cluster_type: 'GALERA',
            configuration_file: '/etc/cmon.d/cmon_25.cnf',
            effective_privileges: '',
            log_file: '/var/log/cmon_25.log',
            maintenance_mode_active: false,
            managed: true,
            node_auto_recovery: true,
            rpc_version: '1.0',
            state: 'STARTED',
            status_text: 'All nodes are operational.',
            vendor: 'percona',
            version: '5.7',
            alarm_statistics: {
                class_name: 'CmonAlarmStatistics',
                cluster_id: 25,
                created_after: null,
                critical: 0,
                reported_after: null,
                warning: 1,
            },
            group_owner: {
                class_name: 'CmonGroup',
                cdt_path: '/groups',
                owner_user_id: 1,
                owner_user_name: 'system',
                owner_group_id: 1,
                owner_group_name: 'admins',
                acl: 'user::rwx,group::rwx,other::---',
                group_id: 1,
                group_name: 'admins',
            },
            hosts: [
                {
                    class_name: 'CmonGaleraHost',
                    cdt_path: '/CHQ-MYSQL2-QA',
                    owner_user_id: 1,
                    owner_user_name: 'system',
                    owner_group_id: 1,
                    owner_group_name: 'admins',
                    acl: 'user::rwx,group::rwx,other::---',
                    clusterid: 25,
                    configfile: ['/etc/mysql/my.cnf'],
                    connected: true,
                    container: false,
                    datadir: '/var/lib/mysql/',
                    description: '',
                    errorcode: 0,
                    errormsg: 'Up and running.',
                    hostId: 165,
                    hostname: 'CHQMYSQL2QA2',
                    hostname_data: 'CHQMYSQL2QA2',
                    hostname_internal: '10.202.42.42',
                    hoststatus: 'CmonHostOnline',
                    ip: '10.202.42.42',
                    isgalera: true,
                    lastseen: 1679940613,
                    log_bin: 'ON',
                    log_bin_basename: '/var/lib/mysql/binlog',
                    logfile: '/var/log/mysql/mysqld.log',
                    maintenance_mode: 'none',
                    maintenance_mode_active: false,
                    message: 'Up and running.',
                    mysqlstatus: 0,
                    nodeid: 165,
                    nodetype: 'galera',
                    performance_schema: true,
                    pid: 2336,
                    pidfile: '/var/lib/mysql/mysql.pid',
                    pingstatus: 1,
                    pingtime: 1257,
                    port: '3306',
                    readonly: false,
                    role: 'master',
                    serverid: 25000,
                    skip_name_resolve: false,
                    slaves: [],
                    socket: '/var/lib/mysql/mysql.sock',
                    sshfailcount: 0,
                    statereason:
                        'Setting to MYSQL_OK because the node is Synced',
                    status: 0,
                    stopped_at: '2022-12-04T09:51:25.901Z',
                    super_read_only: false,
                    timestamp: 1679940613,
                    unique_id: 153,
                    uptime: 1497146,
                    version: '5.7.40-43-57-log',
                    version_comment:
                        'Percona XtraDB Cluster (GPL), Release rel43, Revision ab4d0bd, WSREP version 31.63, wsrep_31.63',
                    wallclock: 1679940573,
                    wallclocktimestamp: 1679940573,
                    audit_log: {
                        plugins: [],
                    },
                    distribution: {
                        name: 'ubuntu',
                        codename: 'focal',
                        release: '20.04',
                        type: 'debian',
                    },
                    galera: {
                        certsdepsdistance: 0,
                        cluster_address:
                            'gcomm://10.202.42.42,10.202.42.43,10.202.42.41',
                        clustername: 'CHQ-MYSQL2-QA',
                        clustersize: 3,
                        connected: true,
                        flowctrlpaused: 0,
                        flowctrlrecv: 0,
                        flowctrlsent: 0,
                        galerastatus: 'Primary',
                        gcache_recover: false,
                        lastcommitted: 26023,
                        localrecvqueue: 0,
                        localrecvqueueavg: 0,
                        localsendqueue: 0,
                        localsendqueueavg: 0,
                        localstatus: 4,
                        localstatusstr: 'Synced',
                        provider: '/usr/lib/libgalera_smm.so',
                        ready: 'ON',
                        segmentid: 0,
                        socketssl: true,
                        sst_method: 'xtrabackup-v2',
                    },
                    replication_master: {
                        binlog_do_db: '',
                        binlog_ignore_db: '',
                        exec_gtid:
                            '33e7fe75-70f4-ee12-6a4f-9cf72ef12316:1-964,93474419-5dff-ee13-6239-1570910ada2a:1-82106,b0c54df4-78fb-ee12-4c66-48f0684bbc3e:1-206,cec224fc-8c46-ee12-66bf-b8d36628ae64:1-16654,dc671b22-8e34-ee12-5362-29e279c97227:1-1957,e6170f58-9aeb-ee12-426a-f15e9b96af2a:1-53618:53760-55072',
                        file: 'binlog.000017',
                        position: '664266',
                        semisync_status: 'OFF',
                    },
                    replication_slave: {
                        exec_master_log_pos: 22504783,
                        executed_gtid_set: '',
                        gtid_binlog_pos: '',
                        gtid_io_pos: '',
                        last_io_errno: '',
                        last_io_error: '',
                        last_io_error_timestamp: '221216 08:31:46',
                        last_sql_errno: '',
                        last_sql_error: '',
                        last_sql_error_timestamp: '',
                        linkstatus: 7,
                        master_cluster_id: '-1',
                        master_host: '',
                        master_log_file: '',
                        master_port: '',
                        master_server_id: '',
                        master_uuid: '737d1d90-71cb-11ed-a61e-0050568aff8b',
                        read_master_log_pos: '0',
                        relay_master_log_file: '',
                        retrieved_gtid_set: '',
                        seconds_behind_master: '',
                        semisync_status: 'OFF',
                        slave_io_running: '',
                        slave_io_state: '',
                        slave_sql_running: '',
                        slave_sql_state: '',
                        sqldelay: 0,
                        status: '',
                        using_gtid: '',
                    },
                    slow_query: {
                        log_not_using_idxs: 'OFF',
                        long_query_time: '0.500000',
                        slow_query_log: 'OFF',
                        slow_query_log_file: '/var/log/mysql/mysql-slow.log',
                    },
                    ssl_certs: {
                        replication: {
                            ca: '',
                            id: 48,
                            key: '/etc/mysql/certs/galera_rep.key',
                            path: '/etc/mysql/certs/galera_rep.crt',
                        },
                        server: {
                            ca: '/etc/mysql/certs/server_ca.crt',
                            id: 46,
                            key: '/etc/mysql/certs/server.key',
                            path: '/etc/mysql/certs/server.crt',
                            ssl_enabled: true,
                        },
                    },
                },
                {
                    class_name: 'CmonHost',
                    cdt_path: '/CHQ-MYSQL2-QA',
                    owner_user_id: 1,
                    owner_user_name: 'system',
                    owner_group_id: 1,
                    owner_group_name: 'admins',
                    acl: 'user::rwx,group::rwx,other::---',
                    clusterid: 25,
                    configfile: '/etc/cmon.d/cmon_25.cnf',
                    connected: true,
                    container: false,
                    hostId: 166,
                    hostname: 'CHQMYSQLMGMT',
                    hoststatus: 'CmonHostOnline',
                    ip: '10.200.6.171',
                    lastseen: 1679940594,
                    logfile: '/var/log/cmon_25.log',
                    maintenance_mode: 'none',
                    maintenance_mode_active: false,
                    message: 'Up and running.',
                    nodetype: 'controller',
                    pid: 1774,
                    pingstatus: -1,
                    pingtime: -1,
                    port: 9500,
                    role: 'controller',
                    status: 10,
                    timestamp: 1679940594,
                    unique_id: 154,
                    uptime: 300959,
                    version: '1.9.5.6225',
                    wallclock: 1679940573,
                    wallclocktimestamp: 1679940573,
                    distribution: {
                        name: 'ubuntu',
                        codename: 'bionic',
                        release: '18.04',
                        type: 'debian',
                    },
                },
                {
                    class_name: 'CmonHaProxyHost',
                    cdt_path: '/CHQ-MYSQL2-QA',
                    acl: 'user::rwx,group::rwx,other::---',
                    backend_name_ro: '',
                    backend_name_rw: '',
                    clusterid: 25,
                    cmdline:
                        '/usr/sbin/haproxy -D -f /etc/haproxy/haproxy.cfg -sf $(cat /var/run/haproxy.pid)',
                    configfile: '/etc/haproxy/haproxy.cfg',
                    connectstring: 'CHQMYSQLHAPROXY1:3307',
                    container: false,
                    hostId: 167,
                    hostname: 'CHQMYSQLHAPROXY1',
                    hoststatus: 'CmonHostOnline',
                    ip: '10.200.6.91',
                    lastseen: 1679940594,
                    lb_admin: 'admin',
                    lb_name: 'lb_group1',
                    lb_password: 'admin',
                    lb_policy: 'leastconn',
                    maintenance_mode: 'none',
                    maintenance_mode_active: false,
                    message: "Process 'haproxy' is running.",
                    nodetype: 'haproxy',
                    pid: 1289,
                    pingstatus: 1,
                    pingtime: 918,
                    port: 9600,
                    ro_port: 3308,
                    rw_port: 3307,
                    socket: '/var/run/haproxy.socket',
                    sshfailcount: 0,
                    statereason: 'CmonProcessManager::haProxyDaemonFound()',
                    stats_socket: '/var/run/haproxy.socket',
                    status: 10,
                    timeout_client: 10800,
                    timeout_server: 10800,
                    timestamp: 1679940600,
                    unique_id: 155,
                    version: '1.8.8',
                    wallclock: 1679940573,
                    wallclocktimestamp: 1679940573,
                    xinetd_allow_from: '0.0.0.0/0',
                    backend_info: {
                        backend_servers: [
                            {
                                name: 'admin_page',
                                last_check_state: '',
                                service: 'FRONTEND',
                                status: 'OPEN',
                            },
                            {
                                name: 'admin_page',
                                last_check_state: '',
                                service: 'BACKEND',
                                status: 'UP',
                            },
                            {
                                name: 'haproxy_CHQMYSQLHAPROXY1_3307',
                                last_check_state: '',
                                service: 'FRONTEND',
                                status: 'OPEN',
                            },
                            {
                                name: 'haproxy_CHQMYSQLHAPROXY1_3307',
                                last_check_state: '',
                                service: 'CHQMYSQL2QA2',
                                status: 'UP',
                            },
                            {
                                name: 'haproxy_CHQMYSQLHAPROXY1_3307',
                                last_check_state: '(tcp-check)',
                                service: 'CHQMYSQL2QA3',
                                status: 'UP',
                            },
                            {
                                name: 'haproxy_CHQMYSQLHAPROXY1_3307',
                                last_check_state: '(tcp-check)',
                                service: 'CHQMYSQL2QA1',
                                status: 'UP',
                            },
                            {
                                name: 'haproxy_CHQMYSQLHAPROXY1_3307',
                                last_check_state: '',
                                service: 'BACKEND',
                                status: 'UP',
                            },
                        ],
                    },
                    distribution: {
                        name: 'ubuntu',
                        codename: 'bionic',
                        release: '18.04',
                        type: 'debian',
                    },
                },
                {
                    class_name: 'CmonKeepalivedHost',
                    name: 'VI_s9s_keepalived_import',
                    cdt_path: '/CHQ-MYSQL2-QA',
                    owner_user_id: 1,
                    owner_user_name: 'system',
                    owner_group_id: 1,
                    owner_group_name: 'admins',
                    acl: 'user::rwx,group::rwx,other::---',
                    clusterid: 25,
                    cmdline: 'service keepalived restart',
                    configfile: '/etc/keepalived/keepalived.conf',
                    container: false,
                    hostId: 167,
                    hostname: 'CHQMYSQLHAPROXY1',
                    hoststatus: 'CmonHostOnline',
                    ip: '10.200.6.91',
                    lastseen: 1679940594,
                    maintenance_mode: 'none',
                    maintenance_mode_active: false,
                    message: "Process 'keepalived' is running.",
                    nic: 'ens160',
                    nodetype: 'keepalived',
                    pid: 1301,
                    pingstatus: 1,
                    pingtime: 918,
                    port: 112,
                    role: 'master',
                    sshfailcount: 0,
                    statereason: 'The keepalived process found.',
                    status: 10,
                    timestamp: 1679940594,
                    unique_id: 156,
                    version: '1.3',
                    virtualip: '10.200.6.90',
                    wallclock: 1679940573,
                    wallclocktimestamp: 1679940573,
                    distribution: {
                        name: 'ubuntu',
                        codename: 'bionic',
                        release: '18.04',
                        type: 'debian',
                    },
                },
                {
                    class_name: 'CmonKeepalivedHost',
                    name: 'VI_s9s_keepalived_import',
                    cdt_path: '/CHQ-MYSQL2-QA',
                    owner_user_id: 1,
                    owner_user_name: 'system',
                    owner_group_id: 1,
                    owner_group_name: 'admins',
                    acl: 'user::rwx,group::rwx,other::---',
                    clusterid: 25,
                    cmdline: 'service keepalived restart',
                    configfile: '/etc/keepalived/keepalived.conf',
                    container: false,
                    hostId: 168,
                    hostname: 'CHQMYSQLHAPROXY2',
                    hoststatus: 'CmonHostOnline',
                    ip: '10.200.6.92',
                    lastseen: 1679940594,
                    maintenance_mode: 'none',
                    maintenance_mode_active: false,
                    message: "Process 'keepalived' is running.",
                    nic: 'ens160',
                    nodetype: 'keepalived',
                    pid: 1021,
                    pingstatus: 1,
                    pingtime: 167,
                    port: 112,
                    role: 'backup',
                    sshfailcount: 0,
                    statereason: 'The keepalived process found.',
                    status: 10,
                    timestamp: 1679940594,
                    unique_id: 157,
                    version: '1.3',
                    virtualip: '10.200.6.90',
                    wallclock: 1679940573,
                    wallclocktimestamp: 1679940573,
                    distribution: {
                        name: 'ubuntu',
                        codename: 'bionic',
                        release: '18.04',
                        type: 'debian',
                    },
                },
                {
                    class_name: 'CmonHaProxyHost',
                    cdt_path: '/CHQ-MYSQL2-QA',
                    acl: 'user::rwx,group::rwx,other::---',
                    backend_name_ro: '',
                    backend_name_rw: '',
                    clusterid: 25,
                    cmdline:
                        '/usr/sbin/haproxy -D -f /etc/haproxy/haproxy.cfg -sf $(cat /var/run/haproxy.pid)',
                    configfile: '/etc/haproxy/haproxy.cfg',
                    connectstring: 'CHQMYSQLHAPROXY2:3307',
                    container: false,
                    hostId: 168,
                    hostname: 'CHQMYSQLHAPROXY2',
                    hoststatus: 'CmonHostOnline',
                    ip: '10.200.6.92',
                    lastseen: 1679940594,
                    lb_admin: 'admin',
                    lb_name: 'lb_group1',
                    lb_password: 'admin',
                    lb_policy: 'leastconn',
                    maintenance_mode: 'none',
                    maintenance_mode_active: false,
                    message: "Process 'haproxy' is running.",
                    nodetype: 'haproxy',
                    pid: 1072,
                    pingstatus: 1,
                    pingtime: 167,
                    port: 9600,
                    ro_port: 3308,
                    rw_port: 3307,
                    socket: '/var/run/haproxy.socket',
                    sshfailcount: 0,
                    statereason: 'CmonProcessManager::haProxyDaemonFound()',
                    stats_socket: '/var/run/haproxy.socket',
                    status: 10,
                    timeout_client: 10800,
                    timeout_server: 10800,
                    timestamp: 1679940594,
                    unique_id: 158,
                    version: '1.8.8',
                    wallclock: 1679940573,
                    wallclocktimestamp: 1679940573,
                    xinetd_allow_from: '0.0.0.0/0',
                    backend_info: {
                        backend_servers: [
                            {
                                name: 'admin_page',
                                last_check_state: '',
                                service: 'FRONTEND',
                                status: 'OPEN',
                            },
                            {
                                name: 'admin_page',
                                last_check_state: '',
                                service: 'BACKEND',
                                status: 'UP',
                            },
                            {
                                name: 'haproxy_CHQMYSQLHAPROXY2_3307',
                                last_check_state: '',
                                service: 'FRONTEND',
                                status: 'OPEN',
                            },
                            {
                                name: 'haproxy_CHQMYSQLHAPROXY2_3307',
                                last_check_state: '(tcp-check)',
                                service: 'CHQMYSQL2QA2',
                                status: 'UP',
                            },
                            {
                                name: 'haproxy_CHQMYSQLHAPROXY2_3307',
                                last_check_state: '(tcp-check)',
                                service: 'CHQMYSQL2QA3',
                                status: 'UP',
                            },
                            {
                                name: 'haproxy_CHQMYSQLHAPROXY2_3307',
                                last_check_state: '(tcp-check)',
                                service: 'CHQMYSQL2QA1',
                                status: 'UP',
                            },
                            {
                                name: 'haproxy_CHQMYSQLHAPROXY2_3307',
                                last_check_state: '',
                                service: 'BACKEND',
                                status: 'UP',
                            },
                        ],
                    },
                    distribution: {
                        name: 'ubuntu',
                        codename: 'bionic',
                        release: '18.04',
                        type: 'debian',
                    },
                },
                {
                    class_name: 'CmonGaleraHost',
                    cdt_path: '/CHQ-MYSQL2-QA',
                    owner_user_id: 1,
                    owner_user_name: 'system',
                    owner_group_id: 1,
                    owner_group_name: 'admins',
                    acl: 'user::rwx,group::rwx,other::---',
                    clusterid: 25,
                    configfile: ['/etc/mysql/my.cnf'],
                    connected: true,
                    container: false,
                    datadir: '/var/lib/mysql/',
                    errorcode: 0,
                    errormsg: 'Up and running.',
                    hostId: 170,
                    hostname: 'CHQMYSQL2QA3',
                    hostname_internal: '10.202.42.43',
                    hoststatus: 'CmonHostOnline',
                    ip: '10.202.42.43',
                    isgalera: true,
                    lastseen: 1679940613,
                    log_bin: 'ON',
                    log_bin_basename: '/var/lib/mysql/binlog',
                    logfile: '/var/log/mysql/mysqld.log',
                    maintenance_mode: 'none',
                    maintenance_mode_active: false,
                    message: 'Up and running.',
                    mysqlstatus: 0,
                    nodeid: 170,
                    nodetype: 'galera',
                    performance_schema: true,
                    pid: 2693,
                    pidfile: '/var/lib/mysql/mysql.pid',
                    pingstatus: 1,
                    pingtime: 549,
                    port: 3306,
                    readonly: false,
                    role: 'master',
                    serverid: 25000,
                    skip_name_resolve: false,
                    slaves: [],
                    socket: '/var/lib/mysql/mysql.sock',
                    sshfailcount: 0,
                    statereason:
                        'Setting to MYSQL_OK because the node is Synced',
                    status: 0,
                    super_read_only: false,
                    timestamp: 1679940613,
                    unique_id: 160,
                    uptime: 1497229,
                    version: '5.7.40-43-57-log',
                    version_comment:
                        'Percona XtraDB Cluster (GPL), Release rel43, Revision ab4d0bd, WSREP version 31.63, wsrep_31.63',
                    wallclock: 1679940573,
                    wallclocktimestamp: 1679940573,
                    audit_log: {
                        plugins: [],
                    },
                    distribution: {
                        name: 'ubuntu',
                        codename: 'focal',
                        release: '20.04',
                        type: 'debian',
                    },
                    galera: {
                        certsdepsdistance: 0,
                        cluster_address:
                            'gcomm://10.202.42.42,10.202.42.43,10.202.42.41',
                        clustername: 'CHQ-MYSQL2-QA',
                        clustersize: 3,
                        connected: true,
                        flowctrlpaused: 0,
                        flowctrlrecv: 0,
                        flowctrlsent: 0,
                        galerastatus: 'Primary',
                        gcache_recover: false,
                        lastcommitted: 26023,
                        localrecvqueue: 0,
                        localrecvqueueavg: 0,
                        localsendqueue: 0,
                        localsendqueueavg: 0,
                        localstatus: 4,
                        localstatusstr: 'Synced',
                        provider: '/usr/lib/libgalera_smm.so',
                        ready: 'ON',
                        segmentid: 0,
                        socketssl: true,
                        sst_method: 'xtrabackup-v2',
                    },
                    replication_master: {
                        binlog_do_db: '',
                        binlog_ignore_db: '',
                        exec_gtid:
                            '33e7fe75-70f4-ee12-6a4f-9cf72ef12316:1-964,93474419-5dff-ee13-6239-1570910ada2a:1-82106,b0c54df4-78fb-ee12-4c66-48f0684bbc3e:1-206,cec224fc-8c46-ee12-66bf-b8d36628ae64:1-16659,dc671b22-8e34-ee12-5362-29e279c97227:1-1957,e6170f58-9aeb-ee12-426a-f15e9b96af2a:1-53618:53760-55072',
                        file: 'binlog.000015',
                        position: '664443',
                        semisync_status: 'OFF',
                    },
                    replication_slave: {
                        exec_master_log_pos: 22504783,
                        executed_gtid_set: '',
                        gtid_binlog_pos: '',
                        gtid_io_pos: '',
                        last_io_errno: '',
                        last_io_error: '',
                        linkstatus: 7,
                        master_cluster_id: '-1',
                        master_host: '',
                        master_log_file: '',
                        master_port: '',
                        read_master_log_pos: '0',
                        relay_master_log_file: '',
                        retrieved_gtid_set: '',
                        seconds_behind_master: '',
                        semisync_status: 'OFF',
                        slave_io_running: '',
                        slave_io_state: '',
                        slave_sql_running: '',
                        slave_sql_state: '',
                        status: '',
                        using_gtid: '',
                    },
                    slow_query: {
                        log_not_using_idxs: 'OFF',
                        long_query_time: '0.500000',
                        slow_query_log: 'OFF',
                        slow_query_log_file: '/var/log/mysql/mysql-slow.log',
                    },
                    ssl_certs: {
                        replication: {
                            ca: '',
                            id: 48,
                            key: '/etc/mysql/certs/galera_rep.key',
                            path: '/etc/mysql/certs/galera_rep.crt',
                        },
                        server: {
                            ca: '/etc/mysql/certs/server_ca.crt',
                            id: 46,
                            key: '/etc/mysql/certs/server.key',
                            path: '/etc/mysql/certs/server.crt',
                            ssl_enabled: true,
                        },
                    },
                },
                {
                    class_name: 'CmonGaleraHost',
                    cdt_path: '/CHQ-MYSQL2-QA',
                    owner_user_id: 1,
                    owner_user_name: 'system',
                    owner_group_id: 1,
                    owner_group_name: 'admins',
                    acl: 'user::rwx,group::rwx,other::---',
                    clusterid: 25,
                    configfile: ['/etc/mysql/my.cnf'],
                    connected: true,
                    container: false,
                    datadir: '/var/lib/mysql/',
                    description: '',
                    errorcode: 0,
                    errormsg: 'Up and running.',
                    hostId: 171,
                    hostname: 'CHQMYSQL2QA1',
                    hostname_internal: '10.202.42.41',
                    hoststatus: 'CmonHostOnline',
                    ip: '10.202.42.41',
                    isgalera: true,
                    lastseen: 1679940613,
                    log_bin: 'ON',
                    log_bin_basename: '/var/lib/mysql/binlog',
                    logfile: '/var/log/mysql/mysqld.log',
                    maintenance_mode: 'none',
                    maintenance_mode_active: false,
                    message: 'Up and running.',
                    mysqlstatus: 0,
                    nodeid: 171,
                    nodetype: 'galera',
                    performance_schema: true,
                    pid: 2442,
                    pidfile: '/var/lib/mysql/mysql.pid',
                    pingstatus: 1,
                    pingtime: 590,
                    port: 3306,
                    readonly: false,
                    role: 'slave',
                    serverid: 25000,
                    skip_name_resolve: false,
                    slaves: [],
                    socket: '/var/lib/mysql/mysql.sock',
                    sshfailcount: 0,
                    statereason:
                        'Setting to MYSQL_OK because the node is Synced',
                    status: 0,
                    stopped_at: '2022-12-28T23:35:37.207Z',
                    super_read_only: false,
                    timestamp: 1679940613,
                    unique_id: 161,
                    uptime: 1496988,
                    version: '5.7.40-43-57-log',
                    version_comment:
                        'Percona XtraDB Cluster (GPL), Release rel43, Revision ab4d0bd, WSREP version 31.63, wsrep_31.63',
                    wallclock: 1679940573,
                    wallclocktimestamp: 1679940573,
                    audit_log: {
                        plugins: [],
                    },
                    distribution: {
                        name: 'ubuntu',
                        codename: 'focal',
                        release: '20.04',
                        type: 'debian',
                    },
                    galera: {
                        certsdepsdistance: 0,
                        cluster_address:
                            'gcomm://10.202.42.42,10.202.42.43,10.202.42.41',
                        clustername: 'CHQ-MYSQL2-QA',
                        clustersize: 3,
                        connected: true,
                        flowctrlpaused: 0,
                        flowctrlrecv: 0,
                        flowctrlsent: 0,
                        galerastatus: 'Primary',
                        gcache_recover: false,
                        lastcommitted: 26023,
                        localrecvqueue: 0,
                        localrecvqueueavg: 0,
                        localsendqueue: 0,
                        localsendqueueavg: 0,
                        localstatus: 4,
                        localstatusstr: 'Synced',
                        provider: '/usr/lib/libgalera_smm.so',
                        ready: 'ON',
                        segmentid: 0,
                        socketssl: true,
                        sst_method: 'xtrabackup-v2',
                    },
                    replication_master: {
                        binlog_do_db: '',
                        binlog_ignore_db: '',
                        exec_gtid:
                            '33e7fe75-70f4-ee12-6a4f-9cf72ef12316:1-964,93474419-5dff-ee13-6239-1570910ada2a:1-82106,b0c54df4-78fb-ee12-4c66-48f0684bbc3e:1-206,cec224fc-8c46-ee12-66bf-b8d36628ae64:1-16662,dc671b22-8e34-ee12-5362-29e279c97227:1-1957,e6170f58-9aeb-ee12-426a-f15e9b96af2a:1-53618:53760-55072',
                        file: 'binlog.000022',
                        position: '664545',
                        semisync_status: 'OFF',
                    },
                    replication_slave: {
                        exec_master_log_pos: '828750',
                        executed_gtid_set:
                            '33e7fe75-70f4-ee12-6a4f-9cf72ef12316:1-964,\n93474419-5dff-ee13-6239-1570910ada2a:1-82106,\nb0c54df4-78fb-ee12-4c66-48f0684bbc3e:1-206,\ncec224fc-8c46-ee12-66bf-b8d36628ae64:1-16662,\ndc671b22-8e34-ee12-5362-29e279c97227:1-1957,\ne6170f58-9aeb-ee12-426a-f15e9b96af2a:1-53618:53760-55072',
                        gtid_binlog_pos: '',
                        gtid_io_pos: '',
                        last_io_errno: '0',
                        last_io_error: '',
                        last_io_error_timestamp: '',
                        last_sql_errno: '0',
                        last_sql_error: '',
                        last_sql_error_timestamp: '',
                        linkstatus: 1,
                        master_cluster_id: 24,
                        master_host: '10.102.42.41',
                        master_log_file: 'binlog.000005',
                        master_port: '3306',
                        master_server_id: '24000',
                        master_uuid: 'ccff55fe-8f0b-11ed-b65e-0050568a530c',
                        read_master_log_pos: '828750',
                        relay_master_log_file: 'binlog.000005',
                        retrieved_gtid_set: '',
                        seconds_behind_master: '0',
                        semisync_status: 'OFF',
                        slave_io_running: 'Yes',
                        slave_io_state: 'Waiting for master to send event',
                        slave_sql_running: 'Yes',
                        slave_sql_state:
                            'Slave has read all relay log; waiting for more updates',
                        sqldelay: 0,
                        status:
                            'Waiting for master to send event<br/>Slave has read all relay log; waiting for more updates',
                        using_gtid: 'ON',
                    },
                    slow_query: {
                        log_not_using_idxs: 'OFF',
                        long_query_time: '0.500000',
                        slow_query_log: 'OFF',
                        slow_query_log_file: '/var/log/mysql/mysql-slow.log',
                    },
                    ssl_certs: {
                        replication: {
                            ca: '',
                            id: 48,
                            key: '/etc/mysql/certs/galera_rep.key',
                            path: '/etc/mysql/certs/galera_rep.crt',
                        },
                        server: {
                            ca: '/etc/mysql/certs/server_ca.crt',
                            id: 46,
                            key: '/etc/mysql/certs/server.key',
                            path: '/etc/mysql/certs/server.crt',
                            ssl_enabled: true,
                        },
                    },
                },
            ],
            job_statistics: {
                class_name: 'CmonJobStatistics',
                cluster_id: 25,
                by_state: {
                    ABORTED: 0,
                    DEFINED: 0,
                    DEQUEUED: 0,
                    FAILED: 0,
                    FINISHED: 4,
                    RUNNING: 1,
                },
            },
            owner: {
                class_name: 'CmonUser',
                cdt_path: '/',
                owner_user_id: 1,
                owner_user_name: 'system',
                owner_group_id: 1,
                owner_group_name: 'admins',
                acl: 'user::rwx,group::r--,other::r--',
                created: '2018-12-13T23:26:18.826Z',
                disabled: false,
                first_name: 'System',
                last_failed_login: '',
                last_login: '2023-03-27T18:10:11.706Z',
                last_name: 'User',
                n_failed_logins: 0,
                origin: 'CmonDb',
                password_encrypted:
                    '43030e3067de2de956046fcc97ec13337b5ec2f51efbc66ebfe88f913d4339c4',
                password_format: 'sha256',
                password_salt: '75b76a66-f0ee-4cf6-8f28-480ed0d4809a',
                suspended: false,
                user_id: 1,
                user_name: 'system',
                groups: [
                    {
                        class_name: 'CmonGroup',
                        cdt_path: '/groups',
                        owner_user_id: 1,
                        owner_user_name: 'system',
                        owner_group_id: 1,
                        owner_group_name: 'admins',
                        group_id: 1,
                        group_name: 'admins',
                    },
                ],
                timezone: {
                    class_name: 'CmonTimeZone',
                    name: 'Pacific Standard Time',
                    abbreviation: 'PST',
                    offset: 28800,
                    use_dst: false,
                },
            },
        },
    ],
};

export default result;
