import { ButtonProps } from 'antd/lib/button';
import React from 'react';
import CcCluster from '../../../services/models/CcCluster';
import ButtonWithForm from '../../../common/General/ButtonWithForm';
import BackupSettingsModalForm from '../../Backups/BackupSettingsModalForm';
import ActionButton from '@severalnines/bar-frontend-components/build/lib/General/ActionButton';
export default BackupSettingsButton;

export type BackupSettingsButtonPros = ButtonProps & {
    cluster: CcCluster;
};
function BackupSettingsButton({
    cluster,
    children,
    ...rest
}: BackupSettingsButtonPros) {
    return (
        <ButtonWithForm
            button={<ActionButton {...rest}>Backup settings</ActionButton>}
            form={<BackupSettingsModalForm cluster={cluster} />}
        />
    );
}
