export function getSortAlphabeticFn(
    direction: string,
    getA?: (a: any) => string,
    getB?: (b: any) => string
) {
    return (a: any, b: any) => {
        const valueA = getA ? getA(a) : a;
        const valueB = getB ? getB(b) : getA ? getA(b) : b;

        return direction === 'descend'
            ? valueB.toLowerCase().localeCompare(valueA.toLowerCase())
            : valueA.toLowerCase().localeCompare(valueB.toLowerCase());
    };
}

export function getSortDateFn(
    direction: string,
    getA: (a: any) => Date,
    getB?: (b: any) => Date
) {
    return (a: any, b: any) => {
        const valueA = getA ? getA(a) : a;
        const valueB = getB ? getB(b) : getA ? getA(b) : b;

        return direction === 'descend'
            ? valueB.getTime() - valueA.getTime()
            : valueA.getTime() - valueB.getTime();
    };
}

export function getSortNumberFn(
    direction: string,
    getA: (a: any) => number,
    getB?: (b: any) => number
) {
    return (a: any, b: any) => {
        const valueA = getA ? getA(a) : a;
        const valueB = getB ? getB(b) : getA ? getA(b) : b;

        return direction === 'descend' ? valueB - valueA : valueA - valueB;
    };
}

/**
 * Sorts a collection by the value of a field given a sortOrder array of values
 * @example: fn({field: 'a'}, {field: 'b'}, {field: 'c'}, 'field', ['c', 'a'])
 * => [{field: 'c'}, {field: 'a'}, {field: 'b'}]
 */
export function collectionKeyValueSort(
    collection: { [key: string]: any }[],
    key: string,
    sortOrder: string[] = []
) {
    return sortByOrderedValues(collection, (item) => item[key], sortOrder);
}

export function sortByOrderedValues(
    collection: any[],
    getValue: (item: any) => any,
    sortOrder: any[] = []
) {
    const firstPart = collection
        .filter((item) => sortOrder.includes(getValue(item)))
        .sort(
            (itemA, itemB) =>
                sortOrder.indexOf(getValue(itemA)) -
                sortOrder.indexOf(getValue(itemB))
        );

    const secondPart = collection
        .filter((item) => !sortOrder.includes(getValue(item)))
        .sort((itemA, itemB) => getValue(itemA) - getValue(itemB));

    return firstPart.concat(secondPart);
}
