import CcGroup from '../../../../services/models/CcGroup';

const result = {
    total: 7,
    groups: [
        new CcGroup({
            class_name: 'CmonGroup',
            cdt_path: '/groups',
            owner_user_id: 1,
            owner_user_name: 'system',
            owner_group_id: 1,
            owner_group_name: 'admins',
            acl: 'user::rwx,group::rwx,other::---',
            created: '2022-02-02T13:11:15.734Z',
            effective_privileges: 'rwx',
            group_id: 1,
            group_name: 'admins',
        }),
        new CcGroup({
            class_name: 'CmonGroup',
            cdt_path: '/groups',
            owner_user_id: 1,
            owner_user_name: 'system',
            owner_group_id: 1,
            owner_group_name: 'admins',
            acl: 'user::rwx,group::rwx,other::---',
            created: '2022-02-02T13:11:15.736Z',
            effective_privileges: 'rwx',
            group_id: 2,
            group_name: 'users',
        }),
        new CcGroup({
            class_name: 'CmonGroup',
            cdt_path: '/groups',
            owner_user_id: 1,
            owner_user_name: 'system',
            owner_group_id: 1,
            owner_group_name: 'admins',
            acl: 'user::rwx,group::rwx,other::---',
            created: '2022-02-02T13:11:15.738Z',
            effective_privileges: 'rwx',
            group_id: 3,
            group_name: 'nobody',
        }),
        new CcGroup({
            class_name: 'CmonGroup',
            cdt_path: '/groups',
            owner_user_id: 5,
            owner_user_name: 'pablo',
            owner_group_id: 1,
            owner_group_name: 'admins',
            acl: 'user::rwx,group::rwx,other::---',
            created: '2022-09-12T18:16:59.975Z',
            effective_privileges: 'rwx',
            group_id: 4,
            group_name: 'groupFullAccess',
        }),
        new CcGroup({
            class_name: 'CmonGroup',
            cdt_path: '/groups',
            owner_user_id: 5,
            owner_user_name: 'pablo',
            owner_group_id: 1,
            owner_group_name: 'admins',
            acl: 'user::rwx,group::rwx,other::---',
            created: '2022-09-13T01:11:00.948Z',
            effective_privileges: 'rwx',
            group_id: 5,
            group_name: 'groupViewAccess',
        }),
        new CcGroup({
            class_name: 'CmonGroup',
            cdt_path: '/groups',
            owner_user_id: 5,
            owner_user_name: 'pablo',
            owner_group_id: 1,
            owner_group_name: 'admins',
            acl: 'user::rwx,group::rwx,other::---',
            created: '2022-09-15T10:52:58.438Z',
            effective_privileges: 'rwx',
            group_id: 9,
            group_name: 'groupNoAccess',
        }),
        new CcGroup({
            class_name: 'CmonGroup',
            cdt_path: '/groups',
            owner_user_id: 5,
            owner_user_name: 'pablo',
            owner_group_id: 1,
            owner_group_name: 'admins',
            acl: 'user::rwx,group::rwx,other::---',
            created: '2022-09-15T11:01:36.908Z',
            effective_privileges: 'rwx',
            group_id: 10,
            group_name: 'groupCM1V2Access',
        }),
    ],
};

export default result;
