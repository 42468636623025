import CmonUser, { CmonUserProps } from '../cmon/models/CmonUser';
import CmonTimeZone from '../cmon/models/CmonTimeZone';
import { CMON_TIMEZONES_ABBR_LIST } from './CcTimezones';
import CcGroup, { CcGroupProps } from './CcGroup';

export const SYSTEM_USER_NAME = 'system';
export const CCRPC_USER_NAME = 'ccrpc';
export const ADMIN_USER_NAME = 'admin';
export const NOBODY_USER_NAME = 'nobody';
export const CMON_SYSTEM_USER_ID = 1;

export type CcUserProps = Omit<CmonUserProps, 'groups'> & {
    timezone?: CmonTimeZone;
    groups?: CcGroupProps[];
};

export default class CcUser extends CmonUser {
    public timezone?: CmonTimeZone | any;
    public userGroups?: CcGroup[];

    constructor(props: CcUserProps) {
        super(props);
        this.timezone = props.timezone as CmonTimeZone;
        if (
            this.timezone?.name &&
            CMON_TIMEZONES_ABBR_LIST[this.timezone?.name]
        ) {
            this.timezone.name = CMON_TIMEZONES_ABBR_LIST[this.timezone?.name];
        }
        this.userGroups = (props.groups || []).map(
            (group) => new CcGroup(group)
        );
    }

    isSuperUser(): boolean {
        // 🦸‍
        if (
            this.userId === CMON_SYSTEM_USER_ID ||
            (this.userGroups &&
                this.userGroups.filter((group) => group.isAdminsGroup())
                    .length > 0)
        ) {
            return true;
        }
        return false;
    }

    getName(): string {
        return (
            `${this.firstName || ''} ${this.lastName || ''}`.trim() ||
            this.userName ||
            ''
        );
    }

    getGroup(): CcGroup {
        return this.userGroups?.[0] as CcGroup;
    }

    hasName(): boolean {
        return !!this.firstName || !!this.lastName;
    }

    getKey(): string {
        return `${this.userId}`;
    }

    isForbiddenUser(): boolean {
        return [
            SYSTEM_USER_NAME,
            ADMIN_USER_NAME,
            CCRPC_USER_NAME,
            NOBODY_USER_NAME,
        ].includes(this.userName!);
    }
}
