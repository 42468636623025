import CmonReport, { CmonReportProps } from '../cmon/models/CmonReport';

export type CcReportProps = CmonReportProps & {
};

export default class CcReport extends CmonReport {
    constructor(props: CmonReportProps) {
        super(props);
    }

    getKey(): string {
        return `${this.reportId}`;
    }
}
