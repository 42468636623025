import './SquareStatusBadge.less';
import React from 'react';
import classNames from 'classnames';
import StatisticBadge from './StatisticBadge';

export type SquareBorderIconProps = {
    count?: number;
    status?: 'error' | 'success' | 'info' | 'warning' | 'unknown';
    className?: string;
    text?: string;
    children?: any;
};
export default function SquareStatusBadge({
    count = 0,
    status,
    className,
    text,
    children,
    ...rest
}: SquareBorderIconProps) {
    return (
        <div
            className={classNames(
                'SquareStatusBadge',
                `SquareStatusBadge--status-${status}`,
                className
            )}
        >
            <div style={{ marginBottom: '10px' }}>
                <StatisticBadge
                    count={count}
                    dot={false}
                    size={'default'}
                    status={'error'}
                    {...rest}
                >
                    <div
                        className={classNames(
                            'SquareStatusBadge_content',
                            `SquareStatusBadge_content--status-${status}`,
                            className
                        )}
                    >
                        {children}
                    </div>
                </StatisticBadge>
            </div>
            <span>{text}</span>
        </div>
    );
}
