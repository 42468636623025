import React, { useEffect, useMemo, useState } from 'react';
import ModalDefaultForm from '../../../common/ModalDefaultForm';
import { FormInstance } from 'antd/es';
import { Form } from 'antd';
import useLdapSettings from './useLdapSettings';
import WizardFormConfiguration from '@severalnines/bar-frontend-components/build/lib/Navigation/Wizard/WizardFormConfiguration';
import { LdapConfig } from './LdapTypes';
import LdapSettingsGeneralForm, {
    getLdapSettingsGeneralFormValidate,
} from './LdapSettingsGeneralForm';
import LdapSettingsAdvancedForm from './LdapSettingsAdvancedForm';
import LdapSettingsSslTlsForm, {
    getLdapSettingsSslTlsFormValidate,
} from './LdapSettingsSslTlsForm';
import {
    notifyError,
    notifyOperationSuccess,
    NotifyType,
} from '../../Notifications/uiNotification';

export default LdapSettingsModal;

enum LdapSettingsModalFormSteps {
    GENERAL = 'GENERAL',
    ADVANCED = 'ADVANCED',
    SSL_TLS = 'SSL_TLS',
}

export type LdapSettingsModalProps = {
    onCancel?: () => void;
    onSuccess?: () => void;
    onError?: (err: any) => void;
    form?: FormInstance;
};

export interface LdapSettingsModalFormValues extends LdapConfig {}

function LdapSettingsModal({
    onCancel,
    onError,
    onSuccess,
    form: parentForm,
    ...rest
}: LdapSettingsModalProps) {
    const [internalForm] = Form.useForm<LdapSettingsModalFormValues>();
    const form = parentForm || internalForm;
    const [loading, setLoading] = useState(false);

    const {
        loading: loadingSettings,
        record: settings,
        refresh: refreshSettings,
        save: saveSettings,
        defaults: defaultSettings,
    } = useLdapSettings();

    useEffect(() => {
        (async () => {
            await refreshSettings();
        })();
    }, []);

    const steps = useMemo(() => {
        return [
            <WizardFormConfiguration.Step
                key={LdapSettingsModalFormSteps.GENERAL}
                title="General"
                subTitle=" "
                validate={getLdapSettingsGeneralFormValidate()}
                hasRequiredFields={true}
            >
                <LdapSettingsGeneralForm defaultSettings={defaultSettings} />
            </WizardFormConfiguration.Step>,
            <WizardFormConfiguration.Step
                key={LdapSettingsModalFormSteps.ADVANCED}
                title="Advanced"
                subTitle=" "
                validate={getLdapSettingsGeneralFormValidate()}
            >
                <LdapSettingsAdvancedForm
                    defaultSettings={defaultSettings}
                    form={form}
                />
            </WizardFormConfiguration.Step>,
            <WizardFormConfiguration.Step
                key={LdapSettingsModalFormSteps.SSL_TLS}
                title="SSL/TLS"
                subTitle=" "
                validate={getLdapSettingsSslTlsFormValidate()}
            >
                <LdapSettingsSslTlsForm defaultSettings={defaultSettings} />
            </WizardFormConfiguration.Step>,
        ];
    }, [defaultSettings]);

    const initialValues = useMemo(
        () => ({
            ...settings,
            ldapSettings: {
                ...settings?.ldapSettings,
                ldapNetworkTimeout:
                    settings?.ldapSettings?.ldapNetworkTimeout ||
                    defaultSettings?.ldapSettings?.ldapNetworkTimeout,
                ldapProtocolVersion:
                    settings?.ldapSettings?.ldapProtocolVersion ||
                    defaultSettings?.ldapSettings?.ldapProtocolVersion,
                ldapQueryTimeLimit:
                    settings?.ldapSettings?.ldapQueryTimeLimit ||
                    defaultSettings?.ldapSettings?.ldapQueryTimeLimit,
            },
        }),
        [settings, defaultSettings]
    );

    const handleSubmit = async () => {
        const newSettings = form.getFieldsValue(true);
        try {
            setLoading(true);
            await saveSettings(newSettings);
            setLoading(false);
            notifyOperationSuccess({
                type: NotifyType.TOAST,
                title: <span>LDAP settings updated succesfully.</span>,
            });
            onSuccess?.();
        } catch (e) {
            notifyError({
                content: e.message,
            });
            setLoading(false);
            onError?.(e);
        }
    };

    return (
        <ModalDefaultForm
            title={`LDAP settings`}
            width={900}
            form={form}
            onCancel={onCancel}
            defaultVisible={true}
            bodyStyle={{
                padding: '0',
                paddingLeft: '5px',
                minHeight: '400px',
            }}
            loading={loading || loadingSettings}
            shakeWizardOnValidationError={true}
            showConfirmClose={true}
            {...rest}
        >
            {settings && defaultSettings ? (
                <WizardFormConfiguration
                    form={form}
                    loading={loading}
                    steps={steps}
                    onSubmit={handleSubmit}
                    showCancelButton
                    cancelButtonText="Cancel"
                    onCancel={onCancel}
                    initialValues={initialValues}
                    saveMode={true}
                    {...rest}
                />
            ) : null}
        </ModalDefaultForm>
    );
}
