import { SpaceProps } from 'antd/lib/space';
import { Button, Space } from 'antd';
import { ReactNode } from 'react';

export default InlineSelect;
export type InlineSelectOption = {
    value: any;
    label: ReactNode;
};
export type InlineSelectProps = SpaceProps & {
    options: InlineSelectOption[];
    value?: any;
    onChange?: (value: any) => void;
};
function InlineSelect({
    options,
    value,
    onChange,
    ...rest
}: InlineSelectProps) {
    const handlePress = (value: any) => {
        onChange?.(value);
    };
    return (
        <Space {...rest}>
            {options.map(({ value: val, label }) => (
                <Button
                    key={val}
                    type={val === value ? 'default' : 'text'}
                    onClick={() => handlePress(val)}
                >
                    {label}
                </Button>
            ))}
        </Space>
    );
}
