import React, { useRef } from 'react';
import SpaceWide from '../../../common/SpaceWide';
import ButtonWithForm from '../../../common/General/ButtonWithForm';
import { Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import CloudCredentialsModalAdd from './CloudCredentialsModalAdd';
import CloudCredentialsList, {
    CloudCredentialsListApi,
} from './CloudCredentialsList';

export default CloudCredentialsPage;
export type CloudCredentialsPageProps = {};

function CloudCredentialsPage({ ...rest }: CloudCredentialsPageProps) {
    const listRef = useRef<CloudCredentialsListApi>();
    const handleAddCredentialsSuccess = () => {
        listRef.current?.refresh();
    };
    return (
        <SpaceWide direction="vertical" size={20}>
            <SpaceWide direction="vertical" align="end">
                <ButtonWithForm
                    button={
                        <Button type="primary" icon={<PlusOutlined />}>
                            Add cloud storage credentials
                        </Button>
                    }
                    form={
                        <CloudCredentialsModalAdd
                            onSuccess={handleAddCredentialsSuccess}
                        />
                    }
                />
            </SpaceWide>
            <CloudCredentialsList ref={listRef} />
        </SpaceWide>
    );
}
