import React from 'react';
import ServiceMockProvider from './serviceMock/ServiceMockContext';
import DebugComponents from './DebugComponents';

export default DebugComponentsPage;
export type DebugComponentsPageProps = {};

function DebugComponentsPage({ ...rest }: DebugComponentsPageProps) {
    return (
        <ServiceMockProvider>
            <DebugComponents />
        </ServiceMockProvider>
    );
}
