import CcBackupStorageLocation, {
    CcBackupStorageLocationProps,
    CcBackupStorageLocationType,
} from './CcBackupStorageLocation';
import { CcCloudProviderType } from './CcCloudCredentials';

export interface CcBackupCloudStorageLocationProps
    extends CcBackupStorageLocationProps {
    cloud_location_uuid?: string;
    bucket_and_path?: string;
    credentials_id?: number;
    provider?: string;
    storage_service?: string;
}

export default class CcBackupCloudStorageLocation extends CcBackupStorageLocation {
    public readonly cloudLocationUuid?: string;
    public readonly bucketAndPath?: string;
    public readonly credentialsId?: number;
    public readonly provider: CcCloudProviderType;
    public readonly storageService?: string;

    constructor(props: CcBackupCloudStorageLocationProps) {
        super({ ...props, type: CcBackupStorageLocationType.CLOUD });
        this.cloudLocationUuid = props.cloud_location_uuid;
        this.bucketAndPath = props.bucket_and_path;
        this.credentialsId = props.credentials_id;
        this.provider = props.provider as CcCloudProviderType;
        this.storageService = props.storage_service;
    }

    public getPath(): string {
        return this.bucketAndPath || '';
    }
    public getUuid(): string {
        return this.cloudLocationUuid || '';
    }
}
