import PostgreSqlImportConfigurator from '../PostgreSql/PostgreSqlImportConfigurator';
import TimescaleDbConfigurator from '../../TimescaleDb/TimescaleDbConfigurator';
import merge from 'deepmerge';
import PostgreSqlConfigurator, {
    PostgreSqlFormValues,
} from '../../PostgreSql/PostgreSqlConfigurator';

export default class TimescaleDbImportConfigurator extends PostgreSqlImportConfigurator {
    public static getVendors() {
        return TimescaleDbConfigurator.getVendors();
    }

    public static getJobOptions(formValues: PostgreSqlFormValues): any {
        return merge(PostgreSqlConfigurator.getJobOptions(formValues), {
            job: {
                title: 'Import TimescaleDB Cluster',
            },
        });
    }
}
