import React, { useState } from 'react';
import ModalDefaultForm from '../../common/ModalDefaultForm';
import useForm from 'antd/lib/form/hooks/useForm';
import { Form, Alert, Input, Layout, Row, Col, Switch } from 'antd';
import FormFooter from '../../common/FormFooter';
import KeyManagementTree from './KeyManagementTree';
import CmonCaService from '../../services/cmon/CmonCaService';
import {
    notifyError,
    notifyOperationSuccess,
    NotifyType,
} from '../Notifications/uiNotification';
import { useDebugContext } from '../../common/Debug';

const { Sider, Content } = Layout;

export default KeyManagementImportCertificateModal;

type KeyManagementImportCertificateModalFields = {
    saveAs: string;
    certificateFile: string;
    privateKey: string;
    selfSignedCertificate: boolean;
    certificateCAFile: string;
};

export type KeyManagementImportCertificateModalProps = {
    list: any;
    onCancel?: () => void;
    onSuccess?: () => void;
};

function KeyManagementImportCertificateModal({
    list,
    onCancel,
    onSuccess,
}: KeyManagementImportCertificateModalProps) {
    const [form] = useForm<KeyManagementImportCertificateModalFields>();
    const [destinationPath, setDestinationPath] = useState<string>('');
    const [selfSigned, setSelfSigned] = useState<boolean>(true);
    const [loading, setLoading] = useState<boolean>(false);
    const { log } = useDebugContext();

    const handleTreeItemSelect = (node: any) => {
        setDestinationPath(node.path ? `${node.path}/` : '');
    };

    const handleSubmit = async ({
        certificateFile,
        privateKey,
        saveAs,
        certificateCAFile,
    }: KeyManagementImportCertificateModalFields) => {
        try {
            setLoading(true);
            await CmonCaService.importlocal({
                cert_file: certificateFile,
                key_file: privateKey,
                name: `${destinationPath}${saveAs}`,
                ca_file: !selfSigned ? certificateCAFile : undefined,
            });
            setLoading(false);
            onSuccess?.();
            notifyOperationSuccess({
                type: NotifyType.TOAST,
                title: 'Success!',
                content: 'Changes are saved successfully!',
            });
        } catch (err) {
            setLoading(false);
            notifyError({ content: err.message });
            log.error(err);
        }
    };

    const handleChange = (value: boolean) => {
        setSelfSigned(value);
    };

    return (
        <ModalDefaultForm
            title={`Import certificate`}
            width={944}
            form={form}
            onCancel={onCancel}
            defaultVisible={true}
            bodyStyle={{ padding: '20px' }}
            loading={loading}
        >
            <Form form={form} onFinish={handleSubmit} layout="vertical">
                <Layout>
                    <Sider
                        width={260}
                        style={{
                            background: '#fff',
                            borderRight: '1px solid #ccc',
                            paddingRight: '5px',
                        }}
                    >
                        <KeyManagementTree
                            list={list}
                            onSelect={handleTreeItemSelect}
                        />
                    </Sider>
                    <Content
                        style={{
                            padding: '0 24px',
                            minHeight: 280,
                            background: '#fff',
                        }}
                    >
                        <Alert
                            showIcon
                            style={{ marginBottom: '10px' }}
                            message={
                                <ol>
                                    <li>
                                        Upload your certificate and key to a
                                        directory on the ClusterControl
                                        Controller host
                                    </li>
                                    <li>
                                        Uncheck the self-signed certificate
                                        checkbox if the certificate is not
                                        self-signed
                                    </li>
                                    <li>
                                        You need to also provide a CA
                                        certificate if the certificate is not
                                        self-signed
                                    </li>
                                    <li>
                                        Duplicate certificates will not be
                                        created
                                    </li>
                                </ol>
                            }
                        ></Alert>
                        <Alert
                            type="success"
                            style={{
                                marginBottom: '10px',
                                backgroundColor: '#F5F5F5',
                                borderColor: '#F5F5F5',
                            }}
                            message={
                                <>
                                    <b>Destination path: </b>{' '}
                                    <span>
                                        /var/lib/cmon/ca/{destinationPath}
                                    </span>
                                </>
                            }
                        ></Alert>
                        <Row gutter={[24, 0]}>
                            <Col xs={12} sm={12} md={12}>
                                <Form.Item
                                    name="saveAs"
                                    label="Save as"
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                'Enter name w/o extensions, eg. cluster1',
                                        },
                                    ]}
                                >
                                    <Input placeholder="Name your certificate" />
                                </Form.Item>
                            </Col>
                            <Col xs={12} sm={12} md={12}>
                                <Form.Item
                                    name="certificateFile"
                                    label="Certificate file"
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                'Enter the absolute path of certificate file!',
                                        },
                                    ]}
                                >
                                    <Input placeholder="Absolute path to certificate file" />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={[24, 0]}>
                            <Col xs={12} sm={12} md={12}>
                                <Form.Item
                                    name="privateKey"
                                    label="Private key"
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                'Enter the absolute path of key file!',
                                        },
                                    ]}
                                >
                                    <Input placeholder="Absolute path to key file" />
                                </Form.Item>
                            </Col>
                            <Col xs={12} sm={12} md={12}>
                                <Form.Item
                                    name="selfSignedCertificate"
                                    label="Self signed certificate"
                                >
                                    <Switch
                                        onChange={handleChange}
                                        checked={selfSigned}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        {!selfSigned ? (
                            <Row gutter={[24, 0]}>
                                <Col xs={12} sm={12} md={12}>
                                    <Form.Item
                                        name="certificateCAFile"
                                        label="Certificate CA file"
                                        rules={[
                                            {
                                                required: true,
                                                message:
                                                    'Enter the absolute path of certificate CA file!',
                                            },
                                        ]}
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>
                            </Row>
                        ) : (
                            ''
                        )}
                    </Content>
                </Layout>
                <FormFooter
                    loading={loading}
                    submitButtonText="Import"
                    showCancelButton
                    showSubmitButton
                    onCancel={onCancel}
                />
            </Form>
        </ModalDefaultForm>
    );
}
