export function digestTextToMatchPattern(digestText?: string) {
    const pattern = digestText
        ? digestText
              .split('*')
              .join('\\*')
              .split('(')
              .join('\\(')
              .split(')')
              .join('\\)')
              .split('+')
              .join('\\+')
              .split('?')
              .join('.*')
        : '';

    return `^${pattern}`;
}
