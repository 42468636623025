import React from 'react';
import { Button, Col, Form, Row } from 'antd';
import FormFooter from '../../../common/FormFooter';
import ModalDefaultForm from '../../../common/ModalDefaultForm';
import CcNode from '../../../services/models/CcNode';
import NodeFormat from '../NodeFormat';
import SpaceWide from '../../../common/SpaceWide';
import useCreateJob from '../../Jobs/useCreateJob';
import { CmonJobInstanceCommand } from '../../../services/cmon/models/CmonJobInstance';
import Alert from '@severalnines/bar-frontend-components/build/lib/Feedback/Alert';
import RebuildFromBackupSelectField from '../../Clusters/Actions/AddNode/FormParts/RebuildFromBackupSelectField';
import CcBackup from '../../../services/models/CcBackup';

export default NodeResyncForm;

export type NodeResyncFormProps = {
    node: CcNode;
    onSuccess?: () => void;
    onCancel?: () => void;
};
export type NodeResyncFormValues = {
    initialStart?: boolean;
    stopTimeout?: number;
    forceStop?: boolean;
    backup?: CcBackup;
};

function NodeResyncForm({ node, onSuccess, onCancel }: NodeResyncFormProps) {
    const [form] = Form.useForm<NodeResyncFormValues>();
    const { loading, send } = useCreateJob({
        clusterId: node.clusterid,
        title: 'Resync node',
        // Resync node is just Restart node job but with initial: true by default.
        command: CmonJobInstanceCommand.RESTART,
        onSuccess,
    });

    const handleSubmit = async (values: NodeResyncFormValues) => {
        await send({
            initial: true,
            hostname: node.hostname,
            port: node.port,
            backupid: values.backup?.getId(),
        });
    };
    return (
        <ModalDefaultForm
            title="Resync node"
            form={form}
            footer={[]}
            onCancel={onCancel}
            width={450}
            defaultVisible={true}
        >
            <Form
                className="NodeResyncForm"
                form={form}
                layout="vertical"
                onFinish={handleSubmit}
                initialValues={{
                    initialStart: false,
                    stopTimeout: 1800,
                    forceStop: false,
                }}
            >
                <Row gutter={24}>
                    <Col span={24}>
                        <SpaceWide direction="vertical">
                            <NodeFormat
                                node={node}
                                size="large"
                                contentProps={{ style: { width: '100%' } }}
                            />
                            <RebuildFromBackupSelectField
                                clusterId={node.clusterid!}
                            />
                            <Alert
                                message="Resync Node: removes all files in the datadir and forces a full resync (SST) of the node. This is necessary sometimes if the galera node is trying Node Recovery multiple times and there is e.g., a filesystem issue"
                                showIcon={true}
                            />
                            <Alert
                                message="Wait for completion before starting another node with Initial Start"
                                showIcon={true}
                            />
                        </SpaceWide>
                    </Col>
                </Row>
                <FormFooter>
                    <Button
                        key="done"
                        type="primary"
                        htmlType="submit"
                        loading={loading}
                    >
                        Resync
                    </Button>
                </FormFooter>
            </Form>
        </ModalDefaultForm>
    );
}
