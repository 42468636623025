import React from 'react';
import ActionsMenu, {
    ActionsMenuProps,
} from '../../common/Navigation/ActionsMenu';
import CcJob, { CcJobStatus } from '../../services/models/CcJob';
import JobDetailsButton from './JobDetailsButton';
import JobRetryButton from './JobRetryButton';
import { ActionMenuItem } from '@severalnines/bar-frontend-components/build/lib/Navigation/ActionMenu';
import useCurrentUser from '../User/useCurrentUser';
import CcCluster from '../../services/models/CcCluster';
import { CmonJobInstanceCommand } from '../../services/cmon/models/CmonJobInstance';
import JobDeleteButton from './JobDeleteButton';

export default JobActionsMenu;

export type JobActionsMenuProps = ActionsMenuProps & {
    job: CcJob;
    cluster?: CcCluster;
};

function JobActionsMenu({
    job,
    cluster,
    onActionPerformed,
    ...rest
}: JobActionsMenuProps) {
    const { canManageCluster, canExecuteClusters } = useCurrentUser();
    const handleJobRetrySuccess = () => {
        if (onActionPerformed) {
            onActionPerformed();
        }
    };

    const canRetryJob = () => {
        if ([CcJobStatus.FAILED, CcJobStatus.ABORTED].includes(job.status)) {
            if (
                [
                    CmonJobInstanceCommand.CREATE_CLUSTER,
                    CmonJobInstanceCommand.ADD_CLUSTER,
                ].includes(job.spec?.command) &&
                canExecuteClusters()
            ) {
                // user can execute clusters
                return true;
            } else if (
                canManageCluster(cluster?.clusterId as number) &&
                job.userName !== 'system'
            ) {
                // user can manage cluster and job triggerer is not system
                return true;
            } else {
                return false;
            }
        }
        return false;
    };

    const canDeleteJob = () => {
        if (job.canBeDeleted) {
            if (
                [
                    CmonJobInstanceCommand.CREATE_CLUSTER,
                    CmonJobInstanceCommand.ADD_CLUSTER,
                ].includes(job.spec?.command) &&
                canExecuteClusters()
            ) {
                // user can execute clusters
                return true;
            } else if (canManageCluster(cluster?.clusterId as number)) {
                // user can manage cluster
                return true;
            } else {
                return false;
            }
        }
        return false;
    };
    return (
        <ActionsMenu
            items={
                [
                    {
                        key: 'details',
                        label: <JobDetailsButton job={job} />,
                    },
                    canRetryJob()
                        ? {
                              key: 'retry',
                              waitForConfirm: true,
                              label: (
                                  <JobRetryButton
                                      job={job}
                                      onSuccess={handleJobRetrySuccess}
                                  />
                              ),
                          }
                        : null,

                    canDeleteJob()
                        ? {
                              key: 'delete',
                              waitForConfirm: true,
                              label: (
                                  <JobDeleteButton
                                      job={job}
                                      onSuccess={handleJobRetrySuccess}
                                  />
                              ),
                          }
                        : null,
                ] as ActionMenuItem[]
            }
            {...rest}
        />
    );
}
