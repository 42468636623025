import React, { useState } from 'react';
import { ButtonProps } from 'antd/lib/button';
import CcNode from '../../services/models/CcNode';
import CcCluster from '../../services/models/CcCluster';
import {
    notifyError,
    notifyOperationSuccess,
    NotifyType,
} from '../Notifications/uiNotification';
import CmonMaintenanceService from '../../services/cmon/CmonMaintenanceService';
import { useDebugContext } from '../../common/Debug';
import { CcMaintenancePeriod } from '../../services/models/CcMaintenanceInfo';
import useScheduledMaintenancesList from './useScheduledMaintenancesList';
import AppConfirmActionButton from '../../common/General/AppConfirmActionButton';

export default DisableMaintenanceButton;

export type DisableMaintenanceButtonProps = ButtonProps & {
    cluster?: CcCluster;
    node?: CcNode;
    period?: CcMaintenancePeriod;
    children?: React.ReactNode;
    onSuccess?: () => void;
    onError?: (err: Error) => void;
};

function DisableMaintenanceButton({
    cluster,
    node,
    period,
    children,
    onSuccess,
    onError,
    ...rest
}: DisableMaintenanceButtonProps) {
    const { log } = useDebugContext();
    const [loading, setLoading] = useState(false);
    const {
        refresh: refreshScheduledMaintenances,
    } = useScheduledMaintenancesList({
        name: 'scheduled-maintenances-list-from-DisableMaintenanceButton',
    });
    const handleConfirm = async () => {
        try {
            setLoading(true);
            let periodToDelete = period;
            if (!periodToDelete) {
                const {
                    found_maintenance: foundMaintenance,
                    maintenance_period: maintenancePeriod,
                } = await CmonMaintenanceService.getCurrentMaintenance({
                    cluster_id: cluster?.clusterId,
                    hostname: node?.hostname,
                });
                if (foundMaintenance) {
                    periodToDelete = new CcMaintenancePeriod(maintenancePeriod);
                }
            }

            if (periodToDelete) {
                await CmonMaintenanceService.removeMaintenance({
                    UUID: periodToDelete.uuid,
                });
            }
            await refreshScheduledMaintenances();
            notifyOperationSuccess({
                type: NotifyType.TOAST,
                title: period ? (
                    <span>Deleted scheduled maintenance</span>
                ) : (
                    <span>
                        Stopped maintenance for{' '}
                        {node ? node.getName() : cluster?.clusterName}
                    </span>
                ),
            });
            setLoading(false);
            onSuccess?.();
        } catch (err) {
            log.error(err);
            setLoading(false);
            notifyError({
                content: err.message,
            });
            onError?.(err);
        }
    };
    return (
        <AppConfirmActionButton
            confirmTitle={
                period
                    ? `Delete scheduled maintenance?`
                    : `Stop current maintenance for: ${
                          node ? node.getName() : cluster?.clusterName
                      }?`
            }
            title={
                period
                    ? `Delete scheduled maintenance`
                    : `Stop current maintenance`
            }
            onConfirm={handleConfirm}
            loading={loading}
            critical={!!period}
            {...rest}
        >
            {children ||
                (period
                    ? 'Delete scheduled mainteance'
                    : 'Stop current maintenance')}
        </AppConfirmActionButton>
    );
}
