import { createStore, Store } from 'redux';
import Immutable from 'immutable';
import CcController from './services/models/CcController';
import CcClusterStats from './services/models/CcClusterStats';
import CcCluster from './services/models/CcCluster';
import CcAlarmStats from './services/models/CcAlarmStats';
import CcControllerStats from './services/models/CcControllerStats';
import CcJobStats from './services/models/CcJobStats';
import CcBackupStats from './services/models/CcBackupStats';
import CcUser from './services/models/CcUser';
import CcNodeStats from './services/models/CcNodeStats';
import CcJob, { CcJobProps } from './services/models/CcJob';
import CcAlarm from './services/models/CcAlarm';
import CcLicenseInfo from './services/models/CcLicenseInfo';
import CcLogStats from './services/models/CcLogStats';
import { CcNodeType } from './services/models/CcNode';
import CcMaintenanceInfo from './services/models/CcMaintenanceInfo';
import CcTreeItem from './services/models/CcTreeItem';
import { camelCasePropToUnderscore } from './common/helpers';
import { AppConfig } from './AppConfig';

const SET_USER = 'cmon-proxy-fe/app/SET_USER';
const SET_LICENSE_INFO = 'cmon-proxy-fe/app/SET_LICENSE_INFO';
const SET_CONTROLLERS = 'cmon-proxy-fe/app/SET_CONTROLLERS';
const SET_CONTROLLER_STATS = 'cmon-proxy-fe/app/SET_CONTROLLER_STATS';
const SET_CLUSTER_STATS = 'cmon-proxy-fe/app/SET_CLUSTER_STATS';
const SET_CLUSTERS = 'cmon-proxy-fe/app/SET_CLUSTERS';
const SET_CLUSTER = 'cmon-proxy-fe/app/SET_CLUSTER';
const SET_NODE_STATS = 'cmon-proxy-fe/app/SET_NODE_STATS';
const SET_ALARM_STATS = 'cmon-proxy-fe/app/SET_ALARM_STATS';
const SET_TECHNOLOGIES = 'cmon-proxy-fe/app/SET_TECHNOLOGIES';
const SET_NODE_TYPES = 'cmon-proxy-fe/app/SET_NODE_TYPES';
const SET_TAGS = 'cmon-proxy-fe/app/SET_TAGS';
const SET_GLOBAL_FILTERS = 'cmon-proxy-fe/app/SET_GLOBAL_FILTERS';
const SET_JOB_STATS = 'cmon-proxy-fe/app/SET_JOB_STATS';
const SET_LOG_STATS = 'cmon-proxy-fe/app/SET_LOG_STATS';
const SET_RUNNING_JOBS = 'cmon-proxy-fe/app/SET_RUNNING_JOBS';
const ADD_NEW_RUNNING_JOB = 'cmon-proxy-fe/app/ADD_NEW_RUNNING_JOB';
const SET_CURRENT_ALARMS = 'cmon-proxy-fe/app/SET_CURRENT_ALARMS';
const SET_SCHEDULED_MAINTENANCES =
    'cmon-proxy-fe/app/SET_SCHEDULED_MAINTENANCES';
const SET_BACKUP_STATS = 'cmon-proxy-fe/app/SET_BACKUP_STATS';
const OPEN_ACTIVITY_QUICK_VIEW = 'cmon-proxy-fe/app/OPEN_ACTIVITY_QUICK_VIEW';
const CLOSE_ACTIVITY_QUICK_VIEW = 'cmon-proxy-fe/app/CLOSE_ACTIVITY_QUICK_VIEW';
const SET_USER_UI_CONFIG = 'cmon-proxy-fe/app/SET_USER_UI_CONFIG';
const UPDATE_USER_UI_CONFIG = 'cmon-proxy-fe/app/UPDATE_USER_UI_CONFIG';
const SET_TREE = 'cmon-proxy-fe/app/SET_TREE';

export type AppStateUser = CcUser | undefined;
export type AppStateLicenseInfo = CcLicenseInfo | undefined;
export type AppStateControllersMap = Immutable.Map<string, CcController>;
export type AppStateClustersMap = Immutable.Map<string, CcCluster>;
export type AppStateControllerStatsRecord = CcControllerStats | undefined;
export type AppStateControllerStatsUpdated = Date | undefined;
export type AppStateClusterStatsRecord = CcClusterStats | undefined;
export type AppStateClusterStatsUpdated = Date | undefined;
export type AppStateNodeStatsRecord = CcNodeStats | undefined;
export type AppStateNodeStatsUpdated = Date | undefined;
export type AppStateAlarmStatsRecord = CcAlarmStats | undefined;
export type AppStateAlarmStatsUpdated = Date | undefined;
export type AppStateTechnologies = string[];
export type AppStateNodeTypes = CcNodeType[];
export type AppStateTags = string[];
export type AppStateGlobalFilters = Immutable.Map<string, any>;
export type AppStateJobStatsRecord = CcJobStats | undefined;
export type AppStateJobStatsUpdated = Date | undefined;
export type AppStateLogStatsRecord = CcLogStats | undefined;
export type AppStateLogStatsUpdated = Date | undefined;
export type AppStateRunningJobs = Immutable.Map<string, CcJob>;
export type AppStateCurrentAlarms = Immutable.Map<string, CcAlarm>;
export type AppStateScheduledMaintenances = Immutable.Map<
    string,
    CcMaintenanceInfo
>;
export type AppStateBackupStatsRecord = CcBackupStats | undefined;
export type AppStateBackupStatsUpdated = Date | undefined;

export type AppStateUserUIConfig = Immutable.Record<{
    notifications: Immutable.Record<{
        lastSeenLog: Date;
    }>;
}>;
export type AppStateTree = Immutable.List<CcTreeItem>;

export type AppState = {
    user?: AppStateUser;
    licenseInfo?: AppStateLicenseInfo;
    controllers: AppStateControllersMap;
    controllerStats?: AppStateControllerStatsRecord;
    controllerStatsUpdated?: AppStateControllerStatsUpdated;
    clusterStats?: AppStateClusterStatsRecord;
    clusterStatsUpdated?: AppStateClusterStatsUpdated;
    clusters: AppStateClustersMap;
    nodeStats?: AppStateNodeStatsRecord;
    nodeStatsUpdated?: AppStateNodeStatsUpdated;
    alarmStats?: AppStateAlarmStatsRecord;
    alarmStatsUpdated?: AppStateAlarmStatsUpdated;

    /**
     * available technologies from current clusters
     */
    technologies: AppStateTechnologies;

    /**
     * available nodeTypes from current clusters
     */
    nodeTypes: AppStateNodeTypes;

    /**
     * available tags from current clusters
     */
    tags: AppStateTags;
    globalFilters: AppStateGlobalFilters;
    jobStats?: AppStateJobStatsRecord;
    jobStatsUpdated?: AppStateJobStatsUpdated;
    logStats?: AppStateLogStatsRecord;
    logStatsUpdated?: AppStateLogStatsUpdated;
    runningJobs: AppStateRunningJobs;
    currentAlarms: AppStateCurrentAlarms;
    scheduledMaintenances: AppStateScheduledMaintenances;
    backupStats?: AppStateBackupStatsRecord;
    backupStatsUpdated?: AppStateBackupStatsUpdated;
    activityQuickViewOpened: boolean;
    userUIConfig: AppStateUserUIConfig;
    tree: AppStateTree;
};

export type AppAction = {
    type: string;
    data: any;
};

export const initialState: AppState = {
    controllers: Immutable.Map(),
    clusters: Immutable.Map(),
    technologies: [],
    nodeTypes: [],
    tags: [],
    globalFilters: Immutable.Map(),
    runningJobs: Immutable.Map(),
    currentAlarms: Immutable.Map(),
    scheduledMaintenances: Immutable.Map(),
    activityQuickViewOpened: false,
    // next config should be loaded from cmon db when implemented
    // https://severalnines.atlassian.net/browse/CCV2-65
    userUIConfig: Immutable.Record({
        notifications: Immutable.Record({
            lastSeenLog: new Date(),
            // @TODO: should be done by cluster, so when the user navigates to cmon log in clusters
            // we dont clear the global lastSeenLog var
        })({}),
    })({}),
    tree: Immutable.List(),
};

export default function reducer(
    state: AppState = initialState,
    action: AppAction
): AppState {
    switch (action.type) {
        case SET_USER:
            return { ...state, user: action.data };
        case SET_LICENSE_INFO:
            return { ...state, licenseInfo: action.data };
        case SET_CONTROLLERS:
            return { ...state, controllers: action.data };
        case SET_CONTROLLER_STATS:
            return {
                ...state,
                controllerStats: action.data,
                controllerStatsUpdated: new Date(),
            };
        case SET_CLUSTER_STATS:
            return {
                ...state,
                clusterStats: action.data,
                clusterStatsUpdated: new Date(),
            };
        case SET_CLUSTERS:
            return { ...state, clusters: action.data };
        case SET_CLUSTER:
            const cluster = action.data;
            const newClusters = state.clusters?.set(cluster.getKey(), cluster);
            return { ...state, clusters: newClusters };
        case SET_NODE_STATS:
            return {
                ...state,
                nodeStats: action.data,
                nodeStatsUpdated: new Date(),
            };
        case SET_ALARM_STATS:
            return {
                ...state,
                alarmStats: action.data,
                alarmStatsUpdated: new Date(),
            };
        case SET_TECHNOLOGIES:
            return { ...state, technologies: action.data };
        case SET_NODE_TYPES:
            return { ...state, nodeTypes: action.data };
        case SET_TAGS:
            return { ...state, tags: action.data };
        case SET_GLOBAL_FILTERS:
            return { ...state, globalFilters: action.data };
        case SET_JOB_STATS:
            return {
                ...state,
                jobStats: action.data,
                jobStatsUpdated: new Date(),
            };

        case SET_LOG_STATS:
            return {
                ...state,
                logStats: action.data,
                logStatsUpdated: new Date(),
            };
        case SET_RUNNING_JOBS:
            return { ...state, runningJobs: action.data };
        case ADD_NEW_RUNNING_JOB:
            const job = new CcJob({
                ...camelCasePropToUnderscore(action.data),
                new_job: true,
            } as CcJobProps);

            const newRunningJobs = state.runningJobs?.set(
                job.getCommandClusterKey(),
                job
            );
            return {
                ...state,
                runningJobs: newRunningJobs,
            };
        case SET_CURRENT_ALARMS:
            return { ...state, currentAlarms: action.data };
        case SET_SCHEDULED_MAINTENANCES:
            return { ...state, scheduledMaintenances: action.data };
        case SET_BACKUP_STATS:
            return {
                ...state,
                backupStats: action.data,
                backupStatsUpdated: new Date(),
            };
        case OPEN_ACTIVITY_QUICK_VIEW:
            return {
                ...state,
                activityQuickViewOpened: true,
            };
        case CLOSE_ACTIVITY_QUICK_VIEW:
            return {
                ...state,
                activityQuickViewOpened: false,
            };
        case SET_USER_UI_CONFIG:
            return {
                ...state,
                userUIConfig: action.data,
            };
        case UPDATE_USER_UI_CONFIG:
            return {
                ...state,
                userUIConfig: state.userUIConfig.setIn(
                    action.data.path,
                    action.data.value
                ),
            };
        case SET_TREE:
            return {
                ...state,
                tree: Immutable.List<CcTreeItem>(action.data),
            };
        default:
            return state;
    }
}

// Only enable Redux DevTools in development mode
const enhancers = AppConfig.ENABLE_REDUX_DEVTOOLS
    ? // @ts-ignore
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__?.()
    : undefined;

export function createAppStore(state?: AppState): Store {
    return createStore<AppState, AppAction, any, any>(
        reducer,
        state,
        enhancers
    );
}

export function setUser(user: any): AppAction {
    return {
        type: SET_USER,
        data: user,
    };
}

export function setLicenseInfo(licenseInfo: AppStateLicenseInfo): AppAction {
    return {
        type: SET_LICENSE_INFO,
        data: licenseInfo,
    };
}

export function setControllers(controllers: AppStateControllersMap): AppAction {
    return {
        type: SET_CONTROLLERS,
        data: controllers,
    };
}

export function setControllerStats(
    controllerStats: CcControllerStats
): AppAction {
    return {
        type: SET_CONTROLLER_STATS,
        data: controllerStats,
    };
}

export function setClusterStats(clusterStats: CcClusterStats): AppAction {
    return {
        type: SET_CLUSTER_STATS,
        data: clusterStats,
    };
}

export function setClusters(clusters: AppStateClustersMap): AppAction {
    return {
        type: SET_CLUSTERS,
        data: clusters,
    };
}

export function setCluster(cluster: CcCluster): AppAction {
    return {
        type: SET_CLUSTER,
        data: cluster,
    };
}

export function setNodeStats(nodeStats: CcNodeStats): AppAction {
    return {
        type: SET_NODE_STATS,
        data: nodeStats,
    };
}

export function setAlarmStats(alarmStats: CcAlarmStats): AppAction {
    return {
        type: SET_ALARM_STATS,
        data: alarmStats,
    };
}

export function setTechnologies(technologies: AppStateTechnologies): AppAction {
    return {
        type: SET_TECHNOLOGIES,
        data: technologies,
    };
}

export function setNodeTypes(nodeTypes: AppStateNodeTypes): AppAction {
    return {
        type: SET_NODE_TYPES,
        data: nodeTypes,
    };
}

export function setTags(tags: AppStateTags): AppAction {
    return {
        type: SET_TAGS,
        data: tags,
    };
}

export function setGlobalFilters(
    globalFilters: AppStateGlobalFilters
): AppAction {
    return {
        type: SET_GLOBAL_FILTERS,
        data: globalFilters,
    };
}

export function setJobStats(jobStats: CcJobStats): AppAction {
    return {
        type: SET_JOB_STATS,
        data: jobStats,
    };
}

export function setLogStats(logStats: CcLogStats): AppAction {
    return {
        type: SET_LOG_STATS,
        data: logStats,
    };
}

export function setRunningJobs(runningJobs: AppStateRunningJobs): AppAction {
    return {
        type: SET_RUNNING_JOBS,
        data: runningJobs,
    };
}

export function addNewRunningJob(runningJob: CcJob): AppAction {
    return {
        type: ADD_NEW_RUNNING_JOB,
        data: runningJob,
    };
}

export function setCurrentAlarms(
    currentAlarms: AppStateCurrentAlarms
): AppAction {
    return {
        type: SET_CURRENT_ALARMS,
        data: currentAlarms,
    };
}

export function setScheduledMaintenances(
    scheduledMaintenances: AppStateScheduledMaintenances
): AppAction {
    return {
        type: SET_SCHEDULED_MAINTENANCES,
        data: scheduledMaintenances,
    };
}

export function setBackupStats(backupStats: CcBackupStats): AppAction {
    return {
        type: SET_BACKUP_STATS,
        data: backupStats,
    };
}

export function openActivityQuickView(): AppAction {
    return {
        type: OPEN_ACTIVITY_QUICK_VIEW,
        data: null,
    };
}

export function closeActivityQuickView(): AppAction {
    return {
        type: CLOSE_ACTIVITY_QUICK_VIEW,
        data: null,
    };
}

export function setUserUIConfig(userUIConfig: AppStateUserUIConfig): AppAction {
    return {
        type: SET_USER_UI_CONFIG,
        data: userUIConfig,
    };
}

export function updateUserUIConfig(path: string[], value: any): AppAction {
    return {
        type: UPDATE_USER_UI_CONFIG,
        data: { path, value },
    };
}

export function setTree(tree: CcTreeItem[]): AppAction {
    return {
        type: SET_TREE,
        data: tree,
    };
}
