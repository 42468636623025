import React from 'react';
import CcCluster from '../../../services/models/CcCluster';
import AppPageWrapper from '../../../common/Layout/AppPageWrapper';
import { Tabs } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import ClusterConfigPage from './ClusterConfigPage';
import EmailNotifications from '../../EmailNotifications/EmailNotifications';

export default ClusterSettingsPage;
export type ClusterSettingsPageProps = {
    cluster: CcCluster;
};

function ClusterSettingsPage({ cluster, ...rest }: ClusterSettingsPageProps) {
    const navigate = useNavigate();
    const { settingsPage } = useParams<{
        settingsPage: string;
    }>();

    return (
        <AppPageWrapper
            className="ClusterLogs"
            transparentBackground={true}
            noPadding={true}
        >
            <Tabs
                onTabClick={(key) => {
                    navigate(`/clusters/${cluster.clusterId}/settings/${key}`);
                }}
                activeKey={settingsPage}
            >
                <Tabs.TabPane tab="System settings" key="system-settings">
                    <ClusterConfigPage cluster={cluster} />
                </Tabs.TabPane>

                <Tabs.TabPane
                    tab="Email notifications"
                    key="email-notifications"
                >
                    <EmailNotifications cluster={cluster} />
                </Tabs.TabPane>
            </Tabs>
        </AppPageWrapper>
    );
}
