import SelectWrap, { SelectWrapProps } from '../../common/DataEntry/SelectWrap';
import CcBackupHostStorageLocation from '../../services/models/CcBackupHostStorageLocation';
import CcBackupCloudStorageLocation from '../../services/models/CcBackupCloudStorageLocation';
import { Select } from 'antd';
import StorageLocationFormat from './StorageLocations/StorageLocationFormat';
import React from 'react';
import CcBackup from '../../services/models/CcBackup';
export default BackupStorageLocationSelect;
type BackupStorageLocationSelectProps = SelectWrapProps & {
    backup: CcBackup;
};

function BackupStorageLocationSelect({
    backup,
    ...rest
}: BackupStorageLocationSelectProps) {
    return (
        <SelectWrap {...rest}>
            {backup
                .getStorageLocations()
                .map(
                    (
                        storageLocation:
                            | CcBackupHostStorageLocation
                            | CcBackupCloudStorageLocation
                    ) => (
                        <Select.Option
                            value={storageLocation.getUuid()}
                            key={storageLocation.getUuid()}
                        >
                            <StorageLocationFormat
                                storageLocation={storageLocation}
                            >
                                {storageLocation.getPath()}
                            </StorageLocationFormat>
                        </Select.Option>
                    )
                )}
        </SelectWrap>
    );
}
