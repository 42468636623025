import { useMemo } from 'react';
import CcAdvisorSchedule from '../../../services/models/CcAdvisorSchedule';

type UseAdvisorStatusesProps = {
    schedules: CcAdvisorSchedule[];
};
export default function useAdvisorStatuses({
    schedules,
}: UseAdvisorStatusesProps) {
    return useMemo(() => {
        return Array.from(
            new Set(schedules?.map((item) => item.statusTitle || ''))
        );
    }, [schedules]);
}
