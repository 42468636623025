import React, { Fragment, useEffect, useRef, useState } from 'react';
import './UseExistingCertificate.less';
import { Col, Form, Row } from 'antd';
import FormFooter from '../../common/FormFooter';
import SpaceWide from '../../common/SpaceWide';
import FormItemInlineSwitch from '../../common/DataEntry/FormItemInlineSwitch';
import Alert from '@severalnines/bar-frontend-components/build/lib/Feedback/Alert';
import CcCluster from '../../services/models/CcCluster';
import AppDirectoryTree from '../../common/General/AppDirectoryTree';
import TypographyText from '../../common/TypographyText';
import { DirectoryTreeProps } from 'antd/lib/tree';
import useFetch from '../../common/useFetch';
import CmonCaService from '../../services/cmon/CmonCaService';
import AppSpin from '../../common/General/AppSpin';
import { notifyJobCreationError } from '../Notifications/uiNotification';
import useCreateJob from '../Jobs/useCreateJob';
import { CmonJobInstanceCommand } from '../../services/cmon/models/CmonJobInstance';
import { FileTextOutlined } from '@ant-design/icons';

export default UseExistingCertificate;

export type UseExistingCertificateProps = {
    galera?: boolean;
    cluster?: CcCluster;
    onSuccess?: () => void;
    onCancel?: () => void;
};

export type UseExistingCertificateValues = {
    restart?: boolean;
};

export enum CertificateTypes {
    DIRECTORY = 'directory',
    CERTIFICATE = 'certificate',
    CERTIFICATE_AUTHORITY = 'certificate-authority',
}

function UseExistingCertificate({
    galera,
    cluster,
    onSuccess,
    onCancel,
}: UseExistingCertificateProps) {
    const [form] = Form.useForm<UseExistingCertificateValues>();
    const selectedNode = useRef<any>();

    const [expandedKeys, setExpandedKeys] = useState<string[]>([]);
    const [treeData, setTreeData] = useState<any[]>([]);

    const {
        loading: loadingData,
        data: result,
        refresh: refreshFetch,
    } = useFetch({
        name: 'listCerts',
        fetchFn: async (params, opts) => {
            const response = await CmonCaService.listCerts(
                {
                    ...params,
                    cluster_id: cluster?.clusterId,
                },
                opts
            );
            return response;
        },
        cancelFn: async ({ requestId }) => {
            await CmonCaService.cancelRequest(requestId);
        },
    });
    useEffect(() => {
        (async () => {
            await refreshFetch();
        })();
    }, []);

    useEffect(() => {
        if (Array.isArray(result?.data)) {
            setTreeData(loadCertificatesTree(result?.data));
        }
    }, [result]);

    useEffect(() => {
        setExpandedKeys(treeData.map((node) => node.key));
    }, [treeData]);

    const handleExpand: DirectoryTreeProps['onExpand'] = (keys, info) => {
        setExpandedKeys(keys as string[]);
    };

    const handleSelect: DirectoryTreeProps['onSelect'] = async (
        keys,
        { node }: any
    ) => {
        selectedNode.current = node;
    };

    const [isRestart, setRestart] = useState<boolean>();

    const { loading, send } = useCreateJob({
        clusterId: cluster?.clusterId,
        title: galera ? 'Encrypting Replication' : 'Setup SSL',
        command: galera
            ? (CmonJobInstanceCommand.ENCRYPT_REPLICATION.toLowerCase() as CmonJobInstanceCommand)
            : (CmonJobInstanceCommand.SETUP_SSL.toLowerCase() as CmonJobInstanceCommand),
        onSuccess,
    });

    const handleSubmit = async (values: UseExistingCertificateValues) => {
        if (galera) {
            try {
                await send({
                    action: 'enable',
                    certificateId: selectedNode.current?.data?.id,
                    clusterId: `${cluster?.clusterId}`,
                    expire_days: 0,
                });
            } catch (err: any) {
                notifyJobCreationError({
                    size: 'large',
                    content: 'Job failded.',
                });
            }
        } else {
            try {
                await send({
                    action: 'enable',
                    certificateId: selectedNode.current?.data?.id,
                    clusterId: `${cluster?.clusterId}`,
                    rolling_restart: values?.restart,
                    expire_days: null,
                });
            } catch (err: any) {
                notifyJobCreationError({
                    size: 'large',
                    content: 'Job failded.',
                });
            }
        }
    };

    const handleRestartChange = (value: boolean) => {
        setRestart(value);
    };

    return (
        <Form
            className="UseExistingCertificate"
            form={form}
            layout="vertical"
            onFinish={handleSubmit}
            initialValues={{
                restart: false,
            }}
        >
            <Row>
                <Col span={24}>
                    <AppSpin tall={true} spinning={loadingData}>
                        <AppDirectoryTree
                            onExpand={handleExpand}
                            expandedKeys={expandedKeys}
                            onSelect={handleSelect}
                            treeData={treeData}
                            titleRender={(node) => (
                                <TypographyText nowrap={true}>
                                    {node.title}
                                </TypographyText>
                            )}
                        />
                    </AppSpin>
                </Col>
                {!galera && (
                    <Fragment>
                        <Col span={24}>
                            <FormItemInlineSwitch
                                justify
                                onChange={handleRestartChange}
                                name="restart"
                                label={'Restart nodes'}
                                valuePropName="checked"
                                style={{ margin: '1rem 0' }}
                            />
                        </Col>
                        <Col span={24}>
                            <SpaceWide direction="vertical">
                                {!isRestart ? (
                                    <Alert
                                        showIcon
                                        type="info"
                                        message={
                                            'To setup SSL encryption, the nodes must be restarted. Switch ON "Restart Nodes" to perform a rolling start of the nodes.'
                                        }
                                    />
                                ) : (
                                    <Alert
                                        showIcon
                                        type="warning"
                                        message={
                                            'The nodes will be restarted with a rolling restart. Applications may be affected during this restart.'
                                        }
                                    />
                                )}
                            </SpaceWide>
                        </Col>
                    </Fragment>
                )}
            </Row>
            <FormFooter
                loading={loading}
                showSubmitButton={true}
                showCancelButton={true}
                submitButtonText="Create"
                onCancel={onCancel}
            />
        </Form>
    );
}

export function loadCertificatesTree(data: any, hideFiles: boolean = false) {
    const populateTree = (tree: any, cert: any) => {
        let currentChildren = tree;

        let path: string[] = [];
        // there is never a leading slash

        cert.certfile
            .split('/')
            .forEach((name: any, i: any, { length }: any) => {
                if (name) {
                    const isFile = i === length - 1;
                    const itemInTree = currentChildren.find(
                        (item: any) => item.name === name
                    );

                    if (itemInTree === undefined && isFile && !hideFiles) {
                        cert['parentpath'] = path.join('/');
                        currentChildren.push({
                            data: cert,
                            name,
                            title: name,
                            key: `${path.join(
                                '-'
                            )}-${name}-${i}-${Math.random()}`,
                            type: cert.isCA
                                ? CertificateTypes.CERTIFICATE_AUTHORITY
                                : CertificateTypes.CERTIFICATE,
                            path: path.join('/'),
                            icon: <FileTextOutlined />,
                        });
                    } else if (itemInTree === undefined && !isFile) {
                        path.push(name);
                        currentChildren.push({
                            children: [],
                            name,
                            title: name,
                            key: `${path.join('-')}-${name}-${i}`,
                            type: CertificateTypes.DIRECTORY,
                            path: path.join('/'),
                        });

                        currentChildren =
                            currentChildren[currentChildren.length - 1]
                                .children;
                    } else if (!isFile) {
                        path.push(name);
                        currentChildren = itemInTree.children;
                    }
                }
            });
    };

    // @todo add sorting to ccTree instead
    const sortChildren = (children: any) => {
        children.sort((a: any, b: any) => {
            if (a.name > b.name) {
                return 1;
            } else if (a.name < b.name) {
                return -1;
            } else {
                return 0;
            }
        });

        children.forEach((child: any) => {
            if (child.children) {
                sortChildren(child.children);
            }
        });
    };

    const tree: any = [];

    data.forEach((cert: any) => {
        populateTree(tree, cert);
        sortChildren(tree);
    });
    return tree;
}
