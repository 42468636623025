import { Typography as AntTypography } from 'antd';
import { TextProps } from 'antd/lib/typography/Text';
import classNames from 'classnames';
import './TypographyText.less';

export default TypographyText;
export type TypographyTextProps = TextProps & {
    muted?: boolean;
    primary?: boolean;
    nowrap?: boolean;
    white?: boolean;
    strong?: boolean;
    truncate?: number;
    className?: string;
    children?: React.ReactNode;
};

function TypographyText({
    muted,
    primary,
    nowrap,
    white,
    strong,
    children,
    className,
    ...rest
}: TypographyTextProps) {
    return (
        <AntTypography.Text
            className={classNames(className, {
                'TypographyText--type-muted': muted,
                'TypographyText--type-primary': primary,
                'TypographyText--type-nowrap': nowrap,
                'TypographyText--type-white': white,
                'TypographyText--type-strong': strong,
            })}
            {...rest}
        >
            {children}
        </AntTypography.Text>
    );
}
