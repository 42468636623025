
import CcStats, { CcStatsProps } from '../../models/CcStats';


export interface CmonNdbStatsProps  extends CcStatsProps {
    aborts?: number;
    commits?: number;
    dm_total_bytes?: number;
    dm_used_bytes?: number;
    im_total_bytes?: number;
    im_used_bytes?: number;
    operations?: number;
    range_scans?: number;
    reads?: number;
    simple_reads?: number;
    table_scans?: number;
    transactions?: number;
    writes?: number;

}



export default class CmonNdbStats extends CcStats {
    public aborts?: number;
    public commits?: number;
    public dmTotalBytes?: number;
    public dmUsedBytes?: number;
    public imTotalBytes?: number;
    public imUsedBytes?: number;
    public operations?: number;
    public rangeScans?: number;
    public reads?: number;
    public simpleReads?: number;
    public tableScans?: number;
    public transactions?: number;
    public writes?: number;


    constructor(props: CmonNdbStatsProps) {
        super(props);
        this.aborts = props.aborts;
        this.commits = props.commits;
        this.dmTotalBytes = props.dm_total_bytes;
        this.dmUsedBytes = props.dm_used_bytes;
        this.imTotalBytes = props.im_total_bytes;
        this.imUsedBytes = props.im_used_bytes;
        this.operations = props.operations;
        this.rangeScans = props.range_scans;
        this.reads = props.reads;
        this.simpleReads = props.simple_reads;
        this.tableScans = props.table_scans;
        this.transactions = props.transactions;
        this.writes = props.writes;

    }

}
