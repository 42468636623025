const SET_PAGE = 'cmon-proxy-fe/list/SET_PAGE';
const SET_PAGE_SIZE = 'cmon-proxy-fe/list/SET_PAGE_SIZE';
const SET_ORDER = 'cmon-proxy-fe/list/SET_ORDER';
const SET_FILTERS = 'cmon-proxy-fe/list/SET_FILTERS';
const SET_ALL = 'cmon-proxy-fe/list/SET_ALL';

export type ListState = {
    page?: number;
    pageSize?: number;
    order?: (a: any, b: any) => number;
    filters?: Function[];
};

export type ListAction = {
    type: string;
    data: any;
};

export const initialListState: ListState = {
    page: 1,
    pageSize: 0,
};

export function listReducer(state: ListState, action: ListAction): ListState {
    switch (action.type) {
        case SET_PAGE:
            return { ...state, page: action.data };
        case SET_PAGE_SIZE:
            return { ...state, pageSize: action.data };
        case SET_ORDER:
            return { ...state, order: action.data };
        case SET_FILTERS:
            return { ...state, filters: action.data };
        case SET_ALL:
            return { ...state, ...action.data };
        default:
            throw new Error(`Action: ${action.type} does not exists.`);
    }
}

export function setListAll(data: ListState): ListAction {
    return {
        type: SET_ALL,
        data: data,
    };
}
