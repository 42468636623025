
import CcNode, { CcNodeProps } from '../../models/CcNode';
import CcNodeReplicationSlave, { CcNodeReplicationSlaveProps } from '../../models/CcNodeReplicationSlave';
export interface CmonRedisHostReplicationSlaveProps extends CcNodeReplicationSlaveProps {
    linkstatus?: string;
    status?: string;
}

export interface CmonRedisHostReplicationMasterProps {
    slave_info?: {[key:string]:any};
}

export interface CmonRedisHostRedisProps {
    appendlogname?: string;
    appendonly?: boolean;
    dbfilename?: string;
}


export interface CmonRedisHostProps  extends CcNodeProps {
    redis?: CmonRedisHostRedisProps;
    replication_master?: CmonRedisHostReplicationMasterProps;
    replication_slave?: CmonRedisHostReplicationSlaveProps;

}

export class CmonRedisHostReplicationSlave extends CcNodeReplicationSlave {
    public linkstatus?: string;
    public status?: string;

    constructor(props: CmonRedisHostReplicationSlaveProps) {
        super(props)
        this.linkstatus = props.linkstatus;
        this.status = props.status;

    }}

export class CmonRedisHostReplicationMaster {
    public slaveInfo?: {[key:string]:any};

    constructor(props: CmonRedisHostReplicationMasterProps) {
        this.slaveInfo = props.slave_info;

    }}

export class CmonRedisHostRedis {
    public appendlogname?: string;
    public appendonly?: boolean;
    public dbfilename?: string;

    constructor(props: CmonRedisHostRedisProps) {
        this.appendlogname = props.appendlogname;
        this.appendonly = props.appendonly;
        this.dbfilename = props.dbfilename;

    }}



export default class CmonRedisHost extends CcNode {
    public redis?: CmonRedisHostRedis;
    public replicationMaster?: CmonRedisHostReplicationMaster;
    public replicationSlave?: CmonRedisHostReplicationSlave;


    constructor(props: CmonRedisHostProps) {
        super(props);
        this.redis = props.redis && new CmonRedisHostRedis(props.redis);
        this.replicationMaster = props.replication_master && new CmonRedisHostReplicationMaster(props.replication_master);
        this.replicationSlave = props.replication_slave && new CmonRedisHostReplicationSlave(props.replication_slave);

    }

}
