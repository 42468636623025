import CmonRedisHost, {
    CmonRedisHostProps,
} from '../cmon/models/CmonRedisHost';

export interface CcRedisNodeProps extends CmonRedisHostProps {}

export default class CcRedisNode extends CmonRedisHost {
    constructor(props: CcRedisNodeProps) {
        super(props);
    }

    isReplicationRunning() {
        return this.replicationSlave?.status === 'up';
    }
}
