import React, { useEffect, useState } from 'react';
import CcCluster from '../../services/models/CcCluster';
import BooleanStatusFormat, {
    BooleanStatusFormatProps,
} from '@severalnines/bar-frontend-components/build/lib/Format/BooleanStatusFormat';
import { useDispatch } from 'react-redux';
import { setCluster } from '../../appReducer';
import { CmonJobInstanceCommand } from '../../services/cmon/models/CmonJobInstance';
import CmonClustersService from '../../services/cmon/CmonClustersService';
import useJobWatcher from '../Jobs/useJobWatcher';

export default ClusterNodeAutoRecoveryFormat;

export type ClusterNodeAutoRecoveryFormatProps = BooleanStatusFormatProps & {
    cluster: CcCluster;
};

function ClusterNodeAutoRecoveryFormat({
    cluster,
    ...rest
}: ClusterNodeAutoRecoveryFormatProps) {
    const [stillLoading, setStillLoading] = useState(false);
    const { loading: loadingJob } = useJobWatcher({
        command: [
            CmonJobInstanceCommand.DISABLE_NODE_RECOVERY,
            CmonJobInstanceCommand.ENABLE_NODE_RECOVERY,
        ],
        clusterId: cluster.clusterId,
        onFinish: async () => {
            setStillLoading(true);
            const { clusters } = await CmonClustersService.getAllClusterInfo({
                cluster_ids: [cluster.clusterId],
                with_hosts: true,
            });
            const [updatedCluster] = clusters;
            if (updatedCluster) {
                dispatch(setCluster(updatedCluster));
            }
        },
    });
    const dispatch = useDispatch();

    useEffect(() => {
        if (loadingJob === false) {
            setStillLoading(false);
        }
    }, [cluster]);

    return (
        <BooleanStatusFormat
            className="ClusterNodeAutoRecoveryFormat"
            booleanVar={cluster.nodeAutoRecovery}
            statusFormatProps={{ loading: loadingJob || stillLoading }}
            {...rest}
        />
    );
}
