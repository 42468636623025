import CmonGaleraHost, {
    CmonGaleraHostGalera,
    CmonGaleraHostGaleraProps,
    CmonGaleraHostProps,
} from '../cmon/models/CmonGaleraHost';

export interface CcGaleraNodeGaleraProps extends CmonGaleraHostGaleraProps {
    clustername?: string;
    cluster_address?: string;
    segmentid?: number;
    socketssl?: boolean;
    sst_method?: string;
}
export class CcGaleraNodeGalera extends CmonGaleraHostGalera {
    public clustername?: string;
    public clusterAddress?: string;
    public segmentid?: number;
    public socketssl?: boolean;
    public sstMethod?: string;
    constructor(props: CcGaleraNodeGaleraProps) {
        super(props);
        this.clustername = props.clustername;
        this.clusterAddress = props.cluster_address;
        this.segmentid = props.segmentid;
        this.socketssl = props.socketssl;
        this.sstMethod = props.sst_method;
    }

    isPerformanceSchemaEnabled() {
        return !!this.performanceSchema;
    }
}

export interface CcGaleraNodeProps extends CmonGaleraHostProps {
    galera?: CcGaleraNodeGaleraProps;
}

export default class CcGaleraNode extends CmonGaleraHost {
    public galera?: CcGaleraNodeGalera;
    constructor(props: CcGaleraNodeProps) {
        super(props);
        this.galera = props.galera && new CcGaleraNodeGalera(props.galera);
    }
}
