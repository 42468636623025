import React, { useMemo } from 'react';
import WizardSelectCardStep from '@severalnines/bar-frontend-components/build/lib/Navigation/Wizard/WizardSelectCardStep';
import WizardConfigurationModal, {
    WizardConfigurationModalOption,
} from '../../../common/Navigation/WizardConfigurationModal';
import SMTPForm from './SMTPForm';
import SendMailForm from './SendMailForm';
import './MailServerForm.less';
import { ModalDefaultFormProps } from '../../../common/ModalDefaultForm';

export default MailServerForm;

export enum MailServerFormFormOptionType {
    SMTP_SERVER = 'smtpserver',
    SEND_MAIL = 'sendmail',
}

export type MailServerFormFormProps = {
    modalProps?: ModalDefaultFormProps;
    onSuccess?: (option: WizardConfigurationModalOption | undefined) => void;
    onCancel?: () => void;
};

function MailServerForm({
    modalProps,
    onSuccess,
    onCancel,
}: MailServerFormFormProps) {
    const options: WizardConfigurationModalOption<
        MailServerFormFormOptionType
    >[] = useMemo(() => {
        const items: WizardConfigurationModalOption<
            MailServerFormFormOptionType
        >[] = [
            {
                key: MailServerFormFormOptionType.SMTP_SERVER,
                item: (
                    <WizardSelectCardStep.Item
                        key={MailServerFormFormOptionType.SMTP_SERVER}
                        icon={
                            <img
                                src={require('./mail.svg')}
                                alt={getMailServerFormFormOptionTypeTitle(
                                    MailServerFormFormOptionType.SMTP_SERVER
                                )}
                            />
                        }
                        title={getMailServerFormFormOption(
                            MailServerFormFormOptionType.SMTP_SERVER
                        )}
                        action={MailServerFormFormOptionType.SMTP_SERVER}
                        buttonTitle="Configure"
                    />
                ),
                step: {
                    step: MailServerFormFormOptionType.SMTP_SERVER,
                    title: 'Configure mail server: SMTP',
                    content: <SMTPForm />,
                },
            },
            {
                key: MailServerFormFormOptionType.SEND_MAIL,
                item: (
                    <WizardSelectCardStep.Item
                        key={MailServerFormFormOptionType.SEND_MAIL}
                        icon={
                            <img
                                src={require('./mail.svg')}
                                alt={getMailServerFormFormOptionTypeTitle(
                                    MailServerFormFormOptionType.SMTP_SERVER
                                )}
                            />
                        }
                        title={getMailServerFormFormOption(
                            MailServerFormFormOptionType.SEND_MAIL
                        )}
                        action={MailServerFormFormOptionType.SEND_MAIL}
                        buttonTitle="Configure"
                    />
                ),
                step: {
                    step: MailServerFormFormOptionType.SEND_MAIL,
                    title: 'Configure mail server: Sendmail',
                    content: <SendMailForm />,
                },
            },
        ];

        return items;
    }, []);

    return (
        <WizardConfigurationModal
            title="Configure mail server"
            description="Configures how email notifications should be sent out. ClusterControl supports two options for sending email notifications - local MTA (Sendmail/Postfix/Exim) and an external SMTP server."
            options={options}
            onSuccess={onSuccess}
            onCancel={onCancel}
            modalProps={
                {
                    className: 'MailServerForm',
                } as any
            }
        />
    );
}

export function getMailServerFormFormOption(
    type: MailServerFormFormOptionType
) {
    switch (type) {
        case MailServerFormFormOptionType.SMTP_SERVER:
            return (
                <div className="MailServerForm_Title">
                    SMTP Server{' '}
                    <span className="MailServerForm_Recommended">
                        Recommended
                    </span>
                </div>
            );
        case MailServerFormFormOptionType.SEND_MAIL:
            return <div className="MailServerForm_Title">Sendmail</div>;
        default:
            return '';
    }
}

export function getMailServerFormFormOptionTypeTitle(
    type: MailServerFormFormOptionType
) {
    switch (type) {
        case MailServerFormFormOptionType.SMTP_SERVER:
            return 'SMTP Server (Recommended)';
        case MailServerFormFormOptionType.SEND_MAIL:
            return 'Sendmail';
        default:
            return '';
    }
}
