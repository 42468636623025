import React from 'react';
import CcCluster from '../../../../services/models/CcCluster';
import AppRow from '../../../../common/AppRow';
import { Col } from 'antd';
import NodeConfigurationTemplateField from '../../../../common/Form/Fields/NodeConfigurationTemplateField';
import DataDirectoryField from '../../../../common/Form/Fields/DataDirectoryField';
import InstallSoftwareSwitch from '../../../../common/Form/Fields/InstallSoftwareSwitch';
import DisableFirewallSwitch from '../../../../common/Form/Fields/DisableFirewallSwitch';
import DisableSelinuxSwitch from '../../../../common/Form/Fields/DisableSelinuxSwitch';

export default ClusterAddShardConfigurationForm;

export type ClusterAddShardConfigurationFormProps = {
    cluster: CcCluster;
};

function ClusterAddShardConfigurationForm({
    cluster,
}: ClusterAddShardConfigurationFormProps) {
    return (
        <div className="ClusterAddShardConfigurationForm">
            <AppRow gutter={24}>
                <Col md={12} xs={24} sm={24}>
                    <NodeConfigurationTemplateField
                        vendor={cluster.vendor as string}
                        version={cluster.version as string}
                        clusterType={cluster.originalClusterType}
                    />
                </Col>
                <Col md={12} xs={24} sm={24}>
                    <DataDirectoryField name={['serverDataDirectory']} />
                </Col>
                <Col xs={24} sm={24} md={12}>
                    <InstallSoftwareSwitch />
                </Col>
            </AppRow>
            <AppRow gutter={[24, 0]}>
                <Col span={12}>
                    <h3>Security configuration</h3>
                    <DisableFirewallSwitch />
                    <DisableSelinuxSwitch />
                </Col>
            </AppRow>
        </div>
    );
}
