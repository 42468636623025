import useListFetch, { UseListFetchProps } from '../../common/useListFetch';
import CmonUsersService from '../../services/cmon/CmonUsersService';
import { useCallback, useEffect, useReducer, useState } from 'react';
import {
    initialListState,
    listReducer,
    setListAll,
} from '../../common/listReducer';
import { arrayFilter, arrayPages } from '../../common/filtering';
import { getSortNumberFn } from '../../common/sorting';
import { AppConfig } from '../../AppConfig';
import CmonStatService from '../../services/cmon/CmonStatService';
export type useTopQueriesListProps = Omit<UseListFetchProps, 'fetchFn'> & {
    pageSize?: number;
    useCache?: boolean;
    filters?: Function[];
    clusterId?: number;
};

// @todo refactor this to useListFetch file
type ListParams = {
    page?: number;
    pageSize?: number;
    order?: (a: any, b: any) => any;
    filters?: Function[];
};

// @todo refactor this to useListFetch file
type FilterFunctionParams = ListParams & { arr?: any[] };

export default function useTopQueriesList({
    pageSize = AppConfig.defaultPaginationConfig.pageSize,
    useCache,
    filters,
    name,
    clusterId,
}: useTopQueriesListProps) {
    const { list: tableList, loading, loaded, refresh, cancel } = useListFetch({
        name: name || 'queries-overview-table',
        useCache,
        fetchFn: async (
            { begin, end, hostname, pageSize, page, ...rest },
            opts
        ) => {
            try {
                const { queries } = await CmonStatService.getTopQueries(
                    {
                        cluster_id: clusterId,
                        origin: 'topQueries',
                        begin,
                        end,
                        limit: 200,
                        hostname,
                    },
                    opts
                );
                const resultData = queries.result.resultData;
                let resultList = [];
                if (resultData) {
                    resultList = resultData.records.map((record: any) => {
                        return record.reduce(
                            (acc: any, curr: any, idx: number) => {
                                acc[resultData.columnNames[idx]] = curr;
                                return acc;
                            },
                            {}
                        );
                    }, []);
                }
                return {
                    list: resultList,
                    total: resultList.length,
                };
            } catch (error) {
                if (
                    error.message &&
                    (error.message.includes('not found') ||
                        error.message.includes('Connection refused'))
                ) {
                    return {};
                }
                return {};
            }
        },
        cancelFn: async ({ requestId }) => {
            await CmonUsersService.cancelRequest(requestId);
        },
    });
    const [list, setList] = useState<any[]>();
    const [total, setTotal] = useState<number>(0);

    const [
        {
            page: listPage,
            pageSize: listPageSize,
            order: listOrder,
            filters: listFilters,
        },
        listDispatch,
    ] = useReducer(listReducer, {
        ...initialListState,
        pageSize: pageSize || 0,
        filters: filters,
        order: getSortNumberFn('descend', (x) => x.sumTime),
    });

    const filter = useCallback<(p?: FilterFunctionParams) => void>(
        ({
            page = listPage,
            pageSize = listPageSize,
            order = listOrder,
            filters = listFilters,
            arr = tableList || [],
        } = {}) => {
            listDispatch(setListAll({ page, pageSize, order, filters }));
            const filteredArr = arrayFilter({
                filters: [...(filters || [])],
                arr,
            });

            setList([
                ...arrayPages({
                    page,
                    pageSize,
                    order,
                    arr: filteredArr,
                }),
            ]);
            setTotal(filteredArr.length);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [listPage, listPageSize, listOrder, listFilters, tableList]
    );
    // const refresh = useCallback<(p?: RefreshFunctionParams) => Promise<void>>(
    //     async ({
    //         page = listPage,
    //         pageSize = listPageSize,
    //         order = listOrder,
    //         filters = listFilters,
    //         ...rest
    //     } = {}) => {
    //         listDispatch(setListAll({ page, pageSize, order, filters }));
    //         await refreshTable({
    //             ...rest,
    //         });
    //     },
    //     [listPage, listPageSize, listOrder, listFilters, refreshTable]
    // );

    useEffect(() => {
        if (tableList) {
            filter({
                arr: tableList,
            });
        }
    }, [tableList]);

    return {
        list,
        loading,
        loaded,
        refresh,
        filter,
        page: listPage,
        pageSize: listPageSize,
        total,
        cancel,
    };
}
