import React from 'react';
import './DashboardCard.less';
import { Empty } from 'antd';
import classNames from 'classnames';
import AppCard from '../../common/DataDisplay/AppCard';
import AppSpin from '../../common/General/AppSpin';

export default DashboardCard;

export type DashboardCardProps = {
    title?: string | React.ReactElement;
    className?: string;
    children?: any;
    loading?: boolean;
    showContent?: boolean;
    containerProps?: Record<string, any>;
    extra?: any;
    footer?: any;
    footerProps?: any;
    verticalPadding?: number;
    horizontalPadding?: number;
};
function DashboardCard({
    title,
    className,
    children,
    loading,
    showContent = true,
    containerProps,
    extra,
    footer,
    footerProps,
    verticalPadding = 20,
    horizontalPadding = 20,
    ...rest
}: DashboardCardProps) {
    const { className: containerClassName, ...restContainerProps } =
        containerProps || {};
    return (
        <AppCard
            title={title}
            bordered={false}
            className={classNames(
                'DashboardCard',
                { 'DashboardCard--with-footer': !!footer },
                className
            )}
            extra={<div className="DashboardCard_extra">{extra}</div>}
            bodyStyle={{
                paddingTop: `${verticalPadding}px`,
                paddingBottom: `${verticalPadding}px`,
                paddingLeft: `${horizontalPadding}px`,
                paddingRight: `${horizontalPadding}px`,
            }}
            {...rest}
        >
            <div
                className={classNames(
                    'DashboardCard_container',
                    containerClassName
                )}
                {...restContainerProps}
            >
                {loading ? (
                    <AppSpin
                        className="DashboardCard_loading"
                        spinning={true}
                    />
                ) : showContent ? (
                    children
                ) : (
                    <Empty />
                )}
            </div>
            {footer ? (
                <div className="DashboardCard_footer" {...footerProps}>
                    {footer}
                </div>
            ) : null}
        </AppCard>
    );
}
