import React from 'react';
import './StatisticBox.less';
import { Statistic } from 'antd';
import classNames from 'classnames';
import AppTooltip from './Feedback/AppTooltip';
import AppSpin from './General/AppSpin';

export default StatisticBox;

export function getConditionalType(
    value: number,
    conditional: (number | string)[]
) {
    let types = conditional.filter((v: number | string) =>
        Number.isNaN(Number(v))
    );
    let values = conditional.filter(
        (v: number | string) => !Number.isNaN(Number(v))
    );
    if (values.length === 0) {
        values = [0];
    }
    if (types.length === 0) {
        if (values.length === 1) {
            types = ['success', 'error'];
        } else {
            types = ['success', 'warning', 'error'];
        }
    } else if (types.length === 1) {
        types = ['success', types[0]];
    }
    let type = types[0];
    let i = 0;
    while (i < values.length) {
        if (value > values[i]) {
            type = types[i + 1] ? types[i + 1] : types[types.length - 1];
        }
        i++;
    }
    return type;
}

export type StatisticBoxProps = {
    title?: string;
    type?: string;
    value: number | null;
    className?: string;
    highlight?: boolean;
    selectable?: boolean;
    conditional?: (number | string)[];
    loading?: boolean;
    size?: string;
    total?: number | null;
    layout?: 'vertical' | 'horizontal' | 'inline';
    footer?: any;
};

function StatisticBox({
    title,
    type,
    value,
    className,
    highlight,
    selectable,
    conditional,
    loading,
    size,
    total,
    layout,
    footer,
    ...rest
}: StatisticBoxProps) {
    return (
        <div
            className={classNames(
                'StatisticBox',
                `StatisticBox--type-${
                    !type &&
                    conditional &&
                    conditional.length > 0 &&
                    value !== null &&
                    value !== undefined
                        ? getConditionalType(value, conditional)
                        : type
                }`,
                `StatisticBox--size-${size}`,
                `StatisticBox--${layout}`,
                {
                    'StatisticBox--highlight': highlight,
                    'StatisticBox--selectable': selectable,
                    'StatisticBox--loading':
                        (value === null || value === undefined) && loading,
                    'StatisticBox--loading-background':
                        value !== null && value !== undefined && loading,
                },
                className
            )}
            {...rest}
        >
            <>
                {value !== null && value !== undefined && loading ? (
                    <AppSpin
                        size="small"
                        className="StatisticBox_loading-background"
                    />
                ) : null}

                {layout === 'inline' ? (
                    <span>
                        <span className="StatisticBox_inline-value">
                            {value === null || value === undefined
                                ? '-'
                                : value}{' '}
                            {title}
                        </span>{' '}
                        <span className="StatisticBox_inline-total">
                            / {total} total
                        </span>
                    </span>
                ) : (
                    <Statistic
                        title={
                            <AppTooltip
                                title={title}
                                mouseEnterDelay={1}
                                placement="top"
                            >
                                <div className="StatisticBox_title">
                                    {title}
                                </div>
                            </AppTooltip>
                        }
                        loading={
                            (value === null || value === undefined) && loading
                        }
                        value={
                            value === null || value === undefined ? '-' : value
                        }
                    />
                )}
            </>
            {layout !== 'horizontal' && footer ? (
                <div className="StatisticBox_footer">
                    <div className="StatisticBox_footer-content">{footer}</div>
                </div>
            ) : null}
        </div>
    );
}
