import React from 'react';
import AppPageWrapper from '../../../common/Layout/AppPageWrapper';
import CcCluster from '../../../services/models/CcCluster';
import SystemLogsPage from '../../SystemLogs/SystemLogsPage';

export default ClusterSystemLogs;
export type ClusterSystemLogsProps = { cluster: CcCluster };

function ClusterSystemLogs({ cluster, ...rest }: ClusterSystemLogsProps) {
    return (
        <AppPageWrapper
            className="ClusterJobs"
            transparentBackground={true}
            noPadding={true}
            tall={true}
        >
            <SystemLogsPage cluster={cluster} />
        </AppPageWrapper>
    );
}
