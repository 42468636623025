import React, { Fragment } from 'react';
import { Col, Input, Row, Space } from 'antd';
import InfoIcon from '@severalnines/bar-frontend-components/build/lib/General/InfoIcon';
import FormItem from '../../../common/DataEntry/FormItem';
import { LdapConfig } from './LdapTypes';

export default LdapSettingsSslTlsForm;

export type LdapSettingsSslTlsFormProps = {
    defaultSettings?: LdapConfig;
};

function LdapSettingsSslTlsForm({
    defaultSettings,
}: LdapSettingsSslTlsFormProps) {
    return (
        <Fragment>
            <Row gutter={[24, 0]}>
                <Col span={24}>
                    <h3>SSL/TLS settings</h3>
                </Col>
            </Row>
            <Row gutter={[24, 0]}>
                <Col xs={24} sm={24} md={12}>
                    <FormItem
                        name={['security', 'caCertFile']}
                        label={
                            <Space>
                                <span>CA cert file</span>
                                <InfoIcon info="This is the location of the CA certification file. (Only for LDAPS)." />
                            </Space>
                        }
                    >
                        <Input
                            placeholder={defaultSettings?.security?.caCertFile}
                        />
                    </FormItem>
                </Col>
                <Col xs={24} sm={24} md={12}>
                    <FormItem
                        name={['security', 'certFile']}
                        label={
                            <Space>
                                <span>Certificate file</span>
                                <InfoIcon info="This is the location of the certification file. (Only for LDAPS)." />
                            </Space>
                        }
                    >
                        <Input
                            placeholder={defaultSettings?.security?.certFile}
                        />
                    </FormItem>
                </Col>
                <Col xs={24} sm={24} md={12}>
                    <FormItem
                        name={['security', 'keyFile']}
                        label={
                            <Space>
                                <span>Key file</span>
                                <InfoIcon info="This is the location of the certificate key file. (Only for LDAPS)." />
                            </Space>
                        }
                    >
                        <Input
                            placeholder={defaultSettings?.security?.keyFile}
                        />
                    </FormItem>
                </Col>
            </Row>
        </Fragment>
    );
}

export function getLdapSettingsSslTlsFormValidate() {
    return [];
}
