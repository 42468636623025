import React, { useEffect, useState } from 'react';
import SpaceWide from '../../../common/SpaceWide';
import { Space } from 'antd';
import ModalDefault from '../../../common/ModalDefault';
import CcUser from '../../../services/models/CcUser';
import UserInfo from '../../User/UserInfo';
import UserPermissionsInfo from '../UserPermissionsInfo';
import UserEditButton from './UserEditButton';
import AppSpin from '../../../common/General/AppSpin';

export default UserDetailsModal;

export type UserDetailsModalProps = {
    user: CcUser;
    onCancel?: () => void;
    onEdit?: () => void;
};

function UserDetailsModal({ user, onCancel, onEdit }: UserDetailsModalProps) {
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        setLoading(false);
    }, [user]);

    const handleEditUserSuccess = () => {
        setLoading(true);
        onEdit?.();
    };
    return (
        <ModalDefault
            title="User details"
            onCancel={onCancel}
            width={900}
            visible={true}
            bodyStyle={{ padding: '25px' }}
            footer={
                <SpaceWide justify="flex-end">
                    <UserEditButton
                        user={user}
                        type="primary"
                        onSuccess={handleEditUserSuccess}
                        size="middle"
                    >
                        Edit user
                    </UserEditButton>
                </SpaceWide>
            }
            loading={loading}
        >
            <SpaceWide direction="vertical" style={{ margin: '0 auto' }}>
                <SpaceWide justify="center">
                    <UserInfo user={user} size="large" />
                </SpaceWide>
                <div style={{ textAlign: 'center' }}>
                    <Space
                        direction="vertical"
                        style={{
                            width: '80%',
                            textAlign: 'left',
                            margin: 'auto',
                        }}
                    >
                        {!loading ? (
                            <UserPermissionsInfo user={user} />
                        ) : (
                            <AppSpin spinning={true} />
                        )}
                    </Space>
                </div>
            </SpaceWide>
        </ModalDefault>
    );
}
