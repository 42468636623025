


export interface CmonFunctionProps  {

}



export default class CmonFunction {


    constructor(props: CmonFunctionProps) {

    }

}
