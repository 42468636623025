import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { ResponsiveContext } from '@severalnines/bar-frontend-components/build/lib/Layout/Responsive';
import useListFetch from '../../common/useListFetch';
import {
    AppState,
    AppStateClustersMap,
    updateUserUIConfig,
} from '../../appReducer';
import { useDispatch, useSelector } from 'react-redux';
import ClusterFormat from '../Clusters/ClusterFormat';

import CmonLogService from '../../services/cmon/CmonLogService';
import CcLogEntry, {
    CcLogEntrySeverity,
    getLogSeverities,
} from '../../services/models/CcLogEntry';
import LogEntrySeverityFormat, {
    getLogEntrySeverityFormatText,
    getLogEntrySeverityFormatType,
} from './LogEntrySeverityFormat';
import { TablePaginationConfig } from 'antd/es';
import AppEmpty from '../../common/Feedback/AppEmpty';
import AppDateFormat from '../../common/AppDateFormat';
import AppTooltip from '../../common/Feedback/AppTooltip';
import AppTable from '../../common/DataDisplay/AppTable';
import useTableFilter from '../../common/hooks/useTableFilter';
import { ArrayParam, NumberParam } from 'use-query-params';
import useTableFilterColumns, {
    TableFilterType,
} from '../../common/hooks/useTableFilterColumns';
import TimeRange from '../../common/DataEntry/TimeRange';
import SpaceWide from '../../common/SpaceWide';
import StatusFormat from '@severalnines/bar-frontend-components/build/lib/Format/StatusFormat';
import TypographyText from '../../common/TypographyText';
import { FilterDropdownProps } from 'antd/lib/table/interface';
import LogSeverityFilterDropdown from './LogSeverityFilterDropdown';

export default LogsTable;

export type LogsTableProps = {
    enablePagination?: boolean;
    pageLimit?: number;
    footerRender?: (total?: number) => React.ReactNode;
    isActive?: boolean;
    clusterId?: number;
    showRelativeTime?: boolean;
};

function LogsTable({
    enablePagination = true,
    pageLimit = 20,
    footerRender,
    isActive,
    clusterId,
    showRelativeTime,
}: LogsTableProps) {
    const { filterParams, handleTableChange, addFilterParams } = useTableFilter(
        {
            params: {
                cluster: ArrayParam,
                from: NumberParam,
                to: NumberParam,
                severity: ArrayParam,
            },
            defaultParams: {
                severity: [CcLogEntrySeverity.LOG_WARNING],
            },
        }
    );
    const { responsive } = useContext(ResponsiveContext);
    const dispatch = useDispatch();
    const {
        list: logs,
        loading: loadingLogs,
        loaded,
        refresh: refreshLogs,
        page,
        pageSize,
        total,
        cancel,
        stopAutoRefresh,
    } = useListFetch({
        name: 'logs-list',
        pageSize: pageLimit,
        fetchFn: async ({ pageSize, page, ...rest }, opts) => {
            const response = await CmonLogService.getLogEntries(
                {
                    ascending: false,
                    limit: pageSize,
                    offset: (page - 1) * pageSize,
                    ...rest,
                },
                opts
            );

            return {
                list: response.log_entries,
                total: response.total,
            };
        },
        cancelFn: async ({ requestId }) => {
            await CmonLogService.cancelRequest(requestId);
        },
    });

    const [clustersMap]: [
        AppStateClustersMap
    ] = useSelector(({ clusters }: AppState) => [clusters]);

    const refresh = async (params: any = {}) => {
        let refreshParams: any = {
            cluster_ids: undefined,
            page: filterParams.page || 1,
            created_after: filterParams.from
                ? new Date(filterParams.from * 1000).toISOString()
                : undefined,
            created_before: filterParams.to
                ? new Date(filterParams.to * 1000).toISOString()
                : undefined,
            severity: filterParams.severity?.[0] || undefined,
        };
        refreshParams = {
            ...refreshParams,
            cluster_id: filterParams.cluster?.[0] || clusterId,
        };
        await refreshLogs({ ...refreshParams, ...params });
    };

    useEffect(() => {
        (async () => {
            await refresh();
        })();
    }, [filterParams, clusterId]);

    useEffect(() => {
        if (isActive) {
            (async () => {
                await refresh({ autoRefresh: 10000, showLoading: !loaded });
            })();
        } else {
            cancel();
            stopAutoRefresh();
        }
    }, [isActive]);

    useEffect(() => {
        if (logs) {
            dispatch(
                updateUserUIConfig(['notifications', 'lastSeenLog'], new Date())
            );
        }
    }, [logs]);

    const { columns } = useTableFilterColumns({
        columns: useMemo(
            () => [
                {
                    title: 'Message',
                    key: 'message',
                    render: (record: CcLogEntry) => (
                        <AppTooltip
                            size={'large'}
                            title={
                                <span
                                    dangerouslySetInnerHTML={{
                                        __html: record.getLogMessage(),
                                    }}
                                ></span>
                            }
                            style={{ width: '400px' }}
                        >
                            <div
                                style={{
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    display: 'block',
                                    width: '400px',
                                }}
                                dangerouslySetInnerHTML={{
                                    __html: record.getLogMessage(),
                                }}
                            />
                        </AppTooltip>
                    ),
                },
                {
                    title: 'Severity',
                    key: 'severity',
                    render: (record: CcLogEntry) => (
                        <LogEntrySeverityFormat logEntry={record} />
                    ),
                    filterMultiple: false,
                    filters: getLogSeverities().map((severity, index) => ({
                        value: severity,
                        text: (
                            <TypographyText>
                                <StatusFormat
                                    status={getLogEntrySeverityFormatType(
                                        severity
                                    )}
                                >
                                    {getLogEntrySeverityFormatText(severity)}
                                </StatusFormat>
                            </TypographyText>
                        ),
                    })),

                    filterDropdown: (props: FilterDropdownProps) => (
                        <LogSeverityFilterDropdown
                            filterDropdownProps={props}
                        />
                    ),
                },
                {
                    title: 'Component',
                    key: 'component',
                    render: (record: CcLogEntry) => record.component,
                },
                {
                    title: 'Cluster',
                    key: 'cluster',
                    render: (record: CcLogEntry) => (
                        <ClusterFormat
                            clusterLink={true}
                            linkDestination={'logs/cmon-log'}
                            cluster={clustersMap.get(record.getClusterKey())}
                            showPopover={true}
                        />
                    ),
                    filterType: TableFilterType.CLUSTER,
                    filterMultiple: false,
                },
                {
                    title: 'When',
                    key: 'created',
                    render: (record: CcLogEntry) => {
                        return (
                            <AppDateFormat fromNow={showRelativeTime}>
                                {record.created
                                    ? new Date(record.created)
                                    : undefined}
                            </AppDateFormat>
                        );
                    },
                },
            ],
            [clustersMap, showRelativeTime]
        ),
        filterParams,
        state: {
            clusters: clustersMap,
        },
    });

    const rowKey = useCallback(
        (record: CcLogEntry) => `${record.getKey()}`,
        []
    );

    const handleOnRow = useCallback(
        (record: CcLogEntry, index: number) => ({
            'data-testid': `quick-log-list-row-${index}`,
        }),
        []
    );

    const pagination: TablePaginationConfig = {
        size: 'default',
        pageSize,
        current: page,
        total,
        hideOnSinglePage: true,
        showQuickJumper: true,
        showSizeChanger: true,
        position: ['bottomCenter'],
    };

    const extraProps = useCallback(() => {
        let props: any = {};
        if (footerRender) {
            props.footer = () => footerRender(total);
        }
        return props;
    }, [footerRender, total]);

    const handleTimeRangeChange = (from: number, to: number) => {
        addFilterParams({ ...filterParams, from, to });
    };
    const handleTimeRangeReset = () => {
        addFilterParams({ ...filterParams, from: undefined, to: undefined });
    };

    return (
        <SpaceWide direction="vertical">
            <SpaceWide direction="vertical" align="end">
                <TimeRange
                    setDefaults={false}
                    onChange={handleTimeRangeChange}
                    onReset={handleTimeRangeReset}
                />
            </SpaceWide>
            <AppTable
                className="LogsTable"
                style={{ width: '100%' }}
                loading={loadingLogs}
                rowKey={rowKey}
                dataSource={logs}
                columns={columns}
                pagination={enablePagination ? pagination : false}
                onChange={handleTableChange}
                responsive={responsive}
                onRow={handleOnRow}
                renderEmpty={
                    filterParams.cluster ? (
                        false
                    ) : (
                        <AppEmpty
                            loading={loadingLogs}
                            description="You dont’t have any logs yet. When you do, it'll show up here."
                        />
                    )
                }
                {...extraProps()}
            />
        </SpaceWide>
    );
}
