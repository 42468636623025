import { CcClusterType } from '../../../services/models/CcCluster';
import ClusterTopologyMysqlReplicationConfigurator from './ClusterTopologyMysqlReplicationConfigurator';
import ClusterTopologyGaleraConfigurator from './ClusterTopologyGaleraConfigurator';
import ClusterTopologyPostgresqlConfigurator from './ClusterTopologyPostgresqlConfigurator';
import ClusterTopologyRedisConfigurator from './ClusterTopologyRedisConfigurator';
import ClusterTopologyMongodbConfigurator from './ClusterTopologyMongodbConfigurator';
import ClusterTopologyMssqlConfigurator from './ClusterTopologyMssqlConfigurator';
import ClusterTopologyElasticConfigurator from './ClusterTopologyElasticConfigurator';
import ClusterTopologyConfigurator from './ClusterTopologyConfigurator';

export function getClusterTopologyConfigurator(clusterType?: CcClusterType) {
    switch (clusterType) {
        case CcClusterType.TYPE_REPLICATION:
        case CcClusterType.TYPE_MYSQL_SINGLE:
            return ClusterTopologyMysqlReplicationConfigurator;
        case CcClusterType.TYPE_GALERA:
            return ClusterTopologyGaleraConfigurator;
        case CcClusterType.TYPE_TIMESCALEDB:
        case CcClusterType.TYPE_POSTGRESQL:
            return ClusterTopologyPostgresqlConfigurator;
        case CcClusterType.TYPE_REDIS:
            return ClusterTopologyRedisConfigurator;
        case CcClusterType.TYPE_MONGODB:
        case CcClusterType.TYPE_MONGODB_SHARDS:
            return ClusterTopologyMongodbConfigurator;
        case CcClusterType.TYPE_MSSQL_SINGLE:
        case CcClusterType.TYPE_MSSQL_AO_ASYNC:
            return ClusterTopologyMssqlConfigurator;
        case CcClusterType.TYPE_ELASTIC:
            return ClusterTopologyElasticConfigurator;
        default:
            return ClusterTopologyConfigurator;
    }
}
