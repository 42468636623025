import React from 'react';
import { Space } from 'antd';
import InfoIcon from '@severalnines/bar-frontend-components/build/lib/General/InfoIcon';
import FormItemInlineSwitch, {
    FormItemInlineSwitchProps,
} from '../../DataEntry/FormItemInlineSwitch';

export default SemiSynchronousReplicationSwitch;

export type SemiSynchronousReplicationSwitchProps = FormItemInlineSwitchProps & {};

function SemiSynchronousReplicationSwitch({
    ...rest
}: SemiSynchronousReplicationSwitchProps) {
    return (
        <div className="SemiSynchronousReplicationSwitch">
            <FormItemInlineSwitch
                justify={true}
                name="semiSynchronous"
                label={
                    <Space>
                        Semi-synchronous replication
                        <InfoIcon
                            info={
                                <span>
                                    Some combination (database vendor &amp;
                                    version and template file) may have this
                                    enabled by default.
                                </span>
                            }
                        />
                    </Space>
                }
                valuePropName="checked"
                {...rest}
            />
        </div>
    );
}
