import React, { useCallback, useEffect, useMemo, useState } from 'react';
import CcCluster from '../../../../services/models/CcCluster';
import { ClusterConfigValue } from '../../useClusterConfig';
import CmonConfigService from '../../../../services/cmon/CmonConfigService';
import { notifyError, NotifyType } from '../../../Notifications/uiNotification';
import useFetch from '../../../../common/useFetch';
import CcNode from '../../../../services/models/CcNode';
import { ClusterConfigGrouped } from '../../useClusterConfigGrouped';
import { ConfigGroupedProvider } from '../ConfigContext';

type ConfigNodeFileProviderProps = {
    children: React.ReactNode;
    cluster?: CcCluster;
    hostname?: string;
    port?: number;
    filename?: string;
    loadConfigGrouped?: boolean;
    onValueChanged?: (item: ClusterConfigValue, value: any) => void;
};

export function ConfigNodeFileProvider({
    cluster,
    hostname,
    port,
    filename,
    loadConfigGrouped = true,
    onValueChanged,
    children,
}: ConfigNodeFileProviderProps) {
    const [changeLoading, setChangeLoading] = useState(false);

    const {
        grouped,
        refresh: refreshConfigGrouped,
        loading,
    } = useNodeFilesGroupedConfig({
        cluster,
        hostname,
        port,
    });

    useEffect(() => {
        (async () => {
            if (cluster) {
                if (loadConfigGrouped) {
                    await refreshConfigGrouped({});
                }
            }
        })();
    }, [cluster?.clusterId, hostname, port]);

    const changeValue = useCallback(
        async (item: ClusterConfigValue, value: any) => {
            try {
                setChangeLoading(true);
                await CmonConfigService.setConfig({
                    cluster_id: cluster?.clusterId,
                    hostname: hostname,
                    port: port,
                    configuration: [
                        {
                            name: item.name,
                            group: item.group,
                            value: value,
                        },
                    ],
                });
                await refreshConfigGrouped({});
                onValueChanged?.(item, value);
            } catch (err: any) {
                notifyError({ type: NotifyType.TOAST, content: err.message });
                throw err; // rethrow error to restore previous value in input
            } finally {
                setChangeLoading(false);
            }
        },
        [cluster, hostname, port]
    );

    const configGrouped = filename ? grouped[filename] : undefined;

    return (
        <ConfigGroupedProvider
            changeValue={changeValue}
            refresh={refreshConfigGrouped}
            configGrouped={configGrouped}
            configGroupedLoading={loading}
            changeLoading={changeLoading}
        >
            {children}
        </ConfigGroupedProvider>
    );
}

type UseNodeFilesGroupedConfigProps = {
    cluster?: CcCluster;
    hostname?: string;
    port?: number;
};

function useNodeFilesGroupedConfig({
    cluster,
    hostname,
    port,
}: UseNodeFilesGroupedConfigProps) {
    type FileConfig = {
        filename: string;
        values: {
            filepath: string;
            linenumber: number;
            section: string;
            value: string;
            variablename: string;
        }[];
    };

    const { data, loading, refresh } = useFetch<{ files: FileConfig[] }>({
        autoFetch: true,
        fetchFn: async ({ ...rest }, opts) => {
            const result = await CmonConfigService.getConfig(
                {
                    cluster_id: cluster?.clusterId,
                    hostName: hostname,
                    port: port,
                },
                opts
            );

            return {
                node: result.config.host as CcNode,
                files: result.config.files || [],
            };
        },
        cancelFn: async ({ requestId }) => {
            await CmonConfigService.cancelRequest(requestId);
        },
    });

    const grouped = useMemo(() => {
        if (!data) {
            return {};
        }
        const result: { [key: string]: ClusterConfigGrouped } = {};
        data.files.forEach((file) => {
            const section: ClusterConfigGrouped = {};
            file.values.forEach((value) => {
                section[value.section || 'root'] = {};
            });
            result[file.filename] = section;
        });
        data.files.forEach((file) => {
            file.values.forEach((value) => {
                result[file.filename][value.section || 'root'][
                    value.variablename
                ] = {
                    name: value.variablename,
                    current_value: value.value,
                    group: value.section,
                    default_value: '',
                    description: '',
                    keys: [],
                    sql_keys: [],
                };
            });
        });
        return result;
    }, [data]);

    return {
        refresh,
        loading,
        grouped,
    };
}
