import ClusterTopologyConfigurator, {
    ClusterTopologyCard,
} from './ClusterTopologyConfigurator';
import CcNode, {
    CcNodeRole,
    CcNodeType,
} from '../../../services/models/CcNode';
import CcCluster from '../../../services/models/CcCluster';
import CcRedisNode from '../../../services/models/CcRedisNode';
import YesNoFormat from '../../../common/Format/YesNoFormat';
import UnavailableFormat from '@severalnines/bar-frontend-components/build/lib/Format/UnavailableFormat';
import { IDiagramItem } from '@severalnines/bar-frontend-components/build/lib/DataDisplay/Diagram';

export default class ClusterTopologyRedisConfigurator extends ClusterTopologyConfigurator {
    public hasPgBouncers: boolean;
    constructor(clusters: CcCluster[]) {
        super(clusters);
        this.hasPgBouncers = false;
    }

    nodeToItem(node: CcNode, cluster: CcCluster, item: IDiagramItem): any {
        let result = item;
        this.hasDbNodes = true;
        if (node.isType(CcNodeType.REDIS) && !node.isRole(CcNodeRole.BVS)) {
            if (node.isRole(CcNodeRole.MASTER)) {
                result.from = 'db';
                result.arrow = 'both';
            }

            if (node.isRole(CcNodeRole.SLAVE)) {
                const master = cluster.getPrimaryOf(node);

                if (master) {
                    result.from = master.getKey();
                    result.arrow = 'right';
                    if (!(node as CcRedisNode).isReplicationRunning()) {
                        result.connectionStatus = 'error';
                    }
                }
            }
        } else {
            result = super.nodeToItem(node, cluster, item);
        }
        return result;
    }
    getNodeCoverCardConfig(node: CcNode): ClusterTopologyCard {
        if (
            node.isType(CcNodeType.REDIS) ||
            node.isType(CcNodeType.REDIS_SENTINEL)
        ) {
            const redisNode = node as CcRedisNode;
            return [
                [
                    {
                        label: 'DB file name',
                        value: redisNode.redis?.dbfilename,
                    },
                ],
                [
                    {
                        label: 'Append log name',
                        value: redisNode.redis?.appendlogname,
                    },
                ],
                [
                    {
                        label: 'Append only',
                        value: (
                            <YesNoFormat
                                booleanVar={redisNode.redis?.appendonly}
                            />
                        ),
                    },
                ],
                ...([CcNodeRole.SLAVE].includes(redisNode.role as CcNodeRole)
                    ? [
                          [
                              {
                                  label: 'Link status',
                                  value: redisNode.replicationSlave?.linkstatus,
                              },
                          ],
                          [
                              {
                                  label: 'Replication status',
                                  value: redisNode.replicationSlave?.status,
                              },
                              {
                                  label: 'Lag',
                                  value: (
                                      <UnavailableFormat
                                          val={
                                              redisNode.replicationSlave
                                                  ?.secondsBehindMaster
                                          }
                                      >
                                          {
                                              redisNode.replicationSlave
                                                  ?.secondsBehindMaster
                                          }
                                          s
                                      </UnavailableFormat>
                                  ),
                              },
                          ],
                      ]
                    : []),
            ];
        }

        return super.getNodeCoverCardConfig(node);
    }
}
