import React, { useState } from 'react';
import { Button } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { IntegrationType } from './NotificationServicesProviderFormat';
import { useDebugContext } from '../../../common/Debug';
import {
    notifyError,
    notifyOperationSuccess,
    NotifyType,
} from '../../Notifications/uiNotification';
import { getConfig } from './NotificationServicesModalAdd';

export default NotificationServicesTestCredentialButton;

export type NotificationServicesTestCredentialButtonProps = {
    form: FormInstance;
    integrationType: IntegrationType;
};

function NotificationServicesTestCredentialButton({
    form,
    integrationType,
    ...rest
}: NotificationServicesTestCredentialButtonProps) {
    const { log } = useDebugContext();
    const [loading, setLoading] = useState<boolean>(false);
    const handleTestCredential = async () => {
        try {
            setLoading(true);
            const response = await fetch(
                `/api/events-test/${integrationType}`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        name: integrationType,
                        credentials: getConfig(integrationType, form.getFieldsValue(true)),
                    }),
                }
            );
            const data: any = await response.json();
            if (data.error) {
                throw new Error(data.error);
            } else {
                notifyOperationSuccess({
                    type: NotifyType.TOAST,
                    title: 'Test sent successfully!',
                });
            }
        } catch (err) {
            log.error(err);
            notifyError({
                type: NotifyType.TOAST,
                title: err.message,
            });
        } finally {
            setLoading(false);
        }
    };
    return (
        <Button onClick={handleTestCredential} {...rest} loading={loading}>
            Test credentials
        </Button>
    );
}
