import './AppPageHeaderBlock.less';
import React from 'react';

export default AppPageHeaderBlock;
export type AppPageHeaderBlockProps = {
    children?: React.ReactNode;
};

function AppPageHeaderBlock({ children, ...rest }: AppPageHeaderBlockProps) {
    return (
        <div className="AppPageHeaderBlock" {...rest}>
            {children}
        </div>
    );
}
