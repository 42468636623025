import React, { useState } from 'react';
import ModalDefaultForm from '../../common/ModalDefaultForm';
import { Form } from 'antd';
import FormFooter from '../../common/FormFooter';
import CcUser from '../../services/models/CcUser';
import useForm from 'antd/lib/form/hooks/useForm';
import PasswordInput from '../../common/DataEntry/PasswordInput';
import CmonUsersService from '../../services/cmon/CmonUsersService';
import {
    notifyError,
    notifyOperationSuccess,
    NotifyType,
} from '../Notifications/uiNotification';

export default UserChangePasswordModal;

type UserChangePasswordFieldsType = {
    currentPassword: string;
    newPassword: string;
    repeatPassword: string;
};
export type UserChangePasswordModalProps = {
    user: CcUser;
    noCurrentPassword?: boolean;
    noRepeatPassword?: boolean;
    onSuccess?: () => void;
    onCancel?: () => void;
};

function UserChangePasswordModal({
    user,
    noCurrentPassword,
    noRepeatPassword,
    onSuccess,
    onCancel,
    ...rest
}: UserChangePasswordModalProps) {
    const [loading, setLoading] = useState(false);
    const [form] = useForm<UserChangePasswordFieldsType>();

    const handleCancel = () => {
        onCancel?.();
    };

    const handleSubmit = async (fields: UserChangePasswordFieldsType) => {
        try {
            setLoading(true);
            await CmonUsersService.changePassword({
                old_password: fields.currentPassword,
                new_password: fields.newPassword,
                user: {
                    class_name: 'CmonUser',
                    user_name: user.userName,
                },
            });
            notifyOperationSuccess({
                type: NotifyType.TOAST,
                title: <span>Password was changed succesfully.</span>,
            });
            onSuccess?.();
        } catch (e: any) {
            console.error(e.message);
            notifyError({ content: e.message });
        } finally {
            setLoading(false);
        }
    };
    return (
        <ModalDefaultForm
            title="Change password"
            width={450}
            form={form}
            onCancel={handleCancel}
            defaultVisible={true}
            bodyStyle={{ padding: '20px' }}
            loading={loading}
        >
            <Form
                form={form}
                onFinish={handleSubmit}
                layout="vertical"
                initialValues={{}}
            >
                {noCurrentPassword ? null : (
                    <Form.Item
                        name="currentPassword"
                        label="Current password"
                        rules={[
                            {
                                required: true,
                                message: 'Please enter current password',
                            },
                        ]}
                    >
                        <PasswordInput
                            autoComplete="new-password"
                            placeholder="Enter current password"
                        />
                    </Form.Item>
                )}

                <Form.Item
                    name="newPassword"
                    label="New password"
                    rules={[
                        {
                            required: true,
                            message: 'Please enter new password',
                        },
                    ]}
                >
                    <PasswordInput
                        autoComplete="new-password"
                        placeholder="Enter new password"
                    />
                </Form.Item>
                {noRepeatPassword ? null : (
                    <Form.Item
                        name="repeatPassword"
                        label="Repeat password"
                        rules={[
                            {
                                required: true,
                                validator: async (rule: any, value: number) => {
                                    if (
                                        value !==
                                        form.getFieldValue('newPassword')
                                    ) {
                                        throw new Error(
                                            'Please repeat new password correctly'
                                        );
                                    }

                                    return true;
                                },
                            },
                        ]}
                    >
                        <PasswordInput
                            autoComplete="new-password"
                            placeholder="Repeat new password"
                        />
                    </Form.Item>
                )}

                <FormFooter
                    loading={loading}
                    submitButtonText="Change"
                    showCancelButton
                    showSubmitButton
                    onCancel={handleCancel}
                />
            </Form>
        </ModalDefaultForm>
    );
}
