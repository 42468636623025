import React from 'react';
import FormItemInlineSwitch, {
    FormItemInlineSwitchProps,
} from '../../DataEntry/FormItemInlineSwitch';

export default DisableFirewallSwitch;

export type DisableFirewallSwitchProps = FormItemInlineSwitchProps & {};

function DisableFirewallSwitch({ ...rest }: DisableFirewallSwitchProps) {
    return (
        <div className="DisableFirewallSwitch">
            <FormItemInlineSwitch
                justify={true}
                name="disableFirewall"
                label="Disable firewall"
                valuePropName="checked"
                {...rest}
            />
        </div>
    );
}
