import { CcClusterType } from './CcCluster';

export type CcRepositoryProps = {
    cluster_type?: string;
    db_version?: string;
    modified?: string;
    name?: string;
    os?: { [key: string]: string };
    path?: string;
    used_by_cluster?: string;
    vendor?: string;
};

export default class CcRepository {
    public readonly clusterType?: CcClusterType;
    public readonly dbVersion?: string;
    public readonly modified?: string;
    public readonly name?: string;
    public readonly os?: { [key: string]: string };
    public readonly path?: string;
    public readonly usedByCluster?: string;
    public readonly vendor?: string;

    constructor(props: CcRepositoryProps) {
        // super(props);
        this.clusterType = props.cluster_type as CcClusterType;
        this.dbVersion = props.db_version;
        this.modified = props.modified;
        this.name = props.name;
        this.os = props.os;
        this.path = props.path;
        this.usedByCluster = props.used_by_cluster;
        this.vendor = props.vendor;
    }
}
