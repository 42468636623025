import React, { useMemo } from 'react';
import { Col, Form, Row, Select, Space } from 'antd';
import InfoIcon from '@severalnines/bar-frontend-components/build/lib/General/InfoIcon';
import CcCluster from '../../../../../services/models/CcCluster';
import CcNode, { CcNodeType } from '../../../../../services/models/CcNode';
import { getNodeHostWithDesc } from '../../../../Nodes/NodeFormat';
import FormItemInlineSwitch from '../../../../../common/DataEntry/FormItemInlineSwitch';
import { FormInstance } from 'antd/lib/form';
import AppContentToggle from '../../../../../common/AppContentToggle';
import AppFormItem from '../../../../../common/Form/AppFormItem';
import AppRadio from '../../../../../common/DataEntry/AppRadio';
import { groupCollectionBy } from '../../../../../common/utils/aggregating';
import NodeTypeFormat from '../../../../Nodes/NodeTypeFormat';

type CreateHAProxyServerInstancesProps = {
    selectedCluster?: CcCluster;
    form: FormInstance;
};

export function getProxyableInstances(cluster: CcCluster) {
    return [
        ...cluster.getDatabaseNodes(),
        ...cluster.getNodesByType(CcNodeType.PGBOUNCER),
    ];
}

export default CreateHAProxyServerInstances;

function CreateHAProxyServerInstances({
    selectedCluster,
    form,
}: CreateHAProxyServerInstancesProps) {
    const isToggleOpen = (node: CcNode) => {
        return form?.getFieldValue('toggle')?.[node.getKey()] || false;
    };

    const handleOnToggle = (node: CcNode) => {
        form?.setFieldsValue({
            toggle: {
                [node.getKey()]: !isToggleOpen(node),
            },
        });
    };
    const proxyableInstances = useMemo(
        () => (selectedCluster ? getProxyableInstances(selectedCluster) : []),
        [selectedCluster]
    );
    const proxyableTypes = useMemo(
        () => Object.keys(groupCollectionBy(proxyableInstances, 'nodetype')),
        [proxyableInstances]
    );

    return (
        <div style={{ minHeight: 450 }}>
            <Row>
                <Col span={24}>
                    <h3>
                        Server instances in the load balancer{' '}
                        <InfoIcon
                            info={
                                <span>
                                    Switch on the instances you want to be
                                    included in the load balancer.
                                </span>
                            }
                        />
                    </h3>
                </Col>
            </Row>
            {proxyableTypes.length > 1 ? (
                <Row>
                    <Col span={24}>
                        <AppFormItem
                            name={'haProxyServerInstancesInstanceType'}
                            initialValue={proxyableTypes[0]}
                        >
                            <AppRadio.Group>
                                {proxyableTypes.map((t) => (
                                    <AppRadio.Button value={t}>
                                        <NodeTypeFormat
                                            type={t as CcNodeType}
                                        />
                                        {' nodes'}
                                    </AppRadio.Button>
                                ))}
                            </AppRadio.Group>
                        </AppFormItem>
                    </Col>
                </Row>
            ) : (
                <AppFormItem
                    name={'haProxyServerInstancesInstanceType'}
                    hidden={true}
                    initialValue={proxyableTypes[0]}
                    noStyle={true}
                />
            )}

            <AppFormItem
                noStyle={true}
                shouldUpdate={(n, o) =>
                    o.haProxyServerInstancesInstanceType !==
                    n.haProxyServerInstancesInstanceType
                }
            >
                {() =>
                    proxyableInstances
                        .filter((i) =>
                            i.isType(
                                form.getFieldValue(
                                    'haProxyServerInstancesInstanceType'
                                )
                            )
                        )
                        .map((node) => {
                            return (
                                <Row
                                    key={`${node.getKey()}-${form.getFieldValue(
                                        'haProxyServerInstancesInstanceType'
                                    )}`}
                                >
                                    <Col span={24}>
                                        <FormItemInlineSwitch
                                            justify={true}
                                            label={`${getNodeHostWithDesc(
                                                node
                                            )}`}
                                            name={[
                                                'haProxyServerInstances',
                                                node.getKey(),
                                                `included`,
                                            ]}
                                            style={{ width: '50%' }}
                                            labelStrong={false}
                                            valuePropName="checked"
                                            extraOnSwitch={
                                                <Row gutter={[24, 0]}>
                                                    <Col span={24}>
                                                        <AppContentToggle
                                                            title="Advanced options"
                                                            onChange={() =>
                                                                handleOnToggle(
                                                                    node
                                                                )
                                                            }
                                                            open={isToggleOpen(
                                                                node
                                                            )}
                                                        >
                                                            <Row
                                                                gutter={[24, 0]}
                                                            >
                                                                <Col span={12}>
                                                                    <Form.Item
                                                                        name={[
                                                                            'haProxyServerInstances',
                                                                            node.getKey(),
                                                                            `role`,
                                                                        ]}
                                                                        label={
                                                                            <Space>
                                                                                Role
                                                                            </Space>
                                                                        }
                                                                    >
                                                                        <Select>
                                                                            {[
                                                                                [
                                                                                    `active`,
                                                                                    'Active',
                                                                                ],
                                                                                [
                                                                                    `backup`,
                                                                                    'Backup',
                                                                                ],
                                                                            ].map(
                                                                                ([
                                                                                    key,
                                                                                    name,
                                                                                ]) => (
                                                                                    <Select.Option
                                                                                        key={
                                                                                            key
                                                                                        }
                                                                                        value={
                                                                                            key
                                                                                        }
                                                                                    >
                                                                                        {
                                                                                            name
                                                                                        }
                                                                                    </Select.Option>
                                                                                )
                                                                            )}
                                                                        </Select>
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col span={12}>
                                                                    <Form.Item
                                                                        name={[
                                                                            'haProxyServerInstances',
                                                                            node.getKey(),
                                                                            `connection`,
                                                                        ]}
                                                                        label={
                                                                            <Space>
                                                                                Connection
                                                                                Address
                                                                            </Space>
                                                                        }
                                                                    >
                                                                        <Select>
                                                                            {node.hostname &&
                                                                                [
                                                                                    [
                                                                                        '1',
                                                                                        `External Address [${node.hostname}]`,
                                                                                    ],
                                                                                ].map(
                                                                                    ([
                                                                                        key,
                                                                                        name,
                                                                                    ]) => (
                                                                                        <Select.Option
                                                                                            key={
                                                                                                key
                                                                                            }
                                                                                            value={
                                                                                                key
                                                                                            }
                                                                                        >
                                                                                            {
                                                                                                name
                                                                                            }
                                                                                        </Select.Option>
                                                                                    )
                                                                                )}
                                                                            {node.hostnameInternal &&
                                                                                [
                                                                                    [
                                                                                        `Internal Address [${node.hostnameInternal}]`,
                                                                                    ],
                                                                                ].map(
                                                                                    ([
                                                                                        key,
                                                                                        name,
                                                                                    ]) => (
                                                                                        <Select.Option
                                                                                            key={
                                                                                                key
                                                                                            }
                                                                                            value={
                                                                                                key
                                                                                            }
                                                                                        >
                                                                                            {
                                                                                                name
                                                                                            }
                                                                                        </Select.Option>
                                                                                    )
                                                                                )}
                                                                        </Select>
                                                                    </Form.Item>
                                                                </Col>
                                                            </Row>
                                                        </AppContentToggle>
                                                    </Col>
                                                </Row>
                                            }
                                        />
                                    </Col>
                                </Row>
                            );
                        })
                }
            </AppFormItem>
        </div>
    );
}
