import { ReactNode, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    AppState,
    AppStateUser,
    AppStateUserUIConfig,
    setUser,
} from '../../appReducer';

import useFetch, { UseFetchProps } from '../../common/useFetch';
import moment from 'moment-timezone';

import CmonUsersService from '../../services/cmon/CmonUsersService';
import CcTreeItem, {
    CcTreeItemAccess,
    CcTreeItemHandledAclPath,
    CcTreeItemType,
} from '../../services/models/CcTreeItem';
import useTree from '../UserManagement/useTree';
import CcUser from '../../services/models/CcUser';
import CcCluster from '../../services/models/CcCluster';

export type UseCurrentUserProps = Omit<UseFetchProps, 'fetchFn'> & {
    name?: string | null;
};
export default function useCurrentUser({ name }: UseCurrentUserProps = {}) {
    const [storedRecord, storedUserUIConfig]: [
        AppStateUser,
        AppStateUserUIConfig
    ] = useSelector(({ user, userUIConfig }: AppState) => [user, userUIConfig]);

    const {
        refresh: refreshTree,
        aclItems,
        checkEffectiveAccess,
        checkEffectiveAccessToSomeClusters,
    } = useTree({
        name: 'tree-from-current-user',
    });
    const { error, loading, loaded, data, params, refresh, cancel } = useFetch<
        CcUser
    >({
        name,
        throwError: true,
        fetchFn: async (params, opts) => {
            const response = await CmonUsersService.whoAmI(
                {
                    // ref needed for lastSeenAuditLog otherwise is not update
                    ...params,
                },
                opts
            );
            moment.tz.setDefault(response?.user?.timezone?.name || 'UTC');

            await refreshTree({});
            return response.user;
        },
        cancelFn: async ({ requestId }) => {
            await CmonUsersService.cancelRequest(requestId);
        },
    });
    const [record, setRecord] = useState<AppStateUser | undefined>(
        storedRecord
    );
    const dispatch = useDispatch();

    const setUIConfig = useCallback(
        async (path: string[], value: any) => {
            try {
                const newConfig = storedUserUIConfig?.setIn(path, value);
                const response = await CmonUsersService.setUser({
                    user: {
                        ui_config: JSON.stringify(newConfig?.toJS()),
                        user_name: record?.userName,
                    },
                });
                console.log('setting', response);
            } catch (err) {
                console.log(err);
            }
        },
        [record]
    );

    const checkAccess = useCallback(
        (path, level) => {
            return path === '/*'
                ? checkEffectiveAccessToSomeClusters(level)
                : checkEffectiveAccess(path, level);
        },
        [checkEffectiveAccess, checkEffectiveAccessToSomeClusters]
    );

    const canManage = useCallback(
        (path) =>
            checkAccess(path, CcTreeItemAccess.FULL_ACCESS) ||
            checkAccess(path, CcTreeItemAccess.EXECUTE),
        [checkAccess]
    );

    const canView = useCallback(
        (path) => checkAccess(path, CcTreeItemAccess.READ),
        [checkAccess]
    );

    const canManageUsers = useCallback(
        () =>
            record?.isSuperUser() &&
            canManage(CcTreeItemHandledAclPath.ACL_USER_MANAGER),
        [canManage, record]
    );
    const canManageConfiguration = useCallback(
        () => canManage(CcTreeItemHandledAclPath.ACL_UI_CONFIG),
        [canManage]
    );
    const canExecuteClusters = useCallback(
        () =>
            canManage(CcTreeItemHandledAclPath.ACL_JOB_EXECUTOR_CREATE_CLUSTER),
        [canManage]
    );

    const canManageLdapSettings = useCallback(
        () => canManage(CcTreeItemHandledAclPath.ACL_LDAP_SETTINGS),
        [canManage]
    );

    const canManageCluster = useCallback(
        (clusterId: number) => {
            return aclItems
                .filter(
                    (item: CcTreeItem) =>
                        item.isType(CcTreeItemType.Cluster) &&
                        item.checkEffectiveAccess(CcTreeItemAccess.FULL_ACCESS)
                )
                .map((item: CcTreeItem) => item.clusterId)
                .includes(clusterId);
        },
        [aclItems]
    );
    const canViewCluster = useCallback(
        (clusterId: number) => {
            return aclItems
                .filter(
                    (item: CcTreeItem) =>
                        item.isType(CcTreeItemType.Cluster) &&
                        item.checkEffectiveAccess(CcTreeItemAccess.READ)
                )
                .map((item: CcTreeItem) => item.clusterId)
                .includes(clusterId);
        },
        [aclItems]
    );

    useEffect(() => {
        if (data) {
            dispatch(setUser(data));
            setRecord(data);
        }
    }, [data]);

    useEffect(() => {
        if (storedRecord) {
            setRecord(storedRecord);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [storedRecord]);

    return {
        setUIConfig,
        error,
        loading,
        loaded,
        record,
        refresh,
        cancel,
        canView,
        canManage,
        canManageCluster,
        canViewCluster,
        canExecuteClusters,
        canManageUsers,
        canManageConfiguration,
        canManageLdapSettings,
        ...params,
    };
}

export type ACLViewContentProps = {
    path: CcTreeItemHandledAclPath | string;
    children?: ReactNode;
};

export function ACLViewContent({ path, children }: ACLViewContentProps) {
    const { canView } = useCurrentUser({});

    return (canView(path) ? children : null) as JSX.Element;
}

export type ACLManageContentProps = {
    path: CcTreeItemHandledAclPath | string;
    children?: ReactNode;
};
export function ACLManageContent({ path, children }: ACLManageContentProps) {
    const { canManage } = useCurrentUser({});

    return (canManage(path) ? children : null) as JSX.Element;
}

export type ACLManageClusterContentProps = {
    cluster: CcCluster;
    children?: ReactNode;
};
export function ACLManageClusterContent({
    cluster,
    children,
}: ACLManageClusterContentProps) {
    const { canManageCluster } = useCurrentUser({});

    return (canManageCluster(cluster.clusterId as number)
        ? children
        : null) as JSX.Element;
}
