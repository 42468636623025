import React, { useState } from 'react';
import { FormInstance } from 'antd/lib/form';
import { Button } from 'antd';
import WizardSelectTypeStep, {
    WizardSelectTypeStepItem,
} from '@severalnines/bar-frontend-components/build/lib/Navigation/Wizard/WizardSelectTypeStep';
import WizardConfiguration from '@severalnines/bar-frontend-components/build/lib/Navigation/Wizard/WizardConfiguration';
import SpaceWide from '../../../common/SpaceWide';
import NotificationServicesWizardForm, {
    NotificationServicesWizardFormFields,
} from './NotificationServicesWizardForm';
import { IntegrationType } from './NotificationServicesProviderFormat';

export default NotificationServicesWizard;

export enum NotificationServicesWizardStep {
    LIST = 'list',
    FORMS = 'forms',
}

export type NotificationServicesWizardProps = {
    form: FormInstance;
    activeStep?: NotificationServicesWizardStep;
    onCancel?: () => void;
    onSuccess?: () => void;
    onIntegrationTypeChange?: (type: IntegrationType) => void;
    onSubmit?: (
        fields: NotificationServicesWizardFormFields,
        type: IntegrationType
    ) => void;
};

const NOTIFICATION_SERVICES = [
    {
        name: 'Slack',
        key: IntegrationType.SLACK,
        icon: 'img-logo-slack.svg',
        description:
            'Slack is the collaboration hub that brings the right people, information, and tools together to get work done.',
        documentationLink: getDocumentationLink(IntegrationType.SLACK),
    },

    {
        name: 'Telegram',
        key: IntegrationType.TELEGRAM,
        icon: 'img-logo-telegram.svg',
        description:
            'Telegram is a cloud-based mobile and desktop messaging app with a focus on security and speed. With Telegram, you can send messages, photos, videos and files of any type, as well as create groups for up to 200,000 people or channels for broadcasting to unlimited audiences.',
        documentationLink: getDocumentationLink(IntegrationType.TELEGRAM),
    },
    {
        name: 'ServiceNow',
        key: IntegrationType.SERVICE_NOW,
        icon: 'img-logo-now.svg',
        description:
            'ServiceNow is a cloud-based workflow automation platform that enables enterprise organizations to improve operational efficiencies by streamlining and automating routine work tasks.',
        documentationLink: getDocumentationLink(IntegrationType.SERVICE_NOW),
    },
    {
        name: 'PagerDuty',
        key: IntegrationType.PAGER_DUTY,
        icon: 'img-logo-pagerduty.svg',
        description:
            'Through its SaaS-based platform, PagerDuty empowers developers, DevOps, IT operations and business leaders to prevent and resolve business-impacting incidents for exceptional customer experience.',
        documentationLink: getDocumentationLink(IntegrationType.PAGER_DUTY),
    },
    {
        name: 'VictorOps',
        key: IntegrationType.VICTOR_OPS,
        icon: 'img-logo-victoropts.svg',
        description:
            'VictorOps is the real-time incident management platform that allows DevOps teams to manage their on-duty schedules and deliver problem notifications. VictorOps provides teams with a virtual environment where they can prepare for, react to, and recover from each incident regardless of location or device.',
        documentationLink: getDocumentationLink(IntegrationType.VICTOR_OPS),
    },
    {
        name: 'OpsGenie',
        key: IntegrationType.OPS_GENIE,
        icon: 'img-logo-opsgenie.svg',
        description:
            'Opsgenie is a modern incident management platform that ensures critical incidents are never missed, and actions are taken by the right people in the shortest possible time. Opsgenie receives alerts from your monitoring systems and custom applications and categorizes each alert based on importance and timing.',
        documentationLink: getDocumentationLink(IntegrationType.OPS_GENIE),
    },
    {
        name: 'Webhook',
        key: IntegrationType.WEB_HOOK,
        icon: 'img-logo-webhooks.svg',
        description:
            'A webhook is a lightweight API that powers one-way data sharing triggered by events. Together, they enable applications to share data and functionality, and turn the web into something greater than the sum of its parts. APIs and webhooks both allow different software systems to sync up and share information.',
        documentationLink: getDocumentationLink(IntegrationType.WEB_HOOK),
    },
];

export function getDocumentationLink(integrationType: IntegrationType) {
    switch (integrationType) {
        case IntegrationType.SLACK:
            return 'https://api.slack.com/messaging/webhooks';
        case IntegrationType.TELEGRAM:
            return 'https://core.telegram.org/bots';
        case IntegrationType.SERVICE_NOW:
            return 'https://developer.servicenow.com/dev.do';
        case IntegrationType.PAGER_DUTY:
            return 'https://support.pagerduty.com/docs/services-and-integrations#section-configuring-services-and-integrations';
        case IntegrationType.VICTOR_OPS:
            return 'https://help.victorops.com/knowledge-base/rest-endpoint-integration-guide/';
        case IntegrationType.OPS_GENIE:
            return 'https://support.atlassian.com/opsgenie/docs/configure-a-team-dashboard/#team-integrations';
        case IntegrationType.WEB_HOOK:
            return 'https://docs.webhook.site/index.html';
    }
}

function NotificationServicesWizard({
    form,
    activeStep = NotificationServicesWizardStep.LIST,
    onCancel,
    onSuccess,
    onIntegrationTypeChange,
    onSubmit,
}: NotificationServicesWizardProps) {
    const [integrationType, setIntegrationType] = useState<IntegrationType>(
        IntegrationType.PAGER_DUTY
    );
    const handleNotificationServiceProviderSelect = ({
        key,
    }: WizardSelectTypeStepItem) => {
        setIntegrationType(key as IntegrationType);
        onIntegrationTypeChange?.(key as IntegrationType);
    };
    const handleNoficationServiceConfirm = () => {
        setStep(NotificationServicesWizardStep.FORMS);
    };
    const handleWizardCancel = () => {
        onCancel?.();
    };
    const handleFormCancel = () => {
        setStep(NotificationServicesWizardStep.LIST);
    };
    const [step, setStep] = useState<NotificationServicesWizardStep>(
        activeStep
    );
    return (
        <WizardConfiguration
            activeStep={step}
            steps={[
                {
                    step: NotificationServicesWizardStep.LIST,
                    className: 'CloudCredentialsWizard_select-service',
                    content: (
                        <WizardSelectTypeStep
                            onSelect={handleNotificationServiceProviderSelect}
                            onConfirm={handleNoficationServiceConfirm}
                            footer={
                                <SpaceWide justify="flex-end">
                                    <Button onClick={handleWizardCancel}>
                                        Cancel
                                    </Button>
                                </SpaceWide>
                            }
                            items={NOTIFICATION_SERVICES.map((item) => ({
                                ...item,
                                value: item.key,
                            }))}
                            emptyState={
                                <WizardSelectTypeStep.DetailEmptyState
                                    src={require('../NotificationServices/img-notification-service-zero-state.svg')}
                                    description="Choose a notification service on your left"
                                />
                            }
                        />
                    ),
                },
                {
                    step: NotificationServicesWizardStep.FORMS,
                    content: (
                        <NotificationServicesWizardForm
                            form={form}
                            onCancel={handleFormCancel}
                            integrationType={integrationType}
                            onSubmit={onSubmit}
                        />
                    ),
                },
            ]}
        />
    );
}
