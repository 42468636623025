import React from 'react';
import { ButtonProps } from 'antd/lib/button';

import { CmonJobInstanceCommand } from '../../../services/cmon/models/CmonJobInstance';
import CcNode from '../../../services/models/CcNode';
import ButtonWithForm from '../../../common/General/ButtonWithForm';
import NodeRestartForm from './NodeRestartForm';
import JobActionButton from '../../Jobs/JobActionButton';

export default NodeRestartButton;

export type NodeRestartButtonProps = ButtonProps & {
    node: CcNode;
    children?: React.ReactNode;
    onSuccess?: () => void;
};

function NodeRestartButton({
    node,
    children,
    onSuccess,
    ...rest
}: NodeRestartButtonProps) {
    return (
        <ButtonWithForm
            button={
                <JobActionButton
                    command={CmonJobInstanceCommand.RESTART}
                    clusterId={node.clusterid}
                    extraKey={[node.hostname, node.port]}
                    {...rest}
                >
                    {children || 'Restart node'}
                </JobActionButton>
            }
            form={<NodeRestartForm node={node} onSuccess={onSuccess} />}
        />
    );
}
