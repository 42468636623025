import Button, { ButtonProps } from 'antd/lib/button';
import React, { useState } from 'react';

export default AppButton;

export type AppButtonProps = ButtonProps & {
    asyncLoading?: boolean;
    loadingContent?: React.ReactNode;
};

function AppButton({
    asyncLoading = false,
    loadingContent,
    loading,
    onClick,
    children,
    ...rest
}: AppButtonProps) {
    const [localLoading, setLocalLoading] = useState<boolean>(false);
    const handleClick = async (e: any) => {
        if (asyncLoading) {
            setLocalLoading(true);
            await onClick?.(e);
            setLocalLoading(false);
        } else {
            await onClick?.(e);
        }
    };
    const isLoading = loading || localLoading;
    return (
        <Button loading={isLoading} onClick={handleClick} {...rest}>
            {isLoading && loadingContent ? loadingContent : children}
        </Button>
    );
}
