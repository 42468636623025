import CcNode, { CcNodeType } from './CcNode';
import { CmonHostDistribution } from '../cmon/models/CmonHost';

export interface CcHostProps {
    hostname: string;
    hostId: number;
    types: CcNodeType[];
    distribution: CmonHostDistribution;
    nodes: CcNode[];
}

export default class CcHost {
    public readonly hostname: string;
    public readonly hostId: number;
    public readonly types: CcNodeType[];
    public readonly distribution: CmonHostDistribution;
    public readonly nodes: CcNode[];
    constructor(props: CcHostProps) {
        this.hostname = props.hostname;
        this.hostId = props.hostId;
        this.types = props.types;
        this.distribution = props.distribution;
        this.nodes = props.nodes;
    }

    public hasNodeType(type: CcNodeType) {
        return this.types.includes(type);
    }
}
