import React from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { ButtonProps } from 'antd/lib/button';
import { ServiceWizardActionType, ServiceWizardProps } from './ServiceWizard';
import ButtonWithForm from '../../common/General/ButtonWithForm';
import ServiceWizardModal from './ServiceWizardModal';

export type CreateServiceButtonProps = {
    defaultActionType?: ServiceWizardActionType;
    wizardProps?: ServiceWizardProps;
} & ButtonProps;
const CreateServiceButton = ({
    children,
    type,
    onClick,
    wizardProps,
}: CreateServiceButtonProps) => {
    const handleClick = (event: any) => {
        if (onClick) {
            onClick(event);
        }
    };
    return (
        <ButtonWithForm
            button={
                <Button
                    className="AddServiceButton"
                    type={type}
                    onClick={handleClick}
                    icon={<PlusOutlined />}
                >
                    {children || 'Deploy a cluster'}
                </Button>
            }
            form={<ServiceWizardModal wizardProps={wizardProps} />}
        />
    );
};

export default CreateServiceButton;
