


export interface CmonReportProps  {
    cluster_id?: number;
    content?: string;
    created?: string;
    days?: string;
    document?: string;
    owner?: string;
    path?: string;
    recipients?: string;
    report_id?: number;
    report_type?: string;
    text_format?: string;
    title?: number;

}



export default class CmonReport {
    public clusterId?: number;
    public content?: string;
    public created?: string;
    public days?: string;
    public document?: string;
    public owner?: string;
    public path?: string;
    public recipients?: string;
    public reportId?: number;
    public reportType?: string;
    public textFormat?: string;
    public title?: number;


    constructor(props: CmonReportProps) {
        this.clusterId = props.cluster_id;
        this.content = props.content;
        this.created = props.created;
        this.days = props.days;
        this.document = props.document;
        this.owner = props.owner;
        this.path = props.path;
        this.recipients = props.recipients;
        this.reportId = props.report_id;
        this.reportType = props.report_type;
        this.textFormat = props.text_format;
        this.title = props.title;

    }

}
