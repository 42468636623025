


export interface CmonAlarmProps  {
    alarm_id?: number;
    cluster_id?: number;
    cluster_name?: string;
    component?: number;
    component_name?: string;
    controller_hostname?: string;
    counter?: number;
    created?: string;
    host_id?: number;
    hostname?: string;
    ignored?: number;
    measured?: number;
    message?: string;
    recommendation?: string;
    reported?: string;
    severity?: number;
    severity_name?: string;
    title?: string;
    type?: number;
    type_name?: string;

}



export default class CmonAlarm {
    public alarmId?: number;
    public clusterId?: number;
    public clusterName?: string;
    public component?: number;
    public componentName?: string;
    public controllerHostname?: string;
    public counter?: number;
    public created?: string;
    public hostId?: number;
    public hostname?: string;
    public ignored?: number;
    public measured?: number;
    public message?: string;
    public recommendation?: string;
    public reported?: string;
    public severity?: number;
    public severityName?: string;
    public title?: string;
    public type?: number;
    public typeName?: string;


    constructor(props: CmonAlarmProps) {
        this.alarmId = props.alarm_id;
        this.clusterId = props.cluster_id;
        this.clusterName = props.cluster_name;
        this.component = props.component;
        this.componentName = props.component_name;
        this.controllerHostname = props.controller_hostname;
        this.counter = props.counter;
        this.created = props.created;
        this.hostId = props.host_id;
        this.hostname = props.hostname;
        this.ignored = props.ignored;
        this.measured = props.measured;
        this.message = props.message;
        this.recommendation = props.recommendation;
        this.reported = props.reported;
        this.severity = props.severity;
        this.severityName = props.severity_name;
        this.title = props.title;
        this.type = props.type;
        this.typeName = props.type_name;

    }

}
