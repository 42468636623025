
import CmonRequestService, {CmonRequestServiceResponseData} from './CmonRequestService';


export interface GetStatisticsRequestData {
    cluster_id?: number;
    request_created?: string;
    request_id?: number;
    [key: string]: any;
}

export interface GetAlarmRequestData {
    alarm_id?: number;
    request_created?: string;
    request_id?: number;
    [key: string]: any;
}

export interface GetAlarmsRequestData {
    class_name?: string;
    cluster_id?: number;
    request_created?: string;
    request_id?: number;
    [key: string]: any;
}

export interface IgnoreAlarmRequestData {
    alarm_id?: number;
    ignore?: boolean;
    request_created?: string;
    request_id?: number;
    [key: string]: any;
}


export default class CmonAlarmService extends CmonRequestService {
    
    public static module(): string {
        return 'alarm';
    }
    
    public static async getStatistics(
        data: GetStatisticsRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('getStatistics', data, opts);
    }

    public static async getAlarm(
        data: GetAlarmRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('getAlarm', data, opts);
    }

    public static async getAlarms(
        data: GetAlarmsRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('getAlarms', data, opts);
    }

    public static async ignoreAlarm(
        data: IgnoreAlarmRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('ignoreAlarm', data, opts);
    }

}
