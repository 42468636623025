import React from 'react';
import TopologySummary, { TopologySummaryProps } from '../TopologySummary';

export default MongoShardsRouterTopologySummary;

export type MongoShardsRouterTopologySummaryProps = Omit<
    TopologySummaryProps,
    'configurator'
> & {};

function MongoShardsRouterTopologySummary({
    ...props
}: MongoShardsRouterTopologySummaryProps) {
    return (
        <div className="MongoShardsRouterTopologySummary">
            <TopologySummary
                title="Router/Mongos servers"
                singleNode={true}
                topologyFieldPath={['routersMongosTopology']}
                {...props}
            />
        </div>
    );
}
