import './AppLink.less';
import React from 'react';
import { Link, LinkProps } from 'react-router-dom';
import classNames from 'classnames';

export default AppLink;

export type AppLinkProps = LinkProps & { hoverable?: boolean };

function AppLink({
    hoverable = false,
    children,
    className,
    ...rest
}: AppLinkProps) {
    return (
        <Link
            className={classNames(
                'AppLink',
                { 'AppLink--hoverable': hoverable },
                className
            )}
            {...rest}
        >
            {children}
        </Link>
    );
}
