import merge from 'deepmerge';
import React from 'react';
import {
    AddClusterNodeConfigFormValues,
    ClusterConfigurator,
    ClusterConfiguratorFormValues,
} from '../ClusterConfigurator';
import { CcClusterType } from '../../../../services/models/CcCluster';
import { TopologyItem } from '../../../Topology/TopologyItem';
import MssqlNodeConfiguration, {
    MssqlNodeConfigurationProps,
} from './MssqlNodeConfiguration';
import MssqlNodeSummary, { MssqlNodeSummaryProps } from './MssqlNodeSummary';
import { Space } from 'antd';
import { generate } from 'generate-password';
import { FormInstance } from 'antd/lib/form';
import Alert from '@severalnines/bar-frontend-components/build/lib/Feedback/Alert';
import ClusterNodesForm from '../ClusterNodesForm';

export const MSSQL_PASSWORD_SYMBOLS = '!#$%^&*+_|:"/?.><,`~-';
export const REGEX_IP = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/g;

export interface MssqlFormValues extends ClusterConfiguratorFormValues {
    nodeConfig: AddClusterNodeConfigFormValues & {
        serverPort?: number;
        dbUser?: string;
        dbPassword?: string;
        clusterAutoRecovery?: boolean;
        nodeAutoRecovery?: boolean;
    };
}

export default class MssqlConfigurator extends ClusterConfigurator {
    public static getDefaults(): MssqlFormValues {
        return merge(ClusterConfigurator.getDefaults(), {
            nodeConfig: {
                serverPort: 1433,
                dbUser: 'SQLServerAdmin',
                dbPassword: generate({
                    length: 16,
                    numbers: true,
                    lowercase: true,
                    uppercase: true,
                    symbols: MSSQL_PASSWORD_SYMBOLS,
                    strict: true,
                }),
            },
        });
    }

    public static getJobData(formValues: MssqlFormValues): any {
        const { topology, nodeConfig, topologyDataIps } = formValues;
        return merge(ClusterConfigurator.getJobData(formValues), {
            cluster_type: (topology.length > 1
                ? CcClusterType.TYPE_MSSQL_AO_ASYNC
                : CcClusterType.TYPE_MSSQL_SINGLE
            ).toLowerCase(),
            db_user: nodeConfig.dbUser,
            db_password: nodeConfig.dbPassword,
            nodes: topology.map((currentNode: TopologyItem) => ({
                class_name: 'CmonMsSqlHost',
                hostname: currentNode.extraData.hostname,
                hostname_data: currentNode.extraData.hostname,
                hostname_internal:
                    topologyDataIps?.[currentNode.extraData.hostname] || '',
                port: nodeConfig.serverPort,
                nodetype: '',
                configfile: '/var/opt/mssql/mssql.conf',
                datadir: '/var/opt/mssql/data',
            })),
        });
    }

    public static getVendors() {
        return [
            {
                name: 'Microsoft',
                value: 'microsoft',
                versions: [
                    { name: '2022', value: '2022' },
                    { name: '2019', value: '2019' },
                ],
            },
        ];
    }

    public static getNodeConfigurationStep(
        props: MssqlNodeConfigurationProps
    ): React.ReactNode {
        return <MssqlNodeConfiguration {...props} />;
    }

    public static getNodeConfigurationValidate(
        form?: FormInstance
    ): ((() => void) | string[])[] {
        return [
            ['nodeConfig', 'serverPort'],
            ['nodeConfig', 'dbUser'],
            ['nodeConfig', 'dbPassword'],
        ];
    }

    public static getNodeConfigurationSummary(
        props: MssqlNodeSummaryProps
    ): React.ReactNode {
        return <MssqlNodeSummary {...props} />;
    }

    public static getTopologyStep(form: FormInstance): React.ReactNode {
        return (
            <div>
                <ClusterNodesForm
                    form={form}
                    nodesInputProps={{
                        validateItem: topologyItemValidator,
                        formProps: {
                            primaryPlaceholder: 'Type node FQDN',
                            secondaryPlaceholder: 'Type node FQDN',
                        },
                    }}
                />
                <Alert
                    message={
                        <Space direction="vertical" size={0}>
                            <span>Up to 8 nodes are supported.</span>
                            <span>
                                Always On with Asynchronous-commit mode only at
                                this time.
                            </span>
                        </Space>
                    }
                    type="info"
                    showIcon={true}
                />
            </div>
        );
    }

    public static getTopologyValidate(form: FormInstance) {
        const topology: TopologyItem[] = form.getFieldValue('topology');
        return [
            () => {
                if (!topology?.length) {
                    throw new Error('At least one node is needed');
                } else if (topology?.length > 9) {
                    throw new Error(
                        'A maximum of eight nodes can be specified'
                    );
                }
            },
            ...ClusterConfigurator.getTopologyValidate(form),
        ];
    }
}

export function topologyItemValidator(item: TopologyItem) {
    if (REGEX_IP.test(item.extraData.hostname)) {
        throw new Error('FQDN is required, IP is not supported');
    }
    return item;
}
