import RequestService from './RequestService';

export interface CmonRequestServiceResponseData {
    request_created?: string;
    request_id?: number;
    request_processed?: string;
    request_status?: string;
    request_user_id?: number;
    error_string?: string;
    [key: string]: any;
};

let cmonApiUrl: string = 'http://localhost:8080';

export default class CmonRequestService extends RequestService {
    protected static addr(): string {
        return `${cmonApiUrl}/${this.module()}`;
    }

    public static setAddr(addr: string): void {
        cmonApiUrl = addr;
    }

    public static module(): void {
        throw new Error(`module() is not implemented`);
    }

    public static async doRequest(
        operation: string,
        data?: any,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        const cookies = Object.fromEntries(
            document.cookie.split(/; */).map((cookie) => cookie.split('=', 2))
        );
        let path = operation && operation.indexOf('/') === 0 ? operation : '';
        const {path: optsPath} = opts || {};
        if (optsPath) {
            path += optsPath;
        }
        const resData: any = await super.doRequest(
            path,
            {
                ...data,
                operation: path ? undefined : operation,
            },
            {
                ...opts,
                ...(cookies['cmon-sid']
                    ? { Cookie: `cmon-sid=${cookies['cmon-sid']}` }
                    : {}),
            }
        );

        if (resData.request_status !== 'Ok') {
            const errorModule = await import(
                `./errors/Cmon${resData.request_status}Error`
                );
            throw new errorModule.default(resData);
        }

        return await transformInstances(resData);
    }
}


async function getModule(className: string) {
    switch (className) {
        
        case 'CmonUser':
            return require('../models/CcUser');

        case 'CmonGroup':
            return require('../models/CcGroup');

        case 'CmonClusterInfo':
            return require('../models/CcCluster');

        case 'CmonJobInstance':
            return require('../models/CcJob');

        case 'CmonBackupRecord':
            return require('../models/CcBackup');

        case 'CmonLogMessage':
            return require('../models/CcLogEntry');

        case 'CmonAlarm':
            return require('../models/CcAlarm');

        case 'CmonRepository':
            return require('../models/CcRepository');

        case 'CmonLicense':
            return require('../models/CcLicense');

        case 'CmonLicenseCheck':
            return require('../models/CcLicenseCheck');

        case 'CmonHost':
            return require('../models/CcNode');

        case 'CmonHostReplicationSlave':
            return require('../models/CcNodeReplicationSlave');

        case 'CmonMySqlHost':
            return require('../models/CcMySqlNode');

        case 'CmonGaleraHost':
            return require('../models/CcGaleraNode');

        case 'CmonMsSqlHost':
            return require('../models/CcMsSqlNode');

        case 'CmonAuditEntry':
            return require('../models/CcAuditLog');

        case 'CmonProxySqlSchedule':
            return require('../models/CcProxySqlSchedule');

        case 'CmonProxySqlUser':
            return require('../models/CcProxySqlUser');

        case 'CmonTreeItem':
            return require('../models/CcTreeItem');

        case 'CmonProxySqlProcessList':
            return require('../models/CcProxySqlProcessList');

        case 'CmonAccount':
            return require('../models/CcDatabaseAccount');

        case 'CmonStats':
            return require('../models/CcStats');

        case 'CmonDbStats':
            return require('../models/CcDbStats');

        case 'CmonAgentHost':
            return require('../models/CcAgentNode');

        case 'CmonPkgInfo':
            return require('../models/CcPkgInfo');

        case 'CmonReport':
            return require('../models/CcReport');
        default:
            return require(`./models/${className}`);
    }
}

/**
 * Recursively convert objects that has class_name defined
 * to the actual instance of it
 * @param data
 */
export async function transformInstances(data: any) {
    if (typeof data === 'object' && data !== null) {
        if (Array.isArray(data) && data.length !== undefined) {
            data = await Promise.all(data.map(transformInstances));
        } else {
            for (let prop in data) {
                if (prop === 'class_name') {
                    try {
                        const module = await getModule(data[prop]);
                        return new module.default(data);
                    } catch (err) {
                        console.debug(err.message);
                        return data;
                    }
                } else {
                    if (typeof data[prop] === 'object' && data[prop] !== null) {
                        data[prop] = await transformInstances(data[prop]);
                    }
                }
            }
        }
    }
    return data;
}