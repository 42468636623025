import React, { useState } from 'react';
import { Form } from 'antd';
import CcUser from '../../../services/models/CcUser';
import ModalDefaultForm from '../../../common/ModalDefaultForm';
import CcGroup from '../../../services/models/CcGroup';
import UserDetailsForm from '../UserDetailsForm';
import FormFooter from '../../../common/FormFooter';
import GroupField from '../GroupField';
import moment from 'moment';
import CmonUsersService from '../../../services/cmon/CmonUsersService';
import {
    notifyError,
    notifyOperationSuccess,
    NotifyType,
} from '../../Notifications/uiNotification';
import { FormInstance } from 'antd/es';

export default UserEditModal;

export type UserEditModalProps = {
    user: CcUser;
    onCancel?: () => void;
    onSuccess?: () => void;
    onError?: (error: any) => void;
    form?: FormInstance;
};

export interface UserEditWizardFormValues {
    firstName?: string;
    lastName?: string;
    username?: string;
    email?: string;
    timezone?: string;
    group?: CcGroup;
}

function UserEditModal({
    user,
    onCancel,
    onSuccess,
    onError,
    form: parentForm,
}: UserEditModalProps) {
    const [loading, setLoading] = useState(false);
    const [internalForm] = Form.useForm<UserEditWizardFormValues>();
    const form = parentForm || internalForm;
    const handleCancel = () => {
        onCancel?.();
    };

    const handleSubmit = async (fields: UserEditWizardFormValues) => {
        try {
            setLoading(true);
            const timezone = fields.timezone
                ? moment.tz(fields.timezone)
                : undefined;
            await CmonUsersService.setUser({
                user: {
                    class_name: 'CmonUser',
                    user_name: user.userName,
                    email_address: fields.email,
                    first_name: fields.firstName,
                    last_name: fields.lastName,
                    timezone: {
                        class_name: 'CmonTimeZone',
                        abbreviation: timezone?.zoneAbbr(),
                        name: fields.timezone,
                        offset: timezone?.utcOffset(),
                        use_dst: false,
                    },
                    groups: [
                        {
                            class_name: 'CmonGroup',
                            group_name: fields.group?.groupName,
                            group_id: fields.group?.groupId,
                        },
                    ],
                },
            });
            setLoading(false);
            notifyOperationSuccess({
                type: NotifyType.TOAST,
                title: <span>User edited succesfully.</span>,
            });
            onSuccess?.();
        } catch (e: any) {
            notifyError({
                content: e.message,
            });
            setLoading(false);
            onError?.(e);
        }
    };
    return (
        <ModalDefaultForm
            title={`Edit user ${user.userName}`}
            width={450}
            form={form}
            onCancel={handleCancel}
            defaultVisible={true}
            bodyStyle={{ padding: '20px' }}
            loading={loading}
        >
            <Form
                form={form}
                layout="vertical"
                onFinish={handleSubmit}
                initialValues={{
                    firstName: user.firstName,
                    lastName: user.lastName,
                    username: user.userName,
                    email: user.emailAddress,
                    timezone: user.timezone?.name,
                    group: user.getGroup(),
                }}
            >
                <UserDetailsForm oneColumn={true} />
                <GroupField />
                <FormFooter
                    loading={loading}
                    submitButtonText="Save"
                    showCancelButton
                    showSubmitButton
                    onCancel={handleCancel}
                />
            </Form>
        </ModalDefaultForm>
    );
}
