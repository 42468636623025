import React from 'react';
import ButtonWithForm from '../../../common/General/ButtonWithForm';
import { ButtonProps } from 'antd/lib/button';
import CcGroup from '../../../services/models/CcGroup';
import GroupDetailsModal from './GroupDetailsModal';
import AppActionButton from '../../../common/General/AppActionButton';

export default GroupDetailsButton;

export type GroupDetailsButtonProps = ButtonProps & {
    group: CcGroup;
    onEdit?: () => void;
};

function GroupDetailsButton({
    group,
    onEdit,
    children,
    ...rest
}: GroupDetailsButtonProps) {
    return (
        <ButtonWithForm
            button={
                <AppActionButton {...rest}>
                    {children || 'View'}
                </AppActionButton>
            }
            form={<GroupDetailsModal group={group} onEdit={onEdit} />}
        />
    );
}
