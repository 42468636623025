import { useCallback, useEffect, useRef, useState } from 'react';
import { Map } from 'immutable';

type FetchParams = {
    [key: string]: any;
};
export type FetchOptions = {
    requestId: string;
    requestName?: string;
    useCache?: boolean;
};
export type FetchRefreshFunctionParams = FetchParams & {
    autoRefresh?: number;
    showLoading?: boolean;
    useCache?: boolean;
};
export type UseFetchProps = {
    fetchFn: (
        params: FetchParams,
        options: FetchOptions
    ) => Promise<{ [key: string]: any }>;
    name?: string | null;
    cancelFn?: (params: {
        requestId: string;
        requestName: string;
    }) => Promise<void>;
    useCache?: boolean;
    throwError?: boolean;
    throwCancel?: boolean;
    autoFetch?: boolean;
};

export type UseFetchReturn<T> = {
    requestId: string;
    error: Error | null;
    loading: boolean;
    loaded: boolean;
    data: T;
    refresh: (params?: FetchRefreshFunctionParams) => Promise<T>;
    cancel: (params?: FetchOptions) => void;
    stopAutoRefresh: () => void;
    params: FetchParams;
};

export default function useFetch<T>({
    name,
    fetchFn,
    cancelFn,
    useCache: fetchCache = false,
    throwError,
    throwCancel,
    autoFetch = false,
    ...rest
}: UseFetchProps): UseFetchReturn<T> {
    const [error, setError] = useState<Error | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [loaded, setLoaded] = useState<boolean>(false);
    const [fetchData, setFetchData] = useState<any | undefined>();
    const fetchParams = useRef<Map<string, any>>(Map(rest));

    const [requestId] = useState<string>(`${name}-${new Date().getTime()}`);
    const timeout = useRef<any>(null);

    const cancel = useCallback(
        async (
            { requestId: requestIdToCancel }: FetchOptions = {
                requestId: requestId,
            }
        ) => {
            setLoading(false);
            if ((requestIdToCancel || requestId) && cancelFn) {
                try {
                    await cancelFn({
                        requestId: requestIdToCancel || requestId,
                        requestName: name || requestIdToCancel || requestId,
                    });
                } catch (err) {
                    console.error(err);
                }
            }
        },
        [requestId, cancelFn, name]
    );

    const refresh = useCallback<
        (p?: FetchRefreshFunctionParams, o?: FetchOptions) => Promise<any>
    >(
        async (
            {
                autoRefresh,
                showLoading = true,
                useCache = fetchCache,
                ...rest
            } = {},
            { requestId: requestIdForRefresh } = { requestId: requestId }
        ) => {
            setLoading(showLoading);
            setError(null);

            const params = { ...fetchParams.current.toObject(), ...rest };
            fetchParams.current = Map(params);
            let data;
            try {
                data = await fetchFn(params, {
                    requestId: requestIdForRefresh || requestId,
                    requestName: name || requestIdForRefresh || requestId,
                    ...(useCache !== undefined && useCache !== null
                        ? { useCache }
                        : {}),
                });
                setFetchData(data);
                setLoading(false);
                setLoaded(true);
            } catch (err: any) {
                if (!err.isCanceled) {
                    setFetchData(undefined);
                    setError(err);
                    setLoading(false);
                    if (throwError) {
                        throw err;
                    }
                } else {
                    if (throwCancel) {
                        throw err;
                    }
                    console.debug(
                        `Request canceled, requestName: ${name}, requestId: ${requestIdForRefresh}`
                    );
                }
            }
            if (autoRefresh) {
                timeout.current = setTimeout(async () => {
                    await refresh({
                        showLoading: false,
                        autoRefresh,
                    });
                }, autoRefresh);
            }
            return data;
        },
        [requestId, fetchCache, fetchFn, name, throwCancel, throwError]
    );

    const stopAutoRefresh = useCallback(() => {
        if (timeout.current !== null) {
            clearTimeout(timeout.current);
            timeout.current = null;
        }
    }, []);

    useEffect(() => {
        if (autoFetch) {
            (async () => {
                await refresh();
            })();
        }
        return () => {
            // stop refresh when component is removed
            stopAutoRefresh();
            // cancel request if it is ongoing
            cancel();
        };
    }, []);

    return {
        requestId,
        error,
        loading,
        loaded,
        data: fetchData,
        refresh,
        cancel,
        stopAutoRefresh,
        params: fetchParams.current.toObject(),
    };
}
