import React, { useState, useMemo, useCallback } from 'react';
import { Col, Row, Space, InputNumber, Select, Switch } from 'antd';
import { Form } from 'antd';
import { FormInstance } from 'antd/lib/form';
import CcCluster, {
    CcClusterType,
} from '../../../../../services/models/CcCluster';
import { getHostWithDesc } from '../../../../Nodes/NodeFormat';
import FormItemInlineSwitch from '../../../../../common/DataEntry/FormItemInlineSwitch';
import SshCheckableNodesAutocomplete from '../../../../../common/DataEntry/SshCheckeableNodesAutocomplete';
import { DefaultOptionType } from 'rc-select/lib/Select';
import { getTopologyValidator } from '../../../loadBalancerHelpers';
import { CcNodeType } from '../../../../../services/models/CcNode';
import { TopologyItem } from '../../../../Topology/TopologyItem';

type CreateHAProxyWhereProps = {
    selectedCluster?: CcCluster;
    form: FormInstance;
};

export const haProxyWhereInitialValues = (selectedCluster?: CcCluster) => {
    const type = selectedCluster?.clusterType;
    return {
        haProxyWhere: {
            portRW:
                type === CcClusterType.TYPE_POSTGRESQL ||
                type === CcClusterType.TYPE_TIMESCALEDB
                    ? 5433
                    : 3307,
            portRO:
                type === CcClusterType.TYPE_POSTGRESQL ||
                type === CcClusterType.TYPE_TIMESCALEDB
                    ? 5434
                    : 3308,
            rwSplitting:
                selectedCluster?.clusterType === CcClusterType.TYPE_GALERA
                    ? false
                    : true,
            firewall: true,
            appArmor: true,
            policy: 'lastconn',
        },
    };
};

export default CreateHAProxyWhere;

function CreateHAProxyWhere({
    selectedCluster,
    form,
}: CreateHAProxyWhereProps) {
    const getOptions = useCallback((): DefaultOptionType[] | undefined => {
        return selectedCluster?.getHosts().map((host) => {
            return {
                value: `${host.hostname}`,
                label: getHostWithDesc(host),
                disabled: host.hasNodeType(CcNodeType.HAPROXY),
            };
        });
    }, [selectedCluster]);

    const options: DefaultOptionType[] | undefined = useMemo(
        () => getOptions(),
        [selectedCluster]
    );

    const [checkSplitting, setCheckSplitting] = useState<boolean>(
        selectedCluster?.clusterType === CcClusterType.TYPE_GALERA
            ? false
            : true
    );

    const onChangeHandler = (value: boolean) => {
        setCheckSplitting(value);
    };

    return (
        <div style={{ minHeight: 450 }}>
            <Row>
                <Col span={24}>
                    <h3>Where to install</h3>
                </Col>
            </Row>
            <Row gutter={[24, 0]}>
                <Col span={24}>
                    <Form.Item
                        name={['haProxyWhere', 'address']}
                        label={<Space>Server Address</Space>}
                        rules={[
                            {
                                required: true,
                                message: 'Please select server address.',
                            },
                        ]}
                    >
                        <SshCheckableNodesAutocomplete
                            validateItem={async (item: TopologyItem) => {
                                try {
                                    if (
                                        selectedCluster
                                            ?.getHosts()
                                            .find(
                                                (host) =>
                                                    host.hostname ===
                                                        item.extraData
                                                            .hostname &&
                                                    host.hasNodeType(
                                                        CcNodeType.HAPROXY
                                                    )
                                            )
                                    ) {
                                        throw new Error(
                                            'This host already has HAProxy'
                                        );
                                    }
                                    return item;
                                } catch (error) {
                                    throw error;
                                }
                            }}
                            clusterId={selectedCluster?.clusterId}
                            emptyState={null}
                            singleNode={true}
                            formProps={{
                                primaryTitle: null,
                                primaryFormItemProps: {
                                    withLessMarginBottom: true,
                                },
                            }}
                            autocompleteOptions={options}
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        name={['haProxyWhere', 'policy']}
                        label={<Space>Policy</Space>}
                        rules={[
                            {
                                required: true,
                                message: 'Please select policy.',
                            },
                        ]}
                    >
                        <Select placeholder="Click here to select a policy">
                            {[
                                ['leastconn', 'leastconn'],
                                ['roundrobin', 'roundrobin'],
                                ['source', 'source'],
                            ].map(([key, name]) => (
                                <Select.Option key={key} value={key}>
                                    {name}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        name={['haProxyWhere', 'portRW']}
                        label={<Space>Listen Port (Read/Write)</Space>}
                        rules={[
                            {
                                required: true,
                                message: 'Enter port number.',
                            },
                        ]}
                    >
                        <InputNumber
                            min={0}
                            style={{ width: '100%' }}
                            placeholder="Enter port number."
                            onChange={(value: number) =>
                                form.setFieldsValue({
                                    haProxyAdvanced: {
                                        backendNameRW: `haproxy_${value}_rw`,
                                    },
                                })
                            }
                        ></InputNumber>
                    </Form.Item>
                </Col>
                {checkSplitting && (
                    <Col span={12}>
                        <Form.Item
                            name={['haProxyWhere', 'portRO']}
                            label={<Space>Listen Port (Read Only)</Space>}
                            rules={[
                                {
                                    required: true,
                                    message: 'Enter port number.',
                                },
                            ]}
                        >
                            <InputNumber
                                min={0}
                                style={{ width: '100%' }}
                                placeholder="Enter port number."
                                onChange={(value: number) =>
                                    form.setFieldsValue({
                                        haProxyAdvanced: {
                                            backendNameRO: `haproxy_${value}_ro`,
                                        },
                                    })
                                }
                            ></InputNumber>
                        </Form.Item>
                    </Col>
                )}
            </Row>
            <Row gutter={[24, 0]}>
                <Col span={12}>
                    <Row wrap={false}>
                        <Col>
                            Install for read/write splitting (master-slave
                            replication)
                        </Col>
                        <Col>
                            <Form.Item name={['haProxyWhere', 'rwSplitting']}>
                                <Switch
                                    checked={checkSplitting}
                                    checkedChildren={<span>On</span>}
                                    unCheckedChildren={<span>Off</span>}
                                    onChange={onChangeHandler}
                                    disabled={
                                        selectedCluster?.clusterType ===
                                        CcClusterType.TYPE_GALERA
                                            ? false
                                            : true
                                    }
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Col>
                <Col span={24}>
                    <h3>Security configuration</h3>
                </Col>
                <Col span={12}>
                    <Row justify="space-between" align="middle">
                        <Col span={24}>
                            <FormItemInlineSwitch
                                justify={true}
                                label="Firewall"
                                name={['haProxyWhere', 'firewall']}
                                labelStrong={false}
                                valuePropName="checked"
                            />
                        </Col>
                    </Row>
                    <Row justify="space-between" align="middle">
                        <Col span={24}>
                            <FormItemInlineSwitch
                                justify={true}
                                label="AppArmor/SELinux"
                                name={['haProxyWhere', 'appArmor']}
                                labelStrong={false}
                                valuePropName="checked"
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    );
}

export function getCreateHaProxyWhereValidation(form: FormInstance) {
    return [
        ['haProxyWhere', 'address'],
        ['haProxyWhere', 'policy'],
        ['haProxyWhere', 'portRW'],
        ['haProxyWhere', 'portRO'],
        getTopologyValidator(form, ['haProxyWhere', 'address']),
    ];
}
