import './UserAvatar.less';
import React from 'react';
import CcUser from '../../services/models/CcUser';
import Avatar, { AvatarProps } from '../../common/Avatar';
import FlagImage from '@severalnines/bar-frontend-components/build/lib/General/FlagImage';
import { Space } from 'antd';

export default UserAvatar;

export type UserAvatarProps = Omit<AvatarProps, 'name'> & {
    user: CcUser;
    country?: string;
};

function UserAvatar({ user, country, ...rest }: UserAvatarProps) {
    return (
        <Space direction="vertical" align="center" size={1}>
            <Avatar
                name={user.getName()}
                showAvatar={!user.hasName()}
                colorGenerateKey={`${user.userId}-${user.userName}-${user.origin}`}
                {...rest}
            />

            {country && <FlagImage countryCode={country} />}
        </Space>
    );
}
