export enum CcStatsBaseAttentionLevel {
    WARN = 'WARN',
    ERROR = 'ERROR',
    ALL = 'ALL',
}

export type CcStatsBaseProps = {
    statsCount: { [key: string]: number };
    attentionWarnStats?: string[];
    attentionErrorStats?: string[];
};

export default class CcStatsBase {
    public statsCount: { [key: string]: number };
    public attentionStats: { [key in CcStatsBaseAttentionLevel]?: string[] };

    constructor(props: CcStatsBaseProps) {
        this.statsCount = props.statsCount || {};
        this.attentionStats = {};
        this.attentionStats[CcStatsBaseAttentionLevel.ERROR] =
            props.attentionErrorStats || [];
        this.attentionStats[CcStatsBaseAttentionLevel.WARN] =
            props.attentionWarnStats || [];
        this.attentionStats[CcStatsBaseAttentionLevel.ALL] = [
            ...(props.attentionErrorStats || []),
            ...(props.attentionWarnStats || []),
        ];
    }

    public getStatsCount(s?: string[] | string): number {
        if (Array.isArray(s)) {
            return s
                .map((s) => this.getStatsCount(s))
                .reduce((total: number, stat: number) => total + stat, 0);
        } else {
            return s
                ? this.statsCount[s] || 0
                : Object.values(this.statsCount).reduce((a, b) => a + b, 0);
        }
    }

    public getAttentionStatsCount(
        level: CcStatsBaseAttentionLevel = CcStatsBaseAttentionLevel.ALL
    ): number {
        return (this.attentionStats[level] || [])
            .map((s) => this.getStatsCount(s))
            .reduce((sum: number, v: number) => sum + v, 0);
    }

    public getAttentionStats(
        level: CcStatsBaseAttentionLevel = CcStatsBaseAttentionLevel.ALL
    ): [string, number][] {
        const statusPairs: [string, number][] = (
            this.attentionStats[level] || []
        ).map((s) => [s, this.getStatsCount(s)]);
        const filtered: [string, number][] = statusPairs.filter(
            ([, v]) => v !== 0
        );
        return filtered.length > 0 ? filtered : statusPairs;
    }

    public getAttentionStatsValues(
        level: CcStatsBaseAttentionLevel = CcStatsBaseAttentionLevel.ALL
    ): string[] {
        return this.getAttentionStats(level).map(([s]) => s);
    }

    public getFirstAttentionStat(
        level: CcStatsBaseAttentionLevel = CcStatsBaseAttentionLevel.ALL
    ): [string, number] {
        return this.getAttentionStats(level)[0];
    }

    public getWarnings(): number {
        return this.getStatsCount(
            this.attentionStats[CcStatsBaseAttentionLevel.WARN]
        );
    }

    public getErrors(): number {
        return this.getStatsCount(
            this.attentionStats[CcStatsBaseAttentionLevel.ERROR]
        );
    }
}
