import React from 'react';
import { Row } from 'antd';
import { FormInstance } from 'antd/lib/form';
import SshCredentials from '../FormParts/SshCredentials';

export default ImportSshConfiguration;

export type ImportSshConfigurationProps = {
    form: FormInstance;
};

function ImportSshConfiguration({ form }: ImportSshConfigurationProps) {
    return (
        <div className="ImportSshConfiguration">
            <Row gutter={[24, 0]}>
                <SshCredentials form={form} />
            </Row>
        </div>
    );
}
