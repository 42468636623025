import React, { useEffect, useState } from 'react';
import { Select, SelectProps } from 'antd';
import CmonRepositoriesService from '../../services/cmon/CmonRepositoriesService';
import CcCluster, { CcClusterType } from '../../services/models/CcCluster';
import CcRepository from '../../services/models/CcRepository';

export default RepositoryInput;

export enum RepositoryItemKey {
    CREATE_NEW = 'create-new',
    DO_NOT_USE_VENDOR = 'dont-use-vendor',
    MIRRORED_GROUP = 'mirrored-item',
    USE_MIRRORED_REPO = 'use-mirror',
    USE_VENDOR = 'use-vendor',
}

export interface RepositoryItem {
    name?: string;
    key: string;
    enabled?: boolean;
    group?: string;
    clusterType?: CcClusterType;
    vendor?: string;
    version?: string;
}

export type RepositoryInputProps = SelectProps<any> & {
    cluster?: CcCluster;
    onChange?: (value: RepositoryItemKey | string) => void;
};

function RepositoryInput({
    cluster,
    onChange,
    loading,
    disabled,
    ...rest
}: RepositoryInputProps) {
    const [repoItems, setRepoItems] = useState<RepositoryItem[]>([]);
    const [loadingData, setLoadingData] = useState<boolean>(false);

    useEffect(() => {
        (async () => {
            let repos = [];
            if (cluster) {
                try {
                    setLoadingData(true);
                    const {
                        repositories,
                    } = await CmonRepositoriesService.getRepositories({
                        cluster_id: cluster.clusterId,
                    });
                    repos.push(repositories);
                } catch (err) {}
                setLoadingData(false);
            }
            setRepoItems([
                {
                    name: getRepositoryText(RepositoryItemKey.USE_VENDOR),
                    key: RepositoryItemKey.USE_VENDOR,
                    enabled: true,
                },
                {
                    name: getRepositoryText(
                        RepositoryItemKey.DO_NOT_USE_VENDOR
                    ),
                    key: RepositoryItemKey.DO_NOT_USE_VENDOR,
                    enabled: true,
                },
            ]);
        })();
    }, []);

    const handleOnChange = (value: RepositoryItemKey) => {
        onChange?.(value);
    };

    return (
        <Select
            className="RepositoryInput"
            style={{ width: '100%' }}
            onChange={handleOnChange}
            disabled={loadingData || disabled}
            loading={loadingData || loading}
            {...rest}
        >
            {repoItems.map((repoItem) => (
                <Select.Option value={repoItem.key} key={repoItem.key}>
                    {repoItem.name}
                </Select.Option>
            ))}
            {/* Uncomment when we handle this feature check thing*/}
            {/*<FeatureDisabledContent*/}
            {/*    feature={CcLicenseFeature.FULL_REPOSITORIES}*/}
            {/*>*/}
            {/*    Contact Sales for enabling more repository options.*/}
            {/*</FeatureDisabledContent>*/}
        </Select>
    );
}

export function buildRepositoriesItemList(
    repositoriesList: CcRepository[],
    fullRepos: boolean
) {
    const result: RepositoryItem[] = [
        {
            name: getRepositoryText(RepositoryItemKey.USE_VENDOR),
            key: RepositoryItemKey.USE_VENDOR,
            enabled: true,
        },
    ];

    if (!fullRepos) {
        return result;
    }

    result.push({
        name: getRepositoryText(RepositoryItemKey.DO_NOT_USE_VENDOR),
        key: RepositoryItemKey.DO_NOT_USE_VENDOR,
        enabled: true,
    });

    if (repositoriesList && repositoriesList.length > 0) {
        result.push({
            name: getRepositoryText(RepositoryItemKey.USE_MIRRORED_REPO),
            key: RepositoryItemKey.USE_MIRRORED_REPO,
            enabled: false,
        });

        repositoriesList.forEach((repo) => {
            if (repo.name) {
                result.push({
                    name: repo ? htmlDecode(`&nbsp;&nbsp;${repo.name}`) : 'N/A',
                    key: repo.name,
                    group: RepositoryItemKey.MIRRORED_GROUP,
                    enabled: true,
                    clusterType: repo.clusterType,
                    vendor: repo.vendor,
                    version: repo.dbVersion,
                });
            }
        });
    }

    result.push({
        name: getRepositoryText(RepositoryItemKey.CREATE_NEW),
        key: RepositoryItemKey.CREATE_NEW,
        enabled: true,
    });

    return result;

    /**
     * Decode the HTML
     **/
    function htmlDecode(input: string): string {
        const e = document.createElement('div');
        e.innerHTML = input;
        return e.childNodes[0].nodeValue || 'N/A';
    }
}

export function getRepositoryText(repoKey: RepositoryItemKey) {
    switch (repoKey) {
        case RepositoryItemKey.CREATE_NEW:
            return 'Create new repository';
        case RepositoryItemKey.USE_VENDOR:
            return 'Use vendor repositories';
        case RepositoryItemKey.DO_NOT_USE_VENDOR:
            return 'Do not setup vendor repositories';
        case RepositoryItemKey.USE_MIRRORED_REPO:
            return 'Use mirrored repositories';
        default:
            return repoKey;
    }
}

export function getRepositoryItemInfo(repoKey: RepositoryItemKey) {
    switch (repoKey) {
        case RepositoryItemKey.CREATE_NEW:
            return "Create/mirror the vendor's repositories. Deploy with the mirrored repositories.";
        case RepositoryItemKey.USE_VENDOR:
            return "Setup and use the vendor's repositories.";
        case RepositoryItemKey.DO_NOT_USE_VENDOR:
            return 'Do not setup vendor repositories. Use existing repositories on the nodes.';
        default:
            return '';
    }
}
