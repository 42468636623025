import React from 'react';
import { InputNumber, InputNumberProps } from 'antd';

export default InputNumberWide;

export type InputNumberWideProps = InputNumberProps & {};

function InputNumberWide({ ...rest }: InputNumberWideProps) {
    return <InputNumber style={{ width: '100%' }} {...rest} />;
}
