import React from 'react';
import { Button, Form, Space } from 'antd';
import FormFooter from '../../../common/FormFooter';
import ModalDefaultForm from '../../../common/ModalDefaultForm';
import CcNode from '../../../services/models/CcNode';
import NodeFormat from '../NodeFormat';
import SpaceWide from '../../../common/SpaceWide';
import InfoIcon from '@severalnines/bar-frontend-components/build/lib/General/InfoIcon';
import useCreateJob from '../../Jobs/useCreateJob';
import { CmonJobInstanceCommand } from '../../../services/cmon/models/CmonJobInstance';
import InputNumberWide from '../../../common/DataEntry/InputNumberWide';
import FormItemInlineSwitch from '../../../common/DataEntry/FormItemInlineSwitch';

export default NodeStepDownForm;

export type NodeStepDownFormProps = {
    node: CcNode;
    onSuccess?: () => void;
    onCancel?: () => void;
};

export type NodeStepDownFormValues = {
    stepDownTime?: number;
    catchupTime?: number;
    force?: boolean;
};

function NodeStepDownForm({
    node,
    onSuccess,
    onCancel,
}: NodeStepDownFormProps) {
    const [form] = Form.useForm<NodeStepDownFormValues>();
    const { loading, send } = useCreateJob({
        clusterId: node.clusterid,
        title: 'Step down node',
        command: CmonJobInstanceCommand.REPLSET_STEP_DOWN,
        onSuccess,
    });

    const handleSubmit = async (values: NodeStepDownFormValues) => {
        await send({
            hostname: node.hostname,
            port: node.port,
            stepdown_time: values.stepDownTime,
            catchup_time: values.force ? 0 : values.catchupTime,
            force: values.force,
        });
    };
    return (
        <ModalDefaultForm
            title="Step down node"
            form={form}
            footer={[]}
            onCancel={onCancel}
            width={450}
            defaultVisible={true}
        >
            <Form
                className="NodeStepDownForm"
                form={form}
                layout="vertical"
                onFinish={handleSubmit}
                initialValues={{ stepDownTime: 60, catchupTime: 10 }}
            >
                <SpaceWide direction="vertical">
                    <NodeFormat
                        node={node}
                        size="large"
                        contentProps={{ style: { width: '100%' } }}
                    />
                    <Form.Item
                        name="stepDownTime"
                        label={<span>Stepdown time (in seconds)</span>}
                        rules={[
                            {
                                required: true,
                                message: 'Please enter stepdown time.',
                            },
                            {
                                pattern: /^[0-9][0-9]*$/,
                                message:
                                    'Please enter a number greater or equal than 0',
                            },
                        ]}
                    >
                        <InputNumberWide placeholder="Enter enter stepdown time" />
                    </Form.Item>
                    <FormItemInlineSwitch
                        justify
                        noMargin
                        name="force"
                        label={
                            <Space>
                                <span>Force stepdown</span>
                                <InfoIcon
                                    info={
                                        <span>
                                            Triggers the primary of the replica
                                            set to become a secondary. This in
                                            turn triggers an election for
                                            primary. The method steps down the
                                            primary.
                                        </span>
                                    }
                                />
                            </Space>
                        }
                        valuePropName="checked"
                    />
                    <Form.Item shouldUpdate>
                        {() => {
                            return (
                                <Form.Item
                                    name="catchupTime"
                                    label={
                                        <Space>
                                            <span>
                                                Catchup time (in seconds)
                                            </span>
                                            <InfoIcon
                                                info={
                                                    <span>
                                                        The number of seconds
                                                        the mongod will wait for
                                                        an electable secondary
                                                        to catch up this
                                                        primary.
                                                    </span>
                                                }
                                            />
                                        </Space>
                                    }
                                    rules={[
                                        {
                                            pattern: /^[0-9][0-9]*$/,
                                            message:
                                                'Please enter a number greater or equal than 0',
                                        },
                                    ]}
                                >
                                    <InputNumberWide
                                        disabled={form.getFieldValue('force')}
                                        placeholder="Enter enter catchup time"
                                    />
                                </Form.Item>
                            );
                        }}
                    </Form.Item>
                </SpaceWide>
                <FormFooter>
                    <Button
                        key="done"
                        type="primary"
                        htmlType="submit"
                        loading={loading}
                    >
                        Step down
                    </Button>
                </FormFooter>
            </Form>
        </ModalDefaultForm>
    );
}
