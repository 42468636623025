import React, { useMemo } from 'react';
import { Alert, Col, Row, Space } from 'antd';
import CloudAwsAuthentication, {
    getCloudAwsAuthenticationValidate,
} from './FormParts/CloudAwsAuthentication';
import CloudJsonAuthentication, {
    getCloudJsonAuthenticationValidate,
} from './FormParts/CloudJsonAuthentication';
import CloudS3Authentication, {
    getCloudS3AuthenticationValidate,
} from './FormParts/CloudS3Authentication';
import { FormInstance } from 'antd/lib/form';
import Paragraph from 'antd/lib/typography/Paragraph';
import {
    CcCloudCredentialsFields,
    CcCloudProviderType
} from '../../../services/models/CcCloudCredentials';
import { getCloudCredentialsDocumentationLink } from './CloudCredentialsWizard';
import TypographyText from '../../../common/TypographyText';


const CREDENTIALS_FIELDS_CONFIG: any = {
    aws: {
        access_key_id: {
            label: 'AWS key ID',
        },
        access_key_secret: {
            label: 'AWS key secret',
        },
        access_key_region: {
            label: 'Default region',
            valueRender: (value: string) => (
                <TypographyText nowrap>{value || 'None'}</TypographyText>
            ),
        },
    },
    gce: {
        type: {
            label: 'Type',
            required: true,
        },
        project_id: {
            label: 'Project ID',
            required: true,
        },
        private_key_id: {
            label: 'Private Key ID',
            required: true,
        },
        private_key: {
            label: 'Private Key',
            valueRender: (value: string) => (
                <Paragraph
                    style={{ margin: 0 }}
                    copyable
                    ellipsis={{ suffix: '', rows: 2 }}
                >
                    {value || 'None'}
                </Paragraph>
            ),
            required: true,
        },
        client_email: {
            label: 'Client Email',
            required: true,
        },
        client_id: {
            label: 'Client ID',
            required: true,
        },
        auth_uri: {
            label: 'Auth URI',
            required: true,
        },
        token_uri: {
            label: 'Token URI',
            required: true,
        },
        auth_provider_x509_cert_url: {
            label: 'Auth provider x509 certificate URL',
            required: true,
        },
        client_x509_cert_url: {
            label: 'Client x509 certificate URL',
            required: true,
        },
    },
    az: {
        application_id: {
            label: 'Application ID',
            required: true,
        },
        client_secret: {
            label: 'Client Secret',
            required: true,
        },
        subscription_id: {
            label: 'Subscription ID',
            required: true,
        },
        tenant_id: {
            label: 'Tenant ID',
            required: true,
        },
        resource_group: {
            label: 'Resource Group',
            required: true,
        },
        storage_account: {
            label: 'Storage Account',
        },
    },

    s3: {
        endpoint: {
            label: 'Endpoint',
        },
        access_key_region: {
            label: 'Region',
        },
        access_key_id: {
            label: 'Access Key',
        },
        access_key_secret: {
            label: 'Secret Key',
        },
        use_ssl: {
            label: 'Use SSL',
        },
    },
};

export type CloudCredentialsAuthenticationFormProps = {
    form: FormInstance;
    type: CcCloudProviderType;
};

export default function CloudCredentialsAuthenticationForm({
    form,
    type,
}: CloudCredentialsAuthenticationFormProps) {
    const formPart = useMemo(() => {
        switch (type) {
            case CcCloudProviderType.AWS:
                return <CloudAwsAuthentication />;
            case CcCloudProviderType.GOOGLE:
            case CcCloudProviderType.AZURE:
                return <CloudJsonAuthentication form={form} type={type} />;
            case CcCloudProviderType.S3:
                return <CloudS3Authentication />;
            default:
                return null;
        }
    }, [type]);
    return (
        <Space
            size={20}
            direction="vertical"
            className="CloudCredentialsAuthenticationForm"
        >
            <div style={{ minHeight: 350 }}>
                <Row gutter={[24, 0]}>
                    <Col span={24}>
                        <h3>Authentication</h3>
                    </Col>
                </Row>
                {formPart}
            </div>
            {getCloudCredentialsServiceInfo(type)}
        </Space>
    );
}

function getCloudCredentialsServiceInfo(type: CcCloudProviderType) {
    switch (type) {
        case CcCloudProviderType.S3:
        case CcCloudProviderType.AWS:
            return (
                <Alert
                    showIcon
                    type="info"
                    message="Need help?"
                    description={
                        <span>
                            Check our{' '}
                            <a
                                rel="noreferrer"
                                target="_blank"
                                href={getCloudCredentialsDocumentationLink(
                                    type
                                )}
                            >
                                online documentation
                            </a>{' '}
                            for detailed instructions on how to create your
                            cloud credentials for Amazon Web Services.
                        </span>
                    }
                />
            );

        case CcCloudProviderType.GOOGLE:
            return (
                <Alert
                    showIcon
                    type="info"
                    message="Need help?"
                    description={
                        <span>
                            Check our{' '}
                            <a
                                rel="noreferrer"
                                target="_blank"
                                href={getCloudCredentialsDocumentationLink(
                                    type
                                )}
                            >
                                online documentation
                            </a>{' '}
                            for detailed instructions on how to create your
                            cloud credentials for Google Cloud.
                        </span>
                    }
                />
            );
        case CcCloudProviderType.AZURE:
            return (
                <Alert
                    showIcon
                    type="info"
                    message="Need help?"
                    description={
                        <span>
                            Check our{' '}
                            <a
                                rel="noreferrer"
                                target="_blank"
                                href={getCloudCredentialsDocumentationLink(
                                    type
                                )}
                            >
                                online documentation
                            </a>{' '}
                            for detailed instructions on how to create your
                            cloud credentials for Microsoft Azure.
                        </span>
                    }
                />
            );
        default:
            return null;
    }
}

export function getCloudCredentialsField(
    type: CcCloudProviderType,
    field: string
) {
    return CREDENTIALS_FIELDS_CONFIG[type]?.[field];
}

export function getCloudCredentialsFieldLabel(
    type: CcCloudProviderType,
    field: string
) {
    return getCloudCredentialsField(type, field)?.label || '';
}

export function getCloudCredentialsServiceFields(type: CcCloudProviderType) {
    return Object.keys(CREDENTIALS_FIELDS_CONFIG[type] || {});
}


export function filterProviderCredentialsFields(
    provider: CcCloudProviderType,
    fields: CcCloudCredentialsFields | any
) {
    const fieldsConfig = CREDENTIALS_FIELDS_CONFIG[provider];
    const result: CcCloudCredentialsFields | any = {};
    for (const key in fields) {
        if (fieldsConfig?.hasOwnProperty(key)) {
            result[key] = fields[key as any];
        }
    }
    return result;
}


export function getCloudCredentialsAuthenticateValidate(
    type: CcCloudProviderType,
    form: FormInstance
) {
    let fields = [];
    switch (type) {
        case CcCloudProviderType.AWS:
            fields = getCloudAwsAuthenticationValidate();
            break;
        case CcCloudProviderType.GOOGLE:
        case CcCloudProviderType.AZURE:
            fields = getCloudJsonAuthenticationValidate();
            break;
        case CcCloudProviderType.S3:
            fields = getCloudS3AuthenticationValidate();
            break;
    }

    return [
        ...fields,
        'name',
        ...getCloudCredentialsServiceFields(type)
            .map((field) => {
                const { label, required } = getCloudCredentialsField(
                    type,
                    field
                );
                if (required === true) {
                    return () => {
                        if (!form.getFieldValue([type, field])) {
                            throw new Error(
                                `Credentials field '${label}' is not set!`
                            );
                        }
                    };
                }
                return null;
            })
            .filter((item) => !!item),
    ];
}
