import React from 'react';
import { FormInstance } from 'antd/lib/form';
import SpaceDescriptions from '../../../../../common/Layout/SpaceDescriptions';
import AppDivider from '../../../../../common/AppDivider';

export type ImportProxySQLPreviewProps = {
    form: FormInstance;
    isImportConfig: boolean;
};

export default ImportProxySQLPreview;

function ImportProxySQLPreview({
    form,
    isImportConfig,
}: ImportProxySQLPreviewProps) {
    const { proxySQLConfiguration, proxySQLCredentials } = form.getFieldsValue(
        true
    );
    const [address] = proxySQLConfiguration.address;
    return (
        <div>
            <SpaceDescriptions
                direction="vertical"
                title="Configuration"
                size="small"
                alignItems="right"
            >
                <SpaceDescriptions.Item label="Server Address" labelStrong>
                    {address?.extraData?.hostname ?? ''}
                </SpaceDescriptions.Item>
                {proxySQLConfiguration?.proxySQLInstance !== undefined &&
                    proxySQLConfiguration?.importConfig && (
                        <SpaceDescriptions.Item
                            label="ProxySQL Instance"
                            labelStrong
                        >
                            {proxySQLConfiguration?.proxySQLInstance[0]
                                ?.extraData.hostname ?? ''}
                        </SpaceDescriptions.Item>
                    )}

                <SpaceDescriptions.Item label="Admin Port" labelStrong>
                    {proxySQLConfiguration.adminPort ?? ''}
                </SpaceDescriptions.Item>
                <SpaceDescriptions.Item label="Listening Port" labelStrong>
                    {proxySQLConfiguration.listenPort ?? ''}
                </SpaceDescriptions.Item>
            </SpaceDescriptions>
            <AppDivider />

            {!isImportConfig && (
                <SpaceDescriptions
                    direction="vertical"
                    title="Credentials"
                    size="small"
                    alignItems="right"
                >
                    <SpaceDescriptions.Item
                        label="Administration user"
                        labelStrong
                    >
                        {proxySQLCredentials.administrationUser ?? ''}
                    </SpaceDescriptions.Item>
                    {proxySQLCredentials.monitorUser && (
                        <SpaceDescriptions.Item
                            label="Monitor user"
                            labelStrong
                        >
                            {proxySQLCredentials.monitorUser}
                        </SpaceDescriptions.Item>
                    )}
                </SpaceDescriptions>
            )}
        </div>
    );
}
