import React from 'react';
import { Form } from 'antd';
import WizardForm from '@severalnines/bar-frontend-components/build/lib/Navigation/WizardForm';
import WizardFormConfiguration from '@severalnines/bar-frontend-components/build/lib/Navigation/Wizard/WizardFormConfiguration';
import CcCluster from '../../../../services/models/CcCluster';
import { FormInstance } from 'antd/lib/form';
import useCreateJob from '../../../Jobs/useCreateJob';
import { CmonJobInstanceCommand } from '../../../../services/cmon/models/CmonJobInstance';

import CreateKeepalivedConfiguration, {
    getCreateKeepalivedConfigurationValidation,
    keepalivedConfigurationInitial,
} from './CreateKeepalivedConfiguration';
import CreateKeepalivedHost, {
    keepalivedHostInitial,
    keepalivedHostValidation,
} from './CreateKeepalivedHost';
import CreateKeepalivedPreview from './CreateKeepalivedPreview';

export enum KeepalivedWizardFormSteps {
    Configuration = 'Configuration',
    HostConfiguration = 'HostConfiguration',
    PREVIEW = 'preview',
}

export type CreateKeepalivedWizardFormProps = {
    onCancel?: () => void;
    onSuccess?: () => void;
    onError?: (err: Error) => void;
    onStepErrorInsist?: (err: Error) => void;
    onTouchedChange?: (touched: boolean) => void;
    cluster?: CcCluster;
};

export default CreateKeepalivedWizardForm;

function CreateKeepalivedWizardForm({
    onCancel,
    onSuccess,
    onStepErrorInsist,
    onTouchedChange,
    onError,
    cluster,
}: CreateKeepalivedWizardFormProps) {
    const [form] = Form.useForm();

    const { loading, send } = useCreateJob({
        clusterId: cluster?.clusterId,
        title: 'Setup Keepalived',
        command:
            CmonJobInstanceCommand.KEEPALIVED.toLowerCase() as CmonJobInstanceCommand,
        onSuccess,
    });

    const handleSubmit = async () => {
        try {
            await send({
                clusterId: cluster?.clusterId,
                ...formatFields(form),
            });
        } catch (err: any) {
            if (onError) {
                onError(err);
            }
        }
    };

    return (
        <WizardFormConfiguration
            form={form}
            onSubmit={handleSubmit}
            loading={loading}
            onCancel={onCancel}
            initialValues={{
                ...keepalivedConfigurationInitial(cluster),
                ...keepalivedHostInitial,
            }}
            onStepErrorInsist={onStepErrorInsist}
            onTouchedChange={onTouchedChange}
            steps={[
                <WizardForm.Step
                    key={KeepalivedWizardFormSteps.Configuration}
                    title="Configuration"
                    subTitle=" "
                    validate={getCreateKeepalivedConfigurationValidation(form)}
                >
                    <CreateKeepalivedConfiguration
                        selectedCluster={cluster}
                        form={form}
                    />
                </WizardForm.Step>,
                <WizardForm.Step
                    key={KeepalivedWizardFormSteps.HostConfiguration}
                    title={
                        <span style={{ whiteSpace: 'nowrap' }}>
                            Host configuration
                        </span>
                    }
                    subTitle=" "
                    validate={keepalivedHostValidation}
                >
                    <CreateKeepalivedHost />
                </WizardForm.Step>,
                <WizardForm.Step
                    key={KeepalivedWizardFormSteps.PREVIEW}
                    title="Preview"
                    subTitle=" "
                >
                    <CreateKeepalivedPreview form={form} />
                </WizardForm.Step>,
            ]}
        />
    );
}

export function formatFields(form: FormInstance) {
    const { keepalivedConfiguration, keepalivedHost } =
        form.getFieldsValue(true);

    return {
        action: 'setupKeepalived',
        nodes: keepalivedConfiguration.hostname.map((data: any) => {
            const hostname = data?.extraData.hostname;
            const port = data?.extraData.selectedOption?.port;
            return {
                hostname,
                port,
            };
        }),
        virtual_ip: keepalivedHost.virtualIP,
        eth_interface: keepalivedHost.networkInterface,
        procname: keepalivedConfiguration.type,
        disable_firewall: keepalivedConfiguration.firewall,
        disable_selinux: keepalivedConfiguration.appArmor,
    };
}
