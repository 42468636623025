import React, { useContext, useMemo } from 'react';
import CcCluster from '../../services/models/CcCluster';
import TopQueriesPage from './TopQueriesPage';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { ClusterConfigContext } from '../Clusters/Config/ClusterConfigContext';
import QueryProcessesPage from './QueryProcessesPage';
import { StringParam, useQueryParam } from 'use-query-params';
import QueryOutliersPage from './QueryOutliersPage';
import QueryMonitorSettingsForm from './QueryMonitorSettingsForm';
import AppSubsectionWrapper from '../../common/Layout/AppSubsectionWrapper';

export default QueryMonitorPage;

export enum QueryMonitorPageSection {
    TOP_QUERIES = 'top-queries',
    DB_CONNECTIONS = 'db-connections',
    QUERY_OUTLIERS = 'query-outliers',
    QUERY_MONITOR_SETTINGS = 'settings',
}

export type QueryMonitorPageProps = {
    cluster: CcCluster;
};

function QueryMonitorPage({ cluster, ...rest }: QueryMonitorPageProps) {
    const navigate = useNavigate();
    const { refresh: refreshConfig } = useContext(ClusterConfigContext);
    const { subSection = QueryMonitorPageSection.DB_CONNECTIONS } = useParams<{
        subSection: QueryMonitorPageSection;
    }>();
    const [hostQuery] = useQueryParam<any>('host', StringParam);

    const handleQueryMonitorSettingsSuccess = () => {
        refreshConfig({});
    };
    const submenuItems = useMemo(
        () => [
            {
                label: 'DB connections',
                key: QueryMonitorPageSection.DB_CONNECTIONS,
                children: <QueryProcessesPage cluster={cluster} />,
            },
            {
                label: 'Top queries',
                key: QueryMonitorPageSection.TOP_QUERIES,
                children: <TopQueriesPage cluster={cluster} />,
            },
            {
                label: 'Query outliers',
                key: QueryMonitorPageSection.QUERY_OUTLIERS,
                children: <QueryOutliersPage cluster={cluster} />,
            },
            {
                label: 'Settings',
                key: QueryMonitorPageSection.QUERY_MONITOR_SETTINGS,
                children: (
                    <QueryMonitorSettingsForm
                        cluster={cluster}
                        columns={2}
                        onSuccess={handleQueryMonitorSettingsSuccess}
                    />
                ),
            },
        ],
        []
    );
    return (
        <AppSubsectionWrapper
            items={submenuItems}
            activeSection={subSection}
            onNavigate={(key) => {
                const querySearch = hostQuery ? `?host=${hostQuery}` : '';
                navigate(
                    `/clusters/${cluster.clusterId}/performance/query-monitor/${key}${querySearch}`
                );
            }}
        />
    );
}
