import React, { ReactNode, useEffect, useRef, useState } from 'react';
import './ClusterTopologySmall.less';
import Diagram, {
    IDiagramItem,
} from '@severalnines/bar-frontend-components/build/lib/DataDisplay/Diagram';
import CcCluster from '../../services/models/CcCluster';
import {
    getNodeStatusFormatText,
    getNodeStatusFormatType,
} from '../Nodes/NodeStatusFormat';
import { getNodeTypeInitial, getNodeTypeText } from '../Nodes/NodeTypeFormat';
import TypographyText from '../../common/TypographyText';
import ClusterTopologyConfigurator from './ClusterTopology/ClusterTopologyConfigurator';
import { Space } from 'antd';
import AppDivider from '../../common/AppDivider';
import StatusFormat from '@severalnines/bar-frontend-components/build/lib/Format/StatusFormat';
import useClusterList from './useClusterList';
import classNames from 'classnames';
import ClusterTopologySmallNodeItem from './ClusterTopology/ClusterTopologySmallNodeItem';
import { getClusterTopologyConfigurator } from './ClusterTopology/ClusterTopologyHelper';

export default ClusterTopology;

export type ClusterTopologyProps = {
    cluster: CcCluster;
};

function ClusterTopology({ cluster }: ClusterTopologyProps) {
    const [items, setItems] = useState<any>([]);
    const [groups, setGroups] = useState<any[]>([]);
    const [typesLegend, setTypesLegend] = useState<any>([]);
    const [statusLegend, setStatusLegend] = useState<any>([]);
    const { list: clusters } = useClusterList({
        name: 'cluster-list-from-cluster-topology',
    });
    const diagramRef = useRef<any>();
    const configurator = useRef<ClusterTopologyConfigurator>(
        new (getClusterTopologyConfigurator(cluster.clusterType))(
            clusters as CcCluster[]
        ) as ClusterTopologyConfigurator
    );

    useEffect(() => {
        const itemList = configurator.current.getDiagramItems(cluster);

        const legend: { [key: string]: string[] } = {};
        const sLegend: { [key: string]: ReactNode } = {};
        const groups: any[] = [];
        itemList.forEach((i) => {
            if (i.type !== 'group') {
                const key = getNodeTypeInitial(
                    i.data.node.nodetype,
                    i.data.node.getRole()
                );
                if (!legend[key]) {
                    legend[key] = [
                        key,
                        getNodeTypeText(
                            i.data.node.nodetype,
                            i.data.node.getRole()
                        ),
                    ];
                }
                const statusKey = getNodeStatusFormatText(
                    i.data.node.hoststatus
                );
                if (!sLegend[statusKey]) {
                    sLegend[statusKey] = (
                        <StatusFormat
                            key={statusKey}
                            status={getNodeStatusFormatType(
                                i.data.node.hoststatus
                            )}
                        >
                            <small>{statusKey}</small>
                        </StatusFormat>
                    );
                }
            } else {
                groups.push(i);
            }
        });

        setTypesLegend(Object.values(legend));
        setStatusLegend(Object.values(sLegend));
        setItems(itemList);
        setGroups(groups);
    }, [cluster]);

    const diagram = (
        <div ref={diagramRef}>
            <Diagram
                size={'small'}
                renderItem={(item: IDiagramItem) => (
                    <ClusterTopologySmallNodeItem item={item} />
                )}
                renderGroup={(item: any, children: ReactNode) => {
                    return groups.length > 1 ? (
                        <Space
                            className={classNames('ClusterTypologySmall_group')}
                            direction="vertical"
                            size={0}
                        >
                            <TypographyText
                                className="ClusterTypologySmall_group-title"
                                muted
                            >
                                {item.title}
                            </TypographyText>
                            {children}
                        </Space>
                    ) : (
                        <div>{children}</div>
                    );
                }}
                renderConnectionDot={() => null}
                items={items}
            />
            <Space split={<AppDivider type="vertical" />}>
                {typesLegend.map(([key, value]: string[]) => (
                    <TypographyText muted key={key}>
                        <small>
                            {key} - {value}
                        </small>
                    </TypographyText>
                ))}
                {statusLegend}
            </Space>
        </div>
    );

    return <div className={classNames('ClusterTopologySmall')}>{diagram}</div>;
}
