import CmonBackupService, {
    GetBackupsRequestData,
    GetSnapshotRepositoriesRequestData,
} from './cmon/CmonBackupService';
import { CmonRequestServiceResponseData } from './cmon/CmonRequestService';
import CcBackup, { CcBackupProps } from './models/CcBackup';
import CcSnapshotRepository, {
    CcSnapshotRepositoryProps,
} from './models/CcSnapshotRepository';
export type SnapshotRepositoriesListData = {
    [key: string]: CcSnapshotRepository;
};
export type SnapshotRepositoriesClustersData = {
    [key: number]: SnapshotRepositoriesListData;
};
type GetAllSnapshotRepositoriesData = CmonRequestServiceResponseData & {
    snapshot_repositories_list: SnapshotRepositoriesClustersData;
};

export default class CcBackupService extends CmonBackupService {
    public static async getBackups(
        data: GetBackupsRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        const response = await CmonBackupService.getBackups(data, opts);
        return {
            ...response,
            backup_records: response.backup_records.map(
                (b: CcBackupProps) => new CcBackup(b)
            ),
        };
    }

    public static async getSnapshotRepositories(
        data: GetSnapshotRepositoriesRequestData,
        opts?: any
    ) {
        const { snapshot_repositories, ...rest } =
            await CmonBackupService.getSnapshotRepositories(data, opts);
        return {
            snapshot_repositories: createRepositoryListMap(
                snapshot_repositories,
                data.clusterId
            ),
            ...rest,
        };
    }

    public static async getAllSnapshotRepositories(
        data: GetSnapshotRepositoriesRequestData,
        opts?: any
    ): Promise<GetAllSnapshotRepositoriesData> {
        const { snapshot_repositories_list, ...rest } =
            await CmonBackupService.getAllSnapshotRepositories(data, opts);

        const repositoryMap = Object.entries(snapshot_repositories_list).reduce(
            (a: any, [clusterId, list]: any) => {
                a[clusterId] = createRepositoryListMap(list, clusterId);
                return a;
            },
            {}
        );

        return {
            snapshot_repositories_list: repositoryMap,
            ...rest,
        };
    }
}

function createRepositoryListMap(list: any, clusterId: number) {
    return Object.entries(list).reduce((a: any, [key, repository]: any) => {
        a[key] = new CcSnapshotRepository({
            name: key,
            cluster_id: clusterId,
            ...(repository as CcSnapshotRepositoryProps),
        });
        return a;
    }, {});
}
