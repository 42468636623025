import './MaxScaleDetailsModal.less';
import React, { useState } from 'react';
import {
    Button,
    Col,
    Divider,
    Form,
    Input,
    Row,
    Space,
    AutoComplete,
} from 'antd';
import ModalDefault from '../../../../common/ModalDefault';

import NodeFormat from '../../NodeFormat';

import CcImperativeService from '../../../../services/CcImperativeService';
import CcCluster from '../../../../services/models/CcCluster';
import CcNode from '../../../../services/models/CcNode';
import { OPTIONS } from './maxScaleDetailsModalHelper';
import AppSpin from '../../../../common/General/AppSpin';
import AppPageWrapper from '../../../../common/Layout/AppPageWrapper';

export default MaxScaleDetailsModal;

export type MaxScaleDetailsModalProps = {
    cluster: CcCluster;
    node: CcNode;
    onCancel?: () => void;
    onError?: (err: Error) => void;
};

type FormValues = {
    task: string;
    user: string;
    password: string;
};
type Messages = {
    fileName: string;
    lineNumber: number;
    message: string;
};

function MaxScaleDetailsModal({
    cluster,
    node,
    onCancel,
    onError,
}: MaxScaleDetailsModalProps) {
    const [form] = Form.useForm();
    const [messages, setMessages] = useState<Messages[]>();
    const [loading, setLoading] = useState<boolean>(false);

    const handleSubmit = async (values: FormValues) => {
        const arg = `"${values.task ? values.task : ''}" ${node.hostname} ${
            node.port
        } ${values.user ? values.user : ''} ${
            values.password ? values.password : ''
        }`;
        try {
            setLoading(true);
            const data = await CcImperativeService.executeScript({
                filename: '/s9s/maxscale/ms_admin.js',
                arguments: arg,
                cluster_id: cluster.clusterId,
            });
            setLoading(false);
            setMessages((prev) =>
                prev
                    ? [...prev, ...data.results.messages]
                    : [...data.results.messages]
            );
        } catch (error) {
            setLoading(false);
            onError?.(error);
        }
    };

    return (
        <ModalDefault
            title="Node details"
            visible={true}
            width={'90%'}
            onCancel={onCancel}
        >
            <AppPageWrapper
                contentClassName="MaxScaleDetailsModal"
                transparent={true}
                contentProps={{ style: { border: 0 } }}
            >
                <Form form={form} layout="vertical" onFinish={handleSubmit}>
                    <Row justify="space-between" align="middle">
                        <NodeFormat
                            key={node?.hostname}
                            node={node}
                            size="large"
                        />

                        <Row gutter={[24, 0]}>
                            <Col>
                                <Form.Item name="task" label="Task">
                                    <AutoComplete
                                        className="MaxScaleDetailsModalAutoComplete"
                                        allowClear
                                        options={OPTIONS}
                                        placeholder="Select Task"
                                        filterOption={(inputValue, option) =>
                                            option!.value
                                                .toUpperCase()
                                                .indexOf(
                                                    inputValue.toUpperCase()
                                                ) !== -1
                                        }
                                    />
                                </Form.Item>
                            </Col>
                            <Col>
                                <Form.Item name="user" label="User">
                                    <Input placeholder="Enter User" />
                                </Form.Item>
                            </Col>
                            <Col>
                                <Form.Item name="password" label="Password">
                                    <Input.Password placeholder="Enter Password" />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Row>
                    <Row justify="end">
                        <Space align="end">
                            <Button onClick={() => setMessages(undefined)}>
                                Reset console
                            </Button>
                            <Button htmlType="submit" type="primary">
                                Execute
                            </Button>
                        </Space>
                    </Row>
                </Form>
                <div className="MaxScaleDetailsModalPage">
                    <Divider />
                    <AppSpin spinning={loading}>
                        {messages?.map((data, index) => (
                            <pre key={index}>{data.message}</pre>
                        ))}
                    </AppSpin>
                </div>
            </AppPageWrapper>
        </ModalDefault>
    );
}
