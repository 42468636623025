import { Form, Input, Radio, Space } from 'antd';
import InfoIcon from '@severalnines/bar-frontend-components/build/lib/General/InfoIcon';
import HostCheckInput from '../../../common/DataEntry/HostCheckInput';
import React from 'react';
import CcCluster from '../../../services/models/CcCluster';
import { FormInstance } from 'antd/lib/form';

export default BackupToolPBMSetupForm;
type BackupToolPBMSetupFormProps = {};

function BackupToolPBMSetupForm({}: BackupToolPBMSetupFormProps) {
    return (
        <Form.Item
            name="pbm_directory"
            label={<Space>Backup directory</Space>}
            rules={[
                {
                    required: true,
                    message: 'Please enter backup directory host.',
                },
            ]}
        >
            <Input placeholder="Enter backup directory" />
        </Form.Item>
    );
}
