import React, { useEffect, useState } from 'react';
import { Select } from 'antd';
import { FetchRefreshFunctionParams } from '../../common/useFetch';
import { TableDataProps } from './EmailNotifications';
import CmonConfigService from '../../services/cmon/CmonConfigService';
import CcCluster from '../../services/models/CcCluster';
import {
    notifyError,
    notifyOperationSuccess,
    NotifyType,
} from '../Notifications/uiNotification';
import { tableOptions } from './emailNotificationsHelper';

export default EmailNotificationsAction;
export type EmailNotificationsActionProps = {
    cluster: CcCluster;
    record: TableDataProps;
    selectedValue: string;
    type: string;
    notifications: any;
    onSuccess: (params: FetchRefreshFunctionParams) => Promise<any>;
};
function EmailNotificationsAction({
    cluster,
    record,
    selectedValue,
    type,
    notifications,
    onSuccess,
}: EmailNotificationsActionProps) {
    const [value, setValue] = useState(selectedValue);
    useEffect(() => {
        setValue(selectedValue);
    }, [selectedValue]);
    const onChangeHandler = async (value: string) => {
        try {
            let data = {
                cluster_id: cluster.clusterId,
                daily_limit: notifications.daily_limit,
                digest_hour: notifications.digest_hour,
                email: notifications.recipient,
                settings: {
                    ...notifications.settings,
                    [`${record.component}`]: {
                        ...notifications.settings[`${record.component}`],
                        [`${type}`]: value,
                    },
                },
            };
            await CmonConfigService.setnotificationsettings(data);
            await onSuccess({});
            notifyOperationSuccess({
                type: NotifyType.TOAST,
                title: `Changes saved successfully!`,
                cancelText: 'Close',
            });
            setValue(value);
        } catch (err) {
            notifyError({ type: NotifyType.TOAST, content: err.message });
        }
    };
    return (
        <Select
            onChange={onChangeHandler}
            placeholder="Click here to select"
            value={value}
            options={tableOptions}
        />
    );
}
