import MysqlReplicationConfigurator, {
    MysqlReplicationFormValues,
} from '../../MysqlReplication/MysqlReplicationConfigurator';
import { ClusterConfiguratorFormValues } from '../../ClusterConfigurator';
import merge from 'deepmerge';
import { RepositoryItemKey } from '../../../../../common/DataEntry/RepositoryInput';
import React from 'react';
import MysqlNodeConfiguration from '../../Mysql/MysqlNodeConfiguration';
import { CcClusterType } from '../../../../../services/models/CcCluster';
import NodeConfigurationSummary, {
    NodeConfigurationSummaryProps,
} from '../../NodeConfigurationSummary';
import { TopologyItem } from '../../../../Topology/TopologyItem';
import FormItemInlineSwitch from '../../../../../common/DataEntry/FormItemInlineSwitch';
import { Space } from 'antd';
import InfoIcon from '@severalnines/bar-frontend-components/build/lib/General/InfoIcon';
import TopologySummary from '../../TopologySummary';
import { FormInstance } from 'antd/lib/form';
import ClusterNodesForm from '../../ClusterNodesForm';
import { StatusFormatStatus } from '@severalnines/bar-frontend-components/build/lib/Format/StatusFormat';

export default class MysqlReplicationDeploymentConfigurator extends MysqlReplicationConfigurator {
    public static getDefaults(): MysqlReplicationFormValues {
        return merge(MysqlReplicationConfigurator.getDefaults(), {
            details: {
                vendor: 'mariadb',
                version: '10.11',
            },
            nodeConfig: {
                repository: RepositoryItemKey.USE_VENDOR,
                semiSynchronous: true,
                sslEncryption: true,
            },
        });
    }

    public static getNodeConfigurationStep(props: any): React.ReactNode {
        return (
            <MysqlNodeConfiguration
                {...props}
                clusterType={CcClusterType.TYPE_REPLICATION}
                hasSslEncryption={true}
                hasSemiSynchronous={true}
            />
        );
    }

    public static getNodeConfigurationSummary(
        props: NodeConfigurationSummaryProps
    ): React.ReactNode {
        return (
            <NodeConfigurationSummary
                hasSslEncryption={true}
                hasSemiSynchronous={true}
                {...props}
            />
        );
    }

    public static getJobData(formValues: MysqlReplicationFormValues): any {
        const {
            nodeConfig,
            topology,
            topologyMultiPrimary,
            topologyDataIps,
            useMultiPrimary,
        } = formValues;
        return merge(MysqlReplicationConfigurator.getJobData(formValues), {
            mysql_semi_sync: nodeConfig.semiSynchronous,
            // merging more nodes from multi primary topology
            nodes: ((useMultiPrimary && topologyMultiPrimary) || []).map(
                (currentNode: TopologyItem) => ({
                    port: nodeConfig.serverPort,
                    hostname: currentNode.extraData.hostname,
                    hostname_data: currentNode.extraData.hostname,
                    hostname_internal:
                        topologyDataIps?.[currentNode.extraData.hostname] || '',
                })
            ),
            topology: {
                master_slave_links: getJobDataPrimaryReplicaLinks(
                    topology,
                    useMultiPrimary ? topologyMultiPrimary : undefined
                ),
            },
        });
    }

    public static getTopologyStep(form: FormInstance): React.ReactNode {
        return (
            <div>
                <ClusterNodesForm form={form} />
                <FormItemInlineSwitch
                    name={['useMultiPrimary']}
                    label={
                        <Space>
                            <span>Use multi-primary replication</span>
                            <InfoIcon info="Add a second primary (source) node to setup multi-primary (source) replication. This node is by default put into read-only." />
                        </Space>
                    }
                    extraOnSwitch={
                        <div>
                            <ClusterNodesForm
                                form={form}
                                formItemProps={{
                                    name: ['topologyMultiPrimary'],
                                }}
                            />
                        </div>
                    }
                />
            </div>
        );
    }

    public static getTopologyValidate(form: FormInstance) {
        return [
            ...MysqlReplicationConfigurator.getTopologyValidate(form),
            () => {
                const {
                    useMultiPrimary,
                    topologyMultiPrimary,
                } = form.getFieldsValue([
                    'useMultiPrimary',
                    'topologyMultiPrimary',
                ]);
                if (
                    useMultiPrimary &&
                    topologyMultiPrimary.find(
                        (item: TopologyItem) =>
                            item.status !== StatusFormatStatus.success
                    )
                ) {
                    throw new Error('All nodes should be reachable');
                }
            },
        ];
    }

    public static getTopologySummary(form: FormInstance): React.ReactNode {
        const { useMultiPrimary, topologyMultiPrimary } = form.getFieldsValue([
            'useMultiPrimary',
            'topologyMultiPrimary',
        ]);
        return (
            <div>
                <TopologySummary
                    form={form}
                    topologyFieldPath={['topology']}
                    primaryTitle={
                        useMultiPrimary && topologyMultiPrimary?.length
                            ? 'Primary A'
                            : 'Primary'
                    }
                />
                {useMultiPrimary && topologyMultiPrimary?.length ? (
                    <TopologySummary
                        form={form}
                        title={null}
                        topologyFieldPath={['topologyMultiPrimary']}
                        primaryTitle="Primary B"
                    />
                ) : null}
            </div>
        );
    }

    public static getJobOptions(
        formValues: ClusterConfiguratorFormValues
    ): any {
        return merge(MysqlReplicationConfigurator.getJobOptions(formValues), {
            enable_uninstall: true,
            job: {
                title: 'Deploy MySQL Replication Cluster',
            },
        });
    }
}

export function getJobDataPrimaryReplicaLinks(
    topology: TopologyItem[],
    topologyMultiPrimary?: TopologyItem[]
): any {
    let masterSlaveLinks: { [key: string]: any }[] = [];
    const masters = [topology[0]];
    if (topologyMultiPrimary?.[0]) {
        masters.push(topologyMultiPrimary[0]);
    }

    // multi primary links
    if (masters.length > 1) {
        for (let i = 1; i < masters.length; i++) {
            let newLink: { [key: string]: any } = {};
            newLink[masters[i].extraData.hostname] =
                masters[i - 1].extraData.hostname;
            masterSlaveLinks.push(newLink);
        }
        // close the replication circle
        masterSlaveLinks.push({
            [masters[0].extraData.hostname]:
                masters[masters.length - 1].extraData.hostname,
        });
    }

    // replica links
    topology.forEach((currentNode) => {
        const master = topology.find(
            (node) => node.key === currentNode.fromKey
        );
        if (master) {
            let newLink: { [key: string]: any } = {};
            newLink[master.extraData.hostname] = currentNode.extraData.hostname;
            masterSlaveLinks.push(newLink);
        }
    });

    // 2nd replica links
    if (topologyMultiPrimary?.[0]) {
        topologyMultiPrimary.forEach((currentNode) => {
            const master = topologyMultiPrimary.find(
                (node) => node.key === currentNode.fromKey
            );
            if (master) {
                let newLink: { [key: string]: any } = {};
                newLink[master.extraData.hostname] =
                    currentNode.extraData.hostname;
                masterSlaveLinks.push(newLink);
            }
        });
    }
    return masterSlaveLinks;
}
