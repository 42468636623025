import React from 'react';
import { NamePath } from 'rc-field-form/lib/interface';
import { getObjectValueByPath, ObjectPropertyPath } from '../objectHelpers';
import { Form } from 'antd';

export default FormValueListener;

export type FormValueListenerProps = {
    name: NamePath;
    onChange: (value: any, prevValue: any) => void;
    getValue?: (values: any) => any;
};

function FormValueListener({
    name,
    onChange,
    getValue,
}: FormValueListenerProps) {
    return (
        <Form.Item
            className="FormValueListener"
            noStyle={true}
            shouldUpdate={(prev, curr) => {
                const prevByPath = getObjectValueByPath(
                    name as ObjectPropertyPath,
                    prev
                );
                const prevValue = getValue ? getValue(prevByPath) : prevByPath;
                const currByPath = getObjectValueByPath(
                    name as ObjectPropertyPath,
                    curr
                );
                const currValue = getValue ? getValue(currByPath) : currByPath;
                if (prevValue !== currValue) {
                    onChange(currByPath, prevByPath);
                    return true;
                } else {
                    return false;
                }
            }}
        >
            {() => {
                return null;
            }}
        </Form.Item>
    );
}
