import React from 'react';
import AlarmsListView from '../Alarms/AlarmsListView';
import DashboardCard from './DashboardCard';
import { Space } from 'antd';
import { AlarmsIcon } from '../../common/icons/icons';
import ActivityCenterAlarmsLink from '../ActivityCenter/ActivityCenterAlarmsLink';

export default DasboardAlarmsCard;

export type DasboardAlarmsCardProps = {};

function DasboardAlarmsCard({}: DasboardAlarmsCardProps) {
    return (
        <DashboardCard
            className="DasboardAlarmsCard"
            title={
                <Space>
                    <AlarmsIcon />
                    <span>Recent alarms</span>
                </Space>
            }
            extra={
                <ActivityCenterAlarmsLink>View alarms</ActivityCenterAlarmsLink>
            }
        >
            <AlarmsListView />
        </DashboardCard>
    );
}
