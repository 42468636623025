import React from 'react';
import ClusterList from '../components/Clusters/ClusterList';
import AppPageWrapper from '../common/Layout/AppPageWrapper';

export default DebugClusterList;
export type DebugClusterListProps = {};

function DebugClusterList({ ...rest }: DebugClusterListProps) {
    return (
        <AppPageWrapper>
            <ClusterList />
        </AppPageWrapper>
    );
}
