import React from 'react';
import { Form } from 'antd';
import { FormItemProps } from 'antd/lib/form';
import SshCheckableNodesInput, {
    SshCheckableNodesInputProps,
} from '../../DataEntry/SshCheckeableNodesInput';

export default StorageHostField;
export type StorageHostFieldProps = FormItemProps & {
    inputProps?: SshCheckableNodesInputProps;
    clusterId?: number;
};

function StorageHostField({
    inputProps,
    name,
    ...rest
}: StorageHostFieldProps) {
    return (
        <Form.Item name={name || 'storageHost'} {...rest}>
            <SshCheckableNodesInput
                singleNode={true}
                formProps={{
                    primaryTitle: 'Storage host',
                    primaryRequired: true,
                }}
                mutateItem={(item) => ({
                    ...item,
                    description: 'Storage host',
                })}
                {...inputProps}
            />
        </Form.Item>
    );
}
