import { Tag, Select, SelectProps } from 'antd';
import NotificationEventTag from '../DataDisplay/NotificationEventTag';
import { FormInstance } from 'antd/lib/form';
import { IntegrationType } from '../../components/Integrations/NotificationServices/NotificationServicesProviderFormat';

export default EventSelect;

export const EVENT_TRIGGERS: { [key: string]: string } = {
    ALL: 'All Events',
    ALARM_WARNING: 'Warning',
    ALARM_CRITICAL: 'Critical',
};

export const EVENT_TYPES: { [key: string]: string } = {
    Network: 'Network',
    CmonDatabase: 'CMON Database',
    Mail: 'Mail',
    Cluster: 'Cluster',
    DbHealth: 'Database Health',
    DbPerformance: 'Database Performance',
    Host: 'Host',
    ClusterConfiguration: 'Cluster Configuration',
    ClusterRecovery: 'Cluster Recovery',
    Node: 'Node',
    SoftwareInstallation: 'Software Installation',
    Backup: 'Backup',
};

export const ALL_EVENT_TYPES: { [key: string]: string } = {
    ALL: 'All Events',
    ALL_WARNING: 'All Warnings Events',
    ALL_CRITICAL: 'All Critical Events',
};

export type NotificationServicesSettingsProps = {
    form: FormInstance;
    integrationType: IntegrationType;
};

export const ALL = 'ALL';
export const ALARM_WARNING = 'ALARM_WARNING';
export const ALARM_CRITICAL = 'ALARM_CRITICAL';
export const ALL_OPTION = 'ALL,ALL';
export const ALL_WARNING_OPTION = `ALL_WARNING,${ALARM_WARNING}`;
export const ALL_CRITICAL_OPTION = `ALL_CRITICAL,${ALARM_CRITICAL}`;

const options: any = [
    {
        label: ALL_EVENT_TYPES.ALL,
        value: ALL_OPTION,
    },
    {
        label: (
            <>
                {ALL_EVENT_TYPES.ALL_WARNING}{' '}
                <Tag color={'orange'}>{'Warning'}</Tag>
            </>
        ),
        value: ALL_WARNING_OPTION,
    },
    {
        label: (
            <>
                {ALL_EVENT_TYPES.ALL_CRITICAL}{' '}
                <Tag color={'red'}>{'Critical'}</Tag>
            </>
        ),
        value: ALL_CRITICAL_OPTION,
    },
];

function getNotificationOptions() {
    Object.keys(EVENT_TYPES).map((type) => {
        Object.keys(EVENT_TRIGGERS).map((trigger) => {
            options.push({
                trigger,
                type,
                label: (
                    <NotificationEventTag
                        trigger={trigger}
                        type={type}
                        separate={true}
                    />
                ),
                value: `${type},${trigger}`,
            });
        });
    });

    return options;
}

export type EventSelectProps = SelectProps<string> & {
    onEventSelect: (event: string) => void;
};

function EventSelect({ onEventSelect, ...rest }: EventSelectProps) {
    const handleSelect = (value: any) => {
        onEventSelect?.(value);
    };
    return (
        <div className="EventSelect">
            <Select
                maxTagCount="responsive"
                options={getNotificationOptions()}
                placeholder="Choose one or more events to track"
                mode="multiple"
                onSelect={handleSelect}
                {...rest}
            />
        </div>
    );
}
