import React, { useMemo, useState } from 'react';
import CcNode from '../../services/models/CcNode';
import ModalDefault from '../../common/ModalDefault';
import CcCluster from '../../services/models/CcCluster';
import { useSelector } from 'react-redux';
import { AppState } from '../../appReducer';
import { CcMaintenancePeriod } from '../../services/models/CcMaintenanceInfo';
import StatusFormat, {
    StatusFormatStatus,
} from '@severalnines/bar-frontend-components/build/lib/Format/StatusFormat';
import DurationFormat from '@severalnines/bar-frontend-components/build/lib/Format/DurationFormat';
import DisableMaintenanceButton from './DisableMaintenanceButton';
import { DeleteOutlined } from '@ant-design/icons';
import ActionsMenu from '../../common/Navigation/ActionsMenu';
import SpaceWide from '../../common/SpaceWide';
import { Button } from 'antd';
import InfoIcon from '@severalnines/bar-frontend-components/build/lib/General/InfoIcon';
import AppDateFormat from '../../common/AppDateFormat';
import AppTable from '../../common/DataDisplay/AppTable';

export default ManageMaintenanceModal;

export type ManageMaintenanceModalProps = {
    node?: CcNode;
    cluster?: CcCluster;
    onSuccess?: () => void;
    onCancel?: () => void;
};

function ManageMaintenanceModal({
    node,
    cluster,
    onSuccess,
    onCancel,
}: ManageMaintenanceModalProps) {
    const [touched, setTouched] = useState<boolean>(false);
    const [
        storedScheduledMaintenances,
    ] = useSelector(({ scheduledMaintenances }: AppState) => [
        scheduledMaintenances,
    ]);

    const dataSource = useMemo(() => {
        return (
            storedScheduledMaintenances
                .get(
                    `${
                        (cluster && cluster.clusterId) ||
                        (node && node.hostname)
                    }`
                )
                ?.getMaintenancePeriods() || []
        );
    }, [storedScheduledMaintenances]);

    const handleDisableMaintenanceSuccess = () => {
        // the user removed at least one maintenance
        setTouched(true);
    };

    const handleDone = () => {
        if (touched) {
            // triggering success only when something was changed (record deleted)
            onSuccess?.();
        } else {
            onCancel?.();
        }
    };

    const columns = [
        {
            title: 'Start time',
            key: 'initiate',
            render: (record: CcMaintenancePeriod) => (
                <AppDateFormat fromNow={!record.isMaintenanceActive()}>
                    {record.initiate}
                </AppDateFormat>
            ),
        },
        {
            title: 'End time',
            key: 'deadline',
            render: (record: CcMaintenancePeriod) => (
                <AppDateFormat fromNow={record.isMaintenanceActive()}>
                    {record.deadline}
                </AppDateFormat>
            ),
        },
        {
            title: 'Duration',
            key: 'duration',
            render: (record: CcMaintenancePeriod) => (
                <DurationFormat precision={2}>
                    {record.getDuration()}
                </DurationFormat>
            ),
        },
        {
            title: 'Status',
            key: 'status',
            render: (record: CcMaintenancePeriod) => (
                <StatusFormat
                    status={
                        record.isMaintenanceActive()
                            ? StatusFormatStatus.info
                            : StatusFormatStatus.success
                    }
                >
                    {record.isMaintenanceActive() ? 'Running' : 'Scheduled'}
                </StatusFormat>
            ),
        },
        {
            title: 'Info',
            key: 'info',
            render: (record: CcMaintenancePeriod) => (
                <InfoIcon
                    info={
                        <SpaceWide direction="vertical">
                            <span>Who: {record.username}</span>
                            <span>Reason: {record.reason || 'No reason'}</span>
                        </SpaceWide>
                    }
                />
            ),
        },
        {
            key: 'actions',
            title: 'Actions',
            align: 'center',
            render: (record: CcMaintenancePeriod) => (
                <ActionsMenu
                    menuItems={[
                        {
                            key: 'delete-maintenance-period',
                            waitForConfirm: true,
                            label: (
                                <DisableMaintenanceButton
                                    period={record}
                                    onSuccess={handleDisableMaintenanceSuccess}
                                >
                                    <DeleteOutlined />
                                </DisableMaintenanceButton>
                            ),
                        },
                    ]}
                ></ActionsMenu>
            ),
        },
    ];

    return (
        <ModalDefault
            title="Manage scheduled maintenance"
            onCancel={onCancel}
            width={900}
            visible={true}
            bodyStyle={{ padding: '25px' }}
            footer={
                //@todo, create ModalFooter component??
                <SpaceWide justify="flex-end">
                    <Button type="primary" onClick={handleDone}>
                        Done
                    </Button>
                </SpaceWide>
            }
        >
            <AppTable
                rowKey={(record: CcMaintenancePeriod) => record.uuid!}
                size="small"
                className="MaintenancePeriodsTable"
                dataSource={dataSource}
                columns={columns}
                pagination={{
                    pageSize: dataSource.length,
                    hideOnSinglePage: true,
                }}
            />
        </ModalDefault>
    );
}
