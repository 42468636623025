import AppEditor, {
    EditorTreeDataNode,
    updateTreeDataNode,
} from '../../../common/AppEditor/AppEditor';
import React, { useEffect, useState } from 'react';
import CcCluster from '../../../services/models/CcCluster';
import CmonImperativeService from '../../../services/cmon/CmonImperativeService';
import { DirectoryTreeProps } from 'antd/lib/tree';
import { notifyError } from '../../Notifications/uiNotification';
import { Button, Form } from 'antd';
import useClusterScript, {
    ClusterScriptRequestDTO,
    ScriptDataResponse,
} from './useClusterScript';
import ClusterScriptParamsFields from './FormParts/ClusterScriptParamsFields';
import useFetch from '../../../common/useFetch';
import { useForm } from 'antd/lib/form/Form';
import SpaceWide from '../../../common/SpaceWide';
import AppDivider from '../../../common/AppDivider';
import { useDebugContext } from '../../../common/Debug';

export default ClusterScriptsEditor;

type ScriptTreeNode = Omit<ScriptDataResponse, 'type'> & EditorTreeDataNode;

type ClusterScriptsEditorProps = {
    cluster: CcCluster;
};

function ClusterScriptsEditor({ cluster }: ClusterScriptsEditorProps) {
    const { log } = useDebugContext();
    const [form] = useForm();
    const [treeData, setTreeData] = useState<ScriptTreeNode[]>([]);

    const { loading, data, refresh } = useFetch<any>({
        name: 'cluster-scripts-editor',
        fetchFn: async (params, opts) => {
            const { data } = await CmonImperativeService.dirTree(
                {
                    ...params,
                    cluster_id: cluster.clusterId,
                },
                opts
            );
            return data;
        },
        cancelFn: async ({ requestId }) => {
            await CmonImperativeService.cancelRequest(requestId);
        },
    });

    useEffect(() => {
        (async () => {
            await refresh({});
        })();
    }, []);

    useEffect(() => {
        if (data?.contents) {
            setTreeData(getNodeTreeData(data.contents));
        }
    }, [data]);

    const {
        saveLoading,
        compileLoading,
        runLoading,
        refresh: scriptRefresh,
        save,
        compile,
        run,
    } = useClusterScript({
        cluster,
    });

    const handleSelect: DirectoryTreeProps['onSelect'] = async (
        keys,
        { node }: any
    ) => {
        setTreeData(
            updateTreeDataNode(treeData, {
                key: node.key,
                loading: true,
            })
        );
        try {
            const data: ScriptDataResponse | undefined = await scriptRefresh({
                filename: node.key,
            });
            const tags = data?.settings?.project?.tags?.split(';') || [];
            form.setFieldsValue({ tags: tags });
            setTreeData(
                updateTreeDataNode(treeData, {
                    key: node.key,
                    loading: false,
                    content: data?.content,
                })
            );
        } catch (e) {
            notifyError({ content: e.message });
        }
    };

    const getRequestDto = (node: ScriptTreeNode): ClusterScriptRequestDTO => {
        const content = node.value || node.content;
        if (!content) {
            throw new Error('Nothing to save');
        }
        return {
            filename: `${node.key}`,
            content,
            tags: form.getFieldValue('tags')?.join(';'),
            arguments: form.getFieldValue('arguments'),
        };
    };

    const handleNodeSaveClick = async (node: ScriptTreeNode) => {
        try {
            await save(getRequestDto(node));
        } catch (e: any) {
            notifyError({ content: e.message });
            log.error(e);
        }
    };

    const handleCompileClick = async (node: ScriptTreeNode) => {
        await compile(getRequestDto(node));
    };
    const handleRunClick = async (node: ScriptTreeNode) => {
        await run(getRequestDto(node));
    };

    return (
        <Form form={form} layout="vertical" style={{ height: '100%' }}>
            <AppEditor
                treeData={treeData}
                loading={loading}
                onNodeSaveClick={handleNodeSaveClick}
                onSelect={handleSelect}
                footerTopExtra={() => {
                    const tags = form.getFieldValue('tags') || [];
                    return (
                        <SpaceWide direction="vertical" size={0}>
                            <AppDivider />
                            <div style={{ padding: '0 10px' }}>
                                <ClusterScriptParamsFields tags={tags} />
                            </div>
                        </SpaceWide>
                    );
                }}
                footerLeftExtra={(node: ScriptTreeNode) => [
                    <Button
                        key="run"
                        type="primary"
                        loading={saveLoading || runLoading}
                        onClick={async () => {
                            await handleRunClick(node);
                        }}
                    >
                        Compile & run
                    </Button>,
                    <Button
                        key="compile"
                        loading={saveLoading || compileLoading}
                        onClick={async () => {
                            await handleCompileClick(node);
                        }}
                    >
                        Compile
                    </Button>,
                ]}
            />
        </Form>
    );
}

function getNodeTreeData(data: any[]): ScriptTreeNode[] {
    return data.map((item: any) => {
        const { name, path, type, contents, ...rest } = item;
        const fullPath = `${path}${name}`;
        const node: ScriptTreeNode = {
            name: name,
            key: fullPath,
            type: type === 'directory' ? 'dir' : 'file',
            isLeaf: type === 'file',
            selectable: type === 'file',
            icon: type === 'file' ? <></> : undefined,
            ...rest,
        };

        if (type === 'directory') {
            node.children = getNodeTreeData(contents);
        }

        return node;
    });
}
