import CcUser from '../../../../services/models/CcUser';

const result = {
    total: 9,
    users: [
        new CcUser({
            class_name: 'CmonUser',
            cdt_path: '/',
            owner_user_id: 1,
            owner_user_name: 'system',
            owner_group_id: 1,
            owner_group_name: 'admins',
            acl: 'user::rwx,group::r--,other::r--',
            created: '2022-02-02T13:11:15.740Z',
            disabled: false,
            effective_privileges: 'rwx',
            first_name: 'System',
            last_failed_login: '',
            last_login: '2022-09-15T16:46:17.735Z',
            last_name: 'User',
            n_failed_logins: 0,
            origin: 'CmonDb',
            suspended: false,
            user_id: 1,
            user_name: 'system',
            groups: [
                {
                    class_name: 'CmonGroup',
                    cdt_path: '/groups',
                    owner_user_id: 1,
                    owner_user_name: 'system',
                    owner_group_id: 1,
                    owner_group_name: 'admins',
                    acl: 'user::rwx,group::rwx,other::---',
                    created: '2022-02-02T13:11:15.734Z',
                    group_id: 1,
                    group_name: 'admins',
                },
            ],
            timezone: {
                class_name: 'CmonTimeZone',
                name: 'Coordinated Universal Time',
                abbreviation: 'UTC',
                offset: 0,
                use_dst: false,
            },
        }),
        new CcUser({
            class_name: 'CmonUser',
            cdt_path: '/',
            owner_user_id: 2,
            owner_user_name: 'nobody',
            owner_group_id: 1,
            owner_group_name: 'admins',
            acl: 'user::rwx,group::r--,other::r--',
            created: '2022-02-02T13:11:15.742Z',
            disabled: false,
            effective_privileges: 'rwx',
            first_name: 'Default',
            last_name: 'User',
            origin: 'CmonDb',
            suspended: false,
            user_id: 2,
            user_name: 'nobody',
            groups: [
                {
                    class_name: 'CmonGroup',
                    cdt_path: '/groups',
                    owner_user_id: 1,
                    owner_user_name: 'system',
                    owner_group_id: 1,
                    owner_group_name: 'admins',
                    acl: 'user::rwx,group::rwx,other::---',
                    created: '2022-02-02T13:11:15.738Z',
                    group_id: 3,
                    group_name: 'nobody',
                },
            ],
            timezone: {
                class_name: 'CmonTimeZone',
                name: 'Coordinated Universal Time',
                abbreviation: 'UTC',
                offset: 0,
                use_dst: false,
            },
        }),
        new CcUser({
            class_name: 'CmonUser',
            cdt_path: '/',
            owner_user_id: 3,
            owner_user_name: 'admin',
            owner_group_id: 1,
            owner_group_name: 'admins',
            acl: 'user::rwx,group::r--,other::r--',
            created: '2022-02-02T13:11:15.744Z',
            disabled: false,
            effective_privileges: 'rwx',
            first_name: 'Default',
            last_failed_login: '',
            last_login: '2022-03-24T13:28:26.367Z',
            last_name: 'User',
            n_failed_logins: 0,
            origin: 'CmonDb',
            suspended: false,
            user_id: 3,
            user_name: 'admin',
            groups: [
                {
                    class_name: 'CmonGroup',
                    cdt_path: '/groups',
                    owner_user_id: 1,
                    owner_user_name: 'system',
                    owner_group_id: 1,
                    owner_group_name: 'admins',
                    acl: 'user::rwx,group::rwx,other::---',
                    created: '2022-02-02T13:11:15.734Z',
                    group_id: 1,
                    group_name: 'admins',
                },
            ],
            timezone: {
                class_name: 'CmonTimeZone',
                name: 'Coordinated Universal Time',
                abbreviation: 'UTC',
                offset: 0,
                use_dst: false,
            },
        }),
        new CcUser({
            class_name: 'CmonUser',
            cdt_path: '/',
            owner_user_id: 4,
            owner_user_name: 'ccrpc',
            owner_group_id: 1,
            owner_group_name: 'admins',
            acl: 'user::rwx,group::r--,other::r--',
            created: '2022-02-02T13:11:26.376Z',
            disabled: false,
            effective_privileges: 'rwx',
            first_name: 'RPC',
            last_failed_login: '',
            last_login: '2022-03-24T13:23:59.153Z',
            last_name: 'API',
            n_failed_logins: 0,
            origin: 'CmonDb',
            suspended: false,
            user_id: 4,
            user_name: 'ccrpc',
            groups: [
                {
                    class_name: 'CmonGroup',
                    cdt_path: '/groups',
                    owner_user_id: 1,
                    owner_user_name: 'system',
                    owner_group_id: 1,
                    owner_group_name: 'admins',
                    acl: 'user::rwx,group::rwx,other::---',
                    created: '2022-02-02T13:11:15.734Z',
                    group_id: 1,
                    group_name: 'admins',
                },
            ],
            timezone: {
                class_name: 'CmonTimeZone',
                name: 'Coordinated Universal Time',
                abbreviation: 'UTC',
                offset: 0,
                use_dst: false,
            },
        }),
        new CcUser({
            class_name: 'CmonUser',
            cdt_path: '/',
            owner_user_id: 5,
            owner_user_name: 'pablo',
            owner_group_id: 1,
            owner_group_name: 'admins',
            acl: 'user::rwx,group::r--,other::r--',
            created: '2022-03-24T13:24:02.244Z',
            disabled: false,
            effective_privileges: 'rwx',
            email_address: 'pablo@localhost.xyz',
            first_name: 'pablo',
            last_failed_login: '',
            last_login: '2022-09-15T14:45:58.928Z',
            last_name: '',
            n_failed_logins: 0,
            origin: 'CmonDb',
            suspended: false,
            user_id: 5,
            user_name: 'pablo',
            groups: [
                {
                    class_name: 'CmonGroup',
                    cdt_path: '/groups',
                    owner_user_id: 1,
                    owner_user_name: 'system',
                    owner_group_id: 1,
                    owner_group_name: 'admins',
                    acl: 'user::rwx,group::rwx,other::---',
                    created: '2022-02-02T13:11:15.734Z',
                    group_id: 1,
                    group_name: 'admins',
                },
            ],
            timezone: {
                class_name: 'CmonTimeZone',
                name: 'Coordinated Universal Time',
                abbreviation: 'UTC',
                offset: 0,
                use_dst: false,
            },
        }),
        new CcUser({
            class_name: 'CmonUser',
            cdt_path: '/',
            owner_user_id: 15,
            owner_user_name: 'userViewAccess',
            owner_group_id: 1,
            owner_group_name: 'admins',
            acl: 'user::rwx,group::r--,other::r--',
            created: '2022-09-15T16:15:51.929Z',
            disabled: false,
            effective_privileges: 'rwx',
            email_address: 'userViewAccess@userViewAccess.com',
            first_name: 'userViewAccess',
            last_name: 'userViewAccess',
            origin: 'CmonDb',
            suspended: false,
            user_id: 15,
            user_name: 'userViewAccess',
            groups: [
                {
                    class_name: 'CmonGroup',
                    cdt_path: '/groups',
                    owner_user_id: 5,
                    owner_user_name: 'pablo',
                    owner_group_id: 1,
                    owner_group_name: 'admins',
                    acl: 'user::rwx,group::rwx,other::---',
                    created: '2022-09-13T01:11:00.948Z',
                    group_id: 5,
                    group_name: 'groupViewAccess',
                },
            ],
            timezone: {
                class_name: 'CmonTimeZone',
                name: 'Coordinated Universal Time',
                abbreviation: 'UTC',
                offset: 0,
                use_dst: false,
            },
        }),
        new CcUser({
            class_name: 'CmonUser',
            cdt_path: '/',
            owner_user_id: 16,
            owner_user_name: 'userNoAccess',
            owner_group_id: 1,
            owner_group_name: 'admins',
            acl: 'user::rwx,group::r--,other::r--',
            created: '2022-09-15T16:18:37.952Z',
            disabled: false,
            effective_privileges: 'rwx',
            email_address: 'userNoAccess@userNoAccess.com',
            first_name: 'userNoAccess',
            last_name: 'userNoAccess',
            origin: 'CmonDb',
            suspended: false,
            user_id: 16,
            user_name: 'userNoAccess',
            groups: [
                {
                    class_name: 'CmonGroup',
                    cdt_path: '/groups',
                    owner_user_id: 5,
                    owner_user_name: 'pablo',
                    owner_group_id: 1,
                    owner_group_name: 'admins',
                    acl: 'user::rwx,group::rwx,other::---',
                    created: '2022-09-15T10:52:58.438Z',
                    group_id: 9,
                    group_name: 'groupNoAccess',
                },
            ],
            timezone: {
                class_name: 'CmonTimeZone',
                name: 'Coordinated Universal Time',
                abbreviation: 'UTC',
                offset: 0,
                use_dst: false,
            },
        }),
        new CcUser({
            class_name: 'CmonUser',
            cdt_path: '/',
            owner_user_id: 17,
            owner_user_name: 'userFullAccess',
            owner_group_id: 1,
            owner_group_name: 'admins',
            acl: 'user::rwx,group::r--,other::r--',
            created: '2022-09-15T16:21:10.975Z',
            disabled: false,
            effective_privileges: 'rwx',
            email_address: 'userFullAccess@userFullAccess.com',
            first_name: 'userFullAccess',
            last_name: 'userFullAccess',
            origin: 'CmonDb',
            suspended: false,
            user_id: 17,
            user_name: 'userFullAccess',
            groups: [
                {
                    class_name: 'CmonGroup',
                    cdt_path: '/groups',
                    owner_user_id: 5,
                    owner_user_name: 'pablo',
                    owner_group_id: 1,
                    owner_group_name: 'admins',
                    acl: 'user::rwx,group::rwx,other::---',
                    created: '2022-09-12T18:16:59.975Z',
                    group_id: 4,
                    group_name: 'groupFullAccess',
                },
            ],
            timezone: {
                class_name: 'CmonTimeZone',
                name: 'Coordinated Universal Time',
                abbreviation: 'UTC',
                offset: 0,
                use_dst: false,
            },
        }),
        new CcUser({
            class_name: 'CmonUser',
            cdt_path: '/',
            owner_user_id: 18,
            owner_user_name: 'userCM1V2Access',
            owner_group_id: 1,
            owner_group_name: 'admins',
            acl: 'user::rwx,group::r--,other::r--',
            created: '2022-09-15T16:22:12.965Z',
            disabled: false,
            effective_privileges: 'rwx',
            email_address: 'userCM1V2Access@userCM1V2Access.com',
            first_name: 'userCM1V2Access',
            last_name: 'userCM1V2Access',
            origin: 'CmonDb',
            suspended: false,
            user_id: 18,
            user_name: 'userCM1V2Access',
            groups: [
                {
                    class_name: 'CmonGroup',
                    cdt_path: '/groups',
                    owner_user_id: 5,
                    owner_user_name: 'pablo',
                    owner_group_id: 1,
                    owner_group_name: 'admins',
                    acl: 'user::rwx,group::rwx,other::---',
                    created: '2022-09-15T11:01:36.908Z',
                    group_id: 10,
                    group_name: 'groupCM1V2Access',
                },
            ],
            timezone: {
                class_name: 'CmonTimeZone',
                name: 'Coordinated Universal Time',
                abbreviation: 'UTC',
                offset: 0,
                use_dst: false,
            },
        }),
    ],
};

export default result;
