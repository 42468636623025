import React from 'react';
import './AppSectionWrapper.less';
import Tabs from '../DataDisplay/Tabs';

export default AppSectionWrapper;

export type AppSectionWrapperProps = {
    items: any[];
    activeSection?: string;
    base?: string;
    querySearch?: string;
    onNavigate?: (key: string) => void;
    tall?: boolean;
};

function AppSectionWrapper({
    items,
    activeSection,
    onNavigate,
    tall,
}: AppSectionWrapperProps) {
    return (
        <Tabs
            onTabClick={onNavigate}
            activeKey={activeSection}
            destroyInactiveTabPane={true}
            tall={tall}
        >
            {items.map((item) => (
                <Tabs.TabPane key={item.key} tab={item.label}>
                    {item.children}
                </Tabs.TabPane>
            ))}
        </Tabs>
    );
}
