import ClusterTopologyConfigurator, {
    ClusterTopologyCard,
} from './ClusterTopologyConfigurator';
import CcNode, {
    CcNodeRole,
    CcNodeType,
} from '../../../services/models/CcNode';
import CcCluster from '../../../services/models/CcCluster';
import CcMySqlNode from '../../../services/models/CcMySqlNode';
import { IDiagramItem } from '@severalnines/bar-frontend-components/build/lib/DataDisplay/Diagram';
import CcGaleraNode from '../../../services/models/CcGaleraNode';
import {
    getMysqlNodeCoverCardConfig,
    getMysqlNodeExtraCard,
} from './ClusterTopologyMysqlReplicationConfigurator';

export default class ClusterTopologyGaleraConfigurator extends ClusterTopologyConfigurator {
    constructor(clusters: CcCluster[]) {
        super(clusters);
    }

    nodeToItem(
        node: CcNode,
        cluster: CcCluster,
        item: IDiagramItem
    ): IDiagramItem {
        let result = item;
        this.hasDbNodes = true;
        if (node.isType(CcNodeType.GALERA) && !node.isRole(CcNodeRole.BVS)) {
            result.arrow = 'both';
            // by default galera nodes to galera rack connection
            result.rack = 'galera';
            result.from = 'db';
        }
        if (
            (node.isType(CcNodeType.GALERA) || node.isType(CcNodeType.MYSQL)) &&
            !node.isRole(CcNodeRole.BVS)
        ) {
            if (
                node.isRole(CcNodeRole.SLAVE) ||
                (node as CcMySqlNode).isBinaryLogOFF()
            ) {
                const master = cluster.getPrimaryOf(node);

                if (master) {
                    result.from = master.getKey();
                    result.arrow = 'right';

                    if (!(node as CcMySqlNode).isReplicationRunning()) {
                        result.connectionStatus = 'error';
                    }
                }
            } else if (
                node.isType(CcNodeType.MYSQL) &&
                node.isRole(CcNodeRole.MASTER)
            ) {
                result = this.enhanceItemForPrimaries(result);
            }
        } else {
            result = super.nodeToItem(node, cluster, item);
        }
        return result;
    }

    getNodeCoverCardConfig(node: CcNode): ClusterTopologyCard {
        if (node.isType(CcNodeType.GALERA)) {
            const galeraNode = node as CcGaleraNode;
            return [
                [
                    {
                        label: 'Status',
                        value: `${galeraNode.galera?.localstatusstr} (${galeraNode.galera?.galerastatus})`,
                    },
                ],
                [
                    {
                        label: 'Cluster size',
                        value: `${galeraNode.galera?.clustersize}`,
                    },
                    {
                        label: 'WSREP',
                        value: `${galeraNode.galera?.ready}`,
                    },
                ],
                [
                    {
                        label: 'Last commited',
                        value: `${galeraNode.galera?.lastcommitted}`,
                    },
                ],
                [
                    {
                        label: 'Segment ID',
                        value: `${galeraNode.galera?.segmentid}`,
                    },
                ],
            ];
        } else if (node.isType(CcNodeType.MYSQL)) {
            return getMysqlNodeCoverCardConfig(node as CcMySqlNode);
        }
        return super.getNodeCoverCardConfig(node);
    }

    getNodeExtraCardsConfig(node: CcNode): ClusterTopologyCard[] {
        if (node.isType(CcNodeType.GALERA)) {
            const galeraNode = node as CcGaleraNode;
            return [
                [
                    [
                        {
                            label: 'Local send Q (now/avg)',
                            value: `${galeraNode.galera?.localsendqueue}/${galeraNode.galera?.localsendqueueavg}`,
                        },
                    ],
                    [
                        {
                            label: 'Local recv Q (now/avg)',
                            value: `${galeraNode.galera?.localrecvqueue}/${galeraNode.galera?.localrecvqueueavg}`,
                        },
                    ],
                    [
                        {
                            label: 'Flow control (sent/recv)',
                            value: `${galeraNode.galera?.flowctrlsent}/${galeraNode.galera?.flowctrlrecv}`,
                        },
                    ],
                    [
                        {
                            label: 'Cert deps',
                            value: `${galeraNode.galera?.certsdepsdistance}`,
                        },
                    ],
                ],
                [
                    ...getMysqlNodeCoverCardConfig(node),
                    ...[
                        [
                            {
                                label: 'Lag (s)',
                                value: `${galeraNode.replicationSlave?.secondsBehindMaster}`,
                            },
                        ],
                    ],
                ],
                ...super.getNodeExtraCardsConfig(node),
            ];
        } else if (node.isType(CcNodeType.MYSQL)) {
            return [
                getMysqlNodeExtraCard(node as CcMySqlNode),
                ...super.getNodeExtraCardsConfig(node),
            ];
        } else {
            return super.getNodeExtraCardsConfig(node);
        }
    }
}
