import { ButtonProps } from 'antd/lib/button';
import ActionButton from '@severalnines/bar-frontend-components/build/lib/General/ActionButton';
import React, { useState } from 'react';
import CmonJobService from '../../services/cmon/CmonJobsService';
import { CcJobStatus } from '../../services/models/CcJob';
import CcBackupSchedule from '../../services/models/CcBackupSchedule';
import { notifyError } from '../Notifications/uiNotification';

export type BackupScheduleDisableActionButtonProps = ButtonProps & {
    schedule: CcBackupSchedule;
    onSuccess?: () => void;
};
const BackupScheduleDisableActionButton = ({
    children,
    schedule,
    onSuccess,
    ...rest
}: BackupScheduleDisableActionButtonProps) => {
    const [loading, setLoading] = useState<boolean>(false);
    const handleClick = async () => {
        setLoading(true);
        try {
            await CmonJobService.updateJobInstance({
                cluster_id: schedule.clusterId as number,
                job: {
                    class_name: 'CmonJobInstance',
                    status: CcJobStatus.PAUSED,
                    job_id: schedule.jobId,
                },
            });
            if (onSuccess) {
                onSuccess();
            }
        } catch (err) {
            notifyError({ size: 'large', content: err.message });
        } finally {
            setLoading(false);
        }
    };
    return (
        <ActionButton onClick={handleClick} loading={loading} {...rest}>
            {children || 'Disable'}
        </ActionButton>
    );
};
export default BackupScheduleDisableActionButton;
