import React from 'react';
import { Col, Input, Row } from 'antd';
import FormItem from '../../common/DataEntry/FormItem';
import PasswordInput from '../../common/DataEntry/PasswordInput';
import TimezoneSelect from '../../common/DataEntry/TimezoneSelect';

export default UserDetailsForm;

export type UserDetailsFormProps = {
    oneColumn?: boolean;
    showPassword?: boolean;
    showUsername?: boolean;
};

function UserDetailsForm({
    oneColumn,
    showPassword,
    showUsername,
}: UserDetailsFormProps) {
    const md = oneColumn ? 24 : 12;
    return (
        <Row gutter={[24, 0]} className="UserDetailsForm">
            <Col xs={24} sm={24} md={md}>
                <FormItem
                    name="firstName"
                    label="First name"
                    rules={[
                        {
                            required: true,
                            message: 'Please enter first name',
                        },
                    ]}
                >
                    <Input placeholder="Enter first name" />
                </FormItem>
            </Col>
            <Col xs={24} sm={24} md={md}>
                <FormItem
                    name="lastName"
                    label="Last name"
                    rules={[
                        {
                            required: true,
                            message: 'Please enter last name',
                        },
                    ]}
                >
                    <Input placeholder="Enter last name" />
                </FormItem>
            </Col>
            {showUsername ? (
                <Col xs={24} sm={24} md={md}>
                    <FormItem
                        name="username"
                        label="Username"
                        rules={[
                            {
                                required: true,
                                message: 'Please enter username',
                            },
                        ]}
                    >
                        <Input placeholder="Enter username" />
                    </FormItem>
                </Col>
            ) : null}
            {showPassword ? (
                <Col xs={24} sm={24} md={md}>
                    <FormItem
                        name="password"
                        label="Password"
                        rules={[
                            {
                                required: true,
                                message: 'Please enter password',
                            },
                        ]}
                    >
                        <PasswordInput
                            autoComplete="new-password"
                            placeholder="Enter password"
                        />
                    </FormItem>
                </Col>
            ) : null}

            <Col xs={24} sm={24} md={md}>
                <FormItem
                    name="email"
                    label="Email"
                    rules={[
                        {
                            required: true,
                            type: 'email',
                            message: 'Please enter email address',
                        },
                    ]}
                >
                    <Input placeholder="Enter email address" />
                </FormItem>
            </Col>
            <Col xs={24} sm={24} md={md}>
                <FormItem name="timezone" label="Timezone">
                    <TimezoneSelect />
                </FormItem>
            </Col>
        </Row>
    );
}
