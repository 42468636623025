import merge from 'deepmerge';
import MssqlConfigurator, {
    MssqlFormValues,
} from '../../Mssql/MssqlConfigurator';
import React from 'react';
import MssqlNodeConfiguration, {
    MssqlNodeConfigurationProps,
} from '../../Mssql/MssqlNodeConfiguration';
import MssqlAdvancedNodeConfiguration from '../Mssql/MssqlAdvancedNodeConfiguration';

export default class MssqlImportConfigurator extends MssqlConfigurator {
    public static getDefaults(): MssqlFormValues {
        return merge(MssqlConfigurator.getDefaults(), {
            nodeConfig: {
                dbUser: 'SA',
                dbPassword: '',
                clusterAutoRecovery: false,
                nodeAutoRecovery: false,
            },
        });
    }

    public static getJobData(formValues: MssqlFormValues): any {
        const { nodeConfig } = formValues;

        return merge(MssqlConfigurator.getJobData(formValues), {
            db_user: undefined,
            db_password: undefined,
            admin_user: nodeConfig.dbUser,
            admin_password: nodeConfig.dbPassword,
            enable_cluster_autorecovery: nodeConfig.clusterAutoRecovery,
            enable_node_autorecovery: nodeConfig.nodeAutoRecovery,
        });
    }

    public static getJobOptions(formValues: MssqlFormValues): any {
        const { details } = formValues;
        return merge(MssqlConfigurator.getJobOptions(formValues), {
            job: {
                title: `Import SQL Server ${details?.version || ''} Cluster`,
            },
        });
    }

    public static getNodeConfigurationStep(
        props: MssqlNodeConfigurationProps
    ): React.ReactNode {
        return (
            <>
                <MssqlNodeConfiguration {...props} isImport={true} />
                <MssqlAdvancedNodeConfiguration />
            </>
        );
    }
}
