import { Form, Space } from 'antd';
import InfoIcon from '@severalnines/bar-frontend-components/build/lib/General/InfoIcon';
import InputNumberWide from '../../../../../common/DataEntry/InputNumberWide';
import React from 'react';
export default BackupRestoreUseMemoryField;
type BackupRestoreUseMemoryFieldProps = {};

function BackupRestoreUseMemoryField({}: BackupRestoreUseMemoryFieldProps) {
    return (
        <Form.Item
            name="xtraUseMemory"
            label={
                <Space>
                    <span>xtrabackup --use-memory (MiB)</span>
                    <InfoIcon
                        info={
                            <span>
                                This option affects how much memory is allocated
                                for preparing a backup with xtrabackup
                                --prepare, or analyzing statistics with
                                xtrabackup --stats. Its purpose is similar to
                                innodb_buffer_pool_size. It does not do the same
                                thing as the similarly named option in
                                Oracle&#8217;s InnoDB Hot Backup tool. The
                                default value is 100MB, and if you have enough
                                available memory, 1024MB to 2048MB is a good
                                recommended value.
                            </span>
                        }
                    />
                </Space>
            }
        >
            <InputNumberWide placeholder="Enter memory in MB" />
        </Form.Item>
    );
}
