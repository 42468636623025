import React, { useEffect } from 'react';
import SpaceDescriptions from '../../common/Layout/SpaceDescriptions';
import YesNoFormat from '../../common/Format/YesNoFormat';
import { CcTreeItemAccess } from '../../services/models/CcTreeItem';
import { getTreeItemAccessText } from './useTree';
import SpaceWide from '../../common/SpaceWide';
import { AppState, AppStateClustersMap } from '../../appReducer';
import { useSelector } from 'react-redux';
import ClusterFormat from '../Clusters/ClusterFormat';
import useGroupTree from './useGroupTree';
import AppSpin from '../../common/General/AppSpin';

export default GroupPermissions;

export type GroupPermissionsProps = {
    groupName: string;
};

function GroupPermissions({ groupName }: GroupPermissionsProps) {
    const {
        getAclOverClusters,
        getAclOverActions,
        refresh,
        loading,
    } = useGroupTree({
        name: 'tree-from-user-create-form',
        groupName,
    });
    const [clustersMap]: [
        AppStateClustersMap
    ] = useSelector(({ clusters }: AppState) => [clusters]);

    useEffect(() => {
        (async () => await refresh())();
    }, []);
    return (
        <AppSpin spinning={loading}>
            <SpaceWide direction="vertical" className="GroupPermissions">
                <SpaceDescriptions
                    direction="vertical"
                    title="Permissions"
                    size="small"
                    alignItems="right"
                >
                    {getAclOverActions().map((i) => (
                        <SpaceDescriptions.Item
                            key={i.key}
                            labelStrong
                            label={i.description}
                        >
                            <YesNoFormat
                                booleanVar={[
                                    CcTreeItemAccess.FULL_ACCESS,
                                    CcTreeItemAccess.EXECUTE,
                                ].includes(i.level)}
                            />
                        </SpaceDescriptions.Item>
                    ))}
                </SpaceDescriptions>
                <SpaceDescriptions
                    direction="vertical"
                    title="Cluster permissions"
                    size="small"
                    alignItems="right"
                >
                    <SpaceDescriptions.Item>
                        {getAclOverClusters().map((item, index) => (
                            <ClusterFormat
                                key={item.key}
                                cluster={
                                    item.clusterKey
                                        ? clustersMap.get(item.clusterKey)
                                        : undefined
                                }
                            >
                                <span>
                                    {index !== 0 ? ', ' : ''}
                                    {item.title} (
                                    {getTreeItemAccessText(item.level)})
                                </span>
                            </ClusterFormat>
                        ))}
                    </SpaceDescriptions.Item>
                </SpaceDescriptions>
            </SpaceWide>
        </AppSpin>
    );
}
