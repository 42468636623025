import React from 'react';
import { Button, Col, Divider, Row } from 'antd';
import {
    notify,
    notifyAlert,
    notifyConfirm,
    notifyError,
    notifyJobCreationError,
    NotifyJobCreationProps,
    notifyJobCreationSuccess,
    NotifyType,
} from '../components/Notifications/uiNotification';

export default DebugNotifications;

export type DebugNotificationsProps = {};

function DebugNotifications({}: DebugNotificationsProps) {
    const samples: {
        message: string;
        props: NotifyJobCreationProps;
        fn: Function;
    }[] = [
        {
            message: 'notify type=modal default',
            props: {},
            fn: notify,
        },
        {
            message: 'notify type=modal large',
            props: { size: 'large' },
            fn: notify,
        },
        {
            message: 'notify type=toast default',
            props: { type: NotifyType.TOAST },
            fn: notify,
        },
        {
            message: 'notifyAlert default',
            props: {
                title: 'Do you really want to close the wizard?',
                content: 'All the information will be lost.',
            },
            fn: notifyAlert,
        },
        {
            message: 'notifyAlert large',
            props: {
                size: 'large',
                title: 'something has happened',
                content: 'All the information will be lost.',
            },
            fn: notifyAlert,
        },
        {
            message: 'notifyAlert toast',
            props: {
                type: NotifyType.TOAST,
                title: 'something has happened',
                content: 'All the information will be lost.',
            },
            fn: notifyAlert,
        },
        {
            message: 'notifyError default',
            props: {
                content: 'All the information will be lost.',
            },
            fn: notifyError,
        },
        {
            message: 'notifyError large',
            props: {
                size: 'large',
                content: 'All the information will be lost.',
            },
            fn: notifyError,
        },
        {
            message: 'notifyError toast',
            props: {
                type: NotifyType.TOAST,
                content: 'All the information will be lost.',
            },
            fn: notifyError,
        },
        {
            message: 'notifyConfirm default',
            props: {
                title: 'Do you really want to close the wizard?',
                content: 'All the information will be lost.',
            },
            fn: notifyConfirm,
        },
        {
            message: 'notifyConfirm large',
            props: {
                size: 'large',
                title: 'Do you really want to close the wizard?',
                content: 'All the information will be lost.',
            },
            fn: notifyConfirm,
        },
        {
            message: 'notifyConfirm toast',
            props: {
                size: 'large',
                type: NotifyType.TOAST,
                title: 'Do you really want to close the wizard?',
                content: 'All the information will be lost.',
            },
            fn: notifyConfirm,
        },
        {
            message: 'notifyJobCreationSuccess default',
            props: {},
            fn: notifyJobCreationSuccess,
        },
        {
            message: 'notifyJobCreationSuccess large',
            props: { size: 'large' },
            fn: notifyJobCreationSuccess,
        },
        {
            message: 'notifyJobCreationSuccess toast',
            props: { type: NotifyType.TOAST },
            fn: notifyJobCreationSuccess,
        },
        {
            message: 'notifyJobCreationError default',
            props: {},
            fn: notifyJobCreationError,
        },
        {
            message: 'notifyJobCreationError large',
            props: { size: 'large' },
            fn: notifyJobCreationError,
        },
        {
            message: 'notifyJobCreationError toast',
            props: { type: NotifyType.TOAST },
            fn: notifyJobCreationError,
        },
    ];
    return (
        <div className="DebugNotifications">
            <h1>Job notifications</h1>
            <Row gutter={16}>
                {samples.map((sample, index) => (
                    <Col span={8} key={index}>
                        <Divider
                            plain={true}
                            orientation="left"
                            style={{ marginBottom: '0px', marginTop: '15px' }}
                        >
                            {sample.message}
                        </Divider>
                        <Button
                            onClick={() => {
                                sample.fn(sample.props);
                            }}
                            type="primary"
                        >
                            {sample.message}
                        </Button>
                    </Col>
                ))}
            </Row>
        </div>
    );
}
