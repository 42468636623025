import React from 'react';
import { Divider as AntDivider } from 'antd';
import { DividerProps as AntDividerProps } from 'antd/lib/divider';

export default AppDivider;

export type DividerProps = AntDividerProps & {
    fullHeight?: boolean;
    inline?: boolean;
    noMargin?: boolean;
    invisible?: boolean;
};

function AppDivider({
    type,
    fullHeight = false,
    noMargin = false,
    inline,
    invisible,
    ...rest
}: DividerProps) {
    return (
        <AntDivider
            className="Divider"
            style={{
                margin:
                    noMargin === true
                        ? 0
                        : type === 'vertical'
                        ? '0 5px'
                        : '5px 0',
                ...(fullHeight ? { height: '100%' } : {}),
                ...(inline ? { display: 'inline' } : {}),
                ...(invisible ? { visibility: 'hidden' } : {}),
            }}
            type={type}
            {...rest}
        />
    );
}
