


export interface CmonDataBaseProps  {
    acl?: string;
    cdt_path?: string;
    cluster_id?: number;
    data_directory?: string;
    database_id?: number;
    database_name?: string;
    database_size?: number;
    effective_privileges?: string;
    number_of_directories?: number;
    number_of_rows?: number;
    owner_group_id?: number;
    owner_group_name?: string;
    owner_user_id?: number;
    owner_user_name?: string;

}



export default class CmonDataBase {
    public acl?: string;
    public cdtPath?: string;
    public clusterId?: number;
    public dataDirectory?: string;
    public databaseId?: number;
    public databaseName?: string;
    public databaseSize?: number;
    public effectivePrivileges?: string;
    public numberOfDirectories?: number;
    public numberOfRows?: number;
    public ownerGroupId?: number;
    public ownerGroupName?: string;
    public ownerUserId?: number;
    public ownerUserName?: string;


    constructor(props: CmonDataBaseProps) {
        this.acl = props.acl;
        this.cdtPath = props.cdt_path;
        this.clusterId = props.cluster_id;
        this.dataDirectory = props.data_directory;
        this.databaseId = props.database_id;
        this.databaseName = props.database_name;
        this.databaseSize = props.database_size;
        this.effectivePrivileges = props.effective_privileges;
        this.numberOfDirectories = props.number_of_directories;
        this.numberOfRows = props.number_of_rows;
        this.ownerGroupId = props.owner_group_id;
        this.ownerGroupName = props.owner_group_name;
        this.ownerUserId = props.owner_user_id;
        this.ownerUserName = props.owner_user_name;

    }

}
