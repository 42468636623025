import React from 'react';
import WrapFormat from '../../common/Format/WrapFormat';
import CcBackup from '../../services/models/CcBackup';
import { Space } from 'antd';
import { StorageIcon } from '../../common/icons/icons';
import { CloudOutlined } from '@ant-design/icons';
import StorageLocationTable from './StorageLocations/StorageLocationTable';

export default StorageLocationFormat;

export type StorageLocationFormatProps = {
    backup: CcBackup;
    children?: React.ReactNode;
};

function StorageLocationFormat({
    backup,
    children,
}: StorageLocationFormatProps) {
    return (
        <WrapFormat
            popoverContent={
                <StorageLocationTable
                    storageLocations={backup.getStorageLocations()}
                />
            }
        >
            {children || (
                <Space>
                    <Space size={2} style={{ verticalAlign: 'middle' }}>
                        <StorageIcon />
                        <span>{backup.hostLocations.length}</span>
                    </Space>
                    <Space size={2} style={{ verticalAlign: 'middle' }}>
                        <CloudOutlined />
                        <span>{backup.cloudLocations.length}</span>
                    </Space>
                </Space>
            )}
        </WrapFormat>
    );
}
