import React from 'react';
import { ACLManageContent } from './useCurrentUser';
import CcCluster from '../../services/models/CcCluster';
import { CcTreeItemHandledAclPath } from '../../services/models/CcTreeItem';

export default UserAclManageConfiguration;

export type UserAclManageConfigurationProps = {
    cluster?: CcCluster;
    children?: React.ReactNode;
};

function UserAclManageConfiguration({ cluster, ...rest }: UserAclManageConfigurationProps) {
    return <ACLManageContent path={CcTreeItemHandledAclPath.ACL_UI_CONFIG} {...rest} />;
}
