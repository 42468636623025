import React from 'react';
import ModalDefaultForm from '../../common/ModalDefaultForm';
import { useForm } from 'antd/lib/form/Form';
import { Form, Input, Space } from 'antd';
import FormItemInlineSwitch from '../../common/DataEntry/FormItemInlineSwitch';
import InfoIcon from '@severalnines/bar-frontend-components/build/lib/General/InfoIcon';
import FormFooter from '../../common/FormFooter';
import useCreateJob from '../Jobs/useCreateJob';
import { CmonJobInstanceCommand } from '../../services/cmon/models/CmonJobInstance';

type CreateReportFormFields = {
    destination: string;
    maskPasswords: boolean;
};
export default CreateReportModal;
export type CreateReportModalProps = {
    clusterId?: number;
    onCancel?: () => void;
    onSuccess?: () => void;
};

function CreateReportModal({
    clusterId,
    onCancel,
    onSuccess,
    ...rest
}: CreateReportModalProps) {
    const [form] = useForm<CreateReportFormFields>();
    const { send, loading } = useCreateJob({
        clusterId: clusterId,
        title: 'Generate error report',
        command: CmonJobInstanceCommand.ERROR_REPORT,
    });
    const handleFormFinish = async ({
        destination,
        maskPasswords,
    }: CreateReportFormFields) => {
        await send({
            clusterId: clusterId,
            report_dir: destination,
            mask_passwords: maskPasswords,
        });
        onSuccess?.();
    };
    return (
        <ModalDefaultForm
            title="Generate error report"
            form={form}
            footer={[]}
            width={450}
            defaultVisible={true}
            onCancel={onCancel}
        >
            <Form
                form={form}
                onFinish={handleFormFinish}
                layout="vertical"
                initialValues={{ maskPasswords: true }}
            >
                <Form.Item
                    name="destination"
                    label={<Space>Destination</Space>}
                >
                    <Input placeholder="Enter destination path" />
                </Form.Item>
                <FormItemInlineSwitch
                    justify
                    noMargin
                    name="maskPasswords"
                    label={
                        <Space>
                            <span>Mask password</span>
                            <InfoIcon
                                info={
                                    <span>
                                        When it sets CMON masks the passwords
                                        out from the report.
                                    </span>
                                }
                            />
                        </Space>
                    }
                    valuePropName="checked"
                />
                <FormFooter
                    loading={loading}
                    submitButtonText="Generate"
                    showSubmitButton={true}
                />
            </Form>
        </ModalDefaultForm>
    );
}
