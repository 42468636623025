import React from 'react';
import { Form, Col, Row, Input, Space } from 'antd';
import FormItemInlineSwitch from '../../../../../../common/DataEntry/FormItemInlineSwitch';
import AppSelect from '../../../../../../common/DataEntry/AppSelect';
import InputNumberWide from '../../../../../../common/DataEntry/InputNumberWide';
import InfoIcon from '@severalnines/bar-frontend-components/build/lib/General/InfoIcon';

export const RULES_ADV_SETTING_INITIAL = {
    rulesAdvSetting: {
        negativePattern: false,
        reconnect: 'default',
        log: 'default',
    },
};

export default ProxySQLRulesAdvSetting;

function ProxySQLRulesAdvSetting() {
    return (
        <div style={{ minHeight: 450 }}>
            <Row gutter={[24, 0]}>
                <Col span={24}>
                    <h3>Advanced setting</h3>
                </Col>
            </Row>
            <Row gutter={[24, 0]} align="bottom">
                <Col span={12}>
                    <Form.Item
                        name={['rulesAdvSetting', 'delay']}
                        label={
                            <Space>
                                Delay
                                <InfoIcon
                                    info={
                                        'Number of milliseconds to delay the execution of the query. This is essentially a throttling mechanism and QoS, allowing to give priority to some queries instead of others. This value is added to the mysql-default_query_delay global variable that applies to all queries. Future version of ProxySQL will provide a more advanced throttling mechanism.'
                                    }
                                />
                            </Space>
                        }
                    >
                        <InputNumberWide
                            min={0}
                            placeholder="Click here to enter delay."
                        ></InputNumberWide>
                    </Form.Item>
                </Col>

                <Col span={12}>
                    <FormItemInlineSwitch
                        justify={true}
                        label={
                            <Space>
                                Negative match pattern
                                <InfoIcon
                                    info={
                                        'If this is enabled, only queries not matching the query text will be considered as a match. This acts as a NOT operator in front of the regular expression matching against match_pattern or match_digest.'
                                    }
                                />
                            </Space>
                        }
                        name={['rulesAdvSetting', 'negativePattern']}
                        labelStrong={false}
                        valuePropName="checked"
                    />
                </Col>
            </Row>

            <Row gutter={[24, 0]}>
                <Col span={12}>
                    <Form.Item
                        name={['rulesAdvSetting', 'mirrorHostGroup']}
                        label={'Mirror hostgroup'}
                    >
                        <AppSelect
                            placeholder={'Enter mirror hostgroup.'}
                            options={[
                                { value: '10', label: '10' },
                                { value: '20', label: '20' },
                            ]}
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        name={['rulesAdvSetting', 'mirrorFlagOut']}
                        label={'Mirror flag out'}
                    >
                        <InputNumberWide
                            min={0}
                            placeholder="Enter mirror flag out."
                        ></InputNumberWide>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        name={['rulesAdvSetting', 'flagIN']}
                        label={
                            <Space>
                                flagIN
                                <InfoIcon
                                    info={
                                        'This, together with flag out and apply, allow us to create "chains of rules" that get applied one after the other. An input flag value is set to 0, and only rules with flagIN=0 are considered at the beginning. When a matching rule is found for a specific query, flagOUT is evaluated and if NOT NULL the query will be flagged with the specified flag in flagOUT. If flagOUT differs from flagIN , the query will exit the current chain and enters a new chain of rules having flagIN as the new input flag. If flagOUT matches flagIN, the query will be re-evaluate again against the first rule with said flagIN. This happens until there are no more matching rules, or apply is set to 1 (which means this is the last rule to be applied).'
                                    }
                                />
                            </Space>
                        }
                    >
                        <InputNumberWide
                            min={0}
                            placeholder="Enter flagIN."
                        ></InputNumberWide>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        name={['rulesAdvSetting', 'flagOut']}
                        label={
                            <Space>
                                flagOut
                                <InfoIcon
                                    info={
                                        'This, together with flag in and apply, allow us to create "chains of rules" that get applied one after the other. An input flag value is set to 0, and only rules with flagIN=0 are considered at the beginning. When a matching rule is found for a specific query, flagOUT is evaluated and if NOT NULL the query will be flagged with the specified flag in flagOUT. If flagOUT differs from flagIN , the query will exit the current chain and enters a new chain of rules having flagIN as the new input flag. If flagOUT matches flagIN, the query will be re-evaluate again against the first rule with said flagIN. This happens until there are no more matching rules, or apply is set to 1 (which means this is the last rule to be applied).'
                                    }
                                />
                            </Space>
                        }
                    >
                        <InputNumberWide
                            min={0}
                            placeholder="Enter flagOut."
                        ></InputNumberWide>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        name={['rulesAdvSetting', 'reconnect']}
                        label={'Reconnect'}
                    >
                        <AppSelect
                            placeholder={'Enter reconnect.'}
                            options={[
                                { value: 'default', label: 'Default' },
                                { value: 'disabled', label: 'Disabled' },
                                { value: 'enabled', label: 'Enabled' },
                            ]}
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        name={['rulesAdvSetting', 'log']}
                        label={
                            <Space>
                                Log
                                <InfoIcon info={'Query will be logged.'} />
                            </Space>
                        }
                    >
                        <AppSelect
                            placeholder={'Enter log.'}
                            options={[
                                { value: 'default', label: 'Default' },
                                { value: 'disabled', label: 'Disabled' },
                                { value: 'enabled', label: 'Enabled' },
                            ]}
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        name={['rulesAdvSetting', 'stickyConn']}
                        label={
                            <Space>
                                Sticky conn
                                <InfoIcon
                                    info={
                                        'Connections are routed to the same destination within the same connection from proxysql instance to backend.'
                                    }
                                />
                            </Space>
                        }
                        rules={[
                            {
                                pattern: new RegExp(/^[0-1]+$/),
                                message: 'Sticky conn must be from 0 : 1.',
                            },
                        ]}
                    >
                        <InputNumberWide placeholder="Enter sticky conn."></InputNumberWide>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        name={['rulesAdvSetting', 'multiplex']}
                        label={
                            <Space>
                                Multiplex
                                <InfoIcon
                                    info={
                                        'If 0, multiplex will be disabled. If 1, multiplex could be re-enabled if there are is not any other conditions preventing this (like user variables or transactions). If 2, multiplexing is not disabled for just the current query. See wiki Default is NULL, thus not modifying multiplexing policies.'
                                    }
                                />
                            </Space>
                        }
                        rules={[
                            {
                                pattern: new RegExp(/^[0-2]+$/),
                                message: 'Multiplex must be from 0 : 2.',
                            },
                        ]}
                    >
                        <InputNumberWide placeholder="Enter multiplex."></InputNumberWide>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        name={['rulesAdvSetting', 'okMessage']}
                        label={'Ok message'}
                    >
                        <Input placeholder="Enter ok message."></Input>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        name={['rulesAdvSetting', 'errorMessage']}
                        label={'Error message'}
                    >
                        <Input placeholder="Enter error message."></Input>
                    </Form.Item>
                </Col>
            </Row>
        </div>
    );
}
