import React from 'react';
import { Form } from 'antd';
import { Col, Row, Space, Input } from 'antd';

export const haProxyAdvancedValidation = [
    ['haProxyAdvanced', 'adminUser'],
    ['haProxyAdvanced', 'adminPassword'],
    ['haProxyAdvanced', 'cmdLine'],
    ['haProxyAdvanced', 'lbName'],
    ['haProxyAdvanced', 'haProxyConfig'],
    ['haProxyAdvanced', 'statsSocket'],
];
export const haProxyAdvancedInitial = {
    haProxyAdvanced: {
        adminUser: 'admin',
        adminPassword: 'admin',
        cmdLine:
            '/usr/sbin/haproxy -D -f /etc/haproxy/haproxy.cfg -sf $(cat /var/run/haproxy.pid)',
        lbName: 'lb_group1',
        haProxyConfig: '/etc/haproxy/haproxy.cfg',
        statsSocket: '/var/run/haproxy.socket',
    },
};

export default ImportHAProxyAdvanced;

function ImportHAProxyAdvanced() {
    return (
        <div style={{ minHeight: 450 }}>
            <Row gutter={[24, 0]}>
                <Col span={24}>
                    <h3>Advanced settings</h3>
                </Col>
            </Row>
            <Row gutter={[24, 0]}>
                <Col span={12}>
                    <Form.Item
                        name={['haProxyAdvanced', 'adminUser']}
                        label={<Space>Admin user</Space>}
                        rules={[
                            {
                                required: true,
                                message: 'Enter admin user.',
                            },
                        ]}
                    >
                        <Input placeholder="Enter admin user."></Input>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        name={['haProxyAdvanced', 'adminPassword']}
                        label={<Space>Admin password</Space>}
                        rules={[
                            {
                                required: true,
                                message: 'Enter admin password.',
                            },
                        ]}
                    >
                        <Input placeholder="Enter admin password."></Input>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        name={['haProxyAdvanced', 'cmdLine']}
                        label={<Space>cmdline</Space>}
                        rules={[
                            {
                                required: true,
                                message: 'Enter cmdline.',
                            },
                        ]}
                    >
                        <Input placeholder="Enter cmdline."></Input>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        name={['haProxyAdvanced', 'lbName']}
                        label={<Space>LB name</Space>}
                        rules={[
                            {
                                required: true,
                                message: 'Enter LB name.',
                            },
                        ]}
                    >
                        <Input placeholder="Enter LB name."></Input>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        name={['haProxyAdvanced', 'haProxyConfig']}
                        label={<Space>Haproxy config</Space>}
                        rules={[
                            {
                                required: true,
                                message: 'Enter Haproxy config.',
                            },
                        ]}
                    >
                        <Input placeholder="Enter Haproxy config."></Input>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        name={['haProxyAdvanced', 'statsSocket']}
                        label={<Space>Stats socket</Space>}
                        rules={[
                            {
                                required: true,
                                message: 'Enter stats socket.',
                            },
                        ]}
                    >
                        <Input placeholder="Enter stats socket."></Input>
                    </Form.Item>
                </Col>
            </Row>
        </div>
    );
}
