import { Alert } from 'antd';
import React, { useRef } from 'react';
import ModalDefault from '../../common/ModalDefault';
import CcCluster from '../../services/models/CcCluster';
import { CLUSTER_CONFIG_READONLY_CATEGORIES } from '../Clusters/useClusterConfigGrouped';
import ClusterConfigParams from '../Clusters/Config/ClusterConfigParams';
import { ConfigNodeFileProvider } from '../Clusters/Config/ConfigContext/ConfigNodeFileProvider';

export default ConfigEditParamsModal;
export type ConfigEditParamsButtonModal = {
    cluster: CcCluster;
    title: React.ReactNode;
    hostname?: string;
    port?: number;
    filename?: string;
    onCancel?: (changed: boolean) => void;
};

function ConfigEditParamsModal({
    cluster,
    title,
    hostname,
    port,
    filename,
    onCancel,
    ...rest
}: ConfigEditParamsButtonModal) {
    const changed = useRef<boolean>(false);
    const handleValueChanged = () => {
        changed.current = true;
    }
    const handleCancel = () => {
        onCancel?.(changed.current);
    }
    return (
        <ModalDefault
            title={title}
            onCancel={handleCancel}
            width={900}
            visible={true}
            bodyStyle={{ padding: '25px', minHeight: 600 }}
        >
            <ConfigNodeFileProvider
                cluster={cluster}
                loadConfigGrouped={true}
                hostname={hostname}
                port={port}
                filename={filename}
                onValueChanged={handleValueChanged}
            >
                <ClusterConfigParams
                    readonlyCategories={CLUSTER_CONFIG_READONLY_CATEGORIES}
                    noDescription={true}
                    readonlyMessage={
                        <Alert
                            message={
                                <span>
                                    This category contains CMON settings and
                                    can't be changed for the specific cluster,
                                    to change these settings please check
                                    /etc/cmon.cnf file
                                </span>
                            }
                        />
                    }
                />
            </ConfigNodeFileProvider>
        </ModalDefault>
    );
}
