import ClusterTopologyConfigurator, {
    ClusterTopologyCard,
} from './ClusterTopologyConfigurator';
import CcNode, {
    CcNodeRole,
    CcNodeType,
} from '../../../services/models/CcNode';
import CcCluster from '../../../services/models/CcCluster';
import CcMySqlNode from '../../../services/models/CcMySqlNode';
import { getReadonlyTag } from '../../Nodes/NodeFormatHelper';
import TruncateText from '@severalnines/bar-frontend-components/build/lib/Format/TruncateText';
import UnavailableFormat from '@severalnines/bar-frontend-components/build/lib/Format/UnavailableFormat';

export default class ClusterTopologyMysqlReplicationConfigurator extends ClusterTopologyConfigurator {
    constructor(clusters: CcCluster[]) {
        super(clusters);
    }

    nodeToItem(node: CcNode, cluster: CcCluster, item: any): any {
        let result = item;
        if (node.isType(CcNodeType.MYSQL) && !node.isRole(CcNodeRole.BVS)) {
            this.hasDbNodes = true;
            const master = cluster?.getPrimaryOf(node);

            if (node.isRole(CcNodeRole.MASTER)) {
                result = this.enhanceItemForPrimaries(result);
            }
            if (master) {
                if (node.isRole(CcNodeRole.MULTI)) {
                    const isPrimaryOfItself = cluster?.isPrimaryOf(
                        node,
                        node,
                        true
                    );
                    if (isPrimaryOfItself && master.isRole(CcNodeRole.MULTI)) {
                        result.rack = 'multi-master';
                        result.arrow = 'both';
                    } else {
                        result.from = master.getKey();
                        result.arrow = 'right';
                        result.data.overrideNodeDesc = 'Intermediate';
                    }
                } else {
                    result.from = master.getKey();
                    result.arrow = 'right';
                }
                if (!(node as CcMySqlNode).isReplicationRunning()) {
                    result.connectionStatus = 'error';
                }
            }
        } else {
            result = super.nodeToItem(node, cluster, item);
        }
        return result;
    }

    getNodeCoverCardConfig(node: CcNode): ClusterTopologyCard {
        if (node.isType(CcNodeType.MYSQL)) {
            return getMysqlNodeCoverCardConfig(node as CcMySqlNode);
        }
        return super.getNodeCoverCardConfig(node);
    }

    getNodeExtraCardsConfig(node: CcNode): ClusterTopologyCard[] {
        return [
            ...(node.isDatabaseNode()
                ? [getMysqlNodeExtraCard(node as CcMySqlNode)]
                : []),
            ...super.getNodeExtraCardsConfig(node),
        ];
    }
}

export function getMysqlNodeCoverCardConfig(
    node: CcMySqlNode
): ClusterTopologyCard {
    return [
        [
            { label: 'Server ID', value: `${node.serverid}` },
            ...(node.isRole(CcNodeRole.SLAVE)
                ? [
                      {
                          label: 'Lag',
                          value: (
                              <UnavailableFormat
                                  val={
                                      node.replicationSlave?.secondsBehindMaster
                                  }
                              >
                                  {node.replicationSlave?.secondsBehindMaster}s
                              </UnavailableFormat>
                          ),
                      },
                  ]
                : []),
            { value: getReadonlyTag(node) },
        ],
        ...(node.isRole(CcNodeRole.MASTER)
            ? [
                  [
                      {
                          label: 'Executed Gtid',
                          value: node.replicationMaster?.execGtid && (
                              <TruncateText
                                  text={node.replicationMaster?.execGtid}
                              />
                          ),
                      },
                  ],
                  [
                      {
                          label: 'Binlog file',
                          value: node.replicationMaster?.file,
                      },
                  ],
                  [
                      {
                          label: 'Binlog position',
                          value: node.replicationMaster?.position,
                      },
                  ],
              ]
            : []),
        ...([
            CcNodeRole.SLAVE,
            CcNodeRole.MULTI,
            CcNodeRole.INTERMEDIATE,
        ].includes(node.role as CcNodeRole)
            ? [
                  [
                      {
                          label: 'Retrieved Gtid Set',
                          value: node.replicationSlave?.retrievedGtidSet && (
                              <TruncateText
                                  text={node.replicationSlave?.retrievedGtidSet}
                              />
                          ),
                      },
                  ],
                  [
                      {
                          label: 'Executed Gtid Set',
                          value: node.replicationSlave?.executedGtidSet && (
                              <TruncateText
                                  text={node.replicationSlave?.executedGtidSet}
                              />
                          ),
                      },
                  ],
                  [
                      {
                          label: 'Master Log File',
                          value: node.replicationSlave?.masterLogFile,
                      },
                  ],
                  [
                      {
                          label: 'Read Master Log Pos',
                          value: node.replicationSlave?.readMasterLogPos,
                      },
                  ],
              ]
            : []),
    ];
}

export function getMysqlNodeExtraCard(node: CcMySqlNode): ClusterTopologyCard {
    const mysqlNode = node as CcMySqlNode;
    return [
        ...(node.isRole(CcNodeRole.MASTER)
            ? [
                  [
                      {
                          label: 'Binlog do DB',
                          value: mysqlNode.replicationMaster?.binlogDoDb,
                      },
                  ],
                  [
                      {
                          label: 'Binlog ignore DB',
                          value: mysqlNode.replicationMaster?.binlogIgnoreDb,
                      },
                  ],
                  [
                      {
                          label: 'Semisync status',
                          value: mysqlNode.replicationMaster?.semisyncStatus,
                      },
                      {
                          label: 'Binary log',
                          value: mysqlNode.logBin,
                      },
                  ],
              ]
            : []),
        ...([
            CcNodeRole.SLAVE,
            CcNodeRole.MULTI,
            CcNodeRole.INTERMEDIATE,
        ].includes(mysqlNode.role as CcNodeRole)
            ? [
                  [
                      {
                          label: 'Exec Master Log Pos',
                          value: mysqlNode.replicationSlave?.execMasterLogPos,
                      },
                  ],
                  [
                      {
                          label: 'Slave IO Running',
                          value: mysqlNode.replicationSlave?.slaveIoRunning,
                      },
                  ],
                  [
                      {
                          label: 'Slave SQL Running',
                          value: mysqlNode.replicationSlave?.slaveSqlRunning,
                      },
                  ],
                  [
                      {
                          label: 'Semisync status',
                          value: mysqlNode.replicationMaster?.semisyncStatus,
                      },
                      {
                          label: 'Binary log',
                          value: mysqlNode.logBin,
                      },
                  ],
              ]
            : []),
    ];
}
