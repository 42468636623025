export function toUpperCaseFirst(input: string) {
    return input.charAt(0).toUpperCase() + input.slice(1);
}

export function abbreviateString(str: string, maxLength: number = 0) {
    const words = str.split(' ');
    if (maxLength > 0) {
        words.splice(0, maxLength);
    }
    return words.map((word: string) => word.charAt(0).toUpperCase()).join('');
}

export function clearTags(str: string): string {
    return str.replace(/<\/?[^>]+(>|$)/g, '');
}

export function ucFirst(input: string) {
    return input.charAt(0).toUpperCase() + input.slice(1);
}
