const mock = {
    class_name: 'CmonClusterInfo',
    cdt_path: '/',
    acl: 'user::rwx,group::rwx,other::---',
    tags: [],
    cluster_auto_recovery: true,
    cluster_id: 7,
    cluster_name: 'redis6dev',
    cluster_type: 'REDIS',
    configuration_file: '/etc/cmon.d/cmon_5.cnf',
    effective_privileges: 'rwx',
    log_file: '/var/log/cmon_5.log',
    maintenance_mode_active: false,
    managed: true,
    node_auto_recovery: true,
    rpc_version: '2.0',
    state: 'FAILURE',
    status_text: 'Cluster failure.',
    vendor: 'redis',
    version: '',
    alarm_statistics: {
        class_name: 'CmonAlarmStatistics',
        cluster_id: 5,
        created_after: '1970-01-01T00:00:00.000Z',
        critical: 0,
        reported_after: '1970-01-01T00:00:00.000Z',
        warning: 1,
    },
    group_owner: {
        class_name: 'CmonGroup',
        cdt_path: '/groups',
        owner_user_id: 1,
        owner_user_name: 'system',
        owner_group_id: 1,
        owner_group_name: 'admins',
        acl: 'user::rwx,group::rwx,other::---',
        created: '2021-04-28T22:57:55.328Z',
        group_id: 1,
        group_name: 'admins',
    },
    hosts: [
        {
            class_name: 'CmonRedisHost',
            cdt_path: '/cluster_5',
            owner_user_id: 7,
            owner_user_name: 'admin',
            owner_group_id: 1,
            owner_group_name: 'admins',
            acl: 'user::rwx,group::rwx,other::---',
            clusterid: 5,
            configfile: '/etc/redis/redis.conf',
            connected: true,
            container: true,
            datadir: '/var/lib/redis',
            description: '',
            hostId: 31,
            hostname: '10.0.8.33',
            hoststatus: 'CmonHostShutDown',
            ip: '10.0.8.33',
            lastseen: 1628602994,
            logfile: '/var/log/redis/redis-server.log',
            maintenance_mode: 'none',
            maintenance_mode_active: false,
            message: 'Redis Server is stopped by the user.',
            nodetype: 'redis',
            pidfile: '/var/run/redis/redis-server.pid',
            pingstatus: 1,
            pingtime: 67,
            port: 6379,
            role: 'master',
            sshfailcount: 0,
            statereason: 'Redis Server is stopped by the user.',
            status: 11,
            timestamp: 1628602994,
            unique_id: 26,
            uptime: 78145,
            version: '6.0.6',
            wallclock: 1628602966,
            wallclocktimestamp: 1628602966,
            distribution: {
                name: 'ubuntu',
                codename: 'bionic',
                release: '18.04',
                type: 'debian',
            },
            redis: {
                appendlogname: 'appendonly.aof',
                appendonly: true,
            },
            replication_master: {
                slave_info: {
                    slaves: [],
                },
            },
        },
        {
            class_name: 'CmonRedisSentinelHost',
            cdt_path: '/cluster_5',
            owner_user_id: 7,
            owner_user_name: 'admin',
            owner_group_id: 1,
            owner_group_name: 'admins',
            acl: 'user::rwx,group::rwx,other::---',
            clusterid: 5,
            configfile: '/etc/redis/sentinel.conf',
            connected: true,
            container: true,
            datadir: '/var/lib/redis',
            description: 'All is fine.',
            hostId: 31,
            hostname: '10.0.8.33',
            hoststatus: 'CmonHostOnline',
            ip: '10.0.8.33',
            lastseen: 1628603018,
            maintenance_mode: 'none',
            maintenance_mode_active: false,
            message: 'Up and running.',
            nodetype: 'sentinel',
            pingstatus: 1,
            pingtime: 67,
            port: 26379,
            sshfailcount: 0,
            statereason: 'Up and running.',
            status: 10,
            timestamp: 1628603018,
            unique_id: 27,
            uptime: 951152,
            version: '6.0.6',
            wallclock: 1628602966,
            wallclocktimestamp: 1628602966,
            distribution: {
                name: 'ubuntu',
                codename: 'bionic',
                release: '18.04',
                type: 'debian',
            },
            sentinel: {
                master_info: [
                    {
                        name: 'cluster_5',
                        address: '10.0.8.33:6379',
                        id: 'primary0',
                        sentinels: 1,
                        slaves: 0,
                        status: 'ok',
                    },
                ],
            },
        },
        {
            class_name: 'CmonHost',
            cdt_path: '/cluster_5',
            owner_user_id: 7,
            owner_user_name: 'admin',
            owner_group_id: 1,
            owner_group_name: 'admins',
            acl: 'user::rwx,group::rwx,other::---',
            clusterid: 5,
            configfile: '/etc/cmon.d/cmon_5.cnf',
            connected: true,
            container: false,
            hostId: 33,
            hostname: '143.198.228.20',
            hoststatus: 'CmonHostOnline',
            ip: '143.198.228.20',
            lastseen: 1628602994,
            logfile: '/var/log/cmon_5.log',
            maintenance_mode: 'none',
            maintenance_mode_active: false,
            message: 'Up and running.',
            nodetype: 'controller',
            pid: 15943,
            pingstatus: -1,
            pingtime: -1,
            port: 9500,
            role: 'controller',
            status: 10,
            timestamp: 1628602994,
            unique_id: 28,
            uptime: 5931,
            version: '1.9.1.4712',
            wallclock: 1628602966,
            wallclocktimestamp: 1628602966,
            distribution: {
                name: 'ubuntu',
                codename: 'bionic',
                release: '18.04',
                type: 'debian',
            },
        },
    ],
    job_statistics: {
        class_name: 'CmonJobStatistics',
        cluster_id: 5,
        by_state: {
            ABORTED: 0,
            DEFINED: 0,
            DEQUEUED: 0,
            FAILED: 0,
            FINISHED: 0,
            PAUSED: 2,
            RUNNING: 0,
        },
    },
    owner: {
        class_name: 'CmonUser',
        cdt_path: '/',
        acl: 'user::rwx,group::r--,other::r--',
        disabled: false,
        suspended: false,
        groups: [],
        timezone: {
            class_name: 'CmonTimeZone',
            name: 'Coordinated Universal Time',
            abbreviation: 'UTC',
            offset: 0,
            use_dst: false,
        },
    },
};

export default mock;
