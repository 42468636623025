import { FormInstance } from 'antd/lib/form';
import { useEffect, useState } from 'react';
import {
    BackupFormFieldsType,
    BackupFormWizardStep,
    getBackupStepAvailableConfig,
} from '../Config/BackupFormConfigurator';
import merge from 'deepmerge';

export type UseBackupWizardStepsProps = {
    form: FormInstance;
};
export default function useBackupWizardSteps({
    form,
}: UseBackupWizardStepsProps) {
    const [availableSteps, setAvailableSteps] = useState<
        BackupFormWizardStep[]
    >([]);

    useEffect(() => {
        setAvailableSteps(getBackupAvailableSteps(form.getFieldsValue()));
    }, []);

    const valuesUpdated = (values: BackupFormFieldsType) => {
        setAvailableSteps(
            getBackupAvailableSteps(merge(form.getFieldsValue(true), values))
        );
    };

    return {
        availableSteps,
        valuesUpdated,
    };
}

function getBackupAvailableSteps(values: BackupFormFieldsType) {
    return [
        BackupFormWizardStep.CONFIGURATION,
        ...[
            BackupFormWizardStep.ADVANCED,
            BackupFormWizardStep.STORAGE,
            BackupFormWizardStep.CLOUD,
            BackupFormWizardStep.VERIFY,
            BackupFormWizardStep.SCHEDULE,
        ].filter((step) => {
            return getBackupStepAvailableConfig(step)(values);
        }),
        BackupFormWizardStep.PREVIEW,
    ];
}
