import React from 'react';
import FormItemInlineSwitch, {
    FormItemInlineSwitchProps,
} from '../../DataEntry/FormItemInlineSwitch';

export default InstallSoftwareSwitch;

export type InstallSoftwareSwitchProps = FormItemInlineSwitchProps & {};

function InstallSoftwareSwitch({ ...rest }: InstallSoftwareSwitchProps) {
    return (
        <div className="InstallSoftwareSwitch">
            <FormItemInlineSwitch
                justify={true}
                name="installSoftware"
                label="Install software"
                valuePropName="checked"
                {...rest}
            />
        </div>
    );
}
