import React from 'react';
import { Form, Select, Row, Col, Button, Badge } from 'antd';
import {
    AppState,
    AppStateGlobalFilters,
    AppStateTags,
    AppStateTechnologies,
    setGlobalFilters,
} from '../appReducer';
import { useDispatch, useSelector } from 'react-redux';
import useLocalStorage from '@severalnines/bar-frontend-components/build/lib/hooks/useLocalStorage';
import TechnologyFormat from '../components/Clusters/TechnologyFormat';
import {
    CcClusterTechnology,
    getClusterTypesByTechnology,
} from '../services/models/CcCluster';
import { Map } from 'immutable';
import { FilterOutlined } from '@ant-design/icons';
import AppPopover from './Feedback/AppPopover';

export default GlobalFilter;
export function clearGlobalFilters(filters: any) {
    return filters
        ? filters.filter(
              ({ key }: { key: string }) =>
                  !['cluster_type', 'tags'].includes(key)
          )
        : filters;
}
export function getFiltersForFetch(filterMap: AppStateGlobalFilters) {
    let filters: any = [];

    if (filterMap) {
        if (
            filterMap.get('technologies') &&
            filterMap.get('technologies').length > 0
        ) {
            filters.push({
                key: 'cluster_type',
                values: filterMap
                    .get('technologies')
                    .map((t: CcClusterTechnology) =>
                        getClusterTypesByTechnology(t)
                    )
                    .flat(),
            });
        }
        if (filterMap.get('tags') && filterMap.get('tags').length > 0) {
            filters.push({
                key: 'tags',
                matchall: filterMap.get('tags'),
            });
        }
    }

    return filters;
}

export type GlobalFilterValues = {
    technologies: string[];
    tags: string[];
};

export type GlobalFilterProps = {
    disabled?: boolean;
    responsive?: boolean;
    onFilterChange?: (values: GlobalFilterValues) => void;
    [key: string]: any;
};

function GlobalFilter({
    disabled,
    responsive = true,
    onFilterChange,
    ...rest
}: GlobalFilterProps) {
    const dispatch = useDispatch();
    const [globalFilters, technologies, tags]: [
        AppStateGlobalFilters,
        AppStateTechnologies,
        AppStateTags
    ] = useSelector(({ globalFilters, technologies, tags }: AppState) => [
        globalFilters,
        technologies,
        tags,
    ]);

    const [selectedTags, setSelectedTags] = useLocalStorage(
        'global-filters-tags'
    );
    const [selectedTechnologies, setSelectedTechnologies] = useLocalStorage(
        'global-filters-technologies'
    );

    const handleTagFilterChange = (values: string[]) => {
        setSelectedTags(values);
        dispatch(
            setGlobalFilters(Map({ ...globalFilters.toObject(), tags: values }))
        );
        if (onFilterChange) {
            onFilterChange({
                technologies: selectedTechnologies,
                tags: values,
            });
        }
    };

    const handleTechnologyFilterChange = (values: string[]) => {
        setSelectedTechnologies(values);
        dispatch(
            setGlobalFilters(
                Map({ ...globalFilters.toObject(), technologies: values })
            )
        );
        if (onFilterChange) {
            onFilterChange({ tags: selectedTags, technologies: values });
        }
    };

    const content = (
        <Form layout={responsive ? 'vertical' : 'inline'}>
            <Form.Item label="Tags">
                <Select
                    data-testid="global-filter-tag-select"
                    disabled={disabled}
                    mode="multiple"
                    allowClear
                    style={{ minWidth: '250px' }}
                    defaultValue={selectedTags}
                    placeholder="Filter by tags"
                    onChange={handleTagFilterChange}
                >
                    {tags &&
                        tags.map((o) => (
                            <Select.Option key={o} value={o}>
                                {o}
                            </Select.Option>
                        ))}
                </Select>
            </Form.Item>
            <Form.Item label="Cluster Type">
                <Select
                    data-testid="global-filter-technology-select"
                    disabled={disabled}
                    mode="multiple"
                    allowClear
                    style={{ minWidth: '250px' }}
                    defaultValue={selectedTechnologies}
                    placeholder="Filter by technology"
                    onChange={handleTechnologyFilterChange}
                >
                    {technologies &&
                        technologies.map((o) => (
                            <Select.Option key={o} value={o}>
                                <TechnologyFormat technology={o} />
                            </Select.Option>
                        ))}
                </Select>
            </Form.Item>
        </Form>
    );
    return (
        <Row className="GlobalFilter" style={{ marginRight: '20px' }} {...rest}>
            <Col span={24} flex={1}>
                {responsive ? (
                    <AppPopover
                        content={content}
                        trigger="click"
                        placement="topRight"
                    >
                        <Badge
                            size="small"
                            count={
                                (selectedTechnologies
                                    ? selectedTechnologies.length
                                    : 0) +
                                (selectedTags ? selectedTags.length : 0)
                            }
                            style={{ backgroundColor: '#000000' }}
                        >
                            <Button>
                                <FilterOutlined />
                            </Button>
                        </Badge>
                    </AppPopover>
                ) : (
                    content
                )}
            </Col>
        </Row>
    );
}
