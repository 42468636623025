import React from 'react';
import CcCluster, { CcClusterState } from '../services/models/CcCluster';
import SpaceDescriptions from '../common/Layout/SpaceDescriptions';

import ClusterFormat from '../components/Clusters/ClusterFormat';
import { baseClustersList } from './dataMocks/clusters';
import { Col, Row } from 'antd';
import postgresWithLb from './dataMocks/clusters/postgresWithLb';

const clusters = baseClustersList
    .concat([
        {
            ...postgresWithLb,
            cluster_name: 'Postgres (with maintenance mode)',
            cluster_id: 600,
            maintenance_mode_active: true,
        },
        {
            ...postgresWithLb,
            cluster_name: 'Postgres (with error)',
            cluster_id: 601,
            state: CcClusterState.CLUSTER_FAILURE,
        },
        {
            ...postgresWithLb,
            cluster_name: 'Postgres (with warning)',
            cluster_id: 601,
            state: CcClusterState.CLUSTER_DEGRADED,
        },
    ])
    .map((c) => new CcCluster(c as any));

export default DebugClusterFormat;

export type DebugClusterFormatProps = {};

function DebugClusterFormat({}: DebugClusterFormatProps) {
    return (
        <Row className="DebugClusterFormat" gutter={[24, 24]}>
            <Col span={12}>
                <SpaceDescriptions title="showId=true" direction={'vertical'}>
                    <ClusterFormat
                        key={clusters[0].clusterName}
                        cluster={clusters[0]}
                        showId={true}
                    />
                </SpaceDescriptions>
                <SpaceDescriptions
                    title="showStatus=true"
                    direction={'vertical'}
                >
                    <ClusterFormat
                        key={clusters[0].clusterName}
                        cluster={clusters[0]}
                        showStatus={true}
                    />
                </SpaceDescriptions>
                <SpaceDescriptions
                    title="showStatus=true, showType=false"
                    direction={'vertical'}
                >
                    <ClusterFormat
                        key={clusters[0].clusterName}
                        cluster={clusters[0]}
                        showStatus={true}
                        showType={false}
                    />
                </SpaceDescriptions>
                <SpaceDescriptions title="size=large" direction={'vertical'}>
                    <ClusterFormat
                        key={clusters[0].clusterName}
                        cluster={clusters[0]}
                        size="large"
                    />
                </SpaceDescriptions>
                <SpaceDescriptions
                    title="size=large, showId=true"
                    direction={'vertical'}
                >
                    <ClusterFormat
                        key={clusters[0].clusterName}
                        cluster={clusters[0]}
                        size="large"
                        showId={true}
                    />
                </SpaceDescriptions>
                <SpaceDescriptions
                    title="size=large, showId=true, showStatus: true"
                    direction={'vertical'}
                >
                    <ClusterFormat
                        key={clusters[0].clusterName}
                        cluster={clusters[0]}
                        size="large"
                        showId={true}
                        showStatus={true}
                    />
                </SpaceDescriptions>
                <SpaceDescriptions
                    title="size=large, showId=true, showStatus: true, showPopover: true"
                    direction={'vertical'}
                >
                    <ClusterFormat
                        key={clusters[0].clusterName}
                        cluster={clusters[0]}
                        size="large"
                        showId={true}
                        showStatus={true}
                        showPopover={true}
                    />
                </SpaceDescriptions>
            </Col>
            <Col span={12}>
                <SpaceDescriptions title="default" direction={'vertical'}>
                    {clusters.map((c) => {
                        return (
                            <ClusterFormat key={c.clusterName} cluster={c} />
                        );
                    })}
                </SpaceDescriptions>
            </Col>

            <Col span={12}>
                <SpaceDescriptions title="With popover" direction={'vertical'}>
                    {clusters.map((c) => {
                        return (
                            <ClusterFormat
                                key={c.clusterName}
                                cluster={c}
                                showPopover={true}
                            />
                        );
                    })}
                </SpaceDescriptions>
            </Col>
            <Col span={12}>
                <SpaceDescriptions
                    title="With popover and full data"
                    direction={'vertical'}
                >
                    {clusters.map((c) => {
                        return (
                            <ClusterFormat
                                key={c.clusterName}
                                cluster={c}
                                showPopover={true}
                            />
                        );
                    })}
                </SpaceDescriptions>
            </Col>
        </Row>
    );
}
