import React from 'react';
import { Form, Col, Row, Input, Space } from 'antd';
import AppSelect from '../../../../../../common/DataEntry/AppSelect';
import InputNumberWide from '../../../../../../common/DataEntry/InputNumberWide';
import InfoIcon from '@severalnines/bar-frontend-components/build/lib/General/InfoIcon';

export const RULES_CONFIGURATION_VALIDATION = [
    ['rulesConfiguration', 'ruleId'],
    ['rulesConfiguration', 'hostgroup'],
];

export default ProxySQLRulesConfiguration;
type ProxySQLRulesConfigurationProps = {
    hostGroupList: { value: string; label: string }[] | undefined;
};

function ProxySQLRulesConfiguration({
    hostGroupList,
}: ProxySQLRulesConfigurationProps) {
    return (
        <div style={{ minHeight: 450 }}>
            <Row gutter={[24, 0]}>
                <Col span={24}>
                    <h3>Configuration</h3>
                </Col>
            </Row>
            <Row gutter={[24, 0]}>
                <Col span={12}>
                    <Form.Item
                        name={['rulesConfiguration', 'ruleId']}
                        label={
                            <Space>
                                Rule ID
                                <InfoIcon
                                    info={
                                        'The unique id of the rule. Rules are processed in Rule ID order.'
                                    }
                                />
                            </Space>
                        }
                        rules={[
                            {
                                required: true,
                                message: 'Please enter rule id.',
                            },
                        ]}
                    >
                        <InputNumberWide
                            min={0}
                            placeholder="Enter rule id."
                        ></InputNumberWide>
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={[24, 0]}>
                <Col span={12}>
                    <Form.Item
                        name={['rulesConfiguration', 'proxyAddress']}
                        label={
                            <Space>
                                Proxy address
                                <InfoIcon
                                    info={
                                        'Match incoming traffic on a specific local IP.'
                                    }
                                />
                            </Space>
                        }
                    >
                        <Input placeholder="Enter proxy address."></Input>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        name={['rulesConfiguration', 'proxyPort']}
                        label={
                            <Space>
                                Proxy port
                                <InfoIcon
                                    info={
                                        'Match incoming traffic on a specific local port.'
                                    }
                                />
                            </Space>
                        }
                    >
                        <Input placeholder="Enter proxy port."></Input>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        name={['rulesConfiguration', 'username']}
                        label={
                            <Space>
                                Username
                                <InfoIcon
                                    info={
                                        'Filtering criteria matching username. If is not empty, a query will match only if the connection is made with the correct username.'
                                    }
                                />
                            </Space>
                        }
                    >
                        <Input placeholder="Enter username."></Input>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        name={['rulesConfiguration', 'hostgroup']}
                        label={
                            <Space>
                                Destination hostgroup
                                <InfoIcon
                                    info={
                                        'Route matched queries to this hostgroup. This happens unless there is a started transaction and the logged in user has the transaction_persistent flag set to 1.'
                                    }
                                />
                            </Space>
                        }
                        rules={[
                            {
                                required: true,
                                message: 'Please enter destination hostgroup.',
                            },
                        ]}
                    >
                        <AppSelect
                            placeholder={'Enter destination hostgroup.'}
                            options={hostGroupList}
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        name={['rulesConfiguration', 'clientAddress']}
                        label={
                            <Space>
                                Client address
                                <InfoIcon
                                    info={
                                        'Match traffic from a specific source.'
                                    }
                                />
                            </Space>
                        }
                    >
                        <Input placeholder="Enter client address."></Input>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        name={['rulesConfiguration', 'schemaName']}
                        label={
                            <Space>
                                Schema name
                                <InfoIcon
                                    info={
                                        'Filtering criteria matching schema name. If is not empty, a query will match only if the connection uses this schema name as default schema.'
                                    }
                                />
                            </Space>
                        }
                    >
                        <Input placeholder="Enter schema name."></Input>
                    </Form.Item>
                </Col>
            </Row>
        </div>
    );
}
