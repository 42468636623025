import React from 'react';
import { ButtonProps } from 'antd/lib/button';

import { CmonJobInstanceCommand } from '../../../services/cmon/models/CmonJobInstance';
import CcNode from '../../../services/models/CcNode';
import ButtonWithForm from '../../../common/General/ButtonWithForm';
import JobActionButton from '../../Jobs/JobActionButton';
import ClusterConfigureWalForm from './ClusterConfigureWalForm';
import CcCluster from '../../../services/models/CcCluster';

export default ClusterConfigureWalButton;

export type ClusterConfigureWalButtonProps = ButtonProps & {
    cluster: CcCluster;
    node?: CcNode;
    children?: React.ReactNode;
    onSuccess?: () => void;
};

function ClusterConfigureWalButton({
    cluster,
    node,
    children,
    onSuccess,
    ...rest
}: ClusterConfigureWalButtonProps) {
    return (
        <ButtonWithForm
            button={
                <JobActionButton
                    command={CmonJobInstanceCommand.ENABLE_LOG_ARCHIVING}
                    clusterId={cluster.clusterId}
                    extraKey={node ? [node.hostname, node.port] : undefined}
                    {...rest}
                >
                    {children || 'Configure WAL'}
                </JobActionButton>
            }
            form={
                <ClusterConfigureWalForm
                    cluster={cluster}
                    defaultNode={node}
                    onSuccess={onSuccess}
                />
            }
        />
    );
}
