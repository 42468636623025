import moment from 'moment';

const MONTH = 30.5;
const MONTH_SECONDS = 86400 * MONTH;

const MINUTE_BREAKPOINTS = [2, 5, 10, 15, 30, 60];
const HOURS_BREAKPOINTS = [2, 4, 8, 12, 24];
const DAYS_BREAKPOINTS = [2, 4, 7, 14, MONTH];
const MONTH_BREAKPOINTS = [2, 4, 6, 12];

const DURATION_BREAKPOINTS = [
    ...MINUTE_BREAKPOINTS.map((item) => item * 60),
    ...HOURS_BREAKPOINTS.map((item) => item * 3600),
    ...DAYS_BREAKPOINTS.map((item) => item * 86400),
    ...MONTH_BREAKPOINTS.map((item) => item * MONTH_SECONDS),
];

export function timeDurationRound(
    duration: number,
    breakpoints: number[] = DURATION_BREAKPOINTS
) {
    return breakpoints.reduce(
        (prev, curr) =>
            Math.abs(curr - duration) < Math.abs(prev - duration) ? curr : prev,
        0
    );
}

export function timeRangeZoomOut(
    from: number,
    to: number,
    now: number = Math.floor(Date.now() / 1000),
    breakpoints: number[] = DURATION_BREAKPOINTS
) {
    const duration = timeDurationRound((to - from) * 2, breakpoints);
    let dateTo = to + duration / 4;
    dateTo = dateTo > now ? now : dateTo;
    return [dateTo - duration, dateTo === now ? 0 : dateTo];
}

export function timeRangeZoomIn(
    from: number,
    to: number,
    now: number = Math.floor(Date.now() / 1000),
    breakpoints: number[] = DURATION_BREAKPOINTS
) {
    const duration = timeDurationRound((to - from) / 2 + 1, breakpoints);
    return [to - duration, to];
}

export function picoToSec(value: number) {
    return value / 1000000000000; //12 zeros
}
export function nanoToSec(value: number) {
    return value / 1000000000; //9 zeros
}
export function microToSec(value: number) {
    return value / 1000000; //6 zeros
}
export function milliToSec(value: number) {
    return value / 1000; //3 zeros
}
export function isoTimeToMilliseconds(time: string) {
    const dateTime = moment(`1970-01-01 ${time}`);
    return dateTime.valueOf() - dateTime.startOf('day').valueOf();
}
