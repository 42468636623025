import './KeepalivedDetailsModal.less';
import React from 'react';
import { Row, Space } from 'antd';
import ModalDefault from '../../../../common/ModalDefault';
import CcKeepalivedNode from '../../../../services/models/CcKeepalivedNode';
import SpaceDescriptions from '../../../../common/Layout/SpaceDescriptions';
import KeepalivedDetailsTable from './KeepalivedDetailsTable';
import NodeFormat from '../../NodeFormat';
import AppPageWrapper from '../../../../common/Layout/AppPageWrapper';

export default KeepalivedDetailsModal;

export type KeepalivedDetailsModalProps = {
    node: CcKeepalivedNode;
    onCancel?: () => void;
};

function KeepalivedDetailsModal({
    node,
    onCancel,
}: KeepalivedDetailsModalProps) {
    return (
        <ModalDefault
            title="Node details"
            visible={true}
            width={450}
            onCancel={onCancel}
        >
            <AppPageWrapper
                contentClassName="KeepalivedDetailsModal"
                transparent={true}
                contentProps={{ style: { border: 0 } }}
            >
                <Row justify="space-between">
                    <Space direction="vertical" align="start" size={0}>
                        <SpaceDescriptions direction={'vertical'}>
                            <NodeFormat
                                key={node?.hostname}
                                node={node}
                                size="large"
                            />
                        </SpaceDescriptions>
                    </Space>

                    <Space align="end"></Space>
                </Row>
                <KeepalivedDetailsTable node={node} />
            </AppPageWrapper>
        </ModalDefault>
    );
}
