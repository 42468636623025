import React, {
    useCallback,
    useContext,
    useEffect,
    useImperativeHandle,
    forwardRef,
} from 'react';
import useUsersList from './useUsersList';
import { TablePaginationConfig } from 'antd/es';
import { ResponsiveContext } from '@severalnines/bar-frontend-components/build/lib/Layout/Responsive';
import CcUser from '../../services/models/CcUser';
import AppDateFormat from '../../common/AppDateFormat';
import UserActionMenu from './UserActionMenu';
import BooleanFormat from '@severalnines/bar-frontend-components/build/lib/Format/BooleanFormat';
import { getSortAlphabeticFn, getSortDateFn } from '../../common/sorting';
import AppTable from '../../common/DataDisplay/AppTable';
import GroupDetailsButton from './Actions/GroupDetailsButton';
import UserAvatar from '../User/UserAvatar';
import { Space } from 'antd';
import useTree from './useTree';
import useGroupList from './useGroupList';
import useLdapSettings from './Ldap/useLdapSettings';
import UserDetailsButton from './Actions/UserDetailsButton';
import { getOriginText } from './UserFormat';

export default forwardRef(UsersList);

export interface UsersListApi {
    refresh: () => void;
}

export type UsersListProps = {};

function UsersList({}: UsersListProps, ref: any) {
    const { responsive } = useContext(ResponsiveContext);

    const { refresh: refreshTree } = useTree({
        name: 'tree-from-user-list',
    });
    const { refresh: refreshGroups } = useGroupList({
        name: 'tree-from-group-list',
    });
    const {
        list: users,
        loading: loadingUsers,
        refresh: refreshUsers,
        filter: filterUsers,
        page,
        pageSize,
        total,
    } = useUsersList({
        useCache: false,
    });
    const {
        record: ldapSettings,
        refresh: refreshLdapSettings,
    } = useLdapSettings();

    useImperativeHandle(
        ref,
        (): UsersListApi => ({
            async refresh() {
                await refreshUsers();
            },
        })
    );
    useEffect(() => {
        (async () => {
            await refreshUsers();
            await refreshLdapSettings();
        })();
    }, []);

    const handleActionPerformed = () => {
        refreshTree();
        refreshUsers();
        refreshGroups();
    };

    const columns = [
        {
            title: 'User',
            key: 'user_name',
            render: (record: CcUser) => (
                <UserDetailsButton user={record} onEdit={handleActionPerformed}>
                    <Space>
                        <UserAvatar size={'xsmall'} user={record} />
                        {record.userName}
                    </Space>
                </UserDetailsButton>
            ),
            sorter: true,
        },
        ...(ldapSettings?.enabled
            ? [
                  {
                      title: 'Origin',
                      key: 'origin',
                      render: (record: CcUser) => (
                          <span>{getOriginText(record)}</span>
                      ),
                      sorter: true,
                  },
              ]
            : []),
        {
            title: 'Email',
            key: 'email',
            render: (record: CcUser) => <>{record.emailAddress}</>,
            sorter: true,
        },
        {
            title: 'Team',
            key: 'group',
            render: (record: CcUser) => (
                <>
                    {(record.userGroups || []).map((group) => (
                        <GroupDetailsButton
                            key={group.groupId}
                            group={group}
                            onEdit={handleActionPerformed}
                        >
                            {group.groupName}
                        </GroupDetailsButton>
                    ))}
                </>
            ),
            sorter: true,
        },
        {
            title: 'First name',
            key: 'first_name',
            render: (record: CcUser) => <>{record.firstName}</>,
            sorter: true,
        },
        {
            title: 'Last name',
            key: 'last_name',
            render: (record: CcUser) => <>{record.lastName}</>,
            sorter: true,
        },

        {
            title: 'Status',
            key: 'status',
            width: 20,
            render: (record: CcUser) => (
                <BooleanFormat
                    booleanVar={!(record.suspended || record.disabled)}
                    contentOn="Enabled"
                    contentOff={record.suspended ? 'Suspended' : 'Disabled'}
                    statusOn="success"
                    {...(record.suspended ? { statusOff: 'error' } : {})}
                />
            ),
            sorter: true,
        },
        {
            title: 'Created',
            key: 'created',
            render: (record: CcUser) => (
                <span style={{ whiteSpace: 'nowrap' }}>
                    <AppDateFormat fromNow>
                        {record.created ? new Date(record.created) : undefined}
                    </AppDateFormat>
                </span>
            ),
            sorter: true,
        },

        {
            key: 'actions',
            title: 'Actions',
            align: 'center',
            render: (record: CcUser) => (
                <UserActionMenu
                    user={record}
                    onActionPerformed={handleActionPerformed}
                />
            ),
            width: 20,
            onCell: () => ({ style: { padding: '2px 10px' } }),
        },
    ];
    const handleTableChange = (pagination: any, filter: any, sorters: any) => {
        let sorterFn;
        switch (sorters.columnKey) {
            case 'user_name':
                sorterFn = getSortAlphabeticFn(
                    sorters.order,
                    (x) => x.userName
                );
                break;
            case 'origin':
                sorterFn = getSortAlphabeticFn(
                    sorters.order,
                    (x) => x.origin || ''
                );
                break;
            case 'email':
                sorterFn = getSortAlphabeticFn(
                    sorters.order,
                    (x) => x.emailAddress || ''
                );
                break;
            case 'group':
                sorterFn = getSortAlphabeticFn(
                    sorters.order,
                    (x) => x.getGroup().groupName
                );
                break;
            case 'first_name':
                sorterFn = getSortAlphabeticFn(
                    sorters.order,
                    (x) => x.firstName
                );
                break;
            case 'last_name':
                sorterFn = getSortAlphabeticFn(
                    sorters.order,
                    (x) => x.lastName
                );
                break;
            case 'status':
                sorterFn = getSortAlphabeticFn(sorters.order, (x) =>
                    x.suspended
                        ? 'Suspended'
                        : x.disabled
                        ? 'Disabled'
                        : 'Enabled'
                );
                break;
            case 'created':
                sorterFn = getSortDateFn(
                    sorters.order,
                    (x) => new Date(x.created)
                );
                break;
        }

        filterUsers({
            page: pagination.current,
            order: sorterFn || undefined,
        });
    };
    const pag: TablePaginationConfig = {
        pageSize,
        current: page,
        total,
    };

    const rowKey = useCallback((record: CcUser) => record.getKey(), []);
    return (
        <div className="UsersList">
            <AppTable
                className="UsersTable"
                loading={loadingUsers}
                rowKey={rowKey}
                dataSource={users}
                columns={columns}
                pagination={pag}
                size="middle"
                responsive={responsive}
                onChange={handleTableChange}
            />
        </div>
    );
}
