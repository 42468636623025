import React from 'react';
import FormItemInlineSwitch, {
    FormItemInlineSwitchProps,
} from '../../DataEntry/FormItemInlineSwitch';

export default IncludeInLoadBalancerSwitch;

export type IncludeInLoadBalancerSwitchProps = FormItemInlineSwitchProps & {};

function IncludeInLoadBalancerSwitch({
    ...rest
}: IncludeInLoadBalancerSwitchProps) {
    return (
        <div className="IncludeInLoadBalancerSwitch">
            <FormItemInlineSwitch
                justify={true}
                name="includeLB"
                label="Include in LoadBalancer set (if exists)"
                valuePropName="checked"
                {...rest}
            />
        </div>
    );
}
