import React, { useState } from 'react';
import ModalDefaultForm from '../../common/ModalDefaultForm';
import { ModalDefaultProps } from '../../common/ModalDefault';
import { useForm } from 'antd/lib/form/Form';
import { Form, Input } from 'antd';
import FormFooter from '../../common/FormFooter';
import KeyManagementTree from './KeyManagementTree';
import CmonCaService from '../../services/cmon/CmonCaService';
import {
    notifyError,
    notifyOperationSuccess,
    NotifyType,
} from '../Notifications/uiNotification';
import { useDebugContext } from '../../common/Debug';

export type KeyManagementMoveLocationModalProps = {
    list: any;
    selectedRow: any;
    onCancel?: () => void;
    onSuccess?: () => void;
} & Omit<ModalDefaultProps, 'children'>;

type KeyManagementMoveLocationType = {
    certificateName: string;
};

export default KeyManagementMoveLocationModal;

function KeyManagementMoveLocationModal({
    list,
    selectedRow,
    onSuccess,
    onCancel,
}: KeyManagementMoveLocationModalProps) {
    const [form] = useForm<KeyManagementMoveLocationType>();
    const [loading, setLoading] = useState<boolean>(false);
    const { log } = useDebugContext();

    const handleSubmit = async ({
        certificateName,
    }: KeyManagementMoveLocationType) => {
        try {
            setLoading(true);
            await CmonCaService.move({
                id: selectedRow?.id,
                name: certificateName,
            });
            setLoading(false);
            onSuccess?.();
            notifyOperationSuccess({
                type: NotifyType.TOAST,
                title: 'Success!',
                content: 'Changes are saved successfully!',
            });
        } catch (err) {
            setLoading(false);
            notifyError({ content: err.message });
            log.error(err);
        }
    };

    const handleOnMove = (node: any) => {
        const path = selectedRow?.certfile.split('/');
        form.setFieldsValue({
            certificateName: [node?.path, path[path.length - 1]].join('/'),
        });
    };

    return (
        <ModalDefaultForm
            title="Move to a new location"
            form={form}
            footer={[]}
            width={700}
            defaultVisible={true}
            onCancel={onCancel}
        >
            <Form
                form={form}
                onFinish={handleSubmit}
                layout="vertical"
                initialValues={{
                    certificateName: selectedRow?.certfile,
                }}
            >
                <Form.Item
                    name="certificateName"
                    label="Certificate name"
                    rules={[
                        {
                            required: true,
                            message: 'Please enter name of certificate',
                        },
                    ]}
                >
                    <Input placeholder="Enter name of certificate select one on the bottom" />
                </Form.Item>
                <Form.Item>
                    <KeyManagementTree
                        list={list}
                        onSelect={handleOnMove}
                        hideFiles={true}
                    />
                </Form.Item>
                <FormFooter
                    loading={loading}
                    submitButtonText="Move"
                    showCancelButton
                    showSubmitButton
                    onCancel={onCancel}
                />
            </Form>
        </ModalDefaultForm>
    );
}
