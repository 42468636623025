import React from 'react';
import { Col, Layout } from 'antd';
import AppRow from '../../common/AppRow';
import DashboardClustersCard from './DashboardClustersCard';
import DashboardNodesCard from './DashboardNodesCard';
import DasboardAlarmsCard from './DasboardAlarmsCard';

export default Dashboard;

function Dashboard() {
    return (
        <section className="Dashboard" data-testid="dashboard">
            <Layout>
                <AppRow>
                    <Col xs={24} sm={24} md={24}>
                        <AppRow>
                            <Col span={24}>{<DashboardClustersCard />}</Col>
                        </AppRow>
                        <AppRow style={{ marginTop: '20px' }} gutter={20}>
                            <Col
                                span={16}
                                style={{ display: 'flex', minHeight: '100%' }}
                            >
                                <DashboardNodesCard />
                            </Col>

                            <Col
                                span={8}
                                style={{ display: 'flex', minHeight: '100%' }}
                            >
                                <DasboardAlarmsCard />
                            </Col>
                        </AppRow>
                    </Col>
                </AppRow>
            </Layout>
        </section>
    );
}
