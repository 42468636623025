import React, { useMemo } from 'react';
import ActionsMenu from '../../common/Navigation/ActionsMenu';
import { SettingsIcon } from '../../common/icons/icons';
import CcCluster from '../../services/models/CcCluster';
import { ActionMenuItem } from '@severalnines/bar-frontend-components/build/lib/Navigation/ActionMenu';
import InstallCmonAgentsButton from './InstallCmonAgentsButton';
import UninstallAllCmonAgentsButton from './UninstallAllCmonAgentsButton';

export default CmonAgentsSettingsMenu;

export type CmonAgentsSettingsMenuProps = {
    cluster: CcCluster;
};

function CmonAgentsSettingsMenu({ cluster }: CmonAgentsSettingsMenuProps) {
    const menuItems = useMemo(() => {
        let items: React.ReactNode[] = [
            {
                key: 'install_agents',
                label: <InstallCmonAgentsButton cluster={cluster} />,
            },
            {
                key: 'uninstall_agents',
                label: <UninstallAllCmonAgentsButton cluster={cluster} />,
            },
        ];

        return items;
    }, [cluster]);
    return (
        <ActionsMenu items={menuItems as ActionMenuItem[]}>
            <SettingsIcon />
        </ActionsMenu>
    );
}
