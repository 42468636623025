import CcUser from './CcUser';
import CcGroup from './CcGroup';

export enum CcTreeItemType {
    Folder = 'Folder',
    Cluster = 'Cluster',
    Server = 'Server',
    File = 'File',
    Node = 'Node',
    Group = 'Group',
    User = 'User',
    Custom = 'Custom',
}

export enum CcTreeItemHandledAclPath {
    ACL_JOB_EXECUTOR_CREATE_CLUSTER = '/.runtime/jobs/jobExecutorCreateCluster',
    ACL_LDAP_SETTINGS = '/.runtime/LDAP/configuration',
    ACL_USER_MANAGER = '/.runtime/user_manager',
    ACL_JOB_EXECUTOR = '/.runtime/jobs/jobExecutor',
    ACL_UI_CONFIG = '/.runtime/ui/configuration',
}

export enum CcTreeItemAccess {
    READ = 'r--',
    EXECUTE = 'r-x',
    NO_ACCESS = '---',
    FULL_ACCESS = 'rwx',
}
export enum CcTreeItemAccessBlock {
    USER = 'user',
    GROUP = 'group',
    OTHER = 'other',
}

const ACCESS_LEVEL_ORDER = {
    [CcTreeItemAccess.NO_ACCESS]: 0,
    [CcTreeItemAccess.READ]: 1,
    [CcTreeItemAccess.EXECUTE]: 3,
    [CcTreeItemAccess.FULL_ACCESS]: 4,
};

export type CcTreeItemAccessMapValue = {
    [key: string]: CcTreeItemAccess;
};
export type CcTreeItemAccessMap = {
    [key in CcTreeItemAccessBlock]: CcTreeItemAccessMapValue;
};
export type CcTreeItemProps = {
    effective_privileges?: string;
    cluster_id?: number;
    item_acl?: string;
    item_name?: string;
    item_path?: string;
    item_spec?: string;
    item_type?: CcTreeItemType;
    owner_group_id: number;
    owner_group_name: string;
    owner_user_id: number;
    owner_user_name: string;
    sub_items: CcTreeItemProps[];
};

export default class CcTreeItem {
    public readonly effectivePrivileges?: CcTreeItemAccess;
    public readonly clusterId?: number;
    public readonly itemAcl?: string;
    public readonly itemName?: string;
    public readonly itemPath?: string;
    public readonly itemSpec?: string;
    public readonly itemType?: CcTreeItemType;
    public readonly ownerGroupId: number;
    public readonly ownerGroupName: string;
    public readonly ownerUserId: number;
    public readonly ownerUserName: string;
    public readonly subItems: CcTreeItem[];

    public readonly accessMap: CcTreeItemAccessMap;

    constructor(props: CcTreeItemProps) {
        this.effectivePrivileges = props.effective_privileges as CcTreeItemAccess;
        this.clusterId = props.cluster_id;
        this.itemAcl = props.item_acl;
        this.itemName = props.item_name;
        this.itemPath = props.item_path;
        this.itemSpec = props.item_spec;
        this.itemType = props.item_type;
        this.ownerGroupId = props.owner_group_id;
        this.ownerGroupName = props.owner_group_name;
        this.ownerUserId = props.owner_user_id;
        this.ownerUserName = props.owner_user_name;
        this.subItems = props.sub_items
            ? props.sub_items.map((i) => new CcTreeItem(i))
            : [];

        this.accessMap = props.item_acl
            ? props.item_acl.split(',').reduce(
                  (acc: CcTreeItemAccessMap, item: string) => {
                      const [category, name, level]: [
                          CcTreeItemAccessBlock,
                          string,
                          CcTreeItemAccess
                      ] = item.split(':') as any;
                      acc[category][name] = level;
                      return acc;
                  },
                  { group: {}, user: {}, other: {} }
              )
            : { group: {}, user: {}, other: {} };
    }

    public getKey() {
        return `${this.itemPath !== '/' ? this.itemPath : ''}/${this.itemName}`;
    }

    public getClusterKey() {
        return `${this.clusterId}`;
    }

    /**
     * This was working fine, the checking in client side, now we dont use this
     * we use backend to get the tree of particular group
     * @not-used
     */
    public getAclForGroup(groupName: string) {
        if (groupName === this.ownerGroupName) {
            return CcTreeItemAccess.FULL_ACCESS;
        }
        return (
            this.accessMap[CcTreeItemAccessBlock.GROUP]?.[groupName] ||
            this.accessMap[CcTreeItemAccessBlock.OTHER]?.['']
        );
    }

    /**
     * This was working fine, the checking in client side, now we dont use this
     * we use backend to get the tree of particular user
     * @not-used
     */
    public getAclForUser(userName: string) {
        if (userName === this.ownerUserName) {
            return CcTreeItemAccess.FULL_ACCESS;
        }
        return (
            this.accessMap[CcTreeItemAccessBlock.USER]?.[userName] ||
            this.accessMap[CcTreeItemAccessBlock.OTHER]?.['']
        );
    }

    /**
     * This check access was working fine but we prefer to check effective priviledges
     * that comes from backend.
     * @not-used
     */
    public checkAccess(user: CcUser, level: CcTreeItemAccess) {
        if (
            ACCESS_LEVEL_ORDER[this.getAclForUser(user.userName as string)] >=
            ACCESS_LEVEL_ORDER[level]
        ) {
            return true;
        }

        return (user.userGroups as CcGroup[])
            .map((group) => {
                return (
                    ACCESS_LEVEL_ORDER[this.getAclForGroup(group.groupName)] >=
                    ACCESS_LEVEL_ORDER[level]
                );
            })
            .some((value) => value);
    }

    public checkEffectiveAccess(level: CcTreeItemAccess) {
        return (
            ACCESS_LEVEL_ORDER[this.effectivePrivileges as CcTreeItemAccess] >=
            ACCESS_LEVEL_ORDER[level]
        );
    }

    public isType(type: CcTreeItemType) {
        return this.itemType === type;
    }
}

export function getLevelsBelow(level?: CcTreeItemAccess) {
    const levels = [
        CcTreeItemAccess.FULL_ACCESS,
        // CcTreeItemAccess.EXECUTE,
        CcTreeItemAccess.READ,
        CcTreeItemAccess.NO_ACCESS,
    ];
    if (!level) {
        return levels;
    }
    return levels.filter(
        (l) => ACCESS_LEVEL_ORDER[l] <= ACCESS_LEVEL_ORDER[level]
    );
}

/**
 * Given an array of different levels, it returns the less permisive one.
 */
export function getMinLevel(levels: CcTreeItemAccess[]): CcTreeItemAccess {
    return levels.reduce((min: CcTreeItemAccess, curr: CcTreeItemAccess) => {
        if (ACCESS_LEVEL_ORDER[curr] < ACCESS_LEVEL_ORDER[min]) {
            return curr;
        } else {
            return min;
        }
    }, CcTreeItemAccess.FULL_ACCESS);
}
