import React, { useContext, useEffect, useMemo } from 'react';
import './NodeList.less';
import { ArrayParam } from 'use-query-params';
import useNodeList from './useNodeList';
import CcCluster, { CcClusterType } from '../../services/models/CcCluster';
import { Col } from 'antd';
import CcNode, { CcNodeStatus } from '../../services/models/CcNode';
import AppEmpty from '../../common/Feedback/AppEmpty';
import CreateServiceButton from '../Services/CreateServiceButton';
import ResponsiveTableFooter from '@severalnines/bar-frontend-components/build/lib/DataDisplay/ResponsiveTableFooter';
import { ResponsiveContext } from '@severalnines/bar-frontend-components/build/lib/Layout/Responsive';
import NodeStatusFormat, {
    getNodeStatusesTextValue,
    getNodeStatusFormatText,
    getNodeStatusFormatType,
} from './NodeStatusFormat';
import NodeTypeFormat, {
    getNodeRoleText,
    getNodeTypeText,
} from './NodeTypeFormat';
import ClusterFormat from '../Clusters/ClusterFormat';
import {
    AppState,
    AppStateClustersMap,
    AppStateNodeTypes,
} from '../../appReducer';
import { useSelector } from 'react-redux';
import StatisticBox from '../../common/StatisticBox';
import AppRow from '../../common/AppRow';
import useNodesOverview from './useNodesOverview';
import SpaceWide from '../../common/SpaceWide';
import {
    getSortAlphabeticFn,
    getSortDateFn,
    getSortNumberFn,
} from '../../common/sorting';
import StatusFormat from '@severalnines/bar-frontend-components/build/lib/Format/StatusFormat';
import NodeActionsMenu from './Actions/NodeActionsMenu';
import { TablePaginationConfig } from 'antd/es';
import LicenseSpaceWrapper from '../License/LicenseSpaceWrapper';
import CcMongoNode from '../../services/models/CcMongoNode';
import AppDateFormat from '../../common/AppDateFormat';
import CcElasticNode from '../../services/models/CcElasticNode';
import NodeRoleFormat from './NodeRoleFormat';
import TableWithJobRow from '../../common/DataDisplay/TableWithJobRow';
import { CmonJobInstanceCommand } from '../../services/cmon/models/CmonJobInstance';
import UserAclExecuteClusters from '../User/UserAclExecuteClusters';
import useTableFilter from '../../common/hooks/useTableFilter';
import useTableFilterColumns, {
    TableFilterType,
} from '../../common/hooks/useTableFilterColumns';
import NodeFormat from './NodeFormat';
import classNames from 'classnames';
import NodeDetailsButton from './Actions/NodeDetailsButton';
import AppSpin from '../../common/General/AppSpin';
import AppDivider from '../../common/AppDivider';
import TypographyText from '../../common/TypographyText';

const JOB_COMMANDS = [
    CmonJobInstanceCommand.ADD_REPLICATION_SLAVE,
    CmonJobInstanceCommand.ADDNODE,
    CmonJobInstanceCommand.PGBACKREST,
    CmonJobInstanceCommand.PBMAGENT,
    CmonJobInstanceCommand.HAPROXY,
    CmonJobInstanceCommand.KEEPALIVED,
    CmonJobInstanceCommand.PGBOUNCER,
    CmonJobInstanceCommand.PROXYSQL,
    CmonJobInstanceCommand.MAXSCALE,
];

export default NodeList;

export type NodeListProps = { cluster?: CcCluster };

function NodeList({ cluster }: NodeListProps) {
    const { responsive } = useContext(ResponsiveContext);
    const {
        filterParams,
        handleTableChange,
        addFilterParams,
        resetFilters,
    } = useTableFilter({
        params: {
            type: ArrayParam,
            cluster: ArrayParam,
            status: ArrayParam,
        },
    });

    const clusterIdFilter = useMemo(
        () =>
            (cluster?.clusterId && `${cluster?.clusterId}`) ||
            filterParams.cluster,
        [cluster, filterParams]
    );

    const {
        list: nodes,
        loading: loadingNodes,
        filter: filterNodes,
        refresh: refreshNodes,
        page,
        pageSize,
        total,
    } = useNodeList({ name: 'node-list', pageSize: 20, useCache: false });

    const {
        loading: loadingNodesOverview,
        refresh: refreshNodesOverview,
        // filter: filterOverview,
        record: nodesOverview,
    } = useNodesOverview({ name: 'nodes-overview-dashboard' });
    const [clustersMap, nodeTypes]: [
        AppStateClustersMap,
        AppStateNodeTypes
    ] = useSelector(({ clusters, nodeTypes }: AppState) => [
        clusters,
        nodeTypes,
    ]);

    useEffect(() => {
        (async () => {
            await refreshNodesOverview(
                (cluster?.clusterId && {
                    filters: getNodeListClientFilters({
                        cluster: `${cluster?.clusterId}`,
                    }),
                }) ||
                    {}
            );
        })();
        (async () => {
            await refreshNodes({
                autoRefresh: 10000,
                filters: getNodeListClientFilters({
                    status: filterParams.status,
                    cluster: cluster?.clusterId,
                    type: filterParams.type,
                }),
            });
        })();
    }, [cluster?.clusterId]);

    useEffect(() => {
        filterNodes({
            filters: getNodeListClientFilters({
                status: filterParams.status,
                cluster: clusterIdFilter,
                type: filterParams.type,
            }),
            order: getSorterFunction(filterParams) || null,
            page: filterParams.page || 1,
        });
    }, [clusterIdFilter, filterParams]);

    const handleActionPerformed = async () => {
        await refreshNodes({
            filters: getNodeListClientFilters({
                status: filterParams.status,
                cluster: clusterIdFilter,
                type: filterParams.type,
            }),
        });
    };

    const { columns } = useTableFilterColumns({
        columns: useMemo(
            () => [
                {
                    title: 'Hostname',
                    key: 'hostname',
                    render: (record: CcNode) => record.hostname,
                    sorter: true,
                },
                {
                    title: 'Port',
                    key: 'port',
                    render: (record: CcNode) => record.port,
                    sorter: true,
                },
                ...(cluster?.isType(CcClusterType.TYPE_MONGODB_SHARDS)
                    ? [
                          {
                              title: 'ReplicaSet',
                              key: 'replicaset',
                              render: (record: CcMongoNode) => record.rs,
                              sorter: true,
                              hideWithJob: true,
                          },
                      ]
                    : []),
                {
                    title: 'Status',
                    key: 'status',
                    render: (record: CcNode) => (
                        <NodeFormat node={record}>
                            <NodeStatusFormat
                                node={record}
                                showTooltip={false}
                                showMaintenance={true}
                                showRunningJobs={true}
                            />
                        </NodeFormat>
                    ),
                    sorter: true,
                    filters: getNodeStatusesTextValue().map((i) => ({
                        ...i,
                        text: (
                            <StatusFormat
                                status={getNodeStatusFormatType(i.value)}
                                text={getNodeStatusFormatText(i.value)}
                            />
                        ),
                    })),
                    progress: true,
                },
                {
                    title: 'Type',
                    key: 'type',
                    render: (record: CcNode) =>
                        record.isPerformanceHaNode() ? (
                            <NodeDetailsButton
                                node={record}
                                cluster={
                                    cluster ||
                                    clustersMap.get(record.getClusterKey())!
                                }
                                type="link"
                                style={{ padding: 0 }}
                            >
                                <NodeTypeFormat
                                    type={record.nodetype}
                                    vendor={record.vendor}
                                />
                            </NodeDetailsButton>
                        ) : (
                            <NodeTypeFormat
                                type={record.nodetype}
                                vendor={record.vendor}
                            />
                        ),
                    sorter: true,
                    filterType: TableFilterType.NODE_TYPE,
                    hideWithJob: true,
                },
                {
                    title: 'Role',
                    key: 'role',
                    render: (record: CcNode & CcElasticNode) => (
                        <NodeRoleFormat node={record} />
                    ),
                    sorter: true,
                    hideWithJob: true,
                },
                {
                    title: 'Version',
                    key: 'version',
                    sorter: true,
                    render: (record: CcNode) => (
                        <TypographyText
                            style={{ maxWidth: 200 }}
                            ellipsis={{
                                tooltip: record.version,
                            }}
                        >
                            {record.version}
                        </TypographyText>
                    ),
                },
                ...(!cluster
                    ? [
                          {
                              title: 'Cluster',
                              key: 'cluster',
                              render: (record: CcNode) => (
                                  <ClusterFormat
                                      clusterLink={true}
                                      linkDestination={'nodes'}
                                      cluster={clustersMap.get(
                                          record.getClusterKey()
                                      )}
                                      showPopover={true}
                                      popoverPlacement="left"
                                  />
                              ),
                              sorter: true,
                              filterType: TableFilterType.CLUSTER,
                              hideWithJob: true,
                          },
                      ]
                    : []),
                {
                    title: 'Last seen',
                    key: 'lastseen',
                    render: (record: CcNode) => (
                        <AppDateFormat fromNow>
                            {record.lastseen
                                ? new Date(record.lastseen * 1000)
                                : undefined}
                        </AppDateFormat>
                    ),
                    sorter: true,
                    hideWithJob: true,
                },
                {
                    key: 'actions',
                    title: 'Actions',
                    align: 'center',
                    render: (record: CcNode) => {
                        const cluster = clustersMap.get(record.getClusterKey());
                        return (
                            cluster && (
                                <NodeActionsMenu
                                    node={record}
                                    cluster={cluster}
                                    onActionPerformed={handleActionPerformed}
                                />
                            )
                        );
                    },
                    actions: true,
                    onCell: () => ({
                        style: {
                            padding: '2px 10px',
                        },
                    }),
                },
            ],
            [filterParams, clustersMap]
        ),
        filterParams,
        state: {
            clusters: clustersMap,
            nodeTypes,
        },
    });

    const getSorterFunction = ({
        sort,
        order,
    }: {
        sort: string;
        order: string;
    }) => {
        switch (sort) {
            case 'hostname':
                return getSortAlphabeticFn(order, (x) => x.hostname);
            case 'port':
                return getSortNumberFn(order, (x) => x.port);
            case 'replicaset':
                // only for mongo
                return getSortAlphabeticFn(order, (x) => x.rs || '');
            case 'status':
                return getSortAlphabeticFn(order, (x) =>
                    getNodeStatusFormatText(x.hoststatus)
                );
            case 'type':
                return getSortAlphabeticFn(order, (x) =>
                    getNodeTypeText(x.nodetype)
                );
            case 'role':
                return getSortAlphabeticFn(order, (x) =>
                    getNodeRoleText(x.getRole())
                );
            case 'version':
                return getSortAlphabeticFn(order, (x) => x.version || '');
            case 'lastseen':
                return getSortDateFn(order, (x) => new Date(x.lastseen * 1000));
            case 'cluster':
                return getSortAlphabeticFn(
                    order,
                    (x) => clustersMap.get(x.getClusterKey())?.clusterName || ''
                );
        }
        return undefined;
    };
    const pagination: TablePaginationConfig = {
        size: 'default',
        pageSize,
        current: page,
        total,
        hideOnSinglePage: true,
        showQuickJumper: false,
        showSizeChanger: false,
        position: ['bottomCenter'],
    };
    return (
        <LicenseSpaceWrapper>
            <SpaceWide direction="vertical" size={25}>
                <AppSpin spinning={loadingNodesOverview}>
                    <AppDivider invisible={true} />
                    <AppRow gutter={0} className="NodeList_statistics">
                        {[
                            [CcNodeStatus.CmonHostOnline],
                            [CcNodeStatus.CmonHostFailed],
                            [CcNodeStatus.CmonHostOffLine],
                            [CcNodeStatus.CmonHostShutDown],
                            [CcNodeStatus.CmonHostRecovery],
                            [CcNodeStatus.CmonHostUnknown],
                        ].map(([s]: any) => (
                            <Col
                                key={s}
                                onClick={() =>
                                    addFilterParams({
                                        status: [s],
                                    })
                                }
                                className={classNames(
                                    'NodeList_statistics-item',
                                    {
                                        'NodeList_statistics-item--active': filterParams.status?.includes(
                                            s
                                        ),
                                    }
                                )}
                            >
                                <StatisticBox
                                    title={getNodeStatusFormatText(s)}
                                    value={nodesOverview?.getStatsCount(s) || 0}
                                    conditional={[
                                        0,
                                        'none',
                                        getNodeStatusFormatType(s),
                                    ]}
                                />
                            </Col>
                        ))}
                        <Col
                            onClick={resetFilters}
                            className={classNames('NodeList_statistics-item', {
                                'NodeList_statistics-item--active':
                                    filterParams.status === undefined,
                            })}
                        >
                            <StatisticBox
                                title="All"
                                value={nodesOverview?.getStatsCount() || 0}
                                type="none"
                            />
                        </Col>
                    </AppRow>
                </AppSpin>
                <TableWithJobRow
                    filterJobs={(job) => {
                        // find another way to make this better
                        return (
                            job.spec.job_data?.action !== 'synchronize' &&
                            job.spec.job_data?.action !== 'restore_backup' &&
                            job.spec.job_data?.action !== 'backup'
                        );
                    }}
                    command={JOB_COMMANDS}
                    clusterId={cluster?.clusterId}
                    jobRecord={(job) => {
                        return new CcNode({
                            deployment_job_id: job.jobId,
                            ...(job.spec.job_data.node ||
                                job.spec.job_data.nodes?.[0] || {
                                    hostname: 'unknown',
                                    port: 0,
                                }),
                        });
                    }}
                    loading={loadingNodes}
                    rowKey={(record: CcNode) => record.getKey(true)}
                    dataSource={nodes}
                    columns={columns}
                    pagination={pagination}
                    size="middle"
                    onChange={handleTableChange}
                    responsive={responsive}
                    onRow={(record: CcCluster, index: number) => ({
                        'data-testid': `cluster-list-row-${index}`,
                    })}
                    renderEmpty={
                        filterParams ? null : (
                            <AppEmpty
                                loading={loadingNodes}
                                description="You haven’t created any clusters. When you do, it'll show up here."
                                extra={
                                    <UserAclExecuteClusters>
                                        <CreateServiceButton type="primary" />
                                    </UserAclExecuteClusters>
                                }
                            />
                        )
                    }
                    locale={
                        filterParams
                            ? { emptyText: 'There are no matches' }
                            : {}
                    }
                    footer={() => (
                        <ResponsiveTableFooter
                            pagination={pagination}
                            currentPageLength={nodes && nodes.length}
                        />
                    )}
                />
            </SpaceWide>
        </LicenseSpaceWrapper>
    );
}

export function getNodeListClientFilters(filterMap: any) {
    let filters = [];
    if (filterMap['status']) {
        filters.push(
            (n: CcNode) =>
                n.hoststatus === filterMap['status'] ||
                (Array.isArray(filterMap['status']) &&
                    filterMap['status'].includes(n.hoststatus))
        );
    }
    if (filterMap['cluster']) {
        filters.push(
            (n: CcNode) =>
                `${n.clusterid}` === filterMap['cluster'] ||
                (Array.isArray(filterMap['cluster']) &&
                    filterMap['cluster'].includes(`${n.clusterid}`))
        );
    }
    if (filterMap['type']) {
        filters.push(
            (n: CcNode) =>
                `${n.nodetype}` === filterMap['type'] ||
                (Array.isArray(filterMap['type']) &&
                    filterMap['type'].includes(`${n.nodetype}`))
        );
    }

    return filters;
}
