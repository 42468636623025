


export interface CmonProxySqlTopQueryProps  {
    class_name?: string;
    count_star?: number;
    digest?: string;
    digest_text?: string;
    first_seen?: number;
    host_port?: string;
    hostgroup?: number;
    last_seen?: number;
    max_time?: number;
    min_time?: number;
    schemaname?: string;
    sum_time?: number;
    username?: string;

}



export default class CmonProxySqlTopQuery {
    public className?: string;
    public countStar?: number;
    public digest?: string;
    public digestText?: string;
    public firstSeen?: number;
    public hostPort?: string;
    public hostgroup?: number;
    public lastSeen?: number;
    public maxTime?: number;
    public minTime?: number;
    public schemaname?: string;
    public sumTime?: number;
    public username?: string;


    constructor(props: CmonProxySqlTopQueryProps) {
        this.className = props.class_name;
        this.countStar = props.count_star;
        this.digest = props.digest;
        this.digestText = props.digest_text;
        this.firstSeen = props.first_seen;
        this.hostPort = props.host_port;
        this.hostgroup = props.hostgroup;
        this.lastSeen = props.last_seen;
        this.maxTime = props.max_time;
        this.minTime = props.min_time;
        this.schemaname = props.schemaname;
        this.sumTime = props.sum_time;
        this.username = props.username;

    }

}
