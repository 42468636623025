import React from 'react';
import { Button } from 'antd';
import { ButtonProps } from 'antd/es';
import './AppActionButton.less';
import classNames from 'classnames';

export default AppActionButton;

export type AppActionButtonProps = ButtonProps & {};

function AppActionButton({ className, ...rest }: AppActionButtonProps) {
    return (
        <Button
            className={classNames('AppActionButton', className)}
            size="small"
            type="link"
            {...rest}
        />
    );
}
