import React from 'react';
import { ButtonProps } from 'antd/lib/button';

import { CmonJobInstanceCommand } from '../../../services/cmon/models/CmonJobInstance';
import CcNode from '../../../services/models/CcNode';
import ButtonWithForm from '../../../common/General/ButtonWithForm';
import CcCluster from '../../../services/models/CcCluster';
import JobActionButton from '../../Jobs/JobActionButton';
import NodeRebuildReplicationSlaveForm from './NodeRebuildReplicationSlaveForm';

export default NodeRebuildReplicationSlaveButton;

export type NodeRebuildReplicationSlaveButtonProps = ButtonProps & {
    node: CcNode;
    cluster: CcCluster;
    children?: React.ReactNode;
    onSuccess?: () => void;
};

function NodeRebuildReplicationSlaveButton({
    node,
    cluster,
    children,
    onSuccess,
    ...rest
}: NodeRebuildReplicationSlaveButtonProps) {
    return (
        <ButtonWithForm
            button={
                <JobActionButton
                    command={CmonJobInstanceCommand.STAGE_REPLICATION_SLAVE}
                    clusterId={node.clusterid}
                    extraKey={[node.hostname, node.port]}
                    {...rest}
                >
                    {children || 'Rebuild replica'}
                </JobActionButton>
            }
            form={
                <NodeRebuildReplicationSlaveForm
                    node={node}
                    cluster={cluster}
                    onSuccess={onSuccess}
                />
            }
        />
    );
}
