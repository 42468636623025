import React, { useEffect, useMemo, useState } from 'react';
import { Form } from 'antd';
import WizardForm from '@severalnines/bar-frontend-components/build/lib/Navigation/WizardForm';
import WizardFormConfiguration from '@severalnines/bar-frontend-components/build/lib/Navigation/Wizard/WizardFormConfiguration';
import CcCluster from '../../../../services/models/CcCluster';

import useCreateJob from '../../../Jobs/useCreateJob';
import { CmonJobInstanceCommand } from '../../../../services/cmon/models/CmonJobInstance';

import CreateProxySQLWhere, {
    getCreateProxySQLWhereValidation,
    proxySQLWhereInitialValues,
} from './CreateProxySQLWizradFromSteps/CreateProxySQLWhere';

import CreateProxySQLConfiguration, {
    PROXYSQL_CONFIGURATION_INITIAL,
    PROXYSQL_CONFIGURATION_VALIDATION,
} from './CreateProxySQLWizradFromSteps/CreateProxySQLConfiguration';

import CreateProxySQLServerInstances, {
    proxySQLServerInstancesValues,
} from './CreateProxySQLWizradFromSteps/CreateProxySQLServerInstances';
import CreateProxySQLPreview from './CreateProxySQLWizradFromSteps/CreateProxySQLPreview';
import { formatFields } from './createProxySQLWizardHelper';

export enum CreateProxySQFormSteps {
    WHERE_TO_INS = 'WhereToInstall',
    CONFIGURATION = 'Configuration',
    SERVER_INS = 'ServerInstances',
    PREVIEW = 'preview',
}

export type CreateProxySQLWizardFromProps = {
    onCancel?: () => void;
    onSuccess?: () => void;
    onError?: (err: Error) => void;
    onStepErrorInsist?: (err: Error) => void;
    onTouchedChange?: (touched: boolean) => void;
    cluster?: CcCluster;
};

export default CreateProxySQLWizardFrom;

function CreateProxySQLWizardFrom({
    onCancel,
    onSuccess,
    onStepErrorInsist,
    onTouchedChange,
    onError,
    cluster,
}: CreateProxySQLWizardFromProps) {
    const [form] = Form.useForm();
    const [isImportConfig, setIsImportConfig] = useState<boolean>(false);
    const [isDbUsernameTouched, setIsDbUsernameTouched] =
        useState<boolean>(false);

    const { loading, send } = useCreateJob({
        clusterId: cluster?.clusterId,
        title: 'Install ProxySQL',
        command:
            CmonJobInstanceCommand.PROXYSQL.toLowerCase() as CmonJobInstanceCommand,
        onSuccess,
    });

    const handleImportConfig = (importConfig: boolean) => {
        setIsImportConfig(importConfig);
    };
    const checkDbUsernameTouched = (touched: boolean) => {
        setIsDbUsernameTouched(touched);
    };

    useEffect(() => {
        proxySQLServerInstancesValues(form, cluster);
    }, []);

    const handleSubmit = async () => {
        try {
            const { proxySQLWhere } = form.getFieldsValue(true);
            const [node] = proxySQLWhere.address;
            await send(
                {
                    clusterId: cluster?.clusterId,
                    ...formatFields(form),
                },
                {
                    job: {
                        title: `Installing ProxySql ${node?.extraData.hostname}`,
                    },
                }
            );
        } catch (err: any) {
            if (onError) {
                onError(err);
            }
        }
    };

    const steps = useMemo(
        () =>
            isImportConfig
                ? [
                      <WizardForm.Step
                          key={CreateProxySQFormSteps.WHERE_TO_INS}
                          title="Where to install"
                          subTitle=" "
                          validate={getCreateProxySQLWhereValidation(form)}
                      >
                          <CreateProxySQLWhere
                              selectedCluster={cluster}
                              isImportConfig={isImportConfig}
                              form={form}
                          />
                      </WizardForm.Step>,
                      <WizardForm.Step
                          key={CreateProxySQFormSteps.PREVIEW}
                          title="Preview"
                          subTitle=" "
                      >
                          <CreateProxySQLPreview
                              form={form}
                              isImportConfig={isImportConfig}
                          />
                      </WizardForm.Step>,
                  ]
                : [
                      <WizardForm.Step
                          key={CreateProxySQFormSteps.WHERE_TO_INS}
                          title="Where to install"
                          subTitle=" "
                          validate={getCreateProxySQLWhereValidation(form)}
                      >
                          <CreateProxySQLWhere
                              selectedCluster={cluster}
                              form={form}
                          />
                      </WizardForm.Step>,
                      <WizardForm.Step
                          key={CreateProxySQFormSteps.CONFIGURATION}
                          title="Configuration"
                          subTitle=" "
                          validate={[
                              ...PROXYSQL_CONFIGURATION_VALIDATION,
                              isDbUsernameTouched && [
                                  'proxySQLConfiguration',
                                  'sqlPrivileges',
                              ],
                              isDbUsernameTouched && [
                                  'proxySQLConfiguration',
                                  'dbPassword',
                              ],
                              isDbUsernameTouched && [
                                  'proxySQLConfiguration',
                                  'dbUsername',
                              ],
                          ]}
                      >
                          <CreateProxySQLConfiguration
                              checkDbUsernameTouched={checkDbUsernameTouched}
                              selectedCluster={cluster}
                              form={form}
                          />
                      </WizardForm.Step>,
                      <WizardForm.Step
                          key={CreateProxySQFormSteps.SERVER_INS}
                          title="Server instances"
                          subTitle=" "
                      >
                          <CreateProxySQLServerInstances
                              form={form}
                              selectedCluster={cluster}
                          />
                      </WizardForm.Step>,

                      <WizardForm.Step
                          key={CreateProxySQFormSteps.PREVIEW}
                          title="Preview"
                          subTitle=" "
                      >
                          <CreateProxySQLPreview
                              form={form}
                              isImportConfig={isImportConfig}
                          />
                      </WizardForm.Step>,
                  ],
        [isImportConfig, isDbUsernameTouched]
    );

    return (
        <WizardFormConfiguration
            form={form}
            onValuesChange={(value) => {
                if (value?.proxySQLWhere?.importConfig !== undefined) {
                    handleImportConfig(value?.proxySQLWhere?.importConfig);
                }
            }}
            onSubmit={handleSubmit}
            loading={loading}
            onCancel={onCancel}
            initialValues={{
                ...proxySQLWhereInitialValues(cluster),
                ...PROXYSQL_CONFIGURATION_INITIAL,
            }}
            onStepErrorInsist={onStepErrorInsist}
            onTouchedChange={onTouchedChange}
            steps={steps}
        />
    );
}
