import './Tabs.less';
import React, { useState } from 'react';
import { Tabs as AntTabs, TabsProps, TabPaneProps } from 'antd';
import classNames from 'classnames';

export default Tabs;

export type AppTabsProps = TabsProps & {
    forceReloadOnTabChange?: boolean;
    tall?: boolean;
};

function Tabs({
    forceReloadOnTabChange,
    tall,
    onChange,
    ...rest
}: AppTabsProps) {
    const [key, setKey] = useState<string>('');
    const handleTabChange = (...args: [activeKey: string]) => {
        if (forceReloadOnTabChange) {
            setKey(`${new Date().getTime()}`);
        }

        if (onChange) {
            onChange(...args);
        }
    };
    return (
        <AntTabs
            className={classNames('AppTabs', {
                'AppTabs--tall': tall,
            })}
            onChange={handleTabChange}
            key={key}
            {...rest}
        />
    );
}

export type AppTabsPaneProps = TabPaneProps & {};
Tabs.TabPane = ({ ...rest }: AppTabsPaneProps) => {
    return <AntTabs.TabPane {...rest} />;
};
