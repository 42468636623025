import './SegmentedData.less';
import React, { HTMLAttributes } from 'react';

export default SegmentedData;
export type SegmentedDataProps = HTMLAttributes<HTMLDivElement> & {
    children: React.ReactNode;
    extraBefore?: React.ReactNode;
    extraAfter?: React.ReactNode;
};

function SegmentedData({
    children,
    extraBefore,
    extraAfter,
    ...rest
}: SegmentedDataProps) {
    const items = children
        ? Array.isArray(children)
            ? children
            : [children]
        : [];
    return (
        <div className="SegmentedData" {...rest}>
            {extraBefore && (
                <div className="SegmentedData_extra SegmentedData_item-wrap">
                    {extraBefore}
                </div>
            )}
            {items.map((item, index) => {
                return (
                    <div
                        className="SegmentedData_item-wrap SegmentedData_item-data-wrap"
                        key={index}
                    >
                        {item}
                    </div>
                );
            })}
            {extraAfter && (
                <div className="SegmentedData_extra SegmentedData_item-wrap">
                    {extraAfter}
                </div>
            )}
        </div>
    );
}
