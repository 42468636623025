import CcNode, { CcNodeType } from '../../services/models/CcNode';
import { Tag } from 'antd';

export function getReadonlyTag(node: CcNode) {
    switch (node.nodetype) {
        case CcNodeType.GALERA:
            return node.readonly ? <Tag>Readonly</Tag> : undefined;
        case CcNodeType.MYSQL:
            return node.readonly ? (
                <Tag>Readonly</Tag>
            ) : (
                <Tag color="green">Writable</Tag>
            );
        default:
            return undefined;
    }
}
