import React, { useEffect, useState } from 'react';
import { Alert, Col, Form, Input, InputNumber, Row, Space } from 'antd';
import InfoIcon from '@severalnines/bar-frontend-components/build/lib/General/InfoIcon';
import CcCluster from '../../services/models/CcCluster';
import CcNode, { CcNodeType } from '../../services/models/CcNode';
import { FormInstance } from 'antd/lib/form';
import PrometheusSelectHost from './PrometheusSelectHost';

export default PrometheusSettingsForm;

export type PrometheusSettingsFormValues = {
    host: string;
    scrapeInterval: number;
    dataRetention: number;
    dataRetentionSize: number;
    dataDirectory: string;
};
export type PrometheusSettingsFormProps = {
    cluster: CcCluster;
    form: FormInstance;
    hideHost?: boolean;
};

function PrometheusSettingsForm({
    cluster,
    form,
    hideHost = false,
}: PrometheusSettingsFormProps) {
    const [, setHosts] = useState<CcNode[]>();
    useEffect(() => {
        const currentHosts = cluster
            .getHosts()
            .map(({ nodes }) => nodes?.[0])
            .filter((node) => node?.isType(CcNodeType.CONTROLLER));
        setHosts(currentHosts);
        if (!form.getFieldValue('host')) {
            form.setFieldsValue({
                host: currentHosts[0]?.hostname || undefined,
            });
        }
    }, [cluster]);
    return (
        <div className="PrometheusSettingsForm">
            {hideHost ? null : (
                <>
                    <h3>Host</h3>
                    <Space direction="vertical" size={20}>
                        <Alert
                            message={
                                <span>
                                    Select a host to install the Prometheus
                                    server (port 9090 will be exposed by its
                                    basic web client). You can re-use an
                                    existing Prometheus server for more than one
                                    cluster!
                                </span>
                            }
                            type="info"
                        />
                        <Row gutter={[24, 0]}>
                            <Col span={24}>
                                <Form.Item
                                    name="host"
                                    label={<Space>Select host</Space>}
                                >
                                    <PrometheusSelectHost cluster={cluster} />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Space>
                </>
            )}

            <h3>Settings</h3>

            <Row gutter={[24, 0]}>
                <Col span={12}>
                    <Form.Item
                        name="scrapeInterval"
                        label={
                            <Space>
                                Scrape Interval
                                <InfoIcon
                                    info={
                                        <span>
                                            Set how frequently the nodes are
                                            scraped for metrics.
                                        </span>
                                    }
                                />
                            </Space>
                        }
                    >
                        <InputNumber min={1} addonBefore="seconds" />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        name="dataRetention"
                        label={
                            <Space>
                                Data Retention
                                <InfoIcon
                                    info={
                                        <span>
                                            Set how long the metrics are kept
                                            before being removed.
                                        </span>
                                    }
                                />
                            </Space>
                        }
                    >
                        <InputNumber min={1} addonBefore="days" />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        name="dataRetentionSize"
                        label={
                            <Space>
                                Data Retention Size
                                <InfoIcon
                                    info={
                                        <span>
                                            The maximum number of bytes of
                                            storage blocks to retain. The oldest
                                            data will be removed first. Defaults
                                            to 0 or disabled..
                                        </span>
                                    }
                                />
                            </Space>
                        }
                    >
                        <InputNumber min={0} addonBefore="MB" />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        name="dataDirectory"
                        label="Data Directory"
                        rules={[
                            {
                                pattern: /(\/[^/ $€´`‘]*)+?/,
                                message: 'Please enter valid directory',
                            },
                        ]}
                    >
                        <Input placeholder="Enter Data Directory."></Input>
                    </Form.Item>
                </Col>
            </Row>
        </div>
    );
}
