const dashboard = {
    title: 'Galera Server Charts',
    meta: {
        slug: 'galera-server-charts',
        supportClusterTypes: ['galera'],
    },
    panels: [
        {
            title: 'Cluster status',
            type: 'singlestat',
            gridPos: {
                h: 4,
                w: 4,
                x: 0,
                y: 0,
            },
            options: {
                decimals: 0,
                format: 'none',
                type: 'line',
                legend: {},
                chart: {
                    full: false,
                    show: false,
                },
                valueMaps: [
                    {
                        op: '=',
                        value: 'Primary',
                    },
                    {
                        op: '=',
                        value: 'Disconnected',
                        color: '#DE3C4B',
                    },
                ],
            },
            datasource: 'clustercontrol',
            targets: [
                {
                    expr:
                        "node:$[hostname='$instance' and nodetype='galera'].{'galeraStatus': galera.galerastatus}",
                    valueFormat: '{{ galeraStatus }}',
                },
            ],
        },
        {
            title: 'Status',
            type: 'singlestat',
            gridPos: {
                h: 4,
                w: 4,
                x: 0,
                y: 0,
            },
            options: {
                decimals: 0,
                format: 'none',
                type: 'line',
                legend: {},
                chart: {
                    full: false,
                    show: false,
                },
                valueMaps: [
                    {
                        op: '=',
                        value: 'Synced',
                        status: 'success',
                    },
                ],
            },
            datasource: 'clustercontrol',
            targets: [
                {
                    expr:
                        "node:$[hostname='$instance' and nodetype='galera'].{'galeraLocalStatusStr': galera.localstatusstr}",
                    valueFormat: '{{ galeraLocalStatusStr }}',
                },
            ],
        },
        {
            title: 'WSREP ready',
            type: 'singlestat',
            gridPos: {
                h: 4,
                w: 4,
                x: 0,
                y: 0,
            },
            options: {
                decimals: 0,
                format: 'none',
                type: 'line',
                legend: {},
                chart: {
                    full: false,
                    show: false,
                },
                valueMaps: [
                    {
                        op: '=',
                        value: 'ON',
                        text: 'On',
                    },
                    {
                        op: '=',
                        value: 'OFF',
                        text: 'Off',
                        status: 'error',
                    },
                ],
            },
            datasource: 'clustercontrol',
            targets: [
                {
                    expr:
                        "node:$[hostname='$instance' and nodetype='galera'].{'galeraReady': galera.ready}",
                    valueFormat: '{{ galeraReady }}',
                },
            ],
        },
        {
            title: 'WSREP connected',
            type: 'singlestat',
            gridPos: {
                h: 4,
                w: 4,
                x: 0,
                y: 0,
            },
            options: {
                decimals: 0,
                format: 'none',
                type: 'line',
                legend: {},
                chart: {
                    full: false,
                    show: false,
                },
                valueMaps: [
                    {
                        op: '=',
                        text: 'Yes',
                        value: 'true',
                    },
                    {
                        op: '=',
                        text: 'No',
                        value: 'false',
                        color: '#DE3C4B',
                    },
                ],
            },
            datasource: 'clustercontrol',
            targets: [
                {
                    expr:
                        "node:$[hostname='$instance' and nodetype='galera'].{'galeraConnected': galera.connected}",
                    valueFormat: '{{ galeraConnected }}',
                },
            ],
        },
        {
            title: 'Cluster size',
            type: 'singlestat',
            gridPos: {
                h: 4,
                w: 4,
                x: 0,
                y: 0,
            },
            options: {
                decimals: 0,
                format: 'none',
                type: 'line',
                legend: {},
                chart: {
                    full: false,
                    show: false,
                },
                valueMaps: [
                    {
                        op: '>',
                        value: '0',
                    },
                    {
                        op: '=',
                        text: '0',
                        value: '0',
                        color: '#DE3C4B',
                    },
                ],
            },
            datasource: 'clustercontrol',
            targets: [
                {
                    expr:
                        "node:$[hostname='$instance' and nodetype='galera'].{'galeraClusterSize': galera.clustersize}",
                    valueFormat: '{{ galeraClusterSize }}',
                },
            ],
        },
        {
            title: 'Segment ID',
            type: 'singlestat',
            gridPos: {
                h: 4,
                w: 4,
                x: 0,
                y: 0,
            },
            options: {
                format: 'none',
            },
            datasource: 'clustercontrol',
            targets: [
                {
                    expr:
                        "node:$[hostname='$instance' and nodetype='galera'].{'galeraSegmentId': galera.segmentid}",
                    valueFormat: '{{ galeraSegmentId }}',
                },
            ],
        },
        {
            title: 'Desync mode',
            type: 'singlestat',
            gridPos: {
                h: 4,
                w: 4,
                x: 0,
                y: 0,
            },
            options: {
                decimals: 0,
                format: 'none',
                type: 'line',
                legend: {},
                chart: {
                    full: false,
                    show: false,
                },
                valueMaps: [
                    {
                        op: '=',
                        text: 'On',
                        value: '1',
                    },
                    {
                        op: '=',
                        text: 'Off',
                        value: '0',
                    },
                ],
            },
            targets: [
                {
                    expr:
                        'mysql_global_variables_wsrep_desync{instance=~"$instance"}',
                    legendFormat: '',
                },
            ],
        },
        {
            title: 'SSL link encryption',
            type: 'singlestat',
            gridPos: {
                h: 4,
                w: 4,
                x: 0,
                y: 0,
            },
            options: {
                decimals: 0,
                format: 'none',
                type: 'line',
                legend: {},
                chart: {
                    full: false,
                    show: false,
                },
                valueMaps: [
                    {
                        op: '=',
                        text: 'On',
                        value: 'true',
                    },
                    {
                        op: '=',
                        text: 'Off',
                        value: 'false',
                    },
                ],
            },
            datasource: 'clustercontrol',
            targets: [
                {
                    expr:
                        "node:$[hostname='$instance' and nodetype='galera'].{'galeraSocketSsl': galera.socketssl}",
                    valueFormat: '{{ galeraSocketSsl }}',
                },
            ],
        },
        {
            title: 'GCache size',
            type: 'singlestat',
            gridPos: {
                h: 4,
                w: 4,
                x: 0,
                y: 0,
            },
            options: {
                decimals: 0,
                format: 'size',
                type: 'line',
                legend: {},
                chart: {
                    full: false,
                    show: false,
                },
            },
            targets: [
                {
                    expr:
                        'mysql_galera_gcache_size_bytes{instance=~"$instance"}',
                    legendFormat: '',
                },
            ],
        },
        {
            title: 'Server version',
            type: 'singlestat',
            gridPos: {
                h: 4,
                w: 12,
                x: 0,
                y: 0,
            },
            options: {
                format: 'none',
            },
            targets: [
                {
                    expr: 'mysql_version_info{instance=~"$instance"}',
                    legendFormat: '{{version}}',
                    legendAsValue: true,
                },
            ],
        },
        {
            title: 'WSREP cluster name',
            type: 'singlestat',
            gridPos: {
                h: 4,
                w: 18,
                x: 0,
                y: 0,
            },
            options: {},
            datasource: 'clustercontrol',
            targets: [
                {
                    expr:
                        "node:$[hostname='$instance' and nodetype='galera'].{'galeraClusterName': galera.clustername}",
                    valueFormat: '{{ galeraClusterName }}',
                },
            ],
        },
        {
            title: 'WSREP SST method',
            type: 'singlestat',
            gridPos: {
                h: 4,
                w: 6,
                x: 0,
                y: 0,
            },
            options: {},
            datasource: 'clustercontrol',
            targets: [
                {
                    expr:
                        "node:$[hostname='$instance' and nodetype='galera'].{'galeraSstMethod': galera.sstMethod}",
                    valueFormat: '{{ galeraSstMethod }}',
                },
            ],
        },
        {
            title: 'WSREP cluster address',
            type: 'singlestat',
            gridPos: {
                h: 4,
                w: 18,
                x: 0,
                y: 0,
            },
            options: {},
            datasource: 'clustercontrol',
            targets: [
                {
                    expr:
                        "node:$[hostname='$instance' and nodetype='galera'].{'galeraClusterAddress': galera.clusterAddress}",
                    valueFormat: '{{ galeraClusterAddress }}',
                },
            ],
        },
        {
            title: 'WSREP slave threads',
            type: 'singlestat',
            gridPos: {
                h: 4,
                w: 6,
                x: 0,
                y: 0,
            },
            options: {
                decimals: 0,
                format: 'none',
                type: 'column',
                legend: {
                    alignAsTable: false,
                },
            },
            targets: [
                {
                    expr:
                        'mysql_global_variables_wsrep_slave_threads{instance=~"$instance"}',
                },
            ],
        },
        {
            title: 'Last committed and cached downto',
            type: 'chart',
            gridPos: {
                h: 7,
                w: 24,
                x: 0,
                y: 0,
            },
            options: {
                decimals: 2,
                format: 'short',
                type: 'line',
                legend: {
                    alignAsTable: true,
                },
                yaxis: [
                    {
                        format: 'short',
                        max: null,
                        min: 0,
                    },
                ],
                stack: false,
            },
            targets: [
                {
                    expr:
                        'mysql_global_status_wsrep_last_committed{instance=~"$instance"}',
                    legendFormat: 'wsrep_last_committed',
                },
                {
                    expr:
                        'mysql_global_status_wsrep_local_cached_downto{instance=~"$instance"}',
                    legendFormat: 'wsrep_local_cached_downto',
                },
            ],
        },
        {
            title: 'WSREP send queue',
            type: 'chart',
            gridPos: {
                h: 7,
                w: 24,
                x: 0,
                y: 0,
            },
            options: {
                decimals: 2,
                format: 'short',
                type: 'line',
                legend: {
                    alignAsTable: true,
                },
                yaxis: [
                    {
                        format: 'short',
                        max: null,
                        min: 0,
                    },
                ],
                stack: false,
            },
            targets: [
                {
                    expr:
                        'mysql_global_status_wsrep_local_send_queue{instance=~"$instance"}',
                    legendFormat: 'WSREP Send Queue',
                },
                {
                    expr:
                        'mysql_global_status_wsrep_local_send_queue_avg{instance=~"$instance"}',
                    legendFormat: 'WSREP Send Queue (avg)',
                },
                {
                    expr:
                        'mysql_global_status_wsrep_local_send_queue_min{instance=~"$instance"}',
                    legendFormat: 'WSREP Send Queue (min)',
                },
                {
                    expr:
                        'mysql_global_status_wsrep_local_send_queue_max{instance=~"$instance"}',
                    legendFormat: 'WSREP Send Queue (max)',
                },
            ],
        },
        {
            title: 'WSREP recv queue',
            type: 'chart',
            gridPos: {
                h: 7,
                w: 24,
                x: 0,
                y: 0,
            },
            options: {
                decimals: 2,
                format: 'short',
                type: 'line',
                legend: {
                    alignAsTable: true,
                },
                yaxis: [
                    {
                        format: 'short',
                        max: null,
                        min: 0,
                    },
                ],
                stack: false,
            },
            targets: [
                {
                    expr:
                        'mysql_global_status_wsrep_local_recv_queue{instance=~"$instance"}',
                    legendFormat: 'WSREP Recv Queue',
                },
                {
                    expr:
                        'mysql_global_status_wsrep_local_recv_queue_avg{instance=~"$instance"}',
                    legendFormat: 'WSREP Recv Queue (avg)',
                },
                {
                    expr:
                        'mysql_global_status_wsrep_local_recv_queue_min{instance=~"$instance"}',
                    legendFormat: 'WSREP Recv Queue (min)',
                },
                {
                    expr:
                        'mysql_global_status_wsrep_local_recv_queue_max{instance=~"$instance"}',
                    legendFormat: 'WSREP Recv Queue (max)',
                },
            ],
        },
    ],
    templating: {
        list: [
            {
                datasource: 'clustercontrol',
                multi: false,
                label: 'Host',
                name: 'instance',
                query: "host:$[nodes[nodetype='galera']]",
                optionValue: 'hostname',
                optionText: 'hostname',
                sort: 1,
                isInstancePicker: true,
            },
        ],
    },
    schemaVersion: 1,
};

export default dashboard;
