import React from 'react';
import FormItemInlineSwitch from '../../../../common/DataEntry/FormItemInlineSwitch';
import BackupConfigurationFields from '../../FormParts/BackupConfigurationFields';
import { Col, Form, Row } from 'antd';
import { CmonJobInstanceCommand } from '../../../../services/cmon/models/CmonJobInstance';
import { FormInstance } from 'antd/lib/form';
import CcCluster from '../../../../services/models/CcCluster';
import BackupConfigurationFieldsWithTool from '../../FormParts/BackupConfigurationFieldsWithTool';
import BackupFormConfigurator from '../../Config/BackupFormConfigurator';

export default BackupConfigurationMongoForm;
export type BackupConfigurationMongoFormProps = {
    form: FormInstance;
    configurator: BackupFormConfigurator;
    cluster?: CcCluster;
    clusterPreselected?: boolean;
    onClusterSelect?: (cluster?: CcCluster) => void;
};

function BackupConfigurationMongoForm({
    form,
    configurator,
    cluster,
    clusterPreselected,
    onClusterSelect,
    ...rest
}: BackupConfigurationMongoFormProps) {
    const renderFields = (size: 'small' | 'large' = 'small') => {
        const md = size === 'large' ? 24 : 12;
        return (
            <div>
                <BackupConfigurationFields
                    configurator={configurator}
                    form={form}
                    cluster={cluster}
                    onClusterSelect={onClusterSelect}
                    clusterPreselected={clusterPreselected}
                    size={size}
                />
                {configurator.available('cloudUpload') && (
                    <Row gutter={[24, 0]}>
                        <Col xs={24} sm={24} md={md}>
                            <FormItemInlineSwitch
                                justify
                                noMargin
                                name="cloudUpload"
                                label="Upload backup to cloud"
                                valuePropName="checked"
                            />
                        </Col>
                    </Row>
                )}
            </div>
        );
    };
    return (
        <div className="BackupConfigurationPostgresForm">
            <Form.Item shouldUpdate noStyle>
                {() => {
                    return (
                        <BackupConfigurationFieldsWithTool
                            form={form}
                            configurator={configurator}
                            renderFields={renderFields}
                            toolCommand={CmonJobInstanceCommand.PBMAGENT}
                            cluster={cluster}
                        />
                    );
                }}
            </Form.Item>
        </div>
    );
}
