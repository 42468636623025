import React, { useState } from 'react';
import { Button, Col, Form, Row, Space } from 'antd';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import InputNumberWide from '../../../common/DataEntry/InputNumberWide';
import FormItemInlineSwitch from '../../../common/DataEntry/FormItemInlineSwitch';

export default DatabaseUserAdvancedFields;
export type DatabaseUserAdvancedFieldsProps = {
    edit?: boolean;
};

function DatabaseUserAdvancedFields({
    edit,
    ...rest
}: DatabaseUserAdvancedFieldsProps) {
    const [showAdvanced, setShowAdvanced] = useState(false);

    const handleAdvancedClick = () => {
        setShowAdvanced(!showAdvanced);
    };

    const advancedVisible = edit || showAdvanced;
    return (
        <>
            <Row gutter={[24, 0]}>
                <Col span={24}>
                    <Button
                        type="link"
                        style={{ padding: 0 }}
                        onClick={handleAdvancedClick}
                    >
                        <h3>
                            <Space>
                                <span>Advanced</span>
                                {showAdvanced ? (
                                    <UpOutlined />
                                ) : (
                                    <DownOutlined />
                                )}
                            </Space>
                        </h3>
                    </Button>
                </Col>
            </Row>
            {advancedVisible && (
                <Row gutter={[24, 0]}>
                    <Col span={24}>
                        <Form.Item
                            name="maxQueriesPerHour"
                            label="Max Queries Per Hour"
                        >
                            <InputNumberWide placeholder="Enter number" />
                        </Form.Item>
                        <Form.Item
                            name="maxUpdatesPerHour"
                            label="Max Updates Per Hour"
                        >
                            <InputNumberWide placeholder="Enter number" />
                        </Form.Item>
                        <Form.Item
                            name="maxConnectionsPerHour"
                            label="Max Connections Per Hour"
                        >
                            <InputNumberWide placeholder="Enter number" />
                        </Form.Item>
                        <Form.Item
                            name="maxUserConnections"
                            label="Max User Connections"
                        >
                            <InputNumberWide placeholder="Enter number" />
                        </Form.Item>
                        <FormItemInlineSwitch
                            name="requireSsl"
                            label="Requires SSL"
                        />
                    </Col>
                </Row>
            )}
        </>
    );
}
