import React, { useState, useContext, useEffect, useCallback } from 'react';
import { Form, Select, Space, Input, Alert } from 'antd';
import FormFooter from '../../../../../../common/FormFooter';
import ModalDefaultForm from '../../../../../../common/ModalDefaultForm';
import { ResponsiveContext } from '@severalnines/bar-frontend-components/build/lib/Layout/Responsive';
import AppEmpty from '../../../../../../common/Feedback/AppEmpty';
import CcCluster from '../../../../../../services/models/CcCluster';
import CcProxySqlNode from '../../../../../../services/models/CcProxySqlNode';
import CmonClustersService from '../../../../../../services/cmon/CmonClustersService';
import { BLACKLISTED_DB_USERS } from './proxySQLUserHelper';
import useFetch from '../../../../../../common/useFetch';
import ProxySQLHostGroup from './ProxySQLHostGroup';
import CmonProxysqlService from '../../../../../../services/cmon/CmonProxysqlService';
import {
    notifyError,
    notifyOperationSuccess,
    NotifyType,
} from '../../../../../Notifications/uiNotification';
import AppTable from '../../../../../../common/DataDisplay/AppTable';
import TypographyText from '../../../../../../common/TypographyText';

export default ProxySQLImportUsers;

export type ProxySQLImportUsersProps = {
    cluster: CcCluster;
    node: CcProxySqlNode;
    onSuccess?: () => void;
    onCancel?: () => void;
    onError?: (err: Error) => void;
    hostGroupList: { value: string; label: string }[] | undefined;
};

export type DataSourceType = {
    key?: string;
    class_name: string;
    host_allow: string;
    username: string;
    default_hostgroup?: number;
    password?: string;
    auth_plugin?: string;
};

function ProxySQLImportUsers({
    onSuccess,
    onCancel,
    onError,
    hostGroupList,
    cluster,
    node,
}: ProxySQLImportUsersProps) {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState<boolean>(false);
    const { responsive } = useContext(ResponsiveContext);
    const [hostGroupValue, setHostGroupValue] = useState<string>();
    const [accounts, setAccounts] = useState<DataSourceType[]>([]);
    const [sourceInfo, setSourceInfo] = useState<{
        sourceHostName: string;
        sourcePort: number;
    }>();

    const { loading: loadAccounts, data, refresh: refreshList } = useFetch<any>(
        {
            name: 'getAccounts',
            fetchFn: async (params, opts) => {
                const response = await CmonClustersService.listAccounts(
                    {
                        ...params,
                        cluster_id: cluster?.clusterId,
                    },
                    opts
                );

                return response;
            },
            cancelFn: async ({ requestId }) => {
                await CmonClustersService.cancelRequest(requestId);
            },
        }
    );

    useEffect(() => {
        refreshList();
    }, []);

    useEffect(() => {
        if (data) {
            setAccounts(
                data?.queryResults[0]?.accounts
                    ?.map((acc: any, index: number) => {
                        return {
                            key: `${acc.userName}_${index}`,
                            class_name: 'CmonProxySqlUser',
                            username: acc.userName,
                            host_allow: acc.hostAllow,
                            auth_plugin: acc?.authPlugin,
                        };
                    })
                    ?.filter(
                        (user: any) =>
                            !BLACKLISTED_DB_USERS.includes(user.username)
                    )
            );
            setSourceInfo({
                sourceHostName: data?.queryResults[0]?.hostname,
                sourcePort: data?.queryResults[0]?.port,
            });
        }
    }, [data]);

    const [usersList, setUsersList] = useState<DataSourceType[]>([]);

    const onChangeHandler = useCallback(
        (value: string) => {
            setHostGroupValue(value);
            if (accounts.length > 0) {
                setUsersList((prev) =>
                    prev.map((user) => {
                        user.default_hostgroup = +value;
                        return user;
                    })
                );
                setAccounts((prev) =>
                    prev.map((user) => {
                        user.default_hostgroup = +value;
                        return user;
                    })
                );
            }
        },
        [accounts]
    );

    const setUserHostGroup = useCallback(
        (record: DataSourceType, value: string) => {
            if (accounts.length > 0) {
                setUsersList((prev) =>
                    prev.map((user) => {
                        if (user.key === record.key) {
                            user.default_hostgroup = +value;
                        }
                        return user;
                    })
                );
                setAccounts((prev) =>
                    prev.map((user) => {
                        if (user.key === record.key) {
                            user.default_hostgroup = +value;
                        }
                        return user;
                    })
                );
            }
        },
        [accounts]
    );

    const handlePasswordChange = (record: any, value: string) => {
        setAccounts((prev) =>
            prev.map((user) => {
                if (user.key === record.key) {
                    user.password = value || undefined;
                }
                return user;
            })
        );
    };

    const handleSubmit = async () => {
        if (usersList.length === 0) {
            return;
        }
        try {
            setLoading(true);
            await CmonProxysqlService.importmysqlusers({
                cluster_id: cluster?.clusterId,
                hostName: node?.hostname,
                port: node?.port,
                userList: [
                    {
                        proxySqlUsers: usersList.map(
                            ({ key, ...rest }) => rest
                        ),
                        ...sourceInfo,
                    },
                ],
            });
            notifyOperationSuccess({
                type: NotifyType.TOAST,
                title: <span>Done</span>,
            });
            setLoading(false);
            onSuccess?.();
        } catch (error: any) {
            setLoading(false);
            notifyError({
                content: error.message,
            });
            onError?.(error);
        }
    };

    const columns = [
        {
            title: 'Username',
            key: 'username',
            // dataIndex: 'username',
            render: (record: DataSourceType) => (
                <TypographyText>
                    '{record.username}'@'{record.host_allow}'
                </TypographyText>
            ),
        },
        {
            title: 'Hostgroup',
            render: (record: DataSourceType) => (
                <ProxySQLHostGroup
                    globalHostGroupValue={hostGroupValue}
                    hostGroupList={hostGroupList}
                    record={record}
                    usersList={usersList}
                    form={form}
                    setUserHostGroup={setUserHostGroup}
                />
            ),
        },
        {
            title: 'Password',
            key: 'password',
            render: (record: DataSourceType) => {
                const passwordRequired = !!(
                    usersList.some(
                        (user) => user.key === record.key
                    ) &&
                    record?.auth_plugin &&
                    record.auth_plugin ===
                    'caching_sha2_password'
                )
                return (
                    <Form.Item
                        label={' '}
                        name={`password_${record.key}`}
                        rules={[
                            {
                                required: passwordRequired,
                                message: 'Enter password',
                            },
                        ]}
                    >
                        <Input.Password
                            placeholder="Enter password"
                            onChange={(value) =>
                                handlePasswordChange(
                                    record,
                                    value.currentTarget.value
                                )
                            }
                            disabled={!passwordRequired}
                        />
                    </Form.Item>
                );
            },
        },
    ];

    const rowSelection = {
        onSelect: (record: DataSourceType, selected: boolean) => {
            if (selected) {
                setUsersList((prev) => [...prev, record]);
            } else {
                setUsersList((prev) =>
                    prev.filter(
                        (acc: DataSourceType) =>
                            acc.username !== record.username
                    )
                );
            }
        },
        onSelectAll: (selected: boolean) => {
            if (selected) {
                setUsersList(accounts);
            } else {
                setUsersList([]);
            }
        },
    };

    return (
        <ModalDefaultForm
            title="Import users"
            form={form}
            footer={[]}
            onCancel={onCancel}
            defaultVisible={true}
            width={650}
        >
            <Form
                className="ProxySQLImportUsers"
                form={form}
                layout="vertical"
                requiredMark={false}
                onFinish={handleSubmit}
            >
                <Form.Item
                    name={'generalHostGroup'}
                    label={<Space>hostgroup</Space>}
                >
                    <Select
                        value={hostGroupValue}
                        onChange={onChangeHandler}
                        placeholder={'Hostgroup'}
                        options={hostGroupList}
                    />
                </Form.Item>

                <AppTable
                    className="ProxySQLUsersTable"
                    size="small"
                    rowSelection={rowSelection}
                    dataSource={accounts}
                    columns={columns}
                    responsive={responsive}
                    pagination={false}
                    loading={loadAccounts}
                    renderEmpty={
                        <AppEmpty
                            loading={loadAccounts}
                            description="There are no DB users to show."
                        />
                    }
                />

                <Alert
                    message="Please provide passwords for MySQL users which has their passwords set with the 'caching_sha2_password' plugin."
                    type="info"
                    style={{ marginTop: '10px' }}
                />

                <FormFooter
                    loading={loading}
                    showCancelButton
                    onCancel={onCancel}
                    submitButtonText="Import"
                    showSubmitButton
                />
            </Form>
        </ModalDefaultForm>
    );
}
