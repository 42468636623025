import React from 'react';
import JobActionButton from '../../../Jobs/JobActionButton';
import { CmonJobInstanceCommand } from '../../../../services/cmon/models/CmonJobInstance';
import ButtonWithForm from '../../../../common/General/ButtonWithForm';
import CcCluster from '../../../../services/models/CcCluster';
import { ButtonProps } from 'antd/lib/button';
import ClusterRemoveShardModal from './ClusterRemoveShardModal';

export default ClusterRemoveShard;

export type ClusterRemoveShardProps = ButtonProps & {
    cluster: CcCluster;
    children?: React.ReactNode;
    onSuccess?: () => void;
};

function ClusterRemoveShard({
    cluster,
    onSuccess,
    children,
    ...rest
}: ClusterRemoveShardProps) {
    return (
        <ButtonWithForm
            button={
                <JobActionButton
                    command={CmonJobInstanceCommand.REMOVE_SHARD}
                    clusterId={cluster.clusterId}
                    clusterType={cluster.clusterType}
                    {...rest}
                >
                    {children || 'Remove shard'}
                </JobActionButton>
            }
            form={<ClusterRemoveShardModal cluster={cluster} />}
        />
    );
}
