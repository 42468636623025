import React from 'react';
import { Space, Tag } from 'antd';
import { getNodeRoleText } from './NodeTypeFormat';
import { getReadonlyTag } from './NodeFormatHelper';
import { SpaceProps } from 'antd/lib/space';
import CcNode, { CcNodeType } from '../../services/models/CcNode';
import CcElasticNode from '../../services/models/CcElasticNode';

export default NodeRoleFormat;
export type NodeRoleFormatProps = SpaceProps & { node: CcNode };

function NodeRoleFormat({ node, ...rest }: NodeRoleFormatProps) {
    return (
        <Space {...rest}>
            {node.role ? getNodeRoleText(node.getRole()) : 'N/A'}
            {getReadonlyTag(node)}
            {node.isType(CcNodeType.ELASTIC) &&
                (node as CcElasticNode).electedMaster && (
                    <Tag color="blue">elected</Tag>
                )}
        </Space>
    );
}
