import React from 'react';
import { Col, Form, Input, Row, Space } from 'antd';
import InfoIcon from '@severalnines/bar-frontend-components/build/lib/General/InfoIcon';
import PasswordInput from '../../../../common/DataEntry/PasswordInput';
import { FileTextOutlined } from '@ant-design/icons';
import { validatePasswordStrength } from '../../../../common/helpers';
import { MSSQL_PASSWORD_SYMBOLS } from './MssqlConfigurator';
import VendorRepositoryField from '../../../../common/Form/Fields/VendorRepositoryField';
import { FormInstance } from 'antd/lib/form';
import SslEncryptionField from '../FormParts/SslEncryptionField';

export default MssqlNodeConfiguration;

export type MssqlNodeConfigurationProps = {
    form: FormInstance;
    isImport?: boolean;
};

function MssqlNodeConfiguration({
    form,
    isImport,
}: MssqlNodeConfigurationProps) {
    return (
        <div className="MssqlNodeConfiguration">
            <Row gutter={[24, 0]}>
                <Col span={24}>
                    <h3>Node configuration</h3>
                </Col>

                <Col xs={24} sm={24} md={12}>
                    <Form.Item
                        name={['nodeConfig', 'dbUser']}
                        label={
                            <Space>
                                Admin username
                                <InfoIcon
                                    info={
                                        isImport
                                            ? "Enter a sysadmin name to use when importing the server. You can use 'SA' or any other sysadmin username."
                                            : "Enter a sysadmin username to use for the new server. You cannot use 'SA' which is reserved."
                                    }
                                />
                            </Space>
                        }
                        rules={[
                            {
                                required: true,
                                message:
                                    'Please enter SQL server admin username.',
                            },
                        ]}
                    >
                        <Input placeholder="Enter the admin username" />
                    </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={12}>
                    <Form.Item
                        name={['nodeConfig', 'dbPassword']}
                        label={
                            <Space>
                                Admin password
                                <InfoIcon info="The admin password." />
                            </Space>
                        }
                        rules={[
                            {
                                required: true,
                                message: 'Please enter the admin password.',
                            },
                            {
                                validator: async (rule, value) => {
                                    if (isImport) {
                                        return true;
                                    }
                                    if (/[{}[\]();=@]/.test(value)) {
                                        throw new Error(
                                            'Characters {}[]();=@ are not allowed'
                                        );
                                    }
                                    if (
                                        validatePasswordStrength(value, {
                                            symbols: MSSQL_PASSWORD_SYMBOLS,
                                        })
                                    ) {
                                        return true;
                                    }
                                    throw new Error(
                                        `Password must be at least 8 characters length, contain numbers, lower case letters, uppercase letters and symbol such as ${MSSQL_PASSWORD_SYMBOLS}`
                                    );
                                },
                            },
                        ]}
                    >
                        <PasswordInput
                            autoComplete="new-password"
                            placeholder="Enter the admin password"
                        />
                    </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={12}>
                    <Form.Item
                        name={['nodeConfig', 'serverPort']}
                        label={
                            <Space>
                                Server port
                                <InfoIcon info="The SQL server port." />
                            </Space>
                        }
                        rules={[
                            {
                                required: true,
                                message: 'Please enter the SQL service port.',
                            },
                        ]}
                    >
                        <Input placeholder="Enter server port" disabled />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12}>
                    <a
                        href="https://docs.microsoft.com/en-us/sql/relational-databases/security/password-policy?view=sql-server-ver15"
                        target="_blank"
                        title="Password Policy"
                        rel="noreferrer"
                    >
                        <FileTextOutlined /> Password Policy
                    </a>
                </Col>
                {!isImport ? (
                    <Col xs={24} sm={24} md={12}>
                        <VendorRepositoryField
                            name={['nodeConfig', 'repository']}
                            form={form}
                        />
                    </Col>
                ) : null}
            </Row>
            {!isImport ? (
                <Row gutter={[24, 0]}>
                    <Col xs={24} sm={24} md={12}>
                        <SslEncryptionField />
                    </Col>
                </Row>
            ) : null}
        </div>
    );
}
