import React from 'react';
import { Row, Col, Form, Input } from 'antd';
import { StatusFormatStatus } from '@severalnines/bar-frontend-components/build/lib/Format/StatusFormat';
import NodePortField from '../../../../../common/Form/Fields/NodePortField';
import { FormInstance } from 'antd/lib/form';
import InputNumberWide from '../../../../../common/DataEntry/InputNumberWide';
import FormItemInlineSwitch from '../../../../../common/DataEntry/FormItemInlineSwitch';
import FormItem from '../../../../../common/DataEntry/FormItem';
import { TopologyItem } from '../../../../Topology/TopologyItem';
import ClusterNodesForm from '../../ClusterNodesForm';

export default MongoShardsReplicaSetForm;

export type MongoShardsReplicaSetFormProps = {
    form: FormInstance;
    shardKey: string;
    validateItem?: (item: TopologyItem) => Promise<TopologyItem> | TopologyItem;
    clusterId?: number;
};

function MongoShardsReplicaSetForm({
    form,
    shardKey,
    validateItem,
    clusterId,
}: MongoShardsReplicaSetFormProps) {
    const fieldNamespace = ['shards', shardKey];
    const renderItemFooter = (item: TopologyItem) => {
        return (
            <div>
                {item.status === StatusFormatStatus.success &&
                item.type !== 'primary' ? (
                    <div>
                        <FormItem shouldUpdate={true} noStyle={true}>
                            {() => (
                                <div>
                                    <FormItem
                                        withLessMarginBottom={true}
                                        name={[
                                            ...fieldNamespace,
                                            'perNode',
                                            item.extraData.hostname,
                                            'slaveDelay',
                                        ]}
                                        label="Replication delay (seconds)"
                                    >
                                        <InputNumberWide
                                            placeholder="Enter slave delay"
                                            disabled={form.getFieldValue([
                                                ...fieldNamespace,
                                                'perNode',
                                                item.extraData.hostname,
                                                'arbiter',
                                            ])}
                                        />
                                    </FormItem>

                                    <FormItem
                                        withLessMarginBottom={true}
                                        name={[
                                            ...fieldNamespace,
                                            'perNode',
                                            item.extraData.hostname,
                                            'priority',
                                        ]}
                                        label="Priority"
                                    >
                                        <InputNumberWide
                                            placeholder="Enter priority"
                                            disabled={
                                                form.getFieldValue([
                                                    ...fieldNamespace,
                                                    'perNode',
                                                    item.extraData.hostname,
                                                    'arbiter',
                                                ]) ||
                                                form.getFieldValue([
                                                    ...fieldNamespace,
                                                    'perNode',
                                                    item.extraData.hostname,
                                                    'slaveDelay',
                                                ]) > 0
                                            }
                                        />
                                    </FormItem>
                                    {/* more than 1 replica enables arbiter */}
                                    {(form.getFieldValue([
                                        ...fieldNamespace,
                                        'topology',
                                    ])?.length || 0) > 2 ? (
                                        <FormItemInlineSwitch
                                            withLessMarginBottom={true}
                                            justify={true}
                                            name={[
                                                ...fieldNamespace,
                                                'perNode',
                                                item.extraData.hostname,
                                                'arbiter',
                                            ]}
                                            label="Act as arbiter"
                                            valuePropName="checked"
                                        />
                                    ) : null}
                                </div>
                            )}
                        </FormItem>
                    </div>
                ) : null}
            </div>
        );
    };
    return (
        <div className="MongoShardsReplicaSetForm">
            <Row gutter={[24, 0]}>
                <Col span={24}>
                    <h3>Define the shard</h3>
                </Col>
                <Col xs={24} sm={24} md={12}>
                    <Form.Item
                        name={[...fieldNamespace, 'replicaSetName']}
                        label="ReplicaSet name"
                        rules={[
                            {
                                required: true,
                                message: 'Please enter ReplicaSet name',
                            },
                        ]}
                    >
                        <Input placeholder="Enter ReplicaSet name" />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12}>
                    <NodePortField name={[...fieldNamespace, 'serverPort']} />
                </Col>
            </Row>
            <Row gutter={[24, 0]}>
                <Col xs={24} sm={24} md={24}>
                    <ClusterNodesForm
                        title="Nodes in this shard"
                        form={form}
                        formItemProps={{
                            name: [...fieldNamespace, 'topology'],
                        }}
                        nodesInputProps={{
                            onItemAdd: (item: TopologyItem) => {
                                // setting initial values
                                form.setFieldsValue({
                                    shards: {
                                        [shardKey]: {
                                            perNode: {
                                                [item.extraData.hostname]: {
                                                    slaveDelay: 0,
                                                    priority: 1,
                                                },
                                            },
                                        },
                                    },
                                });
                            },
                            formProps: {
                                primaryTitle: 'Add nodes to the shard',
                            },
                            clusterId,
                            validateItem,
                            mutateItem: (item: TopologyItem) => {
                                return {
                                    ...item,
                                    footer: renderItemFooter(item),
                                    footerExpandable: true,
                                };
                            },
                        }}
                    />
                </Col>
            </Row>
        </div>
    );
}
