import { TopologyItem } from './TopologyItem';
import * as Immutable from 'immutable';
import { matchCollectionEntryProps } from '../../common/filtering';

export interface TopologyItemListProps {
    items?: TopologyItem[];
}

export class TopologyItemList {
    public items: Immutable.Map<string, TopologyItem>;

    constructor(props: TopologyItemListProps = {}) {
        this.items = props.items
            ? Immutable.OrderedMap(props.items.map((i) => [i.key, i]))
            : Immutable.OrderedMap();
    }

    count(): number {
        return this.items.count();
    }

    getItems(filterByProps?: Partial<TopologyItem>): TopologyItem[] {
        return this.items
            .toList()
            .toArray()
            .filter(
                (i) =>
                    !filterByProps ||
                    (filterByProps &&
                        matchCollectionEntryProps(i, filterByProps))
            );
    }

    setItems(items: TopologyItem[]): TopologyItemList {
        this.items = Immutable.OrderedMap(items.map((i) => [i.key, i]));
        return this;
    }

    getParents(filterByProps?: Partial<TopologyItem>): TopologyItem[] {
        return this.items
            .toList()
            .toArray()
            .filter(
                (i) =>
                    !i.fromKey &&
                    (!filterByProps ||
                        (filterByProps &&
                            matchCollectionEntryProps(i, filterByProps)))
            );
    }

    getItemChildren(
        item: TopologyItem | string,
        filterByProps?: Partial<TopologyItem>
    ): TopologyItem[] {
        return this.items
            .filter((i) =>
                typeof item === 'string'
                    ? i.fromKey === item
                    : i.fromKey === item.key
            )
            .toList()
            .toArray()
            .filter(
                (i) =>
                    !filterByProps ||
                    (filterByProps &&
                        matchCollectionEntryProps(i, filterByProps))
            );
    }

    setItem(newItem: TopologyItem) {
        this.items = this.items.set(newItem.key, newItem);
        return this;
    }

    removeItem(key: string) {
        // remove children recursively first
        this.getItemChildren(key).forEach((children) =>
            this.removeItem(children.key)
        );
        // remove item
        this.items = this.items.delete(key);
        return this;
    }
}
