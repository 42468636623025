import React from 'react';
import { FormInstance } from 'antd/lib/form';
import SpaceDescriptions from '../../../../../common/Layout/SpaceDescriptions';
import AppDivider from '../../../../../common/AppDivider';

export type ImportMaxScalePreviewProps = {
    form: FormInstance;
};

export default ImportMaxScalePreview;

function ImportMaxScalePreview({ form }: ImportMaxScalePreviewProps) {
    const { maxScaleWhere } = form.getFieldsValue(true);
    const [address] = maxScaleWhere.address;
    return (
        <div>
            <SpaceDescriptions
                direction="vertical"
                title="Where to install"
                size="small"
                alignItems="right"
            >
                <SpaceDescriptions.Item label="Server Address" labelStrong>
                    {address?.extraData.hostname ?? ''}
                </SpaceDescriptions.Item>
            </SpaceDescriptions>
            <AppDivider />
        </div>
    );
}
