import './PageMenu.less';
import { Tabs } from 'antd';
import React from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate, generatePath } from 'react-router-dom';

export default PageMenu;
export type PageMenuItem = {
    title: React.ReactNode | ((props: any) => React.ReactNode);
    key: string;
    path: string;
};
export type PageMenuProps = {
    activeTab?: string;
    menuItems: PageMenuItem[];
};
function PageMenu({ activeTab, menuItems }: PageMenuProps) {
    const navigate = useNavigate();
    const params: any = useParams();
    const page = params.page || activeTab;

    return (
        <Tabs
            className="PageMenu"
            activeKey={page}
            onTabClick={(key) => {
                const path = menuItems.find((item) => item.key === key)?.path;
                if (path) {
                    navigate(generatePath(path, params));
                }
            }}
        >
            {menuItems.map(({ title, key }) => (
                <Tabs.TabPane
                    tab={typeof title === 'function' ? title(params) : title}
                    key={key}
                />
            ))}
        </Tabs>
    );
}
