import ClusterTopologyConfigurator, {
    ClusterTopologyCard,
} from './ClusterTopologyConfigurator';
import CcNode, {
    CcNodeRole,
    CcNodeType,
} from '../../../services/models/CcNode';
import CcCluster from '../../../services/models/CcCluster';
import CcMsSqlNode from '../../../services/models/CcMsSqlNode';
import UnavailableFormat from '@severalnines/bar-frontend-components/build/lib/Format/UnavailableFormat';

export default class ClusterTopologyMssqlConfigurator extends ClusterTopologyConfigurator {
    public hasPgBouncers: boolean;
    constructor(clusters: CcCluster[]) {
        super(clusters);
        this.hasPgBouncers = false;
    }

    nodeToItem(node: CcNode, cluster: CcCluster, item: any): any {
        let result = item;
        this.hasDbNodes = true;
        if (node.isType(CcNodeType.MSSQL) && !node.isRole(CcNodeRole.BVS)) {
            if (node.isPrimary()) {
                result.from = 'db';
                result.arrow = 'both';
            }

            if (node.isReplica()) {
                const master = cluster.getPrimaryOf(node);

                if (master) {
                    result.from = master.getKey();
                    result.arrow = 'right';
                    if (!(node as CcMsSqlNode).isReplicationRunning()) {
                        result.connectionStatus = 'error';
                    }
                }
            }
        } else {
            result = super.nodeToItem(node, cluster, item);
        }
        return result;
    }
    getNodeCoverCardConfig(node: CcNode): ClusterTopologyCard {
        if (node.isType(CcNodeType.MSSQL)) {
            const mssqlNode = node as CcMsSqlNode;
            return [
                [
                    {
                        label: 'Operational state',
                        value: mssqlNode.operationalState,
                    },
                ],
                ...([CcNodeRole.SECONDARY].includes(
                    mssqlNode.role as CcNodeRole
                )
                    ? [
                          [
                              {
                                  label: 'Availability mode',
                                  value: mssqlNode.replica?.availabilityMode,
                              },
                          ],
                          [
                              {
                                  label: 'Failover mode',
                                  value: mssqlNode.replica?.failoverMode,
                              },
                          ],
                          [
                              {
                                  label: 'Synchronization health',
                                  value:
                                      mssqlNode.replica?.synchronizationHealth,
                              },
                              {
                                  label: 'Lag',
                                  value: (
                                      <UnavailableFormat
                                          val={
                                              mssqlNode.replicationSlave
                                                  ?.secondsBehindMaster
                                          }
                                      >
                                          {
                                              mssqlNode.replicationSlave
                                                  ?.secondsBehindMaster
                                          }
                                          s
                                      </UnavailableFormat>
                                  ),
                              },
                          ],
                      ]
                    : []),
            ];
        }

        return super.getNodeCoverCardConfig(node);
    }
}
