import React, { CSSProperties, useEffect, useState } from 'react';
import './SpaceDescriptions.less';
import { Button, Space } from 'antd';
import TypographyText, { TypographyTextProps } from '../TypographyText';
import { SpaceProps } from 'antd/lib/space';
import classNames from 'classnames';
import { DownOutlined, UpOutlined } from '@ant-design/icons';

export type SpaceDescriptionsProps = Omit<SpaceProps, 'title' | 'align'> & {
    align?: 'start' | 'end' | 'center' | 'baseline' | 'normal';
    title?: React.ReactNode;
    titleProps?: TypographyTextProps;
    children?: React.ReactNode;
    alignItems?: 'right' | 'left';
    alignTitle?: 'right' | 'left';
    nowrapTitle?: boolean;
    extra?: React.ReactNode;
    footer?: React.ReactNode;
    footerExtra?: React.ReactNode;
    wrapperSize?: number;
    itemSplit?: React.ReactNode;
    wide?: boolean;
    contentStyle?: CSSProperties;
    contentWrap?: boolean;
    showItems?: number;
    expandable?: boolean;
};

type SpaceDescriptionsType = React.FC<SpaceDescriptionsProps> & {
    Item: React.FC<SpaceDescriptionsItemProps>;
};

const SpaceDescriptions: SpaceDescriptionsType = ({
    title,
    titleProps = {},
    align,
    children,
    direction,
    size,
    style,
    className,
    alignItems,
    alignTitle,
    nowrapTitle,
    wrapperSize,
    extra,
    footer,
    footerExtra,
    itemSplit,
    wide,
    contentStyle = {},
    showItems,
    expandable = false,
    ...rest
}: SpaceDescriptionsProps) => {
    const [items, setItems] = useState<React.ReactNode>();
    const [expanded, setExpanded] = useState(false);
    useEffect(() => {
        if (showItems && !expanded && Array.isArray(children)) {
            setItems(children.slice(0, showItems));
        } else {
            setItems(children);
        }
    }, [children, showItems, expanded]);

    const handleExpand = () => {
        setExpanded(!expanded);
    };
    return (
        <Space
            className={classNames(
                'SpaceDescriptions',
                `SpaceDescriptions--align_title-${alignTitle}`,
                `SpaceDescriptions--align_items-${alignItems}`,
                {
                    'SpaceDescriptions--with-title': title,
                    'SpaceDescriptions--wide': wide,
                },
                className
            )}
            direction="vertical"
            size={wrapperSize !== undefined ? wrapperSize : 10}
            style={{ width: '100%', ...style }}
            {...rest}
        >
            {title || extra ? (
                <Space
                    style={{
                        justifyContent: 'space-between',
                        width: extra ? '100%' : 'auto',
                    }}
                >
                    {title ? (
                        <TypographyText
                            strong
                            className={classNames('SpaceDescriptions_title')}
                            nowrap={nowrapTitle}
                            {...titleProps}
                        >
                            {title}
                        </TypographyText>
                    ) : null}
                    {extra}
                </Space>
            ) : null}

            <Space
                className={classNames('SpaceDescriptions_items')}
                direction={direction}
                align={align === 'normal' ? 'center' : align}
                size={size}
                style={{
                    width: 'inherit',
                    ...(align === 'normal' ? { alignItems: 'normal' } : {}),
                    ...contentStyle,
                }}
                split={itemSplit}
            >
                {items}
                {expandable ? (
                    <Button type="link" onClick={handleExpand}>
                        {expanded ? (
                            <>
                                Show less <UpOutlined />
                            </>
                        ) : (
                            <>
                                Show more <DownOutlined />
                            </>
                        )}
                    </Button>
                ) : null}
            </Space>
            {footer || footerExtra ? (
                <Space
                    style={{
                        justifyContent: 'space-between',
                        width: footerExtra ? '100%' : 'auto',
                    }}
                >
                    <div>{footer}</div>
                    {footerExtra}
                </Space>
            ) : null}
        </Space>
    );
};

export type SpaceDescriptionsItemProps = SpaceProps & {
    children?: React.ReactNode;
    label?: React.ReactNode;
    contentStyle?: CSSProperties;
    labelStyle?: CSSProperties;
    labelStrong?: boolean;
    labelMuted?: boolean;
    labelNowrap?: boolean;
    colon?: boolean | React.ReactNode;
    text?: string;
    renderEmpty?: React.ReactNode;
};
const SpaceDescriptionItem = ({
    children,
    label,
    contentStyle = {},
    labelStyle,
    labelStrong,
    labelMuted = true,
    labelNowrap = false,
    colon = true,
    text,
    renderEmpty = <span>-</span>,
    ...rest
}: SpaceDescriptionsItemProps) => {
    const content = text || children;
    return (
        <Space className="SpaceDescriptionsItem" {...rest}>
            {label ? (
                <TypographyText
                    className="SpaceDescriptionsItem_label"
                    muted={!labelStrong && labelMuted}
                    strong={labelStrong}
                    nowrap={labelNowrap}
                    style={{ ...labelStyle }}
                >
                    {label}
                    {colon ? (colon === true ? ':' : colon) : null}
                </TypographyText>
            ) : null}
            <span style={{ ...contentStyle }}>
                {!content ? renderEmpty : content}
            </span>
        </Space>
    );
};
SpaceDescriptions.Item = SpaceDescriptionItem;
export default SpaceDescriptions;
