import { Button } from 'antd';
import { CmonJobInstanceCommand } from '../../../services/cmon/models/CmonJobInstance';
import PromoCard from '../../../common/Layout/PromoCard';
import TypographyText from '../../../common/TypographyText';
import React from 'react';
import CcCluster from '../../../services/models/CcCluster';
import { FormInstance } from 'antd/lib/form';
import useBackRestSetup from './useBackRestSetup';
import CcJob from '../../../services/models/CcJob';
import BackupToolJobProgress from './BackupToolJobProgress';
import AppSpin from '../../../common/General/AppSpin';

export default BackupToolPgBackrestReconfigure;
type BackupToolPgBackrestInstallProps = {
    cluster: CcCluster;
    form: FormInstance;
    onFinish?: (job: CcJob) => void;
    onReset?: () => void;
    onJobStart?: () => void;
};

function BackupToolPgBackrestReconfigure({
    cluster,
    form,
    onFinish,
    onReset,
    onJobStart,
}: BackupToolPgBackrestInstallProps) {
    const { loading, reconfigure } = useBackRestSetup({
        cluster,
        form,
        onSuccess: onJobStart,
    });

    const handleReconfigureClick = async () => {
        await reconfigure();
    };
    return (
        <AppSpin spinning={loading}>
            <BackupToolJobProgress
                reconfigure={true}
                command={CmonJobInstanceCommand.PGBACKREST}
                cluster={cluster}
                showReset={true}
                onFinish={onFinish}
                onReset={onReset}
                jobId={form.getFieldValue('backupToolJobId')}
                fallback={
                    <PromoCard
                        title={
                            <TypographyText type={'danger'}>
                                Reconfigure new backup tool
                            </TypographyText>
                        }
                        extra={
                            <Button
                                type="primary"
                                size="middle"
                                onClick={handleReconfigureClick}
                            >
                                Reconfigure
                            </Button>
                        }
                        iconType={'install'}
                        bg={'circles-bottom-right'}
                    >
                        <div style={{ paddingBottom: 50 }}>
                            <TypographyText>
                                <a
                                    href="https://pgbackrest.org/"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    PgBackRest{' '}
                                </a>
                                - Reliable PostgreSQL Backup & Restore.
                            </TypographyText>
                        </div>
                    </PromoCard>
                }
            />
        </AppSpin>
    );
}
