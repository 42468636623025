import { Col, Form, Input, Row, Space } from 'antd';
import InfoIcon from '@severalnines/bar-frontend-components/build/lib/General/InfoIcon';
import React, { useState } from 'react';
import CcCloudCredentials from '../../../services/models/CcCloudCredentials';
import CloudCredentialsBucketSelect from '../../Integrations/CloudCredentials/FormParts/CloudCredentialsBucketSelect';
import { FormInstance } from 'antd/es';
import CloudCredentialsSelectField from '../../../common/Form/Fields/CloudCredentialsSelectField';

export default BackupCloudCredentialsForm;

export type BackupCloudCredentialsFormProps = {
    form: FormInstance;
    fullWidth?: boolean;
    showLocationPath?: boolean;
    onLoadingChange?: (loading: boolean) => void;
    onCredentialsSelect?: (credentials?: CcCloudCredentials) => void;
};

function BackupCloudCredentialsForm({
    form,
    fullWidth = false,
    showLocationPath = true,
    onLoadingChange,
    onCredentialsSelect,
}: BackupCloudCredentialsFormProps) {
    const [selectedCredentials, setSelectedCredentials] =
        useState<CcCloudCredentials>();

    const handleCredentialsSelect = (credentials?: CcCloudCredentials) => {
        setSelectedCredentials(credentials);
        onCredentialsSelect?.(credentials);
    };

    const credentialsField = (
        <Col xs={24} sm={24} md={fullWidth ? 24 : 12}>
            <CloudCredentialsSelectField
                name="cloudCredentials"
                onCredentialsSelect={handleCredentialsSelect}
            />
        </Col>
    );

    const bucketField = selectedCredentials && (
        <Col xs={24} sm={24} md={fullWidth ? 24 : 12}>
            <CloudCredentialsBucketSelect
                credentials={selectedCredentials}
                onLoadingChange={onLoadingChange}
            />
        </Col>
    );

    const locationPath = showLocationPath && selectedCredentials && (
        <Col xs={24} sm={24} md={fullWidth ? 24 : 12}>
            <Form.Item shouldUpdate noStyle>
                {() => (
                    <Form.Item
                        name="cloudLocationPath"
                        label={
                            <Space>
                                Location path
                                <InfoIcon
                                    info={
                                        <span>
                                            Directory under bucket where backup
                                            will be stored. Backup will be
                                            stored in bucket root directory if
                                            location path is empty
                                        </span>
                                    }
                                />
                            </Space>
                        }
                        extra={getLocationPath()}
                    >
                        <Input
                            disabled={!form.getFieldValue('cloudBucket')}
                            placeholder="Enter location"
                        />
                    </Form.Item>
                )}
            </Form.Item>
        </Col>
    );
    return (
        <div className="BackupCloudCredentialsForm">
            {showLocationPath ? (
                <div>
                    <Row gutter={[24, 0]}>{credentialsField}</Row>
                    <Row gutter={[24, 0]}>
                        {bucketField}
                        {locationPath}
                    </Row>
                </div>
            ) : (
                <Row gutter={[24, 0]}>
                    {credentialsField}
                    {bucketField}
                    {locationPath}
                </Row>
            )}
        </div>
    );

    function getLocationPath() {
        const bucket = form.getFieldValue('cloudBucket');
        return (
            (bucket &&
                `${bucket}/${form.getFieldValue('cloudLocationPath') || ''}`) ||
            ''
        );
    }
}
