import React from 'react';
import { Form } from 'antd';
import WizardForm from '@severalnines/bar-frontend-components/build/lib/Navigation/WizardForm';
import WizardFormConfiguration from '@severalnines/bar-frontend-components/build/lib/Navigation/Wizard/WizardFormConfiguration';
import CcCluster from '../../../../services/models/CcCluster';
import { FormInstance } from 'antd/lib/form';
import useCreateJob from '../../../Jobs/useCreateJob';
import { CmonJobInstanceCommand } from '../../../../services/cmon/models/CmonJobInstance';
import ImportGarbdConfiguration, {
    GARBD_CONFIG_INITIAL,
    getImportGarbdConfigurationValidation,
} from './ImportGarbdConfiguration';
import ImportGarbdPreview from './ImportGarbdPreview';

export enum GarbdFormSteps {
    CONFIGURATION = 'Configuration',
    PREVIEW = 'Preview',
}

export type ImportGarbdWizardFormProps = {
    onCancel?: () => void;
    onSuccess?: () => void;
    onError?: (err: Error) => void;
    onStepErrorInsist?: (err: Error) => void;
    onTouchedChange?: (touched: boolean) => void;
    cluster?: CcCluster;
};

export default ImportGarbdWizardForm;

function ImportGarbdWizardForm({
    onCancel,
    onSuccess,
    onStepErrorInsist,
    onTouchedChange,
    onError,
    cluster,
}: ImportGarbdWizardFormProps) {
    const [form] = Form.useForm();

    const { loading, send } = useCreateJob({
        clusterId: cluster?.clusterId,
        title: 'Register Node',
        command:
            CmonJobInstanceCommand.REGISTERNODE.toLowerCase() as CmonJobInstanceCommand,
        onSuccess,
    });

    const handleSubmit = async () => {
        try {
            const { garbdConfig } = form.getFieldsValue(true);
            const [address] = garbdConfig.address;
            await send(
                {
                    clusterId: cluster?.clusterId,
                    ...formatFields(form),
                },
                {
                    job: {
                        title: `Register Node ${address?.extraData.hostname}`,
                    },
                }
            );
        } catch (err: any) {
            if (onError) {
                onError(err);
            }
        }
    };

    return (
        <WizardFormConfiguration
            form={form}
            onSubmit={handleSubmit}
            loading={loading}
            onCancel={onCancel}
            initialValues={{
                ...GARBD_CONFIG_INITIAL,
            }}
            onStepErrorInsist={onStepErrorInsist}
            onTouchedChange={onTouchedChange}
            steps={[
                <WizardForm.Step
                    key={GarbdFormSteps.CONFIGURATION}
                    title="Configuration"
                    subTitle=" "
                    validate={getImportGarbdConfigurationValidation(form)}
                >
                    <ImportGarbdConfiguration
                        selectedCluster={cluster}
                        form={form}
                    />
                </WizardForm.Step>,
                <WizardForm.Step
                    key={GarbdFormSteps.PREVIEW}
                    title="Preview"
                    subTitle=" "
                >
                    <ImportGarbdPreview form={form} />
                </WizardForm.Step>,
            ]}
        />
    );
}

export function formatFields(form: FormInstance) {
    const { garbdConfig } = form.getFieldsValue(true);

    const [address] = garbdConfig.address;
    const date = {
        component: 'garbd',
        hostname: address?.extraData.hostname,
        cmdline: garbdConfig.cmdline,
        port: garbdConfig.port,
    };
    return date;
}
