import CmonProxySqlServer, {
    CmonProxySqlServerProps,
} from '../cmon/models/CmonProxySqlServer';

export interface CcProxySqlServerProps extends CmonProxySqlServerProps {}

export enum CcProxySqlServerStatus {
    ONLINE = 'ONLINE',
    SHUNNED = 'SHUNNED',
    OFFLINE_SOFT = 'OFFLINE_SOFT',
    OFFLINE_HARD = 'OFFLINE_HARD',
}

export default class CcProxySqlServer extends CmonProxySqlServer {
    constructor(props: CcProxySqlServerProps) {
        super(props);
    }
}
