import React from 'react';
import InstallBackupTool from '../components/Backups/InstallBackupTool';
import Form from 'antd/lib/form';
import CcCluster from '../services/models/CcCluster';
import useForm from 'antd/lib/form/hooks/useForm';
import { CmonJobInstanceCommand } from '../services/cmon/models/CmonJobInstance';
import { Divider, Space } from 'antd';
import BackupToolPgBackrestSetup from '../components/Backups/BackupTool/BackupToolPgBackrestSetup';
import BackupToolPgBackrestReconfigure from '../components/Backups/BackupTool/BackupToolPgBackrestReconfigure';
import ButtonWithForm from '../common/General/ButtonWithForm';
import JobButton from '../components/Jobs/JobButton';
import BackupToolPgBackrestSetupModal from '../components/Backups/BackupTool/BackupToolPgBackrestSetupModal';
import BackupToolPBMSetupModal
    from "../components/Backups/BackupTool/BackupToolPBMSetupModal";
import BackupToolPBMSetup
    from "../components/Backups/BackupTool/BackupToolPBMSetup";

export default DebugInstallBackupTool;

export type DebugInstallBackupToolProps = {};

function DebugInstallBackupTool({}: DebugInstallBackupToolProps) {
    const [form] = useForm();
    return (
        <Space direction="vertical" className="DebugInstallBackupTool">
            <Space>
                <ButtonWithForm
                    button={
                        <JobButton
                            command={CmonJobInstanceCommand.PGBACKREST}
                            clusterId={1}
                        >
                            Install PgBackRest
                        </JobButton>
                    }
                    form={
                        <BackupToolPgBackrestSetupModal
                            cluster={new CcCluster({ cluster_id: 11 })}
                            reinstall={false}
                        />
                    }
                />
                <ButtonWithForm
                    button={
                        <JobButton
                            command={CmonJobInstanceCommand.PGBACKREST}
                            clusterId={1}
                        >
                            Reinstall PgBackRest
                        </JobButton>
                    }
                    form={
                        <BackupToolPgBackrestSetupModal
                            cluster={new CcCluster({ cluster_id: 11 })}
                            reinstall={true}
                        />
                    }
                />
                <ButtonWithForm
                    button={
                        <JobButton
                            command={CmonJobInstanceCommand.PGBACKREST}
                            clusterId={1}
                        >
                            Install PBM
                        </JobButton>
                    }
                    form={
                        <BackupToolPBMSetupModal
                            cluster={new CcCluster({ cluster_id: 11 })}
                            reinstall={false}
                        />
                    }
                />
                <ButtonWithForm
                    button={
                        <JobButton
                            command={CmonJobInstanceCommand.PGBACKREST}
                            clusterId={1}
                        >
                            Reinstall PBM
                        </JobButton>
                    }
                    form={
                        <BackupToolPBMSetupModal
                            cluster={new CcCluster({ cluster_id: 11 })}
                            reinstall={true}
                        />
                    }
                />
            </Space>
            <Form layout="vertical" form={form}>
                <Space direction="vertical">
                    <Divider plain={true} orientation="left">
                        PgBackRest
                    </Divider>
                    <InstallBackupTool
                        form={form}
                        command={CmonJobInstanceCommand.PGBACKREST}
                        cluster={new CcCluster({ cluster_id: 11 })}
                    />
                    <Divider plain={true} orientation="left">
                        PgBackRest reconfigure
                    </Divider>
                    <InstallBackupTool
                        form={form}
                        command={CmonJobInstanceCommand.PGBACKREST}
                        reinstall={true}
                        cluster={new CcCluster({ cluster_id: 11 })}
                    />
                    <Divider plain={true} orientation="left">
                        PBM
                    </Divider>
                    <InstallBackupTool
                        form={form}
                        command={CmonJobInstanceCommand.PBMAGENT}
                        cluster={new CcCluster({ cluster_id: 11 })}
                    />
                    <Divider plain={true} orientation="left">
                        PgBackRest new
                    </Divider>
                    <BackupToolPgBackrestSetup
                        form={form}
                        cluster={new CcCluster({ cluster_id: 11 })}
                    />
                    <Divider plain={true} orientation="left">
                        PgBackRest reconfigure new
                    </Divider>
                    <BackupToolPgBackrestReconfigure
                        form={form}
                        cluster={new CcCluster({ cluster_id: 11 })}
                    />
                    <Divider plain={true} orientation="left">
                        PBM new
                    </Divider>
                    <BackupToolPBMSetup
                        form={form}
                        cluster={new CcCluster({ cluster_id: 11 })}
                    />
                </Space>
            </Form>
        </Space>
    );
}
