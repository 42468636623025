import React from 'react';
import CcCluster from '../../services/models/CcCluster';
import ImportHAProxyWizardForm from './ImportLoadBalancerWizard/ImportHAProxyWizardForm/ImportHAProxyWizardForm';
import PgpoolWizardForm from './ImportLoadBalancerWizard/PgpoolWizardForm/PgpoolWizardForm';
import ImportKeepalivedWizardForm from './ImportLoadBalancerWizard/ImportKeepalivedWizardForm/ImportKeepalivedWizardForm';
import ImportProxySQLWizardFrom from './ImportLoadBalancerWizard/ImportProxySQLWizardFrom/ImportProxySQLWizardFrom';
import ImportMaxScaleWizardForm from './ImportLoadBalancerWizard/ImportMaxScaleWizardForm/ImportMaxScaleWizardForm';
import ImportGarbdWizardForm from './ImportLoadBalancerWizard/ImportGarbdWizardForm/ImportGarbdWizardForm';

import { LoadBalancerType } from './LoadBalancerWizard';
import ImportPgBouncerWizardForm from './ImportLoadBalancerWizard/ImportPgBouncerWizardForm/ImportPgBouncerWizardForm';

export default LoadBalancerFormsImport;

export type LoadBalancerFormsImportProps = {
    onCancel?: () => void;
    onSuccess?: () => void;
    onStepErrorInsist?: (err: Error) => void;
    onError?: (err: Error) => void;
    onTouchedChange?: (touched: boolean) => void;
    cluster?: CcCluster;
    loadBalancerTypes: LoadBalancerType;
};

function LoadBalancerFormsImport({
    onCancel,
    onSuccess,
    onStepErrorInsist,
    onError,
    onTouchedChange,
    cluster,
    loadBalancerTypes,
}: LoadBalancerFormsImportProps) {
    switch (loadBalancerTypes) {
        case LoadBalancerType.HAPROXY:
            return (
                <ImportHAProxyWizardForm
                    onCancel={onCancel}
                    cluster={cluster}
                    onSuccess={onSuccess}
                    onError={onError}
                    onTouchedChange={onTouchedChange}
                    onStepErrorInsist={onStepErrorInsist}
                />
            );
        case LoadBalancerType.KEEPALIVED:
            return (
                <ImportKeepalivedWizardForm
                    onCancel={onCancel}
                    cluster={cluster}
                    onSuccess={onSuccess}
                    onError={onError}
                    onTouchedChange={onTouchedChange}
                    onStepErrorInsist={onStepErrorInsist}
                />
            );
        case LoadBalancerType.PROXYSQL:
            return (
                <ImportProxySQLWizardFrom
                    onCancel={onCancel}
                    cluster={cluster}
                    onSuccess={onSuccess}
                    onError={onError}
                    onTouchedChange={onTouchedChange}
                    onStepErrorInsist={onStepErrorInsist}
                />
            );
        case LoadBalancerType.MAXSCALE:
            return (
                <ImportMaxScaleWizardForm
                    onCancel={onCancel}
                    cluster={cluster}
                    onSuccess={onSuccess}
                    onError={onError}
                    onTouchedChange={onTouchedChange}
                    onStepErrorInsist={onStepErrorInsist}
                />
            );
        case LoadBalancerType.GARBD:
            return (
                <ImportGarbdWizardForm
                    onCancel={onCancel}
                    cluster={cluster}
                    onSuccess={onSuccess}
                    onError={onError}
                    onTouchedChange={onTouchedChange}
                    onStepErrorInsist={onStepErrorInsist}
                />
            );
        case LoadBalancerType.PGBOUNCER:
            return (
                <ImportPgBouncerWizardForm
                    onCancel={onCancel}
                    cluster={cluster}
                    onSuccess={onSuccess}
                    onError={onError}
                    onTouchedChange={onTouchedChange}
                    onStepErrorInsist={onStepErrorInsist}
                />
            );
        case LoadBalancerType.PGPOOL_II:
            return <PgpoolWizardForm onCancel={onCancel} cluster={cluster} />;

        default:
            return <div></div>;
    }
}
