


export interface CmonBackupScheduleProps  {
    cluster_id?: number;
    enabled?: boolean;
    id?: number;
    job?: {[key:string]:any};
    lastExec?: string;
    schedule?: string;

}



export default class CmonBackupSchedule {
    public clusterId?: number;
    public enabled?: boolean;
    public id?: number;
    public job?: {[key:string]:any};
    public lastExec?: string;
    public schedule?: string;


    constructor(props: CmonBackupScheduleProps) {
        this.clusterId = props.cluster_id;
        this.enabled = props.enabled;
        this.id = props.id;
        this.job = props.job;
        this.lastExec = props.lastExec;
        this.schedule = props.schedule;

    }

}
