const dashboard = {
    title: 'MongoDB Server',
    meta: {
        slug: 'mongodb-server',
        supportClusterTypes: ['mongodb', 'mongodb_shards'],
    },
    panels: [
        {
            title: 'Name',
            type: 'singlestat',
            options: {
                decimals: 0,
                format: 'none',
                legend: {},
                chart: {
                    full: false,
                    show: true,
                },
                valueMaps: [],
            },
            gridPos: {
                h: 4,
                w: 6,
                x: 0,
                y: 0,
            },
            targets: [
                {
                    expr:
                        'mongodb_mongod_replset_my_name{instance=~"$instance"}',
                    legendFormat: '{{ name }}',
                    legendAsValue: true,
                },
            ],
        },
        {
            title: 'ReplSet name',
            type: 'singlestat',
            options: {
                decimals: 0,
                format: 'none',
                legend: {},
                chart: {
                    full: false,
                    show: true,
                },
                valueMaps: [],
            },
            gridPos: {
                h: 4,
                w: 6,
                x: 0,
                y: 0,
            },
            targets: [
                {
                    expr:
                        'mongodb_mongod_replset_my_name{instance=~"$instance"}',
                    legendFormat: '{{ set }}',
                    legendAsValue: true,
                },
            ],
        },
        {
            title: 'Role',
            type: 'singlestat',
            gridPos: {
                h: 4,
                w: 8,
                x: 0,
                y: 0,
            },
            options: {
                format: 'none',
                type: 'column',
                legend: {
                    alignAsTable: false,
                },
                yaxis: [],
                stack: false,
            },
            datasource: 'clustercontrol',
            targets: [
                {
                    expr:
                        "node:$[hostname='$instance' and nodetype='mongo'].{'memberRole': memberRole}",
                    legendFormat: '{{ memberRole }}',
                    legendAsValue: true,
                },
            ],
        },
        {
            title: 'Server uptime',
            type: 'singlestat',
            gridPos: {
                h: 4,
                w: 4,
                x: 0,
                y: 0,
            },
            options: {
                decimals: 1,
                format: 'duration',
            },
            targets: [
                {
                    expr:
                        'mongodb_instance_uptime_seconds{instance=~"$instance"}',
                },
            ],
        },
        {
            title: 'OpsCounters',
            type: 'chart',
            options: {
                decimals: null,
                format: 'short',
                type: 'area',
                legend: {
                    alignAsTable: true,
                },
                yaxis: [
                    {
                        format: 'short',
                        max: null,
                        min: 0,
                    },
                ],
                stack: false,
            },
            targets: [
                {
                    expr:
                        'irate(mongodb_op_counters_total{instance=~"$instance"}[5m])',
                    legendFormat: '{{ type }}',
                },
            ],
            gridPos: {
                h: 7,
                w: 12,
                x: 0,
                y: 0,
            },
        },
        {
            title: 'Connections',
            type: 'chart',
            options: {
                decimals: null,
                format: 'short',
                type: 'area',
                legend: {
                    alignAsTable: true,
                },
                yaxis: [
                    {
                        format: 'short',
                        max: null,
                        min: 0,
                    },
                    {
                        format: 'short',
                        max: 100,
                        min: 0,
                    },
                ],
                stack: false,
            },
            targets: [
                {
                    expr: 'mongodb_connections{instance=~"$instance"}',
                    legendFormat: '{{ state }}',
                },
                {
                    expr:
                        '100 * (sum(mongodb_connections{instance=~"$instance", state="current"}) / sum(mongodb_connections{instance=~"$instance"}))',
                    legendFormat: 'used %',
                    yaxis: 1,
                },
            ],
            gridPos: {
                h: 7,
                w: 12,
                x: 0,
                y: 0,
            },
        },
        {
            title: 'WT - concurrent tickets (read)',
            type: 'chart',
            options: {
                decimals: null,
                format: 'short',
                type: 'area',
                legend: {
                    alignAsTable: true,
                },
                yaxis: [
                    {
                        format: 'short',
                        max: null,
                        min: 0,
                    },
                    {
                        format: 'short',
                        max: null,
                        min: null,
                    },
                ],
                stack: false,
            },
            targets: [
                {
                    expr:
                        'rate(mongodb_mongod_wiredtiger_concurrent_transactions_available_tickets{instance=~"$instance", type="read"}[$interval])',
                    legendFormat: 'Available',
                },
                {
                    expr:
                        'rate(mongodb_mongod_wiredtiger_concurrent_transactions_total_tickets{instance=~"$instance", type="read"}[$interval])',
                    legendFormat: 'Total',
                },
                {
                    expr:
                        'rate(mongodb_mongod_wiredtiger_concurrent_transactions_out_tickets{instance=~"$instance", type="read"}[$interval])',
                    legendFormat: 'Out',
                },
            ],
            gridPos: {
                h: 7,
                w: 12,
                x: 0,
                y: 0,
            },
        },
        {
            title: 'WT - concurrent tickets (write)',
            type: 'chart',
            options: {
                decimals: null,
                format: 'short',
                type: 'area',
                legend: {
                    alignAsTable: true,
                },
                yaxis: [
                    {
                        format: 'short',
                        max: null,
                        min: 0,
                    },
                ],
                stack: false,
            },
            targets: [
                {
                    expr:
                        'rate(mongodb_mongod_wiredtiger_concurrent_transactions_available_tickets{instance=~"$instance", type="write"}[$interval])',
                    legendFormat: 'Available',
                },
                {
                    expr:
                        'rate(mongodb_mongod_wiredtiger_concurrent_transactions_total_tickets{instance=~"$instance", type="write"}[$interval])',
                    legendFormat: 'Total',
                },
                {
                    expr:
                        'rate(mongodb_mongod_wiredtiger_concurrent_transactions_out_tickets{instance=~"$instance", type="write"}[$interval])',
                    legendFormat: 'Out',
                },
            ],
            gridPos: {
                h: 7,
                w: 12,
                x: 0,
                y: 0,
            },
        },
        {
            title: 'WT - cache',
            type: 'chart',
            options: {
                decimals: 2,
                type: 'area',
                legend: {
                    alignAsTable: true,
                },
                yaxis: [
                    {
                        format: 'size',
                        max: null,
                        min: 0,
                    },
                ],
                stack: false,
            },
            targets: [
                {
                    expr:
                        'mongodb_mongod_wiredtiger_cache_max_bytes{instance=~"$instance"}',
                    legendFormat: 'Max',
                    format: 'size',
                },
                {
                    expr:
                        'rate(mongodb_mongod_wiredtiger_cache_bytes{instance=~"$instance", type="dirty"}[$interval])',
                    legendFormat: 'Dirty',
                    format: 'size',
                },
                {
                    expr:
                        'rate(mongodb_mongod_wiredtiger_cache_bytes{instance=~"$instance", type="total"}[$interval])',
                    legendFormat: 'Total',
                    format: 'size',
                },
                {
                    expr:
                        'rate(mongodb_mongod_wiredtiger_cache_evicted_total{instance=~"$instance", type="modified"}[$interval])',
                    legendFormat: 'Evicted - Modified',
                    format: 'sizeps',
                },
                {
                    expr:
                        'rate(mongodb_mongod_wiredtiger_cache_evicted_total{instance=~"$instance", type="unmodified"}[$interval])',
                    legendFormat: 'Evicted - Unmodified',
                    format: 'sizeps',
                },
            ],
            gridPos: {
                h: 7,
                w: 12,
                x: 0,
                y: 0,
            },
        },
        {
            title: 'Global lock',
            type: 'chart',
            options: {
                decimals: null,
                format: 'short',
                type: 'area',
                legend: {
                    alignAsTable: true,
                },
                yaxis: [
                    {
                        format: 'short',
                        max: null,
                        min: 0,
                    },
                ],
                stack: false,
            },
            targets: [
                {
                    expr:
                        'rate(mongodb_mongod_global_lock_current_queue{instance=~"$instance", type="reader"}[$interval])',
                    legendFormat: 'Current Queue - Reader',
                },
                {
                    expr:
                        'rate(mongodb_mongod_global_lock_current_queue{instance=~"$instance", type="writer"}[$interval])',
                    legendFormat: 'Current Queue - Writer',
                },
                {
                    expr:
                        'rate(mongodb_mongod_global_lock_client{instance=~"$instance", type="reader"}[$interval])',
                    legendFormat: 'Client - Reader',
                },
                {
                    expr:
                        'rate(mongodb_mongod_global_lock_client{instance=~"$instance", type="writer"}[$interval])',
                    legendFormat: 'Client - Writer',
                },
            ],
            gridPos: {
                h: 7,
                w: 12,
                x: 0,
                y: 0,
            },
        },
        {
            title: 'Asserts',
            type: 'chart',
            options: {
                decimals: null,
                format: 'short',
                type: 'line',
                legend: {
                    alignAsTable: true,
                },
                yaxis: [
                    {
                        format: 'short',
                        max: null,
                        min: 0,
                    },
                ],
                stack: false,
            },
            targets: [
                {
                    expr:
                        'irate(mongodb_asserts_total{instance=~"$instance",type="msg"}[5m])',
                    legendFormat: 'msg',
                },
                {
                    expr:
                        'irate(mongodb_asserts_total{instance=~"$instance",type="regular"}[5m])',
                    legendFormat: 'regular',
                },
                {
                    expr:
                        'irate(mongodb_asserts_total{instance=~"$instance",type="rollovers"}[5m])',
                    legendFormat: 'rollovers',
                },
                {
                    expr:
                        'irate(mongodb_asserts_total{instance=~"$instance",type="user"}[5m])',
                    legendFormat: 'user',
                },
                {
                    expr:
                        'irate(mongodb_asserts_total{instance=~"$instance",type="warning"}[5m])',
                    legendFormat: 'warning',
                },
            ],
            gridPos: {
                h: 7,
                w: 12,
                x: 0,
                y: 0,
            },
        },
    ],
    templating: {
        list: [
            {
                datasource: 'clustercontrol',
                multi: false,
                label: 'Host',
                name: 'instance',
                query: "host:$[nodes[nodetype='mongo']]",
                optionValue: 'hostname',
                optionText: 'hostname',
                sort: 1,
                isInstancePicker: true,
            },
        ],
    },
};
export default dashboard;
