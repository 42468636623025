import React from 'react';
import { Link, LinkProps, To } from 'react-router-dom';

export default ActivityCenterAuditLink;

export type ActivityCenterAuditLinkProps = Omit<LinkProps, 'to'> & {
    to?: To;
};

function ActivityCenterAuditLink({
    to = '/activity-center/audit',
    ...rest
}: ActivityCenterAuditLinkProps) {
    return <Link to={to} {...rest} />;
}
