import React, { useEffect, useMemo, useState } from 'react';
import ModalDefaultForm from '../../../common/ModalDefaultForm';
import { Form, Input, Space } from 'antd';
import { LdapSettingsModalFormValues } from './LdapSettingsModal';
import { FormInstance } from 'antd/es';
import useLdapSettings from './useLdapSettings';
import SpaceWide from '../../../common/SpaceWide';
import GroupField from '../GroupField';
import AppRadio from '../../../common/DataEntry/AppRadio';
import InfoIcon from '@severalnines/bar-frontend-components/build/lib/General/InfoIcon';
import FormItem from '../../../common/DataEntry/FormItem';
import FormFooter from '../../../common/FormFooter';
import {
    notifyError,
    notifyOperationSuccess,
    NotifyType,
} from '../../Notifications/uiNotification';
import { LdapConfigGroupMappingsItem } from './LdapTypes';
import useGroupList from '../useGroupList';

export default LdapMapGroupForm;

export interface LdapMapGroupFormValues {
    rdnMode?: 'single' | 'multi';
}

export type LdapMapGroupFormProps = {
    item?: LdapConfigGroupMappingsItem;
    onCancel?: () => void;
    onSuccess?: () => void;
    onError?: (err: any) => void;
    form?: FormInstance;
};

function LdapMapGroupForm({
    item: currentItem,
    onCancel,
    onError,
    onSuccess,
    form: parentForm,
    ...rest
}: LdapMapGroupFormProps) {
    const [internalForm] = Form.useForm<LdapSettingsModalFormValues>();
    const form = parentForm || internalForm;
    const [loading, setLoading] = useState(false);

    const {
        loading: loadingSettings,
        record: settings,
        refresh: refreshSettings,
        defaults: defaultSettings,
        addGroupMap,
        updateGroupMap,
    } = useLdapSettings();

    const {
        list: groups,
        loading: loadingGroups,
        refresh: refreshGroups,
    } = useGroupList({
        pageSize: 0,
    });

    const groupNameAttribute =
        settings?.ldapSettings?.ldapGroupNameAttribute ||
        defaultSettings?.ldapSettings?.ldapGroupNameAttribute;

    useEffect(() => {
        (async () => {
            await refreshSettings();
        })();
    }, []);

    useEffect(() => {
        if (currentItem) {
            (async () => {
                await refreshGroups();
            })();
        }
    }, [currentItem]);

    const handleSubmit = async () => {
        const { group, ldapGroupId } = form.getFieldsValue(true);

        try {
            setLoading(true);
            const newItem = {
                cmonGroupName: group.groupName,
                ldapGroupId: ldapGroupId,
            };
            if (currentItem) {
                await updateGroupMap(currentItem, newItem);
            } else {
                await addGroupMap(newItem);
            }

            setLoading(false);
            notifyOperationSuccess({
                type: NotifyType.TOAST,
                title: (
                    <span>
                        LDAP group map {currentItem ? 'udpated' : 'added'}{' '}
                        succesfully.
                    </span>
                ),
            });
            onSuccess?.();
        } catch (e) {
            notifyError({
                content: e.message,
            });
            setLoading(false);
            onError?.(e);
        }
    };

    const initialValues = useMemo(() => {
        const isMulti = currentItem && currentItem.ldapGroupId?.includes(`=`);
        return {
            rdnMode: isMulti ? 'multi' : 'single',
            ...(currentItem
                ? {
                      group: groups?.find(
                          (group) =>
                              currentItem.cmonGroupName === group.groupName
                      ),
                      ldapGroupId: currentItem.ldapGroupId,
                  }
                : {}),
        };
    }, [settings, currentItem, groups]);
    return (
        <ModalDefaultForm
            title={`Map LDAP group`}
            width={450}
            form={form}
            onCancel={onCancel}
            defaultVisible={true}
            loading={loading || loadingSettings || loadingGroups}
            {...rest}
        >
            {!currentItem || (currentItem && groups) ? (
                <Form
                    form={form}
                    initialValues={initialValues}
                    layout="vertical"
                    onFinish={handleSubmit}
                >
                    <SpaceWide direction="vertical">
                        <h4>ClusterControl team</h4>
                        <GroupField />
                        <h4>LDAP group and RDN options</h4>
                        <Form.Item
                            name="rdnMode"
                            label={
                                <Space>
                                    <span>RDN Options</span>
                                    <InfoIcon info="The LDAP’s Group RDN path relative to the Group Base DN to be mapped with the ClusterControl Team. Suitable for multi-tier group mappings. For example: “CN=group1,OU=org1,OU=org2”," />
                                </Space>
                            }
                        >
                            <AppRadio.Group>
                                <AppRadio.Button
                                    key="single-tier-rdn"
                                    value="single"
                                >
                                    Single-tier RDN
                                </AppRadio.Button>
                                <AppRadio.Button
                                    key="multi-tier-rdn"
                                    value="multi"
                                >
                                    Multi-tier RDN
                                </AppRadio.Button>
                            </AppRadio.Group>
                        </Form.Item>
                        <FormItem noStyle={true} shouldUpdate={true}>
                            {() => {
                                return (
                                    <FormItem
                                        name="ldapGroupId"
                                        label={
                                            <Space>
                                                <span>LDAP group</span>
                                                <InfoIcon info="The LDAP group’s name should be mapped with “ClusterControl’s Team”. If the LDAP group’s DN path is CN=DBA, specify “DBA” here and then CN in the Group name attribute." />
                                            </Space>
                                        }
                                        rules={[
                                            {
                                                required: true,
                                                message:
                                                    'Please enter LDAP group',
                                            },
                                        ]}
                                    >
                                        <Input
                                            addonBefore={
                                                form.getFieldValue(
                                                    'rdnMode'
                                                ) === 'single' ? (
                                                    <span>
                                                        {groupNameAttribute}=
                                                    </span>
                                                ) : null
                                            }
                                            placeholder="Enter LDAP group"
                                        />
                                    </FormItem>
                                );
                            }}
                        </FormItem>

                        <FormFooter
                            loading={loading}
                            submitButtonText="Save"
                            showCancelButton
                            showSubmitButton
                            onCancel={onCancel}
                        />
                    </SpaceWide>
                </Form>
            ) : null}
        </ModalDefaultForm>
    );
}
