import React, { useEffect, useState } from 'react';
import { Col, Form, Input, Row } from 'antd';
import FormFooter from '../../common/FormFooter';
import ModalDefaultForm from '../../common/ModalDefaultForm';
import {
    NotifyType,
    notifyError,
    notifyOperationSuccess,
} from '../Notifications/uiNotification';
import CmonConfigService from '../../services/cmon/CmonConfigService';
import { ResultGroup, defaultSettings } from './emailNotificationsHelper';
import CcCluster from '../../services/models/CcCluster';

export default ExternalEmailModal;

export type ExternalEmailModalProps = {
    onSuccess: (email: string) => Promise<any>;
    onCancel?: () => void;
    onError?: (err: Error) => void;
    cluster: CcCluster;
    groupList: ResultGroup[] | undefined;
};
export type ExternalEmailModalFormValues = {
    email: string;
};

const regex = /^(([^<>()[\].,;:\s@"]+(.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+.)+[^<>()[\].,;:\s@"]{2,})$/i;

function ExternalEmailModal({
    cluster,
    groupList,
    onSuccess,
    onCancel,
    onError,
}: ExternalEmailModalProps) {
    const [form] = Form.useForm<ExternalEmailModalFormValues>();
    const [loading, setLoading] = useState<boolean>(false);

    const emailValidator = async (rule: any, value: string) => {
        if (!value || regex.test(value) === false) {
            throw new Error(`Please, enter a valid email address.`);
        }
        if ((value || regex.test(value)) && groupList) {
            const emailExist = groupList.some(
                (obj) =>
                    obj.users &&
                    obj.users.some((user) => user.emailAddress === value)
            );
            if (emailExist) throw new Error(`This email is already added.`);
        }

        return true;
    };

    const handleSubmit = async (values: ExternalEmailModalFormValues) => {
        try {
            setLoading(true);
            await CmonConfigService.setnotificationsettings({
                settings: defaultSettings,
                cluster_id: cluster.clusterId,
                email: values.email,
            });
            notifyOperationSuccess({
                type: NotifyType.TOAST,
                title: `${values.email} changed successfully!`,
                cancelText: 'Close',
            });
            await onSuccess(values.email);
            setLoading(false);
        } catch (err: any) {
            setLoading(false);
            notifyError({ type: NotifyType.TOAST, content: err.message });
            onError?.(err);
        }
    };

    return (
        <ModalDefaultForm
            title={'Add an external email'}
            form={form}
            footer={[]}
            onCancel={onCancel}
            width={450}
            defaultVisible={true}
        >
            <Form
                className="ExternalEmailModal"
                form={form}
                layout="vertical"
                onFinish={handleSubmit}
                initialValues={{}}
            >
                <Row gutter={[24, 0]}>
                    <Col span={24}>
                        <Form.Item
                            name={'email'}
                            label={'Email address'}
                            rules={[
                                {
                                    type: 'email',
                                    required: true,
                                    validator: emailValidator,
                                },
                            ]}
                        >
                            <Input
                                maxLength={100}
                                placeholder="Enter an email address"
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <FormFooter
                    loading={loading}
                    showCancelButton
                    onCancel={onCancel}
                    submitButtonText="Add"
                    showSubmitButton
                />
            </Form>
        </ModalDefaultForm>
    );
}
