import useClusterList from '../../components/Clusters/useClusterList';
import { AppState } from '../../appReducer';
import { useSelector } from 'react-redux';
import CryptoJS from 'crypto-js';
import useCurrentUser from '../../components/User/useCurrentUser';
import CcCluster, {
    CcClusterType,
    CcClusterVendor,
} from '../../services/models/CcCluster';
import CcNode, { CcNodeType } from '../../services/models/CcNode';
import { AppConfig } from '../../AppConfig';
import useLocalStorage from '@severalnines/bar-frontend-components/build/lib/hooks/useLocalStorage';
import { useEffect } from 'react';
import { requestCMONVersion } from '../../components/Auth/Registration';
import { useDebugContext } from '../Debug';
import { CcLicenseType } from '../../services/models/CcLicense';

type UsePingHomeProps = {};

const PING_DATA_PLACEHOLDER = {
    ccui: '',
    cc_address: '',
    galera: 0,
    galerahost: 0,

    groupreplication: 0,
    groupreplication_hosts: 0,

    replication: 0,
    replhost: 0,
    mysqlcluster: 0,
    mysqlclusterhost: 0,
    mongodb: 0,
    mongodbhost: 0,
    version: 0,
    mysqlsingle: 0,
    mysqlsingle_hosts: 0,

    haproxy: 0,
    proxysql: 0,
    maxscale: 0,

    postgresql: 0,
    postgresql_hosts: 0,
    redis: 0,
    redis_hosts: 0,
    redis_sentinel_hosts: 0,
    elastic: 0,
    elastic_hosts: 0,
    sqlserver: 0,
    sqlserver_hosts: 0,

    os_name: '',
    os_codename: '',
    os_release: '',
    vendor_percona: 0,
    vendor_mariadb: 0,
    vendor_oracle: 0,
    version_55: 0,
    version_56: 0,
    version_57: 0,
    version_101: 0,
    version_10: 0,

    uid_hash: '',
    paying: 0,

    extra_info: '',

    vendors: '-',
    vendors_versions: '-',
};

const VENDORS = [
    CcClusterVendor.TEN_GEN,
    CcClusterVendor.CODERSHIP,
    CcClusterVendor.MARIADB,
    CcClusterVendor.ORACLE,
    CcClusterVendor.PERCONA,
    CcClusterVendor.REDIS,
    CcClusterVendor.ELASTICSEARCH,
    CcClusterVendor.MICROSOFT,
    CcClusterVendor.POSTGRESQL,
];

const CLUSTER_TYPES = [
    CcClusterType.TYPE_REPLICATION,
    CcClusterType.TYPE_GALERA,
    CcClusterType.TYPE_MYSQL_SINGLE,
    CcClusterType.TYPE_MYSQL_CLUSTER,
    CcClusterType.TYPE_MONGODB,
    CcClusterType.TYPE_POSTGRESQL,
    CcClusterType.TYPE_TIMESCALEDB,
    CcClusterType.TYPE_GROUP_REPLICATION,
    CcClusterType.TYPE_REDIS,
    CcClusterType.TYPE_MSSQL_SINGLE,
    CcClusterType.TYPE_MSSQL_AO_ASYNC,
    CcClusterType.TYPE_ELASTIC,
];

const NODE_TYPES = [
    CcNodeType.CONTROLLER,
    CcNodeType.MYSQL,
    CcNodeType.GALERA,
    CcNodeType.POSTGRESQL,
    CcNodeType.PGBOUNCER,
    CcNodeType.GROUP_REPLICATION,
    CcNodeType.MONGO,
    CcNodeType.HAPROXY,
    CcNodeType.KEEPALIVED,
    CcNodeType.PROXYSQL,
    CcNodeType.NDB,
    CcNodeType.NDB_MGMD,
    CcNodeType.CMON_AGENT,
    CcNodeType.GARBD,
    CcNodeType.MEMCACHED,
    CcNodeType.MAXSCALE,
    CcNodeType.PROMETHEUS,
    CcNodeType.PBM_AGENT,
    CcNodeType.REDIS,
    CcNodeType.REDIS_SENTINEL,
    CcNodeType.MSSQL,
    CcNodeType.PGBACKREST,
    CcNodeType.ELASTIC,
    CcNodeType.TIMESCALEDB,
];

/**
 * Implementation of ping home feature, with env stat data for marketing purposes
 * This hook is trying to imitate what was done in old UI, but does not follow all logic, so might have differences in data that being sent
 * Also added new fields to request compare to old UI
 */
export default function usePingHome({}: UsePingHomeProps = {}) {
    const { log } = useDebugContext();
    const licenseInfo = useSelector(({ licenseInfo }: AppState) => licenseInfo);
    const { list } = useClusterList({ fromStore: true });
    const { record: currentUser } = useCurrentUser();

    const [loginUserName, setLoginUserName] = useLocalStorage(
        'deferred-user-logged-in'
    );

    const getData = (list: CcCluster[]) => {
        const data = {
            ...getClusterTypeCounts(list, ''),
            ...getClusterTypeDbNodesCounts(list),
            ...getNodeTypesCounts(list.map((c) => c.nodes).flat()),
            ...getNodeTypesCounts(
                list.map((c) => c.getDatabaseNodes()).flat(),
                '_db_node_count'
            ),
            ...getClusterVendorCount(list),
            ...getClusterVersionCount(list),
            ...getVendorVersions(list),
            ...getOsInfo(list),
        };
        return data;
    };

    const prepareData = (list: CcCluster[]) => {
        const params: any = getData(list);

        const containerNode =
            (list.some((c) => c.nodes.some((n) => n.container)) &&
                ';container_node') ||
            '';

        /**
         * Added for compatibility with old UI and data handlers
         */
        const legacyData = {
            //// <DEPRECATED> ------
            //// ------- Host counts by cluster type
            replhost: params['replication_cluster_nodes_count'],
            groupreplication_hosts:
                params['group_replication_cluster_nodes_count'],
            mysqlclusterhost: params['mysqlcluster_cluster_nodes_count'],
            mysqlsingle_hosts: params['mysql_single_cluster_nodes_count'],

            //// ------- Cluster type couts
            postgresql:
                (params['postgresql_single'] || 0) +
                (params['timescaledb'] || 0),
            groupreplication: params['group_replication'],
            mysqlsingle: params['mysql_single'],
            sqlserver:
                (params['mssql_single'] || 0) + (params['mssql_ao_async'] || 0),

            //// ------- Db node type counts
            redis_hosts: params['redis_db_node_count'],
            mongodbhost: params['mongo_db_node_count'],
            galerahost: params['galera_db_node_count'],
            postgresql_hosts:
                (params['postgres_db_node_count'] || 0) +
                (params['timescaledb_db_node_count'] || 0),
            elastic_hosts: params['elastic_db_node_count'],
            sqlserver_hosts: params['mssql_db_node_count'],

            redis_sentinel_hosts: params['sentinel_node_count'],
            haproxy: params['haproxy_node_count'],
            proxysql: params['proxysql_node_count'],
            maxscale: params['maxscale_node_count'],

            //// ------- Vendor counts
            vendor_percona: params['vendor_percona_count'],
            vendor_mariadb: params['vendor_mariadb_count'],
            vendor_oracle: params['vendor_oracle_count'],

            //// </DEPRECATED> ------
        };
        let paying = 0;
        if (licenseInfo?.license?.type === CcLicenseType.Community) {
            paying = 0;
        } else if (licenseInfo?.license?.type === CcLicenseType.Demo) {
            paying = 1;
        } else if (
            licenseInfo?.isLicenseActive() &&
            licenseInfo?.license?.payed === true
        ) {
            paying = 2;
        }
        const data: any = {
            ...legacyData,

            //// --- other fields
            version: `${AppConfig.VERSION || '0'}-${
                AppConfig.BUILD_NUMBER || 0
            }#${AppConfig.GIT_SHA}`,
            ccui: null,
            cc_address: window.location.hostname,
            extra_info: `${containerNode}`, // in cc1 can also be prefix "docker"
            uid_hash: CryptoJS.MD5(currentUser?.emailAddress || '').toString(),
            paying,
            ...params,
        };

        // remove keys with undefined values
        Object.keys(data).forEach((key) => {
            if (data[key] === undefined) {
                delete data[key];
            }
        });

        return {
            ...PING_DATA_PLACEHOLDER,
            ...data,
        };
    };

    /**
     * This was copied from old UI: https://github.com/severalnines/dc-ui/blob/9a746da18a1b9c2a601ccf9dd7a769f1c8ad4a5f/app/webroot/js/ClusterControl.js#L801
     * Note sure why ping was done this way, maybe to avoid CORS issues?
     *
     * @param list
     */
    const ping = async (list: CcCluster[]) => {
        const cmonVersion = await requestCMONVersion();
        const data = { ...prepareData(list), version_cmon: cmonVersion };

        const url = 'https://www.severalnines.com/service/cmon_reg.php?d=';

        const script = document.createElement('script');
        const scripts = document.getElementsByTagName('script')[0];

        script.type = 'text/javascript';
        script.async = true;
        script.src = url + encodeURIComponent(JSON.stringify(data));
        scripts.parentNode?.insertBefore(script, scripts);
    };

    useEffect(() => {
        if (loginUserName && list && list.length > 0) {
            (async () => {
                try {
                    await ping(list);
                } catch (e) {
                    log.error('Ping home failed', e);
                }
            })();

            setLoginUserName(null);
        }
    }, [loginUserName, list]);
}

/**
 * Count versions of all clusters
 * @param list
 */
function countClusterVersions(list: CcCluster[]) {
    return list.reduce((a, c) => {
        if (!c.version) {
            return a;
        }
        if (!a.hasOwnProperty(c.version)) {
            a[c.version] = 0;
        }
        a[c.version]++;
        return a;
    }, {} as { [key in string]: number });
}

/**
 * Count vendors of all clusters
 * @param list
 */
function countClusterVendors(list: CcCluster[]) {
    return list.reduce((a, c) => {
        if (!c.vendor) {
            return a;
        }
        if (!a.hasOwnProperty(c.vendor)) {
            a[c.vendor] = 0;
        }
        a[c.vendor]++;
        return a;
    }, {} as { [key in string]: number });
}

/**
 * Count amount of db nodes for each cluster type
 * @param list
 */
function countClusterDbNodesTypes(list: CcCluster[]) {
    return list.reduce((a, c) => {
        if (!a.hasOwnProperty(c.clusterType)) {
            a[c.clusterType] = 0;
        }
        a[c.clusterType] += c.getDatabaseNodes().length;
        return a;
    }, {} as { [key in CcClusterType]: number });
}

/**
 * Count cluster types
 * @param list
 */
function countClusterTypes(list: CcCluster[]) {
    return list.reduce((a, c) => {
        if (!a.hasOwnProperty(c.clusterType)) {
            a[c.clusterType] = 0;
        }
        a[c.clusterType]++;
        return a;
    }, {} as { [key in CcClusterType]: number });
}

/**
 * Count node types
 * @param nodes
 */
function countNodeTypes(nodes: CcNode[]) {
    return nodes.reduce((a, c) => {
        if (!c.nodetype) {
            return a;
        }
        if (!a.hasOwnProperty(c.nodetype)) {
            a[c.nodetype] = 0;
        }
        a[c.nodetype]++;
        return a;
    }, {} as { [key in CcNodeType]: number });
}

/**
 * Collects all vendors and versions from cluster
 * Result as vendors and versions in order accordingly to each other
 *
 * @param list
 */
const getVendorVersions = (list: CcCluster[]) => {
    const data = list.reduce(
        (a, c) => {
            if (c.vendor) {
                a.vendors.push(c.vendor);
                a.vendors_versions.push(c.version || '');
            }

            return a;
        },
        { vendors: [], vendors_versions: [] } as any
    );

    return {
        vendors: data.vendors.join(','),
        vendors_versions: data.vendors_versions.join(','),
    };
};

/**
 * Count versions of all clusters
 * @param list
 */
const getClusterVersionCount = (list: CcCluster[]) => {
    const counts = countClusterVersions(list);
    const version_data = Object.keys(counts).reduce((a, c) => {
        // remove everything besides 0-9
        const key = c.replace(/[^0-9]/g, '');
        a[`version_${key}`] = counts[c];
        return a;
    }, {} as any);

    return {
        ...version_data,
    };
};

/**
 * Count vendors of all clusters
 * @param list
 */
function getClusterVendorCount(list: CcCluster[]) {
    const vendorCounts = countClusterVendors(list);

    const data = VENDORS.reduce((a, c) => {
        a[`vendor_${c.toLowerCase()}_count`] = vendorCounts?.[c] || 0;
        return a;
    }, {} as any);

    return {
        ...data,
    };
}

/**
 * Count cluster types
 * @param list
 * @param suffix
 */
function getClusterTypeCounts(list: CcCluster[], suffix: string = '') {
    const typeCounts = countClusterTypes(list);

    return CLUSTER_TYPES.reduce((a, c) => {
        a[`${c.toLowerCase()}${suffix}`] = typeCounts?.[c] || 0;
        return a;
    }, {} as any);
}

/**
 * Count amount of db nodes for each cluster type
 * @param list
 * @param suffix
 */
function getClusterTypeDbNodesCounts(
    list: CcCluster[],
    suffix: string = '_cluster_nodes_count'
) {
    const counts = countClusterDbNodesTypes(list);

    return CLUSTER_TYPES.reduce((a, c) => {
        a[`${c.toLowerCase()}${suffix}`] = counts?.[c] || 0;
        return a;
    }, {} as any);
}

/**
 * Count node types
 * @param list
 * @param suffix
 */
function getNodeTypesCounts(
    list: CcNode[] = [],
    suffix: string = '_node_count'
) {
    const typeCounts = countNodeTypes(list);
    return NODE_TYPES.reduce((a, c) => {
        a[`${c.toLowerCase()}${suffix}`] = typeCounts?.[c] || 0;
        return a;
    }, {} as any);
}

/**
 * Get OS info from first controller node
 * @param list
 */
function getOsInfo(list: CcCluster[]) {
    const getDistribution = (c: CcCluster) =>
        c.nodes.find(
            (n) => n.nodetype === CcNodeType.CONTROLLER && n.distribution
        )?.distribution;

    const c = list.find((c) => getDistribution);
    const distribution = c && getDistribution(c);
    if (distribution) {
        return {
            os_name: distribution.name,
            os_codename: distribution.codename,
            os_release: distribution.release,
            os_type: distribution.type,
        };
    }
    return {};
}
