import StatusFormat, {
    StatusFormatProps,
} from '@severalnines/bar-frontend-components/build/lib/Format/StatusFormat';
import React from 'react';
import { StatusFormatStatus } from '@severalnines/bar-frontend-components/build/lib/Format/StatusFormat';

export default AdvisorStatusFormat;
export type AdvisorStatusFormatProps = Omit<StatusFormatProps, 'status'> & {
    status?: string;
};

function AdvisorStatusFormat({ status, ...rest }: AdvisorStatusFormatProps) {
    return (
        <StatusFormat
            status={getAdvisorStatusFormatStatus(status)}
            text={status || 'Unknown'}
            {...rest}
        />
    );
}

export function getAdvisorStatusFormatStatus(status?: string) {
    switch (status?.toLowerCase()) {
        case 'ok':
            return StatusFormatStatus.success;
        case 'warning':
            return StatusFormatStatus.warning;
        case 'critical':
            return StatusFormatStatus.error;
        case 'disabled':
            return StatusFormatStatus.default;
        default:
            return StatusFormatStatus.unknown;
    }
}
