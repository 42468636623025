import React, { useEffect, useState } from 'react';
import InstallBackupTool, { InstallBackupToolProps } from './InstallBackupTool';
import CcJob, { CcJobStatus } from '../../services/models/CcJob';
import { CmonJobInstanceCommand } from '../../services/cmon/models/CmonJobInstance';

export default InstallBackupToolInput;

export enum InstallBackupToolStatus {
    UNINSTALLED = 'UNINSTALLED',
    PROGRESS = 'PROGRESS',
    SUCCESS = 'SUCCESS',
    FAILED = 'FAILED',
    REINSTALL = 'REINSTALL',
}

export type InstallBackupToolInputProps = InstallBackupToolProps & {
    value?: InstallBackupToolStatus;
    onChange?: (value: InstallBackupToolStatus | undefined) => void;
    required?: boolean;
    onJobFinished?: (job: CcJob) => void;
    command:
        | CmonJobInstanceCommand.PGBACKREST
        | CmonJobInstanceCommand.PBMAGENT;
};

function InstallBackupToolInput({
    value,
    onChange,
    reinstall,
    required = false,
    onJobFinished,
    command,
    ...rest
}: InstallBackupToolInputProps) {
    const [status, setStatus] = useState<InstallBackupToolStatus | undefined>(
        value === InstallBackupToolStatus.SUCCESS
            ? value
            : value || reinstall
            ? InstallBackupToolStatus.REINSTALL
            : required
            ? InstallBackupToolStatus.UNINSTALLED
            : undefined
    );
    const handleFormOpen = () => {
        setStatus(InstallBackupToolStatus.PROGRESS);
    };
    const handleFinish = (job: CcJob) => {
        onJobFinished?.(job);
        if ([CcJobStatus.FAILED, CcJobStatus.ABORTED].includes(job.status)) {
            setStatus(InstallBackupToolStatus.FAILED);
        } else if (job.status === CcJobStatus.FINISHED) {
            setStatus(InstallBackupToolStatus.SUCCESS);
        }
    };

    const handleCancel = () => {
        setStatus(undefined);
    };

    const handleReset = () => {
        setStatus(undefined);
    };

    useEffect(() => {
        onChange?.(status);
    }, [status]);
    useEffect(() => {
        if (value !== status) {
            setStatus(status);
        }
    }, [value]);
    return (
        <InstallBackupTool
            {...rest}
            reinstall={reinstall}
            onFormOpen={handleFormOpen}
            onCancel={handleCancel}
            onFinish={handleFinish}
            onReset={handleReset}
        />
    );
}
