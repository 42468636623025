
import CcNode, { CcNodeProps } from '../../models/CcNode';


export interface CmonAgentHostProps  extends CcNodeProps {
    DbPassword?: boolean;
    DbUser?: boolean;
    apiKey?: boolean;
    queryConfFile?: boolean;

}



export default class CmonAgentHost extends CcNode {
    public DbPassword?: boolean;
    public DbUser?: boolean;
    public apiKey?: boolean;
    public queryConfFile?: boolean;


    constructor(props: CmonAgentHostProps) {
        super(props);
        this.DbPassword = props.DbPassword;
        this.DbUser = props.DbUser;
        this.apiKey = props.apiKey;
        this.queryConfFile = props.queryConfFile;

    }

}
