const mock = {
    class_name: 'CmonClusterInfo',
    cdt_path: '/',
    acl: 'user::rwx,group::rwx,other::---',
    tags: ['mariadb', 'production', 'galera'],
    cluster_auto_recovery: true,
    cluster_id: 1,
    cluster_name: 'MariaDB Galera Cluster',
    cluster_type: 'GALERA',
    configuration_file: '/etc/cmon.d/cmon_1.cnf',
    effective_privileges: 'rwx',
    log_file: '/var/log/cmon_1.log',
    maintenance_mode_active: false,
    managed: true,
    node_auto_recovery: true,
    rpc_version: '2.0',
    state: 'STARTED',
    status_text: 'All nodes are operational.',
    vendor: 'mariadb',
    version: '10.4',
    alarm_statistics: {
        class_name: 'CmonAlarmStatistics',
        cluster_id: 1,
        created_after: '1970-01-01T00:00:00.000Z',
        critical: 0,
        reported_after: '1970-01-01T00:00:00.000Z',
        warning: 0,
    },
    group_owner: {
        class_name: 'CmonGroup',
        cdt_path: '/groups',
        owner_user_id: 1,
        owner_user_name: 'system',
        owner_group_id: 1,
        owner_group_name: 'admins',
        acl: 'user::rwx,group::rwx,other::---',
        created: '2021-04-28T22:57:55.328Z',
        group_id: 1,
        group_name: 'admins',
    },
    hosts: [
        {
            class_name: 'CmonGaleraHost',
            cdt_path: '/MariaDB Galera Cluster',
            owner_user_id: 1,
            owner_user_name: 'system',
            owner_group_id: 1,
            owner_group_name: 'admins',
            acl: 'user::rwx,group::rwx,other::---',
            clusterid: 1,
            configfile: ['/etc/mysql/my.cnf'],
            connected: true,
            container: true,
            datadir: '/var/lib/mysql/',
            description: '',
            errorcode: 0,
            errormsg: 'Up and running.',
            hostId: 2,
            hostname: 'c2',
            hostname_internal: '10.0.8.12',
            hoststatus: 'CmonHostOnline',
            ip: '10.0.8.12',
            isgalera: true,
            lastseen: 1628603020,
            log_bin: 'ON',
            log_bin_basename: '/var/lib/mysql/binlog',
            logfile: '/var/log/mysql/mysqld.log',
            maintenance_mode: 'none',
            maintenance_mode_active: false,
            message: 'Up and running.',
            mysqlstatus: 0,
            nodeid: 2,
            nodetype: 'galera',
            performance_schema: false,
            pid: 6765,
            pidfile: '/var/lib/mysql/mysql.pid',
            pingstatus: 1,
            pingtime: 81,
            port: 3306,
            readonly: false,
            role: 'master',
            serverid: 1000,
            skip_name_resolve: false,
            slaves: [],
            socket: '/var/lib/mysql/mysql.sock',
            sshfailcount: 0,
            statereason: 'Setting to MYSQL_OK because the node is Synced',
            status: 0,
            stopped_at: '2021-06-14T10:16:08.447Z',
            timestamp: 1628603020,
            unique_id: 2,
            uptime: 950443,
            version: '10.4.18-MariaDB-1:10.4.18+maria~bionic-log',
            version_comment: 'mariadb.org binary distribution',
            wallclock: 1628602973,
            wallclocktimestamp: 1628602973,
            audit_log: {
                plugins: [],
            },
            distribution: {
                name: 'ubuntu',
                codename: 'bionic',
                release: '18.04',
                type: 'debian',
            },
            galera: {
                certsdepsdistance: 0,
                cluster_address: 'gcomm://10.0.8.12,10.0.8.13,c4',
                clustername: 'MariaDB_Galera_Cluster',
                clustersize: 3,
                connected: true,
                flowctrlpaused: 0,
                flowctrlrecv: 0,
                flowctrlsent: 0,
                galerastatus: 'Primary',
                gcache_recover: true,
                lastcommitted: 27245,
                localrecvqueue: 0,
                localrecvqueueavg: 0,
                localsendqueue: 0,
                localsendqueueavg: 0,
                localstatus: 4,
                localstatusstr: 'Synced',
                provider: '/usr/lib/galera/libgalera_smm.so',
                ready: 'ON',
                segmentid: 0,
                socketssl: true,
                sst_method: 'mariabackup',
            },
            replication_master: {
                binlog_do_db: '',
                binlog_ignore_db: '',
                exec_gtid: '1000-1000-20447',
                file: 'binlog.000463',
                position: '138754',
                semisync_status: 'OFF',
            },
            replication_slave: {
                exec_master_log_pos: 20792392,
                executed_gtid_set: '',
                gtid_binlog_pos: '',
                gtid_io_pos: '',
                last_io_errno: '',
                last_io_error: '',
                linkstatus: 7,
                master_cluster_id: '-1',
                master_host: '',
                master_log_file: '',
                master_port: '',
                read_master_log_pos: '0',
                relay_master_log_file: '',
                retrieved_gtid_set: '',
                seconds_behind_master: '',
                semisync_status: 'OFF',
                slave_io_running: '',
                slave_io_state: '',
                slave_sql_running: '',
                slave_sql_state: '',
                status: '',
                using_gtid: '',
            },
            slow_query: {
                log_not_using_idxs: 'OFF',
                long_query_time: '0.500000',
                slow_query_log: 'ON',
                slow_query_log_file: '/var/log/mysql/mysql-slow.log',
            },
            ssl_certs: {
                replication: {
                    ca: '',
                    id: 6,
                    key: '/etc/mysql/certs/galera_rep.key',
                    path: '/etc/mysql/certs/galera_rep.crt',
                },
                server: {
                    ca: '/etc/mysql/certs/server_ca.crt',
                    id: 4,
                    key: '/etc/mysql/certs/server.key',
                    path: '/etc/mysql/certs/server.crt',
                    ssl_enabled: true,
                },
            },
        },
        {
            class_name: 'CmonHost',
            cdt_path: '/MariaDB Galera Cluster',
            owner_user_id: 1,
            owner_user_name: 'system',
            owner_group_id: 1,
            owner_group_name: 'admins',
            acl: 'user::rwx,group::rwx,other::---',
            clusterid: 1,
            configfile: '/etc/cmon.d/cmon_1.cnf',
            connected: true,
            container: false,
            hostId: 3,
            hostname: '143.198.228.20',
            hoststatus: 'CmonHostOnline',
            ip: '143.198.228.20',
            lastseen: 1628603003,
            logfile: '/var/log/cmon_1.log',
            maintenance_mode: 'none',
            maintenance_mode_active: false,
            message: 'Up and running.',
            nodetype: 'controller',
            pid: 15943,
            pingstatus: -1,
            pingtime: -1,
            port: 9500,
            role: 'controller',
            status: 10,
            timestamp: 1628603003,
            unique_id: 3,
            uptime: 5940,
            version: '1.9.1.4712',
            wallclock: 1628602973,
            wallclocktimestamp: 1628602973,
            distribution: {
                name: 'ubuntu',
                codename: 'bionic',
                release: '18.04',
                type: 'debian',
            },
        },
        {
            class_name: 'CmonGaleraHost',
            cdt_path: '/MariaDB Galera Cluster',
            owner_user_id: 1,
            owner_user_name: 'system',
            owner_group_id: 1,
            owner_group_name: 'admins',
            acl: 'user::rwx,group::rwx,other::---',
            clusterid: 1,
            configfile: ['/etc/mysql/my.cnf'],
            connected: true,
            container: true,
            datadir: '/var/lib/mysql/',
            description: '',
            errorcode: 0,
            errormsg: 'Up and running.',
            hostId: 8,
            hostname: 'c3',
            hostname_internal: '10.0.8.13',
            hoststatus: 'CmonHostOnline',
            ip: '10.0.8.13',
            isgalera: true,
            lastseen: 1628603020,
            log_bin: 'ON',
            log_bin_basename: '/var/lib/mysql/binlog',
            logfile: '/var/log/mysql/mysqld.log',
            maintenance_mode: 'none',
            maintenance_mode_active: false,
            message: 'Up and running.',
            mysqlstatus: 0,
            nodeid: 8,
            nodetype: 'galera',
            performance_schema: false,
            pid: 6238,
            pidfile: '/var/lib/mysql/mysql.pid',
            pingstatus: 1,
            pingtime: 143,
            port: 3306,
            readonly: true,
            role: 'master',
            serverid: 1000,
            skip_name_resolve: false,
            slaves: [],
            socket: '/var/lib/mysql/mysql.sock',
            sshfailcount: 0,
            statereason: 'Setting to MYSQL_OK because the node is Synced',
            status: 0,
            stopped_at: '2021-06-14T10:17:08.576Z',
            timestamp: 1628603020,
            unique_id: 8,
            uptime: 950281,
            version: '10.4.18-MariaDB-1:10.4.18+maria~bionic-log',
            version_comment: 'mariadb.org binary distribution',
            wallclock: 1628602973,
            wallclocktimestamp: 1628602973,
            audit_log: {
                plugins: [],
            },
            distribution: {
                name: 'ubuntu',
                codename: 'bionic',
                release: '18.04',
                type: 'debian',
            },
            galera: {
                certsdepsdistance: 0,
                cluster_address: 'gcomm://10.0.8.12,10.0.8.13,c4',
                clustername: 'MariaDB_Galera_Cluster',
                clustersize: 3,
                connected: true,
                flowctrlpaused: 0,
                flowctrlrecv: 0,
                flowctrlsent: 0,
                galerastatus: 'Primary',
                gcache_recover: true,
                lastcommitted: 27245,
                localrecvqueue: 0,
                localrecvqueueavg: 0,
                localsendqueue: 0,
                localsendqueueavg: 0,
                localstatus: 4,
                localstatusstr: 'Synced',
                provider: '/usr/lib/galera/libgalera_smm.so',
                ready: 'ON',
                segmentid: 0,
                socketssl: true,
                sst_method: 'mariabackup',
            },
            replication_master: {
                binlog_do_db: '',
                binlog_ignore_db: '',
                exec_gtid: '1000-1000-38575',
                file: 'binlog.000455',
                position: '214854',
                semisync_status: 'OFF',
            },
            replication_slave: {
                exec_master_log_pos: 20792392,
                executed_gtid_set: '',
                gtid_binlog_pos: '',
                gtid_io_pos: '',
                last_io_errno: '',
                last_io_error: '',
                linkstatus: 7,
                master_cluster_id: '-1',
                master_host: '',
                master_log_file: '',
                master_port: '',
                read_master_log_pos: '0',
                relay_master_log_file: '',
                retrieved_gtid_set: '',
                seconds_behind_master: '',
                semisync_status: 'OFF',
                slave_io_running: '',
                slave_io_state: '',
                slave_sql_running: '',
                slave_sql_state: '',
                status: '',
                using_gtid: '',
            },
            slow_query: {
                log_not_using_idxs: 'OFF',
                long_query_time: '0.500000',
                slow_query_log: 'ON',
                slow_query_log_file: '/var/log/mysql/mysql-slow.log',
            },
            ssl_certs: {
                replication: {
                    ca: '',
                    id: 6,
                    key: '/etc/mysql/certs/galera_rep.key',
                    path: '/etc/mysql/certs/galera_rep.crt',
                },
                server: {
                    ca: '/etc/mysql/certs/server_ca.crt',
                    id: 4,
                    key: '/etc/mysql/certs/server.key',
                    path: '/etc/mysql/certs/server.crt',
                    ssl_enabled: true,
                },
            },
        },
        {
            class_name: 'CmonGaleraHost',
            cdt_path: '/MariaDB Galera Cluster',
            owner_user_id: 1,
            owner_user_name: 'system',
            owner_group_id: 1,
            owner_group_name: 'admins',
            acl: 'user::rwx,group::rwx,other::---',
            clusterid: 1,
            configfile: ['/etc/mysql/my.cnf'],
            connected: true,
            container: true,
            datadir: '/var/lib/mysql/',
            description: '',
            errorcode: 0,
            errormsg: 'Up and running.',
            hostId: 13,
            hostname: 'c4',
            hostname_internal: '10.0.8.14',
            hoststatus: 'CmonHostOnline',
            ip: '10.0.8.14',
            isgalera: true,
            lastseen: 1628603020,
            log_bin: 'ON',
            log_bin_basename: '/var/lib/mysql/binlog',
            logfile: '/var/log/mysql/mysqld.log',
            maintenance_mode: 'none',
            maintenance_mode_active: false,
            message: 'Up and running.',
            mysqlstatus: 0,
            nodeid: 13,
            nodetype: 'galera',
            performance_schema: false,
            pid: 6000,
            pidfile: '/var/lib/mysql/mysql.pid',
            pingstatus: 1,
            pingtime: 60,
            port: 3306,
            readonly: true,
            role: 'master',
            serverid: 1000,
            skip_name_resolve: false,
            slaves: [],
            socket: '/var/lib/mysql/mysql.sock',
            sshfailcount: 0,
            statereason: 'Setting to MYSQL_OK because the node is Synced',
            status: 0,
            stopped_at: '2021-06-14T10:22:41.740Z',
            timestamp: 1628603020,
            unique_id: 13,
            uptime: 950240,
            version: '10.4.18-MariaDB-1:10.4.18+maria~bionic-log',
            version_comment: 'mariadb.org binary distribution',
            wallclock: 1628602973,
            wallclocktimestamp: 1628602973,
            audit_log: {
                plugins: [],
            },
            distribution: {
                name: 'ubuntu',
                codename: 'bionic',
                release: '18.04',
                type: 'debian',
            },
            galera: {
                certsdepsdistance: 0,
                cluster_address: 'gcomm://10.0.8.12,10.0.8.13,c4',
                clustername: 'MariaDB_Galera_Cluster',
                clustersize: 3,
                connected: true,
                flowctrlpaused: 0,
                flowctrlrecv: 0,
                flowctrlsent: 0,
                galerastatus: 'Primary',
                gcache_recover: true,
                lastcommitted: 27245,
                localrecvqueue: 0,
                localrecvqueueavg: 0,
                localsendqueue: 0,
                localsendqueueavg: 0,
                localstatus: 4,
                localstatusstr: 'Synced',
                provider: '/usr/lib/galera/libgalera_smm.so',
                ready: 'ON',
                segmentid: 0,
                socketssl: true,
                sst_method: 'mariabackup',
            },
            replication_master: {
                binlog_do_db: '',
                binlog_ignore_db: '',
                exec_gtid: '1000-1000-40129',
                file: 'binlog.000454',
                position: '214854',
                semisync_status: 'OFF',
            },
            replication_slave: {
                exec_master_log_pos: 20792392,
                executed_gtid_set: '',
                gtid_binlog_pos: '',
                gtid_io_pos: '',
                last_io_errno: '',
                last_io_error: '',
                linkstatus: 7,
                master_cluster_id: '-1',
                master_host: '',
                master_log_file: '',
                master_port: '',
                read_master_log_pos: '0',
                relay_master_log_file: '',
                retrieved_gtid_set: '',
                seconds_behind_master: '',
                semisync_status: 'OFF',
                slave_io_running: '',
                slave_io_state: '',
                slave_sql_running: '',
                slave_sql_state: '',
                status: '',
                using_gtid: '',
            },
            slow_query: {
                log_not_using_idxs: 'OFF',
                long_query_time: '0.500000',
                slow_query_log: 'ON',
                slow_query_log_file: '/var/log/mysql/mysql-slow.log',
            },
            ssl_certs: {
                replication: {
                    ca: '',
                    id: 6,
                    key: '/etc/mysql/certs/galera_rep.key',
                    path: '/etc/mysql/certs/galera_rep.crt',
                },
                server: {
                    ca: '/etc/mysql/certs/server_ca.crt',
                    id: 4,
                    key: '/etc/mysql/certs/server.key',
                    path: '/etc/mysql/certs/server.crt',
                    ssl_enabled: true,
                },
            },
        },
        {
            class_name: 'CmonPrometheusHost',
            cdt_path: '/MariaDB Galera Cluster',
            owner_user_id: 1,
            owner_user_name: 'system',
            owner_group_id: 1,
            owner_group_name: 'admins',
            acl: 'user::rwx,group::rwx,other::---',
            clusterid: 1,
            configfile: '/etc/prometheus/prometheus.yml',
            container: false,
            data_retention: '15d',
            datadir: '/var/lib/prometheus',
            hostId: 3,
            hostname: '143.198.228.20',
            hoststatus: 'CmonHostOnline',
            ip: '143.198.228.20',
            lastseen: 1628603003,
            logfile: '/var/log/prometheus/prometheus.log',
            maintenance_mode: 'none',
            maintenance_mode_active: false,
            message: "Process 'prometheus' is running.",
            nodetype: 'prometheus',
            pid: 26117,
            pingstatus: -1,
            pingtime: -1,
            port: 9090,
            scrape_interval: '10s',
            statereason: 'Prometheus is available.',
            status: 10,
            timestamp: 1628603003,
            unique_id: 14,
            version: '2.20.1',
            wallclock: 1628602973,
            wallclocktimestamp: 1628602973,
            configuration: [
                {
                    arguments: '',
                    job: 'prometheus',
                    scrape_interval: '2s',
                },
                {
                    arguments: '',
                    job: 'node',
                    scrape_interval: '10s',
                },
                {
                    arguments: '',
                    job: 'process',
                    scrape_interval: '10s',
                },
                {
                    arguments:
                        '--collect.perf_schema.eventswaits --collect.perf_schema.file_events --collect.perf_schema.file_instances --collect.perf_schema.indexiowaits --collect.perf_schema.tableiowaits --collect.perf_schema.tablelocks --collect.info_schema.tablestats --collect.info_schema.processlist --collect.binlog_size --collect.global_status --collect.global_variables --collect.info_schema.innodb_metrics --collect.slave_status --collect.info_schema.userstats',
                    job: 'mysqld',
                    scrape_interval: '10s',
                },
                {
                    arguments: '',
                    job: 'proxysql',
                    scrape_interval: '10s',
                },
                {
                    arguments: '',
                    job: 'haproxy',
                    scrape_interval: '10s',
                },
            ],
            distribution: {
                name: 'ubuntu',
                codename: 'bionic',
                release: '18.04',
                type: 'debian',
            },
            exporters: [
                {
                    address: '143.198.228.20:9011',
                    job: 'process',
                    monitored_host: '',
                    up: true,
                    version: '0.10.10',
                },
                {
                    address: '143.198.228.20:9100',
                    job: 'node',
                    monitored_host: '',
                    up: true,
                    version: '1.0.1',
                },
                {
                    address: 'c11:42004',
                    job: 'proxysql',
                    monitored_host: 'c11:6032',
                    up: true,
                    version: '1.1.0',
                },
                {
                    address: 'c11:9011',
                    job: 'process',
                    monitored_host: '',
                    up: true,
                    version: '0.10.10',
                },
                {
                    address: 'c11:9100',
                    job: 'node',
                    monitored_host: '',
                    up: true,
                    version: '1.0.1',
                },
                {
                    address: 'c2:9011',
                    job: 'process',
                    monitored_host: '',
                    up: false,
                    version: '0.10.10',
                },
                {
                    address: 'c2:9100',
                    job: 'node',
                    monitored_host: '',
                    up: false,
                    version: '1.0.1',
                },
                {
                    address: 'c2:9104',
                    job: 'mysqld',
                    monitored_host: 'c2:3306',
                    up: true,
                    version: '0.11.0',
                },
                {
                    address: 'c3:9011',
                    job: 'process',
                    monitored_host: '',
                    up: true,
                    version: '0.10.10',
                },
                {
                    address: 'c3:9100',
                    job: 'node',
                    monitored_host: '',
                    up: false,
                    version: '1.0.1',
                },
                {
                    address: 'c3:9104',
                    job: 'mysqld',
                    monitored_host: 'c3:3306',
                    up: true,
                    version: '0.11.0',
                },
                {
                    address: 'c4:9011',
                    job: 'process',
                    monitored_host: '',
                    up: true,
                    version: '0.10.10',
                },
                {
                    address: 'c4:9100',
                    job: 'node',
                    monitored_host: '',
                    up: false,
                    version: '1.0.1',
                },
                {
                    address: 'c4:9104',
                    job: 'mysqld',
                    monitored_host: 'c4:3306',
                    up: true,
                    version: '0.11.0',
                },
            ],
        },
        {
            class_name: 'CmonProxySqlHost',
            cdt_path: '/MariaDB Galera Cluster',
            owner_user_id: 1,
            owner_user_name: 'system',
            owner_group_id: 1,
            owner_group_name: 'admins',
            acl: 'user::rwx,group::rwx,other::---',
            admin_user: 'proxysql-admin',
            clusterid: 1,
            cmdline: 'systemctl start proxysql',
            configfile: '/etc/proxysql.cnf',
            connected: true,
            container: true,
            datadir: '/var/lib/proxysql/',
            hostId: 14,
            hostname: 'c11',
            hoststatus: 'CmonHostOnline',
            ip: '10.0.8.21',
            is_clustered: false,
            lastseen: 1628603016,
            lb_policy: 'rw-split',
            listening_port: 6033,
            logfile: '/var/lib/proxysql/proxysql.log',
            maintenance_mode: 'none',
            maintenance_mode_active: false,
            message: "Process 'proxysql' is running.",
            monitor_user: 'proxysql-monitor',
            nodetype: 'proxysql',
            pid: 506,
            pidfile: '/var/lib/proxysql/proxysql.pid',
            pingstatus: 1,
            pingtime: 66,
            port: 6032,
            sshfailcount: 0,
            statereason: "Process 'proxysql' is running.",
            status: 10,
            timestamp: 1628603016,
            unique_id: 16,
            uptime: 951176,
            version: '2.0.18',
            wallclock: 1628602973,
            wallclocktimestamp: 1628602973,
            backend_info: {
                backend_servers: [
                    {
                        class_name: 'CmonProxySqlServer',
                        Bytes_data_recv: '0',
                        Bytes_data_sent: '0',
                        ConnERR: '0',
                        ConnFree: '0',
                        ConnUsed: '0',
                        Latency: '209',
                        Queries: '0',
                        comment: 'read server',
                        compression: '0',
                        hostgroup_id: '20',
                        hostname: 'c2',
                        max_connections: '100',
                        max_latency_ms: '0',
                        max_replication_lag: '10',
                        port: '3306',
                        status: 'ONLINE',
                        use_ssl: '0',
                        weight: '1',
                    },
                    {
                        class_name: 'CmonProxySqlServer',
                        Bytes_data_recv: '0',
                        Bytes_data_sent: '0',
                        ConnERR: '0',
                        ConnFree: '0',
                        ConnUsed: '0',
                        Latency: '174',
                        Queries: '0',
                        comment: 'read server',
                        compression: '0',
                        hostgroup_id: '20',
                        hostname: 'c3',
                        max_connections: '100',
                        max_latency_ms: '0',
                        max_replication_lag: '10',
                        port: '3306',
                        status: 'ONLINE',
                        use_ssl: '0',
                        weight: '1',
                    },
                    {
                        class_name: 'CmonProxySqlServer',
                        Bytes_data_recv: '0',
                        Bytes_data_sent: '0',
                        ConnERR: '0',
                        ConnFree: '0',
                        ConnUsed: '0',
                        Latency: '250',
                        Queries: '0',
                        comment: 'read server',
                        compression: '0',
                        hostgroup_id: '20',
                        hostname: 'c4',
                        max_connections: '100',
                        max_latency_ms: '0',
                        max_replication_lag: '10',
                        port: '3306',
                        status: 'ONLINE',
                        use_ssl: '0',
                        weight: '1',
                    },
                    {
                        class_name: 'CmonProxySqlServer',
                        Bytes_data_recv: '0',
                        Bytes_data_sent: '0',
                        ConnERR: '0',
                        ConnFree: '0',
                        ConnUsed: '0',
                        Latency: '209',
                        Queries: '0',
                        comment: 'read and write server',
                        compression: '0',
                        hostgroup_id: '10',
                        hostname: 'c2',
                        max_connections: '100',
                        max_latency_ms: '0',
                        max_replication_lag: '10',
                        port: '3306',
                        status: 'ONLINE',
                        use_ssl: '0',
                        weight: '1',
                    },
                    {
                        class_name: 'CmonProxySqlServer',
                        Bytes_data_recv: null,
                        Bytes_data_sent: null,
                        ConnERR: null,
                        ConnFree: null,
                        ConnUsed: null,
                        Latency: null,
                        Queries: null,
                        comment: 'read and write server',
                        compression: '0',
                        hostgroup_id: '10',
                        hostname: 'c3',
                        max_connections: '100',
                        max_latency_ms: '0',
                        max_replication_lag: '10',
                        port: '3306',
                        status: 'ONLINE',
                        use_ssl: '0',
                        weight: '1',
                    },
                    {
                        class_name: 'CmonProxySqlServer',
                        Bytes_data_recv: null,
                        Bytes_data_sent: null,
                        ConnERR: null,
                        ConnFree: null,
                        ConnUsed: null,
                        Latency: null,
                        Queries: null,
                        comment: 'read and write server',
                        compression: '0',
                        hostgroup_id: '10',
                        hostname: 'c4',
                        max_connections: '100',
                        max_latency_ms: '0',
                        max_replication_lag: '10',
                        port: '3306',
                        status: 'ONLINE',
                        use_ssl: '0',
                        weight: '1',
                    },
                ],
            },
            distribution: {
                name: 'ubuntu',
                codename: 'bionic',
                release: '18.04',
                type: 'debian',
            },
        },
    ],
    job_statistics: {
        class_name: 'CmonJobStatistics',
        cluster_id: 1,
        by_state: {
            ABORTED: 0,
            DEFINED: 0,
            DEQUEUED: 0,
            FAILED: 7,
            FINISHED: 25,
            PAUSED: 3,
            RUNNING: 0,
            SCHEDULED: 3,
        },
    },
    owner: {
        class_name: 'CmonUser',
        cdt_path: '/',
        acl: 'user::rwx,group::r--,other::r--',
        disabled: false,
        suspended: false,
        groups: [],
        timezone: {
            class_name: 'CmonTimeZone',
            name: 'Coordinated Universal Time',
            abbreviation: 'UTC',
            offset: 0,
            use_dst: false,
        },
    },
};
export default mock;
