import WizardConfiguration from '@severalnines/bar-frontend-components/build/lib/Navigation/Wizard/WizardConfiguration';
import React, { useState, useEffect } from 'react';
import WizardSelectCardStep from '@severalnines/bar-frontend-components/build/lib/Navigation/Wizard/WizardSelectCardStep';

import CcCluster from '../../services/models/CcCluster';

import CreateCertificateForm from './CreateCertificateForm';
import UseExistingCertificate from './UseExistingCertificate';
import { Form } from 'antd';
import ModalDefaultForm from '../../common/ModalDefaultForm';

export enum SSLModalWizardStepActionType {
    CREATE = 'Create',
    IMPORT = 'Import',
}

export enum SSLModalWizardStep {
    ACTION = 'action',
    FORM_CREATE = 'form_create',
    FORM_IMPORT = 'form_import',
}

export type SSLModalWizardProps = {
    activeStep?: SSLModalWizardStep;
    cluster?: CcCluster;
    galera?: boolean;
    onCancel?: () => void;
    onSuccess?: () => void;
};
export default function SSLModalWizard({
    cluster,
    galera,
    onSuccess,
    onCancel,
}: SSLModalWizardProps) {
    const [form] = Form.useForm();

    const [title, setTitle] = useState<string>('');

    const [step, setStep] = useState<SSLModalWizardStep>(
        SSLModalWizardStep.ACTION
    );

    useEffect(() => {
        if (step === SSLModalWizardStep.ACTION) {
            setTitle?.('');
        }
    }, [step]);

    const handleActionSelect = (value: SSLModalWizardStepActionType) => {
        if (value === SSLModalWizardStepActionType.CREATE) {
            setTitle?.('Create certificate');
            setStep(SSLModalWizardStep.FORM_CREATE);
        } else {
            setTitle?.('Use existing certificate');
            setStep(SSLModalWizardStep.FORM_IMPORT);
        }
    };

    return (
        <ModalDefaultForm
            title={title}
            form={form}
            defaultVisible={true}
            showConfirmClose={true}
            onCancel={onCancel}
        >
            <WizardConfiguration
                activeStep={step}
                steps={[
                    {
                        step: SSLModalWizardStep.ACTION,
                        content: (
                            <WizardSelectCardStep
                                title={
                                    galera
                                        ? 'Galera SSL Encryption'
                                        : 'SSL Encryption'
                                }
                                description=""
                                onSelect={(value) =>
                                    handleActionSelect(
                                        value as SSLModalWizardStepActionType
                                    )
                                }
                            >
                                <WizardSelectCardStep.Item
                                    icon={
                                        <img
                                            src={require('./img-service-deploy.svg')}
                                            alt="Create certificate"
                                        />
                                    }
                                    backgroundType="squares"
                                    title="Create certificate"
                                    action={SSLModalWizardStepActionType.CREATE}
                                    description="Create new SSL certificates and keys (with OpenSSL)."
                                    buttonTitle={'Continue'}
                                />
                                <WizardSelectCardStep.Item
                                    icon={
                                        <img
                                            src={require('./img-service-import.svg')}
                                            alt="Use existing certificate"
                                        />
                                    }
                                    backgroundType="circles"
                                    title="Use existing certificate"
                                    action={SSLModalWizardStepActionType.IMPORT}
                                    description='View your existing SSL certificates and keys in the "Key Management"  page.'
                                    buttonTitle={'Continue'}
                                />
                            </WizardSelectCardStep>
                        ),
                    },
                    {
                        step: SSLModalWizardStep.FORM_CREATE,
                        content: (
                            <CreateCertificateForm
                                cluster={cluster}
                                galera={galera}
                                onCancel={onSuccess}
                                onSuccess={onCancel}
                            />
                        ),
                    },
                    {
                        step: SSLModalWizardStep.FORM_IMPORT,
                        content: (
                            <UseExistingCertificate
                                cluster={cluster}
                                galera={galera}
                                onCancel={onSuccess}
                                onSuccess={onCancel}
                            />
                        ),
                    },
                ]}
            />
        </ModalDefaultForm>
    );
}
