import React from 'react';
import './AlarmSeverityFormat.less';

import CcAlarm, { CcAlarmSeverity } from '../../services/models/CcAlarm';
import StatusFormat, {
    StatusFormatStatus,
    StatusFormatType,
} from '@severalnines/bar-frontend-components/build/lib/Format/StatusFormat';

export default AlarmSeverityFormat;

export function getAlarmSeverityFormatType(severityName?: CcAlarmSeverity) {
    switch (severityName) {
        case CcAlarmSeverity.ALARM_OK:
            return StatusFormatStatus.success;
        case CcAlarmSeverity.ALARM_CRITICAL:
            return StatusFormatStatus.error;
        case CcAlarmSeverity.ALARM_WARNING:
            return StatusFormatStatus.warning;
        case CcAlarmSeverity.ALARM_UNDEFINED:
        default:
            return StatusFormatStatus.info;
    }
}

export function getAlarmSeverityFormatText(severityName?: CcAlarmSeverity) {
    switch (severityName) {
        case CcAlarmSeverity.ALARM_OK:
            return 'Ok';
        case CcAlarmSeverity.ALARM_CRITICAL:
            return 'Critical';
        case CcAlarmSeverity.ALARM_WARNING:
            return 'Warning';
        case CcAlarmSeverity.ALARM_UNDEFINED:
        default:
            return 'Unknown';
    }
}

export type AlarmSeverityFormatProps = {
    alarm: CcAlarm;
    type?: StatusFormatType;
};

function AlarmSeverityFormat({
    alarm,
    type,
    ...rest
}: AlarmSeverityFormatProps) {
    return (
        <StatusFormat
            className="AlarmSeverityFormat"
            status={getAlarmSeverityFormatType(
                alarm.severityName as CcAlarmSeverity
            )}
            text={getAlarmSeverityFormatText(
                alarm.severityName as CcAlarmSeverity
            )}
            type={type}
            {...rest}
        />
    );
}
