import CmonPrometheusHost, {
    CmonPrometheusHostProps,
} from '../cmon/models/CmonPrometheusHost';
import CcPrometheusExporter, {
    CcPrometheusExporterProps,
} from './CcPrometheusExporter';
import { groupCollectionBy } from '../../common/utils/aggregating';

export interface CcPrometheusNodeProps extends CmonPrometheusHostProps {
    exporters?: CcPrometheusExporterProps[];
}

export default class CcPrometheusNode extends CmonPrometheusHost {
    public readonly exporters: CcPrometheusExporter[];
    constructor(props: CcPrometheusNodeProps) {
        super(props);
        this.exporters = props.exporters
            ? props.exporters.map((e) => new CcPrometheusExporter(e))
            : [];
    }

    getExportersGroupedByJob() {
        return groupCollectionBy(this.exporters, 'job');
    }
}
