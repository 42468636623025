import React, { useMemo, useCallback } from 'react';
import { Col, Row, Space } from 'antd';
import { Form } from 'antd';
import { FormInstance } from 'antd/lib/form';
import CcCluster from '../../../../../services/models/CcCluster';
import { getHostWithDesc } from '../../../../Nodes/NodeFormat';
import { CcNodeType } from '../../../../../services/models/CcNode';
import SshCheckableNodesAutocomplete from '../../../../../common/DataEntry/SshCheckeableNodesAutocomplete';
import { DefaultOptionType } from 'rc-select/lib/Select';
import { getTopologyValidator } from '../../../loadBalancerHelpers';

type ImportMaxScaleWhereProps = {
    selectedCluster?: CcCluster;
    form: FormInstance;
};

export default ImportMaxScaleWhere;

function ImportMaxScaleWhere({
    selectedCluster,
    form,
}: ImportMaxScaleWhereProps) {
    const getOptions = useCallback((): DefaultOptionType[] | undefined => {
        return selectedCluster
            ?.getHosts()
            .filter((h) => !h.types.includes(CcNodeType.MAXSCALE))
            .map((host) => {
                return {
                    value: `${host.hostname}`,
                    label: getHostWithDesc(host),
                };
            });
    }, [selectedCluster]);

    const options: DefaultOptionType[] | undefined = useMemo(
        () => getOptions(),
        [selectedCluster]
    );

    return (
        <div style={{ minHeight: 450 }}>
            <Row>
                <Col span={24}>
                    <h3>Where to install</h3>
                </Col>
            </Row>
            <Row gutter={[24, 0]}>
                <Col span={24}>
                    <Form.Item
                        name={['maxScaleWhere', 'address']}
                        label={<Space>MaxScale Address</Space>}
                        rules={[
                            {
                                required: true,
                                message: 'Please select MaxScale Address.',
                            },
                        ]}
                    >
                        <SshCheckableNodesAutocomplete
                            clusterId={selectedCluster?.clusterId}
                            emptyState={null}
                            singleNode={true}
                            formProps={{
                                primaryTitle: null,
                                primaryFormItemProps: {
                                    withLessMarginBottom: true,
                                },
                            }}
                            autocompleteOptions={options}
                        />
                    </Form.Item>
                </Col>
            </Row>
        </div>
    );
}

export function getImportMaxScaleWhereValidation(form: FormInstance) {
    return [
        ['maxScaleWhere', 'address'],
        getTopologyValidator(form, ['maxScaleWhere', 'address']),
    ];
}
