import React from 'react';
import { Form } from 'antd';
import { Col, Row, Space, Input, InputNumber } from 'antd';

export const haProxyAdvancedValidation = [
    ['haProxyAdvanced', 'statsSocket'],
    ['haProxyAdvanced', 'adminPort'],
    ['haProxyAdvanced', 'adminUser'],
    ['haProxyAdvanced', 'adminPassword'],
    ['haProxyAdvanced', 'backendNameRW'],
    ['haProxyAdvanced', 'backendNameRO'],
    ['haProxyAdvanced', 'timeoutServer'],
    ['haProxyAdvanced', 'timeoutClient'],
    ['haProxyAdvanced', 'maxConnectionsFrontend'],
    ['haProxyAdvanced', 'maxConnectionsBackend'],
    ['haProxyAdvanced', 'xintedAllow'],
];
export const haProxyAdvancedInitial = {
    haProxyAdvanced: {
        statsSocket: '/var/run/haproxy.socket',
        adminPort: 9600,
        adminUser: 'admin',
        adminPassword: 'admin',
        backendNameRW: 'haproxy_3307_rw',
        backendNameRO: 'haproxy_3308_ro',
        timeoutServer: 10800,
        timeoutClient: 10800,
        maxConnectionsFrontend: 8192,
        maxConnectionsBackend: 64,
        xintedAllow: '0.0.0.0/0',
    },
};
export default CreateHAProxyAdvanced;

function CreateHAProxyAdvanced() {
    return (
        <div style={{ minHeight: 450 }}>
            <Row gutter={[24, 0]}>
                <Col span={24}>
                    <h3>Advanced settings</h3>
                </Col>
            </Row>
            <Row gutter={[24, 0]}>
                <Col span={12}>
                    <Form.Item
                        name={['haProxyAdvanced', 'statsSocket']}
                        label={<Space>Stats socket</Space>}
                        rules={[
                            {
                                required: true,
                                message: 'Enter stats socket.',
                            },
                        ]}
                    >
                        <Input placeholder="Enter stats socket."></Input>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        name={['haProxyAdvanced', 'adminPort']}
                        label={<Space>Admin port</Space>}
                        rules={[
                            {
                                required: true,
                                message: 'Enter admin port.',
                            },
                        ]}
                    >
                        <InputNumber
                            min={0}
                            style={{ width: '100%' }}
                            placeholder="Enter admin port."
                        ></InputNumber>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        name={['haProxyAdvanced', 'adminUser']}
                        label={<Space>Admin user</Space>}
                        rules={[
                            {
                                required: true,
                                message: 'Enter admin user.',
                            },
                        ]}
                    >
                        <Input placeholder="Enter admin user."></Input>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        name={['haProxyAdvanced', 'adminPassword']}
                        label={<Space>Admin password</Space>}
                        rules={[
                            {
                                required: true,
                                message: 'Enter admin password.',
                            },
                        ]}
                    >
                        <Input placeholder="Enter admin password."></Input>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        name={['haProxyAdvanced', 'backendNameRW']}
                        label={<Space>Backend name (RW)</Space>}
                        rules={[
                            {
                                required: true,
                                message: 'Enter backend name (RW).',
                            },
                        ]}
                    >
                        <Input placeholder="Enter backend name (RW)."></Input>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        name={['haProxyAdvanced', 'backendNameRO']}
                        label={<Space>Backend name (RO)</Space>}
                        rules={[
                            {
                                required: true,
                                message: 'Enter backend name (RO).',
                            },
                        ]}
                    >
                        <Input placeholder="Enter backend name (RO)."></Input>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        name={['haProxyAdvanced', 'timeoutServer']}
                        label={<Space>Timeout server (in seconds)</Space>}
                        rules={[
                            {
                                required: true,
                                message: 'Enter timeout server (in seconds).',
                            },
                        ]}
                    >
                        <InputNumber
                            min={0}
                            style={{ width: '100%' }}
                            placeholder="Enter timeout server (in seconds)."
                        ></InputNumber>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        name={['haProxyAdvanced', 'timeoutClient']}
                        label={<Space>Timeout client (in seconds)</Space>}
                        rules={[
                            {
                                required: true,
                                message: 'Enter timeout client (in seconds).',
                            },
                        ]}
                    >
                        <InputNumber
                            min={0}
                            style={{ width: '100%' }}
                            placeholder="Enter timeout client (in seconds)."
                        ></InputNumber>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        name={['haProxyAdvanced', 'maxConnectionsFrontend']}
                        label={<Space>Max connections frontend</Space>}
                        rules={[
                            {
                                required: true,
                                message: 'Enter max connections frontend.',
                            },
                        ]}
                    >
                        <InputNumber
                            min={0}
                            style={{ width: '100%' }}
                            placeholder="Enter max connections frontend."
                        ></InputNumber>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        name={['haProxyAdvanced', 'maxConnectionsBackend']}
                        label={
                            <Space>
                                Max connections backend (per instance)
                            </Space>
                        }
                        rules={[
                            {
                                required: true,
                                message:
                                    'Enter max connections backend (per instance).',
                            },
                        ]}
                    >
                        <InputNumber
                            min={0}
                            style={{ width: '100%' }}
                            placeholder="Enter max connections backend (per instance)."
                        ></InputNumber>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        name={['haProxyAdvanced', 'xintedAllow']}
                        label={<Space>xinted allow connections from</Space>}
                        rules={[
                            {
                                required: true,
                                message: 'Enter xinted allow connections from.',
                            },
                        ]}
                    >
                        <Input placeholder="Enter xinted allow connections from."></Input>
                    </Form.Item>
                </Col>
            </Row>
        </div>
    );
}
