import React from 'react';
import JobActionButton from '../../Jobs/JobActionButton';
import { CmonJobInstanceCommand } from '../../../services/cmon/models/CmonJobInstance';
import ButtonWithForm from '../../../common/General/ButtonWithForm';
import CcCluster from '../../../services/models/CcCluster';
import ClusterCloneModal from './ClusterCloneModal';

export default ClusterCloneButton;

export type ClusterCloneButtonProps = {
    cluster: CcCluster;
    children?: React.ReactNode;
    onSuccess?: () => void;
};

function ClusterCloneButton({
    cluster,
    children,
    onSuccess,
    ...rest
}: ClusterCloneButtonProps) {
    return (
        <ButtonWithForm
            button={
                <JobActionButton
                    command={CmonJobInstanceCommand.CLONE_CLUSTER}
                    clusterId={cluster.clusterId}
                    {...rest}
                >
                    {children || 'Clone cluster'}
                </JobActionButton>
            }
            form={<ClusterCloneModal cluster={cluster} onSuccess={onSuccess} />}
        />
    );
}
