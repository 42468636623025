import React, {
    forwardRef,
    useImperativeHandle,
    useRef,
    useState,
} from 'react';
import './ModalDefaultForm.less';
import ModalDefault, { ModalDefaultProps } from './ModalDefault';
import { FormInstance } from 'antd/lib/form';
import { notifyConfirmCloseWindow } from '../components/Notifications/uiNotification';
import MotionShake, { MotionApi } from './motion/MotionShake';
import usePreviousValue from '@severalnines/bar-frontend-components/build/lib/hooks/usePreviousValue';
import { FormProps } from 'antd';

export default forwardRef(ModalDefaultForm);

export type ModalDefaultFormProps = ModalDefaultProps &
    FormProps & {
        defaultVisible?: boolean;
        children?: React.ReactNode;
        defaultConfirmClose?: boolean;
        form: FormInstance;
        onCancel?: () => void;
        shakeWizardOnValidationError?: boolean;
        showConfirmClose?: boolean;
        /**
         * added this for backward compatibility,
         * not sure why we did not confirm closing on modal cancel
         */
        showConfirmCloseModal?: boolean;
    };

export type ModalDefaultFormApi = {
    open: () => void;
    close: () => void;
};

function ModalDefaultForm(
    {
        className,
        wrapClassName,
        form,
        defaultVisible = false,
        onCancel,
        children,
        defaultConfirmClose,
        onValuesChange,
        shakeWizardOnValidationError,
        showConfirmClose,
        showConfirmCloseModal,
        ...rest
    }: ModalDefaultFormProps,
    ref: any
) {
    const [confirmClose, setConfirmClose] = useState(!!defaultConfirmClose);
    const animationRef = useRef<MotionApi | undefined>();
    const [visible, setVisible] = useState<boolean>(defaultVisible);
    const touched = useRef<boolean>(false);
    const prevTouched = usePreviousValue(touched);

    useImperativeHandle(
        ref,
        (): ModalDefaultFormApi => ({
            //exposing methods to parent through ref
            open() {
                setVisible(true);
            },
            close() {
                close();
            },
        })
    );
    const handleValueChange = (changedValues: any, values: any) => {
        const tou = form.isFieldsTouched([], true);
        if (prevTouched !== tou) {
            setConfirmClose(tou);
        }
        onValuesChange?.(changedValues, values);
    };

    const handleFormStepErrorInsist = (err: Error) => {
        if (animationRef.current) {
            animationRef.current.animate();
        }
    };

    const handleCancel = () => {
        if (confirmClose) {
            notifyConfirmCloseWindow({
                onOk: () => {
                    onCancel?.();
                },
            });
        } else {
            onCancel?.();
        }
    };

    const handleCancelModal = () => {
        if (showConfirmCloseModal) {
            handleCancel();
        } else {
            onCancel?.();
        }
    };

    //@todo: check if this is useful, not used  for now
    const close = () => {
        setVisible(false);
        form.resetFields();
    };

    const render = () => {
        return children
            ? React.cloneElement(children as React.ReactElement, {
                  ...(children as React.ReactElement).props,
                  // next line will work only for Wizards
                  ...(shakeWizardOnValidationError
                      ? { onStepErrorInsist: handleFormStepErrorInsist }
                      : {}),
                  ...(showConfirmClose
                      ? {
                            onValuesChange: handleValueChange,
                            onCancel: handleCancel,
                        }
                      : {}),
              })
            : children;
    };

    return (
        <ModalDefault
            className="ModalDefaultForm"
            visible={visible}
            onCancel={handleCancelModal}
            destroyOnClose={true}
            footer={null}
            wrapClassName="ModalDefaultForm_wrap"
            animateHeight={true}
            bodyStyle={{ padding: '25px' }}
            modalRender={(content) => (
                <MotionShake ref={animationRef}>{content}</MotionShake>
            )}
            {...rest}
        >
            {render()}
        </ModalDefault>
    );
}
