import { Button } from 'antd';
import React from 'react';
import ModalDefault from '../../../common/ModalDefault';
import CcAdvisorSchedule from '../../../services/models/CcAdvisorSchedule';
import AdvisorResultsTable from './AdvisorResultsTable';

export default AdvisorInfoModal;
type AdvisorInfoModalProps = {
    onCancel?: () => void;
    advisorSchedule?: CcAdvisorSchedule;
};

function AdvisorInfoModal({
    onCancel,
    advisorSchedule,
}: AdvisorInfoModalProps) {
    return (
        <ModalDefault
            title={
                advisorSchedule?.getCurrentStatus()?.title ||
                advisorSchedule?.filename
            }
            visible={true}
            onCancel={onCancel}
            bodyStyle={{ padding: 20 }}
            footer={[
                <Button key="done" type="primary" onClick={onCancel}>
                    Done
                </Button>,
            ]}
        >
            {advisorSchedule && (
                <AdvisorResultsTable
                    disableFilters={true}
                    schedules={[advisorSchedule]}
                />
            )}
        </ModalDefault>
    );
}
