


export interface CmonMetaClassProps  {

}



export default class CmonMetaClass {


    constructor(props: CmonMetaClassProps) {

    }

}
