import { useEffect, useMemo, useState } from 'react';
import useClusterList from './useClusterList';
import { groupArrayBy } from '../../common/helpers';
import CcCluster, { CcClusterType } from '../../services/models/CcCluster';

export default useUserClusterType;

export type UseUserClusterTypeProps = {};

/**
 * This supposed to return cluster type that we think user prefer to work with
 */
function useUserClusterType({}: UseUserClusterTypeProps = {}) {
    const { list } = useClusterList({ fromStore: true });
    return useMemo(() => {
        if (list) {
            const typeGrouped = groupArrayBy(list, (cluster: CcCluster) =>
                cluster.getTechnology()
            );
            // group each group by cluster type
            const typeGroupedGrouped = Object.entries(typeGrouped).reduce(
                (acc, [key, group]: any) => {
                    acc[key] = groupArrayBy(
                        group,
                        (cluster: CcCluster) => cluster.clusterType
                    );
                    return acc;
                },
                {} as { [key: string]: { [key: string]: CcCluster[] } }
            );
            list.sort((a, b) => {
                const technologyA = a.getTechnology();
                const technologyB = b.getTechnology();
                let sort =
                    typeGrouped[technologyA].length -
                    typeGrouped[technologyB].length;

                if (sort === 0) {
                    sort =
                        typeGroupedGrouped[technologyA][a.clusterType].length -
                        typeGroupedGrouped[technologyB][b.clusterType].length;
                }
                if (sort === 0) {
                    // by cluster id
                    sort = (a?.clusterId || 0) - (b.clusterId || 0);
                }
                return sort;
            });
            const favorite = list.pop();
            if (favorite) {
                return favorite.clusterType ===
                    CcClusterType.TYPE_MSSQL_AO_ASYNC
                    ? CcClusterType.TYPE_MSSQL_SINGLE
                    : favorite.clusterType;
            }
        }
    }, [list]);
}
