import React, { forwardRef, useImperativeHandle, useState } from 'react';
import './DetailsModal.less';
import { ModalProps, Button, Row, Col, Typography, Space } from 'antd';
import PageWrapper from '@severalnines/bar-frontend-components/build/lib/Layout/PageWrapper';
import AppDivider from '../AppDivider';
import ModalDefault from '../ModalDefault';

export default forwardRef(DetailsModal);

export interface DetailsModalApi {
    show: () => void;
}
export type DetailsModalProps = ModalProps & {
    children?: React.ReactNode;
    icon?: React.ReactNode;
    header?: React.ReactNode;
    headerTitle?: React.ReactNode;
    headerExtra?: React.ReactNode;
};

function DetailsModal(
    {
        icon,
        header,
        headerTitle,
        headerExtra,
        children,
        onCancel,
        ...rest
    }: DetailsModalProps,
    ref: any
) {
    const [visible, setVisible] = useState(false);
    useImperativeHandle(
        ref,
        (): DetailsModalApi => ({
            show() {
                setVisible(true);
            },
        })
    );
    const handleCancel = (e: any) => {
        setVisible(false);
        onCancel?.(e);
    };
    return (
        <ModalDefault
            className="DetailsModal"
            centered
            visible={visible}
            onCancel={handleCancel}
            width={920}
            footer={[
                <Button key="done" type="primary" onClick={handleCancel}>
                    Close
                </Button>,
            ]}
            bodyStyle={{ padding: 0 }}
            destroyOnClose={true}
            maskClosable={false}
            {...rest}
        >
            <Space
                direction="vertical"
                className="DetailsModal_content-wrapper"
            >
                <Row wrap={false}>
                    {icon ? (
                        <Col flex="200px" className="DetailsModal_icon">
                            {icon}
                        </Col>
                    ) : null}
                    <Col flex="auto">
                        {headerTitle || headerExtra ? (
                            <Space className="DetailsModal_title-wrapper">
                                {headerTitle ? (
                                    <Typography.Title level={2}>
                                        {headerTitle}
                                    </Typography.Title>
                                ) : null}
                                {headerExtra}
                            </Space>
                        ) : null}

                        {header}
                    </Col>
                </Row>
                <AppDivider />
                <PageWrapper>{children}</PageWrapper>
            </Space>
        </ModalDefault>
    );
}
