import './HAProxyDetailsModal.less';
import React, { useEffect, useState, useCallback } from 'react';
import { Row, Space, Divider } from 'antd';
import ModalDefault from '../../../../common/ModalDefault';
import useFetch from '../../../../common/useFetch';
import CcHaProxyNode from '../../../../services/models/CcHaProxyNode';
import HAProxyDetailsTable from './HAProxyDetailsTable';
import CcCluster from '../../../../services/models/CcCluster';
import CmonStatService from '../../../../services/cmon/CmonStatService';
import SpaceDescriptions from '../../../../common/Layout/SpaceDescriptions';
import NodeFormat from '../../NodeFormat';
import ActionsMenu, {
    ActionsMenuProps,
} from '../../../../common/Navigation/ActionsMenu';

import { prepareHAPRoxyTableData } from './haProxyDetailsHelper';
import { csvToArray, groupArrayBy } from '../../../../common/helpers';
import RefreshButton from '../../../../common/General/RefreshButton';
import AppPageWrapper from '../../../../common/Layout/AppPageWrapper';

export default HAProxyDetailsModal;

export type HAProxyDetailsModalProps = ActionsMenuProps & {
    node: CcHaProxyNode;
    onCancel?: () => void;
    cluster: CcCluster;
};

function HAProxyDetailsModal({
    node,
    onCancel,
    cluster,
    ...rest
}: HAProxyDetailsModalProps) {
    const [haProxyTableData, setHAProxyTableData] = useState<undefined | any[]>(
        undefined
    );

    const {
        loading,
        data: haProxyResponse,
        refresh: refreshHAProxyStat,
    } = useFetch({
        name: 'haproxystat-list',
        fetchFn: async (params, opts) => {
            const haProxyHostId = `haproxystat.${node?.hostId}.lastsample`;
            const response = await CmonStatService.getInfo(
                {
                    cluster_id: cluster.clusterId,
                    name: haProxyHostId,
                    ...params,
                },
                opts
            );
            const data = response?.collected_info?.length
                ? response?.collected_info[0]?.info[haProxyHostId]?.contents
                : '';
            return csvToArray(data);
        },
        cancelFn: async ({ requestId }) => {
            await CmonStatService.cancelRequest(requestId);
        },
    });

    const handleRefresh = useCallback(async () => {
        await refreshHAProxyStat();
    }, []);

    useEffect(() => {
        handleRefresh();
    }, []);

    useEffect(() => {
        if (haProxyResponse !== undefined) {
            const haProxyObject = groupArrayBy(
                haProxyResponse,
                (item) => item.pxname
            );
            setHAProxyTableData(prepareHAPRoxyTableData(haProxyObject, node));
        }
    }, [haProxyResponse]);

    return (
        <ModalDefault
            title="Node details"
            visible={true}
            width={'90%'}
            onCancel={() => onCancel?.()}
        >
            <AppPageWrapper
                contentClassName="HAProxyDetailsModal"
                transparent={true}
                contentProps={{ style: { border: 0 } }}
            >
                <Row justify="space-between">
                    <Space direction="vertical" align="start" size={0}>
                        <SpaceDescriptions direction={'vertical'}>
                            <NodeFormat
                                key={node?.hostname}
                                node={node}
                                size="large"
                            />
                        </SpaceDescriptions>
                    </Space>

                    <Space align="end">
                        <RefreshButton
                            isMenu
                            isNeverRefresh
                            onRefresh={handleRefresh}
                        />
                        <RefreshButton onRefresh={handleRefresh} />
                        <Divider type="vertical" style={{ height: '28px' }} />
                        <ActionsMenu
                            items={[
                                {
                                    key: 'detailed-view',
                                    label: (
                                        <a
                                            href={`http://${node?.hostname}:${node?.port}`}
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            Detailed view
                                        </a>
                                    ),
                                },
                            ]}
                            {...rest}
                        />
                    </Space>
                </Row>
                <HAProxyDetailsTable
                    haProxyTableData={haProxyTableData}
                    clusterId={cluster?.clusterId}
                    loading={loading}
                    handleRefresh={handleRefresh}
                />
            </AppPageWrapper>
        </ModalDefault>
    );
}
