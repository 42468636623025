import CcBackupStorageLocation, {
    CcBackupStorageLocationProps,
    CcBackupStorageLocationType,
} from './CcBackupStorageLocation';

export interface CcBackupHostStorageLocationProps
    extends CcBackupStorageLocationProps {
    host_location_uuid?: string;
    root_dir?: string;
    storage_host?: string;
}

export default class CcBackupHostStorageLocation extends CcBackupStorageLocation {
    public readonly hostLocationUuid?: string;
    public readonly rootDir?: string;
    public readonly storageHost?: string;
    constructor(props: CcBackupHostStorageLocationProps) {
        super({ ...props, type: CcBackupStorageLocationType.HOST });
        this.hostLocationUuid = props.host_location_uuid;
        this.rootDir = props.root_dir;
        this.storageHost = props.storage_host;
    }

    public getPath(): string {
        return this.rootDir || '';
    }

    public getUuid(): string {
        return this.hostLocationUuid || '';
    }
}
