import React, { Fragment } from 'react';
import { Col, Input, Row, Space } from 'antd';
import FormItemInlineSwitch from '../../../common/DataEntry/FormItemInlineSwitch';
import InfoIcon from '@severalnines/bar-frontend-components/build/lib/General/InfoIcon';
import FormItem from '../../../common/DataEntry/FormItem';
import PasswordInput from '../../../common/DataEntry/PasswordInput';
import { LdapConfig } from './LdapTypes';

export default LdapSettingsGeneralForm;

export type LdapSettingsGeneralFormProps = {
    defaultSettings?: LdapConfig;
};

function LdapSettingsGeneralForm({
    defaultSettings,
}: LdapSettingsGeneralFormProps) {
    return (
        <Fragment>
            <Row gutter={[24, 0]}>
                <Col span={24}>
                    <h3>General settings</h3>
                </Col>
            </Row>
            <Row gutter={[24, 0]}>
                <Col xs={24} sm={24} md={12}>
                    <FormItemInlineSwitch
                        name="enabled"
                        justify={true}
                        label={
                            <Space>
                                <span>Enable LDAP authentication</span>
                                <InfoIcon info="Enables LDAP authentication. The native authentication in ClusterControl will also work." />
                            </Space>
                        }
                    />
                </Col>
            </Row>
            <Row gutter={[24, 0]}>
                <Col xs={24} sm={24} md={12}>
                    <FormItem
                        name="ldapServerUri"
                        label={
                            <Space>
                                <span>LDAP/LDAPS URI</span>
                                <InfoIcon info="Enter the LDAP or LDAPS Uniform Resource Identifier (URI), with the port number (if applicable)." />
                            </Space>
                        }
                        rules={[
                            {
                                required: true,
                                message: 'Please enter LDAP/LDAPS URI',
                            },
                            {
                                pattern: /(^ldap[s]{0,1}:\/\/.*)/g,
                                message: 'Please enter valid LDAP/LDAPS URI',
                            },
                        ]}
                    >
                        <Input placeholder={defaultSettings?.ldapServerUri} />
                    </FormItem>
                </Col>
                <Col xs={24} sm={24} md={12}></Col>
                <Col xs={24} sm={24} md={12}>
                    <FormItem
                        name="ldapAdminUser"
                        label={
                            <Space>
                                <span>Login DN</span>
                                <InfoIcon info="The “Distinguished Name” is used to bind the LDAP server. This user requires read access to all LDAP user and group entries to work correctly. ClusterControl must perform a LDAP search using the DN before any user can log in. This field is case-sensitive." />
                            </Space>
                        }
                        rules={[
                            {
                                required: true,
                                message: 'Please enter Login DN',
                            },
                        ]}
                    >
                        <Input placeholder={defaultSettings?.ldapAdminUser} />
                    </FormItem>
                </Col>
                <Col xs={24} sm={24} md={12}>
                    <FormItem
                        name="ldapAdminPassword"
                        label={
                            <Space>
                                <span>Login DN password</span>
                                <InfoIcon info="Enter the password for the “Distinguished Name” login." />
                            </Space>
                        }
                        rules={[
                            {
                                required: true,
                                message: 'Please enter Login DN password',
                            },
                        ]}
                    >
                        <PasswordInput
                            placeholder={defaultSettings?.ldapAdminPassword}
                        />
                    </FormItem>
                </Col>
                <Col xs={24} sm={24} md={12}>
                    <FormItem
                        name="ldapUserSearchRoot"
                        label={
                            <Space>
                                <span>User base DN</span>
                                <InfoIcon info="Enter the user’s “Relative Distinguished Name” (RDN) to locate this user’s information. This field is case-sensitive." />
                            </Space>
                        }
                        rules={[
                            {
                                required: true,
                                message: 'Please enter User base DN',
                            },
                        ]}
                    >
                        <Input
                            placeholder={defaultSettings?.ldapUserSearchRoot}
                        />
                    </FormItem>
                </Col>
                <Col xs={24} sm={24} md={12}>
                    <FormItem
                        name="ldapGroupSearchRoot"
                        label={
                            <Space>
                                <span>Group base DN</span>
                                <InfoIcon info="The group’s “Relative Distinguished Name” (RDN) is used to locate the group information. ClusterControl does not support LDAP users that do not belong to at least one LDAP group." />
                            </Space>
                        }
                        rules={[
                            {
                                required: true,
                                message: 'Please enter Group base DN',
                            },
                        ]}
                    >
                        <Input
                            placeholder={defaultSettings?.ldapGroupSearchRoot}
                        />
                    </FormItem>
                </Col>
            </Row>
        </Fragment>
    );
}

export function getLdapSettingsGeneralFormValidate() {
    return [
        'ldapServerUri',
        'ldapAdminUser',
        'ldapAdminPassword',
        'ldapUserSearchRoot',
        'ldapGroupSearchRoot',
    ];
}
