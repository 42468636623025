import React, { useMemo, useState } from 'react';
import WizardFormConfiguration, {
    WizardFormConfigurationProps,
} from '@severalnines/bar-frontend-components/build/lib/Navigation/Wizard/WizardFormConfiguration';
import { Col, Form, Input, Row } from 'antd';
import FormItem from '../../common/DataEntry/FormItem';
import SpaceDescriptions from '../../common/Layout/SpaceDescriptions';
import AppDivider from '../../common/AppDivider';
import { FormInstance } from 'antd/es';
import CcUser from '../../services/models/CcUser';
import CmonUsersService from '../../services/cmon/CmonUsersService';
import {
    notifyError,
    notifyOperationSuccess,
    NotifyType,
} from '../Notifications/uiNotification';
import UserSelect from './UserSelect';
import Alert from '@severalnines/bar-frontend-components/build/lib/Feedback/Alert';
import { PermissionLevelType } from './PermissionLevelSelect';
import useTree, { aclItemsPathsDeps } from './useTree';
import PermissionsForm from './PermissionsForm';
import ClustersPermissionsForm from './ClustersPermissionsForm';
import {
    CcTreeItemAccess,
    CcTreeItemHandledAclPath,
} from '../../services/models/CcTreeItem';
import PermissionsSummary from './PermissionsSummary';
import CmonTreeService from '../../services/cmon/CmonTreeService';
import TypographyText from '../../common/TypographyText';
import {
    getClusterPermissions,
    getInitialValues,
} from './userManagementHelper';
import { GroupEditWizardFormValues } from './Actions/GroupEditModal';

export default GroupCreateWizardForm;

enum GroupCreateWizardFormStep {
    DETAILS = 'DETAILS',
    USERS = 'USERS',
    PERMISSIONS = 'PERMISSIONS',
    SUMMARY = 'SUMMARY',
}

export interface GroupCreateWizardFormValues {
    groupName?: string;
    users?: CcUser[];
    permissionLevel?: PermissionLevelType;
    permissions: { [key in CcTreeItemHandledAclPath]: boolean };
    clusterPermissions: { [key: string]: PermissionLevelType };
}

export type GroupCreateWizardFormProps = Omit<
    WizardFormConfigurationProps,
    'steps' | 'initialValues' | 'form' | 'onSubmit'
> & {
    initialValues?: GroupCreateWizardFormValues;
    onSuccess?: () => void;
    onError?: (err: any) => void;
    form?: FormInstance;
};

function GroupCreateWizardForm({
    onSuccess,
    onError,
    onCancel,
    form: parentForm,
    ...rest
}: GroupCreateWizardFormProps) {
    const [localForm] = Form.useForm<GroupCreateWizardFormValues>();
    const [loading, setLoading] = useState<boolean>(false);
    const { getEffectiveAclOverClusters, getEffectiveAclOverActions } = useTree(
        {
            name: 'tree-from-group-create-form',
        }
    );

    const groupNoClusterPermissions = getEffectiveAclOverActions([
        CcTreeItemAccess.FULL_ACCESS,
        CcTreeItemAccess.EXECUTE,
    ]);
    const groupClusterPermissions = getEffectiveAclOverClusters([
        CcTreeItemAccess.FULL_ACCESS,
        CcTreeItemAccess.EXECUTE,
    ]);
    const form = parentForm || localForm;

    const handleSubmit = async () => {
        const {
            groupName,
            users,
            permissions,
            clusterPermissions,
        } = form.getFieldsValue(true);
        try {
            setLoading(true);
            // create the team
            await CmonUsersService.createGroup({
                group: {
                    class_name: 'CmonGroup',
                    group_name: groupName,
                },
            });
            // move users to team
            if (users) {
                await Promise.all(
                    users.map((user: CcUser) =>
                        CmonUsersService.addToGroup({
                            group_name: groupName,
                            replace_primary_group: true,
                            user: {
                                class_name: 'CmonUser',
                                user_name: user.userName,
                            },
                        })
                    )
                );
            }

            // permissions
            await Promise.all(
                // do add acl for paths and dependencies
                Object.keys(permissions).reduce(
                    (acc: Promise<any>[], permissionKey: string) => {
                        acc.push(
                            CmonTreeService.addAcl({
                                acl: `group:${groupName}:${
                                    permissions[permissionKey]
                                        ? CcTreeItemAccess.FULL_ACCESS
                                        : CcTreeItemAccess.NO_ACCESS
                                }`,
                                path: permissionKey,
                            })
                        );
                        if (
                            aclItemsPathsDeps[
                                permissionKey as CcTreeItemHandledAclPath
                            ] &&
                            permissions[permissionKey]
                        ) {
                            acc.push(
                                ...aclItemsPathsDeps[
                                    permissionKey as CcTreeItemHandledAclPath
                                ]!.map((depKey) =>
                                    CmonTreeService.addAcl({
                                        acl: `group:${groupName}:${CcTreeItemAccess.FULL_ACCESS}`,
                                        path: depKey,
                                    })
                                )
                            );
                        }
                        return acc;
                    },
                    []
                )
            );
            // clusterPermissions
            await Promise.all(
                Object.keys(clusterPermissions).map((permissionKey) =>
                    CmonTreeService.addAcl({
                        acl: `group:${groupName}:${clusterPermissions[permissionKey]}`,
                        path: permissionKey,
                    })
                )
            );
            if (
                Object.keys(clusterPermissions).some(
                    (permissionKey) =>
                        clusterPermissions[permissionKey] ===
                        CcTreeItemAccess.FULL_ACCESS
                )
            ) {
                // this means that user needs to have job executor permssion for managing at least 1 cluster
                await CmonTreeService.addAcl({
                    acl: `group:${groupName}:${CcTreeItemAccess.FULL_ACCESS}`,
                    path: CcTreeItemHandledAclPath.ACL_JOB_EXECUTOR,
                });
            }

            notifyOperationSuccess({
                type: NotifyType.TOAST,
                title: <span>The team was created successfully.</span>,
            });
            setLoading(false);
            if (onSuccess) {
                onSuccess();
            }
        } catch (err: any) {
            setLoading(false);
            if (onError) {
                onError(err);
            }
            notifyError({ content: err.message });
        }
    };
    const handleCancel = () => {
        onCancel?.();
    };

    const steps = useMemo(() => {
        return [
            <WizardFormConfiguration.Step
                key={GroupCreateWizardFormStep.DETAILS}
                title="Details"
                subTitle=" "
                validate={['groupName']}
                hasRequiredFields={true}
            >
                <Row gutter={[24, 0]}>
                    <Col span={24}>
                        <h3>Configuration</h3>
                    </Col>
                </Row>
                <Row gutter={[24, 0]}>
                    <Col xs={24} sm={24} md={12}>
                        <FormItem
                            name="groupName"
                            label="Team name"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter team name',
                                },
                            ]}
                        >
                            <Input placeholder="Enter team name" />
                        </FormItem>
                    </Col>
                </Row>
            </WizardFormConfiguration.Step>,
            <WizardFormConfiguration.Step
                key={GroupCreateWizardFormStep.USERS}
                title="Users"
                subTitle=" "
                hasRequiredFields={false}
            >
                <Row gutter={[24, 0]}>
                    <Col span={24}>
                        <h3>Users</h3>
                    </Col>
                </Row>
                <Row gutter={[24, 0]}>
                    <Col xs={24} sm={24} md={12}>
                        <FormItem
                            name="users"
                            label="Add existing users to the team"
                            extra={
                                <TypographyText muted={true}>
                                    You can always add users later
                                </TypographyText>
                            }
                        >
                            <UserSelect
                                mode="multiple"
                                placeholder="Select one or more users"
                            />
                        </FormItem>
                        <FormItem shouldUpdate={true} noStyle={true}>
                            {() => {
                                return form.getFieldValue('users')?.length ? (
                                    <Alert
                                        showIcon={true}
                                        type="warning"
                                        message={`The selected users will be moved from their current teams to this new team.`}
                                    />
                                ) : null;
                            }}
                        </FormItem>
                    </Col>
                </Row>
            </WizardFormConfiguration.Step>,
            <WizardFormConfiguration.Step
                key={GroupCreateWizardFormStep.PERMISSIONS}
                title="Permissions"
                subTitle=" "
                validate={['permissionLevel']}
                hasRequiredFields={true}
            >
                <Row gutter={[24, 0]}>
                    <Col span={24}>
                        <h3>Permissions</h3>
                    </Col>
                </Row>
                <Row gutter={[24, 0]}>
                    <Col xs={24} sm={24} md={24}>
                        <PermissionsForm
                            permissions={groupNoClusterPermissions}
                        />
                    </Col>

                    <Col xs={24} sm={24} md={24}>
                        <ClustersPermissionsForm
                            form={form}
                            permissions={groupClusterPermissions}
                        />
                    </Col>
                </Row>
            </WizardFormConfiguration.Step>,
            <WizardFormConfiguration.Step
                key={GroupCreateWizardFormStep.SUMMARY}
                title="Preview"
                subTitle=" "
                hasRequiredFields={false}
            >
                <FormItem shouldUpdate={true} noStyle={true}>
                    {() => {
                        const formFields = form.getFieldsValue(true);
                        return (
                            <Row gutter={[24, 0]}>
                                <Col span={24}>
                                    <SpaceDescriptions
                                        direction="vertical"
                                        title="Details"
                                        size="small"
                                        alignItems="right"
                                    >
                                        <SpaceDescriptions.Item
                                            label="Team name"
                                            labelStrong
                                        >
                                            {formFields.groupName}
                                        </SpaceDescriptions.Item>
                                    </SpaceDescriptions>
                                    <AppDivider />
                                    <SpaceDescriptions
                                        direction="vertical"
                                        title="Users"
                                        size="small"
                                        alignItems="right"
                                    >
                                        <SpaceDescriptions.Item labelStrong>
                                            {formFields.users
                                                ? formFields.users
                                                      .map(
                                                          (g: CcUser) =>
                                                              g.userName
                                                      )
                                                      .join(', ')
                                                : 'No users'}
                                        </SpaceDescriptions.Item>
                                    </SpaceDescriptions>
                                    <AppDivider />
                                    <PermissionsSummary form={form} />
                                </Col>
                            </Row>
                        );
                    }}
                </FormItem>
            </WizardFormConfiguration.Step>,
        ];
    }, [groupNoClusterPermissions, groupClusterPermissions]);

    const handleValuesChange = (values: Partial<GroupEditWizardFormValues>) => {
        if (values.permissionLevel) {
            if (values.permissionLevel !== 'custom') {
                form.setFieldsValue(
                    getClusterPermissions(
                        form.getFieldValue('clusterPermissions'),
                        values.permissionLevel as CcTreeItemAccess
                    )
                );
            }
        }
    };

    const initialValues = useMemo(
        () =>
            getInitialValues(
                groupNoClusterPermissions,
                groupClusterPermissions
            ),
        []
    );

    return (
        <WizardFormConfiguration
            form={form}
            loading={loading}
            onValuesChange={handleValuesChange}
            steps={steps}
            onSubmit={handleSubmit}
            showCancelButton
            cancelButtonText="Cancel"
            onCancel={handleCancel}
            initialValues={initialValues}
            {...rest}
        />
    );
}
