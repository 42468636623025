import React from 'react';
import GroupSelect from './GroupSelect';
import FormItem, { FormItemProps } from '../../common/DataEntry/FormItem';

export default GroupField;

export type GroupFieldProps = FormItemProps & {};

function GroupField({ ...rest }: GroupFieldProps) {
    return (
        <FormItem
            name="group"
            label="Choose a team"
            rules={[
                {
                    required: true,
                    message: 'Please select a group',
                },
            ]}
            {...rest}
        >
            <GroupSelect />
        </FormItem>
    );
}
