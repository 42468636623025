
import CmonRequestService, {CmonRequestServiceResponseData} from './CmonRequestService';


export interface AuthenticateWithPasswordRequestData {
    password?: string;
    request_created?: string;
    request_id?: number;
    user_name?: string;
    [key: string]: any;
}

export interface AuthenticateRequestData {
    user_name?: string;
    [key: string]: any;
}

export interface AuthenticateresponseRequestData {
    request_created?: string;
    request_id?: number;
    signature?: string;
    [key: string]: any;
}

export interface LogoutRequestData {
    request_created?: string;
    request_id?: number;
    [key: string]: any;
}

export interface PasswordResetRequestData {
    new_password?: string;
    request_created?: string;
    request_id?: number;
    password_reset_token?: string;
    user_name?: string;
    [key: string]: any;
}


export default class CmonAuthService extends CmonRequestService {
    
    public static module(): string {
        return 'auth';
    }
    
    public static async authenticateWithPassword(
        data: AuthenticateWithPasswordRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('authenticateWithPassword', data, opts);
    }

    public static async authenticate(
        data: AuthenticateRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('authenticate', data, opts);
    }

    public static async authenticateresponse(
        data: AuthenticateresponseRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('authenticateresponse', data, opts);
    }

    public static async logout(
        data: LogoutRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('logout', data, opts);
    }

    public static async passwordReset(
        data: PasswordResetRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('passwordReset', data, opts);
    }

}
