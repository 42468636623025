import React from 'react';
import { Form } from 'antd';
import WizardForm from '@severalnines/bar-frontend-components/build/lib/Navigation/WizardForm';
import WizardFormConfiguration from '@severalnines/bar-frontend-components/build/lib/Navigation/Wizard/WizardFormConfiguration';
import CcCluster from '../../../../services/models/CcCluster';
import { FormInstance } from 'antd/lib/form';
import useCreateJob from '../../../Jobs/useCreateJob';
import { CmonJobInstanceCommand } from '../../../../services/cmon/models/CmonJobInstance';
import CreateMaxScaleWhere, {
    MAX_SCALE_WHERE_INITIAL,
    getCreateMaxScaleWhereValidation,
} from './CreateMaxScaleWizardFromSteps/CreateMaxScaleWhere';
import CreateMaxScaleConfiguration, {
    MAX_SCALE_CONFIGURATION_INITIAL,
    MAX_SCALE_CONFIGURATION_VALIDATION,
} from './CreateMaxScaleWizardFromSteps/CreateMaxScaleConfiguration';
import CreateMaxScaleServerInstances, {
    maxScaleServerInstancesValues,
} from './CreateMaxScaleWizardFromSteps/CreateMaxScaleServerInstances';
import CreateMaxScalePreview from './CreateMaxScaleWizardFromSteps/CreateMaxScalePreview';

export enum MaxScaleFormSteps {
    WHERE_TO_INS = 'WhereToInstall',
    CONFIGURATION = 'Configuration',
    SERVER_INS = 'ServerInstances',
    PREVIEW = 'preview',
}

export type CreateMaxScaleWizardFromProps = {
    onCancel?: () => void;
    onSuccess?: () => void;
    onError?: (err: Error) => void;
    onStepErrorInsist?: (err: Error) => void;
    onTouchedChange?: (touched: boolean) => void;
    cluster?: CcCluster;
};

export default CreateMaxScaleWizardFrom;

function CreateMaxScaleWizardFrom({
    onCancel,
    onSuccess,
    onStepErrorInsist,
    onTouchedChange,
    onError,
    cluster,
}: CreateMaxScaleWizardFromProps) {
    const [form] = Form.useForm();

    const { loading, send } = useCreateJob({
        clusterId: cluster?.clusterId,
        title: 'Setup MaxScale',
        command:
            CmonJobInstanceCommand.MAXSCALE.toLowerCase() as CmonJobInstanceCommand,
        onSuccess,
    });

    const handleSubmit = async () => {
        try {
            const { maxScaleWhere } = form.getFieldsValue(true);
            const [address] = maxScaleWhere.address;
            await send(
                {
                    clusterId: cluster?.clusterId,
                    ...formatFields(form),
                },
                {
                    job: {
                        title: `Setup MaxScale ${address?.extraData.hostname}`,
                    },
                }
            );
        } catch (err: any) {
            if (onError) {
                onError(err);
            }
        }
    };

    return (
        <WizardFormConfiguration
            form={form}
            onSubmit={handleSubmit}
            loading={loading}
            onCancel={onCancel}
            initialValues={{
                ...MAX_SCALE_WHERE_INITIAL,
                ...MAX_SCALE_CONFIGURATION_INITIAL,
                maxScaleServerInstances: maxScaleServerInstancesValues(
                    form,
                    cluster
                ),
            }}
            onStepErrorInsist={onStepErrorInsist}
            onTouchedChange={onTouchedChange}
            steps={[
                <WizardForm.Step
                    key={MaxScaleFormSteps.WHERE_TO_INS}
                    title="Where to install"
                    subTitle=" "
                    validate={getCreateMaxScaleWhereValidation(form)}
                >
                    <CreateMaxScaleWhere
                        selectedCluster={cluster}
                        form={form}
                    />
                </WizardForm.Step>,
                <WizardForm.Step
                    key={MaxScaleFormSteps.CONFIGURATION}
                    title="Configuration"
                    subTitle=" "
                    validate={MAX_SCALE_CONFIGURATION_VALIDATION}
                >
                    <CreateMaxScaleConfiguration form={form} />
                </WizardForm.Step>,
                <WizardForm.Step
                    key={MaxScaleFormSteps.SERVER_INS}
                    title="Server instances"
                    subTitle=" "
                >
                    <CreateMaxScaleServerInstances
                        form={form}
                        selectedCluster={cluster}
                    />
                </WizardForm.Step>,
                <WizardForm.Step
                    key={MaxScaleFormSteps.PREVIEW}
                    title="Preview"
                    subTitle=" "
                >
                    <CreateMaxScalePreview form={form} />
                </WizardForm.Step>,
            ]}
        />
    );
}

export function serverInstanceNodeAddress(formObject: any) {
    let finalArray: string[] = [];
    Object.keys(formObject).forEach(function (key) {
        if (key.indexOf('node') === 0 && formObject[key]) {
            if (formObject[`nodeAddress${key.split('node')[1]}`]) {
                finalArray.push(
                    formObject[`nodeAddress${key.split('node')[1]}`]
                );
            }
        }
    });

    return finalArray;
}

export function formatFields(form: FormInstance) {
    const { maxScaleWhere, maxScaleConfiguration, maxScaleServerInstances } =
        form.getFieldsValue(true);
    let nodeAddress = serverInstanceNodeAddress(maxScaleServerInstances);
    const [address] = maxScaleWhere.address;
    const date = {
        action: 'setupMaxScale',
        node: {
            hostname: address?.extraData.hostname,
        },
        node_addresses: nodeAddress,
        maxscale_admin_user: maxScaleConfiguration.maxScaleAdminUser,
        maxscale_admin_password: maxScaleConfiguration.maxScaleAdminPassword,
        maxscale_mysql_user: maxScaleConfiguration.maxScaleMySQLUser,
        maxscale_mysql_password: maxScaleConfiguration.maxScaleMySQLPassword,
        maxscale_rr_port: maxScaleWhere.portRR,
        maxscale_rw_port: maxScaleWhere.portRW,
        maxscale_threads: maxScaleWhere.threads,
        disable_firewall: maxScaleWhere.firewall,
        disable_selinux: maxScaleWhere.appArmor,
    };
    return date;
}
