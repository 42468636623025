import { Alert, Form, Input, Select, Space } from 'antd';
import React from 'react';
import CcCluster from '../../../services/models/CcCluster';
import ModalDefaultForm, {
    ModalDefaultFormProps,
} from '../../../common/ModalDefaultForm';
import FormFooter from '../../../common/FormFooter';
import { useForm } from 'antd/lib/form/Form';
import CronInput from '@severalnines/bar-frontend-components/build/lib/DataEntry/CronInput';
import CronFormat from '@severalnines/bar-frontend-components/build/lib/Format/CronFormat';
import CcAdvisorSchedule from '../../../services/models/CcAdvisorSchedule';
import CmonImperativeService from '../../../services/cmon/CmonImperativeService';
import {
    notifyError,
    notifyOperationSuccess,
    NotifyType,
} from '../../Notifications/uiNotification';
import { useDebugContext } from '../../../common/Debug';

export default AdvisorEditScriptModal;
type AdvisorEditParamsModalProps = Omit<
    ModalDefaultFormProps,
    'children' | 'form'
> & {
    cluster: CcCluster;
    advisorSchedule: CcAdvisorSchedule;
    onSuccess?: () => void;
};

function AdvisorEditScriptModal({
    cluster,
    onCancel,
    onSuccess,
    advisorSchedule,
    ...rest
}: AdvisorEditParamsModalProps) {
    const { log } = useDebugContext();

    const [form] = useForm();

    const handleSubmit = async (values: any) => {
        try {
            await CmonImperativeService.schedule({
                schedule_id: advisorSchedule.scheduleid,
                cluster_id: cluster.clusterId,
                filename: advisorSchedule.filename,
                schedule: values.schedule,
                arguments: values.arguments,
            });
            onSuccess?.();
            notifyOperationSuccess({
                type: NotifyType.TOAST,
                title: 'Success!',
                content: 'Schedule updated successfully',
            });
        } catch (e: any) {
            notifyError({ content: e.message });
            log.error(e);
        }
    };
    return (
        <ModalDefaultForm
            title={`Edit params for "${
                advisorSchedule?.getCurrentStatus()?.title ||
                advisorSchedule?.filename ||
                'advisor'
            }"`}
            width={700}
            form={form}
            onCancel={onCancel}
            defaultVisible={true}
            bodyStyle={{ padding: '20px' }}
            loading={false}
        >
            <Form
                form={form}
                onFinish={handleSubmit}
                layout="vertical"
                initialValues={{
                    schedule: advisorSchedule.file?.schedule,
                    tags: advisorSchedule.tags,
                    arguments: advisorSchedule.file?.schedule_args,
                }}
            >
                <Form.Item name="arguments" label="Arguments">
                    <Input />
                </Form.Item>
                <Form.Item name="schedule" label="Schedule">
                    <CronInput
                        value={advisorSchedule?.file?.schedule}
                        showThreeLetterDay={true}
                    />
                </Form.Item>
                <Form.Item noStyle={true} shouldUpdate={true}>
                    {() => {
                        return (
                            <Alert
                                message={
                                    <>
                                        <Space>
                                            Script will be executed:
                                            <b>
                                                <CronFormat>
                                                    {form.getFieldValue(
                                                        'schedule'
                                                    )}
                                                </CronFormat>
                                            </b>
                                            (UTC)
                                        </Space>
                                    </>
                                }
                                type="info"
                                showIcon={true}
                            />
                        );
                    }}
                </Form.Item>

                <FormFooter
                    loading={false}
                    submitButtonText="Save"
                    showCancelButton
                    showSubmitButton
                    onCancel={onCancel}
                />
            </Form>
        </ModalDefaultForm>
    );
}
