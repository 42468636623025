import {
    useCallback,
    useContext,
    useEffect,
    useReducer,
    useState,
} from 'react';
import { ClusterConfigValue } from '../useClusterConfig';
import {
    initialListState,
    listReducer,
    setListAll,
} from '../../../common/listReducer';
import { arrayPages } from '../../../common/filtering';
import {
    ClusterConfigCategory,
    ClusterConfigGrouped,
} from '../useClusterConfigGrouped';
import { getSortAlphabeticFn } from '../../../common/sorting';
import { ConfigGroupedContext } from './ConfigContext';

const defaultSorterFn = getSortAlphabeticFn(
    'ascend',
    (x: ClusterConfigValue) => x.name
);

export type UseClusterConfigGroupedListFilterParams = {
    order?: (a: any, b: any) => number;
    config?: ClusterConfigGrouped;
};
export type UseClusterConfigGroupedListProps = {
    categories?: string[];
};
export default function useClusterConfigGroupedList({
    categories,
}: UseClusterConfigGroupedListProps) {
    const [items, setItems] = useState<ClusterConfigValue[]>([]);
    const { configGrouped, ...rest } = useContext(ConfigGroupedContext);

    const [{ order: listOrder }, listDispatch] = useReducer(listReducer, {
        ...initialListState,
        order: defaultSorterFn,
    });

    const filter = useCallback<
        (p?: UseClusterConfigGroupedListFilterParams) => void
    >(
        ({ order = listOrder, config = configGrouped } = {}) => {
            const arr =
                categories
                    ?.map((category) =>
                        Object.values(
                            config?.[category as ClusterConfigCategory] || {}
                        )
                    )
                    .flat() || [];
            listDispatch(setListAll({ order }));
            setItems(
                arrayPages({
                    order,
                    arr,
                })
            );
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [configGrouped, listOrder]
    );

    useEffect(() => {
        if (configGrouped) {
            filter({
                config: configGrouped,
            });
        }
    }, [configGrouped]);

    return {
        items,
        filter,
        ...rest,
    };
}
