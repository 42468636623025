import merge from 'deepmerge';
import React from 'react';
import {
    ClusterConfiguratorFormValues,
    AddClusterNodeConfigFormValues,
    ClusterConfigurator,
} from '../ClusterConfigurator';
import { CcClusterType } from '../../../../services/models/CcCluster';
import { FormInstance } from 'antd/lib/form';
import ElasticNodeConfiguration, {
    ElasticNodeConfigurationProps,
} from './ElasticNodeConfiguration';
import { TopologyItem } from '../../../Topology/TopologyItem';

export interface ElasticFormValues extends ClusterConfiguratorFormValues {
    nodeConfig: AddClusterNodeConfigFormValues & {
        httpPort?: number;
        transferPort?: number;
        dbUser?: string;
        dbPassword?: string;
    };

    eligibleMasterTopology: TopologyItem[];
    dataNodeTopology: TopologyItem[];
    useAsDataNode?: {
        [key: string]: boolean;
    };
    itemType?: {
        [key: string]: boolean;
    };

    repository?: {
        storageHost?: TopologyItem[];
        name?: string;
        snapshotLocation?: string;
        configureSharedFs?: boolean;
    };
}

export default class ElasticConfigurator extends ClusterConfigurator {
    public static getDefaults(): ElasticFormValues {
        return merge(ClusterConfigurator.getDefaults(), {
            eligibleMasterTopology: [],
            dataNodeTopology: [],
            nodeConfig: {
                httpPort: 9200,
                transferPort: 9200,
                dbUser: 'admin',
            },
            useAsDataNode: {},
        });
    }

    public static getJobData(formValues: ElasticFormValues): any {
        const { nodeConfig } = formValues;
        return merge(ClusterConfigurator.getJobData(formValues), {
            cluster_type: CcClusterType.TYPE_ELASTIC.toLowerCase(),
            db_user: nodeConfig.dbUser,
            db_password: nodeConfig.dbPassword,
        });
    }

    public static getVendors() {
        return [
            {
                name: 'Elastic',
                value: 'elasticsearch',
                versions: [
                    { name: '8.3.1', value: '8.3.1' },
                    { name: '8.1.3', value: '8.1.3' },
                    { name: '7.17.3', value: '7.17.3' },
                ],
            },
        ];
    }

    public static getNodeConfigurationStep(
        props: ElasticNodeConfigurationProps
    ): React.ReactNode {
        return <ElasticNodeConfiguration {...props} />;
    }

    public static getNodeConfigurationValidate(
        form?: FormInstance
    ): ((() => void) | string[])[] {
        return [
            ['nodeConfig', 'httpPort'],
            ['nodeConfig', 'transferPort'],
            ['nodeConfig', 'dbUser'],
            ['nodeConfig', 'dbPassword'],
        ];
    }
}
