import CmonHaProxyHost, {
    CmonHaProxyHostProps,
} from '../cmon/models/CmonHaProxyHost';

export interface CcHaProxyNodeProps extends CmonHaProxyHostProps {}

export default class CcHaProxyNode extends CmonHaProxyHost {
    constructor(props: CcHaProxyNodeProps) {
        super(props);
    }
}
