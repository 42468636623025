import React from 'react';
// import useFetch from '../common/useFetch';
// import CcService from '../services/CcService';
// import { Button, Descriptions } from 'antd';
// import useBeforeChange from '../common/useBeforeChange';

export default DebugUseFetch;

export type DebugUseFetchProps = {};

function DebugUseFetch() {
    return <div>need update</div>;
    // const { error, loading, loaded, data, params, refresh, cancel } = useFetch({
    //     name: 'debug-use-fetch',
    //     fetchFn: async (params, opts) => {
    //         const { stats } = await CcService.getAlarmsOverview(params, opts);
    //         return {
    //             stats,
    //         };
    //     },
    //     cancelFn: async ({ requestId }) => {
    //         await CcService.cancelRequest(requestId);
    //     },
    // });
    //
    // const previousLoading = useBeforeChange(loading);
    // const previousLoaded = useBeforeChange(loaded);
    // const previousError = useBeforeChange(error);
    // const previousData = useBeforeChange(data);
    // const previousParams = useBeforeChange(params);
    // const previousRefresh = useBeforeChange(refresh);
    // const previousCancel = useBeforeChange(cancel);
    //
    // useEffect(() => {
    //     // refresh({ useCache: false });
    // }, []);
    // return (
    //     <div className="DebugUseFetch">
    //         <h1>useFetch</h1>
    //         <Descriptions bordered>
    //             <Descriptions.Item
    //                 label="loading"
    //                 span={2}
    //             >{`${loading}`}</Descriptions.Item>
    //             <Descriptions.Item label="prev loading">{`${previousLoading}`}</Descriptions.Item>
    //             <Descriptions.Item
    //                 label="loaded"
    //                 span={2}
    //             >{`${loaded}`}</Descriptions.Item>
    //             <Descriptions.Item label="prev loaded">{`${previousLoaded}`}</Descriptions.Item>
    //             <Descriptions.Item
    //                 label="error"
    //                 span={2}
    //             >{`${error}`}</Descriptions.Item>
    //             <Descriptions.Item label="prev error">{`${previousError}`}</Descriptions.Item>
    //
    //             <Descriptions.Item label="params" span={2}>{`${JSON.stringify(
    //                 params
    //             )}`}</Descriptions.Item>
    //             <Descriptions.Item label="prev params">
    //                 <span
    //                     style={{
    //                         color: previousParams !== params ? 'red' : 'black',
    //                     }}
    //                 >{`${JSON.stringify(previousParams)}`}</span>
    //             </Descriptions.Item>
    //             <Descriptions.Item label="refresh" span={2}>
    //                 {typeof refresh}
    //             </Descriptions.Item>
    //             <Descriptions.Item label="prev refresh">
    //                 <span
    //                     style={{
    //                         color:
    //                             previousRefresh !== refresh ? 'red' : 'black',
    //                     }}
    //                 >
    //                     {typeof previousRefresh}
    //                 </span>
    //             </Descriptions.Item>
    //             <Descriptions.Item label="cancel" span={2}>
    //                 {typeof cancel}
    //             </Descriptions.Item>
    //             <Descriptions.Item label="prev cancel">
    //                 <span
    //                     style={{
    //                         color: previousCancel !== cancel ? 'red' : 'black',
    //                     }}
    //                 >
    //                     {typeof previousCancel}
    //                 </span>
    //             </Descriptions.Item>
    //             <Descriptions.Item label="test" span={3}>
    //                 <Button
    //                     // loading={loading}
    //                     onClick={() => {
    //                         refresh({ useCache: false });
    //                     }}
    //                 >
    //                     Test
    //                 </Button>
    //                 &nbsp;
    //                 <Button
    //                     disabled={!loading}
    //                     onClick={() => {
    //                         cancel();
    //                     }}
    //                 >
    //                     Cancel
    //                 </Button>
    //                 &nbsp;
    //                 <Button
    //                     onClick={async () => {
    //                         refresh();
    //                         setTimeout(() => {
    //                             refresh({ useCache: true });
    //                         }, 500);
    //                     }}
    //                 >
    //                     Test Cache
    //                 </Button>
    //                 &nbsp;
    //                 <Button
    //                     onClick={() => {
    //                         refresh({ useCache: false });
    //                         setTimeout(() => {
    //                             refresh({
    //                                 useCache: false,
    //                                 filters: [
    //                                     {
    //                                         key: 'cluster_type',
    //                                         values: ['GALERA'],
    //                                     },
    //                                 ],
    //                             });
    //                         }, 500);
    //                     }}
    //                 >
    //                     Test Cancel
    //                 </Button>
    //                 &nbsp;
    //                 <Button
    //                     onClick={() => {
    //                         refresh({ filters: [] });
    //                     }}
    //                 >
    //                     Clear Filters
    //                 </Button>
    //             </Descriptions.Item>
    //             <Descriptions.Item label="data" span={2}>
    //                 <div style={{ width: '200px' }}>{`${JSON.stringify(
    //                     data
    //                 )}`}</div>
    //             </Descriptions.Item>
    //             <Descriptions.Item label="prev data">
    //                 <div style={{ width: '200px' }}>{`${JSON.stringify(
    //                     previousData
    //                 )}`}</div>
    //             </Descriptions.Item>
    //         </Descriptions>
    //     </div>
    // );
}
