


export interface CmonTableProps  {

}



export default class CmonTable {


    constructor(props: CmonTableProps) {

    }

}
