import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    AppState,
    AppStateLogStatsRecord,
    setLogStats,
} from '../../appReducer';

import useFetch from '../../common/useFetch';

import CmonLogService from '../../services/cmon/CmonLogService';
import CcLogStats, { LogsCountProps } from '../../services/models/CcLogStats';

export type UseLogsStatsProps = {
    name?: string | null;
    useGlobalState?: boolean;
    useCache?: boolean;
};
export default function useLogStats({
    name,
    useGlobalState = true,
    useCache = false,
}: UseLogsStatsProps = {}) {
    const [storedRecord, lastSeenLog]: [
        AppStateLogStatsRecord,
        Date
    ] = useSelector(({ logStats, userUIConfig }: AppState) => [
        logStats,
        userUIConfig.getIn(['notifications', 'lastSeenLog']) as Date,
    ]);
    const lastSeenLogRef = useRef<Date>(lastSeenLog);

    useEffect(() => {
        lastSeenLogRef.current = lastSeenLog;
    }, [lastSeenLog]);

    const { error, loading, data, params, refresh, cancel } = useFetch<any>({
        name,
        useCache,
        fetchFn: async (params, opts) => {
            // ref needed for lastseenlog
            const { log_entry_counts } = await CmonLogService.getLogEntries(
                {
                    // ref needed for lastseenlog otherwise is not updated
                    created_after: lastSeenLogRef.current.toISOString(),
                    ...params,
                },
                opts
            );
            return {
                stats: log_entry_counts,
            };
        },
        cancelFn: async ({ requestId }) => {
            await CmonLogService.cancelRequest(requestId);
        },
    });
    const [record, setRecord] = useState<CcLogStats>();
    const dispatch = useDispatch();

    useEffect(() => {
        if (data) {
            const newLogsCount: LogsCountProps = data.stats
                ? data.stats.severity
                : {};

            const stats = new CcLogStats({ newLogsCount });
            if (useGlobalState) {
                dispatch(setLogStats(stats));
            }
            setRecord(stats);
        }
    }, [data]);

    useEffect(() => {
        if (useGlobalState && storedRecord) {
            setRecord(storedRecord);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [storedRecord]);

    return {
        error,
        loading,
        record,
        refresh,
        cancel,
        ...params,
    };
}
