import React, { useEffect, useMemo, useState } from 'react';
import { Col, Form, Row } from 'antd';
import CcUser from '../../../services/models/CcUser';
import ModalDefaultForm from '../../../common/ModalDefaultForm';
import CcGroup from '../../../services/models/CcGroup';
import FormFooter from '../../../common/FormFooter';
import {
    notifyError,
    notifyOperationSuccess,
    NotifyType,
} from '../../Notifications/uiNotification';
import UserSelect from '../UserSelect';
import FormItem from '../../../common/DataEntry/FormItem';
import {
    CcTreeItemAccess,
    CcTreeItemHandledAclPath,
} from '../../../services/models/CcTreeItem';
import { PermissionLevelType } from '../PermissionLevelSelect';
import PermissionsForm from '../PermissionsForm';
import ClustersPermissionsForm from '../ClustersPermissionsForm';
import useTree, { aclItemsPathsDeps, AclPermissionItem } from '../useTree';
import {
    getClusterPermissions,
    getInitialValues,
} from '../userManagementHelper';
import CmonTreeService from '../../../services/cmon/CmonTreeService';
import useUsersList from '../useUsersList';
import { arrayDifference } from '../../../common/filtering';
import CmonUsersService from '../../../services/cmon/CmonUsersService';
import { FormInstance } from 'antd/es';
import useGroupTree from '../useGroupTree';

export default GroupEditModal;

export type GroupEditModalProps = {
    group: CcGroup;
    onCancel?: () => void;
    onSuccess?: () => void;
    onError?: (err: any) => void;
    form?: FormInstance;
};

export interface GroupEditWizardFormValues {
    users: CcUser[];
    permissionLevel: PermissionLevelType;
    permissions: { [key in CcTreeItemHandledAclPath]?: boolean };
    clusterPermissions: { [key: string]: PermissionLevelType };
}

function GroupEditModal({
    group,
    onCancel,
    onSuccess,
    onError,
    form: parentForm,
}: GroupEditModalProps) {
    const [loading, setLoading] = useState(false);
    const [internalForm] = Form.useForm<GroupEditWizardFormValues>();
    const form = parentForm || internalForm;
    const {
        list: groupUsers,
        loading: loadingGroupUsers,
        refresh: refreshGroupUsers,
    } = useUsersList({
        pageSize: 0,
        useCache: true,
        filters: [
            (user: CcUser) => user.getGroup().groupName === group.groupName,
        ],
    });

    // current logged user permissions, this will be the max permission it can
    // give to the edited group
    const { getEffectiveAclOverActions, getEffectiveAclOverClusters } = useTree(
        {
            name: 'tree-from-group-edit-form',
        }
    );

    const groupNoClusterPermissions = getEffectiveAclOverActions([
        CcTreeItemAccess.FULL_ACCESS,
        CcTreeItemAccess.EXECUTE,
    ]);
    const groupClusterPermissions = getEffectiveAclOverClusters([
        CcTreeItemAccess.FULL_ACCESS,
        CcTreeItemAccess.EXECUTE,
    ]);

    // this are the permissios of the team we are editing
    const {
        getAclOverActions,
        getAclOverClusters,
        refresh: refreshCurrentAcl,
        loaded: loadedCurrentAcl,
        loading: loadingCurrentAcl,
    } = useGroupTree({
        name: 'current-group-tree-from-group-edit-form',
        groupName: group.groupName,
    });

    const currentGroupNoClusterPermissions = getAclOverActions();
    const currentGroupClusterPermissions = getAclOverClusters();

    useEffect(() => {
        (async () => {
            await refreshGroupUsers();
        })();
        (async () => {
            await refreshCurrentAcl();
        })();
    }, []);

    const handleCancel = () => {
        onCancel?.();
    };

    const handleSubmit = async (fields: GroupEditWizardFormValues) => {
        try {
            setLoading(true);
            const { users, permissions, clusterPermissions } = fields;

            // current users - new users
            const usersToRemove = arrayDifference(
                groupUsers || [],
                users,
                (user) => user.userId
            );

            // removing users
            // 1. moving users to nobody
            await Promise.all(
                usersToRemove
                    // filter out users that are in 'nobody' team already (this can happen, but it should not)
                    .filter(
                        (user) =>
                            !user.userGroups
                                .map((group: CcGroup) => group.groupName)
                                .includes('nobody')
                    )
                    // users cannot have 0 groups so we move them to 'nobody' group
                    .map((user) =>
                        CmonUsersService.addToGroup({
                            group_name: 'nobody',
                            // using this field will remove user from current group
                            replace_primary_group: true,
                            user: {
                                class_name: 'CmonUser',
                                user_name: user.userName,
                            },
                        })
                    )
            );

            // new users - current users
            const usersToAdd = arrayDifference(
                users,
                groupUsers || [],
                (user) => user.userId
            );

            // adding users
            await Promise.all(
                usersToAdd.map((user) =>
                    CmonUsersService.addToGroup({
                        group_name: group.groupName,
                        replace_primary_group: true,
                        user: {
                            class_name: 'CmonUser',
                            user_name: user.userName,
                        },
                    })
                )
            );

            // permissions
            await Promise.all(
                currentGroupNoClusterPermissions
                    // filter to update only what has changed
                    .filter(
                        (permissionItem) =>
                            permissions[
                                permissionItem.key as CcTreeItemHandledAclPath
                            ] !==
                            [
                                CcTreeItemAccess.FULL_ACCESS,
                                CcTreeItemAccess.EXECUTE,
                            ].includes(permissionItem.level)
                    )
                    // do add acl for paths and dependencies
                    .reduce(
                        (
                            acc: Promise<any>[],
                            permissionItem: AclPermissionItem
                        ) => {
                            acc.push(
                                CmonTreeService.addAcl({
                                    acl: `group:${group.groupName}:${
                                        permissions[
                                            permissionItem.key as CcTreeItemHandledAclPath
                                        ]
                                            ? CcTreeItemAccess.FULL_ACCESS
                                            : CcTreeItemAccess.NO_ACCESS
                                    }`,
                                    path: permissionItem.key,
                                })
                            );
                            if (
                                aclItemsPathsDeps[
                                    permissionItem.key as CcTreeItemHandledAclPath
                                ] &&
                                permissions[
                                    permissionItem.key as CcTreeItemHandledAclPath
                                ]
                            ) {
                                // here we are adding the requests for dependency paths
                                acc.push(
                                    ...aclItemsPathsDeps[
                                        permissionItem.key as CcTreeItemHandledAclPath
                                    ]!.map((depKey) =>
                                        CmonTreeService.addAcl({
                                            acl: `group:${group.groupName}:${CcTreeItemAccess.FULL_ACCESS}`,
                                            path: depKey,
                                        })
                                    )
                                );
                            }
                            return acc;
                        },
                        []
                    )
            );

            //clusterPermissions
            await Promise.all(
                Object.keys(clusterPermissions).map((permissionKey) =>
                    CmonTreeService.addAcl({
                        acl: `group:${group.groupName}:${clusterPermissions[permissionKey]}`,
                        path: permissionKey,
                    })
                )
            );
            if (
                Object.keys(clusterPermissions).some(
                    (permissionKey) =>
                        clusterPermissions[permissionKey] ===
                        CcTreeItemAccess.FULL_ACCESS
                )
            ) {
                // this means that group needs to have job executor permssion for managing at least 1 cluster
                await CmonTreeService.addAcl({
                    acl: `group:${group.groupName}:${CcTreeItemAccess.FULL_ACCESS}`,
                    path: CcTreeItemHandledAclPath.ACL_JOB_EXECUTOR,
                });
            }

            setLoading(false);
            notifyOperationSuccess({
                type: NotifyType.TOAST,
                title: <span>Group edited succesfully.</span>,
            });
            onSuccess?.();
        } catch (e: any) {
            notifyError({
                content: e.message,
            });
            setLoading(false);
            onError?.(e);
        }
    };

    const handleValuesChange = (values: Partial<GroupEditWizardFormValues>) => {
        if (values.permissionLevel) {
            if (values.permissionLevel !== 'custom') {
                form.setFieldsValue(
                    getClusterPermissions(
                        form.getFieldValue('clusterPermissions'),
                        values.permissionLevel as CcTreeItemAccess
                    )
                );
            }
        }
    };
    const initialValues = useMemo(
        () => ({
            ...getInitialValues(
                currentGroupNoClusterPermissions,
                currentGroupClusterPermissions,
                group.isAdminsGroup()
            ),
            users: groupUsers || [],
        }),

        [groupUsers, loadedCurrentAcl, group]
    );
    return (
        <ModalDefaultForm
            title={`Edit team ${group.groupName}`}
            width={750}
            form={form}
            onCancel={handleCancel}
            defaultVisible={true}
            bodyStyle={{ padding: '20px', minHeight: '400px' }}
            loading={loading || loadingGroupUsers || loadingCurrentAcl}
        >
            {groupUsers && loadedCurrentAcl ? (
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={handleSubmit}
                    initialValues={initialValues}
                    onValuesChange={handleValuesChange}
                >
                    <Row gutter={[24, 0]}>
                        <Col xs={24} sm={24} md={24}>
                            <FormItem name="users" label="Users in the group">
                                <UserSelect
                                    mode="multiple"
                                    placeholder="Select one or more users"
                                />
                            </FormItem>
                        </Col>
                    </Row>
                    <Row gutter={[24, 0]}>
                        <Col span={24}>
                            <h3>Permissions</h3>
                        </Col>
                    </Row>
                    <Row gutter={[24, 0]}>
                        <Col xs={24} sm={24} md={24}>
                            <PermissionsForm
                                permissions={groupNoClusterPermissions}
                            />
                        </Col>

                        <Col xs={24} sm={24} md={24}>
                            <FormItem noStyle={true} shouldUpdate={true}>
                                {() => {
                                    return (
                                        <ClustersPermissionsForm
                                            form={form}
                                            permissions={
                                                groupClusterPermissions
                                            }
                                        />
                                    );
                                }}
                            </FormItem>
                        </Col>
                    </Row>

                    <FormFooter
                        loading={loading}
                        submitButtonText="Save"
                        showCancelButton
                        showSubmitButton
                        onCancel={handleCancel}
                    />
                </Form>
            ) : null}
        </ModalDefaultForm>
    );
}
