import './ProxySQLSchedulerScript.less';
import React, { useContext, useEffect } from 'react';
import { ResponsiveContext } from '@severalnines/bar-frontend-components/build/lib/Layout/Responsive';
import { PlusOutlined } from '@ant-design/icons';
import { Button, Row, Space, Typography } from 'antd';
import { TablePaginationConfig } from 'antd/es';
import ActionsMenu, {
    ActionsMenuProps,
} from '../../../../../../common/Navigation/ActionsMenu';
import StatusFormat, {
    StatusFormatStatus,
} from '@severalnines/bar-frontend-components/build/lib/Format/StatusFormat';
import ButtonWithForm from '../../../../../../common/General/ButtonWithForm';
import CcCluster from '../../../../../../services/models/CcCluster';
import CcProxySqlNode from '../../../../../../services/models/CcProxySqlNode';
import CmonProxysqlService from '../../../../../../services/cmon/CmonProxysqlService';
import useListFetch from '../../../../../../common/useListFetch';
import { notifyError } from '../../../../../Notifications/uiNotification';
import CcProxySqlSchedule from '../../../../../../services/models/CcProxySqlSchedule';
import AppConfirmActionButton from '../../../../../../common/General/AppConfirmActionButton';
import AppTable from '../../../../../../common/DataDisplay/AppTable';
import ProxySQLSchedulerScriptModal from './ProxySQLSchedulerScriptModal';

export default ProxySQLSchedulerScript;

export type ProxySQLSchedulerScriptProps = ActionsMenuProps & {
    cluster: CcCluster;
    node: CcProxySqlNode;
    schedulePageSize?: number;
};

function ProxySQLSchedulerScript({
    cluster,
    node,
    schedulePageSize = 15,
    ...rest
}: ProxySQLSchedulerScriptProps) {
    const { responsive }: any = useContext(ResponsiveContext);

    const {
        loading,
        list: scripts,
        refresh: refreshScript,
        page,
        pageSize,
        total,
    } = useListFetch({
        name: 'querySchedules',
        pageSize: schedulePageSize,
        fetchFn: async ({ pageSize, page, ...rest }, opts) => {
            const response = await CmonProxysqlService.queryschedules(
                {
                    cluster_id: cluster?.clusterId,
                    hostName: node?.hostname,
                    port: node?.port,
                    ...rest,
                },
                opts
            );

            return {
                list: response?.queryResults,
                total: response?.queryResultTotalCount,
            };
        },
        cancelFn: async ({ requestId }) => {
            await CmonProxysqlService.cancelRequest(requestId);
        },
    });

    useEffect(() => {
        refreshScript();
    }, []);

    const handleTableChange = async (pagination: any) => {
        await refreshScript({
            page: pagination.current,
            pageSize: pagination.pageSize,
        });
    };

    const deleteScript = async (script: CcProxySqlSchedule) => {
        try {
            await CmonProxysqlService.deleteschedule({
                hostName: node?.hostname,
                port: node?.port,
                cluster_id: cluster?.clusterId,
                schedule: {
                    id: Number(script.id),
                    class_name: script.className,
                },
            });
            refreshScript();
        } catch (error) {
            notifyError({ content: error.message });
        }
    };

    const columns = [
        {
            title: 'Scheduler ID',
            key: 'schedulerID',
            render: (record: CcProxySqlSchedule) => {
                return <span>{`Scheduler ${record.id}`}</span>;
            },
        },
        {
            title: 'Status',
            key: 'status',
            render: (record: CcProxySqlSchedule) => (
                <StatusFormat
                    status={
                        (record.active as any) === '1'
                            ? StatusFormatStatus.success
                            : StatusFormatStatus.unknown
                    }
                    text={
                        (record.active as any) === '1' ? 'Active' : 'Inactive'
                    }
                />
            ),
        },
        {
            title: 'Interval',
            key: 'intervalMs',
            render: (record: CcProxySqlSchedule) => {
                return <span>{`${record.intervalMs} ms`}</span>;
            },
        },
        {
            title: 'Script Path',
            key: 'filename',
            dataIndex: 'filename',
        },
        {
            title: 'Comment',
            key: 'comment',
            dataIndex: 'comment',
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (record: CcProxySqlSchedule) => (
                <ActionsMenu
                    items={[
                        {
                            key: 'edit-script',
                            label: (
                                <ButtonWithForm
                                    button={<Button type="link">Edit</Button>}
                                    form={
                                        <ProxySQLSchedulerScriptModal
                                            cluster={cluster}
                                            node={node}
                                            onSuccess={refreshScript}
                                            script={record}
                                            edit={true}
                                            title={'Edit script'}
                                        />
                                    }
                                />
                            ),
                        },
                        {
                            key: 'delete-script',
                            waitForConfirm: true,
                            label: (
                                <AppConfirmActionButton
                                    confirmTitle={`Delete Script?`}
                                    critical
                                    onConfirm={() => deleteScript(record)}
                                >
                                    <Typography.Text type="danger">
                                        Delete
                                    </Typography.Text>
                                </AppConfirmActionButton>
                            ),
                        },
                    ]}
                    {...rest}
                />
            ),
        },
    ];

    const pagination: TablePaginationConfig = {
        size: 'default',
        pageSize,
        current: page,
        total,
        hideOnSinglePage: true,
        showQuickJumper: true,
        showSizeChanger: true,
        position: ['bottomCenter'],
    };
    return (
        <div className="ProxySQLSchedulerScript">
            <Row justify="end" style={{ paddingBottom: '1rem' }}>
                <Space>
                    <ButtonWithForm
                        button={
                            <Button disabled={loading} icon={<PlusOutlined />}>
                                Add new script
                            </Button>
                        }
                        form={
                            <ProxySQLSchedulerScriptModal
                                cluster={cluster}
                                node={node}
                                onSuccess={refreshScript}
                                title={'Add new script'}
                            />
                        }
                    />
                </Space>
            </Row>

            <AppTable
                loading={loading}
                rowKey={(record: CcProxySqlSchedule) => record.getKey()}
                onRow={(record: CcProxySqlSchedule, index: any) => ({
                    'data-testid': `scheduler-table-row-${index}`,
                })}
                className="ProxySQLSchedulerScriptTable"
                size="small"
                dataSource={scripts}
                columns={columns}
                responsive={responsive}
                onChange={handleTableChange}
                pagination={pagination}
            />
        </div>
    );
}
