import React, { useState } from 'react';
import { Form, Input } from 'antd';

import FormFooter from '../../../../../../common/FormFooter';

import {
    notifyError,
    notifyOperationSuccess,
    NotifyType,
} from '../../../../../Notifications/uiNotification';
import CcCluster from '../../../../../../services/models/CcCluster';
import CcProxySqlNode from '../../../../../../services/models/CcProxySqlNode';
import CmonProxysqlService from '../../../../../../services/cmon/CmonProxysqlService';
import CcProxySqlVariable from '../../../../../../services/models/CcProxySqlVariable';

export default ProxySQLVariablesForm;

export type ProxySQLVariablesFormProps = {
    cluster: CcCluster;
    node: CcProxySqlNode;
    onSuccess?: () => void;
    onCancel?: () => void;
    onError?: (err: Error) => void;
    variableData: CcProxySqlVariable;
};
export type ProxySQLUsersFormValues = {
    variableValue: string;
};

function ProxySQLVariablesForm({
    onSuccess,
    onError,
    onCancel,
    cluster,
    node,
    variableData,
}: ProxySQLVariablesFormProps) {
    const [form] = Form.useForm<ProxySQLUsersFormValues>();
    const [loading, setLoading] = useState<boolean>(false);

    const handleSubmit = async (values: ProxySQLUsersFormValues) => {
        try {
            setLoading(true);
            await CmonProxysqlService.updatevariable({
                clusterid: cluster?.clusterId,
                hostName: node?.hostname,
                port: node?.port,
                variable: {
                    class_name: variableData.className,
                    variable_name: variableData.variableName,
                    variable_value: values?.variableValue,
                },
            });
            notifyOperationSuccess({
                type: NotifyType.TOAST,
                title: <span>Done</span>,
            });
            setLoading(false);
            onSuccess?.();
        } catch (error: any) {
            setLoading(false);
            notifyError({
                content: error.message,
            });
            onError?.(error);
        }
    };

    return (
        <Form
            className="ProxySQLVariablesForm"
            form={form}
            layout="vertical"
            onFinish={handleSubmit}
        >
            <Form.Item
                name={'variableValue'}
                label=""
                rules={[
                    {
                        required: true,
                        message: 'Enter value.',
                    },
                ]}
            >
                <Input placeholder="Click here to enter value."></Input>
            </Form.Item>

            <FormFooter
                loading={loading}
                showCancelButton
                onCancel={onCancel}
                submitButtonText="Save"
                showSubmitButton
            />
        </Form>
    );
}
