import React from 'react';
import { Space } from 'antd';
import { SpaceProps } from 'antd/lib/space';
import { Property } from 'csstype';

export default SpaceWide;

export type SpaceWideProps = SpaceProps & {
    justify?: Property.JustifyContent;
    padding?: number;
};

function SpaceWide({
    style,
    direction,
    padding,
    justify,
    ...rest
}: SpaceWideProps) {
    return (
        <Space
            direction={direction}
            style={{
                width: '100%',
                justifyContent: justify,
                ...(padding
                    ? direction === 'vertical'
                        ? {
                              paddingTop: `${padding}px`,
                              paddingBottom: `${padding}px`,
                          }
                        : {
                              paddingLeft: `${padding}px`,
                              paddingRight: `${padding}px`,
                          }
                    : {}),
                ...style,
            }}
            {...rest}
        />
    );
}
