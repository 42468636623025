import React, { useRef } from 'react';
import { Space, Tabs } from 'antd';
import BackupsList, { BackupListApi } from './BackupsList';
import BackupSchedulesList, {
    BackupSchedulesListApi,
} from './BackupSchedulesList';
import { BackupsIcon } from '../../common/icons/icons';
import { useNavigate, useParams } from 'react-router-dom';
import LicenseSpaceWrapper from '../License/LicenseSpaceWrapper';
import ElasticSnapshotRepositoryList, {
    ElasticSnapshotRepositoryListApi,
} from '../SnapshotRepository/ElasticSnapshotRepositoryList';
import useClusterList from '../Clusters/useClusterList';
import { CcClusterType } from '../../services/models/CcCluster';
import AppPageWrapper from '../../common/Layout/AppPageWrapper';
import BackupCreateWizardButton from './BackupCreateWizardButton';
import UserAclManageCluster from '../User/UserAclManageCluster';

export enum BackupsPageListType {
    BACKUP = 'list',
    SCHEDULES = 'schedules',
    SNAPSHOT_REPOSITORIES = 'repositories',
}
export default BackupsPage;
export type BackupsPageProps = {};
function BackupsPage({}: BackupsPageProps) {
    const navigate = useNavigate();
    const schedulesRef = useRef<BackupSchedulesListApi>();
    const backupsRef = useRef<BackupListApi>();
    const snapshotsRef = useRef<ElasticSnapshotRepositoryListApi>();
    const { listType = BackupsPageListType.BACKUP } = useParams<{
        listType: BackupsPageListType;
    }>();
    const { hasClusterType } = useClusterList({ fromStore: true });
    const handleWizardSuccess = async () => {
        switch (listType) {
            case BackupsPageListType.BACKUP:
                await backupsRef.current?.refresh();
                break;
            case BackupsPageListType.SCHEDULES:
                await schedulesRef.current?.refresh();
                break;
            case BackupsPageListType.SNAPSHOT_REPOSITORIES:
                await snapshotsRef.current?.refresh();
                break;
        }
    };
    return (
        <AppPageWrapper
            className="BackupsPage"
            title={
                <span>
                    <BackupsIcon /> Backups
                </span>
            }
            headerMenu={
                <>
                    <Space size={'large'}>
                        <UserAclManageCluster>
                            <BackupCreateWizardButton
                                onSuccess={handleWizardSuccess}
                            />
                        </UserAclManageCluster>
                    </Space>
                </>
            }
            transparentBackground={true}
            noPadding={true}
        >
            <LicenseSpaceWrapper>
                <Tabs
                    onTabClick={(key) => {
                        navigate(`/backup/${key}`);
                    }}
                    activeKey={listType}
                >
                    <Tabs.TabPane
                        tab="All Backups"
                        key={BackupsPageListType.BACKUP}
                    >
                        <BackupsList ref={backupsRef} />
                    </Tabs.TabPane>
                    <Tabs.TabPane
                        tab="Backup Schedules"
                        key={BackupsPageListType.SCHEDULES}
                    >
                        <BackupSchedulesList ref={schedulesRef} />
                    </Tabs.TabPane>
                    {hasClusterType(CcClusterType.TYPE_ELASTIC) && (
                        <Tabs.TabPane
                            tab="Elastic repositories"
                            key={BackupsPageListType.SNAPSHOT_REPOSITORIES}
                        >
                            <ElasticSnapshotRepositoryList ref={snapshotsRef} />
                        </Tabs.TabPane>
                    )}
                </Tabs>
            </LicenseSpaceWrapper>
        </AppPageWrapper>
    );
}
