import CmonMsSqlHost, {
    CmonMsSqlHostProps,
    CmonMsSqlHostReplica,
    CmonMsSqlHostReplicaProps,
} from '../cmon/models/CmonMsSqlHost';
import CcNodeReplicationSlave, {
    CcNodeReplicationSlaveProps,
} from './CcNodeReplicationSlave';

export interface CcMsSqlNodeReplicationSlaveProps
    extends Omit<CcNodeReplicationSlaveProps, 'master_port'> {
    master_host?: string;
    master_port?: number;
}

export interface CcMsSqlNodeReplicaProps extends CmonMsSqlHostReplicaProps {}

export class CcMsSqlNodeReplica extends CmonMsSqlHostReplica {
    constructor(props: CcMsSqlNodeReplicaProps) {
        super(props);
    }
}

export interface CcMsSqlNodeProps
    extends Omit<CmonMsSqlHostProps, 'replication_slave'> {
    replica?: CcMsSqlNodeReplicaProps;
    replication_slave?: CcMsSqlNodeReplicationSlaveProps;
}
export class CcMsSqlNodeReplicationSlave extends CcNodeReplicationSlave {
    public masterHost?: string;
    public masterPort?: number;

    constructor(props: CcMsSqlNodeReplicationSlaveProps) {
        super(props);
        this.masterHost = props.master_host;
        this.masterPort = props.master_port;
    }
}

export default class CcMsSqlNode extends CmonMsSqlHost {
    public replicationSlave?: CcMsSqlNodeReplicationSlave;
    public replica?: CcMsSqlNodeReplica;
    constructor(props: CcMsSqlNodeProps) {
        super(props);
        // @todo recheck if replicationSlave is comming from backend
        this.replicationSlave =
            props.replication_slave &&
            new CcMsSqlNodeReplicationSlave(props.replication_slave);
        this.replica = props?.replica && new CcMsSqlNodeReplica(props.replica);
    }

    isReplicationRunning() {
        return this.replica?.synchronizationHealth === 'HEALTHY';
    }
}
