import React, { useMemo } from 'react';
import Form, { FormItemProps } from 'antd/lib/form';
import { Select } from 'antd';

export default MongoNodeTypeSelectField;

export enum MongoNodeType {
    DB = 0,
    CONFIG = 2,
    ARBITER = 3,
}

export type MongoNodeTypeSelectFieldProps = FormItemProps & {
    isShard?: boolean;
};

function MongoNodeTypeSelectField({
    isShard = false,
    ...rest
}: MongoNodeTypeSelectFieldProps) {
    const types = useMemo(
        () =>
            isShard
                ? [
                      MongoNodeType.DB,
                      MongoNodeType.CONFIG,
                      MongoNodeType.ARBITER,
                  ]
                : [MongoNodeType.DB, MongoNodeType.ARBITER],
        [isShard]
    );
    return (
        <Form.Item
            name="mongoNodeType"
            rules={[
                {
                    required: true,
                    message: 'Please select node type',
                },
            ]}
            label="Node type"
            {...rest}
        >
            <Select
                placeholder="Choose a node type"
                options={types.map((type) => ({
                    value: type,
                    label: getMongoNodeTypeName(type),
                }))}
            />
        </Form.Item>
    );
}

function getMongoNodeTypeName(type: MongoNodeType) {
    switch (type) {
        case MongoNodeType.DB:
            return 'Db server';
        case MongoNodeType.CONFIG:
            return 'Config server';
        case MongoNodeType.ARBITER:
            return 'Arbiter';
    }
}
