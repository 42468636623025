import { useState, useEffect } from 'react';
import { AppConfig } from '../../AppConfig';
import RegistrationShort from './RegistrationShort';
import RegistrationFull from './RegistrationFull';
import CmonAuthService from '../../services/cmon/CmonAuthService';
import CcUser from '../../services/models/CcUser';
import LoadingPage from '../../common/LoadingPage';

function Registration() {
    const [user, setUser] = useState<CcUser>();

    useEffect(() => {
        (async () => {
            try {
                const { user } = await CmonAuthService.authenticateWithPassword(
                    {
                        user_name: AppConfig.INITIAL_USER,
                        password: AppConfig.INITIAL_PASSWORD,
                    }
                );
                setUser(user);
            } catch (err) {
                console.log(err);
                window.location.assign(`/logout`);
            }
        })();
    }, []);

    return !user ? (
        <LoadingPage />
    ) : AppConfig.USER_REGISTRATION ? (
        <RegistrationFull user={user} />
    ) : (
        <RegistrationShort user={user} />
    );
}

export async function requestCMONVersion(): Promise<string | undefined> {
    const response = await fetch(`${AppConfig.CMON_API_URL}/controller`, {
        method: 'POST',
        body: JSON.stringify({
            operation: 'ping',
        }),
    });
    return response.headers.get('server')?.split('/')[1];
}

export default Registration;
