import React from 'react';
import { Popover, PopoverProps } from 'antd';
import './AppPopover.less';
import classNames from 'classnames';

export default AppPopover;

export type AppPopoverProps = PopoverProps & {};

function AppPopover({ overlayClassName, ...rest }: AppPopoverProps) {
    return (
        <Popover
            overlayClassName={classNames(
                'AppPopover_overlay',
                overlayClassName
            )}
            {...rest}
        />
    );
}
