


export interface CmonVariantProps  {

}



export default class CmonVariant {


    constructor(props: CmonVariantProps) {

    }

}
