import React from 'react';
import { Form, Space } from 'antd';
import InfoIcon from '@severalnines/bar-frontend-components/build/lib/General/InfoIcon';
import FormItemInlineSwitch, {
    FormItemInlineSwitchProps,
} from '../../../../../common/DataEntry/FormItemInlineSwitch';
import BackupSelect from '../../../../../common/DataEntry/BackupSelect';
import CcBackup from '../../../../../services/models/CcBackup';
import SpaceWide from '../../../../../common/SpaceWide';
import BackupFormat from '../../../../Backups/BackupFormat';
import StorageLocationFormat from '../../../../Backups/BackupStorageFormat';

export default RebuildFromBackupSelectField;

export type BackupSelectFieldProps = FormItemInlineSwitchProps & {
    clusterId: number;
};

function RebuildFromBackupSelectField({
    clusterId,
    ...rest
}: BackupSelectFieldProps) {
    return (
        <div className="BackupSelectField">
            <FormItemInlineSwitch
                justify={true}
                name="rebuildFromBackup"
                label={
                    <Space>
                        Rebuild from a backup
                        <InfoIcon
                            info={
                                <span>
                                    Select an existing backup to initially stage
                                    the slave from instead of rebuilding
                                    directly from a master node.
                                </span>
                            }
                        />
                    </Space>
                }
                valuePropName="checked"
                extraOnSwitch={
                    <div>
                        <Form.Item
                            name="backup"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please select backup',
                                },
                                {
                                    validator: backupRebuildLocationValidator,
                                },
                            ]}
                        >
                            <BackupSelect
                                clusterId={clusterId}
                                filter={(backup) => backup?.canUseForPitr()}
                                renderLabel={(backup: CcBackup) => (
                                    <SpaceWide justify={'space-between'}>
                                        <BackupFormat
                                            backup={backup}
                                            shortBackupName={true}
                                        />
                                        <StorageLocationFormat
                                            backup={backup}
                                        />
                                    </SpaceWide>
                                )}
                            />
                        </Form.Item>
                    </div>
                }
                {...rest}
            />
        </div>
    );
}

export async function backupRebuildLocationValidator(
    rule: any,
    value: CcBackup
) {
    if (
        value &&
        value?.cloudLocations?.length > 0 &&
        value?.hostLocations?.length < 1
    ) {
        throw new Error(
            'The selected backup is stored in the cloud. To use the backup you have to go to the Backup page and download it first.'
        );
    }
    return true;
}
