import React from 'react';
import AppEmpty from '../Feedback/AppEmpty';
import UserAclExecuteClusters from '../../components/User/UserAclExecuteClusters';
import CreateServiceButton from '../../components/Services/CreateServiceButton';
import { Button } from 'antd';

export default FilteredTableEmpty;
export type FilteredTableEmptyProps = {
    onReset?: () => void;
};

function FilteredTableEmpty({ onReset, ...rest }: FilteredTableEmptyProps) {
    return (
        <AppEmpty
            className="ClustersEmpty"
            description="There are no result with current selected filters, please change filters or reset them."
            extra={
                onReset ? (
                    <Button type="primary" onClick={onReset}>
                        Reset filters
                    </Button>
                ) : undefined
            }
            {...rest}
        />
    );
}
