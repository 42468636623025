import CcStatsBase, { CcStatsBaseAttentionLevel } from './CcStatsBase';
import { CcAlarmSeverity } from './CcAlarm';

export type AlarmsCountProps = {
    [key in CcAlarmSeverity]?: number;
};

export type CcAlarmStatsProps = {
    alarmsCount: AlarmsCountProps;
};

export default class CcAlarmStats extends CcStatsBase {
    constructor(props: CcAlarmStatsProps) {
        super({
            statsCount: props.alarmsCount,
            attentionErrorStats: [CcAlarmSeverity.ALARM_CRITICAL],
            attentionWarnStats: [CcAlarmSeverity.ALARM_WARNING],
        });
    }

    public getFirstAttentionStat(
        level?: CcStatsBaseAttentionLevel
    ): [CcAlarmSeverity, number] {
        return super.getFirstAttentionStat(level) as [CcAlarmSeverity, number];
    }

    public getAttentionStatsValues(
        level?: CcStatsBaseAttentionLevel
    ): CcAlarmSeverity[] {
        return super.getAttentionStatsValues(level) as CcAlarmSeverity[];
    }
}
