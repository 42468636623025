import { useParams } from 'react-router-dom';
import CcCluster from '../../services/models/CcCluster';
import { useSelector } from 'react-redux';
import { AppState } from '../../appReducer';

export default useCurrentCluster;
export type UseCurrentClusterProps = { clusterId?: number };

function useCurrentCluster({ clusterId }: UseCurrentClusterProps = {}):
    | CcCluster
    | undefined {
    const { clusterId: urlClusterId } = useParams<{
        clusterId: string;
    }>();
    return useSelector(({ clusters }: AppState) => {
        const id = clusterId?.toString() || urlClusterId;
        if (!id || !clusters.has(id)) {
            return undefined;
        }
        return clusters.get(id);
    });
}
