import React, { Fragment, ReactNode, useMemo } from 'react';
import WrapFormat from '../../common/Format/WrapFormat';
import CcNode, { CcNodeType } from '../../services/models/CcNode';
import StatusFormat, {
    StatusFormatStatus,
} from '@severalnines/bar-frontend-components/build/lib/Format/StatusFormat';
import SpaceDescriptions from '../../common/Layout/SpaceDescriptions';
import SpaceWide from '../../common/SpaceWide';
import CcMySqlNode from '../../services/models/CcMySqlNode';
import CcRedisNode from '../../services/models/CcRedisNode';
import UnavailableFormat from '@severalnines/bar-frontend-components/build/lib/Format/UnavailableFormat';
import CcMsSqlNode from '../../services/models/CcMsSqlNode';

export default NodeReplicationFormat;

export type NodeReplicationFormatProps = {
    node: CcNode;
    children?: React.ReactNode;
};

function NodeReplicationFormat({ node, children }: NodeReplicationFormatProps) {
    const replicationInfo = useMemo(() => {
        const masterHost = node.replicationSlave?.getMasterAddress();
        let commonContent: ReactNode = (
            <Fragment>
                <SpaceDescriptions.Item label="Primary address">
                    <UnavailableFormat val={masterHost}>
                        {masterHost}
                    </UnavailableFormat>
                </SpaceDescriptions.Item>
                <SpaceDescriptions.Item label="Lag">
                    <UnavailableFormat
                        val={node.replicationSlave?.secondsBehindMaster}
                        checks={[null, undefined, '']}
                    >
                        {node.replicationSlave?.secondsBehindMaster}s
                    </UnavailableFormat>
                </SpaceDescriptions.Item>
            </Fragment>
        );
        if (node.isType(CcNodeType.MYSQL) && node.isReplica()) {
            const mysqlNode = node as CcMySqlNode;
            return (
                <SpaceDescriptions direction="vertical" size={5}>
                    {commonContent}
                    <SpaceDescriptions.Item label="Slave IO running">
                        {mysqlNode.replicationSlave?.slaveIoRunning}
                    </SpaceDescriptions.Item>
                    {!mysqlNode.isReplicationIoRunning() ? (
                        <SpaceDescriptions.Item label="Slave IO Error">
                            {node.replicationSlave.lastIoErrno}
                            {node.replicationSlave.lastIoErrno
                                ? `: ${node.replicationSlave.lastIoError} at ${node.replicationSlave.lastIoErrorTimestamp}`
                                : ''}
                        </SpaceDescriptions.Item>
                    ) : null}
                    <SpaceDescriptions.Item label="Slave SQL running">
                        {node.replicationSlave.slaveSqlRunning}
                    </SpaceDescriptions.Item>
                    {!mysqlNode.isReplicationSqlRunning() ? (
                        <SpaceDescriptions.Item label="Slave SQL Error">
                            {node.replicationSlave.lastSqlErrno}
                            {!!node.replicationSlave.lastSqlErrno
                                ? `: ${node.replicationSlave.lastSqlError} at ${node.replicationSlave.lastSqlErrorTimestamp}`
                                : ''}
                        </SpaceDescriptions.Item>
                    ) : null}
                </SpaceDescriptions>
            );
        } else if (node.isType(CcNodeType.REDIS) && node.isReplica()) {
            const redisNode = node as CcRedisNode;
            return (
                <SpaceDescriptions direction="vertical" size={5}>
                    {commonContent}
                    <SpaceDescriptions.Item label="Link status">
                        {redisNode.replicationSlave?.linkstatus}
                    </SpaceDescriptions.Item>
                    <SpaceDescriptions.Item label="Replication status">
                        {redisNode.replicationSlave?.status}
                    </SpaceDescriptions.Item>
                </SpaceDescriptions>
            );
        } else if (node.isType(CcNodeType.MSSQL) && node.isReplica()) {
            const mssqlNode = node as CcMsSqlNode;
            return (
                <SpaceDescriptions direction="vertical" size={5}>
                    {commonContent}
                    <SpaceDescriptions.Item label="Connected state">
                        {mssqlNode.replica?.connectedState}
                    </SpaceDescriptions.Item>
                    <SpaceDescriptions.Item label="Recovery health">
                        {mssqlNode.replica?.recoveryHealth}
                    </SpaceDescriptions.Item>
                    <SpaceDescriptions.Item label="Synchronization health">
                        {mssqlNode.replica?.synchronizationHealth}
                    </SpaceDescriptions.Item>
                </SpaceDescriptions>
            );
        } else {
            return (
                <SpaceDescriptions direction="vertical" size={5}>
                    {commonContent}
                </SpaceDescriptions>
            );
        }
    }, [node]);
    return (
        <WrapFormat
            className="NodeReplicationFormat"
            popoverStatus={
                node?.replicationSlave?.isReplicationRunning()
                    ? StatusFormatStatus.success
                    : StatusFormatStatus.error
            }
            popoverContent={
                <SpaceWide direction="vertical" size={20}>
                    <SpaceDescriptions direction="horizontal" size={20}>
                        {replicationInfo}
                    </SpaceDescriptions>
                </SpaceWide>
            }
        >
            {children || (
                <StatusFormat
                    status={
                        node?.replicationSlave?.isReplicationRunning()
                            ? StatusFormatStatus.success
                            : StatusFormatStatus.error
                    }
                />
            )}
        </WrapFormat>
    );
}
