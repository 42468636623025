import './PgBouncerDetailsContent.less';
import React, { useContext, useEffect } from 'react';
import { ResponsiveContext } from '@severalnines/bar-frontend-components/build/lib/Layout/Responsive';
import { PlusOutlined } from '@ant-design/icons';
import { Button, Divider, Row, Space, Typography } from 'antd';
import ActionsMenu, {
    ActionsMenuProps,
} from '../../../../common/Navigation/ActionsMenu';
import ButtonWithForm from '../../../../common/General/ButtonWithForm';
import CcCluster from '../../../../services/models/CcCluster';
import CcPostgreSqlNode from '../../../../services/models/CcPostgreSqlNode';
import { notifyError } from '../../../Notifications/uiNotification';
import AppConfirmActionButton from '../../../../common/General/AppConfirmActionButton';
import AppTable from '../../../../common/DataDisplay/AppTable';
import CmonConfigService from '../../../../services/cmon/CmonConfigService';
import useFetch from '../../../../common/useFetch';
import PgBouncerPoolModal from './PgBouncerPoolModal';
import { parseConfigPools } from './pgBouncerPoolHelper';

export default PgBouncerDetailsContent;

export type PgBouncerDetailsContentProps = ActionsMenuProps & {
    cluster: CcCluster;
    node: CcPostgreSqlNode;
};

function PgBouncerDetailsContent({
    cluster,
    node,
    ...rest
}: PgBouncerDetailsContentProps) {
    const { responsive } = useContext(ResponsiveContext);

    const { loading, data, refresh: refreshPool } = useFetch<any>({
        name: 'getConfig',
        fetchFn: async (params, opts) => {
            const data = await CmonConfigService.getConfig(
                {
                    ...params,
                    cluster_id: cluster?.clusterId,
                    hostName: node?.hostname,
                    port: node?.port,
                },
                opts
            );
            return parseConfigPools(data.config);
        },
    });

    useEffect(() => {
        (async () => {
            await refreshPool({});
        })();
    }, []);

    const deletePool = async (pool: any) => {
        try {
            await CmonConfigService.unsetConfig({
                hostName: node?.hostname,
                port: node?.port,
                cluster_id: cluster?.clusterId,
                configuration: [
                    {
                        group: 'databases',
                        name: pool.database,
                    },
                ],
            });
            await refreshPool({});
        } catch (error) {
            notifyError({ content: error.message });
        }
    };

    const columns = [
        {
            title: 'Pool name',
            key: 'database',
            dataIndex: 'database',
        },
        {
            title: 'Username',
            key: 'user',
            dataIndex: 'user',
        },
        {
            title: 'Pool mode',
            key: 'pool_mode',
            dataIndex: 'pool_mode',
            render: (record: string) => (record ? record : 'default'),
        },
        {
            title: 'Pool size',
            key: 'pool_size',
            dataIndex: 'pool_size',
            render: (record: string) => (record ? record : 'default'),
        },
        {
            title: 'Max connections',
            key: 'max_db_connections',
            dataIndex: 'max_db_connections',
            render: (record: string) => (record ? record : 'default'),
        },

        {
            title: 'Actions',
            key: 'actions',
            render: (record: any) => (
                <ActionsMenu
                    items={[
                        {
                            key: 'edit-pool',
                            label: (
                                <ButtonWithForm
                                    button={<Button type="link">Edit</Button>}
                                    form={
                                        <PgBouncerPoolModal
                                            cluster={cluster}
                                            title="Edit pool"
                                            node={node}
                                            edit
                                            pool={record}
                                            onSuccess={async () =>
                                                await refreshPool({})
                                            }
                                        />
                                    }
                                />
                            ),
                        },
                        {
                            key: 'delete-pool',
                            waitForConfirm: true,
                            label: (
                                <AppConfirmActionButton
                                    confirmTitle={`Delete pool?`}
                                    critical
                                    onConfirm={() => deletePool(record)}
                                >
                                    <Typography.Text type="danger">
                                        Delete
                                    </Typography.Text>
                                </AppConfirmActionButton>
                            ),
                        },
                    ]}
                    {...rest}
                />
            ),
        },
    ];

    return (
        <div className="PgBouncerDetailsContent">
            <Divider />
            <Row justify="end" style={{ paddingBottom: '1rem' }}>
                <Space>
                    <ButtonWithForm
                        button={
                            <Button disabled={loading} icon={<PlusOutlined />}>
                                Create new pool
                            </Button>
                        }
                        form={
                            <PgBouncerPoolModal
                                cluster={cluster}
                                title="Create new pool"
                                node={node}
                                onSuccess={async () => await refreshPool({})}
                            />
                        }
                    />
                </Space>
            </Row>

            <AppTable
                loading={loading}
                onRow={(record: any, index: any) => ({
                    'data-testid': `pgBouncer-table-row-${index}`,
                })}
                className="PgBouncerDetailsContentTable"
                size="small"
                dataSource={data}
                columns={columns}
                responsive={responsive}
            />
        </div>
    );
}
