import React, { useEffect } from 'react';
import SpaceWide from '../../common/SpaceWide';
import ButtonWithForm from '../../common/General/ButtonWithForm';
import { PlusOutlined } from '@ant-design/icons';
import CreateReportModal from './CreateReportModal';
import ReportsTable from './ReportsTable';
import AppPageWrapper from '../../common/Layout/AppPageWrapper';
import CcCluster from '../../services/models/CcCluster';
import useListFetch from '../../common/useListFetch';
import CmonReportsService from '../../services/cmon/CmonReportsService';
import { CmonJobInstanceCommand } from '../../services/cmon/models/CmonJobInstance';
import JobButton from '../Jobs/JobButton';

export default ReportsPage;
export type ReportsPageProps = { cluster: CcCluster };

function ReportsPage({ cluster, ...rest }: ReportsPageProps) {
    const { list, loading, refresh } = useListFetch({
        name: 'reports-list',
        fetchFn: async ({ pageSize, page, ...rest }, opts) => {
            const response = await CmonReportsService.listErrorReports(
                {
                    cluster_id: cluster.clusterId,
                    ...rest,
                },
                opts
            );
            return {
                list: response.data.map((item: any) => ({
                    ...item,
                    clusterId: cluster.clusterId,
                })),
                total: response.total,
            };
        },
        cancelFn: async ({ requestId }) => {
            await CmonReportsService.cancelRequest(requestId);
        },
    });

    useEffect(() => {
        (async () => {
            await refresh();
        })();
    }, [cluster.clusterId]);

    const handleReportDeleted = async () => {
        await refresh();
    };
    const handleJobFinished = async () => {
        await refresh();
    };

    return (
        <AppPageWrapper
            className="ReportsPage"
            transparentBackground={true}
            noPadding={true}
        >
            <SpaceWide direction="vertical" size={20}>
                <SpaceWide direction="vertical" align="end">
                    <ButtonWithForm
                        button={
                            <JobButton
                                type="primary"
                                size="middle"
                                command={CmonJobInstanceCommand.ERROR_REPORT}
                                clusterId={cluster.clusterId}
                                clusterType={cluster.clusterType}
                                icon={<PlusOutlined />}
                            >
                                Create error report
                            </JobButton>
                        }
                        form={
                            <CreateReportModal clusterId={cluster.clusterId} />
                        }
                    />
                </SpaceWide>
                <ReportsTable
                    loading={loading}
                    clusterId={cluster.clusterId}
                    dataSource={list}
                    onDeleted={handleReportDeleted}
                    onJobFinished={handleJobFinished}
                />
            </SpaceWide>
        </AppPageWrapper>
    );
}
