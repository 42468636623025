import CcImperativeService from '../../services/CcImperativeService';
import useFetch, { FetchRefreshFunctionParams } from '../../common/useFetch';

export type UseClusterDatabaseUsersProps = {
    name?: string | null;
    clusterId: number | undefined;
    useCache?: boolean;
};
export type DatabaseUsersSchema = {
    account: string;
    db: string;
    host: string;
    is_grantable: boolean;
    max_connections: string;
    max_questions: string;
    max_updates: string;
    max_user_connections: string;
    privileges: string;
    report_hostname: string;
    report_port: number;
    schema: string;
    ssl_cipher: string;
    ssl_type: string;
    table: string;
    user: string;
    x509_issuer: string;
    x509_subject: string;
}[];
const useClusterDatabaseUsers = ({
    name = 'use-cluster-database-users',
    clusterId,
    useCache = false,
}: UseClusterDatabaseUsersProps) => {
    const {
        loading,
        data: usersData,
        refresh: refreshFetch,
    }: {
        loading: boolean;
        refresh: (p?: FetchRefreshFunctionParams | undefined) => Promise<void>;
        data: DatabaseUsersSchema;
    } = useFetch({
        name,
        useCache,
        fetchFn: async (params, opts) => {
            const data = await CcImperativeService.executeScript(
                {
                    ...params,
                    filename: '/s9s/mysql/widgets/usermgmt/show_db_grants.js',
                    arguments: '* * * * *',
                    cluster_id: clusterId,
                },
                opts
            );
            // process to make all accounts together
            const accounts = data?.results?.exitStatus?.accounts;
            let users = [];
            for (const key in accounts) {
                const newUsers = accounts[key];
                users.push(
                    ...Object.keys(newUsers)
                        .filter((key) => typeof newUsers[key] === 'object')
                        .map((key) => newUsers[key])
                );
            }
            return users;
        },
        cancelFn: async ({ requestId }) => {
            await CcImperativeService.cancelRequest(requestId);
        },
    });

    return {
        loading,
        usersData,
        refresh: refreshFetch,
    };
};

export default useClusterDatabaseUsers;
