import ActionButton from '@severalnines/bar-frontend-components/build/lib/General/ActionButton';
import ButtonWithForm from '../../common/General/ButtonWithForm';
import KeyManagementGenerateCertificateModal from './KeyManagementGenerateCertificateModal';

export default KeyManagementGenerateCertificateButton;

export type KeyManagementGenerateCertificateButtonProps = {
    list: any;
    onSuccess?: () => void;
    clientServer: boolean;
};

function KeyManagementGenerateCertificateButton({
    list,
    clientServer = false,
    onSuccess,
}: KeyManagementGenerateCertificateButtonProps) {
    return (
        <ButtonWithForm
            button={
                <ActionButton critical={false}>
                    {clientServer
                        ? 'Generate client/server certificate'
                        : 'Generate selfsigned certificate'}
                </ActionButton>
            }
            form={
                <KeyManagementGenerateCertificateModal
                    clientServer={clientServer}
                    onSuccess={onSuccess}
                    list={list}
                />
            }
        />
    );
}
