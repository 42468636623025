import React, { useState } from 'react';
import { Button } from 'antd';

import CcCluster from '../../../../../../services/models/CcCluster';
import CcProxySqlNode from '../../../../../../services/models/CcProxySqlNode';
import CcProxySqlVariable from '../../../../../../services/models/CcProxySqlVariable';
import ProxySQLVariablesForm from './ProxySQLVariablesForm';
import AppPopover from '../../../../../../common/Feedback/AppPopover';

export type ProxySQLVariablesPopoverProps = {
    cluster: CcCluster;
    node: CcProxySqlNode;
    variableData: CcProxySqlVariable;
    onSuccess?: () => void;
};

function ProxySQLVariablesPopover({
    cluster,
    node,
    variableData,
    onSuccess,
}: ProxySQLVariablesPopoverProps) {
    const [popoverVisible, setPopoverVisible] = useState(false);

    const handleVisibleChange = (visible: boolean) => {
        setPopoverVisible(visible);
    };
    return (
        <AppPopover
            visible={
                variableData?.readOnly === true
                    ? !variableData?.readOnly
                    : popoverVisible
            }
            onVisibleChange={handleVisibleChange}
            placement="leftTop"
            content={
                <ProxySQLVariablesForm
                    cluster={cluster}
                    node={node}
                    onCancel={() => {
                        handleVisibleChange(false);
                    }}
                    onSuccess={() => {
                        handleVisibleChange(false);
                        onSuccess?.();
                    }}
                    variableData={variableData}
                />
            }
            trigger="click"
        >
            <Button disabled={variableData?.readOnly} type="link">
                Edit
            </Button>
        </AppPopover>
    );
}

export default ProxySQLVariablesPopover;
