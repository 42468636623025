import React, { useState } from 'react';
import { Button, DatePicker, Form, Input } from 'antd';
import FormFooter from '../../common/FormFooter';
import ModalDefaultForm from '../../common/ModalDefaultForm';
import CcNode from '../../services/models/CcNode';
import NodeFormat from '../Nodes/NodeFormat';
import SpaceWide from '../../common/SpaceWide';
import CmonMaintenanceService from '../../services/cmon/CmonMaintenanceService';
import Alert from '@severalnines/bar-frontend-components/build/lib/Feedback/Alert';
import moment, { Moment } from 'moment';
import {
    notifyError,
    notifyOperationSuccess,
    NotifyType,
} from '../Notifications/uiNotification';
import CcCluster from '../../services/models/CcCluster';
import ClusterFormat from '../Clusters/ClusterFormat';
import { useDebugContext } from '../../common/Debug';
import useScheduledMaintenancesList from './useScheduledMaintenancesList';
import AppRadio from '../../common/DataEntry/AppRadio';
import DateFormat from '@severalnines/bar-frontend-components/build/lib/Format/DateFormat';

export default AddMaintenanceForm;

export type AddMaintenanceFormProps = {
    node?: CcNode;
    cluster?: CcCluster;
    onSuccess?: () => void;
    onCancel?: () => void;
    onError?: (err: Error) => void;
};
export type AddMaintenanceFormValues = {
    reason?: string;
    range?: string | number;
    startEnd?: Moment[];
};

function AddMaintenanceForm({
    node,
    cluster,
    onSuccess,
    onCancel,
    onError,
}: AddMaintenanceFormProps) {
    const { log } = useDebugContext();
    const [form] = Form.useForm<AddMaintenanceFormValues>();
    const [loading, setLoading] = useState<boolean>(false);

    const { refresh: refreshScheduledMaintenances } =
        useScheduledMaintenancesList({
            name: 'scheduled-maintenances-list-from-AddMaintenanceForm',
        });

    const handleSubmit = async (values: AddMaintenanceFormValues) => {
        try {
            setLoading(true);
            let startMoment;
            let endMoment;
            if (values.range === 'custom') {
                let [customStart, customEnd] = values.startEnd as Moment[];
                startMoment = customStart.utc();
                endMoment = customEnd.utc();
            } else {
                startMoment = moment.utc().add(30, 'seconds');
                endMoment = moment
                    .utc()
                    .add(30, 'seconds')
                    .add(values.range, 'hours');
            }
            await CmonMaintenanceService.addMaintenance({
                deadline: endMoment.toISOString(),
                cluster_id: cluster ? cluster.clusterId : undefined,
                hostname: node ? node.hostname : undefined,
                initiate: startMoment.toISOString(),
                reason: values.reason,
            });
            await refreshScheduledMaintenances();
            notifyOperationSuccess({
                type: NotifyType.TOAST,
                title: (
                    <span>
                        Maintenance scheduled for{' '}
                        {node ? node.getName() : cluster?.clusterName}
                    </span>
                ),
                content:
                    values.range !== 'custom' ? (
                        <span>
                            Maintenance will start in a few seconds and will
                            finish{' '}
                            <DateFormat fromNow>
                                {endMoment.toDate()}
                            </DateFormat>
                            .
                        </span>
                    ) : (
                        <span>
                            Maintenance scheduled from{' '}
                            <DateFormat>{startMoment.toDate()}</DateFormat> to{' '}
                            <DateFormat>{endMoment.toDate()}</DateFormat>.
                        </span>
                    ),
            });
            setLoading(false);
            onSuccess?.();
        } catch (err) {
            log.error(err);
            setLoading(false);
            notifyError({
                content: err.message,
            });
            onError?.(err);
        }
    };
    return (
        <ModalDefaultForm
            title="Schedule maintenance"
            form={form}
            footer={[]}
            onCancel={onCancel}
            width={550}
            defaultVisible={true}
        >
            <Form
                className="AddMaintenanceForm"
                form={form}
                layout="vertical"
                onFinish={handleSubmit}
                initialValues={{ range: 1 }}
            >
                <SpaceWide direction="vertical">
                    {node ? (
                        <NodeFormat
                            node={node}
                            size="large"
                            contentProps={{ style: { width: '100%' } }}
                        />
                    ) : (
                        <ClusterFormat
                            cluster={cluster}
                            size="large"
                            contentProps={{ style: { width: '100%' } }}
                            showId={true}
                        />
                    )}

                    <Form.Item name="reason" label="Reason">
                        <Input.TextArea rows={2} />
                    </Form.Item>
                    <Form.Item name="range" label="Start maintenance mode for">
                        <AppRadio.Group>
                            <AppRadio.Button value={1}>1 hour</AppRadio.Button>
                            <AppRadio.Button value={2}>2 hours</AppRadio.Button>
                            <AppRadio.Button value={3}>3 hours</AppRadio.Button>
                            <AppRadio.Button value={'custom'}>
                                Custom
                            </AppRadio.Button>
                        </AppRadio.Group>
                    </Form.Item>
                    <Form.Item noStyle shouldUpdate={true}>
                        {() => {
                            return form.getFieldValue('range') === 'custom' ? (
                                <Form.Item
                                    label={`Start date - End date (${moment
                                        .tz(moment.tz.guess())
                                        .zoneAbbr()})`}
                                    name="startEnd"
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                'Please select a start and end date',
                                        },
                                        {
                                            validator: validateDateRange,
                                        },
                                    ]}
                                >
                                    <DatePicker.RangePicker showTime />
                                </Form.Item>
                            ) : null;
                        }}
                    </Form.Item>
                    <Alert
                        message={`All alarms and notifications from the ${
                            node ? 'node' : 'cluster'
                        } are disabled during maintenance mode.`}
                        type="warning"
                        showIcon
                    />
                </SpaceWide>
                <FormFooter>
                    <Button
                        key="done"
                        type="primary"
                        htmlType="submit"
                        loading={loading}
                    >
                        Schedule
                    </Button>
                </FormFooter>
            </Form>
        </ModalDefaultForm>
    );
}

export async function validateDateRange(rule: any, value: Moment[]) {
    if (value) {
        const [customStart, customEnd] = value;
        if (customEnd.diff(customStart, 'minutes') <= 0) {
            throw new Error('Start date should be lower than end date.');
        } else if (customStart.diff(moment().utc(), 'seconds') < 0) {
            throw new Error('Start time should be in future.');
        } else {
            return true;
        }
    } else {
        return true;
    }
}
