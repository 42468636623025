import React, { useEffect, useState } from 'react';
import { Form } from 'antd';
import useBackups from '../../components/Backups/useBackups';
import CcBackup, {
    CcBackupMethod,
    CcBackupStatus,
} from '../../services/models/CcBackup';
import BackupFormat from '../../components/Backups/BackupFormat';
import SpaceWide from '../SpaceWide';
import SelectWrap, { SelectWrapProps } from './SelectWrap';
import { DefaultOptionType } from 'rc-select/lib/Select';

export default BackupSelect;

export type BackupSelectProps = SelectWrapProps & {
    clusterId: number;
    value?: CcBackup;
    backupLabel?: React.ReactNode;
    onChange?: (value: CcBackup) => void;
    filter?: (value: CcBackup) => boolean;
    createOption?: (value: CcBackup) => DefaultOptionType;
    renderLabel?: (value: CcBackup) => React.ReactNode;
    disableIncremental?: boolean;
};

function BackupSelect({
    clusterId,
    value,
    backupLabel,
    onChange,
    filter = defaultBackupFilter,
    createOption,
    renderLabel,
    selectFirst = false,
    disabled,
    disableIncremental = undefined,
    ...rest
}: BackupSelectProps) {
    const [parentBackupId, setParentBackupId] = useState<number>();
    const [backupChildrenId, setChildBackupId] = useState<number>();
    const [parentBackups, setParentBackups] = useState<CcBackup[]>([]);
    const [backupChildren, setBackupChildren] = useState<CcBackup[]>([]);
    const { list, refresh, loading } = useBackups({ pageSize: 0 });
    useEffect(() => {
        (async () => {
            await refresh({ cluster_id: clusterId, useCache: true });
        })();
    }, [clusterId]);

    useEffect(() => {
        if (list?.length) {
            setParentBackups(
                list.filter(
                    (backup) =>
                        backup.getParentId() === 0 &&
                        backup.getStatus() ===
                            CcBackupStatus.STATUS_COMPLETED &&
                        (filter ? filter(backup) : true)
                )
            );
            const selectedBackup = list.find(
                (backup) => backup.getId() === value?.getId()
            );
            if (selectedBackup) {
                if (selectedBackup.getParentId() < 1) {
                    setParentBackupId(selectedBackup.getId());
                    setChildren(selectedBackup.getId());
                } else {
                    setParentBackupId(selectedBackup.getParentId());
                    setChildBackupId(selectedBackup.getId());
                    setChildren(selectedBackup.getParentId());
                }
            }
        }
    }, [list, value]);

    const setChildren = (backupId: number) => {
        setBackupChildren(
            list?.filter(
                (backup: CcBackup) => backup?.getParentId() === backupId
            ) || []
        );
    };

    const handleParentBackupSelect = (backupId: number) => {
        const backup = parentBackups.find((item) => item.getId() === backupId);
        setChildren(backupId);
        if (backup) {
            setParentBackupId(backupId);
            onChange?.(backup);
        }
    };

    const handleBackupChildSelect = (backupId: number) => {
        const backup = backupChildren.find((item) => item.getId() === backupId);
        if (backup) {
            setChildBackupId(backupId);
            onChange?.(backup);
        }
    };
    return (
        <SpaceWide direction="vertical" size={0}>
            <Form.Item
                label={backupLabel !== undefined ? backupLabel : 'Backup'}
                style={{ marginBottom: '5px' }}
            >
                <SelectWrap
                    data-testid="backup-parents-select"
                    selectFirst={selectFirst}
                    onChange={handleParentBackupSelect}
                    disabled={loading || disabled}
                    value={parentBackupId}
                    placeholder="Choose a backup file"
                    loading={loading}
                    options={parentBackups.map((backup) =>
                        createOption
                            ? createOption(backup)
                            : {
                                  value: backup.getId(),
                                  label: renderLabel?.(backup) || (
                                      <BackupFormat
                                          backup={backup}
                                          shortBackupName={true}
                                          showPopover={false}
                                      />
                                  ),
                              }
                    )}
                    {...rest}
                />
            </Form.Item>
            {backupChildren.length > 0 && (
                <Form.Item label="Incremental backups" style={{ margin: 0 }}>
                    <SelectWrap
                        data-testid="backup-children-select"
                        onChange={handleBackupChildSelect}
                        value={backupChildrenId}
                        loading={loading}
                        options={backupChildren.map((backup) =>
                            createOption
                                ? createOption(backup)
                                : {
                                      value: backup.getId(),
                                      label: renderLabel?.(backup) || (
                                          <BackupFormat
                                              backup={backup}
                                              shortBackupName={true}
                                              showPopover={false}
                                          />
                                      ),
                                  }
                        )}
                        disabled={
                            loading ||
                            (disableIncremental === undefined
                                ? disabled
                                : disableIncremental)
                        }
                        {...rest}
                    />
                </Form.Item>
            )}
        </SpaceWide>
    );
}

function defaultBackupFilter(backup: CcBackup) {
    return !backup.isMethod(CcBackupMethod.PROXYSQL);
}
