


export interface CmonTimeZoneProps  {
    abbreviation?: string;
    name?: string;
    offset?: number;
    use_dst?: number;

}



export default class CmonTimeZone {
    public abbreviation?: string;
    public name?: string;
    public offset?: number;
    public useDst?: number;


    constructor(props: CmonTimeZoneProps) {
        this.abbreviation = props.abbreviation;
        this.name = props.name;
        this.offset = props.offset;
        this.useDst = props.use_dst;

    }

}
