import React, { useState } from 'react';
import ModalDefaultForm from '../../common/ModalDefaultForm';
import useForm from 'antd/lib/form/hooks/useForm';
import { Alert, Button, Form, Radio, Space } from 'antd';
import SpaceWide from '../../common/SpaceWide';
import SpaceDescriptions from '../../common/Layout/SpaceDescriptions';
import FormFooter from '../../common/FormFooter';
import CmonJobsService from '../../services/cmon/CmonJobsService';
import CcCluster from '../../services/models/CcCluster';
import {
    notifyError,
    notifyOperationSuccess,
    NotifyType,
} from '../Notifications/uiNotification';

export default PrometheusDisableModal;

export type PrometheusDisableModalProps = {
    cluster: CcCluster;
    onCancel?: () => void;
    onSuccess?: () => void;
};

function PrometheusDisableModal({
    cluster,
    onCancel,
    onSuccess,
}: PrometheusDisableModalProps) {
    const [loading, setLoading] = useState(false);
    const [form] = useForm();

    const handleSubmit = async () => {
        const stop = !!form.getFieldValue('stop');
        const node = cluster.getPrometheusNode();
        if (cluster.clusterId && node) {
            try {
                setLoading(true);
                await CmonJobsService.createRemovenodeJobInstance(
                    cluster.clusterId,
                    {
                        job_data: {
                            clusterId: cluster.clusterId,
                            unregister_only: !stop,
                            node: {
                                hostname: node.hostname,
                                port: node.port,
                            },
                        },
                    },
                    {
                        job: {
                            title: 'Disable agent-based monitoring',
                        },
                    }
                );
                notifyOperationSuccess({
                    type: NotifyType.TOAST,
                    title: 'Agent-based monitoring disabling job has started',
                    content: stop
                        ? 'All exporters will be stopped'
                        : 'All exporters still will be running',
                });
                setLoading(false);
                onSuccess?.();
            } catch (e) {
                setLoading(false);
                notifyError({
                    content: e.message,
                });
            }
        }
    };

    return (
        <ModalDefaultForm
            title="Disable agent-based monitoring"
            form={form}
            footer={[]}
            onCancel={onCancel}
            width={450}
            defaultVisible={true}
        >
            <Form
                form={form}
                layout="vertical"
                onFinish={handleSubmit}
                initialValues={{ stop: 0 }}
            >
                <SpaceWide size={30} direction="vertical">
                    <Alert
                        type="warning"
                        message="Disabling the agent based monitoring will disable also the dashboards for this cluster."
                    />
                    <Form.Item name="stop">
                        <Radio.Group>
                            <Space direction="vertical" size={20}>
                                <Radio value={0}>
                                    <SpaceDescriptions title="Keep the exporters running">
                                        Keep the Prometheus running and fall
                                        back to SSH based sampling, Prometheus
                                        will continue to pull stats and the
                                        exporters wont be stopped
                                    </SpaceDescriptions>
                                </Radio>
                                <Radio value={1}>
                                    <SpaceDescriptions title="Stop the exporters">
                                        Stop the exporters, unregister them from
                                        Prometheus and stop Prometheus (if not
                                        used by other clusters)
                                    </SpaceDescriptions>
                                </Radio>
                            </Space>
                        </Radio.Group>
                    </Form.Item>
                </SpaceWide>
                <FormFooter>
                    <Button type="primary" htmlType="submit" loading={loading}>
                        Disable
                    </Button>
                </FormFooter>
            </Form>
        </ModalDefaultForm>
    );
}
