import React from 'react';
import SpaceDescriptions from '../../common/Layout/SpaceDescriptions';
import { AclPermissionItem, getTreeItemAccessText } from './useTree';
import YesNoFormat from '../../common/Format/YesNoFormat';
import {
    CcTreeItemAccess,
    CcTreeItemHandledAclPath,
} from '../../services/models/CcTreeItem';
import ClusterFormat from '../Clusters/ClusterFormat';
import { Col, Row, Table } from 'antd';
import SpaceWide from '../../common/SpaceWide';
import { AppState, AppStateClustersMap } from '../../appReducer';
import { useSelector } from 'react-redux';

export default PermissionsInfo;

export type PermissionsInfoProps = {
    permissions: AclPermissionItem[];
    canManageUsers?: boolean;
};

/**
 * It shows permissions info, it uses canManageUsers boolean to override
 * CcTreeItemHandledAclPath.ACL_USER_MANAGER path in permission item.
 * Backend does not use this path to check permissions in user management module.
 * https://severalnines.atlassian.net/browse/CCV2-946
 * @param permissions
 * @param canManageUsers
 */
function PermissionsInfo({
    permissions,
    canManageUsers,
}: PermissionsInfoProps) {
    const [clustersMap]: [
        AppStateClustersMap
    ] = useSelector(({ clusters }: AppState) => [clusters]);
    const columns = [
        {
            key: 'name',
            title: 'Cluster',
            render: (item: AclPermissionItem) => item.title,
        },
        {
            key: 'more-info',
            title: 'More info',
            render: (item: AclPermissionItem) => (
                <ClusterFormat
                    cluster={
                        item.clusterKey
                            ? clustersMap.get(item.clusterKey)
                            : undefined
                    }
                />
            ),
            width: '40%',
        },
        {
            key: 'access-level',
            title: 'Access level',
            render: (item: AclPermissionItem) => (
                <span>{getTreeItemAccessText(item.level)}</span>
            ),
            width: '20%',
        },
    ];
    return (
        <SpaceWide direction="vertical">
            <SpaceDescriptions
                title="Permissions"
                className="PermissionsInfo"
                wrap={true}
            >
                <Row gutter={[24, 0]} wrap={true}>
                    {permissions
                        .filter((i: AclPermissionItem) => !i.isCluster)
                        .map((i) => (
                            <Col span={12}>
                                <SpaceDescriptions.Item
                                    key={i.key}
                                    label={i.description}
                                >
                                    {i.key ===
                                    CcTreeItemHandledAclPath.ACL_USER_MANAGER ? (
                                        <YesNoFormat
                                            booleanVar={canManageUsers}
                                        />
                                    ) : (
                                        <YesNoFormat
                                            booleanVar={[
                                                CcTreeItemAccess.FULL_ACCESS,
                                                CcTreeItemAccess.EXECUTE,
                                            ].includes(i.level)}
                                        />
                                    )}
                                </SpaceDescriptions.Item>
                            </Col>
                        ))}
                </Row>
            </SpaceDescriptions>
            <Table
                columns={columns}
                rowKey={(item) => item.key}
                dataSource={permissions.filter(
                    (i: AclPermissionItem) => i.isCluster
                )}
                size="small"
                pagination={false}
            ></Table>
        </SpaceWide>
    );
}
