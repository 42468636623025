import React from 'react';
import './FormItemInline.less';
import classNames from 'classnames';
import TypographyText from './TypographyText';
import FormItem, { FormItemProps } from './DataEntry/FormItem';

export default FormItemInline;

export interface FormItemInlineProps extends FormItemProps {
    justify?: boolean;
    noMargin?: boolean;
    labelStrong?: boolean;
}

function FormItemInline({
    className,
    colon = false,
    label,
    labelStrong = false,
    justify,
    noMargin,
    ...rest
}: FormItemInlineProps) {
    return (
        <FormItem
            className={classNames(
                'FormItemInline',
                { 'FormItemInline--justify': justify },
                { 'FormItemInline--no-margin': noMargin },
                className
            )}
            label={
                <span>
                    <TypographyText strong={labelStrong}>
                        {label}
                    </TypographyText>
                    {colon === false ? '' : ':'}
                </span>
            }
            {...rest}
        />
    );
}
