import React from 'react';
import { Form, Input, Space } from 'antd';
import InfoIcon from '@severalnines/bar-frontend-components/build/lib/General/InfoIcon';
import TypographyText from '../../TypographyText';
import { FormItemProps } from 'antd/lib/form';

export default BytesInputField;
export type BytesInputFieldProps = FormItemProps & {
    required?: boolean;
    requiredMessage?: string | React.ReactElement;
    info?: React.ReactElement;
    placeholder?: string;
};

function BytesInputField({
    info,
    label,
    required,
    requiredMessage,
    placeholder,
    ...rest
}: BytesInputFieldProps) {
    return (
        <Form.Item
            label={
                info ? (
                    <Space>
                        <span>{label}</span>
                        <InfoIcon info={info} />
                    </Space>
                ) : (
                    label
                )
            }
            rules={[
                {
                    required,
                    message: requiredMessage,
                },
            ]}
            extra={
                <TypographyText muted>
                    Accepts byte size units, such as 1g, 10mb, 5k, or 1024B.
                    Defaults to unlimited.
                </TypographyText>
            }
            {...rest}
        >
            <Input placeholder={placeholder} />
        </Form.Item>
    );
}
