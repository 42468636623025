
import CcStats, { CcStatsProps } from '../../models/CcStats';


export interface CmonTcpStatsProps  extends CcStatsProps {
    received_bad_segments?: number;
    received_segments?: number;
    retransmitted_segments?: number;
    sent_segments?: number;

}



export default class CmonTcpStats extends CcStats {
    public receivedBadSegments?: number;
    public receivedSegments?: number;
    public retransmittedSegments?: number;
    public sentSegments?: number;


    constructor(props: CmonTcpStatsProps) {
        super(props);
        this.receivedBadSegments = props.received_bad_segments;
        this.receivedSegments = props.received_segments;
        this.retransmittedSegments = props.retransmitted_segments;
        this.sentSegments = props.sent_segments;

    }

}
