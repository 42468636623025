import React from 'react';

import MonitorVariable from '../components/Monitor/MonitorVariable';

import galera from './dataMocks/clusters/galera';
import CcCluster from '../services/models/CcCluster';

import SystemOverview from '../components/Monitor/data/system-overview';

export default DebugMonitorVariable;

function DebugMonitorVariable() {
    return (
        <div>
            {SystemOverview.templating.list.map((v, i) => (
                <MonitorVariable
                    cluster={new CcCluster(galera as any)}
                    variable={v}
                    key={i}
                    vars={[]}
                    style={{ width: '100%' }}
                />
            ))}
        </div>
    );
}
