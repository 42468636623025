


export interface CmonTableStatProps  {

}



export default class CmonTableStat {


    constructor(props: CmonTableStatProps) {

    }

}
