import React, { useState } from 'react';
import { Alert, Button } from 'antd';
import ModalDefault from '../../../../../../common/ModalDefault';
import ButtonWithForm from '../../../../../../common/General/ButtonWithForm';

export default TopQueryFullDigestModal;

export type TopQueryFullDigestModalProps = {
    digestText?: string;
};

function TopQueryFullDigestModal({ digestText }: TopQueryFullDigestModalProps) {
    const [visible, setVisible] = useState(true);
    const showModal = () => {
        setVisible(true);
    };
    const handleCancel = () => {
        setVisible(false);
    };

    return (
        <ButtonWithForm
            button={
                <Button type="link" onClick={showModal}>
                    Full digest
                </Button>
            }
            form={
                <ModalDefault
                    title={'Full digest'}
                    visible={visible}
                    bodyStyle={{
                        padding: ' 25px',
                    }}
                    footer={[
                        <Button
                            key="done"
                            type="primary"
                            onClick={handleCancel}
                        >
                            Done
                        </Button>,
                    ]}
                >
                    <Alert message="Digest text" description={digestText} />
                </ModalDefault>
            }
        />
    );
}
