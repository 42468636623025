import './ClusterConfigValueInput.less';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Popconfirm } from 'antd';
import { ClusterConfigValue } from '../useClusterConfig';
import TypographyText from '../../../common/TypographyText';
import InfoIcon from '@severalnines/bar-frontend-components/build/lib/General/InfoIcon';
import { ConfigActionContext } from './ConfigContext';
import AppSpin from '../../../common/General/AppSpin';

export default ClusterConfigValueInput;
export type ClusterConfigValueInputProps = {
    value: ClusterConfigValue;
    render?: (value: string) => React.ReactNode;
};

const CONFIG_HIDDEN_VALUE = '<hidden>';

function ClusterConfigValueInput({
    value,
    render,
    ...rest
}: ClusterConfigValueInputProps) {
    const isObject =
        value.current_value && typeof value.current_value === 'object';

    const valueString = useMemo(() => {
        if (isObject) {
            return JSON.stringify(value.current_value, null, 2);
        }
        return value.current_value === CONFIG_HIDDEN_VALUE
            ? '**********'
            : `${value.current_value || ''}`;
    }, [value, isObject]);

    const [loading, setLoading] = useState(false);
    const [currentValue, setCurrentValue] = useState<string>(valueString);
    const { changeValue } = useContext(ConfigActionContext);
    const [confirmVisible, setConfirmVisible] = useState(false);
    const [confirmValue, setConfirmValue] = useState<string>('');

    useEffect(() => {
        setCurrentValue(valueString);
    }, [value]);
    const handleCancel = () => {
        setCurrentValue(valueString);
        setConfirmVisible(false);
    };
    const handleChange = async (newValue: string) => {
        if (value.current_value === CONFIG_HIDDEN_VALUE) {
            setConfirmValue(newValue);
            setConfirmVisible(true);
        } else {
            setLoading(true);
            try {
                await changeValue(value, newValue);
                setCurrentValue(newValue);
            } catch (e) {
                handleCancel();
            }
            setLoading(false);
        }
    };

    const handleConfirm = async () => {
        setLoading(true);
        try {
            setConfirmVisible(false);
            await changeValue(value, confirmValue);
            setCurrentValue(valueString);
        } catch (e) {
            handleCancel();
        }
        setLoading(false);
    };

    const info = value.readonly ? (
        <span>This option is readonly</span>
    ) : value.default_value ? (
        <span>Default value: {value.default_value}</span>
    ) : undefined;

    const readonly = value.readonly || isObject;
    return readonly ? (
        <TypographyText
            ellipsis={{
                tooltip: (
                    <pre
                        style={{
                            wordWrap: 'break-word',
                            whiteSpace: 'pre-wrap',
                        }}
                    >
                        {currentValue}
                    </pre>
                ),
            }}
        >
            {currentValue}
        </TypographyText>
    ) : (
        <AppSpin spinning={loading} size="small" style={{ width: '100%' }}>
            <div className="ClusterConfigValueInput">
                <Popconfirm
                    title="Are you sure you want to save?"
                    onConfirm={handleConfirm}
                    onCancel={handleCancel}
                    okText="Yes"
                    cancelText="No"
                    visible={confirmVisible}
                >
                    <TypographyText
                        className="ClusterConfigValueInput_value"
                        ellipsis={{ tooltip: true }}
                        editable={{
                            triggerType: ['text', 'icon'],
                            onCancel: handleCancel,
                            onChange: handleChange,
                            onStart: () => {
                                if (
                                    value.current_value === CONFIG_HIDDEN_VALUE
                                ) {
                                    setCurrentValue('');
                                }
                            },
                            onEnd: () => {
                                setCurrentValue(valueString);
                            },
                        }}
                    >
                        {currentValue || <span></span>}
                    </TypographyText>
                </Popconfirm>

                {info && <InfoIcon info={info} />}
            </div>
        </AppSpin>
    );
}
