import React from 'react';
import { Form } from 'antd';
import FormFooter from '../../../common/FormFooter';
import CcCluster from '../../../services/models/CcCluster';
import ModalDefaultForm from '../../../common/ModalDefaultForm';
import useCreateJob from '../../Jobs/useCreateJob';
import { CmonJobInstanceCommand } from '../../../services/cmon/models/CmonJobInstance';
import NodeStopForm from '../../Nodes/Actions/NodeStopForm';
import SpaceWide from '../../../common/SpaceWide';
import ClusterFormat from '../ClusterFormat';

export default ClusterStopForm;

export type ClusterStopFormProps = {
    cluster: CcCluster;
    onSuccess?: () => void;
    onCancel?: () => void;
};
export type ClusterStopFormValues = {
    stopTimeout?: number;
    forceStop?: boolean;
};

function ClusterStopForm({
    cluster,
    onSuccess,
    onCancel,
}: ClusterStopFormProps) {
    const { loading, send } = useCreateJob({
        clusterId: cluster.clusterId,
        title: 'Stop cluster',
        command: CmonJobInstanceCommand.STOP_CLUSTER,
        onSuccess,
    });
    const [form] = Form.useForm<ClusterStopFormValues>();

    const handleSubmit = async (values: ClusterStopFormValues) => {
        await send({
            stop_timeout: values.stopTimeout,
            force: values.forceStop,
        });
    };
    return (
        <ModalDefaultForm
            title="Stop cluster"
            form={form}
            footer={[]}
            onCancel={onCancel}
            defaultVisible={true}
            width={450}
        >
            <Form
                className="ClusterStopForm"
                form={form}
                layout="vertical"
                onFinish={handleSubmit}
                initialValues={{
                    stopTimeout: 1800,
                    forceStop: false,
                }}
            >
                <SpaceWide direction="vertical">
                    <ClusterFormat cluster={cluster} size="large" wide={true} />
                    <NodeStopForm.StopTimeoutInput />
                    <NodeStopForm.ForceStopInput />
                    <NodeStopForm.Notice />
                </SpaceWide>
                <FormFooter
                    loading={loading}
                    showSubmitButton
                    submitButtonText="Stop"
                />
            </Form>
        </ModalDefaultForm>
    );
}
