import React from 'react';
import './CoverView.less';
import { Empty, Typography } from 'antd';
import { DeploymentUnitOutlined } from '@ant-design/icons';
import {
    getStatusFormatColor,
    StatusFormatStatus,
} from '@severalnines/bar-frontend-components/build/lib/Format/StatusFormat';
import getAntIconSvgFile from '@severalnines/bar-frontend-components/build/lib/helpers/getAntIconSvgFile';
import classNames from 'classnames';

export default CoverView;

export type CoverViewProps = {
    emptyState?: React.ReactElement;
    defaultIconComponent?: React.ExoticComponent;
    emptyDescription?: React.ReactNode;
    extra?: React.ReactNode;
    title?: React.ReactNode;
    icon?: string | JSX.Element;
    description?: React.ReactNode;
    showBackgroundIcon?: boolean;
    isEmpty?: boolean;
};

export type CoverViewEmptyStateProps = {
    description?: React.ReactNode;
    src?: any;
    imgAlt?: string;
};

type DefaultIconProps = {
    size: number;
    component: React.ExoticComponent<any>;
};
function DefaultIcon({ size, component: Component }: DefaultIconProps) {
    return (
        <Component
            className="CoverView_default-icon"
            style={{ fontSize: size }}
        />
    );
}

const DetailEmptyState = ({
    description,
    src,
    imgAlt,
}: CoverViewEmptyStateProps) => {
    return (
        <Empty
            description={description || ' '}
            image={
                <img
                    src={src || require('./img-cover-view-zero-state.svg')}
                    alt={imgAlt || ' '}
                />
            }
        />
    );
};

function CoverView({
    emptyState,
    defaultIconComponent = DeploymentUnitOutlined,
    emptyDescription,
    extra,
    title,
    description,
    icon,
    isEmpty,
    showBackgroundIcon,
}: CoverViewProps) {
    return (
        <div className="CoverView_wrapper">
            <div
                className={classNames('CoverView', {
                    'CoverView--empty': isEmpty,
                })}
            >
                {!isEmpty ? (
                    <>
                        <div>
                            {getCoverViewIcon(icon, 70, defaultIconComponent)}
                        </div>
                        <div>
                            <Typography.Title level={2}>
                                {title}
                            </Typography.Title>
                            {typeof description === 'string' ? (
                                <Typography.Paragraph>
                                    {description}
                                </Typography.Paragraph>
                            ) : (
                                description
                            )}

                            {extra}
                        </div>
                        {showBackgroundIcon !== false ? (
                            <style
                                dangerouslySetInnerHTML={
                                    (icon && {
                                        __html: `.CoverView:before {
                                    background-image: url("${getIconSvgFile(
                                        icon
                                    )}")
                                    }`,
                                    }) || {
                                        __html: `.CoverView:before {
                                    background-color: ${'#530099'};
                                    -webkit-mask-image: url("${getDefaultIconSvgFile(
                                        defaultIconComponent
                                    )}");
                                    mask-image: url(${getDefaultIconSvgFile(
                                        defaultIconComponent
                                    )});
                                }`,
                                    }
                                }
                            />
                        ) : null}
                    </>
                ) : (
                    emptyState || (
                        <DetailEmptyState description={emptyDescription} />
                    )
                )}
            </div>
        </div>
    );
}

export function getCoverViewIcon(
    icon: string | JSX.Element | undefined,
    size: number = 70,
    defaultIconComponent = DeploymentUnitOutlined
) {
    return (
        (icon && (
            <img
                style={{ width: `${size}px` }}
                src={getIconSvgFile(icon)}
                alt="cluster-type-icon"
            />
        )) || <DefaultIcon size={size} component={defaultIconComponent} />
    );
}

export function getDefaultIconSvgFile(Component: React.ExoticComponent<any>) {
    return getAntIconSvgFile(<Component />);
}
/**
 * require() icon svg file
 * @param icon - string with icon file name or ant design icon JSX
 */
export function getIconSvgFile(icon: string | JSX.Element) {
    if (typeof icon === 'string') {
        return require(`@severalnines/bar-frontend-components/build/lib/General/icons/${icon}`);
    }
    return getAntIconSvgFile(icon);
}
