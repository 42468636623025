import CmonCaService from '../../services/cmon/CmonCaService';
import { ConfirmActionButtonProps } from '@severalnines/bar-frontend-components/build/lib/General/ConfirmActionButton';
import {
    notifyError,
    notifyOperationSuccess,
    NotifyType,
} from '../Notifications/uiNotification';
import { useDebugContext } from '../../common/Debug';
import AppConfirmActionButton from '../../common/General/AppConfirmActionButton';

export default KeyManagementActionMenuDeleteButton;

export type KeyManagementActionMenuDeleteButtonProps = ConfirmActionButtonProps & {
    selectedRow: any;
    onSuccess?: (id: number) => void;
};

function KeyManagementActionMenuDeleteButton({
    selectedRow,
    onSuccess,
    ...rest
}: KeyManagementActionMenuDeleteButtonProps) {
    const { log } = useDebugContext();

    const handleConfirm = async () => {
        try {
            await CmonCaService.delete({
                id: selectedRow?.id,
            });
            notifyOperationSuccess({
                type: NotifyType.TOAST,
                title: 'Success!',
                content: 'File successfully deleted',
            });
            onSuccess?.(selectedRow.id);
        } catch (e) {
            notifyError({ content: e.message });
            log.error(e);
        }
    };

    return (
        <AppConfirmActionButton
            critical={true}
            confirmTitle="You want to remove this file?"
            onConfirm={handleConfirm}
            {...rest}
        >
            {'Delete'}
        </AppConfirmActionButton>
    );
}
