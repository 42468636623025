
import CmonRequestService, {CmonRequestServiceResponseData} from './CmonRequestService';


export interface ProxyRequestData {
    uri?: string;
    method?: string;
    provider?: string;
    credentials_id?: number;
    body?: string;
    [key: string]: any;
}

export interface VerifyCredentialsRequestData {
    provider?: string;
    credentials?: {[key:string]:any};
    [key: string]: any;
}

export interface ListCredentialsRequestData {

    [key: string]: any;
}

export interface GetCredentialsRequestData {
    id?: number;
    provider?: string;
    [key: string]: any;
}

export interface AddCredentialsRequestData {
    provider?: string;
    name?: string;
    credentials?: {[key:string]:any};
    comment?: string;
    [key: string]: any;
}

export interface UpdateCredentialsRequestData {
    id?: number;
    provider?: string;
    name?: string;
    credentials?: {[key:string]:any};
    comment?: string;
    [key: string]: any;
}

export interface RemoveCredentialsRequestData {
    id?: number;
    provider?: string;
    [key: string]: any;
}


export default class CmonCloudService extends CmonRequestService {
    
    public static module(): string {
        return 'cloud';
    }
    
    public static async proxy(
        data: ProxyRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('proxy', data, opts);
    }

    public static async verifyCredentials(
        data: VerifyCredentialsRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('verifyCredentials', data, opts);
    }

    public static async listCredentials(
        data: ListCredentialsRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('listCredentials', data, opts);
    }

    public static async getCredentials(
        data: GetCredentialsRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('getCredentials', data, opts);
    }

    public static async addCredentials(
        data: AddCredentialsRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('addCredentials', data, opts);
    }

    public static async updateCredentials(
        data: UpdateCredentialsRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('updateCredentials', data, opts);
    }

    public static async removeCredentials(
        data: RemoveCredentialsRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('removeCredentials', data, opts);
    }

}
