import React, { useContext } from 'react';
import { ResponsiveContext } from '@severalnines/bar-frontend-components/build/lib/Layout/Responsive';
import CcBackupStorageLocation from '../../../services/models/CcBackupStorageLocation';
import StorageLocationFormat from './StorageLocationFormat';
import AppTable from '../../../common/DataDisplay/AppTable';

export default StorageLocationTable;

export type StorageLocationTableProps = {
    storageLocations: CcBackupStorageLocation[];
};

function StorageLocationTable({ storageLocations }: StorageLocationTableProps) {
    const { responsive } = useContext(ResponsiveContext);

    const columns = [
        {
            title: 'Type',
            key: 'type',
            render: (record: CcBackupStorageLocation) => (
                <StorageLocationFormat storageLocation={record} />
            ),
        },
        {
            title: 'Path',
            key: 'path',
            render: (record: CcBackupStorageLocation) => record.getPath(),
        },
    ];
    return (
        <AppTable
            rowKey={(record: CcBackupStorageLocation) => record.getPath()}
            size="small"
            className="StorageLocationTable"
            responsive={responsive}
            dataSource={storageLocations}
            columns={columns}
            pagination={{
                pageSize: storageLocations.length,
                hideOnSinglePage: true,
            }}
        />
    );
}
