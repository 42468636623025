import { Button, Form, Space, Tabs } from 'antd';
import React, { useEffect, useState } from 'react';
import CcCluster from '../../../services/models/CcCluster';
import AppMonacoEditor from '../../../common/AppEditor/AppMonacoEditor';
import { MonacoDiffEditor } from 'react-monaco-editor';
import CcAdvisorSchedule from '../../../services/models/CcAdvisorSchedule';
import AppDivider from '../../../common/AppDivider';
import SpaceWide from '../../../common/SpaceWide';
import { notifyError } from '../../Notifications/uiNotification';
import { useDebugContext } from '../../../common/Debug';
import ModalDefaultForm, {
    ModalDefaultFormProps,
} from '../../../common/ModalDefaultForm';
import { useForm } from 'antd/lib/form/Form';
import AdvisorResultLogsToggle from './AdvisorResultLogsToggle';
import useClusterScript, {
    ClusterScriptRequestDTO,
} from '../Scripts/useClusterScript';
import ClusterScriptParamsFields from '../Scripts/FormParts/ClusterScriptParamsFields';

export default AdvisorEditScriptModal;
type AdvisorEditScriptModalProps = Omit<
    ModalDefaultFormProps,
    'children' | 'form'
> & {
    cluster: CcCluster;
    advisorSchedule: CcAdvisorSchedule;
    onSuccess?: () => void;
    onUpdate?: () => void;
};

function AdvisorEditScriptModal({
    cluster,
    onCancel,
    onSuccess,
    onUpdate,
    advisorSchedule,
    ...rest
}: AdvisorEditScriptModalProps) {
    const { log } = useDebugContext();
    const [execResult, setExecResult] = useState<
        CcAdvisorSchedule | undefined
    >();
    const [form] = useForm();

    const [content, setContent] = React.useState<string>('');
    const {
        loading,
        saveLoading,
        compileLoading,
        runLoading,
        refresh,
        data,
        save,
        compile,
        run,
    } = useClusterScript({
        cluster,
        file: advisorSchedule?.file,
    });
    useEffect(() => {
        (async () => {
            await refresh({});
        })();
    }, []);

    const getSaveDto = (
        dto: ClusterScriptRequestDTO = {}
    ): ClusterScriptRequestDTO => {
        const contentToSave = content || data?.content;
        if (!contentToSave) {
            throw new Error('Nothing to save');
        }
        return {
            content: contentToSave,
            tags: form.getFieldValue('tags')?.join(';'),
            ...dto,
        };
    };

    const handleValueChange = (value: string) => {
        setContent(value);
    };

    const handleSaveClick = async () => {
        try {
            await save(getSaveDto());
            onUpdate?.();
            onSuccess?.();
        } catch (e: any) {
            notifyError({ content: e.message });
            log.error(e);
        }
    };

    const handleCompileClick = async () => {
        await compile(
            getSaveDto({ arguments: form.getFieldValue('arguments') })
        );
        onUpdate?.();
    };
    const handleRunClick = async () => {
        const result = await run(
            getSaveDto({ arguments: form.getFieldValue('arguments') })
        );
        setExecResult(result);
        onUpdate?.();
    };
    return (
        <ModalDefaultForm
            form={form}
            title={`Edit ${advisorSchedule?.filename}`}
            visible={true}
            width={1100}
            bodyStyle={{ padding: 20 }}
            footer={[
                <div>
                    <br />
                </div>,
            ]}
            onCancel={onCancel}
            {...rest}
        >
            <Form
                form={form}
                layout="vertical"
                initialValues={{
                    tags: advisorSchedule?.tags || [],
                    arguments: advisorSchedule?.file?.schedule_args,
                }}
            >
                <SpaceWide direction="vertical">
                    <Tabs>
                        <Tabs.TabPane tab="Code" key="code">
                            <AppMonacoEditor
                                height="400"
                                loading={loading}
                                language={'javascript'}
                                value={data?.content}
                                options={{
                                    automaticLayout: true,
                                    scrollbar: {
                                        vertical: 'hidden',
                                        verticalScrollbarSize: 0,
                                    },
                                    overviewRulerBorder: false,
                                    minimap: {
                                        size: 'fit',
                                    },
                                }}
                                onChange={(newValue: string) =>
                                    handleValueChange(newValue)
                                }
                            />
                        </Tabs.TabPane>
                        <Tabs.TabPane
                            tab="Diff"
                            key="diff"
                            disabled={!content || content === data?.content}
                        >
                            <MonacoDiffEditor
                                height="400"
                                options={{
                                    automaticLayout: true,
                                }}
                                language="javascript"
                                original={data?.content}
                                value={content}
                            />
                        </Tabs.TabPane>
                    </Tabs>

                    <AppDivider />
                    <ClusterScriptParamsFields tags={advisorSchedule?.tags} />
                    <SpaceWide justify="space-between">
                        <Space>
                            <Button
                                key="run"
                                type="primary"
                                loading={saveLoading || runLoading}
                                onClick={handleRunClick}
                            >
                                Compile & run
                            </Button>
                            <Button
                                key="compile"
                                loading={saveLoading || compileLoading}
                                onClick={handleCompileClick}
                            >
                                Compile
                            </Button>
                        </Space>
                        <Button
                            disabled={!(data?.content || content)}
                            key="done"
                            type="primary"
                            loading={saveLoading}
                            onClick={handleSaveClick}
                        >
                            Save
                        </Button>
                    </SpaceWide>

                    <AppDivider />
                    <AdvisorResultLogsToggle
                        open={!!execResult}
                        schedule={execResult || advisorSchedule}
                    />
                </SpaceWide>
            </Form>
        </ModalDefaultForm>
    );
}
