import { ButtonProps } from 'antd/lib/button';
import React from 'react';
import CcCluster from '../../services/models/CcCluster';
import AppLink from '../../common/AppLink';
export default QueryMonitorSettingsButton;

export type QueryMonitorSettingsButtonPros = ButtonProps & {
    cluster: CcCluster;
    onSuccess?: () => void;
};
function QueryMonitorSettingsButton({
    cluster,
    children,
    onSuccess,
    ...rest
}: QueryMonitorSettingsButtonPros) {
    return (
        <AppLink
            to={`/clusters/${cluster.clusterId}/performance/query-monitor/settings`}
        >
            Query monitor settings
        </AppLink>
    );
}
