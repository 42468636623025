import React from 'react';
import SpaceDescriptions from '../../common/Layout/SpaceDescriptions';
import TypographyText from '../../common/TypographyText';
import DurationFormat from '@severalnines/bar-frontend-components/build/lib/Format/DurationFormat';
import { ExporterType, PrometheusFormValues } from './PrometheusEnableWizard';
import AppDivider from '../../common/AppDivider';
import SpaceWide from '../../common/SpaceWide';

export default PrometheusExportersData;

export type PrometheusExportersDataProps = {
    data: PrometheusFormValues;
    exporterTypes: ExporterType[];
};

function PrometheusExportersData({
    data,
    exporterTypes,
}: PrometheusExportersDataProps) {
    return (
        <SpaceWide
            size={10}
            direction="vertical"
            className="PrometheusExportersData"
        >
            <SpaceDescriptions
                direction="vertical"
                title="Settings"
                size="small"
                alignItems="right"
            >
                <SpaceDescriptions.Item label="Scrape interval" labelStrong>
                    <TypographyText>
                        <DurationFormat precision={2}>
                            {data.scrapeInterval * 1000}
                        </DurationFormat>
                    </TypographyText>
                </SpaceDescriptions.Item>
                <SpaceDescriptions.Item label="Data Retention" labelStrong>
                    <TypographyText>
                        <DurationFormat precision={1} units={['d']}>
                            {data.dataRetention * 86400000}
                        </DurationFormat>
                    </TypographyText>
                </SpaceDescriptions.Item>
                <SpaceDescriptions.Item label="Data Retention Size" labelStrong>
                    <TypographyText>{data.dataRetentionSize} MB</TypographyText>
                </SpaceDescriptions.Item>
                <SpaceDescriptions.Item label="Data Directory" labelStrong>
                    <TypographyText>{data.dataDirectory}</TypographyText>
                </SpaceDescriptions.Item>
            </SpaceDescriptions>
            {exporterTypes
                .filter(({ type }) => !!data[type])
                .map(({ type, title }) => (
                    <div key={type}>
                        <AppDivider />
                        <SpaceDescriptions
                            direction="vertical"
                            title={title}
                            size="small"
                            alignItems="right"
                        >
                            <SpaceDescriptions.Item
                                label="Scrape interval"
                                labelStrong
                            >
                                <TypographyText>
                                    <DurationFormat precision={2}>
                                        {(data[type]?.scrapeInterval || 0) *
                                            1000}
                                    </DurationFormat>
                                </TypographyText>
                            </SpaceDescriptions.Item>
                            <SpaceDescriptions.Item
                                labelStrong
                                align="end"
                                direction="vertical"
                                style={{ width: '80%' }}
                            >
                                <TypographyText strong>
                                    Arguments:{' '}
                                </TypographyText>
                                <TypographyText>
                                    {data[type]?.arguments?.join(' ') || 'No'}
                                </TypographyText>
                            </SpaceDescriptions.Item>
                        </SpaceDescriptions>
                    </div>
                ))}
        </SpaceWide>
    );
}
