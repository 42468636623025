const result = {
    total: 61,
    backup_records: [
        {
            metadata: {
                class_name: 'CmonBackupRecord',
                backup_host: 'c5',
                backup_name: '',
                backup_tool_version: '8.0.22-13',
                chain_up: 0,
                children: 0,
                cid: 2,
                compressed: true,
                created: '2021-08-29T06:07:04.000Z',
                created_by: '',
                db_vendor: 'percona',
                description: '',
                encrypted: true,
                encryption_md5: '',
                finished: '2021-08-29T06:07:16.111Z',
                id: 6193,
                job_id: 22537,
                log_file: '',
                lsn: 0,
                method: 'mysqldump',
                mysqldump_type: 'SchemaAndData',
                parent_id: 0,
                root_dir: '/root/backups/BACKUP-6193',
                schedule_id: 0,
                server_version: '8.0.22-13',
                stanza_name: '',
                status: 'Completed',
                storage_host: 'c5',
                timezone: 'UTC',
                title: 'BACKUP-6193',
                total_datadir_size: 719189823,
                use_for_pitr: true,
                version: 1,
                backup: [
                    {
                        class_name: 'CmonBackupRecordEntry',
                        database_names: ['mysql'],
                        db: 'mysql',
                        start_time: '2021-08-29T06:07:13.000Z',
                        files: [
                            {
                                class_name: 'CmonBackupFile',
                                created: '2021-08-29T06:07:12.000Z',
                                hash: '',
                                path:
                                    'mysqldump_2021-08-29_060710_schemaanddata.sql.gz',
                                size: 243053,
                                type: 'data,schema',
                            },
                        ],
                    },
                ],
                config: {
                    backupDir: '/root/backups',
                    backupHost: 'c5',
                    backupMethod: 'mysqldump',
                    backupSubDir: 'BACKUP-%I',
                    backupToIndividualFiles: false,
                    backup_failover: false,
                    backup_failover_host: 'auto',
                    backup_retention: 0,
                    backup_title: '',
                    ccStorage: false,
                    checkHost: false,
                    compression: true,
                    compressionLevel: 6,
                    compressionThreads: -1,
                    createdBy: 'ccadmin',
                    description: null,
                    encrypt_backup: false,
                    excludeTables: null,
                    extended_insert: true,
                    includeDatabases: null,
                    includeTables: null,
                    mysqldump_type: 'SchemaAndData',
                    netcat_port: 0,
                    pitr_compatible: false,
                    port: 3306,
                    set_gtid_purged_off: true,
                    stanzaName: '',
                    storageHost: 'c5',
                    throttle_rate_iops: 0,
                    throttle_rate_netbw: 0,
                    usePigz: false,
                    wsrep_desync: false,
                    xtrabackupParallellism: 1,
                    xtrabackup_lock_ddl_per_table: false,
                    xtrabackup_locks: false,
                },
                verified: {
                    message: '',
                    status: 'Verified',
                    verified_time: '',
                },
            },
        },
        {
            metadata: {
                class_name: 'CmonBackupRecord',
                backup_host: 'c5',
                backup_name: '',
                backup_tool_version: '8.0.22-13',
                chain_up: 0,
                children: 0,
                cid: 1,
                compressed: true,
                created: '2021-08-28T06:07:06.000Z',
                created_by: '',
                db_vendor: 'percona',
                description: '',
                encrypted: true,
                encryption_md5: '',
                finished: '2021-08-28T06:07:17.836Z',
                id: 6192,
                job_id: 22529,
                log_file: '',
                lsn: 0,
                method: 'mysqldump',
                mysqldump_type: 'SchemaAndData',
                parent_id: 0,
                root_dir: '/root/backups/BACKUP-6192',
                schedule_id: 0,
                server_version: '8.0.22-13',
                stanza_name: '',
                status: 'Completed',
                storage_host: 'c5',
                timezone: 'UTC',
                title: 'BACKUP-6192',
                total_datadir_size: 719189823,
                use_for_pitr: false,
                version: 1,
                backup: [
                    {
                        class_name: 'CmonBackupRecordEntry',
                        database_names: ['mysql'],
                        db: 'mysql',
                        start_time: '2021-08-28T06:07:15.000Z',
                        files: [
                            {
                                class_name: 'CmonBackupFile',
                                created: '2021-08-28T06:07:13.000Z',
                                hash: '',
                                path:
                                    'mysqldump_2021-08-28_060712_schemaanddata.sql.gz',
                                size: 243053,
                                type: 'data,schema',
                            },
                        ],
                    },
                ],
                config: {
                    backupDir: '/root/backups',
                    backupHost: 'c5',
                    backupMethod: 'mysqldump',
                    backupSubDir: 'BACKUP-%I',
                    backupToIndividualFiles: false,
                    backup_failover: false,
                    backup_failover_host: 'auto',
                    backup_retention: 0,
                    backup_title: '',
                    ccStorage: false,
                    checkHost: false,
                    compression: true,
                    compressionLevel: 6,
                    compressionThreads: -1,
                    createdBy: 'ccadmin',
                    description: null,
                    encrypt_backup: false,
                    excludeTables: null,
                    extended_insert: true,
                    includeDatabases: ['db1', 'db2'],
                    includeTables: null,
                    mysqldump_type: 'SchemaAndData',
                    netcat_port: 0,
                    pitr_compatible: false,
                    port: 3306,
                    set_gtid_purged_off: true,
                    stanzaName: '',
                    storageHost: 'c5',
                    throttle_rate_iops: 0,
                    throttle_rate_netbw: 0,
                    usePigz: false,
                    wsrep_desync: false,
                    xtrabackupParallellism: 1,
                    xtrabackup_lock_ddl_per_table: false,
                    xtrabackup_locks: false,
                },
                verified: {
                    message: '',
                    status: 'NotVerified',
                    verified_time: '',
                },
            },
        },
        {
            metadata: {
                class_name: 'CmonBackupRecord',
                backup_host: 'c2',
                backup_name: '',
                backup_tool_version:
                    '10.4.18-MariaDB-1:10.4.18+maria~bionic-log',
                chain_up: 0,
                children: 0,
                cid: 1,
                compressed: true,
                created: '2021-08-28T03:00:01.000Z',
                created_by: '',
                db_vendor: 'mariadb',
                description: '',
                encrypted: false,
                encryption_md5: '',
                finished: '2021-08-28T03:00:36.158Z',
                id: 6191,
                job_id: 22528,
                log_file: '',
                lsn: 0,
                method: 'mysqldump',
                mysqldump_type: 'DbDumpFile',
                parent_id: 0,
                root_dir: '/root/backups/BACKUP-6191',
                schedule_id: 0,
                server_version: '10.4.18-MariaDB-1:10.4.18+maria~bionic-log',
                stanza_name: '',
                status: 'Completed',
                storage_host: 'c2',
                timezone: 'UTC',
                title: 'BACKUP-6191',
                total_datadir_size: 11738891706,
                use_for_pitr: false,
                version: 1,
                backup: [
                    {
                        class_name: 'CmonBackupRecordEntry',
                        database_names: ['alex', 'sbtest'],
                        db: 'alex,sbtest',
                        start_time: '2021-08-28T03:00:33.000Z',
                        files: [
                            {
                                class_name: 'CmonBackupFile',
                                created: '2021-08-28T03:00:31.000Z',
                                hash: 'md5:65e67e041b0c0098818d8e92472aac2e',
                                path:
                                    'mysqldump_2021-08-28_030013_dbdumpfile.sql.gz',
                                size: 95109266,
                                type: 'data,schema',
                            },
                        ],
                    },
                ],
                config: {
                    backupDir: '/root/backups',
                    backupHost: 'c2',
                    backupMethod: 'mysqldump',
                    backupSubDir: 'BACKUP-%I',
                    backupToIndividualFiles: false,
                    backup_failover: false,
                    backup_failover_host: '',
                    backup_retention: 0,
                    backup_title: '',
                    ccStorage: false,
                    checkHost: false,
                    compression: true,
                    compressionLevel: -1,
                    compressionThreads: -1,
                    createdBy: 'ccadmin',
                    description: null,
                    encrypt_backup: false,
                    excludeTables: null,
                    includeDatabases: ['db1', 'db2'],
                    includeTables: null,
                    mysqldump_type: '',
                    netcat_port: 0,
                    pitr_compatible: false,
                    port: 3306,
                    set_gtid_purged_off: true,
                    stanzaName: '',
                    storageHost: 'c2',
                    throttle_rate_iops: 0,
                    throttle_rate_netbw: 0,
                    usePigz: false,
                    wsrep_desync: false,
                    xtrabackupParallellism: 1,
                    xtrabackup_lock_ddl_per_table: false,
                    xtrabackup_locks: false,
                },
                verified: {
                    message: '',
                    status: 'NotVerified',
                    verified_time: '',
                },
            },
        },
        {
            version: 2,
            cloud_locations: [],
            host_locations: [
                {
                    created_time: '2022-01-26T11:48:04.000Z',
                    finished_time: '2022-01-26T11:48:59.090Z',
                    host_location_uuid: '813cb001-2054-446c-b7dd-5b937b6ff2fb',
                    root_dir: '/home/ubuntu/backups/BACKUP-7615',
                    storage_host: 'c5',
                },
            ],
            metadata: {
                class_name: 'CmonBackupRecord',
                backup_host: 'c5',
                backup_name: '',
                backup_tool_version: '8.0.23',
                chain_up: 0,
                children: 3,
                cid: 2,
                compressed: true,
                created: '2022-01-26T11:48:04.000Z',
                created_by: '',
                db_vendor: 'percona',
                description: '',
                encrypted: false,
                encryption_md5: '',
                finished: '2022-01-26T11:48:59.090Z',
                id: 7615,
                job_id: 27287,
                log_file: '',
                lsn: 24231805,
                method: 'xtrabackupfull',
                mysqldump_type: '',
                parent_id: 0,
                root_dir: '/home/ubuntu/backups/BACKUP-7615',
                schedule_id: 0,
                server_version: '8.0.22-13',
                stanza_name: '',
                status: 'Completed',
                storage_host: 'c5',
                timezone: 'UTC',
                title: 'BACKUP-7615',
                total_datadir_size: 724302960,
                use_for_pitr: true,
                backup: [
                    {
                        class_name: 'CmonBackupRecordEntry',
                        database_names: [],
                        db: '',
                        start_time: '2022-01-26T11:48:59.000Z',
                        files: [
                            {
                                class_name: 'CmonBackupFile',
                                created: '2022-01-26T11:48:18.000Z',
                                hash: '',
                                path:
                                    'backup-full-2022-01-26_114810.xbstream.gz',
                                size: 2073419,
                                type: 'full',
                            },
                        ],
                    },
                ],
                config: {
                    backupDir: '/home/ubuntu/backups',
                    backupHost: 'c5',
                    backupMethod: 'xtrabackupfull',
                    backupSubDir: 'BACKUP-%I',
                    backupToIndividualFiles: false,
                    backup_failover: false,
                    backup_failover_host: 'auto',
                    backup_password: '',
                    backup_retention: 0,
                    backup_system_db: false,
                    backup_title: '',
                    ccStorage: false,
                    checkHost: false,
                    compression: true,
                    compressionLevel: 6,
                    compressionThreads: -1,
                    createdBy: 'ccadmin',
                    description: null,
                    encrypt_backup: false,
                    includeDatabases: null,
                    mysqldump_type: '',
                    netcat_port: 0,
                    pitr_compatible: false,
                    port: 3306,
                    set_gtid_purged_off: true,
                    stanzaName: '',
                    storageHost: 'c5',
                    throttle_rate_iops: 0,
                    throttle_rate_netbw: 0,
                    usePigz: false,
                    wsrep_desync: false,
                    xtrabackupParallellism: 1,
                    xtrabackup_lock_ddl_per_table: false,
                    xtrabackup_locks: true,
                },
                verified: {
                    message: '',
                    status: 'NotVerified',
                    verified_time: '',
                },
            },
        },
        {
            version: 2,
            cloud_locations: [],
            host_locations: [
                {
                    created_time: '2022-01-26T11:51:04.000Z',
                    finished_time: '2022-01-26T11:51:14.908Z',
                    host_location_uuid: 'ec7e0a83-cb9e-428e-818c-530f8dcdf19c',
                    root_dir: '/home/ubuntu/backups/BACKUP-7618',
                    storage_host: 'c5',
                },
            ],
            metadata: {
                class_name: 'CmonBackupRecord',
                backup_host: 'c5',
                backup_name: '',
                backup_tool_version: '8.0.23',
                chain_up: 7617,
                children: 0,
                cid: 2,
                compressed: true,
                created: '2022-01-26T11:51:04.000Z',
                created_by: '',
                db_vendor: 'percona',
                description: '',
                encrypted: false,
                encryption_md5: '',
                finished: '2022-01-26T11:51:14.908Z',
                id: 7618,
                job_id: 27290,
                log_file: '',
                lsn: 24231925,
                method: 'xtrabackupincr',
                mysqldump_type: '',
                parent_id: 7615,
                root_dir: '/home/ubuntu/backups/BACKUP-7618',
                schedule_id: 0,
                server_version: '8.0.22-13',
                stanza_name: '',
                status: 'Completed',
                storage_host: 'c5',
                timezone: 'UTC',
                title: 'BACKUP-7618',
                total_datadir_size: 724057866,
                use_for_pitr: true,
                backup: [
                    {
                        class_name: 'CmonBackupRecordEntry',
                        database_names: [],
                        db: '',
                        start_time: '2022-01-26T11:51:14.000Z',
                        files: [
                            {
                                class_name: 'CmonBackupFile',
                                created: '2022-01-26T11:51:14.000Z',
                                hash: '',
                                path:
                                    'backup-incr-2022-01-26_115109.xbstream.gz',
                                size: 40332,
                                type: 'incr',
                            },
                        ],
                    },
                ],
                config: {
                    backupDir: '/home/ubuntu/backups',
                    backupHost: 'c5',
                    backupMethod: 'xtrabackupincr',
                    backupSubDir: 'BACKUP-%I',
                    backupToIndividualFiles: false,
                    backup_failover: false,
                    backup_failover_host: 'auto',
                    backup_password: '',
                    backup_retention: 0,
                    backup_system_db: false,
                    backup_title: '',
                    ccStorage: false,
                    checkHost: false,
                    compression: true,
                    compressionLevel: 6,
                    compressionThreads: -1,
                    createdBy: 'ccadmin',
                    description: null,
                    encrypt_backup: false,
                    includeDatabases: null,
                    mysqldump_type: '',
                    netcat_port: 0,
                    pitr_compatible: false,
                    port: 3306,
                    set_gtid_purged_off: true,
                    stanzaName: '',
                    storageHost: 'c5',
                    throttle_rate_iops: 0,
                    throttle_rate_netbw: 0,
                    usePigz: false,
                    wsrep_desync: false,
                    xtrabackupParallellism: 1,
                    xtrabackup_lock_ddl_per_table: false,
                    xtrabackup_locks: true,
                },
                verified: {
                    message: '',
                    status: 'NotVerified',
                    verified_time: '',
                },
            },
        },
        {
            version: 2,
            cloud_locations: [],
            host_locations: [
                {
                    created_time: '2022-01-26T11:50:03.000Z',
                    finished_time: '2022-01-26T11:50:14.956Z',
                    host_location_uuid: 'c2444081-f1fd-4bca-b101-8f4415ddfbbb',
                    root_dir: '/home/ubuntu/backups/BACKUP-7617',
                    storage_host: 'c5',
                },
            ],
            metadata: {
                class_name: 'CmonBackupRecord',
                backup_host: 'c5',
                backup_name: '',
                backup_tool_version: '8.0.23',
                chain_up: 7616,
                children: 0,
                cid: 2,
                compressed: true,
                created: '2022-01-26T11:50:03.000Z',
                created_by: '',
                db_vendor: 'percona',
                description: '',
                encrypted: false,
                encryption_md5: '',
                finished: '2022-01-26T11:50:14.956Z',
                id: 7617,
                job_id: 27289,
                log_file: '',
                lsn: 24231885,
                method: 'xtrabackupincr',
                mysqldump_type: '',
                parent_id: 7615,
                root_dir: '/home/ubuntu/backups/BACKUP-7617',
                schedule_id: 0,
                server_version: '8.0.22-13',
                stanza_name: '',
                status: 'Completed',
                storage_host: 'c5',
                timezone: 'UTC',
                title: 'BACKUP-7617',
                total_datadir_size: 724139564,
                use_for_pitr: true,
                backup: [
                    {
                        class_name: 'CmonBackupRecordEntry',
                        database_names: [],
                        db: '',
                        start_time: '2022-01-26T11:50:14.000Z',
                        files: [
                            {
                                class_name: 'CmonBackupFile',
                                created: '2022-01-26T11:50:14.000Z',
                                hash: '',
                                path:
                                    'backup-incr-2022-01-26_115009.xbstream.gz',
                                size: 40301,
                                type: 'incr',
                            },
                        ],
                    },
                ],
                config: {
                    backupDir: '/home/ubuntu/backups',
                    backupHost: 'c5',
                    backupMethod: 'xtrabackupincr',
                    backupSubDir: 'BACKUP-%I',
                    backupToIndividualFiles: false,
                    backup_failover: false,
                    backup_failover_host: 'auto',
                    backup_password: '',
                    backup_retention: 0,
                    backup_system_db: false,
                    backup_title: '',
                    ccStorage: false,
                    checkHost: false,
                    compression: true,
                    compressionLevel: 6,
                    compressionThreads: -1,
                    createdBy: 'ccadmin',
                    description: null,
                    encrypt_backup: false,
                    includeDatabases: null,
                    mysqldump_type: '',
                    netcat_port: 0,
                    pitr_compatible: false,
                    port: 3306,
                    set_gtid_purged_off: true,
                    stanzaName: '',
                    storageHost: 'c5',
                    throttle_rate_iops: 0,
                    throttle_rate_netbw: 0,
                    usePigz: false,
                    wsrep_desync: false,
                    xtrabackupParallellism: 1,
                    xtrabackup_lock_ddl_per_table: false,
                    xtrabackup_locks: true,
                },
                verified: {
                    message: '',
                    status: 'NotVerified',
                    verified_time: '',
                },
            },
        },
        {
            version: 2,
            cloud_locations: [],
            host_locations: [
                {
                    created_time: '2022-01-26T11:49:08.000Z',
                    finished_time: '2022-01-26T11:49:19.472Z',
                    host_location_uuid: '3ff34250-8a0c-44d6-8271-15aff99611bc',
                    root_dir: '/home/ubuntu/backups/BACKUP-7616',
                    storage_host: 'c5',
                },
            ],
            metadata: {
                class_name: 'CmonBackupRecord',
                backup_host: 'c5',
                backup_name: '',
                backup_tool_version: '8.0.23',
                chain_up: 7615,
                children: 0,
                cid: 2,
                compressed: true,
                created: '2022-01-26T11:49:08.000Z',
                created_by: '',
                db_vendor: 'percona',
                description: '',
                encrypted: false,
                encryption_md5: '',
                finished: '2022-01-26T11:49:19.472Z',
                id: 7616,
                job_id: 27288,
                log_file: '',
                lsn: 24231845,
                method: 'xtrabackupincr',
                mysqldump_type: '',
                parent_id: 7615,
                root_dir: '/home/ubuntu/backups/BACKUP-7616',
                schedule_id: 0,
                server_version: '8.0.22-13',
                stanza_name: '',
                status: 'Completed',
                storage_host: 'c5',
                timezone: 'UTC',
                title: 'BACKUP-7616',
                total_datadir_size: 724221262,
                use_for_pitr: true,
                backup: [
                    {
                        class_name: 'CmonBackupRecordEntry',
                        database_names: [],
                        db: '',
                        start_time: '2022-01-26T11:49:19.000Z',
                        files: [
                            {
                                class_name: 'CmonBackupFile',
                                created: '2022-01-26T11:49:18.000Z',
                                hash: '',
                                path:
                                    'backup-incr-2022-01-26_114913.xbstream.gz',
                                size: 40288,
                                type: 'incr',
                            },
                        ],
                    },
                ],
                config: {
                    backupDir: '/home/ubuntu/backups',
                    backupHost: 'c5',
                    backupMethod: 'xtrabackupincr',
                    backupSubDir: 'BACKUP-%I',
                    backupToIndividualFiles: false,
                    backup_failover: false,
                    backup_failover_host: 'auto',
                    backup_password: '',
                    backup_retention: 0,
                    backup_system_db: false,
                    backup_title: '',
                    ccStorage: false,
                    checkHost: false,
                    compression: true,
                    compressionLevel: 6,
                    compressionThreads: -1,
                    createdBy: 'ccadmin',
                    description: null,
                    encrypt_backup: false,
                    includeDatabases: null,
                    mysqldump_type: '',
                    netcat_port: 0,
                    pitr_compatible: false,
                    port: 3306,
                    set_gtid_purged_off: true,
                    stanzaName: '',
                    storageHost: 'c5',
                    throttle_rate_iops: 0,
                    throttle_rate_netbw: 0,
                    usePigz: false,
                    wsrep_desync: false,
                    xtrabackupParallellism: 1,
                    xtrabackup_lock_ddl_per_table: false,
                    xtrabackup_locks: true,
                },
                verified: {
                    message: '',
                    status: 'NotVerified',
                    verified_time: '',
                },
            },
        },
    ],
    debug_messages: ["RPC V2 authenticated user is 'ccadmin'."],
};

export default result;
