import React, { useEffect, useState } from 'react';
import { Select, SelectProps } from 'antd';
import CmonConfigService from '../../services/cmon/CmonConfigService';
import { CcClusterType } from '../../services/models/CcCluster';
import useFetch from '../useFetch';

export default ConfigTemplateInput;

export type ConfigTemplateInputProps = SelectProps<any> & {
    vendor: string;
    version: string;
    clusterType: CcClusterType;
    value?: string;
    onChange?: (value: string) => void;
    extraQueryParams?: { db_role?: string };
};

function ConfigTemplateInput({
    vendor,
    version,
    clusterType,
    value,
    onChange,
    loading,
    disabled,
    extraQueryParams,
    ...rest
}: ConfigTemplateInputProps) {
    const [configTemplates, setConfigTemplates] = useState<string[]>([]);

    const {
        loading: loadingData,
        data,
        refresh: refreshFetch,
        cancel: cancelFetch,
    } = useFetch({
        name: 'config-template-list',
        useCache: true,
        fetchFn: async (params, opts) => {
            const { data } = await CmonConfigService.listTemplates(
                {
                    ...params,
                    ...extraQueryParams,
                    cluster_type: clusterType.toLowerCase(),
                    vendor,
                    version,
                },
                opts
            );

            return data;
        },
        cancelFn: async ({ requestId }) => {
            await CmonConfigService.cancelRequest(requestId);
        },
    });
    useEffect(() => {
        (async () => {
            await refreshFetch();
        })();
        return () => {
            (async () => {
                await cancelFetch();
            })();
        };
    }, []);

    useEffect(() => {
        if (data) {
            setConfigTemplates(data.config_templates || []);
            onChange?.(data.config_templates[0]);
        }
    }, [data]);

    const handleOnChange = (value: string) => {
        onChange?.(value);
    };

    return (
        <Select
            className="ConfigTemplateInput"
            style={{ width: '100%' }}
            onChange={handleOnChange}
            value={value}
            loading={loadingData || loading}
            disabled={loadingData || disabled}
            {...rest}
        >
            {configTemplates.map((configItem) => (
                <Select.Option value={configItem} key={configItem}>
                    {configItem}
                </Select.Option>
            ))}
        </Select>
    );
}
