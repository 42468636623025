import React, { useEffect } from 'react';
import AppDivider from '../common/AppDivider';
import SpaceDescriptions from '../common/Layout/SpaceDescriptions';
import MonitorSettingsMenu from '../components/Monitor/MonitorSettingsMenu';
import { baseClustersList } from './dataMocks/clusters';
import CcCluster from '../services/models/CcCluster';
import { useDispatch } from 'react-redux';
import { setClusters } from '../appReducer';
import Immutable from 'immutable';

export default DebugMonitoringActionMenu;

export type DebugMonitoringActionMenuProps = {};

const clusters = baseClustersList.map((c) => new CcCluster(c as any));

function DebugMonitoringActionMenu({}: DebugMonitoringActionMenuProps) {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(
            setClusters(
                Immutable.Map(
                    clusters.map((c: CcCluster) => {
                        return [c.getKey(), c];
                    })
                )
            )
        );
    }, []);
    return (
        <div className="DebugMonitoringActionMenu">
            {clusters.map((c) => {
                return (
                    <SpaceDescriptions
                        key={c.clusterName}
                        title={c.clusterName}
                        direction={'vertical'}
                    >
                        <MonitorSettingsMenu cluster={c} />
                        <AppDivider />
                    </SpaceDescriptions>
                );
            })}
        </div>
    );
}
