import React from 'react';
import ActionsMenu, {
    ActionsMenuProps,
} from '../../../common/Navigation/ActionsMenu';
import CcNode, {
    CcNodeBase,
    CcNodeRole,
    CcNodeStatus,
    CcNodeType,
} from '../../../services/models/CcNode';
import CcCluster, {
    CcClusterBase,
    CcClusterType,
} from '../../../services/models/CcCluster';
import CcMySqlNode from '../../../services/models/CcMySqlNode';
import CcPostgreSqlNode from '../../../services/models/CcPostgreSqlNode';
import NodeRebootHostButton from './NodeRebootHostButton';
import NodeDisableBinaryLoggingButton from './NodeDisableBinaryLoggingButton';
import NodeStopButton from './NodeStopButton';
import NodeRestartButton from './NodeRestartButton';
import NodeStartButton from './NodeStartButton';
import NodeUnregisterButton from './NodeUnregisterButton';
import NodeChangeReplicationMasterButton from './NodeChangeReplicationMasterButton';
import NodePromoteSlaveButton from './NodePromoteSlaveButton';
import NodeStopSlaveButton from './NodeStopSlaveButton';
import NodeStartSlaveButton from './NodeStartSlaveButton';
import NodeToggleReadonlyButton from './NodeToggleReadonlyButton';
import AddMaintenanceButton from '../../Maintenance/AddMaintenanceButton';
import DisableMaintenanceButton from '../../Maintenance/DisableMaintenanceButton';
import NodeResetReplicaButton from './NodeResetReplicaButton';
import NodeRebuildReplicationSlaveButton from './NodeRebuildReplicationSlaveButton';
import ClusterConfigureWalButton from '../../Clusters/Actions/ClusterConfigureWalButton';
import useMaintenanceWatcher from '../../Maintenance/useMaintenanceWatcher';
import ManageMaintenanceButton from '../../Maintenance/ManageMaintenanceButton';
import NodeToggleSynchronousReplicationButton from './NodeToggleSynchronousReplicationButton';
import NodeFreezeButton from './NodeFreezeButton';
import NodeStepDownButton from './NodeStepDownButton';
import CcMongoNode, {
    CcMongoNodeMemberRole,
} from '../../../services/models/CcMongoNode';
import { ActionMenuItem } from '@severalnines/bar-frontend-components/build/lib/Navigation/ActionMenu';
import NodeDetailsButton from './NodeDetailsButton';
import UserAclManageCluster from '../../User/UserAclManageCluster';
import ProxySqlExportConfigButton from '../ProxySqlAction/ProxySqlExportConfigButton';
import ProxySqlSynchronizeInstancesButton from '../ProxySqlAction/ProxySqlSynchronizeInstancesButton';
import NodeResyncButton from './NodeResyncButton';

export default NodeActionsMenu;

export type NodeActionsMenuProps = ActionsMenuProps & {
    node: CcNode;
    cluster: CcCluster;
    onActionPerformed?: () => void;
};

function NodeActionsMenu({
    node,
    cluster,
    onActionPerformed,
    ...rest
}: NodeActionsMenuProps) {
    const {
        active: activeMaintenance,
        next: nextMaintenance,
    } = useMaintenanceWatcher({
        hostname: node.hostname,
    });

    const handleActionSuccess = () => {
        onActionPerformed?.();
    };
    let nodeDetailsButton = undefined;
    let nodeDisableBinaryLogButton = undefined;
    let nodeToggleReadonlyButton = undefined;
    let nodePromoteSlaveButton = undefined;
    let nodeRebuildReplicationSlaveButton = undefined;
    let nodeChangeReplicationMasterButton = undefined;
    let nodeStopSlaveButton = undefined;
    let nodeStartSlaveButton = undefined;
    let nodeResetReplicaButton = undefined;

    if (
        [
            CcClusterType.TYPE_REPLICATION,
            CcClusterType.TYPE_GALERA,
            CcClusterType.TYPE_POSTGRESQL,
            CcClusterType.TYPE_TIMESCALEDB,
        ].includes(cluster.clusterType) &&
        node.isPerformanceHaNode()
    ) {
        nodeDetailsButton = {
            key: 'node-details-button',
            label: <NodeDetailsButton node={node} cluster={cluster} />,
        };
    }

    if (
        [
            CcClusterType.TYPE_GALERA,
            CcClusterType.TYPE_REPLICATION,
            CcClusterType.TYPE_MSSQL_AO_ASYNC,
            CcClusterType.TYPE_POSTGRESQL,
            CcClusterType.TYPE_TIMESCALEDB,
        ].includes(cluster.clusterType)
    ) {
        if (
            // is Mysql, Mssql or Postgres and replica or readonly
            ([
                CcNodeType.MYSQL,
                CcNodeType.MSSQL,
                CcNodeType.POSTGRESQL,
                CcNodeType.TIMESCALEDB,
            ].includes(node.getType()) &&
                (node.readonly || node.isReplica())) ||
            // or is postgres and primary not connected or shutdown
            (node.isBase(CcNodeBase.BASE_POSTGRESQL) &&
                node.isPrimary() &&
                (!node.connected ||
                    (node.hoststatus as CcNodeStatus) ===
                        CcNodeStatus.CmonHostShutDown))
        ) {
            nodeRebuildReplicationSlaveButton = {
                key: 'node-rebuild-replication-slave',
                label: (
                    <NodeRebuildReplicationSlaveButton
                        node={node}
                        cluster={cluster}
                    />
                ),
            };
        }
    }

    if (
        [CcNodeStatus.CmonHostOnline, CcNodeStatus.CmonHostFailed].includes(
            node.hoststatus as CcNodeStatus
        )
    ) {
        if (node.isType(CcNodeType.GALERA) && node.isRole(CcNodeRole.MASTER)) {
            nodeDisableBinaryLogButton = {
                key: 'node-disable-binary-logging',
                label: <NodeDisableBinaryLoggingButton node={node} />,
            };
        }

        if (
            (node.isType(CcNodeType.MYSQL) || node.isType(CcNodeType.GALERA)) &&
            node.connected
        ) {
            nodeToggleReadonlyButton = {
                key: 'node-enable-readonly',
                waitForConfirm: true,
                label: <NodeToggleReadonlyButton node={node} />,
            };
        }

        const mysqlReplicaNode =
            (cluster.isType(CcClusterType.TYPE_MYSQL_SINGLE) ||
                cluster.isType(CcClusterType.TYPE_REPLICATION)) &&
            node.readonly &&
            node.isType(CcNodeType.MYSQL) &&
            node.isReplica();
        const postgresReplicaNode =
            cluster.isBase(CcClusterBase.BASE_POSTGRESQL) &&
            node.isBase(CcNodeBase.BASE_POSTGRESQL) &&
            node.isReplica();
        const mssqlReplicaNode =
            cluster.isType(CcClusterType.TYPE_MSSQL_AO_ASYNC) &&
            node.isType(CcNodeType.MSSQL) &&
            node.isReplica();
        if (mysqlReplicaNode || postgresReplicaNode || mssqlReplicaNode) {
            nodePromoteSlaveButton = {
                key: 'node-promote-slave',
                label: (
                    <NodePromoteSlaveButton
                        node={node}
                        clusterType={cluster.clusterType}
                    />
                ),
            };
        }

        if (
            (cluster.isType(CcClusterType.TYPE_GALERA) &&
                node.isRole(CcNodeRole.SLAVE)) ||
            (cluster.isType(CcClusterType.TYPE_REPLICATION) &&
                node.isType(CcNodeType.MYSQL))
        ) {
            nodeChangeReplicationMasterButton = {
                key: 'node-change-replication-master',
                label: (
                    <NodeChangeReplicationMasterButton
                        node={node}
                        cluster={cluster}
                    />
                ),
            };
        }

        if (
            (node.isType(CcNodeType.GALERA) || node.isType(CcNodeType.MYSQL)) &&
            node.isReplica()
        ) {
            nodeResetReplicaButton = {
                key: 'node-reset-replica',
                waitForConfirm: true,
                label: <NodeResetReplicaButton node={node} />,
            };

            if ((node as CcMySqlNode).isReplicationRunning()) {
                nodeStopSlaveButton = {
                    key: 'node-stop-slave',
                    waitForConfirm: true,
                    label: <NodeStopSlaveButton node={node} />,
                };
            }

            if (
                !(node as CcMySqlNode).isReplicationIoRunning() &&
                !(node as CcMySqlNode).isReplicationSqlRunning()
            ) {
                nodeStartSlaveButton = {
                    key: 'node-start-slave',
                    waitForConfirm: true,
                    label: <NodeStartSlaveButton node={node} />,
                };
            }
            if (
                (node as CcMySqlNode).isReplicationIoRunning() &&
                !(node as CcMySqlNode).isReplicationSqlRunning()
            ) {
                nodeStartSlaveButton = {
                    key: 'node-start-slave-sql-thread',
                    waitForConfirm: true,
                    label: (
                        <NodeStartSlaveButton
                            node={node}
                            title="Start replica SQL thread"
                        />
                    ),
                };
                nodeStopSlaveButton = {
                    key: 'node-stop-slave-io-thread',
                    waitForConfirm: true,
                    label: (
                        <NodeStopSlaveButton
                            node={node}
                            title="Stop replica IO thread"
                        />
                    ),
                };
            }
            if (
                !(node as CcMySqlNode).isReplicationIoRunning() &&
                (node as CcMySqlNode).isReplicationSqlRunning()
            ) {
                nodeStartSlaveButton = {
                    key: 'node-start-slave-io-thread',
                    waitForConfirm: true,
                    label: (
                        <NodeStartSlaveButton
                            node={node}
                            title="Start replica IO thread"
                        />
                    ),
                };
                nodeStopSlaveButton = {
                    key: 'node-stop-slave-sql-thread',
                    waitForConfirm: true,
                    label: (
                        <NodeStopSlaveButton
                            node={node}
                            title="Stop replica SQL thread"
                        />
                    ),
                };
            }
        }
    }

    let nodeRebootHostButton = {
        key: 'node-reboot-host',
        waitForConfirm: true,
        label: <NodeRebootHostButton node={node} />,
    };
    let nodeRestartButton = undefined;
    let nodeStopButton = undefined;
    if (
        [CcNodeStatus.CmonHostOnline, CcNodeStatus.CmonHostFailed].includes(
            node.hoststatus as CcNodeStatus
        )
    ) {
        nodeRestartButton = {
            key: 'node-restart-node',
            label: <NodeRestartButton node={node} />,
        };
        nodeStopButton = {
            key: 'node-stop-node',
            label: <NodeStopButton node={node} />,
        };
    }

    const nodeResyncButton = node.isType(CcNodeType.GALERA)
        ? {
              key: 'node-resync-node',
              label: <NodeResyncButton node={node} />,
          }
        : undefined;

    const nodeStartButton = [
        CcNodeStatus.CmonHostShutDown,
        CcNodeStatus.CmonHostOffLine,
    ].includes(node.hoststatus as CcNodeStatus)
        ? {
              key: 'node-start-node',
              label: <NodeStartButton node={node} />,
          }
        : undefined;

    const nodeUnregisterButton = {
        key: 'node-unregister-node',
        label: <NodeUnregisterButton node={node} />,
    };

    const nodeEnableMaintenanceModeButton = {
        key: 'node-enable-maintenance-mode',
        label: <AddMaintenanceButton node={node} />,
    };
    const nodeManageMaintenanceModeButton =
        nextMaintenance || activeMaintenance
            ? {
                  key: 'node-manage-maintenance-mode',
                  label: (
                      <ManageMaintenanceButton
                          node={node}
                          onSuccess={handleActionSuccess}
                      />
                  ),
              }
            : undefined;
    const nodeDisableMaintenanceModeButton = node.isMaintenanceModeEnabled()
        ? {
              key: 'node-disable-maintenance-mode',
              waitForConfirm: true,
              label: (
                  <DisableMaintenanceButton
                      node={node}
                      onSuccess={handleActionSuccess}
                  />
              ),
          }
        : undefined;

    // proxysql
    const nodeProxySqlExportConfigButton = node.isType(CcNodeType.PROXYSQL)
        ? {
              key: 'proxysql-export-config',
              label: (
                  <ProxySqlExportConfigButton
                      cluster={cluster}
                      onSuccess={handleActionSuccess}
                  />
              ),
          }
        : null;
    const nodeProxySqlSynchronizeInstancesButton = node.isType(
        CcNodeType.PROXYSQL
    )
        ? {
              key: 'proxysql-synchronize-instances',
              label: (
                  <ProxySqlSynchronizeInstancesButton
                      cluster={cluster}
                      onSuccess={handleActionSuccess}
                  />
              ),
          }
        : null;

    // postgres
    const nodeConfigureWalButton =
        cluster.isBase(CcClusterBase.BASE_POSTGRESQL) &&
        node.isBase(CcNodeBase.BASE_POSTGRESQL) &&
        node.connected
            ? {
                  key: 'node-configure-wal',
                  label: (
                      <ClusterConfigureWalButton
                          cluster={cluster}
                          node={node}
                          onSuccess={handleActionSuccess}
                      />
                  ),
              }
            : null;

    const nodeToggleSynchronousReplicationButton =
        cluster.isBase(CcClusterBase.BASE_POSTGRESQL) &&
        node.isType([CcNodeType.POSTGRESQL, CcNodeType.TIMESCALEDB]) &&
        node.isReplica()
            ? {
                  key: 'node-toggle-synchronous-replication',
                  waitForConfirm: true,
                  label: (
                      <NodeToggleSynchronousReplicationButton
                          node={node as CcPostgreSqlNode}
                          onSuccess={handleActionSuccess}
                      />
                  ),
              }
            : null;

    // mongo
    const isMongoNode =
        cluster.isBase(CcClusterBase.BASE_MONGODB) &&
        node.isType(CcNodeType.MONGO);
    const nodeFreezeButton =
        isMongoNode &&
        !node.isRole(CcNodeRole.MONGO_MONGOS) &&
        !node.isRole(CcNodeRole.MONGO_CONFIG_SERVER)
            ? {
                  key: 'node-freeze',
                  label: (
                      <NodeFreezeButton
                          node={node as CcMongoNode}
                          onSuccess={handleActionSuccess}
                      />
                  ),
              }
            : null;

    const nodeStepDownButton =
        isMongoNode &&
        (node as CcMongoNode).memberRole === CcMongoNodeMemberRole.PRIMARY
            ? {
                  key: 'node-step-down',
                  label: (
                      <NodeStepDownButton
                          node={node as CcMongoNode}
                          onSuccess={handleActionSuccess}
                      />
                  ),
              }
            : null;

    const items = [
        nodeDetailsButton,
        nodeEnableMaintenanceModeButton,
        nodeManageMaintenanceModeButton,
        nodeDisableMaintenanceModeButton,
        nodeProxySqlSynchronizeInstancesButton,
        nodeProxySqlExportConfigButton,
        nodeRebootHostButton,
        nodeStartButton,
        nodeResyncButton,
        nodeRestartButton,
        nodeStepDownButton,
        nodeFreezeButton,
        nodeConfigureWalButton,
        nodeToggleSynchronousReplicationButton,
        nodeToggleReadonlyButton,
        nodeDisableBinaryLogButton,
        nodePromoteSlaveButton,
        nodeStartSlaveButton,
        nodeStopSlaveButton,
        nodeResetReplicaButton,
        nodeRebuildReplicationSlaveButton,
        nodeChangeReplicationMasterButton,
        nodeStopButton,
        nodeUnregisterButton,
    ].filter((i) => i !== undefined);
    return (
        <UserAclManageCluster cluster={cluster}>
            <ActionsMenu
                disabled={items.length === 0}
                items={items as ActionMenuItem[]}
                {...rest}
            />
        </UserAclManageCluster>
    );
}
