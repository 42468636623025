import React, { useState } from 'react';
import JobActionButton from '../../Jobs/JobActionButton';
import { CmonJobInstanceCommand } from '../../../services/cmon/models/CmonJobInstance';
import ButtonWithForm from '../../../common/General/ButtonWithForm';
import CcCluster from '../../../services/models/CcCluster';
import AddNodeReplicationModal from './AddNode/Replication/AddNodeReplicationModal';
import { ButtonProps } from 'antd/lib/button';

export default ClusterAddReplicationSecondary;

export type ClusterAddReplicationSecondaryProps = ButtonProps & {
    cluster: CcCluster;
    children?: React.ReactNode;
    onSuccess?: () => void;
};

function ClusterAddReplicationSecondary({
    cluster,
    onSuccess,
    children,
    ...rest
}: ClusterAddReplicationSecondaryProps) {
    const [command, setCommand] = useState<CmonJobInstanceCommand>(
        CmonJobInstanceCommand.ADD_REPLICATION_SLAVE
    );
    const handleCommandChange = (command: CmonJobInstanceCommand) => {
        setCommand(command);
    };
    return (
        <ButtonWithForm
            button={
                <JobActionButton
                    command={command}
                    clusterId={cluster.clusterId}
                    {...rest}
                >
                    {children || 'Add replication node'}
                </JobActionButton>
            }
            form={
                <AddNodeReplicationModal
                    cluster={cluster}
                    onSuccess={onSuccess}
                    onCommandChange={handleCommandChange}
                />
            }
        />
    );
}
