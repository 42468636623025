import { Col, Form, Row, Space } from 'antd';
import { FormInstance } from 'antd/lib/form';
import ClusterSelectDropdown, {
    SELECT_ALL_CLUSTER_OPTION,
} from '../../Clusters/ClusterSelectDropdown';
import EventSelect, {
    ALL_OPTION,
    ALL_WARNING_OPTION,
    ALL_CRITICAL_OPTION,
    ALARM_WARNING,
    ALARM_CRITICAL,
    ALL,
} from '../../../common/DataEntry/EventSelect';
import CcCluster from '../../../services/models/CcCluster';
import { NotificationServicesWizardFormFields } from './NotificationServicesWizardForm';

export default NotificationServicesSettings;

export type NotificationServicesSettingsProps = {
    form: FormInstance<NotificationServicesWizardFormFields>;
};

function NotificationServicesSettings({
    form,
}: NotificationServicesSettingsProps) {
    const handleClustersSelect = (clusters?: CcCluster[]) => {
        // FIXME: clusters array should container only CcCLuster models
        if (clusters?.includes(SELECT_ALL_CLUSTER_OPTION as any)) {
            form.setFieldsValue({
                clusters: [SELECT_ALL_CLUSTER_OPTION],
            });
        } else {
            form.setFieldsValue({
                clusters: clusters?.map((cluster) => cluster?.clusterId || ''),
            });
        }
    };

    const handleEventSelect = (event: string) => {
        if (event === ALL_OPTION) {
            form.setFieldsValue({
                events: [ALL_OPTION],
            });
        } else if (event === ALL_WARNING_OPTION) {
            const events = form
                .getFieldValue('events')
                .filter(
                    (event: string) =>
                        ![ALARM_WARNING, ALL].includes(event.split(',')?.[1])
                );
            events.unshift(ALL_WARNING_OPTION);
            form.setFieldsValue({
                events,
            });
        } else if (event === ALL_CRITICAL_OPTION) {
            const events = form
                .getFieldValue('events')
                .filter(
                    (event: string) =>
                        ![ALARM_CRITICAL, ALL].includes(event.split(',')?.[1])
                );
            events.unshift(ALL_CRITICAL_OPTION);
            form.setFieldsValue({
                events,
            });
        } else {
            const [trigger, alarm] = event.split(',');
            const removeItems = [ALL_OPTION];
            if (alarm === ALARM_WARNING || alarm === ALARM_CRITICAL) {
                if (alarm === ALARM_WARNING) {
                    removeItems.push(ALL_WARNING_OPTION);
                } else if (alarm === ALARM_CRITICAL) {
                    removeItems.push(ALL_CRITICAL_OPTION);
                }
                removeItems.push(`${trigger},${ALL}`);
                const events = form
                    .getFieldValue('events')
                    .filter((event: string) => !removeItems.includes(event));
                form.setFieldsValue({
                    events,
                });
            } else if (alarm === ALL) {
                removeItems.push(`${trigger},${ALARM_WARNING}`);
                removeItems.push(`${trigger},${ALARM_CRITICAL}`);
                const events = form
                    .getFieldValue('events')
                    .filter((event: string) => !removeItems.includes(event));
                form.setFieldsValue({
                    events,
                });
            }
        }
    };
    return (
        <>
            <h3
                style={{
                    marginBottom: '10px',
                }}
            >
                Notification settings
            </h3>
            <Row gutter={[24, 0]}>
                <Col xs={24} sm={24} md={24}>
                    <Form.Item
                        name="clusters"
                        label={<Space>Clusters</Space>}
                        rules={[
                            {
                                required: true,
                                message:
                                    'Please choose one or more clusters to track',
                            },
                        ]}
                    >
                        <ClusterSelectDropdown
                            onClustersSelect={handleClustersSelect}
                            showId={true}
                            useGlobalState
                            addAllOption={true}
                            multiple={true}
                            placeholder="Choose one or more clusters to track"
                        />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24}>
                    <Form.Item
                        name="events"
                        label="Events"
                        rules={[
                            {
                                required: true,
                                message:
                                    'Please choose one or more events to track!',
                            },
                        ]}
                    >
                        <EventSelect onEventSelect={handleEventSelect} />
                    </Form.Item>
                </Col>
            </Row>
        </>
    );
}
