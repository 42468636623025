


export interface CmonDbStatsProps  {
    class_name?: string;
    data_size?: number;
    database_count?: number;
    datadir?: string;
    index_size?: number;

}



export default class CmonDbStats {
    public className?: string;
    public dataSize?: number;
    public databaseCount?: number;
    public datadir?: string;
    public indexSize?: number;


    constructor(props: CmonDbStatsProps) {
        this.className = props.class_name;
        this.dataSize = props.data_size;
        this.databaseCount = props.database_count;
        this.datadir = props.datadir;
        this.indexSize = props.index_size;

    }

}
