import React, {
    Fragment,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';
import './EmailNotifications.less';
import { ResponsiveContext } from '@severalnines/bar-frontend-components/build/lib/Layout/Responsive';
import CcCluster from '../../services/models/CcCluster';
import AppEmpty from '../../common/Feedback/AppEmpty';
import AppTable from '../../common/DataDisplay/AppTable';
import TypographyText from '../../common/TypographyText';
import { Alert, Button, Col, Form, Row, Select, Space } from 'antd';
import { ActionsMenuProps } from '../../common/Navigation/ActionsMenu';
import CmonConfigService from '../../services/cmon/CmonConfigService';
import useFetch from '../../common/useFetch';
import EmailNotificationsAction from './EmailNotificationsAction';
import useUsersList from '../UserManagement/useUsersList';
import {
    formatUserData,
    ResultGroup,
    UserEmail,
} from './emailNotificationsHelper';
import { DefaultOptionType } from 'antd/lib/select';
import ButtonWithForm from '../../common/General/ButtonWithForm';
import { PlusOutlined } from '@ant-design/icons';
import ExternalEmailModal from './ExternalEmailModal';
import { notifyError } from '../Notifications/uiNotification';
import EmailNotificationDailyLimit from './EmailNotificationDailyLimit';
import EmailNotificationDeliveryTime from './EmailNotificationDeliveryTime';
import useCurrentUser from '../User/useCurrentUser';
import AppSpin from '../../common/General/AppSpin';
import EmailNotificationsAddRecipientButton from './EmailNotificationsAddRecipientButton';
import EmailNotificationsRemoveRecipientButton from './EmailNotificationsRemoveRecipientButton';
import AppLink from '../../common/AppLink';
import useGroupTree from '../UserManagement/useGroupTree';
import { CcTreeItemAccess } from '../../services/models/CcTreeItem';

//@TODO: This file a bit too big, lets try to refactor in future

export default EmailNotifications;

export type TableDataProps = {
    id: number;
    alarm_name: string;
    component: string;
    log_name: string;
    message_name: string;
    CRITICAL: string;
    INFO: string;
    WARNING: string;
};

export type EmailNotificationsProps = ActionsMenuProps & {
    cluster: CcCluster;
};

function EmailNotifications({ cluster, ...rest }: EmailNotificationsProps) {
    const { responsive } = useContext(ResponsiveContext);
    const [form] = Form.useForm();

    const { record: currentUser, canManageUsers } = useCurrentUser();

    // table Data
    const [tableData, setTableData] = useState([]);

    // selected user
    const [selectedUser, setSelectedUser] = useState<UserEmail>();

    // group value
    const [groupValue, setGroupValue] = useState<string | undefined>('');

    const {
        refresh: refreshGroupAcl,
        loading: loadingGroupAcl,
        getAclOverClusters,
    } = useGroupTree({});

    // group list
    const [groupsList, setGroupsList] = useState<ResultGroup[]>();
    const {
        refresh: usersRefresh,
        list: usersList,
        loading: usersLoading,
    } = useUsersList({
        name: 'users-from-group-list',
        pageSize: 0,
    });

    const {
        data: recipients,
        loading: loadingRecipients,
        refresh: recipientsRefresh,
    } = useFetch<any>({
        fetchFn: async () => {
            const { data } = await CmonConfigService.getrecipients({
                cluster_id: cluster.clusterId,
            });
            return data;
        },
        cancelFn: async ({ requestId }) => {
            await CmonConfigService.cancelRequest(requestId);
        },
    });

    useEffect(() => {
        (async () => {
            await usersRefresh();
            await recipientsRefresh({});
        })();
    }, []);

    useEffect(() => {
        if (usersList && recipients) {
            // if he an Admin user
            if (canManageUsers()) {
                setGroupsList(formatUserData(usersList, recipients));
            } else {
                const group = formatUserData(
                    usersList,
                    recipients
                ).filter((group) =>
                    group.users.find(
                        (user) =>
                            user.emailAddress === currentUser?.emailAddress
                    )
                );
                setGroupsList(group);
                setGroupValue(group[0].group_name);
                refreshGroupAcl({ groupName: group[0].group_name });
            }
        }
    }, [usersList, recipients]);

    useEffect(() => {
        if (canManageUsers()) {
            const currentUserGroup = currentUser?.userGroups?.[0]?.groupName;
            form.setFieldsValue({
                groups: currentUserGroup,
                allUsers: currentUser?.emailAddress,
            });
            setGroupValue(currentUserGroup);
            setUserValue(currentUser?.emailAddress);
        }
    }, []);

    const getGroupsOptions = useCallback(():
        | DefaultOptionType[]
        | undefined => {
        return groupsList?.map((group) => {
            return {
                value: group.group_name,
                label: group.group_name,
            };
        });
    }, [groupsList]);

    const groupOptions: DefaultOptionType[] | undefined = useMemo(
        () => getGroupsOptions(),
        [groupsList, recipients]
    );

    // user value
    const [userValue, setUserValue] = useState<string | undefined>('');

    useEffect(() => {
        if (userValue) {
            setSelectedUser(
                groupsList
                    ?.find((group) => group.group_name === groupValue)
                    ?.users.find((user) => user.emailAddress === userValue)
            );
        }
    }, [userValue, groupsList]);

    // user options
    const getUsersOptions = useCallback(
        (groupName?: string): DefaultOptionType[] | undefined => {
            return groupsList
                ?.find((group) => group.group_name === groupName)
                ?.users.map((user) => {
                    return {
                        value: user.emailAddress,
                        label: user.emailAddress,
                        groupName: groupName,
                    };
                });
        },
        [groupsList]
    );

    const userOptions: DefaultOptionType[] | undefined = useMemo(
        () => getUsersOptions(groupValue),
        [groupValue, getUsersOptions]
    );

    const handleGroupChange = async (value: string) => {
        setGroupValue(value);
        getUsersOptions(value);
        form.resetFields(['allUsers', 'emailLimit', 'digest']);
        setUserValue('');
        setTableData([]);
        setSelectedUser(undefined);
    };

    useEffect(() => {
        if (groupValue) {
            refreshGroupAcl({ groupName: groupValue, showLoading: true });
        }
    }, [groupValue]);

    const canGroupAccessCluster = useCallback(() => {
        return getAclOverClusters([
            CcTreeItemAccess.READ,
            CcTreeItemAccess.FULL_ACCESS,
        ]).find(
            (item) =>
                item.clusterKey === `${cluster.clusterId}` && !item.disabled
        );
    }, [getAclOverClusters]);

    // user change
    const onUserChange = (value: string) => {
        form.resetFields(['emailLimit', 'digest']);
        setUserValue(value);
        if (Array.isArray(userOptions))
            setSelectedUser(
                groupsList
                    ?.find((group) => group.group_name === groupValue)
                    ?.users.find((user) => user.emailAddress === value)
            );
    };

    // get notification settings from API
    const {
        data: notificationSettings,
        loading: loadingNotificationSettings,
        refresh: notificationRefresh,
    } = useFetch<any>({
        fetchFn: async (params) => {
            const { data: metaData } = await CmonConfigService.getcomponentmeta(
                {}
            );

            const { data } = await CmonConfigService.getnotificationsettings({
                cluster_id: cluster.clusterId,
                email: params?.email
                    ? params?.email
                    : selectedUser?.emailAddress,
            });
            const settings: any = data[0].settings;
            const newTableData = metaData.map((meta: any, index: number) => {
                return {
                    id: index,
                    ...meta,
                    ...settings[`${meta.component}`],
                };
            });
            return {
                notifications: data[0],
                tableData: newTableData,
            };
        },
        cancelFn: async ({ requestId }) => {
            await CmonConfigService.cancelRequest(requestId);
        },
    });

    useEffect(() => {
        if (currentUser?.emailAddress && !canManageUsers()) {
            (async () => {
                await notificationRefresh({ email: currentUser?.emailAddress });
            })();
            setUserValue(currentUser?.emailAddress);
            setSelectedUser(
                groupsList
                    ?.find((group) => group.group_name === groupValue)
                    ?.users.find(
                        (user) =>
                            user.emailAddress === currentUser?.emailAddress
                    )
            );
        }
    }, [groupValue, currentUser]);

    useEffect(() => {
        if (!selectedUser?.active) {
            setTableData([]);
        }
        if (selectedUser && selectedUser.active) {
            (async () => {
                await notificationRefresh({ email: selectedUser.emailAddress });
            })();
        }
    }, [selectedUser]);

    useEffect(() => {
        if (notificationSettings) {
            setTableData(notificationSettings?.tableData);
            if (notificationSettings?.notifications) {
                form.setFieldsValue({
                    emailLimit: notificationSettings?.notifications.daily_limit,
                });
                form.setFieldsValue({
                    digest: notificationSettings?.notifications.digest_hour,
                });
            }
        }
    }, [notificationSettings]);

    // Notification Columns
    const columns = [
        {
            title: 'Category',
            key: 'category',
            width: 600,
            render: (record: TableDataProps) => {
                return (
                    <>
                        <TypographyText strong>
                            {record.log_name}
                        </TypographyText>
                        <div>{record.message_name}</div>
                    </>
                );
            },
        },
        {
            title: (
                <TypographyText type="danger">Critical events</TypographyText>
            ),
            key: 'critical_events',
            render: (record: TableDataProps) => (
                <EmailNotificationsAction
                    cluster={cluster}
                    record={record}
                    type="CRITICAL"
                    selectedValue={record.CRITICAL}
                    notifications={notificationSettings?.notifications}
                    onSuccess={notificationRefresh}
                />
            ),
        },
        {
            title: (
                <TypographyText type="warning">Warning events</TypographyText>
            ),
            key: 'warning_events',
            render: (record: TableDataProps) => (
                <EmailNotificationsAction
                    cluster={cluster}
                    record={record}
                    type="WARNING"
                    selectedValue={record.WARNING}
                    notifications={notificationSettings?.notifications}
                    onSuccess={notificationRefresh}
                />
            ),
        },
        {
            title: 'Info events',
            key: 'info_events',
            render: (record: TableDataProps) => {
                return (
                    <EmailNotificationsAction
                        cluster={cluster}
                        record={record}
                        type="INFO"
                        selectedValue={record.INFO}
                        notifications={notificationSettings?.notifications}
                        onSuccess={notificationRefresh}
                    />
                );
            },
        },
    ];

    const handleUserEnabled = async () => {
        try {
            await recipientsRefresh({});
            await notificationRefresh({});
        } catch (error) {
            notifyError({ content: error.message });
        }
    };
    const handleUserDisabled = async () => {
        try {
            await usersRefresh();
            await recipientsRefresh({});
            await notificationRefresh({ email: '' });
            form.resetFields(['emailLimit', 'digest']);
        } catch (error) {
            notifyError({ content: error.message });
        }
    };

    const handleRecipientRemoved = async () => {
        try {
            await usersRefresh();
            await recipientsRefresh({});
            await notificationRefresh({ email: '' });
            form.resetFields(['emailLimit', 'digest']);
            form.resetFields(['allUsers']);
            setUserValue('');
            setTableData([]);
            setSelectedUser(undefined);
        } catch (error) {
            notifyError({ content: error.message });
        }
    };

    const onExternalSuccess = async (email: string) => {
        await recipientsRefresh({});
        await notificationRefresh({ email });
        setGroupValue('external');
        getUsersOptions('external');
        setUserValue(email);
        form.setFieldsValue({
            allUsers: email,
            groups: 'external',
        });
    };

    const actionButton =
        selectedUser &&
        (selectedUser.groupName === 'external' ? (
            <EmailNotificationsRemoveRecipientButton
                userEmail={selectedUser}
                cluster={cluster}
                onRemove={handleRecipientRemoved}
            />
        ) : selectedUser.active ? (
            <EmailNotificationsRemoveRecipientButton
                userEmail={selectedUser}
                cluster={cluster}
                onRemove={handleUserDisabled}
                confirmTitle={`Are you sure you want to disable notifications for ${selectedUser?.emailAddress}?`}
            >
                Disable
            </EmailNotificationsRemoveRecipientButton>
        ) : (
            <EmailNotificationsAddRecipientButton
                userEmail={selectedUser}
                cluster={cluster}
                onAdd={handleUserEnabled}
            />
        ));

    return (
        <AppSpin
            tall={true}
            spinning={
                loadingRecipients ||
                loadingNotificationSettings ||
                usersLoading ||
                loadingGroupAcl
            }
            className="EmailNotifications"
        >
            <Form form={form} layout="vertical">
                <Row justify="space-between">
                    <h1>Email recipients</h1>

                    {canManageUsers() && canGroupAccessCluster() && (
                        <ButtonWithForm
                            button={
                                <Button type="primary" icon={<PlusOutlined />}>
                                    Add an external email
                                </Button>
                            }
                            form={
                                <ExternalEmailModal
                                    cluster={cluster}
                                    onSuccess={onExternalSuccess}
                                    groupList={groupsList}
                                />
                            }
                        />
                    )}
                </Row>

                {canManageUsers() && (
                    <Row gutter={[24, 0]} align="middle">
                        <Col span={8}>
                            <Form.Item name="groups" label="User group">
                                <Select
                                    placeholder="Click here to select"
                                    options={groupOptions}
                                    onChange={handleGroupChange}
                                    value={groupValue}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                name="allUsers"
                                label="Users in the selected group"
                                shouldUpdate={true}
                            >
                                <Select
                                    placeholder="Click here to select"
                                    options={userOptions}
                                    value={userValue}
                                    onChange={(value, option) =>
                                        onUserChange(value)
                                    }
                                    disabled={!canGroupAccessCluster()}
                                />
                            </Form.Item>
                        </Col>
                        {actionButton && <Col flex="auto">{actionButton}</Col>}
                    </Row>
                )}

                {!canGroupAccessCluster() && (
                    <Fragment>
                        <Alert
                            message={
                                <Space>
                                    <span>
                                        Selected team has no access to this
                                        cluster.
                                    </span>
                                    <span>
                                        Change permisions in{' '}
                                        <AppLink to={`/user-management/teams`}>
                                            user management
                                        </AppLink>{' '}
                                        section.
                                    </span>
                                </Space>
                            }
                            type="info"
                            showIcon={true}
                        />
                        <br />
                    </Fragment>
                )}
                {!canManageUsers() && (
                    <Row
                        gutter={[24, 0]}
                        align="middle"
                        className="EmailNotifications_CurrentUserInfo"
                    >
                        <Col flex="auto">
                            <Alert
                                message={
                                    <span>
                                        Notification e-mail will be sent to:{' '}
                                        <TypographyText strong={true}>
                                            {selectedUser?.emailAddress}
                                        </TypographyText>
                                        . You can change this e-mail address in
                                        profile{' '}
                                        <AppLink
                                            target={'_blank'}
                                            to="/settings/profile"
                                        >
                                            settings
                                        </AppLink>
                                        .
                                    </span>
                                }
                                type="info"
                                showIcon={true}
                            />
                        </Col>
                        {actionButton && <Col flex="auto">{actionButton}</Col>}
                    </Row>
                )}
                <Row gutter={[24, 0]} align="middle">
                    <EmailNotificationDeliveryTime
                        cluster={cluster}
                        emailAddress={userValue || ''}
                        notifications={notificationSettings?.notifications}
                        onSuccess={notificationRefresh}
                        disable={
                            !userValue ||
                            !selectedUser?.active ||
                            loadingNotificationSettings
                        }
                    />
                    <EmailNotificationDailyLimit
                        cluster={cluster}
                        emailAddress={userValue || ''}
                        notifications={notificationSettings?.notifications}
                        onSuccess={notificationRefresh}
                        disable={
                            !userValue ||
                            !selectedUser?.active ||
                            loadingNotificationSettings
                        }
                    />
                </Row>
            </Form>
            <AppTable
                className="EmailNotifications_Table"
                loading={false}
                rowKey={(record: any) => record.id}
                dataSource={tableData && userValue ? tableData : []}
                columns={columns}
                responsive={responsive}
                pagination={{
                    pageSize: tableData.length,
                    hideOnSinglePage: true,
                }}
                onRow={() => ({})}
                renderEmpty={
                    <AppEmpty
                        loading={loadingNotificationSettings}
                        description={
                            tableData && userValue ? (
                                <Space direction="vertical">
                                    There is no data to show at the moment,
                                    because the email your choose is already
                                    disabled.
                                    <Space>
                                        To see any data, please, enable the
                                        selected email.
                                    </Space>
                                </Space>
                            ) : (
                                <Space direction="vertical">
                                    There is no data to show at the moment.
                                    <Space>
                                        To see any data, please, select a group
                                        and a user.
                                    </Space>
                                </Space>
                            )
                        }
                        extra={
                            !selectedUser?.active &&
                            selectedUser && (
                                <EmailNotificationsAddRecipientButton
                                    userEmail={selectedUser}
                                    cluster={cluster}
                                    onAdd={handleUserEnabled}
                                />
                            )
                        }
                    />
                }
            />
        </AppSpin>
    );
}
