import React from 'react';
import { ButtonProps } from 'antd/lib/button';

import { CmonJobInstanceCommand } from '../../../services/cmon/models/CmonJobInstance';
import CcNode from '../../../services/models/CcNode';
import NodeFreezeForm from './NodeFreezeForm';
import ButtonWithForm from '../../../common/General/ButtonWithForm';
import JobActionButton from '../../Jobs/JobActionButton';

export default NodeFreezeButton;

export type NodeFreezeButtonProps = ButtonProps & {
    node: CcNode;
    children?: React.ReactNode;
    onSuccess?: () => void;
};

function NodeFreezeButton({
    node,
    children,
    onSuccess,
    ...rest
}: NodeFreezeButtonProps) {
    return (
        <ButtonWithForm
            button={
                <JobActionButton
                    command={CmonJobInstanceCommand.REPLSET_FREEZE}
                    clusterId={node.clusterid}
                    extraKey={[node.hostname, node.port]}
                    {...rest}
                >
                    {children || 'Freeze node'}
                </JobActionButton>
            }
            form={<NodeFreezeForm node={node} onSuccess={onSuccess} />}
        />
    );
}
