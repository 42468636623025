
import CmonRequestService, {CmonRequestServiceResponseData} from './CmonRequestService';


export interface AddMaintenanceRequestData {
    deadline?: string;
    hostname?: string;
    initiate?: string;
    reason?: string;
    request_created?: string;
    request_id?: number;
    [key: string]: any;
}

export interface RemoveMaintenanceRequestData {
    UUID?: string;
    request_created?: string;
    request_id?: number;
    [key: string]: any;
}

export interface GetMaintenanceRequestData {
    request_created?: string;
    request_id?: number;
    [key: string]: any;
}

export interface GetCurrentMaintenanceRequestData {
    cluster_id?: number;
    hostname?: string;
    request_created?: string;
    request_id?: number;
    [key: string]: any;
}

export interface GetNextMaintenanceRequestData {
    cluster_id?: number;
    hostname?: string;
    request_created?: string;
    request_id?: number;
    [key: string]: any;
}


export default class CmonMaintenanceService extends CmonRequestService {
    
    public static module(): string {
        return 'maintenance';
    }
    
    public static async addMaintenance(
        data: AddMaintenanceRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('addMaintenance', data, opts);
    }

    public static async removeMaintenance(
        data: RemoveMaintenanceRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('removeMaintenance', data, opts);
    }

    public static async getMaintenance(
        data: GetMaintenanceRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('getMaintenance', data, opts);
    }

    public static async getCurrentMaintenance(
        data: GetCurrentMaintenanceRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('getCurrentMaintenance', data, opts);
    }

    public static async getNextMaintenance(
        data: GetNextMaintenanceRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('getNextMaintenance', data, opts);
    }

}
