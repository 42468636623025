import React, { useEffect, useState } from 'react';
import useJobWatcher from '../Jobs/useJobWatcher';
import { getNodeRelatedJobs } from '../../services/models/CcJob';
import CcNode from '../../services/models/CcNode';
import AppSpin from '../../common/General/AppSpin';
import SpaceWide from '../../common/SpaceWide';
import { Space } from 'antd';
import ActivityCenterJobsLink from '../ActivityCenter/ActivityCenterJobsLink';

export default NodeRunningJobsFormat;

type NodeRunningJobsFormatProps = {
    node: CcNode;
    showList?: boolean;
    wrapper?: (children?: React.ReactNode) => JSX.Element;
};

function NodeRunningJobsFormat({
    node,
    showList,
    wrapper,
    ...rest
}: NodeRunningJobsFormatProps) {
    const {
        loading: nodeJobsLoading,
        runningJobs: nodeRunningJobs,
    } = useJobWatcher({
        clusterId: node.clusterid,
        command: getNodeRelatedJobs(),
        extraKey: [node.hostname, node.port],
    });
    const [loading, setLoading] = useState<boolean>(false);
    const [nodeUpdated, setNodeUpdated] = useState<boolean>(false);

    useEffect(() => {
        if (nodeJobsLoading) {
            // we set loading when jobs related to node are in progress but we
            // need the following useEffect (@ref1) to set loading to false
            setLoading(true);
        } else {
            if (nodeUpdated) {
                setLoading(false);
            }
        }
    }, [nodeJobsLoading]);

    useEffect(() => {
        // @ref1 this is bc there is a gap when the job stops running and the
        // node data is retrieved with updated status
        if (node) {
            if (!nodeJobsLoading) {
                setLoading(false);
            } else {
                setNodeUpdated(true);
            }
        }
    }, [node]);

    const content =
        showList && loading ? (
            <SpaceWide direction={'vertical'}>
                {nodeRunningJobs.map((job) => (
                    <SpaceWide
                        key={job.jobId}
                        justify={'space-between'}
                        style={{ width: '100%' }}
                    >
                        <Space>
                            <AppSpin spinning={true} size={'small'} />
                            <span>{job.title}</span>
                        </Space>
                        <ActivityCenterJobsLink />
                    </SpaceWide>
                ))}
            </SpaceWide>
        ) : loading ? (
            <AppSpin spinning={true} size={'small'} />
        ) : null;
    return content && wrapper ? wrapper(content) : content;
}
