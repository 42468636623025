import React, { useRef } from 'react';

import cluster from './dataMocks/clusters/mysqlReplication';
import { useDispatch } from 'react-redux';
import { addNewRunningJob, setRunningJobs } from '../appReducer';
import CcJob, { CcJobStatus } from '../services/models/CcJob';
import { CmonJobInstanceCommand } from '../services/cmon/models/CmonJobInstance';
import { Button, Divider, Space } from 'antd';
import JobProgress from '../components/Jobs/JobProgress';
import { FileTextOutlined, StopOutlined } from '@ant-design/icons';
import CcCluster from '../services/models/CcCluster';
import AppEmpty from '../common/Feedback/AppEmpty';
import TypographyText from '../common/TypographyText';
import ButtonWithForm from '../common/General/ButtonWithForm';
import PrometheusEnableModal from '../components/Prometheus/PrometheusEnableModal';
import ClusterEnablePrometheusButton from '../components/Clusters/Actions/ClusterEnablePrometheusButton';
import SpaceWide from '../common/SpaceWide';
import Immutable from 'immutable';

export default DebugMonitorVariable;

function DebugMonitorVariable() {
    const processMarker = useRef<number>();
    const timer = useRef<any>();
    const dispatch = useDispatch();
    const resetJobUpdateProcess = () => {
        processMarker.current = Date.now();
        clearTimeout(timer.current);
    };
    return (
        <div>
            <Button
                onClick={() => {
                    resetJobUpdateProcess();
                    dispatch(
                        addNewRunningJob(
                            new CcJob({
                                title: 'Enable anget-based monitoring',
                                created: new Date().toISOString(),
                                cluster_id: cluster.cluster_id,
                                job_spec: JSON.stringify({
                                    command:
                                        CmonJobInstanceCommand.DEPLOY_AGENTS,
                                }),
                                status: CcJobStatus.RUNNING,
                                has_progress: true,
                                progress_percent: 0,
                            })
                        )
                    );
                }}
            >
                simulate installation progress
            </Button>
            <Button
                onClick={() => {
                    resetJobUpdateProcess();
                    dispatch(
                        addNewRunningJob(
                            new CcJob({
                                title: 'Enable anget-based monitoring',
                                created: new Date().toISOString(),
                                cluster_id: cluster.cluster_id,
                                job_spec: JSON.stringify({
                                    command:
                                        CmonJobInstanceCommand.DEPLOY_AGENTS,
                                }),
                                status: CcJobStatus.RUNNING,
                                has_progress: true,
                                progress_percent: 50,
                            })
                        )
                    );
                }}
            >
                50%
            </Button>
            <Button
                onClick={() => {
                    resetJobUpdateProcess();
                    dispatch(
                        addNewRunningJob(
                            new CcJob({
                                title: 'Enable anget-based monitoring',
                                created: new Date().toISOString(),
                                cluster_id: cluster.cluster_id,
                                job_spec: JSON.stringify({
                                    command:
                                        CmonJobInstanceCommand.DEPLOY_AGENTS,
                                }),
                                status: CcJobStatus.RUNNING,
                                has_progress: true,
                                progress_percent: 100,
                            })
                        )
                    );
                }}
            >
                100% and not finished
            </Button>
            <Button
                onClick={() => {
                    resetJobUpdateProcess();
                    dispatch(
                        addNewRunningJob(
                            new CcJob({
                                title: 'Enable anget-based monitoring',
                                created: new Date().toISOString(),
                                cluster_id: cluster.cluster_id,
                                job_spec: JSON.stringify({
                                    command:
                                        CmonJobInstanceCommand.DEPLOY_AGENTS,
                                }),
                                status: CcJobStatus.FINISHED,
                                has_progress: true,
                                progress_percent: 100,
                            })
                        )
                    );
                }}
            >
                status === finished
            </Button>
            <Button
                onClick={() => {
                    resetJobUpdateProcess();
                    dispatch(
                        addNewRunningJob(
                            new CcJob({
                                title: 'Enable anget-based monitoring',
                                created: new Date().toISOString(),
                                cluster_id: cluster.cluster_id,
                                job_spec: JSON.stringify({
                                    command:
                                        CmonJobInstanceCommand.DEPLOY_AGENTS,
                                }),
                                status: CcJobStatus.FAILED,
                                has_progress: true,
                                progress_percent: 80,
                            })
                        )
                    );
                }}
            >
                status === failed
            </Button>
            <Button
                onClick={() => {
                    resetJobUpdateProcess();
                    dispatch(
                        addNewRunningJob(
                            new CcJob({
                                created: new Date().toISOString(),
                                cluster_id: cluster.cluster_id,
                                job_spec: JSON.stringify({
                                    command:
                                        CmonJobInstanceCommand.DEPLOY_AGENTS,
                                }),
                                status: CcJobStatus.RUNNING,
                                has_progress: false,
                            })
                        )
                    );
                }}
            >
                job with no progress
            </Button>
            <Button
                onClick={async () => {
                    const now = Date.now();
                    processMarker.current = now;
                    const getJob = () =>
                        new CcJob({
                            job_id: 111,
                            created: new Date().toISOString(),
                            cluster_id: cluster.cluster_id,
                            job_spec: JSON.stringify({
                                command: CmonJobInstanceCommand.DEPLOY_AGENTS,
                            }),
                            status: CcJobStatus.RUNNING,
                            has_progress: false,
                        });

                    const setJobs = () => {
                        const job = getJob();
                        dispatch(
                            setRunningJobs(
                                Immutable.Map(
                                    [job].map((c: CcJob) => [
                                        c.getCommandClusterKey(),
                                        c,
                                    ])
                                )
                            )
                        );
                    };
                    dispatch(addNewRunningJob(getJob()));

                    for (let i = 0; i < 20; i++) {
                        if (processMarker.current !== now) {
                            return;
                        }
                        await new Promise(
                            (resolve) =>
                                (timer.current = setTimeout(() => {
                                    setJobs();
                                    resolve(undefined);
                                }, 1000))
                        );
                    }
                    if (processMarker.current !== now) {
                        return;
                    }
                    await new Promise(
                        (resolve) =>
                            (timer.current = setTimeout(() => {
                                const job = new CcJob({
                                    job_id: 111,
                                    created: new Date().toISOString(),
                                    cluster_id: cluster.cluster_id,
                                    job_spec: JSON.stringify({
                                        command:
                                            CmonJobInstanceCommand.DEPLOY_AGENTS,
                                    }),
                                    status: CcJobStatus.FINISHED,
                                    has_progress: false,
                                });
                                dispatch(
                                    setRunningJobs(
                                        Immutable.Map(
                                            [job].map((c: CcJob) => [
                                                c.getCommandClusterKey(),
                                                c,
                                            ])
                                        )
                                    )
                                );
                                resolve(undefined);
                            }, 1000))
                    );
                }}
            >
                job with faking progress
            </Button>
            <Divider>Default</Divider>

            <JobProgress
                command={CmonJobInstanceCommand.DEPLOY_AGENTS}
                cluster={new CcCluster(cluster as any)}
                title="Enabling agent based monitoring"
                failedMessage="Enabling agent based monitoring has failed"
                successMessage="Enabling agent based monitoring has finished succesfully"
                extra={
                    <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://prometheus.io/docs/"
                    >
                        <FileTextOutlined /> Prometheus documentation
                    </a>
                }
                fakeProgress={true}
                fakePeriod={1}
                fallback={
                    <AppEmpty
                        loading={false}
                        title={
                            'Dashboards are disabled (this is fallback message of job progress)'
                        }
                        description={
                            <div>
                                <TypographyText muted>
                                    Enable the agent based monitoring in order
                                    to use the dashboards. A Prometheus server
                                    will be installed and used to store
                                    monitoring metrics. Special-purpose
                                    exporters agents will also be installed on
                                    each of the cluster nodes and will expose
                                    metrics that Prometheus scrapes/polls (using
                                    http) at a custom interval. for further
                                    information.
                                </TypographyText>
                                <br />
                                <br />
                                <a
                                    target="_blank"
                                    rel="noreferrer"
                                    href="https://prometheus.io/docs/"
                                >
                                    <FileTextOutlined /> Prometheus
                                    documentation
                                </a>
                            </div>
                        }
                        extra={
                            <ButtonWithForm
                                button={
                                    <Button type="primary">
                                        Enable agent based monitoring
                                    </Button>
                                }
                                form={
                                    <PrometheusEnableModal
                                        cluster={new CcCluster(cluster as any)}
                                    />
                                }
                            />
                        }
                    />
                }
            />
            <Divider>Small</Divider>
            <JobProgress
                size="small"
                command={CmonJobInstanceCommand.DEPLOY_AGENTS}
                cluster={new CcCluster(cluster as any)}
                fakeProgress={true}
                fakePeriod={1}
                fallback={
                    <SpaceWide direction="vertical" align="center">
                        <TypographyText muted>
                            <Space>
                                <StopOutlined />
                                <span>No data</span>
                            </Space>
                        </TypographyText>
                        <ClusterEnablePrometheusButton
                            cluster={new CcCluster(cluster as any)}
                        />
                    </SpaceWide>
                }
            />
        </div>
    );
}
