import { MonitorValueFormatType } from './MonitorValueFormat';
import { TimelineChartType } from '../../common/tmp/DataDisplay/TimelineChart';
import { MonitorType } from './MonitorBox';
import { StatusFormatStatus } from '@severalnines/bar-frontend-components/build/lib/Format/StatusFormat';
import { CcClusterType } from '../../services/models/CcCluster';

export enum MonitorDatasource {
    PROMETHEUS = 'prometheus',
    CLUSTERCONTROL = 'clustercontrol',
    CMONAGENT = 'cmonagent',
}
export enum MonitorDataType {
    GAUGE = 'gauge',
    COUNTER = 'counter',
}
export interface MonitorTarget {
    expr: string;
    legendFormat?: string;
    legendAsValue?: boolean;
    valueFormat?: string;
    color?: string;
    datasource?: MonitorDatasource;
}

export interface MonitorDashboardTemplatingItemConfig {
    allValue: any;
    datasource: MonitorDatasource.CLUSTERCONTROL | MonitorDatasource.PROMETHEUS;
    hide?: number;
    multi: boolean;
    multiFormat?: string;
    includeAll?: boolean;
    label: string;
    name: string;
    query: string;
    optionValue: string;
    optionText: string;
    sort: number;
    isInstancePicker?: boolean;
    allFormat?: string;
}

export interface MonitorDashboardPanelTargetConfig {
    expr: string;
    legendFormat?: string;
    color?: string;
}

export type MonitorDashboardYAxisConfig = {
    min?: number;
    max?: number;
    format?: MonitorValueFormatType;
    postfix?: string;
    decimals?: number;
};

export type MonitorDashboardPanelOptionsValueMapsItem = {
    op: '=' | '<' | '>' | '>=' | '<=';
    value: string;
    color?: string;
    text?: string;
    status?: StatusFormatStatus;
};

export type MonitorDashboardPanelOptionsConfig = {
    format?: MonitorValueFormatType;
    type?: TimelineChartType;
    stack?: boolean;
    yaxis?: MonitorDashboardYAxisConfig[];
    decimals?: number;
    postfix?: string;
    valueMaps?: MonitorDashboardPanelOptionsValueMapsItem[];
};

export interface MonitorDashboardPanelConfig {
    type: MonitorType;
    datasource: MonitorDatasource;
    title?: string;
    gridPos?: any;
    options: MonitorDashboardPanelOptionsConfig;
    targets: MonitorDashboardPanelTargetConfig[];
}

export interface MonitorDashboardConfig {
    show?: boolean;
    title?: string;
    meta?: { slug?: string; supportClusterTypes?: string[] | CcClusterType[] };
    panels: MonitorDashboardPanelConfig[];
    templating: {
        list: MonitorDashboardTemplatingItemConfig[];
    };
}
