


export interface CmonEventProps  {

}



export default class CmonEvent {


    constructor(props: CmonEventProps) {

    }

}
