export enum CcBackupStorageLocationType {
    HOST = 'host',
    CLOUD = 'cloud',
}

export interface CcBackupStorageLocationProps {
    type: CcBackupStorageLocationType;
    created_time: number;
    finished_time: number;
    being_deleted?: boolean;
    retention: number;
}

export default class CcBackupStorageLocation {
    public readonly type: CcBackupStorageLocationType;
    public readonly createdTime?: number;
    public readonly finishedTime?: number;
    public readonly beingDeleted?: boolean;
    public readonly retention?: number;

    protected constructor(props: CcBackupStorageLocationProps) {
        this.type = props.type;
        this.createdTime = props.created_time;
        this.finishedTime = props.finished_time;
        this.beingDeleted = props.being_deleted;
        this.retention = props.retention;
    }

    public isTypeCloud(): boolean {
        return this.type === CcBackupStorageLocationType.CLOUD;
    }

    public isTypeHost(): boolean {
        return this.type === CcBackupStorageLocationType.HOST;
    }

    public getPath(): string {
        throw new Error('getPath not implemented');
    }
    public getUuid(): string {
        throw new Error('getPath not implemented');
    }
}
