import React, { useEffect, useState } from 'react';
import { Select, SelectProps } from 'antd';
import CcCluster from '../../services/models/CcCluster';
import CcHost from '../../services/models/CcHost';
import { collectionKeyValueSort, sortByOrderedValues } from '../sorting';
import { arrayUnique } from '../filtering';
import { CcNodeType } from '../../services/models/CcNode';
import { getHostWithDesc } from '../../components/Nodes/NodeFormat';

export default ClusterHostInput;

export type ClusterHostInputProps = SelectProps<any> & {
    cluster: CcCluster;
    value?: string;
    onChange?: (value: string) => void;
};

function ClusterHostInput({
    cluster,
    value,
    onChange,
    ...rest
}: ClusterHostInputProps) {
    const [hosts, setHosts] = useState<CcHost[]>([]);

    useEffect(() => {
        const order = [CcNodeType.CONTROLLER];
        setHosts(
            sortByOrderedValues(
                cluster.getHosts(),
                (host) => {
                    return order.find((type) => host.hasNodeType(type));
                },
                order
            )
        );
    }, [cluster]);

    return (
        <Select
            className="ClusterHostInput"
            style={{ width: '100%' }}
            onChange={onChange}
            value={value}
            {...rest}
        >
            {hosts.map((host) => (
                <Select.Option value={host.hostname} key={host.hostname}>
                    {getHostWithDesc(host)}
                </Select.Option>
            ))}
        </Select>
    );
}
