import React from 'react';
import { FormInstance, FormItemProps } from 'antd/lib/form';
import CcCluster from '../../../services/models/CcCluster';
import { Form, Input, Select, Space } from 'antd';
import { getNodeHostWithDesc } from '../../Nodes/NodeFormat';

export default BackupHostSelectField;
export type BackupHostSelectFieldProps = FormItemProps & {
    form: FormInstance;
    cluster?: CcCluster;
};

function BackupHostSelectField({
    form,
    cluster,
    ...rest
}: BackupHostSelectFieldProps) {
    const onHostChange = (host: string) => {
        if (host === 'auto') {
            form.setFieldsValue({
                port: undefined,
            });
        } else {
            const node = cluster
                ?.getDatabaseNodes()
                .find((node) => node.hostname === host);
            form.setFieldsValue({
                port: node?.port,
            });
        }
    };
    return (
        <div>
            <Form.Item
                name="host"
                label={<Space>Backup host</Space>}
                rules={[
                    {
                        required: true,
                        message: 'Please select backup host.',
                    },
                ]}
                {...rest}
            >
                <Select
                    data-testid="configuration-host-select"
                    onChange={onHostChange}
                >
                    {cluster?.getDatabaseNodes().map((o) => (
                        <Select.Option
                            key={o.hostname}
                            value={o.hostname || ''}
                        >
                            {getNodeHostWithDesc(o)}
                        </Select.Option>
                    ))}
                    <Select.Option key="auto" value="auto">
                        Auto Select
                    </Select.Option>
                </Select>
            </Form.Item>
            <Form.Item name="port" hidden={true}>
                <Input type="hidden" />
            </Form.Item>
        </div>
    );
}
