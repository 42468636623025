import React, { useState } from 'react';
import { ButtonProps } from 'antd/lib/button';
import CcBackup from '../../../services/models/CcBackup';
import ActionButton from '@severalnines/bar-frontend-components/build/lib/General/ActionButton';
import ModalDefault from '../../../common/ModalDefault';
import JobLog from '../../Jobs/JobLog';
import { Button } from 'antd';
import AppPageWrapper from '../../../common/Layout/AppPageWrapper';

export type BackupLogsActionButtonProps = ButtonProps & {
    backup: CcBackup;
    onSuccess?: () => void;
};
const BackupLogsActionButton = ({
    children,
    backup,
    onSuccess,
    ...rest
}: BackupLogsActionButtonProps) => {
    const [visible, setVisible] = useState(false);
    const handleClick = () => {
        setVisible(true);
        if (onSuccess) {
            onSuccess();
        }
    };
    const handleCancel = () => {
        setVisible(false);
    };
    return (
        <>
            <ActionButton onClick={handleClick} {...rest}>
                {children || 'Logs'}
            </ActionButton>
            <ModalDefault
                title={<div>Backup {backup.getId()} Log</div>}
                visible={visible}
                onCancel={handleCancel}
                footer={[
                    <Button key="done" type="primary" onClick={handleCancel}>
                        Done
                    </Button>,
                ]}
            >
                <AppPageWrapper>
                    <JobLog
                        jobId={backup.getJobId()}
                        style={{ minHeight: '500px' }}
                    />
                </AppPageWrapper>
            </ModalDefault>
        </>
    );
};

export default BackupLogsActionButton;
