import './AddNodeWizard.less';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import CcCluster from '../../../../services/models/CcCluster';
import WizardConfiguration from '@severalnines/bar-frontend-components/build/lib/Navigation/Wizard/WizardConfiguration';
import WizardSelectCardStep from '@severalnines/bar-frontend-components/build/lib/Navigation/Wizard/WizardSelectCardStep';
import { DeploymentUnitOutlined, ImportOutlined } from '@ant-design/icons';
import { AddNodeFormWizardFields } from './AddNodeFormWizard';
import { Space } from 'antd';
import { getClusterTypeIcon } from '../../ClusterTypeFormat';

export default AddNodeWizard;

export enum AddNodeWizardStep {
    TYPE = 'TYPE',
    FORM_NEW = 'FORM_NEW',
    FORM_IMPORT = 'FORM_IMPORT',
}

export enum AddNodeWizardAction {
    NEW = 'NEW',
    IMPORT = 'IMPORT',
}

export type AddNodeWizardProps = {
    cluster: CcCluster;
    titleNew: string | React.ReactElement;
    titleImport: string | React.ReactElement;
    formCreateWizard: React.ReactElement;
    formImportWizard: React.ReactElement;
    activeStep?: AddNodeWizardStep;
    onStepChange?: (
        step: AddNodeWizardStep,
        action: AddNodeWizardAction | undefined
    ) => void;
    onSubmit?: (
        fields: AddNodeFormWizardFields,
        action: AddNodeWizardAction
    ) => void;
    onTouchedChange?: (touched: boolean) => void;
};

function AddNodeWizard({
    cluster,
    titleNew,
    titleImport,
    formCreateWizard,
    formImportWizard,
    activeStep = AddNodeWizardStep.TYPE,
    onStepChange,
    onSubmit,
    onTouchedChange,
}: AddNodeWizardProps) {
    const [step, setStep] = useState<AddNodeWizardStep>(activeStep);
    const actionType = useRef<AddNodeWizardAction | undefined>(
        activeStep === AddNodeWizardStep.FORM_NEW
            ? AddNodeWizardAction.NEW
            : AddNodeWizardAction.IMPORT
    );

    const handleActionSelect = (action: string) => {
        actionType.current = action as AddNodeWizardAction;
        setStep(
            action === AddNodeWizardAction.NEW
                ? AddNodeWizardStep.FORM_NEW
                : AddNodeWizardStep.FORM_IMPORT
        );
    };

    const handleCancel = () => {
        actionType.current = undefined;
        setStep(AddNodeWizardStep.TYPE);
    };

    useEffect(() => {
        if (step) {
            onStepChange?.(step, actionType.current);
        }
    }, [step]);

    const renderWizard = useCallback(
        (wizard: React.ReactElement) => {
            return React.cloneElement(wizard as React.ReactElement, {
                ...(wizard as React.ReactElement).props,
                onCancel: handleCancel,
                onSubmit,
                onTouchedChange,
            });
        },
        [formCreateWizard, formImportWizard, onSubmit]
    );

    return (
        <div className="AddNodeWizard">
            <WizardConfiguration
                activeStep={step}
                steps={[
                    {
                        step: AddNodeWizardStep.TYPE,
                        content: (
                            <WizardSelectCardStep
                                title={
                                    <Space>
                                        {getClusterTypeIcon(
                                            cluster.clusterType,
                                            cluster.vendor,
                                            { size: 50 }
                                        )}
                                        <span>{cluster.clusterName}</span>
                                    </Space>
                                }
                                description={`ID: ${cluster.clusterId} | Version: ${cluster.version}`}
                                onSelect={handleActionSelect}
                            >
                                <WizardSelectCardStep.Item
                                    icon={<DeploymentUnitOutlined />}
                                    title={titleNew}
                                    action={AddNodeWizardAction.NEW}
                                    size="small"
                                />
                                <WizardSelectCardStep.Item
                                    icon={<ImportOutlined />}
                                    title={titleImport}
                                    action={AddNodeWizardAction.IMPORT}
                                    size="small"
                                />
                            </WizardSelectCardStep>
                        ),
                    },
                    {
                        step: AddNodeWizardStep.FORM_NEW,
                        content: renderWizard(formCreateWizard),
                    },
                    {
                        step: AddNodeWizardStep.FORM_IMPORT,
                        content: renderWizard(formImportWizard),
                    },
                ]}
            />
        </div>
    );
}
interface JobDataNode {
    class_name?: string;
    hostname: string;
    hostname_internal?: string;
    port?: number;
    customlogfile?: string;
    synchronous?: boolean;
    pgname?: string;
    roles?: string;
    protocol?: string;
}

export interface AddNodeJobDataDTO {
    master_address?: string;
    master_delay?: number;
    node?: JobDataNode;
    nodes?: JobDataNode[];
    hostname?: string;
    port?: number;
    clusterId?: number;
    config_template?: string;
    datadir?: string;
    usePackageForDataDir?: boolean;
    install_software?: boolean;
    disable_firewall?: boolean;
    disable_selinux?: boolean;
    enable_uninstall?: boolean;
    mysql_semi_sync?: boolean;
    galera_segment?: number;
    update_lb?: boolean;
    backupid?: number;
    component?: string;
    replicaset?: string;
    node_type?: number;
}
