import CmonRequestService from './cmon/CmonRequestService';
import { ApiError } from './cmon/RequestService';
import { AppConfig } from '../AppConfig';

CmonRequestService.setAddr(AppConfig.CMON_API_URL);
CmonRequestService.setRequestCacheMs(20000);
CmonRequestService.setErrorHandlerFn((path, data, res, resData) => {
    if (path === '/auth/login') {
        throw new ApiError({
            message: `${resData.request_status}: ${resData.error_string}`,
            code: res.status,
        });
    } else {
        if (res.status === 401 || res.status === 403) {
            if (window.location.pathname !== '/login') {
                window.location.assign(
                    `/login?redirectTo=${encodeURI(window.location.href)}`
                );
            }
        } else if (res.status === 425) {
            // throw new ApiError({
            //     message: `${resData.request_status}: ${resData.error_string}`,
            //     code: res.status,
            //     type: CcApiErrorType.DuplicateObject,
            // });
        }
    }
});
