import { FormInstance } from 'antd/lib/form';

export function formatFields(form: FormInstance) {
    const { proxySQLConfiguration, proxySQLCredentials } = form.getFieldsValue(
        true
    );

    const { adminPort, listenPort, importConfig } = proxySQLConfiguration;
    const [address] = proxySQLConfiguration.address;

    if (proxySQLConfiguration?.proxySQLInstance !== undefined && importConfig) {
        const [proxySQLInstance] = proxySQLConfiguration.proxySQLInstance;
        const date = {
            action: 'registerProxySql',
            node: {
                hostname: address?.extraData.hostname,
                port: adminPort,
            },
            listening_port: listenPort,
            sync_with_instance: proxySQLInstance?.extraData.hostname,
        };
        return date;
    } else {
        const date = {
            action: 'registerProxySql',
            node: {
                hostname: address?.extraData.hostname,
                port: adminPort,
            },
            listening_port: listenPort,
            admin_user: proxySQLCredentials?.administrationUser,
            admin_password: proxySQLCredentials?.administrationPassword,
            monitor_user: proxySQLCredentials?.monitorUser,
            monitor_password: proxySQLCredentials?.monitorPassword,
        };
        return date;
    }
}
