
import CmonRequestService, {CmonRequestServiceResponseData} from './CmonRequestService';


export interface GetConfigRequestData {
    hostname?: string;
    request_created?: string;
    request_id?: number;
    [key: string]: any;
}

export interface GetConfigsRequestData {

    [key: string]: any;
}

export interface SetConfigRequestData {
    configuration?: [];
    hostname?: string;
    port?: number;
    request_created?: string;
    request_id?: number;
    [key: string]: any;
}

export interface UnsetConfigRequestData {
    configuration?: [];
    hostname?: string;
    port?: number;
    request_created?: string;
    request_id?: number;
    [key: string]: any;
}

export interface GetLdapConfigRequestData {
    request_created?: string;
    request_id?: number;
    [key: string]: any;
}

export interface SetLdapConfigRequestData {
    request_created?: string;
    request_id?: number;
    ldap_configuration?: {[key:string]:any};
    [key: string]: any;
}

export interface ListTemplatesRequestData {
    request_created?: string;
    request_id?: number;
    [key: string]: any;
}

export interface SetLicenseRequestData {
    request_created?: string;
    request_id?: number;
    licensedata?: string;
    [key: string]: any;
}

export interface GetLicenseRequestData {
    request_created?: string;
    request_id?: number;
    [key: string]: any;
}

export interface SetmailserverRequestData {
    smtp_server?: {[key:string]:any};
    [key: string]: any;
}

export interface GetmailserverRequestData {

    [key: string]: any;
}

export interface SetContentRequestData {
    content?: string;
    cluster_id?: number;
    hostname?: string;
    filename?: string;
    port?: number;
    request_created?: string;
    request_id?: number;
    [key: string]: any;
}

export interface AddintegrationRequestData {
    integration?: {[key:string]:any};
    [key: string]: any;
}

export interface UpdateintegrationRequestData {
    integration?: {[key:string]:any};
    [key: string]: any;
}

export interface RemoveintegrationRequestData {
    id?: number;
    [key: string]: any;
}

export interface ListintegrationsRequestData {

    [key: string]: any;
}

export interface GetcomponentmetaRequestData {

    [key: string]: any;
}

export interface GetrecipientsRequestData {
    cluster_id?: number;
    [key: string]: any;
}

export interface SetnotificationsettingsRequestData {
    cluster_id?: number;
    daily_limit?: number;
    digest_hour?: number;
    email?: string;
    settings?: {[key:string]:any};
    time_zone?: number;
    [key: string]: any;
}

export interface GetnotificationsettingsRequestData {
    email?: string;
    cluster_id?: number;
    [key: string]: any;
}

export interface RemoverecipientRequestData {
    email?: string;
    cluster_id?: number;
    [key: string]: any;
}


export default class CmonConfigService extends CmonRequestService {
    
    public static module(): string {
        return 'config';
    }
    
    public static async getConfig(
        data: GetConfigRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('getConfig', data, opts);
    }

    public static async getConfigs(
        data: GetConfigsRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('getConfigs', data, opts);
    }

    public static async setConfig(
        data: SetConfigRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('setConfig', data, opts);
    }

    public static async unsetConfig(
        data: UnsetConfigRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('unsetConfig', data, opts);
    }

    public static async getLdapConfig(
        data: GetLdapConfigRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('getLdapConfig', data, opts);
    }

    public static async setLdapConfig(
        data: SetLdapConfigRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('setLdapConfig', data, opts);
    }

    public static async listTemplates(
        data: ListTemplatesRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('listTemplates', data, opts);
    }

    public static async setLicense(
        data: SetLicenseRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('setLicense', data, opts);
    }

    public static async getLicense(
        data: GetLicenseRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('getLicense', data, opts);
    }

    public static async setmailserver(
        data: SetmailserverRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('setmailserver', data, opts);
    }

    public static async getmailserver(
        data: GetmailserverRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('getmailserver', data, opts);
    }

    public static async setContent(
        data: SetContentRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('setContent', data, opts);
    }

    public static async addintegration(
        data: AddintegrationRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('addintegration', data, opts);
    }

    public static async updateintegration(
        data: UpdateintegrationRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('updateintegration', data, opts);
    }

    public static async removeintegration(
        data: RemoveintegrationRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('removeintegration', data, opts);
    }

    public static async listintegrations(
        data: ListintegrationsRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('listintegrations', data, opts);
    }

    public static async getcomponentmeta(
        data: GetcomponentmetaRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('getcomponentmeta', data, opts);
    }

    public static async getrecipients(
        data: GetrecipientsRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('getrecipients', data, opts);
    }

    public static async setnotificationsettings(
        data: SetnotificationsettingsRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('setnotificationsettings', data, opts);
    }

    public static async getnotificationsettings(
        data: GetnotificationsettingsRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('getnotificationsettings', data, opts);
    }

    public static async removerecipient(
        data: RemoverecipientRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('removerecipient', data, opts);
    }

}
