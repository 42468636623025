import { FormInstance } from 'antd/lib/form';
import WizardForm from '@severalnines/bar-frontend-components/build/lib/Navigation/WizardForm';
import WizardFormConfiguration from '@severalnines/bar-frontend-components/build/lib/Navigation/Wizard/WizardFormConfiguration';
import NotificationServicesConfiguration from './NotificationServicesConfiguration';
import NotificationServicesSettings from './NotificationServicesSettings';
import { IntegrationType } from './NotificationServicesProviderFormat';

export default NotificationServicesWizardForm;

export type NotificationServicesWizardFormFields = {
    clusters: number[];
    events: string[];
    name: string;
    serviceKey: string;
    restUrl: string;
    token: string;
    channel: string;
    region: string;
    teams: string;
    apiKey: string;
    url: string;
    user: string;
    password: string;
    businessService: string;
    cmdbCi: string;
    instance: string;
    isActive: boolean;
};

export enum NotificationServicesWizardFormStep {
    CONFIGURATION = 'configuration',
    SETTINGS = 'settings',
}

export type NotificationServicesWizardFormProps = {
    form: FormInstance<NotificationServicesWizardFormFields>;
    loading?: boolean;
    saveMode?: boolean;
    defaultInitialValues?: NotificationServicesWizardFormFields;
    integrationType: IntegrationType;
    activeStep?: string;
    onCancel?: () => void;
    onSubmit?: (
        data: NotificationServicesWizardFormFields,
        type: IntegrationType
    ) => void;
    onTouchedChange?: (touched: boolean) => void;
};

function NotificationServicesWizardForm({
    form,
    loading,
    saveMode,
    defaultInitialValues,
    integrationType,
    activeStep,
    onCancel,
    onSubmit,
    onTouchedChange,
}: NotificationServicesWizardFormProps) {
    const handleSubmit = async () => {
        onSubmit?.(
            form.getFieldsValue(true) as NotificationServicesWizardFormFields,
            integrationType
        );
    };
    return (
        <WizardFormConfiguration
            form={form}
            onSubmit={handleSubmit}
            activeKey={activeStep}
            initialValues={defaultInitialValues}
            loading={loading}
            footerExtra={null}
            saveMode={saveMode}
            showCancelButton
            cancelButtonText={saveMode ? 'Cancel' : 'Back'}
            onCancel={onCancel}
            onTouchedChange={onTouchedChange}
            steps={[
                <WizardForm.Step
                    key={NotificationServicesWizardFormStep.CONFIGURATION}
                    title="Service configuration"
                    subTitle=" "
                    validate={[
                        'name',
                        'restUrl',
                        'token',
                        'channel',
                        'region',
                        'teams',
                        'apiKey',
                        'url',
                        'user',
                        'password',
                        'businessService',
                        'cmdbCi',
                        'instance',
                        'serviceKey',
                    ]}
                >
                    <NotificationServicesConfiguration
                        form={form}
                        integrationType={integrationType}
                    />
                </WizardForm.Step>,
                <WizardForm.Step
                    key={NotificationServicesWizardFormStep.SETTINGS}
                    title="Notification settings"
                    subTitle=" "
                    validate={['clusters', 'events']}
                >
                    <NotificationServicesSettings form={form} />
                </WizardForm.Step>,
            ]}
        />
    );
}
