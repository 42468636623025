import React, { useEffect, useMemo } from 'react';
import { Space } from 'antd';
import AppTable from '../../common/DataDisplay/AppTable';
import AppEmpty from '../../common/Feedback/AppEmpty';
import CmonImperativeService from '../../services/cmon/CmonImperativeService';
import useRunningOperation from './useRunningOperation';
import YesNoFormat from '../../common/Format/YesNoFormat';

export type RunningOperationPageProps = {
    cluster: CcCluster;
};

export default RunningOperationPage;

function RunningOperationPage({ cluster }: RunningOperationPageProps) {
    const { data, loading, refresh } = useRunningOperation({
        clusterId: cluster?.clusterId,
    });

    useEffect(() => {
        (async () => {
            await refresh();
        })();
    }, []);

    const columns = [
        {
            title: 'ID',
            key: 'opid',
            render: (record: any) => record?.opid,
        },
        {
            title: 'Client',
            key: 'client',
            render: (record: any) => record?.client,
        },
        {
            title: 'Server',
            key: 'reported_by',
            render: (record: any) => record?.reported_by,
        },
        {
            title: 'Active',
            key: 'active',
            render: (record: any) => (
                <YesNoFormat booleanVar={record?.active} />
            ),
        },
        {
            title: 'Exec Time',
            key: 'esecs_running',
            width: 100,
            render: (record: any) => record?.secs_running,
        },
        {
            title: 'Op',
            key: 'op',
            render: (record: any) => record?.op,
        },
        {
            title: 'NS',
            key: 'ns',
            render: (record: any) => record?.ns,
        },
        {
            title: 'Query',
            key: 'query',
            render: (record: any) => JSON.stringify(record?.query),
        },
        {
            title: 'Thread ID',
            key: 'threadId',
            width: 100,
            render: (record: any) => record?.threadId,
        },
        {
            title: 'Connection ID',
            key: 'connectionId',
            width: 120,
            render: (record: any) => record?.connectionId,
        },

        {
            title: 'Waiting (Lock)',
            key: 'waitingForLock',
            render: (record: any) => (
                <YesNoFormat booleanVar={record?.waitingForLock} />
            ),
        },
        {
            title: 'Acquiring',
            children: [
                {
                    title: '(r^w) us',
                    key: 'timeAcquiringMicrosWriteIS',
                    width: 100,
                    render: (record: any) =>
                        record?.timeAcquiringMicrosWriteIS &&
                        `${record?.timeAcquiringMicrosWriteIS}^${record?.timeAcquiringMicrosReadIS}`,
                },
                {
                    title: '(R^W) us',
                    key: 'timeAcquiringMicrosWriteIX',
                    width: 100,
                    render: (record: any) =>
                        record?.timeAcquiringMicrosWriteIX &&
                        `${record?.timeAcquiringMicrosWriteIX}^${record?.timeAcquiringMicrosReadIX}`,
                },
            ],
        },
        {
            title: 'Description',
            key: 'desc',
            render: (record: any) => record?.desc,
        },
    ];
    return (
        <AppTable
            loading={loading}
            dataSource={data || []}
            renderEmpty={
                <AppEmpty loading={loading} description="No records" />
            }
            onRow={() => {}}
            columns={columns}
            size="middle"
            footer={() => <Space size="large" />}
        />
    );
}
