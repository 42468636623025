import React from 'react';
import { useSelector } from 'react-redux';
import ModalDefaultForm from '../../../common/ModalDefaultForm';
import useForm from 'antd/lib/form/hooks/useForm';
import NotificationServicesConfiguration from './NotificationServicesConfiguration';
import NotificationServicesSettings from './NotificationServicesSettings';
import { Form } from 'antd';
import SpaceWide from '../../../common/SpaceWide';
import FormFooter from '../../../common/FormFooter';
import {
    notifyError,
    notifyOperationSuccess,
    NotifyType,
} from '../../Notifications/uiNotification';
import { getNotificationProviderName } from './NotificationServicesProviderFormat';
import { NotificationServicesWizardFormFields } from './NotificationServicesWizardForm';
import CmonConfigService from '../../../services/cmon/CmonConfigService';
import { useDebugContext } from '../../../common/Debug';
import { getConfig } from './NotificationServicesModalAdd';
import { AppState, AppStateClustersMap } from '../../../appReducer';
import { SELECT_ALL_CLUSTER_OPTION } from '../../Clusters/ClusterSelectDropdown';
import AppSpin from '../../../common/General/AppSpin';

export default NotificationServicesModalEdit;

export type NotificationServicesModalEditProps = {
    onCancel?: () => void;
    onSuccess?: () => void;
    record: any;
};

function NotificationServicesModalEdit({
    onCancel,
    onSuccess,
    record,
}: NotificationServicesModalEditProps) {
    const [form] = useForm<NotificationServicesWizardFormFields>();
    const { log } = useDebugContext();
    const [clustersMap]: [
        AppStateClustersMap
    ] = useSelector(({ clusters }: AppState) => [clusters]);

    const config = record.config;

    const clusterIds: number[] = [];
    config?.clusters.forEach((clusterId: number) => {
        if (clusterId) {
            if (clusterId === SELECT_ALL_CLUSTER_OPTION) {
                clusterIds.push(clusterId);
            } else {
                const cluster = clustersMap.get(clusterId.toString());
                if (cluster) {
                    clusterIds.push(clusterId);
                }
            }
        }
    });

    const handleCancel = () => {
        onCancel?.();
    };

    const handleSubmit = async (
        fields: NotificationServicesWizardFormFields
    ) => {
        try {
            const triggers = fields.events.map((event) => {
                const [trigger, severity] = event.split(',');
                return {
                    trigger,
                    severity,
                };
            });
            await CmonConfigService.updateintegration({
                integration: {
                    id: record.id,
                    name: fields.name,
                    service_id: record.service_id,
                    is_active: fields.isActive,
                    config: {
                        name: fields.name,
                        clusters: fields.clusters,
                        triggers,
                        ...getConfig(record.service_id, fields),
                    },
                },
            });
            notifyOperationSuccess({
                type: NotifyType.TOAST,
                title: 'Success!',
                content: 'Successfully updated',
            });
            onSuccess?.();
        } catch (e) {
            notifyError({ content: e.message });
            log.error(e);
        }
    };

    return (
        <ModalDefaultForm
            title={`Edit ${getNotificationProviderName(record?.service_id)}`}
            form={form}
            onCancel={handleCancel}
            defaultVisible={true}
            width={700}
        >
            <AppSpin spinning={false}>
                <Form
                    form={form}
                    onFinish={handleSubmit}
                    layout="vertical"
                    initialValues={{
                        name: config?.name,
                        serviceKey: config?.service_key,
                        restUrl: config?.rest_url,
                        token: config?.token,
                        channel: config?.channel,
                        region: config?.region,
                        teams: config?.teams,
                        apiKey: config?.api_key,
                        url: config?.url,
                        user: config?.user,
                        password: config?.password,
                        businessService: config?.business_service,
                        cmdbCi: config?.cmdb_ci,
                        instance: config?.instance,
                        clusters: clusterIds,
                        events: config?.triggers.map(
                            (trigger: any) =>
                                `${trigger.trigger},${trigger.severity}`
                        ),
                        isActive: record?.is_active,
                    }}
                >
                    <SpaceWide justify="space-between" direction="vertical">
                        <NotificationServicesConfiguration
                            form={form}
                            integrationType={record?.service_id}
                            editMode={true}
                        />
                        <NotificationServicesSettings form={form} />
                        <FormFooter
                            showSubmitButton
                            submitButtonText="Save"
                            showCancelButton
                            onCancel={handleCancel}
                        />
                    </SpaceWide>
                </Form>
            </AppSpin>
        </ModalDefaultForm>
    );
}
