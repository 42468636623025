import React from 'react';
import { ACLManageContent } from './useCurrentUser';
import { CcTreeItemHandledAclPath } from '../../services/models/CcTreeItem';

export default UserAclExecuteClusters;

export type UserAclExecuteClustersProps = {
    children?: React.ReactNode;
};

function UserAclExecuteClusters({ ...rest }: UserAclExecuteClustersProps) {
    return (
        <ACLManageContent
            path={CcTreeItemHandledAclPath.ACL_JOB_EXECUTOR_CREATE_CLUSTER}
            {...rest}
        />
    );
}
