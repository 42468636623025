import CmonElasticHost, {
    CmonElasticHostProps,
} from '../cmon/models/CmonElasticHost';

export enum CcElasticRole {
    MASTER = 'master',
    MASTER_DATA = 'master-data',
    DATA = 'data',
    MULTI_ROLE = 'multi-role',
    COORDINATOR = 'coordinator_only',
}

export interface CcElasticNodeProps extends CmonElasticHostProps {
    elastic_roles?: string;
}

export default class CcElasticNode extends CmonElasticHost {
    public readonly elasticRoles: CcElasticRole[];
    constructor(props: CcElasticNodeProps) {
        super(props);
        this.elasticRoles = (
            props.elastic_roles ? props.elastic_roles.split(',') : []
        ) as CcElasticRole[];
    }

    public getRole() {
        return this.elasticRoles.length > 1
            ? CcElasticRole.MULTI_ROLE
            : (this.elasticRoles[0] as CcElasticRole);
    }
}
