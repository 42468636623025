


export interface CmonAdviceProps  {
    advice?: string;
    created?: number;
    creator?: string;
    host?: string;
    justification?: string;
    severity?: string;
    title?: string;

}



export default class CmonAdvice {
    public advice?: string;
    public created?: number;
    public creator?: string;
    public host?: string;
    public justification?: string;
    public severity?: string;
    public title?: string;


    constructor(props: CmonAdviceProps) {
        this.advice = props.advice;
        this.created = props.created;
        this.creator = props.creator;
        this.host = props.host;
        this.justification = props.justification;
        this.severity = props.severity;
        this.title = props.title;

    }

}
