import './GroupInfo.less';
import React from 'react';
import { Space } from 'antd';
import CcGroup from '../../services/models/CcGroup';
import Title from 'antd/lib/typography/Title';

export default GroupInfo;
export type GroupInfoProps = { group: CcGroup; size?: 'small' | 'large' };

function GroupInfo({ group, size = 'small', ...rest }: GroupInfoProps) {
    const align = size === 'small' ? 'start' : 'center';
    return (
        <Space
            className="GroupInfo"
            align={align}
            direction={size === 'small' ? 'horizontal' : 'vertical'}
        >
            <Space
                direction="vertical"
                align={align}
                size={size === 'large' ? 20 : 5}
            >
                <Space direction="vertical" align={align} size={1}>
                    <Title
                        className="GroupInfo_name"
                        level={size === 'large' ? 2 : 5}
                    >
                        {group.groupName}
                    </Title>
                </Space>
            </Space>
        </Space>
    );
}
