import React, { useState } from 'react';
import useForm from 'antd/lib/form/hooks/useForm';
import ModalDefaultForm from '../../../common/ModalDefaultForm';
import NotificationServicesWizard from './NotificationServicesWizard';
import {
    IntegrationType,
    getNotificationProviderName,
} from './NotificationServicesProviderFormat';
import {
    notifyError,
    notifyOperationSuccess,
    NotifyType,
} from '../../Notifications/uiNotification';
import CmonConfigService from '../../../services/cmon/CmonConfigService';
import { useDebugContext } from '../../../common/Debug';
import { NotificationServicesWizardFormFields } from './NotificationServicesWizardForm';

export default NotificationServicesModalAdd;

export type NotificationServicesModalAddProps = {
    onCancel?: () => void;
    onSuccess?: () => void;
};

export function getConfig(
    integration: IntegrationType,
    {
        serviceKey,
        restUrl,
        token,
        channel,
        region,
        teams,
        apiKey,
        url,
        user,
        password,
        businessService,
        cmdbCi,
        instance,
    }: NotificationServicesWizardFormFields
) {
    switch (integration) {
        case IntegrationType.SLACK:
            return {
                url,
                channel,
            };
        case IntegrationType.TELEGRAM:
            return {
                token,
                channel,
            };
        case IntegrationType.SERVICE_NOW:
            return {
                user,
                password,
                business_service: businessService,
                cmdb_ci: cmdbCi,
                instance,
            };
        case IntegrationType.PAGER_DUTY:
            return {
                service_key: serviceKey,
            };
        case IntegrationType.VICTOR_OPS:
            return {
                rest_url: restUrl,
            };
        case IntegrationType.OPS_GENIE:
            return {
                region,
                teams,
                api_key: apiKey,
            };
        case IntegrationType.WEB_HOOK:
            return {
                url,
            };
    }
}

function NotificationServicesModalAdd({
    onCancel,
    onSuccess,
}: NotificationServicesModalAddProps) {
    const [interfaceTypeName, setInterfaceTypeName] = useState<string>('');
    const [form] = useForm();
    const [loading, setLoading] = useState<boolean>(false);
    const { log } = useDebugContext();

    const handleCancel = () => {
        onCancel?.();
    };

    const handleIntegrationTypeChange = (type: IntegrationType) => {
        setInterfaceTypeName(getNotificationProviderName(type));
    };

    const handleSubmit = async (
        fields: NotificationServicesWizardFormFields,
        integrationType: IntegrationType
    ) => {
        const triggers = fields.events.map((event) => {
            const evnt = event.split(',');
            return {
                trigger: evnt[0],
                severity: evnt[1],
            };
        });
        try {
            setLoading(true);
            await CmonConfigService.addintegration({
                is_active: true,
                integration: {
                    name: fields.name,
                    service_id: integrationType,
                    config: {
                        name: fields.name,
                        clusters: fields.clusters,
                        triggers,
                        ...getConfig(integrationType, fields),
                    },
                },
            });
            setLoading(false);
            notifyOperationSuccess({
                type: NotifyType.TOAST,
                title: 'Success!',
                content: 'Successfully added',
            });
            onSuccess?.();
        } catch (e) {
            setLoading(false);
            notifyError({ content: e.message });
            log.error(e);
        }
    };

    return (
        <ModalDefaultForm
            title={`Link an external notification service${
                interfaceTypeName ? ':' : ''
            } ${interfaceTypeName}`}
            form={form}
            footer={[]}
            onCancel={handleCancel}
            bodyStyle={{ padding: 0 }}
            defaultVisible={true}
        >
            <NotificationServicesWizard
                form={form}
                onCancel={handleCancel}
                onSubmit={handleSubmit}
                onIntegrationTypeChange={handleIntegrationTypeChange}
            />
        </ModalDefaultForm>
    );
}
