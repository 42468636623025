


export interface CmonJobMessageProps  {
    created?: string;
    file_name?: string;
    job_id?: number;
    line_number?: number;
    message_id?: number;
    message_status?: string;
    message_text?: string;

}



export default class CmonJobMessage {
    public created?: string;
    public fileName?: string;
    public jobId?: number;
    public lineNumber?: number;
    public messageId?: number;
    public messageStatus?: string;
    public messageText?: string;


    constructor(props: CmonJobMessageProps) {
        this.created = props.created;
        this.fileName = props.file_name;
        this.jobId = props.job_id;
        this.lineNumber = props.line_number;
        this.messageId = props.message_id;
        this.messageStatus = props.message_status;
        this.messageText = props.message_text;

    }

}
