


export interface CmonTableOfContentsProps  {

}



export default class CmonTableOfContents {


    constructor(props: CmonTableOfContentsProps) {

    }

}
