import React from 'react';

import StatusFormat, {
    StatusFormatStatus,
    StatusFormatType,
} from '@severalnines/bar-frontend-components/build/lib/Format/StatusFormat';
import CcBackupSchedule from '../../services/models/CcBackupSchedule';
import { CcJobStatus } from '../../services/models/CcJob';
import {
    PlayCircleOutlined,
    InfoCircleOutlined,
    PauseCircleOutlined,
} from '@ant-design/icons';

export default BackupScheduleStatusFormat;

export function getBackupScheduleStatusFormatType(status?: CcJobStatus) {
    switch (status) {
        case CcJobStatus.SCHEDULED:
            return StatusFormatStatus.success;
        case CcJobStatus.PAUSED:
            return StatusFormatStatus.warning;
        default:
            return StatusFormatStatus.info;
    }
}

export function getBackupScheduleStatusFormatText(status?: CcJobStatus) {
    switch (status) {
        case CcJobStatus.SCHEDULED:
            return 'Active';
        case CcJobStatus.PAUSED:
            return 'Paused';
        default:
            return 'Unknown';
    }
}

function getBackupSchedulesStatusFormatIcon(
    status: CcJobStatus,
    iconProps: any = {}
) {
    switch (status) {
        case CcJobStatus.SCHEDULED:
            return <PlayCircleOutlined {...iconProps} />;
        case CcJobStatus.PAUSED:
            return <PauseCircleOutlined {...iconProps} />;
        default:
            return <InfoCircleOutlined {...iconProps} />;
    }
}

export type BackupScheduleStatusFormatProps = {
    schedule: CcBackupSchedule;
    type?: StatusFormatType;
};

function BackupScheduleStatusFormat({
    schedule,
    type,
}: BackupScheduleStatusFormatProps) {
    return (
        <StatusFormat
            className="BackupScheduleStatusFormat"
            status={getBackupScheduleStatusFormatType(schedule.status)}
            text={getBackupScheduleStatusFormatText(schedule.status)}
            type={type}
            icon={getBackupSchedulesStatusFormatIcon(schedule.status, {
                style: { fontSize: '20px' },
            })}
            nowrap={true}
        />
    );
}
