import React from 'react';

export default PageWithJsError;

/**
 * @name PageWithJsError
 * @desc PageWithJsError component.
 */
function PageWithJsError() {
    return (
        <div>
            {/* @ts-ignore */}
            {asdf}
        </div>
    );
}
