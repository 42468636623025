const dashboard = {
    title: 'HAProxy Overview',
    meta: {
        slug: 'haproxy-overview',
        supportClusterTypes: [
            'replication',
            'galera',
            'mysql_single',
            'postgresql_single',
        ],
    },
    panels: [
        {
            title: 'Active Backends',
            type: 'singlestat',
            gridPos: {
                h: 4,
                w: 6,
                x: 0,
                y: 0,
            },
            options: {
                decimals: 0,
                format: 'short',
                type: 'area',
                legend: {
                    alignAsTable: true,
                },
                valueMaps: [
                    {
                        op: '=',
                        value: '0',
                        color: '#DE3C4B',
                    },
                ],
            },
            targets: [
                {
                    expr:
                        'count(haproxy_backend_up{backend=~".*",instance=~"$instance"} == 1)',
                },
            ],
        },
        {
            title: 'Inactive Backends',
            type: 'singlestat',
            gridPos: {
                h: 4,
                w: 6,
                x: 0,
                y: 0,
            },
            options: {
                decimals: 0,
                format: 'short',
                type: 'area',
                legend: {
                    alignAsTable: true,
                },
                valueMaps: [
                    {
                        op: '>',
                        value: '0',
                        color: '#DE3C4B',
                    },
                ],
            },
            targets: [
                {
                    expr:
                        'count(haproxy_backend_up{instance=~"$instance"} == 0) or (count(haproxy_backend_up{instance=~"$instance"} == 1) - count(haproxy_backend_up{instance=~"$instance"} == 1))',
                },
            ],
        },
        {
            title: 'Backend Servers Up',
            type: 'singlestat',
            gridPos: {
                h: 4,
                w: 6,
                x: 0,
                y: 0,
            },
            options: {
                decimals: 0,
                format: 'short',
                type: 'area',
                legend: {
                    alignAsTable: true,
                },
                valueMaps: [
                    {
                        op: '=',
                        value: '0',
                        color: '#DE3C4B',
                    },
                ],
            },
            targets: [
                {
                    expr:
                        'count(haproxy_server_up{backend=~".*",instance=~"$instance"} == 1)',
                },
            ],
        },
        {
            title: 'Backend Servers Down',
            type: 'singlestat',
            gridPos: {
                h: 4,
                w: 6,
                x: 0,
                y: 0,
            },
            options: {
                decimals: 0,
                format: 'short',
                type: 'area',
                legend: {
                    alignAsTable: true,
                },
                valueMaps: [
                    {
                        op: '>',
                        value: '0',
                        color: '#DE3C4B',
                    },
                ],
            },
            targets: [
                {
                    expr:
                        'count(haproxy_server_up{instance=~"$instance"} == 0) or (count(haproxy_server_up{instance=~"$instance"} == 1) - count(haproxy_server_up{instance=~"$instance"} == 1))',
                },
            ],
        },
        {
            title: 'Backends Up / Down',
            type: 'chart',
            gridPos: {
                h: 6,
                w: 12,
                x: 0,
                y: 0,
            },
            options: {
                decimals: 0,
                format: 'short',
                type: 'area',
                legend: {
                    alignAsTable: true,
                },
                yaxis: [
                    {
                        format: 'short',
                        max: null,
                        min: null,
                    },
                    {
                        format: 'short',
                        max: null,
                        min: null,
                    },
                ],
                stack: false,
            },
            targets: [
                {
                    expr:
                        'count(haproxy_backend_up{instance=~"$instance"} == 1)',
                    legendFormat: 'Backends Up',
                },
                {
                    expr:
                        'count(haproxy_backend_up{instance=~"$instance"} == 0)',
                    legendFormat: 'Backends Down',
                    color: '#a51203',
                },
            ],
        },
        {
            title: 'Backends Servers Up / Down',
            type: 'chart',
            gridPos: {
                h: 6,
                w: 12,
                x: 0,
                y: 0,
            },
            options: {
                decimals: 0,
                format: 'short',
                type: 'area',
                legend: {
                    alignAsTable: true,
                },
                yaxis: [
                    {
                        format: 'short',
                        max: null,
                        min: null,
                    },
                    {
                        format: 'short',
                        max: null,
                        min: null,
                    },
                ],
                stack: false,
            },
            targets: [
                {
                    expr:
                        'count(haproxy_server_up{backend=~".*", instance=~"$instance"} == 1) or (count(haproxy_server_up{backend=~".*",instance=~"$instance"} == 0) - count(haproxy_server_up{backend=~".*",instance=~"$instance"} == 0))',
                    legendFormat: 'Backend Servers Up',
                },
                {
                    expr:
                        'count(haproxy_server_up{backend=~".*",instance=~"$instance"} == 0) or (count(haproxy_server_up{backend=~".*",instance=~"$instance"} == 1) - count(haproxy_server_up{backend=~".*",instance=~"$instance"} == 1))',
                    legendFormat: 'Backends Servers Down',
                    color: '#a51203',
                },
            ],
        },
        {
            title: 'Total Bytes In/Out - Backend and Frontend',
            type: 'chart',
            gridPos: {
                h: 10,
                w: 24,
                x: 0,
                y: 0,
            },
            options: {
                decimals: 1,
                format: 'short',
                type: 'area',
                legend: {
                    alignAsTable: true,
                },
                yaxis: [
                    {
                        format: 'size',
                        max: null,
                        min: null,
                    },
                    {
                        format: 'short',
                        max: null,
                        min: 0,
                    },
                ],
                stack: false,
            },
            targets: [
                {
                    expr:
                        'sum(irate(haproxy_frontend_bytes_in_total{instance=~"$instance"}[5m]))',
                    legendFormat: 'Frontend IN',
                },
                {
                    expr:
                        'sum(irate(haproxy_frontend_bytes_out_total{instance=~"$instance"}[5m]))',
                    legendFormat: 'Frontend OUT',
                },
                {
                    expr:
                        'sum(irate(haproxy_backend_bytes_in_total{instance=~"$instance"}[5m]))',
                    legendFormat: 'Backend IN',
                },
                {
                    expr:
                        'sum(irate(haproxy_backend_bytes_out_total{instance=~"$instance"}[5m]))',
                    legendFormat: 'Backend OUT',
                },
            ],
        },
        {
            title: 'Backends - Total Bytes In',
            type: 'chart',
            gridPos: {
                h: 10,
                w: 12,
                x: 0,
                y: 0,
            },
            options: {
                decimals: 1,
                format: 'short',
                type: 'area',
                legend: {
                    alignAsTable: true,
                },
                yaxis: [
                    {
                        format: 'size',
                        max: null,
                        min: null,
                    },
                    {
                        format: 'short',
                        max: null,
                        min: 0,
                    },
                ],
                stack: false,
            },
            targets: [
                {
                    expr:
                        'sum(irate(haproxy_server_bytes_in_total{instance=~"$instance"}[5m])) by (backend)',
                    legendFormat: '{{ backend }}',
                },
            ],
        },
        {
            title: 'Backends - Total Bytes Out',
            type: 'chart',
            gridPos: {
                h: 10,
                w: 12,
                x: 0,
                y: 0,
            },
            options: {
                decimals: 1,
                format: 'short',
                type: 'area',
                legend: {
                    alignAsTable: true,
                },
                yaxis: [
                    {
                        format: 'size',
                        max: null,
                        min: null,
                    },
                    {
                        format: 'short',
                        max: null,
                        min: 0,
                    },
                ],
                stack: false,
            },
            targets: [
                {
                    expr:
                        'sum(irate(haproxy_server_bytes_out_total{instance=~"$instance"}[5m])) by (backend)',
                    legendFormat: '{{ backend }}',
                },
            ],
        },
        {
            title: 'Backend Servers - Total Bytes In',
            type: 'chart',
            gridPos: {
                h: 10,
                w: 12,
                x: 0,
                y: 0,
            },
            options: {
                decimals: 1,
                format: 'short',
                type: 'area',
                legend: {
                    alignAsTable: true,
                },
                yaxis: [
                    {
                        format: 'size',
                        max: null,
                        min: null,
                    },
                    {
                        format: 'short',
                        max: null,
                        min: 0,
                    },
                ],
                stack: false,
            },
            targets: [
                {
                    expr:
                        'sum(irate(haproxy_server_bytes_in_total{instance=~"$instance"}[5m])) by (server)',
                    legendFormat: '{{ server }}',
                },
            ],
        },
        {
            title: 'Backend Servers - Total Bytes Out',
            type: 'chart',
            gridPos: {
                h: 10,
                w: 12,
                x: 0,
                y: 0,
            },
            options: {
                decimals: 1,
                format: 'short',
                type: 'area',
                legend: {
                    alignAsTable: true,
                },
                yaxis: [
                    {
                        format: 'size',
                        max: null,
                        min: null,
                    },
                    {
                        format: 'short',
                        max: null,
                        min: 0,
                    },
                ],
                stack: false,
            },
            targets: [
                {
                    expr:
                        'sum(irate(haproxy_server_bytes_out_total{instance=~"$instance"}[5m])) by (server)',
                    legendFormat: '{{ server }}',
                },
            ],
        },
        {
            title: 'Current Sessions - Backends',
            type: 'chart',
            gridPos: {
                h: 10,
                w: 12,
                x: 0,
                y: 0,
            },
            options: {
                format: 'short',
                type: 'area',
                legend: {
                    alignAsTable: true,
                },
                yaxis: [
                    {
                        format: 'short',
                        max: null,
                        min: null,
                    },
                    {
                        format: 'short',
                        max: null,
                        min: 0,
                    },
                ],
                stack: false,
            },
            targets: [
                {
                    expr:
                        'sum(haproxy_server_current_sessions{instance=~"$instance"}) by (backend)',
                    legendFormat: ' {{ backend }}',
                },
            ],
        },
        {
            title: 'Current Session Rate - Backends',
            type: 'chart',
            gridPos: {
                h: 10,
                w: 12,
                x: 0,
                y: 0,
            },
            options: {
                format: 'short',
                type: 'area',
                legend: {
                    alignAsTable: true,
                },
                yaxis: [
                    {
                        format: 'short',
                        max: null,
                        min: null,
                    },
                    {
                        format: 'short',
                        max: null,
                        min: 0,
                    },
                ],
                stack: false,
            },
            targets: [
                {
                    expr:
                        'sum(haproxy_server_current_session_rate{instance=~"$instance"}) by (backend)',
                    legendFormat: ' {{ backend }}',
                },
            ],
        },
        {
            title: 'Current Sessions - Backend Servers',
            type: 'chart',
            gridPos: {
                h: 10,
                w: 12,
                x: 0,
                y: 0,
            },
            options: {
                format: 'short',
                type: 'area',
                legend: {
                    alignAsTable: true,
                },
                yaxis: [
                    {
                        format: 'short',
                        max: null,
                        min: null,
                    },
                    {
                        format: 'short',
                        max: null,
                        min: 0,
                    },
                ],
                stack: false,
            },
            targets: [
                {
                    expr:
                        'sum(haproxy_server_current_sessions{instance=~"$instance"}) by (server)',
                    legendFormat: ' {{ server }}',
                },
            ],
        },
        {
            title: 'Current Session Rate - Backend Servers',
            type: 'chart',
            gridPos: {
                h: 10,
                w: 12,
                x: 0,
                y: 0,
            },
            options: {
                format: 'short',
                type: 'area',
                legend: {
                    alignAsTable: true,
                },
                yaxis: [
                    {
                        format: 'short',
                        max: null,
                        min: null,
                    },
                    {
                        format: 'short',
                        max: null,
                        min: 0,
                    },
                ],
                stack: false,
            },
            targets: [
                {
                    expr:
                        'sum(haproxy_server_current_session_rate{instance=~"$instance"}) by (server)',
                    legendFormat: ' {{ server }}',
                },
            ],
        },
        {
            title: 'Max Sessions - Backend Servers',
            type: 'chart',
            gridPos: {
                h: 10,
                w: 12,
                x: 0,
                y: 0,
            },
            options: {
                format: 'short',
                type: 'area',
                legend: {
                    alignAsTable: true,
                },
                yaxis: [
                    {
                        format: 'short',
                        max: null,
                        min: null,
                    },
                    {
                        format: 'short',
                        max: null,
                        min: 0,
                    },
                ],
                stack: false,
            },
            targets: [
                {
                    expr:
                        'sum(haproxy_server_max_sessions{instance=~"$instance"}) by (server)',
                    legendFormat: ' {{ server }}',
                },
            ],
        },
        /*
            {
                "title": "Total Connections - Backend and Frontend",
                "type": "chart",
                "gridPos": {
                    "h": 10,
                    "w": 12,
                    "x": 0,
                    "y": 0
                },
                "options": {
                    "format": "short",
                    "type": "area",
                    "legend": {
                        "alignAsTable": true
                    },
                    "yaxis": [
                        {
                            "format": "short",
                            "max": null,
                            "min": null
                        },
                        {
                            "format": "short",
                            "max": null,
                            "min": 0
                        }
                    ],
                    "stack": false
                },
                "targets": [
                    {
                        "expr": "irate(haproxy_frontend_connections_total{instance=~\"$instance\"}[5m])",
                        "legendFormat": "Frontend Connections"
                    },
                    {
                        "expr": "sum(irate(haproxy_backend_connections_total{instance=~\"$instance\"}[5m])) by (instance)",
                        "legendFormat": "Backend Connections"
                    },
                    {
                        "expr": "sum(irate(haproxy_backend_connection_errors_total{instance=~\"$instance\"}[5m])) by (instance)",
                        "legendFormat": "Backend Connection Errors"
                    }
                ]
            },
        */
        /*
                    {
                        "title": "Total requests / responses",
                        "type": "chart",
                        "gridPos": {
                            "h": 10,
                            "w": 12,
                            "x": 0,
                            "y": 0
                        },
                        "options": {
                            "format": "short",
                            "type": "area",
                            "legend": {
                                "alignAsTable": true
                            },
                            "yaxis": [
                                {
                                    "format": "short",
                                    "max": null,
                                    "min": null
                                },
                                {
                                    "format": "short",
                                    "max": null,
                                    "min": 0
                                }
                            ],
                            "stack": false
                        },
                        "targets": [
                            {
                                "expr": "sum(deriv(haproxy_backend_response_errors_total{backend=~\".*\",instance=~\"$instance\"}[5m])) by (instance)",
                                "legendFormat": "Response errors"
                            },
                            {
                                "expr": "sum(deriv(haproxy_frontend_request_errors_total{frontend=~\".*\",instance=~\"$instance\"}[5m])) by (instance)",
                                "legendFormat": "Requests errors"
                            },
                            {
                                "expr": "sum(deriv(haproxy_backend_redispatch_warnings_total{backend=~\".*\",instance=~\"$instance\"}[5m])) by (instance)",
                                "legendFormat": "Backend redispatch"
                            },
                            {
                                "expr": "sum(deriv(haproxy_backend_retry_warnings_total{backend=~\".*\",instance=~\"$instance\"}[5m])) by (instance)",
                                "legendFormat": "Backend retry"
                            },
                            {
                                "expr": "sum(deriv(haproxy_frontend_requests_denied_total{frontend=~\".*\",instance=~\"$instance\"}[5m])) by (instance)",
                                "legendFormat": "Request denied"
                            },
                            {
                                "expr": "sum(haproxy_backend_current_queue{backend=~\".*\",instance=~\"$instance\"}) by (instance)",
                                "legendFormat": "Backend Queued"
                            }
                        ]
                    },
            */
        {
            title: 'Sessions',
            type: 'chart',
            gridPos: {
                h: 10,
                w: 12,
                x: 0,
                y: 0,
            },
            options: {
                format: 'short',
                type: 'area',
                legend: {
                    alignAsTable: true,
                },
                yaxis: [
                    {
                        format: 'short',
                        max: null,
                        min: null,
                    },
                    {
                        format: 'short',
                        max: null,
                        min: 0,
                    },
                ],
                stack: false,
            },
            targets: [
                {
                    expr:
                        'sum(haproxy_frontend_current_sessions{frontend=~"$frontend",instance=~"$instance"}) by (instance)',
                    legendFormat: 'Frontend current sessions',
                },
                {
                    expr:
                        'sum(haproxy_frontend_current_session_rate{frontend=~"$frontend",instance=~"$instance"}) by (instance)',
                    legendFormat: 'Frontend current session rate',
                },
                {
                    expr:
                        'sum(haproxy_backend_current_sessions{backend=~".*",instance=~"$instance"}) by (instance)',
                    legendFormat: 'Backend current sessions',
                },
                {
                    expr:
                        'sum(haproxy_backend_current_session_rate{backend=~".*",instance=~"$instance"}) by (instance)',
                    legendFormat: 'Backend current session rate',
                },
            ],
        },
        {
            /*"title": "Backend - Total number of connections OK / Error",*/
            title: 'Backend - Total Number of Connection Errors',
            type: 'chart',
            gridPos: {
                h: 11,
                w: 12,
                x: 0,
                y: 0,
            },
            options: {
                format: 'short',
                type: 'area',
                legend: {
                    alignAsTable: true,
                },
                yaxis: [
                    {
                        format: 'short',
                        max: null,
                        min: null,
                    },
                    {
                        format: 'short',
                        max: null,
                        min: 0,
                    },
                ],
                stack: false,
            },
            targets: [
                /*                {
                                        "expr": "sum(irate(haproxy_backend_connections_total{instance=~\"$instance\"}[5m]))",
                                        "legendFormat": "Connections"
                                    },*/
                {
                    expr:
                        'sum(irate(haproxy_backend_connection_errors_total{instance=~"$instance"}[5m]))',
                    legendFormat: 'Connection Errors',
                },
            ],
        },
        /*
            {
                "title": "Frontend - Total number of connections",
                "type": "chart",
                "gridPos": {
                    "h": 11,
                    "w": 12,
                    "x": 0,
                    "y": 0
                },
                "options": {
                    "format": "short",
                    "type": "area",
                    "legend": {
                        "alignAsTable": true
                    },
                    "yaxis": [
                        {
                            "format": "short",
                            "max": null,
                            "min": 0
                        },
                        {
                            "format": "short",
                            "max": null,
                            "min": 0
                        }
                    ],
                    "stack": false
                },
                "targets": [
                    {
                        "expr": "irate(haproxy_frontend_connections_total{instance=~\"$instance\"}[5m])",
                        "legendFormat": "{{ frontend }}"
                    }
                ]
            },
        */
        {
            title: 'Back - Max Queued Requests (not assigned to any server)',
            type: 'chart',
            gridPos: {
                h: 10,
                w: 12,
                x: 0,
                y: 0,
            },
            options: {
                format: 'short',
                type: 'area',
                legend: {
                    alignAsTable: true,
                },
                yaxis: [
                    {
                        format: 'short',
                        max: null,
                        min: 0,
                    },
                    {
                        format: 'short',
                        max: null,
                        min: 0,
                    },
                ],
                stack: false,
            },
            targets: [
                {
                    expr: '(haproxy_backend_max_queue{instance=~"$instance"})',
                    legendFormat: '{{ backend }}',
                },
            ],
        },
        {
            title:
                'Backend - Current Queued Requests (not assigned to a server)',
            type: 'chart',
            gridPos: {
                h: 10,
                w: 12,
                x: 0,
                y: 0,
            },
            options: {
                format: 'short',
                type: 'area',
                legend: {
                    alignAsTable: true,
                },
                yaxis: [
                    {
                        format: 'short',
                        max: null,
                        min: 0,
                    },
                    {
                        format: 'short',
                        max: null,
                        min: 0,
                    },
                ],
                stack: false,
            },
            targets: [
                {
                    expr:
                        'haproxy_backend_current_queue{backend=~".*",instance=~"$instance"}',
                    legendFormat: '{{ backend }}',
                },
            ],
        },
    ],
    templating: {
        list: [
            {
                datasource: 'clustercontrol',
                multi: false,
                label: 'Host',
                name: 'instance',
                query: "host:$[nodes[nodetype='haproxy']]",
                optionValue: 'hostname',
                optionText: 'hostname',
                sort: 1,
                isInstancePicker: true,
            },
        ],
    },
    schemaVersion: 1,
};
export default dashboard;
