import React from 'react';
import { Col, Divider, Form, Row, Space } from 'antd';
import TopologyNodeDataIpField from '../common/Form/Fields/TopologyNodeDataIpField';
import { TopologyItem } from '../components/Topology/TopologyItem';
import { FormInstance } from 'antd/es';
import TypographyText from '../common/TypographyText';
import SshCheckableNodesInput, {
    SshCheckableNodesInputProps,
} from '../common/DataEntry/SshCheckeableNodesInput';

export default DebugSshCheckableNodesInput;

export type DebugSshCheckableNodesInputProps = {};

const commonProps: SshCheckableNodesInputProps = {
    sshKeyPath: 'keypath',
    sshUser: 'ubuntu',
    sshSudoPassword: 'password',
    clusterId: 3,
    mutateItem: (item: TopologyItem) => ({
        ...item,
        footerExpanded: true,
        footerExpandable: true,
        footer: (
            <div>
                <TopologyNodeDataIpField
                    name={['topologyDataIps', item.extraData.hostname]}
                    item={item}
                />
            </div>
        ),
    }),
    validateItem: async (item: TopologyItem) => {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                if (item.title === 'error') {
                    reject(new Error('An error adding the node'));
                } else {
                    resolve(item);
                }
            }, 1500);
        });
    },
    formProps: {
        secondaryTitleExtra: (
            <TypographyText muted={true}>(try 'error')</TypographyText>
        ),
    },
};

function DebugSshCheckableNodesInput({}: DebugSshCheckableNodesInputProps) {
    const [form1] = Form.useForm();
    const [form2] = Form.useForm();
    const [form3] = Form.useForm();
    const [form4] = Form.useForm();
    const samples: {
        title: string;
        form: FormInstance;
        props?: SshCheckableNodesInputProps;
    }[] = [
        {
            title: 'using (with footer, and validation)',
            form: form1,
            props: {
                ...commonProps,
            },
        },
        {
            title: 'using for only primaries with footer, and validation',
            form: form2,
            props: { ...commonProps, onlyPrimaries: true },
        },
        {
            title: 'using for singleNode with footer, and validation',
            form: form3,
            props: { ...commonProps, singleNode: true },
        },
        {
            title: 'using (defaults)',
            form: form4,
            props: {
                sshKeyPath: 'keypath',
                sshUser: 'ubuntu',
                sshSudoPassword: 'password',
                clusterId: 3,
            },
        },
    ];
    return (
        <div className="DebugSshCheckableNodesInput">
            <h1>SshCheckableNodesInput</h1>
            {samples.map(({ title, form, props }, index) => (
                <div key={index}>
                    <Divider plain={true} orientation="left">
                        {title}
                    </Divider>
                    <Form
                        layout="vertical"
                        form={form}
                        initialValues={{
                            topology: [],
                        }}
                    >
                        <Row>
                            <Col xs={24} sm={24} md={12}>
                                <Form.Item name="topology">
                                    <SshCheckableNodesInput {...props} />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12}>
                                <Form.Item shouldUpdate noStyle>
                                    {() => (
                                        <Space direction="vertical">
                                            <span>Form Result:</span>
                                            <pre style={{ fontSize: '10px' }}>
                                                {JSON.stringify(
                                                    form.getFieldsValue(true),
                                                    null,
                                                    4
                                                )}
                                            </pre>
                                        </Space>
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </div>
            ))}
        </div>
    );
}
