import React from 'react';
import CcCluster from '../services/models/CcCluster';
import SpaceDescriptions from '../common/Layout/SpaceDescriptions';

import { baseClustersList } from './dataMocks/clusters';
import { Space } from 'antd';
import ClusterActionsMenu from '../components/Clusters/Actions/ClusterActionsMenu';
import AppDivider from '../common/AppDivider';
import galera from './dataMocks/clusters/galera';
import postgresWithLb from './dataMocks/clusters/postgresWithLb';
import mongoShards from './dataMocks/clusters/mongoShards';
import CmonMaintenanceService from '../services/cmon/CmonMaintenanceService';
import { debugModal } from './serviceMock/ServiceMockContext';
import mongoReplicaSet from './dataMocks/clusters/mongoReplicaSet';

const clusters = baseClustersList
    .concat([mongoReplicaSet])
    .concat([mongoShards])
    .concat([
        {
            ...galera,
            cluster_name: 'Galera with auto recovery false',
            node_auto_recovery: false,
        },
    ])
    .concat([
        {
            ...galera,
            cluster_name: 'Galera with readonly true',
            node_auto_recovery: false,
            hosts: galera.hosts.map((h) =>
                h.nodetype === 'galera' ? { ...h, readonly: true } : h
            ) as any,
        },
    ])
    .concat([
        {
            ...postgresWithLb,
            cluster_name: 'Postgres with timescaledb',
            hosts: postgresWithLb.hosts.map((h) =>
                h.nodetype === 'postgres'
                    ? { ...h, extensions: [{ name: 'timescaledb' }] }
                    : h
            ) as any,
        },
    ])
    .map((c) => new CcCluster(c as any));

export default DebugClusterActionsMenu;

export type DebugClusterActionsMenuProps = {};

function DebugClusterActionsMenu({}: DebugClusterActionsMenuProps) {
    CmonMaintenanceService.addMaintenance = ((data: any, opts: any) => {
        return debugModal(data, opts, {});
    }) as any;
    return (
        <Space
            className="DebugClusterActionsMenu"
            direction="vertical"
            size={20}
        >
            {clusters.map((c) => {
                return (
                    <SpaceDescriptions
                        key={c.clusterName}
                        title={c.clusterName}
                        direction={'vertical'}
                    >
                        <ClusterActionsMenu key={c.clusterName} cluster={c} />
                        <AppDivider />
                    </SpaceDescriptions>
                );
            })}
        </Space>
    );
}
