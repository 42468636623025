import './AppSplitLayout.less';
import classNames from 'classnames';
import React, { HTMLAttributes } from 'react';

export default AppSplitLayout;
export type AppSplitLayoutProps = HTMLAttributes<HTMLDivElement> & {
    left?: React.ReactNode;
    right?: React.ReactNode;
    type?: 'tab-content';
    noPaddingLeft?: boolean;
    noPaddingRight?: boolean;
    noSplitBorder?: boolean;
};

function AppSplitLayout({
    left,
    right,
    type,
    noPaddingLeft = false,
    noPaddingRight = false,
    noSplitBorder,
    className,
    ...rest
}: AppSplitLayoutProps) {
    return (
        <div
            className={classNames('AppSplitLayout', className, {
                'AppSplitLayout--tab-content': type === 'tab-content',
            })}
            {...rest}
        >
            <div
                className={classNames('AppSplitLayout_Left', className, {
                    'AppSplitLayout_Left--no-padding': noPaddingLeft,
                    'AppSplitLayout_Left--no-split-border': noSplitBorder,
                })}
            >
                {left}
            </div>
            <div
                className={classNames('AppSplitLayout_Right', className, {
                    'AppSplitLayout_Right--no-padding': noPaddingRight,
                })}
            >
                {right}
            </div>
        </div>
    );
}
