import React, {
    useContext,
    useEffect,
    useState,
    useImperativeHandle,
    forwardRef,
} from 'react';
import { ResponsiveContext } from '@severalnines/bar-frontend-components/build/lib/Layout/Responsive';
import { PlusOutlined } from '@ant-design/icons';
import { Button, Row, Typography } from 'antd';
import { TablePaginationConfig } from 'antd/es';
import ActionsMenu, {
    ActionsMenuProps,
} from '../../../../../../common/Navigation/ActionsMenu';
import StatusFormat, {
    StatusFormatStatus,
} from '@severalnines/bar-frontend-components/build/lib/Format/StatusFormat';
import ButtonWithForm from '../../../../../../common/General/ButtonWithForm';
import ProxySQLRulesWizardModal from './ProxySQLRulesWizardModal';
import CmonProxysqlService from '../../../../../../services/cmon/CmonProxysqlService';
import CcCluster from '../../../../../../services/models/CcCluster';
import CcProxySqlNode from '../../../../../../services/models/CcProxySqlNode';
import useListFetch from '../../../../../../common/useListFetch';
import AppEmpty from '../../../../../../common/Feedback/AppEmpty';
import {
    prepareDeleteRuleData,
    prepareTableRules,
    ProxySqlEndPointRuleData,
} from './proxySQLRulesHelper';
import { notifyError } from '../../../../../Notifications/uiNotification';

import AppConfirmActionButton from '../../../../../../common/General/AppConfirmActionButton';
import AppTable from '../../../../../../common/DataDisplay/AppTable';

export default forwardRef(ProxySQLRules);

export type RuleIdType = {
    nextCacheId: number;
    nextRuleId: number;
};

export interface ProxySQLRulesApi {
    refresh: () => void;
}

export type ProxySQLRulesProps = ActionsMenuProps & {
    cluster: CcCluster;
    node: CcProxySqlNode;
    hostGroupList: { value: string; label: string }[] | undefined;
    onQueryRuleId: (obj: RuleIdType) => void;
};

function ProxySQLRules(
    {
        cluster,
        node,
        hostGroupList,
        onQueryRuleId,
        ...rest
    }: ProxySQLRulesProps,
    forwardedRef: any
) {
    const { responsive }: any = useContext(ResponsiveContext);

    const [queryRuleId, setQueryRuleId] = useState<RuleIdType>();

    const {
        loading: rulesLoading,
        list: rulesResponse,
        refresh: refreshRules,
        page: rulesPage,
        pageSize: rulesPageSize,
        total: rulesTotal,
    } = useListFetch({
        name: 'queryRules',
        pageSize: 15,
        fetchFn: async ({ pageSize, page, ...rest }, opts) => {
            const response = await CmonProxysqlService.queryrules(
                {
                    cluster_id: cluster.clusterId,
                    hostName: node.hostname,
                    port: node.port,
                    ...rest,
                },
                opts
            );

            return {
                list: response.queryResults,
                total: response.queryResultTotalCount,
            };
        },
        cancelFn: async ({ requestId }) => {
            await CmonProxysqlService.cancelRequest(requestId);
        },
    });

    const [rulesTableData, setRulesTableData] = useState<
        undefined | ProxySqlEndPointRuleData[]
    >(undefined);

    useEffect(() => {
        refreshRules();
    }, []);

    useEffect(() => {
        if (Array.isArray(rulesResponse)) {
            const ids = rulesResponse.map((rule) => {
                return +rule.ruleId;
            });
            setRulesTableData(prepareTableRules(rulesResponse));
            const ruleId = {
                nextCacheId:
                    rulesResponse.length !== 0 ? Math.min(...ids) - 1 : 99,
                nextRuleId:
                    rulesResponse.length !== 0 ? Math.max(...ids) + 100 : 100,
            };
            setQueryRuleId(ruleId);
            onQueryRuleId(ruleId);
        }
    }, [rulesResponse]);

    useImperativeHandle(
        forwardedRef,
        (): ProxySQLRulesApi => ({
            async refresh() {
                await refreshRules();
            },
        })
    );

    const deleteRule = async (rule: ProxySqlEndPointRuleData) => {
        try {
            await CmonProxysqlService.deletequeryrule({
                hostName: node?.hostname,
                port: node?.port,
                queryRule: prepareDeleteRuleData(rule),
            });
            refreshRules();
        } catch (error) {
            notifyError({ content: error.message });
        }
    };

    const handleTableChange = async (pagination: any) => {
        await refreshRules({
            page: pagination.current,
            pageSize: pagination.pageSize,
        });
    };

    const columns = [
        {
            title: 'Rule ID',
            key: 'rule',
            dataIndex: 'rule',
        },
        {
            title: 'Status',
            key: 'status',
            dataIndex: 'status',
            render: (record: any) => (
                <StatusFormat
                    status={
                        record === 'Active'
                            ? StatusFormatStatus.success
                            : StatusFormatStatus.unknown
                    }
                    text={record}
                />
            ),
        },
        {
            title: 'Apply',
            key: 'apply',
            dataIndex: 'apply',
        },
        {
            title: 'Hits',
            key: 'hits',
            dataIndex: 'hits',
        },
        {
            title: 'Digest/Query',
            key: 'query',
            dataIndex: 'query',
        },
        {
            title: 'Destination hostgroup',
            key: 'hostGroup',
            dataIndex: 'hostGroup',
        },
        {
            title: 'Negate match pattern',
            key: 'pattern',
            dataIndex: 'pattern',
        },
        {
            title: 'Username',
            key: 'userName',
            dataIndex: 'userName',
        },
        {
            title: 'Schema name',
            key: 'schemaName',
            dataIndex: 'schemaName',
        },
        {
            title: 'Actions',
            key: 'actions',
            dataIndex: 'actions',
            render: (record: any) => (
                <ActionsMenu
                    items={[
                        {
                            key: 'edit-rule',
                            label: (
                                <ButtonWithForm
                                    button={<Button type="link">Edit</Button>}
                                    form={
                                        <ProxySQLRulesWizardModal
                                            title="Edit rule"
                                            edit={true}
                                            rule={record.queryRule}
                                            onSuccess={refreshRules}
                                            nextQueryRuleId={
                                                queryRuleId?.nextRuleId
                                            }
                                            cluster={cluster}
                                            node={node}
                                            hostGroupList={hostGroupList}
                                        />
                                    }
                                />
                            ),
                        },
                        {
                            key: 'delete-rule',
                            waitForConfirm: true,
                            label: (
                                <AppConfirmActionButton
                                    confirmTitle={`Delete Rule?`}
                                    critical
                                    onConfirm={() =>
                                        deleteRule(record.queryRule)
                                    }
                                >
                                    <Typography.Text type="danger">
                                        Delete
                                    </Typography.Text>
                                </AppConfirmActionButton>
                            ),
                        },
                    ]}
                    {...rest}
                />
            ),
        },
    ];

    const pagination: TablePaginationConfig = {
        size: 'default',
        pageSize: rulesPageSize,
        current: rulesPage,
        total: rulesTotal,
        hideOnSinglePage: true,
        showQuickJumper: true,
        showSizeChanger: true,
        position: ['bottomCenter'],
    };
    return (
        <div style={{ minHeight: '750px' }}>
            <Row justify="end" style={{ paddingBottom: '1rem' }}>
                <ButtonWithForm
                    button={
                        <Button disabled={rulesLoading} icon={<PlusOutlined />}>
                            Create new rule
                        </Button>
                    }
                    form={
                        <ProxySQLRulesWizardModal
                            title="Create new rule"
                            onSuccess={refreshRules}
                            nextQueryRuleId={queryRuleId?.nextRuleId}
                            cluster={cluster}
                            node={node}
                            hostGroupList={hostGroupList}
                        />
                    }
                />
            </Row>

            <AppTable
                className="ProxySQLRulesTable"
                size="small"
                dataSource={rulesTableData}
                columns={columns}
                responsive={responsive}
                onChange={handleTableChange}
                pagination={pagination}
                loading={rulesLoading}
                renderEmpty={
                    <AppEmpty
                        loading={rulesLoading}
                        description="You haven’t created Rules yet. When you do, it'll show up here."
                    />
                }
            />
        </div>
    );
}
