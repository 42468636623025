import React, { useEffect, useState } from 'react';
import './LoginPage.less';
import { Alert, Button, Form, Input } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { StringParam, useQueryParam } from 'use-query-params';
import AuthWrapper from './AuthWrapper';
import CmonAuthService from '../../services/cmon/CmonAuthService';
import { useDebugContext } from '../../common/Debug';
import { AppConfig } from '../../AppConfig';
import LoadingPage from '../../common/LoadingPage';
import useLocalStorage from "@severalnines/bar-frontend-components/build/lib/hooks/useLocalStorage";

export default LoginPage;

export type LoginPageProps = {};

function LoginPage() {
    const { log } = useDebugContext();
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<any | null>(null);
    const [redirectTo] = useQueryParam<any, any>('redirectTo', StringParam);
    const navigate = useNavigate();
    const [, setLoginUserName] = useLocalStorage('deferred-user-logged-in');
    const [loadingPage, setLoadingPage] = useState<boolean>(true);

    const redirect = () => {
        if (redirectTo) {
            window.location.href = redirectTo;
        } else {
            navigate('/dashboard');
        }
    };
    const handleFinish = async (values: any) => {
        try {
            setLoading(true);
            await CmonAuthService.authenticateWithPassword({
                password: values.password,
                user_name: values.username,
            });
            setLoginUserName(values.username);
            redirect();
        } catch (err) {
            setLoading(false);
            setError(err);
        }
    };

    const handleFinishFailed = (errorInfo: any) => {
        log.error(errorInfo);
    };

    useEffect(() => {
        (async () => {
            try {
                if (AppConfig.INITIAL_USER) {
                    await CmonAuthService.authenticateWithPassword({
                        user_name: AppConfig.INITIAL_USER,
                        password: AppConfig.INITIAL_PASSWORD,
                    });
                    window.location.assign(`/registration`);
                }
                setLoadingPage(false);
            } catch (err) {
                setLoadingPage(false);
                log.error(err);
            }
        })();
    }, []);

    return loadingPage ? (
        <LoadingPage />
    ) : (
        <AuthWrapper>
            <Form
                className="LoginPage_form"
                name="basic"
                layout="vertical"
                initialValues={{
                    remember: true,
                }}
                onFinish={handleFinish}
                onFinishFailed={handleFinishFailed}
            >
                <p className="LoginPage_title">Login</p>
                <Form.Item
                    data-testid="userNameField"
                    label="Username"
                    name="username"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your username!',
                        },
                    ]}
                >
                    <Input placeholder="Enter username" />
                </Form.Item>

                <Form.Item
                    data-testid="passwordField"
                    label="Password"
                    name="password"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your password!',
                        },
                    ]}
                >
                    <Input.Password placeholder="Enter your password" />
                </Form.Item>

                {error ? (
                    <>
                        <Alert showIcon message={error.message} type="error" />
                        <br />
                    </>
                ) : null}
                {/*<Form.Item {...tailLayout} name="remember" valuePropName="checked">*/}
                {/*    <Checkbox>Remember me</Checkbox>*/}
                {/*</Form.Item>*/}

                <Link to="/reset-password">
                    <span>Forgot your password?</span>
                </Link>

                <Form.Item style={{ marginTop: '10px', textAlign: 'right' }}>
                    <Button loading={loading} type="primary" htmlType="submit">
                        Log In
                    </Button>
                </Form.Item>
            </Form>
        </AuthWrapper>
    );
}
