import CmonClustersService from '../../services/cmon/CmonClustersService';
import { useEffect, useState } from 'react';
import useFetch from '../../common/useFetch';
import { ClusterConfig, ClusterConfigValue } from './useClusterConfig';

export enum ClusterConfigCategory {
    BACKUP = 'backup',
    CLUSTER = 'cluster',
    CMONDB = 'cmondb',
    CONTROLLER = 'controller',
    LONG_QUERY = 'long_query',
    REPLICATION = 'replication',
    RETENTION = 'retention',
    SAMPLING = 'sampling',
    SWAPPING = 'swapping',
    SYSTEM = 'system',
    THRESHOLD = 'threshold',
}

export const CLUSTER_CONFIG_READONLY_CATEGORIES = [
    ClusterConfigCategory.CMONDB,
    ClusterConfigCategory.CONTROLLER,
];
export const CLUSTER_CONFIG_READONLY_ITEMS = ['config_file_path'];
export type ClusterConfigGrouped = {
    [key in ClusterConfigCategory]?: ClusterConfig;
} & {
    [key: string]: ClusterConfig;
};

export type UseClusterConfigGroupedProps = {
    clusterId?: number;
    name?: string;
    useCache?: boolean;
};
export default function useClusterConfigGrouped({
    clusterId,
    name = 'use-cluster-config-grouped',
    useCache = false,
}: UseClusterConfigGroupedProps) {
    const [config, setConfig] = useState<ClusterConfigGrouped | undefined>();
    const { loading, data, refresh: refreshFetch } = useFetch({
        name,
        useCache,
        fetchFn: async (params, opts) => {
            const {
                configuration: { extended_info },
            } = await CmonClustersService.getConfigGrouped(
                {
                    ...params,
                    cluster_id: clusterId,
                },
                opts
            );
            return extended_info;
        },
        cancelFn: async ({ requestId }) => {
            await CmonClustersService.cancelRequest(requestId);
        },
    });
    useEffect(() => {
        if (data) {
            setConfig(
                (data as any).reduce((a: any, c: any) => {
                    Object.entries(c).forEach(([key, value]) => {
                        if (!a[key]) {
                            a[key] = {};
                        }
                        const readonlyCategory =
                            CLUSTER_CONFIG_READONLY_CATEGORIES.includes(
                                key as ClusterConfigCategory
                            );
                        (value as ClusterConfigValue[]).forEach((value) => {
                            a[key][value.name] = {
                                ...value,
                                readonly:
                                    readonlyCategory ||
                                    CLUSTER_CONFIG_READONLY_ITEMS.includes(
                                        value.name
                                    ),
                            };
                        });
                    });
                    return a;
                }, {})
            );
        }
    }, [data]);
    return {
        config,
        loading,
        refresh: refreshFetch,
    };
}
