import React from 'react';
import { Col, Row } from 'antd';
import ClusterNameAndTags from '../FormParts/ClusterNameAndTags';
import { ClusterConfigurator } from '../ClusterConfigurator';
import ClusterVendorSelect from '../../../../common/Form/Fields/ClusterVendorSelect';

export default ImportClusterDetails;

export type DeploymentClusterDetailsProps = {
    configurator: typeof ClusterConfigurator;
    hasVendor?: boolean;
};

function ImportClusterDetails({
    configurator,
    hasVendor,
}: DeploymentClusterDetailsProps) {
    return (
        <div className="ImportClusterDetails">
            {hasVendor ? (
                <Row gutter={[24, 0]}>
                    <Col xs={24} sm={24} md={16}>
                        <ClusterVendorSelect
                            name={['details', 'vendor']}
                            vendors={configurator.getVendors()}
                        />
                    </Col>
                </Row>
            ) : null}
            <Row gutter={[24, 0]}>
                <ClusterNameAndTags />
            </Row>
        </div>
    );
}

export function getImportClusterDetailsValidate() {
    return [
        ['details', 'names'],
        ['details', 'tags'],
    ];
}
