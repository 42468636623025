import React from 'react';
import { CcCloudProviderType } from '../../services/models/CcCloudCredentials';
import {
    CloudAwsIcon,
    CloudAzureIcon,
    CloudGoogleIcon,
    CloudIcon,
} from '../../common/icons/icons';
import { AppIconSize } from '../../common/icons/AppIcon';
import { getArrayFromEnum } from '../../common/utils/enums';

export default CloudProviderTypeFormat;

export type CloudProviderTypeProps = {
    children?: React.ReactNode;
    provider: CcCloudProviderType;
};

function CloudProviderTypeFormat({
    children,
    provider,
}: CloudProviderTypeProps) {
    return (
        <span className="CloudProviderType">
            {getCloudProviderIcon(provider)}{' '}
            <span>{children || getCloudProviderName(provider)}</span>
        </span>
    );
}

export function getCloudProviderIcon(provider: CcCloudProviderType) {
    switch (provider) {
        case CcCloudProviderType.AWS:
            return <CloudAwsIcon />;
        case CcCloudProviderType.GOOGLE:
            return <CloudGoogleIcon />;
        case CcCloudProviderType.AZURE:
            return <CloudAzureIcon />;
        default:
            return <CloudIcon size={AppIconSize.small} />;
    }
}

export function getCloudProviderName(provider: CcCloudProviderType) {
    switch (provider) {
        case CcCloudProviderType.AWS:
            return 'Amazon Web Services';
        case CcCloudProviderType.GOOGLE:
            return 'Google Cloud';
        case CcCloudProviderType.AZURE:
            return 'Microsoft Azure';
        case CcCloudProviderType.S3:
            return 'S3 Compatible storage provider';
    }
}

export function getCloudProvidersTextValue() {
    return (getArrayFromEnum(CcCloudProviderType) as CcCloudProviderType[]).map(
        (type) => ({
            text: getCloudProviderName(type),
            value: type,
        })
    );
}
