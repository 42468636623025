import './UserInfo.less';
import React from 'react';
import UserAvatar from './UserAvatar';
import { Space } from 'antd';
import TypographyText from '../../common/TypographyText';
import CcUser from '../../services/models/CcUser';
import SpaceDescriptions from '../../common/Layout/SpaceDescriptions';
import Title from 'antd/lib/typography/Title';
import { getOriginText } from '../UserManagement/UserFormat';

export default UserInfo;
export type UserInfoProps = {
    user: CcUser;
    size?: 'xsmall' | 'small' | 'large';
};

function UserInfo({ user, size = 'small', ...rest }: UserInfoProps) {
    const align = size === 'small' ? 'start' : 'center';
    return (
        <Space
            className="UserInfo"
            align={align}
            direction={size === 'small' ? 'horizontal' : 'vertical'}
        >
            <UserAvatar
                size={['xsmall', 'small'].includes(size) ? 'small' : 'large'}
                user={user}
            />
            {size === 'xsmall' ? (
                <Space direction="vertical" align={align} size={0}>
                    <span>{user.userName}</span>
                </Space>
            ) : (
                <Space
                    direction="vertical"
                    align={align}
                    size={size === 'large' ? 20 : 5}
                >
                    <Space direction="vertical" align={align} size={1}>
                        <Title
                            className="UserInfo_name"
                            level={size === 'large' ? 2 : 5}
                        >
                            {user.getName()}
                        </Title>
                        <TypographyText muted>
                            {user.emailAddress}
                        </TypographyText>
                    </Space>
                    {size === 'large' ? (
                        <Space direction="vertical" align="center">
                            <SpaceDescriptions.Item
                                colon={true}
                                label="Time zone"
                                direction="horizontal"
                                align={align}
                            >
                                <Space>
                                    <span>{user.timezone?.name}</span>
                                </Space>
                            </SpaceDescriptions.Item>
                            <SpaceDescriptions.Item
                                colon={true}
                                label="Username"
                                direction="horizontal"
                                align={align}
                            >
                                <Space>
                                    <span>{user.userName}</span>
                                </Space>
                            </SpaceDescriptions.Item>
                            <SpaceDescriptions.Item
                                colon={true}
                                label="Team"
                                direction="horizontal"
                                align={align}
                            >
                                <Space>
                                    <span>
                                        {user.userGroups?.[0]?.groupName}
                                    </span>
                                </Space>
                            </SpaceDescriptions.Item>
                            <SpaceDescriptions.Item
                                colon={true}
                                label="Origin"
                                direction="horizontal"
                                align={align}
                            >
                                <Space>
                                    <span>{getOriginText(user)}</span>
                                </Space>
                            </SpaceDescriptions.Item>
                        </Space>
                    ) : (
                        <SpaceDescriptions.Item
                            colon={true}
                            direction="horizontal"
                            align={align}
                        >
                            <Space>
                                <span>{user.timezone?.name}</span>
                            </Space>
                        </SpaceDescriptions.Item>
                    )}
                </Space>
            )}
        </Space>
    );
}
