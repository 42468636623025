import './LicenseInfo.less';
import React from 'react';
import { AppState, AppStateLicenseInfo } from '../../appReducer';
import { useSelector } from 'react-redux';
import { Button, Space } from 'antd';
import TypographyText from '@severalnines/bar-frontend-components/build/lib/General/TypographyText';
import Title from 'antd/lib/typography/Title';
import SpaceDescriptions from '../../common/Layout/SpaceDescriptions';
import StatusFormat, {
    StatusFormatStatus,
} from '@severalnines/bar-frontend-components/build/lib/Format/StatusFormat';
import image from './cc-license-box.png';
import AppProgress from '../../common/AppProgress';
import { FileDoneOutlined } from '@ant-design/icons';
import ButtonWithForm from '../../common/General/ButtonWithForm';
import LicenseKeyModal from './LicenseKeyModal';
import MailtoButton from '../../common/Navigation/MailtoButton';
import { CcLicenseType } from '../../services/models/CcLicense';
import AppDateFormat from '../../common/AppDateFormat';

export default LicenseInfo;

export type LicenseInfoProps = {};

function LicenseInfo({ ...rest }: LicenseInfoProps) {
    const [licenseInfo]: [
        AppStateLicenseInfo
    ] = useSelector(({ licenseInfo }: AppState) => [licenseInfo]);
    return (
        <Space direction="vertical" className="LicenseInfo">
            <Space align="start" size={40}>
                <img src={image} alt="ClusterControl Enterprise" width={250} />
                <Space direction="vertical" className="LicenseInfo_Info">
                    <Space direction="vertical" size={0}>
                        <TypographyText muted={true}>License</TypographyText>
                        <Title level={2}>
                            ClusterControl{' '}
                            {licenseInfo?.license?.type &&
                                getLicenseTypeName(licenseInfo?.license?.type)}
                        </Title>
                    </Space>
                    <SpaceDescriptions
                        direction="horizontal"
                        align="start"
                        size={20}
                    >
                        <SpaceDescriptions direction="vertical" size={15}>
                            <SpaceDescriptions.Item
                                direction="vertical"
                                label="Status"
                                colon={false}
                            >
                                <TypographyText nowrap={true}>
                                    {licenseInfo?.license.validDate ? (
                                        <StatusFormat
                                            status={StatusFormatStatus.success}
                                        >
                                            Active
                                        </StatusFormat>
                                    ) : (
                                        <StatusFormat
                                            status={StatusFormatStatus.error}
                                        >
                                            Expired
                                        </StatusFormat>
                                    )}
                                </TypographyText>
                            </SpaceDescriptions.Item>
                            {licenseInfo?.license?.expirationDate && (
                                <SpaceDescriptions.Item
                                    labelNowrap={true}
                                    direction="vertical"
                                    label="Expiration date"
                                >
                                    <TypographyText nowrap={true}>
                                        <AppDateFormat includeTimezone={false}>
                                            {
                                                new Date(
                                                    licenseInfo?.license?.expirationDate
                                                )
                                            }
                                        </AppDateFormat>
                                    </TypographyText>
                                </SpaceDescriptions.Item>
                            )}{' '}
                        </SpaceDescriptions>
                        <SpaceDescriptions direction="vertical" size={15}>
                            <SpaceDescriptions.Item
                                labelNowrap={true}
                                direction="vertical"
                                label="License owner"
                            >
                                <TypographyText nowrap={true}>
                                    {licenseInfo?.license?.company}
                                </TypographyText>
                            </SpaceDescriptions.Item>
                            <SpaceDescriptions.Item
                                labelNowrap={true}
                                direction="vertical"
                                label="License owner's email"
                            >
                                <TypographyText nowrap={true}>
                                    {licenseInfo?.license?.emailAddress}
                                </TypographyText>
                            </SpaceDescriptions.Item>
                        </SpaceDescriptions>
                    </SpaceDescriptions>
                    <Space direction="vertical" size={30}>
                        <div className="LicenseInfo_Nodes">
                            <TypographyText muted>Nodes limit</TypographyText>
                            <AppProgress
                                infoMuted={true}
                                warnExceeding={true}
                                showActualNumbers={true}
                                infoExtra={'nodes'}
                                total={licenseInfo?.license?.licensedNodes}
                                current={licenseInfo?.license?.usedNodes}
                                steps={10}
                            />
                        </div>
                        <Space>
                            {!licenseInfo?.license?.validDate && (
                                <MailtoButton mailto="mailto:sales@severalnines.com?subject=Purchase ClusterControl&body=## Please provide details about your organisation and database setup.">
                                    Purchase ClusterControl
                                </MailtoButton>
                            )}
                            <ButtonWithForm
                                button={
                                    <Button type="primary">
                                        <FileDoneOutlined />
                                        <span>Enter a license key</span>
                                    </Button>
                                }
                                form={
                                    <LicenseKeyModal
                                        license={licenseInfo?.license}
                                    />
                                }
                            />
                        </Space>
                    </Space>
                </Space>
            </Space>
        </Space>
    );
}

function getLicenseTypeName(type: CcLicenseType) {
    switch (type) {
        case CcLicenseType.Demo:
            return 'Enterprise trial';
        default:
            return type;
    }
}
