
import CmonRequestService, {CmonRequestServiceResponseData} from './CmonRequestService';
import {CmonUserProps} from './models/CmonUser'
import {CmonGroupProps} from './models/CmonGroup'


export interface CreateUserRequestData {
    create_group?: boolean;
    new_password?: string;
    user?: CmonUserProps;
    [key: string]: any;
}

export interface WhoAmIRequestData {
    request_created?: string;
    request_id?: number;
    with_extended_privileges?: boolean;
    [key: string]: any;
}

export interface GetUsersRequestData {
    request_created?: string;
    request_id?: number;
    with_tags?: [];
    [key: string]: any;
}

export interface SetUserRequestData {
    request_created?: string;
    request_id?: number;
    user?: CmonUserProps;
    [key: string]: any;
}

export interface DisableRequestData {
    request_created?: string;
    request_id?: number;
    user?: CmonUserProps;
    [key: string]: any;
}

export interface DeleteUserRequestData {
    request_created?: string;
    request_id?: number;
    user?: CmonUserProps;
    [key: string]: any;
}

export interface EnableRequestData {
    request_created?: string;
    request_id?: number;
    user?: CmonUserProps;
    [key: string]: any;
}

export interface ChangePasswordRequestData {
    new_password?: string;
    old_password?: string;
    request_created?: string;
    request_id?: number;
    user?: CmonUserProps;
    [key: string]: any;
}

export interface GetKeysRequestData {
    request_created?: string;
    request_id?: number;
    user?: CmonUserProps;
    [key: string]: any;
}

export interface AddKeyRequestData {
    public_key?: {[key:string]:any};
    request_created?: string;
    request_id?: number;
    user?: CmonUserProps;
    [key: string]: any;
}

export interface DeleteKeyRequestData {
    public_key?: {[key:string]:any};
    request_created?: string;
    request_id?: number;
    user?: CmonUserProps;
    [key: string]: any;
}

export interface CreateGroupRequestData {
    group?: CmonGroupProps;
    [key: string]: any;
}

export interface DeleteGroupRequestData {
    group?: CmonGroupProps;
    request_created?: string;
    request_id?: number;
    [key: string]: any;
}

export interface GetGroupsRequestData {
    request_created?: string;
    request_id?: number;
    [key: string]: any;
}

export interface AddToGroupRequestData {
    group_name?: string;
    replace_primary_group?: boolean;
    request_created?: string;
    request_id?: number;
    user?: CmonUserProps;
    [key: string]: any;
}

export interface RemoveFromGroupRequestData {
    group_name?: string;
    request_created?: string;
    request_id?: number;
    user?: CmonUserProps;
    [key: string]: any;
}

export interface CanCreateUserRequestData {
    request_created?: string;
    request_id?: number;
    [key: string]: any;
}

export interface CanCreateGroupRequestData {
    request_created?: string;
    request_id?: number;
    [key: string]: any;
}

export interface UpdateUserRequestData {
    new_password?: string;
    user?: CmonUserProps;
    [key: string]: any;
}

export interface SetUserPreferencesRequestData {
    request_created?: string;
    request_id?: number;
    user?: CmonUserProps;
    [key: string]: any;
}

export interface GetUserPreferencesRequestData {
    request_created?: string;
    request_id?: number;
    user?: CmonUserProps;
    [key: string]: any;
}

export interface DeleteUserPreferencesRequestData {
    request_created?: string;
    request_id?: number;
    user?: CmonUserProps;
    [key: string]: any;
}


export default class CmonUsersService extends CmonRequestService {
    
    public static module(): string {
        return 'users';
    }
    
    public static async createUser(
        data: CreateUserRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('createUser', data, opts);
    }

    public static async whoAmI(
        data: WhoAmIRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('whoAmI', data, opts);
    }

    public static async getUsers(
        data: GetUsersRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('getUsers', data, opts);
    }

    public static async setUser(
        data: SetUserRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('setUser', data, opts);
    }

    public static async disable(
        data: DisableRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('disable', data, opts);
    }

    public static async deleteUser(
        data: DeleteUserRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('deleteUser', data, opts);
    }

    public static async enable(
        data: EnableRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('enable', data, opts);
    }

    public static async changePassword(
        data: ChangePasswordRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('changePassword', data, opts);
    }

    public static async getKeys(
        data: GetKeysRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('getKeys', data, opts);
    }

    public static async addKey(
        data: AddKeyRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('addKey', data, opts);
    }

    public static async deleteKey(
        data: DeleteKeyRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('deleteKey', data, opts);
    }

    public static async createGroup(
        data: CreateGroupRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('createGroup', data, opts);
    }

    public static async deleteGroup(
        data: DeleteGroupRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('deleteGroup', data, opts);
    }

    public static async getGroups(
        data: GetGroupsRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('getGroups', data, opts);
    }

    public static async addToGroup(
        data: AddToGroupRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('addToGroup', data, opts);
    }

    public static async removeFromGroup(
        data: RemoveFromGroupRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('removeFromGroup', data, opts);
    }

    public static async canCreateUser(
        data: CanCreateUserRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('canCreateUser', data, opts);
    }

    public static async canCreateGroup(
        data: CanCreateGroupRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('canCreateGroup', data, opts);
    }

    public static async updateUser(
        data: UpdateUserRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('updateUser', data, opts);
    }

    public static async setUserPreferences(
        data: SetUserPreferencesRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('setUserPreferences', data, opts);
    }

    public static async getUserPreferences(
        data: GetUserPreferencesRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('getUserPreferences', data, opts);
    }

    public static async deleteUserPreferences(
        data: DeleteUserPreferencesRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('deleteUserPreferences', data, opts);
    }

}
