import FormItemInline from '../../../../common/FormItemInline';
import React from 'react';
import Switch from '../../../../common/DataEntry/Switch';
export default NodeAutoRecovery;
export type AutoRecoveryProps = {};
function NodeAutoRecovery({}: AutoRecoveryProps) {
    return (
        <>
            <FormItemInline
                justify={true}
                name={['nodeConfig', 'clusterAutoRecovery']}
                label="Cluster auto-recovery"
                valuePropName="checked"
                noMargin
            >
                <Switch />
            </FormItemInline>
            <FormItemInline
                justify={true}
                name={['nodeConfig', 'nodeAutoRecovery']}
                label="Node auto-recovery"
                valuePropName="checked"
                noMargin
            >
                <Switch />
            </FormItemInline>
        </>
    );
}
