import React from 'react';
import { Button, Divider, Space } from 'antd';
import ButtonWithForm from '../common/General/ButtonWithForm';
import AddNodeReplicationModal from '../components/Clusters/Actions/AddNode/Replication/AddNodeReplicationModal';
import CcCluster from '../services/models/CcCluster';
import galera from './dataMocks/clusters/galera';
import CcBackupService from '../services/CcBackupService';
import getBackups1 from './dataMocks/rpc/backup/getBackups1';
import CcBackup from '../services/models/CcBackup';
import AddNodeDatabaseModal from '../components/Clusters/Actions/AddNode/Database/AddNodeDatabaseModal';
import ClusterTypeFormat from '../components/Clusters/ClusterTypeFormat';
import mysqlReplication from './dataMocks/clusters/mysqlReplication';
import postgresWithLb from './dataMocks/clusters/postgresWithLb';

export default DebugAddNodeModal;

export type DebugAddNodeModalProps = {};

const galeraCluster = new CcCluster(galera as any);
const replicationCluster = new CcCluster(mysqlReplication as any);
const postgresqlCluster = new CcCluster(postgresWithLb as any);
function DebugAddNodeModal({}: DebugAddNodeModalProps) {
    CcBackupService.getBackups = () =>
        Promise.resolve({
            backup_records: getBackups1.backup_records.map(
                (record) => new CcBackup(record as any)
            ),
        });
    return (
        <Space direction="vertical" size={20}>
            <Space>
                <Divider
                    plain={true}
                    orientation="left"
                    style={{ marginBottom: '0px', marginTop: '15px' }}
                >
                    Add{' '}
                    <ClusterTypeFormat
                        showIcon
                        type={galeraCluster.clusterType}
                    />{' '}
                    Replication Node
                </Divider>
                <ButtonWithForm
                    button={<Button>Button</Button>}
                    form={<AddNodeReplicationModal cluster={galeraCluster} />}
                />
            </Space>
            <Space>
                <Divider
                    plain={true}
                    orientation="left"
                    style={{ marginBottom: '0px', marginTop: '15px' }}
                >
                    Add{' '}
                    <ClusterTypeFormat
                        showIcon
                        type={galeraCluster.clusterType}
                    />{' '}
                    Database Node
                </Divider>
                <ButtonWithForm
                    button={<Button>Button</Button>}
                    form={<AddNodeDatabaseModal cluster={galeraCluster} />}
                />
            </Space>
            <Space>
                <Divider
                    plain={true}
                    orientation="left"
                    style={{ marginBottom: '0px', marginTop: '15px' }}
                >
                    Add{' '}
                    <ClusterTypeFormat
                        showIcon
                        type={replicationCluster.clusterType}
                    />{' '}
                    Replication Node
                </Divider>
                <ButtonWithForm
                    button={<Button>Button</Button>}
                    form={
                        <AddNodeReplicationModal cluster={replicationCluster} />
                    }
                />
            </Space>
            <Space>
                <Divider
                    plain={true}
                    orientation="left"
                    style={{ marginBottom: '0px', marginTop: '15px' }}
                >
                    Add{' '}
                    <ClusterTypeFormat
                        showIcon
                        type={postgresqlCluster.clusterType}
                    />{' '}
                    Replication Node
                </Divider>
                <ButtonWithForm
                    button={<Button>Button</Button>}
                    form={
                        <AddNodeReplicationModal cluster={postgresqlCluster} />
                    }
                />
            </Space>
        </Space>
    );
}
