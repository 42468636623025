import React from 'react';
import ActionsMenu, {
    ActionsMenuProps,
} from '../../common/Navigation/ActionsMenu';
import CcScheduleReport from '../../services/models/CcScheduleReport';

import OperationalReportScheduleDeleteButton from './OperationalReportScheduleDeleteButton';
import OperationalReportCreateScheduleReportButton from './OperationalReportCreateScheduleReportButton';
import CcReport from '../../services/models/CcReport';

export default OperationalReportScheduleActionMenu;

export type OperationalReportScheduleActionMenuProps = ActionsMenuProps & {
    schedule: CcScheduleReport;
    templates?: CcReport[];
};

function OperationalReportScheduleActionMenu({
    schedule,
    templates,
    onActionPerformed,
}: OperationalReportScheduleActionMenuProps) {
    return (
        <ActionsMenu
            items={[
                {
                    key: 'edit',
                    label: (
                        <OperationalReportCreateScheduleReportButton
                            schedule={schedule}
                            templates={templates}
                            onSuccess={onActionPerformed}
                        />
                    ),
                },
                {
                    key: 'delete',
                    label: (
                        <OperationalReportScheduleDeleteButton
                            schedule={schedule}
                            onSuccess={onActionPerformed}
                        ></OperationalReportScheduleDeleteButton>
                    ),
                },
            ]}
        />
    );
}
