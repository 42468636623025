import React from 'react';
import './ActivityCenterPage.less';
import { Space } from 'antd';
import AlarmsTable from '../Alarms/AlarmsTable';
import JobsTable from '../Jobs/JobsTable';
import LogsTable from '../Logs/LogsTable';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import AlarmsBadge from '../Alarms/AlarmsBadge';
import JobsBadge from '../Jobs/JobsBadge';
import { ActivityIcon } from '../../common/icons/icons';
import Tabs from '../../common/DataDisplay/Tabs';
import AuditLogTable from '../AuditLog/AuditLogTable';
import AppPageWrapper from '../../common/Layout/AppPageWrapper';
import CmonLogBadge from '../Logs/CmonLogBadge';

export default ActivityCenterPage;

export enum ActivityCenterPageKey {
    ALARMS = 'alarms',
    JOBS = 'jobs',
    LOGS = 'logs',
    AUDIT_LOG = 'audit',
}

export type ActivityCenterPageProps = {};

function ActivityCenterPage({}: ActivityCenterPageProps) {
    const navigate = useNavigate();
    const { activityType = ActivityCenterPageKey.ALARMS } = useParams<{
        activityType: ActivityCenterPageKey;
    }>();
    return (
        <AppPageWrapper
            className="ActivityCenterPage"
            title={
                <Space>
                    <ActivityIcon />
                    Activity center
                </Space>
            }
            transparentBackground={true}
            noPadding={true}
        >
            <Tabs
                className="ActivityCenterPage_tabs"
                activeKey={activityType}
                onTabClick={(key) => {
                    navigate(`/activity-center/${key}`);
                }}
                destroyInactiveTabPane={true}
            >
                <Tabs.TabPane
                    tab={
                        <AlarmsBadge offsetY={-5} offsetX={5}>
                            Alarms
                        </AlarmsBadge>
                    }
                    key={ActivityCenterPageKey.ALARMS}
                >
                    <AlarmsTable />
                </Tabs.TabPane>
                <Tabs.TabPane
                    tab={
                        <JobsBadge offsetY={-5} offsetX={5}>
                            Jobs
                        </JobsBadge>
                    }
                    key={ActivityCenterPageKey.JOBS}
                >
                    <JobsTable />
                </Tabs.TabPane>
                <Tabs.TabPane
                    tab={
                        <CmonLogBadge offsetY={-5} offsetX={5}>
                            CMON Log
                        </CmonLogBadge>
                    }
                    key={ActivityCenterPageKey.LOGS}
                >
                    <LogsTable
                        isActive={activityType === ActivityCenterPageKey.LOGS}
                    />
                </Tabs.TabPane>
                <Tabs.TabPane
                    tab={<span>Audit Log</span>}
                    key={ActivityCenterPageKey.AUDIT_LOG}
                >
                    <AuditLogTable
                        isActive={
                            activityType === ActivityCenterPageKey.AUDIT_LOG
                        }
                    />
                </Tabs.TabPane>
            </Tabs>
        </AppPageWrapper>
    );
}
