
import CmonRequestService, {CmonRequestServiceResponseData} from './CmonRequestService';


export interface GetRepositoriesRequestData {
    cluster_id?: number;
    request_created?: string;
    request_id?: number;
    [key: string]: any;
}

export interface GetSupportedSetupsRequestData {
    request_created?: string;
    request_id?: number;
    [key: string]: any;
}


export default class CmonRepositoriesService extends CmonRequestService {
    
    public static module(): string {
        return 'repositories';
    }
    
    public static async getRepositories(
        data: GetRepositoriesRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('getRepositories', data, opts);
    }

    public static async getSupportedSetups(
        data: GetSupportedSetupsRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('getSupportedSetups', data, opts);
    }

}
