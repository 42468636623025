import React from 'react';
import { Form } from 'antd';
import MongoReplicaSetSelect from '../../DataEntry/MongoReplicaSetSelect';
import CcCluster from '../../../services/models/CcCluster';
import { FormItemProps } from 'antd/lib/form';
import CcMongoNode from '../../../services/models/CcMongoNode';

export default MongoReplicaSetSelectField;

export type MongoReplicaSetSelectFieldProps = FormItemProps & {
    cluster: CcCluster;
    nodeFilter?: (node: CcMongoNode) => boolean;
};

function MongoReplicaSetSelectField({
    cluster,
    nodeFilter,
    name = 'mongoReplicaSet',
    label = 'Replica set',
    required = true,
    ...rest
}: MongoReplicaSetSelectFieldProps) {
    return (
        <div className="MongoReplicaSetSelectField">
            <Form.Item
                name={name}
                label={label}
                rules={[
                    {
                        required,
                        message: 'Please select replica set',
                    },
                ]}
                {...rest}
            >
                <MongoReplicaSetSelect
                    cluster={cluster}
                    nodeFilter={nodeFilter}
                />
            </Form.Item>
        </div>
    );
}
