import React from 'react';
import DataDirectoryField, {
    DataDirectoryFieldProps,
} from '../../../../common/Form/Fields/DataDirectoryField';

export default NodeDataDirectoryField;

export type NodeDataDirectoryFieldProps = DataDirectoryFieldProps & {
    label?: React.ReactNode;
    required?: boolean;
};

function NodeDataDirectoryField({
    label = 'Server data directory',
    required = false,
    ...rest
}: NodeDataDirectoryFieldProps) {
    return (
        <DataDirectoryField
            required={required}
            name={['nodeConfig', 'serverDataDirectory']}
            label={label}
            {...rest}
        />
    );
}
