import React from 'react';
import WizardFormConfiguration, {
    WizardFormConfigurationProps,
} from '@severalnines/bar-frontend-components/build/lib/Navigation/Wizard/WizardFormConfiguration';
import TypographyText from '../../../../common/TypographyText';
import { AddNodeConfigurationFormFields } from './AddNodeConfigurationForm';
import { AddNodeAdvancedFormFields } from './AddNodeAdvancedForm';
import { AddNodeHostFormFields } from './AddNodeHostForm';

export default AddNodeFormWizard;

export type AddNodeFormWizardFields = AddNodeConfigurationFormFields &
    AddNodeAdvancedFormFields &
    AddNodeHostFormFields;

export enum AddNodeFormWizardStep {
    CONFIG = 'CONFIG',
    ADVANCED = 'ADVANCED',
    NODE = 'NODE',
    SUMMARY = 'SUMMARY',
}
export type AddNodeFormWizardProps = WizardFormConfigurationProps & {};

function AddNodeFormWizard({ ...rest }: AddNodeFormWizardProps) {
    return <WizardFormConfiguration {...rest} />;
}

export function getAddNodeWizardStepName(step: AddNodeFormWizardStep) {
    switch (step) {
        case AddNodeFormWizardStep.CONFIG:
            return <TypographyText nowrap>Node configuration</TypographyText>;
        case AddNodeFormWizardStep.ADVANCED:
            return 'Advanced settings';
        case AddNodeFormWizardStep.NODE:
            return 'Add node';
        case AddNodeFormWizardStep.SUMMARY:
            return 'Preview';
        default:
            return '';
    }
}
