import React from 'react';
import './AppEmpty.less';
import { Empty as AntEmpty, EmptyProps, Space, Typography } from 'antd';

export default AppEmpty;

export type AppEmptyProps = EmptyProps & {
    title?: string;
    loading?: boolean;
    extra?: React.ReactNode;
};

function AppEmpty({
    title,
    description,
    style,
    extra,
    loading,
    ...rest
}: AppEmptyProps) {
    return (
        <AntEmpty
            className="AppEmpty"
            image={AntEmpty.PRESENTED_IMAGE_DEFAULT}
            description={
                <Space
                    className="AppEmpty_wrap"
                    direction="vertical"
                    size={20}
                    style={{
                        visibility: loading ? 'hidden' : 'visible',
                    }}
                >
                    {title ? (
                        <Typography.Title level={3}>{title}</Typography.Title>
                    ) : null}
                    {description}
                    {extra}
                </Space>
            }
            style={{ paddingTop: '20px', paddingBottom: '20px', ...style }}
            {...rest}
        />
    );
}
