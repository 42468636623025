import CcTreeItem from '../../../../services/models/CcTreeItem';

const result = {
    cdt: new CcTreeItem({
        class_name: 'CmonTreeItem',
        owner_user_id: 1,
        owner_user_name: 'system',
        owner_group_id: 1,
        owner_group_name: 'admins',
        effective_privileges: 'rwx',
        item_acl: 'user::rwx,group::rwx,other::rwx',
        item_name: '',
        item_path: '/',
        item_type: 'Folder',
        sub_items: [
            {
                class_name: 'CmonTreeItem',
                owner_user_id: 5,
                owner_user_name: 'pablo',
                owner_group_id: 1,
                owner_group_name: 'admins',
                effective_privileges: 'rwx',
                item_acl:
                    'user::rwx,group::rwx,group:groupFullAccess:rwx,group:groupViewAccess:r--,group:groupNoAccess:---,group:groupCM1V2Access:rwx,group:nobody:rwx,group:newgroups:rwx,group:newgroup:rwx,other::---',
                item_name: 'cluster_1',
                item_path: '/',
                item_type: 'Cluster',
                sub_items: [
                    {
                        class_name: 'CmonTreeItem',
                        owner_user_id: 5,
                        owner_user_name: 'pablo',
                        owner_group_id: 1,
                        owner_group_name: 'admins',
                        effective_privileges: 'rwx',
                        item_acl: 'user::rwx,group::rwx,other::---',
                        item_name: '10.0.8.11:3306',
                        item_path: '/cluster_1',
                        item_spec: 'master',
                        item_type: 'Node',
                    },
                    {
                        class_name: 'CmonTreeItem',
                        owner_user_id: 1,
                        owner_user_name: 'system',
                        owner_group_id: 1,
                        owner_group_name: 'admins',
                        effective_privileges: 'rwx',
                        item_acl: 'user::rwx,group::rwx,other::---',
                        item_name: '10.0.8.11:6603',
                        item_path: '/cluster_1',
                        item_spec: '',
                        item_type: 'Node',
                    },
                    {
                        class_name: 'CmonTreeItem',
                        owner_user_id: 5,
                        owner_user_name: 'pablo',
                        owner_group_id: 1,
                        owner_group_name: 'admins',
                        effective_privileges: 'rwx',
                        item_acl: 'user::rwx,group::rwx,other::---',
                        item_name: '10.0.8.12:3306',
                        item_path: '/cluster_1',
                        item_spec: 'slave',
                        item_type: 'Node',
                    },
                    {
                        class_name: 'CmonTreeItem',
                        owner_user_id: 5,
                        owner_user_name: 'pablo',
                        owner_group_id: 1,
                        owner_group_name: 'admins',
                        effective_privileges: 'rwx',
                        item_acl: 'user::rwx,group::rwx,other::---',
                        item_name: '134.122.121.181:9090',
                        item_path: '/cluster_1',
                        item_spec: '',
                        item_type: 'Node',
                    },
                    {
                        class_name: 'CmonTreeItem',
                        owner_user_id: 5,
                        owner_user_name: 'pablo',
                        owner_group_id: 1,
                        owner_group_name: 'admins',
                        effective_privileges: 'rwx',
                        item_acl: 'user::rwx,group::rwx,other::---',
                        item_name: '134.122.121.181:9500',
                        item_path: '/cluster_1',
                        item_spec: 'controller',
                        item_type: 'Node',
                    },
                    {
                        class_name: 'CmonTreeItem',
                        owner_user_id: 5,
                        owner_user_name: 'pablo',
                        owner_group_id: 1,
                        owner_group_name: 'admins',
                        effective_privileges: 'rwx',
                        item_acl: 'user::rwx,group::r--,other::r--',
                        item_name: '.runtime',
                        item_path: '/cluster_1',
                        item_type: 'Folder',
                        sub_items: [
                            {
                                class_name: 'CmonTreeItem',
                                owner_user_id: 5,
                                owner_user_name: 'pablo',
                                owner_group_id: 1,
                                owner_group_name: 'admins',
                                effective_privileges: 'rwx',
                                item_acl: 'user::r--,group::---,other::---',
                                item_name: 'cpu_collector',
                                item_path: '/cluster_1/.runtime',
                                item_type: 'File',
                                major_device_number: 74,
                                minor_devide_number: 1,
                            },
                            {
                                class_name: 'CmonTreeItem',
                                owner_user_id: 5,
                                owner_user_name: 'pablo',
                                owner_group_id: 1,
                                owner_group_name: 'admins',
                                effective_privileges: 'rwx',
                                item_acl: 'user::r--,group::---,other::---',
                                item_name: 'mem_collector',
                                item_path: '/cluster_1/.runtime',
                                item_type: 'File',
                                major_device_number: 75,
                                minor_devide_number: 1,
                            },
                            {
                                class_name: 'CmonTreeItem',
                                owner_user_id: 5,
                                owner_user_name: 'pablo',
                                owner_group_id: 1,
                                owner_group_name: 'admins',
                                effective_privileges: 'rwx',
                                item_acl: 'user::r--,group::---,other::---',
                                item_name: 'net_collector',
                                item_path: '/cluster_1/.runtime',
                                item_type: 'File',
                                major_device_number: 73,
                                minor_devide_number: 1,
                            },
                            {
                                class_name: 'CmonTreeItem',
                                owner_user_id: 5,
                                owner_user_name: 'pablo',
                                owner_group_id: 1,
                                owner_group_name: 'admins',
                                effective_privileges: 'rwx',
                                item_acl: 'user::r--,group::---,other::---',
                                item_name: 'os_collector',
                                item_path: '/cluster_1/.runtime',
                                item_type: 'File',
                                major_device_number: 76,
                                minor_devide_number: 1,
                            },
                            {
                                class_name: 'CmonTreeItem',
                                owner_user_id: 5,
                                owner_user_name: 'pablo',
                                owner_group_id: 1,
                                owner_group_name: 'admins',
                                effective_privileges: 'rwx',
                                item_acl: 'user::r--,group::---,other::---',
                                item_name: 'proc_collector',
                                item_path: '/cluster_1/.runtime',
                                item_type: 'File',
                                major_device_number: 72,
                                minor_devide_number: 1,
                            },
                            {
                                class_name: 'CmonTreeItem',
                                owner_user_id: 5,
                                owner_user_name: 'pablo',
                                owner_group_id: 1,
                                owner_group_name: 'admins',
                                effective_privileges: 'rwx',
                                item_acl: 'user::r--,group::---,other::---',
                                item_name: 'sql_collector',
                                item_path: '/cluster_1/.runtime',
                                item_type: 'File',
                                major_device_number: 71,
                                minor_devide_number: 1,
                            },
                            {
                                class_name: 'CmonTreeItem',
                                owner_user_id: 5,
                                owner_user_name: 'pablo',
                                owner_group_id: 1,
                                owner_group_name: 'admins',
                                effective_privileges: 'rwx',
                                item_acl: 'user::r--,group::---,other::---',
                                item_name: 'state',
                                item_path: '/cluster_1/.runtime',
                                item_type: 'File',
                                major_device_number: 70,
                                minor_devide_number: 1,
                            },
                            {
                                class_name: 'CmonTreeItem',
                                owner_user_id: 5,
                                owner_user_name: 'pablo',
                                owner_group_id: 1,
                                owner_group_name: 'admins',
                                effective_privileges: 'rwx',
                                item_acl: 'user::r--,group::---,other::---',
                                item_name: 'tcp_collector',
                                item_path: '/cluster_1/.runtime',
                                item_type: 'File',
                                major_device_number: 77,
                                minor_devide_number: 1,
                            },
                        ],
                    },
                    {
                        class_name: 'CmonTreeItem',
                        owner_user_id: 5,
                        owner_user_name: 'pablo',
                        owner_group_id: 1,
                        owner_group_name: 'admins',
                        effective_privileges: 'rwx',
                        item_acl: 'user::rwx,group::rwx,other::rwx',
                        item_name: 'databases',
                        item_path: '/cluster_1',
                        item_type: 'Folder',
                        sub_items: [
                            {
                                class_name: 'CmonTreeItem',
                                owner_user_id: 5,
                                owner_user_name: 'pablo',
                                owner_group_id: 1,
                                owner_group_name: 'admins',
                                effective_privileges: 'rwx',
                                item_acl: 'user::rwx,group::rwx,other::---',
                                item_name: 'sys',
                                item_path: '/cluster_1/databases',
                                item_spec: '',
                                item_type: 'Database',
                            },
                        ],
                    },
                ],
            },
            {
                class_name: 'CmonTreeItem',
                owner_user_id: 5,
                owner_user_name: 'pablo',
                owner_group_id: 1,
                owner_group_name: 'admins',
                effective_privileges: 'rwx',
                item_acl:
                    'user::rwx,group::rwx,group:groupFullAccess:rwx,group:groupViewAccess:r--,group:groupNoAccess:---,group:groupCM1V2Access:r--,group:nobody:rwx,group:newgroups:rwx,group:newgroup:rwx,other::---',
                item_name: 'cluster_2',
                item_path: '/',
                item_type: 'Cluster',
                sub_items: [
                    {
                        class_name: 'CmonTreeItem',
                        owner_user_id: 5,
                        owner_user_name: 'pablo',
                        owner_group_id: 1,
                        owner_group_name: 'admins',
                        effective_privileges: 'rwx',
                        item_acl: 'user::rwx,group::rwx,other::---',
                        item_name: '10.0.8.13:3306',
                        item_path: '/cluster_2',
                        item_spec: 'master',
                        item_type: 'Node',
                    },
                    {
                        class_name: 'CmonTreeItem',
                        owner_user_id: 5,
                        owner_user_name: 'pablo',
                        owner_group_id: 1,
                        owner_group_name: 'admins',
                        effective_privileges: 'rwx',
                        item_acl: 'user::rwx,group::rwx,other::---',
                        item_name: '134.122.121.181:9090',
                        item_path: '/cluster_2',
                        item_spec: '',
                        item_type: 'Node',
                    },
                    {
                        class_name: 'CmonTreeItem',
                        owner_user_id: 5,
                        owner_user_name: 'pablo',
                        owner_group_id: 1,
                        owner_group_name: 'admins',
                        effective_privileges: 'rwx',
                        item_acl: 'user::rwx,group::rwx,other::---',
                        item_name: '134.122.121.181:9500',
                        item_path: '/cluster_2',
                        item_spec: 'controller',
                        item_type: 'Node',
                    },
                    {
                        class_name: 'CmonTreeItem',
                        owner_user_id: 5,
                        owner_user_name: 'pablo',
                        owner_group_id: 1,
                        owner_group_name: 'admins',
                        effective_privileges: 'rwx',
                        item_acl: 'user::rwx,group::r--,other::r--',
                        item_name: '.runtime',
                        item_path: '/cluster_2',
                        item_type: 'Folder',
                        sub_items: [
                            {
                                class_name: 'CmonTreeItem',
                                owner_user_id: 5,
                                owner_user_name: 'pablo',
                                owner_group_id: 1,
                                owner_group_name: 'admins',
                                effective_privileges: 'rwx',
                                item_acl: 'user::r--,group::---,other::---',
                                item_name: 'cpu_collector',
                                item_path: '/cluster_2/.runtime',
                                item_type: 'File',
                                major_device_number: 74,
                                minor_devide_number: 2,
                            },
                            {
                                class_name: 'CmonTreeItem',
                                owner_user_id: 5,
                                owner_user_name: 'pablo',
                                owner_group_id: 1,
                                owner_group_name: 'admins',
                                effective_privileges: 'rwx',
                                item_acl: 'user::r--,group::---,other::---',
                                item_name: 'mem_collector',
                                item_path: '/cluster_2/.runtime',
                                item_type: 'File',
                                major_device_number: 75,
                                minor_devide_number: 2,
                            },
                            {
                                class_name: 'CmonTreeItem',
                                owner_user_id: 5,
                                owner_user_name: 'pablo',
                                owner_group_id: 1,
                                owner_group_name: 'admins',
                                effective_privileges: 'rwx',
                                item_acl: 'user::r--,group::---,other::---',
                                item_name: 'net_collector',
                                item_path: '/cluster_2/.runtime',
                                item_type: 'File',
                                major_device_number: 73,
                                minor_devide_number: 2,
                            },
                            {
                                class_name: 'CmonTreeItem',
                                owner_user_id: 5,
                                owner_user_name: 'pablo',
                                owner_group_id: 1,
                                owner_group_name: 'admins',
                                effective_privileges: 'rwx',
                                item_acl: 'user::r--,group::---,other::---',
                                item_name: 'os_collector',
                                item_path: '/cluster_2/.runtime',
                                item_type: 'File',
                                major_device_number: 76,
                                minor_devide_number: 2,
                            },
                            {
                                class_name: 'CmonTreeItem',
                                owner_user_id: 5,
                                owner_user_name: 'pablo',
                                owner_group_id: 1,
                                owner_group_name: 'admins',
                                effective_privileges: 'rwx',
                                item_acl: 'user::r--,group::---,other::---',
                                item_name: 'proc_collector',
                                item_path: '/cluster_2/.runtime',
                                item_type: 'File',
                                major_device_number: 72,
                                minor_devide_number: 2,
                            },
                            {
                                class_name: 'CmonTreeItem',
                                owner_user_id: 5,
                                owner_user_name: 'pablo',
                                owner_group_id: 1,
                                owner_group_name: 'admins',
                                effective_privileges: 'rwx',
                                item_acl: 'user::r--,group::---,other::---',
                                item_name: 'sql_collector',
                                item_path: '/cluster_2/.runtime',
                                item_type: 'File',
                                major_device_number: 71,
                                minor_devide_number: 2,
                            },
                            {
                                class_name: 'CmonTreeItem',
                                owner_user_id: 5,
                                owner_user_name: 'pablo',
                                owner_group_id: 1,
                                owner_group_name: 'admins',
                                effective_privileges: 'rwx',
                                item_acl: 'user::r--,group::---,other::---',
                                item_name: 'state',
                                item_path: '/cluster_2/.runtime',
                                item_type: 'File',
                                major_device_number: 70,
                                minor_devide_number: 2,
                            },
                            {
                                class_name: 'CmonTreeItem',
                                owner_user_id: 5,
                                owner_user_name: 'pablo',
                                owner_group_id: 1,
                                owner_group_name: 'admins',
                                effective_privileges: 'rwx',
                                item_acl: 'user::r--,group::---,other::---',
                                item_name: 'tcp_collector',
                                item_path: '/cluster_2/.runtime',
                                item_type: 'File',
                                major_device_number: 77,
                                minor_devide_number: 2,
                            },
                        ],
                    },
                    {
                        class_name: 'CmonTreeItem',
                        owner_user_id: 5,
                        owner_user_name: 'pablo',
                        owner_group_id: 1,
                        owner_group_name: 'admins',
                        effective_privileges: 'rwx',
                        item_acl: 'user::rwx,group::rwx,other::rwx',
                        item_name: 'databases',
                        item_path: '/cluster_2',
                        item_type: 'Folder',
                        sub_items: [
                            {
                                class_name: 'CmonTreeItem',
                                owner_user_id: 5,
                                owner_user_name: 'pablo',
                                owner_group_id: 1,
                                owner_group_name: 'admins',
                                effective_privileges: 'rwx',
                                item_acl: 'user::rwx,group::rwx,other::---',
                                item_name: 'sys',
                                item_path: '/cluster_2/databases',
                                item_spec: '',
                                item_type: 'Database',
                            },
                        ],
                    },
                ],
            },
            {
                class_name: 'CmonTreeItem',
                owner_user_id: 1,
                owner_user_name: 'system',
                owner_group_id: 1,
                owner_group_name: 'admins',
                effective_privileges: 'rwx',
                item_acl: 'user::rwx,group::rwx,other::rwx',
                item_name: 'localhost',
                item_path: '/',
                item_spec: '',
                item_type: 'Server',
                sub_items: [
                    {
                        class_name: 'CmonTreeItem',
                        owner_user_id: 1,
                        owner_user_name: 'system',
                        owner_group_id: 1,
                        owner_group_name: 'admins',
                        effective_privileges: 'rwx',
                        item_acl: 'user::rwx,group::r--,other::r--',
                        item_name: '.runtime',
                        item_path: '/localhost',
                        item_type: 'Folder',
                        sub_items: [
                            {
                                class_name: 'CmonTreeItem',
                                owner_user_id: 1,
                                owner_user_name: 'system',
                                owner_group_id: 1,
                                owner_group_name: 'admins',
                                effective_privileges: 'rwx',
                                item_acl: 'user::rwx,group::r--,other::r--',
                                item_name: 'images.csv',
                                item_path: '/localhost/.runtime',
                                item_type: 'File',
                                major_device_number: 7,
                                minor_devide_number: 1,
                            },
                            {
                                class_name: 'CmonTreeItem',
                                owner_user_id: 1,
                                owner_user_name: 'system',
                                owner_group_id: 1,
                                owner_group_name: 'admins',
                                effective_privileges: 'rwx',
                                item_acl: 'user::rwx,group::r--,other::r--',
                                item_name: 'nics.csv',
                                item_path: '/localhost/.runtime',
                                item_type: 'File',
                                major_device_number: 6,
                                minor_devide_number: 1,
                            },
                            {
                                class_name: 'CmonTreeItem',
                                owner_user_id: 1,
                                owner_user_name: 'system',
                                owner_group_id: 1,
                                owner_group_name: 'admins',
                                effective_privileges: 'rwx',
                                item_acl: 'user::rwx,group::r--,other::r--',
                                item_name: 'regions.csv',
                                item_path: '/localhost/.runtime',
                                item_type: 'File',
                                major_device_number: 8,
                                minor_devide_number: 1,
                            },
                            {
                                class_name: 'CmonTreeItem',
                                owner_user_id: 1,
                                owner_user_name: 'system',
                                owner_group_id: 1,
                                owner_group_name: 'admins',
                                effective_privileges: 'rwx',
                                item_acl: 'user::rwx,group::r--,other::r--',
                                item_name: 'state',
                                item_path: '/localhost/.runtime',
                                item_type: 'File',
                                major_device_number: 5,
                                minor_devide_number: 1,
                            },
                        ],
                    },
                    {
                        class_name: 'CmonTreeItem',
                        owner_user_id: 1,
                        owner_user_name: 'system',
                        owner_group_id: 1,
                        owner_group_name: 'admins',
                        effective_privileges: 'rwx',
                        item_acl: 'user::rwx,group::r--,other::r--',
                        item_name: 'containers',
                        item_path: '/localhost',
                        item_type: 'Folder',
                        major_device_number: 3,
                        minor_devide_number: 1,
                    },
                ],
            },
            {
                class_name: 'CmonTreeItem',
                owner_user_id: 1,
                owner_user_name: 'system',
                owner_group_id: 1,
                owner_group_name: 'admins',
                effective_privileges: 'rwx',
                item_acl: 'user::rwx,group::rwx,other::r--',
                item_name: '.runtime',
                item_path: '/',
                item_type: 'Folder',
                sub_items: [
                    {
                        class_name: 'CmonTreeItem',
                        owner_user_id: 1,
                        owner_user_name: 'system',
                        owner_group_id: 1,
                        owner_group_name: 'admins',
                        effective_privileges: 'rwx',
                        item_acl: 'user::rwx,group::rwx,other::r--',
                        item_name: 'LDAP',
                        item_path: '/.runtime',
                        item_type: 'Folder',
                        sub_items: [
                            {
                                class_name: 'CmonTreeItem',
                                owner_user_id: 1,
                                owner_user_name: 'system',
                                owner_group_id: 1,
                                owner_group_name: 'admins',
                                effective_privileges: 'rwx',
                                item_acl:
                                    'user::rw-,group::---,group:groupFullAccess:rwx,group:groupViewAccess:---,group:groupNoAccess:---,group:groupCM1V2Access:---,other::---',
                                item_name: 'configuration',
                                item_path: '/.runtime/LDAP',
                                item_type: 'File',
                                major_device_number: 120,
                                minor_devide_number: 4,
                            },
                        ],
                    },
                    {
                        class_name: 'CmonTreeItem',
                        owner_user_id: 1,
                        owner_user_name: 'system',
                        owner_group_id: 1,
                        owner_group_name: 'admins',
                        effective_privileges: 'rwx',
                        item_acl: 'user::rwx,group::rwx,other::r--',
                        item_name: 'cloud',
                        item_path: '/.runtime',
                        item_type: 'Folder',
                        sub_items: [
                            {
                                class_name: 'CmonTreeItem',
                                owner_user_id: 1,
                                owner_user_name: 'system',
                                owner_group_id: 1,
                                owner_group_name: 'admins',
                                effective_privileges: 'rwx',
                                item_acl: 'user::rw-,group::---,other::---',
                                item_name: 'credentials',
                                item_path: '/.runtime/cloud',
                                item_type: 'File',
                                major_device_number: 110,
                                minor_devide_number: 2,
                            },
                            {
                                class_name: 'CmonTreeItem',
                                owner_user_id: 1,
                                owner_user_name: 'system',
                                owner_group_id: 1,
                                owner_group_name: 'admins',
                                effective_privileges: 'rwx',
                                item_acl: 'user::r--,group::---,other::---',
                                item_name: 'credentials_manager',
                                item_path: '/.runtime/cloud',
                                item_type: 'File',
                                major_device_number: 110,
                                minor_devide_number: 1,
                            },
                        ],
                    },
                    {
                        class_name: 'CmonTreeItem',
                        owner_user_id: 1,
                        owner_user_name: 'system',
                        owner_group_id: 1,
                        owner_group_name: 'admins',
                        effective_privileges: 'rwx',
                        item_acl: 'user::rwx,group::rwx,other::r--',
                        item_name: 'cmon_ha',
                        item_path: '/.runtime',
                        item_type: 'Folder',
                        sub_items: [
                            {
                                class_name: 'CmonTreeItem',
                                owner_user_id: 1,
                                owner_user_name: 'system',
                                owner_group_id: 1,
                                owner_group_name: 'admins',
                                effective_privileges: 'rwx',
                                item_acl: 'user::r--,group::---,other::---',
                                item_name: 'controller_data',
                                item_path: '/.runtime/cmon_ha',
                                item_type: 'File',
                                major_device_number: 100,
                                minor_devide_number: 2,
                            },
                            {
                                class_name: 'CmonTreeItem',
                                owner_user_id: 1,
                                owner_user_name: 'system',
                                owner_group_id: 1,
                                owner_group_name: 'admins',
                                effective_privileges: 'rwx',
                                item_acl: 'user::r--,group::---,other::---',
                                item_name: 'controller_manager',
                                item_path: '/.runtime/cmon_ha',
                                item_type: 'File',
                                major_device_number: 100,
                                minor_devide_number: 1,
                            },
                            {
                                class_name: 'CmonTreeItem',
                                owner_user_id: 1,
                                owner_user_name: 'system',
                                owner_group_id: 1,
                                owner_group_name: 'admins',
                                effective_privileges: 'rwx',
                                item_acl: 'user::rw-,group::---,other::---',
                                item_name: 'enabled',
                                item_path: '/.runtime/cmon_ha',
                                item_type: 'File',
                                major_device_number: 100,
                                minor_devide_number: 3,
                            },
                            {
                                class_name: 'CmonTreeItem',
                                owner_user_id: 1,
                                owner_user_name: 'system',
                                owner_group_id: 1,
                                owner_group_name: 'admins',
                                effective_privileges: 'rwx',
                                item_acl: 'user::r--,group::---,other::---',
                                item_name: 'self_controller',
                                item_path: '/.runtime/cmon_ha',
                                item_type: 'File',
                                major_device_number: 101,
                                minor_devide_number: 2,
                            },
                        ],
                    },
                    {
                        class_name: 'CmonTreeItem',
                        owner_user_id: 1,
                        owner_user_name: 'system',
                        owner_group_id: 1,
                        owner_group_name: 'admins',
                        effective_privileges: 'rwx',
                        item_acl: 'user::rwx,group::rwx,other::r--',
                        item_name: 'files',
                        item_path: '/.runtime',
                        item_type: 'Folder',
                        sub_items: [
                            {
                                class_name: 'CmonTreeItem',
                                owner_user_id: 1,
                                owner_user_name: 'system',
                                owner_group_id: 1,
                                owner_group_name: 'admins',
                                effective_privileges: 'rwx',
                                item_acl: 'user::r--,group::---,other::---',
                                item_name: 'config_files.csv',
                                item_path: '/.runtime/files',
                                item_type: 'File',
                                major_device_number: 121,
                                minor_devide_number: 5,
                            },
                            {
                                class_name: 'CmonTreeItem',
                                owner_user_id: 1,
                                owner_user_name: 'system',
                                owner_group_id: 1,
                                owner_group_name: 'admins',
                                effective_privileges: 'rwx',
                                item_acl: 'user::r--,group::---,other::---',
                                item_name: 'config_versions.csv',
                                item_path: '/.runtime/files',
                                item_type: 'File',
                                major_device_number: 121,
                                minor_devide_number: 2,
                            },
                        ],
                    },
                    {
                        class_name: 'CmonTreeItem',
                        owner_user_id: 1,
                        owner_user_name: 'system',
                        owner_group_id: 1,
                        owner_group_name: 'admins',
                        effective_privileges: 'rwx',
                        item_acl: 'user::rwx,group::rwx,other::r--',
                        item_name: 'jobs',
                        item_path: '/.runtime',
                        item_type: 'Folder',
                        sub_items: [
                            {
                                class_name: 'CmonTreeItem',
                                owner_user_id: 1,
                                owner_user_name: 'system',
                                owner_group_id: 2,
                                owner_group_name: 'users',
                                effective_privileges: 'rwx',
                                item_acl: 'user::rwx,group::r-x,other::---',
                                item_name: 'jobExecutor',
                                item_path: '/.runtime/jobs',
                                item_type: 'File',
                                size: 0,
                            },
                            {
                                class_name: 'CmonTreeItem',
                                owner_user_id: 1,
                                owner_user_name: 'system',
                                owner_group_id: 2,
                                owner_group_name: 'users',
                                effective_privileges: 'rwx',
                                item_acl:
                                    'user::rwx,group::r-x,group:admins:r-x,group:groupFullAccess:rwx,group:groupViewAccess:---,group:groupNoAccess:---,group:groupCM1V2Access:rwx,other::---',
                                item_name: 'jobExecutorCreateCluster',
                                item_path: '/.runtime/jobs',
                                item_type: 'File',
                                size: 0,
                            },
                            {
                                class_name: 'CmonTreeItem',
                                owner_user_id: 1,
                                owner_user_name: 'system',
                                owner_group_id: 2,
                                owner_group_name: 'users',
                                effective_privileges: 'rwx',
                                item_acl: 'user::rwx,group::r-x,other::---',
                                item_name: 'jobExecutorDeleteOldJobs',
                                item_path: '/.runtime/jobs',
                                item_type: 'File',
                                size: 1302,
                            },
                            {
                                class_name: 'CmonTreeItem',
                                owner_user_id: 1,
                                owner_user_name: 'system',
                                owner_group_id: 1,
                                owner_group_name: 'admins',
                                effective_privileges: 'rwx',
                                item_acl: 'user::r--,group::---,other::---',
                                item_name: 'job_manager',
                                item_path: '/.runtime/jobs',
                                item_type: 'File',
                                major_device_number: 40,
                                minor_devide_number: 1,
                            },
                        ],
                    },
                    {
                        class_name: 'CmonTreeItem',
                        owner_user_id: 1,
                        owner_user_name: 'system',
                        owner_group_id: 1,
                        owner_group_name: 'admins',
                        effective_privileges: 'rwx',
                        item_acl: 'user::rwx,group::rwx,other::r--',
                        item_name: 'rpc',
                        item_path: '/.runtime',
                        item_type: 'Folder',
                        sub_items: [
                            {
                                class_name: 'CmonTreeItem',
                                owner_user_id: 1,
                                owner_user_name: 'system',
                                owner_group_id: 1,
                                owner_group_name: 'admins',
                                effective_privileges: 'rwx',
                                item_acl: 'user::r--,group::---,other::---',
                                item_name: 'rpc_stats',
                                item_path: '/.runtime/rpc',
                                item_type: 'File',
                                major_device_number: 91,
                                minor_devide_number: 1,
                            },
                            {
                                class_name: 'CmonTreeItem',
                                owner_user_id: 1,
                                owner_user_name: 'system',
                                owner_group_id: 1,
                                owner_group_name: 'admins',
                                effective_privileges: 'rwx',
                                item_acl: 'user::r--,group::---,other::---',
                                item_name: 'rpcv2_service',
                                item_path: '/.runtime/rpc',
                                item_type: 'File',
                                major_device_number: 90,
                                minor_devide_number: 1,
                            },
                            {
                                class_name: 'CmonTreeItem',
                                owner_user_id: 1,
                                owner_user_name: 'system',
                                owner_group_id: 1,
                                owner_group_name: 'admins',
                                effective_privileges: 'rwx',
                                item_acl: 'user::r--,group::---,other::---',
                                item_name: 'sessions',
                                item_path: '/.runtime/rpc',
                                item_type: 'File',
                                major_device_number: 92,
                                minor_devide_number: 1,
                            },
                        ],
                    },
                    {
                        class_name: 'CmonTreeItem',
                        owner_user_id: 1,
                        owner_user_name: 'system',
                        owner_group_id: 1,
                        owner_group_name: 'admins',
                        effective_privileges: 'rwx',
                        item_acl: 'user::rwx,group::rwx,other::r--',
                        item_name: 'ui',
                        item_path: '/.runtime',
                        item_type: 'Folder',
                        sub_items: [
                            {
                                class_name: 'CmonTreeItem',
                                owner_user_id: 1,
                                owner_user_name: 'system',
                                owner_group_id: 1,
                                owner_group_name: 'admins',
                                effective_privileges: 'rwx',
                                item_acl:
                                    'user::rwx,group::rwx,group:groupFullAccess:rwx,group:groupViewAccess:rwx,group:groupNoAccess:---,group:groupCM1V2Access:---,other::---',
                                item_name: 'configuration',
                                item_path: '/.runtime/ui',
                                item_type: 'File',
                            },
                        ],
                    },
                    {
                        class_name: 'CmonTreeItem',
                        owner_user_id: 1,
                        owner_user_name: 'system',
                        owner_group_id: 1,
                        owner_group_name: 'admins',
                        effective_privileges: 'rwx',
                        item_acl: 'user::r--,group::---,other::---',
                        item_name: 'cluster_manager',
                        item_path: '/.runtime',
                        item_type: 'File',
                        major_device_number: 10,
                        minor_devide_number: 1,
                    },
                    {
                        class_name: 'CmonTreeItem',
                        owner_user_id: 1,
                        owner_user_name: 'system',
                        owner_group_id: 1,
                        owner_group_name: 'admins',
                        effective_privileges: 'rwx',
                        item_acl: 'user::rw-,group::---,other::---',
                        item_name: 'cmon_license',
                        item_path: '/.runtime',
                        item_type: 'File',
                        major_device_number: 111,
                        minor_devide_number: 2,
                    },
                    {
                        class_name: 'CmonTreeItem',
                        owner_user_id: 1,
                        owner_user_name: 'system',
                        owner_group_id: 1,
                        owner_group_name: 'admins',
                        effective_privileges: 'rwx',
                        item_acl: 'user::r--,group::---,other::---',
                        item_name: 'config_manager',
                        item_path: '/.runtime',
                        item_type: 'File',
                        major_device_number: 121,
                        minor_devide_number: 1,
                    },
                    {
                        class_name: 'CmonTreeItem',
                        owner_user_id: 1,
                        owner_user_name: 'system',
                        owner_group_id: 1,
                        owner_group_name: 'admins',
                        effective_privileges: 'rwx',
                        item_acl: 'user::r--,group::---,other::---',
                        item_name: 'controller',
                        item_path: '/.runtime',
                        item_type: 'File',
                        major_device_number: 99,
                        minor_devide_number: 1,
                    },
                    {
                        class_name: 'CmonTreeItem',
                        owner_user_id: 1,
                        owner_user_name: 'system',
                        owner_group_id: 1,
                        owner_group_name: 'admins',
                        effective_privileges: 'rwx',
                        item_acl: 'user::r--,group::---,other::---',
                        item_name: 'controller_clock',
                        item_path: '/.runtime',
                        item_type: 'File',
                        size: 24,
                    },
                    {
                        class_name: 'CmonTreeItem',
                        owner_user_id: 1,
                        owner_user_name: 'system',
                        owner_group_id: 1,
                        owner_group_name: 'admins',
                        effective_privileges: 'rwx',
                        item_acl: 'user::r--,group::---,other::---',
                        item_name: 'controller_hostname',
                        item_path: '/.runtime',
                        item_type: 'File',
                        size: 17,
                    },
                    {
                        class_name: 'CmonTreeItem',
                        owner_user_id: 1,
                        owner_user_name: 'system',
                        owner_group_id: 1,
                        owner_group_name: 'admins',
                        effective_privileges: 'rwx',
                        item_acl: 'user::r--,group::---,other::---',
                        item_name: 'controller_pid',
                        item_path: '/.runtime',
                        item_type: 'File',
                        size: 6,
                    },
                    {
                        class_name: 'CmonTreeItem',
                        owner_user_id: 1,
                        owner_user_name: 'system',
                        owner_group_id: 1,
                        owner_group_name: 'admins',
                        effective_privileges: 'rwx',
                        item_acl: 'user::r--,group::---,other::---',
                        item_name: 'host_manager',
                        item_path: '/.runtime',
                        item_type: 'File',
                        major_device_number: 20,
                        minor_devide_number: 1,
                    },
                    {
                        class_name: 'CmonTreeItem',
                        owner_user_id: 1,
                        owner_user_name: 'system',
                        owner_group_id: 1,
                        owner_group_name: 'admins',
                        effective_privileges: 'rwx',
                        item_acl: 'user::r--,group::---,other::---',
                        item_name: 'mutexes',
                        item_path: '/.runtime',
                        item_type: 'File',
                        major_device_number: 50,
                        minor_devide_number: 1,
                    },
                    {
                        class_name: 'CmonTreeItem',
                        owner_user_id: 1,
                        owner_user_name: 'system',
                        owner_group_id: 1,
                        owner_group_name: 'admins',
                        effective_privileges: 'rwx',
                        item_acl: 'user::r--,group::---,other::---',
                        item_name: 'process_manager',
                        item_path: '/.runtime',
                        item_type: 'File',
                        major_device_number: 41,
                        minor_devide_number: 1,
                    },
                    {
                        class_name: 'CmonTreeItem',
                        owner_user_id: 1,
                        owner_user_name: 'system',
                        owner_group_id: 1,
                        owner_group_name: 'admins',
                        effective_privileges: 'rwx',
                        item_acl: 'user::r--,group::---,other::---',
                        item_name: 'server_manager',
                        item_path: '/.runtime',
                        item_type: 'File',
                        major_device_number: 4,
                        minor_devide_number: 1,
                    },
                    {
                        class_name: 'CmonTreeItem',
                        owner_user_id: 1,
                        owner_user_name: 'system',
                        owner_group_id: 1,
                        owner_group_name: 'admins',
                        effective_privileges: 'rwx',
                        item_acl: 'user::r--,group::---,other::---',
                        item_name: 'threads',
                        item_path: '/.runtime',
                        item_type: 'File',
                        major_device_number: 60,
                        minor_devide_number: 1,
                    },
                    {
                        class_name: 'CmonTreeItem',
                        owner_user_id: 1,
                        owner_user_name: 'system',
                        owner_group_id: 1,
                        owner_group_name: 'admins',
                        effective_privileges: 'rwx',
                        item_acl:
                            'user::r--,group::---,group:groupFullAccess:rwx,group:groupViewAccess:---,group:groupNoAccess:---,group:groupCM1V2Access:---,other::---',
                        item_name: 'user_manager',
                        item_path: '/.runtime',
                        item_type: 'File',
                        major_device_number: 30,
                        minor_devide_number: 1,
                    },
                ],
            },
            {
                class_name: 'CmonTreeItem',
                owner_user_id: 1,
                owner_user_name: 'system',
                owner_group_id: 1,
                owner_group_name: 'admins',
                effective_privileges: 'rwx',
                item_acl: 'user::rwx,group::rwx,other::r--',
                item_name: 'groups',
                item_path: '/',
                item_type: 'Folder',
                major_device_number: 1,
                minor_devide_number: 0,
                sub_items: [
                    {
                        class_name: 'CmonTreeItem',
                        owner_user_id: 1,
                        owner_user_name: 'system',
                        owner_group_id: 1,
                        owner_group_name: 'admins',
                        effective_privileges: 'rwx',
                        item_acl: 'user::rwx,group::rwx,other::---',
                        item_name: 'admins',
                        item_path: '/groups',
                        item_type: 'Group',
                    },
                    {
                        class_name: 'CmonTreeItem',
                        owner_user_id: 5,
                        owner_user_name: 'pablo',
                        owner_group_id: 1,
                        owner_group_name: 'admins',
                        effective_privileges: 'rwx',
                        item_acl: 'user::rwx,group::rwx,other::---',
                        item_name: 'groupCM1V2Access',
                        item_path: '/groups',
                        item_type: 'Group',
                    },
                    {
                        class_name: 'CmonTreeItem',
                        owner_user_id: 5,
                        owner_user_name: 'pablo',
                        owner_group_id: 1,
                        owner_group_name: 'admins',
                        effective_privileges: 'rwx',
                        item_acl: 'user::rwx,group::rwx,other::---',
                        item_name: 'groupFullAccess',
                        item_path: '/groups',
                        item_type: 'Group',
                    },
                    {
                        class_name: 'CmonTreeItem',
                        owner_user_id: 5,
                        owner_user_name: 'pablo',
                        owner_group_id: 1,
                        owner_group_name: 'admins',
                        effective_privileges: 'rwx',
                        item_acl: 'user::rwx,group::rwx,other::---',
                        item_name: 'groupNoAccess',
                        item_path: '/groups',
                        item_type: 'Group',
                    },
                    {
                        class_name: 'CmonTreeItem',
                        owner_user_id: 5,
                        owner_user_name: 'pablo',
                        owner_group_id: 1,
                        owner_group_name: 'admins',
                        effective_privileges: 'rwx',
                        item_acl: 'user::rwx,group::rwx,other::---',
                        item_name: 'groupViewAccess',
                        item_path: '/groups',
                        item_type: 'Group',
                    },
                    {
                        class_name: 'CmonTreeItem',
                        owner_user_id: 5,
                        owner_user_name: 'pablo',
                        owner_group_id: 1,
                        owner_group_name: 'admins',
                        effective_privileges: 'rwx',
                        item_acl: 'user::rwx,group::rwx,other::---',
                        item_name: 'newgroup',
                        item_path: '/groups',
                        item_type: 'Group',
                    },
                    {
                        class_name: 'CmonTreeItem',
                        owner_user_id: 5,
                        owner_user_name: 'pablo',
                        owner_group_id: 1,
                        owner_group_name: 'admins',
                        effective_privileges: 'rwx',
                        item_acl: 'user::rwx,group::rwx,other::---',
                        item_name: 'newgroups',
                        item_path: '/groups',
                        item_type: 'Group',
                    },
                    {
                        class_name: 'CmonTreeItem',
                        owner_user_id: 1,
                        owner_user_name: 'system',
                        owner_group_id: 1,
                        owner_group_name: 'admins',
                        effective_privileges: 'rwx',
                        item_acl: 'user::rwx,group::rwx,other::---',
                        item_name: 'nobody',
                        item_path: '/groups',
                        item_type: 'Group',
                    },
                    {
                        class_name: 'CmonTreeItem',
                        owner_user_id: 1,
                        owner_user_name: 'system',
                        owner_group_id: 1,
                        owner_group_name: 'admins',
                        effective_privileges: 'rwx',
                        item_acl: 'user::rwx,group::rwx,other::---',
                        item_name: 'users',
                        item_path: '/groups',
                        item_type: 'Group',
                    },
                ],
            },
            {
                class_name: 'CmonTreeItem',
                owner_user_id: 1,
                owner_user_name: 'system',
                owner_group_id: 1,
                owner_group_name: 'admins',
                effective_privileges: 'rwx',
                item_acl: 'user::rw-,group::r--,other::r--',
                item_name: '.issue',
                item_path: '/',
                item_type: 'File',
                size: 36,
            },
            {
                class_name: 'CmonTreeItem',
                owner_user_id: 3,
                owner_user_name: 'admin',
                owner_group_id: 1,
                owner_group_name: 'admins',
                effective_privileges: 'rwx',
                item_acl: 'user::rwx,group::r--,other::r--',
                item_name: 'admin',
                item_path: '/',
                item_spec: 'Default User',
                item_type: 'User',
            },
            {
                class_name: 'CmonTreeItem',
                owner_user_id: 4,
                owner_user_name: 'ccrpc',
                owner_group_id: 1,
                owner_group_name: 'admins',
                effective_privileges: 'rwx',
                item_acl: 'user::rwx,group::r--,other::r--',
                item_name: 'ccrpc',
                item_path: '/',
                item_spec: 'RPC API',
                item_type: 'User',
            },
            {
                class_name: 'CmonTreeItem',
                owner_user_id: 2,
                owner_user_name: 'nobody',
                owner_group_id: 1,
                owner_group_name: 'admins',
                effective_privileges: 'rwx',
                item_acl: 'user::rwx,group::r--,other::r--',
                item_name: 'nobody',
                item_path: '/',
                item_spec: 'Default User',
                item_type: 'User',
            },
            {
                class_name: 'CmonTreeItem',
                owner_user_id: 5,
                owner_user_name: 'pablo',
                owner_group_id: 1,
                owner_group_name: 'admins',
                effective_privileges: 'rwx',
                item_acl: 'user::rwx,group::r--,other::r--',
                item_name: 'pablo',
                item_path: '/',
                item_spec: '',
                item_type: 'User',
            },
            {
                class_name: 'CmonTreeItem',
                owner_user_id: 1,
                owner_user_name: 'system',
                owner_group_id: 1,
                owner_group_name: 'admins',
                effective_privileges: 'rwx',
                item_acl: 'user::rwx,group::r--,other::r--',
                item_name: 'system',
                item_path: '/',
                item_spec: 'System User',
                item_type: 'User',
            },
            {
                class_name: 'CmonTreeItem',
                owner_user_id: 18,
                owner_user_name: 'userCM1V2Access',
                owner_group_id: 1,
                owner_group_name: 'admins',
                effective_privileges: 'rwx',
                item_acl: 'user::rwx,group::r--,other::r--',
                item_name: 'userCM1V2Access',
                item_path: '/',
                item_spec: 'userCM1V2Access userCM1V2Access',
                item_type: 'User',
            },
            {
                class_name: 'CmonTreeItem',
                owner_user_id: 17,
                owner_user_name: 'userFullAccess',
                owner_group_id: 1,
                owner_group_name: 'admins',
                effective_privileges: 'rwx',
                item_acl: 'user::rwx,group::r--,other::r--',
                item_name: 'userFullAccess',
                item_path: '/',
                item_spec: 'userFullAccess userFullAccess',
                item_type: 'User',
            },
            {
                class_name: 'CmonTreeItem',
                owner_user_id: 16,
                owner_user_name: 'userNoAccess',
                owner_group_id: 1,
                owner_group_name: 'admins',
                effective_privileges: 'rwx',
                item_acl: 'user::rwx,group::r--,other::r--',
                item_name: 'userNoAccess',
                item_path: '/',
                item_spec: 'userNoAccess userNoAccess',
                item_type: 'User',
            },
            {
                class_name: 'CmonTreeItem',
                owner_user_id: 15,
                owner_user_name: 'userViewAccess',
                owner_group_id: 1,
                owner_group_name: 'admins',
                effective_privileges: 'rwx',
                item_acl: 'user::rwx,group::r--,other::r--',
                item_name: 'userViewAccess',
                item_path: '/',
                item_spec: 'userViewAccess userViewAccess',
                item_type: 'User',
            },
        ],
    }),
};

export default result;
