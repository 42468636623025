import React, { CSSProperties } from 'react';
import { FormInstance } from 'antd/lib/form';
import SpaceDescriptions, {
    SpaceDescriptionsProps,
} from '../../../common/Layout/SpaceDescriptions';
import {
    getCloudCredentialsField,
    getCloudCredentialsServiceFields,
} from './CloudCredentialsAuthenticationForm';
import { CcCloudProviderType } from '../../../services/models/CcCloudCredentials';

export default CloudCredentialsSummary;

export type CloudCredentialsSummaryProps = SpaceDescriptionsProps & {
    form: FormInstance;
    type: CcCloudProviderType;
    itemContentStyle?: CSSProperties;
};

function CloudCredentialsSummary({
    form,
    type,
    itemContentStyle = {},
    ...rest
}: CloudCredentialsSummaryProps) {
    const fields = getCloudCredentialsServiceFields(type);
    return fields ? (
        <SpaceDescriptions
            direction="vertical"
            title="Credentials"
            size="small"
            align="end"
            wide
            {...rest}
        >
            {fields.map((field) => {
                const { label, valueRender } = getCloudCredentialsField(
                    type,
                    field
                );
                const value = form.getFieldValue([type, field]);
                return (
                    <SpaceDescriptions.Item
                        key={field}
                        label={label}
                        labelNowrap
                        labelStrong
                        contentStyle={{ ...itemContentStyle }}
                    >
                        {(valueRender && valueRender(value)) || value || 'None'}
                    </SpaceDescriptions.Item>
                );
            })}
        </SpaceDescriptions>
    ) : null;
}

export function getCloudCredentialsSummary({
    type,
    form,
    ...rest
}: CloudCredentialsSummaryProps) {
    switch (type) {
        case CcCloudProviderType.GOOGLE:
        case CcCloudProviderType.AZURE:
            return (
                <CloudCredentialsSummary
                    form={form}
                    type={type}
                    itemContentStyle={{ width: 330, display: 'block' }}
                    {...rest}
                />
            );
        default:
            return <CloudCredentialsSummary form={form} type={type} />;
    }
}
