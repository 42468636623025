import MongoConfigurator, {
    MongoFormValues,
} from '../../Mongo/MongoConfigurator';
import merge from 'deepmerge';
import { ClusterConfiguratorFormValues } from '../../ClusterConfigurator';
import { RepositoryItemKey } from '../../../../../common/DataEntry/RepositoryInput';
import React from 'react';
import MongoNodeConfiguration, {
    MongoNodeConfigurationProps,
} from '../../Mongo/MongoNodeConfiguration';
import TopologySummary from '../../TopologySummary';
import { ServiceClusterWizardStep } from '../../ServiceClusterWizardStep';
import { FormInstance } from 'antd/lib/form';
import { WizardFormConfigurationStepProps } from '@severalnines/bar-frontend-components/build/lib/Navigation/Wizard/WizardFormConfiguration';
import NodeConfigurationSummary, {
    NodeConfigurationSummaryProps,
} from '../../NodeConfigurationSummary';
import SpaceDescriptions from '../../../../../common/Layout/SpaceDescriptions';
import ClusterNodesForm from '../../ClusterNodesForm';
import { TopologyItem } from '../../../../Topology/TopologyItem';
import { StatusFormatStatus } from '@severalnines/bar-frontend-components/build/lib/Format/StatusFormat';
import FormItemInlineSwitch from '../../../../../common/DataEntry/FormItemInlineSwitch';
import FormItem from '../../../../../common/DataEntry/FormItem';
import AppTooltip from '../../../../../common/Feedback/AppTooltip';

export default class MongoDeploymentConfigurator extends MongoConfigurator {
    public static getDefaults(): MongoFormValues {
        return merge(MongoConfigurator.getDefaults(), {
            details: {
                vendor: 'percona',
                version: '6.0',
            },
            nodeConfig: {
                repository: RepositoryItemKey.USE_VENDOR,
                sslEncryption: true,
            },
        });
    }

    public static getNodeConfigurationStep(
        props: MongoNodeConfigurationProps
    ): React.ReactNode {
        return <MongoNodeConfiguration hasSslEncryption={true} {...props} />;
    }

    public static getNodeConfigurationSummary(
        props: NodeConfigurationSummaryProps
    ): React.ReactNode {
        const form = props.form;
        const nodeConfig = form.getFieldValue('nodeConfig');
        return (
            <NodeConfigurationSummary
                hasSslEncryption={true}
                extra={
                    <SpaceDescriptions.Item label="ReplicaSet name" labelStrong>
                        {nodeConfig?.replicaSetName}
                    </SpaceDescriptions.Item>
                }
                {...props}
            />
        );
    }

    public static getTopologyStep(form: FormInstance): React.ReactNode {
        return (
            <ClusterNodesForm
                form={form}
                nodesInputProps={{
                    formProps: {
                        primaryExtra:
                            'Please note that an odd number of nodes is recommended, i.e., 3, 5, 7, etc.',
                    },
                    mutateItem: (item: TopologyItem) => {
                        const actAsArbiter = () => {
                            const topology = form.getFieldValue('topology');
                            const arbiterNode = form.getFieldValue(
                                'arbiterNode'
                            );
                            const nodesCount =
                                (topology &&
                                    topology.filter(
                                        (node: TopologyItem) =>
                                            !arbiterNode?.[
                                                node.extraData.hostname
                                            ]
                                    ).length) ||
                                0;
                            return nodesCount > 2 ||
                                !!arbiterNode?.[item.extraData.hostname] ? (
                                <FormItemInlineSwitch
                                    justify
                                    noMargin
                                    label="Act as an arbiter"
                                    name={[
                                        'arbiterNode',
                                        item.extraData.hostname,
                                    ]}
                                />
                            ) : (
                                <AppTooltip
                                    title={
                                        <span>
                                            At least one replica data node is
                                            required with an arbiter node
                                        </span>
                                    }
                                >
                                    <FormItemInlineSwitch
                                        justify
                                        noMargin
                                        disabled={true}
                                        label="Act as an arbiter"
                                        name={[
                                            'arbiterNode',
                                            item.extraData.hostname,
                                        ]}
                                    />
                                </AppTooltip>
                            );
                        };

                        return {
                            ...item,
                            footer:
                                item.status === StatusFormatStatus.success &&
                                item.type !== 'primary' ? (
                                    <FormItem
                                        shouldUpdate={true}
                                        noStyle={true}
                                    >
                                        {actAsArbiter}
                                    </FormItem>
                                ) : null,
                        };
                    },
                }}
            />
        );
    }

    public static getTopologySummary(form: FormInstance): React.ReactNode {
        return <TopologySummary form={form} />;
    }

    public static getJobOptions(
        formValues: ClusterConfiguratorFormValues
    ): any {
        return merge(MongoConfigurator.getJobOptions(formValues), {
            job: {
                title: 'Deploy Mongo ReplicaSet Cluster',
            },
        });
    }

    public static getDeploymentSteps(
        form: FormInstance,
        formValues?: MongoFormValues
    ): (
        | ServiceClusterWizardStep
        | [ServiceClusterWizardStep | string, WizardFormConfigurationStepProps]
    )[] {
        return [
            ServiceClusterWizardStep.DETAILS,
            ServiceClusterWizardStep.SSH_CONFIG,
            ServiceClusterWizardStep.NODE_CONFIG,
            ServiceClusterWizardStep.TOPOLOGY,
            ServiceClusterWizardStep.PREVIEW,
        ];
    }
}
