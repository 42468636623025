


export interface CmonProxySqlVariableProps  {
    class_name?: string;
    read_only?: string;
    variable_name?: string;
    variable_value?: string;

}



export default class CmonProxySqlVariable {
    public className?: string;
    public readOnly?: string;
    public variableName?: string;
    public variableValue?: string;


    constructor(props: CmonProxySqlVariableProps) {
        this.className = props.class_name;
        this.readOnly = props.read_only;
        this.variableName = props.variable_name;
        this.variableValue = props.variable_value;

    }

}
