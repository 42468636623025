


export interface CmonProxySqlGaleraHostgroupProps  {
    active?: boolean;
    backup_writer_hostgroup?: number;
    class_name?: string;
    comment?: string;
    max_transactions_behind?: number;
    max_writers?: number;
    offline_hostgroup?: number;
    reader_hostgroup?: number;
    writer_hostgroup?: number;
    writer_is_also_reader?: boolean;

}



export default class CmonProxySqlGaleraHostgroup {
    public active?: boolean;
    public backupWriterHostgroup?: number;
    public className?: string;
    public comment?: string;
    public maxTransactionsBehind?: number;
    public maxWriters?: number;
    public offlineHostgroup?: number;
    public readerHostgroup?: number;
    public writerHostgroup?: number;
    public writerIsAlsoReader?: boolean;


    constructor(props: CmonProxySqlGaleraHostgroupProps) {
        this.active = props.active;
        this.backupWriterHostgroup = props.backup_writer_hostgroup;
        this.className = props.class_name;
        this.comment = props.comment;
        this.maxTransactionsBehind = props.max_transactions_behind;
        this.maxWriters = props.max_writers;
        this.offlineHostgroup = props.offline_hostgroup;
        this.readerHostgroup = props.reader_hostgroup;
        this.writerHostgroup = props.writer_hostgroup;
        this.writerIsAlsoReader = props.writer_is_also_reader;

    }

}
