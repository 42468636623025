import React from 'react';
import './AppRibbon.less';
import { Badge } from 'antd';
import { RibbonProps } from 'antd/es/badge/Ribbon';

export default AppRibbon;

export type AppRibbonProps = RibbonProps & {};

function AppRibbon({ ...rest }: AppRibbonProps) {
    return <Badge.Ribbon className="AppRibbon" {...rest}></Badge.Ribbon>;
}
