import TypographyText from '../../../../../common/TypographyText';
import React from 'react';
import { FormInstance } from 'antd/lib/form';

export default BackupRestorePITRInfoMessage;
type BackupRestorePITRInfoMessageProps = {
    form: FormInstance;
};

function BackupRestorePITRInfoMessage({
    form,
}: BackupRestorePITRInfoMessageProps) {
    const node = form.getFieldValue('node');

    return (
        <span>
            The host <TypographyText strong={true}>{node}</TypographyText> will
            be started during the PITR process.
        </span>
    );
}
