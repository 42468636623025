import React, { Fragment, useEffect, useMemo, useState } from 'react';
import './ClusterLoadChart.less';
import { TinyArea, TinyAreaConfig } from '@ant-design/charts';
import { StopOutlined } from '@ant-design/icons';
import useMonitor from '../Monitor/useMonitor';
import { MonitorDatasource } from '../Monitor/Monitor';
import CcCluster from '../../services/models/CcCluster';
import { MonitorDataPoint, MonitorType } from '../Monitor/MonitorBox';
import { CcNodeType } from '../../services/models/CcNode';
import { Space } from 'antd';
import SpaceWide from '../../common/SpaceWide';
import TypographyText from '../../common/TypographyText';
import ClusterEnablePrometheusButton from './Actions/ClusterEnablePrometheusButton';
import { CmonJobInstanceCommand } from '../../services/cmon/models/CmonJobInstance';
import JobProgress from '../Jobs/JobProgress';
import CcJob, { CcJobStatus } from '../../services/models/CcJob';
import useClusterList from './useClusterList';
import AppSpin from '../../common/General/AppSpin';

export default ClusterLoadChart;

export type ClusterLoadChartProps = {
    cluster: CcCluster;
    height?: number;
    emptyStateMessage?: React.ReactNode;
};

function ClusterLoadChart({
    cluster,
    height,
    emptyStateMessage,
}: ClusterLoadChartProps) {
    const [finalData, setFinalData] = useState<number[]>([]);
    const { refresh: refreshClusters } = useClusterList({
        name: 'cluster-list-from-ClusterDashboards',
    });
    const hosts = cluster
        .getHosts()
        .filter((h) => !h.types.includes(CcNodeType.CONTROLLER));
    const { refresh, data, loading, loaded } = useMonitor({
        datasource: MonitorDatasource.PROMETHEUS,
        targets: [
            {
                expr: `max(node_load1{instance=~"${hosts
                    .map((h) => `${h.hostname}(:[0-9]+)?`)
                    .join('|')}"})`,
                legendFormat: '{{ instance }}',
            },
        ],
        type: MonitorType.CHART,
        cluster,
        autoRefresh: 30000,
        refreshDelta: 5000, // +/- 5 secs
        lastSeconds: 300, // 5 mins
    });

    const average = (data: number[]): string => {
        return (
            data?.reduce((data1: number, data2: number) => data1 + data2, 0) /
            data.length
        ).toFixed(2);
    };

    useEffect(() => {
        const nData =
            data?.flat().map(([, v]: MonitorDataPoint) => v as number) || [];
        setFinalData(nData);
    }, [data]);

    useEffect(() => {
        (async () => {
            if (cluster.isMonitoringEnabled() && !loaded) {
                await refresh({
                    clusterId: cluster.clusterId,
                });
            }
        })();
    }, [cluster.isMonitoringEnabled()]);

    const handleInstallationFinish = async (job: CcJob) => {
        if (job.status === CcJobStatus.FINISHED) {
            await refreshClusters();
        }
    };

    const config: TinyAreaConfig = useMemo(
        () => ({
            height: height || 45,
            autoFit: true,
            data: finalData,
            smooth: false,
            animation: false,
            loadingTemplate: <AppSpin spinning={true} />,
            annotations: [
                {
                    type: 'line',
                    start: ['min', 'median'],
                    end: ['max', 'median'],

                    style: {
                        stroke: 'rgba(0, 0, 0, 0.2)',
                        lineDash: [4, 2],
                    },
                },
                {
                    type: 'text',
                    content: finalData?.length ? average(finalData) : '',
                    background: {
                        padding: 5,
                        style: {
                            fill: 'rgba(255, 255, 255, 0.7)',
                        },
                    },
                    position: ['0%', '25%'],
                    style: {
                        textAlign: 'left',
                        fontSize: 12,
                        fill: 'rgba(0, 0, 0, 0.5)',
                        textBaseline: 'bottom',
                    },
                },
            ],
        }),
        [finalData]
    );
    return (
        <div
            className="ClusterLoadChart"
            style={{
                height: config.height,
                width: '100%',
                paddingTop: cluster.isMonitoringEnabled() ? '15px' : '0',
            }}
        >
            {cluster.isMonitoringEnabled() ? (
                <Fragment>
                    <TinyArea key="chart" loading={loading} {...config} />
                    <div
                        key="actions"
                        className={'ClusterLoadChart_info'}
                    ></div>
                </Fragment>
            ) : (
                <JobProgress
                    size="small"
                    command={CmonJobInstanceCommand.DEPLOY_AGENTS}
                    cluster={cluster}
                    onFinish={handleInstallationFinish}
                    fallback={
                        <SpaceWide direction="vertical" align="center">
                            {emptyStateMessage !== undefined ? (
                                emptyStateMessage
                            ) : (
                                <TypographyText muted>
                                    <Space direction="vertical" align="center">
                                        <StopOutlined />
                                        <span>No data</span>
                                    </Space>
                                </TypographyText>
                            )}
                            <ClusterEnablePrometheusButton cluster={cluster}>
                                Enable monitoring
                            </ClusterEnablePrometheusButton>
                        </SpaceWide>
                    }
                />
            )}
        </div>
    );
}
