import './AppDirectoryTree.less';
import React from 'react';
import TypographyText from '../TypographyText';
import Tree, { DirectoryTreeProps } from 'antd/lib/tree';
import classNames from 'classnames';
const { DirectoryTree } = Tree;

export default AppDirectoryTree;
export type AppDirectoryTreeProps = DirectoryTreeProps & {};

function AppDirectoryTree({ className, ...rest }: AppDirectoryTreeProps) {
    return (
        <DirectoryTree
            className={classNames('AppDirectoryTree', className)}
            titleRender={(node) => (
                <TypographyText nowrap={true}>{node.title}</TypographyText>
            )}
            {...rest}
        />
    );
}
