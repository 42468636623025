import React from 'react';
import CcCluster from '../../../../../services/models/CcCluster';
import useForm from 'antd/lib/form/hooks/useForm';
import { AddNodeWizardAction } from '../AddNodeWizard';
import ReplicationFormWizard, {
    getImportReplicationJobDataDTO,
    getNewReplicationJobDataDTO,
} from './ReplicationFormWizard';
import { CmonJobInstanceCommand } from '../../../../../services/cmon/models/CmonJobInstance';
import { AddNodeFormWizardFields } from '../AddNodeFormWizard';
import AddNodeModal from '../AddNodeModal';

export default AddNodeReplicationModal;

export type AddNodeReplicationModalProps = {
    cluster: CcCluster;
    onCancel?: () => void;
    onSuccess?: () => void;
    onCommandChange?: (command: CmonJobInstanceCommand) => void;
};

function AddNodeReplicationModal({
    cluster,
    onCancel,
    onSuccess,
    onCommandChange,
}: AddNodeReplicationModalProps) {
    const [form] = useForm<any>();

    const handleSubmit = (
        fields: AddNodeFormWizardFields,
        action: AddNodeWizardAction
    ) => {
        return action === AddNodeWizardAction.NEW
            ? getNewReplicationJobDataDTO(fields, cluster)
            : getImportReplicationJobDataDTO(fields, cluster);
    };

    return (
        <AddNodeModal
            cluster={cluster}
            form={form}
            onSubmit={handleSubmit}
            onCancel={onCancel}
            onSuccess={onSuccess}
            onCommandChange={onCommandChange}
            titleNew={'Create a replication node'}
            titleImport={'Import a replication node'}
            commandNew={CmonJobInstanceCommand.ADD_REPLICATION_SLAVE}
            commandImport={CmonJobInstanceCommand.REGISTERNODE}
            formCreateWizard={
                <ReplicationFormWizard
                    action={AddNodeWizardAction.NEW}
                    form={form}
                    cluster={cluster}
                />
            }
            formImportWizard={
                <ReplicationFormWizard
                    action={AddNodeWizardAction.IMPORT}
                    form={form}
                    cluster={cluster}
                />
            }
        />
    );
}
