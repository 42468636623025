import React, {
    forwardRef,
    useCallback,
    useEffect,
    useImperativeHandle,
    useRef,
    useState,
} from 'react';
import './MonitorBox.less';
import useMonitor from './useMonitor';
import TimelineChart, {
    TimelineChartApi,
    TimelineChartData,
    TimelineChartProps,
    Timestamp,
    Value,
} from '../../common/tmp/DataDisplay/TimelineChart';
import {
    formatMonitorValue,
    MonitorValueFormatType,
} from './MonitorValueFormat';
import { Button, Space } from 'antd';
import { MonitorVariableItem } from './MonitorVariable';
import CcCluster from '../../services/models/CcCluster';
import { CcNodeType } from '../../services/models/CcNode';
import {
    MonitorDashboardPanelOptionsConfig,
    MonitorDashboardYAxisConfig,
    MonitorDatasource,
    MonitorTarget,
} from './Monitor';
import { ArrowsAltOutlined, ShrinkOutlined } from '@ant-design/icons';
import ModalDefault from '../../common/ModalDefault';
import SpaceDescriptions from '../../common/Layout/SpaceDescriptions';
import SpaceWide from '../../common/SpaceWide';
import TypographyText from '../../common/TypographyText';
import { exprReplace, strInterpolate } from './monitorUtils';
import AppEmpty from '../../common/Feedback/AppEmpty';
import MonitorValueMapsFormat from './MonitorValueMapsFormat';
import { AppState, AppStateUser } from '../../appReducer';
import { useSelector } from 'react-redux';
import AppSpin from '../../common/General/AppSpin';
import ButtonWithForm from '../../common/General/ButtonWithForm';

export default forwardRef(MonitorBox);

export enum MonitorType {
    CHART = 'chart',
    SINGLESTAT = 'singlestat',
}

export type MonitorDataPoint = [
    t: Timestamp,
    v: Value,
    cat?: string,
    extra?: { [key: string]: any }
];
export type MonitorDataRow = MonitorDataPoint[];
export type MonitorDataRows = MonitorDataRow[];
export type MonitorData = MonitorDataRows;
export type MonitorOptions = MonitorDashboardPanelOptionsConfig;
export type MonitorCoordinate = { x: number; y: number };

export interface MonitorBoxApi {
    reload: () => void;
    update: () => void;
    isLoaded: () => boolean;
    showTooltip: (coordinate: MonitorCoordinate) => void;
    hideTooltip: () => void;
}

export type MonitorBoxProps = {
    type: MonitorType;
    datasource: MonitorDatasource;
    cluster: CcCluster;
    startTs: number;
    endTs: number;
    title?: string;
    options?: MonitorOptions;
    targets: MonitorTarget[];
    vars: MonitorVariableItem[];
    filters?: ((d: any) => boolean)[];
    onTooltipShow?: (coordinate: MonitorCoordinate) => void;
    onTooltipHide?: () => void;
    onRangeSelect?: (event: CustomEvent) => void;
    onPlotClick?: (data: any, event: CustomEvent) => void;
};

function MonitorBox(
    {
        type,
        datasource,
        cluster,
        startTs,
        endTs,
        title,
        options,
        targets,
        filters,
        vars,
        onTooltipShow,
        onTooltipHide,
        onRangeSelect,
        onPlotClick,
    }: MonitorBoxProps,
    ref: any
) {
    const [initialData, setInitialData] = useState<MonitorData | null>(null);
    const [initialSeries, setInitialSeries] = useState<string[]>();
    const [dataUpdate, setDataUpdate] = useState<MonitorData | null>(null);
    const [dataToCheck, setDataToCheck] = useState<MonitorData | null>(null);
    const [finalTargets, setFinalTargets] = useState<MonitorTarget[]>([]);
    const [lastTs, setLastTs] = useState<number | null>(null);
    const [loaded, setLoaded] = useState<boolean>(false);
    const [blockUpdate, setBlockUpdate] = useState<boolean>(false);
    const componentRef = useRef<TimelineChartApi>(null);
    const { refresh, loading, data, loaded: dataLoaded } = useMonitor({
        cluster,
        datasource,
        targets: finalTargets,
        startTs,
        endTs,
        filters,
        type,
        name: `${title}-from-monitor-box`,
    });
    const [user]: [AppStateUser] = useSelector(({ user }: AppState) => [user]);

    const reload = useCallback(async () => {
        setLastTs(null);
        setLoaded(false);
        setBlockUpdate(true);

        await refresh({
            clusterId: cluster.clusterId,
            startTs,
            endTs,
            targets: finalTargets,
            returnFromTs: null,
            useCache: false,
        });
        setBlockUpdate(false);
    }, [startTs, endTs, lastTs, cluster, loaded, finalTargets, refresh]);

    const update = useCallback(async () => {
        await refresh({
            clusterId: cluster.clusterId,
            startTs,
            endTs,
            returnFromTs: lastTs ? lastTs : null,
        });
    }, [startTs, endTs, lastTs, cluster, loaded, refresh]);

    useImperativeHandle(
        ref,
        (): MonitorBoxApi => ({
            async reload() {
                await reload();
            },
            async update() {
                await update();
            },
            isLoaded() {
                return loaded;
            },
            showTooltip(coordinate: MonitorCoordinate) {
                componentRef.current?.showTooltip(coordinate);
            },
            hideTooltip() {
                componentRef.current?.hideTooltip();
            },
        })
    );

    // change in targets will reload
    useEffect(() => {
        if (finalTargets.length > 0) {
            (async () => {
                await reload();
            })();
        }
    }, [finalTargets]);

    // data change will sets initial date or update
    // sets max ts for next request
    useEffect(() => {
        if (data) {
            if (loaded && !blockUpdate) {
                // when updating data, it can happen that the amount of series changes
                // then we use the initial series to sort updated data
                const dataSortedBySerie = initialSeries
                    ? initialSeries.map((initialSerieName) => {
                          const dataForSerie = data.find(
                              (dp) => dp[0]?.[2] === initialSerieName
                          );
                          return dataForSerie || [];
                      })
                    : data;
                setDataUpdate(dataSortedBySerie);
            } else {
                setInitialData(data);
                setLoaded(true);
                // we get the series name from the first data poin of each serie
                // remember each serie is like [[time, value, serieName], ...]
                setInitialSeries(data.map((d) => d[0]?.[2] || ''));
            }
            let maxTs = lastTs || 0;
            data.forEach((dataRow: any[]) => {
                if (
                    dataRow.length > 0 &&
                    dataRow[dataRow.length - 1][0] > maxTs
                ) {
                    maxTs = dataRow[dataRow.length - 1][0];
                }
            });
            setLastTs(maxTs);
        }
    }, [data]);

    // a change in var params updates final targets
    useEffect(() => {
        if (vars) {
            if (
                vars.every((v) => v.ready && v.loaded) &&
                targets &&
                targets.length > 0
            ) {
                const prometheusNode = cluster.getNodesByType(
                    CcNodeType.PROMETHEUS
                )[0];
                setFinalTargets(
                    targets.map((t) => ({
                        ...t,
                        expr: exprReplace(
                            t.expr,
                            vars,
                            datasource,
                            prometheusNode?.hostname || ''
                        ),
                    }))
                );
            } else {
                setFinalTargets([]);
            }
        }
    }, [vars]);

    useEffect(() => {
        setDataToCheck(initialData);
    }, [initialData]);

    useEffect(() => {
        if (dataUpdate?.some((d) => d.length)) {
            setDataToCheck(dataUpdate);
        }
    }, [dataUpdate]);

    const yAxisOptions: MonitorDashboardYAxisConfig = options?.yaxis
        ? options?.yaxis[0]
        : {};

    const getMonitorContent = ({
        legendAsTable,
    }: Partial<TimelineChartProps> = {}) =>
        type === MonitorType.CHART ? (
            <TimelineChart
                ref={componentRef}
                loading={loading && !loaded}
                data={initialData as TimelineChartData}
                dataUpdate={dataUpdate as TimelineChartData}
                from={startTs}
                to={endTs}
                stacked={options?.stack}
                type={options?.type}
                yMax={yAxisOptions.max}
                yMin={yAxisOptions.min}
                legendAsTable={!!legendAsTable}
                valueFormatter={(v: number) =>
                    formatMonitorValue(
                        v,
                        (yAxisOptions.format ||
                            options?.format) as MonitorValueFormatType,
                        yAxisOptions?.decimals || options?.decimals,
                        yAxisOptions?.postfix || options?.postfix
                    )
                }
                timezone={user?.timezone?.name}
                onTooltipShow={onTooltipShow}
                onTooltipHide={onTooltipHide}
                onRangeSelect={onRangeSelect}
                onPlotClick={onPlotClick}
            />
        ) : type === MonitorType.SINGLESTAT ? (
            dataToCheck ? (
                dataToCheck.map((d: any, dIdx: number) => {
                    const dataPoint = d[d.length - 1];
                    const label = Array.isArray(dataPoint)
                        ? dataPoint[2]
                        : finalTargets[dIdx]?.legendFormat
                        ? strInterpolate(
                              finalTargets[dIdx].legendFormat || '',
                              dataPoint
                          )
                        : null;
                    const value = finalTargets[dIdx]?.legendAsValue
                        ? label
                        : Array.isArray(dataPoint)
                        ? dataPoint[1]
                        : strInterpolate(
                              finalTargets[dIdx]?.valueFormat || '',
                              dataPoint
                          );

                    const formatType = options?.format as MonitorValueFormatType;
                    return (
                        <SpaceWide key={dIdx}>
                            <SpaceDescriptions key={finalTargets[dIdx]?.expr}>
                                <SpaceDescriptions.Item
                                    label={
                                        !finalTargets[dIdx]?.legendAsValue &&
                                        label
                                    }
                                >
                                    <TypographyText strong>
                                        <MonitorValueMapsFormat
                                            value={value}
                                            maps={options?.valueMaps}
                                            formatType={
                                                formatType === undefined
                                                    ? MonitorValueFormatType.NONE
                                                    : formatType
                                            }
                                            decimals={options?.decimals}
                                            postfix={options?.postfix}
                                        />
                                    </TypographyText>
                                </SpaceDescriptions.Item>
                            </SpaceDescriptions>
                        </SpaceWide>
                    );
                })
            ) : (
                <AppSpin spinning={loading} size={'small'}>
                    -
                </AppSpin>
            )
        ) : null;

    return (
        <Space
            className="MonitorBox"
            style={{ width: '100%' }}
            direction="vertical"
        >
            <Space className="MonitorBox_header">
                <div>{title}</div>
                <div>
                    {type === MonitorType.CHART ? (
                        <ButtonWithForm
                            button={
                                <Button type="text" size="small">
                                    <ArrowsAltOutlined />
                                </Button>
                            }
                            form={
                                <ModalDefault
                                    title={title}
                                    visible={true}
                                    closeIcon={<ShrinkOutlined />}
                                >
                                    <div className="MonitorBox_expanded-modal-content">
                                        {getMonitorContent({
                                            legendAsTable: true,
                                        })}
                                    </div>
                                </ModalDefault>
                            }
                        />
                    ) : null}
                </div>
            </Space>
            {loaded &&
            dataLoaded &&
            dataToCheck &&
            (dataToCheck.length === 0 ||
                dataToCheck.every((val) => !val.length)) ? (
                type === MonitorType.CHART ? (
                    <AppEmpty description="There is no data to display" />
                ) : (
                    '-'
                )
            ) : (
                getMonitorContent()
            )}
        </Space>
    );
}
