export const CMON_TIMEZONES_ABBR_LIST: { [key: string]: string } = {
    'Alpha Time Zone': 'A',
    'Australian Central Daylight Time': 'ACDT',
    'Australian Central Standard Time': 'ACST',
    'Atlantic Daylight Time': 'ADT',
    'Australian Eastern Daylight Time': 'AEDT',
    'Australian Eastern Standard Time': 'AEST',
    'Afghanistan Time': 'AFT',
    'Alaska Daylight Time': 'AKDT',
    'Alaska Standard Time': 'AKST',
    'Alma-Ata Time': 'ALMT',
    'Armenia Summer Time': 'AMST',
    'Amazon Summer Time': 'AMST',
    'Armenia Time': 'AMT',
    'Amazon Time': 'AMT',
    'Anadyr Summer Time': 'ANAST',
    'Anadyr Time': 'ANAT',
    'Aqtobe Time': 'AQTT',
    'Argentina Time': 'ART',
    'Arabia Standard Time': 'AST',
    'Atlantic Standard Time': 'AST',
    'Australian Western Daylight Time': 'AWDT',
    'Australian Western Standard Time': 'AWST',
    'Azores Summer Time': 'AZOST',
    'Azores Time': 'AZOT',
    'Azerbaijan Summer Time': 'AZST',
    'Azerbaijan Time': 'AZT',
    'Bravo Time Zone': 'B',
    'Brunei Darussalam Time': 'BNT',
    'Bolivia Time': 'BOT',
    'Brasilia Summer Time': 'BRST',
    'Brasília time': 'BRT',
    'Bangladesh Standard Time': 'BST',
    'British Summer Time': 'BST',
    'Bhutan Time': 'BTT',
    'Charlie Time Zone': 'C',
    'Casey Time': 'CAST',
    'Central Africa Time': 'CAT',
    'Cocos Islands Time': 'CCT',
    'Cuba Daylight Time': 'CDT',
    'Central Daylight Time': 'CDT',
    'Central European Summer Time': 'CEST',
    'Central European Time': 'CET',
    'Chatham Island Daylight Time': 'CHADT',
    'Chatham Island Standard Time': 'CHAST',
    'Cook Island Time': 'CKT',
    'Chile Summer Time': 'CLST',
    'Chile Standard Time': 'CLT',
    'Colombia Time': 'COT',
    'China Standard Time': 'CST',
    'Central Standard Time': 'CST',
    'Cuba Standard Time': 'CST',
    'Cape Verde Time': 'CVT',
    'Christmas Island Time': 'CXT',
    'Chamorro Standard Time': 'ChST',
    'Delta Time Zone': 'D',
    'Davis Time': 'DAVT',
    'Echo Time Zone': 'E',
    'Easter Island Summer Time': 'EASST',
    'Easter Island Standard Time': 'EAST',
    'Eastern Africa Time': 'EAT',
    'East Africa Time': 'EAT',
    'Ecuador Time': 'ECT',
    'Eastern Daylight Time': 'EDT',
    'Eastern European Summer Time': 'EEST',
    'Eastern European Time': 'EET',
    'Eastern Greenland Summer Time': 'EGST',
    'East Greenland Time': 'EGT',
    'Eastern Standard Time': 'EST',
    'Tiempo del Este': 'ET',
    'Foxtrot Time Zone': 'F',
    'Fiji Summer Time': 'FJST',
    'Fiji Time': 'FJT',
    'Falkland Islands Summer Time': 'FKST',
    'Falkland Island Time': 'FKT',
    'Fernando de Noronha Time': 'FNT',
    'Golf Time Zone': 'G',
    'Galapagos Time': 'GALT',
    'Gambier Time': 'GAMT',
    'Georgia Standard Time': 'GET',
    'French Guiana Time': 'GFT',
    'Gilbert Island Time': 'GILT',
    'Greenwich Mean Time': 'GMT',
    'Gulf Standard Time': 'GST',
    'Guyana Time': 'GYT',
    'Hotel Time Zone': 'H',
    "Heure Avancée de l'Atlantique": 'HAA',
    'Heure Avancée du Centre': 'HAC',
    'Hawaii-Aleutian Daylight Time': 'HADT',
    "Heure Avancée de l'Est": 'HAE',
    'Heure Avancée du Pacifique': 'HAP',
    'Heure Avancée des Rocheuses': 'HAR',
    'Hawaii-Aleutian Standard Time': 'HAST',
    'Heure Avancée de Terre-Neuve': 'HAT',
    'Heure Avancée du Yukon': 'HAY',
    'Hong Kong Time': 'HKT',
    'Hora Legal de Venezuela': 'HLV',
    "Heure Normale de l'Atlantique": 'HNA',
    'Heure Normale du Centre': 'HNC',
    "Heure Normale de l'Est": 'HNE',
    'Heure Normale du Pacifique': 'HNP',
    'Heure Normale des Rocheuses': 'HNR',
    'Heure Normale de Terre-Neuve': 'HNT',
    'Heure Normale du Yukon': 'HNY',
    'Hovd Time': 'HOVT',
    'India Time Zone': 'I',
    'Indochina Time': 'ICT',
    'Israel Daylight Time': 'IDT',
    'Indian Chagos Time': 'IOT',
    'Iran Daylight Time': 'IRDT',
    'Irkutsk Summer Time': 'IRKST',
    'Irkutsk Time': 'IRKT',
    'Iran Standard Time': 'IRST',
    'Israel Standard Time': 'IST',
    'India Standard Time': 'IST',
    'Irish Standard Time': 'IST',
    'Japan Standard Time': 'JST',
    'Kilo Time Zone': 'K',
    'Kyrgyzstan Time': 'KGT',
    'Krasnoyarsk Summer Time': 'KRAST',
    'Krasnoyarsk Time': 'KRAT',
    'Korea Standard Time': 'KST',
    'Kuybyshev Time': 'KUYT',
    'Lima Time Zone': 'L',
    'Lord Howe Daylight Time': 'LHDT',
    'Lord Howe Standard Time': 'LHST',
    'Line Islands Time': 'LINT',
    'Mike Time Zone': 'M',
    'Magadan Summer Time': 'MAGST',
    'Magadan Time': 'MAGT',
    'Marquesas Time': 'MART',
    'Mawson Time': 'MAWT',
    'Mountain Daylight Time': 'MDT',
    'Mitteleuropäische Sommerzeit': 'MESZ',
    'Mitteleuropäische Zeit': 'MEZ',
    'Marshall Islands Time': 'MHT',
    'Myanmar Time': 'MMT',
    'Moscow Daylight Time': 'MSD',
    'Moscow Standard Time': 'MSK',
    'Mountain Standard Time': 'MST',
    'Mauritius Time': 'MUT',
    'Maldives Time': 'MVT',
    'Malaysia Time': 'MYT',
    'November Time Zone': 'N',
    'New Caledonia Time': 'NCT',
    'Newfoundland Daylight Time': 'NDT',
    'Norfolk Time': 'NFT',
    'Novosibirsk Summer Time': 'NOVST',
    'Novosibirsk Time': 'NOVT',
    'Nepal Time': 'NPT',
    'Newfoundland Standard Time': 'NST',
    'Niue Time': 'NUT',
    'New Zealand Daylight Time': 'NZDT',
    'New Zealand Standard Time': 'NZST',
    'Oscar Time Zone': 'O',
    'Omsk Summer Time': 'OMSST',
    'Omsk Standard Time': 'OMST',
    'Papa Time Zone': 'P',
    'Pacific Daylight Time': 'PDT',
    'Peru Time': 'PET',
    'Kamchatka Summer Time': 'PETST',
    'Kamchatka Time': 'PETT',
    'Papua New Guinea Time': 'PGT',
    'Phoenix Island Time': 'PHOT',
    'Philippine Time': 'PHT',
    'Pakistan Standard Time': 'PKT',
    'Pierre & Miquelon Daylight Time': 'PMDT',
    'Pierre & Miquelon Standard Time': 'PMST',
    'Pohnpei Standard Time': 'PONT',
    'Pacific Standard Time': 'PST',
    'Pitcairn Standard Time': 'PST',
    'Tiempo del Pacífico': 'PT',
    'Palau Time': 'PWT',
    'Paraguay Summer Time': 'PYST',
    'Paraguay Time': 'PYT',
    'Quebec Time Zone': 'Q',
    'Romeo Time Zone': 'R',
    'Reunion Time': 'RET',
    'Sierra Time Zone': 'S',
    'Samara Time': 'SAMT',
    'South Africa Standard Time': 'SAST',
    'Solomon IslandsTime': 'SBT',
    'Seychelles Time': 'SCT',
    'Singapore Time': 'SGT',
    'Suriname Time': 'SRT',
    'Samoa Standard Time': 'SST',
    'Tango Time Zone': 'T',
    'Tahiti Time': 'TAHT',
    'French Southern and Antarctic Time': 'TFT',
    'Tajikistan Time': 'TJT',
    'Tokelau Time': 'TKT',
    'East Timor Time': 'TLT',
    'Turkmenistan Time': 'TMT',
    'Tuvalu Time': 'TVT',
    'Uniform Time Zone': 'U',
    'Ulaanbaatar Time': 'ULAT',
    'Coordinated Universal Time': 'UTC',
    'Uruguay Summer Time': 'UYST',
    'Uruguay Time': 'UYT',
    'Uzbekistan Time': 'UZT',
    'Victor Time Zone': 'V',
    'Venezuelan Standard Time': 'VET',
    'Vladivostok Summer Time': 'VLAST',
    'Vladivostok Time': 'VLAT',
    'Vanuatu Time': 'VUT',
    'Whiskey Time Zone': 'W',
    'West Africa Summer Time': 'WAST',
    'West Africa Time': 'WAT',
    'Western European Summer Time': 'WEST',
    'Westeuropäische Sommerzeit': 'WESZ',
    'Western European Time': 'WET',
    'Westeuropäische Zeit': 'WEZ',
    'Wallis and Futuna Time': 'WFT',
    'Western Greenland Summer Time': 'WGST',
    'West Greenland Time': 'WGT',
    'Western Indonesian Time': 'WIB',
    'Eastern Indonesian Time': 'WIT',
    'Central Indonesian Time': 'WITA',
    'Western Sahara Summer Time': 'WST',
    'West Samoa Time': 'WST',
    'Western Sahara Standard Time': 'WT',
    'X-ray Time Zone': 'X',
    'Yankee Time Zone': 'Y',
    'Yakutsk Summer Time': 'YAKST',
    'Yakutsk Time': 'YAKT',
    'Yap Time': 'YAPT',
    'Yekaterinburg Summer Time': 'YEKST',
    'Yekaterinburg Time': 'YEKT',
    'Zulu Time Zone': 'Z',
};
