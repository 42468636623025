import CmonImperativeService from '../../../services/cmon/CmonImperativeService';
import {
    notifyError,
    notifyOperationSuccess,
    NotifyType,
} from '../../Notifications/uiNotification';
import useFetch from '../../../common/useFetch';
import CcCluster from '../../../services/models/CcCluster';
import { useDebugContext } from '../../../common/Debug';
import CcAdvisorSchedule, {
    ClusterDirTreeFileType,
} from '../../../services/models/CcAdvisorSchedule';
import { Space } from 'antd';
import React, { useState } from 'react';

export type ClusterScriptRequestDTO = {
    filename?: string;
    content?: string;
    tags?: string;
    arguments?: string;
};

export type ScriptDataResponse = ClusterDirTreeFileType & {
    content?: string;
};

type UseClusterScriptProps = {
    cluster: CcCluster;
    file?: ClusterDirTreeFileType;
};

export default function useClusterScript({
    cluster,
    file,
}: UseClusterScriptProps) {
    const [saveLoading, setSaveLoading] = useState<boolean>(false);
    const [compileLoading, setCompileLoading] = useState<boolean>(false);
    const [runLoading, setRunLoading] = useState<boolean>(false);

    const { log } = useDebugContext();
    const filename = (file && `${file.path}${file.filename}`) || undefined;
    const { loading, refresh, data } = useFetch<ScriptDataResponse | undefined>(
        {
            fetchFn: async (params, opts) => {
                const { data } = await CmonImperativeService.loadScript(
                    {
                        cluster_id: cluster.clusterId,
                        filename,
                        ...params,
                    },
                    opts
                );

                return data;
            },
            cancelFn: async ({ requestId }) => {
                await CmonImperativeService.cancelRequest(requestId);
            },
        }
    );
    const save = async ({ content, ...dto }: ClusterScriptRequestDTO) => {
        try {
            setSaveLoading(true);
            if (!content) {
                throw new Error('Nothing to save');
            }
            await CmonImperativeService.saveScript({
                cluster_id: cluster.clusterId,
                filename,
                content,
                ...dto,
            });
            await refresh({});
            notifyOperationSuccess({
                type: NotifyType.TOAST,
                title: 'Script saved successfully',
            });
        } catch (e: any) {
            notifyError({ content: e.message });
            log.error(e);
        } finally {
            setSaveLoading(false);
        }
    };
    const compile = async (dto: ClusterScriptRequestDTO) => {
        try {
            setCompileLoading(true);
            await save(dto);
            await CmonImperativeService.compileScript({
                cluster_id: cluster.clusterId,
                filename: filename || dto.filename,
            });
            notifyOperationSuccess({
                type: NotifyType.TOAST,
                title: 'Script compiled successfully!',
            });
        } catch (e: any) {
            notifyError({ content: e.message });
            log.error(e);
        } finally {
            setCompileLoading(false);
        }
    };

    const run = async (dto: ClusterScriptRequestDTO) => {
        let execResult;
        try {
            setRunLoading(true);
            await save(dto);
            const { results } = await CmonImperativeService.executeScript({
                cluster_id: cluster.clusterId,
                filename: filename || dto.filename,
                arguments: dto.arguments,
            });
            execResult = new CcAdvisorSchedule({ ...results }, file);

            const notificationContent = (
                <Space direction="vertical">
                    {execResult?.messages?.map(({ message }) => message)}
                </Space>
            );
            if (execResult?.status === 'Ended') {
                notifyOperationSuccess({
                    type: NotifyType.TOAST,
                    title: 'Script executed successfully!',
                    content: notificationContent,
                });
            } else {
                notifyError({
                    type: NotifyType.TOAST,
                    title: 'Script execution error',
                    content: notificationContent,
                });
            }
        } catch (e: any) {
            notifyError({ content: e.message });
            log.error(e);
        } finally {
            setRunLoading(false);
        }

        return execResult;
    };

    return {
        loading,
        saveLoading,
        compileLoading,
        runLoading,
        data,
        refresh,
        save,
        compile,
        run,
    };
}
