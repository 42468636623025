import { BackupWizardStep } from './Wizard/BackupWizard';
import React from 'react';
import CcBackupSchedule from '../../services/models/CcBackupSchedule';
import { ButtonProps } from 'antd/lib/button';
import { AppState, AppStateClustersMap } from '../../appReducer';
import { useSelector } from 'react-redux';
import BackupWizardModal from './Wizard/BackupWizardModal';
import ActionButton from '@severalnines/bar-frontend-components/build/lib/General/ActionButton';
import ButtonWithForm from '../../common/General/ButtonWithForm';

export type BackupScheduleActionEditButtonProps = {
    schedule: CcBackupSchedule;
    onSuccess: () => void;
} & ButtonProps;
const BackupScheduleEditActionButton = ({
    schedule,
    children,
    onSuccess,
    ...rest
}: BackupScheduleActionEditButtonProps) => {
    const [clustersMap]: [AppStateClustersMap] = useSelector(
        ({ clusters }: AppState) => [clusters]
    );

    return (
        <ButtonWithForm
            button={<ActionButton {...rest}>{children || 'Edit'}</ActionButton>}
            form={
                <BackupWizardModal
                    title={`Update Schedule #${schedule.jobId}`}
                    cluster={clustersMap.get(`${schedule.clusterId}`)}
                    schedule={schedule}
                    onSuccess={onSuccess}
                    activeStep={BackupWizardStep.FORM_UPDATE_SCHEDULE}
                />
            }
        />
    );
};

export default BackupScheduleEditActionButton;
