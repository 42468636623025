import React from 'react';
import { Col, Row, Form, Input, Space } from 'antd';
import { FormInstance } from 'antd/lib/form';
import InfoIcon from '@severalnines/bar-frontend-components/build/lib/General/InfoIcon';
import PasswordInput from '../../../../../common/DataEntry/PasswordInput';

export const MAX_SCALE_CONFIGURATION_VALIDATION = [
    ['maxScaleConfiguration', 'maxScaleAdminUser'],
    ['maxScaleConfiguration', 'maxScaleAdminPassword'],
    ['maxScaleConfiguration', 'maxScaleMySQLUser'],
    ['maxScaleConfiguration', 'maxScaleMySQLPassword'],
];

export const MAX_SCALE_CONFIGURATION_INITIAL = {
    maxScaleConfiguration: {
        maxScaleAdminUser: 'admin',
        maxScaleAdminPassword: 'mariadb',
    },
};

type CreateMaxScaleConfigurationProps = {
    form: FormInstance;
};

export default CreateMaxScaleConfiguration;

function CreateMaxScaleConfiguration({
    form,
}: CreateMaxScaleConfigurationProps) {
    return (
        <div style={{ minHeight: 450 }}>
            <Row gutter={[24, 0]}>
                <Col span={24}>
                    <h3>Configuration</h3>
                </Col>
            </Row>
            <Row gutter={[24, 0]}>
                <Col span={12}>
                    <Form.Item
                        name={['maxScaleConfiguration', 'maxScaleAdminUser']}
                        label={<span>MaxScale admin username</span>}
                        rules={[
                            {
                                required: true,
                                message: 'Enter MaxScale admin username.',
                            },
                        ]}
                    >
                        <Input placeholder="Enter MaxScale admin username."></Input>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        shouldUpdate={(_, current) => {
                            if (
                                form?.getFieldValue([
                                    'maxScaleConfiguration',
                                    'maxScaleAdminUser',
                                ]) === 'admin' &&
                                current?.maxScaleConfiguration
                                    ?.maxScaleAdminPassword !== 'mariadb'
                            ) {
                                form?.setFieldsValue({
                                    maxScaleConfiguration: {
                                        maxScaleAdminPassword: 'mariadb',
                                    },
                                });
                            }
                            return true;
                        }}
                    >
                        {() => {
                            const passwordDisabled =
                                form?.getFieldValue([
                                    'maxScaleConfiguration',
                                    'maxScaleAdminUser',
                                ]) === 'admin';
                            return (
                                <Form.Item
                                    name={[
                                        'maxScaleConfiguration',
                                        'maxScaleAdminPassword',
                                    ]}
                                    label={
                                        <Space>
                                            <span>MaxScale Admin Password</span>
                                            <InfoIcon
                                                info={
                                                    <span>
                                                        MaxScale enforce that
                                                        the admin password for
                                                        admin user 'admin' is
                                                        'mariadb' if you want
                                                        change or use another
                                                        password you must create
                                                        user. The 'Admin' user
                                                        can later be dropped
                                                    </span>
                                                }
                                            />
                                        </Space>
                                    }
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                'Enter MaxScale Admin Password.',
                                        },
                                    ]}
                                >
                                    {passwordDisabled ? (
                                        <Input disabled={true}></Input>
                                    ) : (
                                        <PasswordInput
                                            disabled={passwordDisabled}
                                            placeholder="Enter MaxScale Admin Password."
                                        />
                                    )}
                                </Form.Item>
                            );
                        }}
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        name={['maxScaleConfiguration', 'maxScaleMySQLUser']}
                        label={'MaxScale MySQL Username'}
                        rules={[
                            {
                                required: true,
                                message: 'Enter MaxScale MySQL Username.',
                            },
                        ]}
                    >
                        <Input placeholder="Enter MaxScale MySQL Username."></Input>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        name={[
                            'maxScaleConfiguration',
                            'maxScaleMySQLPassword',
                        ]}
                        label={'MaxScale MySQL Password'}
                        rules={[
                            {
                                required: true,
                                message: 'Enter MaxScale MySQL Password.',
                            },
                        ]}
                    >
                        <Input.Password
                            visibilityToggle={false}
                            placeholder="Enter MaxScale MySQL Password."
                        />
                    </Form.Item>
                </Col>
            </Row>
        </div>
    );
}
