import useFetch from '../../common/useFetch';
import CcImperativeService from '../../services/CcImperativeService';

export type UseRunningOperationProps = {
    name?: string;
    clusterId: number;
};

const useRunningOperation = ({
    name = 'use-running-operation',
    clusterId,
}: UseClusterDatabasesProps) => {
    const { loading, data, refresh } = useFetch({
        name,
        fetchFn: async () => {
            const { results } = await CcImperativeService.executeScript({
                filename: '/s9s/mongodb/widgets/qm/db_currentop.js',
                cluster_id: clusterId,
            });
            return results?.exitStatus;
        },
        cancelFn: async ({ requestId }) => {
            await CcImperativeService.cancelRequest(requestId);
        },
    });

    return {
        loading,
        data,
        refresh,
    };
};

export default useRunningOperation;
