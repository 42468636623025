
import CcStats, { CcStatsProps } from '../../models/CcStats';


export interface CmonSqlStatsProps  extends CcStatsProps {
    ABORTED_CLIENTS?: number;
    ABORTED_CONNECTS?: number;
    ARCHIVED_COUNT?: number;
    BUFFERS_ALLOC?: number;
    BUFFERS_BACKEND?: number;
    BUFFERS_BACKEND_FSYNC?: number;
    BUFFERS_CHECKPOINT?: number;
    BUFFERS_CLEAN?: number;
    BYTES_RECEIVED?: number;
    CHECKPOINTS_REQ?: number;
    CHECKPOINTS_TIMED?: number;
    CHECKPOINT_SYNC_TIME?: number;
    CHECKPOINT_WRITE_TIME?: number;
    COM_DELETE?: number;
    COM_INSERT?: number;
    COM_REPLACE?: number;
    COM_SELECT?: number;
    COM_UPDATE?: number;
    CONNECTIONS?: number;
    CREATED_TMP_DISK_TABLES?: number;
    CREATED_TMP_FILES?: number;
    CREATED_TMP_TABLES?: number;
    FAILED_COUNT?: number;
    HANDLER_DELETE?: number;
    HANDLER_READ_FIRST?: number;
    HANDLER_READ_KEY?: number;
    HANDLER_READ_LAST?: number;
    HANDLER_READ_NEXT?: number;
    HANDLER_READ_PREV?: number;
    HANDLER_READ_RND?: number;
    HANDLER_READ_RND_NEXT?: number;
    HANDLER_UPDATE?: number;
    HANDLER_WRITE?: number;
    INNODB_BUFFER_POOL_PAGES_DATA?: number;
    INNODB_BUFFER_POOL_PAGES_DIRTY?: number;
    INNODB_BUFFER_POOL_PAGES_FLUSHED?: number;
    INNODB_BUFFER_POOL_PAGES_FREE?: number;
    INNODB_BUFFER_POOL_PAGES_LRU_FLUSHED?: number;
    INNODB_BUFFER_POOL_PAGES_MADE_NOT_YOUNG?: number;
    INNODB_BUFFER_POOL_PAGES_MADE_YOUNG?: number;
    INNODB_BUFFER_POOL_PAGES_MISC?: number;
    INNODB_BUFFER_POOL_PAGES_OLD?: number;
    INNODB_BUFFER_POOL_PAGES_TOTAL?: number;
    INNODB_DATA_FSYNCS?: number;
    INNODB_DATA_PENDING_FSYNCS?: number;
    INNODB_DATA_PENDING_READS?: number;
    INNODB_DATA_PENDING_WRITES?: number;
    INNODB_DATA_READ?: number;
    INNODB_DATA_READS?: number;
    INNODB_DATA_WRITES?: number;
    INNODB_DATA_WRITTEN?: number;
    INNODB_LOG_WAITS?: number;
    INNODB_LSN_CURRENT?: number;
    INNODB_LSN_FLUSHED?: number;
    INNODB_LSN_LAST_CHECKPOINT?: number;
    INNODB_OS_LOG_FSYNCS?: number;
    INNODB_OS_LOG_WRITTEN?: number;
    INNODB_PAGES_CREATED?: number;
    INNODB_PAGES_READ?: number;
    INNODB_PAGES_WRITTEN?: number;
    INNODB_ROW_LOCK_TIME?: number;
    MAXWRITTEN_CLEAN?: number;
    OPENED_TABLES?: number;
    OPEN_TABLES?: number;
    QUERIES?: number;
    QUESTIONS?: number;
    SELECT_FULL_JOIN?: number;
    SELECT_FULL_RANGE_JOIN?: number;
    SELECT_RANGE?: number;
    SELECT_RANGE_CHECK?: number;
    SELECT_SCAN?: number;
    SLOW_QUERIES?: number;
    THREADS_CACHED?: number;
    THREADS_CONNECTED?: number;
    THREADS_CREATED?: number;
    THREADS_RUNNING?: number;
    WSREP_FLOW_CONTROL_PAUSED?: number;
    WSREP_FLOW_CONTROL_PAUSED_NS?: number;
    WSREP_FLOW_CONTROL_RECV?: number;
    WSREP_FLOW_CONTROL_SENT?: number;
    WSREP_LOCAL_BF_ABORTS?: number;
    WSREP_LOCAL_RECV_QUEUE?: number;
    WSREP_LOCAL_RECV_QUEUE_AVG?: number;
    WSREP_LOCAL_RECV_QUEUE_MAX?: number;
    WSREP_LOCAL_RECV_QUEUE_MIN?: number;
    WSREP_LOCAL_SEND_QUEUE?: number;
    WSREP_LOCAL_SEND_QUEUE_AVG?: number;
    WSREP_LOCAL_SEND_QUEUE_MAX?: number;
    WSREP_LOCAL_SEND_QUEUE_MIN?: number;
    WSREP_RECEIVED_BYTES?: number;
    WSREP_REPLICATED_BYTES?: number;
    WSREP_REPL_DATA_BYTES?: number;
    WSREP_REPL_KEYS_BYTES?: number;
    WSREP_REPL_OTHER_BYTES?: number;
    'blocks-hit'?: number;
    'blocks-read'?: number;
    commits?: number;
    connections?: number;
    rollbacks?: number;
    'rows-deleted'?: number;
    'rows-fetched'?: number;
    'rows-inserted'?: number;
    'rows-updated'?: number;

}



export default class CmonSqlStats extends CcStats {
    public ABORTED_CLIENTS?: number;
    public ABORTED_CONNECTS?: number;
    public ARCHIVED_COUNT?: number;
    public BUFFERS_ALLOC?: number;
    public BUFFERS_BACKEND?: number;
    public BUFFERS_BACKEND_FSYNC?: number;
    public BUFFERS_CHECKPOINT?: number;
    public BUFFERS_CLEAN?: number;
    public BYTES_RECEIVED?: number;
    public CHECKPOINTS_REQ?: number;
    public CHECKPOINTS_TIMED?: number;
    public CHECKPOINT_SYNC_TIME?: number;
    public CHECKPOINT_WRITE_TIME?: number;
    public COM_DELETE?: number;
    public COM_INSERT?: number;
    public COM_REPLACE?: number;
    public COM_SELECT?: number;
    public COM_UPDATE?: number;
    public CONNECTIONS?: number;
    public CREATED_TMP_DISK_TABLES?: number;
    public CREATED_TMP_FILES?: number;
    public CREATED_TMP_TABLES?: number;
    public FAILED_COUNT?: number;
    public HANDLER_DELETE?: number;
    public HANDLER_READ_FIRST?: number;
    public HANDLER_READ_KEY?: number;
    public HANDLER_READ_LAST?: number;
    public HANDLER_READ_NEXT?: number;
    public HANDLER_READ_PREV?: number;
    public HANDLER_READ_RND?: number;
    public HANDLER_READ_RND_NEXT?: number;
    public HANDLER_UPDATE?: number;
    public HANDLER_WRITE?: number;
    public INNODB_BUFFER_POOL_PAGES_DATA?: number;
    public INNODB_BUFFER_POOL_PAGES_DIRTY?: number;
    public INNODB_BUFFER_POOL_PAGES_FLUSHED?: number;
    public INNODB_BUFFER_POOL_PAGES_FREE?: number;
    public INNODB_BUFFER_POOL_PAGES_LRU_FLUSHED?: number;
    public INNODB_BUFFER_POOL_PAGES_MADE_NOT_YOUNG?: number;
    public INNODB_BUFFER_POOL_PAGES_MADE_YOUNG?: number;
    public INNODB_BUFFER_POOL_PAGES_MISC?: number;
    public INNODB_BUFFER_POOL_PAGES_OLD?: number;
    public INNODB_BUFFER_POOL_PAGES_TOTAL?: number;
    public INNODB_DATA_FSYNCS?: number;
    public INNODB_DATA_PENDING_FSYNCS?: number;
    public INNODB_DATA_PENDING_READS?: number;
    public INNODB_DATA_PENDING_WRITES?: number;
    public INNODB_DATA_READ?: number;
    public INNODB_DATA_READS?: number;
    public INNODB_DATA_WRITES?: number;
    public INNODB_DATA_WRITTEN?: number;
    public INNODB_LOG_WAITS?: number;
    public INNODB_LSN_CURRENT?: number;
    public INNODB_LSN_FLUSHED?: number;
    public INNODB_LSN_LAST_CHECKPOINT?: number;
    public INNODB_OS_LOG_FSYNCS?: number;
    public INNODB_OS_LOG_WRITTEN?: number;
    public INNODB_PAGES_CREATED?: number;
    public INNODB_PAGES_READ?: number;
    public INNODB_PAGES_WRITTEN?: number;
    public INNODB_ROW_LOCK_TIME?: number;
    public MAXWRITTEN_CLEAN?: number;
    public OPENED_TABLES?: number;
    public OPEN_TABLES?: number;
    public QUERIES?: number;
    public QUESTIONS?: number;
    public SELECT_FULL_JOIN?: number;
    public SELECT_FULL_RANGE_JOIN?: number;
    public SELECT_RANGE?: number;
    public SELECT_RANGE_CHECK?: number;
    public SELECT_SCAN?: number;
    public SLOW_QUERIES?: number;
    public THREADS_CACHED?: number;
    public THREADS_CONNECTED?: number;
    public THREADS_CREATED?: number;
    public THREADS_RUNNING?: number;
    public WSREP_FLOW_CONTROL_PAUSED?: number;
    public WSREP_FLOW_CONTROL_PAUSED_NS?: number;
    public WSREP_FLOW_CONTROL_RECV?: number;
    public WSREP_FLOW_CONTROL_SENT?: number;
    public WSREP_LOCAL_BF_ABORTS?: number;
    public WSREP_LOCAL_RECV_QUEUE?: number;
    public WSREP_LOCAL_RECV_QUEUE_AVG?: number;
    public WSREP_LOCAL_RECV_QUEUE_MAX?: number;
    public WSREP_LOCAL_RECV_QUEUE_MIN?: number;
    public WSREP_LOCAL_SEND_QUEUE?: number;
    public WSREP_LOCAL_SEND_QUEUE_AVG?: number;
    public WSREP_LOCAL_SEND_QUEUE_MAX?: number;
    public WSREP_LOCAL_SEND_QUEUE_MIN?: number;
    public WSREP_RECEIVED_BYTES?: number;
    public WSREP_REPLICATED_BYTES?: number;
    public WSREP_REPL_DATA_BYTES?: number;
    public WSREP_REPL_KEYS_BYTES?: number;
    public WSREP_REPL_OTHER_BYTES?: number;
    public blocksHit?: number;
    public blocksRead?: number;
    public commits?: number;
    public connections?: number;
    public rollbacks?: number;
    public rowsDeleted?: number;
    public rowsFetched?: number;
    public rowsInserted?: number;
    public rowsUpdated?: number;


    constructor(props: CmonSqlStatsProps) {
        super(props);
        this.ABORTED_CLIENTS = props.ABORTED_CLIENTS;
        this.ABORTED_CONNECTS = props.ABORTED_CONNECTS;
        this.ARCHIVED_COUNT = props.ARCHIVED_COUNT;
        this.BUFFERS_ALLOC = props.BUFFERS_ALLOC;
        this.BUFFERS_BACKEND = props.BUFFERS_BACKEND;
        this.BUFFERS_BACKEND_FSYNC = props.BUFFERS_BACKEND_FSYNC;
        this.BUFFERS_CHECKPOINT = props.BUFFERS_CHECKPOINT;
        this.BUFFERS_CLEAN = props.BUFFERS_CLEAN;
        this.BYTES_RECEIVED = props.BYTES_RECEIVED;
        this.CHECKPOINTS_REQ = props.CHECKPOINTS_REQ;
        this.CHECKPOINTS_TIMED = props.CHECKPOINTS_TIMED;
        this.CHECKPOINT_SYNC_TIME = props.CHECKPOINT_SYNC_TIME;
        this.CHECKPOINT_WRITE_TIME = props.CHECKPOINT_WRITE_TIME;
        this.COM_DELETE = props.COM_DELETE;
        this.COM_INSERT = props.COM_INSERT;
        this.COM_REPLACE = props.COM_REPLACE;
        this.COM_SELECT = props.COM_SELECT;
        this.COM_UPDATE = props.COM_UPDATE;
        this.CONNECTIONS = props.CONNECTIONS;
        this.CREATED_TMP_DISK_TABLES = props.CREATED_TMP_DISK_TABLES;
        this.CREATED_TMP_FILES = props.CREATED_TMP_FILES;
        this.CREATED_TMP_TABLES = props.CREATED_TMP_TABLES;
        this.FAILED_COUNT = props.FAILED_COUNT;
        this.HANDLER_DELETE = props.HANDLER_DELETE;
        this.HANDLER_READ_FIRST = props.HANDLER_READ_FIRST;
        this.HANDLER_READ_KEY = props.HANDLER_READ_KEY;
        this.HANDLER_READ_LAST = props.HANDLER_READ_LAST;
        this.HANDLER_READ_NEXT = props.HANDLER_READ_NEXT;
        this.HANDLER_READ_PREV = props.HANDLER_READ_PREV;
        this.HANDLER_READ_RND = props.HANDLER_READ_RND;
        this.HANDLER_READ_RND_NEXT = props.HANDLER_READ_RND_NEXT;
        this.HANDLER_UPDATE = props.HANDLER_UPDATE;
        this.HANDLER_WRITE = props.HANDLER_WRITE;
        this.INNODB_BUFFER_POOL_PAGES_DATA = props.INNODB_BUFFER_POOL_PAGES_DATA;
        this.INNODB_BUFFER_POOL_PAGES_DIRTY = props.INNODB_BUFFER_POOL_PAGES_DIRTY;
        this.INNODB_BUFFER_POOL_PAGES_FLUSHED = props.INNODB_BUFFER_POOL_PAGES_FLUSHED;
        this.INNODB_BUFFER_POOL_PAGES_FREE = props.INNODB_BUFFER_POOL_PAGES_FREE;
        this.INNODB_BUFFER_POOL_PAGES_LRU_FLUSHED = props.INNODB_BUFFER_POOL_PAGES_LRU_FLUSHED;
        this.INNODB_BUFFER_POOL_PAGES_MADE_NOT_YOUNG = props.INNODB_BUFFER_POOL_PAGES_MADE_NOT_YOUNG;
        this.INNODB_BUFFER_POOL_PAGES_MADE_YOUNG = props.INNODB_BUFFER_POOL_PAGES_MADE_YOUNG;
        this.INNODB_BUFFER_POOL_PAGES_MISC = props.INNODB_BUFFER_POOL_PAGES_MISC;
        this.INNODB_BUFFER_POOL_PAGES_OLD = props.INNODB_BUFFER_POOL_PAGES_OLD;
        this.INNODB_BUFFER_POOL_PAGES_TOTAL = props.INNODB_BUFFER_POOL_PAGES_TOTAL;
        this.INNODB_DATA_FSYNCS = props.INNODB_DATA_FSYNCS;
        this.INNODB_DATA_PENDING_FSYNCS = props.INNODB_DATA_PENDING_FSYNCS;
        this.INNODB_DATA_PENDING_READS = props.INNODB_DATA_PENDING_READS;
        this.INNODB_DATA_PENDING_WRITES = props.INNODB_DATA_PENDING_WRITES;
        this.INNODB_DATA_READ = props.INNODB_DATA_READ;
        this.INNODB_DATA_READS = props.INNODB_DATA_READS;
        this.INNODB_DATA_WRITES = props.INNODB_DATA_WRITES;
        this.INNODB_DATA_WRITTEN = props.INNODB_DATA_WRITTEN;
        this.INNODB_LOG_WAITS = props.INNODB_LOG_WAITS;
        this.INNODB_LSN_CURRENT = props.INNODB_LSN_CURRENT;
        this.INNODB_LSN_FLUSHED = props.INNODB_LSN_FLUSHED;
        this.INNODB_LSN_LAST_CHECKPOINT = props.INNODB_LSN_LAST_CHECKPOINT;
        this.INNODB_OS_LOG_FSYNCS = props.INNODB_OS_LOG_FSYNCS;
        this.INNODB_OS_LOG_WRITTEN = props.INNODB_OS_LOG_WRITTEN;
        this.INNODB_PAGES_CREATED = props.INNODB_PAGES_CREATED;
        this.INNODB_PAGES_READ = props.INNODB_PAGES_READ;
        this.INNODB_PAGES_WRITTEN = props.INNODB_PAGES_WRITTEN;
        this.INNODB_ROW_LOCK_TIME = props.INNODB_ROW_LOCK_TIME;
        this.MAXWRITTEN_CLEAN = props.MAXWRITTEN_CLEAN;
        this.OPENED_TABLES = props.OPENED_TABLES;
        this.OPEN_TABLES = props.OPEN_TABLES;
        this.QUERIES = props.QUERIES;
        this.QUESTIONS = props.QUESTIONS;
        this.SELECT_FULL_JOIN = props.SELECT_FULL_JOIN;
        this.SELECT_FULL_RANGE_JOIN = props.SELECT_FULL_RANGE_JOIN;
        this.SELECT_RANGE = props.SELECT_RANGE;
        this.SELECT_RANGE_CHECK = props.SELECT_RANGE_CHECK;
        this.SELECT_SCAN = props.SELECT_SCAN;
        this.SLOW_QUERIES = props.SLOW_QUERIES;
        this.THREADS_CACHED = props.THREADS_CACHED;
        this.THREADS_CONNECTED = props.THREADS_CONNECTED;
        this.THREADS_CREATED = props.THREADS_CREATED;
        this.THREADS_RUNNING = props.THREADS_RUNNING;
        this.WSREP_FLOW_CONTROL_PAUSED = props.WSREP_FLOW_CONTROL_PAUSED;
        this.WSREP_FLOW_CONTROL_PAUSED_NS = props.WSREP_FLOW_CONTROL_PAUSED_NS;
        this.WSREP_FLOW_CONTROL_RECV = props.WSREP_FLOW_CONTROL_RECV;
        this.WSREP_FLOW_CONTROL_SENT = props.WSREP_FLOW_CONTROL_SENT;
        this.WSREP_LOCAL_BF_ABORTS = props.WSREP_LOCAL_BF_ABORTS;
        this.WSREP_LOCAL_RECV_QUEUE = props.WSREP_LOCAL_RECV_QUEUE;
        this.WSREP_LOCAL_RECV_QUEUE_AVG = props.WSREP_LOCAL_RECV_QUEUE_AVG;
        this.WSREP_LOCAL_RECV_QUEUE_MAX = props.WSREP_LOCAL_RECV_QUEUE_MAX;
        this.WSREP_LOCAL_RECV_QUEUE_MIN = props.WSREP_LOCAL_RECV_QUEUE_MIN;
        this.WSREP_LOCAL_SEND_QUEUE = props.WSREP_LOCAL_SEND_QUEUE;
        this.WSREP_LOCAL_SEND_QUEUE_AVG = props.WSREP_LOCAL_SEND_QUEUE_AVG;
        this.WSREP_LOCAL_SEND_QUEUE_MAX = props.WSREP_LOCAL_SEND_QUEUE_MAX;
        this.WSREP_LOCAL_SEND_QUEUE_MIN = props.WSREP_LOCAL_SEND_QUEUE_MIN;
        this.WSREP_RECEIVED_BYTES = props.WSREP_RECEIVED_BYTES;
        this.WSREP_REPLICATED_BYTES = props.WSREP_REPLICATED_BYTES;
        this.WSREP_REPL_DATA_BYTES = props.WSREP_REPL_DATA_BYTES;
        this.WSREP_REPL_KEYS_BYTES = props.WSREP_REPL_KEYS_BYTES;
        this.WSREP_REPL_OTHER_BYTES = props.WSREP_REPL_OTHER_BYTES;
        this.blocksHit = props['blocks-hit'];
        this.blocksRead = props['blocks-read'];
        this.commits = props.commits;
        this.connections = props.connections;
        this.rollbacks = props.rollbacks;
        this.rowsDeleted = props['rows-deleted'];
        this.rowsFetched = props['rows-fetched'];
        this.rowsInserted = props['rows-inserted'];
        this.rowsUpdated = props['rows-updated'];

    }

}
