import React from 'react';
import { CmonJobInstanceCommand } from '../../services/cmon/models/CmonJobInstance';
import ActionButton from '@severalnines/bar-frontend-components/build/lib/General/ActionButton';
import JobButtonBarrier from '../../common/Barrier/JobButtonBarrier';
import { CcClusterType } from '../../services/models/CcCluster';
import JobWatcherButton from './JobWatcherButton';

export default JobActionButton;

export type JobActionButtonProps = {
    clusterId?: number;
    clusterType?: CcClusterType;
    command: CmonJobInstanceCommand;
    children?: React.ReactNode;
    critical?: boolean;
    extraKey?: (string | number | undefined)[];
    disabled?: boolean;
};

function JobActionButton({
    clusterId,
    clusterType,
    command,
    critical,
    children,
    extraKey,
    ...rest
}: JobActionButtonProps) {
    return (
        <JobButtonBarrier command={command} clusterType={clusterType}>
            <JobWatcherButton
                command={command}
                clusterId={clusterId}
                extraKey={extraKey}
                button={
                    <ActionButton critical={critical} {...rest}>
                        {children}
                    </ActionButton>
                }
            />
        </JobButtonBarrier>
    );
}
