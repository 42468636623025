import React, { useEffect, useState } from 'react';
import SpaceWide from '../../../common/SpaceWide';
import { Space } from 'antd';
import ModalDefault from '../../../common/ModalDefault';
import CcGroup from '../../../services/models/CcGroup';
import GroupInfo from '../GroupInfo';
import SpaceDescriptions from '../../../common/Layout/SpaceDescriptions';
import GroupPermissionsInfo from '../GroupPermissionsInfo';
import useUsersList from '../useUsersList';
import UserFormat from '../UserFormat';
import TypographyText from '../../../common/TypographyText';
import CcUser from '../../../services/models/CcUser';
import GroupEditButton from './GroupEditButton';
import AppSpin from '../../../common/General/AppSpin';

export default GroupDetailsModal;

export type GroupDetailsModalProps = {
    group: CcGroup;
    onCancel?: () => void;
    onEdit?: () => void;
};

function GroupDetailsModal({
    group,
    onCancel,
    onEdit,
}: GroupDetailsModalProps) {
    const {
        list: users,
        loading: loadingUsers,
        loaded: loadedUsers,
        refresh: refreshUsers,
        total,
    } = useUsersList({
        pageSize: 0,
        useCache: true,
        filters: [
            (user: CcUser) => user.getGroup().groupName === group.groupName,
        ],
    });
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        (async () => {
            await refreshUsers();
        })();
    }, []);

    useEffect(() => {
        setLoading(false);
    }, [group]);

    const handleEditGroupSuccess = () => {
        setLoading(true);
        onEdit?.();
    };
    return (
        <ModalDefault
            title="Team details"
            onCancel={onCancel}
            width={900}
            visible={true}
            bodyStyle={{ padding: '25px' }}
            footer={
                <SpaceWide justify="flex-end">
                    <GroupEditButton
                        group={group}
                        type="primary"
                        onSuccess={handleEditGroupSuccess}
                        size="middle"
                    >
                        Edit user
                    </GroupEditButton>
                </SpaceWide>
            }
            loading={loadingUsers || loading}
        >
            <SpaceWide direction="vertical" style={{ margin: '0 auto' }}>
                <SpaceWide align="center" direction="vertical">
                    <GroupInfo group={group} size="large" />
                    {loadedUsers ? (
                        <TypographyText muted={true}>
                            {total} User{total === 1 ? '' : 's'}
                        </TypographyText>
                    ) : null}
                </SpaceWide>
                <div style={{ textAlign: 'center' }}>
                    <Space
                        direction="vertical"
                        style={{
                            width: '80%',
                            textAlign: 'left',
                            margin: 'auto',
                        }}
                        size={24}
                    >
                        {(users && users.length > 0) || !loadedUsers ? (
                            <SpaceDescriptions title="Users">
                                <SpaceDescriptions.Item>
                                    <Space>
                                        {users &&
                                            users.map((u) => (
                                                <UserFormat
                                                    key={u.userName}
                                                    user={u}
                                                />
                                            ))}
                                    </Space>
                                </SpaceDescriptions.Item>
                            </SpaceDescriptions>
                        ) : null}

                        {!loading ? (
                            <GroupPermissionsInfo group={group} />
                        ) : (
                            <AppSpin spinning={true} />
                        )}
                    </Space>
                </div>
            </SpaceWide>
        </ModalDefault>
    );
}
