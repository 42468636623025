import CcProxySqlSchedule from '../../../../../../services/models/CcProxySqlSchedule';
import { ProxySQLSchedulerFormValues } from './ProxySQLSchedulerScriptModal';

export function prepareScriptInitialValue(script: CcProxySqlSchedule) {
    return {
        filename: script.filename,
        arguments: [
            `${script.arg1 ? script.arg1 : ''}`,
            `${script.arg2 ? script.arg2 : ''}`,
            `${script.arg3 ? script.arg3 : ''}`,
            `${script.arg4 ? script.arg4 : ''}`,
            `${script.arg5 ? script.arg5 : ''}`,
        ].filter((str) => str),
        interval: script.intervalMs,
        comment: script.comment ? script.comment : '',
        active: (script.active as any) === '1' ? true : false,
    };
}

export function prepareAddScript(script: ProxySQLSchedulerFormValues) {
    const [arg1, arg2, arg3, arg4, arg5] = Array.isArray(script.arguments)
        ? script.arguments
        : [];
    return {
        active: script.active ? '1' : '0',
        arg1,
        arg2,
        arg3,
        arg4,
        arg5,
        comment: script.comment ? script.comment : '',
        filename: script.filename,
        interval_ms: Number(script.interval),
        class_name: 'CmonProxySqlSchedule',
        id: null,
    };
}

export function prepareEditScript(script: ProxySQLSchedulerFormValues) {
    const [arg1, arg2, arg3, arg4, arg5] = script.arguments;
    return {
        active: script.active ? '1' : '0',
        arg1,
        arg2,
        arg3,
        arg4,
        arg5,
        comment: script.comment ? script.comment : '',
        filename: script.filename,
        interval_ms: Number(script.interval),
        class_name: 'CmonProxySqlSchedule',
    };
}
