export enum CcCloudProviderType {
    AWS = 'aws',
    GOOGLE = 'gce',
    AZURE = 'az',
    S3 = 's3',
}

export interface CcCloudCredentialsDTO {
    id?: number;
    name: string;
    comment: string;
    provider: CcCloudProviderType;
    credentials: CcCloudCredentialsFields;
}

export type CcCloudCredentialsAwsFields = {
    access_key_id: string;
    access_key_secret: string;
    access_key_region: string;
};

export type CcCloudCredentialsGoogleFields = {
    type: string;
    project_id: string;
    private_key_id: string;
    private_key: string;
    client_email: string;
    client_id: string;
    auth_uri: string;
    token_uri: string;
    auth_provider_x509_cert_url: string;
    client_x509_cert_url: string;
};
export type CcCloudCredentialsAzureFields = {
    application_id: string;
    client_secret: string;
    subscription_id: string;
    tenant_id: string;
    resource_group: string;
    storage_account: string;
};
export type CcCloudCredentialsS3Fields = {
    endpoint: string;
    access_key_region: string;
    access_key_id: string;
    access_key_secret: string;
    use_ssl: string;
};

export type CcCloudCredentialsFields = CcCloudCredentialsAwsFields &
    CcCloudCredentialsGoogleFields &
    CcCloudCredentialsAzureFields &
    CcCloudCredentialsS3Fields;

export interface CcCloudBucket {
    name: string;
}

export type CcCloudCredentialsProps = {
    id: number;
    name: string;
    comment: string;
    provider: CcCloudProviderType;
    credentials: CcCloudCredentialsFields;
};

export default class CcCloudCredentials {
    public readonly id: number;
    public readonly name: string;
    public readonly comment: string;
    public readonly provider: CcCloudProviderType;
    public readonly credentials: CcCloudCredentialsFields;
    constructor(props: CcCloudCredentialsProps) {
        this.id = props.id;
        this.name = props.name;
        this.comment = props.comment;
        this.provider = props.provider;
        this.credentials = props.credentials;
    }
}
