import merge from 'deepmerge';
import RedisConfigurator, {
    RedisFormValues,
} from '../../Redis/RedisConfigurator';
import React from 'react';
import RedisNodeConfiguration, {
    RedisNodeConfigurationProps,
} from '../../Redis/RedisNodeConfiguration';
import RedisAdvancedNodeConfiguration from './RedisAdvancedNodeConfiguration';

export default class RedisImportConfigurator extends RedisConfigurator {
    public static getDefaults(): RedisFormValues {
        return merge(RedisConfigurator.getDefaults(), {
            nodeConfig: {
                dbPassword: '',
                clusterAutoRecovery: false,
                nodeAutoRecovery: false,
            },
        });
    }

    public static getJobData(formValues: RedisFormValues): any {
        const { nodeConfig } = formValues;

        return merge(RedisConfigurator.getJobData(formValues), {
            enable_cluster_autorecovery: nodeConfig.clusterAutoRecovery,
            enable_node_autorecovery: nodeConfig.nodeAutoRecovery,
        });
    }

    public static getJobOptions(formValues: RedisFormValues): any {
        return merge(RedisConfigurator.getJobOptions(formValues), {
            job: {
                title: 'Import Redis Sentinel Cluster',
            },
        });
    }

    public static getNodeConfigurationStep({
        form,
    }: RedisNodeConfigurationProps): React.ReactNode {
        return (
            <>
                <RedisNodeConfiguration
                    form={form}
                    hasRepository={false}
                    importCluster={true}
                />
                <RedisAdvancedNodeConfiguration />
            </>
        );
    }
}
