import React, { useMemo } from 'react';
import ActionsMenu from '../../common/Navigation/ActionsMenu';
import ActionButton from '@severalnines/bar-frontend-components/build/lib/General/ActionButton';
import { SettingsIcon } from '../../common/icons/icons';
import CcCluster from '../../services/models/CcCluster';
import ButtonWithForm from '../../common/General/ButtonWithForm';
import ClusterEnablePrometheusButton from '../Clusters/Actions/ClusterEnablePrometheusButton';
import PrometheusExportersModal from '../Prometheus/PrometheusExportersModal';
import PrometheusConfigurationModal from '../Prometheus/PrometheusConfigurationModal';
import ClusterDisablePrometheusButton from '../Clusters/Actions/ClusterDisablePrometheusButton';
import { ActionMenuItem } from '@severalnines/bar-frontend-components/build/lib/Navigation/ActionMenu';

export default MonitorSettingsMenu;

export type MonitorSettingsMenuProps = {
    cluster: CcCluster;
    extraItems?: React.ReactNode[];
};

function MonitorSettingsMenu({
    cluster,
    extraItems,
}: MonitorSettingsMenuProps) {
    const menuItems = useMemo(() => {
        const node = cluster.getPrometheusNode();
        let items: React.ReactNode[] = [
            {
                key: 'enable_monitoring',
                label: (
                    <ClusterEnablePrometheusButton
                        reEnable={!!node}
                        cluster={cluster}
                    />
                ),
            },
        ];
        if (node) {
            items.unshift(
                {
                    key: 'exporters',
                    label: (
                        <ButtonWithForm
                            button={<ActionButton>Exporters</ActionButton>}
                            form={
                                <PrometheusExportersModal
                                    node={node}
                                    cluster={cluster}
                                />
                            }
                        />
                    ),
                },
                {
                    key: 'configuration',
                    label: (
                        <ButtonWithForm
                            button={<ActionButton>Configuration</ActionButton>}
                            form={
                                <PrometheusConfigurationModal
                                    cluster={cluster}
                                    node={node}
                                />
                            }
                        />
                    ),
                }
            );
            items.push({
                key: 'disable_monitoring',
                label: <ClusterDisablePrometheusButton cluster={cluster} />,
            });
        }
        if (extraItems && extraItems.length) {
            items = items.concat(extraItems);
        }
        return items;
    }, [cluster, extraItems]);
    return (
        <ActionsMenu items={menuItems as ActionMenuItem[]}>
            <SettingsIcon />
        </ActionsMenu>
    );
}
