import './PromoCard.less';
import React, { ReactNode, useMemo } from 'react';
import { Space } from 'antd';
import classNames from 'classnames';
import SpaceWide from '../SpaceWide';
import AppCard, { AppCardProps } from '../DataDisplay/AppCard';

export default PromoCard;

export type PromoCardProps = AppCardProps & {
    title: ReactNode;
    extra: ReactNode;
    bg?: 'circles-top-left' | 'circles-bottom-right' | 'squares';
    iconType?: 'install' | undefined;
};

function PromoCard({
    title,
    extra,
    className,
    bg,
    children,
    iconType,
    ...rest
}: PromoCardProps) {
    const icon = useMemo(() => {
        switch (iconType) {
            case 'install':
                return (
                    <img
                        alt="Installation"
                        src={require('./img-icon-install.svg')}
                    />
                );
        }
        return null;
    }, [iconType]);

    return (
        <AppCard
            className={classNames(
                'PromoCard',
                { [`PromoCard--bg-${bg}`]: bg },
                className
            )}
            {...rest}
        >
            <SpaceWide justify="space-between" direction="vertical">
                <Space direction="vertical" className="PromoCard_content">
                    <h3>{title}</h3>
                    {children}
                </Space>
                <SpaceWide justify="space-between" className="PromoCard_footer">
                    <Space>{extra}</Space>
                    {icon}
                </SpaceWide>
            </SpaceWide>
        </AppCard>
    );
}
