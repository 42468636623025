import React from 'react';
import { ButtonProps } from 'antd/lib/button';
import { Button } from 'antd';

export default MailtoButton;
export type MailtoButtonProps = ButtonProps & { mailto: string };

function MailtoButton({ children, mailto, ...rest }: MailtoButtonProps) {
    return (
        <Button
            onClick={(e) => {
                window.location.href = mailto;
                e.preventDefault();
            }}
            {...rest}
        >
            {children}
        </Button>
    );
}
