import React from 'react';
import { Form } from 'antd';
import { FormItemProps } from 'antd/lib/form';
import ClusterNodeSelect, {
    ClusterNodeSelectProps,
} from '../../DataEntry/ClusterNodeSelect';

export default PrimaryNodeSelectField;

export type PrimaryNodeSelectFieldProps = FormItemProps & {
    selectProps: ClusterNodeSelectProps;
};

function PrimaryNodeSelectField({
    selectProps,
    ...rest
}: PrimaryNodeSelectFieldProps) {
    return (
        <div className="PrimaryNodeSelectField">
            <Form.Item
                name="nodePrimary"
                label="Primary node"
                rules={[
                    { required: true, message: 'Please select primary node' },
                ]}
                {...rest}
            >
                <ClusterNodeSelect {...selectProps} />
            </Form.Item>
        </div>
    );
}
