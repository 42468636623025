export const SUPPORTED_EVENTS_POSTGRES = [
    {
        value: 'ROLE',
        label: 'ROLE',
    },
    {
        value: 'DDL',
        label: 'DDL',
    },
    {
        value: 'MISC',
        label: 'MISC',
    },
    {
        value: 'READ',
        label: 'READ',
    },
    {
        value: 'WRITE',
        label: 'WRITE',
    },
    {
        value: 'FUNCTION',
        label: 'FUNCTION',
    },
    {
        value: 'ALL',
        label: 'ALL',
    },
];

export const SUPPORTED_EVENTS = [
    {
        value: 'CONNECT',
        label: 'CONNECT',
    },
    {
        value: 'QUERY',
        label: 'QUERY',
    },
    {
        value: 'QUERY_DCL',
        label: 'QUERY_DCL',
    },
    {
        value: 'QUERY_DDL',
        label: 'QUERY_DDL',
    },
    {
        value: 'QUERY_DML',
        label: 'QUERY_DML',
    },
    {
        value: 'QUERY_DML_NO_SELECT',
        label: 'QUERY_DML_NO_SELECT',
    },
    {
        value: 'TABLE',
        label: 'TABLE',
    },
];

export const SUPPORTED_FORMATS = ['OLD', 'NEW', 'CSV', 'JSON'];

export const SUPPORTED_STRATEGIES = [
    'ASYNCHRONOUS',
    'PERFORMANCE',
    'SEMISYNCHRONOUS',
    'SYNCHRONOUS',
];

export const SUPPORTED_POLICIES = ['ALL', 'LOGINS', 'QUERIES', 'NONE'];
