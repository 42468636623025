import React, { useEffect } from 'react';
import CmonAuthService from '../../services/cmon/CmonAuthService';
import LoadingPage from '../../common/LoadingPage';

export default LogoutPage;

export type LogoutPageProps = {};

function LogoutPage({}: LogoutPageProps) {
    useEffect(() => {
        (async () => {
            try {
                await CmonAuthService.logout({});
            } catch (err) {
                console.debug('logout', err);
            }

            // nasty way of logout
            window.document.cookie = window.document.cookie + ';max-age=0';
            window.location.assign('/login');
        })();
    }, []);
    return (
        <div className="LogoutPage">
            <LoadingPage />
        </div>
    );
}
