export interface CcAuditLogProps {
    class_name: string;
    id: number;
    client_hostname: string;
    cluster_id: number;
    entry_type: string;
    message_text: string;
    report_ts: string;
    username: string;
}

export default class CcAuditLog {
    public readonly className?: string;
    public readonly id: number;
    public readonly clientHostname?: string;
    public readonly clusterId?: number;
    public readonly entryType?: string;
    public readonly messageText?: string;
    public readonly reportTs?: string;
    public readonly userName?: string;

    constructor(props: CcAuditLogProps) {
        this.className = props.class_name;
        this.id = props.id;
        this.clientHostname = props.client_hostname;
        this.clusterId = props.cluster_id;
        this.entryType = props.entry_type;
        this.messageText = props.message_text;
        this.reportTs = props.report_ts;
        this.userName = props.username;
    }

    public getAuditLogMessage() {
        return this.messageText || '';
    }

    public getKey(): string {
        return `${this.id}`;
    }
    public getClusterKey(): string {
        return `${this.clusterId}`;
    }
}
