import React, { useState } from 'react';
import { ButtonProps } from 'antd/lib/button';
import ConfirmActionButton from '@severalnines/bar-frontend-components/build/lib/General/ConfirmActionButton';
import CmonUsersService from '../../../services/cmon/CmonUsersService';
import { useDebugContext } from '../../../common/Debug';
import { notifyError } from '../../Notifications/uiNotification';
import CcGroup from '../../../services/models/CcGroup';

export default GroupDeleteButton;

export type GroupDeleteButtonProps = ButtonProps & {
    group: CcGroup;
    onSuccess?: () => void;
    onError?: (error: any) => void;
};

function GroupDeleteButton({
    group,
    children,
    onSuccess,
    onError,
    ...rest
}: GroupDeleteButtonProps) {
    const { log } = useDebugContext();
    const [loading, setLoading] = useState(false);
    const handleClick = async () => {
        try {
            setLoading(true);
            await CmonUsersService.deleteGroup({
                group: {
                    class_name: 'CmonGroup',
                    group_name: group.groupName,
                },
            });

            onSuccess?.();
        } catch (err) {
            log.error(err);
            notifyError({ content: err.message });
            onError?.(err);
        }
        setLoading(false);
    };

    return (
        <ConfirmActionButton
            confirmTitle={'Delete team?'}
            onConfirm={handleClick}
            loading={loading}
            critical={true}
            {...rest}
        >
            {children || 'Delete'}
        </ConfirmActionButton>
    );
}
