import React, { useState } from 'react';
import { notifyError } from '../Notifications/uiNotification';
import { useDebugContext } from '../../common/Debug';
import CmonReportsService from '../../services/cmon/CmonReportsService';
import CcReport from '../../services/models/CcReport';
import ActionButton from '@severalnines/bar-frontend-components/build/lib/General/ActionButton';

export default OperationalReportViewButton;

export type OperationalReportViewButtonProps = {
    report: CcReport;
};

function OperationalReportViewButton({
    report,
}: OperationalReportViewButtonProps) {
    const { log } = useDebugContext();
    const [loading, setLoading] = useState<boolean>(false);

    const handleClick = async () => {
        try {
            setLoading(true);
            const response = await CmonReportsService.getReport({
                cluster_id: report.clusterId,
                report: {
                    report_id: report.reportId,
                    text_format: 'HtmlDocument',
                },
            });
            setLoading(false);
            const reportWindow = window.open(
                '',
                `report-window-${report.reportId}`
            );
            if (reportWindow) {
                reportWindow.document.open();
                reportWindow.document.write(response.report.content);
                const filename = report?.path?.split('/').pop();
                reportWindow.document.title = filename || '';
                reportWindow.document.close();
            }
        } catch (e) {
            notifyError({ content: e.message });
            log.error(e);
        }
    };

    return (
        <ActionButton onClick={handleClick} loading={loading}>
            {'View'}
        </ActionButton>
    );
}
