import React, { forwardRef, useImperativeHandle, useState } from 'react';
import TweenOne from 'rc-tween-one';

export default forwardRef(MotionShake);

export type MotionShakeProps = {
    children?: React.ReactNode;
    durationMs?: number;
};

export interface MotionApi {
    animate: () => void;
}

function MotionShake({ children, durationMs }: MotionShakeProps, ref: any) {
    const [animationPaused, setAnimationPaused] = useState<boolean>(true);
    const [animationMoment, setAnimationMoment] = useState<
        number | undefined
    >();

    useImperativeHandle(
        ref,
        (): MotionApi => ({
            //exposing methods to parent through ref
            animate() {
                setTimeout(() => {
                    setAnimationMoment(0);
                    setAnimationPaused(false);
                    setTimeout(() => {
                        setAnimationMoment(undefined);
                    });
                });
            },
        })
    );

    return (
        <TweenOne
            animation={getAnimationSteps({ durationMs })}
            paused={animationPaused}
            moment={animationMoment}
            repeat={1}
        >
            {children}
        </TweenOne>
    );
}

function getAnimationSteps({ durationMs = 200 }: { durationMs?: number }) {
    const duration = durationMs / 5;
    return [
        {
            marginLeft: '-10px',
            marginRight: '10px',
            duration,
        },
        {
            marginLeft: '10px',
            marginRight: '-10px',
            duration: duration * 2,
        },
        {
            marginLeft: '0',
            marginRight: '0',
            duration,
        },
    ];
}
