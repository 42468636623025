import React from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb as AntBreadcrumb } from 'antd';
import useBreadcrumb, { BreadcrumbConfig } from './useBreadcrumb';

export default Breadcrumb;

export type BreadcrumbProps = {
    config: BreadcrumbConfig;
};

function Breadcrumb({ config }: BreadcrumbProps) {
    const { items } = useBreadcrumb({ config });
    return items.length > 0 ? (
        <AntBreadcrumb>
            {items.map((item) => (
                <AntBreadcrumb.Item key={item.path}>
                    <Link to={item.path}>{item.name}</Link>
                </AntBreadcrumb.Item>
            ))}
        </AntBreadcrumb>
    ) : null;
}
