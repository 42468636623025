import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { AppState } from '../../appReducer';
import ActionsMenu, {
    ActionsMenuProps,
} from '../../common/Navigation/ActionsMenu';
import JobActionsMenu from './JobActionsMenu';
import ActionButton from '@severalnines/bar-frontend-components/build/lib/General/ActionButton';

export default RunningJobActionsMenu;
export type RunningJobActionsMenuProps = Omit<ActionsMenuProps, 'job'> & {
    jobId: number;
};

function RunningJobActionsMenu({ jobId, ...rest }: RunningJobActionsMenuProps) {
    const [storedRunningJobs] = useSelector(({ runningJobs }: AppState) => [
        runningJobs,
    ]);
    const job = useMemo(() => {
        return storedRunningJobs.find((job) => job.jobId === jobId);
    }, [storedRunningJobs]);
    return job ? (
        <JobActionsMenu job={job} {...rest} />
    ) : (
        <ActionsMenu
            items={[
                {
                    key: 'details',
                    label: <ActionButton disabled={true} />,
                },
            ]}
        />
    );
}
