import { Col, Form, Input, Space } from 'antd';
import InfoIcon from '@severalnines/bar-frontend-components/build/lib/General/InfoIcon';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import React, { useRef } from 'react';
import { FormInstance } from 'antd/lib/form';

export default SshCredentials;
export type SshCredentialsProps = { form: FormInstance };

function SshCredentials({ form }: SshCredentialsProps) {
    const sshKetPathTouched = useRef(false);
    const handleSshPathChange = () => {
        sshKetPathTouched.current = true;
    };
    return (
        <>
            <Col span={24}>
                <h3>SSH Credentials</h3>
            </Col>
            <Col xs={24} sm={24} md={12}>
                <Form.Item
                    name={['sshConfig', 'sshUser']}
                    label={
                        <Space>
                            SSH user
                            <InfoIcon
                                info={
                                    <span>
                                        Specify root if you have root
                                        credentials if you use <b>sudo</b> to
                                        execute system commands, specify the
                                        username that you wish to use here. The
                                        user must exists on all nodes.
                                    </span>
                                }
                            />
                        </Space>
                    }
                    rules={[
                        {
                            required: true,
                            message: 'Please enter a SSH user.',
                        },
                    ]}
                >
                    <Input placeholder="Enter SSH user" />
                </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12}>
                <Form.Item
                    shouldUpdate={(prev, current) => {
                        if (
                            prev.sshConfig?.sshUser !==
                                current.sshConfig?.sshUser &&
                            !sshKetPathTouched.current
                        ) {
                            const user = form.getFieldValue([
                                'sshConfig',
                                'sshUser',
                            ]);
                            form.setFieldsValue({
                                sshConfig: {
                                    sshKeyPath:
                                        user === 'root'
                                            ? '/root/.ssh/id_rsa'
                                            : user
                                            ? `/home/${user}/.ssh/id_rsa`
                                            : '',
                                },
                            });
                            return true;
                        }
                        return false;
                    }}
                    noStyle
                >
                    {() => {
                        return (
                            <Form.Item
                                name={['sshConfig', 'sshKeyPath']}
                                label={
                                    <Space>
                                        SSH user key path
                                        <InfoIcon
                                            info={
                                                <span>
                                                    Specify the full path of SSH
                                                    key (the key must exist in
                                                    ClusterControl node) that
                                                    will be used by{' '}
                                                    <strong>SSH User</strong> to
                                                    perform passwordless SSH.
                                                </span>
                                            }
                                        />
                                    </Space>
                                }
                                rules={[
                                    {
                                        required: true,
                                        message:
                                            'Please enter the SSH key path.',
                                    },
                                ]}
                            >
                                <Input
                                    placeholder="Enter SSH key path"
                                    onChange={handleSshPathChange}
                                />
                            </Form.Item>
                        );
                    }}
                </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12}>
                <Form.Item
                    name={['sshConfig', 'sshPort']}
                    label={
                        <Space>
                            SSH port
                            <InfoIcon
                                info={
                                    <span>
                                        Specify the SSH port for target nodes.
                                        ClusterControl assumes SSH is running on
                                        the same port on all nodes.
                                    </span>
                                }
                            />
                        </Space>
                    }
                    rules={[
                        {
                            required: true,
                            message: 'Please enter the SSH port.',
                        },
                    ]}
                >
                    <Input placeholder="Enter SSH port" />
                </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12}>
                <Form.Item
                    shouldUpdate={(prev, current) => {
                        if (
                            form.getFieldValue(['sshConfig', 'sshUser']) ===
                                'root' &&
                            form.getFieldValue([
                                'sshConfig',
                                'sshSudoPassword',
                            ]) !== ''
                        ) {
                            form.setFieldsValue({
                                sshConfig: {
                                    sshSudoPassword: '',
                                },
                            });
                        }
                        return (
                            prev.sshConfig?.sshSudoPassword !==
                            current.sshConfig?.sshSudoPassword
                        );
                    }}
                    noStyle
                >
                    {() => {
                        const passwordDisabled =
                            form.getFieldValue(['sshConfig', 'sshUser']) ===
                            'root';
                        return (
                            <Form.Item
                                label={
                                    <Space>
                                        SSH sudo password
                                        <InfoIcon
                                            info={
                                                <span>
                                                    Specify the password if the
                                                    SSH user that you specified
                                                    under{' '}
                                                    <strong>SSH User</strong>{' '}
                                                    requires sudo password to
                                                    run super-privileged
                                                    commands. Ignore this if{' '}
                                                    <strong>SSH User</strong> is
                                                    root or have no sudo
                                                    password.
                                                </span>
                                            }
                                        />
                                    </Space>
                                }
                                name={['sshConfig', 'sshSudoPassword']}
                            >
                                <Input.Password
                                    placeholder="Enter SSH sudo password"
                                    iconRender={(visible) =>
                                        visible ? (
                                            <EyeTwoTone />
                                        ) : (
                                            <EyeInvisibleOutlined />
                                        )
                                    }
                                    disabled={passwordDisabled}
                                />
                            </Form.Item>
                        );
                    }}
                </Form.Item>
            </Col>
        </>
    );
}

export function getSshCredentialsValidate() {
    return [
        ['sshConfig', 'sshUser'],
        ['sshConfig', 'sshKeyPath'],
        ['sshConfig', 'sshPort'],
        ['sshConfig', 'sshSudoPassword'],
    ];
}
