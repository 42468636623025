import React, { Fragment, useState } from 'react';
import CcNode, { CcNodeType } from '../../services/models/CcNode';
import { Space, Tag } from 'antd';
import NodeStatusFormat, { getNodeStatusFormatType } from './NodeStatusFormat';

import { StatusFormatType } from '@severalnines/bar-frontend-components/build/lib/Format/StatusFormat';
import UnavailableFormat from '@severalnines/bar-frontend-components/build/lib/Format/UnavailableFormat';
import WrapFormat, { WrapFormatProps } from '../../common/Format/WrapFormat';
import SpaceDescriptions from '../../common/Layout/SpaceDescriptions';
import ClusterFormat from '../Clusters/ClusterFormat';
import { AppState, AppStateClustersMap } from '../../appReducer';
import { useSelector } from 'react-redux';
import NodeTypeFormat, { getNodeTypeText } from './NodeTypeFormat';
import TypographyText from '../../common/TypographyText';
import CcHost from '../../services/models/CcHost';
import { NodesIcon } from '../../common/icons/icons';
import SpaceWide from '../../common/SpaceWide';
import MaintenanceFormat from '../Maintenance/MaintenanceFormat';
import YesNoFormat from '../../common/Format/YesNoFormat';
import AppDivider from '../../common/AppDivider';
import NodeRoleFormat from './NodeRoleFormat';
import AppDateFormat from '../../common/AppDateFormat';
import CcMySqlNode from '../../services/models/CcMySqlNode';
import CcPostgreSqlNode from '../../services/models/CcPostgreSqlNode';
import { truncateText } from '@severalnines/bar-frontend-components/build/lib/Format/TruncateText';
import NodeRunningJobsFormat from './NodeRunningJobsFormat';

export type NodeFormatProps = WrapFormatProps & {
    node: CcNode;
    showType?: boolean;
    showStatus?: boolean;
    showText?: boolean;
    truncate?: boolean;
    showRole?: boolean;
    extraRight?: React.ReactNode;
};

export default function NodeFormat({
    node,
    showStatus,
    showType = true,
    showText = true,
    truncate,
    showRole = true,
    extraRight,
    size,
    children,
    popoverProps,
    ...rest
}: NodeFormatProps) {
    const [clustersMap]: [
        AppStateClustersMap
    ] = useSelector(({ clusters }: AppState) => [clusters]);
    const nodeHostname = showRole
        ? getNodeHostWithDesc(node, true, truncate)
        : node.getHostWithPort();
    const content =
        size === 'large' ? (
            <Space className="NodeFormat" size={15}>
                {showType ? (
                    <NodeTypeFormat
                        type={node.nodetype}
                        vendor={node.vendor}
                        showDesc={false}
                        showIcon={true}
                    />
                ) : null}

                <TypographyText strong style={{ flexGrow: 1 }}>
                    {nodeHostname}
                </TypographyText>

                {showStatus ? (
                    <NodeStatusFormat
                        node={node as CcNode}
                        type={StatusFormatType.iconCircled}
                        showTooltip={false}
                    />
                ) : null}
                {extraRight}
            </Space>
        ) : (
            <Space className="NodeFormat" size={5}>
                {showStatus ? (
                    <NodeStatusFormat
                        node={node as CcNode}
                        type={StatusFormatType.iconCircled}
                        showTooltip={false}
                    />
                ) : null}
                {showType ? (
                    <NodeTypeFormat
                        type={node.nodetype}
                        vendor={node.vendor}
                        showDesc={false}
                        showIcon={true}
                    />
                ) : null}
                {showText ? <span>{nodeHostname}</span> : null}
                {extraRight}
            </Space>
        );
    return (
        <UnavailableFormat val={node}>
            <WrapFormat
                size={size}
                popoverStatus={getNodeStatusFormatType(node.hoststatus)}
                popoverProps={{
                    title: (
                        <Space>
                            <NodesIcon /> Node information
                        </Space>
                    ),
                    destroyTooltipOnHide: true,
                    overlayInnerStyle: { minWidth: '490px' },
                    ...popoverProps,
                }}
                popoverContent={
                    <SpaceWide direction="vertical" size={10}>
                        <SpaceDescriptions direction="horizontal" size={20}>
                            <SpaceDescriptions.Item
                                label="Hostname"
                                direction="vertical"
                                colon={false}
                            >
                                {node.hostname}
                            </SpaceDescriptions.Item>

                            <SpaceDescriptions.Item
                                label="Port"
                                direction="vertical"
                                colon={false}
                            >
                                {node.port}
                            </SpaceDescriptions.Item>
                            <SpaceDescriptions.Item
                                label="Cluster"
                                direction="vertical"
                                colon={false}
                            >
                                <ClusterFormat
                                    cluster={clustersMap.get(
                                        node.getClusterKey()
                                    )}
                                />
                            </SpaceDescriptions.Item>
                            <SpaceDescriptions.Item
                                label="Status"
                                direction="vertical"
                                colon={false}
                            >
                                <NodeStatusFormat
                                    node={node}
                                    showTooltip={false}
                                />
                            </SpaceDescriptions.Item>
                        </SpaceDescriptions>
                        <AppDivider />
                        <SpaceDescriptions direction="horizontal" size={10}>
                            <SpaceDescriptions.Item
                                label="Role"
                                direction="vertical"
                                colon={false}
                            >
                                <NodeRoleFormat wrap={true} node={node} />
                            </SpaceDescriptions.Item>

                            <SpaceDescriptions.Item
                                label="Type"
                                direction="vertical"
                                colon={false}
                            >
                                {getNodeTypeText(node.nodetype)}
                            </SpaceDescriptions.Item>
                            <SpaceDescriptions.Item
                                label="Last seen"
                                direction="vertical"
                                colon={false}
                            >
                                <AppDateFormat fromNow>
                                    {node.lastseen
                                        ? new Date(node.lastseen * 1000)
                                        : undefined}
                                </AppDateFormat>
                            </SpaceDescriptions.Item>
                            <SpaceDescriptions.Item
                                label="Maintenance"
                                direction="vertical"
                                colon={false}
                            >
                                <MaintenanceFormat
                                    node={node}
                                    emptyText="No schedules"
                                    asText={true}
                                />
                            </SpaceDescriptions.Item>
                        </SpaceDescriptions>
                        <AppDivider />
                        <SpaceDescriptions direction="horizontal" size={10}>
                            {node.isReplica() &&
                            node.isType([
                                CcNodeType.POSTGRESQL,
                                CcNodeType.MYSQL,
                            ]) ? (
                                <SpaceDescriptions.Item
                                    label="Replication running"
                                    direction="vertical"
                                    colon={false}
                                >
                                    <YesNoFormat
                                        booleanVar={
                                            (node as any)
                                                .isReplicationRunning &&
                                            (node as
                                                | CcMySqlNode
                                                | CcPostgreSqlNode).isReplicationRunning()
                                        }
                                    />
                                </SpaceDescriptions.Item>
                            ) : null}
                            <SpaceDescriptions.Item
                                label="Message"
                                direction="vertical"
                                colon={false}
                                style={{ maxWidth: '600px' }}
                            >
                                {node.getMessage()}
                            </SpaceDescriptions.Item>
                        </SpaceDescriptions>
                        <NodeRunningJobsFormat
                            node={node}
                            showList={true}
                            wrapper={(content) => (
                                <Fragment>
                                    <AppDivider />
                                    <SpaceDescriptions
                                        direction="vertical"
                                        size={10}
                                        wide={true}
                                    >
                                        <SpaceDescriptions.Item
                                            label="Running jobs"
                                            direction="vertical"
                                            colon={false}
                                        >
                                            {content}
                                        </SpaceDescriptions.Item>
                                    </SpaceDescriptions>
                                </Fragment>
                            )}
                        />
                    </SpaceWide>
                }
                // title={node?.getAddress()}
                {...rest}
            >
                {children || content}
            </WrapFormat>
        </UnavailableFormat>
    );
}

export function getNodeHostWithDesc(
    node: CcNode,
    withPort: boolean = true,
    truncate: boolean = false
) {
    const nodeHostname = truncate
        ? truncateText(node.hostname as string)
        : node.hostname;
    return `${
        withPort ? `${nodeHostname}:${node.port}` : nodeHostname
    } (${getNodeTypeText(node.nodetype, node.getRole())})`;
}

//@todo should belong to HostFormat?
export function getHostWithDesc(host: CcHost) {
    return `${host.hostname} (${host.nodes
        .map((node) => getNodeTypeText(node.nodetype, node.getRole()))
        .join(', ')})`;
}
