


export interface CmonLogMessageProps  {
    class_name?: string;
    cluster_id?: number;
    component?: string;
    created?: string;
    log_class?: number;
    log_id?: number;
    statistics?: string;

}



export default class CmonLogMessage {
    public className?: string;
    public clusterId?: number;
    public component?: string;
    public created?: string;
    public logClass?: number;
    public logId?: number;
    public statistics?: string;


    constructor(props: CmonLogMessageProps) {
        this.className = props.class_name;
        this.clusterId = props.cluster_id;
        this.component = props.component;
        this.created = props.created;
        this.logClass = props.log_class;
        this.logId = props.log_id;
        this.statistics = props.statistics;

    }

}
