import CcImperativeService from '../../services/CcImperativeService';
import useFetch from '../../common/useFetch';
import { useEffect, useState } from 'react';

export type UseClusterDatabasesProps = {
    clusterId: number;
    name?: string | null;
    host?: string;
    schemas?: string[];
    useCache?: boolean;
};
export type UserClusterDatabaseTable = {
    name: string;
    text: string;
    hostname?: string;
    port?: number;
    schema?: string;
};
const useClusterDatabasesTables = ({
    name = 'use-cluster-database-tables',
    host = '',
    schemas = [],
    clusterId,
    useCache = false,
}: UseClusterDatabasesProps) => {
    const [tables, setTables] = useState<UserClusterDatabaseTable[]>([]);
    const { loaded, loading, data, refresh: refreshFetch } = useFetch({
        name,
        useCache,
        schemas,
        fetchFn: async ({ schemas, ...params }, opts) => {
            const result = await Promise.all(
                schemas.map(
                    async (schema: string) =>
                        await CcImperativeService.executeScript(
                            {
                                ...params,
                                filename:
                                    '/s9s/mysql/widgets/schema/show_tables.js',
                                arguments: [schema, host]
                                    .filter((item) => item)
                                    .join(' '),
                                cluster_id: clusterId,
                            },
                            opts
                        )
                )
            );
            return result
                .map(
                    ({
                        results: {
                            exitStatus: { tables },
                        },
                    }: any) => tables
                )
                .flat();
        },
        cancelFn: async ({ requestId }) => {
            await CcImperativeService.cancelRequest(requestId);
        },
    });

    useEffect(() => {
        if (data) {
            setTables(
                data?.map((item: any) => ({
                    ...item,
                    text: item.name,
                })) || []
            );
        }
    }, [data]);

    return {
        loaded,
        loading,
        tables,
        refresh: refreshFetch,
    };
};

export default useClusterDatabasesTables;
