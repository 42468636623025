import React from 'react';
import { Col, Form, Input, Row, Space } from 'antd';
import InfoIcon from '@severalnines/bar-frontend-components/build/lib/General/InfoIcon';
import PasswordInput from '../../../../common/DataEntry/PasswordInput';
import VendorRepositoryField from '../../../../common/Form/Fields/VendorRepositoryField';
import { FormInstance } from 'antd/lib/form';
import SslEncryptionField from '../FormParts/SslEncryptionField';

export default ElasticNodeConfiguration;

const ELASTIC_NOT_ALLOWED_CHARACTERS = /[!"'<>()\\&|;]/;

export type ElasticNodeConfigurationProps = {
    form: FormInstance;
    isImport?: boolean;
};

function ElasticNodeConfiguration({
    form,
    isImport,
}: ElasticNodeConfigurationProps) {
    return (
        <div className="ElasticNodeConfiguration">
            <Row gutter={[24, 0]}>
                <Col span={24}>
                    <h3>Node configuration</h3>
                </Col>
                <Col xs={24} sm={24} md={12}>
                    <Form.Item
                        name={['nodeConfig', 'httpPort']}
                        label={
                            <Space>
                                <span>HTTP port</span>
                                <InfoIcon info="The Elastic http port." />
                            </Space>
                        }
                        rules={[
                            {
                                required: true,
                                message: 'Please enter the Elastic http port.',
                            },
                        ]}
                    >
                        <Input placeholder="Enter http port" disabled />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12}>
                    <Form.Item
                        name={['nodeConfig', 'transferPort']}
                        label={
                            <Space>
                                <span>Transfer port</span>
                                <InfoIcon info="The Elastic transfer port." />
                            </Space>
                        }
                        rules={[
                            {
                                required: true,
                                message:
                                    'Please enter the Elastic transfer port.',
                            },
                        ]}
                    >
                        <Input placeholder="Enter transfer port" disabled />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12}>
                    <Form.Item
                        name={['nodeConfig', 'dbUser']}
                        label={
                            <Space>
                                <span>Admin user</span>
                                <InfoIcon
                                    info={
                                        isImport
                                            ? 'Enter a sysadmin username to use for the new server.'
                                            : 'Enter a sysadmin username to use for the new server.'
                                    }
                                />
                            </Space>
                        }
                        rules={[
                            {
                                required: true,
                                message: 'Please enter Elastic admin username.',
                            },
                            {
                                validator: elasticCharacterValidator,
                            },
                        ]}
                    >
                        <Input placeholder="Enter the admin username" />
                    </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={12}>
                    <Form.Item
                        name={['nodeConfig', 'dbPassword']}
                        label={
                            <Space>
                                <span>Admin password</span>
                                <InfoIcon info="The admin password." />
                            </Space>
                        }
                        rules={[
                            {
                                required: true,
                                message: 'Please enter the admin password.',
                            },
                            {
                                min: 6,
                                message:
                                    'Password must contain at least 6 characters.',
                            },
                            {
                                validator: elasticCharacterValidator,
                            },
                        ]}
                    >
                        <PasswordInput
                            autoComplete="new-password"
                            placeholder="Enter the admin password"
                        />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12}>
                    <VendorRepositoryField
                        name={['nodeConfig', 'repository']}
                        form={form}
                    />
                </Col>
            </Row>
            {!isImport ? (
                <Row gutter={[24, 0]}>
                    <Col xs={24} sm={24} md={12}>
                        <SslEncryptionField />
                    </Col>
                </Row>
            ) : null}
        </div>
    );
}

async function elasticCharacterValidator(rule: any, value: any) {
    if (ELASTIC_NOT_ALLOWED_CHARACTERS.test(value)) {
        throw new Error('Characters !"\'<>()&|; are not allowed');
    } else {
        return true;
    }
}
