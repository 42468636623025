import { ClusterConfiguratorFormValues } from '../../ClusterConfigurator';
import merge from 'deepmerge';
import MssqlConfigurator, {
    MssqlFormValues,
} from '../../Mssql/MssqlConfigurator';
import { RepositoryItemKey } from '../../../../../common/DataEntry/RepositoryInput';
import { NodeConfigurationSummaryProps } from '../../NodeConfigurationSummary';
import React from 'react';
import MssqlNodeSummary from '../../Mssql/MssqlNodeSummary';

export default class MssqlDeploymentConfigurator extends MssqlConfigurator {
    public static getDefaults(): MssqlFormValues {
        return merge(MssqlConfigurator.getDefaults(), {
            details: {
                vendor: 'microsoft',
                version: '2022',
            },
            nodeConfig: {
                repository: RepositoryItemKey.USE_VENDOR,
                sslEncryption: true,
            },
        });
    }

    public static getJobOptions(
        formValues: ClusterConfiguratorFormValues
    ): any {
        const { details } = formValues;
        return merge(MssqlConfigurator.getJobOptions(formValues), {
            enable_uninstall: true,
            job: {
                title: `Deploy SQL Server ${details?.version || ''} Cluster`,
            },
        });
    }

    public static getNodeConfigurationSummary(
        props: NodeConfigurationSummaryProps
    ): React.ReactNode {
        return <MssqlNodeSummary hasSslEncryption={true} {...props} />;
    }
}
