import HostCheckInput from './HostCheckInput';
import React from 'react';
import CcCluster from '../../services/models/CcCluster';
import Form, { FormInstance, FormItemProps } from 'antd/lib/form';
import { TopologyItem } from '../../components/Topology/TopologyItem';
import { StatusFormatStatus } from '@severalnines/bar-frontend-components/build/lib/Format/StatusFormat';

export default HostCheckInputField;
type HostCheckInputFieldProps = FormItemProps & {
    form: FormInstance;
    cluster: CcCluster;
};

function HostCheckInputField({
    form,
    cluster,
    validateTrigger,
    name = 'host',
    rules = [],
    ...rest
}: HostCheckInputFieldProps) {
    const topologyItemName = `${name}TopologyItems`;
    const topologyItemChange = async (values: TopologyItem[]) => {
        const item = values?.[0];
        form.setFieldsValue({
            [`${name}`]: item?.extraData?.hostname,
        });
        if (
            validateTrigger === false &&
            values?.[0]?.status === StatusFormatStatus.success
        ) {
            // to remove error message on success if validateTrigger is false
            try {
                await form.validateFields([topologyItemName]);
            } catch (e) {
                // ignore
            }
        }
    };
    return (
        <Form.Item
            validateTrigger={validateTrigger}
            name={topologyItemName}
            rules={[
                {
                    validator: hostReachableCheckInputValidator,
                },
                ...rules,
            ]}
            {...rest}
        >
            <HostCheckInput
                clusterId={cluster.clusterId}
                placeholder="Enter FQDN or IP-address"
                onChange={topologyItemChange}
            />
        </Form.Item>
    );
}

export async function hostReachableCheckInputValidator(
    rule: any,
    value: TopologyItem[]
) {
    if (
        Array.isArray(value) &&
        value.length > 0 &&
        value[0]?.status !== StatusFormatStatus.success
    ) {
        throw new Error('Host should be reachable');
    }

    return true;
}
