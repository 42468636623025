
import CcMySqlNode, { CcMySqlNodeProps } from '../../models/CcMySqlNode';
import CmonMySqlHostGalera, { CmonMySqlHostGaleraProps } from './CmonMySqlHost';
export interface CmonGaleraHostGaleraProps extends CmonMySqlHostGaleraProps {
    certsdepsdistance?: string;
    clustersize?: number;
    connected?: number;
    flowctrlpaused?: number;
    flowctrlrecv?: number;
    flowctrlsent?: number;
    galerastatus?: string;
    lastcommitted?: number;
    localrecvqueue?: number;
    localrecvqueueavg?: number;
    localsendqueue?: number;
    localsendqueueavg?: number;
    localstatus?: number;
    localstatusstr?: string;
    ready?: number;
}


export interface CmonGaleraHostProps  extends CcMySqlNodeProps {
    galera?: CmonGaleraHostGaleraProps;

}

export class CmonGaleraHostGalera extends CmonMySqlHostGalera {
    public certsdepsdistance?: string;
    public clustersize?: number;
    public connected?: number;
    public flowctrlpaused?: number;
    public flowctrlrecv?: number;
    public flowctrlsent?: number;
    public galerastatus?: string;
    public lastcommitted?: number;
    public localrecvqueue?: number;
    public localrecvqueueavg?: number;
    public localsendqueue?: number;
    public localsendqueueavg?: number;
    public localstatus?: number;
    public localstatusstr?: string;
    public ready?: number;

    constructor(props: CmonGaleraHostGaleraProps) {
        super(props)
        this.certsdepsdistance = props.certsdepsdistance;
        this.clustersize = props.clustersize;
        this.connected = props.connected;
        this.flowctrlpaused = props.flowctrlpaused;
        this.flowctrlrecv = props.flowctrlrecv;
        this.flowctrlsent = props.flowctrlsent;
        this.galerastatus = props.galerastatus;
        this.lastcommitted = props.lastcommitted;
        this.localrecvqueue = props.localrecvqueue;
        this.localrecvqueueavg = props.localrecvqueueavg;
        this.localsendqueue = props.localsendqueue;
        this.localsendqueueavg = props.localsendqueueavg;
        this.localstatus = props.localstatus;
        this.localstatusstr = props.localstatusstr;
        this.ready = props.ready;

    }}



export default class CmonGaleraHost extends CcMySqlNode {
    public galera?: CmonGaleraHostGalera;


    constructor(props: CmonGaleraHostProps) {
        super(props);
        this.galera = props.galera && new CmonGaleraHostGalera(props.galera);

    }

}
