


export interface CmonTitleProps  {

}



export default class CmonTitle {


    constructor(props: CmonTitleProps) {

    }

}
