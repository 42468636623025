import React, { useEffect, useState } from 'react';
import ModalDefault from '../../common/ModalDefault';
import PrometheusExportersData from './PrometheusExportersData';
import CcCluster from '../../services/models/CcCluster';
import {
    getPrometheusExporterTypes,
    getPrometheusNodeFormValues,
    PrometheusFormValues,
} from './PrometheusEnableWizard';
import { Button } from 'antd';
import PrometheusEnableModal from './PrometheusEnableModal';
import ButtonWithForm from '../../common/General/ButtonWithForm';
import CcPrometheusNode from '../../services/models/CcPrometheusNode';

export default PrometheusConfigurationModal;

export type PrometheusConfigurationModalProps = {
    cluster: CcCluster;
    node: CcPrometheusNode;
    onCancel?: () => void;
    onSuccess?: () => void;
};

function PrometheusConfigurationModal({
    cluster,
    node,
    onCancel,
    onSuccess,
}: PrometheusConfigurationModalProps) {
    const [data, setData] = useState<PrometheusFormValues>();
    useEffect(() => {
        if (node) {
            setData(getPrometheusNodeFormValues(node));
        }
    }, [node]);
    return data ? (
        <ModalDefault
            title="Prometheus Configuration"
            className="PrometheusConfigurationModal"
            destroyOnClose={true}
            footer={
                <ButtonWithForm
                    button={<Button type="primary">Edit</Button>}
                    form={
                        <PrometheusEnableModal
                            cluster={cluster}
                            defaultInitialValues={data}
                            saveMode={true}
                        />
                    }
                />
            }
            animateHeight={true}
            visible={true}
            bodyStyle={{ padding: '25px' }}
            onCancel={onCancel}
            width={715}
        >
            <PrometheusExportersData
                data={data}
                exporterTypes={getPrometheusExporterTypes(cluster)}
            />
        </ModalDefault>
    ) : null;
}
