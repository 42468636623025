const mock = {
    class_name: 'CmonClusterInfo',
    cdt_path: '/',
    acl: 'user::rwx,group::rwx,other::---',
    tags: [],
    cluster_auto_recovery: true,
    cluster_id: 6,
    cluster_name: 'mongodb2',
    cluster_type: 'MONGODB',
    configuration_file: '/etc/cmon.d/cmon_5.cnf',
    effective_privileges: 'rwx',
    log_file: '/var/log/cmon_5.log',
    maintenance_mode_active: false,
    managed: true,
    node_auto_recovery: true,
    rpc_version: '2.0',
    state: 'FAILURE',
    status_text: 'Cluster failure.',
    vendor: 'redis',
    version: '',
    alarm_statistics: {
        class_name: 'CmonAlarmStatistics',
        cluster_id: 5,
        created_after: '1970-01-01T00:00:00.000Z',
        critical: 0,
        reported_after: '1970-01-01T00:00:00.000Z',
        warning: 1,
    },
    group_owner: {
        class_name: 'CmonGroup',
        cdt_path: '/groups',
        owner_user_id: 1,
        owner_user_name: 'system',
        owner_group_id: 1,
        owner_group_name: 'admins',
        acl: 'user::rwx,group::rwx,other::---',
        created: '2021-04-28T22:57:55.328Z',
        group_id: 1,
        group_name: 'admins',
    },
    hosts: [
        {
            class_name: 'CmonMongoHost',
            clusterid: 181,
            configfile: '/etc/mongod.conf',
            connected: true,
            connections_available: 814,
            connections_current: 5,
            container: true,
            distribution: {
                codename: 'xenial',
                name: 'ubuntu',
                release: '16.04',
                type: 'debian',
            },
            global_lock_queue: 0,
            global_lock_ratio: 0,
            hidden: false,
            hostId: 123,
            hostname: '10.0.3.29',
            hoststatus: 'CmonHostOnline',
            ip: '10.0.3.29',
            lastseen: 1504700930,
            logfile: '/var/log/mongodb/mongod.log',
            maintenance_mode_active: false,
            member_role: 'Primary',
            message: 'Up and Running',
            nodetype: 'mongo',
            pid: 8234,
            pidfile: '/var/run/mongod.pid',
            pingstatus: 1,
            pingtime: 24,
            port: 27018,
            priority: 1,
            replication_lag: 0,
            role: 'shardsvr',
            roleid: 0,
            rs: 'ReplSet01',
            slaveDelay: 0,
            sshfailcount: 0,
            timestamp: 1504700933,
            unique_id: 111,
            uptime: 1211,
            version: '3.2.16-3.6',
            wallclock: 1504700933,
            wallclocktimestamp: 1504700933,
        },
        {
            class_name: 'CmonMongoHost',
            clusterid: 181,
            configfile: '/etc/mongod.conf',
            connected: true,
            connections_available: 814,
            connections_current: 5,
            container: true,
            distribution: {
                codename: 'xenial',
                name: 'ubuntu',
                release: '16.04',
                type: 'debian',
            },
            global_lock_queue: 0,
            global_lock_ratio: 0,
            hidden: false,
            hostId: 124,
            hostname: '10.0.3.65',
            hoststatus: 'CmonHostOnline',
            ip: '10.0.3.65',
            lastseen: 1504700930,
            logfile: '/var/log/mongodb/mongod.log',
            maintenance_mode_active: false,
            member_role: 'Primary',
            message: 'Up and Running',
            nodetype: 'mongo',
            pid: 8337,
            pidfile: '/var/run/mongod.pid',
            pingstatus: 1,
            pingtime: 23,
            port: 27018,
            priority: 1,
            replication_lag: 0,
            role: 'shardsvr',
            roleid: 0,
            rs: 'ReplSet02',
            slaveDelay: 0,
            sshfailcount: 0,
            timestamp: 1504700933,
            unique_id: 112,
            uptime: 1206,
            version: '3.2.16-3.6',
            wallclock: 1504700933,
            wallclocktimestamp: 1504700933,
        },
        {
            class_name: 'CmonMongoHost',
            clusterid: 181,
            configfile: ['/etc/mongod.conf'],
            connected: true,
            connections_available: 818,
            connections_current: 1,
            container: true,
            distribution: {
                codename: 'xenial',
                name: 'ubuntu',
                release: '16.04',
                type: 'debian',
            },
            global_lock_queue: 0,
            global_lock_ratio: 0,
            hostId: 125,
            hostname: '10.0.3.208',
            hoststatus: 'CmonHostOnline',
            ip: '10.0.3.208',
            lastseen: 1504700930,
            maintenance_mode_active: false,
            member_role: 'Mongos',
            message: 'Up and Running',
            nodetype: 'mongo',
            pid: 8907,
            pingstatus: 1,
            pingtime: 25,
            port: 27017,
            role: 'mongos',
            roleid: 2,
            rs: '',
            sshfailcount: 0,
            timestamp: 1504700933,
            unique_id: 113,
            uptime: 1214,
            version: '3.2.16-3.6',
            wallclock: 1504700933,
            wallclocktimestamp: 1504700933,
        },
        {
            class_name: 'CmonMongoHost',
            clusterid: 181,
            configfile: ['/etc/mongod.conf'],
            connected: true,
            connections_available: 812,
            connections_current: 7,
            container: true,
            distribution: {
                codename: 'xenial',
                name: 'ubuntu',
                release: '16.04',
                type: 'debian',
            },
            global_lock_queue: 0,
            global_lock_ratio: 0,
            hidden: false,
            hostId: 125,
            hostname: '10.0.3.208',
            hoststatus: 'CmonHostOnline',
            ip: '10.0.3.208',
            lastseen: 1504700930,
            maintenance_mode_active: false,
            member_role: 'Configsvr',
            message: 'Up and Running',
            nodetype: 'mongo',
            pid: 8677,
            pingstatus: 1,
            pingtime: 25,
            port: 27019,
            priority: 1,
            replication_lag: 0,
            role: 'configsvr',
            roleid: 1,
            rs: 'replica_set_config',
            slaveDelay: 0,
            sshfailcount: 0,
            timestamp: 1504700933,
            unique_id: 114,
            uptime: 1220,
            version: '3.2.16-3.6',
            wallclock: 1504700933,
            wallclocktimestamp: 1504700933,
        },
        {
            class_name: 'CmonHost',
            clusterid: 181,
            configfile: '/etc/cmon.d/cmon_181.cnf',
            connected: true,
            container: false,
            distribution: {
                codename: 'zesty',
                name: 'ubuntu',
                release: '17.04',
                type: 'debian',
            },
            hostId: 127,
            hostname: 'cmon-controller',
            hoststatus: 'CmonHostOnline',
            ip: '192.168.0.100',
            lastseen: 1504700930,
            logfile: '/var/log/cmon_181.log',
            maintenance_mode_active: false,
            message: 'Up and running',
            nodetype: 'controller',
            pid: 7231,
            pingstatus: 1,
            pingtime: 16,
            port: 9500,
            role: 'controller',
            timestamp: 1504700933,
            unique_id: 115,
            uptime: 6850,
            version: '1.4.3',
            wallclock: 1504700933,
            wallclocktimestamp: 1504700933,
        },
    ],
    job_statistics: {
        class_name: 'CmonJobStatistics',
        cluster_id: 5,
        by_state: {
            ABORTED: 0,
            DEFINED: 0,
            DEQUEUED: 0,
            FAILED: 0,
            FINISHED: 0,
            PAUSED: 2,
            RUNNING: 0,
        },
    },
    owner: {
        class_name: 'CmonUser',
        cdt_path: '/',
        acl: 'user::rwx,group::r--,other::r--',
        disabled: false,
        suspended: false,
        groups: [],
        timezone: {
            class_name: 'CmonTimeZone',
            name: 'Coordinated Universal Time',
            abbreviation: 'UTC',
            offset: 0,
            use_dst: false,
        },
    },
};

export default mock;
