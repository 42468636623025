import React from 'react';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Divider } from 'antd';
import SquareStatusBadge from '../common/SquareStatusBadge';
const DebugSquareStatusBadge = () => {
    const samples: any[] = [
        {
            count: 14,
            status: 'success',
            text: 'text',
        },
        {
            count: 13,
            status: 'info',
            text: 'text',
        },
        {
            count: 5,
            status: 'warning',
            text: 'text',
        },
        {
            count: 1,
            status: 'error',
            text: 'text',
        },
        {
            count: 3,
            status: 'unknown',
            text: 'text',
        },
    ];
    return (
        <div className="DebugSquareStatusBadge">
            <h1>SquareStatusBadge</h1>
            {samples.map((sample, index) => (
                <div key={index}>
                    <Divider
                        plain={true}
                        orientation="left"
                        style={{ marginBottom: '0px', marginTop: '15px' }}
                    >
                        {JSON.stringify(sample)}
                    </Divider>
                    <SquareStatusBadge {...sample}>
                        <ExclamationCircleOutlined />
                    </SquareStatusBadge>
                </div>
            ))}
        </div>
    );
};
export default DebugSquareStatusBadge;
