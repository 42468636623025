


export interface CmonGroupProps  {
    acl?: string;
    cdt_path?: string;
    created?: string;
    group_id?: number;
    group_name?: number;
    owner_group_id?: number;
    owner_group_name?: string;
    owner_user_id?: number;
    owner_user_name?: string;
    tags?: string;

}



export default class CmonGroup {
    public acl?: string;
    public cdtPath?: string;
    public created?: string;
    public groupId?: number;
    public groupName?: number;
    public ownerGroupId?: number;
    public ownerGroupName?: string;
    public ownerUserId?: number;
    public ownerUserName?: string;
    public tags?: string;


    constructor(props: CmonGroupProps) {
        this.acl = props.acl;
        this.cdtPath = props.cdt_path;
        this.created = props.created;
        this.groupId = props.group_id;
        this.groupName = props.group_name;
        this.ownerGroupId = props.owner_group_id;
        this.ownerGroupName = props.owner_group_name;
        this.ownerUserId = props.owner_user_id;
        this.ownerUserName = props.owner_user_name;
        this.tags = props.tags;

    }

}
