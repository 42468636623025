import CcNode from './CcNode';
import { clearTags, ucFirst } from '../../common/utils/string';

export enum AdvisorResultStatus {
    OK = 'ok',
    WARNING = 'warning',
    CRITICAL = 'critical',
    DISABLED = 'disabled',
    UNKNOWN = 'unknown',
}

export const ADVISOR_STATUS_PRIORITY = {
    [AdvisorResultStatus.CRITICAL]: 0,
    [AdvisorResultStatus.WARNING]: 10,
    [AdvisorResultStatus.OK]: 20,
    [AdvisorResultStatus.UNKNOWN]: 30,
    [AdvisorResultStatus.DISABLED]: 40,
};

export type ClusterDirTreeFileType = {
    name?: string;
    filename?: string;
    path?: string;
    schedule?: string;
    schedule_args?: string;
    schedule_enabled?: boolean;
    schedule_id?: number;
    timestamp?: number;
    type?: 'file' | 'directory';
    version?: number;
    settings?: {
        project?: {
            tags?: string;
        };
    };
};

export type AdvisorExitStatus = {
    advice?: string;
    className?: string;
    created?: string;
    justification?: string;
    severity?: string;
    title?: string;
    host?: CcNode;
};

export interface AdvisorScheduleResultsType {
    enabled?: boolean;
    filename?: string;
    scheduleid?: number;
    status?: string;
    timestamp?: number;
    exitStatus?: AdvisorExitStatus[];
    messages?: {
        fileName?: string;
        lineNumber?: number;
        message?: string;
    }[];
}

export default class CcAdvisorSchedule {
    enabled: boolean;
    filename: string;
    scheduleid: number;
    timestamp: number;
    exitStatus: AdvisorExitStatus[];
    messages: {
        fileName: string;
        lineNumber: number;
        message: string;
    }[];
    status: string;
    statusTitle: string;
    file: ClusterDirTreeFileType;
    tags: string[];

    constructor(
        data: AdvisorScheduleResultsType,
        file?: ClusterDirTreeFileType
    ) {
        this.enabled = data.enabled || false;
        this.filename = data.filename || '';
        this.scheduleid = data.scheduleid || 0;
        this.timestamp = data.timestamp || 0;
        this.exitStatus = data.exitStatus || [];
        this.messages = (data.messages || ([] as any)).map((m: any) => ({
            ...m,
            message: clearTags(m.message),
        }));
        this.status = data.status || '';
        this.statusTitle =
            (data.enabled &&
                (this.getCurrentStatus()?.severity ||
                    ucFirst(AdvisorResultStatus.UNKNOWN))) ||
            ucFirst(AdvisorResultStatus.DISABLED);
        this.file = file || ({} as ClusterDirTreeFileType);
        this.tags = this.file.settings?.project?.tags?.split(';') || [];
    }

    getCurrentStatus(): AdvisorExitStatus | undefined {
        // to put unknown status at the end

        return (
            this.exitStatus &&
            Object.values(this.exitStatus).sort((a, b) => {
                const aSeverity = getAdvisorSeverityPriority(a.severity);
                const bSeverity = getAdvisorSeverityPriority(a.severity);
                return aSeverity - bSeverity;
            })[0]
        );
    }

    getTitle() {
        return this?.getCurrentStatus()?.title || this?.filename;
    }
}

const HIGHEST_SEVERITY_PRIORITY =
    ((Object.keys(Object.values(ADVISOR_STATUS_PRIORITY)).pop() as any) || 0) +
    10;

export function getAdvisorSeverityPriority(severity?: string) {
    if (!severity) {
        return HIGHEST_SEVERITY_PRIORITY;
    }
    let priority =
        ADVISOR_STATUS_PRIORITY[severity?.toLowerCase() as AdvisorResultStatus];
    if (priority === undefined) {
        priority = HIGHEST_SEVERITY_PRIORITY;
    }
    return priority;
}
