import React from 'react';
import { Form, InputNumberProps } from 'antd';
import { FormItemProps } from 'antd/lib/form';
import InputNumberWide from '../../DataEntry/InputNumberWide';

export default NodePortField;

export type NodePortFieldProps = FormItemProps & {
    required?: boolean;
    inputProps?: InputNumberProps;
};

function NodePortField({
    required = true,
    inputProps,
    ...rest
}: NodePortFieldProps) {
    return (
        <div className="NodePortField">
            <Form.Item
                name="nodePort"
                label="Port"
                rules={[
                    {
                        required,
                        message: 'Please enter node port',
                    },
                ]}
                {...rest}
            >
                <InputNumberWide
                    placeholder="Enter node port"
                    {...inputProps}
                />
            </Form.Item>
        </div>
    );
}
