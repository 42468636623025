import React from 'react';
import { Form, Space } from 'antd';
import { FormItemProps } from 'antd/lib/form';
import SnapshotRepositorySelect from '../../DataEntry/SnapshotRepositorySelect';
export default ElasticRepositorySelectField;

export const ELASTIC_REPOSITORY_DEFAULT_NAME = 'cc_snapshots';

export type ElasticRepositorySelectFieldProps = FormItemProps & {
    clusterId: number;
};

function ElasticRepositorySelectField({
    clusterId,
    ...rest
}: ElasticRepositorySelectFieldProps) {
    return (
        <Form.Item
            name="elasticRepository"
            label={<Space>Repository</Space>}
            rules={[
                {
                    required: true,
                    message: '',
                },
            ]}
            {...rest}
        >
            <SnapshotRepositorySelect clusterId={clusterId} />
        </Form.Item>
    );
}
