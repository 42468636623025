import {
    CmonHostReplicationSlave,
    CmonHostReplicationSlaveProps,
} from '../cmon/models/CmonHost';

export type CcNodeReplicationSlaveProps = CmonHostReplicationSlaveProps & {
    master_cluster_id?: number;
};

export default class CcNodeReplicationSlave extends CmonHostReplicationSlave {
    // overrides
    public readonly masterPort?: number;
    public readonly masterClusterId?: number;

    constructor(props: CcNodeReplicationSlaveProps) {
        super(props);
        this.masterPort = props.master_port
            ? parseInt(props.master_port, 10)
            : undefined;
        this.masterClusterId =
            Number(props.master_cluster_id) > 0
                ? Number(props.master_cluster_id)
                : 0;
    }

    public getMasterAddress() {
        return this.masterHost
            ? `${this.masterHost}${
                  this.masterPort ? `:${this.masterPort}` : ''
              }`
            : undefined;
    }

    public isReplicationRunning() {
        return false;
    }

    public isReplicationIoRunning() {
        return false;
    }

    public isReplicationIoConnecting() {
        return false;
    }

    public isReplicationSqlRunning() {
        return false;
    }
}
