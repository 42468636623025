const result = {
    response: {
        json: [
            { name: 'bucket-name1' },
            { name: 'bucket-name2' },
            { name: 'bucket-name3' },
            { name: 'bucket-name4' },
            { name: 'bucket-name5' },
        ],
    },
};
export default result;
