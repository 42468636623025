import React, { useState } from 'react';
import { ButtonProps } from 'antd/lib/button';
import CcJob from '../../services/models/CcJob';
import CmonJobsService from '../../services/cmon/CmonJobsService';
import { notifyJobCreationError } from '../Notifications/uiNotification';
import { CmonRequestServiceResponseData } from '../../services/cmon/CmonRequestService';
import { addNewRunningJob } from '../../appReducer';
import { useDispatch } from 'react-redux';
import { useDebugContext } from '../../common/Debug';
import AppConfirmActionButton from '../../common/General/AppConfirmActionButton';

export default JobRetryButton;

export type JobRetryButtonProps = ButtonProps & {
    job: CcJob;
    children?: React.ReactNode;
    onSuccess?: () => void;
};

function JobRetryButton({
    job,
    children,
    onSuccess,
    ...rest
}: JobRetryButtonProps) {
    const { log } = useDebugContext();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const handleClick = async () => {
        let response: CmonRequestServiceResponseData | undefined;
        try {
            setLoading(true);
            response = await CmonJobsService.cloneJobInstance({
                job_id: job.jobId,
                cluster_id: job.clusterId,
            });
            if (onSuccess) {
                onSuccess();
            }
            if (response) {
                const { job } = response;
                dispatch(addNewRunningJob(job));
            }
        } catch (err) {
            log.error(err);
            notifyJobCreationError({ content: err.message });
        }
        setLoading(false);
    };

    return (
        <AppConfirmActionButton
            confirmTitle={
                <span>
                    Retry '<b>{job.title}</b>' job?
                </span>
            }
            onConfirm={handleClick}
            loading={loading}
            {...rest}
        >
            {children || 'Retry'}
        </AppConfirmActionButton>
    );
}
