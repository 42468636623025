import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { ResponsiveContext } from '@severalnines/bar-frontend-components/build/lib/Layout/Responsive';
import useListFetch from '../../common/useListFetch';
import {
    AppState,
    AppStateClustersMap,
    updateUserUIConfig,
} from '../../appReducer';
import { useDispatch, useSelector } from 'react-redux';
import ClusterFormat from '../Clusters/ClusterFormat';

import CmonAuditService from '../../services/cmon/CmonAuditService';
import CcAuditLog from '../../services/models/CcAuditLog';
import { TablePaginationConfig } from 'antd/es';
import AppEmpty from '../../common/Feedback/AppEmpty';
import AppDateFormat from '../../common/AppDateFormat';
import AppTable from '../../common/DataDisplay/AppTable';
import useTableFilter from '../../common/hooks/useTableFilter';
import { ArrayParam } from 'use-query-params';
import useTableFilterColumns, {
    TableFilterType,
} from '../../common/hooks/useTableFilterColumns';

export default AuditLogTable;

export type AuditLogTableProps = {
    enablePagination?: boolean;
    pageLimit?: number;
    clusterId?: number;
    isActive?: boolean;
    footerRender?: (total?: number) => React.ReactNode;
    showRelativeTime?: boolean;
};

function AuditLogTable({
    enablePagination = true,
    pageLimit = 20,
    isActive,
    footerRender,
    clusterId,
    showRelativeTime,
}: AuditLogTableProps) {
    const { filterParams, handleTableChange } = useTableFilter({
        params: {
            cluster: ArrayParam,
        },
    });

    const { responsive } = useContext(ResponsiveContext);
    const dispatch = useDispatch();
    const {
        list: auditLogList,
        loading: loadingAuditLog,
        loaded,
        refresh: refreshAuditLog,
        page,
        pageSize,
        total,
        cancel,
        stopAutoRefresh,
    } = useListFetch({
        name: 'auditLog-list',
        pageSize: pageLimit,
        fetchFn: async ({ pageSize, page, ...rest }, opts) => {
            const response = await CmonAuditService.getEntries(
                {
                    limit: pageSize,
                    offset: (page - 1) * pageSize,
                    cluster_id: clusterId,
                    ...rest,
                },
                opts
            );
            return {
                list: response.audit_entries,
                total: response.total,
            };
        },
        cancelFn: async ({ requestId }) => {
            await CmonAuditService.cancelRequest(requestId);
        },
    });

    // @todo come up with reusable logic handling these filters
    const refresh = async (params: any = {}) => {
        let refreshParams: any = {
            cluster_ids: undefined,
            page: filterParams.page || 1,
        };
        if (clusterId) {
            refreshParams = {
                ...refreshParams,
                cluster_id: clusterId,
            };
        } else if (filterParams.cluster) {
            refreshParams = {
                ...refreshParams,
                cluster_ids: filterParams.cluster,
            };
        }
        await refreshAuditLog({ ...refreshParams, ...params });
    };

    useEffect(() => {
        (async () => {
            await refresh();
        })();
    }, [filterParams, clusterId]);

    useEffect(() => {
        if (isActive) {
            (async () => {
                await refresh({
                    autoRefresh: 10000,
                    showLoading: !loaded,
                });
            })();
        } else {
            cancel();
            stopAutoRefresh();
        }
    }, [isActive]);

    const [clustersMap]: [
        AppStateClustersMap
    ] = useSelector(({ clusters }: AppState) => [clusters]);

    const { columns } = useTableFilterColumns({
        columns: useMemo(
            () => [
                {
                    title: 'Activity',
                    key: 'activity',
                    render: (record: CcAuditLog) => record.getAuditLogMessage(),
                },
                {
                    title: 'Type',
                    key: 'type',
                    render: (record: CcAuditLog) => record.entryType,
                },
                {
                    title: 'User',
                    key: 'user',
                    render: (record: CcAuditLog) => record.userName,
                },
                {
                    title: 'Hostname',
                    key: 'hostname',
                    render: (record: CcAuditLog) => record.clientHostname,
                },
                {
                    title: 'Cluster Name',
                    key: 'cluster',
                    render: (record: CcAuditLog) => (
                        <ClusterFormat
                            clusterLink={true}
                            linkDestination={'logs/audit-log'}
                            cluster={clustersMap.get(record.getClusterKey())}
                            showPopover={true}
                        />
                    ),
                    filterType: TableFilterType.CLUSTER,
                },
                {
                    title: 'When',
                    key: 'created',
                    render: (record: CcAuditLog) => {
                        return (
                            <AppDateFormat fromNow={showRelativeTime}>
                                {record.reportTs
                                    ? new Date(record.reportTs)
                                    : undefined}
                            </AppDateFormat>
                        );
                    },
                },
            ],
            [clustersMap, showRelativeTime]
        ),
        filterParams,
        state: {
            clusters: clustersMap,
        },
    });

    const rowKey = useCallback(
        (record: CcAuditLog) => `${record.getKey()}`,
        []
    );

    const handleOnRow = useCallback(
        (record: CcAuditLog, index: number) => ({
            'data-testid': `quick-auditLog-list-row-${index}`,
        }),
        []
    );

    const pagination: TablePaginationConfig = {
        size: 'default',
        pageSize,
        current: page,
        total,
        hideOnSinglePage: true,
        showQuickJumper: true,
        showSizeChanger: true,
        position: ['bottomCenter'],
    };

    const extraProps = useCallback(() => {
        let props: any = {};
        if (footerRender) {
            props.footer = () => footerRender(total);
        }
        return props;
    }, [footerRender, total]);

    return (
        <AppTable
            className="AuditLog"
            loading={loadingAuditLog}
            rowKey={rowKey}
            dataSource={auditLogList}
            columns={columns}
            pagination={enablePagination ? pagination : false}
            onChange={handleTableChange}
            responsive={responsive}
            onRow={handleOnRow}
            renderEmpty={
                filterParams.cluster ? (
                    false
                ) : (
                    <AppEmpty
                        loading={loadingAuditLog}
                        description="You dont’t have any Audit Log yet. When you do, it'll show up here."
                    />
                )
            }
            {...extraProps()}
        />
    );
}
