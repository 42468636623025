import React from 'react';
import { Form } from 'antd';
import WizardForm from '@severalnines/bar-frontend-components/build/lib/Navigation/WizardForm';
import WizardFormConfiguration from '@severalnines/bar-frontend-components/build/lib/Navigation/Wizard/WizardFormConfiguration';
import CcCluster from '../../../../services/models/CcCluster';
import { FormInstance } from 'antd/lib/form';
import useCreateJob from '../../../Jobs/useCreateJob';
import { CmonJobInstanceCommand } from '../../../../services/cmon/models/CmonJobInstance';

import ImportHAProxyConfiguration, {
    getImportHaProxyConfigurationValidation,
    haProxyConfigurationInitialValues,
} from './HAProxyWizradFromSteps/ImportHAProxyConfiguration';

import ImportHAProxyAdvanced, {
    haProxyAdvancedInitial,
    haProxyAdvancedValidation,
} from './HAProxyWizradFromSteps/ImportHAProxyAdvanced';

import ImportHAProxyPreview from './HAProxyWizradFromSteps/ImportHAProxyPreview';

export enum HAProxyFormSteps {
    Configuration = 'Configuration',
    ADV_SETTINGS = 'AdvancedSettings',
    PREVIEW = 'preview',
}

export type ImportHAProxyWizardFormProps = {
    onCancel?: () => void;
    onSuccess?: () => void;
    onError?: (err: Error) => void;
    onStepErrorInsist?: (err: Error) => void;
    onTouchedChange?: (touched: boolean) => void;
    cluster?: CcCluster;
};

export default ImportHAProxyWizardForm;

function ImportHAProxyWizardForm({
    onCancel,
    onSuccess,
    onStepErrorInsist,
    onError,
    onTouchedChange,
    cluster,
}: ImportHAProxyWizardFormProps) {
    const [form] = Form.useForm();

    const { loading, send } = useCreateJob({
        clusterId: cluster?.clusterId,
        title: 'Import HAProxy',
        command: CmonJobInstanceCommand.REGISTERNODE.toLowerCase() as CmonJobInstanceCommand,
        onSuccess,
    });

    const handleSubmit = async () => {
        try {
            await send({
                clusterId: cluster?.clusterId,
                ...formatFields(form),
            });
        } catch (err: any) {
            if (onError) {
                onError(err);
            }
        }
    };

    return (
        <WizardFormConfiguration
            form={form}
            onSubmit={handleSubmit}
            loading={loading}
            onCancel={onCancel}
            initialValues={{
                ...haProxyConfigurationInitialValues,
                ...haProxyAdvancedInitial,
            }}
            onTouchedChange={onTouchedChange}
            onStepErrorInsist={onStepErrorInsist}
            steps={[
                <WizardForm.Step
                    key={HAProxyFormSteps.Configuration}
                    title="Configuration"
                    subTitle=" "
                    validate={getImportHaProxyConfigurationValidation(form)}
                >
                    <ImportHAProxyConfiguration
                        selectedCluster={cluster}
                        form={form}
                    />
                </WizardForm.Step>,
                <WizardForm.Step
                    key={HAProxyFormSteps.ADV_SETTINGS}
                    title="Advanced settings"
                    subTitle=" "
                    validate={haProxyAdvancedValidation}
                >
                    <ImportHAProxyAdvanced />
                </WizardForm.Step>,
                <WizardForm.Step
                    key={HAProxyFormSteps.PREVIEW}
                    title="Preview"
                    subTitle=" "
                >
                    <ImportHAProxyPreview form={form} />
                </WizardForm.Step>,
            ]}
        />
    );
}

export function formatFields(form: FormInstance) {
    const { haProxyConfiguration, haProxyAdvanced } = form.getFieldsValue(true);
    const [address] = haProxyConfiguration.address;
    const date = {
        node: {
            hostname: address?.extraData.hostname,
            port: haProxyConfiguration.port,
            lb_admin: haProxyAdvanced.adminUser,
            lb_password: haProxyAdvanced.adminPassword,
            max_connection_be: 64,
            max_connection_fe: 8192,
            ro_port: 3308,
            rw_port: 3307,
            rw_splitting: false,
            stats_socket: haProxyAdvanced.statsSocket,
            timeout_client: 10800,
            timeout_server: 10800,
            xinetd_allow_from: '0.0.0.0/0',
            backend_name_ro: '',
            backend_name_rw: '',
        },
        cmdline: haProxyAdvanced.cmdLine,
        haproxy_name: haProxyAdvanced.lbName,
        config_file: haProxyAdvanced.haProxyConfig,
        component: 'haproxy',
    };
    return date;
}
