import { useEffect, useRef, useState } from 'react';
import { NodeTreeTopologyNode } from './TopologyNodeTree';
import { TopologyItem } from './TopologyItem';
import { NodeTreeDataNode } from '../../common/NodeTree';
import { TopologyItemList } from './TopologyItemList';
import { StatusFormatStatus } from '@severalnines/bar-frontend-components/build/lib/Format/StatusFormat';

export type UseTopologyNodeTreeDataProps = {
    items: TopologyItem[];
    onTreeDataNode?: (
        node: NodeTreeDataNode,
        item: TopologyItem
    ) => NodeTreeDataNode;
};
export default function useTopologyNodeTreeData({
    items,
    onTreeDataNode,
}: UseTopologyNodeTreeDataProps) {
    const itemList = useRef<TopologyItemList>(new TopologyItemList({ items }));
    const [treeData, setTreeData] = useState<NodeTreeTopologyNode[]>();

    useEffect(() => {
        itemList.current = new TopologyItemList({ items });
    }, [items]);
    useEffect(() => {
        const parents = itemList.current.getParents();
        setTreeData(
            parents.reduce((accumulator: NodeTreeTopologyNode[], item) => {
                if (!accumulator.find((i) => i.key === item.key)) {
                    const childrenNodes = itemList.current.getItemChildren(
                        item
                    );
                    const getNode = (item: TopologyItem) => ({
                        key: item.key,
                        title: item.title || '---.---.---.---',
                        status: item.status,
                        loading: item.loading,
                        message: item.message,
                        description:
                            item.status === StatusFormatStatus.error
                                ? item.message
                                : item.description ||
                                  (item.fromKey ? 'Replica' : 'Primary'),
                        footerExpanded: item.footerExpanded,
                        footerExpandable: item.footerExpandable,
                        footer: item.footer,
                        item,
                    });
                    const node: NodeTreeTopologyNode = {
                        ...getNode(item),
                        isLeaf: false,
                        children: childrenNodes.map((item) => {
                            const node = getNode(item);
                            return {
                                ...node,
                                ...(onTreeDataNode?.(node, item) || {}),
                            };
                        }),
                    };

                    return [
                        ...accumulator,
                        {
                            ...node,
                            ...(onTreeDataNode?.(node, item) || {}),
                        },
                    ];
                }
                return accumulator;
            }, [])
        );
    }, [items]);

    return {
        treeData,
    };
}
