import { useCallback } from 'react';
import moment from 'moment';
import useFetch, {
    FetchRefreshFunctionParams,
    UseFetchProps,
} from '../../common/useFetch';
import CmonStatService from '../../services/cmon/CmonStatService';
import { parse } from 'query-string';
import { arrayFilter } from '../../common/filtering';
import CcCluster from '../../services/models/CcCluster';
import { MonitorDatasource, MonitorTarget } from './Monitor';
import { MonitorDataPoint, MonitorType } from './MonitorBox';
import {
    getStepByRange,
    isQueryForCluster,
    queryClusterObjects,
} from './monitorUtils';
import { parseDuration } from '@severalnines/bar-frontend-components/build/lib/Format/DurationFormat';
import ClustercontrolDataProcessor from './ClustercontrolDataProcessor';
import PrometheusDataProcessor from './PrometheusDataProcessor';
import CmonAgentDataProcessor from './CmonAgentDataProcessor';
import { useDebugContext } from '../../common/Debug';
import { getRandomBetweenNegativeAndPositive } from '../../common/utils/number';

export default useMonitor;

export type UseMonitorProps = Omit<UseFetchProps, 'fetchFn'> & {
    datasource?: MonitorDatasource;
    targets: MonitorTarget[];
    type: MonitorType;
    cluster: CcCluster;
    startTs?: number;
    endTs?: number;
    returFrom?: number;
    lastSeconds?: number;
    refreshDelta?: number;
    autoRefresh?: number;
};

export type UseMonitorRefreshProps = FetchRefreshFunctionParams & {
    startTs?: number;
    endTs?: number;
    returFrom?: number;
    filters?: ((d: any) => boolean)[];
};

function useMonitor({
    datasource = MonitorDatasource.PROMETHEUS,
    targets: paramTargets,
    type,
    startTs,
    endTs,
    returnFromTs,
    cluster,
    filters,
    autoRefresh = 0,
    lastSeconds,
    refreshDelta,
    ...rest
}: UseMonitorProps): {
    loading: boolean;
    refresh: Function;
    cancel: Function;
    loaded: boolean;
    data: MonitorDataPoint[][];
} {
    const { log } = useDebugContext();
    const { loading, loaded, data, refresh: refreshFetch, cancel } = useFetch<
        MonitorDataPoint[][]
    >({
        useCache: true,
        fetchFn: async (params, opts) => {
            const {
                clusterId,
                startTs = moment().unix() - (lastSeconds || 300),
                endTs = moment().unix(),
                returnFromTs,
                targets = paramTargets,
                fullLoad,
                ...restParams
            } = params;

            let data: any[][] = [];
            if (datasource === MonitorDatasource.CMONAGENT) {
                let jsonQueries: string[] = [];
                const responses = await Promise.all(
                    targets.map((q: MonitorTarget) => {
                        const [, jsonQuery, querySearch = ''] =
                            q.expr.match(/(.*)\((.*)\)/m) || [];
                        jsonQueries.push(jsonQuery);
                        return CmonStatService.getTopQueries(
                            {
                                cluster_id: clusterId,
                                begin: new Date(startTs * 1000).toISOString(),
                                end: new Date(endTs * 1000).toISOString(),
                                // limit: 20,
                                ...parse(querySearch),
                                ...restParams,
                            },
                            // we need unique request id because params are similar
                            { ...opts, requestId: JSON.stringify(jsonQuery) }
                        );
                    })
                );
                const resultLists = responses.map((response) => {
                    const resultList =
                        response.queries.result.resultData.records;
                    resultList.sort();
                    return resultList;
                });
                data = CmonAgentDataProcessor.processData(resultLists, {
                    jsonQueries,
                });
                // console.log('data agents', data);
            } else if (datasource === MonitorDatasource.CLUSTERCONTROL) {
                let jsonQueries: string[] = [];
                const response = await Promise.all(
                    targets.map((q: MonitorTarget) => {
                        if (isQueryForCluster(q.expr)) {
                            // query is for cluster object we return a promise
                            // with data from quering the cluster
                            return Promise.resolve({
                                data: queryClusterObjects(cluster, q.expr),
                            });
                        } else {
                            // this block is experimental to request stats from our current
                            // ssh clustercontrol stats (not used for now in ccv2)
                            const [, jsonQuery, querySearch = ''] =
                                q.expr.match(/(.*)\((.*)\)/m) || [];
                            jsonQueries.push(jsonQuery);
                            return CmonStatService.statByName(
                                {
                                    cluster_id: clusterId,
                                    startdate: startTs,
                                    enddate: endTs,
                                    returnfrom: returnFromTs,
                                    ...parse(querySearch),
                                    ...restParams,
                                },
                                opts
                            );
                        }
                    })
                );
                if (type === MonitorType.SINGLESTAT) {
                    const singleStatData: {
                        [key: string]: any;
                    }[][] = response.map((resp: any) => resp.data);
                    data = singleStatData;
                } else {
                    // this part is not used in ccv2 for now
                    // this process the data from requesting stats from our stats system
                    data = ClustercontrolDataProcessor.processData(
                        response.map((resp: any) =>
                            arrayFilter({ filters, arr: resp.data })
                        ),
                        {
                            jsonQueries,
                        }
                    );
                }
            } else if (datasource === MonitorDatasource.PROMETHEUS) {
                const prometheusNode = cluster.getPrometheusNode();
                const step = getStepByRange(
                    startTs,
                    endTs,
                    prometheusNode?.scrapeInterval
                        ? parseDuration(prometheusNode?.scrapeInterval, true)
                        : undefined
                );
                const queryObjects = targets.map((q: MonitorTarget) => ({
                    query: q.expr,
                    step,
                }));
                log.debug(
                    '[useMonitor]',
                    `Requested range (${
                        endTs - (returnFromTs || startTs)
                    }s) for queries: \n\t${queryObjects
                        .map((q) => q.query)
                        .join(
                            '\n\t'
                        )} \n with parameters:\n\tstartTs: ${new Date(
                        startTs * 1000
                    ).toISOString()}\n\treturnFromTs: ${
                        returnFromTs
                            ? new Date(returnFromTs * 1000).toISOString()
                            : null
                    }\n\tendTs: ${new Date(endTs * 1000).toISOString()}\n\n`
                );

                // requesting stats from prometheus
                const response = await CmonStatService.prometheusProxy(
                    {
                        cluster_id: clusterId,
                        start: returnFromTs || startTs,
                        end: endTs,
                        step,
                        queries: queryObjects,
                    },
                    { ...opts, path: '/query_range' }
                );

                data = PrometheusDataProcessor.processData(
                    response.data.map((d: any) => d.data),
                    {
                        targets,
                        prometheusNode,
                        step,
                    }
                );
            }

            return data;
        },
        cancelFn: async ({ requestId }) => {
            await CmonStatService.cancelRequest(requestId);
        },
        ...rest,
    });

    const refresh = useCallback(
        async (params: UseMonitorRefreshProps = {}, opts) => {
            await refreshFetch({
                clusterId: cluster?.clusterId,
                startTs,
                endTs,
                returnFromTs,
                // delay or execute in advance the timeout time so that is randomized in case we use
                // bunch of monitors in same component
                autoRefresh: refreshDelta
                    ? autoRefresh +
                      getRandomBetweenNegativeAndPositive(refreshDelta)
                    : autoRefresh,
                ...params,
            });
        },
        [
            cluster,
            startTs,
            endTs,
            returnFromTs,
            refreshFetch,
            autoRefresh,
            refreshDelta,
        ]
    );

    return {
        refresh,
        data,
        loading,
        loaded,
        cancel,
    };
}
