const dashboard = {
    title: 'MySQL Replication - Master',
    meta: {
        slug: 'mysql-replication-master',
        supportClusterTypes: ['replication'],
    },
    panels: [
        {
            title: 'Server Id',
            type: 'singlestat',
            gridPos: {
                h: 4,
                w: 4,
                x: 0,
                y: 0,
            },
            options: {
                decimals: 2,
                format: 'none',
                type: 'column',
                legend: {
                    alignAsTable: false,
                },
                yaxis: [],
                stack: false,
            },
            datasource: 'clustercontrol',
            targets: [
                {
                    expr:
                        "node:$[hostname='$instance' and nodetype='mysql' and role='master'].{'serverId': serverid}",
                    legendAsValue: true,
                    legendFormat: '{{ serverId }}',
                },
            ],
        },
        {
            title: 'Master Binlog File',
            type: 'singlestat',
            gridPos: {
                h: 4,
                w: 12,
                x: 0,
                y: 0,
            },
            options: {
                format: 'none',
                type: 'column',
                legend: {
                    alignAsTable: false,
                },
                yaxis: [],
                stack: false,
            },
            datasource: 'clustercontrol',
            targets: [
                {
                    expr:
                        "node:$[hostname='$instance' and nodetype='mysql' and role='master'].{'replicationMasterFile': replicationMaster.file}",
                    legendAsValue: true,
                    legendFormat: '{{ replicationMasterFile }}',
                },
            ],
        },
        {
            title: 'Master Binlog Pos',
            type: 'singlestat',
            gridPos: {
                h: 4,
                w: 4,
                x: 0,
                y: 0,
            },
            options: {
                decimals: 2,
                format: 'none',
                type: 'column',
                legend: {
                    alignAsTable: false,
                },
                yaxis: [],
                stack: false,
            },
            datasource: 'clustercontrol',
            targets: [
                {
                    expr:
                        "node:$[hostname='$instance' and nodetype='mysql' and role='master'].{'replicationMasterPosition': replicationMaster.position}",
                    legendAsValue: true,
                    legendFormat: '{{ replicationMasterPosition }}',
                },
            ],
        },
        {
            title: 'Slaves Connected',
            type: 'singlestat',
            gridPos: {
                h: 4,
                w: 4,
                x: 0,
                y: 0,
            },
            options: {
                decimals: 0,
                format: 'short',
                type: 'column',
                legend: {
                    alignAsTable: false,
                },
                yaxis: [],
                stack: false,
            },
            targets: [
                {
                    expr:
                        'mysql_global_status_slaves_connected{instance=~"$instance"}',
                    legendFormat: '',
                },
            ],
        },
        {
            title: 'Binlog writes',
            type: 'chart',
            gridPos: {
                h: 7,
                w: 24,
                x: 0,
                y: 0,
            },
            options: {
                decimals: 2,
                type: 'area',
                legend: {
                    alignAsTable: true,
                },
                yaxis: [
                    {
                        format: 'sizeps',
                        max: null,
                        min: 0,
                    },
                ],
                stack: false,
            },
            targets: [
                {
                    expr:
                        'irate(mysql_global_status_binlog_bytes_written{instance=~"$instance"}[5m])',
                    legendFormat: 'Binlog writes',
                },
            ],
        },
        {
            title: 'Binlog Creation Frequency',
            type: 'chart',
            gridPos: {
                h: 7,
                w: 24,
                x: 0,
                y: 0,
            },
            options: {
                decimals: 2,
                type: 'column',
                legend: {
                    alignAsTable: true,
                },
                yaxis: [
                    {
                        format: 'short',
                        max: null,
                        min: 0,
                    },
                ],
                stack: false,
            },
            targets: [
                {
                    expr:
                        'sum(mysql_binlog_file_number{instance=~"$instance"}) by  (mysql_binlog_file_number)',
                    legendFormat: 'Binlog Index',
                },
            ],
        },
    ],
    templating: {
        list: [
            {
                datasource: 'clustercontrol',
                multi: false,
                label: 'Host',
                name: 'instance',
                query:
                    "host:$[nodes[(nodetype='mysql' or nodetype='galera') and role='master']]",
                optionValue: 'hostname',
                optionText: 'hostname',
                sort: 1,
                isInstancePicker: true,
            },
        ],
    },
    schemaVersion: 1,
};
export default dashboard;
