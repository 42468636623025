import React from 'react';
import { FormInstance } from 'antd/lib/form';
import SpaceDescriptions from '../../../../common/Layout/SpaceDescriptions';
import YesNoFormat from '../../../../common/Format/YesNoFormat';
import AppDivider from '../../../../common/AppDivider';
import MongoShardsDeploymentShardsSummary from '../../../Services/Cluster/Deploy/MongoShards/MongoShardsDeploymentShardsSummary';
import MongoShardsDeploymentConfigurator from '../../../Services/Cluster/Deploy/MongoShards/MongoShardsDeploymentConfigurator';

export default ClusterAddShardSummary;

export type ClusterAddShardSummaryProps = {
    form: FormInstance;
};

function ClusterAddShardSummary({ form }: ClusterAddShardSummaryProps) {
    const {
        configurationTemplate,
        serverDataDirectory,
        installSoftware,
        disableFirewall,
        disableSeLinux,
    } = form.getFieldsValue(true);
    return (
        <div>
            <SpaceDescriptions
                direction="vertical"
                title="Database settings"
                className="ClusterAddShardSummary"
                alignItems="right"
            >
                <SpaceDescriptions.Item
                    label="Configuration template"
                    labelStrong
                >
                    {configurationTemplate}
                </SpaceDescriptions.Item>
                <SpaceDescriptions.Item label="Data directory" labelStrong>
                    {serverDataDirectory}
                </SpaceDescriptions.Item>
                <SpaceDescriptions.Item label="Install software" labelStrong>
                    <YesNoFormat booleanVar={installSoftware} />
                </SpaceDescriptions.Item>
                <AppDivider />
            </SpaceDescriptions>
            <SpaceDescriptions
                direction="vertical"
                title="Security configuration"
                size="small"
                alignItems="right"
            >
                <SpaceDescriptions.Item label="Disable firewall" labelStrong>
                    <YesNoFormat booleanVar={disableFirewall} />
                </SpaceDescriptions.Item>
                <SpaceDescriptions.Item
                    label="Disable SELinux/AppArmor"
                    labelStrong
                >
                    <YesNoFormat booleanVar={disableSeLinux} />
                </SpaceDescriptions.Item>
                <AppDivider />
            </SpaceDescriptions>
            <MongoShardsDeploymentShardsSummary
                form={form}
                configurator={MongoShardsDeploymentConfigurator}
            />
        </div>
    );
}
