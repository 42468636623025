
import CcMySqlNode, { CcMySqlNodeProps } from '../../models/CcMySqlNode';

export interface CmonGroupReplHostGroupReplProps {
    channel_name?: string;
    member_status?: string;
    worker_last_errno?: number;
    worker_last_error?: string;
    worker_service_state?: string;
    worker_tx_checked?: string;
    worker_tx_conflicts?: string;
    worker_tx_in_queue?: string;
    worker_tx_rows_validated?: string;
}


export interface CmonGroupReplHostProps  extends CcMySqlNodeProps {
    group_repl?: CmonGroupReplHostGroupReplProps;

}

export class CmonGroupReplHostGroupRepl {
    public channelName?: string;
    public memberStatus?: string;
    public workerLastErrno?: number;
    public workerLastError?: string;
    public workerServiceState?: string;
    public workerTxChecked?: string;
    public workerTxConflicts?: string;
    public workerTxInQueue?: string;
    public workerTxRowsValidated?: string;

    constructor(props: CmonGroupReplHostGroupReplProps) {
        this.channelName = props.channel_name;
        this.memberStatus = props.member_status;
        this.workerLastErrno = props.worker_last_errno;
        this.workerLastError = props.worker_last_error;
        this.workerServiceState = props.worker_service_state;
        this.workerTxChecked = props.worker_tx_checked;
        this.workerTxConflicts = props.worker_tx_conflicts;
        this.workerTxInQueue = props.worker_tx_in_queue;
        this.workerTxRowsValidated = props.worker_tx_rows_validated;

    }}



export default class CmonGroupReplHost extends CcMySqlNode {
    public groupRepl?: CmonGroupReplHostGroupRepl;


    constructor(props: CmonGroupReplHostProps) {
        super(props);
        this.groupRepl = props.group_repl && new CmonGroupReplHostGroupRepl(props.group_repl);

    }

}
