import {
    useCallback,
    useContext,
    useEffect,
    useReducer,
    useState,
} from 'react';
import { ClusterConfigValue } from '../useClusterConfig';
import {
    initialListState,
    listReducer,
    setListAll,
} from '../../../common/listReducer';
import { arrayPages } from '../../../common/filtering';
import { getSortAlphabeticFn } from '../../../common/sorting';
import {
    ConfigClusterThresholdsContext,
    ConfigGroupedContext,
} from './ConfigContext';

const defaultSorterFn = getSortAlphabeticFn(
    'ascend',
    (x: ClusterConfigValue) => x.name
);

export type UseClusterConfigThresholdListFilterParams = {
    order?: (a: any, b: any) => number;
    arr?: any[];
};
export type UseClusterConfigThresholdListProps = {};
export default function useClusterConfigThresholdList({}: UseClusterConfigThresholdListProps = {}) {
    const [items, setItems] = useState<ClusterConfigValue[]>([]);
    const {thresholds} = useContext(ConfigClusterThresholdsContext);
    const { ...rest } = useContext(ConfigGroupedContext);
    const [{ order: listOrder }, listDispatch] = useReducer(listReducer, {
        ...initialListState,
        order: defaultSorterFn,
    });

    const filter = useCallback<
        (p?: UseClusterConfigThresholdListFilterParams) => void
    >(
        ({ order = listOrder, arr = thresholds } = {}) => {
            listDispatch(setListAll({ order }));
            setItems(
                arrayPages({
                    order,
                    arr,
                })
            );
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [thresholds, listOrder]
    );

    useEffect(() => {
        if (thresholds) {
            filter({
                arr: thresholds,
            });
        }
    }, [thresholds]);

    return {
        items,
        filter,
        ...rest,
    };
}
