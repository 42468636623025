const mock = {
    class_name: 'CmonClusterInfo',
    cdt_path: '/',
    acl: 'user::rwx,group::rwx,other::---',
    tags: ['postgresql', 'development'],
    cluster_auto_recovery: false,
    cluster_id: 3,
    cluster_name: 'postgresWithLb',
    cluster_type: 'POSTGRESQL_SINGLE',
    configuration_file: '/etc/cmon.d/cmon_3.cnf',
    effective_privileges: 'rwx',
    log_file: '/var/log/cmon_3.log',
    maintenance_mode_active: false,
    managed: true,
    node_auto_recovery: false,
    rpc_version: '2.0',
    state: 'STARTED',
    status_text: 'All nodes are operational.',
    vendor: 'postgres',
    version: '12',
    alarm_statistics: {
        class_name: 'CmonAlarmStatistics',
        cluster_id: 3,
        created_after: '1970-01-01T00:00:00.000Z',
        critical: 0,
        reported_after: '1970-01-01T00:00:00.000Z',
        warning: 0,
    },
    group_owner: {
        class_name: 'CmonGroup',
        cdt_path: '/groups',
        owner_user_id: 1,
        owner_user_name: 'system',
        owner_group_id: 1,
        owner_group_name: 'admins',
        acl: 'user::rwx,group::rwx,other::---',
        created: '2021-04-28T22:57:55.328Z',
        group_id: 1,
        group_name: 'admins',
    },
    hosts: [
        {
            acl: 'user::rwx,group::rwx,other::---',
            archive_command: '',
            archive_mode: 'off',
            cdt_path: '/PG',
            class_name: 'CmonPostgreSqlHost',
            clusterid: 481,
            configfile: ['/etc/postgresql/11/main/postgresql.conf'],
            connected: true,
            connected_slaves: 2,
            container: false,
            data_directory: '/var/lib/postgresql/11/main',
            datadir: '/var/lib/postgresql/11/main',
            description: '',
            distribution: {
                codename: 'xenial',
                name: 'ubuntu',
                release: '16.04',
                type: 'debian',
            },
            extensions: [
                { name: 'plpgsql', version: '1.0' },
                { name: 'pg_stat_statements', version: '1.6' },
            ],
            hostId: 4639,
            hostname: '10.10.10.16',
            hostname_data: '10.10.10.16',
            hostname_internal: '',
            hoststatus: 'CmonHostOnline',
            hot_standby: true,
            ip: '10.10.10.16',
            lastseen: 1571661307,
            logfile:
                '/var/lib/postgresql/11/main/log/postgresql-2019-10-21_000000.log',
            maintenance_mode_active: false,
            message: 'Up and running.',
            nodetype: 'postgres',
            owner_group_id: 1,
            owner_group_name: 'admins',
            owner_user_id: 1,
            owner_user_name: 'system',
            pgname: 'main',
            pid: 21478,
            pidfile: '/var/run/postgresql/11-main.pid',
            pingstatus: 1,
            pingtime: 205,
            port: 5432,
            readonly: false,
            received_location: '0/70E67A8',
            replication_slave: {
                master_host: '',
                master_port: '',
                replay_location: '0/70E67A8',
            },
            role: 'master',
            service_started: 1570802499,
            socket: '/var/run/postgresql/.s.PGSQL.5432',
            sshfailcount: 0,
            ssl_certs: {
                server: {
                    ca: '',
                    id: 0,
                    key: '/var/lib/postgresql/11/main/server.key',
                    path: '/var/lib/postgresql/11/main/server.crt',
                    ssl_enabled: false,
                },
            },
            synchronous: false,
            timestamp: 1571661307,
            unique_id: 1027,
            uptime: 858804,
            version: '11.5 (Ubuntu 11.5-3.pgdg16.04+1)',
            wallclock: 1571661278,
            wallclocktimestamp: 1571661277,
        },
        {
            acl: 'user::rwx,group::rwx,other::---',
            cdt_path: '/PG',
            class_name: 'CmonHost',
            clusterid: 481,
            configfile: '/etc/cmon.d/cmon_481.cnf',
            connected: true,
            container: false,
            distribution: {
                codename: 'xenial',
                name: 'ubuntu',
                release: '16.04',
                type: 'debian',
            },
            hostId: 4642,
            hostname: '10.0.1.13',
            hoststatus: 'CmonHostOnline',
            ip: '10.0.1.13',
            lastseen: 1571661302,
            logfile: '/var/log/cmon_481.log',
            maintenance_mode_active: false,
            message: 'Up and running.',
            nodetype: 'controller',
            owner_group_id: 1,
            owner_group_name: 'admins',
            owner_user_id: 1,
            owner_user_name: 'system',
            pid: 32496,
            pingstatus: 1,
            pingtime: 230,
            port: 9500,
            role: 'controller',
            timestamp: 1571661302,
            unique_id: 1030,
            uptime: 692,
            version: '1.7.4',
            wallclock: 1571661277,
            wallclocktimestamp: 1571661277,
        },
        {
            acl: 'user::rwx,group::rwx,other::---',
            cdt_path: '/PG',
            class_name: 'CmonPrometheusHost',
            clusterid: 481,
            configfile: '/etc/prometheus/prometheus.yml',
            configuration: [
                { arguments: '', job: 'prometheus', scrape_interval: '2s' },
                { arguments: '', job: 'node', scrape_interval: '10s' },
                { arguments: '', job: 'process', scrape_interval: '10s' },
                { arguments: '', job: 'proxysql', scrape_interval: '10s' },
                { arguments: '', job: 'postgres', scrape_interval: '10s' },
                { arguments: '', job: 'haproxy', scrape_interval: '10s' },
            ],
            container: false,
            data_retention: '15d',
            distribution: {
                codename: 'xenial',
                name: 'ubuntu',
                release: '16.04',
                type: 'debian',
            },
            exporters: [
                {
                    address: '10.10.10.16:9011',
                    job: 'process',
                    monitored_host: '',
                    up: true,
                    version: '0.10.10',
                },
                {
                    address: '10.10.10.16:9100',
                    job: 'node',
                    monitored_host: '',
                    up: true,
                    version: '0.18.0',
                },
                {
                    address: '10.10.10.16:9101',
                    job: 'haproxy',
                    monitored_host: '10.10.10.16:9600',
                    up: true,
                    version: '0.9.0',
                },
                {
                    address: '10.10.10.16:9187',
                    job: 'postgres',
                    monitored_host: '10.10.10.16:5432',
                    up: true,
                    version: '0.4.7',
                },
                {
                    address: '10.10.10.17:9011',
                    job: 'process',
                    monitored_host: '',
                    up: true,
                    version: '0.10.10',
                },
                {
                    address: '10.10.10.17:9100',
                    job: 'node',
                    monitored_host: '',
                    up: true,
                    version: '0.18.0',
                },
                {
                    address: '10.10.10.17:9102',
                    job: 'haproxy',
                    monitored_host: '10.10.10.17:9600',
                    up: true,
                    version: '0.9.0',
                },
                {
                    address: '10.10.10.17:9188',
                    job: 'postgres',
                    monitored_host: '10.10.10.17:5432',
                    up: true,
                    version: '0.5.1',
                },
            ],
            hostId: 4642,
            hostname: '10.0.1.13',
            hoststatus: 'CmonHostOnline',
            ip: '10.0.1.13',
            lastseen: 1571661302,
            logfile: '/var/log/prometheus/prometheus.log',
            maintenance_mode_active: false,
            message: "Process 'prometheus' is running.",
            nodetype: 'prometheus',
            owner_group_id: 1,
            owner_group_name: 'admins',
            owner_user_id: 1,
            owner_user_name: 'system',
            pid: 14852,
            pingstatus: 1,
            pingtime: 230,
            port: 9090,
            scrape_interval: '10s',
            timestamp: 1571661302,
            unique_id: 1039,
            version: '2.12.0',
            wallclock: 1571661277,
            wallclocktimestamp: 1571661277,
        },
        {
            acl: 'user::rwx,group::rwx,other::---',
            application_name: 'pgsql_481_node_0',
            archive_command: '',
            archive_mode: 'off',
            cdt_path: '/PG',
            class_name: 'CmonPostgreSqlHost',
            clusterid: 481,
            configfile: ['/etc/postgresql/11/main/postgresql.conf'],
            connected: true,
            connected_slaves: 0,
            container: false,
            data_directory: '/var/lib/postgresql/11/main',
            datadir: '/var/lib/postgresql/11/main',
            description: '',
            distribution: {
                codename: 'xenial',
                name: 'ubuntu',
                release: '16.04',
                type: 'debian',
            },
            extensions: [
                { name: 'plpgsql', version: '1.0' },
                { name: 'pg_stat_statements', version: '1.6' },
            ],
            failedmaster: false,
            hostId: 4699,
            hostname: '10.10.10.17',
            hoststatus: 'CmonHostOnline',
            hot_standby: true,
            ip: '10.10.10.17',
            lastseen: 1571661303,
            logfile:
                '/var/lib/postgresql/11/main/log/postgresql-2019-10-21_000000.log',
            maintenance_mode_active: false,
            message: 'Up and running.',
            nodetype: 'postgres',
            owner_group_id: 1,
            owner_group_name: 'admins',
            owner_user_id: 1,
            owner_user_name: 'system',
            pgname: 'main',
            pid: 9694,
            pidfile: '/var/run/postgresql/11-main.pid',
            pingstatus: 1,
            pingtime: 272,
            port: 5432,
            readonly: true,
            received_location: '0/70E67A8',
            replication_slave: {
                master_cluster_id: 481,
                master_host: '10.10.10.16',
                master_port: '5432',
                replay_location: '0/70E67A8',
                seconds_behind_master: '0',
            },
            replication_state: 'streaming',
            role: 'slave',
            service_started: 1571122996,
            socket: '/var/run/postgresql/.s.PGSQL.5432',
            sshfailcount: 0,
            ssl_certs: {
                server: {
                    ca: '',
                    key: '/var/lib/postgresql/11/main/server.key',
                    path: '/var/lib/postgresql/11/main/server.crt',
                    ssl_enabled: false,
                },
            },
            sync_priority: 0,
            sync_state: 'async',
            synchronous: false,
            timestamp: 1571661303,
            trigger_file: '/tmp/failover_5432.trigger',
            unique_id: 1048,
            uptime: 538307,
            version: '11.5 (Ubuntu 11.5-3.pgdg16.04+1)',
            wallclock: 1571661278,
            wallclocktimestamp: 1571661278,
        },
        {
            acl: 'user::rwx,group::rwx,other::---',
            backend_info: {
                backend_servers: [
                    {
                        last_check_state: '',
                        name: 'admin_page',
                        service: 'FRONTEND',
                        status: 'OPEN',
                    },
                    {
                        last_check_state: '',
                        name: 'admin_page',
                        service: 'BACKEND',
                        status: 'UP',
                    },
                    {
                        last_check_state: '',
                        name: 'haproxy_10.10.10.16_5433_rw',
                        service: 'FRONTEND',
                        status: 'OPEN',
                    },
                    {
                        last_check_state: '(tcp-check)',
                        name: 'haproxy_10.10.10.16_5433_rw',
                        service: '10.10.10.16',
                        status: 'UP',
                    },
                    {
                        last_check_state:
                            "TCPCHK did not match content 'master is running' at step 1",
                        name: 'haproxy_10.10.10.16_5433_rw',
                        service: '10.10.10.17',
                        status: 'DOWN',
                    },
                    {
                        last_check_state: '',
                        name: 'haproxy_10.10.10.16_5433_rw',
                        service: 'BACKEND',
                        status: 'UP',
                    },
                    {
                        last_check_state: '',
                        name: 'haproxy__5434_ro',
                        service: 'FRONTEND',
                        status: 'OPEN',
                    },
                    {
                        last_check_state: '',
                        name: 'haproxy__5434_ro',
                        service: '10.10.10.16',
                        status: 'UP',
                    },
                    {
                        last_check_state: '(tcp-check)',
                        name: 'haproxy__5434_ro',
                        service: '10.10.10.17',
                        status: 'UP',
                    },
                    {
                        last_check_state: '',
                        name: 'haproxy__5434_ro',
                        service: 'BACKEND',
                        status: 'UP',
                    },
                ],
            },
            backend_name_ro: 'haproxy__5434_ro',
            backend_name_rw: 'haproxy_10.10.10.16_5433_rw',
            bind_node_address: [],
            cdt_path: '/PG',
            class_name: 'CmonHaProxyHost',
            clusterid: 481,
            cmdline: 'systemctl restart haproxy',
            configfile: '/etc/haproxy/haproxy.cfg',
            connectstring: '10.10.10.16:5433',
            container: false,
            distribution: {
                codename: 'xenial',
                name: 'ubuntu',
                release: '16.04',
                type: 'debian',
            },
            hostId: 4639,
            hostname: '10.10.10.16',
            hoststatus: 'CmonHostOnline',
            ip: '10.10.10.16',
            lastseen: 1571661302,
            lb_admin: 'admin',
            lb_name: 'haproxy__5434_ro',
            lb_password: 'admin',
            lb_policy: 'leastconn',
            maintenance_mode_active: false,
            max_connections_be: 64,
            max_connections_fe: 8192,
            message: "Process 'haproxy' is running.",
            monitor_user: 's9smysqlchk',
            nodetype: 'haproxy',
            pid: 15152,
            pingstatus: 1,
            pingtime: 205,
            port: 9600,
            ro_port: 5434,
            rw_port: 5433,
            server_info: {
                nodes: [
                    { hostname: '10.10.10.16', port: 5432, role: 'active' },
                    { hostname: '10.10.10.17', port: 5432, role: 'active' },
                ],
            },
            socket: '/var/run/haproxy.socket',
            sshfailcount: 0,
            stats_socket: '/var/run/haproxy.socket',
            svc_check_script: 'postgreschk',
            timeout_client: 10800,
            timeout_server: 10800,
            timestamp: 1571661302,
            unique_id: 1051,
            version: '1.6.3',
            wallclock: 1571661278,
            wallclocktimestamp: 1571661278,
            xinetd_allow_from: '0.0.0.0/0',
            xinetd_port: 9201,
        },
        {
            acl: 'user::rwx,group::rw-,other::---',
            backend_info: {
                backend_servers: [
                    {
                        last_check_state: '',
                        name: 'admin_page',
                        service: 'FRONTEND',
                        status: 'OPEN',
                    },
                    {
                        last_check_state: '',
                        name: 'admin_page',
                        service: 'BACKEND',
                        status: 'UP',
                    },
                    {
                        last_check_state: '',
                        name: 'haproxy_10.10.10.17_5433_rw',
                        service: 'FRONTEND',
                        status: 'OPEN',
                    },
                    {
                        last_check_state: '',
                        name: 'haproxy_10.10.10.17_5433_rw',
                        service: '10.10.10.16',
                        status: 'UP',
                    },
                    {
                        last_check_state:
                            "TCPCHK did not match content 'master is running' at step 2",
                        name: 'haproxy_10.10.10.17_5433_rw',
                        service: '10.10.10.17',
                        status: 'DOWN',
                    },
                    {
                        last_check_state: '',
                        name: 'haproxy_10.10.10.17_5433_rw',
                        service: 'BACKEND',
                        status: 'UP',
                    },
                    {
                        last_check_state: '',
                        name: 'haproxy__5434_ro',
                        service: 'FRONTEND',
                        status: 'OPEN',
                    },
                    {
                        last_check_state: '(tcp-check)',
                        name: 'haproxy__5434_ro',
                        service: '10.10.10.16',
                        status: 'UP',
                    },
                    {
                        last_check_state: '(tcp-check)',
                        name: 'haproxy__5434_ro',
                        service: '10.10.10.17',
                        status: 'UP',
                    },
                    {
                        last_check_state: '',
                        name: 'haproxy__5434_ro',
                        service: 'BACKEND',
                        status: 'UP',
                    },
                ],
            },
            backend_name_ro: 'haproxy__5434_ro',
            backend_name_rw: 'haproxy_10.10.10.17_5433_rw',
            bind_node_address: [],
            cdt_path: '/PG',
            class_name: 'CmonHaProxyHost',
            clusterid: 481,
            cmdline: 'systemctl restart haproxy',
            configfile: '/etc/haproxy/haproxy.cfg',
            connectstring: '10.10.10.17:5433',
            container: false,
            distribution: {
                codename: 'xenial',
                name: 'ubuntu',
                release: '16.04',
                type: 'debian',
            },
            hostId: 4699,
            hostname: '10.10.10.17',
            hoststatus: 'CmonHostOnline',
            ip: '10.10.10.17',
            lastseen: 1571661302,
            lb_admin: 'admin',
            lb_name: 'haproxy__5434_ro',
            lb_password: 'admin',
            lb_policy: 'leastconn',
            maintenance_mode_active: false,
            max_connections_be: 64,
            max_connections_fe: 8192,
            message: "Process 'haproxy' is running.",
            monitor_user: 's9smysqlchk',
            nodetype: 'haproxy',
            pid: 7432,
            pingstatus: 1,
            pingtime: 272,
            port: 9600,
            ro_port: 5434,
            rw_port: 5433,
            server_info: {
                nodes: [
                    { hostname: '10.10.10.16', port: 5432, role: 'active' },
                    { hostname: '10.10.10.17', port: 5432, role: 'active' },
                ],
            },
            socket: '/var/run/haproxy.socket',
            sshfailcount: 0,
            stats_socket: '/var/run/haproxy.socket',
            svc_check_script: 'postgreschk',
            timeout_client: 10800,
            timeout_server: 10800,
            timestamp: 1571661302,
            unique_id: 1147,
            version: '1.6.3',
            wallclock: 1571661278,
            wallclocktimestamp: 1571661278,
            xinetd_allow_from: '0.0.0.0/0',
            xinetd_port: 9201,
        },
    ],
    job_statistics: {
        class_name: 'CmonJobStatistics',
        cluster_id: 3,
        by_state: {
            ABORTED: 0,
            DEFINED: 0,
            DEQUEUED: 0,
            FAILED: 1,
            FINISHED: 0,
            PAUSED: 1,
            RUNNING: 0,
            SCHEDULED: 1,
        },
    },
    owner: {
        class_name: 'CmonUser',
        cdt_path: '/',
        acl: 'user::rwx,group::r--,other::r--',
        disabled: false,
        suspended: false,
        groups: [],
        timezone: {
            class_name: 'CmonTimeZone',
            name: 'Coordinated Universal Time',
            abbreviation: 'UTC',
            offset: 0,
            use_dst: false,
        },
    },
};
export default mock;
