import React, { useRef } from 'react';
import { ButtonProps } from 'antd/lib/button';
import CcAlarm from '../../services/models/CcAlarm';
import DetailsModal, {
    DetailsModalApi,
} from '../../common/Feedback/DetailsModal';
import { Space } from 'antd';
import { getStatusFormatIconFilled } from '@severalnines/bar-frontend-components/build/lib/Format/StatusFormat';
import { getAlarmSeverityFormatType } from './AlarmSeverityFormat';
import TextFormat from '@severalnines/bar-frontend-components/build/lib/Format/TextFormat';
import SpaceDescriptions from '../../common/Layout/SpaceDescriptions';
import AlarmActionsMenu from './AlarmActionsMenu';
import TypographyText from '../../common/TypographyText';
import { CopyOutlined } from '@ant-design/icons';
import ButtonWithForm from '../../common/General/ButtonWithForm';
import AppActionButton from '../../common/General/AppActionButton';

export default AlarmDetailsButton;

export type AlarmDetailsButtonProps = ButtonProps & {
    alarm: CcAlarm;
    children?: React.ReactNode;
};

function AlarmDetailsButton({
    alarm,
    children,
    ...rest
}: AlarmDetailsButtonProps) {
    const modalRef = useRef<DetailsModalApi>();
    const handleClick = async () => {
        modalRef.current?.show();
    };

    return (
        <ButtonWithForm
            button={
                <AppActionButton onClick={handleClick} {...rest}>
                    {children || 'Details'}
                </AppActionButton>
            }
            form={
                <DetailsModal
                    ref={modalRef}
                    visible={true}
                    title="Alarm details"
                    headerTitle={
                        <TextFormat
                            type={getAlarmSeverityFormatType(
                                alarm.severityName
                            )}
                        >
                            {alarm.title}
                        </TextFormat>
                    }
                    icon={getStatusFormatIconFilled(
                        getAlarmSeverityFormatType(alarm.severityName)
                    )}
                    header={
                        <SpaceDescriptions>
                            <SpaceDescriptions.Item label="Recommendation">
                                {alarm.recommendation}
                            </SpaceDescriptions.Item>
                        </SpaceDescriptions>
                    }
                    headerExtra={
                        <AlarmActionsMenu
                            alarm={alarm}
                            excludeKeys={['details']}
                        />
                    }
                >
                    <SpaceDescriptions
                        title="Alarm details"
                        extra={
                            <TypographyText
                                copyable={{
                                    text: alarm.message,
                                    icon: (
                                        <Space>
                                            <CopyOutlined />
                                            Copy to clipboard
                                        </Space>
                                    ),
                                }}
                            ></TypographyText>
                        }
                    >
                        <SpaceDescriptions.Item>
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: alarm.message || '',
                                }}
                            />
                        </SpaceDescriptions.Item>
                    </SpaceDescriptions>
                </DetailsModal>
            }
        ></ButtonWithForm>
    );
}
