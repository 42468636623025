import { Select, SelectProps } from 'antd';
import React, { ReactNode, useEffect, useState } from 'react';
export default MultiSelect;

export const MULTI_SELECT_ALL_OPTION_VALUE = '|{all}|';
export type MultiSelectOptionType = {
    label?: ReactNode;
    value?: number | string;
};
export type MultiSelectProps = Omit<SelectProps<any>, 'options'> & {
    options: MultiSelectOptionType[];
    allOptionName?: string;
};
function MultiSelect({
    options,
    allOptionName,
    value,
    onChange,
    ...rest
}: MultiSelectProps) {
    const [list, setList] = useState<MultiSelectOptionType[]>(getAllOption());
    const [selected, setSelected] = useState<any[]>(
        value || (allOptionName && [MULTI_SELECT_ALL_OPTION_VALUE]) || undefined
    );
    useEffect(() => {
        if (options && options.length > 0) {
            setList([...getAllOption(), ...options]);
            if (value) {
                const items = options
                    .filter(
                        (option) =>
                            !!value.find((item: any) => item === option.value)
                    )
                    .map((option) => option.value);
                if (items.length > 0) {
                    setSelected(items);
                } else if (!!allOptionName) {
                    changeValue([MULTI_SELECT_ALL_OPTION_VALUE]);
                }
            } else if (!!allOptionName) {
                changeValue([MULTI_SELECT_ALL_OPTION_VALUE]);
            }
        }
    }, [options]);

    useEffect(() => {
        if (value) {
            setSelected(value);
        }
    }, [value]);

    const changeValue = (values: string[], option?: any) => {
        setSelected(values);
        onChange?.(
            values,
            option || values.map((item) => ({ label: item, value: item }))
        );
    };

    const handleChange = (values: string[], option: any) => {
        let newValues;
        if (
            !!allOptionName &&
            (values[values.length - 1] === MULTI_SELECT_ALL_OPTION_VALUE ||
                values.length < 1)
        ) {
            newValues = [MULTI_SELECT_ALL_OPTION_VALUE];
        } else {
            newValues = values.filter(
                (value) => value !== MULTI_SELECT_ALL_OPTION_VALUE
            );
        }
        changeValue(newValues, option);
    };

    return (
        <Select
            data-testid="cc-multi-select"
            mode="multiple"
            value={selected}
            onChange={handleChange}
            options={list as any}
            showArrow={true}
            {...rest}
        />
    );

    function getAllOption(): MultiSelectOptionType[] {
        return !!allOptionName
            ? [{ label: allOptionName, value: MULTI_SELECT_ALL_OPTION_VALUE }]
            : [];
    }
}
