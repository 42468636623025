
import CmonRequestService, {CmonRequestServiceResponseData} from './CmonRequestService';


export interface ListCertsRequestData {

    [key: string]: any;
}

export interface CertInfoRequestData {
    id?: number;
    [key: string]: any;
}

export interface CreateRequestData {
    type?: string;
    name?: string;
    validity?: number;
    data?: {[key:string]:any};
    [key: string]: any;
}

export interface RevokeRequestData {
    id?: number;
    [key: string]: any;
}

export interface DeleteRequestData {
    id?: number;
    [key: string]: any;
}

export interface MoveRequestData {
    id?: number;
    name?: string;
    [key: string]: any;
}

export interface CrlRequestData {
    id?: number;
    [key: string]: any;
}

export interface ImportlocalRequestData {
    cert_file?: string;
    key_file?: string;
    ca_file?: string;
    name?: string;
    name_ca?: string;
    [key: string]: any;
}


export default class CmonCaService extends CmonRequestService {
    
    public static module(): string {
        return 'ca';
    }
    
    public static async listCerts(
        data: ListCertsRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('listCerts', data, opts);
    }

    public static async certInfo(
        data: CertInfoRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('certInfo', data, opts);
    }

    public static async create(
        data: CreateRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('create', data, opts);
    }

    public static async revoke(
        data: RevokeRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('revoke', data, opts);
    }

    public static async delete(
        data: DeleteRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('delete', data, opts);
    }

    public static async move(
        data: MoveRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('move', data, opts);
    }

    public static async crl(
        data: CrlRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('crl', data, opts);
    }

    public static async importlocal(
        data: ImportlocalRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('importlocal', data, opts);
    }

}
