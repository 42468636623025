


export interface CmonDateTimeProps  {
    tv_nsec?: number;
    tv_sec?: number;

}



export default class CmonDateTime {
    public tvNsec?: number;
    public tvSec?: number;


    constructor(props: CmonDateTimeProps) {
        this.tvNsec = props.tv_nsec;
        this.tvSec = props.tv_sec;

    }

}
