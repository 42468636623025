import React from 'react';
import ButtonWithForm from '../../../common/General/ButtonWithForm';
import UserDetailsModal from './UserDetailsModal';
import { ButtonProps } from 'antd/lib/button';
import CcUser from '../../../services/models/CcUser';
import AppActionButton from '../../../common/General/AppActionButton';

export default UserDetailsButton;

export type UserDetailsButtonProps = ButtonProps & {
    user: CcUser;
    onEdit?: () => void;
};

function UserDetailsButton({
    user,
    children,
    onEdit,
    ...rest
}: UserDetailsButtonProps) {
    return (
        <ButtonWithForm
            button={
                <AppActionButton {...rest}>
                    {children || 'View'}
                </AppActionButton>
            }
            form={<UserDetailsModal user={user} onEdit={onEdit} />}
        />
    );
}
