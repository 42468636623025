import React from 'react';
import { Form } from 'antd';
import { FormInstance } from 'antd/lib/form';
import WizardForm from '@severalnines/bar-frontend-components/build/lib/Navigation/WizardForm';
import WizardFormConfiguration from '@severalnines/bar-frontend-components/build/lib/Navigation/Wizard/WizardFormConfiguration';
import CcCluster from '../../../../services/models/CcCluster';
import CreatePgBouncerAuthentication from './CreatePgBouncerWizardSteps/CreatePgBouncerAuthentication';
import CreatePgBouncerAddNodes, {
    getCreatePgBouncerAddNodesValidation,
    PGBOUNCER_NODES_INITIAL,
} from './CreatePgBouncerWizardSteps/CreatePgBouncerAddNodes';
import CreatePgBouncerPreview from './CreatePgBouncerWizardSteps/CreatePgBouncerPreview';
import useCreateJob from '../../../Jobs/useCreateJob';
import { CmonJobInstanceCommand } from '../../../../services/cmon/models/CmonJobInstance';

enum PgBouncerFormSteps {
    AUTHENTICATION = 'Authentication',
    ADD_NODES = 'Add nodes',
    PREVIEW = 'preview',
}

export type CreatePgBouncerWizardFormProps = {
    onCancel?: () => void;
    onSuccess?: () => void;
    onError?: (err: Error) => void;
    onStepErrorInsist?: (err: Error) => void;
    onTouchedChange?: (touched: boolean) => void;
    cluster?: CcCluster;
};
export default CreatePgBouncerWizardForm;

function CreatePgBouncerWizardForm({
    onCancel,
    onSuccess,
    onStepErrorInsist,
    onTouchedChange,
    onError,
    cluster,
}: CreatePgBouncerWizardFormProps) {
    const [form] = Form.useForm();

    const { loading, send } = useCreateJob({
        clusterId: cluster?.clusterId,
        title: 'Setup PgBouncer',
        command: CmonJobInstanceCommand.PGBOUNCER.toLowerCase() as CmonJobInstanceCommand,
        onSuccess,
    });

    const handleSubmit = async () => {
        try {
            await send({
                clusterId: cluster?.clusterId,
                ...formatFields(form),
            });
        } catch (err: any) {
            if (onError) {
                onError(err);
            }
        }
    };
    return (
        <WizardFormConfiguration
            form={form}
            onSubmit={handleSubmit}
            loading={loading}
            onCancel={onCancel}
            onStepErrorInsist={onStepErrorInsist}
            onTouchedChange={onTouchedChange}
            initialValues={{
                ...PGBOUNCER_NODES_INITIAL,
            }}
            steps={[
                <WizardForm.Step
                    key={PgBouncerFormSteps.AUTHENTICATION}
                    title="Authentication"
                    subTitle=" "
                >
                    <CreatePgBouncerAuthentication />
                </WizardForm.Step>,
                <WizardForm.Step
                    key={PgBouncerFormSteps.ADD_NODES}
                    title="Add nodes"
                    subTitle=" "
                    validate={getCreatePgBouncerAddNodesValidation(form)}
                >
                    <CreatePgBouncerAddNodes form={form} cluster={cluster} />
                </WizardForm.Step>,

                <WizardForm.Step
                    key={PgBouncerFormSteps.PREVIEW}
                    title="Preview"
                    subTitle=" "
                >
                    <CreatePgBouncerPreview form={form} />
                </WizardForm.Step>,
            ]}
        />
    );
}

export function formatFields(form: FormInstance) {
    const { pgBouncerAuth, pgBouncerAddNodes } = form.getFieldsValue(true);

    return {
        action: 'setup',
        nodes: pgBouncerAddNodes?.hostname?.map((data: any) => {
            const hostname = data?.extraData.hostname;
            return {
                class_name: 'CmonPgBouncerHost',
                hostname,
                port: pgBouncerAddNodes?.listenPort,
            };
        }),
        admin_username: pgBouncerAuth?.adminUser,
        admin_password: pgBouncerAuth?.adminPassword,
    };
}
