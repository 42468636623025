


export interface CmonClusterConfigProps  {

}



export default class CmonClusterConfig {


    constructor(props: CmonClusterConfigProps) {

    }

}
