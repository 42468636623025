import ClusterTopologyConfigurator from './ClusterTopologyConfigurator';
import CcCluster from '../../../services/models/CcCluster';
import CcNode, { CcNodeType } from '../../../services/models/CcNode';
import CcElasticNode from '../../../services/models/CcElasticNode';
import { getNodeRoleText } from '../../Nodes/NodeTypeFormat';

export default class ClusterTopologyElasticConfigurator extends ClusterTopologyConfigurator {
    public hasRouters: boolean;
    constructor(clusters: CcCluster[]) {
        super(clusters);
        this.hasRouters = false;
    }

    nodeToItem(node: CcNode, cluster: CcCluster, item: any): any {
        let result = item;
        this.hasDbNodes = true;

        const elasticNode = node as CcElasticNode;
        if (node.isType(CcNodeType.ELASTIC)) {
            if (!this.groups[elasticNode.getRole()]) {
                this.groups[elasticNode.getRole()] = {
                    key: elasticNode.getRole() || '',
                    type: 'group',
                    title: getNodeRoleText(elasticNode.getRole()),
                    from: 'db',
                    placement: 'in',
                };
            }
            result.from = elasticNode.getRole();
        } else {
            result = super.nodeToItem(node, cluster, item);
        }
        return result;
    }
}
