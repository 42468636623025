import { Collapse } from 'antd';
import { CollapseProps } from 'antd/lib/collapse/Collapse';
import { ReactNode } from 'react';

export const CONTENT_TOGGLE_KEY = 'toggle-key';
export default AppContentToggle;

export type AppContentToggleProps = {
    title?: ReactNode;
    children?: ReactNode;
    open?: boolean;
    onChange?: Function;
} & CollapseProps;

function AppContentToggle({
    title,
    children,
    open = false,
    onChange,
    ...rest
}: AppContentToggleProps) {
    const { Panel } = Collapse;
    const key = CONTENT_TOGGLE_KEY;
    return (
        <Collapse
            defaultActiveKey={open ? [key] : []}
            onChange={onChange}
            ghost={true}
            {...rest}
        >
            <Panel header={title} key={key}>
                {children}
            </Panel>
        </Collapse>
    );
}
