import React from 'react';
import { Select, SelectProps } from 'antd';
import CcCluster, {
    CcClusterBase,
    CcClusterType,
} from '../../../services/models/CcCluster';
import {
    MonitorDashboardConfig,
    MonitorDashboardPanelConfig,
    MonitorDatasource,
} from '../../Monitor/Monitor';
import { collectionKeyValueSort } from '../../../common/sorting';
import ClusterOverview from '../../Monitor/data/cluster-overview';
import SystemOverview from '../../Monitor/data/system-overview';
import PostgresqlOverview from '../../Monitor/data/postgresql-overview';
import RedisOverview from '../../Monitor/data/redis-overview';
import MSSQLOverview from '../../Monitor/data/mssql-overview';
import GaleraOverview from '../../Monitor/data/galera-overview';
import MysqlServerGeneral from '../../Monitor/data/mysql-server-general';
import MysqlServerCaches from '../../Monitor/data/mysql-server-caches';
import MysqlServerUserstats from '../../Monitor/data/mysql-server-userstats';
import { targetsToExporters } from '../../Monitor/monitorUtils';
import { arrayIntersection } from '../../../common/filtering';
import { CcPrometheusExporterJob } from '../../../services/models/CcPrometheusExporter';
import MysqlInnodbMetrics from '../../Monitor/data/mysql-innodb-metrics';
import GaleraServerCharts from '../../Monitor/data/galera-server-charts';
import ProxysqlOverview from '../../Monitor/data/proxysql-overview';
import MongodbClusterOverview from '../../Monitor/data/mongodb-cluster-overview';
import { CcNodeRole, CcNodeType } from '../../../services/models/CcNode';
import MongodbReplicaset from '../../Monitor/data/mongodb-replicaset';
import MongodbServer from '../../Monitor/data/mongodb-server';
import HaproxyOverview from '../../Monitor/data/haproxy-overview';
import PgbouncerOverview from '../../Monitor/data/pgbouncer-overview';
import MysqlReplicationMaster from '../../Monitor/data/mysql-replication-master';
import MysqlReplicationSlave from '../../Monitor/data/mysql-replication-slave';
import { groupArrayBy } from '../../../common/helpers';

export default ClusterDashboardSelect;

export type ClusterDashboardSelectProps = SelectProps<string> & {
    cluster: CcCluster;
};

const DASHBOARDS: MonitorDashboardConfig[] = collectionKeyValueSort(
    [
        SystemOverview,
        ClusterOverview,
        PostgresqlOverview,
        RedisOverview,
        MSSQLOverview,
        GaleraOverview,
        GaleraServerCharts,
        MysqlServerGeneral,
        MysqlServerCaches,
        MysqlServerUserstats,
        MysqlInnodbMetrics,
        MysqlReplicationMaster,
        MysqlReplicationSlave,
        ProxysqlOverview,
        HaproxyOverview,
        PgbouncerOverview,
        MongodbClusterOverview,
        MongodbReplicaset,
        MongodbServer,
    ],
    'meta.slug',
    [
        'system-overview',
        'cluster-overview',
        'mysql-server-general',
        'mysql-server-caches',
        'mysql-server-userstats',
        'mysql-innodb-metrics',
        'mysql-replication-master',
        'mysql-replication-slave',
        'galera-overview',
        'galera-server-charts',
        'postgresql-overview',
        'mongodb-cluster-overview',
        'mongodb-replicaset',
        'mongodb-server',
        'proxysql-overview',
        'haproxy-overview',
        'pgbouncer-overview',
        'redis-overview',
        'mssql-overview',
    ]
) as any;

function ClusterDashboardSelect({
    cluster,
    ...rest
}: ClusterDashboardSelectProps) {
    const groupedDashboards = getDashboardsGrouped(
        getDashboardsForCluster(cluster)
    );
    return (
        <Select {...rest}>
            {Object.keys(groupedDashboards).map((k) => (
                <Select.OptGroup label={k} key={k}>
                    {groupedDashboards[k].map((d: MonitorDashboardConfig) => (
                        <Select.Option
                            key={d.meta?.slug}
                            value={d.meta?.slug || ''}
                        >
                            {d.title}
                        </Select.Option>
                    ))}
                </Select.OptGroup>
            ))}
        </Select>
    );
}

/**
 * Retunrs dashboards for clusters
 */
export function getDashboardsForCluster(
    cluster: CcCluster
): MonitorDashboardConfig[] {
    return DASHBOARDS.filter(
        (d) =>
            d.show !== false &&
            (d.meta?.supportClusterTypes.includes('all') ||
                d.meta?.supportClusterTypes.includes(
                    cluster.clusterType.toLowerCase()
                ))
    ).filter((d) => {
        if (d.meta?.slug === 'mysql-replication-slave') {
            // cluster must have a mysql slave node so we can show mysql-replication-slave dashboard
            return cluster.containsNodeTypeRole(
                CcNodeType.MYSQL,
                CcNodeRole.SLAVE
            );
        } else if (d.meta?.slug === 'proxysql-overview') {
            // cluster must have a proxysql node so we can show proxysql-overview dashboard
            return cluster.containsNodeType(CcNodeType.PROXYSQL);
        } else if (d.meta?.slug === 'haproxy-overview') {
            // cluster must have a haproxy node so we can show haproxy-overview dashboard
            return cluster.containsNodeType(CcNodeType.HAPROXY);
        } else if (d.meta?.slug === 'pgbouncer-overview') {
            // cluster must have a pgbouncer node so we can show pgbouncer-overview dashboard
            return cluster.containsNodeType(CcNodeType.PGBOUNCER);
        } else if (d.meta?.slug === 'mongodb-cluster-overview') {
            // cluster must have a mongos node so we can show mongodb-cluster-overview dashboard
            return cluster.containsNodeRole(CcNodeRole.MONGO_MONGOS);
        } else {
            return true;
        }
    });
}

/**
 * Retunrs default dashboard slug for cluster type
 */
function getDefaultDashboardSlug(type: CcClusterType): string | undefined {
    let defaultDashboards: { [key in CcClusterType]?: string } = {};
    defaultDashboards[CcClusterType.TYPE_POSTGRESQL] = 'postgresql-overview';
    defaultDashboards[CcClusterType.TYPE_TIMESCALEDB] = 'postgresql-overview';
    defaultDashboards[CcClusterType.TYPE_GALERA] = 'galera-overview';
    defaultDashboards[CcClusterType.TYPE_MSSQL_SINGLE] = 'mysql-server-general';
    defaultDashboards[CcClusterType.TYPE_MSSQL_AO_ASYNC] =
        'mysql-server-general';
    defaultDashboards[CcClusterType.TYPE_MONGODB] = 'mongodb-replicaset';
    defaultDashboards[CcClusterType.TYPE_REDIS] = 'redis-overview';
    defaultDashboards[CcClusterType.TYPE_MSSQL_SINGLE] = 'mssql-overview';
    return defaultDashboards[type];
}

/**
 * Returns default dashboard object for cluster
 */
export function getDefaultDashboard(
    cluster: CcCluster,
    preferredSlug?: string
) {
    const dashboards = getDashboardsForCluster(cluster);

    // prioritizing preferred slug
    let selectedDashboard = dashboards.find(
        (d) => d.meta?.slug === preferredSlug
    );
    if (!selectedDashboard) {
        // preferred slug not available then we selecte default for cluster
        selectedDashboard = dashboards.find(
            (d) => d.meta?.slug === getDefaultDashboardSlug(cluster.clusterType)
        );
    }
    if (!selectedDashboard) {
        // default not available, then we pick first in the list
        selectedDashboard = dashboards[0];
    }
    return selectedDashboard;
}

export function getDashboardsGrouped(dashboards: MonitorDashboardConfig[]) {
    return groupArrayBy(dashboards, (item) => {
        let result;
        switch (item.meta?.slug) {
            case 'mysql-innodb-metrics':
            case 'mysql-server-general':
            case 'mysql-server-caches':
            case 'mysql-server-userstats':
                result = 'MySQL';
                break;
            case 'mysql-replication-master':
            case 'mysql-replication-slave':
                result = 'Replication';
                break;
            case 'galera-overview':
            case 'galera-server-charts':
                result = 'Galera';
                break;
            case 'postgresql-overview':
                result = 'PostgreSQL';
                break;
            case 'proxysql-overview':
            case 'haproxy-overview':
            case 'pgbouncer-overview':
                result = 'Load Balancers';
                break;
            case 'mongodb-cluster-overview':
            case 'mongodb-replicaset':
            case 'mongodb-server':
                result = 'MongoDB';
                break;
            case 'redis-overview':
                result = 'Redis';
                break;
            case 'mssql-overview':
                result = 'MSSQL';
                break;
            case 'system-overview':
            case 'cluster-overview':
            default:
                result = 'General';
        }
        return result;
    });
}

/**
 * Returns needed exporters array for panel
 */
export function getNeededExportersForPanel(
    panel: MonitorDashboardPanelConfig
): string[] {
    return targetsToExporters(panel.targets);
}

/**
 * Retunrs dashboards panels for dashboard and cluster
 */
export function getDashboardPanelsForCluster(
    dashboard: MonitorDashboardConfig,
    cluster: CcCluster
): MonitorDashboardPanelConfig[] {
    return dashboard.panels.filter((p) => {
        const neededExporters = getNeededExportersForPanel(p);
        if (
            p.datasource === MonitorDatasource.CLUSTERCONTROL ||
            p.datasource === MonitorDatasource.CMONAGENT
        ) {
            return true;
        } else if (cluster.isBase(CcClusterBase.BASE_POSTGRESQL)) {
            // needed exporters includes one of the following exporters
            return (
                arrayIntersection(neededExporters, [
                    CcPrometheusExporterJob.PGBOUNCER,
                    CcPrometheusExporterJob.HAPROXY,
                    CcPrometheusExporterJob.POSTGRES,
                    CcPrometheusExporterJob.NODE,
                ]).length > 0
            );
        } else if (cluster.isType(CcClusterType.TYPE_REDIS)) {
            // needed exporters includes one of the following exporters
            return (
                arrayIntersection(neededExporters, [
                    CcPrometheusExporterJob.REDIS,
                    CcPrometheusExporterJob.NODE,
                ]).length > 0
            );
        } else if (
            cluster.isType(CcClusterType.TYPE_MSSQL_SINGLE) ||
            cluster.isType(CcClusterType.TYPE_MSSQL_AO_ASYNC)
        ) {
            // needed exporters includes one of the following exporters
            return (
                arrayIntersection(neededExporters, [
                    CcPrometheusExporterJob.MSSQL,
                    CcPrometheusExporterJob.NODE,
                ]).length > 0
            );
        } else if (cluster.isType(CcClusterType.TYPE_MONGODB)) {
            // needed exporters includes one of the following exporters
            return (
                arrayIntersection(neededExporters, [
                    CcPrometheusExporterJob.MONGO,
                    CcPrometheusExporterJob.NODE,
                ]).length > 0
            );
        } else if (cluster.isType(CcClusterType.TYPE_ELASTIC)) {
            // needed exporters includes one of the following exporters
            return (
                arrayIntersection(neededExporters, [
                    CcPrometheusExporterJob.NODE,
                ]).length > 0
            );
        } else {
            return true;
        }
    });
}
