
import CcNode, { CcNodeProps } from '../../models/CcNode';


export interface CmonProxySqlHostProps  extends CcNodeProps {
    admin_password?: string;
    admin_user?: string;
    backend_info?: {[key:string]:any};
    backend_version?: {[key:string]:any};
    ccx_svc?: boolean;
    db_database?: string;
    db_password?: string;
    db_privs?: string;
    db_user?: string;
    is_clustered?: boolean;
    lb_policy?: string;
    listening_port?: string;
    monitor_password?: string;
    monitor_user?: string;
    rw_splitting?: {[key:string]:any};

}



export default class CmonProxySqlHost extends CcNode {
    public adminPassword?: string;
    public adminUser?: string;
    public backendInfo?: {[key:string]:any};
    public backendVersion?: {[key:string]:any};
    public ccxSvc?: boolean;
    public dbDatabase?: string;
    public dbPassword?: string;
    public dbPrivs?: string;
    public dbUser?: string;
    public isClustered?: boolean;
    public lbPolicy?: string;
    public listeningPort?: string;
    public monitorPassword?: string;
    public monitorUser?: string;
    public rwSplitting?: {[key:string]:any};


    constructor(props: CmonProxySqlHostProps) {
        super(props);
        this.adminPassword = props.admin_password;
        this.adminUser = props.admin_user;
        this.backendInfo = props.backend_info;
        this.backendVersion = props.backend_version;
        this.ccxSvc = props.ccx_svc;
        this.dbDatabase = props.db_database;
        this.dbPassword = props.db_password;
        this.dbPrivs = props.db_privs;
        this.dbUser = props.db_user;
        this.isClustered = props.is_clustered;
        this.lbPolicy = props.lb_policy;
        this.listeningPort = props.listening_port;
        this.monitorPassword = props.monitor_password;
        this.monitorUser = props.monitor_user;
        this.rwSplitting = props.rw_splitting;

    }

}
