import React from 'react';
import { FormInstance } from 'antd/lib/form';
import SpaceDescriptions from '../../../../common/Layout/SpaceDescriptions';
import AppDivider from '../../../../common/AppDivider';
import { TopologyItem } from '../../../Topology/TopologyItem';

export type CreateKeepalivedPreviewProps = {
    form: FormInstance;
};

export default CreateKeepalivedPreview;

function CreateKeepalivedPreview({ form }: CreateKeepalivedPreviewProps) {
    const { keepalivedConfiguration, keepalivedHost } = form.getFieldsValue(
        true
    );
    return (
        <div>
            <SpaceDescriptions
                direction="vertical"
                title="Configuration"
                size="small"
                alignItems="right"
            >
                <SpaceDescriptions.Item label="Load balancer type" labelStrong>
                    {`${keepalivedConfiguration.type ?? ''}`}
                </SpaceDescriptions.Item>
                {Array.isArray(keepalivedConfiguration.hostname) &&
                    keepalivedConfiguration.hostname.map(
                        (topology: TopologyItem, index: number) => {
                            return (
                                <SpaceDescriptions.Item
                                    key={index}
                                    labelMuted
                                    label={`Keepalived ${index + 1}`}
                                >
                                    {topology?.extraData.hostname ?? ''}
                                </SpaceDescriptions.Item>
                            );
                        }
                    )}
                <SpaceDescriptions.Item label="Firewall" labelStrong>
                    {`${keepalivedConfiguration.firewall ?? ''}`}
                </SpaceDescriptions.Item>
                <SpaceDescriptions.Item label="AppArmor/SELinux" labelStrong>
                    {`${keepalivedConfiguration.appArmor ?? ''}`}
                </SpaceDescriptions.Item>
            </SpaceDescriptions>
            <AppDivider />
            <SpaceDescriptions
                direction="vertical"
                title="Host configuration"
                size="small"
                alignItems="right"
            >
                <SpaceDescriptions.Item label="Virtual IP">
                    {`${keepalivedHost.virtualIP ?? ''}`}
                </SpaceDescriptions.Item>
                <SpaceDescriptions.Item label="Network interface">
                    {`${keepalivedHost.networkInterface ?? ''}`}
                </SpaceDescriptions.Item>
            </SpaceDescriptions>
        </div>
    );
}
