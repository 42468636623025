import React, { useContext } from 'react';
import { ResponsiveContext } from '@severalnines/bar-frontend-components/build/lib/Layout/Responsive';
import AppEmpty from '../../../common/Feedback/AppEmpty';
import ClusterConfigValueInput from './ClusterConfigValueInput';
import { ClusterConfigValue } from '../useClusterConfig';
import { Space } from 'antd';
import InfoIcon from '@severalnines/bar-frontend-components/build/lib/General/InfoIcon';
import TypographyText from '../../../common/TypographyText';
import SpaceWide from '../../../common/SpaceWide';
import { ThresholdItem } from './ClusterConfigContext';
import { getSortAlphabeticFn } from '../../../common/sorting';
import useClusterConfigThresholdList from './useClusterConfigThresholdList';
import AppTable from '../../../common/DataDisplay/AppTable';
import AppWordHighlighter from '../../../common/Text/AppWordHighlighter';
import { ConfigContext } from './ConfigContext';

export default ClusterConfigThresholdsTable;
export type ClusterConfigThresholdsTableProps = {  };

function ClusterConfigThresholdsTable({
    ...rest
}: ClusterConfigThresholdsTableProps) {
    const { responsive } = useContext(ResponsiveContext);

    const {searchString, changeLoading} = useContext(ConfigContext);
    const {
        filter,
        items,
        configGroupedLoading,
    } = useClusterConfigThresholdList();


    const handleTableChange = (
        pagination: any,
        currentFilter: any,
        sorters: any
    ) => {
        let sorterFn: any = null;
        if (sorters.order) {
            switch (sorters.columnKey) {
                case 'parameter':
                    sorterFn = getSortAlphabeticFn(
                        sorters.order,
                        (x: ClusterConfigValue) => x.name
                    );
                    break;
            }
        }
        filter({ order: sorterFn });
    };

    const columns = [
        {
            title: 'Parameter',
            key: 'parameter',
            sorter: true,
            defaultSortOrder: 'ascend',
            sortDirections: ['ascend', 'descend', 'ascend'],
            render: (record: ThresholdItem) => (
                <Space>
                    <AppWordHighlighter
                        searchWords={[searchString || '']}
                        textToHighlight={record.name}
                    />
                    <InfoIcon
                        info={
                            <Space direction="vertical">
                                <span>
                                    <b>Warning:</b>{' '}
                                    <AppWordHighlighter
                                        searchWords={[searchString || '']}
                                        textToHighlight={
                                            record.warning?.description || ''
                                        }
                                    />
                                </span>
                                <span>
                                    <b>Critical:</b>{' '}
                                    <AppWordHighlighter
                                        searchWords={[searchString || '']}
                                        textToHighlight={
                                            record.critical?.description || ''
                                        }
                                    />
                                </span>
                            </Space>
                        }
                    />
                </Space>
            ),
        },
        {
            title: (
                <Space>
                    <span>Warning level</span>
                    <InfoIcon
                        info={
                            <span>
                                Sets your warning threshold in percentage for a
                                specific event.
                            </span>
                        }
                    />
                </Space>
            ),
            key: 'warning',
            width: 200,
            render: (record: ThresholdItem) =>
                record.warning && (
                    <ClusterConfigValueInput
                        value={record.warning}
                    />
                ),
        },
        {
            title: (
                <Space>
                    <span>Critical level</span>
                    <InfoIcon
                        info={
                            <span>
                                Sets your critical threshold in percentage for a
                                specific event.
                            </span>
                        }
                    />
                </Space>
            ),
            key: 'critical',
            width: 200,
            render: (record: ThresholdItem) =>
                record.critical && (
                    <ClusterConfigValueInput
                        value={record.critical}
                    />
                ),
        },
    ];

    return (
        <SpaceWide direction="vertical">
            <Space direction="vertical">
                <TypographyText strong={true}>
                    Thresholds for warnings and notifications
                </TypographyText>
                <TypographyText>
                    Thresholds' specifies the threshold level at which an alarm
                    will be triggered
                </TypographyText>
            </Space>
            <AppTable
                rowKey={(record: ThresholdItem) => record.name}
                loading={configGroupedLoading && !changeLoading}
                dataSource={items}
                columns={columns}
                responsive={responsive}
                pagination={false}
                onRow={() => ({})}
                onChange={handleTableChange}
                renderEmpty={
                    <AppEmpty
                        loading={configGroupedLoading && !changeLoading}
                        description="You haven’t received alarms yet. When you do, it'll show up here."
                    />
                }
            />
        </SpaceWide>
    );
}
