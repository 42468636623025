import React from 'react';
import CcCluster from '../../../services/models/CcCluster';
import AlarmsTable from '../../Alarms/AlarmsTable';
import AppPageWrapper from '../../../common/Layout/AppPageWrapper';

export default ClusterAlarms;

export type ClusterAlarmsProps = { cluster: CcCluster };

function ClusterAlarms({ cluster }: ClusterAlarmsProps) {
    return (
        <AppPageWrapper
            className="ClusterAlarms"
            transparentBackground={true}
            noPadding={true}
        >
            <AlarmsTable clusterId={cluster.clusterId} />
        </AppPageWrapper>
    );
}
