import React, { useRef } from 'react';
import { Column, ColumnConfig, Plot, PlotEvent } from '@ant-design/charts';
import { Formatter } from 'antd/es/statistic/utils';

export default AppColumnChart;

export type AppColumnChartDataPoint<T> = {
    category: string;
    type: string;
    value: number;
    extra?: T;
};
export type AppColumnChartProps<T> = Omit<ColumnConfig, 'xField' | 'yField'> & {
    data: AppColumnChartDataPoint<T>[];
    valueFormatter?: Formatter;
    onClick?: (data: any) => void;
};

function AppColumnChart({
    data,
    legend,
    valueFormatter,
    onClick,
    ...rest
}: AppColumnChartProps<any>) {
    const plotRef = useRef<Plot<ColumnConfig>>();

    const handleClick = (evt: PlotEvent) => {
        const { x, y } = evt;
        const tooltipData = plotRef.current?.chart.getTooltipItems({ x, y });
        onClick?.(tooltipData);
    };

    const handleReady = (plot: Plot<ColumnConfig>) => {
        plotRef.current = plot;
        plotRef.current.on('plot:click', handleClick);
    };
    return (
        <Column
            xField="category"
            yField="value"
            seriesField="type"
            data={data}
            isStack={true}
            height={200}
            legend={{
                layout: 'horizontal',
                position: 'top-left',
                ...legend,
            }}
            animation={false}
            meta={{
                value: {
                    formatter: valueFormatter || ((v) => `${v}`),
                },
            }}
            theme={getChartTheme('s9s')}
            onReady={handleReady}
            {...rest}
        />
    );
}

/**
 * Reuse this function in monitor dashboards
 */
export function getChartTheme(theme?: string) {
    switch (theme) {
        case 's9s':
            return {
                colors10: [
                    '#FF99C3',
                    '#753FA7',
                    '#5D7092',
                    '#F6BD16',
                    '#6F5EF9',
                    '#6DC8EC',
                    '#945FB9',
                    '#FF9845',
                    '#1E9493',
                    '#FF99C3',
                ],
                colors20: [
                    '#B89CD2',
                    '#753FA7',
                    '#5AD8A6',
                    '#CDF3E4',
                    '#5D7092',
                    '#CED4DE',
                    '#F6BD16',
                    '#FCEBB9',
                    '#6F5EF9',
                    '#D3CEFD',
                    '#6DC8EC',
                    '#D3EEF9',
                    '#945FB9',
                    '#DECFEA',
                    '#FF9845',
                    '#FFE0C7',
                    '#1E9493',
                    '#BBDEDE',
                    '#FF99C3',
                    '#FFE0ED',
                ],
            };
        default:
            return {
                colors10: [
                    '#5B8FF9',
                    '#5AD8A6',
                    '#5D7092',
                    '#F6BD16',
                    '#6F5EF9',
                    '#6DC8EC',
                    '#945FB9',
                    '#FF9845',
                    '#1E9493',
                    '#FF99C3',
                ],
                colors20: [
                    '#5B8FF9',
                    '#CDDDFD',
                    '#5AD8A6',
                    '#CDF3E4',
                    '#5D7092',
                    '#CED4DE',
                    '#F6BD16',
                    '#FCEBB9',
                    '#6F5EF9',
                    '#D3CEFD',
                    '#6DC8EC',
                    '#D3EEF9',
                    '#945FB9',
                    '#DECFEA',
                    '#FF9845',
                    '#FFE0C7',
                    '#1E9493',
                    '#BBDEDE',
                    '#FF99C3',
                    '#FFE0ED',
                ],
            };
    }
}
