import React, { useRef, useState } from 'react';
import ModalDefault, { ModalDefaultProps } from '../../../common/ModalDefault';
import BackupWizard, {
    BackupWizardStep,
    BackupWizardStepActionType,
    getBackupWizardStepTitle,
} from './BackupWizard';
import { notifyConfirmCloseWindow } from '../../Notifications/uiNotification';
import MotionShake, { MotionApi } from '../../../common/motion/MotionShake';
import CcBackupSchedule from '../../../services/models/CcBackupSchedule';
import CcCluster from '../../../services/models/CcCluster';

export interface BackupWizardModalApi {
    show: () => void;
}
export type BackupWizardModalProps = {
    onSuccess?: () => void;
    onCancel?: () => void;
    activeStep?: BackupWizardStep;
    schedule?: CcBackupSchedule;
    cluster?: CcCluster;
    title?: React.ReactNode;
    wizardAction?: BackupWizardStepActionType;
} & Omit<ModalDefaultProps, 'children'>;
const BackupWizardModal = ({
    onSuccess,
    onCancel,
    activeStep,
    wizardAction,
    schedule,
    cluster,
    title,
    ...rest
}: BackupWizardModalProps) => {
    const [modalTitle, setModalTitle] = useState<React.ReactNode>(title);
    const [confirmClose, setConfirmClose] = useState<boolean>(false);
    const animationRef = useRef<MotionApi | undefined>();

    const stepChangeHandler = (step: BackupWizardStep) => {
        setModalTitle(title || getBackupWizardStepTitle(step));
    };
    const handleTouchChanged = (touched: boolean) => {
        setConfirmClose(touched);
    };
    const handleModalCancel = () => {
        if (confirmClose) {
            notifyConfirmCloseWindow({
                onOk: () => {
                    onCancel?.();
                },
            });
        } else {
            onCancel?.();
        }
    };
    const handleFormStepErrorInsist = (err: Error) => {
        if (animationRef.current) {
            animationRef.current.animate();
        }
    };

    return (
        <ModalDefault
            title={modalTitle}
            animateHeight={true}
            visible={true}
            onCancel={handleModalCancel}
            modalRender={(content) => (
                <MotionShake ref={animationRef}>{content}</MotionShake>
            )}
            {...rest}
        >
            <BackupWizard
                activeStep={activeStep}
                wizardAction={wizardAction}
                onSuccess={onSuccess}
                onChange={stepChangeHandler}
                onFormTouchedChange={handleTouchChanged}
                onFormStepErrorInsist={handleFormStepErrorInsist}
                cluster={cluster}
                backupSchedule={schedule}
            />
        </ModalDefault>
    );
};
export default BackupWizardModal;
