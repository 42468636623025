
import CcNode, { CcNodeProps } from '../../models/CcNode';


export interface CmonKeepalivedHostProps  extends CcNodeProps {
    backend_info?: {[key:string]:any};
    haproxy_address1?: string;
    haproxy_address2?: string;
    haproxy_name1?: string;
    haproxy_name2?: string;
    name?: string;
    nic?: string;
    virtualip?: string;

}



export default class CmonKeepalivedHost extends CcNode {
    public backendInfo?: {[key:string]:any};
    public haproxyAddress1?: string;
    public haproxyAddress2?: string;
    public haproxyName1?: string;
    public haproxyName2?: string;
    public name?: string;
    public nic?: string;
    public virtualip?: string;


    constructor(props: CmonKeepalivedHostProps) {
        super(props);
        this.backendInfo = props.backend_info;
        this.haproxyAddress1 = props.haproxy_address1;
        this.haproxyAddress2 = props.haproxy_address2;
        this.haproxyName1 = props.haproxy_name1;
        this.haproxyName2 = props.haproxy_name2;
        this.name = props.name;
        this.nic = props.nic;
        this.virtualip = props.virtualip;

    }

}
