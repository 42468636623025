import { CmonJobInstanceCommand } from '../../../services/cmon/models/CmonJobInstance';
import TypographyText from '../../../common/TypographyText';
import React, { useState } from 'react';
import CcCluster from '../../../services/models/CcCluster';
import { FormInstance } from 'antd/lib/form';
import BackupToolPgBackrestSetupForm from './BackupToolPgBackrestSetupForm';
import useBackRestSetup from './useBackRestSetup';
import CcJob from '../../../services/models/CcJob';
import BackupToolJobProgress from './BackupToolJobProgress';
import BackupToolPromoCard from './BackupToolPromoCard';
import { useDebugContext } from '../../../common/Debug';
import AppSpin from '../../../common/General/AppSpin';

export default BackupToolPgBackrestSetup;

type BackupToolPgBackrestSetupProps = {
    cluster: CcCluster;
    form: FormInstance;
    onFormShow?: () => void;

    onFinish?: (job: CcJob) => void;
    onReset?: () => void;
    onCancel?: () => void;
    onJobStart?: () => void;
};

function BackupToolPgBackrestSetup({
    cluster,
    form,
    onFormShow,
    onFinish,
    onReset,
    onCancel,
    onJobStart,
}: BackupToolPgBackrestSetupProps) {
    const { log } = useDebugContext();
    const [showForm, setShowForm] = useState<boolean>(false);

    const { loading, setup } = useBackRestSetup({
        cluster,
        form,
        onSuccess: onJobStart,
    });

    const handleCancelClick = () => {
        setShowForm(false);
        onCancel?.();
    };

    const handleContinueClick = () => {
        setShowForm(true);
        onFormShow?.();
    };

    const handleInstallClick = async () => {
        try {
            await form.validateFields();
            await setup();
        } catch (e) {
            log.error(e);
        }
    };
    return (
        <AppSpin spinning={loading}>
            <BackupToolJobProgress
                command={CmonJobInstanceCommand.PGBACKREST}
                cluster={cluster}
                showReset={true}
                onFinish={onFinish}
                onReset={onReset}
                jobId={form.getFieldValue('__backupToolJobId__')}
                fallback={
                    showForm ? (
                        <BackupToolPromoCard
                            title={
                                <TypographyText>
                                    Install new backup tool
                                </TypographyText>
                            }
                            onInstallClick={handleInstallClick}
                            onCancelClick={handleCancelClick}
                        >
                            <BackupToolPgBackrestSetupForm
                                cluster={cluster}
                                form={form}
                            />
                        </BackupToolPromoCard>
                    ) : (
                        <BackupToolPromoCard
                            intoCommand={CmonJobInstanceCommand.PGBACKREST}
                            title={
                                <TypographyText primary={true}>
                                    Install new backup tool
                                </TypographyText>
                            }
                            onContinueClick={handleContinueClick}
                            iconType={'install'}
                            bg={'circles-bottom-right'}
                        />
                    )
                }
            />
        </AppSpin>
    );
}
