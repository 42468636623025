import WizardConfiguration from '@severalnines/bar-frontend-components/build/lib/Navigation/Wizard/WizardConfiguration';
import React, { useEffect, useMemo, useState } from 'react';
import WizardSelectCardStep from '@severalnines/bar-frontend-components/build/lib/Navigation/Wizard/WizardSelectCardStep';
import BackupWizardForm from './BackupWizardForm';
import CcCluster, { CcClusterType } from '../../../services/models/CcCluster';
import CcBackupSchedule from '../../../services/models/CcBackupSchedule';
import { DatabaseOutlined } from '@ant-design/icons';
import ElasticSnapshotRepositoryFormWizard from '../Repository/ElasticSnapshotRepositoryFormWizard';
import { Space } from 'antd';
import useClusterList from '../../Clusters/useClusterList';
import TypographyText from '../../../common/TypographyText';
import { ClusterConfigProvider } from '../../Clusters/Config/ClusterConfigContext';
import { FormInstance } from 'antd/lib/form';

export enum BackupWizardStepActionType {
    DEMAND = 'demand',
    SCHEDULE = 'schedule',
    REPOSITORY = 'repository',
}

export enum BackupWizardStep {
    TYPE = 'type',
    FORM_BACKUP = 'backup',
    FORM_CREATE_SCHEDULE = 'create_schedule',
    FORM_UPDATE_SCHEDULE = 'update_schedule',
    FORM_CREATE_REPOSITORY = 'create_repository',
}

export type BackupWizardProps = {
    activeStep?: BackupWizardStep;
    wizardAction?: BackupWizardStepActionType;
    onBackupCreateSubmit?: (form: FormInstance) => void;
    onScheduleCreateSubmit?: (form: FormInstance) => void;
    onScheduleUpdateSubmit?: (form: FormInstance) => void;
    onFormTouchedChange?: (touched: boolean) => void;
    onFormStepErrorInsist?: (err: Error) => void;
    onChange?: (step: BackupWizardStep) => void;
    cluster?: CcCluster;
    backupSchedule?: CcBackupSchedule;
    onSuccess?: () => void;
};
export default function BackupWizard({
    activeStep = BackupWizardStep.TYPE,
    wizardAction,
    onFormTouchedChange,
    onFormStepErrorInsist,
    onChange,
    cluster,
    backupSchedule,
    onSuccess,
}: BackupWizardProps) {
    const [step, setStep] = useState<BackupWizardStep>(activeStep);
    const [action, setAction] = useState<
        BackupWizardStepActionType | undefined
    >(wizardAction);
    const { hasClusterType } = useClusterList({ fromStore: true });
    const typeSelectHandler = (value: string) => {
        setAction(value as BackupWizardStepActionType);
    };

    useEffect(() => {
        if (action && step === BackupWizardStep.TYPE) {
            const newStep = getActionWizardStep(action);
            if (newStep) {
                setStep(newStep);
            }
        }
    }, [action]);

    useEffect(() => {
        if (step === BackupWizardStep.TYPE) {
            setAction(undefined);
        }

        if (onChange) {
            onChange(step);
        }
    }, [step]);

    const handleFormCancel = () => {
        setStep(BackupWizardStep.TYPE);
    };

    const elasticInstalled = hasClusterType(CcClusterType.TYPE_ELASTIC);
    const stepItems = useMemo(() => {
        const items = [
            <WizardSelectCardStep.Item
                key={BackupWizardStepActionType.DEMAND}
                icon={
                    <img
                        src={require('./img-backup-wizard-demand-icon.svg')}
                        alt={getCreateBackupActionTypeTitle(
                            BackupWizardStepActionType.DEMAND
                        )}
                    />
                }
                title={getCreateBackupActionTypeTitle(
                    BackupWizardStepActionType.DEMAND
                )}
                action={BackupWizardStepActionType.DEMAND}
                description={getCreateBackupActionTypeDescription(
                    BackupWizardStepActionType.DEMAND
                )}
                buttonTitle="Create"
            />,
            <WizardSelectCardStep.Item
                key={BackupWizardStepActionType.SCHEDULE}
                icon={
                    <img
                        src={require('./img-backup-wizard-schedule-icon.svg')}
                        alt={getCreateBackupActionTypeTitle(
                            BackupWizardStepActionType.SCHEDULE
                        )}
                    />
                }
                title={getCreateBackupActionTypeTitle(
                    BackupWizardStepActionType.SCHEDULE
                )}
                action={BackupWizardStepActionType.SCHEDULE}
                description={getCreateBackupActionTypeDescription(
                    BackupWizardStepActionType.SCHEDULE
                )}
                buttonTitle="Schedule"
            />,
        ];
        if (elasticInstalled) {
            items.push(
                <WizardSelectCardStep.Item
                    key={BackupWizardStepActionType.REPOSITORY}
                    icon={<DatabaseOutlined />}
                    title={getCreateBackupActionTypeTitle(
                        BackupWizardStepActionType.REPOSITORY
                    )}
                    action={BackupWizardStepActionType.REPOSITORY}
                    description={getCreateBackupActionTypeDescription(
                        BackupWizardStepActionType.REPOSITORY
                    )}
                    buttonTitle="Create"
                />
            );
        }

        return items;
    }, [elasticInstalled]);

    return (
        <ClusterConfigProvider>
            <WizardConfiguration
                activeStep={step}
                steps={[
                    {
                        step: BackupWizardStep.TYPE,
                        title: ' ',
                        content: (
                            <WizardSelectCardStep
                                title="Create backup"
                                description="Create backups on-demand or schedule backups to run at specific times."
                                onSelect={typeSelectHandler}
                            >
                                {stepItems}
                            </WizardSelectCardStep>
                        ),
                    },
                    {
                        step: BackupWizardStep.FORM_BACKUP,
                        title: 'Create a Backup',
                        content: (
                            <BackupWizardForm
                                onSuccess={onSuccess}
                                onCancel={handleFormCancel}
                                cluster={cluster}
                                onTouchedChange={onFormTouchedChange}
                                onStepErrorInsist={onFormStepErrorInsist}
                            />
                        ),
                    },
                    {
                        step: BackupWizardStep.FORM_CREATE_SCHEDULE,
                        title: 'Create a Backup Schedule',
                        content: (
                            <BackupWizardForm
                                onSuccess={onSuccess}
                                onCancel={handleFormCancel}
                                cluster={cluster}
                                onTouchedChange={onFormTouchedChange}
                                onStepErrorInsist={onFormStepErrorInsist}
                                isSchedule={true}
                            />
                        ),
                    },
                    {
                        step: BackupWizardStep.FORM_UPDATE_SCHEDULE,
                        title: 'Update a Backup Schedule',
                        content: (
                            <BackupWizardForm
                                onSuccess={onSuccess}
                                onCancel={handleFormCancel}
                                onTouchedChange={onFormTouchedChange}
                                onStepErrorInsist={onFormStepErrorInsist}
                                schedule={backupSchedule}
                                cluster={cluster}
                                saveMode={true}
                                isSchedule={true}
                            />
                        ),
                    },
                    {
                        step: BackupWizardStep.FORM_CREATE_REPOSITORY,
                        title: 'Create an Elastic repository',
                        content: (
                            <ElasticSnapshotRepositoryFormWizard
                                cluster={cluster}
                                onSuccess={onSuccess}
                                onCancel={handleFormCancel}
                                onTouchedChange={onFormTouchedChange}
                                onStepErrorInsist={onFormStepErrorInsist}
                            />
                        ),
                    },
                ]}
            />
        </ClusterConfigProvider>
    );
}

export function getCreateBackupActionTypeTitle(
    type: BackupWizardStepActionType
) {
    switch (type) {
        case BackupWizardStepActionType.DEMAND:
            return 'Backup on Demand';
        case BackupWizardStepActionType.SCHEDULE:
            return 'Schedule a Backup';
        case BackupWizardStepActionType.REPOSITORY:
            return 'Elastic snapshot repository';
        default:
            return '';
    }
}

export function getBackupWizardStepTitle(step: BackupWizardStep) {
    switch (step) {
        case BackupWizardStep.TYPE:
            return '';
        case BackupWizardStep.FORM_BACKUP:
            return 'Create a Backup';
        case BackupWizardStep.FORM_CREATE_SCHEDULE:
            return 'Create a Backup Schedule';
        case BackupWizardStep.FORM_UPDATE_SCHEDULE:
            return 'Update Backup Schedule';
        case BackupWizardStep.FORM_CREATE_REPOSITORY:
            return 'Create Elastic repository';
    }
}

function getCreateBackupActionTypeDescription(
    type: BackupWizardStepActionType
) {
    switch (type) {
        case BackupWizardStepActionType.DEMAND:
            return (
                <ul>
                    <li>Create a single backup instantly</li>
                    <li>Store backups on premise or in the cloud</li>
                    <li>Compress and encrypt backups for secure storage</li>
                </ul>
            );
        case BackupWizardStepActionType.SCHEDULE:
            return (
                <ul>
                    <li>Define a schedule to periodically create backups</li>
                    <li>Store backups on premise or in the cloud</li>
                    <li>Compress and encrypt backups for secure storage</li>
                    <li>Automate backup verification</li>
                </ul>
            );
        case BackupWizardStepActionType.REPOSITORY:
            return (
                <Space direction="vertical" size={5}>
                    <span>
                        A snapshot is a backup of a running Elasticsearch
                        cluster. Elasticsearch stores snapshots in an
                        off-cluster storage location called a snapshot
                        repository. Before you can take or restore snapshots,
                        you must register a snapshot repository on the cluster.
                    </span>
                    <span>
                        ClusterControl currently supports these repository
                        types:{' '}
                    </span>
                    <ul>
                        <li>
                            <TypographyText strong>
                                Local storage, AWS S3
                            </TypographyText>
                        </li>
                    </ul>
                </Space>
            );
    }
}

function getActionWizardStep(action: BackupWizardStepActionType) {
    switch (action) {
        case BackupWizardStepActionType.DEMAND:
            return BackupWizardStep.FORM_BACKUP;
        case BackupWizardStepActionType.SCHEDULE:
            return BackupWizardStep.FORM_CREATE_SCHEDULE;
        case BackupWizardStepActionType.REPOSITORY:
            return BackupWizardStep.FORM_CREATE_REPOSITORY;
    }
}
