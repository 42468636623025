const result = {
    configuration: {
        values: [
            {
                name: 'backup_retention',
                current_value: 31,
                default_value: 31,
                description:
                    'Setting of how many days to keep the backups. Backups matching retention period are removed.',
                keys: ['backup_retention'],
                sql_keys: ['BACKUP_RETENTION'],
            },
            {
                name: 'backup_cloud_retention',
                current_value: 180,
                default_value: 180,
                description:
                    'Setting of how many days to keep the backups uploaded to a cloud. Backups matching retention period are removed.',
                keys: ['backup_cloud_retention'],
                sql_keys: ['BACKUP_CLOUD_RETENTION'],
            },
            {
                name: 'backupdir',
                current_value: '/root/backups',
                default_value: null,
                description:
                    'The default backup directory, to be pre-filled in Frontend.',
                keys: ['backupdir'],
                sql_keys: ['BACKUPDIR'],
            },

            {
                name: 'backup_subdir',
                current_value: 'BACKUP-%I',
                default_value: null,
                description:
                    'Set the name of the backup subdirectory. This string may hold standard "%X" field separators, the "%06I" for example will be replaced by the numerical ID of the backup in 6 field wide format that uses \'0\' as leading fill characters.\nDefault value: "BACKUP-%I"\nHere is the list of fields the backend currently supports:\n- B The date and time when the backup creation was beginning.\n- H The name of the backup host, the host that created the backup.\n- i The numerical ID of the cluster.\n- I The numerical ID of the backup.\n- J The numerical ID of the job that created the backup.\n- M The backup method (e.g. "mysqldump").\n- O The name of the user who initiated the backup job.\n- S The name of the storage host, the host that stores the backup files.\n- % The percent sign itself. Use two percent signs, "%%" the same way the standard printf() function interprets it as one percent sign.',
                keys: ['backup_subdir'],
                sql_keys: ['BACKUP_SUBDIR'],
            },

            {
                name: 'netcat_port',
                current_value: '9999,9990-9998',
                default_value: '9999,9990-9998',
                description:
                    "List of netcat ports and port ranges used to stream backups. Defaults to '9999,9990-9998' and port 9999 will be preferred if available.",
                keys: ['netcat_port'],
                sql_keys: ['NETCAT_PORT'],
            },
            {
                name: 'pitr_retention_hours',
                current_value: 0,
                default_value: 0,
                description:
                    'Retention hours (to erase old WAL archive logs) for PITR.',
                keys: ['pitr_retention_hours', 'wal_retention_hours'],
                sql_keys: ['PITR_RETENTION_HOURS'],
            },
            {
                name: 'backup_create_hash',
                current_value: 'true',
                default_value: true,
                description:
                    'Configures cmon if it has to calculate md5hash on the created backup files and verify them.',
                keys: ['backup_create_hash'],
                sql_keys: ['BACKUP_CREATE_HASH'],
            },
        ],
    },
};
export default result;
