


export interface CmonJobInstanceProps  {
    can_be_aborted?: boolean;
    can_be_deleted?: boolean;
    class_name?: string;
    cluster_id?: number;
    created?: string;
    ended?: string;
    exit_code?: number;
    group_id?: number;
    group_name?: string;
    has_progress?: boolean;
    ip_address?: string;
    job_id?: number;
    job_spec?: string;
    last_executed?: string;
    max_retry_count?: number;
    progress_percent?: number;
    recurrence?: string;
    retry_count?: number;
    retry_delay?: string;
    rpc_version?: string;
    scheduled?: string;
    started?: string;
    status?: string;
    status_text?: string;
    tags?: string;
    title?: string;
    user_id?: number;
    user_name?: string;

}



export enum CmonJobInstanceCommand {
    START = 'START',
	STARTINITIAL = 'STARTINITIAL',
	STOP = 'STOP',
	RESTART = 'RESTART',
	RELOAD = 'RELOAD',
	REBOOT = 'REBOOT',
	START_CLUSTER = 'START_CLUSTER',
	STOP_CLUSTER = 'STOP_CLUSTER',
	BOOTSTRAP_CLUSTER = 'BOOTSTRAP_CLUSTER',
	FIND_MOST_ADVANCED = 'FIND_MOST_ADVANCED',
	ADDNODE = 'ADDNODE',
	ADD_SHARD = 'ADD_SHARD',
	REMOVE_SHARD = 'REMOVE_SHARD',
	REPLSET_STEP_DOWN = 'REPLSET_STEP_DOWN',
	REPLSET_FREEZE = 'REPLSET_FREEZE',
	ADD_REPLICATION_SLAVE = 'ADD_REPLICATION_SLAVE',
	STAGE_REPLICATION_SLAVE = 'STAGE_REPLICATION_SLAVE',
	FAILOVER_REPLICATION_SLAVE = 'FAILOVER_REPLICATION_SLAVE',
	STOP_REPLICATION_SLAVE = 'STOP_REPLICATION_SLAVE',
	START_REPLICATION_SLAVE = 'START_REPLICATION_SLAVE',
	PROMOTE_REPLICATION_SLAVE = 'PROMOTE_REPLICATION_SLAVE',
	RESET_REPLICATION_SLAVE = 'RESET_REPLICATION_SLAVE',
	DEMOTE_NODE = 'DEMOTE_NODE',
	DELAY_REPLICATION_SLAVE = 'DELAY_REPLICATION_SLAVE',
	TOGGLE_REPLICATION_SYNC = 'TOGGLE_REPLICATION_SYNC',
	MAKE_REPLICATION_MASTER = 'MAKE_REPLICATION_MASTER',
	DISABLE_BINARY_LOGGING = 'DISABLE_BINARY_LOGGING',
	ENABLE_BINARY_LOGGING = 'ENABLE_BINARY_LOGGING',
	ENABLE_LOG_ARCHIVING = 'ENABLE_LOG_ARCHIVING',
	DISABLE_LOG_ARCHIVING = 'DISABLE_LOG_ARCHIVING',
	ENABLE_CLUSTER_READONLY = 'ENABLE_CLUSTER_READONLY',
	DISABLE_CLUSTER_READONLY = 'DISABLE_CLUSTER_READONLY',
	ENABLE_DB_READONLY = 'ENABLE_DB_READONLY',
	DISABLE_DB_READONLY = 'DISABLE_DB_READONLY',
	ENCRYPT_REPLICATION = 'ENCRYPT_REPLICATION',
	SETUP_SSL = 'SETUP_SSL',
	REMOVENODE = 'REMOVENODE',
	GARBD = 'GARBD',
	HAPROXY = 'HAPROXY',
	MAXSCALE = 'MAXSCALE',
	PROXYSQL = 'PROXYSQL',
	KEEPALIVED = 'KEEPALIVED',
	PGBOUNCER = 'PGBOUNCER',
	PGBACKREST = 'PGBACKREST',
	PBMAGENT = 'PBMAGENT',
	NFSCLIENT = 'NFSCLIENT',
	NFSSERVER = 'NFSSERVER',
	REGISTERNODE = 'REGISTERNODE',
	IMPORT_CONFIG = 'IMPORT_CONFIG',
	EXPORT_CONFIG = 'EXPORT_CONFIG',
	MODIFY_DBCONFIG = 'MODIFY_DBCONFIG',
	UPGRADE_CLUSTER = 'UPGRADE_CLUSTER',
	STOPSTART_CLUSTER = 'STOPSTART_CLUSTER',
	ROLLING_RESTART = 'ROLLING_RESTART',
	SAVE_CLUSTER = 'SAVE_CLUSTER',
	RESTORE_CLUSTER = 'RESTORE_CLUSTER',
	SAVE_CONTROLLER = 'SAVE_CONTROLLER',
	RESTORE_CONTROLLER = 'RESTORE_CONTROLLER',
	BACKUP = 'BACKUP',
	DELETE_BACKUP = 'DELETE_BACKUP',
	RUN = 'RUN',
	CLONE_CLUSTER = 'CLONE_CLUSTER',
	EMAIL = 'EMAIL',
	REPORT = 'REPORT',
	RESTORE_BACKUP = 'RESTORE_BACKUP',
	VERIFY_BACKUP = 'VERIFY_BACKUP',
	DELETE_OLD_BACKUPS = 'DELETE_OLD_BACKUPS',
	DELETE_ALL_BACKUPS = 'DELETE_ALL_BACKUPS',
	UPLOAD_BACKUP_DATA_TO_CLOUD_STORAGE = 'UPLOAD_BACKUP_DATA_TO_CLOUD_STORAGE',
	DOWNLOAD_BACKUP_DATA_FROM_CLOUD_STORAGE = 'DOWNLOAD_BACKUP_DATA_FROM_CLOUD_STORAGE',
	CLOUD_BACKUP_UPLOAD = 'CLOUD_BACKUP_UPLOAD',
	CLOUD_BACKUP_DOWNLOAD = 'CLOUD_BACKUP_DOWNLOAD',
	DEPLOY_AGENTS = 'DEPLOY_AGENTS',
	CONFIGURE_AGENTS = 'CONFIGURE_AGENTS',
	FAILOVER = 'FAILOVER',
	ADD_CLUSTER = 'ADD_CLUSTER',
	REMOVE_CLUSTER = 'REMOVE_CLUSTER',
	SETUP_SERVER = 'SETUP_SERVER',
	CREATE_CLUSTER = 'CREATE_CLUSTER',
	CONVERT_TO_SHARDED_CLUSTER = 'CONVERT_TO_SHARDED_CLUSTER',
	DO_CHECK = 'DO_CHECK',
	COLLECT_LOGS = 'COLLECT_LOGS',
	CREATE_LOCAL_REPOSITORY = 'CREATE_LOCAL_REPOSITORY',
	UPDATE_LOCAL_REPOSITORY = 'UPDATE_LOCAL_REPOSITORY',
	MIGRATE_DATA = 'MIGRATE_DATA',
	IMPORT_CERTIFICATES = 'IMPORT_CERTIFICATES',
	MIGRATE_BACKUPS = 'MIGRATE_BACKUPS',
	MIGRATE_BACKUPSCHEDULES = 'MIGRATE_BACKUPSCHEDULES',
	ERROR_REPORT = 'ERROR_REPORT',
	FAIL = 'FAIL',
	SUCCESS = 'SUCCESS',
	CREATE_CONTAINER_SERVER = 'CREATE_CONTAINER_SERVER',
	CREATE_CONTAINER = 'CREATE_CONTAINER',
	CREATE_CONTAINERS = 'CREATE_CONTAINERS',
	STOP_CONTAINER = 'STOP_CONTAINER',
	START_CONTAINER = 'START_CONTAINER',
	DELETE_CONTAINER = 'DELETE_CONTAINER',
	PITR = 'PITR',
	SETUP_AUDIT_LOGGING = 'SETUP_AUDIT_LOGGING',
	SETUP_TDE = 'SETUP_TDE',
	SETUP_EXTENSION = 'SETUP_EXTENSION',
	CALCULATE_DB_GROWTH = 'CALCULATE_DB_GROWTH',
	CREATE_REPLICATION_LINK = 'CREATE_REPLICATION_LINK',
	DESTROY_REPLICATION_LINK = 'DESTROY_REPLICATION_LINK',
	CMON_HA_CREATE_SNAPSHOT = 'CMON_HA_CREATE_SNAPSHOT',
	INSPECT_HOST = 'INSPECT_HOST',
	MANAGE_PROCESSES = 'MANAGE_PROCESSES',
	PURGE_OLD_DATA = 'PURGE_OLD_DATA',
	PURGE_QUERY_MONITOR = 'PURGE_QUERY_MONITOR',
	ROTATE_LOGS = 'ROTATE_LOGS',
	CREATE_MAINTENANCE = 'CREATE_MAINTENANCE',
	DISABLE_RECOVERY = 'DISABLE_RECOVERY',
	ENABLE_RECOVERY = 'ENABLE_RECOVERY',
	DISABLE_NODE_RECOVERY = 'DISABLE_NODE_RECOVERY',
	ENABLE_NODE_RECOVERY = 'ENABLE_NODE_RECOVERY',
	CLUSTER_RECOVERY = 'CLUSTER_RECOVERY',
	CHECK_PKG_UPGRADES = 'CHECK_PKG_UPGRADES',
	SYNC_CLUSTERS = 'SYNC_CLUSTERS',
	DEPLOY_CMONAGENTS = 'DEPLOY_CMONAGENTS',
	UNINSTALL_CMONAGENT = 'UNINSTALL_CMONAGENT',
	DELETE_DATABASE = 'DELETE_DATABASE',
	SETUP_LOGROTATE = 'SETUP_LOGROTATE',
}
            
export default class CmonJobInstance {
    public canBeAborted?: boolean;
    public canBeDeleted?: boolean;
    public className?: string;
    public clusterId?: number;
    public created?: string;
    public ended?: string;
    public exitCode?: number;
    public groupId?: number;
    public groupName?: string;
    public hasProgress?: boolean;
    public ipAddress?: string;
    public jobId?: number;
    public jobSpec?: string;
    public lastExecuted?: string;
    public maxRetryCount?: number;
    public progressPercent?: number;
    public recurrence?: string;
    public retryCount?: number;
    public retryDelay?: string;
    public rpcVersion?: string;
    public scheduled?: string;
    public started?: string;
    public status?: string;
    public statusText?: string;
    public tags?: string;
    public title?: string;
    public userId?: number;
    public userName?: string;


    constructor(props: CmonJobInstanceProps) {
        this.canBeAborted = props.can_be_aborted;
        this.canBeDeleted = props.can_be_deleted;
        this.className = props.class_name;
        this.clusterId = props.cluster_id;
        this.created = props.created;
        this.ended = props.ended;
        this.exitCode = props.exit_code;
        this.groupId = props.group_id;
        this.groupName = props.group_name;
        this.hasProgress = props.has_progress;
        this.ipAddress = props.ip_address;
        this.jobId = props.job_id;
        this.jobSpec = props.job_spec;
        this.lastExecuted = props.last_executed;
        this.maxRetryCount = props.max_retry_count;
        this.progressPercent = props.progress_percent;
        this.recurrence = props.recurrence;
        this.retryCount = props.retry_count;
        this.retryDelay = props.retry_delay;
        this.rpcVersion = props.rpc_version;
        this.scheduled = props.scheduled;
        this.started = props.started;
        this.status = props.status;
        this.statusText = props.status_text;
        this.tags = props.tags;
        this.title = props.title;
        this.userId = props.user_id;
        this.userName = props.user_name;

    }

}
