import React from 'react';
import { FormInstance } from 'antd/lib/form';
import SpaceDescriptions from '../../../../../common/Layout/SpaceDescriptions';
import AppDivider from '../../../../../common/AppDivider';
import { getServerInstancesToSend } from '../CreateHAProxyWizardForm';

export type CreateHAProxyPreviewProps = {
    form: FormInstance;
};

export default CreateHAProxyPreview;

function CreateHAProxyPreview({ form }: CreateHAProxyPreviewProps) {
    const {
        haProxyWhere,
        haProxyInstallation,
        haProxyAdvanced,
        haProxyServerInstances,
        haProxyServerInstancesInstanceType,
    } = form.getFieldsValue(true);
    const [address] = haProxyWhere.address;
    return (
        <div>
            <SpaceDescriptions
                direction="vertical"
                title="Where to install"
                size="small"
                alignItems="right"
            >
                <SpaceDescriptions.Item label="Server Address" labelStrong>
                    {address?.extraData.hostname ?? ''}
                </SpaceDescriptions.Item>
                <SpaceDescriptions.Item label="Policy" labelStrong>
                    {haProxyWhere.policy ?? ''}
                </SpaceDescriptions.Item>
                <SpaceDescriptions.Item
                    label="Listen Port (Read/Write)"
                    labelStrong
                >
                    {haProxyWhere.portRW ?? ''}
                </SpaceDescriptions.Item>
                <SpaceDescriptions.Item
                    label="Listen Port (Read only)"
                    labelStrong
                >
                    {haProxyWhere.portRO ?? ''}
                </SpaceDescriptions.Item>
                <SpaceDescriptions.Item label="Firewall" labelStrong>
                    {`${haProxyWhere.firewall ?? ''}`}
                </SpaceDescriptions.Item>
                <SpaceDescriptions.Item label="AppArmor/SELinux" labelStrong>
                    {`${haProxyWhere.appArmor ?? ''}`}
                </SpaceDescriptions.Item>
            </SpaceDescriptions>
            <AppDivider />
            <SpaceDescriptions
                direction="vertical"
                title="Installation settings"
                size="small"
                alignItems="right"
            >
                <SpaceDescriptions.Item label="Overwrite" labelStrong>
                    {`${haProxyInstallation.overwrite ?? ''}`}
                </SpaceDescriptions.Item>
            </SpaceDescriptions>
            <AppDivider />
            <SpaceDescriptions
                direction="vertical"
                title="Advanced settings"
                size="small"
                alignItems="right"
            >
                <SpaceDescriptions.Item label="Stats socket">
                    {`${haProxyAdvanced.statsSocket ?? ''}`}
                </SpaceDescriptions.Item>
                <SpaceDescriptions.Item label="adminPort">
                    {`${haProxyAdvanced.adminPort ?? ''}`}
                </SpaceDescriptions.Item>
                <SpaceDescriptions.Item label="adminUser">
                    {`${haProxyAdvanced.adminUser ?? ''}`}
                </SpaceDescriptions.Item>
                <SpaceDescriptions.Item label="adminPassword">
                    {`${haProxyAdvanced.adminPassword ?? ''}`}
                </SpaceDescriptions.Item>
                <SpaceDescriptions.Item label="backendNameRW">
                    {`${haProxyAdvanced.backendNameRW ?? ''}`}
                </SpaceDescriptions.Item>
                <SpaceDescriptions.Item label="backendNameRO">
                    {`${haProxyAdvanced.backendNameRO ?? ''}`}
                </SpaceDescriptions.Item>
                <SpaceDescriptions.Item label="timeoutServer">
                    {`${haProxyAdvanced.timeoutServer ?? ''}`}
                </SpaceDescriptions.Item>
                <SpaceDescriptions.Item label="timeoutClient">
                    {`${haProxyAdvanced.timeoutClient ?? ''}`}
                </SpaceDescriptions.Item>
                <SpaceDescriptions.Item label="maxConnectionsFrontend">
                    {`${haProxyAdvanced.maxConnectionsFrontend ?? ''}`}
                </SpaceDescriptions.Item>
                <SpaceDescriptions.Item label="maxConnectionsBeckend">
                    {`${haProxyAdvanced.maxConnectionsBackend ?? ''}`}
                </SpaceDescriptions.Item>
                <SpaceDescriptions.Item label="xintedAllow">
                    {`${haProxyAdvanced.xintedAllow ?? ''}`}
                </SpaceDescriptions.Item>
            </SpaceDescriptions>
            <SpaceDescriptions
                direction="vertical"
                title="Server instances in the load balancer"
                size="small"
                alignItems="right"
            >
                {getServerInstancesToSend(form.getFieldsValue(true)).map(
                    (instance: any) => {
                        return (
                            <SpaceDescriptions.Item key={instance.hostPort}>
                                {`${instance.hostPort ?? ''} (${
                                    instance.role
                                })`}
                            </SpaceDescriptions.Item>
                        );
                    }
                )}
            </SpaceDescriptions>
        </div>
    );
}
