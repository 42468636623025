import React from 'react';
import { Form } from 'antd';
import WizardForm from '@severalnines/bar-frontend-components/build/lib/Navigation/WizardForm';
import WizardFormConfiguration from '@severalnines/bar-frontend-components/build/lib/Navigation/Wizard/WizardFormConfiguration';
import CcCluster from '../../../../services/models/CcCluster';
import { FormInstance } from 'antd/lib/form';
import useCreateJob from '../../../Jobs/useCreateJob';
import { CmonJobInstanceCommand } from '../../../../services/cmon/models/CmonJobInstance';

import ImportKeepalivedConfiguration, {
    getImportKeepalivedConfigurationValidation,
} from './ImportKeepalivedConfiguration';
import ImportKeepalivedHost, {
    keepalivedHostValidation,
} from './ImportKeepalivedHost';
import ImportKeepalivedPreview from './ImportKeepalivedPreview';

export enum KeepalivedWizardFormSteps {
    Configuration = 'Configuration',
    HostConfiguration = 'HostConfiguration',
    PREVIEW = 'preview',
}

export type ImportKeepalivedWizardFormProps = {
    onCancel?: () => void;
    onSuccess?: () => void;
    onError?: (err: Error) => void;
    onStepErrorInsist?: (err: Error) => void;
    onTouchedChange?: (touched: boolean) => void;
    cluster?: CcCluster;
};

export default ImportKeepalivedWizardForm;

function ImportKeepalivedWizardForm({
    onCancel,
    onSuccess,
    onStepErrorInsist,
    onTouchedChange,
    onError,
    cluster,
}: ImportKeepalivedWizardFormProps) {
    const [form] = Form.useForm();

    const { loading, send } = useCreateJob({
        clusterId: cluster?.clusterId,
        title: 'Keepalived Register Job',
        command:
            CmonJobInstanceCommand.KEEPALIVED.toLowerCase() as CmonJobInstanceCommand,
        onSuccess,
    });

    const handleSubmit = async () => {
        try {
            await send({
                clusterId: cluster?.clusterId,
                ...formatFields(form),
            });
        } catch (err: any) {
            if (onError) {
                onError(err);
            }
        }
    };

    return (
        <WizardFormConfiguration
            form={form}
            onSubmit={handleSubmit}
            loading={loading}
            onCancel={onCancel}
            onStepErrorInsist={onStepErrorInsist}
            onTouchedChange={onTouchedChange}
            steps={[
                <WizardForm.Step
                    key={KeepalivedWizardFormSteps.Configuration}
                    title="Configuration"
                    subTitle=" "
                    validate={getImportKeepalivedConfigurationValidation(form)}
                >
                    <ImportKeepalivedConfiguration
                        selectedCluster={cluster}
                        form={form}
                    />
                </WizardForm.Step>,
                <WizardForm.Step
                    key={KeepalivedWizardFormSteps.HostConfiguration}
                    title={
                        <span style={{ whiteSpace: 'nowrap' }}>
                            Host configuration
                        </span>
                    }
                    subTitle=" "
                    validate={keepalivedHostValidation}
                >
                    <ImportKeepalivedHost />
                </WizardForm.Step>,
                <WizardForm.Step
                    key={KeepalivedWizardFormSteps.PREVIEW}
                    title="Preview"
                    subTitle=" "
                >
                    <ImportKeepalivedPreview form={form} />
                </WizardForm.Step>,
            ]}
        />
    );
}

export function formatFields(form: FormInstance) {
    const { keepalivedConfiguration, keepalivedHost } =
        form.getFieldsValue(true);

    const data = {
        action: 'register',
        component: 'keepalived',
        nodes: keepalivedConfiguration.hostname.map((data: any) => {
            const hostname = data?.extraData.hostname;
            return {
                hostname: hostname,
            };
        }),
        virtual_ip: keepalivedHost?.virtualIP,
    };
    return data;
}
