import React, { useEffect, useState } from 'react';
import './NodeTree.less';
import classNames from 'classnames';
import { DataNode } from 'antd/lib/tree';
import { Tree } from 'antd';
import {
    DatabaseFilled,
    DatabaseOutlined,
    DownOutlined,
    QuestionOutlined,
    UpOutlined,
} from '@ant-design/icons';
import StatusFormat, {
    StatusFormatStatus,
    StatusFormatType,
} from '@severalnines/bar-frontend-components/build/lib/Format/StatusFormat';
import { mergeCollectionDeepByKey } from './merging';
import TypographyText from './TypographyText';
import AppSpin from './General/AppSpin';

export default NodeTree;

export interface NodeTreeDataNode extends DataNode {
    description?: React.ReactNode;
    extraData?: any;
    loading?: boolean;
    status?: StatusFormatStatus;
    children?: NodeTreeDataNode[];
    message?: string;
    extra?: React.ReactNode;
    footer?: React.ReactNode;
    footerExpandable?: boolean;
    footerExpanded?: boolean;
}

export type NodeTreeItemProps = {
    dataNode: NodeTreeDataNode;
    showIcon?: boolean;
};

function NodeTreeItem({ dataNode, showIcon = true }: NodeTreeItemProps) {
    const [expanded, setExpanded] = useState(dataNode.footerExpanded);
    const toggleExpanded = () => {
        setExpanded(!expanded);
    };

    useEffect(() => {
        setExpanded(dataNode.footerExpanded);
    }, [dataNode.footerExpanded]);
    return (
        <div
            className={classNames(
                'NodeTreeItem',
                `NodeTreeItem--status-${dataNode.status}`,
                {
                    'NodeTreeItem--loading': dataNode.loading,
                    'NodeTreeItem--with-extra': dataNode.extra,
                    'NodeTreeItem--expandable': dataNode.footerExpandable,
                    'NodeTreeItem--expanded':
                        expanded &&
                        dataNode.status === StatusFormatStatus.success &&
                        !!dataNode.footer,
                }
            )}
        >
            <div className="NodeTreeItem_wrap">
                <div className="NodeTreeItem_icon">
                    {dataNode.footerExpandable ? (
                        expanded ? (
                            <UpOutlined onClick={toggleExpanded} />
                        ) : (
                            <DownOutlined onClick={toggleExpanded} />
                        )
                    ) : dataNode.status &&
                      dataNode.status !== StatusFormatStatus.unknown ? (
                        <DatabaseFilled />
                    ) : (
                        <DatabaseOutlined />
                    )}
                </div>
                <div className="NodeTreeItem_content">{dataNode.title}</div>
                {showIcon ? (
                    <div className="NodeTreeItem_status">
                        {dataNode.loading ? (
                            <AppSpin size={'small'} />
                        ) : !dataNode.title ? (
                            <TypographyText muted>
                                <QuestionOutlined />
                            </TypographyText>
                        ) : (
                            <StatusFormat
                                status={dataNode.status}
                                type={StatusFormatType.icon}
                            />
                        )}{' '}
                    </div>
                ) : null}

                {dataNode.extra ? (
                    <div className="NodeTreeItem_extra">{dataNode.extra}</div>
                ) : null}
            </div>
            {dataNode.footer ? (
                <div className="NodeTreeItem_footer">{dataNode.footer}</div>
            ) : null}
        </div>
    );
}

export type NodeTreeProps = {
    treeData: NodeTreeDataNode[];
    placeholderTree?: NodeTreeDataNode[];
    fullWidth?: boolean;
    fitContent?: boolean;
    renderNode?: (node: NodeTreeDataNode) => React.ReactElement;
};

function NodeTree({
    treeData,
    placeholderTree,
    fullWidth = false,
    fitContent = false,
    renderNode,
}: NodeTreeProps) {
    const [data, setData] = useState<NodeTreeDataNode[]>(
        mergeCollectionDeepByKey(
            placeholderTree || [],
            treeData,
            'key',
            'children'
        )
    );
    useEffect(() => {
        setData(
            mergeCollectionDeepByKey(
                placeholderTree || [],
                treeData,
                'key',
                'children'
            )
        );
    }, [placeholderTree, treeData]);

    const renderNodeItem = (node: NodeTreeDataNode) => {
        return renderNode?.(node) || <NodeTreeItem dataNode={node} />;
    };

    return (
        <div
            className={classNames('NodeTree', {
                'NodeTree--fullWidth': fullWidth,
                'NodeTree--fitContent': fitContent,
            })}
        >
            <Tree
                defaultExpandAll={true}
                expandedKeys={data.map((p) => p.key)}
                disabled={true}
                draggable={false}
                selectable={false}
                switcherIcon={<div></div>}
                showLine={{ showLeafIcon: false }}
                treeData={data}
                titleRender={renderNodeItem}
            />
        </div>
    );
}

NodeTree.Item = NodeTreeItem;
