import React from 'react';
import StatisticBadge, {
    StatisticBadgeProps,
} from '../../common/StatisticBadge';
import { useStateRunningJobsStats } from './useRunningJobList';
import CcJob from '../../services/models/CcJob';

export default JobsBadge;

export type JobsBadgeProps = StatisticBadgeProps & {
    clusterId?: number;
};

function JobsBadge({ clusterId, status, ...rest }: JobsBadgeProps) {
    const stats = useStateRunningJobsStats({
        filters: [(job: CcJob) => !clusterId || job.clusterId === clusterId],
    });

    const jobCount = stats?.getStatsCount(status);

    return (
        <StatisticBadge
            count={jobCount}
            dot={false}
            status={status || 'info'}
            title={`${jobCount} running jobs`}
            {...rest}
        />
    );
}
