export interface CcLicenseCheckProps {
    expires_days?: number;
    has_license?: boolean;
    status_text?: string;
}

export default class CcLicenseCheck {
    public readonly expiresDays?: number;
    public readonly hasLicense?: boolean;
    public readonly statusText?: string;

    constructor(props: CcLicenseCheckProps) {
        this.expiresDays = props.expires_days;
        this.hasLicense = props.has_license;
        this.statusText = props.status_text;
    }
}
