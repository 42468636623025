import React from 'react';
import { Form } from 'antd';
import WizardForm from '@severalnines/bar-frontend-components/build/lib/Navigation/WizardForm';
import WizardFormConfiguration from '@severalnines/bar-frontend-components/build/lib/Navigation/Wizard/WizardFormConfiguration';
import CcCluster from '../../../../services/models/CcCluster';
import { FormInstance } from 'antd/lib/form';
import useCreateJob from '../../../Jobs/useCreateJob';
import { CmonJobInstanceCommand } from '../../../../services/cmon/models/CmonJobInstance';

import CreateHAProxyWhere, {
    getCreateHaProxyWhereValidation,
    haProxyWhereInitialValues,
} from './CreateHAProxyWizradFromSteps/CreateHAProxyWhere';

import CreateHAProxyInstallation, {
    haProxyInstallationInitial,
} from './CreateHAProxyWizradFromSteps/CreateHAProxyInstallation';

import CreateHAProxyAdvanced, {
    haProxyAdvancedInitial,
    haProxyAdvancedValidation,
} from './CreateHAProxyWizradFromSteps/CreateHAProxyAdvanced';

import CreateHAProxyServerInstances, {
    getProxyableInstances,
} from './CreateHAProxyWizradFromSteps/CreateHAProxyServerInstances';
import CreateHAProxyPreview from './CreateHAProxyWizradFromSteps/CreateHAProxyPreview';

export enum HAProxyFormSteps {
    WHERE_TO_INS = 'WhereToInstall',
    INSTALL_SETTINGS = 'InstallationSettings',
    ADV_SETTINGS = 'AdvancedSettings',
    SERVER_INS = 'ServerInstances',
    PREVIEW = 'preview',
}

export type CreateHAProxyWizardFormProps = {
    onCancel?: () => void;
    onSuccess?: () => void;
    onError?: (err: Error) => void;
    onStepErrorInsist?: (err: Error) => void;
    onTouchedChange?: (touched: boolean) => void;
    cluster?: CcCluster;
};

export default CreateHAProxyWizardForm;

function CreateHAProxyWizardForm({
    onCancel,
    onSuccess,
    onStepErrorInsist,
    onTouchedChange,
    onError,
    cluster,
}: CreateHAProxyWizardFormProps) {
    const [form] = Form.useForm();

    const { loading, send } = useCreateJob({
        clusterId: cluster?.clusterId,
        title: 'Setup HAProxy Server',
        command: CmonJobInstanceCommand.HAPROXY.toLowerCase() as CmonJobInstanceCommand,
        onSuccess,
    });

    const handleSubmit = async () => {
        try {
            await send({
                clusterId: cluster?.clusterId,
                ...formatFields(form),
            });
        } catch (err: any) {
            if (onError) {
                onError(err);
            }
        }
    };

    return (
        <WizardFormConfiguration
            form={form}
            onSubmit={handleSubmit}
            loading={loading}
            onCancel={onCancel}
            initialValues={{
                ...haProxyWhereInitialValues(cluster),
                ...haProxyInstallationInitial,
                ...haProxyAdvancedInitial,
                haProxyServerInstances: getInitialValuesServerInstances(
                    cluster
                ),
            }}
            onStepErrorInsist={onStepErrorInsist}
            onTouchedChange={onTouchedChange}
            steps={[
                <WizardForm.Step
                    key={HAProxyFormSteps.WHERE_TO_INS}
                    title="Where to install"
                    subTitle=" "
                    validate={getCreateHaProxyWhereValidation(form)}
                >
                    <CreateHAProxyWhere selectedCluster={cluster} form={form} />
                </WizardForm.Step>,
                <WizardForm.Step
                    key={HAProxyFormSteps.INSTALL_SETTINGS}
                    title="Installation settings"
                    subTitle=" "
                >
                    <CreateHAProxyInstallation selectedCluster={cluster} />
                </WizardForm.Step>,
                <WizardForm.Step
                    key={HAProxyFormSteps.ADV_SETTINGS}
                    title="Advanced settings"
                    subTitle=" "
                    validate={haProxyAdvancedValidation}
                >
                    <CreateHAProxyAdvanced />
                </WizardForm.Step>,
                <WizardForm.Step
                    key={HAProxyFormSteps.SERVER_INS}
                    title="Server instances"
                    subTitle=" "
                >
                    <CreateHAProxyServerInstances
                        form={form}
                        selectedCluster={cluster}
                    />
                </WizardForm.Step>,
                <WizardForm.Step
                    key={HAProxyFormSteps.PREVIEW}
                    title="Preview"
                    subTitle=" "
                >
                    <CreateHAProxyPreview form={form} />
                </WizardForm.Step>,
            ]}
        />
    );
}

export function getServerInstancesToSend(formValues: any) {
    return Object.values(formValues.haProxyServerInstances).filter(
        (instance: any) =>
            formValues.haProxyServerInstancesInstanceType ===
                instance.nodetype && instance.included
    );
}

export function getInitialValuesServerInstances(cluster?: CcCluster) {
    let haProxyServerInstances: any = {};
    if (cluster) {
        const proxyableInstances = getProxyableInstances(cluster);
        for (let i = 0; i < proxyableInstances.length; i++) {
            haProxyServerInstances[proxyableInstances[i].getKey()] = {
                hostPort: proxyableInstances[i].getHostWithPort(),
                nodetype: proxyableInstances[i].nodetype,
                included: false,
                role: 'active',
                connection: '1',
            };
        }
    }
    return haProxyServerInstances;
}

export function formatFields(form: FormInstance) {
    const {
        haProxyWhere,
        haProxyInstallation,
        haProxyAdvanced,
    } = form.getFieldsValue(true);
    let nodeAddress = getServerInstancesToSend(form.getFieldsValue(true)).map(
        (i: any) => `${i.hostPort}:${i.role}`
    );
    const [address] = haProxyWhere.address;
    const date = {
        action: 'setupHaProxy',
        node: {
            hostname: address?.extraData.hostname,
            lb_admin: haProxyAdvanced.adminUser,
            lb_password: haProxyAdvanced.adminPassword,
            lb_policy: haProxyWhere.policy,
            max_connection_be: haProxyAdvanced.maxConnectionsBackend,
            max_connection_fe: haProxyAdvanced.maxConnectionsFrontend,
            port: haProxyAdvanced.adminPort,
            rw_port: haProxyWhere.portRW,
            ro_port: haProxyWhere.portRO,
            rw_splitting: haProxyWhere.rwSplitting,
            stats_socket: haProxyAdvanced.statsSocket,
            timeout_client: haProxyAdvanced.timeoutClient,
            timeout_server: haProxyAdvanced.timeoutServer,
            xinetd_allow_from: haProxyAdvanced.xintedAllow,
            backend_name_ro: haProxyAdvanced.backendNameRO,
            backend_name_rw: haProxyAdvanced.backendNameRW,
        },
        node_addresses: nodeAddress.join(','),
        overwrite_mysqlchk: haProxyInstallation.overwrite,
        disable_firewall: haProxyWhere.firewall,
        disable_selinux: haProxyWhere.appArmor,
    };
    return date;
}
