


export interface CmonDiskInfoProps  {
    capacity?: number;
    device?: string;
    model?: string;
    'model-family'?: string;
    partitions?: string;
    'power-cycle-count'?: string;
    'power-on-hours'?: number;
    'reallocated-sector-counter'?: number;
    'self-health-assessment'?: string;
    'serial-number'?: string;
    'temperature-celsius'?: number;

}



export default class CmonDiskInfo {
    public capacity?: number;
    public device?: string;
    public model?: string;
    public modelFamily?: string;
    public partitions?: string;
    public powerCycleCount?: string;
    public powerOnHours?: number;
    public reallocatedSectorCounter?: number;
    public selfHealthAssessment?: string;
    public serialNumber?: string;
    public temperatureCelsius?: number;


    constructor(props: CmonDiskInfoProps) {
        this.capacity = props.capacity;
        this.device = props.device;
        this.model = props.model;
        this.modelFamily = props['model-family'];
        this.partitions = props.partitions;
        this.powerCycleCount = props['power-cycle-count'];
        this.powerOnHours = props['power-on-hours'];
        this.reallocatedSectorCounter = props['reallocated-sector-counter'];
        this.selfHealthAssessment = props['self-health-assessment'];
        this.serialNumber = props['serial-number'];
        this.temperatureCelsius = props['temperature-celsius'];

    }

}
