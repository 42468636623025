


export interface CmonCdtEntryProps  {
    acl?: string;
    cdt_path?: string;
    content?: string;
    dirty?: boolean;
    major_device_number?: number;
    minor_device_number?: number;
    name?: string;
    owner_group_id?: number;
    owner_group_name?: string;
    owner_user_id?: number;
    owner_user_name?: string;
    type?: string;

}



export default class CmonCdtEntry {
    public acl?: string;
    public cdtPath?: string;
    public content?: string;
    public dirty?: boolean;
    public majorDeviceNumber?: number;
    public minorDeviceNumber?: number;
    public name?: string;
    public ownerGroupId?: number;
    public ownerGroupName?: string;
    public ownerUserId?: number;
    public ownerUserName?: string;
    public type?: string;


    constructor(props: CmonCdtEntryProps) {
        this.acl = props.acl;
        this.cdtPath = props.cdt_path;
        this.content = props.content;
        this.dirty = props.dirty;
        this.majorDeviceNumber = props.major_device_number;
        this.minorDeviceNumber = props.minor_device_number;
        this.name = props.name;
        this.ownerGroupId = props.owner_group_id;
        this.ownerGroupName = props.owner_group_name;
        this.ownerUserId = props.owner_user_id;
        this.ownerUserName = props.owner_user_name;
        this.type = props.type;

    }

}
