import React from 'react';
import BooleanFormat, {
    BooleanFormatProps,
} from '@severalnines/bar-frontend-components/build/lib/Format/BooleanFormat';

export default YesNoFormat;

export type YesNoFormatProps = BooleanFormatProps;

function YesNoFormat({ ...rest }: YesNoFormatProps) {
    return (
        <BooleanFormat
            contentOn="Yes"
            contentOff="No"
            statusOn="success"
            statusOff="default"
            {...rest}
        />
    );
}
