


export interface CmonGraphProps  {

}



export default class CmonGraph {


    constructor(props: CmonGraphProps) {

    }

}
