


export interface CmonAlarmStatisticsProps  {
    cluster_id?: number;
    created_after?: number;
    critical?: number;
    reported_after?: number;
    warning?: number;

}



export default class CmonAlarmStatistics {
    public clusterId?: number;
    public createdAfter?: number;
    public critical?: number;
    public reportedAfter?: number;
    public warning?: number;


    constructor(props: CmonAlarmStatisticsProps) {
        this.clusterId = props.cluster_id;
        this.createdAfter = props.created_after;
        this.critical = props.critical;
        this.reportedAfter = props.reported_after;
        this.warning = props.warning;

    }

}
