import { useMemo } from 'react';
import CcAdvisorSchedule from '../../../services/models/CcAdvisorSchedule';

type UseAdvisorTagsProps = {
    schedules: CcAdvisorSchedule[];
};
export default function useAdvisorTags({ schedules }: UseAdvisorTagsProps) {
    return useMemo(() => {
        const tags = schedules?.reduce((acc, item) => {
            if (item.tags) {
                acc.push(...item.tags);
            }
            return acc;
        }, [] as string[]);
        return Array.from(new Set(tags));
    }, [schedules]);
}
