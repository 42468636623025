import React from 'react';
import { ButtonProps } from 'antd/lib/button';
import { CmonJobInstanceCommand } from '../../../services/cmon/models/CmonJobInstance';
import NodeDisableBinaryLoggingForm from './NodeDisableBinaryLoggingForm';
import CcNode from '../../../services/models/CcNode';
import ButtonWithForm from '../../../common/General/ButtonWithForm';
import JobActionButton from '../../Jobs/JobActionButton';

export default NodeDisableBinaryLoggingButton;

export type NodeDisableBinaryLoggingButtonProps = ButtonProps & {
    node: CcNode;
    children?: React.ReactNode;
    onSuccess?: () => void;
};

function NodeDisableBinaryLoggingButton({
    node,
    children,
    onSuccess,
    ...rest
}: NodeDisableBinaryLoggingButtonProps) {
    return (
        <ButtonWithForm
            button={
                <JobActionButton
                    command={CmonJobInstanceCommand.DISABLE_BINARY_LOGGING}
                    clusterId={node.clusterid}
                    extraKey={[node.hostname, node.port]}
                    {...rest}
                >
                    {children || 'Disable binary logging'}
                </JobActionButton>
            }
            form={
                <NodeDisableBinaryLoggingForm
                    node={node}
                    onSuccess={onSuccess}
                />
            }
        />
    );
}
