import React from 'react';
import ActionButton from '@severalnines/bar-frontend-components/build/lib/General/ActionButton';
import ButtonWithForm from '../../../common/General/ButtonWithForm';
import { ButtonProps } from 'antd/lib/button';
import CcCluster from '../../../services/models/CcCluster';
import PrometheusEnableModal from '../../Prometheus/PrometheusEnableModal';

export default ClusterEnablePrometheusButton;

export type ClusterEnablePrometheusButtonProps = ButtonProps & {
    cluster: CcCluster;
    reEnable?: boolean;
    children?: React.ReactNode;
};

function ClusterEnablePrometheusButton({
    cluster,
    reEnable = false,
    children,
    ...rest
}: ClusterEnablePrometheusButtonProps) {
    return (
        <ButtonWithForm
            button={
                <ActionButton {...rest}>
                    {children ||
                        (reEnable
                            ? 'Re-enable agent based monitoring'
                            : 'Enable agent based monitoring')}
                </ActionButton>
            }
            form={<PrometheusEnableModal cluster={cluster} />}
        />
    );
}
