import React from 'react';
import Form, { FormItemProps } from 'antd/lib/form';
import { CcClusterType } from '../../../services/models/CcCluster';
import ClusterNodeRoleSelect from '../../DataEntry/ClusterNodeRoleSelect';

export default TopologyNodeRoleSelectField;
export type TopologyNodeRoleSelectFieldProps = FormItemProps & {
    clusterType: CcClusterType;
};

function TopologyNodeRoleSelectField({
    clusterType,
    ...rest
}: TopologyNodeRoleSelectFieldProps) {
    return (
        <Form.Item
            label="Node role"
            name="nodeRole"
            rules={[{ required: true, message: 'Please select node role' }]}
            {...rest}
        >
            <ClusterNodeRoleSelect clusterType={clusterType} />
        </Form.Item>
    );
}
