import { useCallback, useState } from 'react';

import useFetch, { UseFetchProps } from '../../../common/useFetch';

import CmonConfigService from '../../../services/cmon/CmonConfigService';
import { LdapConfig, LdapConfigGroupMappingsItem } from './LdapTypes';
import merge from 'deepmerge';

export type UseLdapSettingsProps = Omit<UseFetchProps, 'fetchFn'> & {
    name?: string | null;
};
export default function useLdapSettings({ name }: UseLdapSettingsProps = {}) {
    const { error, loading, loaded, params, refresh, cancel } = useFetch({
        name,
        throwError: true,
        fetchFn: async (params, opts) => {
            const {
                ldap_configuration,
            } = await CmonConfigService.getLdapConfig(
                {
                    ...params,
                },
                opts
            );

            const { defaults, ...restConfig } = ldap_configuration;
            setRecord(restConfig);
            setDefaults(defaults);
            return restConfig as LdapConfig;
        },
        cancelFn: async ({ requestId }) => {
            await CmonConfigService.cancelRequest(requestId);
        },
    });
    const [record, setRecord] = useState<LdapConfig | undefined>();
    const [defaults, setDefaults] = useState<LdapConfig | undefined>();

    const save = useCallback(
        async (config: LdapConfig) => {
            let currentRecord = record;
            if (!currentRecord) {
                currentRecord = await refresh();
            }
            await CmonConfigService.setLdapConfig({
                ldap_configuration: {
                    ...merge(currentRecord || ({} as LdapConfig), config),
                    // merge will duplicate groupMappings so we override them with current
                    groupMappings: currentRecord?.groupMappings,
                },
            });
        },
        [record]
    );

    const addGroupMap = useCallback(
        async (groupMapItem: LdapConfigGroupMappingsItem) => {
            let currentRecord = record;
            if (!currentRecord) {
                currentRecord = await refresh();
            }
            const newSectionName = `${groupMapItem.ldapGroupId}${groupMapItem.cmonGroupName}`;
            if (
                currentRecord?.groupMappings &&
                currentRecord?.groupMappings.find(
                    (item) => item.sectionName === newSectionName
                )
            ) {
                throw new Error('LDAP group mapping already exists.');
            }
            await CmonConfigService.setLdapConfig({
                ldap_configuration: {
                    ...currentRecord,
                    groupMappings: [
                        ...(currentRecord?.groupMappings || []),
                        {
                            ...groupMapItem,
                            sectionName: newSectionName,
                        },
                    ],
                },
            });
        },
        [record, refresh]
    );
    const updateGroupMap = useCallback(
        async (
            currentGroupMapItem: LdapConfigGroupMappingsItem,
            newGroupMapItem: LdapConfigGroupMappingsItem
        ) => {
            let currentRecord = record;
            if (!currentRecord) {
                currentRecord = await refresh();
            }
            const newSectionName = `${newGroupMapItem.ldapGroupId}${newGroupMapItem.cmonGroupName}`;
            if (
                currentRecord?.groupMappings &&
                currentRecord?.groupMappings.find(
                    (item) => item.sectionName === newSectionName
                )
            ) {
                throw new Error('LDAP group mapping already exists.');
            }
            await CmonConfigService.setLdapConfig({
                ldap_configuration: {
                    ...currentRecord,
                    groupMappings: [
                        ...(currentRecord?.groupMappings
                            ? removeItemFromArray(
                                  currentRecord.groupMappings,
                                  currentGroupMapItem
                              )
                            : []),
                        {
                            ...newGroupMapItem,
                            sectionName: newSectionName,
                        },
                    ],
                },
            });
        },
        [record, refresh]
    );
    const deleteGroupMap = useCallback(
        async (itemToDelete: LdapConfigGroupMappingsItem) => {
            let currentRecord = record;
            if (!currentRecord) {
                currentRecord = await refresh();
            }
            await CmonConfigService.setLdapConfig({
                ldap_configuration: {
                    ...currentRecord,
                    groupMappings: [
                        ...(currentRecord?.groupMappings
                            ? removeItemFromArray(
                                  currentRecord.groupMappings,
                                  itemToDelete
                              )
                            : []),
                    ],
                },
            });
        },
        [record, refresh]
    );

    return {
        error,
        loading,
        loaded,
        record,
        refresh,
        cancel,
        save,
        addGroupMap,
        updateGroupMap,
        deleteGroupMap,
        defaults,
        ...params,
    };
}

function removeItemFromArray(
    groupMappings: LdapConfigGroupMappingsItem[],
    itemToDelete: LdapConfigGroupMappingsItem
) {
    return groupMappings.filter(
        (item) =>
            !(
                itemToDelete.cmonGroupName === item.cmonGroupName &&
                itemToDelete.ldapGroupId === item.ldapGroupId
            )
    );
}
