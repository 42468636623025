import { useEffect, useState } from 'react';
import { Layout, Button } from 'antd';
import { RedoOutlined } from '@ant-design/icons';
import KeyManagementTree from './KeyManagementTree';
import KeyManagementTable from './KeyManagementTable';
import useKeyManagement from './useKeyManagement';
import { CertificateTypes } from '../Security/UseExistingCertificate';
import SpaceWide from '../../common/SpaceWide';
import KeyManagementGenerateActionMenu from './KeyManagementGenerateActionMenu';

export default KeyManagementManagePage;

const { Content, Sider, Footer } = Layout;

function KeyManagementManagePage() {
    const { list, refresh, loading, total } = useKeyManagement({});
    const [selectedNode, setSelectedNode] = useState();

    const handleTreeItemSelect = (node: any) => {
        setSelectedNode(node);
    };

    const handleRefresh = () => {
        (async () => {
            await refresh({});
        })();
    };

    useEffect(() => {
        handleRefresh();
    }, []);

    return (
        <>
            <Layout
                style={{
                    background: '#fff',
                }}
            >
                <Sider
                    style={{
                        background: '#fff',
                        borderRight: '1px solid #ccc',
                        paddingRight: '5px',
                    }}
                    width={260}
                >
                    <KeyManagementTree
                        list={list}
                        onSelect={handleTreeItemSelect}
                        onRefresh={handleRefresh}
                    />
                </Sider>
                <Content
                    style={{
                        padding: '0 24px',
                        minHeight: 280,
                        background: '#fff',
                    }}
                >
                    <SpaceWide direction="vertical" align="end">
                        <div>
                            <Button
                                type="primary"
                                icon={<RedoOutlined />}
                                onClick={handleRefresh}
                            >
                                Refresh
                            </Button>
                            <KeyManagementGenerateActionMenu
                                list={list}
                                onActionPerformed={handleRefresh}
                            />
                        </div>
                    </SpaceWide>
                    <KeyManagementTable
                        selectedNode={selectedNode}
                        loading={loading}
                        list={list}
                        onActionPerformed={handleRefresh}
                    />

                    <Footer
                        style={{
                            marginTop: '10px',
                        }}
                    ></Footer>
                </Content>
            </Layout>
        </>
    );
}
