import React from 'react';
import JobButton, { JobButtonProps } from './JobButton';
import useCreateJob from './useCreateJob';

export default JobCreateButton;

export type JobCreateButtonProps = JobButtonProps & {
    title: string;
    onSuccess?: () => void;
    jobData?: any;
};

function JobCreateButton({
    onClick,
    clusterId,
    command,
    title,
    onSuccess,
    jobData,
    ...rest
}: JobCreateButtonProps) {
    const { send, loading } = useCreateJob({
        title,
        command,
        clusterId,
        onSuccess,
    });
    const handleClick = async (
        event: React.MouseEvent<HTMLElement, MouseEvent>
    ) => {
        await send(jobData);
        onClick?.(event);
    };
    return (
        <JobButton
            onClick={handleClick}
            loading={loading}
            command={command}
            clusterId={clusterId}
            {...rest}
        />
    );
}
