


export interface CmonStatsProps  {
    created?: number;
    hostid?: number;
    interval?: number;
    sampleends?: number;
    samplekey?: string;
    serverid?: number;

}



export default class CmonStats {
    public created?: number;
    public hostid?: number;
    public interval?: number;
    public sampleends?: number;
    public samplekey?: string;
    public serverid?: number;


    constructor(props: CmonStatsProps) {
        this.created = props.created;
        this.hostid = props.hostid;
        this.interval = props.interval;
        this.sampleends = props.sampleends;
        this.samplekey = props.samplekey;
        this.serverid = props.serverid;

    }

}
