import React from 'react';
import { ButtonProps } from 'antd/lib/button';

import { CmonJobInstanceCommand } from '../../../services/cmon/models/CmonJobInstance';
import CcNode from '../../../services/models/CcNode';
import ButtonWithForm from '../../../common/General/ButtonWithForm';
import NodePromoteSlaveForm from './NodePromoteSlaveForm';
import JobActionButton from '../../Jobs/JobActionButton';
import { CcClusterType } from '../../../services/models/CcCluster';

export default NodePromoteSlaveButton;

export type NodePromoteSlaveButtonProps = ButtonProps & {
    node: CcNode;
    clusterType?: CcClusterType;
    children?: React.ReactNode;
    onSuccess?: () => void;
};

function NodePromoteSlaveButton({
    node,
    clusterType,
    children,
    onSuccess,
    ...rest
}: NodePromoteSlaveButtonProps) {
    return (
        <ButtonWithForm
            button={
                <JobActionButton
                    command={CmonJobInstanceCommand.PROMOTE_REPLICATION_SLAVE}
                    clusterId={node.clusterid}
                    clusterType={clusterType}
                    extraKey={[node.hostname, node.port]}
                    {...rest}
                >
                    {children || 'Promote replica'}
                </JobActionButton>
            }
            form={<NodePromoteSlaveForm node={node} onSuccess={onSuccess} />}
        />
    );
}
