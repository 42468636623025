const dashboard = {
    title: 'MySQL Server - General',
    meta: {
        slug: 'mysql-server-general',
        supportClusterTypes: [
            'replication',
            'galera',
            'mysql_single',
            'mysqlcluster',
        ],
    },
    panels: [
        {
            title: 'MySQL uptime',
            type: 'singlestat',
            gridPos: {
                h: 4,
                w: 6,
                x: 0,
                y: 0,
            },
            options: {
                decimals: 1,
                format: 'duration',
                type: 'line',
                legend: {},
                chart: {
                    full: false,
                    show: false,
                },
                valueMaps: [],
            },
            targets: [
                {
                    expr: 'mysql_global_status_uptime{instance=~"$instance"}',
                    legendFormat: '',
                },
            ],
        },
        {
            title: 'Queries/s',
            type: 'singlestat',
            gridPos: {
                h: 4,
                w: 6,
                x: 0,
                y: 0,
            },
            options: {
                decimals: 0,
                format: 'short',
                type: 'line',
                legend: {},
                chart: {
                    full: false,
                    show: true,
                },
                postfix: ' / sec',
                valueMaps: [],
            },
            targets: [
                {
                    expr:
                        'irate(mysql_global_status_queries{instance=~"$instance"}[5m])',
                    legendFormat: '',
                },
            ],
        },
        {
            title: 'Connections used',
            type: 'singlestat',
            gridPos: {
                h: 4,
                w: 6,
                x: 0,
                y: 0,
            },
            options: {
                decimals: 2,
                format: 'percent',
                type: 'line',
                legend: {},
                chart: {
                    full: false,
                    show: true,
                },
                valueMaps: [],
            },
            targets: [
                {
                    expr:
                        '100 * mysql_global_status_threads_connected{instance=~"$instance"} / mysql_global_variables_max_connections{instance=~"$instance"}',
                    legendFormat: '',
                },
            ],
        },
        {
            title: 'Innodb bufferpool hit ratio',
            type: 'singlestat',
            gridPos: {
                h: 4,
                w: 6,
                x: 0,
                y: 0,
            },
            options: {
                decimals: 0,
                format: 'number',
                type: 'line',
                legend: {},
                chart: {
                    full: false,
                    show: false,
                },
                postfix: ' / 1,000',
                valueMaps: [],
            },
            targets: [
                {
                    expr:
                        '1000*mysql_global_status_innodb_buffer_pool_read_requests{instance=~"$instance"} / (mysql_global_status_innodb_buffer_pool_reads{instance=~"$instance"} + mysql_global_status_innodb_buffer_pool_read_requests{instance=~"$instance"})',
                    legendFormat: '',
                },
            ],
        },
        {
            title: 'Handler stats',
            type: 'chart',
            gridPos: {
                h: 9,
                w: 8,
                x: 0,
                y: 0,
            },
            options: {
                decimals: 0,
                format: 'short',
                type: 'line',
                legend: {
                    alignAsTable: true,
                },
                yaxis: [
                    {
                        format: 'short',
                        max: null,
                        min: 0,
                    },
                    {
                        format: 'short',
                        max: null,
                        min: 0,
                    },
                ],
                stack: false,
            },
            targets: [
                {
                    expr:
                        'irate(mysql_global_status_handlers_total{instance=~"$instance",handler="read_rnd"}[5m])',
                    legendFormat: 'Handler_read_rnd',
                },
                {
                    expr:
                        'irate(mysql_global_status_handlers_total{instance=~"$instance",handler="read_rnd_next"}[5m])',
                    legendFormat: 'Handler_read_rnd_next',
                },
                {
                    expr:
                        'irate(mysql_global_status_handlers_total{instance=~"$instance",handler="read_next"}[5m])',
                    legendFormat: 'Handler_read_next',
                },
                {
                    expr:
                        'irate(mysql_global_status_handlers_total{instance=~"$instance",handler="read_key"}[5m])',
                    legendFormat: 'Handler_read_key',
                },
                {
                    expr:
                        'irate(mysql_global_status_handlers_total{instance=~"$instance",handler="read_first"}[5m])',
                    legendFormat: 'Handler_read_first',
                },
                {
                    expr:
                        'irate(mysql_global_status_handlers_total{instance=~"$instance",handler="read_last"}[5m])',
                    legendFormat: 'Handler_read_last',
                },
                {
                    expr:
                        'irate(mysql_global_status_handlers_total{instance=~"$instance",handler="delete"}[5m])',
                    legendFormat: 'Handler_delete',
                },
                {
                    expr:
                        'irate(mysql_global_status_handlers_total{instance=~"$instance",handler="update"}[5m])',
                    legendFormat: 'Handler_update',
                },
                {
                    expr:
                        'irate(mysql_global_status_handlers_total{instance=~"$instance",handler="write"}[5m])',
                    legendFormat: 'Handler_write',
                },
            ],
        },
        {
            title: 'Handler transaction stats',
            type: 'chart',
            gridPos: {
                h: 9,
                w: 8,
                x: 0,
                y: 0,
            },
            options: {
                decimals: 0,
                format: 'short',
                type: 'line',
                legend: {
                    alignAsTable: true,
                },
                yaxis: [
                    {
                        format: 'short',
                        max: null,
                        min: 0,
                    },
                    {
                        format: 'short',
                        max: null,
                        min: 0,
                    },
                ],
                stack: false,
            },
            targets: [
                {
                    expr:
                        'irate(mysql_global_status_handlers_total{instance=~"$instance",handler="rollback"}[5m])',
                    legendFormat: 'Handler_rollback',
                },
                {
                    expr:
                        'irate(mysql_global_status_handlers_total{instance=~"$instance",handler="savepoint"}[5m])',
                    legendFormat: 'Handler_savepoint',
                },
                {
                    expr:
                        'irate(mysql_global_status_handlers_total{instance=~"$instance",handler="commit"}[5m])',
                    legendFormat: 'Handler_commit',
                },
                {
                    expr:
                        'irate(mysql_global_status_handlers_total{instance=~"$instance",handler="savepoint_rollback"}[5m])',
                    legendFormat: 'Handler_savepoint_rollback',
                },
                {
                    expr:
                        'irate(mysql_global_status_handlers_total{instance=~"$instance",handler="prepare"}[5m])',
                    legendFormat: 'Handler_prepare',
                },
            ],
        },
        {
            title: 'DB connections',
            type: 'chart',
            gridPos: {
                h: 9,
                w: 8,
                x: 0,
                y: 0,
            },
            options: {
                decimals: 0,
                format: 'short',
                type: 'area',
                legend: {
                    alignAsTable: true,
                },
                yaxis: [
                    {
                        format: 'short',
                        max: null,
                        min: 0,
                    },
                    {
                        format: 'short',
                        max: null,
                        min: 0,
                    },
                ],
                stack: false,
            },
            targets: [
                {
                    expr:
                        'mysql_global_status_threads_connected{instance=~"$instance"}',
                    legendFormat: 'Threads Connected',
                },
                {
                    expr:
                        'mysql_global_status_max_used_connections{instance=~"$instance"}',
                    legendFormat: 'Max Used Connections',
                },
                {
                    expr:
                        'mysql_global_variables_max_connections{instance=~"$instance"}',
                    legendFormat: 'Max Connections',
                },
                {
                    expr:
                        'irate(mysql_global_status_aborted_clients{instance=~"$instance"}[5m])',
                    legendFormat: 'Aborted Clients',
                },
                {
                    expr:
                        'irate(mysql_global_status_aborted_connects{instance=~"$instance"}[5m])',
                    legendFormat: 'Aborted Connections',
                },
                {
                    expr:
                        'irate(mysql_global_status_connections{instance=~"$instance"}[5m])',
                    legendFormat: 'Connections',
                },
            ],
        },
        {
            title: 'Queries',
            type: 'chart',
            gridPos: {
                h: 7,
                w: 8,
                x: 0,
                y: 0,
            },
            options: {
                decimals: 0,
                format: 'short',
                type: 'line',
                legend: {
                    alignAsTable: true,
                },
                yaxis: [
                    {
                        format: 'short',
                        max: null,
                        min: 0,
                    },
                    {
                        format: 'short',
                        max: null,
                        min: 0,
                    },
                ],
                stack: false,
            },
            targets: [
                {
                    expr:
                        'irate(mysql_global_status_queries{instance=~"$instance"}[5m])',
                    legendFormat: 'Queries',
                },
            ],
        },
        {
            title: 'Scan operations',
            type: 'chart',
            gridPos: {
                h: 7,
                w: 8,
                x: 0,
                y: 0,
            },
            options: {
                decimals: 0,
                format: 'number',
                type: 'area',
                legend: {
                    alignAsTable: true,
                },
                yaxis: [
                    {
                        format: 'short',
                        max: null,
                        min: 0,
                    },
                    {
                        format: 'short',
                        max: null,
                        min: 0,
                    },
                ],
                stack: false,
            },
            targets: [
                {
                    expr:
                        'irate(mysql_global_status_select_scan{instance=~"$instance"}[5m])',
                    legendFormat:
                        'Select_scan (joins required a full scan of the first table)',
                },
                {
                    expr:
                        'irate(mysql_global_status_delete_scan{instance=~"$instance"}[5m])',
                    legendFormat:
                        'Delete_scan (deletes requiring full table scan)',
                },
                {
                    expr:
                        'irate(mysql_global_status_update_scan{instance=~"$instance"}[5m])',
                    legendFormat:
                        'Update_scan (updates requiring full table scan)',
                },
            ],
        },
        {
            title: 'Table locking',
            type: 'chart',
            gridPos: {
                h: 7,
                w: 8,
                x: 0,
                y: 0,
            },
            options: {
                decimals: 2,
                format: 'short',
                type: 'area',
                legend: {
                    alignAsTable: true,
                },
                yaxis: [
                    {
                        format: 'short',
                        max: null,
                        min: 0,
                    },
                    {
                        format: 'short',
                        max: null,
                        min: 0,
                    },
                ],
                stack: false,
            },
            targets: [
                {
                    expr:
                        'irate(mysql_global_status_table_locks_immediate{instance=~"$instance"}[5m])',
                    legendFormat: 'Table Locks Immediate',
                },
                {
                    expr:
                        'irate(mysql_global_status_table_locks_waited{instance=~"$instance"}[5m])',
                    legendFormat: 'Table Locks Waited',
                },
            ],
        },
        {
            title: 'Temporary tables',
            type: 'chart',
            gridPos: {
                h: 7,
                w: 8,
                x: 0,
                y: 0,
            },
            options: {
                decimals: 2,
                format: 'short',
                type: 'line',
                legend: {
                    alignAsTable: true,
                },
                yaxis: [
                    {
                        format: 'short',
                        max: null,
                        min: 0,
                    },
                ],
                stack: false,
            },
            targets: [
                {
                    expr:
                        'irate(mysql_global_status_created_tmp_tables{instance=~"$instance"}[5m])',
                    legendFormat: 'Created Tmp Tables / sec',
                },
                {
                    expr:
                        'irate(mysql_global_status_created_tmp_disk_tables{instance=~"$instance"}[5m])',
                    legendFormat: 'Created Tmp Disk Tables / sec',
                },
            ],
        },
        {
            title: 'Sorting',
            type: 'chart',
            gridPos: {
                h: 7,
                w: 8,
                x: 0,
                y: 0,
            },
            options: {
                decimals: 2,
                format: 'short',
                type: 'line',
                legend: {
                    alignAsTable: true,
                },
                yaxis: [
                    {
                        format: 'short',
                        max: null,
                        min: 0,
                    },
                    {
                        format: 'short',
                        max: null,
                        min: 0,
                    },
                ],
                stack: false,
            },
            targets: [
                {
                    expr:
                        'irate(mysql_global_status_sort_merge_passes{instance=~"$instance"}[5m])',
                    legendFormat: 'Sort_merge_passes',
                    yaxis: 1,
                },
                {
                    expr:
                        'irate(mysql_global_status_sort_scan{instance=~"$instance"}[5m])',
                    legendFormat: 'Sort_scan (table scans)',
                    yaxis: 1,
                },
                {
                    expr:
                        'irate(mysql_global_status_sort_range{instance=~"$instance"}[5m])',
                    legendFormat: 'Sort_range (index scans)',
                    yaxis: 1,
                },
                {
                    expr:
                        'irate(mysql_global_status_sort_rows{instance=~"$instance"}[5m])',
                    legendFormat: 'Sort_rows (number of rows sorted)',
                    yaxis: 0,
                },
            ],
        },
        {
            title: 'Aborted connections',
            type: 'chart',
            gridPos: {
                h: 8,
                w: 8,
                x: 0,
                y: 0,
            },
            options: {
                decimals: 2,
                format: 'short',
                type: 'area',
                legend: {
                    alignAsTable: true,
                },
                yaxis: [
                    {
                        format: 'short',
                        max: null,
                        min: 0,
                    },
                ],
                stack: false,
            },
            targets: [
                {
                    expr:
                        'irate(mysql_global_status_aborted_connects{instance=~"$instance"}[5m])',
                    legendFormat: 'Aborted Connects',
                },
                {
                    expr:
                        'irate(mysql_global_status_aborted_clients{instance=~"$instance"}[5m])',
                    legendFormat: 'Aborted Clients',
                },
                {
                    expr:
                        'irate(mysql_global_status_access_denied_errors{instance=~"$instance"}[5m])',
                    legendFormat: 'Access Denied',
                },
            ],
        },
        {
            title: 'Memory utilization',
            type: 'chart',
            gridPos: {
                h: 8,
                w: 8,
                x: 0,
                y: 0,
            },
            options: {
                decimals: 2,
                format: 'short',
                type: 'area',
                legend: {
                    alignAsTable: true,
                },
                yaxis: [
                    {
                        format: 'size',
                        max: null,
                        min: 0,
                    },
                ],
                stack: true,
            },
            targets: [
                {
                    expr:
                        'mysql_global_status_innodb_mem_adaptive_hash{instance=~"$instance"}',
                    legendFormat: 'innodb_mem_adaptive_hash',
                },
                {
                    expr:
                        'mysql_global_variables_innodb_buffer_pool_size{instance=~"$instance"}',
                    legendFormat: 'innodb_buffer_pool_size',
                },
                {
                    expr:
                        'mysql_global_variables_query_cache_size{instance=~"$instance"}',
                    legendFormat: 'query_cache_size',
                },
                {
                    expr:
                        'mysql_global_variables_key_buffer_size{instance=~"$instance"}',
                    legendFormat: 'key_buffer_size',
                },
                {
                    expr:
                        'mysql_global_variables_innodb_log_buffer_size{instance=~"$instance"}',
                    legendFormat: 'innodb_log_buffer_size',
                },
            ],
        },
    ],
    templating: {
        list: [
            {
                datasource: 'clustercontrol',
                multi: false,
                label: 'Host',
                name: 'instance',
                query: "host:$[nodes[nodetype='mysql' or nodetype='galera']]",
                optionValue: 'hostname',
                optionText: 'hostname',
                sort: 1,
                isInstancePicker: true,
            },
        ],
    },
    schemaVersion: 1,
};
export default dashboard;
