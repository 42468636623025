import {
    CcBackupConfigProps,
    CcBackupMethod,
    CcBackupStatus,
    CcBackupVerifiedStatus,
} from './CcBackup';

export type CcBackupMetadataProps = {
    id: number;
    job_id: number;
    cid: number;
    parent_id: number;
    chain_up: number;
    children: number;
    backup: [];
    backup_host: string;
    storage_host: string;
    root_dir: string;
    method: string;
    status: CcBackupStatus;
    created: string;
    finished: string;
    use_for_pitr: boolean;
    encrypted: boolean;
    config: CcBackupConfigProps;
    verified?: {
        message: string;
        status: CcBackupVerifiedStatus;
        verified_time: string;
    };
};

export default class CcBackupMetadata {
    public readonly id: number;
    public readonly jobId: number;
    public readonly cid: number;
    public readonly parentId: number;
    public readonly chainUp: number;
    public readonly children: number;
    public readonly backup: any[];
    public readonly backupHost: string;
    public readonly storageHost: string;
    public readonly rootDir: string;
    public readonly method: CcBackupMethod;
    public readonly status: CcBackupStatus;
    public readonly created: string;
    public readonly finished: string;
    public readonly encrypted: boolean;
    public readonly verified: boolean;
    public readonly useForPitr: boolean;
    public readonly config: CcBackupConfigProps;

    constructor(props: CcBackupMetadataProps) {
        // super(props);
        this.id = props.id;
        this.jobId = props.job_id;
        this.cid = props.cid;
        this.parentId = props.parent_id;
        this.chainUp = props.chain_up;
        this.children = props.children;
        this.backup = props.backup || [];
        this.backupHost = props.backup_host;
        this.storageHost = props.storage_host;
        this.rootDir = props.root_dir;
        this.method = props.method as CcBackupMethod;
        this.status = props.status;
        this.created = props.created;
        this.finished = props.finished;
        this.encrypted = props.encrypted;
        this.verified =
            props.verified?.status === CcBackupVerifiedStatus.VERIFIED;
        this.useForPitr = props.use_for_pitr;
        this.config = props.config;
    }

    public getDuration(): number {
        let duration = 0;
        if (this.created) {
            if (
                [
                    CcBackupStatus.STATUS_COMPLETED,
                    CcBackupStatus.STATUS_FAILED,
                ].includes(this.status) &&
                this.finished
            ) {
                duration =
                    new Date(this.finished).getTime() -
                    new Date(this.created).getTime();
            } else {
                duration =
                    new Date().getTime() - new Date(this.created).getTime();
            }
        }

        return duration;
    }

    public getKey(): string {
        return `${this.id}`;
    }

    public getClusterKey(): string {
        return `${this.cid}`;
    }

    public getControllerKey(): string {
        return '';
    }

    public getSize(): number {
        return this.backup.reduce(
            (backupSize: number, item: { files: [] }) =>
                backupSize +
                    item?.files.reduce(
                        (size: number, file: { size: number }) =>
                            size + file.size,
                        0
                    ) || 0,
            0
        );
    }

    public canUseForPitr(): boolean {
        return (
            this.useForPitr === true ||
            this.config?.mysqldump_pitr_compat === true
        );
    }
}
