
import CmonFunction, { CmonFunctionProps } from './CmonFunction';


export interface CmonStackFunctionProps  extends CmonFunctionProps {

}



export default class CmonStackFunction extends CmonFunction {


    constructor(props: CmonStackFunctionProps) {
        super(props);

    }

}
