import CmonRequestService, {
    CmonRequestServiceResponseData,
} from './cmon/CmonRequestService';
import { ScheduleBackupRequestData } from './cmon/CmonBackupService';

export default class CcImperativeService extends CmonRequestService {
    public static module(): string {
        return 'imperative';
    }

    public static async executeScript(
        data: ScheduleBackupRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('executeScript', data, opts);
    }
}
