import { DatePicker, Form } from 'antd';
import React from 'react';
import { FormItemProps } from 'antd/lib/form';

export default BackupRestoreTimePickerField;
type BackupRestoreTimePickerFieldProps = FormItemProps & {};

function BackupRestoreTimePickerField({
    ...rest
}: BackupRestoreTimePickerFieldProps) {
    return (
        <Form.Item
            name="pitrTime"
            label={<span>Restore time</span>}
            rules={[
                {
                    required: true,
                    message: 'Please select date and time',
                },
            ]}
            {...rest}
        >
            <DatePicker
                style={{
                    width: '100%',
                }}
                showTime={true}
                format="YYYY-MM-DD HH:mm:ss"
                placeholder="Select Time"
            />
        </Form.Item>
    );
}
