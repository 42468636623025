import React, { useState } from 'react';
import { Form } from 'antd';
import WizardForm from '@severalnines/bar-frontend-components/build/lib/Navigation/WizardForm';
import WizardFormConfiguration from '@severalnines/bar-frontend-components/build/lib/Navigation/Wizard/WizardFormConfiguration';
import CcCluster from '../../../../services/models/CcCluster';

enum HAProxyFormStaps {
    WHERE_TO_INS = 'WhereToInstall',
    INSTALL_SETTINGS = 'InstallationSettings',
    ADV_SETTINGS = 'AdvancedSettings',
    SERVER_INS = 'ServerInstances',
    PREVIEW = 'preview',
}

export type PgpoolWizardFormProps = {
    onCancel?: () => void;
    cluster?: CcCluster;
};

const PgpoolWizardForm = ({ onCancel, cluster }: PgpoolWizardFormProps) => {
    const [form] = Form.useForm();
    const [loading] = useState(false);

    const handleSubmit = async () => {};
    return (
        <WizardFormConfiguration
            form={form}
            onSubmit={handleSubmit}
            loading={loading}
            onCancel={onCancel}
            steps={[
                <WizardForm.Step
                    key={HAProxyFormStaps.WHERE_TO_INS}
                    title="Authentication"
                    subTitle=" "
                >
                    <div></div>
                </WizardForm.Step>,
                <WizardForm.Step
                    key={HAProxyFormStaps.INSTALL_SETTINGS}
                    title="Advanced settings"
                    subTitle=" "
                >
                    <div></div>
                </WizardForm.Step>,
                <WizardForm.Step
                    key={HAProxyFormStaps.INSTALL_SETTINGS}
                    title="Node configuration"
                    subTitle=" "
                >
                    <div></div>
                </WizardForm.Step>,

                <WizardForm.Step
                    key={HAProxyFormStaps.PREVIEW}
                    title="Preview"
                    subTitle=" "
                >
                    <div></div>
                </WizardForm.Step>,
            ]}
        />
    );
};

export default PgpoolWizardForm;
