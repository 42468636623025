import React from 'react';
import { Typography } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import AuthWrapper from './AuthWrapper';
import './ResetPasswordPage.less';

const { Paragraph } = Typography;
export default ResetPasswordPage;

export type ResetPasswordPageProps = {};

function ResetPasswordPage() {
    return (
        <AuthWrapper>
            <div className="ResetPasswordPage">
                <div className="ResetPasswordPage_body">
                    <Link to="/login" className="ResetPasswordPage_back">
                        <ArrowLeftOutlined />
                    </Link>
                    <p className="ResetPasswordPage_title">
                        Forgot your password?
                    </p>
                    <Paragraph>
                        Use S9S tool in order to change password from Command
                        Line.
                    </Paragraph>
                    <Paragraph>
                        <pre>
                            <Paragraph
                                className="ResetPasswordPage_code"
                                copyable
                            >
                                s9s user --change-password
                                --new-password=YOURNEWPASSWORD USERNAME
                            </Paragraph>
                        </pre>
                    </Paragraph>
                </div>
                <Paragraph>
                    For more details, please refer to the{' '}
                    <a
                        href="https://docs.severalnines.com/docs/clustercontrol/user-guide-cli/"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Severalnines User Guide
                    </a>
                    .
                </Paragraph>
            </div>
        </AuthWrapper>
    );
}
