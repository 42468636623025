import React, { useState } from 'react';
import FloatingContent from '@severalnines/bar-frontend-components/build/lib/Layout/FloatingContent';
import { Button } from 'antd';
import FeedbackForm from '@severalnines/bar-frontend-components/build/lib/General/FeedbackForm';
import CcUser from './services/models/CcUser';
import { FeedbackIcon } from './common/icons/icons';
import { AppConfig } from './AppConfig';

export default Feedback;

export type FeedbackProps = {
    user?: CcUser;
};

function Feedback({ user }: FeedbackProps) {
    const [feedbackOpen, setFeedbackOpen] = useState(false);
    const handleFeedbackFormVisibleChange = (visible: boolean) => {
        setFeedbackOpen(visible);
    };
    const handleFeedbackFormSubmit = async ({
        feedbackType,
        feedbackMessage,
    }: any) => {
        const data: any = {
            name: user?.userName || '',
            email: user?.emailAddress || '',
            uiv: `v:${AppConfig.VERSION} b:${AppConfig.BUILD_NUMBER} sha:${AppConfig.GIT_SHA}`,
            category: feedbackType,
            location: 'cc2',
            feedback: feedbackMessage,
        };
        await sendForm(data);
    };

    return (
        <FloatingContent>
            <FeedbackForm
                visible={feedbackOpen}
                onVisibleChange={handleFeedbackFormVisibleChange}
                onSubmit={handleFeedbackFormSubmit}
                feedbackTypes={['General Feedback']}
                showError={true}
                showThanks={true}
            >
                <Button
                    type="primary"
                    style={{
                        marginRight: '20px',
                        marginBottom: '20px',
                    }}
                >
                    <FeedbackIcon />
                    <span>Give us feedback</span>
                </Button>
            </FeedbackForm>
        </FloatingContent>
    );
}

async function sendForm(data: any) {
    const formBody: string = Object.keys(data)
        .map((k) => encodeURIComponent(k) + '=' + encodeURIComponent(data[k]))
        .join('&');

    await fetch('https://severalnines.com/service/feedback.php', {
        method: 'POST',
        mode: 'no-cors',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
        },
        body: formBody,
    });
}
