import React from 'react';
import { FormInstance } from 'antd/lib/form';
import SpaceDescriptions from '../../../../../../common/Layout/SpaceDescriptions';
import AppDivider from '../../../../../../common/AppDivider';

export type ProxySQLRulesPreviewProps = {
    form: FormInstance;
};

export default ProxySQLRulesPreview;

function ProxySQLRulesPreview({ form }: ProxySQLRulesPreviewProps) {
    const {
        rulesConfiguration,
        rulesType,
        rulesSetting,
        rulesAdvSetting,
    } = form.getFieldsValue(true);
    return (
        <div>
            <SpaceDescriptions
                direction="vertical"
                title="Configuration"
                size="small"
                alignItems="right"
            >
                <SpaceDescriptions.Item label="Rule ID" labelStrong>
                    {rulesConfiguration.ruleId}
                </SpaceDescriptions.Item>
                {rulesConfiguration.proxyAddress && (
                    <SpaceDescriptions.Item label="Proxy address" labelStrong>
                        {rulesConfiguration.proxyAddress}
                    </SpaceDescriptions.Item>
                )}
                {rulesConfiguration.proxyPort && (
                    <SpaceDescriptions.Item label="Proxy port" labelStrong>
                        {rulesConfiguration.proxyPort}
                    </SpaceDescriptions.Item>
                )}
                {rulesConfiguration.username && (
                    <SpaceDescriptions.Item label="Username" labelStrong>
                        {rulesConfiguration.username}
                    </SpaceDescriptions.Item>
                )}
                <SpaceDescriptions.Item
                    label="Destination hostgroup"
                    labelStrong
                >
                    {`${rulesConfiguration.hostgroup}`}
                </SpaceDescriptions.Item>
                {rulesConfiguration.clientAddress && (
                    <SpaceDescriptions.Item label="Client address" labelStrong>
                        {rulesConfiguration.clientAddress}
                    </SpaceDescriptions.Item>
                )}
                {rulesConfiguration.schemaName && (
                    <SpaceDescriptions.Item label="Schema name" labelStrong>
                        {rulesConfiguration.schemaName}
                    </SpaceDescriptions.Item>
                )}
            </SpaceDescriptions>
            <AppDivider />
            <SpaceDescriptions
                direction="vertical"
                title="Rule Type"
                size="small"
                alignItems="right"
            >
                {rulesType.ruleType && (
                    <SpaceDescriptions.Item label="Rule Type" labelStrong>
                        {rulesType.ruleType}
                    </SpaceDescriptions.Item>
                )}
                {rulesType.ruleType === 'digestType' && rulesType.digest && (
                    <SpaceDescriptions.Item label="Digest" labelStrong>
                        {rulesType.digest}
                    </SpaceDescriptions.Item>
                )}
                {rulesType.ruleType === 'matchDigestType' &&
                    rulesType.matchDigest && (
                        <SpaceDescriptions.Item
                            label="Match Digest"
                            labelStrong
                        >
                            {rulesType.matchDigest}
                        </SpaceDescriptions.Item>
                    )}
                {rulesType.ruleType === 'matchPatternType' &&
                    rulesType.matchPattern && (
                        <SpaceDescriptions.Item
                            label="Match pattern"
                            labelStrong
                        >
                            {rulesType.matchPattern}
                        </SpaceDescriptions.Item>
                    )}
                {rulesType.ruleType === 'matchPatternType' &&
                    rulesType.replacePattern && (
                        <SpaceDescriptions.Item
                            label="Replace pattern"
                            labelStrong
                        >
                            {rulesType.replacePattern}
                        </SpaceDescriptions.Item>
                    )}
                {rulesType.comments && (
                    <SpaceDescriptions.Item label="Comments" labelStrong>
                        {`${rulesType.comments}`}
                    </SpaceDescriptions.Item>
                )}
            </SpaceDescriptions>
            <AppDivider />
            <SpaceDescriptions
                direction="vertical"
                title="Setting"
                size="small"
                alignItems="right"
            >
                {rulesSetting.active && (
                    <SpaceDescriptions.Item label="Active" labelStrong>
                        {`${rulesSetting.active}`}
                    </SpaceDescriptions.Item>
                )}
                {rulesSetting.apply && (
                    <SpaceDescriptions.Item label="Apply" labelStrong>
                        {`${rulesSetting.apply}`}
                    </SpaceDescriptions.Item>
                )}
                {rulesSetting.timeout !== undefined && (
                    <SpaceDescriptions.Item label="Timeout" labelStrong>
                        {`${rulesSetting.timeout}`}
                    </SpaceDescriptions.Item>
                )}
                {rulesSetting.cacheTTL !== undefined && (
                    <SpaceDescriptions.Item label="Cache TTL" labelStrong>
                        {`${rulesSetting.cacheTTL}`}
                    </SpaceDescriptions.Item>
                )}
                {rulesSetting.retries !== undefined && (
                    <SpaceDescriptions.Item label="Retries" labelStrong>
                        {`${rulesSetting.retries}`}
                    </SpaceDescriptions.Item>
                )}
            </SpaceDescriptions>
            <AppDivider />
            <SpaceDescriptions
                direction="vertical"
                title="Advanced setting"
                size="small"
                alignItems="right"
            >
                {rulesAdvSetting.delay !== undefined && (
                    <SpaceDescriptions.Item label="Delay" labelStrong>
                        {`${rulesAdvSetting.delay}`}
                    </SpaceDescriptions.Item>
                )}
                {rulesAdvSetting.negativePattern && (
                    <SpaceDescriptions.Item
                        label="Negative match pattern"
                        labelStrong
                    >
                        {`${rulesAdvSetting.negativePattern}`}
                    </SpaceDescriptions.Item>
                )}
                {rulesAdvSetting.mirrorHostGroup && (
                    <SpaceDescriptions.Item
                        label="Mirror hostgroup"
                        labelStrong
                    >
                        {`${rulesAdvSetting.mirrorHostGroup}`}
                    </SpaceDescriptions.Item>
                )}
                {rulesAdvSetting.mirrorFlagOut !== undefined && (
                    <SpaceDescriptions.Item label="Mirror flag out" labelStrong>
                        {`${rulesAdvSetting.mirrorFlagOut}`}
                    </SpaceDescriptions.Item>
                )}
                {rulesAdvSetting.flagIN !== undefined && (
                    <SpaceDescriptions.Item label="flagIN" labelStrong>
                        {`${rulesAdvSetting.flagIN}`}
                    </SpaceDescriptions.Item>
                )}
                {rulesAdvSetting.flagOut !== undefined && (
                    <SpaceDescriptions.Item label="flagOut" labelStrong>
                        {`${rulesAdvSetting.flagOut}`}
                    </SpaceDescriptions.Item>
                )}
                {rulesAdvSetting.reconnect && (
                    <SpaceDescriptions.Item label="Reconnect" labelStrong>
                        {`${rulesAdvSetting.reconnect}`}
                    </SpaceDescriptions.Item>
                )}
                {rulesAdvSetting.log && (
                    <SpaceDescriptions.Item label="Log" labelStrong>
                        {`${rulesAdvSetting.log}`}
                    </SpaceDescriptions.Item>
                )}
                {rulesAdvSetting.stickyConn !== undefined && (
                    <SpaceDescriptions.Item label="Sticky conn" labelStrong>
                        {`${rulesAdvSetting.stickyConn}`}
                    </SpaceDescriptions.Item>
                )}
                {rulesAdvSetting.multiplex !== undefined && (
                    <SpaceDescriptions.Item label="Multiplex" labelStrong>
                        {`${rulesAdvSetting.multiplex}`}
                    </SpaceDescriptions.Item>
                )}
                {rulesAdvSetting.okMessage && (
                    <SpaceDescriptions.Item label="Ok message" labelStrong>
                        {`${rulesAdvSetting.okMessage}`}
                    </SpaceDescriptions.Item>
                )}
                {rulesAdvSetting.errorMessage && (
                    <SpaceDescriptions.Item label="Error message" labelStrong>
                        {`${rulesAdvSetting.errorMessage}`}
                    </SpaceDescriptions.Item>
                )}
            </SpaceDescriptions>
        </div>
    );
}
