import CcAdvisorSchedule from '../../../services/models/CcAdvisorSchedule';
import { Space } from 'antd';
import TypographyText from '../../../common/TypographyText';
import AppDateFormat from '../../../common/AppDateFormat';
import React, { useEffect } from 'react';
import AppContentToggle, {
    AppContentToggleProps,
    CONTENT_TOGGLE_KEY,
} from '../../../common/AppContentToggle';

export default AdvisorResultLogsToggle;

type AdvisorResultLogsToggleProps = AppContentToggleProps & {
    schedule: CcAdvisorSchedule;
};

function AdvisorResultLogsToggle({
    schedule,
    open,
    ...rest
}: AdvisorResultLogsToggleProps) {
    const [activeKeys, setActiveKeys] = React.useState<string[]>([]);
    useEffect(() => {
        if (open) {
            setActiveKeys([CONTENT_TOGGLE_KEY]);
        } else {
            setActiveKeys([]);
        }
    }, [open]);
    return (
        <AppContentToggle
            activeKey={activeKeys}
            onChange={(keys) => {
                setActiveKeys(Array.isArray(keys) ? keys : [keys]);
            }}
            title={
                <Space>
                    <TypographyText strong={true}>Logs:</TypographyText>
                    {(schedule?.timestamp && (
                        <TypographyText muted={true}>
                            last scheduled execution:{' '}
                            <AppDateFormat fromNow={true}>
                                {schedule?.timestamp
                                    ? new Date(schedule.timestamp * 1000)
                                    : undefined}
                            </AppDateFormat>
                        </TypographyText>
                    )) ||
                        undefined}
                </Space>
            }
            ghost={true}
            {...rest}
        >
            <Space direction="vertical">
                {schedule?.messages?.map(({ message }, index) => (
                    <TypographyText key={index}>{message}</TypographyText>
                ))}
            </Space>
        </AppContentToggle>
    );
}
