import './AppMonacoEditor.less';
import { MonacoEditorProps } from 'react-monaco-editor/src/types';
import MonacoEditor from 'react-monaco-editor';
import { Skeleton } from 'antd';
import React from 'react';
export default AppMonacoEditor;
type AppMonacoEditorProps = MonacoEditorProps & {
    loading?: boolean;
};

function AppMonacoEditor({ loading = false, ...rest }: AppMonacoEditorProps) {
    return loading ? (
        <div className="AppMonacoEditor_SkeletonWrap">
            <Skeleton active={true} paragraph={{ rows: 9 }} />
        </div>
    ) : (
        <MonacoEditor theme="vs-light" {...rest} />
    );
}
