import React from 'react';
import BackupSchedulesList from '../components/Backups/BackupSchedulesList';
import AppPageWrapper from '../common/Layout/AppPageWrapper';

export default DebugBackupScheduleList;
export type DebugBackupScheduleListProps = {};

function DebugBackupScheduleList({ ...rest }: DebugBackupScheduleListProps) {
    return (
        <AppPageWrapper>
            <BackupSchedulesList />
        </AppPageWrapper>
    );
}
