import CmonLogMessage, {
    CmonLogMessageProps,
} from '../cmon/models/CmonLogMessage';
import { getArrayFromEnum } from '../../common/utils/enums';

export enum CcLogEntrySeverity {
    LOG_EMERG = 'LOG_EMERG',
    LOG_ALERT = 'LOG_ALERT',
    LOG_CRIT = 'LOG_CRIT',
    LOG_ERR = 'LOG_ERR',
    LOG_WARNING = 'LOG_WARNING',
    LOG_INFO = 'LOG_INFO',
    LOG_NOTICE = 'LOG_NOTICE',
    LOG_DEBUG = 'LOG_DEBUG',
}

export interface CcLogEntryProps extends CmonLogMessageProps {
    log_origins: any;
    log_specifics: any;
    severity: CcLogEntrySeverity;
}

export default class CcLogEntry extends CmonLogMessage {
    public readonly logOrigins: any;
    public readonly logSpecifics: any;
    public readonly severity: CcLogEntrySeverity;
    constructor(props: CcLogEntryProps) {
        super(props);
        this.logOrigins = props.log_origins;
        this.logSpecifics = props.log_specifics;
        this.severity = props.severity;
    }

    public getLogMessage() {
        return this.logSpecifics?.message_text || '';
    }

    public getKey(): string {
        return `${this.logId}`;
    }
    public getClusterKey(): string {
        return `${this.logSpecifics?.cluster_id}`;
    }
}

export function getLogSeverities(): CcLogEntrySeverity[] {
    return getArrayFromEnum<CcLogEntrySeverity>(CcLogEntrySeverity);
}
