
import CmonRequestService, {CmonRequestServiceResponseData} from './CmonRequestService';


export interface LoadScriptRequestData {
    filename?: string;
    [key: string]: any;
}

export interface SaveScriptRequestData {
    filename?: string;
    content?: string;
    tags?: string;
    [key: string]: any;
}

export interface CompileScriptRequestData {
    filename?: string;
    [key: string]: any;
}

export interface ExecuteScriptRequestData {
    filename?: string;
    arguments?: string;
    [key: string]: any;
}

export interface DirTreeRequestData {
    path?: string;
    showFiles?: boolean;
    [key: string]: any;
}

export interface ScheduleRequestData {
    schedule_id?: number;
    schedule?: string;
    arguments?: string;
    [key: string]: any;
}

export interface ChangeScheduleRequestData {
    schedule_id?: number;
    enable?: boolean;
    schedule?: string;
    arguments?: string;
    [key: string]: any;
}

export interface ScheduleResultsRequestData {
    filenames?: [];
    [key: string]: any;
}


export default class CmonImperativeService extends CmonRequestService {
    
    public static module(): string {
        return 'imperative';
    }
    
    public static async loadScript(
        data: LoadScriptRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('loadScript', data, opts);
    }

    public static async saveScript(
        data: SaveScriptRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('saveScript', data, opts);
    }

    public static async compileScript(
        data: CompileScriptRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('compileScript', data, opts);
    }

    public static async executeScript(
        data: ExecuteScriptRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('executeScript', data, opts);
    }

    public static async dirTree(
        data: DirTreeRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('dirTree', data, opts);
    }

    public static async schedule(
        data: ScheduleRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('schedule', data, opts);
    }

    public static async changeSchedule(
        data: ChangeScheduleRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('changeSchedule', data, opts);
    }

    public static async scheduleResults(
        data: ScheduleResultsRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('scheduleResults', data, opts);
    }

}
