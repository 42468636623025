import React, { useContext, ReactNode } from 'react';
import './ProxySQLMonitor.less';
import { ResponsiveContext } from '@severalnines/bar-frontend-components/build/lib/Layout/Responsive';

import StatusFormat, {
    StatusFormatStatus,
} from '@severalnines/bar-frontend-components/build/lib/Format/StatusFormat';

import AppEmpty from '../../../../../../common/Feedback/AppEmpty';
import CcProxySqlServer, {
    CcProxySqlServerStatus,
} from '../../../../../../services/models/CcProxySqlServer';
import CcCluster from '../../../../../../services/models/CcCluster';
import AppTable from '../../../../../../common/DataDisplay/AppTable';
import { Link } from 'react-router-dom';
import SpaceWide from '../../../../../../common/SpaceWide';
import CcProxySqlNode from '../../../../../../services/models/CcProxySqlNode';
import AppSpin from '../../../../../../common/General/AppSpin';

export default ProxySQLMonitor;

export type ProxySQLMonitorProps = {
    cluster: CcCluster;
    node: CcProxySqlNode;
    serversTablesData:
        | {
              [key: string]: CcProxySqlServer[];
          }
        | undefined;
    loading: boolean;
};

function ProxySQLMonitor({
    serversTablesData,
    loading,
    cluster,
    node,
}: ProxySQLMonitorProps) {
    const { responsive }: any = useContext(ResponsiveContext);

    const columns = [
        {
            title: 'Host',
            key: 'hostname',
            dataIndex: 'hostname',
        },
        {
            title: 'Status',
            key: 'status',
            dataIndex: 'status',
            render: (record: CcProxySqlServerStatus) => getStatus(record),
        },
        {
            title: 'Conn. Used',
            key: 'ConnUsed',
            dataIndex: 'ConnUsed',
            render: (record: string) => `${record}`,
        },
        {
            title: 'Conn. Free',
            key: 'ConnFree',
            dataIndex: 'ConnFree',
            render: (record: string) => `${record}`,
        },
        {
            title: 'Conn. Err',
            key: 'ConnERR',
            dataIndex: 'ConnERR',
            render: (record: string) => `${record}`,
        },
        {
            title: 'Queries',
            key: 'Queries',
            dataIndex: 'Queries',
            render: (record: string) => `${record}`,
        },
        {
            title: 'Data Sent',
            key: 'BytesDataSent',
            dataIndex: 'BytesDataSent',
            render: (record: string) => `${record}`,
        },
        {
            title: 'Data Recv',
            key: 'BytesDataRecv',
            dataIndex: 'BytesDataRecv',
            render: (record: string) => `${record}`,
        },
        {
            title: 'Latency',
            key: 'Latency',
            dataIndex: 'Latency',
            render: (record: string) => `${record} µs`,
        },
    ];

    return (
        <div className="ProxySQLMonitor">
            <AppSpin spinning={loading}>
                {serversTablesData ? (
                    Object.keys(serversTablesData).map((objectKey) => (
                        <div
                            key={objectKey}
                            className="ProxySQLMonitor__Tables"
                        >
                            <h4>{`Hostgroup ${objectKey} (${
                                serversTablesData[`${objectKey}`][0]
                                    .hostgroupName
                            })`}</h4>
                            <AppTable
                                size="small"
                                dataSource={serversTablesData[`${objectKey}`]}
                                rowKey={(record: CcProxySqlServer) =>
                                    `${objectKey} ${record.hostname}`
                                }
                                columns={columns}
                                responsive={responsive}
                                pagination={false}
                            />
                        </div>
                    ))
                ) : (
                    <AppEmpty
                        loading={loading}
                        description="There is no servers yet."
                    />
                )}

                <SpaceWide
                    className="ProxySQLMonitor__Link"
                    direction="vertical"
                    align="center"
                    size={5}
                >
                    Go to the Dashboards for more detailed data and graphics
                    <Link
                        to={`/clusters/${cluster?.clusterId}/dashboard?instance=${node?.hostname}&slug=proxysql-overview`}
                        target="_blank"
                    >
                        Go to Dashboards
                    </Link>
                </SpaceWide>
            </AppSpin>
        </div>
    );
}

function getStatus(type: CcProxySqlServerStatus): ReactNode {
    switch (type) {
        case CcProxySqlServerStatus.ONLINE:
            return (
                <StatusFormat status={StatusFormatStatus.success} text={type} />
            );
        case CcProxySqlServerStatus.SHUNNED:
            return (
                <StatusFormat status={StatusFormatStatus.warning} text={type} />
            );
        case CcProxySqlServerStatus.OFFLINE_SOFT:
            return (
                <StatusFormat status={StatusFormatStatus.error} text={type} />
            );
        case CcProxySqlServerStatus.OFFLINE_HARD:
            return (
                <StatusFormat status={StatusFormatStatus.error} text={type} />
            );

        default:
            return (
                <StatusFormat status={StatusFormatStatus.unknown} text={type} />
            );
    }
}
