import React, { useEffect } from 'react';
import CcUser from '../../services/models/CcUser';
import PermissionsInfo from './PermissionsInfo';
import useGroupTree from './useGroupTree';
import AppSpin from '../../common/General/AppSpin';

export default UserPermissionsInfo;

export type UserPermissionsInfoProps = {
    user: CcUser;
};

function UserPermissionsInfo({ user }: UserPermissionsInfoProps) {
    const { getAcl, refresh, loading } = useGroupTree({
        name: 'tree-from-user-create-form',
        groupName: user.getGroup().groupName,
        userName: user.userName,
    });

    useEffect(() => {
        (async () => await refresh())();
    }, []);

    return (
        <AppSpin spinning={loading}>
            <PermissionsInfo
                permissions={getAcl()}
                canManageUsers={user.isSuperUser()}
            />
        </AppSpin>
    );
}
