import React, { useEffect, useState } from 'react';
import { CmonJobInstanceCommand } from '../../../services/cmon/models/CmonJobInstance';
import BackupToolPgBackrestSetup from './BackupToolPgBackrestSetup';
import CcCluster from '../../../services/models/CcCluster';
import { FormInstance } from 'antd/lib/form';
import CcJob, { CcJobStatus } from '../../../services/models/CcJob';
import BackupToolPBMSetup from './BackupToolPBMSetup';
import BackupToolPgBackrestReconfigure from './BackupToolPgBackrestReconfigure';

export default BackupToolSetupInput;

export enum BackupToolSetupInputStatus {
    UNINSTALLED = 'UNINSTALLED',
    PROGRESS = 'PROGRESS',
    SUCCESS = 'SUCCESS',
    FAILED = 'FAILED',
    RECONFIGURE = 'RECONFIGURE',
}

export type BackupToolSetupInputProps = {
    cluster: CcCluster;
    form: FormInstance;
    value?: BackupToolSetupInputStatus;
    reconfigureMessage?: React.ReactNode;
    onChange?: (value: BackupToolSetupInputStatus | undefined) => void;
    required?: boolean;
    onSuccess?: (job: CcJob) => void;
    command:
        | CmonJobInstanceCommand.PGBACKREST
        | CmonJobInstanceCommand.PBMAGENT;
};

function BackupToolSetupInput({
    form,
    value,
    onChange,
    reconfigureMessage,
    required = false,
    onSuccess,
    command,
    ...rest
}: BackupToolSetupInputProps) {
    const [status, setStatus] = useState<
        BackupToolSetupInputStatus | undefined
    >(() => {
        if (value === BackupToolSetupInputStatus.SUCCESS) {
            return BackupToolSetupInputStatus.SUCCESS;
        } else if (value || !!reconfigureMessage) {
            // @todo test this condition
            return BackupToolSetupInputStatus.RECONFIGURE;
        } else if (required) {
            return BackupToolSetupInputStatus.UNINSTALLED;
        }
        return undefined;
    });
    const handleFormShow = () => {
        setStatus(BackupToolSetupInputStatus.PROGRESS);
    };
    const handleFinish = (job: CcJob) => {
        if ([CcJobStatus.FAILED, CcJobStatus.ABORTED].includes(job.status)) {
            setStatus(BackupToolSetupInputStatus.FAILED);
        } else if (job.status === CcJobStatus.FINISHED) {
            onSuccess?.(job);
            form.setFieldsValue({
                __backupToolJobId__: job.jobId,
            });
            setStatus(BackupToolSetupInputStatus.SUCCESS);
        }
    };

    const handleCancel = () => {
        setStatus(undefined);
    };

    const handleReset = () => {
        setStatus(undefined);
    };

    useEffect(() => {
        onChange?.(status);
    }, [status]);
    useEffect(() => {
        // @todo test this condition
        if (value !== status) {
            setStatus(status);
        }
    }, [value]);

    if (command === CmonJobInstanceCommand.PGBACKREST) {
        if (reconfigureMessage) {
            return (
                <BackupToolPgBackrestReconfigure
                    form={form}
                    onFinish={handleFinish}
                    onReset={handleReset}
                    {...rest}
                />
            );
        }
        return (
            <BackupToolPgBackrestSetup
                form={form}
                onFormShow={handleFormShow}
                onCancel={handleCancel}
                onFinish={handleFinish}
                onReset={handleReset}
                {...rest}
            />
        );
    } else if (command === CmonJobInstanceCommand.PBMAGENT) {
        return (
            <BackupToolPBMSetup
                form={form}
                onFormShow={handleFormShow}
                onCancel={handleCancel}
                onFinish={handleFinish}
                onReset={handleReset}
                {...rest}
            />
        );
    }
    return null;
}
