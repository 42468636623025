// Can get ideas for dashboard from
// https://github.com/severalnines/mssql_exporter/blob/main/metrics.json
const dashboard = {
    title: 'MSSQL Overview',
    meta: {
        slug: 'mssql-overview',
        supportClusterTypes: ['mssql_single', 'mssql_ao_async'],
    },
    panels: [
        {
            title: 'Instances up',
            type: 'chart',
            gridPos: {
                h: 4,
                w: 8,
                x: 0,
                y: 0,
            },
            options: {
                decimals: 0,
                format: 'number',
            },
            targets: [
                {
                    expr: 'mssql_up{instance=~"$instance"}',
                    legendFormat: 'Instances up',
                },
            ],
        },
        {
            title: 'Exceptions',
            type: 'chart',
            gridPos: {
                h: 4,
                w: 8,
                x: 0,
                y: 0,
            },
            options: {
                decimals: 0,
                format: 'number',
            },
            targets: [
                {
                    expr: 'mssql_exceptions{instance=~"$instance"}',
                    legendFormat: 'Exceptions',
                },
            ],
        },
        {
            title: 'Timeouts',
            type: 'chart',
            gridPos: {
                h: 4,
                w: 8,
                x: 0,
                y: 0,
            },
            options: {
                decimals: 0,
                format: 'number',
            },
            targets: [
                {
                    expr: 'mssql_timeouts{instance=~"$instance"}',
                    legendFormat: 'Timeouts',
                },
            ],
        },
        {
            title: 'Deadlocks',
            type: 'chart',
            gridPos: {
                h: 4,
                w: 8,
                x: 0,
                y: 0,
            },
            options: {
                decimals: 0,
                format: 'number',
            },
            targets: [
                {
                    expr: 'mssql_deadlocks{instance=~"$instance"}',
                    legendFormat: 'Deadlocks',
                },
            ],
        },
        {
            title: 'Running processes',
            type: 'chart',
            gridPos: {
                h: 7,
                w: 8,
                x: 0,
                y: 0,
            },
            options: {
                type: 'line',
                legend: {
                    alignAsTable: true,
                },
            },
            targets: [
                {
                    expr:
                        'irate(mssql_process_status{instance=~"$instance",status="running"}[5m])',
                    legendFormat: 'Running processes',
                },
            ],
        },
        {
            title: 'Background processes',
            type: 'chart',
            gridPos: {
                h: 7,
                w: 8,
                x: 0,
                y: 0,
            },
            options: {
                type: 'line',
                legend: {
                    alignAsTable: true,
                },
            },
            targets: [
                {
                    expr:
                        'irate(mssql_process_status{instance=~"$instance",status="background"}[5m])',
                    legendFormat: 'Background processes',
                },
            ],
        },
        {
            title: 'Sleeping processes',
            type: 'chart',
            gridPos: {
                h: 7,
                w: 8,
                x: 0,
                y: 0,
            },
            options: {
                type: 'line',
                legend: {
                    alignAsTable: true,
                },
            },
            targets: [
                {
                    expr:
                        'irate(mssql_process_status{instance=~"$instance",status="sleeping"}[5m])',
                    legendFormat: 'Sleeping processes',
                },
            ],
        },
        {
            title: 'Runnable processes',
            type: 'chart',
            gridPos: {
                h: 7,
                w: 8,
                x: 0,
                y: 0,
            },
            options: {
                type: 'line',
                legend: {
                    alignAsTable: true,
                },
            },
            targets: [
                {
                    expr:
                        'irate(mssql_process_status{instance=~"$instance",status="runnable"}[5m])',
                    legendFormat: 'Runnable processes',
                },
            ],
        },
    ],
    templating: {
        list: [
            {
                datasource: 'clustercontrol',
                multi: false,
                label: 'Host',
                name: 'instance',
                query: "host:$[nodes[nodetype='mssql']]",
                optionValue: 'hostname',
                optionText: 'hostname',
                sort: 1,
                isInstancePicker: true,
            },
        ],
    },
};
export default dashboard;
