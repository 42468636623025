import React, { useState } from 'react';
import { getCloudServiceName } from './CloudCredentialsWizard';
import ModalDefaultForm from '../../../common/ModalDefaultForm';
import CcCloudCredentials from '../../../services/models/CcCloudCredentials';
import useForm from 'antd/lib/form/hooks/useForm';
import {
    CloudCredentialsFormFields,
    getCredentialsItemFormValues,
    getFormFieldsCredentialsDTO,
} from './CloudCredentialsWizardForm';
import CmonCloudService from '../../../services/cmon/CmonCloudService';
import {
    notifyError,
    notifyOperationSuccess,
    NotifyType,
} from '../../Notifications/uiNotification';
import { Form } from 'antd';
import SpaceWide from '../../../common/SpaceWide';
import FormFooter from '../../../common/FormFooter';
import CloudCredentialsAuthenticationForm from './CloudCredentialsAuthenticationForm';
import AppSpin from '../../../common/General/AppSpin';

export default CloudCredentialsModalEdit;

export type CloudEditCredentialsModalProps = {
    credentials: CcCloudCredentials;
    onCancel?: () => void;
    onSuccess?: () => void;
};

function CloudCredentialsModalEdit({
    credentials,
    onCancel,
    onSuccess,
}: CloudEditCredentialsModalProps) {
    const [form] = useForm<CloudCredentialsFormFields>();
    const [loading, setLoading] = useState<boolean>(false);

    const handleCancel = () => {
        onCancel?.();
    };

    const handleSubmit = async () => {
        const dto = getFormFieldsCredentialsDTO(
            form.getFieldsValue(true),
            credentials.provider
        );
        if (dto) {
            try {
                setLoading(true);
                await CmonCloudService.updateCredentials(dto);
                notifyOperationSuccess({
                    type: NotifyType.TOAST,
                    title: 'Edits saved successfully',
                    content: '',
                });
                setLoading(false);
                onSuccess?.();
            } catch (e) {
                setLoading(false);
                notifyError({
                    content: e.message,
                });
            }
        }
    };

    return (
        <ModalDefaultForm
            title={`Edit ${
                getCloudServiceName(credentials.provider) ||
                'a cloud service provider'
            }`}
            form={form}
            footer={[]}
            onCancel={handleCancel}
            bodyStyle={{}}
            defaultVisible={true}
        >
            <AppSpin spinning={loading}>
                <Form
                    form={form}
                    onFinish={handleSubmit}
                    layout="vertical"
                    initialValues={getCredentialsItemFormValues(credentials)}
                >
                    <SpaceWide justify="space-between" direction="vertical">
                        <CloudCredentialsAuthenticationForm
                            form={form}
                            type={credentials.provider}
                        />
                        <FormFooter
                            showSubmitButton
                            submitButtonText="Save"
                            showCancelButton
                            onCancel={handleCancel}
                        />
                    </SpaceWide>
                </Form>
            </AppSpin>
        </ModalDefaultForm>
    );
}
