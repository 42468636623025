import React, { useEffect, useState } from 'react';
import { Button, Form, Input, Space, Tag } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { CcClusterTechnology } from '../../../services/models/CcCluster';
import DBPrivilegesSelect, {
    DB_PRIVILEGES,
    POSTGRESQL_DATA_PRIVILEGES,
    POSTGRESQL_DATABASE_PRIVILEGES,
    POSTGRESQL_USER_PRIVILEGES,
} from '../../../common/DataEntry/DBPrivilegesSelect';
import AppCard from '../../../common/DataDisplay/AppCard';
import DeleteButton from '../../../common/General/DeleteButton';
import FormItem from '../../../common/DataEntry/FormItem';
import SpaceWide from '../../../common/SpaceWide';

export default DatabasePrivilegesForm;

export type DatabasePrivilegesFormProps = {
    form: FormInstance;
    technology: CcClusterTechnology;
};

export type DBUserPrivilegesFieldType = {
    privileges: typeof DB_PRIVILEGES[number][];
    db: string;
};

function DatabasePrivilegesForm({
    form,
    technology,
    ...rest
}: DatabasePrivilegesFormProps) {
    return (
        <Form.List name="privileges" initialValue={[{}]}>
            {(fields, { add, remove }) => {
                return (
                    <SpaceWide
                        direction="vertical"
                        className="DatabasePrivilegesForm"
                    >
                        {fields.map((field, index) => {
                            return (
                                <DBUserPrivilegesItemForm
                                    form={form}
                                    key={field.key}
                                    index={index}
                                    technology={technology}
                                    onDelete={() => {
                                        remove(field.name);
                                    }}
                                    deletable={index > 0}
                                />
                            );
                        })}
                        <Button
                            type="link"
                            onClick={() => {
                                add({});
                            }}
                        >
                            Add privileges
                        </Button>
                    </SpaceWide>
                );
            }}
        </Form.List>
    );
}

type DBUserPrivilegesItemProps = {
    form: FormInstance;
    index: number;
    technology: CcClusterTechnology;
    onDelete?: () => void;
    deletable?: boolean;
    listName?: string;
};

function DBUserPrivilegesItemForm({
    form,
    index,
    technology,
    onDelete,
    deletable = true,
    listName = 'privileges',
    ...rest
}: DBUserPrivilegesItemProps) {
    const dbTableValidator = async (rule: any, value: string) => {
        if (!value) {
            throw new Error('Please type database.table name');
        }
        if (technology === CcClusterTechnology.TECHNOLOGY_POSTGRESQL) {
            const firstPrivilege = form.getFieldValue(listName)?.[index]
                ?.privileges?.[0];

            if (POSTGRESQL_DATA_PRIVILEGES.includes(firstPrivilege)) {
                if (
                    !/^[a-zA-Z_][a-zA-Z0-9_]*\.(?:[a-zA-Z_][a-zA-Z0-9_]*|\*)$/.test(
                        value
                    )
                ) {
                    throw new Error(
                        'Please type database.table name correctly'
                    );
                }
            } else if (
                POSTGRESQL_DATABASE_PRIVILEGES.includes(firstPrivilege) &&
                !/^[a-zA-Z0-9$_]+$/.test(value)
            ) {
                throw new Error('Please type database name correctly');
            }
        } else if (!/^.+\..+$/g.test(value)) {
            throw new Error('Please type database.table name correctly');
        }
    };

    return (
        <div className="DBUserPrivilegesItem">
            <AppCard bodyStyle={{ paddingRight: 10 }}>
                <Space>
                    <Form.Item shouldUpdate={true} noStyle={true}>
                        {() => {
                            const username = `${
                                form.getFieldValue('username') || 'username'
                            }@${form.getFieldValue('hostname') || 'hostname'}`;

                            const db = form.getFieldValue([
                                listName,
                                index,
                                'db',
                            ]);

                            const privileges =
                                form.getFieldValue([
                                    listName,
                                    index,
                                    'privileges',
                                ]) || [];

                            const firstPrivilege = privileges[0];

                            const showDbTable = !POSTGRESQL_USER_PRIVILEGES.includes(
                                firstPrivilege
                            );

                            const inputExtra = showDbTable &&
                                technology ===
                                    CcClusterTechnology.TECHNOLOGY_POSTGRESQL &&
                                db?.endsWith('*') && (
                                    <span>all tables in schema</span>
                                );

                            return (
                                <Space
                                    wrap={true}
                                    style={{
                                        borderRight:
                                            '1px solid rgba(0, 0, 0, 0.06)',
                                        paddingRight: 10,
                                    }}
                                >
                                    <FormItem
                                        withLessMarginBottom={true}
                                        name={[index, 'privileges']}
                                        rules={[
                                            {
                                                required: true,
                                                message:
                                                    'Please select privileges',
                                            },
                                        ]}
                                    >
                                        <DatabasePrivilegesSelect
                                            technology={technology}
                                        />
                                    </FormItem>

                                    {showDbTable ? (
                                        <Space>
                                            <span>on</span>
                                            <FormItem
                                                withLessMarginBottom={true}
                                                name={[index, 'db']}
                                                extra={
                                                    inputExtra
                                                        ? `all tables in schema`
                                                        : undefined
                                                }
                                                rules={[
                                                    {
                                                        pattern: /^.+\..+$/,
                                                        validator: dbTableValidator,
                                                    },
                                                ]}
                                            >
                                                <Input
                                                    placeholder={getDatabasePlaceholder(
                                                        technology,
                                                        firstPrivilege
                                                    )}
                                                    style={{ width: 200 }}
                                                />
                                            </FormItem>
                                            <span>to</span>
                                        </Space>
                                    ) : undefined}

                                    <span>{username}</span>
                                </Space>
                            );
                        }}
                    </Form.Item>
                    <Space>
                        <DeleteButton
                            disabled={!deletable}
                            onClick={onDelete}
                        />
                    </Space>
                </Space>
            </AppCard>
        </div>
    );
}

function getDatabasePlaceholder(
    technology: CcClusterTechnology,
    firstPrivilege?: string
) {
    switch (technology) {
        case CcClusterTechnology.TECHNOLOGY_MYSQL:
            return '<db.* | db.table>';
        case CcClusterTechnology.TECHNOLOGY_POSTGRESQL:
            if (firstPrivilege) {
                if (POSTGRESQL_DATABASE_PRIVILEGES.includes(firstPrivilege)) {
                    return '<db>';
                } else if (
                    POSTGRESQL_DATA_PRIVILEGES.includes(firstPrivilege)
                ) {
                    return '<db.table>';
                }
            }

            return '<db | db.table>';
    }
}

type DatabasePrivilegesSelectProps = {
    technology: CcClusterTechnology;
    onChange?: (value: DBUserPrivilegesFieldType['privileges']) => void;
    value?: DBUserPrivilegesFieldType['privileges'];
};

function DatabasePrivilegesSelect({
    value,
    onChange,
    technology,
}: DatabasePrivilegesSelectProps) {
    const [privileges, setPrivileges] = useState<
        typeof DB_PRIVILEGES[number][] | undefined
    >(value);
    const handlePrivilegesChange = (updatedPrivileges: string[] | any) => {
        const newPrivileges = updatedPrivileges.includes('ALL PRIVILEGES')
            ? ['ALL PRIVILEGES']
            : [...updatedPrivileges, ...(privileges || [])];
        setPrivileges(Array.from(new Set(newPrivileges)));
    };

    useEffect(() => {
        if (privileges) {
            onChange?.(privileges);
        }
    }, [privileges]);

    const removePrivilege = (privilege: typeof DB_PRIVILEGES[number]) => {
        setPrivileges(privileges?.filter((p) => p !== privilege));
    };

    return (
        <Space wrap={true}>
            <span>Grant</span>
            {privileges?.map((privilege) => (
                <Tag
                    closable={true}
                    key={privilege}
                    style={{ margin: 0 }}
                    onClose={() => {
                        removePrivilege(privilege);
                    }}
                >
                    {privilege}
                </Tag>
            ))}
            <DBPrivilegesSelect
                technology={technology}
                onChange={handlePrivilegesChange}
                value={privileges as any}
            />
        </Space>
    );
}
