// import { FetchRefreshFunctionParams } from '../../common/useFetch';
import useClusterList, {
    getFiltersForClient,
} from '../Clusters/useClusterList';
import { useCallback, useEffect, useReducer, useRef, useState } from 'react';
import {
    initialListState,
    listReducer,
    setListAll,
} from '../../common/listReducer';
import { getSortNumberFn } from '../../common/sorting';
import {
    AppState,
    AppStateClustersMap,
    AppStateGlobalFilters,
} from '../../appReducer';
import CcNode, { CcNodeType } from '../../services/models/CcNode';
import { arrayFilter, arrayPages } from '../../common/filtering';
import { useSelector } from 'react-redux';
import { FetchRefreshFunctionParams } from '../../common/useFetch';

type ListParams = {
    page?: number;
    pageSize?: number;
    filters?: Function[];
};

type FilterFunctionParams = ListParams & {
    // null can be used to reset the order and cancel sorting
    order?: null | ((a: any, b: any) => number);
    arr?: any[];
};

type RefreshFunctionParams = FetchRefreshFunctionParams;

type UseNodeListProps = ListParams & {
    name?: string | null;
    excludeGlobalFilters?: boolean;
    useCache?: boolean;
    order?: (a: any, b: any) => number;
};
export default function useNodeList({
    name,
    pageSize,
    order,
    excludeGlobalFilters,
    useCache = true,
    filters,
}: UseNodeListProps = {}) {
    const {
        error,
        loading,
        loaded,
        list: clusters,
        refresh: refreshClusters,
        filter: filterClusters,
        cancel,
    } = useClusterList({
        name: 'cluster-list-from-use-node-list',
        useCache,
        pageSize: 0,
    });
    const [globalFilters]: [
        AppStateGlobalFilters
    ] = useSelector(({ globalFilters }: AppState) => [globalFilters]);
    const [allList, setAllList] = useState<CcNode[]>();
    const [list, setList] = useState<CcNode[]>();
    const [total, setTotal] = useState<number>(0);
    const [
        {
            page: listPage,
            pageSize: listPageSize,
            order: listOrder,
            filters: listFilters,
        },
        listDispatch,
    ] = useReducer(listReducer, {
        ...initialListState,
        pageSize: pageSize || 0,
        order: order || getSortNumberFn('ascend', (x) => x.clusterid),
        filters: filters,
    });

    const gFilters = useRef<any>(
        !excludeGlobalFilters ? getFiltersForClient(globalFilters) : []
    );

    const filter = useCallback<(p?: FilterFunctionParams) => void>(
        ({
            page = listPage,
            pageSize = listPageSize,
            order = listOrder,
            filters = listFilters,
            arr = allList || [],
        } = {}) => {
            listDispatch(
                setListAll({
                    page,
                    pageSize,
                    order: order === null ? undefined : order,
                    filters,
                })
            );

            const filteredArr = arrayFilter({
                filters: [...(filters || []), ...gFilters.current],
                arr,
            });
            setList(
                arrayPages({
                    page,
                    pageSize,
                    order: order === null ? undefined : order,
                    arr: filteredArr,
                })
            );
            setTotal(filteredArr.length);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [
            listPage,
            listPageSize,
            listOrder,
            listFilters,
            gFilters.current,
            allList,
        ]
    );
    const preFilter = (props: FilterFunctionParams = {}) => {
        filterClusters();
        const { page, pageSize, order, filters } = props;
        listDispatch(
            setListAll({
                page,
                pageSize,
                order: order === null ? undefined : order,
                filters,
            })
        );
        filter(props);
    };

    const refresh = useCallback<(p?: RefreshFunctionParams) => Promise<void>>(
        async ({
            page = listPage,
            pageSize = listPageSize,
            order = listOrder,
            filters = listFilters,
            ...rest
        } = {}) => {
            listDispatch(setListAll({ page, pageSize, order, filters }));
            await refreshClusters({
                filters: gFilters.current,
                ...rest,
            });
        },
        [gFilters.current]
    );
    useEffect(() => {
        if (clusters) {
            const all = clusters.reduce(
                (result: CcNode[], currentCluster) =>
                    result.concat(
                        (currentCluster.nodes.filter(
                            (n) => !n.isType(CcNodeType.CONTROLLER)
                        ) as CcNode[]) || []
                    ),
                []
            );
            setAllList(all);

            filter({
                arr: all,
            });
        }
    }, [clusters]);
    return {
        error,
        loading,
        loaded,
        list,
        refresh,
        total,
        filter: preFilter,
        cancel,
        page: listPage,
        pageSize: listPageSize,
    };
}
