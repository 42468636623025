import './UserProfilePage.less';
import React from 'react';
import UserInfo from './UserInfo';
import SpaceWide from '../../common/SpaceWide';
import { AppState, AppStateUser } from '../../appReducer';
import { useSelector } from 'react-redux';
import { Space } from 'antd';
import UserSettingsButton from './UserSettingsButton';
import UserChangePasswordButton from './UserChangePasswordButton';

export default UserProfilePage;
export type UserProfilePageProps = {};

function UserProfilePage({ ...rest }: UserProfilePageProps) {
    const [user]: [AppStateUser] = useSelector(({ user }: AppState) => [user]);
    return user ? (
        <SpaceWide
            align="center"
            direction="vertical"
            className="UserProfilePage"
            size={40}
        >
            <UserInfo user={user} size="large" />
            <Space direction="vertical" align="center">
                <UserSettingsButton user={user} type="primary" />
                <UserChangePasswordButton user={user} type="link" />
            </Space>
        </SpaceWide>
    ) : null;
}
