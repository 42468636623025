import React from 'react';
import { CcSnapshotRepositoryType } from '../../services/models/CcSnapshotRepository';
import { CloudIcon, NodesIcon } from '../../common/icons/icons';
import { Space } from 'antd';
import { SpaceProps } from 'antd/lib/space';

export default SnapshotRepositoryType;
export type SnapshotRepositoryTypeProps = SpaceProps & {
    type: CcSnapshotRepositoryType;
};

function SnapshotRepositoryType({
    type,
    children,
    ...rest
}: SnapshotRepositoryTypeProps) {
    return (
        <Space>
            {getSnapshotRepositoryIcon(type)}
            {children || getSnapshotRepositoryTypeName(type)}
        </Space>
    );
}

export function getSnapshotRepositoryTypeName(type: CcSnapshotRepositoryType) {
    switch (type) {
        case CcSnapshotRepositoryType.S3:
            return 'S3 compatible';
        case CcSnapshotRepositoryType.FS:
            return 'File system';
    }
}

export function getSnapshotRepositoryIcon(type?: CcSnapshotRepositoryType) {
    switch (type) {
        case CcSnapshotRepositoryType.S3:
            return <CloudIcon />;
        default:
            return <NodesIcon />;
    }
}
