import SpaceDescriptions from '../Layout/SpaceDescriptions';
import { Button, DatePicker, Divider, Form, Space } from 'antd';
import InlineSelect from './InlineSelect';
import React from 'react';
import { useForm } from 'antd/lib/form/Form';
import moment from 'moment-timezone';
import DurationFormat from '@severalnines/bar-frontend-components/build/lib/Format/DurationFormat';
import SpaceWide from '../SpaceWide';

const DAY_SECONDS = 86400;
const DEFAULT_TIME_INTERVALS = [
    60 * 15, // 15 mins
    60 * 30, // 30 mins
    60 * 45, // 45 mins
    3600, // hour
    DAY_SECONDS, // day
    DAY_SECONDS * 7, // week
    DAY_SECONDS * 30, // month
    DAY_SECONDS * 365, // year
];

export default TimeRangeForm;
export type TimeRangeFormProps = {
    dateFrom: string;
    dateTo: string;
    quickRange: number;
    onQuickRangeChange?: (range: number) => void;
    onTimeRangeChange?: (from: moment.Moment, to: moment.Moment) => void;
    extraButtons?: React.ReactElement;
    minRange?: number;
    enableCustomTimerange?: boolean;
    quickRanges?: number[];
    timezone?: string;
};
function TimeRangeForm({
    dateFrom,
    dateTo,
    quickRange,
    onQuickRangeChange,
    onTimeRangeChange,
    extraButtons,
    minRange = 0,
    enableCustomTimerange = true,
    quickRanges = DEFAULT_TIME_INTERVALS,
    timezone = 'UTC',
}: TimeRangeFormProps) {
    const [form] = useForm();

    const handleValuesChange = (values: any) => {
        if (values.quickRange) {
            onQuickRangeChange?.(values.quickRange);
        }
    };

    const handleApplyRange = () => {
        onTimeRangeChange?.(
            form.getFieldValue('dateFrom'),
            form.getFieldValue('dateTo')
        );
    };

    return (
        <Form
            layout="vertical"
            form={form}
            initialValues={{
                dateFrom: moment(dateFrom).tz(timezone),
                dateTo: moment(dateTo).tz(timezone),
                quickRange,
            }}
            onValuesChange={handleValuesChange}
        >
            <SpaceDescriptions
                wrap
                itemSplit={
                    <Divider type="vertical" style={{ height: '100%' }} />
                }
                align="normal"
            >
                {enableCustomTimerange && (
                    <SpaceDescriptions.Item
                        label="Date time range"
                        labelStrong
                        direction="vertical"
                        colon={false}
                        size={25}
                    >
                        <Form.Item name="dateFrom" label={<Space>From</Space>}>
                            <DatePicker showTime />
                        </Form.Item>
                        <Form.Item name="dateTo" label={<Space>To</Space>}>
                            <DatePicker showTime />
                        </Form.Item>
                        <SpaceWide justify="end">
                            {extraButtons}
                            <Button type="primary" onClick={handleApplyRange}>
                                Apply
                            </Button>
                        </SpaceWide>
                    </SpaceDescriptions.Item>
                )}

                <SpaceDescriptions.Item
                    label={
                        <Space style={{ paddingLeft: 15 }}>
                            Quick time ranges
                        </Space>
                    }
                    labelStrong
                    direction="vertical"
                    colon={false}
                >
                    <Form.Item name="quickRange">
                        <InlineSelect
                            wrap
                            style={{ height: 200 }}
                            size={2}
                            direction="vertical"
                            options={quickRanges
                                .filter((item) => item > minRange)
                                .map((item) => ({
                                    label: (
                                        <span>
                                            last{' '}
                                            <DurationFormat
                                                precision={1}
                                                units={
                                                    item > DAY_SECONDS
                                                        ? ['d', 'h', 'm']
                                                        : ['h', 'm']
                                                }
                                            >
                                                {item * 1000}
                                            </DurationFormat>
                                        </span>
                                    ),
                                    value: item,
                                }))}
                        />
                    </Form.Item>
                </SpaceDescriptions.Item>
            </SpaceDescriptions>
        </Form>
    );
}
