import React, { useMemo } from 'react';
import { Col, Row } from 'antd';
import { Form } from 'antd';

import CcCluster, {
    CcClusterType,
} from '../../../../services/models/CcCluster';

import { FormInstance } from 'antd/lib/form';
import { CcNodeType } from '../../../../services/models/CcNode';
import KeepAlivedHostnameField from '../../FormFields/KeepAlivedHostnameField';
import { getTopologyValidator } from '../../loadBalancerHelpers';

type ImportKeepalivedConfigurationProps = {
    selectedCluster?: CcCluster;
    form: FormInstance;
};

export default ImportKeepalivedConfiguration;

function ImportKeepalivedConfiguration({
    selectedCluster, // @todo make cluster prop required
    form,
}: ImportKeepalivedConfigurationProps) {
    const nodeTypes = useMemo(() => {
        if (!selectedCluster) {
            return [];
        }
        if (selectedCluster?.clusterType === CcClusterType.TYPE_POSTGRESQL) {
            return [CcNodeType.HAPROXY];
        }

        return [CcNodeType.PROXYSQL, CcNodeType.HAPROXY, CcNodeType.MAXSCALE];
    }, [selectedCluster]);

    return (
        <div style={{ minHeight: 450 }}>
            <Row>
                <Col span={24}>
                    <h3>Load balancer type</h3>
                </Col>
            </Row>
            <Row gutter={[24, 0]}>
                <Col span={24}>
                    <Form.Item shouldUpdate={true}>
                        {() =>
                            selectedCluster ? (
                                <KeepAlivedHostnameField
                                    name={[
                                        'keepalivedConfiguration',
                                        'hostname',
                                    ]}
                                    form={form}
                                    cluster={selectedCluster}
                                    nodeTypes={nodeTypes}
                                    selectHosts={true}
                                />
                            ) : null
                        }
                    </Form.Item>
                </Col>
            </Row>
        </div>
    );
}

export function getImportKeepalivedConfigurationValidation(form: FormInstance) {
    return [
        ['keepalivedConfiguration', 'hostname'],
        getTopologyValidator(form, ['keepalivedConfiguration', 'hostname']),
    ];
}
