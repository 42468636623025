import React, { useCallback, useContext, useEffect } from 'react';
import CcAlarm from '../../services/models/CcAlarm';
import AlarmSeverityFormat from './AlarmSeverityFormat';
import { ResponsiveContext } from '@severalnines/bar-frontend-components/build/lib/Layout/Responsive';
import ClusterFormat from '../Clusters/ClusterFormat';
import { AppState, AppStateClustersMap } from '../../appReducer';
import { useSelector } from 'react-redux';
import AlarmActionsMenu from './AlarmActionsMenu';
import TypographyText from '../../common/TypographyText';
import useCurrentAlarmsList, {
    getIgnoredAlarmsFilter,
} from './useCurrentAlarmsList';
import { Space } from 'antd';
import { AlarmsIcon } from '../../common/icons/icons';
import { TablePaginationConfig } from 'antd/es';
import AppEmpty from '../../common/Feedback/AppEmpty';
import AppDateFormat from '../../common/AppDateFormat';
import AppTooltip from '../../common/Feedback/AppTooltip';
import AppTable from '../../common/DataDisplay/AppTable';
import AlarmDetailsButton from './AlarmDetailsButton';

export default AlarmsTable;

export type AlarmsTableProps = {
    enablePagination?: boolean;
    pageLimit?: number;
    footerRender?: (total?: number) => React.ReactNode;
    hideIgnored?: boolean;
    clusterId?: number;
    showRelativeTime?: boolean;
};

function AlarmsTable({
    enablePagination = true,
    pageLimit = 20,
    footerRender,
    hideIgnored,
    clusterId,
    showRelativeTime,
}: AlarmsTableProps) {
    const { responsive } = useContext(ResponsiveContext);
    const {
        list: alarms,
        loading: loadingAlarms,
        refresh: refreshAlarms,
        filter: filterAlarms,
        page,
        pageSize,
        total,
    } = useCurrentAlarmsList({
        pageSize: pageLimit,
        ...(hideIgnored ? { filters: [getIgnoredAlarmsFilter()] } : {}),
    });

    const [clustersMap]: [
        AppStateClustersMap
    ] = useSelector(({ clusters }: AppState) => [clusters]);

    useEffect(() => {
        if (clusterId) {
            filterAlarms({
                filters: [(alarm: CcAlarm) => alarm.clusterId === clusterId],
            });
        } else {
            filterAlarms();
        }
    }, []);

    const handleActionPerfomed = async () => {
        await refreshAlarms();
        if (clusterId) {
            filterAlarms({
                filters: [(alarm: CcAlarm) => alarm.clusterId === clusterId],
            });
        } else {
            filterAlarms();
        }
    };

    const columns = [
        {
            title: 'Title',
            key: 'title',
            render: (record: CcAlarm) => (
                <Space
                    align="center"
                    style={{
                        width: '100%',
                        justifyContent: 'space-between',
                    }}
                >
                    <AlarmDetailsButton alarm={record}>
                        {record.title}
                    </AlarmDetailsButton>
                    {record.isIgnored() ? (
                        <AppTooltip title="Muted alarm">
                            <TypographyText muted={true}>
                                <AlarmsIcon disable={true} />
                            </TypographyText>
                        </AppTooltip>
                    ) : null}
                </Space>
            ),
        },
        {
            title: 'Severity',
            key: 'severity',
            render: (record: CcAlarm) => <AlarmSeverityFormat alarm={record} />,
        },
        {
            title: 'Category',
            key: 'category',
            render: (record: CcAlarm) => record.componentName,
        },
        {
            title: 'Cluster',
            key: 'cluster',
            render: (record: CcAlarm) => (
                <ClusterFormat
                    clusterLink={true}
                    linkDestination={'alarms'}
                    cluster={clustersMap.get(record.getClusterKey())}
                    showPopover={true}
                />
            ),
        },
        {
            title: 'Hostname',
            key: 'hostname',
            render: (record: CcAlarm) => record.hostname,
        },
        {
            title: 'When',
            key: 'created',
            render: (record: CcAlarm) => (
                <>
                    <AppDateFormat fromNow={showRelativeTime}>
                        {record.created ? new Date(record.created) : null}
                    </AppDateFormat>
                </>
            ),
        },
        {
            key: 'actions',
            title: 'Actions',
            align: 'center',
            render: (record: CcAlarm) => (
                <AlarmActionsMenu
                    cluster={clustersMap.get(record.getClusterKey())}
                    alarm={record}
                    onActionPerformed={handleActionPerfomed}
                />
            ),
            onCell: () => ({ style: { padding: '2px 10px' } }),
        },
    ];

    const extraProps = useCallback(() => {
        let props: any = {};
        if (footerRender) {
            props.footer = () => footerRender(total);
        }
        return props;
    }, [footerRender, total]);

    // @TODO: make pagination config to be shared somehow
    const pagination: TablePaginationConfig = {
        size: 'default',
        pageSize,
        current: page,
        total,
        hideOnSinglePage: true,
        showQuickJumper: true,
        showSizeChanger: true,
        position: ['bottomCenter'],
    };

    const handleTableChange = (pagination: any, filter: any, sorters: any) => {
        filterAlarms({
            page: pagination.current,
            // filters: getClientFilters({
            //     controller: controllerF,
            //     state: stateFilter,
            // }),
        });
    };
    return (
        <AppTable
            loading={loadingAlarms}
            rowKey={(record: CcAlarm) => record.getKey()}
            dataSource={alarms}
            columns={columns}
            responsive={responsive}
            onRow={(record: CcAlarm, index: any) => ({
                'data-testid': `alarm-table-row-${index}`,
            })}
            pagination={enablePagination ? pagination : false}
            onChange={handleTableChange}
            renderEmpty={
                <AppEmpty
                    loading={loadingAlarms}
                    description="You haven’t received alarms yet. When you do, it'll show up here."
                />
            }
            {...extraProps()}
        />
    );
}
