import CmonClustersService from '../../services/cmon/CmonClustersService';
import CcImperativeService from '../../services/CcImperativeService';
import CcDatabaseAccount, {
    CcDatabaseAccountStats,
} from '../../services/models/CcDatabaseAccount';
import { camelCasePropToUnderscore } from '../../common/helpers';
import { useEffect, useMemo } from 'react';
import useClientListFilter from '../../common/hooks/useClientListFilter';
import useFetch from '../../common/useFetch';
import { useDebugContext } from '../../common/Debug';
import { notifyError, NotifyType } from '../Notifications/uiNotification';

type UseDatabaseUsersProps = {
    clusterId: number;
};
export default function useDatabaseUsers({ clusterId }: UseDatabaseUsersProps) {
    const { log } = useDebugContext();

    const { data, loading, refresh } = useFetch<any>({
        name: 'database-users-list',
        throwError: true,
        fetchFn: async ({ ...rest }, opts) => {
            const { accounts } = await CmonClustersService.getAccounts(
                {
                    ...rest,
                },
                opts
            );
            const { results } = await CcImperativeService.executeScript(
                {
                    filename:
                        '/s9s/mysql/widgets/usermgmt/show_unused_accounts.js',
                    ...rest,
                },
                opts
            );
            const {
                error_msg: errorMsg,
                unused_accounts: unusedAccounts,
            } = results?.exitStatus;
            let unusedAccountsReady = true;
            if (errorMsg) {
                if (errorMsg.startsWith('Server must have been running for')) {
                    unusedAccountsReady = false;
                } else {
                    throw new Error(errorMsg);
                }
            }

            // create object with key ${user}'@'${host}
            const inactiveMap = unusedAccounts.reduce((acc: any, item: any) => {
                acc[`${item.user}'@'${item.host}`] = item;
                return acc;
            }, {});
            const list = accounts.map((account: CcDatabaseAccount) => {
                let status;
                if (unusedAccountsReady) {
                    if (
                        inactiveMap[
                            `${account.userName}'@'${account.hostAllow}`
                        ]
                    ) {
                        status = CcDatabaseAccountStats.INACTIVE;
                    } else {
                        status = CcDatabaseAccountStats.ACTIVE;
                    }
                } else {
                    status = CcDatabaseAccountStats.UNKNOWN;
                }
                return new CcDatabaseAccount({
                    ...camelCasePropToUnderscore(account),
                    status,
                });
            });

            return {
                list,
                unusedAccountsReady,
            };
        },
        cancelFn: async ({ requestId }) => {
            await CmonClustersService.cancelRequest(requestId);
            await CcImperativeService.cancelRequest(requestId);
        },
    });

    const { list: accountsList, unusedAccountsReady } = useMemo(() => {
        if (!data) {
            return {
                unusedAccountsReady: false,
            };
        }
        const { list, unusedAccountsReady } = data;
        return {
            list,
            unusedAccountsReady,
        };
    }, [data]);

    const { list, filter, total } = useClientListFilter({
        dataList: accountsList,
    });

    useEffect(() => {
        (async () => {
            try {
                await refresh({ cluster_id: clusterId });
            } catch (e: any) {
                log.error(e);
                notifyError({
                    type: NotifyType.TOAST,
                    duration: 0,
                    content: e.message,
                });
            }
        })();
    }, [clusterId]);

    return {
        list,
        filter,
        total,
        loading,
        unusedAccountsReady,
        refresh,
    };
}
