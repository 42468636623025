


export interface CmonProxySqlScheduleProps  {
    active?: number;
    arg1?: string;
    arg2?: string;
    arg3?: string;
    arg4?: string;
    arg5?: string;
    class_name?: string;
    comment?: string;
    filename?: string;
    id?: string;
    interval_ms?: number;

}



export default class CmonProxySqlSchedule {
    public active?: number;
    public arg1?: string;
    public arg2?: string;
    public arg3?: string;
    public arg4?: string;
    public arg5?: string;
    public className?: string;
    public comment?: string;
    public filename?: string;
    public id?: string;
    public intervalMs?: number;


    constructor(props: CmonProxySqlScheduleProps) {
        this.active = props.active;
        this.arg1 = props.arg1;
        this.arg2 = props.arg2;
        this.arg3 = props.arg3;
        this.arg4 = props.arg4;
        this.arg5 = props.arg5;
        this.className = props.class_name;
        this.comment = props.comment;
        this.filename = props.filename;
        this.id = props.id;
        this.intervalMs = props.interval_ms;

    }

}
