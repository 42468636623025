import React from 'react';
import { Button, Col, Form, Input, Row } from 'antd';
import Alert from '@severalnines/bar-frontend-components/build/lib/Feedback/Alert';
import FormFooter from '../../../common/FormFooter';
import CcCluster from '../../../services/models/CcCluster';
import ModalDefaultForm from '../../../common/ModalDefaultForm';
import useCreateJob from '../../Jobs/useCreateJob';
import { CmonJobInstanceCommand } from '../../../services/cmon/models/CmonJobInstance';
import { CcNodeType } from '../../../services/models/CcNode';
import SpaceWide from '../../../common/SpaceWide';
import TypographyText from '../../../common/TypographyText';
import ClusterNodeSelect from '../../../common/DataEntry/ClusterNodeSelect';
import { FormInstance } from 'antd/lib/form';

export default ProxySqlExportConfigFrom;

export type ProxySqlExportConfigFromProps = {
    cluster: CcCluster;
    onSuccess?: () => void;
    onCancel?: () => void;
    form?: FormInstance;
};
export type ProxySqlExportConfigFromValues = {
    sourceInstance?: string;
    exportDirectory?: string;
};

function ProxySqlExportConfigFrom({
    cluster,
    form: parentForm,
    onSuccess,
    onCancel,
}: ProxySqlExportConfigFromProps) {
    const [localForm] = Form.useForm<ProxySqlExportConfigFromValues>();
    const form = parentForm || localForm;

    const { loading, send } = useCreateJob({
        clusterId: cluster?.clusterId,
        title: 'Backup ProxySql Configuration',
        command: CmonJobInstanceCommand.PROXYSQL.toLowerCase() as CmonJobInstanceCommand,
        onSuccess,
    });

    const handleSubmit = async (values: ProxySqlExportConfigFromValues) => {
        await send(
            {
                action: 'backup',
                backup_method: 'proxysql',
                hostname: values.sourceInstance,
                backupdir: values.exportDirectory,
                clusterId: cluster.clusterId,
            },
            {
                job: {
                    title: `Backup ProxySql Configuration ${values.sourceInstance}`,
                },
            }
        );
    };
    return (
        <ModalDefaultForm
            title="Export configuration data"
            form={form}
            footer={[]}
            onCancel={onCancel}
            defaultVisible={true}
        >
            <Form
                className="ProxySqlExportConfigFrom"
                form={form}
                layout="vertical"
                onFinish={handleSubmit}
                initialValues={{ exportDirectory: '/root/backups/proxysql' }}
            >
                <Row gutter={24}>
                    <Col span={12}>
                        <TypographyText>
                            Export configuration data to a backup which can be
                            imported/restored on any ProxySOL instance.
                        </TypographyText>
                        <Form.Item
                            label="Source Instance"
                            style={{ marginTop: '1rem' }}
                            name={'sourceInstance'}
                            rules={[
                                {
                                    required: true,
                                    message: 'Please select source instance',
                                },
                            ]}
                        >
                            <ClusterNodeSelect
                                cluster={cluster}
                                primaryCandidatesOnly={false}
                                databaseNodes={false}
                                autoComplete={true}
                                extractNodeValue={(node) => node.hostname}
                                placeholder="select source instance"
                                types={[CcNodeType.PROXYSQL]}
                            />
                        </Form.Item>
                        <SpaceWide direction="vertical">
                            <Form.Item
                                label="Export Directory"
                                name={'exportDirectory'}
                                rules={[
                                    {
                                        required: true,
                                        message:
                                            'Please enter export directory',
                                    },
                                ]}
                            >
                                <Input placeholder="Enter export directory." />
                            </Form.Item>
                        </SpaceWide>
                    </Col>
                    <Col span={12}>
                        <SpaceWide direction="vertical">
                            <Alert
                                message={
                                    <span>
                                        <h4>
                                            Configuration data will be exported
                                        </h4>
                                        <span>
                                            The configuration data will be
                                            exported into several SQL dump files
                                            where applicable. The following
                                            configuration data will be exported:
                                        </span>
                                        <ol>
                                            <li>Query Rules</li>
                                            <li>Host Groups/Servers</li>
                                            <li>
                                                Users and corresponding MySQL
                                                users
                                            </li>
                                            <li>Global Variables</li>
                                            <li>Scheduler</li>
                                            <li>proxysql.cnf</li>
                                        </ol>
                                    </span>
                                }
                                showIcon={true}
                            />
                        </SpaceWide>
                    </Col>
                </Row>
                <FormFooter>
                    <Button
                        key="done"
                        type="primary"
                        htmlType="submit"
                        loading={loading}
                    >
                        Export
                    </Button>
                </FormFooter>
            </Form>
        </ModalDefaultForm>
    );
}
