import React from 'react';
import { Button, Form, Space } from 'antd';
import Alert from '@severalnines/bar-frontend-components/build/lib/Feedback/Alert';
import FormItemInlineSwitch from '../../../common/DataEntry/FormItemInlineSwitch';
import FormFooter from '../../../common/FormFooter';
import ModalDefaultForm from '../../../common/ModalDefaultForm';
import CcNode from '../../../services/models/CcNode';
import NodeFormat from '../NodeFormat';
import SpaceWide from '../../../common/SpaceWide';
import InfoIcon from '@severalnines/bar-frontend-components/build/lib/General/InfoIcon';
import useCreateJob from '../../Jobs/useCreateJob';
import { CmonJobInstanceCommand } from '../../../services/cmon/models/CmonJobInstance';
import InputNumberWide from '../../../common/DataEntry/InputNumberWide';

export default NodeStopForm;

export type NodeStopFormProps = {
    node: CcNode;
    onSuccess?: () => void;
    onCancel?: () => void;
};
export type NodeStopFormValues = {
    stopTimeout?: number;
    forceStop?: boolean;
};

function NodeStopForm({ node, onSuccess, onCancel }: NodeStopFormProps) {
    const [form] = Form.useForm<NodeStopFormValues>();
    const { loading, send } = useCreateJob({
        clusterId: node.clusterid,
        title: 'Stop node',
        command: CmonJobInstanceCommand.STOP,
        onSuccess,
    });

    const handleSubmit = async (values: NodeStopFormValues) => {
        await send({
            hostname: node.hostname,
            port: node.port,
            stop_timeout: values.stopTimeout,
            force_stop: values.forceStop,
        });
    };
    return (
        <ModalDefaultForm
            title="Stop node"
            form={form}
            footer={[]}
            onCancel={onCancel}
            width={450}
            defaultVisible={true}
        >
            <Form
                className="NodeStopForm"
                form={form}
                layout="vertical"
                onFinish={handleSubmit}
                initialValues={{ forceStop: false }}
            >
                <SpaceWide direction="vertical">
                    <NodeFormat
                        node={node}
                        size="large"
                        contentProps={{ style: { width: '100%' } }}
                    />
                    <NodeStopFormStopTimeoutInput />
                    <NodeStopFormStopForceStopInput />
                    <NodeStopFormNotice />
                </SpaceWide>
                <FormFooter>
                    <Button
                        key="done"
                        type="primary"
                        htmlType="submit"
                        loading={loading}
                    >
                        Stop
                    </Button>
                </FormFooter>
            </Form>
        </ModalDefaultForm>
    );
}

function NodeStopFormStopTimeoutInput() {
    return (
        <Form.Item
            name="stopTimeout"
            label={
                <Space>
                    Graceful shutdown timeout (in seconds)
                    <InfoIcon
                        info={
                            <span>
                                Controller will give up waiting for node to
                                gracefully teminate. If the node is still
                                running after the timeout, send the SIGKILL
                                signal to force the node down.
                            </span>
                        }
                    />
                </Space>
            }
            rules={[
                {
                    pattern: /^[1-9][0-9]*$/,
                    message: 'Please enter a number greater than 0',
                },
            ]}
        >
            <InputNumberWide placeholder="Enter enter shutdown timeout" />
        </Form.Item>
    );
}
function NodeStopFormStopForceStopInput() {
    return (
        <FormItemInlineSwitch
            justify
            noMargin
            name="forceStop"
            label={<Space>Force stop the nodes (after shutdown time)</Space>}
            valuePropName="checked"
        />
    );
}

function NodeStopFormNotice() {
    return (
        <Alert
            message="No data will be lost during the process."
            showIcon={true}
        />
    );
}

NodeStopForm.StopTimeoutInput = NodeStopFormStopTimeoutInput;
NodeStopForm.ForceStopInput = NodeStopFormStopForceStopInput;
NodeStopForm.Notice = NodeStopFormNotice;
