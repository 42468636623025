import React from 'react';
import { FormInstance } from 'antd/lib/form';
import SpaceDescriptions from '../../../../common/Layout/SpaceDescriptions';
import YesNoFormat from '../../../../common/Format/YesNoFormat';

export default MssqlNodeSummary;

export type MssqlNodeSummaryProps = {
    form: FormInstance;
    hasSslEncryption?: boolean;
};

function MssqlNodeSummary({ form, hasSslEncryption }: MssqlNodeSummaryProps) {
    const nodeConfig = form.getFieldValue('nodeConfig');
    return (
        <SpaceDescriptions
            direction="vertical"
            title="Node configuration"
            className="MssqlNodeSummary"
            alignItems="right"
        >
            <SpaceDescriptions.Item label="Server port" labelStrong>
                {nodeConfig?.serverPort}
            </SpaceDescriptions.Item>
            <SpaceDescriptions.Item label="Admin username" labelStrong>
                {nodeConfig?.dbUser}
            </SpaceDescriptions.Item>
            <SpaceDescriptions.Item label="Admin password" labelStrong>
                {nodeConfig?.dbPassword
                    ? nodeConfig.dbPassword.replace(/./g, '*')
                    : null}
            </SpaceDescriptions.Item>
            {hasSslEncryption ? (
                <SpaceDescriptions.Item
                    label="Enable SSL encryption"
                    labelStrong
                >
                    <YesNoFormat booleanVar={nodeConfig.sslEncryption} />
                </SpaceDescriptions.Item>
            ) : null}
        </SpaceDescriptions>
    );
}
