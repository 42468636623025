import './SystemLogsPage.less';
import React, { useEffect, useRef, useState } from 'react';
import CmonLogService from '../../services/cmon/CmonLogService';
import { DirectoryTreeProps } from 'antd/lib/tree';
import { groupArrayBy } from '../../common/helpers';
import useFetch from '../../common/useFetch';
import RefreshLogButton from './RefreshLogButton';
import CcCluster from '../../services/models/CcCluster';
import AppEditor, {
    EditorTreeDataNode,
    updateTreeDataNode,
} from '../../common/AppEditor/AppEditor';

export default SystemLogsPage;
export type SystemLogsPageProps = {
    cluster: CcCluster;
};

function SystemLogsPage({ cluster, ...rest }: SystemLogsPageProps) {
    const selectedNode = useRef<EditorTreeDataNode>();
    const [treeData, setTreeData] = useState<EditorTreeDataNode[]>([]);

    const {
        data: files,
        loading: filesLoading,
        refresh: filesRefresh,
    } = useFetch<any>({
        name: 'log-files-list',
        fetchFn: async ({ ...rest }, opts) => {
            const { files } = await CmonLogService.listCollectedLogs({
                cluster_id: cluster.clusterId,
                ...rest,
            });

            return files;
        },
        cancelFn: async ({ requestId }) => {
            await CmonLogService.cancelRequest(requestId);
        },
    });

    const {
        data: logData,
        loading: loadingLog,
        refresh: logRefresh,
    } = useFetch<any>({
        name: 'get-collected-logs',
        useCache: false,
        throwError: true,
        fetchFn: async ({ filename, hostname }, {}) => {
            const { data } = await CmonLogService.getCollectedLog({
                cluster_id: cluster.clusterId,
                filename,
                hostname,
                ...rest,
            });

            return data;
        },
        cancelFn: async ({ requestId }) => {
            await CmonLogService.cancelRequest(requestId);
        },
    });

    useEffect(() => {
        (async () => {
            await filesRefresh({});
        })();
    }, [cluster.clusterId]);

    useEffect(() => {
        if (files) {
            const treeData = files
                ? Object.entries(
                      groupArrayBy(files, (file) => file.hostname)
                  ).map(([key, group]: any) => ({
                      selectable: false,
                      name: key,
                      type: 'dir',
                      key,
                      children: group.map((file: any) => ({
                          name: file.filename,
                          type: 'file',
                          icon: <></>,
                          filename: file.filename,
                          hostname: key,
                          key: `${key}-${file.filename}`,
                          isLeaf: true,
                          language: 'plaintext',
                      })),
                  }))
                : [];
            setTreeData(treeData);
            // setExpandedKeys(treeData.map((node) => node.key));
        }
    }, [files]);

    useEffect(() => {
        if (logData && selectedNode.current) {
            setTreeData(
                updateTreeDataNode(treeData, {
                    ...selectedNode.current,
                    loading: loadingLog,
                    content: logData.content,
                })
            );
        }
    }, [logData, loadingLog]);

    const handleSelect: DirectoryTreeProps['onSelect'] = async (
        keys,
        { node }: any
    ) => {
        selectedNode.current = node;
        await logRefresh({
            filename: node.filename,
            hostname: node.hostname,
        });
    };

    const handleRefreshJobFinish = async () => {
        await filesRefresh({});
        if (selectedNode.current) {
            await logRefresh({
                filename: selectedNode.current?.filename,
                hostname: selectedNode.current?.hostname,
            });
        }
    };

    return (
        <div className="SystemLogsPage_Wrap">
            <AppEditor
                readOnly={true}
                treeData={treeData}
                loading={filesLoading}
                onSelect={handleSelect}
                footerLeftExtra={
                    <RefreshLogButton
                        className="SystemLogsPage_Refresh"
                        cluster={cluster}
                        onFinish={handleRefreshJobFinish}
                    />
                }
            />
        </div>
    );
}
