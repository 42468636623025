import JobProgress, { JobProgressProps } from '../../Jobs/JobProgress';
import { useMemo } from 'react';
import { CmonJobInstanceCommand } from '../../../services/cmon/models/CmonJobInstance';

export default BackupToolJobProgress;

type BackupToolJobProgressProps = JobProgressProps & {
    reconfigure?: boolean;
};

function BackupToolJobProgress({
    reconfigure = false,
    command,
    ...rest
}: BackupToolJobProgressProps) {
    const props = useMemo(() => {
        const action = reconfigure ? 'Reconfiguring' : 'Installing';
        switch (command) {
            case CmonJobInstanceCommand.PGBACKREST:
                return {
                    title: `${action} PgBackRest Backup`,
                    failedMessage: `${action} PgBackRest has failed`,
                    successMessage: `${action} PgBackRest has finished successfully`,
                };
            case CmonJobInstanceCommand.PBMAGENT:
                return {
                    title: `${action} MongoDB Percona Backup`,
                    failedMessage: `${action} MongoDB Percona Backup has failed`,
                    successMessage: `${action} MongoDB Percona Backup has finished successfully`,
                };
            default:
                return {};
        }
    }, [command]);
    return <JobProgress command={command} {...props} {...rest} />;
}
