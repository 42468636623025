import { Alert, Col, Form, Row, Space } from 'antd';
import React, { useState } from 'react';
import { FormInstance } from 'antd/lib/form';
import CronInput from '@severalnines/bar-frontend-components/build/lib/DataEntry/CronInput';
import CronFormat from '@severalnines/bar-frontend-components/build/lib/Format/CronFormat';

export type BackupScheduleCronInputFormProps = {
    form: FormInstance;
};
const BackupScheduleCronInputForm = ({
    form,
}: BackupScheduleCronInputFormProps) => {
    const [value, setValue] = useState(form.getFieldValue('scheduleCron'));
    const changeHandle = (newValue: string) => {
        setValue(newValue);
    };
    return (
        <div className="BackupScheduleCronInputForm">
            <Row gutter={[24, 0]}>
                <Col span={24}>
                    <h3>Schedule</h3>
                </Col>
                <Col span={24}>
                    <Form.Item
                        name="scheduleCron"
                        label={<Space>Set backup schedule</Space>}
                        rules={[
                            {
                                required: true,
                                message: 'Please select backup method.',
                            },
                        ]}
                    >
                        <CronInput
                            value={value}
                            showThreeLetterDay={true}
                            timePickerInputProps={{ selectOnClick: true }}
                            onChange={changeHandle}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={[24, 0]}>
                <Col span={24}>
                    <Alert
                        message={
                            <>
                                <Space>
                                    Your backup will start:
                                    <b>
                                        <CronFormat>{value}</CronFormat>
                                    </b>
                                    (UTC)
                                </Space>
                            </>
                        }
                        type="info"
                        showIcon={true}
                    />
                </Col>
            </Row>
        </div>
    );
};

export default BackupScheduleCronInputForm;
