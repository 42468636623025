import React from 'react';
import { Button, Form, Space } from 'antd';
import Alert from '@severalnines/bar-frontend-components/build/lib/Feedback/Alert';
import FormItemInlineSwitch from '../../../common/DataEntry/FormItemInlineSwitch';
import FormFooter from '../../../common/FormFooter';
import ModalDefaultForm from '../../../common/ModalDefaultForm';
import CcNode, { CcNodeType } from '../../../services/models/CcNode';
import NodeFormat from '../NodeFormat';
import SpaceWide from '../../../common/SpaceWide';
import useCreateJob from '../../Jobs/useCreateJob';
import { CmonJobInstanceCommand } from '../../../services/cmon/models/CmonJobInstance';
import InfoIcon from '@severalnines/bar-frontend-components/build/lib/General/InfoIcon';

export default NodeStartForm;

export type NodeStartFormProps = {
    node: CcNode;
    onSuccess?: () => void;
    onCancel?: () => void;
};
export type NodeStartFormValues = {
    initialStart?: boolean;
    bootstrap?: boolean;
};

function NodeStartForm({ node, onSuccess, onCancel }: NodeStartFormProps) {
    const [form] = Form.useForm<NodeStartFormValues>();
    const { loading, send } = useCreateJob({
        clusterId: node.clusterid,
        title: 'Start node',
        command: CmonJobInstanceCommand.START,
        onSuccess,
    });

    const handleSubmit = async (values: NodeStartFormValues) => {
        await send({
            hostname: node.hostname,
            port: node.port,
            initial: values.initialStart,
            bootstrap: values.bootstrap,
        });
    };

    const handleValuesChange = (values: NodeStartFormValues) => {
        const { bootstrap, initialStart } = values;
        // bootstrap and initialStart cant be set to true at same time
        // https://severalnines.atlassian.net/browse/CCV2-326
        if (bootstrap === true) {
            form.setFieldsValue({ initialStart: false });
        }
        if (initialStart === true) {
            form.setFieldsValue({ bootstrap: false });
        }
    };

    return (
        <ModalDefaultForm
            title="Start node"
            form={form}
            footer={[]}
            onCancel={onCancel}
            width={450}
            defaultVisible={true}
        >
            <Form
                className="NodeStartForm"
                form={form}
                layout="vertical"
                onFinish={handleSubmit}
                initialValues={{ initialStart: false }}
                onValuesChange={handleValuesChange}
            >
                <SpaceWide direction="vertical">
                    <NodeFormat
                        node={node}
                        size="large"
                        contentProps={{ style: { width: '100%' } }}
                    />
                    {node.isType(CcNodeType.GALERA) ? (
                        <>
                            <NodeStartFormInitialStartInput />
                            <FormItemInlineSwitch
                                justify
                                noMargin
                                name="bootstrap"
                                label={
                                    <Space>
                                        <span>Bootstrap</span>
                                        <InfoIcon info="With this option the node will start as the first node of a cluster in case of you have all the Galera nodes or cluster down" />{' '}
                                    </Space>
                                }
                                valuePropName="checked"
                            />
                        </>
                    ) : null}
                </SpaceWide>
                <FormFooter>
                    <Button
                        key="done"
                        type="primary"
                        htmlType="submit"
                        loading={loading}
                    >
                        Start
                    </Button>
                </FormFooter>
            </Form>
        </ModalDefaultForm>
    );
}

function NodeStartFormInitialStartInput() {
    return (
        <FormItemInlineSwitch
            justify
            noMargin
            name="initialStart"
            label={
                <Space>
                    <span>Initial start</span>
                    <InfoIcon info="Initial start will remove all files in the datadir and force a full resync (SST), which is necessary sometimes if the Galera node is trying a Node Recovery multiple times and there is a filesystem issue." />{' '}
                </Space>
            }
            valuePropName="checked"
            extraOnSwitch={
                <Alert
                    message={
                        <span>
                            Wait for completion before restarting another node
                            with Initial Start.
                        </span>
                    }
                    showIcon={true}
                />
            }
        />
    );
}

NodeStartForm.InitialStartInput = NodeStartFormInitialStartInput;
