import React, { useEffect } from 'react';
import useServiceMock from './serviceMock/useServiceMock';
import { ServiceMethod } from './serviceMock/ServiceMockContext';
import LdapGroupMapActionMenu from '../components/UserManagement/Ldap/LdapGroupMapActionMenu';
import useGroupList from '../components/UserManagement/useGroupList';

export default DebugLdapGroupMapActionMenu;

export type DebugLdapGroupMapActionMenuProps = {};

function DebugLdapGroupMapActionMenu({}: DebugLdapGroupMapActionMenuProps) {
    useServiceMock({
        mockId: 'ldap-map-group',
        methods: [
            ServiceMethod.GET_LDAP_CONFIG,
            ServiceMethod.SET_LDAP_CONFIG,
            ServiceMethod.USER_GET_GROUPS,
        ],
    });
    const { refresh } = useGroupList({
        useCache: false,
    });
    useEffect(() => {
        (async () => {
            await refresh();
        })();
    }, []);
    return (
        <div className="DebugLdapGroupMapActionMenu">
            <LdapGroupMapActionMenu
                item={{
                    cmonGroupName: 'users',
                    ldapGroupId: 'ldapusersgroup',
                    sectionName: 'ldapusersgroupusers',
                }}
            />
        </div>
    );
}
