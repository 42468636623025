import React, { useState } from 'react';
import CcCloudCredentials from '../../../services/models/CcCloudCredentials';
import { ButtonProps } from 'antd/lib/button';
import CmonCloudService from '../../../services/cmon/CmonCloudService';
import {
    notifyError,
    notifyOperationSuccess,
    NotifyType,
} from '../../Notifications/uiNotification';
import AppConfirmActionButton from '../../../common/General/AppConfirmActionButton';

export default CloudCredentialsDeleteActionButton;

export type CloudCredentialsDeleteActionButtonProps = ButtonProps & {
    credentials: CcCloudCredentials;
    onSuccess?: () => void;
};

function CloudCredentialsDeleteActionButton({
    children,
    credentials,
    onSuccess,
    ...rest
}: CloudCredentialsDeleteActionButtonProps) {
    const [loading, setLoading] = useState(false);
    const handleClick = async () => {
        try {
            setLoading(true);
            await CmonCloudService.removeCredentials({
                provider: credentials.provider,
                id: credentials.id,
            });
            notifyOperationSuccess({
                type: NotifyType.TOAST,
                title: `Credentials ${credentials.name}#${credentials.id} removed successfully!`,
                content: '',
            });
            setLoading(false);
            onSuccess?.();
        } catch (e) {
            setLoading(false);
            notifyError({
                content: e.message,
            });
        }
    };
    return (
        <AppConfirmActionButton
            critical={true}
            confirmTitle={
                <span>Are you sure, you want to delete these credentials?</span>
            }
            onConfirm={handleClick}
            loading={loading}
            {...rest}
        >
            {children || 'Delete'}
        </AppConfirmActionButton>
    );
}
