import React from 'react';
import ButtonWithForm from '../../common/General/ButtonWithForm';
import CcCluster, {
    CcClusterTechnology,
} from '../../services/models/CcCluster';
import EnableAuditLogRegularModal from './EnableAuditLogRegularModal';
import EnableAuditLogPostgreSQLModal from './EnableAuditLogPostgreSQLModal';
import JobActionButton from '../Jobs/JobActionButton';
import { CmonJobInstanceCommand } from '../../services/cmon/models/CmonJobInstance';

export default EnableAuditLogButton;

export type EnableAuditLogButtonProps = {
    cluster: CcCluster;
};

function EnableAuditLogButton({ cluster, ...rest }: EnableAuditLogButtonProps) {
    return (
        <ButtonWithForm
            button={
                <JobActionButton
                    command={
                        CmonJobInstanceCommand.SETUP_AUDIT_LOGGING.toLowerCase() as CmonJobInstanceCommand
                    }
                    clusterId={cluster.clusterId}
                    clusterType={cluster.clusterType}
                    {...rest}
                >
                    Enable Audit Log
                </JobActionButton>
            }
            form={
                cluster.isTechnology(
                    CcClusterTechnology.TECHNOLOGY_POSTGRESQL
                ) ? (
                    <EnableAuditLogPostgreSQLModal cluster={cluster} />
                ) : (
                    <EnableAuditLogRegularModal cluster={cluster} />
                )
            }
        />
    );
}
