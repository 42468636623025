import { ResponsiveTableColumnsType } from '@severalnines/bar-frontend-components/build/lib/DataDisplay/ResponsiveTable';
import React, { useMemo } from 'react';
import { AppStateClustersMap, AppStateNodeTypes } from '../../appReducer';
import { ColumnType } from 'antd/lib/table';
import NodeTypeFormat from '../../components/Nodes/NodeTypeFormat';
import ClusterFormat from '../../components/Clusters/ClusterFormat';

type TableColumnType = ColumnType<any> & {
    filterType?: TableFilterType;
};
type TableFilterHandledState = {
    clusters?: AppStateClustersMap;
    nodeTypes?: AppStateNodeTypes;
};

export enum TableFilterType {
    NODE_TYPE = 'node_type',
    CLUSTER = 'cluster',
}

export type UseTableFilterProps = {
    columns: ResponsiveTableColumnsType<any>;
    filterParams?: any;
    state?: TableFilterHandledState;
    disableFilters?: boolean;
};
export default function useTableFilterColumns({
    columns,
    filterParams = {},
    state = {},
    disableFilters = false,
}: UseTableFilterProps) {
    const applyColumnFiltersAndSort = (column: TableColumnType) => {
        let modifiedColumn = column;
        switch (column.filterType) {
            case TableFilterType.NODE_TYPE:
                if (state.nodeTypes) {
                    modifiedColumn = {
                        ...column,
                        filters: state.nodeTypes.map((i) => ({
                            value: i,
                            text: <NodeTypeFormat showIcon={true} type={i} />,
                        })),
                    };
                }

                break;
            case TableFilterType.CLUSTER:
                if (state.clusters) {
                    modifiedColumn = {
                        ...column,
                        filters: state.clusters
                            .toList()
                            .toArray()
                            .map((i) => ({
                                text: <ClusterFormat cluster={i} />,
                                value: `${i.clusterId}`,
                            })),
                    };
                }

                break;
        }
        if (disableFilters) {
            modifiedColumn = {
                ...modifiedColumn,
                filteredValue: undefined,
                filters: undefined,
            };
        }
        if (
            modifiedColumn.filters &&
            modifiedColumn.key &&
            filterParams[modifiedColumn.key]
        ) {
            modifiedColumn.filteredValue = filterParams[modifiedColumn.key];
        }
        if (
            modifiedColumn.sorter &&
            modifiedColumn.key &&
            filterParams.sort === modifiedColumn.key
        ) {
            modifiedColumn.defaultSortOrder = filterParams.order;
        }

        return modifiedColumn;
    };
    const modifiedColumns = useMemo(() => {
        return columns.map((column) => {
            return applyColumnFiltersAndSort(column);
        });
    }, [columns, filterParams]);

    return { columns: modifiedColumns };
}
