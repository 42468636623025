import React, { ReactElement, useMemo } from 'react';
import AddNodeFormWizard, {
    AddNodeFormWizardFields,
    AddNodeFormWizardProps,
    AddNodeFormWizardStep,
    getAddNodeWizardStepName,
} from '../AddNodeFormWizard';
import CcCluster, {
    CcClusterType,
} from '../../../../../services/models/CcCluster';
import { AddNodeJobDataDTO, AddNodeWizardAction } from '../AddNodeWizard';
import { FormInstance } from 'antd/lib/form';
import AddNodeConfigurationForm, {
    getNodeConfigurationValidatingFields,
    getImportReplicationNodeConfigurationFields,
    getNewReplicationNodeConfigurationFields,
} from '../AddNodeConfigurationForm';
import {
    getAdvancedValidatingFields,
    getReplicationAdvancedForm,
} from '../AddNodeAdvancedForm';
import AddNodeHostForm, {
    getNodeHostValidatingFields,
} from '../AddNodeHostForm';
import AddNodeSummary from '../AddNodeSummary';
import WizardFormConfiguration from '@severalnines/bar-frontend-components/build/lib/Navigation/Wizard/WizardFormConfiguration';

export default ReplicationFormWizard;

export type ReplicationFormWizardProps = Omit<
    AddNodeFormWizardProps,
    'steps' | 'onSubmit'
> & {
    form: FormInstance<AddNodeFormWizardFields>;
    cluster: CcCluster;
    action: AddNodeWizardAction;
    activeStep?: AddNodeFormWizardStep;
    onSubmit?: (
        fields: AddNodeFormWizardFields,
        action: AddNodeWizardAction
    ) => void;
};

function ReplicationFormWizard({
    form,
    cluster,
    action,
    activeStep,
    onSubmit,
    ...rest
}: ReplicationFormWizardProps) {
    const handleSubmit = () => {
        onSubmit?.(form.getFieldsValue(true), action);
    };
    const steps = useMemo(() => {
        const actionSteps =
            action === AddNodeWizardAction.NEW
                ? [
                      AddNodeFormWizardStep.CONFIG,
                      AddNodeFormWizardStep.ADVANCED,
                      AddNodeFormWizardStep.NODE,
                      AddNodeFormWizardStep.SUMMARY,
                  ]
                : [
                      AddNodeFormWizardStep.CONFIG,
                      AddNodeFormWizardStep.NODE,
                      AddNodeFormWizardStep.SUMMARY,
                  ];
        return actionSteps
            .map((step) =>
                getReplicationFromWizardStep(cluster, form, step, action)
            )
            .filter((step) => !!step) as ReactElement[];
    }, [cluster, action]);

    return (
        <AddNodeFormWizard
            form={form}
            activeKey={activeStep}
            onSubmit={handleSubmit}
            initialValues={
                action === AddNodeWizardAction.IMPORT
                    ? getImportReplicationDefaultValues(cluster)
                    : getNewReplicationDefaultValues(cluster)
            }
            {...rest}
            steps={steps}
        />
    );
}

export function getReplicationFromWizardStep(
    cluster: CcCluster,
    form: FormInstance,
    step: AddNodeFormWizardStep,
    action: AddNodeWizardAction
) {
    switch (step) {
        case AddNodeFormWizardStep.CONFIG:
            return (
                <WizardFormConfiguration.Step
                    key={step}
                    title={getAddNodeWizardStepName(step)}
                    subTitle=" "
                    validate={getNodeConfigurationValidatingFields()}
                    hasRequiredFields={true}
                >
                    <AddNodeConfigurationForm
                        cluster={cluster}
                        form={form}
                        availableFields={
                            action === AddNodeWizardAction.NEW
                                ? getNewReplicationNodeConfigurationFields(
                                      cluster.clusterType
                                  )
                                : getImportReplicationNodeConfigurationFields(
                                      cluster.clusterType
                                  )
                        }
                    />
                </WizardFormConfiguration.Step>
            );
        case AddNodeFormWizardStep.ADVANCED:
            return (
                <WizardFormConfiguration.Step
                    key={step}
                    title={getAddNodeWizardStepName(step)}
                    subTitle=" "
                    validate={getAdvancedValidatingFields()}
                    hasRequiredFields={true}
                >
                    {getReplicationAdvancedForm(
                        cluster.clusterId as number,
                        cluster.clusterType
                    )}
                </WizardFormConfiguration.Step>
            );
        case AddNodeFormWizardStep.NODE:
            return (
                <WizardFormConfiguration.Step
                    key={step}
                    title={getAddNodeWizardStepName(step)}
                    subTitle=" "
                    validate={() =>
                        getNodeHostValidatingFields(
                            form.getFieldValue('topology')
                        )
                    }
                    hasRequiredFields={true}
                >
                    <AddNodeHostForm
                        cluster={cluster}
                        form={form}
                        isReplication={true}
                        hasPrimary={action === AddNodeWizardAction.NEW}
                        hasDataIp={action === AddNodeWizardAction.NEW}
                        isImport={action === AddNodeWizardAction.IMPORT}
                    />
                </WizardFormConfiguration.Step>
            );
        case AddNodeFormWizardStep.SUMMARY:
            return (
                <WizardFormConfiguration.Step
                    key={step}
                    title={getAddNodeWizardStepName(step)}
                    subTitle=" "
                >
                    <AddNodeSummary
                        form={form}
                        hasAdvanced={action === AddNodeWizardAction.NEW}
                        hasSecurity={action === AddNodeWizardAction.NEW}
                    />
                </WizardFormConfiguration.Step>
            );
        default:
            return undefined;
    }
}

export function getNewReplicationDefaultValues(
    cluster: CcCluster
): AddNodeFormWizardFields {
    const node = cluster.primaryNode || cluster.nodes?.[0];
    switch (cluster.clusterType) {
        case CcClusterType.TYPE_REPLICATION:
        case CcClusterType.TYPE_GALERA:
            return {
                netcatPorts: '9999, 9990-9998',
                nodePort: 3306,
                installSoftware: true,
                disableFirewall: true,
                disableSeLinux: true,
                dataDir: node.datadir || '/var/lib/mysql/',
                delayReplica: false,
                delayReplicaSeconds: 3600,
                rebuildFromBackup: false,
                includeLB: false,
                semiSynchronous: true,
            };
        case CcClusterType.TYPE_TIMESCALEDB:
        case CcClusterType.TYPE_POSTGRESQL:
            return {
                dataDir: node.datadir,
                nodePort: 5432,
                installSoftware: true,
                usePackageForDataDir: true,
                includeLB: false,
                semiSynchronous: false,
            };
        default:
            return {};
    }
}
export function getImportReplicationDefaultValues(
    cluster: CcCluster
): AddNodeFormWizardFields {
    switch (cluster.clusterType) {
        case CcClusterType.TYPE_REPLICATION:
        case CcClusterType.TYPE_GALERA:
            return {
                nodePort: 3306,
                includeLB: false,
            };
        case CcClusterType.TYPE_TIMESCALEDB:
        case CcClusterType.TYPE_POSTGRESQL:
            return {
                nodePort: 5432,
                includeLB: false,
            };
        default:
            return {};
    }
}

export function getNewReplicationJobDataDTO(
    fields: AddNodeFormWizardFields,
    cluster: CcCluster
): AddNodeJobDataDTO {
    const node = {
        hostname: fields.topology?.[0].extraData.hostname,
        hostname_data: fields.topology?.[0].extraData.hostname,
        hostname_internal: fields.nodeDataIp || '',
        port: fields.nodePort,
    };
    const data = {
        master_address: fields.nodePrimary,
        master_delay:
            (fields.delayReplica && fields.delayReplicaSeconds) || undefined,
        install_software: fields.installSoftware,
        disable_selinux: fields.disableSeLinux,
        disable_firewall: fields.disableFirewall,
        update_lb: fields.includeLB,
        config_template: fields.configurationTemplate,
        datadir: fields.dataDir,
        backupid: fields.backup?.getId(),
        clusterId: cluster.clusterId,
    };
    switch (cluster.clusterType) {
        case CcClusterType.TYPE_GALERA:
        case CcClusterType.TYPE_REPLICATION:
            return {
                ...data,
                mysql_semi_sync: fields.semiSynchronous,
                node,
            };
        case CcClusterType.TYPE_TIMESCALEDB:
        case CcClusterType.TYPE_POSTGRESQL:
            return {
                ...data,
                usePackageForDataDir: fields.usePackageForDataDir,
                node: {
                    ...node,
                    synchronous: fields.semiSynchronous,
                    pgname: fields.clusterName,
                },
            };
    }
    return data;
}

export function getImportReplicationJobDataDTO(
    fields: AddNodeFormWizardFields,
    cluster: CcCluster
): AddNodeJobDataDTO {
    const data: AddNodeJobDataDTO = {
        update_lb: fields.includeLB,
        clusterId: cluster.clusterId,
    };
    const node = {
        hostname: fields.topology?.[0].extraData.hostname,
        port: fields.nodePort,
    };
    switch (cluster.clusterType) {
        case CcClusterType.TYPE_GALERA:
        case CcClusterType.TYPE_REPLICATION:
            return {
                ...data,
                ...node,
                component: 'mysqld',
            };
        case CcClusterType.TYPE_TIMESCALEDB:
        case CcClusterType.TYPE_POSTGRESQL:
            return {
                ...data,
                node: { ...node, customlogfile: fields.customLogFile },
            };
    }
    return data;
}
