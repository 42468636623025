import React, { useState } from 'react';
import CcUser from '../../services/models/CcUser';
import { Form, Input } from 'antd';
import FormFooter from '../../common/FormFooter';
import ModalDefaultForm from '../../common/ModalDefaultForm';
import useForm from 'antd/lib/form/hooks/useForm';
import TimezoneSelect from '../../common/DataEntry/TimezoneSelect';
import CmonUsersService from '../../services/cmon/CmonUsersService';
import { useDispatch } from 'react-redux';
import { setUser } from '../../appReducer';
import { notifyError } from '../Notifications/uiNotification';
import moment from 'moment';

export default UserSettingsModal;

type UserSettingsFieldsType = {
    email: string;
    firstName: string;
    lastName: string;
    timezone: string;
};
export type UserSettingsModalProps = {
    user: CcUser;
    onSuccess?: () => void;
    onCancel?: () => void;
};

function UserSettingsModal({
    user,
    onSuccess,
    onCancel,
    ...rest
}: UserSettingsModalProps) {
    const [loading, setLoading] = useState(false);
    const [form] = useForm<UserSettingsFieldsType>();
    const dispatch = useDispatch();

    const handleCancel = () => {
        onCancel?.();
    };

    const handleSubmit = async (fields: UserSettingsFieldsType) => {
        try {
            setLoading(true);
            const timezone = fields.timezone
                ? moment.tz(fields.timezone)
                : undefined;
            const { user: newUser } = await CmonUsersService.setUser({
                user: {
                    class_name: 'CmonUser',
                    user_name: user.userName,
                    email_address: fields.email,
                    first_name: fields.firstName,
                    last_name: fields.lastName,
                    timezone: {
                        class_name: 'CmonTimeZone',
                        abbreviation: timezone?.zoneAbbr(),
                        name: fields.timezone,
                        offset: timezone?.utcOffset(),
                        use_dst: false,
                    },
                },
            });
            setLoading(false);
            if (newUser) {
                dispatch(setUser(newUser));
                moment.tz.setDefault(newUser?.timezone?.name || 'UTC');
            }
            onSuccess?.();
        } catch (e: any) {
            notifyError({
                content: e.message,
            });
            setLoading(false);
            onCancel?.();
        }
    };

    return (
        <ModalDefaultForm
            title="Profile settings"
            width={450}
            form={form}
            onCancel={handleCancel}
            defaultVisible={true}
            bodyStyle={{ padding: '20px' }}
            loading={loading}
        >
            <Form
                form={form}
                onFinish={handleSubmit}
                layout="vertical"
                initialValues={{
                    email: user.emailAddress,
                    firstName: user.firstName,
                    lastName: user.lastName,
                    timezone: user.timezone?.name,
                }}
            >
                <Form.Item
                    name="email"
                    label="Email"
                    rules={[
                        {
                            required: true,
                            type: 'email',
                            message: 'Please enter email address',
                        },
                    ]}
                >
                    <Input placeholder="Enter email address" />
                </Form.Item>
                <Form.Item name="firstName" label="First name">
                    <Input placeholder="Enter first name" />
                </Form.Item>
                <Form.Item name="lastName" label="Last name">
                    <Input placeholder="Enter last name" />
                </Form.Item>
                <Form.Item name="timezone" label="Time zone">
                    <TimezoneSelect />
                </Form.Item>
                <FormFooter
                    loading={loading}
                    submitButtonText="Save"
                    showCancelButton
                    showSubmitButton
                    onCancel={handleCancel}
                />
            </Form>
        </ModalDefaultForm>
    );
}
