import CcHaProxyNode from '../../../../services/models/CcHaProxyNode';
import { CmonHaProxyStatsProps } from '../../../../services/cmon/models/CmonHaProxyStats';

export type Operation =
    | string
    | {
          name: string;
          data: {
              action: string;
              node: {
                  hostname: string | undefined;
                  port: number | undefined;
              };
              backend_name: string | undefined;
              node_address: string | undefined;
          };
      };

export function prepareHAPRoxyTableData(
    haProxyObject: any,
    node: CcHaProxyNode
) {
    return Object.keys(haProxyObject).map((pxname, index) => {
        const data = {
            key: `${index}`,
            rowName: pxname,
            operation: '',
            children: haProxyObject[pxname].map(
                (data: CmonHaProxyStatsProps, index: number) => {
                    let role = '';
                    if (
                        data.svname !== 'FRONTEND' &&
                        data.svname !== 'BACKEND'
                    ) {
                        if (data.act === 1) {
                            role = 'Active';
                        } else if (data.bck === 1) {
                            role = 'Backup';
                        }
                    }
                    let operation: Operation = '';
                    if (data.pxname !== 'admin_page') {
                        if (
                            data.svname !== 'FRONTEND' &&
                            data.svname !== 'BACKEND' &&
                            data.status !== 'DOWN'
                        ) {
                            operation = {
                                name:
                                    data.status === 'UP'
                                        ? 'Disable Node'
                                        : 'Enable Node',
                                data: {
                                    action:
                                        data.status === 'UP'
                                            ? 'disableNode'
                                            : 'enableNode',
                                    node: {
                                        hostname: node.hostname,
                                        port: node.port,
                                    },
                                    backend_name: data.pxname,
                                    node_address: data.svname,
                                },
                            };
                        } else {
                            operation = '';
                        }
                    }
                    return {
                        key: `${pxname} ${data.svname}`,
                        rowName: data.svname,
                        status: data.status,
                        role: role,
                        curQueue: data.qcur,
                        maxQueue: data.qmax,
                        limitQueue: data.qlimit,
                        curRate: data.rate,
                        maxRate: data.rate_max,
                        limitRate: data.rate_max,
                        curSession: data.scur,
                        maxSession: data.smax,
                        limitSession: data.slim,
                        inBytes: data.bin,
                        outBytes: data.bout,
                        operation: operation,
                    };
                }
            ),
        };
        return data;
    });
}

export function getMenuText(key: string) {
    switch (key) {
        case '10000':
            return '10 sec';
        case '20000':
            return '20 sec';
        case '30000':
            return '30 sec';
        case '60000':
            return '1 min';
        case '300000':
            return '5 min';
        default:
            return 'Refresh';
    }
}
