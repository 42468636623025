import React, { useEffect, useState } from 'react';
import { Form } from 'antd';
import merge from 'deepmerge';
import {
    ClusterConfiguratorFormValues,
    ClusterConfigurator,
} from '../ClusterConfigurator';
import { CcClusterType } from '../../../../services/models/CcCluster';
import CmonJobsService from '../../../../services/cmon/CmonJobsService';
import { notifyError } from '../../../Notifications/uiNotification';
import WizardFormConfiguration from '@severalnines/bar-frontend-components/build/lib/Navigation/Wizard/WizardFormConfiguration';
import { getImportClusterDetailsValidate } from './ImportClusterDetails';

import WizardSummary from '../WizardSummary';
import ImportSshConfiguration from './ImportSshConfiguration';
import { getSshCredentialsValidate } from '../FormParts/SshCredentials';
import RedisImportConfigurator from './Redis/RedisImportConfigurator';
import MysqlReplicationImportConfigurator from './MysqlReplication/MysqlReplicationImportConfigurator';
import MssqlImportConfigurator from './Mssql/MssqlImportConfigurator';
import MysqlGaleraImportConfigurator from './MysqlGalera/MysqlGaleraImportConfigurator';
import PostgreSqlImportConfigurator from './PostgreSql/PostgreSqlImportConfigurator';
import TimescaleDbImportConfigurator from './TimescaleDb/TimescaleDbImportConfigurator';
import MongoImportConfigurator from './Mongo/MongoImportConfigurator';
import MongoShardsImportConfigurator from './MongoShards/MongoShardsImportConfigurator';
import { useDispatch } from 'react-redux';
import { addNewRunningJob } from '../../../../appReducer';

export default ImportWizard;

export enum ImportWizardStep {
    DETAILS = 'details',
    SSH_CONFIG = 'sshConfig',
    NODE_CONFIG = 'nodeConfig',
    TOPOLOGY = 'topology',
    PREVIEW = 'preview',
}
export type DeploymentWizardProps = {
    initialValues?: ClusterConfiguratorFormValues;
    defaultActiveStep?: ImportWizardStep;
    onSuccess?: () => void;
    onError?: (err: Error) => void;
    onCancel?: () => void;
    onTouchedChange?: (touched: boolean) => void;
    onStepErrorInsist?: (err: Error) => void;
    clusterType: CcClusterType;
};

let valuesCacheMap: { [key: string]: any } = {};

function ImportWizard({
    initialValues,
    defaultActiveStep,
    onSuccess,
    onError,
    onCancel,
    onTouchedChange,
    onStepErrorInsist,
    clusterType,
}: DeploymentWizardProps) {
    const [form] = Form.useForm();

    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    const configurator = getImportConfigurator(clusterType);

    useEffect(() => {
        return () => {
            valuesCacheMap = {};
        };
    }, []);

    useEffect(() => {
        form.resetFields();
        form.setFieldsValue(
            merge(
                valuesCacheMap[clusterType],
                merge(configurator.getDefaults(), initialValues || {})
            )
        );
    }, [clusterType]);

    const handleSubmit = async () => {
        const formValues = form.getFieldsValue(true);
        try {
            setLoading(true);
            // @todo use useCreateJob and remove dispatch(addRunningJob(job));
            const { job } = await CmonJobsService.createAddClusterJobInstance(
                0,
                {
                    job_data: configurator.getJobData(formValues),
                },
                configurator.getJobOptions(formValues)
            );
            setLoading(false);
            if (onSuccess) {
                onSuccess();
            }
            dispatch(addNewRunningJob(job));
        } catch (err: any) {
            setLoading(false);
            if (onError) {
                onError(err);
            }
            notifyError({ size: 'large', content: err.message });
        }
    };
    const handleValuesChange = (values: any) => {
        valuesCacheMap[clusterType] = values;
    };

    const handleCancel = () => {
        if (onCancel) {
            onCancel();
        }
    };
    return (
        <div className="ImportWizard">
            <WizardFormConfiguration
                form={form}
                activeKey={defaultActiveStep}
                onValuesChange={handleValuesChange}
                onSubmit={handleSubmit}
                onCancel={handleCancel}
                initialValues={merge(
                    configurator.getDefaults(),
                    initialValues || {}
                )}
                loading={loading}
                onStepErrorInsist={onStepErrorInsist}
                onTouchedChange={onTouchedChange}
                steps={[
                    <WizardFormConfiguration.Step
                        key={ImportWizardStep.DETAILS}
                        title="Cluster details"
                        subTitle=" "
                        validate={getImportClusterDetailsValidate()}
                        hasRequiredFields={true}
                    >
                        {configurator?.getImportDetailsStep({
                            form,
                            configurator,
                        })}
                    </WizardFormConfiguration.Step>,
                    <WizardFormConfiguration.Step
                        key={ImportWizardStep.SSH_CONFIG}
                        title="SSH configuration"
                        subTitle=" "
                        validate={getSshCredentialsValidate()}
                        hasRequiredFields={true}
                    >
                        <ImportSshConfiguration form={form} />
                    </WizardFormConfiguration.Step>,
                    <WizardFormConfiguration.Step
                        key={ImportWizardStep.NODE_CONFIG}
                        title={
                            <span style={{ whiteSpace: 'nowrap' }}>
                                Node configuration
                            </span>
                        }
                        subTitle=" "
                        validate={configurator.getNodeConfigurationValidate(
                            form
                        )}
                        hasRequiredFields={true}
                    >
                        {configurator.getNodeConfigurationStep({ form })}
                    </WizardFormConfiguration.Step>,
                    <WizardFormConfiguration.Step
                        key={ImportWizardStep.TOPOLOGY}
                        title="Add nodes"
                        subTitle=" "
                        validate={() => {
                            return configurator.getTopologyValidate(form);
                        }}
                        hasRequiredFields={true}
                    >
                        {configurator.getTopologyStep(form)}
                    </WizardFormConfiguration.Step>,
                    <WizardFormConfiguration.Step
                        key={ImportWizardStep.PREVIEW}
                        title="Preview"
                        subTitle=" "
                    >
                        <Form.Item noStyle shouldUpdate={true}>
                            {() => (
                                <WizardSummary
                                    configurator={configurator}
                                    clusterType={clusterType}
                                    form={form}
                                />
                            )}
                        </Form.Item>
                    </WizardFormConfiguration.Step>,
                ]}
            />
        </div>
    );
}

export function getImportConfigurator(clusterType?: CcClusterType) {
    switch (clusterType) {
        case CcClusterType.TYPE_REPLICATION:
            return MysqlReplicationImportConfigurator;
        case CcClusterType.TYPE_GALERA:
            return MysqlGaleraImportConfigurator;
        case CcClusterType.TYPE_POSTGRESQL:
            return PostgreSqlImportConfigurator;
        case CcClusterType.TYPE_TIMESCALEDB:
            return TimescaleDbImportConfigurator;
        case CcClusterType.TYPE_MONGODB:
            return MongoImportConfigurator;
        case CcClusterType.TYPE_MONGODB_SHARDS:
            return MongoShardsImportConfigurator;
        case CcClusterType.TYPE_REDIS:
            return RedisImportConfigurator;
        case CcClusterType.TYPE_MSSQL_SINGLE:
            return MssqlImportConfigurator;
        default:
            return ClusterConfigurator;
    }
}
