const dashboard = {
    title: 'MySQL Server - User stats',
    meta: {
        slug: 'mysql-server-userstats',
        supportClusterTypes: [
            'replication',
            'galera',
            'mysql_single',
            'mysqlcluster',
            'group_replication',
        ],
    },
    panels: [
        {
            title: 'MySQL uptime',
            type: 'singlestat',
            gridPos: {
                h: 4,
                w: 6,
                x: 0,
                y: 0,
            },
            options: {
                decimals: 1,
                format: 'duration',
                type: 'line',
                legend: {},
                chart: {
                    full: false,
                    show: false,
                },
                valueMaps: [],
            },
            targets: [
                {
                    expr: 'mysql_global_status_uptime{instance=~"$instance"}',
                    legendFormat: '',
                },
            ],
        },
        {
            title: 'Queries',
            type: 'singlestat',
            gridPos: {
                h: 4,
                w: 6,
                x: 0,
                y: 0,
            },
            options: {
                decimals: 0,
                format: 'short',
                type: 'line',
                legend: {},
                chart: {
                    full: false,
                    show: true,
                },
                postfix: ' / sec',
                valueMaps: [],
            },
            targets: [
                {
                    expr:
                        'irate(mysql_global_status_queries{instance=~"$instance"}[5m])',
                    legendFormat: '',
                },
            ],
        },
        {
            title: 'Connections used',
            type: 'singlestat',
            gridPos: {
                h: 4,
                w: 6,
                x: 0,
                y: 0,
            },
            options: {
                decimals: 2,
                format: 'percent',
                type: 'line',
                legend: {},
                chart: {
                    full: false,
                    show: true,
                },
                valueMaps: [],
            },
            targets: [
                {
                    expr:
                        '100 * mysql_global_status_threads_connected{instance=~"$instance"} / mysql_global_variables_max_connections{instance=~"$instance"}',
                    legendFormat: '',
                },
            ],
        },
        {
            title: 'Innodb bufferpool hit ratio',
            type: 'singlestat',
            gridPos: {
                h: 4,
                w: 6,
                x: 0,
                y: 0,
            },
            options: {
                decimals: 0,
                format: 'number',
                type: 'line',
                legend: {},
                chart: {
                    full: false,
                    show: false,
                },
                postfix: ' / 1,000',
                valueMaps: [],
            },
            targets: [
                {
                    expr:
                        '1000*mysql_global_status_innodb_buffer_pool_read_requests{instance=~"$instance"} / (mysql_global_status_innodb_buffer_pool_reads{instance=~"$instance"} + mysql_global_status_innodb_buffer_pool_read_requests{instance=~"$instance"})',
                    legendFormat: '',
                },
            ],
        },
        {
            title: 'Total connections',
            //            "title": "Connections per sec",
            type: 'chart',
            gridPos: {
                h: 9,
                w: 8,
                x: 0,
                y: 0,
            },
            options: {
                decimals: 0,
                format: 'short',
                type: 'line',
                legend: {
                    alignAsTable: true,
                },
                yaxis: [
                    {
                        format: 'short',
                        max: null,
                        min: 0,
                    },
                    {
                        format: 'short',
                        max: null,
                        min: 0,
                    },
                ],
                stack: false,
            },
            targets: [
                {
                    //                  "expr": "irate(mysql_info_schema_user_statistics_total_connections{instance=~\"$instance\"}[5m])",
                    expr:
                        'mysql_info_schema_user_statistics_total_connections{instance=~"$instance"}',
                    legendFormat: '{{ user }}',
                },
            ],
        },
        {
            title: 'Access denied',
            //            "title": "Access Denied per sec",
            type: 'chart',
            gridPos: {
                h: 9,
                w: 8,
                x: 0,
                y: 0,
            },
            options: {
                decimals: 0,
                format: 'short',
                type: 'line',
                legend: {
                    alignAsTable: true,
                },
                yaxis: [
                    {
                        format: 'short',
                        max: null,
                        min: 0,
                    },
                    {
                        format: 'short',
                        max: null,
                        min: 0,
                    },
                ],
                stack: false,
            },
            targets: [
                {
                    //                  "expr": "irate(mysql_info_schema_user_statistics_access_denied_total{instance=~\"$instance\"}[5m])",
                    expr:
                        'mysql_info_schema_user_statistics_access_denied_total{instance=~"$instance"}',
                    legendFormat: '{{ user }}',
                },
            ],
        },
        {
            title: 'Total SSL connections',
            //            "title": "SSL Connections per sec",
            type: 'chart',
            gridPos: {
                h: 9,
                w: 8,
                x: 0,
                y: 0,
            },
            options: {
                decimals: 0,
                format: 'short',
                type: 'line',
                legend: {
                    alignAsTable: true,
                },
                yaxis: [
                    {
                        format: 'short',
                        max: null,
                        min: 0,
                    },
                    {
                        format: 'short',
                        max: null,
                        min: 0,
                    },
                ],
                stack: false,
            },
            targets: [
                {
                    //           "expr": "irate(mysql_info_schema_user_statistics_total_ssl_connections_total{instance=~\"$instance\"}[5m])",
                    expr:
                        'mysql_info_schema_user_statistics_total_ssl_connections_total{instance=~"$instance"}',
                    legendFormat: '{{ user }}',
                },
            ],
        },
        {
            title: 'Concurrent connections',
            type: 'chart',
            gridPos: {
                h: 9,
                w: 8,
                x: 0,
                y: 0,
            },
            options: {
                decimals: 0,
                format: 'short',
                type: 'line',
                legend: {
                    alignAsTable: true,
                },
                yaxis: [
                    {
                        format: 'short',
                        max: null,
                        min: 0,
                    },
                    {
                        format: 'short',
                        max: null,
                        min: 0,
                    },
                ],
                stack: false,
            },
            targets: [
                {
                    //           "expr": "irate(mysql_info_schema_user_statistics_concurrent_connections{instance=~\"$instance\"}[5m])",
                    expr:
                        'mysql_info_schema_user_statistics_concurrent_connections{instance=~"$instance"}',
                    legendFormat: '{{ user }}',
                },
            ],
        },
        {
            title: 'Total lost connections',
            //            "title": "Lost Connections per sec",
            type: 'chart',
            gridPos: {
                h: 9,
                w: 8,
                x: 0,
                y: 0,
            },
            options: {
                decimals: 0,
                format: 'short',
                type: 'line',
                legend: {
                    alignAsTable: true,
                },
                yaxis: [
                    {
                        format: 'short',
                        max: null,
                        min: 0,
                    },
                    {
                        format: 'short',
                        max: null,
                        min: 0,
                    },
                ],
                stack: false,
            },
            targets: [
                {
                    //           "expr": "irate(mysql_info_schema_user_statistics_lost_connections_total{instance=~\"$instance\"}[5m])",
                    expr:
                        'mysql_info_schema_user_statistics_lost_connections_total{instance=~"$instance"}',
                    legendFormat: '{{ user }}',
                },
            ],
        },
        {
            title: 'Total denied connections',
            //            "title": "Denied Connections per sec",
            type: 'chart',
            gridPos: {
                h: 9,
                w: 8,
                x: 0,
                y: 0,
            },
            options: {
                decimals: 0,
                format: 'short',
                type: 'line',
                legend: {
                    alignAsTable: true,
                },
                yaxis: [
                    {
                        format: 'short',
                        max: null,
                        min: 0,
                    },
                    {
                        format: 'short',
                        max: null,
                        min: 0,
                    },
                ],
                stack: false,
            },
            targets: [
                {
                    //           "expr": "irate(mysql_info_schema_user_statistics_denied_connections_total{instance=~\"$instance\"}[5m])",
                    expr:
                        'mysql_info_schema_user_statistics_denied_connections_total{instance=~"$instance"}',
                    legendFormat: '{{ user }}',
                },
            ],
        },
        {
            title: 'Connected time',
            type: 'chart',
            gridPos: {
                h: 9,
                w: 8,
                x: 0,
                y: 0,
            },
            options: {
                decimals: 0,
                format: 'short',
                type: 'line',
                legend: {
                    alignAsTable: true,
                },
                yaxis: [
                    {
                        format: 'short',
                        max: null,
                        min: 0,
                    },
                    {
                        format: 'short',
                        max: null,
                        min: 0,
                    },
                ],
                stack: false,
            },
            targets: [
                {
                    //           "expr": "irate(mysql_info_schema_user_statistics_connected_time_seconds_total{instance=~\"$instance\"}[5m])",
                    expr:
                        'mysql_info_schema_user_statistics_connected_time_seconds_total{instance=~"$instance"}',
                    legendFormat: '{{ user }}',
                },
            ],
        },
        {
            title: 'Busy time',
            type: 'chart',
            gridPos: {
                h: 9,
                w: 8,
                x: 0,
                y: 0,
            },
            options: {
                decimals: 0,
                format: 'short',
                type: 'line',
                legend: {
                    alignAsTable: true,
                },
                yaxis: [
                    {
                        format: 'short',
                        max: null,
                        min: 0,
                    },
                    {
                        format: 'short',
                        max: null,
                        min: 0,
                    },
                ],
                stack: false,
            },
            targets: [
                {
                    //           "expr": "irate(mysql_info_schema_user_statistics_busy_seconds_total{instance=~\"$instance\"}[5m])",
                    expr:
                        'mysql_info_schema_user_statistics_busy_seconds_total{instance=~"$instance"}',
                    legendFormat: '{{ user }}',
                },
            ],
        },
        {
            title: 'CPU time',
            type: 'chart',
            gridPos: {
                h: 9,
                w: 8,
                x: 0,
                y: 0,
            },
            options: {
                decimals: 0,
                format: 'short',
                type: 'line',
                legend: {
                    alignAsTable: true,
                },
                yaxis: [
                    {
                        format: 'short',
                        max: null,
                        min: 0,
                    },
                    {
                        format: 'short',
                        max: null,
                        min: 0,
                    },
                ],
                stack: false,
            },
            targets: [
                {
                    //           "expr": "irate(mysql_info_schema_user_statistics_cpu_time_seconds_total{instance=~\"$instance\"}[5m])",
                    expr:
                        'mysql_info_schema_user_statistics_cpu_time_seconds_total{instance=~"$instance"}',
                    legendFormat: '{{ user }}',
                },
            ],
        },
        {
            title: 'Bytes received per sec',
            type: 'chart',
            gridPos: {
                h: 9,
                w: 8,
                x: 0,
                y: 0,
            },
            options: {
                decimals: 0,
                format: 'short',
                type: 'line',
                legend: {
                    alignAsTable: true,
                },
                yaxis: [
                    {
                        format: 'short',
                        max: null,
                        min: 0,
                    },
                    {
                        format: 'short',
                        max: null,
                        min: 0,
                    },
                ],
                stack: false,
            },
            targets: [
                {
                    expr:
                        'irate(mysql_info_schema_user_statistics_bytes_received_total{instance=~"$instance"}[5m])',
                    legendFormat: '{{ user }}',
                },
            ],
        },
        {
            title: 'Bytes sent per sec',
            type: 'chart',
            gridPos: {
                h: 9,
                w: 8,
                x: 0,
                y: 0,
            },
            options: {
                decimals: 0,
                format: 'short',
                type: 'line',
                legend: {
                    alignAsTable: true,
                },
                yaxis: [
                    {
                        format: 'short',
                        max: null,
                        min: 0,
                    },
                    {
                        format: 'short',
                        max: null,
                        min: 0,
                    },
                ],
                stack: false,
            },
            targets: [
                {
                    expr:
                        'irate(mysql_info_schema_user_statistics_bytes_sent_total{instance=~"$instance"}[5m])',
                    legendFormat: '{{ user }}',
                },
            ],
        },
        {
            title: 'Selects per sec',
            type: 'chart',
            gridPos: {
                h: 9,
                w: 8,
                x: 0,
                y: 0,
            },
            options: {
                decimals: 0,
                format: 'short',
                type: 'line',
                legend: {
                    alignAsTable: true,
                },
                yaxis: [
                    {
                        format: 'short',
                        max: null,
                        min: 0,
                    },
                    {
                        format: 'short',
                        max: null,
                        min: 0,
                    },
                ],
                stack: false,
            },
            targets: [
                {
                    expr:
                        'irate(mysql_info_schema_user_statistics_select_commands_total{instance=~"$instance"}[5m])',
                    legendFormat: '{{ user }}',
                },
            ],
        },
        {
            title: 'Updates per sec',
            type: 'chart',
            gridPos: {
                h: 9,
                w: 8,
                x: 0,
                y: 0,
            },
            options: {
                decimals: 0,
                format: 'short',
                type: 'line',
                legend: {
                    alignAsTable: true,
                },
                yaxis: [
                    {
                        format: 'short',
                        max: null,
                        min: 0,
                    },
                    {
                        format: 'short',
                        max: null,
                        min: 0,
                    },
                ],
                stack: false,
            },
            targets: [
                {
                    expr:
                        'irate(mysql_info_schema_user_statistics_update_commands_total{instance=~"$instance"}[5m])',
                    legendFormat: '{{ user }}',
                },
            ],
        },
        {
            title: 'Commits per sec',
            type: 'chart',
            gridPos: {
                h: 9,
                w: 8,
                x: 0,
                y: 0,
            },
            options: {
                decimals: 0,
                format: 'short',
                type: 'line',
                legend: {
                    alignAsTable: true,
                },
                yaxis: [
                    {
                        format: 'short',
                        max: null,
                        min: 0,
                    },
                    {
                        format: 'short',
                        max: null,
                        min: 0,
                    },
                ],
                stack: false,
            },
            targets: [
                {
                    expr:
                        'irate(mysql_info_schema_user_statistics_commit_transactions_total{instance=~"$instance"}[5m])',
                    legendFormat: '{{ user }}',
                },
            ],
        },
        {
            title: 'Rollbacks per sec',
            type: 'chart',
            gridPos: {
                h: 9,
                w: 8,
                x: 0,
                y: 0,
            },
            options: {
                decimals: 0,
                format: 'short',
                type: 'line',
                legend: {
                    alignAsTable: true,
                },
                yaxis: [
                    {
                        format: 'short',
                        max: null,
                        min: 0,
                    },
                    {
                        format: 'short',
                        max: null,
                        min: 0,
                    },
                ],
                stack: false,
            },
            targets: [
                {
                    expr:
                        'irate(mysql_info_schema_user_statistics_rollback_transactions_total{instance=~"$instance"}[5m])',
                    legendFormat: '{{ user }}',
                },
            ],
        },
        {
            title: 'Table rows read per sec',
            type: 'chart',
            gridPos: {
                h: 9,
                w: 8,
                x: 0,
                y: 0,
            },
            options: {
                decimals: 0,
                format: 'short',
                type: 'line',
                legend: {
                    alignAsTable: true,
                },
                yaxis: [
                    {
                        format: 'short',
                        max: null,
                        min: 0,
                    },
                    {
                        format: 'short',
                        max: null,
                        min: 0,
                    },
                ],
                stack: false,
            },
            targets: [
                {
                    expr:
                        'irate(mysql_info_schema_user_statistics_table_rows_read_total{instance=~"$instance"}[5m])',
                    legendFormat: '{{ user }}',
                },
            ],
        },
        {
            title: 'Rows fetched per sec',
            type: 'chart',
            gridPos: {
                h: 9,
                w: 8,
                x: 0,
                y: 0,
            },
            options: {
                decimals: 0,
                format: 'short',
                type: 'line',
                legend: {
                    alignAsTable: true,
                },
                yaxis: [
                    {
                        format: 'short',
                        max: null,
                        min: 0,
                    },
                    {
                        format: 'short',
                        max: null,
                        min: 0,
                    },
                ],
                stack: false,
            },
            targets: [
                {
                    expr:
                        'irate(mysql_info_schema_user_statistics_rows_fetched_total{instance=~"$instance"}[5m])',
                    legendFormat: '{{ user }}',
                },
            ],
        },
        {
            title: 'Rows updated per sec',
            type: 'chart',
            gridPos: {
                h: 9,
                w: 8,
                x: 0,
                y: 0,
            },
            options: {
                decimals: 0,
                format: 'short',
                type: 'line',
                legend: {
                    alignAsTable: true,
                },
                yaxis: [
                    {
                        format: 'short',
                        max: null,
                        min: 0,
                    },
                    {
                        format: 'short',
                        max: null,
                        min: 0,
                    },
                ],
                stack: false,
            },
            targets: [
                {
                    expr:
                        'irate(mysql_info_schema_user_statistics_rows_updated_total{instance=~"$instance"}[5m])',
                    legendFormat: '{{ user }}',
                },
            ],
        },
    ],
    templating: {
        list: [
            {
                datasource: 'clustercontrol',
                multi: false,
                label: 'Host',
                name: 'instance',
                query: "host:$[nodes[nodetype='mysql' or nodetype='galera']]",
                optionValue: 'hostname',
                optionText: 'hostname',
                sort: 1,
                isInstancePicker: true,
            },
        ],
    },
    schemaVersion: 1,
};
export default dashboard;
