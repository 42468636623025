import { Row, RowProps } from 'antd';
import React from 'react';
import './AppRow.less';
import classNames from 'classnames';

export default AppRow;

export type AppRowProps = RowProps & {
    withDivider?: boolean;
    className?: string;
};

function AppRow({ withDivider = false, className = '', ...rest }: AppRowProps) {
    return (
        <Row
            className={classNames(
                'AppRow',
                { 'AppRow--with-divider': withDivider },
                className
            )}
            {...rest}
        />
    );
}
