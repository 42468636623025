import React, { useRef } from 'react';
import { Space, Tabs } from 'antd';
import { UserManagementIcon } from '../../common/icons/icons';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import LicenseSpaceWrapper from '../License/LicenseSpaceWrapper';
import UsersList, { UsersListApi } from './UsersList';
import GroupList, { GroupListApi } from './GroupList';
import UserGroupCreateButton from './UserGroupCreateButton';
import AppPageWrapper from '../../common/Layout/AppPageWrapper';
import useTree from './useTree';
import { WizardConfigurationModalOption } from '../../common/Navigation/WizardConfigurationModal';
import { UserGroupCreateOptionType } from './UserGroupCreateForm';
import useCurrentUser from '../User/useCurrentUser';
import UserAclManageUsers from '../User/UserAclManageUsers';
import LdapPage from './Ldap/LdapPage';

export enum UserManagementPageListType {
    USERS = 'users',
    GROUPS = 'teams',
    LDAP = 'ldap',
}
export default UserManagementPage;
export type UserManagementPageProps = {
    defaultSection?: UserManagementPageListType | string;
};
function UserManagementPage({ defaultSection }: UserManagementPageProps) {
    const navigate = useNavigate();
    const usersListRef = useRef<UsersListApi>();
    const groupListRef = useRef<GroupListApi>();
    const {
        section = defaultSection || UserManagementPageListType.USERS,
    } = useParams<{
        section: UserManagementPageListType;
    }>();
    const { refresh: refreshTree } = useTree({
        name: 'tree-from-user-management',
    });
    const { canManageLdapSettings, canManageUsers } = useCurrentUser();

    const handleUserGroupCreateSuccess = (
        option: WizardConfigurationModalOption | undefined
    ) => {
        usersListRef.current?.refresh();
        groupListRef.current?.refresh();
        refreshTree();
        if (option) {
            if (option.key === UserGroupCreateOptionType.USER) {
                navigate('/user-management/users');
            } else {
                navigate('/user-management/teams');
            }
        }
    };
    return (
        <AppPageWrapper
            className="UserManagementPage"
            title={
                <span>
                    <UserManagementIcon /> User management
                </span>
            }
            headerMenu={
                <Space size={'large'}>
                    <UserAclManageUsers>
                        <UserGroupCreateButton
                            formContainerProps={{
                                onSuccess: handleUserGroupCreateSuccess,
                            }}
                        />
                    </UserAclManageUsers>
                </Space>
            }
            transparentBackground={true}
            noPadding={true}
            horizontalScroll={true}
        >
            <LicenseSpaceWrapper>
                <Tabs
                    onTabClick={(key) => {
                        navigate(`/user-management/${key}`);
                    }}
                    activeKey={section}
                    destroyInactiveTabPane={true}
                >
                    {canManageUsers() ? (
                        <Tabs.TabPane
                            tab="Users"
                            key={UserManagementPageListType.USERS}
                        >
                            <UsersList ref={usersListRef} />
                        </Tabs.TabPane>
                    ) : null}
                    {canManageUsers() ? (
                        <Tabs.TabPane
                            tab="Teams"
                            key={UserManagementPageListType.GROUPS}
                        >
                            <GroupList ref={groupListRef} />
                        </Tabs.TabPane>
                    ) : null}
                    {canManageLdapSettings() ? (
                        <Tabs.TabPane
                            tab="LDAP"
                            key={UserManagementPageListType.LDAP}
                        >
                            <LdapPage />
                        </Tabs.TabPane>
                    ) : null}
                </Tabs>
            </LicenseSpaceWrapper>
        </AppPageWrapper>
    );
}
