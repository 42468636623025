import React from 'react';
import { Col, Form, Input, Row, Space } from 'antd';
import CcBackup from '../../../../services/models/CcBackup';
import { CcClusterType } from '../../../../services/models/CcCluster';
import SemiSynchronousReplicationSwitch from '../../../../common/Form/Fields/SemiSynchronousReplicationSwitch';
import RebuildFromBackupSelectField from './FormParts/RebuildFromBackupSelectField';
import DelayReplicaField from '../../../../common/Form/Fields/DelayReplicaField';
import IncludeInLoadBalancerSwitch from '../../../../common/Form/Fields/IncludeInLoadBalancerSwitch';
import InfoIcon from '@severalnines/bar-frontend-components/build/lib/General/InfoIcon';

export default AddNodeAdvancedForm;

type AddNodeAdvancedFormSubFields = {
    delayReplicaSeconds?: number;
    backup?: CcBackup;
};
type AddNodeAdvancedFormMainFields = {
    delayReplica?: boolean;
    rebuildFromBackup?: boolean;
    includeLB?: boolean;
    semiSynchronous?: boolean;
    clusterName?: string;
};

export type AddNodeAdvancedFormFields = AddNodeAdvancedFormMainFields &
    AddNodeAdvancedFormSubFields;

export type AddNodeAdvancedFormProps = {
    clusterId: number;
    availableFields?: (keyof AddNodeAdvancedFormMainFields)[];
};

function AddNodeAdvancedForm({
    clusterId,
    availableFields = ['includeLB'],
}: AddNodeAdvancedFormProps) {
    return (
        <div className="AddNodeAdvancedForm">
            <Row gutter={[24, 0]}>
                <Col span={24}>
                    <h3>Advanced settings</h3>
                </Col>
            </Row>
            <Row gutter={[24, 0]}>
                {availableFields?.includes('rebuildFromBackup') ? (
                    <Col xs={24} sm={24} md={12}>
                        <RebuildFromBackupSelectField clusterId={clusterId} />
                    </Col>
                ) : null}
                {availableFields?.includes('delayReplica') ? (
                    <Col xs={24} sm={24} md={12}>
                        <DelayReplicaField />
                    </Col>
                ) : null}

                {availableFields?.includes('includeLB') ? (
                    <Col xs={24} sm={24} md={12}>
                        <IncludeInLoadBalancerSwitch />
                    </Col>
                ) : null}
                {availableFields?.includes('semiSynchronous') ? (
                    <Col xs={24} sm={24} md={12}>
                        <SemiSynchronousReplicationSwitch />
                    </Col>
                ) : null}
                {availableFields?.includes('clusterName') ? (
                    <Col xs={24} sm={24} md={12}>
                        <Form.Item
                            name="clusterName"
                            label={
                                <Space>
                                    <span>Instance name</span>
                                    <InfoIcon
                                        info={
                                            <span>
                                                Sets "cluster_name" property in
                                                the generated postgres
                                                configuration, a name that
                                                identifies this database cluster
                                                (instance) for various purposes.
                                                The cluster name appears in the
                                                process title for all server
                                                processes in this cluster.
                                                Moreover, it is the default
                                                application name for a standby
                                                connection
                                            </span>
                                        }
                                    />
                                </Space>
                            }
                        >
                            <Input placeholder="Enter instance name" />
                        </Form.Item>
                    </Col>
                ) : null}
            </Row>
        </div>
    );
}

export function getReplicationAdvancedForm(
    clusterId: number,
    clusterType: CcClusterType
) {
    switch (clusterType) {
        case CcClusterType.TYPE_REPLICATION:
        case CcClusterType.TYPE_GALERA:
            return (
                <AddNodeAdvancedForm
                    clusterId={clusterId}
                    availableFields={[
                        'delayReplica',
                        'rebuildFromBackup',
                        'includeLB',
                        'semiSynchronous',
                    ]}
                />
            );
        case CcClusterType.TYPE_TIMESCALEDB:
        case CcClusterType.TYPE_POSTGRESQL:
            return (
                <AddNodeAdvancedForm
                    clusterId={clusterId}
                    availableFields={[
                        'includeLB',
                        'semiSynchronous',
                        'clusterName',
                    ]}
                />
            );
        default:
            return undefined;
    }
}

export function getDatabaseNodeAdvancedForm(
    clusterId: number,
    clusterType: CcClusterType
) {
    switch (clusterType) {
        case CcClusterType.TYPE_GALERA:
            return (
                <AddNodeAdvancedForm
                    clusterId={clusterId}
                    availableFields={['rebuildFromBackup', 'includeLB']}
                />
            );
        default:
            return undefined;
    }
}

export function getAdvancedValidatingFields() {
    return ['backup'];
}
