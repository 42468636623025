import './ClusterFilter.less';
import React, { useEffect, useMemo } from 'react';
import { Button, Col, Form, Row, Select, Space } from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import ClusterTypeFormat from './ClusterTypeFormat';
import AppPopover from '../../common/Feedback/AppPopover';
import {
    AppState,
    AppStateClustersMap,
    AppStateNodeTypes,
} from '../../appReducer';
import { useSelector } from 'react-redux';
import NodeTypeFormat from '../Nodes/NodeTypeFormat';
import MultiSelect from '../../common/DataEntry/MultiSelect';
import StatusFormat, {
    StatusFormatStatus,
} from '@severalnines/bar-frontend-components/build/lib/Format/StatusFormat';
import { CcClusterType } from '../../services/models/CcCluster';
import { CcNodeType } from '../../services/models/CcNode';
import StatisticBadge from '../../common/StatisticBadge';
import SpaceWide from '../../common/SpaceWide';
import TypographyText from '../../common/TypographyText';
import { DownOutlined } from '@ant-design/icons';

const FILTER_PARAMS = ['clusterTypes', 'nodeTypes', 'state', 'tags'];
export type ClusterFilterFormFields = {
    clusterTypes?: CcClusterType[];
    nodeTypes?: CcNodeType[];
    state?: 'success' | 'warning' | 'error' | 'recovery-on' | 'recovery-off';
    tags?: string[];
};
export type ClusterFilterParams = ClusterFilterFormFields & {
    sort?: 'id' | 'name';
};
export default ClusterFilter;
export type ClusterFilterProps = {
    onChange?: (value: ClusterFilterParams) => void;
    filterParams?: ClusterFilterParams;
};

function ClusterFilter({
    onChange,
    filterParams,
    ...rest
}: ClusterFilterProps) {
    const [form] = Form.useForm<ClusterFilterFormFields>();
    const [clustersMap, nodeTypes]: [
        AppStateClustersMap,
        AppStateNodeTypes
    ] = useSelector(({ clusters, nodeTypes }: AppState) => [
        clusters,
        nodeTypes,
    ]);

    const clusters = useMemo(() => clustersMap?.toList().toArray(), [
        clustersMap,
    ]);
    const clusterTags = useMemo(
        () =>
            Array.from(
                new Set(clusters.map((cluster) => cluster.tagList).flat())
            ) as string[],
        [clusters]
    );

    const clusterTypes = useMemo(
        () =>
            Array.from(
                new Set(clusters?.map((cluster) => cluster.clusterType))
            ),
        [clusters]
    );

    const handleReset = () => {
        form.resetFields();
        onChange?.({});
    };

    const handleValuesChange = (
        changedValues: ClusterFilterParams,
        allValues: ClusterFilterParams
    ) => {
        onChange?.(allValues);
    };

    const handleSortChange = (sort: 'id' | 'name') => {
        onChange?.({ ...filterParams, sort });
    };

    useEffect(() => {
        form.setFieldsValue(filterParams || {});
    }, []);

    const filterCount = useMemo(
        () =>
            Object.entries(filterParams || {}).filter(
                ([key, value]) => !!value && FILTER_PARAMS.includes(key)
            ).length,
        [filterParams]
    );
    return (
        <Space size={15}>
            <AppPopover
                placement="leftTop"
                overlayClassName="ClusterFilter_overlay"
                content={
                    <Form
                        form={form}
                        layout="vertical"
                        onValuesChange={handleValuesChange}
                    >
                        <div className="ClusterFilter_content">
                            <Row gutter={[24, 0]}>
                                <Col span={12}>
                                    <FormItem
                                        labelCol={{ span: 24 }}
                                        label="Cluster types"
                                        name="clusterTypes"
                                    >
                                        <MultiSelect
                                            maxTagCount="responsive"
                                            options={clusterTypes.map(
                                                (type) => ({
                                                    label: (
                                                        <ClusterTypeFormat
                                                            type={type}
                                                            showIcon={true}
                                                        />
                                                    ),
                                                    value: type,
                                                })
                                            )}
                                        />
                                    </FormItem>
                                </Col>
                                <Col span={12}>
                                    <FormItem
                                        labelCol={{ span: 24 }}
                                        label="Node types"
                                        name="nodeTypes"
                                    >
                                        <MultiSelect
                                            maxTagCount="responsive"
                                            options={nodeTypes.map((type) => ({
                                                label: (
                                                    <NodeTypeFormat
                                                        type={type}
                                                        showIcon={true}
                                                    />
                                                ),
                                                value: type,
                                            }))}
                                        />
                                    </FormItem>
                                </Col>
                                <Col span={12}>
                                    <FormItem
                                        labelCol={{ span: 24 }}
                                        label="Tags"
                                        name="tags"
                                    >
                                        <MultiSelect
                                            maxTagCount="responsive"
                                            options={clusterTags.map((tag) => ({
                                                label: tag,
                                                value: tag,
                                            }))}
                                        />
                                    </FormItem>
                                </Col>
                                <Col span={12}>
                                    <FormItem
                                        labelCol={{ span: 24 }}
                                        label="Status"
                                        name="state"
                                    >
                                        <MultiSelect
                                            maxTagCount="responsive"
                                            options={[
                                                {
                                                    label: (
                                                        <StatusFormat
                                                            status={
                                                                StatusFormatStatus.success
                                                            }
                                                            text="Operational"
                                                            colorText={false}
                                                        />
                                                    ),
                                                    value: 'success',
                                                },
                                                {
                                                    label: (
                                                        <StatusFormat
                                                            status={
                                                                StatusFormatStatus.warning
                                                            }
                                                            text="Warning"
                                                            colorText={false}
                                                        />
                                                    ),
                                                    value: 'warning',
                                                },
                                                {
                                                    label: (
                                                        <StatusFormat
                                                            status={
                                                                StatusFormatStatus.error
                                                            }
                                                            text="Error"
                                                            colorText={false}
                                                        />
                                                    ),
                                                    value: 'error',
                                                },
                                            ]}
                                        />
                                    </FormItem>
                                </Col>
                            </Row>
                            <SpaceWide justify="end">
                                <Button type="link" onClick={handleReset}>
                                    Reset
                                </Button>
                            </SpaceWide>
                        </div>
                    </Form>
                }
                trigger="click"
            >
                <StatisticBadge
                    count={filterCount}
                    dot={false}
                    offsetY={6}
                    offsetX={-6}
                >
                    <Button>
                        <Space size={10}>
                            <span>Filters</span>
                            <TypographyText className="ClusterFilter_filter-button-arrow">
                                <DownOutlined />
                            </TypographyText>
                        </Space>
                    </Button>
                </StatisticBadge>
            </AppPopover>
            <Space>
                <TypographyText>Sort by:</TypographyText>
                <Select
                    className="ClusterFilter_sort-select"
                    value={filterParams?.sort}
                    onChange={handleSortChange}
                    options={[
                        {
                            label: 'Cluster ID',
                            value: 'id',
                        },
                        {
                            label: 'Cluster name',
                            value: 'name',
                        },
                    ]}
                />
            </Space>
        </Space>
    );
}
