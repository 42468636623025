import React from 'react';
import MonitorToolbar from '../components/Monitor/MonitorToolbar';
import { Select } from 'antd';
import SpaceDescriptions from '../common/Layout/SpaceDescriptions';
import SystemOverview from '../components/Monitor/data/system-overview';
import PostgresqlOverview from '../components/Monitor/data/postgresql-overview';
const DASHBOARDS = [SystemOverview, PostgresqlOverview];

export default DebugMonitorToolbar;

function DebugMonitorToolbar() {
    return (
        <div>
            <MonitorToolbar
                timeRange={30 * 60}
                selectors={[
                    <SpaceDescriptions title="Dashboard">
                        <Select
                            key="dashboard"
                            options={DASHBOARDS.map((item) => ({
                                label: item.title,
                                value: item.meta.slug,
                            }))}
                            value={'system-overview'}
                        />
                    </SpaceDescriptions>,
                ]}
            />
        </div>
    );
}
