const dashboard = {
    title: 'MongoDB ReplicaSet',
    meta: {
        slug: 'mongodb-replicaset',
        supportClusterTypes: ['mongodb', 'mongodb_shards'],
    },
    panels: [
        {
            title: 'ReplSet size',
            type: 'singlestat',
            options: {
                decimals: 0,
                format: 'none',
                legend: {},
                chart: {
                    full: false,
                    show: true,
                },
                valueMaps: [],
            },
            gridPos: {
                h: 4,
                w: 6,
                x: 0,
                y: 0,
            },
            targets: [
                {
                    expr:
                        'mongodb_mongod_replset_number_of_members{instance=~"$instance"}',
                    legendFormat: '',
                },
            ],
        },
        {
            title: 'ReplSet name',
            type: 'singlestat',
            options: {
                decimals: 0,
                format: 'none',
                legend: {},
                chart: {
                    full: false,
                    show: true,
                },
                valueMaps: [],
            },
            gridPos: {
                h: 4,
                w: 6,
                x: 0,
                y: 0,
            },
            targets: [
                {
                    expr:
                        'mongodb_mongod_replset_my_name{instance=~"$instance"}',
                    legendFormat: '{{ set }}',
                    legendAsValue: true,
                },
            ],
        },
        {
            title: 'Primary',
            type: 'singlestat',
            options: {
                decimals: 0,
                format: 'none',
                legend: {},
                chart: {
                    full: false,
                    show: true,
                },
                valueMaps: [],
            },
            gridPos: {
                h: 4,
                w: 6,
                x: 0,
                y: 0,
            },
            targets: [
                {
                    expr:
                        'mongodb_mongod_replset_member_state{instance=~"$instance", state="PRIMARY"}',
                    legendFormat: '{{ name }}',
                    legendAsValue: true,
                },
            ],
        },
        {
            title: 'Server version',
            type: 'singlestat',
            options: {
                format: 'string',
                legend: {},
            },
            gridPos: {
                h: 4,
                w: 6,
                x: 0,
                y: 0,
            },
            targets: [
                {
                    expr: 'mongodb_version_info{instance=~"$instance"}',
                    legendFormat: '{{ mongodb }}',
                    legendAsValue: true,
                },
            ],
        },
        {
            title: 'Replica Sets and Members',
            type: 'chart',
            gridPos: {
                h: 10,
                w: 12,
                x: 0,
                y: 0,
            },
            options: {
                decimals: 0,
                format: 'short',
                type: 'line',
                legend: {
                    alignAsTable: true,
                },
                yaxis: [
                    {
                        max: null,
                        min: 0,
                    },
                ],
                stack: false,
            },
            targets: [
                {
                    expr:
                        'min(mongodb_mongod_replset_member_state{state=~"PRIMARY|SECONDARY|ARBITER"} / mongodb_mongod_replset_member_state{state=~"PRIMARY|SECONDARY|ARBITER"}) by (set,state,name)',
                    legendFormat: '{{set }} -  {{ name }} - {{state}}',
                },
            ],
        },
        {
            title: 'Oplog window per ReplSet ',
            type: 'chart',
            gridPos: {
                h: 10,
                w: 12,
                x: 0,
                y: 0,
            },
            options: {
                decimals: 0,
                format: 'duration',
                type: 'line',
                legend: {
                    alignAsTable: true,
                },
                yaxis: [
                    {
                        max: null,
                        min: 0,
                    },
                ],
                stack: false,
            },
            targets: [
                {
                    expr:
                        'mongodb_mongod_replset_oplog_head_timestamp- mongodb_mongod_replset_oplog_tail_timestamp  * ON (instance,job)  group_left max(mongodb_mongod_replset_my_state == 1) by (instance, job)',
                    legendFormat: '{{ instance }} - {{job}}',
                },
            ],
        },
        {
            title: 'Replication headroom ',
            type: 'chart',
            gridPos: {
                h: 10,
                w: 12,
                x: 0,
                y: 0,
            },
            options: {
                decimals: 0,
                format: 'duration',
                type: 'line',
                legend: {
                    alignAsTable: true,
                },
                yaxis: [
                    {
                        max: null,
                        min: null,
                    },
                ],
                stack: false,
            },
            targets: [
                {
                    expr:
                        'max(((mongodb_mongod_replset_oplog_head_timestamp- mongodb_mongod_replset_oplog_tail_timestamp * ON (instance)  group_left max(mongodb_mongod_replset_my_state == 1) by (instance)))) by (instance) - ((max(mongodb_mongod_replset_member_optime_date{state="PRIMARY"}) by (instance)) - min(mongodb_mongod_replset_member_optime_date{state="SECONDARY"}) by (instance))',
                    legendFormat: '{{ instance }}',
                },
            ],
        },
        {
            title: 'Total of Primary/Secondary online per ReplSet ',
            type: 'chart',
            gridPos: {
                h: 10,
                w: 12,
                x: 0,
                y: 0,
            },
            options: {
                decimals: 0,
                format: 'short',
                type: 'line',
                legend: {
                    alignAsTable: true,
                },
                yaxis: [
                    {
                        max: null,
                        min: 0,
                    },
                ],
                stack: false,
            },
            targets: [
                {
                    expr:
                        'count(count by (name,set,state) (mongodb_mongod_replset_member_state{state=~"PRIMARY|SECONDARY"})) by (set)',
                    legendFormat: '{{set }}',
                },
            ],
        },
        {
            title: 'Open cursors per ReplSet',
            type: 'chart',
            gridPos: {
                h: 8,
                w: 8,
                x: 0,
                y: 0,
            },
            options: {
                decimals: 0,
                format: 'short',
                type: 'line',
                legend: {
                    alignAsTable: true,
                },
                yaxis: [
                    {
                        max: null,
                        min: 0,
                    },
                ],
                stack: false,
            },
            targets: [
                {
                    expr:
                        'sum(mongodb_mongod_metrics_cursor_open{state="total"} * on (instance)  group_right mongodb_mongod_replset_my_name) by (set)',
                    legendFormat: 'Total - {{ set }}',
                },
                {
                    expr:
                        'sum(mongodb_mongod_metrics_cursor_open{state="pinned"} * on (instance)  group_right mongodb_mongod_replset_my_name) by (set)',
                    legendFormat: 'Pinned - {{ set }}',
                },
                {
                    expr:
                        'sum(mongodb_mongod_metrics_cursor_open{state="noTimeout"} * on (instance)  group_right mongodb_mongod_replset_my_name) by (set)',
                    legendFormat: 'Timedout - {{ set }}',
                },
            ],
        },
        {
            title: 'ReplSet - timed-out cursors per set',
            type: 'chart',
            gridPos: {
                h: 8,
                w: 8,
                x: 0,
                y: 0,
            },
            options: {
                decimals: 0,
                format: 'short',
                type: 'line',
                legend: {
                    alignAsTable: true,
                },
                yaxis: [
                    {
                        max: null,
                        min: 0,
                    },
                ],
                stack: false,
            },
            targets: [
                {
                    expr:
                        'sum(mongodb_mongod_metrics_cursor_timed_out_total * on (instance)  group_right mongodb_mongod_replset_my_name) by (set)',
                    legendFormat: '{{ set }}',
                },
            ],
        },
        {
            title: 'Max replication lag per ReplSet',
            type: 'chart',
            gridPos: {
                h: 8,
                w: 8,
                x: 0,
                y: 0,
            },
            options: {
                decimals: 0,
                format: 'short',
                type: 'line',
                legend: {
                    alignAsTable: true,
                },
                yaxis: [
                    {
                        max: null,
                        min: 0,
                    },
                ],
                stack: false,
            },
            targets: [
                {
                    expr:
                        'max(min(mongodb_mongod_replset_member_state{state="PRIMARY"}) by (set,name)  * ON (name,set) group_right mongodb_mongod_replset_member_optime_date{state="PRIMARY"}) by (set) - min(min(mongodb_mongod_replset_member_state{state="SECONDARY"}) by (set,name) / min(mongodb_mongod_replset_member_state{state="SECONDARY"}) by (set,name)   * ON (name,set) group_right mongodb_mongod_replset_member_optime_date{state="SECONDARY"} ) by (set)',
                    legendFormat: '{{ set }}',
                },
            ],
        },
        {
            title: 'Oplog size',
            type: 'chart',
            gridPos: {
                h: 8,
                w: 8,
                x: 0,
                y: 0,
            },
            options: {
                decimals: 2,
                format: 'size',
                type: 'area',
                legend: {
                    alignAsTable: true,
                },
                yaxis: [
                    {
                        max: null,
                        min: 0,
                    },
                ],
                stack: false,
            },
            targets: [
                {
                    expr:
                        'mongodb_mongod_replset_oplog_size_bytes{instance=~"$instance", type="storage"}',
                    legendFormat: 'Allocated Size',
                },
                {
                    expr:
                        'mongodb_mongod_replset_oplog_size_bytes{instance=~"$instance", type="current"}',
                    legendFormat: 'Current Size',
                },
            ],
        },
        {
            title: 'OpsCounters',
            type: 'chart',
            gridPos: {
                h: 8,
                w: 8,
                x: 0,
                y: 0,
            },
            options: {
                decimals: 0,
                format: 'short',
                type: 'line',
                legend: {
                    alignAsTable: true,
                },
                yaxis: [
                    {
                        max: null,
                        min: 0,
                    },
                ],
                stack: false,
            },
            targets: [
                {
                    expr:
                        'irate(mongodb_op_counters_total{instance=~"$instance"}[5m])',
                    legendFormat: '{{ type }}',
                },
            ],
        },
        {
            title: 'Ping time to ReplicaSet emmbers from Primary(s)',
            type: 'chart',
            gridPos: {
                h: 8,
                w: 8,
                x: 0,
                y: 0,
            },
            options: {
                decimals: 0,
                format: 'short',
                type: 'line',
                legend: {
                    alignAsTable: true,
                },
                yaxis: [
                    {
                        max: null,
                        min: 0,
                    },
                ],
                stack: false,
            },
            targets: [
                {
                    expr:
                        'mongodb_mongod_replset_my_name * ON (instance) group_left mongodb_mongod_replset_member_ping_ms{state="PRIMARY"}',
                    legendFormat: '{{set}} - {{name}}',
                },
            ],
        },
    ],
    templating: {
        list: [
            {
                datasource: 'clustercontrol',
                multi: false,
                label: 'Host',
                name: 'instance',
                query: "host:$[nodes[nodetype='mongo']]",
                optionValue: 'hostname',
                optionText: 'hostname',
                sort: 1,
                isInstancePicker: true,
            },
        ],
    },
};
export default dashboard;
