
import CcStats, { CcStatsProps } from '../../models/CcStats';

export interface CmonMongoStatsWiredTigerConcurrentTransactionsWriteProps {
    available?: number;
    out?: number;
}

export interface CmonMongoStatsWiredTigerConcurrentTransactionsReadProps {
    available?: number;
    out?: number;
}

export interface CmonMongoStatsWiredTigerConcurrentTransactionsProps {
    read?: CmonMongoStatsWiredTigerConcurrentTransactionsReadProps;
    write?: CmonMongoStatsWiredTigerConcurrentTransactionsWriteProps;
}

export interface CmonMongoStatsWiredTigerCacheProps {
    'bytes currently in the cache'?: number;
    'maximum bytes configured'?: number;
    'modified pages evicted'?: number;
    'tracked dirty bytes in the cache'?: number;
    'unmodified unmodified pages evicted'?: number;
}

export interface CmonMongoStatsWiredTigerProps {
    cache?: CmonMongoStatsWiredTigerCacheProps;
    concurrentTransactions?: CmonMongoStatsWiredTigerConcurrentTransactionsProps;
}

export interface CmonMongoStatsReplSetStatusProps {
    replicationLag?: number;
}

export interface CmonMongoStatsOpcountersProps {
    command?: number;
    delete?: number;
    getmore?: number;
    insert?: number;
    query?: number;
    update?: number;
}

export interface CmonMongoStatsNetworkProps {
    bytesIn?: number;
    bytesOut?: number;
}

export interface CmonMongoStatsMetricsCursorOpenProps {
    noTimeout?: number;
    total?: number;
}

export interface CmonMongoStatsMetricsCursorProps {
    open?: CmonMongoStatsMetricsCursorOpenProps;
    timedOut?: number;
}

export interface CmonMongoStatsMetricsProps {
    cursor?: CmonMongoStatsMetricsCursorProps;
}

export interface CmonMongoStatsGlobalLockCurrentQueueProps {
    readers?: number;
    writers?: number;
}

export interface CmonMongoStatsGlobalLockActiveClientsProps {
    readers?: number;
    writers?: number;
}

export interface CmonMongoStatsGlobalLockProps {
    activeClients?: CmonMongoStatsGlobalLockActiveClientsProps;
    currentQueue?: CmonMongoStatsGlobalLockCurrentQueueProps;
    lockTime?: number;
    ratio?: number;
    totalTime?: number;
}

export interface CmonMongoStatsConnectionsProps {
    available?: number;
    current?: number;
    totalCreated?: number;
}

export interface CmonMongoStatsAssertsProps {
    msg?: number;
    regular?: number;
    user?: number;
    warning?: number;
}


export interface CmonMongoStatsProps  extends CcStatsProps {
    asserts?: CmonMongoStatsAssertsProps;
    connections?: CmonMongoStatsConnectionsProps;
    globalLock?: CmonMongoStatsGlobalLockProps;
    metrics?: CmonMongoStatsMetricsProps;
    network?: CmonMongoStatsNetworkProps;
    opcounters?: CmonMongoStatsOpcountersProps;
    replSetStatus?: CmonMongoStatsReplSetStatusProps;
    wiredTiger?: CmonMongoStatsWiredTigerProps;

}

export class CmonMongoStatsWiredTigerConcurrentTransactionsWrite {
    public available?: number;
    public out?: number;

    constructor(props: CmonMongoStatsWiredTigerConcurrentTransactionsWriteProps) {
        this.available = props.available;
        this.out = props.out;

    }}

export class CmonMongoStatsWiredTigerConcurrentTransactionsRead {
    public available?: number;
    public out?: number;

    constructor(props: CmonMongoStatsWiredTigerConcurrentTransactionsReadProps) {
        this.available = props.available;
        this.out = props.out;

    }}

export class CmonMongoStatsWiredTigerConcurrentTransactions {
    public read?: CmonMongoStatsWiredTigerConcurrentTransactionsRead;
    public write?: CmonMongoStatsWiredTigerConcurrentTransactionsWrite;

    constructor(props: CmonMongoStatsWiredTigerConcurrentTransactionsProps) {
        this.read = props.read && new CmonMongoStatsWiredTigerConcurrentTransactionsRead(props.read);
        this.write = props.write && new CmonMongoStatsWiredTigerConcurrentTransactionsWrite(props.write);

    }}

export class CmonMongoStatsWiredTigerCache {
    public bytesCurrentlyInTheCache?: number;
    public maximumBytesConfigured?: number;
    public modifiedPagesEvicted?: number;
    public trackedDirtyBytesInTheCache?: number;
    public unmodifiedUnmodifiedPagesEvicted?: number;

    constructor(props: CmonMongoStatsWiredTigerCacheProps) {
        this.bytesCurrentlyInTheCache = props['bytes currently in the cache'];
        this.maximumBytesConfigured = props['maximum bytes configured'];
        this.modifiedPagesEvicted = props['modified pages evicted'];
        this.trackedDirtyBytesInTheCache = props['tracked dirty bytes in the cache'];
        this.unmodifiedUnmodifiedPagesEvicted = props['unmodified unmodified pages evicted'];

    }}

export class CmonMongoStatsWiredTiger {
    public cache?: CmonMongoStatsWiredTigerCache;
    public concurrentTransactions?: CmonMongoStatsWiredTigerConcurrentTransactions;

    constructor(props: CmonMongoStatsWiredTigerProps) {
        this.cache = props.cache && new CmonMongoStatsWiredTigerCache(props.cache);
        this.concurrentTransactions = props.concurrentTransactions && new CmonMongoStatsWiredTigerConcurrentTransactions(props.concurrentTransactions);

    }}

export class CmonMongoStatsReplSetStatus {
    public replicationLag?: number;

    constructor(props: CmonMongoStatsReplSetStatusProps) {
        this.replicationLag = props.replicationLag;

    }}

export class CmonMongoStatsOpcounters {
    public command?: number;
    public delete?: number;
    public getmore?: number;
    public insert?: number;
    public query?: number;
    public update?: number;

    constructor(props: CmonMongoStatsOpcountersProps) {
        this.command = props.command;
        this.delete = props.delete;
        this.getmore = props.getmore;
        this.insert = props.insert;
        this.query = props.query;
        this.update = props.update;

    }}

export class CmonMongoStatsNetwork {
    public bytesIn?: number;
    public bytesOut?: number;

    constructor(props: CmonMongoStatsNetworkProps) {
        this.bytesIn = props.bytesIn;
        this.bytesOut = props.bytesOut;

    }}

export class CmonMongoStatsMetricsCursorOpen {
    public noTimeout?: number;
    public total?: number;

    constructor(props: CmonMongoStatsMetricsCursorOpenProps) {
        this.noTimeout = props.noTimeout;
        this.total = props.total;

    }}

export class CmonMongoStatsMetricsCursor {
    public open?: CmonMongoStatsMetricsCursorOpen;
    public timedOut?: number;

    constructor(props: CmonMongoStatsMetricsCursorProps) {
        this.open = props.open && new CmonMongoStatsMetricsCursorOpen(props.open);
        this.timedOut = props.timedOut;

    }}

export class CmonMongoStatsMetrics {
    public cursor?: CmonMongoStatsMetricsCursor;

    constructor(props: CmonMongoStatsMetricsProps) {
        this.cursor = props.cursor && new CmonMongoStatsMetricsCursor(props.cursor);

    }}

export class CmonMongoStatsGlobalLockCurrentQueue {
    public readers?: number;
    public writers?: number;

    constructor(props: CmonMongoStatsGlobalLockCurrentQueueProps) {
        this.readers = props.readers;
        this.writers = props.writers;

    }}

export class CmonMongoStatsGlobalLockActiveClients {
    public readers?: number;
    public writers?: number;

    constructor(props: CmonMongoStatsGlobalLockActiveClientsProps) {
        this.readers = props.readers;
        this.writers = props.writers;

    }}

export class CmonMongoStatsGlobalLock {
    public activeClients?: CmonMongoStatsGlobalLockActiveClients;
    public currentQueue?: CmonMongoStatsGlobalLockCurrentQueue;
    public lockTime?: number;
    public ratio?: number;
    public totalTime?: number;

    constructor(props: CmonMongoStatsGlobalLockProps) {
        this.activeClients = props.activeClients && new CmonMongoStatsGlobalLockActiveClients(props.activeClients);
        this.currentQueue = props.currentQueue && new CmonMongoStatsGlobalLockCurrentQueue(props.currentQueue);
        this.lockTime = props.lockTime;
        this.ratio = props.ratio;
        this.totalTime = props.totalTime;

    }}

export class CmonMongoStatsConnections {
    public available?: number;
    public current?: number;
    public totalCreated?: number;

    constructor(props: CmonMongoStatsConnectionsProps) {
        this.available = props.available;
        this.current = props.current;
        this.totalCreated = props.totalCreated;

    }}

export class CmonMongoStatsAsserts {
    public msg?: number;
    public regular?: number;
    public user?: number;
    public warning?: number;

    constructor(props: CmonMongoStatsAssertsProps) {
        this.msg = props.msg;
        this.regular = props.regular;
        this.user = props.user;
        this.warning = props.warning;

    }}



export default class CmonMongoStats extends CcStats {
    public asserts?: CmonMongoStatsAsserts;
    public connections?: CmonMongoStatsConnections;
    public globalLock?: CmonMongoStatsGlobalLock;
    public metrics?: CmonMongoStatsMetrics;
    public network?: CmonMongoStatsNetwork;
    public opcounters?: CmonMongoStatsOpcounters;
    public replSetStatus?: CmonMongoStatsReplSetStatus;
    public wiredTiger?: CmonMongoStatsWiredTiger;


    constructor(props: CmonMongoStatsProps) {
        super(props);
        this.asserts = props.asserts && new CmonMongoStatsAsserts(props.asserts);
        this.connections = props.connections && new CmonMongoStatsConnections(props.connections);
        this.globalLock = props.globalLock && new CmonMongoStatsGlobalLock(props.globalLock);
        this.metrics = props.metrics && new CmonMongoStatsMetrics(props.metrics);
        this.network = props.network && new CmonMongoStatsNetwork(props.network);
        this.opcounters = props.opcounters && new CmonMongoStatsOpcounters(props.opcounters);
        this.replSetStatus = props.replSetStatus && new CmonMongoStatsReplSetStatus(props.replSetStatus);
        this.wiredTiger = props.wiredTiger && new CmonMongoStatsWiredTiger(props.wiredTiger);

    }

}
