import { StatusFormatStatus } from '@severalnines/bar-frontend-components/build/lib/Format/StatusFormat';
export function getStatusFormatColor(status?: StatusFormatStatus) {
    switch (status) {
        case 'success':
            return '#52c41a';
        case 'error':
            return '#ff4d4f';
        case 'warning':
            return '#faad14';
        case 'info':
            return '#530099';
        case 'loading':
            return '#1890ff';
        case 'unknown':
        default:
            return '#dbdbdb';
    }
}
export * from '@severalnines/bar-frontend-components/build/lib/Format/StatusFormat';
