import React, { useMemo } from 'react';
import SpaceDescriptions from '../../../../common/Layout/SpaceDescriptions';
import {
    BackupRestoreType,
    BackupRestoreWizardFormValues,
} from './BackupRestoreWizardForm';
import BackupFormat from '../../BackupFormat';
import StorageLocationFormat from '../../StorageLocations/StorageLocationFormat';
import YesNoFormat from '../../../../common/Format/YesNoFormat';
import { FormInstance } from 'antd/lib/form';
import { getBackupRestoreTypeFormItemName } from './FormParts/BackupRestoreTypeForm';
import TypographyText from '../../../../common/TypographyText';
import DateFormat from '@severalnines/bar-frontend-components/build/lib/Format/DateFormat';
import {
    isBackupMethodPgBackRest,
    isBackupMethodPgBaseBackup,
} from '../../../../services/models/CcBackup';
import AppDateFormat from '../../../../common/AppDateFormat';

export default BackupRestoreWizardSummary;

type BackupRestoreWizardSummaryProps = {
    form: FormInstance<BackupRestoreWizardFormValues>;
};

function BackupRestoreWizardSummary({ form }: BackupRestoreWizardSummaryProps) {
    const fields = form.getFieldsValue(true);
    const backupLocation = useMemo(() => {
        return (
            fields.backupLocation &&
            fields?.backup?.getLocation(fields.backupLocation)
        );
    }, [fields?.backup, fields.backupLocation]);

    const isCloud = backupLocation?.isTypeCloud();

    return (
        <>
            {fields.backup && (
                <SpaceDescriptions
                    direction="vertical"
                    title="Backup"
                    size="small"
                    align="end"
                    wide={true}
                >
                    <BackupFormat
                        backup={fields.backup}
                        shortBackupName={true}
                    />

                    {backupLocation && (
                        <StorageLocationFormat storageLocation={backupLocation}>
                            {backupLocation.getPath()}
                        </StorageLocationFormat>
                    )}
                    <SpaceDescriptions.Item
                        label={'How to restore'}
                        labelNowrap={true}
                        labelStrong={true}
                    >
                        <TypographyText nowrap={true}>
                            {getBackupRestoreTypeFormItemName(
                                fields?.restoreType
                            )}
                        </TypographyText>
                    </SpaceDescriptions.Item>
                </SpaceDescriptions>
            )}

            <SpaceDescriptions
                direction="vertical"
                title="Settings"
                size="small"
                align="end"
                wide={true}
            >
                {fields?.tmpDirectory && (
                    <SpaceDescriptions.Item
                        label={'Temporary directory'}
                        labelNowrap={true}
                        labelStrong={true}
                    >
                        {fields?.tmpDirectory}
                    </SpaceDescriptions.Item>
                )}

                {fields?.restoreType === BackupRestoreType.NODE && (
                    <>
                        {fields?.node && (
                            <SpaceDescriptions.Item
                                label={'Restore on node'}
                                labelNowrap={true}
                                labelStrong={true}
                            >
                                {fields?.node}
                            </SpaceDescriptions.Item>
                        )}
                        <SpaceDescriptions.Item
                            label={'Bootstrap cluster'}
                            labelNowrap={true}
                            labelStrong={true}
                        >
                            <YesNoFormat booleanVar={fields.bootstrapCluster} />
                        </SpaceDescriptions.Item>
                        <SpaceDescriptions.Item
                            label={'Copy data directory'}
                            labelNowrap={true}
                            labelStrong={true}
                        >
                            <YesNoFormat booleanVar={fields.copyDataDir} />
                        </SpaceDescriptions.Item>
                        {isCloud && (
                            <SpaceDescriptions.Item
                                label={'Download backup to'}
                                labelNowrap={true}
                                labelStrong={true}
                            >
                                {fields.downloadDirectory}
                            </SpaceDescriptions.Item>
                        )}
                        {fields?.pitrTime && (
                            <SpaceDescriptions.Item
                                label={'PITR stop time'}
                                labelNowrap={true}
                                labelStrong={true}
                            >
                                <TypographyText nowrap={true}>
                                    <AppDateFormat includeTimezone={false}>
                                        {fields.pitrTime}
                                    </AppDateFormat>
                                </TypographyText>
                            </SpaceDescriptions.Item>
                        )}
                        {fields?.pitrBinLogName && (
                            <SpaceDescriptions.Item
                                label={'PITR binary log'}
                                labelNowrap={true}
                                labelStrong={true}
                            >
                                {fields?.pitrBinLogName}
                            </SpaceDescriptions.Item>
                        )}
                        {fields?.pitrLogStopPosition && (
                            <SpaceDescriptions.Item
                                label={'PITR log stop position'}
                                labelNowrap={true}
                                labelStrong={true}
                            >
                                {fields?.pitrLogStopPosition}
                            </SpaceDescriptions.Item>
                        )}
                    </>
                )}

                {fields?.restoreType === BackupRestoreType.STANDALONE_HOST && (
                    <>
                        {fields?.restoreHost && (
                            <SpaceDescriptions.Item
                                label={'Restore on standalone host'}
                                labelNowrap={true}
                                labelStrong={true}
                            >
                                {fields?.restoreHost}
                            </SpaceDescriptions.Item>
                        )}
                        <SpaceDescriptions.Item
                            label={'Install software'}
                            labelNowrap={true}
                            labelStrong={true}
                        >
                            <YesNoFormat booleanVar={fields.installSoftware} />
                        </SpaceDescriptions.Item>
                        <SpaceDescriptions.Item
                            label={'Disable firewall'}
                            labelNowrap={true}
                            labelStrong={true}
                        >
                            <YesNoFormat booleanVar={fields.disableFirewall} />
                        </SpaceDescriptions.Item>
                        <SpaceDescriptions.Item
                            label={'Disable SELinux/AppArmor'}
                            labelNowrap={true}
                            labelStrong={true}
                        >
                            <YesNoFormat booleanVar={fields.disableSeLinux} />
                        </SpaceDescriptions.Item>
                        <SpaceDescriptions.Item
                            label={'Shutdown after restore'}
                            labelNowrap={true}
                            labelStrong={true}
                        >
                            <YesNoFormat
                                booleanVar={fields.shutdownAfterRestore}
                            />
                        </SpaceDescriptions.Item>
                    </>
                )}
            </SpaceDescriptions>
        </>
    );
}
