import React, { useEffect, useMemo, useState } from 'react';
import { CcClusterType } from '../../services/models/CcCluster';
import { CcElasticRole } from '../../services/models/CcElasticNode';
import { Select, SelectProps } from 'antd';
import { getNodeRoleText } from '../../components/Nodes/NodeTypeFormat';

export default ClusterNodeRoleSelect;
export type NodeRoleSelectProps = SelectProps & {
    clusterType: CcClusterType;
    selectDefault?: boolean;
};

function ClusterNodeRoleSelect({
    clusterType,
    selectDefault = false,
    value,
    ...rest
}: NodeRoleSelectProps) {
    const [current, setCurrent] = useState<string | undefined>(() => {
        if (!selectDefault) {
            return undefined;
        }
        switch (clusterType) {
            case CcClusterType.TYPE_ELASTIC:
                return CcElasticRole.MASTER_DATA;
        }
    });

    useEffect(() => {
        if (value && value !== current) {
            setCurrent(value);
        }
    }, [value]);

    const options = useMemo(
        () =>
            getClusterNodeRoles(clusterType).map((role) => ({
                value: role,
                label: getNodeRoleText(role, undefined, clusterType),
            })),
        [clusterType]
    );

    return <Select value={current} options={options} {...rest} />;
}

function getClusterNodeRoles(clusterType: CcClusterType) {
    switch (clusterType) {
        case CcClusterType.TYPE_ELASTIC:
            return [
                CcElasticRole.MASTER_DATA,
                CcElasticRole.MASTER,
                CcElasticRole.DATA,
                CcElasticRole.COORDINATOR,
            ];
        default:
            return [];
    }
}
