import React from 'react';
import { Link, LinkProps, To } from 'react-router-dom';

export default ActivityCenterJobsLink;

export type ActivityCenterJobsLinkProps = Omit<LinkProps, 'to'> & {
    to?: To;
};

function ActivityCenterJobsLink({
    to = '/activity-center/jobs',
    children,
    ...rest
}: ActivityCenterJobsLinkProps) {
    return (
        <Link to={to} {...rest}>
            {children || 'Activity center'}
        </Link>
    );
}
