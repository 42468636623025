import React from 'react';
import { CcClusterType } from '../../services/models/CcCluster';
import {
    ClustersIcon,
    CodershipIcon,
    ElasticsearchIcon,
    MongodbIcon,
    MsSQLIcon,
    MysqlIcon,
    PostgresqlIcon,
    RedisIcon,
    TimescaledbIcon,
} from '../../common/icons/icons';
import { getVendorIcon } from '../Misc/VendorIcon';

export default ClusterTypeFormat;

export function getClusterTypeText(type?: string) {
    switch (type) {
        case CcClusterType.TYPE_REPLICATION:
            return 'MySQL Replication';
        case CcClusterType.TYPE_GALERA:
            return 'MySQL Galera';
        case CcClusterType.TYPE_GROUP_REPLICATION:
            return 'Group Replication';
        case CcClusterType.TYPE_MYSQL_CLUSTER:
            return 'MySQL Cluster';
        case CcClusterType.TYPE_MYSQL_SINGLE:
            return 'MySQL';
        case CcClusterType.TYPE_MONGODB:
            return 'MongoDB ReplicaSet';
        case CcClusterType.TYPE_MONGODB_SHARDS:
            return 'MongoDB Shards';
        case CcClusterType.TYPE_POSTGRESQL:
            return 'PostgreSQL';
        case CcClusterType.TYPE_REDIS:
            return 'Redis Sentinel';
        case CcClusterType.TYPE_TIMESCALEDB:
            return 'TimescaleDB';
        case CcClusterType.TYPE_MSSQL_SINGLE:
            return 'SQL Server';
        case CcClusterType.TYPE_MSSQL_AO_ASYNC:
            return 'SQL Server Replication';
        case CcClusterType.TYPE_ELASTIC:
            return 'Elasticsearch';
        default:
            return 'Unknown';
    }
}

export function getClusterTypeIcon(
    type?: string,
    vendor?: string,
    iconProps?: any
) {
    if (vendor) {
        const icon = getVendorIcon(vendor, iconProps);
        if (icon) {
            return icon;
        }
    }
    switch (type) {
        case CcClusterType.TYPE_REPLICATION:
            return <MysqlIcon {...iconProps} />;
        case CcClusterType.TYPE_GALERA:
            return <CodershipIcon {...iconProps} />;
        case CcClusterType.TYPE_GROUP_REPLICATION:
            return <MysqlIcon {...iconProps} />;
        case CcClusterType.TYPE_MYSQL_CLUSTER:
            return <MysqlIcon {...iconProps} />;
        case CcClusterType.TYPE_MYSQL_SINGLE:
            return <MysqlIcon {...iconProps} />;
        case CcClusterType.TYPE_MONGODB:
        case CcClusterType.TYPE_MONGODB_SHARDS:
            return <MongodbIcon {...iconProps} />;
        case CcClusterType.TYPE_POSTGRESQL:
            return <PostgresqlIcon {...iconProps} />;
        case CcClusterType.TYPE_REDIS:
            return <RedisIcon {...iconProps} />;
        case CcClusterType.TYPE_MSSQL_SINGLE:
        case CcClusterType.TYPE_MSSQL_AO_ASYNC:
            return <MsSQLIcon {...iconProps} />;
        case CcClusterType.TYPE_TIMESCALEDB:
            return <TimescaledbIcon {...iconProps} />;
        case CcClusterType.TYPE_ELASTIC:
            return <ElasticsearchIcon {...iconProps} />;
        default:
            return <ClustersIcon {...iconProps} />;
    }
}
export type ClusterTypeFormatProps = {
    type?: string;
    vendor?: string;
    showIcon?: boolean;
    showDesc?: boolean;
    iconProps?: any;
};

function ClusterTypeFormat({
    type,
    vendor,
    showIcon,
    showDesc = true,
    iconProps,
}: ClusterTypeFormatProps) {
    return (
        <span className="ClusterTypeFormat">
            {showIcon ? getClusterTypeIcon(type, vendor, iconProps) : null}{' '}
            {showDesc ? getClusterTypeText(type) : null}
        </span>
    );
}
