import CmonProxySqlProcessList, {
    CmonProxySqlProcessListProps,
} from '../cmon/models/CmonProxySqlProcessList';

export interface CcProxySqlProcessListProps
    extends CmonProxySqlProcessListProps {}

export default class CcProxySqlProcessList extends CmonProxySqlProcessList {
    constructor(props: CcProxySqlProcessListProps) {
        super(props);
    }

    public getKey(): string {
        return `${this.SessionID} ${this.ThreadID}`;
    }
}
