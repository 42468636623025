import React from 'react';
import { Col, Row } from 'antd';
import InfoIcon from '@severalnines/bar-frontend-components/build/lib/General/InfoIcon';
import CcCluster from '../../../../../services/models/CcCluster';
import { getNodeHostWithDesc } from '../../../../Nodes/NodeFormat';
import FormItemInlineSwitch from '../../../../../common/DataEntry/FormItemInlineSwitch';

import { FormInstance } from 'antd/lib/form';

type CreateMaxScaleServerInstancesProps = {
    selectedCluster?: CcCluster;
    form: FormInstance;
};

export const maxScaleServerInstancesValues = (
    form: FormInstance,
    selectedCluster?: CcCluster
): void => {
    let maxScaleServerInstances: any = {};
    if (selectedCluster?.getDatabaseNodes())
        for (let i = 0; i < selectedCluster?.getDatabaseNodes().length; i++) {
            maxScaleServerInstances[
                `node:${selectedCluster?.getDatabaseNodes()[i].getKey()}`
            ] = false;
            maxScaleServerInstances[
                `nodeAddress:${selectedCluster?.getDatabaseNodes()[i].getKey()}`
            ] = `${selectedCluster?.getDatabaseNodes()[i].getHostWithPort()}`;
        }
    return maxScaleServerInstances;
};

export default CreateMaxScaleServerInstances;

function CreateMaxScaleServerInstances({
    selectedCluster,
}: CreateMaxScaleServerInstancesProps) {
    return (
        <div style={{ minHeight: 450 }}>
            <Row>
                <Col span={24}>
                    <h3>
                        Server instances in the load balancer{' '}
                        <InfoIcon
                            info={
                                <span>
                                    Switch on the instances you want to be
                                    included in the load balancer.
                                </span>
                            }
                        />
                    </h3>
                </Col>
            </Row>

            {selectedCluster?.getDatabaseNodes().map((node) => {
                return (
                    <Row key={node.hostname}>
                        <Col span={24}>
                            <FormItemInlineSwitch
                                justify={true}
                                label={`${getNodeHostWithDesc(node)}`}
                                name={[
                                    'maxScaleServerInstances',
                                    `node:${node.getKey()}`,
                                ]}
                                style={{ width: '50%' }}
                                labelStrong={false}
                                valuePropName="checked"
                            />
                        </Col>
                    </Row>
                );
            })}
        </div>
    );
}
