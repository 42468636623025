


export interface CmonProxySqlServersProps  {
    class_name?: string;
    comment?: string;
    hostname?: string;
    port?: number;
    weight?: number;

}



export default class CmonProxySqlServers {
    public className?: string;
    public comment?: string;
    public hostname?: string;
    public port?: number;
    public weight?: number;


    constructor(props: CmonProxySqlServersProps) {
        this.className = props.class_name;
        this.comment = props.comment;
        this.hostname = props.hostname;
        this.port = props.port;
        this.weight = props.weight;

    }

}
