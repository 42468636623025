const mock = {
    class_name: 'CmonClusterInfo',
    cdt_path: '/',
    acl: 'user::rwx,group::rwx,other::---',
    tags: ['production', 'billing'],
    cluster_auto_recovery: true,
    cluster_id: 13,
    cluster_name: 'MS SQL Cluster 2019 Billing (REPLICATION FAKE)',
    cluster_type: 'MSSQL_AO_ASYNC',
    configuration_file: '/etc/cmon.d/cmon_12.cnf',
    effective_privileges: 'rwx',
    log_file: '/var/log/cmon_12.log',
    maintenance_mode_active: false,
    managed: true,
    node_auto_recovery: true,
    rpc_version: '2.0',
    state: 'STARTED',
    status_text: 'All nodes are operational.',
    vendor: 'microsoft',
    version: '2019',
    alarm_statistics: {
        class_name: 'CmonAlarmStatistics',
        cluster_id: 12,
        created_after: null,
        critical: 0,
        reported_after: null,
        warning: 1,
    },
    group_owner: {
        class_name: 'CmonGroup',
        cdt_path: '/groups',
        owner_user_id: 1,
        owner_user_name: 'system',
        owner_group_id: 1,
        owner_group_name: 'admins',
        acl: 'user::rwx,group::rwx,other::---',
        created: '2021-04-28T22:57:55.328Z',
        group_id: 1,
        group_name: 'admins',
    },
    hosts: [
        {
            class_name: 'CmonMsSqlHost',
            cdt_path: '/MS SQL Cluster 2019 Billing',
            owner_user_id: 5,
            owner_user_name: 'ccadmin',
            owner_group_id: 1,
            owner_group_name: 'admins',
            acl: 'user::rwx,group::rwx,other::---',
            clusterid: 12,
            configfile: '/var/opt/mssql/mssql.conf',
            connected: true,
            container: true,
            datadir: '/var/opt/mssql/data',
            description: '',
            hostId: 107,
            hostname: '10.0.8.35',
            hoststatus: 'CmonHostOnline',
            ip: '10.0.8.35',
            lastseen: 1638448951,
            maintenance_mode: 'none',
            maintenance_mode_active: false,
            message: 'Up and running.',
            nodetype: 'mssql',
            pingstatus: 1,
            pingtime: 80,
            port: 1433,
            role: 'PRIMARY',
            sshfailcount: 0,
            statereason: 'Up and running.',
            status: 10,
            timestamp: 1638448951,
            unique_id: 34,
            version: '15.0.4153.1',
            wallclock: 1638448905,
            wallclocktimestamp: 1638448905,
            distribution: {
                name: 'ubuntu',
                codename: 'focal',
                release: '20.04',
                type: 'debian',
            },
        },
        {
            class_name: 'CmonMsSqlHost',
            cdt_path: '/MS SQL Cluster 2019 Billing',
            owner_user_id: 5,
            owner_user_name: 'ccadmin',
            owner_group_id: 1,
            owner_group_name: 'admins',
            acl: 'user::rwx,group::rwx,other::---',
            clusterid: 12,
            configfile: '/var/opt/mssql/mssql.conf',
            connected: true,
            container: true,
            datadir: '/var/opt/mssql/data',
            description: '',
            hostId: 108,
            hostname: '10.0.8.36',
            hoststatus: 'CmonHostOnline',
            ip: '10.0.8.35',
            lastseen: 1638448951,
            maintenance_mode: 'none',
            maintenance_mode_active: false,
            message: 'Up and running.',
            nodetype: 'mssql',
            pingstatus: 1,
            pingtime: 80,
            port: 1433,
            role: 'SECONDARY',
            sshfailcount: 0,
            statereason: 'Up and running.',
            status: 10,
            timestamp: 1638448951,
            unique_id: 35,
            version: '15.0.4153.1',
            wallclock: 1638448905,
            wallclocktimestamp: 1638448905,
            distribution: {
                name: 'ubuntu',
                codename: 'focal',
                release: '20.04',
                type: 'debian',
            },
            replica: {
                availability_mode: 'ASYNCHRONOUS_COMMIT',
                connected_state: 'CONNECTED',
                failover_mode: 'MANUAL',
                recovery_health: 'ONLINE',
                synchronization_health: 'HEALTHY',
            },
            replication_slave: {
                master_host: '10.0.8.35',
                master_port: 1433,
            },
        },
        {
            class_name: 'CmonMsSqlHost',
            cdt_path: '/MS SQL Cluster 2019 Billing',
            owner_user_id: 5,
            owner_user_name: 'ccadmin',
            owner_group_id: 1,
            owner_group_name: 'admins',
            acl: 'user::rwx,group::rwx,other::---',
            clusterid: 12,
            configfile: '/var/opt/mssql/mssql.conf',
            connected: true,
            container: true,
            datadir: '/var/opt/mssql/data',
            description: '',
            hostId: 109,
            hostname: '10.0.8.37',
            hoststatus: 'CmonHostOnline',
            ip: '10.0.8.35',
            lastseen: 1638448951,
            maintenance_mode: 'none',
            maintenance_mode_active: false,
            message: 'Up and running.',
            nodetype: 'mssql',
            pingstatus: 1,
            pingtime: 80,
            port: 1433,
            role: 'SECONDARY',
            sshfailcount: 0,
            statereason: 'Up and running.',
            status: 10,
            timestamp: 1638448951,
            unique_id: 36,
            version: '15.0.4153.1',
            wallclock: 1638448905,
            wallclocktimestamp: 1638448905,
            distribution: {
                name: 'ubuntu',
                codename: 'focal',
                release: '20.04',
                type: 'debian',
            },
            replica: {
                availability_mode: 'ASYNCHRONOUS_COMMIT',
                connected_state: 'CONNECTED',
                failover_mode: 'MANUAL',
                recovery_health: 'ONLINE',
                synchronization_health: 'NOTHEALTHY',
            },
            replication_slave: {
                master_host: '10.0.8.35',
                master_port: 1433,
            },
        },
        {
            class_name: 'CmonHost',
            cdt_path: '/MS SQL Cluster 2019 Billing',
            owner_user_id: 5,
            owner_user_name: 'ccadmin',
            owner_group_id: 1,
            owner_group_name: 'admins',
            acl: 'user::rwx,group::rwx,other::---',
            clusterid: 12,
            configfile: '/etc/cmon.d/cmon_12.cnf',
            connected: true,
            container: false,
            hostId: 108,
            hostname: '143.198.228.20',
            hoststatus: 'CmonHostOnline',
            ip: '143.198.228.20',
            lastseen: 1638448933,
            logfile: '/var/log/cmon_12.log',
            maintenance_mode: 'none',
            maintenance_mode_active: false,
            message: 'Up and running.',
            nodetype: 'controller',
            pid: 24504,
            pingstatus: -1,
            pingtime: -1,
            port: 9500,
            role: 'controller',
            status: 10,
            timestamp: 1638448933,
            unique_id: 35,
            uptime: 4237,
            version: '1.9.2.4985',
            wallclock: 1638448905,
            wallclocktimestamp: 1638448905,
            distribution: {
                name: 'ubuntu',
                codename: 'bionic',
                release: '18.04',
                type: 'debian',
            },
        },
        {
            class_name: 'CmonPrometheusHost',
            cdt_path: '/MS SQL Cluster 2019 Billing',
            owner_user_id: 5,
            owner_user_name: 'ccadmin',
            owner_group_id: 1,
            owner_group_name: 'admins',
            acl: 'user::rwx,group::rwx,other::---',
            clusterid: 12,
            configfile: '/etc/prometheus/prometheus.yml',
            container: false,
            data_retention: '15d',
            data_retention_size: '0',
            datadir: '/var/lib/prometheus',
            hostId: 108,
            hostname: '143.198.228.20',
            hoststatus: 'CmonHostOnline',
            ip: '143.198.228.20',
            lastseen: 1638448933,
            logfile: '/var/log/prometheus/prometheus.log',
            maintenance_mode: 'none',
            maintenance_mode_active: false,
            message: "Process 'prometheus' is running.",
            nodetype: 'prometheus',
            pid: 29127,
            pingstatus: -1,
            pingtime: -1,
            port: 9090,
            scrape_interval: '10s',
            statereason: 'Prometheus is available.',
            status: 10,
            timestamp: 1638448933,
            unique_id: 55,
            version: '2.29.2',
            wallclock: 1638448905,
            wallclocktimestamp: 1638448905,
            configuration: [
                {
                    arguments: '',
                    job: 'prometheus',
                    scrape_interval: '2s',
                },
                {
                    arguments: '',
                    job: 'node',
                    scrape_interval: '10s',
                },
                {
                    arguments: '',
                    job: 'process',
                    scrape_interval: '10s',
                },
                {
                    arguments:
                        ' --collect.perf_schema.eventswaits  --collect.perf_schema.file_events  --collect.perf_schema.file_instances  --collect.perf_schema.indexiowaits  --collect.perf_schema.tableiowaits  --collect.perf_schema.tablelocks  --collect.info_schema.tablestats  --collect.info_schema.processlist  --collect.info_schema.userstats  --collect.binlog_size  --collect.global_status  --collect.global_variables  --collect.info_schema.innodb_metrics  --collect.slave_status ',
                    job: 'mysqld',
                    scrape_interval: '10s',
                },
                {
                    arguments: '',
                    job: 'proxysql',
                    scrape_interval: '10s',
                },
                {
                    arguments: '',
                    job: 'haproxy',
                    scrape_interval: '10s',
                },
                {
                    arguments: '',
                    job: 'redis',
                    scrape_interval: '10s',
                },
                {
                    arguments: '',
                    job: 'mssql',
                    scrape_interval: '10s',
                },
            ],
            distribution: {
                name: 'ubuntu',
                codename: 'bionic',
                release: '18.04',
                type: 'debian',
            },
            exporters: [
                {
                    address: '10.0.8.35:9011',
                    job: 'process',
                    monitored_host: '',
                    up: true,
                    version: '0.10.10',
                },
                {
                    address: '10.0.8.35:9100',
                    job: 'node',
                    monitored_host: '',
                    up: true,
                    version: '1.0.1',
                },
                {
                    address: '143.198.228.20:9011',
                    job: 'process',
                    monitored_host: '',
                    up: true,
                    version: '0.10.10',
                },
                {
                    address: '143.198.228.20:9100',
                    job: 'node',
                    monitored_host: '',
                    up: true,
                    version: '1.0.1',
                },
            ],
        },
    ],
    job_statistics: {
        class_name: 'CmonJobStatistics',
        cluster_id: 12,
        by_state: {
            ABORTED: 0,
            DEFINED: 0,
            DEQUEUED: 0,
            FAILED: 0,
            FINISHED: 1,
            RUNNING: 0,
        },
    },
    owner: {
        class_name: 'CmonUser',
        cdt_path: '/',
        owner_user_id: 5,
        owner_user_name: 'ccadmin',
        owner_group_id: 1,
        owner_group_name: 'admins',
        acl: 'user::rwx,group::r--,other::r--',
        created: '2021-04-28T23:00:42.821Z',
        disabled: false,
        email_address: 'ccadmin@localhost.xyz',
        first_name: '',
        last_failed_login: '',
        last_login: '2021-12-02T11:42:53.951Z',
        last_name: '',
        n_failed_logins: 0,
        origin: 'CmonDb',
        suspended: false,
        user_id: 5,
        user_name: 'ccadmin',
        groups: [
            {
                class_name: 'CmonGroup',
                cdt_path: '/groups',
                owner_user_id: 1,
                owner_user_name: 'system',
                owner_group_id: 1,
                owner_group_name: 'admins',
                acl: 'user::rwx,group::rwx,other::---',
                created: '2021-04-28T22:57:55.328Z',
                group_id: 1,
                group_name: 'admins',
            },
        ],
        timezone: {
            class_name: 'CmonTimeZone',
            name: 'Coordinated Universal Time',
            abbreviation: 'UTC',
            offset: 0,
            use_dst: false,
        },
    },
};
export default mock;
