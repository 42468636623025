import React from 'react';
import NodePortField from '../../../../common/Form/Fields/NodePortField';
import ClusterNodesForm, { ClusterNodesFormProps } from '../ClusterNodesForm';

export default MongoShardsRouterClusterNodesForm;

export type MongoShardsRouterClusterNodesFormProps = ClusterNodesFormProps & {
    clusterId?: number;
};

function MongoShardsRouterClusterNodesForm({
    form,
    clusterId,
    ...rest
}: MongoShardsRouterClusterNodesFormProps) {
    return (
        <div className="MongoShardsRouterClusterNodesForm">
            <ClusterNodesForm
                title="Router/Mongos"
                form={form}
                formItemProps={{ name: 'routersMongosTopology' }}
                nodesInputProps={{
                    onlyPrimaries: true,
                    formProps: {
                        primaryTitle: 'Node router server',
                        primaryInfo:
                            'Router/Mongos are responsible for routing queries and write operations to shards in a sharded cluster',
                        primaryExtra: null,
                        extra: (
                            <div>
                                <NodePortField name="routersMongosPort" />
                            </div>
                        ),
                    },
                    clusterId,
                    mutateItem: (item) => ({
                        ...item,
                        description: 'Router/Mongos',
                    }),
                }}
                {...rest}
            />
        </div>
    );
}
