import './SelectWrap.less';
import React, { useEffect, useState } from 'react';
import { Select, SelectProps } from 'antd';
import TypographyText from '../TypographyText';
import { DefaultOptionType } from 'rc-select/lib/Select';
import CcBackup from '../../services/models/CcBackup';

const SELECT_ADD_OPTION_VALUE = '[__new__]';

export default SelectWrap;
export type SelectWrapProps<T = any> = SelectProps<T> & {
    selectFirst?: boolean;
    // this works only if options are provided
    selectFirstOptionFilter?: (item: DefaultOptionType) => boolean;
    addOptionForm?: React.ReactElement;
    addOptionLabel?: React.ReactNode;
};

// @todo merge with AppSelect
// @todo consider merging this component with MultiSelect
function SelectWrap({
    children,
    onChange,
    onSelect,
    value,
    options,
    selectFirst,
    selectFirstOptionFilter,
    addOptionForm,
    addOptionLabel,
    mode,
    ...rest
}: SelectWrapProps) {
    const [formVisible, setFormVisible] = useState(false);

    useEffect(() => {
        if (selectFirst && !value && (onChange || onSelect)) {
            const firstOption = selectFirstOptionFilter
                ? options?.find(selectFirstOptionFilter)
                : options?.[0];
            let newValue = firstOption?.value;
            if (!newValue && Array.isArray(children) && children.length > 0) {
                newValue = (children[0] as React.ReactElement).props.value;
            }
            if (newValue) {
                const option = { value: newValue, label: newValue };
                onChange?.(newValue, option);
                onSelect?.(newValue, option);
            }
        }
    }, [value, children, options, onChange]);

    const handleSelect = (value: any, option: DefaultOptionType) => {
        if (value === SELECT_ADD_OPTION_VALUE) {
            setFormVisible(true);
        }
        // @todo re-think this, not sure why it's needed here
        if (mode !== 'multiple') {
            onSelect?.(value, option);
        }
    };
    const handleSuccess = (...args: any) => {
        setFormVisible(false);
        (addOptionForm as React.ReactElement)?.props?.onSuccess?.(...args);
    };
    const handleCancel = (...args: any) => {
        setFormVisible(false);
        onChange?.(undefined, {} as any);
        (addOptionForm as React.ReactElement)?.props?.onCancel?.(...args);
    };

    const addOption = !!addOptionForm;

    return (
        <div className="SelectWrap">
            {addOption && formVisible
                ? React.cloneElement(addOptionForm as React.ReactElement, {
                      ...(addOptionForm as React.ReactElement).props,
                      onSuccess: handleSuccess,
                      onCancel: handleCancel,
                  })
                : null}
            <Select
                value={value}
                onSelect={handleSelect}
                options={
                    addOption && options
                        ? [
                              {
                                  value: SELECT_ADD_OPTION_VALUE,
                                  label: (
                                      <div className="SelectWrap_new-option">
                                          {addOptionLabel || (
                                              <TypographyText>
                                                  Create new
                                              </TypographyText>
                                          )}
                                      </div>
                                  ),
                              },
                              ...options,
                          ]
                        : options
                }
                onChange={(...args) => {
                    onChange?.(...args);
                }}
                mode={mode}
                {...rest}
            >
                {addOption && Array.isArray(children)
                    ? [
                          <Select.Option
                              value={SELECT_ADD_OPTION_VALUE}
                              key={SELECT_ADD_OPTION_VALUE}
                          >
                              <div className="SelectWrap_new-option">
                                  {addOptionLabel || (
                                      <TypographyText>
                                          Create new
                                      </TypographyText>
                                  )}
                              </div>
                          </Select.Option>,
                          ...children,
                      ]
                    : children}
            </Select>
        </div>
    );
}
