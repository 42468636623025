import React, { useEffect } from 'react';
import { Space } from 'antd';
import useBackups from '../components/Backups/useBackups';
import CcBackupService from '../services/CcBackupService';
import getBackups1 from './dataMocks/rpc/backup/getBackups1';
import CcBackup from '../services/models/CcBackup';
import BackupFormat from '../components/Backups/BackupFormat';
import CcCluster from '../services/models/CcCluster';
import postgresSingleNode from './dataMocks/clusters/postgresSingleNode';
import mysqlReplication from './dataMocks/clusters/mysqlReplication';

export default DebugBackupFormat;

const clusters: { [key: number]: CcCluster } = {
    1: new CcCluster({
        ...postgresSingleNode,
        cluster_name: 'Postgres',
        cluster_id: 1,
    } as any),
    2: new CcCluster({
        ...mysqlReplication,
        cluster_name: 'Replication',
        cluster_id: 2,
    } as any),
};

function DebugBackupFormat() {
    CcBackupService.getBackups = () =>
        Promise.resolve({
            backup_records: getBackups1.backup_records.map(
                (record) => new CcBackup(record as any)
            ),
        });
    const { list, refresh } = useBackups({});
    useEffect(() => {
        refresh();
    }, []);
    return (
        <Space direction="vertical" size={20}>
            {list &&
                list.map((backup: CcBackup) => (
                    <BackupFormat
                        backup={backup}
                        cluster={clusters[backup.getCid()] as any}
                        key={backup.getId()}
                        showPopover={true}
                    >
                        Backup #{backup.getId()}
                    </BackupFormat>
                ))}
        </Space>
    );
}
