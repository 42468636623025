import './uiNotification.less';
import { Button, Modal, ModalProps, notification, Result, Space } from 'antd';
import classNames from 'classnames';
import {
    getStatusFormatIcon,
    StatusFormatStatus,
} from '@severalnines/bar-frontend-components/build/lib/Format/StatusFormat';
import { ModalFunc } from 'antd/lib/modal/confirm';
import { ArgsProps } from 'antd/lib/message';
import SpaceWide from '../../common/SpaceWide';
import * as React from 'react';
import AppDivider from '../../common/AppDivider';

export {
    notify,
    notifyModal,
    notifyToast,
    notifyConfirm,
    notifyConfirmSync,
    notifyAlert,
    notifyError,
    notifyOperationSuccess,
    notifyOperationMessages,
    notifyJobCreationSuccess,
    notifyJobCreationError,
    notifyConfirmCloseWindow,
    getLargeIconUrl,
};

export enum NotifyType {
    MODAL = 'modal',
    TOAST = 'toast',
}

export type NotifyProps<T> = T & {
    type?: NotifyType;
    status?: StatusFormatStatus;
    title?: React.ReactNode;
    content?: React.ReactNode;
    footer?: React.ReactNode;
    duration?: number | null;
};

function notify({
    type = NotifyType.MODAL,
    title,
    content,
    ...rest
}: NotifyProps<any>) {
    const defaluts = {
        title: title !== undefined ? title : 'Notification title!',
        content: content !== undefined ? content : '',
    };
    if (type === NotifyType.TOAST) {
        return notifyToast({ ...defaluts, ...rest });
    } else {
        return notifyModal({ ...defaluts, ...rest });
    }
}

export type NotifyToastProps = NotifyProps<ArgsProps>;

function notifyToast({
    type,
    content,
    title,
    status,
    // @ts-ignore
    ...rest
}: NotifyToastProps) {
    return notification.open({
        description: content,
        message: title,
        type: status,
        ...rest,
    });
}

export type NotifyModalProps = NotifyProps<ModalProps> & {
    size?: 'large' | 'default';
    modalFn?: ModalFunc;
};
function notifyModal({
    type,
    size,
    status,
    content,
    title,
    footer,
    modalFn = Modal.confirm,
    ...rest
}: NotifyModalProps) {
    return modalFn({
        title,
        content: (
            <SpaceWide direction="vertical">
                <div>{content}</div>
                <div style={{ textAlign: 'right' }}>{footer}</div>
            </SpaceWide>
        ),
        className: classNames(
            'UiNotification_modal',
            `UiNotification_modal--type-${status}`,
            `UiNotification_modal--size-${size}`
        ),
        ...(size === 'large'
            ? {
                  icon: <div></div>,
                  footer: null,
                  title: null,
                  width: 900,
                  content: (
                      <Result
                          style={{
                              background: 'white',
                              minWidth: '300px',
                              minHeight: '400px',
                          }}
                          status="success"
                          icon={
                              <div
                                  className="UiNotification_icon"
                                  style={{
                                      backgroundImage: `url("${getLargeIconUrl(
                                          status as StatusFormatStatus
                                      )}")`,
                                  }}
                              ></div>
                          }
                          title={title}
                          subTitle={
                              <Space direction="vertical" size={20}>
                                  <div>{content}</div>
                                  {footer}
                              </Space>
                          }
                      ></Result>
                  ),
              }
            : {
                  icon: getStatusFormatIcon(status as StatusFormatStatus),
              }),
        ...rest,
    });
}

function notifyConfirm({
    onCancel,
    onOk,
    cancelText = 'Cancel',
    okText = 'Ok',
    size,
    ...rest
}: NotifyModalProps) {
    let modal: any;
    const handleOkClick = (e: React.MouseEvent<HTMLElement>) => {
        e.persist();
        onOk?.(e);
        modal.destroy();
    };
    const handleCloseClick = (e: React.MouseEvent<HTMLElement>) => {
        e.persist();
        onCancel?.(e);
        modal.destroy();
    };
    modal = notify({
        onCancel,
        onOk,
        size,
        cancelText,
        okText,
        footer: (
            <Space>
                <Button
                    className="UiNotification_modal-button-cancel"
                    onClick={handleCloseClick}
                >
                    {cancelText}
                </Button>
                <Button
                    className="UiNotification_modal-button-ok"
                    type="primary"
                    onClick={handleOkClick}
                >
                    {okText}
                </Button>
            </Space>
        ),
        ...rest,
    });

    return modal;
}

async function notifyConfirmSync({
    onCancel,
    onOk,
    ...rest
}: NotifyModalProps) {
    let promise = new Promise((resolve) => {
        const handleOkClick = (e: React.MouseEvent<HTMLElement>) => {
            e.persist();
            onOk?.(e);
            resolve(true);
        };
        const handleCloseClick = (e: React.MouseEvent<HTMLElement>) => {
            e.persist();
            onCancel?.(e);
            resolve(false);
        };
        notifyConfirm({
            onOk: handleOkClick,
            onCancel: handleCloseClick,
            ...rest,
        });
    });

    return promise;
}
function notifyAlert({ onOk, okText = 'Ok', size, ...rest }: NotifyModalProps) {
    let modal: any;
    const handleOkClick = (e: React.MouseEvent<HTMLElement>) => {
        e.persist();
        onOk?.(e);
        modal.destroy();
    };
    modal = notify({
        onOk,
        status: StatusFormatStatus.warning,
        size,
        okText,
        footer: (
            <Space>
                <Button
                    className="UiNotification_modal-button-ok"
                    type="primary"
                    onClick={handleOkClick}
                >
                    {okText}
                </Button>
            </Space>
        ),
        ...rest,
    });
    return modal;
}

function notifyError({ onOk, okText, size, title, ...rest }: NotifyModalProps) {
    let modal: any;
    modal = notifyAlert({
        title: title || 'An error has ocurred!',
        onOk,
        status: StatusFormatStatus.error,
        size,
        okText,
        ...rest,
    });
    return modal;
}

export type NotifyJobCreationProps = NotifyModalProps & {
    navigate?: any;
};
function notifyJobCreationSuccess({
    type,
    size,
    navigate,
    ...rest
}: NotifyJobCreationProps = {}) {
    return notifyOperationSuccess({
        type,
        size,
        navigate,
        title: 'Job created successfully',
        content:
            type === NotifyType.TOAST
                ? 'This may take some time, please wait.'
                : 'Job is in progress, you can follow status in activity list.',
        okUri: '/activity-center/jobs',
        okText: 'Go to activity list',
        cancelText: 'Close',
        ...rest,
    });
}

export type OperationSuccessProps = NotifyModalProps & {
    navigate?: any;
    okUri?: string;
};
function notifyOperationSuccess({
    size,
    title,
    content,
    navigate,
    okUri,
    okText,
    cancelText,
    ...rest
}: OperationSuccessProps = {}) {
    let modal: any;
    const handleDetailsClick = () => {
        if (navigate && okUri) {
            navigate(okUri);
        }
        modal.destroy();
    };
    modal = notifyConfirm({
        title: title,
        size: size,
        status: StatusFormatStatus.success,
        content: content,
        okText,
        cancelText,
        onOk: handleDetailsClick,
        ...rest,
    });
    return modal;
}

function notifyJobCreationError({
    size,
    title,
    content,
    ...rest
}: NotifyJobCreationProps = {}) {
    let modal: any;
    const handleCloseClick = () => {
        modal.destroy();
    };

    const cancelText = 'Close';
    modal = notifyAlert({
        title: title || 'There was an error while creating the job!',
        size: size,
        status: StatusFormatStatus.error,
        content: content || 'Error when trying to create the job',
        cancelText,
        modalFn: Modal.error,
        onCancel: handleCloseClick,
        ...rest,
    });
    return modal;
}

export type ConfirmCloseWindowProps = NotifyModalProps & {
    onOk?: (result: boolean) => void;
};
function notifyConfirmCloseWindow({ onOk }: ConfirmCloseWindowProps = {}) {
    let modal: any;

    modal = notifyConfirm({
        size: 'large',
        title: 'Do you want to close the window?',
        content: 'All of the information you’ve entered will be lost.',
        okText: 'No',
        cancelText: 'Yes',
        onCancel: () => {
            if (onOk) {
                onOk(false);
            }
        },
    });
    return modal;
}


export type OperationMessagesProps = OperationSuccessProps & {
    messages: string[]
};

function notifyOperationMessages({
    messages,
    ...rest
}: OperationMessagesProps) {

    // add element AppDivider in between messages skip last one
    const messagesArray = messages.reduce((acc: any[], curr, i) => {
        const key = `${i}-${Date.now()}`;
        acc.push(
            <span
                key={`${key}`}
                dangerouslySetInnerHTML={{
                    __html: curr || '',
                }}
            ></span>
        );
        acc.push(<AppDivider key={`divider-${key}`} />);
        return acc;
    }, []);
    messagesArray.pop();

    notifyOperationSuccess({
        type: NotifyType.TOAST,
        title: <div>{messagesArray}</div>,
        content: '',
        ...rest
    });
}

function getLargeIconUrl(type?: StatusFormatStatus) {
    switch (type) {
        case StatusFormatStatus.success:
            return require('./img-check-sonar.svg');
        case StatusFormatStatus.error:
            return require('./img-cross-sonar.svg');
        case StatusFormatStatus.unknown:
        default:
            return require('./img-question-sonar.svg');
    }
}
