import React from 'react';
import { Form, Col, Row, InputNumber } from 'antd';
import CcCluster from '../../../../../services/models/CcCluster';
import { FormInstance } from 'antd/lib/form';
import PgBouncerHostnameField from '../../../FormFields/PgBouncerHostnameField';
import { getTopologyValidator } from '../../../loadBalancerHelpers';

export const PGBOUNCER_NODES_INITIAL = {
    pgBouncerAddNodes: {
        listenPort: 6432,
    },
};

type ImportPgBouncerAddNodesProps = {
    cluster?: CcCluster;
    form: FormInstance;
};

export default ImportPgBouncerAddNodes;

function ImportPgBouncerAddNodes({
    cluster,
    form,
}: ImportPgBouncerAddNodesProps) {
    return (
        <div style={{ minHeight: 450 }}>
            <Row gutter={[24, 0]}>
                <Col span={24}>
                    <h3>Add nodes</h3>
                </Col>
            </Row>
            <Row gutter={[24, 0]}>
                <Col span={24}>
                    <Form.Item shouldUpdate={true}>
                        {() =>
                            cluster ? (
                                <PgBouncerHostnameField
                                    name={['pgBouncerAddNodes', 'hostname']}
                                    form={form}
                                    cluster={cluster}
                                    nodeTypes={[
                                        form.getFieldValue([
                                            'pgBouncerAddNodes',
                                            'hostname',
                                        ]),
                                    ]}
                                />
                            ) : null
                        }
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        name={['pgBouncerAddNodes', 'listenPort']}
                        label="Listen Port"
                    >
                        <InputNumber
                            min={0}
                            style={{ width: '100%' }}
                            placeholder="Enter port number."
                        />
                    </Form.Item>
                </Col>
            </Row>
        </div>
    );
}

export function getImportPgBouncerAddNodesValidation(form: FormInstance) {
    return [
        ['pgBouncerAddNodes', 'hostname'],
        getTopologyValidator(form, ['pgBouncerAddNodes', 'hostname']),
    ];
}
