import CcProxySqlServer from '../../../../../../services/models/CcProxySqlServer';

export function prepareServerDataEndPoint(data: any) {
    return {
        class_name: 'CmonProxySqlServer',
        hostname: data.hostName,
        comment: data.comment,
        port: +data.port,
        compression: data.compression ? 1 : 0,
        use_ssl: data.useSSL ? 1 : 0,
        hostgroup_id: +data.hostGroupId,
        max_connections: +data.maxConnections,
        max_latency_ms: +data.latency,
        max_replication_lag: +data.replicationLag,
        weight: +data.weight,
    };
}

export function prepareDeleteServerData(data: CcProxySqlServer) {
    return {
        class_name: data.className,
        hostname: data.hostname,
        port: data.port,
        comment: data.comment,
        compression: Number(data.compression),
        hostgroup_id: Number(data.hostgroupId),
        max_connections: Number(data.maxConnections),
        max_latency_ms: Number(data.maxLatencyMs),
        max_replication_lag: Number(data.maxReplicationLag),
        use_ssl: Number(data.useSsl),
        weight: Number(data.weight),
    };
}
export function prepareInitialFormServerData(data: CcProxySqlServer) {
    return {
        hostName: data.hostname,
        comment: data.comment,
        hostGroupId: data.hostgroupId,
        port: Number(data.port),
        compression: Number(data.compression) === 0 ? false : true,
        useSSL: Number(data.useSsl) === 0 ? false : true,
        maxConnections: Number(data.maxConnections),
        latency: Number(data.maxLatencyMs),
        replicationLag: Number(data.maxReplicationLag),
        weight: Number(data.weight),
    };
}
