import React from 'react';
import { FormInstance } from 'antd/lib/form';
import SpaceDescriptions from '../../../../../common/Layout/SpaceDescriptions';
import AppDivider from '../../../../../common/AppDivider';

export type ImportHAProxyPreviewProps = {
    form: FormInstance;
};

export default ImportHAProxyPreview;

function ImportHAProxyPreview({ form }: ImportHAProxyPreviewProps) {
    const { haProxyConfiguration, haProxyAdvanced } = form.getFieldsValue(true);

    const [address] = haProxyConfiguration.address;
    return (
        <div>
            <SpaceDescriptions
                direction="vertical"
                title="Configuration"
                size="small"
                alignItems="right"
            >
                <SpaceDescriptions.Item label="Server Address" labelStrong>
                    {address?.extraData.hostname ?? ''}
                </SpaceDescriptions.Item>
                <SpaceDescriptions.Item label="Listen Port" labelStrong>
                    {haProxyConfiguration.port ?? ''}
                </SpaceDescriptions.Item>
            </SpaceDescriptions>
            <AppDivider />
            <SpaceDescriptions
                direction="vertical"
                title="Advanced settings"
                size="small"
                alignItems="right"
            >
                <SpaceDescriptions.Item label="adminUser" labelStrong>
                    {`${haProxyAdvanced.adminUser ?? ''}`}
                </SpaceDescriptions.Item>
                <SpaceDescriptions.Item label="adminPassword" labelStrong>
                    {`${haProxyAdvanced.adminPassword ?? ''}`}
                </SpaceDescriptions.Item>
                <SpaceDescriptions.Item label="cmdLine" labelStrong>
                    {`${haProxyAdvanced.cmdLine ?? ''}`}
                </SpaceDescriptions.Item>
                <SpaceDescriptions.Item label="lbName" labelStrong>
                    {`${haProxyAdvanced.lbName ?? ''}`}
                </SpaceDescriptions.Item>
                <SpaceDescriptions.Item label="haProxyConfig" labelStrong>
                    {`${haProxyAdvanced.haProxyConfig ?? ''}`}
                </SpaceDescriptions.Item>
                <SpaceDescriptions.Item
                    label="bustatsSocketildSource"
                    labelStrong
                >
                    {`${haProxyAdvanced.statsSocket ?? ''}`}
                </SpaceDescriptions.Item>
            </SpaceDescriptions>
        </div>
    );
}
