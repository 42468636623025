import React from 'react';
import { Form } from 'antd';
import { FormItemProps } from 'antd/lib/form';
import InputNumberWide from '../../DataEntry/InputNumberWide';

export default GaleraSegmentField;

export type GaleraSegmentFieldProps = FormItemProps & {};

function GaleraSegmentField({ ...rest }: GaleraSegmentFieldProps) {
    return (
        <div className="GaleraSegmentField">
            <Form.Item name="galeraSegment" label="Galera segment" {...rest}>
                <InputNumberWide placeholder="Enter galera segment" />
            </Form.Item>
        </div>
    );
}
