import React from 'react';
import { FormInstance } from 'antd/lib/form';
import SpaceDescriptions from '../../../../common/Layout/SpaceDescriptions';
import YesNoFormat from '../../../../common/Format/YesNoFormat';
import AppDivider from '../../../../common/AppDivider';
import MongoShardsConfigurationServerTopologySummary from '../../../Services/Cluster/MongoShards/MongoShardsConfigurationServerTopologySummary';
import MongoShardsRouterTopologySummary from '../../../Services/Cluster/MongoShards/MongoShardsRouterTopologySummary';

export default ClusterConvertToShardSummary;

export type ClusterConvertToShardSummaryProps = {
    form: FormInstance;
};

function ClusterConvertToShardSummary({
    form,
}: ClusterConvertToShardSummaryProps) {
    const {
        configurationTemplate,
        routerConfigurationTemplate,
        serverDataDirectory,
        installSoftware,
        disableFirewall,
        disableSeLinux,
    } = form.getFieldsValue(true);
    return (
        <div>
            <SpaceDescriptions
                direction="vertical"
                title="Database settings"
                className="ClusterConvertToShardSummary"
                alignItems="right"
            >
                <SpaceDescriptions.Item
                    label="Configuration template"
                    labelStrong
                >
                    {configurationTemplate}
                </SpaceDescriptions.Item>
                <SpaceDescriptions.Item
                    label="Router configuration template"
                    labelStrong
                >
                    {routerConfigurationTemplate}
                </SpaceDescriptions.Item>
                <SpaceDescriptions.Item label="Data directory" labelStrong>
                    {serverDataDirectory}
                </SpaceDescriptions.Item>
                <SpaceDescriptions.Item label="Install software" labelStrong>
                    <YesNoFormat booleanVar={installSoftware} />
                </SpaceDescriptions.Item>
                <AppDivider />
            </SpaceDescriptions>
            <SpaceDescriptions
                direction="vertical"
                title="Security configuration"
                size="small"
                alignItems="right"
            >
                <SpaceDescriptions.Item label="Disable firewall" labelStrong>
                    <YesNoFormat booleanVar={disableFirewall} />
                </SpaceDescriptions.Item>
                <SpaceDescriptions.Item
                    label="Disable SELinux/AppArmor"
                    labelStrong
                >
                    <YesNoFormat booleanVar={disableSeLinux} />
                </SpaceDescriptions.Item>
                <AppDivider />
            </SpaceDescriptions>
            <MongoShardsConfigurationServerTopologySummary form={form} />
            <AppDivider />
            <MongoShardsRouterTopologySummary form={form} />
        </div>
    );
}
