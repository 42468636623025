import React from 'react';
import './ClusterReplicationFormat.less';
import StatusFormat, {
    StatusFormatStatus,
    StatusFormatType,
} from '@severalnines/bar-frontend-components/build/lib/Format/StatusFormat';
import { CaretLeftFilled, CaretRightFilled } from '@ant-design/icons';
import ClusterFormat from '../../components/Clusters/ClusterFormat';
import CcCluster from '../../services/models/CcCluster';
import { Space } from 'antd';
import CcMySqlNode from '../../services/models/CcMySqlNode';
import AppTooltip from '../../common/Feedback/AppTooltip';
import CcNode, { CcNodeType } from '../../services/models/CcNode';
import CcPostgreSqlNode from '../../services/models/CcPostgreSqlNode';

export default ClusterReplicationFormat;

export type ClusterReplicationFormatProps = {
    cluster: CcCluster;
    secondaryCluster?: CcCluster;
    isBidirectional?: boolean;
};

function ClusterReplicationFormat({
    cluster,
    secondaryCluster,
    isBidirectional,
}: ClusterReplicationFormatProps) {
    const replicationNode =
        secondaryCluster?.getClusterReplicationNode() ||
        cluster.getClusterReplicationNode();

    const statusType = replicationNode
        ? getClusterReplicationFormatType(replicationNode)
        : StatusFormatStatus.default;

    const tooltipTitle = (
        <Space direction="vertical">
            <span>
                {isBidirectional ? 'Bidirectional' : 'One way'} replication
            </span>
            {/* @TODO: Commenting out until we find a better way to show c2c replication status/format */}
            {/*{[StatusFormatStatus.error, StatusFormatStatus.warning].includes(*/}
            {/*    statusType*/}
            {/*) ? (*/}
            {/*    [*/}
            {/*        <Space key={1}>*/}
            {/*            <TypographyText nowrap={true}>*/}
            {/*                Slave IO state:*/}
            {/*            </TypographyText>*/}
            {/*            <span>{replicationInfo.slaveIoState}</span>*/}
            {/*        </Space>,*/}
            {/*        <Space key={2}>*/}
            {/*            <TypographyText nowrap={true}>*/}
            {/*                Slave SQL state:*/}
            {/*            </TypographyText>*/}
            {/*            <span>{replicationInfo.slaveSqlState}</span>*/}
            {/*        </Space>,*/}
            {/*    ]*/}
            {/*) : (*/}
            {/*    <Space>*/}
            {/*        <span>From:</span>*/}
            {/*        <span>*/}
            {/*            {replicationInfo?.masterHost}:*/}
            {/*            {replicationInfo?.masterPort}*/}
            {/*        </span>*/}
            {/*    </Space>*/}
            {/*)}*/}
        </Space>
    );
    return (
        <StatusFormat
            className="ClusterReplicationFormat"
            type={StatusFormatType.tag}
            status={statusType}
            icon={
                isBidirectional ? (
                    <AppTooltip title={tooltipTitle}>
                        <span>
                            <CaretLeftFilled className="ClusterReplicationFormat__first-icon" />
                            <CaretRightFilled />
                            &nbsp;
                        </span>
                    </AppTooltip>
                ) : (
                    <AppTooltip title={tooltipTitle}>
                        <CaretRightFilled />
                        &nbsp;
                    </AppTooltip>
                )
            }
        >
            <Space>
                <ClusterFormat
                    showType={false}
                    cluster={cluster}
                    clusterLink={true}
                    linkDestination="nodes"
                />
            </Space>
        </StatusFormat>
    );
}

function getClusterReplicationFormatType(replicationNode: CcNode) {
    if (
        (replicationNode as
            | CcMySqlNode
            | CcPostgreSqlNode).isReplicationRunning()
    ) {
        return StatusFormatStatus.success;
    } else if (replicationNode.isType(CcNodeType.MYSQL)) {
        const mysqlNode = replicationNode as CcMySqlNode;
        if (
            [
                mysqlNode.isReplicationIoRunning(),
                mysqlNode.isReplicationSqlRunning(),
            ].some((i) => i === true)
        ) {
            return StatusFormatStatus.warning;
        } else if (
            [
                mysqlNode.replicationSlave?.slaveIoRunning,
                mysqlNode.replicationSlave?.slaveSqlRunning,
            ].some((i) => i === 'Connecting')
        ) {
            return StatusFormatStatus.loading;
        } else {
            return StatusFormatStatus.error;
        }
    } else {
        return StatusFormatStatus.error;
    }
}
