


export interface CmonProxySqlProcessListProps  {
    SessionID?: string;
    ThreadID?: string;
    class_name?: string;
    cli_host?: string;
    cli_port?: string;
    command?: string;
    db?: string;
    hostgroup?: number;
    info?: string;
    l_srv_host?: string;
    l_srv_port?: string;
    srv_host?: string;
    srv_port?: string;
    time_ms?: number;
    user?: string;

}



export default class CmonProxySqlProcessList {
    public SessionID?: string;
    public ThreadID?: string;
    public className?: string;
    public cliHost?: string;
    public cliPort?: string;
    public command?: string;
    public db?: string;
    public hostgroup?: number;
    public info?: string;
    public lSrvHost?: string;
    public lSrvPort?: string;
    public srvHost?: string;
    public srvPort?: string;
    public timeMs?: number;
    public user?: string;


    constructor(props: CmonProxySqlProcessListProps) {
        this.SessionID = props.SessionID;
        this.ThreadID = props.ThreadID;
        this.className = props.class_name;
        this.cliHost = props.cli_host;
        this.cliPort = props.cli_port;
        this.command = props.command;
        this.db = props.db;
        this.hostgroup = props.hostgroup;
        this.info = props.info;
        this.lSrvHost = props.l_srv_host;
        this.lSrvPort = props.l_srv_port;
        this.srvHost = props.srv_host;
        this.srvPort = props.srv_port;
        this.timeMs = props.time_ms;
        this.user = props.user;

    }

}
