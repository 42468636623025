import React, {
    forwardRef,
    useCallback,
    useContext,
    useEffect,
    useImperativeHandle,
    useMemo,
    useState,
} from 'react';
import useCloudCredentials, {
    UseCloudCredentialsFilterParams,
} from './useCloudCredentials';
import AppEmpty from '../../../common/Feedback/AppEmpty';
import { Space } from 'antd';
import { ResponsiveContext } from '@severalnines/bar-frontend-components/build/lib/Layout/Responsive';
import CcCloudCredentials from '../../../services/models/CcCloudCredentials';
import CloudProviderTypeFormat, {
    getCloudProviderName,
    getCloudProvidersTextValue,
} from '../CloudProviderTypeFormat';
import CloudCredentialsActionMenu from './CloudCredentialsActionMenu';
import { getSortAlphabeticFn, getSortNumberFn } from '../../../common/sorting';
import { TablePaginationConfig } from 'antd/es';
import { StringParam, useQueryParam } from 'use-query-params';
import AppTable from '../../../common/DataDisplay/AppTable';

export default forwardRef(CloudCredentialsList);
export interface CloudCredentialsListApi {
    refresh: () => void;
}

export type CloudCredentialsListProps = {};

function CloudCredentialsList({}: CloudCredentialsListProps, ref: any) {
    const [listOrder, setListOrder] = useState<(a: any, b: any) => number>(() =>
        getSortNumberFn('ascend', (x: CcCloudCredentials) => x.id)
    );
    const [providerFilter, setProviderFilter] = useQueryParam<any, any>(
        'provider',
        StringParam
    );
    const [listFilter, setListFilter] =
        useState<UseCloudCredentialsFilterParams>();
    const { responsive } = useContext(ResponsiveContext);
    const { list, loading, loaded, refresh, filter, page, pageSize, total } =
        useCloudCredentials({
            pageSize: 100,
        });
    useImperativeHandle(
        ref,
        (): CloudCredentialsListApi => ({
            async refresh() {
                await refresh();
            },
        })
    );

    useEffect(() => {
        refresh();
    }, []);

    useEffect(() => {
        if (loaded) {
            filter(listFilter);
        }
    }, [loaded, listFilter]);

    useEffect(() => {
        setListFilter({
            order: listOrder,
            filters: getCloudCredentialsListFilters({
                provider: providerFilter,
            }),
        });
    }, [listOrder, providerFilter]);

    const rowKey = useCallback((record: CcCloudCredentials) => record.id, []);

    const columns = useMemo(
        () => [
            {
                title: 'ID',
                key: 'id',
                width: 50,
                sorter: true,
                render: (record: CcCloudCredentials) => <>{record.id}</>,
            },
            {
                title: 'Name',
                key: 'name',
                width: 210,
                sorter: true,
                render: (record: CcCloudCredentials) => <>{record.name}</>,
            },
            {
                title: 'Provider',
                key: 'provider',
                width: 250,
                sorter: true,
                render: (record: CcCloudCredentials) => (
                    <CloudProviderTypeFormat provider={record.provider} />
                ),
                filters: getCloudProvidersTextValue().map((i) => ({
                    ...i,
                    text: <CloudProviderTypeFormat provider={i.value} />,
                })),
                filterSearch: true,
                filteredValue: providerFilter?.split('|'),
            },
            {
                title: 'Comment',
                key: 'comment',
                render: (record: CcCloudCredentials) => <>{record.comment}</>,
            },
            {
                key: 'Actions',
                align: 'actions',
                width: 30,
                render: (record: CcCloudCredentials) => (
                    <CloudCredentialsActionMenu
                        credentials={record}
                        onActionPerformed={() => {
                            refresh();
                        }}
                    />
                ),
            },
        ],
        [providerFilter]
    );

    const handleOnRow = useCallback(
        (record: CcCloudCredentials, index: number) => ({
            'data-testid': `cloud-credentials-list-row-${index}`,
        }),
        []
    );

    const handleTableChange = (
        pagination: any,
        currentFilter: any,
        sorters: any
    ) => {
        let sorterFn: any;
        switch (sorters.columnKey) {
            case 'id':
                sorterFn = getSortNumberFn(
                    sorters.order,
                    (x: CcCloudCredentials) => x.id
                );
                break;
            case 'name':
                sorterFn = getSortAlphabeticFn(
                    sorters.order,
                    (x: CcCloudCredentials) => x.name
                );
                break;
            case 'provider':
                sorterFn = getSortAlphabeticFn(
                    sorters.order,
                    (x: CcCloudCredentials) => getCloudProviderName(x.provider)
                );
                break;

            default:
                sorterFn = getSortNumberFn(
                    sorters.order,
                    (x: CcCloudCredentials) => x.id
                );
                break;
        }

        if (currentFilter.provider) {
            setProviderFilter(currentFilter.provider.join('|'));
        } else {
            setProviderFilter(undefined);
        }

        if (sorterFn) {
            setListOrder(() => sorterFn);
        }
    };
    const pagination: TablePaginationConfig = {
        size: 'default',
        pageSize,
        current: page,
        total,
        hideOnSinglePage: true,
        showQuickJumper: false,
        showSizeChanger: false,
        position: ['bottomCenter'],
    };

    return (
        <AppTable
            loading={loading}
            rowKey={rowKey}
            dataSource={list}
            renderEmpty={
                <AppEmpty
                    loading={loading}
                    description="You haven’t created credentials yet. When you do, it'll show up here."
                />
            }
            columns={columns}
            pagination={pagination}
            size="middle"
            onChange={handleTableChange}
            responsive={responsive}
            footer={() => <Space size="large" />}
            onRow={handleOnRow}
        />
    );
}

function getCloudCredentialsListFilters(filtersData: any): Function[] {
    const filters = [];
    if (filtersData['provider']) {
        filters.push(
            (record: CcCloudCredentials) =>
                record.provider === filtersData['provider'] ||
                filtersData['provider'].split('|').includes(record.provider)
        );
    }
    return filters;
}
