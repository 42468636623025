import useListFetch from '../../../common/useListFetch';
import CmonConfigService from '../../../services/cmon/CmonConfigService';

export type useNotificationServicesProps = {};

type IntegrationItem = {
    name: string;
    config: any;
    id: number;
    is_active: boolean;
    service_id: string;
};
export default function useNotificationServices({}: useNotificationServicesProps = {}) {
    const { list, refresh, loading } = useListFetch<IntegrationItem[]>({
        name: 'notification-services-list',
        fetchFn: async ({ pageSize, page, ...rest }, opts) => {
            const { integrations } = await CmonConfigService.listintegrations(
                {}
            );
            return {
                list:
                    integrations.map((item: IntegrationItem) => {
                        return { ...item, config: JSON.parse(item.config) };
                    }) || [],
            };
        },
        cancelFn: async ({ requestId }) => {
            await CmonConfigService.cancelRequest(requestId);
        },
    });
    return {
        list,
        refresh,
        loading,
    };
}
