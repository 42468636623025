import React, { useState } from 'react';
import ModalDefaultForm from '../../../common/ModalDefaultForm';
import useForm from 'antd/lib/form/hooks/useForm';
import CloudCredentialsWizard, {
    getCloudServiceName,
} from './CloudCredentialsWizard';
import {
    CcCloudCredentialsDTO,
    CcCloudProviderType,
} from '../../../services/models/CcCloudCredentials';
import { CloudCredentialsFormFields } from './CloudCredentialsWizardForm';
import CmonCloudService from '../../../services/cmon/CmonCloudService';
import {
    notifyError,
    notifyOperationSuccess,
    NotifyType,
} from '../../Notifications/uiNotification';

export default CloudCredentialsModalAdd;

export type CloudAddCredentialsResponse = {
    added_id?: number;
};

export type CloudAddCredentialsModalProps = {
    onCancel?: () => void;
    onSuccess?: (responseData: CloudAddCredentialsResponse) => void;
};

function CloudCredentialsModalAdd({
    onCancel,
    onSuccess,
}: CloudAddCredentialsModalProps) {
    const [serviceName, setServiceName] = useState<string>();
    const [form] = useForm<CloudCredentialsFormFields>();
    const [loading, setLoading] = useState<boolean>(false);
    const [confirmClose, setConfirmClose] = useState<boolean>(false);

    const handleCancel = () => {
        onCancel?.();
    };
    const handleServiceTypeChange = (type: CcCloudProviderType) => {
        setServiceName(getCloudServiceName(type));
    };

    const handleTouchedChange = (touched: boolean) => {
        setConfirmClose(touched);
    };

    const handleSubmit = async (dto: CcCloudCredentialsDTO) => {
        if (dto) {
            try {
                setLoading(true);
                const responseData = (await CmonCloudService.addCredentials(
                    dto
                )) as CloudAddCredentialsResponse;
                notifyOperationSuccess({
                    type: NotifyType.TOAST,
                    title: `Credentials '${dto.name}' saved successfully!`,
                    content: '',
                });
                setLoading(false);

                onSuccess?.(responseData);
            } catch (e) {
                setLoading(false);
                notifyError({
                    content: e.message,
                });
            }
        }
    };

    return (
        <ModalDefaultForm
            title={`Link ${serviceName || 'a cloud service provider'}`}
            form={form}
            footer={[]}
            onCancel={handleCancel}
            confirmClose={confirmClose}
            bodyStyle={{ padding: 0 }}
            defaultVisible={true}
        >
            <CloudCredentialsWizard
                form={form}
                onServiceTypeChange={handleServiceTypeChange}
                onSubmit={handleSubmit}
                onCancel={handleCancel}
                loading={loading}
                onTouchedChange={handleTouchedChange}
            />
        </ModalDefaultForm>
    );
}
