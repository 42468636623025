import './CurrentUserMenu.less';
import React from 'react';
import CcUser from '../../services/models/CcUser';
import WrapFormat from '../../common/Format/WrapFormat';
import {
    LogoutOutlined,
    SolutionOutlined,
    UserOutlined,
} from '@ant-design/icons';
import { Button, Divider, Space } from 'antd';
import TypographyText from '../../common/TypographyText';
import AppLink from '../../common/AppLink';
import UserInfo from './UserInfo';
import UserAclManageConfiguration from './UserAclManageConfiguration';

export default CurrentUserMenu;
export type CurrentUserMenuProps = {
    user: CcUser;
    country?: string;
    showName?: boolean;
};

function CurrentUserMenu({
    user,
    country,
    showName = true,
    ...rest
}: CurrentUserMenuProps) {
    return (
        <WrapFormat
            popoverProps={{
                destroyTooltipOnHide: true,
                overlayInnerStyle: { minWidth: '240px', padding: '10px' },
                trigger: 'click',
                placement: 'bottomLeft',
            }}
            popoverContent={
                <Space
                    className="CurrentUserMenu_Popover"
                    direction="vertical"
                    size={0}
                >
                    <UserInfo user={user} />
                    <Divider />
                    <Space
                        className="CurrentUserMenu_Links"
                        direction="vertical"
                        size={20}
                    >
                        <AppLink hoverable={true} to="/settings/profile">
                            <UserOutlined /> View profile
                        </AppLink>
                        <UserAclManageConfiguration>
                            <AppLink hoverable={true} to="/settings/license">
                                <SolutionOutlined /> License
                            </AppLink>
                        </UserAclManageConfiguration>

                        <AppLink hoverable={true} to="/logout">
                            <TypographyText type="danger">
                                <LogoutOutlined /> Logout
                            </TypographyText>
                        </AppLink>
                    </Space>
                </Space>
            }
        >
            <Button className="CurrentUserMenu_Name" type="link" size="small">
                <UserOutlined /> {showName && user.getName()}
            </Button>
        </WrapFormat>
    );
}
