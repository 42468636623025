import { FormInstance } from 'antd/lib/form';
import SpaceWide from '../../../../../common/SpaceWide';
import { Alert, Col, Form, Input, Radio, Row, Space } from 'antd';
import { BackupRestorePITRType } from '../BackupRestoreWizardForm';
import BackupRestoreTimePickerField from './BackupRestoreTimePickerField';
import InfoIcon from '@severalnines/bar-frontend-components/build/lib/General/InfoIcon';
import InputNumberWide from '../../../../../common/DataEntry/InputNumberWide';
import BackupRestorePITRInfoMessage from './BackupRestorePITRInfoMessage';
import React from 'react';

export default BackupRestoreMySqlPITRForm;
type BackupRestoreMySqlPITRFormProps = {
    form: FormInstance;
    showInfo?: boolean;
};

function BackupRestoreMySqlPITRForm({ form }: BackupRestoreMySqlPITRFormProps) {
    return (
        <SpaceWide direction="vertical">
            <Row gutter={[24, 0]}>
                <Col xs={24} sm={24} md={12}>
                    <Row>
                        <Form.Item name="pitrType">
                            <Radio.Group size="large">
                                <Radio.Button
                                    value={BackupRestorePITRType.TIME}
                                >
                                    Time
                                </Radio.Button>
                                <Radio.Button
                                    value={BackupRestorePITRType.POSITION}
                                >
                                    Position
                                </Radio.Button>
                            </Radio.Group>
                        </Form.Item>
                    </Row>

                    <Form.Item noStyle={true} shouldUpdate={true}>
                        {() => {
                            if (
                                form.getFieldValue('pitrType') ===
                                BackupRestorePITRType.TIME
                            ) {
                                return (
                                    <BackupRestoreTimePickerField
                                        label={
                                            <span>
                                                Restore time{' '}
                                                <InfoIcon
                                                    info={
                                                        <span>
                                                            Recover the data up
                                                            until the date and
                                                            time given by
                                                            Restore Time (Event
                                                            time - stop
                                                            date&amp;time).
                                                        </span>
                                                    }
                                                />
                                            </span>
                                        }
                                    />
                                );
                            } else {
                                return (
                                    <>
                                        <Form.Item
                                            name="pitrBinLogName"
                                            label={<span>Binary log name</span>}
                                            rules={[
                                                {
                                                    required: true,
                                                    message:
                                                        'Please enter binary log name',
                                                },
                                            ]}
                                        >
                                            <Input placeholder="Enter binary log name" />
                                        </Form.Item>
                                        <Form.Item
                                            name="pitrLogStopPosition"
                                            label={
                                                <span>Log stop position</span>
                                            }
                                            rules={[
                                                {
                                                    required: true,
                                                    type: 'number',
                                                    message:
                                                        'Please enter log stop position',
                                                },
                                            ]}
                                        >
                                            <InputNumberWide placeholder="Enter log stop position" />
                                        </Form.Item>
                                    </>
                                );
                            }
                        }}
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={[24, 0]}>
                <Col span={24}>
                    <Alert
                        showIcon={true}
                        description={
                            <Space direction="vertical">
                                <span>
                                    Initially, only a single node (master) of
                                    the cluster will be restored. Please verify
                                    the integrity of the dataset on the
                                    recovered node and then individually rebuild
                                    each remaining node from the "Node Actions"
                                    menu.
                                </span>
                                <BackupRestorePITRInfoMessage form={form} />
                            </Space>
                        }
                    />
                </Col>
            </Row>
        </SpaceWide>
    );
}
