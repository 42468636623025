import React, { useMemo, useState } from 'react';
import { Col, Form, Input, Row, Space, Checkbox } from 'antd';
import FormItemInlineSwitch from '../../../common/DataEntry/FormItemInlineSwitch';
import InfoIcon from '@severalnines/bar-frontend-components/build/lib/General/InfoIcon';
import FormFooter from '../../../common/FormFooter';
import { AppState, AppStateUser } from '../../../appReducer';
import { useSelector } from 'react-redux';
import CmonConfigService from '../../../services/cmon/CmonConfigService';
import {
    notifyError,
    notifyOperationSuccess,
    NotifyType,
} from '../../Notifications/uiNotification';
import { useDebugContext } from '../../../common/Debug';
import useClusterList from '../../Clusters/useClusterList';
import CmonClustersService from '../../../services/cmon/CmonClustersService';
import AppDivider from '../../../common/AppDivider';
import useCreateJob from '../../Jobs/useCreateJob';
import { CmonJobInstanceCommand } from '../../../services/cmon/models/CmonJobInstance';
import AppFormItem from '../../../common/Form/AppFormItem';
import useFetch from '../../../common/useFetch';
import AppSpin from '../../../common/General/AppSpin';
import ContentWrapper from "../../../common/Layout/ContentWrapper";

export default SMTPForm;

export type SMTPFormProps = {
    onCancel?: () => void;
    onSuccess?: () => void;
};

export interface SMTPFormValues {
    hostname?: string;
    port?: number;
    username?: string;
    password?: string;
    sender?: string;
    useTls?: boolean;
    emailAddress?: string;
    sendTestEmail?: boolean;
}

function SMTPForm({ onCancel, onSuccess }: SMTPFormProps) {
    const [form] = Form.useForm<SMTPFormValues>();
    const [user]: [AppStateUser] = useSelector(({ user }: AppState) => [user]);
    const { log } = useDebugContext();
    const [loading, setLoading] = useState<boolean>(false);
    const { list: clusters } = useClusterList({ fromStore: true });

    const { send: sendTestEmail } = useCreateJob({
        title: 'Send test email',
        command: CmonJobInstanceCommand.EMAIL,
        clusterId: 0,
        successTitle: 'Job to test email server created succesfully',
    });

    const {
        data: currentConfig,
        loading: loadingCurrentConfig,
        loaded,
    } = useFetch<any>({
        autoFetch: true,
        fetchFn: async () => {
            return await CmonConfigService.getmailserver({});
        },
    });

    const handleSubmit = async () => {
        try {
            setLoading(true);
            const formValues = form.getFieldsValue();
            await CmonConfigService.setmailserver({
                smtp_server: {
                    hostname: formValues.hostname,
                    port: formValues.port,
                    username: formValues.username,
                    password: formValues.password,
                    sender: formValues.sender,
                    use_tls: formValues.useTls,
                },
            });
            notifyOperationSuccess({
                type: NotifyType.TOAST,
                title: 'SMTP server configured successfully!',
                content: '',
            });

            const success: number[] = [];
            const errors: Error[] = [];
            if (clusters) {
                //FIXME: backend should support saving config for all clusters or at least receive an array of clusterIds
                await Promise.all(
                    clusters.map(async (cluster) => {
                        try {
                            await CmonClustersService.setConfig({
                                cluster_id: cluster.clusterId,
                                configuration: [
                                    {
                                        name: 'cmon_use_mail',
                                        value: false,
                                    },
                                ] as any,
                            });
                            success.push(cluster.clusterId as number);
                        } catch (err) {
                            errors.push(
                                new Error(
                                    `Saving cluster (#${cluster.clusterId}) configuration has failed! Error: ${err.message}`
                                )
                            );
                        }
                    })
                );
                if (success.length > 0) {
                    notifyOperationSuccess({
                        type: NotifyType.TOAST,
                        title: `Configuration saved successfully!`,
                        content: `clusters ${success
                            .map((id) => `#${id}`)
                            .join(',')}`,
                    });
                }
            }
            if (errors.length > 0) {
                errors.forEach((err) => {
                    notifyError({
                        type: NotifyType.TOAST,
                        content: err?.message,
                    });
                    log.error(err);
                });
            } else {
                if (formValues.sendTestEmail) {
                    await sendTestEmail({ recipient: formValues.emailAddress });
                }
                onSuccess?.();
            }
        } catch (err) {
            notifyError({ type: NotifyType.TOAST, content: err?.message });
            log.error(err);
        } finally {
            setLoading(false);
        }
    };

    const initialValues = useMemo(
        () =>
            loadingCurrentConfig
                ? undefined
                : currentConfig
                ? {
                      hostname: currentConfig.smtp_server.hostname,
                      port: currentConfig.smtp_server.port,
                      username: currentConfig.smtp_server.username,
                      password: currentConfig.smtp_server.password,
                      sender: currentConfig.smtp_server.sender,
                      useTls: currentConfig.smtp_server.use_tls,
                      emailAddress: user?.emailAddress,
                  }
                : {},
        [currentConfig, user, loadingCurrentConfig]
    );

    return (
        <ContentWrapper>
            <AppSpin spinning={loadingCurrentConfig}>
                {initialValues ? (
                    <Form
                        form={form}
                        layout="vertical"
                        autoComplete="off"
                        onFinish={handleSubmit}
                        initialValues={initialValues}
                    >
                        <Row gutter={[24, 0]}>
                            <Col xs={24} sm={24} md={12} key="serverAddress">
                                <Form.Item
                                    name="hostname"
                                    label={
                                        <Space>
                                            Server Address
                                            <InfoIcon
                                                info={
                                                    <>
                                                        <span>
                                                            SMTP mail server
                                                            address that you are
                                                            going to use to send
                                                            the email.
                                                        </span>
                                                    </>
                                                }
                                            />
                                        </Space>
                                    }
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                'Please enter the server address!',
                                        },
                                    ]}
                                >
                                    <Input placeholder="Enter the server address" />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12} key="port">
                                <Form.Item
                                    name="port"
                                    label={
                                        <Space>
                                            Port
                                            <InfoIcon
                                                info={
                                                    <>
                                                        <span>
                                                            SMTP port for the
                                                            mail server.
                                                            Usually, this value
                                                            is 25 or 587,
                                                            depending on your
                                                            SMTP mail server
                                                            configuration.
                                                        </span>
                                                    </>
                                                }
                                            />
                                        </Space>
                                    }
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter the port!',
                                        },
                                    ]}
                                >
                                    <Input placeholder="Enter the server port" />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={[24, 0]}>
                            <Col xs={24} sm={24} md={12} key="username">
                                <Form.Item
                                    name="username"
                                    label={
                                        <Space>
                                            User name
                                            <InfoIcon
                                                info={
                                                    <>
                                                        <span>
                                                            SMTP user name.
                                                            Leave empty if no
                                                            authentication
                                                            required.
                                                        </span>
                                                    </>
                                                }
                                            />
                                        </Space>
                                    }
                                >
                                    <Input placeholder="Enter the user name" />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12} key="password">
                                <Form.Item
                                    name="password"
                                    label={
                                        <Space>
                                            Password
                                            <InfoIcon
                                                info={
                                                    <>
                                                        <span>
                                                            SMTP password. Leave
                                                            empty if no
                                                            authentication
                                                            required.
                                                        </span>
                                                    </>
                                                }
                                            />
                                        </Space>
                                    }
                                >
                                    <Input.Password
                                        maxLength={100}
                                        placeholder="Enter the password"
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={[24, 0]}>
                            <Col xs={24} sm={24} md={12} key="sender">
                                <Form.Item
                                    name="sender"
                                    label={
                                        <Space>
                                            Reply to/from
                                            <InfoIcon
                                                info={
                                                    <>
                                                        <span>
                                                            Specify the sender
                                                            of the email. This
                                                            will appear in the
                                                            'From' field of the
                                                            mail header.
                                                        </span>
                                                    </>
                                                }
                                            />
                                        </Space>
                                    }
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                'Please enter the Reply to/from!',
                                        },
                                        {
                                            type: 'email',
                                            message: 'Invalid email address',
                                        },
                                    ]}
                                >
                                    <Input
                                        type="email"
                                        placeholder="Enter the Reply to/from"
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12} key="useTls">
                                <Form.Item>
                                    <FormItemInlineSwitch
                                        justify
                                        noMargin
                                        name="useTls"
                                        label={
                                            <Space>
                                                TLS/SSL required
                                                <InfoIcon
                                                    info={
                                                        <>
                                                            <span>
                                                                Check this box
                                                                if ou want to
                                                                use TLS/SSL for
                                                                extra security.
                                                                The mail server
                                                                must support
                                                                TLS/SSL.
                                                            </span>
                                                        </>
                                                    }
                                                />
                                            </Space>
                                        }
                                        valuePropName="checked"
                                    />
                                </Form.Item>
                            </Col>
                            <AppDivider />

                            <Col xs={24} sm={24} md={12} key="emailAddress">
                                <AppFormItem
                                    name="sendTestEmail"
                                    valuePropName="checked"
                                    initialValue={false}
                                    extra={
                                        <AppFormItem
                                            shouldUpdate={(prev, next) =>
                                                prev.sendTestEmail !==
                                                next.sendTestEmail
                                            }
                                            noStyle={true}
                                        >
                                            {() => {
                                                return form.getFieldValue(
                                                    'sendTestEmail'
                                                ) ? (
                                                    <AppFormItem
                                                        name="emailAddress"
                                                        rules={[
                                                            {
                                                                type: 'email',
                                                                message:
                                                                    'Invalid email address',
                                                            },
                                                            {
                                                                required: true,
                                                                message:
                                                                    'Email address is required',
                                                            },
                                                        ]}
                                                        label={null}
                                                    >
                                                        <Input placeholder="Enter email address" />
                                                    </AppFormItem>
                                                ) : null;
                                            }}
                                        </AppFormItem>
                                    }
                                >
                                    <Checkbox data-testid="trial">
                                        Send test email
                                    </Checkbox>
                                </AppFormItem>
                            </Col>
                        </Row>
                        <AppFormItem
                            shouldUpdate={(prev, next) =>
                                prev.sendTestEmail !== next.sendTestEmail
                            }
                            noStyle={true}
                        >
                            {() => {
                                return (
                                    <FormFooter
                                        loading={loading}
                                        submitButtonText={`${
                                            form.getFieldValue('sendTestEmail')
                                                ? 'Save and send test email'
                                                : 'Save'
                                        }`}
                                        showCancelButton
                                        showSubmitButton
                                        onCancel={onCancel}
                                    />
                                );
                            }}
                        </AppFormItem>
                    </Form>
                ) : null}
            </AppSpin>
        </ContentWrapper>
    );
}
