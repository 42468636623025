import { useState, useEffect } from 'react';
import { Tooltip } from 'antd';
import { CloseCircleOutlined, CheckCircleOutlined } from '@ant-design/icons';
import SpaceWide from '../../common/SpaceWide';
import AppTable from '../../common/DataDisplay/AppTable';
import AppDateFormat from '../../common/AppDateFormat';
import KeyManagementActionMenu from './KeyManagementActionMenu';
import TypographyText from '../../common/TypographyText';
import { CertificateTypes } from '../Security/UseExistingCertificate';

export default KeyManagementTable;

export type KeyManagementTableProps = {
    selectedNode: any;
    list: any;
    loading: boolean;
    onActionPerformed: () => void;
};

function KeyManagementTable({
    selectedNode,
    list,
    loading = false,
    onActionPerformed,
}: KeyManagementTableProps) {
    const [records, setRecords] = useState([]);
    let selectedItem: any = [];
    const handleOnDelete = (id: number) => {
        const rows = [...records];
        rows.splice(
            rows.findIndex((cert: any) => cert.id === id),
            1
        );
        setRecords(rows);
        onActionPerformed?.();
    };

    function getChildren(node: any) {
        if (
            node?.type === CertificateTypes.CERTIFICATE ||
            node?.type === CertificateTypes.CERTIFICATE_AUTHORITY
        ) {
            selectedItem.push(node.data);
        } else if (node.children) {
            node?.children?.forEach((child: any) => {
                getChildren(child);
            });
        }
    }

    const handleTreeItemSelect = (node: any) => {
        selectedItem = [];
        if (node.type === CertificateTypes.DIRECTORY) {
            node?.children?.forEach((child: any) => {
                getChildren(child);
            });
        } else if (
            node.type === CertificateTypes.CERTIFICATE ||
            node.type === CertificateTypes.CERTIFICATE_AUTHORITY
        ) {
            selectedItem.push(node.data);
        }
        setRecords(selectedItem);
    };

    useEffect(() => {
        if (selectedNode) {
            handleTreeItemSelect(selectedNode);
        }
    }, [selectedNode]);

    const columns = [
        {
            title: 'Key',
            key: 'certfile',
            render: (record: any) => (
                <>
                    {record.subjectName.description ||
                    record.inUseBy.hosts.length ? (
                        <Tooltip
                            placement="topLeft"
                            title={
                                <>
                                    {record.subjectName.description ? (
                                        <div>
                                            Comment:
                                            <br />{' '}
                                            {record.subjectName?.description}
                                        </div>
                                    ) : (
                                        ''
                                    )}
                                    {record.inUseBy.hosts.length ? (
                                        <div
                                            style={{
                                                marginTop: '10px',
                                            }}
                                        >
                                            In Use By:
                                            <br />
                                            {record.inUseBy.hosts.join(', ')}
                                        </div>
                                    ) : (
                                        ''
                                    )}
                                </>
                            }
                        >
                            {record.certfile?.split('/').pop()}
                        </Tooltip>
                    ) : (
                        record.certfile?.split('/').pop()
                    )}
                </>
            ),
        },
        {
            title: 'Expiration date',
            key: 'validUntil',
            render: (record: any) => (
                <span style={{ whiteSpace: 'nowrap' }}>
                    <AppDateFormat>
                        {record.validUntil
                            ? new Date(record.validUntil * 1000)
                            : undefined}
                    </AppDateFormat>
                </span>
            ),
        },
        {
            title: 'Created',
            key: 'validFrom',
            render: (record: any) => (
                <span style={{ whiteSpace: 'nowrap' }}>
                    <AppDateFormat>
                        {record.validFrom
                            ? new Date(record.validFrom * 1000)
                            : undefined}
                    </AppDateFormat>
                </span>
            ),
        },
        {
            title: 'In Use',
            key: 'inUseBy',
            render: (record: any) =>
                record?.inUseBy.hosts.length > 0 ? (
                    <CheckCircleOutlined style={{ color: '#52C41A' }} />
                ) : (
                    <CloseCircleOutlined />
                ),
        },
        {
            title: 'Common name',
            key: 'subjectName',
            render: (record: any) => (
                <TypographyText
                    style={{ maxWidth: 200 }}
                    ellipsis={{
                        tooltip: record.subjectName?.CN,
                    }}
                >
                    {record.subjectName?.CN}
                </TypographyText>
            ),
        },
        {
            title: 'Actions',
            align: 'center',
            render: (record: any) => (
                <KeyManagementActionMenu
                    list={list}
                    selectedRow={record}
                    onActionPerformed={onActionPerformed}
                    onDelete={handleOnDelete}
                />
            ),
        },
    ];

    return (
        <SpaceWide direction="vertical">
            <AppTable
                loading={loading}
                dataSource={records}
                columns={columns}
                size="middle"
                onRow={(record: any, index: any) => ({
                    'data-testid': `report-table-row-${index}`,
                })}
            />
            <div></div>
        </SpaceWide>
    );
}
