import React from 'react';

import * as icons from '../common/icons/icons';
import { Col, Divider, Row } from 'antd';

export default DebugIcons;

export type DebugIconsProps = {};

function DebugIcons({}: DebugIconsProps) {
    return (
        <div className="DebugIcons">
            {[
                {},
                { disable: true },
                { size: 'xsmall' },
                { size: 'xsmall', disable: true },
                { size: 'small' },
                { size: 'small', disable: true },
                { size: 'medium' },
                { size: 'medium', disable: true },
                { size: 'large' },
                { size: 'large', disable: true },
                { size: 'xlarge' },
                { size: 'xlarge', disable: true },
            ].map((props) => (
                <div>
                    <Divider>{JSON.stringify(props)}</Divider>
                    <Row>
                        {Object.keys(icons).map((k, idx) => {
                            // @ts-ignore
                            const Component: any = icons[k];
                            return (
                                <Col key={idx} span={2}>
                                    <Component {...props} />
                                    <br />
                                    {k}
                                </Col>
                            );
                        })}
                    </Row>
                </div>
            ))}
            {[
                {
                    width: '100%',
                    height: '100%',
                    size: 'auto',
                },
                {
                    width: '50%',
                    height: '50%',
                    size: 'auto',
                },
            ].map((props) => (
                <div>
                    <Divider>{JSON.stringify(props)}</Divider>
                    <Row>
                        {Object.keys(icons).map((k, idx) => {
                            // @ts-ignore
                            const Component: any = icons[k];
                            return (
                                <Col
                                    key={idx}
                                    style={{
                                        border: '1px solid',
                                        width: '150px',
                                        height: '150px',
                                    }}
                                >
                                    <Component {...props} />
                                    <br />
                                    {k}
                                </Col>
                            );
                        })}
                    </Row>
                </div>
            ))}
        </div>
    );
}
