import React, { useState } from 'react';
import { ButtonProps } from 'antd/lib/button';
import ConfirmActionButton from '@severalnines/bar-frontend-components/build/lib/General/ConfirmActionButton';
import CmonUsersService from '../../../services/cmon/CmonUsersService';
import { useDebugContext } from '../../../common/Debug';
import { notifyError } from '../../Notifications/uiNotification';
import CcUser from '../../../services/models/CcUser';

export default UserEnableDisableButton;

export type UserEnableDisableButtonProps = ButtonProps & {
    user: CcUser;
    onSuccess?: () => void;
    onError?: (error: any) => void;
};

function UserEnableDisableButton({
    user,
    children,
    onSuccess,
    onError,
    ...rest
}: UserEnableDisableButtonProps) {
    const { log } = useDebugContext();
    const [loading, setLoading] = useState(false);
    const handleClick = async () => {
        try {
            setLoading(true);
            const data = {
                user: {
                    class_name: 'CmonUser',
                    user_name: user.userName,
                },
            };
            if (user.suspended || user.disabled) {
                await CmonUsersService.enable(data);
            } else {
                await CmonUsersService.disable(data);
            }
            onSuccess?.();
        } catch (err) {
            log.error(err);
            notifyError({ content: err.message });
            onError?.(err);
        }
        setLoading(false);
    };

    return (
        <ConfirmActionButton
            confirmTitle={
                user.suspended || user.disabled
                    ? 'Enable user?'
                    : 'Disable user?'
            }
            onConfirm={handleClick}
            loading={loading}
            critical={!(user.suspended || user.disabled)}
            {...rest}
        >
            {children ||
                (user.suspended || user.disabled ? 'Enable' : 'Disable')}
        </ConfirmActionButton>
    );
}
