import React, { useState } from 'react';
import { Col, Divider, Row } from 'antd';
import AppTable from '../common/DataDisplay/AppTable';

export default DebugAppFormItem;
export type DebugAppFormItemProps = {};

function DebugAppFormItem({ ...rest }: DebugAppFormItemProps) {
    const columns = [
        {
            title: 'field1',
            dataIndex: 'field1',
        },
        {
            title: 'field2',
            dataIndex: 'field2',
        },
    ];
    return (
        <Row className="DebugAppFormItem">
            <Col span={12}>
                <Divider plain={true} orientation="left">
                    <strong>datasource null</strong>
                </Divider>
                <AppTable columns={columns} dataSource={null!} />
                <Divider plain={true} orientation="left">
                    <strong>datasource undefined</strong>
                </Divider>
                <AppTable columns={columns} dataSource={undefined!} />
                <Divider plain={true} orientation="left">
                    <strong>datasource empty []</strong>
                </Divider>
                <AppTable columns={columns} dataSource={[]} />
                <Divider plain={true} orientation="left">
                    <strong>datasource empty [] but not loaded</strong>
                </Divider>
                <AppTable columns={columns} dataSource={[]} loaded={false} />
                <Divider plain={true} orientation="left">
                    <strong>datasource not empty</strong>
                </Divider>
                <AppTable
                    columns={columns}
                    dataSource={[{ field1: 'FIELD A', field2: 'FIELD B' }]}
                />
            </Col>
            <Col span={12}>
                <Divider plain={true} orientation="left">
                    <strong>datasource null loading</strong>
                </Divider>
                <AppTable columns={columns} dataSource={null!} loading={true} />
                <Divider plain={true} orientation="left">
                    <strong>datasource undefined loading</strong>
                </Divider>
                <AppTable
                    columns={columns}
                    dataSource={undefined!}
                    loading={true}
                />
                <Divider plain={true} orientation="left">
                    <strong>datasource empty [] loading</strong>
                </Divider>
                <AppTable columns={columns} dataSource={[]} loading={true} />
                <Divider plain={true} orientation="left">
                    <strong>datasource empty [] but not loaded, loading</strong>
                </Divider>
                <AppTable
                    columns={columns}
                    dataSource={[]}
                    loaded={false}
                    loading={true}
                />
                <Divider plain={true} orientation="left">
                    <strong>datasource not empty loading</strong>
                </Divider>
                <AppTable
                    columns={columns}
                    dataSource={[{ field1: 'FIELD A', field2: 'FIELD B' }]}
                    loading={true}
                />
            </Col>
        </Row>
    );
}
