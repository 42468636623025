import React from 'react';
import { ButtonProps } from 'antd/lib/button';
import ButtonWithForm from '../../../common/General/ButtonWithForm';
import ClusterNodesUpgradeForm from '../Upgrade/ClusterNodesUpgradeForm';
import { CmonJobInstanceCommand } from '../../../services/cmon/models/CmonJobInstance';
import JobActionButton from '../../Jobs/JobActionButton';
import CcCluster from '../../../services/models/CcCluster';

export default ClusterNodesUpgrade;
export type ClusterNodesUpgradeProps = ButtonProps & {
    cluster: CcCluster;
};

function ClusterNodesUpgrade({
    cluster,
    children,
    ...rest
}: ClusterNodesUpgradeProps) {
    return (
        <ButtonWithForm
            button={
                <JobActionButton
                    command={CmonJobInstanceCommand.UPGRADE_CLUSTER}
                    clusterId={cluster.clusterId}
                    {...rest}
                >
                    {children || 'Upgrade nodes'}
                </JobActionButton>
            }
            form={<ClusterNodesUpgradeForm cluster={cluster} />}
        />
    );
}
