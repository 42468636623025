const result = {
    ldap_configuration: {
        enabled: true,
        ldapAdminPassword: 'admin',
        ldapAdminUser: 'cn=admin,dc=nodomain',
        ldapGroupSearchRoot: 'dc=nodomain',
        ldapServerUri: 'ldap://localhost',
        ldapUserSearchRoot: 'dc=nodomain',
        defaults: {
            enabled: false,
            ldapAdminPassword: '',
            ldapAdminUser:
                'CN=Administrator,CN=Users,DC=ldap,DC=mydomain,DC=com',
            ldapGroupSearchRoot: 'CN=Groups,OU=IT,DC=ldap,DC=mydomain,DC=com',
            ldapServerUri: 'ldaps://ldap.mydomain.com:686',
            ldapUserSearchRoot: 'CN=Users,DC=ldap,DC=mydomain,DC=com',
            groupMappings: [],
            ldapSettings: {
                ldapEmailAttributes: 'mail,userPrincipalName',
                ldapGroupClassName: '',
                ldapGroupIdAttributes: 'dn,uid',
                ldapGroupNameAttribute: 'cn',
                ldapMemberAttributes: 'member,memberUid',
                ldapNetworkTimeout: 5,
                ldapProtocolVersion: 3,
                ldapQueryTimeLimit: 5,
                ldapRealnameAttributes: 'cn,displayName',
                ldapUserClassName: 'objectclass=*',
                ldapUsernameAttributes: 'uid,sAMAccountName',
            },
            security: {
                caCertFile: '/etc/ssl/certs/ldap-ca.crt',
                certFile: '/etc/ssl/certs/ldap.crt',
                keyFile: '/etc/ssl/certs/ldap.pem',
            },
        },
        groupMappings: [
            {
                cmonGroupName: 'users',
                ldapGroupId: 'ldapusersgroup',
                sectionName: 'ldapusersgroupusers',
            },
            {
                cmonGroupName: 'admins',
                ldapGroupId: 'ldapadminsgroup',
                sectionName: 'ldapadminsgroupadmins',
            },
        ],
        ldapSettings: {
            ldapEmailAttributes: 'mail',
            ldapGroupClassName: null,
            ldapGroupIdAttributes: 'uid',
            ldapGroupNameAttribute: 'cn',
            ldapMemberAttributes: 'member',
            ldapNetworkTimeout: null,
            ldapProtocolVersion: null,
            ldapQueryTimeLimit: null,
            ldapRealnameAttributes: 'cn',
            ldapUserClassName: null,
            ldapUsernameAttributes: 'uid',
        },
        security: {
            caCertFile: null,
            certFile: null,
            keyFile: null,
        },
    },
};

export default result;
