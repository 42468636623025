import React from 'react';
import HostCheckInput from '../common/DataEntry/HostCheckInput';
import { Divider } from 'antd';

export default DebugHostCheckInput;
export type DebugHostCheckInputProps = {};

function DebugHostCheckInput({ ...rest }: DebugHostCheckInputProps) {
    return (
        <div className="DebugHostCheckInput" style={{ width: 300 }}>
            <h1>HostCheckInput</h1>
            <Divider plain={true} orientation="left">
                with clusterId
            </Divider>
            <HostCheckInput clusterId={1} />
        </div>
    );
}
