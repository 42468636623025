import React, { useEffect, useRef } from 'react';
import { Divider, Space } from 'antd';
import useClusterList from '../components/Clusters/useClusterList';
import BackupCreateWizardButton from '../components/Backups/BackupCreateWizardButton';
import ClusterTypeFormat, {
    getClusterTypeText,
} from '../components/Clusters/ClusterTypeFormat';
import {
    BackupWizardStep,
    BackupWizardStepActionType,
} from '../components/Backups/Wizard/BackupWizard';
import useServiceMock from './serviceMock/useServiceMock';
import { debugModal, ServiceMethod } from './serviceMock/ServiceMockContext';
import { setRunningJobs } from '../appReducer';
import Immutable from 'immutable';
import CcJob from '../services/models/CcJob';
import { useDispatch } from 'react-redux';
import getJobInstances from './dataMocks/rpc/jobs/getJobInstances';

export default DebugBackupWizardButton;

const jobData = {
    can_be_aborted: false,
    can_be_deleted: false,
    cluster_id: 10,
    created: '2022-09-01T06:48:43.000Z',
    exit_code: 0,
    group_id: 1,
    group_name: 'admins',
    has_progress: true,
    ip_address: '58.11.94.163',
    job_id: 27077,
    job_spec: {
        command: 'pgbackrest',
        job_data: {
            action: 'setup',
            clusterId: 10,
            nodes: [
                {
                    class_name: 'CmonPgBackRestHost',
                    hostname: '10.0.8.11',
                },
            ],
        },
    },
    progress_percent: 75.5,
    rpc_version: '2.0',
    started: '2022-09-01T06:48:43.000Z',
    status: 'RUNNING',
    status_text: '10.0.8.11: Installing.',
    title: 'Install PgBackRest Backup',
    user_id: 5,
    user_name: 'alex',
};
function DebugBackupWizardButton() {
    const lastClusterId = useRef<number>();
    const dispatch = useDispatch();

    const createJob = (
        clusterId?: number,
        percent?: number,
        status?: string,
        command?: string
    ) => {
        return new CcJob({
            ...jobData,
            cluster_id: clusterId,
            status: status,
            progress_percent: percent,
            job_spec: {
                ...jobData.job_spec,
                command,
                job_data: {
                    ...jobData.job_spec.job_data,
                    clusterId,
                },
            },
        } as any);
    };

    const setJobs = (
        clusterId?: number,
        percent?: number,
        status?: string,
        command?: string
    ) => {
        lastClusterId.current = clusterId;
        const job = createJob(clusterId, percent, status, command);
        dispatch(
            setRunningJobs(
                Immutable.Map(
                    [job].map((c: CcJob) => [c.getCommandClusterKey(), c])
                )
            )
        );
    };
    const jobProgress = async (command: string, clusterId?: number) => {
        setJobs(clusterId, undefined, 'DEFINED', command);
        await new Promise((resolve) =>
            setTimeout(() => {
                setJobs(clusterId, 35, 'RUNNING', command);
                resolve(undefined);
            }, 5000)
        );
        await new Promise((resolve) =>
            setTimeout(() => {
                setJobs(clusterId, 70, 'RUNNING', command);
                resolve(undefined);
            }, 5000)
        );
        await new Promise((resolve) =>
            setTimeout(() => {
                setJobs(clusterId, 100, 'RUNNING', command);
                resolve(undefined);
            }, 5000)
        );
        await new Promise((resolve) =>
            setTimeout(() => {
                dispatch(setRunningJobs(Immutable.Map([])));
                resolve(undefined);
            }, 5000)
        );
    };

    useServiceMock({
        mockId: 'backup-job-instance',
        methods: [
            ServiceMethod.CREATE_JOB_INSTANCE,
            ServiceMethod.GET_JOB_INSTANCE,
        ],
        mock: [
            async (data: any, opts: any) => {
                // @todo try to use actual here
                const result = await debugModal(
                    {
                        ...data,
                        job: {
                            ...data.job,
                            job_spec: JSON.parse(data.job.job_spec),
                        },
                    },
                    opts,
                    {
                        job: new CcJob({
                            ...data.job,
                            created: new Date().toISOString(),
                        }),
                    }
                );
                const spec = data?.job?.job_spec
                    ? JSON.parse(data.job.job_spec)
                    : {};
                if (['pbmagent', 'pgbackrest'].includes(spec.command)) {
                    jobProgress(spec.command, data?.cluster_id);
                }

                return result;
            },
            async ({ job_id }: any) => {
                if (job_id === jobData.job_id) {
                    return {
                        job: createJob(lastClusterId.current, 100, 'FINISHED'),
                    };
                }
                // @todo try to use actual here
                return {
                    job: getJobInstances.jobs
                        .map((props) => new CcJob(props as any))
                        .find((job) => job.jobId === job_id),
                };
            },
        ],
    });

    const { list: clusters, refresh } = useClusterList();
    useEffect(() => {
        (async () => {
            await refresh();
        })();
    }, []);
    return (
        <Space direction="vertical" size={20}>
            <Space>
                <Divider
                    plain={true}
                    orientation="left"
                    style={{ marginBottom: '0px', marginTop: '15px' }}
                >
                    Create Backup Wizard
                </Divider>
                <BackupCreateWizardButton>Button</BackupCreateWizardButton>
            </Space>

            {clusters?.map((cluster) => (
                <Space
                    key={`backup${cluster.clusterId}-${cluster.clusterType}`}
                >
                    <Divider
                        plain={true}
                        orientation="left"
                        style={{ marginBottom: '0px', marginTop: '15px' }}
                    >
                        <span>
                            Create Backup For{' '}
                            <ClusterTypeFormat
                                type={cluster.clusterType}
                                showIcon={true}
                            />
                        </span>
                    </Divider>
                    <BackupCreateWizardButton
                        cluster={cluster}
                        wizardAction={BackupWizardStepActionType.DEMAND}
                        activeStep={BackupWizardStep.FORM_BACKUP}
                    >
                        Backup {getClusterTypeText(cluster.clusterType)}
                    </BackupCreateWizardButton>
                </Space>
            ))}
            {clusters?.map((cluster) => (
                <Space
                    key={`schedule${cluster.clusterId}-${cluster.clusterType}`}
                >
                    <Divider
                        plain={true}
                        orientation="left"
                        style={{ marginBottom: '0px', marginTop: '15px' }}
                    >
                        <span>
                            Create Backup Schedule For{' '}
                            <ClusterTypeFormat
                                type={cluster.clusterType}
                                showIcon={true}
                            />
                        </span>
                    </Divider>
                    <BackupCreateWizardButton
                        cluster={cluster}
                        wizardAction={BackupWizardStepActionType.SCHEDULE}
                        activeStep={BackupWizardStep.FORM_CREATE_SCHEDULE}
                    >
                        Schedule {getClusterTypeText(cluster.clusterType)}
                    </BackupCreateWizardButton>
                </Space>
            ))}
        </Space>
    );
}
