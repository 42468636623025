import React from 'react';
import { CcTreeItemHandledAclPath } from '../../services/models/CcTreeItem';
import SpaceDescriptions from '../../common/Layout/SpaceDescriptions';
import YesNoFormat from '../../common/Format/YesNoFormat';
import { FormInstance } from 'antd/lib/form';
import { getAclItemDescription, getTreeItemAccessText } from './useTree';
import SpaceWide from '../../common/SpaceWide';
import { Space } from 'antd';

export default PermissionsSummary;

export type PermissionsSummaryProps = {
    form: FormInstance;
};

function PermissionsSummary({ form }: PermissionsSummaryProps) {
    const {
        permissions,
        clusterPermissions,
        permissionLevel,
    } = form.getFieldsValue(true);
    return (
        <SpaceWide direction="vertical">
            {permissions ? (
                <SpaceDescriptions
                    direction="vertical"
                    title="Permissions"
                    size="small"
                    alignItems="right"
                >
                    {Object.keys(permissions).map((key) => (
                        <SpaceDescriptions.Item
                            key={key}
                            labelStrong
                            label={getAclItemDescription(
                                key as CcTreeItemHandledAclPath
                            )}
                        >
                            <YesNoFormat booleanVar={permissions[key]} />
                        </SpaceDescriptions.Item>
                    ))}
                </SpaceDescriptions>
            ) : null}

            {clusterPermissions ? (
                <SpaceDescriptions
                    direction="vertical"
                    title="Cluster permissions"
                    size="small"
                    alignItems="right"
                >
                    <SpaceDescriptions.Item>
                        <Space>
                            {permissionLevel === 'custom' ? (
                                Object.keys(clusterPermissions).map(
                                    (key, index) => (
                                        <span key={key}>
                                            {index !== 0 ? ',' : ''}
                                            {key} (
                                            {getTreeItemAccessText(
                                                clusterPermissions[key]
                                            )}
                                            )
                                        </span>
                                    )
                                )
                            ) : (
                                <span>
                                    All clusters (
                                    {getTreeItemAccessText(permissionLevel)})
                                </span>
                            )}
                        </Space>
                    </SpaceDescriptions.Item>
                </SpaceDescriptions>
            ) : null}
        </SpaceWide>
    );
}
