import useListFetch, { UseListFetchProps } from '../../common/useListFetch';
import CmonCaService from '../../services/cmon/CmonCaService';

export default function useKeyManagement() {
    const { list, loading, refresh, total } = useListFetch({
        name: 'list-certs',
        fetchFn: async ({}, opts) => {
            const response = await CmonCaService.listCerts({}, opts);
            return {
                list: response?.data || [],
                total: response?.total,
            };
        },
        cancelFn: async ({ requestId }) => {
            await CmonCaService.cancelRequest(requestId);
        },
    });

    return {
        list,
        loading,
        refresh,
        total,
    };
}
