import React, { useEffect } from 'react';
import { Button, Divider, Space } from 'antd';
import CloudCredentialsModalAdd from '../components/Integrations/CloudCredentials/CloudCredentialsModalAdd';
import ButtonWithForm from '../common/General/ButtonWithForm';
import CmonCloudService from '../services/cmon/CmonCloudService';
import listCredentials1 from './dataMocks/rpc/cloud/listCredentials1';
import useCloudCredentials from '../components/Integrations/CloudCredentials/useCloudCredentials';
import CloudCredentialsModalEdit from '../components/Integrations/CloudCredentials/CloudCredentialsModalEdit';
import CloudProviderTypeFormat from '../components/Integrations/CloudProviderTypeFormat';
import { debugModal } from './serviceMock/ServiceMockContext';

export default DebugCloudCredentialsModal;

export type DebugCloudCredentialsModalProps = {};

function DebugCloudCredentialsModal({}: DebugCloudCredentialsModalProps) {
    CmonCloudService.addCredentials = ((data: any, opts: any) => {
        return debugModal(data, opts, {});
    }) as any;
    CmonCloudService.updateCredentials = ((data: any, opts: any) => {
        return debugModal(data, opts, {});
    }) as any;
    CmonCloudService.listCredentials = () => Promise.resolve(listCredentials1);
    const { list, refresh } = useCloudCredentials();
    useEffect(() => {
        refresh();
    }, []);
    return (
        <Space direction="vertical" size={20}>
            <Space>
                <Divider
                    plain={true}
                    orientation="left"
                    style={{ marginBottom: '0px', marginTop: '15px' }}
                >
                    Add Credentials Wizard
                </Divider>
                <ButtonWithForm
                    button={<Button>Button</Button>}
                    form={<CloudCredentialsModalAdd />}
                />
            </Space>
            {list?.map((item) => (
                <Space key={item.id}>
                    <Divider
                        plain={true}
                        orientation="left"
                        style={{ marginBottom: '0px', marginTop: '15px' }}
                    >
                        Edit{' '}
                        <CloudProviderTypeFormat provider={item.provider}>
                            {item.name}
                        </CloudProviderTypeFormat>
                    </Divider>
                    <ButtonWithForm
                        button={<Button>Button</Button>}
                        form={<CloudCredentialsModalEdit credentials={item} />}
                    />
                </Space>
            ))}
        </Space>
    );
}
