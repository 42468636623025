import PostgreSqlConfigurator from '../PostgreSql/PostgreSqlConfigurator';

export default class TimescaleDbConfigurator extends PostgreSqlConfigurator {
    public static getVendors() {
        return [
            {
                name: 'TimescaleDB',
                value: 'default',
                versions: [
                    { name: '15', value: '15' },
                    { name: '14', value: '14' },
                    { name: '13', value: '13' },
                    { name: '12', value: '12' },
                    { name: '11', value: '11' },
                ],
            },
        ];
    }
}
