import React from 'react';
import ButtonWithForm from '../../../../common/General/ButtonWithForm';
import ActionButton from '@severalnines/bar-frontend-components/build/lib/General/ActionButton';
import CcCluster from '../../../../services/models/CcCluster';
import AddRpcForm from './AddRpcForm';

export default AddRpcButton;

export type AddRpcButtonProps = {
    cluster: CcCluster;
};

function AddRpcButton({ cluster }: AddRpcButtonProps) {
    return (
        <ButtonWithForm
            button={<ActionButton>Change RPC API token</ActionButton>}
            form={<AddRpcForm cluster={cluster} />}
        />
    );
}
