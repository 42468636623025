import React, { useContext, useEffect, useRef, useState } from 'react';
import CcCluster from '../../services/models/CcCluster';
import MonitorToolbar, { MonitorToolbarApi } from '../Monitor/MonitorToolbar';
import { ResponsiveContext } from '@severalnines/bar-frontend-components/build/lib/Layout/Responsive';
import useQMTopQueriesList from './useQMTopQueriesList';
import TypographyText from '../../common/TypographyText';
import AppTable from '../../common/DataDisplay/AppTable';
import SpaceWide from '../../common/SpaceWide';
import AppDateFormat from '../../common/AppDateFormat';
import { formatNumber } from '@severalnines/bar-frontend-components/build/lib/Format/NumberFormat';
import QueryMonitorPurgeButton from './QueryMonitorPurgeButton';
import QueryMonitorEnabledWrapper from './QueryMonitorEnabledWrapper';
import QueryMonitorNodeSelect from './QueryMonitorNodeSelect';
import useTableFilter from '../../common/hooks/useTableFilter';
import { StringParam } from 'use-query-params';
import useTableFilterColumns from '../../common/hooks/useTableFilterColumns';
import { formatDuration } from '@severalnines/bar-frontend-components/build/lib/Format/DurationFormat';
import { microToSec } from '../../common/dateTime';
export default TopQueriesPage;

export type TopQueriesPageProps = {
    cluster: CcCluster;
};

function TopQueriesPage({ cluster }: TopQueriesPageProps) {
    const { responsive } = useContext(ResponsiveContext);
    const monitorToolbarRef = useRef<MonitorToolbarApi>(null);

    const {
        filterParams,
        handleTableChange: onTableChange,
        addFilterParams,
    } = useTableFilter({
        params: {
            host: StringParam,
        },
        defaultParams: {
            sort: 'cnt',
            order: 'descend',
        },
    });
    const [selectedHostname, setSelectedHostname] = useState<
        string | undefined
    >(filterParams.host || 'all');

    const {
        refresh,
        list,
        loading,
        page,
        pageSize,
        total,
        totalExecTime,
    } = useQMTopQueriesList({
        clusterId: cluster.clusterId!,
    });

    const handleNodeChange = async (value: string) => {
        monitorToolbarRef.current?.resetTimeout();
        setSelectedHostname(value);
        if (value === 'all') {
            addFilterParams({
                host: null,
                page: 1,
            });
        } else {
            addFilterParams({ host: value, page: 1 });
        }
    };

    const handleRefresh = (triggerClick?: boolean) => {
        refresh({ showLoading: !!triggerClick, page });
    };

    useEffect(() => {
        const { host, ...restFilter } = filterParams;
        const [hostId, hostname, port] = (host || '')?.split(':');
        (async () => {
            await refresh({
                ...restFilter,
                hostId: Number.parseInt(hostId),
                hostname,
                port: Number.parseInt(port),
            });
        })();
    }, [filterParams]);

    const { columns } = useTableFilterColumns({
        columns: [
            {
                title: 'Query',
                key: 'query',
                render: (record: any) => (
                    <TypographyText
                        ellipsis={{ tooltip: record.canonical }}
                        style={{ width: '250px' }}
                    >
                        {record.canonical}
                    </TypographyText>
                ),
            },
            {
                title: 'DB',
                key: 'db',
                render: (record: any) => record.db,
            },
            {
                title: 'Count',
                key: 'cnt',
                render: (record: any) => record.count,
                align: 'right',
                sorter: true,
                // do not allow disabling sorting
                sortDirections: ['ascend', 'descend', 'ascend'],
            },
            {
                title: 'Rows',
                children: [
                    {
                        title: 'Sent',
                        key: 'rows_sent',
                        render: (record: any) => <>{record.rows_sent}</>,
                        align: 'right',
                    },
                    {
                        title: 'Examined',
                        key: 'rows_examined',
                        render: (record: any) => <>{record.rows_examined}</>,
                        align: 'right',
                    },
                ],
            },
            {
                title: 'Tmp tables',
                children: [
                    {
                        title: 'RAM',
                        key: 'ram',
                        render: (record: any) => (
                            <>{record.sum_created_tmp_tables || 0}</>
                        ),
                        align: 'right',
                    },
                    {
                        title: 'On disk',
                        key: 'ondisk',
                        render: (record: any) => (
                            <>{record.sum_created_tmp_disk_tables || 0}</>
                        ),
                        align: 'right',
                    },
                ],
            },
            {
                title: 'Exec time',
                children: [
                    {
                        title: 'Max',
                        key: 'time',
                        render: (record: any) =>
                            formatDuration(
                                formatNumber(
                                    microToSec(record.max_query_time),
                                    1
                                ),
                                {
                                    round: false,
                                    short: true,
                                    seconds: true,
                                }
                            ),
                        align: 'right',
                        sorter: true,
                    },
                    {
                        title: 'Avg',
                        key: 'avg_query_time',
                        render: (record: any) =>
                            formatDuration(
                                formatNumber(
                                    microToSec(record.avg_query_time),
                                    1
                                ),
                                {
                                    round: false,
                                    short: true,
                                    seconds: true,
                                }
                            ),

                        align: 'right',
                        sorter: true,
                    },
                    {
                        title: 'Stddev',
                        key: 'stdev',
                        render: (record: any) =>
                            formatDuration(
                                formatNumber(microToSec(record.stddev), 1),
                                {
                                    round: false,
                                    short: true,
                                    seconds: true,
                                }
                            ),
                        align: 'right',
                        sorter: true,
                    },
                ],
            },
            {
                title: 'Total exec time',
                children: [
                    {
                        title: 'Absolute',
                        key: 'total_time',
                        render: (record: any) => (
                            <>
                                {topQueriesTotalTimeAbsoluteFilter(
                                    record.sum_query_time
                                )}
                            </>
                        ),
                        align: 'right',
                        sorter: true,
                    },
                    {
                        title: 'Relative %',
                        key: 'avg_exec_time',
                        render: (record: any) => (
                            <>
                                {topQueriesTotalTimeRelativeFilter(
                                    record.sum_query_time,
                                    totalExecTime
                                )}
                            </>
                        ),
                        align: 'right',
                    },
                ],
            },
            {
                title: 'Last seen',
                key: 'last_seen',
                render: (record: any) => (
                    <AppDateFormat fromNow={true}>
                        {new Date(record.last_seen * 1000)}
                    </AppDateFormat>
                ),
                align: 'right',
            },
        ],
        filterParams,
    });
    const handleTableChange = (pagination: any, filter: any, sorters: any) => {
        onTableChange(pagination, filter, sorters);
        // need to reset timeout in another tick so page is updated
        setTimeout(() => {
            monitorToolbarRef.current?.resetTimeout();
        });
    };

    const rowKey = (record: any) => `${record.hostid}-${record.canonical}`;
    return (
        <QueryMonitorEnabledWrapper loading={loading} cluster={cluster}>
            <SpaceWide
                direction="vertical"
                size={24}
                className="TopQueriesPage"
            >
                <MonitorToolbar
                    ref={monitorToolbarRef}
                    enableTimerange={false}
                    onRefresh={handleRefresh}
                    selectors={[
                        <QueryMonitorNodeSelect
                            key="query-mointor-node-select"
                            cluster={cluster}
                            onChange={handleNodeChange}
                            value={selectedHostname}
                        />,
                    ]}
                    extra={
                        <SpaceWide justify="right" size={12}>
                            <QueryMonitorPurgeButton cluster={cluster} />
                        </SpaceWide>
                    }
                />

                <AppTable
                    className="QueryMonitorOverview"
                    rowKey={rowKey}
                    dataSource={list}
                    columns={columns}
                    pagination={{ current: page, pageSize, total }}
                    size="middle"
                    responsive={responsive}
                    onChange={handleTableChange}
                />
            </SpaceWide>
        </QueryMonitorEnabledWrapper>
    );
}

export function topQueriesTotalTimeAbsoluteFilter(value: number) {
    value = value * 1;
    value = value / 1000 / 1000;

    const hours = Math.floor(value / 3600);
    const minutes = Math.floor((value - hours * 3600) / 60);
    const seconds = Math.floor(value - hours * 3600 - minutes * 60);

    const hoursStr = hours < 10 ? `0${hours}` : hours;
    const minutesStr = minutes < 10 ? `0${minutes}` : minutes;
    const secondsStr = seconds < 10 ? `0${seconds}` : seconds;

    return `${hoursStr}:${minutesStr}:${secondsStr}`;
}

function topQueriesTotalTimeRelativeFilter(queryTime: number, totalTime = 1) {
    return formatNumber((queryTime * 100) / Math.max(totalTime, 1), 2);
}
