const result = {
    result: {
        aws: [
            {
                name: 'aws credentials',
                comment: '',
                id: 11,
                credentials: {
                    access_key_id: 'AAAAAAAAAAAAAAAAAAAAAAAA',
                    access_key_region: 'ap-southeast-1',
                    access_key_secret: '888888888888888888888888',
                },
            },
        ],
        az: [
            {
                name: 'azure credentials',
                comment: '',
                id: 14,
                credentials: {
                    application_id: '7f649053-xxxx-xxxx-xxxx-2179c1fa83b8',
                    client_secret: 'xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx',
                    tenant_id: 'ce6b8358-xxxx-xxxx-xxxx-49b8c7a5cbc2',
                    subscription_id: '6fafe95c-xxxx-xxxx-xxxx-1c33daa1c2c3',
                    resource_group: 'cc',
                    storage_account: 'mybackupazure',
                },
            },
        ],
        gce: [
            {
                name: 'google cloud credentials',
                comment: '',
                id: 12,
                credentials: {
                    auth_provider_x509_cert_url:
                        'https://www.googleapis.com/oauth2/v1/certs',
                    auth_uri: 'https://accounts.google.com/o/oauth2/auth',
                    client_email: 'test@iam.gserviceaccount.com',
                    client_id: '1111111111111111111',
                    client_x509_cert_url:
                        'https://www.googleapis.com/robot/v1/metadata/x509/test.iam.gserviceaccount.com',
                    private_key:
                        '-----BEGIN PRIVATE KEY-----\nMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM\nMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM\nMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM\nMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM\nMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM\nMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM\nMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM\nMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM\nMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM\nMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM\nMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM\nMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM\nMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM\nMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM\nMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM\nMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM\nMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM\nMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM\n-----END PRIVATE KEY-----\n',
                    private_key_id: '3333333333333333333333333333333333333333',
                    project_id: 'testing',
                    token_uri: 'https://oauth2.googleapis.com/token',
                    type: 'service_account',
                },
            },
        ],
        s3: [
            {
                name: 's3 credentials',
                comment: '',
                id: 5,
                credentials: {
                    access_key_id: 'aaaaaaaaaaaaaaaaa',
                    access_key_region: 'aaaaaaaaaaaaaaa',
                    access_key_secret: 'aaaaaaaaaaaaaa',
                    endpoint: 'asd',
                    use_ssl: true,
                },
            },
        ],
    },
};

export default result;
