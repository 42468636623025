import React, { useEffect, useState } from 'react';
import ModalDefaultForm from '../../../../common/ModalDefaultForm';
import AddNodeWizard, {
    AddNodeJobDataDTO,
    AddNodeWizardAction,
    AddNodeWizardStep,
} from './AddNodeWizard';
import { FormInstance } from 'antd/lib/form';
import { CmonJobInstanceCommand } from '../../../../services/cmon/models/CmonJobInstance';
import { AddNodeFormWizardFields } from './AddNodeFormWizard';
import useCreateJob from '../../../Jobs/useCreateJob';
import CcCluster from '../../../../services/models/CcCluster';

export default AddNodeModal;

export type AddNodeModalProps = {
    cluster: CcCluster;
    form: FormInstance;
    titleNew: string;
    titleImport: string;
    commandNew: CmonJobInstanceCommand;
    commandImport: CmonJobInstanceCommand;
    formCreateWizard: React.ReactElement;
    formImportWizard: React.ReactElement;
    defaultWizardStep?: AddNodeWizardStep;
    onCancel?: () => void;
    onSuccess?: () => void;
    onCommandChange?: (command: CmonJobInstanceCommand) => void;
    onSubmit?: (
        fields: AddNodeFormWizardFields,
        action: AddNodeWizardAction
    ) => AddNodeJobDataDTO;
};

function AddNodeModal({
    cluster,
    form,
    titleNew,
    titleImport,
    formCreateWizard,
    formImportWizard,
    defaultWizardStep,
    commandNew,
    commandImport,
    onCancel,
    onSuccess,
    onCommandChange,
    onSubmit,
}: AddNodeModalProps) {
    const [title, setTitle] = useState<string>();
    const [command, setCommand] = useState<CmonJobInstanceCommand>(commandNew);
    const { loading, send } = useCreateJob({
        clusterId: cluster.clusterId,
        title: title || 'Add node',
        command: command,
        onSuccess,
    });

    const handleStepChange = (
        step: AddNodeWizardStep,
        action: AddNodeWizardAction | undefined
    ) => {
        if (step === AddNodeWizardStep.TYPE) {
            setTitle('');
        } else {
            setTitle(
                action === AddNodeWizardAction.NEW ? titleNew : titleImport
            );
        }
        setCommand(
            action === AddNodeWizardAction.NEW ? commandNew : commandImport
        );
    };

    const handleCancel = () => {
        onCancel?.();
    };

    const handleSubmit = async (
        fields: AddNodeFormWizardFields,
        action: AddNodeWizardAction
    ) => {
        onSubmit && (await send(onSubmit(fields, action)));
    };

    useEffect(() => {
        onCommandChange?.(command);
    }, [command]);

    return (
        <ModalDefaultForm
            title={title}
            form={form}
            footer={[]}
            bodyStyle={{ padding: 0 }}
            onCancel={handleCancel}
            confirmClose={true}
            loading={loading}
            defaultVisible={true}
        >
            <AddNodeWizard
                cluster={cluster}
                titleNew={titleNew}
                titleImport={titleImport}
                onSubmit={handleSubmit}
                activeStep={defaultWizardStep}
                formCreateWizard={formCreateWizard}
                formImportWizard={formImportWizard}
                onStepChange={handleStepChange}
            />
        </ModalDefaultForm>
    );
}
