import React, { useState } from 'react';
import PrometheusEnableWizard, {
    getPrometheusExporterTypes,
    PrometheusFormValues,
} from './PrometheusEnableWizard';
import ModalDefaultForm from '../../common/ModalDefaultForm';
import useForm from 'antd/lib/form/hooks/useForm';
import CcCluster from '../../services/models/CcCluster';
import CmonJobsService from '../../services/cmon/CmonJobsService';
import {
    notifyError,
    notifyOperationSuccess,
    NotifyType,
} from '../Notifications/uiNotification';
import { addNewRunningJob } from '../../appReducer';
import { useDispatch } from 'react-redux';

export default PrometheusEnableModal;

export type PrometheusEnableModalProps = {
    cluster: CcCluster;
    defaultInitialValues?: PrometheusFormValues;
    saveMode?: boolean;
    onCancel?: () => void;
    onSuccess?: () => void;
};

function PrometheusEnableModal({
    cluster,
    defaultInitialValues,
    saveMode = false,
    onCancel,
    onSuccess,
}: PrometheusEnableModalProps) {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState<boolean>(false);
    const [confirmClose, setConfirmClose] = useState<boolean>(false);

    const [form] = useForm<PrometheusFormValues>();

    const handleCancel = () => {
        onCancel?.();
    };

    const handleSubmit = async () => {
        const formValues = form.getFieldsValue(true);
        const exporters = getPrometheusExporterTypes(cluster);
        if (cluster.clusterId) {
            try {
                setLoading(true);
                const response = await CmonJobsService.createDeployAgentsJobInstance(
                    cluster.clusterId,
                    {
                        job_data: {
                            clusterId: cluster.clusterId,
                            node: {
                                data_retention: `${
                                    formValues.dataRetention || 0
                                }d`,
                                data_retention_size:
                                    formValues.dataRetentionSize || 0,
                                datadir: formValues.dataDirectory,
                                hostname: formValues.host,
                                scrape_interval: `${
                                    formValues.scrapeInterval || 0
                                }s`,
                                configuration: exporters
                                    .filter(({ type }) => !!formValues[type])
                                    .map(({ type }) => ({
                                        job: type,
                                        scrape_interval: `${
                                            formValues[type].scrapeInterval || 0
                                        }s`,
                                        arguments:
                                            formValues[type].arguments?.join(
                                                ' '
                                            ) || '',
                                    })),
                            },
                        },
                    },
                    {
                        job: {
                            title: 'Enable agent-based monitoring',
                        },
                    }
                );
                notifyOperationSuccess({
                    type: NotifyType.TOAST,
                    title: 'Agent-based monitoring enabling job has started',
                    content: '',
                });
                setLoading(false);
                if (response) {
                    const { job } = response;
                    dispatch(addNewRunningJob(job));
                }
                onSuccess?.();
            } catch (e: any) {
                setLoading(false);
                notifyError({
                    content: e.message,
                });
            }
        }
    };

    const handleTouchedChange = (touched: boolean) => {
        setConfirmClose(touched);
    };

    return (
        <ModalDefaultForm
            title="Enable Agent-Based Monitoring"
            form={form}
            footer={[]}
            onCancel={handleCancel}
            confirmClose={confirmClose}
            bodyStyle={{}}
            defaultVisible={true}
        >
            <PrometheusEnableWizard
                form={form}
                cluster={cluster}
                defaultInitialValues={defaultInitialValues}
                saveMode={saveMode}
                loading={loading}
                onSubmit={handleSubmit}
                onCancel={handleCancel}
                onTouchedChange={handleTouchedChange}
            />
        </ModalDefaultForm>
    );
}
