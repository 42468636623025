import React from 'react';
import { Form, Space } from 'antd';
import InfoIcon from '@severalnines/bar-frontend-components/build/lib/General/InfoIcon';
import { FileTextOutlined, InfoCircleOutlined } from '@ant-design/icons';
import RepositoryInput, {
    getRepositoryItemInfo,
} from '../../DataEntry/RepositoryInput';
import { FormInstance, FormItemProps } from 'antd/lib/form';
import { getObjectValueByPath } from '../../objectHelpers';

export default VendorRepositoryField;

export type VendorRepositoryFieldProps = Omit<FormItemProps, 'name'> & {
    name?: string | string[];
    form: FormInstance;
};

function VendorRepositoryField({
    form,
    name = 'repository',
    ...rest
}: VendorRepositoryFieldProps) {
    return (
        <div className="VendorRepositoryField">
            <Form.Item
                shouldUpdate={(prev, current) =>
                    getObjectValueByPath(
                        Array.isArray(name) ? name : [name],
                        prev
                    ) !==
                    getObjectValueByPath(
                        Array.isArray(name) ? name : [name],
                        current
                    )
                }
                noStyle={true}
                {...rest}
            >
                {() => (
                    <Form.Item
                        name={name}
                        label={
                            <Space>
                                Repository
                                <InfoIcon info="The repository." />
                            </Space>
                        }
                        rules={[
                            {
                                required: true,
                                message: 'Please select the repository.',
                            },
                        ]}
                        extra={
                            <small>
                                <InfoCircleOutlined />{' '}
                                {getRepositoryItemInfo(
                                    form.getFieldValue(name)
                                )}
                                <br />
                                <a
                                    href="https://docs.severalnines.com/docs/clustercontrol/user-guide-gui/dashboard/global-settings/repositories/"
                                    target="_blank"
                                    title="Repositories help page"
                                    rel="noreferrer"
                                >
                                    <FileTextOutlined /> Learn more
                                </a>{' '}
                                about vendor repositories.
                            </small>
                        }
                    >
                        <RepositoryInput />
                    </Form.Item>
                )}
            </Form.Item>
        </div>
    );
}
