import React, { useState } from 'react';
import { FetchRefreshFunctionParams } from '../../common/useFetch';
import CcCluster from '../../services/models/CcCluster';
import { Col, Form, Select } from 'antd';
import { EMAIL_LIMIT_OPTIONS } from './emailNotificationsHelper';
import {
    NotifyType,
    notifyError,
    notifyOperationSuccess,
} from '../Notifications/uiNotification';
import CmonConfigService from '../../services/cmon/CmonConfigService';

export default EmailNotificationDailyLimit;
export type EmailNotificationDailyLimitProps = {
    cluster: CcCluster;
    notifications: any;
    onSuccess: (params: FetchRefreshFunctionParams) => Promise<any>;
    emailAddress: string;
    disable: boolean;
};
function EmailNotificationDailyLimit({
    cluster,
    notifications,
    onSuccess,
    emailAddress,
    disable,
}: EmailNotificationDailyLimitProps) {
    const [value, setValue] = useState<number | undefined>();

    const onChangeHandler = async (value: number) => {
        try {
            await CmonConfigService.setnotificationsettings({
                ...notifications,
                cluster_id: cluster.clusterId,
                daily_limit: value,
                email: emailAddress,
            });
            await onSuccess({});
            notifyOperationSuccess({
                type: NotifyType.TOAST,
                title: `Changes saved successfully!`,
                cancelText: 'Close',
            });
            setValue(value);
        } catch (err) {
            notifyError({ type: NotifyType.TOAST, content: err.message });
        }
    };
    return (
        <Col span={8}>
            <Form.Item name="emailLimit" label="Daily email limit">
                <Select
                    onChange={onChangeHandler}
                    value={value}
                    placeholder="Click here to select"
                    options={EMAIL_LIMIT_OPTIONS}
                    disabled={disable}
                />
            </Form.Item>
        </Col>
    );
}
