import React, { useEffect, useState } from 'react';
// import {
//     // TimelineChartData,
//     TimelineChartDataRow,
// } from '../common/tmp/DataDisplay/TimelineChart';
import moment from 'moment';
import { stringify } from 'query-string';
import MonitorBox from '../components/Monitor/MonitorBox';
import { MonitorValueFormatType } from '../components/Monitor/MonitorValueFormat';
import { Col, Row } from 'antd';
import { MonitorDatasource } from '../components/Monitor/Monitor';
import CcCluster, { CcClusterProps } from '../services/models/CcCluster';

export default DebugSandbox;

export type DebugSandboxProps = {};

//
// const generateFakeData = (
//     series: number,
//     from: number,
//     to?: number,
//     interval?: number,
//     last?: number[]
// ) => {
//     return Array.from(Array(series).keys()).map((s, sIdx) => {
//         const dataRow: TimelineChartDataRow = [];
//         let lastValue: number =
//             (last && last[sIdx]) ||
//             Math.floor(Math.random() * (100 - 0 + 1) + 0);
//         for (
//             let i = from + (interval || 60);
//             i < (to || moment().unix());
//             i += interval || 60
//         ) {
//             dataRow.push([i, lastValue]);
//             lastValue +=
//                 (Math.random() < 0.5 ? -1 : 1) *
//                 Math.floor(Math.random() * (2 - 0 + 1) + 0);
//         }
//         return dataRow;
//     });
// };

function DebugSandbox() {
    // const [lastTs, setLastTs] = useState<number>(
    //     Math.floor(moment().subtract(17, 'minutes').unix())
    // );

    const [startTs, setStartTs] = useState<number>(
        moment().subtract(30, 'minutes').unix()
    );
    const [endTs, setEndTs] = useState<number>(
        moment().subtract(1, 'minutes').unix()
    );

    // const [lastValue, setLastValue] = useState<number[] | undefined>();
    //
    // const [data, setData] = useState<TimelineChartData | null>(null);
    // const [dataUpdate, setDataUpdate] = useState<TimelineChartData | null>(
    //     null
    // );

    useEffect(() => {
        const timer = setTimeout(() => {
            setStartTs(moment().subtract(30, 'minutes').unix());
            setEndTs(moment().subtract(1, 'minutes').unix());
        }, 10000);
        return () => {
            clearTimeout(timer);
        };
    }, [endTs]);

    // useEffect(() => {
    //     const d = generateFakeData(2, startTs, endTs, 10);
    //     setData(d);
    //     setLastTs(d[0][d[0].length - 1][0]);
    //     setLastValue(d.map((dr) => dr[dr.length - 1][1]));
    // }, []);
    //
    // useEffect(() => {
    //     const timer = setTimeout(() => {
    //         if (data) {
    //             const d = generateFakeData(
    //                 2,
    //                 lastTs,
    //                 moment().subtract(30, 'seconds').unix(),
    //                 10,
    //                 lastValue
    //             );
    //             if (d && d.length) {
    //                 setDataUpdate(d);
    //                 if (d[0] && d[0].length) {
    //                     setLastTs(d[0][d[0].length - 1][0]);
    //                     setLastValue(d.map((dr) => dr[dr.length - 1][1]));
    //                 }
    //             }
    //         }
    //     }, 1000);
    //     return () => {
    //         clearTimeout(timer);
    //     };
    // }, [dataUpdate, data]);
    //txBytes,rxBytes,rxDrops,rxErrors,txDrops,txErrors,interface,hostid
    return (
        <Row gutter={[24, 24]}>
            <Col span={24}>
                <Row gutter={20}>
                    <Col span={12}>
                        <MonitorBox
                            datasource={MonitorDatasource.PROMETHEUS}
                            cluster={
                                new CcCluster({
                                    clusterId: 1,
                                } as CcClusterProps)
                            }
                            title="Load Average"
                            startTs={startTs}
                            endTs={endTs}
                            options={{
                                type: 'line',
                                decimals: 2,
                                yaxis: [
                                    {
                                        min: 0,
                                    },
                                ],
                            }}
                            targets={[
                                {
                                    expr:
                                        'node_load1{instance=~"143.198.228.20(:[0-9]+)?|127.0.0.1(:[0-9]+)?"}',
                                    legendFormat: 'LoadAvg 1m',
                                },
                                {
                                    expr:
                                        'node_load5{instance=~"143.198.228.20(:[0-9]+)?|127.0.0.1(:[0-9]+)?"}',
                                    legendFormat: 'LoadAvg 5m',
                                },
                                {
                                    expr:
                                        'node_load15{instance=~"143.198.228.20(:[0-9]+)?|127.0.0.1(:[0-9]+)?"}',
                                    legendFormat: 'LoadAvg 15m',
                                },
                            ]}
                            vars={[]}
                        />
                    </Col>
                    {/*<Col span={12}>*/}
                    {/*    <MonitorBox*/}
                    {/*        datasource={MonitorDatasource.PROMETHEUS}*/}
                    {/*        clusterId={1}*/}
                    {/*        title="CPU Usage"*/}
                    {/*        startTs={startTs}*/}
                    {/*        endTs={endTs}*/}
                    {/*        options={{*/}
                    {/*            stack: true,*/}
                    {/*            type: 'area',*/}
                    {/*            format: 'percent',*/}
                    {/*            yAxis: {*/}
                    {/*                min: 0,*/}
                    {/*                max: 100,*/}
                    {/*            },*/}
                    {/*        }}*/}
                    {/*        targets={[*/}
                    {/*            {*/}
                    {/*                expr:*/}
                    {/*                    '100-100*sum((avg by (mode, instance) (irate(node_cpu_seconds_total{instance=~"143.198.228.20(:[0-9]+)?|127.0.0.1(:[0-9]+)?",mode!="idle"}[5m]))))',*/}
                    {/*                legendFormat: 'Idle',*/}
                    {/*            },*/}
                    {/*            {*/}
                    {/*                expr:*/}
                    {/*                    '100*(avg by (mode, instance) (irate(node_cpu_seconds_total{instance=~"143.198.228.20(:[0-9]+)?|127.0.0.1(:[0-9]+)?",mode="user"}[5m])))',*/}
                    {/*                legendFormat: 'User',*/}
                    {/*            },*/}
                    {/*            {*/}
                    {/*                expr:*/}
                    {/*                    '100*(avg by (mode, instance) (irate(node_cpu_seconds_total{instance=~"143.198.228.20(:[0-9]+)?|127.0.0.1(:[0-9]+)?",mode="system"}[5m])))',*/}
                    {/*                legendFormat: 'System',*/}
                    {/*            },*/}
                    {/*            {*/}
                    {/*                expr:*/}
                    {/*                    '100*(avg by (mode, instance) (irate(node_cpu_seconds_total{instance=~"143.198.228.20(:[0-9]+)?|127.0.0.1(:[0-9]+)?",mode="iowait"}[5m])))',*/}
                    {/*                legendFormat: 'Iowait',*/}
                    {/*            },*/}
                    {/*            {*/}
                    {/*                expr:*/}
                    {/*                    '100*(avg by (mode, instance) (irate(node_cpu_seconds_total{instance=~"143.198.228.20(:[0-9]+)?|127.0.0.1(:[0-9]+)?",mode="softirq"}[5m])))',*/}
                    {/*                legendFormat: 'SoftIrq',*/}
                    {/*            },*/}
                    {/*            {*/}
                    {/*                expr:*/}
                    {/*                    '100*(avg by (mode, instance) (irate(node_cpu_seconds_total{instance=~"143.198.228.20(:[0-9]+)?|127.0.0.1(:[0-9]+)?",mode="irq"}[5m])))',*/}
                    {/*                legendFormat: 'Irq',*/}
                    {/*            },*/}
                    {/*            {*/}
                    {/*                expr:*/}
                    {/*                    '100*(avg by (mode, instance) (irate(node_cpu_seconds_total{instance=~"143.198.228.20(:[0-9]+)?|127.0.0.1(:[0-9]+)?",mode="steal"}[5m])))',*/}
                    {/*                legendFormat: 'Steal',*/}
                    {/*            },*/}
                    {/*            {*/}
                    {/*                expr:*/}
                    {/*                    '100*(avg by (mode, instance) (irate(node_cpu_seconds_total{instance=~"143.198.228.20(:[0-9]+)?|127.0.0.1(:[0-9]+)?",mode="nice"}[5m])))',*/}
                    {/*                legendFormat: 'Nice',*/}
                    {/*            },*/}
                    {/*        ]}*/}
                    {/*    />*/}
                    {/*</Col>*/}
                </Row>
            </Col>
            {/*<Col span={24}>*/}
            {/*    <Row gutter={20}>*/}
            {/*        <Col span={12}>*/}
            {/*            <MonitorBox*/}
            {/*                datasource={MonitorDatasource.PROMETHEUS}*/}
            {/*                clusterId={1}*/}
            {/*                title="RAM Usage"*/}
            {/*                startTs={startTs}*/}
            {/*                endTs={endTs}*/}
            {/*                options={{*/}
            {/*                    type: 'area',*/}
            {/*                    format: 'size',*/}
            {/*                    decimals: 2,*/}
            {/*                    yAxis: {*/}
            {/*                        min: 0,*/}
            {/*                    },*/}
            {/*                }}*/}
            {/*                targets={[*/}
            {/*                    {*/}
            {/*                        expr:*/}
            {/*                            'node_memory_MemTotal_bytes{instance=~"143.198.228.20(:[0-9]+)?|127.0.0.1(:[0-9]+)?"}',*/}
            {/*                        legendFormat: 'Total',*/}
            {/*                        color: '#4363d8',*/}
            {/*                    },*/}
            {/*                    {*/}
            {/*                        expr:*/}
            {/*                            'node_memory_MemAvailable_bytes{instance=~"143.198.228.20(:[0-9]+)?|127.0.0.1(:[0-9]+)?"}',*/}
            {/*                        legendFormat: 'Available (for apps)',*/}
            {/*                        color: '#3cb44b',*/}
            {/*                    },*/}
            {/*                    {*/}
            {/*                        expr:*/}
            {/*                            'node_memory_MemFree_bytes{instance=~"143.198.228.20(:[0-9]+)?|127.0.0.1(:[0-9]+)?"}',*/}
            {/*                        legendFormat: 'Free',*/}
            {/*                        color: '#911eb4',*/}
            {/*                    },*/}
            {/*                ]}*/}
            {/*            />*/}
            {/*        </Col>*/}
            {/*        <Col span={12}>*/}
            {/*            <MonitorBox*/}
            {/*                datasource={MonitorDatasource.PROMETHEUS}*/}
            {/*                clusterId={1}*/}
            {/*                title="Disk Space Usage"*/}
            {/*                startTs={startTs}*/}
            {/*                endTs={endTs}*/}
            {/*                options={{*/}
            {/*                    stack: true,*/}
            {/*                    type: 'area',*/}
            {/*                    format: 'size',*/}
            {/*                    yAxis: {*/}
            {/*                        min: 0,*/}
            {/*                    },*/}
            {/*                }}*/}
            {/*                targets={[*/}
            {/*                    {*/}
            {/*                        expr:*/}
            {/*                            'node_filesystem_avail_bytes{instance=~"143.198.228.20(:[0-9]+)?|127.0.0.1(:[0-9]+)?",fstype=~"ext4|xfs"}',*/}
            {/*                        legendFormat: 'Free: {{ device }}',*/}
            {/*                    },*/}
            {/*                    {*/}
            {/*                        expr:*/}
            {/*                            'node_filesystem_size_bytes{instance=~"143.198.228.20(:[0-9]+)?|127.0.0.1(:[0-9]+)?",fstype=~"ext4|xfs"}- node_filesystem_avail_bytes{instance=~"143.198.228.20(:[0-9]+)?|127.0.0.1(:[0-9]+)?",fstype=~"ext4|xfs"} ',*/}
            {/*                        legendFormat: 'Used: {{ device }}',*/}
            {/*                    },*/}
            {/*                ]}*/}
            {/*            />*/}
            {/*        </Col>*/}
            {/*    </Row>*/}
            {/*</Col>*/}
            {/*<Divider />*/}
            <Col span={24}>
                <Row gutter={20}>
                    <Col span={12}>
                        <MonitorBox
                            datasource={MonitorDatasource.CLUSTERCONTROL}
                            cluster={
                                new CcCluster({
                                    clusterId: 1,
                                } as CcClusterProps)
                            }
                            title="CPU Usage"
                            startTs={startTs}
                            endTs={endTs}
                            options={{
                                stack: true,
                                format: MonitorValueFormatType.PERCENTAGE,
                                type: 'area',
                                decimals: 2,
                            }}
                            // [[$.[created, idle, "Idle"]]](calculate_per_sec=true&compact_format=false&name=cpustat)
                            // filters={filters}
                            targets={[
                                {
                                    expr: `[[$.[created, idle, "Idle"]],[$.[created,user, "User"]],[$.[created,sys, "System"]],[$.[created,irq, "IRQ"]],[$.[created,steal, "Steal"]]](${stringify(
                                        {
                                            calculate_per_sec: false,
                                            compact_format: false,
                                            fields:
                                                'idle,user,sys,iowait,irq,steal,cpuid,hostid,created',
                                            hostid: 8,
                                            name: 'cpustat',
                                            stat_info: false,
                                        }
                                    )})`,
                                },
                            ]}
                            vars={[]}
                        />
                    </Col>
                    {/*<Col span={12}>*/}
                    {/*    <MonitorBox*/}
                    {/*        datasource={MonitorDatasource.CLUSTERCONTROL}*/}
                    {/*        clusterId={1}*/}
                    {/*        title="Network Usage"*/}
                    {/*        startTs={startTs}*/}
                    {/*        endTs={endTs}*/}
                    {/*        options={{*/}
                    {/*            type: 'line',*/}
                    {/*            format: 'sizeps',*/}
                    {/*        }}*/}
                    {/*        filters={[(d) => d.interface === 'eth0']}*/}
                    {/*        targets={[*/}
                    {/*            {*/}
                    {/*                expr: `[[$.[created, txBytes, "Sent"]], [$.[created, rxBytes, "Received"]]](${stringify(*/}
                    {/*                    {*/}
                    {/*                        calculate_per_sec: false,*/}
                    {/*                        compact_format: false,*/}
                    {/*                        fields:*/}
                    {/*                            'txBytes,rxBytes,rxDrops,rxErrors,txDrops,txErrors,interface,hostid,created',*/}
                    {/*                        hostid: 8,*/}
                    {/*                        name: 'netstat',*/}
                    {/*                        stat_info: false,*/}
                    {/*                    }*/}
                    {/*                )})`,*/}
                    {/*            },*/}
                    {/*        ]}*/}
                    {/*    />*/}
                    {/*</Col>*/}
                </Row>
            </Col>
            {/*<Col span={24}>*/}
            {/*    <Row gutter={20}>*/}
            {/*        <Col span={12}>*/}
            {/*            <MonitorBox*/}
            {/*                datasource={MonitorDatasource.CLUSTERCONTROL}*/}
            {/*                clusterId={1}*/}
            {/*                title="Disk Space"*/}
            {/*                startTs={startTs}*/}
            {/*                endTs={endTs}*/}
            {/*                options={{*/}
            {/*                    type: 'line',*/}
            {/*                    format: 'size',*/}
            {/*                }}*/}
            {/*                // filters={[(d) => d.device === 'eth0']}*/}
            {/*                targets={[*/}
            {/*                    {*/}
            {/*                        expr: `[[$.[created, free, "Free"]], [$.[created, total - free, "Used"]]](${stringify(*/}
            {/*                            {*/}
            {/*                                calculate_per_sec: false,*/}
            {/*                                compact_format: false,*/}
            {/*                                fields:*/}
            {/*                                    'free,total,sectorsread,blocksize,sectorswritten,utilization,readspersec,writespersec,readspersec,writespersec,device,hostid,created',*/}
            {/*                                hostid: 8,*/}
            {/*                                name: 'diskstat',*/}
            {/*                                stat_info: false,*/}
            {/*                            }*/}
            {/*                        )})`,*/}
            {/*                    },*/}
            {/*                ]}*/}
            {/*            />*/}
            {/*        </Col>*/}
            {/*        <Col span={12}>*/}
            {/*            <MonitorBox*/}
            {/*                datasource={MonitorDatasource.CLUSTERCONTROL}*/}
            {/*                clusterId={1}*/}
            {/*                title="RAM Usage"*/}
            {/*                startTs={startTs}*/}
            {/*                endTs={endTs}*/}
            {/*                options={{*/}
            {/*                    type: 'area',*/}
            {/*                    stack: true,*/}
            {/*                    format: 'size',*/}
            {/*                }}*/}
            {/*                // filters={[(d) => d.device === 'eth0']}*/}
            {/*                targets={[*/}
            {/*                    {*/}
            {/*                        expr: `[[$.[created, ramfree, "Free"]], [$.[created, rambuffers, "Buffers"]], [$.[created, ramcached, "Cached"]], [$.[created, ramtotal - ramfree - ramcached - rambuffers, "Used"]]](${stringify(*/}
            {/*                            {*/}
            {/*                                calculate_per_sec: false,*/}
            {/*                                compact_format: false,*/}
            {/*                                fields:*/}
            {/*                                    'ramfree,rambuffers,ramcached,ramtotal,swaptotal,swapfree,hostid,created',*/}
            {/*                                hostid: 8,*/}
            {/*                                name: 'memorystat',*/}
            {/*                                stat_info: false,*/}
            {/*                            }*/}
            {/*                        )})`,*/}
            {/*                    },*/}
            {/*                ]}*/}
            {/*            />*/}
            {/*        </Col>*/}
            {/*    </Row>*/}
            {/*</Col>*/}
            {/*<Col span={12}></Col>*/}
            {/*<Col span={12}></Col>*/}

            {/*<h1>Chart - fake</h1>*/}
            {/*<Chart*/}
            {/*    data={data}*/}
            {/*    dataUpdate={dataUpdate}*/}
            {/*    from={Math.floor(moment().subtract(17, 'minutes').unix())}*/}
            {/*    to={Math.floor(moment().unix())}*/}
            {/*/>*/}
            {/*<Chart*/}
            {/*    data={data}*/}
            {/*    dataUpdate={dataUpdate}*/}
            {/*    from={Math.floor(moment().subtract(3, 'minutes').unix())}*/}
            {/*    to={Math.floor(moment().unix())}*/}
            {/*/>*/}
        </Row>
    );
}
