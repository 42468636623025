import CmonMySqlHost, {
    CmonMySqlHostProps,
    CmonMySqlHostReplicationMaster,
    CmonMySqlHostReplicationMasterProps,
    CmonMySqlHostReplicationSlave,
    CmonMySqlHostReplicationSlaveProps,
} from '../cmon/models/CmonMySqlHost';
import { CcNodeRole } from './CcNode';

export enum CcMySqlNodeBinLog {
    On = 'ON',
    Off = 'OFF',
}

export type CcMySqlNodeReplicationMasterProps = CmonMySqlHostReplicationMasterProps & {};

export class CcMySqlNodeReplicationMaster extends CmonMySqlHostReplicationMaster {
    constructor(props: CcMySqlNodeReplicationMasterProps) {
        super(props);
    }
}

export type CcMySqlNodeReplicationSlaveProps = CmonMySqlHostReplicationSlaveProps & {};

export class CcMySqlNodeReplicationSlave extends CmonMySqlHostReplicationSlave {
    // public readonly replayLogPaused?: string;
    public readonly lastIoErrno?: number;
    public readonly lastSqlErrno?: number;

    constructor(props: CcMySqlNodeReplicationSlaveProps) {
        super(props);
        this.lastIoErrno =
            props.last_io_errno && !Number.isNaN(props.last_io_errno)
                ? Number.parseInt(props.last_io_errno)
                : undefined;
        this.lastSqlErrno =
            props.last_sql_errno && !Number.isNaN(props.last_sql_errno)
                ? Number.parseInt(props.last_sql_errno)
                : undefined;

        // this.replayLogPaused = props.replaylog_paused;
    }

    public isReplicationRunning() {
        return this.slaveIoRunning === 'Yes' && this.slaveSqlRunning === 'Yes';
    }

    public isReplicationIoRunning() {
        return this.slaveIoRunning === 'Yes';
    }

    public isReplicationIoConnecting() {
        return this.slaveIoRunning === 'Connecting';
    }

    public isReplicationSqlRunning() {
        return this.slaveSqlRunning === 'Yes';
    }
}

export interface CcMySqlNodeProps extends Omit<CmonMySqlHostProps, 'log_bin'> {
    log_bin?: CcMySqlNodeBinLog;
}

export default class CcMySqlNode extends CmonMySqlHost {
    public readonly logBin?: CcMySqlNodeBinLog;
    public readonly replicationSlave?: CcMySqlNodeReplicationSlave;
    public readonly replicationMaster?: CcMySqlNodeReplicationMaster;
    constructor(props: CcMySqlNodeProps) {
        super(props);

        this.logBin = props.log_bin as CcMySqlNodeBinLog;
        this.replicationSlave =
            props.replication_slave &&
            new CcMySqlNodeReplicationSlave(props.replication_slave);
        this.replicationMaster =
            props.replication_master &&
            new CcMySqlNodeReplicationMaster(props.replication_master);
    }

    isReplicationRunning(): boolean {
        return !!this.replicationSlave?.isReplicationRunning();
    }

    public isReplicationIoRunning() {
        return !!this.replicationSlave?.isReplicationIoRunning();
    }

    public isReplicationSqlRunning() {
        return !!this.replicationSlave?.isReplicationSqlRunning();
    }

    public isReplica() {
        return (
            this.isRole(CcNodeRole.SLAVE) ||
            this.isRole(CcNodeRole.MULTI) ||
            this.isRole(CcNodeRole.INTERMEDIATE)
        );
    }

    /**
     * Returns if whether the binary log is 'OFF' or not
     */
    isBinaryLogOFF(): boolean {
        return this.logBin === CcMySqlNodeBinLog.Off;
    }

    /**
     * Returns if whether the binary log is 'ON' or not
     */
    isBinaryLogON(): boolean {
        return this.logBin === CcMySqlNodeBinLog.On;
    }

    /**
     * Moved logic from ccv1, method getStatusExplainRpc
     */
    public getMessage() {
        let message;
        switch (this.mysqlstatus) {
            case 0:
                message = super.getMessage();
                break;
            case 1:
                message = 'Node is Disconnected / Down';
                break;
            case 2:
                message = 'Replication is lagging behind';
                break;
            case 3:
                message = this.replicationSlave?.status
                    ? this.replicationSlave?.status
                    : 'Replication on node is stopped';
                break;
            case 4:
                message =
                    'Check IO and SQL Thread, slave may have to be rebuilt';
                break;
            case 5:
                message = 'Rebuild slave is needed';
                break;
            case 6:
                message = 'Rebuilding slave';
                break;
            case 7:
                message = 'Rebuild slave failed - try again';
                break;
            case 8:
                message =
                    'Skipping ahead to a good position - data will be lost';
                break;
            case 9:
                message = 'Replication on node is stopped by user';
                break;
            case 10:
                message = 'Slave can be resumed now';
                break;
            case 11:
                message = 'Galera failed on node';
                break;
            case 12:
                message = 'Node recovery needed';
                break;
            case 13:
                message = 'Cluster recovery needed';
                break;
            case 14:
                message = 'Donor (r/w for xtrabackup)';
                break;
            case 15:
                message = 'Galera node recovery in progress)';
                break;
            case 16:
                message = 'Galera cluster recovery in progress)';
                break;
            case 17:
                message = 'Node is shutdown by user';
                break;
            case 18:
                message =
                    'Node not connected to NDB! Check https://support.severalnines.com/hc/en-us/community/posts/207460483-Node-not-connected-to-NDB for suggestions.';
                break;
            case 19:
                message = 'Node is shutdown by user';
                break;
            default:
                message = super.getMessage();
        }
        return message;
    }

    isPerformanceSchemaEnabled() {
        return !!this.performanceSchema;
    }
}
