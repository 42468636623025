
import CcStats, { CcStatsProps } from '../../models/CcStats';


export interface CmonDatabaseStatsProps  extends CcStatsProps {
    'blocks-hit'?: number;
    'blocks-read'?: number;
    databaseName?: string;
    'idx-hit'?: number;
    'idx-read'?: number;
    'tidx-hit'?: number;
    'tidx-read'?: number;
    'toast-hit'?: number;
    'toast-read'?: number;

}



export default class CmonDatabaseStats extends CcStats {
    public blocksHit?: number;
    public blocksRead?: number;
    public databaseName?: string;
    public idxHit?: number;
    public idxRead?: number;
    public tidxHit?: number;
    public tidxRead?: number;
    public toastHit?: number;
    public toastRead?: number;


    constructor(props: CmonDatabaseStatsProps) {
        super(props);
        this.blocksHit = props['blocks-hit'];
        this.blocksRead = props['blocks-read'];
        this.databaseName = props.databaseName;
        this.idxHit = props['idx-hit'];
        this.idxRead = props['idx-read'];
        this.tidxHit = props['tidx-hit'];
        this.tidxRead = props['tidx-read'];
        this.toastHit = props['toast-hit'];
        this.toastRead = props['toast-read'];

    }

}
