import React, { useState, useEffect, useRef } from 'react';
import { Tabs } from 'antd';
import ProxySQLRules, {
    ProxySQLRulesApi,
    RuleIdType,
} from './ProxySQLPagesContent/ProxySQLRules/ProxySQLRules';
import ProxySQLServers from './ProxySQLPagesContent/ProxySQLServers/ProxySQLServers';
import ProxySQLVariables from './ProxySQLPagesContent/ProxySQLVariables/ProxySQLVariables';
import CcCluster from '../../../../services/models/CcCluster';
import CcProxySqlNode from '../../../../services/models/CcProxySqlNode';
import ProxySQLUsers from './ProxySQLPagesContent/ProxySQLUsers/ProxySQLUsers';

import CmonProxysqlService from '../../../../services/cmon/CmonProxysqlService';
import useFetch from '../../../../common/useFetch';
import { groupArrayBy } from '../../../../common/helpers';

import CcProxySqlServer from '../../../../services/models/CcProxySqlServer';
import ProxySQLTopQueries from './ProxySQLPagesContent/ProxySQLTopQueries/ProxySQLTopQueries';
import ProxySQLSchedulerScript from './ProxySQLPagesContent/ProxySQLSchedulerScript/ProxySQLSchedulerScript';
import ProxySQLProcessList from './ProxySQLPagesContent/ProxySQLProcessList/ProxySQLProcessList';
import ProxySQLMonitor from './ProxySQLPagesContent/ProxySQLMonitor/ProxySQLMonitor';

export enum ProxySQLPageListType {
    MONITOR = 'monitor',
    TOP_QUERIES = 'top_queries',
    RULES = 'rules',
    SERVERS = 'servers',
    USERS = 'users',
    VARIABLES = 'variables',
    SCHEDULER_SCRIPTS = 'scheduler_scripts',
    NODE_PERFORMANCE = 'node_performance',
    PROCESS_LIST = 'process_list',
}

type ProxySQLDetailsPageProps = {
    cluster: CcCluster;
    node: CcProxySqlNode;
};

export default ProxySQLDetailsPage;

function ProxySQLDetailsPage({ cluster, node }: ProxySQLDetailsPageProps) {
    const [hostGroupList, setHostGroupList] = useState<
        { value: string; label: string }[] | undefined
    >();
    const [serversTablesData, setServersTablesData] = useState<
        | undefined
        | {
              [key: string]: CcProxySqlServer[];
          }
    >();

    // we load hostgroups in main Tabs file controller because we need them loaded inside some of the tabs
    const {
        data: serversResponse,
        loading: serversLoading,
        refresh: refreshServers,
    } = useFetch({
        name: 'queryServers',
        fetchFn: async (params, opts) => {
            const response: any = await CmonProxysqlService.queryservers(
                {
                    cluster_id: cluster?.clusterId,
                    hostName: node?.hostname,
                    port: node?.port,
                    ...params,
                },
                opts
            );

            return response?.queryResults;
        },
        cancelFn: async ({ requestId }) => {
            await CmonProxysqlService.cancelRequest(requestId);
        },
    });
    useEffect(() => {
        refreshServers();
    }, []);

    useEffect(() => {
        if (serversResponse !== undefined) {
            const data = groupArrayBy(
                serversResponse,
                (item) => item.hostgroupId
            );
            setServersTablesData(data);
            setHostGroupList(
                Object.keys(data).map((key) => {
                    return { value: key, label: key };
                })
            );
        }
    }, [serversResponse]);

    // Rules
    const [queryRuleId, setQueryRuleId] = useState<{
        nextCacheId: number;
        nextRuleId: number;
    }>();

    const onQueryRuleId = (obj: RuleIdType) => {
        setQueryRuleId(obj);
    };

    const rulesRef = useRef<ProxySQLRulesApi>();
    const onQueriesLoad = async () => {
        rulesRef.current?.refresh();
    };

    return (
        <Tabs>
            <Tabs.TabPane tab={'Monitor'} key={ProxySQLPageListType.MONITOR}>
                <ProxySQLMonitor
                    cluster={cluster}
                    node={node}
                    serversTablesData={serversTablesData}
                    loading={serversLoading}
                />
            </Tabs.TabPane>
            <Tabs.TabPane tab="Rules" key={ProxySQLPageListType.RULES}>
                <ProxySQLRules
                    ref={rulesRef}
                    hostGroupList={hostGroupList}
                    cluster={cluster}
                    node={node}
                    onQueryRuleId={onQueryRuleId}
                />
            </Tabs.TabPane>
            <Tabs.TabPane
                tab={'Top queries'}
                key={ProxySQLPageListType.TOP_QUERIES}
            >
                <ProxySQLTopQueries
                    hostGroupList={hostGroupList}
                    cluster={cluster}
                    node={node}
                    onQueriesLoad={onQueriesLoad}
                    queryRuleId={queryRuleId}
                />
            </Tabs.TabPane>
            <Tabs.TabPane tab={'Servers'} key={ProxySQLPageListType.SERVERS}>
                <ProxySQLServers
                    hostGroupList={hostGroupList}
                    serversTablesData={serversTablesData}
                    loading={serversLoading}
                    refreshServers={refreshServers}
                    cluster={cluster}
                    node={node}
                />
            </Tabs.TabPane>
            <Tabs.TabPane tab={'Users'} key={ProxySQLPageListType.USERS}>
                <ProxySQLUsers
                    cluster={cluster}
                    node={node}
                    hostGroupList={hostGroupList}
                />
            </Tabs.TabPane>
            <Tabs.TabPane
                tab={'Variables'}
                key={ProxySQLPageListType.VARIABLES}
            >
                <ProxySQLVariables cluster={cluster} node={node} />
            </Tabs.TabPane>
            <Tabs.TabPane
                tab={'Scheduler scripts'}
                key={ProxySQLPageListType.SCHEDULER_SCRIPTS}
            >
                <ProxySQLSchedulerScript cluster={cluster} node={node} />
            </Tabs.TabPane>
            <Tabs.TabPane
                tab={'Process list'}
                key={ProxySQLPageListType.PROCESS_LIST}
            >
                <ProxySQLProcessList cluster={cluster} node={node} />
            </Tabs.TabPane>
        </Tabs>
    );
}
