import React from 'react';
import { AppIconProps } from '../../common/icons/AppIcon';
import { CcClusterVendor } from '../../services/models/CcCluster';
import {
    CodershipIcon,
    MariadbIcon,
    PerconaIcon,
} from '../../common/icons/icons';

export function getVendorIcon(vendor: string, iconProps?: AppIconProps) {
    switch (vendor) {
        case CcClusterVendor.MARIADB:
            return <MariadbIcon {...iconProps} />;
        case CcClusterVendor.PERCONA:
            return <PerconaIcon {...iconProps} />;
        case CcClusterVendor.CODERSHIP:
            return <CodershipIcon {...iconProps} />;
        default:
            return null;
    }
}
