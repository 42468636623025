import React from 'react';
import PageWrapper, {
    PageWrapperProps,
} from '@severalnines/bar-frontend-components/build/lib/Layout/PageWrapper';
import classNames from 'classnames';
import './AppPageWrapper.less';

export default AppPageWrapper;

export type AppPageWrapperProps = PageWrapperProps & {};

function AppPageWrapper({
    className,
    headerClassName,
    contentClassName,
    contentProps,
    ...rest
}: AppPageWrapperProps) {
    const { classNameContent, ...restContentProps } = contentProps || {};
    return (
        <PageWrapper
            className={classNames('AppPageWrapper', className)}
            headerClassName={classNames(
                'AppPageWrapper_header',
                headerClassName
            )}
            contentClassName={classNames(
                'AppPageWrapper_content',
                contentClassName
            )}
            contentProps={{
                ...(classNameContent
                    ? {
                          className: classNames(
                              'AppPageWrapper_content',
                              classNameContent
                          ),
                      }
                    : {}),
                ...restContentProps,
            }}
            {...rest}
        />
    );
}
