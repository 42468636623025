import CmonTreeService from '../../services/cmon/CmonTreeService';
import useFetch, { UseFetchProps } from '../../common/useFetch';
import CcTreeItem, {
    CcTreeItemAccess,
    CcTreeItemHandledAclPath,
    CcTreeItemType,
    getMinLevel,
} from '../../services/models/CcTreeItem';
import { useCallback, useEffect, useState } from 'react';
import { AppState, AppStateTree, setTree } from '../../appReducer';
import { useDispatch, useSelector } from 'react-redux';

export type UseTreeProps = Omit<UseFetchProps, 'fetchFn'> & {};

export type AclPermissionItem = {
    key: string;
    title?: string;
    description?: string;
    level: CcTreeItemAccess;
    isCluster: boolean;
    item: CcTreeItem;
    clusterKey?: string;
    disabled?: boolean;
};

export const aclItemsPaths = [
    CcTreeItemHandledAclPath.ACL_UI_CONFIG,
    CcTreeItemHandledAclPath.ACL_USER_MANAGER,
    CcTreeItemHandledAclPath.ACL_LDAP_SETTINGS,
    CcTreeItemHandledAclPath.ACL_JOB_EXECUTOR_CREATE_CLUSTER,
];

export const aclItemsPathsDeps: {
    [key in CcTreeItemHandledAclPath]?: CcTreeItemHandledAclPath[];
} = {
    [CcTreeItemHandledAclPath.ACL_JOB_EXECUTOR_CREATE_CLUSTER]: [
        CcTreeItemHandledAclPath.ACL_JOB_EXECUTOR,
    ],
    [CcTreeItemHandledAclPath.ACL_UI_CONFIG]: [
        CcTreeItemHandledAclPath.ACL_JOB_EXECUTOR,
    ],
};

export default function useTree({}: UseTreeProps) {
    const [storedTree]: [AppStateTree] = useSelector(({ tree }: AppState) => [
        tree,
    ]);

    const [aclItems, setAclItems] = useState<CcTreeItem[]>(
        storedTree ? generateAclItems(storedTree.toArray()) : []
    );
    const [data, setData] = useState<CcTreeItem[]>(
        storedTree ? storedTree.toArray() : []
    );

    const { data: treeData, loading, refresh, cancel, loaded } = useFetch<
        CcTreeItem
    >({
        name: 'tree',
        useCache: false,
        fetchFn: async ({ pageSize, page, ...rest }, opts) => {
            const { cdt } = await CmonTreeService.getTree(
                {
                    filters: [
                        // needed paths
                        ...aclItemsPaths,
                        // needed dependencies
                        ...Object.values(aclItemsPathsDeps).flat(),
                        // items with type = cluster
                        CcTreeItemType.Cluster,
                    ],
                    ...rest,
                },
                opts
            );
            return cdt;
        },
        cancelFn: async ({ requestId }) => {
            await CmonTreeService.cancelRequest(requestId);
        },
    });

    const dispatch = useDispatch();

    const getEffectiveAcl = useCallback(
        (levels?: CcTreeItemAccess[]): AclPermissionItem[] =>
            aclItems.map((item) => {
                const key = item.getKey() as CcTreeItemHandledAclPath;
                const effectivePrivileges: CcTreeItemAccess = getMinLevel([
                    item.effectivePrivileges!,
                    ...(aclItemsPathsDeps[key]
                        ? // then we get the dependecy items privileges
                          aclItemsPathsDeps[key]!.map(
                              (path) =>
                                  getTreeItem(path, data)?.effectivePrivileges!
                          )
                        : item.isType(CcTreeItemType.Cluster)
                        ? // then we get the real permission for that cluster
                          [getRealAccessToClusterTypeItem(item, data!)]
                        : []),
                ]);
                return {
                    key: item.getKey(),
                    title: item.isType(CcTreeItemType.Cluster)
                        ? item.itemName
                        : getAclItemText(key),
                    description: getAclItemDescription(key),
                    level: effectivePrivileges,
                    isCluster: item.isType(CcTreeItemType.Cluster),
                    clusterKey: item.getClusterKey(),
                    disabled: !!(
                        levels && !levels.includes(effectivePrivileges)
                    ),
                    item,
                };
            }),
        [aclItems]
    );
    const getEffectiveAclOverActions = useCallback(
        (levels?: CcTreeItemAccess[]): AclPermissionItem[] =>
            getEffectiveAcl(levels).filter((item) => !item.isCluster),
        [aclItems, getEffectiveAcl]
    );

    const getEffectiveAclOverClusters = useCallback(
        (levels?: CcTreeItemAccess[]): AclPermissionItem[] =>
            getEffectiveAcl(levels).filter((item) => item.isCluster),
        [aclItems, getEffectiveAcl]
    );

    const checkEffectiveAccess = useCallback(
        (path, level): boolean => {
            const item = getTreeItem(path, data);
            if (!item) {
                return false;
            }

            return item.checkEffectiveAccess(level);
        },

        [data]
    );
    const checkEffectiveAccessToSomeClusters = useCallback(
        (level): boolean => {
            return aclItems
                .filter((item) => item.isType(CcTreeItemType.Cluster))
                .map((item) => item.checkEffectiveAccess(level))
                .some((value) => value);
        },

        [aclItems]
    );

    useEffect(() => {
        if (treeData) {
            const tree = [treeData];
            setAclItems(generateAclItems(tree));
            setData(tree);
            dispatch(setTree(tree));
        }
    }, [treeData]);

    useEffect(() => {
        if (storedTree) {
            const tree = storedTree.toArray();
            setAclItems(generateAclItems(tree));
            setData(tree);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [storedTree]);

    return {
        data,
        aclItems,
        getEffectiveAcl,
        getEffectiveAclOverActions,
        getEffectiveAclOverClusters,
        checkEffectiveAccess,
        checkEffectiveAccessToSomeClusters,
        loading,
        refresh,
        cancel,
        loaded,
    };
}

export function getTreeItem(
    itemPath: string,
    tree: CcTreeItem[]
): CcTreeItem | undefined {
    if (!tree) {
        return undefined;
    }
    let path = itemPath.split('/');
    const name = path.shift();
    const item = tree.find((i) => i.itemName === name);
    if (path.length > 0 && item?.subItems && item?.subItems.length > 0) {
        return getTreeItem(path.join('/'), item.subItems);
    } else {
        return item;
    }
}
export function getTreeItemsByType(
    type: CcTreeItemType,
    tree: CcTreeItem[]
): CcTreeItem[] {
    if (!tree) {
        return [];
    }
    const items = tree.filter((i) => i.isType(type));
    return [
        ...items,
        ...tree.map((i) => getTreeItemsByType(type, i.subItems)).flat(),
    ];
}

export function getAclItemText(itemPath: CcTreeItemHandledAclPath) {
    switch (itemPath) {
        case CcTreeItemHandledAclPath.ACL_UI_CONFIG:
            return 'Controller Configuration';
        case CcTreeItemHandledAclPath.ACL_USER_MANAGER:
            return 'User Management';
        case CcTreeItemHandledAclPath.ACL_JOB_EXECUTOR_CREATE_CLUSTER:
            return 'Cluster Management';
        case CcTreeItemHandledAclPath.ACL_LDAP_SETTINGS:
            return 'LDAP Settings';
        default:
            return itemPath;
    }
}
export function getAclItemDescription(itemPath: CcTreeItemHandledAclPath) {
    switch (itemPath) {
        case CcTreeItemHandledAclPath.ACL_UI_CONFIG:
            return 'Change controller configuration';
        case CcTreeItemHandledAclPath.ACL_USER_MANAGER:
            return 'Manage users and teams';
        case CcTreeItemHandledAclPath.ACL_JOB_EXECUTOR_CREATE_CLUSTER:
            return 'Deploy clusters';
        case CcTreeItemHandledAclPath.ACL_LDAP_SETTINGS:
            return 'Change LDAP settings';
        default:
            return itemPath;
    }
}
export function getTreeItemAccessText(itemAccess: CcTreeItemAccess) {
    if (!itemAccess) {
        return 'No access';
    }
    switch (itemAccess) {
        case CcTreeItemAccess.READ:
            return 'View';
        case CcTreeItemAccess.FULL_ACCESS:
            return 'Manage';
        case CcTreeItemAccess.NO_ACCESS:
            return 'No access';
        case CcTreeItemAccess.EXECUTE:
            return 'Execute';
        default:
            return itemAccess;
    }
}
export function getTreeItemAccessDescription(itemAccess: CcTreeItemAccess) {
    if (!itemAccess) {
        return 'The users are not allowed to view the clusters.';
    }
    switch (itemAccess) {
        case CcTreeItemAccess.READ:
            return 'The users can view the clusters and their properties such as jobs, backups, charts, metrics, and settings of the cluster. They can not modify the clusters.';
        case CcTreeItemAccess.FULL_ACCESS:
            return 'The users can view all clusters and their properties such as jobs, backups, charts, metrics, and settings. They can change the clusters settings, and manage the jobs.';
        case CcTreeItemAccess.NO_ACCESS:
            return 'The users are not allowed to view the clusters.';
        case CcTreeItemAccess.EXECUTE:
            return 'Execute';
        default:
            return itemAccess;
    }
}

export function generateAclItems(tree: CcTreeItem[]) {
    return [
        ...aclItemsPaths
            .map((path) => getTreeItem(path, tree) as CcTreeItem)
            .filter((i) => i !== undefined),
        ...getTreeItemsByType(CcTreeItemType.Cluster, tree),
    ];
}

/**
 * Managing a cluster needs to have job executor permissions, this function
 * will return correct priviledges to show
 */
export function getRealAccessToClusterTypeItem(
    item: CcTreeItem,
    tree: CcTreeItem[]
): CcTreeItemAccess {
    return item.effectivePrivileges === CcTreeItemAccess.FULL_ACCESS &&
        ![CcTreeItemAccess.FULL_ACCESS, CcTreeItemAccess.EXECUTE].includes(
            getTreeItem(CcTreeItemHandledAclPath.ACL_JOB_EXECUTOR, tree!)
                ?.effectivePrivileges!
        )
        ? CcTreeItemAccess.READ
        : (item.effectivePrivileges as CcTreeItemAccess);
}
