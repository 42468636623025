import './ProxySQLProcessList.less';
import React, { useContext, useEffect } from 'react';
import { ResponsiveContext } from '@severalnines/bar-frontend-components/build/lib/Layout/Responsive';
import { TablePaginationConfig } from 'antd/es';

import CcCluster from '../../../../../../services/models/CcCluster';
import CcProxySqlNode from '../../../../../../services/models/CcProxySqlNode';
import CmonProxysqlService from '../../../../../../services/cmon/CmonProxysqlService';
import useListFetch from '../../../../../../common/useListFetch';
import CcProxySqlProcessList from '../../../../../../services/models/CcProxySqlProcessList';
import AppTable from '../../../../../../common/DataDisplay/AppTable';

export default ProxySQLProcessList;

export type ProxySQLProcessListProps = {
    cluster: CcCluster;
    node: CcProxySqlNode;
    processPageSize?: number;
};

function ProxySQLProcessList({
    cluster,
    node,
    processPageSize = 15,
}: ProxySQLProcessListProps) {
    const { responsive }: any = useContext(ResponsiveContext);

    const {
        loading,
        list: processes,
        refresh: refreshProcesses,
        page,
        pageSize,
        total,
    } = useListFetch({
        name: 'processlist',
        pageSize: processPageSize,
        fetchFn: async ({ pageSize, page, ...rest }, opts) => {
            const response = await CmonProxysqlService.processlist(
                {
                    cluster_id: cluster?.clusterId,
                    hostName: node?.hostname,
                    port: node?.port,
                    ...rest,
                },
                opts
            );

            return {
                list: response?.queryResults,
                total: response?.queryResultTotalCount,
            };
        },
        cancelFn: async ({ requestId }) => {
            await CmonProxysqlService.cancelRequest(requestId);
        },
    });

    useEffect(() => {
        refreshProcesses();
    }, []);

    const handleTableChange = async (pagination: any) => {
        await refreshProcesses({
            page: pagination.current,
            pageSize: pagination.pageSize,
        });
    };

    const columns = [
        {
            title: 'Session ID',
            key: 'SessionID',
            dataIndex: 'SessionID',
        },
        {
            title: 'Thread ID',
            key: 'ThreadID',
            dataIndex: 'ThreadID',
        },
        {
            title: 'CLI Hostname',
            key: 'cliHost',
            render: (record: CcProxySqlProcessList) => {
                return (
                    <span>{`${record.cliHost ? record.cliHost : ''}:${
                        record.cliPort ? record.cliPort : ''
                    }`}</span>
                );
            },
        },
        {
            title: 'Command',
            key: 'command',
            dataIndex: 'command',
        },
        {
            title: 'DB',
            key: 'db',
            dataIndex: 'db',
        },
        {
            title: 'User',
            key: 'user',
            dataIndex: 'user',
        },
        {
            title: 'Host Group',
            key: 'hostgroup',
            dataIndex: 'hostgroup',
        },
        {
            title: 'Local server hosts',
            key: 'lSrvHost',
            render: (record: CcProxySqlProcessList) => {
                return (
                    <span>{`${record.lSrvHost ? record.lSrvHost : ''}:${
                        record.lSrvPort ? record.lSrvPort : ''
                    }`}</span>
                );
            },
        },
        {
            title: 'Server hosts',
            key: 'srvHost',
            render: (record: CcProxySqlProcessList) => {
                return (
                    <span>{`${record.srvHost ? record.srvHost : ''}:${
                        record.srvPort ? record.srvPort : ''
                    }`}</span>
                );
            },
        },
        {
            title: 'Time',
            key: 'timeMs',
            dataIndex: 'timeMs',
        },
        {
            title: 'Info',
            key: 'info',
            dataIndex: 'info',
        },
    ];

    const pagination: TablePaginationConfig = {
        size: 'default',
        pageSize,
        current: page,
        total,
        hideOnSinglePage: true,
        showQuickJumper: true,
        showSizeChanger: true,
        position: ['bottomCenter'],
    };
    return (
        <div className="ProxySQLProcessList">
            <AppTable
                loading={loading}
                rowKey={(record: CcProxySqlProcessList) => record.getKey()}
                onRow={(record: CcProxySqlProcessList, index: any) => ({
                    'data-testid': `process-table-row-${index}`,
                })}
                className="ProxySQLProcessListTable"
                size="small"
                dataSource={processes}
                columns={columns}
                responsive={responsive}
                onChange={handleTableChange}
                pagination={pagination}
            />
        </div>
    );
}
