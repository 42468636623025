import React from 'react';
import { Button, Form, Space } from 'antd';
import FormFooter from '../../../common/FormFooter';
import ModalDefaultForm from '../../../common/ModalDefaultForm';
import CcNode from '../../../services/models/CcNode';
import NodeFormat from '../NodeFormat';
import SpaceWide from '../../../common/SpaceWide';
import useCreateJob from '../../Jobs/useCreateJob';
import { CmonJobInstanceCommand } from '../../../services/cmon/models/CmonJobInstance';
import FormItemInlineSwitch from '../../../common/DataEntry/FormItemInlineSwitch';
import Alert from '@severalnines/bar-frontend-components/build/lib/Feedback/Alert';

export default NodeResetReplicaForm;

export type NodeResetReplicaFormProps = {
    node: CcNode;
    onSuccess?: () => void;
    onCancel?: () => void;
};
export type NodeResetReplicaFormValues = {
    force?: boolean;
};

function NodeResetReplicaForm({
    node,
    onSuccess,
    onCancel,
}: NodeResetReplicaFormProps) {
    const [form] = Form.useForm<NodeResetReplicaFormValues>();
    const { loading, send } = useCreateJob({
        clusterId: node.clusterid,
        title: 'Reset replica',
        command: CmonJobInstanceCommand.RESET_REPLICATION_SLAVE,
        onSuccess,
    });

    const handleSubmit = async (values: NodeResetReplicaFormValues) => {
        await send({
            force: values.force,
            replication_slave: {
                hostname: node.hostname,
                port: node.port,
                class_name: 'CmonHost',
            },
        });
    };
    return (
        <ModalDefaultForm
            title="Reset replica"
            form={form}
            footer={[]}
            onCancel={onCancel}
            width={450}
            defaultVisible={true}
        >
            <Form
                className="NodeResetReplicaForm"
                form={form}
                layout="vertical"
                onFinish={handleSubmit}
                initialValues={{ force: false }}
            >
                <SpaceWide direction="vertical">
                    <NodeFormat
                        node={node}
                        size="large"
                        contentProps={{ style: { width: '100%' } }}
                    />
                    <FormItemInlineSwitch
                        justify
                        noMargin
                        name="force"
                        label={<Space>Force reset</Space>}
                        valuePropName="checked"
                    />
                    <Form.Item noStyle={false} shouldUpdate={true}>
                        {() => (
                            <Alert
                                message={
                                    <span>
                                        RESET SLAVE{' '}
                                        {form.getFieldValue('force')
                                            ? ' ALL'
                                            : ''}{' '}
                                        will be executed
                                    </span>
                                }
                                showIcon={true}
                            />
                        )}
                    </Form.Item>
                </SpaceWide>
                <FormFooter>
                    <Button
                        key="done"
                        type="primary"
                        htmlType="submit"
                        loading={loading}
                    >
                        Reset
                    </Button>
                </FormFooter>
            </Form>
        </ModalDefaultForm>
    );
}
