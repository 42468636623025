import React from 'react';
import { Col, Form, Row } from 'antd';
import { FormInstance } from 'antd/lib/form';
import ClusterNameAndTags from '../FormParts/ClusterNameAndTags';
import { ClusterConfigurator } from '../ClusterConfigurator';
import CcCluster from '../../../../services/models/CcCluster';
import ClusterNodeSelect from '../../../../common/DataEntry/ClusterNodeSelect';
import CcNode from '../../../../services/models/CcNode';
import FormItemInlineSwitch from '../../../../common/DataEntry/FormItemInlineSwitch';
import CcBackup, { CcBackupMethod } from '../../../../services/models/CcBackup';
import BackupLocationSelectForm from '../../../../common/Form/BackupLocationSelectForm';

export default DeploymentClusterDetails;

export type DeploymentClusterDetailsProps = {
    form: FormInstance;
    configurator: typeof ClusterConfigurator;
    cluster?: CcCluster;
    primaryCluster?: CcCluster;
    fromBackup?: boolean;
    backup?: CcBackup;
};

function DeploymentClusterDetails({
    form,
    configurator,
    cluster,
    primaryCluster,
    fromBackup,
    backup,
}: DeploymentClusterDetailsProps) {
    const handleBackupSelect = (backup: CcBackup) => {
        const node = primaryCluster?.nodes.find(
            (n) => backup.getBackupHost() === n.hostname && n.isDatabaseNode()
        );
        if (node) {
            form.setFieldsValue({
                primaryCluster: {
                    address: node.getHostWithPort(),
                },
            });
        }
    };

    const currentCluster = primaryCluster || cluster;

    const showBackupSelect = fromBackup && currentCluster;
    const showPrimarySelect = !showBackupSelect && primaryCluster;
    const showBiDirectional = !!primaryCluster;

    return (
        <div className="DeploymentClusterDetails">
            {showBackupSelect ? (
                <BackupLocationSelectForm
                    form={form}
                    cluster={currentCluster}
                    onBackupSelect={handleBackupSelect}
                    backup={backup}
                    backupSelectProps={
                        primaryCluster
                            ? {
                                  filter: deploymentClusterBackupFilter,
                              }
                            : undefined
                    }
                    backupFieldName={['fromBackup', 'backup']}
                    locationFieldName={['fromBackup', 'backupLocation']}
                    showLocation={false}
                />
            ) : undefined}

            {showPrimarySelect ? (
                <Row gutter={[24, 0]}>
                    <Col span={12}>
                        <h3>Select primary</h3>
                        <Form.Item
                            label="Cluster replication primary"
                            name={['primaryCluster', 'address']}
                            rules={[
                                {
                                    required: true,
                                    message:
                                        'Please select a primary to stream from.',
                                },
                            ]}
                        >
                            <ClusterNodeSelect
                                cluster={primaryCluster}
                                placeholder="Select a primary"
                                filter={(n: CcNode) => n.isPrimary()}
                            />
                        </Form.Item>
                    </Col>
                </Row>
            ) : undefined}
            {showBiDirectional ? (
                <Row gutter={[24, 0]}>
                    <Col span={12}>
                        <FormItemInlineSwitch
                            justify
                            name={[
                                'primaryCluster',
                                'bidirectionalReplication',
                            ]}
                            label={<span>Bi-directional replication</span>}
                            valuePropName="checked"
                        />
                    </Col>
                </Row>
            ) : undefined}
            <Row gutter={[24, 0]}>
                <ClusterNameAndTags />
                {configurator.clusterDetailsExtra(form)}
            </Row>
        </div>
    );
}

function deploymentClusterBackupFilter(backup: CcBackup) {
    return [
        CcBackupMethod.XTRABACKUP,
        CcBackupMethod.XTRABACKUP_FULL,
        CcBackupMethod.XTRABACKUP_INCR,
        CcBackupMethod.MARIABACKUP,
        CcBackupMethod.MARIABACKUP_FULL,
        CcBackupMethod.MARIABACKUP_INCR,
    ].includes(backup.getMethod());
}

export function getDeploymentClusterDetailsValidate() {
    return [
        ['details', 'names'],
        ['details', 'tags'],
        ['details', 'enterpriseToken'],
        ['primaryCluster', 'address'],
        ['fromBackup', 'backup'],
    ];
}
