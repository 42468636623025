import React, { useState } from 'react';
import './ActivityQuickView.less';
import { RadioChangeEvent, Space, Tabs, Typography } from 'antd';
import AlarmsTable from '../Alarms/AlarmsTable';
import JobsTable from '../Jobs/JobsTable';
import LogsTable from '../Logs/LogsTable';
import classNames from 'classnames';
import { AlarmsIcon, JobsIcon, LogsIcon } from '../../common/icons/icons';
import AlarmsBadge from '../Alarms/AlarmsBadge';
import JobsBadge from '../Jobs/JobsBadge';
import ActivityQuickViewFooter from './ActivityQuickViewFooter';
import { AppState, closeActivityQuickView } from '../../appReducer';
import { useDispatch, useSelector } from 'react-redux';
import AuditLogTable from '../AuditLog/AuditLogTable';
import AppPageWrapper from '../../common/Layout/AppPageWrapper';
import AppRadio from '../../common/DataEntry/AppRadio';
import ActivityCenterJobsLink from './ActivityCenterJobsLink';
import ActivityCenterLogsLink from './ActivityCenterLogsLink';
import ActivityCenterAlarmsLink from './ActivityCenterAlarmsLink';
import ActivityCenterAuditLink from './ActivityCenterAuditLink';
import CmonLogBadge from '../Logs/CmonLogBadge';

export default ActivityQuickView;

export enum ActivityQuickViewKey {
    ALARMS = 'alarms',
    JOBS = 'jobs',
    LOGS = 'logs',
    AUDIT_LOG = 'audit',
}

export type ActivityQuickViewProps = {
    activeKey?: ActivityQuickViewKey;
    visible?: boolean;
};

function ActivityQuickView({
    activeKey: paramActiveKey,
    visible = true,
}: ActivityQuickViewProps) {
    const [activeKey, setActiveKey] = useState(
        paramActiveKey || ActivityQuickViewKey.ALARMS
    );
    const [activityQuickViewOpened]: [
        boolean
    ] = useSelector(({ activityQuickViewOpened }: AppState) => [
        activityQuickViewOpened,
    ]);
    const dispatch = useDispatch();

    const handleTabChange = (e: RadioChangeEvent) => {
        setActiveKey(e.target.value);
    };

    const alarmsFooterRender = (total?: number) => (
        <ActivityQuickViewFooter>
            You have{' '}
            <Typography.Text strong>{total} unmuted alarms</Typography.Text>. To
            see all alarms, go to{' '}
            <ActivityCenterAlarmsLink
                onClick={() => dispatch(closeActivityQuickView())}
            >
                Activity center
            </ActivityCenterAlarmsLink>
            .
        </ActivityQuickViewFooter>
    );
    const jobsFooterRender = (total?: number) => (
        <ActivityQuickViewFooter>
            You have <Typography.Text strong>{total} jobs</Typography.Text>. To
            see all jobs, go to{' '}
            <ActivityCenterJobsLink
                onClick={() => dispatch(closeActivityQuickView())}
            >
                Activity center
            </ActivityCenterJobsLink>
            .
        </ActivityQuickViewFooter>
    );
    const logsFooterRender = (total?: number) => (
        <ActivityQuickViewFooter>
            You have <Typography.Text strong>{total} logs</Typography.Text>. To
            see all logs, go to{' '}
            <ActivityCenterLogsLink
                onClick={() => dispatch(closeActivityQuickView())}
            >
                Activity center
            </ActivityCenterLogsLink>
            .
        </ActivityQuickViewFooter>
    );
    const auditLogFooterRender = (total?: number) => (
        <ActivityQuickViewFooter>
            You have <Typography.Text strong>{total} audit log</Typography.Text>
            . To see all audit log, go to{' '}
            <ActivityCenterAuditLink
                onClick={() => dispatch(closeActivityQuickView())}
            >
                Activity center
            </ActivityCenterAuditLink>
            .
        </ActivityQuickViewFooter>
    );

    const handleContentClick = (event: any) => {
        // we don't want activity quick view to be closed when user clicks layout
        // so we stop event propagation
        event.stopPropagation();
    };

    const handleContentKeyUp = (event: any) => {
        if (event.keyCode === 27) {
            if (activityQuickViewOpened) {
                // closing activity quick view when user clicks enter and it is opened
                dispatch(closeActivityQuickView());
            }
        }
    };
    return (
        <div
            className={classNames('ActivityQuickView', {
                'ActivityQuickView--visible': visible,
            })}
            onClick={handleContentClick}
            // following rules are for avoiding jsx-a11y complain
            // @todo think about removing some of these rules?
            // Visible, non-interactive elements with click handlers must have at least one keyboard listener  jsx-a11y/click-events-have-key-events
            // Static HTML elements with event handlers require a role jsx-a11y/no-static-element-interactions
            role="button"
            tabIndex={0}
            onKeyUp={handleContentKeyUp}
        >
            <AppPageWrapper
                title={
                    activeKey === ActivityQuickViewKey.ALARMS ? (
                        <Space>
                            <AlarmsIcon />
                            Alarms (unmuted)
                        </Space>
                    ) : activeKey === ActivityQuickViewKey.JOBS ? (
                        <Space>
                            <JobsIcon />
                            Jobs
                        </Space>
                    ) : activeKey === ActivityQuickViewKey.AUDIT_LOG ? (
                        <Space>
                            <LogsIcon />
                            Audit Log
                        </Space>
                    ) : (
                        <Space>
                            <LogsIcon />
                            CMON Log
                        </Space>
                    )
                }
                contentClassName="ActivityQuickView_content"
                headerMenu={
                    <AppRadio.Group
                        onChange={handleTabChange}
                        value={activeKey}
                        className="ActivityQuickView_menu"
                    >
                        <AppRadio.Button
                            key={ActivityQuickViewKey.ALARMS}
                            value={ActivityQuickViewKey.ALARMS}
                        >
                            <AlarmsBadge offsetY={-5} offsetX={5}>
                                Alarms
                            </AlarmsBadge>
                        </AppRadio.Button>
                        <AppRadio.Button
                            key={ActivityQuickViewKey.JOBS}
                            value={ActivityQuickViewKey.JOBS}
                        >
                            <JobsBadge offsetY={-5} offsetX={5}>
                                Jobs
                            </JobsBadge>
                        </AppRadio.Button>
                        <AppRadio.Button
                            key={ActivityQuickViewKey.LOGS}
                            value={ActivityQuickViewKey.LOGS}
                        >
                            <CmonLogBadge offsetY={-5} offsetX={5}>
                                CMON Log
                            </CmonLogBadge>
                        </AppRadio.Button>
                        <AppRadio.Button
                            key={ActivityQuickViewKey.AUDIT_LOG}
                            value={ActivityQuickViewKey.AUDIT_LOG}
                        >
                            Audit Log
                        </AppRadio.Button>
                    </AppRadio.Group>
                }
                gutter={0}
            >
                <Tabs activeKey={activeKey} renderTabBar={() => <div />}>
                    <Tabs.TabPane
                        tab="Alarms"
                        key={ActivityQuickViewKey.ALARMS}
                    >
                        <AlarmsTable
                            enablePagination={false}
                            pageLimit={5}
                            hideIgnored={true}
                            footerRender={alarmsFooterRender}
                            showRelativeTime={true}
                        />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Jobs" key={ActivityQuickViewKey.JOBS}>
                        <JobsTable
                            enablePagination={false}
                            pageLimit={5}
                            footerRender={jobsFooterRender}
                            showRelativeTime={true}
                        />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Logs" key={ActivityQuickViewKey.LOGS}>
                        <LogsTable
                            enablePagination={false}
                            pageLimit={5}
                            footerRender={logsFooterRender}
                            isActive={activeKey === ActivityQuickViewKey.LOGS}
                            showRelativeTime={true}
                        />
                    </Tabs.TabPane>
                    <Tabs.TabPane
                        tab="Audit Log"
                        key={ActivityQuickViewKey.AUDIT_LOG}
                    >
                        <AuditLogTable
                            enablePagination={false}
                            pageLimit={5}
                            footerRender={auditLogFooterRender}
                            isActive={
                                activeKey === ActivityQuickViewKey.AUDIT_LOG
                            }
                            showRelativeTime={true}
                        />
                    </Tabs.TabPane>
                </Tabs>
            </AppPageWrapper>
        </div>
    );
}
