import FormItem from '../../../common/DataEntry/FormItem';
import {Alert, Col, Divider, Input, Row, Select, Switch} from 'antd';
import {
    IntegrationType,
    getNotificationProviderName,
} from './NotificationServicesProviderFormat';
import { FormInstance } from 'antd/lib/form';
import FormItemInlineSwitch from '../../../common/DataEntry/FormItemInlineSwitch';
import { getDocumentationLink } from './NotificationServicesWizard';

import NotificationServicesTestCredentialButton from './NotificationServicesTestCredentialButton';
import './NotificationServices.less';
import React from 'react';
import AppDivider from '../../../common/AppDivider';

export default NotificationServicesConfiguration;

function getNotificationServicesConfigurationHelp(type: IntegrationType) {
    switch (type) {
        case IntegrationType.PAGER_DUTY:
            return (
                <>
                    <ol>
                        <li>Login to your PagerDuty account</li>
                        <li>
                            Go to Configuration - Services and select "Add New
                            Service"
                        </li>
                        <li>
                            Under Integration Settings select "Use our API
                            directly" and "Events API v2", and give it an
                            "Integration Name"
                        </li>
                        <li>
                            Under "Incident Settings" select an Escalation
                            Policy
                        </li>
                    </ol>
                    <span>
                        Need more info? Check the{' '}
                        <a
                            rel="noreferrer"
                            target="_blank"
                            href={getDocumentationLink(
                                IntegrationType.PAGER_DUTY
                            )}
                        >
                            PagerDuty documentation
                        </a>{' '}
                    </span>
                </>
            );
        case IntegrationType.VICTOR_OPS:
            return (
                <>
                    <ol>
                        <li>Go to your VictorOps account</li>
                        <li>Go to settings - integrations</li>
                        <li>Look for REST</li>
                        <li>Create new integration and copy the url</li>
                    </ol>
                    <span>
                        Need more info? Check the{' '}
                        <a
                            rel="noreferrer"
                            target="_blank"
                            href={getDocumentationLink(
                                IntegrationType.VICTOR_OPS
                            )}
                        >
                            VictorOps documentation
                        </a>{' '}
                    </span>
                </>
            );
        case IntegrationType.TELEGRAM:
            return (
                <>
                    <ol>
                        <li>Create a new bot using BotFather</li>
                        <li>Copy the bot token</li>
                        <li>Create a new channel</li>
                        <li>Add bot to the channel as Administrator</li>
                        <li>Allow your bot to send messages to the channel</li>
                        <li>Copy channel id from the channel link</li>
                    </ol>
                    <span>
                        Need more info? Check the{' '}
                        <a
                            rel="noreferrer"
                            target="_blank"
                            href={getDocumentationLink(
                                IntegrationType.TELEGRAM
                            )}
                        >
                            Telegram documentation
                        </a>{' '}
                    </span>
                    <div><b>Note:</b> The channel should start with the prefix "@".</div>
                </>
            );
        case IntegrationType.OPS_GENIE:
            return (
                <>
                    <ol>
                        <li>Go to your OpsGenie account</li>
                        <li>Open "Teams"</li>
                        <li>Create a new team or use existing</li>
                        <li>Select "Integrations" from team dashboard</li>
                        <li>Create a new API integration</li>
                    </ol>
                    <span>Note, only team API keys are supported.</span>
                    <br />
                    <span>
                        Need more info? Check the{' '}
                        <a
                            rel="noreferrer"
                            target="_blank"
                            href={getDocumentationLink(
                                IntegrationType.OPS_GENIE
                            )}
                        >
                            OpsGenie documentation
                        </a>{' '}
                    </span>
                </>
            );
        case IntegrationType.SLACK:
            return (
                <>
                    <ol>
                        <li>Go to "Apps & Integrations"</li>
                        <li>Go to "Manage"</li>
                        <li>Select "Custom Integrations"</li>
                        <li>Add an "Incoming WebHook" and copy the Url</li>
                        <li>Copy channel name</li>
                    </ol>

                    <span>
                        Need more info? Check the{' '}
                        <a
                            rel="noreferrer"
                            target="_blank"
                            href={getDocumentationLink(IntegrationType.SLACK)}
                        >
                            Slack documentation
                        </a>{' '}
                    </span>
                </>
            );
        case IntegrationType.WEB_HOOK:
            return (
                <>
                    <ol>
                        <li>Copy the Url of that should be triggered</li>
                    </ol>
                    <span>
                        Need more info? Check the{' '}
                        <a
                            rel="noreferrer"
                            target="_blank"
                            href={getDocumentationLink(
                                IntegrationType.WEB_HOOK
                            )}
                        >
                            Webhook documentation
                        </a>{' '}
                    </span>
                </>
            );
        case IntegrationType.SERVICE_NOW:
            return (
                <>
                    <span>
                        Check the{' '}
                        <a
                            rel="noreferrer"
                            target="_blank"
                            href={getDocumentationLink(
                                IntegrationType.SERVICE_NOW
                            )}
                        >
                            ServiceNow documentation
                        </a>{' '}
                    </span>
                </>
            );
    }
}

function getNotificationServicesConfigurationFields(type: IntegrationType) {
    switch (type) {
        case IntegrationType.PAGER_DUTY:
            return (
                <>
                    <Col xs={24} sm={24} md={12}>
                        <FormItem
                            name="serviceKey"
                            label="Service Key"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter service key!',
                                },
                            ]}
                        >
                            <Input placeholder="Enter your key here" />
                        </FormItem>
                    </Col>
                </>
            );
        case IntegrationType.VICTOR_OPS:
            return (
                <>
                    <Col xs={24} sm={24} md={12}>
                        <FormItem
                            name="restUrl"
                            label="REST Integration url"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter your url!',
                                },
                            ]}
                        >
                            <Input placeholder="Enter your url here" />
                        </FormItem>
                    </Col>
                </>
            );
        case IntegrationType.TELEGRAM:
            return (
                <>
                    <Col xs={24} sm={24} md={12}>
                        <FormItem
                            name="token"
                            label="Telegram BOT token"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter your bot token key!',
                                },
                            ]}
                        >
                            <Input placeholder="Enter your bot token key" />
                        </FormItem>
                    </Col>
                    <Col xs={24} sm={24} md={12}>
                        <FormItem
                            name="channel"
                            label="Channel"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter channel!',
                                },
                            ]}
                        >
                            <Input placeholder="Enter channel name here" />
                        </FormItem>
                    </Col>
                </>
            );
        case IntegrationType.OPS_GENIE:
            return (
                <>
                    <Col xs={24} sm={24} md={12}>
                        <FormItem
                            name="region"
                            label="Region"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please select region!',
                                },
                            ]}
                        >
                            <Select
                                placeholder="Select region"
                                options={[
                                    { value: 'EU', label: 'EU' },
                                    { value: 'US', label: 'US' },
                                ]}
                            ></Select>
                        </FormItem>
                    </Col>
                    <Col xs={24} sm={24} md={24}>
                        <FormItem
                            name="teams"
                            label="Teams"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter teams!',
                                },
                            ]}
                        >
                            <Select
                                placeholder="Type here and press enter to add a team"
                                mode="tags"
                            ></Select>
                        </FormItem>
                    </Col>
                    <Col xs={24} sm={24} md={12}>
                        <FormItem
                            name="apiKey"
                            label="Api key"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter your key!',
                                },
                            ]}
                        >
                            <Input placeholder="Enter your key here" />
                        </FormItem>
                    </Col>
                </>
            );
        case IntegrationType.SLACK:
            return (
                <>
                    <Col xs={24} sm={24} md={12}>
                        <FormItem
                            name="url"
                            label="Workspace url"
                            rules={[
                                {
                                    required: true,
                                    message:
                                        'Please enter link to your Slack workspace!',
                                },
                            ]}
                        >
                            <Input placeholder="Enter link to your Slack workspace" />
                        </FormItem>
                    </Col>
                    <Col xs={24} sm={24} md={12}>
                        <FormItem
                            name="channel"
                            label="Channel"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter channel!',
                                },
                            ]}
                        >
                            <Input placeholder="#enter-channel-name-here" />
                        </FormItem>
                    </Col>
                </>
            );
        case IntegrationType.WEB_HOOK:
            return (
                <>
                    <Col xs={24} sm={24} md={12}>
                        <FormItem
                            name="url"
                            label="Url"
                            rules={[
                                {
                                    required: true,
                                    message:
                                        'Please enter the url of that should be triggered!',
                                },
                            ]}
                        >
                            <Input placeholder="Enter the url of that should be triggered" />
                        </FormItem>
                    </Col>
                </>
            );
        case IntegrationType.SERVICE_NOW:
            return (
                <>
                    <Col xs={24} sm={24} md={12}>
                        <FormItem
                            name="user"
                            label="Username"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter your username!',
                                },
                            ]}
                        >
                            <Input placeholder="Enter your username" />
                        </FormItem>
                    </Col>
                    <Col xs={24} sm={24} md={12}>
                        <FormItem
                            name="password"
                            label="Password"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter your password!',
                                },
                            ]}
                        >
                            <Input placeholder="Enter your password" />
                        </FormItem>
                    </Col>
                    <Col xs={24} sm={24} md={12}>
                        <FormItem
                            name="businessService"
                            label="Service"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter the service!',
                                },
                            ]}
                        >
                            <Input placeholder="Enter the service" />
                        </FormItem>
                    </Col>
                    <Col xs={24} sm={24} md={12}>
                        <FormItem
                            name="cmdbCi"
                            label="Configuration Item"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter configuration item!',
                                },
                            ]}
                        >
                            <Input placeholder="Enter configuration item" />
                        </FormItem>
                    </Col>
                    <Col xs={24} sm={24} md={12}>
                        <FormItem
                            name="instance"
                            label="Instance"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter instance!',
                                },
                            ]}
                        >
                            <Input placeholder="Enter instance" />
                        </FormItem>
                    </Col>
                </>
            );
    }
}

export type NotificationServicesConfigurationProps = {
    form: FormInstance;
    integrationType: IntegrationType;
    editMode?: boolean;
};

function NotificationServicesConfiguration({
    form,
    integrationType,
    editMode = false,
}: NotificationServicesConfigurationProps) {
    return (
        <>
            <h3>Service configuration</h3>
            <Alert
                showIcon
                className="notification-services-alert"
                type="info"
                message={`How to setup notifications with ${getNotificationProviderName(
                    integrationType
                )}`}
                description={getNotificationServicesConfigurationHelp(
                    integrationType
                )}
                style={{
                    marginBottom: '10px',
                    marginTop: '10px',
                }}
            />
            <Row gutter={[24, 0]}>
                <Col xs={24} sm={24} md={12}>
                    <FormItem
                        name="name"
                        label="Integration name"
                        rules={[
                            {
                                required: true,
                                message:
                                    'Please enter name of this integration!',
                            },
                        ]}
                    >
                        <Input placeholder="Enter name of this integration" />
                    </FormItem>
                </Col>
                {getNotificationServicesConfigurationFields(integrationType)}
            </Row>
            <Divider />
            <Row gutter={[24, 0]}>
                <Col xs={24} sm={24} md={12}>
                    <NotificationServicesTestCredentialButton
                        form={form}
                        integrationType={integrationType}
                    />
                </Col>
                {editMode && (
                    <Col xs={24} sm={24} md={12}>
                        <FormItemInlineSwitch
                            name="isActive"
                            label="Enable integration"
                            valuePropName="checked"
                            justify={true}
                        >
                            <Switch />
                        </FormItemInlineSwitch>
                    </Col>
                )}
            </Row>
        </>
    );
}
