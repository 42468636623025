import React, { useEffect, useMemo } from 'react';
import { Col, Form, Input, Row, Select } from 'antd';
import FormFooter from '../../common/FormFooter';
import ModalDefaultForm from '../../common/ModalDefaultForm';
import Alert from '@severalnines/bar-frontend-components/build/lib/Feedback/Alert';
import CcCluster from '../../services/models/CcCluster';
import { SUPPORTED_EVENTS, SUPPORTED_FORMATS } from './enableAuditLogHelper';
import InputNumberWide from '../../common/DataEntry/InputNumberWide';
import useCreateJob from '../Jobs/useCreateJob';
import { CmonJobInstanceCommand } from '../../services/cmon/models/CmonJobInstance';
import { notifyJobCreationError } from '../Notifications/uiNotification';
import useClusterDatabaseUsers from '../Clusters/useClusterDatabaseUsers';
import { arrayUnique } from '../../common/filtering';

export default EnableAuditLogRegular;

export type EnableAuditLogRegularProps = {
    cluster: CcCluster;
    onSuccess?: () => void;
    onCancel?: () => void;
};
export type EnableAuditLogRegularValues = {
    events: string[];
    excludeUsers: string[];
    logPath: string;
    rotationSize: number;
    rotation: number;
};

function EnableAuditLogRegular({
    cluster,
    onSuccess,
    onCancel,
}: EnableAuditLogRegularProps) {
    const [form] = Form.useForm<EnableAuditLogRegularValues>();

    const { loading, send } = useCreateJob({
        clusterId: cluster?.clusterId,
        title: 'Setup Audit Logging',
        command: CmonJobInstanceCommand.SETUP_AUDIT_LOGGING.toLowerCase() as CmonJobInstanceCommand,
        onSuccess,
    });

    const handleSubmit = async (values: EnableAuditLogRegularValues) => {
        try {
            await send({
                action: 'enable',
                audit_events: values.events.toString(),
                clusterId: cluster?.clusterId,
                audit_file_rotate_size: values.rotationSize * 1024 * 1024,
                audit_file_rotations: values.rotation,
                audit_log_exclude_users: Array.isArray(values.excludeUsers)
                    ? values.excludeUsers.toString()
                    : '',
                audit_log_format: cluster?.isVendorMariaDb()
                    ? SUPPORTED_FORMATS[3]
                    : SUPPORTED_FORMATS[0],
                audit_log_path: values.logPath,
            });
        } catch (err: any) {
            notifyJobCreationError({
                size: 'large',
                content: 'Job failded.',
            });
        }
    };

    const { refresh, usersData, loading: loadUsers } = useClusterDatabaseUsers({
        clusterId: cluster?.clusterId,
    });
    useEffect(() => {
        refresh();
    }, []);

    const dbUsersNames = useMemo((): {
        value: string;
        label: string;
    }[] => {
        if (typeof usersData === 'object') {
            const options = Object.keys(usersData).map((key: any) => {
                return {
                    value: usersData[key].user,
                    label: usersData[key].user,
                };
            });
            return arrayUnique(options, (a, b) => a.value === b.value);
        } else {
            return [];
        }
    }, [usersData, loadUsers]);

    return (
        <ModalDefaultForm
            title="Enable Audit Log"
            form={form}
            footer={[]}
            onCancel={onCancel}
            width={450}
            defaultVisible={true}
        >
            <Form
                form={form}
                layout="vertical"
                onFinish={handleSubmit}
                initialValues={{
                    events: ['CONNECT'],
                    logPath: cluster?.isVendorMariaDb()
                        ? 'server_audit.log'
                        : 'audit.log',
                    rotationSize: 1024,
                    rotation: 5,
                }}
            >
                <Row gutter={[24, 0]}>
                    <Col span={24}>
                        <Form.Item
                            name="logPath"
                            label="Log Path"
                            rules={[
                                {
                                    required: true,
                                    message: 'Enter Log Path.',
                                },
                            ]}
                        >
                            <Input placeholder="Enter Log Path."></Input>
                        </Form.Item>
                    </Col>

                    <Col span={24}>
                        <Alert
                            showIcon
                            type="info"
                            message={
                                'The filename to store the audit log in the data directory (/var/log/mysql/). Optionally, it can contain a path relative to the data directory or an absolute path.'
                            }
                        />
                    </Col>
                    <Col span={24} style={{ margin: '1rem 0' }}>
                        <Alert
                            showIcon
                            type="warning"
                            message={
                                'Changing the default value will require a server restart.'
                            }
                        />
                    </Col>

                    <Col span={12}>
                        <Form.Item
                            name={'rotationSize'}
                            label={'Rotation Size in (MB)'}
                            rules={[
                                {
                                    required: true,
                                    message: 'Enter Rotation Size.',
                                },
                            ]}
                        >
                            <InputNumberWide
                                min={0}
                                placeholder="Rotation Size."
                            ></InputNumberWide>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name={'rotation'}
                            label={'Rotations'}
                            rules={[
                                {
                                    required: true,
                                    message: 'Enter Rotations.',
                                },
                            ]}
                        >
                            <InputNumberWide
                                min={0}
                                placeholder="Rotations."
                            ></InputNumberWide>
                        </Form.Item>
                    </Col>

                    <Col span={24}>
                        <Form.Item
                            name="events"
                            label={'Events'}
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter Event.',
                                },
                            ]}
                        >
                            <Select
                                mode="tags"
                                placeholder="Events"
                                options={SUPPORTED_EVENTS}
                            />
                        </Form.Item>
                    </Col>

                    <Col span={24}>
                        <Form.Item name="excludeUsers" label="Exclude Users">
                            <Select
                                mode="tags"
                                placeholder="Exclude Users"
                                options={dbUsersNames}
                            />
                        </Form.Item>
                    </Col>

                    <Col span={24}>
                        <Alert
                            showIcon
                            type="info"
                            message={
                                'This feature will be enabled on all nodes of this cluster.'
                            }
                        />
                    </Col>
                </Row>
                <FormFooter
                    loading={loading}
                    showCancelButton
                    onCancel={onCancel}
                    submitButtonText="Save"
                    showSubmitButton
                />
            </Form>
        </ModalDefaultForm>
    );
}
