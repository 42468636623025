import React, { useState } from 'react';
import AppEmpty from '../../common/Feedback/AppEmpty';
import { Space } from 'antd';
import CcCluster, {
    CcClusterTechnology,
} from '../../services/models/CcCluster';
import { CmonJobInstanceCommand } from '../../services/cmon/models/CmonJobInstance';
import TypographyText from '../../common/TypographyText';
import InstallCmonAgentsButton from './InstallCmonAgentsButton';
import JobProgress from '../Jobs/JobProgress';
import { CcNodeType } from '../../services/models/CcNode';
import CcPostgreSqlNode from '../../services/models/CcPostgreSqlNode';
import CcMySqlNode from '../../services/models/CcMySqlNode';
import Alert from '@severalnines/bar-frontend-components/build/lib/Feedback/Alert';
import CcJob, { CcJobStatus } from '../../services/models/CcJob';

export default AgentQueryMonitorEnabledWrapper;

export type AgentQueryMonitorEnabledWrapperProps = {
    cluster: CcCluster;
    children?: React.ReactNode;
};

function AgentQueryMonitorEnabledWrapper({
    cluster,
    children,
}: AgentQueryMonitorEnabledWrapperProps) {
    const [showContent, setShowContent] = useState<boolean>(
        cluster.getNodesByType(CcNodeType.CMON_AGENT).length > 0
    );

    const handleInstallationFinish = (job: CcJob) => {
        if (job.status === CcJobStatus.FINISHED) {
            setShowContent(true);
        }
    };
    return (
        <div>
            {showContent ? (
                children || null
            ) : (
                <JobProgress
                    command={CmonJobInstanceCommand.DEPLOY_CMONAGENTS}
                    cluster={cluster}
                    title="Installing query monitoring agent"
                    failedMessage="Installing query monitoring agent has failed"
                    successMessage="Installing query monitoring agent has finished successfully"
                    onFinish={handleInstallationFinish}
                    fakeProgress={true}
                    fallback={
                        <AppEmpty
                            loading={false}
                            title={'Agent based query monitoring not enabled'}
                            description={
                                <div>
                                    <TypographyText muted>
                                        Install the monitoring agents in order
                                        to use the query monitoring. A
                                        monitoring agent will be installed on
                                        each node. You can remove these agents
                                        later on.
                                    </TypographyText>
                                </div>
                            }
                            extra={
                                <Space direction="vertical" size={30}>
                                    {!cluster.areStatsEnabled()
                                        ? getStatsWarningMessage(cluster)
                                        : null}
                                    <InstallCmonAgentsButton
                                        cluster={cluster}
                                        type="primary"
                                        size="middle"
                                        disabled={!cluster.areStatsEnabled()}
                                    >
                                        Install monitoring agent
                                    </InstallCmonAgentsButton>
                                </Space>
                            }
                        />
                    }
                />
            )}
        </div>
    );
}

function getStatsWarningMessage(cluster: CcCluster) {
    let missingConfigNodes;
    let message;
    switch (cluster.getTechnology()) {
        case CcClusterTechnology.TECHNOLOGY_POSTGRESQL:
            missingConfigNodes = cluster
                .getDatabaseNodes()
                .filter(
                    (node) =>
                        !(node as CcPostgreSqlNode).isPgStatStatementsEnabled()
                )
                .map((node) => node.hostname);
            message = `Pg_stat_statements is not enabled on ${missingConfigNodes.join(
                ', '
            )}. Pg_stat_statements must be enabled before the query monitor can be installed`;
            break;
        case CcClusterTechnology.TECHNOLOGY_MYSQL:
            missingConfigNodes = cluster
                .getDatabaseNodes()
                .filter(
                    (node) =>
                        !(node as CcMySqlNode).isPerformanceSchemaEnabled()
                )
                .map((node) => node.hostname);
            message = `Performance_schema is not enabled on ${missingConfigNodes.join(
                ', '
            )}. Performance_schema must be enabled before the query monitor can be installed`;
            break;
    }
    return <Alert showIcon={true} type={'warning'} message={message} />;
}
