import React, { ReactNode } from 'react';
import useTopologyNodeTreeData from './useTopologyNodeTreeData';
import { Space } from 'antd';
import NodeTree, {
    NodeTreeDataNode,
    NodeTreeItemProps,
    NodeTreeProps,
} from '../../common/NodeTree';
import { TopologyItem } from './TopologyItem';
import DeleteButton from '../../common/General/DeleteButton';

export default TopologyNodeTree;

export interface NodeTreeTopologyNode extends NodeTreeDataNode {
    item?: TopologyItem;
    description?: ReactNode;
}

export type TopologyNodeTreeProps = Omit<NodeTreeProps, 'treeData'> & {
    itemProps: Omit<TopologyNodeTreeItemProps, 'dataNode'>;
    items: TopologyItem[];
};

function TopologyNodeTree({
    items,
    placeholderTree,
    itemProps,
    ...rest
}: TopologyNodeTreeProps) {
    const { treeData } = useTopologyNodeTreeData({
        items,
    });

    const renderNode = (node: NodeTreeTopologyNode) => {
        return <TopologyNodeTreeItem {...itemProps} dataNode={node} />;
    };
    return (
        (treeData && (
            <NodeTree
                {...rest}
                fitContent={true}
                treeData={treeData || []}
                placeholderTree={placeholderTree}
                renderNode={renderNode}
            />
        )) ||
        null
    );
}

export type TopologyNodeTreeItemProps = NodeTreeItemProps & {
    onDelete?: (dataNode: NodeTreeTopologyNode) => void;
};

function TopologyNodeTreeItem({
    dataNode,
    onDelete,
    ...rest
}: TopologyNodeTreeItemProps) {
    const handleDelete = () => {
        onDelete?.(dataNode);
    };
    return (
        (dataNode && (
            <NodeTree.Item
                dataNode={{
                    ...dataNode,
                    title: (
                        <span>
                            <Space>{dataNode.title}</Space>
                            <br />
                            <small>{dataNode.description}</small>
                        </span>
                    ),
                    extra: (
                        <Space>
                            <DeleteButton
                                className="TopologyNodeTree_Item_delete-replica-button"
                                data-testid="topology-item-delete-button"
                                onClick={handleDelete}
                            />
                        </Space>
                    ),
                }}
                {...rest}
            />
        )) ||
        null
    );
}

TopologyNodeTree.Item = TopologyNodeTreeItem;
