import MongoConfigurator, {
    MongoFormValues,
} from '../../Mongo/MongoConfigurator';
import merge from 'deepmerge';
import MongoNodeConfiguration, {
    MongoNodeConfigurationProps,
} from '../../Mongo/MongoNodeConfiguration';
import React from 'react';
import MongoImportClusterDetails, {
    MongoImportClusterDetailsProps,
} from './MongoImportClusterDetails';
import TopologySummary from '../../TopologySummary';
import { FormInstance } from 'antd/lib/form';
import ClusterNodesForm from '../../ClusterNodesForm';
import { Alert } from 'antd';

export default class MongoImportConfigurator extends MongoConfigurator {
    public static getDefaults(): MongoFormValues {
        return merge(MongoConfigurator.getDefaults(), {
            details: {
                vendor: 'percona',
            },
        });
    }

    public static getJobOptions(formValues: MongoFormValues): any {
        return merge(MongoConfigurator.getJobOptions(formValues), {
            job: {
                title: 'Import Mongo Cluster',
            },
        });
    }

    public static getJobData(formValues: MongoFormValues): any {
        const { topology, nodeConfig } = formValues;
        return merge(MongoConfigurator.getJobData(formValues), {
            replica_sets: null,
            server_address: `${topology?.[0].extraData.hostname}:${nodeConfig.serverPort}`,
        });
    }

    public static getImportDetailsStep(
        props: MongoImportClusterDetailsProps
    ): React.ReactNode {
        return <MongoImportClusterDetails {...props} />;
    }

    public static getNodeConfigurationStep(
        props: MongoNodeConfigurationProps
    ): React.ReactNode {
        return (
            <MongoNodeConfiguration
                hasDataDir={false}
                hasRepository={false}
                hasReplicaSetName={false}
                hasTemplate={false}
                hasMongoAuthDb={true}
                {...props}
            />
        );
    }

    public static getTopologyStep(form: FormInstance): React.ReactNode {
        return (
            <div>
                <ClusterNodesForm
                    form={form}
                    nodesInputProps={{ singleNode: true }}
                />
                <Alert
                    message="Specify one primary node of the replica set and ClusterControl will discover the rest of the replica set members."
                    type="info"
                    showIcon={true}
                />
            </div>
        );
    }

    public static getTopologySummary(form: FormInstance): React.ReactNode {
        return <TopologySummary singleNode={true} form={form} />;
    }
}
