import ActionsMenu, {
    ActionsMenuProps,
} from '../../common/Navigation/ActionsMenu';
import KeyManagementGenerateCertificateButton from './KeyManagementGenerateCertificateButton';
import KeyManagementImportCertificateButton from './KeyManagementImportCertificateButton';

export default KeyManagementGenerateActionMenu;

export type KeyManagementGenerateActionMenuProps = ActionsMenuProps & {
    list: any;
    onActionPerformed: () => void;
};

function KeyManagementGenerateActionMenu({
    list,
    onActionPerformed,
}: KeyManagementGenerateActionMenuProps) {
    return (
        <ActionsMenu
            items={[
                {
                    key: 'generate-key-1',
                    label: (
                        <KeyManagementGenerateCertificateButton
                            clientServer={false}
                            list={list}
                            onSuccess={onActionPerformed}
                        />
                    ),
                },
                {
                    key: 'generate-key-2',
                    label: (
                        <KeyManagementGenerateCertificateButton
                            clientServer={true}
                            list={list}
                            onSuccess={onActionPerformed}
                        />
                    ),
                },
                {
                    key: 'import-key',
                    label: (
                        <KeyManagementImportCertificateButton
                            onSuccess={onActionPerformed}
                            list={list}
                        />
                    ),
                },
            ]}
        />
    );
}
