import React from 'react';
import BackupWizardModal, {
    BackupWizardModalProps,
} from '../Wizard/BackupWizardModal';
import { BackupWizardStep } from '../Wizard/BackupWizard';
import { ElasticSnapshotRepositoryFormValues } from './ElasticSnapshotRepositoryFormWizard';

export default SnapshotRepositoryCreateModal;
export type SnapshotRepositoryCreateModalProps = BackupWizardModalProps & {
    onSuccess?: (fields: ElasticSnapshotRepositoryFormValues) => void;
    onCancel?: () => void;
};

function SnapshotRepositoryCreateModal({
    onSuccess,
    onCancel,
    ...rest
}: SnapshotRepositoryCreateModalProps) {
    return (
        <BackupWizardModal
            defaultVisible={true}
            activeStep={BackupWizardStep.FORM_CREATE_REPOSITORY}
            onRepositoryCreated={onSuccess}
            onCancel={onCancel}
            {...rest}
        />
    );
}
