import merge from 'deepmerge';
import React from 'react';
import MysqlGaleraConfigurator from '../../MysqlGalera/MysqlGaleraConfigurator';
import MysqlNodeConfiguration from '../../Mysql/MysqlNodeConfiguration';
import { MysqlFormValues } from '../../Mysql/MysqlConfigurator';
import MysqlAdvancedNodeConfiguration from '../../Mysql/MysqlAdvancedNodeConfiguration';
import MysqlGaleraNodeInfo from '../../MysqlGalera/MysqlGaleraNodeInfo';
import { Alert, Space } from 'antd';
import { CcClusterType } from '../../../../../services/models/CcCluster';
import NodeConfigurationSummary, {
    NodeConfigurationSummaryProps,
} from '../../NodeConfigurationSummary';
import Form from 'antd/lib/form';
import { FormInstance } from 'antd/es';
import ClusterNodesForm from '../../ClusterNodesForm';

export default class MysqlGaleraImportConfigurator extends MysqlGaleraConfigurator {
    public static getDefaults(): MysqlFormValues {
        return merge(MysqlGaleraConfigurator.getDefaults(), {
            details: {
                vendor: 'percona',
            },
            nodeConfig: {
                informationSchemaQueries: false,
                clusterAutoRecovery: false,
                nodeAutoRecovery: false,
                nodeAutoDetect: true,
            },
        });
    }

    public static getJobData(formValues: MysqlFormValues): any {
        const { nodeConfig } = formValues;

        return merge(MysqlGaleraConfigurator.getJobData(formValues), {
            enable_information_schema_queries:
                nodeConfig.informationSchemaQueries,
            enable_cluster_autorecovery: nodeConfig.clusterAutoRecovery,
            enable_node_autorecovery: nodeConfig.nodeAutoRecovery,
        });
    }

    public static getJobOptions(formValues: MysqlFormValues): any {
        return merge(MysqlGaleraConfigurator.getJobOptions(formValues), {
            job: {
                title: 'Import MySQL Galera Cluster',
            },
        });
    }

    public static getTopologyStep(form: FormInstance): React.ReactNode {
        return (
            <Form.Item noStyle shouldUpdate>
                {() => {
                    return (
                        <div>
                            <ClusterNodesForm
                                form={form}
                                nodesInputProps={{
                                    onlyPrimaries: !form?.getFieldValue([
                                        'nodeConfig',
                                        'nodeAutoDetect',
                                    ]),
                                    singleNode: true,
                                    formProps: {
                                        primaryExtra:
                                            'You need to add at least one primary node.',
                                    },
                                }}
                            />
                            <Alert
                                message={
                                    <span>
                                        If wsrep_node_incoming_address=AUTO then
                                        disable 'Automatic Node Discovery' in
                                        the previous 'Node Configuration' step
                                        and add all your primary nodes.
                                    </span>
                                }
                            />
                        </div>
                    );
                }}
            </Form.Item>
        );
    }

    public static getNodeConfigurationStep({
        form,
        ...rest
    }: any): React.ReactNode {
        return (
            <>
                <MysqlNodeConfiguration
                    hasRepository={false}
                    hasTemplate={false}
                    form={form}
                    clusterType={CcClusterType.TYPE_GALERA}
                    {...rest}
                />
                <Space direction="vertical" size={20}>
                    <MysqlAdvancedNodeConfiguration hasNodeAutodetect={true} />
                    <MysqlGaleraNodeInfo form={form} />
                </Space>
            </>
        );
    }

    public static getNodeConfigurationSummary(
        props: NodeConfigurationSummaryProps
    ): React.ReactNode {
        return (
            <NodeConfigurationSummary
                hasAdvancedNodeConfiguration
                hasNodeAutodetect
                {...props}
            />
        );
    }
}
