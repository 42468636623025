// import React from 'react';
// import useFeatureCheck from '../components/License/useFeatureCheck';
// import { Provider } from 'react-redux';
// import { createAppStore } from '../appReducer';

export default DebugUseFetch;

export type DebugUseFetchProps = {};

function DebugUseFetch() {
    // const { isFeatureEnabled } = useFeatureCheck();
    return <div></div>;
}

// function ProviderWrapper({ state, children }: any) {
//     return <Provider store={createAppStore(state)}>{children}</Provider>;
// }
