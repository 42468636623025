import React, { useEffect, useState, useMemo } from 'react';
import { Col, Row, Form, Space, Input, RadioChangeEvent } from 'antd';
import InfoIcon from '@severalnines/bar-frontend-components/build/lib/General/InfoIcon';
import CcCluster from '../../../../../services/models/CcCluster';
import { FormInstance } from 'antd/lib/form';
import useClusterDatabaseUsers from '../../../../Clusters/useClusterDatabaseUsers';
import AppSelect from '../../../../../common/DataEntry/AppSelect';
import { privilegeOptions } from '../createProxySQLWizardHelper';
import { arrayUnique } from '../../../../../common/filtering';
import Alert from '@severalnines/bar-frontend-components/build/lib/Feedback/Alert';
import AppRadio from '../../../../../common/DataEntry/AppRadio';
import { BLACKLISTED_DB_USERS } from '../../../../Nodes/NodeDetails/ProxySQLPage/ProxySQLPagesContent/ProxySQLUsers/proxySQLUserHelper';
import { CcNodeType } from '../../../../../services/models/CcNode';
import CcProxySqlNode from '../../../../../services/models/CcProxySqlNode';

export const dbNameValidator = async (rule: any, value: string) => {
    if (!/^[0-9a-zA-Z$%*_]+\.[0-9a-zA-Z$%*_]+$/.test(value)) {
        throw new Error(
            `Please type database name correctly, it should be *.* or database.table or string end with .*`
        );
    }

    return true;
};

export const PROXYSQL_CONFIGURATION_VALIDATION = [
    ['proxySQLConfiguration', 'administrationUser'],
    ['proxySQLConfiguration', 'administrationPassword'],
    ['proxySQLConfiguration', 'monitorUser'],
    ['proxySQLConfiguration', 'monitorPassword'],
];

export const PROXYSQL_CONFIGURATION_INITIAL = {
    proxySQLConfiguration: {
        administrationUser: 'proxysql-admin',
        monitorUser: 'proxysql-monitor',
        userType: 'createUser',
        dbName: '*.*',
    },
};

type CreateProxySQLConfigurationProps = {
    selectedCluster?: CcCluster;
    form: FormInstance;
    checkDbUsernameTouched: (touched: boolean) => void;
};

export default CreateProxySQLConfiguration;

function CreateProxySQLConfiguration({
    selectedCluster,
    form,
    checkDbUsernameTouched,
}: CreateProxySQLConfigurationProps) {
    const [userType, setUserType] = useState(
        form?.getFieldValue(['proxySQLConfiguration', 'userType'])
    );

    const isProxySqlClustered: boolean = useMemo(() => {
        const proxyNodes = selectedCluster
            ?.getNodesByType(CcNodeType.PROXYSQL)
            ?.filter((node: CcProxySqlNode) => node.isClustered);

        if (proxyNodes) {
            return (
                proxyNodes?.length > 0 &&
                form.getFieldValue(['proxySQLWhere', 'nativeClustering'])
            );
        } else {
            return false;
        }
    }, [selectedCluster]);

    const [isRequired, setIsRequired] = useState(false);
    const onTypeChange = (e: RadioChangeEvent) => {
        const type = e.target.value;
        setUserType(type);
        form.setFieldsValue({
            proxySQLConfiguration: {
                dbUsername: '',
                dbPassword: '',
                sqlPrivileges: [],
            },
        });
        setIsRequired(false);
    };

    const { refresh, usersData, loading } = useClusterDatabaseUsers({
        clusterId: selectedCluster?.clusterId,
    });
    useEffect(() => {
        refresh();
    }, []);

    const dbUsersNames = useMemo((): {
        value: string;
        label: string;
    }[] => {
        if (typeof usersData === 'object') {
            const options = Object.keys(usersData)
                .map((key: any) => {
                    return {
                        value: usersData[key].account.replace(/'/g, ''),
                        label: usersData[key].user,
                    };
                })
                ?.filter((option: string) => {
                    return !BLACKLISTED_DB_USERS.includes(option.label);
                });
            return arrayUnique(options, (a, b) => a.value === b.value);
        } else {
            return [];
        }
    }, [usersData, loading]);

    useEffect(() => {
        const dbUsernameValue = form?.getFieldValue([
            'proxySQLConfiguration',
            'dbUsername',
        ]);
        if (dbUsernameValue) {
            setIsRequired(true);
        }
    }, []);

    const onChangeDbName = (
        e: React.ChangeEvent<HTMLInputElement> | string
    ) => {
        if (
            userType === 'createUser' &&
            typeof e !== 'string' &&
            e?.target?.value !== ''
        ) {
            checkDbUsernameTouched(true);
            setIsRequired(true);
        } else if (typeof e === 'string' && e !== '') {
            checkDbUsernameTouched(true);
            setIsRequired(true);
        } else {
            checkDbUsernameTouched(false);
            setIsRequired(false);
        }
    };

    return (
        <div style={{ minHeight: 450 }}>
            <Row gutter={[24, 0]}>
                <Col span={24}>
                    <h3>Configuration</h3>
                </Col>
            </Row>
            <Row gutter={[24, 0]}>
                <Col span={12}>
                    <Form.Item
                        name={['proxySQLConfiguration', 'administrationUser']}
                        label={
                            <Space>
                                Administration user
                                <InfoIcon
                                    info={
                                        'Set administration user to "admin" if you prefer to use SSH for sampling stats and administrative commands. Using SSH is more resource consuming. For any other username than "admin" ClusterControl will use the native MySQL protocol for the stats sampling and administrative commands. For more information please visit the user manual.'
                                    }
                                />
                            </Space>
                        }
                        rules={[
                            {
                                required: !isProxySqlClustered,
                                message: 'Enter Administration User.',
                            },
                        ]}
                    >
                        <Input
                            disabled={isProxySqlClustered}
                            placeholder="Enter Administration User."
                        ></Input>
                    </Form.Item>
                    {!isProxySqlClustered && (
                        <Alert
                            closable
                            message={`Requires port 6032 to be open in the firewall/security group.`}
                            type="info"
                            showIcon
                        />
                    )}
                </Col>
                <Col span={12}>
                    <Form.Item
                        name={[
                            'proxySQLConfiguration',
                            'administrationPassword',
                        ]}
                        label={<Space>Administration password</Space>}
                        rules={[
                            {
                                required: !isProxySqlClustered,
                                message: 'Enter Administration Password.',
                            },
                        ]}
                    >
                        <Input.Password
                            disabled={isProxySqlClustered}
                            placeholder="Enter Administration Password."
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        name={['proxySQLConfiguration', 'monitorUser']}
                        label={<Space>Monitor user</Space>}
                        rules={[
                            {
                                required: !isProxySqlClustered,
                                message: 'Enter Monitor User.',
                            },
                        ]}
                    >
                        <Input
                            disabled={isProxySqlClustered}
                            placeholder="Enter Monitor User."
                        ></Input>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        name={['proxySQLConfiguration', 'monitorPassword']}
                        label={<Space>Monitor password</Space>}
                        rules={[
                            {
                                required: !isProxySqlClustered,
                                message: 'Enter Monitor Password.',
                            },
                        ]}
                    >
                        <Input.Password
                            disabled={isProxySqlClustered}
                            placeholder="Enter Monitor Password."
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={[24, 0]}>
                <Col span={24}>
                    <h3>
                        Database user (Optional){' '}
                        {userType === 'existingUser' && (
                            <InfoIcon
                                info={
                                    <span>
                                        The user must exist on the DB nodes, and
                                        allowed access from the ProxySQL server.
                                        If you have more users then those can be
                                        added later in the ProxySQL view.
                                    </span>
                                }
                            />
                        )}
                    </h3>
                </Col>
                <Col span={24}>
                    <Form.Item
                        name={['proxySQLConfiguration', 'userType']}
                        label=""
                    >
                        <AppRadio.Group onChange={onTypeChange}>
                            <AppRadio.Button value="existingUser">
                                Existing user
                            </AppRadio.Button>

                            <AppRadio.Button value="createUser">
                                Create new user
                            </AppRadio.Button>
                        </AppRadio.Group>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        name={['proxySQLConfiguration', 'dbUsername']}
                        label={<Space>Database username</Space>}
                        rules={[
                            { required: isRequired },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (
                                        getFieldValue([
                                            'proxySQLConfiguration',
                                            'administrationUser',
                                        ]) !== value
                                    ) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(
                                        'You cannot use the same DB User as the ProxySQL Admin User.'
                                    );
                                },
                            }),
                        ]}
                    >
                        <AppSelect
                            options={
                                userType === 'existingUser' ? dbUsersNames : []
                            }
                            editable={
                                userType === 'existingUser' ? false : true
                            }
                            onChange={(e) => onChangeDbName(e)}
                            placeholder="Enter Database Username."
                            loading={
                                userType === 'existingUser'
                                    ? loading
                                    : undefined
                            }
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        name={['proxySQLConfiguration', 'dbPassword']}
                        label={<Space>Database password</Space>}
                        rules={[
                            {
                                required: isRequired,
                                message: '',
                            },
                        ]}
                    >
                        <Input.Password placeholder="Enter Database Password." />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        name={['proxySQLConfiguration', 'dbName']}
                        label={<Space>Database name</Space>}
                        rules={[
                            {
                                validator: dbNameValidator,
                            },
                        ]}
                        hidden={userType === 'existingUser'}
                    >
                        <Input placeholder="Enter Database name."></Input>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        name={['proxySQLConfiguration', 'sqlPrivileges']}
                        label={<Space>MySQL privilege(s)</Space>}
                        hidden={userType === 'existingUser'}
                        rules={
                            userType !== 'existingUser'
                                ? [
                                      {
                                          required: isRequired,
                                          message: '',
                                      },
                                  ]
                                : undefined
                        }
                    >
                        <AppSelect
                            mode="multiple"
                            allowClear
                            placeholder="Please Select MySQL Privilege(s)."
                            options={privilegeOptions}
                            placement="topLeft"
                        />
                    </Form.Item>
                </Col>
            </Row>
        </div>
    );
}
