import React, { useRef } from 'react';
import { Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import SpaceWide from '../../../common/SpaceWide';
import ButtonWithForm from '../../../common/General/ButtonWithForm';
import NotificationServicesTable from './NotificationServicesTable';
import NotificationServicesModalAdd from './NotificationServicesModalAdd';
import { NotificationServicesTableApi } from './NotificationServicesTable';

export default NotificationServicesPage;

export type NotificationServicesPageProps = {};

function NotificationServicesPage({}: NotificationServicesPageProps) {
    const listRef = useRef<NotificationServicesTableApi>();
    const handleSuccess = () => {
        listRef.current?.refresh();
    };
    return (
        <SpaceWide direction="vertical" size={20}>
            <SpaceWide direction="vertical" align="end">
                <ButtonWithForm
                    button={
                        <Button type="primary" icon={<PlusOutlined />}>
                            Add new integration
                        </Button>
                    }
                    form={
                        <NotificationServicesModalAdd
                            onSuccess={handleSuccess}
                        />
                    }
                />
            </SpaceWide>
            <NotificationServicesTable ref={listRef} />
        </SpaceWide>
    );
}
