import React from 'react';
import CcBackupStorageLocation from '../../../services/models/CcBackupStorageLocation';
import CcBackupCloudStorageLocation from '../../../services/models/CcBackupCloudStorageLocation';
import CloudProviderTypeFormat from '../../Integrations/CloudProviderTypeFormat';
import { Space } from 'antd';
import { HddOutlined } from '@ant-design/icons';
import CcBackupHostStorageLocation from '../../../services/models/CcBackupHostStorageLocation';

export default StorageLocationFormat;

export type StorageLocationFormatProps = {
    storageLocation: CcBackupStorageLocation;
    children?: React.ReactNode;
};

function StorageLocationFormat({
    storageLocation,
    children,
}: StorageLocationFormatProps) {
    return storageLocation.isTypeCloud() ? (
        <CloudProviderTypeFormat
            provider={
                (storageLocation as CcBackupCloudStorageLocation).provider
            }
        >
            {children}
        </CloudProviderTypeFormat>
    ) : (
        <Space>
            <HddOutlined />
            <span>
                Local storage (
                {children ||
                    (storageLocation as CcBackupHostStorageLocation)
                        .storageHost}
                )
            </span>
        </Space>
    );
}
