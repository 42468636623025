import { useEffect, useState, useRef, useCallback } from 'react';

enum TIMER_STATUS {
    STARTED = 'Started',
    STOPPED = 'Stopped',
}

type AutoRefreshHandler = {
    time: number;
    repeated?: boolean;
    runAtTheBeginning?: boolean;
    onRefresh?: () => void;
};

export type UseCountdownTimerProps = {
    timeInMs?: number;
    repeat?: boolean;
    onRefresh?: () => void;
};

export default function useCountdownTimer({
    timeInMs = 0,
    repeat = true,
    onRefresh,
}: UseCountdownTimerProps = {}) {
    const [time, setTime] = useState(timeInMs);

    // general repeated
    const [autoRepeat, setAutoRepeat] = useState(repeat);

    // auto refresh status for autoRefresh Function
    const [autoRefreshStatus, setAutoRefreshStatus] = useState(false);

    const [secondsRemaining, setSecondsRemaining] = useState(
        Math.floor(time / 1000)
    );
    const [status, setStatus] = useState(TIMER_STATUS.STOPPED);

    const onRefreshRef = useRef(onRefresh);

    const start = useCallback(() => {
        setStatus(TIMER_STATUS.STARTED);
    }, []);
    const stop = useCallback(() => {
        setStatus(TIMER_STATUS.STOPPED);
    }, []);
    const reset = useCallback(() => {
        setStatus(TIMER_STATUS.STOPPED);
        setSecondsRemaining(Math.floor(time / 1000));
    }, []);
    const handleRefreshAgain = useCallback(() => {
        stop();
        reset();
        start();
    }, []);

    const autoRefresh = useCallback(
        ({
            time,
            repeated = true,
            runAtTheBeginning = false,
            onRefresh,
        }: AutoRefreshHandler) => {
            setTime(time);
            if (repeated) {
                setAutoRepeat(false);
                setAutoRefreshStatus(true);
            } else {
                setAutoRepeat(false);
                setAutoRefreshStatus(false);
            }

            setSecondsRemaining(Math.floor(time / 1000));
            start();

            if (onRefresh) {
                onRefreshRef.current = onRefresh;
                runAtTheBeginning && onRefreshRef.current();
            }
        },
        []
    );

    useEffect(() => {
        if (timeInMs !== 0) {
            start();
        }
    }, []);

    useInterval(
        () => {
            if (secondsRemaining > 0) {
                setSecondsRemaining(secondsRemaining - 1);
            } else if (!autoRefreshStatus && autoRepeat) {
                handleRefreshAgain();
                onRefreshRef.current && onRefreshRef.current();
            } else if (autoRefreshStatus) {
                autoRefresh({ time });
                onRefreshRef.current && onRefreshRef.current();
            } else {
                stop();
                onRefreshRef.current && onRefreshRef.current();
            }
        },
        status === TIMER_STATUS.STARTED ? 1000 : null
        // passing null stops the interval
    );
    return {
        secondsRemaining,
        start,
        stop,
        reset,
        autoRefresh,
    };
}

function useInterval(callback: any, delay: any) {
    const savedCallback: any = useRef();

    // Remember the latest callback.
    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    // Set up the interval.
    useEffect(() => {
        function tick() {
            savedCallback.current();
        }
        if (delay !== null) {
            let id = setInterval(tick, delay);
            return () => clearInterval(id);
        }
    }, [delay]);
}
