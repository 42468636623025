import React, { useMemo, useCallback } from 'react';
import { Col, Row, Input } from 'antd';
import { Form } from 'antd';
import { FormInstance } from 'antd/lib/form';
import CcCluster from '../../../../services/models/CcCluster';
import { getHostWithDesc } from '../../../Nodes/NodeFormat';
import FormItemInlineSwitch from '../../../../common/DataEntry/FormItemInlineSwitch';
import { CcNodeType } from '../../../../services/models/CcNode';
import SshCheckableNodesAutocomplete from '../../../../common/DataEntry/SshCheckeableNodesAutocomplete';
import { DefaultOptionType } from 'rc-select/lib/Select';
import { getTopologyValidator } from '../../loadBalancerHelpers';

type CreateGarbdConfigurationProps = {
    selectedCluster?: CcCluster;
    form: FormInstance;
};

export const GARBD_CONFIG_INITIAL = {
    garbdConfig: {
        cmdline: '/usr/bin/garbd  --cfg /etc/garbd.cnf  --daemon',
        firewall: true,
        appArmor: true,
    },
};

export default CreateGarbdConfiguration;

function CreateGarbdConfiguration({
    selectedCluster,
    form,
}: CreateGarbdConfigurationProps) {
    const getOptions = useCallback((): DefaultOptionType[] | undefined => {
        return selectedCluster
            ?.getHosts()
            .filter(
                (h) =>
                    !h.types.includes(CcNodeType.CONTROLLER) &&
                    !h.types.includes(CcNodeType.GALERA) &&
                    !h.types.includes(CcNodeType.MYSQL) &&
                    !h.types.includes(CcNodeType.POSTGRESQL) &&
                    !h.types.includes(CcNodeType.NDB)
            )
            .map((host) => {
                return {
                    value: `${host.hostname}`,
                    label: getHostWithDesc(host),
                };
            });
    }, [selectedCluster]);

    const options: DefaultOptionType[] | undefined = useMemo(
        () => getOptions(),
        [selectedCluster]
    );

    return (
        <div style={{ minHeight: 450 }}>
            <Row>
                <Col span={24}>
                    <h3>Configuration</h3>
                </Col>
            </Row>
            <Row gutter={[24, 0]}>
                <Col span={24}>
                    <Form.Item
                        name={['garbdConfig', 'address']}
                        label={'Server Address'}
                        rules={[
                            {
                                required: true,
                                message: 'Please select server address.',
                            },
                        ]}
                    >
                        <SshCheckableNodesAutocomplete
                            clusterId={selectedCluster?.clusterId}
                            emptyState={null}
                            singleNode={true}
                            formProps={{
                                primaryTitle: null,
                                primaryFormItemProps: {
                                    withLessMarginBottom: true,
                                },
                            }}
                            autocompleteOptions={options}
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        name={['garbdConfig', 'cmdline']}
                        label={'CmdLine'}
                        rules={[
                            {
                                required: true,
                                message: 'Enter CmdLine.',
                            },
                        ]}
                    >
                        <Input placeholder="Enter CmdLine."></Input>
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={[24, 0]}>
                <Col span={24}>
                    <h3>Security configuration</h3>
                </Col>
            </Row>
            <Row gutter={[24, 0]}>
                <Col span={12}>
                    <Row justify="space-between" align="middle">
                        <Col span={24}>
                            <FormItemInlineSwitch
                                justify={true}
                                label="Firewall"
                                name={['garbdConfig', 'firewall']}
                                labelStrong={false}
                                valuePropName="checked"
                            />
                        </Col>
                    </Row>
                    <Row justify="space-between" align="middle">
                        <Col span={24}>
                            <FormItemInlineSwitch
                                justify={true}
                                label="AppArmor/SELinux"
                                name={['garbdConfig', 'appArmor']}
                                labelStrong={false}
                                valuePropName="checked"
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    );
}

export function getCreateGarbdConfigurationValidation(form: FormInstance) {
    return [
        ['garbdConfig', 'address'],
        ['garbdConfig', 'cmdline'],
        getTopologyValidator(form, ['garbdConfig', 'address']),
    ];
}
