import React, { useMemo, useState } from 'react';

export default ButtonWithForm;

export type ButtonWithFormProps = {
    button: React.ReactNode;
    form: React.ReactNode;
};

function ButtonWithForm({ form, button }: ButtonWithFormProps) {
    const [visible, setVisible] = useState(false);
    const handleClick = (e: any) => {
        setVisible(true);
        (button as React.ReactElement)?.props?.onClick?.(e);
    };
    const handleCancel = (...args: any) => {
        setVisible(false);
        (form as React.ReactElement)?.props?.onCancel?.(...args);
    };
    const handleSuccess = (...args: any) => {
        setVisible(false);
        (form as React.ReactElement)?.props?.onSuccess?.(...args);
    };
    const buttonRender = useMemo(
        () =>
            React.cloneElement(button as React.ReactElement, {
                ...(button as React.ReactElement).props,
                onClick: handleClick,
            }),
        [button]
    );
    return (
        <>
            {buttonRender}
            {visible
                ? React.cloneElement(form as React.ReactElement, {
                      ...(form as React.ReactElement).props,
                      onSuccess: handleSuccess,
                      onCancel: handleCancel,
                  })
                : null}
        </>
    );
}
