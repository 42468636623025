import { Button } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';
import ButtonWithForm from '../../common/General/ButtonWithForm';
import KeyManagementCreateFolderModal from './KeyManagementCreateFolderModal';

export type KeyManagementCreateFolderButtonProps = {
    onSuccess?: (folderName: string) => void;
};

export default KeyManagementCreateFolderButton;

function KeyManagementCreateFolderButton({
    onSuccess,
}: KeyManagementCreateFolderButtonProps) {
    const handleActionSuccess = (folderName: string) => {
        onSuccess?.(folderName);
    };
    return (
        <ButtonWithForm
            button={
                <Button size={'small'} icon={<PlusCircleOutlined />}>
                    Create folder
                </Button>
            }
            form={
                <KeyManagementCreateFolderModal
                    onSuccess={handleActionSuccess}
                />
            }
        ></ButtonWithForm>
    );
}
