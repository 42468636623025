import React, { useState, useRef, useMemo } from 'react';
import ModalDefault from '../../../../../../common/ModalDefault';
import { notifyConfirmCloseWindow } from '../../../../../Notifications/uiNotification';
import MotionShake, {
    MotionApi,
} from '../../../../../../common/motion/MotionShake';
import WizardForm from '@severalnines/bar-frontend-components/build/lib/Navigation/WizardForm';
import WizardFormConfiguration from '@severalnines/bar-frontend-components/build/lib/Navigation/Wizard/WizardFormConfiguration';
import { Form } from 'antd';
import ProxySQLRulesConfiguration, {
    RULES_CONFIGURATION_VALIDATION,
} from './ProxySQLRulesConfiguration';
import ProxySQLRulesType from './ProxySQLRulesType';
import ProxySQLRulesSetting from './ProxySQLRulesSetting';
import ProxySQLRulesAdvSetting from './ProxySQLRulesAdvSetting';
import ProxySQLRulesPreview from './ProxySQLRulesPreview';
import {
    ProxySqlEndPointRuleData,
    prepareCreateRuleFormData,
    prepareEditRuleFormData,
    prepareInitialValue,
    TopQueryDataType,
    prepareCacheQueryData,
} from './proxySQLRulesHelper';
import CmonProxysqlService from '../../../../../../services/cmon/CmonProxysqlService';
import CcCluster from '../../../../../../services/models/CcCluster';
import CcProxySqlNode from '../../../../../../services/models/CcProxySqlNode';
import {
    notifyError,
    notifyOperationSuccess,
    NotifyType,
} from '../../../../../Notifications/uiNotification';
import rulesCacheQuerySteps from './RulesCacheQuery/rulesCacheQuerySteps';

export default ProxySQLRulesWizardModal;

export enum ProxySQLRulesFormSteps {
    CONFIGURATION = 'Configuration',
    TYPE = 'Type',
    SETTINGS = 'Settings',
    ADV_SETTINGS = 'AdvancedSettings',
    PREVIEW = 'Preview',
}

export type ProxySQLRulesWizardModalProps = {
    showModalHandler?: () => void;
    onSuccess?: () => void;
    onCancel?: () => void;
    onError?: (err: Error) => void;
    changeVisible?: (value: boolean) => void;
    visible?: boolean;
    title?: string;
    nextQueryRuleId?: number;
    cluster: CcCluster;
    node: CcProxySqlNode;
    edit?: boolean;
    topQueryCreate?: boolean;
    topQueryCache?: boolean;
    topQueryData?: TopQueryDataType;
    rule?: ProxySqlEndPointRuleData;
    hostGroupList: { value: string; label: string }[] | undefined;
};

function ProxySQLRulesWizardModal({
    onCancel,
    onSuccess,
    onError,
    title = '',
    nextQueryRuleId,
    cluster,
    node,
    edit,
    rule,
    hostGroupList,
    topQueryCreate,
    topQueryCache,
    topQueryData,
}: ProxySQLRulesWizardModalProps) {
    const animationRef = useRef<MotionApi | undefined>();

    const [confirmClose, setConfirmClose] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleFormTouchedChange = (touched: boolean) => {
        setConfirmClose(touched);
    };

    const handleCancel = () => {
        if (confirmClose) {
            notifyConfirmCloseWindow({
                onOk: () => {
                    onCancel?.();
                },
            });
        } else {
            onCancel?.();
        }
    };

    const handleFormStepErrorInsist = (err: Error) => {
        if (animationRef.current) {
            animationRef.current.animate();
        }
    };

    const [form] = Form.useForm();
    const handleSubmit = async () => {
        const { rulesConfiguration } = form.getFieldsValue(true);
        try {
            setLoading(true);
            if (edit) {
                await CmonProxysqlService.updatequeryrule({
                    clusterid: cluster.clusterId,
                    hostName: node.hostname,
                    port: node.port,
                    queryRule: {
                        ...prepareEditRuleFormData(form),
                        new_rule_id: +rulesConfiguration.ruleId,
                        rule_id: +rule?.ruleId,
                    },
                });
            } else {
                await CmonProxysqlService.insertqueryrule({
                    clusterid: cluster.clusterId,
                    hostName: node.hostname,
                    port: node.port,
                    queryRule: topQueryCache
                        ? prepareCacheQueryData(form, topQueryData)
                        : prepareCreateRuleFormData(form),
                });
            }
            notifyOperationSuccess({
                type: NotifyType.TOAST,
                title: (
                    <span>Rule {edit ? 'edited' : 'added'} successfully</span>
                ),
            });
            setLoading(false);
            onSuccess?.();
        } catch (error) {
            setLoading(false);
            notifyError({
                content: error.message,
            });
            onError?.(error);
        }
    };

    const steps = useMemo(
        () =>
            !topQueryCache
                ? [
                      <WizardForm.Step
                          key={ProxySQLRulesFormSteps.CONFIGURATION}
                          title="Configuration"
                          subTitle=" "
                          validate={RULES_CONFIGURATION_VALIDATION}
                      >
                          <ProxySQLRulesConfiguration
                              hostGroupList={hostGroupList}
                          />
                      </WizardForm.Step>,
                      <WizardForm.Step
                          key={ProxySQLRulesFormSteps.TYPE}
                          title="Type"
                          subTitle=" "
                      >
                          <ProxySQLRulesType form={form} />
                      </WizardForm.Step>,
                      <WizardForm.Step
                          key={ProxySQLRulesFormSteps.SETTINGS}
                          title="Settings"
                          subTitle=" "
                      >
                          <ProxySQLRulesSetting />
                      </WizardForm.Step>,
                      <WizardForm.Step
                          key={ProxySQLRulesFormSteps.ADV_SETTINGS}
                          title="Advanced settings"
                          subTitle=" "
                      >
                          <ProxySQLRulesAdvSetting />
                      </WizardForm.Step>,
                      <WizardForm.Step
                          key={ProxySQLRulesFormSteps.PREVIEW}
                          title="Preview"
                          subTitle=" "
                      >
                          <ProxySQLRulesPreview form={form} />
                      </WizardForm.Step>,
                  ]
                : rulesCacheQuerySteps(hostGroupList, form),
        [topQueryCache]
    );
    return (
        <ModalDefault
            title={title}
            animateHeight={true}
            visible={true}
            onCancel={handleCancel}
            modalRender={(content) => (
                <MotionShake ref={animationRef}>{content}</MotionShake>
            )}
        >
            <WizardFormConfiguration
                form={form}
                onSubmit={handleSubmit}
                loading={loading}
                onCancel={onCancel}
                initialValues={prepareInitialValue(
                    edit,
                    rule,
                    nextQueryRuleId,
                    topQueryCreate,
                    topQueryCache,
                    topQueryData
                )}
                onStepErrorInsist={handleFormStepErrorInsist}
                onTouchedChange={handleFormTouchedChange}
                steps={steps}
            />
        </ModalDefault>
    );
}
