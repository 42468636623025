import React, { useEffect } from 'react';
import SpaceWide from '../../common/SpaceWide';
import './AlarmsListView.less';
import useCurrentAlarmsList, {
    getIgnoredAlarmsFilter,
} from './useCurrentAlarmsList';
import AppSpin from '../../common/General/AppSpin';
import StatusFormat from '@severalnines/bar-frontend-components/build/lib/Format/StatusFormat';
import { getAlarmSeverityFormatType } from './AlarmSeverityFormat';
import AlarmDetailsButton from './AlarmDetailsButton';
import AppEmpty from '../../common/Feedback/AppEmpty';
import TypographyText from '@severalnines/bar-frontend-components/build/lib/General/TypographyText';
import ActivityCenterAlarmsLink from '../ActivityCenter/ActivityCenterAlarmsLink';
import AppDateFormat from '../../common/AppDateFormat';

export default AlarmsListView;

export type AlarmsListViewProps = {};

function AlarmsListView({}: AlarmsListViewProps) {
    const {
        list: alarms,
        loading: loadingAlarms,
        refresh: refreshAlarms,
        total,
    } = useCurrentAlarmsList({
        pageSize: 5,
        filters: [getIgnoredAlarmsFilter()],
        useCache: true,
    });
    useEffect(() => {
        (async () => {
            await refreshAlarms();
        })();
    }, []);
    return (
        <AppSpin
            spinning={loadingAlarms}
            showFallback={true}
            style={{ minHeight: '203px' }}
        >
            {alarms ? (
                !alarms.length ? (
                    <AppEmpty description={'No alarms'} />
                ) : (
                    <SpaceWide className="AlarmsListView" direction="vertical">
                        {alarms.map((alarm) => (
                            <AlarmDetailsButton
                                type="link"
                                alarm={alarm}
                                className="AlarmListView_item-button"
                            >
                                <div style={{ width: '100%' }}>
                                    <SpaceWide
                                        key={alarm.alarmId}
                                        direction="vertical"
                                        size={0}
                                    >
                                        <SpaceWide justify="space-between">
                                            <StatusFormat
                                                status={getAlarmSeverityFormatType(
                                                    alarm.severityName
                                                )}
                                            >
                                                {alarm.title}
                                            </StatusFormat>
                                            <TypographyText muted={true}>
                                                <AppDateFormat fromNow={true}>
                                                    {alarm.reported
                                                        ? new Date(
                                                              alarm.reported
                                                          )
                                                        : undefined}
                                                </AppDateFormat>
                                            </TypographyText>
                                        </SpaceWide>

                                        <TypographyText ellipsis={true}>
                                            {alarm.recommendation}
                                        </TypographyText>
                                    </SpaceWide>
                                </div>
                            </AlarmDetailsButton>
                        ))}
                        {total && alarms && total > alarms?.length ? (
                            <SpaceWide key={'more'} justify="right">
                                <ActivityCenterAlarmsLink>
                                    ... {total - alarms.length} more unmuted
                                    alarms
                                </ActivityCenterAlarmsLink>
                            </SpaceWide>
                        ) : null}
                    </SpaceWide>
                )
            ) : null}
        </AppSpin>
    );
}
