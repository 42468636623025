import React from 'react';
import CcCluster, { CcClusterState } from '../../services/models/CcCluster';
import { Space } from 'antd';

import StatusFormat, {
    StatusFormatProps,
    StatusFormatStatus,
    StatusFormatType,
} from '@severalnines/bar-frontend-components/build/lib/Format/StatusFormat';
import MaintenanceFormat from '../Maintenance/MaintenanceFormat';

export default ClusterStateFormat;

export function getClusterStateFormatType(state?: CcClusterState) {
    switch (state) {
        case CcClusterState.CLUSTER_STARTED:
            return StatusFormatStatus.success;
        case CcClusterState.CLUSTER_FAILURE:
            return StatusFormatStatus.error;
        case CcClusterState.CLUSTER_UNKNOWN:
        case CcClusterState.CLUSTER_DEGRADED:
        case CcClusterState.CLUSTER_MGMD_NO_CONTACT:
        case CcClusterState.CLUSTER_NOT_STARTED:
        case CcClusterState.CLUSTER_SHUTTING_DOWN:
        case CcClusterState.CLUSTER_RECOVERING:
        case CcClusterState.CLUSTER_STOPPED:
            return StatusFormatStatus.warning;
        case CcClusterState.CLUSTER_STARTING:
        default:
            return StatusFormatStatus.info;
    }
}

export function getClusterStateFormatText(state?: CcClusterState) {
    switch (state) {
        case CcClusterState.CLUSTER_STARTED:
            return 'Operational';
        case CcClusterState.CLUSTER_FAILURE:
            return 'Failed';
        case CcClusterState.CLUSTER_DEGRADED:
            return 'Degraded';
        case CcClusterState.CLUSTER_MGMD_NO_CONTACT:
            return 'No Contact';
        case CcClusterState.CLUSTER_NOT_STARTED:
            return 'Not Started';
        case CcClusterState.CLUSTER_SHUTTING_DOWN:
            return 'Shutting Down';
        case CcClusterState.CLUSTER_RECOVERING:
            return 'Recovering';
        case CcClusterState.CLUSTER_STARTING:
            return 'Starting';
        case CcClusterState.CLUSTER_STOPPED:
            return 'Stopped';
        case CcClusterState.CLUSTER_UNKNOWN:
        default:
            return 'Unknown state';
    }
}

export function getClusterStateGroup(status?: CcClusterState) {
    switch (status) {
        case CcClusterState.CLUSTER_FAILURE:
        case CcClusterState.CLUSTER_MGMD_NO_CONTACT:
        case CcClusterState.CLUSTER_NOT_STARTED:
        case CcClusterState.CLUSTER_RECOVERING:
        case CcClusterState.CLUSTER_SHUTTING_DOWN:
            return [
                CcClusterState.CLUSTER_FAILURE,
                CcClusterState.CLUSTER_MGMD_NO_CONTACT,
                CcClusterState.CLUSTER_NOT_STARTED,
                CcClusterState.CLUSTER_RECOVERING,
                CcClusterState.CLUSTER_SHUTTING_DOWN,
            ];
        case CcClusterState.CLUSTER_STARTED:
            return [CcClusterState.CLUSTER_STARTED];
        case CcClusterState.CLUSTER_DEGRADED:
            return [CcClusterState.CLUSTER_DEGRADED];
        case CcClusterState.CLUSTER_STOPPED:
            return [CcClusterState.CLUSTER_STOPPED];
        case CcClusterState.CLUSTER_UNKNOWN:
        case CcClusterState.CLUSTER_STARTING:
            return [
                CcClusterState.CLUSTER_UNKNOWN,
                CcClusterState.CLUSTER_STARTING,
            ];
        default:
            return [];
    }
}

export type ClusterStateFormatProps = StatusFormatProps & {
    cluster: CcCluster;
    type?: StatusFormatType;
    showMaintenance?: boolean;
};

function ClusterStateFormat({
    cluster,
    showMaintenance,
    ...rest
}: ClusterStateFormatProps) {
    return (
        <Space>
            <StatusFormat
                className="ClusterStateFormat"
                status={getClusterStateFormatType(cluster.state)}
                text={getClusterStateFormatText(cluster.state)}
                {...rest}
            />
            {showMaintenance ? <MaintenanceFormat cluster={cluster} /> : null}
        </Space>
    );
}
