import IMonitorDataProcessor from './IMonitorDataProcessor';
import { MonitorDataPoint } from './MonitorBox';
import { collectionUniqueBy } from '../../common/filtering';
import CcPrometheusExporter from '../../services/models/CcPrometheusExporter';
import { MonitorTarget } from './Monitor';
import { getObjectValueByPath } from '../../common/objectHelpers';
import { targetsToExporters } from './monitorUtils';
import CcPrometheusNode from '../../services/models/CcPrometheusNode';

export interface PrometheusResultDataResultItem {
    metric: any;
    values: [number, string][];
    queries?: MonitorTarget;
    targetIndex?: number;
}
export interface PrometheusResultData {
    result: PrometheusResultDataResultItem[];
}

export default class PrometheusDataProcessor implements IMonitorDataProcessor {
    public static processData(
        data: PrometheusResultData[] | PrometheusResultData,
        options: {
            targets: MonitorTarget[];
            prometheusNode: CcPrometheusNode;
            step: number;
        }
    ): MonitorDataPoint[][] {
        const { targets, prometheusNode, step } = options;
        let newData: any[] = getNormalizedData(data, targets);

        let series: any[] = [];
        const exportersByJob = prometheusNode.getExportersGroupedByJob();
        let exporters = collectionUniqueBy(
            targetsToExporters(targets).reduce(
                (result: CcPrometheusExporter[], curr: string) => {
                    if (exportersByJob[curr]) {
                        return [...result, ...exportersByJob[curr]];
                    } else {
                        return result;
                    }
                },
                []
            ),
            'address'
        );
        targets.forEach((t: MonitorTarget, tIdx: number) => {
            const regexp = /\{\{\s?([\w|.]+)\s?\}\}/g;
            const matches = t.legendFormat && t.legendFormat.match(regexp);
            if (matches) {
                newData
                    .filter(({ targetIndex }) => targetIndex === tIdx)
                    .forEach((d) => {
                        let name: string | undefined = t.legendFormat;
                        let hasEmptyReplacement = false;
                        let fieldValue;
                        matches.forEach((m) => {
                            const field = m.replace(regexp, '$1');
                            let replacement;
                            switch (field) {
                                case 'instance':
                                    const exporter = exporters.find(
                                        (e) => e.address === d.metric.instance
                                    );
                                    replacement =
                                        (exporter && exporter.getHostname()) ||
                                        d.metric.instance;
                                    break;
                                default:
                                    fieldValue = getObjectValueByPath(
                                        field.split('.'),
                                        d.metric
                                    );
                                    replacement =
                                        fieldValue !== undefined
                                            ? `${fieldValue}`
                                            : undefined;
                            }
                            hasEmptyReplacement =
                                hasEmptyReplacement ||
                                replacement === undefined;
                            name =
                                name?.replace(
                                    new RegExp(`{{ ?${field} ?}}`, 'g'),
                                    replacement
                                ) || '';
                        });
                        if (!hasEmptyReplacement) {
                            series.push({
                                name,
                                // yAxis: t.yaxis,
                                // type: t.type || undefined,
                            });
                        }
                    });
            } else {
                // const serieData = newData.find((d) => d.targetIndex === tIdx);
                const serieName = t.legendFormat;
                series.push({
                    name: serieName,
                    // yAxis: t.yaxis,
                    // type:
                    //     (vm.displayType === 'chart' &&
                    //         (t.type || (vm.options && vm.options.type))) ||
                    //     undefined,
                    // color: (vm.displayType === 'chart' && t.color) || undefined,
                });
                return { name: serieName };
            }
        });

        let gapTolerance = step;
        return newData.map((d, i) =>
            d.values.reduce(
                (
                    collector: MonitorDataPoint[],
                    [t, v]: [number, string],
                    j: number
                ) => {
                    if (targets[d.targetIndex]?.step !== undefined) {
                        gapTolerance = targets[d.targetIndex]?.step;
                    }
                    if (j === 0) {
                        // addding null data point to the first place of data update
                        // if it intersects with current data it will be deleted in chart
                        // @todo: rethink or refactor this to be in the timeline chart
                        collector.push([
                            t - gapTolerance,
                            null,
                            series[i]?.name,
                        ]);
                    }
                    // we add real data point
                    collector.push([t, parseFloat(v), series[i]?.name]);
                    if (
                        d.values[j + 1] &&
                        d.values[j + 1][0] - t > gapTolerance
                    ) {
                        // this means there is a blank between this data point and next one
                        // so we add a null point between them
                        // @todo: rethink and refactor this to be in the timeline chart
                        collector.push([
                            t + gapTolerance,
                            null,
                            series[i]?.name,
                        ]);
                    }
                    return collector;
                },
                []
            )
        );
    }
}

export function getNormalizedData(
    data: PrometheusResultData[] | PrometheusResultData,
    targets: MonitorTarget[]
): any[] {
    let newData: any[];
    if (Array.isArray(data)) {
        newData = data.reduce(
            (result: PrometheusResultDataResultItem[], datum, i) => {
                if (datum.result.length > 0) {
                    result.push(
                        ...datum.result.map((r: any) => ({
                            ...r,
                            queries: targets[i],
                            targetIndex: i,
                        }))
                    );
                } else {
                    result.push({
                        metric: {},
                        targetIndex: i,
                        values: [],
                    });
                }
                return result;
            },
            []
        );
    } else {
        if (data.result.length > 0) {
            newData = data.result.map((r: any) => ({
                ...r,
                queries: targets[0],
                targetIndex: 0,
            }));
        } else {
            newData = [
                {
                    metric: {},
                    targetIndex: 0,
                    values: [],
                },
            ];
        }
    }
    return newData;
}
