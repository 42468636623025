


export interface CmonDocumentProps  {

}



export default class CmonDocument {


    constructor(props: CmonDocumentProps) {

    }

}
