import React, { useMemo, useState } from 'react';
import { Col, Form, Input, Row, Select, Space, Typography } from 'antd';
import FormFooter from '../../../common/FormFooter';
import ModalDefaultForm from '../../../common/ModalDefaultForm';
import NodeFormat from '../../Nodes/NodeFormat';
import SpaceWide from '../../../common/SpaceWide';
import useCreateJob from '../../Jobs/useCreateJob';
import { CmonJobInstanceCommand } from '../../../services/cmon/models/CmonJobInstance';
import FormItemInlineSwitch from '../../../common/DataEntry/FormItemInlineSwitch';
import FormItemInline from '../../../common/FormItemInline';
import InfoIcon from '@severalnines/bar-frontend-components/build/lib/General/InfoIcon';
import Alert from '@severalnines/bar-frontend-components/build/lib/Feedback/Alert';
import CcPostgreSqlNode from '../../../services/models/CcPostgreSqlNode';
import CcCluster from '../../../services/models/CcCluster';
import ClusterFormat from '../ClusterFormat';
import AppRadio from '../../../common/DataEntry/AppRadio';

export default ClusterConfigureWalForm;

export type ClusterConfigureWalFormProps = {
    cluster: CcCluster;
    defaultNode?: CcPostgreSqlNode;
    onSuccess?: () => void;
    onCancel?: () => void;
};

export type ClusterConfigureWalFormValues = {
    archiveDir?: string;
    archiveMode?: string;
    compression?: boolean;
    force?: boolean;
};

function ClusterConfigureWalForm({
    cluster,
    defaultNode,
    onSuccess,
    onCancel,
}: ClusterConfigureWalFormProps) {
    const [form] = Form.useForm<ClusterConfigureWalFormValues>();
    const [node, setNode] = useState<CcPostgreSqlNode | null>(
        defaultNode as CcPostgreSqlNode
    );
    const { loading, send } = useCreateJob({
        clusterId: cluster?.clusterId || node?.clusterid,
        title: 'Configure WAL',
        command: CmonJobInstanceCommand.ENABLE_LOG_ARCHIVING,
        onSuccess,
    });

    const clusterArchiveMode = useMemo(() => {
        const clusterNodesArchiveModes = cluster
            .getDatabaseNodes()
            .map((n) => (n as CcPostgreSqlNode).archiveMode);

        // returning value if they are all the same for all nodes ('off' or 'on' or 'always')
        return (
            clusterNodesArchiveModes.every(
                (v) => v === clusterNodesArchiveModes[0]
            ) && clusterNodesArchiveModes[0]
        );
    }, [cluster]);

    const handleClusterNodeChange = (nodeKey: string) => {
        if (nodeKey === 'all') {
            setNode(null);
            form.setFieldsValue({
                archiveMode: clusterArchiveMode || 'always',
            });
        } else {
            const n = cluster.nodes.find(
                (n) => n.getKey() === nodeKey
            ) as CcPostgreSqlNode;
            setNode(n || null);
            form.setFieldsValue({ archiveMode: n?.archiveMode || 'always' });
        }
    };

    const handleSubmit = async (values: ClusterConfigureWalFormValues) => {
        await send({
            archive_dir: values.archiveDir ? values.archiveDir : undefined,
            archive_mode: values.archiveMode,
            compression: values.compression,
            node: node
                ? {
                      hostname: node.hostname,
                      port: node.port,
                  }
                : undefined,
            force: values.force || false,
        });
    };

    return (
        <ModalDefaultForm
            title="Configure WAL"
            form={form}
            footer={[]}
            onCancel={onCancel}
            width={900}
            defaultVisible={true}
        >
            <Form
                className="ClusterConfigureWalForm"
                form={form}
                layout="vertical"
                onFinish={handleSubmit}
                initialValues={{
                    archiveMode:
                        defaultNode?.archiveMode ||
                        clusterArchiveMode ||
                        'always',
                    compression: true,
                    clusterNode: defaultNode ? defaultNode.getKey() : 'all',
                }}
            >
                <Row gutter={24}>
                    <Col span={12}>
                        <Form.Item>
                            <ClusterFormat
                                cluster={cluster}
                                size="large"
                                showId={true}
                                contentProps={{
                                    style: { width: '100%' },
                                }}
                            />
                        </Form.Item>
                        <Form.Item name="clusterNode" label="Apply to">
                            <Select
                                data-testid="cluster-configure-wal-form-node-select"
                                onChange={handleClusterNodeChange}
                            >
                                <Select.Option key="all" value="all">
                                    All PostgreSQL nodes
                                </Select.Option>
                                {cluster.getDatabaseNodes().map((n) => (
                                    <Select.Option
                                        key={n.getKey()}
                                        value={n.getKey()}
                                    >
                                        <NodeFormat
                                            node={n}
                                            showPopover={false}
                                        />
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            shouldUpdate={(prev, curr) => {
                                return prev.clusterNode !== curr.clusterNode;
                            }}
                            noStyle
                        >
                            {() => (
                                <FormItemInline
                                    name="archiveMode"
                                    justify={true}
                                    label={
                                        <Space>
                                            <span>Archive mode</span>
                                            <InfoIcon
                                                info={
                                                    <span>
                                                        <b>Off</b> : wal logs
                                                        are not archived, pitr
                                                        will not be possible,
                                                        <br />
                                                        <b>On</b> : wal logs are
                                                        archived only if the
                                                        node is master mode,{' '}
                                                        <br />
                                                        <b>Always</b>: wal logs
                                                        are archived no matter
                                                        the node is master or
                                                        slave mode
                                                    </span>
                                                }
                                            />
                                        </Space>
                                    }
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                'Please select a archive mode.',
                                        },
                                    ]}
                                >
                                    <AppRadio.Group
                                        style={{ display: 'inline-flex' }}
                                    >
                                        <AppRadio.Button key="off" value="off">
                                            Off
                                        </AppRadio.Button>
                                        <AppRadio.Button key="on" value="on">
                                            On
                                        </AppRadio.Button>
                                        <AppRadio.Button
                                            key="always"
                                            value="always"
                                        >
                                            Always
                                        </AppRadio.Button>
                                    </AppRadio.Group>
                                </FormItemInline>
                            )}
                        </Form.Item>
                        <FormItemInlineSwitch
                            justify
                            name="compression"
                            label={<span>Compress WAL archive</span>}
                            valuePropName="checked"
                        />
                        <Form.Item
                            name="archiveDir"
                            label="Custom WAL archive directory (optional)"
                        >
                            <Input />
                        </Form.Item>
                        {node?.isPitrMethodPGBackRest() ? (
                            <FormItemInlineSwitch
                                justify
                                name="force"
                                label={
                                    <Space>
                                        Force
                                        <InfoIcon
                                            info={
                                                <span>
                                                    Required for deconfiguring
                                                    PgBackRest (not recommended)
                                                </span>
                                            }
                                        />
                                    </Space>
                                }
                                valuePropName="checked"
                                extraOnSwitch={
                                    <Alert
                                        showIcon
                                        type="error"
                                        message={
                                            'PgBackRest will be deconfigured.'
                                        }
                                    />
                                }
                            />
                        ) : null}
                    </Col>
                    <Col span={12}>
                        {node?.archiveCommand ? (
                            <Form.Item label="Archive command">
                                <Typography.Paragraph>
                                    <pre style={{ margin: 0 }}>
                                        <small>
                                            <Typography.Paragraph
                                                code
                                                copyable={true}
                                            >
                                                {node.archiveCommand}
                                            </Typography.Paragraph>
                                        </small>
                                    </pre>
                                </Typography.Paragraph>
                            </Form.Item>
                        ) : null}
                        {node?.restoreCommand ? (
                            <Form.Item label="Restore command">
                                <Typography.Paragraph>
                                    <pre style={{ margin: 0 }}>
                                        <small>
                                            <Typography.Paragraph
                                                code
                                                copyable={true}
                                            >
                                                {node.restoreCommand}
                                            </Typography.Paragraph>
                                        </small>
                                    </pre>
                                </Typography.Paragraph>
                            </Form.Item>
                        ) : null}

                        <SpaceWide direction="vertical">
                            <Alert
                                showIcon
                                type="warning"
                                message={
                                    'Changing the current configuration leads to loss of collected continuos WAL archive and thus loss of time frame to do PITR in.'
                                }
                            />
                            {node?.isPitrMethodPGBackRest() ? (
                                <Alert
                                    showIcon
                                    type="warning"
                                    message={
                                        "pgBackRest will be deconfigured if switching the WAL archiving method from 'pgBackRest' to 'local WAL archiving'."
                                    }
                                />
                            ) : null}
                            {node?.isPitrMethodLocalWalArchive() ? (
                                <Alert
                                    showIcon
                                    type="warning"
                                    message={
                                        "PITR using pg_basebackup will no longer be possible if switching the WAL archiving method from 'local WAL archiving' to 'pgBackRest'."
                                    }
                                />
                            ) : null}
                        </SpaceWide>
                    </Col>
                </Row>
                <FormFooter
                    loading={loading}
                    showSubmitButton={true}
                    showCancelButton={true}
                    submitButtonText="Configure"
                    onCancel={onCancel}
                />
            </Form>
        </ModalDefaultForm>
    );
}
