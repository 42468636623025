import React from 'react';
import { Col, Form, Input, Row, Space } from 'antd';
import InfoIcon from '@severalnines/bar-frontend-components/build/lib/General/InfoIcon';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import VendorRepositoryField from '../../../../common/Form/Fields/VendorRepositoryField';
import { FormInstance } from 'antd/lib/form';
import SslEncryptionField from '../FormParts/SslEncryptionField';
import PasswordInput from '../../../../common/DataEntry/PasswordInput';

export default RedisNodeConfiguration;

export type RedisNodeConfigurationProps = {
    form: FormInstance;
    hasRepository?: boolean;
    hasSslEncryption?: boolean;
    importCluster?: boolean;
};

function RedisNodeConfiguration({
    form,
    hasRepository = true,
    hasSslEncryption = false,
    importCluster = false,
}: RedisNodeConfigurationProps) {
    return (
        <div className="RedisDeploymentNodeConfiguration">
            <Row gutter={[24, 0]}>
                <Col span={24}>
                    <h3>Node configuration</h3>
                </Col>
                <Col xs={24} sm={24} md={12}>
                    <Form.Item
                        name={['nodeConfig', 'redisPort']}
                        label={
                            <Space>
                                Redis Port
                                <InfoIcon info="The redis service port." />
                            </Space>
                        }
                        rules={[
                            {
                                required: true,
                                message: 'Please enter the redis nodes port.',
                            },
                        ]}
                    >
                        <Input placeholder="Enter redis port" />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12}>
                    <Form.Item
                        name={['nodeConfig', 'redisSentinelPort']}
                        label={
                            <Space>
                                Redis Sentinel Port
                                <InfoIcon info="The redis sentinel port." />
                            </Space>
                        }
                        rules={[
                            {
                                required: true,
                                message:
                                    'Please enter the redis sentinel nodes port.',
                            },
                        ]}
                    >
                        <Input placeholder="Enter redis sentinel port" />
                    </Form.Item>
                </Col>
                {hasRepository ? (
                    <Col xs={24} sm={24} md={12}>
                        <VendorRepositoryField
                            name={['nodeConfig', 'repository']}
                            form={form}
                        />
                    </Col>
                ) : null}
            </Row>
            <Row gutter={[24, 0]}>
                {hasSslEncryption ? (
                    <Col xs={24} sm={24} md={12}>
                        <SslEncryptionField />
                    </Col>
                ) : null}
                <Col span={24}>
                    <h3>Authentication</h3>
                </Col>
                <Col xs={24} sm={24} md={12}>
                    <Form.Item
                        name={['nodeConfig', 'dbPassword']}
                        label={
                            <Space>
                                Password
                                <InfoIcon info="The primary node password used for client/replica authentication." />
                            </Space>
                        }
                        rules={[
                            {
                                required: true,
                                message: 'Please enter a password.',
                            },
                            {
                                validator: async (rule, value) => {
                                    if (importCluster) {
                                        return true;
                                    }
                                    if (/[%]/.test(value)) {
                                        throw new Error(
                                            'Character % is not allowed'
                                        );
                                    }
                                    return true;
                                },
                            },
                        ]}
                    >
                        <PasswordInput placeholder="Enter password" />
                    </Form.Item>
                </Col>
            </Row>
            {importCluster && (
                <div>
                    <Row gutter={[24, 0]}>
                        <Col xs={24} sm={24} md={12}>
                            <Form.Item
                                name={['nodeConfig', 'replicationUser']}
                                label={<Space>Replication user</Space>}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter a user.',
                                    },
                                ]}
                            >
                                <Input placeholder="Enter replication user" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12}>
                            <Form.Item
                                name={['nodeConfig', 'replicationPassword']}
                                label={<Space>Replication password</Space>}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter a password.',
                                    },
                                ]}
                            >
                                <PasswordInput placeholder="Enter password" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[24, 0]}>
                        <Col xs={24} sm={24} md={12}>
                            <Form.Item
                                name={['nodeConfig', 'sentinelPassword']}
                                label={<Space>Sentinel password</Space>}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter a password.',
                                    },
                                ]}
                            >
                                <PasswordInput placeholder="Enter password" />
                            </Form.Item>
                        </Col>
                    </Row>
                </div>
            )}
        </div>
    );
}
