import React from 'react';
import ModalDefaultForm from '../../common/ModalDefaultForm';
import CcBackup from '../../services/models/CcBackup';
import useForm from 'antd/lib/form/hooks/useForm';
import { Form, Select, Input } from 'antd';
import FormFooter from '../../common/FormFooter';
import useCreateJob from '../Jobs/useCreateJob';
import { CmonJobInstanceCommand } from '../../services/cmon/models/CmonJobInstance';
import StorageLocationFormat from './StorageLocations/StorageLocationFormat';
import ClusterHostInput from '../../common/DataEntry/ClusterHostInput';
import CcCluster from '../../services/models/CcCluster';
import BackupFormat from './BackupFormat';
import InputNumberWide from '../../common/DataEntry/InputNumberWide';
import { BACKUP_CLOUD_DEFAULT_RETENTION } from './Config/BackupDefaultFieldsConfig';

export default BackupCloudDownloadModal;

export type BackupCloudDownloadModalProps = {
    backup: CcBackup;
    cluster: CcCluster;
    onCancel?: () => void;
    onSuccess?: () => void;
};

type DownloadFieldsType = {
    locationId: string;
    storageHost: string;
    backupRetention?: number;
    backupDir: string;
};

function BackupCloudDownloadModal({
    backup,
    cluster,
    onCancel,
    onSuccess,
}: BackupCloudDownloadModalProps) {
    const [form] = useForm<DownloadFieldsType>();

    const { loading, send } = useCreateJob({
        clusterId: backup.getCid(),
        title: 'Download Backup Data From Cloud Storage',
        command: CmonJobInstanceCommand.DOWNLOAD_BACKUP_DATA_FROM_CLOUD_STORAGE,
        onSuccess,
    });

    const handleCancel = () => {
        onCancel?.();
    };

    const handleSubmit = async ({
        locationId,
        storageHost,
        backupDir,
        backupRetention,
    }: DownloadFieldsType) => {
        await send({
            backupid: backup.getId(),
            clusterId: backup.getCid(),
            backup_retention: backupRetention,
            backupdir: backupDir,
            cloud_location_uuid: locationId,
            storage_host: storageHost,
        });
    };
    return (
        <ModalDefaultForm
            title={`Download backup from the cloud`}
            width={450}
            form={form}
            onCancel={handleCancel}
            defaultVisible={true}
            bodyStyle={{ padding: '20px' }}
            loading={loading}
        >
            <Form
                form={form}
                onFinish={handleSubmit}
                layout="vertical"
                initialValues={{
                    locationId: backup.cloudLocations[0]?.cloudLocationUuid,
                    storageHost: cluster.getHosts()[0]?.hostname,
                }}
            >
                <Form.Item>
                    <BackupFormat
                        backup={backup}
                        size="large"
                        contentProps={{ style: { width: '100%' } }}
                    />
                </Form.Item>
                <Form.Item name="locationId" label="Download from">
                    <Select>
                        {backup.cloudLocations.map((storageLocation) => (
                            <Select.Option
                                value={
                                    storageLocation.cloudLocationUuid as string
                                }
                                key={storageLocation.cloudLocationUuid}
                            >
                                <StorageLocationFormat
                                    storageLocation={storageLocation}
                                >
                                    {storageLocation.bucketAndPath}
                                </StorageLocationFormat>
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item name="storageHost" label="Download to">
                        <ClusterHostInput cluster={cluster} />
                </Form.Item>
                <Form.Item label="Backup dir in host" name={'backupDir'}>
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Retention period"
                    name={'backupRetention'}
                    extra={`The default retention period is ${BACKUP_CLOUD_DEFAULT_RETENTION} days`}
                >
                    <InputNumberWide
                        placeholder={`${BACKUP_CLOUD_DEFAULT_RETENTION} Days`}
                    />
                </Form.Item>
                <FormFooter
                    loading={loading}
                    submitButtonText="Download"
                    showCancelButton
                    showSubmitButton
                    onCancel={handleCancel}
                />
            </Form>
        </ModalDefaultForm>
    );
}
