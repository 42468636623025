import React, { useEffect, useState } from 'react';
import CcCluster from '../../services/models/CcCluster';
import { AppState, AppStateClustersMap } from '../../appReducer';
import { useSelector } from 'react-redux';
import { getHostWithDesc } from '../Nodes/NodeFormat';
import { Select, SelectProps } from 'antd';
import { groupArrayBy } from '../../common/helpers';
import { CcNodeType } from '../../services/models/CcNode';
import CcHost from '../../services/models/CcHost';

export default PrometheusSelectHost;

export type PrometheusSelectHostProps = SelectProps<any> & {
    cluster?: CcCluster;
};

function PrometheusSelectHost({
    cluster,
    value,
    onChange,
    ...rest
}: PrometheusSelectHostProps) {
    const [options, setOptions] = useState<any>();
    const [selected, setSelected] = useState<string>();

    const [clustersMap]: [
        AppStateClustersMap
    ] = useSelector(({ clusters }: AppState) => [clusters]);
    useEffect(() => {
        const currentOptions = [];
        const controllerHost = cluster?.getControllerHost();
        if (controllerHost) {
            currentOptions.push({
                label: 'Cluster hosts',
                options: [
                    {
                        label: getHostWithDesc(controllerHost),
                        value: `controller|${controllerHost.hostname}`,
                    },
                ],
            });
        }
        const existingHosts = clustersMap
            ?.toList()
            .toArray()
            .map((cluster) => cluster.getHosts())
            .map((hosts) =>
                hosts.find((host) => host.hasNodeType(CcNodeType.PROMETHEUS))
            )
            .filter((host) => !!host);

        if (existingHosts.length > 0) {
            currentOptions.push({
                label: 'Existing prometheus nodes',
                options: (Object.values(
                    groupArrayBy(existingHosts, (host: CcHost) => host.hostname)
                ) as CcHost[][]).map(([host]) => ({
                    label: getHostWithDesc(host),
                    value: `prometheus|${host.hostname}`,
                })),
            });
        }
        setOptions(currentOptions);
    }, [clustersMap, cluster]);

    useEffect(() => {
        if (value) {
            const option = options
                ?.map(({ options }: any) => options)
                .flat()
                .find(({ value: val }: any) => val?.split('|').pop() === value);

            if (option?.value) {
                setSelected(option?.value);
            } else {
                setNewHostOption(value);
                setSelected(value);
            }
        }
    }, [value, options]);

    const handleChange = (host: string, option: any) => {
        const newValue = host?.split('|').pop();
        if (value !== newValue) {
            onChange?.(newValue, option);
        }
        setSelected(host);
    };
    const handleSearch = (searchValue: string) => {
        setNewHostOption(searchValue);
    };

    return options ? (
        <Select
            options={options}
            value={selected}
            showSearch
            onChange={handleChange}
            onSearch={handleSearch}
            {...rest}
        />
    ) : null;

    function setNewHostOption(value: string) {
        if (value) {
            const currentOptions = options?.filter(
                (option: any) => option.label !== 'New host'
            );
            currentOptions?.push({
                label: 'New host',
                options: [
                    {
                        label: value,
                        value: value,
                    },
                ],
            });
            if (currentOptions) {
                setOptions(currentOptions);
            }
        }
    }
}
