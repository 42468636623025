import React from 'react';
import { Select, SelectProps } from 'antd';
import { CcSnapshotRepositoryType } from '../../../services/models/CcSnapshotRepository';
import SnapshotRepositoryType from '../../SnapshotRepository/SnapshotRepositoryType';

export default ElasticRepositoryTypeSelect;

export type ElasticRepositoryTypeSelectProps = SelectProps & {};

function ElasticRepositoryTypeSelect({
    ...rest
}: ElasticRepositoryTypeSelectProps) {
    return (
        <div className="ElasticRepositoryTypeSelect">
            <Select
                options={[
                    {
                        label: (
                            <SnapshotRepositoryType
                                type={CcSnapshotRepositoryType.S3}
                            />
                        ),
                        value: CcSnapshotRepositoryType.S3,
                    },
                ]}
                {...rest}
            />
        </div>
    );
}
