import React, { useState } from 'react';
import { ButtonProps } from 'antd/lib/button';
import CmonJobsService from '../../services/cmon/CmonJobsService';
import CcJob from '../../services/models/CcJob';
import { notifyError } from '../Notifications/uiNotification';
import { useDebugContext } from '../../common/Debug';
import AppConfirmActionButton from '../../common/General/AppConfirmActionButton';

export default JobDeleteButton;

export type JobDeleteButtonProps = ButtonProps & {
    job: CcJob;
    children?: React.ReactNode;
    onSuccess?: () => void;
};

function JobDeleteButton({
    job,
    children,
    onSuccess,
    ...rest
}: JobDeleteButtonProps) {
    const { log } = useDebugContext();
    const [loading, setLoading] = useState(false);
    const handleClick = async () => {
        try {
            setLoading(true);
            await CmonJobsService.deleteJobInstance({
                job_id: job.jobId,
            });
            if (onSuccess) {
                onSuccess();
            }
        } catch (err) {
            notifyError({ content: err.message });
            log.error(err);
        }
        setLoading(false);
    };

    return (
        <AppConfirmActionButton
            confirmTitle="Delete job?"
            onConfirm={handleClick}
            loading={loading}
            critical
            {...rest}
        >
            {children || 'Delete'}
        </AppConfirmActionButton>
    );
}
