import React from 'react';
import ActionsMenu, {
    ActionsMenuProps,
} from '../../common/Navigation/ActionsMenu';
import CcSnapshotRepository from '../../services/models/CcSnapshotRepository';
import SnapshotRepositoryDeleteActionButton from './SnapshotRepositoryDeleteActionButton';

export default SnapshotRepositoryActionsMenu;
export type SnapshotRepositoryActionsMenuProps = ActionsMenuProps & {
    repository: CcSnapshotRepository;
    onActionPerformed?: () => void;
};

function SnapshotRepositoryActionsMenu({
    repository,
    onActionPerformed,
    ...rest
}: SnapshotRepositoryActionsMenuProps) {
    const handleActionSuccess = () => {
        onActionPerformed?.();
    };
    return (
        <ActionsMenu
            items={
                repository?.name && repository?.clusterId
                    ? [
                          {
                              key: 'delete',
                              waitForConfirm: true,
                              label: (
                                  <SnapshotRepositoryDeleteActionButton
                                      repository={repository}
                                      onSuccess={handleActionSuccess}
                                  />
                              ),
                          },
                      ]
                    : []
            }
            {...rest}
        />
    );
}
