import {
    Alert,
    Col,
    Divider,
    Form,
    Input,
    Row,
    Select,
    Skeleton,
    Space,
} from 'antd';
import React, { useContext } from 'react';
import InfoIcon from '@severalnines/bar-frontend-components/build/lib/General/InfoIcon';
import FormItemInlineSwitch from '../../common/DataEntry/FormItemInlineSwitch';
import { ClusterConfig } from '../Clusters/useClusterConfig';
import CcNode from '../../services/models/CcNode';
import { getNodeHostWithDesc } from '../Nodes/NodeFormat';
import FormFooter from '../../common/FormFooter';
import { FormInstance } from 'antd/lib/form';
import { ClusterConfigContext } from '../Clusters/Config/ClusterConfigContext';
import SpaceWide from '../../common/SpaceWide';

export default BackupSettingsForm;
export type BackupSettingsFormFields = {
    directory?: string;
    subDirectory?: string;
    netcatPort?: string;
    hashCheck?: boolean;
    retentionPeriod?: number;
    cloudRetentionPeriod?: number;
    enableWALArchiving?: boolean;
    walBackupHost?: string;
    compressWALArchive?: boolean;
    pitrRetentionPeriod?: number;
};
export type BackupSettingsFormProps = {
    form: FormInstance;
    onSubmit?: (fields: BackupSettingsFormFields) => void;
    onCancel?: () => void;
    onFieldsTouched?: (touched: boolean) => void;
    buttonLoading?: boolean;
    walArchiving?: boolean;
    hosts: CcNode[];
};
function BackupSettingsForm({
    form,
    onSubmit,
    onCancel,
    onFieldsTouched,
    buttonLoading = false,
    walArchiving,
    hosts,
    ...rest
}: BackupSettingsFormProps) {
    const { configGroupedLoading, configGrouped } =
        useContext(ClusterConfigContext);

    const handleSubmit = () => {
        onSubmit?.(form.getFieldsValue(true));
    };

    const handleCancel = () => {
        onCancel?.();
    };

    if (configGroupedLoading || !configGrouped) {
        return (
            <SpaceWide direction="vertical">
                <Row gutter={[24, 0]}>
                    <Col xs={24} sm={24} md={12}>
                        <Skeleton title={true} paragraph={false} />
                    </Col>
                </Row>
                <Row gutter={[24, 0]}>
                    <Col xs={24} sm={24} md={12}>
                        <Skeleton.Input
                            active={true}
                            size="large"
                            block={true}
                        />
                    </Col>
                    <Col xs={24} sm={24} md={12}>
                        <Skeleton.Input
                            active={true}
                            size="large"
                            block={true}
                        />
                    </Col>
                </Row>
                <Row gutter={[24, 0]}>
                    <Col xs={24} sm={24} md={12}>
                        <Skeleton.Input
                            active={true}
                            size="large"
                            block={true}
                        />
                    </Col>
                </Row>
                <Row gutter={[24, 0]}>
                    <Col xs={24} sm={24} md={12}>
                        <Skeleton active={true} />
                    </Col>
                </Row>
                <Divider />
                <Row gutter={[24, 0]}>
                    <Col xs={24} sm={24} md={12}>
                        <Skeleton.Input
                            active={true}
                            size="large"
                            block={true}
                        />
                    </Col>
                    <Col xs={24} sm={24} md={12}>
                        <Skeleton.Input
                            active={true}
                            size="large"
                            block={true}
                        />
                    </Col>
                </Row>
                <Skeleton active={true} />
                {walArchiving ? (
                    <>
                        <Divider />
                        <Row gutter={[24, 0]}>
                            <Col xs={24} sm={24} md={12}>
                                <Skeleton.Input
                                    active={true}
                                    size="large"
                                    block={true}
                                />
                            </Col>
                        </Row>
                    </>
                ) : (
                    ''
                )}
                <SpaceWide align="end" justify="end">
                    <Skeleton.Button active={true} size="large" />
                    <Skeleton.Button active={true} size="large" />
                </SpaceWide>
            </SpaceWide>
        );
    }

    return (
        <Form
            form={form}
            layout="vertical"
            onFinish={handleSubmit}
            initialValues={getBackupSettingConfigFields(
                configGrouped?.backup || {}
            )}
            {...rest}
        >
            <Row gutter={[24, 0]}>
                <Col span={24}>
                    <h3>
                        <strong>Storage</strong>
                    </h3>
                </Col>
            </Row>
            <Row gutter={[24, 0]}>
                <Col xs={24} sm={24} md={12}>
                    <Form.Item
                        name="directory"
                        label={<Space>Default directory</Space>}
                    >
                        <Input placeholder="Enter directory path" />
                    </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={12}>
                    <Form.Item
                        name="subDirectory"
                        label={
                            <Space>
                                Default subdirectory{' '}
                                <InfoIcon
                                    info={
                                        <span>
                                            Pattern used for generating backup
                                            filenames, available fields to be
                                            substituted: %B - creation date
                                            <br />
                                            %H - hostname
                                            <br />
                                            %i - cluster number
                                            <br />
                                            %I - backup number
                                            <br />
                                            %J - job number
                                            <br />
                                            %M - backup method
                                            <br />
                                            %O - user
                                            <br />
                                            %S - storage host'
                                        </span>
                                    }
                                />
                            </Space>
                        }
                    >
                        <Input placeholder="Enter subdirectory pattern" />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={[24, 0]}>
                <Col xs={24} sm={24} md={12}>
                    <Form.Item
                        name="netcatPort"
                        label={
                            <Space>
                                Netcat port
                                <InfoIcon
                                    info={
                                        <span>
                                            List of netcat ports and port ranges
                                            used to stream backups. Defaults to
                                            9999,9990-9998 and port 9999 will be
                                            preferred if available.
                                        </span>
                                    }
                                />
                            </Space>
                        }
                    >
                        <Input placeholder="Enter netcat ports ranges" />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={[24, 0]}>
                <Col xs={24} sm={24} md={12}>
                    <FormItemInlineSwitch
                        justify
                        noMargin
                        name="hashCheck"
                        label={
                            <Space>
                                Enable hash check on created backup files
                            </Space>
                        }
                        valuePropName="checked"
                        extraOffSwitch={
                            <Alert
                                message="When disabling hash check, there is no way to verify the integrity of backup files when streamed over the network."
                                type="warning"
                                showIcon={true}
                            />
                        }
                    />
                </Col>
            </Row>
            <Divider />
            <Row gutter={[24, 0]}>
                <Col span={24}>
                    <h3>
                        <strong>Retention Period</strong>
                    </h3>
                </Col>
            </Row>
            <Row gutter={[24, 0]}>
                <Col xs={24} sm={24} md={12}>
                    <Form.Item
                        name="retentionPeriod"
                        extra={'The retention period is displayed in days'}
                        label={
                            <Space>
                                Default backups retention period
                                <InfoIcon
                                    info={<span>0 means no retention</span>}
                                />
                            </Space>
                        }
                    >
                        <Input placeholder="Enter retention period in days" />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12}>
                    <Form.Item
                        name="cloudRetentionPeriod"
                        extra={'The retention period is displayed in days'}
                        label={
                            <Space>
                                Default cloud backups retention period
                                <InfoIcon
                                    info={<span>0 means no retention</span>}
                                />
                            </Space>
                        }
                    >
                        <Input placeholder="Enter cloud retention period in days" />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={[24, 0]}>
                <Col span={24}>
                    <Alert
                        message="Backups exceeding the retention period will be deleted. Please, note that once the backups are deleted, they can’t be recovered."
                        type="info"
                        showIcon={true}
                    />
                </Col>
            </Row>
            {walArchiving ? (
                <>
                    <Divider />
                    <Row gutter={[24, 0]}>
                        <Col span={24}>
                            <h3>
                                <strong>Point in time recovery</strong>
                            </h3>
                        </Col>
                    </Row>
                    <Row gutter={[24, 0]}>
                        <Col span={12}>
                            <FormItemInlineSwitch
                                justify
                                noMargin
                                name="enableWALArchiving"
                                label={
                                    <Space>
                                        Enable Point In Time Recovery (WAL
                                        Archiving)
                                    </Space>
                                }
                                valuePropName="checked"
                            />
                        </Col>
                    </Row>
                </>
            ) : null}
            <Form.Item
                shouldUpdate={(prev, curr) => {
                    return prev.enableWALArchiving !== curr.enableWALArchiving;
                }}
                noStyle
            >
                {() => {
                    return hosts && form.getFieldValue('enableWALArchiving') ? (
                        <Row gutter={[24, 0]}>
                            <Col xs={24} sm={24} md={12}>
                                <Form.Item
                                    name="walBackupHost"
                                    label={<Space>Backup Host</Space>}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please select host.',
                                        },
                                    ]}
                                >
                                    <Select
                                        options={hosts.map((node) => ({
                                            value: node.getHostWithPort(),
                                            label: getNodeHostWithDesc(node),
                                        }))}
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12}>
                                <Form.Item
                                    name="pitrRetentionPeriod"
                                    label={
                                        <Space>
                                            PITR Retention Hours
                                            <InfoIcon
                                                info={
                                                    <span>
                                                        This setting specifies
                                                        how long WAL files are
                                                        kept. The default is 0
                                                        which means old WAL
                                                        files will be kept
                                                        forever.
                                                    </span>
                                                }
                                            />
                                        </Space>
                                    }
                                >
                                    <Input placeholder="Enter pitr retention period in days" />
                                </Form.Item>
                            </Col>

                            <Col xs={24} sm={24} md={12}>
                                <FormItemInlineSwitch
                                    justify
                                    noMargin
                                    name="compressWALArchive"
                                    label={<Space>Compress WAL Archive</Space>}
                                    valuePropName="checked"
                                />
                            </Col>
                            <Col span={24}>
                                <Alert
                                    message={
                                        <>
                                            The following steps will be
                                            performed on the master node:
                                            <ol>
                                                <li>
                                                    Enable the WAL Archiving on
                                                    the Master Node
                                                </li>
                                                <li>
                                                    Master Node will be
                                                    restarted
                                                </li>
                                                <li>
                                                    PG_base backup will be taken
                                                </li>
                                            </ol>
                                        </>
                                    }
                                    type="warning"
                                    showIcon={true}
                                />
                            </Col>
                        </Row>
                    ) : null;
                }}
            </Form.Item>
            <FormFooter
                showCancelButton
                showSubmitButton
                submitButtonText="Save"
                loading={buttonLoading}
                onCancel={handleCancel}
            />
        </Form>
    );
}

export function getBackupSettingsEnableArchivingJobData(
    clusterId: number,
    fields: BackupSettingsFormFields
) {
    const [hostname, port] = fields.walBackupHost?.split(':') || [];
    return {
        clusterId,
        compression: !!fields.compressWALArchive,
        hostname,
        port,
    };
}

export function getBackupSettingConfigFields(
    config: ClusterConfig
): BackupSettingsFormFields {
    return {
        retentionPeriod: +config.backup_retention?.current_value,
        cloudRetentionPeriod: +config.backup_cloud_retention?.current_value,
        directory: `${config.backupdir?.current_value}`,
        subDirectory: `${config.backup_subdir?.current_value}`,
        netcatPort: `${config.netcat_port?.current_value}`,
        pitrRetentionPeriod: +config.pitr_retention_hours?.current_value,
        hashCheck: config.backup_create_hash?.current_value === 'true',
    };
}

export function getBackupSettingConfig(fields: BackupSettingsFormFields) {
    return [
        {
            name: 'backup_retention',
            value: fields.retentionPeriod || 0,
        },
        {
            name: 'backup_cloud_retention',
            value: fields.cloudRetentionPeriod || 0,
        },
        {
            name: 'backupdir',
            value: fields.directory,
        },
        {
            name: 'backup_subdir',
            value: fields.subDirectory,
        },
        {
            name: 'netcat_port',
            value: fields.netcatPort,
        },
        {
            name: 'pitr_retention_hours',
            value: fields.pitrRetentionPeriod || 0,
        },
        {
            name: 'backup_create_hash',
            value: fields.hashCheck || false,
        },
    ];
}
