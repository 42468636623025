import React from 'react';
import SpaceWide from '../SpaceWide';
import { Button, Popconfirm } from 'antd';
import { MonacoDiffEditor } from 'react-monaco-editor';
import ModalDefault from '../ModalDefault';
import AppButton from '../Control/AppButton';

export default AppEditorDiffModal;

export type AppEditorDiffModalProps = {
    original: string;
    value: string;
    title: React.ReactNode;
    onCancel?: () => void;
    onSuccess?: () => void;
    onSaveClick?: () => Promise<void>;
    onRevertClick?: () => void;
};

function AppEditorDiffModal({
    original,
    value,
    title,
    onCancel,
    onSuccess,
    onSaveClick,
    onRevertClick,
}: AppEditorDiffModalProps) {
    const handleSaveClick = async () => {
        await onSaveClick?.();
        onSuccess?.();
    };

    const handleRevertClick = () => {
        onRevertClick?.();
        onCancel?.();
    };

    return (
        <ModalDefault
            title={<span>{title} diff</span>}
            onCancel={onCancel}
            width={1100}
            visible={true}
            bodyStyle={{ padding: '25px' }}
            footer={
                <SpaceWide justify="flex-end">
                    <Popconfirm
                        okType="danger"
                        onConfirm={handleRevertClick}
                        title="Revert changes?"
                    >
                        <Button size="middle" danger={true}>
                            Revert changes
                        </Button>
                    </Popconfirm>
                    <AppButton
                        asyncLoading={true}
                        type="primary"
                        onClick={handleSaveClick}
                        size="middle"
                        disabled={original === value}
                    >
                        Save
                    </AppButton>
                </SpaceWide>
            }
        >
            <MonacoDiffEditor
                height="600"
                options={{
                    automaticLayout: true,
                }}
                language="javascript"
                original={original}
                value={value}
            />
        </ModalDefault>
    );
}
