import React from 'react';
import { Col, Form, Row } from 'antd';
import CcCluster from '../../../services/models/CcCluster';
import BackupFormConfigurator, {
    BackupFormFieldsType,
} from '../Config/BackupFormConfigurator';
import { FormInstance } from 'antd/lib/form';
import { CmonJobInstanceCommand } from '../../../services/cmon/models/CmonJobInstance';
import CcJob from '../../../services/models/CcJob';
import BackupToolSetupInput from '../BackupTool/BackupToolSetupInput';

export default BackupConfigurationFieldsWithTool;
export type BackupConfigurationFieldsWithToolProps = {
    form: FormInstance<BackupFormFieldsType>;
    configurator: BackupFormConfigurator;
    renderFields: (size?: 'small' | 'large') => React.ReactNode;
    toolCommand?:
        | CmonJobInstanceCommand.PGBACKREST
        | CmonJobInstanceCommand.PBMAGENT;
    cluster?: CcCluster;
    reconfigureMessage?: React.ReactNode;
};

function BackupConfigurationFieldsWithTool({
    form,
    configurator,
    renderFields,
    toolCommand,
    cluster,
    reconfigureMessage,
    ...rest
}: BackupConfigurationFieldsWithToolProps) {
    const handleJobFinished = (job: CcJob) => {
        form.setFieldsValue({ backupToolJobId: job?.jobId });
    };
    return (
        <div className="BackupConfigurationFieldsWrap">
            {cluster &&
            toolCommand &&
            configurator.available('installBackupTool') &&
            (!cluster.hasBackupTool() || !!reconfigureMessage) ? (
                <Row gutter={[24, 0]}>
                    <Col xs={24} sm={24} md={12} key="fields">
                        {renderFields('large')}
                    </Col>
                    <Col xs={24} sm={24} md={12} key="backupTool">
                        <Form.Item name="toolStatus" noStyle>
                            <BackupToolSetupInput
                                form={form}
                                cluster={cluster}
                                command={toolCommand}
                                reconfigureMessage={reconfigureMessage}
                                required={true}
                                onSuccess={handleJobFinished}
                            />
                        </Form.Item>
                    </Col>
                </Row>
            ) : (
                renderFields()
            )}
        </div>
    );
}
