import React from 'react';
import './AppSubLayout.less';
import { LayoutProps, Layout } from 'antd';

export default AppSubLayout;

export type AppSubLayoutProps = LayoutProps & {};

function AppSubLayout({ children, ...rest }: AppSubLayoutProps) {
    return (
        <Layout className="AppSubLayout" {...rest}>
            <div className="AppSubLayout_content">
                <div className="AppSubLayout_content-wrap">{children}</div>
            </div>
        </Layout>
    );
}
