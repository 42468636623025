
import CcStats, { CcStatsProps } from '../../models/CcStats';


export interface CmonNetworkStatsProps  extends CcStatsProps {
    interface?: string;
    rxBytes?: number;
    rxDrops?: number;
    rxErrors?: number;
    rxFrames?: number;
    rxMulticasts?: number;
    rxPackets?: number;
    txBytes?: number;
    txDrops?: number;
    txErrors?: number;
    txPackets?: number;

}



export default class CmonNetworkStats extends CcStats {
    public interface?: string;
    public rxBytes?: number;
    public rxDrops?: number;
    public rxErrors?: number;
    public rxFrames?: number;
    public rxMulticasts?: number;
    public rxPackets?: number;
    public txBytes?: number;
    public txDrops?: number;
    public txErrors?: number;
    public txPackets?: number;


    constructor(props: CmonNetworkStatsProps) {
        super(props);
        this.interface = props.interface;
        this.rxBytes = props.rxBytes;
        this.rxDrops = props.rxDrops;
        this.rxErrors = props.rxErrors;
        this.rxFrames = props.rxFrames;
        this.rxMulticasts = props.rxMulticasts;
        this.rxPackets = props.rxPackets;
        this.txBytes = props.txBytes;
        this.txDrops = props.txDrops;
        this.txErrors = props.txErrors;
        this.txPackets = props.txPackets;

    }

}
