import React, { useState } from 'react';
import ModalDefaultForm from '../../common/ModalDefaultForm';
import CcBackup from '../../services/models/CcBackup';
import useForm from 'antd/lib/form/hooks/useForm';
import { Form } from 'antd';
import BackupCloudForm from './Wizard/BackupCloudForm';
import FormFooter from '../../common/FormFooter';
import useCreateJob from '../Jobs/useCreateJob';
import { CmonJobInstanceCommand } from '../../services/cmon/models/CmonJobInstance';
import BackupFormat from './BackupFormat';
import { getBackupFormCloudJobData } from './Config/BackupJobConfig';
import { BACKUP_CLOUD_DEFAULT_RETENTION } from './Config/BackupDefaultFieldsConfig';
import { BackupFormFieldsType } from './Config/BackupFormConfigurator';

export default BackupCloudUploadModal;

export type BackupCloudUploadModalProps = {
    backup: CcBackup;
    onCancel?: () => void;
    onSuccess?: () => void;
};

function BackupCloudUploadModal({
    backup,
    onCancel,
    onSuccess,
}: BackupCloudUploadModalProps) {
    const [form] = useForm<BackupFormFieldsType>();
    const [confirmClose, setConfirmClose] = useState<boolean>(false);
    const [cloudFromLoading, setCloudFormLoading] = useState<boolean>(false);

    const { loading, send } = useCreateJob({
        clusterId: backup.getCid(),
        title: 'Upload Backup Data To Cloud Storage',
        command: CmonJobInstanceCommand.UPLOAD_BACKUP_DATA_TO_CLOUD_STORAGE,
        onSuccess,
    });

    const handleCancel = () => {
        onCancel?.();
    };

    const handleValuesChange = (values: any) => {
        setConfirmClose(form.isFieldsTouched([], true));
    };

    const handleSubmit = async () => {
        const cloud = form.getFieldsValue(true);
        if (cloud) {
            await send({
                backupid: backup.getId(),
                clusterId: backup.getCid(),
                ...getBackupFormCloudJobData(cloud),
            });
        }
    };

    return (
        <ModalDefaultForm
            title={`Upload backup to the cloud`}
            width={450}
            form={form}
            onCancel={handleCancel}
            confirmClose={confirmClose}
            defaultVisible={true}
            bodyStyle={{ padding: '20px' }}
            loading={loading || cloudFromLoading}
        >
            <Form
                form={form}
                onFinish={handleSubmit}
                onValuesChange={handleValuesChange}
                layout="vertical"
                initialValues={{
                    cloudEnableRetention: true,
                    cloudDefaultRetention: BACKUP_CLOUD_DEFAULT_RETENTION,
                    cloudProvider: '',
                }}
            >
                <Form.Item>
                    <BackupFormat
                        backup={backup}
                        size="large"
                        contentProps={{ style: { width: '100%' } }}
                    />
                </Form.Item>
                <BackupCloudForm
                    form={form}
                    onLoadingChange={(loading) => {
                        setCloudFormLoading(loading);
                    }}
                    fullWidth={true}
                />

                <FormFooter
                    loading={loading}
                    submitButtonText="Upload"
                    showSubmitButton
                    showCancelButton
                    onCancel={handleCancel}
                />
            </Form>
        </ModalDefaultForm>
    );
}
