import { ClusterConfiguratorFormValues } from '../Services/Cluster/ClusterConfigurator';
import DeploymentWizard from '../Services/Cluster/Deploy/DeploymentWizard';
import React from 'react';
import ModalDefaultForm from '../../common/ModalDefaultForm';
import { useForm } from 'antd/lib/form/Form';
import CcCluster from '../../services/models/CcCluster';
import CcBackup from '../../services/models/CcBackup';

export default BackupCreateClusterModal;
type BackupCreateClusterModalProps = {
    cluster: CcCluster;
    backup: CcBackup;
    onSuccess?: () => void;
    onCancel?: () => void;
};

function BackupCreateClusterModal({
    cluster,
    backup,
    onSuccess,
    onCancel,
}: BackupCreateClusterModalProps) {
    const [form] = useForm();
    const vendor = cluster.vendor;
    const version = cluster.version;
    return (
        <ModalDefaultForm
            title={`Create cluster from backup`}
            form={form}
            onCancel={onCancel}
            shakeWizardOnValidationError={true}
            showConfirmClose={true}
            defaultVisible={true}
            bodyStyle={{ padding: '20px' }}
        >
            <DeploymentWizard
                backup={backup}
                onCancel={onCancel}
                clusterType={cluster.clusterType}
                onSuccess={onSuccess}
                cluster={cluster}
                fromBackup={true}
                initialValues={
                    {
                        details: {
                            vendor,
                            version,
                        },
                    } as ClusterConfiguratorFormValues
                }
                cancelButtonText="Cancel"
            />
        </ModalDefaultForm>
    );
}
