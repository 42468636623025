import React, { useMemo } from 'react';
import CcCluster from '../../services/models/CcCluster';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import QueryMonitorOverviewPage from './QueryMonitorOverviewPage';
import CmonAgentsPage from './CmonAgentsPage';
import AppSubsectionWrapper from '../../common/Layout/AppSubsectionWrapper';

export default AgentQueryMonitorPage;

export enum AgentQueryMonitorPageSection {
    OVERVIEW = 'overview',
    AGENTS = 'agents',
}

export type AgentQueryMonitorPageProps = {
    cluster: CcCluster;
};

function AgentQueryMonitorPage({
    cluster,
    ...rest
}: AgentQueryMonitorPageProps) {
    const navigate = useNavigate();
    const { subSection = AgentQueryMonitorPageSection.OVERVIEW } = useParams<{
        subSection: AgentQueryMonitorPageSection;
    }>();

    const submenuItems = useMemo(
        () => [
            {
                label: 'Overview',
                key: AgentQueryMonitorPageSection.OVERVIEW,
                children: <QueryMonitorOverviewPage cluster={cluster} />,
            },
            {
                label: 'Agents',
                key: AgentQueryMonitorPageSection.AGENTS,
                children: <CmonAgentsPage cluster={cluster} />,
            },
        ],
        []
    );
    return (
        <AppSubsectionWrapper
            items={submenuItems}
            activeSection={subSection}
            onNavigate={(key) => {
                navigate(
                    `/clusters/${cluster.clusterId}/performance/agent-based-query-monitor/${key}`
                );
            }}
        />
    );
}
