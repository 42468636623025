import React, { useCallback, useContext } from 'react';
import AppTable from '../../common/DataDisplay/AppTable';
import SpaceWide from '../../common/SpaceWide';
import CcDbStats from '../../services/models/CcDbStats';
import CcDbStatsDb from '../../services/models/CcDbStatsDb';
import ActionButton from '@severalnines/bar-frontend-components/build/lib/General/ActionButton';
import { ResponsiveContext } from '@severalnines/bar-frontend-components/build/lib/Layout/Responsive';
import {
    formatMonitorValue,
    MonitorValueFormatType,
} from '../Monitor/MonitorValueFormat';
import InfoIcon from '@severalnines/bar-frontend-components/build/lib/General/InfoIcon';
import AppDateFormat from '../../common/AppDateFormat';
import ActionsMenu from '../../common/Navigation/ActionsMenu';
import { ActionMenuItem } from '@severalnines/bar-frontend-components/build/lib/Navigation/ActionMenu';

export default DbGrowthTopDbsTable;

export type DbGrowthTopDbsTableProps = {
    data: CcDbStats;
    onRecordView?: (db: CcDbStatsDb) => void;
};

function DbGrowthTopDbsTable({ data, onRecordView }: DbGrowthTopDbsTableProps) {
    const { responsive } = useContext(ResponsiveContext);
    const columns = [
        {
            title: 'name',
            key: 'name',
            render: (record: CcDbStatsDb) => <>{record.dbName}</>,
        },
        {
            title: 'Table count',
            key: 'table_count',
            align: 'right',
            render: (record: CcDbStatsDb) => <>{record.tableCount}</>,
        },
        {
            title: 'Rows',
            key: 'rows',
            align: 'right',
            render: (record: CcDbStatsDb) => <>{record.rowCount}</>,
        },
        {
            title: 'Data size',
            key: 'data_size',
            align: 'right',
            render: (record: CcDbStatsDb) => (
                <>
                    {formatMonitorValue(
                        record.dataSize as number,
                        MonitorValueFormatType.SIZE,
                        2
                    )}
                </>
            ),
        },
        {
            title: 'Index size',
            key: 'index_size',
            align: 'right',
            render: (record: CcDbStatsDb) => (
                <>
                    {formatMonitorValue(
                        record.indexSize as number,
                        MonitorValueFormatType.SIZE,
                        2
                    )}
                </>
            ),
        },
        {
            title: 'Database size',
            key: 'database_size',
            align: 'right',
            render: (record: CcDbStatsDb) => (
                <>
                    {formatMonitorValue(
                        record.dataSize ?? 0 + (record.indexSize ?? 0),
                        MonitorValueFormatType.SIZE,
                        2
                    )}
                </>
            ),
        },
        {
            key: 'actions',
            title: 'Actions',
            align: 'center',

            render: (record: CcDbStatsDb) => (
                <ActionsMenu
                    items={
                        [
                            {
                                key: 'edit',
                                label: (
                                    <ActionButton
                                        onClick={() => onRecordView?.(record)}
                                    >
                                        View
                                    </ActionButton>
                                ),
                            },
                        ] as ActionMenuItem[]
                    }
                />
            ),

            width: 20,
            onCell: () => ({ style: { padding: '2px 10px' } }),
        },
    ];

    const rowKey = useCallback(
        (record: CcDbStatsDb) => record.dbName as string,
        []
    );
    return (
        <SpaceWide direction="vertical">
            <h3>
                Top largest databases -{' '}
                <AppDateFormat
                    format="dddd, MMMM Do YYYY"
                    includeTimezone={false}
                >
                    {data.createdDate}
                </AppDateFormat>
                &nbsp;
                <InfoIcon info="Displaying the top 25 larges databases for the selected date." />
            </h3>

            <AppTable
                className="DbGrowthPageTop25Dbs"
                rowKey={rowKey}
                dataSource={data.dbs}
                columns={columns}
                size="middle"
                responsive={responsive}
                // onChange={handleTableChange}
            />
        </SpaceWide>
    );
}
