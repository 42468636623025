import React from 'react';
import { Divider, Space } from 'antd';
import AppProgress from '../common/AppProgress';

export default DebugAppProgress;
export type DebugAppProgressProps = {};

function DebugAppProgress({ ...rest }: DebugAppProgressProps) {
    return (
        <Space direction="vertical" size={20}>
            <Space direction="vertical">
                <Divider
                    plain={true}
                    orientation="left"
                    style={{ marginBottom: '0px', marginTop: '15px' }}
                >
                    warnExceeding=true
                </Divider>
                <Space direction="vertical" style={{ width: 170 }}>
                    <AppProgress warnExceeding={true} percent={70} />
                    <AppProgress warnExceeding={true} percent={91} />
                    <AppProgress
                        warnExceeding={true}
                        percent={100}
                        showInfo={false}
                    />
                    <AppProgress
                        warnExceeding={true}
                        total={333}
                        current={333}
                        steps={10}
                        showInfo={false}
                    />
                </Space>
            </Space>
            <Space direction="vertical">
                <Divider
                    plain={true}
                    orientation="left"
                    style={{ marginBottom: '0px', marginTop: '15px' }}
                >
                    total=200 & current=100
                </Divider>
                <Space direction="vertical" style={{ width: 170 }}>
                    <AppProgress total={200} current={100} />
                </Space>
            </Space>
            <Space direction="vertical">
                <Divider
                    plain={true}
                    orientation="left"
                    style={{ marginBottom: '0px', marginTop: '15px' }}
                >
                    infoInColor=true
                </Divider>
                <Space direction="vertical" style={{ width: 170 }}>
                    <AppProgress infoInColor={true} total={200} current={100} />
                    <AppProgress
                        infoInColor={true}
                        showActualNumbers={true}
                        total={200}
                        current={180}
                    />
                </Space>
            </Space>
            <Space direction="vertical">
                <Divider
                    plain={true}
                    orientation="left"
                    style={{ marginBottom: '0px', marginTop: '15px' }}
                >
                    showActualNumbers=true & infoExtra='extra'
                </Divider>
                <Space direction="vertical" style={{ width: 170 }}>
                    <AppProgress
                        showActualNumbers={true}
                        total={200}
                        current={100}
                    />
                    <AppProgress
                        showActualNumbers={true}
                        infoExtra={'extra'}
                        total={200}
                        current={190}
                    />
                </Space>
            </Space>
        </Space>
    );
}
