


export interface CmonUserProps  {
    acl?: string;
    cdt_path?: string;
    created?: string;
    disabled?: boolean;
    distinguished_name?: string;
    effective_privileges?: string;
    email_address?: string;
    first_name?: string;
    groups?: string;
    job_title?: string;
    last_failed_login?: number;
    last_login?: string;
    last_name?: string;
    middle_name?: string;
    n_failed_logins?: number;
    origin?: string;
    owner_group_id?: number;
    owner_group_name?: string;
    owner_user_id?: number;
    owner_user_name?: string;
    password_encrypted?: string;
    password_format?: string;
    password_salt?: string;
    public_keys?: string;
    suspended?: boolean;
    tags?: string;
    timezone?: string;
    title?: string;
    user_id?: number;
    user_name?: string;

}



export default class CmonUser {
    public acl?: string;
    public cdtPath?: string;
    public created?: string;
    public disabled?: boolean;
    public distinguishedName?: string;
    public effectivePrivileges?: string;
    public emailAddress?: string;
    public firstName?: string;
    public groups?: string;
    public jobTitle?: string;
    public lastFailedLogin?: number;
    public lastLogin?: string;
    public lastName?: string;
    public middleName?: string;
    public nFailedLogins?: number;
    public origin?: string;
    public ownerGroupId?: number;
    public ownerGroupName?: string;
    public ownerUserId?: number;
    public ownerUserName?: string;
    public passwordEncrypted?: string;
    public passwordFormat?: string;
    public passwordSalt?: string;
    public publicKeys?: string;
    public suspended?: boolean;
    public tags?: string;
    public timezone?: string;
    public title?: string;
    public userId?: number;
    public userName?: string;


    constructor(props: CmonUserProps) {
        this.acl = props.acl;
        this.cdtPath = props.cdt_path;
        this.created = props.created;
        this.disabled = props.disabled;
        this.distinguishedName = props.distinguished_name;
        this.effectivePrivileges = props.effective_privileges;
        this.emailAddress = props.email_address;
        this.firstName = props.first_name;
        this.groups = props.groups;
        this.jobTitle = props.job_title;
        this.lastFailedLogin = props.last_failed_login;
        this.lastLogin = props.last_login;
        this.lastName = props.last_name;
        this.middleName = props.middle_name;
        this.nFailedLogins = props.n_failed_logins;
        this.origin = props.origin;
        this.ownerGroupId = props.owner_group_id;
        this.ownerGroupName = props.owner_group_name;
        this.ownerUserId = props.owner_user_id;
        this.ownerUserName = props.owner_user_name;
        this.passwordEncrypted = props.password_encrypted;
        this.passwordFormat = props.password_format;
        this.passwordSalt = props.password_salt;
        this.publicKeys = props.public_keys;
        this.suspended = props.suspended;
        this.tags = props.tags;
        this.timezone = props.timezone;
        this.title = props.title;
        this.userId = props.user_id;
        this.userName = props.user_name;

    }

}
