import React from 'react';
import AppRibbon, { AppRibbonProps } from './AppRibbon';

export default BetaRibbon;

export type BetaRibbonProps = AppRibbonProps & {
    margin?: number;
};

function BetaRibbon({ margin, style, ...rest }: BetaRibbonProps) {
    return (
        <AppRibbon
            text="Beta"
            style={{ marginRight: `-${margin}px`, ...style }}
            {...rest}
        />
    );
}
