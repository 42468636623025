import React from 'react';
import { Tooltip, TooltipProps } from 'antd';
import './AppTooltip.less';
import classNames from 'classnames';

export default AppTooltip;

export type AppTooltipProps = TooltipProps & { size?: 'large' | 'medium' };
function AppTooltip({
    overlayClassName,
    size = 'medium',
    ...rest
}: AppTooltipProps) {
    return (
        <Tooltip
            overlayClassName={classNames(
                'AppTooltip_overlay',
                { 'AppTooltip_overlay--size-large': size === 'large' },
                overlayClassName
            )}
            {...rest}
        />
    );
}
