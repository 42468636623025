
import CmonContainerServer, { CmonContainerServerProps } from './CmonContainerServer';


export interface CmonCloudServerProps  extends CmonContainerServerProps {

}



export default class CmonCloudServer extends CmonContainerServer {


    constructor(props: CmonCloudServerProps) {
        super(props);

    }

}
