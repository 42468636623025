import React, { useState } from 'react';
import { ButtonProps } from 'antd/lib/button';
import {
    notifyError,
    notifyOperationSuccess,
    NotifyType,
} from '../Notifications/uiNotification';
import { useDebugContext } from '../../common/Debug';
import AppConfirmActionButton from '../../common/General/AppConfirmActionButton';
import CcCluster from '../../services/models/CcCluster';
import CmonStatService from '../../services/cmon/CmonStatService';

export default KillQueryButton;

export type KillQueryButtonProps = ButtonProps & {
    cluster: CcCluster;
    children?: React.ReactNode;
    hostPort?: string;
    pid?: number;
    onSuccess?: () => void;
};

function KillQueryButton({
    cluster,
    children,
    hostPort,
    pid,
    onSuccess,
    ...rest
}: KillQueryButtonProps) {
    const { log } = useDebugContext();
    const [loading, setLoading] = useState(false);
    const handleClick = async () => {
        try {
            setLoading(true);
            await CmonStatService.qm_killprocess(
                {
                    cluster_id: cluster.clusterId,
                    host_port: hostPort,
                    pid: pid,
                },
                { requestName: '' }
            );
            notifyOperationSuccess({
                type: NotifyType.TOAST,
                title: <span>Query was killed succesfuly.</span>,
            });
            if (onSuccess) {
                onSuccess();
            }
        } catch (err) {
            log.error(err);
            notifyError({ content: err.message });
        }
        setLoading(false);
    };

    return (
        <AppConfirmActionButton
            confirmTitle="Kill this process?"
            onConfirm={handleClick}
            loading={loading}
            critical={true}
            {...rest}
        >
            {children || 'Kill query'}
        </AppConfirmActionButton>
    );
}
