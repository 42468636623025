import React from 'react';
import ButtonWithForm from '../../common/General/ButtonWithForm';
import ActionButton from '@severalnines/bar-frontend-components/build/lib/General/ActionButton';
import CcCluster from '../../services/models/CcCluster';

import SSLModalWizard from './SSLModalWizard';

export default ClusterEnableSSLButton;

export type ClusterEnableSSLButton = {
    cluster?: CcCluster;
    galera?: boolean;
};

function ClusterEnableSSLButton({ cluster, galera }: ClusterEnableSSLButton) {
    const isGalera = () => {
        if (galera) {
            return !cluster?.isReplicationSSLEnabled()
                ? 'Enable Galera SSL encryption'
                : 'Change Galera SSL Certificate';
        } else {
            return !cluster?.isRegularSSLEnabled()
                ? 'Enable SSL encryption'
                : 'Change SSL Certificate';
        }
    };
    return (
        <ButtonWithForm
            button={<ActionButton>{isGalera()}</ActionButton>}
            form={<SSLModalWizard galera={galera} cluster={cluster} />}
        />
    );
}
