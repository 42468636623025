import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Input, Row, Space, Typography } from 'antd';
import Switch from '../../../common/DataEntry/Switch';
import InfoIcon from '@severalnines/bar-frontend-components/build/lib/General/InfoIcon';
import NodeTree, { NodeTreeDataNode } from '../../../common/NodeTree';
import { DeleteOutlined } from '@ant-design/icons';
import { StatusFormatStatus } from '@severalnines/bar-frontend-components/build/lib/Format/StatusFormat';
import FormItemInlineSwitch from '../../../common/DataEntry/FormItemInlineSwitch';
import DisableFirewallSwitch from '../../../common/Form/Fields/DisableFirewallSwitch';
import InstallSoftwareSwitch from '../../../common/Form/Fields/InstallSoftwareSwitch';
import DisableSelinuxSwitch from '../../../common/Form/Fields/DisableSelinuxSwitch';
import { FormInstance } from 'antd/lib/form';
import { BackupFormFieldsType } from '../Config/BackupFormConfigurator';

export default BackupVerifyForm;

export type BackupVerifyFormProps = {
    form: FormInstance<BackupFormFieldsType>;
};
function BackupVerifyForm({ form }: BackupVerifyFormProps) {
    const [host, setHost] = useState<NodeTreeDataNode | undefined>(
        form.getFieldValue('verificationHost')
            ? getHost(form.getFieldValue('verificationHost'))
            : undefined
    );

    const handleAddHostKeyDown = (event: any) => {
        if (event.key === 'Enter') {
            event.preventDefault();
        }
    };
    const handleAddHostKeyUp = (event: any) => {
        if (event.key === 'Enter') {
            handleAddHost();
        }
    };
    const handleAddHost = () => {
        const currentHost = form.getFieldValue('verificationHostField');
        if (currentHost) {
            setHost(getHost(currentHost));
            form.setFieldsValue({
                verificationHost: currentHost,
                verificationHostField: '',
            });
        }
    };

    useEffect(() => {
        if (!host) {
            form.setFieldsValue({
                verificationHost: '',
            });
        }
    }, [host]);

    return (
        <div className="BackupVerifyForm">
            <Row gutter={[24, 0]}>
                <Col span={24}>
                    <h3>Verify Settings</h3>
                </Col>
                <Col span={12}>
                    <Form.Item
                        name="verificationHostField"
                        label={
                            <Space>
                                <span>
                                    <Typography.Text type="danger">
                                        *
                                    </Typography.Text>{' '}
                                    Restore Backup On
                                </span>
                                <InfoIcon info="Enter a FQDN or IP Address. This host will be used to restore and verify that backup set." />
                            </Space>
                        }
                    >
                        <Input
                            placeholder="Enter FQDN or IP-address"
                            onKeyDown={handleAddHostKeyDown}
                            onKeyUp={handleAddHostKeyUp}
                            addonAfter={
                                <Button
                                    type="link"
                                    size="small"
                                    onClick={handleAddHost}
                                >
                                    +
                                </Button>
                            }
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <NodeTree
                        treeData={host ? [host] : []}
                        fitContent={true}
                        placeholderTree={[
                            {
                                key: 'host',
                                isLeaf: false,
                                title: (
                                    <span>
                                        ---.---.---.---
                                        <br />
                                        <small />
                                    </span>
                                ),
                            },
                        ]}
                    />
                </Col>
            </Row>
            <Space>
                <Space />
            </Space>
            <Row gutter={[24, 0]}>
                <Col span={24}>
                    <h3>Additional Settings</h3>
                </Col>
                <Col xs={24} sm={24} md={12}>
                    <InstallSoftwareSwitch
                        name="verificationInstallSoftware"
                        label="Install database software"
                        noMargin
                    />
                </Col>
                <Col xs={24} sm={24} md={12}>
                    <DisableFirewallSwitch
                        name="verificationDisableFirewall"
                        noMargin
                    />
                </Col>
            </Row>
            <Row gutter={[24, 0]}>
                <Col xs={24} sm={24} md={12}>
                    <FormItemInlineSwitch
                        justify={true}
                        name="verificationShutdownAfter"
                        label="Shut down the server after backup restored"
                        valuePropName="checked"
                    >
                        <Switch />
                    </FormItemInlineSwitch>
                </Col>
                <Col xs={24} sm={24} md={12}>
                    <DisableSelinuxSwitch name="verificationDisableSELinux" />
                </Col>
            </Row>
            <Row gutter={[24, 0]}>
                <Col xs={24} sm={24} md={16}>
                    <Form.Item
                        name="verificationStartIn"
                        label={
                            <Space>
                                Start backup verification (after completion) in:
                            </Space>
                        }
                        extra={
                            'You can set verification up to 24 hours after completion'
                        }
                    >
                        <Input placeholder="0 hours" addonAfter="hour(s)" />
                    </Form.Item>
                </Col>
            </Row>
        </div>
    );

    function handleRemoveHost() {
        setHost(undefined);
    }

    function getHost(host: string) {
        return {
            key: 'host',
            title: (
                <span>
                    {host}
                    <br />
                    <small>Verification Host</small>
                </span>
            ),
            isLeaf: false,
            status: StatusFormatStatus.success,
            extra: (
                <Space>
                    <Button
                        disabled={false}
                        type="link"
                        size="small"
                        onClick={handleRemoveHost}
                        danger={true}
                        data-testid="btn-remove-verify-host"
                    >
                        <DeleteOutlined />
                    </Button>
                </Space>
            ),
        };
    }
}

export function getBackupVerifyValidate(form: FormInstance) {
    return [
        () => {
            if (!form.getFieldValue('verificationHost')) {
                throw new Error('Please add host to restore backup on!');
            }
        },
    ];
}
