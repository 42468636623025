import React from 'react';
import './AppSlider.less';
import { Slider, SliderSingleProps } from 'antd';
import classNames from 'classnames';

export default AppSlider;

export type AppSliderProps = SliderSingleProps & {};

function AppSlider({ className, ...rest }: AppSliderProps) {
    return <Slider className={classNames(className, 'AppSlider')} {...rest} />;
}
