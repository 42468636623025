import React from 'react';
import SpaceDescriptions from '../../../common/Layout/SpaceDescriptions';
import SpaceWide from '../../../common/SpaceWide';
import { FormInstance } from 'antd/lib/form';
import TypographyText from '../../../common/TypographyText';
import { getCloudCredentialsSummary } from './CloudCredentialsSummary';
import AppDivider from '../../../common/AppDivider';
import { CcCloudProviderType } from '../../../services/models/CcCloudCredentials';

export default CloudCredentialsWizardSummary;

export type CloudCredentialsWizardSummaryProps = {
    form: FormInstance;
    type: CcCloudProviderType;
};

function CloudCredentialsWizardSummary({
    form,
    type,
}: CloudCredentialsWizardSummaryProps) {
    return (
        <SpaceWide
            size={10}
            direction="vertical"
            className="CloudCredentialsWizardSummary"
        >
            <SpaceDescriptions
                direction="vertical"
                title="Integration"
                size="small"
                alignItems="right"
            >
                <SpaceDescriptions.Item label="Name" labelStrong>
                    <TypographyText>
                        {form.getFieldValue('name')}
                    </TypographyText>
                </SpaceDescriptions.Item>
                <SpaceDescriptions.Item label="Comment" labelStrong>
                    <TypographyText>
                        {form.getFieldValue('comment') || 'None'}
                    </TypographyText>
                </SpaceDescriptions.Item>
            </SpaceDescriptions>
            <AppDivider />
            {getCloudCredentialsSummary({ type, form })}
        </SpaceWide>
    );
}
