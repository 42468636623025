import React from 'react';
import ActionButton from '@severalnines/bar-frontend-components/build/lib/General/ActionButton';
import ButtonWithForm from '../../../common/General/ButtonWithForm';
import { Space } from 'antd';
import { LdapGroupMapIcon } from '../../../common/icons/icons';
import LdapMapGroupForm from './LdapMapGroupForm';
import { LdapConfigGroupMappingsItem } from './LdapTypes';

export default LdapMapGroupButton;

export type LdapMapGroupButtonProps = {
    item?: LdapConfigGroupMappingsItem;
    onSuccess?: () => void;
    children?: React.ReactNode;
};

function LdapMapGroupButton({
    item,
    onSuccess,
    children,
    ...rest
}: LdapMapGroupButtonProps) {
    return (
        <ButtonWithForm
            button={
                <ActionButton {...rest}>
                    {children || (
                        <Space>
                            <LdapGroupMapIcon />
                            <span>Map LDAP group</span>
                        </Space>
                    )}
                </ActionButton>
            }
            form={<LdapMapGroupForm item={item} onSuccess={onSuccess} />}
        />
    );
}
