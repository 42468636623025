import useCreateJob from '../../Jobs/useCreateJob';
import { CmonJobInstanceCommand } from '../../../services/cmon/models/CmonJobInstance';
import CcCluster from '../../../services/models/CcCluster';
import { FormInstance } from 'antd/lib/form';

export default usePBMSetup;
type UsePBMSetupProps = {
    cluster: CcCluster;
    form: FormInstance;
    onSuccess?: () => void;
    reinstall?: boolean;
};

function usePBMSetup({
    cluster,
    form,
    onSuccess,
    reinstall = false,
}: UsePBMSetupProps) {
    const { loading, send } = useCreateJob({
        clusterId: cluster?.clusterId,
        title: `${reinstall ? 'Reinstall' : 'Install'} Percona Backup MongoDB`,
        command: `${CmonJobInstanceCommand.PBMAGENT.toLowerCase()}` as CmonJobInstanceCommand,
    });

    const setup = async () => {
        try {
            await form.validateFields(['pbm_directory']);
            await send({
                action: reinstall ? 'reinstall' : 'setup',
                nodes: [
                    {
                        class_name: 'CmonPBMAgentHost',
                        backup_dir: form.getFieldValue('pbm_directory'),
                        hostname: '*',
                    },
                ],
                clusterId: cluster?.clusterId,
            });
            onSuccess?.();
        } catch (e: any) {
            console.error(`Install backup tool error: ${e.message}`, e);
        }
    };

    return {
        loading,
        setup,
    };
}
