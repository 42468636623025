


export interface CmonAccountProps  {
    acl?: string;
    auth_plugin?: string;
    cdt_path?: string;
    connections?: number;
    grants?: string;
    host_allow?: string;
    max_connections?: number;
    max_questions?: number;
    max_updates?: number;
    max_user_connections?: number;
    own_database?: string;
    owner_group_id?: number;
    owner_group_name?: string;
    owner_user_id?: number;
    owner_user_name?: string;
    password?: string;
    password_expired?: boolean;
    private?: boolean;
    ssl_type?: number;
    system_user?: boolean;
    user_name?: string;

}



export default class CmonAccount {
    public acl?: string;
    public authPlugin?: string;
    public cdtPath?: string;
    public connections?: number;
    public grants?: string;
    public hostAllow?: string;
    public maxConnections?: number;
    public maxQuestions?: number;
    public maxUpdates?: number;
    public maxUserConnections?: number;
    public ownDatabase?: string;
    public ownerGroupId?: number;
    public ownerGroupName?: string;
    public ownerUserId?: number;
    public ownerUserName?: string;
    public password?: string;
    public passwordExpired?: boolean;
    public private?: boolean;
    public sslType?: number;
    public systemUser?: boolean;
    public userName?: string;


    constructor(props: CmonAccountProps) {
        this.acl = props.acl;
        this.authPlugin = props.auth_plugin;
        this.cdtPath = props.cdt_path;
        this.connections = props.connections;
        this.grants = props.grants;
        this.hostAllow = props.host_allow;
        this.maxConnections = props.max_connections;
        this.maxQuestions = props.max_questions;
        this.maxUpdates = props.max_updates;
        this.maxUserConnections = props.max_user_connections;
        this.ownDatabase = props.own_database;
        this.ownerGroupId = props.owner_group_id;
        this.ownerGroupName = props.owner_group_name;
        this.ownerUserId = props.owner_user_id;
        this.ownerUserName = props.owner_user_name;
        this.password = props.password;
        this.passwordExpired = props.password_expired;
        this.private = props.private;
        this.sslType = props.ssl_type;
        this.systemUser = props.system_user;
        this.userName = props.user_name;

    }

}
