import React from 'react';
import './Agreement.less';

export default Agreement;

function Agreement() {
    return (
        <div className="Agreement">
            <div className="Agreement_content">
                <h1>ClusterControl License Agreement</h1>
                <p>
                    Regarding ClusterControl software (the “Licensed Software”)
                    that is wholly owned by Severalnines AB, the following terms
                    and conditions shall apply:
                </p>
                <ul>
                    <li>
                        1. Licensee's use is restricted to a limited,
                        non-exclusive, non-transferable license to run copies of
                        the object code version of the Licensed Software to
                        manage Licensee's database servers.
                        <br />
                        For Licensees installing the Licensed Software for first
                        time trials, the Licensor grants to Licensee a
                        non-exclusive, non-transferable, time-limited enterprise
                        license to use the Licensed Software for evaluation
                        purposes only. This enterprise license trial period
                        begins when Licensee downloads the Licensed Software and
                        ends thirty (30) calendar days after running the
                        Licensed Software.
                        <br />
                        For the sake of clarity, enterprise licenses for trials
                        are limited to new users only.{' '}
                    </li>
                    <li>
                        2. Licensee’s use is limited to internal business
                        purposes. If the Licensed Software is licensed for
                        concurrent or network use, Licensee is prohibited from
                        allowing more than the maximum number of authorized
                        users to access and use the Licensed Software
                        concurrently.
                    </li>
                    <li>
                        3. Licensee is only permitted to make copies of the
                        Licensed Software for backup and archival purposes.
                    </li>
                    <li>
                        4. Licensee is prohibited from: (a) copying the Licensed
                        Software onto any public or distributed network; (b)
                        using the Licensed Software to operate in or as a
                        time-sharing, outsourcing, service bureau, application
                        service provider or managed service provider
                        environment; (c) changing any proprietary rights notices
                        which appear in the Licensed Software; or (d) modifying
                        the Licensed Software.
                    </li>
                    <li>
                        5. All third party licensors and suppliers retain all
                        right, title and interest in third party software and
                        all copies thereof, including all copyright and other
                        intellectual property rights.
                    </li>
                    <li>
                        6. Licensee is permitted to transfer the license of the
                        Licensed Software only if (a) Licensee complies with any
                        transfer terms imposed by Severalnines and delivers the
                        Licensed Software to the transferee along with the EULA
                        terms and conditions contained herein, (b) transferee
                        accepts the EULA terms and conditions as a condition to
                        any transfer, and (c) Licensee’s license to use the
                        Licensed Software terminates upon transfer.
                    </li>
                    <li>
                        7. Licensee will comply with all applicable export laws
                        and regulations.
                    </li>
                    <li>
                        8. Licensee will immediately destroy all copies of the
                        Licensed Software upon termination of the EULA.
                    </li>
                </ul>
            </div>
        </div>
    );
}
