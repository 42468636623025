import React, { ReactNode, useEffect, useRef, useState } from 'react';
import './ClusterTopology.less';
import Diagram, {
    IDiagramItem,
} from '@severalnines/bar-frontend-components/build/lib/DataDisplay/Diagram';
import CcCluster from '../../services/models/CcCluster';
import TypographyText from '../../common/TypographyText';
import ClusterTopologyConfigurator from './ClusterTopology/ClusterTopologyConfigurator';
import { Space } from 'antd';
import useClusterList from './useClusterList';
import classNames from 'classnames';
import ClusterFormat from './ClusterFormat';
import ClusterTopologyNodeItem from './ClusterTopology/ClusterTopologyNodeItem';
import ZoomableContent from '@severalnines/bar-frontend-components/build/lib/DataDisplay/ZoomableContent';
import NodeReplicationFormat from '../Nodes/NodeReplicationFormat';
import { getClusterTopologyConfigurator } from './ClusterTopology/ClusterTopologyHelper';

export default ClusterTopology;

export type ClusterTopologyProps = {
    cluster: CcCluster;
};

function ClusterTopology({ cluster }: ClusterTopologyProps) {
    const [items, setItems] = useState<any>([]);
    const [groups, setGroups] = useState<any[]>([]);
    const { list: clusters } = useClusterList({
        name: 'cluster-list-from-cluster-topology',
    });
    const diagramRef = useRef<any>();
    const maskRef = useRef<any>();
    const configurator = useRef<ClusterTopologyConfigurator>(
        new (getClusterTopologyConfigurator(cluster.clusterType))(
            clusters as CcCluster[]
        ) as ClusterTopologyConfigurator
    );

    useEffect(() => {
        const itemList = configurator.current.getDiagramItems(cluster);

        const groups: any[] = [];
        itemList.forEach((i) => {
            groups.push(i);
        });

        setItems(itemList);
        setGroups(groups);
    }, [cluster]);

    const diagram = (
        <div ref={diagramRef}>
            <Diagram
                renderItem={(item: IDiagramItem) => (
                    <ClusterTopologyNodeItem
                        item={item}
                        configurator={configurator.current}
                    />
                )}
                renderGroup={(item: any, children: ReactNode) => {
                    return groups.length > 1 ? (
                        <Space
                            className={classNames('ClusterTypology_group')}
                            direction="vertical"
                            size={0}
                        >
                            <TypographyText
                                className="ClusterTypology_group-title"
                                muted
                            >
                                {item.data?.cluster ? (
                                    <ClusterFormat
                                        cluster={item.data.cluster}
                                        clusterLink={true}
                                    />
                                ) : (
                                    item.title
                                )}
                            </TypographyText>
                            {children}
                        </Space>
                    ) : (
                        <div>{children}</div>
                    );
                }}
                renderConnectionDot={() => null}
                renderConnectionIcon={(item, defaultIcon) => {
                    return item.data?.node ? (
                        <NodeReplicationFormat node={item.data.node}>
                            {defaultIcon}
                        </NodeReplicationFormat>
                    ) : (
                        defaultIcon
                    );
                }}
                items={items}
            />
        </div>
    );

    return (
        <div className={classNames('ClusterTopology')}>
            <div ref={maskRef}>
                <ZoomableContent
                    wheel={{ step: 0.1, wheelDisabled: true }}
                    // This makes the diagram fit the mask
                    // commented for now
                    // onInit={(ref) => {
                    //     setTimeout(() => {
                    //         ref.setTransform(
                    //             0,
                    //             0,
                    //             diagramRef.current.offsetWidth /
                    //                 diagramRef.current.offsetHeight >
                    //                 maskRef.current.offsetWidth /
                    //                     maskRef.current.offsetHeight
                    //                 ? maskRef.current.offsetWidth /
                    //                       diagramRef.current.offsetWidth
                    //                 : maskRef.current.offsetHeight /
                    //                       diagramRef.current.offsetHeight
                    //         );
                    //     });
                    // }}
                >
                    {diagram}
                </ZoomableContent>
            </div>
        </div>
    );
}
