import React from 'react';
import AppPageWrapper from '../common/Layout/AppPageWrapper';
import ClusterListMini from '../components/Clusters/ClusterListMini';

export default DebugClusterListMini;
export type DebugClusterListMiniProps = {};

function DebugClusterListMini({ ...rest }: DebugClusterListMiniProps) {
    return (
        <AppPageWrapper>
            <ClusterListMini />
        </AppPageWrapper>
    );
}
