import CmonDbStats, { CmonDbStatsProps } from '../cmon/models/CmonDbStats';
import CcDbStatsDb, { CcDbStatsDbProps } from './CcDbStatsDb';

export type CcDbStatsProps = CmonDbStatsProps & {
    year?: number;
    yearday?: number;
    data_size?: number;
    index_size?: number;
    hostname?: string;
    port?: number;
    total_datadir_size?: number;
    free_datadir_size?: number;
    dbs?: CcDbStatsDbProps[];
    created?: string;
};

export default class CcDbStats extends CmonDbStats {
    public year?: number;
    public yearday?: number;
    public dataSize?: number;
    public indexSize?: number;
    public hostname?: string;
    public port?: number;
    public totalDatadirSize?: number;
    public freeDatadirSize?: number;
    public dbs?: CcDbStatsDb[];
    public created?: string;
    public createdDate?: Date;

    constructor(props: CcDbStatsProps) {
        super(props);
        this.year = props.year;
        this.yearday = props.yearday;
        this.dataSize = props.data_size;
        this.indexSize = props.index_size;
        this.hostname = props.hostname;
        this.port = props.port;
        this.totalDatadirSize = props.total_datadir_size;
        this.freeDatadirSize = props.free_datadir_size;
        this.dbs = props.dbs && props.dbs.map((db) => new CcDbStatsDb(db));
        this.created = props.created;
        this.createdDate = new Date(props.year as number, 0, props.yearday);
    }
}
