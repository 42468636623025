import React from 'react';
import { CmonJobInstanceCommand } from '../../services/cmon/models/CmonJobInstance';
import useJobWatcher from './useJobWatcher';
import useCreateJob from './useCreateJob';
import { CcClusterType } from '../../services/models/CcCluster';
import JobButtonBarrier from '../../common/Barrier/JobButtonBarrier';
import AppConfirmActionButton, {
    AppConfirmActionButtonProps,
} from '../../common/General/AppConfirmActionButton';

export default JobConfirmActionButton;

export type JobConfirmActionButtonProps = AppConfirmActionButtonProps & {
    clusterId?: number;
    clusterType?: CcClusterType;
    command: CmonJobInstanceCommand;
    title: string;
    confirmTitle: React.ReactNode;
    children?: React.ReactNode;
    onSuccess?: () => void;
    jobData?: any;
    extraKey?: (string | number | undefined)[];
};

function JobConfirmActionButton({
    clusterId,
    clusterType,
    command,
    title,
    confirmTitle,
    children,
    onSuccess,
    jobData,
    extraKey,
    ...rest
}: JobConfirmActionButtonProps) {
    const { loading: loadingJob } = useJobWatcher({
        command,
        clusterId,
        extraKey,
    });
    const { loading, send } = useCreateJob({
        clusterId,
        title,
        command: command,
        onSuccess,
    });

    const handleConfirm = async () => {
        await send(jobData);
    };
    return (
        <JobButtonBarrier command={command} clusterType={clusterType}>
            <AppConfirmActionButton
                className="JobConfirmActionButton"
                confirmTitle={confirmTitle || `${title}?`}
                onConfirm={handleConfirm}
                loading={loading || loadingJob}
                {...rest}
            >
                {children || title}
            </AppConfirmActionButton>
        </JobButtonBarrier>
    );
}
