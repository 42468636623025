import React from 'react';
import { Form, Col, Row, Space } from 'antd';
import FormItemInlineSwitch from '../../../../../../common/DataEntry/FormItemInlineSwitch';
import InputNumberWide from '../../../../../../common/DataEntry/InputNumberWide';
import InfoIcon from '@severalnines/bar-frontend-components/build/lib/General/InfoIcon';

export default ProxySQLRulesSetting;

export const RULES_SETTING_INITIAL = {
    rulesSetting: {
        active: true,
        apply: true,
    },
};

function ProxySQLRulesSetting() {
    return (
        <div style={{ minHeight: 450 }}>
            <Row gutter={[24, 0]}>
                <Col span={24}>
                    <h3>Setting</h3>
                </Col>
            </Row>
            <Row gutter={[24, 0]}>
                <Col span={12}>
                    <FormItemInlineSwitch
                        justify={true}
                        label={
                            <Space>
                                Active
                                <InfoIcon
                                    info={
                                        'Only active RULES will be considered by the query processing module.'
                                    }
                                />
                            </Space>
                        }
                        name={['rulesSetting', 'active']}
                        labelStrong={false}
                        valuePropName="checked"
                    />
                </Col>
                <Col span={12}>
                    <FormItemInlineSwitch
                        justify={true}
                        label={
                            <Space>
                                Apply
                                <InfoIcon
                                    info={
                                        'This, together with flag in and flag out, allow us to create "chains of rules" that get applied one after the other. An input flag value is set to 0, and only rules with flagIN=0 are considered at the beginning. When a matching rule is found for a specific query, flagOUT is evaluated and if NOT NULL the query will be flagged with the specified flag in flagOUT. If flagOUT differs from flagIN , the query will exit the current chain and enters a new chain of rules having flagIN as the new input flag. If flagOUT matches flagIN, the query will be re-evaluate again against the first rule with said flagIN. This happens until there are no more matching rules, or apply is set to 1 (which means this is the last rule to be applied).'
                                    }
                                />
                            </Space>
                        }
                        name={['rulesSetting', 'apply']}
                        labelStrong={false}
                        valuePropName="checked"
                    />
                </Col>
            </Row>
            <Row gutter={[24, 0]}>
                <Col span={12}>
                    <Form.Item
                        name={['rulesSetting', 'timeout']}
                        label={
                            <Space>
                                Timeout
                                <InfoIcon
                                    info={
                                        'The maximum timeout in milliseconds with which the matched or rewritten query should be executed. If a query run for longer than the specific threshold, the query is automatically killed. If timeout is not specified, global variable mysql-default_query_timeout applies.'
                                    }
                                />
                            </Space>
                        }
                    >
                        <InputNumberWide
                            min={0}
                            placeholder="Click here to enter timeout."
                        ></InputNumberWide>
                    </Form.Item>
                </Col>

                <Col span={12}>
                    <Form.Item
                        name={['rulesSetting', 'cacheTTL']}
                        label={
                            <Space>
                                Cache TTL
                                <InfoIcon
                                    info={
                                        'The number of milliseconds for which to cache the result of the query. Note: in ProxySQL 1.1 cache_ttl was in seconds.'
                                    }
                                />
                            </Space>
                        }
                    >
                        <InputNumberWide
                            min={0}
                            placeholder="Click here to enter Cache TTL."
                        ></InputNumberWide>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        name={['rulesSetting', 'retries']}
                        label={
                            <Space>
                                Retries
                                <InfoIcon
                                    info={
                                        'The maximum number of times a query needs to be re-executed in case of detected failure during the execution of the query. If retries is not specified, global variable mysql-query_retries_on_failure applies.'
                                    }
                                />
                            </Space>
                        }
                    >
                        <InputNumberWide
                            min={0}
                            placeholder="Click here to enter retries."
                        ></InputNumberWide>
                    </Form.Item>
                </Col>
            </Row>
        </div>
    );
}
