import React from 'react';
import ButtonWithForm from '../../common/General/ButtonWithForm';
import { ButtonProps } from 'antd/lib/button';
import { Button } from 'antd';
import CcUser from '../../services/models/CcUser';
import UserSettingsModal from './UserSettingsModal';

export default UserSettingsButton;
export type UserSettingsButtonProps = ButtonProps & {
    user: CcUser;
    onSuccess?: () => void;
};

function UserSettingsButton({
    children,
    user,
    onSuccess,
    ...rest
}: UserSettingsButtonProps) {
    return (
        <ButtonWithForm
            button={<Button {...rest}>{children || 'Settings'}</Button>}
            form={<UserSettingsModal user={user} onSuccess={onSuccess} />}
        />
    );
}
