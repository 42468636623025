
import CmonRequestService, {CmonRequestServiceResponseData} from './CmonRequestService';
import {CmonAccountProps} from './models/CmonAccount'
import {CmonDataBaseProps} from './models/CmonDataBase'


export interface GetConfigRequestData {
    cluster_id?: number;
    request_created?: string;
    request_id?: number;
    [key: string]: any;
}

export interface GetConfigGroupedRequestData {
    cluster_id?: number;
    request_created?: string;
    request_id?: number;
    [key: string]: any;
}

export interface SetConfigRequestData {
    cluster_id?: number;
    configuration?: [];
    request_created?: string;
    request_id?: number;
    [key: string]: any;
}

export interface GetAllClusterInfoRequestData {
    request_created?: string;
    with_hosts?: boolean;
    with_sheet_info?: boolean;
    [key: string]: any;
}

export interface CreateAccountRequestData {
    account?: CmonAccountProps;
    cluster_id?: number;
    request_created?: string;
    [key: string]: any;
}

export interface UpdateAccountRequestData {
    account?: CmonAccountProps;
    cluster_id?: number;
    request_created?: string;
    [key: string]: any;
}

export interface GetAccountsRequestData {
    cluster_id?: number;
    request_created?: string;
    request_id?: number;
    [key: string]: any;
}

export interface ListAccountsRequestData {
    cluster_id?: number;
    request_created?: string;
    request_id?: number;
    [key: string]: any;
}

export interface DeleteAccountRequestData {
    account?: CmonAccountProps;
    cluster_id?: number;
    request_created?: string;
    [key: string]: any;
}

export interface GrantPrivilegesRequestData {
    account?: CmonAccountProps;
    cluster_id?: number;
    privileges?: string;
    [key: string]: any;
}

export interface RevokePrivilegesRequestData {
    account?: CmonAccountProps;
    cluster_id?: number;
    [key: string]: any;
}

export interface GetSqlProcessesRequestData {
    cluster_id?: number;
    request_created?: string;
    request_id?: number;
    [key: string]: any;
}

export interface GetTopQueriesRequestData {
    cluster_id?: number;
    filterStrings?: [];
    limit?: number;
    offset?: number;
    request_created?: string;
    request_id?: number;
    [key: string]: any;
}

export interface CreateDatabaseRequestData {
    cluster_id?: number;
    database?: CmonDataBaseProps;
    request_created?: string;
    [key: string]: any;
}

export interface PingRequestData {
    request_created?: string;
    request_id?: number;
    [key: string]: any;
}

export interface AvailableUpgradesRequestData {
    cluster_id?: number;
    [key: string]: any;
}


export default class CmonClustersService extends CmonRequestService {
    
    public static module(): string {
        return 'clusters';
    }
    
    public static async getConfig(
        data: GetConfigRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('getConfig', data, opts);
    }

    public static async getConfigGrouped(
        data: GetConfigGroupedRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('getConfigGrouped', data, opts);
    }

    public static async setConfig(
        data: SetConfigRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('setConfig', data, opts);
    }

    public static async getAllClusterInfo(
        data: GetAllClusterInfoRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('getAllClusterInfo', data, opts);
    }

    public static async createAccount(
        data: CreateAccountRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('createAccount', data, opts);
    }

    public static async updateAccount(
        data: UpdateAccountRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('updateAccount', data, opts);
    }

    public static async getAccounts(
        data: GetAccountsRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('getAccounts', data, opts);
    }

    public static async listAccounts(
        data: ListAccountsRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('listAccounts', data, opts);
    }

    public static async deleteAccount(
        data: DeleteAccountRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('deleteAccount', data, opts);
    }

    public static async grantPrivileges(
        data: GrantPrivilegesRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('grantPrivileges', data, opts);
    }

    public static async revokePrivileges(
        data: RevokePrivilegesRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('revokePrivileges', data, opts);
    }

    public static async getSqlProcesses(
        data: GetSqlProcessesRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('getSqlProcesses', data, opts);
    }

    public static async getTopQueries(
        data: GetTopQueriesRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('getTopQueries', data, opts);
    }

    public static async createDatabase(
        data: CreateDatabaseRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('createDatabase', data, opts);
    }

    public static async ping(
        data: PingRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('ping', data, opts);
    }

    public static async availableUpgrades(
        data: AvailableUpgradesRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('availableUpgrades', data, opts);
    }

}
