import { useCallback, useEffect, useReducer, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    AppState,
    AppStateScheduledMaintenances,
    setScheduledMaintenances,
} from '../../appReducer';
import Immutable from 'immutable';
import { arrayFilter, arrayPages } from '../../common/filtering';
import useListFetch from '../../common/useListFetch';

import {
    initialListState,
    listReducer,
    setListAll,
} from '../../common/listReducer';
import { getSortDateFn } from '../../common/sorting';
import { FetchRefreshFunctionParams } from '../../common/useFetch';
import CmonMaintenanceService from '../../services/cmon/CmonMaintenanceService';
import CcMaintenanceInfo, {
    CcMaintenanceInfoProps,
    CcMaintenancePeriod,
} from '../../services/models/CcMaintenanceInfo';

type ListParams = {
    page?: number;
    pageSize?: number;
    order?: (a: any, b: any) => number;
    filters?: Function[];
};

type FilterFunctionParams = ListParams & { arr?: any[] };

type RefreshFunctionParams = FetchRefreshFunctionParams;

export type UseScheduledMaintenancesListProps = ListParams & {
    name?: string | null;
    useGlobalState?: boolean;
    useCache?: boolean;
};
export default function useScheduledMaintenancesList({
    name,
    useGlobalState = true,
    useCache = false,
    filters,
    pageSize = 10,
}: UseScheduledMaintenancesListProps = {}) {
    const {
        error,
        loading,
        loaded,
        list: scheduledMaintenances,
        refresh: refreshFetch,
        cancel,
    } = useListFetch({
        name,
        useCache,
        pageSize: 0,
        fetchFn: async (params, opts) => {
            const {
                maintenance_records,
                total,
            } = await CmonMaintenanceService.getMaintenance(
                {
                    ...params,
                },
                opts
            );
            return {
                list:
                    maintenance_records.map(
                        (m: CcMaintenanceInfoProps) => new CcMaintenanceInfo(m)
                    ) || [],
                total: total,
            };
        },
        cancelFn: async ({ requestId }) => {
            await CmonMaintenanceService.cancelRequest(requestId);
        },
    });
    const [list, setList] = useState<CcMaintenancePeriod[]>();
    const [total, setTotal] = useState<number>();
    const [storedScheduledMaintenances]: [
        AppStateScheduledMaintenances
    ] = useSelector(({ scheduledMaintenances }: AppState) => [
        scheduledMaintenances,
    ]);
    const dispatch = useDispatch();
    const [
        {
            page: listPage,
            pageSize: listPageSize,
            order: listOrder,
            filters: listFilters,
        },
        listDispatch,
    ] = useReducer(listReducer, {
        ...initialListState,
        pageSize: pageSize,
        order: getSortDateFn('ascend', (x) => x.initiate),
        filters: filters,
    });

    const filter = useCallback<(p?: FilterFunctionParams) => void>(
        ({
            page = listPage,
            pageSize = listPageSize,
            order = listOrder,
            filters = listFilters,
            arr = (storedScheduledMaintenances &&
                storedScheduledMaintenances.toList().toArray()) ||
                [],
        } = {}) => {
            listDispatch(setListAll({ page, pageSize, order, filters }));
            // we create a list with periods
            const periods = arr.reduce(
                (acc: CcMaintenancePeriod[], m: CcMaintenanceInfo) => [
                    ...acc,
                    ...m.maintenancePeriods,
                ],
                []
            );
            const filteredArr = arrayFilter({ filters, arr: periods });
            setList(arrayPages({ page, pageSize, order, arr: filteredArr }));
            setTotal(filteredArr.length);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [
            listPage,
            listPageSize,
            listOrder,
            listFilters,
            storedScheduledMaintenances,
        ]
    );

    const refresh = useCallback<(p?: RefreshFunctionParams) => Promise<void>>(
        async ({
            page = listPage,
            pageSize = listPageSize,
            order = listOrder,
            filters = listFilters,
            ...rest
        } = {}) => {
            listDispatch(setListAll({ page, pageSize, order, filters }));
            await refreshFetch({ ...rest });
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    useEffect(() => {
        if (scheduledMaintenances) {
            if (useGlobalState) {
                dispatch(
                    setScheduledMaintenances(
                        Immutable.Map(
                            scheduledMaintenances.map(
                                (m: CcMaintenanceInfo) => [m.getKey(), m]
                            )
                        )
                    )
                );
            }

            filter({
                arr: scheduledMaintenances,
            });
        }
    }, [scheduledMaintenances]);

    useEffect(() => {
        if (useGlobalState && storedScheduledMaintenances) {
            filter();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [storedScheduledMaintenances]);

    return {
        error,
        loading,
        loaded,
        list,
        refresh,
        total,
        filter,
        cancel,
        page: listPage,
        pageSize: listPageSize,
    };
}
