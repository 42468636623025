import React from 'react';
import { Progress, ProgressProps } from 'antd';
import TypographyText from './TypographyText';
import { getStatusFormatColor } from './General/AppStatusFormat';
import { StatusFormatStatus } from '@severalnines/bar-frontend-components/build/lib/Format/StatusFormat';

const PROGRESS_WARN_PERCENT = 90;
const PROGRESS_FINISH_PERCENT = 100;

export default AppProgress;
export type AppProgressProps = ProgressProps & {
    warnExceeding?: boolean;
    infoInColor?: boolean;
    infoMuted?: boolean;
    showActualNumbers?: boolean;
    infoExtra?: React.ReactNode;
    info?: () => React.ReactNode;
    total?: number;
    current?: number;
};

function AppProgress({
    warnExceeding,
    infoInColor,
    infoMuted,
    showActualNumbers,
    infoExtra,
    total,
    current,
    format,
    ...rest
}: AppProgressProps) {
    let props: ProgressProps = { ...rest };
    if (!rest.percent && total && current) {
        props = { ...props, percent: (current * 100) / total };
    }
    const status = getWarnExceedingStatus({ percent: props.percent });
    const color = getStatusFormatColor(
        getWarnExceedingStatus({ percent: props.percent })
    );
    if (warnExceeding && !props.strokeColor) {
        props = {
            ...props,
            strokeColor: color,
        };
        if (status === StatusFormatStatus.error) {
            infoInColor = true;
        }
    }
    if (infoInColor || showActualNumbers || infoMuted) {
        const formatResult = format?.(props.percent, props.success?.percent);
        props = {
            ...props,
            format: () => (
                <TypographyText
                    muted={infoMuted}
                    style={(infoInColor && { color }) || {}}
                >
                    {formatResult ||
                        (showActualNumbers &&
                            `${current || 0}/${
                                (total || 0) < 1 ? 'unlimited' : total
                            }`) ||
                        `${props.percent}%`}{' '}
                    {infoExtra}
                </TypographyText>
            ),
        };
    }

    return <Progress {...props} />;
}
type WarnExceedingColorProps = {
    total?: number;
    current?: number;
    percent?: number;
    warnPercent?: number;
    exceedPercent?: number;
};
export function getWarnExceedingStatus({
    total,
    current,
    percent,
    warnPercent = PROGRESS_WARN_PERCENT,
    exceedPercent = PROGRESS_FINISH_PERCENT,
}: WarnExceedingColorProps) {
    if (!percent && current && total) {
        percent = (current * 100) / total;
    }
    if (percent !== undefined && !isNaN(percent as any)) {
        if (percent >= exceedPercent) {
            return StatusFormatStatus.error;
        }
        if (percent >= warnPercent) {
            return StatusFormatStatus.warning;
        }
    }
    return StatusFormatStatus.info;
}
