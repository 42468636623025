import CcCluster from '../../../../../services/models/CcCluster';
import { FormInstance } from 'antd/lib/form';
import { BackupRestoreWizardFormValues } from '../BackupRestoreWizardForm';
import React from 'react';
import { Col, Form, Row } from 'antd';
import FormItemInlineSwitch from '../../../../../common/DataEntry/FormItemInlineSwitch';
import BackupDatabaseSelect from '../../../FormParts/BackupDatabaseSelect';
import {
    CcBackupMethod,
    isBackupMethodMariaBackup,
    isBackupMethodXtraBackup,
} from '../../../../../services/models/CcBackup';
import BackupRestoreUseMemoryField from './BackupRestoreUseMemoryField';
import BackupRestoreMySqlPITRForm from './BackupRestoreMySqlPITRForm';

export default BackupRestoreSettingsMySqlFormFields;
type BackupRestoreSettingsMySqlFormFieldsProps = {
    cluster: CcCluster;
    form: FormInstance<BackupRestoreWizardFormValues>;
};

function BackupRestoreSettingsMySqlFormFields({
    cluster,
    form,
}: BackupRestoreSettingsMySqlFormFieldsProps) {
    const { backup }: BackupRestoreWizardFormValues = form.getFieldsValue(true);
    const method = backup?.getMethod();
    const isMysqldump = method === CcBackupMethod.MYSQLDUMP;
    const isXtraBackup = method && isBackupMethodXtraBackup(method);
    const isXtraOrMaria =
        method && (isXtraBackup || isBackupMethodMariaBackup(method));
    const canUsePITR = backup?.canUseForPitr();

    return (
        <>
            {isXtraBackup && (
                <Row gutter={[24, 0]}>
                    <Col xs={24} sm={24} md={12}>
                        <BackupRestoreUseMemoryField />
                    </Col>
                </Row>
            )}
            <Row gutter={[24, 0]}>
                {isXtraOrMaria && (
                    <Col xs={24} sm={24} md={12}>
                        <FormItemInlineSwitch
                            justify={true}
                            name="copyDataDir"
                            label={
                                <span>
                                    Make a copy of the datadir before restoring
                                    the backup
                                </span>
                            }
                            valuePropName="checked"
                        />
                    </Col>
                )}
                {isXtraOrMaria && (
                    <Col xs={24} sm={24} md={12}>
                        <Form.Item noStyle={true} shouldUpdate={true}>
                            {() => {
                                const pitr = form.getFieldValue('pitr');
                                return (
                                    <FormItemInlineSwitch
                                        justify={true}
                                        name="bootstrapCluster"
                                        label={
                                            <span>
                                                Bootstrap cluster from restored
                                                node
                                            </span>
                                        }
                                        disabled={pitr}
                                        valuePropName="checked"
                                    />
                                );
                            }}
                        </Form.Item>
                    </Col>
                )}
            </Row>

            {isMysqldump && (
                <Row gutter={[24, 0]}>
                    <Col xs={24} sm={24} md={12}>
                        <Form.Item
                            name="databases"
                            label={<span>Restore system database</span>}
                            rules={[
                                {
                                    required: true,
                                    message: 'Please select at least one',
                                },
                            ]}
                        >
                            <BackupDatabaseSelect
                                backup={form.getFieldValue('backup')}
                            />
                        </Form.Item>
                    </Col>
                </Row>
            )}
            {isMysqldump && (
                <Row gutter={[24, 0]}>
                    <Col xs={24} sm={24} md={12}>
                        <FormItemInlineSwitch
                            justify={true}
                            name="restoreSystemDatabase"
                            label={<span>Restore system database</span>}
                        />
                    </Col>
                </Row>
            )}
            {canUsePITR && (
                <Row gutter={[24, 0]}>
                    <Col xs={24} sm={24} md={12}>
                        <FormItemInlineSwitch
                            justify={true}
                            name="pitr"
                            label={<span>Point in time recovery (PITR)</span>}
                            valuePropName="checked"
                        />
                    </Col>
                </Row>
            )}
            <Form.Item shouldUpdate={true} noStyle={true}>
                {() => {
                    if (form.getFieldValue('pitr')) {
                        return <BackupRestoreMySqlPITRForm form={form} />;
                    }
                    return null;
                }}
            </Form.Item>
        </>
    );
}
