import { FormInstance } from 'antd/lib/form';
import { NamePath } from 'rc-field-form/lib/interface';
import { TopologyItem } from '../Topology/TopologyItem';
import { StatusFormatStatus } from '@severalnines/bar-frontend-components/build/lib/Format/StatusFormat';

export function getTopologyValidator(form: FormInstance, fieldName: NamePath) {
    return async () => {
        if (
            form
                .getFieldValue(fieldName)
                ?.find(
                    (item: TopologyItem) =>
                        item.status !== StatusFormatStatus.success
                )
        ) {
            throw new Error('All nodes should be reachable');
        }
        return true;
    };
}
