import CmonAlarm, { CmonAlarmProps } from '../cmon/models/CmonAlarm';

export enum CcAlarmSeverity {
    ALARM_OK = 'ALARM_OK',
    ALARM_WARNING = 'ALARM_WARNING',
    ALARM_CRITICAL = 'ALARM_CRITICAL',
    ALARM_UNDEFINED = 'ALARM_UNDEFINED',
}

export type CcAlarmProps = CmonAlarmProps & {
    severity_name: CcAlarmSeverity;
};

export default class CcAlarm extends CmonAlarm {
    public readonly severityName: CcAlarmSeverity;

    constructor(props: CcAlarmProps) {
        super(props);
        this.severityName = props.severity_name;
    }

    public isIgnored() {
        return this.ignored === 1;
    }

    public getClusterKey(): string {
        return `${this.clusterId}`;
    }

    public getKey(): string {
        return `${this.alarmId}`;
    }
}
