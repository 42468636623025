
import CcNode, { CcNodeProps } from '../../models/CcNode';
import CcNodeReplicationSlave, { CcNodeReplicationSlaveProps } from '../../models/CcNodeReplicationSlave';
export interface CmonPostgreSqlHostReplicationSlaveProps extends CcNodeReplicationSlaveProps {
    replay_location?: string;
    replaylog_paused?: boolean;
}


export interface CmonPostgreSqlHostProps  extends CcNodeProps {
    application_name?: string;
    archive_command?: string;
    archive_mode?: string;
    connected_slaves?: number;
    data_directory?: string;
    extensions?: {[key:string]:any};
    failedmaster?: boolean;
    hba_allowall?: boolean;
    hot_standby?: boolean;
    pgname?: {[key:string]:any};
    pitr_method?: string;
    primary_conninfo?: string;
    received_location?: string;
    replication_slave?: CmonPostgreSqlHostReplicationSlaveProps;
    replication_state?: string;
    restore_command?: string;
    sync_priority?: number;
    sync_state?: string;
    synchronous?: boolean;
    total_dbsize?: number;
    trigger_file?: string;
    wal_level?: string;

}

export class CmonPostgreSqlHostReplicationSlave extends CcNodeReplicationSlave {
    public replayLocation?: string;
    public replaylogPaused?: boolean;

    constructor(props: CmonPostgreSqlHostReplicationSlaveProps) {
        super(props)
        this.replayLocation = props.replay_location;
        this.replaylogPaused = props.replaylog_paused;

    }}



export default class CmonPostgreSqlHost extends CcNode {
    public applicationName?: string;
    public archiveCommand?: string;
    public archiveMode?: string;
    public connectedSlaves?: number;
    public dataDirectory?: string;
    public extensions?: {[key:string]:any};
    public failedmaster?: boolean;
    public hbaAllowall?: boolean;
    public hotStandby?: boolean;
    public pgname?: {[key:string]:any};
    public pitrMethod?: string;
    public primaryConninfo?: string;
    public receivedLocation?: string;
    public replicationSlave?: CmonPostgreSqlHostReplicationSlave;
    public replicationState?: string;
    public restoreCommand?: string;
    public syncPriority?: number;
    public syncState?: string;
    public synchronous?: boolean;
    public totalDbsize?: number;
    public triggerFile?: string;
    public walLevel?: string;


    constructor(props: CmonPostgreSqlHostProps) {
        super(props);
        this.applicationName = props.application_name;
        this.archiveCommand = props.archive_command;
        this.archiveMode = props.archive_mode;
        this.connectedSlaves = props.connected_slaves;
        this.dataDirectory = props.data_directory;
        this.extensions = props.extensions;
        this.failedmaster = props.failedmaster;
        this.hbaAllowall = props.hba_allowall;
        this.hotStandby = props.hot_standby;
        this.pgname = props.pgname;
        this.pitrMethod = props.pitr_method;
        this.primaryConninfo = props.primary_conninfo;
        this.receivedLocation = props.received_location;
        this.replicationSlave = props.replication_slave && new CmonPostgreSqlHostReplicationSlave(props.replication_slave);
        this.replicationState = props.replication_state;
        this.restoreCommand = props.restore_command;
        this.syncPriority = props.sync_priority;
        this.syncState = props.sync_state;
        this.synchronous = props.synchronous;
        this.totalDbsize = props.total_dbsize;
        this.triggerFile = props.trigger_file;
        this.walLevel = props.wal_level;

    }

}
