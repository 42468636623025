import React from 'react';
import CcCluster from '../../../services/models/CcCluster';
import NodeList from '../../Nodes/NodeList';
import AppSectionWrapper from '../../../common/Layout/AppSectionWrapper';
import { useParams } from 'react-router-dom';
import AppPageWrapper from '../../../common/Layout/AppPageWrapper';
import ClusterTopology from '../ClusterTopology';
import { useNavigate } from 'react-router-dom';
import './ClusterNodes.less';

export default ClusterNodes;

export enum NodesSectionType {
    LIST = 'list',
    TOPOLOGY = 'topology',
}
export type ClusterNodesProps = { cluster: CcCluster };

function ClusterNodes({ cluster }: ClusterNodesProps) {
    const navigate = useNavigate();
    const { section = NodesSectionType.LIST } = useParams<{
        section: NodesSectionType;
    }>();
    return (
        <AppPageWrapper
            className="ClusterNodes"
            transparentBackground={true}
            noPadding={true}
        >
            <AppSectionWrapper
                activeSection={section}
                items={[
                    {
                        label: 'Node list',
                        children: <NodeList cluster={cluster} />,
                        key: NodesSectionType.LIST,
                    },
                    {
                        label: 'Topology',
                        children: <ClusterTopology cluster={cluster} />,
                        key: NodesSectionType.TOPOLOGY,
                    },
                ]}
                onNavigate={(key) => {
                    navigate(`/clusters/${cluster.getKey()}/nodes/${key}`);
                }}
            />
        </AppPageWrapper>
    );
}
