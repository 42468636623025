import React, { useEffect, useState } from 'react';
import ModalDefault from '../../common/ModalDefault';
import CcCluster from '../../services/models/CcCluster';
import CcPrometheusNode from '../../services/models/CcPrometheusNode';
import CcPrometheusExporter from '../../services/models/CcPrometheusExporter';
import StatusFormat, {
    StatusFormatStatus,
} from '@severalnines/bar-frontend-components/build/lib/Format/StatusFormat';
import SpaceDescriptions from '../../common/Layout/SpaceDescriptions';
import TypographyText from '../../common/TypographyText';
import PrometheusEnableModal from './PrometheusEnableModal';
import ButtonWithForm from '../../common/General/ButtonWithForm';
import { Button } from 'antd';
import SpaceWide from '../../common/SpaceWide';
import PrometheusDisableModal from './PrometheusDisableModal';
import AppTable from '../../common/DataDisplay/AppTable';

export default PrometheusExportersModal;

export type PrometheusExportersModalProps = {
    cluster: CcCluster;
    node: CcPrometheusNode;
    onCancel?: () => void;
};

function PrometheusExportersModal({
    cluster,
    node,
    onCancel,
}: PrometheusExportersModalProps) {
    const [data, setData] = useState<any>();
    useEffect(() => {
        let lastHostname = '';
        setData(
            node?.exporters
                ?.sort((a, b) =>
                    a.getHostname() < b.getHostname()
                        ? -1
                        : a.getHostname() > b.getHostname()
                        ? 1
                        : 0
                )
                .map((exporter) => {
                    const currentHostname = exporter.getHostname();
                    const item = {
                        address: exporter.address,
                        hostname:
                            lastHostname === currentHostname
                                ? ''
                                : currentHostname,
                        status: exporter.up
                            ? StatusFormatStatus.success
                            : StatusFormatStatus.error,
                        type: `${exporter.job}:${exporter.version}`,
                    };
                    lastHostname = currentHostname;
                    return item;
                })
        );
    }, [cluster]);

    const columns = [
        {
            title: 'Host',
            key: 'host',
            render: ({ hostname }: any) => hostname,
        },
        {
            title: 'Status',
            key: 'status',
            render: ({ status }: any) => (
                <StatusFormat
                    status={status}
                    text={
                        status === StatusFormatStatus.success
                            ? 'Enabled'
                            : 'Disabled'
                    }
                />
            ),
        },
        {
            title: 'Type',
            key: 'type',
            render: ({ type }: any) => type,
        },
    ];
    return (
        <ModalDefault
            title="Exporters"
            className="PrometheusExportersModal"
            destroyOnClose={true}
            animateHeight={true}
            visible={true}
            bodyStyle={{ padding: '15px' }}
            onCancel={onCancel}
            width={470}
            footer={
                <SpaceWide
                    direction="vertical"
                    size={15}
                    style={{ padding: '15px 5px' }}
                >
                    <SpaceWide justify="space-between">
                        <span>Re-enable, disabled exporters</span>
                        <ButtonWithForm
                            button={<Button>Re-Enable</Button>}
                            form={<PrometheusEnableModal cluster={cluster} />}
                        />
                    </SpaceWide>
                    <SpaceWide justify="space-between">
                        <span>Disable, enabled exporters</span>
                        <ButtonWithForm
                            button={<Button>Disable</Button>}
                            form={<PrometheusDisableModal cluster={cluster} />}
                        />
                    </SpaceWide>
                </SpaceWide>
            }
        >
            <SpaceWide
                size={30}
                direction="vertical"
                style={{ paddingBottom: 10 }}
            >
                <SpaceDescriptions.Item label="Prometheus version" labelStrong>
                    <TypographyText>{node?.version}</TypographyText>
                </SpaceDescriptions.Item>
                {data ? (
                    <AppTable
                        rowKey={(record: CcPrometheusExporter) =>
                            record.address
                        }
                        dataSource={data || []}
                        columns={columns}
                        size="middle"
                        responsive={false}
                        pagination={{ pageSize: 999, hideOnSinglePage: true }}
                    />
                ) : null}
            </SpaceWide>
        </ModalDefault>
    );
}
