import React, { useEffect, useMemo, useRef, useState } from 'react';
import './JobProgress.less';
import { CmonJobInstanceCommand } from '../../services/cmon/models/CmonJobInstance';
import CcCluster from '../../services/models/CcCluster';
import useJobWatcher from './useJobWatcher';
import { Button, Progress, Space, Typography } from 'antd';
import CcJob, { CcJobStatus } from '../../services/models/CcJob';
import SpaceWide from '../../common/SpaceWide';
import JobDetailsButton from './JobDetailsButton';
import JobRetryButton from './JobRetryButton';
import TypographyText from '../../common/TypographyText';
import { formatNumber } from '@severalnines/bar-frontend-components/build/lib/Format/NumberFormat';
import AppEmpty from '../../common/Feedback/AppEmpty';
import CmonJobsService from '../../services/cmon/CmonJobsService';
import { SizeType } from 'antd/es/config-provider/SizeContext';
import classNames from 'classnames';
import AppTooltip from '../../common/Feedback/AppTooltip';
import useFakeProgress from './useFakeProgress';
import AppSpin from '../../common/General/AppSpin';
import { useDebugContext } from '../../common/Debug';

export default JobProgress;

export type JobProgressProps = {
    title?: React.ReactNode;
    command: CmonJobInstanceCommand | CmonJobInstanceCommand[];
    jobId?: number;
    cluster?: CcCluster;
    extra?: React.ReactNode;
    fallback?: React.ReactNode;
    failedMessage?: React.ReactNode;
    successMessage?: React.ReactNode;
    progressMessage?: React.ReactNode;
    size?: SizeType;
    onFinish?: (job: CcJob) => void;
    showReset?: boolean;
    onReset?: () => void;
    className?: string;
    alignTitle?: 'left' | 'center';
    noMargin?: boolean;
    fakeProgress?: boolean;
    fakePeriod?: number;
};

function JobProgress({
    title,
    command,
    jobId,
    cluster,
    extra,
    fallback,
    failedMessage,
    successMessage,
    progressMessage,
    size,
    onFinish,
    showReset = false,
    onReset,
    className,
    alignTitle = 'center',
    noMargin = false,
    fakeProgress = false,
    fakePeriod,
}: JobProgressProps) {
    const { log } = useDebugContext();
    const lastPercent = useRef<number>(0);
    const { runningJobs } = useJobWatcher({
        command,
        clusterId: cluster?.clusterId,
        onFinish: () => {
            lastPercent.current = 0;
        },
    });
    const [job, setJob] = useState<CcJob | undefined>();
    const {
        percent: fakePercent,
        update: updateFakeProgress,
        clear: clearFakeProgress,
    } = useFakeProgress({ id: job?.jobId, increasePeriod: fakePeriod });

    useEffect(() => {
        if (runningJobs.length) {
            if (jobId) {
                setJob(runningJobs.find((job) => job.jobId === jobId));
            } else {
                setJob(runningJobs[0]);
            }
        } else {
            let currentJobId = jobId;
            if (
                !currentJobId &&
                job &&
                (!cluster || job.clusterId === cluster.clusterId)
            ) {
                currentJobId = job.jobId;
            }

            if (currentJobId) {
                // this means the job was running before and now has changed status
                (async () => {
                    try {
                    const {
                        job: newJob,
                    } = await CmonJobsService.getJobInstance(
                        {
                            job_id: currentJobId,
                        },
                        { requestId: 'job-from-JobProgress' }
                    );
                    setJob(newJob);
                    if (
                        [
                            CcJobStatus.FAILED,
                            CcJobStatus.ABORTED,
                            CcJobStatus.FINISHED,
                        ].includes(newJob?.status)
                    ) {
                        // triggering when job has a termination status
                        onFinish?.(newJob);
                    }
                    } catch (e) {
                        log.error(e);
                        setJob(undefined);
                    }
                })();
            } else {
                setJob(undefined);
            }
        }
    }, [runningJobs, cluster]);

    const handleResetClick = () => {
        setJob(undefined);
        onReset?.();
    };

    useEffect(() => {
        if (fakeProgress) {
            updateFakeProgress();
        }
    }, [job]);

    const percent = useMemo(() => {
        if (!job) {
            return 0;
        }
        // localStorage.getItem('fakeProgress');
        if (job.progressPercent !== undefined) {
            return job.progressPercent || 0;
        }
        if (fakeProgress && !job.newJob) {
            if (job.status === CcJobStatus.FINISHED) {
                lastPercent.current = 0;
                clearFakeProgress();
                return 100;
            }
            return fakePercent;
        }
        return 0;
    }, [job, fakePercent]);

    const progress = job ? (
        <Progress
            percent={formatNumber(
                percent === 100 && job.status !== CcJobStatus.FINISHED
                    ? 99
                    : percent
            )}
            status={
                job.status === CcJobStatus.FINISHED
                    ? 'success'
                    : [CcJobStatus.FAILED, CcJobStatus.ABORTED].includes(
                          job.status
                      )
                    ? 'exception'
                    : 'active'
            }
        />
    ) : null;
    return (
        <div
            className={classNames('JobProgress', className, {
                'JobProgress--no-margin': noMargin,
            })}
        >
            {job ? (
                size === 'small' ? (
                    <AppTooltip
                        title={
                            job.status === CcJobStatus.FINISHED
                                ? successMessage ||
                                  'Job has finished successfully'
                                : [
                                      CcJobStatus.FAILED,
                                      CcJobStatus.ABORTED,
                                  ].includes(job.status)
                                ? failedMessage || 'Job has failed'
                                : progressMessage || 'Job in progress...'
                        }
                        mouseEnterDelay={0.5}
                    >
                        <div
                            className={classNames(
                                'JobProgress_small-body',
                                `JobProgress_small-body--align-${alignTitle}`
                            )}
                        >
                            <div>{title || job.title}</div>
                            {progress}
                        </div>
                    </AppTooltip>
                ) : (
                    <AppEmpty
                        image={null}
                        imageStyle={{ height: 'auto' }}
                        description={
                            <SpaceWide
                                direction="vertical"
                                className="JobProgress_body"
                            >
                                {title || job.title ? (
                                    <Typography.Title level={3}>
                                        {title || job.title}
                                    </Typography.Title>
                                ) : null}
                                {progress}

                                <TypographyText muted>
                                    {job.status === CcJobStatus.FINISHED ? (
                                        successMessage ||
                                        'Job has finished successfully'
                                    ) : [
                                          CcJobStatus.FAILED,
                                          CcJobStatus.ABORTED,
                                      ].includes(job.status) ? (
                                        failedMessage || 'Job has failed'
                                    ) : (
                                        <Space>
                                            <AppSpin
                                                spinning={true}
                                                size="small"
                                            />
                                            <span>
                                                {progressMessage ||
                                                    'Job in progress...'}
                                            </span>
                                        </Space>
                                    )}
                                </TypographyText>
                            </SpaceWide>
                        }
                        extra={
                            <SpaceWide justify="space-between">
                                {extra}
                                <Space>
                                    <JobDetailsButton
                                        job={job}
                                        type="default"
                                        size="middle"
                                    >
                                        Job log
                                    </JobDetailsButton>
                                    {job.status === CcJobStatus.FAILED ? (
                                        <JobRetryButton
                                            type="primary"
                                            size="middle"
                                            job={job}
                                        />
                                    ) : null}
                                    {showReset &&
                                        job.status === CcJobStatus.FAILED && (
                                            <Button
                                                type="ghost"
                                                size="middle"
                                                onClick={handleResetClick}
                                            >
                                                Reset
                                            </Button>
                                        )}
                                </Space>
                            </SpaceWide>
                        }
                    />
                )
            ) : (
                fallback
            )}
        </div>
    );
}
