import React, { useState } from 'react';
import { Button, Space } from 'antd';
import ModalDefault from '../common/ModalDefault';
const DebugModalDefault = () => {
    const [visible, setVisible] = useState(false);
    const [showDetailed, setShowDetailed] = useState(false);
    const handleClick = () => {
        setVisible(true);
    };
    const handleCancel = () => {
        setVisible(false);
    };
    const toggleShowDetailed = () => {
        setShowDetailed(!showDetailed);
    };
    return (
        <div className="DebugModalDefault">
            <h1>ModalDefault</h1>
            <Button type="primary" onClick={handleClick}>
                Open Modal
            </Button>
            <ModalDefault
                title="Modal Title"
                visible={visible}
                onCancel={handleCancel}
                animateHeight={true}
                footer={[
                    <Button
                        key="toggle"
                        type="primary"
                        onClick={toggleShowDetailed}
                    >
                        Toggle Size
                    </Button>,
                ]}
            >
                <Space direction="vertical">
                    <Space>Line 1</Space>
                    {showDetailed ? <Space>Line 2</Space> : null}
                    {showDetailed ? <Space>Line 3</Space> : null}
                    {showDetailed ? <Space>Line 4</Space> : null}
                </Space>
            </ModalDefault>
        </div>
    );
};
export default DebugModalDefault;
