import React from 'react';
import AppPageWrapper from '../common/Layout/AppPageWrapper';
import NodeList from '../components/Nodes/NodeList';

export default DebugNodeList;
export type DebugNodeListProps = {};

function DebugNodeList({ ...rest }: DebugNodeListProps) {
    return (
        <AppPageWrapper>
            <NodeList />
        </AppPageWrapper>
    );
}
