import React, { useState } from 'react';
import { ButtonProps } from 'antd/lib/button';
import CcAlarm from '../../services/models/CcAlarm';
import CmonAlarmService from '../../services/cmon/CmonAlarmService';
import { notifyError } from '../Notifications/uiNotification';
import { useDebugContext } from '../../common/Debug';
import AppConfirmActionButton from '../../common/General/AppConfirmActionButton';

export default AlarmIgnoreButton;

export type AlarmIgnoreButtonProps = ButtonProps & {
    alarm: CcAlarm;
    children?: React.ReactNode;
    onSuccess?: () => void;
};

function AlarmIgnoreButton({
    alarm,
    children,
    onSuccess,
    ...rest
}: AlarmIgnoreButtonProps) {
    const { log } = useDebugContext();
    const [loading, setLoading] = useState(false);
    const handleClick = async () => {
        try {
            setLoading(true);
            await CmonAlarmService.ignoreAlarm(
                {
                    alarm_id: alarm.alarmId,
                    ignore: !alarm.isIgnored(),
                },
                { requestName: '' }
            );
            if (onSuccess) {
                onSuccess();
            }
        } catch (err) {
            log.error(err);
            notifyError({ content: err.message });
        }
        setLoading(false);
    };

    return (
        <AppConfirmActionButton
            confirmTitle={alarm.isIgnored() ? 'Unmute alarm?' : 'Mute alarm?'}
            onConfirm={handleClick}
            loading={loading}
            critical={!alarm.isIgnored()}
            {...rest}
        >
            {children || (alarm.isIgnored() ? 'Unmute' : 'Mute')}
        </AppConfirmActionButton>
    );
}
