import React, { forwardRef, useState } from 'react';
import { AutoComplete, Button, Input, InputProps } from 'antd';
import { ButtonProps } from 'antd/es';
import TypographyText from '../TypographyText';
import classNames from 'classnames';
import { DefaultOptionType } from 'rc-select/lib/Select';

export default forwardRef(HostAddInput);

export type HostAddInputProps = InputProps & {
    onAdd?: (value: string, extraData?: any) => void;
    buttonProps?: ButtonProps;
    enableAutocomplete?: boolean;
    autocompleteOptions?: DefaultOptionType[];
};

function HostAddInput(
    {
        onAdd,
        value,
        onChange,
        buttonProps,
        className,
        enableAutocomplete,
        autocompleteOptions,
        ...rest
    }: HostAddInputProps,
    ref: any
) {
    const [currentValue, setCurrentValue] = useState<string>(value as string);

    const addHostValue = (value: string, extraData?: any) => {
        setCurrentValue('');
        return onAdd?.(value, extraData);
    };

    const handleKeyDown = (event: any) => {
        if (event.key === 'Enter') {
            event.preventDefault();
        }
    };

    const handleKeyUp = async (event: any) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            await handleAdd();
        }
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setCurrentValue(e.target.value);
        onChange?.(e);
    };
    const handleAutocompleteSelect = (
        value: string,
        option: DefaultOptionType
    ) => {
        addHostValue(value, { selectedOption: option });
    };

    const handleAdd = () => {
        return addHostValue(currentValue);
    };

    const input = (
        <Input
            ref={ref}
            onKeyDown={handleKeyDown}
            onKeyUp={handleKeyUp}
            onChange={handleChange}
            value={currentValue}
            addonAfter={
                <Button
                    type="link"
                    size="small"
                    onClick={handleAdd}
                    children={'+'}
                    {...buttonProps}
                    disabled={!currentValue || buttonProps?.disabled}
                />
            }
            {...rest}
        />
    );

    return (
        <div className={classNames('HostAddInput', className)}>
            {enableAutocomplete ? (
                <AutoComplete
                    value={currentValue}
                    options={autocompleteOptions}
                    onSelect={handleAutocompleteSelect}
                >
                    {input}
                </AutoComplete>
            ) : (
                input
            )}

            {currentValue ? (
                <TypographyText muted>Press enter to confirm</TypographyText>
            ) : null}
        </div>
    );
}
