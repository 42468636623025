import { CcJobStatus } from './CcJob';
import CcStatsBase, { CcStatsBaseAttentionLevel } from './CcStatsBase';

export type JobsCountProps = {
    [key in CcJobStatus]?: number;
};

export type CcJobStatsProps = {
    jobCount: JobsCountProps;
};

export default class CcJobStats extends CcStatsBase {
    constructor(props: CcJobStatsProps) {
        super({
            statsCount: props.jobCount,
            attentionErrorStats: [CcJobStatus.FAILED, CcJobStatus.ABORTED],
        });
    }

    public getFirstAttentionStat(
        level?: CcStatsBaseAttentionLevel
    ): [CcJobStatus, number] {
        return super.getFirstAttentionStat(level) as [CcJobStatus, number];
    }

    public getAttentionStatsValues(
        level?: CcStatsBaseAttentionLevel
    ): CcJobStatus[] {
        return super.getAttentionStatsValues(level) as CcJobStatus[];
    }
}
