import CmonKeepalivedHost, {
    CmonKeepalivedHostProps,
} from '../cmon/models/CmonKeepalivedHost';

export interface CcKeepalivedNodeProps extends CmonKeepalivedHostProps {}

export default class CcKeepalivedNode extends CmonKeepalivedHost {
    constructor(props: CcKeepalivedNodeProps) {
        super(props);
    }
}
