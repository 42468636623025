import React from 'react';
import CcCluster from '../../services/models/CcCluster';
import CreateHAProxyWizardForm from './CreateLoadBalancerWizard/CreateHAProxyWizardForm/CreateHAProxyWizardForm';
import CreatePgBouncerWizardForm from './CreateLoadBalancerWizard/CreatePgBouncerWizardForm/CreatePgBouncerWizardForm';
import PgpoolWizardForm from './CreateLoadBalancerWizard/PgpoolWizardForm/PgpoolWizardForm';
import CreateKeepalivedWizardForm from './CreateLoadBalancerWizard/CreateKeepalivedWizardForm/CreateKeepalivedWizardForm';
import CreateProxySQLWizardFrom from './CreateLoadBalancerWizard/CreateProxySQLWizardFrom/CreateProxySQLWizardFrom';
import CreateMaxScaleWizardFrom from './CreateLoadBalancerWizard/CreateMaxScaleWizardFrom/CreateMaxScaleWizardFrom';
import CreateGarbdWizardForm from './CreateLoadBalancerWizard/CreateGarbdWizardForm/CreateGarbdWizardForm';

import { LoadBalancerType } from './LoadBalancerWizard';

export default LoadBalancerFormsCreate;

export type LoadBalancerFormsCreateProps = {
    onCancel?: () => void;
    onSuccess?: () => void;
    onStepErrorInsist?: (err: Error) => void;
    onError?: (err: Error) => void;
    onTouchedChange?: (touched: boolean) => void;
    cluster?: CcCluster;
    loadBalancerTypes: LoadBalancerType;
};

function LoadBalancerFormsCreate({
    onCancel,
    onSuccess,
    onStepErrorInsist,
    onTouchedChange,
    onError,
    cluster,
    loadBalancerTypes,
}: LoadBalancerFormsCreateProps) {
    switch (loadBalancerTypes) {
        case LoadBalancerType.HAPROXY:
            return (
                <CreateHAProxyWizardForm
                    onCancel={onCancel}
                    cluster={cluster}
                    onSuccess={onSuccess}
                    onError={onError}
                    onTouchedChange={onTouchedChange}
                    onStepErrorInsist={onStepErrorInsist}
                />
            );
        case LoadBalancerType.KEEPALIVED:
            return (
                <CreateKeepalivedWizardForm
                    onCancel={onCancel}
                    cluster={cluster}
                    onSuccess={onSuccess}
                    onError={onError}
                    onTouchedChange={onTouchedChange}
                    onStepErrorInsist={onStepErrorInsist}
                />
            );
        case LoadBalancerType.PROXYSQL:
            return (
                <CreateProxySQLWizardFrom
                    onCancel={onCancel}
                    cluster={cluster}
                    onSuccess={onSuccess}
                    onError={onError}
                    onTouchedChange={onTouchedChange}
                    onStepErrorInsist={onStepErrorInsist}
                />
            );
        case LoadBalancerType.MAXSCALE:
            return (
                <CreateMaxScaleWizardFrom
                    onCancel={onCancel}
                    cluster={cluster}
                    onSuccess={onSuccess}
                    onError={onError}
                    onTouchedChange={onTouchedChange}
                    onStepErrorInsist={onStepErrorInsist}
                />
            );
        case LoadBalancerType.GARBD:
            return (
                <CreateGarbdWizardForm
                    onCancel={onCancel}
                    cluster={cluster}
                    onSuccess={onSuccess}
                    onError={onError}
                    onTouchedChange={onTouchedChange}
                    onStepErrorInsist={onStepErrorInsist}
                />
            );
        case LoadBalancerType.PGBOUNCER:
            return (
                <CreatePgBouncerWizardForm
                    onCancel={onCancel}
                    cluster={cluster}
                    onSuccess={onSuccess}
                    onError={onError}
                    onTouchedChange={onTouchedChange}
                    onStepErrorInsist={onStepErrorInsist}
                />
            );
        case LoadBalancerType.PGPOOL_II:
            return <PgpoolWizardForm onCancel={onCancel} cluster={cluster} />;

        default:
            return <div></div>;
    }
}
