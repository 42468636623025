import React, { useContext, useEffect, useState, useRef } from 'react';
import './ProxySQLVariables.less';
import { ResponsiveContext } from '@severalnines/bar-frontend-components/build/lib/Layout/Responsive';
import { Button, InputRef, Input, Space, Typography } from 'antd';

import CcCluster from '../../../../../../services/models/CcCluster';
import CcProxySqlNode from '../../../../../../services/models/CcProxySqlNode';
import CmonProxysqlService from '../../../../../../services/cmon/CmonProxysqlService';
import useListFetch from '../../../../../../common/useListFetch';
import AppEmpty from '../../../../../../common/Feedback/AppEmpty';
import { TablePaginationConfig } from 'antd/es';
import type { FilterConfirmProps } from 'antd/lib/table/interface';
import type { ColumnType } from 'antd/lib/table';
import { SearchOutlined } from '@ant-design/icons';

import { TableData, prepareTable } from './proxySQLVariablesHelper';

import { SearchIcon } from '../../../../../../common/icons/icons';
import ProxySQLVariablesPopover from './ProxySQLVariablesPopover';
import AppTable from '../../../../../../common/DataDisplay/AppTable';

export default ProxySQLVariables;

export type ProxySQLVariablesProps = {
    cluster: CcCluster;
    node: CcProxySqlNode;
    variablesPageSize?: number;
};

function ProxySQLVariables({
    cluster,
    node,
    variablesPageSize = 15,
}: ProxySQLVariablesProps) {
    const { responsive } = useContext(ResponsiveContext);
    const [variablesTableData, setVariablesTableData] = useState<
        undefined | TableData[]
    >();

    const {
        loading,
        list: variablesResponse,
        refresh: refreshVariables,
        page,
        pageSize,
        total,
    } = useListFetch({
        name: 'queryVariables',
        pageSize: variablesPageSize,
        fetchFn: async ({ pageSize, page, ...rest }, opts) => {
            const response = await CmonProxysqlService.queryvariables(
                {
                    cluster_id: cluster.clusterId,
                    hostName: node.hostname,
                    port: node.port,
                    ...rest,
                },
                opts
            );

            return {
                list: response?.queryResults,
                total: response?.queryResultTotalCount,
            };
        },
        cancelFn: async ({ requestId }) => {
            await CmonProxysqlService.cancelRequest(requestId);
        },
    });
    const [totalPage, setTotalPage] = useState<number>();
    useEffect(() => {
        refreshVariables();
    }, []);

    useEffect(() => {
        if (Array.isArray(variablesResponse)) {
            setVariablesTableData(prepareTable(variablesResponse));
            setTotalPage(total);
        }
    }, [variablesResponse]);

    const handleTableChange = async (
        pagination: any,
        filters: any,
        sorter: any,
        extra: { currentDataSource: any[]; action: any }
    ) => {
        await refreshVariables({
            page: pagination.current,
            pageSize: pagination.pageSize,
        });
        setTotalPage(extra.currentDataSource.length);
    };

    const searchInput = useRef<InputRef>(null);

    const handleSearch = (confirm: (param?: FilterConfirmProps) => void) => {
        confirm();
    };

    const count = useRef(0);

    const getColumnSearchProps = (dataIndex: any): ColumnType<any> => ({
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
        }) => (
            <div className="ProxySQLVariables__Search">
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) =>
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                    }
                    onPressEnter={() => handleSearch(confirm)}
                    className="ProxySQLVariables--input"
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => {
                            handleSearch(confirm);
                            count.current = 0;
                        }}
                        icon={<SearchOutlined />}
                        size="small"
                        className="ProxySQLVariables--button"
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => {
                            clearFilters?.();
                            confirm({ closeDropdown: true });
                            count.current = 0;
                        }}
                        size="small"
                        className="ProxySQLVariables--button"
                    >
                        Reset
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: () => <SearchIcon />,
        onFilter: (value, record) => {
            return record[dataIndex]
                .toString()
                .toLowerCase()
                .includes((value as string).toLowerCase());
        },
        onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
    });

    const columns = [
        {
            title: 'Variable name',
            key: 'variableName',
            dataIndex: '',
            ...getColumnSearchProps('variableName'),
            render: (record: TableData) => (
                <>
                    {record?.variableName}
                    {record?.readOnly && (
                        <Typography.Text disabled>
                            ( read only )
                        </Typography.Text>
                    )}
                </>
            ),
        },
        {
            title: 'Value',
            key: 'variableValue',
            dataIndex: 'variableValue',
        },
        {
            title: 'Actions',
            key: '',
            dataIndex: '',
            render: (record: TableData) => (
                <ProxySQLVariablesPopover
                    cluster={cluster}
                    node={node}
                    onSuccess={refreshVariables}
                    variableData={record}
                />
            ),
        },
    ];

    const pagination: TablePaginationConfig = {
        size: 'default',
        pageSize,
        current: page,
        total: totalPage,
        hideOnSinglePage: true,
        showQuickJumper: true,
        showSizeChanger: true,
        position: ['bottomCenter'],
    };
    return (
        <div className="ProxySQLVariables">
            <AppTable
                size="small"
                dataSource={variablesTableData}
                columns={columns}
                responsive={responsive}
                onChange={handleTableChange}
                pagination={pagination}
                loading={loading}
                renderEmpty={
                    <AppEmpty
                        loading={loading}
                        description="There is no variables yet. "
                    />
                }
            />
        </div>
    );
}
