import React, { useMemo } from 'react';
import { useForm } from 'antd/lib/form/Form';
import ModalDefaultForm from '../../common/ModalDefaultForm';
import { Form } from 'antd';
import FormFooter from '../../common/FormFooter';
import CcCluster, {
    CcClusterTechnology,
} from '../../services/models/CcCluster';
import CcDatabaseAccount from '../../services/models/CcDatabaseAccount';
import DatabaseMySQLUserForm from './DatabaseMySQLUserForm';
import DatabasePostgresUserForm from './DatabasePostgresUserForm';
import useDatabaseUserFormHandler, {
    DatabaseUserFormFields,
} from './useDatabaseUserFormHandler';

export default DatabaseUserFormModal;

export type DatabaseUserFormModalProps = {
    cluster: CcCluster;
    onCancel?: () => void;
    onError?: () => void;
    onSuccess?: () => void;
    editAccount?: CcDatabaseAccount;
};

function DatabaseUserFormModal({
    cluster,
    onCancel,
    onError,
    onSuccess,
    editAccount,
    ...rest
}: DatabaseUserFormModalProps) {
    const { initialValues, loading, create, update } =
        useDatabaseUserFormHandler({
            cluster,
            editAccount,
            onSuccess,
            onError,
            onCancel,
        });
    const [form] = useForm<DatabaseUserFormFields>();
    const handleFormFinish = async (fields: DatabaseUserFormFields) => {
        if (editAccount) {
            await update(fields);
        } else {
            await create(fields);
        }
    };

    const formRender = useMemo(() => {
        switch (cluster.getTechnology()) {
            case CcClusterTechnology.TECHNOLOGY_MYSQL:
                return (
                    <DatabaseMySQLUserForm
                        cluster={cluster}
                        form={form}
                        edit={!!editAccount}
                    />
                );
            case CcClusterTechnology.TECHNOLOGY_POSTGRESQL:
                return (
                    <DatabasePostgresUserForm
                        cluster={cluster}
                        form={form}
                        edit={!!editAccount}
                    />
                );
        }
    }, [cluster, form, !!editAccount]);

    return (
        <ModalDefaultForm
            title={editAccount ? 'Edit database user' : 'Create database user'}
            form={form}
            footer={[]}
            width={750}
            loading={loading}
            defaultVisible={true}
            onCancel={onCancel}
        >
            <Form
                form={form}
                onFinish={handleFormFinish}
                layout="vertical"
                initialValues={initialValues}
            >
                {formRender}
                <FormFooter
                    loading={loading}
                    submitButtonText={editAccount ? 'Save' : 'Create'}
                    showSubmitButton={true}
                />
            </Form>
        </ModalDefaultForm>
    );
}
