import CcUser from '../../services/models/CcUser';

export const defaultSettings = {
    Backup: {
        CRITICAL: 'Deliver',
        INFO: 'Ignore',
        WARNING: 'Ignore',
    },
    Cluster: {
        CRITICAL: 'Deliver',
        INFO: 'Ignore',
        WARNING: 'Ignore',
    },
    ClusterConfiguration: {
        CRITICAL: 'Deliver',
        INFO: 'Ignore',
        WARNING: 'Ignore',
    },
    ClusterRecovery: {
        CRITICAL: 'Deliver',
        INFO: 'Ignore',
        WARNING: 'Ignore',
    },
    CmonDatabase: {
        CRITICAL: 'Deliver',
        INFO: 'Ignore',
        WARNING: 'Ignore',
    },
    DbHealth: {
        CRITICAL: 'Deliver',
        INFO: 'Ignore',
        WARNING: 'Ignore',
    },
    DbPerformance: {
        CRITICAL: 'Deliver',
        INFO: 'Ignore',
        WARNING: 'Ignore',
    },
    Host: {
        CRITICAL: 'Deliver',
        INFO: 'Ignore',
        WARNING: 'Ignore',
    },
    Mail: {
        CRITICAL: 'Deliver',
        INFO: 'Ignore',
        WARNING: 'Ignore',
    },
    Network: {
        CRITICAL: 'Deliver',
        INFO: 'Ignore',
        WARNING: 'Ignore',
    },
    Node: {
        CRITICAL: 'Deliver',
        INFO: 'Ignore',
        WARNING: 'Ignore',
    },
    SoftwareInstallation: {
        CRITICAL: 'Deliver',
        INFO: 'Ignore',
        WARNING: 'Ignore',
    },
    Unknown: {
        CRITICAL: 'Deliver',
        INFO: 'Ignore',
        WARNING: 'Ignore',
    },
};

export const EMAIL_LIMIT_OPTIONS = [
    { label: 'Unlimited', value: -1 },
    { label: 'Disabled', value: 0 },
    { label: '1', value: 1 },
    { label: '2', value: 2 },
    { label: '3', value: 3 },
    { label: '4', value: 4 },
    { label: '5', value: 5 },
    { label: '10', value: 10 },
    { label: '20', value: 20 },
    { label: '30', value: 30 },
    { label: '40', value: 40 },
    { label: '50', value: 50 },
    { label: '100', value: 100 },
];

export const deliveryTimeOptions = Array.from({ length: 24 }, (_, index) => {
    // Iterate from 0 to 23 (24 elements)
    // The "_" parameter represents the current element, but we don't need it in this case
    // The "index" parameter represents the current index of the element in the array

    const hour = index + 1; // Get the hour value by adding 1 to the index
    const label = `${hour.toString().padStart(2, '0')}:00`; // Format the hour value as a two-digit string with leading zeros and append ":00"
    const value = hour; // The value is the same as the hour

    return { label, value }; // Create and return an object with "label" and "value" properties for each element
});

export const tableOptions = [
    {
        value: 'Deliver',
        label: 'Deliver',
    },
    {
        value: 'Ignore',
        label: 'Ignore',
    },
    {
        value: 'Digest',
        label: 'Digest',
    },
];

export interface Group {
    group_id: number;
    group_name: string;
}

export interface ResultGroup {
    group_id: number | string;
    group_name: string;
    users: UserEmail[];
}

export interface UserEmail {
    emailAddress: string;
    id: number | string;
    active: boolean;
    groupName: string;
}

export function formatUserData(userList: CcUser[], recipients: string[]) {
    const result: ResultGroup[] = [];
    const groupMap: Record<number | string, ResultGroup> = {};

    // Iterate over userList
    userList.forEach((user: CcUser) => {
        if (user.emailAddress && Array.isArray(user.groups))
            user.groups.forEach((group: Group) => {
                const { group_id, group_name } = group;

                // Check if the group_id exists in groupMap
                if (!groupMap[group_id]) {
                    // If it doesn't exist, create a new group entry
                    groupMap[group_id] = {
                        group_id,
                        group_name,
                        users: [],
                    };
                }

                // Check if the user's emailAddress exists in recipients
                const isActive =
                    (user.emailAddress &&
                        recipients.includes(user.emailAddress)) ||
                    false;
                // Add the user's emailAddress to the group's users array with the active property
                groupMap[group_id].users.push({
                    emailAddress: user.emailAddress || '',
                    id: user.userId || '',
                    active: isActive,
                    groupName: group_name,
                });
            });
    });

    // Add any email in recipients that is not in userList to the "external" group
    const externalUsers: UserEmail[] = [];
    recipients.forEach((email: string, index: number) => {
        const isUserExists = userList.some(
            (user) => user.emailAddress === email
        );
        if (!isUserExists) {
            externalUsers.push({
                emailAddress: email,
                id: `external ${index}`,
                active: true,
                groupName: 'external',
            });
        }
    });

    if (externalUsers.length > 0) {
        groupMap.external = {
            group_id: 'external',
            group_name: 'external',
            users: externalUsers,
        };
    }

    // Convert groupMap object to an array
    for (const groupId in groupMap) {
        result.push(groupMap[groupId]);
    }

    return result;
}
