const result = {
    jobs: [
        {
            class_name: 'CmonJobInstance',
            can_be_aborted: false,
            can_be_deleted: true,
            cluster_id: 0,
            created: '2022-07-08T02:59:36.000Z',
            ended: '2022-07-08T03:04:06.000Z',
            exit_code: 0,
            group_id: 1,
            group_name: 'admins',
            has_progress: true,
            ip_address: '184.22.78.221',
            job_id: 161,
            job_spec:
                '{"job_data":{"cluster_name":"Vagrant Elastic Single Node Cluster 2","ssh_user":"root","ssh_keyfile":"/root/.ssh/id_rsa","ssh_port":22,"sudo_password":"","type":"elasticsearch","vendor":"elasticsearch","version":"8.1.3","generate_token":true,"disable_firewall":true,"disable_selinux":true,"deploy_agents":true,"install_software":true,"cluster_type":"elastic","db_user":"admin","db_password":"123456","snapshot_location":"/mnt/data/backups/es-snapshot-repositories","snapshot_repository":"second-repo-name","storage_host":"10.0.0.11","nodes":[{"class_name":"CmonElasticHost","hostname":"10.0.0.11","hostname_data":"10.0.0.11","protocol":"elastic","roles":"master-data"}],"snapshot_repository_type":"fs-nfs"},"command":"CREATE_CLUSTER"}',
            progress_percent: 70,
            rpc_version: '2.0',
            started: '2022-07-08T02:59:36.000Z',
            status: 'RUNNING',
            status_text: 'Job finished.',
            title: 'Deploy Elasticsearch 8.1.3 Cluster',
            user_id: 5,
            user_name: 'alex',
        },
        {
            class_name: 'CmonJobInstance',
            can_be_aborted: false,
            can_be_deleted: true,
            cluster_id: 0,
            created: '2022-08-31T04:03:41.000Z',
            ended: '2022-08-31T04:03:41.000Z',
            exit_code: 1,
            group_id: 1,
            group_name: 'admins',
            ip_address: '58.11.97.165',
            job_id: 27069,
            rpc_version: '2.0',
            started: '2022-08-31T04:03:41.000Z',
            status: 'RUNNING',
            progress_percent: 50,
            status_text: 'Job failed.',
            title: 'Adding a Replication Slave 10.0.8.11',
            user_id: 5,
            user_name: 'alex',
            job_spec: {
                command: 'add_replication_slave',
                job_data: {
                    disable_firewall: true,
                    disable_selinux: true,
                    install_software: true,
                    master_address: '10.0.8.11:5432',
                    update_lb: false,
                    usePackageForDataDir: true,
                    node: {
                        hostname: '10.0.8.11',
                        port: 5432,
                        synchronous: false,
                    },
                },
            },
        },
        {
            class_name: 'CmonJobInstance',
            can_be_aborted: false,
            can_be_deleted: true,
            cluster_id: 0,
            created: '2022-08-31T04:03:51.000Z',
            ended: '2022-08-31T04:03:52.000Z',
            exit_code: 1,
            group_id: 1,
            group_name: 'admins',
            ip_address: '58.11.97.165',
            job_id: 27070,
            rpc_version: '2.0',
            started: '2022-08-31T04:03:51.000Z',
            status: 'RUNNING',
            progress_percent: 85,
            status_text: 'Job failed.',
            title: 'Add New Node to Cluster 10.0.8.12',
            user_id: 5,
            user_name: 'alex',
            job_spec: {
                command: 'addnode',
                job_data: {
                    disable_firewall: true,
                    disable_selinux: true,
                    install_software: true,
                    node_type: 0,
                    replicaset: 'my_mongodb_0',
                    node: {
                        hostname: '10.0.8.12',
                        port: 27017,
                    },
                },
            },
        },
        {
            class_name: 'CmonJobInstance',
            can_be_aborted: false,
            can_be_deleted: false,
            cluster_id: 1,
            created: '2022-09-27T08:49:13.000Z',
            exit_code: 0,
            group_id: 1,
            group_name: 'admins',
            ip_address: '184.22.157.147',
            job_id: 28756,
            job_spec: {
                command: 'ERROR_REPORT',
                job_data: {
                    clusterId: 1,
                    mask_passwords: true,
                },
            },
            rpc_version: '2.0',
            started: '2022-09-27T08:49:13.000Z',
            status: 'RUNNING',
            status_text: 'Generating error-report',
            title: 'Generate error report',
            user_id: 5,
            user_name: 'alex',
        },
    ],
    total: 123,
};

export default result;
