import React from 'react';
import './FormFooter.less';
import SpaceWide from './SpaceWide';
import classNames from 'classnames';
import { Button, Divider } from 'antd';
import { ButtonProps } from 'antd/es';

export default FormFooter;

export type FormFooterProps = {
    children?: React.ReactNode;
    right?: boolean;
    left?: boolean;
    center?: boolean;
    onCancel?: () => void;
    showCancelButton?: boolean;
    cancelButtonText?: string;
    cancelButtonProps?: ButtonProps;
    showSubmitButton?: boolean;
    submitButtonText?: string;
    submitButtonProps?: ButtonProps;
    loading?: boolean;
    noDivider?: boolean;
    extraLeft?: boolean;
};

function FormFooter({
    children,
    right = true,
    left,
    center,
    onCancel,
    showCancelButton,
    cancelButtonText,
    cancelButtonProps,
    showSubmitButton,
    submitButtonText,
    submitButtonProps,
    loading,
    noDivider,
    extraLeft,
    ...rest
}: FormFooterProps) {
    return (
        <>
            {noDivider ? null : <Divider />}
            <SpaceWide
                className={classNames('FormFooter', {
                    'FormFooter--align-right': right,
                    'FormFooter--align-left': left,
                    'FormFooter--align-center': center,
                    'FormFooter--noDivider': noDivider,
                })}
                align={'end'}
                {...rest}
            >
                {extraLeft && children}
                {showCancelButton ? (
                    <Button
                        key="cancel"
                        onClick={onCancel}
                        {...cancelButtonProps}
                    >
                        {cancelButtonText || 'Cancel'}
                    </Button>
                ) : null}
                {showSubmitButton ? (
                    <Button
                        key="done"
                        type="primary"
                        htmlType="submit"
                        loading={loading}
                        {...submitButtonProps}
                    >
                        {submitButtonText || 'Submit'}
                    </Button>
                ) : null}
                {!extraLeft && children}
            </SpaceWide>
        </>
    );
}
