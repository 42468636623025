import React, { useRef } from 'react';
import { Space, Tabs } from 'antd';
import { BackupsPageListType } from '../../Backups/BackupsPage';
import BackupsList, { BackupListApi } from '../../Backups/BackupsList';
import BackupSchedulesList, {
    BackupSchedulesListApi,
} from '../../Backups/BackupSchedulesList';
import CcCluster from '../../../services/models/CcCluster';
import { useNavigate, useParams } from 'react-router-dom';
import AppPageWrapper from '../../../common/Layout/AppPageWrapper';
import UserAclManageCluster from '../../User/UserAclManageCluster';
import BackupCreateWizardButton from '../../Backups/BackupCreateWizardButton';
import ClusterBackupsMenu from './ClusterBackupsMenu';

export default ClusterBackups;

export type ClusterBackupsProps = { cluster: CcCluster; baseUrl: string };

function ClusterBackups({ cluster, baseUrl }: ClusterBackupsProps) {
    const navigate = useNavigate();
    const schedulesRef = useRef<BackupSchedulesListApi>();
    const backupsRef = useRef<BackupListApi>();
    const { listType = BackupsPageListType.BACKUP } = useParams<{
        listType: BackupsPageListType;
    }>();
    const handleWizardSuccess = () => {
        switch (listType) {
            case BackupsPageListType.BACKUP:
                backupsRef.current?.refresh();
                break;
            case BackupsPageListType.SCHEDULES:
                schedulesRef.current?.refresh();
                break;
        }
    };
    return (
        <AppPageWrapper
            className="ClusterBackups"
            transparentBackground={true}
            headerMenu={
                <Space size={'large'}>
                    <UserAclManageCluster cluster={cluster}>
                        <BackupCreateWizardButton
                            cluster={cluster}
                            onSuccess={handleWizardSuccess}
                        />
                    </UserAclManageCluster>
                    <UserAclManageCluster cluster={cluster}>
                        <ClusterBackupsMenu cluster={cluster} />
                    </UserAclManageCluster>
                </Space>
            }
            noPadding={true}
        >
            <Tabs
                onTabClick={(key) => {
                    navigate(getUrl(key));
                }}
                activeKey={listType}
            >
                <Tabs.TabPane
                    tab="All Backups"
                    key={BackupsPageListType.BACKUP}
                >
                    <BackupsList
                        ref={backupsRef}
                        clusterId={cluster.clusterId}
                        excludeColumns={['cluster']}
                    />
                </Tabs.TabPane>
                <Tabs.TabPane
                    tab="Backup Schedules"
                    key={BackupsPageListType.SCHEDULES}
                >
                    <BackupSchedulesList
                        ref={schedulesRef}
                        clusterId={cluster.clusterId}
                        excludeColumns={['cluster']}
                    />
                </Tabs.TabPane>
            </Tabs>
        </AppPageWrapper>
    );

    function getUrl(key: string) {
        if (key === BackupsPageListType.BACKUP) {
            return baseUrl;
        }
        return `${baseUrl}/${key}`;
    }
}
