import CmonGroup, { CmonGroupProps } from '../cmon/models/CmonGroup';
import { SYSTEM_USER_NAME } from './CcUser';

export const CMON_ADMINS_GROUP_ID = 1;
export const CMON_ADMINS_GROUP_NAME = 'admins';

export type CcGroupProps = Omit<CmonGroupProps, 'group_name'> & {
    group_name?: string; //@fixme: generator metada
};

export default class CcGroup extends CmonGroup {
    public groupName: string;

    constructor(props: CcGroupProps) {
        super(props);
        this.groupName = props.group_name as string;
    }

    getKey(): string {
        return `${this.groupId}`;
    }

    isSystemUserOwnedGroup(): boolean {
        return this.ownerUserName === SYSTEM_USER_NAME;
    }

    isAdminsGroup(): boolean {
        return this.groupId === CMON_ADMINS_GROUP_ID;
    }
}
