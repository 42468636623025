import React from 'react';
import PromoCard from '../common/Layout/PromoCard';
import { Button, Space } from 'antd';
import SpaceWide from '../common/SpaceWide';

export default DebugPromoCard;

export type DebugPromoCardProps = {};

function DebugPromoCard({}: DebugPromoCardProps) {
    return (
        <div className="DebugPromoCard" style={{ width: 500 }}>
            <SpaceWide direction="vertical">
                <PromoCard
                    title={'Cart with circles background'}
                    extra={
                        <Space>
                            <Button type="primary">button</Button>
                        </Space>
                    }
                    iconType="install"
                >
                    content here
                </PromoCard>
                <PromoCard
                    title={'Cart with circles bottom right background'}
                    bg="circles-bottom-right"
                    extra={
                        <Space>
                            <Button type="primary">button</Button>
                        </Space>
                    }
                    iconType="install"
                >
                    content here
                </PromoCard>
            </SpaceWide>
        </div>
    );
}
