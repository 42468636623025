import React from 'react';
import { Form, Input } from 'antd';
import { FormItemProps } from 'antd/lib/form';

export default DataDirectoryField;

export type DataDirectoryFieldProps = FormItemProps & { required?: boolean };

function DataDirectoryField({
    required = true,
    ...rest
}: DataDirectoryFieldProps) {
    return (
        <div className="DataDirectoryField">
            <Form.Item
                name="dataDir"
                label="Data directory"
                rules={[
                    {
                        required,
                        message: 'Please enter data directory path',
                    },
                ]}
                {...rest}
            >
                <Input placeholder="Enter data directory path" />
            </Form.Item>
        </div>
    );
}
