import React, { useEffect } from 'react';
import { Select, SelectProps } from 'antd';
import useUsersList from './useUsersList';
import CcUser from '../../services/models/CcUser';

export default UserSelect;

export type UserSelectProps = SelectProps & {
    value?: string;
    onChange?: (value: CcUser | CcUser[] | undefined) => void;
};

function UserSelect({
    value,
    onChange,
    loading,
    disabled,
    ...rest
}: UserSelectProps) {
    const { list: users, loading: loadingUsers, refresh } = useUsersList({
        pageSize: 0,
    });
    useEffect(() => {
        (async () => {
            await refresh();
        })();
    }, []);

    const handleOnChange = (value: string | string[]) => {
        onChange?.(
            Array.isArray(value)
                ? value.map(
                      (v) => users?.find((g) => g.getKey() === v) as CcUser
                  )
                : users?.find((g) => g.getKey() === value)
        );
    };

    return (
        <Select
            className="UserSelect"
            style={{ width: '100%' }}
            onChange={handleOnChange}
            value={
                Array.isArray(value)
                    ? value.map((v) => v.getKey())
                    : value?.getKey()
            }
            loading={loadingUsers || loading}
            disabled={loadingUsers || disabled}
            options={(users || []).map((user) => ({
                value: user.getKey(),
                label: user.userName,
            }))}
            optionFilterProp="label"
            {...rest}
        />
    );
}
