import React, { Fragment } from 'react';
import { Button, Col, Input, Row, Space } from 'antd';
import InfoIcon from '@severalnines/bar-frontend-components/build/lib/General/InfoIcon';
import FormItem from '../../../common/DataEntry/FormItem';
import { LdapConfig } from './LdapTypes';
import InputNumberWide from '../../../common/DataEntry/InputNumberWide';
import { FormInstance } from 'antd/es';

export default LdapSettingsAdvancedForm;

export type LdapSettingsAdvancedFormProps = {
    defaultSettings?: LdapConfig;
    form: FormInstance;
};

function LdapSettingsAdvancedForm({
    defaultSettings,
    form,
}: LdapSettingsAdvancedFormProps) {
    const handlePrefillActiveDirectoryClick = () => {
        form.setFieldsValue({
            ldapSettings: {
                ldapUsernameAttributes: 'sAMAccountName',
                ldapRealnameAttributes: 'displayName',
                ldapEmailAttributes: 'userPrincipalName',
                ldapMemberAttributes: 'member',
                ldapGroupIdAttributes: 'dn',
                ldapGroupNameAttribute: 'cn',
            },
        });
    };
    const handlePrefillOpenLdapClick = () => {
        form.setFieldsValue({
            ldapSettings: {
                ldapUsernameAttributes: 'uid',
                ldapRealnameAttributes: 'cn',
                ldapEmailAttributes: 'email',
                ldapMemberAttributes: 'member',
                ldapGroupIdAttributes: 'uid',
                ldapGroupNameAttribute: 'cn',
            },
        });
    };
    const handleResetPrefill = () => {
        form.resetFields([
            ['ldapSettings', 'ldapUsernameAttributes'],
            ['ldapSettings', 'ldapRealnameAttributes'],
            ['ldapSettings', 'ldapEmailAttributes'],
            ['ldapSettings', 'ldapMemberAttributes'],
            ['ldapSettings', 'ldapGroupIdAttributes'],
            ['ldapSettings', 'ldapGroupNameAttribute'],
        ]);
    };
    return (
        <Fragment>
            <Row gutter={[24, 0]}>
                <Col span={24}>
                    <h3>Advanced settings</h3>
                </Col>
            </Row>
            <Row gutter={[24, 0]}>
                <Col span={24}>
                    <FormItem label="Pre-fill for">
                        <Space>
                            <Button onClick={handlePrefillActiveDirectoryClick}>
                                Active Directory
                            </Button>
                            <Button onClick={handlePrefillOpenLdapClick}>
                                OpenLDAP
                            </Button>
                            <Button onClick={handleResetPrefill}>Reset</Button>
                        </Space>
                    </FormItem>
                </Col>
            </Row>
            <Row gutter={[24, 0]}>
                <Col xs={24} sm={24} md={12}>
                    <FormItem
                        name={['ldapSettings', 'ldapUserClassName']}
                        label={
                            <Space>
                                <span>User base filter</span>
                                <InfoIcon info="This is used to filter the object class of the LDAP users. If empty, all object classes will be returned." />
                            </Space>
                        }
                    >
                        <Input
                            placeholder={
                                defaultSettings?.ldapSettings?.ldapUserClassName
                            }
                        />
                    </FormItem>
                </Col>
                <Col xs={24} sm={24} md={12}>
                    <FormItem
                        name={['ldapSettings', 'ldapUsernameAttributes']}
                        label={
                            <Space>
                                <span>Username attributes</span>
                                <InfoIcon info="The LDAP attribute which should contain the username, separated by a comma (whitespace value is not allowed). For Active Directory, this is commonly “sAMAccountName” and “uid” for OpenLDAP. If more than one attribute is specified ClusterControl will attempt to look up all of them with the first non-empty reply (in the particular order) used for the login username." />
                            </Space>
                        }
                    >
                        <Input
                            placeholder={
                                defaultSettings?.ldapSettings
                                    ?.ldapUsernameAttributes
                            }
                        />
                    </FormItem>
                </Col>
                <Col xs={24} sm={24} md={12}>
                    <FormItem
                        name={['ldapSettings', 'ldapRealnameAttributes']}
                        label={
                            <Space>
                                <span>Real name attributes</span>
                                <InfoIcon info="The LDAP attribute which should contain the full name of the user, separated by a comma. For Active Directory, this is commonly “displayName” and “cn” for OpenLDAP. If more than one attribute is specified ClusterControl will attempt to look up all of them with the first non-empty reply (in the particular order) used for the user’s full name." />
                            </Space>
                        }
                    >
                        <Input
                            placeholder={
                                defaultSettings?.ldapSettings
                                    ?.ldapRealnameAttributes
                            }
                        />
                    </FormItem>
                </Col>
                <Col xs={24} sm={24} md={12}>
                    <FormItem
                        name={['ldapSettings', 'ldapEmailAttributes']}
                        label={
                            <Space>
                                <span>Email attributes</span>
                                <InfoIcon info="The LDAP attribute which should contain the email address of the user, separated by a comma. For Active Directory, this is commonly “userPrincipalName” and “mail” for OpenLDAP. If more than one attribute is specified, ClusterControl will attempt to look up all of them with the first non-empty reply (in the particular order) used for the user’s email address." />
                            </Space>
                        }
                    >
                        <Input
                            placeholder={
                                defaultSettings?.ldapSettings
                                    ?.ldapEmailAttributes
                            }
                        />
                    </FormItem>
                </Col>
                <Col xs={24} sm={24} md={12}>
                    <FormItem
                        name={['ldapSettings', 'ldapGroupClassName']}
                        label={
                            <Space>
                                <span>Group base filter</span>
                                <InfoIcon info="Filter the object class for the LDAP groups. If empty, all object classes will be returned." />
                            </Space>
                        }
                    >
                        <Input
                            placeholder={
                                defaultSettings?.ldapSettings
                                    ?.ldapGroupClassName
                            }
                        />
                    </FormItem>
                </Col>

                <Col xs={24} sm={24} md={12}>
                    <FormItem
                        name={['ldapSettings', 'ldapMemberAttributes']}
                        label={
                            <Space>
                                <span>Static member attribute</span>
                                <InfoIcon info="The LDAP attribute which represents the group’s members, separated by a comma. For Active Directory, this is commonly “member” and “memberUid” for OpenLDAP. These attributes will be used to create combinations with the value of Group mapping attributes when querying the LDAP server to retrieve the group’s information." />
                            </Space>
                        }
                    >
                        <Input
                            placeholder={
                                defaultSettings?.ldapSettings
                                    ?.ldapMemberAttributes
                            }
                        />
                    </FormItem>
                </Col>
                <Col xs={24} sm={24} md={12}>
                    <FormItem
                        name={['ldapSettings', 'ldapGroupIdAttributes']}
                        label={
                            <Space>
                                <span>Group mapping attributes</span>
                                <InfoIcon info="The LDAP attribute of which name holds the group membership, separated by a comma. For Active Directory, this is commonly “dn” and “uid” for OpenLDAP. The value of these attributes will be used to create combinations with Static member attributes to query the LDAP server to retrieve the group." />
                            </Space>
                        }
                    >
                        <Input
                            placeholder={
                                defaultSettings?.ldapSettings
                                    ?.ldapGroupIdAttributes
                            }
                        />
                    </FormItem>
                </Col>
                <Col xs={24} sm={24} md={12}>
                    <FormItem
                        name={['ldapSettings', 'ldapGroupNameAttribute']}
                        label={
                            <Space>
                                <span>Group name attribute</span>
                                <InfoIcon info="The LDAP attribute where the name represents the group’s name. This is commonly “cn”. The value of this attribute will be used in the group mapping with “ClusterControl’s Team” for authorization purposes." />
                            </Space>
                        }
                    >
                        <Input
                            placeholder={
                                defaultSettings?.ldapSettings
                                    ?.ldapGroupNameAttribute
                            }
                        />
                    </FormItem>
                </Col>
                <Col xs={24} sm={24} md={8}>
                    <FormItem
                        name={['ldapSettings', 'ldapNetworkTimeout']}
                        label={
                            <Space>
                                <span>Network timeout</span>
                                <InfoIcon info="This variable specifies the connection timeout in seconds if the LDAP server is unreachable or takes too long to respond." />
                            </Space>
                        }
                    >
                        <InputNumberWide />
                    </FormItem>
                </Col>
                <Col xs={24} sm={24} md={8}>
                    <FormItem
                        name={['ldapSettings', 'ldapProtocolVersion']}
                        label={
                            <Space>
                                <span>Protocol version</span>
                                <InfoIcon info="This is the current LDAP protocol version being used." />
                            </Space>
                        }
                    >
                        <InputNumberWide />
                    </FormItem>
                </Col>
                <Col xs={24} sm={24} md={8}>
                    <FormItem
                        name={['ldapSettings', 'ldapQueryTimeLimit']}
                        label={
                            <Space>
                                <span>Time limit</span>
                                <InfoIcon info="This variable specifies the limit in seconds for an LDAP query to finish, any query which takes longer will be aborted." />
                            </Space>
                        }
                    >
                        <InputNumberWide />
                    </FormItem>
                </Col>
            </Row>
        </Fragment>
    );
}

export function getLdapSettingsAdvancedFormValidate() {
    return [];
}
