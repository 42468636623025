import { Space } from 'antd';
import { NodesIcon } from '../../../common/icons/icons';
import SpaceWide from '../../../common/SpaceWide';
import SpaceDescriptions from '../../../common/Layout/SpaceDescriptions';
import AppDateFormat from '../../../common/AppDateFormat';
import WrapFormat, { WrapFormatProps } from '../../../common/Format/WrapFormat';
import React from 'react';
import CcAdvisorSchedule from '../../../services/models/CcAdvisorSchedule';
import AdvisorStatusFormat, {
    getAdvisorStatusFormatStatus,
} from './AdvisorStatusFormat';
import CronFormat from '@severalnines/bar-frontend-components/build/lib/Format/CronFormat';
import { StatusFormatType } from '@severalnines/bar-frontend-components/build/lib/Format/StatusFormat';
import { Link } from 'react-router-dom';

export default AdvisorFormat;
type AdvisorFormatProps = WrapFormatProps & {
    link?: string;
    advisorSchedule: CcAdvisorSchedule;
};

function AdvisorFormat({
    link,
    advisorSchedule,
    children,
    popoverProps,
    ...rest
}: AdvisorFormatProps) {
    const title =
        advisorSchedule.getCurrentStatus()?.title || advisorSchedule.filename;
    const content = children || (
        <AdvisorStatusFormat
            status={advisorSchedule.statusTitle}
            text={title}
            colorText={false}
            type={StatusFormatType.dot}
        />
    );
    return (
        <WrapFormat
            popoverStatus={getAdvisorStatusFormatStatus(
                advisorSchedule.statusTitle
            )}
            contentProps={{ style: { width: '100%' } }}
            popoverProps={{
                title: (
                    <Space>
                        <NodesIcon /> Advisor information
                    </Space>
                ),
                destroyTooltipOnHide: true,
                ...popoverProps,
            }}
            popoverContent={
                <SpaceWide direction="vertical" size={20}>
                    <SpaceDescriptions direction="horizontal" size={20}>
                        <SpaceDescriptions.Item
                            label="Status"
                            direction="vertical"
                            colon={false}
                        >
                            <AdvisorStatusFormat
                                status={advisorSchedule.statusTitle}
                                nowrap={true}
                            />
                        </SpaceDescriptions.Item>

                        <SpaceDescriptions.Item
                            label="Schedule"
                            direction="vertical"
                            colon={false}
                        >
                            <CronFormat>
                                {advisorSchedule.file?.schedule}
                            </CronFormat>
                        </SpaceDescriptions.Item>
                        <SpaceDescriptions.Item
                            label="Last execution"
                            direction="vertical"
                            colon={false}
                        >
                            <AppDateFormat fromNow>
                                {advisorSchedule.timestamp
                                    ? new Date(advisorSchedule.timestamp * 1000)
                                    : undefined}
                            </AppDateFormat>
                        </SpaceDescriptions.Item>
                    </SpaceDescriptions>
                    <SpaceDescriptions direction="horizontal" size={20}>
                        <SpaceDescriptions.Item
                            label="File name"
                            direction="vertical"
                            colon={false}
                        >
                            {advisorSchedule.filename}
                        </SpaceDescriptions.Item>
                    </SpaceDescriptions>
                </SpaceWide>
            }
            {...rest}
        >
            {link ? <Link to={link}>{content}</Link> : content}
        </WrapFormat>
    );
}
