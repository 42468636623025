const mock = {
    class_name: 'CmonClusterInfo',
    cdt_path: '/',
    acl: 'user::rwx,group::rwx,other::---',
    tags: ['mysql', 'aws'],
    cluster_auto_recovery: false,
    cluster_id: 2,
    cluster_name: 'MySQL Replication KK',
    cluster_type: 'REPLICATION',
    configuration_file: '/etc/cmon.d/cmon_2.cnf',
    effective_privileges: 'rwx',
    log_file: '/var/log/cmon_2.log',
    maintenance_mode_active: false,
    managed: true,
    node_auto_recovery: false,
    rpc_version: '2.0',
    state: 'STARTED',
    status_text: 'All nodes are operational.',
    vendor: 'percona',
    version: '8.0',
    alarm_statistics: {
        class_name: 'CmonAlarmStatistics',
        cluster_id: 2,
        created_after: '1970-01-01T00:00:00.000Z',
        critical: 0,
        reported_after: '1970-01-01T00:00:00.000Z',
        warning: 0,
    },
    group_owner: {
        class_name: 'CmonGroup',
        cdt_path: '/groups',
        owner_user_id: 1,
        owner_user_name: 'system',
        owner_group_id: 1,
        owner_group_name: 'admins',
        acl: 'user::rwx,group::rwx,other::---',
        created: '2021-04-28T22:57:55.328Z',
        group_id: 1,
        group_name: 'admins',
    },
    hosts: [
        {
            cdt_path: '/',
            class_name: 'CmonMySqlHost',
            clusterid: 2,
            configfile: ['/etc/mysql/my.cnf'],
            connected: true,
            container: false,
            datadir: '/var/lib/mysql/',
            distribution: {
                codename: 'trusty',
                name: 'ubuntu',
                release: '14.04',
                type: 'debian',
            },
            errorcode: 0,
            errormsg: 'Up and running.',
            hostId: 5,
            hostname: '10.0.0.101',
            hostname_data: '10.0.0.101',
            hoststatus: 'CmonHostOnline',
            ip: '10.0.0.101',
            isgalera: false,
            lastseen: 1508937076,
            log_bin: 'ON',
            logfile: '/var/log/mysql/mysqld.log',
            maintenance_mode_active: false,
            message: 'Up and running',
            mysqlstatus: 0,
            nodeid: 5,
            nodetype: 'mysql',
            performance_schema: false,
            pid: 15264,
            pidfile: '/var/lib/mysql/mysql.pid',
            pingstatus: 1,
            pingtime: 4519,
            port: '3306',
            readonly: false,
            replication_master: {
                binlog_do_db: '',
                binlog_ignore_db: '',
                exec_gtid: '63d86139-b97c-11e7-bbad-080027b2ac87:1-118591',
                file: 'binlog.000005',
                position: '19245215',
                semisync_status: 'ON',
            },
            replication_slave: {
                exec_master_log_pos: '670767471',
                executed_gtid_set:
                    '63d86139-b97c-11e7-bbad-080027b2ac87:1-118589',
                last_io_errno: '0',
                last_io_error: '',
                last_io_error_timestamp: '',
                last_sql_errno: '0',
                last_sql_error: '',
                last_sql_error_timestamp: '',
                linkstatus: 1,
                master_host: '10.0.0.102',
                master_log_file: 'binlog.000001',
                master_port: '3306',
                master_server_id: '5',
                master_uuid: '84223adc-b97d-11e7-85ff-080027b2ac87',
                read_master_log_pos: '670767471',
                relay_master_log_file: 'binlog.000001',
                retrieved_gtid_set: '',
                seconds_behind_master: '0',
                semisync_status: 'ON',
                slave_io_running: 'Yes',
                slave_io_state: 'Waiting for master to send event',
                slave_sql_running: 'Yes',
                slave_sql_state:
                    'Slave has read all relay log; waiting for more updates',
                sqldelay: 0,
                status:
                    'Waiting for master to send event<br/>Slave has read all relay log; waiting for more updates',
                using_gtid: 'ON',
            },
            role: 'multi',
            serverid: 1,
            skip_name_resolve: true,
            slaves: ['10.0.0.102:3306', '10.0.0.105:3306'],
            slow_query: {
                log_not_using_idxs: 'OFF',
                long_query_time: '0.500000',
                slow_query_log: 'ON',
                slow_query_log_file: '/var/log/mysql/mysql-slow.log',
            },
            sshfailcount: 0,
            ssl_certs: {
                replication: { ca: '', id: 0, key: '', path: '' },
                server: {
                    ca: '/var/lib/mysql/ca.pem',
                    id: 0,
                    key: '/var/lib/mysql/server-key.pem',
                    path: '/var/lib/mysql/server-cert.pem',
                },
            },
            super_read_only: false,
            timestamp: 1508937076,
            unique_id: 5,
            uptime: 4116,
            version: '5.7.19-17-log',
            wallclock: 1508937043,
            wallclocktimestamp: 1508937043,
        },
        {
            cdt_path: '/',
            class_name: 'CmonMySqlHost',
            clusterid: 2,
            configfile: ['/etc/mysql/my.cnf'],
            connected: true,
            container: false,
            datadir: '/var/lib/mysql/',
            distribution: {
                codename: 'trusty',
                name: 'ubuntu',
                release: '14.04',
                type: 'debian',
            },
            errorcode: 0,
            errormsg: 'Up and running.',
            hostId: 6,
            hostname: '10.0.0.103',
            hostname_data: '10.0.0.103',
            hoststatus: 'CmonHostOnline',
            ip: '10.0.0.103',
            isgalera: false,
            lastseen: 1508937076,
            log_bin: 'ON',
            logfile: '/var/log/mysql/mysqld.log',
            maintenance_mode_active: false,
            message: 'Up and running',
            mysqlstatus: 0,
            nodeid: 6,
            nodetype: 'mysql',
            performance_schema: false,
            pid: 15033,
            pidfile: '/var/lib/mysql/mysql.pid',
            pingstatus: 1,
            pingtime: 1826,
            port: '3306',
            readonly: true,
            replication_master: {
                binlog_do_db: '',
                binlog_ignore_db: '',
                exec_gtid: '63d86139-b97c-11e7-bbad-080027b2ac87:1-118592',
                file: 'binlog.000001',
                position: '670769619',
                semisync_status: 'ON',
            },
            replication_slave: {
                exec_master_log_pos: '670766061',
                executed_gtid_set:
                    '63d86139-b97c-11e7-bbad-080027b2ac87:1-118589',
                last_io_errno: '0',
                last_io_error: '',
                last_io_error_timestamp: '',
                last_sql_errno: '0',
                last_sql_error: '',
                last_sql_error_timestamp: '',
                linkstatus: 1,
                master_host: '10.0.0.105',
                master_log_file: 'binlog.000003',
                master_port: '3306',
                master_server_id: '3',
                master_uuid: 'eaac8ca5-b97c-11e7-b238-080027b2ac87',
                read_master_log_pos: '670767135',
                relay_master_log_file: 'binlog.000003',
                retrieved_gtid_set:
                    '63d86139-b97c-11e7-bbad-080027b2ac87:82918-118591',
                seconds_behind_master: '0',
                semisync_status: 'ON',
                slave_io_running: 'Yes',
                slave_io_state: 'Waiting for master to send event',
                slave_sql_running: 'Yes',
                slave_sql_state: 'System lock',
                sqldelay: 0,
                status: 'Waiting for master to send event<br/>System lock',
                using_gtid: 'ON',
            },
            role: 'multi',
            serverid: 2,
            skip_name_resolve: true,
            slaves: ['10.0.0.106:3306'],
            slow_query: {
                log_not_using_idxs: 'OFF',
                long_query_time: '0.500000',
                slow_query_log: 'ON',
                slow_query_log_file: '/var/log/mysql/mysql-slow.log',
            },
            sshfailcount: 0,
            ssl_certs: {
                replication: { ca: '', id: 0, key: '', path: '' },
                server: {
                    ca: '/var/lib/mysql/ca.pem',
                    id: 0,
                    key: '/var/lib/mysql/server-key.pem',
                    path: '/var/lib/mysql/server-cert.pem',
                },
            },
            super_read_only: false,
            timestamp: 1508937076,
            unique_id: 6,
            uptime: 4006,
            version: '5.7.19-17-log',
            wallclock: 1508937043,
            wallclocktimestamp: 1508937043,
        },
        {
            cdt_path: '/',
            class_name: 'CmonMySqlHost',
            clusterid: 2,
            configfile: ['/etc/mysql/my.cnf'],
            connected: true,
            container: false,
            datadir: '/var/lib/mysql/',
            distribution: {
                codename: 'trusty',
                name: 'ubuntu',
                release: '14.04',
                type: 'debian',
            },
            errorcode: 0,
            errormsg: 'Up and running.',
            hostId: 7,
            hostname: '10.0.0.105',
            hostname_data: '10.0.0.105',
            hoststatus: 'CmonHostOnline',
            ip: '10.0.0.105',
            isgalera: false,
            lastseen: 1508937076,
            log_bin: 'ON',
            logfile: '/var/log/mysql/mysqld.log',
            maintenance_mode_active: false,
            message: 'Up and running',
            mysqlstatus: 0,
            nodeid: 7,
            nodetype: 'mysql',
            performance_schema: false,
            pid: 14957,
            pidfile: '/var/lib/mysql/mysql.pid',
            pingstatus: 1,
            pingtime: 4235,
            port: '3306',
            readonly: true,
            replication_master: {
                binlog_do_db: '',
                binlog_ignore_db: '',
                exec_gtid: '63d86139-b97c-11e7-bbad-080027b2ac87:1-118593',
                file: 'binlog.000003',
                position: '670768842',
                semisync_status: 'ON',
            },
            replication_slave: {
                exec_master_log_pos: '19245215',
                executed_gtid_set:
                    '63d86139-b97c-11e7-bbad-080027b2ac87:1-118592',
                last_io_errno: '0',
                last_io_error: '',
                last_io_error_timestamp: '',
                last_sql_errno: '0',
                last_sql_error: '',
                last_sql_error_timestamp: '',
                linkstatus: 1,
                master_host: '10.0.0.101',
                master_log_file: 'binlog.000005',
                master_port: '3306',
                master_server_id: '1',
                master_uuid: '63d86139-b97c-11e7-bbad-080027b2ac87',
                read_master_log_pos: '19245215',
                relay_master_log_file: 'binlog.000005',
                retrieved_gtid_set:
                    '63d86139-b97c-11e7-bbad-080027b2ac87:1-118592',
                seconds_behind_master: '0',
                semisync_status: 'ON',
                slave_io_running: 'Yes',
                slave_io_state: 'Waiting for master to send event',
                slave_sql_running: 'Yes',
                slave_sql_state:
                    'Slave has read all relay log; waiting for more updates',
                sqldelay: 0,
                status:
                    'Waiting for master to send event<br/>Slave has read all relay log; waiting for more updates',
                using_gtid: 'ON',
            },
            role: 'multi',
            serverid: 3,
            skip_name_resolve: true,
            slaves: ['10.0.0.103:3306'],
            slow_query: {
                log_not_using_idxs: 'OFF',
                long_query_time: '0.500000',
                slow_query_log: 'ON',
                slow_query_log_file: '/var/log/mysql/mysql-slow.log',
            },
            sshfailcount: 0,
            ssl_certs: {
                replication: { ca: '', id: 0, key: '', path: '' },
                server: {
                    ca: '/var/lib/mysql/ca.pem',
                    id: 0,
                    key: '/var/lib/mysql/server-key.pem',
                    path: '/var/lib/mysql/server-cert.pem',
                },
            },
            super_read_only: false,
            timestamp: 1508937076,
            unique_id: 7,
            uptime: 3889,
            version: '5.7.19-17-log',
            wallclock: 1508937043,
            wallclocktimestamp: 1508937043,
        },
        {
            cdt_path: '/',
            class_name: 'CmonMySqlHost',
            clusterid: 2,
            configfile: ['/etc/mysql/my.cnf'],
            connected: true,
            container: false,
            datadir: '/var/lib/mysql/',
            distribution: {
                codename: 'trusty',
                name: 'ubuntu',
                release: '14.04',
                type: 'debian',
            },
            errorcode: 0,
            errormsg: 'Up and running.',
            hostId: 8,
            hostname: '10.0.0.106',
            hostname_data: '10.0.0.106',
            hoststatus: 'CmonHostOnline',
            ip: '10.0.0.106',
            isgalera: false,
            lastseen: 1508937076,
            log_bin: 'ON',
            logfile: '/var/log/mysql/mysqld.log',
            maintenance_mode_active: false,
            message: 'Up and running',
            mysqlstatus: 0,
            nodeid: 8,
            nodetype: 'mysql',
            performance_schema: false,
            pid: 14744,
            pidfile: '/var/lib/mysql/mysql.pid',
            pingstatus: 1,
            pingtime: 735,
            port: '3306',
            readonly: true,
            replication_master: {
                binlog_do_db: '',
                binlog_ignore_db: '',
                exec_gtid: '63d86139-b97c-11e7-bbad-080027b2ac87:1-118596',
                file: 'binlog.000001',
                position: '670771768',
                semisync_status: 'OFF',
            },
            replication_slave: {
                exec_master_log_pos: '670770884',
                executed_gtid_set:
                    '63d86139-b97c-11e7-bbad-080027b2ac87:1-118594',
                last_io_errno: '0',
                last_io_error: '',
                last_io_error_timestamp: '',
                last_sql_errno: '0',
                last_sql_error: '',
                last_sql_error_timestamp: '',
                linkstatus: 1,
                master_host: '10.0.0.103',
                master_log_file: 'binlog.000001',
                master_port: '3306',
                master_server_id: '2',
                master_uuid: 'a46c72e6-b97c-11e7-b277-080027b2ac87',
                read_master_log_pos: '670771326',
                relay_master_log_file: 'binlog.000001',
                retrieved_gtid_set:
                    '63d86139-b97c-11e7-bbad-080027b2ac87:9-118595',
                seconds_behind_master: '0',
                semisync_status: 'ON',
                slave_io_running: 'Yes',
                slave_io_state: 'Waiting for master to send event',
                slave_sql_running: 'Yes',
                slave_sql_state:
                    'Slave has read all relay log; waiting for more updates',
                sqldelay: 0,
                status:
                    'Waiting for master to send event<br/>Slave has read all relay log; waiting for more updates',
                using_gtid: 'ON',
            },
            role: 'slave',
            serverid: 4,
            skip_name_resolve: true,
            slaves: [],
            slow_query: {
                log_not_using_idxs: 'OFF',
                long_query_time: '0.500000',
                slow_query_log: 'ON',
                slow_query_log_file: '/var/log/mysql/mysql-slow.log',
            },
            sshfailcount: 0,
            ssl_certs: {
                replication: { ca: '', id: 0, key: '', path: '' },
                server: {
                    ca: '/var/lib/mysql/ca.pem',
                    id: 0,
                    key: '/var/lib/mysql/server-key.pem',
                    path: '/var/lib/mysql/server-cert.pem',
                },
            },
            super_read_only: false,
            timestamp: 1508937076,
            unique_id: 8,
            uptime: 3790,
            version: '5.7.19-17-log',
            wallclock: 1508937043,
            wallclocktimestamp: 1508937043,
        },
        {
            cdt_path: '/',
            class_name: 'CmonMySqlHost',
            clusterid: 2,
            configfile: ['/etc/mysql/my.cnf'],
            connected: true,
            container: false,
            datadir: '/var/lib/mysql/',
            distribution: {
                codename: 'trusty',
                name: 'ubuntu',
                release: '14.04',
                type: 'debian',
            },
            errorcode: 0,
            errormsg: 'Up and running.',
            hostId: 9,
            hostname: '10.0.0.102',
            hostname_data: '10.0.0.102',
            hoststatus: 'CmonHostOnline',
            ip: '10.0.0.102',
            isgalera: false,
            lastseen: 1508937076,
            log_bin: 'ON',
            logfile: '/var/log/mysql/mysqld.log',
            maintenance_mode_active: false,
            message: 'Up and running',
            mysqlstatus: 0,
            nodeid: 9,
            nodetype: 'mysql',
            performance_schema: false,
            pid: 15271,
            pidfile: '/var/lib/mysql/mysql.pid',
            pingstatus: 1,
            pingtime: 1758,
            port: '3306',
            readonly: true,
            replication_master: {
                binlog_do_db: '',
                binlog_ignore_db: '',
                exec_gtid: '63d86139-b97c-11e7-bbad-080027b2ac87:1-118600',
                file: 'binlog.000001',
                position: '670773919',
                semisync_status: 'ON',
            },
            replication_slave: {
                exec_master_log_pos: '19247408',
                executed_gtid_set:
                    '63d86139-b97c-11e7-bbad-080027b2ac87:1-118596',
                last_io_errno: '0',
                last_io_error: '',
                last_io_error_timestamp: '',
                last_sql_errno: '0',
                last_sql_error: '',
                last_sql_error_timestamp: '',
                linkstatus: 1,
                master_host: '10.0.0.101',
                master_log_file: 'binlog.000005',
                master_port: '3306',
                master_server_id: '1',
                master_uuid: '63d86139-b97c-11e7-bbad-080027b2ac87',
                read_master_log_pos: '19247408',
                relay_master_log_file: 'binlog.000005',
                retrieved_gtid_set:
                    '63d86139-b97c-11e7-bbad-080027b2ac87:1-118596',
                seconds_behind_master: '0',
                semisync_status: 'ON',
                slave_io_running: 'Yes',
                slave_io_state: 'Waiting for master to send event',
                slave_sql_running: 'Yes',
                slave_sql_state:
                    'Slave has read all relay log; waiting for more updates',
                sqldelay: 0,
                status:
                    'Waiting for master to send event<br/>Slave has read all relay log; waiting for more updates',
                using_gtid: 'ON',
            },
            role: 'multi',
            serverid: 5,
            skip_name_resolve: true,
            slaves: ['10.0.0.101:3306', '10.0.0.104:3306'],
            slow_query: {
                log_not_using_idxs: 'OFF',
                long_query_time: '0.500000',
                slow_query_log: 'OFF',
                slow_query_log_file: '/var/log/mysql/mysql-slow.log',
            },
            sshfailcount: 0,
            ssl_certs: {
                replication: { ca: '', id: 0, key: '', path: '' },
                server: {
                    ca: '/var/lib/mysql/ca.pem',
                    id: 0,
                    key: '/var/lib/mysql/server-key.pem',
                    path: '/var/lib/mysql/server-cert.pem',
                },
            },
            super_read_only: false,
            timestamp: 1508937076,
            unique_id: 9,
            uptime: 3631,
            version: '5.7.19-17-log',
            wallclock: 1508937043,
            wallclocktimestamp: 1508937043,
        },
        {
            cdt_path: '/',
            class_name: 'CmonMySqlHost',
            clusterid: 2,
            configfile: ['/etc/mysql/my.cnf'],
            connected: true,
            container: false,
            datadir: '/var/lib/mysql/',
            distribution: {
                codename: 'trusty',
                name: 'ubuntu',
                release: '14.04',
                type: 'debian',
            },
            errorcode: 0,
            errormsg: 'Up and running.',
            hostId: 10,
            hostname: '10.0.0.104',
            hostname_data: '10.0.0.104',
            hoststatus: 'CmonHostOnline',
            ip: '10.0.0.104',
            isgalera: false,
            lastseen: 1508937076,
            log_bin: 'ON',
            logfile: '/var/log/mysql/mysqld.log',
            maintenance_mode_active: false,
            message: 'Up and running',
            mysqlstatus: 0,
            nodeid: 10,
            nodetype: 'mysql',
            performance_schema: false,
            pid: 14741,
            pidfile: '/var/lib/mysql/mysql.pid',
            pingstatus: 1,
            pingtime: 444,
            port: '3306',
            readonly: true,
            replication_master: {
                binlog_do_db: '',
                binlog_ignore_db: '',
                exec_gtid: '63d86139-b97c-11e7-bbad-080027b2ac87:1-118600',
                file: 'binlog.000001',
                position: '670773919',
                semisync_status: 'OFF',
            },
            replication_slave: {
                exec_master_log_pos: '670773919',
                executed_gtid_set:
                    '63d86139-b97c-11e7-bbad-080027b2ac87:1-118600',
                last_io_errno: '0',
                last_io_error: '',
                last_io_error_timestamp: '',
                last_sql_errno: '0',
                last_sql_error: '',
                last_sql_error_timestamp: '',
                linkstatus: 1,
                master_host: '10.0.0.102',
                master_log_file: 'binlog.000001',
                master_port: '3306',
                master_server_id: '5',
                master_uuid: '84223adc-b97d-11e7-85ff-080027b2ac87',
                read_master_log_pos: '670773919',
                relay_master_log_file: 'binlog.000001',
                retrieved_gtid_set:
                    '63d86139-b97c-11e7-bbad-080027b2ac87:1-118600',
                seconds_behind_master: '0',
                semisync_status: 'ON',
                slave_io_running: 'Yes',
                slave_io_state: 'Waiting for master to send event',
                slave_sql_running: 'Yes',
                slave_sql_state:
                    'Slave has read all relay log; waiting for more updates',
                sqldelay: 0,
                status:
                    'Waiting for master to send event<br/>Slave has read all relay log; waiting for more updates',
                using_gtid: 'ON',
            },
            role: 'slave',
            serverid: 6,
            skip_name_resolve: true,
            slaves: [],
            slow_query: {
                log_not_using_idxs: 'OFF',
                long_query_time: '0.500000',
                slow_query_log: 'OFF',
                slow_query_log_file: '/var/log/mysql/mysql-slow.log',
            },
            sshfailcount: 0,
            ssl_certs: {
                replication: { ca: '', id: 0, key: '', path: '' },
                server: {
                    ca: '/var/lib/mysql/ca.pem',
                    id: 0,
                    key: '/var/lib/mysql/server-key.pem',
                    path: '/var/lib/mysql/server-cert.pem',
                },
            },
            super_read_only: false,
            timestamp: 1508937076,
            unique_id: 10,
            uptime: 3461,
            version: '5.7.19-17-log',
            wallclock: 1508937043,
            wallclocktimestamp: 1508937043,
        },
        {
            cdt_path: '/',
            class_name: 'CmonHost',
            clusterid: 2,
            configfile: '/etc/cmon.d/cmon_2.cnf',
            connected: true,
            container: false,
            distribution: {
                codename: 'trusty',
                name: 'ubuntu',
                release: '14.04',
                type: 'debian',
            },
            hostId: 11,
            hostname: '10.0.2.15',
            hoststatus: 'CmonHostOnline',
            ip: '10.0.2.15',
            lastseen: 1508937063,
            logfile: '/var/log/cmon_2.log',
            maintenance_mode_active: false,
            message: 'Up and running',
            nodetype: 'controller',
            pid: 13259,
            pingstatus: 1,
            pingtime: 83,
            port: 9500,
            role: 'controller',
            timestamp: 1508937063,
            unique_id: 11,
            uptime: 19085,
            version: '1.5.0.2180',
            wallclock: 1508937043,
            wallclocktimestamp: 1508937043,
        },
        {
            admin_user: 'admin',
            backend_info: {
                backend_servers: [
                    {
                        Bytes_data_recv: '181694144',
                        Bytes_data_sent: '2934359',
                        ConnERR: '0',
                        ConnFree: '3',
                        ConnUsed: '0',
                        Latency: '808',
                        Queries: '66040',
                        class_name: 'CmonProxySqlServer',
                        comment: 'read server',
                        compression: '0',
                        hostgroup_id: '20',
                        hostname: '10.0.0.105',
                        max_connections: '100',
                        max_latency_ms: '0',
                        max_replication_lag: '10',
                        port: '3306',
                        status: 'ONLINE',
                        use_ssl: '0',
                        weight: '1',
                    },
                    {
                        Bytes_data_recv: '0',
                        Bytes_data_sent: '13861410',
                        ConnERR: '0',
                        ConnFree: '6',
                        ConnUsed: '2',
                        Latency: '210',
                        Queries: '116522',
                        class_name: 'CmonProxySqlServer',
                        comment: 'read and write server',
                        compression: '0',
                        hostgroup_id: '10',
                        hostname: '10.0.0.101',
                        max_connections: '100',
                        max_latency_ms: '0',
                        max_replication_lag: '10',
                        port: '3306',
                        status: 'ONLINE',
                        use_ssl: '0',
                        weight: '1',
                    },
                    {
                        Bytes_data_recv: '189013600',
                        Bytes_data_sent: '3017421',
                        ConnERR: '0',
                        ConnFree: '2',
                        ConnUsed: '1',
                        Latency: '845',
                        Queries: '67789',
                        class_name: 'CmonProxySqlServer',
                        comment: 'read server',
                        compression: '0',
                        hostgroup_id: '20',
                        hostname: '10.0.0.102',
                        max_connections: '100',
                        max_latency_ms: '0',
                        max_replication_lag: '10',
                        port: '3306',
                        status: 'ONLINE',
                        use_ssl: '0',
                        weight: '1',
                    },
                    {
                        Bytes_data_recv: '184974848',
                        Bytes_data_sent: '2991675',
                        ConnERR: '0',
                        ConnFree: '1',
                        ConnUsed: '1',
                        Latency: '1071',
                        Queries: '67291',
                        class_name: 'CmonProxySqlServer',
                        comment: 'read server',
                        compression: '0',
                        hostgroup_id: '20',
                        hostname: '10.0.0.103',
                        max_connections: '100',
                        max_latency_ms: '0',
                        max_replication_lag: '10',
                        port: '3306',
                        status: 'ONLINE',
                        use_ssl: '0',
                        weight: '1',
                    },
                    {
                        Bytes_data_recv: '185642856',
                        Bytes_data_sent: '3069574',
                        ConnERR: '0',
                        ConnFree: '2',
                        ConnUsed: '1',
                        Latency: '210',
                        Queries: '69375',
                        class_name: 'CmonProxySqlServer',
                        comment: 'read server',
                        compression: '0',
                        hostgroup_id: '20',
                        hostname: '10.0.0.101',
                        max_connections: '100',
                        max_latency_ms: '0',
                        max_replication_lag: '10',
                        port: '3306',
                        status: 'ONLINE',
                        use_ssl: '0',
                        weight: '1',
                    },
                    {
                        Bytes_data_recv: '192396788',
                        Bytes_data_sent: '3091044',
                        ConnERR: '0',
                        ConnFree: '1',
                        ConnUsed: '2',
                        Latency: '743',
                        Queries: '69484',
                        class_name: 'CmonProxySqlServer',
                        comment: 'read server',
                        compression: '0',
                        hostgroup_id: '20',
                        hostname: '10.0.0.104',
                        max_connections: '100',
                        max_latency_ms: '0',
                        max_replication_lag: '10',
                        port: '3306',
                        status: 'ONLINE',
                        use_ssl: '0',
                        weight: '1',
                    },
                    {
                        Bytes_data_recv: '186537352',
                        Bytes_data_sent: '3021748',
                        ConnERR: '0',
                        ConnFree: '1',
                        ConnUsed: '1',
                        Latency: '1118',
                        Queries: '67973',
                        class_name: 'CmonProxySqlServer',
                        comment: 'read server',
                        compression: '0',
                        hostgroup_id: '20',
                        hostname: '10.0.0.106',
                        max_connections: '100',
                        max_latency_ms: '0',
                        max_replication_lag: '10',
                        port: '3306',
                        status: 'ONLINE',
                        use_ssl: '0',
                        weight: '1',
                    },
                ],
            },
            cdt_path: '/',
            class_name: 'CmonProxySqlHost',
            clusterid: 2,
            cmdline: 'service proxysql start',
            configfile: '/etc/proxysql.cnf',
            datadir: '/var/lib/proxysql',
            distribution: {
                codename: 'trusty',
                name: 'ubuntu',
                release: '14.04',
                type: 'debian',
            },
            hostId: 5,
            hostname: '10.0.0.101',
            hoststatus: 'CmonHostOnline',
            ip: '10.0.0.101',
            lastseen: 1508937063,
            lb_policy: 'rw-split',
            listening_port: 6033,
            logfile: '/var/lib/proxysql/proxysql.log',
            maintenance_mode_active: false,
            message: "Process 'proxysql' is running.",
            monitor_user: 'proxysql-monitor',
            nodetype: 'proxysql',
            pid: 4622,
            pidfile: '/var/lib/proxysql/proxysql.pid',
            pingstatus: 1,
            pingtime: 4519,
            port: 6032,
            sshfailcount: 0,
            timestamp: 1508937066,
            unique_id: 12,
            version: '1.4.3',
            wallclock: 1508937043,
            wallclocktimestamp: 1508937043,
        },
    ],
    job_statistics: {
        class_name: 'CmonJobStatistics',
        cluster_id: 2,
        by_state: {
            ABORTED: 1,
            DEFINED: 0,
            DEQUEUED: 0,
            FAILED: 0,
            FINISHED: 6,
            PAUSED: 1,
            RUNNING: 0,
            SCHEDULED: 2,
        },
    },
    owner: {
        class_name: 'CmonUser',
        cdt_path: '/',
        acl: 'user::rwx,group::r--,other::r--',
        disabled: false,
        suspended: false,
        groups: [],
        timezone: {
            class_name: 'CmonTimeZone',
            name: 'Coordinated Universal Time',
            abbreviation: 'UTC',
            offset: 0,
            use_dst: false,
        },
    },
};
export default mock;
