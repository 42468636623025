import './TagsList.less';
import React from 'react';
import { Select, SelectProps, Tooltip } from 'antd';
import classNames from 'classnames';
import { CustomTagProps } from 'rc-select/lib/BaseSelect';

export default TagsList;
export type TagsListProps = {
    tags: string[];
    className?: string;
    tagRender?: (props: CustomTagProps) => React.ReactElement;
    title?: React.ReactNode;
    maxTagCount?: SelectProps['maxTagCount'];
};

function TagsList({
    tags,
    className,
    title,
    tagRender,
    maxTagCount = 'responsive',
    ...rest
}: TagsListProps) {
    return (
        // @todo find other way to limit visible tags
        <Tooltip title={title || tags.join(',')}>
            <Select
                className={classNames('TagsList', className)}
                mode="multiple"
                maxTagCount={maxTagCount}
                options={tags.map((tag) => ({
                    label: tag,
                    value: tag,
                }))}
                disabled={true}
                value={tags}
                tagRender={tagRender}
            />
        </Tooltip>
    );
}
