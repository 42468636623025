import React from 'react';
import ActionButton from '@severalnines/bar-frontend-components/build/lib/General/ActionButton';
import ButtonWithForm from '../../../common/General/ButtonWithForm';
import LdapSettingsModal from './LdapSettingsModal';
import { Space } from 'antd';
import { LdapSettingsIcon } from '../../../common/icons/icons';

export default LdapSettingsButton;

export type LdapSettingsButtonProps = {
    onSuccess?: () => void;
};

function LdapSettingsButton({ onSuccess, ...rest }: LdapSettingsButtonProps) {
    return (
        <ButtonWithForm
            button={
                <ActionButton {...rest}>
                    <Space>
                        <LdapSettingsIcon />
                        <span>LDAP Settings</span>
                    </Space>
                </ActionButton>
            }
            form={<LdapSettingsModal onSuccess={onSuccess} />}
        />
    );
}
