import React, { useState } from 'react';
import classNames from 'classnames';
import { ButtonProps } from 'antd/lib/button';
import { Button } from 'antd';
import { DeleteFilled, DeleteOutlined } from '@ant-design/icons';

export default DeleteButton;

export type DeleteButtonProps = ButtonProps & {};

function DeleteButton({ className, children, ...rest }: DeleteButtonProps) {
    const [hovered, setHovered] = useState<boolean>(false);

    const handleMouseEnter = () => {
        setHovered(true);
    };
    const handleMouseLeave = () => {
        setHovered(false);
    };
    return (
        <Button
            className={classNames('DeleteButton', className)}
            type="link"
            size="small"
            danger={true}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            {...rest}
        >
            {hovered ? <DeleteFilled /> : <DeleteOutlined />}
        </Button>
    );
}
