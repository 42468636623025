


export interface CmonProxySqlQueryRuleProps  {
    OK_msg?: string;
    active?: number;
    apply?: number;
    cache_ttl?: string;
    class_name?: string;
    client_addr?: string;
    comment?: string;
    delay?: string;
    destination_hostgroup?: string;
    digest?: string;
    error_msg?: string;
    flagIN?: number;
    flagOUT?: string;
    hits?: string;
    log?: string;
    match_digest?: string;
    match_pattern?: string;
    mirror_flagOUT?: string;
    mirror_hostgroup?: string;
    multiplex?: string;
    negate_match_pattern?: number;
    proxy_addr?: string;
    proxy_port?: string;
    reconnect?: string;
    replace_pattern?: string;
    retries?: string;
    rule_id?: string;
    schemaname?: string;
    sticky_conn?: string;
    timeout?: string;
    username?: string;

}



export default class CmonProxySqlQueryRule {
    public OKMsg?: string;
    public active?: number;
    public apply?: number;
    public cacheTtl?: string;
    public className?: string;
    public clientAddr?: string;
    public comment?: string;
    public delay?: string;
    public destinationHostgroup?: string;
    public digest?: string;
    public errorMsg?: string;
    public flagIN?: number;
    public flagOUT?: string;
    public hits?: string;
    public log?: string;
    public matchDigest?: string;
    public matchPattern?: string;
    public mirrorFlagOUT?: string;
    public mirrorHostgroup?: string;
    public multiplex?: string;
    public negateMatchPattern?: number;
    public proxyAddr?: string;
    public proxyPort?: string;
    public reconnect?: string;
    public replacePattern?: string;
    public retries?: string;
    public ruleId?: string;
    public schemaname?: string;
    public stickyConn?: string;
    public timeout?: string;
    public username?: string;


    constructor(props: CmonProxySqlQueryRuleProps) {
        this.OKMsg = props.OK_msg;
        this.active = props.active;
        this.apply = props.apply;
        this.cacheTtl = props.cache_ttl;
        this.className = props.class_name;
        this.clientAddr = props.client_addr;
        this.comment = props.comment;
        this.delay = props.delay;
        this.destinationHostgroup = props.destination_hostgroup;
        this.digest = props.digest;
        this.errorMsg = props.error_msg;
        this.flagIN = props.flagIN;
        this.flagOUT = props.flagOUT;
        this.hits = props.hits;
        this.log = props.log;
        this.matchDigest = props.match_digest;
        this.matchPattern = props.match_pattern;
        this.mirrorFlagOUT = props.mirror_flagOUT;
        this.mirrorHostgroup = props.mirror_hostgroup;
        this.multiplex = props.multiplex;
        this.negateMatchPattern = props.negate_match_pattern;
        this.proxyAddr = props.proxy_addr;
        this.proxyPort = props.proxy_port;
        this.reconnect = props.reconnect;
        this.replacePattern = props.replace_pattern;
        this.retries = props.retries;
        this.ruleId = props.rule_id;
        this.schemaname = props.schemaname;
        this.stickyConn = props.sticky_conn;
        this.timeout = props.timeout;
        this.username = props.username;

    }

}
