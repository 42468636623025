import React from 'react';
import { NodesIcon } from '../../common/icons/icons';
import NodeList from './NodeList';
import AppPageWrapper from '../../common/Layout/AppPageWrapper';

export default NodesPage;

export type NodesPageProps = {};

function NodesPage({}: NodesPageProps) {
    return (
        <AppPageWrapper
            title={
                <span>
                    <NodesIcon /> Nodes
                </span>
            }
            className="ClusterList_content-header"
            transparentBackground={true}
            noPadding={true}
        >
            <NodeList />
        </AppPageWrapper>
    );
}
