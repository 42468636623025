

export interface CmonHostSslCertsServerProps {
    ca?: string;
    id?: number;
    key?: string;
    path?: string;
    ssl_enabled?: boolean;
}

export interface CmonHostSslCertsReplicationProps {
    ca?: string;
    id?: number;
    key?: string;
    path?: string;
}

export interface CmonHostSslCertsProps {
    replication?: CmonHostSslCertsReplicationProps;
    server?: CmonHostSslCertsServerProps;
}

export interface CmonHostReplicationSlaveProps {
    master_cluster_id?: string;
    master_host?: string;
    master_port?: string;
    prev_master_host?: string;
    prev_master_port?: string;
    seconds_behind_master?: string;
}

export interface CmonHostDistributionProps {
    codename?: string;
    name?: string;
    release?: string;
    type?: string;
}


export interface CmonHostProps  {
    acl?: string;
    additionalconfigfiles?: string;
    alias?: string;
    cdt_path?: string;
    clusterid?: number;
    cmdline?: string;
    configfile?: string;
    connected?: boolean;
    container?: boolean;
    container_id?: string;
    controller_address?: string;
    customlogfile?: string;
    datadir?: string;
    description?: string;
    distribution?: CmonHostDistributionProps;
    effective_privileges?: string;
    failed_restarts?: number;
    hostId?: number;
    hostname?: string;
    hostname_data?: string;
    hostname_internal?: string;
    hoststatus?: string;
    ip?: string;
    ip_internal?: string;
    lastseen?: number;
    logfile?: string;
    maintenance_mode?: string;
    maintenance_mode_active?: boolean;
    message?: string;
    nodetype?: string;
    owner_group_id?: number;
    owner_group_name?: string;
    owner_user_id?: number;
    owner_user_name?: string;
    pid?: number;
    pidfile?: string;
    pingstatus?: number;
    pingtime?: number;
    port?: number;
    readonly?: boolean;
    replication_slave?: CmonHostReplicationSlaveProps;
    role?: string;
    service_started?: number;
    socket?: string;
    sshfailcount?: number;
    ssl?: boolean;
    ssl_certs?: CmonHostSslCertsProps;
    statereason?: string;
    status?: number;
    stopped_at?: string;
    tags?: string;
    timestamp?: number;
    unique_id?: number;
    uptime?: number;
    version?: string;
    wallclock?: number;
    wallclocktimestamp?: number;

}

export class CmonHostSslCertsServer {
    public ca?: string;
    public id?: number;
    public key?: string;
    public path?: string;
    public sslEnabled?: boolean;

    constructor(props: CmonHostSslCertsServerProps) {
        this.ca = props.ca;
        this.id = props.id;
        this.key = props.key;
        this.path = props.path;
        this.sslEnabled = props.ssl_enabled;

    }}

export class CmonHostSslCertsReplication {
    public ca?: string;
    public id?: number;
    public key?: string;
    public path?: string;

    constructor(props: CmonHostSslCertsReplicationProps) {
        this.ca = props.ca;
        this.id = props.id;
        this.key = props.key;
        this.path = props.path;

    }}

export class CmonHostSslCerts {
    public replication?: CmonHostSslCertsReplication;
    public server?: CmonHostSslCertsServer;

    constructor(props: CmonHostSslCertsProps) {
        this.replication = props.replication && new CmonHostSslCertsReplication(props.replication);
        this.server = props.server && new CmonHostSslCertsServer(props.server);

    }}

export class CmonHostReplicationSlave {
    public masterClusterId?: string;
    public masterHost?: string;
    public masterPort?: string;
    public prevMasterHost?: string;
    public prevMasterPort?: string;
    public secondsBehindMaster?: string;

    constructor(props: CmonHostReplicationSlaveProps) {
        this.masterClusterId = props.master_cluster_id;
        this.masterHost = props.master_host;
        this.masterPort = props.master_port;
        this.prevMasterHost = props.prev_master_host;
        this.prevMasterPort = props.prev_master_port;
        this.secondsBehindMaster = props.seconds_behind_master;

    }}

export class CmonHostDistribution {
    public codename?: string;
    public name?: string;
    public release?: string;
    public type?: string;

    constructor(props: CmonHostDistributionProps) {
        this.codename = props.codename;
        this.name = props.name;
        this.release = props.release;
        this.type = props.type;

    }}



export default class CmonHost {
    public acl?: string;
    public additionalconfigfiles?: string;
    public alias?: string;
    public cdtPath?: string;
    public clusterid?: number;
    public cmdline?: string;
    public configfile?: string;
    public connected?: boolean;
    public container?: boolean;
    public containerId?: string;
    public controllerAddress?: string;
    public customlogfile?: string;
    public datadir?: string;
    public description?: string;
    public distribution?: CmonHostDistribution;
    public effectivePrivileges?: string;
    public failedRestarts?: number;
    public hostId?: number;
    public hostname?: string;
    public hostnameData?: string;
    public hostnameInternal?: string;
    public hoststatus?: string;
    public ip?: string;
    public ipInternal?: string;
    public lastseen?: number;
    public logfile?: string;
    public maintenanceMode?: string;
    public maintenanceModeActive?: boolean;
    public message?: string;
    public nodetype?: string;
    public ownerGroupId?: number;
    public ownerGroupName?: string;
    public ownerUserId?: number;
    public ownerUserName?: string;
    public pid?: number;
    public pidfile?: string;
    public pingstatus?: number;
    public pingtime?: number;
    public port?: number;
    public readonly?: boolean;
    public replicationSlave?: CmonHostReplicationSlave;
    public role?: string;
    public serviceStarted?: number;
    public socket?: string;
    public sshfailcount?: number;
    public ssl?: boolean;
    public sslCerts?: CmonHostSslCerts;
    public statereason?: string;
    public status?: number;
    public stoppedAt?: string;
    public tags?: string;
    public timestamp?: number;
    public uniqueId?: number;
    public uptime?: number;
    public version?: string;
    public wallclock?: number;
    public wallclocktimestamp?: number;


    constructor(props: CmonHostProps) {
        this.acl = props.acl;
        this.additionalconfigfiles = props.additionalconfigfiles;
        this.alias = props.alias;
        this.cdtPath = props.cdt_path;
        this.clusterid = props.clusterid;
        this.cmdline = props.cmdline;
        this.configfile = props.configfile;
        this.connected = props.connected;
        this.container = props.container;
        this.containerId = props.container_id;
        this.controllerAddress = props.controller_address;
        this.customlogfile = props.customlogfile;
        this.datadir = props.datadir;
        this.description = props.description;
        this.distribution = props.distribution && new CmonHostDistribution(props.distribution);
        this.effectivePrivileges = props.effective_privileges;
        this.failedRestarts = props.failed_restarts;
        this.hostId = props.hostId;
        this.hostname = props.hostname;
        this.hostnameData = props.hostname_data;
        this.hostnameInternal = props.hostname_internal;
        this.hoststatus = props.hoststatus;
        this.ip = props.ip;
        this.ipInternal = props.ip_internal;
        this.lastseen = props.lastseen;
        this.logfile = props.logfile;
        this.maintenanceMode = props.maintenance_mode;
        this.maintenanceModeActive = props.maintenance_mode_active;
        this.message = props.message;
        this.nodetype = props.nodetype;
        this.ownerGroupId = props.owner_group_id;
        this.ownerGroupName = props.owner_group_name;
        this.ownerUserId = props.owner_user_id;
        this.ownerUserName = props.owner_user_name;
        this.pid = props.pid;
        this.pidfile = props.pidfile;
        this.pingstatus = props.pingstatus;
        this.pingtime = props.pingtime;
        this.port = props.port;
        this.readonly = props.readonly;
        this.replicationSlave = props.replication_slave && new CmonHostReplicationSlave(props.replication_slave);
        this.role = props.role;
        this.serviceStarted = props.service_started;
        this.socket = props.socket;
        this.sshfailcount = props.sshfailcount;
        this.ssl = props.ssl;
        this.sslCerts = props.ssl_certs && new CmonHostSslCerts(props.ssl_certs);
        this.statereason = props.statereason;
        this.status = props.status;
        this.stoppedAt = props.stopped_at;
        this.tags = props.tags;
        this.timestamp = props.timestamp;
        this.uniqueId = props.unique_id;
        this.uptime = props.uptime;
        this.version = props.version;
        this.wallclock = props.wallclock;
        this.wallclocktimestamp = props.wallclocktimestamp;

    }

}
