import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Row, Upload } from 'antd';
import IntegrationName from './IntegrationName';
import IntegrationComment from './IntegrationComment';
import { UploadOutlined } from '@ant-design/icons';
import { getCloudCredentialsSummary } from '../CloudCredentialsSummary';
import { FormInstance } from 'antd/lib/form';
import { RcFile } from 'antd/lib/upload';
import { CcCloudProviderType } from '../../../../services/models/CcCloudCredentials';

export default CloudJsonAuthentication;

export type CloudJsonAuthenticationProps = {
    form: FormInstance;
    type: CcCloudProviderType;
};

function CloudJsonAuthentication({ form, type }: CloudJsonAuthenticationProps) {
    const [showSummary, setShowSummary] = useState(false);
    const handleBeforeUpload = (file: RcFile) => {
        const reader = new FileReader();
        reader.onload = (e) => {
            setShowSummary(false);
            if (e.target?.result && typeof e.target?.result === 'string') {
                form.setFieldsValue({
                    [type]: JSON.parse(e.target?.result),
                });
                setShowSummary(true);
            }
        };
        reader.readAsText(file);
        return false;
    };

    const normFile = (e: any) => {
        if (Array.isArray(e)) {
            return e;
        }
        return e && e.fileList;
    };

    useEffect(() => {
        if (form.getFieldValue([type])) {
            setShowSummary(true);
        }
    }, []);

    return (
        <div className="CloudJsonAuthentication">
            <Row gutter={[24, 0]}>
                <Col span={12}>
                    <IntegrationName />
                </Col>
                <Col span={12}>
                    <IntegrationComment />
                </Col>
                <Col span={12}>
                    <Form.Item
                        name="jsonFile"
                        label="Read from JSON file"
                        rules={[
                            {
                                validator: async (rule, value) => {
                                    // if credentials already loaded(could be edit mode) then do not throw error
                                    if (!form.getFieldValue(type) && !value) {
                                        throw new Error(
                                            'Please choose json file..'
                                        );
                                    }
                                    return true;
                                },
                            },
                        ]}
                        valuePropName="fileList"
                        getValueFromEvent={normFile}
                    >
                        <Upload
                            multiple={false}
                            maxCount={1}
                            beforeUpload={handleBeforeUpload}
                        >
                            <Button icon={<UploadOutlined />}>
                                Click to Upload
                            </Button>
                        </Upload>
                    </Form.Item>
                </Col>
                <Col span={24}>
                    {showSummary
                        ? getCloudCredentialsSummary({
                              type,
                              form,
                              showItems: 3,
                              expandable: true,
                          })
                        : null}
                </Col>
            </Row>
        </div>
    );
}

export function getCloudJsonAuthenticationValidate() {
    return ['jsonFile'];
}
