import React from 'react';
import { Col, Row } from 'antd';
import CreateServiceButton, {
    CreateServiceButtonProps,
} from '../components/Services/CreateServiceButton';
import { CcClusterType } from '../services/models/CcCluster';
import ClusterTypeFormat from '../components/Clusters/ClusterTypeFormat';
import CcNode from '../services/models/CcNode';
import { StatusFormatStatus } from '@severalnines/bar-frontend-components/build/lib/Format/StatusFormat';
import { ServiceWizardStep } from '../components/Services/ServiceWizard';
import { ImportWizardStep } from '../components/Services/Cluster/Import/ImportWizard';
import { ServiceClusterWizardStep } from '../components/Services/Cluster/ServiceClusterWizardStep';

export default DebugCreateServiceButton;

export type DebugCreateServiceButtonProps = {};

function DebugCreateServiceButton() {
    const samplesDeploy: {
        message: React.ReactNode;
        props: CreateServiceButtonProps;
    }[] = [
        {
            message: <span>Service Wizard</span>,
            props: {
                wizardProps: {
                    initialValues: {
                        details: {},
                        sshConfig: {},
                        topology: [],
                        nodeConfig: {},
                    },
                },
            },
        },
        {
            message: (
                <span>
                    Deploy{' '}
                    <ClusterTypeFormat
                        type={CcClusterType.TYPE_ELASTIC}
                        showIcon={true}
                    />
                </span>
            ),
            props: {
                wizardProps: {
                    activeStep: ServiceWizardStep.DEPLOY_WIZARD,
                    defaultDeployActiveStep: ServiceClusterWizardStep.PREVIEW,
                    initialValues: {
                        details: {
                            name: 'Elastic Cluster',
                            tags: ['hello', 'elastic'],
                            vendor: 'elasticsearch',
                            version: '7.16',
                        },
                        sshConfig: {
                            sshUser: 'root',
                            sshKeyPath: '/root/.ssh/id_rsa',
                            sshPort: 22,
                            sshSudoPassword: 'my password',
                            disableFirewall: true,
                            disableAppArmor: true,
                        },
                        eligibleMasterTopology: [
                            {
                                key: 'primary0',
                                title: '10.10.10.15',
                                status: StatusFormatStatus.success,
                                description: 'Eligible master',
                                extraData: {
                                    hostname: '10.10.10.15',
                                },
                            },
                            {
                                key: 'primary1',
                                title: '10.10.10.25',
                                status: StatusFormatStatus.success,
                                description: 'Eligible master',
                                extraData: {
                                    hostname: '10.10.10.25',
                                },
                            },
                            {
                                key: 'primary2',
                                title: '10.10.10.35',
                                status: StatusFormatStatus.success,
                                description: 'Eligible master',
                                extraData: {
                                    hostname: '10.10.10.35',
                                },
                            },
                        ],
                        dataNodeTopology: [
                            {
                                key: 'primary0',
                                title: '10.10.10.45',
                                status: StatusFormatStatus.success,
                                description: 'Data node',
                                extraData: {
                                    hostname: '10.10.10.45',
                                },
                            },
                            {
                                key: 'primary1',
                                title: '10.10.10.55',
                                status: StatusFormatStatus.success,
                                description: 'Data node',
                                extraData: {
                                    hostname: '10.10.10.55',
                                },
                            },
                        ],
                        useAsDataNode: {
                            '10.10.10.15': true,
                            '10.10.10.25': true,
                            '10.10.10.35': true,
                        },
                        repository: { storageHost: '10.10.10.25' },
                        nodeConfig: {
                            dbPassword: 'password',
                        },
                    },
                    initialClusterType: CcClusterType.TYPE_ELASTIC,
                },
            },
        },

        {
            message: (
                <span>
                    Deploy{' '}
                    <ClusterTypeFormat
                        type={CcClusterType.TYPE_REDIS}
                        showIcon={true}
                    />
                </span>
            ),
            props: {
                wizardProps: {
                    activeStep: ServiceWizardStep.DEPLOY_WIZARD,
                    defaultDeployActiveStep: ServiceClusterWizardStep.TOPOLOGY,
                    initialValues: {
                        details: {
                            name: 'Redis Cluster',
                            tags: ['hello', 'redis'],
                            vendor: 'redis',
                            version: '6',
                        },
                        sshConfig: {
                            sshUser: 'root',
                            sshKeyPath: '/root/.ssh/id_rsa',
                            sshPort: 22,
                            sshSudoPassword: 'my password',
                            disableFirewall: true,
                            disableAppArmor: true,
                        },
                        topology: [
                            {
                                key: 'primary0',
                                title: '10.10.10.15',
                                status: StatusFormatStatus.success,
                                extraData: new CcNode({
                                    hostname: '10.10.10.15',
                                }),
                            },
                            {
                                key: 'primary0secondary0',
                                fromKey: 'primary0',
                                title: '10.10.10.16',
                                status: StatusFormatStatus.success,
                                extraData: new CcNode({
                                    hostname: '10.10.10.16',
                                }),
                            },
                        ],
                        nodeConfig: {},
                    },
                    initialClusterType: CcClusterType.TYPE_REDIS,
                },
            },
        },
        {
            message: (
                <span>
                    Deploy{' '}
                    <ClusterTypeFormat
                        type={CcClusterType.TYPE_REPLICATION}
                        showIcon={true}
                    />
                </span>
            ),
            props: {
                wizardProps: {
                    activeStep: ServiceWizardStep.DEPLOY_WIZARD,
                    defaultDeployActiveStep: ServiceClusterWizardStep.PREVIEW,
                    initialValues: {
                        details: {
                            name: 'Mysql Replication',
                            tags: ['hello', 'mysql'],
                            vendor: 'percona',
                            version: '8.0',
                        },
                        sshConfig: {
                            sshUser: 'root',
                            sshKeyPath: '/root/.ssh/id_rsa',
                            sshPort: 22,
                            sshSudoPassword: 'my password',
                            disableFirewall: true,
                            disableAppArmor: true,
                        },
                        topology: [
                            {
                                key: 'primary0',
                                title: '10.10.10.15',
                                status: StatusFormatStatus.success,
                                extraData: new CcNode({
                                    hostname: '10.10.10.15',
                                }),
                            },
                            {
                                key: 'primary0secondary0',
                                fromKey: 'primary0',
                                title: '10.10.10.16',
                                status: StatusFormatStatus.success,
                                extraData: new CcNode({
                                    hostname: '10.10.10.16',
                                }),
                            },
                        ],
                        useMultiPrimary: true,
                        topologyMultiPrimary: [
                            {
                                key: 'primary0',
                                title: '10.10.10.25',
                                status: StatusFormatStatus.success,
                                extraData: new CcNode({
                                    hostname: '10.10.10.25',
                                }),
                            },

                            {
                                key: 'primary0secondary0',
                                fromKey: 'primary0',
                                title: '10.10.10.26',
                                status: StatusFormatStatus.success,
                                extraData: new CcNode({
                                    hostname: '10.10.10.26',
                                }),
                            },
                        ],
                        nodeConfig: {
                            adminUser: 'user',
                            adminPassword: 'password',
                        },
                    },
                    initialClusterType: CcClusterType.TYPE_REPLICATION,
                },
            },
        },

        {
            message: (
                <span>
                    Deploy{' '}
                    <ClusterTypeFormat
                        type={CcClusterType.TYPE_GALERA}
                        showIcon={true}
                    />
                </span>
            ),
            props: {
                wizardProps: {
                    activeStep: ServiceWizardStep.DEPLOY_WIZARD,
                    defaultDeployActiveStep:
                        ServiceClusterWizardStep.NODE_CONFIG,
                    initialValues: {
                        details: {
                            name: 'Mysql Galera',
                            tags: ['hello', 'galera'],
                            vendor: 'percona',
                            version: '8.0',
                        },
                        sshConfig: {
                            sshUser: 'root',
                            sshKeyPath: '/root/.ssh/id_rsa',
                            sshPort: 22,
                            sshSudoPassword: 'my password',
                            disableFirewall: true,
                            disableAppArmor: true,
                        },
                        topology: [
                            {
                                key: 'primary0',
                                title: '10.10.10.15',
                                status: StatusFormatStatus.success,
                                extraData: new CcNode({
                                    hostname: '10.10.10.15',
                                }),
                            },
                            {
                                key: 'primary1',
                                title: '10.10.10.25',
                                status: StatusFormatStatus.success,
                                extraData: new CcNode({
                                    hostname: '10.10.10.25',
                                }),
                            },
                            {
                                key: 'primary2',
                                title: '10.10.10.35',
                                status: StatusFormatStatus.success,
                                extraData: new CcNode({
                                    hostname: '10.10.10.25',
                                }),
                            },
                        ],
                        nodeConfig: {
                            adminPassword: 'password',
                        },
                    },
                    initialClusterType: CcClusterType.TYPE_GALERA,
                },
            },
        },
        {
            message: (
                <span>
                    Deploy{' '}
                    <ClusterTypeFormat
                        type={CcClusterType.TYPE_POSTGRESQL}
                        showIcon={true}
                    />
                </span>
            ),
            props: {
                wizardProps: {
                    activeStep: ServiceWizardStep.DEPLOY_WIZARD,
                    defaultDeployActiveStep: ServiceClusterWizardStep.TOPOLOGY,
                    initialValues: {
                        details: {
                            name: 'PostgreSQL',
                            tags: ['hello', 'postgre'],
                            vendor: 'default',
                            version: '10',
                        },
                        sshConfig: {
                            sshUser: 'root',
                            sshKeyPath: '/root/.ssh/id_rsa',
                            sshPort: 22,
                            sshSudoPassword: 'my password',
                            disableFirewall: true,
                            disableAppArmor: true,
                        },
                        topology: [
                            {
                                key: 'primary0',
                                title: '10.10.10.15',
                                status: StatusFormatStatus.success,
                                extraData: new CcNode({
                                    hostname: '10.10.10.15',
                                }),
                            },
                            {
                                key: 'primary0secondary0',
                                fromKey: 'primary0',
                                title: '10.10.10.16',
                                status: StatusFormatStatus.success,
                                extraData: new CcNode({
                                    hostname: '10.10.10.16',
                                }),
                            },
                            {
                                key: 'primary0secondary1',
                                fromKey: 'primary0',
                                title: '10.10.10.17',
                                status: StatusFormatStatus.success,
                                extraData: new CcNode({
                                    hostname: '10.10.10.17',
                                }),
                            },
                        ],
                        nodeConfig: {
                            adminUser: 'root',
                            adminPassword: 'password',
                        },
                    },
                    initialClusterType: CcClusterType.TYPE_POSTGRESQL,
                },
            },
        },
        {
            message: (
                <span>
                    Deploy{' '}
                    <ClusterTypeFormat
                        type={CcClusterType.TYPE_TIMESCALEDB}
                        showIcon={true}
                    />
                </span>
            ),
            props: {
                wizardProps: {
                    activeStep: ServiceWizardStep.DEPLOY_WIZARD,
                    defaultDeployActiveStep: ServiceClusterWizardStep.TOPOLOGY,
                    initialValues: {
                        details: {
                            name: 'PostgreSQL',
                            tags: ['hello', 'postgre'],
                            vendor: 'default',
                            version: '10',
                        },
                        sshConfig: {
                            sshUser: 'root',
                            sshKeyPath: '/root/.ssh/id_rsa',
                            sshPort: 22,
                            sshSudoPassword: 'my password',
                            disableFirewall: true,
                            disableAppArmor: true,
                        },
                        topology: [
                            {
                                key: 'primary0',
                                title: '10.10.10.15',
                                status: StatusFormatStatus.success,
                                extraData: new CcNode({
                                    hostname: '10.10.10.15',
                                }),
                            },
                            {
                                key: 'primary0secondary0',
                                fromKey: 'primary0',
                                title: '10.10.10.16',
                                status: StatusFormatStatus.success,
                                extraData: new CcNode({
                                    hostname: '10.10.10.16',
                                }),
                            },
                            {
                                key: 'primary0secondary1',
                                fromKey: 'primary0',
                                title: '10.10.10.17',
                                status: StatusFormatStatus.success,
                                extraData: new CcNode({
                                    hostname: '10.10.10.17',
                                }),
                            },
                        ],
                        nodeConfig: {
                            adminUser: 'root',
                            adminPassword: 'password',
                        },
                    },
                    initialClusterType: CcClusterType.TYPE_TIMESCALEDB,
                },
            },
        },
        {
            message: (
                <span>
                    Deploy{' '}
                    <ClusterTypeFormat
                        type={CcClusterType.TYPE_MSSQL_SINGLE}
                        showIcon={true}
                    />
                </span>
            ),
            props: {
                wizardProps: {
                    activeStep: ServiceWizardStep.DEPLOY_WIZARD,
                    defaultDeployActiveStep: ServiceClusterWizardStep.DETAILS,
                    initialValues: {
                        details: {
                            name: 'SQL Server',
                            tags: ['hello', 'mssql'],
                            vendor: 'microsoft',
                            version: '2019',
                        },
                        sshConfig: {
                            sshUser: 'root',
                            sshKeyPath: '/root/.ssh/id_rsa',
                            sshPort: 22,
                            sshSudoPassword: 'my password',
                            // installSoftware: true,
                            disableFirewall: true,
                            disableAppArmor: true,
                        },
                        topology: [
                            {
                                key: 'primary0',
                                title: '10.10.10.15',
                                status: StatusFormatStatus.success,
                                extraData: new CcNode({
                                    hostname: '10.10.10.15',
                                }),
                            },
                        ],
                        nodeConfig: {
                            dbUser: 'dbUser',
                            dbPassword: 'dbPassword',
                        },
                    },
                    initialClusterType: CcClusterType.TYPE_MSSQL_SINGLE,
                },
            },
        },
        {
            message: (
                <span>
                    Deploy{' '}
                    <ClusterTypeFormat
                        type={CcClusterType.TYPE_MONGODB}
                        showIcon={true}
                    />
                </span>
            ),
            props: {
                wizardProps: {
                    activeStep: ServiceWizardStep.DEPLOY_WIZARD,
                    defaultDeployActiveStep:
                        ServiceClusterWizardStep.NODE_CONFIG,
                    initialValues: {
                        details: {
                            name: 'MongoDb',
                            tags: ['hello', 'mongo'],
                            vendor: 'percona',
                            version: '4.4',
                        },
                        sshConfig: {
                            sshUser: 'root',
                            sshKeyPath: '/root/.ssh/id_rsa',
                            sshPort: 22,
                            sshSudoPassword: 'my password',
                            disableFirewall: true,
                            disableAppArmor: true,
                        },
                        topology: [
                            {
                                key: 'primary0',
                                title: '10.10.10.15',
                                status: StatusFormatStatus.success,
                                extraData: new CcNode({
                                    hostname: '10.10.10.15',
                                }),
                            },
                            {
                                key: 'primary0secondary0',
                                fromKey: 'primary0',
                                title: '10.10.10.16',
                                status: StatusFormatStatus.success,
                                extraData: new CcNode({
                                    hostname: '10.10.10.16',
                                }),
                            },
                            {
                                key: 'primary0secondary1',
                                fromKey: 'primary0',
                                title: '10.10.10.17',
                                status: StatusFormatStatus.success,
                                extraData: new CcNode({
                                    hostname: '10.10.10.17',
                                }),
                            },
                        ],
                        nodeConfig: {
                            adminUser: 'root',
                            adminPassword: 'password',
                        },
                    },
                    initialClusterType: CcClusterType.TYPE_MONGODB,
                },
            },
        },
        {
            message: (
                <span>
                    Deploy{' '}
                    <ClusterTypeFormat
                        type={CcClusterType.TYPE_MONGODB_SHARDS}
                        showIcon={true}
                    />
                </span>
            ),
            props: {
                wizardProps: {
                    activeStep: ServiceWizardStep.DEPLOY_WIZARD,
                    defaultDeployActiveStep: ServiceClusterWizardStep.TOPOLOGY,
                    initialValues: {
                        details: {
                            name: 'MongoDb',
                            tags: ['hello', 'mongo'],
                            vendor: 'percona',
                            version: '4.4',
                        },
                        sshConfig: {
                            sshUser: 'root',
                            sshKeyPath: '/root/.ssh/id_rsa',
                            sshPort: 22,
                            sshSudoPassword: 'my password',
                            disableFirewall: true,
                            disableAppArmor: true,
                        },
                        configurationServersTopology: [
                            {
                                key: 'primary0',
                                title: 'config.server.1',
                                status: StatusFormatStatus.success,
                                extraData: new CcNode({
                                    hostname: 'config.server.1',
                                }),
                            },
                        ],
                        routersMongosTopology: [
                            {
                                key: 'primary0',
                                title: 'mongos.server.1',
                                status: StatusFormatStatus.success,
                                extraData: new CcNode({
                                    hostname: 'mongos.server.1',
                                }),
                            },
                        ],
                        nodeConfig: {
                            adminUser: 'root',
                            adminPassword: 'password',
                        },
                        shards: {
                            0: {
                                replicaSetName: 'shard0',
                                serverPort: 27018,
                                topology: [
                                    {
                                        key: 'primary0',
                                        type: 'primary',
                                        title: 'c10',
                                        status: StatusFormatStatus.success,
                                        extraData: new CcNode({
                                            hostname: 'c10',
                                        }),
                                    },
                                    {
                                        key: 'primary0secondary0',
                                        type: 'secondary',
                                        title: 'c20',
                                        index: 0,
                                        status: StatusFormatStatus.success,
                                        extraData: new CcNode({
                                            hostname: 'c20',
                                        }),
                                        fromKey: 'primary0',
                                    },
                                    {
                                        key: 'primary0secondary1',
                                        type: 'secondary',
                                        title: 'c30',
                                        index: 1,
                                        status: StatusFormatStatus.success,
                                        extraData: new CcNode({
                                            hostname: 'c30',
                                        }),
                                        fromKey: 'primary0',
                                    },
                                ],
                                perNode: {
                                    c10: {
                                        dataIp: 'c10.data',
                                    },
                                    c20: {
                                        dataIp: 'c20.data',
                                        slaveDelay: 2,
                                        priority: 1,
                                    },
                                    c30: {
                                        dataIp: 'c30.data',
                                        slaveDelay: 0,
                                        priority: 1,
                                        arbiter: true,
                                    },
                                },
                            },
                            1: {
                                replicaSetName: 'shard1',
                                serverPort: 27018,
                                topology: [
                                    {
                                        key: 'primary0',
                                        type: 'primary',
                                        title: 'c1',
                                        status: StatusFormatStatus.success,
                                        extraData: new CcNode({
                                            hostname: 'c1',
                                        }),
                                    },
                                    {
                                        key: 'primary0secondary0',
                                        type: 'secondary',
                                        title: 'c2',
                                        index: 0,
                                        status: StatusFormatStatus.success,
                                        extraData: new CcNode({
                                            hostname: 'c2',
                                        }),
                                        fromKey: 'primary0',
                                    },
                                    {
                                        key: 'primary0secondary1',
                                        type: 'secondary',
                                        title: 'c3',
                                        index: 1,
                                        status: StatusFormatStatus.success,
                                        extraData: new CcNode({
                                            hostname: 'c3',
                                        }),
                                        fromKey: 'primary0',
                                    },
                                ],
                                perNode: {
                                    c1: {
                                        dataIp: 'c1.data',
                                    },
                                    c2: {
                                        dataIp: 'c2.data',
                                        slaveDelay: 2,
                                        priority: 1,
                                    },
                                    c3: {
                                        dataIp: 'c3.data',
                                        slaveDelay: 0,
                                        priority: 1,
                                        arbiter: true,
                                    },
                                },
                            },
                        },
                    },
                    initialClusterType: CcClusterType.TYPE_MONGODB_SHARDS,
                },
            },
        },
    ] as any;
    const samplesImport: {
        message: React.ReactNode;
        props: CreateServiceButtonProps;
    }[] = [
        {
            message: (
                <span>
                    Import{' '}
                    <ClusterTypeFormat
                        type={CcClusterType.TYPE_REDIS}
                        showIcon={true}
                    />
                </span>
            ),
            props: {
                wizardProps: {
                    activeStep: ServiceWizardStep.IMPORT_WIZARD,
                    defaultImportActiveStep: ImportWizardStep.PREVIEW,
                    initialValues: {
                        details: {
                            name: 'Redis Cluster',
                            tags: ['hello', 'redis'],
                            vendor: 'redis',
                            version: '6',
                        },
                        sshConfig: {
                            sshUser: 'root',
                            sshKeyPath: '/root/.ssh/id_rsa',
                            sshPort: 22,
                            sshSudoPassword: 'my password',
                            disableFirewall: true,
                            disableAppArmor: true,
                        },
                        topology: [
                            {
                                key: 'primary0',
                                title: '10.10.10.15',
                                status: StatusFormatStatus.success,
                                extraData: new CcNode({
                                    hostname: '10.10.10.15',
                                }),
                            },
                            {
                                key: 'primary0secondary0',
                                fromKey: 'primary0',
                                title: '10.10.10.16',
                                status: StatusFormatStatus.success,
                                extraData: new CcNode({
                                    hostname: '10.10.10.16',
                                }),
                            },
                        ],
                        nodeConfig: {
                            clusterAutoRecovery: false,
                            nodeAutoRecovery: true,
                        },
                    },
                    initialClusterType: CcClusterType.TYPE_REDIS,
                },
            },
        },
        {
            message: (
                <span>
                    Import{' '}
                    <ClusterTypeFormat
                        type={CcClusterType.TYPE_REPLICATION}
                        showIcon={true}
                    />
                </span>
            ),
            props: {
                wizardProps: {
                    activeStep: ServiceWizardStep.IMPORT_WIZARD,
                    defaultImportActiveStep: ImportWizardStep.TOPOLOGY,
                    initialValues: {
                        details: {
                            name: 'Mysql Replication',
                            tags: ['hello', 'mysql'],
                            vendor: 'percona',
                            version: '8.0',
                        },
                        sshConfig: {
                            sshUser: 'root',
                            sshKeyPath: '/root/.ssh/id_rsa',
                            sshPort: 22,
                            sshSudoPassword: 'my password',
                            disableFirewall: true,
                            disableAppArmor: true,
                        },
                        topology: [
                            {
                                key: 'primary0',
                                title: '10.10.10.15',
                                status: StatusFormatStatus.success,
                                extraData: new CcNode({
                                    hostname: '10.10.10.15',
                                }),
                            },
                            {
                                key: 'primary1',
                                title: '10.10.10.16',
                                status: StatusFormatStatus.success,
                                extraData: new CcNode({
                                    hostname: '10.10.10.16',
                                }),
                            },
                        ],
                        nodeConfig: {
                            adminUser: 'root',
                            adminPassword: 'password',
                        },
                    },
                    initialClusterType: CcClusterType.TYPE_REPLICATION,
                },
            },
        },
        {
            message: (
                <span>
                    Import{' '}
                    <ClusterTypeFormat
                        type={CcClusterType.TYPE_GALERA}
                        showIcon={true}
                    />
                </span>
            ),
            props: {
                wizardProps: {
                    activeStep: ServiceWizardStep.IMPORT_WIZARD,
                    defaultImportActiveStep: ImportWizardStep.NODE_CONFIG,
                    initialValues: {
                        details: {
                            name: 'Mysql Galera',
                            tags: ['hello', 'mysql'],
                            vendor: 'default',
                            version: '10',
                        },
                        sshConfig: {
                            sshUser: 'root',
                            sshKeyPath: '/root/.ssh/id_rsa',
                            sshPort: 22,
                            sshSudoPassword: 'my password',
                            disableFirewall: true,
                            disableAppArmor: true,
                        },
                        topology: [
                            {
                                key: 'primary0',
                                title: '10.10.10.15',
                                status: StatusFormatStatus.success,
                                extraData: new CcNode({
                                    hostname: '10.10.10.15',
                                }),
                            },
                            {
                                key: 'primary1',
                                title: '10.10.10.25',
                                status: StatusFormatStatus.success,
                                extraData: new CcNode({
                                    hostname: '10.10.10.25',
                                }),
                            },
                            {
                                key: 'primary2',
                                title: '10.10.10.35',
                                status: StatusFormatStatus.success,
                                extraData: new CcNode({
                                    hostname: '10.10.10.35',
                                }),
                            },
                        ],
                        nodeConfig: {
                            adminUser: 'root',
                            adminPassword: 'password',
                        },
                    },
                    initialClusterType: CcClusterType.TYPE_GALERA,
                },
            },
        },
        {
            message: (
                <span>
                    Import{' '}
                    <ClusterTypeFormat
                        type={CcClusterType.TYPE_POSTGRESQL}
                        showIcon={true}
                    />
                </span>
            ),
            props: {
                wizardProps: {
                    activeStep: ServiceWizardStep.IMPORT_WIZARD,
                    defaultImportActiveStep: ImportWizardStep.TOPOLOGY,
                    initialValues: {
                        details: {
                            name: 'PostgreSQL',
                            tags: ['hello', 'postgre'],
                            vendor: 'percona',
                            version: '8.0',
                        },
                        sshConfig: {
                            sshUser: 'root',
                            sshKeyPath: '/root/.ssh/id_rsa',
                            sshPort: 22,
                            sshSudoPassword: 'my password',
                            disableFirewall: true,
                            disableAppArmor: true,
                        },
                        topologyServerPort: {
                            '10.10.10.15': 4567,
                        },
                        topologyLogFile: {
                            '10.10.10.15': '/master/logfile.log',
                            '10.10.10.16': '/secondary1/logfile.log',
                            '10.10.10.17': '/secondary2/logfile.log',
                        },
                        topology: [
                            {
                                key: 'primary0',
                                title: '10.10.10.15',
                                status: StatusFormatStatus.success,
                                extraData: new CcNode({
                                    hostname: '10.10.10.15',
                                }),
                            },
                            {
                                key: 'primary0secondary0',
                                fromKey: 'primary0',
                                title: '10.10.10.16',
                                status: StatusFormatStatus.success,
                                extraData: new CcNode({
                                    hostname: '10.10.10.16',
                                }),
                            },
                            {
                                key: 'primary0secondary1',
                                fromKey: 'primary0',
                                title: '10.10.10.17',
                                status: StatusFormatStatus.success,
                                extraData: new CcNode({
                                    hostname: '10.10.10.17',
                                }),
                            },
                        ],
                        nodeConfig: {
                            adminUser: 'root',
                            adminPassword: 'password',
                        },
                    },
                    initialClusterType: CcClusterType.TYPE_POSTGRESQL,
                },
            },
        },
        {
            message: (
                <span>
                    Import{' '}
                    <ClusterTypeFormat
                        type={CcClusterType.TYPE_TIMESCALEDB}
                        showIcon={true}
                    />
                </span>
            ),
            props: {
                wizardProps: {
                    activeStep: ServiceWizardStep.IMPORT_WIZARD,
                    defaultImportActiveStep: ImportWizardStep.TOPOLOGY,
                    initialValues: {
                        details: {
                            name: 'PostgreSQL',
                            tags: ['hello', 'postgre'],
                            vendor: 'percona',
                            version: '8.0',
                        },
                        sshConfig: {
                            sshUser: 'root',
                            sshKeyPath: '/root/.ssh/id_rsa',
                            sshPort: 22,
                            sshSudoPassword: 'my password',
                            disableFirewall: true,
                            disableAppArmor: true,
                        },
                        topologyServerPort: {
                            '10.10.10.15': 4567,
                        },
                        topologyLogFile: {
                            '10.10.10.15': '/master/logfile.log',
                            '10.10.10.16': '/secondary1/logfile.log',
                            '10.10.10.17': '/secondary2/logfile.log',
                        },
                        topology: [
                            {
                                key: 'primary0',
                                title: '10.10.10.15',
                                status: StatusFormatStatus.success,
                                extraData: new CcNode({
                                    hostname: '10.10.10.15',
                                }),
                            },
                            {
                                key: 'primary0secondary0',
                                fromKey: 'primary0',
                                title: '10.10.10.16',
                                status: StatusFormatStatus.success,
                                extraData: new CcNode({
                                    hostname: '10.10.10.16',
                                }),
                            },
                            {
                                key: 'primary0secondary1',
                                fromKey: 'primary0',
                                title: '10.10.10.17',
                                status: StatusFormatStatus.success,
                                extraData: new CcNode({
                                    hostname: '10.10.10.17',
                                }),
                            },
                        ],
                        nodeConfig: {
                            adminUser: 'root',
                            adminPassword: 'password',
                        },
                    },
                    initialClusterType: CcClusterType.TYPE_POSTGRESQL,
                },
            },
        },
        {
            message: (
                <span>
                    Import{' '}
                    <ClusterTypeFormat
                        type={CcClusterType.TYPE_MSSQL_SINGLE}
                        showIcon={true}
                    />
                </span>
            ),
            props: {
                wizardProps: {
                    activeStep: ServiceWizardStep.IMPORT_WIZARD,
                    defaultDeployActiveStep: ServiceClusterWizardStep.DETAILS,
                    initialValues: {
                        details: {
                            name: 'SQL Server',
                            tags: ['hello', 'mssql'],
                            vendor: 'microsoft',
                            version: '2019',
                        },
                        sshConfig: {
                            sshUser: 'root',
                            sshKeyPath: '/root/.ssh/id_rsa',
                            sshPort: 22,
                            sshSudoPassword: 'my password',
                        },
                        topology: [
                            {
                                key: 'primary0',
                                title: '10.10.10.15',
                                status: StatusFormatStatus.success,
                                extraData: new CcNode({
                                    hostname: '10.10.10.15',
                                }),
                            },
                        ],
                        nodeConfig: {
                            dbUser: 'dbUser',
                            dbPassword: 'dbPassword',
                            clusterAutoRecovery: true,
                            nodeAutoRecovery: true,
                        },
                    },
                    initialClusterType: CcClusterType.TYPE_MSSQL_SINGLE,
                },
            },
        },
        {
            message: (
                <span>
                    Import{' '}
                    <ClusterTypeFormat
                        type={CcClusterType.TYPE_MONGODB}
                        showIcon={true}
                    />
                </span>
            ),
            props: {
                wizardProps: {
                    activeStep: ServiceWizardStep.IMPORT_WIZARD,
                    defaultImportActiveStep: ImportWizardStep.NODE_CONFIG,
                    initialValues: {
                        details: {
                            name: 'MongoDB',
                            tags: ['hello', 'mongo'],
                            vendor: 'percona',
                            version: '4.4',
                        },
                        sshConfig: {
                            sshUser: 'root',
                            sshKeyPath: '/root/.ssh/id_rsa',
                            sshPort: 22,
                            sshSudoPassword: 'my password',
                            disableFirewall: true,
                            disableAppArmor: true,
                        },
                        topology: [
                            {
                                key: 'primary0',
                                title: '10.10.10.15',
                                status: StatusFormatStatus.success,
                                extraData: new CcNode({
                                    hostname: '10.10.10.15',
                                }),
                            },
                        ],
                        nodeConfig: {
                            adminUser: 'root',
                            adminPassword: 'password',
                        },
                    },
                    initialClusterType: CcClusterType.TYPE_MONGODB,
                },
            },
        },

        {
            message: (
                <span>
                    Import{' '}
                    <ClusterTypeFormat
                        type={CcClusterType.TYPE_MONGODB_SHARDS}
                        showIcon={true}
                    />
                </span>
            ),
            props: {
                wizardProps: {
                    activeStep: ServiceWizardStep.IMPORT_WIZARD,
                    defaultImportActiveStep: ImportWizardStep.NODE_CONFIG,
                    initialValues: {
                        details: {
                            name: 'MongoDB',
                            tags: ['hello', 'mongo'],
                            vendor: 'percona',
                            version: '4.4',
                        },
                        sshConfig: {
                            sshUser: 'root',
                            sshKeyPath: '/root/.ssh/id_rsa',
                            sshPort: 22,
                            sshSudoPassword: 'my password',
                            disableFirewall: true,
                            disableAppArmor: true,
                        },
                        topology: [
                            {
                                key: 'primary0',
                                title: '10.10.10.15',
                                status: StatusFormatStatus.success,
                                extraData: new CcNode({
                                    hostname: '10.10.10.15',
                                }),
                            },
                        ],
                        nodeConfig: {
                            adminUser: 'root',
                            adminPassword: 'password',
                        },
                    },
                    initialClusterType: CcClusterType.TYPE_MONGODB_SHARDS,
                },
            },
        },
    ] as any;
    return (
        <div className="DebugCreateServiceButton">
            <h1>CreateServiceButton</h1>
            <Row>
                <Col span={12}>
                    {samplesDeploy.map((sample, index) => (
                        <div key={index}>
                            <CreateServiceButton {...sample.props}>
                                {sample.message}
                            </CreateServiceButton>
                            <br />
                            <br />
                        </div>
                    ))}
                </Col>
                <Col span={12}>
                    {samplesImport.map((sample, index) => (
                        <div key={index}>
                            <CreateServiceButton {...sample.props}>
                                {sample.message}
                            </CreateServiceButton>
                            <br />
                            <br />
                        </div>
                    ))}
                </Col>
            </Row>
        </div>
    );
}
