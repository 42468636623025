const mock = {
    class_name: 'CmonClusterInfo',
    cdt_path: '/',
    acl: 'user::rwx,group::rwx,group:lowpermission:---,other::---',
    tags: [],
    cluster_auto_recovery: true,
    cluster_id: 4,
    cluster_name: 'Mysql Replication + HAProxy + ProxySQL + Keepalived',
    cluster_type: 'REPLICATION',
    configuration_file: '/etc/cmon.d/cmon_4.cnf',
    effective_privileges: 'rwx',
    log_file: '/var/log/cmon_4.log',
    maintenance_mode_active: false,
    managed: true,
    node_auto_recovery: true,
    rpc_version: '2.0',
    state: 'STARTED',
    status_text: 'All nodes are operational.',
    vendor: 'percona',
    version: '8.0',
    alarm_statistics: {
        class_name: 'CmonAlarmStatistics',
        cluster_id: 4,
        created_after: null,
        critical: 0,
        reported_after: null,
        warning: 0,
    },
    group_owner: {
        class_name: 'CmonGroup',
        cdt_path: '/groups',
        owner_user_id: 1,
        owner_user_name: 'system',
        owner_group_id: 1,
        owner_group_name: 'admins',
        acl: 'user::rwx,group::rwx,other::---',
        created: '2022-02-02T13:11:15.734Z',
        group_id: 1,
        group_name: 'admins',
    },
    hosts: [
        {
            class_name: 'CmonMySqlHost',
            cdt_path: '/cluster_4',
            owner_user_id: 5,
            owner_user_name: 'pablo',
            owner_group_id: 1,
            owner_group_name: 'admins',
            acl: 'user::rwx,group::rwx,other::---',
            clusterid: 4,
            connected: false,
            container: true,
            datadir: '/var/lib/mysql/',
            description: '',
            errorcode: 2002,
            errormsg: "Can't connect to MySQL server on 'c6' (115)",
            hostId: 14,
            hostname: 'c6',
            hostname_data: 'c6',
            hoststatus: 'CmonHostShutDown',
            ip: '10.0.8.16',
            isgalera: false,
            lastseen: 1698846811,
            log_bin: 'ON',
            log_bin_basename: '/var/lib/mysql/binlog',
            logfile: '/var/log/mysql/mysqld.log',
            maintenance_mode: 'none',
            maintenance_mode_active: false,
            message: 'Shut down.',
            mysqlstatus: 19,
            nodeid: 14,
            nodetype: 'mysql',
            performance_schema: false,
            pid: -1,
            pidfile: '/var/lib/mysql/mysql.pid',
            pingstatus: 1,
            pingtime: 127,
            port: 3306,
            readonly: true,
            role: 'master',
            serverid: 4001,
            skip_name_resolve: false,
            socket: '/var/lib/mysql/mysql.sock',
            sshfailcount: 0,
            statereason: 'Server was shutting down, now it is gone.',
            status: 19,
            stopped_at: '2023-08-22T18:05:58.467Z',
            super_read_only: true,
            timestamp: 1698846818,
            unique_id: 10,
            uptime: 10,
            version: '8.0.33-25',
            version_comment:
                "Percona Server (GPL), Release '25', Revision '60c9e2c5'",
            wallclock: 1698846781,
            wallclocktimestamp: 1698846781,
            audit_log: {
                plugins: [
                    {
                        name: 'sha2_cache_cleaner',
                        author: 'Oracle Corporation',
                        status: 'ACTIVE',
                        version: '1.0',
                    },
                    {
                        name: 'mysqlx_cache_cleaner',
                        author: 'Oracle Corporation',
                        status: 'ACTIVE',
                        version: '1.0',
                    },
                ],
            },
            configfile: ['/etc/mysql/my.cnf'],
            distribution: {
                name: 'ubuntu',
                codename: 'focal',
                release: '20.04',
                type: 'debian',
            },
            replication_master: {
                binlog_do_db: '',
                binlog_ignore_db: '',
                exec_gtid: 'ed4a9442-386d-11ee-983a-00163e26e13a:1-71',
                file: 'binlog.000012',
                position: '197',
                semisync_status: 'OFF',
            },
            replication_slave: {
                exec_master_log_pos: 23436335,
                executed_gtid_set: '',
                gtid_binlog_pos: '',
                gtid_io_pos: '',
                last_io_errno: '',
                last_io_error: '',
                linkstatus: 7,
                master_cluster_id: '-1',
                master_host: '',
                master_log_file: '',
                master_port: '',
                read_master_log_pos: '0',
                relay_master_log_file: '',
                retrieved_gtid_set: '',
                seconds_behind_master: '',
                semisync_status: '',
                slave_io_running: '',
                slave_io_state: '',
                slave_sql_running: '',
                slave_sql_state: '',
                status: '',
                using_gtid: '',
            },
            slaves: [],
            slow_query: {
                log_not_using_idxs: 'OFF',
                long_query_time: '2.000000',
                slow_query_log: 'ON',
                slow_query_log_file: '/var/log/mysql/mysql-slow.log',
            },
            ssl_certs: {
                replication: {
                    ca: '',
                    id: 0,
                    key: '',
                    path: '',
                },
                server: {
                    ca: '/etc/mysql/certs/server_ca.crt',
                    id: 6,
                    key: '/etc/mysql/certs/server.key',
                    path: '/etc/mysql/certs/server.crt',
                    ssl_enabled: true,
                },
            },
        },
        {
            class_name: 'CmonMySqlHost',
            cdt_path: '/cluster_4',
            owner_user_id: 5,
            owner_user_name: 'pablo',
            owner_group_id: 1,
            owner_group_name: 'admins',
            acl: 'user::rwx,group::rwx,other::---',
            clusterid: 4,
            configfile: ['/etc/mysql/my.cnf'],
            connected: true,
            container: true,
            datadir: '/var/lib/mysql/',
            errorcode: 0,
            errormsg: 'Up and running.',
            hostId: 15,
            hostname: 'c7',
            hostname_data: 'c7',
            hoststatus: 'CmonHostOnline',
            ip: '10.0.8.17',
            isgalera: false,
            lastseen: 1698846821,
            log_bin: 'ON',
            log_bin_basename: '/var/lib/mysql/binlog',
            logfile: '/var/log/mysql/mysqld.log',
            maintenance_mode: 'none',
            maintenance_mode_active: false,
            message: 'Up and running.',
            mysqlstatus: 0,
            nodeid: 15,
            nodetype: 'mysql',
            performance_schema: false,
            pid: 10593,
            pidfile: '/var/lib/mysql/mysql.pid',
            pingstatus: 1,
            pingtime: 137,
            port: 3306,
            readonly: false,
            role: 'master',
            serverid: 4002,
            skip_name_resolve: false,
            slaves: [],
            socket: '/var/lib/mysql/mysql.sock',
            sshfailcount: 0,
            statereason: 'Master is working fine.',
            status: 0,
            super_read_only: false,
            timestamp: 1698846821,
            unique_id: 11,
            uptime: 7070839,
            version: '8.0.33-25',
            version_comment:
                "Percona Server (GPL), Release '25', Revision '60c9e2c5'",
            wallclock: 1698846781,
            wallclocktimestamp: 1698846781,
            audit_log: {
                plugins: [
                    {
                        name: 'sha2_cache_cleaner',
                        author: 'Oracle Corporation',
                        status: 'ACTIVE',
                        version: '1.0',
                    },
                    {
                        name: 'mysqlx_cache_cleaner',
                        author: 'Oracle Corporation',
                        status: 'ACTIVE',
                        version: '1.0',
                    },
                ],
            },
            distribution: {
                name: 'ubuntu',
                codename: 'focal',
                release: '20.04',
                type: 'debian',
            },
            replication_master: {
                binlog_do_db: '',
                binlog_ignore_db: '',
                exec_gtid:
                    'eb929404-386e-11ee-b46a-00163e17f459:1-32,ed4a9442-386d-11ee-983a-00163e26e13a:1-71',
                file: 'binlog.000005',
                position: '9356',
                semisync_status: 'OFF',
            },
            replication_slave: {
                exec_master_log_pos: 23390127,
                executed_gtid_set: '',
                gtid_binlog_pos: '',
                gtid_io_pos: '',
                last_io_errno: '',
                last_io_error: '',
                last_io_error_timestamp: '230822 18:06:03',
                last_sql_errno: '',
                last_sql_error: '',
                last_sql_error_timestamp: '',
                linkstatus: 7,
                master_cluster_id: '-1',
                master_host: '',
                master_log_file: '',
                master_port: '',
                master_server_id: '',
                master_uuid: 'ed4a9442-386d-11ee-983a-00163e26e13a',
                read_master_log_pos: '0',
                relay_master_log_file: '',
                retrieved_gtid_set: '',
                seconds_behind_master: '',
                semisync_status: '',
                slave_io_running: '',
                slave_io_state: '',
                slave_sql_running: '',
                slave_sql_state: '',
                sqldelay: 0,
                status: '',
                using_gtid: '',
            },
            slow_query: {
                log_not_using_idxs: 'OFF',
                long_query_time: '0.500000',
                slow_query_log: 'OFF',
                slow_query_log_file: '/var/log/mysql/mysql-slow.log',
            },
            ssl_certs: {
                replication: {
                    ca: '',
                    id: 0,
                    key: '',
                    path: '',
                },
                server: {
                    ca: '/etc/mysql/certs/server_ca.crt',
                    id: 6,
                    key: '/etc/mysql/certs/server.key',
                    path: '/etc/mysql/certs/server.crt',
                    ssl_enabled: true,
                },
            },
        },
        {
            class_name: 'CmonHost',
            cdt_path: '/cluster_4',
            owner_user_id: 5,
            owner_user_name: 'pablo',
            owner_group_id: 1,
            owner_group_name: 'admins',
            acl: 'user::rwx,group::rwx,other::---',
            clusterid: 4,
            configfile: '/etc/cmon.d/cmon_4.cnf',
            connected: true,
            container: false,
            hostId: 16,
            hostname: '134.122.121.181',
            hoststatus: 'CmonHostOnline',
            ip: '134.122.121.181',
            lastseen: 1698846811,
            logfile: '/var/log/cmon_4.log',
            maintenance_mode: 'none',
            maintenance_mode_active: false,
            message: 'Up and running.',
            nodetype: 'controller',
            pid: 3590757,
            pingstatus: -1,
            pingtime: -1,
            port: 9500,
            role: 'controller',
            status: 10,
            timestamp: 1698846811,
            unique_id: 12,
            uptime: 522984,
            version: '1.9.8.6666',
            wallclock: 1698846781,
            wallclocktimestamp: 1698846781,
            distribution: {
                name: 'ubuntu',
                codename: 'focal',
                release: '20.04',
                type: 'debian',
            },
        },
        {
            class_name: 'CmonPrometheusHost',
            cdt_path: '/cluster_4',
            owner_user_id: 5,
            owner_user_name: 'pablo',
            owner_group_id: 1,
            owner_group_name: 'admins',
            acl: 'user::rwx,group::rwx,other::---',
            clusterid: 4,
            configfile: '/etc/prometheus/prometheus.yml',
            container: false,
            data_retention: '15d',
            data_retention_size: '',
            datadir: '/var/lib/prometheus',
            hostId: 16,
            hostname: '134.122.121.181',
            hoststatus: 'CmonHostOnline',
            ip: '134.122.121.181',
            lastseen: 1698846811,
            logfile: '/var/log/prometheus/prometheus.log',
            maintenance_mode: 'none',
            maintenance_mode_active: false,
            managed_by_sd: false,
            message: "Process 'prometheus' is running.",
            nodetype: 'prometheus',
            pid: 1884288,
            pingstatus: -1,
            pingtime: -1,
            port: 9090,
            scrape_interval: '30s',
            statereason: 'CmonProcessManager::prometheusFound()',
            status: 10,
            timestamp: 1698846811,
            unique_id: 13,
            version: '2.29.2',
            wallclock: 1698846781,
            wallclocktimestamp: 1698846781,
            configuration: [
                {
                    arguments: '',
                    job: 'prometheus',
                    scrape_interval: '2s',
                },
                {
                    arguments: '',
                    job: 'node',
                    scrape_interval: '30s',
                },
                {
                    arguments: '',
                    job: 'process',
                    scrape_interval: '30s',
                },
                {
                    arguments:
                        ' --collect.perf_schema.eventswaits  --collect.perf_schema.file_events  --collect.perf_schema.file_instances  --collect.perf_schema.indexiowaits  --collect.perf_schema.tableiowaits  --collect.perf_schema.tablelocks  --collect.info_schema.tablestats  --collect.info_schema.processlist  --collect.info_schema.userstats  --collect.binlog_size  --collect.global_status  --collect.global_variables  --collect.info_schema.innodb_metrics  --collect.slave_status ',
                    job: 'mysqld',
                    scrape_interval: '30s',
                },
                {
                    arguments: '',
                    job: 'haproxy',
                    scrape_interval: '30s',
                },
                {
                    arguments: '',
                    job: 'proxysql',
                    scrape_interval: '30s',
                },
            ],
            distribution: {
                name: 'ubuntu',
                codename: 'focal',
                release: '20.04',
                type: 'debian',
            },
            exporters: [
                {
                    address: '134.122.121.181:9011',
                    job: 'process',
                    monitored_host: '',
                    up: true,
                    version: '0.10.10',
                },
                {
                    address: '134.122.121.181:9100',
                    job: 'node',
                    monitored_host: '',
                    up: true,
                    version: '1.6.1',
                },
                {
                    address: 'c6:42004',
                    job: 'proxysql',
                    monitored_host: 'c6:6032',
                    up: true,
                    version: '1.1.0',
                },
                {
                    address: 'c6:9011',
                    job: 'process',
                    monitored_host: '',
                    up: true,
                    version: '0.10.10',
                },
                {
                    address: 'c6:9100',
                    job: 'node',
                    monitored_host: '',
                    up: true,
                    version: '1.6.1',
                },
                {
                    address: 'c6:9101',
                    job: 'haproxy',
                    monitored_host: 'c6:9600',
                    up: true,
                    version: '0.9.0',
                },
                {
                    address: 'c6:9104',
                    job: 'mysqld',
                    monitored_host: 'c6:3306',
                    up: false,
                    version: '0.13.0',
                },
                {
                    address: 'c7:42004',
                    job: 'proxysql',
                    monitored_host: 'c7:6032',
                    up: true,
                    version: '1.1.0',
                },
                {
                    address: 'c7:9011',
                    job: 'process',
                    monitored_host: '',
                    up: true,
                    version: '0.10.10',
                },
                {
                    address: 'c7:9100',
                    job: 'node',
                    monitored_host: '',
                    up: true,
                    version: '1.6.1',
                },
                {
                    address: 'c7:9101',
                    job: 'haproxy',
                    monitored_host: 'c7:9600',
                    up: true,
                    version: '0.9.0',
                },
                {
                    address: 'c7:9104',
                    job: 'mysqld',
                    monitored_host: 'c7:3306',
                    up: true,
                    version: '0.13.0',
                },
            ],
        },
        {
            class_name: 'CmonHaProxyHost',
            cdt_path: '/cluster_4',
            acl: 'user::rwx,group::rwx,other::---',
            backend_name_ro: 'haproxy_c6_3308_ro',
            backend_name_rw: 'haproxy_c6_3307_rw_rw',
            clusterid: 4,
            cmdline: 'systemctl restart haproxy',
            configfile: '/etc/haproxy/haproxy.cfg',
            connectstring: 'c6:3307',
            container: true,
            hostId: 14,
            hostname: 'c6',
            hoststatus: 'CmonHostOnline',
            ip: '10.0.8.16',
            lastseen: 1698846811,
            lb_admin: 'admin',
            lb_name: 'haproxy_c6_3308_ro',
            lb_password: 'admin',
            lb_policy: 'leastconn',
            maintenance_mode: 'none',
            maintenance_mode_active: false,
            max_connections_be: 64,
            max_connections_fe: 8192,
            message: "Process 'haproxy' is running.",
            monitor_user: 's9smysqlchk',
            nodetype: 'haproxy',
            pid: 552,
            pingstatus: 1,
            pingtime: 127,
            port: 9600,
            ro_port: 3308,
            rw_port: 3307,
            socket: '/var/run/haproxy.socket',
            sshfailcount: 0,
            statereason: 'HAProxy stat socket is responding OK.',
            stats_socket: '/var/run/haproxy.socket',
            status: 10,
            svc_check_script: 'mysqlchk',
            timeout_client: 10800,
            timeout_server: 10800,
            timestamp: 1698846812,
            unique_id: 14,
            version: '2.0.31',
            wallclock: 1698846781,
            wallclocktimestamp: 1698846781,
            xinetd_port: 9200,
            backend_info: {
                backend_servers: [
                    {
                        name: 'admin_page',
                        last_check_state: '',
                        service: 'FRONTEND',
                        status: 'OPEN',
                    },
                    {
                        name: 'admin_page',
                        last_check_state: '',
                        service: 'BACKEND',
                        status: 'UP',
                    },
                    {
                        name: 'haproxy_c6_3307_rw_rw',
                        last_check_state: '',
                        service: 'FRONTEND',
                        status: 'OPEN',
                    },
                    {
                        name: 'haproxy_c6_3307_rw_rw',
                        last_check_state:
                            "TCPCHK did not match content 'master is running' at step 2",
                        service: 'c6',
                        status: 'DOWN',
                    },
                    {
                        name: 'haproxy_c6_3307_rw_rw',
                        last_check_state: '(tcp-check)',
                        service: 'c7',
                        status: 'UP',
                    },
                    {
                        name: 'haproxy_c6_3307_rw_rw',
                        last_check_state: '',
                        service: 'BACKEND',
                        status: 'UP',
                    },
                    {
                        name: 'haproxy_c6_3308_ro',
                        last_check_state: '',
                        service: 'FRONTEND',
                        status: 'OPEN',
                    },
                    {
                        name: 'haproxy_c6_3308_ro',
                        last_check_state: '',
                        service: 'c6',
                        status: 'DOWN',
                    },
                    {
                        name: 'haproxy_c6_3308_ro',
                        last_check_state: '(tcp-check)',
                        service: 'c7',
                        status: 'UP',
                    },
                    {
                        name: 'haproxy_c6_3308_ro',
                        last_check_state: '',
                        service: 'BACKEND',
                        status: 'UP',
                    },
                ],
            },
            bind_node_address: [],
            distribution: {
                name: 'ubuntu',
                codename: 'focal',
                release: '20.04',
                type: 'debian',
            },
            server_info: {
                nodes: [
                    {
                        hostname: 'c6',
                        port: 3306,
                        role: 'active',
                    },
                    {
                        hostname: 'c7',
                        port: 3306,
                        role: 'active',
                    },
                ],
            },
        },
        {
            class_name: 'CmonHaProxyHost',
            cdt_path: '/cluster_4',
            acl: 'user::rwx,group::rwx,other::---',
            backend_name_ro: 'haproxy_c7_3308_ro',
            backend_name_rw: 'haproxy_c7_3307_rw_rw',
            clusterid: 4,
            cmdline: 'systemctl restart haproxy',
            configfile: '/etc/haproxy/haproxy.cfg',
            connectstring: 'c7:3307',
            container: true,
            hostId: 15,
            hostname: 'c7',
            hoststatus: 'CmonHostOnline',
            ip: '10.0.8.17',
            lastseen: 1698846812,
            lb_admin: 'admin',
            lb_name: 'haproxy_c7_3308_ro',
            lb_password: 'admin',
            lb_policy: 'leastconn',
            maintenance_mode: 'none',
            maintenance_mode_active: false,
            max_connections_be: 64,
            max_connections_fe: 8192,
            message: "Process 'haproxy' is running.",
            monitor_user: 's9smysqlchk',
            nodetype: 'haproxy',
            pid: 45122,
            pingstatus: 1,
            pingtime: 137,
            port: 9600,
            ro_port: 3308,
            rw_port: 3307,
            socket: '/var/run/haproxy.socket',
            sshfailcount: 0,
            statereason: 'HAProxy::registerHaproxy()',
            stats_socket: '/var/run/haproxy.socket',
            status: 10,
            svc_check_script: 'mysqlchk',
            timeout_client: 10800,
            timeout_server: 10800,
            timestamp: 1698846813,
            unique_id: 15,
            version: '2.0.31',
            wallclock: 1698846781,
            wallclocktimestamp: 1698846781,
            xinetd_port: 9200,
            backend_info: {
                backend_servers: [
                    {
                        name: 'admin_page',
                        last_check_state: '',
                        service: 'FRONTEND',
                        status: 'OPEN',
                    },
                    {
                        name: 'admin_page',
                        last_check_state: '',
                        service: 'BACKEND',
                        status: 'UP',
                    },
                    {
                        name: 'haproxy_c7_3307_rw_rw',
                        last_check_state: '',
                        service: 'FRONTEND',
                        status: 'OPEN',
                    },
                    {
                        name: 'haproxy_c7_3307_rw_rw',
                        last_check_state: '',
                        service: 'c6',
                        status: 'DOWN',
                    },
                    {
                        name: 'haproxy_c7_3307_rw_rw',
                        last_check_state: '(tcp-check)',
                        service: 'c7',
                        status: 'DOWN 2/3',
                    },
                    {
                        name: 'haproxy_c7_3307_rw_rw',
                        last_check_state: '',
                        service: 'BACKEND',
                        status: 'DOWN',
                    },
                    {
                        name: 'haproxy_c7_3308_ro',
                        last_check_state: '',
                        service: 'FRONTEND',
                        status: 'OPEN',
                    },
                    {
                        name: 'haproxy_c7_3308_ro',
                        last_check_state:
                            "TCPCHK did not match content 'is running' at step 2",
                        service: 'c6',
                        status: 'DOWN',
                    },
                    {
                        name: 'haproxy_c7_3308_ro',
                        last_check_state: '(tcp-check)',
                        service: 'c7',
                        status: 'DOWN 2/3',
                    },
                    {
                        name: 'haproxy_c7_3308_ro',
                        last_check_state: '',
                        service: 'BACKEND',
                        status: 'DOWN',
                    },
                ],
            },
            bind_node_address: [],
            distribution: {
                name: 'ubuntu',
                codename: 'focal',
                release: '20.04',
                type: 'debian',
            },
            server_info: {
                nodes: [
                    {
                        hostname: 'c6',
                        port: 3306,
                        role: 'active',
                    },
                    {
                        hostname: 'c7',
                        port: 3306,
                        role: 'active',
                    },
                ],
            },
        },
        {
            class_name: 'CmonProxySqlHost',
            cdt_path: '/cluster_4',
            owner_user_id: 1,
            owner_user_name: 'system',
            owner_group_id: 1,
            owner_group_name: 'admins',
            acl: 'user::rwx,group::rwx,other::---',
            admin_user: 'proxysql-admin',
            backend_version: '8.0.33',
            ccx_svc: false,
            clusterid: 4,
            cmdline: 'systemctl start proxysql',
            configfile: '/etc/proxysql.cnf',
            connected: true,
            container: true,
            datadir: '/var/lib/proxysql/',
            hostId: 14,
            hostname: 'c6',
            hoststatus: 'CmonHostOnline',
            ip: '10.0.8.16',
            is_clustered: false,
            lastseen: 1698846817,
            lb_policy: 'rw-split',
            listening_port: 6033,
            logfile: '/var/lib/proxysql/proxysql.log',
            maintenance_mode: 'none',
            maintenance_mode_active: false,
            message: "Process 'proxysql' is running.",
            monitor_user: 'proxysql-monitor',
            nodetype: 'proxysql',
            pid: 478,
            pidfile: '/var/lib/proxysql/proxysql.pid',
            pingstatus: 1,
            pingtime: 127,
            port: 6032,
            sshfailcount: 0,
            statereason: "Process 'proxysql' is running.",
            status: 10,
            timestamp: 1698846817,
            unique_id: 16,
            uptime: 2860832,
            version: '2.5.4',
            wallclock: 1698846781,
            wallclocktimestamp: 1698846781,
            backend_info: {
                backend_servers: [
                    {
                        class_name: 'CmonProxySqlServer',
                        Bytes_data_recv: '0',
                        Bytes_data_sent: '0',
                        ConnERR: '0',
                        ConnFree: '0',
                        ConnUsed: '0',
                        Latency: '0',
                        Queries: '0',
                        comment: 'read and write server',
                        compression: '0',
                        hostgroup_id: '10',
                        hostgroup_name: 'Writer and reader',
                        hostname: 'c6',
                        max_connections: '100',
                        max_latency_ms: '0',
                        max_replication_lag: '10',
                        port: '3306',
                        status: 'OFFLINE_SOFT',
                        use_ssl: '0',
                        weight: '1',
                    },
                    {
                        class_name: 'CmonProxySqlServer',
                        Bytes_data_recv: '0',
                        Bytes_data_sent: '0',
                        ConnERR: '0',
                        ConnFree: '0',
                        ConnUsed: '0',
                        Latency: '274',
                        Queries: '0',
                        comment: 'read server',
                        compression: '0',
                        hostgroup_id: '10',
                        hostgroup_name: 'Writer and reader',
                        hostname: 'c7',
                        max_connections: '100',
                        max_latency_ms: '0',
                        max_replication_lag: '10',
                        port: '3306',
                        status: 'ONLINE',
                        use_ssl: '0',
                        weight: '1',
                    },
                    {
                        class_name: 'CmonProxySqlServer',
                        Bytes_data_recv: '0',
                        Bytes_data_sent: '0',
                        ConnERR: '0',
                        ConnFree: '0',
                        ConnUsed: '0',
                        Latency: '0',
                        Queries: '0',
                        comment: 'read server',
                        compression: '0',
                        hostgroup_id: '20',
                        hostgroup_name: 'Reader',
                        hostname: 'c6',
                        max_connections: '100',
                        max_latency_ms: '0',
                        max_replication_lag: '10',
                        port: '3306',
                        status: 'OFFLINE_SOFT',
                        use_ssl: '0',
                        weight: '1',
                    },
                    {
                        class_name: 'CmonProxySqlServer',
                        Bytes_data_recv: '0',
                        Bytes_data_sent: '0',
                        ConnERR: '0',
                        ConnFree: '0',
                        ConnUsed: '0',
                        Latency: '274',
                        Queries: '0',
                        comment: 'read server',
                        compression: '0',
                        hostgroup_id: '20',
                        hostgroup_name: 'Reader',
                        hostname: 'c7',
                        max_connections: '100',
                        max_latency_ms: '0',
                        max_replication_lag: '10',
                        port: '3306',
                        status: 'ONLINE',
                        use_ssl: '0',
                        weight: '1',
                    },
                ],
            },
            distribution: {
                name: 'ubuntu',
                codename: 'focal',
                release: '20.04',
                type: 'debian',
            },
        },
        {
            class_name: 'CmonProxySqlHost',
            cdt_path: '/cluster_4',
            owner_user_id: 1,
            owner_user_name: 'system',
            owner_group_id: 1,
            owner_group_name: 'admins',
            acl: 'user::rwx,group::rwx,other::---',
            admin_user: 'proxysql-admin',
            backend_version: '8.0.33',
            ccx_svc: false,
            clusterid: 4,
            cmdline: 'systemctl start proxysql',
            configfile: '/etc/proxysql.cnf',
            connected: true,
            container: true,
            datadir: '/var/lib/proxysql/',
            hostId: 15,
            hostname: 'c7',
            hoststatus: 'CmonHostOnline',
            ip: '10.0.8.17',
            is_clustered: false,
            lastseen: 1698846817,
            lb_policy: 'rw-split',
            listening_port: 6033,
            logfile: '/var/lib/proxysql/proxysql.log',
            maintenance_mode: 'none',
            maintenance_mode_active: false,
            message: "Process 'proxysql' is running.",
            monitor_user: 'proxysql-monitor',
            nodetype: 'proxysql',
            pid: 125669,
            pidfile: '/var/lib/proxysql/proxysql.pid',
            pingstatus: 1,
            pingtime: 137,
            port: 6032,
            sshfailcount: 0,
            status: 10,
            timestamp: 1698846817,
            unique_id: 17,
            uptime: 7065042,
            version: '2.5.4',
            wallclock: 1698846781,
            wallclocktimestamp: 1698846781,
            backend_info: {
                backend_servers: [
                    {
                        class_name: 'CmonProxySqlServer',
                        Bytes_data_recv: '0',
                        Bytes_data_sent: '0',
                        ConnERR: '0',
                        ConnFree: '0',
                        ConnUsed: '0',
                        Latency: '77',
                        Queries: '0',
                        comment: 'read and write server',
                        compression: '0',
                        hostgroup_id: '10',
                        hostgroup_name: 'Writer and reader',
                        hostname: 'c6',
                        max_connections: '100',
                        max_latency_ms: '0',
                        max_replication_lag: '10',
                        port: '3306',
                        status: 'OFFLINE_SOFT',
                        use_ssl: '0',
                        weight: '1',
                    },
                    {
                        class_name: 'CmonProxySqlServer',
                        Bytes_data_recv: '0',
                        Bytes_data_sent: '0',
                        ConnERR: '0',
                        ConnFree: '0',
                        ConnUsed: '0',
                        Latency: '229',
                        Queries: '0',
                        comment: 'read server',
                        compression: '0',
                        hostgroup_id: '10',
                        hostgroup_name: 'Writer and reader',
                        hostname: 'c7',
                        max_connections: '100',
                        max_latency_ms: '0',
                        max_replication_lag: '10',
                        port: '3306',
                        status: 'ONLINE',
                        use_ssl: '0',
                        weight: '1',
                    },
                    {
                        class_name: 'CmonProxySqlServer',
                        Bytes_data_recv: '0',
                        Bytes_data_sent: '0',
                        ConnERR: '0',
                        ConnFree: '0',
                        ConnUsed: '0',
                        Latency: '77',
                        Queries: '0',
                        comment: 'read server',
                        compression: '0',
                        hostgroup_id: '20',
                        hostgroup_name: 'Reader',
                        hostname: 'c6',
                        max_connections: '100',
                        max_latency_ms: '0',
                        max_replication_lag: '10',
                        port: '3306',
                        status: 'OFFLINE_SOFT',
                        use_ssl: '0',
                        weight: '1',
                    },
                    {
                        class_name: 'CmonProxySqlServer',
                        Bytes_data_recv: '0',
                        Bytes_data_sent: '0',
                        ConnERR: '0',
                        ConnFree: '0',
                        ConnUsed: '0',
                        Latency: '229',
                        Queries: '0',
                        comment: 'read server',
                        compression: '0',
                        hostgroup_id: '20',
                        hostgroup_name: 'Reader',
                        hostname: 'c7',
                        max_connections: '100',
                        max_latency_ms: '0',
                        max_replication_lag: '10',
                        port: '3306',
                        status: 'ONLINE',
                        use_ssl: '0',
                        weight: '1',
                    },
                ],
            },
            distribution: {
                name: 'ubuntu',
                codename: 'focal',
                release: '20.04',
                type: 'debian',
            },
        },
        {
            class_name: 'CmonKeepalivedHost',
            name: 'VI_HAPROXY',
            cdt_path: '/cluster_4',
            owner_user_id: 1,
            owner_user_name: 'system',
            owner_group_id: 1,
            owner_group_name: 'admins',
            acl: 'user::rwx,group::rwx,other::---',
            clusterid: 4,
            cmdline: '/usr/sbin/keepalived',
            configfile: '/etc/keepalived/keepalived.conf',
            container: true,
            hostId: 14,
            hostname: 'c6',
            hoststatus: 'CmonHostOnline',
            ip: '10.0.8.16',
            lastseen: 1698846811,
            maintenance_mode: 'none',
            maintenance_mode_active: false,
            message: "Process 'keepalived' is running.",
            nic: 'eth0',
            nodetype: 'keepalived',
            pid: 3465538,
            pingstatus: 1,
            pingtime: 127,
            port: 112,
            role: 'master',
            sshfailcount: 0,
            status: 10,
            timestamp: 1698846811,
            unique_id: 22,
            version: '2.0',
            virtualip: '10.10.10.10',
            wallclock: 1698846781,
            wallclocktimestamp: 1698846781,
            backend_info: {
                backend_servers: [
                    {
                        hostname: 'c6',
                        hoststatus: 'CmonHostOnline',
                        nodetype: 'haproxy',
                        port: 9600,
                    },
                    {
                        hostname: 'c7',
                        hoststatus: 'CmonHostOnline',
                        nodetype: 'haproxy',
                        port: 9600,
                    },
                ],
            },
            distribution: {
                name: 'ubuntu',
                codename: 'focal',
                release: '20.04',
                type: 'debian',
            },
        },
        {
            class_name: 'CmonKeepalivedHost',
            name: 'VI_HAPROXY',
            cdt_path: '/cluster_4',
            owner_user_id: 1,
            owner_user_name: 'system',
            owner_group_id: 1,
            owner_group_name: 'admins',
            acl: 'user::rwx,group::rwx,other::---',
            clusterid: 4,
            cmdline: '/usr/sbin/keepalived',
            configfile: '/etc/keepalived/keepalived.conf',
            container: true,
            hostId: 15,
            hostname: 'c7',
            hoststatus: 'CmonHostOnline',
            ip: '10.0.8.17',
            lastseen: 1698846812,
            maintenance_mode: 'none',
            maintenance_mode_active: false,
            message: "Process 'keepalived' is running.",
            nic: 'eth0',
            nodetype: 'keepalived',
            pid: 3116184,
            pingstatus: 1,
            pingtime: 137,
            port: 112,
            role: 'backup',
            sshfailcount: 0,
            status: 10,
            timestamp: 1698846812,
            unique_id: 23,
            version: '2.0',
            virtualip: '10.10.10.10',
            wallclock: 1698846781,
            wallclocktimestamp: 1698846781,
            backend_info: {
                backend_servers: [
                    {
                        hostname: 'c6',
                        hoststatus: 'CmonHostOnline',
                        nodetype: 'haproxy',
                        port: 9600,
                    },
                    {
                        hostname: 'c7',
                        hoststatus: 'CmonHostOnline',
                        nodetype: 'haproxy',
                        port: 9600,
                    },
                ],
            },
            distribution: {
                name: 'ubuntu',
                codename: 'focal',
                release: '20.04',
                type: 'debian',
            },
        },
    ],
    job_statistics: {
        class_name: 'CmonJobStatistics',
        cluster_id: 4,
        by_state: {
            ABORTED: 0,
            DEFINED: 0,
            DEQUEUED: 0,
            FAILED: 0,
            FINISHED: 2,
            PAUSED: 4,
            RUNNING: 0,
        },
    },
    owner: {
        class_name: 'CmonUser',
        cdt_path: '/',
        owner_user_id: 5,
        owner_user_name: 'pablo',
        owner_group_id: 1,
        owner_group_name: 'admins',
        acl: 'user::rwx,group::r--,other::r--',
        created: '2022-03-24T13:24:02.244Z',
        disabled: false,
        email_address: 'pablo@localhost.xyz',
        first_name: 'pablo',
        last_failed_login: '',
        last_login: '2023-11-01T12:04:04.105Z',
        last_name: '',
        n_failed_logins: 0,
        origin: 'CmonDb',
        suspended: false,

        user_id: 5,
        user_name: 'pablo',
        groups: [
            {
                class_name: 'CmonGroup',
                cdt_path: '/groups',
                owner_user_id: 1,
                owner_user_name: 'system',
                owner_group_id: 1,
                owner_group_name: 'admins',
                acl: 'user::rwx,group::rwx,other::---',
                created: '2022-02-02T13:11:15.734Z',
                group_id: 1,
                group_name: 'admins',
            },
        ],
        timezone: {
            class_name: 'CmonTimeZone',
            name: 'Cuba',
            abbreviation: 'CDT',
            offset: -240,
            use_dst: false,
        },
    },
};

export default mock;
