import React from 'react';
import { Col, Form, Input, Row } from 'antd';
import PasswordInput from '../../common/DataEntry/PasswordInput';
import CcCluster from '../../services/models/CcCluster';
import { FormInstance } from 'antd/lib/form';
import DatabaseUserCommonFields from './FormParts/DatabaseUserCommonFields';
import DatabaseUserAdvancedFields from './FormParts/DatabaseUserAdvancedFields';
import DatabasePrivilegesForm from './FormParts/DatabasePrivilegesForm';

export default DatabaseMySQLUserForm;
export type DatabaseMySQLUserFormProps = {
    cluster: CcCluster;
    form: FormInstance;
    edit?: boolean;
};

function DatabaseMySQLUserForm({
    cluster,
    form,
    edit = false,
    ...rest
}: DatabaseMySQLUserFormProps) {
    return (
        <Row gutter={[24, 0]}>
            <Col span={12}>
                <Row gutter={[24, 0]}>
                    <Col span={24}>
                        <h3>Configuration</h3>
                    </Col>
                </Row>
                <Row gutter={[24, 0]}>
                    {edit ? (
                        <Col span={24}>
                            <Form.Item name="newPassword" label="New password">
                                <PasswordInput placeholder="Enter password" />
                            </Form.Item>
                            <Form.Item name="username" hidden={true}>
                                <Input type="hidden" />
                            </Form.Item>
                            <Form.Item name="password" hidden={true}>
                                <Input type="hidden" />
                            </Form.Item>
                            <Form.Item name="hostname" hidden={true}>
                                <Input type="hidden" />
                            </Form.Item>
                        </Col>
                    ) : (
                        <DatabaseUserCommonFields cluster={cluster} />
                    )}
                </Row>
                <DatabaseUserAdvancedFields edit={edit} />
            </Col>
            <Col span={12}>
                <Row gutter={[24, 0]}>
                    <Col span={24}>
                        <h3>Privileges</h3>
                    </Col>
                </Row>
                <Row gutter={[24, 0]}>
                    <Col span={24}>
                        <DatabasePrivilegesForm
                            form={form}
                            technology={cluster.getTechnology()}
                        />
                    </Col>
                </Row>
            </Col>
        </Row>
    );
}
