const mock = {
    class_name: 'CmonClusterInfo',
    cdt_path: '/',
    acl: 'user::rwx,group::rwx,other::---',
    tags: [],
    cluster_auto_recovery: true,
    cluster_id: 14,
    cluster_name: 'Vagrant Elastic HA Cluster',
    cluster_type: 'ELASTIC',
    configuration_file: '/etc/cmon.d/cmon_1.cnf',
    effective_privileges: 'rwx',
    log_file: '/var/log/cmon_1.log',
    maintenance_mode_active: false,
    managed: true,
    node_auto_recovery: true,
    rpc_version: '2.0',
    state: 'FAILURE',
    status_text: 'Cluster failure.',
    vendor: 'elasticsearch',
    version: '',
    alarm_statistics: {
        class_name: 'CmonAlarmStatistics',
        cluster_id: 1,
        created_after: null,
        critical: 0,
        reported_after: null,
        warning: 0,
    },
    group_owner: {
        class_name: 'CmonGroup',
        cdt_path: '/groups',
        owner_user_id: 1,
        owner_user_name: 'system',
        owner_group_id: 1,
        owner_group_name: 'admins',
        acl: 'user::rwx,group::rwx,other::---',
        created: '2022-03-02T06:28:36.559Z',
        group_id: 1,
        group_name: 'admins',
    },
    hosts: [
        {
            class_name: 'CmonElasticHost',
        },
        {
            class_name: 'CmonElasticHost',
        },
        {
            class_name: 'CmonElasticHost',
        },
        {
            class_name: 'CmonHost',
            cdt_path: '/Vagrant Elastic HA Cluster',
            owner_user_id: 5,
            owner_user_name: 'alex',
            owner_group_id: 1,
            owner_group_name: 'admins',
            acl: 'user::rwx,group::rwx,other::---',
            clusterid: 1,
            configfile: '/etc/cmon.d/cmon_1.cnf',
            connected: true,
            container: false,
            hostId: 5,
            hostname: '178.128.85.233',
            hoststatus: 'CmonHostOnline',
            ip: '178.128.85.233',
            lastseen: 1648111706,
            logfile: '/var/log/cmon_1.log',
            maintenance_mode: 'none',
            maintenance_mode_active: false,
            message: 'Up and running.',
            nodetype: 'controller',
            pid: 15697,
            pingstatus: -1,
            pingtime: -1,
            port: 9500,
            role: 'controller',
            status: 10,
            timestamp: 1648111706,
            unique_id: 5,
            uptime: 105206,
            version: '1.9.3.5217',
            wallclock: 1648111676,
            wallclocktimestamp: 1648111676,
            distribution: {
                name: 'ubuntu',
                codename: 'bionic',
                release: '18.04',
                type: 'debian',
            },
        },
        {
            class_name: 'CmonPrometheusHost',
            cdt_path: '/Vagrant Elastic HA Cluster',
            owner_user_id: 5,
            owner_user_name: 'alex',
            owner_group_id: 1,
            owner_group_name: 'admins',
            acl: 'user::rwx,group::rwx,other::---',
            clusterid: 1,
            configfile: '/etc/prometheus/prometheus.yml',
            container: false,
            data_retention: '15d',
            data_retention_size: '',
            datadir: '/var/lib/prometheus',
            hostId: 5,
            hostname: '178.128.85.233',
            hoststatus: 'CmonHostOnline',
            ip: '178.128.85.233',
            lastseen: 1648111706,
            logfile: '/var/log/prometheus/prometheus.log',
            maintenance_mode: 'none',
            maintenance_mode_active: false,
            message: "Process 'prometheus' is running.",
            nodetype: 'prometheus',
            pid: 1600975,
            pingstatus: -1,
            pingtime: -1,
            port: 9090,
            scrape_interval: '30s',
            statereason: 'CmonProcessManager::prometheusFound()',
            status: 10,
            timestamp: 1648111706,
            unique_id: 6,
            version: '2.29.2',
            wallclock: 1648111676,
            wallclocktimestamp: 1648111676,
            configuration: [
                {
                    arguments: '',
                    job: 'prometheus',
                    scrape_interval: '2s',
                },
                {
                    arguments: '',
                    job: 'node',
                    scrape_interval: '30s',
                },
                {
                    arguments: '',
                    job: 'process',
                    scrape_interval: '30s',
                },
            ],
            distribution: {
                name: 'ubuntu',
                codename: 'bionic',
                release: '18.04',
                type: 'debian',
            },
            exporters: [
                {
                    address: '10.0.0.11:9011',
                    job: 'process',
                    monitored_host: '',
                    up: true,
                    version: '0.10.10',
                },
                {
                    address: '10.0.0.11:9100',
                    job: 'node',
                    monitored_host: '',
                    up: true,
                    version: '1.0.1',
                },
                {
                    address: '10.0.0.12:9011',
                    job: 'process',
                    monitored_host: '',
                    up: true,
                    version: '0.10.10',
                },
                {
                    address: '10.0.0.12:9100',
                    job: 'node',
                    monitored_host: '',
                    up: true,
                    version: '1.0.1',
                },
                {
                    address: '10.0.0.13:9011',
                    job: 'process',
                    monitored_host: '',
                    up: true,
                    version: '0.10.10',
                },
                {
                    address: '10.0.0.13:9100',
                    job: 'node',
                    monitored_host: '',
                    up: true,
                    version: '1.0.1',
                },
                {
                    address: '178.128.85.233:9011',
                    job: 'process',
                    monitored_host: '',
                    up: false,
                    version: '0.10.10',
                },
                {
                    address: '178.128.85.233:9100',
                    job: 'node',
                    monitored_host: '',
                    up: false,
                    version: '1.0.1',
                },
            ],
        },
    ],
    job_statistics: {
        class_name: 'CmonJobStatistics',
        cluster_id: 1,
        by_state: {
            ABORTED: 0,
            DEFINED: 0,
            DEQUEUED: 0,
            FAILED: 0,
            FINISHED: 1,
            RUNNING: 0,
        },
    },
    owner: {
        class_name: 'CmonUser',
        cdt_path: '/',
        owner_user_id: 5,
        owner_user_name: 'alex',
        owner_group_id: 1,
        owner_group_name: 'admins',
        acl: 'user::rwx,group::r--,other::r--',
        created: '2022-03-02T06:30:49.781Z',
        disabled: false,
        email_address: 'qaadmin@localhost',
        first_name: '',
        last_failed_login: '',
        last_login: '2022-03-24T07:29:15.591Z',
        last_name: '',
        n_failed_logins: 0,
        origin: 'CmonDb',
        suspended: false,
        user_id: 5,
        user_name: 'alex',
        groups: [
            {
                class_name: 'CmonGroup',
                cdt_path: '/groups',
                owner_user_id: 1,
                owner_user_name: 'system',
                owner_group_id: 1,
                owner_group_name: 'admins',
                acl: 'user::rwx,group::rwx,other::---',
                created: '2022-03-02T06:28:36.559Z',
                group_id: 1,
                group_name: 'admins',
            },
        ],
        timezone: {
            class_name: 'CmonTimeZone',
            name: 'Coordinated Universal Time',
            abbreviation: 'UTC',
            offset: 0,
            use_dst: false,
        },
    },
};
export default mock;
