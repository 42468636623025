import React from 'react';
import { Col, Row } from 'antd';
import DatabaseUserCommonFields from './FormParts/DatabaseUserCommonFields';
import DatabaseUserAdvancedFields from './FormParts/DatabaseUserAdvancedFields';
import CcCluster from '../../services/models/CcCluster';
import { FormInstance } from 'antd/lib/form';
import DatabasePrivilegesForm from './FormParts/DatabasePrivilegesForm';

export default DatabasePostgresUserForm;
export type DatabasePostgresUserFormProps = {
    cluster: CcCluster;
    form: FormInstance;
    edit?: boolean;
};

function DatabasePostgresUserForm({
    cluster,
    form,
    edit = false,
    ...rest
}: DatabasePostgresUserFormProps) {
    return (
        <Row gutter={[24, 0]}>
            {edit ? undefined : (
                <Col span={12}>
                    <Row gutter={[24, 0]}>
                        <Col span={24}>
                            <h3>Configuration</h3>
                        </Col>
                    </Row>
                    <Row gutter={[24, 0]}>
                        <DatabaseUserCommonFields cluster={cluster} />
                    </Row>
                    <DatabaseUserAdvancedFields edit={edit} />
                </Col>
            )}

            <Col span={edit ? 24 : 12}>
                <Row gutter={[24, 0]}>
                    <Col span={24}>
                        <h3>Privileges</h3>
                    </Col>
                </Row>
                <Row gutter={[24, 0]}>
                    <Col span={24}>
                        <DatabasePrivilegesForm
                            form={form}
                            technology={cluster.getTechnology()}
                        />
                    </Col>
                </Row>
            </Col>
        </Row>
    );
}
