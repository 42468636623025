import React from 'react';
import { Alert, Form, Space } from 'antd';
import { FormInstance } from 'antd/lib/form';

export default MysqlGaleraNodeInfo;

export type MysqlGaleraNodeInfoProps = { form: FormInstance };

function MysqlGaleraNodeInfo({ form }: MysqlGaleraNodeInfoProps) {
    return (
        <Form.Item shouldUpdate noStyle className="MysqlGaleraNodeInfo">
            {() => (
                <Alert
                    message={
                        <Space direction="vertical">
                            <span>Note:</span>
                            <ul>
                                {form.getFieldValue([
                                    'nodeConfig',
                                    'nodeAutoDetect',
                                ]) ? (
                                    <li>
                                        ClusterControl will look up and address
                                        the nodes based on the hostname/IPs used
                                        for Galera's intra-node communication.
                                    </li>
                                ) : (
                                    <li>
                                        ClusterControl will look up and address
                                        the specified hostname/IP for each node.
                                        The hostname/IP address must be
                                        reachable from ClusterControl.
                                    </li>
                                )}

                                <li>
                                    Replication slaves, load balancers, and
                                    other supported services connected to the
                                    Galera Cluster can be added after the import
                                    has finished.
                                </li>
                            </ul>
                            <span>
                                For more information please refer to the{' '}
                                <a
                                    target="_blank"
                                    rel="noreferrer"
                                    href="https://docs.severalnines.com/docs/clustercontrol/user-guide-gui/dashboard/deploy-a-database-cluster/galera-cluster/"
                                >
                                    User Guide
                                </a>
                            </span>
                        </Space>
                    }
                    type="info"
                    showIcon={true}
                />
            )}
        </Form.Item>
    );
}
