import React from 'react';
import TopologySummary, { TopologySummaryProps } from '../TopologySummary';

export default MongoShardsConfigurationServerTopologySummary;

export type MongoShardsConfigurationServerTopologySummaryProps = Omit<
    TopologySummaryProps,
    'configurator'
> & {};

function MongoShardsConfigurationServerTopologySummary({
    ...props
}: MongoShardsConfigurationServerTopologySummaryProps) {
    return (
        <div className="MongoShardsConfigurationServerTopologySummary">
            <TopologySummary
                title="Configuration servers"
                singleNode={true}
                topologyFieldPath={['configurationServersTopology']}
                {...props}
            />
        </div>
    );
}
