import React from 'react';
import { ButtonProps } from 'antd/lib/button';
import useCreateJob from '../Jobs/useCreateJob';
import { CmonJobInstanceCommand } from '../../services/cmon/models/CmonJobInstance';
import CcCluster from '../../services/models/CcCluster';
import JobButton from '../Jobs/JobButton';

export default RefreshLogButton;
export type RefreshLogButtonProps = ButtonProps & {
    cluster: CcCluster;
    onFinish?: () => void;
};

function RefreshLogButton({
    cluster,
    onFinish,
    ...rest
}: RefreshLogButtonProps) {
    const { send, loading } = useCreateJob({
        clusterId: cluster.clusterId,
        title: 'Collect system logs',
        command: CmonJobInstanceCommand.COLLECT_LOGS,
    });
    const handleRefresh: ButtonProps['onClick'] = async () => {
        await send({ clusterId: cluster.clusterId });
    };
    return (
        <JobButton
            type="primary"
            size="middle"
            command={CmonJobInstanceCommand.COLLECT_LOGS}
            clusterId={cluster.clusterId}
            clusterType={cluster.clusterType}
            onClick={handleRefresh}
            onFinish={onFinish}
            loading={loading}
            {...rest}
        >
            Refresh logs
        </JobButton>
    );
}
