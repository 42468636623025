import React, { useState, useRef } from 'react';
import ModalDefault from '../../common/ModalDefault';
import { notifyConfirmCloseWindow } from '../Notifications/uiNotification';
import MotionShake, { MotionApi } from '../../common/motion/MotionShake';

import { CcClusterType } from '../../services/models/CcCluster';
import { ServiceWizardProps } from './ServiceWizard';

import { getClusterTypeText } from '../Clusters/ClusterTypeFormat';
import ServiceWizard, {
    ServiceWizardActionType,
    ServiceWizardStep,
    getServiceWizardActionTypeText,
} from './ServiceWizard';

import CcCluster from '../../services/models/CcCluster';

export default ServiceWizardModal;

export type ServiceWizardModalProps = {
    wizardProps?: ServiceWizardProps;
    cluster?: CcCluster;
    onCancel?: () => void;
};

function ServiceWizardModal({
    wizardProps,
    onCancel,
}: ServiceWizardModalProps) {
    const [confirmClose, setConfirmClose] = useState(false);
    const [title, setTitle] = useState<string | undefined>();
    const [serviceWizardAction, setServiceWizardAction] = useState<
        ServiceWizardActionType | undefined
    >();
    const animationRef = useRef<MotionApi | undefined>();

    const handleActionTypeChange = (action: ServiceWizardActionType) => {
        setServiceWizardAction(action);
        setTitle(`${getServiceWizardActionTypeText(action)} cluster`);
    };

    const handleClusterTypeChange = (clusterType: CcClusterType) => {
        setTitle(
            `${getServiceWizardActionTypeText(
                serviceWizardAction
            )} ${getClusterTypeText(clusterType)} cluster`
        );
    };

    const stepChangeHandler = (step: ServiceWizardStep) => {
        if (step === ServiceWizardStep.ACTION) {
            setTitle('');
        }
    };
    const handleFormTouchedChange = (touched: boolean) => {
        setConfirmClose(touched);
    };
    const handleFormStepErrorInsist = (err: Error) => {
        if (animationRef.current) {
            animationRef.current.animate();
        }
    };

    const handleCancel = () => {
        if (confirmClose) {
            notifyConfirmCloseWindow({
                onOk: () => {
                    onCancel?.();
                },
            });
        } else {
            onCancel?.();
        }
    };

    return (
        <ModalDefault
            title={title}
            animateHeight={true}
            visible={true}
            onCancel={handleCancel}
            modalRender={(content) => (
                <MotionShake ref={animationRef}>{content}</MotionShake>
            )}
        >
            <ServiceWizard
                onChange={stepChangeHandler}
                onActionTypeChange={handleActionTypeChange}
                onClusterTypeChange={handleClusterTypeChange}
                onSuccess={onCancel}
                onFormTouchedChange={handleFormTouchedChange}
                onFormStepErrorInsist={handleFormStepErrorInsist}
                {...wizardProps}
            />
        </ModalDefault>
    );
}
