import React from 'react';
import ButtonWithForm from '../../../../common/General/ButtonWithForm';
import ActionButton from '@severalnines/bar-frontend-components/build/lib/General/ActionButton';
import CcCluster from '../../../../services/models/CcCluster';
import ClusterEditDetailsModal from './ClusterEditDetailsModal';

export default ClusterEditDetailsButton;

export type ClusterEditDetailsButtonProps = {
    cluster: CcCluster;
    onSuccess?: () => void;
};

function ClusterEditDetailsButton({
    cluster,
    onSuccess,
    ...rest
}: ClusterEditDetailsButtonProps) {
    return (
        <ButtonWithForm
            button={<ActionButton>Edit details</ActionButton>}
            form={
                <ClusterEditDetailsModal
                    cluster={cluster}
                    onSuccess={onSuccess}
                />
            }
        />
    );
}
