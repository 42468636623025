import React, { useEffect } from 'react';
import PermissionsInfo from './PermissionsInfo';
import CcGroup from '../../services/models/CcGroup';
import useGroupTree from './useGroupTree';
import AppSpin from '../../common/General/AppSpin';

export default GroupPermissionsInfo;

export type GroupPermissionsInfoProps = {
    group: CcGroup;
};

function GroupPermissionsInfo({ group }: GroupPermissionsInfoProps) {
    const { getAcl, refresh, loading } = useGroupTree({
        name: 'tree-from-user-create-form',
        groupName: group.groupName,
    });

    useEffect(() => {
        (async () => await refresh())();
    }, []);

    return (
        <AppSpin spinning={loading}>
            <PermissionsInfo
                permissions={getAcl()}
                canManageUsers={group.isAdminsGroup()}
            />
        </AppSpin>
    );
}
