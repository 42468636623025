import React from 'react';
import { Divider } from 'antd';
import StatisticBadge from '../common/StatisticBadge';

export default DebugStatisticBadge;

export type DebugStatisticBadgeProps = {};

function DebugStatisticBadge() {
    const samples: any[] = [
        {},
        { count: 1 },
        { count: 1, status: 'error' },
        { count: 1, status: 'warning' },
        { count: 1, status: 'success' },
        { count: 1, status: 'info' },
        { count: 1, dot: false },
        { count: 1, status: 'error', dot: false },
        { count: 1, status: 'warning', dot: false },
        { count: 1, status: 'success', dot: false },
        { count: 1, status: 'info', dot: false },
    ];
    return (
        <div className="DebugStatisticBadge">
            <h1>StatisticBadge</h1>
            {samples.map((sample, index) => (
                <div key={index}>
                    <Divider
                        plain={true}
                        orientation="left"
                        style={{ marginBottom: '0px', marginTop: '15px' }}
                    >
                        {JSON.stringify(sample)}
                    </Divider>
                    <StatisticBadge {...sample}>
                        <span
                            style={{
                                paddingTop: '5px',
                                paddingBottom: '10px',
                                display: 'inline-block',
                            }}
                        >
                            something
                        </span>
                    </StatisticBadge>
                </div>
            ))}
        </div>
    );
}
