import { StatusFormatStatus } from '@severalnines/bar-frontend-components/build/lib/Format/StatusFormat';

export interface TopologyItemProps {
    key: string;
    index?: number;
    type?: string;
    title?: React.ReactNode;
    fromKey?: string;
    status?: StatusFormatStatus;
    checkHost?: boolean;
    loading?: boolean;
    message?: string;
    description?: string;
    extraData?: any;
    footerExpandable?: boolean;
    footerExpanded?: boolean;
    footer?: React.ReactNode;
}

export class TopologyItem {
    public key: string;
    public index?: number;
    public type?: string;
    public title?: React.ReactNode;
    public fromKey?: string;
    public status?: StatusFormatStatus;
    public checkHost?: boolean;
    public loading?: boolean;
    public message?: string;
    public description?: string;
    public extraData?: any;
    public footerExpandable?: boolean;
    public footerExpanded?: boolean;
    public footer?: React.ReactNode;

    constructor(props: TopologyItemProps) {
        this.key = props.key;
        this.index = props.index;
        this.type = props.type;
        this.title = props.title;
        this.fromKey = props.fromKey;
        this.status = props.status;
        this.checkHost = props.checkHost;
        this.loading = props.loading;
        this.message = props.message;
        this.description = props.description;
        this.extraData = props.extraData;
        this.footerExpandable = props.footerExpandable;
        this.footerExpanded = props.footerExpanded;
        this.footer = props.footer;
    }
}
