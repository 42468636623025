import React, {
    forwardRef,
    useCallback,
    useContext,
    useEffect,
    useImperativeHandle,
} from 'react';
import { TablePaginationConfig } from 'antd/es';
import { ResponsiveContext } from '@severalnines/bar-frontend-components/build/lib/Layout/Responsive';
import CcGroup from '../../services/models/CcGroup';
import AppDateFormat from '../../common/AppDateFormat';
import useGroupList from './useGroupList';
import GroupActionMenu from './GroupActionMenu';
import { getSortAlphabeticFn, getSortDateFn } from '../../common/sorting';
import useTree from './useTree';
import AppTable from '../../common/DataDisplay/AppTable';
import useUsersList from './useUsersList';
import GroupDetailsButton from './Actions/GroupDetailsButton';

export default forwardRef(GroupList);

export interface GroupListApi {
    refresh: () => void;
}

export type GroupListProps = {};

function GroupList({}: GroupListProps, ref: any) {
    const { responsive } = useContext(ResponsiveContext);

    const { refresh: refreshTree } = useTree({
        name: 'tree-from-group-list',
    });
    const { refresh: refreshUsers } = useUsersList({
        name: 'users-from-group-list',
    });

    const {
        list: groups,
        loading: loadingGroups,
        refresh: refreshGroups,
        filter: filterGroups,
        page,
        pageSize,
        total,
    } = useGroupList({ useCache: false });

    useImperativeHandle(
        ref,
        (): GroupListApi => ({
            async refresh() {
                await refreshGroups();
            },
        })
    );

    useEffect(() => {
        (async () => {
            await refreshGroups();
        })();
    }, []);

    const handleActionPerformed = async () => {
        refreshTree();
        refreshUsers();
        refreshGroups();
    };

    const columns = [
        {
            title: 'Name',
            key: 'group_name',
            render: (record: CcGroup) => (
                <GroupDetailsButton
                    group={record}
                    onEdit={handleActionPerformed}
                >
                    {record.groupName}
                </GroupDetailsButton>
            ),
            sorter: true,
        },
        {
            title: 'Owner',
            key: 'owner',
            render: (record: CcGroup) => <>{record.ownerUserName}</>,
            sorter: true,
        },
        {
            title: 'Created',
            key: 'created',
            render: (record: CcGroup) => (
                <span style={{ whiteSpace: 'nowrap' }}>
                    <AppDateFormat fromNow>
                        {record.created ? new Date(record.created) : undefined}
                    </AppDateFormat>
                </span>
            ),
            sorter: true,
        },

        {
            key: 'actions',
            title: 'Actions',
            align: 'center',

            render: (record: CcGroup) => (
                <GroupActionMenu
                    group={record}
                    onActionPerformed={handleActionPerformed}
                />
            ),

            width: 20,
            onCell: () => ({ style: { padding: '2px 10px' } }),
        },
    ];
    const handleTableChange = (pagination: any, filter: any, sorters: any) => {
        let sorterFn;
        switch (sorters.columnKey) {
            case 'group_name':
                sorterFn = getSortAlphabeticFn(
                    sorters.order,
                    (x) => x.groupName
                );
                break;
            case 'owner':
                sorterFn = getSortAlphabeticFn(
                    sorters.order,
                    (x) => x.ownerUserName
                );
                break;
            case 'created':
                sorterFn = getSortDateFn(
                    sorters.order,
                    (x) => new Date(x.created)
                );
                break;
        }

        filterGroups({
            page: pagination.current,
            order: sorterFn || undefined,
        });
    };
    const pag: TablePaginationConfig = {
        pageSize,
        current: page,
        total,
    };

    const rowKey = useCallback((record: CcGroup) => record.getKey(), []);
    return (
        <div className="GroupList">
            <AppTable
                className="GroupsTable"
                loading={loadingGroups}
                rowKey={rowKey}
                dataSource={groups}
                columns={columns}
                pagination={pag}
                size="middle"
                responsive={responsive}
                onChange={handleTableChange}
            />
        </div>
    );
}
