import React from 'react';
import { FormInstance } from 'antd/lib/form';
import { ExporterType } from './PrometheusEnableWizard';
import PrometheusExportersData from './PrometheusExportersData';

export default PrometheusEnableSummary;

export type PrometheusEnableSummaryProps = {
    form: FormInstance;
    exporterTypes: ExporterType[];
};

function PrometheusEnableSummary({
    form,
    exporterTypes,
}: PrometheusEnableSummaryProps) {
    const formValues = form.getFieldsValue(true);
    return (
        <PrometheusExportersData
            data={formValues}
            exporterTypes={exporterTypes}
        />
    );
}
